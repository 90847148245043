import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ReadMoreWrapper = styled.div`
  display: flex;
  margin-top: ${Spacing.XL};
  margin-bottom: -${Spacing.L};
  justify-content: center;
`
