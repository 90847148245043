import { Button, Modal } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useInviteOrUpdateUmbrellaUserForm } from '../../context/inviteOrUpdateUmbrellaUserFormContext'

export const ModalFooter = () => {
  const { t } = useTranslation()
  const { submitForm, isProcessingInvite, isProcessingUpdate, isUpdateMode } = useInviteOrUpdateUmbrellaUserForm()

  const isProcessing = isProcessingInvite || isProcessingUpdate
  const buttonTranslationKey = isUpdateMode
    ? 'umbrella_users.create_edit_user_modal.save'
    : 'umbrella_users.create_edit_user_modal.send'

  const handleApproveButtonClick = useCallback(() => {
    submitForm()
  }, [submitForm])

  return (
    <Modal.Footer>
      <Button onClick={handleApproveButtonClick} loading={isProcessing}>
        {t(buttonTranslationKey)}
      </Button>
    </Modal.Footer>
  )
}
