import { Children, ReactNode } from 'react'

import { isStringOrNumber } from '../../../utils/isStringOrNumber'

export const getCellTitle = (children: ReactNode): string | undefined => {
  const childrenList = Children.toArray(children)

  if (childrenList.length !== 1) {
    return undefined
  }

  const child = childrenList[0]
  const isTitleAttributeSupported = isStringOrNumber(children)

  return isTitleAttributeSupported ? String(child) : undefined
}
