import { TFunction } from 'i18next'

import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

export const getPeriodText = (
  subscriptionPlan: SubscriptionPlan,
  subscriptionPeriod: SubscriptionPeriod,
  t: TFunction,
) => {
  if (subscriptionPlan === SubscriptionPlan.Complete) {
    return t('organization_subscription.summary_payment_yearly')
  }

  if (subscriptionPlan === SubscriptionPlan.Archive) {
    return t('organization_subscription.months_number_other', { month: 60 })
  }

  return subscriptionPeriod === SubscriptionPeriod.Yearly
    ? t('organization_subscription.summary_payment_yearly')
    : t('organization_subscription.summary_payment_monthly')
}
