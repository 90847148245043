import { Color, rwd, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../../../types/themable'

type GuideLayoutWrapperProps = Themable

export const GuideLayoutWrapper = styled.div<GuideLayoutWrapperProps>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  background-color: ${Color.White};

  ${rwd.equalOrLessThan('phone')} {
    padding-right: ${Spacing.L};
    padding-left: ${Spacing.L};
  }
`

export const LayoutContent = styled.div`
  display: flex;
  min-height: 100%;
`

const SideWrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  ${rwd.equalOrLessThan('large')} {
    padding-right: ${Spacing.XL};
    padding-left: ${Spacing.XL};
  }
  ${rwd.equalOrLessThan('tablet')} {
    padding-right: ${Spacing.L};
    padding-left: ${Spacing.L};
  }
  ${rwd.equalOrLessThan('phone')} {
    padding: 0;
    align-items: flex-start;
  }
`

export const LeftSideWrapper = styled(SideWrapper)<Themable>`
  padding-top: ${Spacing.XXXL};
  padding-bottom: ${Spacing.XXXL};

  ${rwd.equalOrLessThan('tablet')} {
    width: 65%;
  }
  ${rwd.equalOrLessThan('phone')} {
    width: 100%;
    padding-top: ${Spacing.L};
  }
`

export const LeftSideContentWrapper = styled.section`
  display: flex;
  width: 428px;
  min-height: 100%;
  margin-bottom: ${Spacing.XXL};
  flex-direction: column;

  ${rwd.equalOrLessThan('large')} {
    width: 100%;
    min-height: auto;
  }
`

export const LeftSizeHeader = styled.header`
  display: flex;
  margin-bottom: ${Spacing.XL};
  padding-top: ${Spacing.L};
  align-items: center;
  justify-content: space-between;
`

export const ContentWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

interface RightSideWrapperProps {
  imageSrc?: string
}

export const RightSideWrapper = styled(SideWrapper)<RightSideWrapperProps>`
  display: flex;
  background-color: ${Color.Gray30};
  background-position: 60px 60px;
  background-size: 90vh;
  background-repeat: no-repeat;
  overflow: hidden;
  ${({ imageSrc }) => `background-image: url(${imageSrc});`};

  ${rwd.equalOrLessThan('large')} {
    background-position: 30px 60px;
  }
  ${rwd.equalOrLessThan('tablet')} {
    width: 35%;
  }
  ${rwd.equalOrLessThan('phone')} {
    display: none;
  }
`

export const RightSideFooter = styled.footer`
  width: 850px;
  margin: 90vh auto auto -52px;

  ${rwd.equalOrLessThan('large')} {
    width: 250px;
    margin-left: -8px;
  }
  ${rwd.equalOrLessThan('tablet')} {
    display: none;
  }
`
