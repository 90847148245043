import styled from '@emotion/styled'

import { BorderRadius } from '../../enums/borderRadius'
import { Color } from '../../enums/color'
import { Spacing } from '../../enums/spacing'
import { Themable } from '../../types/themable'

const PAGINATION_POSITION = '14px'

export const FilesPaginationComponent = styled.nav<Themable>`
  display: inline-flex;
  border: 1px solid ${Color.DayGrey};
  border-radius: ${BorderRadius.L};
  position: absolute;
  top: ${PAGINATION_POSITION};
  left: ${PAGINATION_POSITION};
  z-index: 2;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  width: 160px;
  padding: ${Spacing.XXS};
  background-color: ${Color.White};
`
