import { Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'
import { Trans } from 'react-i18next'

import { ReactComponent as ReceiptsEmpty } from '../../../../assets/images/receipts-empty.svg'
import { getImageClassName } from '../../../../utils/getClassName'
import * as Styled from './styles'

interface ModalEmptyProps {
  description?: ReactNode
}

export const ModalEmpty = ({ description }: ModalEmptyProps): ReactElement => {
  return (
    <Styled.ModalEmptyWrapper>
      <ReceiptsEmpty className={getImageClassName()} />
      <Styled.DescriptionWrapper>
        {description || (
          <Text colorVariant="secondary" alignment="center">
            <Trans i18nKey="ds.modal_files_chooser.default.empty_description">
              Nice, no receipts ready for handling.
              <br />
              Either you’re in total control, or didn’t get started.
              <span className={getImageClassName()}>🤔</span>
            </Trans>
          </Text>
        )}
      </Styled.DescriptionWrapper>
    </Styled.ModalEmptyWrapper>
  )
}
