import { useInvoice } from '../contexts/invoiceContext'

export const useDisplaySidebar = () => {
  const { invoice } = useInvoice()

  const shouldDisplayTransactionLink = !!invoice?.externalInvoiceId
  const shouldDisplayCustomerLink = !!invoice?.customer?.externalContactId

  const shouldDisplaySidebar = shouldDisplayTransactionLink || shouldDisplayCustomerLink

  return {
    shouldDisplayCustomerLink,
    shouldDisplaySidebar,
    shouldDisplayTransactionLink,
  }
}
