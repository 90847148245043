import { TableBadgeLabel } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceStatus } from '../../../../../../enums/invoiceStatus'
import { invoiceStatusToBadgeVariant } from '../../../../constants/invoiceStatusToBadgeVariant'
import { useInvoicesListFilters } from '../../../../hooks/useInvoicesListFilters'
import { useInvoicesSummaryFiltered } from '../../../../hooks/useInvoicesSummaryFiltered'
import { getInvoicesTitle } from './utils/getInvoicesTitle'

export const InvoicesTitle = (): ReactElement => {
  const { t } = useTranslation()
  const { isLoading, data } = useInvoicesSummaryFiltered()
  const [filters] = useInvoicesListFilters()

  const status = filters?.status || InvoiceStatus.All
  const totalAmount = !data ? 0 : data?.[status].count
  const title = getInvoicesTitle(status, totalAmount, t)
  const variant = invoiceStatusToBadgeVariant[status]

  return (
    <TableBadgeLabel amount={totalAmount} isLoading={isLoading} variant={variant}>
      {title}
    </TableBadgeLabel>
  )
}
