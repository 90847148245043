import { SkeletonBox } from '@design-system'

import React, { ReactElement } from 'react'

import { useOrganizationInvitation } from './context/organizationInvitationContext'
import { AcceptOrganizationInvitation } from './elements/AcceptOrganizationInvitation'
import { InvalidInvitation } from './elements/InvalidInvitation'

export const OrganizationInvitation = (): ReactElement => {
  const { isLoading, data, error, token } = useOrganizationInvitation()

  if (isLoading) {
    return <SkeletonBox fullWidth />
  }

  if (!data) {
    return <InvalidInvitation httpStatusCode={error?.statusCode} />
  }

  const {
    existingUser,
    email,
    organization: { name, url },
  } = data

  return (
    <AcceptOrganizationInvitation
      existingUser={existingUser}
      invitationEmail={email}
      invitationToken={token}
      organizationName={name}
      organizationUrl={url}
    />
  )
}
