import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconMagnifier = ({ color = 'inherit', color2, width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-magnifier')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <path
        fill={coloring(color2 || color)}
        strokeWidth="8"
        d="M4.932,0.986c1.054,0,2.045,0.41,2.79,1.156c1.538,1.538,1.538,4.042,0,5.58
        c-0.745,0.745-1.736,1.156-2.79,1.156c-1.054,0-2.045-0.41-2.79-1.156c-1.538-1.538-1.538-4.042,0-5.58
        C2.887,1.397,3.878,0.986,4.932,0.986 M4.932,0C3.67,0,2.408,0.482,1.445,1.445c-1.926,1.926-1.926,5.049,0,6.975
        C2.408,9.383,3.67,9.864,4.932,9.864c1.262,0,2.525-0.482,3.488-1.445c1.926-1.926,1.926-5.049,0-6.975C7.457,0.482,6.194,0,4.932,0
        L4.932,0z"
      ></path>
      <rect
        fill={coloring(color)}
        x="10.572"
        y="9.093"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.7878 11.5587)"
        width="1.973"
        height="4.932"
      ></rect>
    </svg>
  </Box>
)
