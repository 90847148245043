import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'rebass'

import { Checkmark } from '../../../components-deprecated/Checkmark/Checkmark'
import { Themable } from '../../../types/themable'
import { formatDate } from '../../../utils/date-fns'
import { ExtendedBankLine } from '../types'
import { ReconciliationAmount } from './ReconciliationAmount'

const Suggestion = styled.li<Themable>`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px 11px;
  box-shadow: 0px 2px 20px ${({ theme }) => theme.colors.shade10};
  border-radius: ${({ theme }) => theme.forms.defaultRadius};
  border: 1px solid transparent;
  border-left: 3px solid ${({ theme }) => theme.colors.primary};
  user-select: none;
  cursor: pointer;

  & + & {
    margin-top: 10px;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-left: 3px solid ${({ theme }) => theme.colors.primary};
  }
`

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LineData = styled.ul`
  text-align: right;
`

type ReconciliationSuggestionItemProps = {
  line: ExtendedBankLine
  selected?: boolean
  onClick?: () => void
}

export const ReconciliationSuggestionItem = ({
  line,
  selected = false,
  onClick,
  ...rest
}: ReconciliationSuggestionItemProps) => {
  const { t } = useTranslation()

  return (
    <Suggestion onClick={onClick} {...rest}>
      <Line>
        <Flex>
          <Checkmark checked={selected} /> {t('voucher.inbox.reconciliation.reconcile')}
        </Flex>
        <LineData>
          <li>{line.description}</li>
          <li>
            <ReconciliationAmount amount={line.signedAmount} />
          </li>
          <li>{formatDate(line.entryDate, 'dd-mm-yyyy')}</li>
        </LineData>
      </Line>
    </Suggestion>
  )
}
