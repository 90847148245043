import { GlobalSearch, Menu, SideNavbar } from '@components'
import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { QueryClientProvider } from 'react-query'
import { Router } from 'react-router-dom'

import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { UserOrganizationsContextProvider } from '@modules-deprecated/app/organizations'
import {
  IsTrustedPartnerContextProvider,
  UmbrellaContextProvider,
  UserUmbrellasContextProvider,
} from '@modules-deprecated/app/umbrellas'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { BankLoadDataContextProvider } from '@views/financing/AgerasFinanceLending'
import { SubscriptionPlansContextProvider } from '@views/settings/routes/OrganizationSubscriptionSettings/contexts/subscriptionPlansContext'

import { queryClient } from '../../config/queryClient'
import { EmberCurrentRouteContextProvider } from '../../contexts/emberCurrentRouteContext'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { EmberUserTypeContextProvider } from '../../contexts/emberUserTypeContext'
import { GlobalSearchContextProvider } from '../../contexts/globalSearchContext'
import { SideNavbarContextProvider } from '../../contexts/sideNavbarContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { history } from '../../utils/history'

const MODULE_NAME = 'menu-module'

class DashboardWebComponent extends DOMModel {
  @byAttrVal currentroutename: string
  @byAttrVal currentroutepath: string
  @byAttrVal organizationid: string
  @byAttrVal umbrellaid: string
  @byAttrVal userType: string
  @byAttrVal withsidenavbar: string
  @registerEvent('navigate') navigate: Function | undefined
}

interface MenuModuleProps {
  currentroutename: string
  currentroutepath: string
  onNavigate: EmberNavigate
  organizationid: string
  umbrellaid: string
  userType: string
  withsidenavbar: 'true' | 'false'
}

const MenuModule = ({
  currentroutename,
  currentroutepath,
  onNavigate,
  organizationid,
  umbrellaid,
  userType,
  withsidenavbar,
}: MenuModuleProps) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <Router history={history}>
        <ModalContextProvider>
          <UmbrellaContextProvider umbrellaId={umbrellaid}>
            <CurrentUserContextProvider>
              <UserUmbrellasContextProvider>
                <IsTrustedPartnerContextProvider organizationId={organizationid}>
                  <BankLoadDataContextProvider organizationId={organizationid}>
                    <UserOrganizationsContextProvider>
                      <UserOrganizationContextProvider organizationId={organizationid}>
                        <UserOrganizationSettingsContextProvider organizationId={organizationid}>
                          <AccountsContextProvider organizationId={organizationid}>
                            <EmberCurrentRouteContextProvider
                              currentRouteName={currentroutename}
                              currentRoutePath={currentroutepath}
                            >
                              <EmberRouterContextProvider onNavigate={onNavigate}>
                                <EmberUserTypeContextProvider userType={userType}>
                                  <GlobalSearchContextProvider>
                                    <SideNavbarContextProvider withSideNavbar={withsidenavbar === 'true'}>
                                      {/* SubscriptionPlansContextProvider is needed here to ensure subscription plans prices are available on app load, do not remove */}
                                      <SubscriptionPlansContextProvider>
                                        <SideNavbar />
                                        <Menu />
                                        <GlobalSearch />
                                      </SubscriptionPlansContextProvider>
                                    </SideNavbarContextProvider>
                                  </GlobalSearchContextProvider>
                                </EmberUserTypeContextProvider>
                              </EmberRouterContextProvider>
                            </EmberCurrentRouteContextProvider>
                          </AccountsContextProvider>
                        </UserOrganizationSettingsContextProvider>
                      </UserOrganizationContextProvider>
                    </UserOrganizationsContextProvider>
                  </BankLoadDataContextProvider>
                </IsTrustedPartnerContextProvider>
              </UserUmbrellasContextProvider>
            </CurrentUserContextProvider>
          </UmbrellaContextProvider>
        </ModalContextProvider>
      </Router>
    </ThemeProvider>
  </QueryClientProvider>
)

const MenuElement = createCustomElement(MenuModule, DashboardWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, MenuElement)

export default MenuElement
