import isAfter from 'date-fns/isAfter'

import { getBankConnectionSessionById } from '../utils/getBankConnectionSessionById'
import { useBankConnections } from './useBankConnections'
import { useBankConnectionSessions } from './useBankConnectionSessions'

export const useShouldConnectBank = (accountId: string) => {
  const { bankConnections, isLoading: isLoadingBankConnections } = useBankConnections()
  const { bankConnectionSessions, isLoading: isLoadingSessions } = useBankConnectionSessions()

  const connectedBank = bankConnections?.find((bankConnection) => bankConnection.accountId === accountId)
  const currentSession = bankConnectionSessions
    ? getBankConnectionSessionById(bankConnectionSessions, connectedBank?.referenceId)
    : undefined

  const hasConnectionError = !!connectedBank?.errorCode
  const hasSessionError = !!currentSession?.errorCode

  const isNordicApiConnection = connectedBank?.type === 'nordic_api'
  const sessionExpiryTime = currentSession?.loginTokenExpiryTime
    ? new Date(currentSession.loginTokenExpiryTime)
    : undefined
  const isExpiredNordicApiConnection = sessionExpiryTime
    ? isAfter(new Date(), sessionExpiryTime) && isNordicApiConnection
    : false

  const shouldRenewBankConnection = hasConnectionError || hasSessionError || isExpiredNordicApiConnection
  const shouldConnectBank = !connectedBank
  const isLoading = isLoadingBankConnections || isLoadingSessions

  return {
    isLoading,
    shouldConnectBank,
    shouldRenewBankConnection,
  }
}
