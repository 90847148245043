import { Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface AmountColumnProps {
  amount: number
  translationType: 'organization' | 'user'
}

export const AmountColumn = ({ amount, translationType }: AmountColumnProps): ReactElement => {
  const { t } = useTranslation()
  const translationKey =
    translationType === 'organization'
      ? 'umbrella_roles.roles_table.amount.organizations'
      : 'umbrella_roles.roles_table.amount.users'

  if (amount === 0) {
    return <Text>{t('umbrella_roles.roles_table.amount.all')}</Text>
  }

  if (amount > 0) {
    return <Text>{t(translationKey, { count: amount })}</Text>
  }

  return <></>
}
