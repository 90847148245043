import { AccountSelect, ProcessingOverlay } from '@components'
import { Checkbox, Color, FormItemsGroup, Input, Link, Space, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../hooks'
import { useOrganizationAccountingSettings } from '../../contexts/organizationAccountingSettingsContext'
import { OrganizationAccountingFormSchema } from '../../utils/organizationAccountingSettingsFormData'
import * as Styled from './styles'

export const BillSettings = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<OrganizationAccountingFormSchema>()
  const { isProcessing, isLoading } = useOrganizationAccountingSettings()

  return (
    <Styled.FormFieldsWrapper>
      {(isProcessing || isLoading) && <ProcessingOverlay color={Color.White} />}
      <FormItemsGroup>
        <FormItem
          name="isDuplicateBillCheckDisabled"
          render={({ field: { onChange, value, ...rest } }) => (
            <Checkbox
              checked={value}
              children={t('settings.organization.accounting.duplicate_bills')}
              onChange={onChange}
              {...rest}
            />
          )}
        />
        <FormItem
          name="hasBillVoucherNo"
          render={({ field: { onChange, value, ...rest } }) => (
            <Checkbox
              checked={value}
              children={t('settings.organization.accounting.show_voucher_numbers_for_bills')}
              onChange={onChange}
              {...rest}
            />
          )}
        />
        <FormItem
          name="supplierInvoiceNoEnabled"
          render={({ field: { onChange, value, ...rest } }) => (
            <Checkbox
              checked={value}
              children={t('settings.organization.accounting.show_purchase_invoice_no')}
              onChange={onChange}
              {...rest}
            />
          )}
        />
        <FormItem
          label={t('settings.organization.accounting.default_bill_bank_account')}
          name="defaultBillBankAccountId"
          render={({ field: { value, onChange, ...rest } }) => (
            <AccountSelect
              {...rest}
              dropdownSize="fitTrigger"
              isPaymentEnabled
              onSelect={onChange}
              selectedId={value}
            />
          )}
        />
        <div>
          <Text colorVariant="secondary">
            <Trans i18nKey="settings.organization.accounting.bill_email_address_tip" value={'test'}>
              Email images and PDF files to the address below to enter them as new bills.
              <Link
                href="https://www.billy.dk/blog/opret-regninger-i-billys-billing-vha-din-unikke-e-mail/"
                target="_blank"
                rel="noreferrer"
                withAnimation
              >
                Læs om hvordan du bruger denne e-mail
              </Link>
            </Trans>
          </Text>
          <Space size="xxxs" />
          <FormItem name="billEmailAddress" render={({ field }) => <Input readOnly disabled {...field} />} />
        </div>
      </FormItemsGroup>
    </Styled.FormFieldsWrapper>
  )
}
