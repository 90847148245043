import { Modal, ModalBody, ModalFooter, ModalHeader } from '@components-deprecated'
import { Text } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

export interface DisconnectModalProps {
  isOpen: boolean
  isTryingToDisconnect: boolean
  onClose: () => void
  handleDisconnectAttempt: () => void
}

const disconnectSteps = [
  'skat.disconnecting_modal.instructions_1',
  'skat.disconnecting_modal.instructions_2',
  'skat.disconnecting_modal.instructions_3',
  'skat.disconnecting_modal.instructions_6',
  'skat.disconnecting_modal.instructions_7',
]

export const DisconnectModal = (props: DisconnectModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} width={700}>
      <ModalHeader heading={t('skat.disconnecting_modal.title')} />

      <ModalBody>
        {props.isTryingToDisconnect ? (
          <>
            <Styled.List>
              {disconnectSteps.slice(1).map((step) => (
                <li key={step}>{t(step)}</li>
              ))}
            </Styled.List>
            <br />
            <Text>{t('skat.disconnecting_modal.modal_will_dissappear')}</Text>
          </>
        ) : (
          <>
            <Text>{t('skat.disconnecting_modal.how_to')}</Text>
            <br />
            <Styled.List>
              {disconnectSteps.map((step) => (
                <Styled.ListItem key={step}>{t(step)}</Styled.ListItem>
              ))}
            </Styled.List>
          </>
        )}
      </ModalBody>

      <ModalFooter>
        {!props.isTryingToDisconnect && (
          <Styled.Button danger onClick={props.handleDisconnectAttempt}>
            {t('skat_box.disconnect')}
          </Styled.Button>
        )}
      </ModalFooter>
    </Modal>
  )
}
