import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { ComponentsGroup } from '../ComponentsGroup'

export const TabsGroup = styled(ComponentsGroup)`
  box-shadow: inset 0 -1px 0 ${Color.Gray40};
  flex-grow: 1;
  overflow: hidden;
  align-items: center;
`
