type Format = 'jpeg' | 'png' | 'webp' | 'tiff'
type Fit = 'cover' | 'contain' | 'fill' | 'inside' | 'outside'

export type OptionsProps = {
  format?: Format
  fit?: Fit
  size?: string
  page?: number
  rotate?: number
}

const IMAGE_PROXY_URL = window?.ENV?.IMAGE_PROXY_URL || process.env.IMAGE_PROXY_URL

export const removeUndefinedKeysFromObject = (obj: Record<string, any>) => JSON.parse(JSON.stringify(obj))

export const getThumbnailUrl = (url: string, options: OptionsProps = {}) => {
  if (isURLSameOrigin(url)) {
    return url
  }
  // IE11 compatibility
  // eslint-disable-next-line
  const originalUrl = new URL(url)

  const bucket = getBucketFromHostname(originalUrl.hostname) || getBucketFromUrl(url)
  const optionKeys = Object.keys(removeUndefinedKeysFromObject(options))

  const finalUrl = bucket ? compileBaseImageProxyUrl(bucket, originalUrl) : originalUrl

  for (const optionKey of optionKeys) {
    finalUrl.searchParams.set(optionKey, options[optionKey])
  }

  return finalUrl.toString()
}

function compileBaseImageProxyUrl(bucket: string, originalUrl: URL): URL {
  // IE11 compatibility
  // eslint-disable-next-line
  const imageProxyUrl = new URL(IMAGE_PROXY_URL)
  imageProxyUrl.pathname = bucket + originalUrl.pathname

  for (const [param, value] of originalUrl.searchParams) {
    imageProxyUrl.searchParams.set(param, value)
  }

  return imageProxyUrl
}

function getBucketFromHostname(hostname: string) {
  const match = hostname.match(/^([^.]+)\.s3\.([a-z0-9\-.]*)?amazonaws\.com$/)

  if (match) {
    return match[1]
  }

  return ''
}

function getBucketFromUrl(url: string) {
  if (url.includes(process.env.CLOUD_FRONT_URL as string)) {
    return process.env.CLOUD_FRONT_BUCKET
  }
}

function isURLSameOrigin(url: string) {
  // eslint-disable-next-line no-restricted-globals
  return url.includes(location.origin)
}
