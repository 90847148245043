import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, Text } from '@components-deprecated'
import { Button } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'

export type ReconcileAllModalProps = ModalProps & {
  onCancel: () => void
  onReconcile: () => void
}

export const ReconcileAllModal = ({ onCancel, onReconcile, ...rest }: ReconcileAllModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal width={620} {...rest}>
      <ModalHeader heading={t('bankreconciliation.reconcileallmodal.header')} />
      <ModalBody>
        <Text variant="body2">{t('bankreconciliation.reconcileallmodal.body')}</Text>
      </ModalBody>
      <ModalFooter>
        <Button variant="text" onClick={onCancel}>
          {t('bankreconciliation.reconcileallmodal.cancelreconciliation')}
        </Button>
        <Button onClick={onReconcile}>{t('bankreconciliation.reconcileallmodal.reconcile')}</Button>
      </ModalFooter>
    </Modal>
  )
}
