import React, { useMemo } from 'react'

import { useOrganizationSubscription } from '../../../../../../hooks'
import { isExpired } from '../../../../../../utils/payment'
import { EditCardSubscriptionParams } from '../../types'
import { OrganizationPaymentMethodSelection } from '../OrganizationPaymentMethodSelection'

export interface BillingSummaryProps {
  isLoading?: boolean
  onEditCardSubscription: (event: EditCardSubscriptionParams) => void
}

export const BillingSummaryComponent = ({ isLoading, onEditCardSubscription }: BillingSummaryProps) => {
  const { cardAdded, cardInvalid, cardType, cardLabel, cardExpirationDate } = useOrganizationSubscription()

  const handleEditCardSubscription = () => {
    onEditCardSubscription({ detail: { cardOnly: true } })
  }

  return (
    <OrganizationPaymentMethodSelection
      cardDetails={useMemo(
        () => ({
          expirationDate: cardExpirationDate,
          isInvalid: cardInvalid,
          label: cardLabel,
          type: cardType,
          horizontal: true,
        }),
        [cardExpirationDate, cardInvalid, cardLabel, cardType],
      )}
      isCardExpired={!!cardExpirationDate && isExpired(cardExpirationDate)}
      isLoading={isLoading}
      isSubscribed={cardAdded}
      onAddPaymentClick={handleEditCardSubscription}
      onUpdatePaymentClick={handleEditCardSubscription}
    />
  )
}
