import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetLoanIntegration } from '../../hooks/useGetLoanIntegration'
import { useLunarIntegration } from '../../hooks/useLunarIntegration'
import { useSalaryIntegration } from '../../hooks/useSalaryIntegration'
import { useStripeIntegration } from '../../hooks/useStripeIntegration'
import { IntegrationsList } from '../IntegrationsList'

export const MoreIntegrationsList = (): ReactElement => {
  const { t } = useTranslation()
  const salaryIntegration = useSalaryIntegration()
  const stripeIntegration = useStripeIntegration()
  const getLoanIntegration = useGetLoanIntegration()
  const lunarIntegration = useLunarIntegration()

  const items = useMemo(
    () => [salaryIntegration, stripeIntegration, lunarIntegration, getLoanIntegration],
    [getLoanIntegration, lunarIntegration, salaryIntegration, stripeIntegration],
  )

  return <IntegrationsList title={t('integrations.list.title.get_even_more')} items={items} />
}
