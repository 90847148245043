import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { store } from '@modules-deprecated'
import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import { Account } from '@modules-deprecated/app/accounts/types'
import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { BankReconciliation } from '@modules-deprecated/bankReconciliation'
import { BankIntegrationContextProvider } from '@views/bankIntegration/contexts/bankIntegrationContext'

import { queryClient } from '../../config/queryClient'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

class BankReconciliationWebComponent extends DOMModel {
  @byAttrVal organizationid: string
  @byAttrVal accountid: string
  @registerEvent('selectBankAccount') selectBankAccount: Function | undefined
  @registerEvent('connectBankAccount') connectBankAccount: Function | undefined
  @registerEvent('import') import: Function | undefined
  @registerEvent('checkBankIntegration') checkBankIntegration: EmberEventFn | undefined
}

type BankReconciliationProps = {
  organizationid: string
  accountid: string
  onSelectBankAccount: Function
  onConnectBankAccount: Function
  onImport: Function
  onCheckBankIntegration: EmberEventFn
}

function BankReconciliationModule({
  organizationid,
  accountid,
  onSelectBankAccount,
  onConnectBankAccount,
  onImport,
  onCheckBankIntegration,
}: BankReconciliationProps): ReactElement {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <UserOrganizationContextProvider organizationId={organizationid}>
          <AccountsContextProvider organizationId={organizationid}>
            <ReduxProvider store={store}>
              <Router>
                <ModalContextProvider>
                  <BankIntegrationContextProvider
                    account={accountid}
                    onCheckBankIntegration={onCheckBankIntegration}
                    organizationId={organizationid}
                  >
                    <BankReconciliation
                      organizationId={organizationid}
                      accountId={accountid}
                      onSelectBankAccount={(account: Account) => onSelectBankAccount({ detail: account })}
                      onConnectBankAccount={() => onConnectBankAccount({ detail: null })}
                      onImport={() => onImport({ detail: null })}
                    />
                  </BankIntegrationContextProvider>
                </ModalContextProvider>
              </Router>
            </ReduxProvider>
          </AccountsContextProvider>
        </UserOrganizationContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const BankReconciliationCustomElement = createCustomElement(
  BankReconciliationModule,
  BankReconciliationWebComponent,
  'element',
)

customElements.get('bank-reconciliation-module') ||
  customElements.define('bank-reconciliation-module', BankReconciliationCustomElement)

export default BankReconciliationCustomElement
