import { Button, Modal, Space, useModal } from '@design-system'

import React, { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'

export const RegisterInternationallyPendingModal = (): ReactElement => {
  const { t } = useTranslation()
  const { close } = useModal(ModalId.RegisterInternationallyPendingModal)

  return (
    <Modal id={ModalId.RegisterInternationallyPendingModal} size="s">
      <Modal.Header title={t('modal.register_internationally_pending.title')} />
      <Modal.Body>
        <Trans i18nKey="modal.register_internationally_pending.body">
          You have to finish you registration for sending e-invoices outside of Denmark before you can send this
          e-invoice.
          <Space />
          Please check your email for the missing information and complete the registration before you can move forward
          with sending this e-invoice.
        </Trans>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          {t('modal.register_internationally_pending.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
