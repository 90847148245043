import { Translate } from '@components'
import { Dropzone, notify, SkeletonBox, Text } from '@design-system'

import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'
import { APIError } from 'src/utils'

import { useUserOrganization, useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import { createVoucher } from '@modules-deprecated/inbox/services/api'
import { getDraftVoucherData } from '@modules-deprecated/inbox/utils/getDraftVoucherData'
import { uploadFile } from '@views/receipts'
import { ReceiptFilterType } from '@views/receipts/elements/Receipt'
import { useReceiptsCounter } from '@views/receipts/hooks/useReceiptsCounter'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../enums/queryKeys'
import { TrackingContext } from '../../../../enums/trackingContext'
import { useSegment } from '../../../../hooks'
import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { EmptyState } from '../../elements/emptyState'
import { Widget } from '../../elements/Widget'
import { WidgetId } from '../../enums/widgetId'
import * as Styled from './styles'
import { getUploadAttachmentOptions } from './utils/getUploadAttachmentOptions'

type FileType = 'attachment' | 'voucher'

interface UploadProgress {
  current: number
  total: number
}

const uploadProgressInitial: UploadProgress = {
  current: 0,
  total: 0,
}

export const Bills = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { navigate } = useEmberRouter()
  const { organization } = useUserOrganization()
  const { organizationSettings } = useUserOrganizationSettings()
  const queryClient = useQueryClient()
  const isUploadFinished = useRef(false) // helper flag to not show empty screen after upload anymore
  const [uploadProgress, setUploadProgress] = useState(uploadProgressInitial)
  const [isHighlighted, setIsHighlighted] = useState(false)
  const { countMap: receiptsCountMap, isLoading: isReceiptsCountLoading } = useReceiptsCounter()
  const { isWidgetHidden } = useDashboardSettings()

  const fileType: FileType = organizationSettings?.isInboxEnabled === '1' ? 'voucher' : 'attachment'
  const isAttachmentFileType = fileType === 'attachment'

  const isHidden = useMemo(() => isWidgetHidden(WidgetId.Bills), [isWidgetHidden])

  // Fetching data

  const isLoading = !organization || isReceiptsCountLoading
  const isUploadingFiles = uploadProgress.total > 0
  const shouldShowEmptyState =
    !isUploadingFiles && receiptsCountMap[ReceiptFilterType.All] === 0 && !isUploadFinished.current

  // Effects

  useEffect(() => {
    // It can be added on the ember side, so to not cache it every time we need to force invalidate it
    queryClient.invalidateQueries(QueryKeys.AttachmentsCount)
    queryClient.invalidateQueries(QueryKeys.ReceiptsList)
    queryClient.invalidateQueries(QueryKeys.VouchersList)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Mutations

  const handleSuccessClick = () => {
    if (isAttachmentFileType) {
      navigate(EmberRoute.Uploads)
    } else {
      navigate(EmberRoute.Vouchers)
    }
  }

  const mutationOptions: UseMutationOptions<unknown, APIError, File[], unknown> = {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.AttachmentsCount)
      queryClient.invalidateQueries(QueryKeys.ReceiptsList)
      queryClient.invalidateQueries(QueryKeys.VouchersList)
      notify({
        id: NotificationKeys.DashboardWidgetBillsUpload,
        onClick: handleSuccessClick,
        message: t(`dashboard.widget.bills.upload_success_${fileType}`),
        variant: 'success',
      })
      track('File Uploaded (FE)', {
        context: TrackingContext.BillWidgetUpload,
      })
    },
    onError: (error) => {
      notify({
        id: NotificationKeys.DashboardWidgetBillsUpload,
        message: error?.body?.errorMessage
          ? error.body.errorMessage
          : t(`dashboard.widget.bills.upload_error_${fileType}`),
        variant: 'error',
      })
    },
    onSettled: () => {
      isUploadFinished.current = true
      setUploadProgress(uploadProgressInitial)
    },
  }

  const updateUploadProgressCounter = () => {
    setUploadProgress((uploadProgressCurrent) => ({
      ...uploadProgressCurrent,
      current: uploadProgressCurrent.current + 1,
    }))
  }

  const createVouchersMutation = useMutation(async (files: File[]) => {
    for (const file of files) {
      const voucherData = getDraftVoucherData(file)
      await createVoucher(voucherData, organization?.id as string)
      updateUploadProgressCounter()
    }
  }, mutationOptions)

  const uploadAttachmentMutation = useMutation(async (files: File[]) => {
    if (organization?.id) {
      for (const file of files) {
        await uploadFile(file, getUploadAttachmentOptions(organization.id))
        updateUploadProgressCounter()
      }
    } else {
      notify({
        id: NotificationKeys.DashboardWidgetBillsUpload,
        message: t('dashboard.widget.bills.upload_error_attachment'),
        variant: 'error',
      })
    }
  }, mutationOptions)

  // Component functions

  const processDroppedFiles = (files: File[]) => {
    isUploadFinished.current = false
    setUploadProgress((uploadProgressCurrent) => ({ ...uploadProgressCurrent, total: files.length }))

    if (isAttachmentFileType) {
      uploadAttachmentMutation.mutate(files)
      return
    }

    createVouchersMutation.mutate(files)
  }

  const handleDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (acceptedFiles.length) {
      processDroppedFiles(acceptedFiles)
    }

    if (fileRejections.length) {
      notify({
        id: NotificationKeys.DashboardWidgetBillsUploadFileRejections,
        message: `Could not upload ${fileRejections.length} file(s).`,
        variant: 'error',
      })
      console.error(fileRejections)
    }
  }

  const handleHandleClick = () => {
    navigate(EmberRoute.Uploads)
  }

  const handleEmptyDragActive = (isActive: boolean) => {
    setIsHighlighted(isActive)
  }

  return (
    <Widget title={t('dashboard.widget.bills.title')} isHighlighted={isHighlighted} isHidden={isHidden}>
      {isLoading && <SkeletonBox fullHeight fullWidth />}

      {!isLoading && shouldShowEmptyState && (
        <Styled.EmptyDropzone
          onDrop={handleDrop}
          onDragActive={handleEmptyDragActive}
          trackingContext={TrackingContext.BillWidgetUpload}
          raw
        >
          <EmptyState
            image={<Styled.IconBillUpload />}
            buttonText={t('dashboard.widget.bills.uploads.empty_view.upload_button')}
          >
            {t('dashboard.widget.bills.uploads.empty_view.text')}
          </EmptyState>
        </Styled.EmptyDropzone>
      )}

      {!isLoading && !shouldShowEmptyState && (
        <>
          <Styled.UploadsWidget>
            {!!organizationSettings && receiptsCountMap[ReceiptFilterType.All] > 0 && isAttachmentFileType && (
              <Styled.FilesAside>
                <Text variant="h1">{receiptsCountMap[ReceiptFilterType.All]}</Text>
                <Text variant="small" weight="medium">
                  <Translate
                    value="dashboard.widget.bills.uploads.uploads"
                    count={receiptsCountMap[ReceiptFilterType.All]}
                  />
                </Text>
                <Styled.FileButton variant="secondary" onClick={handleHandleClick}>
                  <Translate value="dashboard.widget.bills.uploads.handle_button" />
                </Styled.FileButton>
              </Styled.FilesAside>
            )}

            <Dropzone
              onDrop={handleDrop}
              isUploading={isUploadingFiles}
              uploadingText={t('ds.dropzone.uploading_with_counter', {
                current: uploadProgress.current,
                total: uploadProgress.total,
              })}
            />
          </Styled.UploadsWidget>

          <Styled.Footer>
            <Text colorVariant="secondary" alignment="center" variant="micro">
              {t('dashboard.widget.bills.footer_info_billy')}
            </Text>
          </Styled.Footer>
        </>
      )}
    </Widget>
  )
}
