import { AttachmentFile } from '@design-system'

import { useCallback, useRef } from 'react'

import { useAttachmentOwnerReference } from '@views/receipts/hooks/useAttachmentOwnerReference'

import { useFormContext } from '../../../../../hooks/useForm'
import { attachmentsFilesToAttachmentsIds } from '../../BillEditableForm/utils/attachmentsFilesToAttachmentsIds'
import { BillFormSchema } from '../../BillEditableForm/utils/formData'

export const useDeleteAttachment = () => {
  const { setValue, getValues } = useFormContext<BillFormSchema>()
  const { removeOwnerReference } = useAttachmentOwnerReference()
  const attachmentsFilesRef = useRef<AttachmentFile[]>()
  const attachmentsFilesChosenRef = useRef<AttachmentFile[]>()

  const deleteAttachmentFile = useCallback(
    (fileId: string) => {
      const attachmentsFiles = attachmentsFilesRef.current || []

      // Update
      const attachmentsFilesUpdated = attachmentsFiles.filter(({ id }) => id !== fileId)
      setValue('attachmentsFiles', attachmentsFilesUpdated, { shouldDirty: true })

      return attachmentsFilesUpdated
    },
    [setValue],
  )

  const deleteAttachmentFileChosen = useCallback(
    (fileId: string) => {
      const attachmentsFiles = attachmentsFilesRef.current || []
      const attachmentsFilesChosen = attachmentsFilesChosenRef.current || []

      const file = attachmentsFiles.find((file) => file.id === fileId)
      const attachmentId = file?.attachmentId

      if (!attachmentId) {
        return attachmentsFilesChosen
      }

      // Update
      const attachmentsFilesChosenUpdated = attachmentsFilesChosen.filter(
        (attachmentFile) => attachmentId !== attachmentFile.attachmentId,
      )
      setValue('attachmentsFilesChosen', attachmentsFilesChosenUpdated)

      // Remove owner reference if needed
      const attachmentsIdsChosen = attachmentsFilesToAttachmentsIds(attachmentsFilesChosen || [])
      const shouldRemoveOwnerReference = !attachmentsIdsChosen.includes(attachmentId)

      if (shouldRemoveOwnerReference) {
        removeOwnerReference(attachmentId)
      }

      return attachmentsFilesChosenUpdated
    },
    [removeOwnerReference, setValue],
  )

  const deleteAttachment = useCallback(
    (fileId: string) => {
      const attachmentsFiles = getValues('attachmentsFiles')
      const attachmentsFilesChosen = getValues('attachmentsFilesChosen')

      attachmentsFilesRef.current = attachmentsFiles || []
      attachmentsFilesChosenRef.current = attachmentsFilesChosen || []

      const attachmentsFilesUpdated = deleteAttachmentFile(fileId)
      const attachmentsFilesChosenUpdated = deleteAttachmentFileChosen(fileId)

      return {
        attachmentsFilesUpdated,
        attachmentsFilesChosenUpdated,
      }
    },
    [deleteAttachmentFile, deleteAttachmentFileChosen, getValues],
  )

  return { deleteAttachment }
}
