const paginationItemsLimit = 8
const paginationItemsSideLimit = Math.floor(paginationItemsLimit / 2)

type PaginationItem = number | undefined

const getPageNumbers = (from: number, to: number): PaginationItem[] =>
  Array.from({ length: to - from + 1 }, (_, currentIndex) => from + currentIndex)

export const usePaginationItems = (activePage: number, pagesTotal: number): PaginationItem[] => {
  const isActivePageAtBeginning = activePage <= paginationItemsSideLimit
  const activePageOnRightSideLimit = pagesTotal - paginationItemsSideLimit
  const lastPage = pagesTotal

  if (pagesTotal === 0) {
    return [1]
  }

  if (pagesTotal <= paginationItemsLimit) {
    const allPages = getPageNumbers(1, pagesTotal)
    return allPages
  }

  if (activePage === paginationItemsSideLimit) {
    const prefixPages = getPageNumbers(1, paginationItemsSideLimit + 1)

    return [...prefixPages, undefined, lastPage]
  }

  if (activePage < paginationItemsLimit && isActivePageAtBeginning) {
    const prefixPages = getPageNumbers(1, paginationItemsSideLimit)

    return [...prefixPages, undefined, lastPage]
  }

  if (activePage > activePageOnRightSideLimit) {
    const suffixPages = getPageNumbers(pagesTotal - paginationItemsSideLimit, pagesTotal)
    return [1, undefined, ...suffixPages]
  }

  const middlePages = getPageNumbers(activePage - 1, activePage + 1)
  return [1, undefined, ...middlePages, undefined, lastPage]
}
