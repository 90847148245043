import { ScoutProvider } from '../../../enums/scoutProvider'

interface ProviderTranslationKeys {
  description: string
  title: string
}

interface DeleteProviderModalTranslationKeys {
  description: string
  failed: string
  succeeded: string
  title: string
}

export const providersTranslationKeys: Record<ScoutProvider, ProviderTranslationKeys> = {
  [ScoutProvider.Google]: {
    description: 'receipts.side_panel.google_email.description',
    title: 'receipts.side_panel.google_email.title',
  },
  [ScoutProvider.Microsoft]: {
    description: 'receipts.side_panel.microsoft_email.description',
    title: 'receipts.side_panel.microsoft_email.title',
  },
}

export const deleteProviderModalTranslationKeys: Record<ScoutProvider, DeleteProviderModalTranslationKeys> = {
  [ScoutProvider.Google]: {
    description: 'receipts.side_panel.google_email.remove.description',
    failed: 'receipts.side_panel.google_email.remove.failed',
    succeeded: 'receipts.side_panel.google_email.remove.succeeded',
    title: 'receipts.side_panel.google_email.remove.title',
  },
  [ScoutProvider.Microsoft]: {
    description: 'receipts.side_panel.microsoft_email.remove.description',
    failed: 'receipts.side_panel.microsoft_email.remove.failed',
    succeeded: 'receipts.side_panel.microsoft_email.remove.succeeded',
    title: 'receipts.side_panel.microsoft_email.remove.title',
  },
}
