import React, { ReactElement, useCallback, useState } from 'react'

import { FilesPreviewContent, FilesPreviewContentProps } from './elements/FilesPreviewContent'
import { FullScreenPreview } from './elements/FullScreenPreview'

type FilesPreviewProps = Omit<FilesPreviewContentProps, 'onImageZoomClick'>

export const FilesPreview = (props: FilesPreviewProps): ReactElement => {
  const [isFullScreenMode, setIsFullScreenMode] = useState(false)

  const handleImageZoomClick = useCallback(() => {
    setIsFullScreenMode(true)
  }, [])

  const handleFullScreenCloseClick = useCallback(() => {
    setIsFullScreenMode(false)
  }, [])

  return (
    <>
      <FilesPreviewContent onImageZoomClick={handleImageZoomClick} {...props} />
      {props.withModalZoom && (
        <FullScreenPreview
          className={props.className}
          onClose={handleFullScreenCloseClick}
          files={props.files}
          visible={isFullScreenMode}
        />
      )}
    </>
  )
}
