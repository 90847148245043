import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../utils'
import { createInvoiceTemplate, CreateInvoiceTemplateResponseData } from '../query-api'

interface UseCreateInvoiceTemplateProps {
  onError?: (error?: APIError) => void
  onSuccess?: (data: CreateInvoiceTemplateResponseData) => void
}

export const useCreateInvoiceTemplate = (props?: UseCreateInvoiceTemplateProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate: create,
    isLoading: isProcessing,
    ...rest
  } = useMutation(createInvoiceTemplate, { onSuccess, onError })

  return { create, isProcessing, ...rest }
}
