import { ProtectedComponent } from '@components'
import { Button, ButtonsGroup, notify, SectionMessage, Space } from '@design-system'

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../enums/queryKeys'
import { Scope } from '../../../../enums/scope'
import { useUpdateSpawnSettings } from '../../hooks/useUpdateSpawnSettings'
import { UpdateRecurringInvoicesSpawnSettingsOptions } from '../../query-api'

export const RecurringInvoicesDisabledSpawnSectionMessage = () => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const queryClient = useQueryClient()

  const { update } = useUpdateSpawnSettings({
    onSuccess: () => {
      notify({
        id: NotificationKeys.RecurringInvoicesSpawnEnable,
        message: t('recurring_invoices.disabled_spawn_section_message.notification.success'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.RecurringInvoicesSpawnSettings)
    },
    onError: () => {
      notify({ id: NotificationKeys.RecurringInvoicesSpawnEnable, message: t('error_saving'), variant: 'error' })
    },
  })

  const organizationId = useMemo(() => organization?.id as string, [organization?.id])

  const updateSpawnSettings = useCallback(
    (options: UpdateRecurringInvoicesSpawnSettingsOptions) => {
      update(options)
    },
    [update],
  )

  const handleSendPlannedAndFutureInvoicesButtonClick = useCallback(() => {
    updateSpawnSettings({ payload: { enabled: true }, organizationId, preserveSpawns: true })
  }, [organizationId, updateSpawnSettings])

  const handleSendOnlyFutureInvoicesButtonClick = useCallback(() => {
    updateSpawnSettings({ payload: { enabled: true }, organizationId })
  }, [organizationId, updateSpawnSettings])

  return (
    <ProtectedComponent scopes={Scope.RecurringInvoiceWrite}>
      <SectionMessage
        variant="error"
        closable={false}
        showIcon={false}
        sideActions={
          <ButtonsGroup>
            <Button variant="secondary" onClick={handleSendPlannedAndFutureInvoicesButtonClick}>
              {t('recurring_invoices.disabled_spawn_section_message.button_label.send_planned_and_future')}
            </Button>
            <Button onClick={handleSendOnlyFutureInvoicesButtonClick}>
              {t('recurring_invoices.disabled_spawn_section_message.button_label.send_only_future')}
            </Button>
          </ButtonsGroup>
        }
      >
        {t('recurring_invoices.disabled_spawn_section_message.content')}
      </SectionMessage>
      <Space size="xxl" />
    </ProtectedComponent>
  )
}
