import { TFunction } from 'i18next'

import { NeedInfoReasons } from '../types'

export const getNeedInfoReasonsMessage = (t: TFunction): Record<NeedInfoReasons, string | undefined> => ({
  [NeedInfoReasons.MissingAmount]: t('voucher.inbox.modals.moreinfo.reason_missing_amount.message'),
  [NeedInfoReasons.MissingDate]: t('voucher.inbox.modals.moreinfo.reason_missing_date.message'),
  [NeedInfoReasons.MissingSupplier]: t('voucher.inbox.modals.moreinfo.reason_missing_supplier.message'),
  [NeedInfoReasons.MissingPurpose]: t('voucher.inbox.modals.moreinfo.reason_missing_purpose.message'),
  [NeedInfoReasons.MissingVAT]: t('voucher.inbox.modals.moreinfo.reason_missing_vat.message'),
  [NeedInfoReasons.ImageQuality]: t('voucher.inbox.modals.moreinfo.reason_image_quality.message'),
  [NeedInfoReasons.MissingPaymentMethod]: t('voucher.inbox.modals.moreinfo.reason_missing_payment_method.message'),
  [NeedInfoReasons.IncorrectReceipt]: t('voucher.inbox.modals.moreinfo.reason_incorrect_receipt.message'),
  [NeedInfoReasons.Other]: undefined,
})
