import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import React from 'react'

import { Theme } from '../../../types/theme'
import { IconCross } from '../../Icons/icons/IconCross'
import { fileThumbnailCommonStyles } from './styles'

const FileEmptyThumbnailStyled = styled(IconCross)`
  ${fileThumbnailCommonStyles};
`

export const FileEmptyThumbnail = () => {
  const theme = useTheme<Theme>()

  return <FileEmptyThumbnailStyled color={theme.colors.shade20} />
}
