import { TabButtonsGroup } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PeriodMode as PeriodModeType } from '../../../../../enums/periodMode'
import { headerPeriodModes } from '../../constants/headerPeriodModes'
import { usePeriodSelectContext } from '../../contexts/periodSelectContext'
import { getPeriodModeToLabel } from '../../utils/getPeriodModes'
import * as Styled from './styles'

export const PeriodModeToggler = (): ReactElement => {
  const { t } = useTranslation()
  const { periodMode, setPeriodMode, isCustomDate, setIsCustomDate, disabledPeriodModes } = usePeriodSelectContext()

  const periodModeToLabel = useMemo(() => getPeriodModeToLabel(t), [t])

  const handleButtonClick = useCallback(
    (mode: PeriodModeType) => () => {
      setPeriodMode(mode)
      setIsCustomDate(false)
    },
    [setIsCustomDate, setPeriodMode],
  )

  return (
    <Styled.PeriodModeTogglerWrapper>
      <TabButtonsGroup size="m" fitToWidth fullWidth>
        {headerPeriodModes.map((mode) => (
          <TabButtonsGroup.Button
            selected={periodMode === mode && !isCustomDate}
            key={mode}
            onClick={handleButtonClick(mode)}
            disabled={disabledPeriodModes.includes(mode)}
          >
            {periodModeToLabel[mode]}
          </TabButtonsGroup.Button>
        ))}
      </TabButtonsGroup>
    </Styled.PeriodModeTogglerWrapper>
  )
}
