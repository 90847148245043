import { Icon } from '@design-system'

import { css } from '@emotion/core'
import React from 'react'
import { Box, BoxProps } from 'rebass'

export const CalendarButton = (props: BoxProps) => (
  <Box
    css={css`
      display: flex;
      opacity: ${props.disabled ? 0.5 : 1};
      position: absolute;
      top: 0;
      right: 0;
      padding: 7px 11px;
      width: 40px;
      height: 40px;
      z-index: 2;
      align-items: center;

      ${!props.disabled &&
      `
        cursor: pointer;
      `}
    `}
    {...props}
  >
    <Icon icon="calendar" />
  </Box>
)
