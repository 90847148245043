import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { SCROLLABLE_LIST_ROW_HEIGHT } from '../../../../../../ScrollableList/constants/scrollableListRowHeight'

export const BillsListItemSkeletonWrapper = styled.div`
  display: flex;
  height: ${SCROLLABLE_LIST_ROW_HEIGHT}px;
  width: 100%;
  margin-top: -${Spacing.XXXS};
  padding: 0 ${Spacing.XL} 0 ${Spacing.L};
  border-bottom: 1px solid ${Color.Gray30};
  align-items: center;
`

export const BillsListItemContentSkeletonWrapper = styled.div`
  width: 100%;
`

export const DetailsSkeletonWrapper = styled.div`
  display: grid;
  grid-template-columns: 120px 70px 300px;
`
