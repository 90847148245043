/*
 *  Remember to wrap the module with Router from 'react-router-dom' to be able to use this hook
 */
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { IParseOptions, IStringifyOptions, parse, stringify } from 'qs'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { fixSearchParamsArrays } from '../utils/fixSearchParamsArrays'

const parseOptions: IParseOptions = {
  allowDots: true,
  ignoreQueryPrefix: true,
}

const stringifyOptions: IStringifyOptions = {
  allowDots: true,
}

export type UseQueryParamsResponse<T> = [
  query: T,
  setQuery: (params: Partial<T> | ((prevParams: Partial<T>) => Partial<T>)) => void,
]

interface UseQueryParams<T> {
  defaultQuery: T
  enabled?: boolean
  // Use a unique key if you want to use queries more than once in a single view (e.g. two different tables)
  key?: string
  // It sets default params in URL
  withDefaultQueryInUrl?: boolean
}

export const useQueryParams = <T extends Record<string, any>>(props: UseQueryParams<T>): UseQueryParamsResponse<T> => {
  const { defaultQuery, enabled = true, key, withDefaultQueryInUrl = true } = props || {}
  const [query, setQuery] = useState<T>(defaultQuery)
  const location = useLocation()
  const history = useHistory()

  // Handling URL
  const getQueryFromUrl = useCallback(() => {
    const hrefSplitted = window.location.href.split('?')
    const search = hrefSplitted.length > 1 && hrefSplitted[hrefSplitted.length - 1]
    return parse(search || '', parseOptions) as T
  }, [])

  const updateUrl = useCallback(
    (query: T) => {
      const urlQuery = getQueryFromUrl()
      const urlQuerySpecified = key ? urlQuery[key] : urlQuery

      const searchLocationMerged = {
        ...urlQuery,
        ...(key
          ? {
              [key]: { ...urlQuerySpecified, ...query },
            }
          : query),
      }

      const formattedSearchParams = fixSearchParamsArrays(searchLocationMerged)

      history.replace(`?${stringify(formattedSearchParams, stringifyOptions)}`)
    },
    [getQueryFromUrl, history, key],
  )

  // Handling state

  const setParams = useCallback(
    (params: Partial<T> | ((prevParams: Partial<T>) => Partial<T>)) => {
      setQuery((prevQuery) => {
        const nextQuery = typeof params === 'function' ? params(prevQuery) : params

        const updatedQuery = {
          ...prevQuery,
          ...nextQuery,
        }

        updateUrl(updatedQuery)
        return updatedQuery
      })
    },
    [updateUrl],
  )

  const getAllowedQueryFromUrl = useCallback(() => {
    const allowedParams = Object.keys(defaultQuery)
    const urlQuery = getQueryFromUrl()
    const urlQuerySpecified = key ? urlQuery[key] : urlQuery
    return pick(urlQuerySpecified, allowedParams) as T
  }, [defaultQuery, getQueryFromUrl, key])

  // Lifecycle

  const fetchQueryFromUrl = useCallback(() => {
    const urlQueryAllowed = getAllowedQueryFromUrl()
    const newQuery = {
      ...defaultQuery,
      ...urlQueryAllowed,
    }

    if (!isEqual(query, newQuery)) {
      setQuery(newQuery)
      updateUrl(newQuery)
    }
  }, [defaultQuery, getAllowedQueryFromUrl, query, updateUrl])

  useEffect(() => {
    if (!enabled) {
      return
    }

    fetchQueryFromUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, location])

  useEffect(() => {
    if (!enabled) {
      return
    }

    const urlQueryAllowed = getAllowedQueryFromUrl()

    if (isEmpty(urlQueryAllowed) && withDefaultQueryInUrl) {
      updateUrl(defaultQuery)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled])

  return [query, setParams]
}
