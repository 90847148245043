import { useModalMultiple, withModalConditionalRender } from '@design-system'

import { useCallback, useEffect } from 'react'

import { BankConnection } from '@modules-deprecated/bankReconciliation/types'

import { ModalId } from '../../../../enums/modalId'
import { useQueryParams } from '../../../../hooks/useQueryParams'
import { DEFAULT_QUERY_PARAMS } from '../../constants/defaultQueryParams'
import { useBankIntegration } from '../../contexts/bankIntegrationContext'
import { useBankConnections } from '../../hooks/useBankConnections'
import { AccountInUseModal } from '../AccountInUseModal/AccountInUseModal'
import { BankAccountOrphanedModal as BankAccountOrphaned } from '../BankAccountOrphanedModal/BankAccountOrphanedModal'
import { BankConnectionFailedModal as BankConnectionFailed } from '../BankConnectionFailedModal/BankConnectionFailedModal'
import { BankToAccountConnectionModal as BankToAccountConnectionModalComponent } from '../BankToAccountConnectionModal'
import { ConnectionExpiredModal } from '../ConnectionExpiredModal/ConnectionExpiredModal'
import { ErrorModal } from '../ErrorModal/ErrorModal'

const BankAccountOrphanedModal = withModalConditionalRender(BankAccountOrphaned)
const BankConnectionFailedModal = withModalConditionalRender(BankConnectionFailed)
const BankToAccountConnectionModal = withModalConditionalRender(BankToAccountConnectionModalComponent)
const BankAccountInUseModal = withModalConditionalRender(AccountInUseModal)
const BankConnectionExpiredModal = withModalConditionalRender(ConnectionExpiredModal)
const BankConnectionErrorModal = withModalConditionalRender(ErrorModal)

export const BankConnectionStatusActions = () => {
  const { open: openModal } = useModalMultiple([
    ModalId.BankAccountOrphanedModal,
    ModalId.BankConnectionErrorModal,
    ModalId.BankToAccountConnectionModal,
    ModalId.BankConnectionAlreadyInUseModal,
  ])

  const [queryParams, setQueryParams] = useQueryParams({
    defaultQuery: DEFAULT_QUERY_PARAMS,
    withDefaultQueryInUrl: false,
  })

  const { accountId, connectedBankAccountId, isOrphaned, hasBankConnectionError } = useBankIntegration()
  const { bankConnections, filteredBankConnections, refetch: refetchBankConnections } = useBankConnections()

  // Open modals related to bank connection errors
  useEffect(() => {
    if (isOrphaned) {
      openModal(ModalId.BankAccountOrphanedModal)
    } else if (hasBankConnectionError) {
      openModal(ModalId.BankConnectionFailedModal)
    }
  }, [hasBankConnectionError, isOrphaned, openModal])

  useEffect(() => {
    if (queryParams.success === 'true') {
      // show all connections
      if (!queryParams.connectionId) {
        openModal(ModalId.BankToAccountConnectionModal)
        return
      }

      // new connection flow based on connectionId
      if (filteredBankConnections) {
        // no available connections = selected bank accounts already connected to other billy accounts
        if ((filteredBankConnections as Array<BankConnection>).length === 0) {
          openModal(ModalId.BankConnectionAlreadyInUseModal)
        } else {
          // happy path
          openModal(ModalId.BankToAccountConnectionModal)
        }
      }
    }

    if (queryParams.success === 'false') {
      openModal(ModalId.BankConnectionErrorModal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, filteredBankConnections, bankConnections])

  const removeQueryParams = useCallback(() => {
    setQueryParams(DEFAULT_QUERY_PARAMS)
  }, [setQueryParams])

  return (
    <>
      <BankAccountInUseModal id={ModalId.BankConnectionAlreadyInUseModal} removeQueryParams={removeQueryParams} />
      <BankConnectionFailedModal id={ModalId.BankConnectionFailedModal} />
      <BankAccountOrphanedModal id={ModalId.BankAccountOrphanedModal} />
      <BankToAccountConnectionModal
        accountId={accountId}
        id={ModalId.BankToAccountConnectionModal}
        refetchBankConnections={refetchBankConnections}
        removeQueryParams={removeQueryParams}
      />
      <BankConnectionExpiredModal
        connectedBankAccountId={connectedBankAccountId}
        id={ModalId.BankConnectionExpiredModal}
      />
      <BankConnectionErrorModal id={ModalId.BankConnectionErrorModal} removeQueryParams={removeQueryParams} />
    </>
  )
}
