import styled from '@emotion/styled'
import { BasePlacement } from '@popperjs/core'

import { pxToNumber } from '../../../utils/pxToNumber'

const ARROW_HEIGHT = '6px'
const ARROW_WIDTH = '12px'
const ARROW_DISPLAY_MARGIN = `-${pxToNumber(ARROW_HEIGHT) - 1}px`

interface ArrowProps {
  placement: BasePlacement
}

export const Arrow = styled.div<ArrowProps>`
  display: flex;

  ${({ placement }) => {
    switch (placement) {
      case 'bottom':
        return `
          width: ${ARROW_WIDTH};
          height: ${ARROW_HEIGHT};
          top: 0;
          left: 0;
          margin-top: ${ARROW_DISPLAY_MARGIN};
        `
      case 'right':
        return `
          width: ${ARROW_HEIGHT};
          height: ${ARROW_WIDTH};
          left: 0;
          margin-left: ${ARROW_DISPLAY_MARGIN};
        `
      case 'left':
        return `
          width: ${ARROW_HEIGHT};
          height: ${ARROW_WIDTH};
          right: 0;
          margin-right: ${ARROW_DISPLAY_MARGIN};
        `
      case 'top':
      default:
        return `
          width: ${ARROW_WIDTH};
          height: ${ARROW_HEIGHT};
          bottom: 0;
          left: 0;
          margin-bottom: ${ARROW_DISPLAY_MARGIN};
        `
    }
  }}
`
