import { Button, ButtonsGroup, Link, Modal, ModalProps, notify, Space, Text, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../../../enums/notificationKeys'
import { useEmberIntegration } from '../../../../context/IntegrationContext'
import { usePaymentMethodsStatuses } from '../../../../hooks/usePaymentMethodsStatuses'
import { useEnableStripe } from '../../hooks/useEnableStripe'

interface EnableStripeModalProps extends ModalProps {
  onActivationSuccess?: () => void
  onModalClose?: () => void
}

export const EnableStripeModal = ({
  onActivationSuccess,
  onModalClose,
  ...modalProps
}: EnableStripeModalProps): ReactElement => {
  const { t } = useTranslation()
  const { refetch: refetchPaymentMethodsStatuses } = usePaymentMethodsStatuses()
  const { reloadPaymentMethods } = useEmberIntegration()

  const handleModalOnClose = useCallback(() => {
    onModalClose?.()
  }, [onModalClose])
  const { close } = useModal(modalProps.id, { onClose: handleModalOnClose })

  const onStripeActivation = useCallback(() => {
    reloadPaymentMethods()
    refetchPaymentMethodsStatuses()
    close()
    onActivationSuccess?.()
  }, [close, refetchPaymentMethodsStatuses, onActivationSuccess, reloadPaymentMethods])

  const onStripeError = useCallback(() => {
    notify({
      id: NotificationKeys.SubscriptionStripe,
      message: t('integrations.card.stripe.notification.failed'),
      variant: 'error',
    })
  }, [t])

  const { enableStripe, isWaiting } = useEnableStripe({ onSuccess: onStripeActivation, onError: onStripeError })

  const handleCancelButtonClick = useCallback(() => {
    close()
  }, [close])

  const handleContinueButtonClick = useCallback(() => {
    enableStripe()
  }, [enableStripe])

  return (
    <Modal size="s" closable {...modalProps}>
      <Modal.Header title={t('integrations.card.stripe.enable_modal.title')} />
      <Modal.Body>
        {isWaiting && <Text>{t('integrations.card.stripe.enable_modal.message_waiting')}</Text>}
        {!isWaiting && (
          <>
            <Trans i18nKey="integrations.card.stripe.enable_modal.message">
              <Text>
                Get paid faster by accepting credit cards. Your clients will be able to pay your invoices using a
                credit/debit card. Your payments will be automatically deposited in your bank account within 48 hours.
              </Text>
              <Space />
              <Text>Fees for credit and debit card payments: 2.9% + $0.30</Text>
              <Space />
              <Text>When you click Continue, a new page will open, where you can create your Stripe account.</Text>
            </Trans>
            <Space />
            <Text>
              <Link href="https://stripe.com/" target="_blank" withAnimation>
                {t('integrations.card.stripe.enable_modal.learn_more')}
              </Link>
            </Text>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button onClick={handleCancelButtonClick} variant="text">
            {t('cancel')}
          </Button>
          {!isWaiting && (
            <Button onClick={handleContinueButtonClick}>
              {t('integrations.card.stripe.enable_modal.button_continue')}
            </Button>
          )}
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
