import { css, SerializedStyles } from '@emotion/core'

import { Spacing } from '../../../enums/spacing'
import { InputSize } from '../types/size'

interface SizeProperties {
  height: number
  yPadding: Spacing
  xPadding: Spacing
}

export const sizeToProps: Record<InputSize, SizeProperties> = {
  m: {
    height: 32,
    yPadding: Spacing.XXS,
    xPadding: Spacing.M,
  },
  l: {
    height: 40,
    yPadding: Spacing.S,
    xPadding: Spacing.M,
  },
  xl: {
    height: 48,
    yPadding: Spacing.M,
    xPadding: Spacing.M,
  },
}

export const getSizeStyle = (size: InputSize, isTextArea: boolean): SerializedStyles => {
  const { height, yPadding, xPadding } = sizeToProps[size]

  return css`
    ${isTextArea ? `min-height: ${height}px` : `height: ${height}px`};
    padding: ${yPadding} ${xPadding};
  `
}
