import React, {
  Children,
  cloneElement,
  isValidElement,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import { useHoverDirty } from 'react-use'

import { Color } from '../../../../enums/color'
import { useScrollToElement } from '../../../../hooks/useScrollToElement'
import { useTheme } from '../../../../hooks/useTheme'
import { Asable } from '../../../../types/asable'
import { IconName } from '../../../Icon'
import { useNavItemSubmit } from '../../hooks/useNavItemSubmit'
import { NavItem } from '../../types/navItem'
import { SubItemsMode } from '../../types/subItemsMode'
import { getItemState } from '../../utils/getItemState'
import { getRealItems } from '../../utils/getRealItems'
import { getStateToStyles } from '../../utils/getStateToStyles'
import { NavIcon } from '../NavIcon'
import { NavListItemText } from '../NavListItemText'
import { NavListItemView } from '../NavListItemView'
import { NavListSubItem } from '../NavListSubItem'
import * as Styled from './styles'

export interface NavListItemProps<T> extends Asable {
  children?: ReactNode
  expandedIds?: string[]
  focusedId?: string
  href?: string
  item: NavItem<T>
  onClick?: (id: string, value: T, event?: MouseEvent) => void
  selectedId?: string
  subItemsMode?: SubItemsMode
  to?: string
  rel?: string
  target?: string
}

export type NavListItemChildren<T> = Pick<NavListItemProps<T>, 'selectedId' | 'focusedId' | 'item'> & {
  textColor?: Color
  textSecondaryColor?: Color
}

export const NavListItem = <T,>({
  as,
  children: componentChildren,
  expandedIds,
  focusedId,
  item,
  onClick,
  selectedId,
  subItemsMode,
  ...rest
}: NavListItemProps<T>): ReactElement => {
  const theme = useTheme()
  const listItemRef = useRef<HTMLDivElement>(null)
  const isHovered = useHoverDirty(listItemRef)

  const { iconLeft, id, children: itemChildren, href, subItems, value, title, to, target, rel, readonly } = item

  const subItemsReal = subItems?.length ? getRealItems(subItems) : undefined
  const defaultIconRight: IconName = subItemsMode === 'horizontal' ? 'chevronRight' : 'chevronDown'
  const defaultIconRightExpanded: IconName = subItemsMode === 'horizontal' ? 'chevronLeft' : 'chevronUp'
  const iconRight = subItemsReal?.length && !item.iconRight ? defaultIconRight : item.iconRight
  const iconRightExpanded =
    subItemsReal?.length && !item.iconRightExpanded ? defaultIconRightExpanded : item.iconRightExpanded

  const isExpanded = expandedIds?.includes(id)
  const isSelected = selectedId === id
  const isFocused = focusedId === id
  const children = (componentChildren || itemChildren) as ReactNode

  const itemState = useMemo(
    () =>
      getItemState({
        expanded: isExpanded,
        selected: isSelected,
        focused: isFocused,
        hovered: isHovered,
        readonly,
      }),
    [isExpanded, isSelected, isFocused, isHovered, readonly],
  )

  const { textColor, iconLeftColor, iconRightColor, textSecondaryColor } = getStateToStyles(theme)[itemState]

  const handleItemClick = useCallback(
    (event?: MouseEvent) => {
      if (!readonly) {
        onClick?.(id, value, event)
      }
    },
    [onClick, id, value, readonly],
  )

  const handleSubItemClick = useCallback(
    (subItemId: string, subItemValue: T, event?: MouseEvent) => {
      if (!readonly) {
        onClick?.(subItemId, subItemValue, event)
      }
    },
    [onClick, readonly],
  )

  const handleKeyDown = useCallback(() => {
    if (isFocused && !readonly) {
      listItemRef.current?.click()
    }
  }, [isFocused, readonly])

  useNavItemSubmit(handleKeyDown, isFocused, [id, value, isFocused, readonly, handleKeyDown])
  useScrollToElement(listItemRef.current, isFocused || isSelected)

  return (
    <NavListItemView
      {...rest}
      as={as}
      href={href}
      target={target}
      rel={rel}
      onClick={handleItemClick}
      readonly={readonly}
      ref={listItemRef}
      state={itemState}
      to={to}
      subItems={
        !!subItemsReal?.length &&
        isExpanded && (
          <Styled.SubItemsList>
            {subItemsReal.map((item) => (
              <NavListSubItem
                key={item.id}
                item={item}
                to={item.to}
                target={item.target}
                rel={item.rel}
                selectedId={selectedId}
                focusedId={focusedId}
                onClick={handleSubItemClick}
              />
            ))}
          </Styled.SubItemsList>
        )
      }
    >
      {iconLeft && (
        <Styled.IconLeftWrapper>
          <NavIcon icon={iconLeft} color={iconLeftColor} />
        </Styled.IconLeftWrapper>
      )}

      {typeof children === 'string' ? (
        <NavListItemText color={textColor} title={title || children}>
          {children}
        </NavListItemText>
      ) : (
        Children.map(children, (child) => {
          if (isValidElement<NavListItemChildren<T>>(child)) {
            return cloneElement(child, {
              selectedId,
              focusedId,
              item,
              textColor,
              textSecondaryColor,
            })
          }

          return child
        })
      )}

      {iconRight && (
        <Styled.IconRightWrapper>
          <NavIcon icon={isExpanded && iconRightExpanded ? iconRightExpanded : iconRight} color={iconRightColor} />
        </Styled.IconRightWrapper>
      )}
    </NavListItemView>
  )
}
