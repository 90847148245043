import { MODULE_LAYOUT_BOTTOM_SPACE } from '@design-system'

import styled from '@emotion/styled'

export const BillFormWrapper = styled.div`
  margin-bottom: ${MODULE_LAYOUT_BOTTOM_SPACE};
`

export const FormHeader = styled.header`
  display: flex;
  align-items: center;
`

export const BillLine = styled.li``

export const BillLineTitleWrapper = styled.header`
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;
`

export const ItemWrapper = styled.div``
