import { Modal } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../enums/modalId'
import { TaxAccountWithDetails } from '../../../../types/taxAccountWithDetails'
import { CreateOrEditVatAccountModalContextProvider } from './context/createOrEditVatAccountModalContext'
import { CreateOrEditVatAccountForm } from './elements/CreateOrEditVatAccountForm'
import { ModalFooter } from './elements/ModalFooter'

interface CreateOrEditVatAccountModalProps {
  vatAccount?: TaxAccountWithDetails
}

export const CreateOrEditVatAccountModal = ({ vatAccount }: CreateOrEditVatAccountModalProps): ReactElement => {
  const { t } = useTranslation()
  const isEditMode = !!vatAccount

  return (
    <CreateOrEditVatAccountModalContextProvider vatAccount={vatAccount}>
      <Modal id={ModalId.CreateOrEditVatAccountModal} size="s" closable autofocusOnInput>
        <Modal.Header
          title={t(
            isEditMode
              ? 'settings.organization.tax_return.modals.edit_account.title'
              : 'settings.organization.tax_return.modals.create_account.title',
          )}
        />
        <Modal.Body>
          <CreateOrEditVatAccountForm />
        </Modal.Body>
        <Modal.Footer>
          <ModalFooter />
        </Modal.Footer>
      </Modal>
    </CreateOrEditVatAccountModalContextProvider>
  )
}
