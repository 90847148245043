import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const BusinessInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${Spacing.M};
  row-gap: ${Spacing.L};
`

export const Item = styled.div``

export const Label = styled(Text)`
  margin-bottom: ${Spacing.XXXS};
`

export const Data = styled(Text)``
