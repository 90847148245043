import { isPast } from 'date-fns'

import BigNumber from '../../../utils/bigNumber'
import { BillState } from '../enums/billState'
import { BillStatus } from '../enums/billStatus'

const getNormalizedPartiallyPaidStatus = (dueDate?: Date): BillStatus => {
  if (dueDate && isPast(dueDate)) {
    return BillStatus.Overdue
  } else {
    return BillStatus.Unpaid
  }
}

interface BillStateToStatusOptions {
  amount?: number | null
  balance?: number | null
  dueDate?: Date
  isPaid?: boolean
  normalizePartiallyPaid?: boolean
  state?: BillState | null
  tax?: number | null
}

export const getBillStatus = ({
  amount,
  balance,
  dueDate,
  isPaid,
  normalizePartiallyPaid,
  state,
  tax,
}: BillStateToStatusOptions): BillStatus => {
  if (state === BillState.Voided) {
    return BillStatus.Voided
  }

  if (state !== BillState.Approved) {
    return BillStatus.Draft
  }

  if (isPaid) {
    return BillStatus.Paid
  }

  const diffGrossAmountWithBalance = new BigNumber(amount || 0).plus(tax || 0).minus(balance || 0)
  const isPartiallyPaid = diffGrossAmountWithBalance.isGreaterThan(0)

  if (isPartiallyPaid) {
    if (normalizePartiallyPaid) {
      return getNormalizedPartiallyPaidStatus(dueDate)
    }

    return BillStatus.PartiallyPaid
  }

  if (dueDate && isPast(dueDate)) {
    return BillStatus.Overdue
  }

  return BillStatus.Unpaid
}
