import { IconButton, Tooltip } from '@design-system'

import React, { ReactElement, useCallback, useState } from 'react'

import { Input, InputProps } from '../Input'
import * as Styled from './styles'

interface PasswordProps extends InputProps {
  className?: string
}

export const Password = ({ className, disabled, ...rest }: PasswordProps): ReactElement => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const handleIconButtonClick = useCallback(() => {
    setIsPasswordVisible((prevIsPasswordVisible) => !prevIsPasswordVisible)
  }, [])

  return (
    <Styled.PasswordWrapper className={className}>
      <Input
        type={isPasswordVisible ? 'text' : 'password'}
        inputClassName="with-right-icon"
        disabled={disabled}
        {...rest}
      />
      {!disabled && (
        <Styled.IconButtonWrapper>
          <Tooltip label={isPasswordVisible ? 'Hide password' : 'Show password'}>
            <IconButton size="m" icon={isPasswordVisible ? 'eyeCrossed' : 'eye'} onClick={handleIconButtonClick} />
          </Tooltip>
        </Styled.IconButtonWrapper>
      )}
    </Styled.PasswordWrapper>
  )
}
