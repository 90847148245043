import React, { ReactElement, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

import { ErrorMessage } from './ErrorMessage'
import * as Styled from './styles'

interface FormRowProps {
  children: ReactNode
  error?: FieldError
}

export const FormRow = ({ children, error }: FormRowProps): ReactElement => {
  return (
    <Styled.FormRow>
      {children}
      {error && (
        <Styled.Error>
          <ErrorMessage error={error} />
        </Styled.Error>
      )}
    </Styled.FormRow>
  )
}
