import React, { ForwardRefExoticComponent, ReactElement, ReactNode, RefAttributes } from 'react'

import { ModuleSection } from './elements/ModuleSection'
import { ModuleSectionsGroup } from './elements/ModuleSectionsGroup'
import * as Styled from './styles'

export interface ModuleProps {
  children: ReactNode
  className?: string
  id?: string
}

type ModuleType = ForwardRefExoticComponent<ModuleProps & RefAttributes<HTMLDivElement>> & {
  Section: typeof ModuleSection
  SectionsGroup: typeof ModuleSectionsGroup
}

export const Module = (({ children, className, id }: ModuleProps): ReactElement => (
  <Styled.ModuleWrapper className={className} id={id}>
    {children}
  </Styled.ModuleWrapper>
)) as ModuleType

Module.Section = ModuleSection
Module.SectionsGroup = ModuleSectionsGroup
