import { Space, Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'
import { Trans } from 'react-i18next'

interface RejectedStatusDescriptionProps {
  reason: ReactNode
}

export const RejectedStatusDescription = ({ reason }: RejectedStatusDescriptionProps): ReactElement => {
  return (
    <Trans i18nKey="einvoce.status.description.rejected" values={{ reason }}>
      Your e-invoice was rejected. Contact the recepient for details.
      <Space />
      Reason:
      <Space />
      <Text variant="micro" colorVariant="secondary">
        {reason}
      </Text>
    </Trans>
  )
}
