import { TransactionOriginatorTypeGroup } from '../enums/transactionOriginatorTypeGroup'

const isOriginatorTypeGroup = (value: string): value is TransactionOriginatorTypeGroup => {
  return Object.values(TransactionOriginatorTypeGroup).includes(value as TransactionOriginatorTypeGroup)
}

export const getOriginatorTypeGroup = (originatorTypeGroup: string): TransactionOriginatorTypeGroup[] => {
  const validOriginatorTypeGroup: TransactionOriginatorTypeGroup[] = []

  for (const typeGroup of originatorTypeGroup.split(',')) {
    if (isOriginatorTypeGroup(typeGroup)) {
      validOriginatorTypeGroup.push(typeGroup)
    }
  }

  return validOriginatorTypeGroup
}
