import { TFunction } from 'i18next'
import { mixed, object, SchemaOf, string } from 'yup'

import { Account } from '@modules-deprecated/app/accounts/types'
import { Company } from '@modules-deprecated/app/companies/types'
import { TaxRate } from '@modules-deprecated/app/taxrates/types'
import { Country } from '@views/contacts/types/country'

import { FormWithAutoCompletedFields } from '../../../../types/formWithAutoCompletedFields'
import { BookkeepingTagNavItemValue } from '../../../selects/BookkeepingHeroSelect'
import { ContactType } from '../enums/contactType'
import { ModalType } from '../enums/modalType'
import { isPersonContactType } from './isPersonContactType'

export const contactFormDefaultValues = {
  autoCompletedFields: undefined,
  companyFromSearch: undefined,
  companyName: undefined,
  contactType: ContactType.Company,
  country: undefined,
  defaultBookkeepingTag: undefined,
  defaultExpenseAccount: undefined,
  defaultProductDescription: undefined,
  email: undefined,
  paymentTermsMode: undefined,
  personFirstName: undefined,
  personLastName: undefined,
  taxId: undefined,
}

interface ContactFormBasic {
  companyFromSearch?: Partial<Company>
  companyName?: string
  contactType?: ContactType
  country?: Partial<Country>
  defaultBookkeepingTag: BookkeepingTagNavItemValue | undefined
  defaultExpenseAccount?: Partial<Account>
  defaultProductDescription?: string
  email?: string
  paymentTermsMode?: Partial<TaxRate>
  personFirstName?: string
  personLastName?: string
  taxId?: string
}

export type ContactForm = ContactFormBasic & FormWithAutoCompletedFields<ContactFormBasic>

export type ContactFormSchema = SchemaOf<ContactForm>

export const getValidationSchema = (modalType: ModalType, t: TFunction): ContactFormSchema => {
  if (modalType === ModalType.Vendor) {
    return object({
      contactType: mixed<ContactType>().oneOf(Object.values(ContactType)).required(),
      companyFromSearch: object().when(['contactType', 'companyName'], {
        is: (contactType: ContactType, companyName: string | undefined) =>
          contactType === ContactType.Company && !companyName?.length,
        then: object({
          id: string().required(t('create_contact_modal.form.error.company')),
        }).required(t('create_contact_modal.form.error.company')),
        otherwise: object(),
      }),
      companyName: string(),
      country: object().when(['contactType', 'companyName'], {
        is: (contactType: ContactType, companyName: string | undefined) =>
          isPersonContactType(contactType) || !!companyName?.length,
        then: object({
          id: string().required(t('create_contact_modal.form.error.country')),
        }).required(t('create_contact_modal.form.error.country')),
        otherwise: object(),
      }),
      defaultBookkeepingTag: object().notRequired().default(undefined),
      email: string().when('contactType', {
        is: isPersonContactType,
        then: string().email(t('create_contact_modal.form.error.email_format')),
        otherwise: string(),
      }),
      taxId: string(),
      defaultExpenseAccount: object(),
      defaultProductDescription: string(),
      paymentTermsMode: object(),
      personFirstName: string().when('contactType', {
        is: isPersonContactType,
        then: string().required(t('create_contact_modal.form.error.first_name')),
        otherwise: string(),
      }),
      personLastName: string().when('contactType', {
        is: isPersonContactType,
        then: string(),
        otherwise: string(),
      }),
      autoCompletedFields: object(),
    })
  }

  return {} as ContactFormSchema
}
