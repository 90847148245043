import { TableActionsWrapper, TableBadgeLabel } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrellaOrganizationSubscriptionsFilters } from '../../context/umbrellaOrganizationSubscriptionsFiltersContext'
import { useUmbrellaOrganizationsSubscriptions } from '../../hooks/useUmbrellaOrganizationsSubscriptions'
import { OrganizationSubscriptionsSearch } from '../OrganizationSubscriptionsSearch'

export const OrganizationSubscriptionsTableActions = (): ReactElement => {
  const { t } = useTranslation()
  const { queryParams } = useUmbrellaOrganizationSubscriptionsFilters()
  const { pagination, isLoading } = useUmbrellaOrganizationsSubscriptions({
    page: queryParams.page,
    pageSize: queryParams.pageSize,
    search: queryParams.search,
  })

  return (
    <TableActionsWrapper>
      <TableBadgeLabel amount={pagination?.total} isLoading={isLoading} variant="neutral">
        {t('umbrella_subscriptions.organization_subscriptions.title')}
      </TableBadgeLabel>
      <OrganizationSubscriptionsSearch />
    </TableActionsWrapper>
  )
}
