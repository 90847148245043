import { Spacing, Text as TextComponent } from '@design-system'

import styled from '@emotion/styled'

export const SalaryFeaturesListWrapper = styled.div``

export const IllustrationWrapper = styled.figure`
  display: flex;
  margin-bottom: ${Spacing.XL};
  justify-content: center;
`

export const Illustration = styled.img`
  width: 262px;
  height: 207px;
`

export const TitleWrapper = styled.div`
  margin-bottom: ${Spacing.XXL};
`

export const TextStriked = styled(TextComponent)`
  text-decoration: line-through;
`

export const FeaturesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${Spacing.S};
`

export const FeatureItem = styled.li`
  display: flex;
  width: calc(50% - ${Spacing.L});
  margin-bottom: ${Spacing.XL};

  &:nth-of-type(2n) {
    margin-left: ${Spacing.XXL};
  }
`

export const IconWrapper = styled.div`
  display: flex;
  padding-top: ${Spacing.XXS};
  margin-right: ${Spacing.S};
`
