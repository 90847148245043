import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { updateContactEan } from '../query-api'

export interface UseUpdateContactProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface UpdateContactProps {
  contactId: string
  contactEan: string
}

export const useUpdateContact = (props?: UseUpdateContactProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: updateContact, isLoading } = useMutation(
    ({ contactId, contactEan }: UpdateContactProps) => updateContactEan(contactId, contactEan),
    {
      onError,
      onSuccess,
    },
  )

  return { updateContact, isLoading }
}
