/* eslint-disable no-redeclare */
const removeAllButLast = (string: string, token: string) => {
  const parts = string.split(token)

  if (parts.length <= 1) {
    return string
  }

  return parts.slice(0, -1).join('') + token + parts.slice(-1)
}

export function amountToNumber(amount: number, convertEmptyStringToZero?: boolean): number
export function amountToNumber(amount: string, convertEmptyStringToZero?: false): number | undefined
export function amountToNumber(amount: string | number, convertEmptyStringToZero: true): number
export function amountToNumber(amount: string | number, convertEmptyStringToZero?: boolean): number | undefined
export function amountToNumber(amount: string | number, convertEmptyStringToZero?: boolean): number | undefined {
  const value = amount.toString()

  if (value === '') {
    return convertEmptyStringToZero ? 0 : undefined
  }

  const valueOnlyDots = value.replace(/[,]/g, '.')
  const valueFormatted = removeAllButLast(valueOnlyDots, '.')
  return parseFloat(valueFormatted)
}
