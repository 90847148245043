import { TableActionsWrapper, TableBadgeLabel } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useInvitedOrganizations } from '../../hooks/useInvitedOrganizations'

export const UmbrelaOrganizationsInvitationsTableActions = (): ReactElement => {
  const { t } = useTranslation()
  const { data, isLoading } = useInvitedOrganizations()

  return (
    <TableActionsWrapper>
      <TableBadgeLabel amount={data?.length} isLoading={isLoading} variant="neutral">
        {t('umbrella_organizations.organizations_table.invitations')}
      </TableBadgeLabel>
    </TableActionsWrapper>
  )
}
