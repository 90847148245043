import { amountToDisplayValue, Radio, RadioGroup, SkeletonBox, Spacing } from '@design-system'

import React from 'react'

import { useFormContext, useWatch } from '../../../../../../../../hooks'
import { useUmbrellaOrganizationChangeSubscription } from '../../contexts/umbrellaOrganizationChangeSubscriptionContext'
import { UmbrellaChangeSubscriptionFormSchema } from '../../utils/umbrellaChangeSubscriptionFormData'
import * as Styled from './styles'

export const ProductPlansFormItem = () => {
  const { plans, isLoadingPlans } = useUmbrellaOrganizationChangeSubscription()
  const { control, FormItem } = useFormContext<UmbrellaChangeSubscriptionFormSchema>()
  const hasConsolidatedBilling = useWatch({ control, name: 'consolidatedBilling' })

  if (!hasConsolidatedBilling) {
    return null
  }

  if (hasConsolidatedBilling && isLoadingPlans) {
    return <SkeletonBox />
  }

  return (
    <Styled.ProductPlansFormItemWrapper>
      <FormItem
        name="subscriptionProductPlan"
        render={({ field: { onChange, value, ...rest } }) => (
          <RadioGroup {...rest} value={value} onChange={onChange} direction="column" spacing={Spacing.L}>
            {plans?.map((plan, i) => {
              return (
                <Radio value={plan.product_plan_key} key={i}>
                  {plan.product_plan_name} ({amountToDisplayValue(plan.product_plan_price, false, 'DKK')}{' '}
                  {plan.product_plan_period})
                </Radio>
              )
            })}
          </RadioGroup>
        )}
      />
    </Styled.ProductPlansFormItemWrapper>
  )
}
