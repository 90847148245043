import { queryClient } from '../../../../../config/queryClient'
import { QueryKeys } from '../../../../../enums/queryKeys'

export const invalidateQueriesAfterTransfer = (isUmbrella: boolean) => {
  if (isUmbrella) {
    queryClient.invalidateQueries(QueryKeys.UmbrellaOrganizations)
    return
  }

  queryClient.invalidateQueries(QueryKeys.OrganizationBookkeepers, { refetchInactive: true })
  queryClient.invalidateQueries(QueryKeys.OrganizationUsers, { refetchInactive: true })
  queryClient.invalidateQueries(QueryKeys.OrganizationSettingsUsersMembers, { refetchInactive: true })
}
