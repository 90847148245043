import styled from '@emotion/styled'

import { getInputWrapperStyle } from '../Input'
import { InputWrapperProps } from '../Input/types/inputWrapperProps'
import { InputSize } from '../Input/types/size'
import { getSizeStyle } from '../Input/utils/getSizeStyle'

type RichTextWrapperProps = InputWrapperProps

export const RichTextWrapper = styled.div<RichTextWrapperProps>`
  ${(props) => getInputWrapperStyle(props, undefined, true)};
  position: relative;
`

interface SuffixIconWrapperProps {
  inputSize: InputSize
}

export const SuffixIconWrapper = styled.div<SuffixIconWrapperProps>`
  position: absolute;
  top: 0;
  right: 0;
  ${({ inputSize }) => getSizeStyle(inputSize, true)};
`
