import { useMutation } from 'react-query'

import { deleteUmbrellaUser as deleteUmbrellaUserQuery } from '@modules-deprecated/app/umbrellas/query-api'

import { APIError } from '../../../../../../../../../utils'

interface UseDeleteUmbrellaUserProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface DeleteUmbrellaUserPayload {
  umbrellaId: string
  userId: string
}

export const useDeleteUmbrellaUser = (props?: UseDeleteUmbrellaUserProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: deleteUmbrellaUser, isLoading: isProcessing } = useMutation(
    ({ umbrellaId, userId }: DeleteUmbrellaUserPayload) => deleteUmbrellaUserQuery(umbrellaId, userId),
    {
      onError,
      onSuccess,
    },
  )

  return {
    deleteUmbrellaUser,
    isProcessing,
  }
}
