import React, { createContext, ReactElement, ReactNode, useContext } from 'react'

interface ContextState {
  withSideNavbar: boolean
}

const SideNavbarContext = createContext<ContextState | undefined>(undefined)

interface SideNavbarContextProps {
  withSideNavbar: boolean
  children?: ReactNode
}

export const SideNavbarContextProvider = ({
  withSideNavbar,
  children,
}: SideNavbarContextProps): ReactElement | null => {
  return <SideNavbarContext.Provider value={{ withSideNavbar }}>{children}</SideNavbarContext.Provider>
}

export const useSideNavbar = () => {
  const context = useContext(SideNavbarContext)

  if (!context) {
    throw new Error('SideNavbarContextProvider is missing in the module!')
  }

  return context
}
