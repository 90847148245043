import { Button, ButtonsGroup, Color, Modal, Space, Text, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useBankIntegration } from '@views/bankIntegration/contexts/bankIntegrationContext'

import { useEmberCloseModal } from '../../contexts/emberCloseModalContext'
import { ModalId } from '../../enums/modalId'
import { useAccount } from '../../hooks/useAccount'
import { BankConnection } from './elements/BankConnection'
import { BankConnectionError } from './elements/BankConnectionError'

export const BankSettingsModal = (): ReactElement => {
  const { t } = useTranslation()
  const { closeEmberModal } = useEmberCloseModal()
  const { open: openConnectionRemoveConfirmationModal } = useModal(ModalId.BankConnectionRemoveConfirmationModal)
  useModal(ModalId.BankSettingsModal, {
    onClose: () => {
      closeEmberModal()
    },
  })

  const {
    accountId,
    connectedBank,
    isLoading: isLoadingBankIntegration,
    isOrphaned,
    hasBankConnectionError,
    renewConnection,
    requiresBankReconnection,
  } = useBankIntegration()

  const { account: bankAccount, isLoading: isLoadingBankAccount } = useAccount(accountId)

  const shouldShowError = (requiresBankReconnection && connectedBank) || isOrphaned || hasBankConnectionError

  const isLoading = isLoadingBankAccount || isLoadingBankIntegration

  const handleRemoveConnection = useCallback(() => {
    openConnectionRemoveConfirmationModal()
  }, [openConnectionRemoveConfirmationModal])

  const handleRenewConnection = useCallback(() => {
    renewConnection()
  }, [renewConnection])

  return (
    <Modal id={ModalId.BankSettingsModal} forceRender size="s">
      <Modal.Header>
        <Text variant="h2">{t('bank_settings_modal.bank_settings')}</Text>
      </Modal.Header>
      <Modal.Body>
        {connectedBank && (
          <>
            <Text>
              <Trans i18nKey="bank_settings_modal.your_account_is_connected" values={{ account: bankAccount?.name }}>
                Your account
                <Text inline weight="bold" as="span">
                  {bankAccount?.name}
                </Text>{' '}
                in Billy is connected with
              </Trans>
            </Text>
            <Space size="xl" />
            <Text color={Color.Gray90}>{t('bank_settings_modal.connected_account')}</Text>
            <Space size="s" />
            <BankConnection connectedBank={connectedBank} isLoading={isLoading} />
            {shouldShowError ? (
              <>
                <Space size="s" />
                <BankConnectionError />
              </>
            ) : null}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <ButtonsGroup>
          {requiresBankReconnection && (
            <Button onClick={handleRenewConnection} disabled={isLoading}>
              {t('bank_settings_modal.renew_connection')}
            </Button>
          )}
          <Button onClick={handleRemoveConnection} disabled={isLoading} danger>
            {t('bank_settings_modal.remove_connection')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
