import React, { ReactElement } from 'react'

import { ButtonDropdown, ButtonDropdownProps } from '../../../ButtonDropdown'
import { IconButton } from '../../../IconButton'
import { NavItem } from '../../../NavList'
import { TABLE_CELL_NOT_CLICKABLE_CLASS } from '../../enums/classes'

export interface TableStaticActionsProps<T = string> extends ButtonDropdownProps<T> {
  items: NavItem<T>[]
}

export const TableStaticActions = <T,>({ items, ...rest }: TableStaticActionsProps<T>): ReactElement => {
  return (
    <ButtonDropdown
      items={items}
      dropdownClassName={TABLE_CELL_NOT_CLICKABLE_CLASS}
      placement="bottom-end"
      triggerElement={<IconButton icon="threeDots" variant="text" size="m" />}
      {...rest}
    />
  )
}
