import { useMutation } from 'react-query'

import { queryClient } from '../../../../config/queryClient'
import { QueryKeys } from '../../../../enums/queryKeys'
import { UpdateUserNotificationSettingsPayload, updateUserNotificationsSettings } from '../query-api'
import { OrganizationNotificationSettings } from '../types'

interface UserNotificationSettingsPayloadResponse {
  data: OrganizationNotificationSettings[]
}

export const useUpdateUserNotificationSettings = () => {
  const { mutate: update, ...rest } = useMutation(updateUserNotificationsSettings, {
    onSettled: () => {
      queryClient.invalidateQueries(QueryKeys.UserNotificationSettings)
    },
    onMutate: async (
      newData: UpdateUserNotificationSettingsPayload,
    ): Promise<UserNotificationSettingsPayloadResponse> => {
      await queryClient.cancelQueries([QueryKeys.UserNotificationSettings])
      const oldData = queryClient.getQueryData([
        QueryKeys.UserNotificationSettings,
      ]) as UserNotificationSettingsPayloadResponse

      let updatedData
      if (newData.organizationId) {
        updatedData = oldData.data.map((organization) =>
          organization.organizationId === newData.organizationId
            ? { ...organization, settings: { ...organization.settings, ...newData.payload } }
            : organization,
        )
      } else {
        updatedData = oldData.data.map((org) =>
          org.organizationId === null ? { ...org, settings: { ...org.settings, ...newData.payload } } : org,
        )
      }
      queryClient.setQueryData([QueryKeys.UserNotificationSettings], { data: updatedData })

      return oldData
    },
    onError: (_, newData, context) => {
      queryClient.setQueryData([QueryKeys.UserNotificationSettings], context?.data)
    },
  })

  return { update, ...rest }
}
