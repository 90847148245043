import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconApp = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-apps')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <circle fill={coloring(color)} cx="6" cy="26" r="5"></circle>
      <path
        fill={coloring(bg)}
        d="M6,32c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S9.309,32,6,32z M6,22c-2.206,0-4,1.794-4,4s1.794,4,4,4 s4-1.794,4-4S8.206,22,6,22z"
      ></path>
      <circle fill={coloring(color)} cx="6" cy="12" r="5"></circle>
      <path
        fill={coloring(bg)}
        d="M6,18c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S9.309,18,6,18z M6,8c-2.206,0-4,1.794-4,4s1.794,4,4,4 s4-1.794,4-4S8.206,8,6,8z"
      ></path>
      <circle fill={coloring(color)} cx="20" cy="26" r="5"></circle>
      <path
        fill={coloring(bg)}
        d="M20,32c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S23.309,32,20,32z M20,22c-2.206,0-4,1.794-4,4 s1.794,4,4,4s4-1.794,4-4S22.206,22,20,22z"
      ></path>
      <circle fill={coloring(color2 || color)} cx="23.441" cy="8.559" r="7.559"></circle>
      <path
        fill={coloring(bg)}
        d="M23.441,17.118c-4.719,0-8.559-3.839-8.559-8.559c0-4.719,3.84-8.559,8.559-8.559S32,3.839,32,8.559 C32,13.278,28.16,17.118,23.441,17.118z M23.441,2c-3.616,0-6.559,2.942-6.559,6.559c0,3.617,2.942,6.559,6.559,6.559 S30,12.175,30,8.559C30,4.942,27.058,2,23.441,2z"
      ></path>
    </svg>
  </Box>
)
