import { UpdateOrganizationPayload } from '@modules-deprecated/app/organization'

import { PaymentTermsMode } from '../enums/paymentTermsMode'
import { InvoiceAndProductSettingsForm } from './formData'

export const getOrganizationPayloadFromInvoiceAndProductForm = (
  invoiceAndProductFormValues: Partial<InvoiceAndProductSettingsForm>,
): Partial<UpdateOrganizationPayload> => {
  const {
    defaultInvoiceBankAccountId,
    defaultSalesAccountId,
    defaultSalesTaxRulesetId,
    defaultTaxMode,
    emailAttachmentDeliveryMode,
    invoiceNoMode,
    localeId,
    nextInvoiceNo,
    paymentTermsDays,
    paymentTermsMode,
  } = invoiceAndProductFormValues

  return {
    defaultInvoiceBankAccountId,
    defaultSalesAccountId,
    defaultSalesTaxRulesetId,
    defaultTaxMode,
    emailAttachmentDeliveryMode,
    invoiceNoMode,
    localeId,
    ...(nextInvoiceNo ? { nextInvoiceNo } : {}),
    paymentTermsDays,
    paymentTermsMode: paymentTermsMode === PaymentTermsMode.OnReceipt ? PaymentTermsMode.Net : paymentTermsMode,
  }
}
