import { ProcessingOverlay, TwoColumnsLayout } from '@components'

import React, { ReactElement } from 'react'

import { BillEditableAttachments } from '../BillEditableAttachments'
import { BillEditableForm, useBillForm } from '../BillEditableForm'

export const BillEditableLayout = (): ReactElement => {
  const { processingAction, isFetching } = useBillForm()

  return (
    <>
      {(!!processingAction || isFetching) && <ProcessingOverlay />}
      <TwoColumnsLayout fullHeight>
        <BillEditableAttachments />
        <BillEditableForm />
      </TwoColumnsLayout>
    </>
  )
}
