import React, { ReactElement } from 'react'

import { useLogo } from '../../hooks/useLogo'
import { Avatar } from '../Avatar'
import { Size } from '../Avatar/types/size'

interface LogoProps {
  logoId?: string
  size?: Size
}

export const FileAvatar = ({ logoId, size = 'm' }: LogoProps): ReactElement => {
  const { url } = useLogo(logoId)

  return <Avatar size={size} url={url} />
}
