import { Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'

import * as Styled from './styles'

interface SubscriptionPeriodToggleProps {
  subscriptionPeriod: SubscriptionPeriod
  isInUpdatePeriodState?: boolean
  onToggle?: () => void
  isDisabled?: boolean
}

export const SubscriptionPeriodToggle = ({
  subscriptionPeriod,
  isInUpdatePeriodState,
  onToggle,
  isDisabled,
}: SubscriptionPeriodToggleProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.ToggleContainer>
      <Text variant="large" weight="medium">
        {t('organization_subscription.monthly')}
      </Text>
      <Styled.Toggle checked={isInUpdatePeriodState} onChange={onToggle} disabled={isDisabled} />
      <Text variant="large" weight="medium">
        {t('organization_subscription.yearly')}
      </Text>
    </Styled.ToggleContainer>
  )
}
