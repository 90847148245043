import { ModuleLayout, Space, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { InvitationsTable } from './elements/InvitationsTable/InvitationsTable'
import { UmbrellaUsersSideActions } from './elements/UmbrellaUsersSideActions/UmbrellaUsersSideActions'
import { UmbrellaUsersTable } from './elements/UmbrellaUsersTable/UmbrellaUsersTable'
import { useInvitedUsers } from './hooks/useInvitedUsers'

export const UmbrellaUsers = (): ReactElement => {
  const { t } = useTranslation()
  const { data: invitedUsers } = useInvitedUsers()
  const shouldShowInvitedUsers = invitedUsers.length > 0

  return (
    <ModuleLayout title={t('umbrella_users.title')} sideActions={<UmbrellaUsersSideActions />}>
      <Text>{t('umbrella_users.intro')}</Text>

      {shouldShowInvitedUsers && (
        <>
          <Space size="xl" />
          <InvitationsTable />
        </>
      )}

      <Space size="xxxl" />
      <UmbrellaUsersTable />
    </ModuleLayout>
  )
}
