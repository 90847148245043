export enum Color {
  // Brand
  ElectricGreen = '#01FF94',
  DeepSeaGreen = '#002E33',
  Lavender = '#DBD4FF',
  DayGrey = '#EDf1F7',

  // Primary
  Black = '#000',
  White = '#FFF',
  Green = '#00E383',
  Yellow = '#FBDB3F',
  Red = '#D92D20',
  Purple = '#5E46D2',

  // Blues
  Blue140 = '#131C3E',
  Blue120 = '#1E267D',
  Blue110 = '#2258CE',
  Blue90 = '#5E8BEF',
  Blue60 = '#7CA2F8',
  Blue10 = '#D0E6FE',
  Blue5 = '#E4F1FF',

  // Purples
  Purple110 = '#5D4DAE',
  Purple20 = '#F8F6FF',

  // Greens
  Green140 = '#075E42',
  Green120 = '#078E64',
  Green110 = '#29B574',
  Green90 = '#32D583',
  Green60 = '#6CE9A6',
  Green20 = '#A6F4C5',
  Green10 = '#D1FADF',
  Green5 = '#E8FFF0',

  // Yellows
  Yellow220 = '#735714',
  Yellow200 = '#8F7638',
  Yellow120 = '#DCB11A',
  Yellow30 = '#FFF8B8',
  Yellow10 = '#FFF9CC',

  // Reds
  Red120 = '#951307',
  Red110 = '#C94337',
  Red100 = '#F4594E',
  Red90 = '#F07B70',
  Red10 = '#F7DDD9',
  Red5 = '#FFF8F6',

  // Grays
  Gray200 = '#26282F',
  Gray90 = '#697588',
  Gray60 = '#A7A9B4',
  Gray50 = '#D3D6DF',
  Gray40 = '#D9DCE5',
  Gray30 = '#E8E9F0',
  Gray20 = '#F5F6F9',
  Gray10 = '#F6F9FF',

  // Old Brand
  Blue = '#2766F1',
}
