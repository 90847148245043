import { Modal } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../enums/modalId'
import { AddCurrencyDefaultPaymentMethodModalContextProvider } from './contexts/addCurrencyDefaultPaymentMethodModalContext'
import { ModalBody } from './elements/ModalBody'
import { ModalFooter } from './elements/ModalFooter'

export const AddCurrencyDefaultPaymentMethodModal = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <AddCurrencyDefaultPaymentMethodModalContextProvider>
      <Modal id={ModalId.AddCurrencyDefaultPaymentMethodModal} size="s" closable>
        <Modal.Header
          title={t('settings.organization.invoice.currency_default_payment_methods_settings.add_modal.title')}
        />
        <Modal.Body>
          <ModalBody />
        </Modal.Body>
        <Modal.Footer>
          <ModalFooter />
        </Modal.Footer>
      </Modal>
    </AddCurrencyDefaultPaymentMethodModalContextProvider>
  )
}
