export enum FontSize {
  TextH1 = '28px',
  TextH2 = '22px',
  TextH3 = '18px',
  TextH4 = '11px',
  TextDisplayM = '48px',
  TextDisplayS = '36px',
  TextDisplayXS = '32px',
  TextLarge = '15px',
  TextNormal = '14px',
  TextSmall = '13px',
  TextMicro = '12px',
}
