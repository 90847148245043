import { ChartOfAccountId } from '../organization/enums/chartOfAccountId'

export type Account = {
  accountNo?: number
  bankAccountNo: string
  bankIban?: string
  bankId?: string
  bankName: string
  bankRoutingNo?: number
  bankSwift?: string
  currencyId?: string
  description: string
  group?: AccountGroup
  groupId?: string
  id: string
  isArchived: boolean
  isBankAccount: boolean
  isPaymentEnabled: boolean
  name: string
  natureId: AccountNature
  predefinedAccount?: PredefinedAccount
  publicAccountId: string
  systemRole?: string
  taxRateId?: string
  updatedTime?: string
}

export interface PublicAccount {
  id: string
  number: number
  name: string
  nature: {
    id: AccountNature
    name: string
  }
}

export interface PredefinedAccount
  extends Pick<
    Account,
    'accountNo' | 'description' | 'id' | 'isBankAccount' | 'isPaymentEnabled' | 'name' | 'natureId'
  > {
  chartOfAccountId: ChartOfAccountId
  groupId: string
  isEditable: boolean
  systemRole: string
  taxRateAbbreviation: string
}

export type AccountsState = {
  account: Account | null
  accountResolved: boolean
  accounts: Account[] | null
}

export type AccountGroup = {
  accountNo: string
  allowPaymentAmounts?: boolean
  id: string
  intervalFrom?: number
  intervalTo?: number
  name?: string
  natureId?: AccountNature
  priority?: number
  sumFrom?: string
  type?: 'group' | 'groupSum'
}

export enum AccountNature {
  Asset = 'asset',
  Equity = 'equity',
  Expense = 'expense',
  Liability = 'liability',
  Revenue = 'revenue',
}
export interface FullAccountNature {
  id: AccountNature
  reportType: 'incomeStatement' | 'balanceSheet'
  name: string
}

export type SelectAccountProps = {
  accountId?: string
  isArchived?: boolean
  isBankAccount?: boolean
  isPaymentEnabled?: boolean
  natures?: AccountNature[]
}

export type SelectorAccountType = Account & {
  id?: string
  text?: string
  isHeading?: boolean
}
