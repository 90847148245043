import { AccountsGroupsSet } from '../types'

export const getAccountsCount = (accountsGroupsSet: AccountsGroupsSet): number => {
  const { accountsInGroups } = accountsGroupsSet
  let number = 0

  for (const accountGroupId in accountsInGroups) {
    number += accountsInGroups[accountGroupId].length
  }

  return number
}
