import { BigNumber } from 'bignumber.js'

import i18n, { locales } from './i18n'

export const DEFAULT_FORMAT = {
  decimalSeparator: ',',
  groupSeparator: '.',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
}

BigNumber.config({ FORMAT: DEFAULT_FORMAT })

export const parse = function (value: string, locale: string = locales[i18n.language]) {
  if (locale === locales.da) {
    return new BigNumber(String(value).replace(/\./g, '').replace(/,/g, '.'))
  }

  return new BigNumber(String(value).replace(/,/g, ''))
}

export type LocalizeNumberConfigProps = {
  decimals?: number
  locale?: string
  groupSeparator?: boolean
}

export const localizeBigNumber = (
  bigNumber: any,
  { decimals = 2, locale = locales[i18n.language], groupSeparator = true }: LocalizeNumberConfigProps = {},
) => {
  const fmt = {
    decimalSeparator: locale === locales.da ? ',' : '.',
    groupSeparator: locale === locales.da ? '.' : ',',
    groupSize: groupSeparator ? 3 : 0,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
  }

  return bigNumber.toFormat(decimals, fmt)
}

export const saveParse = (num: number): any => {
  const temp = new BigNumber(num)
  if (temp.isNaN()) {
    return new BigNumber(0)
  }
  return temp
}

export default BigNumber
