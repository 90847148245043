import { Modal, ModalProps } from '@design-system'

import { useCallback, useMemo } from 'react'
import ReactCSSTransitionReplace from 'react-css-transition-replace'
import { useTranslation } from 'react-i18next'

import { useModalContentToggler } from '../../contexts/modalContentTogglerContext'

export const ToggleModal = ({ ...modalProps }: ModalProps) => {
  const { activeState, activeStateModalData, prevState, setActiveState } = useModalContentToggler()
  const { t } = useTranslation()

  const backwardsNavigationState = useMemo(() => {
    return activeStateModalData?.backwardsNavigationStateId || prevState
  }, [activeStateModalData?.backwardsNavigationStateId, prevState])

  const shouldDisplayBackButton =
    !!activeStateModalData?.withBackwardsNavigation &&
    !!backwardsNavigationState &&
    backwardsNavigationState !== activeState

  const handleBackButtonClick = useCallback(() => {
    setActiveState(backwardsNavigationState)
  }, [backwardsNavigationState, setActiveState])

  return (
    <Modal {...modalProps}>
      <Modal.Header
        onBackButtonClick={handleBackButtonClick}
        title={t(activeStateModalData?.headerTKey as string)}
        withBackButton={shouldDisplayBackButton}
      />
      <Modal.Body>
        <ReactCSSTransitionReplace transitionName="fade-wait" transitionEnterTimeout={500} transitionLeaveTimeout={500}>
          <div key={activeStateModalData?.id}>{activeStateModalData?.modalBody}</div>
        </ReactCSSTransitionReplace>
      </Modal.Body>
      <Modal.Footer>{activeStateModalData?.modalFooter}</Modal.Footer>
    </Modal>
  )
}
