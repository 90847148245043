import { ContactBalance, ContactBalancePagination, useContactBalanceListFilters } from '@features/contactBalance'

import { useFetchDebtors } from './useFetchDebtors'

interface UseDebtorsResponse {
  debtors: ContactBalance[]
  isLoading?: boolean
  pagination?: ContactBalancePagination
}

export const useDebtors = (): UseDebtorsResponse => {
  const [{ acceptLanguage, date, searchQuery, sortDirection, sortProperty }] = useContactBalanceListFilters()
  // we don't pass "page" and "pageSize" to avoid refetching data because of all list is already fetched
  // BE doesn't support this properties
  const { data, isLoading, pagination } = useFetchDebtors({
    acceptLanguage,
    date,
    searchQuery,
    sortDirection,
    sortProperty,
  })

  return {
    debtors: data,
    isLoading,
    pagination,
  }
}
