import React, { ReactElement, useState } from 'react'

import { FilesPreviewContent, FilesPreviewContentProps } from './FilesPreviewContent'
import { FullScreenPreview } from './FullScreenPreview'

type FilesPreviewProps = Omit<FilesPreviewContentProps, 'onImageZoomClick'>

export const FilesPreview = (props: FilesPreviewProps): ReactElement => {
  const [isFullScreenMode, setIsFullScreenMode] = useState(false)

  const handleImageZoomClick = () => {
    setIsFullScreenMode(true)
  }

  const handleFullScreenCloseClick = () => {
    setIsFullScreenMode(false)
  }

  return (
    <>
      <FilesPreviewContent onImageZoomClick={handleImageZoomClick} {...props} />
      {props.withModalZoom && (
        <FullScreenPreview onClose={handleFullScreenCloseClick} files={props.files} visible={isFullScreenMode} />
      )}
    </>
  )
}
