import { RadioButtonSectionGroup, RadioButtonSectionWithHeader } from '@components'
import { Button, ButtonsGroup, Modal, Space, Spacing, Text, useModal } from '@design-system'

import React, { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../config/queryClient'
import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { ModalId } from '../../../../enums/modalId'
import { QueryKeys } from '../../../../enums/queryKeys'
import { useSegment } from '../../../../hooks'
import { useCancelSendingEInvoice } from '../../hooks/useCancelSendingEInvoice'
import { useInvoice } from '../../hooks/useInvoice'
import { RadioValue } from './enums/radioValue'

interface VoidInvoiceModalProps {
  invoiceId: string
  forceRender?: boolean
  onModalClose?: () => void
  onInvoiceVoid?: () => void
  onVoidAndDuplicateInvoice?: () => void
}

export const VoidInvoiceModal = ({
  invoiceId,
  forceRender,
  onModalClose,
  onInvoiceVoid,
  onVoidAndDuplicateInvoice,
}: VoidInvoiceModalProps): ReactElement => {
  const [checkedValue, setCheckedValue] = useState<RadioValue | undefined>(undefined)
  const { t } = useTranslation()
  const { track } = useSegment()
  const { navigate } = useEmberRouter()
  const { data: invoiceData } = useInvoice(invoiceId)
  const invoiceDelivery = invoiceData?.invoice?.delivery
  const { cancelSendingEInvoice } = useCancelSendingEInvoice({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.InvoiceLogs)
    },
  })

  const handleModalClose = useCallback(() => {
    onModalClose?.()
  }, [onModalClose])

  const { close } = useModal(ModalId.VoidInvoiceModal, { onClose: handleModalClose })

  const isContinueButtonDisabled = !checkedValue
  const isVoidAndDuplicateInvoiceEnabled = !!onVoidAndDuplicateInvoice

  const handleRadioChange = useCallback((value: RadioValue | undefined) => {
    setCheckedValue(value)
  }, [])

  const handleVoidInvoice = useCallback(() => {
    onInvoiceVoid?.()

    if (invoiceDelivery?.id) {
      cancelSendingEInvoice({ deliveryId: invoiceDelivery.id })
    }
  }, [cancelSendingEInvoice, invoiceDelivery?.id, onInvoiceVoid])

  const handleVoidAndDuplicateInvoice = useCallback(() => {
    onVoidAndDuplicateInvoice?.()

    if (invoiceDelivery?.id) {
      cancelSendingEInvoice({ deliveryId: invoiceDelivery.id })
    }
  }, [cancelSendingEInvoice, invoiceDelivery?.id, onVoidAndDuplicateInvoice])

  const handleCreateCreditNote = useCallback(() => {
    track('XXX - Jesper - Action Taken', {
      context: 'invoice_viewed',
      action: 'create credit note',
    })
    navigate(EmberRoute.InvoiceCreditNote)
  }, [navigate, track])

  const handleCancelButtonClick = useCallback(() => {
    close()
  }, [close])

  const handleSubmitButtonClick = useCallback(() => {
    if (checkedValue === RadioValue.CreditNote) {
      handleCreateCreditNote()
    } else if (checkedValue === RadioValue.VoidInvoice) {
      handleVoidInvoice()
    } else if (checkedValue === RadioValue.VoidAndDuplicateInvoice) {
      handleVoidAndDuplicateInvoice()
    }

    close()
  }, [checkedValue, close, handleCreateCreditNote, handleVoidAndDuplicateInvoice, handleVoidInvoice])

  return (
    <Modal id={ModalId.VoidInvoiceModal} size="s" forceRender={forceRender} closable>
      <Modal.Header title={t('void_invoice_modal.title')} />
      <Modal.Body>
        {isVoidAndDuplicateInvoiceEnabled && (
          <>
            <Text>{t('void_invoice_modal.paragraph')}</Text>
            <Space size="xl" />
          </>
        )}
        <RadioButtonSectionGroup direction="column" spacing={Spacing.XL}>
          {isVoidAndDuplicateInvoiceEnabled && (
            <RadioButtonSectionWithHeader<RadioValue>
              description={t('void_invoice_modal.void_and_duplicate_invoice.description')}
              heading={t('void_invoice_modal.void_and_duplicate_invoice.heading')}
              icon="paperWithPencil"
              label={t('void_invoice_modal.void_and_duplicate_invoice.label')}
              onChange={handleRadioChange}
              value={RadioValue.VoidAndDuplicateInvoice}
            />
          )}
          {!isVoidAndDuplicateInvoiceEnabled && (
            <RadioButtonSectionWithHeader<RadioValue>
              description={t('void_invoice_modal.credit_note.description')}
              heading={t('void_invoice_modal.credit_note.heading')}
              icon="envelope"
              label={t('void_invoice_modal.credit_note.label')}
              onChange={handleRadioChange}
              value={RadioValue.CreditNote}
            />
          )}
          <RadioButtonSectionWithHeader<RadioValue>
            description={t('void_invoice_modal.void_invoice.description')}
            heading={t(
              isVoidAndDuplicateInvoiceEnabled
                ? 'void_invoice_modal.void_invoice.heading.duplicate'
                : 'void_invoice_modal.void_invoice.heading',
            )}
            icon={isVoidAndDuplicateInvoiceEnabled ? 'trash' : 'invoice'}
            label={t('void_invoice_modal.void_invoice.label')}
            onChange={handleRadioChange}
            value={RadioValue.VoidInvoice}
          />
        </RadioButtonSectionGroup>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={handleCancelButtonClick}>
            {t('void_invoice_modal.back')}
          </Button>
          <Button disabled={isContinueButtonDisabled} onClick={handleSubmitButtonClick}>
            {t('void_invoice_modal.continue')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
