import { Modal } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../enums/modalId'
import { TaxMetaField } from '../../../../types/taxMetaField'
import { CreateOrEditAdditionalFieldModalContextProvider } from './context/createOrEditAdditionalFieldModalContext'
import { CreateOrEditAdditionalFieldForm } from './elements/CreateOrEditAdditionalFieldForm'
import { ModalFooter } from './elements/ModalFooter'

interface CreateOrEditAdditionalFieldModalProps {
  additionalField?: TaxMetaField
}

export const CreateOrEditAdditionalFieldModal = ({
  additionalField,
}: CreateOrEditAdditionalFieldModalProps): ReactElement => {
  const { t } = useTranslation()
  const isEditMode = !!additionalField

  return (
    <CreateOrEditAdditionalFieldModalContextProvider additionalField={additionalField}>
      <Modal id={ModalId.CreateOrEditAdditionalFieldModal} size="s" closable autofocusOnInput>
        <Modal.Header
          title={t(
            isEditMode
              ? 'settings.organization.tax_return.modals.edit_field.title'
              : 'settings.organization.tax_return.modals.create_field.title',
          )}
        />
        <Modal.Body>
          <CreateOrEditAdditionalFieldForm />
        </Modal.Body>
        <Modal.Footer>
          <ModalFooter />
        </Modal.Footer>
      </Modal>
    </CreateOrEditAdditionalFieldModalContextProvider>
  )
}
