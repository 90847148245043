import { Color, DefaultValue, MODULE_LAYOUT_BOTTOM_SPACE, Module as ModuleComponent } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const BillViewWrapper = styled.div`
  margin-bottom: ${MODULE_LAYOUT_BOTTOM_SPACE};
`

interface ModuleProps {
  outlineColor?: Color
}

export const Module = styled(ModuleComponent)<ModuleProps>`
  ${({ outlineColor }) =>
    outlineColor &&
    css`
      position: relative;

      /* Colorized border around the module */
      &:after {
        content: '';
        border: 1px solid ${outlineColor};
        border-radius: ${DefaultValue.BorderRadius};
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
      }
    `};
`
