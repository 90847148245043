import { DraftBlockType, RichUtils } from 'draft-js'
import React, { ReactElement, useCallback } from 'react'

import { Button } from '../../../Button'
import { ButtonsGroup } from '../../../ButtonsGroup'
import { BlockTypeConfig } from '../../constants/blockTypes'
import { useRichTextContext } from '../../contexts/richTextContext'

interface InlineStyleControlsProps {
  blockTypes: BlockTypeConfig[]
}

export const BlockStyleControls = ({ blockTypes }: InlineStyleControlsProps): ReactElement => {
  const { editorState, disabled, setEditorState } = useRichTextContext()
  const selection = editorState.getSelection()
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey())?.getType()

  const handleToggleBlockType = useCallback(
    (type: DraftBlockType) => () => {
      setEditorState(RichUtils.toggleBlockType(editorState, type))
    },
    [editorState, setEditorState],
  )

  return (
    <ButtonsGroup merged>
      {blockTypes.map(({ label, type }) => (
        <Button
          active={type === blockType}
          forceSquareRatio={label.length <= 2}
          key={label}
          onClick={handleToggleBlockType(type)}
          size="m"
          variant="secondary"
          disabled={disabled}
        >
          {label}
        </Button>
      ))}
    </ButtonsGroup>
  )
}
