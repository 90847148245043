import React from 'react'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconToDoList = ({ color = '#fff', width = '16px', height = '16px', isOutlined, ...rest }: IconProps) => (
  <i className={reactClass('icon-to-do-list')} {...rest}>
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 0H2.66667C1.15556 0 0 1.04 0 2.4V13.6C0 14.96 1.15556 16 2.66667 16H13.3333C14.8444 16 16 14.96 16 13.6V2.4C16 1.04 14.8444 0 13.3333 0ZM14.2222 13.6C14.2222 14.08 13.8667 14.4 13.3333 14.4H2.66667C2.13333 14.4 1.77778 14.08 1.77778 13.6V2.4C1.77778 1.92 2.13333 1.6 2.66667 1.6H13.3333C13.8667 1.6 14.2222 1.92 14.2222 2.4V13.6Z"
        fill={coloring(color)}
      />
      <path
        d="M6.27762 3.77L5.10762 4.94L4.83762 4.67C4.47762 4.31 3.93762 4.31 3.57762 4.67C3.21762 5.03 3.21762 5.57 3.57762 5.93L4.47762 6.83C4.83762 7.19 5.37762 7.19 5.73762 6.83L7.53762 5.03C7.89762 4.67 7.89762 4.13 7.53762 3.77C7.17762 3.41 6.63762 3.41 6.27762 3.77Z"
        fill={coloring(color)}
      />
      <path
        d="M6.27762 9.1699L5.10762 10.3399L4.83762 10.0699C4.47762 9.7099 3.93762 9.7099 3.57762 10.0699C3.21762 10.4299 3.21762 10.9699 3.57762 11.3299L4.47762 12.2299C4.83762 12.5899 5.37762 12.5899 5.73762 12.2299L7.53762 10.4299C7.89762 10.0699 7.89762 9.5299 7.53762 9.1699C7.17762 8.8099 6.63762 8.8099 6.27762 9.1699Z"
        fill={coloring(color)}
      />
      <path
        d="M9.41797 5.5H12.0511"
        stroke={coloring(color)}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M9.41797 10.5L12.0753 10.5"
        stroke={coloring(color)}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
    </svg>
  </i>
)
