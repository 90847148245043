import { User } from '../types/user'

export const getSortedUsers = (users: User[]): User[] => {
  return [...users].sort(({ email: emailA, name: nameA }, { email: emailB, name: nameB }) => {
    if (nameA && nameB) {
      return nameA.localeCompare(nameB)
    }

    if (emailA && emailB) {
      return emailA.localeCompare(emailB)
    }

    return 0
  })
}
