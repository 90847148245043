import React, { ReactElement } from 'react'

import { Color } from '../../enums/color'
import { amountToDisplayValue } from '../../utils/amountToDisplayValue'
import { amountToDisplayValueSplitted } from '../../utils/amountToDisplayValueSplitted'
import { TextVariant } from '../Text'
import { useScaleFontSizes } from './hooks/useScaleFontSizes'
import * as Styled from './styles'

export interface AmountValueProps {
  amount: number
  currencyId?: string
  color?: Color
  withScaling?: boolean
  shortFormat?: boolean
  alignCenter?: boolean
  textVariant?: TextVariant
}

export const AmountValue = ({
  amount,
  currencyId,
  color,
  withScaling = true,
  textVariant = 'h2',
  shortFormat,
  alignCenter = false,
  ...rest
}: AmountValueProps): ReactElement => {
  const { valueFontSize, currencyFontSize, containerRef, valueRef, currencyRef } = useScaleFontSizes(withScaling)
  let value = ''
  let decimalSeparator = ''
  let decimalValue = ''

  if (shortFormat) {
    value = amountToDisplayValue(amount, true)
  } else {
    const valueSplitted = amountToDisplayValueSplitted(amount)
    value = valueSplitted.value
    decimalSeparator = valueSplitted.decimalSeparator
    decimalValue = valueSplitted.decimalValue
  }

  const showDecimalValue = !shortFormat && decimalValue !== '00'

  return (
    <Styled.AmountValueWrapper {...rest} ref={containerRef} alignCenter={alignCenter} data-testid="amountValue">
      <Styled.ScaledText color={color} variant={textVariant} ref={valueRef} fontSize={valueFontSize}>
        {value}
      </Styled.ScaledText>

      {(showDecimalValue || currencyId) && (
        <Styled.ScaledText
          alignment="center"
          color={color || Color.Gray90}
          variant="normal"
          weight="regular"
          ref={currencyRef}
          fontSize={currencyFontSize}
        >
          {showDecimalValue && `${decimalSeparator}${decimalValue}`}
          {currencyId && <Styled.CurrencyText>{currencyId}</Styled.CurrencyText>}
        </Styled.ScaledText>
      )}
    </Styled.AmountValueWrapper>
  )
}
