import uniqueId from 'lodash/uniqueId'
import React, { createContext, ReactElement, ReactNode, useContext, useMemo } from 'react'

import { FormLayout } from '../types/formLayout'

interface ContextState {
  name: string
  layout: FormLayout
}

const defaultValue: ContextState = {
  name: 'unknown',
  layout: {},
}

const FormContext = createContext(defaultValue)

interface FormContextProps {
  children?: ReactNode
  formName?: string
  formLayout?: FormLayout
}

export const FormContextProvider = ({ formName, formLayout, children }: FormContextProps): ReactElement => {
  const name = formName || uniqueId('form-')
  const value = useMemo(
    () => ({
      name,
      layout: formLayout || {},
    }),
    [name, formLayout],
  )

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}

export const useForm = () => useContext(FormContext)
