import React from 'react'

export const Rocket = () => {
  return (
    <svg width="71" height="65" viewBox="0 0 71 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.0495193 49.4169C0.202207 55.3479 3.9976 60.5129 9.42894 62.0449C9.77794 62.1543 10.1488 62.2418 10.4978 62.3075C14.6858 63.2048 18.9829 62.592 23.1927 61.8041C27.4025 61.0163 31.6123 60.0314 35.8658 60.1627C45.4633 60.4691 56.3696 66.8597 65.4001 60.0971C72.271 54.954 73.209 41.5381 65.9017 36.3075C62.5426 33.9001 58.6818 33.0684 55.8461 29.8732C53.7303 27.4876 51.8544 25.1459 48.7789 24.0297C45.6378 22.8917 42.2133 23.22 39.2249 24.6425C37.7417 25.3429 36.3893 26.3277 34.9933 27.1594C29.6274 30.3765 24.3706 28.2317 18.612 28.2755C11.5884 28.3193 4.84829 33.1122 1.42371 40.028C0.420333 42.0415 -0.0595437 44.3176 0.00589412 46.5937L0.0495193 49.4169Z"
        fill="#BBE3FF"
      />
      <path
        d="M2.05627 65.0003C2.57978 62.4397 7.2913 60.6013 10.4759 61.6956C10.4759 61.6956 11.981 59.7696 13.3116 61.0171C14.6421 56.9902 20.8151 56.9902 24.0652 59.0912C24.0652 59.0912 25.5703 57.3404 26.7263 57.9969C26.9008 56.1585 29.3875 54.1451 32.7248 55.1518V44.0996H39.6612V58.5003C41.4062 57.3185 43.5875 58.4127 44.0892 60.0104C45.0925 59.2663 46.1832 60.6013 46.1832 60.6013C49.2587 57.6686 55.7807 58.4346 57.4385 61.608C58.9435 61.0171 60.0996 62.7023 60.0996 62.7023C64.5276 61.8707 67.8431 65.0003 67.8431 65.0003H2.05627Z"
        fill="white"
      />
      <path
        d="M38.0689 38.8906H34.339C28.2096 42.3267 34.7098 51.6937 36.1712 52.8974H36.2367C37.6981 51.6937 44.1983 42.3048 38.0689 38.8906Z"
        fill="#FFDD00"
      />
      <path
        d="M36.2148 49.1772C34.9279 47.4045 33.052 43.9903 33.2701 41.4079C33.3792 40.2917 33.8373 39.4819 34.7534 38.8691H37.6545C38.5706 39.4819 39.0505 40.2917 39.1377 41.4079C39.3777 43.9903 37.5018 47.4045 36.2148 49.1772Z"
        fill="white"
      />
      <path
        d="M43.871 23.3086C47.5574 25.3439 52.1162 34.8641 45.5724 43.1369C45.0707 43.7716 44.0455 43.3995 44.0673 42.5897C44.1982 38.5847 42.6495 36.5493 40.9481 36.5493C38.9414 36.5274 43.871 23.3086 43.871 23.3086Z"
        fill="#226ACF"
      />
      <path
        d="M28.4277 23.3086C24.7414 25.3439 20.1826 34.8641 26.7263 43.1369C27.228 43.7716 28.2532 43.3995 28.2314 42.5897C28.1005 38.5847 29.6492 36.5493 31.3506 36.5493C33.3574 36.5274 28.4277 23.3086 28.4277 23.3086Z"
        fill="#226ACF"
      />
      <path d="M39.5522 36.373H32.594V39.2619H39.5522V36.373Z" fill="#05A9FF" />
      <path
        d="M36.084 0.0214844H35.975C24.4361 11.2269 26.6174 27.7286 30.8272 37.2706H41.21C45.4416 27.7067 47.6229 11.2269 36.084 0.0214844Z"
        fill="#2C84FB"
      />
      <path
        d="M31.0017 6.54337H41.0791C39.814 4.24539 38.1781 2.03495 36.1059 0.0214844H35.9968C33.881 2.03495 32.245 4.24539 31.0017 6.54337Z"
        fill="#226ACF"
      />
      <path
        d="M39.7741 21.7781C41.8441 19.7012 41.8441 16.334 39.7741 14.2571C37.7042 12.1802 34.3482 12.1802 32.2782 14.2571C30.2083 16.334 30.2083 19.7012 32.2782 21.7781C34.3482 23.8549 37.7042 23.8549 39.7741 21.7781Z"
        fill="#05A9FF"
      />
      <path
        d="M39.0955 21.0952C40.7906 19.3944 40.7906 16.6368 39.0955 14.936C37.4004 13.2352 34.652 13.2352 32.9569 14.936C31.2618 16.6368 31.2617 19.3944 32.9569 21.0952C34.652 22.796 37.4004 22.796 39.0955 21.0952Z"
        fill="#E9F5FF"
      />
      <path
        d="M29.9764 35.125C30.26 35.8691 30.5436 36.5913 30.8489 37.2698H41.2317C41.5371 36.5913 41.8207 35.8691 42.1042 35.125H29.9764Z"
        fill="#05A9FF"
      />
      <path
        d="M38.7452 20.2215C38.6797 20.2215 38.6143 20.1996 38.5707 20.1777C38.3743 20.0683 38.2871 19.8276 38.3743 19.6306C38.5488 19.2585 38.6579 18.8427 38.6579 18.4269C38.6579 17.4858 38.1998 16.6323 37.4146 16.107C37.2183 15.9757 37.1746 15.7131 37.3055 15.538C37.4364 15.341 37.6982 15.2973 37.8727 15.4286C38.876 16.107 39.4868 17.2232 39.4868 18.4269C39.4868 18.974 39.3777 19.4993 39.1378 20.0027C39.0287 20.134 38.8978 20.2215 38.7452 20.2215Z"
        fill="white"
      />
      <path
        opacity="0.19"
        d="M36.0622 0H35.9532C35.6478 0.306397 35.3424 0.612795 35.0588 0.919192C45.5943 12.1027 43.4131 27.9478 39.2905 37.2492H41.1882C45.4198 27.7071 47.6011 11.2054 36.0622 0Z"
        fill="#006CD3"
      />
      <path
        d="M36.0184 58.7196C36.0184 58.7196 36.3892 60.6017 38.2651 60.9738C38.2651 60.9738 36.3892 61.3458 36.0184 63.228C35.6476 61.3458 33.7717 60.9738 33.7717 60.9738C35.6694 60.6017 36.0184 58.7196 36.0184 58.7196ZM33.7063 56.5967C33.7063 56.5967 34.7315 56.7937 34.9278 57.8223C35.1241 56.7937 36.1493 56.5967 36.1493 56.5967C35.1241 56.3997 34.9278 55.3711 34.9278 55.3711C34.906 55.393 34.7097 56.3997 33.7063 56.5967ZM36.7601 57.4721C36.7601 57.4721 37.4799 57.6034 37.6107 58.3256C37.7416 57.6034 38.4614 57.4721 38.4614 57.4721C37.7416 57.3408 37.6107 56.6186 37.6107 56.6186C37.6107 56.6186 37.4581 57.3408 36.7601 57.4721Z"
        fill="#FFDD00"
      />
    </svg>
  )
}
