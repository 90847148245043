import { css } from '@emotion/core'
import React, { useCallback } from 'react'
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { Box } from 'rebass'

import { IconCheck } from '../../../components-deprecated/Icons/icons'
import { reactClass } from '../../../utils'
import { reconcileMatch } from '../action-creators'
import { BankLine, BillyTransaction } from '../types'
import { cleanId, isReconcilable } from '../utils'
import { DroppableContainer } from './DroppableContainer'
import { MatchDifference } from './MatchDifference'
import { MatchedPlaceholder } from './MatchedPlaceholder'
import { MatchedTransaction } from './MatchedTransaction'
import { MatchListRowActionOverlay as ActionOverlay } from './MatchListRowActionOverlay'
import { Placeholder } from './Placeholder'
import { DroppableAreaProps } from './types'

export type DroppableMatchedTransactionsProps = DroppableAreaProps<BillyTransaction> & {
  bankLines: BankLine[]
  isApproved: boolean
}

export const DroppableMatchedTransactions = ({
  bankLines = [],
  collection = [],
  difference = { amount: 0, types: [] },
  id: matchIdReference,
  type,
  isApproved,
}: DroppableMatchedTransactionsProps) => {
  const hasDifference = !!difference?.differenceAmount
  const dispatch = useDispatch()
  const [, matchId] = cleanId(matchIdReference)

  const approveMatch = useCallback(() => {
    dispatch(reconcileMatch(matchId))
  }, [dispatch, matchId])

  const canBeApproved = isReconcilable({
    isApproved,
    bankLines,
    id: matchId,
    matchedBillyTransactions: collection,
    difference,
  })

  const droppableChild = useCallback(
    (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
      <DroppableContainer
        flex="auto"
        className={reactClass('droppable-matched-transactions')}
        ref={provided.innerRef}
        {...provided.droppableProps}
        {...snapshot}
        css={css`
          position: relative;
          &:not(:hover) .${reactClass('action-overlay')}.${reactClass('hoverable')} {
            max-width: 0;
          }
        `}
      >
        {collection.map((billyTransaction, index) => (
          <MatchedTransaction
            key={billyTransaction.id}
            index={index}
            isApproved={isApproved}
            billyTransaction={billyTransaction}
            {...snapshot}
          />
        ))}
        {!collection.length && <MatchedPlaceholder matchId={matchId} difference={difference} {...snapshot} />}
        {snapshot.draggingFromThisWith && <Placeholder id="placeholder-from-origin" index={0} mb="0px" />}
        <Box display="none">{provided.placeholder}</Box>
        {hasDifference && <MatchDifference difference={difference} matchId={matchId} />}
        {canBeApproved && !snapshot.draggingFromThisWith && (
          <ActionOverlay
            className="reconcile-match"
            hoverable={true}
            placement={'right'}
            m={'0 -1px'}
            css={css`
              top: 1px;
              & > div {
                margin-top: 0;
              }
            `}
          >
            <Box
              mt={'4px'}
              mr={'10px'}
              css={css`
                i {
                  cursor: pointer;
                }
              `}
            >
              <IconCheck width={15} height={15} color={'#66CC6E'} onClick={approveMatch} />
            </Box>
          </ActionOverlay>
        )}
      </DroppableContainer>
    ),
    [approveMatch, canBeApproved, collection, difference, hasDifference, isApproved, matchId],
  )

  return (
    <Droppable isDropDisabled={isApproved} droppableId={matchIdReference} type={type}>
      {droppableChild}
    </Droppable>
  )
}
