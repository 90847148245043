import { Color, Text } from '@design-system'

import React, { Fragment, ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { getClassName } from '../../../../utils/getClassName'
import { Translate } from '../../../Translate'
import { globalSearchShortcutLabel } from '../../constants/globalSearchShortcutLabel'
import * as Styled from './styles'

export const GlobalSearchTips = (): ReactElement => {
  const { t } = useTranslation()
  const shortcutSplitted = globalSearchShortcutLabel.split('+')

  return (
    <Styled.GlobalSearchTipsWrapper className={getClassName('global-search-tips')}>
      <Styled.Header>
        <Styled.TitleWrapper>
          <Styled.TitleIcon icon="bulb" color={Color.White} />
          <Text variant="h4" color={Color.White}>
            <Translate value="global_search.tips.title" />
          </Text>
        </Styled.TitleWrapper>
      </Styled.Header>

      <Styled.Content>
        <Text color={Color.White}>
          <Translate value="global_search.tips.open_with" />{' '}
          {shortcutSplitted.map((key, index) => (
            <Fragment key={key}>
              {index > 0 && index < shortcutSplitted.length ? ' + ' : ''}
              <Styled.ShortcutKey label={key} color={Color.White} />
            </Fragment>
          ))}
        </Text>
      </Styled.Content>

      <Styled.Footer>
        <Text color={Color.White}>
          <Trans i18nKey="global_search.tips.quick_filters">
            Use quick filters like{' '}
            <Styled.ShortcutKey label={t('global_search.go_to').toLowerCase()} color={Color.White} /> to go to specific
            page or <Styled.ShortcutKey label={t('global_search.new').toLowerCase()} color={Color.White} /> to create
            new invoice, bill and more...
          </Trans>
        </Text>
      </Styled.Footer>
    </Styled.GlobalSearchTipsWrapper>
  )
}
