import React, { ReactElement, useEffect, useRef } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Timeout } from '../../../../../../types/timeout'
import { CreateOrganizationForm } from '../../utils/formData'
import * as Styled from './styles'

const AUTO_SCROLL_TIMEOUT = 250

interface OrganizationManualDataProps {
  formControl: Control<CreateOrganizationForm>
  disabled?: boolean
  visible?: boolean
}

export const OrganizationManualData = ({
  formControl,
  disabled,
  visible,
}: OrganizationManualDataProps): ReactElement => {
  const { t } = useTranslation()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const scrollTimeoutRef = useRef<Timeout>()
  const selectorKey = visible?.toString() // forcing rerenders to recalculate trigger positions for popper

  useEffect(() => {
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current)
    }

    if (visible) {
      const firstInput = wrapperRef.current?.querySelector('input') as HTMLInputElement | undefined
      firstInput && firstInput.focus()

      scrollTimeoutRef.current = setTimeout(() => {
        firstInput && firstInput.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, AUTO_SCROLL_TIMEOUT)
    }
  }, [visible])

  return (
    <Styled.OrganizationManualData ref={wrapperRef} visible={visible}>
      <Styled.InputsSection>
        <Styled.Input
          formControl={formControl}
          name="orgName"
          label={t('signup_guide.create_org.form.company_name.label')}
          placeholder={t('signup_guide.create_org.form.company_name.placeholder')}
          disabled={disabled}
          inputSize="xl"
        />
      </Styled.InputsSection>

      <Styled.InputsSection>
        <Styled.Input
          formControl={formControl}
          name="orgStreet"
          label={t('signup_guide.create_org.form.address.label')}
          placeholder={t('signup_guide.create_org.form.address.placeholder')}
          disabled={disabled}
          inputSize="xl"
          autoComplete="disable-autocomplete"
        />

        <Styled.DualInputsWrapper>
          <Styled.Input
            formControl={formControl}
            name="orgZipcode"
            placeholder={t('signup_guide.create_org.form.postcode.placeholder')}
            disabled={disabled}
            inputSize="xl"
            autoComplete="disable-autocomplete"
          />
          <Styled.Input
            formControl={formControl}
            name="orgCity"
            placeholder={t('signup_guide.create_org.form.city.placeholder')}
            disabled={disabled}
            inputSize="xl"
            autoComplete="disable-autocomplete"
          />
        </Styled.DualInputsWrapper>
      </Styled.InputsSection>

      <Styled.DualInputsWrapper key={selectorKey}>
        <Styled.CountrySelector
          formControl={formControl}
          name="orgCountry"
          label={t('signup_guide.create_org.form.country.label')}
          disabled={disabled}
          listWidth="100%"
          inputSize="xl"
          listPlacement="top-start"
          width="100%"
        />
        <Styled.CurrencySelector
          formControl={formControl}
          name="orgCurrency"
          label={t('signup_guide.create_org.form.currency.label')}
          disabled={disabled}
          listWidth="100%"
          inputSize="xl"
          listPlacement="top-start"
          width="100%"
        />
      </Styled.DualInputsWrapper>
    </Styled.OrganizationManualData>
  )
}
