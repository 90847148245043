import { notify } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { UmbrellaRolePayload } from '@modules-deprecated/app/umbrellas/query-api'
import { UmbrellaRole } from '@modules-deprecated/app/umbrellas/types/umbrellaRole'

import { queryClient } from '../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { FormComponent, useForm } from '../../../../../hooks'
import { APIError } from '../../../../../utils'
import {
  CreateOrEditRoleForm,
  getDefaultValues,
  getValidationSchema,
} from '../elements/CreateOrEditUmbrellaRoleModal/utils/formData'
import { useCreateUmbrellaRole, UseCreateUmbrellaRoleProps } from '../hooks/useCreateUmbrellaRole'
import { useUmbrellaRoles } from '../hooks/useUmbrellaRoles'
import { useUpdateUmbrellaRole } from '../hooks/useUpdateUmbrellaRole'

interface ContextState {
  Form: FormComponent
  selectedRole?: UmbrellaRole
  submitForm: () => void
}

const CreateOrEditUmbrellaRoleContext = createContext<ContextState | undefined>(undefined)

interface CreateOrEditUmbrellaRoleContextProps {
  children?: ReactNode
  onRoleUpdated?: () => void
  roleId?: string
}

export const CreateOrEditUmbrellaRoleContextProvider = ({
  onRoleUpdated,
  roleId,
  children,
}: CreateOrEditUmbrellaRoleContextProps): ReactElement => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { data: umbrellaRolesData } = useUmbrellaRoles()
  const selectedRole = useMemo(() => {
    return umbrellaRolesData.find((umbrellaRole) => umbrellaRole.id === roleId)
  }, [umbrellaRolesData, roleId])

  const apiActions: UseCreateUmbrellaRoleProps = {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.UmbrellaRoles)
      onRoleUpdated?.()
    },
    onError: (error: APIError | undefined) => {
      notify({
        id: NotificationKeys.UmbrellaRoleUpdate,
        message: error?.body.errors[0].detail || t('umbrella_roles.edit_roles_modal.form.fail'),
        variant: 'error',
      })
    },
  }

  const { createRole } = useCreateUmbrellaRole(apiActions)
  const { updateRole: editRole } = useUpdateUmbrellaRole(apiActions)

  const { Form, handleSubmit } = useForm({
    defaultValues: useMemo(() => getDefaultValues(selectedRole), [selectedRole]),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const handleSubmitForm = useCallback(
    (formValues: CreateOrEditRoleForm) => {
      if (!umbrella?.id) {
        return
      }

      const organizationIds: string[] = []
      if (formValues.organizations?.length) {
        for (const organization of formValues.organizations) {
          organization.organizationId && organizationIds.push(organization.organizationId)
        }
      }

      const payload: UmbrellaRolePayload = {
        description: formValues.description,
        grantAdmin: formValues.grantAdmin,
        grantAll: formValues.grantAll,
        name: formValues.name,
        organizationIds,
      }

      if (roleId) {
        editRole({ umbrellaId: umbrella?.id, roleId, payload })
      } else {
        createRole({ umbrellaId: umbrella?.id, payload })
      }
    },
    [createRole, editRole, umbrella?.id, roleId],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return (
    <CreateOrEditUmbrellaRoleContext.Provider value={{ Form, selectedRole, submitForm }}>
      {children}
    </CreateOrEditUmbrellaRoleContext.Provider>
  )
}

export const useCreateOrEditUmbrellaRole = () => {
  const context = useContext(CreateOrEditUmbrellaRoleContext)

  if (!context) {
    throw new Error('CreateOrEditUmbrellaRoleContext is missing in the module!')
  }

  return context
}
