import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { ExternalInvoices } from '@views/externalInvoices'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { GlobalFiscalYearContextProvider } from '../../contexts/globalFiscalYearContext'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'external-invoices-module'

class ExternalInvoicesWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
}

type ExternalInvoiceModuleProps = {
  onNavigate: EmberNavigate
  organizationId: string
}

const ExternalInvoiceModule = ({ onNavigate, organizationId }: ExternalInvoiceModuleProps): ReactElement => (
  <ThemeProvider>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <UserOrganizationContextProvider organizationId={organizationId}>
          <UserOrganizationSettingsContextProvider organizationId={organizationId}>
            <GlobalFiscalYearContextProvider organizationId={organizationId}>
              <EmberRouterContextProvider onNavigate={onNavigate}>
                <ModalContextProvider>
                  <CurrentUserContextProvider>
                    <ExternalInvoices />
                  </CurrentUserContextProvider>
                </ModalContextProvider>
              </EmberRouterContextProvider>
            </GlobalFiscalYearContextProvider>
          </UserOrganizationSettingsContextProvider>
        </UserOrganizationContextProvider>
      </QueryClientProvider>
    </HashRouter>
  </ThemeProvider>
)

const InvoicesCustomElement = createCustomElement(ExternalInvoiceModule, ExternalInvoicesWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, InvoicesCustomElement)

export default InvoicesCustomElement
