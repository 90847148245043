import { Button, ButtonsGroup, Input, Modal, ModalProps, Space, Text, useModal } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ModalId } from '../../../../enums/modalId'
import { RegistrationType } from './enums/registrationType'
import { useEnableEInvoicingForm } from './hooks/useEnableEInvoicingForm'

interface ModalTranslationKeys {
  title: string
  description: string
}

type EnableEInvoicingModalProps = ModalProps

export const EnableEInvoicingModal = ({ id, ...modalProps }: EnableEInvoicingModalProps): ReactElement => {
  const { t } = useTranslation()
  const {
    Form,
    FormItem,
    isCVRInputDisabled,
    isEnableEInvoicingError,
    isEnableEInvoicingSuccess,
    isOrganizationLoading,
    isProcessingEnableEInvoicing,
    registrationType,
    reset,
    submitForm,
  } = useEnableEInvoicingForm()
  const { close } = useModal(id, {
    onClose: () => {
      reset()
    },
  })

  const shouldShowForm = !isEnableEInvoicingError && !isEnableEInvoicingSuccess
  const translationKeys: ModalTranslationKeys = useMemo(() => {
    if (!isProcessingEnableEInvoicing) {
      if (isEnableEInvoicingSuccess) {
        return {
          title: 'receipts.e_invoicing.register.title.success',
          description: 'receipts.e_invoicing.register.description.success',
        }
      }

      if (isEnableEInvoicingError) {
        if (registrationType === RegistrationType.Nemhandel) {
          return {
            title: 'receipts.e_invoicing.register.title.error.registration',
            description: 'receipts.e_invoicing.register.description.error.nemhandel',
          }
        }

        if (registrationType === RegistrationType.Sproom) {
          return {
            title: 'receipts.e_invoicing.register.title.error.registration',
            description: 'receipts.e_invoicing.register.description.error.sproom',
          }
        }

        return {
          title: 'receipts.e_invoicing.register.title.error',
          description: 'receipts.e_invoicing.register.description.error',
        }
      }
    }

    return {
      title: 'receipts.e_invoicing.register.title',
      description: 'receipts.e_invoicing.register.description',
    }
  }, [isProcessingEnableEInvoicing, isEnableEInvoicingSuccess, isEnableEInvoicingError, registrationType])

  const handleRegisterButtonClick = useCallback(() => {
    submitForm()
  }, [submitForm])

  const handleCloseButtonClick = useCallback(() => {
    close()
  }, [close])

  return (
    <Modal id={ModalId.EnableEInvoicingModal} size="s" autofocusOnInput closable {...modalProps}>
      <Modal.Header title={t(translationKeys.title)} />
      <Modal.Body>
        {shouldShowForm && (
          <Form>
            <Trans i18nKey="receipts.e_invoicing.register.description">
              <Text>
                In accordance with the new Danish bookkeeping law, all Danish companies can now send and receive
                electronic invoices directly from their accounting platform.
              </Text>
              <Space />
              <Text>
                With a single click to register, you'll be able to effortlessly send and receive e-invoices. Your CVR
                number will be utilized for the registration process.
              </Text>
            </Trans>
            <Space size="l" />
            <FormItem
              label={t('receipts.e_invoicing.register.cvr.label')}
              name="cvr"
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={isCVRInputDisabled}
                  placeholder={t('receipts.e_invoicing.register.cvr.placeholder')}
                />
              )}
            />
          </Form>
        )}
        {!shouldShowForm && <Text>{t(translationKeys.description)}</Text>}
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          {shouldShowForm && (
            <Button
              disabled={isOrganizationLoading || isProcessingEnableEInvoicing}
              loading={isProcessingEnableEInvoicing}
              onClick={handleRegisterButtonClick}
            >
              {t('receipts.e_invoicing.register.register_now')}
            </Button>
          )}
          {!shouldShowForm && (
            <Button onClick={handleCloseButtonClick}>{t('receipts.e_invoicing.register.close')}</Button>
          )}
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
