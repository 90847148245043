import { Color, DefaultValue } from '@design-system'

import styled from '@emotion/styled'
import { transparentize } from 'polished'

export const ReportHtmlWrapper = styled.div`
  position: relative;

  /* Soft background on the right edge of the table to show that there's some content to scroll horizontally */
  &:after {
    content: '';
    border-radius: ${DefaultValue.BorderRadius};
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 15px;
    background: linear-gradient(to right, ${transparentize(1, Color.White)} 10%, ${Color.White} 100%);
  }

  .export-document {
    overflow: auto; // for wider reports we add the scroll
  }
`
