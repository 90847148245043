import { DropzoneProtected } from '@components'
import { SkeletonBox } from '@design-system'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Scope } from '../../../../../../enums/scope'
import { TrackingContext } from '../../../../../../enums/trackingContext'
import { useSegment } from '../../../../../../hooks'
import { Route } from '../../../../enums/route'
import { useReceipts } from '../../../../hooks/useReceipts'
import { useUploadReceipts } from '../../../../hooks/useUploadReceipts'
import { ReceiptDropzoneFullScreen } from '../../../ReceiptDropzoneFullScreen'
import * as Styled from './styles'

export const NewReceipt = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { track } = useSegment()
  const { attachments: receipts, isLoading } = useReceipts()
  const { progress, uploadFiles } = useUploadReceipts({
    onSuccess: () => {
      track('File Uploaded (FE)', {
        context: TrackingContext.Uploads,
      })
      history.push(Route.ReceiptsList)
    },
  })

  useEffect(() => {
    if (receipts.length) {
      history.push(Route.ReceiptsList)
    }
  }, [history, receipts])

  return (
    <>
      <Styled.NewReceiptWrapper>
        {isLoading ? (
          <SkeletonBox fullHeight fullWidth />
        ) : (
          <DropzoneProtected
            scopes={Scope.UploadsWrite}
            onDrop={uploadFiles}
            trackingContext={TrackingContext.Uploads}
            uploadingText={t('ds.dropzone.uploading_with_counter', {
              current: progress.current,
              total: progress.total,
            })}
          />
        )}
      </Styled.NewReceiptWrapper>
      <ReceiptDropzoneFullScreen trackingContext={TrackingContext.Uploads} />
    </>
  )
}
