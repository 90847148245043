import { Color, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const ProgressChartWrapper = styled.section`
  width: 320px;
  margin-bottom: ${Spacing.L};
  margin-right: ${Spacing.XXXL};
`

export const ProgressChartHeader = styled.header`
  margin-bottom: ${Spacing.S};
  display: flex;
  flex-direction: row;
`

export const LeftLabel = styled(Text)``

export const RightLabel = styled(Text)`
  margin-left: auto;
`
export const ProgressBarWrapper = styled.div`
  height: 100%;
  border-radius: 25px;
  background-color: ${Color.Gray30};
  position: relative;
  height: 10px;
`

interface ProgressBarProps {
  barWidth: number
}

export const ProgressBar = styled.div<ProgressBarProps>`
  height: 10px;
  border-radius: 25px;
  width: ${({ barWidth }) => `${barWidth}%`};
  mask: linear-gradient(#fff 0 0);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      to right,
      ${Color.Green} 0%,
      ${Color.Green} 50%,
      ${Color.Yellow} 75%,
      ${Color.Red} 100%
    );
  }
`
