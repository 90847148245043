import { InlineStyle } from '../enums/inlineStyle'
import { RichTextStyle } from '../types/richTextStyle'

export interface InlineStyleConfig {
  name: RichTextStyle
  label: string
  style: InlineStyle
}

export const defaultInlineStyles: InlineStyleConfig[] = [
  { name: 'bold', label: 'B', style: InlineStyle.Bold },
  { name: 'italic', label: 'I', style: InlineStyle.Italic },
  { name: 'underline', label: 'U', style: InlineStyle.Underline },
]
