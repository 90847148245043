import { Badge, SkeletonBox } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { billStatusToBadgeVariant } from '../../../../constants/billStatusToBadgeVariant'
import { billStatusToTitleKey } from '../../../../constants/billStatusToTitleKey'
import { useGetBill } from '../../../../hooks/useGetBill'
import { getBillStatus } from '../../../../utils/getBillStatus'
import { useBillForm } from '../../contexts/billFormContext'
import * as Styled from './styles'

export const BillStatusBadge = (): ReactElement | null => {
  const { billId } = useBillForm()
  const { data: billData, isLoading: isBillLoading } = useGetBill(billId)
  const { t } = useTranslation()

  if (!billId || (!billData && !isBillLoading)) {
    return null
  }

  const { bill } = billData || {}
  const { amount, balance, dueDate, isPaid, state, tax } = bill || {}

  const billStatus = getBillStatus({
    amount,
    balance,
    dueDate: dueDate ? new Date(dueDate) : undefined,
    isPaid,
    state,
    tax,
  })

  const badgeVariant = billStatusToBadgeVariant[billStatus]
  const badgeLabel = t(billStatusToTitleKey[billStatus])

  return (
    <Styled.BillStatusBadgeWrapper>
      {isBillLoading ? <SkeletonBox height={20} width={55} /> : <Badge variant={badgeVariant}>{badgeLabel}</Badge>}
    </Styled.BillStatusBadgeWrapper>
  )
}
