import { BillFormLineKeys } from './formData'

export type GetShouldOverridenParamChange = (args: GetShouldOverridenParamChangeInnerProps) => boolean

export interface GetShouldOverridenParamChangeInnerProps {
  property: BillFormLineKeys
  shouldOverwriteValue: boolean
  propertiesLocked?: boolean
  hasValueOverriden: boolean
}

interface GetShouldOverridenParamChangeOuterProps {
  shouldChangeExpenseParams: boolean
}

export const getShouldOverridenParamChange =
  (outerProps: GetShouldOverridenParamChangeOuterProps): GetShouldOverridenParamChange =>
  (innerProps: GetShouldOverridenParamChangeInnerProps): boolean => {
    const { property, shouldOverwriteValue, propertiesLocked, hasValueOverriden } = innerProps
    let shouldChangeParams = outerProps.shouldChangeExpenseParams

    if (!shouldOverwriteValue) {
      return false
    }

    // VAT-related properties should not be rewriten to empty if already filled
    if (property === 'vatAmount' || property === 'inclVatAmount') {
      return hasValueOverriden
    }

    // bookkeeping tag category takes the highest priority - only autocomplete tax rate and expense category if the bookkeeping tag is changed
    if (property === 'bookkeepingTag') {
      shouldChangeParams = true
    }

    // propertiesLocked can only be truthy from explicit bookkeepingTag user change
    // only taxRate and expenseCategory can be locked
    if (property === 'taxRate' || property === 'expenseCategory') {
      return !propertiesLocked && shouldChangeParams
    }

    return true
  }
