import { Button, ButtonsGroup, Modal, ModalProps, Text, useModal } from '@design-system'

import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ModalId } from '../../../../enums/modalId'

export const BankAccountOrphanedModal = ({ ...modalProps }: ModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeModal } = useModal(ModalId.BankAccountOrphanedModal)
  return (
    <Modal size="s" {...modalProps}>
      <Modal.Header>
        <Text variant="h2">{t('bank_connection.bank_account_orphaned.title')}</Text>
      </Modal.Header>
      <Modal.Body>
        <Text>
          <Trans i18nKey="bank_connection.bank_account_orphaned.text" />
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button onClick={closeModal} variant="primary">
            {t('ok')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
