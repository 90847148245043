import { DefaultDraftBlockRenderMap } from 'draft-js'
import Immutable from 'immutable'
import { ReactElement } from 'react'

import { Text, TextProps } from '../../Text'

// Need to wrap text component because draft-js doesn't handle ForwardRefExotic component too well
const TextBlock = (props: TextProps): ReactElement => <Text {...props}></Text>

const customBlockRenderMap = Immutable.Map({
  'header-one': {
    element: 'h1',
    wrapper: <TextBlock variant="h1" />,
  },
  'header-two': {
    element: 'h2',
    wrapper: <TextBlock variant="h2" />,
  },
  'header-three': {
    element: 'h3',
    wrapper: <TextBlock variant="h3" />,
  },
})

export const blockRenderMap = DefaultDraftBlockRenderMap.merge(customBlockRenderMap)
