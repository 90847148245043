import { AnyAction } from 'redux'

import { USER_BOOTSTRAP_RECEIVED, USER_BOOTSTRAP_REQUESTED } from './actions'
import { User } from './types'

export type UserBootstrapRequested =
  | AnyAction
  | {
      payload: null
      type: typeof USER_BOOTSTRAP_REQUESTED
    }

export type UserBootstrapReceived =
  | AnyAction
  | {
      payload: User
      type: typeof USER_BOOTSTRAP_RECEIVED
    }

export function userBootstrapRequested(): UserBootstrapRequested {
  return {
    payload: null,
    type: USER_BOOTSTRAP_REQUESTED,
  }
}

export function userBootstrapReceived(user: User): UserBootstrapReceived {
  return {
    payload: user,
    type: USER_BOOTSTRAP_RECEIVED,
  }
}
