export const palette = {
  primary: '#2766F1',
  secondary: '#5C6870',
  //
  primaryBlue: '#2766F1',
  primaryLight: '#C2E2FF',
  primaryGray: '#697588',
  primaryWarmGray: '#897F76',
  primaryGreen: '#41C447',
  primaryPurple: '#7151E5',
  primaryRed: '#ED5244',
  primaryYellow: '#FFDD19',
  //
  primaryActive: '#006DCC',
  primaryDarkend: '#095A8C',
  menuBackground: '#2766F1',
  menuActive: '#006DCC',
  inputPlaceholder: '#A6A6A6',
  inputActive: '#354A59',
  //
  blue1: '#F3F9FF',
  blue2: '#C2E2FF',
  blue3: '#47A9FF',
  blue4: '#006DCC',
  blue5: '#095A8C',
  blue6: '#2766F1',
  blueA: '#2766F1',
  //
  mysteryBlue: '#C2E2FF',
  mysteryBlue2: '#2766F1',
  //
  newBlue1: '#3387f7',
  newBlue2: '#d7dde4',
  //
  gray1: '#F4F5F6',
  gray2: '#DDE1E3',
  gray3: '#9AA5AC',
  gray4: '#5C6870',
  gray5: '#40494F',
  gray6: '#D9DEE5',
  gray7: '#484F55',
  grayA: '#697588',
  //
  darkGray1: '#364A59',
  darkGray2: '#41494F',
  darkGray3: '#5A6166',
  //
  warmGray1: '#F6F5F4',
  warmGray2: '#D9D6D3',
  warmGray3: '#B3ADA8',
  warmGray4: '#787068',
  warmGray5: '#625B55',
  warmGrayA: '#897F76',
  //
  newWhite1: '#f6f9ff',
  //
  newGrey1: '#41494f',
  newGray2: '#dee1e3',
  newGray3: '#364a59',
  //
  newRed1: '#FFE7E4',
  lightGray1: '#DEE1E3',
  //
  lightBlue1: '#F6F9FF',
  //
  green1: '#EFFAF0',
  green2: '#D0F1D2',
  green3: '#73D477',
  green4: '#309C35',
  green5: '#2A7A2A',
  greenA: '#41C447',
  //
  newGreen1: '#798052',
  //
  purple1: '#F4F2FC',
  purple2: '#EBE8F9',
  purple3: '#A18CED',
  purple4: '#511BDA',
  purple5: '#3D14A3',
  purpleA: '#7151E5',
  //
  red1: '#FCF5F5',
  red2: '#FFECEB',
  red3: '#FF7770',
  red4: '#FC1B15',
  red5: '#C70101',
  redA: '#ED5244',

  //
  yellow1: '#FFFCEB',
  yellow2: '#FFF9D6',
  yellow3: '#FFE75C',
  yellow4: '#FFCE00',
  yellow5: '#FFBE00',
  yellowA: '#FFDD19',
  //
  newYellow1: '#f3efe2',
  newYellow2: '#F5F9EB',
  //

  creamyWhite: '#F9FAFC',
  brandOrange: '#FE5A37',
  brandOrangeLight: '#FFE7E4',
  // temporary palette colors, it will be removed after Billy gets styles for
  greyscale05: '#4D4D4D',
  greyscale15: '#738188',
  greyscale32: '#D9DEE5',
  greyscale34: '#ececec',
  greyscale35: '#E4E8EC',
  greyscale4: '#E5E5E5',
  darkGreen1: '#90A79C',
  darkGreen2: '#0C776A',
  orange1: '#FF8A00',
  orange2: '#B19945',

  mysteryYellow: '#ffd240',
  greyscale1: '#566060', // Taken from the trial expired banner
  greyscale2: '#5c6666',
  red: '#ED5244',

  backgroundGrey: '#f2f5f7',
}
