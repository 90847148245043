import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const VatBody = styled.div`
  display: flex;
  margin: ${Spacing.XXS} 0 0 0;
`

export const Footer = styled.footer`
  margin-top: auto;
  margin-bottom: -${Spacing.S};
`
