import { useUserOrganization } from '@modules-deprecated/app/organization'

// For buckets 5-9, we use a different Calendly link so it doesn't restrict meeting date
// to 2 weeks in advance
export const USE_NO_RESTRICTIONS_BOOK_MEETING_LINK_BUCKETS = [5, 6, 7, 8, 9]

interface UseBookMeetingLink {
  bookMeetingLink: string
}

export const useBookMeetingLink = (): UseBookMeetingLink => {
  const { organization } = useUserOrganization()
  const shouldUseNoRestrictionsBookMeetingLink =
    organization?.randomBucket !== undefined &&
    USE_NO_RESTRICTIONS_BOOK_MEETING_LINK_BUCKETS.includes(organization.randomBucket)

  const bookMeetingLink = shouldUseNoRestrictionsBookMeetingLink
    ? 'https://calendly.com/megl-ageras/kom-godt-i-gang-med-billy-by-ageras '
    : 'https://calendly.com/kom-godt-igang/kom-godt-i-gang-med-billy?month=2023-04?utm_source=mitbilly&utm_medium=internal&utm_campaign=onboardingtile'

  return {
    bookMeetingLink,
  }
}
