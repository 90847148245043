import { call, put, takeLatest } from 'redux-saga/effects'

import { getRequest } from '../../../utils'
import { userBootstrapReceived } from './action-creators'
import { USER_BOOTSTRAP_REQUESTED } from './actions'

function* fetchUserBootstrap() {
  try {
    const res: { user: any } = yield call(getRequest, '/v2/user/bootstrap')
    yield put(userBootstrapReceived(res.user))
  } catch (error) {
    console.error(error)
  }
}

export default function* () {
  yield takeLatest(USER_BOOTSTRAP_REQUESTED, fetchUserBootstrap)
}
