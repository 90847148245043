import { InternalAccessor } from '@design-system'

import { useMemo } from 'react'

import { AccessTokensTableRow } from '../types/accessTokensTableRow'
import { Actor } from '../types/actor'

export const useAccessTokensTableData = (actors: Actor[]): AccessTokensTableRow[] => {
  return useMemo(
    () =>
      actors.map(({ description, id, name }) => {
        return {
          description: description || undefined,
          name,
          id,
          [InternalAccessor.HoverActions]: true,
          [InternalAccessor.StaticActions]: true,
        }
      }),
    [actors],
  )
}
