import { amountToDisplayValue, Color, FontSize, SkeletonBox } from '@design-system'

import React, { ReactElement } from 'react'
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { ChartDataKeys } from '../../enums/chartDataKeys'
import { ChartData } from '../../types/chartData'
import { generateBar, getOverviewChartGradient } from '../../utils'
import { CustomTooltip } from '../CustomTooltip'

interface OverviewChartProps {
  chartData: ChartData[]
  isLoading: boolean
}

export const OverviewChart = ({ isLoading, chartData }: OverviewChartProps): ReactElement => (
  <ResponsiveContainer height="100%" width="100%">
    {isLoading || chartData.length === 0 ? (
      <SkeletonBox />
    ) : (
      <ComposedChart barGap={2} data={chartData} margin={{ top: 0, left: 0, right: 0, bottom: -9 }} stackOffset="sign">
        {getOverviewChartGradient()}
        <XAxis
          axisLine={{ stroke: Color.Gray40 }}
          tickLine={{ stroke: Color.Gray40 }}
          dataKey="monthData.short"
          fontSize={FontSize.TextMicro}
          label={{ fill: Color.Gray90 }}
          type="category"
        />
        <YAxis
          axisLine={{ stroke: Color.Gray40 }}
          tickLine={{ stroke: Color.Gray40 }}
          fontSize={FontSize.TextMicro}
          label={{ fill: Color.Gray90 }}
          tickFormatter={(num) => amountToDisplayValue(num, true)}
          type="number"
        />
        <Area
          dataKey={ChartDataKeys.Result}
          fill="url(#gradient-lineShadow)"
          type="monotone"
          stroke={Color.Gray50}
          strokeWidth="1"
        />
        {generateBar(ChartDataKeys.Expenses)}
        {generateBar(ChartDataKeys.Revenue)}
        <Tooltip content={<CustomTooltip />} />
        <Line dataKey={ChartDataKeys.Result} stroke={Color.Gray50} strokeWidth="1" type="monotone" />
        <CartesianGrid stroke={Color.Gray40} strokeDasharray="1" vertical={false} />
        <ReferenceLine display="0" ifOverflow="visible" stroke={Color.Gray40} y={0} />
      </ComposedChart>
    )}
  </ResponsiveContainer>
)
