import { SettingsSection } from '@components'
import { Button, Checkbox, FormItemsGroup } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserPrivacyForm } from './hooks/useUserPrivacyForm'

export const UserPrivacySettings = (): ReactElement => {
  const { t } = useTranslation()
  const { Form, FormItem, isLoading, isSubmitting } = useUserPrivacyForm()

  return (
    <SettingsSection>
      <Form>
        <SettingsSection.Content
          title={t('settings.user.privacy_settings')}
          subtitle={t('settings.user.privacy_settings.subtitle')}
        >
          <FormItemsGroup>
            <FormItem
              name="isSupportAccessAllowed"
              render={({ field: { onChange, value } }) => (
                <Checkbox checked={value} onChange={onChange}>
                  {t('settings.user.privacy.supportAccess')}
                </Checkbox>
              )}
            />
            <FormItem
              name="isSupportChatDisabled"
              render={({ field: { onChange, value } }) => (
                <Checkbox checked={value} onChange={onChange}>
                  {t('settings.user.privacy.supportChat')}
                </Checkbox>
              )}
            />
            <FormItem
              name="isBookkeepingHeroDisabled"
              render={({ field: { onChange, value } }) => (
                <Checkbox checked={value} onChange={onChange}>
                  {t('settings.user.privacy.bookkeeping_hero')}
                </Checkbox>
              )}
            />
          </FormItemsGroup>
        </SettingsSection.Content>
        <SettingsSection.Footer>
          <Button loading={isLoading || isSubmitting} size="l" type="submit">
            {t('settings.user.privacy.save_changes')}
          </Button>
        </SettingsSection.Footer>
      </Form>
    </SettingsSection>
  )
}
