import { NavItem } from '@design-system'

import { endOfMonth, isAfter, isBefore, setMonth, setYear, startOfMonth } from 'date-fns'

import { PeriodMode } from '../../../../enums/periodMode'
import { PeriodValue } from '../../../../types/periodValue'
import { getMonthNames } from '../../MonthSelect'

export const getMonthItems = (
  year: number,
  locale?: string,
  minDate?: Date,
  maxDate?: Date,
): NavItem<PeriodValue>[] => {
  const monthsNames = getMonthNames(locale)

  return monthsNames.map((monthName, monthIndex) => {
    const date = setMonth(setYear(new Date(), year), monthIndex)
    let isDisabled = false

    if (maxDate) {
      isDisabled = isAfter(endOfMonth(date), endOfMonth(maxDate))
    }

    if (minDate && !isDisabled) {
      isDisabled = isBefore(startOfMonth(date), startOfMonth(minDate))
    }

    return {
      id: String(monthIndex),
      children: monthName,
      value: {
        mode: PeriodMode.Month,
        year,
        value: monthIndex,
        disabled: isDisabled,
      },
    }
  })
}
