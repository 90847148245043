import { Checkbox } from '@design-system'

import { ChangeEvent, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccountStatementsFilters } from '../../hooks/useAccountStatementsFilters'
import * as Styled from './styles'

export const AccountStatementsVoidedFilter = (): ReactElement => {
  const { t } = useTranslation()
  const [{ withVoided }, setQueryParams] = useAccountStatementsFilters()

  const handleChecked = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked

      setQueryParams({ withVoided: checked })
    },
    [setQueryParams],
  )

  return (
    <Styled.AccountStatementsVoidedFilterWrapper>
      <Checkbox name="withVoided" checked={withVoided} onChange={handleChecked}>
        {t('account_statements.show_voided')}
      </Checkbox>
    </Styled.AccountStatementsVoidedFilterWrapper>
  )
}
