import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconJournal = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-journal')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={coloring(color)}
        d="M20,1H4C2.343,1,1,2.343,1,4v24c0,1.657,1.343,3,3,3h16c1.657,0,3-1.343,3-3V4C23,2.343,21.657,1,20,1z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M20,32H4c-2.206,0-4-1.794-4-4V4c0-2.206,1.794-4,4-4h16c2.206,0,4,1.794,4,4v24C24,30.206,22.206,32,20,32z M4,2C2.897,2,2,2.897,2,4v24c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H4z"
      ></path>
      <path
        fill={coloring(color2 || color)}
        d="M19.515,22.364l10.607-10.607c1.172-1.172,1.172-3.071,0-4.243l-1.414-1.414 c-1.172-1.172-3.071-1.172-4.243,0L13.858,16.707l-2.828,8.485L19.515,22.364z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M9.448,26.773l3.536-10.606L23.757,5.394c1.511-1.512,4.146-1.512,5.657,0l1.414,1.414 c1.56,1.56,1.56,4.098,0,5.657L20.055,23.238L9.448,26.773z M14.732,17.247l-2.122,6.364l6.364-2.121l10.439-10.439 c0.78-0.78,0.78-2.049,0-2.829L28,6.808c-0.756-0.756-2.073-0.756-2.829,0L14.732,17.247z"
      ></path>
      <rect
        x="13.393"
        y="17.829"
        transform="matrix(0.7071 0.7071 -0.7071 0.7071 18.4093 -6.7841)"
        fill="#FFFFFF"
        width="8"
        height="2"
      ></rect>
    </svg>
  </Box>
)
