import { ReactComponent as CalendarIcon } from '../../../../assets/images/lending/calendar.svg'
import { ReactComponent as PercentageIcon } from '../../../../assets/images/lending/discount-percent-square.svg'
import { ReactComponent as MoneyIcon } from '../../../../assets/images/lending/money.svg'
import { Benefit } from '../types/benefit'

export const getBenefits = (): Benefit[] => [
  {
    icon: <MoneyIcon />,
    titleTKey: 'financing.froda_lending.benefit_1.title',
    descriptionTKey: 'financing.froda_lending.benefit_1.description',
    linkUrlEN: 'https://home.froda.se/assets/documents/privacy_policy/froda_privacy_policy_en.pdf',
    linkUrlDK: 'https://home.froda.se/assets/documents/privacy_policy/froda_privacy_policy_da.pdf',
  },
  {
    icon: <CalendarIcon />,
    titleTKey: 'financing.froda_lending.benefit_2.title',
    descriptionTKey: 'financing.froda_lending.benefit_2.description',
  },
  {
    icon: <PercentageIcon />,
    titleTKey: 'financing.froda_lending.benefit_3.title',
    descriptionTKey: 'financing.froda_lending.benefit_3.description',
  },
]
