import { Button, notify } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../enums/queryKeys'
import { useAcceptOrganizationInvitation } from '../../../../hooks/useAcceptOrganizationInvitation'
import { useDeleteOrganizationInvitation } from '../../../../hooks/useDeleteOrganizationInvitation'
import * as Styled from './styles'

interface ActionsProps {
  invitationId: string
  invitationToken: string
}

export const Actions = ({ invitationId, invitationToken }: ActionsProps): ReactElement => {
  const { t } = useTranslation()

  const handleSuccessInvalidateData = useCallback(() => {
    queryClient.invalidateQueries(QueryKeys.UmbrellaOrganizationsInvitations, { refetchInactive: true })
    queryClient.invalidateQueries(QueryKeys.UmbrellaOrganizations, { refetchInactive: true })
  }, [])

  const handleErrorInvalidateData = useCallback(() => {
    notify({ id: NotificationKeys.CreateContact, message: t('error_saving'), variant: 'error' })
  }, [t])

  const { deleteInvitation, isDeleting } = useDeleteOrganizationInvitation({
    onError: handleErrorInvalidateData,
    onSuccess: handleSuccessInvalidateData,
  })
  const { acceptInvitation, isAccepting } = useAcceptOrganizationInvitation({
    onError: handleErrorInvalidateData,
    onSuccess: handleSuccessInvalidateData,
  })

  const handleDeclineClick = useCallback(() => {
    deleteInvitation(invitationId)
  }, [deleteInvitation, invitationId])

  const handleAcceptClick = useCallback(() => {
    acceptInvitation(invitationToken)
  }, [acceptInvitation, invitationToken])

  return (
    <Styled.ActionWrapper>
      <Button loading={isAccepting} onClick={handleAcceptClick} size="m">
        {t('umbrella_organizations.invitations_table.accept')}
      </Button>
      <Button loading={isDeleting} onClick={handleDeclineClick} size="m" variant="secondary">
        {t('umbrella_organizations.invitations_table.decline')}
      </Button>
    </Styled.ActionWrapper>
  )
}
