import React, { ReactElement, useCallback, useState } from 'react'

import { Voucher } from '@modules-deprecated/inbox/types'

import { Tab } from '../enums/tab'
import { ReceiptWithoutVoucher } from '../ReceiptWithoutVoucher'
import { ReceiptWithVoucher } from '../ReceiptWithVoucher'
import { Tabs } from '../Tabs'
import * as Styled from './styles'

interface ReceiptViewProps {
  accountId: string
  bankLineId: string
  voucher?: Voucher
}

export const ReceiptView = ({ accountId, bankLineId, voucher, ...rest }: ReceiptViewProps): ReactElement => {
  const [activeTab, setActiveTab] = useState<Tab>(Tab.WithVoucher)

  const renderContent = useCallback(() => {
    switch (activeTab) {
      case Tab.WithVoucher:
        return <ReceiptWithVoucher accountId={accountId} bankLineId={bankLineId} />

      case Tab.WithoutVoucher:
        return <ReceiptWithoutVoucher bankLineId={bankLineId} />

      default:
        return null
    }
  }, [accountId, activeTab, bankLineId])

  return (
    <div {...rest}>
      <Styled.TabsWrapper>
        <Tabs activeTab={activeTab} onTabClick={setActiveTab} />
      </Styled.TabsWrapper>
      <Styled.Container>{renderContent()}</Styled.Container>
    </div>
  )
}
