import { css } from '@emotion/core'
import { format } from 'date-fns'
import React from 'react'
import { ReactDatePickerProps } from 'react-datepicker'
import { Flex } from 'rebass'

import { useLocale } from '../../hooks/useLocale'
import { CalendarIconCaret } from './CalendarIconCaret'

export type CalendarHeaderComponent = ReactDatePickerProps['renderCustomHeader']

const CalendarHeader: CalendarHeaderComponent = ({
  decreaseMonth,
  decreaseYear,
  increaseMonth,
  increaseYear,
  nextMonthButtonDisabled,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  prevMonthButtonDisabled,
  date,
}) => {
  const locale = useLocale()
  const month = format(date, 'MMMM', { locale })
  const year = format(date, 'yyyy', { locale })
  return (
    <Flex
      css={css`
        color: #354a59;
        font-size: 14px;
        font-weight: 400;
      `}
    >
      <Flex
        flex={1}
        justifyContent="space-between"
        css={css`
          padding: 3px 5px 3px 5px;
          border-right: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          overflow: hidden;
        `}
      >
        <CalendarIconCaret direction="left" onClick={decreaseMonth} disabled={!prevMonthButtonDisabled} />
        {month}
        <CalendarIconCaret direction="right" onClick={increaseMonth} disabled={nextMonthButtonDisabled} />
      </Flex>

      <Flex
        css={css`
          padding: 3px 5px 3px 5px;
          border-bottom: 1px solid #ccc;
        `}
      >
        <CalendarIconCaret direction="left" onClick={decreaseYear} disabled={prevYearButtonDisabled} />
        {year}
        <CalendarIconCaret direction="right" onClick={increaseYear} disabled={nextYearButtonDisabled} />
      </Flex>
    </Flex>
  )
}

export default CalendarHeader
