/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Select /> from design-system instead
 */
import { Icon, IconName, PORTAL_CLASS_NAME, Spacing, zIndex } from '@design-system'

import styled from '@emotion/styled'
import { Placement } from '@popperjs/core'
import React, { KeyboardEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Control, Controller } from 'react-hook-form'
import { usePopper } from 'react-popper'
import { BoxProps } from 'rebass'

import { useKeyDown, useOnClickOutside } from '../../hooks'
import { deprecatedComponentInfo } from '../../utils/deprecatedComponentInfo'
import { getPopperModifiers } from '../../utils/getPopperModifiers'
import { FormControl, FormLabel, InputProps, InputSize } from '../Form'
import { SelectContainer as Container, SelectInput as Input, SelectMenu as Menu } from './elements'
import { MenuEventHandler } from './elements/menuEventHandler'
import { MenuItemEventHandler } from './elements/menuItemEventHandler'

deprecatedComponentInfo('Select', 'SmartSelect')

interface SelectListWrapperProps {
  width?: number | string
}

const SelectListWrapper = styled.div<SelectListWrapperProps>`
  width: ${({ width }) => {
    if (width) {
      if (typeof width === 'number') {
        return `${width}px`
      } else if (width === '100%') {
        return 'auto'
      } else {
        return width
      }
    }

    return '238px'
  }};
  z-index: ${zIndex.Dropdown};
`

interface IconWrapperProps {
  disabled?: boolean
  inputSize?: InputSize
}

const IconWrapper = styled.figure<IconWrapperProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding: ${Spacing.S} ${Spacing.M};
  width: 40px;
  height: ${({ inputSize }) => (inputSize === 'xl' ? 48 : 40)}px;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`

export type SelectItem = {
  id: string
  text?: string
  iconUrl?: string
  [key: string]: unknown | undefined
}

export type SelectComponentProps<T> = InputProps & {
  disconnect?: boolean
  error?: string
  errorPath?: string
  icon?: IconName
  items?: T[]
  listPlacement?: Placement
  listWidth?: number | string
  name: string
  onFetch?: (offset: number, text?: string) => void
  onItemSelect?: (item: T, name?: string) => void
  onTextChange?: (text: string) => void
  render?: Function
  selectedItem?: T
  total?: number | null
}

export const SelectComponent = <T extends SelectItem>({
  autoComplete = 'off',
  disabled,
  disconnect,
  error,
  errorPath,
  icon,
  id,
  inputSize,
  items,
  label,
  listPlacement = 'bottom-start',
  listWidth,
  name,
  onFetch = (offset: number, text?: string) => ({}),
  onItemSelect,
  onTextChange = (text: string) => ({}),
  options,
  placeholder,
  render,
  required,
  selectedItem,
  silent,
  total,
  ...rest
}: SelectComponentProps<T> & Omit<BoxProps, 'label'>) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<T>()
  const [isFocused, setIsFocused] = useState(false)
  const [menuHandler, setMenuHandler] = useState<MenuEventHandler>()
  const [menuItemHandler, setMenuItemHandler] = useState<MenuItemEventHandler<T>>()
  const [value, setValue] = useState<string | undefined>()
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLInputElement>(null)
  const selectListRef = useRef<HTMLDivElement>(null)
  const defaultIcon: IconName = isMenuVisible ? 'chevronUp' : 'chevronDown'

  const modifiers = useMemo(() => getPopperModifiers(listWidth === '100%'), [listWidth])
  const { styles, forceUpdate } = usePopper(containerRef.current, selectListRef.current, {
    placement: listPlacement,
    modifiers,
  })

  useOnClickOutside([containerRef, selectListRef], () => {
    menuHandler && menuHandler.closeMenu()
  })

  useEffect(() => {
    onFetch(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.id !== activeItem?.id) {
        setValue(selectedItem.text)
        setActiveItem(selectedItem)
      }
    } else if (activeItem) {
      setValue('')
      setActiveItem(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem])

  const handleChange = useCallback(
    (text: string) => {
      setValue(text || '')
      setIsMenuVisible(true)
      onTextChange(text)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsMenuVisible, onFetch, onTextChange],
  )

  const handleFetch = useCallback(
    (offset: number, text?: string) => {
      setIsMenuVisible(true)
      onFetch(offset, value)
    },
    [setIsMenuVisible, onFetch, value],
  )

  const handleIconClick = useCallback(() => {
    if (disabled) {
      return
    }

    menuHandler && menuHandler.openMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuHandler])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      menuHandler && menuHandler.handleKeyDown(event)
      menuItemHandler && menuItemHandler.handleKeyDown(event)
    },
    [menuHandler, menuItemHandler],
  )

  const handleOnSelect = useCallback(
    (item: T) => {
      setIsMenuVisible(false)
      onItemSelect && onItemSelect(item)
      setActiveItem(item)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onItemSelect],
  )

  useEffect(() => {
    if (isMenuVisible) {
      forceUpdate?.()
    }
  }, [forceUpdate, isMenuVisible])

  useEffect(() => {
    setMenuHandler(
      new MenuEventHandler({
        input: inputRef.current,
        onMenuToggle: setIsMenuVisible,
      }),
    )
  }, [inputRef, items])

  useEffect(() => {
    setMenuItemHandler(
      new MenuItemEventHandler({
        input: inputRef.current,
        items,
        onActiveChange: setActiveItem,
        onSelect: handleOnSelect,
      }),
    )
  }, [inputRef, items, handleOnSelect])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  useKeyDown('Enter', (event) => {
    if (isFocused) {
      event.preventDefault()
    }
  })

  const preSelectedItem = items?.find((item) => item?.id === selectedItem?.id) || selectedItem

  return (
    <>
      <FormControl {...rest}>
        <FormLabel name={id || name} required={required}>
          {label}
        </FormLabel>
        <Container ref={containerRef} onKeyDown={handleKeyDown} inputSize={inputSize} {...rest}>
          <Input
            id={id || name}
            disabled={disabled}
            name={name}
            onTextChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
            type="text"
            value={preSelectedItem?.text || value}
            iconUrl={preSelectedItem?.iconUrl}
            autoComplete={autoComplete}
            options={options}
            silent={silent}
            required={required}
            disconnect={disconnect}
            errorPath={errorPath}
            isMenuVisible={isMenuVisible}
            inputSize={inputSize}
          />
          <IconWrapper onClick={handleIconClick} inputSize={inputSize} disabled={disabled}>
            <Icon icon={icon || defaultIcon} />
          </IconWrapper>
        </Container>
      </FormControl>
      {ReactDOM.createPortal(
        <SelectListWrapper className={PORTAL_CLASS_NAME} ref={selectListRef} style={styles.popper} width={listWidth}>
          {isMenuVisible && (
            <Menu
              items={items}
              total={total}
              activeItem={activeItem}
              render={render}
              onFetch={(offset: number) => handleFetch(offset, inputRef.current?.value)}
              onSelect={handleOnSelect}
            />
          )}
        </SelectListWrapper>,
        document.body,
      )}
    </>
  )
}

// Controlled
export type SelectProps<T extends SelectItem> = SelectComponentProps<T> & {
  formControl?: Control<any>
  withSelectEvent?: boolean
}

export const Select = <T extends SelectItem>({
  formControl,
  name,
  withSelectEvent,
  ...rest
}: SelectProps<T>): ReactElement => {
  if (!formControl) {
    return <SelectComponent {...rest} name={name} />
  }

  return (
    <Controller
      render={({ field }) => (
        <SelectComponent<T>
          {...rest}
          name={field.name}
          errorPath={!field.value || field.value?.id ? `${name}.id` : `${name}.name`}
          onItemSelect={(value) => {
            field.onChange(value)
            withSelectEvent && rest.onItemSelect && rest.onItemSelect(value, field.name)
          }}
          selectedItem={field.value}
          disconnect
        />
      )}
      control={formControl}
      name={name}
    />
  )
}
