// containerWidth is 1 to prevent division by 0
export const scaleFontSizeToContainer = (fontSize: number, containerWidth = 1, textWidth = 0): number => {
  const proportion = textWidth / containerWidth

  if (proportion > 1) {
    return Math.ceil(fontSize / proportion)
  }

  return fontSize
}
