import { Spacing } from '@design-system'

import styled from '@emotion/styled'

import ImageUpsellWidgetShapesPath from '../../../../../assets/images/image-upsell-widget-shapes.svg'

export const UpsellWidgetSmallWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${ImageUpsellWidgetShapesPath});
`

export const ContentWrapper = styled.div`
  margin-bottom: ${Spacing.S};
`
