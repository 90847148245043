import { FormGroup, Input, InputProps, SmartSelectItem } from '@components-deprecated'
import { amountToDisplayValue, amountToNumber, getEvaluatedExpression, NON_MATH_CHARACTERS } from '@design-system'

import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { Control, Controller } from 'react-hook-form'

import { useKeyDown } from '../../../../hooks'
import { CurrencySelector } from './CurrencySelector'

export type CurrencyInputResult = {
  amount: number
  currency?: string
}

export type CurrencyInputComponentProps = Omit<InputProps, 'onChange'> & {
  amount?: string | number
  currency?: string
  errorAmountPath?: string
  errorCurrencyPath?: string
  onChange?: (data: CurrencyInputResult) => void
  withoutCurrencySelector?: boolean
}

const CurrencyInputComponent = ({
  amount: initialAmount,
  currency: initialCurrency = '',
  disabled,
  disconnect,
  name = 'currency',
  onChange = () => null,
  placeholder,
  required,
  errorAmountPath,
  errorCurrencyPath,
  withoutCurrencySelector,
  ...rest
}: CurrencyInputComponentProps) => {
  const [amount, setAmount] = useState(amountToDisplayValue(initialAmount || ''))
  const [currency, setCurrency] = useState(initialCurrency)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    const initialAmountFormatted = initialAmount ?? amountToDisplayValue(initialAmount || '')

    if (initialAmount && initialAmountFormatted !== amount) {
      setAmount(amountToDisplayValue(initialAmount))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAmount])

  useEffect(() => {
    if (initialCurrency && initialCurrency !== currency) {
      setCurrency(initialCurrency)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCurrency])

  // Strip anything that is not a number, dot or comma or a math operator.
  const sanitizeMathCharacters = (inputValue: string) => inputValue.replace(NON_MATH_CHARACTERS, '')

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value)
  }

  const handleCurrencySelect = (item: SmartSelectItem) => {
    if (currency === item.id) {
      return
    }
    setCurrency(item.id)

    onChange({
      amount: amountToNumber(amount, true),
      currency: item.id,
    })
  }

  const handleAmountFocus = () => {
    setIsFocused(true)
  }

  const handleAmountBlur = () => {
    submit()
    setIsFocused(false)
  }

  const submit = () => {
    const evaluatedExpression = getEvaluatedExpression(sanitizeMathCharacters(amount))
    const amountDisplay = amountToDisplayValue(evaluatedExpression)
    const amountNumber = amountToNumber(evaluatedExpression, true)
    setAmount(amountDisplay)

    onChange({
      amount: amountNumber,
      currency,
    })
  }

  useKeyDown('Enter', (event) => {
    event.preventDefault()

    if (isFocused) {
      submit()
    }
  })

  const renderInput = (restProps?: any) => (
    <Input
      flex="1"
      name={`${name}_input`}
      onChange={handleAmountChange}
      required={required}
      textAlign="right"
      onBlur={handleAmountBlur}
      onFocus={handleAmountFocus}
      type="text"
      value={amount}
      placeholder={placeholder}
      disconnect={disconnect}
      errorPath={errorAmountPath}
      disabled={disabled}
      {...(restProps || {})}
    />
  )

  return (
    <>
      {withoutCurrencySelector ? (
        renderInput({ silent: !disconnect, ...rest })
      ) : (
        <FormGroup id={name} horizontal silent={!disconnect} {...rest}>
          {renderInput()}
          <CurrencySelector
            maxWidth="80px"
            name={`${name}_selector`}
            onItemSelect={handleCurrencySelect}
            placeholder=""
            preselectedId={currency}
            required={required}
            disconnect={disconnect}
            errorPath={errorCurrencyPath}
            disabled={disabled}
            listPlacement="bottom-end"
          />
        </FormGroup>
      )}
    </>
  )
}

// Controlled
export type CurrencyInputControlledResult = {
  currency: string
  value: number
}

export type CurrencyInputProps = Omit<CurrencyInputComponentProps, 'defaultValue'> & {
  formControl?: Control<any>
  defaultValue?: CurrencyInputControlledResult
}

export const CurrencyInput = ({ formControl, name = '', defaultValue, ...rest }: CurrencyInputProps): ReactElement => {
  if (!formControl) {
    return <CurrencyInputComponent {...rest} name={name} />
  }

  return (
    <Controller
      render={({ field }) => {
        const value = field.value as CurrencyInputControlledResult

        return (
          <CurrencyInputComponent
            {...rest}
            name={field.name}
            amount={value?.value || defaultValue?.value}
            currency={value?.currency || defaultValue?.currency}
            onChange={({ amount, currency }: CurrencyInputResult) =>
              field.onChange({
                currency,
                value: amount,
              } as CurrencyInputControlledResult)
            }
            errorAmountPath={`${field.name}.value`}
            errorCurrencyPath={`${field.name}.currency`}
            disconnect
          />
        )
      }}
      control={formControl}
      name={name}
    />
  )
}
