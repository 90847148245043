import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../Button'
import { ButtonDropdown } from '../../../ButtonDropdown'
import { NavItem } from '../../../NavList'
import { TabDropdownItemValue } from '../../utils/getDropdownItemsArray'

interface MoreTabsDropdownProps {
  tabs: NavItem<TabDropdownItemValue>[]
}

export const MoreTabsDropdown = ({ tabs }: MoreTabsDropdownProps) => {
  const { t } = useTranslation()

  const handleSelect = (id: string, value: TabDropdownItemValue) => {
    if (value && value.onClick) {
      value.onClick()
    }
  }

  return (
    <ButtonDropdown<TabDropdownItemValue>
      items={tabs}
      onSelect={handleSelect}
      placement="bottom-end"
      triggerElement={
        <Button variant="text" icon="threeDots" title={t('more')}>
          {t('more')}
        </Button>
      }
    />
  )
}
