import { Divider, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const WidgetsSectionWrapper = styled.div`
  position: relative;
`

export const TitleSection = styled(Divider)`
  margin: ${Spacing.XXXL} 0;
`
