import { TFunction } from 'i18next'

import { PeriodMode } from '../../../../enums/periodMode'

export const getPeriodModeToLabel = (t: TFunction): Record<PeriodMode, string> => ({
  [PeriodMode.All]: t('period_select.mode.all'),
  [PeriodMode.CustomDate]: t('period_select.mode.custom_date'),
  [PeriodMode.CustomDateFrom]: t('period_select.mode.custom_date'),
  [PeriodMode.CustomDateThrough]: t('period_select.mode.custom_date'),
  [PeriodMode.FiscalYear]: t('period_select.mode.fiscal_year'),
  [PeriodMode.Month]: t('period_select.mode.month'),
  [PeriodMode.Quarter]: t('period_select.mode.quarter'),
})
