import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React from 'react'
import { QueryClientProvider } from 'react-query'

import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { SalaryEmberActionsContextProvider, SalaryIntegration } from '@views/salaryIntegration'

import { queryClient } from '../../config/queryClient'
import { EmberConnectBank } from '../../contexts/emberConnectBankContext'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'salary-integration-module'

class SalaryIntegrationWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('openUpgradePlanOverlay') openUpgradePlanOverlay: Function
}

interface SalaryIntegrationModuleProps {
  organizationId: string
  onNavigate: EmberNavigate
  onOpenUpgradePlanOverlay: EmberConnectBank
}

const SalaryIntegrationModule = ({
  organizationId,
  onNavigate,
  onOpenUpgradePlanOverlay,
}: SalaryIntegrationModuleProps) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <CurrentUserContextProvider>
        <UserOrganizationContextProvider organizationId={organizationId}>
          <ModalContextProvider>
            <EmberRouterContextProvider onNavigate={onNavigate}>
              <SalaryEmberActionsContextProvider onOpenUpgradePlanOverlay={onOpenUpgradePlanOverlay}>
                <SalaryIntegration />
              </SalaryEmberActionsContextProvider>
            </EmberRouterContextProvider>
          </ModalContextProvider>
        </UserOrganizationContextProvider>
      </CurrentUserContextProvider>
    </ThemeProvider>
  </QueryClientProvider>
)

const SalaryIntegrationCustomElement = createCustomElement(
  SalaryIntegrationModule,
  SalaryIntegrationWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, SalaryIntegrationCustomElement)

export default SalaryIntegrationCustomElement
