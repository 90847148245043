import styled from '@emotion/styled'

import { alignmentToJustifyContent } from '../../constants/alignmentToJustifyContent'
import { ColumnItemAlignment } from '../../types/columnItemAlignment'

interface ColumnItemWrapperProps {
  alignment: ColumnItemAlignment
}
export const ColumnItemWrapper = styled.div<ColumnItemWrapperProps>`
  display: flex;
  justify-content: ${({ alignment }) => alignmentToJustifyContent[alignment]};
`
