import styled from '@emotion/styled'

export const TablePaginationWrapper = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const SideActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`
