import { ComponentType } from 'react'

import { useComponentToggle } from '../../../hooks/useComponentToggle'
import { transitionToMiliseconds } from '../../../utils/transitionToMiliseconds'
import { MODAL_RENDER_TRANSITION_DURATION } from '../constants/modalRenderTransitionDuration'
import { useModal } from '../context/hooks/useModal'
import { ModalProps } from '../Modal'

export const withModalConditionalRender = <TProps extends ModalProps>(Component: ComponentType<TProps>) => {
  return (props: TProps) => {
    const { isOpen } = useModal(props.id)
    const { isRendered } = useComponentToggle({
      isOpen: isOpen || props?.forceRender,
      animationTime: transitionToMiliseconds(MODAL_RENDER_TRANSITION_DURATION),
    })

    if (!isRendered) {
      return null
    }

    return <Component {...props} />
  }
}
