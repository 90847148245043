export enum SettingsRoute {
  Organization = '/organization',
  OrganizationAccessTokens = '/organization/access_tokens',
  OrganizationAccounting = '/organization/accounting',
  OrganizationAccountingVatReturn = '/organization/accounting/sales_tax_return',
  OrganizationAccountingChartOfAccounts = '/organization/accounting/chart_of_accounts',
  OrganizationBetas = '/organization/betas',
  OrganizationInvoicing = '/organization/invoicing',
  OrganizationSubscription = '/organization/subscription',
  OrganizationSubscriptionPlansSelection = '/organization/subscription/plans_selection',
  OrganizationUsers = '/organization/users-and-accountants',
  OrganizationVatRates = '/organization/vat_rates',
  Profile = '/user/profile',
  ProfileNotifications = '/user/notifications',
  ProfilePrivacy = '/user/privacy',
}
