import { keyframes } from '@emotion/core'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export default {
  hoverMenuWrapper: {
    position: 'fixed',
    zIndex: 4,
    left: '240px',
    '&:after': {
      content: '""',
      width: '110px',
      height: '100%',
      position: 'absolute',
      top: '-4px',
      right: 'calc(100% - 15px)',
      zIndex: -1,
      transform: 'rotate(-12deg)',
      transformOrigin: 'top right',
    },
  },
  hoverMenu: {
    transition: 'all .38s',
    opacity: '0',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '2px',
    whiteSpace: 'nowrap',
    marginLeft: '14px',
    animation: `${fadeIn} .38s ease-in-out`,
    animationFillMode: 'forwards',
  },
  hoverMenuItem: {
    display: 'block',
    marginBottom: '4px',
    cursor: 'pointer',
    lineHeight: '1.5em',
    '&:last-child': {
      marginBottom: '0',
    },
    '> div': {
      borderRadius: '6px',
      display: 'inline-block',
      padding: '0 10px',
      lineHeight: '22px',
    },
    '&:hover': {
      '> div': {
        boxShadow: '0 1px 3px 1px rgba(0,0,0,0.25)',
      },
    },
  },
  hoverMenuDivider: {
    height: '1px',
  },
}
