/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Checkbox /> from design-system instead
 */
import { Spacing, Text } from '@design-system'

import { css, SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'
import { Label as LabelRebass } from '@rebass/forms'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import React, { ChangeEvent, ReactElement } from 'react'
import { Control, Controller } from 'react-hook-form'
import { BoxProps } from 'rebass'

import { ConnectForm, ConnectFormType } from './ConnectForm'
import { ErrorMessage } from './ErrorMessage'
import { FormControl } from './FormControl'
import { InputProps } from './Input'
import * as Styled from './styles'

const LabelText = styled(Text)`
  margin-left: ${Spacing.S};
`

type CheckboxComponentProps = Omit<InputProps, 'onChange'> &
  Omit<BoxProps, 'onChange' | 'label'> & {
    css?: SerializedStyles
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  }

const CheckboxComponent = ({
  checked,
  css: styles,
  defaultChecked,
  disconnect,
  disabled,
  id,
  label,
  name = 'checkbox-field',
  onChange = () => null,
  options = {},
  required,
  ...rest
}: CheckboxComponentProps): ReactElement => {
  const inputId = id || uniqueId('check-')

  return (
    <ConnectForm>
      {({ formState, register }: ConnectFormType) => {
        const errors = formState?.errors || {}
        const error = name ? get(errors, name) : undefined

        return (
          <FormControl
            css={[
              css`
                display: flex;
                margin-right: ${Spacing.L};
                flex-direction: column;

                svg {
                  margin-right: 5px;
                  width: 15px;
                  height: 15px;
                }
              `,
              styles,
            ]}
            {...rest}
          >
            <LabelRebass
              htmlFor={inputId}
              css={css`
                display: flex;
                justify-content: flex-start;
                ${!disabled ? 'cursor: pointer' : ''};
              `}
            >
              <Styled.Input
                name={name}
                type="checkbox"
                checked={checked}
                error={!!error}
                defaultChecked={defaultChecked}
                disabled={disabled}
                id={inputId}
                onChange={onChange}
                css={css`
                  margin-top: ${Spacing.XXS};
                `}
                {...(!disconnect && register ? register(name, { required, ...options }) : undefined)}
              />
              {label && (
                <LabelText as="span" colorVariant="secondary">
                  {label}
                </LabelText>
              )}
              {required && (
                <Text as="span" inherit>
                  *
                </Text>
              )}
            </LabelRebass>
            <ErrorMessage error={(name && errors[name]) || undefined} />
          </FormControl>
        )
      }}
    </ConnectForm>
  )
}

// Controlled
export type CheckboxControlledResult = boolean

export type CheckboxProps = CheckboxComponentProps & {
  formControl?: Control<any>
}

export const Checkbox = ({ formControl, name = '', ...rest }: CheckboxProps): ReactElement => {
  if (!formControl) {
    return <CheckboxComponent {...rest} name={name} />
  }

  return (
    <Controller
      render={({ field }) => (
        <CheckboxComponent
          {...rest}
          name={field.name}
          value={field.value}
          checked={field.value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => field.onChange(event.currentTarget.checked)}
          disconnect
        />
      )}
      control={formControl}
      name={name}
    />
  )
}
