import { Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface GuideHeaderProps {
  title?: ReactNode
  description?: ReactNode
}

export const GuideHeader = ({ title, description, ...rest }: GuideHeaderProps): ReactElement => (
  <Styled.GuideHeaderWrapper {...rest}>
    {title && <Styled.Title variant="displayS">{title}</Styled.Title>}
    {description && <Text variant="large">{description}</Text>}
  </Styled.GuideHeaderWrapper>
)
