import colors from '../colors'

export default {
  menuSearchResultsWrapper: {
    background: colors.menuActive,
    '::-webkit-scrollbar-thumb': {
      backgroundColor: colors.primaryDarkend,
    },
  },
  menuSearchResultsWrapperEmpty: {
    background: colors.menuActive,
  },
  menuSearchResult: {
    color: colors.white,
    '&:hover': {
      background: colors.menuBackground,
    },
  },
}
