import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { BillReadable } from '../../elements/BillReadable'
import { BillUrlParams } from '../../types/billUrlParams'

export const BillView = (): ReactElement => {
  const { billId } = useParams<BillUrlParams>()

  return <BillReadable billId={billId as string} />
}
