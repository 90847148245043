import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../utils'
import { deleteCurrencyDefaultPaymentMethod } from '../query-api'

interface UseDeleteCurrencyDefaultPaymentMethodProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useDeleteCurrencyDefaultPaymentMethod = (props?: UseDeleteCurrencyDefaultPaymentMethodProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate,
    isLoading: isProcessing,
    ...rest
  } = useMutation(deleteCurrencyDefaultPaymentMethod, { onSuccess, onError })

  return { delete: mutate, isProcessing, ...rest }
}
