import { Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

interface InvoiceItemProps {
  clickable?: boolean
  onClick?: () => void
}

export const InvoiceItemWrapper = styled.li<InvoiceItemProps>`
  width: 30%;
  margin-top: -${Spacing.L};

  & + & {
    margin-left: 5%;
  }

  ${({ clickable }) =>
    clickable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`
