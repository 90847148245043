import { amountToDisplayValue } from '@design-system'

import isNumber from 'lodash/isNumber'

import { TransactionSide } from '../../../types/transactionSide'

export const getTransactionSideAmount = (amount: number | null, side: TransactionSide, currency: string | null) => {
  if (!side || !isNumber(amount)) {
    return undefined
  }

  if (amount === 0) {
    return `${amountToDisplayValue(amount)} ${currency}`
  }

  return `${side === 'credit' ? '' : '-'}${amountToDisplayValue(amount)} ${currency}`
}
