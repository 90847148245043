import { Color, Text, TextVariant } from '@design-system'

import React, { ReactElement } from 'react'

import * as Styled from './styles'

export interface InfoItemProps {
  label: string
  labelIcon?: ReactElement
  amount: string
  amountColor?: Color
  amountTextVariant?: TextVariant
}

export const InfoItem = ({ label, labelIcon, amount, amountColor, amountTextVariant }: InfoItemProps) => {
  return (
    <Styled.InfoItemWrapper>
      <Text variant={amountTextVariant} color={amountColor}>
        {amount}
      </Text>

      <Styled.LabelWrapper>
        <Text variant="micro" colorVariant="secondary" weight="regular">
          {label}
        </Text>
        {labelIcon && <Styled.IconWrapper>{labelIcon}</Styled.IconWrapper>}
      </Styled.LabelWrapper>
    </Styled.InfoItemWrapper>
  )
}
