import { Color } from '@design-system'

import { StatsContact } from '../services/query-api'

const DEFAULT_COLOR = Color.Red

export const getUsersWithColors = (users: StatsContact[], colors: Color[]) => {
  // Filter out not needed element with stats
  const filteredUsers = users.filter(({ rest }) => !rest)

  return filteredUsers.map((user, index) => ({
    ...user,
    value: user.amount,
    color: colors[index] || DEFAULT_COLOR,
  }))
}
