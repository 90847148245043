import { Color, DefaultValue, getDefaultTransition } from '@design-system'

import styled from '@emotion/styled'

export const FileItemWrapper = styled.div`
  width: 100%;
  height: 128px;
  position: relative;
  border-radius: ${DefaultValue.BorderRadius};
  border: 1px solid ${Color.Gray50};
  overflow: hidden;
  cursor: pointer;
  ${getDefaultTransition('box-shadow')};

  &:hover {
    box-shadow: 0 0 0 2px ${Color.Green120};
  }
`
