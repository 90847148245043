import { getRequest } from '../../../utils'
import { UserOrganization } from './types'

interface UserOrganizationsResponseData {
  data: UserOrganization[]
}

export const fetchUserOrganizations = (): Promise<UserOrganizationsResponseData> => {
  return getRequest('/user/organizations')
}
