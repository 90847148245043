import difference from 'lodash/difference'

import { Scope } from '../enums/scope'
import { getGlobalBillyIsNewScopesVersion, getGlobalBillyScopes } from './globalBilly'

/**
 * @global IMPORTANT NOTE - when using this function, please do not memoize it, as it checks the global window object which might change
 */
export const isAuthorized = (
  requiredScopes: Scope | Scope[],
  shouldCheckLegacyScopes = false,
  isLegacyPermissionsAuthorized = true,
): boolean => {
  // TODO: Remove legacy if statement when all users got migrated to the new scope version
  if (!getGlobalBillyIsNewScopesVersion() && !shouldCheckLegacyScopes) {
    return isLegacyPermissionsAuthorized
  }

  // Ignore nullable or empty array scopes check
  if (!requiredScopes || !requiredScopes.length) {
    return true
  }

  const requiredScopesArray = Array.isArray(requiredScopes) ? requiredScopes : [requiredScopes]
  const userScopes = getGlobalBillyScopes() || []

  // Check that there's no scope which is not authorized
  return !difference(requiredScopesArray, userScopes).length
}
