import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const ContentWrapper = styled.div`
  max-width: 466px;
  margin-top: ${Spacing.XXL};
`
