import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { darken } from 'polished'

import { DefaultValue } from '../../enums/defaultValue'
import { Spacing } from '../../enums/spacing'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { Variant } from './types/variant'
import { variantStyles } from './utils/variantStyles'

interface TagWrapperProps {
  clickable: boolean
  variant: Variant
}

export const TagWrapper = styled.span<TagWrapperProps>`
  display: inline-flex;
  height: 22px;
  padding: 0 ${Spacing.S};
  border-radius: ${DefaultValue.BorderRadius};
  align-items: center;
  background-color: ${({ variant }) => variantStyles[variant].bgColor};
  white-space: nowrap;

  ${({ clickable, variant }) =>
    clickable &&
    css`
      cursor: pointer;
      ${getDefaultTransition('background-color')};

      &:hover {
        background-color: ${darken(0.05, variantStyles[variant].bgColor)};
      }
    `};
`

export const CloseIconWrapper = styled.div`
  display: flex;
  margin-left: ${Spacing.XXS};
  cursor: pointer;
`
