import { useQuery } from 'react-query'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { getUmbrellaLicensePacks } from '../query-api'

export const useUmbrellaLicensePacks = () => {
  const { umbrella } = useUmbrella()

  const { data, isIdle, isLoading } = useQuery(
    [QueryKeys.UmbrellaLicensePacks, umbrella?.id],
    () => getUmbrellaLicensePacks(umbrella?.id as string),
    {
      enabled: !!umbrella?.id,
    },
  )

  return {
    umbrellaLicensePacks: data?.data || [],
    isLoading: isIdle || isLoading,
  }
}
