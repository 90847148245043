import { useMemo } from 'react'

import { fetchContactById } from '@modules-deprecated/app/contacts'

import { QueryKeys } from '../../../enums/queryKeys'
import { useQueriesTyped } from '../../../hooks/useQueriesTyped'
import { Contact } from '../types/contact'

const isContact = (contact: Contact | undefined): contact is Contact => {
  return !!contact
}

export const useFetchContactsByIds = (contactIds: string[]) => {
  const results = useQueriesTyped(
    contactIds.map((contactId) => ({
      queryKey: [QueryKeys.Contact, contactId],
      queryFn: () => fetchContactById(contactId),
      enabled: !!contactId,
    })) || [],
  )

  const contacts = useMemo(() => results.map((result) => result.data?.contact).filter(isContact), [results])
  const isLoading = useMemo(() => results.some((result) => result.isLoading), [results])
  const isError = useMemo(() => results.some((result) => result.isError), [results])

  return { contacts, isLoading, isError }
}
