import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { fetchSubscriptionBilling, FetchSubscriptionBillingResponse } from '../services/query-api'

interface UseFetchSubscriptionBillingProps {
  onError?: (error?: APIError) => void
  onSuccess?: (data: FetchSubscriptionBillingResponse) => void
}

export const useFetchSubscriptionBilling = (props?: UseFetchSubscriptionBillingProps) => {
  const { onError, onSuccess } = props || {}
  const { mutate: fetch, ...rest } = useMutation(fetchSubscriptionBilling, { onSuccess, onError })

  return { fetch, ...rest }
}
