import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface ModalNavigationProps {
  children?: ReactNode
}

export const ModalNavigation = ({ children }: ModalNavigationProps): ReactElement => {
  return <Styled.ModalNavigationWrapper>{children}</Styled.ModalNavigationWrapper>
}
