import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../../../config/queryClient'
import { SortDirection } from '../../../../../enums/sortDirection'
import { fetchAccountStatements, FetchAccountStatementsPayload } from '../query-api'
import { getAccountStatementsQueryKey } from '../utils/getAccountStatementsQueryKey'

interface UseFetchAccountStatements {
  accountId: string
  period?: string
  searchQuery?: string
  sortDirection?: SortDirection
  withVoided?: boolean
}

export const useFetchAccountStatements = ({
  accountId,
  period,
  searchQuery,
  sortDirection,
  withVoided,
}: UseFetchAccountStatements) => {
  const queryProps: FetchAccountStatementsPayload = useMemo(
    () => ({
      accountId,
      includeVoided: withVoided,
      period,
      q: searchQuery,
      sortDirection,
    }),
    [accountId, period, searchQuery, sortDirection, withVoided],
  )
  const accountStatementsQueryKey = useMemo(() => getAccountStatementsQueryKey(queryProps), [queryProps])

  const { data, ...rest } = useQuery(accountStatementsQueryKey, () => fetchAccountStatements(queryProps), {
    staleTime: defaultStaleTime,
    enabled: !!accountId,
  })

  return { accountStatement: data?.accountStatement, ...rest }
}
