import { getTransactionsQueryKey, TransactionSortProperty, TransactionsQueryProps } from '@views/transactions'

import { QueryKeys } from '../../../../../../../enums/queryKeys'

interface BillTransactionsQueries {
  queryKey: [QueryKeys, TransactionsQueryProps]
  queryProps: TransactionsQueryProps
}

export const getBillTransactionsQueries = (billId: string): BillTransactionsQueries => {
  const queryProps: TransactionsQueryProps = {
    originatorReference: `bill:${billId}`,
    sortProperty: TransactionSortProperty.TransactionNo,
    withVoided: true,
  }

  return {
    queryKey: getTransactionsQueryKey(queryProps),
    queryProps,
  }
}
