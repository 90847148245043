import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { fetchFile } from '../query-api'

export const useOrganizationImage = (fileId?: string) => {
  const {
    data: imageData,
    isIdle,
    isLoading: isImageLoading,
  } = useQuery([QueryKeys.OrganizationImage, fileId], () => fetchFile(fileId as string), {
    enabled: !!fileId,
    keepPreviousData: true,
  })

  const isLoading = !!fileId && (isIdle || isImageLoading)

  return {
    url: imageData?.file?.downloadUrl,
    isLoading,
  }
}
