import { css } from '@emotion/core'

export const getGridTemplateColumnsStyle = (itemsInRow: number, itemsTemplate?: number[]) => {
  if (itemsTemplate) {
    return css`
      grid-template-columns: ${itemsTemplate.map((fraction) => `${fraction}fr`).join(' ')};
    `
  }

  return css`
    grid-template-columns: repeat(${itemsInRow}, 1fr);
  `
}
