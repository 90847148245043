import { ItemsPerPage, useItemsPerPage } from '@design-system'

import { useMemo } from 'react'

import { useQueryParams } from '../../../../../hooks/useQueryParams'

export interface RecurringInvoicesListFilters {
  page?: number
  pageSize?: ItemsPerPage
  searchQuery?: string
  sort?: string
}

type UseRecurringInvoicesFiltersResponse = [
  RecurringInvoicesListFilters,
  (filters: Partial<RecurringInvoicesListFilters>) => void,
]

export const defaultQuery: RecurringInvoicesListFilters = {
  page: 1,
  pageSize: undefined,
  searchQuery: undefined,
  sort: 'recurringDate',
}

export const useRecurringInvoicesListFilters = (): UseRecurringInvoicesFiltersResponse => {
  const [pageSize] = useItemsPerPage()
  const [queryParams, setQueryParams] = useQueryParams<RecurringInvoicesListFilters>({
    defaultQuery: {
      ...defaultQuery,
      pageSize,
    },
  })

  return useMemo(
    () => [
      {
        ...queryParams,
        // Sometimes `page` comes as a string. It needs to be always `number` type.
        page: Number(queryParams.page),
        // Sometimes `pageSize` comes as string. It needs to be always `number` type.
        pageSize: Number(queryParams.pageSize) as ItemsPerPage,
      },
      setQueryParams,
    ],
    [queryParams, setQueryParams],
  )
}
