import { BankConnection, BankConnectionOld } from '@modules-deprecated/bankReconciliation/types'

import { isOldBankConnection } from '../../../../../utils/isOldBankConnection'

export const getAccountData = (connectedBank?: BankConnectionOld | BankConnection) => {
  if (!connectedBank) {
    return
  }

  if (isOldBankConnection(connectedBank)) {
    const { balance, accountName, accountId: externalAccountId, type } = connectedBank
    return { accountName, balance, connection: null, currency: null, externalAccountId, id: null, type }
  } else {
    const { balance, connection, currency, externalAccountId, id, type, name: accountName } = connectedBank
    return { accountName, balance, connection, currency, externalAccountId, id, type }
  }
}
