import { NavItem } from '..'

export const getNavItemsFlat = <T>(items: Array<NavItem<T>>) =>
  items.reduce((result: Array<NavItem<T>>, item) => {
    const itemsFlat = [...result, item]

    if (item.subItems?.length) {
      itemsFlat.push(...getNavItemsFlat(item.subItems))
    }

    return itemsFlat
  }, [])
