import { differenceInDays } from 'date-fns/esm'
import sortBy from 'lodash/sortBy'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import {
  fetchSalesTaxReturnAccountReport,
  fetchTaxReturnPeriods,
  SalesTaxReturnAccountReportResponse,
} from '../query-api'
import { TaxReturn } from '../types/taxReturn'

interface UseVatPeriod {
  salesTaxReturnPeriod?: TaxReturn
  accountReport?: SalesTaxReturnAccountReportResponse
  isLoading?: boolean
  isError?: boolean
}

const MAX_NUMBER_DUE_DAYS = 20

export const useVatPeriod = (organizationId?: string): UseVatPeriod => {
  const [salesTaxReturnPeriod, setSalesTaxReturnPeriod] = useState<TaxReturn>()

  const {
    data: taxReturnPeriods,
    isLoading: arePeriodsLoading,
    isError: taxReturnPeriodsError,
  } = useQuery([QueryKeys.TaxReturnPeriods, organizationId], () => fetchTaxReturnPeriods(organizationId || ''), {
    enabled: !!organizationId,
  })

  const {
    data: accountReport,
    isLoading: isPeriodLoading,
    isError: taxReturnPeriodError,
  } = useQuery(
    [QueryKeys.SalesTaxReturn, salesTaxReturnPeriod?.id],
    () =>
      fetchSalesTaxReturnAccountReport({
        salesTaxReturnId: salesTaxReturnPeriod?.id || '',
        noHtml: true,
        noRaw: true,
      }),
    {
      enabled: !!salesTaxReturnPeriod,
    },
  )

  const canPeriodBeSettled = (period: TaxReturn): boolean => {
    const reportDeadlineDate = new Date(period.reportDeadline)
    const todaysDate = new Date()
    return !period.isSettled && differenceInDays(todaysDate, reportDeadlineDate) <= MAX_NUMBER_DUE_DAYS
  }

  const findTaxReturnPeriodToDisplay = (periods: TaxReturn[]) => {
    const periodsThatCanBeSettled = periods.filter(canPeriodBeSettled)
    const periodsThatCanBeSettledSortedByDate = sortBy(periodsThatCanBeSettled, 'reportDeadline')
    setSalesTaxReturnPeriod(periodsThatCanBeSettledSortedByDate[0])
  }

  useEffect(() => {
    if (taxReturnPeriods?.salesTaxReturns) {
      findTaxReturnPeriodToDisplay(taxReturnPeriods.salesTaxReturns)
    }
  }, [taxReturnPeriods?.salesTaxReturns])

  const isLoading = arePeriodsLoading || isPeriodLoading
  const isError = taxReturnPeriodsError || taxReturnPeriodError

  return { isLoading, isError, accountReport, salesTaxReturnPeriod }
}
