import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { getSalesTaxRulesetDisplayData } from '../../../../utils/getSalesTaxRulesetDisplayData'
import { SalesTaxRuleset } from '../types/salesTaxRuleset'
import { SalesTaxRulesetValue } from '../types/salesTaxRulesetValue'

export const getItems = (salesTaxRulesets: SalesTaxRuleset[], t: TFunction): NavItem<SalesTaxRulesetValue>[] => {
  return salesTaxRulesets.map((salesTaxRuleset) => {
    const { description, id, name, predefinedTag } = salesTaxRuleset
    const { description: displayedDescription, title: displayedTitle } = getSalesTaxRulesetDisplayData({
      description,
      name,
      predefinedTag,
      t,
    })

    return {
      id,
      children: displayedTitle,
      value: {
        ...salesTaxRuleset,
        displayData: {
          description: displayedDescription,
          title: displayedTitle,
        },
      },
    }
  })
}
