import { NavItem, NavListItemWithInlineDescription, Select, SelectProps } from '@design-system'

import omit from 'lodash/omit'
import React, { forwardRef, ReactElement, Ref, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'

import { fetchCountries } from '@modules-deprecated/app/countries/query-api'
import { Country } from '@modules-deprecated/app/countries/types'

import { QueryKeys } from '../../../enums/queryKeys'
import { useLocaleCode } from '../../../hooks/useLocaleCode'
import { CountryIsoCode } from '../../../types/countryIsoCode'
import { CountryFlag } from '../../CountryFlag'
import { CountryValue } from './types/countryValue'
import { getItems } from './utils/getItems'

export interface CountrySelectProps
  extends Omit<SelectProps<CountryValue>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: Country) => void
}

export const CountrySelect = forwardRef(
  ({ onSelect, ...selectProps }: CountrySelectProps, ref: Ref<HTMLDivElement | null>): ReactElement => {
    const locale = useLocaleCode()

    const { data, isLoading } = useQuery([QueryKeys.Countries, locale], () => fetchCountries(locale))

    const items = useMemo(() => getItems(data?.countries || []), [data?.countries])

    const selectedItem = useMemo(
      () => items.find(({ id }) => selectProps.selectedId === id),
      [selectProps.selectedId, items],
    )

    const renderSuffix = useCallback(
      () => (selectedItem ? <CountryFlag countryCode={selectedItem.value.icon as CountryIsoCode} /> : null),
      [selectedItem],
    )

    const getDisplayValue = useCallback((item?: NavItem<CountryValue>) => {
      if (item) {
        return item.value.name
      }

      return ''
    }, [])

    const handleSelect = useCallback(
      (id?: string, value?: CountryValue) => {
        const valueFiltered = omit(value, 'displayData')
        onSelect?.(id, valueFiltered)
      },
      [onSelect],
    )

    return (
      <Select
        {...selectProps}
        dropdownFetching={isLoading}
        dropdownItemRender={(props) => <NavListItemWithInlineDescription {...props} />}
        getDisplayValue={getDisplayValue}
        items={items}
        onSelect={handleSelect}
        ref={ref}
        suffix={renderSuffix()}
      />
    )
  },
)
