import styled from '@emotion/styled'

export const ReportsPeriodFiltersWrapper = styled.div`
  display: flex;
`

export const ReportsComparisonSelectWrapper = styled.div`
  min-width: 205px;
`

export const ReportsComparisonPeriodSelect = styled.div`
  min-width: 75px;
`
