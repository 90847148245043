import { SettingsSection } from '@components'
import { Button, Space, Text } from '@design-system'

import { useTranslation } from 'react-i18next'

import { SettingsRoute } from '../../../../enums/settingsRoute'

export const OtherSettings = () => {
  const { t } = useTranslation()

  return (
    <SettingsSection>
      <SettingsSection.RowsContainer>
        <SettingsSection.Content title={t('settings.organization.accounting.sales_tax_return_settings')}>
          <Text colorVariant="secondary">
            {t('settings.organization.accounting.sales_tax_return_settings.subtext')}
          </Text>
          <Space size="l" />
          <Button variant="secondary" to={SettingsRoute.OrganizationAccountingVatReturn}>
            {t('settings.organization.accounting.sales_tax_return_settings.edit')}
          </Button>
        </SettingsSection.Content>
        <SettingsSection.Content title={t('settings.organization.accounting.chart_of_accounts')}>
          <Text colorVariant="secondary">{t('settings.organization.accounting.chart_of_accounts.subtext')}</Text>
          <Space size="l" />
          <Button variant="secondary" to={SettingsRoute.OrganizationAccountingChartOfAccounts}>
            {t('settings.organization.accounting.chart_of_accounts.edit')}
          </Button>
        </SettingsSection.Content>
      </SettingsSection.RowsContainer>
    </SettingsSection>
  )
}
