import { NavItem } from '@design-system'

import { getYear } from 'date-fns'

import { PeriodMode } from '../../../../enums/periodMode'
import { PeriodValue } from '../../../../types/periodValue'

const isYearDisabled = (year: number, minDate?: Date, maxDate?: Date) => {
  if (minDate && year < getYear(minDate)) {
    return true
  }

  if (maxDate && year > getYear(maxDate)) {
    return true
  }

  return false
}

export const getFiscalYearItems = (yearsRange: string, minDate?: Date, maxDate?: Date): NavItem<PeriodValue>[] => {
  const [yearFrom, yearTo] = yearsRange.split(':')
  const itemsCount = Number(yearTo) - Number(yearFrom) + 1
  const yearItems: NavItem<PeriodValue>[] = []

  for (let i = 0; i < itemsCount; i++) {
    const year = Number(yearFrom) + i

    yearItems.push({
      id: String(year),
      children: year,
      value: {
        value: year,
        year,
        mode: PeriodMode.FiscalYear,
        disabled: isYearDisabled(year, minDate, maxDate),
      },
    })
  }

  return yearItems
}
