import { DateFormatter } from '@components'
import { TableColumn } from '@design-system'

import { TFunction } from 'i18next'

import { ContactsTableAccessor } from '../../../enums/ContactsTableAccessor'
import { ContactsTableRow } from '../hooks/useContactsTableData'

export const getTableColumns = (t: TFunction): TableColumn<ContactsTableRow>[] => {
  const tableColumns: TableColumn<ContactsTableRow>[] = [
    {
      accessor: ContactsTableAccessor.ContactNo,
      Header: t('contacts.table.headers.contact_no'),
      truncate: true,
      hidden: true,
    },
    {
      accessor: ContactsTableAccessor.Name,
      Header: t('contacts.table.headers.name'),
      sortable: true,
      truncate: true,
    },
    {
      accessor: ContactsTableAccessor.Type,
      Header: t('contacts.table.headers.type'),
      truncate: true,
      hidden: true,
    },
    {
      accessor: ContactsTableAccessor.ContactPersonEmail,
      Header: t('contacts.table.headers.email'),
      truncate: true,
    },
    {
      accessor: ContactsTableAccessor.Phone,
      Header: t('contacts.table.headers.phone'),
      truncate: true,
    },
    {
      accessor: ContactsTableAccessor.Country,
      Header: t('contacts.table.headers.country'),
      truncate: true,
    },
    {
      accessor: ContactsTableAccessor.CreatedTime,
      Header: t('contacts.table.headers.createdTime'),
      sortable: true,
      Cell: ({ value: date }) => <DateFormatter value={date} />,
    },
  ]

  return tableColumns
}
