import React, { createContext, ReactNode, useContext } from 'react'
import { useLocalStorage } from 'react-use'

import { LocalStorageKeys } from '../enums/localStorageKeys'

type InboxOrganizationIdSetter = React.Dispatch<React.SetStateAction<string | undefined>>

export const InboxOrganizationIdContext = createContext<[string, InboxOrganizationIdSetter] | undefined>(undefined)

interface InboxOrganizationIdContextProviderProps {
  children: ReactNode
}

export const InboxOrganizationIdContextProvider = ({ children }: InboxOrganizationIdContextProviderProps) => {
  const [inboxOrganizationId, setInboxOrganizationId] = useLocalStorage(LocalStorageKeys.InboxOrganizationId, '')

  return (
    <InboxOrganizationIdContext.Provider value={[inboxOrganizationId || '', setInboxOrganizationId]}>
      {children}
    </InboxOrganizationIdContext.Provider>
  )
}

export const useInboxOrganizationId = () => {
  const context = useContext(InboxOrganizationIdContext)

  if (!context) {
    throw new Error('InboxOrganizationIdContextProvider is missing in the module!')
  }

  return context
}
