import { Button, Tooltip } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'

import { DELETE_SHORTCUT } from '../../../../constants/shortcuts'
import { useBillForm } from '../BillEditableForm/contexts/billFormContext'

interface BillDeleteActionProps {
  onClick?: () => void
}

export const BillDeleteAction = ({ onClick }: BillDeleteActionProps): ReactElement => {
  const { t } = useTranslation()
  const { processingAction, bulkEditMode, isFetching } = useBillForm()

  const isDisabled = isFetching || !!processingAction

  const handleDeleteShortcut = useCallback(() => {
    onClick?.()
  }, [onClick])

  useHotkeys(
    DELETE_SHORTCUT,
    handleDeleteShortcut,
    { enabled: !isDisabled, enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] },
    [handleDeleteShortcut],
  )

  return (
    <>
      {bulkEditMode ? (
        <Tooltip label={t('bill.action.delete')} placement="bottom">
          <Button disabled={isDisabled} icon="trash" onClick={onClick} variant="secondary" />
        </Tooltip>
      ) : (
        <Button disabled={isDisabled} icon="trash" onClick={onClick} variant="secondary">
          {t('bill.action.delete')}
        </Button>
      )}
    </>
  )
}
