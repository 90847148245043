import { addDays, format, startOfMonth } from 'date-fns'
import orderBy from 'lodash/orderBy'
import numeral from 'numeral'

import { DATE_FORMAT } from '../../components-deprecated/FiscalDatePicker/helpers'
import { SpecificState } from '../../types/reduxSaga-deprecated'
import { request } from '../../utils'
import {
  BankLine,
  BankLineMatch,
  BankLineSubjectAssociation,
  BankReconciliationState,
  Bill,
  BillyTransaction,
  Difference,
  DifferenceType,
  DifferenceTypePayload,
  ExtendedBankLineMatch,
  GetBankLineMatchesResponse,
  Invoice,
  Posting,
  PostingWithTransaction,
  ReconcilablePostingSubType,
  ReconcilablePostingType,
} from './types'

type DNDSignature = {
  id: string
  fullMatchId: string
  fullSourceMatchId: string
  bankLineMatches: ExtendedBankLineMatch[]
  billyTransactions: BillyTransaction[]
  sortProperty?: string
  sortDirection?: number
  transactionsSortProperty?: string
  transactionsSortDirection?: number
}

type DNDResult = {
  bankLineMatches: ExtendedBankLineMatch[]
  billyTransactions: BillyTransaction[]
}

type UngroupBankLineQuery = {
  bankLineMatches: ExtendedBankLineMatch[] | null
  bankLine: BankLine
  newBankLineMatch: ExtendedBankLineMatch
  previousBankLineMatchId: string
  sortProperty: string
  sortDirection: number
}

type UngroupBankLineResult = {
  bankLineMatches: ExtendedBankLineMatch[]
}

type PlaceholderSignature = {
  source?: any
  type?: string
  bankLineMatches: ExtendedBankLineMatch[]
  billyTransactions: BillyTransaction[]
}

type CalcDifferenceProps = {
  bankLines: BankLine[]
  matchedBillyTransactions: BillyTransaction[]
}

/**
 * Sort an array chronologically by property entryDate
 * @return Shallow copy of the array sorted by entryDate
 */
export const sortByEntryDate = (a: { entryDate: string }, b: { entryDate: string }) => {
  return b.entryDate < a.entryDate ? 1 : -1
}

export const sortBankMatchesByEntryDate = (a: { entryDate: string }, b: { entryDate: string }) => {
  return b.entryDate < a.entryDate ? 1 : -1
}

/**
 * Split a string id at the colon
 * @param id: string the id to split
 * @return  an array of string
 */
export const cleanId = (id: string) => id.split(':')

/**
 * Specifically extract the associated billy transactions in a group match
 * @param blsa The match group
 * @param res The response from API
 * @param allBillyTransactions
 * @return The record enriched with its matched billy transactions
 */
export const findSubjectAssociation = (
  blsa: BankLineSubjectAssociation,
  res: GetBankLineMatchesResponse,
  allBillyTransactions: BillyTransaction[],
) => {
  const [reference, referenceId] = blsa.subjectReference?.split(':')
  const details = res[`${reference}s`].find((record: BillyTransaction) => record.id === referenceId)
  const extendedDetails = allBillyTransactions.find((billyTransaction: BillyTransaction) => {
    return billyTransaction.id === referenceId
  })

  return {
    ...blsa,
    ...details,
    ...extendedDetails,
  }
}

/**
 * Populate (and sort by date) each match group with its related bank lines
 * @param match the raw match group to extend
 * @param res the response from API
 * @return The match group enriched with its related bank lines ordered by property entryDate
 */
export const makeBankLines = (match: BankLineMatch, res: GetBankLineMatchesResponse): BankLine[] => {
  if (!Array.isArray(match.lineIds)) {
    return []
  }
  return match.lineIds.map((id) => res.bankLines.find((line) => line.id === id) as BankLine).sort(sortByEntryDate)
}

/**
 * Populate (and sort by date) eacg match group with its related billy transactions
 * @param match The match group to enrich
 * @param res The provided blsas
 * @param allBillyTransactions
 * @return The match group enriched with its relate billy transactions ordered by property entryDate
 */
export const makeMatchedBillyTransactions = (
  match: BankLineMatch,
  res: GetBankLineMatchesResponse,
  allBillyTransactions: BillyTransaction[],
): BillyTransaction[] => {
  if (!Array.isArray(match.subjectAssociationIds)) {
    return []
  }

  return match.subjectAssociationIds
    .map((id: string) => {
      return (res.bankLineSubjectAssociations || []).find((blsa: BankLineSubjectAssociation) => blsa.id === id)
    })
    .filter((blsa) => !!blsa)
    .map((blsa?: BankLineSubjectAssociation) => ({
      // The ? is required to please TypeScript even though it is not needed
      ...blsa,
      ...findSubjectAssociation(blsa as BankLineSubjectAssociation, res, allBillyTransactions),
    }))
    .sort(sortByEntryDate)
}

/**
 * Logic for DND and match bank lines across match groups
 * @param id draggable id
 * @param fullMatchId destination droppable full id
 * @param fullSourceMatchId source droppable full id
 * @param bankLineMatches all match groups
 * @param billyTransactions all billy transactions still in source
 * @return Updates matches and Billy transactions
 */
export const dndBankLines = ({
  id,
  fullMatchId,
  fullSourceMatchId,
  bankLineMatches = [],
  billyTransactions = [],
  transactionsSortProperty,
  transactionsSortDirection,
}: DNDSignature): DNDResult => {
  const [, matchId] = cleanId(fullMatchId)
  const [, sourceMatchId] = cleanId(fullSourceMatchId)
  // Get item to be relocated
  const match = bankLineMatches.find((match: ExtendedBankLineMatch) => match.id === sourceMatchId)

  if (!match) {
    return { bankLineMatches, billyTransactions }
  }

  const movingBankLine = match.bankLines.find((line: BankLine) => line.id === id)

  // Guard against errors
  if (!movingBankLine) {
    return { bankLineMatches, billyTransactions }
  }

  // Get destination match/list (where to relocate)
  const destinationMatch = bankLineMatches.find(
    (match: ExtendedBankLineMatch) => match.id === matchId,
  ) as ExtendedBankLineMatch

  // Cancel the drop if it has a Difference selected
  if (destinationMatch?.differenceType && destinationMatch.matchedBillyTransactions.length > 0) {
    return { bankLineMatches, billyTransactions }
  }

  const destinationList = destinationMatch?.bankLines.slice()
  // Update the moving bankLine's matchId to match the new location.
  movingBankLine.matchId = destinationMatch?.id
  // Relocate item into destination list and keep the list sorted chronologically
  destinationList.push(movingBankLine)
  destinationMatch.amount += movingBankLine.side === 'debit' ? movingBankLine.amount : movingBankLine.amount * -1
  destinationList.sort(sortByEntryDate)
  // Get source list, filter out item that has been relocated, and keep the list sorted chronologically
  const sourceList = bankLineMatches
    .find((match: ExtendedBankLineMatch) => match.id === sourceMatchId)
    ?.bankLines.slice()
    .filter((bankLine: BankLine) => bankLine.id !== id)
    .sort(sortByEntryDate)
  // Re-map all bankLineMatches
  const newMatches = bankLineMatches.map((match) => {
    if (match.id === matchId) {
      return { ...match, bankLines: destinationList }
    } // Update destination list
    if (match.id === sourceMatchId) {
      return {
        ...match,
        bankLines: sourceList,
        differenceType: null,
        feeAccountId: null,
        amount: match.amount - (movingBankLine.side === 'debit' ? movingBankLine.amount : movingBankLine.amount * -1),
      } // Update source list
    }
    return match // Otherwise return match unchanged
  })

  // Move back to source Billy transactions left in an empty group (no bank lines)
  let newBillyTransactions = billyTransactions.slice()
  for (const { bankLines, matchedBillyTransactions } of newMatches) {
    if (!bankLines?.length) {
      for (const billyTransaction of matchedBillyTransactions) {
        newBillyTransactions.push(billyTransaction)
      }
    }
  }

  // Remove all not-empty match groups
  const newBankLineMatches = newMatches.filter(({ bankLines }: any) => bankLines.length)

  if (transactionsSortProperty === 'sideAmount') {
    newBillyTransactions = orderBy(
      newBillyTransactions,
      (line) => (line.side === 'debit' ? line.amount : -1 * line.amount),
      transactionsSortDirection === 1 ? 'desc' : 'asc',
    )
  } else {
    newBillyTransactions = orderBy(
      newBillyTransactions,
      transactionsSortProperty,
      transactionsSortDirection === 1 ? 'desc' : 'asc',
    )
  }

  // Return the results
  return {
    bankLineMatches: newBankLineMatches as ExtendedBankLineMatch[],
    billyTransactions: newBillyTransactions as BillyTransaction[],
  }
}

/**
 * Logic for DND and match Billy transactions across the entire sets
 * @param id draggable id
 * @param fullMatchId destination droppable full id
 * @param fullSourceMatchId source droppable full id
 * @param bankLineMatches all match groups
 * @param billyTransactions all billy transactions still in source
 * @return Updates matches and Billy transactions
 */
export const dndBillyTransactions = ({
  id,
  fullMatchId,
  fullSourceMatchId,
  bankLineMatches = [],
  billyTransactions = [],
  sortProperty,
  sortDirection,
}: DNDSignature): DNDResult => {
  const [, matchId] = cleanId(fullMatchId)
  const [, sourceMatchId] = cleanId(fullSourceMatchId)

  // RELOCATE FROM SOURCE TO MATCH GROOUPS
  if (sourceMatchId === 'source') {
    // Get item to be relocated
    const movingBillyTransaction = billyTransactions.find((billyTransaction) => billyTransaction.id === id)

    // Guard against errors
    if (!movingBillyTransaction) {
      return {
        bankLineMatches,
        billyTransactions,
      }
    }

    // Get destination bankLineMatch
    const destinationMatch = bankLineMatches.find((match) => match.id === matchId) as ExtendedBankLineMatch

    // Cancel the drop if it has a Difference selected
    if (destinationMatch?.differenceType && destinationMatch.matchedBillyTransactions.length > 0) {
      return {
        bankLineMatches,
        billyTransactions,
      }
    }

    // Get destination list (where to relocate)
    const destinationList = destinationMatch?.matchedBillyTransactions.slice()
    // Relocate item into destination list and keep the list sorted chronologically
    destinationList.push(movingBillyTransaction)

    // Get source list, filter out item that has been relocated, and keep the list sorted chronologically
    const sourceList = billyTransactions
      .slice()
      .filter((billyTransaction: BillyTransaction) => billyTransaction.id !== id)

    // Re-map all bankLineMatches
    const newMatches = bankLineMatches.map((match) => {
      if (match.id === matchId) {
        return {
          ...match,
          matchedBillyTransactions: destinationList,
          differenceType: null,
          feeAccountId: null,
        } // Update destination list
      }

      return match // Otherwise return match unchanged
    })

    return {
      bankLineMatches: newMatches as ExtendedBankLineMatch[],
      billyTransactions: sourceList,
    }

    // RELOCATE FROM MATCH GROUPS TO SOURCE
  } else if (matchId === 'source') {
    // Get item to be relocated
    const match = bankLineMatches.find((match) => match.id === sourceMatchId)

    // Get destination list (where to relocate)
    let destinationList = billyTransactions.slice()

    if (match) {
      const movingBillyTransaction = match.matchedBillyTransactions.find(
        (billyTransaction: BillyTransaction) => billyTransaction.id === id,
      )
      if (movingBillyTransaction) {
        // Relocate item into destination list and keep the list sorted chronologically
        destinationList.push(movingBillyTransaction)
      }
    }

    if (sortProperty === 'sideAmount') {
      destinationList = orderBy(
        destinationList,
        (line) => (line.side === 'debit' ? line.amount : -1 * line.amount),
        sortDirection === 1 ? 'desc' : 'asc',
      )
    } else {
      destinationList = orderBy(destinationList, sortProperty, sortDirection === 1 ? 'desc' : 'asc')
    }

    // Get source list, filter out item that has been relocated, and keep the list sorted chronologically
    const sourceList = bankLineMatches
      .find((match) => match.id === sourceMatchId)
      ?.matchedBillyTransactions.slice()
      .filter((billyTransaction) => billyTransaction.id !== id)

    // Re-map all bankLineMatches
    const newMatches = bankLineMatches.map((match) => {
      if (match.id === sourceMatchId) {
        return {
          ...match,
          matchedBillyTransactions: sourceList,
          differenceType: null,
          feeAccountId: null,
        } // Update source list
      }
      return match // Otherwise return match unchanged
    })
    return {
      bankLineMatches: newMatches as ExtendedBankLineMatch[],
      billyTransactions: destinationList,
    }

    // RELOCATE BETWEEN MATCH GROUPS
  } else {
    // Get item to be relocated
    const movingBillyTransaction = bankLineMatches
      .find((match) => {
        return match.id === sourceMatchId
      })
      ?.matchedBillyTransactions.find((billyTransaction) => billyTransaction.id === id)

    const destinationMatch = bankLineMatches.find((match) => match.id === matchId) as ExtendedBankLineMatch

    // Cancel the drop if it has a Difference selected
    if (destinationMatch?.differenceType && destinationMatch.matchedBillyTransactions.length > 0) {
      return {
        bankLineMatches,
        billyTransactions,
      }
    }

    // Get destination list (where to relocate)
    const destinationList = destinationMatch?.matchedBillyTransactions.slice()
    // Relocate item into destination list and keep the list sorted chronologically
    destinationList.push(movingBillyTransaction as BillyTransaction)

    // Get source list, filter out item that has been relocated, and keep the list sorted chronologically
    const sourceList = bankLineMatches
      .find((match) => match.id === sourceMatchId)
      ?.matchedBillyTransactions.slice()
      .filter((billyTransaction: BillyTransaction) => billyTransaction.id !== id)

    // Re-map all bankLineMatches
    const newMatches = bankLineMatches.map((match) => {
      if (match.id === matchId) {
        return {
          ...match,
          matchedBillyTransactions: destinationList,
          differenceType: null,
          feeAccountId: null,
        } // Update destination list
      } else if (match.id === sourceMatchId) {
        return {
          ...match,
          matchedBillyTransactions: sourceList,
          differenceType: null,
          feeAccountId: null,
        } // Update source list
      }

      return match // Otherwise return match unchanged
    })

    return {
      bankLineMatches: newMatches as ExtendedBankLineMatch[],
      billyTransactions,
    }
  }
}

/**
 * Logic for removing one bankLine from a match and adding it on a newly created match.
 *
 * @param bankLineMatches BankLineMatches from state
 * @param bankLine The bankLine which was ungrouped
 * @param newBankLineMatch The new match created to hold the ungrouped bankline
 * @param previousBankLineMatchId The previous match that was holding the ungrouped bankline
 */
export const ungroupBankLine = ({
  bankLineMatches,
  bankLine,
  newBankLineMatch,
  previousBankLineMatchId,
  sortProperty,
  sortDirection,
}: UngroupBankLineQuery): UngroupBankLineResult => {
  const bankLineMatchCreated: ExtendedBankLineMatch = {
    ...newBankLineMatch,
    bankLines: [bankLine],
    lineIds: [bankLine.id],
    matchedBillyTransactions: [],
    subjectAssociationIds: [],
  }

  const matches = bankLineMatches
    ? bankLineMatches.map((match) => {
        if (match.id === previousBankLineMatchId) {
          const updatedMatch = {
            ...match,
            bankLines: match.bankLines.filter((line: BankLine) => line.id !== bankLine.id),
            lineIds: match.lineIds.filter((lineId: string) => lineId !== bankLine.id),
            amount: match.amount - (bankLine.side === 'debit' ? bankLine.amount : bankLine.amount * -1),
            differenceType: null,
            feeAccountId: null,
          }

          if (updatedMatch.bankLines.length === 1) {
            updatedMatch.entryDate = updatedMatch.bankLines[0].entryDate
          }

          return updatedMatch
        } else {
          return match
        }
      })
    : []

  let updatedBankLineMatches = [...matches, bankLineMatchCreated]

  if (sortProperty === 'sideAmount') {
    updatedBankLineMatches = orderBy(
      updatedBankLineMatches,
      (line) => (line.side === 'debit' ? line.amount : -1 * line.amount),
      sortDirection === 1 ? 'desc' : 'asc',
    )
  } else {
    updatedBankLineMatches = orderBy(updatedBankLineMatches, sortProperty, sortDirection === 1 ? 'desc' : 'asc')
  }

  return { bankLineMatches: updatedBankLineMatches as ExtendedBankLineMatch[] }
}

/**
 * Logic for creating a temporary DND placeholder
 * @param source source object from handleOnDragStart handler
 * @param type type from handleOnDragStart handler
 * @param matches all match groups
 * @param billyTransactions all billy transactions still in source
 * @return Updates matches and Billy transactions with temporary placeholder
 */
export const createPlaceholder = ({ source, bankLineMatches, billyTransactions }: PlaceholderSignature) => {
  const placeholder = { id: 'placeholder', description: 'PLACEHOLDER' }
  if (source.droppableId === 'billytransaction:source') {
    const newBillyTransactions = billyTransactions.slice()
    newBillyTransactions.splice(source.index, 0, placeholder as BillyTransaction)
    return { bankLineMatches, billyTransactions: newBillyTransactions }
  } else {
    return { bankLineMatches, billyTransactions }
  }
}

/**
 * Logic for removing temporary DND placeholder
 * @param matches all match groups
 * @param billyTransactions all billy transactions still in source
 * @return Removes temporary placeholder from matches and Billy transactions
 */
export const destroyPlaceholders = ({ bankLineMatches, billyTransactions }: PlaceholderSignature) => ({
  // GO THROUGH MATCH GROUPS
  bankLineMatches: bankLineMatches.map((match: ExtendedBankLineMatch) => ({
    ...match,
    // REMOVE PLACEHOLDERS FROM BANK LINES
    bankLines: match.bankLines.filter((line: BankLine) => line.id !== 'placeholder'),
    // REMOVE PLACEHOLDERS FROM MATCHED BILLY TRANSACTIONS
    matchedBillyTransactions: match.matchedBillyTransactions.filter(
      (record: BillyTransaction) => record.id !== 'placeholder',
    ),
  })),
  // REMOVE PLACEHOLDERS FROM BILLY TRANSACTIONS
  billyTransactions: billyTransactions.filter((record: BillyTransaction) => record.id !== 'placeholder'),
})

/**
 * Returns a string representation of the date range starting on the given fiscal year that ends on the specified
 * month and consisting of the days from the first to the last number in the given array
 *
 * @param fiscalYear
 * @param fiscalYearEndMonth 1-indexed fiscalYearEndMonth. Be careful, as Billy uses 1-index but moment and JS use 0-index
 * @param dateRange
 * @param format
 */
export function formatDateRangePeriod(
  fiscalYear: number,
  fiscalYearEndMonth: number,
  dateRange: number[],
  format = DATE_FORMAT,
) {
  return {
    from: formatDateRange(fiscalYear, fiscalYearEndMonth, dateRange[0], format),
    to: formatDateRange(fiscalYear, fiscalYearEndMonth, dateRange[1], format),
  }
}

/**
 * Returns a MMM DD, YYYY formatted representation of the date range starting on the given fiscal year, ending on the specified
 * month and consisting of the given number of days
 *
 * @param fiscalYear
 * @param fiscalYearEndMonth 1-indexed fiscalYearEndMonth. Be careful, as Billy uses 1-index but moment and JS use 0-index
 * @param days
 * @param formatString
 */
export function formatDateRange(
  fiscalYear: number,
  fiscalYearEndMonth: number,
  days: number,
  formatString = DATE_FORMAT,
) {
  // The following code fragment is obvious and redundant, but it is better for it to be verbose so it is completely
  // clear what is being done
  const zeroIndexedFiscalYearEndMonth = fiscalYearEndMonth - 1
  const startOfMonthDate = startOfMonth(
    new Date(fiscalYear, zeroIndexedFiscalYearEndMonth + 1 > 11 ? 0 : zeroIndexedFiscalYearEndMonth + 1, 1),
  )
  return format(addDays(startOfMonthDate, days), formatString)
}

/**
 * Logic for calculating the difference between a group of bankLines and a group of matchedBillyTransactions
 * @param bankLines the grouped banklines
 * @param billyTransactions the billyTransactions matched to the group above
 * @return the difference as a number
 */
export const calcDifference = ({ bankLines, matchedBillyTransactions }: CalcDifferenceProps) => {
  const differenceTypes: DifferenceType[] = []
  const bankLinesAmountSum = bankLines.reduce((acc: number, line: BankLine) => acc + prefixAmount(line), 0) || 0
  const matchedBillyTransactionsAmountSum = matchedBillyTransactions.reduce(
    (acc: number, billyTransaction: BillyTransaction) => acc + prefixAmount(billyTransaction),
    0,
  )

  if (!matchedBillyTransactions.length) {
    return {
      amount: bankLinesAmountSum,
      differenceAmount: 0,
      types: [],
    }
  }

  const isPositive = bankLinesAmountSum > 0 || matchedBillyTransactionsAmountSum > 0

  if (isPositive) {
    if (bankLinesAmountSum > matchedBillyTransactionsAmountSum) {
      differenceTypes.push('overpayment')
    } else {
      differenceTypes.push('underpayment')
      differenceTypes.push('bankFee')
    }
  } else {
    if (bankLinesAmountSum > matchedBillyTransactionsAmountSum) {
      differenceTypes.push('underpayment')
    } else {
      differenceTypes.push('overpayment')
      differenceTypes.push('bankFee')
    }
  }

  return {
    amount: bankLinesAmountSum,
    differenceAmount: numeral(bankLinesAmountSum).subtract(matchedBillyTransactionsAmountSum).value() || 0,
    types: differenceTypes,
  }
}

export function prefixAmount(record: { side?: 'credit' | 'debit'; amount?: number | string } = {}) {
  if (!record.amount) {
    return 0
  }

  return record.side === 'credit' ? Number(record.amount) * -1 : Number(record.amount)
}

export function prefixGrossAmount(record: { side?: 'credit' | 'debit'; grossAmount?: number | null } = {}) {
  if (!record.grossAmount) {
    return 0
  }

  return record.side === 'credit' ? Number(record.grossAmount) * -1 : Number(record.grossAmount)
}

/**
 * Reducer utility
 * @param state BankReconciliationState
 * @param payload
 */
export const selectDifferenceType = (state: BankReconciliationState, payload: DifferenceTypePayload) => {
  return state.bankLineMatches.map((bankLineMatch: ExtendedBankLineMatch) => {
    if (payload.matchId === bankLineMatch.id) {
      return {
        ...bankLineMatch,
        differenceType: payload.type,
      }
    } else {
      return bankLineMatch
    }
  })
}

export const getBankLogoUrl = async (bankId: string): Promise<string | null> => {
  let baseUrl = window.ENV?.bankLogoUrl || process.env.BANK_LOGO_URL || 'https://api.billysbilling.com/images/banks'
  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.substring(0, baseUrl.length - 1)
  }

  const imageUrl = `${baseUrl}/${bankId}.png`
  return new Promise((resolve) => {
    const image = new Image()
    image.onload = () => resolve(imageUrl)
    image.onerror = () => resolve(null)
    image.src = imageUrl
  })
}

export const createBillyTransactionFromBill = (bill: Bill): BillyTransaction => ({
  type: ReconcilablePostingType.Bill,
  subtype: ReconcilablePostingSubType.Bill,
  id: bill.id,
  entryDate: bill.entryDate,
  dueDate: bill.dueDate,
  text: bill.lineDescription,
  accountId: null,
  currencyId: bill.currencyId,
  amount: bill.balance,
  conversionCurrencyId: bill.currencyId,
  convertedAmount: bill.balance,
  // The standard bank side for bill is credit, so a bill credit note is on the debit side
  side: bill.type === 'creditNote' ? 'debit' : 'credit',
  isBankMatched: false,
  originatorType: ReconcilablePostingType.Bill,
  originatorId: bill.id,
  description: `Bill${bill.contactName ? ` from ${bill.contactName}` : ''}`,
  contact: bill.contactName || null,
  sequenceNo: bill.voucherNo,
  orderNo: bill.suppliersInvoiceNo,
  state: bill.state,
})

export const createBillyTransactionFromInvoice = (invoice: Invoice): BillyTransaction => ({
  type: ReconcilablePostingType.Invoice,
  subtype: ReconcilablePostingSubType.Invoice,
  id: invoice.id,
  entryDate: invoice.entryDate,
  dueDate: invoice.dueDate,
  text: invoice.lineDescription,
  accountId: null,
  currencyId: invoice.currencyId,
  amount: invoice.balance,
  conversionCurrencyId: invoice.currencyId,
  convertedAmount: invoice.balance,
  // The standard bank side for an invoice is debit, so an invoice credit note is on the credit side
  side: invoice.type === 'creditNote' ? 'credit' : 'debit',
  isBankMatched: false,
  originatorType: ReconcilablePostingType.Invoice,
  originatorId: invoice.id,
  description: `Invoice number ${invoice.invoiceNo}${invoice.contactName ? ` for ${invoice.contactName}` : ''}`,
  contact: invoice.contactName || null,
  sequenceNo: invoice.invoiceNo,
  orderNo: invoice.orderNo,
  state: invoice.state,
})

export const createBillyTransactionFromPosting = (posting: PostingWithTransaction): BillyTransaction => {
  let meta

  // We need to set this to a value to prevent a parsing error later on. This is not a problem because we have
  // already filtered so we know the switch will always resolve to a value
  let resolvedType: ReconcilablePostingType = null as unknown as ReconcilablePostingType
  const [originatorType, originatorId] = posting.originatorReference
  switch (originatorType) {
    case 'salesTaxPayment':
      resolvedType = ReconcilablePostingType.SalesTaxPayment
      break

    case 'daybookTransaction':
      resolvedType = ReconcilablePostingType.DaybookTransaction
      break

    case 'bankPayment':
      meta = getBankPaymentMetaFromPosting(posting)
      resolvedType = meta.type
      break
  }

  return {
    type: resolvedType,
    subtype: ReconcilablePostingSubType.Posting,
    id: posting.id,
    entryDate: posting.entryDate,
    dueDate: posting.entryDate,
    text: posting.text,
    accountId: posting.accountId,
    currencyId: posting.currencyId,
    amount: posting.amount,
    conversionCurrencyId: posting.currencyId,
    convertedAmount: posting.amount,
    side: posting.side,
    isBankMatched: false,
    originatorType,
    originatorId,
    description: posting.originatorName,
    contact: meta?.contact || null,
    sequenceNo: meta?.sequenceNo || null,
    orderNo: meta?.orderNo || null,
    state: 'approved',
  }
}

export const getBankPaymentMetaFromPosting = (
  posting: PostingWithTransaction,
): { type: ReconcilablePostingType } & Partial<BillyTransaction> => {
  const BillPaymentRegExp = new RegExp(
    '((?<=(Betalt\\saf\\s|Betalt\\stil\\s|Paid\\sby\\s|Paid\\sto\\s))(?<contact>.+)(?=\\s(for\\s(regninger|regning|bill|bills)))).*(?<=\\sfor\\s(regninger|regning|bill|bills)\\s)(?<number>.*)',
    'mi',
  )
  const InvoicePaymentRegExp = new RegExp(
    '((?<=(Betalt\\saf\\s|Betalt\\stil\\s|Paid\\sby\\s|Paid\\sto\\s))(?<contact>.+)(?=\\s(for\\s(fakturaer|faktura|invoice|invoices)))).*(?<=\\sfor\\s(fakturaer|faktura|invoice|invoices)\\s)(?<number>.*)',
    'mi',
  )
  const billMatch = BillPaymentRegExp.exec(posting.originatorName)
  const invoiceMatch = InvoicePaymentRegExp.exec(posting.originatorName)

  return billMatch
    ? {
        type: ReconcilablePostingType.BillPayment,
        contact: billMatch?.groups?.contact,
        sequenceNo: billMatch?.groups?.number,
      }
    : {
        type: ReconcilablePostingType.InvoicePayment,
        contact: invoiceMatch?.groups?.contact,
        sequenceNo: invoiceMatch?.groups?.number,
      }
}

export const isReconcilable = ({
  bankLines,
  differenceType,
  difference,
  isApproved,
  id: matchId,
  matchedBillyTransactions,
}: {
  bankLines?: BankLine[]
  difference: Difference
  differenceType?: DifferenceType
  isApproved: boolean
  id: string
  matchedBillyTransactions: BillyTransaction[]
}) => {
  if (isApproved) {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const state: SpecificState = require('..').store.getState()
  if (!differenceType) {
    const bankLineMatches = state.bankReconciliation.bankLineMatches
    const match = bankLineMatches.find((match) => match.id === matchId)

    if (!match) {
      return false
    }

    differenceType = match.differenceType
  }

  if (differenceType && (bankLines?.length || 0) > 1) {
    return false
  }

  if (!difference) {
    difference = calcDifference({
      bankLines: bankLines as BankLine[],
      matchedBillyTransactions,
    })
  }

  const hasDifference = !!difference?.differenceAmount
  return (
    (!hasDifference && matchedBillyTransactions.length > 0) || (differenceType && matchedBillyTransactions.length >= 1)
  )
}

export const getPosting = async (postingId: string): Promise<Posting> => {
  const data = await request(
    `/v2/postings/${postingId}`,
    {
      method: 'GET',
    },
    {
      returnRawResponse: false,
      withAuth: true,
    },
  )

  return data?.posting
}
