import { SubscriptionInvoicesList } from '@components'
import { ModuleLayout } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../enums/queryKeys'
import { fetchUmbrellaInvoicePdf, fetchUmbrellaInvoices } from '../../../../query-api/subscription-invoices-query'

interface UmbrellaInvoicesProps {
  umbrellaId: string
}

export const UmbrellaInvoices = ({ umbrellaId }: UmbrellaInvoicesProps): ReactElement => {
  const { t } = useTranslation()

  const { data: invoicesData, isLoading: isLoadingInvoices } = useQuery([QueryKeys.UmbrellaInvoices, umbrellaId], () =>
    fetchUmbrellaInvoices(umbrellaId),
  )

  const handleFetchInvoicePdf = useCallback(
    (invoiceId: string) => () => {
      return fetchUmbrellaInvoicePdf(umbrellaId, invoiceId)
    },
    [umbrellaId],
  )

  return (
    <ModuleLayout title={t('umbrella_invoices.title')}>
      <SubscriptionInvoicesList
        invoices={invoicesData?.data}
        isLoading={isLoadingInvoices}
        fetchInvoicePdf={handleFetchInvoicePdf}
      />
    </ModuleLayout>
  )
}
