import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconProcessCircle = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-process-circle')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g transform="translate(8,8)">
        <path
          fill={coloring(color)}
          d="M4.898587196589413e-16,-8A8,8 0 0,1 3.4710699129404654,7.207750943219353L2.6033024347053493,5.405813207414515A6,6 0 0,0 3.6739403974420594e-16,-6Z"
        ></path>
        <path
          fill={coloring(color2 || color)}
          d="M3.4710699129404654,7.207750943219353A8,8 0 1,1 -1.4695761589768238e-15,-8L-1.102182119232618e-15,-6A6,6 0 1,0 2.6033024347053493,5.405813207414515Z"
        ></path>
      </g>
    </svg>
  </Box>
)
