import { Color, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const PurchaseCardFeaturesWrapper = styled.div`
  display: flex;
  margin-top: ${Spacing.XL};
  padding-top: ${Spacing.XL};
  border-top: 1px solid ${Color.Gray30};
  flex-direction: column;
`

export const HeaderWrapper = styled.div`
  margin-bottom: ${Spacing.XL};
`

export const FooterWrapper = styled.div`
  margin-top: ${Spacing.L};
`

export const ItemsLabelWrapper = styled(Text)`
  margin-bottom: ${Spacing.M};
`
