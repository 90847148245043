import { Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import { IntegrationItem } from '../../types/integrationItem'
import { IntegrationCard, SkeletonIntegrationCard } from '../IntegrationCard'
import * as Styled from './styles'

interface IntegrationsListProps {
  title?: ReactNode
  items: IntegrationItem[]
}

export const IntegrationsList = ({ title, items }: IntegrationsListProps): ReactElement => (
  <Styled.IntegrationsListSection>
    {!!title && <Text variant="h2">{title}</Text>}
    <Styled.IntegrationsListWrapper>
      {items.map((item) => {
        if (item.isHiddenLoading) {
          return <SkeletonIntegrationCard key={item.id} />
        }

        if (item.isHidden) {
          return null
        }

        return <IntegrationCard key={item.id} integrationItem={item} />
      })}
    </Styled.IntegrationsListWrapper>
  </Styled.IntegrationsListSection>
)
