import { languageToLocale } from '@design-system'

import qs from 'qs'
import { useCallback, useRef, useState } from 'react'

import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'
import { useUpdateUser } from '@modules-deprecated/app/user'

import { useEmberRouter } from '../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../enums/emberRoute'
import { useSegment } from '../../../../../hooks'
import { getSignupAnalyticsData, removeSignupAnalyticsData } from '../../../../../utils/getSignupAnalyticsData'
import { useSignupGuide } from '../../../context/signupGuideContext'
import { useCreateUmbrellaOrganizationSubscriptionAndTransferOwnership } from '../../../hooks/useCreateUmbrellaOrganizationSubscriptionAndTransferOwnership'
import { useInternalUmbrellaId } from '../../../hooks/useInternalUmbrellaId'
import { CreateOrganizationForm } from '../utils/formData'
import { useCreateOrganization } from './useCreateOrganization'

export const useHandleCreateOrganizationSubmit = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorCode, setErrorCode] = useState<string>()
  const [isError, setIsError] = useState(false)
  const { navigate } = useEmberRouter()
  const { track } = useSegment()
  const { user, registerEmberOrganization, isInternalUmbrellaFlow, language, signupCouponParams, signupUmbrellaId } =
    useSignupGuide()
  const umbrellaId = useInternalUmbrellaId()
  const submittedValuesRef = useRef<CreateOrganizationForm>()

  const handleError = useCallback((error?: any) => {
    if (error?.body?.errorCode) {
      setErrorCode(error?.body?.errorCode)
    }

    console.error(error)
    setIsError(true)
    setIsLoading(false)
  }, [])

  const { create: createOrganizationSubscriptionAndTransferOwnership } =
    useCreateUmbrellaOrganizationSubscriptionAndTransferOwnership({
      onSuccess: (response) => {
        const organization = response.data.organizations[0]
        registerEmberOrganization({
          onError: handleError,
          onSuccess: () => {
            setIsLoading(false)
            navigate(EmberRoute.UmbrellaOrganizations, [umbrellaId])
          },
          organizationUrl: organization.url,
        })
      },
    })
  const { create: createOrganization } = useCreateOrganization({
    onSuccess: (response) => {
      const { organizations } = response
      const organization = organizations[0]
      const organizationUrl = organization.url
      const organizationId = organization.id
      const { analytics } = getSignupAnalyticsData() || {}
      const analyticsSearchParamsPart = (analytics || '').split('?')[1]
      const analyticsParams = qs.parse(analyticsSearchParamsPart)

      removeSignupAnalyticsData()

      if (isInternalUmbrellaFlow && umbrellaId) {
        const { plan = '', period = '' } = submittedValuesRef.current?.productPlan || {}

        createOrganizationSubscriptionAndTransferOwnership({
          organizationId,
          umbrellaId,
          plan,
          period,
        })
        return
      }

      registerEmberOrganization({
        organizationUrl,
        onSuccess: async () => {
          const hasUsedCVRData = !!submittedValuesRef.current?.orgCompany?.id

          track('Signup Organization Create Completed (FE)', {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            used_cvr_data: hasUsedCVRData ? 'yes' : 'no',
            ...analyticsParams,
          })

          if (signupCouponParams.signupCouponId) {
            navigate(EmberRoute.SettingsSubscriptionsPlansSelection, [organizationUrl], {
              queryParams: {
                duration: signupCouponParams.signupAvailableDays,
                coupon: signupCouponParams.signupCouponId,
                period: signupCouponParams.signupPeriod,
                plan: signupCouponParams.signupPlan,
              },
            })
          } else {
            navigate(EmberRoute.Dashboard, [organizationUrl])
          }

          setIsLoading(false)
        },
        onError: handleError,
      })
    },
    onError: handleError,
  })

  const { update: updateUser } = useUpdateUser({
    onSuccess: () => {
      if (!submittedValuesRef.current) {
        handleError(new Error('You have to submit form first!'))
        return
      }

      if (!user) {
        handleError(new Error('User has to be provided!'))
        return
      }

      const {
        orgCity,
        orgName,
        orgCountry,
        orgCompany,
        orgStreet,
        orgZipcode,
        orgCurrency,
        orgFirstFiscalYearEnd,
        orgFirstFiscalYearStart,
        orgFiscalYearEndMonth,
      } = submittedValuesRef.current

      createOrganization({
        name: orgName,
        street: orgStreet,
        zipcode: orgZipcode,
        city: orgCity,
        subscriptionPlan: SubscriptionPlan.Premium,
        subscriptionPeriod: SubscriptionPeriod.Monthly,
        email: user.email,
        ...(orgCountry?.id ? { countryId: orgCountry?.id } : {}),
        ...(orgCompany?.id ? { registrationNo: orgCompany?.id } : {}),
        ...(orgCurrency?.id ? { baseCurrencyId: orgCurrency.id } : {}),
        ...(orgFirstFiscalYearEnd ? { firstFiscalYearEnd: orgFirstFiscalYearEnd } : {}),
        ...(orgFirstFiscalYearStart ? { firstFiscalYearStart: orgFirstFiscalYearStart } : {}),
        ...(orgFiscalYearEndMonth ? { fiscalYearEndMonth: orgFiscalYearEndMonth } : {}),
        ...(signupUmbrellaId ? { signupUmbrellaId } : {}),
      })
    },
    onError: handleError,
  })

  const resetState = useCallback(() => {
    setIsLoading(false)
    setIsError(false)
    setErrorCode(undefined)
  }, [])

  const submit = useCallback(
    (payload: CreateOrganizationForm) => {
      resetState()

      if (!user) {
        handleError(new Error('User has to be provided!'))
        return
      }

      const { userName } = payload
      const locale = languageToLocale[language]

      submittedValuesRef.current = payload
      setIsLoading(true)
      updateUser({
        id: user.id,
        name: userName,
        ...(locale ? { locale } : {}),
      })
    },
    [updateUser, resetState, user, language, handleError],
  )

  return {
    errorCode,
    isError,
    isLoading,
    submit,
  }
}
