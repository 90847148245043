import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { StyledComponent } from '../../../types/styledComponent'

export const getDisabledStyle = (bordered: boolean, Input?: StyledComponent): SerializedStyles => css`
  ${bordered &&
  css`
    border-color: ${Color.Gray40};
    background-color: ${Color.Gray20};
    box-shadow: none;
  `}

  ${Input || '&'} {
    &,
    &::placeholder {
      color: ${Color.Gray50};
    }

    &::-moz-placeholder {
      color: ${Color.Gray50};
    }
  }

  &,
  ${Input} {
    cursor: not-allowed;
  }
`
