import React, { ReactElement } from 'react'

import { Color } from '../../../../enums/color'
import { Icon } from '../../../Icon'
import { InputSuffix, InputSuffixProps } from '../InputSuffix'

type ErrorIconProps = Omit<InputSuffixProps, 'children'>

export const ErrorIcon = ({ disabled, ...rest }: ErrorIconProps): ReactElement => (
  <InputSuffix disabled={disabled} {...rest}>
    <Icon icon="exclamationMarkCircle" color={disabled ? Color.Gray60 : Color.Red} />
  </InputSuffix>
)
