import { Tab } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useContactsListFilters } from '../../../../../../hooks/useContactsListFilters'
import * as Styled from './styles'

enum ContactTabType {
  All = 'all',
  Customers = 'customers',
  Vendors = 'vendors',
}

export const ContactsTabs = (): ReactElement => {
  const { t } = useTranslation()
  const [{ isCustomer, isSupplier }, setFilters] = useContactsListFilters()

  const tabs: ContactTabType[] = [ContactTabType.All, ContactTabType.Customers, ContactTabType.Vendors]

  const activeTab: ContactTabType = useMemo(() => {
    if (isCustomer) {
      return ContactTabType.Customers
    }
    if (isSupplier) {
      return ContactTabType.Vendors
    }
    return ContactTabType.All
  }, [isCustomer, isSupplier])

  const tabTitleTranslations = useMemo(
    () => ({
      all: t('contacts.filter.all'),
      customers: t('contacts.filter.customers'),
      vendors: t('contacts.filter.vendors'),
    }),
    [t],
  )

  const handleFilterClick = useCallback(
    (filter: ContactTabType) => () => {
      switch (filter) {
        case ContactTabType.All:
          setFilters({ isCustomer: undefined, isSupplier: undefined })
          break
        case ContactTabType.Customers:
          setFilters({ isCustomer: true, isSupplier: undefined })
          break
        case ContactTabType.Vendors:
          setFilters({ isCustomer: undefined, isSupplier: true })
      }
    },
    [setFilters],
  )

  return (
    <Styled.ContactsTabsFilterWrapper>
      {tabs.map((tab) => (
        <Tab key={tab} active={tab === activeTab} onClick={handleFilterClick(tab)}>
          {tabTitleTranslations[tab]}
        </Tab>
      ))}
    </Styled.ContactsTabsFilterWrapper>
  )
}
