import { StatusFormatter } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceStatus as InvoiceStatusEnum } from '../../../../enums/invoiceStatus'
import { invoiceStatusToStatusFormatterVariant } from '../../constants/invoiceStatusToStatusFormatterVariant'
import { invoiceStatusToTitleKey } from '../../constants/invoiceStatusToTitleKey'

interface InvoiceStatusProps {
  status: InvoiceStatusEnum
}

export const InvoiceStatus = ({ status }: InvoiceStatusProps): ReactElement => {
  const { t } = useTranslation()

  const titleKey = invoiceStatusToTitleKey[status]
  const variant = invoiceStatusToStatusFormatterVariant[status]

  return <StatusFormatter variant={variant} title={t(titleKey)} />
}
