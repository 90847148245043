export enum PayrollStatus {
  Approved = 'approved',
  AutomaticApproval = 'automatic_approval',
  Awaiting = 'awaiting',
  AwaitingApproval = 'awaiting_approval',
  Cancelled = 'cancelled',
  Failed = 'failed',
  MissingApproval = 'missing_approval',
  Rejected = 'rejected',
  Reviewed = 'reviewed',
  Success = 'success',
  Tentative = 'tentative',
  Unknown = 'unknown',
}
