import noop from 'lodash/noop'
import { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo } from 'react'

import { EmberActionPayload, RegisterEmberOrganizationProps } from '@views/signupGuide/context/signupGuideContext'

import { useFetchOrganizationInvitation } from '../hooks/useFetchOrganizationInvitation'
import { OrganizationInvitationData } from '../query-api'

interface OrganizationInvitationContextState {
  token: string
  isLoading: boolean
  data?: OrganizationInvitationData
  error?: any
  authorizeEmberUser: (token: string) => void
  registerEmberOrganization: (props: RegisterEmberOrganizationProps) => void
}

const defaultValue = {
  token: '',
  isLoading: false,
  data: undefined,
  error: undefined,
  authorizeEmberUser: noop,
  registerEmberOrganization: noop,
}

export const OrganizationInvitationContext = createContext<OrganizationInvitationContextState>(defaultValue)

interface OrganizationInvitationsProviderProps {
  children: ReactNode
  token: string
  onAuthorizeUser: (payload: EmberActionPayload) => void
  onRegisterOrganization: (payload: EmberActionPayload) => void
}

export const OrganizationInvitationProvider = ({
  children,
  token,
  onAuthorizeUser,
  onRegisterOrganization,
}: OrganizationInvitationsProviderProps): ReactElement => {
  const { isLoading, data, error } = useFetchOrganizationInvitation({ token })

  const authorizeEmberUser = useCallback(
    (token: string) => {
      onAuthorizeUser({ detail: { token } })
    },
    [onAuthorizeUser],
  )

  const registerEmberOrganization = useCallback(
    ({ organizationUrl, onSuccess, onError }: RegisterEmberOrganizationProps) => {
      onRegisterOrganization({ detail: { organizationUrl, onSuccess, onError } })
    },
    [onRegisterOrganization],
  )

  const value = useMemo(
    () => ({
      token,
      isLoading,
      data,
      error,
      authorizeEmberUser,
      registerEmberOrganization,
    }),
    [token, isLoading, data, error, authorizeEmberUser, registerEmberOrganization],
  )

  return <OrganizationInvitationContext.Provider value={value}>{children}</OrganizationInvitationContext.Provider>
}

export const useOrganizationInvitation = () => useContext(OrganizationInvitationContext)
