import React, { MouseEvent, ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useReportsEmberConnection } from '../../contexts/reportsEmberConnectionContext'
import { DATA_ACCOUNT_ID, DATA_END_DATE, DATA_START_DATE, formatReportHtml } from '../../utils/formatReportHtml'
import * as Styled from './styles'

interface ReportHtmlProps {
  html?: string
}

export const ReportHtml = ({ html }: ReportHtmlProps): ReactElement => {
  const { t } = useTranslation()
  const { navigateToAccount } = useReportsEmberConnection()

  const reportHtml = useMemo(() => (html ? formatReportHtml(html, t) : ''), [html, t])

  const goToAccount = useCallback(
    (accountId: string, startDate?: string | null, endDate?: string | null) => {
      navigateToAccount(accountId, startDate, endDate)
    },
    [navigateToAccount],
  )

  const handleHtmlClick = useCallback(
    (event: MouseEvent) => {
      const element = event.target as HTMLElement

      if (element?.tagName !== 'A') {
        return
      }

      event.preventDefault()
      const accountId = element.getAttribute(DATA_ACCOUNT_ID)
      const startDate = element.getAttribute(DATA_START_DATE)
      const endDate = element.getAttribute(DATA_END_DATE)

      if (accountId) {
        goToAccount(accountId, startDate, endDate)
      }
    },
    [goToAccount],
  )

  // eslint-disable-next-line @typescript-eslint/naming-convention, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  return <Styled.ReportHtmlWrapper onClick={handleHtmlClick} dangerouslySetInnerHTML={{ __html: reportHtml }} />
}
