import { Checkbox, Color } from '@design-system'

import React, { ChangeEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface ReceiptsSelectAllActionProps {
  isSelected: boolean
  onSelectAllCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void
  selectedReceiptsCount: number
}

export const ReceiptsSelectAllAction = ({
  isSelected,
  onSelectAllCheckboxChange,
  selectedReceiptsCount,
}: ReceiptsSelectAllActionProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.ReceiptsSelectAllActionWrapper>
      <Checkbox checked={isSelected} onChange={onSelectAllCheckboxChange}>
        {t('receipts.receipts_list.select_all')}
      </Checkbox>
      {selectedReceiptsCount > 0 && (
        <Styled.ReceiptsSelectedReceipts color={Color.Green120} nowrap>
          {t('receipts.receipts_list.select_counter', { count: selectedReceiptsCount })}
        </Styled.ReceiptsSelectedReceipts>
      )}
    </Styled.ReceiptsSelectAllActionWrapper>
  )
}
