import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { PurchaseCardHighlightVariant } from './types'
import { variantStyles } from './utils/variantStyles'

interface PurchaseCardOutterWrapperProps {
  variant?: PurchaseCardHighlightVariant
}

export const PurchaseCardWrapper = styled.div<PurchaseCardOutterWrapperProps>`
  width: 100%;
  max-width: 480px;
  border-radius: 16px;
  position: relative;
  flex: 1 1 0;
  background-color: ${({ variant }) => variant && variantStyles[variant].bgColor};
`

export const HighlightedTextWrapper = styled.div`
  position: absolute;
  width: 100%;
  margin-top: ${Spacing.XXS};
`

export const PurchaseCardInnerWrapper = styled.div`
  display: flex;
  height: calc(100% - ${Spacing.XL});
  width: 100%;
  margin-top: ${Spacing.XL};
  padding: ${Spacing.XL};
  border-radius: 16px;
  flex-direction: column;
  background-color: ${Color.White};
`
