import { TableBadgeLabel } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { billStatusToBadgeVariant } from '../../../../constants/billStatusToBadgeVariant'
import { BillStatus } from '../../../../enums/billStatus'
import { useBillsListFilters } from '../../hooks/useBillsListFilters'
import { useBillsSummaryFiltered } from '../../hooks/useBillsSummaryFiltered'
import { getBillsTitle } from '../../utils/getBillsTitle'

export const BillsTitle = (): ReactElement => {
  const { t } = useTranslation()
  const { isLoading, data } = useBillsSummaryFiltered()
  const [filters] = useBillsListFilters()

  const status = filters?.status || BillStatus.All
  const totalAmount = data?.[status].count || 0
  const title = getBillsTitle(status, totalAmount, t)
  const variant = billStatusToBadgeVariant[status]

  return (
    <TableBadgeLabel amount={totalAmount} isLoading={isLoading} variant={variant}>
      {title}
    </TableBadgeLabel>
  )
}
