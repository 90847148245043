import { AccountsGroupsSet } from '../types'

export const filterAccounts = (
  accountsGroupsSet: AccountsGroupsSet,
  includeArchived: boolean,
  searchString: string,
  showOnlyNoPublicCoaId: boolean,
): AccountsGroupsSet => {
  const { accountsInGroups, groups } = accountsGroupsSet
  const filteredAccountsSet = {}

  for (const accountGroupId in accountsInGroups) {
    filteredAccountsSet[accountGroupId] = accountsInGroups[accountGroupId].filter(
      ({ isArchived, name, accountNo, publicAccountId }) => {
        if (showOnlyNoPublicCoaId && publicAccountId) {
          return false
        }

        if (!includeArchived && isArchived) {
          return false
        }

        if (!searchString) {
          return true
        }

        const isAccountNoMatch = accountNo && accountNo.toString().includes(searchString)

        if (isAccountNoMatch) {
          return true
        }

        const isNameMatch = name.toLowerCase().includes(searchString)

        return isNameMatch
      },
    )
  }

  const filteredGroupsSet = searchString
    ? groups.filter(({ id }) => filteredAccountsSet[id] && filteredAccountsSet[id].length > 0)
    : groups

  return {
    accountsInGroups: filteredAccountsSet,
    groups: filteredGroupsSet,
  }
}
