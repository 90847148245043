import { Flex, SectionMessage, Space, Text } from '@design-system'

import isNumber from 'lodash/isNumber'
import { ReactElement, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useSegment } from '../../../../../../../../../../hooks'
import { getImgPath } from '../../../../../../../../../../utils/getImgPath'
import { useExternalInvoicesSyncProgress } from '../../../../../../contexts/externalInvoicesSyncProgressContext'
import { useSyncErrorTopBar } from '../../../../contexts/syncErrorTopBarContext'
import * as Styled from './styles'

const unrecognizedVatRatesIllustration = getImgPath(
  '/external-invoices-sync/modal-external-invoices-sync-unrecognized-vat-rates.svg',
)

export const ModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { processedDocumentsCount, totalDocumentsCount } = useExternalInvoicesSyncProgress()
  const { unrecognizedVatRates } = useSyncErrorTopBar()

  useEffect(() => {
    track('xxx-sales-HistoricalSync-Error Modal Opened', {
      missingRates: unrecognizedVatRates,
    })
  }, [])

  return (
    <Flex flexDirection="column" alignItems="center">
      <img src={unrecognizedVatRatesIllustration} alt="Unrecognized VAT Rates modal illustration" />
      <Space size="xl" />
      {isNumber(processedDocumentsCount) && isNumber(totalDocumentsCount) && (
        <Text>
          <Trans
            i18nKey="external_invoices_sync_unrecognized_vat_rates_modal.we_synced_documents_out_of_total"
            values={{ processedDocumentsCount, totalDocumentsCount }}
          >
            We have synced
            <strong>processedDocuments out of totalDocuments</strong>
            documents to the new platform.
          </Trans>
        </Text>
      )}
      <Space size="xl" />
      {!!unrecognizedVatRates?.length && (
        <SectionMessage
          title={t('external_invoices_sync_unrecognized_vat_rates_modal.we_found_unrecognized_vat_rates')}
          variant="warning"
        >
          <>
            <Styled.List>
              {unrecognizedVatRates.map((vatRate) => (
                <Styled.ListItem key={vatRate}>{vatRate}</Styled.ListItem>
              ))}
            </Styled.List>
            {t('external_invoices_sync_unrecognized_vat_rates_modal.create_vat_rates_to_sync_unrecognized_invoices')}
          </>
        </SectionMessage>
      )}
    </Flex>
  )
}
