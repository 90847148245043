import { UpdateOrganizationPayload } from '@modules-deprecated/app/organization'

import { convertToDate } from './convertDate'
import { convertDuplicateBillCheckToNumber } from './convertDuplicateBillCheck'
import { convertSupplierInvoiceNoToNumber } from './convertSupplierInvoiceNo'
import { formatDate } from './formatDate'
import { OrganizationAccountingForm } from './organizationAccountingSettingsFormData'

export type BooleanString = '' | '0' | '1' | boolean | undefined
export type ReturnValue = '' | '1'

export interface SettingsPayloadType {
  isDuplicateBillCheckDisabled: '' | '1'
  supplierInvoiceNoEnabled: '' | '1'
}

export interface ConvertedAccountingFromData {
  organizationPayload: UpdateOrganizationPayload
  settingsPayload: SettingsPayloadType
}

export const convertAccountingFormDataForSubmit = (values: OrganizationAccountingForm): ConvertedAccountingFromData => {
  const organizationPayload: UpdateOrganizationPayload = {
    accountingBasis: values.accountingBasis,
    bankSyncStartDate: formatDate(values.bankSyncStartDate) || null,
    defaultBankFeeAccountId: values.defaultBankFeeAccountId,
    defaultBillBankAccountId: values.defaultBillBankAccountId,
    firstFiscalYearEnd: convertToDate(Number(values.firstFiscalYearEnd), Number(values.fiscalYearEndMonth + 1)),
    firstFiscalYearStart: formatDate(values.firstFiscalYearStart),
    fiscalLockingDate: formatDate(values.fiscalLockingDate) || null,
    // BE has numbers 1-12 for months, need conversion from 0-11 range
    fiscalYearEndMonth: String(values.fiscalYearEndMonth + 1),
    hasBillVoucherNo: values.hasBillVoucherNo,
    isVatExempted: values.isVatExempted,
    salesTaxPeriod: values.salesTaxPeriod,
  }
  const settingsPayload: SettingsPayloadType = {
    isDuplicateBillCheckDisabled: convertDuplicateBillCheckToNumber(values.isDuplicateBillCheckDisabled),
    supplierInvoiceNoEnabled: convertSupplierInvoiceNoToNumber(values.supplierInvoiceNoEnabled),
  }
  return { organizationPayload, settingsPayload }
}
