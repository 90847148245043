export const TAX_RATES_REQUEST = 'TAX_RATES_REQUEST'
export const TAX_RATES_RECEIVED = 'TAX_RATES_RECEIVED'
export const ACCOUNTS_REQUEST = 'ACCOUNTS_REQUEST'
export const ACCOUNTS_RECEIVED = 'ACCOUNTS_RECEIVED'
export const INBOX_ACTION_REQUEST = 'INBOX_ACTION_REQUEST'
export const VOUCHER_ACTION_REQUEST = 'VOUCHER_ACTION_REQUEST'
export const VOUCHER_ACTION_CUSTOM_REQUEST = 'VOUCHER_ACTION_CUSTOM_REQUEST'
export const VOUCHER_ACTION_REQUEST_FAILED = 'VOUCHER_ACTION_REQUEST_FAILED'
export const VOUCHER_ACTION_RECIEVED = 'VOUCHER_ACTION_RECIEVED'
export const VOUCHERS_NAVIGATION_REQUEST = 'VOUCHERS_NAVIGATION_REQUEST'
export const VOUCHERS_NAVIGATION_RECEIVED = 'VOUCHERS_NAVIGATION_RECEIVED'
export const VOUCHER_DELETE = 'VOUCHER_DELETE'
export const VOUCHER_REQUEST_MORE_INFO = 'VOUCHER_REQUEST_MORE_INFO'
export const VOUCHER_DISCARD = 'VOUCHER_DISCARD'
export const VOUCHERS_WITH_NEED_INFO_REQUEST = 'VOUCHERS_WITH_NEED_INFO_REQUEST'
export const VOUCHERS_WITH_NEED_INFO_RECEIVED = 'VOUCHERS_WITH_NEED_INFO_RECEIVED'
export const FILE_REQUEST = 'FILE_REQUEST'
export const FILE_RECEIVED = 'FILE_RECEIVED'
