import { Tab, TabsGroup } from '@design-system'

import React, { ReactElement, ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useLocalStorageUncontrolled } from '../../../../hooks/useLocalStorageUncontrolled'
import { REPORTS_ROUTE_STORAGE_KEY } from '../../constants/reportsRouteStorageKey'
import { ReportsRoute } from '../../enums/reportsRoute'
import * as Styled from './styles'

interface NavigationTabs {
  name: ReactNode
  route: ReportsRoute
}

export const ReportsNavigationTabs = (): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const { setItem } = useLocalStorageUncontrolled(REPORTS_ROUTE_STORAGE_KEY)

  useEffect(() => {
    setItem(location.pathname)
  }, [location?.pathname])

  const navigationTabs: NavigationTabs[] = useMemo(
    () => [
      {
        name: t('income_statement'),
        route: ReportsRoute.ProfitAndLoss,
      },
      {
        name: t('balance'),
        route: ReportsRoute.Balance,
      },
      {
        name: t('trial_balance'),
        route: ReportsRoute.TrialBalance,
      },
    ],
    [t],
  )

  return (
    <Styled.ReportsNavigationTabsWrapper>
      <TabsGroup>
        {navigationTabs.map(({ name, route }) => (
          <Tab key={route} active={route === location.pathname} to={route}>
            {name}
          </Tab>
        ))}
      </TabsGroup>
    </Styled.ReportsNavigationTabsWrapper>
  )
}
