import cc from 'classcat'
import React, { Component } from 'react'

import { reactClass } from '../../utils'
import { FriendlyError, FriendlyErrorProps } from '../FriendlyError'

export type ErrorBoundaryProps = FriendlyErrorProps

type State = {
  error?: Error
  errorInfo?: React.ErrorInfo
  hasError?: boolean
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  state = {
    error: undefined,
    errorInfo: undefined,
    hasError: false,
  }

  static getDeriveredStateFromError = () => {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.errorInfo) {
      const { className, ...rest } = this.props
      const { error, errorInfo } = this.state
      const comp = React.Children.only(this.props.children)
      const compProps = React.isValidElement(comp) ? comp.props : {}

      return (
        <FriendlyError
          className={cc([className, reactClass('within-error-boundary')])}
          error={error || undefined}
          errorInfo={errorInfo}
          handleRetry={() => this.setState({ error: undefined, errorInfo: undefined })}
          comp={comp}
          compProps={compProps}
          {...rest}
        />
      )
    } else {
      return this.props.children
    }
  }
}
