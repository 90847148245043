import { Flipper, FlipperProps, Label, Text } from '@components-deprecated'
import { Color, FontWeight } from '@design-system'

import { css } from '@emotion/core'
import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { SpecificState } from '../../../types/reduxSaga-deprecated'
import { Account } from '../../app/accounts/types'
import { bankBalanceRequested, setBalanceBarOpen } from '../action-creators'
import { PrimoUltimoBalance } from '../types'
import { NordicApiConnectionStatusLabel } from './NordicApiConnectionStatusLabel'

export type BalanceBarProps = FlipperProps

const BankAccount = ({
  title,
  no,
  noBankAccount,
}: {
  title: string
  no: string | React.ReactNode
  noBankAccount: boolean
}) => (
  <Box>
    <Text variant="body1">
      <Box
        as="span"
        css={css`
          margin-right: 15px;
          font-weight: ${FontWeight.Medium};
        `}
      >
        {noBankAccount ? <Label variant={'danger'}>{title}</Label> : title}
      </Box>{' '}
      {no}
    </Text>
  </Box>
)

const Outstanding = ({ title, amount }: { title: string; amount: number }) => (
  <Flex ml={4}>
    <Text
      variant="body1"
      color={Color.Gray90}
      css={css`
        margin-right: 5px;
      `}
    >
      {title}:
    </Text>
    <Text color={Color.DeepSeaGreen} fontWeight={FontWeight.Medium} variant="body1" type="currency">
      {amount}
    </Text>
  </Flex>
)

const InfoLine = ({
  title,
  info,
  type = 'text',
}: {
  title: string | React.ReactNode
  info: string
  type?: 'text' | 'date' | 'currency'
}) => (
  <Flex
    css={css`
      padding: 4px 0;
      justify-content: space-between;
    `}
  >
    <Text variant="body1">
      <Box
        as="span"
        css={css`
          margin-right: 20px;
          font-weight: ${FontWeight.Medium};
        `}
      >
        {title}
      </Box>
    </Text>
    <Text variant="body1" type={type}>
      {info}
    </Text>
  </Flex>
)

const GridLine = ({ children, ...rest }: { children: React.ReactNode }) => (
  <Box
    css={css`
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      & > * {
        padding: 4px 8px;
        min-width: 110px;
      }

      & > *:not(:first-of-type) {
        text-align: right;
      }
    `}
    {...rest}
  >
    {children}
  </Box>
)

const BankNameInfoLine = ({ account, isBalanceBarOpen }: { account: Account; isBalanceBarOpen: boolean }) => {
  const accountResolved = useSelector((state: SpecificState) => state.app.accountResolved)
  const noBankAccount = !account.name && !account.bankName
  const bankName = account.name || account.bankName || i18next.t('bankreconciliation.balancebar.no_account_selected')
  const bankKonto = `${account.accountNo || ''} ${account.bankAccountNo || ''}`

  if (!accountResolved) {
    return <></>
  }

  if (isBalanceBarOpen) {
    const name = (
      <>
        {noBankAccount ? <Label variant={'danger'}>{bankName}</Label> : bankName}
        <NordicApiConnectionStatusLabel account={account} ml={'5px'} />
      </>
    )

    return <InfoLine title={name} info={bankKonto} />
  }

  const konto = (
    <>
      {bankKonto}
      <NordicApiConnectionStatusLabel account={account} ml={'5px'} />
    </>
  )

  return <BankAccount title={bankName} no={konto} noBankAccount={noBankAccount} />
}

export const BalanceBar = ({ ...rest }: BalanceBarProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const account = useSelector((state: SpecificState) => state.app.account) || ({} as Account)
  const bankConnection = useSelector((state: SpecificState) => state.bankReconciliation.bankConnection)

  const latestBankLineEntryDate = useSelector(
    (state: SpecificState) => state.bankReconciliation.latestBankLineEntryDate,
  )
  const lastPullTime = bankConnection?.lastSynchronizedAt || latestBankLineEntryDate || undefined

  const primoUltimoBalance = useSelector((state: SpecificState) => state.bankReconciliation.primoUltimoBalance)
  const filters = useSelector((state: SpecificState) => state.bankReconciliation.filters)

  const { bankEndBalance, bankStartBalance, billyEndBalance, billyStartBalance } =
    (primoUltimoBalance as Required<PrimoUltimoBalance>) || {}

  const bankBalance = bankEndBalance
  const billyBalance = billyEndBalance

  const difference = billyBalance - bankBalance
  const startDifference = billyStartBalance - bankStartBalance
  const endDifference = billyEndBalance - bankEndBalance

  const toggleFlipSide = () => {
    setIsOpen((prevState: boolean) => !prevState)
    dispatch(setBalanceBarOpen({ isOpen: !isOpen }))
  }

  useEffect(() => {
    if (account?.id && filters) {
      dispatch(bankBalanceRequested())
    }
  }, [account, dispatch, filters])

  return (
    <Flipper onFlipSide={toggleFlipSide} {...rest}>
      <Flex justifyContent="space-between" width="100%">
        <Box flex="auto">
          <BankNameInfoLine account={account} isBalanceBarOpen={isOpen} />
        </Box>
        <Flex
          justifyContent={'flex-end'}
          alignItems={'center'}
          css={css`
            & > * {
              margin-left: 40px;
            }
          `}
        >
          <Text
            variant="body1"
            css={css`
              font-weight: normal;
            `}
          >
            {t('bankreconciliation.balancebar.endbalance')}
          </Text>
          <Outstanding title="Bank" amount={bankBalance} />
          <Outstanding title="Billy" amount={billyBalance} />
          <Outstanding title={t('bankreconciliation.balancebar.difference')} amount={difference} />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" width="100%">
        <Box
          flex="auto"
          css={css`
            flex: auto;
            max-width: 320px;
            & > *:not(:last-of-type) {
              border-bottom: 1px solid #e6e6e6;
            }
          `}
        >
          <BankNameInfoLine account={account} isBalanceBarOpen={isOpen} />

          {!!lastPullTime && (
            <InfoLine title={t('bankreconciliation.balancebar.latestimportfrombank')} info={lastPullTime} type="date" />
          )}
        </Box>
        <Box
          css={css`
            max-width: 470px;
            & > *:nth-of-type(odd) {
              background-color: ${coloring('blue1')};
            }
          `}
        >
          <GridLine>
            <Text> </Text>
            <Text
              variant="body1"
              css={css`
                font-weight: ${FontWeight.Medium};
              `}
            >
              Bank
            </Text>
            <Text
              variant="body1"
              css={css`
                font-weight: ${FontWeight.Medium};
              `}
            >
              Billy
            </Text>
            <Text
              variant="body1"
              css={css`
                font-weight: ${FontWeight.Medium};
              `}
            >
              {t('bankreconciliation.balancebar.difference')}
            </Text>
          </GridLine>
          <GridLine>
            <Text
              variant="body1"
              css={css`
                font-weight: ${FontWeight.Medium};
              `}
            >
              {t('bankreconciliation.balancebar.startbalance')}
            </Text>
            <Text variant="body1" type="currency">
              {bankStartBalance}
            </Text>
            <Text variant="body1" type="currency">
              {billyStartBalance}
            </Text>
            <Text variant="body1" type="currency">
              {startDifference}
            </Text>
          </GridLine>
          <GridLine>
            <Text
              variant="body1"
              css={css`
                font-weight: ${FontWeight.Medium};
              `}
            >
              {t('bankreconciliation.balancebar.endbalance')}
            </Text>
            <Text variant="body1" type="currency">
              {bankEndBalance}
            </Text>
            <Text variant="body1" type="currency">
              {billyEndBalance}
            </Text>
            <Text variant="body1" type="currency">
              {endDifference}
            </Text>
          </GridLine>
        </Box>
      </Flex>
    </Flipper>
  )
}
