import { TFunction } from 'i18next'

interface Fields {
  email?: string
  phone?: string
}

export const getContactContent = ({ email, phone }: Fields, t: TFunction) => {
  let content = ''

  if (phone) {
    content += `${t('selects.bookkeeper_select.phone')} ${phone}`

    if (email) {
      content += ', '
    }
  }

  if (email) {
    content += `${t('selects.bookkeeper_select.email')} ${email}`
  }

  if (!content) {
    content = t('selects.bookkeeper_select.no_content')
  }

  return content
}
