import { RecurringInvoiceStatus } from '../../../enums/recurringInvoiceStatus'

interface GetRecurringInvoiceStatusOptions {
  nextSpawnTimestamp: string
  sendErrorCode: string | null
  spawnErrorCode: string | null
}

export const getRecurringInvoiceStatus = ({
  nextSpawnTimestamp,
  sendErrorCode,
  spawnErrorCode,
}: GetRecurringInvoiceStatusOptions): RecurringInvoiceStatus => {
  if (!nextSpawnTimestamp) {
    return RecurringInvoiceStatus.Inactive
  }

  if (!!spawnErrorCode || !!sendErrorCode) {
    return RecurringInvoiceStatus.Failing
  }

  return RecurringInvoiceStatus.Active
}
