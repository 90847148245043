import { FormItemsGroup, Select, Textarea } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../hooks'
import { FindAccountantFormSchema } from '../../utils/formData'
import { bankTransactionsItems } from './constants/bankTransactionsItems'
import { billsItems } from './constants/billsItems'
import { invoicesItems } from './constants/invoicesItems'
import { revenueItems } from './constants/revenueItems'
import * as Styled from './styles'

export const BusinessSummary = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<FindAccountantFormSchema>()

  return (
    <Styled.BusinessSummaryWrapper>
      <FormItemsGroup itemsInRow={4}>
        <FormItem
          label={t('find_accountant.questions.four.revenue')}
          name="summary.revenue"
          render={({ field }) => {
            const { onChange, ...restField } = field

            return <Select {...restField} items={revenueItems} onSelect={onChange} selectedId={field.value} />
          }}
        />
        <FormItem
          label={t('find_accountant.questions.four.invoices')}
          name="summary.invoices"
          render={({ field }) => {
            const { onChange, ...restField } = field

            return <Select {...restField} items={invoicesItems} onSelect={onChange} selectedId={field.value} />
          }}
        />
        <FormItem
          label={t('find_accountant.questions.four.bills')}
          name="summary.bills"
          render={({ field }) => {
            const { onChange, ...restField } = field

            return <Select {...restField} items={billsItems} onSelect={onChange} selectedId={field.value} />
          }}
        />
        <FormItem
          label={t('find_accountant.questions.four.bank_transactions')}
          name="summary.bankTransactions"
          render={({ field }) => {
            const { onChange, ...restField } = field

            return <Select {...restField} items={bankTransactionsItems} onSelect={onChange} selectedId={field.value} />
          }}
        />
      </FormItemsGroup>
      <FormItemsGroup>
        <FormItem
          label={t('find_accountant.questions.four.description')}
          name="summary.description"
          render={({ field }) => (
            <Textarea
              {...field}
              placeholder={t('find_accountant.questions.four.description.placeholder')}
              resizable={false}
              size="xl"
            />
          )}
        />
      </FormItemsGroup>
    </Styled.BusinessSummaryWrapper>
  )
}
