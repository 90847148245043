import { css } from '@emotion/core'
import { transparentize } from 'polished'

import { Color } from '../enums/color'

const DEFAULT_BORDER_WIDTH = 1
const DEFAULT_OUTLINE_WIDTH = 3

type ShadowType = 'raisedLight' | 'raisedLightInner' | 'raisedStrong' | 'elevatedLight' | 'elevatedStrong'

const getShadowTypeToValue = (type: ShadowType, color?: Color) => {
  const shadowTypes: Record<ShadowType, string> = {
    raisedLight: `0 1px 2px ${transparentize(0.8, color || Color.Gray60)}`,
    raisedLightInner: `inset 0 2px 4px ${transparentize(0.7, color || Color.Gray60)}`,
    raisedStrong: `0 10px 15px ${transparentize(0.85, color || Color.Gray200)}`,
    elevatedLight: `0 48px 110px ${transparentize(0.5, color || Color.Gray50)}`,
    elevatedStrong: `0 10px 70px ${transparentize(0.65, color || Color.Gray200)}`,
  }

  return shadowTypes[type]
}

interface ShadowOptions {
  color?: Color
  extraShadowType?: ShadowType
  extraShadowColor?: Color
  borderColor?: Color
  borderWidth?: number
  outlineColor?: Color
  outlineWidth?: number
}

export const getShadow = (type?: ShadowType, options?: ShadowOptions) => {
  const { color, extraShadowType, extraShadowColor, borderColor, borderWidth, outlineColor, outlineWidth } =
    options || {}

  const systemShadow = type ? getShadowTypeToValue(type, color) : ''
  const extraShadow = extraShadowType ? getShadowTypeToValue(extraShadowType, extraShadowColor) : ''
  const borderShadow = borderColor ? `inset 0 0 0 ${borderWidth || DEFAULT_BORDER_WIDTH}px ${borderColor}` : ''
  const outlineShadow = outlineColor ? `0 0 0 ${outlineWidth || DEFAULT_OUTLINE_WIDTH}px ${outlineColor}` : ''
  const shadows = [systemShadow, extraShadow, borderShadow, outlineShadow].filter(Boolean)

  return css`
    box-shadow: ${shadows.join(',')};
  `
}
