/* eslint-disable no-redeclare */
import { format as fnsFormat } from 'date-fns'
import { da, enUS } from 'date-fns/locale'

export function formatDate(date: Date | string, format?: string, locale?: string): string
export function formatDate(date: undefined | null, format?: string, locale?: string): undefined
export function formatDate(date: Date | string | null | undefined, format?: string, locale?: string): string | undefined
export function formatDate(
  date: Date | undefined | string | null,
  format = 'yyyy-MM-dd',
  locale?: string,
): string | undefined {
  if (!date) {
    return
  }

  const localeObject = locale === 'da' ? da : enUS
  return fnsFormat(new Date(date), format, { locale: localeObject })
}
