import { Divider, Flex, Spacing } from '@design-system'

import { ReactElement, ReactNode } from 'react'

import { CardFooter } from './elements/CardFooter'
import { CardHeader } from './elements/CardHeader'
import * as Styled from './styles'

interface IntegrationCardProps {
  children: ReactNode
}

export const IntegrationCard = ({ children }: IntegrationCardProps): ReactElement => {
  return (
    <Styled.IntegrationCardWrapper>
      <Flex flexDirection="column" gap={Spacing.L}>
        {children}
      </Flex>
    </Styled.IntegrationCardWrapper>
  )
}

IntegrationCard.Header = CardHeader
IntegrationCard.Divider = Divider
IntegrationCard.Footer = CardFooter
