import { useQuery } from 'react-query'

import { fetchFile } from '@views/umbrella/routes/umbrellaProfile/query-api'

import { QueryKeys } from '../enums/queryKeys'

interface UseLogoResponse {
  isLoading: boolean
  url?: string
}

export const useLogo = (fileId?: string): UseLogoResponse => {
  const {
    data,
    isIdle,
    isLoading: isFileLoading,
  } = useQuery([QueryKeys.UmbrellaLogo, fileId], () => fetchFile(fileId as string), {
    enabled: !!fileId,
    keepPreviousData: true,
  })

  const isLoading = !!fileId && (isIdle || isFileLoading)

  return {
    isLoading,
    url: fileId ? data?.file.downloadUrl : undefined,
  }
}
