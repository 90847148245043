import { FontSize, LineHeight, rwd, Spacing, Text as TextComponent } from '@design-system'

import styled from '@emotion/styled'

export const GuideHeaderWrapper = styled.header`
  margin-bottom: ${Spacing.XXL};

  ${rwd.equalOrLessThan('tablet')} {
    margin-bottom: ${Spacing.XL};
  }
`

export const Title = styled(TextComponent)`
  margin-bottom: ${Spacing.XL};

  ${rwd.equalOrLessThan('tablet')} {
    margin-bottom: ${Spacing.L};
    font-size: ${FontSize.TextH2};
    line-height: ${LineHeight.TextH2};
  }
`
