import { Flex, Icon, Spacing, Text } from '@design-system'

import React, { cloneElement, isValidElement, ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TimelineStatusItem } from './elements/TimelineStatusItem'
import { TimelineStatusItemsList, TimelineStatusItemsListProps } from './elements/TimelineStatusItemsList'
import * as Styled from './styles'

export interface TimelineStatusProps {
  children?: ReactNode
  content?: ReactNode
  isCollapsed?: boolean
  isLoading?: boolean
  title?: ReactNode
}

export const TimelineStatus = ({
  children,
  content,
  isCollapsed: isCollapsedControlled = false,
  isLoading,
  title,
}: TimelineStatusProps): ReactElement => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedControlled)

  const { t } = useTranslation()

  useEffect(() => {
    setIsCollapsed(isCollapsedControlled)
  }, [isCollapsedControlled])

  const toggle = useCallback(() => {
    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed)
  }, [])

  const handleHeaderClick = useCallback(() => {
    toggle()
  }, [toggle])

  return (
    <Styled.TimelineStatusWrapper>
      <Styled.Header>
        {title && (
          <Text variant="large" weight="medium">
            {title}
          </Text>
        )}
      </Styled.Header>
      {content && <Styled.ContentWrapper>{content}</Styled.ContentWrapper>}
      {children && isValidElement<TimelineStatusItemsListProps>(children) && (
        <Styled.ListWrapper>
          {cloneElement<TimelineStatusItemsListProps>(children, { isCollapsed, isLoading })}
        </Styled.ListWrapper>
      )}
      <Styled.ExpansionTriggerWrapper onClick={handleHeaderClick}>
        <Flex alignItems="center" justifyContent="center" gap={Spacing.XXS}>
          {!isLoading && (
            <Styled.ExpansionTriggerIconWrapper>
              <Icon icon={isCollapsed ? 'chevronDown' : 'chevronUp'} />
            </Styled.ExpansionTriggerIconWrapper>
          )}
          <Text colorVariant="secondary">
            {t(
              isCollapsed ? 'einvoce.timeline_status_expander.show_more' : 'einvoce.timeline_status_expander.show_less',
            )}
          </Text>
        </Flex>
      </Styled.ExpansionTriggerWrapper>
    </Styled.TimelineStatusWrapper>
  )
}

TimelineStatus.ItemsList = TimelineStatusItemsList
TimelineStatus.Item = TimelineStatusItem
