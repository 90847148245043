import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { FontWeight } from '../../enums/fontWeight'
import { Spacing } from '../../enums/spacing'
import { Themable } from '../../types/themable'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { Badge } from '../Badge'

interface TabProps extends Themable {
  active?: boolean
}

export const Tab = styled.div<TabProps>`
  padding: ${Spacing.M} ${Spacing.L};
  color: ${({ active, theme }) => (active ? theme.ds.colors.base.textPrimary : theme.ds.colors.base.textSecondary)};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex: 0 0 auto;
  user-select: none;
  white-space: nowrap;
  ${getDefaultTransition('box-shadow')};

  &:hover {
    box-shadow: inset 0 -1px 0 ${Color.Green};
  }

  ${({ active }) =>
    active &&
    css`
      &,
      &:hover {
        font-weight: ${FontWeight.Bold};
        box-shadow: inset 0 -2px 0 ${Color.Green};
      }
    `};
`

export const IconWrapper = styled.span`
  margin-right: ${Spacing.S};
  display: flex;
`

export const BadgeCounter = styled(Badge)`
  margin-left: ${Spacing.S};
`
