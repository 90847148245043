import { AccountantType } from '../types/accountantType'

export const accountantTypeToTranslationKey: Record<AccountantType, string> = {
  bookkeeper: 'umbrella.services.accountant_type.bookkeeper',
  accountant: 'umbrella.services.accountant_type.accountant',
  /* eslint-disable @typescript-eslint/naming-convention */
  accountant_hdr: 'umbrella.services.accountant_type.accountant_hdr',
  approved_accountant: 'umbrella.services.accountant_type.approved_accountant',
  chartered_accountant: 'umbrella.services.accountant_type.chartered_accountant',
  /* eslint-enable @typescript-eslint/naming-convention */
}
