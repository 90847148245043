import { call, put, takeLatest } from 'redux-saga/effects'

import { getUnAuthRequest } from '../../../utils'
import { currenciesReceived } from './action-creators'
import { CURRENCIES_REQUESTED } from './actions'
import { Currency } from './types'

function* fetchCurrencies() {
  const res: { currencies: Currency[] } = yield call(getUnAuthRequest, '/v2/currencies')
  yield put(currenciesReceived(res.currencies))
}

export default function* () {
  yield takeLatest(CURRENCIES_REQUESTED, fetchCurrencies)
}
