import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ReceiptFilterType } from '../elements/Receipt'
import { getReceiptsQueryFunction } from '../utils/getReceiptsQueryFunction'
import { getReceiptsQueryKey } from '../utils/getReceiptsQueryKey'
import { useGetFetchReceiptsQueryProps } from './useGetFetchReceiptsQueryProps'
import { useReceiptsFilters } from './useReceiptsFilters'

interface UseGetFirstReceiptProps {
  type?: ReceiptFilterType
}

export const useGetFirstReceipt = ({ type = ReceiptFilterType.All }: UseGetFirstReceiptProps) => {
  const { organization } = useUserOrganization()
  const [{ sortBy, sortDirection }] = useReceiptsFilters()

  const queryProps = useGetFetchReceiptsQueryProps({
    page: 1,
    pageSize: 1 as ItemsPerPage,
    sortDirection,
    searchQuery: '',
    sortBy,
    type,
  })

  const queryKey = getReceiptsQueryKey(queryProps)

  const { data } = useQuery(queryKey, () => getReceiptsQueryFunction(queryProps), {
    enabled: !!organization,
  })

  return useMemo(
    () => ({
      attachment: data?.attachments.length ? data.attachments[0] : undefined,
    }),
    [data],
  )
}
