import { Color, LottieAnimation } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import { FullScreenOverlay } from '../../FullScreenOverlay'
import * as Styled from './styles'

export interface ProcessingScreenProps {
  children: ReactNode
}

export const ProcessingScreen = ({ children }: ProcessingScreenProps): ReactElement => {
  return (
    <FullScreenOverlay bgColor={Color.Gray20} bgOpacity={0.7}>
      <Styled.AnimationWrapper data-chromatic="ignore">
        <LottieAnimation name="processing" width={48} height={48} />
      </Styled.AnimationWrapper>
      {children}
    </FullScreenOverlay>
  )
}
