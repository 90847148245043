import { css, keyframes } from '@emotion/core'

import { Color } from '../enums/color'

const loading = keyframes`
  from {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
`

export const skeletonAnimation = () => {
  const bgColor = Color.Gray20
  const color = Color.Gray50

  return css`
    position: relative;
    background-color: ${bgColor};
    overflow: hidden;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(90deg, ${bgColor} 25%, ${color} 37%, ${bgColor} 63%);
      background-size: 400% 100%;
      animation: ${loading} 1.4s ease infinite;
    }
  `
}
