import { Color, DefaultValue, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

interface AnswerWrapperProps {
  isSelected: boolean
}

export const AnswerWrapper = styled.button<AnswerWrapperProps>`
  display: flex;
  width: 100%;
  height: 56px;
  padding: 0 ${Spacing.XL};
  margin-bottom: -1px;
  border: 1px solid ${Color.Gray30};
  background: ${Color.White};
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${Color.Gray10};
  }

  &:first-of-type {
    margin-top: -1px;
    border-top-left-radius: ${DefaultValue.BorderRadius};
    border-top-right-radius: ${DefaultValue.BorderRadius};
  }
  &:last-of-type {
    border-bottom-left-radius: ${DefaultValue.BorderRadius};
    border-bottom-right-radius: ${DefaultValue.BorderRadius};
  }

  ${({ isSelected }) =>
    isSelected &&
    `
      border-color: ${Color.Green};
      position: relative;
      z-index: 1;

      &,
      &:hover {
        background: ${Color.Green10};
      }

      ${Letter} {
        &:after {
          background: ${Color.Green};
        }
      }
    `}
`

export const Letter = styled(Text)`
  display: flex;
  width: 30px;
  margin-right: ${Spacing.L};
  position: relative;
  align-items: center;

  &:after {
    content: '';
    width: 1px;
    height: 16px;
    position: absolute;
    right: 0;
    background-color: ${Color.Gray30};
  }
`

export const Option = styled(Text)``
