import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const BusinessSummaryWrapper = styled.div``

export const SelectFields = styled.div`
  display: grid;
  margin-bottom: ${Spacing.XL};
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${Spacing.M};
`

export const Field = styled.div``

export const Label = styled(Text)`
  margin-bottom: ${Spacing.XXXS};
`
