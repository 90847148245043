import cc from 'classcat'
import React from 'react'
import { Flex, FlexProps } from 'rebass'

import { reactClass } from '../../../utils'

export const FlexRight = ({ children, className, ...rest }: FlexProps) => (
  <Flex className={cc([className, reactClass('flex-right')])} flex="auto" justifyContent="flex-end" {...rest}>
    {children}
  </Flex>
)
