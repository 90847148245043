import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { StyledComponent } from '../../../types/styledComponent'

export const getErrorStyle = (Control: StyledComponent, checked: boolean): SerializedStyles => css`
  ${Control} {
    box-shadow: none;

    ${checked
      ? css`
          color: ${Color.White};
          border-color: ${Color.Red};
          background-color: ${Color.Red};
        `
      : css`
          color: ${Color.DeepSeaGreen};
          border-color: ${Color.Red};
          background-color: ${Color.White};
        `};
  }
`
