import { withModalConditionalRender } from '@design-system'

import React, { ReactElement } from 'react'

import { ModalId } from '../../../../enums/modalId'
import { DisableStripeModal as DisableStripe } from './elements/DisableStripeModal'
import { EnableStripeModal as EnableStripe } from './elements/EnableStripeModal'

const EnableStripeModal = withModalConditionalRender(EnableStripe)
const DisableStripeModal = withModalConditionalRender(DisableStripe)

export const StripeModalsGroup = (): ReactElement => {
  return (
    <>
      <EnableStripeModal id={ModalId.EnableStripeModal} />
      <DisableStripeModal id={ModalId.DisableStripeModal} />
    </>
  )
}
