import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { BankIntegrationContextProvider } from '@views/bankIntegration/contexts/bankIntegrationContext'
import { BankConnectionStatusActions } from '@views/bankIntegration/elements/BankConnectionStatusActions/BankConnectionStatusActions'

import { queryClient } from '../../config/queryClient'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

class BankConnectionStatusActionsComponent extends DOMModel {
  @byAttrVal accountid: string
  @registerEvent('checkBankIntegration') checkBankIntegration: EmberEventFn | undefined
  @byAttrVal organizationId: string
}

type BankConnectionStatusActionsModuleProps = {
  accountid: string
  onCheckBankIntegration?: EmberEventFn
  organizationId: string
}

function BankConnectionStatusActionsModule({
  accountid,
  onCheckBankIntegration,
  organizationId,
}: BankConnectionStatusActionsModuleProps): ReactElement {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <ModalContextProvider>
              <AccountsContextProvider organizationId={organizationId}>
                <BankIntegrationContextProvider
                  account={accountid}
                  onCheckBankIntegration={onCheckBankIntegration}
                  organizationId={organizationId}
                >
                  <BankConnectionStatusActions />
                </BankIntegrationContextProvider>
              </AccountsContextProvider>
            </ModalContextProvider>
          </UserOrganizationContextProvider>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const BankConnectionCustomElement = createCustomElement(
  BankConnectionStatusActionsModule,
  BankConnectionStatusActionsComponent,
  'element',
)

customElements.get('bank-connection-status-actions') ||
  customElements.define('bank-connection-status-actions', BankConnectionCustomElement)

export default BankConnectionCustomElement
