import { useTheme } from '@design-system'

import { css, SerializedStyles } from '@emotion/core'
import cc from 'classcat'
import React from 'react'
import { Flex, FlexProps } from 'rebass'

import { reactClass } from '../../../utils'

export type ContainerProps = FlexProps & {
  bg?: 'default' | 'inverse' | 'soft' | 'primary' | 'secondary' | 'inherit'
  css?: SerializedStyles
}

export const Container = ({ bg = 'default', children, className, css: styles, ...rest }: ContainerProps) => {
  const theme = useTheme()

  return (
    <Flex
      as="main"
      bg={`bg.${bg}`}
      className={cc([className, reactClass('container')])}
      variant="container"
      css={[
        css`
          width: 100%;
          position: absolute;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          flex-direction: column;
          overflow-x: hidden;
          overflow-y: auto;
          background-color: ${theme.ds.colors.base.background};
        `,
        styles,
      ]}
      {...rest}
    >
      {children}
    </Flex>
  )
}
