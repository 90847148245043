import { Color, getShadow, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ReconciliationSuggestionLineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spacing.S} ${Spacing.L};
  width: 100%;
  border-radius: 8px;
  background-color: ${Color.White};
  ${getShadow('raisedLight')};
`

export const ReconciliationSuggestionDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
