import { NavItem, NavItemAccessor } from '@design-system'

import { TFunction } from 'i18next'

import { EmberRoute } from '../../../../../enums/emberRoute'

export const getUserItems = (
  t: TFunction,
  isUmbrella: boolean,
  shouldShowUserSettings: boolean,
): NavItem<EmberRoute>[] => {
  const items: NavItem<EmberRoute>[] = [
    {
      id: 'logout',
      children: t('logout'),
      value: EmberRoute.Logout,
      iconLeft: 'logout',
    },
  ]

  if (shouldShowUserSettings) {
    items.unshift(
      {
        id: 'userSettings',
        children: t('settings.user'),
        value: isUmbrella ? EmberRoute.SettingsUserUmbrella : EmberRoute.SettingsUser,
        iconLeft: 'user',
      },
      {
        id: 'separator',
        accessor: NavItemAccessor.Separator,
        children: '',
        value: '' as EmberRoute,
      },
    )
  }

  return items
}
