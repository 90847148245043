import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconArrowLeft = ({ color = 'inherit', color2, width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-arrow-left')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <polyline stroke={coloring(color)} points="12 19 5 12 12 5" fill="none" />
      <line
        stroke={coloring(color2 || color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="19"
        y1="12"
        x2="5"
        y2="12"
      />
    </svg>
  </Box>
)
