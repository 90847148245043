import { useContext } from 'react'

import { ModalContext } from '../modalContext'

export const useModals = () => {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error('ModalContextProvider is missing in the module!')
  }

  return context
}
