import { NavItem, TableStaticActions } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface VatRatesTableStaticActionsProps {
  onDelete: (rulesetId: string) => void
  id: string
}

enum VatRatesStaticActionType {
  Delete = 'delete',
  Edit = 'edit',
}

export const VatRatesTableStaticActions = ({ onDelete, id: itemId }: VatRatesTableStaticActionsProps): ReactElement => {
  const { t } = useTranslation()
  const staticActionsItems: NavItem<VatRatesStaticActionType>[] = useMemo(
    () => [
      {
        id: 'delete',
        children: t('delete'),
        value: VatRatesStaticActionType.Delete,
        iconLeft: 'trash',
      },
    ],
    [t],
  )

  const handleStaticActionSelect = useCallback(
    (id: string, value: VatRatesStaticActionType) => {
      switch (value) {
        case 'delete': {
          onDelete(itemId)
          break
        }
      }
    },
    [onDelete, itemId],
  )

  return <TableStaticActions items={staticActionsItems} onSelect={handleStaticActionSelect} />
}
