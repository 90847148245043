import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { getInvitedBookkeepers } from '../query-api'

interface UseOrganizationBookkeepersArgs {
  enabled?: boolean
  refetchOnWindowFocus?: boolean
}

export const useOrganizationBookkeepers = ({
  enabled = true,
  refetchOnWindowFocus = true,
}: UseOrganizationBookkeepersArgs = {}) => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const organizationId = organization?.id || ''
  const ownerUmbrellaId = organization?.ownerUmbrellaId || ''

  const { data, isIdle, isLoading } = useQuery(
    [QueryKeys.OrganizationBookkeepers, organizationId, ownerUmbrellaId],
    () => getInvitedBookkeepers(organizationId, ownerUmbrellaId),
    {
      enabled: enabled && !isOrganizationLoading,
      refetchOnWindowFocus,
      retry: false,
    },
  )

  return {
    data: data || [],
    isLoading: isIdle || isLoading,
  }
}
