import { Color, Text } from '@design-system'

import React from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BoxProps } from 'rebass'

import { Select } from '../../../../components-deprecated/Select'
import { State } from '../../../../types/reduxSaga-deprecated'
import { getItemsForSelect } from '../../../../utils/forms'
import { citiesRequested } from '../action-creators'
import { City } from '../types'

export type CitySelectorProps = Omit<BoxProps, 'type' | 'onBlur' | 'onFocus'> & {
  countryId: string
  formControl?: Control<any>
  name: string
  selectedItem?: City
  disconnect?: boolean
  onItemSelect?: (city: City) => void
  onTextChange?: (text: string) => void
  errorPath?: string
}

const CityMenuItem = (city: City, selected: boolean) => (
  <Text weight="medium" color={selected ? Color.Blue : undefined}>
    {city.name}
  </Text>
)

export const CitySelector = ({
  countryId,
  formControl,
  name,
  selectedItem,
  disconnect,
  onTextChange = () => null,
  onItemSelect = () => null,
  ...rest
}: CitySelectorProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cities: City[] = useSelector((state: State) => state.app.cities.list)
  const page = useSelector((state: State) => state.app.cities.page)

  const handleFetch = (offset: number, text?: string) => {
    dispatch(
      citiesRequested({
        countryId,
        page: {
          offset,
        },
        filters: {
          text: text || null,
        },
      }),
    )
  }

  const items = getItemsForSelect<City>(cities)

  return (
    <Select<City>
      name={name}
      formControl={formControl}
      placeholder={t('app.address.address_selector.city_placeholder')}
      items={items}
      selectedItem={selectedItem}
      total={page?.total}
      render={CityMenuItem}
      onTextChange={onTextChange}
      onItemSelect={onItemSelect}
      onFetch={handleFetch}
      disconnect={!!formControl || disconnect}
      {...rest}
    />
  )
}
