import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const InfoItemWrapper = styled.div`
  display: flex;
  min-width: 120px;
  flex-direction: column;
  justify-content: flex-end;
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const IconWrapper = styled.div`
  margin-left: ${Spacing.XXS};
`
