import { useMemo } from 'react'

import { useFetchInvoices } from './useFetchInvoices'
import { useInvoicesListFilters } from './useInvoicesListFilters'

export const useInvoices = () => {
  const [{ page, pageSize, period, sortProperty, sortDirection, searchQuery, status }] = useInvoicesListFilters()
  const { invoices, isLoading, pagination } = useFetchInvoices({
    page,
    pageSize,
    period,
    sortProperty,
    sortDirection,
    searchQuery,
    status,
  })

  return { invoices: useMemo(() => invoices, [invoices]), isLoading, pagination }
}
