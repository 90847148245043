import React, { ReactElement } from 'react'

import { Color } from '../../../../enums/color'
import { Icon } from '../../../Icon'
import { InputSuffix, InputSuffixProps } from '../InputSuffix'

type SuccessIconProps = Omit<InputSuffixProps, 'children'>

export const SuccessIcon = ({ disabled, ...rest }: SuccessIconProps): ReactElement => (
  <InputSuffix disabled={disabled} {...rest}>
    <Icon icon="checkCircle" color={disabled ? Color.Gray60 : Color.Green} />
  </InputSuffix>
)
