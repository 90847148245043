import { InternalAccessor } from '@design-system'

import { useMemo } from 'react'

import {
  CurrencyDefaultPaymentMethod,
  FetchPaymentMethodsResponseData,
} from '../../../../../../../query-api/payment-methods-query'
import { CurrencyDefaultPaymentMethodsTableRow } from '../types/currencyDefaultPaymentMethodsTableRow'

export const useCurrencyDefaultPaymentMethodsTableData = (
  paymentMethods?: FetchPaymentMethodsResponseData,
): CurrencyDefaultPaymentMethodsTableRow[] => {
  return useMemo(() => {
    const currencyDefaultPaymentMethodsData: CurrencyDefaultPaymentMethod[] =
      paymentMethods?.meta?.currencyDefaults || []
    const paymentMethodsData = paymentMethods?.data || []
    const paymentMethodNameMap = paymentMethodsData.reduce((paymentMethod, item) => {
      paymentMethod[item.id] = item.name

      return paymentMethod
    }, {})

    return currencyDefaultPaymentMethodsData.map(({ currencyCode, paymentMethodId }) => {
      return {
        currencyCode,
        id: `${currencyCode}-${paymentMethodId}`,
        paymentMethodId,
        paymentMethodName: paymentMethodNameMap[paymentMethodId] || '',
        [InternalAccessor.StaticActions]: true,
      }
    })
  }, [paymentMethods?.data, paymentMethods?.meta?.currencyDefaults])
}
