import { Color, TableCellContent } from '@design-system'

import React from 'react'
import { CellProps } from 'react-table'

import { TransactionsTableRow } from '../types/transactionsTableRow'

export const getTransactionsTableColumnCell = ({ data, row, value }: CellProps<TransactionsTableRow>) => {
  const { voided } = data[row.id]

  return (
    <TableCellContent color={voided ? Color.Gray60 : undefined} title={value} truncate>
      {value}
    </TableCellContent>
  )
}
