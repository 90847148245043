import { DefaultValue, rwd, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const ReceiptsWrapper = styled.section`
  border-radius: ${DefaultValue.BorderRadius};
`

export const SkeletonReceiptWrapper = styled.div``

const getGridColumns = () => {
  return css`
    grid-template-columns: repeat(4, 1fr);

    ${rwd.lessThan('xlarge')} {
      grid-template-columns: repeat(4, 1fr);

      ${SkeletonReceiptWrapper} {
        &:nth-of-type(1n + 9) {
          display: none;
        }
      }
    }

    ${rwd.equalOrLessThan('large')} {
      grid-template-columns: repeat(3, 1fr);
    }
  `
}

export const ReceiptsList = styled.ol`
  display: grid;
  ${getGridColumns()}

  grid-column-gap: ${Spacing.L};
  grid-row-gap: ${Spacing.L};
`
