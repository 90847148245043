import { TabButtonsGroup } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useInvoiceEditor } from '../../contexts/InvoiceEditorContext'
import { InvoiceEditorView } from '../../enums/invoiceEditorView'

export const InvoiceEditorPreviewToggle = (): ReactElement => {
  const { t } = useTranslation()
  const { setView, view } = useInvoiceEditor()

  const isEditorSelected = view === InvoiceEditorView.Editor
  const isPreviewSelected = view === InvoiceEditorView.Preview

  const handleEditorTabClick = useCallback(() => {
    setView(InvoiceEditorView.Editor)
  }, [setView])

  const handlePreviewTabClick = useCallback(() => {
    setView(InvoiceEditorView.Preview)
  }, [setView])

  return (
    <TabButtonsGroup>
      <TabButtonsGroup.Button icon="paperWithPencil" onClick={handleEditorTabClick} selected={isEditorSelected}>
        {t('external_invoices.editor.tab.editor')}
      </TabButtonsGroup.Button>
      <TabButtonsGroup.Button icon="eye" onClick={handlePreviewTabClick} selected={isPreviewSelected}>
        {t('external_invoices.editor.tab.preview')}
      </TabButtonsGroup.Button>
    </TabButtonsGroup>
  )
}
