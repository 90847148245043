import React, { memo, ReactNode } from 'react'

import { Asable } from '../../../../types/asable'
import { Text } from '../../../Text'
import * as Styled from './styles'

interface NavListHeaderProps extends Asable {
  children: ReactNode
  onClick?: () => void
  stickyTopOffset?: number
}

export const NavListHeader = memo(({ children, stickyTopOffset, ...rest }: NavListHeaderProps) => {
  return (
    <Styled.Header stickyTopOffset={stickyTopOffset} {...rest}>
      <Text variant="h4" colorVariant="secondary">
        {children}
      </Text>
    </Styled.Header>
  )
})
