import { NavItem, Select } from '@design-system'

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../../../hooks'
import { useSalesTaxPeriods } from '../../../../hooks/useSalesTaxPeriods'
import { getSalesTaxPeriodItems } from '../../../../utils/getSalesTaxPeriodItems'
import { OrganizationAccountingFormSchema } from '../../../../utils/organizationAccountingSettingsFormData'

export const SalesTaxPeriodFormItem = () => {
  const { t } = useTranslation()
  const { salesTaxPeriods, isLoading: isLoadingPeriods } = useSalesTaxPeriods()
  const { control, FormItem } = useFormContext<OrganizationAccountingFormSchema>()
  const isVatExempted = useWatch({ control, name: 'isVatExempted' })
  const canUseVatReportSelect = isVatExempted

  const salesTaxPeriodItems: NavItem<string>[] = useMemo(
    () => getSalesTaxPeriodItems(salesTaxPeriods),
    [salesTaxPeriods],
  )

  return (
    <FormItem
      name="salesTaxPeriod"
      label={t('settings.organization.accounting.vat_report')}
      render={({ field: { onChange, value, ...rest } }) => (
        <Select
          selectedId={value}
          items={salesTaxPeriodItems}
          onSelect={onChange}
          dropdownSize="fitTrigger"
          disabled={canUseVatReportSelect}
          dropdownFetching={isLoadingPeriods}
          {...rest}
        />
      )}
    />
  )
}
