import { TFunction } from 'i18next'
import { array, number, object, SchemaOf, string } from 'yup'

import { RuleForm } from '../../../../../types/ruleForm'

export const createOrEditRulesetFormDefaultValues: CreateOrEditRulesetForm = {
  name: '',
  description: '',
  fallbackTaxRateId: '',
  abbreviation: '',
  rules: undefined,
}

export const getDefaultRule = (): RuleForm => ({
  contactType: null,
  countryGroupId: null,
  taxRateId: null,
  countryId: null,
})

export interface CreateOrEditRulesetForm {
  name: string
  description: string | undefined
  fallbackTaxRateId: string | undefined
  abbreviation: string | undefined
  rules: RuleForm[] | undefined
}

export type CreateOrEditRulesetFormSchema = SchemaOf<CreateOrEditRulesetForm>

export const getValidationSchema = (t: TFunction): CreateOrEditRulesetFormSchema => {
  return object({
    name: string().required(t('required_field')),
    abbreviation: string(),
    description: string(),
    fallbackTaxRateId: string(),
    rules: array().of(
      object({
        countryGroupId: string().nullable().default(null),
        countryId: string().nullable().default(null),
        contactType: string().nullable().default(null),
        taxRateId: string().nullable().default(null),
        priority: number().integer().min(1),
      }),
    ),
  })
}
