import { IntegrationStatus } from '../../../../../enums/integrationStatus'
import { IntegrationStatusBadeProps } from '../../../../../types/integrationStatusBadeProps'

export const integrationStatusToBadgeProps: Record<IntegrationStatus, IntegrationStatusBadeProps | null> = {
  [IntegrationStatus.Inactive]: null,
  [IntegrationStatus.Pending]: {
    variant: 'warning',
    tKey: 'integrations.card.status.pending',
  },
  [IntegrationStatus.Active]: {
    variant: 'success',
    tKey: 'integrations.card.status.active',
  },
}
