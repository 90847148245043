import { getYearsItems, NavItem } from '@design-system'

import { getYear } from 'date-fns'

import { PeriodMode } from '../../../../enums/periodMode'
import { NavigationValue } from '../types/navigationValue'

const SINGLE_YEAR_NAVIGATION_PERIODS: PeriodMode[] = [PeriodMode.Month, PeriodMode.Quarter]
const MULTIPLE_YEAR_NAVIGATION_PERIODS: PeriodMode[] = [PeriodMode.FiscalYear]
const YEAR_ITEMS_PER_VIEW = 10

const getSingleYearNavigationPeriods = (minDate?: Date, maxDate?: Date): NavItem<NavigationValue>[] => {
  return getYearsItems(minDate, maxDate)
}

const getMultipleYearNavigationPeriods = (minDate?: Date, maxDate?: Date): NavItem<NavigationValue>[] => {
  const yearsItems = getYearsItems(minDate, maxDate)
  const minYear = Math.floor(yearsItems[0].value / 10) * 10
  const maxYear = Math.floor(yearsItems[yearsItems.length - 1].value / 10) * 10 + YEAR_ITEMS_PER_VIEW - 1
  const navigationPeriods: NavItem<NavigationValue>[] = []

  let currentYear = minYear

  // we iterate over years from 'minDate' to 'maxDate' per amount of items we want to show in the UI
  while (currentYear <= maxYear) {
    const yearsFrom = currentYear
    const yearsTo = yearsFrom + YEAR_ITEMS_PER_VIEW - 1
    const idKey = `${yearsFrom}:${yearsTo}`

    navigationPeriods.push({
      id: idKey,
      children: `${yearsFrom} - ${yearsTo}`,
      value: idKey,
    })

    currentYear = yearsTo + 1
  }

  return navigationPeriods
}

const getAllTimeNavigationPeriods = (minDate?: Date, maxDate?: Date): NavItem<NavigationValue>[] => {
  const yearsFrom = getYear(minDate || new Date())
  const yearsTo = getYear(maxDate || new Date())
  const idKey = `${yearsFrom}:${yearsTo}`

  return [
    {
      id: idKey,
      children: `${yearsFrom} - ${yearsTo}`,
      value: idKey,
    },
  ]
}

export const getNavigationItems = (
  periodMode: PeriodMode,
  minDate?: Date,
  maxDate?: Date,
): NavItem<NavigationValue>[] => {
  if (SINGLE_YEAR_NAVIGATION_PERIODS.includes(periodMode)) {
    return getSingleYearNavigationPeriods(minDate, maxDate)
  }

  if (MULTIPLE_YEAR_NAVIGATION_PERIODS.includes(periodMode)) {
    return getMultipleYearNavigationPeriods(minDate, maxDate)
  }

  if (periodMode === PeriodMode.All) {
    return getAllTimeNavigationPeriods(minDate, maxDate)
  }

  return []
}
