import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../../../utils'
import { postRuleset } from '../../../../../query-api'

interface UseCreateRuleset {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useCreateRuleset = (props?: UseCreateRuleset) => {
  const { onError, onSuccess } = props || {}

  const {
    mutate: create,
    isLoading: isProcessing,
    ...rest
  } = useMutation(postRuleset, {
    onError,
    onSuccess,
  })

  return { create, isProcessing, ...rest }
}
