import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { InvoiceDocumentType } from '../../../enums/invoiceDocumentType'
import { fetchCreditNoteHtml, fetchInvoiceHtml } from '../../../query-api'

const fetchDocumentHtml: Partial<Record<InvoiceDocumentType, Function>> = {
  [InvoiceDocumentType.Invoice]: fetchInvoiceHtml,
  [InvoiceDocumentType.CreditNote]: fetchCreditNoteHtml,
}

export const useFetchInvoiceHtml = (invoiceId?: string, documentType?: InvoiceDocumentType) => {
  const { data, isLoading, ...rest } = useQuery(
    [QueryKeys.InvoiceZervantHtml, invoiceId, documentType],
    () => fetchDocumentHtml[documentType || '']?.(invoiceId),
    { enabled: !!invoiceId && !!documentType },
  )

  return { data, isLoading, ...rest }
}
