import { PreviewFile } from '../types'

const getPathFromUrl = (url: string): string => url.split('?')[0]

export const checkFilesEquality = (filesPrev: PreviewFile[], filesNext: PreviewFile[]) => {
  if (filesPrev.length !== filesNext.length) {
    return false
  }

  for (let i = 0; i < filesPrev.length; i++) {
    const prevSrcPath = getPathFromUrl(filesPrev[i].src)
    const nextSrcPath = getPathFromUrl(filesNext[i].src)

    if (prevSrcPath !== nextSrcPath) {
      return false
    }
  }

  return true
}
