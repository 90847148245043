import { TransactionOriginatorType } from '../../../enums/transactionOriginatorType'

export const transactionOriginatorTypeToTranslationKey: Record<TransactionOriginatorType, string> = {
  [TransactionOriginatorType.BankPayment]: 'transactions.table.type.bank_payment',
  [TransactionOriginatorType.Bill]: 'transactions.table.type.bill',
  [TransactionOriginatorType.ContactBalancePayment]: 'transactions.table.type.contact_balance_payment',
  [TransactionOriginatorType.ConversionBalance]: 'transactions.table.type.conversion_balance',
  [TransactionOriginatorType.DaybookTransaction]: 'transactions.table.type.daybook_transaction',
  [TransactionOriginatorType.ExternalInvoice]: 'transactions.table.type.invoice',
  [TransactionOriginatorType.InvoiceLateFee]: 'transactions.table.type.invoice_late_free',
  [TransactionOriginatorType.Invoice]: 'transactions.table.type.invoice',
  [TransactionOriginatorType.SalesTaxPayment]: 'transactions.table.type.sales_tax_payment',
  [TransactionOriginatorType.SalesTaxReturn]: 'transactions.table.type.sales_tax_return',
}
