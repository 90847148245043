import { Metable } from '../../../../../../types/metable'
import { deleteRequest, getRequest, postRequest } from '../../../../../../utils'
import { InvoiceTemplate } from './types/invoiceTemplate'

export interface FetchInvoiceTemplatesResponseData {
  data: InvoiceTemplate[]
}

export const fetchInvoiceTemplates = (organizationId: string): Promise<FetchInvoiceTemplatesResponseData> => {
  return getRequest(`/organizations/${organizationId}/templates`)
}

export interface FetchStandardInvoiceTemplateResponseData extends Metable {
  standardTemplate: Partial<InvoiceTemplate>
}

export const fetchStandardInvoiceTemplate = (
  organizationId: string,
): Promise<FetchStandardInvoiceTemplateResponseData> => {
  return getRequest(`/v2/organizations/${organizationId}/standardTemplate`)
}

export interface CreateInvoiceTemplateOptions {
  invoiceTemplate: Partial<InvoiceTemplate>
  organizationId: string
}

export interface CreateInvoiceTemplateResponseData {
  data: InvoiceTemplate
}

export const createInvoiceTemplate = ({
  invoiceTemplate,
  organizationId,
}: CreateInvoiceTemplateOptions): Promise<CreateInvoiceTemplateResponseData> =>
  postRequest(`/organizations/${organizationId}/templates`, { data: invoiceTemplate })

export interface DeleteInvoiceTemplateOptions {
  organizationId: string
  invoiceTemplateId: string
}

export const deleteInvoiceTemplate = ({
  organizationId,
  invoiceTemplateId,
}: DeleteInvoiceTemplateOptions): Promise<void> => {
  return deleteRequest(`/organizations/${organizationId}/templates/${invoiceTemplateId}`)
}
