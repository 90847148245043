import { notify } from '@design-system'

import { useLocalStorage } from '@rehooks/local-storage'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser, useUpdateUser } from '@modules-deprecated/app/user'

import { queryClient } from '../../../../../config/queryClient'
import { useDirtyRoute } from '../../../../../contexts/dirtyRouteContext'
import { LocalStorageKeys } from '../../../../../enums/localStorageKeys'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { useForm } from '../../../../../hooks'
import { getDefaultValues, getValidationSchema, UserPrivacyForm } from '../utils/userPrivacyFormData'

export const useUserPrivacyForm = () => {
  const { t } = useTranslation()
  const { setDirty: setRouteDirty } = useDirtyRoute()
  const [isIntercomMessengerHidden, setIsIntercomMessengerHidden] = useLocalStorage<boolean>(
    LocalStorageKeys.HideIntercomMessenger,
    false,
  )
  const [isBookkeepingHeroHidden, setIsBookkeepingHeroHidden] = useLocalStorage<boolean>(
    LocalStorageKeys.HideBookkeepingHero,
    false,
  )
  const { user, isLoading: isUserDataLoading } = useCurrentUser()
  const { update: updateUser, isLoading: isSubmitting } = useUpdateUser({
    onSuccess: () => {
      notify({
        id: NotificationKeys.SettingsUserProfile,
        message: t('settings.user.privacy.update_success'),
        variant: 'success',
      })
      setRouteDirty(false)
    },
    onError: () => {
      notify({
        id: NotificationKeys.SettingsUserProfile,
        message: t('settings.user.privacy.update_error'),
        variant: 'error',
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(QueryKeys.CurrentUser)
    },
  })

  const toggleSupportChat = useCallback(
    (isChecked: boolean) => {
      setIsIntercomMessengerHidden(isChecked)
      if (window.Intercom) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        window.Intercom('update', { hide_default_launcher: isChecked })
      }
    },
    [setIsIntercomMessengerHidden],
  )

  const toggleBookkeepingHero = useCallback(
    (isChecked: boolean) => {
      setIsBookkeepingHeroHidden(isChecked)

      if (window.userflow && window.userflow.setResourceCenterLauncherHidden) {
        window.userflow.setResourceCenterLauncherHidden(isChecked)
      }
    },
    [setIsBookkeepingHeroHidden],
  )

  const handleFormSubmit = useCallback(
    (values: UserPrivacyForm) => {
      if (!user) {
        return
      }
      toggleSupportChat(values.isSupportChatDisabled)
      toggleBookkeepingHero(values.isBookkeepingHeroDisabled)
      updateUser({
        id: user.id,
        isSupportAccessAllowed: values.isSupportAccessAllowed,
      })
    },
    [toggleBookkeepingHero, toggleSupportChat, updateUser, user],
  )

  const {
    formState: { isDirty: isFormDirty },
    reset,
    watch,
    ...form
  } = useForm({
    defaultValues: useMemo(
      () =>
        getDefaultValues({
          isBookkeepingHeroDisabled: isBookkeepingHeroHidden,
          isSupportChatDisabled: isIntercomMessengerHidden,
          user,
        }),
      [isBookkeepingHeroHidden, isIntercomMessengerHidden, user],
    ),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
    onSubmit: handleFormSubmit,
  })

  useEffect(() => {
    if (!isUserDataLoading) {
      reset(
        getDefaultValues({
          isBookkeepingHeroDisabled: isBookkeepingHeroHidden,
          isSupportChatDisabled: isIntercomMessengerHidden,
          user,
        }),
      )

      // toggleBookkeepingHero(isBookkeepingHeroHidden)
    }
  }, [isUserDataLoading, user, isIntercomMessengerHidden, reset, isBookkeepingHeroHidden, toggleBookkeepingHero])

  useEffect(() => {
    setRouteDirty(isFormDirty)
  }, [isFormDirty, setRouteDirty])

  return {
    ...form,
    isLoading: isUserDataLoading,
    isSubmitting,
  }
}
