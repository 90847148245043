import { css } from '@emotion/core'
import React, { KeyboardEvent } from 'react'
import { Box } from 'rebass'

import { InputSize } from '../../Form/Input'

export type SelectContainerProps = {
  children: React.ReactNode
  inputSize?: InputSize
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void
}

export const SelectContainer = React.forwardRef(
  ({ children, onKeyDown, inputSize, ...rest }: SelectContainerProps, ref) => (
    <Box
      ref={ref}
      onKeyDown={onKeyDown}
      css={css`
        position: relative;
      `}
      {...rest}
    >
      {children}
    </Box>
  ),
)
