import { Attachment, useModal } from '@design-system'

import isEqual from 'lodash/isEqual'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { useAttachmentsContext } from '@views/receipts/contexts/attachmentsContext'
import { useFetchAttachments } from '@views/receipts/hooks/useFetchAttachments'

import { ModalId } from '../../enums/modalId'
import { ModalFilesChooser } from '../ModalFilesChooser'

export interface AttachmentsModalProps {
  idsToSkip?: string[]
  onSelect: (attachment: Attachment) => void
}

export const AttachmentsModal = ({ idsToSkip = [], onSelect }: AttachmentsModalProps): ReactElement => {
  const [attachmentsVisible, setAttachmentsVisible] = useState<Attachment[]>([])
  const { filters, setFilters } = useAttachmentsContext()
  const { isOpen } = useModal(ModalId.AttachmentsModal)
  const { attachments, isLoading, isFetching, pagination } = useFetchAttachments({ enabled: isOpen })

  const attachmentsVisibleFiltered = useMemo(
    () => attachmentsVisible.filter((attachment) => !idsToSkip.includes(attachment.id)),
    [attachmentsVisible, idsToSkip],
  )

  // Set next page to fetch next page attachments
  const handleScrollEnd = useCallback(() => {
    if (pagination?.pageCount && filters.page < pagination.pageCount) {
      setFilters({
        page: filters.page + 1,
      })
    }
  }, [filters, pagination, setFilters])

  // Add new items to files state
  useEffect(() => {
    if (attachments.length && !isEqual(attachments, attachmentsVisible)) {
      setAttachmentsVisible([...attachmentsVisible, ...attachments])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments])

  return (
    <ModalFilesChooser
      files={attachmentsVisibleFiltered}
      id={ModalId.AttachmentsModal}
      isLoading={isLoading || isFetching}
      onScrollEnd={handleScrollEnd}
      onSelect={onSelect}
    />
  )
}
