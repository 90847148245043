import { call, put, takeLatest } from 'redux-saga/effects'

import { getRequest } from '../../../utils'
import {
  isTrustedPartnerReceived,
  IsTrustedPartnerRequestedAction,
  organizationReceived,
  OrganizationRequestedAction,
  organizationSettingsReceived,
  OrganizationSettingsRequestedAction,
  organizationUmbrellasReceived,
  OrganizationUmbrellasRequestedAction,
} from './action-creators'
import {
  IS_TRUSTED_PARTNER_REQUESTED,
  ORGANIZATION_REQUESTED,
  ORGANIZATION_SETTINGS_REQUESTED,
  ORGANIZATION_UMBRELLAS_REQUESTED,
} from './actions'
import { Organization } from './types/organization'
import { OrganizationSettings } from './types/organizationSettings'

type OrganizationUmbrellaInformation = {
  data: Array<{
    id: string
    name: string
    umbrellaId: string
  }>
}

function* fetchOrganization(action: OrganizationRequestedAction) {
  const res: { organization: Organization } = yield call(getRequest, `/v2/organizations/${action.payload}`)
  yield put(organizationReceived(res.organization))
}

function* fetchOrganizationSettings(action: OrganizationSettingsRequestedAction) {
  const res: { settings: OrganizationSettings } = yield call(
    getRequest,
    `/v2/organizations/${action.payload}/bootstrap`,
  )
  yield put(organizationSettingsReceived(res.settings))
}

function* fetchOrganizationUmbrellas(action: OrganizationUmbrellasRequestedAction) {
  try {
    const res: OrganizationUmbrellaInformation = yield call(getRequest, `/organizations/${action.payload}/umbrellas`)
    const umbrellas = []

    for (const { umbrellaId } of res?.data || []) {
      const { data: umbrella } = yield call(getRequest, `/umbrellas/${umbrellaId}`)
      umbrellas.push(umbrella)
    }

    yield put(organizationUmbrellasReceived(umbrellas))
  } catch (error) {
    yield put(organizationUmbrellasReceived([]))
  }
}

function* fetchIsTrustedPartner(action: IsTrustedPartnerRequestedAction) {
  try {
    const { data: isTrustedPartner } = yield call(
      getRequest,
      `/organizations/${action.payload}/umbrellas/isTrustedPartner`,
    )
    yield put(isTrustedPartnerReceived(isTrustedPartner))
  } catch (error) {
    yield put(isTrustedPartnerReceived(false))
  }
}

export default function* () {
  yield takeLatest(ORGANIZATION_REQUESTED, fetchOrganization)
  yield takeLatest(ORGANIZATION_SETTINGS_REQUESTED, fetchOrganizationSettings)
  yield takeLatest(ORGANIZATION_UMBRELLAS_REQUESTED, fetchOrganizationUmbrellas)
  yield takeLatest(IS_TRUSTED_PARTNER_REQUESTED, fetchIsTrustedPartner)
}
