import { Tab, TabsGroup } from '@design-system'

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { SendMode, useSendInvoiceModal } from '../../context/sendInvoiceModalContext'

export const ModalNavigation = () => {
  const { t } = useTranslation()
  const { sendMode, setSendMode } = useSendInvoiceModal()

  const handleTabClick = useCallback(
    (sendMode: SendMode) => () => {
      setSendMode(sendMode)
    },
    [setSendMode],
  )

  return (
    <TabsGroup>
      <Tab active={sendMode === 'eInvoice'} onClick={handleTabClick('eInvoice')}>
        {t('modal.send_invoice.tabs.e_invoice')}
      </Tab>
      <Tab active={sendMode === 'email'} onClick={handleTabClick('email')}>
        {t('modal.send_invoice.tabs.email')}
      </Tab>
    </TabsGroup>
  )
}
