import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface LayoutProps {
  children: ReactNode
}

export const Layout = ({ children }: LayoutProps): ReactElement => {
  return <Styled.LayoutWrapper>{children}</Styled.LayoutWrapper>
}
