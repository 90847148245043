import { Badge } from '@design-system'

import React, { ReactElement } from 'react'

import { Scope } from '../../../../enums/scope'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { useReceiptsCounter } from '../../hooks/useReceiptsCounter'
import { ReceiptFilterType } from '../Receipt'

interface ReceiptsCountBadgeProps {
  documentFilterType?: ReceiptFilterType
}

export const ReceiptsCountBadge = ({ documentFilterType }: ReceiptsCountBadgeProps): ReactElement | null => {
  const { countMap, isOverLimitMap, isLoading } = useReceiptsCounter({ enabled: true })
  const isEDocumentReadAuthorized = isAuthorized(Scope.EDocumentRead)

  const defaultFilterType = isEDocumentReadAuthorized ? ReceiptFilterType.All : ReceiptFilterType.Upload
  const filterType = documentFilterType || defaultFilterType
  const withCounter = countMap[filterType] > 0 && !isLoading

  if (!withCounter) {
    return null
  }

  return (
    <Badge>
      {countMap[filterType]}
      {isOverLimitMap[filterType] ? '+' : ''}
    </Badge>
  )
}
