import { NavItem, Select, SelectProps } from '@design-system'

import React, { forwardRef, ReactElement, Ref, useMemo } from 'react'

import { useUmbrellaOrganizationsProductPlans } from '../../hooks/useUmbrellaOrganizationsProductPlans'
import { getProductPlanId } from './utils/getProductPlanId'

interface UmbrellaOrganizationsProductPlansSelectProps extends Omit<SelectProps<ProductPlan>, 'items'> {
  umbrellaId: string
}

export interface ProductPlan {
  period: string
  plan: string
}

export const UmbrellaOrganizationsProductPlansSelect = forwardRef(
  (
    { umbrellaId, ...restProps }: UmbrellaOrganizationsProductPlansSelectProps,
    ref: Ref<HTMLDivElement | null>,
  ): ReactElement => {
    const { productPlans, isLoading } = useUmbrellaOrganizationsProductPlans(umbrellaId)

    const items: NavItem<ProductPlan>[] = useMemo(() => {
      if (isLoading) {
        return []
      }

      // eslint-disable-next-line @typescript-eslint/naming-convention
      return productPlans.map(({ product_plan_key, product_plan_name, product_plan_period }) => {
        const id = getProductPlanId(product_plan_key, product_plan_period)

        return {
          children: product_plan_name,
          id,
          value: {
            period: product_plan_period,
            plan: product_plan_key,
          },
        }
      })
    }, [productPlans, isLoading])

    return (
      <Select
        {...restProps}
        dropdownFetching={isLoading}
        dropdownSize={restProps.dropdownSize || 'fitTrigger'}
        items={items}
        ref={ref}
      />
    )
  },
)
