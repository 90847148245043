import numeral from 'numeral'

import { amountToDisplayValue } from '../../../utils/amountToDisplayValue'
import { limitValue } from './limitValue'

interface GetFormattedValueOptions {
  value: string | number | undefined
  max?: number
  min?: number
  isDisplayValueFormat?: boolean
  isFallbackToZero?: boolean
}

export interface GetFormattedValueResponse {
  value: string
  hasValueBeenLimited: boolean
}

export const getFormattedValue = ({
  value,
  isDisplayValueFormat,
  isFallbackToZero,
  max,
  min,
}: GetFormattedValueOptions): GetFormattedValueResponse => {
  const valueParsed = numeral(value).value()
  const { valueLimited, hasValueBeenLimited } = limitValue({ value: valueParsed, min, max })
  let formattedValue = valueLimited ? String(valueLimited) : ''

  if (!formattedValue && isFallbackToZero) {
    formattedValue = '0'
  }

  formattedValue = isDisplayValueFormat ? amountToDisplayValue(formattedValue) : formattedValue

  return { value: formattedValue, hasValueBeenLimited }
}
