import qs from 'qs'

import { getRequest } from '../utils'

export interface UmbrellaMemberRole {
  name: string
  roleId: string
}

export interface UmbrellaMember {
  email: string
  name: string
  profilePicUrl: string | null
  userId: string
  roles: UmbrellaMemberRole[]
}

export interface GetUmbrellaMembersQueryParams {
  isGrantAdmin?: boolean
}

export const getUmbrellaMembers = async (umbrellaId: string, { isGrantAdmin }: GetUmbrellaMembersQueryParams = {}) => {
  const queryParams = qs.stringify({
    ...(isGrantAdmin && { isGrantAdmin }),
  })

  const { data: users }: { data: UmbrellaMember[] } = await getRequest(
    `/umbrellas/${umbrellaId}/users?${queryParams}`,
    {},
    true,
  )

  return users
}
