import React from 'react'
import { Box } from 'rebass'

import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconProducts = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  isOutlined,
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-products')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 322.08 354.8">
      <path
        d="M335.06,274.32V143.17a32.79,32.79,0,0,0-16.39-28.36L203.92,49.24a32.78,32.78,0,0,0-32.79,0L56.37,114.81A32.79,32.79,0,0,0,40,143.17V274.32a32.79,32.79,0,0,0,16.39,28.36l114.76,65.57a32.78,32.78,0,0,0,32.79,0l114.75-65.57A32.79,32.79,0,0,0,335.06,274.32Z"
        transform="translate(-26.48 -31.34)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="27"
      />
      <polyline
        points="19.88 95.69 161.04 177.57 301.4 96.05"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="27"
      />
      <line
        x1="161.19"
        y1="339.43"
        x2="161.04"
        y2="177.4"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="27"
      />
    </svg>
  </Box>
)
