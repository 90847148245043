import { SortDirection } from '../../../../../../../enums/sortDirection'
import { AdditionalFieldsSortProperty } from '../../../enums/additionalFieldsSortProperty'
import { TaxMetaField } from '../../../types/taxMetaField'
import { useFetchAdditionalFields } from './useFetchAdditionalFields'

interface UseAdditionalFieldsResponse {
  additionalFields: TaxMetaField[]
  isLoading?: boolean
}

export const useAdditionalFields = (): UseAdditionalFieldsResponse => {
  const { data, isLoading } = useFetchAdditionalFields({
    sortDirection: SortDirection.Asc,
    sortProperty: AdditionalFieldsSortProperty.Priority,
  })

  return {
    additionalFields: data,
    isLoading,
  }
}
