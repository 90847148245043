import { amountToDisplayValue, TableCellContent, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { UmbrellaLicensePackOrganization } from '../../../../types/umbrellaLicensePackOrganization'
import * as Styled from './styles'

interface LicenseOrganizationsSubRowProps {
  license: string
  organizations: UmbrellaLicensePackOrganization[]
}

export const LicenseOrganizationsSubRow = ({
  license,
  organizations,
}: LicenseOrganizationsSubRowProps): ReactElement => {
  const { t } = useTranslation()

  if (!organizations.length) {
    return (
      <Text colorVariant="secondary" alignment="center">
        {t('umbrella_subscriptions.license_packs.table.no_licenses')}
      </Text>
    )
  }

  return (
    <Styled.OrganizationsList>
      {organizations.map(({ id, name }) => {
        return (
          <Styled.OrganizationsListRow key={id}>
            <TableCellContent>{name}</TableCellContent>
            <TableCellContent colorVariant="secondary">{license}</TableCellContent>
            <TableCellContent colorVariant="secondary" alignment="right">
              {amountToDisplayValue(0, true, 'DKK')}
            </TableCellContent>
          </Styled.OrganizationsListRow>
        )
      })}
    </Styled.OrganizationsList>
  )
}
