import { useTranslation } from 'react-i18next'

import { Color } from '../../../../enums/color'
import { Flex } from '../../../Flex'
import { Icon } from '../../../Icon'

export const FileLockedThumbnail = () => {
  const { t } = useTranslation()

  return (
    <Flex alignItems="center" justifyContent="center">
      <Icon icon="lock" color={Color.Red} aria-label={t('attachments.thumbnail_error.password_protected')} />
    </Flex>
  )
}
