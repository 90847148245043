import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from '../../../types/theme'
import { IconLock } from '../../Icons/icons/IconLock'
import { fileThumbnailCommonStyles } from './styles'

const FileLockedThumbnailStyled = styled(IconLock)`
  ${fileThumbnailCommonStyles};
`

export const FileLockedThumbnail = () => {
  const theme = useTheme<Theme>()
  const { t } = useTranslation()

  return (
    <FileLockedThumbnailStyled
      width="15px"
      height="15px"
      color={theme.colors.warning}
      title={t('attachments.thumbnail_error.password_protected')}
    />
  )
}
