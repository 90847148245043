import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { OrganizationInvitation, OrganizationInvitationProvider } from '@views/organizationInvitation'
import { EmberActionPayload } from '@views/signupGuide/context/signupGuideContext'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'organization-invitation-module'

class OrganizationInvitationWebComponent extends DOMModel {
  @byAttrVal token: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('authorizeUser') authorizeUser: Function
  @registerEvent('registerOrganization') registerOrganization: Function
}

interface OrganizationInvitationProps {
  onNavigate: EmberNavigate
  onAuthorizeUser: (payload: EmberActionPayload) => void
  onRegisterOrganization: (payload: EmberActionPayload) => void
  token: string
}

const OrganizationInvitationModule = ({
  onNavigate,
  token,
  onAuthorizeUser,
  onRegisterOrganization,
}: OrganizationInvitationProps): ReactElement => {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <EmberRouterContextProvider onNavigate={onNavigate}>
          <OrganizationInvitationProvider
            token={token}
            onAuthorizeUser={onAuthorizeUser}
            onRegisterOrganization={onRegisterOrganization}
          >
            <OrganizationInvitation />
          </OrganizationInvitationProvider>
        </EmberRouterContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const OrganizationInvitationCustomElement = createCustomElement(
  OrganizationInvitationModule,
  OrganizationInvitationWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, OrganizationInvitationCustomElement)

export default OrganizationInvitationCustomElement
