import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const MissingScreenWrapper = styled.section`
  & + & {
    margin-top: ${Spacing.XXL};
  }
`

export const Title = styled(Text)`
  margin-bottom: 10px;
`
