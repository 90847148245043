import { EmptyView, emptyViewVariants } from '@components'
import { ModuleLayout } from '@design-system'

import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { useContactsEmberConnection } from '../../context/contactsEmberConnectionContext'
import { ContactRoute } from '../../enum/contactRoute'
import { defaultContactsQuery, useContactsListFilters } from '../../hooks/useContactsListFilters'
import { useIsContactsListEmpty } from '../../hooks/useIsContactsListEmpty'

export const ContactsEmptyState = (): ReactElement => {
  const { t } = useTranslation()
  const [, setFilters] = useContactsListFilters()
  const isContactsListEmpty = useIsContactsListEmpty()
  const { createNewContact } = useContactsEmberConnection()

  useEffect(() => {
    setFilters(defaultContactsQuery)
  }, [setFilters])

  const { animation, description, title, buttonText } = emptyViewVariants.contacts

  const handleEmptyViewClick = useCallback(() => {
    createNewContact()
  }, [createNewContact])

  if (!isContactsListEmpty) {
    return <Redirect to={ContactRoute.List} />
  }

  return (
    <ModuleLayout title={t('contacts.header')}>
      <EmptyView
        animation={animation}
        onClick={handleEmptyViewClick}
        tButton={buttonText}
        tDescription={description}
        tTitle={title}
        showCTA
      />
    </ModuleLayout>
  )
}
