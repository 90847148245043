import { Badge } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { billStatusToBadgeVariant } from '../../../../constants/billStatusToBadgeVariant'
import { billStatusToTitleKey } from '../../../../constants/billStatusToTitleKey'
import { BillStatus } from '../../../../enums/billStatus'

interface BillPaymentPanePrefixProps {
  status: BillStatus
}

export const BillPaymentPanePrefix = ({ status }: BillPaymentPanePrefixProps): ReactElement => {
  const { t } = useTranslation()

  const badgeVariant = billStatusToBadgeVariant[status]
  const badgeLabel = t(billStatusToTitleKey[status])

  return <Badge variant={badgeVariant}>{badgeLabel}</Badge>
}
