import { Color } from '../../../../../enums/color'
import { Theme } from '../../../../../types/theme'

export interface DayColorProps {
  isDisabled?: boolean
  isHovered?: boolean
  isSelected?: boolean
  isSelectedStartOrEnd?: boolean
  isWithinHoverRange?: boolean
  theme: Theme
}

interface DayColor {
  bgColor: Color | undefined
  textColor: Color | undefined
}

export const getDayColor = ({
  isDisabled,
  isHovered,
  isSelected,
  isSelectedStartOrEnd,
  isWithinHoverRange,
  theme,
}: DayColorProps): DayColor => {
  if (isSelectedStartOrEnd) {
    return {
      textColor: theme.ds.colors.base.textPrimary,
      bgColor: Color.ElectricGreen,
    }
  }

  if (isSelected) {
    return {
      textColor: theme.ds.colors.base.textPrimary,
      bgColor: Color.ElectricGreen,
    }
  }

  if (isWithinHoverRange) {
    return {
      textColor: Color.White,
      bgColor: theme.ds.colors.base.textPrimary,
    }
  }

  if (isDisabled) {
    return {
      textColor: Color.Gray40,
      bgColor: Color.White,
    }
  }

  if (isHovered) {
    return {
      textColor: Color.White,
      bgColor: theme.ds.colors.base.textPrimary,
    }
  }

  return {
    textColor: undefined, // default Text color
    bgColor: Color.White,
  }
}
