import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Color } from '../../../../enums/color'
import { amountToDisplayValue } from '../../../../utils/amountToDisplayValue'
import { Text } from '../../../Text'

interface PaneAmountProps {
  amount: number | null
  color: Color
}

export const PaneAmount = ({ amount, color }: PaneAmountProps): ReactElement | null => {
  const { t } = useTranslation()

  if (amount !== null) {
    return (
      <>
        <Text colorVariant="secondary" variant="micro">
          {t('invoices.view.payment_pane.amount.label')}
        </Text>
        <Text variant="h3" weight="medium" color={color}>
          {amountToDisplayValue(amount)}
        </Text>
      </>
    )
  }

  return null
}
