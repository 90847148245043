import { Color } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { Checkmark } from '../Checkmark'
import * as Styled from './styles'

interface AnswerProps {
  isSelected: boolean
  letter: string
  onClick: (options: string[]) => void
  option: string
  selectedOptions: string[]
}

export const Answer = ({ isSelected, letter, onClick, option, selectedOptions }: AnswerProps): ReactElement => {
  const handleClick = useCallback(() => {
    if (isSelected) {
      onClick(selectedOptions.filter((selectedOption) => selectedOption !== option))
    } else {
      onClick([...selectedOptions, option])
    }
  }, [isSelected, onClick, option, selectedOptions])

  return (
    <Styled.AnswerWrapper isSelected={isSelected} onClick={handleClick} type="button">
      <Styled.Letter color={isSelected ? Color.Green : Color.Gray40} uppercase>
        {letter}
      </Styled.Letter>
      <Styled.Option colorVariant={isSelected ? 'success' : 'primary'} weight={isSelected ? 'medium' : 'regular'}>
        {option}
      </Styled.Option>
      {isSelected && <Checkmark />}
    </Styled.AnswerWrapper>
  )
}
