import { BooleanString, ReturnValue } from './convertAccountingFormDataForSubmit'

export const convertSupplierInvoiceNoToNumber = (value: BooleanString): ReturnValue => {
  if (value === '1' || value === true) {
    return '1'
  }
  return ''
}

export const convertSupplierInvoiceNoToBoolean = (value: BooleanString): boolean => {
  if (value === '1' || value === true) {
    return true
  }
  return false
}
