import { Badge, TableCellContent } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { User } from '../../../../../../types/user'
import * as Styled from './styles'

interface TableUserNameProps extends Omit<User, 'logoId' | 'id'> {
  name?: string
  prefix?: ReactNode
}

export const TableUserName = ({ isOwner, isPending, name, prefix }: TableUserNameProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.TableUserNameWrapper>
      {prefix}
      {(name || isPending) && (
        <TableCellContent weight="medium" truncate>
          <Styled.UserNameAndBadgeWrapper>
            {!!name && name}
            {isPending && <Badge>{t('settings.organization.users.pending_status')}</Badge>}
          </Styled.UserNameAndBadgeWrapper>
        </TableCellContent>
      )}
    </Styled.TableUserNameWrapper>
  )
}
