import { getImgPath } from '../../../../../../../utils/getImgPath'

interface NotePointImage {
  src: string
  width: number
}

interface NotePoint {
  headingTKey: string
  image?: NotePointImage
  paragraphTKey: string
}

export const debtCollectionNoteModalPoints: NotePoint[] = [
  {
    headingTKey: 'integrations.card.debt_collection.modal_note.body.activate_recovery',
    image: { src: getImgPath('/modals/debt-collection-activate-recovery.png'), width: 350 },
    paragraphTKey: 'integrations.card.debt_collection.modal_note.body.activate_recovery.paragraph',
  },
  {
    headingTKey: 'integrations.card.debt_collection.modal_note.body.dispute_claim',
    image: { src: getImgPath('/modals/debt-collection-dispute-over-claim.png'), width: 500 },
    paragraphTKey: 'integrations.card.debt_collection.modal_note.body.dispute_claim.paragraph',
  },
  {
    headingTKey: 'integrations.card.debt_collection.modal_note.body.record_payments',
    paragraphTKey: 'integrations.card.debt_collection.modal_note.body.record_payments.paragraph',
  },
  {
    headingTKey: 'integrations.card.debt_collection.modal_note.body.disclaimer',
    paragraphTKey: 'integrations.card.debt_collection.modal_note.body.disclaimer.paragraph',
  },
]
