import { AnimationName, Button, ButtonProps, IconName, LottieAnimation, SafeHtml, Text } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TrackingContext } from '../../enums/trackingContext'
import { ButtonPremium } from '../PremiumComponents'
import { ButtonProtected, ProtectableProps } from '../ProtectedComponents'
import * as Styled from './styles'

interface EmptyTypeProps extends Partial<ProtectableProps> {
  animation: AnimationName
  buttonIcon?: IconName
  onClick?: () => void
  showCTA?: boolean
  tButton?: string
  tDescription: string
  trackingContext?: TrackingContext
  tTitle: string
}

export const EmptyView = ({
  animation,
  buttonIcon = 'plusCircle',
  onClick,
  scopes,
  showCTA,
  tButton,
  tDescription,
  tTitle,
  trackingContext,
}: EmptyTypeProps): ReactElement => {
  const { t } = useTranslation()

  const ctaButtonProps: ButtonProps = useMemo(
    () =>
      ({
        onClick,
        icon: buttonIcon,
        size: 'xl',
      }) as ButtonProps,
    [buttonIcon, onClick],
  )

  if (scopes && !trackingContext) {
    throw new Error('You have to define "trackingContext" when button is protected with scopes!')
  }

  const shouldUseProtectedButton = scopes && trackingContext
  const shouldUsePremiumButton = !scopes && trackingContext
  const shouldUseRegularButton = !shouldUseProtectedButton && !shouldUsePremiumButton

  return (
    <Styled.EmptyViewWrapper>
      <LottieAnimation height={100} width={100} isClickToPauseDisabled name={animation} />

      <Styled.TitleWrapper>
        <Text variant="h2">{t(tTitle)}</Text>
      </Styled.TitleWrapper>

      <Styled.TextWrapper>
        <Text alignment="center" colorVariant="secondary" variant="large">
          <SafeHtml as="span" htmlContent={t(tDescription)} />
        </Text>
      </Styled.TextWrapper>

      {showCTA && (
        <Styled.ButtonWrapper>
          {shouldUseProtectedButton && (
            <ButtonProtected {...ctaButtonProps} scopes={scopes} trackingContext={trackingContext as TrackingContext}>
              {tButton && t(tButton)}
            </ButtonProtected>
          )}
          {shouldUsePremiumButton && (
            <ButtonPremium {...ctaButtonProps} trackingContext={trackingContext as TrackingContext}>
              {tButton && t(tButton)}
            </ButtonPremium>
          )}
          {shouldUseRegularButton && <Button {...ctaButtonProps}>{tButton && t(tButton)}</Button>}
        </Styled.ButtonWrapper>
      )}
    </Styled.EmptyViewWrapper>
  )
}
