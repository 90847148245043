import bottommenu from './bottomMenu'
import hovermenu from './hoverMenu'
import menu from './menu'
import menuSearchResults from './menuSearchResults'
import organizationMenu from './organizationMenu'
import quickmenubutton from './quickMenuButton'
import submenu from './subMenu'

export default {
  ...menu,
  ...submenu,
  ...hovermenu,
  ...quickmenubutton,
  ...menuSearchResults,
  ...bottommenu,
  ...organizationMenu,
}
