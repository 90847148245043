import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { TransactionOriginatorTypeGroup } from '../../../../../enums/transactionOriginatorTypeGroup'

export const getTransactionsOriginatorTypeGroupsData = (t: TFunction): NavItem<TransactionOriginatorTypeGroup>[] => {
  return [
    {
      id: TransactionOriginatorTypeGroup.Bills,
      children: t('transactions.table_actions.types.bills'),
      value: TransactionOriginatorTypeGroup.Bills,
    },
    {
      id: TransactionOriginatorTypeGroup.Invoices,
      children: t('transactions.table_actions.types.invoices'),
      value: TransactionOriginatorTypeGroup.Invoices,
    },
    {
      id: TransactionOriginatorTypeGroup.Payments,
      children: t('transactions.table_actions.types.payments'),
      value: TransactionOriginatorTypeGroup.Payments,
    },
    {
      id: TransactionOriginatorTypeGroup.Vat,
      children: t('transactions.table_actions.types.vat'),
      value: TransactionOriginatorTypeGroup.Vat,
    },
    {
      id: TransactionOriginatorTypeGroup.Journals,
      children: t('transactions.table_actions.types.journals'),
      value: TransactionOriginatorTypeGroup.Journals,
    },
  ]
}
