import { call, put, takeLatest } from 'redux-saga/effects'

import { Contact } from '@views/contacts/types/contact'

import { getRequest } from '../../../utils'
import { contactsReceived, ContactsRequested } from './action-creators'
import { CONTACTS_REQUESTED } from './actions'

function* fetchContacts({ payload }: ContactsRequested) {
  const { organizationId, filters } = payload
  const res: { contacts: Contact[] } = yield call(
    getRequest,
    `/v2/contacts?organizationId=${organizationId}` +
      (filters.text ? `&q=${filters.text}` : '') +
      (filters.isCustomer ? '&isCustomer=true' : '') +
      (filters.isVendor ? '&isSupplier=true' : '') +
      (filters.pageSize ? `&pageSize=${filters.pageSize}` : ''),
  )
  yield put(contactsReceived(res.contacts))
}

export default function* () {
  yield takeLatest(CONTACTS_REQUESTED, fetchContacts)
}
