import { css, SerializedStyles } from '@emotion/core'
import cc from 'classcat'
import React from 'react'
import { Box, BoxProps, Flex } from 'rebass'

import { coloring } from '../../styles-deprecated'
import { reactClass } from '../../utils'
import { Line, Thumb } from './elements'
import { randomPercent } from './utils'

export type LoadingPlaceholderProps = BoxProps & {
  borderRadius?: string
  color1?: string
  color2?: string
  css?: SerializedStyles
  fullWidth?: boolean
  inverted?: boolean
  lineHeight?: number | string
  lines?: number
  spacing?: string
  tempo?: number
  withHeader?: boolean
  withThumb?: boolean
}

export const LoadingPlaceholder = ({
  borderRadius = '0px',
  className,
  color1,
  color2,
  css: styles,
  fullWidth,
  inverted,
  lineHeight: height,
  lines = 2,
  spacing = '0.65rem',
  tempo = 2.5,
  withHeader,
  withThumb,
  ...rest
}: LoadingPlaceholderProps) => {
  let adjustedColor1 = inverted ? 'rgba(255, 255, 255, .08)' : 'rgba(0, 0, 0, .05)'
  let adjustedColor2 = inverted ? 'rgba(255, 255, 255, .14)' : 'rgba(0, 0, 0, .10)'

  if (color1) {
    adjustedColor1 = coloring(color1)
  }

  if (color2) {
    adjustedColor2 = coloring(color2)
  }

  const settings = {
    bg: inverted ? 'rgba(255, 255, 255, .90)' : 'rgba(0, 0, 0, .70)',
    borderRadius,
    color1: adjustedColor1,
    color2: adjustedColor2,
    height,
    spacing,
    tempo,
  }

  const l = new Array(lines).fill(true)

  return (
    <Box
      className={cc([className, reactClass('loading-placeholder')])}
      css={[
        css`
          width: 100%;
          background-color: ${inverted ? '#1b1c1d' : '#ffffff'};
          .${reactClass('loading-placeholder-header')} + .${reactClass('loading-placeholder-line')} {
            margin-top: 1rem;
          }
          .${reactClass('loading-placeholder-header')}:first-of-type,
            .${reactClass('loading-placeholder-line')}:first-of-type,
            .${reactClass('loading-placeholder-thumb')}:first-of-type {
            margin-top: 0;
          }
          .${reactClass('loading-placeholder-line')}:last-of-type {
            margin-bottom: 0;
          }
        `,
        styles,
      ]}
      {...rest}
    >
      {withThumb && (
        <Flex>
          <Thumb {...settings} />
          <Box width="80%">
            <Line {...settings} width={randomPercent(80)} header />
            <Line {...settings} width={randomPercent(60)} header />
          </Box>
        </Flex>
      )}
      {!withThumb && withHeader && <Line {...settings} width={randomPercent(80)} header />}
      {l.map((_, index) => (
        <Line key={index} width={fullWidth ? '100%' : randomPercent(60)} {...settings} />
      ))}
    </Box>
  )
}
