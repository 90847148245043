import { useDay } from '@datepicker-react/hooks'
import cc from 'classcat'
import React, { ReactElement, useMemo, useRef } from 'react'
import { useHoverDirty } from 'react-use'

import { useTheme } from '../../../../hooks/useTheme'
import { Text } from '../../../Text'
import { useDatePickerContext } from '../../contexts/datePickerContext'
import * as Styled from './styles'
import { getDayColor } from './utils/getDayColor'

interface DayProps {
  dayLabel: string
  date: Date
}

export const Day = ({ dayLabel, date }: DayProps): ReactElement | null => {
  const dayRef = useRef<HTMLButtonElement>(null)
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useDatePickerContext()
  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  })
  const theme = useTheme()
  const isHovered = useHoverDirty(dayRef)
  const { textColor, bgColor } = useMemo(
    () =>
      getDayColor({
        isDisabled: disabledDate,
        isSelected,
        isSelectedStartOrEnd,
        isWithinHoverRange,
        isHovered,
        theme,
      }),
    [disabledDate, isHovered, isSelected, isSelectedStartOrEnd, isWithinHoverRange, theme],
  )

  if (!dayLabel) {
    return null
  }

  return (
    <Styled.DayWrapper>
      <Styled.DayButton
        className={cc([
          'ds-date-picker-day-button',
          { selected: isSelected || isSelectedStartOrEnd, disabled: !!disabledDate },
        ])}
        bgColor={bgColor}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        ref={dayRef}
        tabIndex={tabIndex}
        disabled={disabledDate}
        selected={isSelected || isSelectedStartOrEnd}
      >
        <Text alignment="center" color={textColor} variant="micro">
          {/* Show "1" instead of "01": */}
          {parseInt(dayLabel, 10)}
        </Text>
      </Styled.DayButton>
    </Styled.DayWrapper>
  )
}
