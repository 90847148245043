import { amountToDisplayValue, Color, FontSize, FontWeight, pxToNumber } from '@design-system'

import { lighten } from 'polished'
import React, { ReactElement, useCallback } from 'react'
import { Bar, Cell, ComposedChart, LabelList, LabelProps, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import * as Styled from './styles'

const ANIMATION_TIME_MS = 750
const BAR_SIZE = 10
const LABEL_Y_OFFSET = -10
const VALUE_X_MINIMUM_OFFSET = 120
const NAME_LABEL_FONT_SIZE = pxToNumber(FontSize.TextSmall)
const VALUE_LABEL_FONT_SIZE = pxToNumber(FontSize.TextNormal)
const X_AXIS_LABEL_FONT_SIZE = pxToNumber(FontSize.TextMicro)

export interface BarChartData {
  label: string
  color: Color
  value: number
  displayValue?: number | string
  isBarHidden?: boolean
}

export interface BarChartProps {
  data: BarChartData[]
  isLoading?: boolean
  shortValueFormat?: boolean
}

export const BarChart = ({ data, isLoading, shortValueFormat }: BarChartProps): ReactElement => {
  const renderNameLabel = useCallback((props: LabelProps) => {
    const { y, value } = props

    return (
      <g>
        <text
          x={5}
          y={Number(y) + LABEL_Y_OFFSET}
          fill={Color.Gray90}
          fontSize={NAME_LABEL_FONT_SIZE}
          fontWeight={FontWeight.Regular}
          textAnchor="start"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    )
  }, [])

  const renderValueLabel = useCallback(
    (props: LabelProps) => {
      const { x, y, width, value, color } = props

      return (
        <g>
          <text
            x={Math.max(VALUE_X_MINIMUM_OFFSET, Number(x) + Number(width))}
            y={Number(y) + LABEL_Y_OFFSET}
            fill={color}
            fontSize={VALUE_LABEL_FONT_SIZE}
            fontWeight={FontWeight.Medium}
            textAnchor="end"
            dominantBaseline="middle"
          >
            {amountToDisplayValue(Number(value), shortValueFormat)}
          </text>
        </g>
      )
    },
    [shortValueFormat],
  )

  const generateGradient = useCallback(
    (index: number, color: Color) => (
      <linearGradient key={index} id={`gradient-${index}`} x1="0" y1="0" x2="1" y2="0">
        <stop offset="0%" stopColor={lighten(0.3, color)} />
        <stop offset="100%" stopColor={color} />
      </linearGradient>
    ),
    [],
  )

  const axisTickFormatter = (value: number) => amountToDisplayValue(value, shortValueFormat)
  const nameLabelAccessor = (data: BarChartData) => data.label
  const valueLabelAccessor = (data: BarChartData) => data.displayValue || data.value

  return (
    <>
      {isLoading ? (
        <Styled.SkeletonWrapper>
          <Styled.SkeletonChart />
          <Styled.SkeletonXAxis />
        </Styled.SkeletonWrapper>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart layout="vertical" data={data}>
            {/* Gradient helpers: */}
            <defs>{data.map((entry, index) => generateGradient(index, entry.color))}</defs>

            {/* Chart: */}
            <XAxis
              type="number"
              tick={{ fill: Color.Gray90, fontSize: X_AXIS_LABEL_FONT_SIZE }}
              stroke={Color.Gray50}
              strokeWidth={1}
              tickMargin={5}
              tickFormatter={axisTickFormatter}
            />
            <YAxis dataKey="label" type="category" scale="band" hide />
            <Bar dataKey="value" barSize={BAR_SIZE} radius={BAR_SIZE / 2} animationDuration={ANIMATION_TIME_MS}>
              <LabelList content={renderNameLabel} valueAccessor={nameLabelAccessor} />
              <LabelList content={renderValueLabel} valueAccessor={valueLabelAccessor} />

              {data.map((barData, index) => {
                const cellProps = barData.isBarHidden ? { width: 0 } : {}
                return <Cell key={`cell-${index}`} fill={`url(#gradient-${index})`} {...cellProps} />
              })}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </>
  )
}
