import { useQuery } from 'react-query'

import { fetchContactBalance } from '@modules-deprecated/app/contacts/query-api'

import { QueryKeys } from '../../../enums/queryKeys'

export const useFetchContactBalance = (contactId?: string, currencyId?: string | null) => {
  const { data, ...rest } = useQuery(
    [QueryKeys.ContactBalance, contactId, currencyId],
    () => fetchContactBalance(contactId as string, currencyId as string),
    {
      enabled: !!contactId && !!currencyId,
    },
  )

  return {
    contactBalance: data?.balance,
    ...rest,
  }
}
