import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { PeriodValue } from '../../../../types/periodValue'

const PROPS_TO_PICK: Array<keyof PeriodValue> = ['value', 'year', 'mode', 'customDate']

export const isPeriodValueEqual = (periodValue?: PeriodValue, periodValueToCompare?: PeriodValue) => {
  const periodValuePicked = pick(periodValue, PROPS_TO_PICK)
  const periodValueToComparePicked = pick(periodValueToCompare, PROPS_TO_PICK)

  return isEqual(periodValuePicked, periodValueToComparePicked)
}
