import { Link, Switch, Text } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface BenefitsListProps {
  onTermsAcceptedChange: (termsAccepted: boolean) => void
  areTermsAccepted: boolean
}

export const LendingTermsSwitch = ({ areTermsAccepted, onTermsAcceptedChange }: BenefitsListProps): ReactElement => {
  const { t } = useTranslation()

  const handleTermsAcceptedChange = useCallback(() => {
    onTermsAcceptedChange(!areTermsAccepted)
  }, [areTermsAccepted, onTermsAcceptedChange])

  return (
    <Switch controlAlignmentPlacement="center" checked={areTermsAccepted} onChange={handleTermsAcceptedChange}>
      <Text variant="micro" colorVariant="secondary" weight="regular">
        <Trans
          defaults={t('financing.froda_lending.terms')}
          components={[<Link href={t('financing.froda_lending.froda_link')} target="_blank" />]}
        />
      </Text>
    </Switch>
  )
}
