import { Tab, TabsGroup } from '@design-system'

import React, { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { settingsRouteToEmberSettingsRoute } from '../../constants/settingsRouteToEmberSettingsRoute'
import { userSettingsRoutes } from '../../constants/userSettingsRoutes'
import { SettingsRoute } from '../../enums/settingsRoute'
import { useHiddenTabsCount } from '../../hooks/useHiddenTabsCount'
import { routeToInternalSettings } from '../../utils/routeToInternalSettings'
import { useOrganizationSettingsTabItems } from './hooks/useOrganizationSettingsTabItems'
import { useUserSettingsTabItems } from './hooks/useUserSettingsTabItems'

export const SettingsNavigation = () => {
  const location = useLocation()
  const { items: userRoutes } = useUserSettingsTabItems()
  const { items: organizationRoutes } = useOrganizationSettingsTabItems()

  const currentRouteName = location.pathname
  const isUserRoute = useMemo(() => userSettingsRoutes.includes(currentRouteName as SettingsRoute), [currentRouteName])
  const routes = useMemo(
    () => (isUserRoute ? userRoutes : organizationRoutes),
    [isUserRoute, userRoutes, organizationRoutes],
  )
  const count = useHiddenTabsCount(routes.length)

  const handleSettingsTabClick = useCallback(
    (route: SettingsRoute) => () => {
      routeToInternalSettings(settingsRouteToEmberSettingsRoute[route])
    },
    [],
  )

  return (
    <TabsGroup hiddenTabsCount={count}>
      {routes.map(({ badgeCounter, icon, route, childRoutes, text, hidden }) => {
        const doesCurrentRouteContainChildRoute = childRoutes?.some((childRoute) =>
          currentRouteName.includes(childRoute),
        )

        return (
          !hidden && (
            <Tab
              active={currentRouteName === route || (childRoutes && doesCurrentRouteContainChildRoute)}
              badgeCounter={badgeCounter}
              icon={icon}
              key={route}
              to={route}
              onClick={handleSettingsTabClick(route)}
            >
              {text}
            </Tab>
          )
        )
      })}
    </TabsGroup>
  )
}
