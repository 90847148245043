import { SettingsSection } from '@components'
import { Button } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useOrganizationInfoSettings } from '../../contexts/organizationInfoSettingsContext'

export const OrganizationInfoFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { submitForm, isProcessing, isLoading } = useOrganizationInfoSettings()

  const handleSaveButtonClick = useCallback(() => {
    submitForm()
  }, [submitForm])

  return (
    <SettingsSection.Footer>
      <Button onClick={handleSaveButtonClick} disabled={isLoading} loading={isProcessing}>
        {t('settings.organization.organization_info.submit_button')}
      </Button>
    </SettingsSection.Footer>
  )
}
