import { Translate } from '@components'
import { Color, Icon, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { Trans } from 'react-i18next'

import { getImageClassName } from '../../../../../../utils/getClassName'
import { getImgPath } from '../../../../../../utils/getImgPath'
import * as Styled from './styles'
import { featuresList } from './utils/featuresList'

export const SalaryFeaturesList = (): ReactElement => {
  const imagePath = getImgPath(`/salary/billy-salary-integration-promo-billy.svg`)

  return (
    <Styled.SalaryFeaturesListWrapper>
      <Styled.IllustrationWrapper className={getImageClassName()}>
        <Styled.Illustration src={imagePath} alt="Salary" />
      </Styled.IllustrationWrapper>

      <Styled.TitleWrapper>
        <Text variant="h2">
          <Trans i18nKey="salary.signup.features.title">
            Get the full benefits of choosing Salary for
            <Styled.TextStriked as="span" variant="h2" inline>
              19 DKK
            </Styled.TextStriked>{' '}
            <Text as="span" variant="h1" colorVariant="success" inline>
              10 DKK today
            </Text>
          </Trans>
        </Text>
        <Text>
          <Translate value="salary.signup.features.period_info" />
        </Text>
      </Styled.TitleWrapper>

      <Styled.FeaturesList>
        {featuresList.map(({ tKey }) => (
          <Styled.FeatureItem key={tKey}>
            <Styled.IconWrapper>
              <Icon icon="checkCircleSolid" color={Color.Green} />
            </Styled.IconWrapper>
            <Text>
              <Trans i18nKey={tKey}>
                <strong>bolded text</strong> other text
              </Trans>
            </Text>
          </Styled.FeatureItem>
        ))}
      </Styled.FeaturesList>
    </Styled.SalaryFeaturesListWrapper>
  )
}
