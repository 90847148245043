import { useUserOrganization } from '@modules-deprecated/app/organization'

export const useOrganizationSource = () => {
  const { organization } = useUserOrganization()

  const isSourceBilly = organization?.source === 'billy'
  const isSourceZervant = organization?.source === 'zervant'

  return { isSourceBilly, isSourceZervant }
}
