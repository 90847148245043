import { SettingsLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { BetaCandidate } from './elements/betaCandidate'
import { OrganizationFeaturesSelection } from './elements/organizationFeaturesSelection'

export const OrganizationFeaturesSettings = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <SettingsLayout title={t('settings.organization.features')}>
      <SettingsLayout.Column>
        <BetaCandidate />
      </SettingsLayout.Column>
      <SettingsLayout.Column>
        <OrganizationFeaturesSelection />
      </SettingsLayout.Column>
    </SettingsLayout>
  )
}
