import { Spacing } from '@design-system'

import styled from '@emotion/styled'

import { ReactComponent as GetStartedIllustration } from '../../../../assets/dashboard-get-started.svg'
import { ReactComponent as NowYouAreReadyIllustration } from '../../../../assets/dashboard-now-you-are-ready.svg'

export const WidgetContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Header = styled.div``

export const Footer = styled.div``

export const DescriptionWrapper = styled.div`
  width: 60%;
`

export const PostChecklistDescriptionWrapper = styled.div`
  width: 75%;
`

export const Illustration = styled(GetStartedIllustration)`
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
`

export const PostChecklistIllustration = styled(NowYouAreReadyIllustration)`
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
`

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: ${Spacing.S};
  right: ${Spacing.S};
`
