import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { Variant } from '../types'

const getDisabledStyleForVariant = (variant: Variant): SerializedStyles =>
  ({
    primary: css`
      &:disabled {
        background-color: ${Color.Gray20};
        color: ${Color.Gray50};
        box-shadow: none;
      }
    `,
    secondary: css`
      &:disabled {
        background-color: ${Color.Gray20};
        color: ${Color.Gray50};
        box-shadow: none;
      }
    `,
    text: css`
      &:disabled {
        background-color: ${Color.Gray20};
        color: ${Color.Gray50};
        box-shadow: none;
      }
    `,
  })[variant]

export const getDisabledStyle = (variant: Variant): SerializedStyles => css`
  ${getDisabledStyleForVariant(variant)};

  &:disabled {
    cursor: not-allowed;
  }
`
