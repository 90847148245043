import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'

export const useInvalidateBills = () => {
  const queryClient = useQueryClient()

  const invalidateBills = useCallback(async () => {
    await queryClient.invalidateQueries(QueryKeys.Bills)
    await queryClient.invalidateQueries(QueryKeys.BillsSummary)
    await queryClient.invalidateQueries(QueryKeys.Creditors)
  }, [queryClient])

  return {
    invalidateBills,
  }
}
