import { AnyAction } from 'redux'

import { TAX_RATES_RECEIVED, TAX_RATES_REQUESTED } from './actions'
import { TaxRate } from './types'

export type TaxRatesRequestedProps = {
  organizationId: string
  page: { offset: number }
  filters: {
    purchase?: boolean
    sale?: boolean
    text: string | null
  }
}

export type TaxRatesRequested =
  | AnyAction
  | {
      payload: {}
      type: typeof TAX_RATES_REQUESTED
    }

export type TaxRatesReceived =
  | AnyAction
  | {
      payload: TaxRate[]
      type: typeof TAX_RATES_RECEIVED
    }

export function taxRatesRequested({ organizationId, page, filters }: TaxRatesRequestedProps) {
  return {
    payload: { organizationId, page, filters },
    type: TAX_RATES_REQUESTED,
  }
}

export function taxRatesReceived(taxRates: TaxRate[]): TaxRatesReceived {
  return {
    payload: taxRates,
    type: TAX_RATES_RECEIVED,
  }
}
