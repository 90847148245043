import React, { ReactElement, ReactNode } from 'react'

import { Badge, BadgeVariant, badgeVariantStyles } from '../Badge'
import { Space } from '../Space'
import { Text } from '../Text'
import * as Styled from './styles'

export interface BadgeWithLabelProps {
  variant?: BadgeVariant
  children?: ReactNode
  badge?: ReactNode
}

export const BadgeWithLabel = ({ variant = 'primary', children, badge }: BadgeWithLabelProps): ReactElement => {
  return (
    <Styled.BadgeWithLabelWrapper>
      {/* Could be number value as badge is often used for presenting those */}
      {badge !== undefined && <Badge variant={variant}>{badge}</Badge>}
      {children && (
        <>
          {badge !== undefined && <Space horizontal size="xs" />}
          <Text variant="small" color={badgeVariantStyles[variant].textColor} weight="medium">
            {children}
          </Text>
        </>
      )}
    </Styled.BadgeWithLabelWrapper>
  )
}
