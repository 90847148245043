import colors from '../colors'

export default {
  menu: {
    background: colors.menuBackground,
  },
  menuItemsContainer: {
    '::-webkit-scrollbar-thumb': {
      backgroundColor: colors.primaryDarkend,
    },
  },
  menuItemContainer: {
    color: colors.white,
    '&:hover .menuItemTitle': {
      backgroundColor: colors.menuActive,
    },
  },
  menuDivider: {
    background: colors.primaryDarkend,
  },
}
