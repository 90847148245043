import { amountToDisplayValue } from '@design-system'

import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import React, { ReactElement } from 'react'

import { Theme } from '../../../types/theme'

type AmountValueProps = {
  textColor: string
}

const AmountValue = styled.span<AmountValueProps>`
  color: ${({ textColor }) => textColor};
`

type ReconciliationAmountProps = {
  amount: number
}

export const ReconciliationAmount = ({ amount }: ReconciliationAmountProps): ReactElement => {
  const theme = useTheme<Theme>()
  const textColor = amount < 0 ? theme.colors.warning : theme.colors.success

  return <AmountValue textColor={textColor}>{amountToDisplayValue(amount)}</AmountValue>
}
