import {
  RowWithCheckboxSpacingLeft,
  SubRowSpacing,
  TABLE_ROW_HORIZONTAL_SPACING,
  TABLE_ROW_VERTICAL_SPACING,
} from '../enums/styles'

const STATIC_ACTIONS_OFFSET = '52px'

interface GetRowPaddingProps {
  ignoreSpacing?: boolean
  selectable?: boolean
  expandable?: boolean
  withStaticActionOffset?: boolean
}

export const getRowPadding = (props?: GetRowPaddingProps) => {
  const { ignoreSpacing, selectable, expandable, withStaticActionOffset } = props || {}

  const spacingRight = withStaticActionOffset ? STATIC_ACTIONS_OFFSET : TABLE_ROW_HORIZONTAL_SPACING

  if (ignoreSpacing) {
    return `padding: 0;`
  }

  if (selectable) {
    if (expandable) {
      return `padding: ${TABLE_ROW_VERTICAL_SPACING} ${spacingRight} ${TABLE_ROW_VERTICAL_SPACING} ${
        RowWithCheckboxSpacingLeft + SubRowSpacing
      }px;`
    }

    return `padding: ${TABLE_ROW_VERTICAL_SPACING} ${spacingRight} ${TABLE_ROW_VERTICAL_SPACING} ${RowWithCheckboxSpacingLeft}px;`
  }

  return expandable
    ? `padding: ${TABLE_ROW_VERTICAL_SPACING} ${spacingRight} ${TABLE_ROW_VERTICAL_SPACING} ${SubRowSpacing * 2}px;`
    : `padding: ${TABLE_ROW_VERTICAL_SPACING} ${spacingRight} ${TABLE_ROW_VERTICAL_SPACING} ${TABLE_ROW_HORIZONTAL_SPACING};`
}
