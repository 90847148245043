import { withModalConditionalRender } from '@design-system'

import React, { ReactElement } from 'react'

import { ModalId } from '../../../../enums/modalId'
import { DebtCollectionNoteModal as DebtCollectionNote } from './elements/DebtCollectionNoteModal'
import { DisableDebtCollectionModal as DisableDebtCollection } from './elements/DisableDebtCollectionModal'
import { EnableDebtCollectionModal as EnableDebtCollection } from './elements/EnableDebtCollectionModal'

const EnableDebtCollectionModal = withModalConditionalRender(EnableDebtCollection)
const DisableDebtCollectionModal = withModalConditionalRender(DisableDebtCollection)
const DebtCollectionNoteModal = withModalConditionalRender(DebtCollectionNote)

export const DebtCollectionModalsGroup = (): ReactElement => (
  <>
    <EnableDebtCollectionModal id={ModalId.EnableDebtCollectionModal} />
    <DisableDebtCollectionModal id={ModalId.DisableDebtCollectionModal} />
    <DebtCollectionNoteModal id={ModalId.DebtCollectionNoteModal} />
  </>
)
