import { Note } from '@components'

import { Timeline } from '../../../types/timeline'

export const getNotesFromTimeline = (timeline: Timeline[]): Note[] => {
  return timeline.map((item) => {
    const { number, timestamp, properties } = item

    return {
      id: number.toString(),
      date: timestamp,
      description: properties.comment,
      author: properties.user?.name,
    }
  })
}
