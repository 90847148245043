import { IconCross, Text as TextComponent } from '@components-deprecated'
import { rwd, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { FileUpload as FileUploadComponent } from '../../../../components-deprecated/FileUpload'
import * as FileThumbnailStyled from '../../../../components-deprecated/FileUpload/elements/FileThumbnail/styles'
import * as FileUploadStyled from '../../../../components-deprecated/FileUpload/styles'

export const CloseButton = styled(IconCross)`
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
`

export const Text = styled(TextComponent)`
  margin-right: 20px;

  ${rwd.equalOrLessThan('xlarge')} {
    font-size: 13px;
  }
`

export const EmailLink = styled.a`
  color: inherit;
  white-space: nowrap;
`

export const FileUpload = styled(FileUploadComponent)`
  ${() => FileUploadStyled.FileListScrollableArea} {
    padding: 10px 20px;
  }

  ${() => FileUploadStyled.FileActionsContainer} {
    margin-right: 30px;
  }

  ${() => FileThumbnailStyled.FileThumbnailContainer} {
    margin-left: 20px;
    margin-right: 30px;
  }
`

export const SectionMessageWrapper = styled.div`
  margin-top: ${Spacing.M};
`
