import { Icon } from '@design-system'

import React, { forwardRef, ReactElement, ReactNode } from 'react'
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import * as Styled from './styles'

interface DraggableItemProps extends DraggableProvidedDraggableProps {
  children: ReactNode
  dragHandleProps?: DraggableProvidedDragHandleProps | null
  isDragging: boolean
  isDraggable: boolean
}

export const DraggableItem = forwardRef<HTMLDivElement, DraggableItemProps>(
  ({ children, isDraggable, isDragging, dragHandleProps, ...rest }: DraggableItemProps, forwardedRef): ReactElement => (
    <Styled.DraggableItemWrapper {...rest} dragging={isDragging} ref={forwardedRef}>
      {isDraggable && (
        <Styled.DragZone {...dragHandleProps}>
          <Icon icon="verticalLinesDotted" />
        </Styled.DragZone>
      )}
      {children}
    </Styled.DraggableItemWrapper>
  ),
)
