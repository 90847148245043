import { BorderRadius, Color, getShadow } from '@design-system'

import styled from '@emotion/styled'

export const EmptyStateWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 150px;
  border-radius: ${BorderRadius.L};
  border: 1px solid ${Color.Gray30};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${getShadow('raisedLight')}
`
