import { Button } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../../../../../hooks'
import { useInviteBookkeeperModal } from '../../contexts/inviteBookkeeperModalContext'
import { InviteBookkeeperFormSchema } from '../../utils/formData'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { isSaving, submitForm } = useInviteBookkeeperModal()
  const { control } = useFormContext<InviteBookkeeperFormSchema>()
  const umbrellaId = useWatch({ control, name: 'umbrellaId' })

  const isDisabled = !umbrellaId

  return (
    <Button disabled={isDisabled} loading={isSaving} onClick={submitForm}>
      {t('settings.organization.users.invite_bookkeeper_modal.invite_bookkeeper')}
    </Button>
  )
}
