import { decodePeriodValue, getPeriodButtonLabel } from '@components'

import { TFunction } from 'i18next'

import { bannedFileNameCharacters } from '../constants/bannedFileNameCharacters'

export const getFileName = (organizationName: string, period: string, t: TFunction) => {
  const decodedPeriod = decodePeriodValue(period)
  let fileName = `${organizationName}, ${getPeriodButtonLabel(t, decodedPeriod)}`

  for (const { value, replacedValue } of bannedFileNameCharacters) {
    fileName = fileName.replaceAll(value, replacedValue)
  }

  return fileName
}
