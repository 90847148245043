import { notify } from '@design-system'

import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomEvent } from '../../../../../enums/customEvent'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { useStripePopupInterval } from './useStripePopupInterval'

const isEventSourceWindow = (source: any | null): source is Window => {
  return !!source && (source as Window)?.close !== undefined
}

interface UseStripePopupListenerProps {
  popupWindow: Window | null
  onSuccess: (authorizatinoCode: string) => void
}

interface UseStripePopupListenerReturn {
  startListening: () => void
}

export const useStripePopupListener = ({
  popupWindow,
  onSuccess,
}: UseStripePopupListenerProps): UseStripePopupListenerReturn => {
  const { t } = useTranslation()
  const { startListening, stopListening } = useStripePopupInterval({ popupWindow })

  const handleStripeIntegration = useCallback(
    (result: { type: string; code: string }) => {
      if (result.type === 'success') {
        onSuccess(result.code)
      } else {
        notify({
          id: NotificationKeys.SubscriptionStripe,
          message: t('integrations.card.stripe.notification.failed'),
          variant: 'error',
        })
      }
    },
    [onSuccess, t],
  )

  const openerMessageListener = useCallback(
    (event: MessageEvent) => {
      if (event.data.message === CustomEvent.StripeIntegrationDeliverResult) {
        handleStripeIntegration(event.data.result)
        if (isEventSourceWindow(event?.source)) {
          event?.source?.close()
        }
        stopListening()
      }
    },
    [handleStripeIntegration, stopListening],
  )

  useEffect(() => {
    window.addEventListener('message', openerMessageListener)

    return () => {
      window.removeEventListener('message', openerMessageListener)
    }
  }, [openerMessageListener])

  return {
    startListening,
  }
}
