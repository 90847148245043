import { TFunction } from 'i18next'

import { RecurringInvoiceSpawnMode } from '../../../types/recurringInvoiceSpawnMode'
import { recurringSpawnModeKeyToTranslationKey } from '../constants/recurringSpawnModeKeyToTranslationKey'

export const getRecurringSpawnMode = (recurringSpawnMode: RecurringInvoiceSpawnMode, t: TFunction): string => {
  const tKey = recurringSpawnModeKeyToTranslationKey[recurringSpawnMode]

  return t(tKey)
}
