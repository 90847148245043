import {
  Checkbox as CheckboxComponent,
  Color,
  SkeletonText as SkeletonTextComponent,
  Spacing,
  Text as TextComponent,
} from '@design-system'

import styled from '@emotion/styled'

interface TaskItemProps {
  greyedOut?: boolean
  crossedOff?: boolean
}

interface CheckboxContainerProps {
  disabled?: boolean
}

export const SideElementsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SkeletonContainer = styled.ul`
  margin-top: ${Spacing.M};
  flex: 1;
  overflow: auto;
`

export const Text = styled(TextComponent)`
  margin-left: 28px;
`

export const TaskItem = styled.li<TaskItemProps>`
  margin-bottom: ${Spacing.S};
  padding-bottom: ${Spacing.S};
  border-bottom: 1px solid ${Color.Gray40};

  &:last-of-type {
    border-bottom: none;
  }

  ${Text} {
    text-decoration: ${({ crossedOff }) => (crossedOff ? 'line-through' : 'none')};
    color: ${Color.Gray90};
  }

  input[type='text'] {
    text-decoration: ${({ crossedOff }) => (crossedOff ? 'line-through' : 'none')};
    color: ${({ greyedOut }) => greyedOut && Color.Gray90};
  }
`

export const SkeletonText = styled(SkeletonTextComponent)`
  margin-bottom: ${Spacing.XL};
`

export const Footer = styled.footer`
  margin-top: ${Spacing.S};
  margin-bottom: -${Spacing.S};
`

export const TaskInfoRow = styled.div`
  display: flex;
  position: relative;
`

export const TaskDeadlineRow = styled(TaskInfoRow)`
  margin-top: -${Spacing.S};
`

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  left: ${Spacing.XXS};
  flex-direction: column;
  justify-content: center;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${() => Checkbox} {
    pointer-events: none;
  }
`

export const Checkbox = styled(CheckboxComponent)``

export const InputContainer = styled.div`
  display: flex;
  height: 32px;
  margin-left: ${Spacing.L};
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
`
