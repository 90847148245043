import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import {
  UpdateOrganizationProps,
  useUpdateOrganization,
  useUserOrganization,
} from '@modules-deprecated/app/organization'
import { UpdateUserPayload, useCurrentUser, useUpdateUser } from '@modules-deprecated/app/user'

import { QueryKeys } from '../../../../../../../enums/queryKeys'
import { PredefinedFields } from '../../types/predefinedFields'
import { SignupForm } from '../utils/formData'

const getUpdatedFields = (formValues: SignupForm, predefinedFields: PredefinedFields): SignupForm =>
  reduce(
    formValues,
    (result, value, key) => {
      if (!predefinedFields[key]) {
        return { ...result, [key]: value }
      }

      return result
    },
    {} as SignupForm,
  )

export const useUpdateAccountSettings = () => {
  const queryClient = useQueryClient()
  const { user } = useCurrentUser()
  const { organization } = useUserOrganization()
  const { update: updateUser } = useUpdateUser({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.CurrentUser)
    },
  })
  const { update: updateOrganization } = useUpdateOrganization({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.UserOrganization)
    },
  })

  const update = useCallback(
    (formValues: SignupForm, predefinedFields: PredefinedFields) => {
      const updatedFields = getUpdatedFields(formValues, predefinedFields)

      if (isEmpty(updatedFields) || !user || !organization) {
        return
      }

      const { userName, organizaionStreet, organizationCity, organizationCvr, organizationZipCode, userPhone } =
        updatedFields

      // Update user if needed
      if (userName || userPhone) {
        const updateUserPayload: UpdateUserPayload = {
          id: user.id,
          ...(userName ? { name: userName } : {}),
          ...(userPhone ? { phone: userPhone } : {}),
        }

        updateUser(updateUserPayload)
      }

      // Update organization if needed
      if (organizaionStreet || organizationCity || organizationCvr || organizationZipCode) {
        const updateOrganizationPayload: UpdateOrganizationProps = {
          organizationId: organization.id,
          payload: {
            ...(organizaionStreet ? { street: organizaionStreet } : {}),
            ...(organizationCity ? { city: organizationCity } : {}),
            ...(organizationCvr ? { registrationNo: organizationCvr } : {}),
            ...(organizationZipCode ? { zipcode: organizationZipCode } : {}),
          },
        }

        updateOrganization(updateOrganizationPayload)
      }
    },
    [organization, updateOrganization, updateUser, user],
  )

  return {
    update,
  }
}
