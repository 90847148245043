import { NavItem } from '@design-system'

import { SalesTaxPeriods } from '../types/salesTaxPeriods'

export const getSalesTaxPeriodItems = (salesTaxPeriods: SalesTaxPeriods): NavItem<string>[] => {
  const items: NavItem<string>[] = []

  for (const [salesTaxPeriod, salesTaxPeriodTranslation] of Object.entries(salesTaxPeriods)) {
    items.push({
      id: salesTaxPeriod,
      children: salesTaxPeriodTranslation,
      value: salesTaxPeriod,
    })
  }

  return items
}
