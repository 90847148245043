import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../enums/queryKeys'
import { getBillReconciliationMatches } from '../query-api'

export const useBillReconciliationSuggestions = (billId?: string) => {
  const { organization } = useUserOrganization()

  const { data, isLoading } = useQuery(
    [QueryKeys.BillRecociliationMatches, billId],
    () =>
      getBillReconciliationMatches({
        organizationId: organization?.id as string,
        billId: billId as string,
      }),
    {
      enabled: !!organization?.id && !!billId,
    },
  )

  const filteredSuggestions = useMemo(
    () =>
      data?.suggestions?.filter((suggestion) => {
        const transaction = suggestion.transactions[0]
        const reconcilable = suggestion.reconcilables[0]
        // filter out suggestions that has reconcilable with different currency than the transaction
        return reconcilable.currency === transaction.currency
      }),
    [data],
  )

  return { suggestions: filteredSuggestions, isLoading }
}
