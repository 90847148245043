import { AsideInfo } from '@components'

import { useTranslation } from 'react-i18next'

import { useDisplaySidebar } from '../../hooks/useDisplaySidebar'
import { InvoiceCustomerLink } from './elements/InvoiceCustomerLink'
import { useDueStatusTitle } from './elements/InvoiceTransactionLink/hooks/useDueStatusTitle'
import { InvoiceTransactionsList } from './elements/InvoiceTransactionsList/InvoiceTransactionsList'
import { useExternalInvoiceTransactions } from './hooks/useExternalInvoiceTransactions'
import * as Styled from './styles'

export const InvoiceViewSidebar = () => {
  const { t } = useTranslation()
  const { title } = useDueStatusTitle()
  const { shouldDisplayCustomerLink, shouldDisplaySidebar, shouldDisplayTransactionLink } = useDisplaySidebar()
  const { isLoading: isLoadingTransactionLink } = useExternalInvoiceTransactions()

  if (!shouldDisplaySidebar) {
    return null
  }

  return (
    <Styled.InvoiceViewSidebarWrapper>
      <AsideInfo title={title}>
        {shouldDisplayCustomerLink && (
          <AsideInfo.Item title={t('external_invoices.view.info.customer')}>
            <InvoiceCustomerLink />
          </AsideInfo.Item>
        )}
        {shouldDisplayTransactionLink && !isLoadingTransactionLink && (
          <AsideInfo.Item title={t('external_invoices.view.info.transaction')}>
            <InvoiceTransactionsList />
          </AsideInfo.Item>
        )}
      </AsideInfo>
    </Styled.InvoiceViewSidebarWrapper>
  )
}
