import { ReactComponent as IconAIStars } from '../../../assets/icons/icon-ai-stars.svg'
import { ReactComponent as IconArrowOutOfSquare } from '../../../assets/icons/icon-arrow-out-of-square.svg'
import { ReactComponent as IconArrowPointingDown } from '../../../assets/icons/icon-arrow-pointing-down.svg'
import { ReactComponent as IconArrowReturn } from '../../../assets/icons/icon-arrow-return.svg'
import { ReactComponent as IconArrowRight } from '../../../assets/icons/icon-arrow-right.svg'
import { ReactComponent as IconArrowUpInsideCloud } from '../../../assets/icons/icon-arrow-up-inside-cloud.svg'
import { ReactComponent as IconArrowsInverted } from '../../../assets/icons/icon-arrows-inverted.svg'
import { ReactComponent as IconBank } from '../../../assets/icons/icon-bank.svg'
import { ReactComponent as IconBarChartEqual } from '../../../assets/icons/icon-bar-chart-equal.svg'
import { ReactComponent as IconBarChart } from '../../../assets/icons/icon-bar-chart.svg'
import { ReactComponent as IconBell } from '../../../assets/icons/icon-bell.svg'
import { ReactComponent as IconBill } from '../../../assets/icons/icon-bill.svg'
import { ReactComponent as IconBills } from '../../../assets/icons/icon-bills.svg'
import { ReactComponent as IconBubbleChart } from '../../../assets/icons/icon-bubble-chart.svg'
import { ReactComponent as IconBulb } from '../../../assets/icons/icon-bulb.svg'
import { ReactComponent as IconBurger } from '../../../assets/icons/icon-burger.svg'
import { ReactComponent as IconCalculator } from '../../../assets/icons/icon-calculator.svg'
import { ReactComponent as IconCalendar } from '../../../assets/icons/icon-calendar.svg'
import { ReactComponent as IconCaretDown } from '../../../assets/icons/icon-caret-down.svg'
import { ReactComponent as IconCaretUp } from '../../../assets/icons/icon-caret-up.svg'
import { ReactComponent as IconChainDisconnected } from '../../../assets/icons/icon-chain-disconnected.svg'
import { ReactComponent as IconCheckCircleSolid } from '../../../assets/icons/icon-check-circle-solid.svg'
import { ReactComponent as IconCheckCircle } from '../../../assets/icons/icon-check-circle.svg'
import { ReactComponent as IconCheckStar } from '../../../assets/icons/icon-check-star.svg'
import { ReactComponent as IconCheck } from '../../../assets/icons/icon-check.svg'
import { ReactComponent as IconChevronDown } from '../../../assets/icons/icon-chevron-down.svg'
import { ReactComponent as IconChevronLeft } from '../../../assets/icons/icon-chevron-left.svg'
import { ReactComponent as IconChevronRight } from '../../../assets/icons/icon-chevron-right.svg'
import { ReactComponent as IconChevronUp } from '../../../assets/icons/icon-chevron-up.svg'
import { ReactComponent as IconCircleDashed } from '../../../assets/icons/icon-circle-dashed.svg'
import { ReactComponent as IconCog } from '../../../assets/icons/icon-cog.svg'
import { ReactComponent as IconCreditCard } from '../../../assets/icons/icon-credit-card.svg'
import { ReactComponent as IconCube } from '../../../assets/icons/icon-cube.svg'
import { ReactComponent as IconDashboard } from '../../../assets/icons/icon-dashboard.svg'
import { ReactComponent as IconDocumentChecked } from '../../../assets/icons/icon-document-checked.svg'
import { ReactComponent as IconDocumentWithDollarSign } from '../../../assets/icons/icon-document-with-dollar-sign.svg'
import { ReactComponent as IconDocumentWithPencil } from '../../../assets/icons/icon-document-with-pencil.svg'
import { ReactComponent as IconDocumentWithXSign } from '../../../assets/icons/icon-document-with-x-sign.svg'
import { ReactComponent as IconDollarCircle } from '../../../assets/icons/icon-dollar-circle.svg'
import { ReactComponent as IconDollarWithArrows } from '../../../assets/icons/icon-dollar-with-arrows.svg'
import { ReactComponent as IconDonutChart } from '../../../assets/icons/icon-donut-chart.svg'
import { ReactComponent as IconEnvelopeWithArrow } from '../../../assets/icons/icon-envelope-with-arrow.svg'
import { ReactComponent as IconEnvelopeWithCheckmark } from '../../../assets/icons/icon-envelope-with-checkmark.svg'
import { ReactComponent as IconEnvelope } from '../../../assets/icons/icon-envelope.svg'
import { ReactComponent as IconExclamationMarkCircle } from '../../../assets/icons/icon-exclamation-mark-circle.svg'
import { ReactComponent as IconEyeCrossed } from '../../../assets/icons/icon-eye-crossed.svg'
import { ReactComponent as IconEye } from '../../../assets/icons/icon-eye.svg'
import { ReactComponent as IconFunnel } from '../../../assets/icons/icon-funnel.svg'
import { ReactComponent as IconISignCircle } from '../../../assets/icons/icon-i-sign-circle.svg'
import { ReactComponent as IconInbox } from '../../../assets/icons/icon-inbox.svg'
import { ReactComponent as IconInvoiceWithPencil } from '../../../assets/icons/icon-invoice-with-pencil.svg'
import { ReactComponent as IconInvoice } from '../../../assets/icons/icon-invoice.svg'
import { ReactComponent as IconLock } from '../../../assets/icons/icon-lock.svg'
import { ReactComponent as IconLogout } from '../../../assets/icons/icon-logout.svg'
import { ReactComponent as IconMagnifyingGlassPlus } from '../../../assets/icons/icon-magnifying-glass-plus.svg'
import { ReactComponent as IconMagnifyingGlass } from '../../../assets/icons/icon-magnifying-glass.svg'
import { ReactComponent as IconMoneyBag } from '../../../assets/icons/icon-money-bag.svg'
import { ReactComponent as IconNotepadChart } from '../../../assets/icons/icon-notepad-chart.svg'
import { ReactComponent as IconPaperWithPencil } from '../../../assets/icons/icon-paper-with-pencil.svg'
import { ReactComponent as IconPaperclip } from '../../../assets/icons/icon-paperclip.svg'
import { ReactComponent as IconPapersPile } from '../../../assets/icons/icon-papers-pile.svg'
import { ReactComponent as IconPeople } from '../../../assets/icons/icon-people.svg'
import { ReactComponent as IconPhone } from '../../../assets/icons/icon-phone.svg'
import { ReactComponent as IconPicture } from '../../../assets/icons/icon-picture.svg'
import { ReactComponent as IconPlay } from '../../../assets/icons/icon-play.svg'
import { ReactComponent as IconPlug } from '../../../assets/icons/icon-plug.svg'
import { ReactComponent as IconPlusCircle } from '../../../assets/icons/icon-plus-circle.svg'
import { ReactComponent as IconQuestionCircle } from '../../../assets/icons/icon-question-circle.svg'
import { ReactComponent as IconRightArrowAroundSquare } from '../../../assets/icons/icon-right-arrow-around-square.svg'
import { ReactComponent as IconRoundedArrows } from '../../../assets/icons/icon-rounded-arrows.svg'
import { ReactComponent as IconSchedule } from '../../../assets/icons/icon-schedule.svg'
import { ReactComponent as IconSettings } from '../../../assets/icons/icon-settings.svg'
import { ReactComponent as IconShield } from '../../../assets/icons/icon-shield.svg'
import { ReactComponent as IconSquaresRegular } from '../../../assets/icons/icon-squares-regular.svg'
import { ReactComponent as IconSquaresSmall } from '../../../assets/icons/icon-squares-small.svg'
import { ReactComponent as IconSquaresWithArrow } from '../../../assets/icons/icon-squares-with-arrow.svg'
import { ReactComponent as IconSuitcase } from '../../../assets/icons/icon-suitcase.svg'
import { ReactComponent as IconThreeDots } from '../../../assets/icons/icon-three-dots.svg'
import { ReactComponent as IconTrash } from '../../../assets/icons/icon-trash.svg'
import { ReactComponent as IconTreeChart } from '../../../assets/icons/icon-tree-chart.svg'
import { ReactComponent as IconTwoSquares } from '../../../assets/icons/icon-two-squares.svg'
import { ReactComponent as IconUserLock } from '../../../assets/icons/icon-user-lock.svg'
import { ReactComponent as IconUserWithTie } from '../../../assets/icons/icon-user-with-tie.svg'
import { ReactComponent as IconUser } from '../../../assets/icons/icon-user.svg'
import { ReactComponent as IconUsers } from '../../../assets/icons/icon-users.svg'
import { ReactComponent as IconVerticalLinesDotted } from '../../../assets/icons/icon-vertical-lines-dotted.svg'
import { ReactComponent as IconWallet } from '../../../assets/icons/icon-wallet.svg'
import { ReactComponent as IconXSignCircleSolid } from '../../../assets/icons/icon-x-sign-circle-solid.svg'
import { ReactComponent as IconXSignCircle } from '../../../assets/icons/icon-x-sign-circle.svg'
import { ReactComponent as IconXSign } from '../../../assets/icons/icon-x-sign.svg'

export const iconToComponent = {
  aiStars: IconAIStars,
  arrowOutOfSquare: IconArrowOutOfSquare,
  arrowPointingDown: IconArrowPointingDown,
  arrowReturn: IconArrowReturn,
  arrowRight: IconArrowRight,
  arrowsInverted: IconArrowsInverted,
  arrowUpInsideCloud: IconArrowUpInsideCloud,
  bank: IconBank,
  barChart: IconBarChart,
  barChartEqual: IconBarChartEqual,
  bell: IconBell,
  bill: IconBill,
  bills: IconBills,
  bubbleChart: IconBubbleChart,
  bulb: IconBulb,
  burger: IconBurger,
  calculator: IconCalculator,
  calendar: IconCalendar,
  caretDown: IconCaretDown,
  caretUp: IconCaretUp,
  chainDisconnected: IconChainDisconnected,
  check: IconCheck,
  checkCircle: IconCheckCircle,
  checkCircleSolid: IconCheckCircleSolid,
  checkStar: IconCheckStar,
  chevronDown: IconChevronDown,
  chevronLeft: IconChevronLeft,
  chevronRight: IconChevronRight,
  chevronUp: IconChevronUp,
  circleDashed: IconCircleDashed,
  cog: IconCog,
  creditCard: IconCreditCard,
  cube: IconCube,
  dashboard: IconDashboard,
  documentChecked: IconDocumentChecked,
  documentWithDollarSign: IconDocumentWithDollarSign,
  documentWithPencil: IconDocumentWithPencil,
  documentWithXSign: IconDocumentWithXSign,
  dollarCircle: IconDollarCircle,
  dollarWithArrows: IconDollarWithArrows,
  donutChart: IconDonutChart,
  envelope: IconEnvelope,
  envelopeWithArrow: IconEnvelopeWithArrow,
  envelopeWithCheckmark: IconEnvelopeWithCheckmark,
  exclamationMarkCircle: IconExclamationMarkCircle,
  eye: IconEye,
  eyeCrossed: IconEyeCrossed,
  funnel: IconFunnel,
  inbox: IconInbox,
  invoice: IconInvoice,
  invoiceWithPencil: IconInvoiceWithPencil,
  iSignCircle: IconISignCircle,
  lock: IconLock,
  logout: IconLogout,
  magnifyingGlass: IconMagnifyingGlass,
  magnifyingGlassPlus: IconMagnifyingGlassPlus,
  moneyBag: IconMoneyBag,
  notepadChart: IconNotepadChart,
  paperclip: IconPaperclip,
  papersPile: IconPapersPile,
  paperWithPencil: IconPaperWithPencil,
  people: IconPeople,
  phone: IconPhone,
  picture: IconPicture,
  play: IconPlay,
  plug: IconPlug,
  plusCircle: IconPlusCircle,
  questionCircle: IconQuestionCircle,
  rightArrowAroundSquare: IconRightArrowAroundSquare,
  roundedArrows: IconRoundedArrows,
  schedule: IconSchedule,
  settings: IconSettings,
  shield: IconShield,
  squaresRegular: IconSquaresRegular,
  squaresSmall: IconSquaresSmall,
  squaresWithArrow: IconSquaresWithArrow,
  suitcase: IconSuitcase,
  threeDots: IconThreeDots,
  trash: IconTrash,
  treeChart: IconTreeChart,
  twoSquares: IconTwoSquares,
  user: IconUser,
  userLock: IconUserLock,
  users: IconUsers,
  userWithTie: IconUserWithTie,
  verticalLinesDotted: IconVerticalLinesDotted,
  wallet: IconWallet,
  xSign: IconXSign,
  xSignCircle: IconXSignCircle,
  xSignCircleSolid: IconXSignCircleSolid,
}
