import { BalanceModifier } from '../types'

const getPaymentIdFromReference = (ref: string) => ref.split('bankPayment:')[1]

const getBillIdFromReference = (ref: string) => ref.split('bill:')[1]

export const getPaymentIdFromBalance = (balanceModifiers: BalanceModifier[], billId: string): string | undefined => {
  const balanceModifier = balanceModifiers.find(
    ({ subjectReference }) => getBillIdFromReference(subjectReference || '') === billId,
  )

  if (!balanceModifier?.modifierReference) {
    return
  }

  return getPaymentIdFromReference(balanceModifier.modifierReference)
}
