export enum QueryParamKeys {
  BankReconciliation = 'bank-reconciliation',
  Contacts = 'contacts',
  EuVatSales = 'eu-vat-sales',
  MatchList = 'match-list',
  MissingInformationsTable = 'mit',
  MissingReceiptsTable = 'mrt',
  ReceiptsList = 'receipts-list',
  TransactionList = 'transaction-list',
  VoucherList = 'vouchers-list',
}
