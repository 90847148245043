import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../types/themable'

type CreditCardWrapperProps = Themable & {
  isInvalid: boolean
  horizontal?: boolean
}

export const CreditCardWrapper = styled.div<CreditCardWrapperProps>`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  justify-content: ${({ horizontal }) => (horizontal ? 'flex-start' : 'space-between')};
  align-items: ${({ horizontal }) => (horizontal ? 'center' : 'flex-start')};
  width: 100%;
  height: ${({ horizontal }) => (horizontal ? '56px' : '148px')};
  padding: ${({ horizontal }) => (horizontal ? Spacing.S : Spacing.XL)};
  margin: ${({ horizontal }) => (horizontal ? 0 : Spacing.XL)} 0;
  background-color: ${Color.White};
  border: ${({ theme }) => `1px solid ${Color.Gray30}`};
  box-sizing: border-box;
  border-radius: ${DefaultValue.BorderRadius};
  opacity: ${({ isInvalid }) => (isInvalid ? 0.3 : 1)};
  box-shadow: ${`0 2px 1px ${Color.Gray30}`};
`

interface IssuerLogoWrapperProps {
  horizontal?: boolean
}

export const IssuerLogoWrapper = styled.div<IssuerLogoWrapperProps>`
  display: flex;
  ${({ horizontal }) => horizontal && `margin-right: ${Spacing.L};`}
`
