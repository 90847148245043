export const transitions = {
  // duration
  fast: '.25s',
  normal: '.3s',
  slow: '.5s',
  slower: '0.7s',
  slowest: '1s',
  // timing
  ease: 'cubic-bezier(.25, .1, .25, 1)',
  easeIn: 'cubic-bezier(.42, 0, 1, 1)',
  easeInOut: 'cubic-bezier(.42, 0, .58, 1)',
  easeOut: 'cubic-bezier(0, 0, .58, 1)',
  linear: 'cubic-bezier(0, 0, 1, 1)',
  end: 'cubic-bezier(1, 0, 1, 0)',
  start: 'cubic-bezier(0, 1, 0, 1)',
}
