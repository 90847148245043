import { EditorState } from 'draft-js'
import { createContext, ReactElement, ReactNode, useContext } from 'react'

import { RichTextStyleSettings } from '../types/richTextStyleSettings'

type RichTextContextType = {
  disabled?: boolean
  editorState: EditorState
  setEditorState: (value: EditorState) => void
  styleSettings?: RichTextStyleSettings
}

const RichTextContext = createContext<RichTextContextType | undefined>(undefined)

interface RichTextContextProps {
  children: ReactNode
  value: RichTextContextType
}

export const RichTextContextProvider = ({ children, value }: RichTextContextProps): ReactElement => (
  <RichTextContext.Provider value={value}>{children}</RichTextContext.Provider>
)

export const useRichTextContext = () => {
  const context = useContext(RichTextContext)

  if (!context) {
    throw new Error('RichTextContextProvider is missing in the module!')
  }

  return context
}
