import { Color } from '@design-system'

import { Status } from '../../../types/status'

export const statusToDotColor: Record<Status, Color> = {
  error: Color.Red,
  neutral: Color.Gray60,
  primary: Color.Blue,
  success: Color.Green,
  warning: Color.Yellow,
}
