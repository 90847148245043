import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SkeletonWrapper = styled.div`
  border: 1px solid ${Color.Gray40};
  border-radius: ${DefaultValue.BorderRadius};
  margin-bottom: ${Spacing.XXL};
`
export const SkeletonRow = styled.div`
  display: flex;
  padding: ${Spacing.L} ${Spacing.XXS};
  border-bottom: 1px solid ${Color.Gray40};
`

export const SkeletonCheckboxCell = styled.div`
  width: 72px;
  padding-left: ${Spacing.XL};
`
