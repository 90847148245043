import {
  Form,
  FormsProps,
  FormSubmittedData,
  IconProcessCircle,
  Input,
  PopOverFooter,
  Reset,
  Submit,
} from '@components-deprecated'

import { css, keyframes } from '@emotion/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'rebass'

import { Contact } from '@views/contacts/types/contact'

import { SpecificState } from '../../../types/reduxSaga-deprecated'
import { getRequest } from '../../../utils'
import { AccountSelector, ContactSelector, TaxRateSelector } from '../../app'
import { Account, AccountNature } from '../../app/accounts/types'
import { Organization } from '../../app/organization/types/organization'
import { createBillAndReconcile } from '../action-creators'
import { ExtendedBankLineMatch } from '../types'

const spin = keyframes`
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

export type CreateBillFormProps = FormsProps & {
  bankLineMatch: ExtendedBankLineMatch
  onClose?: () => void
}

export const CreateBillForm = ({ bankLineMatch, onClose, ...rest }: CreateBillFormProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [preselectedTaxRateId, setPreselectedTaxRateId] = useState('')
  const [preselectedAccountId, setPreselectedAccountId] = useState('')
  const [voucherNo, setVoucherNo] = useState(1)
  const organization = useSelector((state: SpecificState) => state.app.organization) as Organization
  const description = bankLineMatch?.bankLines.map((line) => line.description).join(', ')

  const [defaultValues, setDefaultValues] = useState({ voucherNo, description })

  useEffect(() => {
    getRequest(`/v2/organizations/${organization.id}/nextVoucherNo`)
      .then((res) => setVoucherNo(res.nextVoucherNo))
      .catch(() => {
        /* ignore */
      })
      .finally(() => setIsLoading(false))
  }, [organization.id])

  const handleSubmit = (formData: FormSubmittedData) => {
    const { _account, _contact, description, _taxRate, voucherNo } = formData
    dispatch(
      createBillAndReconcile({
        accountId: _account.id,
        bankLineMatch,
        contactId: _contact.id,
        currencyId: _account.currencyId,
        description,
        organizationId: organization.id,
        taxRateId: _taxRate.id || preselectedTaxRateId,
        voucherNo,
      }),
    )
    if (onClose) {
      setTimeout(() => onClose(), 100)
    }
  }

  useEffect(() => {
    setDefaultValues({ voucherNo, description })
  }, [voucherNo, description])

  const handleSelectContact = (contact: Contact) => {
    const { defaultExpenseAccountId, defaultTaxRateId } = contact
    if (defaultExpenseAccountId) {
      setPreselectedAccountId(defaultExpenseAccountId.toString())
    }

    if (defaultTaxRateId) {
      setPreselectedTaxRateId(defaultTaxRateId.toString())
    }
  }

  const handleSelectAccount = (account: Account) => {
    if (account.id !== preselectedAccountId && account.taxRateId) {
      setPreselectedAccountId(account.id)
      setPreselectedTaxRateId(account.taxRateId)
    }
  }

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <IconProcessCircle
          color="blue3"
          color2="blue4"
          size="14px"
          css={css`
            display: inline-flex;
            animation: ${spin} 2s ease infinite;
            opacity: 0.4;
          `}
        />
      </Flex>
    )
  }

  return (
    <Form defaultValues={defaultValues} onSubmit={handleSubmit} {...rest}>
      <ContactSelector name="contact" isVendor required onItemSelect={handleSelectContact} listWidth="100%" />
      <Input name="voucherNo" placeholder={t('voucher_no')} />
      <Input name="description" placeholder={t('description')} required />
      <AccountSelector
        name="account"
        natures={[AccountNature.Expense, AccountNature.Asset]}
        preselectedId={preselectedAccountId}
        onItemSelect={handleSelectAccount}
        pick="id, currencyId"
        listWidth="100%"
        required
      />
      <TaxRateSelector name="taxRate" preselectedId={preselectedTaxRateId} purchase={true} listWidth="100%" />
      <PopOverFooter>
        <Reset onClick={onClose} size="l">
          {t('cancel')}
        </Reset>
        <Submit>{t('reconcile')}</Submit>
      </PopOverFooter>
    </Form>
  )
}
