import { useTranslation } from 'react-i18next'

import { UmbrellaRoute } from '../../../../../enums/UmbrellaRoute'
import { UmbrellaSettingsTabItem } from '../types/umbrellaSettingsTabItem'
import { UseUmbrellaSettingsTabItems } from '../types/useUmbrellaSettingsTabItems'

export const useUmbrellaUserSettingsTabItems = (): UseUmbrellaSettingsTabItems => {
  const { t } = useTranslation()

  const items: UmbrellaSettingsTabItem[] = [
    {
      icon: 'user',
      route: UmbrellaRoute.SettingsProfile,
      text: t('settings.user.profile'),
    },
    {
      icon: 'bell',
      route: UmbrellaRoute.SettingsNotifications,
      text: t('settings.user.notifications'),
    },
    {
      icon: 'shield',
      route: UmbrellaRoute.SettingsPrivacy,
      text: t('settings.user.privacy'),
    },
  ]

  return { items }
}
