import { isAuthorized } from '../../../utils/isAuthorized'
import { widgetIdToScopes } from '../constants/widgetIdToScopes'
import { WidgetId } from '../enums/widgetId'
import { WidgetSize } from '../enums/widgetSize'
import { Widget } from '../types/widget'

// Get the list of widgets that is available for users
const getAvailableWidgets = (
  widgets: Widget[],
  { isBasicPlan, isFindAccountAvailable }: CleanUpWidgetsOrderOptions = {},
): Widget[] =>
  widgets.filter(({ id }) => {
    if (id === WidgetId.FindAccountant && !isFindAccountAvailable) {
      return false
    }

    if (id === WidgetId.BankIntegration && isBasicPlan) {
      return false
    }

    if (id === WidgetId.Upsell && !isBasicPlan) {
      return false
    }

    const scopes = widgetIdToScopes[id]
    return scopes ? isAuthorized(scopes) : true
  })

// Get widget with provided order.
const getWidgetByOrder = (widgets: Widget[], searchedOrder: number): Widget | undefined =>
  widgets.find(({ order }) => order === searchedOrder)

// If there's upsell widget place it in hardcoded order. In the future, it should be automated based on properties, but right now we don't know what future rules can be.
const getWidgetsWithCustomOrder = (widgets: Widget[], expectedOrder: number) => {
  const newWidgets = [...widgets]
  let oldWidgetId: WidgetId | undefined
  let previousWidgetOrder: number | undefined

  for (const widget of newWidgets) {
    if (widget.id === WidgetId.Upsell) {
      previousWidgetOrder = widget.order
      oldWidgetId = getWidgetByOrder(newWidgets, expectedOrder)?.id
      widget.order = expectedOrder
    }
  }

  for (const widget of newWidgets) {
    if (widget.id === oldWidgetId) {
      widget.order = previousWidgetOrder as number
    }
  }

  return newWidgets
}

// Sort list by `order` property from lowest to highest and including `Upsell` widget as hardcoded order...
const getSortedByOrderWidgets = (widgets: Widget[]) => {
  const newWidgets = [...widgets]

  return newWidgets.sort((widgetLeft, widgetRight) => {
    if (widgetLeft.id === WidgetId.Upsell && widgetLeft.order === widgetRight.order) {
      return -1
    }

    if (widgetRight.id === WidgetId.Upsell && widgetLeft.order === widgetRight.order) {
      return 1
    }

    return widgetLeft.order >= widgetRight.order ? 1 : -1
  })
}

// Unification of `order`.
// It shouldn't be a gap between Widgets eg, first is `order: 0` and next `order: 5`. It's crucial to transform `Widget` type to `Grid.Layout` type correctly
const getWidgetsWithCorrectOrder = (widgets: Widget[]) => {
  const visibleWidgets = getSortedByOrderWidgets(widgets.filter(({ isHidden }) => !isHidden))
  const hiddenWidgets = widgets.filter(({ isHidden }) => isHidden)
  let nextAvailableOrder = 0
  let lastStaticOrder: number | undefined

  return [
    ...hiddenWidgets,
    ...visibleWidgets.reduce((previousWidgets: Widget[], currentWidget: Widget, index: number) => {
      const currentOrder = currentWidget.isStatic ? currentWidget.order : nextAvailableOrder

      const result = [
        ...previousWidgets,
        {
          ...currentWidget,
          order: currentOrder,
        },
      ]

      if (currentWidget.isStatic) {
        lastStaticOrder = currentWidget.order
      }

      if (currentWidget.isStatic && currentWidget.order !== index) {
        nextAvailableOrder = index
      } else {
        nextAvailableOrder += 1

        if (lastStaticOrder === nextAvailableOrder) {
          nextAvailableOrder += 1
        }
      }

      return result
    }, []),
  ]
}

export interface CleanUpWidgetsOrderOptions {
  isFindAccountAvailable?: boolean
  isBasicPlan?: boolean
}

// It combines all actions that are required to get fully unified widgets
export const cleanupWidgetsOrder = (widgets: Widget[], options: CleanUpWidgetsOrderOptions = {}): Widget[] => {
  const filteredWidgets = getAvailableWidgets(widgets, options)

  const firstWidget = getWidgetByOrder(filteredWidgets, 0)
  const isFirstWidgetFullWidth = firstWidget?.size === WidgetSize.FullWidth
  const expectedUpsellWidgetOrder = isFirstWidgetFullWidth ? 2 : 1

  const customOrderWidgets = getWidgetsWithCustomOrder(filteredWidgets, expectedUpsellWidgetOrder)
  const correctOrderWidgets = getWidgetsWithCorrectOrder(customOrderWidgets)

  return getSortedByOrderWidgets(correctOrderWidgets)
}
