import { NavItem, Select, SelectProps } from '@design-system'

import { forwardRef, Ref, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PaymentTermsMode } from '../../../../../../enums/paymentTermsMode'

interface PaymentTermsModeSelectProps
  extends Omit<SelectProps<PaymentTermsMode>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: PaymentTermsMode) => void
}

export const PaymentTermsModeSelect = forwardRef(
  ({ onSelect, ...selectProps }: PaymentTermsModeSelectProps, ref: Ref<HTMLDivElement | null>) => {
    const { t } = useTranslation()

    const items: NavItem<PaymentTermsMode>[] = useMemo(
      () => [
        {
          id: PaymentTermsMode.OnReceipt,
          children: t('settings.organization.invoice.invoice_settings.payment_terms_mode.option.on_receipt'),
          value: PaymentTermsMode.OnReceipt,
        },
        {
          id: PaymentTermsMode.Net,
          children: t('settings.organization.invoice.invoice_settings.payment_terms_mode.option.net'),
          value: PaymentTermsMode.Net,
        },
        {
          id: PaymentTermsMode.NetEndOfMonth,
          children: t('settings.organization.invoice.invoice_settings.payment_terms_mode.option.net_end_of_month'),
          value: PaymentTermsMode.NetEndOfMonth,
        },
      ],
      [t],
    )

    return <Select {...selectProps} items={items} onSelect={onSelect} ref={ref} />
  },
)
