import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { DefaultValue } from '../../../../enums/defaultValue'
import { Spacing } from '../../../../enums/spacing'
import { Asable } from '../../../../types/asable'
import { getDefaultTransition } from '../../../../utils/getDefaultTransition'

type NavListItemViewWrapperProps = Asable

export const NavListItemViewWrapper = styled.li<NavListItemViewWrapperProps>``

interface NavListItemViewProps {
  backgroundColor: Color
  readonly?: boolean
}

export const NavListItemView = styled.div<NavListItemViewProps>`
  display: flex;
  height: 48px;
  padding: ${Spacing.M} ${Spacing.L};
  border-radius: ${DefaultValue.BorderRadius};
  align-items: stretch;
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: ${({ readonly }) => (readonly ? 'default' : 'pointer')};
  ${getDefaultTransition('background-color')};
`
