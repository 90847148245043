import { call, put, takeLatest } from 'redux-saga/effects'

import { getRequest } from '../../../utils'
import { companiesReceived, CompaniesRequestedAction } from './action-creators'
import { COMPANIES_REQUESTED } from './actions'

function* fetchCompaanies({ payload }: CompaniesRequestedAction) {
  if (!payload.filters.text) {
    yield put(
      companiesReceived({
        companies: [],
      }),
    )
    return
  }

  const res: { data: any } = yield call(getRequest, '/cvr/dk/search?q=' + payload.filters.text)

  yield put(
    companiesReceived({
      companies: res.data,
    }),
  )
}

export default function* () {
  yield takeLatest(COMPANIES_REQUESTED, fetchCompaanies)
}
