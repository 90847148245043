import React, { Children, cloneElement, forwardRef, isValidElement, ReactElement, Ref } from 'react'

import { Spacing } from '../../enums/spacing'
import { ComponentsGroup, ComponentsGroupProps } from '../ComponentsGroup'

export type ButtonsGroupProps = Omit<ComponentsGroupProps, 'spacing'> & {
  merged?: boolean
}

export const ButtonsGroup = forwardRef(
  ({ merged, children, ...rest }: ButtonsGroupProps, forwardedRef: Ref<HTMLUListElement>): ReactElement => {
    const spacing = !merged ? Spacing.S : undefined
    const validChildrenCount = Children.toArray(children).filter((child) => isValidElement(child)).length

    return (
      <ComponentsGroup {...rest} spacing={spacing} merged={merged} ref={forwardedRef}>
        {Children.map(
          children,
          (child, index) =>
            isValidElement(child) &&
            cloneElement(child, {
              ...child.props,
              skipLeftRounding: merged && index !== 0,
              skipRightRounding: merged && index !== validChildrenCount - 1,
            }),
        )}
      </ComponentsGroup>
    )
  },
)
