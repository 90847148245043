import { Flex } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { useSideNavbar } from '../../contexts/sideNavbarContext'
import { useSegment, useZervantNavigation } from '../../hooks'
import { getImgPath } from '../../utils/getImgPath'
import { AppButton } from './elements/AppButton'
import * as Styled from './styles'

export const SideNavbar = (): ReactElement | null => {
  const { withSideNavbar } = useSideNavbar()
  const { redirectToZervantLogin } = useZervantNavigation()
  const { track } = useSegment()

  const handleButtonClick = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    track('xxx - supernavigation - clicked (FE)', { button_clicked: 'go to INV' })
    redirectToZervantLogin()
  }, [redirectToZervantLogin, track])

  if (!withSideNavbar) {
    return null
  }

  return (
    <Styled.SideNavbarWrapper>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <AppButton type="zervant" isClickable onClick={handleButtonClick} />
        <AppButton type="ageras" />
      </Flex>
      <Styled.ImageContainer>
        <img src={getImgPath('/ageras-accounting/ageras-logo-gray.svg')} alt="ageras logo" />
      </Styled.ImageContainer>
    </Styled.SideNavbarWrapper>
  )
}
