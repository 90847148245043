import { Color, Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface StepsListItemProps {
  title: ReactNode
  description?: ReactNode
  active?: boolean
}

export const StepsListItem = ({ title, description, active }: StepsListItemProps): ReactElement => {
  return (
    <Styled.StepsListItem active={active}>
      <Text variant="h3" color={active ? Color.DeepSeaGreen : Color.Gray60}>
        {title}
      </Text>
      {description && <Text colorVariant="secondary">{description}</Text>}
    </Styled.StepsListItem>
  )
}
