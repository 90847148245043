import { useEffect } from 'react'

import { CustomEvent } from '../enums/customEvent'

export interface CustomEventType<T = any> extends Event {
  detail?: T
}

export const useCustomEventListener = <T = any>(
  eventName: CustomEvent,
  callback: (event: CustomEventType<T>) => void,
) => {
  useEffect(() => {
    window.addEventListener(eventName, callback)

    return () => {
      window.removeEventListener(eventName, callback)
    }
  }, [eventName, callback])
}
