import { UpgradeSubscriptionModal } from '@components'
import { notify, useModal } from '@design-system'

import useLocalStorage from '@rehooks/local-storage'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { useCurrentUser } from '@modules-deprecated/app/user'
import { freePlans } from '@views/settings/routes/OrganizationSubscriptionSettings/plansData'

import { LocalStorageKeys } from '../../../../../enums/localStorageKeys'
import { ModalId } from '../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { TrackingContext } from '../../../../../enums/trackingContext'
import { FormComponent, FormControl, useForm, useOrganizationSubscription } from '../../../../../hooks'
import { useContactPersons } from '../../../../../hooks/useContactPersons'
import { useEmailInvoice } from '../../../hooks/useEmailInvoice'
import {
  EmailInvoiceForm,
  getEmailInvoiceFormDefaultValues,
  GetEmailInvoiceFormDefaultValuesProps,
  getEmailInvoiceValidationSchema,
  SendEmailInvoiceFormSchema,
} from '../utils/emailInvoiceFormData'
import { useSendInvoiceModal } from './sendInvoiceModalContext'

interface ContextState {
  formControl: FormControl<SendEmailInvoiceFormSchema>
  Form: FormComponent
  isLoading: boolean
  isProcessing: boolean
  submitForm: () => void
}

const EmailContext = createContext<ContextState | undefined>(undefined)

interface EmailContextProps {
  contactId: string
  children: ReactNode
}

export const EmailContextProvider = ({ contactId, children }: EmailContextProps) => {
  const { t } = useTranslation()
  const { user } = useCurrentUser()
  const { subscriptionPlan } = useOrganizationSubscription()
  const { invoiceId, subject, message, selectedContactPersonId, onInvoiceSent } = useSendInvoiceModal()
  const { open: openErrorRateModal } = useModal(ModalId.EmailRateExceeded)
  const { close } = useModal(ModalId.SendInvoiceModal)
  const queryClient = useQueryClient()
  const [isSendCopyToUserEnabled, setIsSendCopyToUserEnabled] = useLocalStorage<boolean>(
    LocalStorageKeys.SendInvoiceCopyToUser,
    false,
  )
  const upgradeModalTrackingContext = freePlans.includes(subscriptionPlan)
    ? TrackingContext.EmailHigherRateExceeded
    : TrackingContext.EmailRateExceeded

  const { contactPersons, isLoading: isLoadingContactPersons } = useContactPersons({ contactId })

  const { sendEmailInvoice, isProcessing } = useEmailInvoice({
    onError: (error) => {
      if (error?.body.errorCode === 'EMAILS_LIMIT') {
        openErrorRateModal()
        return
      }

      notify({ id: NotificationKeys.InvoiceEmailSend, message: t('modal.send_invoice.sent_error'), variant: 'error' })
    },
    onSuccess: () => {
      notify({
        id: NotificationKeys.EInvoiceSend,
        message: t('modal.send_invoice.sent'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.InvoiceLogs)
      onInvoiceSent({ detail: { invoiceWasSentVia: 'email' } })
      close()
    },
  })

  const defaultValuesProps: GetEmailInvoiceFormDefaultValuesProps = useMemo(
    () => ({
      contactId,
      contactPersons: contactPersons?.contactPersons,
      message,
      requestCopy: isSendCopyToUserEnabled,
      selectedContactPersonId,
      subject,
    }),
    [contactId, contactPersons?.contactPersons, isSendCopyToUserEnabled, message, selectedContactPersonId, subject],
  )

  const { Form, handleSubmit, control, reset } = useForm({
    defaultValues: useMemo(() => getEmailInvoiceFormDefaultValues(defaultValuesProps), [defaultValuesProps]),
    validationSchema: useMemo(() => getEmailInvoiceValidationSchema(t), [t]),
  })

  useEffect(() => {
    if (!isLoadingContactPersons) {
      reset(getEmailInvoiceFormDefaultValues(defaultValuesProps))
    }
  }, [isLoadingContactPersons, defaultValuesProps, reset])

  const handleEmailnvoiceSubmitForm = useCallback(
    ({ recipients, subject, message, requestCopy }: EmailInvoiceForm) => {
      setIsSendCopyToUserEnabled(requestCopy)

      const copyToUserId = requestCopy && user?.id ? user.id : null
      if (some(recipients, (recipient) => isEmpty(recipient.email))) {
        notify({ id: NotificationKeys.EInvoiceSend, message: t('modal.send_invoice.email_error'), variant: 'error' })
      } else {
        sendEmailInvoice({ invoiceId, payload: { recipients, subject, message, copyToUserId } })
      }
    },
    [setIsSendCopyToUserEnabled, user?.id, t, sendEmailInvoice, invoiceId],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleEmailnvoiceSubmitForm)()
  }, [handleSubmit, handleEmailnvoiceSubmitForm])

  return (
    <EmailContext.Provider
      value={{
        Form,
        formControl: control,
        isLoading: isLoadingContactPersons,
        isProcessing,
        submitForm,
      }}
    >
      {children}
      <UpgradeSubscriptionModal id={ModalId.EmailRateExceeded} trackingContext={upgradeModalTrackingContext} />
    </EmailContext.Provider>
  )
}

export const useEmailContext = () => {
  const context = useContext(EmailContext)

  if (!context) {
    throw new Error('EmailContextProvider is missing in the module!')
  }

  return context
}
