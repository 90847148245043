import styled from '@emotion/styled'

import { shakeAnimation } from '../../utils/shakeAnimation'
import { Input as InputComponent } from '../Input'
import { SHAKE_CLASS_NAME } from './constants/shakeClassName'

export const Input = styled(InputComponent)`
  &.${SHAKE_CLASS_NAME} {
    ${shakeAnimation()};
  }
`
