import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

import { DropzoneBlockedDefaultProps, dropzoneBlockedDefaultProps } from '../../constants/dropzoneBlockedDefaultProps'
import { PORTAL_CLASS_NAME } from '../../constants/portalClassName'
import { ACCEPT_DEFAULT } from '../Dropzone'
import { useTranslateRejectedFilesErrors } from '../Dropzone/hooks/useTranslateRejectedFilesErrors'
import { DropzoneContent } from './elements/DropzoneContent'
import { useActivateDropzone } from './hooks/useActivateDropzone'
import * as Styled from './styles'

export interface DropzoneFullScreenProps extends DropzoneOptions {
  blockedText?: ReactNode
  children?: ReactNode
  className?: string
  forceActive?: boolean
  isBlocked?: boolean
  isUploading?: boolean
  onDragActive?: (isActive: boolean) => void
  uploadingText?: ReactNode
}

export const DropzoneFullScreen = ({
  accept = ACCEPT_DEFAULT,
  blockedText,
  children,
  className,
  forceActive,
  isBlocked,
  isUploading,
  onDragActive,
  uploadingText,
  onDrop,
  onDropRejected,
  maxFiles,
  maxSize,
  minSize,
  ...rest
}: DropzoneFullScreenProps): ReactElement => {
  const { handleDrop, handleDropRejected } = useTranslateRejectedFilesErrors({
    onDrop,
    onDropRejected,
    maxFiles,
    maxSize,
    minSize,
    accept,
  })

  const dropzoneBlockedProps: DropzoneBlockedDefaultProps | undefined = isBlocked
    ? dropzoneBlockedDefaultProps
    : undefined

  const dropzoneOptions: DropzoneOptions = {
    ...rest,
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop: handleDrop,
    onDropRejected: handleDropRejected,
    noClick: true,
    noKeyboard: true,
    ...dropzoneBlockedProps,
  }

  const [wrapperElement, setWrapperElement] = useState<HTMLElement>()
  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOptions)
  const { ref: wrapperRef, ...rootProps } = getRootProps()
  const isActive = useActivateDropzone(wrapperElement)

  useEffect(() => {
    if (wrapperRef?.current) {
      setWrapperElement(wrapperRef.current as HTMLElement)
    }
  }, [])

  useEffect(() => {
    onDragActive?.(isDragActive)
  }, [onDragActive, isDragActive])

  return ReactDOM.createPortal(
    <Styled.DropzoneFullScreenWrapper
      ref={wrapperRef}
      active={isActive || forceActive}
      className={`${PORTAL_CLASS_NAME} ${className || ''}`}
      {...rootProps}
    >
      <input {...getInputProps()} />

      <Styled.ContentWrapper>
        {children || (
          <DropzoneContent
            blockedText={blockedText}
            isBlocked={isBlocked}
            isUploading={isUploading}
            uploadingText={uploadingText}
          />
        )}
      </Styled.ContentWrapper>
    </Styled.DropzoneFullScreenWrapper>,
    document.body,
  )
}
