import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { DefaultValue } from '../../enums/defaultValue'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { getShadow } from '../../utils/getShadow'
import { PaymentPaneStatus } from './types/paymentPaneStatus'

interface PaymentPaneWrapperProps {
  status?: PaymentPaneStatus
  isCollapsed?: boolean
  withShadow?: boolean
}

export const PaymentPaneWrapper = styled.div<PaymentPaneWrapperProps>`
  overflow: hidden;
  height: 64px;
  border-radius: ${DefaultValue.BorderRadius};
  background-color: ${Color.White};
  ${({ withShadow }) => withShadow && getShadow('raisedLight')}

  ${({ isCollapsed }) => {
    if (!isCollapsed) {
      return css`
        height: 100%;
      `
    }
  }}

  ${({ status }) => {
    if (status && [PaymentPaneStatus.Success, PaymentPaneStatus.Error].includes(status)) {
      return css`
        position: relative;

        /* Colorized border around the wrapper */
        &:after {
          content: '';
          border: 1px solid ${status === PaymentPaneStatus.Success ? Color.Green : Color.Red};
          border-radius: ${DefaultValue.BorderRadius};
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          pointer-events: none;
        }
      `
    }
  }}
`

interface PaymentPaneItemsWrapperProps {
  status?: PaymentPaneStatus
  isCollapsed?: boolean
}

export const PaymentPaneItemsWrapper = styled.div<PaymentPaneItemsWrapperProps>`
  border-top: 1px solid transparent;
  ${getDefaultTransition('border-top-color')};

  ${({ isCollapsed }) => {
    if (!isCollapsed) {
      return css`
        border-top-color: ${Color.Gray30};
      `
    }
  }}
`
