import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconCalendar = ({ color = 'inherit', color2, width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-calendar')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 14">
      <path
        fill={coloring(color)}
        strokeWidth="0"
        d="M14,0H1C0.448,0,0,0.448,0,1v12c0,0.552,0.448,1,1,1h13c0.552,0,1-0.448,1-1V1C15,0.448,14.552,0,14,0z M1,13V2h13v11H1z"
      />
      <rect fill={coloring(color2 || color)} strokeWidth="0" x="6" y="5" width="1" height="5" />
      <rect fill={coloring(color2 || color)} strokeWidth="0" x="9" y="5" width="1" height="5" />
      <rect fill={coloring(color2 || color)} strokeWidth="0" x="4.6" y="5" width="2.4" height="1" />
      <rect fill={coloring(color2 || color)} strokeWidth="0" x="4" y="9" width="3" height="1" />
      <rect fill={coloring(color2 || color)} strokeWidth="0" x="8" y="9" width="2.4" height="1" />
      <rect fill={coloring(color2 || color)} strokeWidth="0" x="5" y="7" width="2" height="1" />
      <rect fill={coloring(color2 || color)} strokeWidth="0" x="8" y="5" width="2" height="1" />
    </svg>
  </Box>
)
