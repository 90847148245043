import { BorderRadius, Color, getShadow, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ReconciliationSuggestionWrapper = styled.div`
  background-color: ${Color.Purple20};
  border: 1px solid ${Color.Lavender};
  padding: ${Spacing.M};
  border-radius: ${BorderRadius.L};
  ${getShadow('raisedLight')};
`

export const ReconciliationSuggestionHeader = styled.div`
  display: flex;
  align-items: center;
`
