import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface SettingsSectionRowsContainerProps {
  children: ReactNode
}

export const SettingsSectionRowsContainer = ({ children }: SettingsSectionRowsContainerProps): ReactElement => {
  return <Styled.SettingsSectionRowsWrapper>{children}</Styled.SettingsSectionRowsWrapper>
}
