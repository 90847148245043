// @todo 'FilesPreview' should be moved to design-system directory sooner than later :)
import { FilesPreview, PreviewFile } from '@components-deprecated'
import { AttachmentFile, ModalConfirmation, useModal } from '@design-system'

import { memo, ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BillAttachmentPreview } from '../BillAttachmentPreview'

interface BillAttachmentPreviewProps {
  file: AttachmentFile
  onDelete?: (fileId: string) => void
}

const DELETE_ATTACHMENT_CONFIRMATION_MODAL_ID = 'delete-file-confirmation'

export const BillEditableAttachmentsPreview = memo(({ file, onDelete }: BillAttachmentPreviewProps): ReactElement => {
  const { t } = useTranslation()

  const { open: openDeleteConfirmation, close: closeDeleteConfirmation } = useModal(
    DELETE_ATTACHMENT_CONFIRMATION_MODAL_ID,
  )

  // Need to receive files from the BE to have downloadUrl
  const previewFiles: PreviewFile[] = useMemo(() => {
    return [{ src: file.downloadUrl }]
  }, [file])

  const handleDeleteFile = useCallback(() => openDeleteConfirmation(), [openDeleteConfirmation])

  const handleDeleteModalOkClick = useCallback(() => {
    onDelete?.(file.id)
    closeDeleteConfirmation()
  }, [closeDeleteConfirmation, file.id, onDelete])

  return (
    <BillAttachmentPreview>
      <FilesPreview
        files={previewFiles}
        fitToHeight
        fitToWidth
        navigationDirection="vertical"
        onDelete={handleDeleteFile}
        withDeleteButton
        zoomable
      />
      <ModalConfirmation
        cancelLabel={t('cancel')}
        danger
        id={DELETE_ATTACHMENT_CONFIRMATION_MODAL_ID}
        message={t('bill.action.delete_attachment.message')}
        okLabel={t('bill.action.delete_attachment.ok_button')}
        onOk={handleDeleteModalOkClick}
        title={t('bill.action.delete_attachment.title')}
      />
    </BillAttachmentPreview>
  )
})
