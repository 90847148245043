import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CookieKeys } from '../../../../../enums/cookieKeys'
import { useSegment } from '../../../../../hooks'
import { useSignup } from '../../../../../hooks/useSignup'
import { SignupErrorCode } from '../../../../../query-api/signup-query'
import { setAccessToken } from '../../../../../utils'
import { getSignupAnalyticsData } from '../../../../../utils/getSignupAnalyticsData'
import { useSignupGuide } from '../../../context/signupGuideContext'
import { GuidePath } from '../../../enums/guide-path'
import { CreateUserForm } from '../utils/formData'

interface UseHandleCreateUserSubmitProps {
  consentText?: string
}

export const useHandleCreateUserSubmit = ({ consentText }: UseHandleCreateUserSubmitProps) => {
  const [isError, setIsError] = useState(false)
  const [errorCode, setErrorCode] = useState<SignupErrorCode>()
  const history = useHistory()
  const { setUser, authorizeEmberUser } = useSignupGuide()
  const { trackRaw, identifyAnonymously } = useSegment()

  const handleError = useCallback((error?: any) => {
    if (error?.body.errorCode) {
      setErrorCode(error?.body.errorCode as SignupErrorCode)
    }

    console.error(error)
    setIsError(true)
  }, [])

  const { signup, isLoading } = useSignup({
    onSuccess: (response) => {
      const {
        meta: { accessToken },
        user,
      } = response

      setAccessToken(accessToken)
      authorizeEmberUser(accessToken)

      identifyAnonymously()

      trackRaw('Signup User Create Completed (FE)', {
        context: 'webapp_create_user',
        email: user.email,
      })

      setUser({ id: user.id, email: user.email })
      history.replace(`/${GuidePath.CreateOrganization}`)
    },
    onError: handleError,
  })

  const resetState = useCallback(() => {
    setIsError(false)
    setErrorCode(undefined)
  }, [])

  const submit = useCallback(
    (payload: CreateUserForm) => {
      const { emali: email, password, acceptedMarketing, acceptedTerms } = payload
      resetState()
      signup({
        email,
        password,
        acceptedMarketing,
        noOrganization: true,
        consentGiven: acceptedTerms,
        consentText,
        ...getSignupAnalyticsData(CookieKeys.BillySignupAnalytics),
      })
    },
    [resetState, signup, consentText],
  )

  return {
    submit,
    isLoading,
    errorCode,
    isError,
  }
}
