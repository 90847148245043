import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { Spacing } from '../../../../enums/spacing'

export const NavListHeaderBreadcrumbWrapper = styled.li`
  position: sticky;
  top: 0;
  background-color: ${Color.White};
`

export const IconWrapper = styled.div`
  display: flex;
  padding-top: ${Spacing.XXS};
`
