import { DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const OrganizationAvatarWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  margin-top: -${Spacing.M};
  margin-left: -${Spacing.S};
  border-radius: ${DefaultValue.BorderRadius};
  align-items: center;
  justify-content: center;
  overflow: auto;
`
