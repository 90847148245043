import { Color } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ReactComponent as ImageTopCustomersDKComponent } from '../../../../assets/images/dashboard-empty-state/dk/top-customers.svg'
import { ReactComponent as ImageTopCustomersENComponent } from '../../../../assets/images/dashboard-empty-state/en/top-customers.svg'
import { ReactComponent as ImageTopCustomersFRComponent } from '../../../../assets/images/dashboard-empty-state/fr/top-customers.svg'
import { LocaleCode } from '../../../../enums/localeCode'
import { QueryKeys } from '../../../../enums/queryKeys'
import { useLocale } from '../../../../hooks/useLocale'
import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { EmptyStateImage } from '../../elements/emptyStateImage'
import { Widget } from '../../elements/Widget'
import { WidgetId } from '../../enums/widgetId'
import { useFiscalYearQueryParams } from '../../hooks/useFiscalYearQueryParams'
import { fetchTopCustomers, StatsDataQueryParams } from '../../services/query-api'
import { getUsersWithColors } from '../../utils/getUsersWithColors'
import { UsersList, UsersListContext } from '../UsersList'

const emptyImageByLanguage: Record<LocaleCode, ReactElement> = {
  [LocaleCode.DA]: <ImageTopCustomersDKComponent />,
  [LocaleCode.EN]: <ImageTopCustomersENComponent />,
  [LocaleCode.FR]: <ImageTopCustomersFRComponent />,
}

export const TopCustomers = (): ReactElement => {
  const { organization } = useUserOrganization()
  const { queryParams } = useFiscalYearQueryParams(organization?.id)
  const { t } = useTranslation()
  const { code } = useLocale()
  const { isWidgetHidden } = useDashboardSettings()

  const isHidden = useMemo(() => isWidgetHidden(WidgetId.TopCustomers), [isWidgetHidden])

  const { data: topCustomers, isLoading: isTopCustomersLoading } = useQuery(
    [QueryKeys.TopCustomers, organization?.id, queryParams],
    () => fetchTopCustomers(organization?.id || '', undefined, queryParams || ({} as StatsDataQueryParams)),
    {
      enabled: !!organization && !!queryParams,
      refetchOnMount: 'always',
    },
  )
  const colors = useMemo(() => [Color.Green, Color.Blue, Color.Red], [])
  const topCustomersWithColor = getUsersWithColors(topCustomers?.data || [], colors)
  const isLoading = !organization || isTopCustomersLoading

  const doTopCustomersExist = topCustomersWithColor.length > 0 && !isHidden
  const shouldShowEmptyState = !!organization && !doTopCustomersExist && !isLoading

  return (
    <Widget title={t('dashboard.widget.topCustomers.title')} isHidden={isHidden}>
      {shouldShowEmptyState && <EmptyStateImage image={emptyImageByLanguage[code]} />}
      {doTopCustomersExist && (
        <UsersList usersListContext={UsersListContext.Customer} isLoading={isLoading} users={topCustomersWithColor} />
      )}
    </Widget>
  )
}
