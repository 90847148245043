import React from 'react'
import { Box } from 'rebass'

import { IconProps } from './Icon'

export const MenetoLogo = ({ width, height, ...rest }: IconProps) => {
  return (
    <Box as="i" {...rest}>
      <svg
        width={width || 60}
        height={height || 60}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80.93 57.08"
      >
        <title>Meneto logo - Green</title>
        <g id="Page-1">
          <g id="meneto-icon---green">
            <path
              id="Shape"
              fill="#2ab24e"
              d="M639.8,417.85l-10.51,10.5a1,1,0,0,1-1.35,0l-27.61-27.6a1,1,0,0,1,0-1.35l27.54-27.54a1,1,0,0,1,1.35,0l10.65,10.64a1,1,0,0,0,1.35,0l10.62-10.62a1,1,0,0,1,1.35,0l27.51,27.5a1,1,0,0,1,0,1.35l-27.64,27.64a1,1,0,0,1-1.35,0l-10.55-10.52A1,1,0,0,0,639.8,417.85ZM637,406.07l-3.24,3.24a1,1,0,0,1-1.35,0L624.16,401a1,1,0,0,1,0-1.35l11.07-11.07a1,1,0,0,0,0-1.35l-6-6a1,1,0,0,0-1.35,0l-18.4,18.4a1,1,0,0,0,0,1.35l18.35,18.35a1,1,0,0,0,1.35,0l18.35-18.35a1,1,0,0,0,0-1.35l-3.9-3.88a1,1,0,0,1,0-1.35l3.24-3.24a1,1,0,0,1,1.35,0l8.44,8.44a1,1,0,0,1,0,1.35l-11.06,11.06a1,1,0,0,0,0,1.35l5.95,6a1,1,0,0,0,1.35,0L671.35,401a1,1,0,0,0,0-1.35L653,381.22a1,1,0,0,0-1.35,0l-18.32,18.32a1,1,0,0,0,0,1.34l3.76,3.85A1,1,0,0,1,637,406.07Z"
              transform="translate(-600.05 -371.57)"
            />
          </g>
        </g>
      </svg>
    </Box>
  )
}
