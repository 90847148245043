import { Table, TableColumn } from '@design-system'

import React, { ReactElement } from 'react'

export const ReportsSkeleton = (): ReactElement => {
  const data: any = []
  const columns: TableColumn<any>[] = [
    {
      accessor: 'id',
      Header: '',
    },
    {
      accessor: 'name',
      Header: '',
    },
    {
      accessor: 'amount',
      Header: '',
    },
    {
      accessor: 'amount2',
      Header: '',
      alignment: 'right',
    },
  ]

  // Just a dummy data so we can simulate the format of data that will be there
  return <Table columns={columns} data={data} isLoading itemsPerPage={50} />
}
