import { amountToDisplayValue, Button, ButtonsGroup, Modal, Space, Text, useModal } from '@design-system'

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberCloseModal } from '../../../../contexts/emberCloseModalContext'
import { ModalId } from '../../../../enums/modalId'
import { useSegment } from '../../../../hooks'
import { EmberEventFn } from '../../../../types/emberEventFn'

interface Variables {
  amount: number
  currency: string
}

interface ApproveFinancingModalProps {
  onOk?: EmberEventFn
  variables?: Record<string, any>
}

export const ApproveFinancingModal = ({ onOk, variables }: ApproveFinancingModalProps) => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { closeEmberModal } = useEmberCloseModal()
  const [isProcessing, setIsProcessing] = useState(false)
  const { amount, currency } = variables as Variables

  useModal(ModalId.ApproveFinancingModal, {
    onClose: () => closeEmberModal(),
  })

  const handleCloseClick = useCallback(() => {
    closeEmberModal()
  }, [closeEmberModal])

  const handleConfirm = useCallback(() => {
    setIsProcessing(true)
    track('Invoice Financing Applied for Invoice (FE)')
    onOk?.({ detail: {} })
  }, [onOk, track])

  return (
    <Modal id={ModalId.ApproveFinancingModal} size="s" closable>
      <Modal.Header title={t('financing.approve_financing_modal.title')} />
      <Modal.Body>
        <Text>{t('financing.approve_financing_modal.description_1')}</Text>
        <Space size="l" />
        <Text alignment="center" variant="displayS">
          {amountToDisplayValue(amount)} {currency}
        </Text>
        <Space size="l" />
        <Text>{t('financing.approve_financing_modal.description_2')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button onClick={handleCloseClick} variant="secondary" disabled={isProcessing}>
            {t('financing.approve_financing_modal.cancel')}
          </Button>
          <Button onClick={handleConfirm} variant="primary" loading={isProcessing} disabled={isProcessing}>
            {t('financing.approve_financing_modal.apply')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
