import { Translate } from '@components'
import { InternalAccessor, Table, TableColumn } from '@design-system'

import React, { ReactElement, useMemo } from 'react'

import { TableIds } from '../../../../../../../../enums/tableIds'
import { usePaymentMethods } from '../../../../../../../../hooks/usePaymentMethods'
import { useCurrencyDefaultPaymentMethodsTableData } from '../../hooks/useCurrencyDefaultPaymentMethodsTableData'
import { CurrencyDefaultPaymentMethodsTableRow } from '../../types/currencyDefaultPaymentMethodsTableRow'
import { CurrencyDefaultPaymentMethodsTableStaticActions } from './elements/CurrencyDefaultPaymentMethodsTableStaticActions'

export const CurrencyDefaultPaymentMethodsTable = (): ReactElement => {
  const { paymentMethods, isLoading } = usePaymentMethods()
  const tableData = useCurrencyDefaultPaymentMethodsTableData(paymentMethods)

  const columns: TableColumn<CurrencyDefaultPaymentMethodsTableRow>[] = useMemo(
    () => [
      {
        accessor: 'currencyCode',
        Header: (
          <Translate value="settings.organization.invoice.currency_default_payment_methods_settings.table.header.currency" />
        ),
      },
      {
        accessor: 'paymentMethodName',
        Header: (
          <Translate value="settings.organization.invoice.currency_default_payment_methods_settings.table.header.payment_method" />
        ),
      },
      {
        accessor: InternalAccessor.StaticActions,
        Cell: ({ row, data }) => {
          const { currencyCode, paymentMethodId } = data[row.id] as CurrencyDefaultPaymentMethodsTableRow

          return (
            <CurrencyDefaultPaymentMethodsTableStaticActions
              currencyCode={currencyCode}
              paymentMethodId={paymentMethodId}
            />
          )
        },
        Header: '',
      },
    ],
    [],
  )

  return (
    <Table
      columns={columns}
      data={tableData}
      id={TableIds.CurrencyDefaultPaymentMethods}
      isLoading={isLoading}
      skeletonItemsPerPage={2}
      withColumnsFiltering={false}
    />
  )
}
