import { ItemsPerPage } from '@design-system'

import qs from 'qs'

import { ApiContentType } from '../../../enums/apiContentType'
import { SortDirection } from '../../../enums/sortDirection'
import { BinaryValue } from '../../../types/binaryValue'
import { Metable } from '../../../types/metable'
import { deleteRequest, getRequest, patchRequest, postRequest, postV2Request, putV2Request } from '../../../utils'
import { VoucherInboxState } from '../../inbox/types'
import { UmbrellaOrganization } from '../organizations/types'
import { OrganizationStateName } from './enums/organizationStateName'
import { InvitedUmbrellaUser } from './types/invitedUmbrellaUser'
import { OrganizationInvitation } from './types/organizationInvitation'
import { Umbrella } from './types/umbrella'
import { UmbrellaOrganizationsProductPlan } from './types/umbrellaOrganizationsProductPlan'
import { UmbrellaRole } from './types/umbrellaRole'
import { UserUmbrella } from './types/userUmbrella'
import { VoucherStats } from './types/voucherStats'

export type UpdateUmbrellaData = Pick<
  Umbrella,
  'address' | 'description' | 'email' | 'logoFileId' | 'name' | 'phone' | 'registrationNo'
> & { services: string | null }

// Change profile location
export const updateUmbrella = (umbrellaId: string, data: UpdateUmbrellaData): Promise<void> => {
  return putV2Request({
    endpoint: `/umbrellas/${umbrellaId}`,
    payload: { data },
    options: {
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  })
}

interface UmbrellaResponseData {
  data: Umbrella
}

export const fetchUmbrella = (umbrellaId: string): Promise<UmbrellaResponseData> => {
  return getRequest(`/umbrellas/${umbrellaId}`)
}

interface UserUmbrellasResponseData {
  data: UserUmbrella[]
}

export const fetchUserUmbrellas = (): Promise<UserUmbrellasResponseData> => {
  return getRequest('/user/umbrellas')
}

export interface OrganizationState {
  state: OrganizationStateName
  value?: string
}

export enum OrganizationsSortProperty {
  DraftDaybookTransactions = 'draftDaybookTransactions',
  LastTransactionTime = 'lastTransactionTime',
  OrphanAttachments = 'orphanAttachments',
  ReconcilableBankLines = 'reconcilableBankLines',
}

export interface OrganizationsQueryParams {
  /**
   * It extends response with information about the user (`name`, `email` and `id`) who made `Lock` action on the organization
   */
  includeWorksOnOrganizationUser?: boolean
  isInboxEnabled?: boolean
  isOwnedByUmbrella?: BinaryValue
  organizationStates?: OrganizationState[]
  organizationStatesOperator?: 'OR' | 'AND'
  /**
   * It shows only organizations that are locked by provided `userId`
   */
  userWorksOnOrganization?: string
  page?: number
  pageSize?: ItemsPerPage
  sortProperty?: OrganizationsSortProperty
  sortDirection?: SortDirection
  q?: string
}

export interface UmbrellaOrganizationsResponseData extends Metable {
  data: UmbrellaOrganization[]
}

export const fetchUmbrellaOrganizations = (
  umbrellaId: string,
  options?: OrganizationsQueryParams,
): Promise<UmbrellaOrganizationsResponseData> => {
  return getRequest(`/umbrellas/${umbrellaId}/organizations?${qs.stringify(options)}`)
}

type VoucherStatsResponseData = VoucherStats[]

interface VoucherStatsQueryParams {
  dueDateTo?: string
  inboxState?: VoucherInboxState
}

export const fetchVoucherStats = (
  organizationIds: string[],
  options?: VoucherStatsQueryParams,
): Promise<VoucherStatsResponseData> => {
  return postRequest(`/voucher-stats`, { organizationIds, ...options })
}

interface IsTrustedPartnerResponseData {
  data: boolean
}

export const fetchIsTrustedPartner = (organizationId: string): Promise<IsTrustedPartnerResponseData> => {
  return getRequest(`/organizations/${organizationId}/umbrellas/isTrustedPartner`)
}

interface FetchUmbrellaOrganizationsInvitationsResponseData extends Metable {
  data: OrganizationInvitation[]
}

export const fetchUmbrellaOrganizationsInvitations = (
  umbrellaId: string,
): Promise<FetchUmbrellaOrganizationsInvitationsResponseData> => {
  return getRequest(`/umbrellas/${umbrellaId}/organization-invitations`)
}

export const deleteUmbrellaOrganizationInvitation = (umbrellaId: string, invitationId: string): Promise<void> => {
  return postV2Request({
    endpoint: `/umbrellas/${umbrellaId}/organization-invitations/${invitationId}/reject`,
    options: {
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  })
}

export const acceptUmbrellaOrganizationInvitation = (token: string): Promise<void> => {
  return postV2Request({
    endpoint: `/organizationInvitations/${token}/accept`,
    options: {
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  })
}

interface GetUmbrellaOrganizationsProductPlansResponseData {
  data: UmbrellaOrganizationsProductPlan[]
}

export const getUmbrellaOrganizationsProductPlans = (
  umbrellaId: string,
): Promise<GetUmbrellaOrganizationsProductPlansResponseData> => {
  return getRequest(`/umbrellas/${umbrellaId}/organizations/getProductPlans`)
}

export interface UpdateUmbrellaOwnershipPayload {
  ownerUserId: string
}

export function updateUmbrellaOwnership(organizationId: string, data: UpdateUmbrellaOwnershipPayload): Promise<void> {
  return putV2Request({
    endpoint: `/umbrellas/${organizationId}/ownership`,
    payload: { data },
    options: {
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  })
}

export interface UmbrellaUserInvitationsResponseData {
  data: InvitedUmbrellaUser[]
}

export const fetchUmbrellaUserInvitations = (umbrellaId: string): Promise<UmbrellaUserInvitationsResponseData> => {
  return getRequest(`/umbrellas/${umbrellaId}/invitations`)
}

export interface UmbrellaRolesResponseData {
  data: UmbrellaRole[]
}

export const fetchUmbrellaRoles = (umbrellaId: string): Promise<UmbrellaRolesResponseData> => {
  return getRequest(`/umbrellas/${umbrellaId}/roles`)
}

export interface UmbrellaUserInvitationPayload {
  email: string
  roles: string[]
}

export const createUmbrellaUserInvitation = (
  umbrellaId: string,
  invitationPayload: UmbrellaUserInvitationPayload,
): Promise<UmbrellaUserInvitationsResponseData> => {
  return postV2Request({
    endpoint: `/umbrellas/${umbrellaId}/invitations`,
    payload: { data: invitationPayload },
    options: {
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  })
}

export const deleteUmbrellaUserInvitation = (umbrellaId: string, invitationId: string): Promise<void> => {
  return deleteRequest(`/umbrellas/${umbrellaId}/invitations/${invitationId}`)
}

export const deleteUmbrellaUser = (umbrellaId: string, userId: string): Promise<void> => {
  return deleteRequest(`/umbrellas/${umbrellaId}/users/${userId}`)
}

export interface UmbrellaUserRolesPayload {
  roleIds: string[]
}

export const updateRolesForUmbrellaUser = (
  umbrellaId: string,
  userId: string,
  rolesPayload: UmbrellaUserRolesPayload,
) => {
  return postV2Request({
    endpoint: `/umbrellas/${umbrellaId}/roles/users/${userId}`,
    payload: { data: rolesPayload },
    options: {
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  })
}

export interface UmbrellaRolePayload extends Pick<UmbrellaRole, 'grantAdmin' | 'grantAll' | 'name'> {
  description?: string
  organizationIds?: string[]
}

export interface UmbrellaRoleResponseData {
  data: UmbrellaRole
}

export const createUmbrellaRole = (
  umbrellaId: string,
  rolePayload: UmbrellaRolePayload,
): Promise<UmbrellaRoleResponseData> => {
  return postV2Request({
    endpoint: `/umbrellas/${umbrellaId}/roles`,
    payload: { data: rolePayload },
    options: {
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  })
}

export const deleteUmbrellaRole = (umbrellaId: string, roleId: string): Promise<void> => {
  return deleteRequest(`/umbrellas/${umbrellaId}/roles/${roleId}`)
}

export const updateUmbrellaRole = (
  umbrellaId: string,
  roleId: string,
  rolePayload: UmbrellaRolePayload,
): Promise<UmbrellaRoleResponseData> => {
  return patchRequest(
    `/umbrellas/${umbrellaId}/roles/${roleId}`,
    { data: rolePayload },
    {},
    true,
    ApiContentType.ApplicationBillyJSON,
  )
}
