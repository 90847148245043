import { Link, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as PuzzleImage } from '../../../../assets/images/integrations/puzzle.svg'
import { getImageClassName } from '../../../../utils/getClassName'
import * as Styled from './styles'

export const IntegrationsFooter = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.IntegrationFooterWrapper>
      <Styled.IntegrationFooterImageWrapper className={getImageClassName()}>
        <PuzzleImage />
      </Styled.IntegrationFooterImageWrapper>
      <Text colorVariant="secondary">{t('integrations.footer.subtitle')}</Text>
      <Styled.IntegrationFooterLinkWrapper>
        <Text variant="h2">
          <Link href="https://www.billy.dk/apps/" target="_blank" withAnimation>
            {t('integrations.footer.title_link')}
          </Link>
          &nbsp;
        </Text>
        <Text variant="h2">{t('integrations.footer.title')}</Text>
      </Styled.IntegrationFooterLinkWrapper>
    </Styled.IntegrationFooterWrapper>
  )
}
