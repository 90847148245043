import { notify } from '@design-system'

import { TFunction } from 'i18next'

import { NotificationKeys } from '../../../../../enums/notificationKeys'

export const showPaymentMethodErrorNotification = (t: TFunction) => {
  notify({
    id: NotificationKeys.PaymentMethodCreateError,
    message: t('integrations.card.mobile_pay.modal.notification.error'),
    variant: 'error',
  })
}
