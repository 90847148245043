import { encodePeriodValue } from '@components'
import { ItemsPerPage, useItemsPerPage } from '@design-system'

import { useCallback, useMemo } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useGlobalFiscalYear } from '../../../../../contexts/globalFiscalYearContext'
import { SortDirection } from '../../../../../enums/sortDirection'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { getPeriodValueFromFiscalYear } from '../../../../../utils/getPeriodValueFromFiscalYear'
import { TransactionOriginatorType } from '../../../enums/transactionOriginatorType'
import { TransactionOriginatorTypeGroup } from '../../../enums/transactionOriginatorTypeGroup'
import { TransactionSortProperty } from '../../../query-api'

export interface TransactionsListFilters {
  originatorType?: TransactionOriginatorType[]
  originatorTypeGroup?: TransactionOriginatorTypeGroup[]
  page?: number
  pageSize?: ItemsPerPage
  period?: string
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: TransactionSortProperty
  withVoided?: boolean
  withHasVoucher?: boolean
}

type UseTransactionsFiltersResponse = [TransactionsListFilters, (filters: Partial<TransactionsListFilters>) => void]

export const defaultTransactionsQuery: TransactionsListFilters = {
  originatorType: undefined,
  originatorTypeGroup: Object.values(TransactionOriginatorTypeGroup),
  page: 1,
  pageSize: undefined,
  period: undefined,
  searchQuery: undefined,
  sortDirection: SortDirection.Desc,
  sortProperty: TransactionSortProperty.TransactionNo,
  withVoided: undefined,
  withHasVoucher: true,
}

export const useTransactionsListFilters = (): UseTransactionsFiltersResponse => {
  const [pageSize] = useItemsPerPage()
  const { organization } = useUserOrganization()
  const { fiscalYear: globalFiscalYear } = useGlobalFiscalYear()
  const [queryParams, setQueryParams] = useQueryParams<TransactionsListFilters>({
    defaultQuery: {
      ...defaultTransactionsQuery,
      pageSize,
      ...(globalFiscalYear && organization?.id
        ? { period: encodePeriodValue(getPeriodValueFromFiscalYear(globalFiscalYear), organization.id) }
        : {}),
    },
  })

  const setFilters = useCallback(
    (params: TransactionsListFilters) => {
      setQueryParams(params)
    },
    [setQueryParams],
  )

  return useMemo(() => {
    const { withVoided, page, pageSize, originatorTypeGroup } = queryParams

    return [
      {
        ...queryParams,
        // Sometimes `page` comes as a string. It needs to be always `number` type.
        page: Number(page),
        // Sometimes `pageSize` comes as a string. It needs to be always `number` type.
        pageSize: Number(pageSize) as ItemsPerPage,
        // Sometimes `withVoided` comes as a string. It needs to be always `boolean` type.
        withVoided: typeof withVoided === 'string' ? withVoided === 'true' : Boolean(withVoided),
        // `originatorTypeGroup` comes as a string when it was set as empty array.
        originatorTypeGroup: typeof originatorTypeGroup === 'string' ? [] : originatorTypeGroup,
      },
      setFilters,
    ]
  }, [queryParams, setFilters])
}
