import { ReactElement } from 'react'
import ReactDOMServer from 'react-dom/server'
import { ColumnInstance } from 'react-table'

import { InternalAccessor } from '../enums/accessors'
import { TableToggleableColumn } from '../types/column'

export const getToggleableColumns = (columns: ColumnInstance<any>[]): TableToggleableColumn[] => {
  const accessibleColumns = columns.filter(
    (column) => !(Object.values(InternalAccessor) as string[]).includes(column.id),
  )

  return accessibleColumns
    .map((column) => {
      const columnName = column.Header && ReactDOMServer.renderToString(column.render('Header') as ReactElement).trim()
      const columnProps = column.getToggleHiddenProps()

      return {
        checked: columnProps.checked,
        id: column.id,
        name: columnName || '',
      }
    })
    .filter(({ name }) => !!name)
}
