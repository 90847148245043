import { ComponentType } from 'react'

import { useCustomEventsRoutes } from '../hooks/useCustomEventsRoutes'

// In some cases in order for "links" to work properly in views using HashRouter
// we need to send a custom event to the view that is using HashRouter
// and this HOC runs useCustomEventsRoutes which changes the url
// and the view based on that custom event
export const withRouteLinkClickedListener = <TProps extends object>(Component: ComponentType<TProps>) => {
  return (props: TProps) => {
    useCustomEventsRoutes()
    return <Component {...props} />
  }
}
