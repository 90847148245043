import isEqual from 'lodash/isEqual'
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { ButtonProps } from '../Button'
import { ButtonDropdown } from '../ButtonDropdown'
import { DropdownNavListProps } from '../DropdownNavList'
import { NavItem } from '../NavList'
import { NavListItemWithCheckbox } from '../NavList/formatters/NavListItemWithCheckbox'
import { ButtonDropdownMultipleHeader } from './elements/ButtonDropdownMultipleHeader'
import { ButtonDropdownMultipleText } from './elements/ButtonDropdownMultipleText'
import { getMultipleDropdownItems } from './utils/getMultipleDropdownItems'

export interface ButtonDropdownMultipleProps<T = string>
  extends Omit<ButtonProps, 'onSelect'>,
    Pick<DropdownNavListProps<T>, 'onOpen' | 'onClose' | 'placement'> {
  // Dropdown props
  dropdownSize?: DropdownNavListProps<T>['size']
  onSelect?: (event: ChangeEvent<HTMLInputElement>, values: T[], value?: T, checked?: boolean) => void
  triggerElement?: ReactElement
  // Button props
  items: NavItem<T>[]
  selectAllText?: string
  selectedValues?: T[]
  withSelectAll?: boolean
}

export const ButtonDropdownMultiple = <T,>({
  // Dropdown props
  dropdownSize = 'l',
  onSelect,
  placement = 'top-start',
  // Multiple select props
  items,
  selectedValues: selectedValuesControlled,
  // Button props
  placeholder,
  size = 'l',
  variant = 'secondary',
  selectAllText,
  withSelectAll,
  ...rest
}: ButtonDropdownMultipleProps<T>): ReactElement => {
  const [selectedValues, setSelectedValues] = useState<T[]>(selectedValuesControlled || [])
  const [isAllChecked, setIsSelectAllChecked] = useState(withSelectAll && selectedValues?.length === items.length)

  const isAllIndeterminate = useMemo(
    () => withSelectAll && !isAllChecked && selectedValues.length < items.length && selectedValues.length > 0,
    [items, selectedValues, withSelectAll, isAllChecked],
  )

  const handleAllChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!withSelectAll) {
        return
      }

      const isChecked = event.target.checked
      setIsSelectAllChecked(isChecked)

      if (isChecked) {
        const selectedValues = items.map(({ value }) => value)
        setSelectedValues(selectedValues)
        onSelect?.(event, selectedValues)
      } else {
        setSelectedValues([])
        onSelect?.(event, [])
      }
    },
    [items, onSelect, withSelectAll],
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: T) => {
      const isChecked = event.target.checked
      const newSelectedValues = isChecked
        ? [...selectedValues, value]
        : selectedValues.filter((prevValue) => !isEqual(prevValue, value))

      setSelectedValues(newSelectedValues)

      if (withSelectAll) {
        setIsSelectAllChecked(newSelectedValues.length === items.length)
      }

      onSelect?.(event, newSelectedValues, value, isChecked)
    },
    [setSelectedValues, selectedValues, onSelect, items, withSelectAll],
  )

  const dropdownItems = useMemo(
    () => getMultipleDropdownItems(items, selectedValues, handleChange, withSelectAll),
    [items, handleChange, selectedValues, withSelectAll],
  )

  useEffect(() => {
    if (selectedValuesControlled !== undefined && !isEqual(selectedValuesControlled, selectedValues)) {
      setSelectedValues(selectedValuesControlled)

      if (withSelectAll) {
        setIsSelectAllChecked(selectedValuesControlled.length === items.length)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValuesControlled])

  return (
    <ButtonDropdown
      dropdownItemRender={(props) => <NavListItemWithCheckbox {...props} />}
      dropdownSize={dropdownSize}
      dropdownHeader={
        withSelectAll ? (
          <ButtonDropdownMultipleHeader
            checked={isAllChecked}
            isIndeterminate={isAllIndeterminate}
            onChange={handleAllChange}
            label={selectAllText}
          />
        ) : undefined
      }
      items={dropdownItems}
      placement={placement}
      size={size}
      variant={variant}
      {...rest}
    >
      {selectedValues.length ? (
        <ButtonDropdownMultipleText items={items} label={selectAllText} selectedValues={selectedValues} />
      ) : (
        placeholder
      )}
    </ButtonDropdown>
  )
}
