import React, { createContext, Dispatch, ReactElement, ReactNode, useContext, useReducer } from 'react'

export type CommonFormDataContextState<T = Record<string, any>> = {
  value?: T
}

const initialState = {
  value: undefined,
}

export const CommonFormDataContext = createContext<[CommonFormDataContextState, Dispatch<Actions>] | undefined>(
  undefined,
)

interface CommonFormDataContextProviderProps {
  children: ReactNode
}

export const CommonFormDataContextProvider = ({ children }: CommonFormDataContextProviderProps): ReactElement => {
  const reducerData = useReducer(commonFormDataReducer, initialState)

  return <CommonFormDataContext.Provider value={reducerData}>{children}</CommonFormDataContext.Provider>
}

function commonFormDataReducer(state: CommonFormDataContextState, action: Actions): CommonFormDataContextState {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        value: action.payload,
      }
    case 'CLEAR':
      return {
        ...state,
        value: undefined,
      }
  }
}

type Actions = { type: 'SET'; payload: Record<string, any> } | { type: 'CLEAR' }

export function useCommonFormData<T>() {
  const context = useContext(CommonFormDataContext)

  if (!context) {
    throw new Error('CommonFormDataContextProvider is missing in the module!')
  }

  const [commonFormData, dispatch] = context
  return { commonFormData: commonFormData as CommonFormDataContextState<T>, dispatch }
}
