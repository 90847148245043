import { ProtectedComponent, SettingsSection } from '@components'
import { Button } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Scope } from '../../../../../../enums/scope'
import { useOrganizationAccountingSettings } from '../../contexts/organizationAccountingSettingsContext'
import { BankSyncSettings } from '../BankSyncSettings'
import { BillSettings } from '../BillSettings'
import { TaxSettings } from '../TaxSettings'

export const AccountingSettingsForm = (): ReactElement => {
  const { t } = useTranslation()
  const { Form, isProcessing, isLoading } = useOrganizationAccountingSettings()

  return (
    <SettingsSection>
      <Form>
        <SettingsSection.ColumnsContainer>
          <SettingsSection.Content title={t('settings.organization.accounting.tax_settings')}>
            <TaxSettings />
          </SettingsSection.Content>
          <SettingsSection.RowsContainer>
            <SettingsSection.Content title={t('settings.organization.accounting.bill_settings')}>
              <BillSettings />
            </SettingsSection.Content>
            <ProtectedComponent scopes={[Scope.BankReconciliationRead, Scope.BankReconciliationWrite]}>
              <SettingsSection.Content title={t('settings.organization.accounting.bank_sync_settings')}>
                <BankSyncSettings />
              </SettingsSection.Content>
            </ProtectedComponent>
          </SettingsSection.RowsContainer>
        </SettingsSection.ColumnsContainer>
        <SettingsSection.Footer>
          <Button type="submit" size="l" loading={isProcessing} disabled={isLoading}>
            {t('settings.organization.accounting.save_changes')}
          </Button>
        </SettingsSection.Footer>
      </Form>
    </SettingsSection>
  )
}
