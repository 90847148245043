import { add, Duration, formatISO, isAfter, isValid, parseISO } from 'date-fns'

import { DurationUnit } from '../types/durationUnit'

export interface Iso8601RepeatingIntervalProps {
  durationCount?: number
  durationUnit?: string
  end?: string
  repetitions?: number
  start?: string
  isValid: boolean
}

const durationIsoToDateFns: Record<DurationUnit, keyof Duration> = {
  D: 'days',
  M: 'months',
  W: 'weeks',
  Y: 'years',
}

function parseDate(value?: string) {
  if (!value) {
    return null
  }
  if (typeof value === 'string') {
    return parseISO(value)
  }
  return value
}

export class Iso8601RepeatingInterval {
  public isValid: boolean
  public start?: Date | number | null
  public end?: Date | number | null
  public repetitions?: number
  public durationCount?: number
  public durationUnit?: string

  constructor(props: Iso8601RepeatingIntervalProps) {
    this.isValid = props.isValid
    this.start = parseDate(props.start)
    this.end = parseDate(props.end)
    this.repetitions = props.repetitions
    this.durationCount = props.durationCount
    this.durationUnit = props.durationUnit

    if (
      !this.repetitions &&
      !!(this.start && this.end && this.durationUnit) &&
      isValid(this.start) &&
      isValid(this.end)
    ) {
      let intervalStart = this.start
      this.repetitions = 0

      while (isAfter(this.end, intervalStart)) {
        const duration = durationIsoToDateFns[this.durationUnit]

        intervalStart = add(intervalStart, { [duration]: this.durationCount })
        this.repetitions++
      }
    }

    if (
      !this.end &&
      !!(this.start && this.durationUnit && this.repetitions && this.durationCount) &&
      isValid(this.start)
    ) {
      const duration = durationIsoToDateFns[this.durationUnit]

      this.end = add(this.start, {
        [duration]: (this.repetitions - 1) * this.durationCount,
      })
    }
  }

  validate = () => this.isValid

  serialize = () => {
    const serializedRepetitions = this.repetitions ?? ''
    const serializedStart = this.start ? formatISO(this.start) : ''

    return `R${serializedRepetitions}/${serializedStart}/P${this.durationCount}${this.durationUnit}`
  }
}
