import { Checkbox, CheckboxProps } from '@components-deprecated'
import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const Navigator = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: ${Spacing.S};

  li {
    display: flex;
    align-items: center;

    + li {
      margin-left: ${Spacing.XL};
    }
  }
`

export const NavigationItem = styled.li``

export const FilterCheckbox = styled(Checkbox)<CheckboxProps>`
  margin: 0;

  label {
    color: ${Color.DeepSeaGreen};
    margin-bottom: 0;
  }
`

export const SectionMessageWrapper = styled.div`
  margin-bottom: ${Spacing.L};
`

export const WarningList = styled.ul`
  list-style-type: disc;
`

export const WarningListItem = styled.li`
  margin-left: ${Spacing.L};
`
