import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { registerInPeppol, RegisterInPeppolPayload } from '../query-api'

interface UseRegisterInPeppolProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useRegisterInPeppol = (props?: UseRegisterInPeppolProps) => {
  const { onError, onSuccess } = props || {}

  const {
    mutate: registerInPeppolMutation,
    isLoading,
    error,
  } = useMutation(
    (props: RegisterInPeppolPayload) => {
      return registerInPeppol(props)
    },
    {
      onError,
      onSuccess,
    },
  )

  return { isLoading, registerInPeppol: registerInPeppolMutation, error }
}
