import { TFunction } from 'i18next'
import { date, object, SchemaOf, string } from 'yup'

import { Account } from '@modules-deprecated/app/accounts/types'

export interface RegisterPaymentForm {
  billDate: Date | undefined
  paymentAccount: Partial<Account> | undefined
  paymentDate: Date | undefined
}

export const defaultValues: RegisterPaymentForm = {
  billDate: undefined,
  paymentAccount: undefined,
  paymentDate: undefined,
}

export type RegisterPaymentFormSchema = SchemaOf<RegisterPaymentForm>

export const getValidationSchema = (t: TFunction): RegisterPaymentFormSchema =>
  object({
    billDate: date(), // helper for 'paymentDate' validation
    paymentAccount: object({
      id: string().required(t('required_field')),
    })
      .required(t('required_field'))
      .typeError(t('required_field')),
    paymentDate: date()
      .when(
        'billDate',
        (billDate, yup) => billDate && yup.min(billDate, t('bill.edit.form.validate.payment_date_before_bill_date')),
      )
      .required(t('required_field')),
  })
