import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'

interface SpaceWrapperProps {
  size: Spacing | 'auto'
  horizontal: boolean
}

export const SpaceWrapper = styled.div<SpaceWrapperProps>`
  ${({ horizontal, size }) =>
    horizontal
      ? css`
          margin-right: ${size};
        `
      : css`
          margin-bottom: ${size};
        `};
`
