import { css, SerializedStyles } from '@emotion/core'
import cc from 'classcat'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { Box, BoxProps, Text } from 'rebass'

import { reactClass } from '../../utils'
import { TableRowComponent } from './TableRow'
import { TableColumn, TableRow, TableTotalRow } from './types'

export type TableBodyProps<T extends TableRow> = Omit<BoxProps, 'data' | 'onSelect'> & {
  columns: TableColumn<T>[]
  data?: T[]
  isRowSelectionDisabled?: (row: T) => boolean
  onFetch?: (offset: number) => void
  onRowSelect?: (row: T, selected: boolean) => void
  onSelect?: (row: T, index: number) => void
  rowStyles?: SerializedStyles
  selectable?: boolean
  total?: number | null
  rowTotal?: TableTotalRow<T>
}

type LoaderProps = {
  columnCount: number
}

export const Loader = ({ columnCount }: LoaderProps) => {
  const { t } = useTranslation()

  return (
    <Box as="tr">
      <Text
        as="td"
        className={reactClass('infinite-table-loader')}
        css={css`
          grid-column: span ${columnCount};
          text-align: center;
        `}
      >
        {t('components.infinitetable.loading')}
      </Text>
    </Box>
  )
}

const Trigger = React.forwardRef(({ ...rest }, ref) => (
  <Box
    as="tfoot"
    className={reactClass('infinite-table-trigger')}
    key="infinte-table-trigger"
    height="10px"
    m="-10px"
    ref={ref}
    {...rest}
  />
))

export const TableBody = <T extends TableRow>({
  className,
  columns,
  data,
  isRowSelectionDisabled = () => false,
  onFetch = () => null,
  onRowSelect = () => null,
  onSelect,
  rowStyles,
  rowTotal,
  selectable,
  total,
  ...rest
}: TableBodyProps<T>) => {
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [ref, inView] = useInView()

  const fetch = () => {
    if (loading || done) {
      return
    }
    setLoading(true)
    onFetch(data?.length || 0)
  }

  const receive = () => {
    if (!data) {
      setDone(false)
      return
    }
    setLoading(false)
    setDone(data.length === total)
  }

  useEffect(receive, [data, total])
  useEffect(fetch, [inView])

  return (
    <>
      <Box as="tbody" className={cc([className, reactClass('infinite-table-body')])} {...rest}>
        {(data || []).map((row: T, index: number) => (
          <TableRowComponent<T>
            key={row.id}
            row={row}
            index={index}
            columns={columns}
            onSelect={onSelect}
            onRowSelect={onRowSelect}
            rowStyles={rowStyles}
            selectable={selectable}
            hoverable={!!onSelect || !!row.linkTo}
            isRowSelectionDisabled={isRowSelectionDisabled(row)}
          />
        ))}
        {rowTotal && (
          <TableRowComponent<T>
            className="total"
            key={rowTotal.id}
            row={rowTotal}
            index={0}
            columns={columns}
            rowStyles={rowStyles}
            selectable={false}
            hoverable={false}
            totalLabelColumnName={rowTotal.labelColumnName}
          />
        )}
        {!done && <Loader columnCount={columns.length} />}
      </Box>
      {!loading && !done && <Trigger ref={ref} />}
    </>
  )
}
