import { Spacing } from '@design-system'

import styled from '@emotion/styled'

import { UmbrellaOrganizationsProductPlansSelect as UmbrellaOrganizationsProductPlansSelectComponent } from '../../../UmbrellaOrganizationsProductPlansSelect'

export const SubscriptionSection = styled.section`
  margin-top: ${Spacing.XXL};
`

export const ProductPlansSection = styled.div`
  margin-top: ${Spacing.S};
`

export const UmbrellaOrganizationsProductPlansSelect = styled(UmbrellaOrganizationsProductPlansSelectComponent)`
  margin-top: ${Spacing.XXXS};
`
