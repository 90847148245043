import { useModal } from '@design-system'

import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as StripeLogo } from '../../../assets/images/integrations/stripe.svg'
import { ModalId } from '../../../enums/modalId'
import { IntegrationStatusLabel } from '../elements/IntegrationCard/elements/IntegrationStatusLabel'
import { IntegrationId } from '../enums/integrationId'
import { IntegrationStatus } from '../enums/integrationStatus'
import { IntegrationItem } from '../types/integrationItem'
import { usePaymentMethodsStatuses } from './usePaymentMethodsStatuses'

export const useStripeIntegration = (): IntegrationItem => {
  const { t } = useTranslation()
  const { open: openEnableStripeModal } = useModal(ModalId.EnableStripeModal)
  const { open: openDisableStripeModal } = useModal(ModalId.DisableStripeModal)
  const { isLoading: isPaymentMethodsStatusesLoading, stripeStatus: integrationStatus } = usePaymentMethodsStatuses()

  const buttonHandler = useCallback(() => {
    if (integrationStatus === IntegrationStatus.Inactive) {
      openEnableStripeModal()
    } else if (integrationStatus === IntegrationStatus.Active) {
      openDisableStripeModal()
    }
  }, [integrationStatus, openEnableStripeModal, openDisableStripeModal])

  const buttonText = useMemo(() => {
    if (integrationStatus === IntegrationStatus.Inactive) {
      return t('integrations.card.stripe.button.activate')
    } else if (integrationStatus === IntegrationStatus.Active) {
      return t('integrations.card.stripe.button.deactivate')
    }
  }, [integrationStatus, t])

  return {
    id: IntegrationId.Stripe,
    name: t('integrations.card.stripe.title'),
    description: t('integrations.card.stripe.body'),
    readMoreLink: 'https://www.billy.dk/support/article/stripe-betalingsmetode/',
    imageNode: <StripeLogo />,
    isHiddenLoading: false,
    isHidden: false,
    isPremium: true,
    buttonHandler,
    buttonText,
    statusLabelNode: (
      <IntegrationStatusLabel status={integrationStatus} isStatusLoading={isPaymentMethodsStatusesLoading} />
    ),
    isStatusLoading: isPaymentMethodsStatusesLoading,
  }
}
