import { convertBookkeepingTagNavItemValueToDbValue, isBookkeepingTagValid } from '@components'

import { formatDate } from '../../../../../utils'
import { roundFloat } from '../../../../../utils/roundFloat'
import { BillState } from '../../../enums/billState'
import { BillType } from '../../../enums/billType'
import { CreateBillPayload } from '../../../query-api'
import { BillAttachment } from '../../../types/billAttachment'
import { BillForm } from './formData'

interface GetAttachmentsResult {
  attachments: Partial<BillAttachment>[]
  attachmentIds: Partial<BillAttachment>[]
}

const getAttachments = (bill: BillForm, organizationId: string): GetAttachmentsResult => {
  const attachmentsFilesChosen = bill.attachmentsFilesChosen
  const attachmentsFilesUploaded = bill.attachmentsFiles?.filter((file) => {
    const isFileChosen = attachmentsFilesChosen?.some(
      (attachmentFile) => attachmentFile.attachmentId === file.attachmentId,
    )

    return !file.attachmentId || !isFileChosen
  })

  // As the 'attachmentIds' we pass all files that have beed selected from "Choose file" modal
  const attachmentIds = attachmentsFilesChosen?.map(({ attachmentId }) => ({ id: attachmentId })) || []

  // As the 'attachments' property we pass all files that have been dragged&dropped (just uploaded) - in this scenario 'file.attachmentId' is undefined
  // Or attachments that are already saved (assigned) to this bill - in this scenario 'file.attachmentId' is defined
  const attachments =
    attachmentsFilesUploaded?.map((file) => ({
      id: file.attachmentId,
      fileId: file.id,
      organizationId,
    })) || []

  return {
    attachments,
    attachmentIds,
  }
}

export const convertBillFormToBillPayload = (
  bill: BillForm,
  organizationId: string,
  isCreditNote?: boolean,
  state?: BillState,
): CreateBillPayload => {
  const lines: CreateBillPayload['lines'] = []
  const { attachments, attachmentIds } = getAttachments(bill, organizationId)
  const billState = state || bill.state || BillState.Draft

  if (bill.billLines) {
    for (let i = 0; i < bill.billLines.length; i++) {
      const line = bill.billLines[i]
      const bookkeepingTag =
        line.bookkeepingTag && isBookkeepingTagValid(line.bookkeepingTag)
          ? JSON.stringify(convertBookkeepingTagNavItemValueToDbValue(line.bookkeepingTag))
          : null

      lines.push({
        accountId: line.expenseCategory?.id || null,
        amount: roundFloat(line.inclVatAmount || 0),
        bookkeepingTag,
        description: line.description || null,
        priority: i + 1, // used for correct ordering
        quantity: 1,
        taxRateId: line.taxRate?.id || null,
      })
    }
  }

  const billPayload: CreateBillPayload = {
    attachments,
    attachmentIds,
    contactId: bill.vendor?.id || null,
    creditedBillId: bill.creditedBillId || null,
    currencyId: bill.currencyId || null,
    dueDate: bill.dueDate ? formatDate(bill.dueDate) : null,
    entryDate: bill.billDate ? formatDate(bill.billDate) : null,
    isBare: billState === BillState.Draft,
    lines,
    organizationId,
    paymentAccountId: bill.paymentAccount?.id || null,
    paymentDate: bill.paymentDate ? formatDate(bill.paymentDate) : null,
    state: billState,
    suppliersInvoiceNo: bill.referenceNumber || null,
    taxMode: bill.taxMode,
    type: isCreditNote ? BillType.CreditNote : BillType.Bill,
    voucherNo: bill.voucherNo || null,
  }

  return billPayload
}
