import { FileAvatar, Translate } from '@components'
import { CellValue, InternalAccessor, NavItem, Space, Table, TableColumn, TableStaticActions } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useRemoveUser } from '../../../../contexts/removeUserContext'
import { useOrganizationBookkeepers } from '../../../../hooks/useOrganizationBookkeepers'
import { User } from '../../../../types/user'
import { TableUserName } from '../../../UsersSection/elements/UsersTable/elements/TableUserName'
import { BookkeepersTableAccessors } from './enums/bookkeepersTableAccessors'
import { BookkeepersTableStaticActionType } from './enums/bookkeepersTableStaticActionType'
import { BookkeepersTableRow } from './types/bookkeepersTableRow'

export const BookkeepersTable = (): ReactElement => {
  const { t } = useTranslation()
  const { data: bookeepersData, isLoading } = useOrganizationBookkeepers()
  const { removeUser } = useRemoveUser()

  const staticActionsItems: NavItem[] = useMemo(
    () => [
      {
        children: t('settings.organization.access_tokens_table.actions.delete'),
        iconLeft: 'trash',
        id: 'delete',
        value: BookkeepersTableStaticActionType.Delete,
      },
    ],
    [t],
  )

  const columns: TableColumn<BookkeepersTableRow>[] = useMemo(
    () => [
      {
        accessor: BookkeepersTableAccessors.Bookkeeper,
        alignment: 'left',
        Cell: ({ value: props }: CellValue<User>) => (
          <TableUserName {...props} prefix={<FileAvatar logoId={props.logoId} />} />
        ),
        Header: <Translate value="settings.organization.users.bookkeepers_section.table.name" />,
      },
      {
        Header: '',
        accessor: InternalAccessor.StaticActions,
        Cell: ({ row, data }) => {
          const { bookkeeper } = data[row.id]

          if (bookkeeper.isOwner) {
            return <Space size="xxl" horizontal />
          }

          return (
            <TableStaticActions
              items={staticActionsItems}
              onSelect={() => {
                removeUser(bookkeeper)
              }}
            />
          )
        },
      },
    ],
    [removeUser, staticActionsItems],
  )

  const tableData = useMemo(() => {
    if (isLoading) {
      return []
    }

    return bookeepersData.map((bookkeeper) => ({
      [BookkeepersTableAccessors.Bookkeeper]: bookkeeper,
      [InternalAccessor.StaticActions]: true,
      id: bookkeeper.id,
    }))
  }, [bookeepersData, isLoading])

  return <Table columns={columns} data={tableData} isLoading={isLoading} skeletonItemsPerPage={5} />
}
