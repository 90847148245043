import { IconName } from '../../../../../Icon'
import { IconButton } from '../../../../../IconButton'
import { Tooltip } from '../../../../../Tooltip'

interface TableHoverActionProps {
  onClick: () => void
  icon: IconName
  label: string
}

export const TableHoverAction = ({ onClick, icon, label }: TableHoverActionProps) => {
  return (
    <Tooltip label={label} placement="bottom">
      <IconButton icon={icon} onClick={onClick} variant="text" size="m" />
    </Tooltip>
  )
}
