import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { BorderRadius } from '../../enums/borderRadius'
import { Color } from '../../enums/color'
import { Spacing } from '../../enums/spacing'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { getShadow } from '../../utils/getShadow'

const activeStyle = css`
  ${getShadow(undefined, { borderColor: Color.Green, borderWidth: 2 })};
`

const clickableStyle = css`
  cursor: pointer;

  &:hover {
    ${getShadow(undefined, { borderColor: Color.Lavender, borderWidth: 2 })};
  }
`

const disabledStyle = css`
  opacity: 0.5;
  cursor: not-allowed;
`

interface AmountInfoBoxWrapperProps {
  active?: boolean
  clickable?: boolean
  disabled?: boolean
}

export const AmountInfoBoxWrapper = styled.div<AmountInfoBoxWrapperProps>`
  min-height: 116px;
  border-radius: ${BorderRadius.XL};
  padding: ${Spacing.L} ${Spacing.XL} ${Spacing.XL};
  background-color: ${Color.White};

  ${getShadow(undefined, { borderColor: Color.Gray30, borderWidth: 1 })};
  ${getDefaultTransition('box-shadow', 'background')};

  ${({ active, disabled }) => active && !disabled && activeStyle};
  ${({ active, clickable, disabled }) => clickable && !active && !disabled && clickableStyle};
  ${({ disabled }) => disabled && disabledStyle}
`

export const BadgeWithLabelWrapper = styled.div`
  margin-bottom: ${Spacing.M};
`
