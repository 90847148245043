import { Text } from '@design-system'

import styled from '@emotion/styled'

export const AccountInfoWrapper = styled.div``

export const AccountInfoText = styled(Text)`
  display: flex;
  flex-direction: column;
  margin: auto;
`

export const OrganizationNameText = styled.span`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
`
