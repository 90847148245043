import { TFunction } from 'i18next'
import { object, SchemaOf, string } from 'yup'

export const inviteBookkeeperFormDefaultValues: InviteBookkeeperForm = {
  umbrellaId: '',
}

export interface InviteBookkeeperForm {
  umbrellaId: string
}

export type InviteBookkeeperFormSchema = SchemaOf<InviteBookkeeperForm>

export const getValidationSchema = (t: TFunction): InviteBookkeeperFormSchema =>
  object({
    umbrellaId: string().required(),
  })
