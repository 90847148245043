import { DeductionComponent } from '@modules-deprecated/app/taxrates/types'

import { DeductionComponentForm } from '../../../../types/deductionComponentForm'

export const shareFromPercentToFraction = (deductionComponents: (DeductionComponent | DeductionComponentForm)[]) =>
  deductionComponents.map((item) => {
    return {
      ...item,
      share: item.share / 100,
    }
  })
