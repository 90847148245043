import { SuggestionItem, SuggestionsGroup } from '@components-deprecated'

import sortBy from 'lodash/sortBy'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BohrScanResult } from '../../../types/bohrScanResult'
import { State } from '../../../types/reduxSaga-deprecated'
import { accountsSelector } from '../../app'
import { Account } from '../../app/accounts/types'

const AMOUNT_LIMIT = 5
const PROBABILITY_LIMIT = 15

type Suggestion = {
  accountId: string
  title: string
  probability: number
}

// deprecated solution, needs adjustments to the 'tag_scores' property:
const getAccountSuggestions = (bohrData: BohrScanResult, accounts: Account[]) => []

const filterSuggestions = (suggestions: Suggestion[]) => {
  const sorted = sortBy(suggestions, 'probability').reverse()
  const filtered = sorted.filter((suggestion) => suggestion.probability * 100 >= PROBABILITY_LIMIT)
  return filtered.slice(0, AMOUNT_LIMIT)
}

interface AccountSuggestionsProps {
  bohrData: BohrScanResult
  onClick: (accountId: string) => void
  selectedId?: string
}

export const AccountSuggestions = ({
  bohrData,
  onClick,
  selectedId,
  ...rest
}: AccountSuggestionsProps): ReactElement | null => {
  const { t } = useTranslation()
  const accounts = useSelector((state: State) => accountsSelector(state, {})) as Account[]

  const suggestions = getAccountSuggestions(bohrData, accounts)
  const suggestionsFiltered = filterSuggestions(suggestions)

  if (!suggestionsFiltered?.length) {
    return null
  }

  return (
    <SuggestionsGroup label={t('voucher.inbox.suggestions.account')} {...rest}>
      {suggestionsFiltered.map(({ title, probability, accountId }) => (
        <SuggestionItem
          key={`suggestion-${accountId}`}
          rate={probability}
          onClick={() => onClick(accountId)}
          selected={accountId === selectedId}
          truncate
        >
          {title}
        </SuggestionItem>
      ))}
    </SuggestionsGroup>
  )
}
