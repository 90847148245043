import qs from 'qs'

import { ApiContentType } from '../../enums/apiContentType'
import { request, RequestDataOptions } from '../../utils'
import { AnnualReportsData } from './types'
import { AnnualReportCredit } from './types/annualReportCredit'

interface FetchAnnualReportsResponseData {
  years: AnnualReportsData[]
}

const options: RequestDataOptions = {
  returnRawResponse: false,
  withAuth: true,
  contentType: ApiContentType.ApplicationBillyJSON,
}

export const fetchAnnualReports = async (organizationId: string): Promise<FetchAnnualReportsResponseData> => {
  const { data } = await request(`/organizations/${organizationId}/annualReport/years`, {}, options)
  return data
}

type FetchAnnualReportCreditsResponseData = AnnualReportCredit[]

export const fetchAnnualReportCredits = async (
  organizationId: string,
): Promise<FetchAnnualReportCreditsResponseData> => {
  const url = `/organizations/${organizationId}/annualReport/credits`
  const params = qs.stringify({ unused: '1' })
  const { data } = await request(`${url}?${params}`, {}, options)
  return data
}
