import { Color, Text } from '@design-system'

import React, { ReactElement } from 'react'

import { getClassName } from '../../../../utils/getClassName'
import { FullScreenOverlay } from '../../FullScreenOverlay'
import * as Styled from './styles'

export interface AppLoadingScreenProps {
  text: string
}
export const AppLoadingScreen = ({ text }: AppLoadingScreenProps): ReactElement => {
  return (
    <FullScreenOverlay bgColor={Color.DeepSeaGreen} className={getClassName('app-loading-screen')}>
      <Styled.Logo logo="agerasSimpleElectric" />
      <Text alignment="center" color={Color.White}>
        {text}
      </Text>
    </FullScreenOverlay>
  )
}
