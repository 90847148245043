import { Module } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const InvoiceHtmlPreviewWrapper = styled(Module)`
  width: 834px;
`

export const InvoicePreviewIframe = styled.iframe`
  width: 100%;
  overflow: hidden;

  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `};
`

export const InvoicePreviewSkeletonWrapper = styled.div`
  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `};
`
