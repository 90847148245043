import { FontWeight } from '@design-system'

import colors from '../colors'

export default {
  bottomMenuItem: {
    color: colors.white,
    'a, a:visited': {
      color: `${colors.white} !important`,
    },
  },
  bottomMenuItemLogOut: {
    background: colors.menuBackground,
    '&:hover': {
      background: colors.menuActive,
    },
  },
  bottomMenuItemSettings: {
    background: colors.menuBackground,
    '&:hover, &.active': {
      background: colors.menuActive,
    },
  },
  bottomMenuItemWide: {
    color: colors.white,
    fontWeight: FontWeight.Medium,
    background: colors.menuBackground,
    '&:hover, &.active': {
      background: colors.menuActive,
    },
  },
  bottomMenuHelp: {
    background: colors.menuActive,
  },
}
