import { Input, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, SmartSelect } from '@components-deprecated'
import { Button } from '@design-system'

import { TFunction } from 'i18next'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InboxModalProps } from '../../types'
import { getNeedInfoReasons } from '../../utils'

type MoreInfoModalProps = InboxModalProps &
  ModalProps & {
    onConfirm?: (reason: MoreInfoReasonItem, message?: string) => void
  }

export type MoreInfoReasonItem = {
  id: string
  text: string
}

const initialMoreInfoReason = { id: '', text: '' }

const getSelectItems = (t: TFunction) => {
  const needInfoReasons = getNeedInfoReasons(t)
  const items = []

  for (const key in needInfoReasons) {
    items.push({ id: key, text: needInfoReasons[key] })
  }

  return items
}

export const MoreInfoModal = ({
  disabled,
  isOpen,
  onCancel = () => null,
  onConfirm = () => null,
  ...rest
}: MoreInfoModalProps) => {
  const { t } = useTranslation()
  const [reason, setReason] = useState<MoreInfoReasonItem>(initialMoreInfoReason)
  const [message, setMessage] = useState('')
  const items = useMemo(() => getSelectItems(t), [t])

  const reset = () => {
    setMessage('')
    setReason(initialMoreInfoReason)
  }

  useEffect(() => {
    reset()
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} {...rest} onClose={onCancel}>
      <ModalHeader heading={t('voucher.inbox.modals.moreinfo.request_more_info')} />
      <ModalBody>
        <SmartSelect
          label={t('voucher.inbox.modals.moreinfo.reason')}
          name="reason"
          placeholder={t('voucher.inbox.modals.moreinfo.select_reason')}
          items={items}
          onItemSelect={(reason) => setReason(reason as MoreInfoReasonItem)}
          value={reason?.text}
          disabled={disabled}
          required
        />
        <Input
          name="message"
          label={t('voucher.inbox.modals.moreinfo.message_label')}
          placeholder={t('voucher.inbox.modals.moreinfo.message_placeholder')}
          value={message}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setMessage(event.target.value)}
          disabled={disabled}
        />
      </ModalBody>
      <ModalFooter>
        <Button disabled={disabled} onClick={onCancel} variant="text">
          {t('voucher.inbox.modals.moreinfo.cancel')}
        </Button>
        <Button disabled={!reason.id || disabled} onClick={() => onConfirm(reason, message)}>
          {t('voucher.inbox.modals.moreinfo.moreinfo')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
