import { PermanentlyHiddenWidgetsMap } from '../hooks/usePermanentlyHiddenWidgets'
import { Widget } from '../types/widget'

export const hideWidgetsPermanently = (widgets: Widget[], permanentlyHiddenWidgets: PermanentlyHiddenWidgetsMap) =>
  widgets.map((widget) => {
    const { id } = widget
    const isHidden = permanentlyHiddenWidgets[id]

    if (isHidden !== undefined) {
      return { ...widget, isHidden }
    }

    return widget
  })
