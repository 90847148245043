import { Icon } from '@design-system'

import { ReactElement } from 'react'

import { InvoiceSentState } from '../../../../enums/invoiceSentState'

interface EmailStatusIconProps {
  sentState: InvoiceSentState
}

export const EmailStatusIcon = ({ sentState }: EmailStatusIconProps): ReactElement | null => {
  switch (sentState) {
    case InvoiceSentState.Opened:
    case InvoiceSentState.Printed:
    case InvoiceSentState.Viewed:
      return <Icon icon="envelopeWithCheckmark" />
    case InvoiceSentState.Sent:
      return <Icon icon="envelopeWithArrow" />
    default:
      return null
  }
}
