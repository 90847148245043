import { StatusFormatterVariant } from '@components'

import { InvoiceStatus } from '../../../enums/invoiceStatus'

export const invoiceStatusToStatusFormatterVariant: Record<
  InvoiceStatus,
  StatusFormatterVariant | [StatusFormatterVariant, StatusFormatterVariant]
> = {
  [InvoiceStatus.All]: 'neutral',
  [InvoiceStatus.Draft]: 'primary',
  [InvoiceStatus.Overdue]: 'error',
  [InvoiceStatus.Paid]: 'success',
  [InvoiceStatus.PartiallyPaid]: ['success', 'warning'],
  [InvoiceStatus.Unpaid]: 'warning',
}
