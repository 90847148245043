import { ModalConfirmation, ModalConfirmationProps, notify, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../enums/queryKeys'
import { APIError } from '../../../../../../../../utils'
import { useDeleteUmbrellaUser } from './hooks/useDeleteUmbrellaUser'

interface DeleteUmbrellaUserModalProps extends ModalConfirmationProps {
  umbrellaId: string
  umbrellaUserId: string
}

export const DeleteUmbrellaUserModal = ({
  umbrellaId,
  umbrellaUserId,
  ...props
}: DeleteUmbrellaUserModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeDeleteUmbrellaUserModal } = useModal(props.id)
  const { deleteUmbrellaUser, isProcessing } = useDeleteUmbrellaUser({
    onSuccess: () => {
      closeDeleteUmbrellaUserModal()
      notify({
        id: NotificationKeys.UmbrellaUserDelete,
        message: t('umbrella_users.deleteUserModal.success'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.UmbrellaMembers)
    },
    onError: (error?: APIError) => {
      closeDeleteUmbrellaUserModal()
      notify({
        id: NotificationKeys.UmbrellaUserDelete,
        message: error?.body?.errors[0]?.detail,
        variant: 'error',
      })
    },
  })

  const handleDeleteButtonClick = useCallback(() => {
    deleteUmbrellaUser({ umbrellaId, userId: umbrellaUserId })
  }, [deleteUmbrellaUser, umbrellaId, umbrellaUserId])

  return (
    <ModalConfirmation
      {...props}
      cancelLabel={t('cancel')}
      danger
      message={t('umbrella_users.deleteUserModal.body')}
      okLabel={t('umbrella_users.deleteUserModal.ok')}
      okLoading={isProcessing}
      onOk={handleDeleteButtonClick}
      title={t('umbrella_users.deleteUserModal.title')}
    />
  )
}
