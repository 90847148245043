import React, { ReactElement, ReactNode } from 'react'

import { FlexItem } from './elements/FlexItem'
import * as Styled from './styles'

export interface FlexProps extends Styled.FlexWrapperProps {
  children?: ReactNode
  className?: string
}

export const Flex = ({ as, children, ...rest }: FlexProps): ReactElement => {
  return (
    <Styled.FlexWrapper as={as} {...rest}>
      {children}
    </Styled.FlexWrapper>
  )
}

Flex.Item = FlexItem
