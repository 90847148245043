import { Color, DefaultValue, getDefaultTransition, Spacing, zIndex } from '@design-system'

import styled from '@emotion/styled'
import { transparentize } from 'polished'

interface OverlayProps {
  visible?: boolean
}

export const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.Modal};
  pointer-events: none;
`

interface ModalProps {
  visible?: boolean
  width?: number
}

export const Modal = styled.div<ModalProps>`
  display: flex;
  width: ${({ width }) => (width ? `${width}px` : '576px')};
  max-height: 80vh;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  margin-top: -${Spacing.XL};
  border-radius: ${DefaultValue.BorderRadius};
  flex-direction: column;
  background-color: ${Color.White};
  box-shadow: 0 10px 70px ${transparentize(0.65, Color.Gray200)};
  transform: ${({ visible }) => (visible ? 'scale(1) translateY(0)' : 'scale(0.98) translateY(20px)')};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  ${getDefaultTransition('opacity', 'transform')}
`

export const Overlay = styled.div<OverlayProps>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  top: 0;
  left: 0;
  background-color: ${transparentize(0.6, Color.Gray200)};
  z-index: ${zIndex.ModalOverlay};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  ${getDefaultTransition('opacity')};
`

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${Spacing.L};
  right: ${Spacing.XL};
  cursor: pointer;
`
