import { DateInput, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@components-deprecated'
import { Button } from '@design-system'

import { addMonths } from 'date-fns'
import React, { ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InboxModalProps } from '../../types'

interface FormValues {
  dueDate: Date | null
}

type PostponeModalProps = InboxModalProps &
  ModalProps & {
    onConfirm?: (values: FormValues) => void
    heading?: string
  }

const defaultValues = {
  dueDate: null,
}

export const PostponeModal = ({
  heading,
  disabled,
  isOpen,
  onCancel = () => null,
  onConfirm = () => null,
  ...rest
}: PostponeModalProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, control, formState } = useForm<FormValues>({
    defaultValues,
  })

  const handlePostponeSubmit = (values: FormValues) => {
    onConfirm(values)
  }

  const isDisabled = formState.isSubmitting

  const today = new Date()
  const threeMonthsFromToday = addMonths(new Date(), 3)

  return (
    <Modal isOpen={isOpen} {...rest} onClose={onCancel}>
      <form onSubmit={handleSubmit(handlePostponeSubmit)}>
        <ModalHeader heading={heading || t('voucher.inbox.modals.postpone.header')} />
        <ModalBody>
          <DateInput
            label={t('voucher.inbox.modals.postpone.label')}
            name="dueDate"
            formControl={control}
            minDate={today}
            maxDate={threeMonthsFromToday}
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={isDisabled} onClick={onCancel} variant="text">
            {t('voucher.inbox.modals.postpone.cancel')}
          </Button>
          <Button disabled={isDisabled} type="submit">
            {t('voucher.inbox.modals.postpone.postpone')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
