import { useMutation } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { APIError } from '../../../utils'
import { GetSalaryLoginResponseData, getSalaryLoginUrl } from '../query-api'

interface UseRequestSalaryLoginUrl {
  onError?: (error?: APIError) => void
  onSettled?: (data: GetSalaryLoginResponseData | undefined, error: APIError | null | undefined) => void
  onSuccess?: (data: GetSalaryLoginResponseData) => void
}

export const useRequestSalaryLoginUrl = (props?: UseRequestSalaryLoginUrl) => {
  const { onError, onSuccess, onSettled } = props || {}
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()

  const { mutate: requestLoginUrl, ...rest } = useMutation(
    () => getSalaryLoginUrl(organization?.id as string, encodeURIComponent(user?.email as string)),
    {
      onSuccess,
      onError,
      onSettled,
    },
  )

  return { requestLoginUrl, ...rest }
}
