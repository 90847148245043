import { Badge, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ReceiptsListSideActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-right: auto;
  justify-content: space-between;
`

export const ButtonsGroupWrapper = styled.div`
  margin-left: auto;
`

export const BadgeWrapper = styled(Badge)`
  margin-left: ${Spacing.S};
`
