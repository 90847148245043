import { TableBadgeLabel } from '@components'

import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccountStatements } from '../../hooks/useAccountStatements'

export const AccountStatementsTableTitle = (): ReactElement => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const { accountStatement, isLoading: isAccountStatementsLoading } = useAccountStatements()

  useEffect(() => {
    if (!isAccountStatementsLoading && isLoading) {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountStatementsLoading])

  return (
    <TableBadgeLabel amount={accountStatement?.rows.length} isLoading={isLoading} variant="neutral">
      {t('postings')}
    </TableBadgeLabel>
  )
}
