import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { NavItem } from '../../../NavList'
import { Select } from '../../../Select'
import { SkeletonBox } from '../../../SkeletonBox'
import { availableValues, ItemsPerPage } from '../../hooks/useItemsPerPage'
import * as Styled from './styles'

const selectItems: NavItem<ItemsPerPage>[] = availableValues.map((value) => ({
  children: String(value),
  id: String(value),
  value,
}))

interface TableItemsAmountSelectProps {
  isSkeletonView?: boolean
  itemsPerPage: number
  onChange: (itemsPerPage: ItemsPerPage) => void
}

export const TableItemsAmountSelect = ({
  isSkeletonView,
  itemsPerPage,
  onChange,
}: TableItemsAmountSelectProps): ReactElement => {
  const { t } = useTranslation()

  const handleSelect = useCallback(
    (id?: string, value?: ItemsPerPage) => {
      if (value) {
        onChange(value as ItemsPerPage)
      }
    },
    [onChange],
  )

  return (
    <Styled.TableItemsAmountSelectWrapper>
      {isSkeletonView ? (
        <SkeletonBox height={34} width={140} />
      ) : (
        <>
          <Styled.SelectWrapper>
            <Select
              defaultSelectedId={String(itemsPerPage)}
              items={selectItems}
              onSelect={handleSelect}
              placement="top-start"
              selectedId={String(itemsPerPage)}
              size="m"
              withSearch={false}
            />
          </Styled.SelectWrapper>
          <Styled.TableItemsAmountSelectLabel colorVariant="secondary" selectable={false}>
            {t('table.items_select.label')}
          </Styled.TableItemsAmountSelectLabel>
        </>
      )}
    </Styled.TableItemsAmountSelectWrapper>
  )
}
