import React from 'react'
import { Box } from 'rebass'

import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconInfo = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-info')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15.999">
      <g>
        <g>
          <path
            d="M15.724,5.931A8,8,0,0,0,5.931.276,8,8,0,0,0,.276,10.07,8.005,8.005,0,0,0,7.988,16a8.064,8.064,0,0,0,2.082-.275,8,8,0,0,0,5.654-9.793Zm-1.986,5.381A6.626,6.626,0,1,1,6.285,1.6a6.668,6.668,0,0,1,1.726-.229,6.628,6.628,0,0,1,5.727,9.941Z"
            fill={color}
          />
          <path
            d="M10.217,11.836c-.381,1.471-4.248,1.58-3.976-.381.163-1.307.436-2.56.653-3.867.11-.817-.435-.544-.871-.708-.326-.163-.326-.544.055-.6a23.153,23.153,0,0,1,2.777-.054c.327,0,.49.163.49.436a1.746,1.746,0,0,1-.054.762c-.218,1.253-.544,2.56-.653,3.759-.055.273,0,.763.381.763a4.1,4.1,0,0,0,.871-.327c.217-.109.326.054.326.218Z"
            fill={color}
            fillRule="evenodd"
          />
          <path
            d="M8.311,3.012A1.29,1.29,0,0,1,9.618,4.319,1.258,1.258,0,0,1,8.311,5.626a1.307,1.307,0,0,1,0-2.614Z"
            fill={color}
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  </Box>
)
