import { Checkbox } from '@design-system'

import { ChangeEvent, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useTransactionsListFilters } from '../../hooks/useTransactionsListFilters'
import * as Styled from './styles'

export const TransactionsVoidedFilter = (): ReactElement => {
  const { t } = useTranslation()
  const [{ withVoided }, setFilters] = useTransactionsListFilters()

  const handleChecked = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked

      setFilters({ withVoided: checked })
    },
    [setFilters],
  )

  return (
    <Styled.TransactionsVoidedFilterWrapper>
      <Checkbox name="withVoided" checked={withVoided} onChange={handleChecked}>
        {t('transactions.table_actions.show_voided')}
      </Checkbox>
    </Styled.TransactionsVoidedFilterWrapper>
  )
}
