import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { getShadow } from '../../../utils/getShadow'

export const getErrorStyle = (bordered?: boolean): SerializedStyles => css`
  border-color: ${Color.Red};
  background-color: ${Color.Red5};
  box-shadow: ${getShadow(undefined, {
    extraShadowType: bordered ? 'raisedLightInner' : undefined,
  })};
`
