/*
 *   Code of this modal is also used in the CreateOrEditVatRateFromTemplateModal
 */
import { Modal, ModalProps, withModalConditionalRender } from '@design-system'

import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { VatRateType } from '../shared'
import { CreateOrEditVatRateModalContextProvider } from './context/createOrEditVatRateModalContext'
import { CreateOrEditVatRateForm } from './elements/CreateOrEditVatRateForm'
import { ModalContent } from './elements/ModalContent'
import { ModalFooter } from './elements/ModalFooter'

interface CreateOrEditVatRateModalProps extends ModalProps {
  vatRateId?: string
  disabled?: boolean
  appliesTo?: VatRateType
}

export const CreateOrEditVatRateModal = withModalConditionalRender(
  ({ vatRateId, disabled, appliesTo, ...modalProps }: CreateOrEditVatRateModalProps): ReactElement => {
    const { t } = useTranslation()
    const modalBodyRef = useRef<HTMLDivElement>(null)
    const [scrollContainerElement, setScrollContainerElement] = useState<HTMLDivElement>()
    const isEditMode = !!vatRateId

    // Bug workaround
    // https://github.com/atlassian/react-beautiful-dnd/issues/1881
    // Ref to scrollable container (modal body) is needed to calculate proper position of dragged element
    // requestAnimationFrame is needed to wait until element inside of ref is rendered
    useEffect(() => {
      const frameId = requestAnimationFrame(() => {
        if (modalBodyRef.current) {
          setScrollContainerElement(modalBodyRef.current)
        }
      })

      return () => {
        cancelAnimationFrame(frameId)
        setScrollContainerElement(undefined)
      }
    }, [])

    return (
      <CreateOrEditVatRateModalContextProvider
        scrollContainerElement={scrollContainerElement}
        modalId={modalProps.id}
        vatRateId={vatRateId}
        disabled={disabled}
        appliesTo={appliesTo}
      >
        <Modal {...modalProps} closable autofocusOnInput>
          <Modal.Header
            title={t(
              isEditMode
                ? 'settings.organization.vat_rates.modals.edit_vat_rate.title'
                : 'settings.organization.vat_rates.modals.create_vat_rate.title',
            )}
          />
          <Modal.Body ref={modalBodyRef}>
            <ModalContent>
              <CreateOrEditVatRateForm />
            </ModalContent>
          </Modal.Body>
          <Modal.Footer>
            <ModalFooter />
          </Modal.Footer>
        </Modal>
      </CreateOrEditVatRateModalContextProvider>
    )
  },
)
