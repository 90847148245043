import { boolean, object, SchemaOf, string } from 'yup'

import { UmbrellaOrganization } from '@modules-deprecated/app/organizations/types'

export interface UmbrellaChangeSubscriptionForm {
  consolidatedBilling: boolean
  subscriptionProductPlan: string | undefined
}

export type UmbrellaChangeSubscriptionFormSchema = SchemaOf<UmbrellaChangeSubscriptionForm>

export const getValidationSchema = (): UmbrellaChangeSubscriptionFormSchema =>
  object({
    consolidatedBilling: boolean().required(),
    subscriptionProductPlan: string(),
  })

export const getDefaultValues = (organization?: UmbrellaOrganization): UmbrellaChangeSubscriptionForm => {
  return {
    consolidatedBilling: organization?.consolidatedBilling || false,
    subscriptionProductPlan: organization?.subscriptionProductPlan || '',
  }
}
