import { Button, ButtonsGroup, Icon, Modal, ModalProps, Space, Text, useModal } from '@design-system'

import React, { ReactElement, ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useCopyAndNotify } from '../../../hooks/useCopyAndNotify'
import * as Styled from './styles'

export interface ErrorStacktraceModalProps extends Omit<ModalProps, 'closable' | 'children'> {
  description?: ReactNode
  stackTrace?: string
  stackTraceTitle?: ReactNode
  title?: ReactNode
}

export const ErrorStacktraceModal = ({
  description,
  id,
  stackTrace,
  stackTraceTitle,
  title,
  ...rest
}: ErrorStacktraceModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close } = useModal(id)
  const { copy } = useCopyAndNotify()

  const handleCancelClick = useCallback(() => {
    close()
  }, [close])

  const handleCopyButtonClick = useCallback(() => {
    if (stackTrace) {
      copy(stackTrace)
    }
  }, [copy, stackTrace])

  return (
    <Modal id={id} closable size="s" {...rest}>
      <Modal.Header title={title || t('modal.error_stack_trace.title')} />
      <Modal.Body>
        <Text>{description || t('modal.error_stack_trace.description')}</Text>
        <Space size="l" />

        <Styled.HeaderWrapper>
          <Icon icon="exclamationMarkCircle" />
          <Space size="s" horizontal />
          <Text variant="large" weight="medium">
            {stackTraceTitle || t('modal.error_stack_trace.stack_trace_title')}
          </Text>
        </Styled.HeaderWrapper>

        <Space size="s" />
        <Styled.Textarea readOnly resizable={false} rows={8} spellCheck="false" value={stackTrace} />
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={handleCancelClick}>
            {t('cancel')}
          </Button>
          <Button icon="twoSquares" onClick={handleCopyButtonClick}>
            {t('modal.error_stack_trace.copy_to_clipboard')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
