import { SettingsSection } from '@components'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { DeleteIconModal } from './elements/DeleteIconModal'
import { OrganizationIcon } from './elements/OrganizationIcon'
import * as Styled from './styles'

export const OrganizationIconSection = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <>
      <SettingsSection.Content
        title={t('settings.organization.organization_info.icon.title')}
        subtitle={t('settings.organization.organization_info.icon.subtitle')}
      >
        <Styled.IconWrapper>
          <OrganizationIcon />
        </Styled.IconWrapper>
      </SettingsSection.Content>
      <DeleteIconModal id={ModalId.DeleteOrganizationIconModal} />
    </>
  )
}
