import { Color, PORTAL_CLASS_NAME } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'
import ReactDOM from 'react-dom'

import * as Styled from './styles'

export interface FullScreenOverlayProps {
  children: ReactNode
  bgColor: Color
  bgOpacity?: number
  className?: string
}

export const FullScreenOverlay = ({
  children,
  className,
  bgColor,
  bgOpacity = 1,
}: FullScreenOverlayProps): ReactElement => {
  return ReactDOM.createPortal(
    <Styled.FullScreenOverlayWrapper
      className={`${PORTAL_CLASS_NAME} ${className || ''}`}
      bgColor={bgColor}
      bgOpacity={bgOpacity}
    >
      {children}
    </Styled.FullScreenOverlayWrapper>,
    document.body,
  )
}
