import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const BadgeLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const TextLabel = styled(Text)`
  margin-right: ${Spacing.S};
`
