import { DateInput } from '@design-system'

import { ReactElement, useCallback } from 'react'

import { useContactBalanceListFilters } from '../../hooks/useContactBalanceListFilters'
import { getQueryDate } from '../../utils/getQueryDate'
import * as Styled from './styles'

export const ContactBalanceDateSelector = (): ReactElement => {
  const [{ date }, setFilters] = useContactBalanceListFilters()

  const handleChange = useCallback(
    (dateToChange?: Date) => {
      setFilters({
        page: 1,
        date: dateToChange ? getQueryDate(dateToChange) : undefined,
      })
    },
    [setFilters],
  )

  return (
    <Styled.ContactBalanceDateSelectorWrapper>
      <DateInput value={date ? new Date(date) : undefined} onChange={handleChange} />
    </Styled.ContactBalanceDateSelectorWrapper>
  )
}
