import { Link } from '@design-system'

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { TrustpilotRating } from './elements/trustpilotRating'
import * as Styled from './styles'

export function ContactSupportFooter() {
  const { t } = useTranslation()
  return (
    <Styled.Container>
      <Styled.TextContainer>
        <Styled.SupportText>{t('need_help')}</Styled.SupportText>
        <Styled.SupportLink>
          <Link href="mailto:billy@billy.dk" withAnimation>
            {t('contact.support')}
          </Link>
        </Styled.SupportLink>
      </Styled.TextContainer>

      <TrustpilotRating />
    </Styled.Container>
  )
}
