import React from 'react'

import { IconProps } from '../Icon'

export const IconTrustPilotStars = ({
  color = '#00B67A',
  color2 = '#F2EFEA',
  width = '108px',
  height = '21px',
  bg = '#ffffff',
}: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 108 21" fill={bg} xmlns="http://www.w3.org/2000/svg">
    <path d="M20.25 0H0V20.25H20.25V0Z" fill={color} />
    <path d="M42.1875 0H21.9375V20.25H42.1875V0Z" fill={color} />
    <path d="M64.125 0H43.875V20.25H64.125V0Z" fill={color} />
    <path d="M86.0625 0H65.8125V20.25H86.0625V0Z" fill={color} />
    <path d="M108 0H87.75V20.25H108V0Z" fill={color2} />
    <path d="M108 0H87.7046V20.2953H108V0Z" fill={color} />
    <path
      d="M10.1251 13.6476L13.2048 12.8672L14.4915 16.8328L10.1251 13.6476ZM17.2126 8.52186H11.7915L10.1251 3.41718L8.45869 8.52186H3.0376L7.4251 11.6859L5.75869 16.7906L10.1462 13.6266L12.8462 11.6859L17.2126 8.52186Z"
      fill="white"
    />
    <path
      d="M32.0626 13.6476L35.1423 12.8672L36.429 16.8328L32.0626 13.6476ZM39.1501 8.52186H33.729L32.0626 3.41718L30.3962 8.52186H24.9751L29.3626 11.6859L27.6962 16.7906L32.0837 13.6266L34.7837 11.6859L39.1501 8.52186Z"
      fill="white"
    />
    <path
      d="M54.0001 13.6476L57.0798 12.8672L58.3665 16.8328L54.0001 13.6476ZM61.0876 8.52186H55.6665L54.0001 3.41718L52.3337 8.52186H46.9126L51.3001 11.6859L49.6337 16.7906L54.0212 13.6266L56.7212 11.6859L61.0876 8.52186Z"
      fill="white"
    />
    <path
      d="M75.9376 13.6476L79.0173 12.8672L80.304 16.8328L75.9376 13.6476ZM83.0251 8.52186H77.604L75.9376 3.41718L74.2712 8.52186H68.8501L73.2376 11.6859L71.5712 16.7906L75.9587 13.6266L78.6587 11.6859L83.0251 8.52186Z"
      fill="white"
    />
    <path
      d="M97.8751 13.6476L100.955 12.8672L102.242 16.8328L97.8751 13.6476ZM104.963 8.52186H99.5415L97.8751 3.41718L96.2087 8.52186H90.7876L95.1751 11.6859L93.5087 16.7906L97.8962 13.6266L100.596 11.6859L104.963 8.52186Z"
      fill="white"
    />
  </svg>
)
