import { NavItem } from '@design-system'

export const getSelectedMenuItemId = (
  items: NavItem[],
  currentPathname: string,
  isUmbrella: boolean,
): string | undefined => {
  const submenus = items.flatMap((item) => item.subItems).filter((item) => item !== undefined)
  const selectedItem = [...submenus, ...items].find((item) => {
    return item?.to && currentPathname.startsWith(item.to)
  })

  return selectedItem?.id
}
