import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchBankLinesForAccount } from '../query-api'

export const useAccountBankLines = (accountId: string, isEnabled = true) => {
  const { data, isLoading } = useQuery([QueryKeys.BankLines, accountId], () => fetchBankLinesForAccount(accountId), {
    enabled: isEnabled && !!accountId,
  })

  return {
    bankLines: data?.bankLines,
    isLoading,
  }
}
