import { SkeletonBox, Text } from '@design-system'

import capitalize from 'lodash/capitalize'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface SubscriptionDetailsProps {
  isAnnualPayment?: boolean
  isLoading?: boolean
  price?: number
  subscriptionName?: string
  withPrice?: boolean
}

export const SubscriptionDetails = ({
  isAnnualPayment,
  isLoading,
  price,
  subscriptionName = '',
  withPrice,
}: SubscriptionDetailsProps): ReactElement => {
  const { t } = useTranslation()

  const formattedSubscriptionName = capitalize(subscriptionName)
  const paymentInterval = isAnnualPayment
    ? t('organization_subscription.summary_yearly')
    : t('organization_subscription.summary_monthly')

  return (
    <Styled.SubscriptionDetailsWrapper>
      <Styled.Section>
        {isLoading ? (
          <SkeletonBox fullWidth />
        ) : (
          <>
            <Text colorVariant="secondary">{t('organization_subscription.summary_current_plan')}</Text>
            <Text alignment="right" colorVariant="primary" weight="medium">
              {formattedSubscriptionName}
            </Text>
          </>
        )}
      </Styled.Section>
      {withPrice && (
        <Styled.Section>
          <Text colorVariant="secondary">
            {t('organization_subscription.summary_price', { interval: paymentInterval })}
          </Text>
          <Text alignment="right" colorVariant="primary" weight="medium">
            {price} DKK
          </Text>
        </Styled.Section>
      )}
    </Styled.SubscriptionDetailsWrapper>
  )
}
