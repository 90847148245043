import { useModal } from '@design-system'

import React, { ReactElement, useCallback, useState } from 'react'

import { ModalId } from '../../../../../../../../enums/modalId'
import { SimpleActor } from '../../../../types/simpleActor'
import { CreateOrEditAccessTokenModal } from '../../../CreateOrEditAccessTokenModal'
import { ViewAccessTokenModal } from '../../../ViewAccessTokenModal'

interface AccessTokenModalsGroupProps {
  actor?: SimpleActor
}

export const AccessTokenModalsGroup = ({ actor }: AccessTokenModalsGroupProps): ReactElement => {
  const [actorId, setActorId] = useState('')
  const { open: openViewAccessTokenModal } = useModal(ModalId.ViewAccessTokenModal, {
    onClose: () => {
      setActorId('')
    },
  })

  const handleCreateActorSuccess = useCallback(
    (id: string | undefined) => {
      setActorId(id || '')
      openViewAccessTokenModal()
    },
    [openViewAccessTokenModal],
  )

  return (
    <>
      <CreateOrEditAccessTokenModal actor={actor} onSuccessCreate={handleCreateActorSuccess} />
      <ViewAccessTokenModal actorId={actorId || actor?.id} />
    </>
  )
}
