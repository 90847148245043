import { Company } from '../types/company'

// order is important
export const companies = ['emv', 'is', 'aps', 'as', 'other', 'foreign'] as const

export const companyToTranslationKey: Record<Company, string> = {
  emv: 'umbrella.services.company.emv',
  is: 'umbrella.services.company.is',
  aps: 'umbrella.services.company.aps',
  as: 'umbrella.services.company.as',
  other: 'umbrella.services.company.other',
  foreign: 'umbrella.services.company.foreign',
}
