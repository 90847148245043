import { Button, ModuleLayout, Tooltip } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { usePaymentMethod } from '../umbrellaSubscriptions/hooks/usePaymentMethod'
import { UmbrellaOrganizationsInvitationsSection } from './elements/UmbrellaOrganizationsInvitationsSection'
import { UmbrellaOrganizationsListSection } from './elements/UmbrellaOrganizationsListSection'

export const UmbrellaOrganizations = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { umbrella } = useUmbrella()
  const { data } = usePaymentMethod()

  const handleCreateOrganizationClick = useCallback(() => {
    navigate(EmberRoute.SignupGuide, { queryParams: { umbrellaId: umbrella?.id } })
  }, [navigate, umbrella?.id])

  const isButtonDisabled = useMemo(() => {
    const hasAddress = !!(
      umbrella &&
      umbrella.address.country &&
      umbrella.address.city &&
      umbrella.address.street &&
      umbrella.address.zipcode
    )
    const hasPayment = (umbrella && umbrella.billingType === 'bankPayment') || !!(data && data.ID)

    return !hasAddress || !umbrella?.registrationNo || !hasPayment
  }, [umbrella, data])

  const createOrganizationButton = useMemo(() => {
    const button = (
      <Button variant="primary" onClick={handleCreateOrganizationClick} icon="plusCircle" disabled={isButtonDisabled}>
        {t('umbrella_organizations.create_organization')}
      </Button>
    )
    return isButtonDisabled ? <Tooltip.DisabledWrapper>{button}</Tooltip.DisabledWrapper> : button
  }, [isButtonDisabled, handleCreateOrganizationClick, t])

  return (
    <ModuleLayout
      title={t('umbrella_organizations.title')}
      sideActions={
        <Tooltip label={t('umbrella_organizations.missing_card_cvr_or_address')} forceHidden={!isButtonDisabled}>
          {createOrganizationButton}
        </Tooltip>
      }
    >
      <UmbrellaOrganizationsInvitationsSection />
      <UmbrellaOrganizationsListSection />
    </ModuleLayout>
  )
}
