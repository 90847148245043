import { FormItem, FormItemsGroup, Link, SkeletonText, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { getContactFromBill } from '../../../../utils/getContactFromBill'
import { useBillView } from '../../contexts/billViewContext'

export const BillVendor = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { billData, isFetching, isFetchingInvalidation } = useBillView()
  const { bill, contacts } = billData || {}

  const vendor = bill ? getContactFromBill(bill, contacts) : undefined
  const isLoading = isFetching || (!vendor && isFetchingInvalidation)

  const handleLinkClick = useCallback(() => {
    navigate(EmberRoute.ContactsSupplier, vendor?.id)
  }, [navigate, vendor?.id])

  return (
    <FormItemsGroup>
      <FormItem label={t('bill.view.form.vendor')}>
        {isLoading ? (
          <SkeletonText variant="h3" width={100} />
        ) : (
          <Text variant="h3">
            <Link withAnimation onClick={handleLinkClick}>
              {vendor?.name}
            </Link>
          </Text>
        )}
      </FormItem>
    </FormItemsGroup>
  )
}
