import React, { ChangeEvent, ReactElement } from 'react'

import { Spacing } from '../../enums/spacing'
import { useMultipleSelectableValues } from '../../hooks/useMultipleSelectableValues'
import { ComponentsGroupProps } from '../ComponentsGroup'
import { SwitchGroupProvider } from './context/switchGroupContext'
import * as Styled from './styles'

export interface SwitchGroupProps extends Pick<ComponentsGroupProps, 'children' | 'direction' | 'spacing'> {
  name?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onChangeMultiple?: (value: string[]) => void
  value?: string | string[]
  defaultValue?: string | string[]
  disabled?: boolean
}

export const SwitchGroup = ({
  children,
  defaultValue,
  disabled,
  name,
  onChange,
  onChangeMultiple,
  spacing = Spacing.S,
  value,
  ...props
}: SwitchGroupProps): ReactElement => {
  const { checkedValue, groupName, handleComponentChange } = useMultipleSelectableValues({
    defaultValue,
    name,
    onChange,
    onChangeMultiple,
    value,
  })

  return (
    <SwitchGroupProvider disabled={disabled} name={groupName} value={checkedValue} onChange={handleComponentChange}>
      <Styled.SwitchGroupWrapper {...props} spacing={spacing}>
        {children}
      </Styled.SwitchGroupWrapper>
    </SwitchGroupProvider>
  )
}
