import { Icon, IconName, Space, Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import { RadioButtonSection, RadioButtonSectionProps } from '../RadioButtonSection'
import * as Styled from './styles'

interface RadioButtonSectionWithHeaderProps<T = string> extends RadioButtonSectionProps<T> {
  heading: ReactNode
  icon: IconName
}

export const RadioButtonSectionWithHeader = <T extends string>({
  heading,
  icon,
  ...props
}: RadioButtonSectionWithHeaderProps<T>): ReactElement => {
  return (
    <>
      <Styled.HeadingIconWrapper>
        <Icon icon={icon} />
        <Space size="s" horizontal />
        <Text variant="large">{heading}</Text>
      </Styled.HeadingIconWrapper>
      <RadioButtonSection {...props} />
    </>
  )
}
