import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ResultsFooterWrapper = styled.footer`
  display: flex;
  width: 100%;
  height: 56px;
  padding: ${Spacing.L};
  border-top: 1px solid ${Color.Gray30};
  border-radius: 0 0 ${DefaultValue.BorderRadius} ${DefaultValue.BorderRadius};
  align-items: center;
  background-color: ${Color.Gray20};
`

export const ShortcursList = styled.ul`
  display: flex;
`

export const ShortcutItem = styled.li`
  display: flex;

  & + & {
    margin-left: ${Spacing.XL};
  }
`

export const ShortcutLabelWrapper = styled.div`
  margin-left: ${Spacing.S};
`
