import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { SettingsRoute } from '@views/settings/enums/settingsRoute'
import { UmbrellaRoute } from '@views/umbrella/enums/UmbrellaRoute'

import { CustomEvent } from '../enums/customEvent'
import { CustomEventType, useCustomEventListener } from './useCustomEventListener'

export interface CustomEventDetail {
  route: UmbrellaRoute | SettingsRoute
}

export const useCustomEventsRoutes = () => {
  const history = useHistory()
  const location = useLocation()

  // This hook should be used in views using HashRouter
  // If it is run in context of HashRouter then key property should be undefined
  // If it is run in context of Router or BrowserRouter then key property should be string
  if (typeof location?.key === 'string') {
    throw new Error(
      "useCustomEventsRoutes should be used in views using HashRouter. You don't need it in View Container.",
    )
  }

  const handleRouteLinkClick = useCallback(
    (event: CustomEventType<CustomEventDetail>) => {
      const route = event.detail?.route

      if (route) {
        history.push(route)
      }
    },
    [history],
  )

  useCustomEventListener(CustomEvent.RoutingLinkClicked, handleRouteLinkClick)
}
