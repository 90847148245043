import { Spacing, Text as TextComponent } from '@design-system'

import styled from '@emotion/styled'

import { AvatarImage as AvatarImageComponent } from './AvatarImage'

export const MenuAccountSelectorWrapper = styled.div`
  margin: ${Spacing.L} 0;
`

export const ButtonContent = styled.span`
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  flex-direction: column;
`

export const Text = styled(TextComponent)`
  width: 100%;
  text-align: left;
`

export const AvatarImage = styled(AvatarImageComponent)`
  width: 32px;
  height: 32px;
  margin-right: ${Spacing.S};
  padding: 0;
`
