import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { BankLineMatchesQueryParams, fetchBillSubjectAssociations } from '../query-api'

interface UseBillSubjectAssociationsProps extends Omit<BankLineMatchesQueryParams, 'accountId'> {
  billId?: string
}

export const useBillSubjectAssociations = ({ billId }: UseBillSubjectAssociationsProps) => {
  const { data: billSubjectAssociations, isLoading } = useQuery(
    [QueryKeys.BillSubjectAssociations, billId],
    () => fetchBillSubjectAssociations(billId as string),
    {
      enabled: !!billId,
    },
  )

  return {
    billSubjectAssociations: billSubjectAssociations?.bankLineSubjectAssociations || [],
    isLoading,
  }
}
