import { Locale } from '@design-system'

import qs from 'qs'

import { getRequest, postRequest } from '../../../utils'
import { BookkeepingTag } from './types/bookkeepingTag'
import { ScannedCategory } from './types/trainingData'

const BOOKY_SEARCH_URL = window.ENV?.BOOKY_SEARCH_URL || process.env.BOOKY_SEARCH_URL

export type FetchTagsResponseData = BookkeepingTag[]

export interface FetchTagsOptions {
  accountType?: string
  countryId?: string
  locale?: string
  searchQuery?: string
}

interface CategorySuggestion {
  name: string
  id: number
  score: number
}

/* eslint-disable @typescript-eslint/naming-convention */
interface SuggestionMeta {
  user_id: string
  file_id: string | null
  category_id: number | null
}
/* eslint-enable @typescript-eslint/naming-convention */

/* eslint-disable @typescript-eslint/naming-convention */
export interface FetchCategorySuggestionsOptions {
  session_id: string
  text_input: string
  contact_vat_number: string
  contact_reg_number: string
  contact_country: string
  user_locale: Locale
  organization_id: string
  organisation_country_code: string
  scanned_category_result: ScannedCategory[] | null
  meta: SuggestionMeta
}
/* eslint-enable @typescript-eslint/naming-convention */

/* eslint-disable @typescript-eslint/naming-convention */
interface FetchCategorySuggestionsResponseData {
  query_id: string
  suggested: CategorySuggestion[]
}
/* eslint-enable @typescript-eslint/naming-convention */

export const fetchCategorySuggestions = (
  options: FetchCategorySuggestionsOptions,
): Promise<FetchCategorySuggestionsResponseData> => {
  return postRequest(`${BOOKY_SEARCH_URL}/category-search/search/`, options)
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface LogSearchCategoryParams {
  category_id: number | null
  event_type: string
  query_id: string
  selected_index: number | null
  session_id: string
}
/* eslint-enable @typescript-eslint/naming-convention */

export const logSearchCategory = (options: LogSearchCategoryParams): Promise<FetchCategorySuggestionsResponseData> => {
  return postRequest(`${BOOKY_SEARCH_URL}/category-search/events/`, options)
}

export const fetchTags = ({
  accountType,
  countryId,
  locale,
  searchQuery,
}: FetchTagsOptions): Promise<FetchTagsResponseData> => {
  const queryParams = qs.stringify({
    ...(accountType ? { accountType } : {}),
    ...(countryId ? { countryId } : {}),
    ...(locale ? { locale } : {}),
    ...(searchQuery ? { q: searchQuery } : {}),
  })

  return getRequest(`/integrations/bookkeepingtag/search?${queryParams}`)
}

export interface FetchTagOptions {
  accountType?: string
  countryId?: string
  id: string
  locale?: string
}

export type FetchTagResponseData = BookkeepingTag

export const fetchTag = ({ id, locale, accountType, countryId }: FetchTagOptions): Promise<FetchTagResponseData> => {
  const queryParams = qs.stringify({
    ...(accountType ? { accountType } : {}),
    ...(countryId ? { countryId } : {}),
    ...(locale ? { locale } : {}),
  })

  return getRequest(`/integrations/bookkeepingtag/tag/${id}?${queryParams}`)
}

interface FetchCategoriesOptions {
  categoryId?: number | number[]
  locale?: string
  accountType?: string
  countryId?: string
}

export const fetchCategories = ({
  categoryId: ids,
  locale,
  accountType,
  countryId,
}: FetchCategoriesOptions): Promise<BookkeepingTag[]> => {
  const idsString = Array.isArray(ids) ? ids.join(',') : ids
  const queryParams = qs.stringify({
    ...(idsString ? { ids: idsString } : {}),
    ...(locale ? { locale } : {}),
    ...(countryId ? { countryId } : {}),
    ...(accountType ? { accountType } : {}),
  })

  return getRequest(`/integrations/bookkeepingtag/categories?${queryParams}`)
}
