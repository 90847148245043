import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ReceiptsLeftActionsWrapper = styled.div`
  display: flex;
  margin-right: auto;
  padding-right: ${Spacing.XL};
  align-items: center;
`
