import { Button as ButtonComponent, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SalaryConnectWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const IllustrationWrapper = styled.figure`
  display: flex;
  margin-bottom: ${Spacing.XXXL};
  justify-content: center;
`

export const Illustration = styled.img`
  width: 262px;
  height: 207px;
`

export const ContentWrapper = styled.section`
  max-width: 652px;
  margin-bottom: ${Spacing.XXL};
`

export const TitleWrapper = styled.header`
  margin-bottom: ${Spacing.L};
`

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
`
export const Button = styled(ButtonComponent)`
  width: 300px;
  text-align: center;
`
