import styled from '@emotion/styled'

import { Spacing } from '../../../../../../enums/spacing'

export const MonthHeaderActionsWrapper = styled.header`
  display: flex;
  margin-bottom: ${Spacing.XL};
`

export const MonthsNavigation = styled.div`
  display: flex;
  margin-right: ${Spacing.L};
  align-items: center;
`

export const MonthNameWrapper = styled.div`
  width: 95px;
`

export const YearSelectWrapper = styled.div`
  flex: 1;
`
