import { TFunction } from 'i18next'
import { object, SchemaOf, string } from 'yup'

import { User } from '@modules-deprecated/app/user'

export interface UserProfilePictureForm {
  profilePicFileId: string | undefined
}

export type UserProfileFormSchema = SchemaOf<UserProfilePictureForm>

export const getValidationSchema = (t: TFunction): UserProfileFormSchema =>
  object({
    profilePicFileId: string().optional(),
  })

export const getDefaultValues = (user?: User): UserProfilePictureForm => ({
  profilePicFileId: user?.profilePicFileId || undefined,
})
