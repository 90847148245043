import { DefaultValue, getShadow, IconButton, Spacing } from '@design-system'

import { css } from '@emotion/core'
import React, { useRef, useState } from 'react'
import { Box, Flex, FlexProps } from 'rebass'

import { useOnClickOutside } from '../../../hooks'
import { reactClass } from '../../../utils'

type DatePeriodChooserDropdownButtonProps = FlexProps & {
  disabled?: boolean
}

/**
 * This is just a temporary component in order to be able to implement and test the API backend
 */
export const DatePeriodChooserDropdownButton = (
  props: React.PropsWithChildren<DatePeriodChooserDropdownButtonProps>,
) => {
  const { disabled, children, ...rest } = {
    dropdownOrigin: 'left',
    ...props,
  }

  const [open, setOpen] = useState(false)
  const buttonRef = useRef<HTMLDivElement | null>(null)

  const handleOpen = () => {
    if (!disabled) {
      setOpen(!open)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  useOnClickOutside(buttonRef, handleClose)

  return (
    <Flex
      ref={buttonRef}
      className={reactClass('filter-dropdown-button')}
      justifyContent={'flex-end'}
      css={css`
        position: relative;
      `}
      {...rest}
    >
      <Box onClick={handleOpen}>
        <IconButton icon="calendar" />
      </Box>
      <Flex
        css={css`
          position: absolute;
          top: 32px;
          right: 0px;
          min-width: 300px;
          padding: ${Spacing.L};
          flex-direction: column;
          align-items: stretch;
          justify-items: flex-start;
          background-color: white;
          border-radius: ${DefaultValue.BorderRadius};
          z-index: 104;
          opacity: ${open ? 1 : 0};
          pointer-events: ${open ? 'auto' : 'none'};
          ${getShadow('elevatedStrong')};
        `}
      >
        {children}
      </Flex>
    </Flex>
  )
}
