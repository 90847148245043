import React, { ReactElement } from 'react'

import * as Styled from './styles'

export interface SkeletonCircleProps {
  size?: number
}

export const SkeletonCircle = ({ size = 160, ...props }: SkeletonCircleProps): ReactElement => {
  return <Styled.SkeletonCircle {...props} height={size} width={size} />
}
