import { Badge, badgeVariantStyles, Text } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useLocale } from '../../../../../../hooks/useLocale'
import { formatDate } from '../../../../../../utils/date-fns'
import { PayrollStatus } from '../../../../types/payrollStatus'
import { statusToBadgeVariant } from '../../utils/statusToBadgeVariant'
import { getStatusToLabel } from '../../utils/statusToLabel'

interface PayrollStatusFormatterProps {
  status: PayrollStatus
  approvalDeadline?: string
}

export const PayrollStatusFormatter = ({ approvalDeadline, status }: PayrollStatusFormatterProps): ReactElement => {
  const { t } = useTranslation()
  const { code } = useLocale()

  const getAdditionalInfo = useCallback(() => {
    if (status === PayrollStatus.AwaitingApproval && approvalDeadline) {
      const deadlineDate = formatDate(approvalDeadline, 'd. MMM hh:mm', code)
      return `(${t('salary.status.no_later_than', { deadlineDate })})`
    }

    return ''
  }, [approvalDeadline, code, status, t])

  const statusBadgeLabelMap = useMemo(() => getStatusToLabel(t), [t])
  const badgeVariant = statusToBadgeVariant[status]
  const badgeLabel = statusBadgeLabelMap[status]
  const additionalInfo = getAdditionalInfo()
  const additionalInfoColor = badgeVariantStyles[badgeVariant].textColor

  return (
    <>
      <Badge variant={badgeVariant}>{badgeLabel}</Badge>

      {additionalInfo && (
        <Text variant="small" color={additionalInfoColor}>
          {additionalInfo}
        </Text>
      )}
    </>
  )
}
