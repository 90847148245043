import { AllowedField } from '../types/types'

export const getEditAccountAllowedFields = (isPredefinedAccount?: boolean): AllowedField[] => {
  const allowedFields: AllowedField[] = [
    'accountName',
    'bank',
    'bankAccountNo',
    'bankIban',
    'bankSwift',
    'currency',
    'description',
    'isBankAccount',
    'isPaymentEnabled',
    'routingNumber',
  ]

  if (!isPredefinedAccount) {
    allowedFields.push('accountNo')
  }

  return allowedFields
}
