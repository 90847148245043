import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import { useCallback, useEffect } from 'react'

const DEBOUNCE_TIMEOUT_MS = 1000
const THROTTLE_TIMEOUT_MS = 200

interface UseDetectScrollEndPosition {
  enabled?: boolean
  onScrollEnd: () => void
  scrollableElement?: HTMLUListElement
  /**
   * fire event X pixels before scroll ends (NOTE: event could be fired several times as it checks >= scrollHeight,
   * so remember to set 'enabled' to false, so detection will be suspended)
   */
  scrollEndOffset?: number
}

export const useDetectScrollEndPosition = ({
  enabled = false,
  onScrollEnd,
  scrollableElement,
  scrollEndOffset = 0,
}: UseDetectScrollEndPosition) => {
  const onScrollEndDebounced = debounce(onScrollEnd, DEBOUNCE_TIMEOUT_MS, { leading: true })

  const checkScrollPosition = useCallback(() => {
    if (!scrollableElement) {
      return
    }

    const el = scrollableElement
    const { scrollTop, scrollHeight } = el
    const { height } = el.getBoundingClientRect()

    if (scrollTop + height + scrollEndOffset >= scrollHeight) {
      onScrollEndDebounced()
    }
  }, [scrollableElement, scrollEndOffset, onScrollEndDebounced])

  const checkScrollPositionThrottled = throttle(checkScrollPosition, THROTTLE_TIMEOUT_MS)

  useEffect(() => {
    const addListener = () => {
      scrollableElement?.addEventListener('scroll', checkScrollPositionThrottled)
    }

    const removeListener = () => {
      scrollableElement?.removeEventListener('scroll', checkScrollPositionThrottled)
    }

    if (enabled) {
      addListener()
    } else {
      removeListener()
    }

    return () => {
      removeListener()
    }
  }, [enabled, checkScrollPositionThrottled, scrollableElement])
}
