import React, { Children, cloneElement, isValidElement, ReactElement, ReactNode } from 'react'

import { TabButton, TabButtonProps, TabButtonSize } from './elements/TabButton'
import * as Styled from './styles'

export interface TabButtonsGroupProps {
  children?: ReactNode
  size?: TabButtonSize
  fitToWidth?: boolean
  fullWidth?: boolean
}

export const TabButtonsGroup = ({
  children,
  fitToWidth = false,
  fullWidth = false,
  size = 'l',
}: TabButtonsGroupProps): ReactElement => {
  return (
    <Styled.TabButtonsGroupWrapper fullWidth={fullWidth}>
      {Children.map(
        children,
        (child) =>
          isValidElement<TabButtonProps>(child) &&
          cloneElement<TabButtonProps>(child, {
            size,
            fitToWidth,
          }),
      )}
    </Styled.TabButtonsGroupWrapper>
  )
}

TabButtonsGroup.Button = TabButton
