import { Input } from '@design-system'

import { format as formatDate } from 'date-fns'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useLocale } from '../../../../../hooks/useLocale'
import { TaskState } from '../enums/taskState'
import * as Styled from '../styles'
import { Task } from '../types/task'
import { TaskItemSideButton } from './taskList/TaskItemSideButton'

export interface TaskItemProps {
  task?: Task
  onConfirm: (taskId: string) => void
  autoFocus?: boolean
  onCreateTask?: () => void
  onObserveInputChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onObserveDeadlineChange?: (deadline: Date) => void
  onApprove: (taskId: string) => void
  onTaskItemCheckboxClick: (taskId?: string) => void
}

export const TaskItem = ({
  task,
  onConfirm: updateTodoStatus,
  autoFocus,
  onCreateTask,
  onObserveInputChange: observeInputChange,
  onApprove,
  onObserveDeadlineChange,
  onTaskItemCheckboxClick,
}: TaskItemProps) => {
  const { t } = useTranslation()
  const [taskDeadline, setTaskDeadline] = useState<string>()
  const addDateButtonRef = useRef<HTMLDivElement>(null)
  const isTaskCompleted = task?.state === TaskState.Completed
  const { code } = useLocale()

  const taskDeadlineContent: string = useMemo(
    () =>
      taskDeadline
        ? `${t('dashboard.widget.tasks.deadline')}: ${formatDate(Date.parse(taskDeadline), 'dd. MMM yyyy', code)}`
        : '',
    [code, t, taskDeadline],
  )

  useEffect(() => {
    if (task?.deadline) {
      setTaskDeadline(task.deadline)
    }
  }, [])

  const handleCheckboxChange = useCallback(() => {
    if (task) {
      updateTodoStatus(task.id)
    }
  }, [task, updateTodoStatus])

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!task && observeInputChange) {
        observeInputChange(event)
      }
    },
    [observeInputChange, task],
  )

  const handleDeadlineChange = useCallback(
    (deadline: Date) => {
      if (onObserveDeadlineChange) {
        onObserveDeadlineChange(deadline)
      }

      setTaskDeadline(`${deadline}`)
    },
    [onObserveDeadlineChange],
  )

  const handleCheckboxContainerClick = useCallback(() => {
    if (task) {
      onTaskItemCheckboxClick(task.id)
    }
  }, [onTaskItemCheckboxClick, task])

  const handleApproveAction = useCallback(() => {
    if (task) {
      onApprove(task.id)
    }
  }, [onApprove, task])

  return (
    <Styled.TaskItem greyedOut={isTaskCompleted} crossedOff={isTaskCompleted}>
      <Styled.TaskInfoRow>
        <Styled.CheckboxContainer onClick={handleCheckboxContainerClick} disabled={isTaskCompleted}>
          <Styled.Checkbox
            onChange={handleCheckboxChange}
            checked={isTaskCompleted}
            disabled={isTaskCompleted || !task?.state}
          />
        </Styled.CheckboxContainer>

        <Styled.InputContainer>
          <Input
            autoFocus={autoFocus}
            value={task?.description}
            placeholder={t('things_to_do')}
            onPressEnter={onCreateTask}
            bordered={false}
            disabled={task?.state === TaskState.Pending || isTaskCompleted}
            onChange={handleInputChange}
            size="m"
          />
        </Styled.InputContainer>

        <Styled.ButtonContainer ref={addDateButtonRef}>
          <TaskItemSideButton
            taskState={task?.state}
            onApprove={handleApproveAction}
            onDeadlineChange={handleDeadlineChange}
          />
        </Styled.ButtonContainer>
      </Styled.TaskInfoRow>

      {taskDeadline && (
        <Styled.TaskDeadlineRow>
          <Styled.Text variant="micro" colorVariant="secondary">
            {taskDeadlineContent}
          </Styled.Text>
        </Styled.TaskDeadlineRow>
      )}
    </Styled.TaskItem>
  )
}
