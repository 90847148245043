import { TFunction } from 'i18next'
import { object, SchemaOf, string } from 'yup'

export interface CreateContactPersonForm {
  firstName: string
  lastName?: string
  email: string
}

export type CreateContactPersonFormSchema = SchemaOf<CreateContactPersonForm>

export const getContactPersonValidationSchema = (t: TFunction): CreateContactPersonFormSchema => {
  return object({
    firstName: string().required(t('modal.create_contact_person.error.first_name')),
    lastName: string(),
    email: string().email().required(t('modal.create_contact_person.error.email')),
  })
}
