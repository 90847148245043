import { TFunction } from 'i18next'
import * as yup from 'yup'

export interface SignupForm {
  userName: string
  userPhone: string
  organizaionStreet: string
  organizationCity: string
  organizationCvr: string
  organizationZipCode: string
}

export const defaultValues: SignupForm = {
  userName: '',
  userPhone: '',
  organizaionStreet: '',
  organizationCity: '',
  organizationCvr: '',
  organizationZipCode: '',
}

export const getValidationSchema = (t: TFunction): yup.SchemaOf<SignupForm> =>
  yup.object().shape({
    userName: yup.string().required(t('salary.signup.validation_required')),
    userPhone: yup.string().required(t('salary.signup.validation_required')),
    organizaionStreet: yup.string().required(t('salary.signup.validation_required')),
    organizationCity: yup.string().required(t('salary.signup.validation_required')),
    organizationCvr: yup.string().required(t('salary.signup.validation_required')),
    organizationZipCode: yup.string().required(t('salary.signup.validation_required')),
  })
