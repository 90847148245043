import { RichUtils } from 'draft-js'
import React, { ReactElement, useCallback } from 'react'

import { Button } from '../../../Button'
import { ButtonsGroup } from '../../../ButtonsGroup'
import { defaultInlineStyles } from '../../constants/inlineStyles'
import { useRichTextContext } from '../../contexts/richTextContext'
import { InlineStyle } from '../../enums/inlineStyle'
import { applyStyleSettings } from '../../utils/applyStyleSettings'

export const InlineStyleControls = (): ReactElement => {
  const { editorState, disabled, setEditorState, styleSettings } = useRichTextContext()
  const currentStyle = editorState.getCurrentInlineStyle()
  const enabledInlineStyles = applyStyleSettings(defaultInlineStyles, styleSettings)

  const handleToggleInlineStyle = useCallback(
    (style: InlineStyle) => () => {
      setEditorState(RichUtils.toggleInlineStyle(editorState, style))
    },
    [editorState, setEditorState],
  )

  return (
    <ButtonsGroup merged>
      {enabledInlineStyles.map(({ label, style }) => (
        <Button
          active={currentStyle.has(style)}
          forceSquareRatio={label.length <= 2}
          key={label}
          onClick={handleToggleInlineStyle(style)}
          size="m"
          variant="secondary"
          disabled={disabled}
        >
          {label}
        </Button>
      ))}
    </ButtonsGroup>
  )
}
