import { useFetchBalanceSheetReport } from '../../../hooks/useFetchBalanceSheetReport'
import { useBalanceFilters } from './useBalanceFilters'

export const useBalance = () => {
  const [options] = useBalanceFilters()
  const { report, isLoading, isIdle } = useFetchBalanceSheetReport(options)

  return {
    report,
    isLoading,
    isIdle,
  }
}
