import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconContacts = ({
  color = '#7151e5',
  color2 = '#fff',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  isOutlined,
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-bills')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 273.63 304.46">
      <path
        d="M311.63,346.63V318A61.66,61.66,0,0,0,250,256.36H126.66A61.66,61.66,0,0,0,65,318v28.61a2.22,2.22,0,0,0,2.22,2.22H309.41A2.21,2.21,0,0,0,311.63,346.63Z"
        transform="translate(-51.5 -57.89)"
        fill={isOutlined ? 'transparent' : coloring('#7151e5')}
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="27"
      />
      <circle
        cx="136.82"
        cy="75.16"
        r="61.66"
        fill={isOutlined ? 'transparent' : coloring('#7151e5')}
        stroke={coloring('#fff')}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="27"
      />
    </svg>
  </Box>
)
