import { FormItemsGroup, Icon, Space, Text, Tooltip } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { AccountNature } from '@modules-deprecated/app/accounts/types'

import { Scope } from '../../../../../enums/scope'
import { TrackingContext } from '../../../../../enums/trackingContext'
import { useFormContext } from '../../../../../hooks'
import { isAuthorized } from '../../../../../utils/isAuthorized'
import { AccountSelect } from '../../../../selects/AccountSelect'
import { TaxRateSelect } from '../../../../selects/TaxRateSelect'
import { UpgradeBadge } from '../../../../UpgradeBadge'
import { ContactFormSchema } from '../../utils/formData'
import { ContactDefaultProductDescription } from './elements/ContactDefaultProductDescription'
import * as Styled from './styles'

export const ContactStandardSettings = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<ContactFormSchema>()
  const isContactBookkeepingAuthorized = isAuthorized(Scope.ContactBookkeepingDefaults)

  return (
    <>
      <Styled.TextWrapper>
        <Text weight="medium" variant="h3" inline>
          {t('create_contact_modal.contact_standard_settings.title')}
        </Text>
        <Tooltip label={t('create_contact_modal.contact_standard_settings.title.tooltip')} placement="right">
          <Icon icon="iSignCircle" />
        </Tooltip>
        {!isContactBookkeepingAuthorized && (
          <UpgradeBadge trackingContext={TrackingContext.CreateContact}>
            {t('create_contact_modal.contact_standard_settings.upgrade')}
          </UpgradeBadge>
        )}
      </Styled.TextWrapper>

      <Space size="m" />
      <ContactDefaultProductDescription disabled={!isContactBookkeepingAuthorized} />
      <Space size="l" />

      <FormItemsGroup itemsInRow={2}>
        <FormItem
          label={t('create_contact_modal.form.contact_standard_settings.expense_category')}
          name="defaultExpenseAccount"
          render={({ field: { value, onChange, ...props } }) => (
            <AccountSelect
              {...props}
              allowClear
              disabled={!isContactBookkeepingAuthorized}
              dropdownSize="fitTrigger"
              natures={[AccountNature.Expense]}
              onSelect={(id, value) => {
                onChange(value)
              }}
              placeholder={t('create_contact_modal.form.contact_standard_settings.expense_category.placeholder')}
              selectedId={value?.id}
            />
          )}
        />

        <FormItem
          label={t('create_contact_modal.form.contact_standard_settings.expense_tax_rate')}
          name="paymentTermsMode"
          render={({ field: { value, onChange, ...props } }) => (
            <TaxRateSelect
              {...props}
              allowClear
              disabled={!isContactBookkeepingAuthorized}
              dropdownSize="fitTrigger"
              onSelect={(id, value) => {
                onChange(value)
              }}
              placeholder={t('create_contact_modal.form.contact_standard_settings.expense_tax_rate.placeholder')}
              purchase
              selectedId={value?.id}
            />
          )}
        />
      </FormItemsGroup>
    </>
  )
}
