import { SkeletonText } from '@design-system'

import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { useAccount } from '../../../../../../hooks/useAccount'
import { ChartOfAccountsUrlParams } from '../../types/chartOfAccountsUrlParams'

export const AccountStatementsTitle = (): ReactElement => {
  const { accountId } = useParams<ChartOfAccountsUrlParams>()
  const { account, isLoading } = useAccount(accountId)

  if (isLoading) {
    return <SkeletonText variant="h1" width={200} />
  }

  return <>{`${account?.accountNo ? `${account?.accountNo} - ` : ''}${account?.name || ''}`}</>
}
