import { getAccessToken, getRequest } from '../../../utils'
import { Role } from './types/role'

export const fetchOrganizationRoles = (organizationId: string): Promise<Role[]> => {
  const accessToken = getAccessToken()

  const headers = {
    Authorization: 'Bearer ' + btoa('access:' + accessToken),
  }

  return getRequest(`/oauth2/${organizationId}/userRoles`, headers, false)
}
