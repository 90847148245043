import { NavItem, Select, SelectProps } from '@design-system'

import { forwardRef, Ref, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceNumberingMode } from '../../../../../../enums/invoiceNumberingMode'

interface InvoiceNumberingModeSelectProps
  extends Omit<SelectProps<InvoiceNumberingMode>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: InvoiceNumberingMode) => void
}

export const InvoiceNumberingModeSelect = forwardRef(
  ({ onSelect, ...selectProps }: InvoiceNumberingModeSelectProps, ref: Ref<HTMLDivElement | null>) => {
    const { t } = useTranslation()

    const items: NavItem<InvoiceNumberingMode>[] = useMemo(
      () => [
        {
          id: InvoiceNumberingMode.Sequential,
          children: t('settings.organization.invoice.invoice_settings.invoice_no_mode.option.sequential'),
          value: InvoiceNumberingMode.Sequential,
        },
        {
          id: InvoiceNumberingMode.Manual,
          children: t('settings.organization.invoice.invoice_settings.invoice_no_mode.option.manual'),
          value: InvoiceNumberingMode.Manual,
        },
      ],
      [t],
    )

    return <Select {...selectProps} items={items} onSelect={onSelect} ref={ref} />
  },
)
