import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'

import { BillForm, getDefaultBillLine, getDefaultValues } from './formData'

export const resetAutoCompletedBillForm = (billForm: BillForm, baseCurrencyId?: string): BillForm => {
  const billFormUpdated: BillForm = cloneDeep(billForm)
  const defaultValues = getDefaultValues(baseCurrencyId)

  defaultValues.billLines = new Array(billFormUpdated.billLines?.length || 1).fill(getDefaultBillLine())

  for (const propertyPath in billFormUpdated.autoCompletedFields) {
    const isAutoCompleted = billFormUpdated.autoCompletedFields[propertyPath]

    if (isAutoCompleted) {
      const valueDefault = get(defaultValues, propertyPath)
      set(billFormUpdated, propertyPath, valueDefault)
    }
  }

  billFormUpdated.autoCompletedFields = defaultValues.autoCompletedFields

  return billFormUpdated
}
