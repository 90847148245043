import { Space, Switch, Text } from '@design-system'

import { ChangeEvent, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ReactComponent as BetaCandidateSelected } from '../../../../../../assets/images/beta-candidate/selected.svg'
import { ReactComponent as BetaCandidateUnselected } from '../../../../../../assets/images/beta-candidate/unselected.svg'
import { Scope } from '../../../../../../enums/scope'
import { getImageClassName } from '../../../../../../utils/getClassName'
import { isAuthorized } from '../../../../../../utils/isAuthorized'
import { useOrganizationCandidateSetting } from '../../hooks/useOrganizationCandidateSetting'
import * as Styled from './styles'

export const BetaCandidate = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { isFeaturesCandidate, setCandidateSetting, unsetCandidateSetting } = useOrganizationCandidateSetting({
    organizationId: organization?.id,
  })
  const isBetaWriteAuthorized = isAuthorized(Scope.BetaWrite)

  const handleFeaturesCandidateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setCandidateSetting()
      } else {
        unsetCandidateSetting()
      }
    },
    [setCandidateSetting, unsetCandidateSetting],
  )

  return (
    <Styled.FeaturesTesterWrapper>
      <Styled.FeaturesTesterQuestion>
        <Text inline variant="h3">
          {t('settings.organization.features.candidate.question')}
        </Text>
        <Styled.CandidateToggleContainer>
          <Switch
            checked={isFeaturesCandidate}
            onChange={handleFeaturesCandidateChange}
            disabled={!isBetaWriteAuthorized}
          />
        </Styled.CandidateToggleContainer>
      </Styled.FeaturesTesterQuestion>
      <Styled.FeaturesTesterDescription>
        <Text variant="small">{t('settings.organization.features.description.question_one')}</Text>
        <Text variant="small">{t('settings.organization.features.description.question_two')}</Text>
        <Text variant="small">{t('settings.organization.features.description.information_one')}</Text>
        <Space size="xl" />
        <Text variant="small">{t('settings.organization.features.description.information_two')}</Text>
      </Styled.FeaturesTesterDescription>
      <Styled.ImageWrapper className={getImageClassName()}>
        {isFeaturesCandidate ? <BetaCandidateSelected /> : <BetaCandidateUnselected />}
      </Styled.ImageWrapper>
    </Styled.FeaturesTesterWrapper>
  )
}
