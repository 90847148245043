import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, Text } from '@components-deprecated'
import { Button } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export enum ValidationModalType {
  FutureDate = 'futureDate',
  MissingComments = 'missingComments',
  DuplicatedBill = 'duplicatedBill',
}

type Labels = 'header' | 'body' | 'cancel' | 'confirm'

type TextObject = Partial<Record<Labels, string>>

const textToType: Partial<Record<ValidationModalType, TextObject>> = {
  [ValidationModalType.FutureDate]: {
    header: 'voucher.inbox.modals.future_date.header',
    body: 'voucher.inbox.modals.future_date.body',
    cancel: 'voucher.inbox.modals.future_date.cancel',
    confirm: 'voucher.inbox.modals.future_date.confirm',
  },
  [ValidationModalType.MissingComments]: {
    header: 'attachments.comments_warning_modal.header',
    body: 'attachments.comments_warning_modal.body',
    cancel: 'attachments.comments_warning_modal.cancel',
    confirm: 'attachments.comments_warning_modal.confirm',
  },
  [ValidationModalType.DuplicatedBill]: {
    header: 'doublet_checker_window.title',
    cancel: 'doublet_checker_window.bill.button.cancel',
    confirm: 'doublet_checker_window.bill.button.save',
  },
}

export type ValidationModalProps = ModalProps & {
  onCancel: () => void
  onConfirm: () => void
  type?: ValidationModalType
  children?: ReactNode
}

export const ValidationModal = ({
  children,
  type,
  onClose,
  onCancel,
  onConfirm,
  ...rest
}: ValidationModalProps): ReactElement => {
  const { t } = useTranslation()

  const { body = '', cancel = '', confirm = '', header = '' } = type ? textToType[type] || {} : {}

  return (
    <Modal onClose={onClose || onCancel} {...rest}>
      <ModalHeader heading={t(header)} />
      <ModalBody>
        {body && <Text variant="body2">{t(body)}</Text>}
        {children}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel} variant="text">
          {t(cancel)}
        </Button>
        <Button onClick={onConfirm}>{t(confirm)}</Button>
      </ModalFooter>
    </Modal>
  )
}
