export enum SubscriptionPlan {
  APIPartner = 'apipartner',
  Archive = 'archive',
  Basic = 'basic',
  BasicPaid = 'basic_paid',
  Complete = 'complete',
  Employee = 'employee',
  Free = 'free',
  FriendsofBilly = 'friendofbilly',
  InternalLicense = 'internallicense',
  NonProfit = 'nonprofit',
  Plus = 'plus',
  PlusTrial = 'plustrial',
  Premium = 'premium',
  PremiumTrial = 'premiumtrial',
  Pro = 'pro',
  SpecialFree = 'specialfree',
  AgerasFree = 'ageras_free',
  AgerasStart = 'ageras_start',
  AgerasBasic = 'ageras_basic',
  AgerasPlus = 'ageras_plus',
  AgerasComplete = 'ageras_complete',
}
