import reduce from 'lodash/reduce'

import { Account } from '@modules-deprecated/app/accounts/types'

interface AccountGroup {
  [accountNo: string]: string
}

export const getAccountGroups = (accounts: Account[]): AccountGroup =>
  reduce(
    accounts,
    (result, account) => {
      const groupKey = account.group?.id
      const groupName = account.group?.name || ''

      if (groupKey && !result[groupKey]) {
        return { ...result, [groupKey]: groupName }
      }

      return result
    },
    {} as AccountGroup,
  )
