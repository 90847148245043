import React, { forwardRef, HTMLAttributes, ReactElement, Ref } from 'react'

import { useThemeContext } from '../../contexts/themeProvider'
import { LogoVariant } from '../../types/logoVariant'
import { getClassName } from '../../utils/getClassName'
import { getImgPath } from '../../utils/getImgPath'
import * as Styled from './styles'

interface ImageParams {
  src: string
  width: number
  height: number
  whiteVersionSrc?: string
}

const logoToImage: Record<LogoVariant, ImageParams> = {
  billy: {
    src: getImgPath('/logo/logo-billy-@2x.png'),
    width: 101,
    height: 29,
  },
  billySimple: {
    src: getImgPath('/logo/logo-billy-simple-@2x.png'),
    width: 34,
    height: 20,
  },
  billyChristmas: {
    src: getImgPath('/logo/logo-billy-christmas-@2x.png'),
    width: 101,
    height: 32,
  },
  billyChristmasSimple: {
    src: getImgPath('/logo/logo-billy-christmas-simple-@2x.png'),
    width: 35,
    height: 32,
  },
  billyWhite: {
    src: getImgPath('/logo/logo-billy-white-@2x.png'),
    width: 101,
    height: 29,
  },
  salary: {
    src: getImgPath('/logo/logo-salary.svg'),
    width: 111,
    height: 51,
  },
  ageras: {
    src: getImgPath('/logo/logo-ageras-@2x.png'),
    width: 133,
    height: 21,
  },
  agerasSimple: {
    src: getImgPath('/logo/logo-ageras-simple-@2x.png'),
    width: 34,
    height: 20,
  },
  agerasSimpleElectric: {
    src: getImgPath('/logo/logo-ageras-simple-electric-@2x.png'),
    width: 34,
    height: 20,
  },
  agerasWhite: {
    src: getImgPath('/logo/logo-ageras-white-@2x.png'),
    width: 133,
    height: 21,
  },
}

interface LogoProps extends HTMLAttributes<HTMLElement> {
  logo?: LogoVariant
  isWhite?: boolean
}

export const Logo = forwardRef(
  ({ onClick, logo, isWhite = false, ...rest }: LogoProps, forwardedRef: Ref<HTMLElement>): ReactElement => {
    const { themeLayout } = useThemeContext()
    const defaultLogoVariant: LogoVariant = themeLayout === 'dark' ? 'agerasSimpleElectric' : 'agerasSimple'
    const { src, width, height } = logoToImage[logo || defaultLogoVariant]

    return (
      <Styled.LogoWrapper
        ref={forwardedRef}
        clickable={!!onClick}
        onClick={onClick}
        className={getClassName('logo')}
        {...rest}
      >
        <img src={src} width={width} height={height} alt="Logo" />
      </Styled.LogoWrapper>
    )
  },
)
