import { Button, ButtonProps } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

export const ToggleMissingReceiptsModeButton = (props: ButtonProps) => {
  const { t } = useTranslation()

  return (
    <Styled.MissingReceiptsModeButton data-tip={t('things_to_do.missing_receipts')}>
      <Button {...props} icon="documentWithXSign" size="m" variant="text" />
    </Styled.MissingReceiptsModeButton>
  )
}
