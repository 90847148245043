import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconList = ({
  color = 'inherit',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-list')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={coloring(color)}
        d="M31,10c0,1.657-1.343,3-3,3H4c-1.657,0-3-1.343-3-3V6c0-1.657,1.343-3,3-3h24c1.657,0,3,1.343,3,3V10z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M28,14H4c-2.206,0-4-1.794-4-4V6c0-2.206,1.794-4,4-4h24c2.206,0,4,1.794,4,4v4C32,12.206,30.206,14,28,14z M4,4C2.897,4,2,4.897,2,6v4c0,1.103,0.897,2,2,2h24c1.103,0,2-0.897,2-2V6c0-1.103-0.897-2-2-2H4z"
      ></path>
      <path
        fill={coloring(color)}
        d="M31,26c0,1.657-1.343,3-3,3H4c-1.657,0-3-1.343-3-3v-4c0-1.657,1.343-3,3-3h24c1.657,0,3,1.343,3,3V26z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M28,30H4c-2.206,0-4-1.794-4-4v-4c0-2.206,1.794-4,4-4h24c2.206,0,4,1.794,4,4v4C32,28.206,30.206,30,28,30z M4,20c-1.103,0-2,0.897-2,2v4c0,1.103,0.897,2,2,2h24c1.103,0,2-0.897,2-2v-4c0-1.103-0.897-2-2-2H4z"
      ></path>
    </svg>
  </Box>
)
