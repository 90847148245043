import { City } from '../../../types/city'
import { Zipcode } from '../../../types/zipcode'

interface GetOrganizationOwnerDisplayedAddressOptions {
  cities: City[]
  cityId?: string
  cityText?: string
  street: string
  zipcodeId?: string
  zipcodes: Zipcode[]
  zipcodeText?: string
}

export const getOrganizationOwnerDisplayedAddress = (
  addressData: GetOrganizationOwnerDisplayedAddressOptions,
): string => {
  const { cities, cityId, cityText, street, zipcodeId, zipcodes, zipcodeText } = addressData

  const matchedCity = cities.find(({ id }) => id === cityId)?.name
  const matchedZipcode = zipcodes.find(({ id }) => id === zipcodeId)?.zipcode

  return `${street}, ${matchedCity || cityText} ${matchedZipcode || zipcodeText}`
}
