export enum TrackingContext {
  BankAccountOverviewConnectBank = 'bank_account_overview_connect_bank',
  BankConnectionsConnectBank = 'bank_connections_connect_bank',
  BillUpload = 'bill_upload',
  BillWidgetUpload = 'bill_widget_upload',
  CreateBillUpload = 'create_bill_upload',
  CreateContact = 'create_contact',
  CreateQuote = 'create_quote',
  CreateRecurringInvoice = 'create_recurring_invoice',
  DaybookAttachment = 'daybook_attachment',
  DaybookIndex = 'daybook_index',
  EInvoice = 'e_invoice',
  EmailHigherRateExceeded = 'email_higher_rate_exceeded',
  EmailRateExceeded = 'email_rate_exceeded',
  FreeExpensesLimitExceeded = 'free_expenses_limit_exceeded',
  FreeInvoicesLimitExceeded = 'free_invoices_limit_exceeded',
  Integrations = 'integrations',
  InvoiceAttachment = 'invoice_attachment',
  InvoiceEdit = 'invoice_edit',
  InvoiceTemplateDesigner = 'invoice_template_designer',
  InvoiceTemplates = 'invoice_templates',
  OrganizationOwners = 'organization_owners',
  OrganizationSettingsUser = 'organization_settings_user',
  PaymentInformationEdit = 'payment_information_edit',
  PaymentMethodEditor = 'payment_method_editor',
  SalarySignup = 'salary_signup',
  Uploads = 'uploads',
}
