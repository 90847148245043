import { Button, ButtonProps, useModal, withModalConditionalRender } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../enums/modalId'
import { Scope } from '../../enums/scope'
import { isAuthorized } from '../../utils/isAuthorized'
import { ReceiptRejectionModal as ReceiptRejectionModalComponent } from './elements/ReceiptRejectionModal'

const ReceiptRejectionModal = withModalConditionalRender(ReceiptRejectionModalComponent)

interface ReceiptRejectionButtonProps extends Pick<ButtonProps, 'size'> {
  attachmentId?: string
  onSuccess?: () => void
}

export const ReceiptRejectionButton = ({
  attachmentId,
  size = 'l',
  onSuccess,
}: ReceiptRejectionButtonProps): ReactElement => {
  const { t } = useTranslation()

  const { open: openRejectionModal } = useModal(ModalId.EInvoiceRejectionModal)

  const isEnabled = isAuthorized(Scope.BillWrite)

  const handleClick = useCallback(() => {
    openRejectionModal()
  }, [openRejectionModal])

  return (
    <>
      <Button danger disabled={!isEnabled} fullWidth onClick={handleClick} size={size}>
        {t('receipts.button.reject_e_invoice')}
      </Button>
      <ReceiptRejectionModal id={ModalId.EInvoiceRejectionModal} attachmentId={attachmentId} onSuccess={onSuccess} />
    </>
  )
}
