import { AnyAction } from 'redux'

import { PRODUCTS_RECEIVED, PRODUCTS_REQUESTED } from './actions'
import { Product } from './types'

export type ProductsRequested =
  | AnyAction
  | {
      payload: string
      type: typeof PRODUCTS_REQUESTED
    }

export type ProductsReceived =
  | AnyAction
  | {
      payload: Array<Product>
      type: typeof PRODUCTS_RECEIVED
    }

export function productsRequested(organizationId: string): ProductsRequested {
  return {
    payload: organizationId,
    type: PRODUCTS_REQUESTED,
  }
}

export function productsReceived(products: Array<Product>): ProductsReceived {
  return {
    payload: products,
    type: PRODUCTS_RECEIVED,
  }
}
