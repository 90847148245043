import styled from '@emotion/styled'

export interface StyledBreadcrumbWrapperProps {
  onClick?: () => void
  clickable: boolean
}

export const BreadcrumbItemWrapper = styled.div<StyledBreadcrumbWrapperProps>`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`
