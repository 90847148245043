import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchAgerasFinanceCustomer } from '../query-api'
import { AgerasFinanceCustomer } from '../types/agerasFinanceCustomer'

interface ContextState {
  customer: AgerasFinanceCustomer | undefined
  token: string | undefined
  isCustomerLoading: boolean
}

const AgerasFinanceCustomerContext = createContext<ContextState | undefined>(undefined)

interface AgerasFinanceCustomerContextProps {
  children?: ReactNode
  organizationId?: string
}

export const AgerasFinanceCustomerContextProvider = ({
  children,
  organizationId = '',
}: AgerasFinanceCustomerContextProps): ReactElement | null => {
  const { data, isLoading: isCustomerLoading } = useQuery(
    [QueryKeys.AgerasFinanceCustomer, organizationId],
    () => fetchAgerasFinanceCustomer(organizationId),
    {
      retry: false,
      enabled: !!organizationId,
    },
  )

  return (
    <AgerasFinanceCustomerContext.Provider
      value={{ customer: data?.customer, token: data?.token?.accessToken, isCustomerLoading }}
    >
      {children}
    </AgerasFinanceCustomerContext.Provider>
  )
}

export const useAgerasFinanceCustomer = () => {
  const context = useContext(AgerasFinanceCustomerContext)

  if (!context) {
    throw new Error('AgerasFinanceCustomerContextProvider is missing in the module!')
  }

  return context
}
