import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { getDefaultTransition } from '../../../../utils/getDefaultTransition'
import { getShadow } from '../../../../utils/getShadow'

export const ImageMagnifierWrapper = styled.div`
  width: 250px;
  height: 250px;
  border: 2px solid ${Color.White};
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  will-change: transform;
  ${getShadow('raisedStrong')};
  ${getDefaultTransition('opacity')};
`
