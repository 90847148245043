import { Color, DefaultValue, getDefaultTransition, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ResponseWrapperButton = styled.button`
  appearance: none;
  width: 100%;
  outline: none;
  display: flex;
  height: 37px;
  border: none;
  border-radius: ${DefaultValue.BorderRadius};
  padding: ${Spacing.S} ${Spacing.M};
  align-items: center;
  background-color: ${Color.Purple20};
  cursor: pointer;
  ${getDefaultTransition('background-color')};

  &:hover {
    background-color: ${Color.Lavender};
  }
`

export const IconWrapper = styled.figure`
  display: flex;
  margin-right: ${Spacing.XXS};
`
