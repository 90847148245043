import React from 'react'
import { Box } from 'rebass'

import { IconProps } from '../Icon'

export const IconJcb = ({ width = '780px', height = '500px', ...rest }: IconProps) => (
  <Box as="i" width={width} height={height} {...rest}>
    <svg
      width={width}
      height={height}
      enableBackground="new 0 0 780 500"
      version="1.1"
      viewBox="0 0 780 500"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m632.24 361.27c0 41.615-33.729 75.36-75.357 75.36h-409.13v-297.88c0-41.626 33.73-75.371 75.364-75.371h409.12l-1e-3 297.89z"
        fill="#fff"
      />
      <linearGradient
        id="c"
        x1="908.72"
        x2="909.72"
        y1="313.21"
        y2="313.21"
        gradientTransform="matrix(132.87 0 0 323.02 -1.2027e5 -1.0093e5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007B40" offset="0" />
        <stop stopColor="#55B330" offset="1" />
      </linearGradient>
      <path
        d="m498.86 256.54c11.686 0.254 23.438-0.516 35.077 0.4 11.787 2.199 14.628 20.043 4.156 25.887-7.145 3.85-15.633 1.434-23.379 2.113h-15.854v-28.4zm41.834-32.145c2.596 9.164-6.238 17.392-15.064 16.13h-26.77c0.188-8.642-0.367-18.022 0.272-26.209 10.724 0.302 21.547-0.616 32.209 0.48 4.581 1.151 8.415 4.917 9.353 9.599zm64.425-135.9c0.498 17.501 0.072 35.927 0.215 53.783-0.033 72.596 0.07 145.19-0.057 217.79-0.47 27.207-24.582 50.848-51.601 51.391-27.045 0.11-54.094 0.017-81.143 0.047v-109.75c29.471-0.152 58.957 0.309 88.416-0.23 13.666-0.858 28.635-9.875 29.271-24.914 1.609-15.104-12.631-25.551-26.151-27.201-5.197-0.135-5.045-1.515 0-2.117 12.895-2.787 23.021-16.133 19.227-29.499-3.233-14.058-18.771-19.499-31.695-19.472-26.352-0.179-52.709-0.025-79.062-0.077 0.17-20.489-0.355-41 0.283-61.474 2.088-26.716 26.807-48.748 53.446-48.27 26.287-4e-3 52.57-4e-3 78.851-5e-3z"
        fill="url(#c)"
      />
      <linearGradient
        id="b"
        x1="908.73"
        x2="909.73"
        y1="313.21"
        y2="313.21"
        gradientTransform="matrix(133.43 0 0 323.02 -1.2108e5 -1.0092e5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1D2970" offset="0" />
        <stop stopColor="#006DBA" offset="1" />
      </linearGradient>
      <path
        d="m174.74 139.54c0.673-27.164 24.888-50.611 51.872-51.008 26.945-0.083 53.894-0.012 80.839-0.036-0.074 90.885 0.146 181.78-0.111 272.66-1.038 26.834-24.989 49.834-51.679 50.309-26.996 0.098-53.995 0.014-80.992 0.041v-113.45c26.223 6.195 53.722 8.832 80.474 4.723 15.991-2.573 33.487-10.426 38.901-27.016 3.984-14.191 1.741-29.126 2.334-43.691v-33.825h-46.297c-0.208 22.371 0.426 44.781-0.335 67.125-1.248 13.734-14.849 22.46-27.802 21.994-16.064 0.17-47.897-11.642-47.897-11.642-0.08-41.914 0.466-94.405 0.693-136.18z"
        fill="url(#b)"
      />
      <linearGradient
        id="a"
        x1="908.72"
        x2="909.72"
        y1="313.21"
        y2="313.21"
        gradientTransform="matrix(132.96 0 0 323.03 -1.205e5 -1.0093e5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E2B2F" offset="0" />
        <stop stopColor="#E30138" offset="1" />
      </linearGradient>
      <path
        d="m324.72 211.89c-2.437 0.517-0.49-8.301-1.113-11.646 0.166-21.15-0.347-42.323 0.283-63.458 2.082-26.829 26.991-48.916 53.738-48.288h78.768c-0.074 90.885 0.145 181.78-0.111 272.66-1.039 26.834-24.992 49.833-51.683 50.309-26.997 0.102-53.997 0.016-80.996 0.042v-124.3c18.439 15.129 43.5 17.484 66.472 17.525 17.318-6e-3 34.535-2.676 51.353-6.67v-22.772c-18.953 9.446-41.233 15.446-62.243 10.019-14.656-3.648-25.295-17.812-25.058-32.937-1.698-15.729 7.522-32.335 22.979-37.011 19.191-6.008 40.107-1.413 58.096 6.398 3.854 2.018 7.766 4.521 6.225-1.921v-17.899c-30.086-7.158-62.104-9.792-92.33-2.005-8.749 2.468-17.273 6.211-24.38 11.956z"
        fill="url(#a)"
      />
    </svg>
  </Box>
)
