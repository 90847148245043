import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { Spacing } from '../../enums/spacing'
import { ControlAlignmentPlacement } from '../../types/controlAlignmentPlacement'
import { getControlAlignmentPlacement } from '../../utils/getControlAlignmentPlacement'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { getShadow } from '../../utils/getShadow'
import { Label as LabelComponent } from '../Label'
import { Size } from './types/size'
import { getCheckedStyle } from './utils/getCheckedStyle'
import { getDangerStyle } from './utils/getDangerStyle'
import { getDisabledStyle } from './utils/getDisabledStyle'
import { getHoverStyle } from './utils/getHoverStyle'
import { getPressedStyle } from './utils/getPressedStyle'
import { sizeToProperties } from './utils/sizeToProperties'

interface ControlProps {
  size: Size
  controlAlignmentPlacement?: ControlAlignmentPlacement
}

export const Control = styled.span<ControlProps>`
  ${({ controlAlignmentPlacement }) => getControlAlignmentPlacement(controlAlignmentPlacement)};
  display: flex;
  width: ${({ size }) => sizeToProperties[size].width}px;
  height: ${({ size }) => sizeToProperties[size].height}px;
  margin-top: ${({ size }) => sizeToProperties[size].offsetTop || 'none'};
  border-radius: ${({ size }) => sizeToProperties[size].height / 2}px;
  justify-content: flex-start;
  position: relative;
  background-color: ${Color.Gray50};
  flex-shrink: 0;
  ${getDefaultTransition('background')}

  &:before {
    content: '';
    width: ${({ size }) => sizeToProperties[size].knobSize}px;
    height: ${({ size }) => sizeToProperties[size].knobSize}px;
    position: absolute;
    left: 2px;
    top: 2px;
    background-color: ${Color.White};
    border-radius: 50%;
    ${getShadow('raisedLight')};
    ${getDefaultTransition('transform')};
  }
`

export const Label = styled(LabelComponent)`
  margin-left: ${Spacing.S};
  cursor: inherit;
  ${getDefaultTransition('color')}
`

export const NativeCheckbox = styled.input`
  opacity: 0;
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

interface SwitchWrapperProps {
  checked: boolean
  disabled: boolean
  danger: boolean
  size: Size
}

export const SwitchWrapper = styled.label<SwitchWrapperProps>`
  display: flex;
  position: relative;
  cursor: pointer;

  ${(props) => hasHoverStyle(props) && getHoverStyle(Control, props.checked)};
  ${(props) => hasPressedStyle(props) && getPressedStyle(Control, props.checked)};
  ${(props) => hasCheckedStyle(props) && getCheckedStyle(Control, props.size)};
  ${(props) => hasDisabledStyle(props) && getDisabledStyle(Control, Label, props.checked)};
  ${(props) => hasDangerStyle(props) && getDangerStyle(Control, props.checked)};
`

const hasHoverStyle = ({ disabled }: SwitchWrapperProps) => !disabled
const hasPressedStyle = ({ disabled }: SwitchWrapperProps) => !disabled
const hasCheckedStyle = ({ checked }: SwitchWrapperProps) => checked
const hasDisabledStyle = ({ disabled }: SwitchWrapperProps) => disabled
const hasDangerStyle = ({ danger }: SwitchWrapperProps) => danger
