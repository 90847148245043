import { createContext, Dispatch, ReactElement, ReactNode, SetStateAction, useContext, useState } from 'react'

import { InvoiceEditorView } from '../enums/invoiceEditorView'

interface ContextState {
  setView: Dispatch<SetStateAction<InvoiceEditorView>>
  view: InvoiceEditorView
}

const InvoiceEditorContext = createContext<ContextState | undefined>(undefined)

interface InvoiceEditorContextProps {
  children: ReactNode
}

export const InvoiceEditorContextProvider = ({ children }: InvoiceEditorContextProps): ReactElement => {
  const [view, setView] = useState(InvoiceEditorView.Editor)

  return <InvoiceEditorContext.Provider value={{ view, setView }}>{children}</InvoiceEditorContext.Provider>
}

export const useInvoiceEditor = () => {
  const context = useContext(InvoiceEditorContext)

  if (!context) {
    throw new Error('InvoiceEditorContextProvider is missing in the module!')
  }

  return context
}
