import { notify } from '@design-system'

import { TFunction } from 'i18next'

import { NotificationKeys } from '../../../../../enums/notificationKeys'

export const notifyDebtCollectionError = (t: TFunction) =>
  notify({
    id: NotificationKeys.DebtCollectionServiceEnabled,
    message: t('integrations.card.debt_collection.enabled.notification.error'),
    variant: 'error',
  })
