import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns'

import { PeriodMode } from '../../../../enums/periodMode'
import { PeriodValue } from '../../../../types/periodValue'

export const convertFiscalYearToCustomDatePeriod = (
  periodValue: PeriodValue,
  month: number | undefined,
): PeriodValue => {
  const { mode, value, ...rest } = periodValue

  if (mode === PeriodMode.FiscalYear) {
    let startDate
    let endDate

    if (month !== undefined) {
      startDate = value ? startOfMonth(new Date(value - 1, month)) : undefined
      endDate = value ? endOfMonth(new Date(value, month - 1)) : undefined
    } else {
      startDate = value ? startOfYear(new Date().setFullYear(value)) : undefined
      endDate = value ? endOfYear(new Date().setFullYear(value)) : undefined
    }

    return {
      ...rest,
      mode: PeriodMode.CustomDate,
      customDate: {
        startDate,
        endDate,
      },
    }
  }

  return periodValue
}
