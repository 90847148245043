import React, { createContext, ReactElement, ReactNode, useContext } from 'react'

interface ContextState {
  currentRoutePath: string
  currentRouteName: string
}

const EmberCurrentRouteContext = createContext<ContextState | undefined>(undefined)

interface EmberCurrentRouteContextProviderProps extends ContextState {
  children?: ReactNode
}

export const EmberCurrentRouteContextProvider = ({
  children,
  currentRoutePath,
  currentRouteName,
}: EmberCurrentRouteContextProviderProps): ReactElement => {
  return (
    <EmberCurrentRouteContext.Provider value={{ currentRouteName, currentRoutePath }}>
      {children}
    </EmberCurrentRouteContext.Provider>
  )
}

export const useEmberCurrentRoute = () => {
  const context = useContext(EmberCurrentRouteContext)

  if (!context) {
    throw new Error('EmberCurrentRouteContextProvider is missing in the module!')
  }

  return context
}
