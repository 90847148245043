import { PaymentMethodType } from '../../../../../../../../../query-api/payment-methods-query'

export const paymentMethodTypeToTranslationKey: Record<PaymentMethodType, string> = {
  bank: 'payment_methods.types.bank',
  cash: 'payment_methods.types.cash',
  check: 'payment_methods.types.check',
  fi: 'payment_methods.types.fi',
  mobilepay: 'payment_methods.types.mobilepay',
  other: 'payment_methods.types.other',
  stripe: 'payment_methods.types.stripe',
}
