import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useInvoice } from '../../../../../contexts/invoiceContext'
import { getDueFromNow } from '../../../utils/getDueFromNow'
import { isInvoicePaid } from '../../../utils/isInvoicePaid'

export const useDueStatusTitle = () => {
  const { t } = useTranslation()
  const { invoice } = useInvoice()
  const { dueDate } = invoice || {}

  const isPaid = isInvoicePaid(invoice)

  const title = useMemo(() => {
    const dueFromNow = getDueFromNow(dueDate, t)
    return isPaid
      ? t('external_invoices.due_status.paid')
      : t('external_invoices.due_status.unpaid', { due: dueFromNow })
  }, [dueDate, isPaid, t])

  return {
    title,
  }
}
