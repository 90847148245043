export const getOrganizationSettingFormValueFromData = (
  organizationSettingValue?: string,
  inverted?: boolean,
): boolean | undefined => {
  if (typeof organizationSettingValue !== 'string') {
    return
  }

  const isOrganizationSettingEnabled = !!parseInt(organizationSettingValue, 10)

  return inverted ? !isOrganizationSettingEnabled : isOrganizationSettingEnabled
}
