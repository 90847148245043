import { encodePeriodValue } from '@components'

import { useEffect, useMemo } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useGlobalFiscalYear } from '../../../../../contexts/globalFiscalYearContext'
import { useLocalStorageUncontrolled } from '../../../../../hooks/useLocalStorageUncontrolled'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { getPeriodValueFromFiscalYear } from '../../../../../utils/getPeriodValueFromFiscalYear'
import { ReportsRoute } from '../../../enums/reportsRoute'
import { IncomeStatementsReportOptions } from '../../../types/reportOptions'
import { getLocalStorageFiltersValidated } from '../../../utils/getLocalStorageFiltersValidated'
import { getReportsFiltersStorageKey } from '../../../utils/getReportsFiltersStorageKey'

type UseProfitAndLossFiltersResponse = [
  IncomeStatementsReportOptions,
  (
    filters:
      | Partial<IncomeStatementsReportOptions>
      | ((prevParams: Partial<IncomeStatementsReportOptions>) => Partial<IncomeStatementsReportOptions>),
  ) => void,
]

const defaultReportsQuery: IncomeStatementsReportOptions = {
  compareCount: 1,
  compareWith: 'period',
  hideZeroes: true,
  inverted: false,
  period: undefined,
  ytd: false,
}

export const useProfitAndLossFilters = (): UseProfitAndLossFiltersResponse => {
  const { organization } = useUserOrganization()
  const { setItem, getItem } = useLocalStorageUncontrolled(
    getReportsFiltersStorageKey(`${ReportsRoute.ProfitAndLoss}-${ReportsRoute.Balance}`, organization?.id),
  )
  const { fiscalYear: globalFiscalYear } = useGlobalFiscalYear()

  const [queryParams, setQueryParams] = useQueryParams<IncomeStatementsReportOptions>({
    defaultQuery: {
      ...defaultReportsQuery,
      ...(globalFiscalYear && organization?.id
        ? { period: encodePeriodValue(getPeriodValueFromFiscalYear(globalFiscalYear), organization.id) }
        : {}),
      ...getLocalStorageFiltersValidated(defaultReportsQuery, getItem()),
    },
  })

  useEffect(() => {
    if (organization?.id) {
      setItem(JSON.stringify(queryParams))
    }
  }, [organization?.id, queryParams, setItem])

  return useMemo(() => {
    const { hideZeroes, inverted, ytd } = queryParams

    return [
      {
        ...queryParams,
        hideZeroes: typeof hideZeroes === 'string' ? hideZeroes === 'true' : Boolean(hideZeroes),
        inverted: typeof inverted === 'string' ? inverted === 'true' : Boolean(inverted),
        ytd: typeof ytd === 'string' ? ytd === 'true' : Boolean(ytd),
      },
      setQueryParams,
    ]
  }, [queryParams, setQueryParams])
}
