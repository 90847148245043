import { Color, FontWeight } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../types/themable'

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Label = styled.p<Themable>`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray7};
`

export const SelectDisplayer = styled.p<Themable>`
  font-weight: ${FontWeight.Medium};
  font-size: 24px;
  line-height: 22px;
  text-align: right;
  color: ${Color.Green};
`

export const RangeSliderContainer = styled.div`
  margin: 5px 0;
`

interface RangeSliderProps extends Themable {
  progress: string
}

export const RangeSlider = styled.input<RangeSliderProps>`
  margin: auto;
  outline: none;
  padding: 0;
  width: 100%;
  height: 6px;
  background-color: ${Color.Gray40};
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, ${Color.Green}),
    color-stop(100%, ${Color.Green})
  );
  background-size: ${({ progress }) => progress};
  background-repeat: no-repeat;
  border-radius: 10px;
  cursor: pointer;
  appearance: none;
  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: 32px;
    width: 32px;
    background-size: cover;
    border-radius: 100%;
    border: 0;
    top: 16px;
    background-image: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_472_247)"><path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="%2300E383"/><path fill-rule="evenodd" clip-rule="evenodd" d="M17 19.8976L21.2127 16L17 12.1024L18.1915 11L23 15.4488V16.5512L18.1915 21L17 19.8976Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15 12.1024L10.7873 16L15 19.8976L13.8085 21L9 16.5512V15.4488L13.8085 11L15 12.1024Z" fill="white"/></g><defs><clipPath id="clip0_472_247"><rect width="32" height="32" fill="white"/></clipPath></defs></svg>');
    transform: translateY(-50%);
    transition: background-color 150ms;
    cursor: pointer;
  }
`

export const MinMax = styled.p<Themable>`
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.primaryGray};
`

export const Min = styled(MinMax)``

export const Max = styled(MinMax)`
  text-align: right;
`
