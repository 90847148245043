import { css } from '@emotion/core'

import { librariesOverrides, sanitize, scrollbars } from '../_common'
import { darkMode } from '../_common/darkMode'
import { toaster } from './toaster.css'

const globalStyle = css`
  ${sanitize}
  ${toaster}
  ${scrollbars}
  ${librariesOverrides}
  ${darkMode}

  html {
    margin: 0;
    height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
  }

  body {
    margin: 0;
    width: 100%;
  }

  button {
    font-family: inherit;
  }

  ::selection {
    color: #ffffff;
    background: #cccccc;
  }

  /*
    Icons global styling
  */
  i > svg {
    display: inline-block;
  }

  /*
    ReactCSSTransitionReplace helper animation styles
  */
  .cross-fade-leave {
    opacity: 1;
  }
  .cross-fade-leave.cross-fade-leave-active {
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }
  .cross-fade-enter {
    opacity: 0;
  }
  .cross-fade-enter.cross-fade-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }
  .cross-fade-height {
    transition: height 0.3s ease-in-out;
  }
  .fade-wait-leave {
    opacity: 1;
  }
  .fade-wait-leave.fade-wait-leave-active {
    opacity: 0;
    transition: opacity 0.4s ease-in;
  }
  .fade-wait-enter {
    opacity: 0;
  }
  .fade-wait-enter.fade-wait-enter-active {
    opacity: 1;
    /* Delay the enter animation until the leave completes */
    transition: opacity 0.4s ease-in 0.6s;
  }
  .fade-wait-height {
    transition: height 0.6s ease-in-out;
  }
`

export default globalStyle
