import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { createActor, CreateActorResponseData } from '../query-api'

interface UseCreateActorProps {
  onError?: (error?: APIError) => void
  onSuccess?: (data: CreateActorResponseData) => void
}

export const useCreateActor = (props?: UseCreateActorProps) => {
  const { onError, onSuccess } = props || {}
  const { mutate: create, ...rest } = useMutation(createActor, { onSuccess, onError })

  return { create, ...rest }
}
