import { Button, ButtonsGroup, Color, Modal, Space, Text, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useEmberCloseModal } from '../../contexts/emberCloseModalContext'
import { ModalId } from '../../enums/modalId'
import { useAccount } from '../../hooks/useAccount'
import { apiUrl } from '../../utils'
import { useBankSettings } from './contexts/bankSettingsContext'
import { BankConnection } from './elements/BankConnection'
import { BankConnectionError } from './elements/BankConnectionError'
import { BankConnectionRenewalDate } from './elements/BankConnectionRenewalDate'
import { useBankConnections } from './hooks/useBankConnections'
import { useBankConnectionSessions } from './hooks/useBankConnectionSessions'
import { getBankConnectionSessionById } from './utils/getBankConnectionSessionById'

export const BankSettingsModal = (): ReactElement => {
  const { t } = useTranslation()
  const { closeEmberModal } = useEmberCloseModal()
  useModal(ModalId.BankSettingsModal, {
    onClose: () => {
      closeEmberModal()
    },
  })
  const { accountId, renewConnection, removeConnection } = useBankSettings()
  const { account: bankAccount, isLoading: isLoadingBankAccount } = useAccount(accountId)
  const { bankConnections, isLoading: isLoadingBankConnections } = useBankConnections()
  const { bankConnectionSessions, isLoading: isLoadingSessions } = useBankConnectionSessions()
  const connectedBank = bankConnections?.find((bankConnection) => bankConnection.accountId === accountId)
  const currentSession = bankConnectionSessions
    ? getBankConnectionSessionById(bankConnectionSessions, connectedBank?.referenceId)
    : undefined
  const hasError = !!currentSession?.errorCode || !!connectedBank?.errorCode
  const shouldShowError = hasError && connectedBank

  const isLoading = isLoadingBankAccount || isLoadingBankConnections || isLoadingSessions

  const handleRenewConnection = useCallback(() => {
    if (!connectedBank || !bankAccount) {
      return
    }

    renewConnection(connectedBank, bankAccount)
  }, [connectedBank, bankAccount, renewConnection])

  const handleRemoveConnection = useCallback(() => {
    if (!bankAccount) {
      return
    }

    removeConnection(bankAccount)
  }, [bankAccount, removeConnection])

  return (
    <Modal id={ModalId.BankSettingsModal} forceRender size="s">
      <Modal.Header>
        <Text variant="h2">{t('bank_settings_modal.bank_settings')}</Text>
      </Modal.Header>
      <Modal.Body>
        <Text>
          <Trans i18nKey="bank_settings_modal.your_account_is_connected" values={{ account: bankAccount?.name }}>
            Your account
            <Text inline weight="bold" as="span">
              {bankAccount?.name}
            </Text>{' '}
            in Billy is connected with
          </Trans>
        </Text>
        <Space size="xl" />
        <Text color={Color.Gray90}>{t('bank_settings_modal.connected_account')}</Text>
        <Space size="s" />
        <BankConnection
          accountName={connectedBank?.accountName}
          bankLogoUrl={`${apiUrl}/images/banks/${bankAccount?.bankId}-sq.png`}
          bankName={connectedBank?.bankName}
          isLoading={isLoading}
        />
        <Space size="s" />
        {!shouldShowError ? (
          <BankConnectionRenewalDate date={currentSession?.loginTokenExpiryTime} isLoading={isLoading} />
        ) : null}
        {shouldShowError ? <BankConnectionError session={currentSession} connectedBank={connectedBank} /> : null}
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button icon="roundedArrows" onClick={handleRenewConnection} disabled={isLoading}>
            {t('bank_settings_modal.renew_connection')}
          </Button>
          <Button onClick={handleRemoveConnection} disabled={isLoading} danger>
            {t('bank_settings_modal.remove_connection')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
