import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const IconContainer = styled.div`
  margin-right: ${Spacing.S};
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 730px;
`

export const FrodaIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
