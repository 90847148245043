import { InternalAccessor } from '@design-system'

import { TFunction } from 'i18next'

import { Payroll } from '../../../types/payroll'
import { PayrollListView } from '../types/payrollListView'
import { frequencyToTranslation } from './frequencyToTranslation'

export const getTableData = (
  payrollList: Payroll[],
  disabledRowId: string | undefined,
  t: TFunction,
): PayrollListView[] => {
  const frequencyTranslationMap = frequencyToTranslation(t)

  return payrollList.map((payroll) => ({
    approvalDeadline: payroll.approvalDeadline,
    cycleFrequency: frequencyTranslationMap[payroll.salaryCycle.frequency] || payroll.salaryCycle.frequency,
    id: payroll.id,
    salaryPeriod: '',
    salaryPeriodFrom: payroll.salaryPeriod.start,
    salaryPeriodTo: payroll.salaryPeriod.end,
    status: payroll.status,
    totalAmount: payroll.totalTransfer,
    [InternalAccessor.Disabled]: disabledRowId === payroll.id,
  }))
}
