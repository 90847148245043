import { Button, DefaultValue, getShadow, Spacing } from '@design-system'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, FlexProps } from 'rebass'

import { useOnClickOutside } from '../../../hooks'
import { Theme } from '../../../types/theme'
import { reactClass } from '../../../utils'

type FilterDropdownButtonProps = FlexProps & {
  disabled?: boolean
  dropdownOrigin?: 'left' | 'right'
}

export const FilterDropdownButton = (props: React.PropsWithChildren<FilterDropdownButtonProps>) => {
  const { disabled, dropdownOrigin, children, ...rest } = {
    dropdownOrigin: 'left',
    ...props,
  }

  const { t } = useTranslation()
  const theme = useTheme<Theme>()
  const { zIndex } = theme

  const [open, setOpen] = useState(false)
  const buttonRef = useRef<HTMLDivElement | null>(null)

  const handleOpen = useCallback(() => {
    if (!disabled) {
      setOpen(!open)
    }
  }, [disabled, open])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  useOnClickOutside(buttonRef, handleClose)

  return (
    <Flex
      ref={buttonRef}
      className={reactClass('filter-dropdown-button')}
      justifyContent={`flex-${dropdownOrigin === 'left' ? 'start' : 'end'}`}
      css={css`
        position: relative;
      `}
      {...rest}
    >
      <Button iconRight="funnel" onClick={handleOpen} variant="secondary">
        {t('bankreconciliation.reconcilablepostings.filter')}
      </Button>
      <Flex
        css={css`
          position: absolute;
          top: calc(100% + ${Spacing.S});
          ${dropdownOrigin}: 0px;
          min-width: 110px;
          padding: ${Spacing.S};
          flex-direction: column;
          align-items: stretch;
          justify-items: flex-start;
          background-color: white;
          border-radius: ${DefaultValue.BorderRadius};
          z-index: ${zIndex.navigation};
          opacity: ${open ? 1 : 0};
          pointer-events: ${open ? 'auto' : 'none'};
          ${getShadow('elevatedStrong')};
        `}
      >
        {children}
      </Flex>
    </Flex>
  )
}
