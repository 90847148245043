import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { BillStatus } from '../../../enums/billStatus'
import { useFetchBills } from '../../../routes/BillsList/hooks/useFetchBills'
import { Bill } from '../../../types/bill'
import { useBillReconciliation } from './billReconciliationContext'

interface SaveSelectedBillAndBillIdOptions {
  currentBillId: string
  isSelected: boolean
}

interface ContextState {
  bills?: Bill[]
  isLoading: boolean
  saveSelectedBillAndBillId: ({ currentBillId, isSelected }: SaveSelectedBillAndBillIdOptions) => void
  selectedBills: Bill[]
  selectedBillsAmountsSum: number
  selectedBillsIds: string[]
}

const MultipleBillsContext = createContext<ContextState | undefined>(undefined)

interface MultipleBillsContextProps {
  children?: ReactNode
}

export const MultipleBillsContextProvider = ({ children }: MultipleBillsContextProps): ReactElement => {
  const { contact, currencyId, initialBill } = useBillReconciliation()
  const [selectedBills, setSelectedBills] = useState<Bill[]>([])
  const [selectedBillsIds, setSelectedBillsIds] = useState<string[]>([])
  const { bills, isLoading } = useFetchBills({
    contactId: contact?.id || '',
    currencyId: currencyId || undefined,
    status: BillStatus.Unpaid,
  })

  const selectedBillsAmountsSum = useMemo(() => {
    return selectedBills?.reduce((sum: number, bill: Bill) => {
      return sum + (bill?.grossAmount || 0)
    }, 0)
  }, [selectedBills])

  const saveSelectedBillAndBillId = useCallback(
    ({ currentBillId, isSelected }: SaveSelectedBillAndBillIdOptions) => {
      const currentBill = bills.find((bill) => bill?.id === currentBillId)

      if (currentBill) {
        if (isSelected) {
          setSelectedBills((prevState) => [...prevState, currentBill])
          setSelectedBillsIds((prevState) => [...prevState, currentBillId])
        } else {
          setSelectedBills((prevState) => prevState.filter((bill) => bill !== currentBill))
          setSelectedBillsIds((prevState) => prevState.filter((id) => id !== currentBillId))
        }
      }
    },
    [bills],
  )

  useEffect(() => {
    if (initialBill && initialBill?.id) {
      setSelectedBills([initialBill])
      setSelectedBillsIds([initialBill.id])
    }
  }, [initialBill])

  useEffect(() => {
    if (bills) {
      bills.sort((billA, billB) => {
        return Number(billB?.id === initialBill?.id) - Number(billA?.id === initialBill?.id)
      })
    }
  }, [initialBill?.id, bills])

  return (
    <MultipleBillsContext.Provider
      value={{
        bills,
        isLoading,
        saveSelectedBillAndBillId,
        selectedBills,
        selectedBillsIds,
        selectedBillsAmountsSum,
      }}
    >
      {children}
    </MultipleBillsContext.Provider>
  )
}

export const useMultipleBills = () => {
  const context = useContext(MultipleBillsContext)

  if (!context) {
    throw new Error('MultipleBillsContextProvider is missing in the module!')
  }

  return context
}
