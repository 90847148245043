import { fetchReceipts } from '../query-api'
import { QueryProps } from '../types/queryProps'

export const getReceiptsQueryFunction = ({
  organizationId,
  page,
  pageSize,
  searchQuery,
  sortDirection,
  sortProperty,
  fillType,
  type,
}: QueryProps) =>
  fetchReceipts({
    organizationId,
    page,
    pageSize,
    searchQuery,
    sortDirection,
    sortProperty,
    fillType,
    type,
  })
