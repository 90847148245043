import { TaxRate } from '@modules-deprecated/app/taxrates/types'

// Custom vat rates have priority 0, but they should be last on the list when sorting with priority
export const adjustTaxRatesPriorities = (taxRates: TaxRate[]): TaxRate[] => {
  const highestPriority = Math.max(...taxRates.map((taxRate) => taxRate.priority))
  const adjustedTaxRates = taxRates.map((taxRate) => {
    if (taxRate.priority === 0) {
      taxRate.priority = highestPriority + 1
    }
    return taxRate
  })
  return adjustedTaxRates
}
