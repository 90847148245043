import { Modal, Space, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { MembersTable, TableDataWithName } from './elements/MembersTable'

interface ModalBodyProps<T> {
  isLoading: boolean
  isUmbrellaTransfer: boolean
  onRowSelect: (member: T) => void
  organizationName?: string
  tableData: T[]
}

export const ModalBody = <T extends TableDataWithName>({
  isLoading,
  isUmbrellaTransfer,
  onRowSelect,
  organizationName,
  tableData,
}: ModalBodyProps<T>): ReactElement => {
  const { t } = useTranslation()

  const introText = isUmbrellaTransfer
    ? t('transfer_ownership_modal.body.umbrella')
    : t('transfer_ownership_modal.body.organization')

  return (
    <Modal.Body>
      <Text>{introText}</Text>
      <Text weight="medium">{t('transfer_ownership_modal.body.warning')}</Text>
      {organizationName && (
        <>
          <Space size="s" />
          <Text>
            {t('transfer_ownership_modal.body.action', {
              organization: organizationName,
            })}
          </Text>
        </>
      )}
      <Space size="m" />
      <MembersTable isLoading={isLoading} tableData={tableData} onRowSelect={onRowSelect} />
    </Modal.Body>
  )
}
