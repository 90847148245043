import { Button, ButtonsGroup, Modal, ModalProps, Text, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useSyncBankConnection } from '@views/bankIntegration/hooks/useSyncBankConnection'

import { ModalId } from '../../../../enums/modalId'

interface ConnectionExpiredModalProps extends ModalProps {
  connectedBankAccountId: string | undefined
}

export const ConnectionExpiredModal = ({
  connectedBankAccountId,
  ...modalProps
}: ConnectionExpiredModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeModal } = useModal(ModalId.BankConnectionExpiredModal)
  const { isLoading, syncAccountBankConnection } = useSyncBankConnection()

  const handleRenewButton = useCallback(() => {
    syncAccountBankConnection(connectedBankAccountId as string)
  }, [connectedBankAccountId, syncAccountBankConnection])

  return (
    <Modal size="s" {...modalProps}>
      <Modal.Header>
        <Text variant="h2">{t('bank_connection.expired_dialog.title')}</Text>
      </Modal.Header>
      <Modal.Body>
        <Text>
          <Trans i18nKey="bank_connection.expired_dialog.text" />
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button onClick={closeModal} variant="primary" danger>
            {t('bank_connection.expired_dialog.cancel_button')}
          </Button>
          <Button onClick={handleRenewButton} variant="primary" loading={isLoading}>
            {t('bank_connection.expired_dialog.confirm_button')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
