import React, { ReactElement, ReactNode } from 'react'

import { Column } from './elements/Column'
import { ColumnItem } from './elements/ColumnItem'
import { ImageColumn } from './elements/ImageColumn'
import * as Styled from './styles'

interface DataSummarySectionProps {
  children: ReactNode
}

export const DataSummarySection = ({ children }: DataSummarySectionProps): ReactElement => {
  return <Styled.DataSummarySectionWrapper>{children}</Styled.DataSummarySectionWrapper>
}

DataSummarySection.ImageColumn = ImageColumn
DataSummarySection.Column = Column
DataSummarySection.ColumnItem = ColumnItem
