import { ModalConfirmation, ModalConfirmationProps, notify, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../enums/queryKeys'
import { APIError } from '../../../../../../../../utils'
import { useDeleteRole } from './hooks/useDeleteRole'

interface DeleteRoleModalProps extends ModalConfirmationProps {
  umbrellaId: string
  roleId: string
}

export const DeleteRoleModal = ({ umbrellaId, roleId, ...props }: DeleteRoleModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeDeleteRoleModal } = useModal(props.id)
  const { deleteRole, isProcessing } = useDeleteRole({
    onSuccess: () => {
      closeDeleteRoleModal()
      notify({
        id: NotificationKeys.UmbrellaRolesDelete,
        message: t('umbrella_roles.delete_role_modal.success'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.UmbrellaRoles)
    },
    onError: (error?: APIError) => {
      notify({
        id: NotificationKeys.UmbrellaRolesDelete,
        message: error?.message,
        variant: 'error',
      })
    },
  })

  const handleDeleteClick = useCallback(() => {
    deleteRole({ umbrellaId, roleId })
  }, [deleteRole, umbrellaId, roleId])

  return (
    <ModalConfirmation
      {...props}
      cancelLabel={t('cancel')}
      danger
      message={t('umbrella_roles.delete_role_modal.body')}
      okLabel={t('umbrella_roles.delete_role_modal.ok')}
      okLoading={isProcessing}
      onOk={handleDeleteClick}
      title={t('umbrella_roles.delete_role_modal.title')}
    />
  )
}
