import { useMemo } from 'react'

import { SortDirection } from '../../../enums/sortDirection'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { ReceiptFilterType } from '../elements/Receipt'
import { ReceiptsSortProperty } from '../enums/sort'

interface ReceiptsFilters {
  page: number
  searchQuery?: string
  sortBy: ReceiptsSortProperty
  sortDirection: SortDirection
  type: ReceiptFilterType
}

type UseReceiptsFiltersResponse = [ReceiptsFilters, (filters: Partial<ReceiptsFilters>) => void]

const defaultQuery: ReceiptsFilters = {
  page: 1,
  searchQuery: undefined,
  sortBy: ReceiptsSortProperty.CreatedTime,
  sortDirection: SortDirection.Desc,
  type: ReceiptFilterType.Upload,
}

export const useReceiptsFilters = (): UseReceiptsFiltersResponse => {
  const [queryParams, setQueryParams] = useQueryParams<ReceiptsFilters>({
    defaultQuery,
  })

  return useMemo(() => {
    return [
      {
        ...queryParams,
        // Sometimes `page` comes as a string. It needs to be always `number` type.
        page: Number(queryParams.page),
      },
      setQueryParams,
    ]
  }, [queryParams, setQueryParams])
}
