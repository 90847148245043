import { Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

export interface SummaryItem {
  name: string
  value: ReactNode
}

export interface SummaryBoxesProps {
  items: SummaryItem[]
}

export const SummaryBoxesComponent = ({ items }: SummaryBoxesProps): ReactElement => (
  <Styled.SummaryBoxesWrapper>
    {items.map(({ name, value }) => (
      <Styled.SummaryBox key={name}>
        <Text colorVariant="secondary" variant="normal" nowrap>
          {name}
        </Text>
        <Text variant="large" weight="medium" nowrap>
          {value}
        </Text>
      </Styled.SummaryBox>
    ))}
  </Styled.SummaryBoxesWrapper>
)
