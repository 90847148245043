import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import { mix } from 'polished'
import React, { ReactElement, ReactNode } from 'react'

import { Themable } from '../../types/themable'
import { Theme } from '../../types/theme'
import { Text as TextComponent } from '../Typography/Text'

const Text = styled((props) => <TextComponent {...props} />)`
  display: flex;
  align-items: center;
`

type SuggestionLabelProps = {
  truncate?: boolean
}

const SuggestionLabel = styled.span<SuggestionLabelProps>`
  display: block;

  ${({ truncate }) =>
    truncate &&
    `
      max-width: 125px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`

type SuggestionItemContainerProps = Themable & {
  selected?: boolean
}

const SuggestionItemContainer = styled.li<SuggestionItemContainerProps>`
  display: flex;
  height: 28px;
  margin-bottom: 12px;
  margin-right: 12px;
  padding: 6px 10px 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-bottom-width: 2px;
  border-radius: 3px;
  align-items: center;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.primary : theme.colors.white)};
  cursor: pointer;

  ${() => SuggestionLabel} {
    color: ${({ theme, selected }) => (selected ? theme.colors.white : theme.colors.text.default)};
  }

  ${({ selected, theme }) => {
    return (
      !selected &&
      `
        &:hover {
          border-color: ${theme.colors.linkHover};
        }
    `
    )
  }}
`

type RateLabelProps = Themable & {
  color: string
}

const RateLabel = styled.span<RateLabelProps>`
  margin-left: 4px;
  color: ${({ color }) => color};
`

export type SuggestionItemProps = {
  children: ReactNode
  onClick?: () => void
  rate: number
  truncate?: boolean
  selected?: boolean
}

export const SuggestionItem = ({ children, rate, truncate, selected, ...rest }: SuggestionItemProps): ReactElement => {
  const theme = useTheme<Theme>()

  let color = mix(rate, theme.colors.success, theme.colors.warning)
  const percentage = `${Math.round(rate * 100)}%`
  const title = typeof children === 'string' ? children : ''

  if (selected) {
    color = theme.colors.white
  }

  return (
    <SuggestionItemContainer title={title} selected={selected} {...rest}>
      <Text>
        <SuggestionLabel truncate={truncate}>{children}</SuggestionLabel>{' '}
        <RateLabel color={color}>{percentage}</RateLabel>
      </Text>
    </SuggestionItemContainer>
  )
}
