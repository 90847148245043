import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { QuotesSideActions } from '../../elements/QuotesSideActions'
import { QuotesRoute } from '../../enums/quotesRoute'
import { QuotesSummary } from './elements/QuotesSummary'
import { QuotesTable } from './elements/QuotesTable'
import { QuotesTableActions } from './elements/QuotesTableActions'
import { useIsQuotesListEmpty } from './hooks/useIsQuotesListEmpty'

export const QuotesList = (): ReactElement => {
  const { t } = useTranslation()
  const isQuotesListEmpty = useIsQuotesListEmpty()

  if (isQuotesListEmpty) {
    return <Redirect to={QuotesRoute.ListEmpty} />
  }

  return (
    <ModuleLayout title={t('quotes')} sideActions={<QuotesSideActions />}>
      <QuotesSummary />
      <QuotesTableActions />
      <QuotesTable />
    </ModuleLayout>
  )
}
