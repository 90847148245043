import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'
import { Variant } from './types/variant'
import { variantStyles } from './utils/variantStyles'

interface BadgeWrapperProps {
  clickable: boolean
  variant: Variant
}

export const BadgeWrapper = styled.span<BadgeWrapperProps>`
  display: inline-flex;
  height: 20px;
  padding: 0 ${Spacing.S};
  border-radius: 10px;
  align-items: center;
  background-color: ${({ variant }) => variantStyles[variant].bgColor};
  text-align: center;
  white-space: nowrap;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`
