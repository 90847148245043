import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

import { Account } from '@modules-deprecated/app/accounts/types'

import { AccountsGroupped } from '../types/accountsGroupped'

export const groupAccounts = (accounts: Account[]): AccountsGroupped => {
  const accountsSorted = sortBy(accounts, ['group.priority', 'group.accountNo', 'group.name', 'accountNo', 'name'])
  const accountsGrouped = groupBy(accountsSorted, (account) => account.group?.id)

  return accountsGrouped
}
