import { TFunction } from 'i18next'

import { SalesTaxRulesetPredefinedTag } from '../enums/salesTaxRulesetPredefinedTag'

interface TKeys {
  description: string
  title: string
}

interface SalesTaxRulesetsTKeys {
  [SalesTaxRulesetPredefinedTag.Exempt]: TKeys
  [SalesTaxRulesetPredefinedTag.Goods]: TKeys
  [SalesTaxRulesetPredefinedTag.Services]: TKeys
}

const SalesTaxRulesetsDisplayDataTKeys: SalesTaxRulesetsTKeys = {
  [SalesTaxRulesetPredefinedTag.Exempt]: {
    description: 'sales_tax_rulesets.2014_exempt.description',
    title: 'sales_tax_rulesets.2014_exempt.name',
  },
  [SalesTaxRulesetPredefinedTag.Goods]: {
    description: 'sales_tax_rulesets.2014_goods.description',
    title: 'sales_tax_rulesets.2014_goods.name',
  },
  [SalesTaxRulesetPredefinedTag.Services]: {
    description: 'sales_tax_rulesets.2014_services.description',
    title: 'sales_tax_rulesets.2014_services.name',
  },
}

interface GetSalesTaxRulesetDisplayDataOptions {
  description: string
  name: string
  predefinedTag: string | null
  t: TFunction
}

interface SalesTaxRulesetDisplayData {
  description: string
  title: string
}

export const getSalesTaxRulesetDisplayData = ({
  description,
  name,
  predefinedTag,
  t,
}: GetSalesTaxRulesetDisplayDataOptions): SalesTaxRulesetDisplayData => {
  if (predefinedTag === null) {
    return { description, title: name }
  }

  const tKeys = SalesTaxRulesetsDisplayDataTKeys[predefinedTag]

  return {
    description: t(tKeys?.description) ?? description,
    title: t(tKeys?.title) ?? name,
  }
}
