import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'

export const ModuleSectionWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Header = styled.header`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.S};
`

export const SideActions = styled.aside`
  margin-left: auto;
  padding-left: ${Spacing.S};
`
