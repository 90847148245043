import { StatusFormatter } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { RecurringInvoiceStatus as RecurringInvoiceStatusEnum } from '../../../../enums/recurringInvoiceStatus'
import { recurringInvoiceStatusToStatusFormatterVariant } from '../../constants/recurringInvoiceStatusToStatusFormatterVariant'
import { recurringInvoiceStatusToTitleKey } from '../../constants/recurringInvoiceStatusToTitleKey'

interface RecurringInvoiceStatusProps {
  status: RecurringInvoiceStatusEnum
}

export const RecurringInvoiceStatus = ({ status }: RecurringInvoiceStatusProps): ReactElement => {
  const { t } = useTranslation()

  const variant = recurringInvoiceStatusToStatusFormatterVariant[status]
  const titleKey = recurringInvoiceStatusToTitleKey[status]

  return <StatusFormatter variant={variant} title={t(titleKey)} />
}
