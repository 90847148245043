import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { defaultStaleTime } from '../../../config/queryClient'
import { QueryKeys } from '../../../enums/queryKeys'
import { fetchScoutConnections } from '../query-api'
import { ScoutConnection } from '../types/scoutConnection'

interface UseFetchScoutConnectionsResponse {
  connections?: ScoutConnection[]
  isLoading: boolean
}

export const useFetchScoutConnections = (): UseFetchScoutConnectionsResponse => {
  const { organization } = useUserOrganization()

  const { data: connections, isLoading } = useQuery(
    QueryKeys.ScoutConnections,
    () => organization && fetchScoutConnections(organization?.id),
    {
      enabled: !!organization?.id,
      staleTime: defaultStaleTime,
    },
  )

  return {
    connections,
    isLoading,
  }
}
