import isArray from 'lodash/isArray'

export const fixSearchParamsArrays = <T extends Record<string, any>>(params: T): T => {
  const formattedSearchParams = {} as T

  for (const property in params) {
    const propertyValue = params[property]

    if (isArray(propertyValue) && !propertyValue.length) {
      formattedSearchParams[property] = '' as T[Extract<keyof T, string>]
    } else {
      formattedSearchParams[property] = propertyValue
    }
  }

  return formattedSearchParams
}
