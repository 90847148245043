import { Modal, ModalConfirmation, useModal } from '@design-system'

import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberCloseModal } from '../../../../contexts/emberCloseModalContext'
import { ModalId } from '../../../../enums/modalId'
import { useInvoice } from '../../hooks/useInvoice'
import { EInvoiceContextProvider } from './context/eInvoiceContext'
import { SendInvoiceModalContextProvider } from './context/sendInvoiceModalContext'
import { RegisterInternationallyFormModal } from './elements/RegisterInternationallyFormModal'
import { RegisterInternationallyPendingModal } from './elements/RegisterInternationallyPendingModal'
import { SendInvoiceModalContent } from './elements/SendInvoiceModalContent'

interface SendInvoiceModalProps {
  contactCVR?: string
  contactEAN?: string
  contactId: string
  emailMessage: string
  emailSubject: string
  invoiceId: string
  selectedContactPersonId?: string
  sendAsEInvoice: boolean
  onInvoiceSent: () => void
}

export const SendInvoiceModal = ({
  contactCVR,
  contactEAN,
  contactId,
  emailMessage,
  emailSubject,
  invoiceId,
  selectedContactPersonId,
  sendAsEInvoice,
  onInvoiceSent,
}: SendInvoiceModalProps) => {
  const { t } = useTranslation()
  const { closeEmberModal } = useEmberCloseModal()
  const { data: invoiceData } = useInvoice(invoiceId)

  const { close: closeSendInvoiceModal } = useModal(ModalId.SendInvoiceModal, {
    onClose: () => closeEmberModal(),
  })

  const { close: closeRegisterInternationallyFailedModal } = useModal(ModalId.RegisterInternationallyFailedModal)
  const { close: closeRegisterInternationallySuccessModal } = useModal(ModalId.RegisterInternationallySuccessModal)

  const canResend = useMemo(() => {
    return !invoiceData?.invoice.delivery || invoiceData?.invoice.delivery.canResend
  }, [invoiceData?.invoice.delivery])

  const handleRegisterFailedModalOkClick = useCallback(() => {
    closeRegisterInternationallyFailedModal()
  }, [closeRegisterInternationallyFailedModal])

  const handleRegisterSuccessModalOkClick = useCallback(() => {
    closeRegisterInternationallySuccessModal()
    closeSendInvoiceModal()
  }, [closeRegisterInternationallySuccessModal, closeSendInvoiceModal])

  return (
    <SendInvoiceModalContextProvider
      canResend={canResend}
      contactCVR={contactCVR}
      contactEAN={contactEAN}
      contactId={contactId}
      invoiceId={invoiceId}
      message={emailMessage}
      selectedContactPersonId={selectedContactPersonId}
      sendAsEInvoice={sendAsEInvoice}
      subject={emailSubject}
      onInvoiceSent={onInvoiceSent}
    >
      <Modal id={ModalId.SendInvoiceModal} size="s" forceRender closable={false}>
        <SendInvoiceModalContent contactId={contactId} />
      </Modal>
      <EInvoiceContextProvider>
        <RegisterInternationallyFormModal />
        <RegisterInternationallyPendingModal />
        <ModalConfirmation
          id={ModalId.RegisterInternationallyFailedModal}
          okLabel={t('modal.register_internationally_failed.close')}
          onOk={handleRegisterFailedModalOkClick}
          title={t('modal.register_internationally_failed.title')}
          message={t('modal.register_internationally_failed.body')}
          danger
        />
        <ModalConfirmation
          id={ModalId.RegisterInternationallySuccessModal}
          okLabel={t('modal.register_internationally_success.close')}
          onOk={handleRegisterSuccessModalOkClick}
          title={t('modal.register_internationally_success.title')}
          message={t('modal.register_internationally_success.body')}
        />
      </EInvoiceContextProvider>
    </SendInvoiceModalContextProvider>
  )
}
