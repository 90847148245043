import React, { ReactElement } from 'react'

import { ReceiptFilterType } from '../Receipt'
import { EInvoicesEmptyState } from './elements/EInvoicesEmptyState'
import { NewReceipt } from './elements/NewReceipt'

export const ReceiptsEmptyState = ({ type }: { type: ReceiptFilterType }): ReactElement | null => {
  if (type === ReceiptFilterType.EDocument) {
    return <EInvoicesEmptyState />
  }

  return <NewReceipt />
}
