import { useMutation } from 'react-query'

import { APIError } from '../../../utils'
import { deleteBankLineSubjectAssociations } from '../query-api'

interface UseDeleteBankLineSubjectAssociationsProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useDeleteBankLineSubjectAssociations = (props?: UseDeleteBankLineSubjectAssociationsProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate: deleteAssociations,
    isLoading: isProcessing,
    ...rest
  } = useMutation(deleteBankLineSubjectAssociations, { onSuccess, onError })

  return { deleteAssociations, isProcessing, ...rest }
}
