import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconAdd = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-add')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
      <polygon
        fill={coloring(color)}
        points="21,10 12,10 12,1 10,1 10,10 1,10 1,12 10,12 10,21 12,21 12,12 21,12 "
      ></polygon>
    </svg>
  </Box>
)
