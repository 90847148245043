import { NavItem, NavItemAccessor } from '@design-system'

import { TFunction } from 'i18next'

import { UserOrganization } from '@modules-deprecated/app/organizations/types'
import { UserUmbrella } from '@modules-deprecated/app/umbrellas/types/userUmbrella'

import { AvatarProps, OrganizationAvatar } from '../OrganizationAvatar'
import { getOrganizationUrlForAppBuildTheme } from './getOrganizationUrlForAppBuildTheme'

export interface OrganizationItemValue {
  organization?: UserOrganization
  umbrella?: UserUmbrella
}

export const getOrganizationItems = (
  userUmbrellas: UserUmbrella[],
  userOrganizations: UserOrganization[],
  t: TFunction,
): NavItem<OrganizationItemValue>[] => {
  const items: NavItem<OrganizationItemValue>[] = []
  const umbrellaAccessorId = 'header-umbrella'
  const organizationAccessorId = 'header-organization'

  // Umbrellas
  if (userUmbrellas.length) {
    items.push({
      id: umbrellaAccessorId,
      accessor: NavItemAccessor.Header,
      children: t('menu.org_dropdown.admin_accounts'),
      value: {},
    })

    for (const umbrella of userUmbrellas) {
      const { umbrellaId, name } = umbrella

      items.push({
        id: umbrellaId,
        accessorGroupId: umbrellaAccessorId,
        children: name,
        iconLeft: <OrganizationAvatar icon="treeChart" />,
        searchKeywords: [name, umbrellaId],
        value: { umbrella },
        href: `/umbrellas/${umbrella.umbrellaId}`,
      })
    }
  }

  // Organizations
  if (userOrganizations.length) {
    items.push({
      id: organizationAccessorId,
      accessor: NavItemAccessor.Header,
      children: t('menu.org_dropdown.organizations'),
      value: {},
    })

    for (const organization of userOrganizations) {
      const { organizationId, name, iconUrl, url } = organization
      const avatarProps: AvatarProps = iconUrl ? { src: iconUrl, alt: `avatar-${name}` } : { icon: 'suitcase' }

      items.push({
        id: organizationId,
        accessorGroupId: organizationAccessorId,
        children: name,
        iconLeft: <OrganizationAvatar {...avatarProps} />,
        searchKeywords: [name, url, organizationId],
        value: { organization },
        href: getOrganizationUrlForAppBuildTheme(organization),
      })
    }
  }

  return items
}
