import { useModalMultiple } from '@design-system'

import isEqual from 'lodash/isEqual'
import { ReactNode, useCallback, useMemo, useRef } from 'react'

import { cloneThroughFragments } from '../utils/cloneThroughFragments'

// Helper types

export interface WarningModalRequiredProps {
  onOk?: (modalId: string) => void
  id: string
}

type WarningMap<T> = Partial<{
  [modalId: string]: keyof T
}>

interface Confirmation {
  value?: any
  valueToCompare?: any
  isConfirmed: boolean
}

interface SaveWithWarningProps<T> {
  values: Partial<T>
  valuesToCompare: Partial<T>
  onWarningsConfirm: () => void
}

// Hook types

interface UseWarningModalsResult<T> {
  saveWithWarning: (props: SaveWithWarningProps<T>) => void
  modals?: ReactNode
}

interface UseWarningModalsProps<T> {
  modals?: ReactNode
  warnings: WarningMap<T>
}

export const useWarningModals = <T>({ modals, warnings }: UseWarningModalsProps<T>): UseWarningModalsResult<T> => {
  const warningConfirmationsRef = useRef(new Map<string, Confirmation>())
  const savePropsRef = useRef<SaveWithWarningProps<any>>()

  const { open } = useModalMultiple(Object.keys(warnings))

  const areAllWarningsConfirmed = useCallback(() => {
    let areAllConfirmed = true

    for (const [, confirmation] of warningConfirmationsRef.current) {
      if (!confirmation.isConfirmed) {
        areAllConfirmed = false
        break
      }
    }

    return areAllConfirmed
  }, [])

  const shouldShowWarning = useCallback((modalId: string, value: any, valueToCompare: any) => {
    const confirmation = warningConfirmationsRef.current.get(modalId)
    const areValuesDifferent = !isEqual(value, valueToCompare)

    const isAlreadyConfirmed = confirmation?.isConfirmed
    const areConfirmedValuesChanged =
      confirmation && (!isEqual(value, confirmation.value) || !isEqual(valueToCompare, confirmation.valueToCompare))

    // "areConfirmedValuesChanged" - a helper flag, when user:
    // 1. Already confirmed the warning
    // 2. Confirming the rest of the warnings hasn't been finished (for example by clicking "Cancel")
    // 3. The value related to the already confirmed warning has been changed
    // 4. Result: The warning modal has to be displayed back again
    return areValuesDifferent && (!isAlreadyConfirmed || areConfirmedValuesChanged)
  }, [])

  const saveWithWarning = useCallback(
    (props: SaveWithWarningProps<T>) => {
      const { values, valuesToCompare, onWarningsConfirm } = props
      let warningModalId: string | undefined
      savePropsRef.current = props

      for (const [modalId, property] of Object.entries(warnings)) {
        if (!property) {
          continue
        }

        const value = values[property]
        const valueToCompare = valuesToCompare[property]

        if (shouldShowWarning(modalId, value, valueToCompare)) {
          // Set map at this point so map cointains all warnings which should pop up; set initial value for 'isConfirmed' to 'false
          // We also keep 'value' and 'valueToCompare' here to check if those values haven't changed in another attempt of saving
          warningConfirmationsRef.current.set(modalId, { value, valueToCompare, isConfirmed: false })
          warningModalId = modalId
        }
      }

      if (!warningModalId) {
        onWarningsConfirm?.()
      } else {
        open(warningModalId)
      }
    },
    [open, shouldShowWarning, warnings],
  )

  const handleModalOk = useCallback(
    (modalId: string) => {
      warningConfirmationsRef.current.set(modalId, {
        ...warningConfirmationsRef.current.get(modalId),
        isConfirmed: true,
      })

      if (areAllWarningsConfirmed()) {
        savePropsRef.current?.onWarningsConfirm?.()
        warningConfirmationsRef.current = new Map()
        savePropsRef.current = undefined
      } else if (savePropsRef.current) {
        saveWithWarning(savePropsRef.current)
      }
    },
    [areAllWarningsConfirmed, saveWithWarning],
  )

  return useMemo(
    () => ({
      modals: cloneThroughFragments<WarningModalRequiredProps>(modals, { onOk: handleModalOk }),
      saveWithWarning,
    }),
    [handleModalOk, modals, saveWithWarning],
  )
}
