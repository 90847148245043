import { Spacing } from '@design-system'

import styled from '@emotion/styled'
import { Flex, FlexProps } from 'rebass'

import { Themable } from '../../../../types/themable'

type FileThumbnailProps = FlexProps & Themable

export const FileThumbnailContainer = styled(Flex)<FileThumbnailProps>`
  width: 47px;
  height: 47px;
  margin-right: ${Spacing.S};
  padding: 3.5px;
  border: ${({ theme }) => `1px solid ${theme.colors.greyscale4}`};
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
`

FileThumbnailContainer.defaultProps = {
  as: 'figure',
}
