import { IconName } from '@design-system'

import { BillBalanceModifierReference } from '../enums/BillBalanceModifierReference'

export const billBalanceModifierReferenceToIconName: Record<BillBalanceModifierReference, IconName> = {
  [BillBalanceModifierReference.BankPayment]: 'bank',
  [BillBalanceModifierReference.Bill]: 'bills',
  [BillBalanceModifierReference.ContactBalancePayment]: 'creditCard',
  [BillBalanceModifierReference.DaybookTransaction]: 'bank',
}
