import { isAfter, isBefore, isValid } from 'date-fns'

interface DateOptions {
  isDateBlocked?: (date: Date) => boolean
  maxDate?: Date
  minDate?: Date
  unavailableDates?: Date[]
}

export const isValidDate = (date: Date, options?: DateOptions) => {
  const { isDateBlocked, maxDate, minDate, unavailableDates } = options || {}

  if (!isValid(date)) {
    return false
  }

  if (maxDate && isAfter(date, maxDate)) {
    return false
  }

  if (minDate && isBefore(date, minDate)) {
    return false
  }

  if (isDateBlocked && isDateBlocked(date)) {
    return false
  }

  if (unavailableDates && unavailableDates.includes(date)) {
    return false
  }

  return true
}
