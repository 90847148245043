import { useMutation } from 'react-query'

import { APIError } from '../../../utils'
import { removeAttachmentOwnerReference, RemoveAttachmentOwnerReferenceResponseData } from '../query-api'

interface UseRemoveAttachmentOwnerReferenceProps {
  onError?: (error?: APIError) => void
  onSuccess?: (response: RemoveAttachmentOwnerReferenceResponseData) => void
}

export const useRemoveAttachmentOwnerReference = (props?: UseRemoveAttachmentOwnerReferenceProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: remove, ...rest } = useMutation(removeAttachmentOwnerReference, {
    onSuccess,
    onError,
  })

  return { remove, ...rest }
}
