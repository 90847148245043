import { UserOrganization } from '@modules-deprecated/app/organizations/types'

import { getAppBuildTheme } from '../../../../../utils/getAppBuildTheme'

const theme = getAppBuildTheme()
const AGERAS_APP_URL = window.ENV?.AGERAS_APP_URL || process.env.AGERAS_APP_URL
const BILLY_APP_URL = window.ENV?.BILLY_APP_URL || process.env.BILLY_APP_URL

export const getOrganizationUrlForAppBuildTheme = (organization: UserOrganization) => {
  if (organization.brand && organization.brand !== theme) {
    return organization.brand === 'billy'
      ? `${BILLY_APP_URL}/${organization.url}`
      : `${AGERAS_APP_URL}/${organization.url}`
  }

  return `/${organization.url}/`
}
