import React, { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { EmberEventFn } from '../../../types/emberEventFn'

export type EmberActivateIntegration = (payload: Record<string, any>) => void

export type EmberDeactivateIntegration = (payload: Record<string, any>) => void

export type EmberReloadPaymentMethods = EmberEventFn

export type ActivateIntegration = (integration: string, callback?: Function) => void

export type DeactivateIntegration = (integration: string, callback?: Function) => void

export type ReloadPaymentMethods = () => void

interface ContextState {
  activateIntegration: ActivateIntegration
  deactivateIntegration: DeactivateIntegration
  reloadPaymentMethods: ReloadPaymentMethods
}

const EmberIntegrationContext = createContext<ContextState | undefined>(undefined)

interface EmberIntegrationContextProviderProps {
  children?: ReactNode
  onActivateIntegration?: EmberActivateIntegration
  onDeactivateIntegration?: EmberDeactivateIntegration
  onReloadPaymentMethods: EmberReloadPaymentMethods
}

export const EmberIntegrationContextProvider = ({
  children,
  onActivateIntegration,
  onDeactivateIntegration,
  onReloadPaymentMethods,
}: EmberIntegrationContextProviderProps): ReactElement => {
  const handleActivateIntegration = useCallback<ActivateIntegration>(
    (integration, callback) => {
      onActivateIntegration?.({ detail: { integration, callback } })
    },
    [onActivateIntegration],
  )

  const handleDeactivateIntegration = useCallback<DeactivateIntegration>(
    (integration, callback) => {
      onDeactivateIntegration?.({ detail: { integration, callback } })
    },
    [onDeactivateIntegration],
  )

  const handleReloadPaymentMethods = useCallback<ReloadPaymentMethods>(() => {
    onReloadPaymentMethods({ detail: {} })
  }, [onReloadPaymentMethods])

  return (
    <EmberIntegrationContext.Provider
      value={{
        activateIntegration: handleActivateIntegration,
        deactivateIntegration: handleDeactivateIntegration,
        reloadPaymentMethods: handleReloadPaymentMethods,
      }}
    >
      {children}
    </EmberIntegrationContext.Provider>
  )
}

export const useEmberIntegration = () => {
  const context = useContext(EmberIntegrationContext)

  if (!context) {
    throw new Error('EmberIntegrationContextProvider is missing in the module!')
  }

  return context
}
