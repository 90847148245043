import React, { ReactElement } from 'react'

import { getFileExtension } from '../../../../utils/getFileExtension'
import { FileEmptyThumbnail, FileErrorThumbnail, FilesPreview, FileType } from '../../../FilesPreview'
import { UploadItem } from '../../types'
import * as Styled from './styles'

interface FileThumbnailProps {
  field: UploadItem
}

export const FileThumbnail = ({ field }: FileThumbnailProps): ReactElement => {
  const { file } = field

  if (file.errors?.length) {
    return (
      <Styled.FileThumbnailContainer title={file.name}>
        <FileErrorThumbnail />
      </Styled.FileThumbnailContainer>
    )
  }

  if (!file.preview || !file.name) {
    return (
      <Styled.FileThumbnailContainer title={file?.name}>
        <FileEmptyThumbnail />
      </Styled.FileThumbnailContainer>
    )
  }

  const fileExtention = getFileExtension(file.name)
  const fileType = fileExtention === 'pdf' ? FileType.Pdf : FileType.Image

  return (
    <Styled.FileThumbnailContainer title={file.name}>
      <FilesPreview
        asType={fileType}
        files={[{ src: file.preview, alt: file.name }]}
        fitToHeight
        height="100%"
        width="100%"
        withNavigation={false}
      />
    </Styled.FileThumbnailContainer>
  )
}
