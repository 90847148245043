import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../../../utils'
import { postVatAccount } from '../../../../../query-api'

interface UseCreateVatAccount {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useCreateVatAccount = (props?: UseCreateVatAccount) => {
  const { onError, onSuccess } = props || {}

  const { mutate: create, ...rest } = useMutation(postVatAccount, {
    onError,
    onSuccess,
  })

  return { create, ...rest }
}
