import React, { MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react'

import { Link } from '../../../Link'
import { Text } from '../../../Text'
import { TableAlignmentProps } from '../../types/alignment'
import { TableSortableProps } from '../../types/sortable'
import { getCellTitle } from '../../utils/getCellTitle'
import * as Styled from './styles'

interface TableHeaderItemProps extends Partial<TableAlignmentProps>, Partial<TableSortableProps> {
  children: ReactNode
  columnId: string
  fixedSize?: boolean
  isSortedDesc: boolean
  onSort?: (columnId: string, isDesc: boolean) => void
  sorted?: boolean
  truncate?: boolean
}

export const TableHeaderItem = ({
  alignment = 'left',
  children,
  columnId,
  isSortedDesc: isSortedDescControlled,
  onSort,
  sortable,
  sorted,
  truncate,
  ...restProps
}: TableHeaderItemProps) => {
  const [isSortedDesc, setIsSortedDesc] = useState(isSortedDescControlled)

  useEffect(() => {
    setIsSortedDesc(isSortedDescControlled)
  }, [isSortedDescControlled])

  const title = truncate ? getCellTitle(children) : undefined

  const handleSort = useCallback(() => {
    if (onSort && sortable) {
      const isSortedDescUpdated = sorted ? !isSortedDesc : isSortedDesc
      onSort(columnId, isSortedDescUpdated)
      setIsSortedDesc(isSortedDescUpdated)
    }
  }, [columnId, isSortedDesc, onSort, sortable, sorted])

  const handleHeaderItemClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()

      handleSort()
    },
    [handleSort],
  )

  return (
    <Styled.TableHeaderItemWrapper {...restProps} alignment={alignment} sortable={sortable} sorted={sorted}>
      <Text title={title} truncate={truncate} weight="medium">
        {sortable ? (
          <Link underlined={false} withAnimation href="#sort" onClick={handleHeaderItemClick}>
            {children}
          </Link>
        ) : (
          children
        )}
      </Text>
      {sorted && <Styled.SortIcon icon={isSortedDesc ? 'caretDown' : 'caretUp'} onClick={handleSort} />}
    </Styled.TableHeaderItemWrapper>
  )
}
