import { Button, useModal } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { useSegment } from '../../../../../../hooks'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useManualPayment } from '../../contexts/manualPaymentContext'
import { BillReconciliationModalStateId } from '../../enums/billReconciliationModalStateId'
import { useModalContentToggler } from '../ModalContentToggler'

export const ManualPaymentModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { canUsePrepayment, selectedDifferenceType } = useBillReconciliation()
  const { hasManualPaymentDifference } = useManualPayment()
  const { setActiveState } = useModalContentToggler()
  useModal(ModalId.BillReconciliationModal, {
    onClose: () => {
      track('xxx - expenses - Manual payment modal closed')
    },
  })

  const isEnterPaymentButtonDisabled = useMemo(
    () => hasManualPaymentDifference && !selectedDifferenceType,
    [hasManualPaymentDifference, selectedDifferenceType],
  )

  const handleButtonClick = useCallback(() => {
    if (canUsePrepayment) {
      setActiveState(BillReconciliationModalStateId.Prepayment)
      return
    }

    track('xxx - expenses - Manual payment modal - register payment clicked')
  }, [canUsePrepayment, setActiveState, track])

  return (
    <Button onClick={handleButtonClick} disabled={isEnterPaymentButtonDisabled}>
      {t('bill.payment_reconcile.button.enter_payment')}
    </Button>
  )
}
