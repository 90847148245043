import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { connectToMobilePay, ConnectToMobilePayResponse } from '../../../query-api'

interface UseConnectToMobilePayProps {
  onSuccess?: (data: ConnectToMobilePayResponse) => void
  onError?: (error?: APIError) => void
}

export const useConnectToMobilePay = (props?: UseConnectToMobilePayProps) => {
  const { onError, onSuccess } = props || {}
  const { mutate: connect, isLoading: isProcessing, ...rest } = useMutation(connectToMobilePay, { onSuccess, onError })

  return { connect, isProcessing, ...rest }
}
