import { SearchLoupeInput } from '@components-deprecated'
import { Text } from '@design-system'

import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from '../../styles'
import { NotMappedToPublicChartOfAccountsTag } from '../NotMappedToPublicChartOfAccountsTag/NotMappedToPublicChartOfAccountsTag'

interface ChartOfAccountsNavigatorProps {
  accountsCount?: number
  onArchivedChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onQueryChange?: (query: string) => void
}

export const ChartOfAccountsNavigator = ({
  accountsCount = 0,
  onArchivedChange = () => null,
  onQueryChange = () => null,
}: ChartOfAccountsNavigatorProps) => {
  const { t } = useTranslation()

  return (
    <Styled.Navigator>
      <Styled.NavigationItem>
        <NotMappedToPublicChartOfAccountsTag />
      </Styled.NavigationItem>
      <Styled.NavigationItem>
        <Text>{t('coa.accounts.count', { count: accountsCount })}</Text>
      </Styled.NavigationItem>
      <Styled.NavigationItem>
        <Styled.FilterCheckbox label={t('coa.show_archived')} onChange={onArchivedChange} />
      </Styled.NavigationItem>
      <Styled.NavigationItem>
        <SearchLoupeInput onChange={onQueryChange} />
      </Styled.NavigationItem>
    </Styled.Navigator>
  )
}
