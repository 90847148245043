import { TableBadgeLabel } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceStatus } from '../../../../enums/invoiceStatus'
import { invoiceStatusToBadgeVariant } from '../../constants/invoiceStatusToBadgeVariant'
import { useInvoicesListFilters } from '../../hooks/useInvoicesListFilters'
import { useInvoiceSummaryFiltered } from '../../hooks/useInvoicesSummaryFiltered'
import { getBadgeAmountForStatusAll } from '../../utils/getBadgeAmountForStatus'
import { getInvoicesTitle } from '../../utils/getInvoicesTitle'

export const InvoicesTitle = (): ReactElement => {
  const { t } = useTranslation()
  const { isLoading, data } = useInvoiceSummaryFiltered()
  const [filters] = useInvoicesListFilters()

  const status = filters?.status || InvoiceStatus.All
  const totalAmount =
    status === InvoiceStatus.All && data ? getBadgeAmountForStatusAll(data) : data?.[status].count || 0
  const title = getInvoicesTitle(status, totalAmount, t)
  const variant = invoiceStatusToBadgeVariant[status]

  return (
    <TableBadgeLabel amount={totalAmount} isLoading={isLoading} variant={variant}>
      {title}
    </TableBadgeLabel>
  )
}
