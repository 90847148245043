import qs from 'qs'

import { getRequest } from '../../utils'

interface SkatConnectionResponseData {
  /* eslint-disable @typescript-eslint/naming-convention */
  connected: boolean
  code?: string
  deep_link?: string
  is_approved?: boolean
  /* eslint-enable @typescript-eslint/naming-convention */
}

export const fetchSkatConnection = async (
  organizationId: string,
  registrationNo?: string,
  skatTransactionId?: string,
): Promise<SkatConnectionResponseData> => {
  const queryParams = qs.stringify({
    ...(registrationNo ? { registrationNumber: registrationNo } : {}),
    ...(skatTransactionId ? { transactionId: skatTransactionId } : {}),
  })

  return getRequest(`/integrations/skat/organizations/${organizationId}/status?${queryParams}`)
}
