import React, { createContext, Dispatch, ReactElement, ReactNode, useReducer } from 'react'

export type ModalContextState = {
  openedIds: string[]
}

export const initialState: ModalContextState = {
  openedIds: [],
}

export const ModalContext = createContext<[ModalContextState, Dispatch<Actions>] | undefined>(undefined)

interface ModalContextProviderProps {
  children: ReactNode
  defaultOpenedIds?: string[]
}

export const ModalContextProvider = ({ children, defaultOpenedIds }: ModalContextProviderProps): ReactElement => {
  const defaultState = defaultOpenedIds ? { openedIds: defaultOpenedIds } : initialState
  const reducerData = useReducer(modalReducer, defaultState)

  return <ModalContext.Provider value={reducerData}>{children}</ModalContext.Provider>
}

function modalReducer(state: ModalContextState, action: Actions): ModalContextState {
  switch (action.type) {
    case 'OPEN': {
      if (state.openedIds.includes(action.modalId)) {
        return state
      }

      return {
        ...state,
        openedIds: [...state.openedIds, action.modalId],
      }
    }
    case 'CLOSE': {
      const openedIdsFiltered = state.openedIds.filter((openedId) => openedId !== action.modalId)

      return {
        ...state,
        openedIds: openedIdsFiltered,
      }
    }
  }
}

type Actions = { type: 'OPEN'; modalId: string } | { type: 'CLOSE'; modalId: string }
