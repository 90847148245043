import { intervalToDuration } from 'date-fns'
import { TFunction } from 'i18next'

export const getDueFromNow = (dueDateString: string | null | undefined, t: TFunction) => {
  if (!dueDateString) {
    return ''
  }

  const todayTimestamp = new Date().setUTCHours(0, 0, 0, 0)
  const today = new Date(todayTimestamp)
  const dueDate = new Date(dueDateString)

  const isOverdue = dueDate.getTime() - todayTimestamp < 0

  const intervalUnits = intervalToDuration({
    start: today,
    end: dueDate,
  })

  let dateUnitText
  if (intervalUnits.years) {
    dateUnitText = t('external_invoices.due_status.short_date_unit.year', { count: intervalUnits.years })
  } else if (intervalUnits.months) {
    dateUnitText = t('external_invoices.due_status.short_date_unit.month', { count: intervalUnits.months })
  } else if (intervalUnits.days) {
    dateUnitText = t('external_invoices.due_status.short_date_unit.day', { count: intervalUnits.days })
  }

  if (!dateUnitText) {
    return t('external_invoices.due_status.due_today')
  }

  if (isOverdue) {
    return t('external_invoices.due_status.due_past', { time: dateUnitText })
  }

  return t('external_invoices.due_status.due_future', { time: dateUnitText })
}
