import { notify, useModal } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../../../../../config/queryClient'
import { ModalId } from '../../../../../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../../enums/queryKeys'
import { FormComponent, useForm } from '../../../../../../../../../hooks'
import { useUpdateCurrencyDefaultPaymentMethod } from '../../../hooks/useUpdateCurrencyDefaultPaymentMethod'
import { AddCurrencyDefaultPaymentMethodForm, defaultValues, getValidationSchema } from '../utils/formData'

interface ContextState {
  Form: FormComponent
  isProcessing: boolean
  submitForm: () => void
}

const AddCurrencyDefaultPaymentMethodModalContext = createContext<ContextState | undefined>(undefined)

interface AddCurrencyDefaultPaymentMethodModalContextProps {
  children?: ReactNode
}

export const AddCurrencyDefaultPaymentMethodModalContextProvider = ({
  children,
}: AddCurrencyDefaultPaymentMethodModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { Form, handleSubmit } = useForm({
    defaultValues,
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })
  const { close: closeAddCurrencyDefaultPaymentMethodModal } = useModal(ModalId.AddCurrencyDefaultPaymentMethodModal)
  const { update, isProcessing } = useUpdateCurrencyDefaultPaymentMethod({
    onSuccess: () => {
      closeAddCurrencyDefaultPaymentMethodModal()
      notify({
        id: NotificationKeys.CurrencyDefaultPaymentMethodUpdate,
        message: t('settings.organization.invoice.currency_default_payment_methods_settings.add_modal.notification'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.PaymentMethods)
    },
    onError: () => {
      notify({
        id: NotificationKeys.CurrencyDefaultPaymentMethodUpdate,
        message: t('error_saving'),
        variant: 'warning',
      })
    },
  })

  const handleFormSubmit = useCallback(
    (values: AddCurrencyDefaultPaymentMethodForm) => {
      if (!organization?.id) {
        return
      }

      update({
        currencyCode: values.currencyCode,
        organizationId: organization.id,
        paymentMethodId: values.paymentMethodId,
      })
    },
    [organization?.id, update],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleFormSubmit)()
  }, [handleFormSubmit, handleSubmit])

  return (
    <AddCurrencyDefaultPaymentMethodModalContext.Provider value={{ Form, isProcessing, submitForm }}>
      {children}
    </AddCurrencyDefaultPaymentMethodModalContext.Provider>
  )
}

export const useAddCurrencyDefaultPaymentMethodModal = () => {
  const context = useContext(AddCurrencyDefaultPaymentMethodModalContext)

  if (!context) {
    throw new Error('AddCurrencyDefaultPaymentMethodModalContextProvider is missing in the module!')
  }

  return context
}
