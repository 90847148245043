export enum QuotesTableAccessor {
  ContactId = 'contactId',
  Description = 'description',
  DueDate = 'dueDate',
  EntryDate = 'entryDate',
  GrossAmount = 'grossAmount',
  IsSent = 'isSent',
  NetAmount = 'netAmount',
  OrderNo = 'orderNo',
  State = 'state',
  Type = 'type',
}
