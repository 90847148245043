import React from 'react'
import { toast, ToastOptions } from 'react-toastify'

import { toastNotifyOptionsDefault } from '../Notify'
import { ProgressNotification, ProgressNotificationProps } from '../ProgressNotification'

const toastNotifyProgressOptionsDefault: ToastOptions = {
  ...toastNotifyOptionsDefault,
  autoClose: false,
}

export interface NotifyProgressProps extends Omit<ProgressNotificationProps, 'onClose'> {
  onClose?: () => void
}

export const notifyProgress = (notificationProps: NotifyProgressProps, toastOptions?: ToastOptions) => {
  // additional security as "notify"" can be invoked from the ember side without checking types
  if (!notificationProps.id) {
    console.error('You have to pass "id" for the notification to make it work')
    return
  }

  if (toast.isActive(notificationProps.id)) {
    toast.dismiss(notificationProps.id)
  }

  const handleClose = (toastId: string) => {
    toast.dismiss(toastId)
    notificationProps.onClose?.()
  }

  toast(<ProgressNotification {...notificationProps} onClose={handleClose} />, {
    ...toastNotifyProgressOptionsDefault,
    ...toastOptions,
    toastId: notificationProps.id,
  })
}

const notifyProgressUpdate = (notificationProps: NotifyProgressProps, toastOptions?: ToastOptions) => {
  const handleClose = (toastId: string) => {
    toast.dismiss(toastId)
    notificationProps.onClose?.()
  }

  toast.update(notificationProps.id, {
    ...toastNotifyProgressOptionsDefault,
    ...toastOptions,
    render: <ProgressNotification {...notificationProps} onClose={handleClose} />,
  })
}

notifyProgress.update = notifyProgressUpdate

notifyProgress.isActive = toast.isActive
