import Grid from 'react-grid-layout'

import { WidgetSize } from '../enums/widgetSize'
import { Widget } from '../types/widget'
import { getXCoordinate } from './getXCoordinate'

export const getLayoutFromWidgets = (widgets: Widget[], isDraggable?: boolean): Grid.Layout[] => {
  let indexOffset = 0

  return widgets.map(({ size, id, isStatic }, index) => {
    // Offset the index depending on full width widgets count
    // and its order. If its even , shift by 1, otherwise 2
    if (size === WidgetSize.FullWidth) {
      indexOffset += index % 2 === 0 ? 1 : 2
    }

    return {
      h: 1,
      i: id,
      static: !isDraggable,
      w: size,
      x: getXCoordinate(index, size, indexOffset),
      y: Math.floor((index + indexOffset) / 2),
    }
  })
}
