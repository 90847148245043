import isUndefined from 'lodash/isUndefined'

import { Account, SelectAccountProps } from '@modules-deprecated/app/accounts/types'

export const filterAccounts = (accounts: Account[], filters?: SelectAccountProps, selectedId?: string): Account[] => {
  const {
    accountId,
    excludedAccounts = [],
    isBankAccount,
    isArchived = false,
    isPaymentEnabled,
    natures = [],
  } = filters || {}

  return accounts.filter((account) => {
    if (account.id === selectedId) {
      return true
    }

    if (!isUndefined(accountId) && accountId !== account.id) {
      return false
    }

    if (!isUndefined(isBankAccount) && isBankAccount !== account.isBankAccount) {
      return false
    }

    if (!isUndefined(isPaymentEnabled) && isPaymentEnabled !== account.isPaymentEnabled) {
      return false
    }

    if (!isUndefined(isArchived) && isArchived !== account.isArchived) {
      return false
    }

    if (excludedAccounts.length && excludedAccounts.includes(account.id)) {
      return false
    }

    if (natures.length && !natures.includes(account.natureId)) {
      return false
    }

    return true
  })
}
