import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAnnualReportByAccountIntegration } from '../../hooks/useAnnualReportByAccountIntegration'
import { useAnnualReportDIYIntegration } from '../../hooks/useAnnualReportDIYIntegration'
import { useFindBookkeeperIntegration } from '../../hooks/useFindBookkeeperIntegration'
import { useMomsIntegration } from '../../hooks/useMomsIntegration'
import { IntegrationsList } from '../IntegrationsList'

export const ControlBookkeepingIntegrationsList = (): ReactElement => {
  const { t } = useTranslation()
  const momsIntegration = useMomsIntegration()
  const annualReportDIYIntegration = useAnnualReportDIYIntegration()
  const annualReportByAccountIntegration = useAnnualReportByAccountIntegration()
  const findBookkeeperIntegration = useFindBookkeeperIntegration()

  const items = useMemo(
    () => [momsIntegration, annualReportDIYIntegration, annualReportByAccountIntegration, findBookkeeperIntegration],
    [annualReportByAccountIntegration, annualReportDIYIntegration, momsIntegration, findBookkeeperIntegration],
  )

  return <IntegrationsList title={t('integrations.list.title.control_over')} items={items} />
}
