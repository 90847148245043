import Grid from 'react-grid-layout'

import { widgetSettingsByWidgetId } from '../constants/widgetSettingsByWidgetId'
import { WidgetId } from '../enums/widgetId'
import { WidgetSize } from '../enums/widgetSize'
import { Widget } from '../types/widget'

export const getWidgetsFromLayout = (layout: Grid.Layout[], isHidden: boolean): Widget[] => {
  const sortedLayout = [...layout].sort((layoutA, layoutB) => {
    if (layoutA.y > layoutB.y) {
      return 1
    }

    if (layoutA.y < layoutB.y) {
      return -1
    }

    if (layoutA.x > layoutB.x) {
      return 1
    }

    if (layoutA.x < layoutB.x) {
      return -1
    }

    return 0
  })

  return sortedLayout.map((layoutItem, index) => {
    const isFullWidth = layoutItem.w === 2

    const { isStatic = false } = widgetSettingsByWidgetId[layoutItem.i]

    return {
      id: layoutItem.i as WidgetId,
      isHidden,
      isStatic,
      order: isHidden ? -index : index,
      size: isFullWidth ? WidgetSize.FullWidth : WidgetSize.HalfWidth,
    }
  })
}
