import { transactionOriginatorTypeGroupToTypes } from '../../../constants/transactionOriginatorTypeGroupToTypes'
import { TransactionOriginatorType } from '../../../enums/transactionOriginatorType'
import { TransactionOriginatorTypeGroup } from '../../../enums/transactionOriginatorTypeGroup'

export const getOriginatorTypesFromGroup = (
  originatorTypeGroups: TransactionOriginatorTypeGroup[],
): TransactionOriginatorType[] => {
  const originatorTypes: TransactionOriginatorType[] = []

  for (const originatorTypeGroup of originatorTypeGroups) {
    originatorTypes.push(...transactionOriginatorTypeGroupToTypes[originatorTypeGroup])
  }

  return originatorTypes
}
