import { useModalMultiple } from '@design-system'

import { useMutation } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ModalId } from '../../../enums/modalId'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { DEFAULT_QUERY_PARAMS } from '../constants/defaultQueryParams'
import { createBankToAccountConnection } from '../query-api'

export interface ConnectBankToAccountProps {
  accountId: string
  aiiaBankAccountId: string
}

type RefetchBankConnections = () => void

export const useConnectBankToAccount = (refetchBankConnections: RefetchBankConnections) => {
  const [, setParams] = useQueryParams({
    defaultQuery: DEFAULT_QUERY_PARAMS,
    withDefaultQueryInUrl: false,
  })
  const { organization } = useUserOrganization()
  const { close, open } = useModalMultiple([
    ModalId.BankToAccountConnectionModal,
    ModalId.BankConnectionEstablishedModal,
    ModalId.BankConnectionErrorModal,
  ])

  const { mutate: connectBankToAccount, isLoading } = useMutation(
    ({ accountId, aiiaBankAccountId }: ConnectBankToAccountProps) => {
      return createBankToAccountConnection({ organizationId: organization?.id as string, accountId, aiiaBankAccountId })
    },
    {
      onSuccess: () => {
        setParams(DEFAULT_QUERY_PARAMS)
        close(ModalId.BankToAccountConnectionModal)
        open(ModalId.BankConnectionEstablishedModal)
        refetchBankConnections()
      },
      onError: () => {
        open(ModalId.BankConnectionErrorModal)
      },
    },
  )

  return {
    isLoading,
    connectBankToAccount,
  }
}
