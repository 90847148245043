import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const BillAttachmentsActionsWrapper = styled.div`
  min-height: 0;
  flex-shrink: 0;
  margin-top: ${Spacing.M};
  display: flex;
  justify-content: space-between;
`
