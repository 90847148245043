import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface ImageColumnProps {
  children: ReactNode
}

export const ImageColumn = ({ children }: ImageColumnProps): ReactElement => {
  return <Styled.ImageColumnWrapper>{children}</Styled.ImageColumnWrapper>
}
