import { sizeToWidth } from '../enums/sizeToWidth'
import { TableColumn, TableColumnsWithOptions } from '../types/column'
import { TableData } from '../types/data'

export const getColumns = <T extends TableData>(columns: TableColumn<T>[]): TableColumnsWithOptions<T>[] =>
  columns.map(
    ({ alignment = 'left', size, id, sortable, ...rest }) =>
      ({
        ...rest,
        ...(size ? sizeToWidth[size] : {}),
        alignment,
        canSort: sortable,
        disableSortBy: !sortable,
        id: id || rest.accessor,
      }) as TableColumnsWithOptions<T>,
  )
