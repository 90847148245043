import { notify } from '@design-system'

import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { ORGANIZATION_LOGO_AND_ICON_MAX_SIZE } from '../constants/organizationLogoAndIconMaxSize'
import { uploadOrganizationLogo } from '../query-api'

export const useUploadOrganizationLogo = () => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()

  const { mutate: upload, ...rest } = useMutation(
    (file: File) => {
      return uploadOrganizationLogo(file, organization?.id || '', {
        'x-image-max-height': ORGANIZATION_LOGO_AND_ICON_MAX_SIZE,
        'x-image-max-width': ORGANIZATION_LOGO_AND_ICON_MAX_SIZE,
      })
    },
    {
      onSuccess: () => {
        notify({
          id: NotificationKeys.OrganizationLogoUpload,
          message: t('settings.organization.organization_info.logo.upload.success'),
          variant: 'success',
        })
        queryClient.invalidateQueries(QueryKeys.UserOrganization)
      },
      onError: () => {
        notify({
          id: NotificationKeys.OrganizationLogoUpload,
          message: t('settings.organization.organization_info.logo.upload.error'),
          variant: 'error',
        })
      },
    },
  )

  return { upload, ...rest }
}
