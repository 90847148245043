import { AttachmentsModal, AttachmentsModalProps } from '@components'
import { Attachment, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { useEmberCloseModal } from '../../../../contexts/emberCloseModalContext'
import { ModalId } from '../../../../enums/modalId'

type ChooseReceiptModalProps = AttachmentsModalProps

export const ChooseReceiptModal = ({ onSelect, idsToSkip }: ChooseReceiptModalProps): ReactElement => {
  const { closeEmberModal } = useEmberCloseModal()

  useModal(ModalId.AttachmentsModal, {
    onClose: () => closeEmberModal(),
  })

  const handleSelect = useCallback(
    (attachment: Attachment) => {
      onSelect(attachment)
    },
    [onSelect],
  )

  return <AttachmentsModal onSelect={handleSelect} idsToSkip={idsToSkip} />
}
