import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const UploadFilesErrorMessageWrapper = styled.ul`
  margin-top: ${Spacing.S};
  list-style-type: disc;
`

export const UploadFileErrorItem = styled.li`
  margin-bottom: ${Spacing.XXS};
`
