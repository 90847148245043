import React, { ReactElement } from 'react'

import { Text } from '../../../Text'
import * as Styled from './styles'

export const PaginationSeparator = (): ReactElement => (
  <Styled.PaginationSeparatorWrapper>
    <Text colorVariant="secondary">...</Text>
  </Styled.PaginationSeparatorWrapper>
)
