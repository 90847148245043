import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { ContactRoute } from '../../enum/contactRoute'
import { useIsContactsListEmpty } from '../../hooks/useIsContactsListEmpty'
import { ContactsTable } from './elements/ContactsTable'
import { ContactsTableActions } from './elements/ContactsTableActions'
import { ContactsTableSideActions } from './elements/ContactsTableSideActions'

export const ContactsList = (): ReactElement => {
  const { t } = useTranslation()
  const isContactsListEmpty = useIsContactsListEmpty()

  if (isContactsListEmpty) {
    return <Redirect to={ContactRoute.ListEmpty} />
  }

  return (
    <ModuleLayout sideActions={<ContactsTableSideActions />} title={t('contacts.header')}>
      <ContactsTableActions />
      <ContactsTable />
    </ModuleLayout>
  )
}
