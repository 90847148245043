import { Text } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { Account, AccountNature } from '@modules-deprecated/app/accounts/types'
import { useUserOrganization, useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import { fetchAccountBalance, fetchBankConnections } from '@modules-deprecated/bankReconciliation/query-api'
import { useMissingReceiptsBankLines } from '@views/thingsToDo/hooks/useMissingReceiptsBankLines'

import { useEmberConnectBank } from '../../../../contexts/emberConnectBankContext'
import { QueryKeys } from '../../../../enums/queryKeys'
import { useBankWidget } from '../../contexts/bankWidgetContext'
import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { Widget } from '../../elements/Widget'
import { WidgetId } from '../../enums/widgetId'
import { BankConnection } from './elements/bankConnection/BankConnection'
import { BankDisconnection } from './elements/bankDisconnection/BankDisconnection'
import { BankSelection } from './elements/BankSelection'
import { ConnectionCTA } from './elements/connectionCTA/ConnectionCTA'
import * as Styled from './styles'
import { getActiveBankAccounts } from './utils/getActiveBankAccounts'
import { getOldestBankConnection } from './utils/getOldestBankConnection'

const DEFAULT_CURRENCY_ID = 'DKK'
const TODAYS_DATE = new Date()

export const BankIntegration = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { organizationSettings } = useUserOrganizationSettings()
  const { accounts: allAccounts = [], isLoading: isAllAccountsLoading } = useAccounts()
  const { isWidgetHidden } = useDashboardSettings()
  const { selectedBankAccountId } = useBankWidget()

  const isHidden = useMemo(() => isWidgetHidden(WidgetId.BankIntegration), [isWidgetHidden])
  const isInboxEnabled = organizationSettings?.isInboxEnabled === '1'

  const {
    bankLines: missingReceiptsBankLines,
    isLoading: isLoadingReceipts,
    isError: isMissingReceiptsError,
  } = useMissingReceiptsBankLines(isInboxEnabled)
  const { connectBank } = useEmberConnectBank()

  const {
    data: bankConnectionData,
    isLoading: isBankConnectionLoading,
    isError: isBankConnectionError,
  } = useQuery([QueryKeys.BankConnections, organization?.id], () => fetchBankConnections(organization?.id || ''), {
    enabled: !!organization && !!organizationSettings,
  })

  const activeBankAccounts: Account[] = useMemo(
    () => getActiveBankAccounts(allAccounts, bankConnectionData),
    [allAccounts, bankConnectionData],
  )

  const { accountId, accountName, errorCode, balance } =
    useMemo(
      () =>
        selectedBankAccountId && activeBankAccounts.find(({ id }) => id === selectedBankAccountId)
          ? bankConnectionData?.find(({ accountId }) => accountId === selectedBankAccountId)
          : getOldestBankConnection(activeBankAccounts, bankConnectionData),
      [activeBankAccounts, bankConnectionData, selectedBankAccountId],
    ) || {}

  const account = useMemo(() => allAccounts.find((account) => account.id === accountId), [allAccounts, accountId])

  const {
    data: accountBalance,
    isLoading: isAccountBalanceLoading,
    isError: isAccountBalanceError,
  } = useQuery(
    [QueryKeys.AccountBalance, accountId, account?.currencyId],
    () => fetchAccountBalance(accountId as string, account?.currencyId || DEFAULT_CURRENCY_ID, TODAYS_DATE),
    {
      enabled: !!account && !!accountId && !!organizationSettings,
    },
  )

  const isError =
    isBankConnectionError || isAccountBalanceError || (isMissingReceiptsError && isInboxEnabled) || !!errorCode
  const isLoading =
    isBankConnectionLoading ||
    isAccountBalanceLoading ||
    isAllAccountsLoading ||
    isLoadingReceipts ||
    !organization ||
    !organizationSettings
  const isBankConnected = bankConnectionData?.length && !!accountId

  const isMissingReceipts = missingReceiptsBankLines?.length > 0

  const shouldShowBankConnection = (isBankConnected || isLoading || isError) && !isHidden

  const getAccountBalance = () => {
    if (!accountBalance) {
      return undefined
    }

    if (account?.natureId === AccountNature.Liability && accountBalance?.balance !== 0) {
      // AccountNature.Liability requires the balance to be converted to the opposite. So positive -> negative and vice versas
      return accountBalance?.balance < 0 ? Math.abs(accountBalance?.balance) : -Math.abs(accountBalance?.balance)
    }

    return accountBalance?.balance
  }

  return (
    <Widget
      title={shouldShowBankConnection ? t('dashboard.widget.bank-integration.title') : undefined}
      sideActions={!isLoading && (!!accountId || isError) && <ConnectionCTA isError={isError} accountId={accountId} />}
      isHidden={isHidden}
    >
      {shouldShowBankConnection ? (
        <>
          <BankConnection
            accountBalanceAmount={getAccountBalance()}
            accountCurrencyId={account?.currencyId}
            bankBalanceAmount={balance}
            isError={isError}
            isLoading={isLoading}
          />

          {!isLoading && (
            <Styled.Footer>
              <Text weight="regular" variant="micro" colorVariant="secondary">
                {!isError && !isMissingReceipts ? accountName : ''}
              </Text>
              <BankSelection accountId={accountId} bankAccounts={activeBankAccounts} />
            </Styled.Footer>
          )}
        </>
      ) : (
        <BankDisconnection onBankConnect={connectBank} />
      )}
    </Widget>
  )
}
