import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchIsTrustedPartner } from './query-api'

interface ContextState {
  isTrustedPartner?: boolean
  isLoading: boolean
}

const IsTrustedPartnerContext = createContext<ContextState | undefined>(undefined)

interface IsTrustedPartnerContextProps {
  children?: ReactNode
  organizationId?: string
}

export const IsTrustedPartnerContextProvider = ({
  children,
  organizationId = '',
}: IsTrustedPartnerContextProps): ReactElement => {
  const { data: isTrustedPartnerData, isLoading } = useQuery(
    [QueryKeys.IsTrustedPartner, organizationId],
    () => fetchIsTrustedPartner(organizationId),
    {
      enabled: !!organizationId,
    },
  )

  return (
    <IsTrustedPartnerContext.Provider value={{ isTrustedPartner: isTrustedPartnerData?.data, isLoading }}>
      {children}
    </IsTrustedPartnerContext.Provider>
  )
}

export const useIsTrustedPartner = () => {
  const context = useContext(IsTrustedPartnerContext)

  if (!context) {
    throw new Error('IsTrustedPartnerContextProvider is missing in the module!')
  }

  return context
}
