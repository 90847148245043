import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { fetchVouchersList, VoucherListResponse } from '@modules-deprecated/inbox/services/query-api'

import { getVouchersListQueryArrayKey } from '../utils/getVouchersListQueryArrayKey'

const defaultData: VoucherListResponse = {
  vouchers: [],
  pagination: { total: 0, limit: 0, offset: 0 },
}

export const useMissingInformation = () => {
  const [offset, setOffset] = useState(0)
  const { organization } = useUserOrganization()
  const query = useQuery(getVouchersListQueryArrayKey(organization?.id, { offset }), fetchVouchersList, {
    enabled: !!organization,
    refetchOnWindowFocus: true,
  })
  const { data = defaultData, isLoading: isQueryLoading } = query

  const isLoading = isQueryLoading || !organization

  const onPageChange = useCallback((page: number): void => {
    setOffset(page - 1)
  }, [])

  return {
    ...query,
    data,
    isLoading,
    onPageChange,
  }
}
