import { useCallback, useEffect } from 'react'

import { log } from '../utils'

type Options = {
  debug?: boolean // Outputs the key event in console
  disabled?: boolean // Will not register the listener
  withShift?: boolean
}

/**
 * @deprecated THIS HOOK IS DEPRECATED. Please use "useHover" or "useHoverDirty" from "react-use" package
 * @param key string - the name of the key to respond to, compared against event.key
 * @param action action - the action to perform on key press
 * @param options Options - modifiers
 * @return void
 */
export const useKeyDown = (key: string, action: (event: KeyboardEvent) => void, options: Options = {}) => {
  const { debug, disabled, withShift } = options as Options

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (disabled) {
        return
      }

      if (debug) {
        log(event)
      }

      if (withShift && !event.shiftKey) {
        return
      }

      if (event.key === key) {
        action(event)
      }
    },
    [action, disabled, debug, key, withShift],
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)

    return () => document.removeEventListener('keydown', onKeyDown)
  }, [onKeyDown])
}
