import React, { ReactElement } from 'react'

import { PaginationPageButton } from '../PaginationPageButton/PaginationPageButton'
import { PaginationSeparator } from '../PaginationSeparator'

interface PaginationItemProps {
  isActive: boolean
  onClick: (page: number) => void
  page: number | undefined
}

export const PaginationItem = ({ isActive, onClick, page }: PaginationItemProps): ReactElement => {
  const isSeparator = page === undefined

  if (isSeparator) {
    return <PaginationSeparator />
  }

  return <PaginationPageButton isActive={isActive} onClick={onClick} page={page} />
}
