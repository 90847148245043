import { Attachment } from '@design-system'

import { Contact } from '@views/contacts/types/contact'

import { Paging } from '../../../../../types/metable'
import { Bill } from '../../../types/bill'
import { useBillsListFilters } from './useBillsListFilters'
import { useFetchBills } from './useFetchBills'

interface UseBillsResponse {
  attachments: Attachment[]
  bills: Bill[]
  contacts: Contact[]
  isLoading: boolean
  pagination?: Paging
}

export const useBills = (): UseBillsResponse => {
  const [{ contactId, page, pageSize, period, searchQuery, sortDirection, sortProperty, status }] =
    useBillsListFilters()
  const { attachments, bills, contacts, isLoading, pagination } = useFetchBills({
    contactId,
    page,
    pageSize,
    period,
    searchQuery,
    sortDirection,
    sortProperty,
    status,
  })

  return {
    attachments,
    bills,
    contacts,
    isLoading,
    pagination,
  }
}
