import { Text } from '@components-deprecated'

import styled from '@emotion/styled'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Side } from '../../../enums/side'
import { orderAndDecorateSuggestions } from '../../inbox/utils/orderAndDecorateSuggestions'
import { getBankLinesSuggestions } from '../services/api'
import { BankLine } from '../types'
import {
  DefaultReconSuggestionFilters,
  ReconcilationSuggestionFilters,
  SuggestionFilters,
} from './ReconciliationSuggestionFilters'
import { ReconciliationSuggestionGroup } from './ReconciliationSuggestionGroup'
import { ReconciliationSuggestionItem } from './ReconciliationSuggestionItem'

const ReconciliationWrapper = styled.div`
  margin-bottom: 40px;
`

const Header = styled(Text)`
  margin-top: 25px;
  padding-top: 15px;
  border-top: 1px solid #e5e5e5;
  font-size: 16px;
  font-weight: 500;
`

type ReconciliationSuggestionsBoxProps = {
  accountId?: string
  amount?: number
  date?: Date
  disabled?: boolean
  matchId?: string
  onSuggestionsSelected?: (suggestion: BankLine | undefined) => void
  suggestionsSide?: Side
}

export const ReconciliationSuggestionsBox = ({
  accountId,
  amount = 0,
  date,
  disabled,
  matchId,
  onSuggestionsSelected = () => null,
  suggestionsSide,
}: ReconciliationSuggestionsBoxProps): ReactElement => {
  const { t } = useTranslation()
  const [bankLinesSuggestions, setBankLinesSuggestions] = useState<BankLine[]>([])
  const [selectedSuggestion, setSelectedSuggestion] = useState(matchId)
  const [suggestionFilters, setSuggestionFilters] = useState<SuggestionFilters>(DefaultReconSuggestionFilters)

  const suggestions = useMemo(
    () => orderAndDecorateSuggestions(bankLinesSuggestions, suggestionFilters, amount, suggestionsSide),
    [amount, bankLinesSuggestions, suggestionFilters, suggestionsSide],
  )

  useEffect(() => {
    const isSuggestionMatched = !!suggestions.find((suggestion) => suggestion.matchId === matchId)

    if (isSuggestionMatched) {
      setSelectedSuggestion(matchId)
    }
  }, [matchId, suggestions])

  const handleSuggestionClick = (matchId: string | undefined) => {
    const newMatchId = matchId === selectedSuggestion ? undefined : matchId
    const suggestion = newMatchId ? suggestions.find((suggestion) => suggestion.matchId === newMatchId) : undefined

    setSelectedSuggestion(newMatchId)
    onSuggestionsSelected && onSuggestionsSelected(suggestion)
  }

  // After fetching new suggestions, check if curretly selected one is still available (if not, deselect it)

  const checkSelectedSuggestion = (newSuggestions: BankLine[]) => {
    if (!selectedSuggestion) {
      return
    }

    const suggestion = newSuggestions.find((suggestion) => suggestion.matchId === selectedSuggestion)

    if (!suggestion) {
      handleSuggestionClick(selectedSuggestion)
    }
  }

  // Fetch reconciliation suggestions

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!date || !accountId || disabled) {
        if (disabled && (bankLinesSuggestions || selectedSuggestion)) {
          handleSuggestionClick(undefined)
          setBankLinesSuggestions([])
        }

        return
      }

      const { amountDifference, daysSpan } = suggestionFilters
      const minAmount = Math.abs(amount) - amountDifference
      const maxAmount = Math.abs(amount) + amountDifference
      const newBankLinesSuggestions = await getBankLinesSuggestions(accountId, date, daysSpan, minAmount, maxAmount)

      setBankLinesSuggestions(newBankLinesSuggestions)
      checkSelectedSuggestion(newBankLinesSuggestions)
    }

    fetchSuggestions()
  }, [accountId, date, suggestionFilters.amountDifference, amount, disabled])

  return (
    <ReconciliationWrapper>
      <Header>{t('voucher.inbox.reconciliation.title')}</Header>
      <Text>{t('voucher.inbox.reconciliation.description')}</Text>
      <ReconcilationSuggestionFilters onFiltersChange={setSuggestionFilters} />
      {!suggestions.length && <Text>{t('voucher.inbox.reconciliation.no_results')}</Text>}
      <ReconciliationSuggestionGroup>
        {suggestions.map((suggestion) => (
          <ReconciliationSuggestionItem
            key={suggestion.id}
            line={suggestion}
            selected={selectedSuggestion === suggestion.matchId}
            onClick={() => handleSuggestionClick(suggestion.matchId)}
          />
        ))}
      </ReconciliationSuggestionGroup>
    </ReconciliationWrapper>
  )
}
