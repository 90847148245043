import { Color } from '@design-system'

import { Variant } from '../types/variant'

export const variantStyles: Record<Variant, Color> = {
  error: Color.Red,
  neutral: Color.Gray40,
  primary: Color.Lavender,
  success: Color.Green,
  warning: Color.Yellow,
}
