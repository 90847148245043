import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import { Box } from 'rebass'

type DashboardWidgetProps = {
  centered?: boolean
  children?: ReactNode
}

const DashboardWidgetBodyContainer = styled(Box)<DashboardWidgetProps>`
  width: 100%;
  height: 100%;

  ${({ centered }) =>
    centered &&
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  `}
`

export const DashboardWidgetBody = ({ children, ...rest }: DashboardWidgetProps) => {
  return <DashboardWidgetBodyContainer {...rest}>{children}</DashboardWidgetBodyContainer>
}
