import { useMutation } from 'react-query'

import { APIError } from '../../../utils'
import { createBillsAndBankLinesReconcilation } from '../query-api'

interface UseCreateBillsAndBankLinesReconciliationProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useCreateBillsAndBankLinesReconciliation = (props: UseCreateBillsAndBankLinesReconciliationProps) => {
  const { onError, onSuccess } = props || {}

  const {
    mutate: create,
    isLoading: isProcessing,
    ...rest
  } = useMutation(createBillsAndBankLinesReconcilation, {
    onSuccess,
    onError,
  })

  return { create, isProcessing, ...rest }
}
