import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { IconCross } from '../../Icons'

export const fileThumbnailCommonStyles = css`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
`

export const FileErrorWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const FileErrorIcon = styled(IconCross)`
  ${fileThumbnailCommonStyles};
`
