import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'
import { Alignment } from '../../types/alignment'

interface FormItemWrapperProps {
  itemColumnEnd?: number
  itemColumnStart?: number
}

export const FormItemWrapper = styled.div<FormItemWrapperProps>`
  min-width: 0; // Fix for truncating stuff inside (https://css-tricks.com/flexbox-truncated-text/#aa-the-solution-is-min-width-0-on-the-flex-child)
  ${({ itemColumnStart }) => itemColumnStart !== undefined && `grid-column-start: ${itemColumnStart};`}
  ${({ itemColumnEnd }) => itemColumnEnd !== undefined && `grid-column-end: ${itemColumnEnd};`}
`

interface LabelWrapperProps {
  alignment: Alignment
}

export const LabelWrapper = styled.div<LabelWrapperProps>`
  margin-bottom: ${Spacing.XXXS};
  text-align: ${({ alignment }) => alignment};
`
