import { Text } from '@components-deprecated'
import { Color, DefaultValue } from '@design-system'

import styled from '@emotion/styled'
import { transparentize } from 'polished'

export type Direction = 'row' | 'column'

export const MissingInformationWrapper = styled.div`
  display: flex;
  padding-top: 12px;
  padding-bottom: 20px;
`

export const FileWrapper = styled.div``

export const FileTitle = styled(Text)`
  margin-bottom: 2px;
`

export const File = styled.div`
  height: 200px;
  width: 170px;
  margin-right: 32px;
  padding: 2px;
  border: 1px solid ${transparentize(0.9, Color.Black)};
  border-radius: ${DefaultValue.BorderRadius};
  flex-shrink: 0;
  background-color: ${Color.White};
`

export const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 26px;
  flex-direction: column;
`

export const NotificationWrapper = styled.div`
  margin-bottom: 16px;
`
