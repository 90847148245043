import React, { HTMLAttributes, memo, ReactElement, ReactNode } from 'react'

import { Text } from '../Text'
import * as Styled from './styles'
import { Variant } from './types/variant'
import { variantStyles } from './utils/variantStyles'

export type BadgeVariant = Variant
export const badgeVariantStyles = variantStyles

export interface BadgeProps extends HTMLAttributes<HTMLSpanElement> {
  children: ReactNode
  variant?: BadgeVariant
}

export const Badge = memo(
  ({ children, variant = 'primary', ...rest }: BadgeProps): ReactElement => (
    <Styled.BadgeWrapper variant={variant} clickable={!!rest.onClick} {...rest}>
      <Text as="span" variant="micro" weight="medium" color={variantStyles[variant].textColor}>
        {children}
      </Text>
    </Styled.BadgeWrapper>
  ),
)
