import { FontWeight } from '@design-system'

import colors from './colors'
import text from './text'

export default {
  topbanner: {
    padding: '0 30px',
    height: 42,
    color: colors.white,
    backgroundColor: colors.gray4,

    '> p': {
      ...text.body1,
      color: 'white',
      fontWeight: FontWeight,
    },
  },
}
