import { Color } from '@design-system'

import cc from 'classcat'
import React from 'react'
import { Box, BoxProps } from 'rebass'

import { Text } from '../Typography/Text'

export type ErrorMessageProps = BoxProps & {
  error:
    | {
        type?: string
        message?: string
      }
    | undefined
}

export const ErrorMessage = ({ className, error, ...rest }: ErrorMessageProps) => {
  if (!error) {
    return null
  }
  return (
    <Box className={cc([className, 'form-error-message'])} variant="forms.error" {...rest}>
      <Text color={Color.Red}>{error.message}</Text>
    </Box>
  )
}
