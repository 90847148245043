import { FormLayout, Spacing } from '@design-system'

import { ReactElement, ReactNode } from 'react'

import { useCreateOrEditRulesetModal } from '../../context/createOrEditRulesetModalContext'

interface ModalContentProps {
  children?: ReactNode
}

export const modalContentLayout: FormLayout = {
  columnSpacing: Spacing.XXXL,
  rowSpacing: Spacing.L,
  spacing: Spacing.L,
  itemsInRow: 2,
}

export const ModalContent = ({ children }: ModalContentProps): ReactElement => {
  const { Form } = useCreateOrEditRulesetModal()

  return <Form layout={modalContentLayout}>{children}</Form>
}
