import { LabsFlag } from '../../../../../enums/labsFlag'
import { LabsMenuFlags } from '../../../../../hooks/useLabsMenuFlags'
import { MenuItemId } from '../types/menuItemId'

export const shouldSkipRenderItemByLabsFlag = (menuId: MenuItemId, labsFlags: LabsMenuFlags) => {
  if (menuId === 'bank' && labsFlags[LabsFlag.ReactBankReconciliation]) {
    return true
  }

  if (menuId === 'bankReact' && !labsFlags[LabsFlag.ReactBankReconciliation]) {
    return true
  }

  if (menuId === 'vatSettlements' && labsFlags[LabsFlag.SalesTaxReturnWithSkatConnection]) {
    return true
  }

  if (menuId === 'vatSettlementsSkat' && !labsFlags[LabsFlag.SalesTaxReturnWithSkatConnection]) {
    return true
  }
}
