import { EmberRoute } from '../../../enums/emberRoute'
import { UmbrellaRoute } from '../enums/UmbrellaRoute'

export const umbrellaRouteToEmberRoute: Record<UmbrellaRoute, EmberRoute> = {
  [UmbrellaRoute.Inbox]: EmberRoute.Inbox,
  [UmbrellaRoute.Invoices]: EmberRoute.UmbrellaInvoices,
  [UmbrellaRoute.Organizations]: EmberRoute.UmbrellaOrganizations,
  [UmbrellaRoute.Profile]: EmberRoute.UmbrellaProfile,
  [UmbrellaRoute.Roles]: EmberRoute.UmbrellaRoles,
  [UmbrellaRoute.Subscriptions]: EmberRoute.UmbrellaSubscriptions,
  [UmbrellaRoute.Users]: EmberRoute.UmbrellaUsers,
  [UmbrellaRoute.Settings]: EmberRoute.SettingsUserUmbrella,
  [UmbrellaRoute.SettingsProfile]: EmberRoute.SettingsUserUmbrella,
  [UmbrellaRoute.SettingsNotifications]: EmberRoute.SettingsNotificationUmbrella,
  [UmbrellaRoute.SettingsPrivacy]: EmberRoute.SettingsPrivacyUmbrella,
  [UmbrellaRoute.VoucherInbox]: EmberRoute.InboxVouchers,
}
