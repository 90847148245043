import React, { ReactElement } from 'react'

import { Size as ButtonSize } from '../Button/types/size'
import * as Styled from './styles'

export interface SkeletonButtonProps {
  width?: number
  fullWidth?: boolean
  size?: ButtonSize
}

export const SkeletonButton = ({ size = 'l', ...props }: SkeletonButtonProps): ReactElement => (
  <Styled.SkeletonButton size={size} {...props} />
)
