import { ModalProps } from '@design-system'

import React, { ReactElement } from 'react'

import { Bill } from '../../types/bill'
import { billReconciliationModalStates } from './constants/billReconciliationModalStates'
import { BankLinesContextProvider } from './contexts/bankLinesContext'
import { BankLinesFilteringContextProvider } from './contexts/bankLinesFilteringContext'
import { BillReconciliationContextProvider } from './contexts/billReconciliationContext'
import { ManualPaymentContextProvider } from './contexts/manualPaymentContext'
import { MatchContextProvider } from './contexts/matchContext'
import { ModalContentsOperationsContextProvider } from './contexts/modalContentsOperationsContext'
import { MultipleBillsContextProvider } from './contexts/multipleBillsContext'
import { ModalContentToggler } from './elements/ModalContentToggler'
import { BillReconciliationModalStateId } from './enums/billReconciliationModalStateId'

interface BillReconciliationModalProps extends ModalProps {
  bill?: Bill
  initialState: BillReconciliationModalStateId
}

export const BillReconciliationModal = ({
  bill,
  initialState,
  ...modalProps
}: BillReconciliationModalProps): ReactElement => (
  <BillReconciliationContextProvider bill={bill}>
    <MultipleBillsContextProvider>
      <MatchContextProvider>
        <BankLinesFilteringContextProvider>
          <BankLinesContextProvider>
            <ManualPaymentContextProvider>
              <ModalContentsOperationsContextProvider>
                <ModalContentToggler<BillReconciliationModalStateId>
                  initialState={initialState}
                  states={billReconciliationModalStates}
                  {...modalProps}
                />
              </ModalContentsOperationsContextProvider>
            </ManualPaymentContextProvider>
          </BankLinesContextProvider>
        </BankLinesFilteringContextProvider>
      </MatchContextProvider>
    </MultipleBillsContextProvider>
  </BillReconciliationContextProvider>
)
