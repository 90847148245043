import { IconCross } from '@components-deprecated'

import styled from '@emotion/styled'
import { Box, Flex } from 'rebass'

import { Themable } from '../../../../types/themable'

const LINE_WRAPPER_BASE_SPACING = 10

export const AddLineButton = styled(Box)`
  display: inline-block;
  cursor: pointer;
  user-select: none;
`

export const LineActions = styled(Flex)`
  position: absolute;
  top: 50%;
  right: -15px;
  align-items: flex-start;
  justify-content: center;
  transform: translateY(-50%);
`

export const RemoveLineButton = styled(IconCross)`
  cursor: pointer;
  user-select: none;
`

export const LineWrapper = styled.li<Themable>`
  margin-top: ${LINE_WRAPPER_BASE_SPACING}px;
  margin-bottom: ${LINE_WRAPPER_BASE_SPACING}px;
  padding-bottom: ${LINE_WRAPPER_BASE_SPACING}px;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.shade10};
  list-style: none;

  &:first-of-type {
    margin-top: ${LINE_WRAPPER_BASE_SPACING * 4}px;
  }
  &:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
  }
`
