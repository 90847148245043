import { WidgetSize } from '../enums/widgetSize'

export const getXCoordinate = (order: number, size: WidgetSize, orderOffset: number): number => {
  if (size === WidgetSize.FullWidth) {
    return 0
  }

  // Check if the order with the offset is even to position the widget
  const isWidgetOrderEven = (order + orderOffset) % 2

  return isWidgetOrderEven ? 1 : 0
}
