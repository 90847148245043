import { BorderRadius, Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

// these styles are temporary and are going to be updated
export const InvoicePreviewWrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: ${Spacing.XXL};
  border-radius: ${BorderRadius.XL};
  background-color: ${Color.Gray30};
`

export const InvoicePageWrapper = styled.div`
  width: 60%;
  height: calc(100vh - 100px);
  padding: ${Spacing.XXXL};
  background-color: ${Color.White};
`
