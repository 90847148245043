import { Color, SkeletonText } from '@design-system'

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import * as Styled from './styles'

export interface TaxDisplayProps {
  amount: number
  period?: string
  userHasToPayTax: boolean
  isLoading?: boolean
}

export const TaxDisplay = ({ amount, period, isLoading }: TaxDisplayProps) => {
  const { t } = useTranslation()

  const { organization } = useUserOrganization()

  const hasToPayTax = amount && amount > 0

  return (
    <Styled.Container>
      <>
        {isLoading ? (
          <SkeletonText width={100} variant="h1" />
        ) : (
          <>
            <Styled.Text colorVariant="secondary" variant="small">
              {t('dashboard.widget.vat.amount_info')}
            </Styled.Text>
            <Styled.AmountValue
              amount={Math.abs(amount)}
              currencyId={organization?.baseCurrencyId}
              textVariant="h1"
              color={hasToPayTax ? Color.Red : Color.Green}
            />

            <Styled.Text weight="regular" colorVariant="secondary" variant="micro">
              {`${t('dashboard.widget.vat.period')}: ${period}`}
            </Styled.Text>
          </>
        )}
      </>
    </Styled.Container>
  )
}
