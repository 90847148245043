import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const LayoutWrapper = styled.div`
  display: grid;
  padding: ${Spacing.XXXL};
  border-radius: ${DefaultValue.BorderRadius};
  grid-template-columns: 1fr 288px;
  grid-column-gap: ${Spacing.XXXL};
  background-color: ${Color.White};
  box-shadow: 0 2px 1px ${Color.Gray30};
`
