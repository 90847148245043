import {
  getDefaultTransition,
  Input as InputComponent,
  InputProps as InputComponentProps,
  Spacing,
} from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

interface InputProps extends InputComponentProps {
  clearable: boolean
  searchable: boolean
}

export const CreateNewContactButton = styled.button`
  appearance: none;
  display: flex;
  border: none;
  align-items: center;
  cursor: pointer;
`

export const ToggleIconWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

export const DropdownPagesLimit = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 0 ${Spacing.L};
`

export const Input = styled(InputComponent)<InputProps>`
  ${({ clearable }) =>
    clearable &&
    `
    &:hover {
      ${ClearInputWrapper} {
        opacity: 1;
      }
    }
  `}

  ${({ searchable, disabled, selectLook }) =>
    !searchable &&
    !disabled &&
    selectLook &&
    css`
      cursor: pointer;
    `}
`

export const ClearInputWrapper = styled.div`
  opacity: 0;
  margin-right: ${Spacing.S};
  ${getDefaultTransition('opacity')};

  &:last-child {
    margin-right: 0;
  }
`
