import colors from './colors'

export default {
  helpBubble: {
    width: 'max-content',
    padding: '3px 10px',
    fontSize: '14px',
    color: colors.white,
    lineHeight: '24px',
    borderRadius: '3px',
    background: 'rgba(132,151,165,.9)',
    ':after': {
      position: 'absolute',
      display: 'block',
      content: '" "',
      width: 0,
      height: 0,
      bottom: '-5px',
      left: '50%',
      marginLeft: '-4px',
      borderColor: 'rgba(132,151,165,.9) transparent transparent transparent',
      borderStyle: 'solid',
      borderWidth: '5px 5px 0 5px',
    },
  },
}
