import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SummaryBoxesWrapper = styled.ul`
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
`
export const SummaryBox = styled.li`
  min-width: 320px;
  padding-right: ${Spacing.XL};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 24px 24px;
  grid-gap: ${Spacing.XXS};
  margin-bottom: ${Spacing.L};
`
