import React, { ReactElement, useMemo } from 'react'

import { getAmountsFromBillLines } from '../../../../utils/getAmountsFromBillLines'
import { BillSummaryModule } from '../../../BillSummaryModule'
import { useBillView } from '../../contexts/billViewContext'
import { getBillLinesFormattedAndConverted } from './utils/getBillLinesFormattedAndConverted'

export const BillSummary = (): ReactElement => {
  const { isFetching, billData } = useBillView()

  const { bill } = billData || {}
  const billLinesFormatted = useMemo(() => getBillLinesFormattedAndConverted(billData), [billData])
  const { exclVatAmountTotal, inclVatAmountTotal, vatAmountTotal } = useMemo(
    () => getAmountsFromBillLines(billLinesFormatted),
    [billLinesFormatted],
  )

  return (
    <BillSummaryModule
      isLoading={isFetching}
      currencyId={bill?.currencyId || undefined}
      exclVatAmount={exclVatAmountTotal}
      inclVatAmount={inclVatAmountTotal}
      vatAmount={vatAmountTotal}
    />
  )
}
