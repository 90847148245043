import { Account } from '@modules-deprecated/app/accounts/types'

import { BookkeepingTagQuestionResponseResult } from '../types/bookkeepingTag'

export const getAccountFromBookkeepingTagAnswer = (
  answer: BookkeepingTagQuestionResponseResult,
  accounts?: Account[],
): Account | undefined => {
  const accountNo = answer?.account && parseInt(answer?.account, 10)
  return accountNo && !isNaN(accountNo) ? accounts?.find((account) => account.accountNo === accountNo) : undefined
}
