export enum Spacing {
  XXXS = '2px',
  XXS = '4px',
  XS = '6px',
  S = '8px',
  M = '12px',
  L = '16px',
  XL = '24px',
  XXL = '32px',
  XXXL = '40px',
  XXXXL = '80px',
}
