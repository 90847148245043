import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { DefaultValue } from '../../enums/defaultValue'
import { Spacing } from '../../enums/spacing'
import { getShadow } from '../../utils/getShadow'

export const SettingsLayoutWrapper = styled.div`
  position: relative;
  max-width: ${DefaultValue.ContainerWidth};
  width: 100%;
  margin: 0 auto;
  background-color: ${Color.White};
  border-radius: ${DefaultValue.BorderRadius};
  padding: ${Spacing.XXL} ${Spacing.XXXL};
  ${getShadow('raisedLight')};
`

interface ChildrenWrapperProps {
  twoColumns?: boolean
}

export const Row = styled.div<ChildrenWrapperProps>`
  display: flex;
  gap: ${Spacing.XXXL};
`

export const Column = styled.div`
  width: 50%;
  height: fit-content;
`
