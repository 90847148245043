import { Color, FontWeight } from '@design-system'

import styled from '@emotion/styled'
import React, { ReactElement, useRef } from 'react'
import { Box, BoxProps, Flex, Text, TextProps } from 'rebass'

import { useWindowSize } from '../../hooks'

const ItemsList = styled(Flex)`
  flex-wrap: wrap;
`

type ItemOptions = {
  truncateValue?: boolean
  itemPerRow?: boolean
  containerWidth: number
}

type ItemProps = BoxProps & Omit<ItemOptions, 'truncateValue'>

const Item = styled(Box)<ItemProps>`
  ${({ containerWidth, itemPerRow }) => {
    if (itemPerRow) {
      return `
        width: 100%;

        &:not(:first-of-type) {
          margin-top: 28px;
        }
      `
    }

    if (containerWidth >= 800) {
      return `
        width: 20%;
        margin-right: 6%;

        &:nth-of-type(4n) {
          margin-right: 0;
        }
        &:nth-of-type(n + 5) {
          margin-top: 28px;
        }
      `
    }

    return `
      width: 44%;

      &:nth-of-type(2n) {
        margin-left: 12%;
      }
      &:nth-of-type(n + 3) {
        margin-top: 28px;
      }
    `
  }};
`

type ItemValueProps = TextProps & Pick<ItemOptions, 'truncateValue'>

const ItemValue = styled(Text)<ItemValueProps>`
  font-weight: ${FontWeight.Medium};

  ${({ truncateValue }) =>
    truncateValue &&
    `
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`

const renderItems = (items: GridListItem[], options: ItemOptions) => {
  const { containerWidth, itemPerRow, truncateValue } = options

  return items.map((item) => {
    const { id, text, value } = item

    return (
      <Item key={id} as="li" containerWidth={containerWidth} itemPerRow={itemPerRow}>
        <Text color={Color.Gray90}>{text}</Text>
        <ItemValue truncateValue={truncateValue} title={truncateValue ? value : ''}>
          {value}
        </ItemValue>
      </Item>
    )
  })
}

export type GridListItem<T = string> = {
  id: T
  text: string
  value: string
}

export type GridListProps<T = string> = BoxProps & {
  itemPerRow?: boolean
  items: GridListItem<T>[]
  truncateValue?: boolean
}

export const GridList = <T extends string>({
  items,
  truncateValue = true,
  itemPerRow,
  ...rest
}: GridListProps<T>): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null)
  const options: ItemOptions = {
    containerWidth: containerRef?.current?.clientWidth || 0,
    itemPerRow,
    truncateValue,
  }
  useWindowSize()

  return (
    <Box ref={containerRef} {...rest}>
      <ItemsList as="ul">{renderItems(items, options)}</ItemsList>
    </Box>
  )
}
