import { OrganizationSettings } from '@modules-deprecated/app/organization/types/organizationSettings'

import { OrganizationFeature, organizationFeaturesTypes } from '../enums/organizationFeature'
import { isFeatureDefinedInOrganizationSettings } from './isFeatureDefinedInOrganizationSettings'

export const filterOutClosedFeatures = (
  features: OrganizationFeature[],
  organizationSettings: OrganizationSettings | undefined,
): OrganizationFeature[] => {
  const featuresFiltered: OrganizationFeature[] = []

  for (const feature of features) {
    if (organizationFeaturesTypes[feature] === 'open') {
      featuresFiltered.push(feature)
    } else if (organizationFeaturesTypes[feature] === 'closed') {
      const isSettingPresent = isFeatureDefinedInOrganizationSettings(organizationSettings, feature)

      if (isSettingPresent) {
        featuresFiltered.push(feature)
      }
    }
  }

  return featuresFiltered
}
