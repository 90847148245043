import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { deleteTaxRate } from '../query-api'

interface UseDeleteVatRateProps {
  onError?: (error?: APIError) => void
  onSettled?: (data: void, error: APIError | null | undefined) => void
  onSuccess?: (data: void) => void
}

export const useDeleteVatRate = (props?: UseDeleteVatRateProps) => {
  const { onError, onSuccess, onSettled } = props || {}
  const { mutate, ...rest } = useMutation(deleteTaxRate, {
    onError,
    onSuccess,
    onSettled,
  })

  return { delete: mutate, ...rest }
}
