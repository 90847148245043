import { Global } from '@emotion/core'
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'
import React, { createContext, ReactElement, ReactNode, useContext, useMemo } from 'react'
import 'simplebar-react/dist/simplebar.min.css'

import { baseStyles, globalStyles, themeDark, themeLight } from '../../styles-deprecated'
import { ThemeLayoutMode } from '../../types/themeLayoutMode'
import { useThemeLayout } from './hooks/useThemeLayout'

export type ThemeProviderProps = {
  children: ReactNode
}

const completeThemeLight = {
  ...baseStyles,
  ...themeLight,
}

const completeThemeDark = {
  ...baseStyles,
  ...themeDark,
}

interface ContextState {
  themeLayout?: ThemeLayoutMode
}

const ThemeProviderContext = createContext<ContextState | undefined>(undefined)

export const ThemeProvider = ({ children }: ThemeProviderProps): ReactElement => {
  const { themeLayout } = useThemeLayout()

  const theme = useMemo(() => {
    if (themeLayout === 'light') {
      return completeThemeLight
    }

    if (themeLayout === 'dark') {
      return completeThemeDark
    }

    return completeThemeLight // add logic for checking current mode
  }, [themeLayout])

  return (
    <ThemeProviderContext.Provider value={{ themeLayout }}>
      <Global styles={globalStyles} />
      <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
    </ThemeProviderContext.Provider>
  )
}

export const useThemeContext = () => {
  const context = useContext(ThemeProviderContext)

  if (!context) {
    throw new Error('ThemeProviderContext is missing in the module!')
  }

  return context
}
