import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface LayoutWithSideFormProps {
  children?: ReactNode
  sideForm?: ReactNode
}

export const LayoutWithSideForm = ({ children, sideForm }: LayoutWithSideFormProps): ReactElement => (
  <Styled.LayoutWithSideFormWrapper>
    <Styled.ContentSide>{children}</Styled.ContentSide>

    <Styled.FormSide>{sideForm}</Styled.FormSide>
  </Styled.LayoutWithSideFormWrapper>
)
