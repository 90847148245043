import { Attachment, AttachmentFile, notify } from '@design-system'

import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'

import { useDirtyRoute } from '../../../../../contexts/dirtyRouteContext'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { useSegment } from '../../../../../hooks'
import { APIError } from '../../../../../utils'
import { getErrorMessage } from '../../../../../utils/getErrorMessage'
import { useBillsEmberConnection } from '../../../contexts/billsEmberConnectionContext'
import { BillsRoute } from '../../../enums/billsRoute'
import { BillSubmitAction } from '../../../enums/billSubmitAction'
import { BulkMode } from '../../../enums/bulkMode'
import { useBulkAttachmentsNavigation } from '../../../hooks/useBulkAttachmentsNavigation'
import { useBulkBillsNavigation } from '../../../hooks/useBulkBillsNavigation'
import {
  CreateBillPayload,
  CreateBillResponseData,
  GetBillResponseData,
  UpdateBillPayload,
  UpdateBillResponseData,
} from '../../../query-api'
import { useInvalidateBills } from '../../../routes/BillsList'
import { getBillEditPath } from '../../../utils/getBillEditPath'
import { getBillViewPath } from '../../../utils/getBillViewPath'
import { decorateAttachmentsFilesWithAttachmentId } from '../utils/decorateAttachmentsFilesWithAttachmentId'
import { useCreateBill } from './useCreateBill'
import { useUpdateBill } from './useUpdateBill'

interface UseBillMutationsProps {
  billIdToCreateCreditNote?: string
  bulkEditMode?: BulkMode
  handleErrorManually?: (error: APIError | undefined) => boolean
}

export const useBillMutations = ({
  billIdToCreateCreditNote,
  bulkEditMode,
  handleErrorManually,
}: UseBillMutationsProps) => {
  const queryClient = useQueryClient()
  const location = useLocation()
  const history = useHistory()
  const { track } = useSegment()
  const { t } = useTranslation()
  const { invalidateBill: invalidateEmberBill } = useBillsEmberConnection()
  const { hasNextBill, goToNextBill } = useBulkBillsNavigation()
  const { hasNextAttachment, goToNextAttachment } = useBulkAttachmentsNavigation()
  const { setDirty } = useDirtyRoute()
  const submitActionRef = useRef<BillSubmitAction>()
  const attachmentsFilesRef = useRef<AttachmentFile[]>()
  const { invalidateBills } = useInvalidateBills()

  // Optimistic update of the query data so bill doesn't have to be refetched after creating or updating

  const updateBillQueryData = useCallback(
    (response: CreateBillResponseData | UpdateBillResponseData) => {
      const billId = response.bills[0].id
      const attachmentsFiles = decorateAttachmentsFilesWithAttachmentId(
        attachmentsFilesRef.current,
        response.attachments,
      )

      const previousData: GetBillResponseData | undefined = queryClient.getQueryData([QueryKeys.Bill, billId])
      const queryData: GetBillResponseData = {
        ...(previousData || {}),
        bill: {
          ...response.bills[0],
          attachments: attachmentsFiles?.map((file) => ({ file, id: file.attachmentId }) as Attachment) || [],
        },
        billLines: response.billLines || [],
      }

      queryClient.setQueryData([QueryKeys.Bill, billId], queryData)

      const isSomeAttachmentNewlyOwnered = attachmentsFilesRef.current?.find(
        (attachmentsFile) => !!attachmentsFile.attachmentId,
      )

      if (isSomeAttachmentNewlyOwnered) {
        queryClient.invalidateQueries(QueryKeys.ReceiptsList)
        queryClient.invalidateQueries(QueryKeys.AttachmentsCount)
      }

      queryClient.invalidateQueries(QueryKeys.Transactions)
      queryClient.invalidateQueries(QueryKeys.AccountStatements)
    },
    [queryClient],
  )

  const redirectAfterBulkAction = useCallback(() => {
    if (bulkEditMode === BulkMode.Bill) {
      if (hasNextBill) {
        goToNextBill()
      } else {
        history.replace(`${BillsRoute.List}${location.search}`)
      }
    }

    if (bulkEditMode === BulkMode.Attachment) {
      if (hasNextAttachment) {
        goToNextAttachment()
      } else {
        history.replace(`${BillsRoute.List}${location.search}`)
      }
    }
  }, [bulkEditMode, goToNextAttachment, goToNextBill, hasNextAttachment, hasNextBill, history, location.search])

  const handleCreateOrUpdateSuccess = useCallback(
    async (response: CreateBillResponseData | UpdateBillResponseData, isCreateAction?: boolean) => {
      const billId = response.bills[0].id
      const creditedBillId = response.bills[0].creditedBillId
      setDirty(false)
      invalidateBills()
      invalidateEmberBill(billId) // invalidating Ember side of the bill to reload transaction view & transaction attachments
      updateBillQueryData(response)
      await queryClient.invalidateQueries(QueryKeys.ReceiptsList)
      await queryClient.invalidateQueries(QueryKeys.NextVoucherNo)

      switch (submitActionRef.current) {
        case BillSubmitAction.Approve:
        case BillSubmitAction.ApproveBulk:
          notify({
            id: 'bill-approve',
            message: t('bill.notification.approve_success'),
            variant: 'success',
          })

          if (response.bills[0].type === 'creditNote') {
            track('Bill Credit Completed (FE)')
          } else {
            track('Bill Approved (FE)', {
              context: submitActionRef.current === BillSubmitAction.Approve ? 'single' : 'bulk',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              has_attachment: !!response.attachments,
            })
          }

          if (submitActionRef.current === BillSubmitAction.Approve) {
            if (billIdToCreateCreditNote) {
              await queryClient.invalidateQueries([QueryKeys.Bill, billIdToCreateCreditNote])
            }
            if (creditedBillId) {
              await queryClient.invalidateQueries([QueryKeys.Bill, creditedBillId])
            }
            await queryClient.invalidateQueries([QueryKeys.Bill, billId])
            history.replace(`${getBillViewPath(billId)}${location.search}`)
          } else {
            redirectAfterBulkAction()
          }

          break

        case BillSubmitAction.Save:
          notify({
            id: 'bill-save-as-draft',
            message: t('bill.notification.save_success'),
            variant: 'success',
          })

          if (isCreateAction) {
            history.replace(`${getBillEditPath(billId)}${location.search}`)
          }

          track('Bill Create Draft Completed (FE)', {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            has_attachment: !!response.attachments,
          })

          break
      }
    },
    [
      billIdToCreateCreditNote,
      history,
      invalidateBills,
      invalidateEmberBill,
      location.search,
      queryClient,
      redirectAfterBulkAction,
      setDirty,
      t,
      track,
      updateBillQueryData,
    ],
  )

  // Creating new bill

  const { create, isLoading: isCreateProcessing } = useCreateBill({
    onSuccess: (response) => handleCreateOrUpdateSuccess(response, true),
    onError: (error) => {
      const isErrorHandled = handleErrorManually?.(error)

      if (isErrorHandled) {
        return
      }

      const message = getErrorMessage(error, 'bill')

      if (
        submitActionRef.current === BillSubmitAction.Approve ||
        submitActionRef.current === BillSubmitAction.ApproveBulk
      ) {
        notify({
          id: 'bill-approve',
          message: message || t('bill.notification.approve_error'),
          variant: 'error',
        })
      } else if (submitActionRef.current === BillSubmitAction.Save) {
        notify({
          id: 'bill-save-as-draft',
          message: message || t('bill.notification.save_error'),
          variant: 'error',
        })
      }
    },
    onSettled: () => {
      submitActionRef.current = undefined
      attachmentsFilesRef.current = undefined
    },
  })

  // Updating existing bill

  const { update, isLoading: isUpdateProcessing } = useUpdateBill({
    onSuccess: (response) => handleCreateOrUpdateSuccess(response, false),
    onError: (error) => {
      const isErrorHandled = handleErrorManually?.(error)

      if (isErrorHandled) {
        return
      }

      const message = getErrorMessage(error, 'bill')

      notify({
        id: 'bill-updated',
        message: message || t('bill.notification.update_error'),
        variant: 'error',
      })
    },
    onSettled: () => {
      submitActionRef.current = undefined
      attachmentsFilesRef.current = undefined
    },
  })

  const createBill = useCallback(
    (payload: CreateBillPayload, submitAction: BillSubmitAction, attachmentsFiles: AttachmentFile[] | undefined) => {
      submitActionRef.current = submitAction
      attachmentsFilesRef.current = attachmentsFiles
      create(payload)
    },
    [create],
  )

  const updateBill = useCallback(
    (payload: UpdateBillPayload, submitAction: BillSubmitAction, attachmentsFiles?: AttachmentFile[] | undefined) => {
      submitActionRef.current = submitAction
      attachmentsFilesRef.current = attachmentsFiles
      update(payload)
    },
    [update],
  )

  const processingAction = isCreateProcessing || isUpdateProcessing ? submitActionRef.current : undefined

  return {
    createBill,
    isCreateProcessing,
    isUpdateProcessing,
    processingAction,
    updateBill,
  }
}
