import React, { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { EmberEventFn } from '../../../types/emberEventFn'

interface ContextState {
  handleAddTransaction: () => void
}

const TransactionsEmberConnectionContext = createContext<ContextState | undefined>(undefined)

interface TransactionsEmberConnectionContextProps {
  onAddTransaction: EmberEventFn
  children: ReactNode
}

export const TransactionsEmberConnectionContextProvider = ({
  onAddTransaction,
  children,
}: TransactionsEmberConnectionContextProps): ReactElement => {
  const handleAddTransaction = useCallback(() => {
    onAddTransaction({ detail: null })
  }, [onAddTransaction])

  return (
    <TransactionsEmberConnectionContext.Provider value={{ handleAddTransaction }}>
      {children}
    </TransactionsEmberConnectionContext.Provider>
  )
}

export const useTransactionsEmberConnection = () => {
  const context = useContext(TransactionsEmberConnectionContext)

  if (!context) {
    throw new Error('TransactionsEmberConnectionContextProvider is missing in the module!')
  }

  return context
}
