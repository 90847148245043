import React, { ReactElement } from 'react'

import { useTheme } from '../../../../hooks/useTheme'
import { Text, TextProps } from '../../../Text'
import { getStateToStyles } from '../../utils/getStateToStyles'

type NavListItemTextProps = TextProps

export const NavListItemText = ({ color, children, ...rest }: NavListItemTextProps): ReactElement => {
  const theme = useTheme()
  const { textColor } = getStateToStyles(theme).default

  return (
    <Text color={color || textColor} weight="medium" truncate {...rest} selectable={false}>
      {children}
    </Text>
  )
}
