import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { Contacts } from '@views/contacts'
import { ContactsEmberConnectionContextProvider } from '@views/contacts/context/contactsEmberConnectionContext'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

const MODULE_NAME = 'contacts-module'

class ContactsWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('createContact') createContact: Function
}

interface ContactsModuleProps {
  organizationId: string
  onNavigate: EmberNavigate
  onCreateContact: EmberEventFn
}

const ContactsModule = ({ organizationId, onNavigate, onCreateContact }: ContactsModuleProps): ReactElement => {
  return (
    <ThemeProvider>
      <HashRouter>
        <QueryClientProvider client={queryClient}>
          <CurrentUserContextProvider>
            <UserOrganizationContextProvider organizationId={organizationId}>
              <EmberRouterContextProvider onNavigate={onNavigate}>
                <ContactsEmberConnectionContextProvider onCreateNewContact={onCreateContact}>
                  <Contacts />
                </ContactsEmberConnectionContextProvider>
              </EmberRouterContextProvider>
            </UserOrganizationContextProvider>
          </CurrentUserContextProvider>
        </QueryClientProvider>
      </HashRouter>
    </ThemeProvider>
  )
}

const ContactsCustomElement = createCustomElement(ContactsModule, ContactsWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, ContactsCustomElement)

export default ContactsCustomElement
