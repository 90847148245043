import styled from '@emotion/styled'

export const PeriodNavigationWrapper = styled.div`
  display: flex;
`

export const NavigationLabelWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`
