import { Spacing } from '@design-system'

import styled from '@emotion/styled'
import { ElementType } from 'react'

interface ContainerProps {
  as?: ElementType
}

export const Container = styled.form<ContainerProps>`
  flex: 0 1 700px;
`

export const InfoWrapper = styled.div`
  margin-bottom: ${Spacing.L};
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const ThumbnailFormatterWrapper = styled.div`
  margin: -${Spacing.S} 0;
`
