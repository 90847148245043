import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

export const freePlans = [SubscriptionPlan.Basic, SubscriptionPlan.Free]

export const plansWithLimits = [SubscriptionPlan.Basic, SubscriptionPlan.Free]

export const planWithOldUsageInfo = [SubscriptionPlan.Premium, SubscriptionPlan.Pro]

export const plansWithoutCoupons = [SubscriptionPlan.Complete]

export const plansWithNoUsageInfo = [
  SubscriptionPlan.BasicPaid,
  SubscriptionPlan.Plus,
  SubscriptionPlan.Pro,
  SubscriptionPlan.Complete,
]

export const plansWithNoPeriodSelection = [
  SubscriptionPlan.Basic,
  SubscriptionPlan.Free,
  SubscriptionPlan.Archive,
  SubscriptionPlan.Complete,
]

export const plansPossibleToChange = [
  SubscriptionPlan.Basic,
  SubscriptionPlan.BasicPaid,
  SubscriptionPlan.Free,
  SubscriptionPlan.Plus,
  SubscriptionPlan.Premium,
  SubscriptionPlan.Pro,
]

export const isPaidPlan = (plan: SubscriptionPlan) => freePlans.indexOf(plan) === -1
