import { Organization } from '@modules-deprecated/app/organization/types/organization'
import { OrganizationSettings } from '@modules-deprecated/app/organization/types/organizationSettings'

import { InvoiceAndProductSettingsForm } from './formData'
import { getOrganizationSettingFormValueFromData } from './getOrganizationSettingFormValueFromData'
import { getPaymentTermsModeFormValue } from './getPaymentTermsModeFormValue'
import { shouldShowNextInvoiceNoField } from './shouldShowNextInvoiceNoField'

export const convertInvoiceSettingsDataToForm = (
  organization: Organization,
  organizationSettings: OrganizationSettings,
): InvoiceAndProductSettingsForm => {
  const {
    defaultInvoiceBankAccountId,
    defaultSalesAccountId,
    defaultSalesTaxRulesetId,
    defaultTaxMode,
    emailAttachmentDeliveryMode,
    invoiceNoMode,
    localeId,
    nextInvoiceNo,
    paymentTermsDays,
  } = organization || {}
  const { isDuplicateInvoiceCheckDisabled, isOrderNoEnabled, isSummaryInclVat } = organizationSettings || {}

  return {
    defaultInvoiceBankAccountId: defaultInvoiceBankAccountId || '',
    defaultSalesAccountId: defaultSalesAccountId || '',
    defaultSalesTaxRulesetId: defaultSalesTaxRulesetId || '',
    defaultTaxMode,
    emailAttachmentDeliveryMode,
    invoiceNoMode,
    isDuplicateInvoiceCheckDisabled: getOrganizationSettingFormValueFromData(isDuplicateInvoiceCheckDisabled, true),
    isOrderNoEnabled: getOrganizationSettingFormValueFromData(isOrderNoEnabled),
    isSummaryInclVat: getOrganizationSettingFormValueFromData(isSummaryInclVat),
    localeId,
    nextInvoiceNo,
    paymentTermsDays,
    paymentTermsMode: getPaymentTermsModeFormValue(organization),
    shouldShowNextInvoiceNoField: shouldShowNextInvoiceNoField(invoiceNoMode),
  }
}
