import cc from 'classcat'
import React from 'react'
import { Flex, FlexProps } from 'rebass'

import { reactClass } from '../../utils'

export type ModalFooterProps = FlexProps

export const ModalFooter = ({ children, className, ...rest }: ModalFooterProps) => (
  <Flex className={cc([className, reactClass('modal-footer')])} variant="modal.footer" {...rest}>
    {children}
  </Flex>
)
