import { BorderRadius, Color, FontWeight } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../types/themable'

export const Container = styled.div``

export const GridLabel = styled.p<Themable>`
  margin: 8px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray7};
`

export const Grid = styled.div<Themable>`
  display: grid;
  grid-gap: 1px;
  border-radius: ${BorderRadius.L};
  background-color: ${Color.DeepSeaGreen};
  padding: 1px;
`

interface GridItemProps extends Themable {
  selected: boolean
}

export const GridItem = styled.div<GridItemProps>`
  grid-area: 1;
  padding: 9px 14px;
  text-align: center;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? Color.DeepSeaGreen : Color.White)};
  color: ${({ selected }) => (selected ? Color.White : Color.DeepSeaGreen)};
  font-weight: ${FontWeight.Medium};
  font-size: 14px;

  &:first-child {
    border-top-left-radius: ${BorderRadius.L};
    border-bottom-left-radius: ${BorderRadius.L};
  }
  &:last-child {
    border-top-right-radius: ${BorderRadius.L};
    border-bottom-right-radius: ${BorderRadius.L};
  }
`
