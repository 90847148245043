import { ItemsPerPage, useItemsPerPage } from '@design-system'

import { useCallback, useMemo } from 'react'

import { SortDirection } from '../../../enums/sortDirection'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { ContactsSortProperty } from '../query-api'

export interface ContactsListFilters {
  isArchived?: boolean
  isCustomer?: boolean
  isSupplier?: boolean
  page?: number
  pageSize?: ItemsPerPage
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: ContactsSortProperty
}

type UseContactsFiltersResponse = [ContactsListFilters, (filters: Partial<ContactsListFilters>) => void]

export const defaultContactsQuery: ContactsListFilters = {
  isArchived: false,
  isCustomer: undefined,
  isSupplier: undefined,
  page: 1,
  pageSize: undefined,
  searchQuery: undefined,
  sortDirection: SortDirection.Asc,
  sortProperty: ContactsSortProperty.Name,
}

export const useContactsListFilters = (): UseContactsFiltersResponse => {
  const [pageSize] = useItemsPerPage()
  const [queryParams, setQueryParams] = useQueryParams<ContactsListFilters>({
    defaultQuery: {
      ...defaultContactsQuery,
      pageSize,
    },
  })

  const setFilters = useCallback(
    (params: ContactsListFilters) => {
      setQueryParams(params)
    },
    [setQueryParams],
  )

  return useMemo(() => {
    const { isArchived, isCustomer, isSupplier, page, pageSize } = queryParams

    return [
      {
        ...queryParams,
        // Sometimes `page` comes as a string. It needs to be always `number` type.
        page: Number(page),
        // Sometimes `pageSize` comes as a string. It needs to be always `number` type.
        pageSize: Number(pageSize) as ItemsPerPage,
        // Sometimes `isArchived` comes as a string. It needs to be always `boolean` type.
        isArchived: typeof isArchived === 'string' ? isArchived === 'true' : Boolean(isArchived),
        // Sometimes `isCustomer` comes as a string. It needs to be always `boolean` type.
        isCustomer: typeof isCustomer === 'string' ? isCustomer === 'true' : Boolean(isCustomer),
        // Sometimes `isSupplier` comes as a string. It needs to be always `boolean` type.
        isSupplier: typeof isSupplier === 'string' ? isSupplier === 'true' : Boolean(isSupplier),
      },
      setFilters,
    ]
  }, [queryParams, setFilters])
}
