import { Payroll } from '../../../types/payroll'

export const getPayrollSplittedLists = (payrollList: Payroll[]) => {
  const payrollListCurrent: Payroll[] = []
  const payrollListPrevious: Payroll[] = []

  for (const payroll of payrollList) {
    if (payroll.isPrevious) {
      payrollListPrevious.push(payroll)
    } else {
      payrollListCurrent.push(payroll)
    }
  }

  return {
    payrollListCurrent,
    payrollListPrevious,
  }
}
