import { SkeletonBox } from '@design-system'

import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { getClassName } from '../../../../../../utils/getClassName'
import { useInvoice } from '../../contexts/invoiceContext'
import { useFetchInvoiceHtml } from '../../hooks/useFetchInvoiceHtml'
import * as Styled from './styles'

const INVOICE_PREVIEW_MIN_HEIGHT = 1062

export const InvoiceHtmlPreview = (): ReactElement | null => {
  const [isLoading, setIsLoading] = useState(true)
  const [previewHeight, setPreviewHeight] = useState('0px')
  const previewIframeRef = useRef<HTMLIFrameElement>(null)
  const { documentType, invoiceId } = useInvoice()
  const { data } = useFetchInvoiceHtml(invoiceId, documentType)

  const handleHtmlPreviewLoad = useCallback(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    const iframeContentHeight = previewIframeRef.current?.contentWindow?.document.body.scrollHeight

    if (!isLoading && iframeContentHeight) {
      setPreviewHeight(`${Math.max(iframeContentHeight, INVOICE_PREVIEW_MIN_HEIGHT)}px`)
    }
  }, [isLoading])

  return (
    <Styled.InvoiceHtmlPreviewWrapper className={getClassName('dark-theme-suspend')}>
      <Styled.InvoicePreviewSkeletonWrapper hidden={!isLoading}>
        <SkeletonBox height={1100} fullWidth />
      </Styled.InvoicePreviewSkeletonWrapper>
      <Styled.InvoicePreviewIframe
        ref={previewIframeRef}
        height={previewHeight}
        hidden={isLoading}
        onLoad={handleHtmlPreviewLoad}
        scrolling="no"
        srcDoc={data?.data}
        title="invoice-html-preview"
      ></Styled.InvoicePreviewIframe>
    </Styled.InvoiceHtmlPreviewWrapper>
  )
}
