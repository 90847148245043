import { addMonths, endOfMonth, format, isAfter, startOfDay, subYears } from 'date-fns'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, BoxProps } from 'rebass'

import DateRangeSlider from './DateRangeSlider'
import { DATE_FORMAT } from './helpers'
import './styles/styles.scss'

const onchangeEvent = new Event('change', { bubbles: true })

export type FiscalDatePickerProps = BoxProps & {
  fiscalYearEndMonth: number
  firstFiscalYearStart: string
  firstFiscalYearEnd: string
  fiscalYear: number
  onDateRangeChange: (range: number[]) => void
}

export const FiscalDatePicker = ({
  fiscalYearEndMonth: oneIndexedFiscalYearEndMonth,
  firstFiscalYearStart,
  fiscalYear,
  onDateRangeChange,
  ...rest
}: FiscalDatePickerProps) => {
  const dateRangeContainerRef = useRef<HTMLDivElement | null>(null)
  const fiscalYearEndMonth = oneIndexedFiscalYearEndMonth - 1

  const debouncedOnChange = useCallback(debounce(onDateRangeChange, 250), [])

  const updateDom = useCallback((attribute: string, value: string) => {
    if (dateRangeContainerRef && dateRangeContainerRef.current) {
      ;(dateRangeContainerRef?.current?.parentNode as HTMLElement).setAttribute(attribute, value)
      dateRangeContainerRef.current.dispatchEvent(onchangeEvent)
    }
  }, [])

  const calculateDates = useCallback(
    (year = new Date().getFullYear()) => {
      let start = startOfDay(new Date(year, fiscalYearEndMonth + 1 > 11 ? 0 : fiscalYearEndMonth + 1, 1))
      let end = endOfMonth(addMonths(start, 11))

      if (isAfter(end, new Date())) {
        end = endOfMonth(new Date())
      }

      if (isAfter(start, end)) {
        start = subYears(start, 1)
      }

      return [format(start, DATE_FORMAT), format(end, DATE_FORMAT)]
    },
    [fiscalYearEndMonth],
  )

  const [dates, setDates] = useState(calculateDates())

  useEffect(() => {
    if (!fiscalYear) {
      return
    }

    setDates(calculateDates(fiscalYear))
  }, [fiscalYear, fiscalYearEndMonth, calculateDates])

  const handleDateRangeChange = useCallback(
    (range: number[]) => {
      debouncedOnChange([range[0], range[1] - 1])
    },
    [debouncedOnChange],
  )

  return (
    <Box ref={dateRangeContainerRef} {...rest}>
      <DateRangeSlider
        startDate={dates[0]}
        endDate={dates[1]}
        updateDom={updateDom}
        onDateRangeChange={handleDateRangeChange}
      />
    </Box>
  )
}
