import { useFetchTrialBalanceReport } from '../../../hooks/useFetchTrialBalanceReport'
import { useTrialBalanceFilters } from './useTrialBalanceFilters'

export const useTrialBalance = () => {
  const [options] = useTrialBalanceFilters()
  const { report, isLoading, isIdle } = useFetchTrialBalanceReport(options)

  return {
    report,
    isLoading,
    isIdle,
  }
}
