import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useSalaryConnectionStatus } from '@views/salaryIntegration'

import { ReactComponent as SalaryLogo } from '../../../assets/images/integrations/salary.svg'
import { useEmberRouter } from '../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../enums/emberRoute'
import { IntegrationStatusLabel } from '../elements/IntegrationCard/elements/IntegrationStatusLabel'
import { IntegrationId } from '../enums/integrationId'
import { IntegrationStatus } from '../enums/integrationStatus'
import { IntegrationItem } from '../types/integrationItem'

export const useSalaryIntegration = (): IntegrationItem => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { isSalaryAvailable, isLoading: isSalaryLoading, isConnected: isSalaryConnected } = useSalaryConnectionStatus()

  const integrationStatus = useMemo(() => {
    if (isSalaryLoading) {
      return null
    }

    return isSalaryConnected ? IntegrationStatus.Active : IntegrationStatus.Inactive
  }, [isSalaryLoading, isSalaryConnected])

  const buttonHandler = useCallback(() => {
    navigate(EmberRoute.SalaryIntegration)
  }, [navigate])

  const buttonText = useMemo(() => {
    if (integrationStatus === IntegrationStatus.Inactive) {
      return t('integrations.card.salary.button.activate')
    } else if (integrationStatus === IntegrationStatus.Active) {
      return t('integrations.card.salary.button.deactivate')
    }
  }, [integrationStatus, t])

  return {
    id: IntegrationId.Salary,
    name: t('integrations.card.salary.title'),
    description: t('integrations.card.salary.body'),
    readMoreLink: 'https://www.billy.dk/apps/salary/',
    imageNode: <SalaryLogo />,
    isHidden: !isSalaryAvailable,
    isHiddenLoading: isSalaryLoading,
    buttonHandler,
    buttonText,
    statusLabelNode: <IntegrationStatusLabel status={integrationStatus} isStatusLoading={isSalaryLoading} />,
    isStatusLoading: isSalaryLoading,
  }
}
