import React, { ReactElement, ReactNode } from 'react'

import { SettingsSectionColumnsContainer } from './elements/SettingsSectionColumnsContainer'
import { SettingsSectionContent } from './elements/SettingsSectionContent'
import { SettingsSectionFooter } from './elements/SettingsSectionFooter'
import { SettingsSectionRowsContainer } from './elements/SettingsSectionRowsContainer'
import * as Styled from './styles'

export interface SettingsSectionProps {
  children: ReactNode
}

export const SettingsSection = ({ children }: SettingsSectionProps): ReactElement => {
  return <Styled.SettingsSectionWrapper>{children}</Styled.SettingsSectionWrapper>
}

SettingsSection.ColumnsContainer = SettingsSectionColumnsContainer
SettingsSection.RowsContainer = SettingsSectionRowsContainer
SettingsSection.Content = SettingsSectionContent
SettingsSection.Footer = SettingsSectionFooter
