import { Tab, TabsGroup } from '@design-system'

import { useLocation } from 'react-router-dom'

import { useHiddenTabsCount } from '@views/settings/hooks/useHiddenTabsCount'

import { UmbrellaRoute } from '../../../../enums/UmbrellaRoute'
import { useUmbrellaUserSettingsTabItems } from './hooks/useUmbrellaUserSettingsTabItems'

export const UmbrellaSettingsNavigation = () => {
  const location = useLocation()
  const { items: userRoutes } = useUmbrellaUserSettingsTabItems()

  const currentRouteName = location.pathname
  const count = useHiddenTabsCount(userRoutes.length)

  return (
    <TabsGroup hiddenTabsCount={count}>
      {userRoutes.map(
        ({ badgeCounter, icon, route, childRoutes, text, hidden }) =>
          !hidden && (
            <Tab
              active={
                currentRouteName === route || (childRoutes && childRoutes.includes(currentRouteName as UmbrellaRoute))
              }
              badgeCounter={badgeCounter}
              icon={icon}
              key={route}
              to={route}
            >
              {text}
            </Tab>
          ),
      )}
    </TabsGroup>
  )
}
