import omit from 'lodash/omit'
import { useMutation } from 'react-query'

import { CustomEvent } from '../../../../enums/customEvent'
import { APIError } from '../../../../utils'
import { dispatchCustomEvent } from '../../../../utils/customEvent'
import { UPDATE_ORGANIZATION_PAYLOAD_OWNER_RESTRICTED } from '../constants/updateOrganizationPayloadOwnerRestricted'
import { updateOrganization, UpdateOrganizationPayload, UpdateOrganizationResponseData } from '../query-api'
import { Organization } from '../types/organization'
import { useUserOrganization } from '../userOrganizationContext'
import { useIsOrganizationOwner } from './useIsOrganizationOwner'

interface UseUpdateUserProps {
  onError?: (error?: APIError) => void
  onSettled?: (data: UpdateOrganizationResponseData | undefined, error: APIError | null | undefined) => void
  onSuccess?: (data: UpdateOrganizationResponseData) => void
}

export interface UpdateOrganizationProps {
  organizationId: string
  payload: UpdateOrganizationPayload
}

export const useUpdateOrganization = (props?: UseUpdateUserProps) => {
  const { isOrganizationOwner } = useIsOrganizationOwner()

  const { onError, onSuccess, onSettled } = props || {}
  const { organization } = useUserOrganization()
  const {
    mutate: update,
    mutateAsync: updateAsync,
    isLoading: isProcessing,
    ...rest
  } = useMutation(
    ({ organizationId, payload }: UpdateOrganizationProps) => {
      const payloadFiltered = !isOrganizationOwner
        ? omit(payload, UPDATE_ORGANIZATION_PAYLOAD_OWNER_RESTRICTED)
        : payload

      return updateOrganization(organizationId, payloadFiltered)
    },
    {
      onSuccess: (data) => {
        onSuccess?.(data)

        const updateOrganizationResponseData = data?.organizations.find(
          (updatedOrganization) => updatedOrganization?.id === organization?.id,
        )

        if (updateOrganizationResponseData) {
          dispatchCustomEvent<Partial<Organization>>(CustomEvent.OrganizationUpdated, updateOrganizationResponseData)
        }
      },
      onError,
      onSettled,
    },
  )

  return { update, updateAsync, isProcessing, ...rest }
}
