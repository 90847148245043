import { IconName } from '../../Icon'

interface GetSelectTogglerIconProps {
  isOpen?: boolean
  isFocused: boolean
  withSearch: boolean
  withSearchOnly: boolean
}

export const getSelectTogglerIcon = ({
  isOpen = false,
  isFocused,
  withSearch,
  withSearchOnly,
}: GetSelectTogglerIconProps): IconName => {
  if (withSearchOnly || (isFocused && withSearch)) {
    return 'magnifyingGlass'
  }

  return isOpen ? 'chevronUp' : 'chevronDown'
}
