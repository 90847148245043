import { SectionMessage, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const UmbrellaOrganizationsInvitationsSectionWrapper = styled.section`
  margin-bottom: ${Spacing.XL};
`

export const InfoSection = styled(SectionMessage)`
  margin-bottom: ${Spacing.XL};
`
