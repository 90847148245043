import { Button, ButtonsGroup, Link, Space, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useSegment, useUserflow } from '../../../../../../hooks'
import { useOnboarding } from '../../../../../../hooks/useOnboarding'
import { getImageClassName } from '../../../../../../utils/getClassName'
import { USERFLOW_ONBOARDING_CHECKLIST_ID } from '../../../../constants/userflowIds'
import { useDashboardSettings } from '../../../../contexts/dashboardSettingsContext'
import { WidgetId } from '../../../../enums/widgetId'
import * as Styled from '../../styles'

export const PostChecklistContent = (): ReactElement => {
  const { t } = useTranslation()
  const { toggleWidgetVisibility } = useDashboardSettings()
  const { start } = useUserflow()
  const { track } = useSegment()
  const { isAfterDayFromRegistering } = useOnboarding()

  const handleHideWidget = useCallback(() => {
    track('xxx - hamster - tile experiment button clicked', {
      button: 'got it',
    })
    toggleWidgetVisibility({ id: WidgetId.GetStarted, shouldHide: true })
  }, [toggleWidgetVisibility, track])

  const handleBookMeeting = useCallback(() => {
    track('xxx - hamster - tile experiment button clicked', {
      button: 'book',
    })
    window
      .open(
        'https://calendly.com/kom-godt-igang/kom-godt-i-gang-med-billy?month=2023-04?utm_source=mitbilly&utm_medium=internal&utm_campaign=onboardingtile',
        '_blank',
      )
      ?.focus()
  }, [track])

  const handleOpenChecklist = useCallback(() => {
    start(USERFLOW_ONBOARDING_CHECKLIST_ID)
  }, [start])

  return (
    <Styled.WidgetContentWrapper>
      <Styled.Header>
        <Space size="s" />
        <Text variant="displayM">{t('dashboard.widget.get_started_completed.title')}</Text>
        <Space />
        <Styled.PostChecklistDescriptionWrapper>
          <Text>
            <Trans i18nKey={`dashboard.widget.get_started_completed.description`}>
              Good job! Your profile is now set up correctly. What is next?
              <Space size="s" />
              You can follow the{' '}
              <Text inline hoverable>
                <Link withAnimation onClick={handleOpenChecklist}>
                  checklist
                </Link>
              </Text>{' '}
              in the bottom right corner, or book a 1:1 startup call with one of our helpful supporters.
            </Trans>
          </Text>
        </Styled.PostChecklistDescriptionWrapper>
      </Styled.Header>
      <Styled.Footer>
        <ButtonsGroup>
          <Button onClick={handleBookMeeting} variant="secondary">
            {t('dashboard.widget.get_started_completed.cta_book')}
          </Button>
          {isAfterDayFromRegistering && (
            <Button variant="text" onClick={handleHideWidget}>
              {t('dashboard.widget.get_started_completed.thanks')}
            </Button>
          )}
        </ButtonsGroup>
      </Styled.Footer>
      <Styled.PostChecklistIllustration className={getImageClassName()} />
    </Styled.WidgetContentWrapper>
  )
}
