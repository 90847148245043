import { useMutation } from 'react-query'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { deleteUmbrellaOrganizationInvitation } from '@modules-deprecated/app/umbrellas/query-api'

import { APIError } from '../../../../../utils'

interface UseDeleteOrganizationInvitationArgs {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useDeleteOrganizationInvitation = ({ onError, onSuccess }: UseDeleteOrganizationInvitationArgs = {}) => {
  const { umbrella } = useUmbrella()
  const { isLoading: isDeleting, mutate: deleteInvitation } = useMutation(
    (invitationId: string) => deleteUmbrellaOrganizationInvitation(umbrella?.id || '', invitationId),
    { onError, onSuccess },
  )

  return {
    deleteInvitation,
    isDeleting,
  }
}
