function isTickActive(index: number, tickValues: number[], selectedValues: number[]): boolean {
  if (tickValues[index + 1] === undefined) {
    return selectedValues[1] > tickValues[index]
  }

  if (selectedValues[1] < tickValues[index] || selectedValues[0] > tickValues[index + 1]) {
    return false
  }

  if (selectedValues[1] === tickValues[index]) {
    return false
  }

  if (selectedValues[0] < tickValues[index + 1] || selectedValues[1] + 20 < tickValues[index]) {
    return true
  }
  return false
}

export default isTickActive
