import { Translate } from '@components'
import { Table, TableColumn } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { Row } from 'react-table'

import { SortDirection } from '../../../../enums/sortDirection'
import { TableIds } from '../../../../enums/tableIds'
import { getTableColumnIdDirection } from '../../../../utils/getTableColumnIdDirection'
import { ContactBalanceSortProperty } from '../../enums/contactBalanceSortProperty'
import { ContactBalanceTableAccessor } from '../../enums/contactBalanceTableAccessor'
import { ContactBalanceType } from '../../enums/contactBalanceType'
import { useContactBalanceListFilters } from '../../hooks/useContactBalanceListFilters'
import { useContactBalanceTableData } from '../../hooks/useContactBalanceTableData'
import { ContactBalance } from '../../types/contactBalance'
import { ContactBalanceTableRow } from '../../types/contactBalanceTableRow'

interface ContactBalanceTableProps {
  contactBalanceType: ContactBalanceType
  data: ContactBalance[]
  isLoading?: boolean
  onRowClick?: (row: Row<ContactBalanceTableRow>) => void
}

export const ContactBalanceTable = ({
  contactBalanceType,
  data: contactBalanceData,
  isLoading,
  onRowClick,
}: ContactBalanceTableProps): ReactElement => {
  const [{ page, sortDirection, sortProperty }, setFilters] = useContactBalanceListFilters()
  const data = useContactBalanceTableData(contactBalanceData)

  const contactNameHeaderTKey =
    contactBalanceType === ContactBalanceType.Creditor
      ? 'contact_balance.table.column.contact_name.creditor'
      : 'contact_balance.table.column.contact_name.debtor'

  const columns: TableColumn<ContactBalanceTableRow>[] = useMemo(
    () => [
      {
        accessor: ContactBalanceTableAccessor.ContactName,
        Header: <Translate value={contactNameHeaderTKey} />,
        id: ContactBalanceSortProperty.ContactName,
        sortable: true,
        truncate: true,
      },
      {
        accessor: ContactBalanceTableAccessor.NotOverdue,
        alignment: 'right',
        Header: <Translate value="contact_balance.table.column.not_overdue" />,
        id: ContactBalanceSortProperty.NotOverdue,
        sortable: true,
        truncate: true,
      },
      {
        accessor: ContactBalanceTableAccessor.ShortRange,
        alignment: 'right',
        Header: <Translate value="contact_balance.table.column.short_range" />,
        id: ContactBalanceSortProperty.ShortRange,
        sortable: true,
        truncate: true,
      },
      {
        accessor: ContactBalanceTableAccessor.AverageRange,
        alignment: 'right',
        Header: <Translate value="contact_balance.table.column.average_range" />,
        id: ContactBalanceSortProperty.AverageRange,
        sortable: true,
        truncate: true,
      },
      {
        accessor: ContactBalanceTableAccessor.LongRange,
        alignment: 'right',
        Header: <Translate value="contact_balance.table.column.long_range" />,
        id: ContactBalanceSortProperty.LongRange,
        sortable: true,
        truncate: true,
      },
      {
        accessor: ContactBalanceTableAccessor.Total,
        alignment: 'right',
        Header: <Translate value="contact_balance.table.column.total" />,
        truncate: true,
      },
    ],
    [contactNameHeaderTKey],
  )

  const sortedColumnId = useMemo(() => {
    return getTableColumnIdDirection(sortProperty, sortDirection === SortDirection.Desc)
  }, [sortDirection, sortProperty])

  const handlePageChange = useCallback(
    (paginationPage: number) => {
      setFilters({ page: paginationPage })
    },
    [setFilters],
  )

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      setFilters({
        page: 1,
        sortProperty: columnId as ContactBalanceSortProperty,
        sortDirection: isDesc ? SortDirection.Desc : SortDirection.Asc,
      })
    },
    [setFilters],
  )

  const handleRowClick = useCallback(
    (row: Row<ContactBalanceTableRow>) => {
      onRowClick?.(row)
    },
    [onRowClick],
  )

  return (
    <Table
      columns={columns}
      currentPage={page}
      data={data}
      id={TableIds.ContactBalanceList}
      isClientSidePagination
      isLoading={isLoading}
      onPageChange={handlePageChange}
      onRowClick={handleRowClick}
      onSort={handleSort}
      sortedColumnId={sortedColumnId}
      withItemsPerPageSelect
      withStickyHeader
    />
  )
}
