import { useCurrentUser } from '../../user'
import { useUserOrganization } from '../userOrganizationContext'

export const useIsOrganizationOwner = () => {
  const { user, isLoading: isLoadingUser } = useCurrentUser()
  const { organization, isLoading: isLoadingOrganization } = useUserOrganization()

  const isOrganizationOwner = organization?.ownerUserId === user?.id
  const isLoading = isLoadingUser || isLoadingOrganization

  return { isOrganizationOwner, isLoading }
}
