import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'

import { WindowPosition } from '../types/windowPosition'
import { WindowSize } from '../types/windowSize'
import { getWindowPosition } from '../utils/getWindowPosition'

interface UseWindowPositionReturn extends WindowPosition, WindowSize {
  windowCenter: WindowPosition
}

export const useWindowPosition = (): UseWindowPositionReturn => {
  const [windowPosition, setWindowPosition] = useState<WindowPosition>(getWindowPosition())
  const { width, height } = useWindowSize()

  useEffect(() => {
    const handleWindowPosition = () => {
      setWindowPosition(getWindowPosition())
    }

    // this might cover some cases (e.g. moving window to another screen),
    // but sadly will not cover a regular window position change
    window.addEventListener('resize', handleWindowPosition)

    return () => {
      window.removeEventListener('resize', handleWindowPosition)
    }
  }, [])

  const windowCenter: WindowPosition = {
    left: windowPosition.left + width / 2,
    top: windowPosition.top + height / 2,
  }

  return {
    ...windowPosition,
    height,
    width,
    windowCenter,
  }
}
