import { Children, cloneElement, isValidElement, ReactElement, ReactNode, useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useClickAway, useKey } from 'react-use'

import { PORTAL_CLASS_NAME } from '../../constants/portalClassName'
import { DefaultValue } from '../../enums/defaultValue'
import { useComponentToggle } from '../../hooks/useComponentToggle'
import { transitionToMiliseconds } from '../../utils/transitionToMiliseconds'
import { useSidePanel } from './context/sidePanelContext'
import { SidePanelBody } from './elements/SidePanelBody'
import { SidePanelHeader } from './elements/SidePanelHeader'
import * as Styled from './styles'

export interface SidePanelProps {
  children?: ReactNode
  closable?: boolean
}

export const SidePanel = ({ children, closable = true }: SidePanelProps): ReactElement => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { isOpen, close } = useSidePanel()
  const { isVisible, isRendered } = useComponentToggle({
    isOpen,
    animationTime: transitionToMiliseconds(DefaultValue.TransitionDuration),
  })

  const handleClickAway = useCallback(() => {
    if (closable) {
      close?.()
    }
  }, [closable, close])

  const handleEscapePress = useCallback(() => {
    if (closable) {
      close?.()
    }
  }, [closable, close])

  useClickAway(wrapperRef, handleClickAway)
  useKey('Escape', handleEscapePress)

  return ReactDOM.createPortal(
    <>
      {isRendered && (
        <Styled.SidePanelWrapper ref={wrapperRef} isOpen={isVisible} className={PORTAL_CLASS_NAME}>
          {Children.map(children, (child) => {
            if (isValidElement<Pick<SidePanelProps, 'closable'>>(child)) {
              return cloneElement(child, { closable })
            }
            return child
          })}
        </Styled.SidePanelWrapper>
      )}
    </>,
    document.body,
  )
}

SidePanel.Header = SidePanelHeader
SidePanel.Body = SidePanelBody
