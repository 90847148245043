import { TFunction } from 'i18next'

import { TrackingContext } from '../../../../enums/trackingContext'
import {
  getTrackingContextToModalContentVariant,
  ModalContent,
} from '../constants/getTrackingContextToModalContentVariant'

export const getModalContent = (trackingContext: TrackingContext, t: TFunction): ModalContent | undefined => {
  let contextualModalContent
  const trackingContextToModalContentVariant = getTrackingContextToModalContentVariant(t)

  for (const [contexts, variant] of trackingContextToModalContentVariant) {
    if (contexts.includes(trackingContext)) {
      contextualModalContent = variant
      break
    }
  }

  return contextualModalContent
}
