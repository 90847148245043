import React, { ReactElement } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { defaultQuery } from '@views/settings/constants/defaultQuery'

import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { InvoicesRoute } from './enums/invoicesRoute'
import { useInvoiceEditorBeta } from './hooks/useInvoiceEditorBeta'
import { InvoiceEditor } from './routes/InvoiceEditor'
import { InvoicesList } from './routes/InvoicesList'
import { InvoiceView } from './routes/InvoiceView'
import { getInvoicePath } from './utils/getInvoicePath'

export const ExternalInvoices = (): ReactElement => {
  const emberQuery = useGetQueryFromEmberRoute({
    queryParamsValidKeys: [...Object.keys(defaultQuery)],
  })
  const { isEligibleForInvoiceEditorBeta } = useInvoiceEditorBeta()

  // trigger the useLocation hook in order to refresh the view on
  // location context change when using the back button on InvoiceView
  useLocation()

  return (
    <Switch>
      <Route exact path={InvoicesRoute.List}>
        {typeof emberQuery?.initialRoute === 'string' ? <Redirect to={emberQuery.initialRoute} /> : <InvoicesList />}
      </Route>
      <Route exact path={getInvoicePath(':invoiceId', ':documentType')}>
        <InvoiceView />
      </Route>
      {isEligibleForInvoiceEditorBeta && (
        <Route exact path={InvoicesRoute.New}>
          <InvoiceEditor />
        </Route>
      )}
      <Route>
        <Redirect to={InvoicesRoute.List} />
      </Route>
    </Switch>
  )
}
