import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { KeyboardKey } from '../../../enums/keyboardKey'
import { amountToNumber } from '../../utils/amountToNumber'
import { getEvaluatedExpression, getUnevaluatedExpression, isExpression } from '../../utils/numbers'
import { Input, InputProps } from '../Input'
import { InputRef } from '../Input/Input'
import { StepNavigation } from './elements/StepNavigation'
import { InputNumberValue } from './types/inputNumberValue'
import { StepDirection } from './types/stepDirection'
import { getFormattedValue } from './utils/getFormattedValue'
import { sanitizeMathCharacters } from './utils/sanitizeMathCharacters'

export interface InputNumberProps extends Omit<InputProps, 'value' | 'defaultValue' | 'onChange' | 'alignment'> {
  defaultValue?: number
  isFallbackToZero?: boolean // when true, the input will fallback to '0' when there's no value
  keyboard?: boolean
  max?: number
  min?: number
  onChange?: (value: number) => void
  onStep?: (value: number, step: number, direction: StepDirection) => void
  step?: number
  value?: number
  withCalculations?: boolean
  withFormatting?: boolean
}

export const InputNumber = forwardRef(
  (
    {
      defaultValue,
      isFallbackToZero = true,
      keyboard = false,
      max,
      min,
      onBlur,
      onChange,
      onFocus,
      onPressEnter,
      onStep,
      placeholder,
      readOnly,
      step = 1,
      suffix,
      value = 0,
      withCalculations = true,
      withFormatting = true,
      ...rest
    }: InputNumberProps,
    forwardedRef: Ref<InputRef>,
  ) => {
    const { value: valueFormatted } = getFormattedValue({
      value: defaultValue ?? value,
      isDisplayValueFormat: withFormatting,
      max,
      min,
      isFallbackToZero,
    })
    const [currentValue, setCurrentValue] = useState<InputNumberValue>(valueFormatted)
    const inputRef = useRef<InputRef>(null)

    // Functions

    const submit = useCallback(
      (value?: InputNumberValue) => {
        const evaluatedExpression = withCalculations
          ? getEvaluatedExpression(sanitizeMathCharacters(value ?? currentValue))
          : getUnevaluatedExpression(sanitizeMathCharacters(value ?? currentValue))
        const { value: valueFormatted } = getFormattedValue({
          value: evaluatedExpression,
          isDisplayValueFormat: withFormatting,
          max,
          min,
          isFallbackToZero,
        })

        const amountNumber = amountToNumber(valueFormatted)

        setCurrentValue(valueFormatted)

        if (amountNumber !== undefined) {
          onChange?.(amountNumber)
        }
      },
      [withCalculations, currentValue, withFormatting, max, min, isFallbackToZero, onChange],
    )

    const handleInputChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const value = event?.currentTarget.value
        const valueNumber = isExpression(value) ? undefined : amountToNumber(value)

        setCurrentValue(value)

        if (valueNumber !== undefined) {
          onChange?.(valueNumber)
        }
      },
      [onChange],
    )

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        submit()
        onBlur?.(event)
      },
      [onBlur, submit],
    )

    const handleFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        const currentValueNumber = amountToNumber(currentValue || '')

        // Clear the input when value is 0, so it's easier to start typing new value
        if (!currentValueNumber && !readOnly) {
          setCurrentValue('')
        }

        onFocus?.(event)
      },
      [currentValue, onFocus, readOnly],
    )

    const handlePressEnter = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        submit()
        onPressEnter?.(event)
      },
      [onPressEnter, submit],
    )

    const handleStepChange = useCallback(
      (direction: StepDirection) => {
        const currentValueNumber = amountToNumber(currentValue || '', true)
        const directionMultiplier = direction === 'up' ? 1 : -1
        const currentValueNumberWithStep = currentValueNumber + step * directionMultiplier

        submit(currentValueNumberWithStep)
        onStep?.(currentValueNumberWithStep, step, direction)
      },
      [currentValue, step, submit, onStep],
    )

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (!keyboard) {
          return
        }

        if (event.key === KeyboardKey.ArrowUp) {
          event.preventDefault()
          handleStepChange('up')
        } else if (event.key === KeyboardKey.ArrowDown) {
          event.preventDefault()
          handleStepChange('down')
        }
      },
      [handleStepChange, keyboard],
    )

    // Mutations

    useEffect(() => {
      if (value !== undefined) {
        const { value: valueFormatted, hasValueBeenLimited } = getFormattedValue({
          value,
          isDisplayValueFormat: withFormatting,
          max,
          min,
          isFallbackToZero,
        })
        const { value: currentValueFormatted } = getFormattedValue({
          value: currentValue,
          isDisplayValueFormat: withFormatting,
          max,
          min,
          isFallbackToZero,
        })
        const valueLimited = amountToNumber(valueFormatted)

        if (currentValueFormatted !== valueFormatted) {
          setCurrentValue(valueFormatted)
        }

        // code below updates value when props "min" or "max" are in use
        if (valueLimited !== undefined && hasValueBeenLimited) {
          onChange?.(valueLimited)
        }
      }
      // should trigger only on value change
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useImperativeHandle(forwardedRef, () => inputRef?.current as InputRef)

    return (
      <Input
        {...rest}
        alignment="right"
        autoComplete="off"
        onBlur={handleBlur}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onPressEnter={handlePressEnter}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={inputRef}
        suffix={
          suffix ||
          (keyboard && (
            <>
              <StepNavigation onChange={handleStepChange} />
            </>
          ))
        }
        value={currentValue}
      />
    )
  },
)
