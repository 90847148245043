import cc from 'classcat'
import React from 'react'
import { Box, BoxProps } from 'rebass'

import { reactClass } from '../../utils'

export type ModalBodyProps = BoxProps

export const ModalBody = ({ children, className, ...rest }: ModalBodyProps) => (
  <Box className={cc([className, reactClass('modal-body')])} variant="modal.body" {...rest}>
    {children}
  </Box>
)
