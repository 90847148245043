import React from 'react'

export const cloneThroughFragments = <T>(children: React.ReactNode, props: Partial<T>): React.ReactNode => {
  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === React.Fragment) {
        return cloneThroughFragments(child.props.children, props)
      }
      return React.cloneElement(child, { ...child.props, ...props })
    }
    return child
  })
}
