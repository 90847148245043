import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SkeletonBox } from '../SkeletonBox'
import { PaginationButton } from './elements/PaginationButton'
import { PaginationItem } from './elements/PaginationItem'
import { usePaginationItems } from './hooks/usePaginationItems'
import * as Styled from './styles'

export interface PaginationProps {
  currentPage?: number
  isSkeletonView?: boolean
  onChange: (page: number) => void
  totalPages: number
}

export const Pagination = ({
  currentPage = 1,
  isSkeletonView,
  onChange,
  totalPages,
}: PaginationProps): ReactElement | null => {
  const [current, setCurrent] = useState<number>(currentPage)
  const { t } = useTranslation()
  const paginationItems = usePaginationItems(current, totalPages)

  const isPreviousActionDisabled = current === 1
  const isNextActionAvailable = current === totalPages

  useEffect(() => {
    setCurrent(currentPage)
  }, [currentPage])

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrent(page)
      onChange(page)
    },
    [onChange],
  )

  const handlePreviousActionClick = useCallback(() => {
    setCurrent((previousCurrent) => {
      const newCurrent = previousCurrent - 1
      onChange(newCurrent)
      return newCurrent
    })
  }, [onChange])

  const handleNextActionClick = useCallback(() => {
    setCurrent((previousCurrent) => {
      const newCurrent = previousCurrent + 1
      onChange(newCurrent)
      return newCurrent
    })
  }, [onChange])

  if (isSkeletonView) {
    return <SkeletonBox height={32} width={320} />
  }

  return (
    <Styled.PaginationWrapper>
      <PaginationButton
        disabled={isPreviousActionDisabled}
        icon="chevronLeft"
        onClick={handlePreviousActionClick}
        title={t('pagination.previous_page')}
      />
      <Styled.PaginationList>
        {paginationItems.map((page, index) => (
          <PaginationItem
            isActive={current === page}
            key={page ? `page-${page}` : `index-${index}`}
            onClick={handlePageClick}
            page={page}
          />
        ))}
      </Styled.PaginationList>
      <PaginationButton
        disabled={isNextActionAvailable}
        icon="chevronRight"
        onClick={handleNextActionClick}
        title={t('pagination.next_page')}
      />
    </Styled.PaginationWrapper>
  )
}
