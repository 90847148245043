import { NavItem } from '@design-system'

import { getYear } from 'date-fns'

import { PeriodValue } from '../../../../../types/periodValue'
import { NavigationValue } from '../../types/navigationValue'

const CURRENT_YEAR = getYear(new Date())

export const getSelectedNavigationItem = (
  navigationItems: NavItem<NavigationValue>[],
  periodValueUpdated?: PeriodValue,
) => {
  const getDefaultYear = () => {
    if (periodValueUpdated?.year) {
      return periodValueUpdated?.year
    }

    if (periodValueUpdated?.customDate?.endDate) {
      return getYear(periodValueUpdated.customDate.endDate)
    }

    if (periodValueUpdated?.customDate?.startDate) {
      return getYear(periodValueUpdated.customDate.startDate)
    }

    return CURRENT_YEAR
  }

  return navigationItems.find((navigationItem) => {
    const defaultYear = getDefaultYear()

    const isMultipleYearNavigation =
      typeof navigationItem.value === 'string' && navigationItem.value.split(':').length === 2

    if (isMultipleYearNavigation && typeof navigationItem.value === 'string') {
      const [yearFrom, yearTo] = navigationItem.value.split(':')
      return defaultYear >= Number(yearFrom) && defaultYear <= Number(yearTo)
    }

    return String(navigationItem.value) === String(defaultYear)
  })
}
