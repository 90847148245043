import { useEffect, useState } from 'react'

export const useDraggingDetection = (classNames?: string): boolean => {
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    // setTimeout is obligatory to postpone the execution of code. The issue exists since 2014 and since that time Chrome has had issues with native DnD. :)
    setTimeout(() => {
      const isDraggingValue = !!classNames?.includes('react-draggable-dragging')
      setIsDragging(isDraggingValue)
      document.body.style.cursor = isDraggingValue ? 'grabbing' : 'unset'
    }, 0)
  }, [classNames])

  return isDragging
}
