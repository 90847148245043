/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use 'DatePicker' from design-system instead
 */
import { FiscalDatePicker } from '@components-deprecated'

import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'

class DatepickerWebComponent extends DOMModel {
  @byAttrVal period: ''
  @byAttrVal fiscalYearEndMonth: ''
  @byAttrVal firstFiscalYearStart: ''
  @byAttrVal firstFiscalYearEnd: ''
}
let DatepickerCustomElement

if (customElements) {
  DatepickerCustomElement = createCustomElement(FiscalDatePicker, DatepickerWebComponent, 'element')

  customElements.get('datepicker-component') || customElements.define('datepicker-component', DatepickerCustomElement)
}

export default DatepickerCustomElement
