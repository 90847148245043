import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconDownloadFile = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-download-file')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
      <path
        fill={coloring(color)}
        d="M14,5.37H11.32A1.32,1.32,0,0,1,10,4.05V1.4H3.38V17.28H8L9.34,18.6h-6a1.32,1.32,0,0,1-1.32-1.32V1.4A1.32,1.32,0,0,1,3.38.08h7.94l4,4V8H14Z"
        transform="translate(-2.06 -0.08)"
        fillRule="evenodd"
      />
      <polygon
        fill={coloring(color2 || color)}
        points="9.93 14.56 9.93 9.26 12.57 9.26 12.57 14.56 15.88 14.56 11.25 19.85 6.62 14.56 9.93 14.56"
        fillRule="evenodd"
      />
    </svg>
  </Box>
)
