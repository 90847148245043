import { StatusFormatter } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { QuoteState as QuoteStateEnum } from '../../../../enums/quoteState'
import { quotesStateToTitleKey } from '../../constants/quotesStateToTitleKey'
import { quoteStateToStatusFormatterVariant } from '../../constants/quoteStateToStatusFormatterVariant'

interface QuoteStateProps {
  state: QuoteStateEnum
  title?: string
}

export const QuoteState = ({ state }: QuoteStateProps): ReactElement => {
  const { t } = useTranslation()

  const titleKey = quotesStateToTitleKey[state]
  const variant = quoteStateToStatusFormatterVariant[state]

  return <StatusFormatter variant={variant} title={t(titleKey)} />
}
