import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const useRedirectHashUrls = () => {
  const history = useHistory()

  useEffect(() => {
    // redirect old HashRouter views URLs to new Router views URLs
    if (history.location.hash.startsWith('#/?')) {
      // URLs with only query params after hash
      // from /adrian-test/debtorbalance#/?searchQuery=test
      // to   /adrian-test/debtorbalance?searchQuery=test
      history.replace(`${history.location.pathname}${history.location.hash.replace('#/?', '?')}`)
    } else if (history.location.hash.startsWith('#/')) {
      // URLs with a path after hash
      // from /adrian-test/settings#/organization
      // to   /adrian-test/settings/organization
      history.replace(`${history.location.pathname}${history.location.hash.replace('#/', '/')}`)
    }
  }, [history])
}
