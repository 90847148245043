import React, { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { EmberEventFn } from '../../../types/emberEventFn'

interface RegisterPaymentOptions {
  billId: string
  accountId?: string
  onBack?: Function
}

interface ContextState {
  registerPayment: (props: RegisterPaymentOptions) => void
  invalidateBill: (billId: string) => void
}

const BillsEmberConnectionContext = createContext<ContextState | undefined>(undefined)

interface BillsEmberConnectionContextProps {
  children?: ReactNode
  onRegisterPayment: EmberEventFn
  onInvalidateBill: EmberEventFn
}

export const BillsEmberConnectionContextProvider = ({
  onRegisterPayment,
  onInvalidateBill,
  children,
}: BillsEmberConnectionContextProps): ReactElement => {
  const registerPayment = useCallback(
    ({ billId, accountId, onBack }: RegisterPaymentOptions) => {
      onRegisterPayment({ detail: { props: { id: billId, accountId, onBack } } })
    },
    [onRegisterPayment],
  )

  const invalidateBill = useCallback(
    (billId: string) => {
      onInvalidateBill({ detail: { props: { id: billId } } })
    },
    [onInvalidateBill],
  )

  return (
    <BillsEmberConnectionContext.Provider value={{ registerPayment, invalidateBill }}>
      {children}
    </BillsEmberConnectionContext.Provider>
  )
}

export const useBillsEmberConnection = () => {
  const context = useContext(BillsEmberConnectionContext)

  if (!context) {
    throw new Error('BillsEmberConnectionContextProvider is missing in the module!')
  }

  return context
}
