import { Flex, Spacing, Switch, Text } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../../../hooks'
import { UmbrellaChangeSubscriptionFormSchema } from '../../utils/umbrellaChangeSubscriptionFormData'
import * as Styled from './styles'

export const ConsolidatedBillingFormItem = () => {
  const { t } = useTranslation()
  const { FormItem, control } = useFormContext<UmbrellaChangeSubscriptionFormSchema>()
  const consolidatedBilling = useWatch({ control, name: 'consolidatedBilling' })

  return (
    <Flex flexDirection="row" gap={Spacing.L}>
      <Flex flexDirection="column">
        <Text weight="bold" variant="small">
          {t('umbrella.organizations_table.change_subscription_modal.consolidated.title')}
        </Text>
        <Text variant="small">
          {consolidatedBilling
            ? t('umbrella.organizations_table.change_subscription_modal.consolidated.enabled.body')
            : t('umbrella.organizations_table.change_subscription_modal.consolidated.disabled.body')}
        </Text>
      </Flex>
      <Styled.ConsolidatedBillingSwitchFormItemWrapper>
        <FormItem
          name="consolidatedBilling"
          render={({ field: { onChange, value, ...rest } }) => <Switch checked={value} onChange={onChange} {...rest} />}
        />
      </Styled.ConsolidatedBillingSwitchFormItemWrapper>
    </Flex>
  )
}
