import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { StyledComponent } from '../../../types/styledComponent'
import { Size } from '../types/size'
import { sizeToProperties } from './sizeToProperties'

export const getCheckedStyle = (Control: StyledComponent, size: Size): SerializedStyles => css`
  ${Control} {
    display: flex;
    border-color: ${Color.Green};
    background-color: ${Color.ElectricGreen};

    /* Check circle inside */
    &:before {
      content: '';
      width: ${sizeToProperties[size].bulletSize}px;
      height: ${sizeToProperties[size].bulletSize}px;
      margin: auto;
      border-radius: 50%;
      background-color: ${Color.DeepSeaGreen};
    }
  }
`
