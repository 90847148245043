import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { PeriodMode } from '../../../../../enums/periodMode'
import { ComparisonValue } from '../../../types/comparisonValue'

export const getItems = (t: TFunction, periodMode?: PeriodMode): NavItem<ComparisonValue>[] | undefined => {
  const itemSameLastYear: NavItem<ComparisonValue> = {
    id: 'year',
    children: t('reports.comparison_select.same_period_last_year'),
    value: 'year',
  }

  const periodModeToItems = new Map<PeriodMode[], NavItem<ComparisonValue>[]>([
    [
      [PeriodMode.FiscalYear],
      [
        {
          id: 'period',
          children: t('reports.comparison_select.previous_year'),
          value: 'period',
        },
      ],
    ],
    [
      [PeriodMode.Month],
      [
        {
          id: 'period',
          children: t('reports.comparison_select.previous_month'),
          value: 'period',
        },
        itemSameLastYear,
      ],
    ],
    [
      [PeriodMode.Quarter],
      [
        {
          id: 'period',
          children: t('reports.comparison_select.previous_quarter'),
          value: 'period',
        },
        itemSameLastYear,
      ],
    ],
    [
      [PeriodMode.CustomDate, PeriodMode.CustomDateFrom, PeriodMode.CustomDateThrough],
      [
        {
          id: 'period',
          children: t('reports.comparison_select.previous_period'),
          value: 'period',
        },
        itemSameLastYear,
      ],
    ],
  ])

  for (const [periodModes, items] of periodModeToItems) {
    if (periodMode && periodModes.includes(periodMode)) {
      return items
    }
  }

  return undefined
}
