import { Button, Color, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '@modules-deprecated/app/user'

import * as Styled from './styles'

export const DefaultNotificationSettings = (): ReactElement => {
  const { t } = useTranslation()
  const { user } = useCurrentUser()

  const linkToEmailPreferences = `https://www.billy.dk/email-preferences/?email=${encodeURIComponent(
    user?.email || '',
  )}`

  return (
    <Styled.DefaultNotificationSettingsWrapper>
      <Styled.HeaderWrapper>
        <Styled.TextWrapper>
          <Text variant="h3" color={Color.DeepSeaGreen}>
            {t('notification_settings.default_settings')}
          </Text>
          <Text variant="micro" colorVariant="secondary">
            {t('notification_settings.default_settings.subText')}
          </Text>
        </Styled.TextWrapper>
        <Styled.ButtonWrapper>
          <Button
            variant="secondary"
            size="m"
            href={linkToEmailPreferences}
            target="_blank"
            icon="arrowOutOfSquare"
            fullWidth
          >
            {t('notification_settings.default_settings.modify')}
          </Button>
        </Styled.ButtonWrapper>
      </Styled.HeaderWrapper>
    </Styled.DefaultNotificationSettingsWrapper>
  )
}
