import { Color, DefaultValue, SkeletonCircle, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { ReactComponent as LogoPlaceholder } from '../../assets/images/organization-users-settings/logo-placeholder.svg'
import { Size } from './types/size'
import { getSizeStyles } from './utils/getSizeStyles'

const avatarCommonStyles = css`
  display: block;
  border-radius: ${DefaultValue.BorderRadius};
`

interface AvatarWrapperProps {
  size: Size
}

export const AvatarWrapper = styled.div<AvatarWrapperProps>`
  margin-right: ${Spacing.M};
  border-radius: ${DefaultValue.BorderRadius};
  position: relative;
  background-color: ${Color.White};
  ${({ size }) => getSizeStyles(size)}
`
export const SkeletonAvatar = styled(SkeletonCircle)`
  position: absolute;
  top: 0;
  left: 0;
  ${avatarCommonStyles}
`

interface AvatarProps {
  isLoading: boolean
}

export const Avatar = styled.img<AvatarProps>`
  width: inherit;
  height: inherit;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  object-fit: cover;
  ${avatarCommonStyles}
`

export const AvatarPlaceholder = styled(LogoPlaceholder)`
  ${avatarCommonStyles}
`
