import { Account, AccountGroup } from '@modules-deprecated/app/accounts/types'
import { useTaxRates } from '@modules-deprecated/app/taxrates/taxRatesContext'
import { getTaxRateById } from '@modules-deprecated/app/taxrates/utils/getTaxRateById'

import { AccountsInGroups } from '../../types'
import { AccountsTable } from '../AccountsTable'
import * as Styled from './styles'

const INIT_TABLE_DATA: Pick<AccountGroup, 'id' | 'accountNo' | 'name'>[] = [
  ...new Array(3).fill(undefined).map((_, index) => ({
    id: index.toString(),
    accountNo: '',
    name: '',
  })),
]

interface ChartOfAccountsListTableProps {
  accountsInGroups: AccountsInGroups
  groups: AccountGroup[]
  isLoading: boolean
  onDeleteAccount: (accountId: string) => void
  onEditAccount: (account: Account) => void
  onEditPredefinedAccount: (account: Account) => void
}

export const ChartOfAccountsListTable = ({
  accountsInGroups,
  groups,
  isLoading,
  onDeleteAccount,
  onEditAccount,
  onEditPredefinedAccount,
}: ChartOfAccountsListTableProps) => {
  const { taxRates = [] } = useTaxRates()

  const getAccountInGroup = (id: string) => {
    const accountsInGroup = accountsInGroups[id]

    if (accountsInGroup) {
      return accountsInGroup.map((account) => {
        return {
          ...account,
          taxRate: account.taxRateId ? getTaxRateById(taxRates, account.taxRateId)?.name : '',
        }
      })
    }

    return []
  }

  return (
    <Styled.AccountList>
      {(groups.length ? groups : INIT_TABLE_DATA).map(({ id, name, accountNo }) => (
        <Styled.AccountGroup key={id}>
          <AccountsTable
            accountNo={accountNo}
            data={getAccountInGroup(id)}
            id={id}
            isLoading={isLoading}
            name={name}
            onDeleteAccount={onDeleteAccount}
            onEditAccount={onEditAccount}
            onEditPredefinedAccount={onEditPredefinedAccount}
          />
        </Styled.AccountGroup>
      ))}
    </Styled.AccountList>
  )
}
