import React, { createContext, ReactElement, ReactNode, useContext } from 'react'

import { EmberEventFn } from '../../../../../types/emberEventFn'

interface ContextState {
  onReloadAccounts: EmberEventFn
}

const ChartOfAccountsContext = createContext<ContextState | undefined>(undefined)

interface ChartOfAccountsContextProps {
  onReloadAccounts: EmberEventFn
  children?: ReactNode
}

export const ChartOfAccountsContextProvider = ({
  onReloadAccounts,
  children,
}: ChartOfAccountsContextProps): ReactElement => {
  return (
    <ChartOfAccountsContext.Provider
      value={{
        onReloadAccounts,
      }}
    >
      {children}
    </ChartOfAccountsContext.Provider>
  )
}

export const useChartOfAccounts = () => {
  const context = useContext(ChartOfAccountsContext)

  if (!context) {
    throw new Error('ChartOfAccountsContextProvider is missing in the module!')
  }

  return context
}
