import { amountToDisplayValue, Flex, getDisplayDate, Text } from '@design-system'

import { ReactElement } from 'react'

import { Bill } from '../../../../../../../../types/bill'
import { useBillReconciliation } from '../../../../../../contexts/billReconciliationContext'
import * as Styled from './styles'

interface BillsListItemContentProps {
  bill: Bill
}

export const BillsListItemContent = ({ bill }: BillsListItemContentProps): ReactElement => {
  const { entryDate, grossAmount, lineDescription, voucherNo } = bill
  const { currencyId } = useBillReconciliation()

  const shouldShowAmount = !!grossAmount && !!currencyId

  return (
    <Styled.BillsListItemContentWrapper>
      <Flex justifyContent="space-between">
        <Styled.DetailsWrapper>
          <Text colorVariant="secondary">{entryDate ? getDisplayDate(new Date(entryDate)) : '-'}</Text>
          <Text colorVariant="secondary">#{voucherNo}</Text>
          <Text>{lineDescription}</Text>
        </Styled.DetailsWrapper>
        {shouldShowAmount && <Text>{amountToDisplayValue(grossAmount, false, currencyId)}</Text>}
      </Flex>
    </Styled.BillsListItemContentWrapper>
  )
}
