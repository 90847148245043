import { css, keyframes } from '@emotion/core'

export const SHAKE_ANIMATION_TIME_MS = 400

const getShakeKeyframes = (offset: number) => keyframes`
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(${offset}em);
  }
  40% {
    transform: translate(-${offset}em);
  }
  60% {
    transform: translate(${offset}em);
  }
  80% {
    transform: translate(-${offset}em);
  }
  100% {
    transform: translate(0);
  }
`

export const shakeAnimation = (offset = 0.5) => css`
  animation: ${getShakeKeyframes(offset)} ${SHAKE_ANIMATION_TIME_MS}ms infinite;
`
