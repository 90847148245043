import { FontWeight } from '@design-system'

import colors from './colors'

const commonStyle = {
  color: 'white',
  fontWeight: FontWeight.Medium,
  fontSize: '14px',
  padding: '1px 10px 2px 10px',
  lineHeight: '18px',
  borderRadius: '2px',
  whiteSpace: 'nowrap',
}

export default {
  label: {
    default: {
      ...commonStyle,
      backgroundColor: colors.label.default,
    },
    primary: {
      ...commonStyle,
      backgroundColor: colors.label.primary,
    },
    secondary: {
      ...commonStyle,
      backgroundColor: colors.label.secondary,
    },
    success: {
      ...commonStyle,
      backgroundColor: colors.label.success,
    },
    warning: {
      ...commonStyle,
      backgroundColor: colors.label.warning,
    },
    danger: {
      ...commonStyle,
      backgroundColor: colors.label.danger,
    },
    notification: {
      ...commonStyle,
      backgroundColor: colors.label.notification,
    },
  },
  paymentLabelPaid: {
    fontSize: '11px',
    padding: '0 7px',
    // background: colors.paid,
    background: colors.label.success,
    color: colors.white,
    textAlign: 'center',
    borderRadius: '3px',
    display: 'inline-block',
    textTransform: 'capitalize',
  },
  paymentLabelUnpaid: {
    fontSize: '11px',
    padding: '0 7px',
    // background: colors.unpaid,
    background: colors.label.warning,
    color: colors.white,
    textAlign: 'center',
    borderRadius: '3px',
    display: 'inline-block',
    textTransform: 'capitalize',
  },
  paymentLabelOverdue: {
    fontSize: '11px',
    padding: '0 7px',
    // background: colors.overdue,
    background: colors.label.danger,
    color: colors.white,
    textAlign: 'center',
    borderRadius: '3px',
    display: 'inline-block',
    textTransform: 'capitalize',
  },
  paymentLabelOverpaid: {
    fontSize: '11px',
    padding: '0 7px',
    // background: colors.overpaid,
    background: colors.label.default,
    color: colors.white,
    textAlign: 'center',
    borderRadius: '3px',
    display: 'inline-block',
    textTransform: 'capitalize',
  },
}
