import React, { MouseEvent, ReactElement, ReactNode, useCallback } from 'react'

import { IconButton } from '../IconButton'
import { ProgressBar, ProgressBarProps } from '../ProgressBar'
import { Space } from '../Space'
import { Text } from '../Text'
import * as Styled from './styles'
import { Variant } from './types/variant'
import { variantToBarColor } from './utils/variantToBarColor'

export type ProgressNotificationVariant = Variant

export interface ProgressNotificationProps {
  id: string
  description?: ReactNode
  progress?: ProgressBarProps['progress']
  variant?: Variant
  closable?: boolean
  onClick?: () => void
  onClose?: (id: string) => void
  title?: ReactNode
}

export const ProgressNotification = ({
  id,
  closable = true,
  description,
  onClose,
  progress,
  variant = 'default',
  onClick,
  title,
}: ProgressNotificationProps): ReactElement => {
  const handleCloseClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      onClose?.(id)
    },
    [id, onClose],
  )

  return (
    <Styled.ProgressNotificationWrapper clickable={!!onClick} onClick={onClick}>
      <Styled.Header>
        {title && <Text variant="h3">{title}</Text>}
        {closable && (
          <Styled.CloseButtonWrapper>
            <IconButton size="m" icon="xSign" onClick={handleCloseClick} />
          </Styled.CloseButtonWrapper>
        )}
      </Styled.Header>
      <Styled.Content>
        {description && <Text colorVariant="secondary">{description}</Text>}
        <Space size="l" />
        <ProgressBar progressColor={variantToBarColor[variant]} size="small" progress={progress} withAnimation />
      </Styled.Content>
    </Styled.ProgressNotificationWrapper>
  )
}
