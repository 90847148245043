import { Badge, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface RoleColumnProps {
  roleName: string
  description: string
  isAdmin: boolean
  hasAccessToAllOrganizations: boolean
}

export const RoleColumn = ({
  roleName,
  description,
  isAdmin,
  hasAccessToAllOrganizations,
}: RoleColumnProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <>
      <Text weight="bold">
        {roleName}{' '}
        {isAdmin ? (
          <Badge>{t('umbrella_roles.roles_table.badge.admin')}</Badge>
        ) : (
          hasAccessToAllOrganizations && <Badge>{t('umbrella_roles.roles_table.badge.accessToAll')}</Badge>
        )}
      </Text>
      <Text>{description}</Text>
    </>
  )
}
