import { Spacing } from '../../../enums/spacing'
import { pxToNumber } from '../../../utils/pxToNumber'
import { DropdownMaxHeight } from '../types/dropdownMaxHeight'

const WINDOW_PADDING = pxToNumber(Spacing.L)

const limitMaxHeight = (maxScreenHeight: number, maxHeight: DropdownMaxHeight | number): number => {
  switch (maxHeight) {
    case 'fullScreen':
      return maxScreenHeight

    case 'halfScreen':
      return maxScreenHeight / 2

    case 'default':
      return 350

    default:
      return Math.min(maxScreenHeight, maxHeight)
  }
}

export const getDropdownMaxHeight = (
  element: HTMLElement | null,
  maxHeight: DropdownMaxHeight | number,
): number | undefined => {
  const elementRect = element?.getBoundingClientRect()

  if (!elementRect) {
    return undefined
  }

  const topDistance = elementRect.top
  const bottomDistance = window.innerHeight - elementRect.bottom
  const maxScreenHeight = Math.max(topDistance, bottomDistance) - WINDOW_PADDING

  return limitMaxHeight(maxScreenHeight, maxHeight)
}
