export enum UpsellVariantIds {
  BankIntegration = 'bank_integration',
  BookkeepingLaw = 'bookkeeping_law',
  BookkeepingSupport = 'bookkeeping_support',
  Integrations = 'integrations',
  InvoiceDesigner = 'invoice_designer',
  MobilePay = 'mobile_pay',
  Salary = 'salary',
  UserInvite = 'user_invite',
  Vouchers = 'vouchers',
}
