import React, { FC } from 'react'

import { isTickActive } from '../helpers'

interface TickType {
  id: string
  percent: number
  value: number
}

interface TickProps {
  key: number
  index: number
  name: string
  width: number
  tickValues: number[]
  selectedValues: number[]
  tick: TickType
  handleTickClick: (index: number) => void
}

export const Tick: FC<TickProps> = ({ index, name, width, tickValues, selectedValues, tick, handleTickClick }) => (
  <>
    <div className="slider-tick" style={{ left: `${tick.percent}%` }} />
    <div
      className={`slider-tick-label ${isTickActive(index, tickValues, selectedValues) ? 'active' : ''}`}
      onClick={(_event) => handleTickClick(index)}
      onKeyUp={(_event) => handleTickClick(index)}
      role="none"
      style={{
        width: `${width}%`,
        left: `${tick.percent}%`,
      }}
    >
      {name}
    </div>
  </>
)
