import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { StyledComponent } from '../../../types/styledComponent'

export const getCheckedStyle = (Control: StyledComponent): SerializedStyles => css`
  ${Control} {
    color: ${Color.DeepSeaGreen};
    border-color: ${Color.Green};
    background-color: ${Color.ElectricGreen};
  }
`
