import { FormItemsGroup, Input, Textarea } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../hooks'
import { AccessTokenFormSchema } from '../../utils/formData'

export const ModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<AccessTokenFormSchema>()

  return (
    <FormItemsGroup>
      <FormItem
        name="name"
        label={t('settings.organization.access_tokens.create_or_edit_access_token_modal.label.name')}
        render={({ field }) => <Input {...field} type="text" />}
      />
      <FormItem
        name="description"
        label={t('settings.organization.access_tokens.create_or_edit_access_token_modal.label.description')}
        render={({ field }) => <Textarea {...field} resizable={false} rows={3} />}
      />
    </FormItemsGroup>
  )
}
