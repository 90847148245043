import { TFunction } from 'i18next'
import { boolean, object, SchemaOf } from 'yup'

import { User } from '@modules-deprecated/app/user'

export interface UserPrivacyForm {
  isBookkeepingHeroDisabled: boolean
  isSupportAccessAllowed: boolean
  isSupportChatDisabled: boolean
}

export type UserPrivacyFormSchema = SchemaOf<UserPrivacyForm>

export const getValidationSchema = (t: TFunction): UserPrivacyFormSchema =>
  object({
    isBookkeepingHeroDisabled: boolean().required(),
    isSupportAccessAllowed: boolean().required(),
    isSupportChatDisabled: boolean().required(),
  })

interface GetDefaultValuesOptions {
  isBookkeepingHeroDisabled?: boolean
  isSupportChatDisabled?: boolean
  user?: User
}

export const getDefaultValues = ({
  isBookkeepingHeroDisabled,
  isSupportChatDisabled,
  user,
}: GetDefaultValuesOptions): UserPrivacyForm => ({
  isBookkeepingHeroDisabled: isBookkeepingHeroDisabled || false,
  isSupportAccessAllowed: user?.isSupportAccessAllowed || false,
  isSupportChatDisabled: isSupportChatDisabled || false,
})
