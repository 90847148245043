import { useWindowSize } from 'react-use'

const windowWidthToVisibleTabsCount: Record<number, number> = {
  915: 5,
  800: 2,
}

export const useHiddenTabsCount = (allTabsCount = 0) => {
  const { width } = useWindowSize()

  let visibleTabsCount

  for (const windowWidth in windowWidthToVisibleTabsCount) {
    if (width < Number(windowWidth) && !visibleTabsCount) {
      visibleTabsCount = windowWidthToVisibleTabsCount[windowWidth]
    }
  }
  return allTabsCount - (visibleTabsCount || allTabsCount)
}
