import styled from '@emotion/styled'

import { BorderRadius } from '../../enums/borderRadius'
import { Spacing } from '../../enums/spacing'
import { getShadow } from '../../utils/getShadow'
import { NotificationVariant } from './types'
import { variantStyles } from './utils'

interface NotificationWrapperProps {
  variant: NotificationVariant
  withCloseButton: boolean
  clickable?: boolean
}

export const NotificationWrapper = styled.div<NotificationWrapperProps>`
  display: flex;
  padding: ${({ withCloseButton }) =>
    withCloseButton ? `${Spacing.M} ${Spacing.S}` : `${Spacing.M} ${Spacing.L} ${Spacing.M} ${Spacing.S}`};
  max-width: 600px;
  min-height: 56px;
  width: fit-content;
  border-radius: ${BorderRadius.L};
  background-color: ${({ variant }) => variantStyles[variant].bgColor};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'initial')};
  ${({ variant }) => getShadow('raisedStrong', { color: variantStyles[variant].boxShadowColor })}
`

export const Content = styled.div`
  display: flex;
  margin-top: ${Spacing.XXS};
`

export const BodyContent = styled.div``

export const IconWrapper = styled.div`
  display: flex;
  margin-left: ${Spacing.S};
  margin-right: ${Spacing.S};
  padding-top: ${Spacing.XXS};
`

export const SideActions = styled.aside`
  margin-top: ${Spacing.S};
`

export const CloseIconWrapper = styled.div`
  margin-left: ${Spacing.S};
  align-self: flex-start;
`
