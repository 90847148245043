import {
  Button,
  DefaultValue,
  Form,
  PORTAL_CLASS_NAME,
  Space,
  Textarea,
  transitionToMiliseconds,
  useComponentToggle,
} from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useClickAway, useKey, usePrevious } from 'react-use'

import { SUBMIT_SHORTCUT } from '../../constants/shortcuts'
import { NoteItem } from './elements/NoteItem'
import { NotesHeader } from './elements/NotesHeader'
import { TriggerIconButton } from './elements/TriggerIconButton'
import * as Styled from './styles'
import { Note } from './types/note'

const SKELETON_MOCKS_COUNT = 2
const SKELETON_MOCKS_ARRAY = Array.from({ length: SKELETON_MOCKS_COUNT }, Object)

export interface NotesPanelOnSubmitOptions {
  onSuccess?: () => void
}

export interface NotesPanelFormValues {
  description: string
}

interface NotesPanelProps {
  autoFocus?: boolean
  closeOnClickAway?: boolean
  isFetching?: boolean
  isOpen?: boolean
  isSubmitting?: boolean
  notes?: Note[]
  onClose?: () => void
  onSubmit?: (values: NotesPanelFormValues, options?: NotesPanelOnSubmitOptions) => void
}

export const NotesPanel = ({
  autoFocus = true,
  closeOnClickAway = true,
  isFetching,
  isOpen: isOpenControlled = false,
  isSubmitting,
  notes = [],
  onClose,
  onSubmit,
}: NotesPanelProps): ReactElement => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(isOpenControlled)
  const [inputValue, setInputValue] = useState('')
  const wrapperRef = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const previousNotes = usePrevious(notes)
  const previousIsSubmitting = usePrevious(isSubmitting)

  const { isVisible, isRendered } = useComponentToggle({
    isOpen,
    animationTime: transitionToMiliseconds(DefaultValue.TransitionDuration),
  })

  useEffect(() => {
    setIsOpen(isOpenControlled)
  }, [isOpenControlled])

  useEffect(() => {
    if (autoFocus && isRendered) {
      setTimeout(() => {
        textareaRef.current?.focus()
      }, transitionToMiliseconds(DefaultValue.TransitionDuration))
    }
  }, [isRendered])

  useEffect(() => {
    if (autoFocus && previousIsSubmitting && !isSubmitting) {
      textareaRef.current?.focus()
    }
  }, [isSubmitting])

  const close = useCallback(() => {
    setIsOpen(false)
    onClose?.()
  }, [onClose])

  const handleCloseClick = useCallback(() => {
    close()
  }, [close])

  const handleClickAway = useCallback(() => {
    if (!closeOnClickAway) {
      return
    }

    close()
  }, [close, closeOnClickAway])

  const handleTextareaValueChane = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value)
  }, [])

  const handleSubmitSuccess = useCallback(() => {
    setInputValue('')
  }, [])

  const submit = useCallback(() => {
    if (isSubmitting) {
      return
    }

    onSubmit?.({ description: inputValue }, { onSuccess: handleSubmitSuccess })
  }, [handleSubmitSuccess, inputValue, isSubmitting, onSubmit])

  const handleSubmitButtonClick = useCallback(() => {
    submit()
  }, [submit])

  const handleSubmitShortcut = useCallback(() => {
    submit()
  }, [submit])

  const handleEscapePress = useCallback(() => {
    if (!isOpen) {
      return
    }

    if (document.activeElement === textareaRef.current) {
      textareaRef.current?.blur()
      return
    }

    close()
  }, [close, isOpen])

  useClickAway(wrapperRef, handleClickAway)
  useKey('Escape', handleEscapePress, { target: window }, [isOpen])
  useHotkeys(
    SUBMIT_SHORTCUT,
    handleSubmitShortcut,
    { enabled: isOpen && !isSubmitting, enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'] },
    [isOpen, handleSubmitShortcut],
  )

  return ReactDOM.createPortal(
    <>
      {isRendered && (
        <Styled.NotesPanelWrapper className={PORTAL_CLASS_NAME} ref={wrapperRef} isOpen={isVisible}>
          <NotesHeader count={notes?.length || 0} onCloseClick={handleCloseClick} />

          <Space size="xl" />

          <Styled.FormWrapper>
            <Form>
              <Textarea
                disabled={isSubmitting || isFetching}
                onChange={handleTextareaValueChane}
                placeholder={t('note_panel.description_placeholder')}
                ref={textareaRef}
                rows={4}
                value={inputValue}
              />
              <Space size="s" />
              <Button
                disabled={!inputValue.trim() || isFetching}
                fullWidth
                loading={isSubmitting}
                onClick={handleSubmitButtonClick}
                size="m"
              >
                {t('note_panel.submit_btn')}
              </Button>
            </Form>
            <Space size="xl" />
          </Styled.FormWrapper>

          <Styled.NotesList>
            {isFetching
              ? SKELETON_MOCKS_ARRAY.map((_, index) => <NoteItem key={index} isLoading />)
              : notes.map((note) => {
                  const isNew = !previousNotes?.find((previousNote) => previousNote.id === note.id)
                  return <NoteItem key={note.id} note={note} isNew={isNew} />
                })}
          </Styled.NotesList>
        </Styled.NotesPanelWrapper>
      )}
    </>,
    document.body,
  )
}

NotesPanel.Trigger = TriggerIconButton
