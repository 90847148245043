import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Addon } from '@modules-deprecated/app/organization/types/addon'

import * as Styled from './styles'

export interface AddonsProps {
  addons: Addon[]
}

export const AddonsListComponent = (props: AddonsProps) => {
  const { t } = useTranslation()

  return (
    <Styled.AddonsListWrapper>
      <Styled.HeaderContainer>
        <Styled.Title>{t('organization_subscription.addons_title')}</Styled.Title>
      </Styled.HeaderContainer>

      <Styled.LineSeparator />

      <Styled.AddonContainer>
        <Styled.Column>
          <Styled.Name>{t('organization_subscription.addon_voucher')}</Styled.Name>
          <Styled.AvailableText>
            {t('organization_subscription.addon_vouchers_available', { amount: props.addons[0].value })}
          </Styled.AvailableText>
        </Styled.Column>
      </Styled.AddonContainer>
    </Styled.AddonsListWrapper>
  )
}
