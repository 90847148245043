import { useMutation } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { OrganizationSettingsError } from '@modules-deprecated/app/organization/types/organizationSettingsError'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { QueryKeys } from '../../../enums/queryKeys'
import { requestAccountant, RequestAccountantArgs } from '../query-api'

interface UseRequestAccountantArgs {
  onError?: (error: OrganizationSettingsError) => void
  onSuccess?: () => void
}

export const useRequestAccountant = ({ onError, onSuccess }: UseRequestAccountantArgs = {}) => {
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()
  const { isLoading: isSaving, mutate: request } = useMutation(
    [QueryKeys.RequestAccountant, organization?.id],
    (payload: Omit<RequestAccountantArgs, 'organizationId'>) =>
      requestAccountant({ ...payload, organizationId: organization?.id || '', userName: user?.name || '' }),
    { onError, onSuccess },
  )

  return {
    isSaving,
    request,
  }
}
