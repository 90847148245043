import { ReactElement } from 'react'

import tableStyles from './styles'

export type ColumnType = keyof typeof tableStyles | string

export enum SortDirection {
  None = 'NONE',
  Asc = 'ASC',
  Desc = 'DESC',
}

export type TableRow<T = Record<string, any>> = T & {
  id: string
  linkTo?: string
}

export type TableColumn<T = Record<string, any>> = {
  customStyle?: { [key: string]: string | number }
  field: keyof T
  isHeaderVisible?: boolean
  render?: (row: any) => ReactElement | null
  size?: string
  sortDirection?: SortDirection
  sortProperty?: string
  truncate?: boolean
  text?: string
  type: ColumnType
}

export type TableTotalRow<T = TableRow> = T & {
  labelColumnName: keyof T
}
