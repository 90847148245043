import { useEffect, useState } from 'react'

import { usePaymentMethodAccount } from '../../../hooks/usePaymentMethodAccount'
import { useReceivableAccount } from '../../../hooks/useReceivableAccount'
import { DEFAULT_STRIPE_ACCOUNT_NAME, DEFAULT_STRIPE_ACCOUNT_NUMBER } from '../constants/defaultStripeAccountProps'
import { useCreateStripeAccount } from './useCreateStripeAccount'
import { useCreateStripePaymentMethod } from './useCreateStripePaymentMethod'
import { useStripePopupWindow } from './useStripePopupWindow'

interface UseEnableStripeProps {
  onSuccess: () => void
  onError: () => void
}

interface UseEnableStripeReturn {
  enableStripe: () => void
  isWaiting: boolean
}

export const useEnableStripe = ({ onSuccess, onError }: UseEnableStripeProps): UseEnableStripeReturn => {
  const [stripeAuthorizationCode, setStripeAuthorizationCode] = useState<string>()
  const { account: stripeAccount, isLoading: isLoadingStripeAccount } = usePaymentMethodAccount({
    accountName: DEFAULT_STRIPE_ACCOUNT_NAME,
    accountNumber: DEFAULT_STRIPE_ACCOUNT_NUMBER,
  })
  const { account: receivableAccount, isLoading: isLoadingReceivableAccount } = useReceivableAccount()
  const { open: enableStripe, isOpen: isWaitingForStripeResponse } = useStripePopupWindow({
    onSuccess: setStripeAuthorizationCode,
  })

  const { createStripeAccount, isProcessing: isCreatingStripeAccount } = useCreateStripeAccount({
    receivableAccountGroupId: receivableAccount?.group?.id,
    onError,
  })
  const { createStripePaymentMethod, isProcessing: isCreatingPaymentMethod } = useCreateStripePaymentMethod({
    onSuccess,
    onError,
  })

  const isLoading = isLoadingStripeAccount || isLoadingReceivableAccount

  useEffect(() => {
    if (!isLoading && !isCreatingStripeAccount) {
      if (!stripeAccount) {
        createStripeAccount()
      } else if (stripeAuthorizationCode) {
        createStripePaymentMethod({ bankAccountId: stripeAccount.id, stripeAuthorizationCode })
      }
    }
  }, [
    createStripeAccount,
    createStripePaymentMethod,
    isCreatingStripeAccount,
    isLoading,
    stripeAccount,
    stripeAuthorizationCode,
  ])

  return { enableStripe, isWaiting: isWaitingForStripeResponse || isCreatingPaymentMethod }
}
