import { DraggableItemList, TaxRateSelect } from '@components'
import { Button, Divider, FormItemsGroup, Input, Space, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useFieldArray, useFormContext } from '../../../../../../../../../../hooks/useForm'
import { useCreateOrEditRulesetModal } from '../../context/createOrEditRulesetModalContext'
import { CreateOrEditRulesetFormSchema, getDefaultRule } from '../../utils/formData'
import { Rule } from './elements/Rule'

export const CreateOrEditRulesetForm = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem, control } = useFormContext<CreateOrEditRulesetFormSchema>()
  const { modalBodyElement, isDisabled: disabled } = useCreateOrEditRulesetModal()

  const {
    fields: rules,
    append: addRule,
    remove: removeRule,
    move: moveRule,
  } = useFieldArray({
    control,
    name: 'rules',
  })

  const ruleCount = rules.length

  const handleRuleOrderChange = useCallback(
    (oldIndex: number, newIndex: number) => {
      moveRule(oldIndex, newIndex)
    },
    [moveRule],
  )

  const handleAddRuleButtonClick = useCallback(() => {
    addRule(getDefaultRule())
  }, [addRule])

  const handleDeleteClick = useCallback(
    (index: number) => () => {
      removeRule(index)
    },
    [removeRule],
  )

  return (
    <>
      <FormItemsGroup itemsInRow={1}>
        <FormItem
          label={t('name')}
          extra={t('settings.organization.vat_rates.modals.create_or_edit_ruleset.name_extra')}
          name="name"
          render={({ field: { onChange, ...props } }) => <Input {...props} onChange={onChange} disabled={disabled} />}
        />
        <FormItem
          label={t('settings.organization.vat_rates.modals.create_or_edit_ruleset.fallback_rate')}
          extra={t('settings.organization.vat_rates.modals.create_or_edit_ruleset.fallback_rate_extra')}
          name="fallbackTaxRateId"
          render={({ field: { value, onChange, ...props } }) => (
            <TaxRateSelect
              {...props}
              allowClear
              dropdownSize="fitTrigger"
              onSelect={onChange}
              sale
              selectedId={value || undefined}
              placeholder={t('settings.organization.vat_rates.modals.create_or_edit_ruleset.no_fallback_rate')}
              disabled={disabled}
            />
          )}
        />
        <FormItem
          label={t('settings.organization.vat_rates.modals.create_or_edit_ruleset.description')}
          extra={t('settings.organization.vat_rates.modals.create_or_edit_ruleset.description_extra')}
          name="description"
          render={({ field: { onChange, ...props } }) => <Input {...props} onChange={onChange} disabled={disabled} />}
        />

        <FormItem
          label={t('settings.organization.vat_rates.modals.create_or_edit_ruleset.abbreviation')}
          extra={t('settings.organization.vat_rates.modals.create_or_edit_ruleset.abbreviation_extra')}
          name="abbreviation"
          render={({ field: { onChange, ...props } }) => <Input {...props} onChange={onChange} disabled={disabled} />}
        />
      </FormItemsGroup>
      <Space size="m" />
      <Divider />
      <Space size="l" />
      <Text variant="h3">{t('settings.organization.vat_rates.modals.create_or_edit_ruleset.rules_title')}</Text>
      <Space size="s" />
      <Text colorVariant="secondary">
        {t('settings.organization.vat_rates.modals.create_or_edit_ruleset.rules_description')}
      </Text>

      <DraggableItemList
        id="ruleset-modal-rules"
        isDraggable={ruleCount > 1}
        onOrderChange={handleRuleOrderChange}
        scrollableContainer={modalBodyElement}
        disabled={disabled}
      >
        {rules.map((rule, index) => (
          <Rule
            id={rule.id}
            index={index}
            key={rule.id}
            count={ruleCount}
            onDelete={handleDeleteClick(index)}
            disabled={disabled}
          />
        ))}
      </DraggableItemList>

      <Space size="s" />

      <Button variant="text" icon="plusCircle" onClick={handleAddRuleButtonClick} disabled={disabled}>
        {t('settings.organization.vat_rates.modals.create_or_edit_ruleset.add_rule')}
      </Button>
    </>
  )
}
