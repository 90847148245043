import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { useCallback } from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'
import { HashRouter } from 'react-router-dom'

import { store } from '@modules-deprecated'
import { AccountGroupsContextProvider, AccountsContextProvider } from '@modules-deprecated/app/accounts'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { TaxRatesContextProvider } from '@modules-deprecated/app/taxrates'
import { IsTrustedPartnerContextProvider } from '@modules-deprecated/app/umbrellas'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { UserNotificationSettingsContextProvider } from '@modules-deprecated/app/user/userNotificationSettingsContext'
import { Settings as SettingsComponent } from '@views/settings'
import { ChartOfAccountsContextProvider } from '@views/settings/routes/OrganizationChartOfAccounts/contexts/chartOfAccountsContext'
import { OrganizationInvoiceSettingsContextProvider } from '@views/settings/routes/OrganizationInvoiceSettings/contexts/organizationInvoiceSettingsContext'
import { OrganizationOwnersContextProvider } from '@views/settings/routes/OrganizationSettings/contexts/organizationOwnersContext'
import { OrganizationSubscriptionSettingsContextProvider } from '@views/settings/routes/OrganizationSubscriptionSettings/contexts/organizationSubscriptionSettingsContext'
import {
  EditSubscriptionParams,
  FetchOrganizationParams,
} from '@views/settings/routes/OrganizationSubscriptionSettings/types'
import { UserAuthorizationContextProvider } from '@views/settings/routes/UserProfileSettings/context/userAuthorizationContext'
import { VatRatesEmberConnectionContextProvider } from '@views/settings/routes/VatRatesSettings/contexts/vatRatesEmberConnectionContext'
import { EmberActionPayload } from '@views/signupGuide/context/signupGuideContext'

import { queryClient } from '../../config/queryClient'
import { DirtyRouteContextProvider, DirtyRouteProps } from '../../contexts/dirtyRouteContext'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { EmberUserTypeContextProvider } from '../../contexts/emberUserTypeContext'
import { GlobalFiscalYearContextProvider } from '../../contexts/globalFiscalYearContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

const componentName = 'settings-module'

class SettingsModel extends DOMModel {
  @byAttrVal organizationId: string
  @byAttrVal userType: string
  @registerEvent('navigate') navigate: Function | undefined
  @registerEvent('leaveRoute') leaveRoute: Function
  @registerEvent('setDirtyRouteFlag') setDirtyRouteFlag: Function
  @registerEvent('openCreateOwnerModal') openCreateOwnerModal: Function | undefined
  @registerEvent('openDeleteOwnerModal') openDeleteOwnerModal: Function | undefined
  @registerEvent('openEditOwnerModal') openEditOwnerModal: Function | undefined
  @registerEvent('openCreatePaymentMethodModal') openCreatePaymentMethodModal: Function | undefined
  @registerEvent('openEditPaymentMethodModal') openEditPaymentMethodModal: Function | undefined
  @registerEvent('reloadPaymentMethods') reloadPaymentMethods: Function
  @registerEvent('createRate') createRate: Function
  @registerEvent('editRate') editRate: Function
  @registerEvent('createRuleset') createRuleset: Function
  @registerEvent('editRuleset') editRuleset: Function
  @registerEvent('editSubscription') editSubscription: EmberEventFn
  @registerEvent('editCardSubscription') editCardSubscription: EmberEventFn
  @registerEvent('fetchOrganization') fetchOrganization: EmberEventFn
  @registerEvent('reloadAccounts') reloadAccounts: Function
  @registerEvent('authorizeUser') authorizeUser: Function
}

interface SettingsProps extends DirtyRouteProps {
  organizationId: string
  userType: string
  onNavigate: EmberNavigate
  onOpenCreateOwnerModal: Function
  onOpenDeleteOwnerModal: Function
  onOpenEditOwnerModal: Function
  onOpenCreatePaymentMethodModal: Function
  onOpenEditPaymentMethodModal: Function
  onReloadPaymentMethods: EmberEventFn
  onCreateRate: EmberEventFn
  onEditRate: EmberEventFn
  onCreateRuleset: EmberEventFn
  onEditRuleset: EmberEventFn
  onEditSubscription: (detail: EditSubscriptionParams) => void
  onEditCardSubscription: () => void
  onFetchOrganization: (data: Record<string, any>) => void
  onReloadAccounts: EmberEventFn
  onAuthorizeUser: (payload: EmberActionPayload) => void
}

const Settings = ({
  organizationId,
  userType,
  onNavigate,
  onLeaveRoute,
  onSetDirtyRouteFlag,
  onOpenCreateOwnerModal,
  onOpenEditOwnerModal,
  onOpenDeleteOwnerModal,
  onOpenCreatePaymentMethodModal,
  onOpenEditPaymentMethodModal,
  onReloadPaymentMethods,
  onCreateRate,
  onEditRate,
  onCreateRuleset,
  onEditRuleset,
  onEditSubscription,
  onEditCardSubscription,
  onFetchOrganization,
  onReloadAccounts,
  onAuthorizeUser,
}: SettingsProps) => {
  const openCreateOwnerModal = useCallback(() => {
    onOpenCreateOwnerModal({ detail: null })
  }, [onOpenCreateOwnerModal])

  const openEditOwnerModal = useCallback(
    (id: string) => {
      onOpenEditOwnerModal({ detail: { id } })
    },
    [onOpenEditOwnerModal],
  )

  const openDeleteOwnerModal = useCallback(
    (id: string) => {
      onOpenDeleteOwnerModal({ detail: { id } })
    },
    [onOpenDeleteOwnerModal],
  )

  const openCreatePaymentMethodModal = useCallback(() => {
    onOpenCreatePaymentMethodModal({ detail: null })
  }, [onOpenCreatePaymentMethodModal])

  const openEditPaymentMethodModal = useCallback(
    (paymentMethodId: string) => {
      onOpenEditPaymentMethodModal({ detail: { paymentMethodId } })
    },
    [onOpenEditPaymentMethodModal],
  )

  const reloadPaymentMethods = useCallback(() => {
    onReloadPaymentMethods({ detail: {} })
  }, [onReloadPaymentMethods])

  const fetchOrganization = (e: FetchOrganizationParams) => {
    onFetchOrganization(e)
  }

  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <HashRouter>
            <EmberRouterContextProvider onNavigate={onNavigate}>
              <UserOrganizationContextProvider organizationId={organizationId}>
                <UserOrganizationSettingsContextProvider organizationId={organizationId}>
                  <AccountsContextProvider organizationId={organizationId}>
                    <AccountGroupsContextProvider organizationId={organizationId}>
                      <GlobalFiscalYearContextProvider organizationId={organizationId}>
                        <EmberUserTypeContextProvider userType={userType}>
                          <IsTrustedPartnerContextProvider organizationId={organizationId}>
                            <CurrentUserContextProvider>
                              <UserAuthorizationContextProvider onAuthorizeUser={onAuthorizeUser}>
                                <ModalContextProvider>
                                  <DirtyRouteContextProvider
                                    onLeaveRoute={onLeaveRoute}
                                    onSetDirtyRouteFlag={onSetDirtyRouteFlag}
                                  >
                                    <OrganizationInvoiceSettingsContextProvider
                                      onOpenCreatePaymentMethodModal={openCreatePaymentMethodModal}
                                      onOpenEditPaymentMethodModal={openEditPaymentMethodModal}
                                      onReloadPaymentMethods={reloadPaymentMethods}
                                    >
                                      <OrganizationOwnersContextProvider
                                        onOpenCreateOwnerModal={openCreateOwnerModal}
                                        onOpenEditOwnerModal={openEditOwnerModal}
                                        onOpenDeleteOwnerModal={openDeleteOwnerModal}
                                      >
                                        <VatRatesEmberConnectionContextProvider
                                          onShowCreateRateModal={onCreateRate}
                                          onShowEditRateModal={onEditRate}
                                          onShowCreateRuleSetModal={onCreateRuleset}
                                          onShowEditRuleSetModal={onEditRuleset}
                                        >
                                          <TaxRatesContextProvider organizationId={organizationId}>
                                            <UserNotificationSettingsContextProvider>
                                              <OrganizationSubscriptionSettingsContextProvider
                                                organizationId={organizationId}
                                                onFetchOrganization={fetchOrganization}
                                                onEditSubscription={onEditSubscription}
                                                onEditCardSubscription={onEditCardSubscription}
                                              >
                                                <ChartOfAccountsContextProvider onReloadAccounts={onReloadAccounts}>
                                                  <SettingsComponent />
                                                </ChartOfAccountsContextProvider>
                                              </OrganizationSubscriptionSettingsContextProvider>
                                            </UserNotificationSettingsContextProvider>
                                          </TaxRatesContextProvider>
                                        </VatRatesEmberConnectionContextProvider>
                                      </OrganizationOwnersContextProvider>
                                    </OrganizationInvoiceSettingsContextProvider>
                                  </DirtyRouteContextProvider>
                                </ModalContextProvider>
                              </UserAuthorizationContextProvider>
                            </CurrentUserContextProvider>
                          </IsTrustedPartnerContextProvider>
                        </EmberUserTypeContextProvider>
                      </GlobalFiscalYearContextProvider>
                    </AccountGroupsContextProvider>
                  </AccountsContextProvider>
                </UserOrganizationSettingsContextProvider>
              </UserOrganizationContextProvider>
            </EmberRouterContextProvider>
          </HashRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </ReduxProvider>
  )
}

const SettingsElement = createCustomElement(Settings, SettingsModel, 'element')

customElements.get(componentName) || customElements.define(componentName, SettingsElement)

export default SettingsElement
