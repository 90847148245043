import { TaxRate } from '../types'

export const getTaxRateById = (taxRates: TaxRate[], id: string): TaxRate => {
  const foundTaxRate = taxRates.find(({ id: taxRateId }) => id === taxRateId)

  return (
    foundTaxRate || {
      abbreviation: '',
      deductionComponents: [],
      description: '',
      id: '',
      isActive: true,
      name: '',
      rate: 0,
      taxRateGroup: 'local',
      priority: 0,
      text: '',
      isPredefined: false,
    }
  )
}
