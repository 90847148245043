export const formatIban = (input?: string): string => {
  if (!input) {
    return ''
  }

  const slicedPart = input.slice(4)
  const prefix = slicedPart.slice(0, 4)
  const suffix = slicedPart.slice(4)
  return `${prefix}-${suffix}`
}
