import { darkTheme, DefaultValue, FontWeight, lightTheme } from '@design-system'

import { transitions, zIndex } from '../_common'
import actionGroup from './actionGroup'
import buttons, { buttonSizes } from './buttons'
import card from './card'
import checkbox from './checkbox'
import colors from './colors'
import columnSelector from './columnSelector'
import dashboard from './dashboard'
import forms from './forms'
import globalStyles from './global-styles.css'
import helpBubble from './helpBubble'
import label from './label'
import layout from './layout'
import links from './links'
import menu from './menu/index'
import modals from './modals'
import navigationButtons from './navigationButtons'
import pageHeader from './pageHeader'
import scrollbar from './scrollbar'
import searchBox from './searchBox'
import tables from './tables'
import text from './text'
import topBanner from './topbanner'

const billyThemeStyles = {
  buttons,
  buttonSizes,
  colors,
  searchBox,
  text,
  transitions,
  zIndex,
  variants: {
    ...actionGroup,
    ...card,
    ...dashboard,
    ...label,
    ...links,
    ...layout,
    ...helpBubble,
    ...menu,
    ...modals,
    ...navigationButtons,
    ...scrollbar,
    ...tables,
    ...columnSelector,
    ...topBanner,
    ...pageHeader,
  },
  fonts: {
    body: DefaultValue.FontFamily,
    heading: 'inherit',
    monospace: 'Maison Neue Mono, Menlo, monospace',
    table: DefaultValue.FontFamily,
  },
  forms: {
    ...checkbox,
    ...forms,
  },
  fontWeights: {
    light: FontWeight.Regular,
    body: FontWeight.Medium,
    heading: FontWeight.Medium,
    bold: FontWeight.Medium,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  space: [0, 5, 10, 20, 30, 40],
  general: {
    borderRadius: '8px',
  },
}

const themeLight = {
  ...billyThemeStyles,
  ...lightTheme,
}

const themeDark = {
  ...billyThemeStyles,
  ...darkTheme,
}

export { themeLight, themeDark, billyThemeStyles, globalStyles }
