import { AccountSelect, ProcessingOverlay, SettingsSection, TaxRulesetSelect } from '@components'
import { Color, FormItemsGroup } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { AccountNature } from '@modules-deprecated/app/accounts/types'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useFormContext } from '../../../../../../../../../../hooks'
import { useInvoiceAndProductSettings } from '../../../../contexts/invoiceAndProductSettingsContext'
import { InvoiceAndProductSettingsFormSchema } from '../../../../utils/formData'
import * as Styled from './styles'

export const ProductSettingsColumn = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { FormItem } = useFormContext<InvoiceAndProductSettingsFormSchema>()
  const { isLoading, isProcessing } = useInvoiceAndProductSettings()

  const defaultSalesTaxRulesetLabelTKey = organization?.hasVat
    ? 'settings.organization.invoice.product_settings.default_sales_tax_ruleset.label_vat'
    : 'settings.organization.invoice.product_settings.default_sales_tax_ruleset.label_no_vat'

  return (
    <SettingsSection.Content title={t('settings.organization.invoice.product_settings.title')}>
      <Styled.ProductSettingsColumnWrapper>
        {(isProcessing || isLoading) && <ProcessingOverlay color={Color.White} />}
        <FormItemsGroup>
          <FormItem
            label={t('settings.organization.invoice.product_settings.default_sales_account.label')}
            name="defaultSalesAccountId"
            render={({ field: { value, onChange, ...props } }) => (
              <AccountSelect
                {...props}
                dropdownSize="fitTrigger"
                natures={[AccountNature.Revenue, AccountNature.Equity, AccountNature.Liability]}
                onSelect={(id?: string) => onChange(id)}
                selectedId={value}
              />
            )}
          />
          <FormItem
            name={'defaultSalesTaxRulesetId'}
            label={t(defaultSalesTaxRulesetLabelTKey)}
            render={({ field: { value, onChange, ...props } }) => (
              <TaxRulesetSelect
                {...props}
                defaultSelectedId={value}
                dropdownSize="fitTrigger"
                onSelect={(id?: string) => onChange(id)}
                selectedId={value}
              />
            )}
          />
        </FormItemsGroup>
      </Styled.ProductSettingsColumnWrapper>
    </SettingsSection.Content>
  )
}
