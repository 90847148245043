import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { getBootstrapUser } from './query-api'
import { User } from './types'

interface ContextState {
  user: User | undefined
  isLoading: boolean
}

const CurrentUserContext = createContext<ContextState>({
  user: undefined,
  isLoading: false,
})

interface CurrentUserContextProps {
  children?: ReactNode
  enabled?: boolean
}

export const CurrentUserContextProvider = ({ children, enabled = true }: CurrentUserContextProps): ReactElement => {
  const { data, isLoading } = useQuery([QueryKeys.CurrentUser], getBootstrapUser, {
    refetchOnWindowFocus: false,
    enabled,
  })

  return <CurrentUserContext.Provider value={{ isLoading, user: data?.user }}>{children}</CurrentUserContext.Provider>
}

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext)

  if (!context) {
    // skipping for now, observed many issues in datadog
    // throw new Error('CurrentUserContextProvider is missing in the module!')
  }

  return context
}
