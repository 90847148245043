import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../../utils'
import { updateTaxRate } from '../../../../query-api'
import { VatRateFormData } from '../../../../types/vatRateFormData'

interface UseUpdateVatRate {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface UpdateVatRateProps {
  id: string
  payload: Partial<VatRateFormData>
}

export const useUpdateVatRate = (props?: UseUpdateVatRate) => {
  const { onError, onSuccess } = props || {}

  const { mutate: update, ...rest } = useMutation(({ id, payload }: UpdateVatRateProps) => updateTaxRate(id, payload), {
    onError,
    onSuccess,
  })

  return { update, ...rest }
}
