import { Input as InputComponent, Password as PasswordComponent } from '@components-deprecated'
import { rwd, Spacing, Text as TextComponent } from '@design-system'

import styled from '@emotion/styled'

export const Input = styled(InputComponent)`
  margin-bottom: ${Spacing.L};
`

export const Password = styled(PasswordComponent)`
  margin-bottom: ${Spacing.L};
`

export const PasswordChecksWrapper = styled.div`
  margin-top: -${Spacing.S};
`

export const ButtonWrapper = styled.div`
  margin-top: ${Spacing.L};
`

export const AgreementsWrapper = styled.div`
  margin-top: ${Spacing.L};
  margin-bottom: ${Spacing.XXXL};

  ${rwd.equalOrLessThan('phone')} {
    margin-bottom: ${Spacing.XXL};
  }
`

export const Footer = styled.footer`
  display: flex;
  margin-top: ${Spacing.L};
  align-items: center;
`

export const FooterLabelText = styled(TextComponent)`
  margin-right: ${Spacing.L};
`

export const AcceptTermsLabelWrapper = styled.div``
