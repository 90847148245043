import { Color } from '@design-system'

import { css } from '@emotion/core'
import { darken } from 'polished'

const FILTER = css`
  filter: invert(100%) hue-rotate(-180deg) saturate(2.5) contrast(0.75) brightness(1.2);
`

const FILTER_INVERTION = css`
  filter: invert(100%) hue-rotate(180deg) saturate(0.55);
`

const FILTER_ELECTRIC_GREEN_INVERTION = css`
  filter: invert(100%) hue-rotate(-522deg) saturate(1);
`

export const darkMode = css`
  body {
    > div {
      transition: filter 0.4s ease-out;
    }
  }

  .theme-layout-dark {
    & > .application, // main ember's application container
      & > .window, // ember's modalboxes
      & > .modal-mask, // ember's modalboxes' overlays
      & > .popover, // ember's dropdowns
      & > .ds-moved-with-portal {
      // react's elements move to body with 'createPortal'
      &:not(.react-app-app-loading-screen) {
        ${FILTER};

        .react-app-logo {
          ${FILTER_ELECTRIC_GREEN_INVERTION};
        }
      }
    }

    .ds-button {
      &.primary {
        ${FILTER_ELECTRIC_GREEN_INVERTION};

        &:not(.danger):not(.success):not(.focused):not(.disabled):not(:disabled):not(:hover):not(:active):not(.active) {
          background-color: ${Color.ElectricGreen};
          color: ${Color.Black};
        }
        &:disabled,
        &.disabled {
          background-color: ${darken(0.1, Color.Gray200)};
          color: ${darken(0.2, Color.Gray90)};
        }
      }
      &.secondary {
        &:not(.danger):not(.success):not(.focused):not(.disabled):not(:disabled) {
          &:not(:hover):not(:active):not(.active) {
            background-color: ${Color.Gray10};
          }

          color: ${Color.Black};
        }
      }
      &.text {
        &:not(.danger):not(.success):not(.focused):not(.disabled):not(:disabled) {
          color: ${Color.Black};
        }

        &.active {
          &:not(.danger):not(.success):not(.focused):not(.disabled):not(:disabled):not(:hover) {
            color: ${Color.Black};
            background-color: ${Color.Gray50};
          }
        }
      }
    }

    .button {
      &:not(.danger):not(.success):not(.focused):not(.disabled):not(:disabled):not(.warning) {
        &:not(:hover):not(:active):not(.active) {
          background-color: ${Color.Gray10};
        }

        color: ${Color.Black};
      }

      &.primary {
        ${FILTER_ELECTRIC_GREEN_INVERTION};

        &:not(.danger):not(.success):not(.focused):not(.disabled):not(:disabled):not(:hover):not(:active):not(.active) {
          background-color: ${Color.ElectricGreen};
          color: ${Color.Black};
        }
        &:disabled,
        &.disabled {
          background-color: ${darken(0.1, Color.Gray200)};
          color: ${darken(0.2, Color.Gray90)};
        }
      }
      &.dropdown {
        &:not(.danger):not(.success):not(.focused):not(.disabled):not(:disabled):not(:hover):not(:active):not(
            .active
          ):not(.primary) {
          background-color: ${Color.Gray10};
          color: ${Color.Black};
        }
      }
      &.warning {
        &:not(:disabled):not(.disabled) {
          ${FILTER_ELECTRIC_GREEN_INVERTION};
        }
      }
    }

    .react-app-image,
    .react-app-dark-theme-suspend,
    .ds-lottie-animation,
    .image,
    .video,
    .icon:not(.search),
    .icon:not(.icon-is-svg),
    .document-paper,
    .theme-layout-dark-suspend {
      ${FILTER_INVERTION};
    }

    div[class*='tool-icons-'] {
      path {
        fill: ${Color.Black};
      }
    }

    .react-app-global-search-tips {
      background: ${Color.Gray200};
    }

    .react-app-input-deprecated {
      color: ${Color.Black};

      &::placeholder {
        color: ${Color.Black} !important;
      }
      &::-moz-placeholder {
        color: ${Color.Black};
      }
    }

    .react-app-label-deprecated {
      color: ${Color.Black};
    }

    .react-app-app-button-wrapper {
      &.ageras {
        .react-app-app-button {
          ${FILTER_ELECTRIC_GREEN_INVERTION};
          background-color: ${Color.ElectricGreen};
        }

        &:after {
          ${FILTER_ELECTRIC_GREEN_INVERTION};
          border-color: ${Color.ElectricGreen};
        }
      }
    }

    .react-app-period-item-button {
      &.active {
        ${FILTER_ELECTRIC_GREEN_INVERTION};
      }
    }

    .ds-link {
      &.withAnimation {
        background-image: linear-gradient(transparent 50%, ${Color.Gray60} 0%);
      }
    }

    .link {
      &.with-animation {
        background-image: linear-gradient(transparent 50%, ${Color.Gray60} 0%);
      }
    }

    .ds-date-picker-day-button {
      &.selected {
        ${FILTER_ELECTRIC_GREEN_INVERTION};
      }
    }

    .ds-checkbox {
      &.checked {
        &,
        &:hover {
          .ds-checkbox-control {
            ${FILTER_ELECTRIC_GREEN_INVERTION};
            color: ${Color.Black};
            border-color: ${Color.ElectricGreen};
            background-color: ${Color.ElectricGreen};
          }
        }
      }
    }

    .ember-checkbox {
      &:checked {
        &,
        &:hover {
          ${FILTER_ELECTRIC_GREEN_INVERTION};
          color: ${Color.Black};
          border-color: ${Color.ElectricGreen};
          background-color: ${Color.ElectricGreen};

          /* icon */
          &:before {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9 1L3.5 6.5L1 4' stroke='%23002E33' stroke-width='1.6666' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
          }
        }
      }
    }

    .top-bar {
      margin-bottom: 0;
    }
  }
`
