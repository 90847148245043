import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import { QueryKeys } from '../../../../../../../enums/queryKeys'

export const useInvalidateRulesets = () => {
  const queryClient = useQueryClient()

  const invalidateRulesets = useCallback(() => {
    queryClient.invalidateQueries(QueryKeys.VatRatesRulesets)
  }, [queryClient])

  return {
    invalidateRulesets,
  }
}
