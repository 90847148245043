import { Button } from '@design-system'

import { css } from '@emotion/core'
import {
  CardCvcElement,
  CardCvcElementComponent,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Box, BoxProps } from 'rebass'

import { Text } from '../Typography/Text'

export type StripeProps = {
  stripe?: any
  elements?: any
  onSuccess: (el: any) => void
}

const stripePromise = loadStripe(process?.env?.MENETO_STRIPE_API_KEY || window?.ENV?.MENETO_STRIPE_API_KEY || 'none')

export const StripeForm = ({ stripe, elements, onSuccess }: StripeProps) => {
  const { handleSubmit } = useForm()
  const { t } = useTranslation()
  const onSubmitHandler = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const cardElement = elements.getElement(CardNumberElement)
    const { token, error } = await stripe.createToken(cardElement)

    if (error) {
      console.error('[error]', error)
      toast.error(error.message)
    } else {
      onSuccess(token.id)
    }
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmitHandler)}>
      <Box
        css={css`
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 16px;
          grid-row-gap: 16px;
        `}
      >
        <StripeInputContainer gridPlacement="1 / 1 / 2 / 6" title={t('stripe.cardNumber')}>
          <CardNumberElement options={{ showIcon: true }} />
        </StripeInputContainer>

        <Box
          css={css`
            grid-area: 2 / 1 / 3 / 4;
          `}
        >
          <Text>{t('stripe.expiryDate')}</Text>
          <Box
            css={css`
              border-radius: 3px;
              border: 2px solid rgb(3, 182, 221);
              font-size: 15px;
              padding: 14px 9px;
            `}
          >
            <CardExpiryElement />
          </Box>
        </Box>

        <Box
          css={css`
            grid-area: 2 / 4 / 3 / 6;
          `}
        >
          <Text>{t('stripe.cvc')}</Text>
          <Box
            css={css`
              border-radius: 3px;
              border: 2px solid rgb(3, 182, 221);
              font-size: 15px;
              padding: 14px 9px;
            `}
          >
            <CardCvcElement />
          </Box>
        </Box>
      </Box>
      <Button variant="primary" type="submit">
        {t('stripe.confirm')}
      </Button>
    </Box>
  )
}

export const InjectedStripeConsumer = ({ ...rest }: any) => (
  <ElementsConsumer>
    {({ stripe, elements }) => <StripeForm stripe={stripe} elements={elements} {...rest} />}
  </ElementsConsumer>
)

export const StripeContainer = ({ ...rest }: StripeProps) => {
  return (
    <Elements stripe={stripePromise}>
      <InjectedStripeConsumer {...rest} />
    </Elements>
  )
}

export type StripeInputContainerProps = BoxProps & {
  title?: string
  gridPlacement?: string
  children?: any | CardCvcElementComponent
}

const StripeInputContainer = ({ children, gridPlacement, title = '' }: StripeInputContainerProps) => {
  return (
    <Box
      css={css`
        grid-area: ${gridPlacement};
      `}
    >
      <Text>{title}</Text>
      <Box
        css={css`
          border-radius: 3px;
          border: 2px solid rgb(3, 182, 221);
          font-size: 15px;
          padding: 14px 9px;
        `}
      >
        {children}
      </Box>
    </Box>
  )
}
