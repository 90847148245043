import { NavItem, Select, SelectProps } from '@design-system'

import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DeductionSource } from '@modules-deprecated/app/taxrates/enums/deductionSource'

type DeductionSourceSelectProps = Omit<SelectProps<DeductionSource>, 'items'>

export const DeductionSourceSelect = (props: DeductionSourceSelectProps): ReactElement => {
  const { t } = useTranslation()

  const items: NavItem<DeductionSource>[] = useMemo(
    () => [
      {
        id: DeductionSource.Tax,
        children: t('settings.organization.vat_rates.deduction_source_select.tax'),
        value: DeductionSource.Tax,
      },
      {
        id: DeductionSource.NetAmount,
        children: t('settings.organization.vat_rates.deduction_source_select.net_amount'),
        value: DeductionSource.NetAmount,
      },
    ],
    [t],
  )

  return <Select {...props} items={items} withSearch={false} />
}
