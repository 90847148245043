import { Button, ButtonsGroup, Color, IconButton, Space } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { Widget } from '../../elements/Widget'
import { WidgetId } from '../../enums/widgetId'
import * as Styled from './styles'

export const FindAccountant = (): ReactElement => {
  const { t } = useTranslation()
  const { isWidgetHidden, toggleWidgetVisibility, isEditMode } = useDashboardSettings()
  const { navigate } = useEmberRouter()

  const isHidden = isWidgetHidden(WidgetId.FindAccountant)

  const handleFindBookkeeperClick = useCallback(() => {
    navigate(EmberRoute.FindAccountant)
  }, [navigate])

  const handleInviteBookkeeperClick = useCallback(() => {
    navigate(EmberRoute.SettingsOrganizationUsers)
  }, [navigate])

  const handleHideWiget = useCallback(() => {
    toggleWidgetVisibility({ id: WidgetId.FindAccountant, shouldHide: true })
  }, [toggleWidgetVisibility])

  return (
    <Widget isHidden={isHidden} backgroundColor={Color.Green10}>
      <Styled.FindAccountantWrapper>
        <Styled.Header>
          <Space size="s" />
          <Styled.Title variant="displayS">{t('dashboard.widget.find_accountant.title')}</Styled.Title>
          <Space />
          <Styled.Content>{t('dashboard.widget.find_accountant.content')}</Styled.Content>
        </Styled.Header>
        <ButtonsGroup>
          <Button onClick={handleFindBookkeeperClick} type="button" variant="secondary">
            {t('dashboard.widget.find_accountant.find_bookkeeper.cta')}
          </Button>
          <Button onClick={handleInviteBookkeeperClick} type="button" variant="text">
            {t('dashboard.widget.find_accountant.invite_bookkeeper.cta')}
          </Button>
        </ButtonsGroup>
        <Styled.Illustration />
      </Styled.FindAccountantWrapper>
      {!isEditMode && (
        <Styled.CloseIconWrapper>
          <IconButton icon="xSign" size="m" onClick={handleHideWiget} color={Color.DeepSeaGreen} />
        </Styled.CloseIconWrapper>
      )}
    </Widget>
  )
}
