import { CURRENCIES_RECEIVED, CURRENCIES_REQUESTED } from './actions'
import { CurrenciesReceivedAction, CurrenciesRequestedAction, Currency } from './types'

export function currenciesRequested(): CurrenciesRequestedAction {
  return {
    type: CURRENCIES_REQUESTED,
  }
}

export function currenciesReceived(currencies: Currency[]): CurrenciesReceivedAction {
  return {
    payload: currencies,
    type: CURRENCIES_RECEIVED,
  }
}
