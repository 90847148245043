import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'
import { Checkbox } from '../../../Checkbox'

export const TableCheckboxWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  left: ${Spacing.XXXS};
  align-items: center;
`

export const CheckboxWrapper = styled(Checkbox)`
  margin-left: ${Spacing.S};
`
