import { ButtonDropdown } from '@design-system'

import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useUserOrganizations } from '@modules-deprecated/app/organizations'
import { useUmbrella, useUserUmbrellas } from '@modules-deprecated/app/umbrellas'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { AccountSelectorFooter } from './AccountSelectorFooter'
import * as Styled from './styles'
import { getAvatarProps } from './utils/getAvatarProps'
import { getOrganizationItems } from './utils/getOrganizationItems'

export const MenuAccountSelector = (): ReactElement => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { user } = useCurrentUser()
  const { organization } = useUserOrganization()
  const { userOrganizations } = useUserOrganizations()
  const { userUmbrellas } = useUserUmbrellas()

  const isLoading = !organization && !umbrella
  const buttonMainLabel = organization?.name || umbrella?.name
  const buttonSmallLabel = user?.name
  const avatarProps = getAvatarProps(organization, umbrella)
  const selectedId = umbrella?.id || organization?.id

  const items = useMemo(
    () => getOrganizationItems(userUmbrellas, userOrganizations, t),
    [userUmbrellas, userOrganizations, t],
  )

  return (
    <Styled.MenuAccountSelectorWrapper>
      {!isLoading && (
        <ButtonDropdown
          items={items}
          variant="secondary"
          icon={avatarProps?.icon ? avatarProps.icon : <Styled.AvatarImage {...avatarProps} />}
          placement="top-start"
          size="xxl"
          dropdownSize="xl"
          selectedId={selectedId}
          dropdownFooter={<AccountSelectorFooter />}
          dropdownKeepHeightOnSearching
          dropdownMaxHeight="fullScreen"
          getDisplayValue={() => (
            <Styled.ButtonContent>
              <Styled.Text title={buttonMainLabel} weight="medium" truncate inheritColor>
                {buttonMainLabel}
              </Styled.Text>
              <Styled.Text variant="micro" title={buttonSmallLabel} truncate inheritColor>
                {buttonSmallLabel}
              </Styled.Text>
            </Styled.ButtonContent>
          )}
          withSearch
          fullWidth
        />
      )}
    </Styled.MenuAccountSelectorWrapper>
  )
}
