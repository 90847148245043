import { Modal, ModalBody, ModalFooter, ModalHeader } from '@components-deprecated'
import { Button } from '@design-system'

import * as React from 'react'
import { useTranslation } from 'react-i18next'

export interface TaskModalProps {
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
  header: string
  bodyText: string
}

export const TaskModal = ({ isOpen, onConfirm, onClose, header, bodyText }: TaskModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader heading={header} />

      <ModalBody>{bodyText}</ModalBody>

      <ModalFooter>
        <Button onClick={onClose} variant="text">
          {t('no')}
        </Button>
        <Button onClick={onConfirm} variant="primary">
          {t('yes')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
