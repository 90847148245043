import { BorderRadius, Color, getDefaultTransition, rwd, Text } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import throttle from 'lodash/throttle'
import { darken } from 'polished'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text as RebassText } from 'rebass'

import { Themable } from '../../types/themable'
import { Theme } from '../../types/theme'
import { IconCross } from '../Icons'
import * as Styled from './styles'

const WRAPPER_HEIGHT = 160
const WRAPPER_HEIGHT_SMALL = 120
const SCROLL_SMOOTHNESS = 2
const SCROLL_THROTTLE_MS = 50

type DropzoneContainerProps = Themable & {
  active?: boolean
  disabled?: boolean
  reduceHeightBy?: number
}

function getBorderColor(active?: boolean) {
  if (active) {
    return darken(0.2, Color.Gray50)
  }

  return Color.Gray50
}

const getCalculatedHeight = (defaultHeight: number, reducedBy = 0) => css`
  height: ${defaultHeight - reducedBy}px;
`

const DropzoneContainer = styled.div<DropzoneContainerProps>`
  ${({ reduceHeightBy }) => getCalculatedHeight(WRAPPER_HEIGHT, reduceHeightBy)};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: flex;
  width: 100%;
  min-height: 60px;
  align-items: center;
  justify-content: center;
  border: ${({ active }) => `2px dashed ${getBorderColor(active)}`};
  border-radius: ${BorderRadius.XL};
  background-color: ${Color.White};
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  transition: height 0.1s linear;
  ${getDefaultTransition('border')};

  ${rwd.equalOrLessThan('xlarge')} {
    ${({ reduceHeightBy }) => getCalculatedHeight(WRAPPER_HEIGHT_SMALL, reduceHeightBy)};
  }

  &:hover {
    border-color: ${Color.DeepSeaGreen};
  }
`

const ErrorMessage = styled(RebassText)`
  display: flex;
  align-items: center;
  font-size: 16px;
`

type DropzoneAreaProps = {
  active?: boolean
  disabled?: boolean
  error?: boolean
  heading?: string
  loading?: boolean
  multiple?: boolean
  scrollQuerySelector?: string
}

export const DropzoneArea = ({
  active,
  disabled,
  error,
  heading,
  loading,
  multiple,
  scrollQuerySelector,
  ...rest
}: DropzoneAreaProps): ReactElement => {
  const [heightReduce, setHeightReduce] = useState(0)
  const theme = useTheme<Theme>()
  const { t } = useTranslation()

  useEffect(() => {
    if (!scrollQuerySelector) {
      return
    }

    const handleScroll = throttle((event: Event) => {
      const container = event.target as HTMLDivElement | undefined
      const scrollTop = container?.scrollTop

      // scrollTop might be sometimes undefined or 0. We want 0 to be passed
      if (scrollTop !== undefined && scrollTop < WRAPPER_HEIGHT * SCROLL_SMOOTHNESS) {
        const reduceBy = Math.round(scrollTop / SCROLL_SMOOTHNESS)
        setHeightReduce(reduceBy)
      }
    }, SCROLL_THROTTLE_MS)

    const scrollableVoucherList = document.querySelector(scrollQuerySelector)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    scrollableVoucherList?.addEventListener('scroll', handleScroll)

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      scrollableVoucherList?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollQuerySelector])

  return (
    <DropzoneContainer active={active} theme={theme} reduceHeightBy={heightReduce} disabled={disabled} {...rest}>
      {error ? (
        <ErrorMessage>
          <IconCross mt="-1px" width="25px" height="25px" color="warning" /> {t('attachments.error')}
        </ErrorMessage>
      ) : (
        <Styled.DropzoneContentWrapper>
          {loading ? (
            <RebassText fontSize="16px">{t('attachments.uploading')}</RebassText>
          ) : (
            <>
              <Styled.DropzoneIcon />
              <Text weight="medium">
                {heading || (multiple ? t('attachments.header.multiple') : t('attachments.header.single'))}
              </Text>
              <Text colorVariant="secondary" variant="small">
                {t('attachments.upload_button')}
              </Text>
            </>
          )}
        </Styled.DropzoneContentWrapper>
      )}
    </DropzoneContainer>
  )
}
