import { useMutation } from 'react-query'

import { APIError, APIResponse } from '../../../utils'
import { updateRecurringInvoicesSpawnSettings, UpdateRecurringInvoicesSpawnSettingsResponse } from '../query-api'

interface UseUpdateSpawnSettingsProps {
  onError?: (error?: APIError) => void
  onSuccess?: (data: APIResponse<UpdateRecurringInvoicesSpawnSettingsResponse>) => void
}

export const useUpdateSpawnSettings = (props?: UseUpdateSpawnSettingsProps) => {
  const { onError, onSuccess } = props || {}
  const { mutate: update, ...rest } = useMutation(updateRecurringInvoicesSpawnSettings, { onSuccess, onError })

  return { update, ...rest }
}
