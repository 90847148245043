import qs from 'qs'

import { getRawRequest } from '../../utils'

interface FetchSalesWithoutVATFileArgs {
  fromDate: string
  organizationId: string
  toDate: string
}

export const fetchSalesWithoutVATFile = ({ fromDate, organizationId, toDate }: FetchSalesWithoutVATFileArgs) => {
  const queryParams = qs.stringify({
    fromDate,
    toDate,
    asCsv: true,
  })

  return getRawRequest(`/v2/organizations/${organizationId}/salesWithoutVat?${queryParams}`)
}
