import { TFunction } from 'i18next'
import { useMemo } from 'react'

import { Transaction } from '../../../types/transaction'
import { TransactionsTableAccessor } from '../enums/TransactionsTableAccessor'
import { TransactionsTableRow } from '../types/transactionsTableRow'
import { getTransactionSideAmount } from '../utils/getTransactionSideAmount'
import { getTransactionTypeFromOriginatorReference } from '../utils/getTransactionTypeFromOriginatorReference'

export const useTransactionsTableData = (transactions: Transaction[], t: TFunction): TransactionsTableRow[] => {
  return useMemo(
    () =>
      transactions.map(
        ({
          amount,
          entryDate,
          hasVoucher,
          id,
          originatorCurrencyId,
          originatorName,
          originatorReference,
          transactionNo,
          isVoid,
          isVoided,
          side,
          voucherNo,
        }) => {
          return {
            [TransactionsTableAccessor.Amount]: getTransactionSideAmount(amount, side, originatorCurrencyId),
            [TransactionsTableAccessor.EntryDate]: entryDate,
            [TransactionsTableAccessor.Description]: originatorName,
            [TransactionsTableAccessor.Attachment]: hasVoucher,
            [TransactionsTableAccessor.OriginatorType]: getTransactionTypeFromOriginatorReference(
              amount,
              originatorReference,
              side,
              t,
            ),
            [TransactionsTableAccessor.TransactionNo]: transactionNo,
            [TransactionsTableAccessor.Voided]: isVoid || isVoided,
            [TransactionsTableAccessor.VoucherNo]: voucherNo,
            id,
          }
        },
      ),
    [transactions, t],
  )
}
