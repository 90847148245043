import { Button, FormItemsGroup, Text } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFieldArray, useFormContext, useWatch } from '../../../../../../../../../../hooks'
import { CreateOrEditRoleFormSchema } from '../../../../utils/formData'
import { OrganizationSelectLine } from '../OrganizationSelectLine/OrganizationSelectLine'
import * as Styled from './styles'

export const OrganizationSelectionSection = (): ReactElement | null => {
  const { t } = useTranslation()
  const { control } = useFormContext<CreateOrEditRoleFormSchema>()
  const hasAccessToAllOrganizations = useWatch({ control, name: 'grantAll' })
  const organizationsToSkip = useWatch({ control, name: 'organizations' })
  const organizationsIdsToSkip = useMemo(
    () => organizationsToSkip?.map((organization) => organization.organizationId),
    [organizationsToSkip],
  )

  const {
    fields: organizations,
    append: addOrganization,
    remove: removeOrganization,
  } = useFieldArray({
    control,
    name: 'organizations',
  })

  const handleAddLineClick = useCallback(() => {
    addOrganization({ organizationId: '' })
  }, [addOrganization])

  const handleDeleteClick = useCallback(
    (index: number) => () => {
      removeOrganization(index)
    },
    [removeOrganization],
  )

  if (hasAccessToAllOrganizations) {
    return null
  }

  return (
    <FormItemsGroup>
      <Text weight="bold">{t('umbrella_roles.edit_roles_modal.form.subtitle')}</Text>
      {organizations.map((organization, index) => (
        <OrganizationSelectLine
          index={index}
          key={organization.id}
          onOrganizationDelete={handleDeleteClick(index)}
          organizationsIdsToSkip={organizationsIdsToSkip}
        />
      ))}
      <Styled.AddOrganizationButtonWrapper>
        <Button variant="text" icon="plusCircle" onClick={handleAddLineClick}>
          {t('umbrella_roles.edit_roles_modal.form.add')}
        </Button>
      </Styled.AddOrganizationButtonWrapper>
    </FormItemsGroup>
  )
}
