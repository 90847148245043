export default {
  bottomMenuContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50px',
  },
  bottomMenuItem: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
    fontSize: '14px',
    height: '50px',
    'i svg': {
      width: '14px',
      height: '14px',
      marginRight: '5px',
      lineHeight: 0,
    },
  },
  bottomMenuItemLogOut: {
    width: '75px',
    height: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all .38s',
    'i svg': {
      width: '14px',
      height: '14px',
      lineHeight: 0,
    },
  },
  bottomMenuItemSettings: {
    width: '75px',
    height: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all .38s',
    'i svg': {
      width: '14px',
      height: '14px',
      lineHeight: 0,
    },
  },
  bottomMenuItemWide: {
    width: '100px',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'all .38s',
    'i svg': {
      width: '14px',
      height: '14px',
      marginRight: '5px',
      lineHeight: 0,
    },
  },
  bottomMenuHelp: {
    position: 'fixed',
    left: '250px',
    bottom: 0,
    right: 0,
    height: '50px',
    paddingLeft: '20px',
    transition: 'bottom .38s',
  },
}
