import { getProductPlanId } from '@views/signupGuide/elements/UmbrellaOrganizationsProductPlansSelect'

import { ProductPlan } from '../types/productPlan'

interface CleanedUpProductPlan {
  id: string
  name: string
  period: string
  price: string
}

export const getCleanedUpProductPlans = (productPlans: ProductPlan[]): Record<string, CleanedUpProductPlan> =>
  productPlans.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [getProductPlanId(currentValue.product_plan_key, currentValue.product_plan_period)]: {
        id: currentValue.product_plan_id,
        name: currentValue.product_plan_name,
        period: currentValue.product_plan_period,
        price: currentValue.product_plan_price,
      },
    }),
    {},
  )
