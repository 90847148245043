import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { SCROLLABLE_LIST_ROW_HEIGHT } from '../../constants/scrollableListRowHeight'

interface ScrollableListItemWrapperProps {
  disabled?: boolean
  highlighted?: boolean
}

export const ScrollableListItemWrapper = styled.label<ScrollableListItemWrapperProps>`
  display: flex;
  height: ${SCROLLABLE_LIST_ROW_HEIGHT}px;
  width: 100%;
  margin-top: -${Spacing.XXXS};
  padding: 0 ${Spacing.XL} 0 ${Spacing.L};
  border-bottom: 1px solid ${Color.Gray30};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  align-items: center;
  position: relative;
  background-color: ${({ highlighted }) => highlighted && Color.DayGrey};

  &:hover {
    background-color: ${Color.DayGrey};
  }
`
