import { InternalAccessor, Table, TableCellContent, TableColumn } from '@design-system'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Row } from 'react-table'

import { QueryKeys } from '../../enums/queryKeys'
import { SubscriptionInvoice } from '../../types/subscriptionInvoice'
import { ProcessingOverlay } from '../ProcessingOverlay'
import { DateFormatter } from '../TableCellFormatters'
import { Translate } from '../Translate'
import * as Styled from './styles'
import { InvoicesTableRow } from './types/invoicesTableRow'

interface SubscriptionInvoicesListProps {
  invoices?: SubscriptionInvoice[]
  isLoading?: boolean
  fetchInvoicePdf: (invoiceId: string) => () => Promise<string>
}

export const SubscriptionInvoicesList = ({ invoices, isLoading, fetchInvoicePdf }: SubscriptionInvoicesListProps) => {
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>('')

  const { isFetching, refetch } = useQuery(
    [QueryKeys.SubscriptionInvoicePdf, selectedInvoiceId],
    fetchInvoicePdf(selectedInvoiceId),
    {
      enabled: false,
      onSuccess: (pdf: string) => {
        openPdf(pdf)
        setSelectedInvoiceId('')
      },
    },
  )

  const openPdf = useCallback((pdf: string) => {
    const win = window.open()
    if (win) {
      win.document.write(
        '<iframe width="100%" height="100%" title="Invoice" src="data:application/pdf;base64, ' + pdf + '"></iframe>',
      )
    }
  }, [])

  const handleRowClick = useCallback((row: Row<InvoicesTableRow>) => {
    const { id, printUrl } = row.original

    if (printUrl) {
      window.open(printUrl, '_blank')
      return
    }

    setSelectedInvoiceId(id)
  }, [])

  useEffect(() => {
    if (selectedInvoiceId) {
      refetch()
    }
  }, [selectedInvoiceId, refetch])

  const columns: TableColumn<InvoicesTableRow>[] = useMemo(
    () => [
      {
        accessor: 'createdTime',
        Cell: ({ value }) => (
          <TableCellContent>
            <DateFormatter value={value} />
          </TableCellContent>
        ),
        Header: <Translate value="date" />,
        size: 'xxs',
      },
      {
        accessor: 'invoiceNo',
        Header: <Translate value="invoice.no" />,
        size: 'xxs',
      },
      {
        accessor: 'state',
        Header: <Translate value="subscription_invoices.state" />,
        size: 'xxs',
      },
      {
        accessor: 'total',
        Header: <Translate value="total" />,
        size: 'xxs',
      },
    ],
    [],
  )

  const tableData = useMemo(() => {
    if (isLoading || !invoices) {
      return []
    }

    return invoices.map((invoice) => {
      const { id, createdTime, invoiceNo, state, amount, tax, printUrl } = invoice
      return {
        createdTime,
        invoiceNo,
        state,
        total: amount + tax,
        id,
        printUrl,
        [InternalAccessor.HoverActions]: true,
      }
    })
  }, [invoices, isLoading])

  return (
    <Styled.SubscriptionInvoicesListWrapper>
      {isFetching && <ProcessingOverlay />}
      <Table
        columns={columns}
        data={tableData}
        isLoading={isLoading}
        skeletonItemsPerPage={3}
        onRowClick={handleRowClick}
        bordered
      />
    </Styled.SubscriptionInvoicesListWrapper>
  )
}
