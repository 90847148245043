import isEmpty from 'lodash/isEmpty'

import { APIError } from './api'

export const getErrorMessage = (error?: APIError, propertyKey?: string): string | undefined => {
  const validationErrors = error?.validationErrors

  if (validationErrors && propertyKey) {
    const attributes = validationErrors[propertyKey]?.attributes

    if (typeof attributes === 'object' && !isEmpty(attributes)) {
      const messages = Object.values(attributes)
      return messages.join('\n')
    }

    const message = validationErrors[propertyKey]?.message

    if (message) {
      return message
    }
  }

  const message = validationErrors?.message || error?.message

  if (message) {
    return message
  }

  return undefined
}
