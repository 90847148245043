import { useInvoice } from '../../../contexts/invoiceContext'
import { getExternalInvoiceOriginatorReference } from '../elements/InvoiceTransactionLink/utils/getExternalInvoiceOriginatorReference'
import { useInvoiceTransactions } from './useInvoiceTransactions'

export const useExternalInvoiceTransactions = () => {
  const { invoice } = useInvoice()
  const { externalInvoiceId } = invoice || {}
  const { transactions, isLoading } = useInvoiceTransactions(getExternalInvoiceOriginatorReference(externalInvoiceId))

  return {
    transactions,
    isLoading,
  }
}
