import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { ComparisonValue } from '../../../types/comparisonValue'

export const getInternalChangeMockItems = (t: TFunction): NavItem<ComparisonValue>[] | undefined => [
  {
    id: 'internal_changes',
    children: t('reports.comparison_select.changes'),
    value: 'internal_changes',
  },
]
