import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { Spacing } from '../../../../enums/spacing'

export const SectionsGroupList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${() => SectionsGroupItem} {
    + ${() => SectionsGroupItem} {
      margin-top: ${Spacing.L};
      padding-top: ${Spacing.L};
      border-top: 1px solid ${Color.Gray30};
    }
  }
`

export const SectionsGroupItem = styled.li``
