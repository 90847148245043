import { TFunction } from 'i18next'

import { BlockTypeConfig } from '../constants/blockTypes'

export const decorateHeaderBlocksWithCustomLabels = (blocks: BlockTypeConfig[], t: TFunction): BlockTypeConfig[] => {
  if (blocks.length === 1) {
    return [
      {
        ...blocks[0],
        label: t('rich_text.header'),
      },
    ]
  }

  return blocks
}
