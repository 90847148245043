import styled from '@emotion/styled'

import { DefaultValue } from '../../enums/defaultValue'
import { LineHeight } from '../../enums/lineHeight'
import { Themable } from '../../types/themable'
import { skeletonAnimation } from '../../utils/skeletonAnimation'
import { TextVariant } from '../Text'

interface SkeletonTextProps extends Themable {
  variant: TextVariant
  fullWidth?: boolean
  width: number
}

const variantToHeight: Record<TextVariant, LineHeight> = {
  h1: LineHeight.TextH1,
  h2: LineHeight.TextH2,
  h3: LineHeight.TextH3,
  h4: LineHeight.TextH4,
  displayM: LineHeight.TextDisplayM,
  displayS: LineHeight.TextDisplayS,
  displayXS: LineHeight.TextDisplayXS,
  large: LineHeight.TextLarge,
  normal: LineHeight.TextNormal,
  small: LineHeight.TextSmall,
  micro: LineHeight.TextMicro,
}

export const SkeletonText = styled.span<SkeletonTextProps>`
  display: block;
  ${({ width }) => width && `width: ${width}px`};
  ${({ variant }) => `height: ${variantToHeight[variant]}`};
  ${({ fullWidth }) => fullWidth && 'width: 100%'};
  ${skeletonAnimation()};
  border-radius: ${DefaultValue.BorderRadius};
`
