import { useMemo } from 'react'

import { useMultipleBankLines } from '@modules-deprecated/bankReconciliation/hooks/useMultipleBankLines'
import { BankLinesQueryParams } from '@modules-deprecated/bankReconciliation/query-api'
import { ReceiptState } from '@modules-deprecated/bankReconciliation/types'

import { useBankAccounts } from '../../../hooks'

export const getMissingReceiptsQueryParams = (accountId: string): BankLinesQueryParams => ({
  accountId,
  sortDirection: 'DESC',
  sortProperty: 'entryDate',
  receiptState: ReceiptState.MissingReceipt,
  isReconciled: false,
})

export const useMissingReceiptsBankLines = (isEnabled = true) => {
  const { bankAccounts, isLoading: isLoadingBankAccounts } = useBankAccounts()
  const bankAccountsIds = useMemo(
    () => bankAccounts.reduce((ids: string[], { id }) => (id ? [...ids, id] : ids), []),
    [bankAccounts],
  )

  const queryParamsList = useMemo(() => bankAccountsIds.map(getMissingReceiptsQueryParams), [bankAccountsIds])

  const {
    bankLines,
    isLoading: isLoadingBankLines,
    isError,
    errors,
  } = useMultipleBankLines(queryParamsList, isEnabled && !!queryParamsList.length)

  return {
    bankLines,
    isLoading: isLoadingBankLines || isLoadingBankAccounts,
    isError,
    errors,
  }
}
