import { useTranslation } from 'react-i18next'

import { InvoiceDocumentType } from '../../../../enums/invoiceDocumentType'
import { useInvoice } from '../../contexts/invoiceContext'

export const InvoiceTitle = () => {
  const { t } = useTranslation()
  const { invoice, documentType } = useInvoice()

  const isCreditNote = documentType === InvoiceDocumentType.CreditNote

  const invoiceTitleData = {
    invoiceNo: (isCreditNote ? invoice?.creditNoteNumber : invoice?.invoiceNumber) || '',
    name: invoice?.customer?.displayName || '',
  }

  if (isCreditNote) {
    return t('external_invoices.view.title.credit_note', invoiceTitleData)
  }

  return t('external_invoices.view.title.invoice', invoiceTitleData)
}
