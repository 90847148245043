import cc from 'classcat'
import React from 'react'
import { Box, BoxProps } from 'rebass'

import { reactClass } from '../../utils'

export type LabelVariant = 'default' | 'notification' | 'primary' | 'success' | 'warning' | 'danger' | 'secondary'

export type LabelProps = BoxProps & {
  variant?: LabelVariant | null
}

export const Label = ({ as = 'span', children, className, variant = 'default', ...rest }: LabelProps) => (
  <Box as={as} className={cc([className, reactClass('label')])} variant={`label.${variant}`} {...rest}>
    {children}
  </Box>
)
