import { IconButton, Text } from '@design-system'

import React, { ReactNode } from 'react'

import * as Styled from './styles'

export interface SettingsModuleLayoutProps {
  backButtonTo?: string
  children?: ReactNode
  headerTopActions?: ReactNode
  sideActions?: ReactNode
  title?: ReactNode
  withBackButton?: boolean
}

export const SettingsModuleLayout = ({
  backButtonTo,
  children,
  headerTopActions,
  sideActions,
  title,
  withBackButton,
}: SettingsModuleLayoutProps) => (
  <>
    <Styled.SettingsModuleHeaderWrapper>
      {headerTopActions && <Styled.HeaderTopActionsWrapper>{headerTopActions}</Styled.HeaderTopActionsWrapper>}
      <Styled.Header>
        {title && (
          <Styled.TitleWrapper>
            {withBackButton && <IconButton to={backButtonTo} icon="chevronLeft" />}
            <Text variant="h2">{title}</Text>
          </Styled.TitleWrapper>
        )}
        {sideActions && <Styled.SideActions>{sideActions}</Styled.SideActions>}
      </Styled.Header>
    </Styled.SettingsModuleHeaderWrapper>
    {children && <Styled.SettingsModuleContent>{children}</Styled.SettingsModuleContent>}
  </>
)
