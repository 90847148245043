import { InternalAccessor } from '@design-system'

import uniqueId from 'lodash/uniqueId'

import { getLocalDateFromDateString } from '../../../../../utils/getLocalDateFromDateString'
import { AccountStatementsTableRow } from '../enums/accountStatementsTableRow'
import { AccountStatement } from '../types/accountStatement'

export const getAccountStatementsTableData = (accountStatement: AccountStatement): AccountStatementsTableRow[] => {
  return accountStatement.rows.map((statement) => ({
    balance: statement.balance,
    credit: statement.credit,
    currencyId: statement.currencyId,
    debit: statement.debit,
    entryDate: getLocalDateFromDateString(statement.entryDate),
    id: statement.id || uniqueId('unknown-account-statement'),
    originatorName: statement.text ? statement.text : statement.originatorName,
    tax: statement.tax,
    taxAbbreviation: statement.taxAbbreviation,
    transactionId: statement.transactionId,
    transactionNo: statement.transactionNo,
    voided: Boolean(statement.isVoided),
    voucherNo: statement.voucherNo,
    [InternalAccessor.Readonly]: !statement.transactionId,
    [InternalAccessor.HoverActions]: true,
  }))
}
