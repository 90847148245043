import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../enums/queryKeys'
import { getApiCountryKey } from '../../../../utils/getApiCountryKey'
import { useUserOrganization } from '../../organization'
import { fetchCompany } from '../query-api'

export const useFetchCompany = (cvr: string) => {
  const { organization } = useUserOrganization()
  const apiCountryKey = getApiCountryKey(organization?.countryId)

  const { data: companyData, ...rest } = useQuery(
    [QueryKeys.Company, cvr, apiCountryKey],
    () => fetchCompany({ cvr, apiCountryKey }),
    {
      enabled: !!cvr && !!apiCountryKey,
    },
  )

  return { company: companyData?.data, ...rest }
}
