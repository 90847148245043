import { css } from '@emotion/core'

import { ColorOrCurrent } from '../../../types/colorOrCurrent'
import { getIconColorStyles } from './getIconColorStyles'

export const getIconStyles = (sizePx: string, color: ColorOrCurrent) => css`
  width: ${sizePx};
  height: ${sizePx};
  ${getIconColorStyles(color)};
`
