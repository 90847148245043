import styled from '@emotion/styled'

import { DefaultValue } from '../../enums/defaultValue'
import { Spacing } from '../../enums/spacing'
import { pxToNumber } from '../../utils/pxToNumber'
import { rwd } from '../../utils/rwd'

interface ContainerWrapperProps {
  fullWidth?: boolean
  fullHeight?: boolean
}

export const ContainerWrapper = styled.div<ContainerWrapperProps>`
  display: flex;
  width: calc(100% - ${pxToNumber(Spacing.XXXL) * 2}px);
  margin: 0 auto;
  flex-direction: column;

  ${({ fullWidth }) =>
    !fullWidth &&
    `
    max-width: ${DefaultValue.ContainerWidth};
  `};

  ${({ fullHeight }) =>
    fullHeight &&
    `
    height: 100%;
  `};

  ${rwd.equalOrLessThan('xlarge')} {
    width: calc(100% - ${pxToNumber(Spacing.XXL) * 2}px);
  }

  ${rwd.equalOrLessThan('tablet')} {
    width: calc(100% - ${pxToNumber(Spacing.L) * 2}px);
  }
`
