import map from 'lodash/map'
import pick from 'lodash/pick'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { useLocale } from '../../../../../hooks/useLocale'
import { useFiscalYearQueryParams } from '../../../hooks/useFiscalYearQueryParams'
import { fetchInvoiceTimeline, InvoiceTimelineMonthlyResponse, StatsDataQueryParams } from '../../../services/query-api'
import { ChartData } from '../types/chartData'
import { getMonthData } from '../utils'

type OverviewData = {
  chartData: ChartData[]
  result: number
  totalExpenses: number
  totalRevenue: number
}

type UseOverviewResponse = OverviewData & {
  code: string
  currencyId: string
  isLoading: boolean
}

const defaultOverviewData: OverviewData = {
  chartData: [],
  result: 0,
  totalExpenses: 0,
  totalRevenue: 0,
}

export const useOverview = (): UseOverviewResponse => {
  const { code } = useLocale()
  const { organization } = useUserOrganization()
  const { queryParams: fiscalYearQueryParams = {} as StatsDataQueryParams } = useFiscalYearQueryParams(organization?.id)
  const [overviewData, setOverviewData] = useState(defaultOverviewData)

  const { data: invoiceTimeline, isLoading: isInvoiceTimelineLoading } = useQuery(
    [QueryKeys.IncomeTimeline, organization?.id, fiscalYearQueryParams],
    () => fetchInvoiceTimeline(organization?.id || '', fiscalYearQueryParams),
    {
      enabled: !!organization,
      refetchOnMount: 'always',
    },
  )

  const { data: invoiceTimelineData } = invoiceTimeline || {}

  const cleanupChartData = useCallback((chartData: ChartData[]) => {
    for (let i = chartData.length - 1; i >= 0; i--) {
      const chartItem = chartData[i]
      if (chartItem.revenue === 0 && chartItem.expenses === 0) {
        chartData[i] = pick(chartItem, 'monthData')
      } else {
        break
      }
    }
  }, [])

  useEffect(() => {
    if (isInvoiceTimelineLoading) {
      return
    }

    const data = JSON.parse(JSON.stringify(defaultOverviewData))

    map(invoiceTimelineData, ({ income, expenses, revenue }: InvoiceTimelineMonthlyResponse, key: string) => {
      if (key.length !== 7) {
        return
      }

      // Month as YYYY-MM, split by the hyphen and then parse it.
      const [year, monthIndex] = key.split('-').map((str) => Number(str))
      const monthData = getMonthData(year, monthIndex, code)
      expenses = expenses === 0 ? 0 : -expenses

      data.totalRevenue += revenue
      data.totalExpenses += expenses
      data.result += income

      data.chartData.push({
        expenses,
        monthData,
        result: income,
        revenue,
      })
    })

    cleanupChartData(data.chartData)

    setOverviewData({
      chartData: data.chartData,
      result: data.result,
      totalExpenses: data.totalExpenses,
      totalRevenue: data.totalRevenue,
    })
  }, [cleanupChartData, code, invoiceTimelineData, isInvoiceTimelineLoading])

  return {
    chartData: overviewData.chartData,
    code,
    currencyId: organization?.baseCurrencyId || 'DKK',
    isLoading: isInvoiceTimelineLoading || !organization,
    result: overviewData.result,
    totalExpenses: overviewData.totalExpenses,
    totalRevenue: overviewData.totalRevenue,
  }
}
