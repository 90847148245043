import { Button } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateOrEditVatRateFromTemplateModal } from '../../context/createOrEditVatRateFromTemplateModalContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const {
    submitForm,
    isLoading,
    isVatRateFormVisible: isVatRateFormActive,
    isLoadingTemplate,
  } = useCreateOrEditVatRateFromTemplateModal()

  return (
    <Button onClick={submitForm} loading={isLoading} disabled={!isVatRateFormActive || isLoadingTemplate}>
      {t('save')}
    </Button>
  )
}
