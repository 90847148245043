import { pxToNumber, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { UmbrellaPaymentMethodSelection } from '../UmbrellaPaymentMethodSelection'

export const PaymentMethodWrapper = styled(UmbrellaPaymentMethodSelection)`
  margin-top: ${pxToNumber(Spacing.XXXL) - pxToNumber(Spacing.XXS)}px;
  margin-bottom: auto;
  position: sticky;
  top: 0;
`
