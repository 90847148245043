import { Color, DefaultValue, getAnimateRotationStyle, Input as InputComponent, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: ${DefaultValue.BorderRadius};
  padding: ${Spacing.L};
  background-color: ${Color.White};
`

export const Input = styled(InputComponent)`
  width: 100%;
`

export const SearchIconWrapper = styled.figure``

export const CloseIconWrapper = styled.figure`
  margin-left: ${Spacing.M};
  cursor: pointer;
`

export const ArcIconWrapper = styled.figure`
  display: flex;
  ${getAnimateRotationStyle()};
`
