import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'
import { NavListItem } from '../../elements/NavListItem'

export const NavListItemWithInlineDescription = styled(NavListItem)`
  height: auto;
` as typeof NavListItem

export const Content = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const PrefixWrapper = styled.div`
  display: flex;
  margin-right: ${Spacing.S};
`
