import styled from '@emotion/styled'

import { DefaultValue } from '../../enums/defaultValue'
import { Themable } from '../../types/themable'
import { skeletonAnimation } from '../../utils/skeletonAnimation'
import { Size as ButtonSize } from '../Button/types/size'
import { sizeToDimension } from '../Button/utils/getSizeStyle'

const DEFAULT_WIDTH = 150

interface SkeletonBoxProps extends Themable {
  fullWidth?: boolean
  width?: number
  size: ButtonSize
}

export const SkeletonButton = styled.div<SkeletonBoxProps>`
  border-radius: ${DefaultValue.BorderRadius};
  height: ${({ size }) => sizeToDimension[size]}px;
  ${({ width = DEFAULT_WIDTH }) => width && `width: ${width}px`};
  ${({ fullWidth }) => fullWidth && 'width: 100%'};
  ${skeletonAnimation()};
`
