import { useCallback, useEffect, useState } from 'react'

import { CustomEvent } from '../../../enums/customEvent'
import { useCustomEventListener } from '../../../hooks/useCustomEventListener'
import { useSystemColorScheme } from '../../../hooks/useSystemColorScheme'
import { EmberEventProps } from '../../../types/emberEventProps'
import { ThemeLayout } from '../../../types/themeLayout'
import { ThemeLayoutMode } from '../../../types/themeLayoutMode'
import { dispatchCustomEvent } from '../../../utils/customEvent'
import { getGlobalBillyThemeLayoutMode } from '../../../utils/globalBilly'

export const useThemeLayout = () => {
  const { systemColorScheme } = useSystemColorScheme()

  const [themeLayoutMode, setThemeLayoutMode] = useState<ThemeLayoutMode | undefined>()
  const [themeLayout, setThemeLayout] = useState<ThemeLayout | undefined>()

  const dispatchThemeLayoutChanged = useCallback((themeLayout: ThemeLayout | undefined) => {
    if (themeLayout) {
      dispatchCustomEvent<ThemeLayout>(CustomEvent.ThemeLayoutChanged, themeLayout)
    }
  }, [])

  // initialization
  useEffect(() => {
    const initialThemeLayoutMode = getGlobalBillyThemeLayoutMode()

    if (initialThemeLayoutMode) {
      const initialThemeLayout = initialThemeLayoutMode === 'auto' ? systemColorScheme : initialThemeLayoutMode
      setThemeLayoutMode(initialThemeLayoutMode)
      setThemeLayout(initialThemeLayout)
      dispatchThemeLayoutChanged(initialThemeLayout)
    }
  }, [])

  // observe system color scheme
  useEffect(() => {
    if (themeLayoutMode === 'auto') {
      setThemeLayout(systemColorScheme)
      dispatchThemeLayoutChanged(systemColorScheme)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemColorScheme])

  const handleThemeLayoutModeChanged = useCallback(
    (event: EmberEventProps<ThemeLayoutMode>) => {
      const themeLayoutMode = event.detail
      const themeLayout = themeLayoutMode === 'auto' ? systemColorScheme : themeLayoutMode

      setThemeLayoutMode(themeLayoutMode)
      setThemeLayout(themeLayout)
      dispatchThemeLayoutChanged(themeLayout)
    },
    [dispatchThemeLayoutChanged, systemColorScheme],
  )

  useCustomEventListener<ThemeLayoutMode>(CustomEvent.ThemeLayoutModeChanged, handleThemeLayoutModeChanged)

  return { themeLayout }
}
