import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { resetOrganization, ResetOrganizationResponseData } from '../query-api'

interface UseResetOrganizationProps {
  onError?: (error: APIError) => void
  onSuccess?: (data: ResetOrganizationResponseData) => void
}

export const useResetOrganization = (props?: UseResetOrganizationProps) => {
  const { onError, onSuccess } = props || {}
  const { mutate, ...rest } = useMutation(resetOrganization, { onSuccess, onError })

  return { resetOrganization: mutate, ...rest }
}
