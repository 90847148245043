import { Color } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../types/themable'

interface ProcessingOverlayWrapperProps extends Themable {
  bgColor?: Color
}

export const ProcessingOverlayWrapper = styled.div<ProcessingOverlayWrapperProps>`
  opacity: 0.6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${({ bgColor, theme }) => bgColor || theme.ds.colors.base.background};
`
