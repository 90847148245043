import React from 'react'
import { Box } from 'rebass'

import { IconProps } from './Icon'

export const BillyFaceNew = ({ width, height, ...rest }: IconProps) => {
  return (
    <Box as="i" {...rest}>
      <svg width={width || 42} height={height || 42} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184.88 207.65">
        <defs>
          <radialGradient id="billy-face-a" cx="92.37" cy="124.38" r="83.03" gradientUnits="userSpaceOnUse">
            <stop offset="0.08" stop-color="#ffd4cc" />
            <stop offset="0.53" stop-color="#fed3ca" />
            <stop offset="0.72" stop-color="#facec3" />
            <stop offset="0.86" stop-color="#f2c6b6" />
            <stop offset="0.98" stop-color="#e8baa5" />
            <stop offset="1" stop-color="#e5b7a0" />
          </radialGradient>
          <linearGradient
            id="billy-face-b"
            x1="2333.51"
            y1="47.48"
            x2="2364.08"
            y2="90.92"
            gradientTransform="matrix(-1, 0, 0, 1, 2360.12, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.23" stop-color="#5c4a3c" />
            <stop offset="0.48" stop-color="#736357" />
          </linearGradient>
          <linearGradient
            id="billy-face-c"
            x1="24.01"
            y1="40.82"
            x2="0.61"
            y2="23.2"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#billy-face-b"
          />
          <linearGradient
            id="billy-face-d"
            x1="157.06"
            y1="43.65"
            x2="188.48"
            y2="88.29"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#billy-face-b"
          />
          <radialGradient id="billy-face-e" cx="98.54" cy="30.05" r="76.16" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#817469" />
            <stop offset="0.39" stop-color="#7f7267" />
            <stop offset="0.61" stop-color="#796a5f" />
            <stop offset="0.71" stop-color="#736357" />
          </radialGradient>
          <linearGradient
            id="billy-face-f"
            x1="130.24"
            y1="107.98"
            x2="137.63"
            y2="95.18"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#323d3d" />
            <stop offset="1" stop-color="gray" />
          </linearGradient>
          <linearGradient id="billy-face-g" x1="45.25" y1="107.98" x2="52.64" y2="95.18" xlinkHref="#billy-face-f" />
          <linearGradient
            id="billy-face-h"
            x1="2.26"
            y1="105.98"
            x2="181.63"
            y2="105.98"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#474d59" />
            <stop offset="1" stop-color="#526c7f" />
          </linearGradient>
        </defs>
        <path
          d="M92.4,47.82C60.23,47.82,9.89,22.44,9.57,78.6c0,0,2.52,66.9,4.89,80s39,48.71,77.72,49.07h.39c38.71-.36,75.35-36,77.72-49.07s4.89-80,4.89-80C174,19.72,124.56,47.82,92.4,47.82Z"
          fill="url(#billy-face-a)"
        />
        <path
          d="M91.49,166.19c-21.72,0-36.52-8.15-36.75-8.28l1.55-2.76c.34.19,33.82,18.54,73.68-1.42l1.41,2.83A88.37,88.37,0,0,1,91.49,166.19Z"
          fill="#665650"
        />
        <path
          d="M.69,55l7.38,48.12c2.65-22.41-1.8-40.5,14.62-54.43-.48-.78,4.21-8.4,3.52-9Z"
          fill="url(#billy-face-b)"
        />
        <path
          d="M28.91,43.21s4-12.77-14.12-24.2c-.67.67,5.38,12.77,2,14.12S2,33.8,0,37.83c0,0,7.4-.67,13.45,2a36.92,36.92,0,0,1,10.08,6.72Z"
          fill="url(#billy-face-c)"
        />
        <path
          d="M184.88,54.33l-8.16,46.08C174.07,78,174.1,55.88,157.68,42c.48-.79-.32-6.1.37-6.74Z"
          fill="url(#billy-face-d)"
        />
        <path
          d="M13.27,51.66S39.78,24.58,65.37,17.6c7.4-2,20.2-4.45,31.6-3.36,14.12,1.34,21.32,6.06,39,5.38,17.48-.68,24.87-6.05,30.92-10.09s7.4-6,7.4-4,.67,19.5-7.4,26.89c0,0,5.38.67,6.73-1.34s-.67,1.34-4.71,8.06S156.14,68,119.83,66,46.89,35.52,13.27,51.66Z"
          fill="#354a59"
          opacity="0.1"
        />
        <path
          d="M20.77,49.64s21-29.77,46.62-36.75c7.39-2,20.2-4.44,31.6-3.36,14.12,1.35,21.32,6.06,39,5.38,17.48-.67,24.88-6.05,30.93-10.08s7.4-6.06,7.4-4,.67,19.5-7.4,26.9c0,0,5.38.67,6.73-1.35s.67,1.35-3.37,8.07S155.46,62,119.16,60,54.39,33.5,20.77,49.64Z"
          fill="url(#billy-face-e)"
        />
        <path
          d="M161,129.19l11-6.86,2.69-13.6-1.11-14.08-5.47-10.37s-16.22-3.09-17.7-2.77-19.68,0-19.68,0L106,86.35s-4.71,9.81-4.71,11.08,6.19,15.64,6.19,15.64l4.36,7.24s4,5.15,5.52,5.79a43.92,43.92,0,0,0,10,2.89c3.33.32,11.31,1.7,12.79,1.38s10.81-.51,10.81-.51Z"
          fill="#fff"
        />
        <path
          d="M24.34,128.51l-11-6.87L10.65,108,11.76,94,17.24,83.6s16.22-3.1,17.7-2.78,19.68,0,19.68,0l24.65,4.81S84,95.47,84,96.75s-6.2,15.63-6.2,15.63l-4.35,7.24s-4,5.15-5.52,5.79a43.92,43.92,0,0,1-10,2.89c-3.33.32-11.32,1.7-12.79,1.38s-10.81-.51-10.81-.51Z"
          fill="#fff"
        />
        <path d="M140.94,101.87a7.18,7.18,0,1,0-7.17,7.17A7.18,7.18,0,0,0,140.94,101.87Z" fill="url(#billy-face-f)" />
        <circle cx="135.45" cy="99.5" r="1.91" fill="#fff" opacity="0.8" />
        <path d="M56,101.87A7.18,7.18,0,1,0,48.78,109,7.19,7.19,0,0,0,56,101.87Z" fill="url(#billy-face-g)" />
        <circle cx="50.46" cy="99.5" r="1.91" fill="#fff" opacity="0.8" />
        <path
          d="M181.43,82.16a.79.79,0,0,0-.75-.71,318.25,318.25,0,0,0-37.14-2.35c-19.73,0-45.19,5.24-49.63,5.24H90c-2.07,0-8.7-1.14-17.18-2.34C63,80.6,50.87,79.1,40.34,79.1c-12.72,0-24.79,1-31.75,1.73-2.54.26-4.41.49-5.38.62a.78.78,0,0,0-.75.71l-.2,9a.78.78,0,0,0,.57.71l1.2.36c1.77.57,1.62,27.62,11.15,34.37,5.66,4,10.7,6.27,31.92,6.27,12.29,0,20-2.8,27.36-11.18,5.29-6,10-20.25,10-20.25A8,8,0,0,1,91.84,95h.1a8,8,0,0,1,7.51,6.38s4.69,14.23,10,20.25c7.34,8.38,15.07,11.18,27.36,11.18,21.23,0,26.27-2.27,31.93-6.27,9.52-6.75,9.37-33.8,11.14-34.37l1.2-.36a.79.79,0,0,0,.58-.71Zm-107,32c-3.12,6.25-11.17,14.31-22.52,14.8-34,1.46-35.62-6.59-37.81-13a37.49,37.49,0,0,1-1.92-18.35c.9-6.38,2.79-10.11,6.21-12,1.47-.82,3.07-1.56,7.16-2.09a159.61,159.61,0,0,1,18.75-.74,111.56,111.56,0,0,1,15.14,1c12.12,1.65,20.3,5.13,20.3,8.57A55.46,55.46,0,0,1,74.46,114.17ZM169.75,116c-2.19,6.37-3.76,14.42-37.8,13-11.35-.49-19.41-8.55-22.53-14.8a55.46,55.46,0,0,1-5.31-21.77c0-4.73,15.52-9.56,35.44-9.56s23.12,1.27,25.91,2.83c3.42,1.88,5.32,5.61,6.21,12A37.37,37.37,0,0,1,169.75,116Z"
          fill="url(#billy-face-h)"
        />
        <path
          d="M116.5,56.09c-33.89-3-59.8-22.69-90.24-10.83C57.12,34.65,83.47,56.2,117.5,58.09c36.31,2,49.09-18.83,53.12-25.55a58.19,58.19,0,0,0,2.85-5.28C167.36,37.25,151.46,59.21,116.5,56.09Z"
          fill="#fff"
          opacity="0.09"
        />
        <path
          d="M117.4,51.8c-11.1-.92-28.78-4.36-38.52-7V45c12.38,3.48,25.15,7.75,39.42,8.48,32.81,1.69,44.36-15.74,48-21.36a49,49,0,0,0,2.58-4.41C163.36,36.05,149,54.41,117.4,51.8Z"
          fill="#fff"
          opacity="0.05"
        />
      </svg>
    </Box>
  )
}
