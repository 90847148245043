import { SkeletonBox } from '@design-system'

import React, { ReactElement } from 'react'

import { InvalidInvitation } from '@views/organizationInvitation/elements/InvalidInvitation'

import { useUmbrellaInvitation } from './context/umbrellaInvitationContext'
import { AcceptUmbrellaInvitation } from './elements/AcceptUmbrellaInvitation'

export const UmbrellaInvitation = (): ReactElement => {
  const { isLoading, data, error, token } = useUmbrellaInvitation()

  if (isLoading) {
    return <SkeletonBox fullWidth />
  }

  if (!data) {
    return <InvalidInvitation httpStatusCode={error?.statusCode} />
  }

  const {
    existingUser,
    email,
    grantAdmin,
    umbrella: { id, name },
  } = data

  return (
    <AcceptUmbrellaInvitation
      existingUser={existingUser}
      invitationToken={token}
      invitationEmail={email}
      umbrellaName={name}
      umbrellaId={id}
      isGrantAdmin={grantAdmin}
    />
  )
}
