import { Translate } from '@components'
import { Modal, ModalBody, ModalFooter, ModalProps } from '@components-deprecated'
import { Button, Checkbox, Link } from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import * as Styled from './styles'

export interface SalarySignupConfirmationModalProps extends ModalProps {
  onCancel: () => void
  onConfirm: () => void
}

export const SalarySignupConfirmationModal = ({
  type,
  onClose,
  onCancel,
  onConfirm,
  isOpen,
  ...rest
}: SalarySignupConfirmationModalProps): ReactElement => {
  const [areTermsAccepted, setAreTermsAccepted] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setAreTermsAccepted(false)
    }
  }, [isOpen])

  const handleTermsCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setAreTermsAccepted(event.currentTarget.checked)
  }, [])

  return (
    <Modal onClose={onClose || onCancel} isOpen={isOpen} {...rest}>
      <ModalBody>
        <Styled.LogoWrapper>
          <Styled.Logo />
          <Styled.Logo logo="salary" />
        </Styled.LogoWrapper>

        <Styled.SectionTitle variant="h3">
          <Translate value="salary.signup.modal.how_it_works.title" />
        </Styled.SectionTitle>
        <Styled.Paragraph>
          <Translate value="salary.signup.modal.how_it_works.description1" />
        </Styled.Paragraph>
        <Styled.Paragraph>
          <Translate value="salary.signup.modal.how_it_works.description2" />
        </Styled.Paragraph>
        <Styled.Paragraph>
          <Translate value="salary.signup.modal.how_it_works.description3" />
        </Styled.Paragraph>

        <Styled.SectionTitle variant="h3">
          <Translate value="salary.signup.modal.price.title" />
        </Styled.SectionTitle>
        <Styled.Paragraph>
          <Translate value="salary.signup.modal.price.description" />
        </Styled.Paragraph>
      </ModalBody>

      <ModalFooter>
        <Styled.TermsWrapper>
          <Checkbox checked={areTermsAccepted} onChange={handleTermsCheckboxChange}>
            <Trans i18nKey="salary.signup.modal.terms">
              I accept
              <Link href="https://app.salary.dk/terms" target="_blank" underlined>
                the terms
              </Link>
            </Trans>
          </Checkbox>
        </Styled.TermsWrapper>
        <Button onClick={onConfirm} disabled={!areTermsAccepted}>
          <Translate value="salary.signup.modal.submit" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}
