import { useEffect } from 'react'

import { log } from '../utils'

type Options = {
  debug?: boolean // Outputs the key event in console
  disabled?: boolean // Will not register the listener
}

/**
 * @deprecated THIS HOOK IS DEPRECATED.  Please use "useClickAway" from "react-use" package
 * Detect clicks outside of a specified element and execute callback
 * @param ref DOM reference (useRef)
 * @param handler callback (ex. handleClose)
 * @param options Options - modifiers
 * @return void
 */
export const useOnClickOutside = (ref: any | any[], handler: (event: Event) => void, options: Options = {}) => {
  const { debug, disabled } = options as Options

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (!ref && !ref?.length) {
        return
      }

      const refList = Array.isArray(ref) ? ref : [ref]

      if (debug) {
        log(event)
      }

      const isOutsideRefs = refList.every((ref) => ref.current && !ref.current.contains(event.target))

      if (isOutsideRefs) {
        handler(event)
      }
    }

    if (!disabled) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchstart', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [debug, disabled, handler, ref])
}
