import React, { ReactNode, useRef } from 'react'
import { useHoverDirty } from 'react-use'

import { PopOver } from './PopOver'

interface PopOverHoverableProps {
  children: ReactNode
  text: ReactNode
}

export const PopOverHoverable = ({ children, text, ...rest }: PopOverHoverableProps) => {
  const triggerWrapperRef = useRef<HTMLDivElement>(null)
  const isHovering = useHoverDirty(triggerWrapperRef)

  return (
    <>
      <div ref={triggerWrapperRef}>{children}</div>
      <PopOver isOpen={isHovering} {...rest}>
        {text}
      </PopOver>
    </>
  )
}
