import { ItemsPerPage } from '@design-system'

import { fetchUmbrellaOrganizations } from '@modules-deprecated/app/umbrellas/query-api'

import { ApiContentType } from '../../../../enums/apiContentType'
import { BinaryValue } from '../../../../types/binaryValue'
import { Metable, Paging } from '../../../../types/metable'
import { getRequest, postV2Request } from '../../../../utils'
import { CreateUmbrellaVariables } from './types/createUmbrellaVariables'
import { OrganizationSubscriptions } from './types/organizationSubscriptions'
import { PaymentMethod } from './types/paymentMethod'
import { ProductPlan } from './types/productPlan'
import { UmbrellaLicensePack } from './types/umbrellaLicensePack'
import { getCleanedUpProductPlans } from './utils/getCleanedUpProductPlans'

interface GetProductPlansResponseData extends Metable {
  data: ProductPlan[]
}

export const getProductPlans = (umbrellaId: string): Promise<GetProductPlansResponseData> => {
  return getRequest(`/umbrellas/${umbrellaId}/organizations/getProductPlans`)
}

interface GetUmbrellaOrganizationsSubscriptionsOptions {
  page?: number
  pageSize?: ItemsPerPage
  search?: string
}

interface GetUmbrellaOrganizationsSubscriptionsResponse {
  data: OrganizationSubscriptions[]
  pagination: Paging
}

export const getUmbrellaOrganizationsSubscriptions = async (
  umbrellaId: string,
  options: GetUmbrellaOrganizationsSubscriptionsOptions = {},
): Promise<GetUmbrellaOrganizationsSubscriptionsResponse> => {
  const [
    {
      data: umbrellaOrganizations,
      meta: { paging: pagination },
    },
    { data: productPlans },
  ] = await Promise.all([
    fetchUmbrellaOrganizations(umbrellaId, {
      isOwnedByUmbrella: 1,
      page: options.page,
      pageSize: options.pageSize,
      q: options.search,
    }),
    getProductPlans(umbrellaId),
  ])

  const cleanedUpProductPlans = getCleanedUpProductPlans(productPlans)

  return {
    data:
      umbrellaOrganizations.map(({ organizationId, name, subscriptionProductPlan, subscriptionPeriod }) => {
        const productPlanKey = `${subscriptionProductPlan}_${subscriptionPeriod}`
        const productPlan = cleanedUpProductPlans[productPlanKey]

        return {
          name,
          organizationId,
          period: productPlan?.period,
          planId: productPlan?.id,
          price: productPlan?.price,
          subscriptionPlan: productPlan?.name,
        }
      }) || [],
    pagination,
  }
}

interface GetUmbrellaPaymentMethodResponse {
  data: PaymentMethod
}

export const getUmbrellaPaymentMethod = (umbrellaId: string): Promise<GetUmbrellaPaymentMethodResponse> => {
  return getRequest(`/umbrellas/${umbrellaId}/payment-methods/default`)
}

export interface CreateUmbrellaSubscriptionsPayload {
  amount: number
  callbackUrl: string
  cancelUrl: string
  continueUrl: string
  country: string
  currency: string
  language: string
  reusePaymentMethod: BinaryValue
  startSubscription: BinaryValue
  variables: Partial<CreateUmbrellaVariables>
}

interface CreateUmbrellaSubscriptionsFullPayload {
  data: CreateUmbrellaSubscriptionsPayload & {
    umbrellaId: string
  }
}

export interface CreateUmbrellaSubscriptionsResponse {
  data: {
    paymentRequired: boolean
    url: string
  }
}

interface GetUmbrellaLicensePacksResponse {
  data: UmbrellaLicensePack[]
}

export const getUmbrellaLicensePacks = (umbrellaId: string): Promise<GetUmbrellaLicensePacksResponse> => {
  return getRequest(`/umbrellas/${umbrellaId}/subscriptions`)
}

export const createUmbrellaSubscriptions = (umbrellaId: string, payload: CreateUmbrellaSubscriptionsPayload) => {
  return postV2Request<CreateUmbrellaSubscriptionsFullPayload, CreateUmbrellaSubscriptionsResponse>({
    endpoint: `/umbrellas/${umbrellaId}/subscriptions/create`,
    payload: {
      data: { ...payload, umbrellaId },
    },
    options: {
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  })
}
