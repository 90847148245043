import { Modifier, ModifierArguments, Options } from '@popperjs/core'

import { Spacing } from '../../../enums/spacing'
import { pxToNumber } from '../../../utils/pxToNumber'

type Modifiers = Array<Partial<Modifier<any, any>>>

export const getPopperModifiers = (fullWidth: boolean): Modifiers => [
  {
    name: 'offset',
    options: {
      offset: [0, pxToNumber(Spacing.XXS)],
    },
  },
  {
    name: 'fullWidth',
    enabled: fullWidth,
    fn: ({ state }: ModifierArguments<Options>) => {
      state.styles.popper.width = `${state.rects.reference.width}px`
    },
    phase: 'beforeWrite',
    requires: ['computeStyles'],
  },
]
