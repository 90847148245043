import { ModalConfirmation, ModalConfirmationProps, notify, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../../../enums/queryKeys'
import { useDeleteCurrencyDefaultPaymentMethod } from '../../../../hooks/useDeleteCurrencyDefaultPaymentMethod'

interface DeleteCurrencyDefaultPaymentMethodModalProps extends ModalConfirmationProps {
  currencyCode: string
  paymentMethodId: string
}

export const DeleteCurrencyDefaultPaymentMethodModal = ({
  currencyCode,
  paymentMethodId,
  ...modalProps
}: DeleteCurrencyDefaultPaymentMethodModalProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { close: closeDeleteCurrencyDefaultPaymentMethodModal } = useModal(modalProps.id)
  const { delete: deleteCurrencyDefault, isProcessing: isDeleting } = useDeleteCurrencyDefaultPaymentMethod({
    onSuccess: () => {
      closeDeleteCurrencyDefaultPaymentMethodModal()
      notify({
        id: NotificationKeys.CurrencyDefaultPaymentMethodDelete,
        message: t('settings.organization.invoice.currency_default_payment_methods_settings.delete_modal.notification'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.PaymentMethods)
    },
    onError: () => {
      notify({
        id: NotificationKeys.CurrencyDefaultPaymentMethodDelete,
        message: t('error_saving'),
        variant: 'warning',
      })
    },
  })

  const handleDeleteButtonClick = useCallback(() => {
    if (!organization?.id || !paymentMethodId || !currencyCode) {
      return
    }

    deleteCurrencyDefault({ currencyCode, organizationId: organization.id, paymentMethodId })
  }, [currencyCode, deleteCurrencyDefault, organization?.id, paymentMethodId])

  return (
    <ModalConfirmation
      {...modalProps}
      cancelLabel={t(
        'settings.organization.invoice.currency_default_payment_methods_settings.delete_modal.cancel_button',
      )}
      danger
      message={t('settings.organization.invoice.currency_default_payment_methods_settings.delete_modal.body')}
      okLabel={t('settings.organization.invoice.currency_default_payment_methods_settings.delete_modal.delete_button')}
      okLoading={isDeleting}
      onOk={handleDeleteButtonClick}
      title={t('settings.organization.invoice.currency_default_payment_methods_settings.delete_modal.title')}
    />
  )
}
