import { Modal, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const FirstInvoiceModal = styled(Modal)`
  overflow: hidden;
`

export const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const LottieAnimationConfetti = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`

export const FeaturesListItem = styled.li`
  display: flex;
  margin-top: ${Spacing.L};
  align-items: center;
`

export const IconWrapper = styled.figure`
  display: flex;
  align-items: center;
  margin-right: ${Spacing.S};
`

export const FirstInvoiceModalFooter = styled(Modal.Footer)`
  z-index: 1;
`
