import isEmpty from 'lodash/isEmpty'
import { useState } from 'react'

import {
  SetMultipleOrganizationSettingsPayload,
  UpdateOrganizationPayload,
  UpdateOrganizationResponseData,
  useUpdateOrganization,
} from '..'
import { queryClient } from '../../../../config/queryClient'
import { QueryKeys } from '../../../../enums/queryKeys'
import { APIError } from '../../../../utils'
import { useUpdateMultipleOrganizationSettings } from './useUpdateMultipleOrganizationSettings'

interface UseUpdateOrganizationAndSettingsProps {
  onError?: (error?: APIError) => void
  onSuccess?: (data: UpdateOrganizationResponseData) => void
}

export interface UpdateOrganizationAndSettingsProps {
  organizationId: string
  payload: UpdateOrganizationPayload
  settingsPayload: SetMultipleOrganizationSettingsPayload
}

export const useUpdateOrganizationAndSettings = (props?: UseUpdateOrganizationAndSettingsProps) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [isError, setIsError] = useState(false)
  const { onError, onSuccess } = props || {}
  const { updateAsync: updateOrganizationAsync } = useUpdateOrganization({
    onSuccess,
    onError,
  })
  const { updateAsync: updateMultipleOrganizationSettingsAsync } = useUpdateMultipleOrganizationSettings()

  const update = async ({ organizationId, payload, settingsPayload }: UpdateOrganizationAndSettingsProps) => {
    setIsProcessing(true)
    setIsError(false)
    const mutations = []
    mutations.push(updateOrganizationAsync({ organizationId, payload }))

    if (!isEmpty(settingsPayload)) {
      mutations.push(
        updateMultipleOrganizationSettingsAsync({
          organizationId,
          payload: settingsPayload,
        }),
      )
    }

    try {
      await Promise.all(mutations)
      await queryClient.invalidateQueries(QueryKeys.UserOrganizationSettings)
      await queryClient.invalidateQueries(QueryKeys.UserOrganization)
      setIsProcessing(false)
    } catch (error) {
      onError?.()
      setIsError(true)
      setIsProcessing(false)
    }
  }

  return { update, isProcessing, isError }
}
