import { css, SerializedStyles } from '@emotion/core'

import { isSafari } from '../../../../utils/isSafari'
import { Color } from '../../../enums/color'
import { Variant } from '../types'

const getFocusExceptionsStyles = () => {
  if (!isSafari()) {
    return ''
  }

  return css`
    // It removes focus effect in some weird scenarios like during switching items in dropdowns by arrows in Safari browser.
    &:focus-visible {
      box-shadow: none;
    }
  `
}

export const getActiveStyle = (variant: Variant, danger?: boolean, forced?: boolean): SerializedStyles => {
  const selectors = ['&:active', ...(forced ? ['&', '&:hover'] : '')].join(',')

  return {
    primary: css`
      ${getFocusExceptionsStyles()};

      &:not(:disabled) {
        ${selectors} {
          background-color: ${Color.ElectricGreen};
          color: ${Color.DeepSeaGreen};
          box-shadow: none;
        }
      }
    `,
    secondary: css`
      ${getFocusExceptionsStyles()};

      &:not(:disabled) {
        ${selectors} {
          background-color: ${Color.Gray40};
          color: ${Color.DeepSeaGreen};
          box-shadow: none;

          ${danger &&
          css`
            background-color: ${Color.Red10};
            color: ${Color.Red};
            box-shadow: none;
          `}
        }
      }
    `,
    text: css`
      ${getFocusExceptionsStyles()};

      &:not(:disabled) {
        ${selectors} {
          background-color: ${Color.Gray30};
          color: ${Color.DeepSeaGreen};
          box-shadow: none;
        }
      }
    `,
  }[variant]
}
