import { Button, ButtonsGroup, Link, LottieAnimation, ModuleLayout, Space, Text, useTheme } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../contexts/emberRouterContext'
import { EmberRoute } from '../../enums/emberRoute'
import * as Styled from './styles'

interface ApplicationErrorProps {
  errorMessage?: string
}

export const ApplicationError = ({ errorMessage }: ApplicationErrorProps): ReactElement => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const email = theme.ds.data.menu.help.email
  const message = errorMessage || t('application_error.unknown_error')

  const handleLogInButtonClick = useCallback(() => {
    navigate(EmberRoute.Logout)
  }, [navigate])

  return (
    <ModuleLayout fullHeight>
      <Styled.ErrorWrapper>
        <Text variant="h1">{t('application_error.title')}</Text>

        <Space size="m" />

        <Styled.MessageWrapper>
          <Text alignment="center">
            <Trans i18nKey="application_error.message" values={{ message }}>
              We're very sorry, but it looks like
              <br />
              <strong>{message}</strong>
            </Trans>
          </Text>
          <Space size="xl" />
          <Text alignment="center" variant="micro">
            <Trans i18nKey="application_error.contact_info" values={{ email }}>
              You can write to us at <Link href={`mailto:${email}`}>{email}</Link>, or&nbsp;it&nbsp;can most likely be
              resolved by either
            </Trans>
          </Text>
        </Styled.MessageWrapper>

        <Space size="xl" />

        <ButtonsGroup>
          <Button variant="secondary" onClick={handleLogInButtonClick}>
            {t('application_error.action.log_in')}
          </Button>
          <Button href="/">{t('application_error.action.go_to_front_page')}</Button>
          <Button href={window.location.href} variant="secondary">
            {t('application_error.action.reload')}
          </Button>
        </ButtonsGroup>

        <Space size="xxl" />
        <LottieAnimation width={330} height={300} name="applicationError" />
        <Space size="xxl" />

        <Styled.MessageWrapper>
          <Text alignment="center" variant="micro" colorVariant="secondary">
            <Trans i18nKey="application_error.image_footer">
              But you found the Loch Ness-monster!
              <br />
              That's something, right?
            </Trans>
          </Text>
        </Styled.MessageWrapper>
      </Styled.ErrorWrapper>
    </ModuleLayout>
  )
}
