import { SearchInput } from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback } from 'react'

import { useSegment } from '../../../../../../../../../../hooks'
import { useBankLinesFilters } from '../../../../../../contexts/bankLinesFilteringContext'

export const BankLinesSearchInput = (): ReactElement => {
  const { track } = useSegment()
  const { bankLinesSearchQuery, setBankLinesSearchQuery } = useBankLinesFilters()

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setBankLinesSearchQuery(event.target.value || undefined)
      track('xxx - expenses - Bank line modal - search used')
    },
    [setBankLinesSearchQuery, track],
  )

  const handleClear = useCallback(() => {
    setBankLinesSearchQuery(undefined)
  }, [setBankLinesSearchQuery])

  return <SearchInput onChangeDebounced={handleChange} onClear={handleClear} value={bankLinesSearchQuery} />
}
