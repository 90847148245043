import { FlexRight, Header } from '@components-deprecated'
import { Button, ButtonsGroup, notify, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { SpecificState } from '../../../types/reduxSaga-deprecated'
import { BankAccountSelector } from '../../app'
import { bankAccountSelected } from '../../app/accounts/action-creators'
import { Account } from '../../app/accounts/types'
import { startBankDataExport } from '../../inbox/services/api'

export type ModuleHeaderProps = {
  onConnectBankAccount: () => void
  onImport: () => void
}

const AccountSelectorTitle = styled(Text)`
  margin-top: -${Spacing.XXS};
  margin-right: ${Spacing.M};
  align-self: center;
`

export const ModuleHeader = ({ onConnectBankAccount, onImport, ...rest }: ModuleHeaderProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const account = useSelector((state: SpecificState) => state.app.account) as Account
  const bankConnection = useSelector((state: SpecificState) => state.bankReconciliation.bankConnection)

  const handleSplitButtonAction = useCallback(
    (value: string) => {
      if (value === 'connect-bank-account') {
        onConnectBankAccount()
      } else if (value === 'import') {
        onImport()
      }
    },
    [onConnectBankAccount, onImport],
  )

  const handleExportClick = useCallback(async () => {
    if (bankConnection?.organizationId && bankConnection.accountId) {
      const { organizationId, accountId } = bankConnection

      try {
        await startBankDataExport(organizationId, accountId)
        notify({
          id: NotificationKeys.BankReconciliationHeaderExport,
          message: t('bankreconciliation.header.export_scheduled'),
          variant: 'success',
        })
      } catch (e) {
        notify({
          id: NotificationKeys.BankReconciliationHeaderExport,
          message: t('bankreconciliation.header.export_error'),
          variant: 'error',
        })
      }
    }
  }, [bankConnection, t])

  return (
    <Header title={t('bankreconciliation')} {...rest}>
      <FlexRight height="100%" mr={Spacing.S}>
        <AccountSelectorTitle colorVariant="secondary">
          {t('bankreconciliation.header.bankaccounts')}:
        </AccountSelectorTitle>
        <BankAccountSelector
          preselectedId={account?.id}
          onItemSelect={(account: Account) => dispatch(bankAccountSelected(account))}
        />
      </FlexRight>
      {bankConnection ? (
        <ButtonsGroup>
          <Button onClick={onImport} variant="secondary">
            {t('bankreconciliation.header.import')}
          </Button>
          <Button onClick={handleExportClick} variant="secondary">
            {t('bankreconciliation.header.export')}
          </Button>
        </ButtonsGroup>
      ) : (
        <ButtonsGroup>
          <Button onClick={() => handleSplitButtonAction('connect-bank-account')}>
            {t('bankreconciliation.header.connectyourbank')}
          </Button>
          <Button onClick={() => handleSplitButtonAction('import')} variant="secondary">
            {t('bankreconciliation.header.import')}
          </Button>
        </ButtonsGroup>
      )}
    </Header>
  )
}
