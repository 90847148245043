import { css } from '@emotion/core'

const ICON_SIZE = '102px'

export const emptyViewIconStyle = css`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  margin: 0 auto;
  color: transparent;
`
