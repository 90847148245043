import { BorderRadius, Color, getShadow, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SettingsSectionWrapper = styled.div`
  display: flex;
  margin-bottom: ${Spacing.XXL};
  padding: ${Spacing.XXL} ${Spacing.XXXL};
  border-radius: ${BorderRadius.L};
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  background-color: ${Color.White};
  ${getShadow('raisedLight')};

  &:last-child {
    margin-bottom: 0;
  }
`
