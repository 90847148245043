import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const IntegrationCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 253px;
  height: 416px;
  margin: ${Spacing.L} ${Spacing.L} 0 0;
  padding: ${Spacing.XL} ${Spacing.L};
  background-color: ${Color.White};
  border-radius: ${DefaultValue.BorderRadius};
  box-shadow: 0 2px 1px ${Color.Gray10};

  &:last-of-type {
    margin-right: 0;
  }
`
export const CardStatusLabelWrapper = styled.div`
  position: absolute;
  left: ${Spacing.L};
  top: ${Spacing.M};
`

export const CardContentWrapper = styled.div``

export const CardImageWrapper = styled.div`
  height: 120px;
  margin-bottom: ${Spacing.XL};
  text-align: center;
  overflow: hidden;

  svg,
  img {
    height: inherit;
  }
`

export const CardDescriptionWrapper = styled.div`
  margin-top: ${Spacing.S};
`

export const CardSwitchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: ${Spacing.S} 0;

  label {
    width: auto;
  }
`

export const CardActionsWrapper = styled.div``
