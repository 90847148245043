import { Color } from '@design-system'

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as BankReconciliationError } from '../../../../../../../../assets/images/bank-reconciliation-error.svg'
import { ReactComponent as BankReconciliationLineError } from '../../../../../../../../assets/images/bank-reconciliation-line-error.svg'
import { ReactComponent as BankReconciliationLineSuccess } from '../../../../../../../../assets/images/bank-reconciliation-line-success.svg'
import { ReactComponent as BankReconciliationSuccess } from '../../../../../../../../assets/images/bank-reconciliation-success.svg'
import { ReconciliationInfo } from '../../../reconciliationInfo'
import * as Styled from './styles'

export interface ConnectionInboxDisabledProps {
  accountBalanceAmount?: number
  accountCurrencyId?: string
  bankBalanceAmount?: number
  isConnectionError: boolean
}

export const ConnectionInboxDisabled = ({
  accountBalanceAmount,
  accountCurrencyId,
  bankBalanceAmount,
  isConnectionError,
}: ConnectionInboxDisabledProps) => {
  const { t } = useTranslation()

  const isBankReconciled =
    typeof accountBalanceAmount === 'number' &&
    typeof bankBalanceAmount === 'number' &&
    bankBalanceAmount - accountBalanceAmount === 0

  return (
    <>
      {isConnectionError ? (
        <Styled.ConnectionErrorContainer>
          <BankReconciliationError />
          <Styled.Text color={Color.Red}>{t('dashboard.widget.bank-integration.connection_error')}</Styled.Text>
        </Styled.ConnectionErrorContainer>
      ) : (
        <>
          <Styled.ReconciliationIconWrapper>
            {isBankReconciled ? <BankReconciliationSuccess /> : <BankReconciliationError />}
          </Styled.ReconciliationIconWrapper>

          <Styled.IconWrapper>
            {isBankReconciled ? <BankReconciliationLineSuccess /> : <BankReconciliationLineError />}
          </Styled.IconWrapper>

          <ReconciliationInfo
            accountBalanceAmount={accountBalanceAmount}
            accountCurrencyId={accountCurrencyId}
            bankBalanceAmount={bankBalanceAmount}
            isBankReconciled={isBankReconciled}
          />
        </>
      )}
    </>
  )
}
