import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { BankIntegrationContextProvider } from '@views/bankIntegration/contexts/bankIntegrationContext'
import { Financing } from '@views/financing'
import { BankLoadDataContextProvider } from '@views/financing/AgerasFinanceLending'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'

class FinancingWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
}

type FinancingProps = {
  organizationId: string
  onNavigate: EmberNavigate
}

function FinancingModule({ organizationId, onNavigate }: FinancingProps): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <EmberRouterContextProvider onNavigate={onNavigate}>
        <CurrentUserContextProvider>
          <Router>
            <BankLoadDataContextProvider organizationId={organizationId}>
              <UserOrganizationContextProvider organizationId={organizationId}>
                <UserOrganizationSettingsContextProvider organizationId={organizationId}>
                  <ModalContextProvider>
                    <AccountsContextProvider organizationId={organizationId}>
                      <BankIntegrationContextProvider organizationId={organizationId}>
                        <ThemeProvider>
                          <Financing />
                        </ThemeProvider>
                      </BankIntegrationContextProvider>
                    </AccountsContextProvider>
                  </ModalContextProvider>
                </UserOrganizationSettingsContextProvider>
              </UserOrganizationContextProvider>
            </BankLoadDataContextProvider>
          </Router>
        </CurrentUserContextProvider>
      </EmberRouterContextProvider>
    </QueryClientProvider>
  )
}

const FinancingCustomElement = createCustomElement(FinancingModule, FinancingWebComponent, 'element')

customElements.get('financing-module') || customElements.define('financing-module', FinancingCustomElement)

export default FinancingCustomElement
