import { zIndex } from '@design-system'

import styled from '@emotion/styled'

export const MaintenanceBarWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: ${zIndex.Alert};
`
