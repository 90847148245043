import { TableBadgeLabel } from '@components'

import React, { ReactElement, useEffect, useState } from 'react'

interface ContactBalanceTitleProps {
  title: string
  isLoading?: boolean
  total?: number
}

export const ContactBalanceTitle = ({
  title,
  total,
  isLoading: isContactBalanceLoading,
}: ContactBalanceTitleProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!isContactBalanceLoading && isLoading) {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContactBalanceLoading])

  return (
    <TableBadgeLabel amount={total} isLoading={isLoading} variant="neutral">
      {title}
    </TableBadgeLabel>
  )
}
