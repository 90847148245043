import { TableActionsWrapper } from '@components'

import { ReactElement } from 'react'

import { QuotesPeriodSelector } from '../QuotesPeriodSelector'
import { QuotesSearch } from '../QuotesSearch'
import { QuotesTitle } from '../QuotesTitle'

export const QuotesTableActions = (): ReactElement => {
  return (
    <TableActionsWrapper>
      <QuotesTitle />
      <QuotesSearch />
      <QuotesPeriodSelector />
    </TableActionsWrapper>
  )
}
