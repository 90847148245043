import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const UpsellWidgetWrapper = styled.div`
  display: flex;
  height: 100%;
  padding-top: ${Spacing.S};
  flex-direction: column;
  justify-content: space-between;
`

export const Header = styled.div``

export const DescriptionWrapper = styled.div`
  width: 60%;
`

export const Illustration = styled.img`
  position: absolute;
  bottom: ${Spacing.S};
  right: ${Spacing.S};
  pointer-events: none;
`

export const Footer = styled.div``
