import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const DefaultNotificationSettingsWrapper = styled.div`
  border: 1px solid ${Color.Gray40};
  border-radius: ${DefaultValue.BorderRadius};
  margin-bottom: ${Spacing.S};
`
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${Spacing.M} ${Spacing.XL};
`

export const TextWrapper = styled.div`
  flex-grow: 3;
`

export const ButtonWrapper = styled.div`
  min-width: 167px;
`
