import { DefaultValue, getDefaultTransition, getShadow, zIndex } from '@design-system'

import styled from '@emotion/styled'

interface GlobalSearchWrapperProps {
  visible?: boolean
}

export const GlobalSearchWrapper = styled.div<GlobalSearchWrapperProps>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  width: 840px;
  max-width: 80vw;
  border-radius: ${DefaultValue.BorderRadius};
  position: fixed;
  top: 25%;
  left: 50%;
  z-index: ${zIndex.GlobalSearch};
  transform: translate(-50%, ${({ visible }) => (visible ? 0 : '20px')});
  will-change: transform opacity;
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  ${getShadow('elevatedStrong')};
  ${getDefaultTransition('transform', 'opacity')}
`
