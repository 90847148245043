import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { EmberActionPayload } from '@views/signupGuide/context/signupGuideContext'
import { UmbrellaInvitation, UmbrellaInvitationProvider } from '@views/umbrellaInvitation'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'umbrella-invitation-module'

class UmbrellaInvitationWebComponent extends DOMModel {
  @byAttrVal token: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('authorizeUser') authorizeUser: Function
}

interface UmbrellaInvitationProps {
  onNavigate: EmberNavigate
  onAuthorizeUser: (payload: EmberActionPayload) => void
  token: string
}

const UmbrellaInvitationModule = ({ onNavigate, token, onAuthorizeUser }: UmbrellaInvitationProps): ReactElement => {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <EmberRouterContextProvider onNavigate={onNavigate}>
          <UmbrellaInvitationProvider token={token} onAuthorizeUser={onAuthorizeUser}>
            <UmbrellaInvitation />
          </UmbrellaInvitationProvider>
        </EmberRouterContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const UmbrellaInvitationCustomElement = createCustomElement(
  UmbrellaInvitationModule,
  UmbrellaInvitationWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, UmbrellaInvitationCustomElement)

export default UmbrellaInvitationCustomElement
