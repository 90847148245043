import { IconButton, IconButtonProps } from '@design-system'

import React, { memo, ReactElement, useCallback } from 'react'

interface LinkFormatterProps extends Omit<Partial<IconButtonProps>, 'onClick'> {
  onClick: (url: string) => void
  value: string
}

export const LinkFormatter = memo(
  ({ icon = 'arrowOutOfSquare', color, onClick, size = 'm', value, ...rest }: LinkFormatterProps): ReactElement => {
    const handleIconClick = useCallback(() => {
      onClick(value)
    }, [onClick, value])

    return <IconButton {...rest} color={color} icon={icon} size={size} onClick={handleIconClick} />
  },
)
