import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

export interface FlexItemProps extends Styled.FlexItemWrapperProps {
  children?: ReactNode
}

export const FlexItem = ({ as, children, ...rest }: FlexItemProps): ReactElement => {
  return (
    <Styled.FlexItemWrapper as={as} {...rest}>
      {children}
    </Styled.FlexItemWrapper>
  )
}
