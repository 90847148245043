import applicationError from './application-error.json'
import bankEmptyState from './bank-empty-state.json'
import billsEmptyState from './bills-empty-state.json'
import billyLoading from './billy-loading.json'
import confetti from './confetti.json'
import contactsEmptyState from './contacts-empty-state.json'
import dropzoneLoading from './dropzone-loading.json'
import errorIcon from './error-icon.json'
import invoicesEmptyState from './invoices-empty-state.json'
import processing from './processing.json'
import productsEmptyState from './products-empty-state.json'
import quotesEmptyState from './quotes-empty-state.json'
import recurringInvoicesEmptyState from './recurring-invoices-empty-state.json'
import successIcon from './success-icon.json'

export default {
  applicationError,
  bankEmptyState,
  billsEmptyState,
  billyLoading,
  confetti,
  contactsEmptyState,
  dropzoneLoading,
  errorIcon,
  invoicesEmptyState,
  processing,
  productsEmptyState,
  quotesEmptyState,
  recurringInvoicesEmptyState,
  // @todo: replace with the transactions animation
  transactionsEmptyState: invoicesEmptyState,
  successIcon,
}
