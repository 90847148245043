import { DateFormatter, LinkFormatter, Translate } from '@components'
import { Color, Table, TableColumn, Text } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TableIds } from '../../../../../../enums/tableIds'
import { useFormContext } from '../../../../../../hooks/useForm'
import { BillType } from '../../../../enums/billType'
import { Bill } from '../../../../types/bill'
import { DuplicateBillView } from '../../../../types/duplicateBillView'
import { getAmountsFromBillLines } from '../../../../utils/getAmountsFromBillLines'
import { getBillAmountToDisplayValue } from '../../../../utils/getBillAmountToDisplayValue'
import { BillFormSchema } from '../../../BillEditableForm/utils/formData'
import * as Styled from './styles'

interface BillDuplicationTableProps {
  duplicatedBills: Bill[]
}

const NEW_BILL_ID = 'new-bill'

export const BillDuplicationTable = ({ duplicatedBills }: BillDuplicationTableProps): ReactElement => {
  const { t } = useTranslation()
  const { getValues } = useFormContext<BillFormSchema>()

  const getRowColor = useCallback((id: string) => (id === NEW_BILL_ID ? Color.Red : undefined), [])

  const openDuplicateBill = useCallback((url: string) => window.open(url, '_blank'), [])

  const duplicateBillsTableData: DuplicateBillView[] = useMemo(() => {
    const duplicateBillsTableData: DuplicateBillView[] = []

    const { billDate, billLines, currencyId, vendor } = getValues()
    const { inclVatAmountTotal } = getAmountsFromBillLines(billLines)

    // Current Bill
    duplicateBillsTableData.push({
      amountInclVAT: getBillAmountToDisplayValue({
        amount: inclVatAmountTotal,
        currency: currencyId || 'DKK',
        isCreditNote: false,
      }),
      date: billDate || '',
      description: billLines?.[0].description || '',
      vendor: vendor?.name || '',
      id: NEW_BILL_ID,
    })

    const currentLocation = window.location.href.split('#/')[0]

    // Duplicate Bills with same date, amount, currencyId, and vendor
    for (const bill of duplicatedBills) {
      duplicateBillsTableData.push({
        amountInclVAT: getBillAmountToDisplayValue({
          amount: bill.grossAmount || 0,
          currency: bill.currencyId || currencyId || 'DKK',
          isCreditNote: bill.type === BillType.CreditNote,
        }),
        date: bill.entryDate || '',
        description: bill.lineDescription || '',
        id: bill.id,
        url: `${currentLocation}/${bill.id}`,
        // Use the vendor name of current bill as it should be the same
        vendor: bill.contactName || vendor?.name || '',
      })
    }

    return duplicateBillsTableData
  }, [duplicatedBills, getValues])

  const columns: TableColumn<DuplicateBillView>[] = useMemo(
    () => [
      {
        Header: <Translate value="bills.table.column.date" />,
        accessor: 'date',
        size: 's',
        Cell: ({ data, row, value }) => {
          const { id } = data[row.id]
          return <DateFormatter color={getRowColor(id)} value={value} />
        },
      },
      {
        Header: <Translate value="bills.table.column.vendor" />,
        accessor: 'vendor',
        size: 'm',
        Cell: ({ data, row, value }) => {
          const { id } = data[row.id]
          return (
            <Text color={getRowColor(id)} truncate>
              {value}
            </Text>
          )
        },
      },
      {
        Header: <Translate value="bills.table.column.description" />,
        accessor: 'description',
        size: 'xxl',
        Cell: ({ data, row, value }) => {
          const { id } = data[row.id]
          return (
            <Text color={getRowColor(id)} truncate>
              {value}
            </Text>
          )
        },
      },
      {
        Header: <Translate value="bills.table.column.included_vat" />,
        accessor: 'amountInclVAT',
        alignment: 'right',
        size: 's',
        Cell: ({ data, row, value }) => {
          const { id } = data[row.id]
          return <Text color={getRowColor(id)}>{value}</Text>
        },
      },
      {
        Header: '',
        accessor: 'url',
        size: 'xxs',
        Cell: ({ data, row, value }) => {
          const { id } = data[row.id]
          return value ? <LinkFormatter color={getRowColor(id)} onClick={openDuplicateBill} value={value} /> : null
        },
      },
    ],
    [getRowColor, openDuplicateBill],
  )

  return (
    <Styled.DuplicateBillsTableWrapper>
      <Styled.DuplicateBillsLabel>
        <Text color={Color.Green120}>{t('bill.duplicate.modal.table.label.new')}</Text>
        <Text color={Color.Green120}>{t('bill.duplicate.modal.table.label.existing')}</Text>
      </Styled.DuplicateBillsLabel>
      <Table
        columns={columns}
        data={duplicateBillsTableData}
        id={TableIds.DuplicateBill}
        withColumnsFiltering={false}
        withPagination={false}
      />
    </Styled.DuplicateBillsTableWrapper>
  )
}
