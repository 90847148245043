import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import { Router } from 'react-router-dom'

import { useReactEmberRouterConnection } from '../../hooks/useReactEmberRouterConnection'
import { history } from '../../utils/history'

const MODULE_NAME = 'routing-module'

class DashboardWebComponent extends DOMModel {
  @byAttrVal dummy: string
}

interface MenuModuleProps {
  dummy: string
}

const RoutingHelper = () => {
  useReactEmberRouterConnection()

  return null
}

const MenuModule = ({ dummy }: MenuModuleProps) => (
  <Router history={history}>
    <RoutingHelper />
  </Router>
)

const MenuElement = createCustomElement(MenuModule, DashboardWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, MenuElement)

export default MenuElement
