import { NavItem, TableStaticActions, useModal, withModalConditionalRender } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DeleteUmbrellaInvitationModal } from '../../../DeleteUmbrellaInvitationModal'

interface InvitationsTableHoverActionsProps {
  invitationId: string
  umbrellaId: string
}

enum InvitationsTableStaticActionType {
  Delete = 'delete',
}

const DeleteUmbrellaInviteModalConditional = withModalConditionalRender(DeleteUmbrellaInvitationModal)

export const getDeleteUmbrellaInviteModalId = (id: string) => `${id}-delete-umbrella-user-invite`

export const InvitationsTableStaticActions = ({
  invitationId,
  umbrellaId,
}: InvitationsTableHoverActionsProps): ReactElement => {
  const { t } = useTranslation()
  const deleteUmbrellaInviteModalId = getDeleteUmbrellaInviteModalId(invitationId)
  const { open: openDeleteUmbrellaInviteModal } = useModal(deleteUmbrellaInviteModalId)

  const staticActionsItems: NavItem<InvitationsTableStaticActionType>[] = useMemo(
    () => [
      {
        id: 'delete',
        children: t('umbrella_users.invitationTable.delete'),
        value: InvitationsTableStaticActionType.Delete,
        iconLeft: 'trash',
      },
    ],
    [t],
  )

  const handleStaticActionSelect = useCallback(openDeleteUmbrellaInviteModal, [openDeleteUmbrellaInviteModal])

  return (
    <>
      <TableStaticActions items={staticActionsItems} onSelect={handleStaticActionSelect} />
      <DeleteUmbrellaInviteModalConditional
        id={deleteUmbrellaInviteModalId}
        umbrellaId={umbrellaId}
        invitationId={invitationId}
      />
    </>
  )
}
