import { EmptyView, emptyViewVariants } from '@components'
import { ModuleLayout } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { Scope } from '../../../../enums/scope'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { InvoicesRoute } from '../../enums/invoicesRoute'
import { useIsInvoicesListEmpty } from '../InvoicesList/hooks/useIsInvoicesListEmpty'

export const InvoicesListEmpty = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const isInvoicesListEmpty = useIsInvoicesListEmpty()

  const shouldSeeCreateButton = isAuthorized(Scope.InvoiceWrite)

  const handleEmptyViewClick = useCallback(() => {
    navigate(EmberRoute.InvoicesNew)
  }, [navigate])

  const { animation, description, title, buttonText } = emptyViewVariants.invoices

  if (!isInvoicesListEmpty) {
    return <Redirect to={InvoicesRoute.List} />
  }

  return (
    <ModuleLayout title={t('invoices')}>
      <EmptyView
        animation={animation}
        onClick={handleEmptyViewClick}
        showCTA={shouldSeeCreateButton}
        tButton={buttonText}
        tDescription={description}
        tTitle={title}
      />
    </ModuleLayout>
  )
}
