import { ProgressNotificationVariant } from '@design-system'

import { DownloadStatus } from '../types/downloadStatus'

export const downloadStatusToProgressVariant: Record<DownloadStatus, ProgressNotificationVariant> = {
  pending: 'default',
  processing: 'default',
  success: 'success',
  error: 'error',
  timeout: 'success',
}
