import React, { forwardRef, ReactElement, Ref, useCallback } from 'react'

import { NavItem } from '../../types/navItem'
import { NavListItem } from '../NavListItem'
import * as Styled from './styles'

interface NavListHeaderBreadcrumbProps<T> {
  focusedId?: string
  item: NavItem<T>
  onClick?: (id: string, value: T) => void
}

const NavListHeaderBreadcrumbForwarded = <T,>(
  { focusedId, item, onClick }: NavListHeaderBreadcrumbProps<T>,
  forwardedRef: Ref<HTMLLIElement>,
): ReactElement => {
  const handleClick = useCallback(() => {
    onClick?.(item.id, item.value)
  }, [item.id, item.value, onClick])

  return (
    <Styled.NavListHeaderBreadcrumbWrapper ref={forwardedRef}>
      <NavListItem as="div" focusedId={focusedId} item={{ ...item, iconLeft: 'chevronLeft' }} onClick={handleClick} />
    </Styled.NavListHeaderBreadcrumbWrapper>
  )
}

export const NavListHeaderBreadcrumb = forwardRef(NavListHeaderBreadcrumbForwarded) as <T>(
  props: NavListHeaderBreadcrumbProps<T> & { ref?: Ref<HTMLLIElement> },
) => ReactElement
