import { FontWeight } from '@design-system'

import { css, SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'
import cc from 'classcat'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { Box, BoxProps, Text as TextComponent, TextProps as TextComponentProps } from 'rebass'

import { Themable } from '../../types/themable'
import { Theme } from '../../types/theme'
import { reactClass } from '../../utils'
import { IconCaretDown } from '../Icons'
import styles from './styles'
import { SortDirection, TableColumn, TableRow } from './types'

export type TableHeadProps<T> = BoxProps & {
  columns: TableColumn<T>[]
  onSort?: (property: string) => void
  onHover?: (isHovered: boolean) => void
  rowStyles?: SerializedStyles
}

type TextProps = Themable & TextComponentProps

const Text = styled(TextComponent)<TextProps>`
  color: ${({ theme }) => theme.ds.colors.base.textPrimary};
  font-weight: ${FontWeight.Medium};
`

export const TableHead = <T extends TableRow>({
  className,
  columns,
  onHover = () => null,
  onSort = () => null,
  rowStyles,
  ...rest
}: TableHeadProps<T>) => {
  const theme = useTheme<Theme>()

  return (
    <Box
      as="thead"
      className={cc([className, reactClass('infinite-table-head')])}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      {...rest}
    >
      <Box as="tr" css={rowStyles}>
        {columns.map(({ field, isHeaderVisible = true, text = '', type, sortDirection, sortProperty }, index) => (
          <Box
            as="th"
            key={field.toString()}
            css={css`
              display: flex;
              justify-content: ${styles[type].justifyContent};
            `}
            onClick={() => sortProperty && onSort(sortProperty)}
          >
            <Text
              key={index}
              variant="tableHeader"
              theme={theme}
              css={css`
                margin-right: 5px;
                &:hover {
                  cursor: ${sortProperty ? 'pointer' : 'default'};
                  text-decoration: ${sortProperty ? 'underline' : 'none'};
                }
              `}
            >
              {isHeaderVisible && text}
            </Text>
            {sortDirection && sortDirection !== SortDirection.None && (
              <IconCaretDown
                width={12}
                height={12}
                rotate={sortDirection === SortDirection.Desc}
                color={theme.ds.colors.base.textPrimary}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
