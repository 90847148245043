import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const NameAndBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing.L};
  cursor: pointer;
`
