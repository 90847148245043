import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconUsers = ({
  color = '#7151e5',
  color2 = '#fff',
  width = '20px',
  height = '20px',
  isOutlined,
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-users')} {...rest}>
    <svg width={width} height={height} x="0px" y="0px" viewBox="0 0 366.12 304.46">
      <path
        d="M264,347.85V319.24a61.65,61.65,0,0,0-61.66-61.65H79a61.66,61.66,0,0,0-61.66,61.65v28.61a2.21,2.21,0,0,0,2.22,2.22H261.75A2.21,2.21,0,0,0,264,347.85Z"
        transform="translate(-3.84 -59.11)"
        fill={isOutlined ? 'transparent' : coloring(color)}
        stroke={coloring(color2)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="27"
      />
      <circle
        cx="136.82"
        cy="75.16"
        r="61.66"
        fill={isOutlined ? 'transparent' : coloring(color)}
        stroke={coloring(color2)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="27"
      />
      <path
        d="M356.46,350.07V319.24a61.67,61.67,0,0,0-46.24-59.65"
        transform="translate(-3.84 -59.11)"
        fill="none"
        stroke={coloring(color2)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="27"
      />
      <path
        d="M248.56,74.62a61.66,61.66,0,0,1,0,119.46"
        transform="translate(-3.84 -59.11)"
        fill="none"
        stroke={coloring(color2)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="27"
      />
    </svg>
  </Box>
)
