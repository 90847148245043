import { SettingsSection } from '@components'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { useUserProfilePictureForm } from '../../../../hooks/useProfilePicForm'
import { ProfilePicDropzone } from '../../../ProfilePicDropzone'
import * as Styled from './styles'

export const ProfilePictureForm = () => {
  const { t } = useTranslation()
  const { Form, FormItem } = useUserProfilePictureForm()

  return (
    <SettingsSection>
      <Form>
        <SettingsSection.RowsContainer>
          <SettingsSection.Content
            title={t('settings.user.profile_picture')}
            subtitle={t('settings.user.profile_picture.subtitle')}
          >
            <FormItem
              name="profilePicFileId"
              render={({ field: { onChange, value } }) => (
                <Styled.ProfilePicWrapper>
                  <ProfilePicDropzone onChange={onChange} value={value} />
                </Styled.ProfilePicWrapper>
              )}
            />
          </SettingsSection.Content>
        </SettingsSection.RowsContainer>
      </Form>
    </SettingsSection>
  )
}
