import omit from 'lodash/omit'

import { BookkeepingTagDbValue } from '../types/bookkeepingTagDbValue'
import { BookkeepingTagNavItemValue } from '../types/bookkeepingTagNavItemValue'

export const convertBookkeepingTagNavItemValueToDbValue = (
  navItemValue: BookkeepingTagNavItemValue,
): BookkeepingTagDbValue | undefined => {
  if (!navItemValue.tag || !navItemValue.response) {
    return undefined
  }

  return {
    tagId: navItemValue.tag.id.toString(),
    name: navItemValue.tag.name,
    answer: omit(navItemValue.response, 'result'),
  }
}
