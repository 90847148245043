import { BorderRadius, Color, getShadow } from '@design-system'

import styled from '@emotion/styled'
import SimpleBar from 'simplebar-react'

export const ScrollableListWrapper = styled.div`
  height: 100%;
  border-radius: ${BorderRadius.L};
  border: 1px solid ${Color.Gray30};
  ${getShadow('raisedLight')}
  overflow: hidden;
`

export const ScrollableListContent = styled(SimpleBar)`
  height: 100%;
`
