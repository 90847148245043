import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0;
`

export const IncludedItem = styled.div`
  margin-bottom: 8px;
  flex-basis: 50%;
  display: flex;
  padding-right: 20px;
`

export const IconWrapper = styled.div``

export const TextWrapper = styled.div`
  flex: 1;
  padding: 2px 8px;
`

export const Text = styled.p``
