import { useMutation } from 'react-query'

import { APIError } from '../../../utils'
import { postEInvoice, PostEInvoicePayload } from '../query-api'

interface UseSendEInvoiceProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useSendEInvoice = (props?: UseSendEInvoiceProps) => {
  const { onError, onSuccess } = props || {}

  const {
    mutate: sendEInvoice,
    isLoading,
    error,
  } = useMutation((props: PostEInvoicePayload) => postEInvoice(props), {
    onError,
    onSuccess,
  })

  return { isLoading, sendEInvoice, error }
}
