import { PrismicArticle } from '@components-deprecated'

import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { prismicGetDocument } from './action-creators'

type PrismicProps = {
  type: string
  uid: string
}

export function Prismic({ type, uid }: PrismicProps): ReactElement | null {
  const dispatch = useDispatch()
  const content = useSelector((state: any) => {
    const articles = state.prismic.entities.prismicArticle
    if (articles) {
      const content = Object.keys(articles).map((article) => articles[article])[0]
      return content
    }
  })

  useEffect(() => {
    dispatch(prismicGetDocument({ type, uid }))
  }, [dispatch, type, uid])

  if (!content) {
    return null
  }

  return <PrismicArticle type={type} uid={uid} content={content.data.content} />
}
