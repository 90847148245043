import { Color, zIndex } from '@design-system'

import styled from '@emotion/styled'
import { transparentize } from 'polished'

interface FullScreenOverlayWrapperProps {
  bgColor: Color
  bgOpacity: number
  scrollable?: boolean
}

export const FullScreenOverlayWrapper = styled.div<FullScreenOverlayWrapperProps>`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: ${zIndex.FullScreenOverlay};
  background-color: ${({ bgColor, bgOpacity }) => transparentize(1 - bgOpacity, bgColor)};
`
