import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchBankSessions } from '../query-api'

export const useBankConnectionSessions = () => {
  const { organization } = useUserOrganization()
  const organizationId = organization?.id

  const { data: bankConnectionSessions, isLoading } = useQuery(
    [QueryKeys.BankConnectionSessions, organizationId],
    () => fetchBankSessions(organizationId as string),
    {
      enabled: !!organizationId,
    },
  )

  return {
    bankConnectionSessions,
    isLoading,
  }
}
