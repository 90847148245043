/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Dropzone /> from design-system instead
 */
import React, {
  cloneElement,
  DragEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Box } from 'rebass'

import { Timeout } from '../../types/timeout'
import { DropzoneArea } from './DropzoneArea'
import { decorateFilesWithCustomErrors, LimitOptions } from './utils/decorateFilesWithCustomErrors'

export type DropzoneProps = {
  accept?: Accept // List of accepted mime types (or single one as string)
  allowMultipleFileUpload?: boolean // Pass true to allow multiple files at once
  borderOnDrag?: string // Display a border over active area on drag (example: '2px dashed #eee')
  children?: ReactNode
  delay?: number // [milliseconds]  Delay reset, set loading to false, default 2500
  disabled?: boolean
  fail?: boolean // If true, shows fail animation and resets to ready state after [delay] milliseconds
  heading?: string // Custom heading, for example "Upgrade your plan to upload files"
  maxFiles?: number // Maximum accepted number of files (default is 0 - no limit files)
  maxSize?: number // Maximum file size (in bytes)
  minSize?: number // Minimum file size (in bytes)
  noClick?: boolean // If true, image is not longer clickable
  noDrag?: boolean // If true, disables drag 'n' drop
  onDragEnter?: (event: DragEvent) => void // Executes when files enter dropzone
  onDragLeave?: (event: DragEvent) => void // Executes when files leave dropzone
  onDragOver?: (event: DragEvent) => void // Executes when files move over dropzone
  onDrop?: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void // Executes when the drop event occurs
  onDropAccepted?: <T extends File>(files: T[], event: DropEvent) => void // Executes if files are accepted
  onDropRejected?: (fileRejections: FileRejection[], event: DropEvent) => void // Executes if files are rejected
  onFileDialogCancel?: () => void // Executes when the user closes the native file window
  scrollQuerySelector?: string
  success?: boolean // If true, shows animation for success, component does not automatically reset
}

export const ACCEPT_DEFAULT: Accept = {
  'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
  'application/pdf': ['.pdf'],
}

export const Dropzone = ({
  accept = ACCEPT_DEFAULT,
  allowMultipleFileUpload: multiple,
  borderOnDrag,
  children,
  delay = 2500,
  disabled,
  fail,
  heading,
  maxFiles,
  maxSize,
  minSize,
  noClick,
  noDrag,
  onDragEnter = () => null,
  onDragLeave = () => null,
  onDragOver = () => null,
  onDrop = () => null,
  onDropAccepted = () => null,
  onDropRejected = () => null,
  onFileDialogCancel = () => null,
  scrollQuerySelector,
  success,
  ...rest
}: DropzoneProps): ReactElement => {
  const isMountedRef = useRef(false)
  const [error, setError] = useState(!!fail)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const limitOptions: LimitOptions = useMemo(
    () => ({
      maxFiles,
      maxSize,
      minSize,
      types: ACCEPT_DEFAULT,
    }),
    [maxFiles, maxSize, minSize],
  )

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  const handleDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void =
    useCallback(
      (acceptedFiles, fileRejections, event) => {
        if (!isMountedRef.current) {
          return
        }

        setError(false)
        setLoading(true)
        decorateFilesWithCustomErrors(fileRejections, limitOptions, t)
        onDrop(acceptedFiles, fileRejections, event)
      },
      [onDrop, limitOptions, t],
    )

  const handleDropAccepted: <T extends File>(files: T[], event: DropEvent) => void = useCallback(
    <T extends File>(files: T[], event: DropEvent) => {
      if (!isMountedRef.current) {
        return
      }

      setError(false)
      setLoading(false)
      onDropAccepted(files, event)
    },
    [onDropAccepted],
  )

  const handleDropRejected = useCallback(
    (fileRejections: FileRejection[], event: DropEvent) => {
      if (!isMountedRef.current) {
        return
      }

      setError(true)
      setLoading(false)
      decorateFilesWithCustomErrors(fileRejections, limitOptions, t)
      onDropRejected(fileRejections, event)
    },
    [onDropRejected, limitOptions, t],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    disabled,
    maxFiles,
    maxSize,
    minSize,
    multiple,
    noClick: noClick || noDrag || loading,
    noDrag: noDrag || loading,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop: handleDrop,
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
    onFileDialogCancel,
  })

  useEffect(() => {
    let timerId: Timeout

    if (fail) {
      setError(true)
      timerId = setTimeout(() => setLoading(false), delay)
    } else {
      setError(false)
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [delay, fail])

  const renderDropzoneContent = () => {
    const isDisabled = disabled || noDrag || noClick

    if (children) {
      return cloneElement(children as ReactElement, { disabled: isDisabled, error, loading, isDragActive, ...rest })
    }

    return (
      <DropzoneArea
        disabled={isDisabled}
        active={isDragActive}
        multiple={multiple}
        error={error}
        scrollQuerySelector={scrollQuerySelector}
        {...rest}
      />
    )
  }

  return (
    <Box {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      {renderDropzoneContent()}
    </Box>
  )
}
