import { useParams } from 'react-router-dom'

interface CreateOrganizationParams {
  umbrellaId?: string
}

export const useInternalUmbrellaId = (): string | undefined => {
  const { umbrellaId } = useParams<CreateOrganizationParams>()

  return umbrellaId
}
