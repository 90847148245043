import { BadgeVariant, SkeletonText } from '@design-system'

import { ReactElement, ReactNode } from 'react'

import { BadgeLabel } from '../BadgeLabel'
import * as Styled from './styles'

interface TableBadgeLabelProps {
  amount?: number
  children?: ReactNode
  isLoading?: boolean
  variant?: BadgeVariant
}

export const TableBadgeLabel = ({ amount, children, isLoading, variant }: TableBadgeLabelProps): ReactElement => {
  return (
    <Styled.TableBadgeLabelWrapper>
      {isLoading ? (
        <SkeletonText variant="h3" width={78} />
      ) : (
        <BadgeLabel badgeAmount={amount || 0} badgeVariant={variant} label={children} />
      )}
    </Styled.TableBadgeLabelWrapper>
  )
}
