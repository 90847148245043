import { CreditCardProps, ExpiredCardInfo, PaymentMethodSelection } from '@components'
import { Module, SkeletonText } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Footer } from './elements/Footer'
import { SubscriptionDetails } from './elements/SubscriptionDetails'

interface UmbrellaPaymentMethodSelectionProps {
  cardDetails?: CreditCardProps
  isAnnualSubscription?: boolean
  isCardExpired?: boolean
  isLoading?: boolean
  isPaidSubscription?: boolean
  isSubscribed?: boolean
  isTrial?: boolean
  onAddPaymentClick: () => void
  onEditPaymentInformationClick?: () => void
  onEnterCouponCodeSubmit?: (couponCode: string) => void
  onUpdatePaymentClick?: () => void
  price?: number
  subscriptionName?: string
  withSubscriptionDetails?: boolean
}

export const UmbrellaPaymentMethodSelection = ({
  cardDetails,
  isAnnualSubscription,
  isCardExpired,
  isLoading,
  isPaidSubscription,
  isSubscribed,
  isTrial,
  onAddPaymentClick,
  onEditPaymentInformationClick,
  onEnterCouponCodeSubmit,
  onUpdatePaymentClick,
  price,
  subscriptionName,
  withSubscriptionDetails,
  ...rest
}: UmbrellaPaymentMethodSelectionProps): ReactElement => {
  const { t } = useTranslation()

  const titleContent = withSubscriptionDetails
    ? t('billing_summary.subscribed.title')
    : t('billing_summary.not_subscribed.title')

  return (
    <Module {...rest}>
      <Module.Section title={isLoading ? <SkeletonText variant="h3" /> : titleContent}>
        {withSubscriptionDetails && (
          <SubscriptionDetails
            isAnnualPayment={isAnnualSubscription}
            isLoading={isLoading}
            price={price}
            subscriptionName={subscriptionName}
            withPrice={isPaidSubscription && !isTrial}
          />
        )}
        <PaymentMethodSelection
          cardDetails={cardDetails}
          isLoading={isLoading}
          isSubscribed={isSubscribed}
          onAddPaymentClick={onAddPaymentClick}
          fullWidth
        />
        {isCardExpired && <ExpiredCardInfo onUpdateCard={onUpdatePaymentClick} />}
        {!isLoading && (
          <Footer
            onEditPaymentInformationClick={onEditPaymentInformationClick}
            onEnterCouponCodeSubmit={onEnterCouponCodeSubmit}
          />
        )}
      </Module.Section>
    </Module>
  )
}
