import { Translate } from '@components'
import { AmountInfoBox, AmountInfoBoxesGroup, AmountInfoBoxProps } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { InvoiceStatus } from '../../../../enums/invoiceStatus'
import { invoiceStatusToBadgeVariant } from '../../constants/invoiceStatusToBadgeVariant'
import { invoiceStatusToTitleKey } from '../../constants/invoiceStatusToTitleKey'
import { useInvoicesListFilters } from '../../hooks/useInvoicesListFilters'
import { useInvoicesSummaryFiltered } from '../../hooks/useInvoicesSummaryFiltered'
import * as Styled from './styles'

const SUMMARY_BOXES_STATUSES: InvoiceStatus[] = [
  InvoiceStatus.All,
  InvoiceStatus.Overdue,
  InvoiceStatus.Unpaid,
  InvoiceStatus.Paid,
]

type SummaryInfoBox = Pick<
  AmountInfoBoxProps<InvoiceStatus>,
  'active' | 'amount' | 'badgeAmount' | 'badgeLabel' | 'badgeVariant' | 'id'
>

export const InvoicesSummary = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { data, isLoading } = useInvoicesSummaryFiltered()
  const [{ status: currentStatus }, setFilters] = useInvoicesListFilters()

  const handleClick = useCallback(
    (id: InvoiceStatus) => {
      setFilters({ page: 1, status: id })
    },
    [setFilters],
  )

  const infoBoxes: SummaryInfoBox[] = useMemo(
    () =>
      SUMMARY_BOXES_STATUSES.map((status) => {
        const { amount: dataAmount, count: dataCount } = data?.[status] || {}
        const badgeAmount = dataCount
        const amount = dataAmount

        return {
          active: status === currentStatus,
          amount,
          badgeAmount,
          badgeLabel: t(invoiceStatusToTitleKey[status], { count: badgeAmount }),
          badgeVariant: invoiceStatusToBadgeVariant[status],
          id: status,
        }
      }),
    [currentStatus, data, t],
  )

  return (
    <Styled.InvoicesSummaryWrapper>
      <Styled.Title variant="h3">
        <Translate value="bills.summary.title" />
      </Styled.Title>
      <AmountInfoBoxesGroup itemsInRow={infoBoxes.length} loading={isLoading}>
        {infoBoxes.map(({ active, amount, badgeAmount, badgeLabel, badgeVariant, id }) => (
          <AmountInfoBox
            active={active}
            amount={amount}
            badgeAmount={badgeAmount}
            badgeLabel={badgeLabel}
            badgeVariant={badgeVariant}
            clickable
            currencyId={organization?.baseCurrencyId}
            id={id}
            key={id}
            onClick={handleClick}
          />
        ))}
      </AmountInfoBoxesGroup>
    </Styled.InvoicesSummaryWrapper>
  )
}
