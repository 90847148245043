import { Button, ButtonsGroup, Modal, ModalProps, SkeletonBox, Space, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useDebtCollectionTerms } from '../../hooks/useDebtCollectionTerms'
import { useEnableDebtCollection } from '../../hooks/useEnableDebtCollection'
import * as Styled from './styles'

type EnableDebtCollectionModalProps = ModalProps

export const EnableDebtCollectionModal = (modalProps: EnableDebtCollectionModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeEnableDebtCollectionModal } = useModal(modalProps.id)
  const { enableDebtCollection, isLoading: isLoadingEnablementData, isProcessing } = useEnableDebtCollection()
  const {
    terms,
    isFetched: areTermsFetched,
    isLoading: isLoadingTerms,
    isError: isTermsError,
  } = useDebtCollectionTerms()

  const isAcceptButtonDisabled = !areTermsFetched || isTermsError || isLoadingEnablementData

  const handleAcceptButtonClick = useCallback(() => {
    enableDebtCollection()
  }, [enableDebtCollection])

  const handleCancelButtonClick = useCallback(() => {
    closeEnableDebtCollectionModal()
  }, [closeEnableDebtCollectionModal])

  return (
    <Modal closable size="s" {...modalProps}>
      <Modal.Header title={t('integrations.card.debt_collection.modal_enable.title')} />
      <Space size="l" />
      <Modal.Body>
        {isLoadingTerms && <SkeletonBox fullWidth height={300} />}
        {areTermsFetched && !!terms && (
          // eslint-disable-next-line @typescript-eslint/naming-convention
          <Styled.TermsContentWrapper dangerouslySetInnerHTML={{ __html: terms }} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={handleCancelButtonClick}>
            {t('integrations.card.debt_collection.modal_enable.cancel_button')}
          </Button>
          <Button onClick={handleAcceptButtonClick} disabled={isAcceptButtonDisabled} loading={isProcessing}>
            {t('integrations.card.debt_collection.modal_enable.accept_button')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
