import { DropzonePremium } from '@components'
import { Button, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { ReactComponent as IconBillUploadComponent } from '../../../../assets/images/dashboard-empty-state/bill-upload.svg'
import { emptyViewIconStyle } from '../styles/emptyIconStyles'

const FILES_ASIDE_WIDTH = '20%'

export const UploadsWidget = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const FilesAside = styled.aside`
  display: flex;
  width: ${FILES_ASIDE_WIDTH};
  height: 100%;
  padding-top: ${Spacing.XL};
  margin-right: ${Spacing.XL};
  flex-direction: column;
  align-items: center;
`

export const FileButton = styled(Button)`
  margin-top: ${Spacing.S};
`

export const Footer = styled.footer`
  margin-left: ${FILES_ASIDE_WIDTH};
  margin-top: ${Spacing.S};
  margin-bottom: -${Spacing.S};
`

export const EmptyDropzone = styled(DropzonePremium)`
  width: 100%;
  height: 100%;
  margin-bottom: -${Spacing.XL};
`

export const IconBillUpload = styled(IconBillUploadComponent)`
  ${emptyViewIconStyle}
`
