import { Spacing } from '@design-system'

import styled from '@emotion/styled'
import SimpleBar from 'simplebar-react'

export const SettingsModuleHeaderWrapper = styled.header`
  width: 100%;
  padding-top: ${Spacing.XL};
  padding-bottom: ${Spacing.XL};
`

export const Header = styled.div`
  display: flex;
  align-items: center;
`

export const TitleWrapper = styled.div`
  display: flex;
  gap: ${Spacing.S};
  align-items: center;
`

export const HeaderTopActionsWrapper = styled.div`
  margin-top: -${Spacing.L};
  margin-bottom: ${Spacing.S};
`

export const SideActions = styled.aside`
  display: flex;
  padding-left: ${Spacing.L};
  flex-grow: 1;
  justify-content: flex-end;
`

export const SettingsModuleContent = styled(SimpleBar)`
  display: flex;
  overflow: auto;
`
