import { TimelineItemStatus } from '@components'

import { InvoiceSendingState } from '@views/invoices'

export const sendingStateToTimelineStatus: Record<InvoiceSendingState, TimelineItemStatus> = {
  created: 'neutral',
  deleted: 'neutral',
  failed: 'error',
  internalcancel: 'neutral',
  pending: 'neutral',
  received: 'neutral',
  sent: 'neutral',
  signedoff: 'neutral',
  voided: 'neutral',
}
