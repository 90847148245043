import { FormItemsGroup, Input, Space, Spacing, Switch } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../hooks'
import { useCreateOrEditUmbrellaRole } from '../../../../../../context/createOrEditUmbrellaRoleContext'
import { CreateOrEditRoleFormSchema } from '../../../../utils/formData'
import { OrganizationSelectionSection } from '../OrganizationSelectionSection'

export const ModalForm = (): ReactElement => {
  const { t } = useTranslation()
  const { selectedRole } = useCreateOrEditUmbrellaRole()
  const { FormItem } = useFormContext<CreateOrEditRoleFormSchema>()

  return (
    <>
      <FormItemsGroup rowSpacing={Spacing.M}>
        <FormItem
          label={t('umbrella_roles.edit_roles_modal.form.name')}
          name="name"
          render={({ field }) => <Input {...field} />}
        />
        <FormItem
          label={t('umbrella_roles.edit_roles_modal.form.description')}
          name="description"
          render={({ field }) => <Input {...field} />}
        />
        {!selectedRole?.grantAdmin && (
          <FormItem
            name="grantAll"
            render={({ field: { value, ...props } }) => (
              <Switch checked={value} {...props}>
                {t('umbrella_roles.edit_roles_modal.form.grant_all')}
              </Switch>
            )}
          />
        )}
      </FormItemsGroup>
      <Space />
      <OrganizationSelectionSection />
    </>
  )
}
