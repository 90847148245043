import { SkeletonBox } from '@design-system'

import React, { ReactElement } from 'react'

import { useOrganizationBrand } from '../../../../hooks'
import { DeleteTrialDataSection } from './elements/DeleteTrialDataSection'
import { OrganizationInfo } from './elements/OrganizationInfo'
import { OrganizationOwnersSection } from './elements/OrganizationInfo/elements/OrganizationOwnersSection'
import { useShouldDisplayDeleteTrialDataSection } from './hooks/useShouldDisplayDeleteTrialDataSection'
import { useShouldDisplayOrganizationOwnersSection } from './hooks/useShouldDisplayOrganizationOwnersSection'
import * as Styled from './styles'

export const OrganizationSettings = (): ReactElement => {
  const { shouldDisplaySection: shouldDisplayDeleteTrialDataSection, isLoading: isLoadingDeleteTrialDataSection } =
    useShouldDisplayDeleteTrialDataSection()

  const { shouldDisplaySection: shouldDisplayOwnersSection, isLoading: isLoadingOwnersSection } =
    useShouldDisplayOrganizationOwnersSection()

  const { isBrandBilly } = useOrganizationBrand()

  return (
    <>
      <OrganizationInfo />
      {isBrandBilly && (
        <>
          {isLoadingOwnersSection && <Styled.OwnersSectionSkeletonBox fullWidth height={234} />}
          {shouldDisplayOwnersSection && <OrganizationOwnersSection />}
          {isLoadingDeleteTrialDataSection && <SkeletonBox fullWidth height={520} />}
          {shouldDisplayDeleteTrialDataSection && <DeleteTrialDataSection />}
        </>
      )}
    </>
  )
}
