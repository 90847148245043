import { DateFormatter } from '@components'
import { Color, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { getPassedTimeText } from '../../../../../../utils/getPassedTimeText'
import { InvoiceStatus } from '../../../../enums/invoiceStatus'

interface InvoicePaymentPaneExtraProps {
  date: string | null
  status: InvoiceStatus
}

export const InvoicePaymentPaneExtra = ({ date, status }: InvoicePaymentPaneExtraProps): ReactElement | null => {
  const { t } = useTranslation()

  if (date) {
    if (status === InvoiceStatus.Paid || status === InvoiceStatus.Draft) {
      return (
        <Text colorVariant="secondary" variant="small">
          <DateFormatter value={date} />
        </Text>
      )
    }

    if (status === InvoiceStatus.Unpaid) {
      return (
        <>
          <Text colorVariant="secondary" variant="micro">
            {t('invoices.view.payment_pane.due')}
          </Text>
          <Text colorVariant="secondary" variant="small">
            <DateFormatter value={date} />
          </Text>
        </>
      )
    }

    if (status === InvoiceStatus.Overdue) {
      const passedTimeText = getPassedTimeText(date, t)

      return (
        <Text colorVariant="secondary" variant="small" color={Color.Red} truncate>
          {passedTimeText}
        </Text>
      )
    }
  }

  return null
}
