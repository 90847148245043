import cc from 'classcat'
import React from 'react'
import { Box, BoxProps, Flex, FlexProps } from 'rebass'

import { reactClass } from '../../../utils'

type StackDirection = 'horizontal' | 'vertical'

export type StackLayoutProps = FlexProps & {
  breakWidth?: number
  direction?: StackDirection
}

export type StackLayoutBoxProps = BoxProps

export const StackLayoutBox = ({ children, ...rest }: StackLayoutBoxProps) => (
  <Box width={1} {...rest}>
    {children}
  </Box>
)

export const StackLayout = ({
  breakWidth,
  children,
  className,
  direction = 'horizontal',
  ...rest
}: StackLayoutProps) => {
  const flexDirection = direction === 'horizontal' ? 'row' : 'column'
  const responsiveStyles = breakWidth
    ? {
        [`@media screen and (max-width: ${breakWidth}px)`]: {
          flexDirection: direction === 'horizontal' ? 'column' : 'row',
        },
      }
    : {}

  return (
    <Flex
      className={cc([className, reactClass('stack-layout')])}
      flexDirection={flexDirection}
      width={1}
      sx={responsiveStyles}
      {...rest}
    >
      {children}
    </Flex>
  )
}
