import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { BillEditable } from '../../elements/BillEditable'
import { BillUrlParams } from '../../types/billUrlParams'

interface BillEditProps {
  isNewCreditNote?: boolean
}

export const BillEdit = ({ isNewCreditNote }: BillEditProps): ReactElement => {
  const { attachmentId, attachmentIdBulk, billId, bulkBillId, billIdToDuplicate, billIdToCreateCreditNote, contactId } =
    useParams<BillUrlParams>()

  return (
    <BillEditable
      attachmentId={attachmentId || attachmentIdBulk}
      billId={billId || bulkBillId}
      billIdToDuplicate={billIdToDuplicate}
      billIdToCreateCreditNote={billIdToCreateCreditNote}
      contactId={contactId}
      isBulkEditMode={!!attachmentIdBulk || !!bulkBillId}
      isNewCreditNote={isNewCreditNote}
    />
  )
}
