/* eslint-disable @typescript-eslint/naming-convention */
interface SettingsFilters {
  coupon?: string
  duration?: string
  payment_cancelled?: string
  payment_failed?: string
  payment_successful?: string
  period?: string
  plan?: string
  promocode?: string
  openCreateVatRateModal?: boolean
  userflow?: string
  utm_campaign?: string
  utm_medium?: string
  utm_source?: string
}

export const defaultQuery: SettingsFilters = {
  coupon: undefined,
  duration: undefined,
  payment_cancelled: undefined,
  payment_failed: undefined,
  payment_successful: undefined,
  period: undefined,
  plan: undefined,
  promocode: undefined,
  openCreateVatRateModal: undefined,
  userflow: undefined,
  utm_campaign: undefined,
  utm_medium: undefined,
  utm_source: undefined,
  /* eslint-enable @typescript-eslint/naming-convention */
}
