import React, { Children, isValidElement, ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

export interface ModuleSectionsGroupProps {
  children: ReactNode
}

export const ModuleSectionsGroup = ({ children }: ModuleSectionsGroupProps): ReactElement => (
  <Styled.SectionsGroupList>
    {Children.map(
      children,
      (child) => isValidElement(child) && <Styled.SectionsGroupItem>{child}</Styled.SectionsGroupItem>,
    )}
  </Styled.SectionsGroupList>
)
