import { z, ZodType } from 'zod'

import { INVOICE_FOOTER_CHARACTER_LIMIT } from '../contants/invoiceFooterCharacterLimit'
import { INVOICE_MESSAGE_CHARACTER_LIMIT } from '../contants/invoiceMessageCharacterLimit'
import { ProductTypes } from '../enums/invoiceProductTypes'
import { InvoiceUnits } from '../enums/invoiceUnits'
import { InvoiceForm } from '../types/invoiceForm'
import { InvoiceLine } from '../types/invoiceLine'

// initial version, to be updated
const getInvoiceLineSchema = (): ZodType<InvoiceLine> => {
  return z.object({
    productName: z.string(),
    productType: z.nativeEnum(ProductTypes),
    date: z.date(),
    quantity: z.number(),
    unit: z.nativeEnum(InvoiceUnits),
    totalExcludingVat: z.number(),
    vat: z.number(),
    totalIncludingVat: z.number(),
  })
}

// initial version, to be updated
export const getInvoiceEditorFormSchema = (): ZodType<InvoiceForm> => {
  return z.object({
    invoiceNumber: z.string(),
    issueDate: z.date(),
    dueDate: z.date(),
    message: z.string().max(INVOICE_MESSAGE_CHARACTER_LIMIT),
    invoiceLines: z.array(getInvoiceLineSchema()),
    footerNote: z.string().max(INVOICE_FOOTER_CHARACTER_LIMIT),
  })
}
