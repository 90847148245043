import { TFunction } from 'i18next'

interface Translation {
  header?: string
  body?: string
}

export const getTranslations = (isVATEnabled: boolean, t: TFunction): Translation => {
  if (isVATEnabled) {
    return {
      header: t('settings.organization.accounting.vat_confirmation_modal.disable.title'),
      body: t('settings.organization.accounting.vat_confirmation_modal.disable.text'),
    }
  }

  return {
    header: t('settings.organization.accounting.vat_confirmation_modal.enable.title'),
    body: t('settings.organization.accounting.vat_confirmation_modal.enable.text'),
  }
}
