import { Company } from '@modules-deprecated/app/companies/types'

export const getDisplayValue = (item?: Partial<Company>, customDisplayValueFn?: (item: Partial<Company>) => string) => {
  if (!item) {
    return null
  }

  if (customDisplayValueFn) {
    return customDisplayValueFn(item)
  }

  if (item.name) {
    return item.name
  }
}
