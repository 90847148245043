import { ContactBalance, ContactBalancePagination, useContactBalanceListFilters } from '@features/contactBalance'

import { useFetchCreditors } from './useFetchCreditors'

interface UseCreditorsResponse {
  creditors: ContactBalance[]
  isLoading?: boolean
  pagination?: ContactBalancePagination
}

export const useCreditors = (): UseCreditorsResponse => {
  const [{ acceptLanguage, date, searchQuery, sortDirection, sortProperty }] = useContactBalanceListFilters()
  // we don't pass "page" and "pageSize" to avoid refetching data because of all list is already fetched
  // BE doesn't support this properties
  const { data, isLoading, pagination } = useFetchCreditors({
    acceptLanguage,
    date,
    searchQuery,
    sortDirection,
    sortProperty,
  })

  return {
    creditors: data,
    isLoading,
    pagination,
  }
}
