import { Button } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'

export const IntegrationsSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()

  const handleCreateAPIClick = useCallback(() => {
    navigate(EmberRoute.SettingsAccessTokens)
  }, [navigate])

  return (
    <Button icon="bubbleChart" onClick={handleCreateAPIClick} variant="primary">
      {t('integrations.side_actions.create_api_key')}
    </Button>
  )
}
