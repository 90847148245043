const MODULES_NO_REDUX = [
  'accountEditor',
  'annualReport',
  'bills',
  'billyWelcomeModalModule',
  'chartOfAccounts',
  'contactBalance',
  'creditors',
  'dashboard',
  'debtors',
  'emptyView',
  'financing',
  'findAccountant',
  'globalSearch',
  'integrations',
  'invoices',
  'locked',
  'maintenance',
  'menu',
  'modal',
  'organizationFeaturesSettings',
  'organizationInvitation',
  'organizationSettingsUsers',
  'organizationSubscription',
  'organizationSubscriptionModule',
  'quotes',
  'receipts',
  'salaryIntegration',
  'salesTax',
  'settings',
  'signupGuide',
  'skatBox',
  'thingsToDo',
  'transactions',
  'umbrellaInvitation',
  'umbrellaOrganizations',
  'umbrellaProfile',
  'umbrellaTransferOwnership',
]

export const mapModules = (m: any) =>
  m.keys().reduce((res: object, filename: string) => {
    const name = filename.match(/\.\/([^/]+)/)
    const moduleName = (name && name[1]) || filename

    if (MODULES_NO_REDUX.indexOf(moduleName) === -1) {
      res[moduleName] = m(filename)
    }

    return res
  }, {})
