import { ChartOfAccountId } from '@modules-deprecated/app/organization/enums/chartOfAccountId'

const ALLOWED_COA: ChartOfAccountId[] = [
  ChartOfAccountId.Billy,
  ChartOfAccountId.BillyWorld,
  ChartOfAccountId.France,
  ChartOfAccountId.MenetoEmv,
  ChartOfAccountId.MenetoSelskab,
]

export const isBookkeepingHeroAllowed = (chartOfAccountId: ChartOfAccountId): boolean =>
  ALLOWED_COA.includes(chartOfAccountId)
