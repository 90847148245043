import isEqual from 'lodash/isEqual'
import { useState } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchInvoiceLogs, FetchInvoiceLogsOptions } from '../query-api'

const REFETCH_INTERVAL = 2500
const MAX_RETRY_COUNT = 20

export const useEInvoiceLogs = (options: FetchInvoiceLogsOptions, refetchInBackgound?: boolean) => {
  const [retryCounter, setRetryCounter] = useState(1)

  const { data, ...rest } = useQuery([QueryKeys.InvoiceLogs, options], () => fetchInvoiceLogs(options), {
    enabled: !!options.organizationId,
    refetchInterval: refetchInBackgound && retryCounter < MAX_RETRY_COUNT ? REFETCH_INTERVAL * retryCounter : false,
    notifyOnChangeProps: ['isLoading', 'isSuccess', 'isError', 'data'],
    isDataEqual: (oldData, newData) => {
      const isDataEqual = isEqual(oldData?.invoiceLogs, newData?.invoiceLogs)

      if (isDataEqual) {
        setRetryCounter((prevValue) => prevValue + 1)
      } else {
        setRetryCounter(1)
      }

      return isDataEqual
    },
  })

  return { eInvoiceLogs: data?.invoiceLogs || [], ...rest }
}
