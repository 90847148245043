import { FileButton, FileButtonProps, useModal } from '@design-system'

import React, { MouseEvent, ReactElement, useCallback } from 'react'

import { ModalId } from '../../../enums/modalId'
import { useComponentId } from '../../../hooks/useComponentId'
import { useIsBasicPlan } from '../../../hooks/useIsBasicPlan'
import { WithTrackingContext } from '../../../types/withTrackingContext'
import { UpgradeSubscriptionModal } from '../../Modal/upgradeSubscriptionModal'

export interface FileButtonPremiumProps extends Omit<FileButtonProps, 'isBlocked'>, WithTrackingContext {
  isBlocked?: boolean
}

export const FileButtonPremium = ({
  disabled,
  isBlocked: blocked,
  onClick,
  trackingContext,
  ...rest
}: FileButtonPremiumProps): ReactElement => {
  // we need unique id just in case couple of UpgradeSubscriptionModal will be rendered in the same view
  const upgradeModalId = useComponentId(ModalId.UpgradeSubscriptionModal)
  const { open: openUpgradeModal } = useModal(upgradeModalId)

  const { isBasicPlan, isLoading } = useIsBasicPlan()
  const isBlocked = blocked || isBasicPlan

  const handleFileButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (isBlocked) {
        openUpgradeModal()
      }

      onClick?.(event)
    },
    [isBlocked, onClick, openUpgradeModal],
  )

  return (
    <>
      <FileButton {...rest} isBlocked={isBlocked} disabled={isLoading || disabled} onClick={handleFileButtonClick} />
      <UpgradeSubscriptionModal id={upgradeModalId} trackingContext={trackingContext} />
    </>
  )
}
