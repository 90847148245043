import { amountToDisplayValue, Attachment, Color, FilesPreview, SkeletonBox, Text } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as FileChooserEmptyHover } from '../../../../assets/images/modal-empty-state/en/file-chooser-hover.svg'
import { BohrScanResult } from '../../../../types/bohrScanResult'
import { getImageClassName } from '../../../../utils/getClassName'
import * as Styled from './styles'

interface ModalFilePreviewProps {
  previewAttachment?: Attachment
}

const DATA_UNAVAILABLE_PLACEHOLDER = '--'
const DEFAULT_CURRENCY_CODE = 'DKK'

export const ModalFilePreview = ({ previewAttachment }: ModalFilePreviewProps): ReactElement => {
  const { t } = useTranslation()
  const {
    document_currency: currencyIdLegacy,
    document_issue_date: issueDateLegacy,
    document_total_incl_vat: totalLegacy,
    entities,
    supplier: supplierLegacy,
  }: BohrScanResult = useMemo(() => JSON.parse(previewAttachment?.file?.dataJson || '{}'), [previewAttachment])
  const {
    currency: currencyData,
    issue_date: issueDateData,
    total_incl_vat: totalInclVatData,
    vendor: vendorData,
  } = entities || {}
  const currencyId = currencyData?.content || currencyIdLegacy
  const issueDate = issueDateData?.content || issueDateLegacy
  const total = totalInclVatData?.content || totalLegacy
  const supplier = vendorData?.content || supplierLegacy

  return (
    <Styled.PreviewWrapper>
      {previewAttachment ? (
        <>
          <Styled.FileInfo>
            <Styled.FileLabel>
              <Text color={Color.Gray200} title={supplier?.name || ''} truncate variant="large">
                {supplier?.name || DATA_UNAVAILABLE_PLACEHOLDER}
              </Text>
              <Text colorVariant="secondary" variant="micro">
                {issueDate || DATA_UNAVAILABLE_PLACEHOLDER}
              </Text>
            </Styled.FileLabel>
            <Styled.AmountLabel alignment="right" inline variant="h3">
              {total
                ? amountToDisplayValue(total, false) + ' ' + (currencyId || DEFAULT_CURRENCY_CODE)
                : DATA_UNAVAILABLE_PLACEHOLDER}
            </Styled.AmountLabel>
          </Styled.FileInfo>
          <Styled.FileImageWrapper>
            <FilesPreview
              className={getImageClassName()}
              files={[{ src: previewAttachment.file.downloadUrl }]}
              fitToHeight
              fitToWidth
              loading={<SkeletonBox fullHeight fullWidth />}
              withNavigation={false}
            />
          </Styled.FileImageWrapper>
        </>
      ) : (
        <Styled.HoverHelperImage className={getImageClassName()}>
          <FileChooserEmptyHover />
          <Styled.Footer>
            <Text variant="h3" alignment="center">
              {t('ds.modal_files_chooser.hover.title')}
            </Text>
            <Text variant="large" alignment="center">
              {t('ds.modal_files_chooser.hover.description')}
            </Text>
          </Styled.Footer>
        </Styled.HoverHelperImage>
      )}
    </Styled.PreviewWrapper>
  )
}
