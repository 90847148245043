import { EmptyProps, EmptyView } from '@components'

import { ReactElement, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { Scope } from '../../../../enums/scope'
import { isAuthorized } from '../../../../utils/isAuthorized'

interface ContactBalanceEmptyProps extends EmptyProps {
  path: string
}

export const ContactBalanceEmpty = ({
  animation,
  buttonText,
  description,
  title,
  path,
}: ContactBalanceEmptyProps): ReactElement => {
  const history = useHistory()
  const shouldSeeCreateButton = isAuthorized(Scope.InvoiceWrite)

  const handleEmptyViewClick = useCallback(() => {
    if (path) {
      history.push(path)
    }
  }, [history, path])

  return (
    <EmptyView
      animation={animation}
      onClick={handleEmptyViewClick}
      tButton={buttonText}
      tDescription={description}
      tTitle={title}
      showCTA={shouldSeeCreateButton}
    />
  )
}
