import { DateFormatter } from '@components'
import { amountToDisplayValue, Color, Space, Text } from '@design-system'

import sortBy from 'lodash/sortBy'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { BillBalanceModifier } from '../../../../types/BillBalanceModifier'
import { billBalanceModifierReferenceToTKey, getBalanceModifierReference } from '../../../BillPaymentPane'
import * as Styled from './styles'

interface BillVoidConfirmationMessageProps {
  balanceModifiers?: BillBalanceModifier[]
}

export const BillVoidConfirmationMessage = ({ balanceModifiers }: BillVoidConfirmationMessageProps): ReactElement => {
  const { t } = useTranslation()

  if (!balanceModifiers?.length) {
    return <Text inherit>{t('bill.void_modal.main_message')}</Text>
  }

  const balanceModifiersSorted = sortBy(balanceModifiers, 'entryDate').reverse()

  return (
    <>
      <Text inherit>{t('bill.void_modal.main_message')}</Text>
      <Space size="l" />
      <Text inherit>{t('bill.void_modal.voided_items')}</Text>
      <Space size="xxs" />
      <Styled.PaymentsList>
        {balanceModifiersSorted.map((balanceModifier) => {
          const { id, amount, entryDate, modifierReference } = balanceModifier
          const modifierData = getBalanceModifierReference(modifierReference)
          const { modifierType } = modifierData || {}
          const paymentType = modifierType && t(billBalanceModifierReferenceToTKey[modifierType])

          return (
            <Styled.PaymentItem key={id}>
              <Text variant="micro" colorVariant="success" inline inherit>
                {t('bill.void_modal.payment')}
              </Text>
              {entryDate && (
                <Text variant="small" inline inherit>
                  <DateFormatter value={entryDate} />
                </Text>
              )}
              {paymentType && (
                <Text variant="small" inline inherit>
                  {paymentType}
                </Text>
              )}
              {amount !== null && (
                <Text color={Color.DeepSeaGreen} inline inherit alignment="right">
                  {amountToDisplayValue(Math.abs(amount))}
                </Text>
              )}
            </Styled.PaymentItem>
          )
        })}
      </Styled.PaymentsList>
    </>
  )
}
