import { PurchaseCard, PurchaseCardHighlight } from '@components'

import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useAnnualReportPurchasing } from '../../contexts/annualReportPurchasingContext'
import { AnnualReportType } from '../../enums/annualReportType'
import { getARTypeFeatures } from '../../utils/getARTypeFeatures'

export const DoItYourselfCard = (): ReactElement => {
  const { t } = useTranslation()
  const [isProcessing, setIsProcessing] = useState(false)
  const {
    diyPrice,
    diyPriceOneOff,
    handlePurchaseDIY,
    hasSubscriptionActive,
    isDIYAlreadyPurchased,
    isDIYPurchaseDisabled,
    isDIYSoldOut,
    year,
  } = useAnnualReportPurchasing()

  const price = hasSubscriptionActive ? diyPrice : diyPriceOneOff
  const doItYourselfHighlight = useMemo((): PurchaseCardHighlight | undefined => {
    if (isDIYSoldOut) {
      return { text: t('annual_report.package.we_do_it.sold_out', { year }), variant: 'danger' }
    } else if (isDIYAlreadyPurchased) {
      return { text: t('annual_report.package.we_do_it.already_purchased'), variant: 'danger' }
    } else {
      return undefined
    }
  }, [isDIYAlreadyPurchased, isDIYSoldOut, t, year])

  const handleBuyButtonClick = useCallback(() => {
    setIsProcessing(true)
    handlePurchaseDIY()
  }, [handlePurchaseDIY])

  return (
    <PurchaseCard highlight={doItYourselfHighlight}>
      <PurchaseCard.Header
        description={
          <Trans i18nKey="annual_report.package.do_it_yourself.description">
            You will do it with our quick and easy
            <br />
            checklist
          </Trans>
        }
        title={t('annual_report.package.do_it_yourself.title')}
      />
      <PurchaseCard.Button onClick={handleBuyButtonClick} loading={isProcessing} disabled={isDIYPurchaseDisabled}>
        {t('annual_report.package.buy')}
      </PurchaseCard.Button>
      <PurchaseCard.PriceSection price={price} priceSuffix={t('annual_report.package.price_suffix')} />
      <PurchaseCard.Features
        header={t('annual_report.package.included')}
        items={getARTypeFeatures(AnnualReportType.DIY)}
      />
    </PurchaseCard>
  )
}
