import React, { useCallback } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../enums/queryKeys'
import { fetchSubscriptionInvoicePdf, fetchSubscriptionInvoices } from '../../query-api/subscription-invoices-query'
import { SubscriptionInvoicesList } from '../SubscriptionInvoicesList'

export const SubscriptionInvoices = () => {
  const { organization } = useUserOrganization()
  const { data: invoicesData, isLoading: isLoadingInvoices } = useQuery(
    [QueryKeys.SubscriptionInvoices, organization?.id],
    () => fetchSubscriptionInvoices(organization?.id as string),
    {
      enabled: !!organization?.id,
    },
  )

  const handleFetchInvoicePdf = useCallback(
    (invoiceId: string) => () => {
      return fetchSubscriptionInvoicePdf(organization?.id || '', invoiceId)
    },
    [organization?.id],
  )

  return (
    <SubscriptionInvoicesList
      invoices={invoicesData}
      isLoading={isLoadingInvoices}
      fetchInvoicePdf={handleFetchInvoicePdf}
    />
  )
}
