import { Text } from '@design-system'

import { ReactElement, Ref } from 'react'
import { useTranslation } from 'react-i18next'
import { connectHitInsights, connectHits } from 'react-instantsearch-dom'

import { insightsClient } from '../../../../../../../../utils/algoliaSearchClient'
import { HitItem } from '../../types/hitItem'
import { AlgoliaHitItem } from '../AlgoliaHitItem'
import * as Styled from './styles'

interface AlgoliaHitsProps {
  hits: HitItem[]
  forwardedRef: Ref<HTMLDivElement>
  focusedHitId?: string
}

const Hit = connectHitInsights(insightsClient)(AlgoliaHitItem)

const HitsComponent = ({ forwardedRef, focusedHitId, hits }: AlgoliaHitsProps): ReactElement => {
  const { t } = useTranslation()

  const isNoResults = !hits?.length

  return (
    <Styled.HitsWrapper ref={forwardedRef}>
      {isNoResults ? (
        <Styled.NoResultsWrapper>
          <Text weight="medium">{t('ds.nav_list.not_found')}</Text>
        </Styled.NoResultsWrapper>
      ) : (
        <Styled.HitsList>
          {hits.map((hit) => (
            <Styled.HitListItem key={hit.objectID} id={hit.objectID} isFocused={hit.objectID === focusedHitId}>
              <Hit hit={hit} />
            </Styled.HitListItem>
          ))}
        </Styled.HitsList>
      )}
    </Styled.HitsWrapper>
  )
}

export const AlgoliaHits = connectHits<AlgoliaHitsProps, HitItem>(HitsComponent)
