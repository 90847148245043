import { getDefaultTransition } from '@design-system'

import { css } from '@emotion/core'

import { COLOR as SCROLLBAR_COLOR, COLOR_HOVER as SCROLLBAR_COLOR_HOVER, SIZE as SCROLLBAR_SIZE } from './scrollbars'

export const librariesOverrides = css`
  .simplebar-scroll-content {
    box-sizing: content-box;
  }

  .simplebar-track {
    &.simplebar-vertical {
      &,
      .simplebar-scrollbar:before {
        width: ${SCROLLBAR_SIZE};
      }
    }
    &.simplebar-horizontal {
      &,
      .simplebar-scrollbar:before {
        height: ${SCROLLBAR_SIZE};
      }
    }

    .simplebar-scrollbar {
      &:before {
        left: 0;
        right: 0;
        border-radius: ${SCROLLBAR_SIZE};
        background: transparent;
        ${getDefaultTransition('background', 'opacity')};
      }

      &.simplebar-visible {
        &:before {
          opacity: 1;
          background: ${SCROLLBAR_COLOR};
        }

        &.simplebar-hover {
          &:before {
            opacity: 1;
            background: ${SCROLLBAR_COLOR_HOVER};
          }
        }
      }
    }
  }
`
