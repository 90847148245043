import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useForm } from '../../../../../hooks'
import { APIError } from '../../../../../utils'
import { EInvoiceRegistrationResponse } from '../enums/eInvoiceRegistrationResponse'
import { RegistrationType } from '../enums/registrationType'
import { EnableEInvoicingForm, getDefaultValues, getValidationSchema } from '../utils/formData'
import { useEnableEInvoicing } from './useEnableEInvoicing'

export const useEnableEInvoicingForm = () => {
  const registrationTypeRef = useRef<RegistrationType | null>(null)
  const { t } = useTranslation()
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const {
    enable: enableEInvoicing,
    isSuccess: isEnableEInvoicingSuccess,
    isError: isEnableEInvoicingError,
    isProcessing: isProcessingEnableEInvoicing,
    reset: resetEnableEInvoicing,
  } = useEnableEInvoicing({
    onError: (error: APIError) => {
      if (error.statusCode === EInvoiceRegistrationResponse.registeredAtNemhandel) {
        registrationTypeRef.current = RegistrationType.Nemhandel
      } else if (error.statusCode === EInvoiceRegistrationResponse.identifierExists) {
        registrationTypeRef.current = RegistrationType.Sproom
      }
    },
  })
  const {
    Form,
    FormItem,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    defaultValues: useMemo(() => getDefaultValues(organization), [organization]),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  useEffect(() => {
    if (!isOrganizationLoading) {
      resetForm(getDefaultValues(organization))
    }
  }, [resetForm, isOrganizationLoading, organization])

  const handleSubmitForm = useCallback(
    (values: EnableEInvoicingForm) => {
      const { cvr } = values
      enableEInvoicing({
        cvr,
        organizationId: organization?.id,
        organizationName: organization?.name,
      })
    },
    [organization, enableEInvoicing],
  )

  const reset = useCallback(() => {
    resetEnableEInvoicing()
    resetForm(getDefaultValues(organization))
  }, [resetEnableEInvoicing, resetForm, organization])

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return {
    Form,
    FormItem,
    isCVRInputDisabled: !!organization?.registrationNo,
    isEnableEInvoicingError,
    isEnableEInvoicingSuccess,
    isOrganizationLoading,
    isProcessingEnableEInvoicing,
    registrationType: registrationTypeRef.current,
    reset,
    submitForm,
  }
}
