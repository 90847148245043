import { AccountSelect } from '@components'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Account, AccountNature } from '@modules-deprecated/app/accounts/types'

import { BillFormFieldsChangeSource } from '../../../../../../enums/billFormFieldsChangeSource'
import { useFormContext, useSegment } from '../../../../../../hooks'
import { useBillForm } from '../../contexts/billFormContext'
import { useExpenseCategoryAutoComplete } from '../../hooks/useExpenseCategoryAutoComplete'
import { BillFormSchema } from '../../utils/formData'

interface BillLineExpenseCategoryProps {
  lineIndex: number
}

export const BillLineExpenseCategory = ({ lineIndex }: BillLineExpenseCategoryProps): ReactElement => {
  const { t } = useTranslation()
  const { FormItem, setValue, getValues } = useFormContext<BillFormSchema>()
  const { autoCompleteWithExpenseCategoryData } = useExpenseCategoryAutoComplete()
  const { track } = useSegment()
  const { billFormView, formFieldsChangeSource, setFormFieldsChangeSource } = useBillForm()

  const expenseCategoryProperty = `billLines.${lineIndex}.expenseCategory` as const

  const handleAccountSelect = useCallback(
    // both adding and removing expense account cause the form to change autocompleted values, to remove or change it accordingly
    (account: Account | undefined) => {
      const { autoCompletedFields } = getValues()
      setValue('autoCompletedFields', { ...autoCompletedFields, [expenseCategoryProperty]: false })
      autoCompleteWithExpenseCategoryData(account, lineIndex, BillFormFieldsChangeSource.Manually)

      if (account?.id) {
        track('xxx - expense - create expense - expense account selected', {
          billPage: billFormView,
          context: BillFormFieldsChangeSource.Manually,
        })

        setFormFieldsChangeSource((previousSourceMap) => {
          return { ...previousSourceMap, expenseCategory: BillFormFieldsChangeSource.Manually }
        })
      } else {
        track('xxx - expense - create expense - expense account removed', {
          billPage: billFormView,
          context: formFieldsChangeSource.expenseCategory
            ? formFieldsChangeSource.expenseCategory
            : formFieldsChangeSource.main,
        })
        setFormFieldsChangeSource((previousSourceMap) => {
          return { ...previousSourceMap, expenseCategory: undefined }
        })
      }
    },
    [
      autoCompleteWithExpenseCategoryData,
      billFormView,
      expenseCategoryProperty,
      formFieldsChangeSource,
      getValues,
      lineIndex,
      setFormFieldsChangeSource,
      setValue,
      track,
    ],
  )

  return (
    <FormItem
      name={expenseCategoryProperty}
      label={t('bill.edit.form.expense_category')}
      render={({ field: { value, onChange, ...props } }) => (
        <AccountSelect
          {...props}
          allowClear
          dropdownSize="fitTrigger"
          natures={[AccountNature.Expense, AccountNature.Asset]}
          onSelect={(id, value) => {
            onChange(value)
            handleAccountSelect(value)
          }}
          selectedId={value?.id}
        />
      )}
    />
  )
}
