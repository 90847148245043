import { Region } from '../types/region'

const EU_COUNTRIES: Record<string, string> = {
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GR: 'Greece',
  HU: 'Hungary',
  IE: 'Ireland',
  IT: 'Italy',
  LV: 'Latvia',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
}

const isCountryEU = (countryCode: string) => Object.keys(EU_COUNTRIES).includes(countryCode)

export const getRegion = (countryCode: string): Region => {
  if (countryCode === 'DK') {
    return 'DK'
  }

  if (isCountryEU(countryCode)) {
    return 'EU'
  }

  return 'Other'
}
