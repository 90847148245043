import { Link, SkeletonText, Space, Text } from '@design-system'

import React, { ReactElement, ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { useEmberRouter } from '../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../enums/emberRoute'
import * as Styled from './styles'

interface ProtectedInfoProps {
  ctaLabel?: ReactNode
  warningLabel?: ReactNode
}

export const ProtectedInfo = ({ ctaLabel, warningLabel }: ProtectedInfoProps): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const { user, isLoading: isUserLoading } = useCurrentUser()

  const isLoading = isOrganizationLoading || isUserLoading
  const isOwner = organization?.ownerUserId === user?.id

  const handlePremiumLinkClick = useCallback(() => {
    navigate(EmberRoute.SettingsSubscriptionsPlansSelection)
  }, [navigate])

  return (
    <>
      <Text alignment="center" colorVariant="secondary">
        {warningLabel || t('premium_feature.warning')}
      </Text>
      {isLoading && (
        <Styled.SkeletonTextWrapper>
          <SkeletonText width={200} />
        </Styled.SkeletonTextWrapper>
      )}
      {!isLoading && (
        <>
          {isOwner ? (
            <Text alignment="center" weight="medium">
              <Link withAnimation onClick={handlePremiumLinkClick}>
                {ctaLabel || t('premium_feature.wrong_subscription')}
              </Link>
            </Text>
          ) : (
            <>
              <Space size="s" />
              <Text alignment="center" colorVariant="secondary">
                {t('premium_feature.warning_wrong_user')}
              </Text>
            </>
          )}
        </>
      )}
    </>
  )
}
