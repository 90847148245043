import styled from '@emotion/styled'

import { StatusFormatterVariant } from './StatusFormatter'
import { getVariantBackground } from './utils/getVariantBackground'

const STATUS_SIZE = 7

interface StatusFormatterWrapperProps {
  variant: StatusFormatterVariant | [StatusFormatterVariant, StatusFormatterVariant]
}

export const StatusFormatterWrapper = styled.div<StatusFormatterWrapperProps>`
  width: ${STATUS_SIZE}px;
  height: ${STATUS_SIZE}px;
  margin: 0 auto;
  border-radius: 50%;
  background: ${({ variant }) => getVariantBackground(variant)};
`
