import { FontWeight } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../types/themable'

const ICON_ALIGNMENT_OFFSET = 3

interface InfoPanelContainerProps {
  color: string
  backgroundColor: string
}

export const InfoPanelContainer = styled.div<Themable & InfoPanelContainerProps>`
  display: flex;
  width: 100%;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 14px 14px 12px;
  border-radius: ${({ theme }) => theme.general.borderRadius};
`

export const InfoPanelTextContainer = styled.div<Themable>`
  display: flex;
  flex-direction: column;
  margin-left: 9px;
`

export const InfoPanelTitle = styled.h4<Themable>`
  margin: -${ICON_ALIGNMENT_OFFSET}px 0 0 0;
  font-size: ${({ theme }) => theme.text.body1.fontSize}px;
  line-height: ${({ theme }) => theme.text.body1.lineHeight};
  font-weight: ${FontWeight.Medium};
`

export const InfoPanelText = styled.p<Themable>`
  margin: 0;
  font-size: ${({ theme }) => theme.text.body2.fontSize}px;
  line-height: ${({ theme }) => theme.text.body2.lineHeight};
`

export const IconWrapper = styled.div`
  margin-top: -${ICON_ALIGNMENT_OFFSET}px;
`
