import { notify } from '@design-system'

import { TFunction } from 'i18next'

import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { APIError } from '../../../../../utils'
import { SaveAccountPayload } from '../query-api'

export const submitFailureCallback = (
  error: APIError,
  t: TFunction,
  callbackFn?: (attributeName?: keyof SaveAccountPayload, errorMessage?: string) => void,
) => {
  const validationErrors = error?.body?.validationErrors

  if (validationErrors) {
    const attributes = validationErrors?.account?.attributes

    if (!attributes) {
      notify({ id: NotificationKeys.FailureSubmit, message: t('error_saving'), variant: 'error' })
      return
    }

    let messages = ''
    for (const field in attributes) {
      messages = `${attributes[field]} ${messages}`
      callbackFn?.(field as keyof SaveAccountPayload, attributes[field])
    }
    notify({ id: NotificationKeys.FailureSubmit, message: messages, variant: 'error' })
    return
  }
  notify({ id: NotificationKeys.FailureSubmit, message: error.body.errorMessage, variant: 'error' })
}
