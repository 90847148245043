import numeral from 'numeral'

import { amountToNumber } from './amountToNumber'

export const amountToDisplayValue = (amount: string | number, shortFormat?: boolean, currencyId?: string) => {
  const schema = shortFormat ? '0,0' : '0,0.00'
  const amountNumber = amountToNumber(amount, true)
  const value = numeral(amountNumber).format(schema)
  const currency = currencyId ? ` ${currencyId}` : ''

  return `${value}${currency}`
}
