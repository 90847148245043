import { ModalProps, notify, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { UmbrellaOrganization } from '@modules-deprecated/app/organizations/types'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { TransferOrganizationOwnershipContextProvider } from './contexts/transferOrganizationOwnershipContext'
import { OrganizationOwnershipModalContent } from './elements/OrganizationOwnershipModalContent'

interface TransferOrganizationOwnershipModalProps extends ModalProps {
  umbrellaOrganization?: UmbrellaOrganization
}

export const TransferOrganizationOwnershipModal = ({
  umbrellaOrganization,
  ...modalProps
}: TransferOrganizationOwnershipModalProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { navigate } = useEmberRouter()
  const { close } = useModal(modalProps.id)

  const onTransferSuccess = useCallback(() => {
    close()
    if (!umbrellaOrganization) {
      navigate(EmberRoute.Dashboard)
      notify({
        id: NotificationKeys.TransferOwnership,
        message: t('transfer_ownership_modal.success.organization'),
        variant: 'success',
      })
    }
  }, [t, navigate, umbrellaOrganization, close])

  return (
    <TransferOrganizationOwnershipContextProvider
      onTransferSuccess={onTransferSuccess}
      organization={organization}
      umbrellaOrganization={umbrellaOrganization}
    >
      <OrganizationOwnershipModalContent {...modalProps} />
    </TransferOrganizationOwnershipContextProvider>
  )
}
