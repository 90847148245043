import { ButtonPremium } from '@components'
import { Button, Link, SkeletonButton, Switch, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { TrackingContext } from '../../../../enums/trackingContext'
import { getImageClassName } from '../../../../utils/getClassName'
import { IntegrationItem } from '../../types/integrationItem'
import * as Styled from './styles'

interface IntegrationCardProps {
  integrationItem: IntegrationItem
}

export const IntegrationCard = ({
  integrationItem: {
    buttonHandler,
    buttonText,
    description,
    imageNode,
    isButtonDisabled,
    isPremium,
    isStatusLoading,
    isSwitchChecked,
    isSwitchLoading,
    name,
    readMoreLink,
    statusLabelNode,
    switchHandler,
    switchText,
  },
}: IntegrationCardProps): ReactElement => {
  const { t } = useTranslation()

  const shouldDisplayRegularButton = !isStatusLoading && !!buttonText && !isPremium
  const shouldDisplayButtonPremium = !isStatusLoading && !!buttonText && isPremium

  return (
    <Styled.IntegrationCardWrapper>
      {!!statusLabelNode && <Styled.CardStatusLabelWrapper>{statusLabelNode}</Styled.CardStatusLabelWrapper>}
      <Styled.CardContentWrapper>
        <Styled.CardImageWrapper className={getImageClassName()}>{imageNode}</Styled.CardImageWrapper>
        <Text variant="h3">{name}</Text>
        <Styled.CardDescriptionWrapper>
          <Text colorVariant="secondary">{description}</Text>
        </Styled.CardDescriptionWrapper>
        {!!readMoreLink && (
          <Link href={readMoreLink} target="_blank" withAnimation>
            {t('integrations.card.read_more')}
          </Link>
        )}
      </Styled.CardContentWrapper>
      <Styled.CardActionsWrapper>
        {!!switchText && (
          <Styled.CardSwitchWrapper>
            <Text weight="medium">{switchText}</Text>
            <Switch checked={isSwitchChecked} onChange={switchHandler} disabled={isSwitchLoading} />
          </Styled.CardSwitchWrapper>
        )}
        {isStatusLoading && <SkeletonButton fullWidth />}
        {shouldDisplayRegularButton && (
          <Button disabled={isButtonDisabled} variant="secondary" fullWidth onClick={buttonHandler}>
            {buttonText}
          </Button>
        )}
        {shouldDisplayButtonPremium && (
          <ButtonPremium
            disabled={isButtonDisabled}
            variant="secondary"
            fullWidth
            onClick={buttonHandler}
            trackingContext={TrackingContext.Integrations}
          >
            {buttonText}
          </ButtonPremium>
        )}
      </Styled.CardActionsWrapper>
    </Styled.IntegrationCardWrapper>
  )
}
