import { AttachmentFile, getCurrentLocale, useModal } from '@design-system'

import isEmpty from 'lodash/isEmpty'
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useAsync, usePrevious } from 'react-use'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useTaxRates } from '@modules-deprecated/app/taxrates'
import { useFetchContact } from '@views/contacts/hooks/useFetchContact'
import { useFetchReceipt } from '@views/receipts/hooks/useFetchReceipt'

import { useDirtyRoute } from '../../../../../contexts/dirtyRouteContext'
import {
  BillFormFieldsChangeSource,
  BillFormFieldsChangeSourceMap,
} from '../../../../../enums/billFormFieldsChangeSource'
import { BillFormView } from '../../../../../enums/billFormView'
import { ModalId } from '../../../../../enums/modalId'
import { useForm } from '../../../../../hooks/useForm'
import { APIError } from '../../../../../utils'
import { IS_DEVELOPMENT } from '../../../../../utils/isDevelopment'
import { useBillAttachmentPageContext } from '../../../contexts/billAttachmentPageContext'
import { BillState } from '../../../enums/billState'
import { BillSubmitAction } from '../../../enums/billSubmitAction'
import { BillType } from '../../../enums/billType'
import { BulkMode } from '../../../enums/bulkMode'
import { useGetBill } from '../../../hooks/useGetBill'
import { Bill } from '../../../types/bill'
import { useBillMutations } from '../hooks/useBillMutations'
import { getAutoCompletedFormWithBohr } from '../hooks/useBohrAutoComplete'
import { convertBillFormToBillPayload } from '../utils/convertBillFormToBillPayload'
import { BillForm, getDefaultBillLine, getDefaultValues, getValidationSchema } from '../utils/formData'
import { getAutoCompletedFormWithVendor } from '../utils/getAutoCompletedFormWithVendor'
import { getInitialBillForm } from '../utils/getInitialBillForm'

interface ContextState {
  approve: () => void
  attachmentId?: string
  billId?: string
  billFormView: BillFormView
  bulkEditMode?: BulkMode
  isCreditNote?: boolean
  isCreditNoteFromBill?: boolean
  isFetching: boolean
  isNewCreditNote?: boolean
  processingAction?: BillSubmitAction
  save: () => void
  setIsReferenceNumberError?: (isError: boolean) => void
  formFieldsChangeSource: BillFormFieldsChangeSourceMap
  setFormFieldsChangeSource: React.Dispatch<React.SetStateAction<BillFormFieldsChangeSourceMap>>
}

const BillFormContext = createContext<ContextState | undefined>(undefined)

interface BillFormContextProps {
  attachmentId?: string
  billId?: string
  billIdToDuplicate?: string
  billIdToCreateCreditNote?: string
  children?: ReactNode
  contactId?: string
  isBulkEditMode?: boolean
  isNewCreditNote?: boolean
}

export const BillFormContextProvider = ({
  attachmentId,
  billId,
  billIdToCreateCreditNote,
  billIdToDuplicate,
  children,
  contactId,
  isBulkEditMode,
  isNewCreditNote,
}: BillFormContextProps): ReactElement => {
  const billIdToFetch = billId || billIdToDuplicate || billIdToCreateCreditNote
  const [isFetching, setIsFetching] = useState(!!billIdToFetch || !!attachmentId || !!contactId)
  const [isReferenceNumberError, setIsReferenceNumberError] = useState(false)
  const [hasAttachmentBeenSet, setHasAttachmentBeenSet] = useState(false)
  const [formFieldsChangeSource, setFormFieldsChangeSource] = useState<BillFormFieldsChangeSourceMap>({
    main: BillFormFieldsChangeSource.Search,
  })
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const locale = getCurrentLocale()
  const { setDirty } = useDirtyRoute()
  const { accounts = [], isLoading: isAccountsLoading } = useAccounts()
  const { taxRates = [], isLoading: isTaxRatesLoading } = useTaxRates()
  const previousBillId = usePrevious(billId)
  const previousAttachmentId = usePrevious(attachmentId)
  const { open: openRegisterPaymentModal } = useModal(ModalId.BillRegisterPaymentModal)
  const [, setCurrentPageIndex] = useBillAttachmentPageContext()
  const billFormView = billId ? BillFormView.edit : BillFormView.create

  // Bill fetch

  const { data: billData, isError: isFetchBillError } = useGetBill(billIdToFetch)
  const { attachment, isError: isFetchReceiptError, isLoading: isReceiptLoading } = useFetchReceipt(attachmentId)
  const { contact, isError: isFetchContactError, isLoading: isContactLoading } = useFetchContact(contactId)

  // Computed values

  const bulkEditMode = useMemo(() => {
    if (!isBulkEditMode) {
      return
    }

    if (billId) {
      return BulkMode.Bill
    }

    if (attachmentId) {
      return BulkMode.Attachment
    }
  }, [attachmentId, billId, isBulkEditMode])
  const isCreditNote = Boolean(billId && billData?.bill.type === BillType.CreditNote)
  const isCreditNoteFromBill = Boolean(billIdToCreateCreditNote)

  // Form initialization

  const {
    handleSubmit,
    setValue,
    Form,
    reset,
    formState: { isDirty, errors },
    getValues,
    setError,
  } = useForm({
    defaultValues: useMemo(() => getDefaultValues(organization?.baseCurrencyId), [organization?.baseCurrencyId]),
    validationSchema: useMemo(() => getValidationSchema(t, isReferenceNumberError), [t, isReferenceNumberError]),
  })

  // Custom error handler

  const errorsCustomHandler = useCallback(
    (error: APIError | undefined): boolean => {
      const propertiesErrors = error?.validationErrors?.bill?.attributes as Record<keyof Bill, string> | undefined

      if (propertiesErrors && propertiesErrors.paymentAccountId) {
        setError('paymentAccount', {
          type: 'custom',
          message: propertiesErrors.paymentAccountId,
        })

        openRegisterPaymentModal()
        setDirty(true)
        return true
      }

      return false
    },
    [openRegisterPaymentModal, setDirty, setError],
  )

  // Bill mutations

  const { createBill, updateBill, processingAction } = useBillMutations({
    bulkEditMode,
    billIdToCreateCreditNote,
    handleErrorManually: errorsCustomHandler,
  })

  // Executors

  const approve = useCallback(
    async (bill: BillForm) => {
      if (!bill.submitAction) {
        console.error('Submit action has to be set!')
        return
      }

      if (!organization) {
        return
      }

      setDirty(false)

      const saveAsCreditNote = isCreditNote || isNewCreditNote || isCreditNoteFromBill
      const billPayload = convertBillFormToBillPayload(bill, organization.id, saveAsCreditNote, BillState.Approved)

      if (billId) {
        updateBill({ billId, payload: { ...billPayload } }, bill.submitAction, bill.attachmentsFiles)
      } else {
        createBill(billPayload, bill.submitAction, bill.attachmentsFiles)
      }
    },

    [billId, createBill, isCreditNote, isCreditNoteFromBill, isNewCreditNote, organization, setDirty, updateBill],
  )

  const save = useCallback(
    (bill: BillForm) => {
      if (!bill.submitAction) {
        console.error('Submit action has to be set!')
        return
      }

      if (!organization) {
        return
      }

      setDirty(false)

      const saveAsCreditNote = isCreditNote || isNewCreditNote || isCreditNoteFromBill
      const billPayload = convertBillFormToBillPayload(bill, organization.id, saveAsCreditNote)

      if (billId) {
        updateBill({ billId, payload: billPayload }, bill.submitAction, bill.attachmentsFiles)
      } else {
        createBill(billPayload, bill.submitAction, bill.attachmentsFiles)
      }
    },
    [billId, createBill, isCreditNote, isCreditNoteFromBill, isNewCreditNote, organization, setDirty, updateBill],
  )

  // Resetting attachments page index to reset preview to first attachment when bill changes in bulk edit mode

  const resetAttachmentsPageIndex = useCallback(() => {
    setCurrentPageIndex(0)
  }, [setCurrentPageIndex])

  // Handlers

  const handleApprove = useCallback(() => {
    setValue('submitAction', bulkEditMode ? BillSubmitAction.ApproveBulk : BillSubmitAction.Approve) // helper form value for validation check and correct processing after mutation
    handleSubmit(approve)()
  }, [approve, bulkEditMode, handleSubmit, setValue])

  const handleSave = useCallback(() => {
    setValue('submitAction', BillSubmitAction.Save) // helper form value for validation check and correct processing after mutation
    handleSubmit(save)()
  }, [handleSubmit, save, setValue])

  // Observe bill
  useAsync(async () => {
    if (isTaxRatesLoading || isAccountsLoading || !organization?.id || !billData || processingAction) {
      return
    }

    const billForm = await getInitialBillForm({
      accounts,
      billData,
      isDuplicate: !!billIdToDuplicate,
      isCreditNoteFromBill: !!billIdToCreateCreditNote,
      locale,
      organization,
      billFormCurrent: {
        submitAction: getValues('submitAction'),
        bookkeepingTagSuggestions: getValues('bookkeepingTagSuggestions'),
      },
      t,
      taxRates,
    })

    if (billForm) {
      resetAttachmentsPageIndex()
      reset(billForm)

      // Wait one frame before setting it to false to better handle loading states -> form needs it to correctly update state
      window.requestAnimationFrame(() => {
        setValue('autoCompletedFields', billForm.autoCompletedFields)
      })

      setIsFetching(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billData, isTaxRatesLoading, isAccountsLoading, processingAction])

  // Observe attachment
  useAsync(async () => {
    if (isTaxRatesLoading || isAccountsLoading || isReceiptLoading || !organization?.id) {
      return
    }

    if (attachment?.file) {
      if (hasAttachmentBeenSet) {
        setIsFetching(false)
        return
      }

      const attachmentFile: AttachmentFile | undefined = {
        ...attachment.file,
        attachmentId: attachment.id,
        attachmentType: attachment.type,
      }

      // we treat it as "chosen" and this attachment is kind of chosen from the receipts, and not assigned to the bill
      // we also don't want to create a new attachment, and "attachmentsFilesChosen" are passed as attachmentsIds in the payload
      const billForm: BillForm = {
        ...getDefaultValues(organization?.baseCurrencyId),
        attachmentsFiles: [attachmentFile],
        attachmentsFilesChosen: [attachmentFile],
      }

      const billFormUpdated = await getAutoCompletedFormWithBohr({
        accounts,
        attachmentFile,
        baseCurrencyId: organization?.baseCurrencyId,
        billForm,
        chartOfAccountId: organization.chartOfAccountId,
        countryId: organization?.countryId,
        locale,
        organizationId: organization?.id,
        taxRates,
      })

      if (attachment.comment) {
        if (!billFormUpdated.billLines?.length) {
          billFormUpdated.billLines = [
            {
              ...getDefaultBillLine(),
              description: attachment.comment,
            },
          ]
        } else {
          billFormUpdated.billLines[0].description = attachment.comment
        }

        billFormUpdated.autoCompletedFields = {
          ...billFormUpdated.autoCompletedFields,
          'billLines.0.description': true,
        }
      }

      reset(billFormUpdated)

      // Wait one frame before setting autoCompletedFields
      window.requestAnimationFrame(() => {
        setValue('autoCompletedFields', billFormUpdated.autoCompletedFields)
      })

      setIsFetching(false)
      setHasAttachmentBeenSet(true)
    }
  }, [
    attachment?.file,
    attachment?.id,
    isTaxRatesLoading,
    isAccountsLoading,
    isReceiptLoading,
    hasAttachmentBeenSet,
    organization?.id,
  ])

  // Observe contact
  useAsync(async () => {
    if (isTaxRatesLoading || isAccountsLoading || isContactLoading || !organization?.id) {
      return
    }

    if (contact) {
      setValue('vendor', contact)

      const billFormUpdated = await getAutoCompletedFormWithVendor({
        accounts,
        baseCurrencyId: organization?.baseCurrencyId,
        billForm: getValues(),
        chartOfAccountId: organization.chartOfAccountId,
        countryId: organization?.countryId,
        isVatExempted: organization.isVatExempted,
        locale,
        taxRates,
        vendor: contact,
      })

      billFormUpdated.autoCompletedFields = {
        ...billFormUpdated.autoCompletedFields,
        vendor: true,
      }

      reset(billFormUpdated)

      // Wait one frame before setting autoCompletedFields
      window.requestAnimationFrame(() => {
        setValue('autoCompletedFields', billFormUpdated.autoCompletedFields)
      })

      setIsFetching(false)
    }
  }, [contact?.id, isTaxRatesLoading, isAccountsLoading, isContactLoading, organization?.id])

  useEffect(() => {
    if (isFetchReceiptError || isFetchBillError || isFetchContactError) {
      setIsFetching(false)
    }
  }, [isFetchBillError, isFetchReceiptError, isFetchContactError])

  useEffect(() => {
    if ((billId && !billData) || (attachmentId && !attachment) || (contactId && !contact)) {
      setIsFetching(true)
    }

    if (
      (previousBillId && previousBillId !== billId) ||
      (previousAttachmentId && previousAttachmentId !== attachmentId)
    ) {
      if (billId || attachmentId) {
        setIsFetching(true)
      }

      setHasAttachmentBeenSet(false)
      resetAttachmentsPageIndex()
      reset(getDefaultValues(organization?.baseCurrencyId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billId, attachmentId, contactId])

  useEffect(() => {
    if (organization?.baseCurrencyId && !getValues('currencyId')) {
      setValue('currencyId', organization.baseCurrencyId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.baseCurrencyId])

  // @todo remove it from here as it causes re-renders
  if (!isEmpty(errors) && IS_DEVELOPMENT) {
    // Some errors might not be visible in the UI (if not handled) so just in case we console them
    console.error('FORM VALIDATION ERROR', errors)
  }

  useEffect(() => {
    setDirty(isDirty)
    // Run effect only on `isDirty` value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  return (
    <BillFormContext.Provider
      value={{
        approve: handleApprove,
        attachmentId,
        billFormView,
        billId,
        bulkEditMode,
        isCreditNote,
        isNewCreditNote,
        isCreditNoteFromBill,
        isFetching,
        processingAction,
        save: handleSave,
        setIsReferenceNumberError,
        formFieldsChangeSource,
        setFormFieldsChangeSource,
      }}
    >
      <Form>{children}</Form>
    </BillFormContext.Provider>
  )
}

export const useBillForm = () => {
  const context = useContext(BillFormContext)

  if (!context) {
    throw new Error('BillFormContextProvider is missing in the module!')
  }

  return context
}
