import { SectionMessage, Space, Text } from '@design-system'

import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BankConnection, BankConnectionSession } from '@modules-deprecated/bankReconciliation/types'

import { formatDate } from '../../../../utils'

interface BankConnectionErrorProps {
  session?: BankConnectionSession
  connectedBank: BankConnection
}

export const BankConnectionError = ({ session, connectedBank }: BankConnectionErrorProps): ReactElement => {
  const { lastPullTime } = connectedBank
  const formattedLastPullTime = formatDate(lastPullTime, 'yyyy-MM-dd, HH:mm')
  const { t } = useTranslation()

  const errorMessage = useMemo(() => {
    const errorCode = session?.errorCode || connectedBank.errorCode
    switch (errorCode) {
      case 'E_SESSION_EXPIRED':
        return t('bank_settings_modal.error.expired_session')
      case 'E_LOGIN_FAILED':
        return t('bank_settings_modal.error.login_failed')
      case 'E_INVALID_ACCOUNT':
        return t('bank_settings_modal.error.invalid_account')
      case 'E_NO_SESSION':
      default:
        return t('bank_settings_modal.error.unknown')
    }
  }, [connectedBank.errorCode, session?.errorCode, t])

  return (
    <SectionMessage variant="error">
      <Text inheritColor>{errorMessage}</Text>
      <Space />
      <Text inheritColor>
        {t('bank_settings_modal.last_time_updated')} {formattedLastPullTime}
      </Text>
    </SectionMessage>
  )
}
