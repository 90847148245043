import { IconTrash as IconTrashComponent } from '@components-deprecated'

import styled from '@emotion/styled'

export const FooterActions = styled.footer`
  display: flex;
  width: 100%;
  margin-top: auto;
  justify-content: flex-end;
`

export const IconTrash = styled(IconTrashComponent)`
  margin-right: 5px;
  position: relative;
  top: -2px;
`
