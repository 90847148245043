import { Translate } from '@components'
import { SkeletonText, Text } from '@design-system'

import React, { memo, ReactElement, useCallback, useMemo } from 'react'

import { useBankAccounts, useLocalStorage } from '../../../hooks'
import { formatDate } from '../../../utils'
import { useUserOrganization } from '../../app/organization'
import { useBankLineMatches } from '../../bankReconciliation/hooks'
import { countNotMissingReceiptBankLines } from '../utils'
import * as Styled from './styles'

const BANK_LINE_MATCHES_DATE_FROM_LIMIT = '2021-01-01'
const BANK_LINE_MATCHES_DATE_TO_LIMIT = formatDate(new Date(), 'yyyy-MM-dd')

export const EmptyVouchersList = memo((): ReactElement => {
  const [bankReconciliationFlag] = useLocalStorage<number>('labs-flag:reactBankReconciliation', 0)
  const { bankAccounts, isLoading: areBankAccountsLoading } = useBankAccounts()
  const accountsIds = useMemo(
    () => bankAccounts.filter(({ isArchived }) => !isArchived).map(({ id }) => id),
    [bankAccounts],
  )
  // we're only looking for not reconciled bank lines with entryDatePeriod starting at the beginning of the 2021
  const { bankLineMatches: filteredBankLineMatches, isLoading: areBankLinesMatchesLoading } = useBankLineMatches({
    accountIds: accountsIds,
    include: 'bankLineMatch.lines:embed',
    isDisabled: areBankAccountsLoading,
    isApproved: false,
    entryDatePeriod: `dates:${BANK_LINE_MATCHES_DATE_FROM_LIMIT}...${BANK_LINE_MATCHES_DATE_TO_LIMIT}`,
  })
  const isLoading = areBankAccountsLoading || areBankLinesMatchesLoading

  const totalAmountToReconcile = useMemo(
    () => countNotMissingReceiptBankLines(filteredBankLineMatches),
    [filteredBankLineMatches],
  )
  const isButtonDisabled = isLoading || (!isLoading && totalAmountToReconcile === 0)
  const { organization } = useUserOrganization()

  const bankReconciliationUrl = useMemo((): string | undefined => {
    if (!organization || !accountsIds.length || isButtonDisabled || isLoading) {
      return
    }

    const baseUrl = `/${organization.url}/bank_accounts/${accountsIds[0]}`

    return bankReconciliationFlag ? `${baseUrl}/bank_sync_react` : `${baseUrl}/sync`
  }, [accountsIds, bankReconciliationFlag, isButtonDisabled, isLoading, organization])

  const handleButtonClick = useCallback((): void => {
    if (bankReconciliationUrl) {
      window.open(bankReconciliationUrl, '_blank')
    }
  }, [bankReconciliationUrl])

  return (
    <Styled.NoVouchersWrapper>
      {isLoading ? (
        <>
          <SkeletonText variant="h1" width={312} />
          <SkeletonText variant="h3" width={416} />
        </>
      ) : (
        <>
          <Text variant="h1">
            <Translate value="voucher.inbox.no_vouchers" />
          </Text>
          <Text variant="h3">
            <Translate count={totalAmountToReconcile} value="voucher.inbox.bank_reconciliation" />
          </Text>
        </>
      )}
      {!isButtonDisabled && (
        <Styled.Button onClick={handleButtonClick}>
          <Translate value="voucher.inbox.go_to_bank_reconciliation" />
        </Styled.Button>
      )}
    </Styled.NoVouchersWrapper>
  )
})
