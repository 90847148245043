import { DEFAULT_API_COUNTRY_KEY } from '../../../constants/defaultApiCountryKey'
import { ApiCountryKey } from '../../../types/apiCountryKey'
import { getRequest } from '../../../utils'
import { Company } from './types'

interface FetchCompaniesResponseData {
  companies: Company[]
}

export const fetchCompanies = async (
  query?: string,
  apiCountryKey: ApiCountryKey = DEFAULT_API_COUNTRY_KEY,
): Promise<FetchCompaniesResponseData> => {
  const { data } = await getRequest(`/cvr/${apiCountryKey}/search?q=${query}`, undefined, true)
  return { companies: data }
}

interface FetchCompanyResponseData {
  data: Company
}

interface FetchCompanyProps {
  cvr: string
  apiCountryKey?: ApiCountryKey
}

export const fetchCompany = ({
  cvr,
  apiCountryKey = DEFAULT_API_COUNTRY_KEY,
}: FetchCompanyProps): Promise<FetchCompanyResponseData> => {
  return getRequest(`/cvr/${apiCountryKey}/companies/${cvr}`)
}
