import { useModalMultiple } from '@design-system'

import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { AllBankConnections, BankConnection, BankConnectionOld } from '@modules-deprecated/bankReconciliation/types'

import { ModalId } from '../../../enums/modalId'
import { useGetQueryFromEmberRoute } from '../../../hooks/useGetQueryFromEmberRoute'
import { EmberEventFn } from '../../../types/emberEventFn'
import { isOldBankConnection } from '../../../utils/isOldBankConnection'
import { BankIntegrationModals } from '../elements/BankIntegrationModals/BankIntegrationModals'
import { useBankConnections } from '../hooks/useBankConnections'
import { useFindBankAccountId } from '../hooks/useFindBankAccountId'
import { useOldBankConnections } from '../hooks/useOldBankConnections'
import { useRemoveBankConnection } from '../hooks/useRemoveBankConnection'
import { useSyncBankConnection } from '../hooks/useSyncBankConnection'

interface ContextState {
  accountId: string
  accountCurrency: string
  bankConnections?: AllBankConnections
  connectedBank?: BankConnection | BankConnectionOld
  connectedBankAccountId?: string
  hasBankConnectionError: boolean
  isLoading: boolean
  isOrphaned: boolean
  removeConnection: () => void
  renewConnection: () => void
  requiresBankReconnection: boolean
}

const BankIntegrationContext = createContext<ContextState | undefined>(undefined)

interface BankIntegrationContextProps {
  account?: string
  children?: ReactNode
  onCheckBankIntegration?: EmberEventFn
  organizationId: string
}

export const BankIntegrationContextProvider = ({
  account,
  children,
  onCheckBankIntegration,
  organizationId,
}: BankIntegrationContextProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true)
  const [connectedBank, setConnectedBank] = useState<BankConnection | BankConnectionOld>()
  const [connectedBankAccountId, setConnectedBankAccountId] = useState<string>()

  useGetQueryFromEmberRoute({
    queryParamsValidKeys: ['success', 'connectionId', 'jobId', 'error_code', 'token', 'auth_code'],
  })

  const { accountId, accountCurrency } = useFindBankAccountId({ account, organizationId })

  const { bankConnections, isLoading: isLoadingBankConnections, refetch: refetchBankConnections } = useBankConnections()

  const connectedBankData = useMemo(
    () => bankConnections?.find((bankConnection) => bankConnection?.externalAccountId === accountId),
    [accountId, bankConnections],
  )

  const {
    connectedBank: connectedBankOld,
    isLoading: isLoadingOldBankConnections,
    oldBankConnections,
    refetchOldBankConnections,
  } = useOldBankConnections(accountId)

  const isLoadingAllConnections = isLoadingBankConnections || isLoadingOldBankConnections

  const shouldUseOldBankConnections =
    !isLoadingAllConnections && !!oldBankConnections?.length && !!connectedBankOld && !connectedBankData

  const {
    openedIds,
    open: openModal,
    close: closeModal,
  } = useModalMultiple([
    ModalId.BankToAccountConnectionModal,
    ModalId.BankSettingsModal,
    ModalId.BankConnectionSuccessModal,
    ModalId.BankConnectionRemovedModal,
  ])

  const { removeBank, removeOldBank } = useRemoveBankConnection({
    onSuccess: () => {
      setConnectedBankAccountId(undefined)
      openModal(ModalId.BankConnectionRemovedModal)
      onCheckBankIntegration?.({ detail: { connectedBankData: false } })
      refetchBankConnections()
      refetchOldBankConnections()
    },
  })

  const { syncAccountBankConnection: renewBankConnection } = useSyncBankConnection()

  const isOldBankConnectionUsed = connectedBank && isOldBankConnection(connectedBank)
  const requiresBankReconnection = (!isOldBankConnectionUsed && connectedBank?.connection?.requiresUpdate) || false
  const isBankAccountOrphaned = !isOldBankConnectionUsed && !!connectedBank?.isOrphaned
  const hasBankConnectionError = !isOldBankConnectionUsed && !!connectedBank?.connection?.errorCode

  const findConnectedBankData = useCallback(() => {
    if (shouldUseOldBankConnections) {
      setConnectedBank(connectedBankOld)
      setConnectedBankAccountId(connectedBankOld?.referenceId)
      setIsLoading(false)
      onCheckBankIntegration?.({ detail: { connectedBankData: connectedBankOld } })
      return
    }

    if (bankConnections?.length) {
      if (connectedBankData) {
        setConnectedBank(connectedBankData)
        setConnectedBankAccountId(connectedBankData?.id)
      } else {
        setConnectedBank(undefined)
        setConnectedBankAccountId(undefined)
      }

      onCheckBankIntegration?.({ detail: { connectedBankData } })
    } else {
      setConnectedBank(undefined)
      setConnectedBankAccountId(undefined)
      onCheckBankIntegration?.({ detail: { connectedBankData: false } })
    }

    setIsLoading(false)
  }, [
    bankConnections?.length,
    connectedBankData,
    connectedBankOld,
    onCheckBankIntegration,
    shouldUseOldBankConnections,
  ])

  const handleRenewConnection = useCallback(() => {
    renewBankConnection(connectedBankAccountId as string)
  }, [renewBankConnection, connectedBankAccountId])

  const handleRemoveConnection = useCallback(() => {
    shouldUseOldBankConnections ? removeOldBank(accountId) : removeBank(connectedBankAccountId as string)
  }, [accountId, connectedBankAccountId, removeBank, removeOldBank, shouldUseOldBankConnections])

  const allBankConnections = useMemo(
    () => ({
      newConnections: bankConnections,
      oldConnections: oldBankConnections,
    }),
    [bankConnections, oldBankConnections],
  )

  useEffect(() => {
    findConnectedBankData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, bankConnections, connectedBankAccountId, oldBankConnections])

  useEffect(() => {
    if (requiresBankReconnection && openedIds.length === 0) {
      openModal(ModalId.BankConnectionExpiredModal)
    } else {
      closeModal(ModalId.BankConnectionExpiredModal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, requiresBankReconnection])

  return (
    <BankIntegrationContext.Provider
      value={{
        accountId,
        bankConnections: allBankConnections,
        connectedBank,
        connectedBankAccountId,
        accountCurrency,
        hasBankConnectionError,
        isLoading: isLoading || isLoadingAllConnections,
        isOrphaned: isBankAccountOrphaned,
        removeConnection: handleRemoveConnection,
        renewConnection: handleRenewConnection,
        requiresBankReconnection,
      }}
    >
      {children}
      <BankIntegrationModals removeConnection={handleRemoveConnection} />
    </BankIntegrationContext.Provider>
  )
}

export const useBankIntegration = () => {
  const context = useContext(BankIntegrationContext)

  if (!context) {
    throw new Error('BankIntegrationContextProvider is missing in the module!')
  }

  return context
}
