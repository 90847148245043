import { Button, ModalConfirmation, notify, SettingsLayout, Space, Text, useModal } from '@design-system'

import { ReactElement, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { APIError } from '../../../../../../utils'
import { TaxAccountWithDetails } from '../../types/taxAccountWithDetails'
import { CreateOrEditVatAccountModal } from './elements/CreateOrEditVatAccountModal'
import { VatAccountsTable } from './elements/VatAccountsTable/VatAccountsTable'
import { useDeleteVatAccount } from './hooks/useDeleteVatAccount'
import { useInvalidateVatAccounts } from './hooks/useInvalidateVatAccounts'
import * as Styled from './styles'

const DELETE_VAT_ACCOUNT_CONFIRMATION_MODAL_ID = 'delete-vat-account-confirmation-modal-id'

export const VatAccounts = (): ReactElement => {
  const { t } = useTranslation()
  const vatAccountToEditRef = useRef<TaxAccountWithDetails>()
  const { open: openCreateOrEditVatAccountModal } = useModal(ModalId.CreateOrEditVatAccountModal, {
    onClose: () => {
      vatAccountToEditRef.current = undefined
    },
  })

  const vatAccountIdToDeleteRef = useRef<string>()
  const { invalidateVatAccounts } = useInvalidateVatAccounts()

  const { open: openDeleteConfirmation, close: closeDeleteConfirmation } = useModal(
    DELETE_VAT_ACCOUNT_CONFIRMATION_MODAL_ID,
    {
      onClose: () => {
        vatAccountIdToDeleteRef.current = undefined
      },
    },
  )

  const handleDeleteVatAccountError = useCallback(
    (error: APIError | undefined) => {
      notify({
        id: 'vat-account-deleted',
        message: error?.message || t('settings.organization.vat_rates.rates.notification.delete_error'),
        variant: 'error',
      })
    },
    [t],
  )

  const handleDeleteVatAccountSuccess = useCallback(() => {
    notify({
      id: 'vat-account-deleted',
      message: t('settings.organization.vat_rates.rates.notification.delete_success'),
      variant: 'success',
    })
    closeDeleteConfirmation()
    invalidateVatAccounts()
  }, [closeDeleteConfirmation, invalidateVatAccounts, t])

  const { delete: deleteVatAccount, isLoading: isDeleting } = useDeleteVatAccount({
    onSuccess: handleDeleteVatAccountSuccess,
    onError: handleDeleteVatAccountError,
  })

  const handleVatAccountDelete = useCallback(
    (vatAccountId: string) => {
      vatAccountIdToDeleteRef.current = vatAccountId
      openDeleteConfirmation()
    },
    [openDeleteConfirmation],
  )

  const handleDeleteModalOkClick = useCallback(() => {
    if (vatAccountIdToDeleteRef.current) {
      deleteVatAccount(vatAccountIdToDeleteRef.current)
    }
  }, [deleteVatAccount])

  const handleCreateVatAccount = useCallback(() => {
    openCreateOrEditVatAccountModal()
  }, [openCreateOrEditVatAccountModal])

  const handleEditVatAccountClick = useCallback(
    (account: TaxAccountWithDetails) => {
      vatAccountToEditRef.current = account
      openCreateOrEditVatAccountModal()
    },
    [openCreateOrEditVatAccountModal],
  )

  return (
    <SettingsLayout
      title={t('settings.organization.tax_return.tax_accounts')}
      sideActions={
        <Button onClick={handleCreateVatAccount} variant="secondary">
          {t('settings.organization.tax_return.create_tax_account')}
        </Button>
      }
    >
      <Styled.TableWrapper>
        <Text variant="small" colorVariant="secondary">
          {t('settings.organization.tax_return.tax_accounts_tip')}
        </Text>
        <Space size="xl" />
        <VatAccountsTable
          onEditVatAccountModal={handleEditVatAccountClick}
          onVatAccountDelete={handleVatAccountDelete}
        />
      </Styled.TableWrapper>
      <CreateOrEditVatAccountModal vatAccount={vatAccountToEditRef.current} />
      <ModalConfirmation
        cancelLabel={t('cancel')}
        danger
        id={DELETE_VAT_ACCOUNT_CONFIRMATION_MODAL_ID}
        message={t('settings.organization.tax_return.modals.delete.message')}
        okLabel={t('delete')}
        okLoading={isDeleting}
        onOk={handleDeleteModalOkClick}
        title={t('settings.organization.tax_return.modals.delete.title')}
      />
    </SettingsLayout>
  )
}
