import { PeriodMode } from '../../../../../enums/periodMode'
import { PeriodValue } from '../../../../../types/periodValue'
import { DEFAULT_PERIOD_MODE } from '../../constants/defaultPeriodMode'
import { headerPeriodModes } from '../../constants/headerPeriodModes'

export const getValidPeriodMode = (periodValue?: PeriodValue, disabledPeriodModes?: PeriodMode[]): PeriodMode => {
  let periodMode = periodValue?.mode
  const isPeriodValueModeDisabled = periodMode && disabledPeriodModes?.includes(periodMode)
  const isPeriodModeInHeader = periodMode && headerPeriodModes.includes(periodMode)

  if (isPeriodValueModeDisabled && disabledPeriodModes?.length) {
    periodMode = Object.values(PeriodMode)
      .filter((mode) => mode && !disabledPeriodModes?.includes(mode))
      .pop()
  }

  if (!periodMode || !isPeriodModeInHeader) {
    periodMode = DEFAULT_PERIOD_MODE
  }

  return periodMode
}
