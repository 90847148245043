import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ZervantInvoicingButton } from '../../elements/ZervantInvoicingButton'
import { InvoicesSummary } from './elements/InvoicesSummary'
import { InvoicesTable } from './elements/InvoicesTable'
import { InvoicesTableActions } from './elements/InvoicesTableActions'

export const InvoicesList = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <ModuleLayout title={t('invoices')} sideActions={<ZervantInvoicingButton />}>
      <InvoicesSummary />
      <InvoicesTableActions />
      <InvoicesTable />
    </ModuleLayout>
  )
}
