import { ButtonProtected } from '@components'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { useBankIntegration } from '../../contexts/bankIntegrationContext'
import { useInitiateBankConnection } from '../../hooks/useInitiateBankConnection'
import { useSyncBankConnection } from '../../hooks/useSyncBankConnection'

export const ConnectBankButton = (): ReactElement | null => {
  const { t } = useTranslation()
  const { connectedBankAccountId, isLoading, requiresBankReconnection } = useBankIntegration()
  const { connectToBank, isLoading: isLoadingAiiaConnection } = useInitiateBankConnection()
  const { syncAccountBankConnection } = useSyncBankConnection()

  const handleConnectBankClick = useCallback(() => {
    if (connectedBankAccountId && requiresBankReconnection) {
      syncAccountBankConnection(connectedBankAccountId)
    } else {
      connectToBank()
    }
  }, [connectToBank, connectedBankAccountId, requiresBankReconnection, syncAccountBankConnection])

  const buttonText = useMemo(() => {
    if (connectedBankAccountId && requiresBankReconnection) {
      return t('bank_integration.renew_connection')
    }

    return t('bank_integration.connect_bank')
  }, [connectedBankAccountId, requiresBankReconnection, t])

  if (isLoading || (connectedBankAccountId && !requiresBankReconnection)) {
    return null
  }

  return (
    <ButtonProtected
      icon={'plug'}
      onClick={handleConnectBankClick}
      loading={isLoadingAiiaConnection}
      scopes={Scope.BankSyncWrite}
      trackingContext={TrackingContext.BankConnectionsConnectBank}
    >
      {buttonText}
    </ButtonProtected>
  )
}
