import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { DefaultValue } from '../../enums/defaultValue'
import { Spacing } from '../../enums/spacing'
import { getShadow } from '../../utils/getShadow'

interface NavWrapperProps {
  visible?: boolean
  width?: number
}

export const NavWrapper = styled.div<NavWrapperProps>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  width: 100%;
  display: flex;
  padding: ${Spacing.S};
  border-radius: ${DefaultValue.BorderRadius};
  flex-direction: column;
  background-color: ${Color.White};
  ${getShadow('raisedStrong')};
`

export const NavFooter = styled.footer`
  margin-top: auto;
`

export const NavHeader = styled.header`
  margin-bottom: ${Spacing.S};
`
