import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'
import { HashRouter } from 'react-router-dom'

import { store } from '@modules-deprecated'
import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { UmbrellaContextProvider } from '@modules-deprecated/app/umbrellas'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { Inbox } from '@modules-deprecated/inbox'

import { queryClient } from '../../config/queryClient'
import { InboxOrganizationIdContextProvider } from '../../contexts/inboxOrganizationIdContext'
import { ThemeProvider } from '../../contexts/themeProvider'

class InboxWebComponent extends DOMModel {
  @byAttrVal customerView: string
  @byAttrVal umbrellaId: string
  @byAttrVal organizationId: string
}

type InboxModuleProps = {
  customerView?: 'true' | 'false'
  umbrellaId?: string
  organizationId?: string
}

function InboxModule({ customerView, umbrellaId, organizationId: userOrganizationId }: InboxModuleProps): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <HashRouter>
          <UmbrellaContextProvider umbrellaId={umbrellaId}>
            <CurrentUserContextProvider>
              <UserOrganizationContextProvider>
                <AccountsContextProvider>
                  <InboxOrganizationIdContextProvider>
                    <ReduxProvider store={store}>
                      <Inbox
                        customerView={customerView === 'true'}
                        umbrellaId={umbrellaId}
                        userOrganizationId={userOrganizationId}
                      />
                    </ReduxProvider>
                  </InboxOrganizationIdContextProvider>
                </AccountsContextProvider>
              </UserOrganizationContextProvider>
            </CurrentUserContextProvider>
          </UmbrellaContextProvider>
        </HashRouter>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

const InboxCustomElement = createCustomElement(InboxModule, InboxWebComponent, 'element')

customElements.get('inbox-module') || customElements.define('inbox-module', InboxCustomElement)

export default InboxCustomElement
