import { Button } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../../../../../hooks'
import { useInviteUserModal } from '../../context/inviteUserModalContext'
import { InviteUserFormSchema } from '../../utils/formData'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { submitForm, isLoading } = useInviteUserModal()
  const { control } = useFormContext<InviteUserFormSchema>()
  const email = useWatch({ control, name: 'email' })

  return (
    <Button type="button" onClick={submitForm} loading={isLoading} disabled={!email.length}>
      {t('settings.organization.users.invite_user_modal.sent_invitation')}
    </Button>
  )
}
