import { TFunction } from 'i18next'
import { object, SchemaOf, string } from 'yup'

export interface RejectReceiptForm {
  reason: string
}

export const getDefaultValues = (): RejectReceiptForm => ({
  reason: '',
})

export type RejectReceiptFormSchema = SchemaOf<RejectReceiptForm>

export const getValidationSchema = (t: TFunction): RejectReceiptFormSchema =>
  object({
    reason: string().required(t('receipts.modal.reject_e_invoice.reason.required')),
  })
