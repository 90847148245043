import { format } from 'date-fns'

import { PeriodMode } from '../../../../enums/periodMode'
import { PeriodValue } from '../../../../types/periodValue'
import { indexValueToDisplayValue } from './indexValueToDisplayValue'

export const encodePeriodValue = (periodValue: PeriodValue, organizationId: string): string | undefined => {
  const { value, customDate, year, mode } = periodValue

  if (customDate) {
    const { startDate, endDate } = customDate

    if (!startDate && !endDate) {
      return PeriodMode.All
    }

    const startDateFormatted = startDate ? format(startDate, 'yyyy-MM-dd') : ''
    const endDateFormatted = endDate ? format(endDate, 'yyyy-MM-dd') : ''

    let value = `${PeriodMode.CustomDate}:${startDateFormatted}...${endDateFormatted}`

    if (!endDateFormatted) {
      value = `${PeriodMode.CustomDateFrom}:${startDateFormatted}`
    } else if (!startDateFormatted) {
      value = `${PeriodMode.CustomDateThrough}:${endDateFormatted}`
    }

    return value
  }

  if (value !== undefined) {
    switch (mode) {
      case PeriodMode.Month:
        return `${PeriodMode.Month}:${year}-${indexValueToDisplayValue(value)}`

      case PeriodMode.Quarter:
        return `${PeriodMode.Quarter}:${year}-${value + 1}`

      case PeriodMode.FiscalYear:
        return `${PeriodMode.FiscalYear}:${organizationId},${year}`
    }
  }

  return PeriodMode.All
}
