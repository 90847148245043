import { notify, useModal } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../config/queryClient'
import { ModalId } from '../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { APIError } from '../../../../../utils'
import { RemoveUserModal } from '../elements/RemoveUserModal'
import { UserType } from '../enums/userType'
import { useDeleteBookkeeper } from '../hooks/useDeleteBookkeeper'
import { useDeleteInvitation } from '../hooks/useDeleteInvitation'
import { useDeleteUser } from '../hooks/useDeleteUser'
import { User } from '../types'

interface ContextState {
  removeUser: (user: User) => void
}

const RemoveUserContext = createContext<ContextState | undefined>(undefined)

interface RemoveUserContextProviderProps {
  children: ReactNode
  userType: UserType
}

export const RemoveUserContextProvider = ({ children, userType }: RemoveUserContextProviderProps): ReactElement => {
  const { t } = useTranslation()
  const userData = useRef<User>()

  const modalId =
    userType === UserType.User
      ? ModalId.OrganizationSettingsRemoveUserModal
      : ModalId.OrganizationSettingsRemoveBookkeeperModal

  const { close, open } = useModal(modalId)

  const handleError = useCallback(
    (error?: APIError) => {
      notify({
        id: NotificationKeys.OrganizationInvitation,
        message: error?.message || t('something_went_wrong'),
        variant: 'error',
      })
    },
    [t],
  )

  const handleDeleteBookkeeperSuccess = useCallback(() => {
    close()
    queryClient.invalidateQueries(QueryKeys.OrganizationBookkeepers, { refetchInactive: true })
  }, [close])

  const handleDeleteUserSuccess = useCallback(() => {
    close()
    queryClient.invalidateQueries(QueryKeys.OrganizationUsers, { refetchInactive: true })
  }, [close])

  const handleDeleteInvitationSuccess = useCallback(() => {
    if (userType === UserType.Bookkeeper) {
      queryClient.invalidateQueries(QueryKeys.OrganizationBookkeepers, { refetchInactive: true })
    } else {
      queryClient.invalidateQueries(QueryKeys.OrganizationUsers, { refetchInactive: true })
    }

    close()
  }, [close, userType])

  const { deleteBookkeeper, isSaving: isDeletingBookkeeper } = useDeleteBookkeeper({
    onError: handleError,
    onSuccess: handleDeleteBookkeeperSuccess,
  })
  const { deleteUser, isSaving: isDeletingUser } = useDeleteUser({
    onError: handleError,
    onSuccess: handleDeleteUserSuccess,
  })
  const { deleteInvitation, isSaving: isDeletingInvitation } = useDeleteInvitation({
    onError: handleError,
    onSuccess: handleDeleteInvitationSuccess,
  })

  const isLoading = isDeletingInvitation || isDeletingUser || isDeletingBookkeeper

  const handleRemoveUser = useCallback(
    (user: User) => {
      userData.current = user
      open()
    },
    [open],
  )

  const handleConfirmation = useCallback(() => {
    if (!userData.current) {
      return
    }

    if (userData.current.isPending) {
      deleteInvitation(userData.current.id)
      return
    }

    if (userType === UserType.Bookkeeper) {
      deleteBookkeeper(userData.current.id)
    } else {
      deleteUser(userData.current.id)
    }
  }, [deleteBookkeeper, deleteInvitation, deleteUser, userType])

  return (
    <RemoveUserContext.Provider value={{ removeUser: handleRemoveUser }}>
      {children}
      <RemoveUserModal
        id={modalId}
        isLoading={isLoading}
        logoId={userData.current?.logoId || ''}
        name={(userData.current?.name || userData.current?.email) as string}
        onOk={handleConfirmation}
        userType={userType}
      />
    </RemoveUserContext.Provider>
  )
}

export const useRemoveUser = () => {
  const context = useContext(RemoveUserContext)

  if (!context) {
    throw new Error('RemoveUserContextProvider is missing in the module!')
  }

  return context
}
