import { TFunction } from 'i18next'
import { Accept, FileError, FileRejection } from 'react-dropzone'

import { formatBytes } from '../../../utils/formatBytes'

export type LimitOptions = {
  types?: Accept
  maxFiles?: number
  maxSize?: number
  minSize?: number
}

const getCustomMessage = (fileError: FileError, limitOptions: LimitOptions, t: TFunction): string => {
  const { types = [], maxFiles = 1, maxSize = 0, minSize = 0 } = limitOptions
  let fileTypes

  switch (fileError.code) {
    case 'file-too-large':
      return `${t('dropzone.error.file_too_large')} ${formatBytes(maxSize)}`
    case 'file-too-small':
      return `${t('dropzone.error.file_too_small')} ${formatBytes(minSize)}`
    case 'file-invalid-type': {
      const fileTypesList = Object.values(types)
        .flat()
        .map((item) => item.replace('.', ''))
      fileTypes = fileTypesList.join(' ,')

      return `${t('dropzone.error.file_invaild_type', { count: fileTypesList.length })} "${fileTypes}"`
    }
    case 'too-many-files':
      return `${t('dropzone.error.too_many_files', { count: maxFiles })}`
    default:
      return fileError.message
  }
}

export const decorateFilesWithCustomErrors = (
  fileRejections: FileRejection[],
  limitOptions: LimitOptions,
  t: TFunction,
): void => {
  for (let i = 0; i < fileRejections.length; i += 1) {
    const errors = fileRejections[i].errors

    for (let j = 0; j < errors.length; j += 1) {
      const error = fileRejections[i].errors[j]

      fileRejections[i].errors[j] = {
        code: error.code,
        message: getCustomMessage(error, limitOptions, t),
      }
    }
  }
}
