import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ErrorWrapper = styled.div`
  display: flex;
  margin-top: ${Spacing.XL};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const MessageWrapper = styled.div`
  display: flex;
  max-width: 270px;
  flex-direction: column;
  justify-content: center;
`
