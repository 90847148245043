import { ReactElement } from 'react'

import { OrganizationAccountingSettingsContextProvider } from './contexts/organizationAccountingSettingsContext'
import { AccountingSettingsForm } from './elements/AccountingSettingsForm'
import { OtherSettings } from './elements/OtherSettings'

export const OrganizationAccountingSettings = (): ReactElement => {
  return (
    <OrganizationAccountingSettingsContextProvider>
      <AccountingSettingsForm />
      <OtherSettings />
    </OrganizationAccountingSettingsContextProvider>
  )
}
