import React, { ReactElement, ReactNode } from 'react'

import { Color } from '../../../../enums/color'
import { Text } from '../../../Text'
import * as Styled from './styles'

interface ErrorInfoProps {
  children: ReactNode
}

export const ErrorInfo = ({ children }: ErrorInfoProps): ReactElement => (
  <Styled.ErrorInfoWrapper>
    <Text variant="small" color={Color.Red} inline>
      {children}
    </Text>
  </Styled.ErrorInfoWrapper>
)
