import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { IconName } from '../Icon'
import { SkeletonBox } from '../SkeletonBox'
import { PaymentPaneItem, PaymentPaneItemProps } from './elements/PaymentPaneItem'
import * as Styled from './styles'
import { PaymentPaneStatus } from './types/paymentPaneStatus'

export interface PaymentPaneProps {
  amount?: ReactNode
  children?: ReactNode
  content?: ReactNode
  collapsible?: boolean // determines if it has functionality to collapse/expand
  extra?: ReactNode
  icon?: IconName
  isCollapsed?: boolean // controlled collapse/expand state
  isLoading?: boolean
  prefix?: ReactNode
  status?: PaymentPaneStatus
  withHoverEffect?: boolean
}

export const PaymentPane = ({
  amount,
  children,
  content,
  collapsible = true,
  extra,
  icon,
  isLoading,
  isCollapsed: isCollapsedControlled = true,
  prefix,
  status,
  withHoverEffect = true,
}: PaymentPaneProps): ReactElement => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsedControlled)

  const handleClick = useCallback(() => {
    if (collapsible) {
      setIsCollapsed((prevICollapsed) => !prevICollapsed)
    }
  }, [collapsible])

  useEffect(() => {
    if (isCollapsedControlled !== undefined && isCollapsedControlled !== isCollapsed) {
      setIsCollapsed(isCollapsedControlled)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsedControlled])

  const chevron: IconName = isCollapsed ? 'chevronDown' : 'chevronUp'

  return (
    <Styled.PaymentPaneWrapper status={status} isCollapsed={isCollapsed} withShadow={!isLoading}>
      {isLoading ? (
        <SkeletonBox fullWidth height={64} />
      ) : (
        <>
          <PaymentPaneItem
            amount={amount}
            collapsible={collapsible}
            content={content}
            extra={extra}
            icon={collapsible ? chevron : icon}
            onClick={handleClick}
            prefix={prefix}
            withHoverEffect={collapsible && withHoverEffect}
          />
          {!isCollapsed && (
            <Styled.PaymentPaneItemsWrapper isCollapsed={isCollapsed}>
              {Children.map(
                children,
                (child) =>
                  isValidElement<PaymentPaneItemProps>(child) &&
                  cloneElement(child, {
                    withHoverEffect,
                  }),
              )}
            </Styled.PaymentPaneItemsWrapper>
          )}
        </>
      )}
    </Styled.PaymentPaneWrapper>
  )
}

PaymentPane.Item = PaymentPaneItem
