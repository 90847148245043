import React, { MouseEvent, ReactElement } from 'react'

import { CheckboxProps } from '../../../Checkbox'
import * as Styled from './styles'

const handleCheckboxWrapperClick = (event: MouseEvent<HTMLDivElement>) => {
  event.stopPropagation()
}

export const TableCheckbox = ({ checked, indeterminate, name, onChange, ...rest }: CheckboxProps): ReactElement => (
  <Styled.TableCheckboxWrapper {...rest} onClick={handleCheckboxWrapperClick}>
    <Styled.CheckboxWrapper checked={checked} indeterminate={indeterminate} name={name} onChange={onChange} />
  </Styled.TableCheckboxWrapper>
)
