import { BorderRadius, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

import ConnectBankIllustration from '../../../../../../assets/dashboard-connect-bank.svg'

const FULL_SIZE = '100%'

export const BankDisconnectionWrapper = styled.div`
  position: absolute;
  height: ${FULL_SIZE};
  width: ${FULL_SIZE};
  top: 0;
  left: 0;
  border-radius: ${BorderRadius.L};
  padding: ${Spacing.XL};
  background-image: url(${ConnectBankIllustration});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 70%;
`

export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  padding-left: ${Spacing.S};
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
`
export const Header = styled.div``

export const Title = styled(Text)`
  width: 100%;
  margin-top: ${Spacing.XXS};
`

export const DescriptionWrapper = styled.div`
  width: 70%;
`
