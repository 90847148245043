import { IconButton, Text } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'

import { usePeriodSelectContext } from '../../contexts/periodSelectContext'
import * as Styled from './styles'

export const PeriodNavigation = (): ReactElement => {
  const { navigationItem, navigate, canNavigate } = usePeriodSelectContext()

  const isLeftButtonDisabled = useMemo(() => !canNavigate('back'), [canNavigate])
  const isRightButtonDisabled = useMemo(() => !canNavigate('next'), [canNavigate])

  const handleLeftButtonClick = useCallback(() => {
    navigate('back')
  }, [navigate])

  const handleRightButtonClick = useCallback(() => {
    navigate('next')
  }, [navigate])

  return (
    <Styled.PeriodNavigationWrapper>
      <IconButton
        disabled={isLeftButtonDisabled}
        icon="chevronLeft"
        onClick={handleLeftButtonClick}
        size="m"
        variant="secondary"
      />
      {!!navigationItem?.children && (
        <Styled.NavigationLabelWrapper>
          <Text variant="large">{navigationItem?.children}</Text>
        </Styled.NavigationLabelWrapper>
      )}
      <IconButton
        disabled={isRightButtonDisabled}
        icon="chevronRight"
        onClick={handleRightButtonClick}
        size="m"
        variant="secondary"
      />
    </Styled.PeriodNavigationWrapper>
  )
}
