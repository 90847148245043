import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../../../utils'
import { updateVatAccount } from '../../../../../query-api'
import { TaxAccount } from '../../../../../types/taxAccount'

interface UseUpdateVatAccount {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface UpdateVatAccountProps {
  id: string
  payload: Partial<TaxAccount>
}

export const useUpdateVatAccount = (props?: UseUpdateVatAccount) => {
  const { onError, onSuccess } = props || {}

  const { mutate: update, ...rest } = useMutation(
    ({ id, payload }: UpdateVatAccountProps) => updateVatAccount(id, payload),
    {
      onError,
      onSuccess,
    },
  )

  return { update, ...rest }
}
