import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

export interface AlertsGroupProps {
  children: ReactNode
}

export const AlertsGroup = ({ children }: AlertsGroupProps): ReactElement => (
  <Styled.AlertsGroupList>
    {React.Children.map(children, (child) => (
      <Styled.AlertsGroupItem>{child}</Styled.AlertsGroupItem>
    ))}
  </Styled.AlertsGroupList>
)
