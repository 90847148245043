import { Color, SkeletonBox, Spacing, Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import { WidgetSize } from '../../enums/widgetSize'
import * as Styled from './styles'

interface WidgetProps {
  autoHeight?: boolean
  backgroundColor?: Color
  children: ReactNode
  className?: string
  isHidden: boolean
  isHighlighted?: boolean
  isLoading?: boolean
  sideActions?: ReactNode
  size?: WidgetSize
  subtitle?: ReactNode
  title?: ReactNode
}
export const Widget = ({
  autoHeight,
  backgroundColor = Color.White,
  children,
  className,
  isHidden,
  isHighlighted,
  isLoading = false,
  sideActions,
  size,
  title,
  subtitle,
}: WidgetProps): ReactElement => (
  <Styled.WidgetWrapper
    className={className}
    size={size}
    highlighted={isHighlighted}
    isHidden={isHidden}
    isLoading={isLoading}
    autoHeight={autoHeight}
    backgroundColor={backgroundColor}
  >
    {isLoading ? (
      <SkeletonBox fullWidth fullHeight />
    ) : (
      <>
        {(title || subtitle || (sideActions && !isHidden)) && (
          <Styled.Header>
            {(title || subtitle) && (
              <Styled.TitleComponentsGroup spacing={Spacing.S} centered={false}>
                {title && (
                  <Text variant="h3" truncate>
                    {title}
                  </Text>
                )}
                {subtitle && (
                  <Text colorVariant="secondary" variant="micro">
                    {subtitle}
                  </Text>
                )}
              </Styled.TitleComponentsGroup>
            )}
            {sideActions && !isHidden && <Styled.SideActions>{sideActions}</Styled.SideActions>}
          </Styled.Header>
        )}
        {children}
      </>
    )}
  </Styled.WidgetWrapper>
)
