import { ButtonDropdown, ButtonDropdownProps } from '@design-system'

import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PeriodMode } from '../../../../enums/periodMode'
import { ComparisonValue } from '../../types/comparisonValue'
import { getItems } from './utils/getItems'

export interface ReportsComparisonSelectProps
  extends Omit<ButtonDropdownProps<ComparisonValue>, 'items' | 'selectedId'> {
  selectedId?: ComparisonValue
  periodMode?: PeriodMode
  items?: ButtonDropdownProps<ComparisonValue>['items']
}

export const ReportsComparisonSelect = ({
  onSelect,
  periodMode,
  items: itemsControlled,
  selectedId: selectedIdControlled,
  ...buttonDropdownProps
}: ReportsComparisonSelectProps): ReactElement => {
  const [selectedId, setSelectedId] = useState(selectedIdControlled)
  const { t } = useTranslation()

  const items = useMemo(() => itemsControlled || getItems(t, periodMode), [itemsControlled, t, periodMode])

  useEffect(() => {
    const isSelectedItemAvaliable = !!items?.find((item) => item.id === selectedId)

    if (!isSelectedItemAvaliable && items?.length) {
      const firstItem = items[0]
      setSelectedId(firstItem.id as ComparisonValue)
      onSelect?.(firstItem.id, firstItem.value)
    }
  }, [items, onSelect])

  useEffect(() => {
    setSelectedId(selectedIdControlled)
  }, [selectedIdControlled])

  return (
    <ButtonDropdown
      {...buttonDropdownProps}
      disabled={buttonDropdownProps.disabled || !periodMode}
      items={items || []}
      onSelect={onSelect}
      placement="bottom-start"
      selectedId={selectedId}
      variant="secondary"
    />
  )
}
