import React from 'react'

import { useFormContext, useWatch } from '../../../../../hooks'
import { ContactType } from '../../enums/contactType'
import { ContactFormSchema } from '../../utils/formData'
import { SupplierCompanyItems } from './elements/SupplierCompanyItems'
import { SupplierPersonItems } from './elements/SupplierPersonItems'

export const SupplierForm = () => {
  const { control } = useFormContext<ContactFormSchema>()
  const contactType = useWatch({ control, name: 'contactType' })

  return contactType === ContactType.Company ? <SupplierCompanyItems /> : <SupplierPersonItems />
}
