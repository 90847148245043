import { BookkeepingTagNavItemValue } from '@components'
import { AttachmentFile } from '@design-system'

import { TFunction } from 'i18next'
import { v4 as uuidv4 } from 'uuid'
import { array, boolean, date, mixed, number, object, SchemaOf, string } from 'yup'

import { Account } from '@modules-deprecated/app/accounts/types'
import { TaxRate } from '@modules-deprecated/app/taxrates/types'
import { Contact } from '@views/contacts/types/contact'

import { TaxMode } from '../../../../../enums/taxMode'
import { FormWithAutoCompletedFields } from '../../../../../types/formWithAutoCompletedFields'
import { BillState } from '../../../enums/billState'
import { BillSubmitAction } from '../../../enums/billSubmitAction'

// bill form properties (tax rate and expense category) in the bill line are autocompleted by selecting bookkeeping tag for this line
// these autocompleted values have a highest priority against all other _autocomplete_ overwrites (e.g. by vendor defaults)
interface Lockable {
  lockedByBookkeepingTag?: boolean
}

export interface BillFormLine extends Lockable {
  bookkeepingTag: BookkeepingTagNavItemValue | undefined
  description: string
  expenseCategory: Partial<Account> | undefined
  inclVatAmount: number | undefined
  taxRate: Partial<TaxRate> | undefined
  // ML Booky unique identifier
  sessionId: string
  vatAmount: number | undefined
}

export type BillFormLineKeys = keyof BillFormLine

export interface BillFormBasic {
  attachmentsFiles: AttachmentFile[] | undefined
  attachmentsFilesChosen: AttachmentFile[] | undefined
  billDate: Date | undefined
  billLines: BillFormLine[] | undefined
  bookkeepingTagSuggestions: BookkeepingTagNavItemValue[] | undefined
  creditedBillId: string | undefined
  currencyId: string | undefined
  dueDate: Date | undefined
  paymentAccount: Partial<Account> | undefined
  paymentDate: Date | undefined
  referenceNumber: string | undefined
  state: BillState | undefined
  taxMode: TaxMode | undefined
  vendor: Partial<Contact> | undefined
  voucherNo: string | undefined
}

// Has to be a function as react-hook-form for some reason mutates the default objects
// https://github.com/react-hook-form/react-hook-form/issues/8071 (it's resolved, but somehow still occurs)
export const getDefaultBillLine = (): BillFormLine => ({
  bookkeepingTag: undefined,
  description: '',
  expenseCategory: undefined,
  inclVatAmount: 0,
  taxRate: undefined,
  sessionId: uuidv4(),
  vatAmount: 0,
})

// Extend with helper props
export interface BillForm extends BillFormBasic, FormWithAutoCompletedFields<BillFormBasic> {
  submitAction: BillSubmitAction | undefined
}

export interface BillFormPartial extends Partial<Omit<BillForm, 'billLines'>> {
  billLines?: Partial<BillFormLine>[]
}

// Has to be a function as react-hook-form for some reason mutates the default objects
// https://github.com/react-hook-form/react-hook-form/issues/8071 (it's resolved, but somehow still occurs)
export const getDefaultValues = (baseCurrencyId?: string): BillForm => ({
  attachmentsFiles: [],
  attachmentsFilesChosen: [],
  autoCompletedFields: undefined,
  billDate: undefined,
  billLines: [getDefaultBillLine()],
  bookkeepingTagSuggestions: undefined,
  creditedBillId: undefined,
  currencyId: baseCurrencyId,
  dueDate: undefined,
  paymentAccount: undefined,
  paymentDate: undefined,
  referenceNumber: undefined,
  state: undefined,
  submitAction: undefined,
  taxMode: TaxMode.Including,
  vendor: undefined,
  voucherNo: undefined,
})

export type BillFormSchema = SchemaOf<BillForm>

// More info about this syntax:
// https://github.com/jquense/yup#schemawhenkeys-string--string-builder-object--values-any-schema--schema-schema
const isBillApproveAction = (submitAction?: BillSubmitAction) =>
  submitAction === BillSubmitAction.Approve || submitAction === BillSubmitAction.ApproveBulk

const attachmentFileSchema: SchemaOf<Partial<AttachmentFile>> = object({
  attachmentId: string(),
  // oneOf does not respect nullable(), thus we need to include null as an option
  // vide: https://github.com/jquense/yup/issues/104#issuecomment-352260184
  attachmentType: mixed().oneOf(['BillyCustomerInvoice', 'EDocument', 'EmailAttachment', 'ApiInvoiceAttachment', null]),
  createdTime: string().nullable(),
  dataJson: string().nullable(),
  downloadUrl: string(),
  fileName: string().nullable(),
  fileSize: number().nullable(),
  fileType: string().nullable(),
  id: string(),
  imageHeight: number().nullable(),
  imageWidth: number().nullable(),
  isImage: boolean().nullable(),
  isPdf: boolean().nullable(),
  originalUrl: string(),
  thumbnailOfId: array().nullable(),
  variants: array().nullable(),
})

export const getValidationSchema = (t: TFunction, isReferenceNumberError: boolean): BillFormSchema =>
  object({
    attachmentsFiles: array().of(attachmentFileSchema).notRequired(),
    attachmentsFilesChosen: array().of(attachmentFileSchema).notRequired(),
    creditedBillId: string(),
    billDate: date().when('submitAction', {
      is: isBillApproveAction,
      then: date()
        .when(
          'paymentDate',
          (paymentDate, yup) =>
            paymentDate && yup.max(paymentDate, t('bill.edit.form.validate.bill_date_after_payment_date')),
        )
        .required(t('bill.edit.form.validate.bill_date_required')),
      otherwise: date().when(
        'paymentDate',
        (paymentDate, yup) =>
          paymentDate && yup.max(paymentDate, t('bill.edit.form.validate.bill_date_after_payment_date')),
      ),
    }),
    billLines: array().when('submitAction', {
      // checking parent properties issue: https://github.com/jquense/yup/issues/225#issuecomment-404335633
      is: isBillApproveAction,
      then: array().of(
        object({
          bookkeepingTag: object(),
          description: string().when('bookkeepingTag', {
            is: (bookkeepingTag: BookkeepingTagNavItemValue | undefined) => bookkeepingTag?.response,
            then: string(),
            otherwise: string().required(t('bill.edit.form.validate.description_required')),
          }),
          expenseCategory: object({
            id: string().required(t('bill.edit.form.validate.expense_category_required')),
          })
            .required(t('bill.edit.form.validate.expense_category_required'))
            .typeError(t('bill.edit.form.validate.expense_category_type')),
          taxRate: object({
            id: string().required(t('bill.edit.form.validate.tax_rate_required')),
          })
            .required(t('bill.edit.form.validate.tax_rate_required'))
            .typeError(t('bill.edit.form.validate.tax_rate_type')),
          vatAmount: number().required(),
          inclVatAmount: number().required(),
        }),
      ),
      otherwise: array().of(
        object({
          bookkeepingTag: object().notRequired().default(undefined),
          description: string(),
          expenseCategory: object({
            id: string(),
          })
            .notRequired()
            .default(undefined)
            .typeError(t('bill.edit.form.validate.expense_category_type')),
          taxRate: object({
            id: string(),
          })
            .notRequired()
            .default(undefined)
            .typeError(t('bill.edit.form.validate.tax_rate_type')),
          vatAmount: number(),
          inclVatAmount: number(),
        }),
      ),
    }),
    currencyId: string(),
    dueDate: date().when(
      'billDate',
      (billDate, yup) => billDate && yup.min(billDate, t('bill.edit.form.validate.due_date_before_bill_date')),
    ),
    referenceNumber: string().when('submitAction', {
      is: isBillApproveAction,
      then: string().test(
        'testReferenceNumber',
        t('bill.edit.form.reference_number.error'),
        (value: string | undefined) => {
          if (value?.length && isReferenceNumberError) {
            return false
          }
          return true
        },
      ),
    }),
    submitAction: mixed<BillSubmitAction>().oneOf(Object.values(BillSubmitAction)),
    vendor: object().when('submitAction', {
      // checking parent properties issue: https://github.com/jquense/yup/issues/225#issuecomment-404335633
      is: isBillApproveAction,
      then: object({
        id: string().required(t('bill.edit.form.validate.vendor_required')),
      })
        .required(t('bill.edit.form.validate.vendor_required'))
        .typeError(t('bill.edit.form.validate.vendor_type')),

      otherwise: object({
        id: string(),
      }).typeError(t('bill.edit.form.validate.vendor_type')),
    }),
    paymentAccount: object({
      id: string(),
    }).typeError(t('required_field')),
    autoCompletedFields: object(),
    paymentDate: date(),
    state: mixed<BillState>().oneOf(Object.values(BillState)),
    taxMode: mixed<TaxMode>().oneOf(Object.values(TaxMode)),
    voucherNo: string(),
    bookkeepingTagSuggestions: array().of(object()).notRequired(),
  })
