import { Flex, Link, Space, Text } from '@design-system'

import { Fragment, ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Benefit } from '../../types/benefit'
import { getBenefits } from '../../utils/getBenefits'
import { HighlightedIcon } from '../HighlightedIcon'

export const BenefitsList = (): ReactElement => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  const getLinkUrl = (benefit: Benefit) => {
    if (locale === 'da') {
      return benefit.linkUrlDK
    }
    if (locale === 'en') {
      return benefit.linkUrlEN
    }
    if (locale === 'fr') {
      return benefit.linkUrlFR
    }
    return null
  }

  return (
    <>
      {getBenefits().map((benefit, index) => {
        const linkUrl = getLinkUrl(benefit)
        return (
          <Fragment key={index}>
            <Flex alignItems="start">
              <HighlightedIcon>{benefit.icon}</HighlightedIcon>
              <Flex flexDirection="column">
                <Text colorVariant="primary" variant="large" weight="bold">
                  {t(benefit.titleTKey)}
                </Text>
                {linkUrl ? (
                  <Text colorVariant="secondary" weight="regular">
                    <Trans
                      defaults={t(benefit.descriptionTKey)}
                      components={[<Link href={linkUrl} target="_blank" />]}
                    />
                  </Text>
                ) : (
                  <Text colorVariant="secondary" weight="regular">
                    {t(benefit.descriptionTKey)}
                  </Text>
                )}
              </Flex>
            </Flex>
            <Space size="xl" />
          </Fragment>
        )
      })}
    </>
  )
}
