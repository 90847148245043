import { amountToDisplayValue } from '@design-system'

interface GetBillAmountToDisplayValueOptions {
  amount: number | string
  currency: string
  isCreditNote: boolean
}

export const getBillAmountToDisplayValue = ({ amount, currency, isCreditNote }: GetBillAmountToDisplayValueOptions) => {
  const correctAmount = isCreditNote ? `-${amount}` : amount

  return `${amountToDisplayValue(correctAmount)} ${currency}`
}
