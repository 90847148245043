import { withModalConditionalRender } from '@design-system'

import { ReactElement, useEffect } from 'react'

import { ModalId } from '../../../../../../enums/modalId'
import { useSegment } from '../../../../../../hooks'
import { SyncErrorTopBarContextProvider } from './contexts/syncErrorTopBarContext'
import { SkipAndCompleteSyncModal } from './elements/SkipAndCompleteSyncModal'
import { SyncErrorTopBarContent } from './elements/SyncErrorTopBarContent'
import { UnrecognizedVatRatesModal } from './elements/UnrecognizedVatRatesModal'

const UnrecognizedVatRatesModalConditional = withModalConditionalRender(UnrecognizedVatRatesModal)
const SkipAndCompleteSyncModalConditional = withModalConditionalRender(SkipAndCompleteSyncModal)

export const SyncErrorTopBar = (): ReactElement => {
  const { track } = useSegment()

  useEffect(() => {
    track('xxx-sales-HistoricalSync-Top Bar updated', {
      topBarType: 'error',
    })
  }, [])

  return (
    <SyncErrorTopBarContextProvider>
      <SyncErrorTopBarContent />
      <UnrecognizedVatRatesModalConditional id={ModalId.UnrecognizedVatRatesModal} />
      <SkipAndCompleteSyncModalConditional id={ModalId.SkipAndCompleteSyncModal} />
    </SyncErrorTopBarContextProvider>
  )
}
