import { Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

export interface SettingsSectionContentHeaderProps {
  sideActions?: ReactNode
  subtitle?: string
  title: string
  onClick?: () => void
}

export const SettingsSectionContentHeader = ({
  sideActions,
  subtitle,
  title,
  onClick,
}: SettingsSectionContentHeaderProps): ReactElement => (
  <Styled.ContentHeaderWrapper onClick={onClick} clickable={!!onClick}>
    <Styled.TitleAndSubtitleWrapper>
      <Text variant="h2">{title}</Text>
      {subtitle && <Text colorVariant="secondary">{subtitle}</Text>}
    </Styled.TitleAndSubtitleWrapper>
    {sideActions && <Styled.SideActionsWrapper>{sideActions}</Styled.SideActionsWrapper>}
  </Styled.ContentHeaderWrapper>
)
