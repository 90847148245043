import { useFetchRecurringInvoices } from './useFetchRecurringInvoices'
import { useRecurringInvoicesListFilters } from './useRecurringInvoicesListFilters'

export const useRecurringInvoices = () => {
  const [{ searchQuery, sort }] = useRecurringInvoicesListFilters()

  const { recurringInvoices, contacts, isLoading } = useFetchRecurringInvoices({ searchQuery, sort })

  return {
    recurringInvoices,
    contacts,
    isLoading,
  }
}
