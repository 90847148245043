import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const OrganizationNotificationSettingsWrapper = styled.div`
  border: 1px solid ${Color.Gray40};
  border-radius: ${DefaultValue.BorderRadius};
  margin-bottom: ${Spacing.XXL};
`
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${Spacing.L} ${Spacing.XL};
  cursor: pointer;
`

export const HeaderFigure = styled.figure`
  height: 32px;
`

export const IconWrapper = styled.img`
  max-height: 32px;
  width: 32px;
  border-radius: ${DefaultValue.BorderRadius};
`

export const TextWrapper = styled.div`
  padding-left: ${Spacing.L};
  flex-grow: 4;
`
