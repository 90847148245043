import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { SendInvoiceModal } from '@views/invoices/elements/SendInvoiceModal'

import { queryClient } from '../../config/queryClient'
import { EmberCloseModalContextProvider, EmberModal } from '../../contexts/emberCloseModalContext'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

class SendInvoiceModalWebComponent extends DOMModel {
  @byAttrVal contactCVR: string
  @byAttrVal contactEAN: string
  @byAttrVal contactId: string
  @byAttrVal emailMessage: string
  @byAttrVal emailSubject: string
  @byAttrVal invoiceId: string
  @byAttrVal organizationId: string
  @byAttrVal selectedContactPersonId: string
  @byAttrVal sendAsEInvoice: string
  @registerEvent('onInvoiceSent') onInvoiceSent: EmberEventFn
  @registerEvent('closeEmber') closeEmber: Function
  @registerEvent('navigate') navigate: Function | undefined
}

type SendInvoiceModalProps = {
  contactCVR?: string
  contactEAN?: string
  contactId: string
  emailMessage: string
  emailSubject: string
  invoiceId: string
  organizationId: string
  selectedContactPersonId: string
  sendAsEInvoice: 'true' | 'false'
  onInvoiceSent: EmberEventFn
  onCloseEmber: EmberModal
  onNavigate: EmberNavigate
}

function SendInvoiceModalModule({
  contactEAN,
  contactId,
  emailMessage,
  emailSubject,
  invoiceId,
  organizationId,
  selectedContactPersonId,
  sendAsEInvoice,
  onInvoiceSent,
  onCloseEmber,
  onNavigate,
}: SendInvoiceModalProps): ReactElement {
  const isSendingAsEInvoice = sendAsEInvoice === 'true'
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <EmberRouterContextProvider onNavigate={onNavigate}>
          <EmberCloseModalContextProvider onCloseModal={onCloseEmber}>
            <UserOrganizationContextProvider organizationId={organizationId}>
              <UserOrganizationSettingsContextProvider organizationId={organizationId}>
                <CurrentUserContextProvider>
                  <ModalContextProvider>
                    <SendInvoiceModal
                      contactEAN={contactEAN}
                      contactId={contactId}
                      emailMessage={emailMessage}
                      emailSubject={emailSubject}
                      invoiceId={invoiceId}
                      selectedContactPersonId={selectedContactPersonId}
                      sendAsEInvoice={isSendingAsEInvoice}
                      onInvoiceSent={onInvoiceSent}
                    />
                  </ModalContextProvider>
                </CurrentUserContextProvider>
              </UserOrganizationSettingsContextProvider>
            </UserOrganizationContextProvider>
          </EmberCloseModalContextProvider>
        </EmberRouterContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const SendInvoiceModalCustomElement = createCustomElement(
  SendInvoiceModalModule,
  SendInvoiceModalWebComponent,
  'element',
)

customElements.get('send-invoice-modal-module') ||
  customElements.define('send-invoice-modal-module', SendInvoiceModalCustomElement)

export default SendInvoiceModalCustomElement
