export const mapValues = (obj: object, fn: string | Function) =>
  Object.keys(obj).reduce((res: object, key: string) => {
    res[key] = typeof fn === 'string' ? obj[key][fn] : fn(obj[key])
    return res
  }, {})

export const keyBy = (arr: any[], prop: string) =>
  arr.reduce((res: object, item: object) => {
    res[item[prop]] = item
    return res
  }, {})

export const convertObjectSnakeCaseToCamelCase = (obj: {}): object => {
  const keys = Object.keys(obj)
  let res = {}
  for (const k in keys) {
    let value = obj[keys[k]]
    if (obj[keys[k]] && typeof obj[keys[k]] === 'object') {
      value = convertObjectSnakeCaseToCamelCase(obj[keys[k]])
    }
    if (typeof keys[k] === 'string') {
      const newKey = snakeToCamel(keys[k])
      res = { ...res, [newKey]: value }
    }
  }
  return res
}

export const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-z0-9])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))

export const removeEmptyKeys = (obj: object): object =>
  Object.keys(obj)
    .filter((k) => obj[k] !== null && obj[k] !== undefined)
    .reduce(
      (newObj, k) =>
        typeof obj[k] === 'object' ? { ...newObj, [k]: removeEmptyKeys(obj[k]) } : { ...newObj, [k]: obj[k] },
      {},
    )
