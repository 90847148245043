interface OrganizationOwnersSectionTKeys {
  titleTKey: string
  subtitleTKey: string
  buttonLabelTKey: string
  addTaxtoAddOwnersTKey: string
}

export const getOrganizationOwnersSectionTKeys = (isProprietorship: boolean): OrganizationOwnersSectionTKeys => ({
  titleTKey: isProprietorship
    ? 'settings.organization.organization_info.proprietors.title'
    : 'settings.organization.organization_info.owners.title',
  subtitleTKey: isProprietorship
    ? 'settings.organization.organization_info.proprietors.subtitle'
    : 'settings.organization.organization_info.owners.subtitle',
  buttonLabelTKey: isProprietorship
    ? 'settings.organization.organization_info.proprietors.button_add'
    : 'settings.organization.organization_info.owners.button_add',
  addTaxtoAddOwnersTKey: isProprietorship
    ? 'settings.organization.organization_info.proprietors.add_tax_no_to_add_proprietor'
    : 'settings.organization.organization_info.owners.add_tax_no_to_add_owner',
})
