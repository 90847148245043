import { Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface ReceiptInfoProps {
  title: ReactNode
  children: ReactNode
  fitToHeight?: boolean
}

export const ReceiptInfo = ({ title, children, fitToHeight = false }: ReceiptInfoProps): ReactElement => (
  <Styled.ReceiptInfoWrapper fitToHeight={fitToHeight}>
    <Text variant="micro" colorVariant="secondary">
      {title}
    </Text>
    {children}
  </Styled.ReceiptInfoWrapper>
)
