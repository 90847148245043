import { getFileName } from '../../../../utils/getFileName'
import { Flex } from '../../../Flex'
import * as Styled from './styles'

interface FileNameProps {
  file: string
}

export const FileName = ({ file }: FileNameProps) => {
  const fileName = getFileName(file)

  return (
    <Styled.FileNameWrapper title={fileName}>
      <Flex>
        <Styled.FileName>{getFileName(file)}</Styled.FileName>
      </Flex>
    </Styled.FileNameWrapper>
  )
}
