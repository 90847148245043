import { Button, Modal, ModalProps, Text, useModalMultiple } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../enums/modalId'

export const RemovedBankConnectionModal = (props: ModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeModal } = useModalMultiple([ModalId.BankConnectionRemovedModal, ModalId.BankSettingsModal])

  const handleCloseModal = useCallback(() => {
    closeModal(ModalId.BankSettingsModal)
    closeModal(ModalId.BankConnectionRemovedModal)
  }, [closeModal])

  return (
    <Modal size="s" {...props}>
      <Modal.Header>
        <Text variant="h2">{t('bank_integration.connection_removed_success_header')}</Text>
      </Modal.Header>
      <Modal.Body>
        <Text>{t('bank_integration.connection_removed_success_message')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseModal} variant="primary">
          {t('bank_integration.close_modal')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
