import { Badge } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { IntegrationStatus } from '../../../../enums/integrationStatus'
import { integrationStatusToBadgeProps } from './utils/getIntegrationStatusLabelProps'

interface IntegrationStatusLabelProps {
  status?: IntegrationStatus | null
  isStatusLoading: boolean
}

export const IntegrationStatusLabel = ({
  status,
  isStatusLoading,
}: IntegrationStatusLabelProps): ReactElement | null => {
  const { t } = useTranslation()

  if (isStatusLoading || !status) {
    return null
  }

  const badgeProp = integrationStatusToBadgeProps[status]

  if (!badgeProp) {
    return null
  }

  return <Badge variant={badgeProp.variant}>{t(badgeProp.tKey)}</Badge>
}
