import React, { ReactElement, ReactNode, useEffect } from 'react'
import { RouteChildrenProps } from 'react-router-dom'

import { Scope } from '../../../enums/scope'
import { isAuthorized } from '../../../utils/isAuthorized'

export interface ProtectableProps {
  scopes: Scope | Scope[]
  scopesCheckDisabled?: boolean
}

export interface ProtectableComponentProps extends ProtectableProps {
  unauthorizedNode?: ReactNode
  onUnauthorized?: () => void
}

export interface ProtectedComponentProps extends ProtectableComponentProps {
  children?: ((props: RouteChildrenProps) => React.ReactNode) | React.ReactNode | undefined
}

export const ProtectedComponent = ({
  children,
  onUnauthorized,
  scopes,
  scopesCheckDisabled,
  unauthorizedNode = null,
}: ProtectedComponentProps): ReactElement | null => {
  const isUnauthorized = !isAuthorized(scopes)

  useEffect(() => {
    if (!scopesCheckDisabled && isUnauthorized) {
      onUnauthorized?.()
    }
  }, [isUnauthorized, onUnauthorized, scopesCheckDisabled])

  if (scopesCheckDisabled) {
    return <>{children}</>
  }

  if (isUnauthorized) {
    return <>{unauthorizedNode}</>
  }

  return <>{children}</>
}
