import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'

import { getAnimateRotationStyle } from '../../../utils/getAnimateRotationStyle'
import { Size } from '../types'
import { Position } from './types'
import { getIconPositionStyle } from './utils'

interface IconWrapperProps {
  loading?: boolean
  position: Position
  size: Size
  withIconMargin?: boolean
  visible: boolean
}

export const IconWrapper = styled('figure', {
  shouldForwardProp: (propName: string) => isPropValid(propName) && propName !== 'loading',
})<IconWrapperProps>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  align-items: center;

  ${({ loading }) => loading && getAnimateRotationStyle()};
  ${({ position, size, withIconMargin }) => getIconPositionStyle(position, size, withIconMargin)};
`
