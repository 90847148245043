import { css, SerializedStyles } from '@emotion/core'
import cc from 'classcat'
import { useTheme } from 'emotion-theming'
import React, { useEffect, useRef, useState } from 'react'
import { Box, BoxProps } from 'rebass'

import { useOnClickOutside } from '../../hooks'
import { coloring } from '../../styles-deprecated'
import { Theme } from '../../types/theme'
import { reactClass } from '../../utils'

export type PopOverProps = BoxProps & {
  css?: SerializedStyles
  isOpen?: boolean
  onClose?: () => void
  required?: boolean
}

export const PopOver = ({ children, className, css: styles, isOpen, onClose, required, ...rest }: PopOverProps) => {
  const [open, setOpen] = useState(isOpen)

  const {
    transitions: { easeOut, fast, linear },
    zIndex,
  } = useTheme<Theme>()

  const handleClose = () => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }

  const modalRef = useRef<HTMLDivElement | null>(null)

  useOnClickOutside(modalRef, handleClose, { disabled: required })

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <Box
      className={cc([className, { open }, reactClass('pop-over')])}
      ref={modalRef}
      css={[
        css`
          padding: 15px;
          max-width: 250px;
          min-width: 200px;
          height: auto;
          border: 1px solid ${coloring('defaultBorderColor')};
          border-radius: 4px;
          background-color: ${coloring('white')};
          box-shadow: 0px 1px 20px 0px ${coloring('shade15')};
          transition:
            transform ${fast} ${easeOut},
            opacity ${fast} ${easeOut},
            filter ${fast} ${linear};
          transform: scale(0.5);
          opacity: 0;
          z-index: ${zIndex.modal};
          &.open {
            transform: scale(1);
            opacity: 1;
          }
        `,
        styles,
      ]}
      {...rest}
    >
      {children}
    </Box>
  )
}
