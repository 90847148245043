import { ModalId } from '../../../../../enums/modalId'
import { useWarningModals } from '../../../../../hooks/useWarningModals'
import { AccountingBasisConfirmationModal } from '../elements/AccountingBasisConfirmationModal'
import { VATConfirmationModal } from '../elements/VATConfirmationModal'
import { OrganizationAccountingForm } from '../utils/organizationAccountingSettingsFormData'

export const useOrganizationAccountingWarningModals = () => {
  return useWarningModals<OrganizationAccountingForm>({
    warnings: {
      [ModalId.AccountingBasisConfirmationModal]: 'accountingBasis',
      [ModalId.VATConfirmationModal]: 'isVatExempted',
    },
    modals: (
      <>
        <AccountingBasisConfirmationModal id={ModalId.AccountingBasisConfirmationModal} />
        <VATConfirmationModal id={ModalId.VATConfirmationModal} />
      </>
    ),
  })
}
