import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface PaneItemChildProps {
  children?: ReactNode
}

export const PaneItemChild = ({ children }: PaneItemChildProps): ReactElement => {
  return <Styled.PaneItemChild>{children}</Styled.PaneItemChild>
}
