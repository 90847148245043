import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../Button'

interface PaginationPageButtonProps {
  isActive?: boolean
  onClick: (page: number) => void
  page: number
}

export const PaginationPageButton = ({
  isActive = false,
  onClick,
  page,
  ...rest
}: PaginationPageButtonProps): ReactElement => {
  const { t } = useTranslation()

  const handleClick = useCallback(() => {
    if (!isActive) {
      onClick(page)
    }
  }, [isActive, onClick, page])

  return (
    <Button
      {...rest}
      active={isActive}
      forceSquareRatio
      fullRound
      onClick={handleClick}
      size="m"
      title={t('pagination.page', { count: page })}
      variant="text"
    >
      {page}
    </Button>
  )
}
