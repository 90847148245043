import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ImageColumnWrapper = styled.div`
  width: 50px;
  margin-right: ${Spacing.XL};
  align-self: center;
  flex-shrink: 0;
`
