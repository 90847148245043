export enum InvoicesTableAccessor {
  Customer = 'contact.name',
  Date = 'entryDate',
  Description = 'lineDescription',
  DueDate = 'dueDate',
  EmailStatus = 'emailStatus',
  OrderNumber = 'orderNo',
  ExcludedVat = 'amount',
  IncludedVat = 'grossAmount',
  Balance = 'balance',
  InvoiceNo = 'invoiceNo',
  Status = 'status',
}
