import { Divider, Flex, SkeletonText, Space, Text } from '@design-system'

import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment } from '../../../../../../hooks'
import { BillSummary } from '../BillSummary'
import { usePrepaymentModalDisplayValues } from './hooks/usePrepaymentModalDisplayValues'

export const PrepaymentModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const {
    contactName,
    creditBalanceColorVariant,
    displayedBillsAmount,
    displayedContactBalanceAmount,
    displayedCreditBalance,
  } = usePrepaymentModalDisplayValues()

  useEffect(() => {
    track('xxx - expenses - Prepayment modal opened')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <BillSummary />
      <Space />
      <Divider />
      <Space />
      {!!displayedContactBalanceAmount && !!contactName ? (
        <Text>
          {t('bill.payment_reconcile.prepayment.you_have_credit', {
            amount: displayedContactBalanceAmount,
            name: contactName,
          })}
        </Text>
      ) : (
        <SkeletonText />
      )}
      <Space />
      <Flex justifyContent="space-between">
        <Text variant="small" colorVariant="secondary">
          {t('bill.payment_reconcile.bill_amount')}
        </Text>
        {displayedBillsAmount ? (
          <Text variant="small" colorVariant="secondary">
            {displayedBillsAmount}
          </Text>
        ) : (
          <SkeletonText variant="small" />
        )}
      </Flex>
      <Space size="xxs" />
      <Flex justifyContent="space-between">
        <Text variant="small" colorVariant="secondary">
          {t('bill.payment_reconcile.prepayment.previous_credit')}
        </Text>
        {displayedContactBalanceAmount ? (
          <Text variant="small" colorVariant="secondary">
            {displayedContactBalanceAmount}
          </Text>
        ) : (
          <SkeletonText variant="small" />
        )}
      </Flex>
      <Space size="xxs" />
      <Divider />
      <Space />
      <Flex justifyContent="space-between">
        <Text variant="h3">{t('bill.payment_reconcile.prepayment.credit_balance')}</Text>
        {displayedCreditBalance ? (
          <Text variant="h3" colorVariant={creditBalanceColorVariant}>
            {displayedCreditBalance}
          </Text>
        ) : (
          <SkeletonText variant="h3" />
        )}
      </Flex>
    </>
  )
}
