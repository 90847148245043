import React, { ReactElement } from 'react'

import { Organization } from '@modules-deprecated/app/organization/types/organization'

import { Copyable } from '../../../../Copyable'
import { Translate } from '../../../../Translate'
import * as Styled from './styles'

interface AccountInfoProps {
  organization: Organization
}

export const AccountInfo = ({ organization }: AccountInfoProps): ReactElement => (
  <Styled.AccountInfoWrapper>
    <Styled.AccountInfoText alignment="center" title={organization.url} colorVariant="secondary">
      <Translate value="menu.footer.account_id" />
      <Copyable valueToCopy={organization.url}>
        <Styled.OrganizationNameText>{organization.url}</Styled.OrganizationNameText>
      </Copyable>
    </Styled.AccountInfoText>
  </Styled.AccountInfoWrapper>
)
