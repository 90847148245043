import { addMonths, endOfMonth } from 'date-fns'

interface StartEndDateByQuarter {
  startDate: Date
  endDate: Date
}

export const getStartEndDateByQuarter = (year?: number, quarter?: number): StartEndDateByQuarter => {
  const startOfYear = new Date(year || new Date().getFullYear(), 0, 1)
  const startDate = addMonths(startOfYear, (quarter || 0) * 3)
  const endDate = endOfMonth(addMonths(startDate, 2))

  return {
    startDate,
    endDate,
  }
}
