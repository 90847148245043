import { Flex } from '@design-system'

import { ReactElement, ReactNode } from 'react'

interface CardFooterProps {
  children: ReactNode
}

export const CardFooter = ({ children }: CardFooterProps): ReactElement => (
  <Flex alignItems="center" justifyContent="space-between">
    {children}
  </Flex>
)
