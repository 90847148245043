import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const FormWrapper = styled.div`
  width: 100%;
  border-radius: ${DefaultValue.BorderRadius};
  padding: ${Spacing.L};
  background-color: ${Color.White};
  box-shadow: 0 2px 1px ${Color.Gray30};
`
