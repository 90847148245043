import { addDays, subDays } from 'date-fns'
import { stringify } from 'qs'

import { formatDate } from '../../../utils'
import { APIResponse, getRequest } from '../../../utils/api'
import { BankLine } from '../types'

const QUERY_PARAM_DATE_FORMAT = 'yyyy-MM-dd'

type GenericQueryParams = {
  minEntryDate?: Date
  maxEntryDate?: Date
  minAmount?: number
  maxAmount?: number
}

type BankLineQueryParams = GenericQueryParams

type QueryParams = GenericQueryParams & {
  accountId?: string
  isReconciled?: boolean
}

const getFormattedDate = (date?: Date) => date && formatDate(date, QUERY_PARAM_DATE_FORMAT)

const getFormattedQueryParams = ({ minEntryDate, maxEntryDate, ...rest }: QueryParams) =>
  stringify({
    ...(minEntryDate ? { minEntryDate: getFormattedDate(minEntryDate) } : {}),
    ...(maxEntryDate ? { maxEntryDate: getFormattedDate(maxEntryDate) } : {}),
    ...rest,
  })

export const getBankLines = async (
  accountId: string,
  { minEntryDate, maxEntryDate, minAmount, maxAmount }: BankLineQueryParams,
  isReconciled = false,
): Promise<BankLine[]> => {
  const queryParams = getFormattedQueryParams({
    accountId,
    minEntryDate,
    maxEntryDate,
    isReconciled,
    minAmount,
    maxAmount,
  })

  try {
    const bankLinesResult: APIResponse<BankLine> = await getRequest('/v2/bankLines/?' + queryParams)

    return bankLinesResult.bankLines
  } catch (error) {
    console.error('Error fetching bank lines')
    return []
  }
}

export const getBankLinesSuggestions = async (
  accountId: string,
  date: Date,
  daySpan = 2,
  minAmount = 0,
  maxAmount = 0,
): Promise<BankLine[]> => {
  const minEntryDate = subDays(date, daySpan)
  const maxEntryDate = addDays(date, daySpan)

  try {
    const bankLines = await getBankLines(accountId, {
      minEntryDate,
      maxEntryDate,
      minAmount,
      maxAmount,
    })

    return bankLines
  } catch (error) {
    console.error('Error fetching bank line suggestions')
  }

  return []
}
