import { TabsGroup } from '@design-system'

import React, { ReactNode, useCallback, useState } from 'react'

import { ActionGroupItemProps } from './ActionGroupItem'

export type ActionData = { [key: string]: any }

export interface ActionGroupProps {
  children: ReactNode
  onSelectAction: (value: string, data?: ActionData) => void
  value?: string
}

export const ActionGroup = ({ children, onSelectAction, ...rest }: ActionGroupProps) => {
  const pick = Array.prototype.slice
    .call(children)
    .find((child: React.ReactElement<ActionGroupItemProps>) => child.props.selected)
  const [selected, setSelected] = useState(pick ? pick.props.value : '') as [string, (value: string) => void]

  const handleSelect = useCallback(
    (value: string, data?: ActionData) => () => {
      setSelected(value)
      onSelectAction(value, data)
    },
    [onSelectAction, setSelected],
  )

  return (
    <TabsGroup>
      {React.Children.map(
        children as React.ReactElement<ActionGroupItemProps>[],
        (child: React.ReactElement<ActionGroupItemProps>): JSX.Element | null =>
          React.isValidElement(child)
            ? React.cloneElement(child, {
                onClick: (data?: ActionData) => handleSelect(child.props.value, data)(),
                selected: selected === child.props.value,
              })
            : null,
      )}
    </TabsGroup>
  )
}
