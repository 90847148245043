import { Flex, Space, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as FrodaLogo } from '../../../../../assets/images/lending/froda-logo.svg'

export const FrodaFinanceLendingHeader = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <>
      <Text colorVariant="primary" variant="h1" weight="bold">
        {t('financing.froda_lending.title')}
      </Text>
      <Flex alignItems="center">
        <Text colorVariant="secondary" weight="medium">
          {t('financing.froda_lending.description')}
        </Text>
        <Space size="xs" horizontal />
        <FrodaLogo />
      </Flex>
    </>
  )
}
