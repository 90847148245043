import { Badge, Button, IconButtonProps } from '@design-system'

import React, { ReactElement } from 'react'

import * as Styled from './styles'

interface TriggerIconButtonProps extends Omit<IconButtonProps, 'icon'> {
  icon?: IconButtonProps['icon']
  count?: number
}

export const TriggerIconButton = ({ count, icon, ...rest }: TriggerIconButtonProps): ReactElement => {
  return (
    <Button variant="secondary" icon={icon || 'documentWithPencil'} {...rest}>
      {count ? (
        <Styled.BadgeWrapper>
          <Badge>{count}</Badge>
        </Styled.BadgeWrapper>
      ) : undefined}
    </Button>
  )
}
