import { KeyboardEvent } from 'react'

import { SelectItem } from '../Select'

export type MenuItemEventHandlerProps<T> = {
  input: HTMLInputElement | null
  items?: T[]
  onActiveChange: (item: T) => void
  onSelect: (item: T) => void
}

export class MenuItemEventHandler<T extends SelectItem> {
  activeIndex: number
  items?: T[]
  input: HTMLInputElement | null
  onActiveChange: (item: T) => void
  onSelect: (item: T) => void

  constructor({ input, items, onActiveChange, onSelect }: MenuItemEventHandlerProps<T>) {
    this.activeIndex = -1
    this.input = input
    this.items = items
    this.onActiveChange = onActiveChange
    this.onSelect = onSelect
  }

  handleKeyDown({ key }: KeyboardEvent<HTMLDivElement>) {
    if (!this.items) {
      return
    }

    switch (key) {
      case 'Enter':
        if (this.items[this.activeIndex]) {
          this.onSelect(this.items[this.activeIndex])
        }
        break
      case 'ArrowDown':
        if (this.activeIndex === null) {
          this.activeIndex = -1
        }
        this.activeIndex = (this.activeIndex + 1) % this.items.length
        this.onActiveChange(this.items[this.activeIndex])
        break
      case 'ArrowUp':
        this.activeIndex--
        if (this.activeIndex < 0) {
          this.activeIndex = this.items.length - 1
        }
        this.onActiveChange(this.items[this.activeIndex])
        break
    }
  }
}
