import React, { memo, ReactElement, useCallback } from 'react'
import { useMutation } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { updateBankLine } from '@modules-deprecated/bankReconciliation/query-api'
import { ReceiptState } from '@modules-deprecated/bankReconciliation/types'
import { ImageFile, UploadItem } from '@modules-deprecated/inbox/elements/billUpload/types'
import { createVoucher } from '@modules-deprecated/inbox/services/api'
import { VoucherInboxState, VoucherOrigin } from '@modules-deprecated/inbox/types'

import { FileUpload } from '../../../../../components-deprecated/FileUpload'
import { queryClient } from '../../../../../config/queryClient'
import { QueryKeys } from '../../../../../enums/queryKeys'
import * as ParentStyled from '../styles'

interface ReceiptWithVoucherProps {
  accountId: string
  bankLineId: string
}

export const ReceiptWithVoucher = memo(({ accountId, bankLineId }: ReceiptWithVoucherProps): ReactElement => {
  const { organization } = useUserOrganization()

  const mutation = useMutation((itemFile?: ImageFile) =>
    createVoucher(
      {
        origin: VoucherOrigin.WEB_APP,
        inboxState: VoucherInboxState.RECEIVED,
        files: itemFile ? [itemFile] : undefined,
        bankAccountId: accountId,
        bankLineId,
      },
      organization?.id as string,
    ),
  )

  const saveItemAsVoucher = useCallback(
    async (item: UploadItem, itemFile?: ImageFile) => {
      if (organization?.id) {
        return mutation.mutate(itemFile)
      }

      return null
    },
    [mutation, organization],
  )

  const handleUploadFinish = useCallback(async () => {
    await updateBankLine({
      id: bankLineId,
      receiptState: ReceiptState.ReceiptUploaded,
    })

    queryClient.invalidateQueries(QueryKeys.BankLines)
  }, [bankLineId])

  return (
    <ParentStyled.Container as="div">
      <FileUpload onItemUpload={saveItemAsVoucher} onUploadFinish={handleUploadFinish} sameHeightFileList />
    </ParentStyled.Container>
  )
})
