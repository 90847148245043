import { NavItem } from '@design-system'

export const bankTransactionsItems: NavItem<number[]>[] = [
  {
    children: '0-39',
    id: '0-39',
    value: [0, 39],
  },
  {
    children: '40-59',
    id: '40-59',
    value: [40, 59],
  },
  {
    children: '60-79',
    id: '60-79',
    value: [60, 79],
  },
  {
    children: '80-100',
    id: '80-100',
    value: [80, 100],
  },
  {
    children: '100+',
    id: '100+',
    value: [100],
  },
]
