import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconZoomIn = ({ color = 'inherit', color2, width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-magnifier')} {...rest}>
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M15.6804 14.48L10.6404 9.84005C11.9204 8.24005 12.3204 6.16005 11.6804 4.16005C11.0404 2.24005 9.44037 0.720047 7.52037 0.240047C5.52037 -0.239953 3.44037 0.240047 1.92037 1.60005C0.400374 2.96005 -0.239626 5.04005 0.0803743 7.04005C0.400374 9.04005 1.76037 10.72 3.68037 11.52C5.60037 12.32 7.68037 12.08 9.44037 10.96L14.5604 15.76C14.7204 15.92 14.9604 16 15.2004 16C15.4404 16 15.6804 15.92 15.8404 15.76C16.0004 15.6 16.0804 15.36 16.0804 15.12C16.0004 14.88 15.9204 14.64 15.6804 14.48ZM1.76037 6.00005C1.76037 4.88005 2.24037 3.76005 3.04037 2.96005C3.84037 2.16005 4.96037 1.68005 6.08037 1.68005C7.20037 1.68005 8.32037 2.16005 9.12037 2.96005C9.92037 3.76005 10.4004 4.88005 10.4004 6.00005C10.4004 7.12005 9.92037 8.24005 9.12037 9.04005C8.32037 9.84005 7.20037 10.32 6.08037 10.32C4.96037 10.32 3.84037 9.84005 3.04037 9.04005C2.16037 8.24005 1.76037 7.12005 1.76037 6.00005Z"
          fill={coloring(color)}
        />
        <path
          d="M7.76035 5.20011H6.80035V4.24011C6.80035 3.84011 6.56035 3.44011 6.16035 3.36011C5.92035 3.36011 5.68035 3.36011 5.52035 3.52011C5.36035 3.76011 5.20035 3.92011 5.20035 4.16011V5.20011H4.24035C3.84035 5.20011 3.44035 5.44011 3.36035 5.84011C3.36035 6.08011 3.36035 6.32011 3.52035 6.48011C3.76035 6.72011 4.00035 6.80011 4.16035 6.80011H5.20035V7.76011C5.20035 8.16011 5.44035 8.56011 5.84035 8.64011C6.08035 8.64011 6.32035 8.64011 6.48035 8.48011C6.64035 8.32011 6.72035 8.08011 6.72035 7.84011V6.80011H7.76035C8.00035 6.80011 8.24035 6.72011 8.40035 6.56011C8.56035 6.40011 8.64035 6.16011 8.56035 5.92011C8.56035 5.52011 8.16035 5.20011 7.76035 5.20011Z"
          fill={coloring(color)}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Box>
)
