import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'
import { ButtonsGroup } from '../ButtonsGroup'

export const PaginationWrapper = styled.section`
  display: flex;
  align-items: center;
`

export const PaginationList = styled(ButtonsGroup)`
  margin-right: ${Spacing.S};
  margin-left: ${Spacing.S};
  flex-shrink: 0;
`
