import { DateFormatter, DueDateFormatter, Translate } from '@components'
import { ItemsPerPage, Table, TableColumn } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { Row } from 'react-table'

import { InvoiceDescription } from '@views/invoices/routes/InvoicesList/elements/InvoiceDescription'
import { LineDescription } from '@views/invoices/routes/InvoicesList/types/lineDescription'

import { SortDirection } from '../../../../../../enums/sortDirection'
import { TableIds } from '../../../../../../enums/tableIds'
import { getTableColumnIdDirection } from '../../../../../../utils/getTableColumnIdDirection'
import { InvoiceDocumentType } from '../../../../enums/invoiceDocumentType'
import { InvoicesSortProperty } from '../../../../enums/InvoicesSortProperty'
import { getInvoicePath } from '../../../../utils/getInvoicePath'
import { getInvoiceQueryKey } from '../../../../utils/getInvoiceQueryKey'
import { useInvoices } from '../../hooks/useInvoices'
import { useInvoicesListFilters } from '../../hooks/useInvoicesListFilters'
import { getInvoiceQueryData } from '../../utils/getInvoiceQueryData'
import { InvoiceStatus } from './elements/InvoiceStatus'
import { InvoicesTableRow } from './types/invoicesTableRow'
import { getInvoicesTableData } from './utils/getInvoicesTableData'

export const InvoicesTable = (): ReactElement => {
  const [{ pageSize, sortProperty, sortDirection }, setQueryParams] = useInvoicesListFilters()
  const { invoices, isLoading, pagination } = useInvoices()

  const queryClient = useQueryClient()
  const history = useHistory()
  const location = useLocation()

  const tableData = useMemo(() => getInvoicesTableData(invoices), [invoices])
  const sortedColumnId = useMemo(
    () => getTableColumnIdDirection(sortProperty, sortDirection === SortDirection.Desc),
    [sortDirection, sortProperty],
  )

  const columns: TableColumn<InvoicesTableRow>[] = useMemo(
    () => [
      {
        accessor: 'status',
        alignment: 'center',
        Cell: ({ value: status }) => <InvoiceStatus status={status} />,
        size: 'xxxxs',
        sortable: false,
      },
      {
        accessor: 'invoiceNo',
        Header: <Translate value="invoices.table.column.invoice_no" />,
        size: 'xs',
        sortable: true,
        truncate: true,
      },
      {
        accessor: 'issueDate',
        Cell: ({ value: date }) => date && <DateFormatter value={date} />,
        fixedSize: true,
        Header: <Translate value="invoices.table.column.date" />,
        size: 's',
        sortable: true,
      },
      {
        accessor: 'dueDate',
        Cell: ({ value: dueDate }) => dueDate && <DueDateFormatter value={dueDate} />,
        fixedSize: true,
        Header: <Translate value="invoices.table.column.due_date" />,
        size: 's',
        sortable: true,
      },
      {
        accessor: (accessors) => accessors['contact.name'],
        Header: <Translate value="invoices.table.column.customer" />,
        id: 'contact.name',
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        accessor: 'description',
        Cell: ({ value: descriptionData }: { value?: LineDescription }) =>
          descriptionData ? (
            <InvoiceDescription isCreditNote={descriptionData.isCreditNote} title={descriptionData.value}>
              {descriptionData.value}
            </InvoiceDescription>
          ) : null,
        Header: <Translate value="invoices.table.column.description" />,
        size: 'm',
        truncate: true,
      },
      {
        accessor: 'netTotal',
        alignment: 'right',
        Header: <Translate value="invoices.table.column.excl_vat" />,
        size: 's',
        sortable: true,
      },
    ],
    [],
  )

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      setQueryParams({
        page: 1,
        sortProperty: columnId as InvoicesSortProperty,
        sortDirection: isDesc ? SortDirection.Desc : SortDirection.Asc,
      })
    },
    [setQueryParams],
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setQueryParams({
        page,
      })
    },
    [setQueryParams],
  )

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: ItemsPerPage) => {
      setQueryParams({
        page: 1,
        pageSize: itemsPerPage,
      })
    },
    [setQueryParams],
  )

  const handleRowClick = useCallback(
    (row: Row<InvoicesTableRow>) => {
      const { id: invoiceId, documentType } = row.original
      const invoiceQueryData = getInvoiceQueryData(invoiceId, invoices)
      queryClient.setQueryData(getInvoiceQueryKey(invoiceId, documentType), () => invoiceQueryData)
      if ([InvoiceDocumentType.Invoice, InvoiceDocumentType.CreditNote].includes(documentType)) {
        history.push(`${getInvoicePath(invoiceId, documentType)}${location.search}`)
      }
    },
    [history, invoices, location.search, queryClient],
  )

  return (
    <Table
      columns={columns}
      currentPage={pagination?.page}
      data={tableData}
      id={TableIds.ExternalInvoicesList}
      isLoading={isLoading}
      itemsPerPage={pageSize}
      onItemsPerPageChange={handleItemsPerPageChange}
      onPageChange={handlePageChange}
      onRowClick={handleRowClick}
      onSort={handleSort}
      sortedColumnId={sortedColumnId}
      totalPageItems={pagination?.total}
      withColumnsFiltering
      withItemsPerPageSelect
      withStickyHeader
    />
  )
}
