import { SmartSelect, SmartSelectProps } from '@components-deprecated'

import React, { useEffect } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { State } from '../../../../types/reduxSaga-deprecated'
import { getItemsForSelect } from '../../../../utils/forms'
import { selectProvidedOrganizationIdOrDefault } from '../../organization/selectors'
import { productsRequested } from '../action-creators'
import { Product } from '../types'

export type ProductSelectorProps = SmartSelectProps<Product> & {
  organizationId?: string
  formControl?: Control<any>
  preselectedId?: string
  onItemSelect?: (product: Product, name?: string) => void
}

export const ProductSelector = ({
  disconnect,
  formControl,
  name = 'product-selector',
  onItemSelect = () => null,
  organizationId: providedOrganizationId,
  preselectedId,
  ...rest
}: ProductSelectorProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const organizationId = useSelector((state: State) =>
    selectProvidedOrganizationIdOrDefault(state, providedOrganizationId),
  )
  const products: Product[] = useSelector((state: any) => state.app.products) || []
  const items = getItemsForSelect<Product>(products)

  useEffect(() => {
    if (organizationId) {
      dispatch(productsRequested(organizationId))
    }
  }, [dispatch, organizationId])

  return (
    <SmartSelect<Product>
      formControl={formControl}
      items={items}
      name={name}
      onItemSelect={onItemSelect}
      notFoundText={t('app.products.product_selector.notfound')}
      placeholder={t('app.products.product_selector.selectproduct')}
      preselectedId={preselectedId}
      disconnect={!!formControl || disconnect}
      {...rest}
    />
  )
}
