import { Button, ButtonsGroup, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { useSegment } from '../../../../../../hooks'

export const PrepaymentModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  useModal(ModalId.BillReconciliationModal, {
    onClose: () => {
      track('xxx - expenses - Prepayment modal closed')
    },
  })

  const handleUsePrepaymentButtonClick = useCallback(() => {
    track('xxx - expenses - Prepayment modal - prepayment clicked')
  }, [track])

  const handleUseWithoutPrepaymentButtonClick = useCallback(() => {
    track('xxx - expenses - Prepayment modal - pay without prepayment clicked')
  }, [track])

  return (
    <ButtonsGroup>
      <Button onClick={handleUseWithoutPrepaymentButtonClick} variant="secondary">
        {t('bill.payment_reconcile.button.pay_without_prepayment')}
      </Button>
      <Button onClick={handleUsePrepaymentButtonClick}>{t('bill.payment_reconcile.button.use_prepayment')}</Button>
    </ButtonsGroup>
  )
}
