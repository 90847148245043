import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconMenetoIncome = ({ color = 'inherit', width = '24px', height = '24px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-meneto-income')} {...rest}>
    <svg
      width={width}
      height={height}
      color={coloring(color)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" fill="none"></path>
      <polyline points="14 2 14 8 20 8" fill="none"></polyline>
      <line x1="12" y1="18" x2="12" y2="12" fill="none"></line>
      <line x1="9" y1="15" x2="15" y2="15" fill="none"></line>
    </svg>
  </Box>
)
