import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface EmptyStateImageProps {
  image: ReactNode
}

export const EmptyStateImage = ({ image }: EmptyStateImageProps): ReactElement => (
  <Styled.EmptyStateImageWrapper>
    <Styled.InnerContainer>{image}</Styled.InnerContainer>
  </Styled.EmptyStateImageWrapper>
)
