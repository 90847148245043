import { NavItem } from '@design-system'

export const billsItems: NavItem<number[]>[] = [
  {
    children: '0-49',
    id: '0-49',
    value: [0, 49],
  },
  {
    children: '50-99',
    id: '50-99',
    value: [50, 99],
  },
  {
    children: '100-149',
    id: '100-149',
    value: [100, 149],
  },
  {
    children: '150-199',
    id: '150-199',
    value: [150, 199],
  },
  {
    children: '200+',
    id: '200+',
    value: [200],
  },
]
