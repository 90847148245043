import { FileRejection } from 'react-dropzone'

interface UploadFilesErrors {
  [fileName: string]: string
}

export const getRejectedFilesList = (filesRejected: FileRejection[]) => {
  return filesRejected.reduce((result, fileRejected) => {
    const fileName = fileRejected.file.name
    result[fileName] = fileRejected.errors.map((error) => error.message).join(', ')
    return result
  }, {} as UploadFilesErrors)
}
