import { notify } from '@design-system'

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { useForm } from '../../../../../hooks'
import { useSignup } from '../../../../../hooks/useSignup'
import { SubmitSignupResponseData } from '../../../../../query-api/signup-query'
import { APIError } from '../../../../../utils'
import { getSignupFormDefaultValues, getValidationSchema, SignupForm } from '../utils/formData'

interface UseSignupFormProps {
  invitationEmail: string
  invitationToken: string
  onError?: (error?: APIError) => void
  onSuccess?: (response: SubmitSignupResponseData) => void
  consentText?: string
}

export const useSignupForm = ({
  consentText,
  invitationEmail,
  invitationToken,
  onSuccess,
  onError,
}: UseSignupFormProps) => {
  const { t } = useTranslation()
  const { Form, FormItem, handleSubmit } = useForm({
    defaultValues: getSignupFormDefaultValues(invitationEmail),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const handleError = useCallback(() => {
    notify({ id: NotificationKeys.SignupUserError, message: t('error_saving'), variant: 'error' })
  }, [t])

  const { signup, isLoading } = useSignup({
    onSuccess,
    onError: onError || handleError,
  })

  const handleSubmitForm = useCallback(
    ({ email, password, name, acceptedMarketing, acceptedTerms }: SignupForm) => {
      return signup({
        email,
        password,
        yourName: name,
        noOrganization: true,
        acceptedMarketing,
        invitationToken,
        consentGiven: acceptedTerms,
        consentText,
      })
    },
    [signup, invitationToken, consentText],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return {
    Form,
    FormItem,
    submitForm,
    isLoading,
  }
}
