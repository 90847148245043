import { UMBRELLA_RECEIVED, UMBRELLA_REQUESTED, USER_UMBRELLAS_RECEIVED, USER_UMBRELLAS_REQUESTED } from './actions'
import { Umbrella } from './types/umbrella'
import { UmbrellaState } from './types/umbrellaState'

export type UserUmbrellasRequested = {
  payload: null
  type: typeof USER_UMBRELLAS_REQUESTED
}

export type UserUmbrellasReceived = {
  payload: UmbrellaState
  type: typeof USER_UMBRELLAS_RECEIVED
}

export type UmbrellaRequested = {
  payload: string
  type: typeof UMBRELLA_REQUESTED
}

export type UmbrellaReceived = {
  payload: Umbrella
  type: typeof UMBRELLA_RECEIVED
}

export const userUmbrellasRequested = (): UserUmbrellasRequested => ({
  payload: null,
  type: USER_UMBRELLAS_REQUESTED,
})

export const userUmbrellasReceived = (payload: UmbrellaState): UserUmbrellasReceived => ({
  payload,
  type: USER_UMBRELLAS_RECEIVED,
})

export const umbrellaRequested = (umbrellaId: string): UmbrellaRequested => ({
  payload: umbrellaId,
  type: UMBRELLA_REQUESTED,
})

export const umbrellaReceived = (umbrella: Umbrella): UmbrellaReceived => ({
  payload: umbrella,
  type: UMBRELLA_RECEIVED,
})
