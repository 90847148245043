import { ButtonDropdown, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ReceiptsSortingWrapper = styled.div`
  display: flex;
  margin-left: ${Spacing.XL};
  align-items: center;
`

export const ButtonDropdownWrapper = styled(ButtonDropdown)`
  width: 260px;
  margin-left: ${Spacing.L};
` as typeof ButtonDropdown
