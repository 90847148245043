import React, { ReactElement } from 'react'

import { Color } from '../../enums/color'
import * as Styled from './styles'
import { Size } from './types/size'
import { sizeToHeight } from './utils/sizeToHeight'

export interface ProgressBarProps {
  backgroundColor?: Color
  progress?: number // [0 - 100]
  progressColor?: Color
  size?: Size
  withAnimation?: boolean
}

export const ProgressBar = ({
  backgroundColor = Color.DeepSeaGreen,
  progress = 0,
  progressColor = Color.ElectricGreen,
  size = 'default',
  withAnimation = false,
}: ProgressBarProps): ReactElement => {
  return (
    <Styled.ProgressBarWrapper
      backgroundColor={backgroundColor}
      height={sizeToHeight[size]}
      withAnimation={withAnimation}
    >
      <Styled.Progress progressColor={progressColor} progress={progress} />
    </Styled.ProgressBarWrapper>
  )
}
