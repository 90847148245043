import { SkeletonBox, SkeletonButton, SkeletonText } from '@design-system'

import React from 'react'

import * as Styled from './styles'

export const SkeletonReceipt = () => {
  return (
    <Styled.SkeletonReceiptWrapper>
      <Styled.SkeletonReceiptImageWrapper>
        <SkeletonBox fullWidth fullHeight />
      </Styled.SkeletonReceiptImageWrapper>
      <Styled.SkeletonReceiptWrapperContent>
        <SkeletonText variant="small" />
        <Styled.SkeletonReceiptFooter>
          <SkeletonButton fullWidth size="m" />
        </Styled.SkeletonReceiptFooter>
      </Styled.SkeletonReceiptWrapperContent>
    </Styled.SkeletonReceiptWrapper>
  )
}
