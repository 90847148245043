import { ProcessingOverlay } from '@components'
import { Color, Modal } from '@design-system'

import React, { ReactElement } from 'react'

import { useEmailContext } from '../../context/emailContext'
import { SendInvoiceFooter } from '../SendInvoiceFooter/SendInvoiceFooter'
import { SendEmailInvoiceForm } from './elements/SendEmailInvoiceForm'
import * as Styled from './styles'

interface SendEmailInvoiceModalContentProps {
  contactId: string
}

export const SendEmailInvoiceModalContent = ({ contactId }: SendEmailInvoiceModalContentProps): ReactElement => {
  const { Form, isLoading, isProcessing } = useEmailContext()

  return (
    <>
      <Modal.Body>
        <Styled.SendEmailInvoiceFormWrapper>
          {(isProcessing || isLoading) && <ProcessingOverlay color={Color.White} />}
          <Form>
            <SendEmailInvoiceForm contactId={contactId} />
          </Form>
        </Styled.SendEmailInvoiceFormWrapper>
      </Modal.Body>

      <Modal.Footer>
        <SendInvoiceFooter.EmailFooter />
      </Modal.Footer>
    </>
  )
}
