import React, { ReactElement } from 'react'

import { Checkbox, CheckboxProps } from '../../../Checkbox'
import { Text } from '../../../Text'
import { NavItemRenderProps } from '../../types/navItemRenderProps'
import * as Styled from './styles'

export type Value = Omit<CheckboxProps, 'children'>

type NavListItemWithCheckboxProps<T> = NavItemRenderProps<T>

export const NavListItemWithCheckbox = <T extends Value>({ item }: NavListItemWithCheckboxProps<T>): ReactElement => {
  const { checked, name, onChange, ...rest } = item.value
  const { children, id } = item

  return (
    <Styled.NavListWithCheckboxWrapper state="default" readonly>
      <Checkbox {...rest} checked={checked} name={name || id} onChange={onChange} size="l">
        <Text colorVariant="primary" weight="medium">
          {children}
        </Text>
      </Checkbox>
    </Styled.NavListWithCheckboxWrapper>
  )
}
