import { object, SchemaOf, string } from 'yup'

export interface CouponCodeForm {
  couponCode: string
}

export const defaultValues: CouponCodeForm = {
  couponCode: '',
}

export type CouponCodeFormSchema = SchemaOf<CouponCodeForm>

export const getValidationSchema = (): CouponCodeFormSchema =>
  object({
    couponCode: string().required(),
  })
