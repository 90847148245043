import { DateFormatter } from '@components'
import { Badge, Color, FormItem, FormItemsGroup, Spacing, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface BillPaymentInfoProps {
  paymentDate: Date
  paymentAccountName: string
}

export const BillPaymentInfo = ({ paymentAccountName, paymentDate }: BillPaymentInfoProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <FormItemsGroup itemsInRow={4} spacing={Spacing.L}>
      <FormItem>
        <Badge variant="success">{t('bills.category.paid')}</Badge>
      </FormItem>
      <FormItem>
        <Text colorVariant="secondary" alignment="right">
          <DateFormatter value={paymentDate} />
        </Text>
      </FormItem>
      <FormItem itemColumnStart={3} itemColumnEnd={5}>
        <Text colorVariant="secondary" alignment="right" title={`From ${paymentAccountName}`} truncate>
          <Text as="span" inherit color={Color.Gray60}>
            {t('bill.register_payment.info.from')}
          </Text>{' '}
          {paymentAccountName}
        </Text>
      </FormItem>
    </FormItemsGroup>
  )
}
