import React from 'react'
import { Text, TextProps } from 'rebass'

import { useUmbrellaOrganizations } from '../../../inbox/hooks/useUmbrellaOrganizations'

type Props = TextProps & {
  id: string
}

export const Organization = ({ id, ...props }: Props) => {
  const { organizations } = useUmbrellaOrganizations()
  const organization = organizations?.find((organization) => organization.organizationId === id)
  const organizationName = organization ? organization?.name : id

  return (
    <Text {...props} title={organizationName}>
      {organizationName}
    </Text>
  )
}
