import { useMemo } from 'react'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { Account } from '@modules-deprecated/app/accounts/types'

interface UsePaymentMethodAccountProps {
  accountName: string
  accountNumber: string | number
}

interface UsePaymentMethodAccountReturn {
  account: Account | null
  isLoading: boolean
}

export const usePaymentMethodAccount = ({
  accountName,
  accountNumber,
}: UsePaymentMethodAccountProps): UsePaymentMethodAccountReturn => {
  const { accounts = [], isLoading } = useAccounts()

  const account = useMemo(() => {
    return (
      accounts.find(
        (account: Account) =>
          account?.name.toLowerCase() === accountName.toLowerCase() || account?.accountNo === accountNumber,
      ) || null
    )
  }, [accounts, accountName, accountNumber])

  return { account, isLoading }
}
