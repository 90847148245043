import React, { MouseEvent, ReactElement } from 'react'

import { RadioProps } from '../../../Radio'
import * as Styled from './styles'

const handleRadioWrapperClick = (event: MouseEvent<HTMLDivElement>) => {
  event.stopPropagation()
}

export const TableRadio = ({ checked, name, onChange, ...rest }: RadioProps): ReactElement => (
  <Styled.TableRadioWrapper {...rest} onClick={handleRadioWrapperClick}>
    <Styled.RadioWrapper checked={checked} name={name} onChange={onChange} />
  </Styled.TableRadioWrapper>
)
