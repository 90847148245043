import { NavItem } from '@design-system'

import { Country } from '@modules-deprecated/app/countries/types'

import { CountryIsoCode } from '../../../../types/countryIsoCode'
import { CountryFlag } from '../../../CountryFlag'
import { CountryValue } from '../types/countryValue'

export const getItems = (countries: Country[]): NavItem<CountryValue>[] => {
  return countries.map((country) => {
    const displayTitle = country.name
    const searchKeywords = [country.id, displayTitle]

    return {
      id: country.id,
      children: displayTitle,
      searchKeywords,
      value: {
        ...country,
        displayData: {
          prefix: <CountryFlag countryCode={country.icon as CountryIsoCode} />,
          title: displayTitle,
        },
      },
    }
  })
}
