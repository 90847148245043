import React, { ReactElement } from 'react'

import { UmbrellaOrganizationsListTable } from '../UmbrellaOrganizationsListTable'
import { UmbrellaOrganizationsListTableActions } from '../UmbrellaOrganizationsListTableActions'

export const UmbrellaOrganizationsListSection = (): ReactElement => {
  return (
    <>
      <UmbrellaOrganizationsListTableActions />
      <UmbrellaOrganizationsListTable />
    </>
  )
}
