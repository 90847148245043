import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchOrganizationSettings } from './query-api'
import { OrganizationSettings } from './types/organizationSettings'

interface ContextState {
  isFetched: boolean
  isIdle: boolean
  isLoading: boolean
  organizationSettings: OrganizationSettings | undefined
}

const UserOrganizationSettingsContext = createContext<ContextState | undefined>(undefined)

interface UserOrganizationSettingsContextProps {
  children?: ReactNode
  organizationId?: string
}

export const UserOrganizationSettingsContextProvider = ({
  children,
  organizationId = '',
}: UserOrganizationSettingsContextProps): ReactElement => {
  const { data, isIdle, isLoading, isFetched } = useQuery(
    [QueryKeys.UserOrganizationSettings, organizationId],
    () => fetchOrganizationSettings(organizationId),
    {
      keepPreviousData: true,
      enabled: !!organizationId,
    },
  )

  return (
    <UserOrganizationSettingsContext.Provider
      value={{ organizationSettings: data?.settings, isLoading, isIdle, isFetched }}
    >
      {children}
    </UserOrganizationSettingsContext.Provider>
  )
}

export const useUserOrganizationSettings = () => {
  const context = useContext(UserOrganizationSettingsContext)

  if (!context) {
    throw new Error('UserOrganizationSettingsContextProvider is missing in the module!')
  }

  return context
}
