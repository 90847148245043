import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SectionsColumnsWrapper = styled.div`
  display: flex;
  margin-bottom: ${Spacing.XXL};
  gap: ${Spacing.XXL};

  &:last-child {
    margin-bottom: 0;
  }

  & > div {
    margin-bottom: 0;
  }
`
