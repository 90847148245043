import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface ProgressChartProps {
  leftLabel?: ReactNode
  rightLabel?: ReactNode
  value: number
  total: number
  width?: number
}

const HUNDRED_PERCENT = 100

export const ProgressChartComponent = ({ leftLabel, rightLabel, value, total }: ProgressChartProps): ReactElement => {
  // Calculate percentage width of the progress bar
  const barWidth = Math.min(Math.round((value / total) * HUNDRED_PERCENT), HUNDRED_PERCENT)
  return (
    <Styled.ProgressChartWrapper>
      <Styled.ProgressChartHeader>
        {leftLabel && (
          <Styled.LeftLabel variant="small" colorVariant="secondary">
            {leftLabel}
          </Styled.LeftLabel>
        )}
        {rightLabel && (
          <Styled.RightLabel variant="small" weight="medium">
            {rightLabel}
          </Styled.RightLabel>
        )}
      </Styled.ProgressChartHeader>
      <Styled.ProgressBarWrapper>
        <Styled.ProgressBar barWidth={barWidth} />
      </Styled.ProgressBarWrapper>
    </Styled.ProgressChartWrapper>
  )
}
