import { GlobalPeriodSelect, ProtectedComponent } from '@components'

import React, { ReactElement } from 'react'

import { PeriodMode } from '../../../../enums/periodMode'
import { Scope } from '../../../../enums/scope'
import { DashboardWidgetsEditAction } from '../DashboardWidgetsEditAction'
import * as Styled from './styles'

export const DashboardActions = (): ReactElement => (
  <Styled.DashboardActionsWrapper>
    <ProtectedComponent scopes={Scope.DashboardWrite}>
      <DashboardWidgetsEditAction />
    </ProtectedComponent>
    <Styled.GlobalPeriodSelectWrapper>
      <GlobalPeriodSelect
        disabledPeriodModes={[PeriodMode.All, PeriodMode.Month, PeriodMode.Quarter, PeriodMode.CustomDate]}
        placement="bottom-end"
      />
    </Styled.GlobalPeriodSelectWrapper>
  </Styled.DashboardActionsWrapper>
)
