import { useMutation } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { removeBankConnection, removeOldBankConnection } from '../query-api'

interface UseRemoveBankConnectionProps {
  onSuccess: () => void
}

export const useRemoveBankConnection = ({ onSuccess }: UseRemoveBankConnectionProps) => {
  const { organization } = useUserOrganization()

  const { mutate: removeBank, isLoading } = useMutation(
    (aiiaBankAccountId: string) =>
      removeBankConnection({ organizationId: organization?.id as string, aiiaBankAccountId }),
    {
      onSuccess: () => {
        onSuccess()
      },
    },
  )

  const { mutate: removeOldBank, isLoading: isLoadingRemoveOldConnection } = useMutation(
    (accountId: string) => removeOldBankConnection({ organizationId: organization?.id as string, accountId }),
    {
      onSuccess: () => {
        onSuccess()
      },
    },
  )

  return {
    isLoading,
    isLoadingRemoveOldConnection,
    removeBank,
    removeOldBank,
  }
}
