import { notify } from '@design-system'

import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization, useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import {
  UpdateOrganizationAndSettingsProps,
  useUpdateOrganizationAndSettings,
} from '@modules-deprecated/app/organization/hooks/useUpdateOrganizationAndSettings'

import { useDirtyRoute } from '../../../../../contexts/dirtyRouteContext'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { Scope } from '../../../../../enums/scope'
import { FormComponent, useForm } from '../../../../../hooks'
import { APIError } from '../../../../../utils'
import { getErrorMessage } from '../../../../../utils/getErrorMessage'
import { isAuthorized } from '../../../../../utils/isAuthorized'
import { isFECReportsEnabled } from '../../../../../utils/isFECReportsEnabled'
import { useOrganizationAccountingWarningModals } from '../hooks/useOrganizationAccountingWarningModals'
import { convertAccountingFormDataForSubmit } from '../utils/convertAccountingFormDataForSubmit'
import {
  getDefaultValues,
  getValidationSchema,
  OrganizationAccountingForm,
} from '../utils/organizationAccountingSettingsFormData'

interface ContextState {
  Form: FormComponent
  saveSettings?: () => void
  isProcessing?: boolean
  isLoading?: boolean
}

const OrganizationAccountingSettingsContext = createContext<ContextState | undefined>(undefined)

interface OrganizationAccountingSettingsContextProps {
  children?: ReactNode
}

export const OrganizationAccountingSettingsContextProvider = ({
  children,
}: OrganizationAccountingSettingsContextProps): ReactElement => {
  const { t } = useTranslation()
  const { setDirty } = useDirtyRoute()
  const [lockDateChanged, setLockDateChanged] = useState(false)
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const { organizationSettings, isLoading: isOrganizationSettingsLoading } = useUserOrganizationSettings()
  const { saveWithWarning, modals: warningModals } = useOrganizationAccountingWarningModals()
  const shouldShowBankSyncSettings = isAuthorized([Scope.BankReconciliationRead, Scope.BankReconciliationWrite])

  const { update, isProcessing, isError } = useUpdateOrganizationAndSettings({
    onError: (error: APIError | undefined) => {
      if (error?.validationErrors.organization.attributes?.firstFiscalYearStart) {
        setError('firstFiscalYearStart', {
          type: 'custom',
          message: t('settings.organization.accounting.date_error'),
        })
      } else {
        notify({
          variant: 'error',
          message: getErrorMessage(error, 'organization') || t('settings.organization.accounting.changes_unsaved'),
          id: NotificationKeys.AccountingSettings,
        })
      }
    },
    onSuccess: () => {
      notify({
        variant: 'success',
        message: t('settings.organization.accounting.changes_saved'),
        id: NotificationKeys.AccountingSettings,
      })
      if (lockDateChanged && isFECReportsEnabled(organization?.countryId)) {
        notify({
          variant: 'info',
          message: t('settings.organization.accounting.lockdate_changed'),
          id: NotificationKeys.AccountingSettingsLockDate,
        })
        setLockDateChanged(false)
      }
      setDirty(false)
    },
  })

  const isLoading = isOrganizationSettingsLoading || isOrganizationLoading

  const saveSettings = useCallback(() => {
    const { organizationPayload, settingsPayload } = convertAccountingFormDataForSubmit(getValues())

    if (!organization || !organizationSettings) {
      return
    }

    if (!shouldShowBankSyncSettings) {
      delete organizationPayload.defaultBankFeeAccountId
    }

    if (organizationPayload.fiscalLockingDate !== organization.fiscalLockingDate) {
      setLockDateChanged(true)
    }

    for (const settingKey in settingsPayload) {
      if (settingsPayload[settingKey] === organizationSettings[settingKey]) {
        delete settingsPayload[settingKey]
      }
    }

    const payload: UpdateOrganizationAndSettingsProps = {
      organizationId: organization.id,
      payload: organizationPayload,
      settingsPayload,
    }

    update(payload)
  }, [organization, organizationSettings, update, setLockDateChanged, shouldShowBankSyncSettings])

  const handleSubmit = useCallback(
    (values: OrganizationAccountingForm) => {
      if (!organization) {
        return
      }

      saveWithWarning({
        values,
        valuesToCompare: {
          isVatExempted: organization.isVatExempted,
          accountingBasis: organization.accountingBasis,
        },
        onWarningsConfirm: saveSettings,
      })
    },
    [organization, saveSettings, saveWithWarning],
  )

  const {
    formState: { isDirty },
    reset,
    setError,
    getValues,
    Form,
  } = useForm({
    defaultValues: useMemo(
      () => getDefaultValues(organization, organizationSettings, { shouldShowBankSyncSettings }),
      [organization, organizationSettings, shouldShowBankSyncSettings],
    ),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    if (!isOrganizationSettingsLoading && !isOrganizationLoading && !isProcessing && !isError) {
      reset(getDefaultValues(organization, organizationSettings, { shouldShowBankSyncSettings }))
    }
  }, [
    isOrganizationSettingsLoading,
    isOrganizationLoading,
    organization,
    organizationSettings,
    shouldShowBankSyncSettings,
    isProcessing,
    isError,
    reset,
  ])

  useEffect(() => {
    setDirty(isDirty)
  }, [setDirty, isDirty])

  return (
    <OrganizationAccountingSettingsContext.Provider value={{ Form, saveSettings, isProcessing, isLoading }}>
      {children}
      {warningModals}
    </OrganizationAccountingSettingsContext.Provider>
  )
}

export const useOrganizationAccountingSettings = () => {
  const context = useContext(OrganizationAccountingSettingsContext)

  if (!context) {
    throw new Error('OrganizationAccountingSettingsContextProvider is missing in the module!')
  }

  return context
}
