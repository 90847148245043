import { EmberRoute } from '../../../../../enums/emberRoute'

const emberRouteToAction: Partial<Record<EmberRoute, string>> = {
  [EmberRoute.InvoicesNew]: 'create new invoice',
  [EmberRoute.BillsNew]: 'create new bill',
  [EmberRoute.QuotesNew]: 'create new quote',
}

export const getFastMenuEventActionFromRoute = (routeTo: EmberRoute) => {
  return emberRouteToAction[routeTo]
}
