import { Spacing } from '../../../enums/spacing'
import { Size } from '../types/size'

interface ControlProperties {
  dimension: number
  bulletSize: number
  offsetTop: Spacing
}

export const sizeToProperties: Record<Size, ControlProperties> = {
  m: { dimension: 16, bulletSize: 6, offsetTop: Spacing.XXS },
  l: { dimension: 20, bulletSize: 8, offsetTop: Spacing.XXXS },
}
