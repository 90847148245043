import { SmartSelect, SmartSelectProps } from '@components-deprecated'
import { ItemsPerPage } from '@design-system'

import React, { ReactElement, useEffect, useMemo } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInboxOrganizationId } from '../../../../contexts/inboxOrganizationIdContext'
import { useUmbrellaOrganizations } from '../../../inbox/hooks/useUmbrellaOrganizations'
import { UmbrellaQueryFilters } from '../action-creators'
import { Organization } from '../types'
import { getOrganizationItems } from './getOrganizationItems'
import { OrganizationSelectItem } from './OrganizationSelectItem'

export type UmbrellaOrganizationSelectorProps = Omit<SmartSelectProps<Organization>, 'name'> & {
  filters?: UmbrellaQueryFilters
  formControl?: Control<any>
  inboxEnabledOnly?: boolean
  name?: string
  onItemSelect?: (organization: Organization, name?: string) => void
  preselectedId?: string
  umbrellaId: string
}

export const UmbrellaOrganizationSelector = ({
  filters,
  formControl,
  inboxEnabledOnly = false,
  name = 'umbrella-organization-selector',
  onItemSelect,
  preselectedId,
  umbrellaId,
  ...rest
}: UmbrellaOrganizationSelectorProps): ReactElement | null => {
  const { t } = useTranslation()
  const [, setOrganizationId] = useInboxOrganizationId()
  const { isLoading, organizations } = useUmbrellaOrganizations({
    isInboxEnabled: inboxEnabledOnly,
    pageSize: 10000 as ItemsPerPage,
  })

  const filteredOrganizations = useMemo(
    () => organizations?.filter(({ isTerminated }) => !isTerminated),
    [organizations],
  )

  useEffect(() => {
    const preselectedOrganization = filteredOrganizations?.find(
      ({ organizationId }) => organizationId === preselectedId,
    )

    if (!preselectedOrganization && !isLoading && filteredOrganizations) {
      setOrganizationId('')
    }
  }, [filteredOrganizations, isLoading, preselectedId, setOrganizationId])

  const items = getOrganizationItems(filteredOrganizations)

  return (
    <SmartSelect<Organization>
      disabled={isLoading || !filteredOrganizations}
      formControl={formControl}
      items={items}
      mode="default"
      name={name}
      notFoundText={t('organization_not_found')}
      onItemSelect={onItemSelect}
      placeholder={t('organization_select')}
      preselectedId={preselectedId}
      render={OrganizationSelectItem}
      {...rest}
    />
  )
}
