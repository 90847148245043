/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <CompanySelect /> instead
 */
import { Color, Text } from '@design-system'

import { useCallback, useState } from 'react'
import { Control } from 'react-hook-form'

import { Select, SelectItem, SelectProps } from '../../../../components-deprecated/Select'
import { deprecatedComponentInfo } from '../../../../utils/deprecatedComponentInfo'
import { getItemsForSelect } from '../../../../utils/forms'
import { useFetchCompanies } from '../hooks/useFetchCompanies'
import { Company } from '../types'

deprecatedComponentInfo('CompanySelector', 'CompanySelect')

export type CompanySelectorProps = Omit<SelectProps<Company>, 'type' | 'onBlur' | 'onFocus'> & {
  error?: string
  formControl?: Control<any>
  name: string
  onItemSelect?: (company: Company, name?: string) => void
  onTextChange?: (text: string) => void
  required?: boolean
  selectedItem?: SelectItem & Company
  displayProp?: keyof Company
}

const getCompanyAddress = (company: Company) =>
  company.numberTo
    ? `${company.street} ${company.number} - ${company.numberTo}`
    : `${company.street} ${company.number}, ${company.zipcode} ${company.city}`

const CompanyMenuItem = (company: Company, active: boolean) => (
  <>
    <Text weight="medium" color={active ? Color.Blue : undefined}>{`${company.name} - ${company.id}`}</Text>
    <Text variant="micro" color={active ? Color.Blue : Color.Gray90}>
      {getCompanyAddress(company)}
    </Text>
  </>
)

export const CompanySelector = ({
  error,
  formControl,
  label,
  name,
  onItemSelect = () => null,
  onTextChange = () => null,
  required,
  selectedItem,
  displayProp,
  ...rest
}: CompanySelectorProps) => {
  const [value, setValue] = useState('')
  const { companies } = useFetchCompanies(value)

  const items = getItemsForSelect<Company>(companies, displayProp)

  const handleTextChange = useCallback(
    (text: string) => {
      setValue(text || '')
      onTextChange && onTextChange(text)
    },
    [onTextChange],
  )

  return (
    <Select<Company>
      formControl={formControl}
      items={items}
      name={name}
      label={label}
      required={required}
      error={error}
      errorPath={name}
      selectedItem={selectedItem}
      render={CompanyMenuItem}
      onTextChange={handleTextChange}
      onItemSelect={(item: object) => onItemSelect(item as Company, name)}
      icon="magnifyingGlass"
      value={value}
      {...rest}
    />
  )
}
