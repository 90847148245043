import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useSkatConnection } from '@features/skatBox'

import { ReactComponent as MomsLogo } from '../../../assets/images/integrations/moms.svg'
import { useEmberRouter } from '../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../enums/emberRoute'
import { IntegrationStatusLabel } from '../elements/IntegrationCard/elements/IntegrationStatusLabel'
import { IntegrationId } from '../enums/integrationId'
import { IntegrationStatus } from '../enums/integrationStatus'
import { IntegrationItem } from '../types/integrationItem'

export const useMomsIntegration = (): IntegrationItem => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { skatConnection, isLoading: isSkatConnectionLoading } = useSkatConnection({
    cacheTime: 0,
  })

  const integrationStatus = useMemo(() => {
    if (isSkatConnectionLoading) {
      return null
    }

    return skatConnection?.connected ? IntegrationStatus.Active : IntegrationStatus.Inactive
  }, [isSkatConnectionLoading, skatConnection?.connected])

  const buttonHandler = useCallback(() => {
    if (integrationStatus === IntegrationStatus.Inactive) {
      return navigate(EmberRoute.TaxReturns)
    } else if (integrationStatus === IntegrationStatus.Active) {
      return navigate(EmberRoute.TaxReturns)
    }
  }, [integrationStatus, navigate])

  const buttonText = useMemo(() => {
    if (integrationStatus === IntegrationStatus.Inactive) {
      return t('integrations.card.momsintegration.button.activate')
    } else if (integrationStatus === IntegrationStatus.Active) {
      return t('integrations.card.momsintegration.button.deactivate')
    }
  }, [integrationStatus, t])

  return {
    id: IntegrationId.Momsintegration,
    name: t('integrations.card.momsintegration.title'),
    description: t('integrations.card.momsintegration.body'),
    readMoreLink: 'https://www.billy.dk/support/article/forbind-dit-regnskab-med-skat/',
    imageNode: <MomsLogo />,
    isHiddenLoading: false,
    isHidden: false,
    buttonHandler,
    buttonText,
    statusLabelNode: <IntegrationStatusLabel status={integrationStatus} isStatusLoading={isSkatConnectionLoading} />,
    isStatusLoading: isSkatConnectionLoading,
  }
}
