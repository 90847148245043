import { ReactElement, SVGProps } from 'react'
import { Bar, Rectangle, RectangleProps } from 'recharts'

import { ChartDataKeys } from '../enums/chartDataKeys'

export const generateBar = (key: string): ReactElement => (
  <Bar
    animationDuration={750}
    barSize={25}
    dataKey={key}
    stackId="stack"
    activeBar={false}
    shape={(props: Omit<SVGProps<SVGPathElement>, 'radius'> & RectangleProps) => {
      const itemValue = props[key]
      const isRevenue = key === ChartDataKeys.Revenue
      const reverseFill = (isRevenue && itemValue < 0) || (!isRevenue && itemValue > 0)

      // Curve the revenue always, and expenses when it has a different sign
      if (isRevenue || Math.sign(itemValue) !== Math.sign(props[ChartDataKeys.Revenue])) {
        props.radius = [25, 25, 0, 0]
      }

      return (
        <Rectangle
          {...props}
          fill={`url(#gradient-${key}${reverseFill ? '-rev' : ''})`}
          opacity={isRevenue ? 1 : 0.5}
          name={String(props.name)}
        />
      )
    }}
  />
)
