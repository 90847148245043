import { Color, DefaultValue, getDefaultTransition, getShadow, Spacing } from '@design-system'

import styled from '@emotion/styled'

interface RadioButtonSectionWrapperProps {
  checked?: boolean
  htmlFor: string
}

export const RadioButtonSectionWrapper = styled.label<RadioButtonSectionWrapperProps>`
  display: flex;
  padding: ${Spacing.L};
  border-radius: ${DefaultValue.BorderRadius};
  position: relative;
  background-color: ${({ checked }) => (checked ? Color.Gray10 : Color.White)};
  box-shadow: 0 0 0 ${({ checked }) => (checked ? `2px ${Color.Green120}` : `1px ${Color.Gray50}`)};
  cursor: pointer;
  ${getDefaultTransition('box-shadow', 'background-color')};

  &:hover {
    box-shadow: 0 0 0 2px ${Color.Green120};
  }
`

export const LeftSection = styled.aside`
  display: flex;
`

interface RadioControlProps {
  checked?: boolean
}

export const RadioControl = styled.span<RadioControlProps>`
  display: flex;
  width: 22px;
  height: 22px;
  margin-top: ${Spacing.XXXS};
  margin-right: ${Spacing.L};
  border: 3px solid ${({ checked }) => (checked ? Color.Blue : Color.Gray60)};
  border-radius: 50%;
  ${getShadow('raisedLight')};
  ${getDefaultTransition('border-color')};

  // pseudo-element below was added to visualise the inside circle of the RadioControl element when it's selected
  &:after {
    opacity: ${({ checked }) => (checked ? '1' : '0')};
    content: '';
    width: 8px;
    height: 8px;
    margin: auto;
    border-radius: 50%;
    background-color: ${Color.Green120};
    ${getDefaultTransition('opacity')};
  }
`

export const NativeRadio = styled.input`
  opacity: 0;
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

export const RightSection = styled.section`
  display: flex;
  flex-direction: column;
`

export const RadioLabelWrapper = styled.div`
  margin-bottom: ${Spacing.S};
`
