import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Themable } from '../../types/themable'

export const IconWrapper = styled.div<Themable>`
  display: inline-block;

  ${({ theme }) => css`
    color: ${theme.colors.text.default};
  `}

  svg {
    fill: currentColor;
  }
`
