import { DefaultValue, transitionToMiliseconds, useComponentToggle } from '@design-system'

import noop from 'lodash/noop'
import React, { createContext, Dispatch, ReactElement, ReactNode, useCallback, useContext, useReducer } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { GLOBAL_SEARCH_SHORTCUT } from '../constants/shortcuts'

export type GlobalSearchContextState = {
  isOpen: boolean
  isRendered: boolean
}

type ContextState = [GlobalSearchContextState, Dispatch<Actions>]

const initialState = {
  isOpen: false,
  isRendered: false,
}

export const GlobalSearchContext = createContext<ContextState>([initialState, noop])

interface GlobalSearchContextProviderProps {
  children: ReactNode
}

export const GlobalSearchContextProvider = ({ children }: GlobalSearchContextProviderProps): ReactElement => {
  const reducerData = useReducer(globalSearchReducer, initialState)
  const [{ isOpen }, dispatch] = reducerData
  const { organization } = useUserOrganization()

  const { isVisible, isRendered } = useComponentToggle({
    isOpen,
    animationTime: transitionToMiliseconds(DefaultValue.TransitionDuration),
  })
  const value = [{ isOpen: isVisible, isRendered }, dispatch] as ContextState
  const isOrganizationLocked = organization && organization?.isLocked

  const handleGlobalShortcutPress = useCallback(
    (event: KeyboardEvent) => {
      if (isOrganizationLocked) {
        return
      }

      event.preventDefault()
      dispatch({ type: isOpen ? 'CLOSE' : 'OPEN' })
    },
    [isOrganizationLocked, dispatch, isOpen],
  )

  useHotkeys(GLOBAL_SEARCH_SHORTCUT, handleGlobalShortcutPress, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] }, [
    isOpen,
  ])

  return <GlobalSearchContext.Provider value={value}>{children}</GlobalSearchContext.Provider>
}

function globalSearchReducer(state: GlobalSearchContextState, action: Actions): GlobalSearchContextState {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        isOpen: true,
      }
    case 'CLOSE':
      return {
        ...state,
        isOpen: false,
      }
  }
}

type Actions = { type: 'OPEN' } | { type: 'CLOSE' }

export const useGlobalSearch = () => {
  const context = useContext(GlobalSearchContext)

  if (!context) {
    throw new Error('GlobalSearchContextProvider is missing in the module!')
  }

  return context
}
