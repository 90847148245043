import { Spacing, Text as TextComponent } from '@design-system'

import styled from '@emotion/styled'

export const ConnectionErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Text = styled(TextComponent)`
  margin-top: ${Spacing.L};
`

export const ReconciliationIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${Spacing.M};
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`
