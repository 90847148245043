import { Modal, ModalProps } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalBody } from './elements/ModalBody'
import { ModalFooter } from './elements/ModalFooter'

export const UnrecognizedVatRatesModal = (modalProps: ModalProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Modal size="s" {...modalProps}>
      <Modal.Header title={t('external_invoices_sync_unrecognized_vat_rates_modal.sync_almost_done')} />
      <Modal.Body>
        <ModalBody />
      </Modal.Body>
      <Modal.Footer>
        <ModalFooter />
      </Modal.Footer>
    </Modal>
  )
}
