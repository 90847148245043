import React, { forwardRef, HTMLAttributes, ReactNode, Ref } from 'react'

import { Color } from '../../enums/color'
import { useTheme } from '../../hooks/useTheme'
import { Asable } from '../../types/asable'
import { Alignment } from './types/alignment'
import { ColorVariant } from './types/colorVariant'
import { Variant } from './types/variant'
import { Weight } from './types/weight'
import { getTextToColor } from './utils/getTextToColor'
import { isInlineElement } from './utils/isInlineElement'
import { variantToComponent } from './utils/variantToComponent'

export type TextWeight = Weight

export type TextAlignment = Alignment

export type TextVariant = Variant

export type TextColorVariant = ColorVariant

export interface TextProps extends HTMLAttributes<HTMLHeadingElement>, Asable {
  alignment?: Alignment
  children?: ReactNode
  color?: Color
  colorVariant?: ColorVariant
  hoverable?: boolean
  htmlFor?: string
  inherit?: boolean
  inheritColor?: boolean
  inline?: boolean
  lowercase?: boolean
  nowrap?: boolean
  selectable?: boolean
  truncate?: boolean
  uppercase?: boolean
  variant?: Variant
  weight?: Weight
  withTransition?: boolean
}

export const Text = forwardRef(
  (
    {
      as,
      children,
      color,
      colorVariant = 'primary',
      inherit,
      inheritColor,
      inline,
      selectable = true,
      variant = 'normal',
      ...rest
    }: TextProps,
    ref: Ref<HTMLHeadingElement>,
  ) => {
    const theme = useTheme()
    const StyledText = variantToComponent[variant]
    const textColor = color || (!inherit && !inheritColor && getTextToColor(theme)[colorVariant])
    const allProps = { textColor, selectable, ...rest }
    const isInline = inline !== undefined ? inline : isInlineElement(as)

    return (
      <StyledText as={as} ref={ref} inherit={inherit} inheritColor={inheritColor} inline={isInline} {...allProps}>
        {children}
      </StyledText>
    )
  },
)
