import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconFilter = ({
  color = 'inherit',
  color2,
  width = '16px',
  height = '16px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-filter')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" transform="translate(-1224.000000, -320.000000)">
        <polygon
          stroke={coloring(color)}
          points="1230.22 331.99 1233.52 335.29 1233.52 326.46 1233.52 326.46 1239.5 320.5 1239.5 320.5 1224.5 320.5 1224.5 320.5 1230.22 326.46"
        ></polygon>
      </g>
    </svg>
  </Box>
)
