import styled from '@emotion/styled'

import { ReactComponent as UnlockedLock } from '../../../../assets/images/periodic-lock-reminder-modal/unlocked-lock.svg'

export const ContentWrapper = styled.div``

export const FigureWrapper = styled.figure`
  display: flex;
  width: 35%;
`
export const LockImage = styled(UnlockedLock)`
  width: 60px;
  height: 60px;
  margin: auto;
`
