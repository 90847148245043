import { AttachmentFile } from '@design-system'

import { Metable } from '../../../../types/metable'
import { getRequest } from '../../../../utils'

interface FetchFileResponseData extends Metable {
  file: AttachmentFile
}

export const fetchFile = (fileId: string): Promise<FetchFileResponseData> => {
  return getRequest(`/v2/files/${fileId}`)
}
