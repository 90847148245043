import React, { ReactElement, ReactNode } from 'react'

import { Text } from '../../../Text'
import * as Styled from './styles'

interface ExtraInfoProps {
  children: ReactNode
}

export const ExtraInfo = ({ children }: ExtraInfoProps): ReactElement => (
  <Styled.ExtraInfoWrapper>
    <Text variant="small" colorVariant="secondary" inline>
      {children}
    </Text>
  </Styled.ExtraInfoWrapper>
)
