import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconBills = ({
  color = '#ED5244',
  color2 = '#fff',
  width = '20px',
  height = '20px',
  isOutlined,
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-bills')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215.69 361.69">
      <path
        d="M275.25,54.67c-4.32,8-12.62,16.34-21.73,16.57h-.63a25.4,25.4,0,0,1-25.41-18.42c-2-5.34-2.16-5.59-7.5-5.59s-5.47,0-7.5,5.59A25.42,25.42,0,0,1,185.67,71,25.41,25.41,0,0,1,159,52.56c-2-5.33-2.16-5.59-7.5-5.59s-5.34,1.78-9.66,7.12a31.89,31.89,0,0,1-25.41,14.48c-8.43,0-16.33-7.32-21.09-14.28l-.06-.07A2.66,2.66,0,0,0,90.63,56v313.9A2.06,2.06,0,0,0,94.41,371l0-.05a26.14,26.14,0,0,1,47.77,5.08c2.16,5.34,2.16,5.59,7.5,5.59s5.47,0,7.5-5.59A25.41,25.41,0,0,1,184,357.14a25.4,25.4,0,0,1,27.06,18.42c2,5.34,2.16,5.59,7.5,5.59s5.34-1.65,9.66-7a31.67,31.67,0,0,1,25.41-14.61,25.43,25.43,0,0,1,21,11.44l.06.07a2.66,2.66,0,0,0,4.64-1.77V55.82a2.2,2.2,0,0,0-4.07-1.15Z"
        transform="translate(-77.13 -33.47)"
        fill={isOutlined ? 'transparent' : coloring(color2)}
        stroke={coloring(color2)}
        strokeMiterlimit="10"
        strokeWidth="27"
      />
      <path
        d="M176.78,141.94H130.44a8.87,8.87,0,0,1,0-17.73h46.34a8.87,8.87,0,1,1,0,17.73Z"
        transform="translate(-77.13 -33.47)"
        fill={isOutlined ? coloring(color2) : coloring(color)}
        stroke={isOutlined ? coloring(color2) : coloring(color)}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        d="M210.83,187.22H130.44a8.87,8.87,0,0,1,0-17.74h80.39a8.87,8.87,0,0,1,0,17.74Z"
        transform="translate(-77.13 -33.47)"
        fill={isOutlined ? coloring(color2) : coloring(color)}
        stroke={isOutlined ? coloring(color2) : coloring(color)}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        d="M236.29,305.9H187a8.87,8.87,0,1,1,0-17.73h49.3a8.87,8.87,0,0,1,0,17.73Z"
        transform="translate(-77.13 -33.47)"
        fill={isOutlined ? coloring(color2) : coloring(color)}
        stroke={isOutlined ? coloring(color2) : coloring(color)}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
    </svg>
  </Box>
)
