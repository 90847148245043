import styled from '@emotion/styled'

export const BillSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

interface BillSummaryItemProps {
  alignRight?: boolean
}
export const BillSummaryItem = styled.div<BillSummaryItemProps>`
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'inherit')};
`
