import { TFunction } from 'i18next'

import { NeedInfoReasons } from '../types'

export const getNeedInfoReasons = (t: TFunction): Record<NeedInfoReasons, string> => ({
  [NeedInfoReasons.MissingAmount]: t('voucher.inbox.modals.moreinfo.reason_missing_amount'),
  [NeedInfoReasons.MissingDate]: t('voucher.inbox.modals.moreinfo.reason_missing_date'),
  [NeedInfoReasons.MissingSupplier]: t('voucher.inbox.modals.moreinfo.reason_missing_supplier'),
  [NeedInfoReasons.MissingPurpose]: t('voucher.inbox.modals.moreinfo.reason_missing_purpose'),
  [NeedInfoReasons.MissingVAT]: t('voucher.inbox.modals.moreinfo.reason_missing_vat'),
  [NeedInfoReasons.ImageQuality]: t('voucher.inbox.modals.moreinfo.reason_image_quality'),
  [NeedInfoReasons.MissingPaymentMethod]: t('voucher.inbox.modals.moreinfo.reason_missing_payment_method'),
  [NeedInfoReasons.IncorrectReceipt]: t('voucher.inbox.modals.moreinfo.reason_incorrect_receipt'),
  [NeedInfoReasons.Other]: t('voucher.inbox.modals.moreinfo.reason_other'),
})
