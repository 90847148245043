import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { BankIntegrationContextProvider } from '@views/bankIntegration/contexts/bankIntegrationContext'
import { ConnectionErrorAlert } from '@views/bankIntegration/elements/ConnectionErrorAlert/ConnectionErrorAlert'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'

class BankConnectionErrorAlertWebComponent extends DOMModel {
  @byAttrVal accountid: string
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
}

type BankConnectionErrorAlertModuleProps = {
  accountid: string
  onNavigate: EmberNavigate
  organizationId: string
}

function BankConnectionErrorAlertModule({
  accountid,
  onNavigate,
  organizationId,
}: BankConnectionErrorAlertModuleProps): ReactElement {
  return (
    <ThemeProvider>
      <Router>
        <QueryClientProvider client={queryClient}>
          <CurrentUserContextProvider>
            <EmberRouterContextProvider onNavigate={onNavigate}>
              <ModalContextProvider>
                <AccountsContextProvider organizationId={organizationId}>
                  <UserOrganizationContextProvider organizationId={organizationId}>
                    <BankIntegrationContextProvider account={accountid} organizationId={organizationId}>
                      <ConnectionErrorAlert />
                    </BankIntegrationContextProvider>
                  </UserOrganizationContextProvider>
                </AccountsContextProvider>
              </ModalContextProvider>
            </EmberRouterContextProvider>
          </CurrentUserContextProvider>
        </QueryClientProvider>
      </Router>
    </ThemeProvider>
  )
}

const BankConnectionErrorAlertCustomElement = createCustomElement(
  BankConnectionErrorAlertModule,
  BankConnectionErrorAlertWebComponent,
  'element',
)

customElements.get('bank-connection-error-alert') ||
  customElements.define('bank-connection-error-alert', BankConnectionErrorAlertCustomElement)

export default BankConnectionErrorAlertCustomElement
