import { Logo } from '@components'
import { Link } from '@design-system'

import { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as BillyUnlock } from '../../../../assets/images/billy-unlock.svg'
import { getImageClassName } from '../../../../utils/getClassName'
import * as Styled from './styles'

interface LayoutProps {
  children: ReactNode
  withFooter?: boolean
}

export const Layout = ({ children, withFooter }: LayoutProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.LayoutWrapper>
      <Styled.Header>
        <Styled.HeaderLeft>
          <Logo />
        </Styled.HeaderLeft>
        <Styled.HeaderRight>
          <Link target="_blank" href="https://www.billy.dk/support/">
            {t('organization_invitation.header.support')}
          </Link>
        </Styled.HeaderRight>
      </Styled.Header>
      <Styled.ContentWrapper>{children}</Styled.ContentWrapper>
      {withFooter && (
        <Styled.Footer>
          <Styled.FooterImageWrapper className={getImageClassName()}>
            <BillyUnlock />
          </Styled.FooterImageWrapper>
        </Styled.Footer>
      )}
    </Styled.LayoutWrapper>
  )
}
