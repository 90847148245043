import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../utils'
import { deleteRuleset } from '../../../query-api'

interface UseDeleteRulesetProps {
  onError?: (error?: APIError) => void
  onSettled?: (data: void, error: APIError | null | undefined) => void
  onSuccess?: (data: void) => void
}

export const useDeleteRuleset = (props?: UseDeleteRulesetProps) => {
  const { onError, onSuccess, onSettled } = props || {}
  const { mutate, ...rest } = useMutation(deleteRuleset, {
    onError,
    onSuccess,
    onSettled,
  })

  return { delete: mutate, ...rest }
}
