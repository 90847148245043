import { Flex, SkeletonText, Space, Spacing, Text } from '@design-system'

import { t } from 'i18next'
import { ReactElement, useCallback } from 'react'

import { useBankLines } from '../../../../contexts/bankLinesContext'
import { useBankLinesFilters } from '../../../../contexts/bankLinesFilteringContext'
import { BankLinesSearchInput } from './elements/BankLinesSearchInput'
import { BankLinesSortingPropertySelect } from './elements/BankLinesSortingPropertySelect'
import * as Styled from './styles'
import { BankLinesSortingProperty } from './types/bankLinesSortingProperty'

export const BankLinesActions = (): ReactElement => {
  const { bankLines, isLoading } = useBankLines()
  const { bankLinesSortingProperty, setBankLinesSortingProperty } = useBankLinesFilters()

  const bankLinesCount = bankLines?.length || 0

  const handleSortingPropertySelect = useCallback(
    (value?: BankLinesSortingProperty) => {
      if (value) {
        setBankLinesSortingProperty(value)
      }
    },
    [setBankLinesSortingProperty],
  )

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        {isLoading ? (
          <SkeletonText />
        ) : (
          <Text colorVariant="secondary">{t('bank_lines', { count: bankLinesCount })}</Text>
        )}
        <Flex gap={Spacing.L}>
          <BankLinesSearchInput />
          <Styled.SortingPropertySelectWrapper>
            <BankLinesSortingPropertySelect
              dropdownSize="fitTrigger"
              onSelect={(id, value) => handleSortingPropertySelect(value)}
              selectedId={bankLinesSortingProperty}
              withSearch={false}
            />
          </Styled.SortingPropertySelectWrapper>
        </Flex>
      </Flex>
      <Space size="s" />
    </>
  )
}
