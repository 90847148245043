import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconClip = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-clip')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={coloring(color)}
        d="M30,15v8H2v-8H30 M30,13H2c-1.105,0-2,0.895-2,2v8c0,1.105,0.895,2,2,2h28c1.105,0,2-0.895,2-2v-8 C32,13.895,31.105,13,30,13L30,13z"
      ></path>
      <rect fill={coloring(color2 || color)} x="6" y="18" width="15" height="2"></rect>
      <rect fill={coloring(color2 || color)} x="2" y="8" width="11" height="2"></rect>
    </svg>
  </Box>
)
