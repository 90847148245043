import { NavItem, Select, SelectProps } from '@design-system'

import { forwardRef, Ref, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment } from '../../../../../../../../../../hooks'
import { BankLinesSortingProperty } from '../../types/bankLinesSortingProperty'

interface BankLinesSortingPropertySelectProps
  extends Omit<SelectProps<BankLinesSortingProperty>, 'items' | 'onSelect'> {
  onSelect?: (id?: string, value?: BankLinesSortingProperty) => void
}

export const BankLinesSortingPropertySelect = forwardRef(
  ({ onSelect, ...selectProps }: BankLinesSortingPropertySelectProps, ref: Ref<HTMLDivElement | null>) => {
    const { t } = useTranslation()
    const { track } = useSegment()

    const items: NavItem<BankLinesSortingProperty>[] = useMemo(
      () => [
        {
          id: BankLinesSortingProperty.AmountDifference,
          children: t('bill.payment_reconcile.bank_lines_sorting_property.amount_difference'),
          value: BankLinesSortingProperty.AmountDifference,
        },
        {
          id: BankLinesSortingProperty.AmountAsc,
          children: t('bill.payment_reconcile.bank_lines_sorting_property.amount_asc'),
          value: BankLinesSortingProperty.AmountAsc,
        },
        {
          id: BankLinesSortingProperty.AmountDesc,
          children: t('bill.payment_reconcile.bank_lines_sorting_property.amount_desc'),
          value: BankLinesSortingProperty.AmountDesc,
        },
        {
          id: BankLinesSortingProperty.DateAsc,
          children: t('bill.payment_reconcile.bank_lines_sorting_property.date_asc'),
          value: BankLinesSortingProperty.DateAsc,
        },
        {
          id: BankLinesSortingProperty.DateDesc,
          children: t('bill.payment_reconcile.bank_lines_sorting_property.date_desc'),
          value: BankLinesSortingProperty.DateDesc,
        },
      ],
      [t],
    )

    const handleSelect = useCallback(
      (id?: string, value?: BankLinesSortingProperty) => {
        onSelect?.(id, value)
        track('xxx - expenses - Bank line modal - filter used', {
          searchQuery: value,
        })
      },
      [onSelect, track],
    )

    return <Select {...selectProps} items={items} onSelect={handleSelect} ref={ref} />
  },
)
