import { createSelector } from 'reselect'

import { SpecificState } from '../../../types/reduxSaga-deprecated'

const BANK_ACCOUNT_NO = 5710

export const getBankAccountSelector = createSelector(
  (state: SpecificState) => state.app.accounts,
  (accounts) => accounts?.find((account) => account.accountNo === BANK_ACCOUNT_NO),
)
