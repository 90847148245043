import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../../../utils'
import { updateAdditionalField } from '../../../../../query-api'
import { TaxMetaField } from '../../../../../types/taxMetaField'

interface UseUpdateAdditionalField {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface UpdateAdditionalFieldProps {
  id: string
  payload: Partial<TaxMetaField>
}

export const useUpdateAdditionalField = (props?: UseUpdateAdditionalField) => {
  const { onError, onSuccess } = props || {}

  const { mutate: update, ...rest } = useMutation(
    ({ id, payload }: UpdateAdditionalFieldProps) => updateAdditionalField(id, payload),
    {
      onError,
      onSuccess,
    },
  )

  return { update, ...rest }
}
