import { Divider, Space, Text } from '@design-system'

import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment } from '../../../../../../hooks'
import { useMultipleBills } from '../../contexts/multipleBillsContext'
import { BillSummary } from '../BillSummary'
import { BillsList } from './elements/BillsList'
import { BillsListSkeleton } from './elements/BillsList/BillsListSkeleton'
import * as Styled from './styles'

export const MultipleBillsModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { bills, isLoading } = useMultipleBills()

  useEffect(() => {
    track('xxx - expenses - Multiple bill selection modal opened', {
      billsCount: bills?.length || 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bills?.length])

  return (
    <>
      <BillSummary />
      <Space />
      <Divider />
      <Space />
      <Text>{t('bill.payment_reconcile.would_you_like_to_register_more_bills')}</Text>
      <Space />
      <Styled.BillsListWrapper>{isLoading ? <BillsListSkeleton /> : <BillsList />}</Styled.BillsListWrapper>
    </>
  )
}
