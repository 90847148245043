import isNumber from 'lodash/isNumber'
import { createContext, ReactElement, ReactNode, useContext } from 'react'

import { ExternalInvoicesSyncStatus } from '../../../enums/externalInvoicesSyncStatus'
import { useFetchExternalInvoicesSync } from '../../../hooks/useFetchExternalInvoicesSync'

interface ContextState {
  processedDocumentsCount?: number | null
  progressPercent: number
  syncStatus?: ExternalInvoicesSyncStatus | null
  totalDocumentsCount?: number | null
}

const ExternalInvoicesSyncProgressContext = createContext<ContextState | undefined>(undefined)

interface ExternalInvoicesSyncProgressContextProps {
  children: ReactNode
}

export const ExternalInvoicesSyncProgressContextProvider = ({
  children,
}: ExternalInvoicesSyncProgressContextProps): ReactElement => {
  const { syncData } = useFetchExternalInvoicesSync()

  const {
    syncStatus,
    processedDocuments: processedDocumentsCount,
    totalDocuments: totalDocumentsCount,
  } = syncData || {}

  const progressPercent =
    isNumber(processedDocumentsCount) && isNumber(totalDocumentsCount)
      ? Math.round((processedDocumentsCount / totalDocumentsCount) * 100)
      : 0

  return (
    <ExternalInvoicesSyncProgressContext.Provider
      value={{ processedDocumentsCount, progressPercent, syncStatus, totalDocumentsCount }}
    >
      {children}
    </ExternalInvoicesSyncProgressContext.Provider>
  )
}

export const useExternalInvoicesSyncProgress = () => {
  const context = useContext(ExternalInvoicesSyncProgressContext)

  if (!context) {
    throw new Error('ExternalInvoicesSyncProgressContextProvider is missing in the module!')
  }

  return context
}
