import { NavItem, Select, SelectProps } from '@design-system'

import { forwardRef, Ref, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

enum AttachmentDeliveryMode {
  LinkAndAttachment = 'linkAndAttachment',
  Attachment = 'attachment',
}

interface AttachmentDeliveryModeSelectProps
  extends Omit<SelectProps<AttachmentDeliveryMode>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: AttachmentDeliveryMode) => void
}

export const AttachmentDeliveryModeSelect = forwardRef(
  ({ onSelect, ...selectProps }: AttachmentDeliveryModeSelectProps, ref: Ref<HTMLDivElement | null>) => {
    const { t } = useTranslation()

    const items: NavItem<AttachmentDeliveryMode>[] = useMemo(
      () => [
        {
          id: AttachmentDeliveryMode.LinkAndAttachment,
          children: t(
            'settings.organization.invoice.invoice_settings.email_attachment_delivery_mode.option.link_and_attachment',
          ),
          value: AttachmentDeliveryMode.LinkAndAttachment,
        },
        {
          id: AttachmentDeliveryMode.Attachment,
          children: t(
            'settings.organization.invoice.invoice_settings.email_attachment_delivery_mode.option.email_attachment',
          ),
          value: AttachmentDeliveryMode.Attachment,
        },
      ],
      [t],
    )

    return <Select {...selectProps} items={items} onSelect={onSelect} ref={ref} />
  },
)
