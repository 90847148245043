import { Table } from '@design-system'

import { getMonth, getYear } from 'date-fns'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Row } from 'react-table'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { SortDirection } from '../../../../../../enums/sortDirection'
import { TableIds } from '../../../../../../enums/tableIds'
import { useAccount } from '../../../../../../hooks/useAccount'
import { AccountStatementsTableRow } from '../../enums/accountStatementsTableRow'
import { useAccountStatements } from '../../hooks/useAccountStatements'
import { useAccountStatementsFilters } from '../../hooks/useAccountStatementsFilters'
import { AccountStatementSortProperty } from '../../types/accountStatementSortProperty'
import { ChartOfAccountsUrlParams } from '../../types/chartOfAccountsUrlParams'
import { getAccountStatementsTableColumns } from '../../utils/getAccountStatementsTableColumns'
import { getAccountStatementsTableData } from '../../utils/getAccountStatementsTableData'

export const AccountStatementsTable = (): ReactElement => {
  const { t } = useTranslation()
  const { accountId } = useParams<ChartOfAccountsUrlParams>()
  const { account } = useAccount(accountId)
  const [{ page, pageSize, sortProperty, sortDirection, withVoided }, setQueryParams] = useAccountStatementsFilters()
  const { accountStatement, isLoading } = useAccountStatements()
  const { navigate } = useEmberRouter()

  const columns = useMemo(
    () => getAccountStatementsTableColumns(withVoided, account?.currencyId, t),
    [account?.currencyId, t, withVoided],
  )
  const data = useMemo(
    () => (accountStatement ? getAccountStatementsTableData(accountStatement) : []),
    [accountStatement],
  )

  const sortedColumnId = useMemo(() => {
    if (!sortProperty) {
      return undefined
    }

    return sortDirection === SortDirection.Desc ? `-${sortProperty}` : `${sortProperty}`
  }, [sortDirection, sortProperty])

  const handlePageChange = useCallback(
    (paginationPage: number) => {
      setQueryParams({ page: paginationPage })
    },
    [setQueryParams],
  )

  const handleRowClick = useCallback(
    (row: Row<AccountStatementsTableRow>) => {
      const { transactionId } = row.original

      if (!transactionId) {
        return
      }

      navigate(EmberRoute.Transaction, transactionId)
    },
    [navigate],
  )

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      setQueryParams({
        page: 1,
        sortProperty: columnId as AccountStatementSortProperty,
        sortDirection: isDesc ? SortDirection.Desc : SortDirection.Asc,
      })
    },
    [setQueryParams],
  )

  const groupRowsByMonths = useCallback((row: Row<AccountStatementsTableRow>) => {
    const { entryDate } = row.original

    if (!entryDate) {
      return ''
    }

    const year = getYear(entryDate)
    const month = getMonth(entryDate)
    return `${year}-${month}`
  }, [])

  return (
    <Table
      columns={columns}
      currentPage={page}
      data={data}
      groupRowsBy={groupRowsByMonths}
      id={TableIds.AccountStatementsTable}
      isClientSidePagination
      isLoading={isLoading}
      itemsPerPage={pageSize}
      onPageChange={handlePageChange}
      onRowClick={handleRowClick}
      onSort={handleSort}
      sortedColumnId={sortedColumnId}
      withColumnsFiltering
      withPagination
      withStickyHeader
    />
  )
}
