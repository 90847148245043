import { Button } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { useTaxRates } from '@modules-deprecated/app/taxrates'

import { useFormContext } from '../../../../../../hooks/useForm'
import { AutoCompletedFields } from '../../../../../../types/formWithAutoCompletedFields'
import { BillFormBasic, BillFormLine, BillFormSchema, getDefaultBillLine } from '../../utils/formData'
import { getAccountFromContact } from '../../utils/getAccountFromContact'
import { getTaxRateFromContact } from '../../utils/getTaxRateFromContact'
import * as Styled from './styles'

interface BillAddLineButtonProps {
  onAddLine: (billLine: BillFormLine) => void
  linesTotal: number
}

export const BillAddLineButton = ({ onAddLine, linesTotal }: BillAddLineButtonProps): ReactElement => {
  const { t } = useTranslation()
  const { accounts = [] } = useAccounts()
  const { taxRates = [] } = useTaxRates()
  const { setValue, getValues } = useFormContext<BillFormSchema>()

  const addBillLineWithDefaults = useCallback(() => {
    const { autoCompletedFields, vendor: contact } = getValues()
    const defaultBillLine = getDefaultBillLine()

    if (!contact) {
      return onAddLine(defaultBillLine)
    }

    const description = contact.defaultExpenseProductDescription
    const expenseCategory = getAccountFromContact(accounts, contact)
    const taxRate = getTaxRateFromContact(taxRates, contact)

    const updatedAutoCompletedFields: AutoCompletedFields<BillFormBasic> = {}

    if (description) {
      defaultBillLine.description = description
      updatedAutoCompletedFields[`billLines.${linesTotal}.description`] = true
    }

    if (expenseCategory) {
      defaultBillLine.expenseCategory = expenseCategory
      updatedAutoCompletedFields[`billLines.${linesTotal}.expenseCategory`] = true
    }

    if (taxRate) {
      defaultBillLine.taxRate = taxRate
      updatedAutoCompletedFields[`billLines.${linesTotal}.taxRate`] = true
    }

    onAddLine(defaultBillLine)

    // Wait one frame before setting autoCompletedFields
    window.requestAnimationFrame(() => {
      setValue('autoCompletedFields', { ...autoCompletedFields, ...updatedAutoCompletedFields })
    })
  }, [getValues, linesTotal, accounts, taxRates, onAddLine, setValue])

  const handleAddLineClick = useCallback(() => {
    addBillLineWithDefaults()
  }, [addBillLineWithDefaults])

  return (
    <Styled.BillAddLineButtonWrapper>
      <Button variant="text" icon="plusCircle" onClick={handleAddLineClick}>
        {t('bill.edit.form.add_line')}
      </Button>
    </Styled.BillAddLineButtonWrapper>
  )
}
