import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'

interface AmountInfoBoxesGroupWrapperProps {
  itemsInRow: number
}

export const AmountInfoBoxesGroupWrapper = styled.ul<AmountInfoBoxesGroupWrapperProps>`
  display: grid;
  width: 100%;
  max-width: 100%;
  grid-template-columns: ${({ itemsInRow }) => `repeat(${itemsInRow}, 1fr)`};
  column-gap: ${Spacing.L};
  row-gap: ${Spacing.L};
`

export const AmountInfoBoxesGroupItem = styled.li`
  min-width: 0;
  overflow: hidden;
`
