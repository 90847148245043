import { Button } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useContactModal } from '../../contexts/contactModalContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { submitForm, isSaving } = useContactModal()

  return (
    <Button type="button" onClick={submitForm} loading={isSaving}>
      {t('save')}
    </Button>
  )
}
