import { Flex, ModuleLayout } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { InvoicesRoute } from '../../enums/invoicesRoute'
import { InvoiceEditorContextProvider } from './contexts/InvoiceEditorContext'
import { InvoiceEditorFormContextProvider } from './contexts/invoiceEditorFormContext'
import { InvoiceEditorContent } from './elements/InvoiceEditorContent'
import { InvoiceEditorPreviewToggle } from './elements/InvoiceEditorPreviewToggle'
import { InvoiceEditorSideActions } from './elements/InvoiceEditorSideActions'
import * as Styled from './styles'

export const InvoiceEditor = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()

  const handleBackButtonClick = useCallback(() => {
    history.push(`${InvoicesRoute.List}${location.search}`)
  }, [history, location.search])

  return (
    <InvoiceEditorContextProvider>
      <InvoiceEditorFormContextProvider>
        <ModuleLayout
          headerContent={
            <Styled.InvoiceEditorPreviewToggleWrapper>
              <Flex justifyContent="center" flexGrow={1}>
                <InvoiceEditorPreviewToggle />
              </Flex>
            </Styled.InvoiceEditorPreviewToggleWrapper>
          }
          fullHeight
          onBackButtonClick={handleBackButtonClick}
          sideActions={<InvoiceEditorSideActions />}
          title={t('external_invoices.editor.new.title')}
          withBackButton
        >
          <InvoiceEditorContent />
        </ModuleLayout>
      </InvoiceEditorFormContextProvider>
    </InvoiceEditorContextProvider>
  )
}
