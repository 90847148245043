import { Color, Text } from '@design-system'

import React from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BoxProps } from 'rebass'

import { Select } from '../../../../components-deprecated/Select'
import { State } from '../../../../types/reduxSaga-deprecated'
import { getItemsForSelect } from '../../../../utils/forms'
import { zipCodesRequested } from '../action-creators'
import { ZipCode } from '../types'

export type ZipCodeSelectorProps = Omit<BoxProps, 'type' | 'onBlur' | 'onFocus'> & {
  countryId: string
  name?: string
  selectedItem?: ZipCode
  errorPath?: string
  formControl?: Control<any>
  disconnect?: boolean
  onItemSelect?: (zipCode: ZipCode) => void
  onTextChange?: (text: string) => void
}

const ZipCodeMenuItem = (zipCode: ZipCode, active?: boolean) => (
  <>
    <Text weight="medium" color={active ? Color.Blue : undefined}>
      {zipCode.zipcode}
    </Text>
    <Text variant="micro" color={active ? Color.Blue : Color.Gray90}>
      {zipCode.city.name}
    </Text>
  </>
)

export const ZipCodeSelector = ({
  countryId,
  name = '',
  disconnect,
  selectedItem,
  formControl,
  onTextChange = () => null,
  onItemSelect = () => null,
  ...rest
}: ZipCodeSelectorProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const zipCodes: ZipCode[] = useSelector((state: State) => state.app.zipCodes.list)
  const page = useSelector((state: State) => state.app.zipCodes.page)

  const handleFetch = (offset: number, text?: string) => {
    dispatch(
      zipCodesRequested({
        countryId,
        page: {
          offset,
        },
        filters: {
          text: text || null,
        },
      }),
    )
  }

  const items = getItemsForSelect<ZipCode>(zipCodes, 'zipcode')

  return (
    <Select<ZipCode>
      formControl={formControl}
      name={name}
      placeholder={t('app.address.address_selector.zip_code_placeholder')}
      items={items}
      selectedItem={selectedItem}
      total={page?.total}
      render={ZipCodeMenuItem}
      onTextChange={onTextChange}
      onItemSelect={onItemSelect}
      onFetch={handleFetch}
      disconnect={!!formControl || disconnect}
      {...rest}
    />
  )
}
