import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const MenuItemTextWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const BadgeWrapper = styled.div`
  display: flex;
  margin-left: ${Spacing.XS};
`
