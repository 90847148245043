import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useState } from 'react'

import { SortDirection } from '../../../enums/sortDirection'
import { ReceiptFillType, ReceiptFilterType } from '../elements/Receipt'
import { ReceiptsSortProperty } from '../enums/sort'

interface AttachmentsFilters {
  fillType: ReceiptFillType
  page: number
  pageSize: number
  searchQuery: string
  sortDirection: SortDirection
  sortProperty: ReceiptsSortProperty
  type: ReceiptFilterType
}

type ContextState = {
  filters: AttachmentsFilters
  resetFilters: () => void
  setFilters: (filters: Partial<AttachmentsFilters>) => void
}

const defaultFilters: AttachmentsFilters = {
  page: 1,
  pageSize: 40,
  searchQuery: '',
  sortDirection: SortDirection.Desc,
  sortProperty: ReceiptsSortProperty.CreatedTime,
  type: ReceiptFilterType.All,
  fillType: ReceiptFillType.Nonfilled,
}

const AttachmentsContext = createContext<ContextState | undefined>(undefined)

interface AttachmentsContextProviderProps {
  children?: ReactNode
}

export const AttachmentsContextProvider = ({ children }: AttachmentsContextProviderProps): ReactElement => {
  const [filters, setFilters] = useState<AttachmentsFilters>(defaultFilters)

  const handleSetFilters = useCallback(
    (newFilters: Partial<AttachmentsFilters>) => {
      setFilters({ ...filters, ...newFilters })
    },
    [setFilters, filters],
  )

  const resetFilters = useCallback(() => {
    setFilters(defaultFilters)
  }, [])

  const contextValue = {
    filters,
    resetFilters,
    setFilters: handleSetFilters,
  }

  return <AttachmentsContext.Provider value={contextValue}>{children}</AttachmentsContext.Provider>
}

export const useAttachmentsContext = () => {
  const context = useContext(AttachmentsContext)

  if (!context) {
    throw new Error('AttachmentsContextProvider is missing in the module!')
  }

  return context
}
