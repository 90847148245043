import { Flex, rwd, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const HeaderContainer = styled(Flex)`
  margin-bottom: ${Spacing.L};

  ${rwd.equalOrLessThan('xlarge')} {
    min-height: 144px;
  }

  ${rwd.equalOrLessThan('medium')} {
    min-height: unset;
  }
`
