import { NavItem } from '@design-system'

import { ReceiptsSortFilterDropdownValue } from '../ReceiptsSorting'

export const getDisplayTitle = ({ value }: NavItem<ReceiptsSortFilterDropdownValue>): string => {
  const {
    displayData: { description, title },
  } = value

  return `${title} (${description})`
}
