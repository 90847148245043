import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const PasswordWrapper = styled.div`
  position: relative;
`

export const IconButtonWrapper = styled.figure`
  position: absolute;
  top: 34px; // this is a placement with had to count also a label's height, that's why I put a hardcoded value
  right: ${Spacing.M};
  z-index: 1;
`
