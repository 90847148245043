import React, {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { usePrevious } from 'react-use'

import { ModalState, ModalStates } from '../ModalContentToggler'

interface ContextState<T> {
  activeState?: string
  activeStateModalData?: ModalState<T>
  prevState?: string
  setActiveState: Dispatch<SetStateAction<string | undefined>>
}

const ModalContentTogglerContext = createContext<ContextState<any> | undefined>(undefined)

interface ModalContentTogglerContextProps<K> {
  children?: ReactNode
  initialState?: string
  states: ModalStates<K>
}

export const ModalContentTogglerContextProvider = <T,>({
  children,
  initialState,
  states,
}: ModalContentTogglerContextProps<T>): ReactElement => {
  const [activeState, setActiveState] = useState<string | undefined>()
  const prevState = usePrevious(activeState)

  const activeStateModalData = states.find((modalState) => modalState.id === activeState) || states[0]

  useEffect(() => {
    setActiveState(initialState)
  }, [initialState])

  return (
    <ModalContentTogglerContext.Provider value={{ activeState, activeStateModalData, prevState, setActiveState }}>
      {children}
    </ModalContentTogglerContext.Provider>
  )
}

export const useModalContentToggler = () => {
  const context = useContext(ModalContentTogglerContext)

  if (!context) {
    throw new Error('ModalContentTogglerContextProvider is missing in the module!')
  }

  return context
}
