import { StatusFormatterVariant } from '@components'

import { BillStatus } from '../enums/billStatus'

export const billStatusToStatusFormatterVariant: Record<
  BillStatus,
  StatusFormatterVariant | [StatusFormatterVariant, StatusFormatterVariant]
> = {
  [BillStatus.All]: 'neutral',
  [BillStatus.Draft]: 'primary',
  [BillStatus.Overdue]: 'error',
  [BillStatus.Paid]: 'success',
  [BillStatus.PartiallyPaid]: ['success', 'warning'],
  [BillStatus.Unpaid]: 'warning',
  [BillStatus.Voided]: 'neutral',
}
