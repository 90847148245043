import React, { ReactElement, ReactNode } from 'react'

import { Icon, IconName } from '../../../Icon'
import * as Styled from './styles'

export interface PaymentPaneItemProps {
  amount?: ReactNode
  collapsible?: boolean
  content?: ReactNode
  extra?: ReactNode
  icon?: IconName
  onClick?: () => void
  prefix?: ReactNode
  withHoverEffect?: boolean
}

export const PaymentPaneItem = ({
  amount,
  collapsible,
  content,
  extra,
  icon,
  onClick,
  prefix,
  withHoverEffect,
}: PaymentPaneItemProps): ReactElement => {
  return (
    <Styled.PaymentPaneItemWrapper collapsible={collapsible} onClick={onClick} withHoverEffect={withHoverEffect}>
      <Styled.PaymentPaneItem>{prefix}</Styled.PaymentPaneItem>
      <Styled.PaymentPaneItem>{extra}</Styled.PaymentPaneItem>
      <Styled.PaymentPaneItem>{content}</Styled.PaymentPaneItem>
      {amount && <Styled.PaymentPaneItem>{amount}</Styled.PaymentPaneItem>}
      {icon && (
        <Styled.PaymentPaneItem>
          <Icon icon={icon} />
        </Styled.PaymentPaneItem>
      )}
    </Styled.PaymentPaneItemWrapper>
  )
}
