import { TableHoverActions } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface UmbrellaOrganizationsListTableStaticActionsProps {
  umbrellaOrganizationUrl: string
}

export const UmbrellaOrganizationsListTableHoverActions = ({
  umbrellaOrganizationUrl,
}: UmbrellaOrganizationsListTableStaticActionsProps): ReactElement => {
  const { t } = useTranslation()

  const handleGoToOrganization = useCallback(() => {
    window.open(`/${umbrellaOrganizationUrl}`, '_blank')
  }, [umbrellaOrganizationUrl])

  return (
    <TableHoverActions>
      <TableHoverActions.Item
        icon="arrowOutOfSquare"
        label={t('umbrella_organizations.organizations_table.goToOrganization')}
        onClick={handleGoToOrganization}
      />
    </TableHoverActions>
  )
}
