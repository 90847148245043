import { useUserOrganization } from '@modules-deprecated/app/organization'
import { CompanyType } from '@modules-deprecated/app/organization/enums/companyType'

export const useShouldDisplayOrganizationOwnersSection = () => {
  const { organization, isLoading } = useUserOrganization()

  const isBillyProviderDk = organization?.billyProviderId === 'dk'
  const isCompany = organization?.companyType === CompanyType.Company
  const isProprietorship = organization?.companyType === CompanyType.Proprietorship
  const shouldDisplaySection = isBillyProviderDk && (isCompany || isProprietorship)

  return {
    shouldDisplaySection,
    isLoading,
  }
}
