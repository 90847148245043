import { Color, Spacing, Text } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

const DOT_DISTANCE_TOP = 8
const DOT_SIZE = 9
const SEPARATOR_DOT_DISTANCE = 8
const SEPARATOR_OFFSET = DOT_DISTANCE_TOP + DOT_SIZE + SEPARATOR_DOT_DISTANCE

interface TimelineStatusItemWrapperProps {
  statusColor: Color
  withSeparator: boolean
  isCollapsed: boolean
}

export const TimelineStatusItemWrapper = styled.div<TimelineStatusItemWrapperProps>`
  padding-left: ${Spacing.L};
  position: relative;

  ${({ isCollapsed, withSeparator }) =>
    !isCollapsed &&
    withSeparator &&
    css`
      min-height: 33px;
    `}

  /* Status dot */
  &:before {
    content: '';
    width: ${DOT_SIZE}px;
    height: ${DOT_SIZE}px;
    border-radius: 50%;
    position: absolute;
    top: ${DOT_DISTANCE_TOP}px;
    left: 0;
    background-color: ${({ statusColor }) => statusColor};
  }

  ${({ withSeparator }) =>
    withSeparator &&
    css`
      /* Vertical separator line */
      &:after {
        content: '';
        width: 1px;
        height: calc(100% - ${SEPARATOR_OFFSET}px);
        position: absolute;
        top: ${SEPARATOR_OFFSET}px;
        left: 4px;
        background-color: ${Color.Gray50};
      }
    `}
`

export const Header = styled.header`
  display: flex;

  + ${() => ContentWrapper} {
    margin-top: ${Spacing.XXS};
  }
`

export const DateWrapper = styled.div`
  margin-left: ${Spacing.S};
  padding-top: ${Spacing.XXS};
`

export const ContentWrapper = styled.div`
  &:not(:empty) {
    padding-bottom: ${Spacing.L};
  }
`

export const ChildrenWrapper = styled.div`
  margin-top: ${Spacing.L};
`

export const DescriptionText = styled(Text)`
  ${({ truncate }) =>
    !truncate &&
    css`
      white-space: pre-line;
    `}
`
