import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../utils'
import {
  createSalaryAccount,
  CreateSalaryAccountPayloadData,
  CreateSalaryAccountResponseData,
} from '../../../../../query-api'

interface UseCreateSalaryAccount {
  onError?: (error: APIError) => void
  onSettled?: (data: CreateSalaryAccountResponseData | undefined, error: APIError | undefined | null) => void
  onSuccess?: (data: CreateSalaryAccountResponseData) => void
}

export const useCreateSalaryAccount = (props: UseCreateSalaryAccount) => {
  const { onSuccess, onError, onSettled } = props || {}
  const { mutate: create, ...rest } = useMutation((data: CreateSalaryAccountPayloadData) => createSalaryAccount(data), {
    onSuccess,
    onError,
    onSettled,
  })

  return {
    create,
    ...rest,
  }
}
