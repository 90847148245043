import { css } from '@emotion/core'
import React from 'react'
import { Box, BoxProps, Flex } from 'rebass'

import { ConnectForm, ConnectFormType } from './ConnectForm'
import { ErrorMessage } from './ErrorMessage'
import { FormLabel } from './FormLabel'
import { InputProps } from './Input'

export type FieldsetProps = Omit<BoxProps, 'children'> & {
  children?: React.ReactElement<InputProps>[] // Limit children to valid inputs
  inline?: boolean
  label?: string
}

export const Fieldset = ({ children, inline, label, ...rest }: FieldsetProps) => {
  const names = [] as string[]
  let required = false
  React.Children.forEach(children || [], (child: React.ReactElement<InputProps>) => {
    child.props.name && names.push(child.props.name)
    required = required || !!(child.props.required || child.props.options?.required)
  })
  return (
    <ConnectForm>
      {({ formState }: ConnectFormType) => {
        const errors = formState?.errors || {}

        return (
          <Box as="fieldset" variant="forms.fieldset" {...rest}>
            {label && <FormLabel required={required}>{label}</FormLabel>}
            <Flex
              css={css`
                flex-direction: ${inline ? 'row' : 'column'};
                justify-content: flex-start;
                align-items: start;
              `}
            >
              {React.Children.map(
                children as React.ReactElement<InputProps>[],
                (child: React.ReactElement<InputProps>): JSX.Element | null =>
                  React.isValidElement(child) ? React.cloneElement(child, { silent: true }) : null,
              )}
            </Flex>
            {names.map((name) => errors[name] && <ErrorMessage key={name} error={errors[name]} />)}
          </Box>
        )
      }}
    </ConnectForm>
  )
}
