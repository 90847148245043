import { NavItem } from '../../NavList'

export const getDisplayValue = <T>(item: NavItem<T>, customDisplayValueFn?: (item: NavItem<T>) => string) => {
  if (customDisplayValueFn) {
    return customDisplayValueFn(item)
  }

  if (item.displayValue) {
    return item.displayValue
  }

  if (typeof item.children === 'string') {
    return item.children
  }

  console.error(
    "Could not get proper value to display. Make sure you set either 'displayValue' or string 'children' for each item",
  )
  return 'undefined'
}
