import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

export interface ContainerProps {
  children: ReactNode
  fullWidth?: boolean
  fullHeight?: boolean
}

export const Container = (props: ContainerProps): ReactElement => {
  const { children, fullWidth, fullHeight, ...rest } = props

  return (
    <Styled.ContainerWrapper fullWidth={fullWidth} fullHeight={fullHeight} {...rest}>
      {children}
    </Styled.ContainerWrapper>
  )
}
