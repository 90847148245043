import { useMemo } from 'react'

import { usePaymentMethods } from '../../../hooks/usePaymentMethods'
import { IntegrationStatus } from '../enums/integrationStatus'
import { PaymentMethodType } from '../enums/paymentMethodType'

export const usePaymentMethodsStatuses = () => {
  const { paymentMethods, isLoading, ...rest } = usePaymentMethods()

  const mobilepayPaymentMethod = useMemo(
    () => paymentMethods?.data.find((pm) => !pm.isDisabled && pm.type === PaymentMethodType.MobilePay),
    [paymentMethods?.data],
  )

  let mobilepayStatus = IntegrationStatus.Inactive
  if (mobilepayPaymentMethod) {
    mobilepayStatus = mobilepayPaymentMethod.isPending ? IntegrationStatus.Pending : IntegrationStatus.Active
  }

  const stripePaymentMethod = useMemo(
    () => paymentMethods?.data.find((pm) => !pm.isDisabled && pm.type === PaymentMethodType.Stripe),
    [paymentMethods?.data],
  )

  let stripeStatus = IntegrationStatus.Inactive
  if (stripePaymentMethod) {
    stripeStatus = stripePaymentMethod.isPending ? IntegrationStatus.Pending : IntegrationStatus.Active
  }

  return {
    ...rest,
    isLoading,
    mobilepayStatus,
    stripeStatus,
  }
}
