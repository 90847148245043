import { Attachment, FilesPreview, getFileThumbnail, PreviewFile, SkeletonBox } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'

import { getImageClassName } from '../../../../utils/getClassName'
import * as Styled from './styles'

interface ModalFileItemProps {
  item: Attachment
  onFileBlur: () => void
  onFileHover: (file: Attachment) => void
  onFileSelect: (file: Attachment) => void
}

export const ModalFileItem = ({ item, onFileBlur, onFileHover, onFileSelect }: ModalFileItemProps): ReactElement => {
  const files: PreviewFile[] = useMemo(
    () => [{ src: getFileThumbnail(item.file, 'small'), srcDownload: item.file.downloadUrl }],
    [item],
  )

  const handleBlur = useCallback(() => {
    onFileBlur()
  }, [onFileBlur])

  const handleHover = useCallback(() => {
    onFileHover(item)
  }, [item, onFileHover])

  const handleClick = useCallback(() => {
    onFileSelect(item)
  }, [item, onFileSelect])

  return (
    <Styled.FileItemWrapper>
      <FilesPreview
        className={getImageClassName()}
        files={files}
        fitToHeight
        fitToWidth
        fitType="cover"
        loading={<SkeletonBox fullHeight fullWidth />}
        onClick={handleClick}
        onMouseEnter={handleHover}
        onMouseLeave={handleBlur}
        withNavigation={false}
      />
    </Styled.FileItemWrapper>
  )
}
