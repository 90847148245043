import { Icon, Text, useNavItemSubmit, useScrollToElement } from '@design-system'

import upperFirst from 'lodash/upperFirst'
import React, { ReactElement, useCallback, useMemo, useRef } from 'react'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'

import { categoryToIcon } from '../../constants/categoryToIcon'
import { DEFAULT_ITEM_ICON } from '../../constants/defaultItemIcon'
import { getCategoryTranslation } from '../../constants/getCategoryTranslation'
import { SearchResultItem } from '../../types/searchResultItem'
import * as Styled from './styles'

interface GlobalSearchItemProps {
  item: SearchResultItem
  onClick?: (item: SearchResultItem) => void
  searchValue?: string
  focusedId?: string
}

export const GlobalSearchItem = ({
  item,
  onClick,
  searchValue = '',
  focusedId,
}: GlobalSearchItemProps): ReactElement => {
  const { t } = useTranslation()
  const { id, text, category, icon } = item
  const listItemRef = useRef<HTMLLIElement>(null)
  const textFormatted = upperFirst(text)

  const categoryTranslations = useMemo(() => getCategoryTranslation(t), [t])
  const categoryTranslated = categoryTranslations[category]

  const isFocused = focusedId === id
  const itemIcon = icon || categoryToIcon[category] || DEFAULT_ITEM_ICON
  const searchWords = searchValue.split(' ')

  const handleItemClick = useCallback(() => {
    onClick?.(item)
  }, [item, onClick])

  const handleKeyDown = useCallback(() => {
    if (isFocused) {
      listItemRef.current?.click()
    }
  }, [isFocused])

  useScrollToElement(listItemRef.current, isFocused)
  useNavItemSubmit(handleKeyDown, isFocused, [item, isFocused, handleKeyDown])

  return (
    <Styled.ListItem ref={listItemRef} onClick={handleItemClick} focused={isFocused} tabIndex={0}>
      <Styled.IconWrapper>
        <Icon icon={itemIcon} />
      </Styled.IconWrapper>

      <Styled.ContentWrapper>
        <Text title={textFormatted} weight="medium" truncate>
          <Highlighter searchWords={searchWords} textToHighlight={textFormatted} autoEscape />
        </Text>
        <Text variant="micro" colorVariant="secondary">
          {categoryTranslated}
        </Text>
      </Styled.ContentWrapper>
    </Styled.ListItem>
  )
}
