import { ReactElement, useEffect } from 'react'

import { FRODA_HOME_URL } from '../../constants'
import { useFrodaFinanceLendingContext } from '../../contexts/frodaFinanceLendingContext'
import { handleFrodaMessage } from '../../utils/handleFrodaMessage'
import * as Styled from './styles'

export const FrodaIframe = (): ReactElement => {
  const { homeAccessUrl, parentUrl } = useFrodaFinanceLendingContext()

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => handleFrodaMessage(event, FRODA_HOME_URL)

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  return (
    <Styled.Container>
      <Styled.FrodaIframe title="froda-access-url" src={`${homeAccessUrl}&parentUrl=${parentUrl}`}></Styled.FrodaIframe>
    </Styled.Container>
  )
}
