import isAfter from 'date-fns/isAfter'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { fetchOldBankConnections } from '@modules-deprecated/bankReconciliation/query-api'
import { BankConnectionOld, BankConnectionSession } from '@modules-deprecated/bankReconciliation/types'

import { QueryKeys } from '../../../enums/queryKeys'
import { Scope } from '../../../enums/scope'
import { isAuthorized } from '../../../utils/isAuthorized'
import { fetchBankSessions } from '../query-api'

interface FilterIrrelevantBankConnectionsProps {
  bankConnections?: BankConnectionOld[]
  bankConnectionSessions?: BankConnectionSession[]
}

const filterIrrelevantBankConnections = ({
  bankConnections,
  bankConnectionSessions,
}: FilterIrrelevantBankConnectionsProps) => {
  return bankConnections?.filter((bankConnection) => {
    const currentSession = bankConnectionSessions
      ? bankConnectionSessions.find((session) => session.id === bankConnection?.referenceId)
      : undefined

    const hasAnyErrors = !!bankConnection?.errorCode || !!currentSession?.errorCode

    const isNordicApiConnection = bankConnection?.type === 'nordic_api'
    const sessionExpiryTime = currentSession?.loginTokenExpiryTime
      ? new Date(currentSession.loginTokenExpiryTime)
      : undefined
    const isExpiredNordicApiConnection = sessionExpiryTime
      ? isAfter(new Date(), sessionExpiryTime) && isNordicApiConnection
      : false

    /*  We're only supporting old bank connection if it doesn't have any errors + until it's expired */
    return !isExpiredNordicApiConnection && !hasAnyErrors
  })
}

export const useOldBankConnections = (accountId: string) => {
  const { organization } = useUserOrganization()

  const canUseBankIntegration = isAuthorized(Scope.BankSyncRead)
  const organizationId = organization?.id

  const {
    data: oldBankConnections,
    isLoading: isLoadingOldBankConnections,
    refetch,
  } = useQuery([QueryKeys.BankConnectionsOld], () => fetchOldBankConnections(organization?.id as string), {
    enabled: !!organizationId && canUseBankIntegration,
  })

  const { data: bankConnectionSessions, isLoading: isLoadingSessions } = useQuery(
    [QueryKeys.BankConnectionSessions],
    () => fetchBankSessions(organizationId as string),
    {
      enabled: !!organizationId && canUseBankIntegration,
    },
  )

  const connectedBank = useMemo(
    () => oldBankConnections?.find((bankConnection) => bankConnection.accountId === accountId),
    [accountId, oldBankConnections],
  )

  const validOldBankConnections = useMemo(
    () =>
      filterIrrelevantBankConnections({
        bankConnections: oldBankConnections,
        bankConnectionSessions,
      }),
    [oldBankConnections, bankConnectionSessions],
  )

  return {
    connectedBank,
    isLoading: isLoadingOldBankConnections || isLoadingSessions,
    oldBankConnections: validOldBankConnections,
    refetchOldBankConnections: refetch,
  }
}
