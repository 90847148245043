import { PurchaseCardsGroup } from '@components'
import { Space, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ContactSupportFooter } from '../../components-deprecated/ContactSupportFooter'
import { AccountingPackageCard } from './elements/AccountingPackageCard'
import { CompletePlanCard } from './elements/CompletePlanCard'
import { DoItYourselfCard } from './elements/DoItYourselfCard'
import * as Styled from './styles'

export const AnnualReport = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.Container>
      <Text variant="h2">{t('annual_report.package.subtitle')}</Text>
      <Space size="m" />
      <PurchaseCardsGroup>
        <DoItYourselfCard />
        <AccountingPackageCard />
        <CompletePlanCard />
      </PurchaseCardsGroup>
      <Space size="xxxl" />
      <ContactSupportFooter />
    </Styled.Container>
  )
}
