import { Badge, BadgeVariant } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface BadgeLabelProps {
  badgeAmount: number
  badgeVariant?: BadgeVariant
  label: ReactNode
}

export const BadgeLabel = ({ badgeAmount, badgeVariant, label }: BadgeLabelProps): ReactElement => (
  <Styled.BadgeLabelWrapper>
    <Styled.TextLabel colorVariant="primary" variant="h3">
      {label}
    </Styled.TextLabel>
    <Badge variant={badgeVariant}>{badgeAmount}</Badge>
  </Styled.BadgeLabelWrapper>
)
