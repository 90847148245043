import React, { createContext, ReactElement, ReactNode, useContext } from 'react'

interface ContextState {
  openCreatePaymentMethodModal?: () => void
  openEditPaymentMethodModal?: (paymentMethodId: string) => void
  reloadPaymentMethods?: () => void
}

const OrganizationInvoiceSettingsContext = createContext<ContextState | undefined>(undefined)

interface OrganizationInvoiceSettingsContextProps {
  onOpenCreatePaymentMethodModal?: () => void
  onOpenEditPaymentMethodModal?: (paymentMethodId: string) => void
  onReloadPaymentMethods?: () => void
  children?: ReactNode
}

export const OrganizationInvoiceSettingsContextProvider = ({
  onOpenCreatePaymentMethodModal,
  onOpenEditPaymentMethodModal,
  onReloadPaymentMethods,
  children,
}: OrganizationInvoiceSettingsContextProps): ReactElement => {
  return (
    <OrganizationInvoiceSettingsContext.Provider
      value={{
        openCreatePaymentMethodModal: onOpenCreatePaymentMethodModal,
        openEditPaymentMethodModal: onOpenEditPaymentMethodModal,
        reloadPaymentMethods: onReloadPaymentMethods,
      }}
    >
      {children}
    </OrganizationInvoiceSettingsContext.Provider>
  )
}

export const useOrganizationInvoiceSettings = () => {
  const context = useContext(OrganizationInvoiceSettingsContext)

  if (!context) {
    throw new Error('OrganizationInvoiceSettingsContextProvider is missing in the module!')
  }

  return context
}
