import { TFunction } from 'i18next'

import { parseIso8601RepeatingInterval } from '../../../../../utils/parseIso8601RepeatingInterval'
import { durationUnitToRecurringPeriodTranslationKey } from '../constants/durationUnitToRecurringPeriodTranslationKey'

export const getRecurringPeriod = (recurringInterval: string, t: TFunction): string => {
  const { durationCount, durationUnit, isValid } = parseIso8601RepeatingInterval(recurringInterval)

  if (isValid && durationUnit) {
    const recurringPeriodTranslationKey = durationUnitToRecurringPeriodTranslationKey[durationUnit]

    return t(recurringPeriodTranslationKey, { count: durationCount })
  }

  return ''
}
