import { Attachment, Button } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { QueryKeys } from '../../../../enums/queryKeys'
import { ReceiptType } from '../../../../enums/receiptType'
import { Scope } from '../../../../enums/scope'
import { getReceiptType } from '../../../../utils/getReceiptType'
import { isAuthorized } from '../../../../utils/isAuthorized'

interface ReceiptButtonProps {
  attachment: Attachment
}

export const ReceiptButton = ({ attachment }: ReceiptButtonProps): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const queryClient = useQueryClient()

  const isEnabled = isAuthorized(Scope.BillWrite)
  const buttonLabel = useMemo(() => {
    return getReceiptType(attachment) === ReceiptType.EDocument
      ? t('receipt.manage_e_invoice')
      : t('receipt.manage_upload')
  }, [attachment, t])

  // Handlers

  const handleClick = useCallback(() => {
    queryClient.setQueryData([QueryKeys.Receipt, attachment.id], { attachment })
    navigate(EmberRoute.BillsNew, { queryParams: { attachmentId: attachment.id } })
  }, [attachment, navigate, queryClient])

  return (
    <Button disabled={!isEnabled} fullWidth onClick={handleClick} variant="secondary" size="m">
      {buttonLabel}
    </Button>
  )
}
