import { Flex, Space, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ErrorImage } from '../../../../assets/images/organization-users-settings/invitation-modal-send-invitation.svg'
import { getImageClassName } from '../../../../utils/getClassName'

export const ModalError = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Flex alignItems="center" flexDirection="column">
      <ErrorImage className={getImageClassName()} width={250} />
      <Space size="l" />
      <Text colorVariant="secondary" variant="small">
        {t('daybook.consequencesModal.error_message')}
      </Text>
    </Flex>
  )
}
