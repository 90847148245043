import { Icon, IconName, Spacing } from '@design-system'

import { css } from '@emotion/core'
import React from 'react'
import { Box, BoxProps } from 'rebass'

import { InputSize } from '../Form/Input'

type IconProps = BoxProps & {
  mode: 'default' | 'lookup'
  isListOpen?: boolean
  onClick?: () => void
  inputSize?: InputSize
}

export const SelectIcon = ({ mode, isListOpen, inputSize, ...rest }: IconProps) => {
  const chevronIcon: IconName = isListOpen ? 'chevronUp' : 'chevronDown'

  return (
    <Box
      css={css`
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        padding: ${Spacing.S} ${Spacing.M};
        width: 40px;
        height: ${inputSize === 'xl' ? 48 : 40}px;
        align-items: center;
        cursor: pointer;
        z-index: 2;
      `}
      {...rest}
    >
      <Icon icon={mode === 'lookup' ? 'magnifyingGlass' : chevronIcon} />
    </Box>
  )
}
