import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconExport = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-export')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={coloring(color)}
        d="M15,5H4C2.343,5,1,6.343,1,8v16c0,1.657,1.343,3,3,3h11c1.657,0,3-1.343,3-3V8C18,6.343,16.657,5,15,5z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M15,28H4c-2.206,0-4-1.794-4-4V8c0-2.206,1.794-4,4-4h11c2.206,0,4,1.794,4,4v16C19,26.206,17.206,28,15,28z M4,6C2.897,6,2,6.897,2,8v16c0,1.103,0.897,2,2,2h11c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4z"
      ></path>
      <polygon fill={coloring(color2 || color)} points="31,16 22.001,8 22,12 15,12 15,20 22,20 22.001,24 "></polygon>
      <path
        fill={coloring(bg)}
        d="M22.001,25c-0.139,0-0.278-0.028-0.41-0.088c-0.359-0.161-0.59-0.519-0.59-0.912l0-3h-6 c-0.552,0-1-0.447-1-1v-8c0-0.553,0.448-1,1-1h6l0-3c0-0.394,0.231-0.751,0.59-0.912c0.359-0.163,0.779-0.097,1.074,0.165l8.999,8 C31.878,15.442,32,15.715,32,16s-0.122,0.558-0.335,0.747l-8.999,8C22.479,24.913,22.241,25,22.001,25z M16,19h6 c0.552,0,1,0.447,1,1l0,1.773L29.495,16L23,10.227L23,12c0,0.553-0.448,1-1,1h-6V19z"
      ></path>
    </svg>
  </Box>
)
