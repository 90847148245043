import { useCallback } from 'react'

import {
  OrganizationSettingKey,
  useUpdateOrganizationSettings,
  useUserOrganization,
  useUserOrganizationSettings,
} from '@modules-deprecated/app/organization'
import { OrganizationSettingsValue } from '@modules-deprecated/app/organization/types/organizationSettingsValue'

import { betaFeatures } from '../constants/betaFeatures'
import { OrganizationFeature } from '../enums/organizationFeature'
import { filterOutClosedFeatures } from '../utils/filterOutClosedFeatures'
import { getOrganizationSettingBetaKey } from '../utils/getOrganizationSettingBetaKey'

interface UseOrganizationFeaturesResponse {
  currentFeatures: OrganizationFeature[]
  hasFeature: (featureName: OrganizationFeature) => boolean
  isLoading: boolean
  isUpdating: boolean
  previousFeatures: OrganizationFeature[]
  setFeature: (featureName: OrganizationFeature) => void
  unsetFeature: (featureName: OrganizationFeature) => void
}

export const useOrganizationFeature = (): UseOrganizationFeaturesResponse => {
  const { organization } = useUserOrganization()
  const { isLoading: isOrganizationSettingsLoading, organizationSettings } = useUserOrganizationSettings()
  const { update: updateSettings, isProcessing: isUpdating } = useUpdateOrganizationSettings()

  const organizationId = organization?.id
  const currentFeatures = filterOutClosedFeatures(betaFeatures.current, organizationSettings)
  const previousFeatures = filterOutClosedFeatures(betaFeatures.previous, organizationSettings)

  const hasFeature = useCallback(
    (featureName: OrganizationFeature) =>
      organizationSettings ? organizationSettings[getOrganizationSettingBetaKey(featureName)] === '1' : false,
    [organizationSettings],
  )

  const set = useCallback(
    (key: OrganizationSettingKey, value: OrganizationSettingsValue) => {
      if (organizationId) {
        updateSettings({
          key,
          organizationId,
          value,
        })
      } else {
        // Improbable case but confirming the call is not made
        console.error('Organization id was not set correctly')
      }
    },
    [organizationId, updateSettings],
  )

  const setFeature = useCallback(
    (featureName: OrganizationFeature) => set(getOrganizationSettingBetaKey(featureName), '1'),
    [set],
  )

  const unsetFeature = useCallback(
    (featureName: OrganizationFeature) => set(getOrganizationSettingBetaKey(featureName), '0'),
    [set],
  )

  return {
    currentFeatures,
    hasFeature,
    isLoading: isOrganizationSettingsLoading,
    isUpdating,
    previousFeatures,
    setFeature,
    unsetFeature,
  }
}
