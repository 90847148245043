import { FileAvatar } from '@components'
import { Button, ButtonsGroup, Modal, Text, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { UserType } from '../../enums/userType'
import * as Styled from './styles'

interface RemoveUserModalProps {
  id: ModalId
  isLoading?: boolean
  logoId?: string
  name: string
  onOk: () => void
  userType: UserType
}

export const RemoveUserModal = ({
  id,
  isLoading,
  logoId,
  name,
  onOk,
  userType,
}: RemoveUserModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeRemoveUserModal } = useModal(id)

  const title =
    userType === UserType.Bookkeeper
      ? t('settings.organization.delete_modal.title.bookkeeper')
      : t('settings.organization.delete_modal.title.user')

  const handleCancelButtonClick = useCallback(() => {
    closeRemoveUserModal()
  }, [closeRemoveUserModal])

  const handleOkButtonClick = useCallback(() => {
    onOk?.()
  }, [onOk])

  return (
    <Modal id={id} closable size="s">
      <Modal.Header title={title} />
      <Modal.Body>
        <Text>{t('settings.organization.delete_modal.message.first')}</Text>
        <Styled.User>
          {userType === UserType.Bookkeeper && <FileAvatar logoId={logoId} />}
          <Text colorVariant="primary" weight="medium">
            {name}
          </Text>
        </Styled.User>
        <Text>{t('settings.organization.delete_modal.message.second')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={handleCancelButtonClick}>
            {t('settings.organization.delete_modal.cancel')}
          </Button>
          <Button onClick={handleOkButtonClick} danger loading={isLoading}>
            {t('settings.organization.delete_modal.remove')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
