import { Button, ButtonsGroup, Flex, ModuleLayout, Space } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { GetLoan as GetLoanIllustration } from '../../../assets/images'
import { useFrodaFinanceLendingContext } from './contexts/frodaFinanceLendingContext'
import { BenefitsList } from './elements/BenefitsList'
import { FrodaFinanceLendingHeader } from './elements/FrodaFinanceLendingHeader'
import { FrodaFinanceLendingModalsGroup } from './elements/FrodaFinanceLendingModalsGroup'
import { FrodaIframe } from './elements/FrodaIframe'
import { LendingTermsSwitch } from './elements/LendingTermsSwitch'
import * as Styled from './styles'

export const FrodaFinanceLending = (): ReactElement | null => {
  const { t } = useTranslation()

  const { homeAccessUrl, isAccessUrlLoading, isProcessing, onGetOfferClick, setTermsAccepted, termsAccepted } =
    useFrodaFinanceLendingContext()

  if (isAccessUrlLoading) {
    return null
  }

  if (homeAccessUrl) {
    return <FrodaIframe />
  }

  return (
    <ModuleLayout>
      <Styled.Container>
        <Flex flexGrow={1} flexDirection="column">
          <FrodaFinanceLendingHeader />
          <Space size="xxl" />
          <BenefitsList />
          <Space size="xxl" />
          <LendingTermsSwitch areTermsAccepted={termsAccepted} onTermsAcceptedChange={setTermsAccepted} />
          <Space size="xl" />
          <ButtonsGroup>
            <Button onClick={onGetOfferClick} disabled={!termsAccepted} loading={isProcessing}>
              {t('financing.froda_lending.button')}
            </Button>
          </ButtonsGroup>
        </Flex>
        <Flex flexGrow={1} alignItems="center">
          <GetLoanIllustration style={{ height: 300, width: 566 }} />
        </Flex>
      </Styled.Container>
      <FrodaFinanceLendingModalsGroup />
    </ModuleLayout>
  )
}
