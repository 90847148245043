import { AttachmentFile } from '@design-system'

import { Origin } from '../../enums/origin'
import { FileWithOrigin } from '../../types/fileWithOrigin'
import { postFileRequest } from '../../utils'

export interface UploadFileResponseData {
  files: AttachmentFile[]
}

export const uploadProfilePic = (file: File): Promise<UploadFileResponseData> => {
  const updatedFile: FileWithOrigin = file
  updatedFile.origin = Origin.Web

  return postFileRequest('/v2/user/profilePic', updatedFile, {})
}
