import { Checkbox, CheckboxGroup, Spacing } from '@design-system'

import { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'
import { ReportFilter } from './types/reportFilter'
import { ReportFilterValues } from './types/reportFilterValues'

const isFilterHidden =
  (hiddenFilters: ReportFilter[]) =>
  (filter: ReportFilter): boolean =>
    hiddenFilters.includes(filter)

const defaultValues: ReportFilterValues = {
  hideZero: true,
  showYtd: false, // Year To Date
  invertAmounts: false,
}

export interface ReportsFiltersProps {
  onFilterChange?: (filter: ReportFilter, value: boolean) => void
  filterValues?: ReportFilterValues
  hiddenFilters?: ReportFilter[]
}

export const ReportsFilters = ({ hiddenFilters, onFilterChange, filterValues }: ReportsFiltersProps): ReactElement => {
  const { t } = useTranslation()
  const [values, setValues] = useReducer(
    (prevState: ReportFilterValues, nextStatePartial: Partial<ReportFilterValues>) => ({
      ...prevState,
      ...nextStatePartial,
    }),
    defaultValues,
  )
  const isHidden = useMemo(() => (hiddenFilters ? isFilterHidden(hiddenFilters) : undefined), [hiddenFilters])

  const handleChange = useCallback(
    (filter: ReportFilter) => (event: ChangeEvent<HTMLInputElement>) => {
      setValues({ [filter]: event.currentTarget.checked })
      onFilterChange?.(filter, event.currentTarget.checked)
    },
    [onFilterChange],
  )

  useEffect(() => {
    if (filterValues) {
      setValues(filterValues)
    }
  }, [filterValues])

  return (
    <Styled.ReportsFiltersWrapper>
      <CheckboxGroup spacing={Spacing.L}>
        {!isHidden?.('hideZero') && (
          <Checkbox onChange={handleChange('hideZero')} checked={values.hideZero}>
            {t('reports.filters.hide_zeroes')}
          </Checkbox>
        )}
        {!isHidden?.('showYtd') && (
          <Checkbox onChange={handleChange('showYtd')} checked={values.showYtd}>
            {t('reports.filters.show_ytd')}
          </Checkbox>
        )}
        {!isHidden?.('invertAmounts') && (
          <Checkbox onChange={handleChange('invertAmounts')} checked={values.invertAmounts}>
            {t('reports.filters.invert_amounts')}
          </Checkbox>
        )}
      </CheckboxGroup>
    </Styled.ReportsFiltersWrapper>
  )
}
