import React from 'react'
import { Box } from 'rebass'

import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconMessageSquare = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-message-square')} color={color} {...rest}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      stroke={color2}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
    </svg>
  </Box>
)
