import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { UmbrellaOrganizationSubscriptionsFiltersProvider } from './context/umbrellaOrganizationSubscriptionsFiltersContext'
import { LicensePacksList } from './elements/LicensePacksList'
import { OrganizationSubscriptionsList } from './elements/OrganizationSubscriptionsList'
import { PaymentMethod } from './elements/PaymentMethod'
import * as Styled from './styles'

export const UmbrellaSubscriptions = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <ModuleLayout title={t('umbrella_subscriptions.title')}>
      <Styled.UmbrellaSubscriptionsWrapper>
        <Styled.LeftSideWrapper>
          <UmbrellaOrganizationSubscriptionsFiltersProvider>
            <LicensePacksList />
            <OrganizationSubscriptionsList />
          </UmbrellaOrganizationSubscriptionsFiltersProvider>
        </Styled.LeftSideWrapper>
        <PaymentMethod />
      </Styled.UmbrellaSubscriptionsWrapper>
    </ModuleLayout>
  )
}
