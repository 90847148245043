import { useMutation } from 'react-query'

import { initiateExternalInvoicesSync } from '../query-api'

interface UseInitiateExternalInvoicesSyncProps {
  onError?: () => void
  onSuccess?: () => void
}

export const useInitiateExternalInvoicesSync = (props?: UseInitiateExternalInvoicesSyncProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate: initiateSync,
    isLoading: isProcessing,
    ...rest
  } = useMutation(initiateExternalInvoicesSync, { onSuccess, onError })

  return { initiateSync, isProcessing, ...rest }
}
