import React, { ReactElement, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

import * as Styled from './styles'

export type LazyPreviewOptions = {
  rootElement?: HTMLElement
  rootMargin?: string
}

type LazyPreviewProps = {
  children: ReactNode
  options?: LazyPreviewOptions
}

export const LazyPreview = ({
  children,
  options: { rootElement, rootMargin = '0px' } = {},
}: LazyPreviewProps): ReactElement => {
  const [ref, inView] = useInView({
    root: rootElement,
    rootMargin,
    triggerOnce: true,
  })

  return <Styled.LazyWrapper ref={ref}>{inView && children}</Styled.LazyWrapper>
}
