import React, { createContext, ReactElement, ReactNode, useContext } from 'react'

import { useFetchSubscriptionPlans } from '../hooks/useFetchSubscriptionPlans'
import { ProductPlan } from '../types/productPlan'

interface ContextState {
  subscriptionPlans?: ProductPlan[]
}

const SubscriptionPlansContext = createContext<ContextState | undefined>(undefined)

interface SubscriptionPlansContextProps {
  children?: ReactNode
}

export const SubscriptionPlansContextProvider = ({ children }: SubscriptionPlansContextProps): ReactElement => {
  const { subscriptionPlans } = useFetchSubscriptionPlans()

  return <SubscriptionPlansContext.Provider value={{ subscriptionPlans }}>{children}</SubscriptionPlansContext.Provider>
}

export const useSubscriptionPlans = () => {
  const context = useContext(SubscriptionPlansContext)

  if (!context) {
    throw new Error('SubscriptionPlansContextProvider is missing in the module!')
  }

  return context
}
