import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconSmartphone = ({ color = '#FFFFFF', width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-smartphone')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 792">
      <g>
        <path
          fill={coloring(color)}
          d="M306,601.289c19.524,0,35.394,15.872,35.394,35.396c0,19.522-15.869,35.394-35.394,35.394
          c-19.523,0-35.395-15.871-35.395-35.394C270.605,617.161,286.477,601.289,306,601.289 M306,580.053
          c-31.274,0-56.631,25.356-56.631,56.632s25.357,56.631,56.631,56.631c31.275,0,56.632-25.355,56.632-56.631
          S337.275,580.053,306,580.053L306,580.053z"
        />
        <g>
          <rect fill={coloring(color)} x="192.737" y="141.158" width="226.525" height="410.578" />
        </g>
        <path
          fill={coloring(color)}
          d="M447.58,70.369c7.799,0,14.158,6.357,14.158,14.158v622.947c0,7.801-6.359,14.158-14.158,14.158H164.421
          c-7.802,0-14.158-6.357-14.158-14.158V84.526c0-7.801,6.356-14.158,14.158-14.158H447.58 M447.58,42.053H164.421
          c-23.46,0-42.474,19.014-42.474,42.474v622.947c0,23.459,19.014,42.474,42.474,42.474H447.58c23.458,0,42.473-19.015,42.473-42.474
          V84.526C490.053,61.066,471.038,42.053,447.58,42.053L447.58,42.053z"
        />
      </g>
    </svg>
  </Box>
)
