import { NavItem } from '@design-system'

import findLast from 'lodash/findLast'
import { array, bool, object, SchemaOf, string } from 'yup'

import { bankTransactionsItems } from '../elements/BusinessSummary/constants/bankTransactionsItems'
import { billsItems } from '../elements/BusinessSummary/constants/billsItems'
import { invoicesItems } from '../elements/BusinessSummary/constants/invoicesItems'
import { revenueItems } from '../elements/BusinessSummary/constants/revenueItems'

interface GetDefaultValuesArgs {
  bankTransactions?: number
  bills?: number
  invoices?: number
  revenue?: number
}

const getClosestDefaultValue = (items: NavItem<number[]>[], value?: number) => {
  if (value === undefined) {
    return
  }

  return findLast(items, ({ value: itemValue }) => value >= itemValue[0])?.id
}

export const getDefaultValues = ({ bankTransactions, bills, invoices, revenue }: GetDefaultValuesArgs) => ({
  expectations: undefined,
  isAccepted: undefined,
  isBusinessInfoCorrect: undefined,
  issue: undefined,
  summary: {
    bankTransactions: getClosestDefaultValue(
      bankTransactionsItems,
      bankTransactions !== undefined ? bankTransactions / 12 : undefined,
    ),
    bills: getClosestDefaultValue(billsItems, bills),
    description: undefined,
    invoices: getClosestDefaultValue(invoicesItems, invoices),
    revenue: getClosestDefaultValue(revenueItems, revenue),
  },
})

export interface FindAccountantFormSummary {
  bankTransactions: string | undefined
  bills: string | undefined
  description: string | undefined
  invoices: string | undefined
  revenue: string | undefined
}

export interface FindAccountantForm {
  expectations: string | undefined
  isAccepted: boolean | undefined
  isBusinessInfoCorrect: boolean | undefined
  issue: string[] | undefined
  summary: FindAccountantFormSummary
}

export type FindAccountantFormSchema = SchemaOf<FindAccountantForm>

export const getValidationSchema = (): FindAccountantFormSchema =>
  object({
    expectations: string().required(),
    isAccepted: bool().required(),
    isBusinessInfoCorrect: bool().required(),
    issue: array().required(),
    summary: object({
      bankTransactions: string().required(),
      bills: string().required(),
      description: string().required(),
      invoices: string().required(),
      revenue: string().required(),
    }).required(),
  })
