import { useCallback, useEffect } from 'react'

import { Timeout } from '../../../types/timeout'

const AUTOFOCUS_TIMEOUT = 250

export const useFocusOnFirstInput = (container: HTMLElement | null, shouldFocus?: boolean) => {
  const focusAndMoveCursorToTheEnd = useCallback((element: HTMLInputElement | HTMLTextAreaElement) => {
    const end = element.value?.length

    if (element.type !== 'checkbox') {
      element.setSelectionRange?.(end, end)
    }

    element.focus()
  }, [])

  useEffect(() => {
    let autofocusTimeout: Timeout

    if (container && shouldFocus) {
      autofocusTimeout = setTimeout(() => {
        const focusableElement = container.querySelectorAll(
          'input:not(:disabled):not([readonly]):not([type=hidden]),textarea:not(:disabled):not([readonly])',
        )?.[0] as undefined | HTMLInputElement | HTMLTextAreaElement

        if (focusableElement) {
          focusAndMoveCursorToTheEnd(focusableElement)
        }
      }, AUTOFOCUS_TIMEOUT)

      return () => {
        if (autofocusTimeout) {
          clearTimeout(autofocusTimeout)
        }
      }
    }
  }, [container, focusAndMoveCursorToTheEnd, shouldFocus])
}
