import { css, SerializedStyles } from '@emotion/core'

import { BorderRadius } from '../../../enums/borderRadius'
import { FontSize } from '../../../enums/fontSize'
import { FontWeight } from '../../../enums/fontWeight'
import { LineHeight } from '../../../enums/lineHeight'
import { Spacing } from '../../../enums/spacing'
import { Size } from '../types'
import { getWidthProperty } from './getWidthProperty'

export const sizeToDimension: Record<Size, number> = {
  m: 32,
  l: 40,
  xl: 48,
  xxl: 56,
}

export const getSizeStyle = (size: Size, forceSquareRatio?: boolean): SerializedStyles =>
  ({
    m: css`
      ${getWidthProperty(forceSquareRatio)}: 32px;
      height: ${sizeToDimension[size]}px;
      border-radius: ${BorderRadius.M};
      padding: ${Spacing.S} ${forceSquareRatio ? Spacing.S : Spacing.L};
      font-size: ${FontSize.TextNormal};
      font-weight: ${FontWeight.Medium};
      line-height: ${LineHeight.TextNormal};
    `,
    l: css`
      ${getWidthProperty(forceSquareRatio)}: 40px;
      height: ${sizeToDimension[size]}px;
      border-radius: ${BorderRadius.L};
      padding: ${Spacing.S} ${forceSquareRatio ? Spacing.S : Spacing.L};
      font-size: ${FontSize.TextNormal};
      font-weight: ${FontWeight.Medium};
      line-height: ${LineHeight.TextNormal};
    `,
    xl: css`
      ${getWidthProperty(forceSquareRatio)}: 48px;
      height: ${sizeToDimension[size]}px;
      border-radius: ${BorderRadius.L};
      padding: ${Spacing.M} ${forceSquareRatio ? Spacing.M : Spacing.L};
      font-size: ${FontSize.TextNormal};
      font-weight: ${FontWeight.Medium};
      line-height: ${LineHeight.TextNormal};
    `,
    xxl: css`
      ${getWidthProperty(forceSquareRatio)}: 56px;
      height: ${sizeToDimension[size]}px;
      border-radius: ${BorderRadius.L};
      padding: ${Spacing.S} ${forceSquareRatio ? Spacing.S : Spacing.L};
      font-size: ${FontSize.TextNormal};
      font-weight: ${FontWeight.Medium};
      line-height: ${LineHeight.TextNormal};
    `,
  })[size]
