import { TFunction } from 'i18next'
import { array, object, SchemaOf, string } from 'yup'

import { UmbrellaMember } from '../../../../../../../query-api/umbrella-members-query'
import { getUserRoleIds } from './getUserRoleIds'

export interface InviteUmbrellaUserForm {
  email: string
  roles: string[] | undefined
}

export type InviteUmbrellaUserFormSchema = SchemaOf<InviteUmbrellaUserForm>

export const getValidationSchema = (t: TFunction): InviteUmbrellaUserFormSchema =>
  object({
    email: string().required(t('umbrella_users.create_edit_user_modal.error.empty_email')),
    roles: array(),
  })

export const getDefaultValues = (user?: UmbrellaMember): InviteUmbrellaUserForm => ({
  email: user?.email || '',
  roles: getUserRoleIds(user?.roles) || [],
})
