import * as React from 'react'

import { useUserOrganizationSettings } from '@modules-deprecated/app/organization'

import { ConnectionInboxDisabled } from './elements/ConnectionInboxDisabled'
import { ConnectionInboxEnabled } from './elements/ConnectionInboxEnabled'
import * as Styled from './styles'

export interface BankConnectionProps {
  accountBalanceAmount?: number
  accountCurrencyId?: string
  bankBalanceAmount?: number
  isError: boolean
  isLoading: boolean
}

export const BankConnection = ({
  accountBalanceAmount,
  accountCurrencyId,
  bankBalanceAmount,
  isError,
  isLoading,
}: BankConnectionProps) => {
  const { organizationSettings } = useUserOrganizationSettings()
  const isInboxEnabled = organizationSettings?.isInboxEnabled === '1'

  return (
    <Styled.BankConnectionWrapper>
      {isLoading ? (
        <Styled.SkeletonBox fullWidth height={150} />
      ) : (
        <Styled.InnerContainer>
          {isInboxEnabled ? (
            <ConnectionInboxEnabled isConnectionError={isError} />
          ) : (
            <ConnectionInboxDisabled
              accountBalanceAmount={accountBalanceAmount}
              accountCurrencyId={accountCurrencyId}
              bankBalanceAmount={bankBalanceAmount}
              isConnectionError={isError}
            />
          )}
        </Styled.InnerContainer>
      )}
    </Styled.BankConnectionWrapper>
  )
}
