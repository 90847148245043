import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { getShadow } from '../../../utils/getShadow'
import { Variant } from '../types'

export const getDangerStyle = (variant: Variant): SerializedStyles =>
  ({
    primary: css`
      background-color: ${Color.Red};
      color: ${Color.White};
      ${getShadow('raisedLight')};

      &:disabled {
        background-color: ${Color.Red5};
        box-shadow: none;
        color: ${Color.Red10};
      }
      &:not(:disabled) {
        &:hover {
          background-color: ${Color.Red120};
          color: ${Color.White};
          ${getShadow('raisedLight')};
        }
        &:active {
          background-color: ${Color.Red110};
          color: ${Color.White};
          ${getShadow('raisedLight')};
        }
      }
    `,
    secondary: css`
      background-color: ${Color.White};
      color: ${Color.Red};
      ${getShadow('raisedLight', { borderColor: Color.Red })};

      &:disabled {
        background-color: ${Color.Red5};
        box-shadow: none;
        color: ${Color.Red10};
      }
      &:not(:disabled) {
        &:hover {
          background-color: ${Color.Red};
          color: ${Color.White};
          ${getShadow('raisedLight')};
        }
        &:active {
          background-color: ${Color.Red10};
          box-shadow: none;
          color: ${Color.Red};
        }
      }
    `,
    text: css`
      color: ${Color.Red};

      &:not(:disabled) {
        &:hover {
          background-color: ${Color.Red};
          color: ${Color.White};
        }
        &:active {
          background-color: ${Color.Gray30};
          color: ${Color.Red};
        }
      }
    `,
  })[variant]
