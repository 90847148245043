/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Modal /> from design-system instead
 */
import { IconButton, PORTAL_CLASS_NAME, transitionToMiliseconds } from '@design-system'

import { SerializedStyles } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { BoxProps } from 'rebass'

import { useKeyDown } from '../../hooks'
import { Theme } from '../../types/theme'
import { Timeout } from '../../types/timeout'
import * as Styled from './styles'

const AUTOFOCUS_TIMEOUT = 250

export type ModalVariant = 'default' | 'wide'

export type ModalProps = BoxProps & {
  autofocus?: boolean
  css?: SerializedStyles
  disabled?: boolean
  isOpen?: boolean
  onClose?: () => void
  required?: boolean
  variant?: ModalVariant
  width?: number
}

export const Modal = ({
  autofocus,
  children,
  className,
  css: styles,
  isOpen = false,
  onClose,
  required,
  variant = 'default',
  width,
  css: outsideCss,
  ...rest
}: ModalProps) => {
  const [open, setOpen] = useState(isOpen) // used for setting animation class
  const [renderModal, setRenderModal] = useState(isOpen) // used for appending modal to body
  const modalRef = useRef<HTMLDivElement | null>(null)

  const {
    transitions: { fast },
  } = useTheme<Theme>()

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus()
    }
  }, [modalRef])

  const handleClose = useCallback(() => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const handleOverlayClick = useCallback(() => {
    if (!required && open) {
      handleClose()
    }
  }, [handleClose, required, open])

  useKeyDown(
    'Escape',
    () => {
      // Note that this will work only if the modal is in focus
      // You will likely have to implement a similar action on the outer component
      if (open) {
        handleClose()
      }
    },
    { disabled: required },
  )

  useEffect(() => {
    if (isOpen) {
      setRenderModal(true)
      setTimeout(() => setOpen(true), 0) // we have to make sure it will invoke after previous line code is finished
    } else {
      setOpen(false)
      setTimeout(() => setRenderModal(false), transitionToMiliseconds(fast))
    }
  }, [fast, isOpen])

  useEffect(() => {
    let autofocusTimeout: Timeout

    if (autofocus) {
      autofocusTimeout = setTimeout(() => {
        const input = modalRef.current?.querySelector('input')
        input && input.focus()
      }, AUTOFOCUS_TIMEOUT)
    }

    return () => {
      if (autofocusTimeout) {
        clearTimeout(autofocusTimeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return ReactDOM.createPortal(
    renderModal ? (
      <>
        <Styled.ModalWrapper className={PORTAL_CLASS_NAME}>
          <Styled.Modal ref={modalRef} visible={open} className={className} css={styles} width={width}>
            {children}
            {!required && (
              <Styled.CloseButtonWrapper onClick={handleClose}>
                <IconButton icon="xSign" />
              </Styled.CloseButtonWrapper>
            )}
          </Styled.Modal>
        </Styled.ModalWrapper>

        <Styled.Overlay className={PORTAL_CLASS_NAME} visible={open} onClick={handleOverlayClick} />
      </>
    ) : null,
    document.body,
  )
}
