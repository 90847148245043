import { ApiContentType } from '../../../../enums/apiContentType'
import { getRequest, putRequest, request } from '../../../../utils'
import { Task } from './types/task'

interface TasksResponseData {
  data: Task[]
}

interface CreateTaskPayload {
  description: string
  deadline?: string
}

export const createTask = (organizationId: string, data: CreateTaskPayload) => {
  return request(
    `/organizations/${organizationId}/tasks`,
    {
      method: 'POST',
      body: JSON.stringify({ data }),
    },
    {
      withAuth: true,
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  )
}

export const updateTaskCompletion = (taskId: string, organizationId: string): Promise<void> => {
  return putRequest(`/organizations/${organizationId}/tasks/${taskId}/completion`, {})
}

export const updateTaskApproval = (taskId: string, organizationId: string): Promise<void> => {
  return putRequest(`/organizations/${organizationId}/tasks/${taskId}/approval`, {})
}

export const fetchTasks = (organizationId: string): Promise<TasksResponseData> => {
  return getRequest(`/organizations/${organizationId}/tasks`)
}
