import { useQuery } from 'react-query'

import { InvoiceDocumentType } from '../../../enums/invoiceDocumentType'
import { fetchCreditNote, fetchInvoice } from '../../../query-api'
import { getInvoiceQueryKey } from '../../../utils/getInvoiceQueryKey'

const fetchDocument: Partial<Record<InvoiceDocumentType, Function>> = {
  [InvoiceDocumentType.Invoice]: fetchInvoice,
  [InvoiceDocumentType.CreditNote]: fetchCreditNote,
}

export const useFetchInvoice = (invoiceId?: string, documentType?: InvoiceDocumentType) => {
  const { data, isLoading, ...rest } = useQuery(
    getInvoiceQueryKey(invoiceId, documentType),
    () => fetchDocument[documentType || '']?.(invoiceId),
    { enabled: !!invoiceId && !!documentType },
  )

  return { data, isLoading, ...rest }
}
