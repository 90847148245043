import React, { ReactElement, useMemo } from 'react'

import { Color } from '../../../enums/color'
import { ColorOrCurrent } from '../../../types/colorOrCurrent'
import { Icon, IconName } from '../../Icon'
import { Size as ButtonSize } from '../types'
import * as Styled from './styles'
import { Position } from './types'

export interface ButtonIconProps {
  color?: Color
  icon?: IconName
  isVisible: boolean
  loading?: boolean
  position: Position
  size: ButtonSize
  withIconMargin?: boolean
}

export const ButtonIcon = ({
  color,
  icon,
  isVisible,
  loading,
  position,
  size,
  withIconMargin,
}: ButtonIconProps): ReactElement | null => {
  const iconColor: ColorOrCurrent = color || 'currentColor'

  const iconWrapperProps = useMemo(
    () => ({
      visible: isVisible,
      loading,
      position,
      size,
      withIconMargin,
    }),
    [loading, position, size, withIconMargin, isVisible],
  )

  return (
    // It has to be always wrapped in element due to the chromium bug:
    // https://github.com/facebook/react/issues/11538
    <Styled.IconWrapper {...iconWrapperProps}>
      {useMemo(() => {
        if (loading) {
          return <Icon color={iconColor} icon="circleDashed" />
        }

        if (!icon) {
          return null
        }

        return typeof icon === 'string' ? <Icon color={iconColor} icon={icon} /> : icon
      }, [icon, iconColor, loading])}
    </Styled.IconWrapper>
  )
}
