import get from 'lodash/get'
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DocumentProps, PageProps, pdfjs, PDFPageProxy } from 'react-pdf'

import { useImageZoom } from '../../../../hooks/useImageZoom'
import { useMeasureDirty } from '../../../../hooks/useMeasureDirty'
import { Flex } from '../../../Flex'
import { Image, ImageMagnifier } from '../../../Image'
import { FileType, FitType } from '../../types'
import { getFileType } from '../../utils/getFileType'
import { FileErrorThumbnail, FileLockedThumbnail } from '../FileThumbnails'
import { LIMIT_CANVAS_WIDTH_CLASS_NAME } from './constants/limitCanvasWidthClassName'
import { PdfDocument } from './elements/PdfDocument'
import * as Styled from './styles'

const MINIMUM_SCALE = 2

// Required by the react-pdf library:
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const documentOptions: DocumentProps['options'] = {
  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
}

type ErrorType = 'default' | 'password-protected'

interface PdfReactProps {
  activePage: number
  fitType: FitType // works only when both fitToHeight and fitToWidth are set to 'true'
  height?: number
  loading?: ReactElement | string
  onLoad?: () => void
  onPdfLoad: (pagesCount: number) => void
  src: string
  srcZoom?: string
  width?: number
  zoomable?: boolean
  zoomScale: number
}

export const PdfReact = memo((props: PdfReactProps): ReactElement => {
  const {
    activePage,
    fitType,
    height = 0,
    loading,
    onLoad,
    onPdfLoad,
    src,
    srcZoom,
    width = 0,
    zoomable,
    zoomScale,
  } = props
  const emptyRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const imageMagnifierRef = useRef<HTMLDivElement>(null)
  const pdfZoomedRef = useRef<HTMLDivElement>(null)
  const hasScaleBeenSet = useRef(false)
  const [previewScale, setPreviewScale] = useState(1)
  const [error, setError] = useState<ErrorType>()
  const isError = !!error

  const srcFileType = getFileType(src)
  const pageHeight = height || undefined

  const pageWidth = width && (height > width || !height) && fitType !== 'contain' ? width : undefined // giving "width" makes "height" to be ignored

  const { width: wrapperWidth = 0, height: wrapperHeight = 0 } = useMeasureDirty(wrapperRef)
  const { imageZoomedTranslateX, imageZoomedTranslateY, magnifierTranslateX, magnifierTranslateY } = useImageZoom({
    imageWrapperRef: zoomable ? wrapperRef : emptyRef,
    imageZoomedRef: pdfZoomedRef,
    imageMagnifierRef,
  })

  const scale = Math.max(MINIMUM_SCALE, zoomScale)

  useEffect(() => {
    if (pdfZoomedRef.current) {
      pdfZoomedRef.current.style.width = `${wrapperWidth * scale}px`
      pdfZoomedRef.current.style.height = `${wrapperHeight * scale}px`
    }
  }, [wrapperWidth, wrapperHeight, pdfZoomedRef, scale])

  useEffect(() => {
    if (!zoomable) {
      return
    }

    if (pdfZoomedRef.current) {
      pdfZoomedRef.current.style.transform = `translate(${imageZoomedTranslateX}, ${imageZoomedTranslateY})`
    }

    if (imageMagnifierRef.current) {
      imageMagnifierRef.current.style.transform = `translate(${magnifierTranslateX}, ${magnifierTranslateY})`
    }
  }, [imageZoomedTranslateX, imageZoomedTranslateY, magnifierTranslateX, magnifierTranslateY, zoomable])

  useEffect(() => {
    hasScaleBeenSet.current = false
  }, [src, pageWidth])

  const handleLoad = useCallback(
    (pdf: pdfjs.PDFDocumentProxy) => {
      const { numPages } = pdf

      onPdfLoad(numPages)
      onLoad?.()
    },
    [onLoad, onPdfLoad],
  )

  const adjustHorizontalPdfsScale = useCallback(
    (page: PDFPageProxy) => {
      const wrapperElement = wrapperRef.current

      if (!height || !width || fitType !== 'contain' || !wrapperElement) {
        return
      }

      if (page.originalHeight > page.originalWidth) {
        setPreviewScale(1)
        hasScaleBeenSet.current = true
        return
      }

      const pageScale = Math.round((wrapperElement.clientWidth / page.width) * 100) / 100

      if (pageScale < 1) {
        setPreviewScale(pageScale)
        hasScaleBeenSet.current = true
      }
    },
    [fitType, height, width],
  )

  const handlePageLoad = useCallback(
    (page: PDFPageProxy) => {
      if (!hasScaleBeenSet.current) {
        adjustHorizontalPdfsScale(page)
      }
    },
    [adjustHorizontalPdfsScale],
  )

  const handlePassword = useCallback(() => {
    console.error(`Pdf ${src} is password protected!`)
    setError('password-protected')
  }, [src])

  const handleLoadError = useCallback(
    (error: Error) => {
      console.error(`Error while displaying pdf file: ${src}`, { error, details: get(error, 'details') || '' })
      setError('default')
    },
    [src],
  )

  const handleSourceError = useCallback(
    (error: Error) => {
      console.error(`Error while getting pdf file: ${src}`, error)
      setError('default')
    },
    [src],
  )

  const documentProps: DocumentProps = useMemo(
    () => ({
      file: srcZoom || src,
      options: documentOptions,
      loading,
      externalLinkTarget: '_blank',
    }),
    [loading, src, srcZoom],
  )

  const pageProps: PageProps = useMemo(
    () => ({
      height: pageHeight,
      width: pageWidth,
      pageIndex: activePage,
      loading,
    }),
    [activePage, loading, pageHeight, pageWidth],
  )

  const previewDocumentProps: DocumentProps = useMemo(
    () => ({
      ...documentProps,
      onPassword: handlePassword,
      onLoadError: handleLoadError,
      onLoadSuccess: handleLoad,
      onSourceError: handleSourceError,
      externalLinkTarget: '_blank',
      className: LIMIT_CANVAS_WIDTH_CLASS_NAME,
    }),
    [documentProps, handleLoad, handleLoadError, handlePassword, handleSourceError],
  )

  const previewPageProps: PageProps = useMemo(
    () => ({
      ...pageProps,
      scale: previewScale,
      onLoadSuccess: handlePageLoad,
    }),
    [handlePageLoad, pageProps, previewScale],
  )

  const zoomedDocumentProps: DocumentProps = useMemo(
    () => ({
      ...documentProps,
      externalLinkTarget: '_blank',
    }),
    [documentProps],
  )

  const zoomedPageProps: PageProps = useMemo(
    () => ({
      ...pageProps,
      scale: scale * previewScale,
    }),
    [pageProps, previewScale, scale],
  )

  return (
    <Styled.PdfReactWrapper isError={isError} width={width || '100%'} ref={wrapperRef}>
      <Flex justifyContent="center">
        {isError ? (
          <Styled.ErrorWrapper>
            {error === 'password-protected' ? <FileLockedThumbnail /> : <FileErrorThumbnail />}
          </Styled.ErrorWrapper>
        ) : (
          <>
            {srcFileType === FileType.Pdf ? (
              <PdfDocument
                key={previewPageProps.scale}
                documentProps={previewDocumentProps}
                pageProps={previewPageProps}
              />
            ) : (
              <Image src={src} objectFit={fitType} />
            )}
            {zoomable && (
              <Styled.PdfZoomedWrapper draggable={false}>
                <ImageMagnifier ref={imageMagnifierRef}>
                  <Styled.DocumentZoomedWrapper ref={pdfZoomedRef}>
                    <PdfDocument
                      key={zoomedPageProps.scale}
                      documentProps={zoomedDocumentProps}
                      pageProps={zoomedPageProps}
                    />
                  </Styled.DocumentZoomedWrapper>
                </ImageMagnifier>
              </Styled.PdfZoomedWrapper>
            )}
          </>
        )}
      </Flex>
    </Styled.PdfReactWrapper>
  )
})
