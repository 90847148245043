import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { isSameDay } from 'date-fns'
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Flex } from 'rebass'

import { DateInput } from '../DateInput'
import { Checkbox as CheckboxComponent } from './Checkbox'
import { FormControl } from './FormControl'
import { FormLabel } from './FormLabel'

const Checkbox = styled(CheckboxComponent)`
  display: flex;
  width: auto;
  margin-right: 0;
  margin-bottom: 0;
  justify-content: center;
`

export type DateCheckboxResult = {
  date?: Date
  checked: boolean
}

export type DateCheckboxComponentProps = {
  checkboxLabel?: string
  checked?: boolean
  date?: Date
  dateInputErrorPath?: string
  dateInputLabel?: string
  disabled?: boolean
  disconnect?: boolean
  formControl?: Control<any>
  name?: string
  onChange?: (data: DateCheckboxResult) => void
  required?: boolean
  silent?: boolean
}

const DateCheckboxComponent = ({
  checkboxLabel,
  checked: checkedInitial,
  date: dateInitial,
  dateInputErrorPath,
  dateInputLabel,
  disabled,
  disconnect,
  formControl,
  name = 'datecheckbox',
  onChange,
  required,
}: DateCheckboxComponentProps) => {
  const [date, setDate] = useState<Date>()
  const [checked, setChecked] = useState(true)

  useEffect(() => {
    if (!date) {
      setDate(dateInitial)
      return
    }

    if (date && dateInitial && !isSameDay(dateInitial, date)) {
      setDate(dateInitial)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateInitial])

  useEffect(() => {
    if (checkedInitial !== undefined && checkedInitial !== checked) {
      setChecked(checkedInitial)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedInitial])

  const onDateChange = (newDate: Date) => {
    setDate(newDate)

    if (onChange) {
      onChange({
        date: newDate,
        checked,
      })
    }
  }

  const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checkedUpdated = event.target.checked
    setChecked(checkedUpdated)

    if (onChange) {
      onChange({
        date,
        checked: checkedUpdated,
      })
    }
  }

  return (
    <Flex flexDirection="row" width="100%">
      <Flex
        flexDirection="column"
        css={css`
          flex-grow: 1;
          align-items: flex-end;

          input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        `}
      >
        <DateInput
          formControl={formControl}
          name={`${name}_date`}
          label={dateInputLabel}
          defaultValue={date}
          disabled={disabled}
          disconnect={disconnect}
          required={required}
          onChange={onDateChange}
          errorPath={dateInputErrorPath}
          width="100%"
          css={css`
            margin-right: -1px;
          `}
        />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="end"
        css={css`
          flex-shrink: 0;
        `}
      >
        <FormControl>
          <FormLabel name="checkbox">{checkboxLabel}</FormLabel>
          <Flex
            variant="forms.datecheckbox.checkboxwrapper"
            alignItems="center"
            justifyContent="center"
            className={disabled ? 'disabled' : ''}
            css={css`
              width: auto;
              height: 40px;
              border-left-color: transparent;
              justify-content: center;
              align-items: center;

              label span {
                display: none;
              }
            `}
          >
            <Checkbox
              name={`${name}_checked`}
              width="22px"
              checked={checked}
              disabled={disabled}
              onChange={onCheckboxChange}
              disconnect={disconnect}
            />
          </Flex>
        </FormControl>
      </Flex>
    </Flex>
  )
}

// Controlled
export type DateCheckboxControlledResult = {
  value?: Date
  checked: boolean
}

export type DateCheckboxProps = DateCheckboxComponentProps & {
  withChangeEvent?: boolean
}

export const DateCheckbox = ({ formControl, name = '', withChangeEvent, ...rest }: DateCheckboxProps): ReactElement => {
  if (!formControl) {
    return <DateCheckboxComponent {...rest} name={name} />
  }

  return (
    <Controller
      render={({ field }) => {
        const value = field.value as DateCheckboxControlledResult

        return (
          <DateCheckboxComponent
            {...rest}
            name={field.name}
            date={value?.value}
            checked={value?.checked}
            onChange={({ date, checked }: DateCheckboxResult) => {
              const data = {
                value: date,
                checked: checked === undefined ? value?.checked : checked,
              } as DateCheckboxControlledResult

              field.onChange(data)
              withChangeEvent && rest.onChange && rest.onChange(data)
            }}
            dateInputErrorPath={`${field.name}.value`}
            disconnect
          />
        )
      }}
      control={formControl}
      name={name}
    />
  )
}
