import { Color, ComponentsGroup, DefaultValue, Divider, getShadow, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const CustomTooltipWrapper = styled.div`
  width: 216px;
  height: 164px;
  background-color: ${Color.White};
  border-radius: ${DefaultValue.BorderRadius};
  ${getShadow('elevatedLight')};
  padding: ${Spacing.L};
`

export const TooltipComponentsGroup = styled(ComponentsGroup)`
  justify-content: space-between;
  margin-top: ${Spacing.S};
`

export const TooltipDivider = styled(Divider)`
  margin-top: ${Spacing.S};
`

export const TooltipData = styled.div``
