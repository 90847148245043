import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyScreen } from '../../components-deprecated/EmptyScreen'
import { RelaxImage } from '../../components-deprecated/RelaxImage'
import { MissingInformations } from './elements/MissingInformations'
import { MissingReceipts } from './elements/MissingReceipts'
import { useToDoCount } from './hooks/useToDoCount'

export const ThingsToDo = (): ReactElement => {
  const { t } = useTranslation()
  const { count, isLoading } = useToDoCount()

  const isToDoListEmpty = !isLoading && count === 0

  return (
    <ModuleLayout title={t('things_to_do')} fullHeight={isToDoListEmpty}>
      {isToDoListEmpty ? (
        <EmptyScreen
          headerText={t('things_to_do.empty.header')}
          subText={t('things_to_do.empty.subText')}
          img={<RelaxImage />}
        />
      ) : (
        <>
          <MissingInformations />
          <MissingReceipts />
        </>
      )}
    </ModuleLayout>
  )
}
