import { Account } from '@modules-deprecated/app/accounts/types'

import { PaymentMethod } from '../../../../../query-api/payment-methods-query'

export const hasCompletePaymentMethod = (paymentMethods: PaymentMethod[], accounts: Account[]) => {
  const bankAccountIds = paymentMethods.map(({ bankAccountId }) => bankAccountId)
  const paymentMethodAccounts = accounts.filter(({ id }) => bankAccountIds.includes(id))
  const hasPaymentMethodWithAccountNumber = paymentMethodAccounts.some(({ bankAccountNo }) => !!bankAccountNo?.length)

  return hasPaymentMethodWithAccountNumber
}
