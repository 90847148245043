import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { Box, Flex, FlexProps } from 'rebass'

import { Themable } from '../../types/themable'
import { IconCross } from '../Icons'

type FlexAndThemeable = FlexProps & Themable

type FileListContainerProps = FlexAndThemeable & {
  sameHeightFileList?: boolean
}

export const FileListContainer = styled(Flex)<FileListContainerProps>`
  width: 100%;
  height: ${({ sameHeightFileList }) => (sameHeightFileList ? '160px' : '275px')};
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.colors.greyscale4}`};
  position: relative;
  box-shadow: ${({ theme }) => `0 1px 3px ${transparentize(0.85, theme.colors.black)}`};
  overflow: auto;
`

export const FileListScrollableArea = styled(Flex)<FlexAndThemeable>`
  padding: ${Spacing.M};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  flex-direction: column;
  background-color: ${Color.White};
  overflow: auto;
`

type FileItemProps = FlexProps & Themable

export const FileItem = styled(Flex)<FileItemProps>`
  width: 100%;
  align-items: center;

  & + & {
    border-top: ${({ theme }) => `1px solid ${theme.colors.greyscale4}`};
    margin-top: 6px;
    padding-top: 6px;
  }
`

export const ButtonWrapper = styled.div`
  margin-left: ${Spacing.S};
  flex-shrink: 0;
`

export const CloseButton = styled(IconCross)<Themable>`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.colors.greyscale2};
  cursor: pointer;
`

export const FileActionsContainer = styled(Box)`
  margin-left: auto;
`
