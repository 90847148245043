import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'
import { transparentize } from 'polished'

interface ListItemProps {
  focused?: boolean
}

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  padding: ${Spacing.S} ${Spacing.L};
  cursor: pointer;
  background-color: ${({ focused }) => (focused ? transparentize(0.75, Color.Gray60) : Color.White)};
  border-top: 1px solid ${Color.Gray30};

  &:first-of-type {
    border-top: none;
  }
  &:hover {
    background-color: ${transparentize(0.75, Color.Gray60)};
  }
`

export const IconWrapper = styled.figure`
  margin-top: ${Spacing.XXS};
  margin-right: ${Spacing.M};
`

export const ContentWrapper = styled.div``
