import { DownloadStatus } from '../types/downloadStatus'

export const downloadStatusToTKeys: Record<DownloadStatus, { title: string; description: string }> = {
  pending: {
    title: 'download_export_task.processing.title',
    description: 'download_export_task.processing.description',
  },
  processing: {
    title: 'download_export_task.processing.title',
    description: 'download_export_task.processing.description',
  },
  success: {
    title: 'download_export_task.success.title',
    description: 'download_export_task.success.description',
  },
  error: {
    title: 'download_export_task.error.title',
    description: 'download_export_task.error.description',
  },
  timeout: {
    title: 'download_export_task.timeout.title',
    description: 'download_export_task.timeout.description',
  },
}
