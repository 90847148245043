import { BooleanString, ReturnValue } from './convertAccountingFormDataForSubmit'

export const convertDuplicateBillCheckToNumber = (value: BooleanString): ReturnValue => {
  if (value === '' || value === true) {
    return ''
  }
  return '1'
}

export const convertDuplicateBillCheckToBoolean = (value: BooleanString): boolean => {
  if (value === '' || value === true) {
    return true
  }
  return false
}
