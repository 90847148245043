import { ModalConfirmation, ModalConfirmationProps, notify, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../enums/queryKeys'
import { APIError } from '../../../../../../utils'
import { useDeleteUmbrellaUserInvitation } from './hooks/useDeleteUmbrellaUserInvitation'

interface DeleteUmbrellaInvitationModalProps extends ModalConfirmationProps {
  umbrellaId: string
  invitationId: string
}

export const DeleteUmbrellaInvitationModal = ({
  umbrellaId,
  invitationId,
  ...props
}: DeleteUmbrellaInvitationModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeDeleteUmbrellaInvitationModal } = useModal(props.id)
  const { deleteUmbrellaUserInvitation, isProcessing } = useDeleteUmbrellaUserInvitation({
    onSuccess: () => {
      closeDeleteUmbrellaInvitationModal()
      notify({
        id: NotificationKeys.UmbrellaUserInvitationDelete,
        message: t('umbrella_users.deleteInviteModal.success'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.UmbrellaInvitedUsers)
    },
    onError: (error?: APIError) => {
      notify({
        id: NotificationKeys.UmbrellaUserInvitationDelete,
        message: error?.message,
        variant: 'error',
      })
    },
  })

  const handleDeleteClick = useCallback(() => {
    deleteUmbrellaUserInvitation({ umbrellaId, invitationId })
  }, [deleteUmbrellaUserInvitation, umbrellaId, invitationId])

  return (
    <ModalConfirmation
      {...props}
      cancelLabel={t('cancel')}
      danger
      message={t('umbrella_users.deleteInviteModal.body')}
      okLabel={t('umbrella_users.deleteInviteModal.ok')}
      okLoading={isProcessing}
      onOk={handleDeleteClick}
      title={t('umbrella_users.deleteInviteModal.title')}
    />
  )
}
