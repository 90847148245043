import { DateFormatter } from '@components'
import { Color, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { getPassedTimeText } from '../../../../../../utils/getPassedTimeText'
import { BillStatus } from '../../../../enums/billStatus'

interface BillPaymentPaneExtraProps {
  date: string | null
  status: BillStatus
}

export const BillPaymentPaneExtra = ({ date, status }: BillPaymentPaneExtraProps): ReactElement | null => {
  const { t } = useTranslation()

  if (date) {
    if (status === BillStatus.Paid || status === BillStatus.Draft) {
      return (
        <Text colorVariant="secondary" variant="small">
          <DateFormatter value={date} />
        </Text>
      )
    }

    if (status === BillStatus.Unpaid) {
      return (
        <>
          <Text colorVariant="secondary" variant="micro">
            {t('bills.view.payment_pane.due')}
          </Text>
          <Text colorVariant="secondary" variant="small">
            <DateFormatter value={date} />
          </Text>
        </>
      )
    }

    if (status === BillStatus.Overdue) {
      const passedTimeText = getPassedTimeText(date, t)

      return (
        <Text colorVariant="secondary" variant="small" color={Color.Red} truncate>
          {passedTimeText}
        </Text>
      )
    }
  }

  return null
}
