import { BadgeVariant } from '@design-system'

import { BillStatus } from '../enums/billStatus'

export const billStatusToBadgeVariant: Record<BillStatus, BadgeVariant> = {
  [BillStatus.All]: 'neutral',
  [BillStatus.Draft]: 'primary',
  [BillStatus.Overdue]: 'error',
  [BillStatus.Paid]: 'success',
  [BillStatus.PartiallyPaid]: 'warning',
  [BillStatus.Unpaid]: 'warning',
  [BillStatus.Voided]: 'neutral',
}
