import { useTranslation } from 'react-i18next'

import { Button } from '../../../Button'
import { ButtonsGroup } from '../../../ButtonsGroup'
import { FileButton } from '../../../FileButton'
import * as Styled from './styles'

interface FileActionsProps {
  handleDeleteClick: () => void
  handleDownloadClick: () => void
  handleRotateClick: () => void
  handleFileSelect: (files: File[]) => void
  onImageZoomClick?: () => void
  shouldRenderRotateButton: boolean
  withDeleteButton: boolean
  withDownloadButton?: boolean
  withEditButton: boolean
  withModalZoom: boolean
}

export const FileActions = ({
  handleDeleteClick,
  handleDownloadClick,
  handleRotateClick,
  handleFileSelect,
  onImageZoomClick,
  shouldRenderRotateButton,
  withDeleteButton,
  withDownloadButton,
  withEditButton,
  withModalZoom,
}: FileActionsProps) => {
  const { t } = useTranslation()

  return (
    <Styled.ActionsWrapper>
      <ButtonsGroup>
        {shouldRenderRotateButton && (
          <Button
            icon="rightArrowAroundSquare"
            onClick={handleRotateClick}
            size="m"
            title={t('rotate')}
            variant="secondary"
          />
        )}
        {withDownloadButton && (
          <Button
            icon="arrowPointingDown"
            onClick={handleDownloadClick}
            size="m"
            title={t('download')}
            variant="secondary"
          />
        )}
        {withEditButton && (
          <FileButton
            icon="paperWithPencil"
            size="m"
            title={t('edit')}
            variant="secondary"
            onFilesSelect={handleFileSelect}
          />
        )}
        {withModalZoom && (
          <Button
            icon="magnifyingGlassPlus"
            onClick={onImageZoomClick}
            size="m"
            title={t('full_screen')}
            variant="secondary"
          />
        )}
        {withDeleteButton && (
          <Button icon="trash" onClick={handleDeleteClick} size="m" title={t('delete')} variant="secondary" />
        )}
      </ButtonsGroup>
    </Styled.ActionsWrapper>
  )
}
