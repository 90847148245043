export enum DefaultValue {
  ActiveElementScale = 0.98,
  BorderRadius = '8px',
  ContainerWidth = '1500px',
  FontFamily = "'Aeonik', sans-serif",
  FontFamilyDisplay = "'ES Rebond Grotesque', sans-serif",
  ImageZoomScale = 3,
  TransitionDuration = '0.1s',
  TransitionTimingFunction = 'ease-out',
}
