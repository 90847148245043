import Cookies from 'js-cookie'

import { CookieKeys } from '../enums/cookieKeys'

interface AnalyticsData {
  analytics: string
}

export const getSignupAnalyticsData = (key: CookieKeys): AnalyticsData | undefined => {
  const data = Cookies.get(key)
  return data ? { analytics: data } : undefined
}

export const removeSignupAnalyticsData = (key: CookieKeys) => {
  Cookies.remove(key)
}
