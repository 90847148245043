import { useMutation } from 'react-query'

import { APIError, APIResponse } from '../../../utils'
import { createContact } from '../query-api'
import { Contact } from '../types/contact'

interface UseCreateContactProps {
  onError?: (error?: APIError) => void
  onSuccess?: (data: APIResponse<Contact>, variables: Partial<Contact>) => void
}

export const useCreateContact = (props?: UseCreateContactProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate: create,
    isLoading: isSaving,
    ...rest
  } = useMutation(createContact, {
    onSuccess,
    onError,
  })

  return { create, isSaving, ...rest }
}
