import { BrandProtectedComponent } from '@components'
import { SkeletonBox } from '@design-system'

import { ReactElement } from 'react'

import { PasswordForm } from './elements/PasswordForm'
import { UserProfileForm } from './elements/UserProfileForm'
import { UserProfileOtherSettings } from './elements/UserProfileOtherSettings'

export const UserProfileSettings = (): ReactElement => {
  return (
    <>
      <UserProfileForm />
      <UserProfileOtherSettings />
      <BrandProtectedComponent brand="billy" loadingNode={<SkeletonBox fullWidth />}>
        <PasswordForm />
      </BrandProtectedComponent>
    </>
  )
}
