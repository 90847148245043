import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { zIndex } from '../../utils/zIndex'
import { DropdownSize } from './types/dropdownSize'

const dropdownSizeToWidth: Record<DropdownSize, string> = {
  l: '225px',
  xl: '287px',
  xxl: '350px',
  fitTrigger: '100%',
}

export const getDropdownWidth = (size: DropdownSize, width?: number) => css`
  width: ${width ? `${width}px` : dropdownSizeToWidth[size]};
`

interface DropdownWrapperProps {
  height?: number
  maxHeight?: number
  width?: number
  size: DropdownSize
}

export const DropdownWrapper = styled.div<DropdownWrapperProps>`
  display: flex;
  position: absolute;
  z-index: ${zIndex.Dropdown};
  ${({ size, width }) => getDropdownWidth(size, width)};
`
