import { Button, ButtonsGroup, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../../../enums/modalId'
import { useDeleteTrialDataModal } from '../../contexts/deleteTrialDataModalContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { isProcessing, submitForm } = useDeleteTrialDataModal()
  const { close: closeDeleteTrialDataModal } = useModal(ModalId.DeleteTrialDataModal)

  const handleCancelButtonClick = useCallback(() => {
    closeDeleteTrialDataModal()
  }, [closeDeleteTrialDataModal])

  const handleConfirmButtonClick = useCallback(() => {
    submitForm()
  }, [submitForm])

  return (
    <>
      <ButtonsGroup>
        <Button variant="text" onClick={handleCancelButtonClick}>
          {t('delete_trial_data_modal.cancel_button_label')}
        </Button>
        <Button danger onClick={handleConfirmButtonClick} loading={isProcessing}>
          {t('delete_trial_data_modal.delete_button_label')}
        </Button>
      </ButtonsGroup>
    </>
  )
}
