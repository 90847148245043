import React, { ReactElement } from 'react'

import { Color } from '../../../../../../enums/color'
import { useTheme } from '../../../../../../hooks/useTheme'
import { TextProps } from '../../../../../Text'
import * as Styled from './styles'

interface TableCellContentProps extends TextProps {
  isSkeletonView?: boolean
  title?: string
}

export const TableCellContent = ({
  children,
  color,
  isSkeletonView,
  title,
  truncate,
  ...props
}: TableCellContentProps): ReactElement => {
  const theme = useTheme()

  if (isSkeletonView) {
    return <Styled.TableSkeletonCellContent>{children}</Styled.TableSkeletonCellContent>
  }

  const textColor: Color | undefined = props.colorVariant ? undefined : color || theme.ds.colors.base.textPrimary
  const titleText = title || (typeof children === 'string' ? children : undefined)

  return (
    <Styled.TableCellContent
      color={textColor}
      data-chromatic="ignore"
      title={truncate ? titleText : undefined}
      truncate={truncate}
      variant="small"
      {...props}
    >
      {children}
    </Styled.TableCellContent>
  )
}
