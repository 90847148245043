import { useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { QueryKeys } from '../enums/queryKeys'
import { customEventToQueryKeys } from '../utils/customEventToQueryKeys'

export const useInvalidateQueriesListener = () => {
  const queryClient = useQueryClient()

  const handler = useCallback(
    (eventName: string) => () => {
      const queryKeys: QueryKeys[] = customEventToQueryKeys[eventName]
      for (const queryKey of queryKeys) {
        queryClient.invalidateQueries(queryKey)
      }
    },
    [queryClient],
  )

  useEffect(() => {
    const customEvents = Object.keys(customEventToQueryKeys)
    const eventListeners = {}

    for (const eventName of customEvents) {
      const eventHandler = handler(eventName)
      window.addEventListener(eventName, eventHandler)
      eventListeners[eventName] = eventHandler
    }

    return () => {
      for (const eventName of customEvents) {
        const eventHandler = eventListeners[eventName]
        window.removeEventListener(eventName, eventHandler)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
