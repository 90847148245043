import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ModalEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`

export const DescriptionWrapper = styled.div`
  margin-top: ${Spacing.L};
`
