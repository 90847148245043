import { ProtectedComponent } from '@components'
import { Button } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { Scope } from '../../../../../../enums/scope'

export const RecurringInvoicesSideActions = () => {
  const { navigate } = useEmberRouter()
  const { t } = useTranslation()

  const handleCreateRecurringInvoiceClick = useCallback(() => {
    navigate(EmberRoute.RecurringInvoicesNew)
  }, [navigate])

  return (
    <ProtectedComponent scopes={Scope.RecurringInvoiceWrite}>
      <Button icon="plusCircle" onClick={handleCreateRecurringInvoiceClick}>
        {t('recurring_invoices.side_actions.create_recurring_invoice')}
      </Button>
    </ProtectedComponent>
  )
}
