import { Color } from '../../../enums/color'
import { Theme } from '../../../types/theme'
import { NavItemState } from '../types/navItemState'

interface ItemStyled {
  backgroundColor: Color
  iconLeftColor: Color
  iconRightColor: Color
  textColor: Color
  textSecondaryColor: Color
  textSubitemColor: Color
}

export const getStateToStyles = (theme: Theme): Record<NavItemState, ItemStyled> => ({
  default: {
    backgroundColor: Color.White,
    iconLeftColor: theme.ds.colors.base.textSecondary,
    iconRightColor: theme.ds.colors.base.textSecondary,
    textColor: theme.ds.colors.base.textPrimary,
    textSecondaryColor: theme.ds.colors.base.textSecondary,
    textSubitemColor: theme.ds.colors.base.textSecondary,
  },
  hovered: {
    backgroundColor: theme.ds.colors.base.textPrimary,
    iconLeftColor: Color.White,
    iconRightColor: Color.White,
    textColor: Color.White,
    textSecondaryColor: Color.White,
    textSubitemColor: Color.White,
  },
  expanded: {
    backgroundColor: Color.White,
    iconLeftColor: theme.ds.colors.base.textPrimary,
    iconRightColor: theme.ds.colors.base.textSecondary,
    textColor: theme.ds.colors.base.textPrimary,
    textSecondaryColor: theme.ds.colors.base.textSecondary,
    textSubitemColor: theme.ds.colors.base.textSecondary,
  },
  focused: {
    backgroundColor: theme.ds.colors.base.textPrimary,
    iconLeftColor: Color.White,
    iconRightColor: Color.White,
    textColor: Color.White,
    textSecondaryColor: Color.White,
    textSubitemColor: Color.White,
  },
  selected: {
    backgroundColor: Color.DayGrey,
    iconLeftColor: theme.ds.colors.base.textSecondary,
    iconRightColor: theme.ds.colors.base.textSecondary,
    textColor: theme.ds.colors.base.textPrimary,
    textSecondaryColor: theme.ds.colors.base.textSecondary,
    textSubitemColor: theme.ds.colors.base.textPrimary,
  },
})
