import React from 'react'

import { coloring } from '../../../styles-deprecated'
import { IconProps } from '../Icon'

export const IconRotate = ({ color = 'inherit', width = '20px', height = '20px' }: IconProps) => (
  <i>
    <svg fill="none" height={height} viewBox="0 0 15 12" width={width} xmlns="http://www.w3.org/2000/svg">
      <path
        fill={coloring(color)}
        d="M14.4 5.1c-.3-.2-.7-.2-.9.1l-.6.7c-.2-1.3-.7-2.6-1.8-3.6A6.2 6.2 0 006.7.5c-.4 0-.6.3-.6.6 0 .4.3.6.6.6 1.2 0 2.5.5 3.5 1.4.9.9 1.3 2 1.4 3.2l-1.1-1c-.3-.2-.7-.2-.9.1-.2.3-.2.7.1.9l2.1 1.8s.1 0 .1.1h.5s.1 0 .1-.1h.1L14.4 6c.3-.3.3-.7 0-.9zM7.6 4.5c-.1-.1-.3-.2-.5-.2H1.2c-.2 0-.3.1-.5.2-.1.1-.2.3-.2.5v5.9c0 .2.1.3.2.5.1.1.3.2.5.2h5.9c.2 0 .3-.1.5-.2.1-.1.2-.3.2-.5V5c0-.2 0-.4-.2-.5zm-1 5.8H1.9V5.6h4.7v4.7z"
      />
    </svg>
  </i>
)
