import React, {
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { NavItem } from '../../types/navItem'
import { NavItemAccessor } from '../../types/navItemAccessor'
import { NavItemRenderProps } from '../../types/navItemRenderProps'
import { SubItemsMode } from '../../types/subItemsMode'
import { NavListHeader } from '../NavListHeader'
import { NavListHeaderBreadcrumb } from '../NavListHeaderBreadcrumb'
import { NavListItem } from '../NavListItem'
import { NavListSeparator } from '../NavListSeparator'

interface NavListRenderProps<T> {
  items: NavItem<T>[]
  expandedIds?: string[]
  focusedId?: string
  itemRender?: (props: NavItemRenderProps<T>) => ReactNode
  onItemClick?: (id: string, value: T, event?: MouseEvent) => void
  selectedId?: string
  subItemsMode?: SubItemsMode
}

export const NavListRender = <T,>({
  items,
  expandedIds,
  focusedId,
  itemRender,
  onItemClick,
  selectedId,
  subItemsMode,
}: NavListRenderProps<T>): ReactElement => {
  const [headerStickyTopOffset, setHeaderStickyTopOffset] = useState(0)
  const headerBreadcrumbRef = useRef<HTMLLIElement>(null)

  useLayoutEffect(() => {
    const headerBreadcrumbElement = headerBreadcrumbRef.current as HTMLLIElement | undefined | null
    const headerBreadcrumbElementHeight = headerBreadcrumbElement?.offsetHeight
    const headerBreadcrumbsCount =
      items?.filter((item) => item.accessor === NavItemAccessor.HeaderBreadcrumb).length || 0

    setHeaderStickyTopOffset(headerBreadcrumbElementHeight ? headerBreadcrumbsCount * headerBreadcrumbElementHeight : 0)
  }, [items])

  const handleItemClick = useCallback(
    (id: string, value: T, event?: MouseEvent) => {
      onItemClick?.(id, value, event)
    },
    [onItemClick],
  )

  const renderCustomNavItem = useCallback(
    (item: NavItem<T>) => {
      const props: NavItemRenderProps<T> = {
        focusedId,
        item,
        key: item.id,
        onClick: () => handleItemClick(item.id, item.value),
        selectedId,
        expandedIds,
      }

      return itemRender?.(props)
    },
    [expandedIds, focusedId, handleItemClick, itemRender, selectedId],
  )

  const renderItem = useCallback(
    (item: NavItem<T>, index: number) => {
      const key = `${item.id}-${index}`

      switch (item.accessor) {
        case NavItemAccessor.Separator:
          return <NavListSeparator key={key} />

        case NavItemAccessor.HeaderBreadcrumb:
          return (
            <NavListHeaderBreadcrumb
              focusedId={focusedId}
              item={item}
              key={key}
              onClick={handleItemClick}
              ref={headerBreadcrumbRef}
            />
          )

        case NavItemAccessor.Header:
          return (
            <NavListHeader key={key} stickyTopOffset={headerStickyTopOffset}>
              {item.children}
            </NavListHeader>
          )

        default:
          return itemRender ? (
            renderCustomNavItem(item)
          ) : (
            <NavListItem
              expandedIds={expandedIds}
              focusedId={focusedId}
              item={item}
              key={key}
              onClick={handleItemClick}
              selectedId={selectedId}
              subItemsMode={subItemsMode}
            />
          )
      }
    },
    [
      expandedIds,
      focusedId,
      handleItemClick,
      headerStickyTopOffset,
      itemRender,
      renderCustomNavItem,
      selectedId,
      subItemsMode,
    ],
  )

  const renderedItems = useMemo(() => items.map(renderItem), [items, renderItem])

  return <>{renderedItems}</>
}
