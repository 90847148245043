import { useMutation } from 'react-query'

import { updateOrganizationOwnership, UpdateOrganizationOwnershipPayload } from '..'
import { APIError } from '../../../../utils'

interface UseTransferOrganizationOwnershipProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface TransferOrganizationOwnershipProps {
  organizationId: string
  payload: UpdateOrganizationOwnershipPayload
}

export const useTransferOrganizationOwnership = (props?: UseTransferOrganizationOwnershipProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: transferOrganizationOwnership, isLoading: isProcessing } = useMutation(
    ({ organizationId, payload }: TransferOrganizationOwnershipProps) =>
      updateOrganizationOwnership(organizationId, payload),
    {
      onError,
      onSuccess,
    },
  )

  return {
    isProcessing,
    transferOrganizationOwnership,
  }
}
