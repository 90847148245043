import { DateFormatter, Translate } from '@components'
import { Badge, Icon, TableColumn } from '@design-system'

import { TFunction } from 'i18next'
import React from 'react'

import { TransactionsTableAccessor } from '../../../enums/TransactionsTableAccessor'
import { TransactionsTableRow } from '../../../types/transactionsTableRow'
import { getTransactionsTableColumnCell } from '../../../utils/getTransactionsTableColumnCell'

export const getTransactionTableColumns = (
  withVoided: boolean | undefined,
  t: TFunction,
): TableColumn<TransactionsTableRow>[] => {
  const tableColumns: TableColumn<TransactionsTableRow>[] = [
    {
      accessor: TransactionsTableAccessor.TransactionNo,
      Cell: getTransactionsTableColumnCell,
      fixedSize: true,
      Header: <Translate value="transactions.table.column.transaction_no" />,
      size: 'xs',
      sortable: true,
      truncate: true,
    },
    {
      accessor: TransactionsTableAccessor.Attachment,
      Cell: ({ value: hasAttachment }) => hasAttachment && <Icon icon="paperclip" />,
      fixedSize: true,
      Header: <Translate value="transactions.table.column.attachment" />,
      size: 'xs',
      sortable: true,
      truncate: true,
    },
    {
      accessor: TransactionsTableAccessor.VoucherNo,
      Cell: getTransactionsTableColumnCell,
      fixedSize: true,
      Header: <Translate value="transactions.table.column.voucher_no" />,
      size: 'm',
      sortable: true,
      truncate: true,
    },
    {
      accessor: TransactionsTableAccessor.EntryDate,
      Cell: ({ value: date, ...rest }) => {
        return getTransactionsTableColumnCell({ ...rest, value: date && <DateFormatter value={date} /> })
      },
      fixedSize: true,
      Header: <Translate value="transactions.table.column.date" />,
      size: 's',
      sortable: true,
    },
    {
      accessor: TransactionsTableAccessor.OriginatorType,
      Cell: getTransactionsTableColumnCell,
      Header: <Translate value="transactions.table.column.type" />,
      size: 'xs',
      truncate: true,
    },
    {
      accessor: TransactionsTableAccessor.Description,
      Cell: getTransactionsTableColumnCell,
      Header: <Translate value="transactions.table.column.description" />,
      size: 'l',
      truncate: true,
    },
    {
      accessor: TransactionsTableAccessor.Amount,
      alignment: 'right',
      Cell: getTransactionsTableColumnCell,
      Header: <Translate value="transactions.table.column.amount" />,
      size: 'xs',
      truncate: true,
    },
  ]

  if (withVoided) {
    tableColumns.push({
      accessor: TransactionsTableAccessor.Voided,
      alignment: 'right',
      Cell: ({ value: voided }) => voided && <Badge variant="neutral">{t('transactions.table.voided')}</Badge>,
      fixedSize: true,
      size: 's',
    })
  }

  return tableColumns
}
