import { pxToNumber } from '@design-system'

import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import { getImageClassName } from '../../utils/getClassName'
import { sizeToPx } from './constants/sizeToPx'
import * as Styled from './styles'
import { Size } from './types/size'

interface AvatarProps {
  size?: Size
  url?: string
}

export const Avatar = ({ size = 'm', url }: AvatarProps): ReactElement => {
  const [hasAvatar, setHasAvatar] = useState(!!url)
  const [isLoading, setIsLoading] = useState(hasAvatar)

  useEffect(() => {
    setHasAvatar(!!url)
    setIsLoading(!!url)
  }, [url])

  const avatarSizeValue = pxToNumber(sizeToPx[size])

  const handleError = useCallback(() => {
    setHasAvatar(false)
    setIsLoading(false)
  }, [])

  const handleLoad = useCallback(() => {
    setIsLoading(false)
  }, [])

  return (
    <Styled.AvatarWrapper className={getImageClassName()} size={size}>
      {isLoading && <Styled.SkeletonAvatar size={avatarSizeValue} />}
      {hasAvatar ? (
        <Styled.Avatar isLoading={isLoading} onError={handleError} onLoad={handleLoad} src={url} />
      ) : (
        <Styled.AvatarPlaceholder height={avatarSizeValue} width={avatarSizeValue} />
      )}
    </Styled.AvatarWrapper>
  )
}
