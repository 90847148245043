import React, { ReactElement } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { Inbox } from '@modules-deprecated/inbox'

import { withRouteLinkClickedListener } from '../../hocs/withRouteLinkClickedListener'
import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { defaultQuery } from './constants/defaultQuery'
import { UmbrellaRoute } from './enums/UmbrellaRoute'
import { UmbrellaInvoices } from './routes/umbrellaInvoices'
import { UmbrellaOrganizations } from './routes/umbrellaOrganizations'
import { UmbrellaProfile } from './routes/umbrellaProfile'
import { UmbrellaRoles } from './routes/umbrellaRoles'
import { UmbrellaSettings } from './routes/umbrellaSettings'
import { UmbrellaSubscriptions } from './routes/umbrellaSubscriptions'
import { UmbrellaUsers } from './routes/umbrellaUsers'

export const Umbrella = withRouteLinkClickedListener((): ReactElement => {
  const emberQuery = useGetQueryFromEmberRoute({ queryParamsValidKeys: Object.keys(defaultQuery) })
  const { umbrella } = useUmbrella()
  const umbrellaId = umbrella?.id

  return (
    <Switch>
      <Route exact path="/">
        {typeof emberQuery?.initialRoute === 'string' ? (
          <Redirect to={emberQuery.initialRoute} />
        ) : (
          <Redirect to={UmbrellaRoute.Organizations} />
        )}
      </Route>
      <Route component={UmbrellaOrganizations} exact path={UmbrellaRoute.Organizations} />
      <Route component={UmbrellaProfile} exact path={UmbrellaRoute.Profile} />
      <Route component={UmbrellaRoles} exact path={UmbrellaRoute.Roles} />
      <Route component={UmbrellaSubscriptions} exact path={UmbrellaRoute.Subscriptions} />
      <Route component={UmbrellaInvoices} exact path={UmbrellaRoute.Invoices}>
        {umbrellaId && <UmbrellaInvoices umbrellaId={umbrellaId} />}
      </Route>
      <Route component={UmbrellaUsers} exact path={UmbrellaRoute.Users} />
      <Route component={UmbrellaSettings} path={UmbrellaRoute.Settings} />
      <Route path={UmbrellaRoute.VoucherInbox}>
        <Inbox umbrellaId={umbrellaId} userOrganizationId="" />
      </Route>
    </Switch>
  )
})
