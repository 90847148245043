import { DateFormatter, ThumbnailFormatter, Translate } from '@components'
import { InternalAccessor, PreviewFile, Table, TableColumn, TableData, Text } from '@design-system'

import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row as ReactTableRowProps } from 'react-table'

import { TableIds } from '../../../enums/tableIds'
import { getThumbnailUrl } from '../../../utils/getThumbnailUrl'
import { Organization } from '../../app/organizations/elements'
import { useCurrentUser } from '../../app/user'
import { DeleteVouchers } from '../hooks/useVouchers'
import { InboxRoutes, Pagination, Voucher, VoucherInboxState } from '../types'
import { getVoucherInboxPath } from '../utils/getVoucherInboxPath'
import { DeleteModal } from './modals/DeleteModal'
import { VouchersActions } from './VouchersActions'
import { VoucherStatus } from './VoucherStatus'

const getVoucherLink = (
  voucher: Voucher,
  customerView: boolean,
  compileVoucherToHandleUrl: (voucherId: string) => string,
) => {
  const { id, inboxState } = voucher

  if (!customerView && inboxState === VoucherInboxState.RECEIVED) {
    return compileVoucherToHandleUrl(id)
  }

  if (customerView) {
    return `/${id}/${InboxRoutes.ViewVoucher}`
  } else {
    return getVoucherInboxPath(id, InboxRoutes.ViewVoucher)
  }
}

const getVoucherListData = (
  vouchers: Voucher[],
  customerView: boolean,
  compileVoucherToHandleUrl: (voucherId: string) => string,
) =>
  vouchers.map((voucher) => {
    const {
      createdTimestamp,
      description,
      fileReferences: [fileReference],
      id,
      inboxState,
      organizationId,
      voucherReference,
    } = voucher

    return {
      id,
      inboxState,
      thumbnail: {
        alt: description,
        src: fileReference?.url ? getThumbnailUrl(fileReference?.url) : '',
      },
      createdTimestamp: new Date(createdTimestamp),
      description,
      vendor: voucherReference?.contactName,
      account: voucherReference?.accountName,
      amount: voucherReference?.amount,
      organizationId,
      [InternalAccessor.UrlInternal]: getVoucherLink(voucher, customerView, compileVoucherToHandleUrl),
    }
  })

interface VoucherRow extends TableData {
  'voucherReference.accountName'?: string | null
  amount?: number | null
  billId?: string | null
  currency?: string | null
  description: string
  inboxState: VoucherInboxState
  organizationId?: string
  thumbnail: PreviewFile
  createdTimestamp: Date
  'voucherReference.contactName'?: string | null
}

type VoucherListProps = {
  compileVoucherToHandleUrl: (voucherId: string) => string
  customerView?: boolean
  defaultPage: number
  defaultSort?: string
  isLoading?: boolean
  onDelete?: DeleteVouchers
  onFetch: (offset: number) => void
  onSort: (sort: string) => void
  pagination: Pagination
  vouchers: Voucher[]
}

interface SelectedVoucher {
  organizationId: string
  voucherId: string
}

export const VoucherList = React.memo(
  ({
    compileVoucherToHandleUrl,
    customerView,
    defaultPage,
    defaultSort,
    isLoading = false,
    onDelete,
    onFetch,
    onSort,
    pagination,
    vouchers,
  }: VoucherListProps) => {
    const [isEditMode, setIsEditMode] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [selectedRows, setSelectedRows] = useState<SelectedVoucher[]>([])
    const { t } = useTranslation()
    const { user } = useCurrentUser()

    const data: VoucherRow[] = useMemo(
      () => getVoucherListData(vouchers, !!customerView, compileVoucherToHandleUrl),
      [compileVoucherToHandleUrl, customerView, vouchers],
    )

    const columns: TableColumn<VoucherRow>[] = useMemo(() => {
      const baseColumns: TableColumn<VoucherRow>[] = [
        {
          Header: '',
          accessor: 'thumbnail',
          size: 'xs',
          Cell: ({ value: thumbnail }) => <ThumbnailFormatter alt={thumbnail.alt} src={thumbnail.src} />,
        },
        {
          Header: <Translate value="voucher.inbox.header.inbox_state" />,
          accessor: 'inboxState',
          size: 's',
          Cell: ({ value: inboxState }) => inboxState && <VoucherStatus state={inboxState} />,
          sortable: true,
        },
        {
          Header: <Translate value="voucher.inbox.header.upload_date" />,
          accessor: 'createdTimestamp',
          size: 's',
          Cell: ({ value: uploadDate }) =>
            uploadDate ? (
              <DateFormatter value={uploadDate} />
            ) : (
              <Translate value="voucher.inbox.voucher_list.empty_value" />
            ),
          sortable: true,
        },
        {
          Header: <Translate value="voucher.inbox.header.description" />,
          accessor: 'description',
          size: 'm',
          Cell: ({ value: description }) => (
            <Text truncate inherit>
              {description}
            </Text>
          ),
          sortable: true,
        },
        {
          accessor: InternalAccessor.UrlInternal,
        },
      ]

      if (!customerView && user?.isStaff) {
        baseColumns.push({
          Header: <Translate value="voucher.inbox.header.organization_id" />,
          accessor: 'organizationId',
          size: 's',
          Cell: ({ value }) => <Organization id={value} />,
          sortable: true,
        })
      } else {
        baseColumns.push({
          Header: <Translate value="voucher.inbox.header.vendor" />,
          accessor: 'voucherReference.contactName',
          size: 'm',
          sortable: true,
        })
        baseColumns.push({
          Header: <Translate value="voucher.inbox.header.account" />,
          accessor: 'voucherReference.accountName',
          size: 'm',
          sortable: true,
        })
        baseColumns.push({
          Header: <Translate value="voucher.inbox.header.amount" />,
          accessor: 'amount',
          alignment: 'right',
          size: 's',
        })
      }

      return baseColumns
    }, [customerView, user])

    const handleSort = useCallback(
      (columnId: string, isDesc: boolean) => {
        onSort(isDesc ? `-${columnId}` : columnId)
      },
      [onSort],
    )

    const handleEditMode = useCallback(() => {
      setIsEditMode((oldIsEditMode: boolean) => !oldIsEditMode)
    }, [])

    const handleDeleteModalVisibility = useCallback(() => {
      setIsDeleteModalVisible((oldIsDeleteModeVisible: boolean) => !oldIsDeleteModeVisible)
    }, [])

    const handleDeleteMode = useCallback(async () => {
      if (onDelete) {
        onDelete(selectedRows)
      }

      setSelectedRows([])
      setIsEditMode(false)
      setIsDeleteModalVisible(false)
    }, [onDelete, selectedRows])

    const handleRowSelect = useCallback((row: ReactTableRowProps<VoucherRow>, isChecked: boolean) => {
      setSelectedRows((previousSelectedRows) => {
        const voucherId = row.original.id

        if (!isChecked) {
          return previousSelectedRows.filter(({ voucherId: oldVoucherId }) => oldVoucherId !== voucherId)
        }

        if (previousSelectedRows.some(({ voucherId: oldVoucherId }) => oldVoucherId === voucherId)) {
          return previousSelectedRows
        }

        return [...previousSelectedRows, { voucherId, organizationId: row.original.organizationId || '' }]
      })
    }, [])

    const handleAllRowsSelect = useCallback((rows: ReactTableRowProps<VoucherRow>[], isChecked: boolean) => {
      if (isChecked) {
        setSelectedRows(
          rows.map((row) => ({
            voucherId: row.original.id,
            organizationId: row.original.organizationId || '',
          })),
        )
      } else {
        setSelectedRows([])
      }
    }, [])

    return (
      <>
        {customerView && (
          <VouchersActions
            isEditMode={isEditMode}
            onEditModeChange={handleEditMode}
            onDelete={handleDeleteModalVisibility}
            selectedRows={selectedRows.length}
            totalRows={pagination.total}
          />
        )}
        <Table<VoucherRow>
          columns={columns}
          currentPage={defaultPage}
          data={data}
          id={TableIds.VoucherList}
          isLoading={isLoading}
          isSelectable={isEditMode}
          onAllRowsSelect={handleAllRowsSelect}
          onPageChange={onFetch}
          onRowSelect={handleRowSelect}
          onSort={handleSort}
          sortedColumnId={defaultSort}
          totalPageItems={pagination.total}
          withStickyHeader
        />
        {customerView && (
          <DeleteModal
            heading={t('voucher.inbox.delete_voucher', { count: selectedRows.length })}
            isOpen={isDeleteModalVisible}
            onCancel={handleDeleteModalVisibility}
            onConfirm={handleDeleteMode}
          />
        )}
      </>
    )
  },
)
