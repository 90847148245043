import { useTranslation } from 'react-i18next'

import { getAppBuildTheme } from '../../../../../utils/getAppBuildTheme'
import { SettingsRoute } from '../../../enums/settingsRoute'
import { SettingsTabItem, UseSettingsTabItems } from '../types'

const theme = getAppBuildTheme()

export const useUserSettingsTabItems = (): UseSettingsTabItems => {
  const { t } = useTranslation()

  const items: SettingsTabItem[] = [
    {
      icon: 'user',
      route: SettingsRoute.Profile,
      text: t('settings.user.profile'),
    },
    {
      icon: 'bell',
      route: SettingsRoute.ProfileNotifications,
      text: t('settings.user.notifications'),
      hidden: theme !== 'billy', // @todo it should be also handled inside routing
    },
    {
      icon: 'shield',
      route: SettingsRoute.ProfilePrivacy,
      text: t('settings.user.privacy'),
    },
  ]

  return { items }
}
