import { QuotesCountByState } from '../types/quotesCountByState'
import { useFetchQuotesSummary } from './useFetchQuotesSummary'
import { useQuotesListFilters } from './useQuotesListFilters'

interface UseQuotesSummaryResponse {
  isLoading: boolean
  quotesCountByState: QuotesCountByState
}

export const useQuotesSummary = (): UseQuotesSummaryResponse => {
  const [{ period, searchQuery }] = useQuotesListFilters()
  const { quotesCountByState, isLoading } = useFetchQuotesSummary({
    period,
    searchQuery,
  })

  return {
    isLoading,
    quotesCountByState,
  }
}
