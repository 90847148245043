import { notify } from '@design-system'

import { createElement, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, RouteProps } from 'react-router-dom'

import { useEmberRouter } from '../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../enums/emberRoute'
import { NotificationKeys } from '../../../enums/notificationKeys'
import { ProtectableComponentProps, ProtectedComponent } from '../ProtectedComponent'

export interface RouteProtectedProps extends RouteProps, ProtectableComponentProps {
  unauthorizedRedirectTo?: EmberRoute
}

export const RouteProtected = ({
  component,
  scopes,
  unauthorizedRedirectTo,
  onUnauthorized,
  unauthorizedNode,
  children,
  ...props
}: RouteProtectedProps): ReactElement | null => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()

  const onRouteUnauthorized = useCallback(() => {
    if (onUnauthorized) {
      onUnauthorized()
    } else {
      notify({ id: NotificationKeys.AccessDenied, message: t('access_denied'), variant: 'error' })
      navigate(unauthorizedRedirectTo || EmberRoute.Dashboard)
    }
  }, [navigate, onUnauthorized, unauthorizedRedirectTo, t])

  return (
    <Route {...props}>
      <ProtectedComponent scopes={scopes} unauthorizedNode={unauthorizedNode} onUnauthorized={onRouteUnauthorized}>
        {component ? createElement(component, props) : children}
      </ProtectedComponent>
    </Route>
  )
}
