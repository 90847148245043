import noop from 'lodash/noop'
import React, { createContext, ReactElement, ReactNode, useContext } from 'react'

interface ContextState {
  openCreateOwnerModal?: () => void
  openEditOwnerModal?: (ownerId: string) => void
  openDeleteOwnerModal?: (ownerId: string) => void
}

const defaultValue: ContextState = {
  openCreateOwnerModal: noop,
  openEditOwnerModal: noop,
  openDeleteOwnerModal: noop,
}

const OrganizationOwnersContext = createContext(defaultValue)

interface OrganizationOwnersContextProps {
  onOpenCreateOwnerModal?: () => void
  onOpenEditOwnerModal?: (ownerId: string) => void
  onOpenDeleteOwnerModal?: (ownerId: string) => void
  children: ReactNode
}

export const OrganizationOwnersContextProvider = ({
  onOpenCreateOwnerModal,
  onOpenEditOwnerModal,
  onOpenDeleteOwnerModal,
  children,
}: OrganizationOwnersContextProps): ReactElement => {
  return (
    <OrganizationOwnersContext.Provider
      value={{
        openCreateOwnerModal: onOpenCreateOwnerModal,
        openEditOwnerModal: onOpenEditOwnerModal,
        openDeleteOwnerModal: onOpenDeleteOwnerModal,
      }}
    >
      {children}
    </OrganizationOwnersContext.Provider>
  )
}

export const useOrganizationOwners = () => useContext(OrganizationOwnersContext)
