import { ScrollableList } from '../../../../../../../ScrollableList'
import { BankLinesListItemSkeleton } from '../BankLinesListItemSkeleton'

export const BankLinesListSkeleton = () => (
  <ScrollableList>
    {[...Array(5).keys()].map((id) => (
      <BankLinesListItemSkeleton key={id} />
    ))}
  </ScrollableList>
)
