import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { DefaultValue } from '../../enums/defaultValue'
import { Spacing } from '../../enums/spacing'
import { Asable } from '../../types/asable'
import { Themable } from '../../types/themable'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { zIndex } from '../../utils/zIndex'

type TooltipWrapperProps = Asable

export const TooltipWrapper = styled.div<TooltipWrapperProps>``

interface TooltipProps extends Themable {
  visible?: boolean
}

export const Tooltip = styled.div<TooltipProps>`
  padding: ${Spacing.S} ${Spacing.M};
  border-radius: ${DefaultValue.BorderRadius};
  background-color: ${Color.DeepSeaGreen};
  z-index: ${zIndex.Tooltip};
  max-width: 250px;
  ${getDefaultTransition('opacity')};

  ${({ visible = false }) => {
    return `
      opacity: ${visible ? 1 : 0};
    `
  }}
`

export const TooltipStoryContainer = styled.span`
  display: flex;
  justify-content: center;
`
