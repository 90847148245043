import { Button, ButtonsGroup, Modal, Space, Text, useModal } from '@design-system'

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as FinancingCheckmark } from '../../../../assets/images/billy-financing-checkmark.svg'
import { useEmberCloseModal } from '../../../../contexts/emberCloseModalContext'
import { ModalId } from '../../../../enums/modalId'
import { getImageClassName } from '../../../../utils/getClassName'
import * as Styled from './styles'

export const ApproveFinancingSuccessModal = () => {
  const { closeEmberModal } = useEmberCloseModal()
  const { t } = useTranslation()

  useModal(ModalId.FinancingApproveSuccessModal, {
    onClose: () => closeEmberModal(),
  })

  const handleCloseClick = useCallback(() => {
    closeEmberModal()
  }, [closeEmberModal])

  return (
    <Modal id={ModalId.FinancingApproveSuccessModal} size="s" closable>
      <Space size="xl" />
      <Modal.Body>
        <Styled.IllustrationWrapper className={getImageClassName()}>
          <FinancingCheckmark />
        </Styled.IllustrationWrapper>
        <Text variant="h3" alignment="center">
          {t('financing.application_sent_modal.thank_you')}
        </Text>
        <Space size="m" />
        <Text alignment="center" colorVariant="secondary">
          {t('financing.application_sent_modal.info')}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="primary" onClick={handleCloseClick}>
            {t('financing.application_sent_modal.close')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
