import { AttachmentFile, getCurrentLocale } from '@design-system'

import { useCallback } from 'react'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { Account } from '@modules-deprecated/app/accounts/types'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { ChartOfAccountId } from '@modules-deprecated/app/organization/enums/chartOfAccountId'
import { useTaxRates } from '@modules-deprecated/app/taxrates'
import { TaxRate } from '@modules-deprecated/app/taxrates/types'

import { Scope } from '../../../../../enums/scope'
import { useFormContext } from '../../../../../hooks/useForm'
import { isAuthorized } from '../../../../../utils/isAuthorized'
import { autoCompleteBillForm } from '../utils/autoCompleteBillForm'
import { BillForm, BillFormSchema } from '../utils/formData'
import { getBillFormFromBohrData } from '../utils/getBillFormFromBohrData'
import { getBillFormFromVendorData } from '../utils/getBillFormFromVendorData'
import { getShouldOverridenParamChange } from '../utils/getShouldOverridenParamChange'
import { resetAutoCompletedBillForm } from '../utils/resetAutoCompletedBillForm'

interface GetAutocompletedFormWithBohrProps {
  accounts?: Account[]
  attachmentFile?: AttachmentFile
  baseCurrencyId?: string
  billForm: BillForm
  chartOfAccountId?: ChartOfAccountId
  countryId?: string
  isVatExempted?: boolean
  locale: string
  organizationId: string
  taxRates?: TaxRate[]
}

export const getAutoCompletedFormWithBohr = async ({
  accounts,
  attachmentFile,
  baseCurrencyId,
  billForm,
  chartOfAccountId,
  countryId,
  isVatExempted,
  locale,
  organizationId,
  taxRates,
}: GetAutocompletedFormWithBohrProps): Promise<BillForm> => {
  const isBohrEnabled = isAuthorized(Scope.VoucherScanRead)

  if (!isBohrEnabled) {
    return billForm
  }

  const billFormBohr = await getBillFormFromBohrData({
    attachmentFile,
    chartOfAccountId,
    countryId,
    locale,
    organizationId,
    withBookkeepingSuggestions: true,
  })

  let billFormUpdated = autoCompleteBillForm({
    // clean up autocompletion flags from previous sources
    // ensures correct coloring of fields that are completed by Bohr and VAT refresh
    billFormDefault: { ...billForm, autoCompletedFields: {} },
    billFormOverrides: billFormBohr,
    baseCurrencyId,
    onlyFirstBillLine: true,
    shouldOverridenParamChangeCallback: getShouldOverridenParamChange({ shouldChangeExpenseParams: true }),
  })

  const billFormBohrVendor = billFormUpdated.vendor
    ? await getBillFormFromVendorData({
        accounts,
        billForm: billFormUpdated,
        chartOfAccountId,
        countryId,
        isVatExempted,
        locale,
        taxRates,
        vendor: billFormUpdated.vendor,
      })
    : undefined

  if (billFormBohrVendor) {
    billFormUpdated = autoCompleteBillForm({
      billFormDefault: billFormUpdated,
      billFormOverrides: billFormBohrVendor,
      baseCurrencyId,
      onlyFirstBillLine: true,
      shouldOverridenParamChangeCallback: getShouldOverridenParamChange({ shouldChangeExpenseParams: true }),
    })
  }

  return billFormUpdated
}

export const useBohrAutoComplete = () => {
  const { organization } = useUserOrganization()
  const { getValues, reset: resetForm, setValue } = useFormContext<BillFormSchema>()
  const locale = getCurrentLocale()
  const { accounts = [] } = useAccounts()
  const { taxRates = [] } = useTaxRates()

  const autoCompleteWithBohrData = useCallback(
    async (attachmentFile?: AttachmentFile) => {
      if (!organization?.id) {
        return
      }

      const billForm = getValues()

      const billFormUpdated = await getAutoCompletedFormWithBohr({
        accounts,
        attachmentFile,
        baseCurrencyId: organization.baseCurrencyId,
        billForm,
        chartOfAccountId: organization?.chartOfAccountId,
        countryId: organization?.countryId,
        locale,
        organizationId: organization.id,
        taxRates,
      })

      resetForm(billFormUpdated, { keepDefaultValues: true })

      // Wait one frame before setting autoCompletedFields
      window.requestAnimationFrame(() => {
        setValue('autoCompletedFields', billFormUpdated.autoCompletedFields)
      })
    },
    [
      organization?.id,
      organization?.baseCurrencyId,
      organization?.chartOfAccountId,
      organization?.countryId,
      getValues,
      accounts,
      locale,
      taxRates,
      resetForm,
      setValue,
    ],
  )

  const resetAutoCompletedWithBohrData = useCallback(() => {
    const billForm = getValues()

    const billFormUpdated = resetAutoCompletedBillForm(billForm, organization?.baseCurrencyId)

    resetForm(billFormUpdated, { keepDefaultValues: true })

    // Wait one frame before setting autoCompletedFields
    window.requestAnimationFrame(() => {
      setValue('autoCompletedFields', billFormUpdated.autoCompletedFields)
    })
  }, [getValues, setValue, resetForm, organization?.baseCurrencyId])

  return { autoCompleteWithBohrData, resetAutoCompletedWithBohrData }
}
