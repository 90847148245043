import { Color } from '@design-system'

import colors from './colors'

export default {
  container: {},
  content: {
    contrained: {
      flex: 'auto',
      margin: '0 auto',
      padding: '0px 30px 90px 30px',
      maxWidth: '1204px',
      minWidth: '904px',
    },
    fullWidth: {
      flex: 'auto',
      margin: '0 auto',
      padding: '0px 30px 90px 30px',
      width: '100%',
    },
  },
  moduleHeader: {
    backgroundColor: Color.Gray20,
  },
  hline: {
    margin: '15px 0',
    height: '1px',
    overflow: 'hidden',
    backgroundColor: colors.gray3,
  },
  tabs: {
    'div + .tabs-content': {
      marginLeft: 30,
    },
  },
}
