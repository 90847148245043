import { useEffect, useMemo, useState } from 'react'

import { TableData } from '../types/data'

interface UseClientSidePaginationProps<T extends TableData> {
  data: T[]
  isActive?: boolean
  isSkeletonView?: boolean
  itemsPerPage: number
  page?: number
}

interface UseClientSidePagination<T extends TableData> {
  clientSidePage: number
  clientSidePaginationData: T[]
  setClientSidePage: (page: number) => void
}

export const useClientSidePagination = <T extends TableData>({
  data,
  isActive,
  isSkeletonView,
  itemsPerPage,
  page = 1,
}: UseClientSidePaginationProps<T>): UseClientSidePagination<T> => {
  const clientSidePageMax = useMemo(
    () => (data.length ? Math.ceil(data.length / itemsPerPage) : page),
    [data.length, itemsPerPage, page],
  )
  const [clientSidePage, setClientSidePage] = useState(Math.min(page, clientSidePageMax))

  useEffect(() => {
    setClientSidePage(Math.min(page, clientSidePageMax))
  }, [page, clientSidePageMax])

  const clientSidePaginationData = useMemo((): T[] => {
    if (!isActive) {
      return []
    }

    if (isSkeletonView) {
      return data
    }

    const pageIndex = clientSidePage - 1
    const beginIndex = itemsPerPage * pageIndex
    const endIndex = beginIndex + itemsPerPage

    return data.slice(beginIndex, endIndex)
    // `T` should be passed as a argument to update because it's Typescript prop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSidePage, data, isSkeletonView, itemsPerPage])

  return {
    clientSidePage,
    clientSidePaginationData,
    setClientSidePage,
  }
}
