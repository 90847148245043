import React, { Children, cloneElement, forwardRef, isValidElement, ReactElement, ReactNode, Ref } from 'react'

import { AmountInfoBoxProps } from '../AmountInfoBox/AmountInfoBox'
import * as Styled from './styles'

export interface AmountInfoBoxesGroupProps {
  children: ReactNode
  itemsInRow?: number
  loading?: boolean
}

export const AmountInfoBoxesGroup = forwardRef(
  (
    { children, itemsInRow, loading, ...rest }: AmountInfoBoxesGroupProps,
    forwardedRef: Ref<HTMLUListElement>,
  ): ReactElement => (
    <Styled.AmountInfoBoxesGroupWrapper
      itemsInRow={itemsInRow || Children.count(children)}
      ref={forwardedRef}
      {...rest}
    >
      {Children.map(children, (child) => {
        const amountInfoBoxProps: AmountInfoBoxProps = {
          loading,
        }

        return (
          isValidElement(child) && (
            <Styled.AmountInfoBoxesGroupItem>{cloneElement(child, amountInfoBoxProps)}</Styled.AmountInfoBoxesGroupItem>
          )
        )
      })}
    </Styled.AmountInfoBoxesGroupWrapper>
  ),
)
