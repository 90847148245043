import React, { ReactNode } from 'react'

import { Color } from '../../enums/color'
import { Text, TextProps } from '../Text'
import * as Styled from './styles'
import { DividerTextOrientation } from './types/dividerTextOrientation'

export interface DividerProps extends Omit<TextProps, 'children'> {
  className?: string
  dashed?: boolean
  orientation?: DividerTextOrientation
  dividerColor?: Color
  children?: ReactNode
}

export const Divider = ({
  className,
  dashed = false,
  orientation = 'center',
  dividerColor = Color.Gray30,
  children,
  ...rest
}: DividerProps) => {
  return (
    <Styled.DividerWrapper dashed={dashed} orientation={orientation} dividerColor={dividerColor} className={className}>
      {children && (
        <Styled.TextContainer>
          <Text colorVariant="secondary" {...rest}>
            {children}
          </Text>
        </Styled.TextContainer>
      )}
    </Styled.DividerWrapper>
  )
}
