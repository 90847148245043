import { AnyAction } from 'redux'

import { APIError } from '../../utils'
import {
  ACTIVATE_TRANSACTION_DROPPABLE_AREA,
  BANK_BALANCE_RECEIVED,
  BANK_BALANCE_REQUESTED,
  BANK_CONNECTION_RECEIVED,
  BANK_CONNECTION_REQUEST_FAILED,
  BANK_CONNECTION_REQUESTED,
  BANK_LINE_MATCH_REMOVED,
  BANK_LINE_MATCH_UPDATED,
  BANK_LINE_MATCHES_DELETED,
  BANK_LINE_SUBJECT_ASSOCIATION_CREATED,
  BANK_LINE_SUBJECT_ASSOCIATION_DELETE_REQUESTED,
  BANK_LINE_SUBJECT_ASSOCIATION_DELETED,
  BANK_LINE_SUBJECT_ASSOCIATION_UPDATED,
  BANK_LINE_UNGROUPED,
  BANK_LINES_AND_TRANSACTIONS_RECEIVED,
  BANK_LINES_AND_TRANSACTIONS_REQUESTED,
  BANK_LINES_LATEST_LINE_RECEIVED,
  BANK_LINES_LATEST_LINE_REQUESTED,
  BANK_LINES_LOADING,
  BILLY_TRANSACTION_ADDED,
  BULK_RECONCILE,
  BULK_RECONCILE_STATE_UPDATED,
  CREATE_BILL_AND_RECONCILE,
  CREATE_INVOICE_AND_RECONCILE,
  CREATE_PLACEHOLDER,
  DELETE_BANK_LINES,
  DESTROY_PLACEHOLDERS,
  DIFFERENCE_TYPE_SELECTED,
  DND_BANK_LINES,
  DND_BILLY_TRANSACTIONS,
  FILTERS_CHANGED,
  RECONCILE_ALL,
  RECONCILE_ALL_APPROVED_STATE_UPDATED,
  RECONCILE_MATCH,
  RESET_BULK_RECONCILE_STATE,
  SELECT_DIFFERENCE_TYPE,
  SET_BALANCE_BAR_OPEN,
  TRANSACTIONS_LOADING,
  UNGROUP_BANK_LINE,
  UNRECONCILE_MATCH,
} from './actions'
import {
  BankConnection,
  BankConnectionReceived,
  BankConnectionRequested,
  BankConnectionRequestFailed,
  BankLine,
  BankLineMatchesDeleted,
  BankLineMatchRemoved,
  BankLineMatchUpdated,
  BankLinesAndTransactionsReceived,
  BankLinesAndTransactionsRequested,
  BankLineSubjectAssociation,
  BankLineSubjectAssociationCreated,
  BankLineSubjectAssociationDeleted,
  BankLineSubjectAssociationUpdated,
  BankLineUngrouped,
  BillyTransaction,
  BillyTransactionAdded,
  BulkReconcilePayload,
  CreateBillAndReconcileAction,
  CreateBillAndReconcilePayload,
  CreateInvoiceAndReconcileAction,
  CreateInvoiceAndReconcilePayload,
  DeleteBankLinesAction,
  DeleteBankLinesPayload,
  DeleteBankLineSubjectAssociationAction,
  DifferenceTypePayload,
  DifferenceTypeSelectedAction,
  ExtendedBankLineMatch,
  Filters,
  FiltersChanged,
  PrimoUltimoBalance,
  ReconcileAllApprovedStatePayload,
  ReconcileMatchAction,
  ReconcileMatchPayload,
  SelectDifferenceTypeAction,
  UngroupBankLine,
  UnreconcileMatchAction,
  UnreconcileMatchPayload,
} from './types'

type UngroupBankLineProps = {
  accountId: string
  amount: number | undefined
  entryDate: string
  isApproved: boolean
  lineId: string
  matchId: string
  side: string
}

type BankLinesAndTransactionsReceivedProps = {
  bankLineMatches: ExtendedBankLineMatch[]
  billyTransactions: BillyTransaction[]
  bankLineSubjectAssociations: BankLineSubjectAssociation[]
}

type BankLineUngroupedProps = {
  newBankLineMatch: ExtendedBankLineMatch
  previousBankLineMatchId: string
  ungroupedBankLine: BankLine
}

type DNDPlaceholderProps = {
  source: any
  type: string
}

// --------------------------------------
// Bank connection
// --------------------------------------

export function bankConnectionRequested(organizationId: string, accountId: string): BankConnectionRequested {
  return {
    payload: { organizationId, accountId },
    type: BANK_CONNECTION_REQUESTED,
  }
}

export function bankConnectionRequestFailed(error: APIError): BankConnectionRequestFailed {
  return {
    payload: error,
    type: BANK_CONNECTION_REQUEST_FAILED,
  }
}

export function bankConnectionReceived(bankConnection: BankConnection | null): BankConnectionReceived {
  return {
    payload: bankConnection,
    type: BANK_CONNECTION_RECEIVED,
  }
}

// --------------------------------------
// Bank lines and Billy transactions
// --------------------------------------

export function bankLinesAndTransactionsRequested(silent = false): BankLinesAndTransactionsRequested {
  return {
    payload: silent,
    type: BANK_LINES_AND_TRANSACTIONS_REQUESTED,
  }
}

export function bankLinesAndTransactionsReceived(
  payload: BankLinesAndTransactionsReceivedProps,
): BankLinesAndTransactionsReceived {
  return {
    payload,
    type: BANK_LINES_AND_TRANSACTIONS_RECEIVED,
  }
}

export function bankLineLatestRequested() {
  return {
    payload: null,
    type: BANK_LINES_LATEST_LINE_REQUESTED,
  }
}

export function bankLineLatestReceived(payload: { latestBankLineEntryDate: string | undefined }) {
  return {
    payload,
    type: BANK_LINES_LATEST_LINE_RECEIVED,
  }
}

// --------------------------------------
// CRUD bank lines and Billy transactions
// --------------------------------------

export function billyTransactionAdded(transaction: BillyTransaction): BillyTransactionAdded {
  return {
    payload: transaction,
    type: BILLY_TRANSACTION_ADDED,
  }
}

export function bankLineMatchRemoved(matchId: string): BankLineMatchRemoved {
  return {
    payload: matchId,
    type: BANK_LINE_MATCH_REMOVED,
  }
}

export function bankLineMatchUpdated(bankLineMatch: ExtendedBankLineMatch): BankLineMatchUpdated {
  return {
    payload: bankLineMatch,
    type: BANK_LINE_MATCH_UPDATED,
  }
}

export function bankLineSubjectAssociationCreated(
  bankLineSubjectAssociation: BankLineSubjectAssociation,
): BankLineSubjectAssociationCreated {
  return {
    payload: bankLineSubjectAssociation,
    type: BANK_LINE_SUBJECT_ASSOCIATION_CREATED,
  }
}

export function bankLineSubjectAssociationUpdated(
  bankLineSubjectAssociation: BankLineSubjectAssociation,
): BankLineSubjectAssociationUpdated {
  return {
    payload: bankLineSubjectAssociation,
    type: BANK_LINE_SUBJECT_ASSOCIATION_UPDATED,
  }
}

export function deleteBankLineSubjectAssociation(payload: {
  bankLineSubjectAssociation: BankLineSubjectAssociation
  bankLineMatch: ExtendedBankLineMatch
}): DeleteBankLineSubjectAssociationAction {
  return {
    payload,
    type: BANK_LINE_SUBJECT_ASSOCIATION_DELETE_REQUESTED,
  }
}

export function bankLineSubjectAssociationDeleted(
  bankLineSubjectAssociationId: string,
): BankLineSubjectAssociationDeleted {
  return {
    payload: bankLineSubjectAssociationId,
    type: BANK_LINE_SUBJECT_ASSOCIATION_DELETED,
  }
}

// --------------------------------------
// Grouping / ungrouping
// --------------------------------------

export function ungroupBankLine(
  { accountId, lineId, matchId, amount, entryDate, isApproved, side }: UngroupBankLineProps,
  cb: (err: string | null) => void,
): UngroupBankLine {
  return {
    payload: { accountId, lineId, matchId, amount, entryDate, isApproved, side, cb },
    type: UNGROUP_BANK_LINE,
  }
}

export function bankLineUngrouped({
  newBankLineMatch,
  ungroupedBankLine,
  previousBankLineMatchId,
}: BankLineUngroupedProps): BankLineUngrouped {
  return {
    payload: { newBankLineMatch, ungroupedBankLine, previousBankLineMatchId },
    type: BANK_LINE_UNGROUPED,
  }
}

export function bankLineMatchesDeleted(lineIds: string[]): BankLineMatchesDeleted {
  return {
    payload: lineIds,
    type: BANK_LINE_MATCHES_DELETED,
  }
}

// --------------------------------------
// Drag'n'Drop
// --------------------------------------

type DNDProps = {
  id: string
  matchId: string
  sourceMatchId: string
  billyTransaction?: BillyTransaction
  bankLineSubjectAssociation?: BankLineSubjectAssociation
}

export function dndBankLines(payload: DNDProps): AnyAction {
  return {
    payload,
    type: DND_BANK_LINES,
  }
}

export function dndBillyTransactions(payload: DNDProps): AnyAction {
  return {
    payload,
    type: DND_BILLY_TRANSACTIONS,
  }
}

export function createPlaceholder({ source, type }: DNDPlaceholderProps): AnyAction {
  return {
    payload: { source, type },
    type: CREATE_PLACEHOLDER,
  }
}

export function destroyPlaceholders(): AnyAction {
  return {
    payload: null,
    type: DESTROY_PLACEHOLDERS,
  }
}

export function toggleTransactionsDroppableArea(payload: { isActive: boolean }): AnyAction {
  return {
    payload,
    type: ACTIVATE_TRANSACTION_DROPPABLE_AREA,
  }
}

// --------------------------------------
// Filters
// --------------------------------------

export function filtersChanged(payload: Partial<Filters>): FiltersChanged {
  return {
    payload,
    type: FILTERS_CHANGED,
  }
}

// --------------------------------------
// Balance bar
// --------------------------------------

export function bankBalanceRequested(): AnyAction {
  return {
    payload: null,
    type: BANK_BALANCE_REQUESTED,
  }
}

type BankBalanceReceivedProps = {
  primoUltimoBalance: PrimoUltimoBalance | null
}

export function bankBalanceReceived(payload: BankBalanceReceivedProps): AnyAction {
  return {
    payload,
    type: BANK_BALANCE_RECEIVED,
  }
}

export function differenceTypeSelected({ type, matchId }: DifferenceTypePayload): DifferenceTypeSelectedAction {
  return {
    payload: { type, matchId },
    type: DIFFERENCE_TYPE_SELECTED,
  }
}

export function selectDifferenceType({ type, matchId }: DifferenceTypePayload): SelectDifferenceTypeAction {
  return {
    payload: { type, matchId },
    type: SELECT_DIFFERENCE_TYPE,
  }
}

export function setBalanceBarOpen({ isOpen }: { isOpen: boolean }): AnyAction {
  return {
    payload: { isOpen },
    type: SET_BALANCE_BAR_OPEN,
  }
}

// --------------------------------------
// Reconciliation
// --------------------------------------

export function bulkReconcile(payload: BulkReconcilePayload): AnyAction {
  return {
    payload,
    type: BULK_RECONCILE,
  }
}

export function reconcileAllApprovedStateUpdated(payload: ReconcileAllApprovedStatePayload): AnyAction {
  return {
    payload,
    type: RECONCILE_ALL_APPROVED_STATE_UPDATED,
  }
}

export function reconcileAll(): AnyAction {
  return {
    payload: null,
    type: RECONCILE_ALL,
  }
}

export function reconcileMatch(payload: ReconcileMatchPayload): ReconcileMatchAction {
  return { payload, type: RECONCILE_MATCH }
}

export function unreconcileMatch(payload: UnreconcileMatchPayload): UnreconcileMatchAction {
  return { payload, type: UNRECONCILE_MATCH }
}

export function createBillAndReconcile(payload: CreateBillAndReconcilePayload): CreateBillAndReconcileAction {
  return { payload, type: CREATE_BILL_AND_RECONCILE }
}

export function createInvoiceAndReconcile(payload: CreateInvoiceAndReconcilePayload): CreateInvoiceAndReconcileAction {
  return { payload, type: CREATE_INVOICE_AND_RECONCILE }
}

type ReconcileAllApprovedStateUpdatedPayload = {
  matchId: string
  success?: boolean
}

export function bulkReconcileStateUpdated(payload: ReconcileAllApprovedStateUpdatedPayload): AnyAction {
  return { payload, type: BULK_RECONCILE_STATE_UPDATED }
}

export function resetBulkReconcileState(): AnyAction {
  return { type: RESET_BULK_RECONCILE_STATE }
}

// --------------------------------------
// Bulk line operations
// --------------------------------------

export function deleteBankLines(payload: DeleteBankLinesPayload): DeleteBankLinesAction {
  return {
    payload,
    type: DELETE_BANK_LINES,
  }
}

// --------------------------------------
// Loaders
// --------------------------------------

export function bankLinesLoading({ isLoading }: { isLoading: boolean }): AnyAction {
  return {
    payload: { isLoading },
    type: BANK_LINES_LOADING,
  }
}

export function transactionsLoading({ isLoading }: { isLoading: boolean }): AnyAction {
  return {
    payload: { isLoading },
    type: TRANSACTIONS_LOADING,
  }
}
