import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { Spacing } from '../../enums/spacing'
import { Text as TextComponent, TextProps } from '../Text'

type AmountProps = {
  alignCenter?: boolean
}

export const AmountValueWrapper = styled.span<AmountProps>`
  display: flex;
  align-items: baseline;
  ${({ alignCenter }) => alignCenter && `justify-content: center;`};
`

type ExtendedTextProps = TextProps & {
  fontSize: number
  color?: Color
}

export const ScaledText = styled(TextComponent)<ExtendedTextProps>`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`};
`

export const CurrencyText = styled.span`
  margin-left: ${Spacing.XXS};
`
