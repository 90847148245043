import { Button, SectionMessage } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface ExpiredCardInfoProps {
  onUpdateCard?: () => void
}

export const ExpiredCardInfo = ({ onUpdateCard }: ExpiredCardInfoProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.ExpiredCardInfoWrapper>
      <SectionMessage
        variant="error"
        icon="exclamationMarkCircle"
        sideActions={
          <Button variant="secondary" onClick={onUpdateCard}>
            {t('billing_summary.update_credit_card.button')}
          </Button>
        }
        showIcon
      >
        {t('billing_summary.update_credit_card.title')}
      </SectionMessage>
    </Styled.ExpiredCardInfoWrapper>
  )
}
