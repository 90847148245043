import React, { ReactElement } from 'react'

import { TextVariant } from '../Text'
import * as Styled from './styles'

const DEFAULT_WIDTH = 150

export interface SkeletonTextProps {
  fullWidth?: boolean
  variant?: TextVariant
  width?: number
}

export const SkeletonText = ({
  variant = 'normal',
  width = DEFAULT_WIDTH,
  ...rest
}: SkeletonTextProps): ReactElement => <Styled.SkeletonText variant={variant} width={width} {...rest} />
