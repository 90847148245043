import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`
export const OverlaySubscriptionPlansAndHeaderWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  padding: ${Spacing.XXXL};
  flex-direction: column;
  align-items: center;
`

export const OverlayHeader = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${Spacing.XXXL};
  justify-content: space-between;
`

export const OverlayHeaderLeftSection = styled.div``
