import { createSelector } from 'reselect'

import { State } from '../../../../types/reduxSaga-deprecated'
import { Account, SelectAccountProps } from '../types'
import { getFilteredAccounts } from '../utils/getFilteredAccounts'

export const accountsSelector = createSelector(
  (state: State) => state.app.accounts,
  (_: unknown, props: SelectAccountProps) => props,
  (accounts: Account[] | undefined, props: SelectAccountProps) => getFilteredAccounts(accounts, props),
)
