import { TFunction } from 'i18next'
import { object, SchemaOf, string } from 'yup'

export interface BankToAccountConnectionForm {
  bankAccount: string
  billyAccount: string
}

export type BankToAccountConnectionFormSchema = SchemaOf<BankToAccountConnectionForm>

export const getValidationSchema = (t: TFunction): BankToAccountConnectionFormSchema =>
  object({
    bankAccount: string().required(t('bank_integration.bank_account')),
    billyAccount: string().required(t('bank_integration.ageras_account')),
  })
