import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../../../utils'
import { postAdditionalField } from '../../../../../query-api'

interface UseCreateAdditionalField {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useCreateAdditionalField = (props?: UseCreateAdditionalField) => {
  const { onError, onSuccess } = props || {}

  const { mutate: create, ...rest } = useMutation(postAdditionalField, {
    onError,
    onSuccess,
  })

  return { create, ...rest }
}
