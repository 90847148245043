import { useMemo } from 'react'

import { useQueryParams } from '../../../../../hooks/useQueryParams'

interface ChartOfAccountsFilters {
  noPublicCoaId?: boolean
}

type UseChartOfAccountsFiltersResult = [ChartOfAccountsFilters, (filters: Partial<ChartOfAccountsFilters>) => void]

export const defaultQuery: ChartOfAccountsFilters = {
  noPublicCoaId: undefined,
}

export const useChartOfAccountsFilters = (): UseChartOfAccountsFiltersResult => {
  const [queryParams, setQueryParams] = useQueryParams<ChartOfAccountsFilters>({
    defaultQuery,
  })

  return useMemo(() => [queryParams, setQueryParams], [queryParams, setQueryParams])
}
