import { Translate } from '@components'
import { Badge, InternalAccessor, Table, TableColumn, Text } from '@design-system'

import pick from 'lodash/pick'
import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SortDirection } from '../../../../../../../../enums/sortDirection'
import { getSalesTaxRulesetDisplayData } from '../../../../../../../../utils/getSalesTaxRulesetDisplayData'
import { getTableColumnIdDirection } from '../../../../../../../../utils/getTableColumnIdDirection'
import { RULESETS_FILTER_KEY } from '../../../../constants/rulesetsFilterKey'
import { VatRatesSortProperty } from '../../../../enums/vatRatesSortProperty'
import { useVatRatesListFilters } from '../../../../hooks/useVatRatesListFilters'
import { VatRatesTableStaticActions } from '../../../VatRatesTableStaticActions'
import { useRulesets } from '../../hooks/useRulesets'
import { RulesetsTableRow } from '../../types/rulesetsTableRow'
import { RulesetsTableHoverActions } from '../RulesetsTableHoverActions'

interface RulesetsTableProps {
  onRulesetDelete: (rulesetId: string) => void
  onShowEditRuleSetModal: (rulesetId: string) => void
}

export const RulesetsTable = ({ onRulesetDelete, onShowEditRuleSetModal }: RulesetsTableProps): ReactElement => {
  const { t } = useTranslation()
  const [{ sortDirection, sortProperty }, setFilters] = useVatRatesListFilters(RULESETS_FILTER_KEY)
  const { rulesets, isLoading } = useRulesets()

  const tableData: RulesetsTableRow[] = useMemo(
    () =>
      rulesets.map((ruleset) => ({
        ...pick(ruleset, ['id', 'isPredefined', 'description', 'name', 'abbreviation', 'predefinedTag']),
        [InternalAccessor.HoverActions]: true,
        [InternalAccessor.StaticActions]: !ruleset.isPredefined,
      })),
    [rulesets],
  )

  const columns: TableColumn<RulesetsTableRow>[] = useMemo(
    () => [
      {
        Header: <Translate value={'settings.organization.vat_rates.name'} />,
        accessor: 'name',
        sortable: true,
        size: 'xl',
        Cell: ({ value, row, data }) => {
          const { isPredefined, description, predefinedTag } = data[row.id] as RulesetsTableRow

          const { description: displayedDescription, title: displayedName } = getSalesTaxRulesetDisplayData({
            description,
            name: value,
            predefinedTag,
            t,
          })

          return (
            <>
              {displayedName} {isPredefined && <Badge>{t('settings.organization.vat_rates.predefined')}</Badge>}
              <Text colorVariant="secondary" variant="micro">
                {displayedDescription}
              </Text>
            </>
          )
        },
      },
      {
        Header: <Translate value={'settings.organization.vat_rates.abbreviation'} />,
        accessor: 'abbreviation',
        alignment: 'left',
        size: 'xxs',
        sortable: false,
      },
      {
        Header: '',
        accessor: InternalAccessor.HoverActions,
        sortable: false,
        Cell: ({ row, data }) => {
          const { id, isPredefined } = data[row.id] as RulesetsTableRow
          return <RulesetsTableHoverActions onEdit={onShowEditRuleSetModal} rulesetId={id} readonly={isPredefined} />
        },
      },
      {
        Header: '',
        accessor: InternalAccessor.StaticActions,
        sortable: false,
        Cell: ({ row, data }) => <VatRatesTableStaticActions onDelete={onRulesetDelete} id={data[row.id].id} />,
      },
    ],
    [onRulesetDelete, onShowEditRuleSetModal, t],
  )

  const sortedColumnId = useMemo(() => {
    return getTableColumnIdDirection(sortProperty, sortDirection === SortDirection.Desc)
  }, [sortDirection, sortProperty])

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      setFilters({
        sortProperty: columnId as VatRatesSortProperty,
        sortDirection: isDesc ? SortDirection.Desc : SortDirection.Asc,
      })
    },
    [setFilters],
  )

  return (
    <Table
      columns={columns}
      data={tableData}
      isLoading={isLoading}
      skeletonItemsPerPage={5}
      onSort={handleSort}
      sortedColumnId={sortedColumnId}
    />
  )
}
