import { css, keyframes } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { Box, Flex } from 'rebass'

import { Theme } from '../../types/theme'

type ProcessingIndicatorProps = {
  color?: string
}

type CircleProps = {
  color: string
  offset: number
}

const t = keyframes`
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
}
`
const Circle = ({ color, offset }: CircleProps) => (
  <Box
    css={css`
      width: 5px;
      height: 5px;
      margin-right: 3px;
      background: ${color};
      border-radius: 100%;
      animation: ${t} 2s ease infinite;
      animation-delay: ${offset}ms;
    `}
  />
)

export const ProcessingIndicator = ({ color = 'primary' }: ProcessingIndicatorProps) => {
  const theme = useTheme<Theme>()

  return (
    <Flex>
      <Circle offset={0} color={theme.colors[color]} />
      <Circle offset={200} color={theme.colors[color]} />
      <Circle offset={400} color={theme.colors[color]} />
    </Flex>
  )
}
