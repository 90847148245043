import { AccountSelect } from '@components'
import { Divider, FormItem, Space, useModal } from '@design-system'

import { ReactElement, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { useSegment } from '../../../../../../hooks'
import { useBillsEmberConnection } from '../../../../contexts/billsEmberConnectionContext'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useMatch } from '../../contexts/matchContext'
import { useBillReconciliationABTest } from '../../hooks/useBillReconciliationABTest'
import { BillSummary } from '../BillSummary'
import { DifferenceSelector } from '../DifferenceSelector'
import { LinkToEmberManualPayment } from './elements/LinkToEmberManualPayment'
import { ReconciliationSuggestionABTest } from './elements/ReconciliationSuggestionABTest'

export const MatchModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  // when enabling Bank Lines, remoteClosed ref will not be needed any more
  const remoteClosed = useRef(false)
  const { initialBill, setPaymentDate } = useBillReconciliation()
  const { hasMatchDifference, matchDifference, matchSuggestion } = useMatch()
  const { isInVariantB } = useBillReconciliationABTest()
  const { close: closeBillReconciliationModal, open: openBillReconciliationModal } = useModal(
    ModalId.BillReconciliationModal,
    {
      onClose: () => {
        if (remoteClosed.current) {
          remoteClosed.current = false
          return
        }

        track('xxx - expenses - Pay and reconcile modal closed')
      },
    },
  )
  const { registerPayment } = useBillsEmberConnection()

  // when enabling Bank Lines, handlePayManually function should be deleted and components that use it should be adjusted
  const handlePayManually = useCallback(
    (isWrongMatch = false) => {
      if (!initialBill?.id) {
        return
      }

      remoteClosed.current = true
      closeBillReconciliationModal()
      registerPayment({
        billId: initialBill?.id,
        onBack: () => {
          openBillReconciliationModal()
        },
      })

      const eventName = isWrongMatch
        ? 'xxx - expenses - Pay and reconcile modal - wrong match button clicked'
        : 'xxx - expenses - Pay and reconcile modal - manual payment clicked'
      track(eventName)
    },
    [initialBill?.id, closeBillReconciliationModal, openBillReconciliationModal, registerPayment, track],
  )

  useEffect(() => {
    const matchDate = matchSuggestion?.bank_date || initialBill?.entryDate

    if (matchDate) {
      setPaymentDate(matchDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBill?.entryDate, matchSuggestion?.bank_date])

  return (
    <>
      <BillSummary />
      <Space />
      <Divider />
      <Space />
      <FormItem label={t('account')}>
        <AccountSelect disabled selectedId={matchSuggestion?.account_id} />
      </FormItem>
      <Space />
      <ReconciliationSuggestionABTest onPayManually={handlePayManually} />
      {hasMatchDifference && <DifferenceSelector difference={matchDifference} />}
      {/* when enabling Bank Lines, replace LinkToEmberManualPayment with LinkToBankLines */}
      {isInVariantB && <LinkToEmberManualPayment onLinkClick={handlePayManually} />}
    </>
  )
}
