import { TableHoverActions, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateOrEditVatRateFromTemplateModal } from '../VatRateModal/CreateOrEditVatRateFromTemplateModal'
import { CreateOrEditVatRateModal } from '../VatRateModal/CreateOrEditVatRateModal'

interface VatRatesTableHoverActionsProps {
  vatRateId: string
  readonly: boolean
  isTemplateModal: boolean
  modalId: string
}
export const VatRatesTableHoverActions = ({
  vatRateId,
  readonly,
  isTemplateModal,
  modalId,
}: VatRatesTableHoverActionsProps): ReactElement => {
  const { open: openEditModal } = useModal(modalId)
  const { t } = useTranslation()

  const handleEditClick = useCallback(() => {
    openEditModal()
  }, [openEditModal])

  return (
    <>
      <TableHoverActions>
        {readonly ? (
          <TableHoverActions.Item icon="eye" label={t('view')} onClick={handleEditClick} />
        ) : (
          <TableHoverActions.Item icon="paperWithPencil" label={t('edit')} onClick={handleEditClick} />
        )}
      </TableHoverActions>
      {isTemplateModal ? (
        <CreateOrEditVatRateFromTemplateModal id={modalId} vatRateId={vatRateId} disabled={readonly} />
      ) : (
        <CreateOrEditVatRateModal id={modalId} vatRateId={vatRateId} disabled={readonly} />
      )}
    </>
  )
}
