import { Button, ButtonsGroup, DefaultValue, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, FlexProps } from 'rebass'

type Alignment = 'left' | 'right'

type BulkActionsWrapperProps = FlexProps & {
  align: Alignment
}

const BulkActionsWrapper = styled(Flex)<BulkActionsWrapperProps>`
  min-height: 58px;
  border-radius: ${DefaultValue.BorderRadius};
  padding: 0 ${Spacing.L} ${Spacing.L};
  align-items: center;
  justify-content: ${({ align }) => (align === 'left' ? 'flex-start' : 'flex-end')};
`

const ButtonsWrapper = styled(ButtonsGroup)`
  margin-left: ${Spacing.L};
`

export type BulkActionsProps = FlexProps & {
  active?: boolean
  align?: Alignment
  onDeleteRow?: () => void
  onDone?: () => void
  onToggle: (active: boolean) => void
  selectedRows?: number
  selectedRowsText?: (selectedRows: number) => string
  totalRows: number
  totalRowsText: (totalRows: number) => string
  skipClosingOnDelete?: boolean
}

export const BulkActions = ({
  active,
  align = 'left',
  onDeleteRow = () => null,
  onDone = () => null,
  onToggle,
  selectedRows = 0,
  selectedRowsText,
  skipClosingOnDelete,
  totalRows,
  totalRowsText,
  ...rest
}: BulkActionsProps) => {
  const { t } = useTranslation()

  const handleDeleteRow = () => {
    onDeleteRow()

    if (!skipClosingOnDelete) {
      onToggle(false)
    }
  }

  const handleDone = () => {
    onDone()
    onToggle(false)
  }

  const selectedRowsTextComputed = selectedRowsText
    ? selectedRowsText(selectedRows)
    : t('components.infinitetable.selected_rows', { count: selectedRows })

  return (
    <BulkActionsWrapper align={align} {...rest}>
      {active ? (
        <>
          <Text>{selectedRowsTextComputed}</Text>
          <ButtonsWrapper>
            <Button danger disabled={!selectedRows} onClick={handleDeleteRow} size="m">
              {t('delete')}
            </Button>
            <Button onClick={handleDone} size="m">
              {t('done')}
            </Button>
          </ButtonsWrapper>
        </>
      ) : (
        <>
          {totalRowsText && <Text>{totalRowsText(totalRows)}</Text>}
          <ButtonsWrapper>
            <Button onClick={() => onToggle(true)} size="m">
              {t('edit')}
            </Button>
          </ButtonsWrapper>
        </>
      )}
    </BulkActionsWrapper>
  )
}
