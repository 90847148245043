import { IconCaretDown, Input, Text } from '@components-deprecated'
import { FontWeight, rwd } from '@design-system'

import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { ChangeEvent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

export enum SuggestionSortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum SuggestionSortOptions {
  Amount = 'signedAmount',
  Date = 'date',
  AmountDifference = 'amountDifference',
}

export type SuggestionFilters = {
  orderBy: SuggestionSortOptions
  direction: SuggestionSortDirection
  amountDifference: number
  daysSpan: number
}

type SortLabels = Record<SuggestionSortOptions, string>

const getSortPropertyLabels = (t: TFunction): SortLabels => ({
  signedAmount: t('voucher.inbox.reconciliation.amount'),
  date: t('voucher.inbox.reconciliation.date'),
  amountDifference: t('voucher.inbox.reconciliation.amount_difference'),
})

const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`

const AmountDifferenceWrapper = styled.div`
  display: flex;
  align-items: center;
`

const DifferenceInput = styled(Input)`
  width: 75px;
  margin-left: 10px;
  margin-bottom: 0;
`

const SortWrapper = styled.ul`
  display: flex;
  align-items: center;
`
type SortOptionProps = {
  active?: boolean
}

const SortOption = styled(Text)<SortOptionProps>`
  font-weight: ${({ active }) => (active ? FontWeight.Medium : FontWeight.Regular)};
  cursor: pointer;
  user-select: none;
  margin-left: 10px;
`

const SortOptionLabel = styled(Text)`
  margin-left: 10px;

  ${rwd.equalOrLessThan('xlarge')} {
    display: none;
  }
`

export const DefaultReconSuggestionFilters: SuggestionFilters = {
  amountDifference: 5,
  daysSpan: 15,
  orderBy: SuggestionSortOptions.AmountDifference,
  direction: SuggestionSortDirection.Asc,
}

type ReconcilationSuggestionFiltersProps = {
  onFiltersChange?: (filters: SuggestionFilters) => void
}

export const ReconcilationSuggestionFilters = ({
  onFiltersChange = () => null,
  ...rest
}: ReconcilationSuggestionFiltersProps): ReactElement => {
  const [filters, setFilters] = useState<SuggestionFilters>(DefaultReconSuggestionFilters)
  const { t } = useTranslation()

  const sortOptions = getSortPropertyLabels(t)

  const handleDifferenceUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(event.target.value, 10) || 0

    if (!isFinite(number)) {
      return
    }

    handleFiltersUpdate({
      ...filters,
      amountDifference: Math.abs(number),
    })
  }

  const handleFiltersUpdate = (filters: SuggestionFilters) => {
    setFilters(filters)
    onFiltersChange(filters)
  }

  const handleSortUpdate = (sortProperty: SuggestionSortOptions) => {
    const isCurrentPropertyActive = filters.orderBy.includes(sortProperty)

    const oppositeDirection =
      filters.direction === SuggestionSortDirection.Asc ? SuggestionSortDirection.Desc : SuggestionSortDirection.Asc
    const newDirection = isCurrentPropertyActive ? oppositeDirection : filters.direction

    const updatedFilters = {
      ...filters,
      orderBy: sortProperty,
      direction: newDirection,
    }

    handleFiltersUpdate(updatedFilters)
  }

  return (
    <FiltersWrapper {...rest}>
      <AmountDifferenceWrapper>
        <Text>{t('voucher.inbox.reconciliation.amount_diff')}</Text>
        <DifferenceInput
          name="amount-difference"
          type="number"
          defaultValue={filters.amountDifference}
          onChange={handleDifferenceUpdate}
          min="0"
        />
      </AmountDifferenceWrapper>
      <SortWrapper>
        <SortOptionLabel>{t('voucher.inbox.reconciliation.sort_by')}</SortOptionLabel>
        {Object.keys(sortOptions).map((key) => {
          const isActive = filters.orderBy === key

          return (
            <SortOption key={key} onClick={() => handleSortUpdate(key as SuggestionSortOptions)} active={isActive}>
              {sortOptions[key]}{' '}
              {isActive && (
                <IconCaretDown rotate={filters.direction === SuggestionSortDirection.Asc} width="14px" height="14px" />
              )}
            </SortOption>
          )
        })}
      </SortWrapper>
    </FiltersWrapper>
  )
}
