import { NavItem, Select, SelectProps } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../../../../../../../../../../enums/queryKeys'
import { SortDirection } from '../../../../../../../../../../../../enums/sortDirection'
import { CountryGroupSortProperty } from '../../../../../../../../enums/countryGroupSortProperty'
import { fetchCountryGroups } from '../../../../../../../../query-api'
import { CountryGroup } from '../../../../../../../../types/countryGroup'
import { getDisplayTitle } from './utils/getDisplayTitle'
import { getItems } from './utils/getItems'

interface CountryGroupSelectProps
  extends Omit<SelectProps<CountryGroup>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: CountryGroup) => void
}

export const CountryGroupSelect = ({ onSelect, ...selectProps }: CountryGroupSelectProps): ReactElement => {
  const { organization } = useUserOrganization()

  const { data: countryGroups, isLoading } = useQuery(
    [QueryKeys.AdditionalFields, organization?.id, CountryGroupSortProperty.Name, SortDirection.Asc],
    () =>
      fetchCountryGroups({
        organizationId: organization?.id as string,
        sortProperty: CountryGroupSortProperty.Name,
        sortDirection: SortDirection.Asc,
      }),
    {
      enabled: !!organization?.id,
    },
  )

  const items = useMemo(() => getItems(countryGroups?.countryGroups || []), [countryGroups])

  const getDisplayValue = useCallback((item: NavItem<CountryGroup>) => getDisplayTitle(item.value), [])

  const handleSelect = useCallback(
    (id?: string, value?: CountryGroup) => {
      onSelect?.(id, value)
    },
    [onSelect],
  )

  return (
    <Select
      {...selectProps}
      dropdownFetching={isLoading}
      getDisplayValue={getDisplayValue}
      items={items}
      dropdownSize="fitTrigger"
      onSelect={handleSelect}
      allowClear
    />
  )
}
