import { Timeout } from '@design-system'

import { useEffect } from 'react'

import { VerificationStatus } from '../enums/verificationStatus'
import { getVerificationResult } from '../query-api'

export const usePollVerificationResult = (
  jobId: string | undefined,
  organizationId: string | null,
  verificationScore: number | undefined,
  setVerificationScore: (score: number) => void,
  openFrodaProcessingModal: () => void,
  closeFrodaProcessingModal: () => void,
  openFrodaFailedModal: () => void,
) => {
  useEffect(() => {
    let interval: Timeout

    const getResult = async () => {
      interval = setInterval(async () => {
        try {
          const result = await getVerificationResult(organizationId as string, jobId as string)

          if (result.status === VerificationStatus.Succeeded) {
            setVerificationScore(result.accountOwner?.score)
            clearInterval(interval)
          } else if (result.status === VerificationStatus.Failed) {
            closeFrodaProcessingModal()
            openFrodaFailedModal()
            clearInterval(interval)
          }
        } catch (error) {
          closeFrodaProcessingModal()
          console.error('Error fetching verification job result', error)
          clearInterval(interval)
        }
      }, 1000)
    }

    if (jobId && !verificationScore) {
      openFrodaProcessingModal()
      getResult()
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [
    jobId,
    verificationScore,
    organizationId,
    setVerificationScore,
    openFrodaProcessingModal,
    closeFrodaProcessingModal,
    openFrodaFailedModal,
  ])
}
