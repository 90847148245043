import { css } from '@emotion/core'
import React from 'react'
import { Box, BoxProps } from 'rebass'

import { reactClass } from '../../../utils'
import { animateGradient } from '../utils'

export type LineProps = BoxProps & {
  bg?: string
  borderRadius?: string
  color1: string
  color2: string
  header?: boolean
  spacing: string
  tempo?: number
}

export const Line = ({ bg, borderRadius, color1, color2, header, spacing, tempo, ...rest }: LineProps) => (
  <Box
    className={reactClass(`loading-placeholder${header ? '-header' : '-line'}`)}
    css={css`
      margin: ${spacing} 0;
      height: ${header ? 16 : 10}px;
      background-color: ${bg};
      border-radius: ${borderRadius};
      ${animateGradient({ color1, color2, tempo })}
    `}
    {...rest}
  />
)
