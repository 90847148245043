import React, { forwardRef, ReactElement, Ref } from 'react'

import { Color } from '../../enums/color'
import { Button, ButtonProps } from '../Button'
import { IconName } from '../Icon/Icon'

export interface IconButtonProps extends Omit<ButtonProps, 'icon' | 'color' | 'fullRound'> {
  color?: Color
  icon: IconName
}

export const IconButton = forwardRef(
  (
    { color, variant = 'text', size = 'l', ...rest }: IconButtonProps,
    forwardedRef: Ref<HTMLButtonElement>,
  ): ReactElement => (
    <Button role="icon-button" ref={forwardedRef} variant={variant} size={size} iconColor={color} fullRound {...rest} />
  ),
)
