import React, { ReactElement, ReactNode } from 'react'

import { Checkmark } from '../Checkmark'
import * as Styled from './styles'

interface QuestionProps {
  content: ReactNode
  isAnswered?: boolean
  title: string
  questionNumber: number
  sideActions?: ReactNode
}

export const Question = ({ title, content, isAnswered, sideActions, questionNumber }: QuestionProps): ReactElement => {
  return (
    <Styled.QuestionWrapper>
      <Styled.TitleWrapper>
        <Styled.Title colorVariant={isAnswered ? 'success' : 'primary'} variant="h3">
          {questionNumber}. {title}
        </Styled.Title>
        {isAnswered && <Checkmark />}
        {!isAnswered && !!sideActions && <Styled.SideActions>{sideActions}</Styled.SideActions>}
      </Styled.TitleWrapper>
      {content}
    </Styled.QuestionWrapper>
  )
}
