import { ReactElement } from 'react'

import { useShouldDisplayExternalInvoicesSyncInitiateAction } from '@features/externalInvoicesSync/hooks/useShouldDisplayExternalInvoicesSyncInitiateAction'

import { useOrganizationSource } from '../../../../hooks/useOrganizationSource'
import { CurrencyDefaultPaymentMethodsSection } from './elements/CurrencyDefaultPaymentMethodsSection'
import { InvoiceAndProductSettingsSection } from './elements/InvoiceAndProductSettingsSection'
import { InvoicesHistorySection } from './elements/InvoicesHistorySection'
import { InvoiceTemplatesSection } from './elements/InvoiceTemplatesSection'
import { PaymentMethodsSection } from './elements/PaymentMethodsSection'

export const InvoiceSettings = (): ReactElement | null => {
  const { isSourceBilly, isSourceZervant } = useOrganizationSource()
  const { shouldDisplayExternalInvoicesSyncInitiateAction } = useShouldDisplayExternalInvoicesSyncInitiateAction()

  if (isSourceBilly) {
    return (
      <>
        <InvoiceAndProductSettingsSection />
        <InvoiceTemplatesSection />
        <PaymentMethodsSection />
        <CurrencyDefaultPaymentMethodsSection />
      </>
    )
  }

  if (isSourceZervant && shouldDisplayExternalInvoicesSyncInitiateAction) {
    return <InvoicesHistorySection />
  }

  return null
}
