import { TableActionsWrapper } from '@components'

import React, { ReactElement } from 'react'

import { InvoicesPeriodSelector } from '../InvoicesPeriodSelector'
import { InvoicesSearch } from './elements/InvoicesSearch'
import { InvoicesTitle } from './elements/InvoicesTitle'

export const InvoicesTableActions = (): ReactElement => {
  return (
    <TableActionsWrapper>
      <InvoicesTitle />
      <InvoicesSearch />
      <InvoicesPeriodSelector />
    </TableActionsWrapper>
  )
}
