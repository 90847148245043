import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useForm } from '../../../../../hooks'
import { useLogin } from '../../../../../hooks/useLogin'
import { SubmitLoginResponseData } from '../../../../../query-api/login-query'
import { APIError } from '../../../../../utils'
import { getLoginFormDefaultValues, getValidationSchema, LoginForm } from '../utils/formData'

interface UseLoginFormProps {
  invitationEmail: string
  onSuccess?: (response: SubmitLoginResponseData) => void
}

export const useLoginForm = ({ invitationEmail, onSuccess }: UseLoginFormProps) => {
  const { t } = useTranslation()
  const { Form, FormItem, handleSubmit, setError } = useForm({
    defaultValues: getLoginFormDefaultValues(invitationEmail),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const { login, isLoading } = useLogin({
    onSuccess,
    onError: (error?: APIError) => {
      setError('password', {
        type: 'custom',
        message: error?.validationErrors?.credentials?.message || t('error_saving'),
      })
    },
  })

  const handleSubmitForm = useCallback(
    ({ email, password }: LoginForm) => {
      login({ email, password })
    },
    [login],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return {
    Form,
    FormItem,
    submitForm,
    isLoading,
  }
}
