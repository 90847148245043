import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { Themable } from '../../../../types/themable'
import { TableRow } from '../TableRow'

export const TableEmptyView = styled(TableRow)`
  cursor: unset;

  &,
  &:hover {
    background-color: ${Color.White};
  }
`

type TableEmptyViewContentProps = Themable

export const TableEmptyViewContent = styled.div<TableEmptyViewContentProps>`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.ds.colors.base.textPrimary};
`
