import { SimpleCheckList } from '@components'
import { SectionMessage, Space, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { getImgPath } from '../../../../../../utils/getImgPath'
import { checkPointsTranslationKeys } from '../../constants/checkPointsTranslationKeys'
import * as Styled from './styles'

export const ModalBody = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <>
      <SectionMessage variant="neutral" showIcon={false} title={t('approve_invoice_modal.section_message')} />
      <Space size="xl" />
      <Text variant="large" weight="medium" alignment="center">
        {t('approve_invoice_modal.check_points.heading')}
      </Text>
      <Space size="l" />
      <Styled.CheckPointsListAndImageWrapper>
        <SimpleCheckList items={checkPointsTranslationKeys} alignment="left" />
        <Styled.ModalImage
          src={getImgPath('/modals/security-shield-illustration.svg')}
          width="118"
          height="118"
          alt="security shield"
        />
      </Styled.CheckPointsListAndImageWrapper>
      <Space size="xl" />
      <Text variant="large" weight="medium" alignment="center">
        {t('approve_invoice_modal.check_points.text_below')}
      </Text>
    </>
  )
}
