import invert from 'lodash/invert'

import { CustomEvent } from '../../../enums/customEvent'
import { EmberRoute } from '../../../enums/emberRoute'
import { CustomEventDetail } from '../../../hooks/useCustomEventsRoutes'
import { dispatchCustomEvent } from '../../../utils/customEvent'
import { settingsRouteToEmberSettingsRoute } from '../constants/settingsRouteToEmberSettingsRoute'
import { SettingsRoute } from '../enums/settingsRoute'

// there's an issue when we try to navigate using ember routing to the same module which is currently opened
// that's why it's better to just invoke a "history" method in this module using custom event
export const routeToInternalSettings = (route: EmberRoute) => {
  const settingsRoute: Partial<Record<EmberRoute, SettingsRoute>> = invert(settingsRouteToEmberSettingsRoute)
  const routeToSettings = settingsRoute[route]

  if (routeToSettings) {
    dispatchCustomEvent<CustomEventDetail>(CustomEvent.RoutingLinkClicked, {
      route: routeToSettings,
    })
  }
}
