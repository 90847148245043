import { ReactElement, ReactNode, useMemo } from 'react'

import { useTheme } from '../../hooks/useTheme'
import { LinkProps } from '../../types/linkProps'
import { getLinkProps } from '../../utils/getLinkProps'
import { Icon, IconName } from '../Icon'
import * as Styled from './styles'

export interface TabProps {
  active?: boolean
  badgeCounter?: number
  children: ReactNode
  href?: string
  icon?: IconName
  onClick?: () => void
  to?: string
}

export const Tab = ({ badgeCounter = 0, icon, children, active, href, to, onClick }: TabProps): ReactElement => {
  const theme = useTheme()
  const linkProps: LinkProps | undefined = useMemo(
    () =>
      getLinkProps({
        href,
        to,
      }),
    [href, to],
  )

  return (
    <Styled.Tab active={active} onClick={onClick} {...linkProps}>
      {icon && (
        <Styled.IconWrapper>
          <Icon
            color={active ? theme.ds.colors.base.textPrimary : theme.ds.colors.base.textSecondary}
            icon={icon}
            size="default"
          />
        </Styled.IconWrapper>
      )}
      {children}
      {badgeCounter > 0 && <Styled.BadgeCounter variant="neutral">{badgeCounter}</Styled.BadgeCounter>}
    </Styled.Tab>
  )
}
