import React from 'react'
import { Box, BoxProps } from 'rebass'

export type TabContentProps = BoxProps & {
  children?: React.ReactNode
  fixed?: boolean
  id?: string
  text?: string
}

export const TabContent = ({ children, id, ...rest }: TabContentProps) => <Box {...rest}>{children}</Box>
