import { CheckboxGroup, CheckboxGroupProps, Spacing } from '@design-system'

import { ReactElement, ReactNode } from 'react'

import { ScrollableListItem } from './elements/ScrollableListItem'
import * as Styled from './styles'

interface ScrollableListProps extends Omit<CheckboxGroupProps, 'children' | 'direction' | 'spacing'> {
  children?: ReactNode
}

type ScrollableListType = {
  Item: typeof ScrollableListItem
}

// To do: in future this component should be moved to 'components' directory
export const ScrollableList = ({
  children,
  ...checkboxGroupProps
}: ScrollableListProps): ReactElement<ScrollableListType> => (
  <Styled.ScrollableListWrapper>
    <Styled.ScrollableListContent>
      <CheckboxGroup {...checkboxGroupProps} direction="column" spacing={Spacing.XXXS}>
        {children}
      </CheckboxGroup>
    </Styled.ScrollableListContent>
  </Styled.ScrollableListWrapper>
)

ScrollableList.Item = ScrollableListItem
