import { css } from '@emotion/core'
import PrismicDOM from 'prismic-dom'
import React from 'react'
import { Box } from 'rebass'

type DocProp = {
  type: string
  uid: string
}

const linkResolver = (doc: DocProp): string => `/page/${doc.type}/${doc.uid}`

export const PrismicArticle = ({ content }: any) => {
  return (
    <Box
      css={css`
        width: 100%;
        * {
          width: 100%;
        }
      `}
      dangerouslySetInnerHTML={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __html: PrismicDOM.RichText.asHtml(content, linkResolver),
      }}
    />
  )
}
