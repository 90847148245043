import { Color } from '@design-system'

import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'

import * as Styled from './styles'

interface SearchLoupeInputProps {
  onChange: (query: string) => void
}

export const SearchLoupeInput = ({ onChange = () => null }: SearchLoupeInputProps) => {
  const [isActive, setIsActive] = useState(false)
  const [query, setQuery] = useState('')
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleLoupeClick = () => {
    setIsActive(!isActive)
    setQueryValue('')
  }

  const setQueryValue = (value: string) => {
    setQuery(value)
    onChange(value)
  }

  const close = () => {
    setIsActive(false)
    setQueryValue('')
  }

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setQueryValue(value)
  }

  const onClickOutside = () => {
    if (query) {
      return
    }

    close()
  }

  useEffect(() => {
    if (isActive && wrapperRef) {
      const input = wrapperRef?.current?.querySelector('input')
      input && input.focus()
    }
  }, [isActive])

  useClickAway(wrapperRef, onClickOutside)

  return (
    <Styled.LoupeInputWrapper ref={wrapperRef}>
      <Styled.IconMagnifier icon="magnifyingGlass" color={Color.Gray90} onClick={handleLoupeClick} />
      <Styled.SearchInput onChange={handleQueryChange} value={query} isActive={isActive} />
      {query && <Styled.IconX icon="xSign" onClick={close} />}
    </Styled.LoupeInputWrapper>
  )
}
