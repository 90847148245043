import { ReactNode } from 'react'

import { NavItem } from '../../NavList'

export const getDisplayValue = <T>(
  item?: NavItem<T>,
  children?: ReactNode,
  customDisplayValueFn?: (item: NavItem<T>) => ReactNode,
): ReactNode | undefined => {
  if (item && customDisplayValueFn) {
    return customDisplayValueFn(item)
  }

  if (item?.children) {
    return item.children
  }

  if (children) {
    return children
  }

  return undefined
}
