import colors from '../colors'

export default {
  subMenu: {
    background: colors.menuActive,
  },
  subMenuItem: {
    color: colors.white,
    '&:hover, &.active': {
      '> div': {
        background: colors.primaryDarkend,
      },
    },
  },
}
