import isFinite from 'lodash/isFinite'

/*
  Checks if GLN number is from abroad.
  Based on: https://www.gs1.dk/om-gs1/overblik-over-gs1-standarder/virksomhedspraefiks-landekoder
*/
export const isForeignGln = (gln: string) => {
  const glnPrefix = gln.slice(0, 3)
  const glnPrefixNumber = parseInt(glnPrefix)

  if (!isFinite(glnPrefixNumber)) {
    return false
  }

  return glnPrefixNumber < 570 || glnPrefixNumber > 579
}
