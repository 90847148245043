import orderBy from 'lodash/orderBy'

import { Side } from '../../../enums/side'
import { SuggestionFilters } from '../../bankReconciliation/elements/ReconciliationSuggestionFilters'
import { BankLine, ExtendedBankLine } from '../../bankReconciliation/types'

export const orderAndDecorateSuggestions = (
  suggestions: BankLine[],
  suggestionFilters: SuggestionFilters,
  lookupAmount: number,
  side?: Side,
): ExtendedBankLine[] => {
  const extendedSuggestions = suggestions.reduce((result: ExtendedBankLine[], suggestion) => {
    if (side && suggestion.side !== side) {
      return result
    }

    const signedAmount = suggestion.side === Side.Credit ? -suggestion.amount : suggestion.amount
    const amountDifference = Math.abs(lookupAmount + signedAmount)

    result.push({
      ...suggestion,
      signedAmount,
      amountDifference,
    })

    return result
  }, [])

  return orderBy(extendedSuggestions, suggestionFilters.orderBy, suggestionFilters.direction)
}
