import { Color } from '@design-system'

import { TFunction } from 'i18next'
import { ReactNode } from 'react'

import { UpsellVariantIds } from '../enums/upsellVariantIds'

interface UpsellAsset {
  alt: string
  src: string
}

type UpsellBackgroundColor = Color.Lavender | Color.Purple20 | Color.Green10 | Color.White

interface UpsellVariant {
  backgroundColor: UpsellBackgroundColor
  body: ReactNode
  buttonLabel: ReactNode
  heading: ReactNode
  id: UpsellVariantIds
  img: UpsellAsset
}

export const getUpsellVariants = (t: TFunction): UpsellVariant[] => [
  {
    backgroundColor: Color.Lavender,
    body: t('dashboard_widget.upsell.integrations.body'),
    buttonLabel: t('dashboard_widget.upsell.integrations.button.label'),
    heading: t('dashboard_widget.upsell.integrations.title'),
    id: UpsellVariantIds.Integrations,
    img: { src: '/upsell-widget/multitasking.svg', alt: 'Dashboard widget - integrations' },
  },
  {
    backgroundColor: Color.Lavender,
    body: t('dashboard_widget.upsell.bankintegration.body'),
    buttonLabel: t('dashboard_widget.upsell.bankintegration.button.label'),
    heading: t('dashboard_widget.upsell.bankintegration.title'),
    id: UpsellVariantIds.BankIntegration,
    img: { src: '/upsell-widget/puzzle-piece.svg', alt: 'Dashboard widget - bank integration' },
  },
  {
    backgroundColor: Color.Green10,
    body: t('dashboard_widget.upsell.bookkeeping_support.body'),
    buttonLabel: t('dashboard_widget.upsell.bookkeeping_support.button.label'),
    heading: t('dashboard_widget.upsell.bookkeeping_support.title'),
    id: UpsellVariantIds.BookkeepingSupport,
    img: { src: '/upsell-widget/phone.svg', alt: 'Dashboard widget - bookkeeping support' },
  },
  {
    backgroundColor: Color.White,
    body: t('dashboard_widget.upsell.invoice_designer.body'),
    buttonLabel: t('dashboard_widget.upsell.invoice_designer.button.label'),
    heading: t('dashboard_widget.upsell.invoice_designer.title'),
    id: UpsellVariantIds.InvoiceDesigner,
    img: { src: '/upsell-widget/pencil.svg', alt: 'Dashboard widget - invoice designer' },
  },
  {
    backgroundColor: Color.Purple20,
    body: t('dashboard_widget.upsell.vouchers.body'),
    buttonLabel: t('dashboard_widget.upsell.vouchers.button.label'),
    heading: t('dashboard_widget.upsell.vouchers.title'),
    id: UpsellVariantIds.Vouchers,
    img: { src: '/upsell-widget/cloud.svg', alt: 'Dashboard widget - vouchers' },
  },
  {
    backgroundColor: Color.Lavender,
    body: t('dashboard_widget.upsell.mobile_pay.body'),
    buttonLabel: t('dashboard_widget.upsell.mobile_pay.button.label'),
    heading: t('dashboard_widget.upsell.mobile_pay.title'),
    id: UpsellVariantIds.MobilePay,
    img: { src: '/upsell-widget/credit-card.svg', alt: 'Dashboard widget - mobile pay' },
  },
  {
    backgroundColor: Color.Green10,
    body: t('dashboard_widget.upsell.salary.body'),
    buttonLabel: t('dashboard_widget.upsell.salary.button.label'),
    heading: t('dashboard_widget.upsell.salary.title'),
    id: UpsellVariantIds.Salary,
    img: { src: '/upsell-widget/thumbs-up.svg', alt: 'Dashboard widget - salary' },
  },
  {
    backgroundColor: Color.White,
    body: t('dashboard_widget.upsell.user_invite.body'),
    buttonLabel: t('dashboard_widget.upsell.user_invite.button.label'),
    heading: t('dashboard_widget.upsell.user_invite.title'),
    id: UpsellVariantIds.UserInvite,
    img: { src: '/upsell-widget/profile.svg', alt: 'Dashboard widget - user invite' },
  },
  {
    backgroundColor: Color.Purple20,
    body: t('dashboard_widget.upsell.bookkeeping_law.body'),
    buttonLabel: t('dashboard_widget.upsell.bookkeeping_law.button.label'),
    heading: t('dashboard_widget.upsell.bookkeeping_law.title'),
    id: UpsellVariantIds.BookkeepingLaw,
    img: { src: '/upsell-widget/checklist.svg', alt: 'Dashboard widget - bookkeeping law' },
  },
]
