import { da, enUS } from 'date-fns/locale'
import upperFirst from 'lodash/upperFirst'

export const getMonthNames = (locale?: string) => {
  const localeObject = locale === 'da' ? da : enUS
  const months = []

  for (let i = 0; i < 12; i++) {
    months.push(upperFirst(localeObject.localize?.month(i)))
  }

  return months
}
