import React, { ReactElement, ReactNode } from 'react'

import { Text } from '../../../Text'
import * as Styled from './styles'

export interface ModuleSectionProps {
  title?: ReactNode
  sideActions?: ReactNode
  children: ReactNode
}
export const ModuleSection = ({ children, sideActions, title }: ModuleSectionProps): ReactElement => (
  <Styled.ModuleSectionWrapper>
    {(title || sideActions) && (
      <Styled.Header>
        {title && <Text variant="h3">{title}</Text>}
        {sideActions && <Styled.SideActions>{sideActions}</Styled.SideActions>}
      </Styled.Header>
    )}
    {children}
  </Styled.ModuleSectionWrapper>
)
