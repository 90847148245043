import Cookies from 'js-cookie'
import { useCallback } from 'react'

import { CookieKeys } from '../enums/cookieKeys'

export const useZervantNavigation = () => {
  const externalToken = Cookies.get(CookieKeys.BillyApiExternalToken)

  const redirectToZervantLogin = useCallback(() => {
    window.location.href = `${window.ENV.ZERVANT_URL}/login/login?zervant-return-access=${externalToken}`
  }, [externalToken])

  return {
    redirectToZervantLogin,
  }
}
