import { notify, useModal } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useCreateContact } from '@views/contacts/hooks/useCreateContact'
import { Contact } from '@views/contacts/types/contact'

import { SUBMIT_SHORTCUT } from '../../../../constants/shortcuts'
import { ModalId } from '../../../../enums/modalId'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { useForm } from '../../../../hooks'
import { ProcessingOverlay } from '../../../ProcessingOverlay'
import { ModalType } from '../enums/modalType'
import { ContactForm, contactFormDefaultValues, getValidationSchema } from '../utils/formData'
import { getContactFormDefaultValues } from '../utils/getContactFormDefaultValues'
import { getCreateContactPayload } from '../utils/getCreateContactPayload'

interface ContextState {
  modalType: ModalType
  isSaving: boolean
  setModalType: (type: ModalType) => void
  submitForm: () => void
}

const ContactModalContext = createContext<ContextState | undefined>(undefined)

interface ContactModalContextProps {
  children: ReactNode
  modalType: ModalType
  defaultValues?: Partial<Contact>
  onContactCreate?: (contact: Contact) => void
}

export const ContactModalContextProvider = ({
  modalType: defaultModalType,
  defaultValues,
  children,
  onContactCreate,
}: ContactModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { close } = useModal(ModalId.CreateContactModal)
  const [modalType, setModalType] = useState(defaultModalType)
  const { create, isSaving } = useCreateContact({
    onSuccess: (data) => {
      close()
      onContactCreate?.(data.contacts[0])
    },
    onError: () => {
      notify({ id: NotificationKeys.CreateContact, message: t('error_saving'), variant: 'error' })
    },
  })

  const { Form, handleSubmit } = useForm({
    defaultValues: {
      ...contactFormDefaultValues,
      ...getContactFormDefaultValues(defaultValues),
    },
    validationSchema: useMemo(() => getValidationSchema(modalType, t), [modalType, t]),
  })

  const handleFormSubmit = useCallback(
    (values: ContactForm) => {
      const createContactPayload = getCreateContactPayload(values, modalType, organization?.id as string)
      create(createContactPayload)
    },
    [modalType, organization?.id, create],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleFormSubmit)()
  }, [handleSubmit, handleFormSubmit])

  // Shortcuts

  useHotkeys(SUBMIT_SHORTCUT, submitForm, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] }, [])

  return (
    <ContactModalContext.Provider
      value={{
        modalType,
        isSaving,
        setModalType,
        submitForm,
      }}
    >
      {isSaving && <ProcessingOverlay />}
      <Form>{children}</Form>
    </ContactModalContext.Provider>
  )
}

export const useContactModal = () => {
  const context = useContext(ContactModalContext)

  if (!context) {
    throw new Error('ContactModalContextProvider is missing in the module!')
  }

  return context
}
