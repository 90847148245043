import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'

import { store } from '@modules-deprecated'
import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { WidgetBankConnection } from '@views/dashboard/widgetBankConnection'

import { queryClient } from '../../config/queryClient'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

class WidgetBankConnectionWebComponent extends DOMModel {
  @byAttrVal organizationid: string
  @byAttrVal hasbankconnections: string
  @registerEvent('connectBankAccount') connectBankAccount: EmberEventFn
  @registerEvent('showSubscriptionComparison') showSubscriptionComparison: EmberEventFn
}

type WidgetBankConnectionProps = {
  organizationid: string
  hasbankconnections: 'true' | 'false'
  onConnectBankAccount: EmberEventFn
  onShowSubscriptionComparison: EmberEventFn
}

function WidgetBankConnectionModule({
  organizationid,
  onConnectBankAccount,
  onShowSubscriptionComparison,
  hasbankconnections,
}: WidgetBankConnectionProps): ReactElement {
  const isLoading = hasbankconnections === undefined
  const hasBankConnections = hasbankconnections === 'true' // only strings are passed from ember

  return (
    <QueryClientProvider client={queryClient}>
      <CurrentUserContextProvider>
        <UserOrganizationContextProvider organizationId={organizationid}>
          <ThemeProvider>
            <ReduxProvider store={store}>
              <WidgetBankConnection
                onConnectBankAccount={onConnectBankAccount}
                onShowSubscriptionComparison={onShowSubscriptionComparison}
                hasBankConnections={hasBankConnections}
                isLoading={isLoading}
              />
            </ReduxProvider>
          </ThemeProvider>
        </UserOrganizationContextProvider>
      </CurrentUserContextProvider>
    </QueryClientProvider>
  )
}

const WidgetBankConnectionCustomElement = createCustomElement(
  WidgetBankConnectionModule,
  WidgetBankConnectionWebComponent,
  'element',
)

customElements.get('widget-bank-connection-module') ||
  customElements.define('widget-bank-connection-module', WidgetBankConnectionCustomElement)

export default WidgetBankConnectionCustomElement
