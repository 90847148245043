import { TFunction } from 'i18next'

import { QuoteType } from '../../../enums/quoteType'
import { quotesTypeToTitleKey } from '../constants/quotesTypeToTableKey'

export const getQuotesType = (type: QuoteType, t: TFunction): string => {
  const tKey = quotesTypeToTitleKey[type]

  return t(tKey)
}
