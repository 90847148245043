import { ButtonDropdown, NavItem } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'

enum ContactsActionId {
  Import = 'import',
  Export = 'export',
}

export const ContactsActions = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()

  const moreButtonItems: NavItem<EmberRoute>[] = useMemo(
    () => [
      {
        id: ContactsActionId.Export,
        children: t('contacts.header.actions.export_contacts'),
        value: EmberRoute.Exports,
      },
      {
        id: ContactsActionId.Import,
        children: t('contacts.header.actions.import_contacts'),
        value: EmberRoute.ContactsImport,
      },
    ],
    [t],
  )

  const handleMoreSelect = useCallback(
    (_: string, value: EmberRoute) => {
      navigate(value)
    },
    [navigate],
  )

  return (
    <ButtonDropdown items={moreButtonItems} onSelect={handleMoreSelect} variant="secondary">
      {t('more')}
    </ButtonDropdown>
  )
}
