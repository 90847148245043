import { DropdownProps } from '@design-system'

import React, { ReactElement, ReactNode, useCallback } from 'react'

import { PeriodMode } from '../../../enums/periodMode'
import { PeriodValue } from '../../../types/periodValue'
import { PeriodSelectContextProvider } from './contexts/periodSelectContext'
import { PeriodSelectContent } from './elements/PeriodSelectContent'

export interface PeriodSelectProps extends Pick<DropdownProps, 'placement' | 'onOpen' | 'onClose'> {
  defaultButtonLabel?: ReactNode
  defaultPeriodValue?: PeriodValue
  disabledPeriodModes?: PeriodMode[]
  maxDate?: Date
  minDate?: Date
  onChange?: (periodValue?: PeriodValue) => void
  periodValue?: PeriodValue
  fullWidth?: boolean
}

export const PeriodSelect = ({
  defaultButtonLabel,
  defaultPeriodValue,
  disabledPeriodModes,
  fullWidth,
  maxDate,
  minDate,
  onChange,
  onClose,
  onOpen,
  periodValue,
  placement = 'bottom-start',
}: PeriodSelectProps): ReactElement => {
  const handleOpen = useCallback(() => {
    onOpen?.()
  }, [onOpen])

  const handleClose = useCallback(() => {
    onClose?.()
  }, [onClose])

  const handleSave = useCallback(
    (periodValue?: PeriodValue) => {
      onChange?.(periodValue)
    },
    [onChange],
  )

  return (
    <PeriodSelectContextProvider
      defaultButtonLabel={defaultButtonLabel}
      defaultPeriodValueSaved={defaultPeriodValue}
      disabledPeriodModes={disabledPeriodModes}
      maxDate={maxDate}
      minDate={minDate}
      periodValueSaved={periodValue}
    >
      <PeriodSelectContent
        fullWidth={fullWidth}
        onClose={handleClose}
        onOpen={handleOpen}
        onSave={handleSave}
        placement={placement}
      />
    </PeriodSelectContextProvider>
  )
}
