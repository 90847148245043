import { FetchInvoiceResponseData } from '../../../query-api'
import { Invoice } from '../../../types/invoice'

interface InvoiceQueryResponseData {
  data: FetchInvoiceResponseData | undefined
}

export const getInvoiceQueryData = (invoiceId: string, invoices: Invoice[]): InvoiceQueryResponseData | undefined => {
  const invoice = invoices.find(({ id }) => id.toString() === invoiceId)

  return invoice ? { data: invoice } : undefined
}
