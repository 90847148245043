import {
  useUpdateOrganizationSettings,
  useUserOrganization,
  useUserOrganizationSettings,
} from '@modules-deprecated/app/organization'

import { formatDate } from '../../../utils'
import { FINANCING_DISMISSED_DATE } from '../constants/financingSettingsKeys'

interface AgerasFinancingProps {
  cancelDismissal: () => void
  dismiss: (forever: boolean) => void
  isDismissed: boolean
  isLoading: boolean
  isUpdating: boolean
}

export const useAgerasFinancing = (): AgerasFinancingProps => {
  const { organization } = useUserOrganization()
  const { organizationSettings, isLoading: isOrganizationSettingsLoading } = useUserOrganizationSettings()
  const isHiddenFromDate = organizationSettings?.financingDismissedDate

  const { update: updateOrganizationSettings, isProcessing: isUpdatingOrganizationSettings } =
    useUpdateOrganizationSettings()

  const dismiss = (forever = false) => {
    if (!organization?.id) {
      return
    }

    updateOrganizationSettings({
      key: FINANCING_DISMISSED_DATE,
      organizationId: organization.id,
      value: JSON.stringify({
        date: formatDate(new Date()),
        forever,
      }),
    })
  }

  const cancelDismissal = () => {
    if (!organization?.id) {
      return
    }

    updateOrganizationSettings({
      key: FINANCING_DISMISSED_DATE,
      organizationId: organization.id,
      value: '',
    })
  }

  return {
    cancelDismissal,
    dismiss,
    isDismissed: !!isHiddenFromDate,
    isLoading: isOrganizationSettingsLoading,
    isUpdating: isUpdatingOrganizationSettings,
  }
}
