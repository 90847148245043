import { Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import { PurchaseCardButton } from './elements/PurchaseCardButton'
import { PurchaseCardFeatures } from './elements/PurchaseCardFeatures'
import { PurchaseCardHeader } from './elements/PurchaseCardHeader'
import { PurchaseCardPriceSection } from './elements/PurchaseCardPriceSection'
import * as Styled from './styles'
import { PurchaseCardHighlight } from './types'
import { variantStyles } from './utils/variantStyles'

export interface PurchaseCardProps {
  children: ReactNode
  highlight?: PurchaseCardHighlight
}

export const PurchaseCard = ({ children, highlight }: PurchaseCardProps): ReactElement => (
  <Styled.PurchaseCardWrapper variant={highlight?.variant}>
    {highlight?.text && highlight?.variant && (
      <Styled.HighlightedTextWrapper>
        <Text variant="h4" alignment="center" color={variantStyles[highlight.variant].textColor} uppercase>
          {highlight.text}
        </Text>
      </Styled.HighlightedTextWrapper>
    )}
    <Styled.PurchaseCardInnerWrapper>{children}</Styled.PurchaseCardInnerWrapper>
  </Styled.PurchaseCardWrapper>
)

PurchaseCard.Header = PurchaseCardHeader
PurchaseCard.Button = PurchaseCardButton
PurchaseCard.PriceSection = PurchaseCardPriceSection
PurchaseCard.Features = PurchaseCardFeatures
