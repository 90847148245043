import { object, SchemaOf, string } from 'yup'

export interface AccessTokenForm {
  name: string
  description: string | undefined
}

export const defaultValues: AccessTokenForm = {
  name: '',
  description: '',
}

export type AccessTokenFormSchema = SchemaOf<AccessTokenForm>

export const getValidationSchema = (): AccessTokenFormSchema =>
  object({
    name: string().required(),
    description: string(),
  })
