import React, { memo, ReactElement } from 'react'

import { useAccount } from '../../../hooks/useAccount'

interface AccountFormatterProps {
  accountId: string
}

export const AccountFormatter = memo(({ accountId }: AccountFormatterProps): ReactElement | null => {
  const { account } = useAccount(accountId)

  if (!account) {
    return null
  }

  return (
    <>
      {account.accountNo} - {account.name}
    </>
  )
})
