import { useMutation } from 'react-query'

import { createUmbrellaRole, UmbrellaRolePayload } from '@modules-deprecated/app/umbrellas/query-api'

import { APIError } from '../../../../../utils'

export interface UseCreateUmbrellaRoleProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface CreateUmbrellaRolePayload {
  payload: UmbrellaRolePayload
  umbrellaId: string
}

export const useCreateUmbrellaRole = (props?: UseCreateUmbrellaRoleProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: createRole, isLoading: isProcessing } = useMutation(
    ({ umbrellaId, payload }: CreateUmbrellaRolePayload) => createUmbrellaRole(umbrellaId, payload),
    {
      onError,
      onSuccess,
    },
  )

  return {
    createRole,
    isProcessing,
  }
}
