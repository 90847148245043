import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'

export const StepNavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;

  & + & {
    margin-top: -${Spacing.S};
  }
`
