import qs from 'qs'

import { SortDirection } from '../../enums/sortDirection'
import { Metable } from '../../types/metable'
import { APIResponse, getRequest, patchRequest, postRequest } from '../../utils'
import { Contact } from './types/contact'

export enum ContactsSortProperty {
  Name = 'name',
  CreatedTime = 'createdTime',
}

export interface FetchContactsOptions {
  isArchived?: boolean
  isCustomer?: boolean
  isSupplier?: boolean
  organizationId?: string
  page?: number
  pageSize?: number
  q?: string
  sortDirection?: SortDirection
  sortProperty?: ContactsSortProperty
}

export interface FetchContactsResponseData extends Metable {
  contacts: Contact[]
}

export const fetchContactsAndContactPersons = ({
  isArchived,
  isCustomer,
  isSupplier,
  organizationId,
  page,
  pageSize,
  q,
  sortDirection,
  sortProperty,
}: FetchContactsOptions): Promise<FetchContactsResponseData> => {
  const queryParams = qs.stringify({
    organizationId,
    ...(page ? { page } : {}),
    ...(pageSize ? { pageSize } : {}),
    ...(q ? { q } : {}),
    ...(sortDirection ? { sortDirection } : {}),
    ...(sortProperty ? { sortProperty } : {}),
    ...(!isArchived ? { isArchived } : {}),
    ...(isCustomer ? { isCustomer } : {}),
    ...(isSupplier ? { isSupplier } : {}),
  })

  return getRequest(`/v2/contacts?include=contact.contactPersons:embed&${queryParams}`)
}

export const updateContact = (contact: Partial<Contact>): Promise<APIResponse<Contact>> =>
  patchRequest('/v2/contacts', { contacts: [contact] })

export const createContact = (contact: Partial<Contact>): Promise<APIResponse<Contact>> =>
  postRequest('/v2/contacts', { contact })
