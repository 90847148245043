import { Translate } from '@components'
import { InternalAccessor, Space, Table, TableColumn, Tag, Text } from '@design-system'

import pick from 'lodash/pick'
import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TaxMetaField } from '../../../../types/taxMetaField'
import { useAdditionalFields } from '../../hooks/useAdditionalFields'
import { AdditionalFieldsTableHoverActions } from './elements/AdditionalFieldsTableHoverActions'
import { AdditionalFieldsTableStaticActions } from './elements/AdditionalFieldsTableStaticActions'

interface AdditionalFieldsTableProps {
  onEditAdditionalFieldModal: (additionalField: TaxMetaField) => void
  onAdditionalFieldDelete: (accountId: string) => void
}

interface AdditionalFieldsTableRow
  extends Pick<TaxMetaField, 'id' | 'name' | 'description' | 'priority' | 'isPredefined'> {
  [InternalAccessor.HoverActions]: boolean
  [InternalAccessor.StaticActions]: boolean
}

export const AdditionalFieldsTable = ({
  onEditAdditionalFieldModal,
  onAdditionalFieldDelete,
}: AdditionalFieldsTableProps): ReactElement => {
  const { t } = useTranslation()
  const { additionalFields, isLoading } = useAdditionalFields()

  const handleOnEditAdditionalFieldClick = useCallback(
    (fieldId: string) => {
      const additionalField = additionalFields.find((additionalFields) => additionalFields.id === fieldId)

      if (additionalField) {
        onEditAdditionalFieldModal(additionalField)
      }
    },
    [onEditAdditionalFieldModal, additionalFields],
  )

  const tableData: AdditionalFieldsTableRow[] = useMemo(() => {
    return additionalFields.map((field) => ({
      ...pick(field, ['id', 'name', 'description', 'priority', 'isPredefined']),
      [InternalAccessor.HoverActions]: true,
      [InternalAccessor.StaticActions]: true,
    }))
  }, [additionalFields])

  const columns: TableColumn<AdditionalFieldsTableRow>[] = useMemo(
    () => [
      {
        Header: <Translate value={'settings.organization.tax_return.columns.name'} />,
        accessor: 'name',
        size: 'xl',
        Cell: ({ value, row, data }) => {
          const { isPredefined, description } = data[row.id] as AdditionalFieldsTableRow

          return (
            <>
              {value} {isPredefined && <Tag closable={false}>{t('settings.organization.tax_return.predefined')}</Tag>}
              <Text colorVariant="secondary" variant="micro">
                {description}
              </Text>
            </>
          )
        },
      },
      {
        Header: <Translate value={'settings.organization.tax_return.columns.priority'} />,
        accessor: 'priority',
        alignment: 'right',
        size: 'xxs',
      },
      {
        Header: '',
        accessor: InternalAccessor.HoverActions,
        Cell: ({ row, data }) => {
          const { id, isPredefined } = data[row.id] as AdditionalFieldsTableRow
          if (isPredefined) {
            return <Text variant="small">{data[row.id].priority}</Text>
          }

          return <AdditionalFieldsTableHoverActions onEdit={handleOnEditAdditionalFieldClick} id={id} />
        },
      },
      {
        Header: '',
        accessor: InternalAccessor.StaticActions,
        Cell: ({ row, data }) => {
          const { id, isPredefined } = data[row.id] as AdditionalFieldsTableRow
          if (isPredefined) {
            return <Space size="xxl" horizontal={true}></Space>
          }

          return <AdditionalFieldsTableStaticActions onDelete={onAdditionalFieldDelete} id={id} />
        },
      },
    ],
    [handleOnEditAdditionalFieldClick, onAdditionalFieldDelete, t],
  )

  return <Table columns={columns} data={tableData} isLoading={isLoading} skeletonItemsPerPage={5} />
}
