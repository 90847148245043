import { all, call, put, takeLatest } from 'redux-saga/effects'

import { getRequest } from '../../../utils'
import { keyBy } from '../../../utils/object'
import { citiesReceived, CitiesRequestedAction, zipCodesReceived, ZipCodesRequestedAction } from './action-creators'
import { CITIES_REQUESTED, ZIP_CODES_REQUESTED } from './actions'
import { ZipCode } from './types'

function* fetchZipCodes({ payload }: ZipCodesRequestedAction) {
  const res: { cities: any; meta: any; zipcodes: ZipCode[] } = yield call(
    getRequest,
    '/v2/zipcodes' +
      `?include=zipcode.city%2Czipcode.state&countryId=${payload.countryId}` +
      (payload.filters.text ? `&q=${payload.filters.text}` : '') +
      `&stateId=&sortProperty=zipcode&sortDirection=ASC&pageSize=100&offset=${payload.page.offset}`,
  )

  const citiesMap = keyBy(res.cities || [], 'id')
  yield put(
    zipCodesReceived({
      page: {
        offset: payload.page.offset,
        total: res.meta.paging.total,
      },
      zipCodes: res.zipcodes.map((zipCode: ZipCode) => ({
        ...zipCode,
        city: citiesMap[zipCode.cityId],
      })),
    }),
  )
}

function* fetchCities({ payload }: CitiesRequestedAction) {
  const res: { meta: any; cities: any } = yield call(
    getRequest,
    '/v2/cities' +
      `?include=city.state&countryId=${payload.countryId}` +
      (payload.filters.text ? `&q=${payload.filters.text}` : '') +
      `&sortProperty=name&sortDirection=ASC&pageSize=100&offset=${payload.page.offset}`,
  )

  yield put(
    citiesReceived({
      page: {
        offset: payload.page.offset,
        total: res.meta.paging.total,
      },
      cities: res.cities,
    }),
  )
}

export default function* (): any {
  yield all([yield takeLatest(CITIES_REQUESTED, fetchCities), yield takeLatest(ZIP_CODES_REQUESTED, fetchZipCodes)])
}
