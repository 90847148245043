import { Button, ButtonsGroup, Flex, Modal, SectionMessage, Space, Spacing, Text, useModal } from '@design-system'

import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { isSettingsModulePath } from '@views/settings/utils/isSettingsModulePath'
import { routeToInternalSettings } from '@views/settings/utils/routeToInternalSettings'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { ModalId } from '../../../../enums/modalId'
import { usePeriodicLockReminder } from './hooks/usePeriodicLockReminder'
import * as Styled from './styles'

export const PeriodicLockReminderModal = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { shouldShowReminder, markAsViewed } = usePeriodicLockReminder()
  const { close, open } = useModal(ModalId.PeriodicLockReminderModal, {
    onClose: () => {
      markAsViewed()
    },
  })

  useEffect(() => {
    if (shouldShowReminder) {
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowReminder])

  const handleSubmitButtonClick = useCallback(() => {
    if (isSettingsModulePath()) {
      routeToInternalSettings(EmberRoute.SettingsAccounting)
    } else {
      navigate(EmberRoute.SettingsAccounting)
    }

    close()
  }, [close, navigate])

  const handleCancelButtonClick = useCallback(() => {
    close()
  }, [close])

  return (
    <Modal id={ModalId.PeriodicLockReminderModal} size="s">
      <Modal.Header title={t('periodic_lock_reminder_modal.title')} />

      <Modal.Body>
        <Flex gap={Spacing.XL}>
          <Styled.ContentWrapper>
            <Text variant="large">{t('periodic_lock_reminder_modal.body1')}</Text>
            <Space size="xl" />
            <Text weight="bold">{t('periodic_lock_reminder_modal.body2')}</Text>
          </Styled.ContentWrapper>

          <Styled.FigureWrapper>
            <Styled.LockImage />
          </Styled.FigureWrapper>
        </Flex>
        <Space size="xl" />
        <SectionMessage variant="warning" icon="iSignCircle">
          {t('periodic_lock_reminder_modal.callout')}
        </SectionMessage>
      </Modal.Body>

      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={handleCancelButtonClick}>
            {t('cancel')}
          </Button>
          <Button onClick={handleSubmitButtonClick}>{t('periodic_lock_reminder_modal.submit_button')}</Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
