import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../../../../../enums/queryKeys'
import { fetchInvoiceTemplates } from '../query-api'

export const useInvoiceTemplates = () => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const organizationId = organization?.id

  const {
    data: invoiceTemplatesData,
    isLoading: isInvoiceTemplatesLoading,
    ...rest
  } = useQuery([QueryKeys.InvoiceTemplates, organizationId], () => fetchInvoiceTemplates(organizationId as string), {
    enabled: !!organizationId,
  })

  const isLoading = isOrganizationLoading || isInvoiceTemplatesLoading

  return { invoiceTemplates: invoiceTemplatesData?.data, isLoading, ...rest }
}
