import { TableBadgeLabel } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { quotesStateToBadgeVariant } from '../../constants/quotesStateToBadgeVariant'
import { useQuotesListFilters } from '../../hooks/useQuotesListFilters'
import { useQuotesSummary } from '../../hooks/useQuotesSummary'
import { getQuotesTitle } from '../../utils/getQuotesTitle'

export const QuotesTitle = (): ReactElement => {
  const { t } = useTranslation()
  const { quotesCountByState, isLoading } = useQuotesSummary()
  const [{ state }] = useQuotesListFilters()

  const totalAmount = quotesCountByState[state]
  const title = getQuotesTitle(state, totalAmount, t)
  const variant = quotesStateToBadgeVariant[state]

  return (
    <TableBadgeLabel amount={totalAmount} isLoading={isLoading} variant={variant}>
      {title}
    </TableBadgeLabel>
  )
}
