import { NavItem, TableStaticActions } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface VatAccountsTableStaticActionsProps {
  onDelete: (rulesetId: string) => void
  id: string
}

enum VatAccountsStaticActionType {
  Delete = 'delete',
  Edit = 'edit',
}

export const VatAccountsTableStaticActions = ({
  onDelete,
  id: itemId,
}: VatAccountsTableStaticActionsProps): ReactElement => {
  const { t } = useTranslation()
  const staticActionsItems: NavItem<VatAccountsStaticActionType>[] = useMemo(
    () => [
      {
        id: 'delete',
        children: t('delete'),
        value: VatAccountsStaticActionType.Delete,
      },
    ],
    [t],
  )

  const handleStaticActionSelect = useCallback(
    (id: string, value: VatAccountsStaticActionType) => {
      switch (value) {
        case 'delete': {
          onDelete(itemId)
          break
        }
      }
    },
    [onDelete, itemId],
  )

  return <TableStaticActions items={staticActionsItems} onSelect={handleStaticActionSelect} />
}
