import { Button, ButtonProps } from '@design-system'

import React, { forwardRef, ReactElement, Ref } from 'react'

export type PurchaseCardButtonProps = ButtonProps

export const PurchaseCardButton = forwardRef(
  ({ children, ...props }: PurchaseCardButtonProps, forwardedRef: Ref<HTMLButtonElement>): ReactElement => (
    <Button {...props} ref={forwardedRef} size="xxl" fullWidth>
      {children}
    </Button>
  ),
)
