import React, { memo, ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface TableEmptyViewProps {
  emptyRowInfo?: ReactNode
}

export const TableEmptyView = memo(({ emptyRowInfo }: TableEmptyViewProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.TableEmptyView id="empty-row">
      <Styled.TableEmptyViewContent>{emptyRowInfo || t('table.empty_message')}</Styled.TableEmptyViewContent>
    </Styled.TableEmptyView>
  )
})
