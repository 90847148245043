import { Badge, TableCellContent } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface BillDescriptionProps {
  children: ReactNode
  isCreditNote?: boolean
  title?: string
}

export const BillDescription = ({ children, isCreditNote, title }: BillDescriptionProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.BillDescriptionWrapper>
      {isCreditNote && (
        <Styled.BadgeWrapper title={t('bills.table.description.credit_note')}>
          <Badge variant="warning">{t('bills.table.description.credit_note_abbreviation')}</Badge>
        </Styled.BadgeWrapper>
      )}
      <TableCellContent title={title} truncate>
        {children}
      </TableCellContent>
    </Styled.BillDescriptionWrapper>
  )
}
