import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconCheck = ({ color = 'inherit', width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-check')} {...rest}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16.49 12.19">
      <polygon
        fill={coloring(color)}
        points="16.49 1.46 6.57 10.83 5.11 12.19 0 6.73 1.47 5.37 5.2 9.36 15.11 0 16.49 1.46"
      />
    </svg>
  </Box>
)
