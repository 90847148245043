import { NavItem, NavItemAccessor } from '@design-system'

import { TFunction } from 'i18next'

import { BookkeepingTag } from '../types/bookkeepingTag'
import { BookkeepingTagNavItemValue } from '../types/bookkeepingTagNavItemValue'

const getSubItems = (bookkeepingTag: BookkeepingTag): NavItem<BookkeepingTagNavItemValue>[] => {
  const accessorGroupId = `subitem-${bookkeepingTag.id.toString()}`

  const subItems: NavItem<BookkeepingTagNavItemValue>[] = [
    {
      id: accessorGroupId,
      accessor: NavItemAccessor.Header,
      children: bookkeepingTag.question.text,
      value: {} as BookkeepingTagNavItemValue,
    },
  ]

  for (const response of bookkeepingTag.question.responses) {
    subItems.push({
      id: response.option,
      accessorGroupId,
      children: response.option,
      value: {
        tag: bookkeepingTag,
        response,
      },
    })
  }

  return subItems
}

export const getItems = (bookkeepingTags: BookkeepingTag[], t: TFunction): NavItem<BookkeepingTagNavItemValue>[] => {
  const HEADER_ID = 'header'
  const items: NavItem<BookkeepingTagNavItemValue>[] = []

  if (bookkeepingTags.length > 0) {
    items.push({
      id: HEADER_ID,
      accessor: NavItemAccessor.Header,
      children: t('select.bookkeeping_hero.suggested_category', { count: bookkeepingTags.length }),
      value: {} as BookkeepingTagNavItemValue,
    })
  }

  for (const bookkeepingTag of bookkeepingTags) {
    items.push({
      id: bookkeepingTag.id.toString(),
      accessorGroupId: HEADER_ID,
      children: bookkeepingTag.name,
      value: {} as BookkeepingTagNavItemValue,
      subItems: getSubItems(bookkeepingTag),
    })
  }

  return items
}
