export enum IntegrationId {
  AnnualReportByAccountant = 'annual_report_by_accountant',
  AnnualReportDIY = 'annual_report_diy',
  DebtCollection = 'debt_collection',
  FindBookkeeper = 'find_bookkeeper',
  GetLoan = 'get_loan',
  InvoiceFinancing = 'invoice_financing',
  Lunar = 'lunar',
  Mobilepay = 'mobilepay',
  Momsintegration = 'momsintegration',
  Salary = 'salary',
  Stripe = 'stripe',
}
