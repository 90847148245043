import { Color, Spacing, Switch as SwitchComponent, Text } from '@design-system'

import styled from '@emotion/styled'

export const SummarySectionWrapper = styled.div`
  margin-top: -${Spacing.XXXL};
  padding-top: ${Spacing.XXXL};
  position: sticky;
  top: 0;
  align-self: flex-start;
`

export const Title = styled(Text)`
  margin-bottom: ${Spacing.XL};
`

export const ContentWrapper = styled.div`
  padding: ${Spacing.XXL};
  border: 1px solid ${Color.Gray40};
`

export const Content = styled(Text)`
  margin-bottom: -${Spacing.S};
`

export const Switch = styled(SwitchComponent)`
  margin: ${Spacing.L} 0;
`
