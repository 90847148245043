import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconClose = ({
  color = 'inherit',
  width = '20px',
  height = '20px',
  strokeWidth = '1px',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-close')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
      <polygon
        stroke={coloring(color)}
        points="22,1.467 20.533,0 11,9.533 1.467,0 0,1.467 9.533,11 0,20.533 1.467,22 11,12.467 20.533,22 22,20.533 12.467,11 "
        style={{ strokeWidth }}
      ></polygon>
    </svg>
  </Box>
)
