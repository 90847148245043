import { Color } from '../../../enums/color'
import { IconName } from '../../Icon'
import { NotificationVariant } from '../types/variant'

interface NotificationStyles {
  color: Color
  bgColor: Color
  icon: IconName
  boxShadowColor: Color
}

export const variantStyles: Record<NotificationVariant, NotificationStyles> = {
  error: {
    color: Color.Red120,
    bgColor: Color.Red10,
    icon: 'exclamationMarkCircle',
    boxShadowColor: Color.Red,
  },
  warning: {
    color: Color.Yellow220,
    bgColor: Color.Yellow30,
    icon: 'exclamationMarkCircle',
    boxShadowColor: Color.Yellow,
  },
  info: {
    color: Color.Purple110,
    bgColor: Color.Lavender,
    icon: 'iSignCircle',
    boxShadowColor: Color.Purple110,
  },
  success: {
    color: Color.Green140,
    bgColor: Color.Green20,
    icon: 'checkCircle',
    boxShadowColor: Color.Green,
  },
}
