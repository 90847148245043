import { css } from '@emotion/core'
import cc from 'classcat'
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Flex, FlexProps } from 'rebass'

import { reactClass } from '../../../utils'

export type PlaceholderProps = FlexProps & {
  id: string
  groupable?: boolean
  index: number
  isDragging?: boolean
}

const groupableStyle = `
  &:not(:first-of-type) {
    margin-top: -1px;
    border-top-style: dashed;
  }
`

export const Placeholder = ({ className, groupable, id, index, isDragging, ...rest }: PlaceholderProps) => (
  <Draggable draggableId={id} index={index}>
    {(provided) => (
      <Flex
        className={cc([className, reactClass('placeholder')])}
        css={css`
          height: 30px;
          border: 1px dashed #cfcfcf;
          border-radius: 4px;
          background-color: #f5fbff;
          ${groupable ? groupableStyle : 'margin-bottom: 10px;'}
        `}
        ref={provided.innerRef}
        {...rest}
      />
    )}
  </Draggable>
)
