import { Spacing } from '@design-system'

import styled from '@emotion/styled'

import { ReceiptContentWrapper, ReceiptFooter, ReceiptImageWrapper, ReceiptWrapper } from '../Receipt/styles'

export const SkeletonReceiptWrapper = styled(ReceiptWrapper)`
  // Remove pseudo-element from ReceiptWrapper
  &:after {
    display: none;
  }
`

export const SkeletonReceiptImageWrapper = styled(ReceiptImageWrapper)``

export const SkeletonReceiptWrapperContent = styled(ReceiptContentWrapper)``

export const SkeletonReceiptFooter = styled(ReceiptFooter)`
  margin-top: ${Spacing.XL};
`
