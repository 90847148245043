import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { getDefaultTransition } from '../../../../utils/getDefaultTransition'
import { rwd } from '../../../../utils/rwd'
import { LIMIT_CANVAS_WIDTH_CLASS_NAME } from './constants/limitCanvasWidthClassName'

interface PdfReactWrapperProps {
  width?: string | number
  isError?: boolean
}

export const PdfReactWrapper = styled.div<PdfReactWrapperProps>`
  width: ${({ width = 0 }) => (typeof width === 'string' ? width : `${width}px`)};
  color: ${Color.Gray90};
  background-color: ${Color.White};

  .${LIMIT_CANVAS_WIDTH_CLASS_NAME} {
    canvas {
      max-width: 100%;
    }
  }

  ${({ isError }) =>
    isError &&
    `
      padding-bottom: 100%;
      position: relative;
      color: ${Color.Red};

      ${rwd.equalOrLessThan('large')} {
        padding-bottom: 45%;
      }
    `};
`

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const PdfZoomedWrapper = styled.figure`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  will-change: opacity;
  ${getDefaultTransition('opacity')};

  &:hover {
    opacity: 1;
    cursor: none;
  }
`

export const DocumentZoomedWrapper = styled.div`
  display: flex;
  position: absolute !important;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: ${Color.White};
  color: ${Color.Gray90};
  ${getDefaultTransition('opacity')};
`
