import { NavItem } from '../../NavList'
import { getNavItemsFlat } from './getNavItemsFlat'

export const findNavItem = <T>(items: Array<NavItem<T>>, itemId?: string) => {
  if (!items.length || !itemId) {
    return undefined
  }

  const itemsFlat = getNavItemsFlat(items)
  return itemsFlat.find((item) => item.id === itemId)
}
