import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { getShadow } from '../../../utils/getShadow'
import { Variant } from '../types'

export const getSuccessStyle = (variant: Variant): SerializedStyles =>
  ({
    primary: css`
      background-color: ${Color.ElectricGreen};
      color: ${Color.DeepSeaGreen};
      ${getShadow('raisedLight', { borderColor: Color.Green })};

      &:disabled {
        background-color: ${Color.Green5};
        color: ${Color.Gray50};
        box-shadow: none;
      }
      &:not(:disabled) {
        &:hover {
          background-color: ${Color.Green};
          color: ${Color.DeepSeaGreen};
          ${getShadow('raisedLight', { borderColor: Color.Green })};
        }
        &:active {
          background-color: ${Color.Green140};
          color: ${Color.White};
          ${getShadow('raisedLight')};
        }
      }
    `,
    secondary: css``,
    text: css``,
  })[variant]
