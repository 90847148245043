import { call, put, select, takeLatest } from 'redux-saga/effects'

import { getRequest } from '../../../utils'
import { countriesReceived, CountriesRequestedAction } from './action-creators'
import { COUNTRIES_REQUESTED } from './actions'

function* fetchCountries({ payload }: CountriesRequestedAction) {
  let countries: any[] = yield select((state) => state.app.countries.list)
  if (!countries?.length) {
    const res: { countries: any } = yield call(getRequest, '/v2/countries', { 'accept-language': payload.locale })
    countries = res.countries
  }

  yield put(countriesReceived({ countries }))
}

export default function* () {
  yield takeLatest(COUNTRIES_REQUESTED, fetchCountries)
}
