import { BookkeepingTagSuggestion, isBookkeepingTagValid, TagSuggestion } from '@components'
import { Space } from '@design-system'

import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BillFormFieldsChangeSource } from '../../../../../../enums/billFormFieldsChangeSource'
import { useSegment } from '../../../../../../hooks'
import { useFormContext, useWatch } from '../../../../../../hooks/useForm'
import { useDeepCompareMemoize } from '../../../../hooks/useDeepCompareMemoize'
import { useBillForm } from '../../contexts/billFormContext'
import { useBookkeepingHeroAutoComplete } from '../../hooks/useBookkeepingHeroAutoComplete'
import { BillFormSchema } from '../../utils/formData'

export const BillBookkeepingTagSuggestion = (): ReactElement | null => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<BillFormSchema>()
  const { autoCompleteWithBookkeepingHeroData } = useBookkeepingHeroAutoComplete()
  const bookkeepingTagSuggestions = useWatch({ control, name: 'bookkeepingTagSuggestions' })
  const { track } = useSegment()
  const { billFormView } = useBillForm()
  const memoizedBookkeepingTagSuggestions = useDeepCompareMemoize(bookkeepingTagSuggestions)

  const tags: TagSuggestion[] = useMemo(
    () =>
      memoizedBookkeepingTagSuggestions?.map((tagSuggestion) => ({
        tagId: tagSuggestion.tag?.id as number,
        tagName: tagSuggestion.tag?.name as string,
      })) || [],
    [memoizedBookkeepingTagSuggestions],
  )

  const handleTagClick = useCallback(
    (tag: TagSuggestion) => {
      track('xxx - expense - create expense - category selected', {
        billPage: billFormView,
        context: BillFormFieldsChangeSource.Bohr,
      })
      const firstBillLineIndex = 0
      const bookkeepingTag = bookkeepingTagSuggestions?.find(
        (bookkeepingTagSuggestion) => bookkeepingTagSuggestion.tag?.id === tag.tagId,
      )
      setValue(`billLines.${firstBillLineIndex}.bookkeepingTag`, bookkeepingTag)

      if (bookkeepingTag && isBookkeepingTagValid(bookkeepingTag)) {
        autoCompleteWithBookkeepingHeroData(bookkeepingTag, firstBillLineIndex, BillFormFieldsChangeSource.Bohr)
      }
    },
    [autoCompleteWithBookkeepingHeroData, billFormView, bookkeepingTagSuggestions, setValue, track],
  )

  useEffect(() => {
    if (tags?.length) {
      track('xxx - expense - create expense - categories suggested', {
        billPage: billFormView,
        context: BillFormFieldsChangeSource.Bohr,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billFormView, tags])

  if (!tags?.length) {
    return null
  }

  return (
    <>
      <BookkeepingTagSuggestion onClick={handleTagClick} tags={tags}>
        {t('bill.bookkeeping_suggestion.title')}
      </BookkeepingTagSuggestion>

      <Space size="s" />
    </>
  )
}
