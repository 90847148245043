import { Modal } from '@design-system'

import { ReactElement } from 'react'

import { useCreateOrEditUmbrellaRole } from '../../../../context/createOrEditUmbrellaRoleContext'
import { ModalForm } from './elements/ModalForm/ModalForm'

export const ModalBody = (): ReactElement => {
  const { Form } = useCreateOrEditUmbrellaRole()

  return (
    <Modal.Body>
      <Form>
        <ModalForm />
      </Form>
    </Modal.Body>
  )
}
