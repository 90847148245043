import { ButtonProtected } from '@components'
import { useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../enums/modalId'
import { Scope } from '../../../../../../../../enums/scope'
import { TrackingContext } from '../../../../../../../../enums/trackingContext'

export const BookkeepersSectionSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { open: openInviteBookkeeperModal } = useModal(ModalId.OrganizationSettingsInviteBookkeeper)

  const handleInviteBookkeeperButtonClick = useCallback(() => {
    openInviteBookkeeperModal()
  }, [openInviteBookkeeperModal])

  return (
    <ButtonProtected
      scopes={Scope.UserWriteAccountant}
      trackingContext={TrackingContext.OrganizationSettingsUser}
      icon="plusCircle"
      onClick={handleInviteBookkeeperButtonClick}
    >
      {t('settings.organization.users.invite_section.invite_bookkeeper')}
    </ButtonProtected>
  )
}
