import { Button, Checkbox, Input, InputPassword, Link, Space, Text } from '@design-system'

import React, { ReactElement, useLayoutEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SubmitLoginResponseData } from '../../../../query-api/login-query'
import { APIError } from '../../../../utils'
import { useSignupForm } from './hooks/useSignupForm'
import * as Styled from './styles'

interface SignUpFormProps {
  invitationEmail: string
  invitationToken: string
  title: string
  onError?: (error?: APIError) => void
  onSuccess?: (response: SubmitLoginResponseData) => void
}

export const SignupForm = ({
  invitationEmail,
  invitationToken,
  title,
  onSuccess,
  onError,
}: SignUpFormProps): ReactElement => {
  const { t } = useTranslation()
  const acceptTermsLabelRef = useRef<HTMLDivElement>(null)
  const [acceptTermsLabelText, setAcceptTermsLabelText] = useState('')
  const { Form, FormItem, submitForm, isLoading } = useSignupForm({
    invitationEmail,
    invitationToken,
    onSuccess,
    onError,
    consentText: acceptTermsLabelText,
  })

  useLayoutEffect(() => {
    setAcceptTermsLabelText(acceptTermsLabelRef?.current?.textContent || '')
  }, [])

  return (
    <>
      <Text variant="h1">{title}</Text>
      <Space size="xl" />
      <Text variant="h3">{t('organization_invitation.form.sign_up_sub_title')}</Text>
      <Space size="xxl" />
      <Form>
        <FormItem
          label={t('organization_invitation.form.label.email')}
          name="email"
          render={({ field }) => (
            <Input
              {...field}
              disabled={!!invitationEmail}
              placeholder={t('organization_invitation.form.placeholder.email')}
              size="xl"
            />
          )}
        />
        <Space />
        <FormItem
          label={t('organization_invitation.form.label.password')}
          name="password"
          render={({ field }) => (
            <InputPassword {...field} placeholder={t('organization_invitation.form.placeholder.password')} size="xl" />
          )}
        />
        <Space />
        <FormItem
          label={t('organization_invitation.form.label.name')}
          name="name"
          render={({ field }) => (
            <Input {...field} placeholder={t('organization_invitation.form.placeholder.name')} size="xl" />
          )}
        />
        <Space />
        <FormItem
          name="acceptedTerms"
          render={({ field: { value, onChange, error, name } }) => (
            <Checkbox name={name} checked={!!value} onChange={onChange} error={error}>
              <Styled.AcceptTermsLabelWrapper ref={acceptTermsLabelRef}>
                <Trans
                  i18nKey="organization_invitation.form.label.accepted_terms"
                  values={{
                    termsLink: t('organization_invitation.form.label.terms_link'),
                    dataLink: t('organization_invitation.form.label.data_link'),
                  }}
                >
                  Accept Billy's
                  <Link target="_blank" href="https://www.billy.dk/generelle-betingelser/">
                    {t('organization_invitation.form.label.terms_link')}
                  </Link>
                  &
                  <Link target="_blank" href="https://www.billy.dk/generelle-betingelser/#databehandleraftale/">
                    {t('organization_invitation.form.label.data_link')}
                  </Link>
                  Processor agreement*
                </Trans>
              </Styled.AcceptTermsLabelWrapper>
            </Checkbox>
          )}
        />
        <Space />
        <FormItem
          name="acceptedMarketing"
          render={({ field: { value, onChange, error, name } }) => (
            <Checkbox name={name} checked={!!value} onChange={onChange} error={error}>
              {t('organization_invitation.form.label.accepted_marketing')}
            </Checkbox>
          )}
        />
        <Space size="xl" />
        <Button type="submit" size="xl" fullWidth onClick={submitForm} loading={isLoading}>
          {t('organization_invitation.form.accept_invitation')}
        </Button>
      </Form>
    </>
  )
}
