import { css } from '@emotion/core'
import cc from 'classcat'
import React, { PropsWithChildren } from 'react'
import { Box, BoxProps } from 'rebass'

import { reactClass } from '../../../utils'

type ActionOverlayProps = BoxProps & {
  css?: any
  forceEnabled?: boolean
  hoverable?: boolean
  placement?: 'left' | 'right'
  width?: number | string
}

export const MatchListRowActionOverlay = ({
  children,
  className,
  css: styles,
  forceEnabled,
  hoverable,
  placement = 'left',
  width = '64px',
  ...rest
}: PropsWithChildren<ActionOverlayProps>) => (
  <Box
    className={cc([
      className,
      reactClass(['action-overlay', hoverable ? 'hoverable' : '', forceEnabled ? 'force-enabled' : ''].join(' ')),
    ])}
    css={[
      css`
        ${placement}: ${placement === 'left' ? 1 : 2}px;
        background: linear-gradient(
          to ${placement === 'left' ? 'right' : 'left'},
          rgba(245, 245, 245, 0.9) 70%,
          rgba(255, 255, 255, 0)
        );
        border-radius: 3px;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        height: 28px;
        justify-content: center;
        max-width: ${width};
        overflow: hidden;
        position: absolute;
        text-align: ${placement};
        top: 1px;
        transition: max-width 200ms;
        width: ${width};
        z-index: 1;
        &:not(.${reactClass('hoverable')}):not(.${reactClass('force-enabled')}) {
          max-width: 0;
        }
      `,
      styles,
    ]}
    {...rest}
  >
    {children}
  </Box>
)
