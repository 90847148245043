import { UploadFilesErrorMessage, UploadFilesErrors } from '@components'
import { AttachmentFile, notify } from '@design-system'

import { useCallback, useRef, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { Origin } from '../../../enums/origin'
import { UploadFileHeaders } from '../../../types/uploadFilesHeaders'
import { APIError } from '../../../utils'
import { uploadFile } from '../query-api'

interface UploadProgress {
  current: number
  total: number
}

interface UseUploadFilesOptions extends UploadFileHeaders {
  fileOrigin?: Origin
}

export interface UseUploadFilesProps<TUploadResponse = unknown> {
  onError?: (error: APIError) => void
  onUpload?: (file: AttachmentFile) => Promise<TUploadResponse> | undefined | void
  onSettled?: () => void
  onSuccess?: (data?: (Awaited<TUploadResponse> | undefined)[]) => void
  options?: UseUploadFilesOptions
}

interface UseUploadFilesResponse {
  isUploading: boolean
  progress: UploadProgress
  uploadFiles: (filesAccepted: File[], filesRejected?: FileRejection[]) => void
}

const uploadProgressInitialData: UploadProgress = {
  current: 0,
  total: 0,
}

export const useUploadFiles = <TUploadResponse>({
  onError,
  onSettled,
  onSuccess,
  onUpload,
  options = {},
}: UseUploadFilesProps<TUploadResponse>): UseUploadFilesResponse => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>(uploadProgressInitialData)
  const existingFilesErrors = useRef<UploadFilesErrors>()

  const { fileOrigin, ...headerOptions } = options

  const handleMultipleFilesMutation = useCallback(
    async (files: File[]) => {
      const resultData: (Awaited<TUploadResponse> | undefined)[] = []
      const totalFilesCount = files.length
      const uploadFilesErrors: UploadFilesErrors = existingFilesErrors.current || {}

      if (!organization?.id) {
        notify({
          id: NotificationKeys.UploadFiles,
          message: t('file.rejected'),
          variant: 'error',
        })
        console.error('"organizationId" has to be provided to create an attachment')

        return
      }

      for (const file of files) {
        setUploadProgress((previousUploadProgress) => ({
          current: previousUploadProgress.current + 1,
          total: totalFilesCount,
        }))

        try {
          const { files: uploadedFiles } = await uploadFile(file, {
            'x-organizationId': organization.id,
            ...headerOptions,
          })

          const result = await onUpload?.(uploadedFiles?.[0])
          if (result) {
            resultData.push(result)
          }
        } catch (error: any) {
          const errorMessage = error.body.errorMessage
          uploadFilesErrors[file.name] = errorMessage || t('file.rejected.unknown_error')
        }
      }

      const rejectedFilesCount = Object.keys(uploadFilesErrors).length

      if (rejectedFilesCount > 0) {
        notify({
          id: NotificationKeys.UploadFiles,
          message: UploadFilesErrorMessage({ uploadFilesErrors }),
          title: t('file.rejected.count', { count: rejectedFilesCount }),
          variant: rejectedFilesCount === totalFilesCount ? 'error' : 'warning',
        })
      }

      return resultData
    },
    [organization, t, headerOptions, onUpload],
  )

  const { isLoading, mutate: upload } = useMutation(handleMultipleFilesMutation, {
    onError: (error: APIError) => {
      onError?.(error)
    },
    onSettled: () => {
      setUploadProgress(uploadProgressInitialData)
      onSettled?.()
    },
    onSuccess,
  })

  const handleUploadFiles = useCallback(
    (filesAccepted: File[], filesRejected?: FileRejection[]) => {
      existingFilesErrors.current = getExistingUploadErrors(filesRejected)
      upload(filesAccepted)
    },
    [upload],
  )

  return {
    isUploading: isLoading,
    progress: uploadProgress,
    uploadFiles: handleUploadFiles,
  }
}

function getExistingUploadErrors(filesRejected?: FileRejection[]): UploadFilesErrors | undefined {
  return filesRejected?.reduce((result, fileRejected) => {
    const fileName = fileRejected.file.name
    result[fileName] = fileRejected.errors.map((error) => error.message).join(', ')
    return result
  }, {} as UploadFilesErrors)
}
