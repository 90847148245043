import { EmberRoute } from '../../../../../enums/emberRoute'
import { getBillViewPath } from '../../../utils/getBillViewPath'
import { BillBalanceModifierReference } from '../enums/BillBalanceModifierReference'

export const getBillPaymentPaneItemRouteMap = (
  modifierId: string,
): Record<BillBalanceModifierReference, EmberRoute | string> => ({
  [BillBalanceModifierReference.BankPayment]: EmberRoute.BankPayment,
  [BillBalanceModifierReference.Bill]: getBillViewPath(modifierId),
  [BillBalanceModifierReference.ContactBalancePayment]: EmberRoute.ContactBalancePayment,
  [BillBalanceModifierReference.DaybookTransaction]: EmberRoute.DaybookTransaction,
})

export const getBillPaymentPaneItemRoute = (
  balanceModifierReference: BillBalanceModifierReference,
  modifierId: string,
): EmberRoute | string => getBillPaymentPaneItemRouteMap(modifierId)[balanceModifierReference]
