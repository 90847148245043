import React from 'react'
import { BoxProps } from 'rebass'

import * as Icons from './iconFiles'
import * as Styled from './styles'

export type IconProps = BoxProps & {
  /** Background color */
  bg?: string
  /** Primary color */
  color?: string
  /** Secondary color, not all icon will have this */
  color2?: string
  /** Optional color */
  color3?: string
  /** Secondary optional color */
  color4?: string
  /** Constrained width */
  width?: number | string
  /** Constrained height */
  height?: number | string
  /** Action to execute on click */
  onClick?: () => void
  /** Dynamic icon type */
  type?: string
  /** Icon rotation */
  rotate?: boolean
  /** Carry over styling from theme */
  variant?: string
  /** Icons that can have either color fill of be outlined, not all icons will have this */
  isOutlined?: boolean
  /** The stroke width of the svg */
  strokeWidth?: string | number
}

export const Icon = ({ className, type = 'caret', ...rest }: IconProps) => {
  const IconComponent = Icons[type]
  return (
    <Styled.IconWrapper className={className}>
      <IconComponent {...rest} />
    </Styled.IconWrapper>
  )
}
