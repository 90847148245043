import { Button, Space, Text } from '@design-system'

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

export interface BankDisconnectionProps {
  onBankConnect: () => void
}

export const BankDisconnection = ({ onBankConnect }: BankDisconnectionProps) => {
  const { t } = useTranslation()

  return (
    <Styled.BankDisconnectionWrapper>
      <Styled.ContentWrapper>
        <Styled.Header>
          <Styled.Title variant="displayM">{t('dashboard.widget.bank-integration.disconnected.title')}</Styled.Title>
          <Space />
          <Styled.DescriptionWrapper>
            <Text>{t('dashboard.widget.bank-integration.disconnected.info_text')}</Text>
          </Styled.DescriptionWrapper>
        </Styled.Header>
        <Button onClick={onBankConnect} icon="plug">
          {t('dashboard.widget.bank-integration.connect_bank')}
        </Button>
      </Styled.ContentWrapper>
    </Styled.BankDisconnectionWrapper>
  )
}
