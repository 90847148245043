import { notify } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../enums/queryKeys'
import { useRemoveAttachmentOwnerReference } from './useRemoveAttachmentOwnerReference'
import { useUpdateReceipt } from './useUpdateReceipt'

interface UseAttachmentOwnerReference {
  removeOwnerReference: (attachmentId: string) => void
  setOwnerReference: (attachmentId: string, billId: string) => void
}

export const useAttachmentOwnerReference = (): UseAttachmentOwnerReference => {
  const queryClient = useQueryClient()
  const { update } = useUpdateReceipt()
  const { remove } = useRemoveAttachmentOwnerReference()
  const { t } = useTranslation()
  const { organization } = useUserOrganization()

  const removeOwnerReference = useCallback(
    (attachmentId: string) => {
      remove(
        {
          attachmentId,
          organizationId: organization?.id as string,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKeys.AttachmentsCount)
            queryClient.invalidateQueries(QueryKeys.ReceiptsList)
          },
          onError: () => {
            notify({
              id: 'owner-reference-remove',
              message: t('attachments.owner_reference.remove.error'),
              variant: 'error',
            })
          },
        },
      )
    },
    [remove, organization?.id, t, queryClient],
  )

  const setOwnerReference = useCallback(
    (attachmentId: string, billId: string) => {
      update(
        {
          id: attachmentId,
          ownerReference: `bill:${billId}`,
          ownerId: billId,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKeys.AttachmentsCount)
            queryClient.invalidateQueries(QueryKeys.ReceiptsList)
          },
          onError: () => {
            notify({
              id: 'owner-reference-update',
              message: t('attachments.owner_reference.update.error'),
              variant: 'error',
            })
          },
        },
      )
    },
    [update, t, queryClient],
  )

  return {
    removeOwnerReference,
    setOwnerReference,
  }
}
