import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ControlBookkeepingIntegrationsList } from './elements/ControlBookkepingIntegrationsList'
import { IntegrationsFooter } from './elements/IntegrationsFooter'
import { IntegrationsHeader } from './elements/IntegrationsHeader'
import { IntegrationsSideActions } from './elements/IntegrationsSideActions'
import { InvoicesIntegrationsList } from './elements/InvoicesIntegrationsList'
import { MoreIntegrationsList } from './elements/MoreIntegrationsList'
import * as Styled from './styles'

export const Integrations = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <ModuleLayout title={t('integrations.title')} sideActions={<IntegrationsSideActions />}>
      <Styled.IntegrationsContainer>
        <IntegrationsHeader />
        <InvoicesIntegrationsList />
        <ControlBookkeepingIntegrationsList />
        <MoreIntegrationsList />
        <IntegrationsFooter />
      </Styled.IntegrationsContainer>
    </ModuleLayout>
  )
}
