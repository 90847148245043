import { css } from '@emotion/core'
import styled from '@emotion/styled'
import SimpleBar from 'simplebar-react'

import { transientOptions } from '../../../../constants/transientOptions'
import { MODULE_LAYOUT_BOTTOM_SPACE } from '../../constants/moduleLayoutBottomSpace'

interface ModuleContentProps {
  $fullHeight?: boolean
}

export const ModuleContent = styled(SimpleBar, transientOptions)<ModuleContentProps>`
  display: flex;
  overflow: auto;

  ${({ $fullHeight }) =>
    $fullHeight &&
    css`
      flex: 1;

      ${InnerContent} {
        padding-bottom: 0;
      }
    `}
`
export const InnerContent = styled.section`
  display: flex;
  padding-bottom: ${MODULE_LAYOUT_BOTTOM_SPACE};
  position: relative;
  flex-grow: 1;
  flex-direction: column;
`
