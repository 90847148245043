import reduce from 'lodash/reduce'

import { TableColumn, TableColumnAccessor } from '../types/column'
import { TableData } from '../types/data'

const DEFAULT_ITEMS_COUNT = 10

export function generateMockTableData<T extends TableData>(
  columns: TableColumn<T>[],
  itemsCount: number = DEFAULT_ITEMS_COUNT,
) {
  const data: T[] = []
  const keys = reduce(
    columns,
    (result: TableColumnAccessor<T>[], column) => {
      if (!column.accessor || typeof column.accessor === 'function') {
        return result
      }

      return [...result, column.accessor]
    },
    [],
  )
  const item = reduce(keys, (result, key) => ({ ...result, [key]: '' }), {} as T)

  for (let i = 0; i < itemsCount; i += 1) {
    data.push({ ...item, id: i })
  }

  return data
}
