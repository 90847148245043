import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const DataSummarySectionWrapper = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: ${Spacing.M} ${Spacing.L};
  border-radius: ${DefaultValue.BorderRadius};
  background-color: ${Color.Gray20};
`
