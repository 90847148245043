import { ReactElement, ReactNode, useCallback } from 'react'

import { IconButton } from '../../../IconButton'
import { Text } from '../../../Text'
import { useSidePanel } from '../../context/sidePanelContext'
import * as Styled from './styles'

interface SidePanelHeaderProps {
  children?: ReactNode
  closable?: boolean
  title?: ReactNode
}

export const SidePanelHeader = ({ children, closable, title }: SidePanelHeaderProps): ReactElement => {
  const { close } = useSidePanel()

  const handleCloseClick = useCallback(() => {
    if (closable) {
      close?.()
    }
  }, [closable, close])

  return (
    <Styled.SidePanelHeaderWrapper>
      {title && <Text variant="h2">{title}</Text>}
      {children}
      {closable && (
        <Styled.CloseButtonWrapper onClick={handleCloseClick}>
          <IconButton icon="xSign" />
        </Styled.CloseButtonWrapper>
      )}
    </Styled.SidePanelHeaderWrapper>
  )
}
