import React, { ReactElement } from 'react'

import { CountryIsoCode } from '../../types/countryIsoCode'
import { getImageClassName } from '../../utils/getClassName'
import { getCountryIconUrl } from '../selects/CountrySelect/utils/getCountryIconUrl'

interface CountryFlagProps {
  countryCode: CountryIsoCode
}

export const CountryFlag = ({ countryCode }: CountryFlagProps): ReactElement => {
  return (
    <img
      src={getCountryIconUrl(countryCode)}
      className={getImageClassName()}
      width={16}
      height={16}
      alt=""
      loading="lazy"
    />
  )
}
