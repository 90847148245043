import { intervalToDuration } from 'date-fns'
import { TFunction } from 'i18next'

export const getPassedTimeText = (date: string | null, t: TFunction): string => {
  if (!date) {
    return ''
  }

  const { years, months, days, hours, minutes } = intervalToDuration({
    start: new Date(date),
    end: new Date(),
  })

  if (years) {
    return t('years_count', { count: years })
  }

  if (months) {
    return t('months_count', { count: months })
  }

  if (days) {
    return t('days_count', { count: days })
  }

  if (hours) {
    return t('hours_count', { count: hours })
  }

  if (minutes) {
    return t('minutes_count', { count: minutes })
  }

  return ''
}
