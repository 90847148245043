import { Button, ButtonProps } from '@design-system'

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BulkReconciliationModal } from './BulkReconciliationModal'

export type BulkReconciliationButtonProps = ButtonProps

export const BulkReconciliationButton = ({ ...rest }: BulkReconciliationButtonProps) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpenModal = useCallback(() => setModalOpen(true), [])
  const handleCloseModal = useCallback(() => setModalOpen(false), [])

  return (
    <>
      <div data-tip={t('bankreconciliation.bulk_reconcile.trigger_tooltip')}>
        <Button {...rest} icon="plusCircle" onClick={handleOpenModal} size="m" variant="text" />
      </div>
      {isModalOpen && <BulkReconciliationModal isOpen={isModalOpen} onClose={handleCloseModal} required width={1080} />}
    </>
  )
}
