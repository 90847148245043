import { NotificationVariant, notify } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import { useEffect } from 'react'

class NotificationWebComponent extends DOMModel {
  @byAttrVal id: string
  @byAttrVal variant: NotificationVariant
  @byAttrVal message: string
}

type NotificationProps = {
  id: string
  variant: NotificationVariant
  message: string
}

const availableVariants: NotificationVariant[] = ['error', 'info', 'success', 'warning']

const NotificationModule = ({ id, variant, message }: NotificationProps): null => {
  useEffect(() => {
    if (!availableVariants.includes(variant)) {
      console.error(
        `Notification: Variant "${variant}" is not correct. Please use one of these: ${availableVariants.join(', ')}.`,
      )

      return
    }

    notify({ id, message, variant })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

const NotificationCustomElement = createCustomElement(NotificationModule, NotificationWebComponent, 'element')

customElements.get('notification-module') || customElements.define('notification-module', NotificationCustomElement)

export default NotificationCustomElement
