import { getRequest, postRequest } from '../../../utils/api'
import { AccessUrlPayload } from './types/accessUrlPayload'
import { OnboardUserPayload } from './types/onboardUserPayload'
import { VerificationResultResponse } from './types/verificationResultResponse'
import { VerifyAccountOwnerPayload } from './types/verifyAccountOwnerPayload'

const LENDING_SERVICE_URL = window.ENV.LENDING_SERVICE_URL || ''
const OPEN_BANKING_SERVICE_URL = window.ENV.OPEN_BANKING_SERVICE_URL || ''

export const requestFrodaAccessUrl = async (accessUrlPayload: AccessUrlPayload): Promise<string> => {
  const response = await postRequest(LENDING_SERVICE_URL + '/lending/access-token', accessUrlPayload)
  return response.url
}

export const onboardUserToFroda = async (onboardUserPayload: OnboardUserPayload): Promise<string> => {
  const response = await postRequest(LENDING_SERVICE_URL + '/lending/onboarding', onboardUserPayload)
  return response.url
}

export const verifyAccountOwner = async (externalId: string, payload: VerifyAccountOwnerPayload): Promise<string> => {
  const response = await postRequest(OPEN_BANKING_SERVICE_URL + `/banking/${externalId}/verify`, payload)
  return response.connectUrl
}
export const getVerificationResult = (externalId: string, jobId: string): Promise<VerificationResultResponse> =>
  getRequest(OPEN_BANKING_SERVICE_URL + `/banking/${externalId}/jobs/${jobId}`)
