import { ItemsPerPage, notify, useItemsPerPage } from '@design-system'

import noop from 'lodash/noop'
import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { useQueryParams } from '../../../../../hooks/useQueryParams'

interface QueryParams {
  callback?: string
  cancelled?: string
  page?: number
  pageSize?: ItemsPerPage
  search?: string
  successful?: string
}

const defaultQuery: QueryParams = {
  callback: undefined,
  cancelled: undefined,
  page: 1,
  pageSize: undefined,
  search: undefined,
  successful: undefined,
}

interface UmbrellaOrganizationSubscriptionsFiltersContextState {
  queryParams: QueryParams
  setQueryParams: (query: Partial<QueryParams>) => void
}

const defaultValue: UmbrellaOrganizationSubscriptionsFiltersContextState = {
  queryParams: defaultQuery,
  setQueryParams: noop,
}

export const UmbrellaOrganizationSubscriptionsFiltersContext =
  createContext<UmbrellaOrganizationSubscriptionsFiltersContextState>(defaultValue)

interface UmbrellaOrganizationSubscriptionsFiltersProviderProps {
  children: ReactNode
}

export const UmbrellaOrganizationSubscriptionsFiltersProvider = ({
  children,
}: UmbrellaOrganizationSubscriptionsFiltersProviderProps): ReactElement => {
  const { t } = useTranslation()
  const [itemsPerPage, setItemsPerPage] = useItemsPerPage()
  const [queryParams, setQueryParams] = useQueryParams<QueryParams>({
    defaultQuery: { ...defaultQuery, pageSize: itemsPerPage },
  })

  useEffect(() => {
    const isSuccessfulPaymentStatus = queryParams.successful === '1'
    const isCancelledPaymentStatus = queryParams.cancelled === '1'
    const isCallbackPaymentStatus = queryParams.callback === '1'

    if (isSuccessfulPaymentStatus) {
      notify({
        id: NotificationKeys.UmbrellaSubscriptionsPaymentStatus,
        message: t('umbrella_subscriptions.card_added'),
        variant: 'success',
      })
      setQueryParams({
        successful: undefined,
      })
    }

    if (isCancelledPaymentStatus) {
      setQueryParams({
        cancelled: undefined,
      })
    }

    if (isCallbackPaymentStatus) {
      setQueryParams({
        callback: undefined,
      })
    }
  }, [queryParams, setQueryParams, t])

  const handleSetQueryParams = useCallback(
    (newQueryParams: Partial<QueryParams>) => {
      if (newQueryParams.pageSize && newQueryParams.pageSize !== queryParams.pageSize) {
        setItemsPerPage(newQueryParams.pageSize)
      }

      setQueryParams(newQueryParams)
    },
    [queryParams, setItemsPerPage, setQueryParams],
  )

  return (
    <UmbrellaOrganizationSubscriptionsFiltersContext.Provider
      value={{ queryParams, setQueryParams: handleSetQueryParams }}
    >
      {children}
    </UmbrellaOrganizationSubscriptionsFiltersContext.Provider>
  )
}

export const useUmbrellaOrganizationSubscriptionsFilters = () =>
  useContext(UmbrellaOrganizationSubscriptionsFiltersContext)
