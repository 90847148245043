import { useCallback } from 'react'

export const useLocalStorageUncontrolled = (key: string) => {
  const setItem = useCallback(
    (value: any) => {
      window.localStorage.setItem(key, value)
    },
    [key],
  )

  const getItem = useCallback(() => {
    return window.localStorage.getItem(key)
  }, [key])

  const removeItem = useCallback(() => {
    window.localStorage.removeItem(key)
  }, [key])

  return {
    setItem,
    getItem,
    removeItem,
  }
}
