import { InputNumber } from '@design-system'

import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../../../../../../../hooks'
import { PaymentTermsMode } from '../../../../../../enums/paymentTermsMode'
import { InvoiceAndProductSettingsFormSchema } from '../../../../../../utils/formData'
import { MINIMUM_PAYMENT_TERMS_DAYS } from '../../constants/minimumPaymentTermsDays'

export const PaymentTermsDays = (): ReactElement => {
  const { t } = useTranslation()
  const { control, FormItem, setValue } = useFormContext<InvoiceAndProductSettingsFormSchema>()
  const paymentTermsMode = useWatch({ control, name: 'paymentTermsMode' })

  const isOnReceiptPaymentMode = paymentTermsMode === PaymentTermsMode.OnReceipt

  useEffect(() => {
    if (isOnReceiptPaymentMode) {
      setValue('paymentTermsDays', MINIMUM_PAYMENT_TERMS_DAYS)
    }
  }, [isOnReceiptPaymentMode, setValue])

  return (
    <FormItem
      label={t('settings.organization.invoice.invoice_settings.payment_terms_days.label')}
      name="paymentTermsDays"
      render={({ field }) => (
        <InputNumber
          {...field}
          disabled={isOnReceiptPaymentMode}
          keyboard
          min={MINIMUM_PAYMENT_TERMS_DAYS}
          withFormatting={false}
        />
      )}
    />
  )
}
