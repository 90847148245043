import { BorderRadius, Color, Spacing, TabButtonsGroup, Text } from '@design-system'

import styled from '@emotion/styled'
import React from 'react'

import { Direction } from '../../hooks/usePagination'
import { Themable } from '../../types/themable'
import { NavigationDirection } from './types'

interface FilesPaginationProps {
  activePage: number
  amountOfPages: number
  canClickButton: (direction: Direction) => boolean
  navigationDirection?: NavigationDirection
  onPageChange: (direction: Direction) => void
}

const PAGINATION_POSITION = '14px'

const FilesPaginationComponent = styled.nav<Themable>`
  display: inline-flex;
  border: 1px solid ${Color.DayGrey};
  border-radius: ${BorderRadius.L};
  position: absolute;
  top: ${PAGINATION_POSITION};
  left: ${PAGINATION_POSITION};
  z-index: 2;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  width: 160px;
  padding: ${Spacing.XXS};
  background-color: ${Color.White};
`

export const FilesPagination = ({
  activePage,
  amountOfPages,
  canClickButton,
  navigationDirection,
  onPageChange,
}: FilesPaginationProps) => {
  const isBackButtonDisabled = !canClickButton(Direction.Back)
  const isNextButtonDisabled = !canClickButton(Direction.Next)

  const handleButtonClick = (direction: Direction, isDisabled: boolean) => {
    if (!isDisabled) {
      onPageChange(direction)
    }
  }

  const isVerticalNavigation = navigationDirection === 'vertical'

  return (
    <FilesPaginationComponent>
      <TabButtonsGroup size="m">
        <TabButtonsGroup.Button
          icon={isVerticalNavigation ? 'chevronUp' : 'chevronLeft'}
          disabled={isBackButtonDisabled}
          onClick={() => handleButtonClick(Direction.Back, isBackButtonDisabled)}
        ></TabButtonsGroup.Button>
        <TabButtonsGroup.Button
          icon={isVerticalNavigation ? 'chevronDown' : 'chevronRight'}
          disabled={isNextButtonDisabled}
          onClick={() => handleButtonClick(Direction.Next, isNextButtonDisabled)}
        ></TabButtonsGroup.Button>
      </TabButtonsGroup>
      <Text variant="large">{`${activePage}/${amountOfPages}`}</Text>
    </FilesPaginationComponent>
  )
}
