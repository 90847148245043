import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { getAccessToken } from './api'
import { IS_DEVELOPMENT } from './isDevelopment'

const DEV_ZERVANT_BASE_URL = 'https://test.zerv.me'
const APPLICATION_ID = 'billy'
const BASE_URL = window.ENV.ZERVANT_URL || (IS_DEVELOPMENT ? DEV_ZERVANT_BASE_URL : '')

// service names in Zervant's SPOE
type ServiceName = 'platform' | 'restInvoice' | 'settingsService'

type ServiceToPrefixMap = {
  [serviceName in ServiceName]: string
}

// list of api URL prefixes per SPOE service
const serviceMap: ServiceToPrefixMap = {
  platform: 'platform',
  restInvoice: 'rest-invoice/api',
  settingsService: 'settings-service',
}

type ApiMethodResponse<T> = Promise<AxiosResponse<T, any>>

interface ApiMethods {
  get: <T>(url?: string, config?: AxiosRequestConfig<any>) => ApiMethodResponse<T>
  post: <T>(url?: string, data?: any, config?: AxiosRequestConfig<any>) => ApiMethodResponse<T>
  put: <T>(url?: string, data?: any, config?: AxiosRequestConfig<any>) => ApiMethodResponse<T>
  patch: <T>(url?: string, data?: any, config?: AxiosRequestConfig<any>) => ApiMethodResponse<T>
}

class ApiZervant {
  private instance: AxiosInstance
  platform: ApiMethods
  restInvoice: ApiMethods
  settingsService: ApiMethods

  constructor(servicesMap: ServiceToPrefixMap) {
    this.instance = axios.create({
      baseURL: BASE_URL,
    })

    this.instance.defaults.headers.common['application-id'] = APPLICATION_ID

    for (const [scope, urlPrefix] of Object.entries(servicesMap)) {
      ;(this as any)[scope] = this.getScopeRequestMethods(urlPrefix)
    }
  }

  getScopeRequestMethods = (pathPrefix: string): ApiMethods => {
    return {
      get: (url = '', config?: AxiosRequestConfig<any>) => this.get(`${pathPrefix}${url}`, config),
      post: (url = '', data?: any, config?: AxiosRequestConfig<any>) => this.post(`${pathPrefix}${url}`, data, config),
      put: (url = '', data?: any, config?: AxiosRequestConfig<any>) => this.put(`${pathPrefix}${url}`, data, config),
      patch: (url = '', data?: any, config?: AxiosRequestConfig<any>) =>
        this.patch(`${pathPrefix}${url}`, data, config),
    }
  }

  // @todo maybe we should send some custom event from ember to set it up here:
  setAccessToken = () => {
    if (this.instance.defaults.headers.common.Authorization) {
      return
    }

    this.instance.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`
  }

  get = <T>(url: string, config?: AxiosRequestConfig) => {
    this.setAccessToken()
    return this.instance.get<T>(url, config)
  }

  post = <T>(url: string, data?: any, config?: AxiosRequestConfig<any>) => {
    this.setAccessToken()
    return this.instance.post<T>(url, data, config)
  }

  put = <T>(url: string, data?: any, config?: AxiosRequestConfig<any>) => {
    this.setAccessToken()
    return this.instance.put<T>(url, data, config)
  }

  patch = <T>(url: string, data?: any, config?: AxiosRequestConfig<any>) => {
    this.setAccessToken()
    return this.instance.patch<T>(url, data, config)
  }
}

const api = new ApiZervant(serviceMap)
export default api
