import { TimelineStatus } from '@components'
import { SectionMessage, Space } from '@design-system'

import filter from 'lodash/filter'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { InvoiceLog, InvoiceSendingState, useInvoice } from '@views/invoices'
import { VoidInvoiceModal } from '@views/invoices/elements/VoidInvoiceModal'

import { SortDirection } from '../../enums/sortDirection'
import { useEInvoiceStatusConnection } from './contexts/eInvoiceStatusEmberConnectionContext'
import { TimelineStatusItem } from './elements/TimelineStatusItem'
import { useEInvoiceLogs } from './hooks/useEInvoiceLogs'
import { FetchInvoiceLogsOptions } from './query-api'
import { errorKeyToAction } from './utils/errorKeyToAction'

const LOGS_TO_REFETCH_IN_BACKGROUND: InvoiceSendingState[] = ['created', 'pending', 'received', 'sent']

interface EInvoiceStatusProps {
  invoiceId: string
}

export const EInvoiceStatus = ({ invoiceId }: EInvoiceStatusProps): ReactElement | null => {
  const [shouldRefetchInBackground, setShouldRefetchInBackground] = useState(false)
  const { organization } = useUserOrganization()
  const { voidInvoice, voidAndDuplicateInvoice } = useEInvoiceStatusConnection()
  const { data: invoiceData, isLoading: isInvoiceLoading } = useInvoice(invoiceId)
  const { t } = useTranslation()

  const queryOptions: FetchInvoiceLogsOptions = useMemo(
    () => ({
      invoiceId,
      organizationId: organization?.id || '',
      sortDirection: SortDirection.Desc,
      sortProperty: 'eventTime',
    }),
    [invoiceId, organization?.id],
  )

  const { eInvoiceLogs: rawEInvoiceLogs, isLoading: isInvoiceLogsLoading } = useEInvoiceLogs(
    queryOptions,
    shouldRefetchInBackground,
  )
  const isLoading = isInvoiceLoading || isInvoiceLogsLoading

  const eInvoiceLogs: InvoiceLog[] = useMemo(() => {
    return filter(rawEInvoiceLogs, (log) => log.type !== 'received')
  }, [rawEInvoiceLogs])

  const isInitiallyCollapsed = useMemo(() => {
    const isLackOfFailedLogs = eInvoiceLogs.every((eInvoice) => eInvoice.type !== 'failed')
    return isLackOfFailedLogs
  }, [eInvoiceLogs])

  const statusContent = useMemo(() => {
    const hasFirstItemSomeAction =
      eInvoiceLogs?.[0]?.messageKey && errorKeyToAction[eInvoiceLogs?.[0]?.messageKey]?.length

    if (hasFirstItemSomeAction) {
      return <SectionMessage variant="error">{t('einvoce.status.message.action_required')}</SectionMessage>
    }

    return null
  }, [eInvoiceLogs, t])

  const invoiceDelivery = invoiceData?.invoice.delivery || undefined

  useEffect(() => {
    const shouldRefetch = eInvoiceLogs.length > 0 && LOGS_TO_REFETCH_IN_BACKGROUND.includes(eInvoiceLogs[0].type)
    setShouldRefetchInBackground(shouldRefetch)
  }, [eInvoiceLogs])

  if (!isLoading && !eInvoiceLogs.length) {
    return null
  }

  return (
    <>
      <TimelineStatus
        content={statusContent}
        isCollapsed={isInitiallyCollapsed}
        isLoading={isLoading}
        title={t('einvoce.status.title')}
      >
        <TimelineStatus.ItemsList>
          {eInvoiceLogs.map((invoiceLog, index) => (
            <TimelineStatusItem
              key={invoiceLog.id}
              invoiceLog={invoiceLog}
              invoiceDelivery={invoiceDelivery}
              skipShowingActions={index !== 0}
            />
          ))}
        </TimelineStatus.ItemsList>
      </TimelineStatus>
      <Space size="xl" />
      <VoidInvoiceModal
        invoiceId={invoiceId}
        onInvoiceVoid={voidInvoice}
        onVoidAndDuplicateInvoice={voidAndDuplicateInvoice}
      />
    </>
  )
}
