import { TableBadgeLabel } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useContacts } from '../../../../../../hooks/useContacts'

export const ContactsTitle = (): ReactElement => {
  const { t } = useTranslation()
  const { isLoading, pagination } = useContacts()

  return (
    <TableBadgeLabel amount={pagination?.total} isLoading={isLoading} variant="neutral">
      {t('contacts.header')}
    </TableBadgeLabel>
  )
}
