import { convertFiscalYearToCustomDatePeriod, decodePeriodValue, encodePeriodValue } from '@components'

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QuoteState } from '../../../enums/quoteState'
import { fetchQuotes } from '../../../query-api'
import { getQuotesQueryKey } from '../../../utils/getQuotesQueryKey'
import { QuotesCountByState } from '../types/quotesCountByState'

interface UseFetchQuotesSummaryProps {
  period?: string
  searchQuery?: string
}

interface UseFetchQuotesSummaryResponse {
  isLoading: boolean
  quotesCountByState: QuotesCountByState
}

export const useFetchQuotesSummary = ({
  period,
  searchQuery,
}: UseFetchQuotesSummaryProps): UseFetchQuotesSummaryResponse => {
  const { organization } = useUserOrganization()

  const queryProps = useMemo(() => {
    const decodedPeriod = period && decodePeriodValue(period)
    // We need to convert fiscal year mode to custom dates because endpoint doesn't accept fiscal year
    const convertedPeriod =
      decodedPeriod && convertFiscalYearToCustomDatePeriod(decodedPeriod, organization?.fiscalYearEndMonth)
    const encodedPeriod = convertedPeriod && organization?.id && encodePeriodValue(convertedPeriod, organization.id)

    return {
      ...(encodedPeriod ? { entryDatePeriod: encodedPeriod } : {}),
      ...(searchQuery ? { q: searchQuery } : {}),
      organization: organization?.id as string,
    }
  }, [period, searchQuery, organization?.id, organization?.fiscalYearEndMonth])

  const queryPropsOpen = useMemo(
    () => ({
      ...queryProps,
      state: QuoteState.Open,
    }),
    [queryProps],
  )

  const queryPropsClosed = useMemo(
    () => ({
      ...queryProps,
      state: QuoteState.Closed,
    }),
    [queryProps],
  )

  const queryKeyOpen = useMemo(() => getQuotesQueryKey(queryPropsOpen), [queryPropsOpen])
  const queryKeyClosed = useMemo(() => getQuotesQueryKey(queryPropsClosed), [queryPropsClosed])

  const {
    data: { meta: { total: totalOpen = 0 } = {} } = {},
    isIdle: isIdleOpen,
    isLoading: isLoadingOpen,
  } = useQuery(queryKeyOpen, () => fetchQuotes(queryPropsOpen), {
    enabled: !!organization,
    keepPreviousData: true,
  })

  const {
    data: { meta: { total: totalClosed = 0 } = {} } = {},
    isIdle: isIdleClosed,
    isLoading: isLoadingClosed,
  } = useQuery(queryKeyClosed, () => fetchQuotes(queryPropsClosed), {
    enabled: !!organization,
    keepPreviousData: true,
  })

  const quotesCountByState = useMemo(
    () => ({
      [QuoteState.All]: totalOpen && totalClosed ? totalOpen + totalClosed : 0,
      [QuoteState.Open]: totalOpen,
      [QuoteState.Closed]: totalClosed,
    }),
    [totalOpen, totalClosed],
  )

  const isLoading = useMemo(
    () => [isIdleOpen, isIdleClosed, isLoadingOpen, isLoadingClosed].some((v) => v),
    [isIdleOpen, isIdleClosed, isLoadingOpen, isLoadingClosed],
  )

  return {
    isLoading,
    quotesCountByState,
  }
}
