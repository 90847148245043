import { Checkbox, IconCircleQuestion } from '@components-deprecated'
import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { zIndex } from '../../../styles-deprecated'
import { Themable } from '../../../types/themable'

interface NavSliderProps {
  visible?: boolean
}

export const SelectionPanelWrapper = styled.div<Themable>`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
`

export const SelectionPanelCheckbox = styled(Checkbox)`
  margin-bottom: -${Spacing.XXXS};
  align-items: center;
`

export const SelectionPanelText = styled.span``

export const SelectionPanelButtonWrapper = styled.div`
  margin-left: 10px;
`

export const NavSlider = styled.nav<NavSliderProps>`
  width: 370px;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  transform: translateX(${({ visible }) => (visible ? '0px' : '-370px')});
  transition: transform 0.2s ease-out;
  will-change: transform;
`

export const HelpBadge = styled(IconCircleQuestion)`
  margin-right: 4px;
  position: relative;
  top: -1px;
  pointer-events: auto;
`

export const CommentButton = styled.button<Themable>`
  margin: 0 4px 0 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  pointer-events: auto;
  position: relative;
  z-index: ${zIndex.aboveElement};
  color: ${Color.Blue};

  svg {
    fill: currentColor;
  }
`

export const MissingReceiptsModeButton = styled.div``

export const BankLogoWrapper = styled.figure`
  opacity: 0.3;
  display: flex;
  justify-content: center;
`
