import { BookkeepingHeroSelect, BookkeepingTagNavItemValue, isBookkeepingTagValid, TrainingData } from '@components'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../hooks'
import { useBillForm } from '../../contexts/billFormContext'
import { useBookkeepingHeroAutoComplete } from '../../hooks/useBookkeepingHeroAutoComplete'
import { BillFormSchema } from '../../utils/formData'
import { getScannedCategoryResultFromAttachmentsFiles } from '../../utils/getScannedCategoryResultFromAttachmentsFiles'

interface BillLineDescriptionProps {
  lineIndex: number
  sessionId: string
}

export const BillLineDescription = ({ lineIndex, sessionId }: BillLineDescriptionProps): ReactElement => {
  const { t } = useTranslation()
  const bookkeepingTagProperty = `billLines.${lineIndex}.bookkeepingTag` as const
  const { control, FormItem, setValue, getValues } = useFormContext<BillFormSchema>()
  const bookkeepingTag = useWatch({ control, name: bookkeepingTagProperty })
  const { billFormView, formFieldsChangeSource, setFormFieldsChangeSource } = useBillForm()
  const { autoCompleteWithBookkeepingHeroData } = useBookkeepingHeroAutoComplete()
  const vendor = useWatch({ control, name: 'vendor' })
  const attachmentsFiles = useWatch({ control, name: 'attachmentsFiles' })
  const scannedCategoryResult = useMemo(
    () => getScannedCategoryResultFromAttachmentsFiles(attachmentsFiles),
    [attachmentsFiles],
  )

  const handleDescriptionClear = useCallback(() => {
    const { autoCompletedFields } = getValues()

    setValue('autoCompletedFields', { ...autoCompletedFields, [bookkeepingTagProperty]: false })
    autoCompleteWithBookkeepingHeroData(undefined, lineIndex, formFieldsChangeSource.main)
  }, [
    autoCompleteWithBookkeepingHeroData,
    bookkeepingTagProperty,
    formFieldsChangeSource.main,
    getValues,
    lineIndex,
    setValue,
  ])

  const handleBookkeepingHeroTagSelect = useCallback(
    (bookkeepingTagValue?: BookkeepingTagNavItemValue) => {
      setValue(bookkeepingTagProperty, bookkeepingTagValue)

      if (bookkeepingTagValue && isBookkeepingTagValid(bookkeepingTagValue)) {
        autoCompleteWithBookkeepingHeroData(bookkeepingTagValue, lineIndex, formFieldsChangeSource.main)
      }
    },
    [autoCompleteWithBookkeepingHeroData, bookkeepingTagProperty, formFieldsChangeSource, lineIndex, setValue],
  )

  const trainingData: TrainingData = useMemo(
    () => ({
      contact: vendor,
      id: sessionId,
      scannedCategoryResult,
    }),
    [scannedCategoryResult, vendor, sessionId],
  )

  return (
    <FormItem
      name={`billLines.${lineIndex}.description`}
      label={t('bill.edit.form.description')}
      render={({ field: { onChange, value, ...props } }) => (
        <BookkeepingHeroSelect
          billFormView={billFormView}
          inputValue={value}
          onChange={onChange}
          onClearDescription={handleDescriptionClear}
          onClear={() => onChange('')}
          onSelect={handleBookkeepingHeroTagSelect}
          selectedValue={bookkeepingTag}
          formFieldsChangeSource={formFieldsChangeSource}
          setFormFieldsChangeSource={setFormFieldsChangeSource}
          trainingData={trainingData}
          {...props}
        />
      )}
    />
  )
}
