import React from 'react'

import { IconProps } from '../Icon'

export const IconMissingReceipts = ({
  className,
  color = 'inherit',
  width = '20px',
  height = '20px',
  ...rest
}: IconProps) => (
  <i className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width={width} height={height}>
      <g fill={color} clipPath="url(#clip0)">
        <path d="M13.0752 7.41327c0-.35089-.2854-.63702-.6365-.63702H9.62207c-.20166.45917-.48022.8808-.8042 1.2746h3.62083c.3511-.00001.6365-.28608.6365-.63758z" />
        <path d="M15.0088 3.78308c-.1187-.11871-.283-.18671-.4507-.18671h-4.5542c.0537.31226.093.62878.093.95215 0 .1095-.0251.21399-.0315.32245h3.855l.0005 9.43193c0 .2327-.1892.422-.4223.422-.2334 0-.4234-.1893-.4234-.422v-2.1198c0-.1705-.0664-.3305-.186-.4508-.1187-.1187-.283-.1867-.451-.1867H6.71606V9.65442c-.406.17242-.83422.28418-1.27417.35528v1.5359H3.85205v2.2269c0 .5947.23169 1.1538.65283 1.5748.41407.4147.9878.6527 1.57422.6527h7.4194c.9356 0 1.6966-.7615 1.6966-1.6971V4.23395c0-.17053-.0664-.33057-.1863-.45087zM6.0791 14.7249c-.52563 0-.95312-.4273-.95312-.9524v-.9524h6.67532v1.4838c.0014.1428.0205.2834.0571.421H6.0791z" />
        <path d="M10.8489 9.15964H8.19873c-.35156 0-.63696.28607-.63696.63751 0 .35145.2854.63755.63696.63755h2.65017c.3508 0 .6364-.2861.6364-.63755 0-.35144-.2856-.63751-.6364-.63751zM7.76465 7.76471c.85303-.85303 1.33228-2.00989 1.33228-3.21619a4.54847 4.54847 0 00-1.33228-3.21624C6.91162.47926 5.75488 0 4.54858 0a4.5486 4.5486 0 00-3.2163 1.33228A4.54842 4.54842 0 000 4.54852a4.54826 4.54826 0 001.33228 3.21619 4.54861 4.54861 0 003.2163 1.33227c1.20629 0 2.36329-.47924 3.21607-1.33227zm-2.82617-.28772a.55145.55145 0 01-.38989.1615c-.2229 0-.42407-.13403-.50928-.34015-.08521-.20612-.03809-.44336.11938-.60077.10547-.10559.24658-.16162.39014-.16162a.54793.54793 0 01.21069.04193c.20606.08527.34033.28662.34033.50958a.55086.55086 0 01-.16137.38953zm.43701-2.22559c-.1731.0846-.28076.26252-.27564.45472v.28558c0 .03625-.01465.07141-.04028.09741a.13902.13902 0 01-.09766.04028h-.82666c-.07617 0-.13818-.06152-.13818-.1377v-.28557a1.59236 1.59236 0 01.24463-.86329 1.59754 1.59754 0 01.67212-.59491c.32739-.14789.53198-.48035.5166-.83911-.02441-.45727-.39014-.82226-.84741-.84643a.87904.87904 0 00-.64697.24604c-.1731.16656-.27051.39721-.26954.63738a.13724.13724 0 01-.04053.09741.13678.13678 0 01-.09741.04059h-.82714c-.07593 0-.13745-.06183-.13745-.138a1.98367 1.98367 0 01.60766-1.43079 1.98552 1.98552 0 011.3772-.55536c.02515 0 .05029.00049.07544.00146.59961.02307 1.15649.31592 1.51562.79663.35889.48071.4812 1.09796.33301 1.67932a1.98689 1.98689 0 01-1.09741 1.31428v.00006z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  </i>
)
