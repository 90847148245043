import { Button, ButtonsGroup } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrellaOrganizationVoucherInbox } from '../../contexts/umbrellaOrganizationUpdateVoucherInboxContext'

export const ModalFooter = () => {
  const { t } = useTranslation()
  const { submitForm } = useUmbrellaOrganizationVoucherInbox()

  const handleApproveButtonClick = useCallback(() => {
    submitForm()
  }, [submitForm])

  return (
    <>
      <ButtonsGroup>
        <Button onClick={handleApproveButtonClick}>
          {t('umbrella.organizations_table.update_voucher_inbox_modal.approve')}
        </Button>
      </ButtonsGroup>
    </>
  )
}
