import { TableActionsWrapper } from '@components'

import { ReactElement } from 'react'

import { TransactionsPeriodSelector } from '../TransactionsPeriodSelector'
import { TransactionsSearch } from '../TransactionsSearch'
import { TransactionsTitle } from '../TransactionsTitle'
import { TransactionsTypesSelector } from '../TransactionsTypesSelector'
import { TransactionsVoidedFilter } from '../TransactionsVoidedFilter'

export const TransactionsTableActions = (): ReactElement => {
  return (
    <TableActionsWrapper>
      <TransactionsTitle />
      <TransactionsSearch />
      <TransactionsVoidedFilter />
      <TransactionsTypesSelector />
      <TransactionsPeriodSelector />
    </TableActionsWrapper>
  )
}
