import React, { ReactNode } from 'react'

export const getTabsToShowAndHide = (children: ReactNode, hiddenTabsCount = 0) => {
  const childrenArray = React.Children.toArray(children)
  const splitPoint = childrenArray.length - hiddenTabsCount
  const tabsToDisplay = childrenArray.slice(0, splitPoint)
  const tabsToDisplayInMoreButton = childrenArray.slice(splitPoint)

  return { tabsToDisplay, tabsToDisplayInMoreButton }
}
