import colors from '../colors'

export default {
  organizationMenu: {
    background: colors.menuBackground,
  },
  organizationMenuCurrentOrganization: {
    color: colors.white,
    background: colors.menuBackground,
    '&:hover': {
      background: colors.menuActive,
    },
  },
  organizationList: {
    background: colors.menuActive,
  },
  organizationListItem: {
    '&:hover, &.active': {
      background: colors.menuBackground,
    },
  },
  organizationMenuMyCompanies: {
    background: colors.menuBackground,
    color: colors.white,
    '&:hover': {
      background: colors.menuActive,
    },
  },
}
