import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'
import { transparentize } from 'polished'

export const LayoutWithSideFormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

export const ContentSide = styled.section`
  flex-grow: 1;
`

export const FormSide = styled.section`
  width: 365px;
  border-radius: 8px;
  margin-left: ${Spacing.XL};
  padding: ${Spacing.XL} ${Spacing.XXL} ${Spacing.XXL};
  flex-shrink: 0;
  background-color: ${Color.White};
  box-shadow: 0 12px 48px ${transparentize(0.85, Color.Gray90)};
`
