import { menuItems } from '../constants/menuItems'
import { MenuItem } from '../types/menuItem'
import { MenuItemId } from '../types/menuItemId'

export interface MenuItemExtended extends MenuItem {
  id: MenuItemId
  submenu?: MenuItemExtended[]
}

export const getMenuItem = (id: MenuItemId, submenuIds?: MenuItemId[]): MenuItemExtended => ({
  id,
  ...menuItems[id],
  ...(submenuIds ? { submenu: submenuIds.map((submenuId) => ({ id: submenuId, ...menuItems[submenuId] })) } : {}),
})
