export enum TransactionsTableAccessor {
  Amount = 'amount',
  Attachment = 'hasVoucher',
  Description = 'description',
  EntryDate = 'entryDate',
  OriginatorType = 'originatorType',
  TransactionNo = 'transactionNo',
  Voided = 'voided',
  VoucherNo = 'voucherNo',
}
