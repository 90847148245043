import { ReactElement, ReactNode } from 'react'

import { modalContentLayout } from '../../../shared'
import { useCreateOrEditVatRateModal } from '../../context/createOrEditVatRateModalContext'

interface ModalContentProps {
  children?: ReactNode
}

export const ModalContent = ({ children }: ModalContentProps): ReactElement => {
  const { Form } = useCreateOrEditVatRateModal()

  return (
    <>
      <Form layout={modalContentLayout}>{children}</Form>
    </>
  )
}
