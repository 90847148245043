import { roleToPrice } from '../constants/roleToPrice'
import { Role } from '../types/role'

export const getPriceForRole = (role: Role, subscriptionProductPlan: string): string | undefined => {
  const priceDetails = roleToPrice[role]

  if (!priceDetails) {
    return undefined
  }

  const { plan, price, currencyId } = priceDetails
  const plans = Array.isArray(plan) ? plan : [plan]
  const isPaid = plans.includes(subscriptionProductPlan)

  if (!isPaid) {
    return undefined
  }

  return `${price} ${currencyId}/m`
}
