import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const IntegrationFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
`

export const IntegrationFooterImageWrapper = styled.div`
  max-width: 182px;
  margin-bottom: ${Spacing.XXL};
`

export const IntegrationFooterLinkWrapper = styled.div`
  display: flex;
`
