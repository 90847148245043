import { css } from '@emotion/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'rebass'

import { Checkbox, Fieldset } from '../../../components-deprecated/Form'
import { SpecificState } from '../../../types/reduxSaga-deprecated'
import { filtersChanged } from '../action-creators'
import { ReconcilablePostingFilters, ReconcilablePostingType } from '../types'

enum RenderableFilterType {
  Invoice,
  Bill,
  OtherTransaction,
}

type RenderableFilters = {
  types: RenderableFilterType[]
  showPaid: boolean
  showUnpaid: boolean
  showDrafts: boolean
}

export const ReconcilablePostingFiltersPanel = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const filters = useSelector((state: SpecificState) => state.bankReconciliation.filters.reconcilablePostingFilters)
  const onChangeFilters = useCallback(
    (reconcilablePostingFilters: ReconcilablePostingFilters) => {
      dispatch(filtersChanged({ reconcilablePostingFilters }))
    },
    [dispatch],
  )

  const getInitialRenderableFilters = useCallback((): RenderableFilters => {
    if (!filters.types.length) {
      return {
        types: Object.values(RenderableFilterType) as RenderableFilterType[],
        showPaid: true,
        showUnpaid: true,
        showDrafts: true,
      }
    }

    const renderableFilterState: RenderableFilters = {
      types: [],
      showPaid: false,
      showUnpaid: false,
      showDrafts: false,
    }

    if (
      filters.types.includes(ReconcilablePostingType.Invoice) ||
      filters.types.includes(ReconcilablePostingType.InvoicePayment)
    ) {
      renderableFilterState.types.push(RenderableFilterType.Invoice)
    }

    if (
      filters.types.includes(ReconcilablePostingType.Bill) ||
      filters.types.includes(ReconcilablePostingType.BillPayment)
    ) {
      renderableFilterState.types.push(RenderableFilterType.Bill)
    }

    if (
      filters.types.includes(ReconcilablePostingType.DaybookTransaction) ||
      filters.types.includes(ReconcilablePostingType.SalesTaxPayment) ||
      filters.types.includes(ReconcilablePostingType.SalesTaxReturn)
    ) {
      renderableFilterState.types.push(RenderableFilterType.OtherTransaction)
    }

    renderableFilterState.showPaid =
      filters.types.includes(ReconcilablePostingType.InvoicePayment) ||
      filters.types.includes(ReconcilablePostingType.BillPayment) ||
      filters.types.includes(ReconcilablePostingType.SalesTaxPayment)

    renderableFilterState.showUnpaid =
      filters.types.includes(ReconcilablePostingType.Invoice) ||
      filters.types.includes(ReconcilablePostingType.Bill) ||
      filters.types.includes(ReconcilablePostingType.SalesTaxReturn) ||
      filters.types.includes(ReconcilablePostingType.DaybookTransaction)

    renderableFilterState.showDrafts = filters.showDrafts

    return renderableFilterState
  }, [filters.showDrafts, filters.types])

  const [renderableFilters, setRenderableFilters] = useState<RenderableFilters>(getInitialRenderableFilters())

  useEffect(() => {
    const types = []

    if (renderableFilters.types.includes(RenderableFilterType.Invoice)) {
      if (renderableFilters.showPaid) {
        types.push(ReconcilablePostingType.InvoicePayment)
      }
      if (renderableFilters.showUnpaid) {
        types.push(ReconcilablePostingType.Invoice)
      }
    }

    if (renderableFilters.types.includes(RenderableFilterType.Bill)) {
      if (renderableFilters.showPaid) {
        types.push(ReconcilablePostingType.BillPayment)
      }
      if (renderableFilters.showUnpaid) {
        types.push(ReconcilablePostingType.Bill)
      }
    }

    if (renderableFilters.types.includes(RenderableFilterType.OtherTransaction)) {
      if (renderableFilters.showPaid) {
        types.push(ReconcilablePostingType.SalesTaxPayment, ReconcilablePostingType.DaybookTransaction)
      }

      if (renderableFilters.showUnpaid) {
        types.push(ReconcilablePostingType.SalesTaxReturn)
      }
    }

    onChangeFilters({
      types,
      showDrafts: false, // @TODO: When reimplementing drafts, use renderableFilters.showDrafts
    })
  }, [onChangeFilters, renderableFilters])

  const toggleFilterType = (type: RenderableFilterType) => {
    const changedRenderableFilters = { ...renderableFilters }
    const types = changedRenderableFilters.types
    const index = changedRenderableFilters.types.indexOf(type)

    if (index < 0) {
      changedRenderableFilters.types.push(type)
    } else {
      changedRenderableFilters.types.splice(index, 1)
    }

    setRenderableFilters({
      ...changedRenderableFilters,
      types,
    })
  }

  return (
    <Fieldset
      css={css`
        align-items: flex-start;
        background-color: white;
        border-radius: 3px;
        background-color: white;
        white-space: nowrap;
        margin-bottom: 0;
        > div {
          flex-direction: row !important;
        }
        label {
          margin-bottom: 0;
          color: #364a59;
        }
      `}
    >
      <Flex
        css={css`
          flex-direction: column;
          margin-top: 10px;
          margin-left: 12px;
          margin-bottom: -1px;
        `}
      >
        <Checkbox
          checked={renderableFilters.types.includes(RenderableFilterType.Invoice)}
          onChange={() => toggleFilterType(RenderableFilterType.Invoice)}
          label={t('bankreconciliation.reconcilablepostings.invoices')}
        />
        <Checkbox
          checked={renderableFilters.types.includes(RenderableFilterType.Bill)}
          onChange={() => toggleFilterType(RenderableFilterType.Bill)}
          label={t('bankreconciliation.reconcilablepostings.bills')}
        />
        <Checkbox
          checked={renderableFilters.types.includes(RenderableFilterType.OtherTransaction)}
          onChange={() => toggleFilterType(RenderableFilterType.OtherTransaction)}
          label={t('bankreconciliation.reconcilablepostings.othertransactions')}
        />
      </Flex>
      <Box
        css={css`
          background: rgba(151, 151, 151, 0.5);
          width: 2px;
          height: 76px;
          align-self: center;
        `}
      />
      <Flex
        css={css`
          flex-direction: column;
          margin-top: 10px;
          margin-left: 11px;
          margin-bottom: -1px;
        `}
      >
        <Checkbox
          checked={renderableFilters.showPaid}
          onChange={() => setRenderableFilters({ ...renderableFilters, showPaid: !renderableFilters.showPaid })}
          label={t('bankreconciliation.reconcilablepostings.paid')}
        />
        <Checkbox
          checked={renderableFilters.showUnpaid}
          onChange={() => setRenderableFilters({ ...renderableFilters, showUnpaid: !renderableFilters.showUnpaid })}
          label={t('bankreconciliation.reconcilablepostings.unpaid')}
        />
        {/* <Checkbox
          checked={renderableFilters.showDrafts}
          onChange={() => setRenderableFilters({ ...renderableFilters, showDrafts: !renderableFilters.showDrafts })}
          label={t('bankreconciliation.reconcilablepostings.drafts')}
        /> */}
      </Flex>
    </Fieldset>
  )
}
