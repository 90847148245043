import { BankLineMatchesResponse } from '../../bankReconciliation/query-api'
import { ReceiptState } from '../../bankReconciliation/types'

export const countNotMissingReceiptBankLines = (allBankLineMatchesData: BankLineMatchesResponse[]): number =>
  allBankLineMatchesData.reduce((totalCount, bankLineMatchesData) => {
    const lines = bankLineMatchesData.bankLineMatches.filter((bankLineMatch) =>
      bankLineMatch.lines.every((line) => line.receiptState !== ReceiptState.MissingReceipt),
    )

    return totalCount + lines.length
  }, 0)
