import { ButtonDropdown, ButtonDropdownProps, NavItem } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ExportType } from './enums/exportType'

interface ExportButtonDropdownProps extends Omit<ButtonDropdownProps<ExportType>, 'items'> {
  exportTypesToSkip?: ExportType[]
}

export const ExportButtonDropdown = ({
  exportTypesToSkip,
  ...buttonDropdownProps
}: ExportButtonDropdownProps): ReactElement => {
  const { t } = useTranslation()

  const allExportTypes: NavItem<ExportType>[] = useMemo(
    () => [
      {
        id: ExportType.Pdf,
        children: t('export_button.pdf'),
        value: ExportType.Pdf,
      },
      {
        id: ExportType.Html,
        children: t('export_button.html'),
        value: ExportType.Html,
      },
      {
        id: ExportType.Csv,
        children: t('export_button.csv'),
        value: ExportType.Csv,
      },
      {
        id: ExportType.Xlsx,
        children: t('export_button.xlsx'),
        value: ExportType.Xlsx,
      },
      {
        id: ExportType.RegnskabBasis,
        children: 'Regnskab Basis',
        value: ExportType.RegnskabBasis,
      },
    ],
    [t],
  )

  const items =
    exportTypesToSkip && exportTypesToSkip.length
      ? allExportTypes.filter((exportType) => !exportTypesToSkip?.includes(exportType.value))
      : allExportTypes

  return (
    <ButtonDropdown {...buttonDropdownProps} items={items}>
      {buttonDropdownProps.children || t('export_button.label')}
    </ButtonDropdown>
  )
}
