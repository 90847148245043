import { Label as LabelRebass, LabelProps as LabelRebassProps } from '@rebass/forms'
import React from 'react'
import { Box } from 'rebass'

import { getClassName } from '../../utils/getClassName'

export type FormLabelProps = LabelRebassProps & {
  name?: string
  onClick?: () => void
  required?: boolean | string
}

export const FormLabel = ({ children, name, required, ...rest }: FormLabelProps) => {
  if (!children) {
    return null
  }
  return (
    <LabelRebass className={getClassName('label-deprecated')} htmlFor={name} {...rest}>
      {children}
      {required && <Box as="span">*</Box>}
    </LabelRebass>
  )
}
