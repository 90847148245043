import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconAccessToken = ({ color = 'inherit', color2, width = '18px', height = '18px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-access-token')} {...rest}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 414.9 401.3"
    >
      <g>
        <path
          fill={coloring(color)}
          d="M388.2,10.8L351.6,10c-3.7-0.1-7.2,1.4-9.8,3.9l-90.2,90.2L174,181.7c-54.2-29.1-121.7-8.7-150.8,45.5 S14.6,349,68.8,378c54.2,29.1,121.7,8.7,150.8-45.5c15.8-29.5,17.5-64.4,4.7-95.3l22.6-22.7c2.3-2.3,3.7-5.4,3.8-8.7l-2.9-28.3 c0.1-1.3,1.1-2.4,2.5-2.5l29.2-1.3c10.5-0.5,18.9-8.9,19.4-19.4l1.3-29.4c0.1-1.3,1.1-2.4,2.5-2.5l29.4-1.3 c10.5-0.5,18.9-8.9,19.4-19.4l1.3-29.2c0.1-1.3,1.1-2.4,2.5-2.5l29.4-1.3c10.5-0.5,18.9-8.9,19.4-19.4l0.9-20.8c0-0.1,0-0.1,0-0.2 C405.1,19,397.7,11.1,388.2,10.8z M119,318c-10,10-26.2,10-36.2,0c-10-10-10-26.2,0-36.2c10-10,26.2-10,36.2,0 C129,291.7,129,308,119,318C119,318,119,318,119,318z"
        />
        <path
          fill={coloring(color2 || color)}
          d="M121.2,401.2c-19.3,0-38.9-4.6-57.1-14.4C35.5,371.5,14.6,346,5.2,315c-9.4-31-6.1-63.9,9.2-92.4
          c15.3-28.6,40.9-49.5,71.9-58.9c28.6-8.6,58.8-6.5,85.7,5.9L334.7,6.9c4.5-4.5,10.7-7,17-6.9l36.8,0.8c14.9,0.5,26.6,13,26.3,27.8
          l0,0.5l-0.9,20.8c-0.7,15.8-13.2,28.2-28.9,28.9l-22.6,1l-1,22.4c-0.7,15.8-13.1,28.2-28.9,28.9l-22.6,1l-1,22.6
          c-0.7,15.8-13.1,28.2-28.9,28.9l-21.3,0.9l2.2,20.8l0,0.7c-0.3,5.8-2.6,11.2-6.7,15.3l-18.1,18.1c11.3,32.1,8.7,67.3-7.6,97.6
          C206.5,378,164.5,401.2,121.2,401.2z M121.5,178.4c-9.8,0-19.8,1.5-29.4,4.4c-25.9,7.8-47.2,25.3-60,49.2
          c-26.4,49.3-7.8,110.8,41.5,137.3c49.3,26.4,110.8,7.8,137.3-41.5c14.4-26.9,16-58.5,4.3-86.8l-2.6-6.2l27.4-27.4
          c0.4-0.4,0.7-1,0.8-1.6l-2.9-28.1l0-0.7c0.3-6.5,5.4-11.7,12-12l29.3-1.3c5.4-0.2,9.6-4.5,9.8-9.8l1.3-29.4c0.3-6.5,5.6-11.7,12-12
          l29.4-1.3c5.4-0.2,9.6-4.5,9.8-9.8l1.3-29.2c0.3-6.6,5.5-11.7,12.1-12l29.4-1.3c5.4-0.2,9.6-4.5,9.8-9.8l0.9-20.8
          c0.1-4-3-7.3-7-7.5L351.3,20c-0.9,0-1.8,0.4-2.5,1l-173,173l-6.5-3.5C154.4,182.5,138,178.4,121.5,178.4z M303.1,132.5L303.1,132.5
          C303.1,132.5,303.1,132.5,303.1,132.5z M310.1,125.4L310.1,125.4C310.1,125.4,310.1,125.4,310.1,125.4z M355.6,80.1L355.6,80.1
          C355.6,80.1,355.6,80.1,355.6,80.1z M100.9,335.5C100.9,335.5,100.9,335.5,100.9,335.5c-9.5,0-18.5-3.7-25.2-10.4
          c-6.7-6.7-10.4-15.7-10.4-25.2c0-9.5,3.7-18.4,10.4-25.2c13.9-13.9,36.5-13.9,50.3,0c13.9,13.9,13.9,36.4,0,50.3l-0.3,0.3
          C119.1,331.9,110.3,335.5,100.9,335.5z M100.9,284.3c-4,0-8,1.5-11,4.6c-2.9,2.9-4.6,6.9-4.6,11c0,4.2,1.6,8.1,4.6,11
          c2.9,2.9,6.9,4.6,11,4.6c0,0,0,0,0,0c4.2,0,8.1-1.6,11-4.6l0.1-0.1c6-6.1,5.9-15.9-0.1-22C108.9,285.8,104.9,284.3,100.9,284.3z"
        />
      </g>
    </svg>
  </Box>
)
