import { ReportFilter } from '../elements/ReportsFilters'
import {
  BalanceSheetReportOptions,
  IncomeStatementsReportOptions,
  TrialBalanceReportOptions,
} from '../types/reportOptions'

export const filterToPayloadOption: Record<
  ReportFilter,
  Partial<keyof (IncomeStatementsReportOptions & TrialBalanceReportOptions & BalanceSheetReportOptions)>
> = {
  hideZero: 'hideZeroes',
  invertAmounts: 'inverted',
  showYtd: 'ytd',
}
