import { NavItem, Space, Text } from '@design-system'

import cc from 'classcat'
import { ReactElement, useCallback } from 'react'

import { PeriodMode } from '../../../../../enums/periodMode'
import { PeriodValue } from '../../../../../types/periodValue'
import { getClassName, getImageClassName } from '../../../../../utils/getClassName'
import { getImgPath } from '../../../../../utils/getImgPath'
import { usePeriodSelectContext } from '../../contexts/periodSelectContext'
import { isPeriodValueEqual } from '../../utils/isPeriodValueEqual'
import { PeriodNavigation } from '../PeriodNavigation'
import * as Styled from './styles'

export const PeriodsList = (): ReactElement => {
  const { setPeriodValue, periodValue, periodItems, periodMode, isCustomDate } = usePeriodSelectContext()

  const handleItemClick = useCallback(
    (item: NavItem<PeriodValue>) => () => {
      if (!item.value.disabled) {
        setPeriodValue(item.value)
      }
    },
    [setPeriodValue],
  )

  return (
    <Styled.PeriodsListWrapper disabled={isCustomDate}>
      <PeriodNavigation />
      <Space size="s" />

      {periodMode === PeriodMode.All ? (
        <Styled.ImagePlaceholderWrapper className={getImageClassName()}>
          <Styled.ImagePlaceholder src={getImgPath('/period-select/all-dates-placeholder.svg')} />
        </Styled.ImagePlaceholderWrapper>
      ) : null}

      {periodItems.length > 0 && (
        <Styled.PeriodItemsList columns={periodMode === PeriodMode.Quarter ? 1 : 3}>
          {periodItems.map((item) => {
            const { value: itemPeriodValue, id } = item
            const isActive = isPeriodValueEqual(itemPeriodValue, periodValue)
            const isDisabled = itemPeriodValue.disabled

            return (
              <Styled.PeriodItem key={id}>
                <Styled.ItemButton
                  className={cc([getClassName('period-item-button'), { active: isActive, disabled: isDisabled }])}
                  active={isActive}
                  disabled={isDisabled}
                  onClick={handleItemClick(item)}
                >
                  <Text variant="micro" weight="medium" inheritColor>
                    {item.children}
                  </Text>
                </Styled.ItemButton>
              </Styled.PeriodItem>
            )
          })}
        </Styled.PeriodItemsList>
      )}
    </Styled.PeriodsListWrapper>
  )
}
