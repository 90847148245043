import { Spacing } from '@design-system'

import styled from '@emotion/styled'
import SimpleBar from 'simplebar-react'

export const TaskList = styled(SimpleBar)`
  display: flex;
  padding-right: ${Spacing.XXS};
  margin-top: -${Spacing.XXS};
  margin-right: -${Spacing.XXS};
  flex: 1;
  flex-direction: column;
  overflow: auto;
  list-style-type: none; // important when using SimpleBar
`
