import { Color, IconButton, Input, SkeletonText, Text } from '@design-system'

import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useFormContext } from '../../../../../../hooks/useForm'
import { useBillForm } from '../../contexts/billFormContext'
import { useNextVoucherNumber } from '../../hooks/useNextVoucherNumber'
import { BillFormSchema } from '../../utils/formData'
import * as Styled from './styles'

export const BillNumber = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { billId, isCreditNote, isFetching } = useBillForm()
  const { setValue, FormItem, getValues } = useFormContext<BillFormSchema>()

  const [isEditing, setIsEditing] = useState(false)

  const isBillNumberEditable = Boolean(organization?.hasBillVoucherNo)
  const { nextVoucherNumber, isLoading } = useNextVoucherNumber()

  useEffect(() => {
    if (!isFetching && !isLoading && nextVoucherNumber && !getValues('voucherNo')) {
      setValue('voucherNo', nextVoucherNumber.toString())
    }
  }, [nextVoucherNumber, billId, setValue, getValues, isFetching, isLoading])

  const handleEditClick = useCallback(() => {
    setIsEditing(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsEditing(false)
  }, [])

  const billTypeLabel = isCreditNote ? t('credit_note') : t('bill')

  if (!organization || isLoading || isFetching) {
    return <SkeletonText variant="h1" />
  }

  return (
    <FormItem
      name="voucherNo"
      render={({ field: { value, onChange } }) => (
        <>
          {isEditing ? (
            <Input
              autoFocus
              value={value}
              onChange={onChange}
              onBlur={handleInputBlur}
              prefix={
                <Text color={Color.Gray60} truncate>
                  {billTypeLabel}
                </Text>
              }
            />
          ) : (
            <Styled.TextWrapper>
              <Styled.TextContainer>
                <Text variant="h3" as="span" title={value ? String(value) : undefined} truncate>
                  {billTypeLabel} {value}
                </Text>
              </Styled.TextContainer>

              {isBillNumberEditable && <IconButton icon="paperWithPencil" onClick={handleEditClick} />}
            </Styled.TextWrapper>
          )}
        </>
      )}
    />
  )
}
