import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { StyledComponent } from '../../../types/styledComponent'
import { Size } from '../types/size'
import { sizeToProperties } from './sizeToProperties'

const SIDES_OFFSET = 4

export const getCheckedStyle = (Control: StyledComponent, size: Size): SerializedStyles => css`
  ${Control} {
    background-color: ${Color.Green};

    &:before {
      transform: translateX(${sizeToProperties[size].width - sizeToProperties[size].knobSize - SIDES_OFFSET}px);
    }
  }
`
