import React from 'react'
import { Box } from 'rebass'

import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconPieChart2 = ({ color = 'inherit', color2, width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-pie-chart-2')} color={color} {...rest}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      stroke={color2}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
      <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
    </svg>
  </Box>
)
