import produce from 'immer'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import { Account } from '../app/accounts/types'
import {
  ACCOUNTS_RECEIVED,
  FILE_RECEIVED,
  TAX_RATES_RECEIVED,
  VOUCHER_ACTION_CUSTOM_REQUEST,
  VOUCHER_ACTION_RECIEVED,
  VOUCHER_ACTION_REQUEST,
  VOUCHER_ACTION_REQUEST_FAILED,
  VOUCHERS_NAVIGATION_RECEIVED,
  VOUCHERS_WITH_NEED_INFO_RECEIVED,
} from './actions'
import {
  GetAmountOfVouchersWithNeedInfoFilterReceived,
  Pagination,
  RequestStatus,
  TaxRate,
  Voucher,
  VoucherAction,
  VoucherActionReceivedType,
  VoucherActionRequestFailedType,
  VoucherActionRequestType,
  VoucherFile,
  VoucherNavigationReceived,
} from './types'

type VoucherList = {
  isLoading: boolean
  vouchers: Voucher[]
  pagination: Pagination
}

const defaultVoucherList: VoucherList = {
  isLoading: true,
  vouchers: [],
  pagination: {
    limit: 100,
    offset: 0,
    total: 0,
  },
}

export type VoucherActionError = {
  [voucherId: string]: Partial<Record<VoucherAction, Error>>
}

export type VouchersActionStatus = {
  [voucherId: string]: Partial<Record<VoucherAction, RequestStatus>>
}

export type VoucherListError = {
  [organizationId: string]: Error
}

export type VouchersListStatus = {
  [organizationId: string]: RequestStatus
}

export type VoucherNavigationIds = {
  [organizationId: string]: string[]
}

export type InboxState = {
  text: string
  taxRates: TaxRate[]
  accounts: Account[]
  file?: VoucherFile
  needInfoVouchersAmount: number
  voucherList: VoucherList
  voucherListError: VoucherListError
  voucherListStatus: VouchersListStatus
  voucherActionError: VoucherActionError
  voucherActionStatus: VouchersActionStatus
  voucherNavigationIds: VoucherNavigationIds
}

const initialState: InboxState = {
  text: '',
  taxRates: [],
  accounts: [],
  needInfoVouchersAmount: 0,
  voucherList: defaultVoucherList,
  voucherListError: {},
  voucherListStatus: {},
  voucherActionError: {},
  voucherActionStatus: {},
  voucherNavigationIds: {},
}

const reducer = (state: InboxState = initialState, { type, payload }: any): InboxState => {
  switch (type) {
    case TAX_RATES_RECEIVED:
      return produce(state, (draftState) => {
        draftState.taxRates = payload
      })
    case ACCOUNTS_RECEIVED:
      return produce(state, (draftState) => {
        draftState.accounts = payload
      })
    case VOUCHER_ACTION_REQUEST:
    case VOUCHER_ACTION_CUSTOM_REQUEST:
      return produce(state, (draftState) => {
        const { action, voucherId } = payload as VoucherActionRequestType
        const currentVoucherStatus = draftState.voucherActionStatus[voucherId] || {}
        const currentVoucherError = draftState.voucherActionError[voucherId]

        draftState.voucherActionStatus[voucherId] = {
          ...currentVoucherStatus,
          ...{
            [action]: RequestStatus.Processing,
          },
        }

        if (currentVoucherError) {
          delete currentVoucherError[action]

          if (isEmpty(currentVoucherError)) {
            const voucherActionError = omit(draftState.voucherActionError, voucherId)
            draftState.voucherActionError = { ...voucherActionError }
          } else {
            draftState.voucherActionError[voucherId] = { ...currentVoucherError }
          }
        }
      })
    case VOUCHER_ACTION_RECIEVED:
      return produce(state, (draftState) => {
        const { action, voucherId } = payload as VoucherActionReceivedType
        const currentVoucherStatus = draftState.voucherActionStatus[voucherId] || {}

        if (action) {
          draftState.voucherActionStatus[voucherId] = {
            ...currentVoucherStatus,
            ...{
              [action]: RequestStatus.Success,
            },
          }
        }
      })
    case VOUCHER_ACTION_REQUEST_FAILED:
      return produce(state, (draftState) => {
        const { action, voucherId, error } = payload as VoucherActionRequestFailedType
        const currentVoucherStatus = draftState.voucherActionStatus[voucherId] || {}
        const currentVoucherError = draftState.voucherActionError[voucherId] || {}

        draftState.voucherActionStatus[voucherId] = {
          ...currentVoucherStatus,
          ...{
            [action]: RequestStatus.Failed,
          },
        }

        draftState.voucherActionError[voucherId] = {
          ...currentVoucherError,
          ...{
            [action]: error,
          },
        }
      })
    case FILE_RECEIVED:
      return produce(state, (draftState) => {
        draftState.file = payload
      })
    case VOUCHERS_NAVIGATION_RECEIVED:
      return produce(state, (draftState) => {
        const { organizationId, vouchersIds } = payload as VoucherNavigationReceived
        draftState.voucherNavigationIds[organizationId] = vouchersIds
      })
    case VOUCHERS_WITH_NEED_INFO_RECEIVED:
      return produce(state, (draftState) => {
        const { amount } = payload as GetAmountOfVouchersWithNeedInfoFilterReceived

        draftState.needInfoVouchersAmount = amount
      })
    default:
      return state
  }
}

export default reducer
