import { Input, SkeletonBox, Text, Tooltip } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../../../hooks'
import { useEInvoiceContext } from '../../../../context/eInvoiceContext'
import { Identifier } from '../../../../enums/identifier'
import { SendEInvoiceFormSchema } from '../../../../utils/eInvoiceFormData'
import { CVR_LENGTH } from '../../constants/cvrLength'

export const CvrFormItem = (): ReactElement | null => {
  const { t } = useTranslation()
  const { control, FormItem } = useFormContext<SendEInvoiceFormSchema>()
  const { isIdentifierVerified, isIdentifierChecked, isIdentifierLoading } = useEInvoiceContext()

  const identifierType = useWatch({ control, name: 'identifierType' })
  const cvrInput = useWatch({ control, name: 'cvr' })

  const cvrInputMessage = useMemo(() => {
    if (cvrInput !== undefined && cvrInput?.length !== CVR_LENGTH) {
      return (
        <Text variant="small" colorVariant="danger">
          {t('modal.send_invoice.form.cvr.invalid')}
        </Text>
      )
    }

    if (cvrInput !== undefined && !isIdentifierVerified && isIdentifierChecked) {
      return (
        <Text variant="small" colorVariant="danger">
          {t('modal.send_invoice.form.cvr.cannot_receive')}
        </Text>
      )
    }
  }, [isIdentifierVerified, cvrInput, t, isIdentifierChecked])

  if (identifierType === Identifier.GLN) {
    return null
  }

  if (isIdentifierLoading) {
    return <SkeletonBox height={40} fullWidth />
  }

  return (
    <FormItem
      name="cvr"
      extra={cvrInputMessage}
      render={({ field }) => (
        <Tooltip label={t('modal.send_invoice.form.identifier.tooltip')}>
          <Input
            {...field}
            disabled
            autoFocus
            success={isIdentifierVerified && isIdentifierChecked}
            error={!isIdentifierVerified && isIdentifierChecked}
          />
        </Tooltip>
      )}
    />
  )
}
