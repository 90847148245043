import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconView = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-view')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={coloring(color)}
        d="M31,28c0,1.657-1.343,3-3,3H4c-1.657,0-3-1.343-3-3V4c0-1.657,1.343-3,3-3h24c1.657,0,3,1.343,3,3V28z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M28,32H4c-2.206,0-4-1.794-4-4V4c0-2.206,1.794-4,4-4h24c2.206,0,4,1.794,4,4v24C32,30.206,30.206,32,28,32z M4,2C2.897,2,2,2.897,2,4v24c0,1.103,0.897,2,2,2h24c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H4z"
      ></path>
      <path fill={coloring(color2 || color)} d="M31,11V4c0-1.657-1.343-3-3-3H4C2.343,1,1,2.343,1,4v7H31z"></path>
      <path
        fill={coloring(bg)}
        d="M32,12H0V4c0-2.206,1.794-4,4-4h24c2.206,0,4,1.794,4,4V12z M2,10h28V4c0-1.103-0.897-2-2-2H4 C2.897,2,2,2.897,2,4V10z"
      ></path>
    </svg>
  </Box>
)
