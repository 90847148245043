import { Button, ButtonsGroup, Color, DefaultValue, FileButton } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import download from 'downloadjs'
import omit from 'lodash/omit'
import React, { Fragment, memo, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoxProps, Flex, FlexProps, Text } from 'rebass'

import { useMeasureDirty } from '../../../hooks/useMeasureDirty'
import { Direction, usePagination } from '../../../hooks/usePagination'
import { Themable } from '../../../types/themable'
import { checkPropsEquality } from '../../../utils/checkPropsEquality'
import { getImageClassName } from '../../../utils/getClassName'
import { getFileName } from '../../../utils/getFileName'
import { getPathFromUrl } from '../../../utils/getPathFromUrl'
import { FilesPagination } from '../FilesPagination'
import { ImagePreview } from '../ImagePreview'
import { LazyPreview, LazyPreviewOptions } from '../LazyPreview'
import { PdfReact } from '../PdfReact'
import { FileType, FitType, NavigationDirection, PreviewFile } from '../types'
import { getFileType } from '../utils/getFileType'

interface StickyWrapperProps {
  fitToHeight?: boolean
  clickable?: boolean
  fitType?: FitType
}

const ActionsWrapper = styled(ButtonsGroup)`
  position: absolute;
  top: 10px;
  right: 10px;
`

interface SectionWrapperProps extends FlexProps {
  fitToHeight?: boolean
  bordered?: boolean
}

const SectionWrapper = styled(Flex)<SectionWrapperProps>`
  ${({ fitToHeight }) => {
    if (fitToHeight) {
      return css`
        height: 100%;
      `
    }

    return css`
      max-height: 100%;
    `
  }}

  ${({ bordered }) => {
    if (bordered) {
      return css`
        border: 1px solid ${Color.Gray50};
        border-radius: ${DefaultValue.BorderRadius};
      `
    }
  }}

  position: relative;
  overflow: hidden;
`

const StickyWrapper = styled.div<StickyWrapperProps>`
  display: flex;
  width: 100%;
  overflow-x: hidden;
  overflow-y: ${({ fitToHeight, fitType }) => (fitToHeight || fitType === 'cover' ? 'hidden' : 'auto')};
  ${({ clickable }) => clickable && 'cursor: pointer'};
  ${({ fitToHeight }) => fitToHeight && 'justify-content: center'};
`

type FileNameWrapperProps = FlexProps & Themable

const FILE_NAME_WRAPPER_POSITION = '30px'

const FileNameWrapper = styled(Flex)<FileNameWrapperProps>`
  max-width: 80%;
  height: 30px;
  border-radius: 2px;
  padding: 4px 10px 2px;
  align-items: center;
  position: absolute;
  bottom: ${FILE_NAME_WRAPPER_POSITION};
  right: ${FILE_NAME_WRAPPER_POSITION};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => `0 1px 5px ${theme.colors.shade20}`};
`

const FileName = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const formatToComponent = {
  [FileType.Image]: ImagePreview,
  [FileType.Pdf]: PdfReact,
}

export type FilesPreviewContentProps = BoxProps & {
  asType?: FileType
  bordered?: boolean
  circle?: boolean
  files: PreviewFile[]
  fitToHeight?: boolean
  fitToWidth?: boolean
  fitType?: FitType // works only when both fitToHeight and fitToWidth are set to 'true'
  lazyLoadOptions?: LazyPreviewOptions
  loading?: ReactElement | string
  navigationDirection?: NavigationDirection
  onDelete?: (file: PreviewFile) => void
  onEdit?: (file: File) => void
  onError?: () => void
  onImageZoomClick?: () => void
  onLoad?: () => void
  usePdfImageProxy?: boolean
  withDeleteButton?: boolean
  withDownloadButton?: boolean
  withEditButton?: boolean
  withFilename?: boolean
  withLazyLoad?: boolean
  withModalZoom?: boolean
  withNavigation?: boolean
  withRotate?: boolean
  zoomable?: boolean
  zoomScale?: number
}

type RotatedFiles = Record<string, number>

const ROTATION_DEFAULT = 0
const ROTATION_STEP = 90
const ROTATION_FULL = 360

export const FilesPreviewContent = memo(
  ({
    asType,
    bordered,
    circle = false,
    files,
    fitToHeight = false,
    fitToWidth = false,
    fitType = 'contain',
    lazyLoadOptions,
    loading,
    navigationDirection = 'horizontal',
    onDelete,
    onEdit,
    onError,
    onLoad,
    onImageZoomClick,
    withDeleteButton = false,
    withDownloadButton,
    withEditButton = false,
    withFilename = false,
    withModalZoom = false,
    withLazyLoad = true,
    withNavigation = true,
    withRotate = false,
    zoomable,
    zoomScale = DefaultValue.ImageZoomScale,
    ...rest
  }: FilesPreviewContentProps): ReactElement | null => {
    const {
      activePage: activeFilePage,
      prevActivePage: prevActiveFilePage,
      canChangePage: canChangeFilePage,
      changePage: changeFilePage,
      setPagesCount: setFilePagesCount,
      pagesCount: filePagesCount,
    } = usePagination()
    const {
      activePage: activePdfPage,
      canChangePage: canChangePdfPage,
      changePage: changePdfPage,
      isFirstPage: isFirstPdfPage,
      isLastPage: isLastPdfPage,
      setPage: setPdfPage,
      setPagesCount: setPdfPagesCount,
      pagesCount: pdfPagesCount,
    } = usePagination()
    const { t } = useTranslation()
    const [isLoading, setLoading] = useState(true)
    const [rotatedFiles, setRotatedFiles] = useState<RotatedFiles>()
    const componentRef = useRef<HTMLDivElement>(null)
    const { width: componentWidth = 0, height: componentHeight = 0 } = useMeasureDirty(componentRef)

    if (!fitToHeight && !fitToWidth) {
      console.error('You have to define fit mode!')
    }

    useEffect(() => {
      setFilePagesCount(files.length)

      if (files.length && !files[activeFilePage]) {
        changeFilePage(activeFilePage > 0 ? Direction.Back : Direction.Next)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files])

    const activeFile = files[activeFilePage]

    if (!files.length || !activeFile) {
      return null
    }

    const activeFileType = asType || getFileType(activeFile.srcZoom || activeFile.src)
    const isPdf = activeFileType === 'pdf'

    const getActivePageNumber = () => {
      if (files.length > 1) {
        return activeFilePage + 1
      }

      return (isPdf ? activePdfPage : activeFilePage) + 1
    }

    const getAmountOfPages = () => {
      if (files.length > 1) {
        return files.length
      }

      return isPdf ? pdfPagesCount : filePagesCount
    }

    const FileComponent = formatToComponent[activeFileType]
    const FileComponentContainer = withLazyLoad ? LazyPreview : Fragment
    const fileComponentContainerOptions = withLazyLoad ? { options: lazyLoadOptions } : {}
    const pagesAmount = getAmountOfPages()
    const shouldRenderNavigation = withNavigation && pagesAmount > 1 && !isLoading
    const shouldRenderRotateButton = activeFileType === FileType.Image && withRotate
    const shouldRenderActions = withNavigation || withRotate || withModalZoom

    const shouldHandleFilePage = (direction: Direction) =>
      activeFileType === FileType.Image ||
      (isLastPdfPage() && direction === Direction.Next) ||
      (isFirstPdfPage() && direction === Direction.Back)

    const handleNavButtonClick = (direction: Direction) => {
      if (shouldHandleFilePage(direction)) {
        changeFilePage(direction)
        return
      }

      changePdfPage(direction)
    }

    const handleCanNavButtonClick = (direction: Direction) => {
      if (shouldHandleFilePage(direction)) {
        return canChangeFilePage(direction)
      }

      return canChangePdfPage(direction)
    }

    const onPdfLoad = (pagesCount: number) => {
      setPdfPagesCount(pagesCount)

      // Sets correct pdf initial page
      // (on "go back" action we have to set initially the last page)
      if (prevActiveFilePage > activeFilePage) {
        setPdfPage(pdfPagesCount - 1)
      } else {
        setPdfPage(0)
      }
    }

    const handleLoad = () => {
      setLoading(false)
      onLoad?.()
    }

    const handleDownloadClick = () => {
      const src = activeFile.srcDownload || activeFile.srcZoom || activeFile.src
      const url = new URL(src)
      let href = url.href

      // force get the src with different url to omit cached one
      href += url.search ? '&' : '?'

      download(href)
    }

    const changeRotation = (src: string, rotation: number) => {
      const value = rotation + ROTATION_STEP

      if (value === ROTATION_FULL && rotatedFiles?.[src]) {
        setRotatedFiles(omit(rotatedFiles, src))
      } else {
        setRotatedFiles({
          ...rotatedFiles,
          [src]: value,
        })
      }
    }

    const handleRotateClick = () => {
      const { src } = activeFile
      const rotation = rotatedFiles?.[src] || ROTATION_DEFAULT

      changeRotation(src, rotation)
    }

    const renderFileName = () => {
      const fileName = getFileName(activeFile.src)

      if (isLoading) {
        return null
      }

      return (
        <FileNameWrapper title={fileName}>
          <FileName>{getFileName(activeFile.src)}</FileName>
        </FileNameWrapper>
      )
    }

    const handleClick = () => {
      if (withModalZoom && onImageZoomClick) {
        onImageZoomClick()
      }
    }

    const handleDeleteClick = () => {
      onDelete?.(activeFile)
    }

    const handleFileSelect = (files: File[]) => {
      onEdit?.(files[0])
    }

    return (
      <SectionWrapper
        as="section"
        ref={componentRef}
        key={activeFile.src}
        fitToHeight={fitToHeight}
        bordered={bordered}
        {...rest}
      >
        <StickyWrapper
          fitToHeight={fitToHeight}
          onClick={handleClick}
          clickable={withModalZoom}
          className={getImageClassName()}
          fitType={fitType}
        >
          {shouldRenderNavigation && (
            <FilesPagination
              activePage={getActivePageNumber()}
              amountOfPages={getAmountOfPages()}
              canClickButton={handleCanNavButtonClick}
              onPageChange={handleNavButtonClick}
              navigationDirection={navigationDirection}
            />
          )}
          <FileComponentContainer {...fileComponentContainerOptions}>
            <FileComponent
              activePage={activePdfPage}
              alt={activeFile.alt}
              circle={circle}
              fitToHeight={fitToHeight}
              fitToWidth={fitToWidth}
              fitType={fitType}
              height={fitToHeight ? Math.round(componentHeight) : 0}
              loading={loading}
              onError={onError}
              onLoad={handleLoad}
              onPdfLoad={onPdfLoad}
              rotation={rotatedFiles?.[activeFile.src]}
              src={activeFile.src}
              srcZoom={activeFile.srcZoom}
              width={fitToWidth ? Math.round(componentWidth) : 0}
              zoomable={zoomable}
              zoomScale={zoomScale}
            />
          </FileComponentContainer>
          {withFilename && renderFileName()}
        </StickyWrapper>

        {shouldRenderActions && (
          <ActionsWrapper>
            {shouldRenderRotateButton && (
              <Button
                icon="rightArrowAroundSquare"
                onClick={handleRotateClick}
                size="m"
                title={t('rotate')}
                variant="secondary"
              />
            )}
            {withDownloadButton && (
              <Button
                icon="arrowPointingDown"
                onClick={handleDownloadClick}
                size="m"
                title={t('download')}
                variant="secondary"
              />
            )}
            {withEditButton && (
              <FileButton
                icon="paperWithPencil"
                size="m"
                title={t('edit')}
                variant="secondary"
                onFilesSelect={handleFileSelect}
              />
            )}
            {withModalZoom && (
              <Button
                icon="magnifyingGlassPlus"
                onClick={onImageZoomClick}
                size="m"
                title={t('full_screen')}
                variant="secondary"
              />
            )}
            {withDeleteButton && (
              <Button icon="trash" onClick={handleDeleteClick} size="m" title={t('delete')} variant="secondary" />
            )}
          </ActionsWrapper>
        )}
      </SectionWrapper>
    )
  },
  arePropsEqual,
)

function checkFilesEquality(filesPrev: PreviewFile[], filesNext: PreviewFile[]) {
  if (filesPrev.length !== filesNext.length) {
    return false
  }

  for (let i = 0; i < filesPrev.length; i++) {
    const prevSrcPath = getPathFromUrl(filesPrev[i].src)
    const nextSrcPath = getPathFromUrl(filesNext[i].src)

    if (prevSrcPath !== nextSrcPath) {
      return false
    }
  }

  return true
}

function arePropsEqual(prevProps: Readonly<FilesPreviewContentProps>, nextProps: Readonly<FilesPreviewContentProps>) {
  const areFilesEqual = checkFilesEquality(prevProps.files, nextProps.files)
  const isRestEqual = checkPropsEquality(prevProps, nextProps, [
    'asType',
    'loading',
    'withDownloadButton',
    'fitToHeight',
    'fitToWidth',
    'withFilename',
    'withModalZoom',
    'withLazyLoad',
    'withNavigation',
    'withRotate',
  ])

  return areFilesEqual && isRestEqual
}
