import { Button, ButtonsGroup, Checkbox, Link, Modal, ModalProps, Space, Text, useModal } from '@design-system'

import { ReactElement, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { getImageClassName } from '../../../utils/getClassName'
import { getImgPath } from '../../../utils/getImgPath'
import * as Styled from './styles'

export interface AccountingPackageModalProps extends ModalProps {
  onAcceptButtonClick: () => void
}

const requirementsTranslationKeys = [
  'accounting_package_modal.requirements.reconciled_intermediate_account',
  'accounting_package_modal.requirements.reconciled_bank_account',
]

const exclusionsTranslationKeys = [
  'accounting_package_modal.exclusions.audit_obligation',
  'accounting_package_modal.exclusions.compulsory_liquidation',
  'accounting_package_modal.exclusions.association',
]

export const AccountingPackageModal = ({
  onAcceptButtonClick,
  ...modalProps
}: AccountingPackageModalProps): ReactElement => {
  const { t } = useTranslation()
  const [termsAccepted, setTermsAccepted] = useState(false)
  const { close: closeAccountingPackageModal } = useModal(modalProps.id)

  const handleDeclineButtonClick = useCallback(() => {
    closeAccountingPackageModal()
  }, [closeAccountingPackageModal])

  const handleAcceptButtonClick = useCallback(() => {
    onAcceptButtonClick?.()
  }, [onAcceptButtonClick])

  const handleAcceptTermsCheckboxChange = useCallback(() => {
    setTermsAccepted((currentValue) => !currentValue)
  }, [])

  return (
    <Modal {...modalProps} size="s" closable>
      <Modal.Header>
        <Text variant="h2">{t('accounting_package_modal.heading')}</Text>
      </Modal.Header>
      <Modal.Body>
        <Styled.ImageWrapper className={getImageClassName()}>
          <img
            src={getImgPath('/modals/multi-task-woman-illustration.svg')}
            width="177"
            height="169"
            alt="multi task woman"
          />
        </Styled.ImageWrapper>
        <Space size="xl" />
        <Text variant="large">{t('accounting_package_modal.requirements.heading')}</Text>
        <Styled.FeaturesList>
          {requirementsTranslationKeys.map((translationKey) => (
            <li key={translationKey}>
              <Text variant="large" inline>
                {t(translationKey)}
              </Text>
            </li>
          ))}
        </Styled.FeaturesList>
        <Space size="xl" />
        <Text variant="large">{t('accounting_package_modal.exclusions.heading')}</Text>
        <Styled.FeaturesList>
          {exclusionsTranslationKeys.map((translationKey) => (
            <li key={translationKey}>
              <Text variant="large" inline>
                {t(translationKey)}
              </Text>
            </li>
          ))}
        </Styled.FeaturesList>
        <Space size="xl" />
        <Text variant="large">{t('accounting_package_modal.questions')}</Text>
        <Space size="xl" />
        <Checkbox checked={termsAccepted} onChange={handleAcceptTermsCheckboxChange}>
          <Trans i18nKey="accounting_package_modal.terms">
            I accept Ageras and partners
            <Link
              href="https://billy.dk/betingelser-revisorpakke/"
              target="_blank"
              rel="noopener noreferrer"
              underlined
            >
              terms and conditions
            </Link>
          </Trans>
        </Checkbox>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={handleDeclineButtonClick}>
            {t('accounting_package_modal.decline_cta')}
          </Button>
          <Button variant="primary" onClick={handleAcceptButtonClick} disabled={!termsAccepted}>
            {t('accounting_package_modal.accept_cta')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
