import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconArrowDown = ({ color = 'inherit', width = '20px', height = '20px', rotate, ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-arrow-down')} {...rest}>
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384.2 205.03"
      style={{
        transition: 'transform 0.3s',
        transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
    >
      <path
        d="M367.2,17l-168,168.1a10,10,0,0,1-14.1,0L17,17"
        fill="none"
        stroke={coloring(color)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="52"
      />
    </svg>
  </Box>
)
