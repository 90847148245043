import { css } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, BoxProps, Flex } from 'rebass'

import { IconAdd, PaymentIcon } from '../Icons'

const BRANDS_MAP = {
  VISA: 'IconVisa',
  MASTERCARD: 'IconMasterCard',
  'AMERICAN EXPRESS': 'IconAmex',
  PAYPAL: 'IconPayPal',
}

export type DotsProps = BoxProps & {
  num?: number
}

export const Dots = ({ num = 4, ...rest }: DotsProps) => (
  <Box
    css={css`
      display: inline-block;
      letter-spacing: 0.1em;
      margin-right: 5px;
      font-size: 19px;
    `}
    {...rest}
  >
    {Array(num).fill('·').join('')}
  </Box>
)

type EmptyStripeCardProps = BoxProps & {
  addCard?: () => void
}

export const EmptyStripeCard = ({ addCard }: EmptyStripeCardProps) => {
  const { t } = useTranslation()
  return (
    <Flex variant="paymentCard" width={240} height={150} alignItems="center" justifyContent="center" color="primary">
      <Box
        onClick={addCard}
        fontSize={14}
        css={css`
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        `}
      >
        {t('stripe.addCard')}
      </Box>
      <IconAdd width={12} height={12} ml={1} display="inherit" />
    </Flex>
  )
}

export const StripeCard = ({ data, addCard }: any) => {
  if (!data) {
    return <EmptyStripeCard addCard={addCard} />
  }

  const expirationMonth = data?.card?.expMonth < 10 ? `0${data?.card?.expMonth}` : data?.card?.expMonth
  return (
    <Box
      variant="paymentCard"
      css={css`
        font-weight: 500;
      `}
    >
      <PaymentIcon type={BRANDS_MAP[data?.card?.brand.toUpperCase()]} width={78} height={48} ml={-10} />
      <Box mt={11}>
        <Dots />
        <Dots />
        <Dots />
        {data?.card?.last4}
      </Box>
      <Box mt={10}>
        {expirationMonth}/{data?.card?.expYear.toString().slice(2)}
      </Box>
    </Box>
  )
}
