import React, { cloneElement, isValidElement, ReactNode, useMemo } from 'react'

import { Color } from '../../../../enums/color'
import { Text, TextWeight } from '../../../Text'
import { NavListItemChildren } from '../../elements/NavListItem'
import { NavItemDisplayableValue } from '../../types/navItem'
import { NavItemRenderProps } from '../../types/navItemRenderProps'
import * as Styled from './styles'

interface DisplayDataBlock {
  description?: string | string[]
  suffix?: ReactNode
  title: ReactNode
}

export type NavListItemWithBlockDescriptionValue = NavItemDisplayableValue<DisplayDataBlock>
type NavListItemWithBlockDescriptionProps<T> = NavItemRenderProps<T>

export const NavListItemWithBlockDescription = <T extends NavListItemWithBlockDescriptionValue>(
  props: NavListItemWithBlockDescriptionProps<T>,
) => {
  return (
    <Styled.NavListItemWithBlockDescription {...props}>
      {/* This is decorated with textColor and textSecondaryColor in "NavListItem.tsx": */}
      <CustomText {...props} />
    </Styled.NavListItemWithBlockDescription>
  )
}

type CustomTextProps<T> = NavListItemChildren<T>

const CustomText = <T extends NavListItemWithBlockDescriptionValue>({
  item,
  textColor,
  textSecondaryColor,
}: CustomTextProps<T>) => {
  const {
    value: {
      displayData: { description, suffix, title },
    },
  } = item

  const descriptionItems = useMemo((): string[] => {
    const descriptionToArray = typeof description === 'string' ? [description] : description

    return descriptionToArray || []
  }, [description])

  return (
    <Styled.ContentWrapper>
      <Styled.Content>
        <Styled.TextWrapper>
          {typeof title === 'string' ? (
            <Text color={textColor} title={title} truncate weight="medium">
              {title}
            </Text>
          ) : (
            isValidElement<{ color?: Color; weight?: TextWeight }>(title) &&
            cloneElement(title, {
              color: textColor,
              weight: 'medium',
            })
          )}
        </Styled.TextWrapper>
        {!!descriptionItems.length &&
          descriptionItems.map((description, index) => (
            <Text key={`${item.id}-${index}`} color={textSecondaryColor} variant="micro">
              {description}
            </Text>
          ))}
      </Styled.Content>
      {!!suffix && <Styled.SuffixWrapper>{suffix}</Styled.SuffixWrapper>}
    </Styled.ContentWrapper>
  )
}
