import { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { BankLinesSortingProperty } from '../elements/BankLinesModalBody/elements/BankLinesActions/types/bankLinesSortingProperty'
import { useBankLines } from './bankLinesContext'
import { useBankLinesFilters } from './bankLinesFilteringContext'
import { useBillReconciliation } from './billReconciliationContext'

interface ContextState {
  resetValues: () => void
}

const ModalContentsOperationsContext = createContext<ContextState | undefined>(undefined)

interface ModalContentsOperationsContextContextProps {
  children?: ReactNode
}

export const ModalContentsOperationsContextProvider = ({
  children,
}: ModalContentsOperationsContextContextProps): ReactElement => {
  const { setSelectedAccountId, setSelectedDifferenceType } = useBillReconciliation()
  const { setBankLinesSearchQuery, setBankLinesSortingProperty } = useBankLinesFilters()
  const { setSelectedBankLinesIds } = useBankLines()

  const resetValues = useCallback(() => {
    setBankLinesSearchQuery(undefined)
    setBankLinesSortingProperty(BankLinesSortingProperty.AmountDifference)
    setSelectedAccountId('')
    setSelectedBankLinesIds([])
    setSelectedDifferenceType(undefined)
  }, [
    setBankLinesSearchQuery,
    setBankLinesSortingProperty,
    setSelectedAccountId,
    setSelectedBankLinesIds,
    setSelectedDifferenceType,
  ])

  return (
    <ModalContentsOperationsContext.Provider
      value={{
        resetValues,
      }}
    >
      {children}
    </ModalContentsOperationsContext.Provider>
  )
}

export const useModalContentsOperations = () => {
  const context = useContext(ModalContentsOperationsContext)

  if (!context) {
    throw new Error('ModalContentsOperationsContextProvider is missing in the module!')
  }

  return context
}
