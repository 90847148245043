import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'
import { alignmentToJustify } from '../../enums/alignmentToJustify'
import { TableAlignment } from '../../types/alignment'
import { getDynamicSizeColumnStyles } from '../../utils/getDynamicSizeColumnStyles'
import { getFixedSizeColumnStyles } from '../../utils/getFixedSizeColumnStyles'

export interface TableCellWrapperProps {
  alignment: TableAlignment
  clickable: boolean
  fixedSize?: boolean
  hidden?: boolean
}

export const TableCellWrapper = styled.div<TableCellWrapperProps>`
  display: flex;
  margin-right: ${Spacing.XL};
  justify-content: ${({ alignment }) => alignmentToJustify[alignment]};
  text-align: ${({ alignment }) => alignment};
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  ${({ clickable }) => !clickable && 'cursor: initial;'};
  ${({ fixedSize }) => (fixedSize ? getFixedSizeColumnStyles() : getDynamicSizeColumnStyles())};

  &:last-of-type {
    margin-right: 0;
  }
`
