import { ChangeEvent } from 'react'

import { NavItem, NavItemAccessor } from '../../NavList'
import { Value as NavListItemValue } from '../../NavList/formatters/NavListItemWithCheckbox'

type Value = Pick<NavListItemValue, 'checked' | 'name' | 'onChange'>

export const getMultipleDropdownItems = <T>(
  items: NavItem<T>[],
  selectedValues: T[],
  onChange: (event: ChangeEvent<HTMLInputElement>, value: T) => void,
  withSeparator?: boolean,
) => {
  const separatorItem: NavItem<Value>[] = withSeparator
    ? [
        {
          accessor: NavItemAccessor.Separator,
          id: '',
          children: '',
          value: {} as Value,
        },
      ]
    : []

  const navItems: NavItem<Value>[] = items.map(({ id, children, value, subItems, ...rest }) => ({
    ...rest,
    children,
    id,
    subItems: subItems ? getMultipleDropdownItems(subItems, selectedValues, onChange) : [],
    value: {
      checked: selectedValues.includes(value),
      name: id,
      onChange: (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event, value)
      },
    },
  }))

  return [...separatorItem, ...navItems]
}
