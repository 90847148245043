import React, {
  FocusEvent,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useKey } from 'react-use'

import { Button } from '../Button'
import { Icon } from '../Icon'
import { Input, InputProps, InputRef } from '../Input'
import * as Styled from './styles'

export interface SearchInputProps extends Omit<InputProps, 'prefix'> {
  onClose?: () => void
  onOpen?: () => void
}

export const SearchInput = forwardRef<InputRef, SearchInputProps>(
  (
    { focused, hidden, onBlur, onChange, onChangeDebounced, onClose, onOpen, placeholder, ...rest }: SearchInputProps,
    forwardedRef,
  ): ReactElement => {
    const searchInputRef = useRef<InputRef>(null)
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()

    useImperativeHandle(forwardedRef, () => searchInputRef?.current as InputRef)

    useEffect(() => {
      if (focused) {
        setIsOpen(true)
      }
    }, [focused])

    useEffect(() => {
      if (hidden) {
        setIsOpen(true)
      }
    }, [hidden])

    const handleButtonClick = useCallback(() => {
      onOpen?.()
      setIsOpen(true)
    }, [onOpen])

    const handleInputBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (!event.target.value) {
          onBlur?.(event)
          setIsOpen(false)
        }

        onClose?.()
      },
      [onBlur, onClose],
    )

    const handleEscapeKey = useCallback(
      (event: KeyboardEvent) => {
        const { target } = event
        const inputElement = searchInputRef.current?.input

        if (target === inputElement) {
          inputElement.blur()
        }
      },
      [searchInputRef],
    )

    useKey('Escape', handleEscapeKey)

    return (
      <Styled.SearchInputWrapper>
        {!isOpen && (
          <Button icon="magnifyingGlass" onClick={handleButtonClick} variant="text">
            {t('ds.search_input.button')}
          </Button>
        )}
        <Styled.InputWrapper visible={isOpen}>
          <Input
            {...rest}
            allowClear
            focused={isOpen}
            hidden={!isOpen}
            onBlur={handleInputBlur}
            onChange={onChange}
            onChangeDebounced={onChangeDebounced}
            placeholder={placeholder}
            prefix={<Icon icon="magnifyingGlass" />}
            ref={searchInputRef}
          />
        </Styled.InputWrapper>
      </Styled.SearchInputWrapper>
    )
  },
)
