import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { TransactionRoute } from '../../enums/transactionRoute'
import { TransactionsSideActions } from './elements/TransactionsSideActions'
import { TransactionsTable } from './elements/TransactionsTable'
import { TransactionsTableActions } from './elements/TransactionsTableActions'
import { useIsTransactionsListEmpty } from './hooks/useIsTransactionsListEmpty'

export const TransactionsList = (): ReactElement => {
  const { t } = useTranslation()
  const isTransactionsListEmpty = useIsTransactionsListEmpty()

  if (isTransactionsListEmpty) {
    return <Redirect to={TransactionRoute.ListEmpty} />
  }

  return (
    <ModuleLayout sideActions={<TransactionsSideActions />} title={t('transactions')}>
      <TransactionsTableActions />
      <TransactionsTable />
    </ModuleLayout>
  )
}
