import { SkeletonBox, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate } from '../../../../utils'

interface BankConnectionRenewalDateProps {
  isLoading: boolean
  date?: string
}
export const BankConnectionRenewalDate = ({ isLoading, date }: BankConnectionRenewalDateProps): ReactElement => {
  const { t } = useTranslation()

  if (isLoading) {
    return <SkeletonBox width={200} height={24} />
  }

  return (
    <Text variant="small">
      {t('bank_settings_modal.renew_again_by')}{' '}
      <Text inline weight="bold" as="span">
        {formatDate(date, 'MMM. d yyyy')}
      </Text>
    </Text>
  )
}
