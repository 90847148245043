import { Button, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const CouponCodeFormWrapper = styled.div`
  display: flex;
  margin-top: ${Spacing.M};
  flex-direction: column;
`

export const SubmitButton = styled(Button)`
  margin-top: ${Spacing.S};
`
