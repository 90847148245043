import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { BankLoadDataContextProvider, Financing } from '@views/financing'

import { queryClient } from '../../config/queryClient'
import { ThemeProvider } from '../../contexts/themeProvider'

class FinancingWebComponent extends DOMModel {
  @byAttrVal organizationId: string
}

type FinancingProps = {
  organizationId: string
}

function FinancingModule({ organizationId }: FinancingProps): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <CurrentUserContextProvider>
        <BankLoadDataContextProvider organizationId={organizationId}>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <ThemeProvider>
              <Financing />
            </ThemeProvider>
          </UserOrganizationContextProvider>
        </BankLoadDataContextProvider>
      </CurrentUserContextProvider>
    </QueryClientProvider>
  )
}

const FinancingCustomElement = createCustomElement(FinancingModule, FinancingWebComponent, 'element')

customElements.get('financing-module') || customElements.define('financing-module', FinancingCustomElement)

export default FinancingCustomElement
