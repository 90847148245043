import React, { ReactElement, ReactNode, useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { SUBMIT_SHORTCUT } from '../../../../../constants/shortcuts'
import { Button } from '../../../Button'
import { ButtonsGroup } from '../../../ButtonsGroup'
import { Text } from '../../../Text'
import { useModal } from '../../context/hooks/useModal'
import { Modal, ModalProps } from '../../Modal'

export interface ModalConfirmationProps extends Omit<ModalProps, 'closable' | 'children'> {
  cancelLabel?: string
  danger?: boolean
  message?: ReactNode
  okLabel?: string
  okLoading?: boolean
  onCancel?: () => void
  onClose?: () => void
  onOk?: () => void
  onOpen?: () => void
  title?: ReactNode
}

export const ModalConfirmation = ({
  cancelLabel,
  danger,
  id,
  message,
  okLabel,
  okLoading,
  onCancel,
  onClose,
  onOk,
  onOpen,
  title,
  ...rest
}: ModalConfirmationProps): ReactElement => {
  const { close, isOpen } = useModal(id, { onClose, onOpen })

  const handleCancelClick = useCallback(() => {
    onCancel?.()
    close()
  }, [close, onCancel])

  const handleOkClick = useCallback(() => {
    onOk?.()
  }, [onOk])

  useHotkeys(SUBMIT_SHORTCUT, handleOkClick, { enabled: isOpen, enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'] }, [
    isOpen,
    handleOkClick,
  ])

  return (
    <Modal id={id} size="s" closable {...rest}>
      {title && <Modal.Header title={title} />}
      {message && (
        <Modal.Body>
          <Text colorVariant="secondary">{message}</Text>
        </Modal.Body>
      )}
      <Modal.Footer>
        <ButtonsGroup>
          {cancelLabel && (
            <Button variant="text" onClick={handleCancelClick}>
              {cancelLabel}
            </Button>
          )}
          {okLabel && (
            <Button onClick={handleOkClick} danger={danger} loading={okLoading}>
              {okLabel}
            </Button>
          )}
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
