import React, {
  Children,
  cloneElement,
  forwardRef,
  ForwardRefExoticComponent,
  isValidElement,
  MouseEvent,
  ReactElement,
  ReactNode,
  RefAttributes,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import ReactDOM from 'react-dom'
import { useKey } from 'react-use'

import { PORTAL_CLASS_NAME } from '../../constants/portalClassName'
import { useComponentToggle } from '../../hooks/useComponentToggle'
import { transitionToMiliseconds } from '../../utils/transitionToMiliseconds'
import { isElementPartOfDropdown } from '../Dropdown'
import { MODAL_RENDER_TRANSITION_DURATION } from './constants/modalRenderTransitionDuration'
import { useModal } from './context/hooks/useModal'
import { ModalBody } from './elements/ModalBody'
import { ModalFooter } from './elements/ModalFooter'
import { ModalHeader } from './elements/ModalHeader'
import { ModalNavigation } from './elements/ModalNavigation'
import { useFocusOnFirstInput } from './hooks/useFocusOnFirstInput'
import * as Styled from './styles'
import { Size } from './types/size'

export interface ModalProps {
  autofocusOnInput?: boolean
  children?: ReactNode
  closable?: boolean
  forceRender?: boolean
  id: string
  size?: Size
  className?: string
}

type ModalType = ForwardRefExoticComponent<ModalProps & RefAttributes<HTMLDivElement>> & {
  Body: typeof ModalBody
  Footer: typeof ModalFooter
  Header: typeof ModalHeader
  Navigation: typeof ModalNavigation
}

export const Modal = forwardRef(
  (
    { autofocusOnInput, id, className, closable = true, children, forceRender, size = 'm' },
    forwardedRef,
  ): ReactElement => {
    const { isOpen, isLastOpened, close, open } = useModal(id)
    const { isVisible, isRendered } = useComponentToggle({
      isOpen,
      animationTime: transitionToMiliseconds(MODAL_RENDER_TRANSITION_DURATION),
    })
    const modalRef = useRef<HTMLDivElement>(null)

    useFocusOnFirstInput(modalRef.current, autofocusOnInput)

    useEffect(() => {
      if (forceRender) {
        open()
      }
    }, [forceRender, open])

    const handleWrapperClick = useCallback(
      (event: MouseEvent<HTMLElement>) => {
        const clickedElement = event.target as HTMLElement
        const clickedInModal = modalRef.current?.contains(clickedElement)

        if (isLastOpened && closable && !clickedInModal && !isElementPartOfDropdown(clickedElement)) {
          close()
        }
      },
      [closable, close, isLastOpened],
    )

    const handleEscapePress = useCallback(() => {
      if (closable && isLastOpened) {
        close()
      }
    }, [closable, close, isLastOpened])

    useKey('Escape', handleEscapePress, { target: window }, [closable, isLastOpened, close])

    return ReactDOM.createPortal(
      <>
        {isRendered && (
          <>
            <Styled.ModalWrapper
              ref={forwardedRef}
              className={PORTAL_CLASS_NAME}
              onClick={handleWrapperClick}
              withOverlay={isVisible && isLastOpened}
            >
              <Styled.Modal ref={modalRef} width={size} visible={isVisible} className={className}>
                {Children.map(children, (child) => {
                  if (isValidElement<Pick<ModalProps, 'id' | 'closable'>>(child)) {
                    return cloneElement(child, { id, closable })
                  }

                  return child
                })}
              </Styled.Modal>
            </Styled.ModalWrapper>
          </>
        )}
      </>,
      document.body,
    )
  },
) as ModalType

Modal.Header = ModalHeader
Modal.Navigation = ModalNavigation
Modal.Body = ModalBody
Modal.Footer = ModalFooter
