import { amountToDisplayValue } from './amountToDisplayValue'

export interface DisplayValueSplitted {
  value: string
  decimalSeparator: string
  decimalValue: string
}

export const amountToDisplayValueSplitted = (amount: string | number): DisplayValueSplitted => {
  const valueFormatted = amountToDisplayValue(amount)
  const [, decimalSeparator, decimalValue] = valueFormatted.match(/([.,])(\d*)$/) || []
  const [value] = valueFormatted.split(decimalSeparator)

  return {
    value,
    decimalSeparator,
    decimalValue,
  }
}
