import { Color, DefaultValue, IconButton, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const FinancingApplicationWrapper = styled.div`
  margin-bottom: ${Spacing.L};
  width: 100%;
  border-radius: ${DefaultValue.BorderRadius};
  padding: ${Spacing.L} ${Spacing.XL};
  background-color: ${Color.White};
  box-shadow: 0 2px 1px ${Color.Gray30};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: ${Spacing.S};
  right: ${Spacing.S};
`

export const Content = styled.div`
  margin-right: ${Spacing.XL};
`
