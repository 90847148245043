import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { DefaultValue } from '../../enums/defaultValue'
import { FontSize } from '../../enums/fontSize'
import { LineHeight } from '../../enums/lineHeight'
import { Asable } from '../../types/asable'
import { Themable } from '../../types/themable'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { getTruncateStyle } from '../../utils/getTruncateStyle'
import { Alignment } from './types/alignment'
import { Weight } from './types/weight'
import { weightToValue } from './utils/weightToValue'

interface TextProps extends Asable, Themable {
  alignment?: Alignment
  fontFamily?: string
  inherit?: boolean
  inheritColor?: boolean
  inline?: boolean
  lowercase?: boolean
  nowrap?: boolean
  selectable?: boolean
  textColor?: Color
  truncate?: boolean
  uppercase?: boolean
  weight?: Weight
}

const inlineStyle = css`
  display: inline;
`

const noWrapStyle = css`
  white-space: nowrap;
`

const uppercaseStyle = css`
  text-transform: uppercase;
`

const lowercaseStyle = css`
  text-transform: lowercase;
`

const getCommonStyles = ({
  alignment = 'inherit',
  fontFamily,
  inherit,
  inheritColor,
  inline,
  lowercase,
  nowrap,
  selectable,
  textColor,
  truncate,
  uppercase,
  weight,
}: TextProps) => css`
  display: block;
  color: ${(inherit || inheritColor) && !textColor ? 'inherit' : textColor};
  font-family: ${inherit && !fontFamily ? 'inherit' : fontFamily || DefaultValue.FontFamily};
  font-weight: ${inherit && !weight ? 'inherit' : weightToValue[weight || 'regular']};
  text-align: ${inherit && !alignment ? 'inherit' : alignment};
  user-select: ${selectable ? 'auto' : 'none'};
  ${inherit ? 'font-size: inherit' : ''};
  ${!inherit && !inheritColor && getDefaultTransition('color')};

  ${nowrap && noWrapStyle};
  ${inline && inlineStyle};
  ${uppercase && uppercaseStyle};
  ${lowercase && lowercaseStyle};
  ${truncate && getTruncateStyle()};
`

export const TextH1 = styled.h1<TextProps>`
  font-size: ${FontSize.TextH1};
  line-height: ${LineHeight.TextH1};
  letter-spacing: -0.015em;
  ${({ weight = 'medium', ...restProps }) => getCommonStyles({ weight, ...restProps })}
`

export const TextH2 = styled.h2<TextProps>`
  font-size: ${FontSize.TextH2};
  line-height: ${LineHeight.TextH2};
  ${({ weight = 'medium', ...restProps }) => getCommonStyles({ weight, ...restProps })}
`

export const TextH3 = styled.h3<TextProps>`
  font-size: ${FontSize.TextH3};
  line-height: ${LineHeight.TextH3};
  ${({ weight = 'medium', ...restProps }) => getCommonStyles({ weight, ...restProps })}
`

export const TextH4 = styled.h4<TextProps>`
  font-size: ${FontSize.TextH4};
  line-height: ${LineHeight.TextH4};
  letter-spacing: 0.6px;
  ${({ uppercase = true, weight = 'medium', ...restProps }) => getCommonStyles({ weight, uppercase, ...restProps })}
`

export const TextLarge = styled.p<TextProps>`
  font-size: ${FontSize.TextLarge};
  line-height: ${LineHeight.TextLarge};
  ${(props) => getCommonStyles(props)}
`
export const TextNormal = styled.p<TextProps>`
  font-size: ${FontSize.TextNormal};
  line-height: ${LineHeight.TextNormal};
  ${(props) => getCommonStyles(props)}
`

export const TextSmall = styled.small<TextProps>`
  font-size: ${FontSize.TextSmall};
  line-height: ${LineHeight.TextSmall};
  ${(props) => getCommonStyles(props)}
`

export const TextMicro = styled.small<TextProps>`
  font-size: ${FontSize.TextMicro};
  line-height: ${LineHeight.TextMicro};
  ${(props) => getCommonStyles(props)}
`

export const DisplayM = styled.h1<TextProps>`
  font-size: ${FontSize.TextDisplayM};
  line-height: ${LineHeight.TextDisplayM};
  ${({ weight = 'bold', fontFamily = DefaultValue.FontFamilyDisplay, ...restProps }) =>
    getCommonStyles({ weight, fontFamily, ...restProps })}
`

export const DisplayS = styled.h2<TextProps>`
  font-size: ${FontSize.TextDisplayS};
  line-height: ${LineHeight.TextDisplayS};
  ${({ weight = 'bold', fontFamily = DefaultValue.FontFamilyDisplay, ...restProps }) =>
    getCommonStyles({ weight, fontFamily, ...restProps })}
`

export const DisplayXS = styled.h3<TextProps>`
  font-size: ${FontSize.TextDisplayXS};
  line-height: ${LineHeight.TextDisplayXS};
  ${({ weight = 'bold', fontFamily = DefaultValue.FontFamilyDisplay, ...restProps }) =>
    getCommonStyles({ weight, fontFamily, ...restProps })}
`
