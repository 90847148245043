import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { BankSettingsContextProvider, BankSettingsModal } from '@modals'
import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'

import { queryClient } from '../../config/queryClient'
import { EmberCloseModalContextProvider, EmberModal } from '../../contexts/emberCloseModalContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

class BankSettingsModalWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @byAttrVal accountId: string
  @registerEvent('closeEmber') closeEmber: Function
  @registerEvent('removeConnection') removeConnection: Function
  @registerEvent('renewConnection') renewConnection: Function
}

type BankSettingsModalModuleProps = {
  organizationId: string
  accountId: string
  onCloseEmber: EmberModal
  onRemoveConnection: EmberEventFn
  onRenewConnection: EmberEventFn
}

function BankSettingsModalModule({
  accountId,
  organizationId,
  onRemoveConnection,
  onRenewConnection,
  onCloseEmber,
}: BankSettingsModalModuleProps): ReactElement {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <EmberCloseModalContextProvider onCloseModal={onCloseEmber}>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <UserOrganizationSettingsContextProvider organizationId={organizationId}>
              <CurrentUserContextProvider>
                <ModalContextProvider>
                  <AccountsContextProvider organizationId={organizationId}>
                    <BankSettingsContextProvider
                      accountId={accountId}
                      removeConnection={onRemoveConnection}
                      renewConnection={onRenewConnection}
                    >
                      <BankSettingsModal />
                    </BankSettingsContextProvider>
                  </AccountsContextProvider>
                </ModalContextProvider>
              </CurrentUserContextProvider>
            </UserOrganizationSettingsContextProvider>
          </UserOrganizationContextProvider>
        </EmberCloseModalContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const BankSettingsModalCustomElement = createCustomElement(
  BankSettingsModalModule,
  BankSettingsModalWebComponent,
  'element',
)

customElements.get('bank-settings-modal-module') ||
  customElements.define('bank-settings-modal-module', BankSettingsModalCustomElement)

export default BankSettingsModalCustomElement
