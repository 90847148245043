import { BankConnection } from '@modules-deprecated/bankReconciliation/types'

import { formatIban } from './formatIban'

export const getDropdownItems = (connections: BankConnection[] | undefined) => {
  return connections?.map((account: BankConnection) => {
    const accountId = formatIban(account.iban)
    const displayTitle = `${account.name} (${accountId} ${account.currency}) - ${account.bank.name}`

    return {
      id: account.id,
      children: displayTitle,
      value: displayTitle,
    }
  })
}
