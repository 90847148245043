import { useTheme } from 'emotion-theming'
import React, { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from '../../../types/theme'
import { FilesPreview, PreviewFile } from '../../FilesPreview'
import * as Styled from './styles'

interface FilesProps {
  files: PreviewFile[]
  isLoading: boolean
}

export const Files = ({ files, isLoading }: FilesProps): ReactElement | null => {
  const { t } = useTranslation()
  const theme = useTheme<Theme>()
  const [isError, setIsError] = useState(false)

  const handleError = useCallback(() => {
    setIsError(true)
  }, [])

  if (isLoading) {
    return null
  }

  if (!files.length) {
    return (
      <Styled.FileWrapper theme={theme} bg="white" fullHeight>
        <Styled.FileMock theme={theme}>{t('no_files_attached')}</Styled.FileMock>
      </Styled.FileWrapper>
    )
  }

  return (
    <Styled.FileWrapper isError={isError}>
      <FilesPreview
        files={files}
        fitToHeight
        loading=""
        onError={handleError}
        withDownloadButton
        withFilename
        withRotate
        zoomable
      />
    </Styled.FileWrapper>
  )
}
