import { BorderRadius, Color } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../../../../types/themable'

export const CheckoutSummaryWrapper = styled.div<Themable>`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 365px;
  margin-left: 50px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 40px rgba(211, 217, 224, 0.8);
  background-color: ${Color.White};
  border-radius: ${BorderRadius.L};
`

export const Title = styled.p<Themable>`
  margin-bottom: 15px;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.darkGray2};
`

export const TermsAndConditionsWrapper = styled.div`
  margin: 17px 0;
`

export const BelowButtonText = styled.p<Themable>`
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayA};
`
