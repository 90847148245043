import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'
import { zIndex } from '../../utils/zIndex'
import { Flex } from '../Flex'
import { Variant } from './types'
import { variantStyles } from './utils'

interface AlertProps {
  variant: Variant
}

export const AlertWrapper = styled.div<AlertProps>`
  background-color: ${({ variant }) => variantStyles[variant].bgColor};
`

export const Alert = styled(Flex)`
  height: 40px;
  padding: ${Spacing.XXS};
  z-index: ${zIndex.Alert};
`

export const IconWrapper = styled.div`
  display: inline-flex;
  margin-right: ${Spacing.S};
`

export const IconCloseWrapper = styled.div`
  margin-left: ${Spacing.S};
`

export const SideActions = styled.aside`
  margin-left: ${Spacing.S};
`
