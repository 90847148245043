import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../styles-deprecated'
import { IconProps } from './Icon'

export const MenetoLogoWithText = ({ color = '#1F2C3C', color2 = '#2AB04E', width, height, ...rest }: IconProps) => {
  return (
    <Box as="i" width={width} height={height} {...rest}>
      <svg
        width={width || 153}
        height={height || 28}
        viewBox="0 0 153 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.116 27.4062L19.1898 22.3372C19.375 22.1655 19.6612 22.1655 19.8464 22.3372L24.9395 27.4158C25.124 27.5846 25.4068 27.5846 25.5912 27.4158L38.9347 14.0724C39.1034 13.8879 39.1034 13.6051 38.9347 13.4206L25.654 0.144771C25.4695 -0.0239901 25.1867 -0.0239901 25.0023 0.144771L19.8754 5.27167C19.6909 5.44043 19.4081 5.44043 19.2236 5.27167L14.0823 0.135116C13.8978 -0.0336453 13.615 -0.0336453 13.4305 0.135116L0.13536 13.4303C-0.0334012 13.6148 -0.0334012 13.8975 0.13536 14.082L13.4643 27.4062C13.6488 27.5749 13.9316 27.5749 14.116 27.4062ZM16.2885 18.2096L17.8526 16.6455C18.0099 16.4672 18.014 16.2009 17.8623 16.0179L16.0471 14.1593C15.8812 13.9755 15.8812 13.6961 16.0471 13.5124L24.8912 4.66822C25.0757 4.49946 25.3585 4.49946 25.5429 4.66822L34.4209 13.5462C34.5896 13.7306 34.5896 14.0134 34.4209 14.1979L25.5285 23.0903C25.344 23.259 25.0612 23.259 24.8767 23.0903L22.0043 20.1937C21.8356 20.0093 21.8356 19.7265 22.0043 19.542L27.3292 14.2172C27.4979 14.0327 27.4979 13.7499 27.3292 13.5655L23.2547 9.49098C23.0702 9.32222 22.7874 9.32222 22.6029 9.49098L21.0388 11.0551C20.87 11.2396 20.87 11.5224 21.0388 11.7068L22.9216 13.5799C23.0903 13.7644 23.0903 14.0472 22.9216 14.2317L14.0871 23.0855C13.9026 23.2542 13.6198 23.2542 13.4354 23.0855L4.57674 14.2268C4.40798 14.0424 4.40798 13.7596 4.57674 13.5751L13.4354 4.68753C13.6198 4.51877 13.9026 4.51877 14.0871 4.68753L16.9836 7.58408C17.1524 7.76855 17.1524 8.05134 16.9836 8.23581L11.6395 13.5799C11.4707 13.7644 11.4707 14.0472 11.6395 14.2317L15.6367 18.2096C15.8212 18.3784 16.104 18.3784 16.2885 18.2096Z"
          fill={coloring(color2)}
        />
        <path
          d="M64.8634 6.10681C67.9965 6.10681 70.111 8.49164 70.111 11.8565V20.8261C70.111 21.0927 69.8948 21.3089 69.6282 21.3089H67.5379C67.2713 21.3089 67.0551 21.0927 67.0551 20.8261V12.6192C67.0551 10.2054 66.0124 8.85853 64.1586 8.85853C62.1889 8.85853 60.6731 10.6592 60.6731 13.2034V20.8503C60.6731 21.1169 60.4569 21.333 60.1903 21.333H58.1627C57.8961 21.333 57.68 21.1169 57.68 20.8503V12.6434C57.68 10.2296 56.6468 8.88267 54.7834 8.88267C52.8089 8.88267 51.2834 10.6834 51.2834 13.2275V20.8744C51.2834 21.141 51.0673 21.3572 50.8006 21.3572H48.7103C48.4437 21.3572 48.2275 21.141 48.2275 20.8744V6.9275C48.2275 6.66088 48.4437 6.44474 48.7103 6.44474H50.0282C50.2513 6.43649 50.4509 6.58221 50.511 6.79715L50.7089 7.52612C50.7549 7.69146 50.8851 7.81999 51.0511 7.86371C51.217 7.90743 51.3937 7.85977 51.5151 7.73853C52.6049 6.6997 54.0502 6.11606 55.5558 6.10681C57.1754 6.05965 58.7128 6.81931 59.6593 8.1344C59.7446 8.25707 59.8819 8.33332 60.0312 8.34087C60.1804 8.34843 60.3247 8.28645 60.422 8.17302C61.5295 6.86841 63.1522 6.11351 64.8634 6.10681Z"
          fill={coloring(color)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.3688 14.4634V13.9082L88.3543 13.9227C88.3543 9.602 85.3274 6.09717 80.7798 6.09717C76.4253 6.09717 73.1426 9.45234 73.1426 13.8889C73.1426 18.3254 76.435 21.6806 80.7798 21.6806C83.7437 21.7337 86.4584 20.0287 87.6978 17.3358C87.7693 17.1857 87.7585 17.0093 87.6692 16.869C87.5799 16.7287 87.4247 16.6442 87.2584 16.6454H85.0426C84.8775 16.6468 84.7245 16.7323 84.6371 16.8723C83.7647 18.1342 82.3079 18.8626 80.775 18.8034C78.7089 18.9 76.8611 17.5268 76.3578 15.5206C76.3178 15.3727 76.3505 15.2147 76.4457 15.0947C76.5409 14.9747 76.6874 14.907 76.8405 14.9123H87.886C88.14 14.913 88.351 14.7167 88.3688 14.4634ZM76.5074 11.7841C77.1262 9.96115 78.8707 8.76275 80.7943 8.83924L80.7895 8.83441C82.7176 8.76022 84.4685 9.95292 85.1053 11.7744C85.1608 11.9229 85.1399 12.0892 85.0493 12.2192C84.9587 12.3493 84.8101 12.4266 84.6515 12.4261H76.966C76.8091 12.4271 76.6615 12.3517 76.5703 12.224C76.4791 12.0963 76.4556 11.9322 76.5074 11.7841Z"
          fill={coloring(color)}
        />
        <path
          d="M99.4778 6.10693C102.954 6.10693 105.121 8.69935 105.121 12.3587V20.8214C105.121 21.088 104.905 21.3042 104.638 21.3042H102.577C102.31 21.3042 102.094 21.088 102.094 20.8214V12.9766C102.094 10.278 100.921 8.84417 98.686 8.84417C96.3495 8.84417 94.6936 10.7221 94.6936 13.3483V20.8214C94.6936 21.088 94.4775 21.3042 94.2109 21.3042H92.1253C91.8587 21.3042 91.6426 21.088 91.6426 20.8214V6.92762C91.6426 6.661 91.8587 6.44486 92.1253 6.44486H93.4046C93.6277 6.43662 93.8273 6.58234 93.8874 6.79728L94.1384 7.66624C94.1837 7.82932 94.311 7.95681 94.474 8.00225C94.637 8.0477 94.8119 8.00447 94.935 7.88831C96.1688 6.71014 97.8205 6.071 99.526 6.11176L99.4778 6.10693Z"
          fill={coloring(color)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.398 14.4586V13.9083L123.388 13.9276C123.388 9.60688 120.362 6.10205 115.814 6.10205C111.46 6.10205 108.177 9.45722 108.177 13.8938C108.177 18.3303 111.469 21.6855 115.814 21.6855C118.778 21.7385 121.493 20.0336 122.732 17.3407C122.806 17.1901 122.796 17.0121 122.707 16.8703C122.617 16.7286 122.46 16.6435 122.293 16.6455H120.082C119.915 16.6452 119.76 16.731 119.671 16.8724C118.799 18.1342 117.342 18.8626 115.809 18.8034C113.744 18.9006 111.897 17.5297 111.392 15.5255C111.35 15.377 111.382 15.2174 111.477 15.0962C111.573 14.9749 111.72 14.9066 111.875 14.9124H122.915C123.171 14.9129 123.383 14.7138 123.398 14.4586ZM111.542 11.7841C112.16 9.96297 113.902 8.76498 115.824 8.83929L115.872 8.84412C117.797 8.77141 119.545 9.96147 120.183 11.7793C120.241 11.9327 120.217 12.1049 120.119 12.2366C120.021 12.3683 119.864 12.4415 119.7 12.431H112.024C111.862 12.4408 111.706 12.3682 111.608 12.2379C111.511 12.1075 111.486 11.937 111.542 11.7841Z"
          fill={coloring(color)}
        />
        <path
          d="M134.767 19.0158V20.8213C134.767 21.0879 134.551 21.304 134.285 21.304H132.508C129.254 21.304 127.246 19.2958 127.246 15.9937V9.52472C127.246 9.2581 127.03 9.04196 126.763 9.04196H125.412C125.266 9.04383 125.133 8.95781 125.075 8.82381C125.017 8.68982 125.045 8.53408 125.146 8.42886L129.636 3.60127C129.737 3.49205 129.895 3.45598 130.034 3.51038C130.172 3.56477 130.263 3.69853 130.263 3.84748V5.91369C130.263 6.18031 130.479 6.39645 130.746 6.39645H134.198C134.464 6.39645 134.681 6.61259 134.681 6.87921V8.52541C134.681 8.79203 134.464 9.00817 134.198 9.00817H130.77C130.504 9.00817 130.287 9.22431 130.287 9.49093V15.7185C130.287 17.4902 131.253 18.4751 133.073 18.4751H134.285C134.423 18.4741 134.556 18.5328 134.648 18.6363C134.741 18.7398 134.784 18.878 134.767 19.0158Z"
          fill={coloring(color)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.476 13.8745C136.476 18.34 139.879 21.6662 144.422 21.6662C148.96 21.6662 152.368 18.3497 152.368 13.8745C152.368 9.39932 148.989 6.08276 144.422 6.08276C139.855 6.08276 136.476 9.40897 136.476 13.8745ZM144.39 9.09528C147.05 9.0776 149.223 11.2147 149.249 13.8745H149.254C149.31 15.1764 148.82 16.4425 147.903 17.3681C146.986 18.2936 145.724 18.7946 144.422 18.7503C141.762 18.7502 139.603 16.5986 139.594 13.9387C139.586 11.2788 141.73 9.11297 144.39 9.09528Z"
          fill={coloring(color)}
        />
      </svg>
    </Box>
  )
}
