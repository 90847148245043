import alphabet from 'alphabet'
import React, { ReactElement } from 'react'

import { Answer } from '../Answer'
import * as Styled from './styles'

interface AnswersProps {
  options: string[]
  onChange: (options: string[]) => void
  selectedOptions?: string[]
}

export const Answers = ({ options, onChange, selectedOptions = [] }: AnswersProps): ReactElement => (
  <Styled.AnswersWrapper>
    {options.map((option, index) => (
      <Answer
        isSelected={!!selectedOptions?.includes(option)}
        key={option}
        letter={alphabet.lower[index]}
        onClick={onChange}
        option={option}
        selectedOptions={selectedOptions}
      />
    ))}
  </Styled.AnswersWrapper>
)
