import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { EmberRoute } from '../../../../../enums/emberRoute'
import { MenuItemText } from '../MenuItemText'
import { generateTo } from './generateTo'
import { MenuItemExtended } from './getMenuItem'

interface GetMenuItemArgs {
  items: MenuItemExtended[]
  organizationUrl: string
  convertEmberRouteToUrl: (route: EmberRoute) => string
  t: TFunction
}

export const getMenuItems = ({
  items,
  organizationUrl,
  convertEmberRouteToUrl,
  t,
}: GetMenuItemArgs): NavItem<EmberRoute>[] =>
  items.map((item) => {
    return {
      id: item.id,
      children: <MenuItemText badge={item.badge}>{t(item.tLabel)}</MenuItemText>,
      title: t(item.tLabel),
      iconLeft: item.icon,
      value: item.routeTo,
      to: generateTo(item, organizationUrl, convertEmberRouteToUrl),
      subItems: item.submenu?.length
        ? getMenuItems({ items: item.submenu, organizationUrl, convertEmberRouteToUrl, t })
        : undefined,
    } as NavItem<EmberRoute>
  })
