import { Spacing } from '@design-system'

import styled from '@emotion/styled'

import { ReactComponent as IconCloudWithArrowUp } from '../../assets/icons/icon-arrow-up-inside-cloud.svg'

export const DropzoneContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const DropzoneIcon = styled(IconCloudWithArrowUp)`
  width: 30px;
  margin-bottom: ${Spacing.S};
`
