import { AttachmentsNavigation, ProtectedComponent } from '@components'
import { FilesPreview, SkeletonBox } from '@design-system'

import React, { memo, ReactElement, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { Scope } from '../../../../enums/scope'
import { getImageClassName } from '../../../../utils/getClassName'
import { useBillAttachmentPageContext } from '../../contexts/billAttachmentPageContext'
import { getAttachmentsFilesFromBill } from '../../utils/getAttachmentsFilesFromBill'
import { getBillFormBaseWrapperHeight } from '../../utils/getBillFormBaseWrapperHeight'
import { BillAttachmentPreview } from '../BillAttachmentPreview'
import { BillAttachmentPreviewBlocked } from '../BillAttachmentPreviewBlocked'
import { BillReadableAttachmentsDropzone } from '../BillReadableAttachmentsDropzone'
import { useBillView } from '../BillReadableForm'
import { useImageWrapperHeight } from './hooks/useImageWrapperHeight'
import * as Styled from './styles'

const EMPTY_VIEW_HEIGHT = 473

export const BillReadableAttachments = memo((): ReactElement => {
  const [isUploading, setIsUploading] = useState(false)
  const { billData, isFetching: isFetchingBill, isFetchingInvalidation: isFetchingBillInvalidation } = useBillView()
  const [currentPageIndex, setCurrentPageIndex] = useBillAttachmentPageContext()
  const billImageWrapperRef = useRef<HTMLDivElement>(null)

  const { bill } = billData || {}
  const attachmentsFiles = useMemo(() => (bill ? getAttachmentsFilesFromBill(bill) : []), [bill]) || []

  useImageWrapperHeight(billImageWrapperRef, attachmentsFiles, !isFetchingBill)

  const isUploadingDropzone = isUploading || isFetchingBillInvalidation

  const handlePageChange = useCallback(
    (index: number) => {
      setCurrentPageIndex(index)
    },
    [setCurrentPageIndex],
  )

  const handleUploadStart = useCallback(() => {
    setIsUploading(true)
  }, [])

  const handleUploadFinished = useCallback(() => {
    setIsUploading(false)
  }, [])

  useLayoutEffect(() => {
    if (billImageWrapperRef.current) {
      const billImageWrapperElement = billImageWrapperRef.current
      const height = getBillFormBaseWrapperHeight()
      billImageWrapperElement.style.height = `${height}px`
    }
  }, [])

  return (
    <Styled.BillReadableAttachmentsWrapper>
      <Styled.BillImageWrapper ref={billImageWrapperRef}>
        {isFetchingBill ? (
          <SkeletonBox height={EMPTY_VIEW_HEIGHT} fullWidth />
        ) : (
          <>
            {attachmentsFiles.length > 0 && !isUploadingDropzone ? (
              <ProtectedComponent scopes={Scope.BillAttachmentRead} unauthorizedNode={<BillAttachmentPreviewBlocked />}>
                <BillAttachmentPreview>
                  <FilesPreview
                    className={getImageClassName()}
                    files={[{ src: attachmentsFiles[currentPageIndex].downloadUrl }]}
                    fitToHeight
                    fitToWidth
                    navigationDirection="vertical"
                    withDownloadButton
                    zoomable
                  />
                </BillAttachmentPreview>
              </ProtectedComponent>
            ) : (
              <Styled.AttachmentDropzoneWrapper height={EMPTY_VIEW_HEIGHT}>
                <BillReadableAttachmentsDropzone
                  isUploading={isUploadingDropzone}
                  onUploadFinished={handleUploadFinished}
                  onUploadStart={handleUploadStart}
                />
              </Styled.AttachmentDropzoneWrapper>
            )}
          </>
        )}
      </Styled.BillImageWrapper>
      {attachmentsFiles?.length > 1 && (
        <Styled.BillReadableAttachmentsActions>
          <AttachmentsNavigation
            onClick={handlePageChange}
            currentIndex={currentPageIndex}
            total={attachmentsFiles.length}
          />
        </Styled.BillReadableAttachmentsActions>
      )}
    </Styled.BillReadableAttachmentsWrapper>
  )
})
