import { IconName } from '@design-system'

import { InvoiceBalanceModifierReference } from '../enums/InvoiceBalanceModifierReference'

export const invoiceBalanceModifierReferenceToIconName: Record<InvoiceBalanceModifierReference, IconName> = {
  [InvoiceBalanceModifierReference.BankPayment]: 'bank',
  [InvoiceBalanceModifierReference.ContactBalancePayment]: 'creditCard',
  [InvoiceBalanceModifierReference.DaybookTransaction]: 'bank',
  [InvoiceBalanceModifierReference.Invoice]: 'invoice',
  [InvoiceBalanceModifierReference.InvoiceLateFee]: 'invoice',
}
