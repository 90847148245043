import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

const BAGDE_HEIGHT = 24

export const BetaTitleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

export const BetaLabelButton = styled.button`
  appearance: none;
  display: flex;
  height: ${BAGDE_HEIGHT}px;
  margin-left: ${Spacing.S};
  padding-left: ${Spacing.S};
  padding-right: ${Spacing.S};
  border: none;
  border-radius: ${BAGDE_HEIGHT / 2}px;
  align-items: center;
  background-color: ${Color.Lavender};
  cursor: pointer;
`
