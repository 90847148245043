import { NavListCreateNewButton, Select, SelectProps, useModal } from '@design-system'

import React, { ChangeEvent, forwardRef, ReactElement, Ref, useCallback, useMemo, useState } from 'react'

import { ContactPerson } from '@modules-deprecated/app/contactPerson/types/contactPerson'

import { ModalId } from '../../../enums/modalId'
import { useContactPersons } from '../../../hooks/useContactPersons'
import { CreateContactPersonModal } from './elements/CreateContactPersonModal'
import { getItems } from './utils/getItems'

interface ContactPersonSelectProps extends Omit<SelectProps<ContactPerson>, 'items' | 'onSelect'> {
  onSelect: (value: ContactPerson) => void
  onCreateContactPerson: (contactPerson: ContactPerson) => void
  contactId?: string
  selectedContacts: ContactPerson[]
}

export const ContactPersonSelect = forwardRef(
  (
    { onSelect, contactId, onCreateContactPerson, selectedContacts, ...selectProps }: ContactPersonSelectProps,
    ref: Ref<HTMLDivElement | null>,
  ): ReactElement => {
    const [isSelectOpen, setIsSelectState] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const { open, close } = useModal(ModalId.CreateContactPersonModal)
    const { contactPersons, isLoading, refetch } = useContactPersons({ contactId, searchQuery: inputValue })

    const items = useMemo(() => {
      if (!contactPersons?.contactPersons) {
        return []
      }

      const contactsList = getItems(contactPersons.contactPersons)

      return selectedContacts.length
        ? contactsList.filter(
            (contact) => !selectedContacts.some((selectedContact) => contact.id === selectedContact.id),
          )
        : contactsList
    }, [contactPersons?.contactPersons, selectedContacts])

    const handleSelect = useCallback(
      (id?: string, value?: ContactPerson) => {
        if (value) {
          onSelect?.(value)
        }
      },
      [onSelect],
    )

    const handleCreateContactPerson = useCallback(() => {
      setIsSelectState(false)
      open()
    }, [open])

    const handleDropdownOpen = useCallback(() => {
      setIsSelectState(true)
    }, [])

    const handleDropdownClose = useCallback(() => {
      setIsSelectState(false)
    }, [])

    const handleContactPersonCreated = useCallback(
      (contactPerson: ContactPerson) => {
        refetch()
        close()
        onCreateContactPerson(contactPerson)
      },
      [close, onCreateContactPerson, refetch],
    )

    const handleGetDisplayValue = useCallback(() => {
      return ''
    }, [])

    const handleSelectInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value)
    }, [])

    return (
      <>
        <Select
          {...selectProps}
          dropdownFetching={isLoading}
          dropdownSize="fitTrigger"
          dropdownFooter={<NavListCreateNewButton onClick={handleCreateContactPerson} />}
          getDisplayValue={handleGetDisplayValue}
          isOpen={isSelectOpen}
          items={items}
          onDropdownOpen={handleDropdownOpen}
          onDropdownClose={handleDropdownClose}
          onSelect={handleSelect}
          ref={ref}
          withSearch
          onChangeDebounced={handleSelectInputChange}
        />

        <CreateContactPersonModal contactId={contactId || ''} onCreateContactPerson={handleContactPersonCreated} />
      </>
    )
  },
)
