import qs from 'qs'

import { deleteRequest, request } from '../../../utils'
import { getFormDataWithFiles } from '../../../utils/getFormDataWithFiles'
import {
  BankPaymentData,
  BillData,
  BillsData,
  BillsQueryParams,
  CreateVoucherPayloadData,
  DaybookData,
  PostingData,
  UserData,
  Voucher,
  VoucherInboxState,
  VoucherListQueryParams,
} from '../types'

export const voucherServiceUrl = window?.ENV?.voucherServiceApiUrl || process.env.VOUCHER_SERVICE_API_URL || ''

export const duplicateVoucher = async (organizationId: string, voucherId: string) => {
  const duplicatedVoucher = await request(
    `${voucherServiceUrl}/organizations/${organizationId}/vouchers/${voucherId}/actions/duplicate`,
    {
      method: 'POST',
    },
    {
      returnRawResponse: false,
      withAuth: true,
    },
  )

  return duplicatedVoucher
}

export const createVoucher = async (data: CreateVoucherPayloadData, organizationId: string): Promise<Voucher> => {
  const body = getFormDataWithFiles(data)

  return request(
    `${voucherServiceUrl}/organizations/${organizationId}/vouchers`,
    {
      method: 'POST',
      body,
    },
    {
      returnRawResponse: false,
      withAuth: true,
      skipContentType: true,
    },
  )
}

export const vouchersListDefaultQueryParams: VoucherListQueryParams = {
  dueDateTo: new Date(),
  inboxState: VoucherInboxState.RECEIVED,
  includeNullDueDate: true,
  limit: 1,
  order: 'ASC',
  sort: 'createdTimestamp',
}

export const getVouchersList = async <T extends boolean>(
  organizationId: string,
  params: VoucherListQueryParams,
  returnRawResponse?: T,
): Promise<T extends true ? Response : Voucher[]> => {
  const {
    search: q,
    inboxState,
    fromDate,
    toDate,
    offset,
    limit,
    sort,
    dueDateTo,
    dueDateFrom,
    includeNullDueDate,
  } = params

  const parameters: VoucherListQueryParams = {
    offset: offset || 0,
    limit: limit || 100,
    ...(q ? { q } : {}),
    ...(sort ? { sort } : {}),
    ...(inboxState ? { inboxState } : {}),
    ...(fromDate ? { fromDate } : {}),
    ...(toDate ? { toDate } : {}),
    ...(dueDateTo ? { dueDateTo } : {}),
    ...(dueDateFrom ? { dueDateFrom } : {}),
    ...(includeNullDueDate ? { includeNullDueDate } : {}),
  }

  return request(
    `${voucherServiceUrl}/organizations/${organizationId}/vouchers?${qs.stringify(parameters)}`,
    {
      method: 'GET',
    },
    {
      returnRawResponse,
      withAuth: true,
    },
  )
}

export const getBill = async (billId: string): Promise<BillData> => {
  return request(
    `/v2/bills/${billId}?include=bill.lines,bill.balanceModifiers.balanceModifier.modifier`,
    {
      method: 'GET',
    },
    {
      returnRawResponse: false,
      withAuth: true,
    },
  )
}

export const getBills = async (organizationId: string, params: BillsQueryParams): Promise<BillsData> => {
  return request(
    `/v2/bills?organizationId=${organizationId}&${qs.stringify(params)}`,
    {
      method: 'GET',
    },
    {
      returnRawResponse: false,
      withAuth: true,
    },
  )
}

export const getTransactionPosting = async (transactionId: string): Promise<PostingData> => {
  const data = await request(
    `/v2/postings?transactionId=${transactionId}&pageSize=100&offset=0`,
    {
      method: 'GET',
    },
    {
      returnRawResponse: false,
      withAuth: true,
    },
  )

  return data
}

export const getBankPayment = async (paymentId: string): Promise<BankPaymentData> => {
  const data = await request(
    `/v2/bankPayments/${paymentId}`,
    {
      method: 'GET',
    },
    {
      returnRawResponse: false,
      withAuth: true,
    },
  )

  return data
}

export const getUser = async (userId: string): Promise<UserData> =>
  await request(
    `/v2/users/${userId}`,
    {
      method: 'GET',
    },
    {
      returnRawResponse: false,
      withAuth: true,
    },
  )

export const startBankDataExport = async (organizationId: string, accountId: string): Promise<void> => {
  await request(
    `/spiir/organizations/${organizationId}/account/${accountId}/export`,
    {
      method: 'GET',
    },
    {
      returnRawResponse: false,
      withAuth: true,
    },
  )
}

export const getVoucher = async (organizationId: string, voucherId: string): Promise<Voucher | undefined> => {
  return request(
    `${voucherServiceUrl}/organizations/${organizationId}/vouchers/${voucherId}`,
    {
      method: 'GET',
    },
    {
      returnRawResponse: false,
      withAuth: true,
    },
  )
}

export const updateVoucherFiles = async (organizationId: string, voucherId: string, files: File[]): Promise<void> => {
  const body = getFormDataWithFiles({ files })

  await request(
    `${voucherServiceUrl}/organizations/${organizationId}/vouchers/${voucherId}/files/replace`,
    {
      method: 'PUT',
      body,
    },
    {
      withAuth: true,
      skipContentType: true,
    },
  )
}

export const deleteVoucher = async (organizationId: string, voucherId: string): Promise<void> => {
  await deleteRequest(`${voucherServiceUrl}/organizations/${organizationId}/vouchers/${voucherId}`)
}

export const getDaybooks = async (organizationId?: string): Promise<DaybookData> => {
  const baseUrl = `/v2/daybooks`
  const query = organizationId ? `${baseUrl}?organizationId=${organizationId}` : baseUrl

  return await request(
    query,
    {
      method: 'GET',
    },
    {
      returnRawResponse: false,
      withAuth: true,
    },
  )
}
