import { Color } from '../../../enums/color'
import { Variant } from '../types/variant'

interface TagStyles {
  color: Color
  bgColor: Color
}

export const variantStyles: Record<Variant, TagStyles> = {
  error: {
    color: Color.DeepSeaGreen,
    bgColor: Color.Red10,
  },
  warning: {
    color: Color.DeepSeaGreen,
    bgColor: Color.Yellow10,
  },
  primary: {
    color: Color.DeepSeaGreen,
    bgColor: Color.Lavender,
  },
  success: {
    color: Color.DeepSeaGreen,
    bgColor: Color.Green10,
  },
  neutral: {
    color: Color.DeepSeaGreen,
    bgColor: Color.DayGrey,
  },
}
