import { TFunction } from 'i18next'
import { mixed, object, SchemaOf, string } from 'yup'

import { AccountingInfo } from '../elements/AccountingInfoSelect'
import { Profile } from '../types/profile'

interface Address {
  city: string
  countryId: string
  street: string
  zipCode: string
}

export interface ProfileForm {
  address: Partial<Address> | undefined
  companyName: string
  cvr: string | undefined
  description: string | undefined
  email: string
  logo: string | undefined
  phone: string | undefined
  services: AccountingInfo | undefined
}

export type ProfileFormSchema = SchemaOf<ProfileForm>

const isDenmarkBasedOrganization = ({ countryId }: Address) => countryId === 'DK'

export const getValidationSchema = (t: TFunction): ProfileFormSchema =>
  object({
    address: object({
      countryId: string().required(t('umbrella.admin_profile.field.country.required')),
      city: string().required(t('umbrella.admin_profile.field.city.required')),
      street: string().required(t('umbrella.admin_profile.field.street.required')),
      zipCode: string().required(t('umbrella.admin_profile.field.zip_code.required')),
    }).required(),
    companyName: string().required(t('umbrella.admin_profile.field.company_name.required')),
    cvr: string().when('address', {
      is: isDenmarkBasedOrganization,
      then: (schema) => schema.matches(/^\d{8}$/, t('umbrella.admin_profile.field.cvr.wrong_format')),
      otherwise: (schema) => schema,
    }),
    email: string()
      .email(t('umbrella.admin_profile.field.email.wrong_format'))
      .required(t('umbrella.admin_profile.field.email.required')),
    logo: string(),
    phone: string().when('address', {
      is: isDenmarkBasedOrganization,
      then: (schema) => schema.min(8, t('umbrella.admin_profile.field.phone.wrong_format')),
      otherwise: (schema) => schema.required(),
    }),
    description: string(),
    services: mixed().nullable(),
  })

export const getDefaultValues = (profile?: Profile): ProfileForm => ({
  address: {
    city: profile?.city || undefined,
    countryId: profile?.country || 'DK',
    street: profile?.street || undefined,
    zipCode: profile?.zipcode || undefined,
  },
  companyName: profile?.name || '',
  cvr: profile?.registrationNo || undefined,
  description: profile?.description || undefined,
  email: profile?.email || '',
  logo: profile?.logoFileId || undefined,
  phone: profile?.phone || undefined,
  services: JSON.parse(profile?.services || '{}') || undefined,
})
