import { AppLoadingScreen } from '@components'

import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import React from 'react'

import { ThemeProvider } from '../../contexts/themeProvider'

class AppLogoModel extends DOMModel {
  @byAttrVal text: ''
}

interface AppLogoComponentProps {
  text: string
}

const AppLogoComponent = ({ text }: AppLogoComponentProps) => (
  <ThemeProvider>
    <AppLoadingScreen text={text} />
  </ThemeProvider>
)

let AnimationCustomElement

if (customElements) {
  AnimationCustomElement = createCustomElement(AppLogoComponent, AppLogoModel, 'element')

  customElements.get('app-logo-component') || customElements.define('app-logo-component', AnimationCustomElement)
}

export default AnimationCustomElement
