import { TableHoverActions } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface AdditionalFieldsTableHoverActionsProps {
  onEdit: (id: string) => void
  id: string
}
export const AdditionalFieldsTableHoverActions = ({
  onEdit,
  id: itemId,
}: AdditionalFieldsTableHoverActionsProps): ReactElement => {
  const { t } = useTranslation()
  const handleEditClick = useCallback(() => {
    onEdit(itemId)
  }, [onEdit, itemId])

  return (
    <TableHoverActions>
      <TableHoverActions.Item icon="paperWithPencil" label={t('edit')} onClick={handleEditClick} />
    </TableHoverActions>
  )
}
