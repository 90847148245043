import { SkeletonBox, Space } from '@design-system'

import React, { ReactElement } from 'react'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { Scope } from '../../enums/scope'
import { CreditCardProps } from '../CreditCard'
import { ProtectedComponent } from '../ProtectedComponents'
import { AddPaymentMethod } from './elements/AddPaymentMethod'
import { CardDetails } from './elements/CardDetails'

interface PaymentMethodSelectionProps {
  cardDetails?: CreditCardProps
  fullWidth?: boolean
  isLoading?: boolean
  isSubscribed?: boolean
  onAddPaymentClick: () => void
}

export const PaymentMethodSelection = ({
  cardDetails,
  fullWidth,
  isLoading,
  isSubscribed,
  onAddPaymentClick,
}: PaymentMethodSelectionProps): ReactElement => {
  const { umbrella } = useUmbrella()

  if (isLoading) {
    return (
      <>
        <Space size="xl" />
        <SkeletonBox fullWidth height={148} />
        <Space size="xl" />
      </>
    )
  }

  if (isSubscribed) {
    return <CardDetails {...cardDetails} />
  }

  return (
    <ProtectedComponent scopes={Scope.OrganizationPaymentWrite} scopesCheckDisabled={!!umbrella?.id}>
      <AddPaymentMethod onAddPaymentClick={onAddPaymentClick} fullWidth={fullWidth} />
    </ProtectedComponent>
  )
}
