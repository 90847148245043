import styled from '@emotion/styled'

export type Direction = 'row' | 'column'

interface FormContentProps {
  direction?: Direction
}

export const FormContent = styled.div<FormContentProps>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'row' ? 'row' : 'column')};
  flex-grow: 1;

  ${() => DropzoneWrapper} {
    + ${() => ContentWrapper} {
      flex-shrink: 0;
      ${({ direction }) => (direction === 'row' ? 'width: 300px;' : 'width: 100%')};
      ${({ direction }) => (direction === 'row' ? 'margin-left: 30px' : 'margin-top: 16px')};
    }
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const DropzoneWrapper = styled.div`
  width: 100%;
`
