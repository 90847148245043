import { ProtectedComponent } from '@components'
import { TabButtonsGroup } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Scope } from '../../../../enums/scope'
import { useReceiptsFilters } from '../../hooks/useReceiptsFilters'
import { ReceiptFilterType } from '../Receipt'
import { ReceiptsCountBadge } from '../ReceiptsCountBadge'

export const AttachmentsEInvoicesToggle = (): ReactElement => {
  const { t } = useTranslation()
  const [{ type }, setFilters] = useReceiptsFilters()

  const isUploadsActive = type === ReceiptFilterType.Upload
  const isEInvoicesActive = type === ReceiptFilterType.EDocument

  const handleUploadsFilterClick = useCallback(() => {
    setFilters({ type: ReceiptFilterType.Upload })
  }, [setFilters])

  const handleEInvoicesFilterClick = useCallback(() => {
    setFilters({ type: ReceiptFilterType.EDocument })
  }, [setFilters])

  return (
    <ProtectedComponent scopes={Scope.EDocumentRead}>
      <TabButtonsGroup>
        <TabButtonsGroup.Button
          onClick={handleUploadsFilterClick}
          selected={isUploadsActive}
          suffix={<ReceiptsCountBadge documentFilterType={ReceiptFilterType.Upload} />}
        >
          {t('receipts.filters.type.uploads')}
        </TabButtonsGroup.Button>

        <TabButtonsGroup.Button
          onClick={handleEInvoicesFilterClick}
          selected={isEInvoicesActive}
          suffix={<ReceiptsCountBadge documentFilterType={ReceiptFilterType.EDocument} />}
        >
          {t('receipts.filters.type.e_invoices')}
        </TabButtonsGroup.Button>
      </TabButtonsGroup>
    </ProtectedComponent>
  )
}
