import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect } from 'react'
import { useLocalStorage } from 'react-use'

import { LocalStorageKeys } from '../enums/localStorageKeys'
import { useOrganizationFiscalYears } from '../hooks/useOrganizationFiscalYears'

interface OldPeriodDropdownSchema {
  value: string
  type: string
}

interface ContextState {
  fiscalYear: number | undefined
  setFiscalYear: (fiscalYear: number) => void
}

const GlobalFiscalYearContext = createContext<ContextState | undefined>(undefined)

interface GlobalPeriodContextProviderProps {
  organizationId: string
  children?: ReactNode
}

export const GlobalFiscalYearContextProvider = ({
  children,
  organizationId,
}: GlobalPeriodContextProviderProps): ReactElement => {
  const localStorageKey = `${LocalStorageKeys.DashboardPeriodDropdown}-${organizationId}`
  const [fiscalYear, setFiscalYear] = useLocalStorage<number>(localStorageKey, undefined)
  const { years, isLoading: areFiscalYearsLoading } = useOrganizationFiscalYears()

  const convertOldPeriodDropdownFormat = useCallback(
    (fiscalYear: any) => {
      if (!fiscalYear || typeof fiscalYear === 'number' || typeof fiscalYear === 'string') {
        return
      }

      if (
        typeof fiscalYear === 'object' &&
        Object.prototype.hasOwnProperty.call(fiscalYear, 'value') &&
        Object.prototype.hasOwnProperty.call(fiscalYear, 'type')
      ) {
        const { value, type } = fiscalYear as OldPeriodDropdownSchema

        if (type === 'fiscalyear') {
          setFiscalYear(Number(value))
        } else {
          setFiscalYear(years[0])
        }
      }
    },
    [setFiscalYear, years],
  )

  // When `fiscalYear` is undefined set the default value from `useOrganizationFiscalYears` hook
  useEffect(() => {
    // fix legacy format of the LocalStorageKeys.DashboardPeriodDropdown key
    convertOldPeriodDropdownFormat(fiscalYear)

    if (!fiscalYear && !areFiscalYearsLoading) {
      setFiscalYear(years[0])
    }
  }, [areFiscalYearsLoading, convertOldPeriodDropdownFormat, fiscalYear, setFiscalYear, years])

  return (
    <GlobalFiscalYearContext.Provider
      value={{
        fiscalYear: fiscalYear !== undefined && typeof fiscalYear !== 'object' ? Number(fiscalYear) : undefined,
        setFiscalYear,
      }}
    >
      {children}
    </GlobalFiscalYearContext.Provider>
  )
}

export const useGlobalFiscalYear = () => {
  const context = useContext(GlobalFiscalYearContext)

  if (!context) {
    throw new Error('GlobalFiscalYearContextProvider is missing in the module!')
  }

  return context
}
