import { Button, ButtonsGroup, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import * as Styled from './styles'

interface AttachmentsNavigationProps {
  currentIndex?: number
  total?: number
  onClick?: (index: number) => void
}

export const AttachmentsNavigation = ({
  currentIndex = 0,
  total = 0,
  onClick,
}: AttachmentsNavigationProps): ReactElement => {
  const currentPage = currentIndex + 1
  const lastItemIndex = total - 1

  const handleBackClick = useCallback(() => {
    const newIndex = currentIndex > 0 ? currentIndex - 1 : lastItemIndex
    onClick?.(newIndex)
  }, [currentIndex, lastItemIndex, onClick])

  const handleNextClick = useCallback(() => {
    const newIndex = currentIndex < lastItemIndex ? currentIndex + 1 : 0
    onClick?.(newIndex)
  }, [currentIndex, lastItemIndex, onClick])

  return (
    <Styled.AttachmentsNavigationWrapper>
      <Styled.NavigationSummary>
        <Text variant="large">{`${currentPage}/${total}`}</Text>
      </Styled.NavigationSummary>
      <ButtonsGroup merged>
        <Button icon="chevronLeft" onClick={handleBackClick} size="m" variant="text" />
        <Button icon="chevronRight" onClick={handleNextClick} size="m" variant="text" />
      </ButtonsGroup>
    </Styled.AttachmentsNavigationWrapper>
  )
}
