import { decodePeriodValue } from '@components'

import { endOfMonth, setMonth, setYear } from 'date-fns'

import { PeriodMode } from '../../../enums/periodMode'
import { formatDate } from '../../../utils'

// For Balance Sheet, when there's Month selected, the BE expects different payload format than "period"
// So instead of { period: "month=2023-04" } we should pass { date: "2023-04-30" }
export const convertPeriodValueToDateForBalanceSheet = (period: string): string | undefined => {
  const periodValue = decodePeriodValue(period)

  if (periodValue?.mode === PeriodMode.Month && periodValue.year && periodValue.value !== undefined) {
    const date = endOfMonth(setMonth(setYear(new Date(), periodValue.year), periodValue.value))
    return formatDate(date)
  }

  return undefined
}
