import {
  GET_PRISMIC_DOCUMENTS_BY_TYPE_TAGS,
  PRISMIC_DOCUMENTS_BY_TYPE_TAGS_RECEIVED,
  PRISMIC_GET_DOCUMENT_ACTION,
  PRISMIC_RECEIVED_ACTION,
  PRISMIC_TITLES_RECEIVED,
} from './actions'
import {
  GetPrismicDocumentsByTypeTagsAction,
  PrismicDocumentsByTypeTagsReceivedAction,
  PrismicDocumentsByTypeTagsReceivedPayload,
  PrismicGetAction,
  PrismicReceivedAction,
} from './types'

type PrismicGetDocumentPayloadProps = {
  type: string
  uid: string
}

export function prismicGetDocument(payload: PrismicGetDocumentPayloadProps): PrismicGetAction {
  return {
    payload,
    type: PRISMIC_GET_DOCUMENT_ACTION,
  }
}

export function prismicReceived(payload: any): PrismicReceivedAction {
  return {
    payload,
    type: PRISMIC_RECEIVED_ACTION,
  }
}

export function prismicDocumentsByTypeTagsReceived(payload: any): PrismicDocumentsByTypeTagsReceivedAction {
  return {
    payload,
    type: PRISMIC_DOCUMENTS_BY_TYPE_TAGS_RECEIVED,
  }
}

export function getPrismicDocumentsByTypeTags(
  payload: PrismicDocumentsByTypeTagsReceivedPayload,
): GetPrismicDocumentsByTypeTagsAction {
  return {
    type: GET_PRISMIC_DOCUMENTS_BY_TYPE_TAGS,
    payload,
  }
}

export function prismicTitlesReceived(payload: string[]) {
  return {
    type: PRISMIC_TITLES_RECEIVED,
    payload,
  }
}
