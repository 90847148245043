import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const EmptyViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const TitleWrapper = styled.div`
  margin-top: ${Spacing.L};
  margin-bottom: ${Spacing.L};
`

export const TextWrapper = styled.div`
  max-width: 840px;
`

export const ButtonWrapper = styled.div`
  margin-top: ${Spacing.XL};
`
