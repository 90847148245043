import { BankConnection, BankConnectionSession } from '@modules-deprecated/bankReconciliation/types'

import { deleteRequest, getRequest, postRequest } from '../../utils'

interface AiiaConnectionCommonProps {
  organizationId: string
  aiiaBankAccountId: string
}

interface InitiateAiiaBankConnectionProps {
  locale: string
  organizationId: string
  redirectUrl: string
}

export const initiateAiiaBankConnection = ({
  locale,
  organizationId,
  redirectUrl,
}: InitiateAiiaBankConnectionProps): Promise<{ authUrl: string }> => {
  return postRequest(`/banking/${organizationId}/connect`, {
    culture: locale,
    redirectUrl,
  })
}

interface SyncBankConnectionProps extends AiiaConnectionCommonProps {
  locale: string
  redirectUrl: string
}

export const syncBankConnection = ({
  aiiaBankAccountId,
  locale,
  organizationId,
  redirectUrl,
}: SyncBankConnectionProps): Promise<{ authUrl?: string }> => {
  return postRequest(`/banking/${organizationId}/accounts/${aiiaBankAccountId}/sync`, {
    culture: locale,
    redirectUrl,
  })
}

interface ConnectBankToAccountProps extends AiiaConnectionCommonProps {
  accountId: string
}

interface BankToAccountConnection {
  accountId: string
  externalAccountId: string
}

export const createBankToAccountConnection = ({
  organizationId,
  accountId,
  aiiaBankAccountId,
}: ConnectBankToAccountProps): Promise<BankToAccountConnection> => {
  return postRequest(`/banking/${organizationId}/accounts/${aiiaBankAccountId}/connect`, {
    externalAccountId: accountId,
  })
}

export const fetchAiiaConnectionForAccount = ({
  organizationId,
  aiiaBankAccountId,
}: AiiaConnectionCommonProps): Promise<BankConnection> => {
  return getRequest(`/banking/${organizationId}/accounts/${aiiaBankAccountId}`)
}

export const removeBankConnection = ({ organizationId, aiiaBankAccountId }: AiiaConnectionCommonProps) => {
  return deleteRequest(`/banking/${organizationId}/accounts/${aiiaBankAccountId}/connection`)
}

interface RemoveOldBankConnectionProps {
  accountId: string
  organizationId: string
}

export const removeOldBankConnection = ({ accountId, organizationId }: RemoveOldBankConnectionProps) => {
  return deleteRequest(`/organizations/${organizationId}/accounts/${accountId}/bankConnection`)
}

export const fetchBankSessions = (organizationId: string): Promise<BankConnectionSession[]> => {
  return getRequest(`/spiir/organizations/${organizationId}/sessions`)
}
