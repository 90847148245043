import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Spacing } from '../../../../enums/spacing'
import { ComponentsGroup } from '../../../ComponentsGroup'
import { defaultHeaderBlocks, defaultListBlocks } from '../../constants/blockTypes'
import { useRichTextContext } from '../../contexts/richTextContext'
import { applyStyleSettings } from '../../utils/applyStyleSettings'
import { decorateHeaderBlocksWithCustomLabels } from '../../utils/decorateHeaderBlocksWithCustomLabels'
import { BlockStyleControls } from '../BlockStyleControls'
import { InlineStyleControls } from '../InlineStyleControls'

export const RichTextControls = (): ReactElement => {
  const { t } = useTranslation()
  const { styleSettings } = useRichTextContext()

  const enabledHeaderBlocks = applyStyleSettings(defaultHeaderBlocks, styleSettings)
  const decoratedHeaderBlocks = decorateHeaderBlocksWithCustomLabels(enabledHeaderBlocks, t)
  const enabledListBlocks = applyStyleSettings(defaultListBlocks, styleSettings)
  return (
    <ComponentsGroup spacing={Spacing.S} direction="row">
      <BlockStyleControls blockTypes={decoratedHeaderBlocks} />
      <InlineStyleControls />
      <BlockStyleControls blockTypes={enabledListBlocks} />
    </ComponentsGroup>
  )
}
