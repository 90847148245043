import { Language } from '@design-system'

import { ApiCountryKey } from '../types/apiCountryKey'

export const getApiCountryKey = (countryId?: string, language?: Language): ApiCountryKey => {
  // fallback for cases when organization is not present, i.e. during registration process
  if (!countryId) {
    return language === 'fr' ? 'fr' : 'dk'
  }

  return countryId === 'FR' ? 'fr' : 'dk'
}
