import { Color, rwd, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: space-between;
`

export const List = styled.ol`
  width: 100%;
  margin-left: ${Spacing.XXXL};
  overflow: hidden;

  ${rwd.equalOrLessThan('xlarge')} {
    margin-left: ${Spacing.XL};
  }
`

interface ListItemProps {
  clickable?: boolean
  color: Color
}

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  padding: ${Spacing.S} 0;
  border-bottom: 1px solid ${Color.Gray40};
  align-items: center;
  list-style: none;

  &:before {
    content: '';
    width: 16px;
    height: 16px;
    margin-right: ${Spacing.M};
    border-radius: 4px;
    background: ${({ color }) => color};
    flex-shrink: 0;
  }

  &:last-of-type {
    border-bottom: 0;
  }
`

export const Label = styled(Text)`
  margin-right: auto;
`

export const Amount = styled(Text)`
  margin-left: ${Spacing.S};
  flex-shrink: 0;
`
