import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { updateBill, UpdateBillPayload, UpdateBillResponseData } from '../../../query-api'

interface UseUpdateBillProps {
  onError?: (error?: APIError) => void
  onSettled?: (
    data: UpdateBillResponseData | undefined,
    error: APIError | null | undefined,
    variables: UpdateBillPayload,
    context: void | undefined,
  ) => void
  onSuccess?: (data: UpdateBillResponseData, variables: UpdateBillPayload) => void
}

export const useUpdateBill = (props?: UseUpdateBillProps) => {
  const { onError, onSuccess, onSettled } = props || {}
  const { mutate: update, ...rest } = useMutation(updateBill, {
    onSuccess,
    onError,
    onSettled,
  })

  return { update, ...rest }
}
