import React, { FC } from 'react'

interface Source {
  id: string
  value: number
  percent: number
}
interface Target {
  id: string
  value: number
  percent: number
}
interface Props {
  source: Source
  target: Target
  getTrackProps(): any
}

export const Track: FC<Props> = ({ source, target, getTrackProps }) => (
  <div
    className="date-range-slider-track"
    style={{
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`,
    }}
    {...getTrackProps()}
  />
)
