import { ModalConfirmation, ModalConfirmationProps, notify, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../../../enums/notificationKeys'
import { PaymentMethodType } from '../../../../enums/paymentMethodType'
import { useDisablePaymentMethod } from '../../../../hooks/useDisablePaymentMethod'

type DisableMobilePayModalProps = ModalConfirmationProps

export const DisableMobilePayModal = (modalProps: DisableMobilePayModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeDisableMobilePayModal } = useModal(modalProps.id)
  const { disablePaymentMethod, isProcessing } = useDisablePaymentMethod({
    paymentMethodType: PaymentMethodType.MobilePay,
    onSuccess: () => {
      closeDisableMobilePayModal()
      notify({
        id: NotificationKeys.RemoveMobilePayPaymentMethod,
        message: t('integrations.card.mobile_pay.remove_payment_method.notification'),
        variant: 'success',
      })
    },
  })

  const handleRemovePaymentMethodButtonClick = useCallback(() => {
    disablePaymentMethod()
  }, [disablePaymentMethod])

  return (
    <ModalConfirmation
      {...modalProps}
      cancelLabel={t('integrations.card.mobile_pay.disable_modal.cancel_button')}
      danger
      message={t('integrations.card.mobile_pay.disable_modal.body')}
      okLabel={t('integrations.card.mobile_pay.disable_modal.remove_button')}
      okLoading={isProcessing}
      onOk={handleRemovePaymentMethodButtonClick}
      title={t('integrations.card.mobile_pay.disable_modal.title')}
    />
  )
}
