import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconMenetoAccounting = ({ color = 'inherit', width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-meneto-accounting')} {...rest}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      color={coloring(color)}
    >
      <line x1="18" y1="20" x2="18" y2="10" fill="none"></line>
      <line x1="12" y1="20" x2="12" y2="4" fill="none"></line>
      <line x1="6" y1="20" x2="6" y2="14" fill="none"></line>
    </svg>
  </Box>
)
