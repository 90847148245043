import { decodePeriodValue } from '@components'

import { endOfMonth, format, startOfMonth } from 'date-fns'

import { PeriodMode } from '../../../enums/periodMode'
import { getStartEndDateByQuarter } from './getStartEndDateByQuarter'

interface StartEndFromPeriod {
  fromDate: string
  toDate: string
}

const DATE_FORMAT = 'yyyy-MM-dd'

export const getStartEndDateFromPeriod = (periodString: string): StartEndFromPeriod | undefined => {
  const decodedPeriod = decodePeriodValue(periodString)

  if (decodedPeriod?.mode === PeriodMode.Month) {
    const selectedDate = new Date(decodedPeriod?.year as number, decodedPeriod?.value as number)

    return {
      fromDate: format(startOfMonth(selectedDate), DATE_FORMAT),
      toDate: format(endOfMonth(selectedDate), DATE_FORMAT),
    }
  }

  if (decodedPeriod?.mode === PeriodMode.Quarter) {
    const { startDate, endDate } = getStartEndDateByQuarter(decodedPeriod?.year, decodedPeriod?.value)

    return {
      fromDate: format(startDate, DATE_FORMAT),
      toDate: format(endDate, DATE_FORMAT),
    }
  }
}
