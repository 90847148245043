import { useCallback, useEffect, useMemo, useRef } from 'react'

import { BulkNavigationQueryParams } from '../../../hooks/useBulkNavigationQueryParams'
import { Paging } from '../../../types/metable'

interface Item {
  id: string
}

interface UseBulkNavigationProps {
  currentId?: string
  isLoading?: boolean
  items: Item[]
  onPageChange?: (id?: string) => void
  onQueryParamsChange?: (queryParams: Partial<BulkNavigationQueryParams>) => void
  page: number
  pageSize: number
  pagination?: Paging
}

type ClickedDirection = 'previous' | 'next'

export const useBulkNavigation = ({
  currentId,
  isLoading,
  items,
  onPageChange,
  onQueryParamsChange,
  page,
  pageSize,
  pagination,
}: UseBulkNavigationProps) => {
  const previousItemId = useRef<string>()
  const nextItemId = useRef<string>()
  const hasPageChanged = useRef<boolean>(false)
  const clickedDirection = useRef<ClickedDirection>()

  const currentItemIndex = useMemo(() => {
    if (isLoading) {
      return -1
    }

    if (hasPageChanged.current) {
      return clickedDirection.current === 'next' ? -1 : items.length
    }

    return items.findIndex(({ id }) => id === currentId)
  }, [currentId, items, isLoading])
  const currentItemNumber = pageSize * (page - 1) + currentItemIndex + 1
  const itemsTotal = useMemo(() => (pagination?.total ? pagination.total : 0), [pagination?.total])
  const hasPrevious = currentItemNumber > 1
  const hasNext = currentItemNumber < itemsTotal

  const handleNext = useCallback(() => {
    const nextDraftBillIndex = currentItemIndex + 1
    const isLastBillOnPage = nextDraftBillIndex === items.length

    clickedDirection.current = 'next'
    nextItemId.current = items[nextDraftBillIndex]?.id

    if (!hasPageChanged.current) {
      previousItemId.current = currentId
    }

    if (isLastBillOnPage) {
      onQueryParamsChange?.({
        bulkPage: page + 1,
      })
      hasPageChanged.current = true

      return
    }

    onPageChange?.(nextItemId.current)
  }, [currentItemIndex, items, onPageChange, currentId, onQueryParamsChange, page])

  const handlePrevious = useCallback(() => {
    const previousDraftBillIndex = currentItemIndex - 1
    const isFirstBillOnPage = currentItemIndex === 0
    const isPreviousPageExists = page > 1

    clickedDirection.current = 'previous'
    previousItemId.current = items[previousDraftBillIndex]?.id

    if (!hasPageChanged.current) {
      nextItemId.current = currentId
    }

    if (!isPreviousPageExists && isFirstBillOnPage) {
      return
    }

    if (isFirstBillOnPage) {
      const newPage = page - 1
      onQueryParamsChange?.({
        bulkPage: newPage,
      })
      hasPageChanged.current = true

      return
    }

    onPageChange?.(previousItemId.current)
  }, [currentItemIndex, page, items, onPageChange, currentId, onQueryParamsChange])

  useEffect(() => {
    if (hasPageChanged.current && !isLoading) {
      switch (clickedDirection.current) {
        case 'next':
          handleNext()
          break
        case 'previous':
          handlePrevious()
          break
        // no-default
      }

      hasPageChanged.current = false
    }
  }, [handleNext, handlePrevious, isLoading, page])

  return {
    itemsToGo: itemsTotal - currentItemNumber,
    next: handleNext,
    previous: handlePrevious,
    hasNext,
    hasPrevious,
  }
}
