import React, { forwardRef, ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton } from '../IconButton'
import { Input, InputProps, InputRef } from '../Input'
import { Tooltip } from '../Tooltip'

export type InputPasswordProps = Omit<InputProps, 'suffix' | 'type'>

export const InputPassword = forwardRef<InputRef, InputPasswordProps>(
  (props: InputPasswordProps, forwardedRef): ReactElement => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const { t } = useTranslation()

    const handleIconButtonClick = useCallback(() => {
      setIsPasswordVisible((prevIsPasswordVisible) => !prevIsPasswordVisible)
    }, [])

    return (
      <Input
        {...props}
        ref={forwardedRef}
        type={isPasswordVisible ? 'text' : 'password'}
        suffix={
          <Tooltip label={isPasswordVisible ? t('ds.input_password.hide') : t('ds.input_password.show')}>
            <IconButton size="m" icon={isPasswordVisible ? 'eyeCrossed' : 'eye'} onClick={handleIconButtonClick} />
          </Tooltip>
        }
      />
    )
  },
)
