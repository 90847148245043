import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconTaskSchedules = ({
  color = '#fff',
  color2 = '#095a8c',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  isOutlined,
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-task-schedules')} {...rest}>
    <svg width={width} height={height} x="0px" y="0px" viewBox="0 0 367.45 291.22">
      <rect
        x="13.5"
        y="13.5"
        width="340.45"
        height="264.22"
        rx="37.83"
        strokeWidth="27"
        stroke={coloring(color)}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={isOutlined ? 'transparent' : coloring(color2)}
      />
      <line
        x1="101.57"
        y1="277.72"
        x2="101.57"
        y2="13.5"
        fill="none"
        stroke={coloring(color)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="27"
      />
      <line
        x1="105.39"
        y1="96.49"
        x2="353.95"
        y2="96.49"
        fill="none"
        stroke={coloring(color)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="27"
      />
    </svg>
  </Box>
)
