import { parse } from 'date-fns'

import { getDateFormat } from './getDateFormat'

const SPECIAL_CHARACTERS_REGEXP = /[^\w ]/g

export const getParsedDateFromString = (value: string): Date => {
  const valueClean = value.replace(SPECIAL_CHARACTERS_REGEXP, '')
  return parse(valueClean, getDateFormat(valueClean), new Date())
}
