import { Button } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../../../../../hooks/useForm'
import { useCreateOrEditVatAccountModal } from '../../context/createOrEditVatAccountModalContext'
import { CreateOrEditVatAccountFormSchema } from '../../utils/formData'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { submitForm, isLoading } = useCreateOrEditVatAccountModal()
  const { control } = useFormContext<CreateOrEditVatAccountFormSchema>()
  const priority = useWatch({ control, name: 'priority' })
  const account = useWatch({ control, name: 'account' })
  const type = useWatch({ control, name: 'type' })

  const isDisabled = priority === undefined || !(account && account.id) || type === ''

  return (
    <Button type="button" onClick={submitForm} loading={isLoading} disabled={isDisabled}>
      {t('save')}
    </Button>
  )
}
