import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const MenuBadgeWrapper = styled.div`
  display: flex;
  margin-top: -${Spacing.S};
  margin-bottom: ${Spacing.L};
  justify-content: center;
`
