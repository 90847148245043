import { css, SerializedStyles } from '@emotion/core'

import { StyledComponent } from '../../../types/styledComponent'
import { Alignment } from '../types/alignment'

export const getAlignmentStyle = (alignment: Alignment, Input?: StyledComponent): SerializedStyles => css`
  ${Input || '&'} {
    text-align: ${alignment === 'left' ? 'left' : 'right'};
  }
`
