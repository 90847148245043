import { TFunction } from 'i18next'

import { PayrollStatus } from '../../../types/payrollStatus'

export const getStatusToLabel = (t: TFunction): Record<PayrollStatus, string> => ({
  [PayrollStatus.Approved]: t('salary.status.approved'),
  [PayrollStatus.AutomaticApproval]: t('salary.status.automatic_approval'),
  [PayrollStatus.Awaiting]: t('salary.status.awaiting'),
  [PayrollStatus.AwaitingApproval]: t('salary.status.awaiting_approval'),
  [PayrollStatus.Cancelled]: t('salary.status.cancelled'),
  [PayrollStatus.Failed]: t('salary.status.failed'),
  [PayrollStatus.MissingApproval]: t('salary.status.missing_approval'),
  [PayrollStatus.Rejected]: t('salary.status.rejected'),
  [PayrollStatus.Reviewed]: t('salary.status.reviewed'),
  [PayrollStatus.Success]: t('salary.status.success'),
  [PayrollStatus.Tentative]: t('salary.status.tentative'),
  [PayrollStatus.Unknown]: t('salary.status.unknown'),
})
