import qs from 'qs'

import { ContactPerson } from '@modules-deprecated/app/contactPerson/types/contactPerson'

import { getRequest } from '../utils'

interface ContactPersonsResponse {
  contactPersons: ContactPerson[]
}

export interface FetchContactPersonsOptions {
  contactId: string
  q?: string
  page?: number
  pageSize?: number
}

export const fetchContactPersons = ({
  contactId,
  q,
  page,
  pageSize,
}: FetchContactPersonsOptions): Promise<ContactPersonsResponse> => {
  const queryParams = qs.stringify({
    ...(contactId ? { contactId } : {}),
    ...(page ? { page } : {}),
    ...(pageSize ? { pageSize } : {}),
    ...(q ? { q } : {}),
  })

  return getRequest(`/v2/contactPersons?${queryParams}`)
}
