import { Button } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Scope } from '../../../../../../enums/scope'
import { isAuthorized } from '../../../../../../utils/isAuthorized'

interface ManageUploadButtonProps {
  onClick: () => void
}

export const ManageUploadButton = ({ onClick }: ManageUploadButtonProps): ReactElement => {
  const { t } = useTranslation()

  const isEnabled = isAuthorized(Scope.BillWrite)

  const handleButtonClick = useCallback(() => {
    onClick()
  }, [onClick])

  return (
    <Button disabled={!isEnabled} fullWidth onClick={handleButtonClick} size="m">
      {t('receipts.modal.manage_now')}
    </Button>
  )
}
