import { ModalConfirmation, ModalConfirmationProps, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteOrganizationLogo } from '../../../../../../../../../OrganizationSettings/hooks/useDeleteOrganizationLogo'

type DeleteLogoModalProps = ModalConfirmationProps

export const DeleteLogoModal = (modalProps: DeleteLogoModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeDeleteLogoModal } = useModal(modalProps.id)
  const { deleteOrganizationLogo, isProcessing } = useDeleteOrganizationLogo({
    onSuccess: () => {
      closeDeleteLogoModal()
    },
  })

  const handleDeleteLogoButtonClick = useCallback(() => {
    deleteOrganizationLogo()
  }, [deleteOrganizationLogo])

  return (
    <ModalConfirmation
      {...modalProps}
      cancelLabel={t('settings.organization.organization_info.logo.delete_modal.cancel_button')}
      danger
      message={t('settings.organization.organization_info.logo.delete_modal.body')}
      okLabel={t('settings.organization.organization_info.logo.delete_modal.delete_button')}
      okLoading={isProcessing}
      onOk={handleDeleteLogoButtonClick}
      title={t('settings.organization.organization_info.logo.delete_modal.title')}
    />
  )
}
