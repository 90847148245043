import React, { createContext, ReactElement, ReactNode, useContext, useState } from 'react'

type ContextState = [currentPage: number, setCurrentPage: (index: number) => void]

const BillAttachmentPageContext = createContext<ContextState | undefined>(undefined)

interface BillAttachmentPageContextProps {
  children?: ReactNode
}

export const BillAttachmentPageContextProvider = ({ children }: BillAttachmentPageContextProps): ReactElement => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  return (
    <BillAttachmentPageContext.Provider value={[currentPageIndex, setCurrentPageIndex]}>
      {children}
    </BillAttachmentPageContext.Provider>
  )
}

export const useBillAttachmentPageContext = () => {
  const context = useContext(BillAttachmentPageContext)

  if (!context) {
    throw new Error('BillAttachmentPageContextProvider is missing in the module!')
  }

  return context
}
