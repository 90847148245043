import { css } from '@emotion/core'
import React from 'react'
import { Flex, FlexProps } from 'rebass'

import { reactClass } from '../../../utils'

type DroppableContainerProps = FlexProps & {
  highlightOnDraggingOver?: boolean
  isDraggingOver?: boolean
  draggingOverWith?: string | null
  draggingFromThisWith?: string | null
  css?: any
}

export const DroppableContainer = React.forwardRef(
  (
    {
      children,
      draggingFromThisWith,
      draggingOverWith,
      highlightOnDraggingOver,
      isDraggingOver,
      css: outsideCss,
      ...rest
    }: DroppableContainerProps,
    ref,
  ) => (
    <Flex
      className={reactClass('droppable-container')}
      ref={ref}
      css={[
        css`
          position: relative;
          flex-direction: column;
          min-height: 30px;
        `,
        outsideCss,
      ]}
      {...rest}
    >
      {children}
    </Flex>
  ),
)
