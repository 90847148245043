import styled from '@emotion/styled'

export const TableWrapper = styled.div`
  width: 100%;
`

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: right;
`
