import React, { Children, forwardRef, isValidElement, ReactElement, ReactNode, Ref } from 'react'

import { Spacing } from '../../enums/spacing'
import * as Styled from './styles'

export interface ComponentsGroupProps {
  centered?: boolean
  children: ReactNode
  direction?: Styled.Direction
  fitToWidth?: boolean
  merged?: boolean
  spacing?: Spacing
}

export const ComponentsGroup = forwardRef(
  (
    { centered = true, children, direction = 'row', fitToWidth, merged, spacing, ...rest }: ComponentsGroupProps,
    forwardedRef: Ref<HTMLUListElement>,
  ): ReactElement => (
    <Styled.ComponentsGroupList
      direction={direction}
      spacing={spacing}
      centered={centered}
      ref={forwardedRef}
      {...rest}
    >
      {Children.map(
        children,
        (child) =>
          isValidElement(child) && (
            <Styled.ComponentsGroupItem fitToWidth={fitToWidth}>{child}</Styled.ComponentsGroupItem>
          ),
      )}
    </Styled.ComponentsGroupList>
  ),
)
