import { css } from '@emotion/core'

import { ColorOrCurrent } from '../../../types/colorOrCurrent'
import { getDefaultTransition } from '../../../utils/getDefaultTransition'

export const getIconColorStyles = (color: ColorOrCurrent = 'currentColor') => css`
  path,
  rect {
    fill: ${color};
    ${getDefaultTransition('fill')}
  }
`
