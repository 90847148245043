export enum BillsTableAccessor {
  Attachment = 'attachments',
  Balance = 'balance',
  Date = 'entryDate',
  Description = 'lineDescription',
  DueDate = 'dueDate',
  ExcludedVat = 'amount',
  IncludedVat = 'grossAmount',
  RefNo = 'refNo',
  Status = 'status',
  Vendor = 'contact.name',
  VoucherNo = 'voucherNo',
}
