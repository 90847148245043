import { User } from '@modules-deprecated/app/user'

import { postRequest } from '../utils'
import { SignupAuth, SignupMeta } from './signup-query'

export interface SubmitLoginResponseData {
  user: User
  meta: SignupMeta
  oAuthActors: SignupAuth[]
  analytics: string
}

export interface SubmitLoginPayload {
  email: string
  password: string
  remember?: boolean
}

export const submitLogin = (payload: SubmitLoginPayload): Promise<SubmitLoginResponseData> => {
  return postRequest('/v2/user/login', { credentials: payload }, undefined, false)
}
