import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../types/themable'

export const SkatBoxWrapper = styled.div<Themable>`
  background-color: ${Color.White};
  border-radius: ${DefaultValue.BorderRadius};
`

export const SkatLogo = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: ${Spacing.M};
`
