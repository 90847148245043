import { encodePeriodValue } from '@components'
import { ItemsPerPage, useItemsPerPage } from '@design-system'

import { useMemo } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useGlobalFiscalYear } from '../../../../../contexts/globalFiscalYearContext'
import { SortDirection } from '../../../../../enums/sortDirection'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { getPeriodValueFromFiscalYear } from '../../../../../utils/getPeriodValueFromFiscalYear'
import { BillStatus } from '../../../enums/billStatus'
import { BillsSortProperty } from '../../../query-api'

export interface BillsListFilters {
  contactId?: string
  contactName?: string
  page?: number
  pageSize?: ItemsPerPage
  period?: string
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: BillsSortProperty
  status?: BillStatus
}

type UseBillsFiltersResponse = [BillsListFilters, (filters: Partial<BillsListFilters>) => void]

export const defaultQuery: BillsListFilters = {
  contactId: undefined,
  contactName: undefined,
  page: 1,
  pageSize: undefined,
  period: undefined,
  searchQuery: undefined,
  sortDirection: SortDirection.Desc,
  sortProperty: BillsSortProperty.EntryDate,
  status: BillStatus.All,
}

interface UseBillsListFiltersProps {
  enabled?: boolean
  sortDirection?: SortDirection
  sortProperty?: BillsSortProperty
  status?: BillStatus
}

export const useBillsListFilters = ({
  enabled = true,
  sortDirection,
  sortProperty,
  status,
}: UseBillsListFiltersProps = {}): UseBillsFiltersResponse => {
  const [pageSize] = useItemsPerPage()
  const { organization } = useUserOrganization()
  const { fiscalYear: globalFiscalYear } = useGlobalFiscalYear()
  const [queryParams, setQueryParams] = useQueryParams<BillsListFilters>({
    enabled,
    defaultQuery: {
      ...defaultQuery,
      sortDirection: sortDirection || defaultQuery.sortDirection,
      sortProperty: sortProperty || defaultQuery.sortProperty,
      status: status || defaultQuery.status,
      ...(globalFiscalYear && organization?.id
        ? { period: encodePeriodValue(getPeriodValueFromFiscalYear(globalFiscalYear), organization.id) }
        : {}),
      pageSize,
    },
  })

  return useMemo(
    () => [
      {
        ...queryParams,
        // Sometimes `page` comes as a string. It needs to be always `number` type.
        page: Number(queryParams.page),
        // Sometimes `pageSize` comes as a string. It needs to be always `number` type.
        pageSize: Number(queryParams.pageSize) as ItemsPerPage,
      },
      setQueryParams,
    ],
    [queryParams, setQueryParams],
  )
}
