import { TFunction } from 'i18next'
import * as yup from 'yup'

export const BillCreateSchema = (t: TFunction) =>
  yup.object().shape({
    vendor: yup
      .object()
      .shape({
        id: yup.string().required(t('voucher.inbox.form.vendor_error')),
      })
      .required(t('voucher.inbox.form.vendor_error')),
    billDate: yup.object().shape({
      value: yup
        .date()
        .typeError(t('voucher.inbox.form.bill_date_error'))
        .required(t('voucher.inbox.form.bill_date_error')),
      checked: yup.boolean(),
    }),
    paymentDate: yup.date().when('billDate.checked', {
      is: true,
      then: yup.date().required(t('voucher.inbox.form.payment_date_error')),
    }),
    paidFrom: yup.object().when('billDate.checked', {
      is: true,
      then: yup
        .object()
        .shape({
          id: yup.string().required(t('voucher.inbox.form.paid_from_error')),
        })
        .required(t('voucher.inbox.form.paid_from_error')),
    }),
    lines: yup
      .array()
      .of(
        yup.object().shape({
          account: yup
            .object()
            .shape({
              id: yup.string().required(t('voucher.inbox.form.account_error')),
            })
            .required(t('voucher.inbox.form.account_error')),
          amount: yup
            .object()
            .shape({
              value: yup
                .number()
                .typeError(t('voucher.inbox.form.amount_error'))
                .positive(t('voucher.inbox.form.amount_error'))
                .required(t('voucher.inbox.form.amount_error')),
              currency: yup.string().required(t('app.currencies.currency_selector.select_currency')),
            })
            .required(),
          description: yup.string().required(t('voucher.inbox.form.description_error')),
          taxRate: yup
            .object()
            .shape({
              id: yup.string().required(t('voucher.inbox.form.vat_rate_error')),
            })
            .required(t('voucher.inbox.form.vat_rate_error')),
        }),
      )
      .min(1),
  })
