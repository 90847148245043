import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { getBookkeepers } from '../query-api'

interface UseBookkeepersArgs {
  searchQuery?: string
}

export const useBookkeepers = ({ searchQuery }: UseBookkeepersArgs = {}) => {
  const { data, isIdle, isLoading } = useQuery([QueryKeys.Bookkeepers, searchQuery], () => getBookkeepers(searchQuery))

  return {
    data: data?.data || [],
    isLoading: isIdle || isLoading,
  }
}
