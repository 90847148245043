import { createContext, ReactElement, ReactNode, useContext, useState } from 'react'

type SidePanelContextState = {
  isOpen: boolean
  open: () => void
  close: () => void
}

type SidePanelContextProviderProps = {
  children: ReactNode
}

const SidePanelContext = createContext<SidePanelContextState | undefined>(undefined)

export const SidePanelContextProvider = ({ children }: SidePanelContextProviderProps): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  return <SidePanelContext.Provider value={{ isOpen, open, close }}>{children}</SidePanelContext.Provider>
}

export const useSidePanel = (): SidePanelContextState => {
  const context = useContext(SidePanelContext)
  if (context === undefined) {
    throw new Error('SidePanelContextProvider is missing in the module!')
  }
  return context
}
