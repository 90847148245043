import { SaveAccountPayload } from '../../../query-api'
import { AddAccountFormInputs } from '../types/types'

export const getSaveAccountPayload = (
  data: AddAccountFormInputs,
  organizationId?: string,
  accountId?: string,
): SaveAccountPayload => ({
  id: accountId,
  accountNo: data.accountNo,
  bankAccountNo: data.bankAccountNo,
  bankIban: data.bankIban,
  bankId: data.bank.id,
  bankName: data.bank.name,
  bankRoutingNo: data.routingNumber,
  bankSwift: data.bankSwift,
  currencyId: data.currency?.id,
  description: data.description,
  groupId: data.accountGroup?.id,
  isArchived: false,
  isBankAccount: data.isBankAccount,
  isPaymentEnabled: data.isPaymentEnabled,
  name: data.accountName,
  natureId: data.accountGroup?.natureId,
  organizationId,
  taxRateId: data.vatRate?.id,
  publicAccountId: data.publicAccountId,
})
