import React, { ReactElement } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CreateOrganizationForm } from '../../utils/formData'
import * as Styled from './styles'

interface OrganizationCVRDataProps {
  formControl: Control<CreateOrganizationForm>
  visible?: boolean
}

export const OrganizationCVRData = ({ formControl, visible }: OrganizationCVRDataProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.OrganizationCVRData visible={visible}>
      <Styled.Input
        formControl={formControl}
        name="orgStreet"
        label={t('signup_guide.create_org.form.address.label')}
        inputSize="xl"
        disabled
      />

      <Styled.DualInputsWrapper>
        <Styled.Input formControl={formControl} name="orgZipcode" inputSize="xl" disabled />
        <Styled.Input formControl={formControl} name="orgCity" inputSize="xl" disabled />
      </Styled.DualInputsWrapper>

      {/* Hidden fields - needed for react-hook-form */}
      <Styled.Input formControl={formControl} name="orgFiscalYearEndMonth" hidden />
      <Styled.Input formControl={formControl} name="orgFirstFiscalYearStart" hidden />
      <Styled.Input formControl={formControl} name="orgFirstFiscalYearEnd" hidden />
    </Styled.OrganizationCVRData>
  )
}
