export const sortByDateKey = <T>(value: T[], key: keyof T) =>
  value.sort((a: T, b: T) => {
    const aValue = a[key]
    const bValue = b[key]

    if (
      (aValue instanceof Date || typeof aValue === 'string') &&
      (bValue instanceof Date || typeof bValue === 'string')
    ) {
      return new Date(bValue).getTime() - new Date(aValue).getTime()
    }

    return 0
  })
