import { ReceiptsCountBadge } from '@views/receipts/elements/ReceiptsCountBadge'
import { ToDoCountBadge } from '@views/thingsToDo/elements/ToDoCountBadge'

import { EmberRoute } from '../../../../../enums/emberRoute'
import { ReactRoute } from '../../../../../enums/reactRoute'
import { Scope } from '../../../../../enums/scope'
import { getOrganizationViewPathPlaceholder } from '../../../../../utils/getOrganizationViewPath'
import { MenuItem } from '../types/menuItem'

export const menuItems: Record<string, MenuItem> = {
  creditors: {
    tLabel: 'creditors',
    to: getOrganizationViewPathPlaceholder(ReactRoute.Creditors),
  },
  dashboard: {
    tLabel: 'dashboard',
    icon: 'dashboard',
    routeTo: EmberRoute.Dashboard,
  },
  debtors: {
    tLabel: 'debtors',
    scopes: Scope.InvoiceRead,
    to: getOrganizationViewPathPlaceholder(ReactRoute.Debtors),
  },
  sales: {
    tLabel: 'sales',
    icon: 'wallet',
    routeTo: EmberRoute.Invoices,
  },
  invoices: {
    tLabel: 'invoices',
    routeTo: EmberRoute.Invoices,
    scopes: Scope.InvoiceRead,
  },
  quotes: {
    tLabel: 'quotes',
    routeTo: EmberRoute.Quotes,
    scopes: Scope.OfferRead,
  },
  recurringInvoices: {
    tLabel: 'recurring_invoices',
    routeTo: EmberRoute.RecurringInvoices,
    scopes: Scope.RecurringInvoiceRead,
  },
  products: {
    tLabel: 'products',
    routeTo: EmberRoute.Products,
    scopes: Scope.ProductRead,
  },
  expenses: {
    tLabel: 'expenses',
    icon: 'bills',
    routeTo: EmberRoute.Bills,
    scopes: Scope.BillRead,
  },
  vouchers: {
    tLabel: 'expenses',
    icon: 'bills',
    routeTo: EmberRoute.Vouchers,
  },
  vouchersList: {
    tLabel: 'vouchers',
    icon: 'bills',
    routeTo: EmberRoute.Vouchers,
  },
  uploads: {
    tLabel: 'uploads',
    icon: 'picture',
    routeTo: EmberRoute.Uploads,
    badge: <ReceiptsCountBadge />,
    scopes: Scope.UploadsRead,
  },
  bank: {
    tLabel: 'bank',
    icon: 'bank',
    routeTo: EmberRoute.Bank,
    scopes: Scope.BankReconciliationRead,
  },
  bankReact: {
    tLabel: 'bank',
    icon: 'bank',
    routeTo: EmberRoute.BankReact,
    scopes: Scope.BankReconciliationRead,
  },
  bankReconciliation: {
    tLabel: 'reconciliation',
    routeTo: EmberRoute.Bank,
    scopes: Scope.BankReconciliationRead,
  },
  vatSettlements: {
    tLabel: 'vat_settlements',
    routeTo: EmberRoute.TaxReturns,
    scopes: Scope.VatReturnRead,
  },
  vatSettlementsSkat: {
    tLabel: 'vat_settlements',
    routeTo: EmberRoute.TaxReturnsSkat,
    scopes: Scope.VatReturnRead,
  },
  accounting: {
    tLabel: 'accounting',
    icon: 'notepadChart',
    routeTo: EmberRoute.Reports,
  },
  reports: {
    tLabel: 'reports',
    routeTo: EmberRoute.Reports,
    scopes: Scope.ReportsRead,
  },
  incomeStatement: {
    tLabel: 'income_statement',
    routeTo: EmberRoute.IncomeStatement,
    scopes: Scope.ReportsRead,
  },
  balance: {
    tLabel: 'balance',
    routeTo: EmberRoute.BalanceSheet,
    scopes: Scope.ReportsRead,
  },
  trialBalance: {
    tLabel: 'trial_balance',
    routeTo: EmberRoute.TrialBalance,
    scopes: Scope.ReportsRead,
  },
  exports: {
    tLabel: 'exports',
    routeTo: EmberRoute.Exports,
    scopes: Scope.ExportRead,
  },
  bookkeeping: {
    tLabel: 'bookkeeping',
    icon: 'calculator',
    routeTo: EmberRoute.Daybooks,
  },
  daybook: {
    tLabel: 'daybook',
    routeTo: EmberRoute.Daybooks,
    scopes: Scope.DaybookWrite,
  },
  transactions: {
    tLabel: 'transactions',
    routeTo: EmberRoute.Transactions,
    scopes: Scope.TransactionRead,
  },
  contacts: {
    tLabel: 'contacts',
    icon: 'people',
    routeTo: EmberRoute.Contacts,
    scopes: Scope.ContactRead,
  },
  integrations: {
    tLabel: 'integrations',
    routeTo: EmberRoute.Integrations,
    scopes: Scope.OrganizationFeatureWrite,
  },
  customers: {
    tLabel: 'customer_overview',
    icon: 'suitcase',
    routeTo: EmberRoute.UmbrellaOrganizations,
  },
  users: {
    tLabel: 'users',
    icon: 'user',
    routeTo: EmberRoute.UmbrellaUsers,
  },
  roles: {
    tLabel: 'roles',
    icon: 'userLock',
    routeTo: EmberRoute.UmbrellaRoles,
  },
  inbox: {
    tLabel: 'inbox',
    icon: 'inbox',
    routeTo: EmberRoute.InboxVouchers,
  },
  thingsToDo: {
    tLabel: 'things_to_do',
    icon: 'schedule',
    routeTo: EmberRoute.ThingsToDo,
    badge: <ToDoCountBadge />,
  },
  annualReports: {
    tLabel: 'annual_report',
    routeTo: EmberRoute.AnnualReports,
  },
  bills: {
    tLabel: 'bills',
    routeTo: EmberRoute.Bills,
  },
  salary: {
    tLabel: 'payroll',
    routeTo: EmberRoute.SalaryIntegration,
    scopes: Scope.SalaryRead,
  },
  umbrellaProfile: {
    tLabel: 'umbrella_profile',
    icon: 'userWithTie',
    routeTo: EmberRoute.UmbrellaProfile,
  },
  services: {
    tLabel: 'services',
    icon: 'chainDisconnected',
    routeTo: EmberRoute.Integrations,
    // @todo doubled scopes-check
    scopes: Scope.ServicesRead,
    oldScopes: Scope.OrganizationFeatureWrite,
  },
  findAccountant: {
    tLabel: 'find_accountant',
    routeTo: EmberRoute.FindAccountant,
    scopes: Scope.FindAccountant,
  },
  umbrellaSubscriptions: {
    tLabel: 'umbrella_subscriptions',
    icon: 'squaresWithArrow',
    routeTo: EmberRoute.UmbrellaSubscriptions,
  },
  loans: {
    tLabel: 'get_a_loan',
    routeTo: EmberRoute.Financing,
    scopes: Scope.LoansWrite,
  },
}
