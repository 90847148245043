import { RefObject, useLayoutEffect } from 'react'
import { useWindowSize } from 'react-use'

import { useMeasureDirty } from '../../../hooks/useMeasureDirty'

export const useCalculateStickyMaxHeight = (
  wrapperRef: RefObject<HTMLElement>,
  stickyElementRef: RefObject<HTMLElement>,
  isEnabled?: boolean,
) => {
  const { height: wrapperHeight } = useMeasureDirty(wrapperRef)
  const { height: windowHeight } = useWindowSize()

  useLayoutEffect(() => {
    const wrapperElement = wrapperRef.current
    const stickyElement = stickyElementRef.current

    if (isEnabled && stickyElement && wrapperElement) {
      const { top: elementTopDistance } = stickyElement.getBoundingClientRect()
      const stickyElementMaxHeight = windowHeight - elementTopDistance
      stickyElement.style.maxHeight = `${stickyElementMaxHeight}px`
    }
  }, [wrapperRef, windowHeight, stickyElementRef, isEnabled, wrapperHeight])
}
