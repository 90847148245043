import { Account } from '@modules-deprecated/app/accounts/types'

import { deleteRequest, postRequest, putRequest } from '../../../../utils'

export interface SaveAccountPayload {
  accountNo: number | null
  bankAccountNo?: string | null
  bankIban?: string
  bankId?: string
  bankName?: string
  bankRoutingNo?: number | null
  bankSwift?: string
  currencyId?: string
  description?: string
  groupId?: string
  id?: string
  isArchived?: boolean
  isBankAccount?: boolean
  isPaymentEnabled?: boolean
  name: string
  natureId?: string
  organizationId?: string
  publicAccountId?: string | null
  taxRateId?: string
}

export const saveAccount = (accountData: SaveAccountPayload) => postRequest('/v2/accounts', { account: accountData })

export interface SetUpdateAccountPayload {
  account: Account
  accountId: string
}

export const updateAccount = (accountData: Partial<SaveAccountPayload>) =>
  putRequest(`/v2/accounts/${accountData.id}`, { account: accountData })

export const deleteAccount = (accountId: string) => deleteRequest(`/v2/accounts/${accountId}`)

export interface SetAccountArchivedStatusPayload {
  accountId: string
  isArchived: boolean
}

export const setAccountArchivedStatus = ({ accountId, isArchived }: SetAccountArchivedStatusPayload) =>
  putRequest(`/v2/accounts/${accountId}`, {
    account: {
      id: accountId,
      isArchived,
    },
  })
