import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const MenuItemsWrapper = styled.div`
  margin-right: -${Spacing.L};
  margin-bottom: auto;
  margin-left: -${Spacing.L};
  padding: 0 ${Spacing.L};
  overflow: auto;
  user-select: none;
`
