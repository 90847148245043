import { Text } from '@design-system'

import React, { ReactElement } from 'react'

import * as Styled from './styles'
import { UploadFilesErrors } from './types/uploadFilesErrors'

interface UploadFilesErrorMessageProps {
  uploadFilesErrors: UploadFilesErrors
}

export const UploadFilesErrorMessage = ({ uploadFilesErrors }: UploadFilesErrorMessageProps): ReactElement => (
  <Styled.UploadFilesErrorMessageWrapper>
    {Object.keys(uploadFilesErrors).map((errorFileName: string, index) => (
      <Styled.UploadFileErrorItem key={index}>
        <Text inline weight="medium">
          {errorFileName}
        </Text>
        <Text inline>{`: ${uploadFilesErrors[errorFileName]}`}</Text>
      </Styled.UploadFileErrorItem>
    ))}
  </Styled.UploadFilesErrorMessageWrapper>
)
