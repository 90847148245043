import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconBookkeeping = ({
  color = '#dde1e3',
  color2 = '#fff',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  isOutlined,
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-bookkeeping')} {...rest}>
    <svg width={width} height={height} x="0px" y="0px" viewBox="0 0 415.38 352">
      <rect
        x="167.38"
        y="141"
        width="238"
        height="70"
        rx="35"
        fill={isOutlined ? 'transparent' : coloring(color)}
        stroke={coloring(color2)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <rect
        x="10"
        y="141"
        width="121.38"
        height="70"
        rx="35"
        fill={isOutlined ? 'transparent' : coloring(color)}
        stroke={coloring(color2)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <rect
        x="167.38"
        y="272"
        width="238"
        height="70"
        rx="35"
        fill={isOutlined ? 'transparent' : coloring(color)}
        stroke={coloring(color2)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <rect
        x="10"
        y="272"
        width="121.38"
        height="70"
        rx="35"
        fill={isOutlined ? 'transparent' : coloring(color)}
        stroke={coloring(color2)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <rect
        x="167.38"
        y="10"
        width="238"
        height="70"
        rx="35"
        fill={isOutlined ? 'transparent' : coloring(color)}
        stroke={coloring(color2)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <rect
        x="10"
        y="10"
        width="121.38"
        height="70"
        rx="35"
        fill={isOutlined ? 'transparent' : coloring(color)}
        stroke={coloring(color2)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
    </svg>
  </Box>
)
