import noop from 'lodash/noop'
import React, { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { EmberEventFn } from '../../../types/emberEventFn'

interface ContextState {
  openSendInvoiceModal: () => void
  voidInvoice: () => void
  voidAndDuplicateInvoice: () => void
}

const defaultValue: ContextState = {
  openSendInvoiceModal: noop,
  voidInvoice: noop,
  voidAndDuplicateInvoice: noop,
}

const EInvoiceStatusConnectionContext = createContext(defaultValue)

interface EInvoiceStatusConnectionContextProps {
  children?: ReactNode
  onVoidInvoice: EmberEventFn
  onVoidAndDuplicateInvoice: EmberEventFn
  onOpenSendInvoiceModal: EmberEventFn
}

export const EInvoiceStatusConnectionContextProvider = ({
  children,
  onOpenSendInvoiceModal,
  onVoidInvoice,
  onVoidAndDuplicateInvoice,
}: EInvoiceStatusConnectionContextProps): ReactElement => {
  const openSendInvoiceModal = useCallback(() => {
    onOpenSendInvoiceModal({ detail: null })
  }, [onOpenSendInvoiceModal])

  const voidInvoice = useCallback(() => {
    onVoidInvoice({ detail: null })
  }, [onVoidInvoice])

  const voidAndDuplicateInvoice = useCallback(() => {
    onVoidAndDuplicateInvoice({ detail: null })
  }, [onVoidAndDuplicateInvoice])

  return (
    <EInvoiceStatusConnectionContext.Provider value={{ openSendInvoiceModal, voidInvoice, voidAndDuplicateInvoice }}>
      {children}
    </EInvoiceStatusConnectionContext.Provider>
  )
}

export const useEInvoiceStatusConnection = () => useContext(EInvoiceStatusConnectionContext)
