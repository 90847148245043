import { Color, DefaultValue, getDefaultTransition, Icon, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { Input } from '../Form'

export const IconMagnifier = styled(Icon)`
  position: absolute;
  left: ${Spacing.M};
  top: ${Spacing.M};
  cursor: pointer;
`

export const IconX = styled(Icon)`
  position: absolute;
  top: ${Spacing.M};
  right: ${Spacing.M};
  left: auto;
  cursor: pointer;
`

interface SearchInputProps {
  isActive?: boolean
}

export const SearchInput = styled(Input)<SearchInputProps>`
  margin-bottom: 0;

  input {
    height: 40px;
    border: 1px solid ${Color.Gray50};
    border-radius: ${DefaultValue.BorderRadius};
    background-color: ${Color.White};
    border: 1px solid ${Color.Gray50};
    padding: ${Spacing.M};
    width: 0;
    opacity: 0;
    pointer-events: none;
    padding-left: ${Spacing.XXXL};
    ${getDefaultTransition('width', 'opacity')}

    ${({ isActive }) => {
      if (isActive) {
        return `
        pointer-events: all;
        width: 304px;
        opacity: 1;
      `
      }
    }}
  }
`

export const LoupeInputWrapper = styled.div`
  position: relative;
`
