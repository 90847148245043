import { notify } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateOrganization, useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'

interface UseDeleteOrganizationLogoProps {
  onSuccess?: () => void
  onError?: () => void
}

export const useDeleteOrganizationLogo = ({ onSuccess, onError }: UseDeleteOrganizationLogoProps) => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const {
    update: updateOrganization,
    isProcessing,
    ...rest
  } = useUpdateOrganization({
    onSuccess: () => {
      onSuccess?.()
      notify({
        id: NotificationKeys.OrganizationLogoDelete,
        message: t('settings.organization.organization_info.notification.update_success'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.UserOrganization)
    },
    onError: () => {
      onError?.()
      notify({ id: NotificationKeys.OrganizationLogoDelete, message: t('error_saving'), variant: 'error' })
    },
  })

  const deleteOrganizationLogo = useCallback(() => {
    if (!organization?.id) {
      return
    }

    updateOrganization({
      organizationId: organization.id,
      payload: {
        logoFileId: null,
      },
    })
  }, [organization?.id, updateOrganization])

  return { deleteOrganizationLogo, isProcessing, ...rest }
}
