import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { submitLogin, SubmitLoginPayload, SubmitLoginResponseData } from '../query-api/login-query'
import { APIError } from '../utils'

interface UseLoginProps {
  onError?: (error?: APIError) => void
  onSuccess?: (response: SubmitLoginResponseData) => void
}

export const useLogin = (props?: UseLoginProps) => {
  const { onSuccess, onError } = props || {}

  const { mutate, ...rest } = useMutation(submitLogin, {
    onSuccess,
    onError,
  })

  const login = useCallback(
    (payload: SubmitLoginPayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    login,
    ...rest,
  }
}
