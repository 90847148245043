export enum TableIds {
  AccessTokens = 'access-tokens',
  AccountStatementsTable = 'account-statements-table',
  AccountsTable = 'accounts-table',
  BillsList = 'bills-list',
  ContactsTable = 'contacts-table',
  ContactBalanceList = 'contact-balance-list',
  CurrencyDefaultPaymentMethods = 'currency-default-payment-methods',
  DuplicateBill = 'duplicate-bill',
  ExternalInvoicesList = 'external-invoices-list',
  InvoiceTemplates = 'invoice-templates',
  InvoicesList = 'invoices-list',
  MissingInformations = 'missing-informations',
  MissingReceipts = 'missing-receipts',
  OrganizationOwners = 'oraganization-owners',
  PaymentMethods = 'payment-methods',
  QuotesTable = 'quotes-table',
  RecurringInvoicesList = 'recurring-invoices-list',
  SalesTax = 'sales-tax',
  TransactionsList = 'transactions-list',
  UmbrellaOrganizationsList = 'umbrella-organizations-list',
  VoucherList = 'voucher-list',
}
