import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

export const organizationSubscriptionSwitchPlanTKeys: Record<SubscriptionPlan, string> = {
  [SubscriptionPlan.APIPartner]: 'organization_subscription.apipartner',
  [SubscriptionPlan.Archive]: 'organization_subscription.archive',
  [SubscriptionPlan.Basic]: 'organization_subscription.basic',
  [SubscriptionPlan.BasicPaid]: 'organization_subscription.basic_paid',
  [SubscriptionPlan.Complete]: 'organization_subscription.complete',
  [SubscriptionPlan.Employee]: 'organization_subscription.employee',
  [SubscriptionPlan.Free]: 'organization_subscription.free',
  [SubscriptionPlan.FriendsofBilly]: 'organization_subscription.friendofbilly',
  [SubscriptionPlan.InternalLicense]: 'organization_subscription.internallicense',
  [SubscriptionPlan.NonProfit]: 'organization_subscription.nonprofit',
  [SubscriptionPlan.Plus]: 'organization_subscription.plus',
  [SubscriptionPlan.PlusTrial]: 'organization_subscription.plus',
  [SubscriptionPlan.Premium]: 'organization_subscription.premium',
  [SubscriptionPlan.PremiumTrial]: 'organization_subscription.premium',
  [SubscriptionPlan.Pro]: 'organization_subscription.pro',
  [SubscriptionPlan.SpecialFree]: 'organization_subscription.specialfree',
  [SubscriptionPlan.AgerasFree]: 'organization_subscription.ageras_free',
  [SubscriptionPlan.AgerasBasic]: 'organization_subscription.ageras_basic',
  [SubscriptionPlan.AgerasStart]: 'organization_subscription.ageras_start',
  [SubscriptionPlan.AgerasPlus]: 'organization_subscription.ageras_plus',
  [SubscriptionPlan.AgerasComplete]: 'organization_subscription.ageras_complete',
}
