import styled from '@emotion/styled'

export const TabItemsList = styled.ul`
  display: flex;
`

export const TabItem = styled.li`
  & + & {
    margin-left: 47px;
  }
`
