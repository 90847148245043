import { Metable } from '../../../types/metable'
import { getRequest } from '../../../utils'
import { SalesTaxRuleset } from './types/salesTaxRuleset'

interface SalesTaxRulesetsResponseData extends Metable {
  salesTaxRulesets: SalesTaxRuleset[]
}

export const fetchSalesTaxRulesets = (organizationId: string): Promise<SalesTaxRulesetsResponseData> =>
  getRequest(`/v2/salesTaxRulesets?organizationId=${organizationId}`)
