import { stringify } from 'qs'
import { call, put, takeLatest } from 'redux-saga/effects'

import { getRequest } from '../../../utils'
import {
  umbrellaOrganizationsReceived,
  UmbrellaOrganizationsRequested,
  userOrganizationsReceived,
} from './action-creators'
import { UMBRELLA_ORGANIZATIONS_REQUESTED, USER_ORGANIZATIONS_REQUESTED } from './actions'

function* fetchUserOrganizations() {
  const res: { data: any } = yield call(getRequest, '/user/organizations')
  yield put(userOrganizationsReceived(res.data))
}

function* fetchUmbrellaOrganizations(action: UmbrellaOrganizationsRequested) {
  const {
    payload: { umbrellaId, filters },
  } = action
  const encodedFilters = filters ? stringify(filters) : ''

  const res: { data: any } = yield call(getRequest, `/umbrellas/${umbrellaId}/organizations?${encodedFilters}`)
  yield put(umbrellaOrganizationsReceived(res.data))
}

export default function* () {
  yield takeLatest(USER_ORGANIZATIONS_REQUESTED, fetchUserOrganizations)
  yield takeLatest(UMBRELLA_ORGANIZATIONS_REQUESTED, fetchUmbrellaOrganizations)
}
