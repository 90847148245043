import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const MatchIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${Spacing.S};
`

interface ReconciliationSuggestionContentProps {
  linePosition: 'left' | 'right'
}

export const ReconciliationSuggestionContent = styled.div<ReconciliationSuggestionContentProps>`
  display: grid;
  grid-template-columns: ${({ linePosition }) => (linePosition === 'left' ? 'auto min-content' : 'min-content auto')};
  grid-gap: ${Spacing.S};
  align-items: center;
`
