import { TFunction } from 'i18next'

import { QuoteState } from '../../../enums/quoteState'
import { quotesStateToTitleKey } from '../constants/quotesStateToTitleKey'

export const getQuotesTitle = (status = QuoteState.Open, amount: number, t: TFunction): string => {
  const tKey = quotesStateToTitleKey[status]

  return t(tKey, { count: amount })
}
