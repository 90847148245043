import { Button, ButtonsGroup } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useContactsEmberConnection } from '../../../../context/contactsEmberConnectionContext'
import { ContactsActions } from '../ContactsActions'

export const ContactsTableSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { createNewContact } = useContactsEmberConnection()

  const handleCreateContactClick = useCallback(() => createNewContact(), [createNewContact])

  return (
    <ButtonsGroup>
      <Button icon="plusCircle" onClick={handleCreateContactClick}>
        {t('contacts.header.create_contact')}
      </Button>
      <ContactsActions />
    </ButtonsGroup>
  )
}
