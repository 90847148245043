import { DateFormatter } from '@components'
import { amountToDisplayValue, PaymentPane, PaymentPaneProps, Text } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { BillType } from '../../../../enums/billType'
import { BillBalanceModifier } from '../../../../types/BillBalanceModifier'
import { billBalanceModifierReferenceToIconName } from '../../constants/billBalanceModifierReferenceToIconName'
import { billBalanceModifierReferenceToTKey } from '../../constants/billBalanceModifierReferenceToTKey'
import { creditNoteBalanceModifierReferenceToTKey } from '../../constants/creditNoteBalanceModifierReferenceToKey'
import { getBalanceModifierReference } from '../../utils/getBalanceModifierReference'
import { getBillPaymentPaneItemRoute } from '../../utils/getBillPaymentPaneItemRoute'

type BillPaymentPaneItemProps = BillBalanceModifier &
  Pick<PaymentPaneProps, 'withHoverEffect'> & {
    billType: BillType | null
  }

export const BillPaymentPaneItem = ({
  amount,
  entryDate,
  modifierReference,
  withHoverEffect,
  billType,
}: BillPaymentPaneItemProps): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const history = useHistory()
  const location = useLocation()

  const modifierData = useMemo(() => getBalanceModifierReference(modifierReference), [modifierReference])

  const { modifierType, modifierId } = modifierData || {}

  const contentText = useMemo(() => {
    if (modifierType) {
      if (billType === BillType.CreditNote) {
        return t(creditNoteBalanceModifierReferenceToTKey[modifierType])
      }
      return t(billBalanceModifierReferenceToTKey[modifierType])
    }
  }, [billType, modifierType, t])

  const icon = useMemo(() => {
    if (modifierType) {
      return billBalanceModifierReferenceToIconName[modifierType]
    }
  }, [modifierType])

  const handleClick = useCallback(() => {
    if (modifierType && modifierId) {
      const route = getBillPaymentPaneItemRoute(modifierType, modifierId)

      if (Object.values(EmberRoute).includes(route as any)) {
        navigate(route as EmberRoute, modifierId)
      } else {
        history.push(`${route}${location.search}`)
      }
    }
  }, [modifierType, modifierId, navigate, history, location.search])

  return (
    <PaymentPane.Item
      amount={amount !== null && <Text variant="normal">{amountToDisplayValue(amount)}</Text>}
      content={
        <Text variant="small" colorVariant="secondary">
          {contentText}
        </Text>
      }
      extra={
        <Text colorVariant="secondary" variant="small">
          {entryDate && <DateFormatter value={entryDate} />}
        </Text>
      }
      icon={icon}
      onClick={handleClick}
      prefix={
        <Text colorVariant="success" variant="micro">
          {t('bills.view.payment_pane.item.prefix')}
        </Text>
      }
      withHoverEffect={withHoverEffect}
    />
  )
}
