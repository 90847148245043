import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { ReactElement, useCallback } from 'react'

import { InvoiceDateModal } from '@views/invoices/elements/InvoiceDateModal/InvoiceDateModal'

import { EmberModal } from '../../contexts/emberCloseModalContext'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'invoice-date-modal-module'

class InvoiceDateModalWebComponent extends DOMModel {
  @byAttrVal entryDate: string
  @registerEvent('closeEmber') closeEmber: Function
  @registerEvent('approveInvoice') approveInvoice: Function | undefined
}

interface InvoiceDateModalProps {
  entryDate: string
  onCloseEmber: EmberModal
  onApproveInvoice: Function
}

function InvoiceDateModalModule({ entryDate, onCloseEmber, onApproveInvoice }: InvoiceDateModalProps): ReactElement {
  const closeModal = useCallback(() => {
    onCloseEmber({ detail: null })
  }, [onCloseEmber])

  const approveInvoice = useCallback(
    (shouldApproveWithTodaysDate: boolean, shouldNeverShowAgain: boolean) => {
      onApproveInvoice({ detail: { shouldApproveWithTodaysDate, shouldNeverShowAgain } })
    },
    [onApproveInvoice],
  )

  return (
    <ThemeProvider>
      <ModalContextProvider>
        <InvoiceDateModal entryDate={entryDate} onModalClose={closeModal} onApproveInvoice={approveInvoice} />
      </ModalContextProvider>
    </ThemeProvider>
  )
}

const InvoiceDateModalCustomElement = createCustomElement(
  InvoiceDateModalModule,
  InvoiceDateModalWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, InvoiceDateModalCustomElement)

export default InvoiceDateModalCustomElement
