import { NavItem, NavListItemWithBlockDescription, Select, SelectProps } from '@design-system'

import omit from 'lodash/omit'
import React, { forwardRef, ReactElement, Ref, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchCurrencies } from './query-api'
import { Currency } from './types/currency'
import { CurrencyValue } from './types/currencyValue'
import { getItems } from './utils/getItems'

interface CurrencySelectProps
  extends Omit<SelectProps<CurrencyValue>, 'items' | 'getDisplayValue' | 'dropdownItemRender' | 'onSelect'> {
  onSelect?: (id?: string, value?: Currency) => void
}

export const CurrencySelect = forwardRef(
  ({ onSelect, ...selectProps }: CurrencySelectProps, ref: Ref<HTMLDivElement | null>): ReactElement => {
    const { data } = useQuery([QueryKeys.Currencies], fetchCurrencies)

    const items = useMemo(() => (data?.currencies ? getItems(data?.currencies) : []), [data?.currencies])

    const getDisplayValue = useCallback((item?: NavItem<CurrencyValue>) => {
      if (item) {
        return item.value.id
      }

      return ''
    }, [])

    const handleSelect = useCallback(
      (id?: string, value?: CurrencyValue) => {
        const valueFiltered = omit(value, 'displayData')
        onSelect?.(id, valueFiltered)
      },
      [onSelect],
    )

    return (
      <Select
        {...selectProps}
        dropdownItemRender={(props) => <NavListItemWithBlockDescription {...props} />}
        getDisplayValue={getDisplayValue}
        items={items}
        onSelect={handleSelect}
        ref={ref}
      />
    )
  },
)
