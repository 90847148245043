import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { getShadow } from '../../../../utils/getShadow'
import { zIndex } from '../../../../utils/zIndex'

interface FullScreenWrapperProps {
  visible?: boolean
}

export const FullScreenWrapper = styled.div<FullScreenWrapperProps>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.Modal};
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  transition: opacity 0.3s ease-out;

  ${() => ModalWrapper} {
    transform: ${({ visible }) => (visible ? 'translateY(0)' : 'translateY(30px)')};
  }
`

export const ModalWrapper = styled.div`
  padding: 10px;
  width: 75vw;
  height: 75vh;
  margin: auto;
  border-radius: 8px;
  position: relative;
  background-color: ${Color.White};
  transition: transform 0.3s ease-out;
  ${getShadow('elevatedLight')}
`

export const CloseWrapper = styled.div`
  position: absolute;
  top: -40px;
  right: 0;
  cursor: pointer;
`
