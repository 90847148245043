const customScrollbar: object = {
  '::-webkit-scrollbar': {
    width: '6px',
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '3px',
  },
}

const customScrollbarGray = { ...customScrollbar }

export default {
  customScrollbar,
  customScrollbarGray,
}
