import qs from 'qs'

import { Metable } from '../../../../types/metable'
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../../utils'
import { AccessToken } from './types/accessToken'
import { Actor } from './types/actor'

export interface FetchActorsOptions {
  offset?: number
  organizationId: string
  pageSize?: number
}

interface FetchActorsResponseData extends Metable {
  oAuthActors: Actor[]
}

export const fetchActors = ({
  offset,
  organizationId,
  pageSize,
}: FetchActorsOptions): Promise<FetchActorsResponseData> => {
  const queryParams = qs.stringify({
    organizationId,
    ...(offset ? { offset } : {}),
    ...(pageSize ? { pageSize } : {}),
  })

  return getRequest(`/v2/oAuthActors?${queryParams}`)
}

interface FetchAccessTokensResponseData extends Metable {
  oAuthAccessTokens: AccessToken[]
}

export interface FetchAccessTokensOptions {
  actorId: string
  offset?: number
  pageSize?: number
}

export const fetchAccessTokens = ({
  actorId,
  offset,
  pageSize,
}: FetchAccessTokensOptions): Promise<FetchAccessTokensResponseData> => {
  const queryParams = qs.stringify({
    actorId,
    ...(offset ? { offset } : {}),
    ...(pageSize ? { pageSize } : {}),
  })

  return getRequest(`/v2/oAuthAccessTokens?${queryParams}`)
}

export interface CreateActorResponseData extends Metable {
  oAuthAccessTokens: AccessToken[]
  oAuthActors: Actor[]
}

export const createActor = (actor: Partial<Actor>): Promise<CreateActorResponseData> =>
  postRequest('/v2/oAuthActors', { oAuthActor: actor })

export interface UpdateActorOptions {
  payload: Partial<Actor>
  actorId: string
}

export interface UpdateActorResponseData extends Metable {
  oAuthActors: Actor[]
}

export const updateActor = ({ payload, actorId }: UpdateActorOptions): Promise<UpdateActorResponseData> =>
  putRequest(`/v2/oAuthActors/${actorId}`, { oAuthActor: payload })

export const deleteActor = (actorId: string): Promise<void> => deleteRequest(`/v2/oAuthActors/${actorId}`)
