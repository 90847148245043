import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { DefaultValue } from '../../../../enums/defaultValue'
import { FlexProps } from '../../../Flex'
import { FitType } from '../../types'

interface SectionWrapperProps extends FlexProps {
  fitToHeight?: boolean
  bordered?: boolean
}

export const SectionWrapper = styled.div<SectionWrapperProps>`
  display: flex;
  height: 100%;

  ${({ bordered }) => {
    if (bordered) {
      return css`
        border: 1px solid ${Color.Gray50};
        border-radius: ${DefaultValue.BorderRadius};
      `
    }
  }}

  position: relative;
  overflow: hidden;
`

interface StickyWrapperProps {
  fitToHeight?: boolean
  clickable?: boolean
  fitType?: FitType
}

export const StickyWrapper = styled.div<StickyWrapperProps>`
  display: flex;
  width: 100%;
  overflow-x: hidden;
  overflow-y: ${({ fitToHeight, fitType }) => (fitToHeight || fitType === 'cover' ? 'hidden' : 'auto')};
  ${({ clickable }) => clickable && 'cursor: pointer'};
  ${({ fitToHeight }) => fitToHeight && 'justify-content: center'};
`
