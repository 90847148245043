import { ProcessingOverlay, SettingsSection } from '@components'
import { Button, Checkbox, Color, FormItemsGroup, Input } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useUserProfileForm } from '../../../../hooks/useUserProfileForm'
import * as Styled from './styles'

export const ProfileInfoForm = () => {
  const { t } = useTranslation()
  const { isLoading: isLoadingOrganization } = useUserOrganization()
  const {
    Form,
    FormItem,
    isLoading: isLoadingForm,
    isEmailChangeAllowed,
    isSubmitting: isProcessing,
  } = useUserProfileForm()

  const isLoading = isLoadingForm || isLoadingOrganization

  return (
    <SettingsSection>
      <Form>
        <SettingsSection.RowsContainer>
          <SettingsSection.Content title={t('settings.user.user_settings')}>
            <Styled.FormFieldsWrapper>
              {(isLoading || isProcessing) && <ProcessingOverlay color={Color.White} />}
              <FormItemsGroup>
                <FormItem
                  label={t('settings.user.full_name')}
                  name="name"
                  render={({ field }) => <Input {...field} />}
                />
                <FormItem
                  label={t('settings.user.email_address')}
                  name="email"
                  render={({ field }) => <Input {...field} disabled={!isEmailChangeAllowed} />}
                />
                <FormItem
                  label={t('settings.user.phone_number')}
                  name="phone"
                  render={({ field }) => <Input {...field} />}
                />
              </FormItemsGroup>
            </Styled.FormFieldsWrapper>
          </SettingsSection.Content>
          <SettingsSection.Content
            title={t('settings.user.for_accountants')}
            subtitle={t('settings.user.for_accountants.subtitle')}
          >
            <Styled.FormFieldsWrapper>
              {(isLoading || isProcessing) && <ProcessingOverlay color={Color.White} />}
              <FormItem
                name="canCreateDaybooks"
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    checked={value}
                    children={t('settings.user.is_support_multiple_daybooks')}
                    onChange={onChange}
                  />
                )}
              />
            </Styled.FormFieldsWrapper>
          </SettingsSection.Content>
        </SettingsSection.RowsContainer>
        <SettingsSection.Footer>
          <Button loading={isLoading || isProcessing} size="l" type="submit">
            {t('settings.user.profile.save_changes')}
          </Button>
        </SettingsSection.Footer>
      </Form>
    </SettingsSection>
  )
}
