import { Checkbox, Fieldset } from '@components-deprecated'

import { css } from '@emotion/core'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { SpecificState } from '../../../types/reduxSaga-deprecated'
import { filtersChanged } from '../action-creators'
import { BankLineGroupFilters } from '../types'

export const BankLineGroupFiltersPanel = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const bankLineGroupFilters = useSelector((state: SpecificState) => {
    return state.bankReconciliation.filters.bankLineGroupFilters
  })

  const { showReconciled, showMatched, showManual } = bankLineGroupFilters

  const onChangeFilters = useCallback(
    (filters: Partial<BankLineGroupFilters>) => {
      dispatch(filtersChanged({ bankLineGroupFilters: { ...bankLineGroupFilters, ...filters } }))
    },
    [dispatch, bankLineGroupFilters],
  )

  return (
    <Fieldset
      css={css`
        align-items: flex-start;
        background-color: white;
        border-radius: 3px;
        white-space: nowrap;
        margin-bottom: 0;
        margin-left: 12px;
        margin-top: 10px;
        label {
          margin-bottom: 0;
          color: #364a59;
        }
      `}
    >
      <Checkbox
        checked={showReconciled}
        onChange={() => onChangeFilters({ showReconciled: !showReconciled })}
        label={t('bankreconciliation.banklinegroups.reconciled')}
      />
      <Checkbox
        checked={showMatched}
        onChange={() => onChangeFilters({ showMatched: !showMatched })}
        label={t('bankreconciliation.banklinegroups.matched')}
      />
      <Checkbox
        checked={showManual}
        onChange={() => onChangeFilters({ showManual: !showManual })}
        label={t('bankreconciliation.banklinegroups.manual')}
      />
    </Fieldset>
  )
}
