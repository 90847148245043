import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const IntegrationsListSection = styled.section`
  margin: ${Spacing.L} 0 ${Spacing.XXL};
`

export const IntegrationsListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
