import { Spacing } from '@design-system'

import { css } from '@emotion/core'
import cc from 'classcat'
import React, { useState } from 'react'
import { Box, BoxProps, Flex } from 'rebass'

import { reactClass } from '../../utils'
import { ActionGroup, ActionGroupItem } from '../ActionGroup'
import { TabContentProps } from './TabContent'

export type TabsProps = BoxProps & {
  onSelectAction?: (value: string) => void
}

export const Tabs = ({ children, className, onSelectAction }: TabsProps) => {
  let pick = ''

  React.Children.forEach(children, (child: any) => {
    const { selected, fixed, id, text } = child.props
    if (!fixed && !pick) {
      pick = id || text
    }
    if (!fixed && selected) {
      pick = id || text
    }
  })

  const [selected, setSelected] = useState<string>(pick)

  const handleSelectAction = (value: string) => {
    setSelected(value)
    if (onSelectAction) {
      onSelectAction(value)
    }
  }

  return (
    <Box className={cc([className, reactClass('tabs')])} variant="tabs">
      <ActionGroup onSelectAction={handleSelectAction}>
        {React.Children.map(
          children as React.ReactElement<TabContentProps>[],
          (child: React.ReactElement<TabContentProps>): JSX.Element | null =>
            React.isValidElement(child) && !child.props.fixed ? (
              <ActionGroupItem
                value={child.props.id || (child.props.text as string)}
                selected={child.props.id === selected || child.props.text === selected}
              >
                {child.props.text}
              </ActionGroupItem>
            ) : null,
        )}
      </ActionGroup>
      <Flex
        variant="tabs.content"
        css={css`
          width: 100%;
          margin-top: ${Spacing.XL};
          justify-content: space-between;
        `}
      >
        {React.Children.map(
          children as React.ReactElement<TabContentProps>[],
          (child: React.ReactElement<TabContentProps>): JSX.Element | null =>
            React.isValidElement(child) &&
            (child.props.fixed || child.props.id === selected || child.props.text === selected)
              ? React.cloneElement(child, {
                  className: 'tabs-content',
                  css: css`
                    flex: 1;
                  `,
                  selected: child.props.id === selected,
                })
              : null,
        )}
      </Flex>
    </Box>
  )
}
