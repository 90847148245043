import { Select, SelectProps } from '@design-system'

import React, { forwardRef, ReactElement, Ref, useCallback, useMemo } from 'react'

import { usePaymentMethods } from '../../../hooks/usePaymentMethods'
import { PaymentMethod } from '../../../query-api/payment-methods-query'
import { getItems } from './utils/getItems'

export interface PaymentMethodSelectProps
  extends Omit<SelectProps<PaymentMethod>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: PaymentMethod) => void
}

export const PaymentMethodSelect = forwardRef(
  ({ onSelect, ...selectProps }: PaymentMethodSelectProps, forwardedRef: Ref<HTMLDivElement | null>): ReactElement => {
    const { paymentMethods, isLoading } = usePaymentMethods()

    const items = useMemo(() => (paymentMethods?.data ? getItems(paymentMethods?.data) : []), [paymentMethods?.data])

    const handleSelect = useCallback(
      (id?: string, value?: PaymentMethod) => {
        onSelect?.(id, value)
      },
      [onSelect],
    )

    return (
      <Select {...selectProps} dropdownFetching={isLoading} items={items} onSelect={handleSelect} ref={forwardedRef} />
    )
  },
)
