import { DraftBlockType } from 'draft-js'

import { RichTextStyle } from '../types/richTextStyle'

export interface BlockTypeConfig {
  name: RichTextStyle
  label: string
  type: DraftBlockType
}

export const defaultHeaderBlocks: BlockTypeConfig[] = [
  { name: 'h1', label: 'H1', type: 'header-one' },
  { name: 'h2', label: 'H2', type: 'header-two' },
  { name: 'h3', label: 'H3', type: 'header-three' },
]

export const defaultListBlocks: BlockTypeConfig[] = [
  { name: 'ul', label: 'UL', type: 'unordered-list-item' },
  { name: 'ol', label: 'OL', type: 'ordered-list-item' },
]
