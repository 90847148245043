import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useLocalStorage } from 'react-use'

import { LocalStorageKeys } from '../../../enums/localStorageKeys'

interface ContextState {
  selectedBankAccountId: string | undefined
  setSelectedBankAccountId: (accountId: string) => void
}

const BankWidgetContext = createContext<ContextState | undefined>(undefined)

interface BankWidgetContextProviderProps {
  organizationId: string
  children?: ReactNode
}

export const BankWidgetContextProvider = ({
  children,
  organizationId,
}: BankWidgetContextProviderProps): ReactElement => {
  const localStorageKey = `${LocalStorageKeys.BankIntegrationSelection}-${organizationId}`

  const [selectedBankAccountId, setSelectedBankAccountId] = useLocalStorage(localStorageKey, '')

  return (
    <BankWidgetContext.Provider value={{ selectedBankAccountId, setSelectedBankAccountId }}>
      {children}
    </BankWidgetContext.Provider>
  )
}

export const useBankWidget = () => {
  const context = useContext(BankWidgetContext)

  if (!context) {
    throw new Error('BankWidgetContextProvider is missing in the module!')
  }

  return context
}
