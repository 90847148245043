export enum UmbrellaRoute {
  Inbox = '/inbox',
  Invoices = '/invoices',
  Organizations = '/customers',
  Profile = '/profile',
  Roles = '/roles',
  Settings = '/settings/user',
  SettingsProfile = '/settings/user/profile',
  SettingsNotifications = '/settings/user/notifications',
  SettingsPrivacy = '/settings/user/privacy',
  Subscriptions = '/subscriptions',
  Users = '/users',
  VoucherInbox = '/inbox/voucher-inbox',
}
