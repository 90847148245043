import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { CreateContactPersonPayload, postContactPerson, PostContactPersonResponse } from '../query-api'

export interface UseCreateContactPersonProps {
  onError?: (error?: APIError) => void
  onSuccess?: (response: PostContactPersonResponse) => void
}

export interface CreateContactPersonProps {
  payload: CreateContactPersonPayload
}

export const useCreateContactPerson = (props?: UseCreateContactPersonProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: createContactPerson, isLoading } = useMutation(
    ({ payload }: CreateContactPersonProps) => postContactPerson(payload),
    {
      onError,
      onSuccess,
    },
  )

  return { createContactPerson, isLoading }
}
