import { ReactElement } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { withRouteLinkClickedListener } from '../../hocs/withRouteLinkClickedListener'
import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { InvoicesRoute } from './enums/invoicesRoute'
import { InvoicesList } from './routes/InvoicesList'
import { defaultQuery } from './routes/InvoicesList/hooks/useInvoicesListFilters'
import { InvoicesListEmpty } from './routes/InvoicesListEmpty'

export const Invoices = withRouteLinkClickedListener((): ReactElement => {
  const emberQuery = useGetQueryFromEmberRoute({
    queryParamsValidKeys: Object.keys(defaultQuery),
  })

  return (
    <Switch>
      <Route exact path={InvoicesRoute.List}>
        {typeof emberQuery?.initialRoute === 'string' ? <Redirect to={emberQuery.initialRoute} /> : <InvoicesList />}
      </Route>
      <Route component={InvoicesListEmpty} exact path={InvoicesRoute.ListEmpty} />
    </Switch>
  )
})
