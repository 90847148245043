import { TableHoverActions, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateOrEditRulesetModal } from '../CreateOrEditRulesetModal'
import { getEditModalId } from './utils/getEditModalId'

interface RulesetsTableHoverActionsProps {
  onEdit: (id: string) => void
  rulesetId: string
  readonly: boolean
}
export const RulesetsTableHoverActions = ({ rulesetId, readonly }: RulesetsTableHoverActionsProps): ReactElement => {
  const modalId = getEditModalId(rulesetId)
  const { open: openEditModal } = useModal(modalId)
  const { t } = useTranslation()

  const handleViewOrEditClick = useCallback(() => {
    openEditModal()
  }, [openEditModal])

  return (
    <>
      <TableHoverActions>
        {readonly ? (
          <TableHoverActions.Item icon="eye" label={t('view')} onClick={handleViewOrEditClick} />
        ) : (
          <TableHoverActions.Item icon="paperWithPencil" label={t('edit')} onClick={handleViewOrEditClick} />
        )}
      </TableHoverActions>
      <CreateOrEditRulesetModal rulesetId={rulesetId} id={modalId} disabled={readonly} />
    </>
  )
}
