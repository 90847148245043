import { Color } from '@design-system'

import { PurchaseCardHighlightVariant } from '../types'

interface PurchaseCardHighlightStyles {
  bgColor: Color
  textColor: Color
}

export const variantStyles: Record<PurchaseCardHighlightVariant, PurchaseCardHighlightStyles> = {
  danger: {
    bgColor: Color.Red10,
    textColor: Color.Red120,
  },
  success: {
    bgColor: Color.Green,
    textColor: Color.White,
  },
}
