import reduceReducers from 'reduce-reducers'
import { Reducer } from 'redux'
import { all, fork } from 'redux-saga/effects'

import { Module } from '../../types/reduxSaga-deprecated'
import { mapModules } from '../../utils/modules'
import { mapValues } from '../../utils/object'
import { getFeatures } from './settings/features'
import { AppState } from './types/appState'

const initialState: AppState = {
  account: null,
  accounts: null,
  accountResolved: false,
  cities: {},
  companies: {},
  contacts: null,
  countries: {},
  features: getFeatures(),
  isTrustedPartner: false,
  organization: null,
  organizationSettings: null,
  organizations: [],
  organizationUmbrellas: [],
  products: null,
  umbrellaOrganizations: [],
  userUmbrellas: [],
  umbrella: null,
  user: null,
  zipCodes: {},
}

export * from './accounts/elements'
export * from './accounts/selectors'
export * from './address/elements'
export * from './companies/elements'
export * from './contacts/elements'
export * from './countries/elements'
export * from './currencies/elements'
export * from './organizations/elements'
export * from './products/elements'
export * from './taxrates/elements'

const modules: Record<string, Module> = mapModules(require.context('./', true, /^\.\/[^/]+\/index.ts$/))

export const sagas = function* () {
  yield all(Object.values(mapValues(modules, 'sagas')).map(fork))
}

const reducers: Reducer<AppState>[] = Object.values(mapValues(modules, 'reducer'))
export const reducer = reduceReducers<AppState>(initialState, ...reducers)

export const actions: Record<string, string> = Object.values(modules).reduce(
  (acc: Record<string, string>, currentValue: Module) => ({
    ...acc,
    ...currentValue.actions,
  }),
  {},
)

export const actionCreators: Record<string, Function> = Object.values(modules).reduce(
  (acc: Record<string, Function>, currentValue: Module) => ({
    ...acc,
    ...currentValue.actionCreators,
  }),
  {},
)
