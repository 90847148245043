import { FontWeight } from '@design-system'

import colors from './colors'
import fonts from './text'

export const buttonSizes = {
  fontSizeSmall: fonts.small2.fontSize,
  fontSizeLarge: fonts.body2.fontSize,
  lineHeightSmall: 26,
  lineHeightLarge: 36,
  paddingSmall: '0 20px',
  paddingLarge: '0 25px',
}

const defaultStyles = {
  borderRadius: 2,
  borderWidth: 1,
  minWidth: 80,
  position: 'relative',
  fontWeight: FontWeight.Medium,
  boxShadow: `0 1px 3px 0 ${colors.shade25}`,
  ul: {
    background: colors.buttons.primary.text,
    boxShadow: `0px 1px 2px ${colors.shade20}`,
    borderRadius: 3,
    top: 'calc(100% + 2px)',
    right: '-2px',
  },
  li: {
    padding: '7px 10px',
    color: colors.buttons.default.li.text,
    textAlign: 'left',
    textShadow: 'none',
    '&:hover': {
      background: colors.buttons.default.li.bgHover,
    },
  },
  i: {
    lineHeight: 0,
  },
  flexShrink: 0,
}

export default {
  primary: {
    ...defaultStyles,
    position: 'relative',
    background: colors.buttons.primary.bg,
    color: colors.buttons.primary.text,
    '&:hover': {
      background: colors.buttons.primary.bgHover,
    },
    '&:active:not(:disabled)': {
      background: `${colors.buttons.primary.bg} !important`,
    },
    '&:focus': {
      background: colors.buttons.primary.bgHover,
    },
    '&:disabled, &:disabled:hover': {
      background: colors.buttons.primary.bg,
      color: colors.buttons.primary.textDisabled,
      boxShadow: 'none',
    },
    '&.splitButtonCaret': {
      background: colors.buttons.primary.bgCaret,
      padding: 0,
    },
    '&.splitButtonCaret:hover': {
      background: colors.buttons.primary.bgCaretHover,
    },
    '&.splitButtonCaret:active:not(:disabled)': {
      background: colors.buttons.primary.bgCaret,
    },
    '&.splitButtonCaret:focus,': {
      background: colors.buttons.primary.bgCaretHover,
    },
  },
  normal: {
    ...defaultStyles,
    position: 'relative',
    background: colors.buttons.normal.bg,
    color: colors.buttons.normal.text,
    '&:hover': {
      background: colors.buttons.normal.bgHover,
    },
    '&:active:not(:disabled)': {
      background: `${colors.buttons.normal.bg} !important`,
    },
    '&:focus': {
      background: colors.buttons.normal.bg,
    },
    '&:disabled, &:disabled:hover': {
      background: colors.buttons.normal.bgDisabled,
      color: colors.buttons.normal.textDisabled,
      textShadow: 'none',
    },
    '&.splitButtonCaret': {
      background: colors.buttons.normal.bgCaret,
      padding: 0,
    },
    '&.splitButtonCaret:hover': {
      background: colors.buttons.normal.bgCaretHover,
    },
    '&.splitButtonCaret:active:not(:disabled)': {
      background: colors.buttons.normal.bgCaret,
    },
    '&.splitButtonCaret:focus': {
      background: colors.buttons.normal.bgCaretHover,
    },
  },
  info: {
    ...defaultStyles,
    position: 'relative',
    background: colors.buttons.info.bg,
    color: colors.buttons.info.text,
    '&:hover': {
      background: colors.buttons.info.bgHover,
    },
    '&:active:not(:disabled)': {
      background: `${colors.buttons.info.bg} !important`,
    },
    '&:focus': {
      background: colors.buttons.info.bg,
    },
    '&:disabled, &:disabled:hover': {
      background: colors.buttons.info.bgDisabled,
      color: colors.buttons.info.textDisabled,
      textShadow: 'none',
    },
    '&.splitButtonCaret': {
      background: colors.buttons.info.bgCaret,
      padding: 0,
    },
    '&.splitButtonCaret:hover': {
      background: colors.buttons.info.bgCaretHover,
    },
    '&.splitButtonCaret:active:not(:disabled)': {
      background: colors.buttons.info.bgCaret,
    },
    '&.splitButtonCaret:focus': {
      background: colors.buttons.info.bgCaretHover,
    },
  },
  warning: {
    ...defaultStyles,
    position: 'relative',
    background: colors.buttons.warning.bg,
    color: colors.buttons.warning.text,
    '&:hover': {
      background: colors.buttons.warning.bgHover,
    },
    '&:active:not(:disabled)': {
      background: `${colors.buttons.warning.bg} !important`,
    },
    '&:focus': {
      background: colors.buttons.warning.bg,
    },
    '&:disabled, &:disabled:hover': {
      background: colors.buttons.warning.bgDisabled,
      color: colors.buttons.warning.textDisabled,
      textShadow: 'none',
    },
    '&.splitButtonCaret': {
      background: colors.buttons.warning.bgCaret,
      padding: 0,
    },
    '&.splitButtonCaret:hover': {
      background: colors.buttons.warning.bgCaretHover,
    },
    '&.splitButtonCaret:active:not(:disabled)': {
      background: colors.buttons.warning.bgCaret,
    },
    '&.splitButtonCaret:focus': {
      background: colors.buttons.warning.bgCaretHover,
    },
  },
  outline: {
    ...buttonSizes,
    ...defaultStyles,
    position: 'relative',
    background: colors.buttons.outline.bg,
    color: colors.buttons.outline.text,
    border: `1px solid ${colors.buttons.outline.border}`,
    '&:hover, &:hover': {
      color: colors.buttons.outline.textHover,
      borderColor: colors.buttons.outline.borderHover,
    },
    '&:disabled, &:disabled:hover': {
      color: colors.buttons.outline.textHover,
      borderColor: colors.buttons.outline.borderDisabled,
      boxShadow: 'none',
    },
  },
  outlinePrimary: {
    borderRadius: 3,
    borderWidth: 1,
    fontSize: 14,
    fontSizeLarge: 17,
    minWidth: 80,
    padding: '0 20px',
    background: colors.buttons.outline.bg,
    color: colors.primary,
    boxShadow: `0 1px 3px 0 ${colors.shade25}`,
    border: `1px solid ${colors.primary}`,
  },
  link: {
    padding: 0,
    background: 'none',
    color: colors.link,
    borderRadius: 0,
    boxShadow: 'none',
    textShadow: 'none',
  },
  formAction: {
    height: 15,
    borderRadius: 15,
    borderWidth: 1,
    fontSize: 10,
    fontSizeLarge: 10,
    fontWeight: 500,
    heightLarge: 15,
    lineHeight: '14px',
    padding: '0 6px',
    background: colors.white,
    color: colors.primary,
    border: `1px solid ${colors.primary}`,
  },
}
