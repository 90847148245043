import React, {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { KeyboardKey } from '../../../enums/keyboardKey'
import { useTheme } from '../../hooks/useTheme'
import { InputPropsRaw } from '../Input'
import { ErrorIcon } from '../Input/elements/ErrorIcon'
import { SuccessIcon } from '../Input/elements/SuccessIcon'
import { getTextColor } from '../Input/utils/getTextColor'
import * as Styled from './styles'

type TextareaType = TextareaHTMLAttributes<HTMLTextAreaElement>
type Value = TextareaType['value']
type InputPropsToOmit = 'prefix' | 'suffix' | 'type' | 'onPressEnter' | 'defaultValue' | 'truncate' | 'selectLook'

export interface TextareaProps extends Omit<TextareaType & InputPropsRaw, InputPropsToOmit> {
  defaultValue?: Value
  onPressEnter?: (event: KeyboardEvent<HTMLTextAreaElement>) => void
  resizable?: boolean
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      alignment = 'left',
      autoCompleted = false,
      autoFocus = false,
      bordered = true,
      colorVariant = 'primary',
      className,
      defaultValue,
      disabled = false,
      error = false,
      focused = false,
      hidden = false,
      id,
      onChange,
      onPressEnter,
      resizable = true,
      rows = 2,
      size = 'l',
      success = false,
      value = '',
      weight = 'regular',
      ...textareaRest
    },
    forwardedRef,
  ) => {
    const [currentValue, setCurrentValue] = useState<Value>(defaultValue ?? value)
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const theme = useTheme()
    const textColor = getTextColor(theme)[colorVariant]

    // Functions

    const handleValueChange = useCallback((newValue: Value) => {
      setCurrentValue(newValue)
    }, [])

    const handleTextareaChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (disabled) {
          return
        }

        handleValueChange(event?.currentTarget.value)
        onChange && onChange(event)
      },
      [handleValueChange, onChange, disabled],
    )

    const handleKeyPress = useCallback(
      (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === KeyboardKey.Enter) {
          onPressEnter && onPressEnter(event)
        }
      },
      [onPressEnter],
    )

    // Mutations

    useEffect(() => {
      if (autoFocus) {
        textareaRef.current?.focus()
      }
    }, [autoFocus])

    useEffect(() => {
      handleValueChange(value)
    }, [value, handleValueChange])

    // Hooks utils

    useImperativeHandle(forwardedRef, () => textareaRef?.current as HTMLTextAreaElement)

    return (
      <Styled.TextareaWrapper className={className}>
        <Styled.Textarea
          {...textareaRest}
          alignment={alignment}
          autoCompleted={autoCompleted}
          bordered={bordered}
          className={className}
          color={textColor}
          disabled={disabled}
          error={error}
          focused={focused}
          hidden={hidden}
          onChange={handleTextareaChange}
          onKeyPress={handleKeyPress}
          ref={textareaRef}
          resizable={resizable}
          rows={rows}
          selectLook={false}
          size={size}
          truncate={false}
          value={currentValue}
          weight={weight}
          withSuffix={error || success}
        />

        {(error || success) && (
          <Styled.SuffixIconWrapper inputSize={size}>
            {error && <ErrorIcon inputSize={size} disabled={disabled} />}
            {success && <SuccessIcon inputSize={size} disabled={disabled} />}
          </Styled.SuffixIconWrapper>
        )}
      </Styled.TextareaWrapper>
    )
  },
)
