import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '../../../Text'
import * as Styled from './styles'

interface NavNotFoundInfoProps {
  children?: ReactNode
}

export const NavNotFoundInfo = ({ children }: NavNotFoundInfoProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.NavNotFoundInfoWrapper>
      <Styled.NavDefaultInfo>
        <Text weight="medium" alignment="center">
          {children || t('ds.nav_list.not_found')}
        </Text>
      </Styled.NavDefaultInfo>
    </Styled.NavNotFoundInfoWrapper>
  )
}
