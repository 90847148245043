import { Flex, SkeletonBox, Space } from '@design-system'

import * as Styled from './styles'

export const BankLinesListItemSkeleton = () => (
  <Styled.BankLinesListItemSkeletonWrapper>
    <SkeletonBox width={16} height={16} />
    <Space size="m" horizontal />
    <Styled.BankLinesListItemSkeletonRow>
      <Flex justifyContent="space-between" alignItems="center">
        <SkeletonBox height={40} width={120} />
        <SkeletonBox height={16} width={100} />
      </Flex>
    </Styled.BankLinesListItemSkeletonRow>
  </Styled.BankLinesListItemSkeletonWrapper>
)
