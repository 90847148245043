import { InternalAccessor, Table, TableColumn, Text } from '@design-system'

import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrella } from '@modules-deprecated/app/umbrellas/umbrellaContext'

import { useUmbrellaMembers } from '../../../../../../hooks/useUmbrellaMembers'
import { UmbrellaMember, UmbrellaMemberRole } from '../../../../../../query-api/umbrella-members-query'
import { UmbrellaUsersTableStaticActions } from './elements/UmbrellaUsersTableStaticActions'

interface UmbrellaMemberRow extends UmbrellaMember {
  id: string
}

export const UmbrellaUsersTable = (): ReactElement => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()

  const { umbrellaMembers, isLoading } = useUmbrellaMembers(umbrella?.id)

  const umbrellaRowMembers: UmbrellaMemberRow[] = useMemo(
    () => umbrellaMembers.map((member) => ({ ...member, id: member.userId, [InternalAccessor.StaticActions]: true })),
    [umbrellaMembers],
  )

  const columns: TableColumn<UmbrellaMemberRow>[] = useMemo(
    () => [
      {
        Header: t('umbrella_users.table.column.name'),
        accessor: 'name',
      },
      {
        Header: t('umbrella_users.table.column.email'),
        accessor: 'email',
      },
      {
        Header: t('umbrella_users.table.column.roles'),
        accessor: 'roles',
        Cell: ({ data, row }) => {
          const { roles } = data[row.id] as UmbrellaMemberRow
          return roles.map((role: UmbrellaMemberRole) => <Text>{role.name}</Text>)
        },
      },
      {
        Header: '',
        accessor: InternalAccessor.StaticActions,
        Cell: ({ data, row }) => {
          const { id } = data[row.id] as UmbrellaMemberRow
          return umbrella ? <UmbrellaUsersTableStaticActions umbrellaId={umbrella?.id} umbrellaUserId={id} /> : <></>
        },
      },
    ],
    [t, umbrella],
  )

  return <Table columns={columns} data={umbrellaRowMembers} isLoading={isLoading} />
}
