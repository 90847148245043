import { Badge, IconButton, useOnFocusedSubmit } from '@design-system'

import React, { ReactElement, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { useOrganizationBrand } from '../../../../hooks/useOrganizationBrand'
import { getAppBuildTheme } from '../../../../utils/getAppBuildTheme'
import { Logo } from '../../../Logo'
import * as Styled from './styles'

interface MenuHeaderProps {
  onCloseClick?: () => void
}

export const MenuHeader = ({ onCloseClick }: MenuHeaderProps): ReactElement => {
  const logoWrapperRef = useRef<HTMLElement>(null)
  const { navigate } = useEmberRouter()
  const { umbrella } = useUmbrella()
  const { organization } = useUserOrganization()
  const { t } = useTranslation()
  const { isBrandAgeras } = useOrganizationBrand()
  const theme = getAppBuildTheme()

  const isOrganizationLocked = organization && organization?.isLocked
  const isAgeras = isBrandAgeras || theme === 'ageras'

  const handleLogoClick = useCallback(() => {
    if (umbrella) {
      navigate(EmberRoute.UmbrellaOrganizations, [umbrella.id])
    } else if (organization) {
      navigate(EmberRoute.Dashboard, [organization.url])
    } else {
      navigate(EmberRoute.Root)
    }
    // ts doesn't recognize "?."
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [umbrella, navigate, organization?.url])

  const handleCloseClick = useCallback(() => {
    onCloseClick?.()
  }, [onCloseClick])

  const handleItemKeyDown = useOnFocusedSubmit(logoWrapperRef.current, handleLogoClick)

  return (
    <Styled.MenuHeader>
      <Styled.LogoWrapper>
        <Logo
          ref={logoWrapperRef}
          onClick={isOrganizationLocked ? undefined : handleLogoClick}
          onKeyDown={handleItemKeyDown}
          tabIndex={0}
        />

        {isAgeras && (
          <Styled.BadgeWrapper>
            <Badge>{t('beta')?.toUpperCase()}</Badge>
          </Styled.BadgeWrapper>
        )}
      </Styled.LogoWrapper>

      <Styled.CloseButtonWrapper onClick={handleCloseClick}>
        <IconButton icon="xSign" />
      </Styled.CloseButtonWrapper>
    </Styled.MenuHeader>
  )
}
