import React from 'react'
import { Document, DocumentProps, Page, PageProps } from 'react-pdf'

interface PdfDocumentProps {
  documentProps: DocumentProps
  pageProps: PageProps
}

export const PdfDocument = ({ documentProps, pageProps }: PdfDocumentProps) => (
  <Document {...documentProps}>
    <Page key={pageProps.scale?.toString()} {...pageProps} />
  </Document>
)
