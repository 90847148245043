import { Button, Flex, Modal, ModalProps, Space, Text, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { getImageClassName } from '../../../../../../utils/getClassName'
import { debtCollectionNoteModalPoints } from './constants/debtCollectionNoteModalPoints'

type DebtCollectionNoteModalProps = ModalProps

export const DebtCollectionNoteModal = (modalProps: DebtCollectionNoteModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeDebtCollectionNoteModal } = useModal(modalProps.id)

  const handleOkButtonClick = useCallback(() => {
    closeDebtCollectionNoteModal()
  }, [closeDebtCollectionNoteModal])

  return (
    <Modal id={modalProps.id} size="s" closable>
      <Modal.Header title={t('integrations.card.debt_collection.modal_note.title')} />
      <Modal.Body>
        {debtCollectionNoteModalPoints.map((point) => (
          <>
            <Text weight="bold">{t(point.headingTKey)}</Text>
            <Text>{t(point.paragraphTKey)}</Text>
            {point?.image && (
              <>
                <Space size="m" />
                <Flex justifyContent="center">
                  <img
                    src={point.image.src}
                    width={point.image.width}
                    alt={t(point.headingTKey)}
                    className={getImageClassName()}
                  />
                </Flex>
              </>
            )}
            <Space size="xl" />
          </>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOkButtonClick}>{t('integrations.card.debt_collection.modal_note.ok_button')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
