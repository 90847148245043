import { Color, DefaultValue, Spacing, useTheme } from '@design-system'

import { css, SerializedStyles } from '@emotion/core'
import cc from 'classcat'
import React, { ReactElement } from 'react'
import { Box, BoxProps } from 'rebass'

import { reactClass } from '../../../utils'

export type ContentInnerProps = BoxProps

export const ContentInner = ({ children, ...rest }: ContentInnerProps): ReactElement => (
  <Box
    className={reactClass('inner-content')}
    css={css`
      margin: 0 auto;
      border-radius: ${DefaultValue.BorderRadius};
      padding: ${Spacing.XL};
      width: 100%;
      height: 100%;
      max-width: ${1200 + 30 * 2}px;
      min-width: ${970 + 30 * 2}px;
      background-color: ${Color.White};
    `}
    {...rest}
  >
    {children}
  </Box>
)

export type ContentProps = BoxProps & {
  bg?: 'default' | 'inverse' | 'soft' | 'primary' | 'secondary' | 'primaryLight' | 'primaryBlueLight'
  css?: SerializedStyles
  variant?: 'constrained' | 'fullWidth'
}

export const Content = ({
  bg = 'default',
  children,
  className,
  css: styles,
  variant = 'constrained',
  ...rest
}: ContentProps): ReactElement => {
  const theme = useTheme()

  return (
    <Box
      as="section"
      bg={`bg.${bg}`}
      className={cc([className, reactClass('content')])}
      variant={`content.${variant}`}
      css={[
        css`
          margin: 0 auto;
          padding: 20px 0;
          width: 100%;
          background-color: ${theme.ds.colors.base.background};
        `,
        styles,
      ]}
      {...rest}
    >
      <ContentInner>{children}</ContentInner>
    </Box>
  )
}
