/* eslint-disable @typescript-eslint/naming-convention */
import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { accountantTypeToTranslationKey } from '../constants/accountantTypeToTranslationKey'
import { companyToTranslationKey } from '../constants/companies'
import { servicesToTranslationKey } from '../constants/servicesToTranslationKey'
import { AccountantType } from '../types/accountantType'
import { Company } from '../types/company'
import { Service } from '../types/service'

type TranslationGroupKey = 'accountant_type' | 'service' | 'company'

const translations: Record<TranslationGroupKey, Partial<Record<AccountantType | Company | Service, string>>> = {
  accountant_type: accountantTypeToTranslationKey,
  company: companyToTranslationKey,
  service: servicesToTranslationKey,
}

export const getAccountingInfoItems = <T extends string>(
  groupValues: readonly T[],
  translationGroupKey: TranslationGroupKey,
  t: TFunction,
): NavItem<T>[] =>
  groupValues.map((type: T) => ({
    id: type,
    children: t(translations[translationGroupKey][type as string]),
    value: type,
  }))
