import React, { Children, isValidElement, ReactElement, ReactNode, useCallback, useMemo } from 'react'
import { DragDropContext, Draggable, DraggingStyle, Droppable, DropResult } from 'react-beautiful-dnd'

import { DraggableItem } from './elements/DraggableItem'
import * as Styled from './styles'

interface DraggableItemListProps {
  id: string
  children: ReactNode
  isDraggable?: boolean
  onOrderChange: (oldIndex: number, newIndex: number) => void
  scrollableContainer?: HTMLDivElement
  disabled?: boolean
}

export interface CustomDraggableItemProps {
  id: string
  index: number
  count: number
  minCount?: number
  onDelete: () => void
}

export const DraggableItemList = ({
  id,
  children,
  onOrderChange,
  scrollableContainer,
  disabled,
}: DraggableItemListProps): ReactElement => {
  const childrenList = useMemo(
    () =>
      Children.toArray(children)
        .filter((child) => isValidElement(child))
        .map((child) => child as ReactElement<CustomDraggableItemProps>),
    [children],
  )

  const handleDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      const hasPositionChanged = !!destination && source.index !== destination.index

      if (!hasPositionChanged) {
        return
      }

      onOrderChange(source.index, destination.index)
    },
    [onOrderChange],
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={id}>
        {(droppableProvided) => (
          <Styled.DraggableItemListWrapper {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
            {childrenList.map((child, index) => {
              const { id } = child.props
              const isDraggable = childrenList.length > 1 && !disabled

              return (
                <Draggable draggableId={id} index={index} isDragDisabled={!isDraggable} key={id}>
                  {(draggableProvided, snapshotProvided) => {
                    return (
                      <DraggableItem
                        {...draggableProvided.draggableProps}
                        dragHandleProps={draggableProvided.dragHandleProps}
                        isDraggable={isDraggable}
                        isDragging={snapshotProvided.isDragging}
                        ref={draggableProvided.innerRef}
                        style={
                          // Bug workaround
                          // https://github.com/atlassian/react-beautiful-dnd/issues/1881#issuecomment-1652549194
                          scrollableContainer && snapshotProvided.isDragging
                            ? ({
                                ...draggableProvided.draggableProps.style,
                                top:
                                  parseInt((draggableProvided.draggableProps.style as CSSStyleDeclaration).top) -
                                  // not sure completely why this works but it works fine
                                  ((scrollableContainer.parentElement && // when there's no scroll
                                    scrollableContainer.parentElement?.offsetTop) ||
                                    scrollableContainer.scrollTop), // when there's scroll
                              } as DraggingStyle)
                            : draggableProvided.draggableProps.style
                        }
                      >
                        {child}
                      </DraggableItem>
                    )
                  }}
                </Draggable>
              )
            })}
            {droppableProvided.placeholder}
          </Styled.DraggableItemListWrapper>
        )}
      </Droppable>
    </DragDropContext>
  )
}
