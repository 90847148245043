import { basics, boxShadow, lights, shades } from '../../_common'
import { palette } from './palette'

// TODO: Palette is still exported as it is for backward compatibility
// It should be removed, proprietary colors from a theme should not be directly exposed

export default {
  ...basics,
  ...lights,
  ...palette,
  ...shades,
  boxShadow,

  // Brand
  primary: palette.primary,
  secondary: palette.secondary,

  brandOrange: palette.brandOrange,
  brandOrangeLight: palette.brandOrangeLight,

  // Basics
  light: palette.grayA,

  // Links
  link: palette.primary,
  linkHover: palette.blue4,

  // Switch
  on: palette.greenA,
  off: palette.grayA,

  // Actionable
  info: palette.primary,
  success: palette.greenA,
  warning: palette.redA,

  // Currency
  negative: palette.redA,
  positive: palette.greenA,

  // Status
  draft: palette.primary,
  overdue: palette.redA,
  overpaid: palette.primary,
  paid: palette.greenA,
  unpaid: palette.yellowA,

  // Actionables
  confirm: palette.greenA,

  // Contacts
  customer: palette.greenA,
  none: palette.grayA,
  vendor: palette.redA,

  // Transactions
  bill: palette.yellowA,
  billPayment: palette.greenA,
  daybookTransaction: palette.greenA,
  invoice: palette.yellowA,
  invoicePayment: palette.greenA,
  salesTaxPayment: palette.greenA,
  salesTaxReturn: palette.yellowA,

  // Text
  text: {
    black: basics.black,
    default: palette.gray5,
    inverse: basics.white,
    light: palette.grayA,
    white: basics.white,
  },

  // Labels
  label: {
    danger: palette.redA,
    default: palette.primary,
    notification: palette.yellowA,
    primary: palette.primary,
    secondary: palette.secondary,
    success: palette.greenA,
    warning: palette.yellowA,
  },

  // Buttons
  buttons: {
    default: {
      ul: {},
      li: {
        bgHover: palette.shade05,
        text: palette.gray5,
      },
    },
    primary: {
      bg: palette.greenA,
      bgHover: palette.green3,
      bgDisabled: palette.greenA,
      bgCaret: palette.green4,
      bgCaretHover: palette.green5,
      bgCaretDisabled: palette.greenA,
      text: basics.white,
      textDisabled: palette.green4,
    },
    normal: {
      bg: palette.grayA,
      bgHover: palette.gray3,
      bgDisabled: palette.grayA,
      bgCaret: palette.gray4,
      bgCaretHover: palette.gray5,
      bgCaretDisabled: palette.grayA,
      text: basics.white,
      textDisabled: palette.gray4,
    },
    info: {
      bg: palette.primary,
      bgHover: palette.blue3,
      bgDisabled: palette.primary,
      bgCaret: palette.blue4,
      bgCaretHover: palette.blue5,
      bgCaretDisabled: palette.primary,
      text: basics.white,
      textDisabled: palette.blue4,
    },
    warning: {
      bg: palette.redA,
      bgHover: palette.red3,
      bgDisabled: palette.redA,
      bgCaret: palette.red4,
      bgCaretHover: palette.red5,
      bgCaretDisabled: palette.redA,
      text: basics.white,
      textDisabled: palette.red3,
    },
    outline: {
      bg: basics.white,
      bgHover: null,
      bgDisabled: null,
      text: palette.gray4,
      textHover: palette.gray3,
      textDisabled: palette.gray2,
      border: palette.gray4,
      borderHover: palette.gray3,
      borderDisabled: palette.gray2,
    },
  },

  // Form elements

  forms: {
    border: palette.gray3,
    lightBackground: palette.creamyWhite,
  },

  // Backgrounds
  bg: {
    primary: palette.primary,
    primaryLight: palette.blue1,
    secondary: palette.gray4,
    default: basics.white,
    inherit: 'inherit',
    inverse: basics.black,
    soft: palette.gray1,
    soft2: palette.blue2,
  },

  // Table
  table: {
    expander: palette.darkGreen2,
    header: palette.primary,
    headerBorder: palette.greyscale32,
    outerShadow: palette.greyscale35,
    innerShadow: palette.darkGreen1,
    row: palette.greyscale15,
    rowBorder: palette.greyscale34,
    rowIsActive: palette.backgroundGrey,
    rowIsHovered: palette.greyscale05,
  },

  // Badges
  badges: {
    default: {
      bg: palette.blue2,
      text: palette.primaryBlue,
    },
    warning: {
      bg: palette.yellow1,
    },
  },
}
