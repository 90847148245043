import { Color, DefaultValue, getDefaultTransition, Spacing } from '@design-system'

import { transparentize } from 'polished'

export default {
  table: {
    boxShadow: `0 2px 1px ${Color.Gray30}`,

    thead: {
      borderBottom: `1px solid ${Color.Gray30}`,

      tr: {
        boxShadow: `0 1px 3px ${transparentize(0.8, Color.Gray90)}`,
        color: Color.DeepSeaGreen,
      },
    },
    tbody: {
      cursor: 'pointer',
    },
    tr: {
      borderBottom: `1px solid ${Color.Gray30}`,
      transition: getDefaultTransition('background'),
    },
    td: {
      padding: 1,
    },
    'tbody tr:hover, tbody tr:hover > *': {
      background: transparentize(0.75, Color.Gray60),
    },
  },
  gridTable: {
    position: 'relative',
    zIndex: 0,

    th: {
      color: Color.DeepSeaGreen,
      paddingBotttom: '12px',
    },
    tr: {
      paddingLeft: Spacing.XXL,
      paddingRight: Spacing.XXL,
      borderBottom: `1px solid ${Color.Gray30}`,
      position: 'relative',

      '&.total': {
        borderTop: `2px solid ${Color.Gray30}`,

        td: {
          fontWeight: 'bold',
        },
      },
    },
    td: {
      paddingTop: Spacing.XXS,
      paddingBottom: Spacing.XXS,
      whiteSpace: 'nowrap',
    },
    'tbody tr.hoverable:hover, tbody tr.hoverable:hover': {
      background: transparentize(0.75, Color.Gray60),
      cursor: 'pointer',
    },
    thead: {
      tr: {
        paddingTop: Spacing.L,
        paddingBottom: Spacing.L,
        borderRadius: `${DefaultValue.BorderRadius} ${DefaultValue.BorderRadius} 0 0`,
        backgroundColor: Color.White,
        boxShadow: `0 1px 3px ${transparentize(0.8, Color.Gray90)}`,
      },
    },
    tbody: {
      backgroundColor: Color.White,
    },
    '&.stickyHeader': {
      thead: {
        tr: {
          position: 'sticky',
          top: 0,
          zIndex: 2,
          backgroundColor: Color.White,
        },
      },
    },
    '&.selectable': {
      tr: {
        paddingLeft: '35px',
      },
    },
  },
}
