import { amountToDisplayValue } from '@design-system'

import { css } from '@emotion/core'
import cc from 'classcat'
import { format } from 'date-fns'
import { useTheme } from 'emotion-theming'
import get from 'lodash/get'
import numeral from 'numeral'
import React from 'react'
import { Box, Flex, TextProps } from 'rebass'

import { useLocale } from '../../hooks/useLocale'
import { Theme } from '../../types/theme'
import { reactClass } from '../../utils'
import styles from './styles'
import { ColumnType, TableRow } from './types'

export type TableCellProps<T> = TextProps & {
  customStyle: object | undefined
  field: keyof T
  type: ColumnType
  render: Function | undefined
  row: {}
  isTotalLabel?: boolean
  truncate?: boolean
}

export const TableCell = <T extends TableRow>({
  className,
  field,
  isTotalLabel,
  render,
  row,
  type,
  customStyle = {},
  truncate,
  ...rest
}: TableCellProps<T>) => {
  const locale = useLocale()
  const theme = useTheme<Theme>()
  let content
  let currency

  switch (type) {
    case 'currency':
      currency = get(row, 'currency') || ''
      content = get(row, field) ? `${amountToDisplayValue(get(row, field))} ${currency}` : ''
      break
    case 'date':
      content = get(row, field) ? format(get(row, field), 'PP', { locale }) : ''
      break
    case 'number':
      content = get(row, field) ? numeral(get(row, field)).format('0.00') : ''
      break
    case 'text':
      content = get(row, field) ? get(row, field).toString() : ''
      break
    case 'custom':
      content = ''
      break
    default:
      // output text as default
      content = get(row, field) ? get(row, field).toString() : ''
  }

  if (render) {
    content = render(row)
  }

  return (
    <Flex
      as="td"
      className={cc([className, reactClass('infinite-table-cell')])}
      variant="body1"
      css={css`
        align-items: center;
        justify-content: ${isTotalLabel ? 'flex-end' : styles[type].justifyContent};
        color: ${theme.colors.text.default};
        font-weight: 400;
      `}
      style={{ ...customStyle }}
      title={typeof content === 'string' ? content : ''}
      {...rest}
    >
      <Box
        css={
          truncate
            ? css`
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              `
            : ''
        }
      >
        {content}
      </Box>
    </Flex>
  )
}
