import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconAccounting = ({
  color = '#ED5244',
  color2 = '#fff',
  color3 = '#FFDD19',
  color4 = '#41C447',
  width = '20px',
  height = '20px',
  isOutlined,
  bg = '#ffffff',
  ...rest
}: IconProps) => {
  return (
    <Box as="i" className={reactClass('icon-accounting')} {...rest}>
      <svg width={width} height={height} x="0px" y="0px" viewBox="0 0 322.09 360.41">
        <path
          d="M40,348.15V208.5a29.1,29.1,0,0,1,29.1-29.09h0A29.09,29.09,0,0,1,98.14,208.5V348.15a29.09,29.09,0,0,1-29.09,29.1h0A29.1,29.1,0,0,1,40,348.15Z"
          transform="translate(-26.45 -30.34)"
          fill={isOutlined ? 'transparent' : coloring(color)}
          stroke={coloring(color2)}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="27"
        />
        <path
          d="M276.86,348.15V149.41A29.08,29.08,0,0,1,306,120.32h0a29.09,29.09,0,0,1,29.1,29.09V348.15a29.1,29.1,0,0,1-29.1,29.1h0A29.09,29.09,0,0,1,276.86,348.15Z"
          transform="translate(-26.45 -30.34)"
          fill={isOutlined ? 'transparent' : coloring(color3)}
          stroke={coloring(color2)}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="27"
        />
        <path
          d="M158.82,348.15V72.94a29.1,29.1,0,0,1,29.1-29.1h0A29.1,29.1,0,0,1,217,72.94V348.15a29.1,29.1,0,0,1-29.09,29.1h0A29.1,29.1,0,0,1,158.82,348.15Z"
          transform="translate(-26.45 -30.34)"
          fill={isOutlined ? 'transparent' : coloring(color4)}
          stroke={coloring(color2)}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="27"
        />
      </svg>
    </Box>
  )
}
