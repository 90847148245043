import qs from 'qs'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { useSegment } from './useSegment'

export const useAnonymousId = () => {
  const { setAnonymousId: setSegmentAnonymousId } = useSegment()
  const location = useLocation()

  const searchParsed = qs.parse(location?.search || '', { ignoreQueryPrefix: true }) || {}
  const anonymousIdFromQuery = searchParsed.anonymousId

  const setAnonymousId = useCallback(
    (anonymousId?: string) => {
      const anonymousIdValue = anonymousId || anonymousIdFromQuery

      if (typeof anonymousIdValue === 'string') {
        setSegmentAnonymousId(anonymousIdValue)
      }
    },
    [anonymousIdFromQuery, setSegmentAnonymousId],
  )

  return { setAnonymousId }
}
