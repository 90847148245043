import { ElementType } from 'react'
import { Link } from 'react-router-dom'

import { LinkProps } from '../types/linkProps'

interface GetLinkProps {
  as?: ElementType
  href?: string
  to?: string
  target?: string
  rel?: string
}

export const getLinkProps = ({ as, href, to, target, rel }: GetLinkProps): LinkProps | undefined => {
  if (href) {
    return { as: 'a' as ElementType, href, target: target || '_self', rel: rel || 'noopener noreferrer' }
  }

  if (to) {
    return { as: Link as ElementType, to }
  }

  return as ? { as } : undefined
}
