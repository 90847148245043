import { Modal } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../enums/modalId'
import { DeleteTrialDataOptions } from '../../types/deleteTrialDataOptions'
import { DeleteTrialDataModalContextProvider } from './contexts/deleteTrialDataModalContext'
import { ModalBody } from './elements/ModalBody'
import { ModalFooter } from './elements/ModalFooter'

interface DeleteTrialDataModalProps {
  deleteTrialDataOptions: DeleteTrialDataOptions
}

export const DeleteTrialDataModal = ({ deleteTrialDataOptions }: DeleteTrialDataModalProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <DeleteTrialDataModalContextProvider deleteTrialDataOptions={deleteTrialDataOptions}>
      <Modal id={ModalId.DeleteTrialDataModal} size="s" closable>
        <Modal.Header title={t('delete_trial_data_modal.title')} />
        <Modal.Body>
          <ModalBody />
        </Modal.Body>
        <Modal.Footer>
          <ModalFooter />
        </Modal.Footer>
      </Modal>
    </DeleteTrialDataModalContextProvider>
  )
}
