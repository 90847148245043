import { Space, Text } from '@design-system'

import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { BankReconciliationEmptyLines } from '../../../../../../../../../../assets/images'
import { useSegment } from '../../../../../../../../../../hooks'
import * as Styled from '../styles'

export const BankLinesEmptyState = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()

  useEffect(() => {
    track('xxx - expenses - Bank line modal - no bank lines shown')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Styled.EmptyStateWrapper>
      <BankReconciliationEmptyLines />
      <Space size="s" />
      <Text>{t('bill.payment_reconcile.empty_state')}</Text>
    </Styled.EmptyStateWrapper>
  )
}
