import { ReactElement } from 'react'

import { WidgetId } from '../enums/widgetId'
import { BankIntegration } from '../widgets/BankIntegration'
import { Bills } from '../widgets/Bills'
import { FindAccountant } from '../widgets/FindAccountant'
import { GetStarted } from '../widgets/GetStarted'
import { Invoices } from '../widgets/Invoices'
import { Overview } from '../widgets/Overview'
import { OverviewMonthly } from '../widgets/OverviewMonthly'
import { Tasks } from '../widgets/Tasks'
import { TopCustomers } from '../widgets/TopCustomers'
import { TopVendors } from '../widgets/TopVendors'
import { Upsell } from '../widgets/Upsell'
import { Vat } from '../widgets/Vat'

export const widgetToComponent: Record<WidgetId, () => ReactElement | null> = {
  BankIntegration,
  Bills,
  FindAccountant,
  GetStarted,
  Invoices,
  Overview,
  OverviewMonthly,
  Tasks,
  TopCustomers,
  TopVendors,
  Upsell,
  Vat,
}
