import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import { QueryKeys } from '../../../../../../../enums/queryKeys'

export const useInvalidateVatAccounts = () => {
  const queryClient = useQueryClient()

  const invalidateVatAccounts = useCallback(() => {
    queryClient.invalidateQueries(QueryKeys.VatAccounts)
  }, [queryClient])

  return {
    invalidateVatAccounts,
  }
}
