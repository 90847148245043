import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconSettings = ({
  color = 'inherit',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-settings')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={coloring(color)}
        d="M31,19v-6h-3.387c-0.28-1.092-0.707-2.127-1.278-3.093l2.392-2.393l-4.242-4.242l-2.393,2.392 C21.127,5.094,20.092,4.666,19,4.387V1h-6v3.387c-1.092,0.279-2.127,0.707-3.093,1.277L7.515,3.272L3.272,7.515l2.392,2.393 C5.094,10.873,4.666,11.908,4.387,13H1v6h3.387c0.279,1.092,0.707,2.127,1.278,3.093l-2.392,2.393l4.242,4.242l2.393-2.392 c0.966,0.57,2.001,0.998,3.093,1.277V31h6v-3.387c1.092-0.279,2.127-0.707,3.093-1.277l2.393,2.392l4.242-4.242l-2.392-2.393 c0.571-0.966,0.998-2.001,1.278-3.093H31z M19,16c0,1.654-1.346,3-3,3s-3-1.346-3-3s1.346-3,3-3S19,14.346,19,16z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M18,2v3.19c1.548,0.285,2.984,0.889,4.232,1.749l2.253-2.253l2.828,2.828L25.06,9.768 c0.86,1.248,1.464,2.683,1.749,4.232H30v4h-3.19c-0.285,1.548-0.889,2.984-1.749,4.232l2.253,2.253l-2.828,2.828l-2.253-2.253 c-1.248,0.86-2.683,1.464-4.232,1.749V30h-4v-3.19c-1.548-0.285-2.984-0.889-4.232-1.749l-2.253,2.253l-2.828-2.828l2.253-2.253 C6.079,20.984,5.475,19.548,5.19,18H2v-4h3.19c0.285-1.548,0.889-2.984,1.749-4.232L4.686,7.515l2.828-2.828L9.768,6.94 C11.016,6.079,12.452,5.475,14,5.19V2H18 M18,0h-4c-1.105,0-2,0.895-2,2v1.635c-0.661,0.212-1.3,0.477-1.917,0.792L8.929,3.272 C8.538,2.882,8.027,2.686,7.515,2.686S6.491,2.882,6.101,3.272L3.272,6.1c-0.781,0.781-0.781,2.047,0,2.828l1.154,1.154 C4.111,10.7,3.847,11.339,3.635,12H2c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h1.635c0.212,0.661,0.477,1.3,0.792,1.917 l-1.154,1.154c-0.781,0.781-0.781,2.047,0,2.828l2.828,2.828c0.391,0.391,0.902,0.586,1.414,0.586s1.024-0.195,1.414-0.586 l1.154-1.154c0.616,0.315,1.256,0.58,1.917,0.792V30c0,1.105,0.895,2,2,2h4c1.105,0,2-0.895,2-2v-1.635 c0.661-0.212,1.3-0.477,1.917-0.792l1.154,1.154c0.391,0.391,0.902,0.586,1.414,0.586s1.024-0.195,1.414-0.586l2.828-2.828 c0.781-0.781,0.781-2.047,0-2.828l-1.154-1.154c0.315-0.616,0.58-1.256,0.792-1.917H30c1.105,0,2-0.895,2-2v-4c0-1.105-0.895-2-2-2 h-1.635c-0.212-0.661-0.476-1.3-0.792-1.917l1.154-1.154c0.781-0.781,0.781-2.047,0-2.828l-2.828-2.828 c-0.391-0.391-0.902-0.586-1.414-0.586s-1.024,0.195-1.414,0.586l-1.154,1.154C21.3,4.111,20.661,3.847,20,3.635V2 C20,0.895,19.105,0,18,0L18,0z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M16,13c1.657,0,3,1.343,3,3s-1.343,3-3,3s-3-1.343-3-3S14.343,13,16,13 M16,11c-2.757,0-5,2.243-5,5 s2.243,5,5,5s5-2.243,5-5S18.757,11,16,11L16,11z"
      ></path>
    </svg>
  </Box>
)
