import colors from './colors'

export default {
  navigationButtons: {
    button: {
      backgroundColor: colors.blue2,
    },
    icon: {
      color: colors.blue3,
    },
  },
}
