import { SkeletonBox } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'

import { useGlobalFiscalYear } from '../../contexts/globalFiscalYearContext'
import { PeriodMode } from '../../enums/periodMode'
import { useOrganizationFiscalYears } from '../../hooks/useOrganizationFiscalYears'
import { PeriodValue } from '../../types/periodValue'
import { getPeriodValueFromFiscalYear } from '../../utils/getPeriodValueFromFiscalYear'
import { decodePeriodValue, PeriodSelect, PeriodSelectProps } from '../selects/PeriodSelect'

export interface GlobalPeriodSelectProps extends Omit<PeriodSelectProps, 'periodValue' | 'onChange'> {
  onChange?: (periodValue: PeriodValue) => void
  encodedPeriod?: string
}

export const GlobalPeriodSelect = ({
  disabledPeriodModes,
  encodedPeriod,
  fullWidth,
  onChange,
  placement,
  minDate: minDateControlled,
  maxDate: maxDateControlled,
}: GlobalPeriodSelectProps): ReactElement => {
  const { years, isLoading } = useOrganizationFiscalYears()
  const { fiscalYear: globalFiscalYear, setFiscalYear } = useGlobalFiscalYear()

  const decodedPeriod: PeriodValue | undefined = useMemo(
    () =>
      (encodedPeriod && decodePeriodValue(encodedPeriod)) ||
      (globalFiscalYear !== undefined ? getPeriodValueFromFiscalYear(globalFiscalYear) : undefined),
    [encodedPeriod, globalFiscalYear],
  )

  const firstYear = years[years.length - 1] - 1
  const lastYear = years[0]

  let maxDate = new Date(lastYear, 11, 31)
  let minDate = new Date(firstYear, 0, 1)

  if (maxDateControlled) {
    maxDate = new Date(Math.min(maxDate.getTime(), maxDateControlled.getTime()))
  }

  if (minDateControlled) {
    minDate = new Date(Math.max(minDate.getTime(), minDateControlled.getTime()))
  }

  const handlePeriodChange = useCallback(
    (periodValue?: PeriodValue) => {
      if (!periodValue) {
        return
      }

      if (periodValue.mode === PeriodMode.FiscalYear && typeof periodValue.value === 'number') {
        setFiscalYear(periodValue.value)
      }

      onChange?.(periodValue)
    },
    [onChange, setFiscalYear],
  )

  if (isLoading) {
    return <SkeletonBox height={40} width={100} fullWidth={fullWidth} />
  }

  return (
    <PeriodSelect
      disabledPeriodModes={disabledPeriodModes}
      fullWidth={fullWidth}
      maxDate={maxDate}
      minDate={minDate}
      onChange={handlePeriodChange}
      periodValue={decodedPeriod}
      placement={placement}
    />
  )
}
