import { AnyAction } from 'redux'

import { COUNTRIES_RECEIVED, COUNTRIES_REQUESTED } from './actions'
import { Country } from './types'

export type CountriesRequestedPayload = {
  locale: string
  filters: {
    text: string | null
  }
}

export type CountriesRequestedAction =
  | AnyAction
  | {
      payload: CountriesRequestedPayload
      type: typeof COUNTRIES_REQUESTED
    }

export type CountriesReceivedPayload = {
  countries: Country[]
}

export type CountriesReceivedAction =
  | AnyAction
  | {
      payload: CountriesReceivedPayload
      type: typeof COUNTRIES_RECEIVED
    }

export function countriesRequested(payload: CountriesRequestedPayload): CountriesRequestedAction {
  return {
    payload,
    type: COUNTRIES_REQUESTED,
  }
}

export function countriesReceived(payload: CountriesReceivedPayload): CountriesReceivedAction {
  return {
    payload,
    type: COUNTRIES_RECEIVED,
  }
}
