import { amountToDisplayValue, Color, FontWeight } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import cc from 'classcat'
import React from 'react'
import { Box, Flex, FlexProps, Text } from 'rebass'

import { reactClass } from '../../utils'

export type Placement = 'left' | 'right'

export type SummaryItem = {
  id: string
  text: string
  value: number
}

export type FinancialSummaryProps = FlexProps & {
  borderColor?: string
  items: SummaryItem[]
  placement?: Placement
}

type SummaryWrapperProps = FlexProps & {
  placement: Placement
}

const SummaryWrapper = styled(Flex)<SummaryWrapperProps>`
  justify-content: ${({ placement }) => (placement === 'left' ? 'flex-start' : 'flex-end')};
`

const SummaryContainer = styled(Box)`
  display: inline-flex;
  flex-direction: column;
`

type ItemProps = FlexProps & {
  withBorder?: boolean
}

const Item = styled(Flex)<ItemProps>`
  padding-top: 5px;
  padding-bottom: 3px;
  justify-content: space-between;

  ${({ withBorder }) =>
    withBorder &&
    css`
      border-top: 1px solid ${Color.Gray40};
      border-bottom: 2px solid ${Color.Gray40};
    `}
`

const ItemText = styled(Text)`
  width: 100%;
  font-weight: ${FontWeight.Medium};
  text-align: right;
`

const ItemValue = styled(Text)`
  min-width: 100px;
  margin-left: 30px;
  flex-shrink: 0;
  text-align: right;
`

const renderItems = (items: SummaryItem[]) =>
  items.map((item, index) => {
    const { text, value } = item
    const isLastItem = index === items.length - 1

    return (
      <Item as="li" key={text} withBorder={isLastItem}>
        <ItemText>{text}</ItemText>
        <ItemValue>{amountToDisplayValue(value)}</ItemValue>
      </Item>
    )
  })

export const FinancialSummary = ({ className, items, placement = 'left', ...rest }: FinancialSummaryProps) => (
  <SummaryWrapper className={cc([className, reactClass('financial-summary')])} placement={placement} {...rest}>
    <SummaryContainer>
      <Box as="ul">{renderItems(items)}</Box>
    </SummaryContainer>
  </SummaryWrapper>
)
