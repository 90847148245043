import { ItemsPerPage, Select, SelectProps } from '@design-system'

import React, { forwardRef, ReactElement, Ref, useCallback, useMemo } from 'react'

import { useUmbrellaOrganizations } from '@modules-deprecated/inbox/hooks/useUmbrellaOrganizations'

import { getItems } from './utils/getItems'

interface UmbrellaOrganizationSelectProps extends Omit<SelectProps<string>, 'items' | 'onSelect' | 'suffix'> {
  onSelect?: (value?: string) => void
  organizationsIdsToSkip?: string[]
}

export const UmbrellaOrganizationSelect = forwardRef(
  (
    { onSelect, selectedId, organizationsIdsToSkip, ...selectProps }: UmbrellaOrganizationSelectProps,
    ref: Ref<HTMLDivElement | null>,
  ): ReactElement => {
    const { organizations } = useUmbrellaOrganizations({
      pageSize: 10000 as ItemsPerPage,
    })
    const organizationsIdsToSkipWithoutCurrentSelection = organizationsIdsToSkip?.filter(
      (organizationId) => organizationId !== selectedId,
    )

    const items = useMemo(
      () => getItems(organizations || [], organizationsIdsToSkipWithoutCurrentSelection),
      [organizations, organizationsIdsToSkipWithoutCurrentSelection],
    )

    const handleSelect = useCallback(
      (_id?: string, value?: string) => {
        onSelect?.(value)
      },
      [onSelect],
    )

    return (
      <Select
        {...selectProps}
        items={items}
        dropdownSize={selectProps.dropdownSize || 'fitTrigger'}
        onSelect={handleSelect}
        ref={ref}
        selectedId={selectedId}
      />
    )
  },
)
