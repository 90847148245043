import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import { UseFormReturn } from 'react-hook-form'
import { AnyObjectSchema, Asserts } from 'yup'

// workaround for mutationg lines in defaultValues object. It fixes a bug with setting up isDirty and dirtyFields wrongly for lines
export const fixDefaultValues = <T extends AnyObjectSchema>(form: UseFormReturn<Asserts<T>, any>) => {
  for (const property in form.control._defaultValues) {
    if (typeof form.control._defaultValues === 'object') {
      set(form.control._defaultValues, property, cloneDeep(form.control._defaultValues[property]))
    }
  }
}
