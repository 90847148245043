import { useMutation } from 'react-query'

import { APIError } from '../../../../utils'
import { updateUser, UpdateUserPayload, UpdateUserResponseData } from '../query-api'

interface UseUpdateUserProps {
  onError?: (error?: APIError, variables?: UpdateUserPayload, context?: unknown) => void
  onSettled?: (data: UpdateUserResponseData | undefined, error: APIError | null | undefined) => void
  onSuccess?: (data: UpdateUserResponseData) => void
  onMutate?: ((variables: UpdateUserPayload) => unknown) | undefined
}

export const useUpdateUser = (props?: UseUpdateUserProps) => {
  const { onError, onSuccess, onSettled, onMutate } = props || {}
  const { mutate: update, ...rest } = useMutation(updateUser, {
    onSuccess,
    onError,
    onSettled,
    onMutate,
  })

  return { update, ...rest }
}
