import { useEffect } from 'react'

import { getGlobalBilly } from '../utils/globalBilly'
import { history, isURLChangeStartedByEmberRouter } from '../utils/history'

export const useReactEmberRouterConnection = () => {
  useEffect(() => {
    // We catch the URL change and prevent it from happening
    // Then we use Ember's router to create transition
    // thanks to this Ember hooks like willTransition and didTransition will be called
    // and URL will be updated by Ember's router but using our history object
    // This code also makes dirty-route modal work properly
    const blockUrlChange = () =>
      history.block((location) => {
        if (location.pathname !== window.location.pathname && !isURLChangeStartedByEmberRouter) {
          try {
            const billyApp = getGlobalBilly()
            const emberRouter = billyApp?.__container__?.lookup('router:main')
            // no mater if transition is successful or not we need to add the listener back
            emberRouter.transitionTo(`${location.pathname}${location.search}${location.hash}`)
          } catch (error) {
            console.error('Ember Router:', error)
          }

          // Prevent React Router from changing the URL
          return false
        }
      })

    const removeListener = blockUrlChange()

    return () => {
      removeListener()
    }
  }, [])
}
