import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface SettingsSectionColumnsContainerProps {
  children: ReactNode
}

export const SettingsSectionColumnsContainer = ({ children }: SettingsSectionColumnsContainerProps): ReactElement => {
  return <Styled.SettingsSectionColumnsWrapper>{children}</Styled.SettingsSectionColumnsWrapper>
}
