import { getCurrentLocale } from '@design-system'

import qs from 'qs'

import { getRequest } from '../../../utils'
import { TaxRate } from './types'

interface TaxRatesResponseData {
  taxRates: TaxRate[]
}

interface FetchTaxRatesParams {
  isActive?: boolean
  organizationId: string
  purchase?: boolean
  sale?: boolean
  text?: string
  include?: string
}

export const fetchTaxRates = ({
  isActive,
  organizationId,
  purchase,
  sale,
  text,
  include = 'taxRate.deductionComponents:embed',
}: FetchTaxRatesParams): Promise<TaxRatesResponseData> => {
  const locale = getCurrentLocale()
  return getRequest(
    `/v2/taxRates?${qs.stringify({
      isActive,
      organizationId,
      purchase,
      sale,
      text,
      include,
    })}`,
    {
      'accept-language': locale,
    },
  )
}
