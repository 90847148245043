import { GetBillResponseData } from '../../../../../query-api'
import { BillLineAmounts } from '../../../../../utils/getAmountsFromBillLines'
import { getInclVatAmount } from '../../../utils/getInclVatAmount'

export const getBillLinesFormattedAndConverted = (billData?: GetBillResponseData): BillLineAmounts[] | undefined => {
  const { billLines, bill } = billData || {}

  if (!bill || !billLines?.length) {
    return
  }

  return billLines.map((billLine) => ({
    vatAmount: billLine.tax || 0,
    inclVatAmount: getInclVatAmount(bill, billLine),
  }))
}
