import { useMutation } from 'react-query'

import { deletePaymentMethod } from '../query-api/payment-methods-query'
import { APIError } from '../utils'

interface UseDeletePaymentMethodProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useDeletePaymentMethod = (props?: UseDeletePaymentMethodProps) => {
  const { onError, onSuccess } = props || {}
  const { mutate, isLoading: isProcessing, ...rest } = useMutation(deletePaymentMethod, { onSuccess, onError })

  return { delete: mutate, isProcessing, ...rest }
}
