import { UmbrellaOrganization } from '../../organizations/types'
import { Organization } from '../types/organization'

interface GetOrganizationIdsProps {
  organization?: Organization
  umbrellaOrganization?: UmbrellaOrganization
}

interface OrganizationIds {
  organizationId: string
  ownerUserId: string
  ownerUmbrellaId: string
}

export const getOrganizationIds = ({
  umbrellaOrganization,
  organization,
}: GetOrganizationIdsProps): OrganizationIds => {
  if (umbrellaOrganization) {
    const umbrellaOrganizationId = umbrellaOrganization?.organizationId
    const umbrellaOrganizationOwnerUserId =
      umbrellaOrganization?.owner.type === 'user' ? umbrellaOrganization.owner.id : ''
    const umbrellaOrganizationOwnerUmbrellaId =
      umbrellaOrganization?.owner.type === 'umbrella' ? umbrellaOrganization.owner.id : ''

    return {
      organizationId: umbrellaOrganizationId,
      ownerUserId: umbrellaOrganizationOwnerUserId,
      ownerUmbrellaId: umbrellaOrganizationOwnerUmbrellaId,
    }
  }

  return {
    organizationId: organization?.id || '',
    ownerUserId: organization?.ownerUserId || '',
    ownerUmbrellaId: organization?.ownerUmbrellaId || '',
  }
}
