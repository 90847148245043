import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../../../config/queryClient'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { usePeriodDates } from '../../../../../hooks/usePeriodDates'
import { fetchInvoicesSummary } from '../../../query-api'
import { FetchInvoicesSummaryParams } from '../../../types/fetchInvoicesSummaryParams'

interface UseFetchInvoicesSummaryProps {
  period?: string
  searchQuery?: string
}

interface UseFetchInvoicesSummaryResponse {
  data?: any
  isLoading: boolean
}

export const useFetchInvoicesSummary = ({
  period,
  searchQuery,
}: UseFetchInvoicesSummaryProps): UseFetchInvoicesSummaryResponse => {
  const { isLoading: isLoadingPeriodDates, fromDate, toDate } = usePeriodDates(period)

  const queryParams: FetchInvoicesSummaryParams = useMemo(
    () => ({
      ...(searchQuery ? { searchQuery } : {}),
      ...(fromDate && toDate ? { fromDate, toDate } : {}),
    }),
    [fromDate, searchQuery, toDate],
  )
  const queryKey: [QueryKeys, FetchInvoicesSummaryParams] = useMemo(
    () => [QueryKeys.BillsSummary, queryParams],
    [queryParams],
  )

  const {
    data: invoicesSummaryData,
    isIdle,
    isLoading,
  } = useQuery(queryKey, () => fetchInvoicesSummary(queryParams), {
    enabled: !isLoadingPeriodDates,
    keepPreviousData: true,
    staleTime: defaultStaleTime,
  })

  return {
    data: invoicesSummaryData?.data?.summary,
    isLoading: isIdle || isLoading,
  }
}
