import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { getOrganizationSalesTaxPeriods } from '../query-api'
import { SalesTaxPeriods } from '../types/salesTaxPeriods'

interface UseVatPeriod {
  isLoading: boolean
  salesTaxPeriods: SalesTaxPeriods
}

export const useSalesTaxPeriods = (): UseVatPeriod => {
  const { organization } = useUserOrganization()
  const {
    data: salesTaxPeriodsData,
    isLoading,
    ...rest
  } = useQuery(
    [QueryKeys.SalesTaxPeriods, organization?.id],
    () => getOrganizationSalesTaxPeriods(organization?.id || ''),
    {
      enabled: !!organization?.id,
    },
  )

  return {
    isLoading,
    salesTaxPeriods: salesTaxPeriodsData?.periods || {},
    ...rest,
  }
}
