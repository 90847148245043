import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { TaxRatesContextProvider } from '@modules-deprecated/app/taxrates'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { Bills, BillsEmberConnectionContextProvider } from '@views/bills'

import { queryClient } from '../../config/queryClient'
import { DirtyRouteContextProvider, DirtyRouteProps } from '../../contexts/dirtyRouteContext'
import { EmberConnectBank, EmberConnectBankContextProvider } from '../../contexts/emberConnectBankContext'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { GlobalFiscalYearContextProvider } from '../../contexts/globalFiscalYearContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

const MODULE_NAME = 'bills-module'

class BillsWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('invalidateBill') invalidateTransactions: Function
  @registerEvent('leaveRoute') leaveRoute: Function
  @registerEvent('navigate') navigate: Function
  @registerEvent('registerPayment') registerPayment: Function
  @registerEvent('setDirtyRouteFlag') setDirtyRouteFlag: Function
  @registerEvent('connectBank') connectBank: Function
}

interface BillsModuleProps extends DirtyRouteProps {
  isBetaBillsEnabled: boolean
  onConnectBank: EmberConnectBank
  onInvalidateBill: EmberEventFn
  onNavigate: EmberNavigate
  onRegisterPayment: EmberEventFn
  onResetAndGoToBill: EmberEventFn
  organizationId: string
}

export const BillsModule = ({
  onConnectBank,
  onInvalidateBill,
  onLeaveRoute,
  onNavigate,
  onRegisterPayment,
  onSetDirtyRouteFlag,
  organizationId,
}: BillsModuleProps): ReactElement => (
  <ThemeProvider>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <CurrentUserContextProvider>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <GlobalFiscalYearContextProvider organizationId={organizationId}>
              <UserOrganizationSettingsContextProvider organizationId={organizationId}>
                <BillsEmberConnectionContextProvider
                  onRegisterPayment={onRegisterPayment}
                  onInvalidateBill={onInvalidateBill}
                >
                  <EmberRouterContextProvider onNavigate={onNavigate}>
                    <AccountsContextProvider organizationId={organizationId}>
                      <TaxRatesContextProvider organizationId={organizationId}>
                        <ModalContextProvider>
                          <DirtyRouteContextProvider
                            onLeaveRoute={onLeaveRoute}
                            onSetDirtyRouteFlag={onSetDirtyRouteFlag}
                          >
                            <EmberConnectBankContextProvider onConnectBank={onConnectBank}>
                              <Bills />
                            </EmberConnectBankContextProvider>
                          </DirtyRouteContextProvider>
                        </ModalContextProvider>
                      </TaxRatesContextProvider>
                    </AccountsContextProvider>
                  </EmberRouterContextProvider>
                </BillsEmberConnectionContextProvider>
              </UserOrganizationSettingsContextProvider>
            </GlobalFiscalYearContextProvider>
          </UserOrganizationContextProvider>
        </CurrentUserContextProvider>
      </QueryClientProvider>
    </HashRouter>
  </ThemeProvider>
)

const BillsCustomElement = createCustomElement(BillsModule, BillsWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, BillsCustomElement)

export default BillsCustomElement
