import { QueryKey } from 'react-query'

import { getRequest, putRequest } from '../../../utils'
import {
  Pagination,
  Voucher,
  VoucherFile,
  VoucherFileMeta,
  VoucherListQueryParams,
  VoucherUpdatePayloadData,
} from '../types'
import {
  deleteVoucher as deleteVoucherApi,
  getVoucher,
  getVouchersList,
  updateVoucherFiles,
  voucherServiceUrl,
} from './api'

interface FetchVoucherProps {
  organizationId: string
  voucherId: string
}

interface VoucherFetchQuery {
  queryKey: [QueryKey, FetchVoucherProps]
}

export const fetchVoucher = async ({ queryKey }: VoucherFetchQuery) => {
  const [, { organizationId, voucherId }] = queryKey
  return getVoucher(organizationId, voucherId)
}

const PAGINATION_LIMIT = 100

interface FetchVouchersListProps {
  organizationId: string
  params: VoucherListQueryParams
}

interface VouchersListQuery {
  queryKey: [QueryKey, FetchVouchersListProps]
}

export interface VoucherListResponse {
  vouchers: Voucher[]
  pagination: Pagination
}

export const fetchVouchersList = async ({ queryKey }: VouchersListQuery): Promise<VoucherListResponse> => {
  const [, { organizationId, params }] = queryKey
  const response = await getVouchersList(organizationId, { ...params, limit: params.limit || PAGINATION_LIMIT }, true)

  if (response.status !== 200) {
    throw new Error(`Could not fetch voucher list for organization with id="${organizationId}"`)
  }

  const pagination = {
    total: parseInt(response.headers.get('x-pagination-total') || '') || 0,
    offset: parseInt(response.headers.get('x-pagination-offset') || '') || 0,
    limit: parseInt(response.headers.get('x-pagination-limit') || '') || PAGINATION_LIMIT,
  }
  const vouchers = await response.json()
  return { pagination, vouchers }
}

interface UpdateVoucherProps extends FetchVoucherProps {
  payload: VoucherUpdatePayloadData
}

export const updateVoucher = async ({ organizationId, voucherId, payload }: UpdateVoucherProps) => {
  const response = await putRequest(
    `${voucherServiceUrl}/organizations/${organizationId}/vouchers/${voucherId}`,
    payload,
  )

  if (!response.id) {
    throw new Error(`Could not update voucher with id "${voucherId}"`)
  }
}

interface ReplaceVoucherFilesProps extends FetchVoucherProps {
  files: File[]
}

export const replaceVoucherFiles = ({ organizationId, voucherId, files }: ReplaceVoucherFilesProps) =>
  updateVoucherFiles(organizationId, voucherId, files)

export type DeleteVoucherProps = FetchVoucherProps

export const deleteVoucher = ({ organizationId, voucherId }: DeleteVoucherProps) =>
  deleteVoucherApi(organizationId, voucherId)

interface FetchVoucherFileResponse {
  meta: VoucherFileMeta
  file: VoucherFile
}

export const fetchVoucherFile = (fileId: string): Promise<FetchVoucherFileResponse> => getRequest(`/v2/files/${fileId}`)

export const deleteVouchers = (vouchers: DeleteVoucherProps[]) =>
  Promise.all(vouchers.map(({ organizationId, voucherId }) => deleteVoucherApi(organizationId, voucherId)))
