import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { Asable } from '../../types/asable'

interface LinkProps extends Asable {
  disabled?: boolean
  underlined?: boolean
  withAnimation?: boolean
}

export const Link = styled.a<LinkProps>`
  display: inline;
  color: currentColor;
  font: inherit;
  cursor: pointer;
  text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
  background-image: linear-gradient(transparent 50%, ${Color.ElectricGreen} 0%);
  background-size: auto 200%;
  transition: background 0.14s ease-in-out;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      text-decoration: none;
    `}

  ${({ withAnimation }) =>
    withAnimation &&
    css`
      &:hover {
        background-position-y: 100%;
        text-decoration: none;
      }
    `}
`
