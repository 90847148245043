import styled from '@emotion/styled'

import { NavListItem } from '../../elements/NavListItem'

export const NavListItemWithBlockDescription = styled(NavListItem)`
  height: auto;
` as typeof NavListItem

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SuffixWrapper = styled.span`
  display: flex;
  margin-left: auto;
`
