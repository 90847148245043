import { Spacing, Switch } from '@design-system'

import styled from '@emotion/styled'

export const SwitchToggle = styled(Switch)`
  width: fit-content;
`

export const OrganizationFeatureItemWrapper = styled.div`
  width: 100%;

  & + & {
    margin-top: ${Spacing.XL};
  }
`

export const FeatureHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${Spacing.M};
  margin-bottom: ${Spacing.S};
`
