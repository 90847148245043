import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { VerificationResponse, verifyRecipient } from '../query-api'

interface UseVerifyRecipientIdentifierProps {
  onError?: (error?: APIError) => void
  onSuccess?: (response: VerificationResponse) => void
}

interface VerifyRecipientProps {
  organizationId: string
  identifierType: string
  gln?: string
  cvr?: string
}

export const useVerifyRecipientIdentifier = (props?: UseVerifyRecipientIdentifierProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: verify } = useMutation((props: VerifyRecipientProps) => verifyRecipient(props), {
    onError,
    onSuccess,
  })

  return { verify }
}
