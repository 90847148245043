import { ComponentsGroup as ComponentsGroupComponent, Spacing, Tag as TagComponent } from '@design-system'

import styled from '@emotion/styled'

export const ComponentsGroup = styled(ComponentsGroupComponent)`
  flex-wrap: wrap;
`

export const Tag = styled(TagComponent)`
  margin-right: ${Spacing.S};
  margin-bottom: ${Spacing.S};
`
