import colors from './colors'

export default {
  customScrollbar: {
    '::-webkit-scrollbar-thumb': {
      backgroundColor: colors.primaryDarkend,
    },
  },
  customScrollbarGray: {
    '::-webkit-scrollbar-thumb': {
      backgroundColor: colors.gray2,
    },
  },
}
