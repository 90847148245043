import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconLogIn = ({
  color = '#FFFFFF',
  color2 = '#095A8C',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-login')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 396.3 435.1">
      <g>
        <path
          fill={coloring(color)}
          d="M148.4,435.1H56.1C25.2,435.1,0,409.9,0,379V56.1C0,25.2,25.2,0,56.1,0h92.2c5.5,0,10,4.5,10,10s-4.5,10-10,10
          H56.1C36.2,20,20,36.2,20,56.1V379c0,19.9,16.2,36.1,36.1,36.1h92.2c5.5,0,10,4.5,10,10S153.9,435.1,148.4,435.1z"
        />
      </g>
      <g>
        <path
          fill={coloring(color2)}
          d="M271.8,245.2H103.1c-1.8,0-3.2-1.5-3.2-3.2v-47.5c0-1.8,1.5-3.2,3.2-3.2h168.6v-86.6l113,113l-113,113V245.2z"
        />
      </g>
      <g>
        <path
          fill={coloring(color)}
          d="M281.8,128.7l88.8,88.8l-88.8,88.9v-71.2H109.9v-34h171.9V128.7 M269.2,90.9c-4.1,0-7.4,3.3-7.5,7.3
          c0,0,0,0.1,0,0.1v82.9H103.1c-7.3,0-13.2,5.9-13.2,13.2V242c0,7.3,5.9,13.2,13.2,13.2h158.6v81.6c0,4.1,3.3,7.4,7.4,7.4
          c0,0,0.1,0,0.1,0c2,0,3.8-0.8,5.2-2.2l120.1-120.1c2.4-2.4,2.4-6.4,0-8.8L274.4,93.1C273.1,91.7,271.2,90.9,269.2,90.9z"
        />
      </g>
    </svg>
  </Box>
)
