import download from 'downloadjs'
import omit from 'lodash/omit'
import React, { Fragment, memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { DefaultValue } from '../../../../enums/defaultValue'
import { useMeasureDirty } from '../../../../hooks/useMeasureDirty'
import { Direction, usePagination } from '../../../../hooks/usePagination'
import { NavigationDirection } from '../../../../types/navigationDirection'
import { FilesPagination } from '../../../FilesPagination/FilesPagination'
import { FileType, FitType, PreviewFile } from '../../types'
import { arePropsEqual } from '../../utils/arePropsEqual'
import { getFileType } from '../../utils/getFileType'
import { FileActions } from '../FileActions'
import { FileName } from '../FileName'
import { ImagePreview } from '../ImagePreview'
import { LazyPreview, LazyPreviewOptions } from '../LazyPreview'
import { PdfReact } from '../PdfReact'
import * as Styled from './styles'

const formatToComponent = {
  [FileType.Image]: ImagePreview,
  [FileType.Pdf]: PdfReact,
}

export type FilesPreviewContentProps = {
  asType?: FileType
  bordered?: boolean
  circle?: boolean
  className: string
  files: PreviewFile[]
  fitToHeight?: boolean
  fitToWidth?: boolean
  fitType?: FitType // works only when both fitToHeight and fitToWidth are set to 'true'
  height?: string
  lazyLoadOptions?: LazyPreviewOptions
  loading?: ReactElement | string
  navigationDirection?: NavigationDirection
  onClick?: () => void
  onDelete?: (file: PreviewFile) => void
  onEdit?: (file: File) => void
  onError?: () => void
  onImageZoomClick?: () => void
  onLoad?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  usePdfImageProxy?: boolean
  width?: string
  withDeleteButton?: boolean
  withDownloadButton?: boolean
  withEditButton?: boolean
  withFilename?: boolean
  withLazyLoad?: boolean
  withModalZoom?: boolean
  withNavigation?: boolean
  withRotate?: boolean
  zoomable?: boolean
  zoomScale?: number
}

type RotatedFiles = Record<string, number>

const ROTATION_DEFAULT = 0
const ROTATION_STEP = 90
const ROTATION_FULL = 360

export const FilesPreviewContent = memo(
  ({
    asType,
    bordered,
    circle = false,
    className,
    files,
    fitToHeight = false,
    fitToWidth = false,
    fitType = 'contain',
    lazyLoadOptions,
    loading,
    navigationDirection = 'horizontal',
    onDelete,
    onEdit,
    onError,
    onLoad,
    onImageZoomClick,
    withDeleteButton = false,
    withDownloadButton,
    withEditButton = false,
    withFilename = false,
    withModalZoom = false,
    withLazyLoad = true,
    withNavigation = true,
    withRotate = false,
    zoomable,
    zoomScale = DefaultValue.ImageZoomScale,
    ...rest
  }: FilesPreviewContentProps): ReactElement | null => {
    const {
      activePage: activeFilePage,
      prevActivePage: prevActiveFilePage,
      canChangePage: canChangeFilePage,
      changePage: changeFilePage,
      setPagesCount: setFilePagesCount,
      pagesCount: filePagesCount,
    } = usePagination()
    const {
      activePage: activePdfPage,
      canChangePage: canChangePdfPage,
      changePage: changePdfPage,
      isFirstPage: isFirstPdfPage,
      isLastPage: isLastPdfPage,
      setPage: setPdfPage,
      setPagesCount: setPdfPagesCount,
      pagesCount: pdfPagesCount,
    } = usePagination()

    const [isLoading, setLoading] = useState(true)
    const [rotatedFiles, setRotatedFiles] = useState<RotatedFiles>()
    const componentRef = useRef<HTMLDivElement>(null)
    const { width: componentWidth = 0, height: componentHeight = 0 } = useMeasureDirty(componentRef)

    if (!fitToHeight && !fitToWidth) {
      console.error('You have to define fit mode!')
    }

    useEffect(() => {
      setFilePagesCount(files.length)

      if (files.length && !files[activeFilePage]) {
        changeFilePage(activeFilePage > 0 ? Direction.Back : Direction.Next)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files])

    const activeFile = files[activeFilePage]
    const activeFileType = asType || getFileType(activeFile.srcZoom || activeFile.src)
    const isPdf = activeFileType === 'pdf'

    const getAmountOfPages = useCallback(() => {
      if (files.length > 1) {
        return files.length
      }

      return isPdf ? pdfPagesCount : filePagesCount
    }, [filePagesCount, files, pdfPagesCount, isPdf])

    const FileComponent = formatToComponent[activeFileType]
    const FileComponentContainer = withLazyLoad ? LazyPreview : Fragment
    const fileComponentContainerOptions = withLazyLoad ? { options: lazyLoadOptions } : {}
    const pagesAmount = getAmountOfPages()
    const shouldRenderNavigation = withNavigation && pagesAmount > 1 && !isLoading
    const shouldRenderRotateButton = activeFileType === FileType.Image && withRotate
    const shouldRenderActions = withNavigation || withRotate || withModalZoom

    const getActivePageNumber = useCallback(() => {
      if (files.length > 1) {
        return activeFilePage + 1
      }

      return (isPdf ? activePdfPage : activeFilePage) + 1
    }, [activeFilePage, activePdfPage, files, isPdf])

    const shouldHandleFilePage = useCallback(
      (direction: Direction) =>
        activeFileType === FileType.Image ||
        (isLastPdfPage() && direction === Direction.Next) ||
        (isFirstPdfPage() && direction === Direction.Back),
      [activeFileType, isFirstPdfPage, isLastPdfPage],
    )

    const handleNavButtonClick = useCallback(
      (direction: Direction) => {
        if (shouldHandleFilePage(direction)) {
          changeFilePage(direction)
          return
        }

        changePdfPage(direction)
      },
      [changeFilePage, changePdfPage, shouldHandleFilePage],
    )

    const handleCanNavButtonClick = useCallback(
      (direction: Direction) => {
        if (shouldHandleFilePage(direction)) {
          return canChangeFilePage(direction)
        }

        return canChangePdfPage(direction)
      },
      [canChangeFilePage, canChangePdfPage, shouldHandleFilePage],
    )

    const onPdfLoad = useCallback(
      (pagesCount: number) => {
        setPdfPagesCount(pagesCount)

        // Sets correct pdf initial page
        // (on "go back" action we have to set initially the last page)
        if (prevActiveFilePage > activeFilePage) {
          setPdfPage(pdfPagesCount - 1)
        } else {
          setPdfPage(0)
        }
      },
      [activeFilePage, pdfPagesCount, prevActiveFilePage, setPdfPage, setPdfPagesCount],
    )

    const handleLoad = useCallback(() => {
      setLoading(false)
      onLoad?.()
    }, [onLoad])

    const handleDownloadClick = useCallback(() => {
      const src = activeFile.srcDownload || activeFile.srcZoom || activeFile.src
      const url = new URL(src)
      let href = url.href

      // force get the src with different url to omit cached one
      href += url.search ? '&' : '?'

      download(href)
    }, [activeFile])

    const changeRotation = useCallback(
      (src: string, rotation: number) => {
        const value = rotation + ROTATION_STEP

        if (value === ROTATION_FULL && rotatedFiles?.[src]) {
          setRotatedFiles(omit(rotatedFiles, src))
        } else {
          setRotatedFiles({
            ...rotatedFiles,
            [src]: value,
          })
        }
      },
      [rotatedFiles],
    )

    const handleRotateClick = useCallback(() => {
      const { src } = activeFile
      const rotation = rotatedFiles?.[src] || ROTATION_DEFAULT

      changeRotation(src, rotation)
    }, [activeFile, changeRotation, rotatedFiles])

    const handleClick = useCallback(() => {
      if (withModalZoom && onImageZoomClick) {
        onImageZoomClick()
      }
    }, [onImageZoomClick, withModalZoom])

    const handleDeleteClick = useCallback(() => {
      onDelete?.(activeFile)
    }, [activeFile, onDelete])

    const handleFileSelect = useCallback(
      (files: File[]) => {
        onEdit?.(files[0])
      },
      [onEdit],
    )

    if (!files.length || !activeFile) {
      return null
    }

    return (
      <Styled.SectionWrapper
        as="section"
        ref={componentRef}
        key={activeFile.src}
        fitToHeight={fitToHeight}
        bordered={bordered}
        {...rest}
      >
        <Styled.StickyWrapper
          fitToHeight={fitToHeight}
          onClick={handleClick}
          clickable={withModalZoom}
          className={className}
          fitType={fitType}
        >
          {shouldRenderNavigation && (
            <FilesPagination
              activePage={getActivePageNumber()}
              amountOfPages={getAmountOfPages()}
              canClickButton={handleCanNavButtonClick}
              onPageChange={handleNavButtonClick}
              navigationDirection={navigationDirection}
            />
          )}
          <FileComponentContainer {...fileComponentContainerOptions}>
            <FileComponent
              activePage={activePdfPage}
              alt={activeFile.alt}
              circle={circle}
              fitToHeight={fitToHeight}
              fitToWidth={fitToWidth}
              fitType={fitType}
              height={fitToHeight ? Math.round(componentHeight) : 0}
              loading={loading}
              onError={onError}
              onLoad={handleLoad}
              onPdfLoad={onPdfLoad}
              rotation={rotatedFiles?.[activeFile.src]}
              src={activeFile.src}
              srcZoom={activeFile.srcZoom}
              width={fitToWidth ? Math.round(componentWidth) : 0}
              zoomable={zoomable}
              zoomScale={zoomScale}
            />
          </FileComponentContainer>
          {withFilename && !isLoading && <FileName file={activeFile.src} />}
        </Styled.StickyWrapper>

        {shouldRenderActions && (
          <FileActions
            handleDeleteClick={handleDeleteClick}
            handleDownloadClick={handleDownloadClick}
            handleFileSelect={handleFileSelect}
            handleRotateClick={handleRotateClick}
            onImageZoomClick={onImageZoomClick}
            shouldRenderRotateButton={shouldRenderRotateButton}
            withDeleteButton={withDeleteButton}
            withDownloadButton={withDownloadButton}
            withEditButton={withEditButton}
            withModalZoom={withModalZoom}
          />
        )}
      </Styled.SectionWrapper>
    )
  },
  arePropsEqual,
)
