import { Color, DefaultValue, Spacing } from '@design-system'

import { transparentize } from 'polished'

export default {
  modal: {
    header: {
      padding: `${Spacing.XL} ${Spacing.XXL} ${Spacing.L}`,
      textOverflow: 'ellipsis',
    },
    body: {
      display: 'flex',
      padding: `${Spacing.L} ${Spacing.XXL} ${Spacing.XXL}`,
      flexDirection: 'column',
      maxHeight: 'calc(100vh - 200px)',
      position: 'relative',
      overflow: 'auto',
    },
    footer: {
      borderBottomLeftRadius: DefaultValue.BorderRadius,
      borderBottomRightRadius: DefaultValue.BorderRadius,
      padding: `${Spacing.L} ${Spacing.XXL}`,
      justifyContent: 'flex-end',
      backgroundColor: Color.Gray20,

      'button + button': {
        marginLeft: Spacing.S,
      },
    },
    overlay: {
      backgroundColor: transparentize(0.6, Color.Gray200),
    },
  },
}
