import { css } from '@emotion/core'
import React from 'react'
import { Box, Flex } from 'rebass'

type ProgressBarProps = {
  titles: string[]
  activeTitle?: string
}

export const ProgressBar = ({ titles, activeTitle = '' }: ProgressBarProps) => {
  return (
    <Flex
      css={css`
        display: flex;
        position: relative;
        width: 100%;
        text-align: center;
      `}
    >
      {titles.map((title, i) => {
        const isDotActive = titles.indexOf(title) <= titles.indexOf(activeTitle)
        const isBarActive = titles.indexOf(title) < titles.indexOf(activeTitle)
        return (
          <Box
            key={i}
            css={css`
              text-align: center;
              width: ${100 / titles.length}%;
              position: relative;
            `}
          >
            <Box
              css={css`
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background: ${isDotActive ? '#2fc557' : '#fff'};
                border: 2px solid #2fc557;
                margin: 0 auto;
              `}
            />
            {i + 1 !== titles.length && (
              <>
                <Box
                  css={css`
                    height: 5px;
                    background: ${isBarActive ? '#2fc557' : '#daf4e0'};
                    width: 4px;
                    position: absolute;
                    right: 0;
                    top: 2px;
                    width: calc(100% - 10px);
                    transform: translate(50%);
                  `}
                />
              </>
            )}
            <Box>{title}</Box>
          </Box>
        )
      })}
    </Flex>
  )
}
