import React, { ReactElement, ReactNode, useCallback } from 'react'

import { InvoiceState } from '../Invoices'
import * as Styled from './styles'

interface InvoiceItemProps {
  children: ReactNode
  invoiceState: InvoiceState
  onClick?: (invoiceState: InvoiceState) => void
}

export const InvoiceItem = ({ children, invoiceState, onClick }: InvoiceItemProps): ReactElement => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(invoiceState)
    }
  }, [invoiceState, onClick])

  return (
    <Styled.InvoiceItemWrapper clickable={!!onClick} onClick={handleClick}>
      {children}
    </Styled.InvoiceItemWrapper>
  )
}
