import { TableBadgeLabel } from '@components'

import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTransactions } from '../../hooks/useTransactions'

export const TransactionsTitle = (): ReactElement => {
  const { t } = useTranslation()
  // @todo: remove local loading state, temporary solution for not showing loading state every time
  const [isLoading, setIsLoading] = useState(true)
  const { pagination, isLoading: isTransactionsLoading } = useTransactions()

  useEffect(() => {
    if (!isTransactionsLoading && isLoading) {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransactionsLoading])

  return (
    <TableBadgeLabel amount={pagination?.total} isLoading={isLoading} variant="neutral">
      {t('transactions')}
    </TableBadgeLabel>
  )
}
