import { ChangeEvent, useCallback } from 'react'

import { useBillReconciliation } from '../../../../contexts/billReconciliationContext'
import { useMultipleBills } from '../../../../contexts/multipleBillsContext'
import { ScrollableList } from '../../../ScrollableList'
import { BillsListItemContent } from './elements/BillsListItemContent'

export const BillsList = () => {
  const { initialBill } = useBillReconciliation()
  const { bills, saveSelectedBillAndBillId, selectedBillsIds } = useMultipleBills()

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      saveSelectedBillAndBillId({ currentBillId: event.currentTarget.value, isSelected: event.currentTarget.checked })
    },
    [saveSelectedBillAndBillId],
  )

  return (
    <ScrollableList onChange={handleCheckboxChange} value={selectedBillsIds}>
      {bills?.map((bill) => {
        const billId = bill?.id
        const isInitialBill = billId === initialBill?.id
        const isSelected = selectedBillsIds.includes(billId)

        return (
          <ScrollableList.Item id={billId} key={billId} disabled={isInitialBill} highlighted={isSelected}>
            <BillsListItemContent bill={bill} />
          </ScrollableList.Item>
        )
      })}
    </ScrollableList>
  )
}
