export default {
  center: {
    size: 'minmax(100px, 1fr)',
    justifyContent: 'center',
  },
  currency: {
    size: 'minmax(100px, 2fr)',
    justifyContent: 'flex-end',
  },
  date: {
    size: 'minmax(100px, 1fr)',
    justifyContent: 'flex-start',
  },
  icon: {
    size: 'minmax(20px, 40px)',
    justifyContent: 'center',
  },
  number: {
    size: 'minmax(120px, 2fr)',
    justifyContent: 'flex-end',
  },
  text: {
    size: 'minmax(80px, 3fr)',
    justifyContent: 'flex-start',
  },
  custom: {
    size: 'minmax(200px, 4fr)',
    justifyContent: 'flex-start',
  },
  thumbnail: {
    size: '47px',
    justifyContent: 'flex-start',
  },
  label: {
    size: 'minmax(50px, 100px)',
    justifyContent: 'flex-start',
  },
}
