import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { BORDER_SIZE } from '../../constants/borderSize'
import { InputSize } from '../../types/size'
import { sizeToProps } from '../../utils/getSizeStyle'

interface InputPrefixWrapperProps {
  disabled: boolean
  inputSize: InputSize
}

const getCompensedPaddingStyles = (inputSize: InputSize) => {
  const { yPadding, xPadding } = sizeToProps[inputSize]

  return css`
    margin: -${yPadding} -${xPadding};
    padding: 0 ${xPadding};
  `
}

export const InputPrefixSuffixWrapper = styled.div<InputPrefixWrapperProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: flex;
  height: ${({ inputSize }) => sizeToProps[inputSize].height - 2 * BORDER_SIZE}px;
  align-items: center;
  flex-shrink: 0;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  ${({ inputSize }) => getCompensedPaddingStyles(inputSize)}
`
