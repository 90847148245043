import { Button, Space, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { PeriodMode } from '../../../../../enums/periodMode'
import { PeriodValue } from '../../../../../types/periodValue'
import { usePeriodSelectContext } from '../../contexts/periodSelectContext'
import { CustomDate } from '../CustomDate'
import { PeriodModeToggler } from '../PeriodModeToggler'
import { PeriodsList } from '../PeriodsList'
import * as Styled from './styles'

interface PeriodDropdownProps {
  onSave?: (periodValue?: PeriodValue) => void
}

export const PeriodDropdown = ({ onSave }: PeriodDropdownProps): ReactElement => {
  const { setPeriodValueSaved, setPeriodValue, periodValue, periodMode, isCustomDate } = usePeriodSelectContext()
  const { t } = useTranslation()

  const isButtonEnabled = !!periodValue || periodMode === PeriodMode.All

  const handleSaveClick = useCallback(() => {
    let periodValueToSave = periodValue

    if (periodMode === PeriodMode.All && !isCustomDate) {
      periodValueToSave = { mode: PeriodMode.All }
    }

    setPeriodValue(periodValueToSave)
    setPeriodValueSaved(periodValueToSave)
    onSave?.(periodValueToSave)
  }, [isCustomDate, onSave, periodMode, periodValue, setPeriodValue, setPeriodValueSaved])

  return (
    <Styled.PeriodDropdownWrapper>
      <Styled.ScrollableWrapper>
        {/* Period Mode Toggler: */}
        <PeriodModeToggler />
        <Space size="l" />

        {/* Periods navigation and list of items:  */}
        <PeriodsList />
        <Space size="l" />

        {/* Custom Date: */}
        <Text colorVariant="secondary">{t('period_select.mode.custom_date')}</Text>
        <Space size="xxxs" />
        <CustomDate />
      </Styled.ScrollableWrapper>

      <Styled.FooterWrapper>
        {/* Save Button: */}
        <Button disabled={!isButtonEnabled} size="m" onClick={handleSaveClick} fullWidth>
          {t('period_select.save_button')}
        </Button>
      </Styled.FooterWrapper>
    </Styled.PeriodDropdownWrapper>
  )
}
