import { Paging } from '../../../../../types/metable'
import { Transaction } from '../../../types/transaction'
import { useFetchTransactions } from './useFetchTransactions'
import { useTransactionsListFilters } from './useTransactionsListFilters'

interface UseTransactionsResponse {
  transactions: Transaction[]
  isLoading: boolean
  pagination?: Paging
}

export const useTransactions = (): UseTransactionsResponse => {
  const [queryProps] = useTransactionsListFilters()
  const { transactions, isLoading, pagination } = useFetchTransactions(queryProps)

  return {
    transactions,
    isLoading,
    pagination,
  }
}
