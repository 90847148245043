import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { getShadow } from '../../../utils/getShadow'

export const getFocusStyle = (error?: boolean): SerializedStyles => css`
  ${error
    ? css`
        border-color: ${Color.Red};
        ${getShadow(undefined, {
          extraShadowType: 'raisedLightInner',
          outlineColor: Color.Red10,
        })}
      `
    : css`
        border-color: ${Color.Green};
        ${getShadow(undefined, {
          extraShadowType: 'raisedLightInner',
          outlineColor: Color.Green10,
        })}
      `}
`
