import { useCallback, useMemo } from 'react'
import { DropEvent, DropzoneOptions, FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { LimitOptions } from '../types/limitOptions'
import { translateRejectedFilesErrors } from '../utils/translateRejectedFilesErrors'

interface UseTranslateRejectedFilesErrorsProps {
  accept: DropzoneOptions['accept']
  maxFiles: DropzoneOptions['maxFiles']
  maxSize: DropzoneOptions['maxSize']
  minSize: DropzoneOptions['minSize']
  onDrop: DropzoneOptions['onDrop']
  onDropRejected: DropzoneOptions['onDropRejected']
}

export const useTranslateRejectedFilesErrors = ({
  onDrop,
  onDropRejected,
  maxFiles,
  maxSize,
  minSize,
  accept,
}: UseTranslateRejectedFilesErrorsProps) => {
  const { t } = useTranslation()

  const limitOptions: LimitOptions = useMemo(
    () => ({
      maxFiles,
      maxSize,
      minSize,
      accept,
    }),
    [accept, maxFiles, maxSize, minSize],
  )

  const handleDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => {
      translateRejectedFilesErrors(rejectedFiles, limitOptions, t)
      onDrop?.(acceptedFiles, rejectedFiles, event)
    },
    [limitOptions, onDrop, t],
  )

  const handleDropRejected = useCallback(
    (rejectedFiles: FileRejection[], event: DropEvent) => {
      translateRejectedFilesErrors(rejectedFiles, limitOptions, t)
      onDropRejected?.(rejectedFiles, event)
    },
    [limitOptions, onDropRejected, t],
  )

  return {
    handleDrop,
    handleDropRejected,
  }
}
