import { TFunction } from 'i18next'
import { mixed, object, SchemaOf, string } from 'yup'

import { InvoiceIdentifierType } from '../../../types/invoiceIdentifierType'

export const sendEInvoiceFormDefaultValues: EInvoiceForm = {
  identifierType: 'gln',
  gln: undefined,
  cvr: undefined,
  orderReference: '',
}

export interface EInvoiceForm {
  identifierType: InvoiceIdentifierType
  cvr: string | undefined
  gln: string | undefined
  orderReference: string | undefined
}

export type SendEInvoiceFormSchema = SchemaOf<EInvoiceForm>

export const getEInvoiceValidationSchema = (t: TFunction): SendEInvoiceFormSchema => {
  return object({
    identifierType: mixed<InvoiceIdentifierType>().oneOf(['cvr', 'gln']).required(),
    gln: string(),
    cvr: string(),
    orderReference: string().optional(),
  }).required()
}
