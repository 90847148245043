import { SelectProps } from '@design-system'

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../../../../../../../../../enums/queryKeys'
import { SortDirection } from '../../../../../../../../../../../enums/sortDirection'
import { fetchAdditionalFields, TaxMetaField } from '../../../../../../../../VatReturnSettings'
import { AdditionalFieldsSortProperty } from '../../../../../../../../VatReturnSettings/enums/additionalFieldsSortProperty'
import { AdditionalFieldSelect } from '../../../../../shared'
import { useCreateOrEditVatRateFromTemplateModal } from '../../../../context/createOrEditVatRateFromTemplateModalContext'

interface AdditionalFieldSelectWrapperProps
  extends Omit<SelectProps<TaxMetaField>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: TaxMetaField) => void
}

export const AdditionalFieldSelectWrapper = ({ selectedId, ...props }: AdditionalFieldSelectWrapperProps) => {
  const { template } = useCreateOrEditVatRateFromTemplateModal()
  const { organization } = useUserOrganization()

  const { data: additionalFields } = useQuery(
    [QueryKeys.AdditionalFields, organization?.id, AdditionalFieldsSortProperty.Priority, SortDirection.Asc],
    () =>
      fetchAdditionalFields({
        organizationId: organization?.id as string,
        sortProperty: AdditionalFieldsSortProperty.Priority,
        sortDirection: SortDirection.Asc,
      }),
    {
      enabled: !!organization?.id,
    },
  )

  const abbreviation = template?.taxRateTemplate.taxSummaryLine?.abbreviation

  const field = useMemo(() => {
    if (abbreviation) {
      return additionalFields?.salesTaxMetaFields.find((item) => item.predefinedTag === abbreviation)
    }

    return undefined
  }, [abbreviation, additionalFields?.salesTaxMetaFields])

  return <AdditionalFieldSelect {...props} selectedId={abbreviation ? field?.id : selectedId} />
}
