import { FormLabel, Input, SelectItem } from '@components-deprecated'

import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, BoxProps, Flex } from 'rebass'

import { Address, City, ZipCode } from '../types'
import { CitySelector } from './CitySelector'
import { ZipCodeSelector } from './ZipCodeSelector'

export type AddressSelectorComponentProps = BoxProps &
  AddressErrorPathsProps & {
    name?: string
    formControl?: Control<any>
    countryId?: string
    address?: Address
    onUpdate?: (address: Address) => void
    disconnect?: boolean
  }

type AddressErrorPathsProps = {
  errorPaths?: {
    zipcode?: string
    city?: string
    street?: string
    state?: string
  }
}

const createEmptyAddress = (): Address => ({
  cityId: null,
  cityText: '',
  stateId: null,
  stateText: '',
  zipcodeId: null,
  zipcodeText: '',
  street: '',
})

const AddressSelectorComponent = ({
  countryId,
  onUpdate,
  address = createEmptyAddress() as Address,
  ...rest
}: AddressSelectorComponentProps) => {
  switch (countryId?.toLowerCase()) {
    case 'dk':
      return <AddressSelectorDK address={address} onUpdate={onUpdate} {...rest} />
    default:
      return <AddressSelectorNonDK address={address} onUpdate={onUpdate} {...rest} />
  }
}

type CountryAddressSelectorProps = BoxProps &
  AddressErrorPathsProps & {
    onUpdate?: (address: Address) => void
    formControl?: Control<any>
    disconnect?: boolean
    address: Address
  }

export const AddressSelectorNonDK = ({
  onUpdate = () => null,
  address,
  formControl,
  disconnect,
  name = '',
  errorPaths,
  ...rest
}: CountryAddressSelectorProps) => {
  const { t } = useTranslation()

  const handleUpdate = (fieldName: string, value: string) => {
    onUpdate({
      ...address,
      [fieldName]: value,
    })
  }

  return (
    <Box variant="forms.addressgroup" {...rest}>
      <Input
        name="street"
        errorPath={errorPaths?.street}
        value={address.street}
        placeholder={t('app.address.address_selector.street_placeholder')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdate('street', e.target.value)}
        disconnect={!!formControl || disconnect}
      />
      <Flex flex="1 auto" mt="-1px">
        <Input
          name="zipcodeText"
          errorPath={errorPaths?.zipcode}
          value={address.zipcodeText}
          placeholder={t('app.address.address_selector.zip_code_placeholder')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdate('zipcodeText', e.target.value)}
          width="100px"
          disconnect={!!formControl || disconnect}
        />
        <Input
          name="cityText"
          errorPath={errorPaths?.city}
          value={address.cityText}
          placeholder={t('app.address.address_selector.city_placeholder')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdate('cityText', e.target.value)}
          disconnect={!!formControl || disconnect}
        />
      </Flex>
      <Input
        name="stateText"
        errorPath={errorPaths?.state}
        value={address.stateText}
        placeholder={t('app.address.address_selector.state_placeholder')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdate('stateText', e.target.value)}
        mt="-1px"
        disconnect={!!formControl || disconnect}
      />
    </Box>
  )
}

export const AddressSelectorDK = ({
  onUpdate = () => null,
  address,
  disconnect,
  name,
  formControl,
  errorPaths,
  ...rest
}: CountryAddressSelectorProps) => {
  const { t } = useTranslation()

  const handleCitySelect = (city: City) => {
    onUpdate({
      ...address,
      cityText: city.name,
      cityId: city.id,
    })
  }

  const handleZipCodeSelect = (zipCode: ZipCode) => {
    onUpdate({
      ...address,
      zipcodeId: zipCode.id,
      zipcodeText: zipCode.zipcode,
      cityId: zipCode.city.id,
      cityText: zipCode.city.name,
    })
  }

  const handleStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({
      ...address,
      street: e.target.value,
    })
  }

  const zipCode: SelectItem & ZipCode = {
    id: address?.zipcodeId || '',
    text: address?.zipcodeText,
    zipcode: address?.zipcodeId || '',
    cityId: '',
    city: {
      id: '',
      name: '',
    },
  }

  const city: SelectItem & City = {
    id: address.cityId || '',
    name: address.cityText,
    text: address.cityText,
  }

  return (
    <Box variant="forms.addressgroup" {...rest}>
      <FormLabel>{t('app.address.address_selector.address')}</FormLabel>
      <Input
        name="street"
        value={address.street}
        placeholder={t('app.address.address_selector.street_placeholder')}
        onChange={handleStreetChange}
        disconnect={disconnect}
        errorPath={errorPaths?.street}
      />
      <Flex>
        <ZipCodeSelector
          countryId="dk"
          name="zipcodeText"
          errorPath={errorPaths?.zipcode}
          selectedItem={zipCode}
          onTextChange={(zipcodeText: string) => onUpdate({ ...address, zipcodeId: null, zipcodeText })}
          onItemSelect={(item: object) => handleZipCodeSelect(item as ZipCode)}
          disconnect={!!formControl || disconnect}
        />
        <CitySelector
          countryId="dk"
          name="cityText"
          errorPath={errorPaths?.city}
          selectedItem={city}
          onTextChange={(cityText: string) => onUpdate({ ...address, cityId: null, cityText })}
          onItemSelect={(item: object) => handleCitySelect(item as City)}
          disconnect={!!formControl || disconnect}
        />
      </Flex>
    </Box>
  )
}

// Controlled
export type AddressSelectorControlledResult = Address

export type AddressSelectorProps = AddressSelectorComponentProps

export const AddressSelector = ({ formControl, name = '', ...rest }: AddressSelectorProps) => {
  if (!formControl) {
    return <AddressSelectorComponent name={name} {...rest} />
  }

  return (
    <Controller
      render={({ field }) => (
        <AddressSelectorComponent
          {...rest}
          formControl={formControl}
          name={name}
          address={field.value}
          onUpdate={field.onChange}
          errorPaths={{
            zipcode: `${field.name}.zipcodeText`,
            city: `${field.name}.cityText`,
            street: `${field.name}.street`,
            state: `${field.name}.stateText`,
          }}
          disconnect
        />
      )}
      control={formControl}
      name={name}
    />
  )
}
