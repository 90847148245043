import { Color, Icon, Text } from '@design-system'

import React, { ReactElement } from 'react'

import { CheckItem } from '../types/checkItem'
import * as Styled from './styles'

interface PasswordCheckItemProps {
  item: CheckItem
  isChecked?: boolean
}

export const PasswordCheckItem = ({ item, isChecked }: PasswordCheckItemProps): ReactElement => {
  const { description } = item

  return (
    <Styled.PasswordChecksItem>
      <Styled.IconWrapper>
        <Icon icon={isChecked ? 'check' : 'xSign'} color={isChecked ? Color.Green : Color.Gray90} />
      </Styled.IconWrapper>

      <Text colorVariant="secondary">{description}</Text>
    </Styled.PasswordChecksItem>
  )
}
