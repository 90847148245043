import React, { ReactElement } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { AccountStatements } from './elements/AccountStatements'
import { defaultQuery } from './elements/AccountStatements/hooks/useAccountStatementsFilters'
import { getAccountViewPath } from './utils/getAccountViewPath'

export const Account = (): ReactElement => {
  const emberQuery = useGetQueryFromEmberRoute({
    queryParamsValidKeys: Object.keys(defaultQuery),
  })

  return (
    <Switch>
      <Route exact path="/">
        {typeof emberQuery?.initialRoute === 'string' && <Redirect to={emberQuery.initialRoute} />}
      </Route>
      <Route exact path={getAccountViewPath(':accountId')} component={AccountStatements} />
    </Switch>
  )
}
