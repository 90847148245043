import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const Title = styled(Text)`
  margin-bottom: ${Spacing.XL};
`

export const SubTitle = styled(Text)`
  margin-bottom: ${Spacing.XL};
`

export const BoldSection = styled(Text)`
  margin-bottom: ${Spacing.L};
`
const AUTO_MARGIN = 'auto'

export const AppOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Spacing.XXL};
  max-width: 350px;
  margin-left: ${AUTO_MARGIN};
  margin-right: ${AUTO_MARGIN};
`

export const AppIllustration = styled.img`
  height: 40px;
`
