import { useUmbrellaMembers } from '../../../../hooks/useUmbrellaMembers'
import { UmbrellaMember } from '../../../../query-api/umbrella-members-query'
import { UmbrellaOrganization } from '../../organizations/types'
import { useCurrentUser } from '../../user/currentUserContext'
import { useUmbrella } from '../umbrellaContext'

interface UseOwnershipMembersProps {
  umbrellaOrganization?: UmbrellaOrganization
}

export const useUmbrellaOwners = ({ umbrellaOrganization }: UseOwnershipMembersProps = {}) => {
  const { umbrella } = useUmbrella()
  const { user } = useCurrentUser()

  const { umbrellaMembers, isLoading } = useUmbrellaMembers(umbrella?.id || '', {
    isGrantAdmin: true,
  })

  const organizationMembersWithoutCurrentOwner: UmbrellaMember[] = umbrellaMembers
    ? umbrellaMembers?.filter((umbrellaMember) => umbrellaMember.userId !== user?.id)
    : []

  return {
    umbrellaOwners: organizationMembersWithoutCurrentOwner,
    isLoading,
  }
}
