import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { Quotes } from '@views/quotes'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { GlobalFiscalYearContextProvider } from '../../contexts/globalFiscalYearContext'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'quotes-module'

class QuotesWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
}

interface QuotesModuleProps {
  organizationId: string
  onNavigate: EmberNavigate
}

const QuotesModule = ({ onNavigate, organizationId }: QuotesModuleProps): ReactElement => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <HashRouter>
        <EmberRouterContextProvider onNavigate={onNavigate}>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <GlobalFiscalYearContextProvider organizationId={organizationId}>
              <CurrentUserContextProvider>
                <ModalContextProvider>
                  <Quotes />
                </ModalContextProvider>
              </CurrentUserContextProvider>
            </GlobalFiscalYearContextProvider>
          </UserOrganizationContextProvider>
        </EmberRouterContextProvider>
      </HashRouter>
    </ThemeProvider>
  </QueryClientProvider>
)

const QuotesCustomElement = createCustomElement(QuotesModule, QuotesWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, QuotesCustomElement)

export default QuotesCustomElement
