import { useMemo } from 'react'

import { userSettingsRoutes } from '../constants/userSettingsRoutes'
import { SettingsRoute } from '../enums/settingsRoute'

export const useIsUserSettingsRoute = (currentRouteName: SettingsRoute) => {
  const isUserRoute = useMemo(() => userSettingsRoutes.includes(currentRouteName), [currentRouteName])

  return isUserRoute
}
