import { Divider, Space } from '@design-system'

import { ReactElement, useEffect } from 'react'

import { useSegment } from '../../../../../../hooks'
import { useBankLines } from '../../contexts/bankLinesContext'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { BillAccountSelector } from '../BillAccountSelector'
import { BillSummary } from '../BillSummary'
import { DifferenceSelector } from '../DifferenceSelector'
import { BankLines } from './elements/BankLines'
import { BankLinesActions } from './elements/BankLinesActions'
import { LinkToManualPayment } from './elements/LinkToManualPayment'

export const BankLinesModalBody = (): ReactElement => {
  const { track } = useSegment()
  const { setPaymentDate } = useBillReconciliation()
  const { bankLinesDifference, hasBankLinesDifference, newestBankLineDate } = useBankLines()

  useEffect(() => {
    track('xxx - expenses - Bank line modal opened')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPaymentDate(newestBankLineDate || null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newestBankLineDate])

  return (
    <>
      <BillSummary />
      <Space />
      <Divider />
      <Space />
      <BillAccountSelector />
      <Space />
      <BankLinesActions />
      <BankLines />
      {hasBankLinesDifference && <DifferenceSelector difference={bankLinesDifference} />}
      <LinkToManualPayment />
    </>
  )
}
