import { Button, Modal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface ModalFooterProps {
  isButtonDisabled?: boolean
  onSubmit?: () => void
}

export const ModalFooter = ({ isButtonDisabled, onSubmit }: ModalFooterProps): ReactElement => {
  const { t } = useTranslation()

  const handleSubmitButtonClick = useCallback(() => {
    onSubmit?.()
  }, [onSubmit])

  return (
    <Modal.Footer>
      <Button type="button" onClick={handleSubmitButtonClick} disabled={isButtonDisabled}>
        {t('transfer_ownership_modal.submit')}
      </Button>
    </Modal.Footer>
  )
}
