import React, { ReactElement, ReactNode } from 'react'
import { Cell, Pie, PieChart } from 'recharts'

import { Color } from '../../enums/color'
import { SkeletonCircle } from '../SkeletonCircle'
import * as Styled from './styles'

const ANIMATION_TIME_MS = 750
const DEFAULT_SIZE = 160
const THICKNESS = 10

export interface DonutChartData {
  color: Color
  value: number
}

export interface DonutChartProps {
  children?: ReactNode
  isLoading?: boolean
  data: DonutChartData[]
  size?: number
}

export const DonutChart = ({ data, children, isLoading, size = DEFAULT_SIZE }: DonutChartProps): ReactElement => {
  const centerPoint = size / 2 - THICKNESS / 2

  return (
    <>
      {isLoading ? (
        <SkeletonCircle size={size} />
      ) : (
        <Styled.Wrapper>
          {children && <Styled.Content>{children}</Styled.Content>}
          <PieChart width={size} height={size}>
            <Pie
              data={data}
              cx={centerPoint}
              cy={centerPoint}
              innerRadius={size / 2 - THICKNESS}
              outerRadius={size / 2}
              animationDuration={ANIMATION_TIME_MS}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </Styled.Wrapper>
      )}
    </>
  )
}
