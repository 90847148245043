import { NavItem } from '@design-system'

import { ContactPerson } from '@views/contacts/types/contactPerson'

const getItem = (contactPerson: ContactPerson): NavItem<ContactPerson> => ({
  id: contactPerson.id,
  children: `${contactPerson.name} <${contactPerson.email}>`,
  value: contactPerson,
})

export const getItems = (contactPersons: ContactPerson[]): NavItem<ContactPerson>[] => {
  return contactPersons.map(getItem)
}
