import { PaymentMethod } from '../../../../../query-api/payment-methods-query'
import { PaymentMethodDefaultName } from '../../../enums/paymentMethodDefaultName'
import { PaymentMethodType } from '../../../enums/paymentMethodType'

export const getMobilePayPaymentMethodPayload = (mobilePayAccountId: string): Partial<PaymentMethod> => ({
  bankAccountId: mobilePayAccountId,
  isDefault: true,
  name: PaymentMethodDefaultName.MobilePay,
  type: PaymentMethodType.MobilePay,
})
