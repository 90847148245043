import React, { ReactElement } from 'react'

import { Size as ButtonSize } from '../Button'
import { IconButtonProps } from '../IconButton'
import { SkeletonBox } from '../SkeletonBox'

const sizeToDimensions: Record<ButtonSize, number> = {
  m: 32,
  l: 40,
  xl: 48,
  xxl: 56,
}

export type SkeletonIconButtonProps = Pick<IconButtonProps, 'size'>

export const SkeletonIconButton = ({ size = 'l' }: SkeletonIconButtonProps): ReactElement => {
  const boxSize = sizeToDimensions[size]

  return <SkeletonBox height={boxSize} width={boxSize} />
}
