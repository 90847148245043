import { NavItem } from '@design-system'

export const getItems = (maxPeriods: number): NavItem<number>[] => {
  const items: NavItem<number>[] = []

  for (let i = 0; i <= maxPeriods; i++) {
    items.push({ id: i.toString(), children: i.toString(), value: i })
  }

  return items
}
