import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { StyledComponent } from '../../../types/styledComponent'

export const getDangerStyle = (Control: StyledComponent, checked: boolean): SerializedStyles => css`
  ${Control} {
    ${checked
      ? css`
          background-color: ${Color.Red};
        `
      : css`
          background-color: ${Color.Gray50};
        `};
  }
  &:hover {
    ${Control} {
      ${checked
        ? css`
            background-color: ${Color.Red};
          `
        : css`
            background-color: ${Color.Gray50};
          `};
    }
  }
`
