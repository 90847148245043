import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '../../../Icon'
import * as Styled from './styles'

interface TableRowExpanderProps {
  expanded: boolean
  isSelectable?: boolean
}

export const TableRowExpander = ({ expanded, isSelectable = false }: TableRowExpanderProps): ReactElement => {
  const { t } = useTranslation()

  const label = expanded ? t('table.expander_button.collapse') : t('table.expander_button.expand')

  return (
    <Styled.TableRowExpanderWrapper aria-label={label} selectable={isSelectable} title={label} data-chromatic="ignore">
      <Icon icon={expanded ? 'chevronDown' : 'chevronRight'} />
    </Styled.TableRowExpanderWrapper>
  )
}
