import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'
import { Text } from '../../../Text'

export const TableItemsAmountSelectWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const TableItemsAmountSelectLabel = styled(Text)`
  margin-left: ${Spacing.S};
  flex-shrink: 0;
`

export const SelectWrapper = styled.div`
  max-width: 74px;
`
