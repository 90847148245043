import { Button, ComponentsGroup, Modal, Space, Spacing, Text } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TrackingContext } from '../../../../../enums/trackingContext'
import { getImageClassName } from '../../../../../utils/getClassName'
import { getImgPath } from '../../../../../utils/getImgPath'
import { SimpleCheckList } from '../../../../SimpleCheckList'
import { modalFeaturesListLeft, modalFeaturesListRight } from '../../constants/featuresLists'
import { getModalContent } from '../../utils/getModalContent'
import * as Styled from './styles'

interface OwnerModalProps {
  modalId: string
  trackingContext: TrackingContext
  forceRender?: boolean
  onUpgradeSubscriptionPlan: () => void
}

export const OwnerModal = ({
  modalId,
  trackingContext,
  forceRender,
  onUpgradeSubscriptionPlan,
}: OwnerModalProps): ReactElement => {
  const { t } = useTranslation()

  const modalContent = useMemo(() => getModalContent(trackingContext, t), [t, trackingContext])

  const handleUpgradeCtaClick = useCallback(() => {
    onUpgradeSubscriptionPlan()
  }, [onUpgradeSubscriptionPlan])

  return (
    <Modal id={modalId} forceRender={forceRender} closable>
      <Modal.Header />
      <Modal.Body>
        <Styled.ModalBodyWrapper>
          <img
            src={getImgPath('/modals/key-and-lock.svg')}
            className={getImageClassName()}
            width={175}
            height={156}
            alt="Key and lock"
          />
          <>
            <Space size="xxl" />
            <Text variant="h1" alignment="center">
              {modalContent?.heading || t('premium_feature.feature.general.heading')}
            </Text>
            <Space size="l" />
            <Text variant="large" alignment="center">
              {modalContent?.body || t('premium_feature.feature.general.body')}
            </Text>
          </>
          <Space size="xxxl" />
          <Text variant="h3">{t('premium_feature.features_heading')}</Text>
          <Space size="xl" />
          <ComponentsGroup direction="row" spacing={Spacing.XL} centered={false}>
            <Styled.SimpleChecklistWrapper>
              <SimpleCheckList items={modalFeaturesListLeft} alignment="left" />
            </Styled.SimpleChecklistWrapper>
            <Styled.SimpleChecklistWrapper>
              <SimpleCheckList items={modalFeaturesListRight} alignment="left" />
            </Styled.SimpleChecklistWrapper>
          </ComponentsGroup>
        </Styled.ModalBodyWrapper>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleUpgradeCtaClick}>{t('premium_feature.choose_your_plan_cta')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
