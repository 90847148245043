import { SkeletonBox, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SkeletonWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
`

export const SkeletonChart = styled(SkeletonBox)`
  width: 100%;
  height: calc(100% - 2 * ${Spacing.L} - ${Spacing.S});
  margin-bottom: ${Spacing.S};
`

export const SkeletonXAxis = styled(SkeletonBox)`
  width: 100%;
  height: ${Spacing.L};
  margin-top: auto;
  margin-bottom: ${Spacing.L};
  flex-shrink: 0;
`

export const BarChartStoryWrapper = styled.div`
  height: 100vh;
`
