import { BorderRadius, Color, DefaultValue, Spacing, Tag as TagComponent } from '@design-system'

import styled from '@emotion/styled'

export const BookkeepingTagSuggestion = styled.div`
  display: flex;
  padding: ${Spacing.M} ${Spacing.L};
  border-radius: ${BorderRadius.L};
  align-items: center;
  background-color: ${Color.Gray20};
`

export const ImageWrapper = styled.figure`
  margin-right: ${Spacing.L};
`

export const BillyFaceImage = styled.img`
  width: 18px;
  height: 20px;
`

export const ContentWrapper = styled.div``

export const TagsList = styled.div`
  margin-top: ${Spacing.XXS};
`

export const Tag = styled(TagComponent)`
  margin-right: ${Spacing.S};
  border-radius: ${DefaultValue.BorderRadius};
`

Tag.defaultProps = {
  closable: false,
  clickable: true,
}
