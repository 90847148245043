import BigNumber from 'bignumber.js'
import { isPast } from 'date-fns'

import { InvoiceState } from '../../../enums/invoiceState'
import { InvoiceStatus } from '../../../enums/invoiceStatus'

interface InvoiceStateToStatusOptions {
  amount?: number | null
  balance?: number | null
  dueDate?: Date | null
  isPaid?: boolean
  state?: InvoiceState | null
  tax?: number | null
}

export const getInvoiceStatus = ({
  amount,
  balance,
  dueDate,
  isPaid,
  state,
  tax,
}: InvoiceStateToStatusOptions): InvoiceStatus => {
  if (state !== InvoiceState.Approved) {
    return InvoiceStatus.Draft
  }

  if (isPaid) {
    return InvoiceStatus.Paid
  }

  const diffGrossAmountWithBalance = new BigNumber(amount || 0).plus(tax || 0).minus(balance || 0)
  const isPartiallyPaid = diffGrossAmountWithBalance.isGreaterThan(0)

  if (isPartiallyPaid) {
    return InvoiceStatus.PartiallyPaid
  }

  if (dueDate && isPast(dueDate)) {
    return InvoiceStatus.Overdue
  }

  return InvoiceStatus.Unpaid
}
