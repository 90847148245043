import { amountToDisplayValueSplitted } from '@design-system'

import React, { ChangeEvent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface RangeSliderProps {
  label: string
  min: number
  max: number
  unit: string
  step: number
  onChange: (amount: number) => void
}

export const RangeSlider = ({ label, min, max, unit, step, onChange }: RangeSliderProps): ReactElement => {
  const { t } = useTranslation()

  const [selectedValue, setSelectedValue] = useState(Math.ceil(max / 2))

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(parseInt(e.target.value))
    onChange(parseInt(e.target.value))
  }

  return (
    <>
      <Styled.InfoContainer>
        <Styled.Label>{label}</Styled.Label>
        <Styled.SelectDisplayer>{`${
          amountToDisplayValueSplitted(selectedValue).value
        } ${unit}`}</Styled.SelectDisplayer>
      </Styled.InfoContainer>

      <Styled.RangeSliderContainer>
        <Styled.RangeSlider
          type="range"
          min={min}
          max={max}
          step={step}
          value={selectedValue}
          onChange={handleChange}
          progress={((selectedValue - min) * 100) / (max - min) + '% 100%'}
        />
      </Styled.RangeSliderContainer>

      <Styled.InfoContainer>
        <Styled.Min>{`${t('min')} ${amountToDisplayValueSplitted(min).value} ${unit}`}</Styled.Min>
        <Styled.Max>{`${t('max')} ${amountToDisplayValueSplitted(max).value} ${unit}`}</Styled.Max>
      </Styled.InfoContainer>
    </>
  )
}
