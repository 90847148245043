import { Button, ButtonDropdown, NavItem, notify } from '@design-system'

import noop from 'lodash/noop'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { QueryKeys } from '../../../enums/queryKeys'
import { QueryParamKeys } from '../../../enums/queryParamKeys'
import { useSetQueryParams } from '../../../hooks/useSetQueryParams'
import { useUserOrganization } from '../../app/organization'
import { organizationRequested } from '../../app/organization/action-creators'
import { voucherActionRequest, voucherDelete, voucherDiscard, voucherRequestMoreInfo } from '../action-creators'
import { useVoucherActionState } from '../hooks/useVoucherActionState'
import { duplicateVoucher } from '../services/api'
import { updateVoucher } from '../services/query-api'
import { InboxRoutes, Voucher, VoucherAction, VoucherInboxState, VoucherUpdatePayloadData } from '../types'
import { getVoucherInboxPath } from '../utils'
import {
  DeleteModal,
  DiscardModal,
  DiscardReasonItem,
  MoreInfoModal,
  MoreInfoReasonItem,
  PostponeModal,
} from './modals'

export enum MoreActionsModal {
  Delete = 'delete',
  Discard = 'discard',
  MoreInfo = 'moreInfo',
  Postpone = 'postpone',
}

enum VoucherMoreActions {
  SetAsReceived = 'receive',
  Discard = 'discard',
  Delete = 'delete',
  RequireInfo = 'requireInfo',
  Archive = 'archive',
  Duplicate = 'duplicate',
  GoToBill = 'goToBill',
  GoToTransaction = 'goToTransaction',
  Postpone = 'postpone',
}

// An set of events to be fired after a given action is performed
type NextActions = Record<VoucherMoreActions, (voucherId?: string) => void>

// A set of actions available for voucher in a given state
type VoucherStateActions = Record<
  Exclude<VoucherInboxState, VoucherInboxState.VOIDED | VoucherInboxState.DELETED | VoucherInboxState.DUPLICATED>,
  VoucherMoreActions[]
>

const partnerActions: VoucherStateActions = {
  [VoucherInboxState.ARCHIVED]: [
    VoucherMoreActions.SetAsReceived,
    VoucherMoreActions.Discard,
    VoucherMoreActions.Delete,
  ],
  [VoucherInboxState.DISCARDED]: [VoucherMoreActions.SetAsReceived, VoucherMoreActions.Delete],
  [VoucherInboxState.RECEIVED]: [
    VoucherMoreActions.RequireInfo,
    VoucherMoreActions.Archive,
    VoucherMoreActions.Duplicate,
    VoucherMoreActions.Discard,
    VoucherMoreActions.Delete,
    VoucherMoreActions.Postpone,
  ],
  [VoucherInboxState.RECORDED]: [
    VoucherMoreActions.Duplicate,
    VoucherMoreActions.GoToBill,
    VoucherMoreActions.GoToTransaction,
  ],
  [VoucherInboxState.NEED_INFO]: [
    VoucherMoreActions.Archive,
    VoucherMoreActions.Duplicate,
    VoucherMoreActions.SetAsReceived,
    VoucherMoreActions.Discard,
    VoucherMoreActions.Delete,
  ],
}

const customerActions: VoucherStateActions = {
  [VoucherInboxState.ARCHIVED]: [VoucherMoreActions.SetAsReceived, VoucherMoreActions.Delete],
  [VoucherInboxState.DISCARDED]: [VoucherMoreActions.SetAsReceived, VoucherMoreActions.Delete],
  [VoucherInboxState.RECEIVED]: [VoucherMoreActions.Delete],
  [VoucherInboxState.RECORDED]: [],
  [VoucherInboxState.NEED_INFO]: [VoucherMoreActions.Delete],
}

const externalRoutingActions: VoucherMoreActions[] = [VoucherMoreActions.GoToBill, VoucherMoreActions.GoToTransaction]

const isExternalRoutingAction = (action: VoucherMoreActions) => externalRoutingActions.includes(action)

const getOnlyAvailableActions = (actions: VoucherStateActions, inboxState: VoucherInboxState, voucher: Voucher) => {
  if (!inboxState || !actions[inboxState]) {
    return []
  }

  return actions[inboxState].filter((action: VoucherMoreActions) => {
    if (!isExternalRoutingAction(action)) {
      return true
    }

    const { billId, transactionId } = voucher.voucherReference || {}
    const isGoToBillAvailable = billId && action === VoucherMoreActions.GoToBill
    const isGoToTransactionAvailable = transactionId && action === VoucherMoreActions.GoToTransaction

    return isGoToBillAvailable || isGoToTransactionAvailable
  })
}

const getActionLink = (action: VoucherMoreActions, voucher: Voucher, organizationUrl: string) => {
  if (!isExternalRoutingAction(action)) {
    return
  }

  const { billId, transactionId } = voucher.voucherReference || {}

  switch (action) {
    case VoucherMoreActions.GoToBill:
      return billId ? getBillLink(organizationUrl, billId) : undefined
    case VoucherMoreActions.GoToTransaction:
      return transactionId ? getTransactionLink(organizationUrl, transactionId) : undefined
  }
}

export type VoucherActionsRefProps = {
  isSomeModalOpen: () => boolean
}

type QueryParams = {
  sort: string
}

type VoucherActionsProps = {
  backToInboxAfterAction?: boolean
  customerView?: boolean
  disabled?: boolean
  expanded?: boolean
  nextVoucherId?: string
  voucher: Voucher
  onSuccess?: () => void
  queryParams?: QueryParams
}

const getBillLink = (organizationUrl: string, billId: string) => `/${organizationUrl}/bills/${billId}`

const getTransactionLink = (organizationUrl: string, transactionId: string) =>
  `/${organizationUrl}/transactions/${transactionId}`

export const VoucherActions = forwardRef<VoucherActionsRefProps, VoucherActionsProps>(
  (
    {
      backToInboxAfterAction = false,
      customerView,
      disabled,
      expanded = false,
      onSuccess,
      nextVoucherId,
      voucher,
      queryParams,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { organization } = useUserOrganization()
    const queryClient = useQueryClient()
    const history = useHistory()
    const setQueryParams = useSetQueryParams(QueryParamKeys.VoucherList)
    const [openModal, setOpenModal] = useState<MoreActionsModal>()

    const inboxState = voucher?.inboxState
    const organizationId = voucher?.organizationId
    const isPartner = !customerView
    const voucherOrganizationUrl = organization?.url

    const getDropdownItems = useCallback(
      (actions: VoucherMoreActions[]): NavItem<VoucherMoreActions>[] =>
        actions.map((action) => ({
          href: voucherOrganizationUrl && getActionLink(action, voucher, voucherOrganizationUrl),
          id: action,
          children: t(`voucher.inbox.action.${action}`),
          value: action,
        })),
      [t, voucher, voucherOrganizationUrl],
    )

    const updateVoucherMutation = useMutation(
      (payload: VoucherUpdatePayloadData) =>
        updateVoucher({
          organizationId,
          voucherId: voucher.id,
          payload,
        }),
      {
        onSuccess: () => {
          handleActionSuccess(VoucherAction.postpone)
        },
        onError: () => {
          handleActionError(VoucherAction.postpone)
        },
      },
    )

    useImperativeHandle(ref, () => ({
      isSomeModalOpen() {
        return !!openModal
      },
    }))

    // Events after actions

    const closeModal = useCallback(() => {
      setOpenModal(undefined)
    }, [])

    const goToCustomerVouchers = useCallback(() => {
      window.location.hash = ''
      window.location.pathname = `${organization?.url}/vouchers`
    }, [organization])

    const goToInbox = useCallback(() => {
      history.push('/inbox/voucher-inbox')
    }, [history])

    const createBillFromVoucher = useCallback(
      (voucherId: string) => {
        const path = getVoucherInboxPath(voucherId, InboxRoutes.CreateBill)

        if (queryParams) {
          setQueryParams(queryParams, path)
        }
      },
      [queryParams, setQueryParams],
    )

    const navigateNextOrInbox = useCallback(() => {
      if (backToInboxAfterAction || !nextVoucherId) {
        goToInbox()
        return
      }

      createBillFromVoucher(nextVoucherId)
    }, [backToInboxAfterAction, createBillFromVoucher, goToInbox, nextVoucherId])

    const partnerNextActions: NextActions = useMemo(
      () => ({
        [VoucherMoreActions.Archive]: navigateNextOrInbox,
        [VoucherMoreActions.Discard]: navigateNextOrInbox,
        [VoucherMoreActions.SetAsReceived]: () => createBillFromVoucher(voucher.id),
        [VoucherMoreActions.RequireInfo]: navigateNextOrInbox,
        [VoucherMoreActions.Delete]: navigateNextOrInbox,
        [VoucherMoreActions.Duplicate]: (duplicatedVoucherId?: string) =>
          duplicatedVoucherId && createBillFromVoucher(duplicatedVoucherId),
        [VoucherMoreActions.GoToBill]: noop,
        [VoucherMoreActions.GoToTransaction]: noop,
        [VoucherMoreActions.Postpone]: navigateNextOrInbox,
      }),
      [createBillFromVoucher, navigateNextOrInbox, voucher.id],
    )

    const customerNextActions: NextActions = useMemo(
      () => ({
        [VoucherMoreActions.Archive]: goToCustomerVouchers,
        [VoucherMoreActions.Discard]: goToCustomerVouchers,
        [VoucherMoreActions.SetAsReceived]: noop, // stay on view
        [VoucherMoreActions.RequireInfo]: goToCustomerVouchers,
        [VoucherMoreActions.Delete]: goToCustomerVouchers,
        [VoucherMoreActions.Duplicate]: goToCustomerVouchers,
        [VoucherMoreActions.GoToBill]: noop,
        [VoucherMoreActions.GoToTransaction]: noop,
        [VoucherMoreActions.Postpone]: noop,
      }),
      [goToCustomerVouchers],
    )

    const getNextActionFunction = useCallback(
      (isPartner: boolean, action: VoucherMoreActions) => {
        return isPartner ? partnerNextActions[action] : customerNextActions[action]
      },
      [customerNextActions, partnerNextActions],
    )

    // Handle action processing

    const handleActionSuccess = useCallback(
      (action: VoucherAction) => {
        queryClient.invalidateQueries(QueryKeys.Voucher)
        queryClient.invalidateQueries(QueryKeys.VouchersList)
        onSuccess && onSuccess()

        switch (action) {
          case VoucherAction.archive:
            notify({
              id: NotificationKeys.VoucherInboxArchive,
              message: t('voucher.inbox.toast.voucher_archived_success'),
              variant: 'success',
            })
            getNextActionFunction(isPartner, VoucherAction[action as string])()
            break
          case VoucherAction.receive:
            notify({
              id: NotificationKeys.VoucherInboxReceive,
              message: t('voucher.inbox.toast.voucher_set_as_received_success'),
              variant: 'success',
            })
            getNextActionFunction(isPartner, VoucherAction[action as string])()
            break
          case VoucherAction.delete:
            notify({
              id: NotificationKeys.VoucherInboxDelete,
              message: t('voucher.inbox.toast.voucher_deleted_success'),
              variant: 'success',
            })
            getNextActionFunction(isPartner, VoucherMoreActions.Delete)()
            closeModal()
            break
          case VoucherAction.requireInfo:
            notify({
              id: NotificationKeys.VoucherInboxInfoRequest,
              message: t('voucher.inbox.toast.voucher_requested_info_success'),
              variant: 'success',
            })
            getNextActionFunction(isPartner, VoucherMoreActions.RequireInfo)()
            closeModal()
            break
          case VoucherAction.discard:
            notify({
              id: NotificationKeys.VoucherInboxDiscard,
              message: t('voucher.inbox.toast.voucher_discarded_success'),
              variant: 'success',
            })
            getNextActionFunction(isPartner, VoucherMoreActions.Discard)()
            closeModal()
            break
          case VoucherAction.postpone:
            notify({
              id: NotificationKeys.VoucherInboxPostpone,
              message: t('voucher.inbox.toast.voucher_postponed_success'),
              variant: 'success',
            })
            getNextActionFunction(isPartner, VoucherMoreActions.Postpone)()
            closeModal()
            break
          // no default
        }
      },
      [closeModal, getNextActionFunction, isPartner, onSuccess, queryClient, t],
    )

    const handleActionError = useCallback(
      (action: VoucherAction) => {
        switch (action) {
          case VoucherAction.archive:
            notify({
              id: NotificationKeys.VoucherInboxArchive,
              message: t('voucher.inbox.toast.voucher_archived_failed'),
              variant: 'error',
            })
            break
          case VoucherAction.receive:
            notify({
              id: NotificationKeys.VoucherInboxReceive,
              message: t('voucher.inbox.toast.voucher_set_as_received_failed'),
              variant: 'error',
            })
            break
          case VoucherAction.delete:
            notify({
              id: NotificationKeys.VoucherInboxDelete,
              message: t('voucher.inbox.toast.voucher_deleted_failed'),
              variant: 'error',
            })
            break
          case VoucherAction.requireInfo:
            notify({
              id: NotificationKeys.VoucherInboxInfoRequest,
              message: t('voucher.inbox.toast.voucher_requested_info_failed'),
              variant: 'error',
            })
            break
          case VoucherAction.discard:
            notify({
              id: NotificationKeys.VoucherInboxDiscard,
              message: t('voucher.inbox.toast.voucher_discarded_failed'),
              variant: 'error',
            })
            break
          case VoucherAction.postpone:
            notify({
              id: NotificationKeys.VoucherInboxPostpone,
              message: t('voucher.inbox.toast.voucher_postponed_failed'),
              variant: 'error',
            })
            break
          // no default
        }
      },
      [t],
    )

    // Action state

    const { isSomeActionProcessing, processingActions } = useVoucherActionState({
      voucherId: voucher?.id || '',
      onError: handleActionError,
      onSuccess: handleActionSuccess,
    })
    const isDisabled = disabled || isSomeActionProcessing

    // Computed variables
    const voucherActions = isPartner ? partnerActions : customerActions
    const availableActions = useMemo(
      () => getOnlyAvailableActions(voucherActions, inboxState, voucher),
      [inboxState, voucher, voucherActions],
    )

    // Handlers
    const handlePostponeVoucher = useCallback(
      (values: VoucherUpdatePayloadData) => {
        updateVoucherMutation.mutate(values)
      },
      [updateVoucherMutation],
    )

    const handleDuplicate = useCallback(async () => {
      const duplicatedVoucher = await duplicateVoucher(organizationId, voucher.id)
      const { id: duplicatedVoucherId } = duplicatedVoucher

      if (duplicatedVoucherId) {
        notify({
          id: NotificationKeys.VoucherInboxDublicate,
          message: t('voucher.inbox.toast.voucher_duplicated_success'),
          variant: 'success',
        })
        getNextActionFunction(isPartner, VoucherMoreActions.Duplicate)(duplicatedVoucherId)
      } else {
        notify({
          id: NotificationKeys.VoucherInboxDublicate,
          message: t('voucher.inbox.toast.voucher_duplicated_failed'),
          variant: 'error',
        })
      }
    }, [getNextActionFunction, isPartner, organizationId, t, voucher.id])

    const handleSelect = useCallback(
      (_: string, action: VoucherMoreActions) => {
        if (!organizationId) {
          return
        }

        switch (action) {
          case VoucherMoreActions.Delete:
            setOpenModal(MoreActionsModal.Delete)
            break
          case VoucherMoreActions.Discard:
            setOpenModal(MoreActionsModal.Discard)
            break
          case VoucherMoreActions.RequireInfo:
            setOpenModal(MoreActionsModal.MoreInfo)
            break
          case VoucherMoreActions.Duplicate:
            return handleDuplicate()
          case VoucherMoreActions.GoToBill:
          case VoucherMoreActions.GoToTransaction:
            break
          case VoucherMoreActions.Postpone:
            setOpenModal(MoreActionsModal.Postpone)
            break
          default:
            dispatch(
              voucherActionRequest({
                voucherId: voucher.id,
                action: VoucherAction[action],
                organizationId,
              }),
            )
        }
      },
      [dispatch, handleDuplicate, organizationId, voucher.id],
    )

    const handleDeleteVoucher = useCallback(() => {
      if (!voucher) {
        console.error('Unable to delete. Cannot find active voucher data.')
        notify({
          id: NotificationKeys.VoucherInboxDelete,
          message: t('voucher.inbox.toast.voucher_deleted_failed'),
          variant: 'error',
        })
        return
      }

      dispatch(
        voucherDelete({
          id: voucher.id,
          organizationId,
        }),
      )
    }, [dispatch, organizationId, t, voucher])

    const handleRequestMoreInfo = useCallback(
      (reason: MoreInfoReasonItem, comment?: string) => {
        if (!voucher) {
          console.error('Unable to request more info. Cannot find active voucher data.')
          notify({
            id: NotificationKeys.VoucherInboxInfoRequest,
            message: t('voucher.inbox.toast.voucher_requested_info_failed'),
            variant: 'error',
          })
          return
        }

        dispatch(
          voucherRequestMoreInfo({
            id: voucher.id,
            organizationId,
            reason: reason.id,
            comment,
          }),
        )
      },
      [dispatch, organizationId, t, voucher],
    )

    const handleDiscard = useCallback(
      (reason: DiscardReasonItem, comment?: string) => {
        if (!voucher) {
          console.error('Unable to discard. Cannot find active voucher data.')
          notify({
            id: NotificationKeys.VoucherInboxDiscard,
            message: t('voucher.inbox.toast.voucher_discarded_failed'),
            variant: 'error',
          })
          return
        }

        dispatch(
          voucherDiscard({
            id: voucher.id,
            organizationId,
            reason: reason.id,
            comment,
          }),
        )
      },
      [dispatch, organizationId, t, voucher],
    )

    useEffect(() => {
      organizationId && dispatch(organizationRequested(organizationId))
    }, [organizationId]) // eslint-disable-line react-hooks/exhaustive-deps

    const renderButtons = useCallback(
      () =>
        availableActions.map((action: VoucherMoreActions) => (
          <Button
            danger={action === VoucherMoreActions.Delete}
            key={`moreaction-${action}`}
            onClick={() => handleSelect('', action)}
            disabled={isDisabled}
            variant="secondary"
          >
            {t(`voucher.inbox.action.${action}`)}
          </Button>
        )),
      [availableActions, handleSelect, isDisabled, t],
    )

    const renderDropdown = useCallback(() => {
      return (
        <ButtonDropdown<VoucherMoreActions>
          disabled={isDisabled}
          items={getDropdownItems(availableActions)}
          onSelect={handleSelect}
          placement="top-start"
          variant="secondary"
        >
          {t('voucher.inbox.form.action.more')}
        </ButtonDropdown>
      )
    }, [availableActions, getDropdownItems, handleSelect, isDisabled, t])

    return (
      <>
        {expanded ? renderButtons() : renderDropdown()}
        <DeleteModal
          isOpen={openModal === MoreActionsModal.Delete}
          onCancel={() => setOpenModal(undefined)}
          onConfirm={handleDeleteVoucher}
          disabled={processingActions[VoucherAction.delete]}
        />
        <DiscardModal
          isOpen={openModal === MoreActionsModal.Discard}
          onCancel={() => setOpenModal(undefined)}
          onConfirm={handleDiscard}
          disabled={processingActions[VoucherAction.discard]}
        />
        <MoreInfoModal
          isOpen={openModal === MoreActionsModal.MoreInfo}
          onCancel={() => setOpenModal(undefined)}
          onConfirm={handleRequestMoreInfo}
          disabled={processingActions[VoucherAction.requireInfo]}
        />
        <PostponeModal
          isOpen={openModal === MoreActionsModal.Postpone}
          onCancel={() => setOpenModal(undefined)}
          onConfirm={(values) => handlePostponeVoucher(values as VoucherUpdatePayloadData)}
        />
      </>
    )
  },
)
