import { useCallback, useEffect, useRef, useState } from 'react'

import { Timeout } from '../types/timeout'

interface UseComponentToggleProps {
  animationTime?: number
  isOpen?: boolean
  onRenderCallback?: (() => void) | null
}

export const useComponentToggle = ({
  animationTime = 0,
  isOpen = false,
  onRenderCallback,
}: UseComponentToggleProps) => {
  const [isRendered, setIsRendered] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const closeTimeout = useRef<Timeout>()
  const openTimeout = useRef<Timeout>()

  const open = useCallback(() => {
    setIsRendered(true)

    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current)
    }

    window.requestAnimationFrame(() => {
      onRenderCallback?.()

      openTimeout.current = setTimeout(() => {
        setIsVisible(true)
      }, 0)
    })
  }, [onRenderCallback])

  const close = useCallback(() => {
    setIsVisible(false)

    if (openTimeout.current) {
      clearTimeout(openTimeout.current)
    }

    closeTimeout.current = setTimeout(() => {
      setIsRendered(false)
    }, animationTime)
  }, [animationTime])

  const toggle = useCallback(() => {
    if (isVisible) {
      close()
      return
    }

    open()
  }, [close, isVisible, open])

  useEffect(() => {
    if (isOpen) {
      open()
    } else {
      close()
    }
  }, [close, isOpen, open])

  useEffect(() => {
    return () => {
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current)
      }

      if (openTimeout.current) {
        clearTimeout(openTimeout.current)
      }
    }
  }, [])

  return { open, close, toggle, isVisible, isRendered }
}
