import { Color } from '@design-system'

import React, { ReactElement, useEffect, useRef } from 'react'

import { useSignupGuide } from '../../context/signupGuideContext'
import * as Styled from './styles'

export const TrustpilotRating = (): ReactElement => {
  const trustboxRef = useRef<HTMLDivElement>(null)
  const { language } = useSignupGuide()

  useEffect(() => {
    if (trustboxRef.current && window.Trustpilot) {
      // refresh widget after changing language
      window.Trustpilot.loadFromElement?.(trustboxRef.current, true)
    }
  }, [language])

  return (
    <Styled.TrustpilotRatingWrapper>
      <div
        ref={trustboxRef}
        data-locale={language}
        data-template-id="5406e65db0d04a09e042d5fc"
        data-businessunit-id="4dc6e02200006400050fab27"
        data-style-height="28px"
        data-style-width="100%"
        data-theme="light"
        data-text-color={Color.Gray90}
      />
    </Styled.TrustpilotRatingWrapper>
  )
}
