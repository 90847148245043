import { notify } from '@design-system'

import get from 'lodash/get'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../config/queryClient'
import { QueryKeys } from '../../../enums/queryKeys'
import { getInvoice } from '../query-api'

export const useInvoice = (invoiceId?: string) => {
  const { t } = useTranslation()
  const { data, isLoading, isError, error } = useQuery(
    [QueryKeys.Invoice, invoiceId],
    () => getInvoice({ invoiceId: invoiceId as string }),
    {
      enabled: !!invoiceId,
      staleTime: defaultStaleTime,
    },
  )

  useEffect(() => {
    if (isError) {
      const message = get(error, 'message') || t('invoice.notification.fetch_invoice_error')
      notify({ id: 'invoice-error', message, variant: 'error' })
    }
  }, [error, isError, t])

  return { data, isLoading }
}
