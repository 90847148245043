import { Account } from '@modules-deprecated/app/accounts/types'

export const getSaveAccountInitialData = (account?: Account, groupId?: string) => {
  return {
    accountName: account?.name || '',
    description: account?.description || '',
    accountGroup: { id: groupId || '', accountNo: '' },
    vatRate: { id: account?.taxRateId || '' },
    accountNo: account?.accountNo || null,
    isArchived: account?.isArchived || false,
    isBankAccount: account?.isBankAccount || false,
    isPaymentEnabled: account?.isPaymentEnabled || false,
    currency: { id: account?.currencyId, name: '' },
    bank: { id: account?.bankId || '', name: account?.bankName || '', countryId: '', logoUrl: '' },
    routingNumber: account?.bankRoutingNo || null,
    bankAccountNo: account?.bankAccountNo || null,
    bankSwift: account?.bankSwift || '',
    bankIban: account?.bankIban || '',
    publicAccountId: account?.publicAccountId || null,
  }
}
