import { BohrScanResult } from '../../../types/bohrScanResult'
import { CurrencyInputControlledResult } from '../../app/currencies'
import { BankLine } from '../../bankReconciliation/types'

const DEFAULT_CURRENCY = 'DKK'

interface VoucherPrefilledValues {
  date: Date | undefined
  amount: CurrencyInputControlledResult | undefined
  description?: string
}

const getVoucherValuesFromBohr = (bohrData: BohrScanResult): VoucherPrefilledValues | undefined => {
  const {
    entities,
    document_issue_date: issueDateLegacy,
    document_total_incl_vat: totalLegacy,
    document_currency: currencyIdLegacy,
  } = bohrData
  const { currency: currencyData, issue_date: issueDateData, total_incl_vat: totalInclVatData } = entities || {}
  const currencyId = currencyData?.content || currencyIdLegacy
  const issueDate = issueDateData?.content || issueDateLegacy
  const total = totalInclVatData?.content || totalLegacy

  const amount = total ? { value: total, currency: currencyId || DEFAULT_CURRENCY } : undefined
  const date = issueDate ? new Date(issueDate) : undefined

  return { date, amount }
}

const getVoucherValuesFromBankLine = (bankLine: BankLine): VoucherPrefilledValues | undefined => {
  const { amount: amountRaw, entryDate, description } = bankLine
  const amount = amountRaw ? { value: amountRaw, currency: DEFAULT_CURRENCY } : undefined
  const date = entryDate ? new Date(entryDate) : undefined

  return { amount, date, description }
}

export const getVoucherPrefilledValues = (
  bohrData?: BohrScanResult,
  bankLine?: BankLine,
): VoucherPrefilledValues | undefined => {
  if (bankLine) {
    return getVoucherValuesFromBankLine(bankLine)
  }

  if (bohrData) {
    return getVoucherValuesFromBohr(bohrData)
  }

  return undefined
}
