import { NavItem } from '@design-system'

import { PaymentMethod } from '../../../../query-api/payment-methods-query'

const getItem = (paymentMethod: PaymentMethod): NavItem<PaymentMethod> => ({
  id: paymentMethod.id,
  children: paymentMethod.name,
  value: paymentMethod,
})

export const getItems = (paymentMethods: PaymentMethod[]): NavItem<PaymentMethod>[] => {
  return paymentMethods.map(getItem)
}
