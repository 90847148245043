import { IconButton } from '@design-system'

import React, { ReactElement, ReactNode, useCallback, useState } from 'react'

import {
  SettingsSectionContentHeader,
  SettingsSectionContentHeaderProps,
} from './elements/SettingsSectionContentHeader'
import * as Styled from './styles'

interface SettingsSectionContentProps extends SettingsSectionContentHeaderProps {
  children?: ReactNode
  collapsible?: boolean
  initiallyCollapsed?: boolean
}

export const SettingsSectionContent = ({
  children,
  sideActions,
  subtitle,
  title,
  collapsible,
  initiallyCollapsed,
}: SettingsSectionContentProps): ReactElement => {
  const [isCollapsed, setIsCollapsed] = useState(!!collapsible && !!initiallyCollapsed)

  const handleToggleCollapsed = useCallback(() => {
    setIsCollapsed((previousState) => !previousState)
  }, [])

  return (
    <Styled.SettingsSectionContentWrapper>
      <SettingsSectionContentHeader
        onClick={collapsible ? handleToggleCollapsed : undefined}
        sideActions={
          <>
            {sideActions}
            {collapsible && <IconButton size="m" icon={isCollapsed ? 'chevronDown' : 'chevronUp'} />}
          </>
        }
        subtitle={subtitle}
        title={title}
      />
      {!isCollapsed ? children : null}
    </Styled.SettingsSectionContentWrapper>
  )
}
