import { NavItem, Select, SelectProps } from '@design-system'

import { forwardRef, Ref, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

enum AccountType {
  Tax = 'tax',
  Deduction = 'deduction',
}

interface AccountTypeSelectProps
  extends Omit<SelectProps<AccountType>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: AccountType) => void
}

export const AccountTypeSelect = forwardRef(
  ({ onSelect, ...selectProps }: AccountTypeSelectProps, ref: Ref<HTMLDivElement | null>) => {
    const { t } = useTranslation()

    const items: NavItem<AccountType>[] = useMemo(
      () => [
        {
          id: AccountType.Tax,
          children: t('tax'),
          value: AccountType.Tax,
        },
        {
          id: AccountType.Deduction,
          children: t('deduction'),
          value: AccountType.Deduction,
        },
      ],
      [t],
    )

    return <Select {...selectProps} items={items} onSelect={onSelect} ref={ref} />
  },
)
