import { useCallback } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../config/queryClient'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { useCreatePaymentMethod } from '../../../../../hooks/useCreatePaymentMethod'
import { PaymentMethod } from '../../../../../query-api/payment-methods-query'
import { PaymentMethodDefaultName } from '../../../enums/paymentMethodDefaultName'
import { PaymentMethodType } from '../../../enums/paymentMethodType'

interface UseCreateStripePaymentMethodProps {
  onSuccess: () => void
  onError: () => void
}

interface CreateStripePaymentMethodProps {
  bankAccountId?: string
  stripeAuthorizationCode?: string
}

interface StripePaymentMethodPayload extends Partial<PaymentMethod> {
  stripeAuthorizationCode?: string
}

export const useCreateStripePaymentMethod = ({ onSuccess, onError }: UseCreateStripePaymentMethodProps) => {
  const { organization } = useUserOrganization()

  const handleStripePaymentMethodCreated = useCallback(() => {
    onSuccess()
  }, [onSuccess])

  const {
    create: createPaymentMethod,
    isProcessing,
    ...rest
  } = useCreatePaymentMethod({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.PaymentMethods)
      handleStripePaymentMethodCreated()
    },
    onError: () => {
      onError()
    },
  })

  const createStripePaymentMethod = useCallback(
    ({ bankAccountId, stripeAuthorizationCode }: CreateStripePaymentMethodProps) => {
      const createStripePaymentMethodPayload: StripePaymentMethodPayload = {
        bankAccountId,
        isDefault: true,
        name: PaymentMethodDefaultName.Stripe,
        type: PaymentMethodType.Stripe,
        stripeAuthorizationCode,
      }

      createPaymentMethod({ payload: createStripePaymentMethodPayload, organizationId: organization?.id })
    },
    [createPaymentMethod, organization?.id],
  )

  return { createStripePaymentMethod, isProcessing, ...rest }
}
