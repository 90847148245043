import { ProcessingOverlay } from '@components'
import { notify, useModal } from '@design-system'

import { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../../../config/queryClient'
import { ModalId } from '../../../../../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../../enums/queryKeys'
import { useForm } from '../../../../../../../../../hooks'
import { useInviteBookkeeper } from '../../../../../hooks/useInviteBookkeeper'
import { getValidationSchema, InviteBookkeeperForm, inviteBookkeeperFormDefaultValues } from '../utils/formData'

interface ContextState {
  isSaving: boolean
  submitForm: () => void
}

const InviteBookkeeperModalContext = createContext<ContextState | undefined>(undefined)

interface InviteBookkeeperModalContextProps {
  children: ReactNode
}

export const InviteBookkeeperModalContextProvider = ({ children }: InviteBookkeeperModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { close } = useModal(ModalId.OrganizationSettingsInviteBookkeeper)
  const { Form, handleSubmit } = useForm({
    defaultValues: inviteBookkeeperFormDefaultValues,
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })
  const { isSaving, invite } = useInviteBookkeeper({
    onError: () => {
      notify({ id: NotificationKeys.CreateContact, message: t('error_saving'), variant: 'error' })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.OrganizationBookkeepers, { refetchInactive: true })
      close()
    },
  })

  const handleSubmitForm = useCallback(
    ({ umbrellaId }: InviteBookkeeperForm) => {
      invite(umbrellaId)
    },
    [invite],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return (
    <InviteBookkeeperModalContext.Provider value={{ isSaving, submitForm }}>
      {isSaving && <ProcessingOverlay />}
      <Form>{children}</Form>
    </InviteBookkeeperModalContext.Provider>
  )
}

export const useInviteBookkeeperModal = () => {
  const context = useContext(InviteBookkeeperModalContext)

  if (!context) {
    throw new Error('InviteBookkeeperModalContextProvider is missing in the module!')
  }

  return context
}
