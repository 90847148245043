import { useModal } from '@design-system'

import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganizationSettings } from '@modules-deprecated/app/organization'

import { ReactComponent as DebtCollection } from '../../../assets/images/integrations/debt-collection.svg'
import { ModalId } from '../../../enums/modalId'
import { IntegrationStatusLabel } from '../elements/IntegrationCard/elements/IntegrationStatusLabel'
import { IntegrationId } from '../enums/integrationId'
import { IntegrationStatus } from '../enums/integrationStatus'
import { IntegrationItem } from '../types/integrationItem'

export const useDebtCollectionIntegration = (): IntegrationItem => {
  const { t } = useTranslation()
  const { open: openEnableDebtCollectionModal } = useModal(ModalId.EnableDebtCollectionModal)
  const { open: openDisableDebtCollectionModal } = useModal(ModalId.DisableDebtCollectionModal)
  const { organizationSettings, isLoading: isOrganizationSettingsLoading } = useUserOrganizationSettings()

  const integrationStatus = useMemo(() => {
    if (isOrganizationSettingsLoading) {
      return null
    }

    return organizationSettings?.featureAIS === '1' ? IntegrationStatus.Active : IntegrationStatus.Inactive
  }, [organizationSettings?.featureAIS, isOrganizationSettingsLoading])

  const buttonHandler = useCallback(() => {
    if (integrationStatus === IntegrationStatus.Inactive) {
      openEnableDebtCollectionModal()
    } else if (integrationStatus === IntegrationStatus.Active) {
      openDisableDebtCollectionModal()
    }
  }, [integrationStatus, openEnableDebtCollectionModal, openDisableDebtCollectionModal])

  const buttonText = useMemo(() => {
    if (integrationStatus === IntegrationStatus.Inactive) {
      return t('integrations.card.debt_collection.button.activate')
    } else if (integrationStatus === IntegrationStatus.Active) {
      return t('integrations.card.debt_collection.button.deactivate')
    }
  }, [integrationStatus, t])

  return {
    id: IntegrationId.DebtCollection,
    name: t('integrations.card.debt_collection.title'),
    description: t('integrations.card.debt_collection.body'),
    readMoreLink: 'https://www.billy.dk/apps/advokaternes-inkasso-service/',
    imageNode: <DebtCollection />,
    isHiddenLoading: false,
    isHidden: false,
    statusLabelNode: (
      <IntegrationStatusLabel status={integrationStatus} isStatusLoading={isOrganizationSettingsLoading} />
    ),
    buttonHandler,
    buttonText,
    isStatusLoading: isOrganizationSettingsLoading,
  }
}
