import { TableActionsWrapper } from '@components'

import React, { ReactElement } from 'react'

import { BillsContactFilter } from '../BillsContactFilter'
import { BillsPeriodSelector } from '../BillsPeriodSelector'
import { BillsSearch } from '../BillsSearch'
import { BillsTitle } from '../BillsTitle'

export const BillsTableActions = (): ReactElement => {
  return (
    <TableActionsWrapper>
      <BillsTitle />
      <BillsSearch />
      <BillsContactFilter />
      <BillsPeriodSelector />
    </TableActionsWrapper>
  )
}
