import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { FontWeight } from '../../../enums/fontWeight'
import { StyledComponent } from '../../../types/styledComponent'
import { Theme } from '../../../types/theme'

export const getPlaceholderStyle = (
  theme: Theme,
  selectLook: boolean,
  Input?: StyledComponent,
): SerializedStyles => css`
  ${Input || '&'} {
    &::placeholder {
      color: ${selectLook ? theme.ds.colors.base.textPrimary : Color.Gray60};
      font-weight: ${selectLook ? FontWeight.Medium : FontWeight.Regular};
    }

    &::-moz-placeholder {
      opacity: 1;
      color: ${selectLook ? theme.ds.colors.base.textPrimary : Color.Gray60};
      font-weight: ${selectLook ? FontWeight.Medium : FontWeight.Regular};
    }
  }
`
