import { Role } from '../types/role'
import { RolePrice } from '../types/rolePrice'

export const roleToPrice: Partial<Record<Role, RolePrice>> = {
  externalInvoicer: {
    plan: [
      'accountant_package_seat_billy',
      'internal_core_10_pack_seat',
      'internal_plus',
      'internal_pro',
      'internal_smart',
    ],
    price: 59,
    currencyId: 'DKK',
  },
}
