import { PaymentPaneStatus } from '@design-system'

import { InvoiceStatus } from '../../../enums/invoiceStatus'

export const invoiceStatusToPaymentPaneStatus: Record<InvoiceStatus, PaymentPaneStatus | undefined> = {
  [InvoiceStatus.All]: undefined,
  [InvoiceStatus.Draft]: PaymentPaneStatus.Success,
  [InvoiceStatus.Overdue]: PaymentPaneStatus.Error,
  [InvoiceStatus.Paid]: PaymentPaneStatus.Success,
  [InvoiceStatus.PartiallyPaid]: undefined,
  [InvoiceStatus.Unpaid]: undefined,
}
