import { Tag } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useChartOfAccountsFilters } from '../../hooks/useChartOfAccountsFilters'

export const NotMappedToPublicChartOfAccountsTag = (): ReactElement | null => {
  const { t } = useTranslation()
  const [{ noPublicCoaId }, setQueryParams] = useChartOfAccountsFilters()

  const handleOnClose = useCallback(() => {
    setQueryParams({
      noPublicCoaId: undefined,
    })
  }, [setQueryParams])

  if (!noPublicCoaId) {
    return null
  }

  return (
    <Tag variant="error" onClose={handleOnClose}>
      {t('coa.not_mapped_to_public_chart_of_accounts_tag')}
    </Tag>
  )
}
