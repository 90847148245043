export enum BillsRoute {
  CreateCreditNote = '/create-credit-note',
  Duplicate = '/duplicate',
  Edit = '/edit',
  EditBulk = '/bulk-edit',
  EditBulkAttachments = '/bulk-edit-attachments',
  List = '/',
  ListEmpty = '/empty',
  New = '/new',
  NewCreditNote = '/new-credit-note',
  NewFromAttachment = '/new-from-attachment',
  NewFromContact = '/new-from-contact',
}
