import { Input } from '@design-system'

import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../hooks'

export const InvoiceNumber = () => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext()

  return (
    <FormItem
      name="invoiceNumber"
      label={t('external_invoices.editor.form.field.invoice_number.label')}
      render={({ field: { value, onChange, ...props } }) => {
        return <Input {...props} autoFocus value={value} onChange={onChange} />
      }}
    />
  )
}
