import { BillStatus } from '../enums/billStatus'

export const billStatusToTitleKey: Record<BillStatus, string> = {
  [BillStatus.All]: 'bills.category.all',
  [BillStatus.Draft]: 'bills.category.draft',
  [BillStatus.Overdue]: 'bills.category.overdue',
  [BillStatus.Paid]: 'bills.category.paid',
  [BillStatus.PartiallyPaid]: 'bills.category.partially_paid',
  [BillStatus.Unpaid]: 'bills.category.unpaid',
  [BillStatus.Voided]: 'bills.category.voided',
}
