import React from 'react'
import { Box } from 'rebass'

import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconFileText = ({ color = 'inherit', color2, width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-file-text')} color={color} {...rest}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      stroke={color2}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
      <polyline points="14 2 14 8 20 8"></polyline>
      <line x1="16" y1="13" x2="8" y2="13"></line>
      <line x1="16" y1="17" x2="8" y2="17"></line>
      <polyline points="10 9 9 9 8 9"></polyline>
    </svg>
  </Box>
)
