/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Select /> from design-system instead
 */
import { Badge, Color, Link, PORTAL_CLASS_NAME, zIndex } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Placement } from '@popperjs/core'
import { Input as InputRebass } from '@rebass/forms'
import cc from 'classcat'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, {
  ChangeEvent,
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactDOM from 'react-dom'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'
import { usePopper } from 'react-popper'
import { Box } from 'rebass'

import { useKeyDown, useOnClickOutside } from '../../hooks'
import { reactClass } from '../../utils'
import { getClassName } from '../../utils/getClassName'
import { getPopperModifiers } from '../../utils/getPopperModifiers'
import {
  ConnectForm,
  ConnectFormType,
  ErrorMessage,
  FormControl,
  FormControlProps,
  FormLabel,
  InputSize,
} from '../Form'
import { CREATE_NEW } from './actions'
import { SelectIcon } from './SelectIcon'
import { SelectList } from './SelectList'
import { SmartSelectItem } from './types'

const SuggestionButtonWrapper = styled(Badge)`
  position: absolute;
  top: 10px;
  right: 35px;
  z-index: 3;
`

interface SmartSelectListWrapperProps {
  width?: number | string
}

const SmartSelectListWrapper = styled.div<SmartSelectListWrapperProps>`
  width: ${({ width }) => {
    if (width) {
      if (typeof width === 'number') {
        return `${width}px`
      } else if (width === '100%') {
        return 'auto'
      } else {
        return width
      }
    }

    return '238px'
  }};
  position: absolute;
  z-index: ${zIndex.Dropdown};
`

export type SmartSelectComponentProps<T> = FormControlProps & {
  allowCreateNew?: boolean
  autoComplete?: string
  disabled?: boolean
  disableDropdownAnimation?: boolean
  disconnect?: boolean
  errorPath?: string
  id?: string
  inputSize?: InputSize
  items?: T[]
  label?: string
  listPlacement?: Placement
  listWidth?: number | string
  mode?: 'default' | 'lookup'
  name: string
  notFoundText?: string
  onCreateNew?: (text?: string) => void
  onEnterPress?: (event: KeyboardEvent, selectedId: string, isFocused: boolean, listItemsFiltered: T[]) => void
  onItemSelect?: (item: T, name: string) => void
  onTabPress?: (event: KeyboardEvent, selectedId: string, isFocused: boolean, listItemsFiltered: T[]) => void
  onTextChange?: (text: string) => void
  options?: { required?: boolean | string; [key: string]: undefined | boolean | string | {} }
  pick?: string | 'id'
  placeholder?: string
  preselectedId?: string
  render?: (props: T) => ReactNode
  request?: () => void
  required?: boolean
  selectFirst?: boolean // additional flag for selecting first available item
  silent?: boolean
  suggestedName?: string
}

const areItemsEqual = <T extends SmartSelectItem>(items: T[], itemsToCompare: T[]) => {
  return JSON.stringify(items) === JSON.stringify(itemsToCompare)
}

const findItemById = <T extends SmartSelectItem>(listItems: T[], preselectedId: string) =>
  listItems.find(({ id }) => id === preselectedId)

const SmartSelectComponent = forwardRef(
  <T extends SmartSelectItem>(
    {
      allowCreateNew,
      autoComplete = 'off',
      disabled,
      disableDropdownAnimation,
      disconnect,
      errorPath,
      inputSize,
      items = [],
      label,
      listPlacement = 'bottom-start',
      listWidth = '100%',
      maxHeight = 250,
      mode = 'default',
      name,
      notFoundText,
      onCreateNew = () => null,
      onEnterPress,
      onItemSelect = () => null,
      onTabPress,
      onTextChange = () => null,
      options = {},
      pick = 'id',
      placeholder,
      preselectedId = '',
      render,
      request,
      required,
      selectFirst,
      silent,
      suggestedName,
      ...rest
    }: SmartSelectComponentProps<T>,
    ref: Ref<HTMLDivElement>,
  ): ReactElement => {
    const { t } = useTranslation()
    const [filterMenuByText, setFilterMenuByText] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const [isListOpen, setIsListOpen] = useState(false)
    const [listItems, setListItems] = useState(items)
    const [listItemsInitial, setListItemsInitial] = useState(items)
    const [selectedId, setSelectedId] = useState('')
    const [selectedItem, setSelectedItem] = useState<T>()
    const [text, setText] = useState('')
    const picks = pick.replace(/\s/g, '').split(',')
    const wrapperRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const selectListRef = useRef<HTMLDivElement>(null)
    // Check if user interacted with field
    const hasFieldStateChange = useRef(false)

    const modifiers = useMemo(() => getPopperModifiers(listWidth === '100%'), [listWidth])
    const { styles, forceUpdate } = usePopper(inputRef.current, selectListRef.current, {
      placement: listPlacement,
      modifiers,
    })

    const isSuggestionAvailable = !!suggestedName && !preselectedId && !selectedItem && !disabled
    const isValuePrefilled = !suggestedName && !!preselectedId && !!selectedItem && !disabled

    useOnClickOutside([selectListRef, wrapperRef], () => {
      handleItemSelect()
    })

    const submit = () => {
      const onlyItems = getOnlySelectableItems(listItems)

      if (selectedId === CREATE_NEW) {
        handleCreateNew()
      } else if (text === '' && selectedId === '') {
        handleItemSelect('')
      } else if (onlyItems.length === 1 || (!selectedId && selectFirst && onlyItems.length)) {
        handleItemSelect(onlyItems[0].id)
      } else {
        const { id = '' } = items.find((item) => item.id === selectedId) || ({} as T)
        handleItemSelect(id)
      }

      setIsListOpen(false)
    }

    const handleCreateNew = () => {
      setIsListOpen(false)
      onCreateNew()
    }

    const handleListOpen = () => {
      if (!isListOpen) {
        setListItems(listItemsInitial)
      }
      setIsListOpen(!isListOpen)
    }

    const resetOrKeepCurrentItem = (id?: string) => {
      // if id is empty, reset currently selected item
      if (id === '') {
        setText('')
        setSelectedId('')
        setSelectedItem(undefined)
        onItemSelect({ id: '' } as T, name)
        return
      }

      if (id !== undefined || text !== suggestedName) {
        setText(selectedItem?.text || '')
      }

      // otherwise (if is not defined), keep current one and do not emit 'selected' event
      setSelectedId(selectedItem?.id || '')
    }

    const getOnlySelectableItems = (listItems: T[]): T[] => listItems.filter(({ isHeading }) => !isHeading)

    const handleItemSelect = useCallback(
      (id?: string) => {
        const newSelectedItem = listItems.find((item: T) => item.id === id) as T | undefined

        if (newSelectedItem) {
          setText(newSelectedItem?.text || '')
          setSelectedId(newSelectedItem?.id || '')
          setSelectedItem(newSelectedItem)

          if (!isEqual(newSelectedItem, selectedItem)) {
            const itemToPass = { ...newSelectedItem }
            delete itemToPass.text
            onItemSelect((itemToPass as T) || ({ id: '' } as T), name)
          }
        } else {
          resetOrKeepCurrentItem(id)
        }

        setIsListOpen(false)
        setFilterMenuByText(false)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [listItems, onItemSelect, selectedItem],
    )

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value
      setText(text)
      onTextChange(text)

      if (selectedId) {
        setSelectedId('')
      }

      if (!text) {
        setIsListOpen(false)
        setFilterMenuByText(false)
      } else {
        setIsListOpen(true)
        setFilterMenuByText(true)
      }

      filterListItemsByText(text)
    }

    const filterListItemsByText = (text: string) => {
      const filteredItems = filterMenuByText
        ? listItemsInitial.filter((item) => item.text?.toLowerCase().indexOf(text.toLowerCase()) !== -1)
        : listItemsInitial

      setListItems(filteredItems)
    }

    const handleFocus = useCallback(() => {
      setIsFocused(true)
    }, [])

    const handleBlur = useCallback(() => {
      setIsFocused(false)
    }, [])

    useKeyDown('Escape', () => {
      if (isListOpen) {
        setIsListOpen(false)
      }
    })

    useKeyDown('ArrowDown', () => {
      if (disabled) {
        return
      }
      if (isFocused) {
        if (!isListOpen) {
          setIsListOpen(true)
        }
        const currentIndex = listItems.map((item) => item.id).indexOf(selectedId)
        if (currentIndex + 1 === listItems.length) {
          setSelectedId(CREATE_NEW)
        } else {
          const step = listItems[currentIndex + 1].isHeading ? 2 : 1
          const { id } = listItems[currentIndex + step] || {}
          setSelectedId(id || listItems[0]?.id || '')
        }
      }
    })

    useKeyDown('ArrowUp', () => {
      if (isFocused) {
        if (isListOpen && !selectedId) {
          setIsListOpen(false)
        } else if (isListOpen) {
          if (selectedId === CREATE_NEW) {
            setSelectedId(listItems[listItems.length - 1].id)
          } else {
            const currentIndex = listItems.map((item) => item.id).indexOf(selectedId)
            const step = listItems[currentIndex - 1]?.isHeading ? 2 : 1
            const { id } = listItems[currentIndex - step] || {}
            setSelectedId(id)
            if (!id) {
              setIsListOpen(false)
            }
          }
        }
      }
    })

    useKeyDown('Enter', (event) => {
      if (isFocused) {
        event.preventDefault()
        submit()
      }

      if (onEnterPress) {
        onEnterPress && onEnterPress(event, selectedId, isFocused, listItems)
      }
    })

    useKeyDown('Tab', (event) => {
      if (isFocused && isListOpen) {
        submit()
      }

      onTabPress && onTabPress(event, selectedId, isFocused, listItems)
    })

    const clearAndSetItemById = (itemId: string, items?: T[]) => {
      const newSelectedItem = findItemById<T>(items || listItems, itemId)

      if (preselectedId && !newSelectedItem) {
        console.warn(`Item with id '${preselectedId}' not found.`)
      }

      if (!isEqual(newSelectedItem, selectedItem)) {
        setSelectedItem(newSelectedItem)
        setSelectedId(newSelectedItem?.id || '')
        setText(newSelectedItem?.text || '')
      }
    }

    const setDefaultSelectedItem = (items?: T[]) => {
      if (preselectedId !== undefined && !selectedItem) {
        clearAndSetItemById(preselectedId, items)
      }
    }

    useEffect(() => {
      if (isListOpen) {
        forceUpdate?.()
      }
    }, [forceUpdate, isListOpen])

    useEffect(() => {
      if (areItemsEqual(listItemsInitial, items)) {
        return
      }

      setListItemsInitial(items)
      setDefaultSelectedItem(items)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])

    useEffect(() => {
      if (!hasFieldStateChange.current && (isFocused || isListOpen)) {
        hasFieldStateChange.current = true
      }
    }, [isFocused, isListOpen])

    // Handle preselectedId async set up:

    useEffect(() => {
      if (mode === 'lookup' && text) {
        return
      }

      if (preselectedId !== undefined && !isEqual(selectedId, preselectedId)) {
        clearAndSetItemById(preselectedId, items)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preselectedId])

    useEffect(() => {
      if (!text && isSuggestionAvailable) {
        setText(suggestedName)
      }
      // `suggestedName` is the only value that we should check
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuggestionAvailable, suggestedName])

    // Handle preselected sync set up:

    useEffect(() => {
      setDefaultSelectedItem()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <ConnectForm>
        {({ formState, register }: ConnectFormType) => {
          const errors = formState?.errors || {}
          const error = errorPath ? get(errors, errorPath) : errors[name]
          const isPrefilledStyleAvailable =
            (isValuePrefilled || isSuggestionAvailable) && !hasFieldStateChange.current && !error

          return (
            <>
              <FormControl ref={mergeRefs([wrapperRef, ref])} className={reactClass('smart-select')} {...rest}>
                <FormLabel name={name} required={required || options.required}>
                  {label}
                </FormLabel>
                <Box
                  css={css`
                    position: relative;

                    ${!disabled &&
                    !error &&
                    css`
                      &:hover {
                        input[type='text']:not(:focus) {
                          border-color: ${Color.DeepSeaGreen};
                        }
                      }
                    `}
                  `}
                >
                  <InputRebass
                    ref={inputRef}
                    autoComplete={autoComplete}
                    className={cc([
                      'for-select',
                      getClassName('input-deprecated'),
                      {
                        invalid: error,
                        suggestion: isSuggestionAvailable && !error,
                        prefilled: isPrefilledStyleAvailable,
                        focus: isListOpen,
                        big: inputSize === 'xl',
                      },
                    ])}
                    disabled={disabled}
                    name={name}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleTextChange}
                    placeholder={placeholder}
                    title={text}
                    value={text}
                    data-testid={name}
                    data-isready={items?.length}
                    {...(!disconnect && register ? register(name, { required, ...options }) : undefined)}
                  />
                  {picks.map((pick: string) => (
                    <InputRebass
                      display="none"
                      key={`${name}-${pick}`}
                      name={`_${name}.${pick}`}
                      type="hidden"
                      value={(selectedItem || {})[pick]}
                      {...(!disconnect && register ? register(`_${name}.${pick}`) : undefined)}
                    />
                  ))}
                  {isSuggestionAvailable && (
                    <SuggestionButtonWrapper>
                      <Link onClick={handleCreateNew}>{t('select.suggestion.confirm')}</Link>
                    </SuggestionButtonWrapper>
                  )}
                  {!disabled && (
                    <SelectIcon mode={mode} isListOpen={isListOpen} onClick={handleListOpen} inputSize={inputSize} />
                  )}
                </Box>
                {!silent && <ErrorMessage error={error} />}
              </FormControl>
              {ReactDOM.createPortal(
                <SmartSelectListWrapper
                  className={PORTAL_CLASS_NAME}
                  ref={selectListRef}
                  style={styles.popper}
                  width={listWidth}
                >
                  {isListOpen && (
                    <SelectList<T>
                      allowCreateNew={allowCreateNew}
                      createNewText={text}
                      disabled={disabled}
                      isOpen={isListOpen}
                      items={listItems}
                      maxHeight={maxHeight}
                      notFoundText={notFoundText}
                      onCreateNew={handleCreateNew}
                      onItemSelect={handleItemSelect}
                      height={isListOpen ? 'auto' : 0}
                      render={render}
                      request={request}
                      selectedId={selectedId}
                    />
                  )}
                </SmartSelectListWrapper>,
                document.body,
              )}
            </>
          )
        }}
      </ConnectForm>
    )
  },
) as <T extends SmartSelectItem>(props: SmartSelectProps<T>, ref: Ref<HTMLDivElement>) => ReactElement

// Controlled
export type SmartSelectProps<T extends SmartSelectItem> = SmartSelectComponentProps<T> & {
  defaultValueId?: string
  formControl?: Control<any>
  withSelectEvent?: boolean
}

export const SmartSelect = forwardRef(
  <T extends SmartSelectItem>(
    { formControl, name, withSelectEvent, defaultValueId, preselectedId, ...rest }: SmartSelectProps<T>,
    ref: Ref<HTMLDivElement>,
  ): ReactElement => {
    if (!formControl) {
      return <SmartSelectComponent {...rest} ref={ref} preselectedId={preselectedId} name={name} />
    }

    return (
      <Controller
        render={({ field }) => (
          <SmartSelectComponent<T>
            {...rest}
            ref={ref}
            name={field.name}
            errorPath={!field.value || field.value?.id ? `${name}.id` : `${name}.name`}
            onItemSelect={(value) => {
              field.onChange(value)
              withSelectEvent && rest.onItemSelect && rest.onItemSelect(value, field.name)
            }}
            preselectedId={field.value?.id ?? defaultValueId}
            disconnect
          />
        )}
        control={formControl}
        name={name}
      />
    )
  },
) as <T extends SmartSelectItem>(props: SmartSelectProps<T>, ref: Ref<HTMLDivElement>) => ReactElement
