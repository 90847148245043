import { ProtectedInfo } from '@components'
import { Space } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { getImageClassName } from '../../../../utils/getClassName'
import { getImgPath } from '../../../../utils/getImgPath'
import * as Styled from './styles'

export const BillAttachmentPreviewBlocked = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.BillAttachmentPreviewBlockedWrapper>
      <Styled.CenteredContent>
        <img
          src={getImgPath('/attachments/empty-attachment.svg')}
          className={getImageClassName()}
          height={74}
          width={100}
          alt={t('bill.attachment.blocked')}
        />
        <Space size="s" />
        <ProtectedInfo warningLabel={t('bill.attachment.existing_atatchments_info')} />
      </Styled.CenteredContent>
    </Styled.BillAttachmentPreviewBlockedWrapper>
  )
}
