import { AttachmentFile } from '@design-system'

import qs from 'qs'

import { Organization } from '@modules-deprecated/app/organizations/types'

import { Metable } from '../../../../types/metable'
import { UploadFileHeaders } from '../../../../types/uploadFilesHeaders'
import { getRequest, postFileRequest, postRequest } from '../../../../utils'
import { DeleteTrialDataRecord } from './elements/DeleteTrialDataSection/types/deleteTrialDataRecord'
import { City } from './types/city'
import { OrganizationOwner } from './types/organizationOwner'
import { Zipcode } from './types/zipcode'

export interface UploadFileResponseData {
  files: AttachmentFile[]
}

export const uploadOrganizationLogo = (
  file: File,
  organizationId: string,
  apiHeaders?: UploadFileHeaders,
): Promise<UploadFileResponseData> => {
  return postFileRequest(`/v2/organizations/${organizationId}/logo`, file, apiHeaders ? { headers: apiHeaders } : {})
}

export const uploadOrganizationIcon = (
  file: File,
  organizationId: string,
  apiHeaders?: UploadFileHeaders,
): Promise<UploadFileResponseData> => {
  return postFileRequest(`/v2/organizations/${organizationId}/icon`, file, apiHeaders ? { headers: apiHeaders } : {})
}

interface FetchFileResponseData extends Metable {
  file: AttachmentFile
}

export const fetchFile = (fileId: string): Promise<FetchFileResponseData> => {
  return getRequest(`/v2/files/${fileId}`)
}

export interface ResetOrganizationOptions {
  payload: DeleteTrialDataRecord
  organizationId: string
}

export interface ResetOrganizationResponseData extends Metable {
  organizations: Organization[]
}

export const resetOrganization = ({
  payload,
  organizationId,
}: ResetOrganizationOptions): Promise<ResetOrganizationResponseData> => {
  return postRequest(`/v2/organizations/${organizationId}/reset`, { record: payload })
}

export interface FetchOrganizationOwnersDataOptions {
  include?: string
  organizationId: string
}

export interface FetchOrganizationOwnersDataResponseData extends Metable {
  cities: City[]
  organizationOwners: OrganizationOwner[]
  zipcodes: Zipcode[]
}

export const fetchOrganizationOwnersData = ({
  include,
  organizationId,
}: FetchOrganizationOwnersDataOptions): Promise<FetchOrganizationOwnersDataResponseData> => {
  const queryParams = qs.stringify({
    organizationId,
    ...(include ? { include } : {}),
  })
  return getRequest(`/v2/organizationOwners?${queryParams}`)
}
