import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconMenetoProducts = ({
  color = '#ffffff',
  width = '20px',
  height = '20px',
  isOutlined,
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-meneto-products')} {...rest}>
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke={coloring(color)}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
        fill="none"
      ></path>
      <polyline points="3.27 6.96 12 12.01 20.73 6.96" fill="none"></polyline>
      <line x1="12" y1="22.08" x2="12" y2="12"></line>
    </svg>
  </Box>
)
