import { Language } from '../types/language'
import { Locale } from '../types/locale'

/* eslint-disable @typescript-eslint/naming-convention */
export const localeToLanguage: Record<Locale, Language> = {
  da_DK: 'da',
  en_US: 'en',
  fr_FR: 'fr',
}
/* eslint-enable @typescript-eslint/naming-convention */
