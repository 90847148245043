import colors from '../colors'

export default {
  quickActionMenuContainer: {
    '&:hover': {
      '.quickActionMenuButton': {
        left: 0,
        right: 0,
      },
    },
  },
  quickActionMenuTitle: {
    color: colors.white,
  },
  quickActionMenuButton: {
    background: colors.menuActive,
  },
}
