import { NavItem, NavItemAccessor } from '@design-system'

import { TFunction } from 'i18next'
import reduce from 'lodash/reduce'
import size from 'lodash/size'

import { Account } from '@modules-deprecated/app/accounts/types'

import { AccountValue } from '../types/accountValue'
import { getAccountGroups } from './getAccountGroups'
import { getDisplayTitle } from './getDisplayTitle'
import { groupAccounts } from './groupAccounts'

const getItem = (account: Account, accessorGroupId?: string): NavItem<AccountValue> => {
  const displayTitle = getDisplayTitle(account)
  const displayDescription = account.description
  const searchKeywords = [account.name.toString()]

  if (account.description) {
    searchKeywords.push(...account.description.split(' '))
  }

  if (account.accountNo) {
    searchKeywords.push(account.accountNo.toString())
  }

  return {
    id: account.id,
    accessorGroupId,
    children: displayTitle,
    searchKeywords,
    value: {
      ...account,
      displayData: {
        title: displayTitle,
        description: displayDescription,
      },
    },
  }
}

export const getItems = (accounts: Account[], t: TFunction): NavItem<AccountValue>[] => {
  const groups = getAccountGroups(accounts)
  const accountsGroupped = groupAccounts(accounts)

  return reduce(
    accountsGroupped,
    (result, accounts, groupKey) => {
      const accessorId = `header-${groups[groupKey]}`
      const items = accounts.map((account) => getItem(account, accessorId))
      const withHeader = size(accountsGroupped) > 1
      const resultUpdated: NavItem<AccountValue>[] = [...result]

      if (withHeader) {
        resultUpdated.push({
          id: accessorId,
          accessor: NavItemAccessor.Header,
          children: groups[groupKey] || t('unknown'),
          value: {} as AccountValue,
        })
      }

      resultUpdated.push(...items)
      return resultUpdated
    },
    [] as NavItem<AccountValue>[],
  )
}
