import { AttachmentFile } from '@design-system'

import { Bill } from '../types/bill'

export const getAttachmentsFilesFromBill = (bill: Bill): AttachmentFile[] | undefined => {
  const { attachments } = bill

  if (!attachments.length) {
    return undefined
  }

  return attachments.map((attachment) => ({ ...attachment.file, attachmentId: attachment.id }))
}
