import { useUserOrganization } from '@modules-deprecated/app/organization'
import { OrganizationFeature, useOrganizationFeature } from '@views/settings/routes/OrganizationFeaturesSettings'

import { useBillReconciliationSuggestions } from '../../hooks/useBillReconciliationSuggestions'
import { BillOverviewReconciliationSuggestionContent } from '../BillOverviewReconciliationSuggestionContent'
import { useBillView } from '../BillReadableForm'

export const BillOverviewReconciliationSuggestion = () => {
  const { organization } = useUserOrganization()
  const { hasFeature } = useOrganizationFeature()
  const hasBillReconciliationFeature = hasFeature(OrganizationFeature.BillReconciliation)
  const { billId, billData, isFetching: isFetchingBill } = useBillView()
  const { suggestions, isLoading: isLoadingSuggestions } = useBillReconciliationSuggestions(billId)
  const bill = billData?.bill
  const isLoading = isLoadingSuggestions || isFetchingBill

  if (
    isLoading ||
    !hasBillReconciliationFeature ||
    !organization?.randomBucket ||
    !suggestions?.length ||
    bill?.isPaid
  ) {
    return null
  }

  return <BillOverviewReconciliationSuggestionContent />
}
