import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

import { ReactComponent as FindAccountantIllustration } from '../../../../assets/dashboard-find-accountant.svg'

export const FindAccountantWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${Spacing.XL};
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
`

export const Header = styled.div``

export const Title = styled(Text)`
  max-width: 95%;
`

export const Content = styled(Text)`
  max-width: 250px;
`

export const Illustration = styled(FindAccountantIllustration)`
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
`

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: ${Spacing.S};
  right: ${Spacing.S};
`
