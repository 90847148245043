export enum WidgetId {
  BankIntegration = 'BankIntegration',
  Bills = 'Bills',
  FindAccountant = 'FindAccountant',
  GetStarted = 'GetStarted',
  Invoices = 'Invoices',
  Overview = 'Overview',
  OverviewMonthly = 'OverviewMonthly',
  Tasks = 'Tasks',
  TopCustomers = 'TopCustomers',
  TopVendors = 'TopVendors',
  Upsell = 'Upsell',
  Vat = 'Vat',
}
