import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

interface StepsListItemProps {
  active?: boolean
}
export const StepsListItem = styled.li<StepsListItemProps>`
  counter-increment: steps-counter;
  padding-left: 48px;
  margin-bottom: ${Spacing.XXL};
  position: relative;

  // Displays the line that connects the step circles
  &:after {
    content: ' ';
    display: block;
    width: 0;
    height: 100%;
    position: absolute;
    left: ${Spacing.L};
    top: 28px;
    border-right: 1px solid ${Color.Gray40};
  }

  // Displays step circle with number in it
  &:before {
    content: counter(steps-counter);
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 1px solid ${({ active }) => (active ? Color.Blue : Color.Gray50)};
    color: ${({ active }) => (active ? Color.White : Color.Gray90)};
    background-color: ${({ active }) => (active ? Color.Blue : Color.White)};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: -4px;
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }
`
