import { Color, DefaultValue, getDefaultTransition, Spacing, Text } from '@design-system'

import { css } from '@emotion/core'
import { transparentize } from 'polished'
import React from 'react'
import { Box } from 'rebass'

export type SelectMenuItemProps = {
  id: string
  active?: boolean
  children: React.ReactNode
  onSelect: (id: string) => void
}

export const SelectMenuItem = React.forwardRef(({ id, active, onSelect, children }: SelectMenuItemProps, ref) => (
  <Box
    ref={ref}
    key={id}
    css={css`
      padding: ${Spacing.M} ${Spacing.L};
      border-radius: ${DefaultValue.BorderRadius};
      background-color: ${active ? Color.Purple20 : Color.White};
      cursor: pointer;
      ${getDefaultTransition('background-color')};

      ${!active &&
      css`
        &:hover {
          background-color: ${transparentize(0.75, Color.Gray60)};
        }
      `}
    `}
    onClick={() => onSelect(id)}
  >
    <Text weight="medium">{children}</Text>
  </Box>
))
