import { Space } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

export interface EmptyStateProps {
  buttonDataCy?: string
  buttonText: string
  children: ReactNode
  hideButton?: boolean
  image: ReactNode
  onButtonClick?: () => void
}

export const EmptyState = ({
  buttonDataCy,
  buttonText,
  children,
  hideButton,
  image,
  onButtonClick,
}: EmptyStateProps): ReactElement => (
  <Styled.EmptyStateWrapper>
    {image}
    <Space size="m" />
    <Styled.Text colorVariant="secondary">{children}</Styled.Text>
    {!hideButton && (
      <>
        <Space size="m" />
        <Styled.Button onClick={onButtonClick}>{buttonText}</Styled.Button>
      </>
    )}
  </Styled.EmptyStateWrapper>
)
