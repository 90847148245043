import { Icon, InputRef } from '@design-system'

import React, { ChangeEvent, forwardRef, ReactElement, Ref } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface GlobalSearchInputProps {
  value?: string
  isLoading?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onClose?: () => void
}

export const GlobalSearchInput = forwardRef(
  ({ isLoading, value, onChange, onClose }: GlobalSearchInputProps, forwardedRef: Ref<InputRef>): ReactElement => {
    const { t } = useTranslation()

    return (
      <Styled.SearchInputWrapper>
        <Styled.SearchIconWrapper>
          <Icon icon="magnifyingGlass" />
        </Styled.SearchIconWrapper>

        <Styled.Input
          ref={forwardedRef}
          bordered={false}
          placeholder={`${t('search')}...`}
          value={value}
          onChange={onChange}
          spellCheck={false}
          suffix={
            isLoading && (
              <Styled.ArcIconWrapper>
                <Icon icon="circleDashed" />
              </Styled.ArcIconWrapper>
            )
          }
          size="m"
        />

        <Styled.CloseIconWrapper>
          <Icon icon="xSign" onClick={onClose} />
        </Styled.CloseIconWrapper>
      </Styled.SearchInputWrapper>
    )
  },
)
