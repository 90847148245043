import { GridList, Input } from '@components-deprecated'
import { notify } from '@design-system'

import queryString from 'qs'
import React, { forwardRef, ReactElement, useImperativeHandle } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../enums/queryKeys'
import { formatDate } from '../../../../utils'
import { AccountSelector } from '../../../app'
import { Account } from '../../../app/accounts/types'
import { useUserOrganization } from '../../../app/organization'
import { voucherActionRequest } from '../../action-creators'
import { useVoucherActionState } from '../../hooks/useVoucherActionState'
import { Voucher, VoucherAction, VoucherAllReasons, VoucherUpdatePayloadData } from '../../types'
import { getNeedInfoReasons } from '../../utils/getNeedInfoReasons'
import { getVoucherStateReasons } from '../../utils/getVoucherStateReasons'

type FormInputs = {
  description?: string
  paymentMethod?: Account
}

type VoucherViewEditableProps = {
  voucher?: Voucher
  organizationId: string
}

export type VoucherViewEditableRefProps = {
  handleSave: () => void
}

export const VoucherViewEditable = forwardRef<VoucherViewEditableRefProps, VoucherViewEditableProps>(
  ({ organizationId, voucher }, ref): ReactElement => {
    const defaultValues: FormInputs = {
      description: voucher?.description || '',
      paymentMethod: voucher?.bankAccountId ? ({ id: voucher?.bankAccountId } as Account) : undefined,
    }
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const form = useForm({ defaultValues })
    const history = useHistory()
    const { search } = useLocation()
    const { t } = useTranslation()
    const needInfoReasons = getNeedInfoReasons(t)
    const stateReasons = getVoucherStateReasons(t)
    const voucherAllReasons: Record<VoucherAllReasons, string> = { ...needInfoReasons, ...stateReasons }
    const { organization } = useUserOrganization()

    const { isSomeActionProcessing: isProcessing } = useVoucherActionState({
      voucherId: voucher?.id || '',
      actions: [VoucherAction.update],
      onError: (action) => {
        if (action === VoucherAction.update) {
          notify({
            id: NotificationKeys.VoucherInboxUpdate,
            message: t('voucher.inbox.toast.voucher_update_failed'),
            variant: 'error',
          })
        }
      },
      onSuccess: (action) => {
        if (action === VoucherAction.update) {
          queryClient.invalidateQueries(QueryKeys.Voucher)
          queryClient.invalidateQueries(QueryKeys.VouchersList)
          notify({
            id: NotificationKeys.VoucherInboxUpdate,
            message: t('voucher.inbox.toast.voucher_update_success'),
            variant: 'success',
          })
          close()
        }
      },
    })

    const checkSourceAndRedirect = (): boolean => {
      const { source } = queryString.parse(search.split('?')?.[1] || '') as { source?: string }

      if (source && organization?.url) {
        window.location.href = `/${organization.url}/${source}`
        return true
      }

      return false
    }

    const close = () => {
      const hasExternalSource = checkSourceAndRedirect()

      if (!hasExternalSource) {
        history.push('/')
      }
    }

    useImperativeHandle(ref, () => ({
      handleSave() {
        onSave()
      },
    }))

    const onSave = () => {
      form.handleSubmit(handleSave)()
    }

    const handleSave = (data: FormInputs) => {
      if (!voucher) {
        return
      }

      const payload: VoucherUpdatePayloadData = {}

      if (data.paymentMethod?.id !== undefined) {
        payload.bankAccountId = data.paymentMethod.id
      }

      if (data.description !== undefined) {
        payload.description = data.description
      }

      dispatch(
        voucherActionRequest({ organizationId, voucherId: voucher.id, action: VoucherAction.update, data: payload }),
      )
    }

    return (
      <>
        <FormProvider {...form}>
          <form>
            <Input
              formControl={form.control}
              label={t('voucher.inbox.view.description')}
              name="description"
              disabled={isProcessing}
            />
            <AccountSelector
              formControl={form.control}
              isPaymentEnabled
              label={t('voucher.inbox.view.payment_method')}
              name="paymentMethod"
              disabled={isProcessing}
            />
          </form>
        </FormProvider>

        <GridList
          truncateValue={false}
          itemPerRow
          mt="20px"
          items={[
            {
              id: 'upload_date',
              text: t('voucher.inbox.view.upload_date'),
              value: formatDate(voucher?.createdTimestamp) || '-',
            },
            ...(voucher?.stateReason
              ? [
                  {
                    id: 'reason',
                    text: t('voucher.inbox.view.reason'),
                    value: voucherAllReasons[voucher.stateReason],
                  },
                ]
              : []),
            ...(voucher?.stateComment
              ? [
                  {
                    id: 'comment',
                    text: t('voucher.inbox.view.comment'),
                    value: voucher?.stateComment,
                  },
                ]
              : []),
          ]}
        />
      </>
    )
  },
)
