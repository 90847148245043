import { InvoiceBalanceModifierReference } from '../enums/InvoiceBalanceModifierReference'

export const invoiceBalanceModifierReferenceToTKey: Record<InvoiceBalanceModifierReference, string> = {
  [InvoiceBalanceModifierReference.BankPayment]: 'invoices.view.payment_pane.modifier.bank_payment',
  [InvoiceBalanceModifierReference.ContactBalancePayment]:
    'invoices.view.payment_pane.modifier.contact_balance_payment',
  [InvoiceBalanceModifierReference.DaybookTransaction]: 'invoices.view.payment_pane.modifier.daybook_transaction',
  [InvoiceBalanceModifierReference.Invoice]: 'invoices.view.payment_pane.modifier.credit_note',
  [InvoiceBalanceModifierReference.InvoiceLateFee]: 'invoices.view.payment_pane.modifier.invoice_late_fee',
}
