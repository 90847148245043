import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconMenetoSupport = ({ color = '#FFFFFF', width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-meneto-support')} {...rest}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      color={coloring(color)}
    >
      <circle cx="12" cy="12" r="10" fill="none"></circle>
      <circle cx="12" cy="12" r="4" fill="none"></circle>
      <line x1="4.93" y1="4.93" x2="9.17" y2="9.17" fill="none"></line>
      <line x1="14.83" y1="14.83" x2="19.07" y2="19.07" fill="none"></line>
      <line x1="14.83" y1="9.17" x2="19.07" y2="4.93" fill="none"></line>
      <line x1="14.83" y1="9.17" x2="18.36" y2="5.64" fill="none"></line>
      <line x1="4.93" y1="19.07" x2="9.17" y2="14.83" fill="none"></line>
    </svg>
  </Box>
)
