import { MODULE_LAYOUT_SCROLL_RESTORATION_KEY } from '../constants/moduleLayoutScrollRestorationKey'

export const clearAllScrollRestorationData = () => {
  const sessionStorageLength = sessionStorage.length

  for (let i = 0; i < sessionStorageLength; i++) {
    const key = sessionStorage.key(i)
    const regexp = new RegExp(MODULE_LAYOUT_SCROLL_RESTORATION_KEY, 'i')

    if (key && regexp.test(key)) {
      sessionStorage.removeItem(key)
    }
  }
}
