import { EmptyView, emptyViewVariants } from '@components'
import { ModuleLayout } from '@design-system'

import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { QuotesRoute } from '../../enums/quotesRoute'
import { useIsQuotesListEmpty } from '../QuotesList/hooks/useIsQuotesListEmpty'
import { useQuotesListFilters } from '../QuotesList/hooks/useQuotesListFilters'

export const QuotesListEmpty = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const [, setFilters] = useQuotesListFilters()
  const isQuotesListEmpty = useIsQuotesListEmpty()

  // Clear filters in case they left after redirecting from `QuotesList`
  useEffect(() => {
    setFilters({
      page: undefined,
      pageSize: undefined,
      period: undefined,
      searchQuery: undefined,
      sortDirection: undefined,
      sortProperty: undefined,
      state: undefined,
    })
  }, [setFilters])

  const { animation, description, title, buttonText } = emptyViewVariants.quotes

  const handleEmptyViewClick = useCallback(() => {
    navigate(EmberRoute.QuotesNew)
  }, [navigate])

  if (!isQuotesListEmpty) {
    return <Redirect to={QuotesRoute.List} />
  }

  return (
    <ModuleLayout title={t('quotes')}>
      <EmptyView
        animation={animation}
        onClick={handleEmptyViewClick}
        scopes={Scope.OfferWrite}
        showCTA={!!buttonText?.length}
        tButton={buttonText}
        tDescription={description}
        trackingContext={TrackingContext.CreateQuote}
        tTitle={title}
      />
    </ModuleLayout>
  )
}
