import { TFunction } from 'i18next'
import { object, SchemaOf, string } from 'yup'

export interface DeleteTrialDataForm {
  password: string
  matchingPhrase: string
}

export const defaultValues: DeleteTrialDataForm = {
  password: '',
  matchingPhrase: '',
}

export type DeleteTrialDataFormSchema = SchemaOf<DeleteTrialDataForm>

export const getValidationSchema = (t: TFunction): DeleteTrialDataFormSchema =>
  object({
    password: string().required(t('required_field')),
    matchingPhrase: string()
      .matches(/^(reset|nulstil)$/, t('delete_trial_data_modal.form_error.matching_phrase'))
      .required(),
  })
