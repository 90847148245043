import { ModalProps } from '@design-system'

import { ReactNode } from 'react'

import { ModalContentTogglerContextProvider } from './contexts/modalContentTogglerContext'
import { ToggleModal } from './elements/ToggleModal'

export interface ModalState<T> {
  backwardsNavigationStateId?: T
  headerTKey: string
  id: T
  modalBody: ReactNode
  modalFooter: ReactNode
  withBackwardsNavigation?: boolean
}

export type ModalStates<T> = ModalState<T>[]

export interface ModalContentTogglerProps<T> extends ModalProps {
  initialState?: string
  states: ModalStates<T>
}

export const ModalContentToggler = <T,>({ initialState, states, ...modalProps }: ModalContentTogglerProps<T>) => (
  <ModalContentTogglerContextProvider<T> initialState={initialState} states={states}>
    <ToggleModal {...modalProps} />
  </ModalContentTogglerContextProvider>
)
