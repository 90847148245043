import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

import { useOrganizationSubscription } from '../../../../../../../hooks'

export const useIsCurrentPlan = (plan: SubscriptionPlan) => {
  const { subscriptionPlan: currentPlan, subscriptionPeriod: currentPlanPeriod } = useOrganizationSubscription()

  const isCurrentPlan = currentPlan === plan
  const isCurrentPlanOnYearlyPayment = currentPlanPeriod === SubscriptionPeriod.Yearly

  return { isCurrentPlan, isCurrentPlanOnYearlyPayment }
}
