import upperFirst from 'lodash/upperFirst'

type ExtendData = Record<string, any>

export const extendVoucherDescription = (description: string, data: ExtendData) => {
  let descriptionExtended = description || ''

  for (const key in data) {
    const separator = descriptionExtended ? '; ' : ''
    descriptionExtended += `${separator}${upperFirst(key)}: ${data[key]}`
  }

  return descriptionExtended
}
