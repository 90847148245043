import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../../utils'
import { addTaxRate } from '../../../../query-api'

interface UseCreateVatRate {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useCreateVatRate = (props?: UseCreateVatRate) => {
  const { onError, onSuccess } = props || {}

  const { mutate: create, ...rest } = useMutation(addTaxRate, {
    onError,
    onSuccess,
  })

  return { create, ...rest }
}
