import { useMutation } from 'react-query'

import {
  createUmbrellaOrganizationSubscriptionAndTransferOwnership,
  CreateUmbrellaOrganizationSubscriptionResponse,
} from '../query-api'

interface UseCreateUmbrellaOrganizationSubscriptionArgs {
  onError?: () => void
  onSuccess?: (response: CreateUmbrellaOrganizationSubscriptionResponse) => void
}

export const useCreateUmbrellaOrganizationSubscriptionAndTransferOwnership = ({
  onError,
  onSuccess,
}: UseCreateUmbrellaOrganizationSubscriptionArgs = {}) => {
  const { mutate: create, isLoading } = useMutation(createUmbrellaOrganizationSubscriptionAndTransferOwnership, {
    onError,
    onSuccess,
  })

  return { create, isLoading }
}
