import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const BillEditableAttachmentsWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 384px;
  flex-direction: column;
  padding-bottom: ${Spacing.XXL};
`

export const BillImageWrapper = styled.div`
  max-height: 100%;
`
