import { notify } from '@design-system'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCopyToClipboard } from 'react-use'

import { NotificationKeys } from '../enums/notificationKeys'

export const useCopyAndNotify = () => {
  const [state, copyToClipboard] = useCopyToClipboard()
  const { t } = useTranslation()

  useEffect(() => {
    if (state.value) {
      notify({
        id: NotificationKeys.CopyableInfo,
        message: t('copyable.success_info'),
        variant: 'success',
      })
    } else if (state.error) {
      notify({
        id: NotificationKeys.CopyableInfo,
        message: t('copyable.error_info'),
        variant: 'error',
      })
    }
  }, [state])

  return { copy: copyToClipboard }
}
