import { ButtonProtected, ProtectedComponent, SettingsSection } from '@components'
import { useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../enums/modalId'
import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { AccessTokensTable } from './elements/AccessTokensTable'

export const OrganizationAccessTokensSettings = (): ReactElement => {
  const { t } = useTranslation()
  const { open: openCreateAccessTokenModal } = useModal(ModalId.CreateOrEditAccessTokenModal)

  const handleCreateAccessTokenButtonClick = useCallback(() => {
    openCreateAccessTokenModal()
  }, [openCreateAccessTokenModal])

  return (
    <SettingsSection>
      <SettingsSection.Content
        title={t('settings.organization.access_tokens')}
        sideActions={
          <ButtonProtected
            icon="plusCircle"
            onClick={handleCreateAccessTokenButtonClick}
            scopes={Scope.TokensWrite}
            trackingContext={TrackingContext.Integrations}
            variant="secondary"
          >
            {t('settings.organization.access_tokens.create_access_token')}
          </ButtonProtected>
        }
      >
        <ProtectedComponent scopes={Scope.TokensWrite}>
          <AccessTokensTable />
        </ProtectedComponent>
      </SettingsSection.Content>
    </SettingsSection>
  )
}
