import { css, SerializedStyles } from '@emotion/core'
import { Line, ProgressProps } from 'rc-progress'
import React from 'react'

import { coloring } from '../../styles-deprecated'
import { Modal, ModalBody, ModalProps } from '../Modal'

export type LoadingModalProps = ModalProps &
  ProgressProps & {
    css?: SerializedStyles
  }

// A percentage value of 0 will make the Line render as 100%, so we need to change it to be a low non-0 number
export const LoadingModal = ({ isOpen, children, css: styles, percent, ...props }: LoadingModalProps) => (
  <Modal
    required={true}
    isOpen={isOpen}
    css={
      [
        css`
          top: calc(50vh - 52px);
        `,
        styles,
      ] as unknown as SerializedStyles
    }
  >
    {isOpen && (
      <ModalBody>
        {children}
        <Line strokeColor={coloring('primary')} percent={percent || 0.01} {...props} />
      </ModalBody>
    )}
  </Modal>
)
