import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { ExternalInvoicesSyncProgressContextProvider, ExternalInvoicesSyncTopBar } from '@features/externalInvoicesSync'
import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'external-invoices-sync-top-bar-module'

class ExternalInvoicesSyncTopBarWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
}

interface ExternalInvoicesSyncTopBarModuleProps {
  onNavigate: EmberNavigate
  organizationId: string
}

const ExternalInvoicesSyncTopBarModule = ({
  onNavigate,
  organizationId,
}: ExternalInvoicesSyncTopBarModuleProps): ReactElement => (
  <ThemeProvider>
    <QueryClientProvider client={queryClient}>
      <EmberRouterContextProvider onNavigate={onNavigate}>
        <UserOrganizationContextProvider organizationId={organizationId}>
          <ModalContextProvider>
            <ExternalInvoicesSyncProgressContextProvider>
              <ExternalInvoicesSyncTopBar />
            </ExternalInvoicesSyncProgressContextProvider>
          </ModalContextProvider>
        </UserOrganizationContextProvider>
      </EmberRouterContextProvider>
    </QueryClientProvider>
  </ThemeProvider>
)

const ExternalInvoicesSyncTopBarCustomElement = createCustomElement(
  ExternalInvoicesSyncTopBarModule,
  ExternalInvoicesSyncTopBarWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, ExternalInvoicesSyncTopBarCustomElement)

export default ExternalInvoicesSyncTopBarCustomElement
