import { NavItem, Select, SelectProps } from '@design-system'

import { forwardRef, Ref, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TaxMode } from '../../../../../../../../../../../../enums/taxMode'

interface TaxModeSelectProps
  extends Omit<SelectProps<TaxMode>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: TaxMode) => void
}

export const TaxModeSelect = forwardRef(
  ({ onSelect, ...selectProps }: TaxModeSelectProps, ref: Ref<HTMLDivElement | null>) => {
    const { t } = useTranslation()

    const items: NavItem<TaxMode>[] = useMemo(
      () => [
        {
          id: TaxMode.Excluding,
          children: t('settings.organization.invoice.invoice_settings.invoice_no_mode.option.excluding_vat'),
          value: TaxMode.Excluding,
        },
        {
          id: TaxMode.Including,
          children: t('settings.organization.invoice.invoice_settings.invoice_no_mode.option.including_vat'),
          value: TaxMode.Including,
        },
      ],
      [t],
    )

    return <Select {...selectProps} items={items} onSelect={onSelect} ref={ref} />
  },
)
