import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { Reports, ReportsEmberConnectionContextProvider } from '@views/reports'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { GlobalFiscalYearContextProvider } from '../../contexts/globalFiscalYearContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

const MODULE_NAME = 'reports-module'

class ReportsWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('navigateToAccount') navigateToAccount: Function
}

interface ReportsModuleProps {
  organizationId: string
  onNavigate: EmberNavigate
  onNavigateToAccount: EmberEventFn
}

const ReportsModule = ({ onNavigate, organizationId, onNavigateToAccount }: ReportsModuleProps): ReactElement => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <HashRouter>
        <CurrentUserContextProvider>
          <EmberRouterContextProvider onNavigate={onNavigate}>
            <UserOrganizationContextProvider organizationId={organizationId}>
              <GlobalFiscalYearContextProvider organizationId={organizationId}>
                <ReportsEmberConnectionContextProvider onNavigateToAccount={onNavigateToAccount}>
                  <Reports />
                </ReportsEmberConnectionContextProvider>
              </GlobalFiscalYearContextProvider>
            </UserOrganizationContextProvider>
          </EmberRouterContextProvider>
        </CurrentUserContextProvider>
      </HashRouter>
    </ThemeProvider>
  </QueryClientProvider>
)

const ReportsCustomElement = createCustomElement(ReportsModule, ReportsWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, ReportsCustomElement)

export default ReportsCustomElement
