import { useMutation } from 'react-query'

import { OrganizationSettingsValue } from '@modules-deprecated/app/organization/types/organizationSettingsValue'

import { APIError } from '../../../../../../../utils'
import { updateVoucherInboxEnabled } from '../../../query-api'

interface UseUpdateVoucherInboxProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
  onSettled?: () => void
}

export interface UpdateVoucherInboxProps {
  organizationId: string
  isInboxEnabled: OrganizationSettingsValue
}

export const useUpdateVoucherInbox = (props?: UseUpdateVoucherInboxProps) => {
  const { onError, onSuccess, onSettled } = props || {}

  const { mutate: update, isLoading: isProcessing } = useMutation(
    ({ organizationId, isInboxEnabled }: UpdateVoucherInboxProps) =>
      updateVoucherInboxEnabled({ organizationId, isInboxEnabled }),
    {
      onError,
      onSuccess,
      onSettled,
    },
  )

  return {
    isProcessing,
    update,
  }
}
