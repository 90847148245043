import {
  convertFromRaw,
  convertToRaw,
  DraftHandleValue,
  Editor,
  EditorCommand,
  EditorProps,
  EditorState,
  RichUtils,
  SelectionState,
} from 'draft-js'
import 'draft-js/dist/Draft.css'
import React, { MouseEvent, ReactElement, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { LineHeight } from '../../enums/lineHeight'
import { pxToNumber } from '../../utils/pxToNumber'
import { ComponentsGroup } from '../ComponentsGroup'
import { InputPropsRaw } from '../Input'
import { ErrorIcon } from '../Input/elements/ErrorIcon'
import { SuccessIcon } from '../Input/elements/SuccessIcon'
import { Space } from '../Space'
import { commandToStyle } from './constants/commandToStyle'
import { RichTextContextProvider } from './contexts/richTextContext'
import { RichTextControls } from './elements/RichTextControls'
import * as Styled from './styles'
import { RichTextStyleSettings } from './types/richTextStyleSettings'
import { blockRenderMap } from './utils/blockRenderMap'
import { keyBindings } from './utils/keyBindings'

type InputPickedProps = Pick<
  InputPropsRaw,
  'autoFocus' | 'error' | 'focused' | 'hidden' | 'success' | 'disabled' | 'size' | 'bordered'
>

const PLAIN_TEXT_DELIMITER = '\u0001'

export interface RichTextProps extends Partial<Omit<EditorProps, 'onChange'>>, InputPickedProps {
  autoSize?: boolean
  onChange?: (editorState?: string) => void
  rows?: number
  styleSettings?: RichTextStyleSettings
  value?: string
}

export const RichText = ({
  autoFocus = false,
  autoSize = false,
  bordered = true,
  disabled = false,
  error = false,
  focused = false,
  hidden = false,
  onChange,
  rows = 4,
  size = 'l',
  styleSettings,
  success = false,
  value,
  ...rest
}: RichTextProps): ReactElement => {
  const [editorState, setEditorState] = useState<EditorState>(() => EditorState.createEmpty())
  const selection = useRef<SelectionState>()
  const editorRef = useRef<Editor>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleOnChange = useCallback(
    (updatedEditorState: EditorState) => {
      selection.current = updatedEditorState.getSelection()
      setEditorState(updatedEditorState)
      const contentState = updatedEditorState.getCurrentContent()
      const rawText = contentState.getPlainText(PLAIN_TEXT_DELIMITER)

      // if there is no text, do not save just metadata
      if (!rawText.length) {
        onChange?.()
        return
      }

      const converted = convertToRaw(contentState)
      onChange?.(JSON.stringify(converted))
    },
    [onChange, setEditorState],
  )

  const handleWrapperClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    if (event.target === wrapperRef.current) {
      editorRef.current?.focus()
    }
  }, [])

  useEffect(() => {
    try {
      const convertedFromValue = convertFromRaw(JSON.parse(value || ''))
      const editorStateFromValue = EditorState.createWithContent(convertedFromValue)
      const stateWithContentAndSelection = selection.current
        ? EditorState.forceSelection(editorStateFromValue, selection.current)
        : editorStateFromValue
      setEditorState(stateWithContentAndSelection)
    } catch (error) {
      setEditorState(EditorState.createEmpty())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    autoFocus && editorRef.current?.focus()
  }, [autoFocus])

  useEffect(() => {
    focused && editorRef.current?.focus()
  }, [focused])

  // This effect sets the correct height of editor, based on autoSize and rows properties.
  // If Autosize is selected, it allows editor to be expanded further below rows, if not, gives it
  // a fixed height.
  useLayoutEffect(() => {
    if (!editorRef.current?.editor) {
      return
    }

    const editorElement = editorRef.current.editor

    if (autoSize) {
      editorElement.style.minHeight = `${rows * pxToNumber(LineHeight.TextNormal)}px`
      return
    }

    editorElement.style.overflow = 'auto'
    editorElement.style.height = `${rows * pxToNumber(LineHeight.TextNormal)}px`
  }, [autoSize, editorRef, rows])

  const handleKeyCommand = (
    command: EditorCommand,
    commandEditorState: EditorState,
    eventTimeStamp: number,
  ): DraftHandleValue => {
    const styleFromCommand = commandToStyle[command]

    if (styleFromCommand) {
      setEditorState(RichUtils.toggleInlineStyle(commandEditorState, styleFromCommand))
      return 'handled'
    }

    return 'not-handled'
  }

  return (
    <RichTextContextProvider
      value={{
        disabled,
        editorState,
        setEditorState,
        styleSettings,
      }}
    >
      <Styled.RichTextWrapper
        alignment="left"
        autoCompleted={false}
        bordered={bordered}
        disabled={disabled}
        error={error}
        focused={focused}
        hidden={hidden}
        onClick={handleWrapperClick}
        ref={wrapperRef}
        selectLook={false}
        size={size}
        truncate={false}
        weight="regular"
      >
        <ComponentsGroup direction="column">
          <RichTextControls />
          <Space size="m" />
          <Editor
            ref={editorRef}
            {...rest}
            editorState={editorState}
            onChange={handleOnChange}
            blockRenderMap={blockRenderMap}
            keyBindingFn={keyBindings}
            handleKeyCommand={handleKeyCommand}
            readOnly={disabled}
          />
          {(error || success) && (
            <Styled.SuffixIconWrapper inputSize={size}>
              {error && <ErrorIcon inputSize={size} disabled={disabled} />}
              {success && <SuccessIcon inputSize={size} disabled={disabled} />}
            </Styled.SuffixIconWrapper>
          )}
        </ComponentsGroup>
      </Styled.RichTextWrapper>
    </RichTextContextProvider>
  )
}
