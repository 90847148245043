import { TFunction } from 'i18next'
import { object, SchemaOf, string } from 'yup'

export interface PasswordForm {
  oldPassword: string
  newPassword: string
}

export type PasswordFormSchema = SchemaOf<PasswordForm>

export const getValidationSchema = (t: TFunction): PasswordFormSchema =>
  object({
    oldPassword: string().required(t('settings.user.profile.password')),
    newPassword: string()
      .min(6, t('organization_invitation.form.errors.password_length'))
      .required(t('settings.user.profile.password')),
  })

export const getDefaultValues = (): PasswordForm => ({
  oldPassword: '',
  newPassword: '',
})
