export const transitionToMiliseconds = (transitionTime: string): number => {
  const transitionWithoutSecond = transitionTime.replace('s', '')
  const transitionFloated = parseFloat(transitionWithoutSecond)

  if (isNaN(transitionFloated) || transitionFloated < 0) {
    console.error(new Error(`Error parsing transitionTime: ${transitionTime}.`))
    return 0
  }

  return transitionFloated * 1000
}
