import { Color } from '@design-system'

import isArray from 'lodash/isArray'

import { StatusFormatterVariant } from '../StatusFormatter'
import { variantStyles } from './variantStyles'

export const getVariantBackground = (
  variant: StatusFormatterVariant | [StatusFormatterVariant, StatusFormatterVariant],
) => {
  if (isArray(variant)) {
    const [leftVariant, rightVariant] = variant
    const leftColor = variantStyles[leftVariant]
    const rightColor = variantStyles[rightVariant]

    return `linear-gradient( -45deg, ${rightColor}, ${rightColor} 49%, ${Color.White} 49%, ${Color.White} 51%, ${leftColor} 51%)`
  }

  return variantStyles[variant]
}
