import { SummaryItem } from '@components-deprecated'

import { TFunction } from 'i18next'

import { roundFloat } from '../../../utils/roundFloat'
import { CurrencyInputControlledResult } from '../../app/currencies'
import { TaxRate } from '../types'

type VoucherLine = {
  taxRate?: TaxRate
  amount?: CurrencyInputControlledResult
}

export enum VATSummaryPart {
  ExclVAT = 'exclVat',
  VAT = 'vat',
  InclVAT = 'inclVat',
}

type AccumulatedSummary = Record<VATSummaryPart, number>

const initialSummary: AccumulatedSummary = {
  [VATSummaryPart.ExclVAT]: 0,
  [VATSummaryPart.VAT]: 0,
  [VATSummaryPart.InclVAT]: 0,
}

export const getAccumulatedSummaryData = <T extends VoucherLine>(lines: T[]) =>
  lines.reduce((summary, line) => {
    const rate = line?.taxRate?.rate || 0
    const amount = line?.amount?.value || 0
    const inclVat = amount
    const vat = Math.abs(1 - 1 / (1 + rate)) * amount
    const exclVat = amount - vat

    return {
      exclVat: roundFloat(summary.exclVat + exclVat),
      vat: roundFloat(summary.vat + vat),
      inclVat: roundFloat(summary.inclVat + inclVat),
    }
  }, initialSummary)

export const getVATSummaryFromLines = <T extends VoucherLine>(lines: T[], t: TFunction) => {
  const accumulated = getAccumulatedSummaryData<T>(lines)

  const summaryItems: SummaryItem[] = [
    {
      id: VATSummaryPart.ExclVAT,
      text: t('vat_summary.excl_vat'),
      value: accumulated.exclVat,
    },
    {
      id: VATSummaryPart.VAT,
      text: t('vat_summary.vat'),
      value: accumulated.vat,
    },
    {
      id: VATSummaryPart.InclVAT,
      text: t('vat_summary.incl_vat'),
      value: accumulated.inclVat,
    },
  ]

  return summaryItems
}
