import { Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

interface ContentHeaderWrapperProps {
  clickable: boolean
}

export const ContentHeaderWrapper = styled.div<ContentHeaderWrapperProps>`
  display: flex;
  margin-bottom: ${Spacing.L};
  justify-content: space-between;
  gap: ${Spacing.XXXXL};

  ${({ clickable }) =>
    clickable &&
    css`
      user-select: none;

      &:hover {
        cursor: pointer;
      }
    `}
`

export const TitleAndSubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.S};
`

export const SideActionsWrapper = styled.div``
