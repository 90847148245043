import { notify } from '@design-system'

import React, { ReactElement, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { fetchVoucher } from '@modules-deprecated/inbox/services/query-api'
import { NeedInfoReasons } from '@modules-deprecated/inbox/types'
import { getNeedInfoReasonsMessage, getVoucherFiles } from '@modules-deprecated/inbox/utils'

import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { MissingAmount, MissingDate, MissingSupplier, MissingVat, UnreadableImage } from '../cases'
import { MissingDescription } from '../cases/MissingDescription'
import { MissingPaymentMethod } from '../cases/MissingPaymentMethod'
import { MissingInformationLayout } from '../MissingInformationLayout'

const needInfoReasonToComponent: Record<NeedInfoReasons, any> = {
  [NeedInfoReasons.ImageQuality]: UnreadableImage,
  [NeedInfoReasons.IncorrectReceipt]: UnreadableImage,
  [NeedInfoReasons.MissingAmount]: MissingAmount,
  [NeedInfoReasons.MissingDate]: MissingDate,
  [NeedInfoReasons.MissingPaymentMethod]: MissingPaymentMethod,
  [NeedInfoReasons.MissingPurpose]: MissingDescription,
  [NeedInfoReasons.MissingSupplier]: MissingSupplier,
  [NeedInfoReasons.MissingVAT]: MissingVat,
  [NeedInfoReasons.Other]: MissingDescription,
}

interface MissingInformationViewProps {
  voucherId: string
}

export const MissingInformationView = ({ voucherId }: MissingInformationViewProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const {
    data: voucher,
    isLoading: isVoucherLoading,
    isError,
    error,
  } = useQuery(
    [QueryKeys.Voucher, organization?.id, voucherId],
    () =>
      organization?.id
        ? fetchVoucher({
            queryKey: [QueryKeys.Voucher, { organizationId: organization?.id, voucherId }],
          })
        : undefined,
    {
      enabled: !!organization && !!organization.id,
    },
  )

  const needInfoReasonsMessage = useMemo(() => getNeedInfoReasonsMessage(t), [t])
  const isLoading = !organization || isVoucherLoading

  const notification = voucher?.stateReason ? needInfoReasonsMessage[voucher?.stateReason] : undefined
  const files = useMemo(() => (voucher ? getVoucherFiles(voucher) : []), [voucher])
  const Content = useMemo(
    () => (voucher?.stateReason ? needInfoReasonToComponent[voucher?.stateReason] : () => null),
    [voucher],
  )

  useEffect(() => {
    if (isError) {
      console.error(error)
      notify({
        id: NotificationKeys.VoucherInboxFetch,
        message: t('voucher.inbox.toast.voucher_fetched_failed', { voucherId }),
        variant: 'error',
      })
    }
  }, [isError, t, voucherId, error])

  return (
    <MissingInformationLayout
      bookkeeperMessage={voucher?.stateComment || ''}
      isLoading={isLoading}
      files={files}
      notificationMessage={notification}
    >
      <Content isLoading={isLoading} voucher={voucher} />
    </MissingInformationLayout>
  )
}
