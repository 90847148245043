import { maxDateDefault, minDateDefault, NavItem } from '@design-system'

import findIndex from 'lodash/findIndex'
import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { PeriodMode } from '../../../../enums/periodMode'
import { useLocale } from '../../../../hooks/useLocale'
import { PeriodValue } from '../../../../types/periodValue'
import { NavigationDirection } from '../types/navigationDirection'
import { NavigationValue } from '../types/navigationValue'
import { getNavigationItems } from '../utils/getNavigationItems'
import { getPeriodItems } from '../utils/getPeriodItems'
import { getSelectedNavigationItem } from './utils/getSelectedNavigationItem'
import { getValidPeriodMode } from './utils/getValidPeriodMode'

interface PeriodSelectContextType {
  canNavigate: (direction: NavigationDirection) => boolean
  defaultButtonLabel?: ReactNode
  disabledPeriodModes: PeriodMode[]
  isCustomDate?: boolean
  maxDate?: Date
  minDate?: Date
  navigate: (direction: NavigationDirection) => void
  navigationItem?: NavItem<NavigationValue>
  periodItems: NavItem<PeriodValue>[]
  periodMode: PeriodMode
  periodValue?: PeriodValue
  periodValueSaved?: PeriodValue
  setIsCustomDate: (value: boolean) => void
  setPeriodMode: (periodMode: PeriodMode) => void
  setPeriodValue: (periodValue: PeriodValue | undefined) => void
  setPeriodValueSaved: (periodValue: PeriodValue | undefined) => void
  updatePeriodState: (periodValue: PeriodValue | undefined) => void
}

export const PeriodSelectContextDefaultValue: PeriodSelectContextType = {
  canNavigate: () => false,
  defaultButtonLabel: undefined,
  disabledPeriodModes: [],
  isCustomDate: false,
  maxDate: undefined,
  minDate: undefined,
  navigate: () => null,
  navigationItem: undefined,
  periodItems: [],
  periodMode: PeriodMode.Month,
  periodValue: undefined,
  periodValueSaved: undefined,
  setIsCustomDate: () => null,
  setPeriodMode: () => null,
  setPeriodValue: () => null,
  setPeriodValueSaved: () => null,
  updatePeriodState: () => null,
}

const PeriodSelectContext = createContext(PeriodSelectContextDefaultValue)

interface PeriodSelectContextProps {
  children?: ReactNode
  defaultButtonLabel?: ReactNode
  defaultPeriodValueSaved?: PeriodValue
  disabledPeriodModes?: PeriodMode[]
  maxDate?: Date
  minDate?: Date
  periodValueSaved?: PeriodValue
}

export const PeriodSelectContextProvider = ({
  children,
  defaultButtonLabel,
  defaultPeriodValueSaved,
  disabledPeriodModes = [],
  maxDate = maxDateDefault,
  minDate = minDateDefault,
  periodValueSaved: periodValueSavedControlled,
}: PeriodSelectContextProps): ReactElement => {
  const defaultPeriodValue = defaultPeriodValueSaved || periodValueSavedControlled
  const defaultPeriodMode = getValidPeriodMode(defaultPeriodValue, disabledPeriodModes)
  const [periodMode, setPeriodMode] = useState(defaultPeriodMode)
  const [periodValue, setPeriodValue] = useState<PeriodValue | undefined>(defaultPeriodValue)
  const [periodValueSaved, setPeriodValueSaved] = useState<PeriodValue | undefined>(defaultPeriodValue)
  const [navigationItem, setNavigationItem] = useState<NavItem<NavigationValue> | undefined>()
  const [isCustomDate, setIsCustomDate] = useState(!!defaultPeriodValue?.customDate)
  const { code } = useLocale()

  // Items in the navigation
  const navigationItems = useMemo(
    () => getNavigationItems(periodMode, minDate, maxDate),
    [maxDate, minDate, periodMode],
  )

  // Additional protection for the use case when periodMode has been changed but navigationItem not yet (it changes in useEffect)
  const isNavigationItemValid = !!navigationItems.find(({ value }) => value === navigationItem?.value)

  // Items in the list, based on currently selected navigationItem
  const periodItems = useMemo(
    () =>
      getPeriodItems({
        periodMode,
        navigationItem: isNavigationItemValid ? navigationItem : undefined,
        locale: code,
        minDate,
        maxDate,
      }),
    [code, isNavigationItemValid, maxDate, minDate, navigationItem, periodMode],
  )

  const updatePeriodState = useCallback(
    (periodValueUpdated?: PeriodValue) => {
      const validPeriodMode = getValidPeriodMode(periodValueUpdated, disabledPeriodModes)

      setPeriodValue(periodValueUpdated)
      setPeriodValueSaved(periodValueUpdated)
      setPeriodMode(validPeriodMode)
      setIsCustomDate(!!periodValueUpdated?.customDate)

      // we want to refresh currently chosen navigationItem when mode hasn't been change
      // that's because it won't trigger recheckig navigationItem automatically
      if (periodValueUpdated && periodValue?.mode === periodValueUpdated?.mode) {
        const navigationItems = getNavigationItems(periodValueUpdated?.mode, minDate, maxDate)
        const navigationItemSelected = getSelectedNavigationItem(navigationItems, periodValueUpdated)
        setNavigationItem(navigationItemSelected)
      }
    },
    [maxDate, minDate, periodValue?.mode, disabledPeriodModes],
  )

  useEffect(() => {
    if (periodValueSavedControlled !== undefined) {
      updatePeriodState(periodValueSavedControlled)
    }
    // should only trigger on 'periodValueSavedControlled' change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodValueSavedControlled])

  useEffect(() => {
    const navigationItemSelected = getSelectedNavigationItem(navigationItems, periodValue)
    setNavigationItem(navigationItemSelected)
  }, [navigationItems, periodValue])

  const navigate = useCallback(
    (direction: NavigationDirection) => {
      const currentIndex = findIndex(navigationItems, (item) => item.id === navigationItem?.id)
      const nextIndex = direction === 'back' ? currentIndex - 1 : currentIndex + 1
      const nextItem = navigationItems[nextIndex]

      if (nextItem) {
        setNavigationItem(nextItem)
      }
    },
    [navigationItem?.id, navigationItems],
  )

  const canNavigate = useCallback(
    (direction: NavigationDirection) => {
      const currentIndex = findIndex(navigationItems, (item) => item.id === navigationItem?.id)
      const nextIndex = direction === 'back' ? currentIndex - 1 : currentIndex + 1
      const nextItem = navigationItems[nextIndex]

      return !!nextItem
    },
    [navigationItem?.id, navigationItems],
  )

  return (
    <PeriodSelectContext.Provider
      value={{
        canNavigate,
        defaultButtonLabel,
        disabledPeriodModes,
        isCustomDate,
        maxDate,
        minDate,
        navigate,
        navigationItem,
        periodItems,
        periodMode,
        periodValue,
        periodValueSaved,
        setIsCustomDate,
        setPeriodMode,
        setPeriodValue,
        setPeriodValueSaved,
        updatePeriodState,
      }}
    >
      {children}
    </PeriodSelectContext.Provider>
  )
}
export const usePeriodSelectContext = () => useContext(PeriodSelectContext)
