import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const ReceiptsSelectAllActionWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`

export const ReceiptsSelectedReceipts = styled(Text)`
  margin-left: ${Spacing.L};
`
