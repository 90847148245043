import { FontWeight } from '@design-system'

import colors from './colors'

export default {
  columnSelector: {
    IconSettings: {
      width: '14px',
      height: '14px',
      color: colors.primaryGray,
      '&:hover': {
        color: colors.primaryBlue,
      },
    },
    column: {
      width: '200px',
      right: '-21px',
      top: '26px',
      borderRadius: '3px',
      boxShadow: '0px 1px 2px rgba(0,0,0,.2)',
      position: 'absolute',
      background: colors.white,
    },
    columnTitle: {
      fontWeight: FontWeight.Medium,
      fontSize: '14px',
      color: colors.gray5,
      borderBottom: `1px solid ${colors.shade10}`,
    },
    columnElement: {
      color: colors.gray5,
      fontSize: '14px',
      lineHeight: '36px',
      borderBottom: `1px solid ${colors.gray2}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    columnButtonHolder: {
      borderTop: `1px solid ${colors.gray2}`,
    },
  },
  tableColumnFilterElement: {
    background: 'green',
  },
}
