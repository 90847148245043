export const basics = {
  black: '#000000',
  white: '#FFFFFF',
}

export const shades = {
  shade00: 'rgba(0,0,0,.00)',
  shade05: 'rgba(0,0,0,.05)',
  shade10: 'rgba(0,0,0,.10)',
  shade15: 'rgba(0,0,0,.15)',
  shade20: 'rgba(0,0,0,.20)',
  shade25: 'rgba(0,0,0,.25)',
  shade30: 'rgba(0,0,0,.30)',
  shade35: 'rgba(0,0,0,.35)',
  shade40: 'rgba(0,0,0,.40)',
  shade45: 'rgba(0,0,0,.45)',
  shade50: 'rgba(0,0,0,.50)',
  shade55: 'rgba(0,0,0,.55)',
  shade60: 'rgba(0,0,0,.60)',
  shade65: 'rgba(0,0,0,.65)',
  shade70: 'rgba(0,0,0,.70)',
  shade75: 'rgba(0,0,0,.75)',
  shade80: 'rgba(0,0,0,.80)',
  shade85: 'rgba(0,0,0,.85)',
  shade90: 'rgba(0,0,0,.90)',
  shade95: 'rgba(0,0,0,.95)',
}

export const lights = {
  light05: 'rgba(255,255,255,.05)',
  light10: 'rgba(255,255,255,.10)',
  light15: 'rgba(255,255,255,.15)',
  light20: 'rgba(255,255,255,.20)',
  light25: 'rgba(255,255,255,.25)',
  light30: 'rgba(255,255,255,.30)',
  light35: 'rgba(255,255,255,.35)',
  light40: 'rgba(255,255,255,.40)',
  light45: 'rgba(255,255,255,.45)',
  light50: 'rgba(255,255,255,.50)',
  light55: 'rgba(255,255,255,.55)',
  light60: 'rgba(255,255,255,.60)',
  light65: 'rgba(255,255,255,.65)',
  light70: 'rgba(255,255,255,.70)',
  light75: 'rgba(255,255,255,.75)',
  light80: 'rgba(255,255,255,.80)',
  light85: 'rgba(255,255,255,.85)',
  light90: 'rgba(255,255,255,.90)',
  light95: 'rgba(255,255,255,.95)',
}

export const boxShadow = '0 2px 4px 0 rgba(30, 57, 101, 0.03)'
