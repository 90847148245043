import { Color, DefaultValue } from '@design-system'

import styled from '@emotion/styled'
import SimpleBar from 'simplebar-react'

export const GlobalSearchResultsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-height: 45vh;
  flex-direction: column;
  background-color: ${Color.White};
  border-top: 1px solid ${Color.Gray40};
  border-radius: 0 0 ${DefaultValue.BorderRadius} ${DefaultValue.BorderRadius};
`

export const ResultsList = styled(SimpleBar)`
  display: flex;
  flex-direction: column;
  list-style-type: none; // important when using SimpleBar
  overflow: auto;
`
