import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import { BankSettingsModal } from '@modals'
import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { BankIntegrationContextProvider } from '@views/bankIntegration/contexts/bankIntegrationContext'

import { queryClient } from '../../config/queryClient'
import { EmberCloseModalContextProvider, EmberModal } from '../../contexts/emberCloseModalContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

class BankSettingsModalWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @byAttrVal accountId: string
  @registerEvent('closeEmber') closeEmber: Function
  @registerEvent('checkBankIntegration') checkBankIntegration: EmberEventFn | undefined
}

type BankSettingsModalModuleProps = {
  organizationId: string
  accountId: string
  onCloseEmber: EmberModal
  onCheckBankIntegration: EmberEventFn
}

function BankSettingsModalModule({
  accountId,
  organizationId,
  onCloseEmber,
  onCheckBankIntegration,
}: BankSettingsModalModuleProps): ReactElement {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <EmberCloseModalContextProvider onCloseModal={onCloseEmber}>
          <Router>
            <UserOrganizationContextProvider organizationId={organizationId}>
              <UserOrganizationSettingsContextProvider organizationId={organizationId}>
                <CurrentUserContextProvider>
                  <ModalContextProvider>
                    <AccountsContextProvider organizationId={organizationId}>
                      <BankIntegrationContextProvider
                        account={accountId}
                        onCheckBankIntegration={onCheckBankIntegration}
                        organizationId={organizationId}
                      >
                        <BankSettingsModal />
                      </BankIntegrationContextProvider>
                    </AccountsContextProvider>
                  </ModalContextProvider>
                </CurrentUserContextProvider>
              </UserOrganizationSettingsContextProvider>
            </UserOrganizationContextProvider>
          </Router>
        </EmberCloseModalContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const BankSettingsModalCustomElement = createCustomElement(
  BankSettingsModalModule,
  BankSettingsModalWebComponent,
  'element',
)

customElements.get('bank-settings-modal-module') ||
  customElements.define('bank-settings-modal-module', BankSettingsModalCustomElement)

export default BankSettingsModalCustomElement
