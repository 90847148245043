import React, { createContext, ReactElement, ReactNode, useContext, useMemo } from 'react'

import { BillState } from '../../../enums/billState'
import { BillStatus } from '../../../enums/billStatus'
import { useGetBill } from '../../../hooks/useGetBill'
import { GetBillResponseData } from '../../../query-api'
import { getBillStatus } from '../../../utils/getBillStatus'

interface ContextState {
  billData?: GetBillResponseData
  billId: string
  billStatus?: BillStatus
  isFetching: boolean
  isFetchingInvalidation: boolean
  isVoided?: boolean
}

const BillViewContext = createContext<ContextState | undefined>(undefined)

interface BillViewContextProps {
  billId: string
  children?: ReactNode
}

export const BillViewContextProvider = ({ billId, children }: BillViewContextProps): ReactElement => {
  const { data: billData, isLoading, isFetching: isFetchingInvalidation } = useGetBill(billId)

  const { bill } = billData || {}
  const { amount, balance, dueDate, isPaid, state, tax } = bill || {}
  const isVoided = bill?.state === BillState.Voided

  const billStatus = useMemo(
    () =>
      bill?.id
        ? getBillStatus({
            amount,
            balance,
            dueDate: dueDate ? new Date(dueDate) : undefined,
            isPaid,
            normalizePartiallyPaid: true,
            state,
            tax,
          })
        : undefined,
    [amount, balance, bill?.id, dueDate, isPaid, state, tax],
  )

  return (
    <BillViewContext.Provider
      value={{ billStatus, billData, billId, isFetching: isLoading, isFetchingInvalidation, isVoided }}
    >
      {children}
    </BillViewContext.Provider>
  )
}

export const useBillView = () => {
  const context = useContext(BillViewContext)

  if (!context) {
    throw new Error('BillViewContextProvider is missing in the module!')
  }

  return context
}
