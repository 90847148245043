import { notify, useModal } from '@design-system'

import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../../enums/queryKeys'
import { FormComponent, useForm } from '../../../../../../../../../hooks'
import { APIError } from '../../../../../../../../../utils'
import { getErrorMessage } from '../../../../../../../../../utils/getErrorMessage'
import { RulesetFormData } from '../../../../../types/rulesetFormData'
import { useRulesets } from '../../../hooks/useRulesets'
import { useCreateRuleset } from '../hooks/useCreateRuleset'
import { useUpdateRuleset } from '../hooks/useUpdateRuleset'
import { CreateOrEditRulesetForm, createOrEditRulesetFormDefaultValues, getValidationSchema } from '../utils/formData'
import { addPriority } from './utils/addPriority'
import { getRulesFormFromRules } from './utils/getRulesFormFromRules'

interface ContextState {
  Form: FormComponent
  isDisabled: boolean
  isLoading: boolean
  isEditMode: boolean
  modalBodyElement: HTMLDivElement | undefined
  submitForm: () => void
}

const CreateOrEditRulesetModalContext = createContext<ContextState | undefined>(undefined)

interface CreateOrEditRulesetModalContextProps {
  children: ReactNode
  rulesetId?: string
  scrollContainerElement: HTMLDivElement | undefined
  modalId: string
  disabled?: boolean
}

export const CreateOrEditRulesetModalContextProvider = ({
  children,
  rulesetId,
  modalId,
  disabled,
  scrollContainerElement: modalBodyElement,
}: CreateOrEditRulesetModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { rulesets } = useRulesets()

  const { Form, handleSubmit, reset } = useForm({
    defaultValues: createOrEditRulesetFormDefaultValues,
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const { close } = useModal(modalId, {
    onOpen: () => {
      reset()
    },
  })

  useEffect(() => {
    let data: CreateOrEditRulesetForm = {
      name: '',
      description: '',
      fallbackTaxRateId: '',
      abbreviation: '',
      rules: [{ contactType: null, countryGroupId: null, countryId: organization?.countryId || null, taxRateId: null }],
    }

    if (rulesetId) {
      const ruleset = rulesets.find(({ id }) => id === rulesetId)

      if (ruleset) {
        const { name, abbreviation, description, fallbackTaxRateId, rules } = ruleset

        data = {
          name,
          description,
          fallbackTaxRateId: fallbackTaxRateId || undefined,
          abbreviation,
          rules: getRulesFormFromRules(rules),
        }
      }
    }

    reset(data)
  }, [organization?.countryId, reset, rulesetId, rulesets])

  const handleCreateOrEditError = useCallback(
    (error?: APIError) => {
      const message = getErrorMessage(error, 'salesTaxRule') || t('error_saving')
      notify({ id: NotificationKeys.CreateOrEditRulesets, message, variant: 'error' })
    },
    [t],
  )

  const handleCreateOrEditSuccess = useCallback(() => {
    queryClient.invalidateQueries(QueryKeys.VatRatesRulesets)
    close()
  }, [close])

  const { create, isProcessing: isCreateProcessing } = useCreateRuleset({
    onError: handleCreateOrEditError,
    onSuccess: handleCreateOrEditSuccess,
  })

  const { update, isProcessing: isEditProcessing } = useUpdateRuleset({
    onError: handleCreateOrEditError,
    onSuccess: handleCreateOrEditSuccess,
  })

  const isEditMode = !!rulesetId
  const isLoading = isEditMode ? isEditProcessing : isCreateProcessing

  const handleSubmitForm = useCallback(
    ({ name, description, abbreviation, fallbackTaxRateId, rules }: CreateOrEditRulesetForm) => {
      const organizationId = organization?.id

      if (!organizationId) {
        throw new Error('No organization id')
      }

      const data: RulesetFormData = {
        organizationId,
        name,
        abbreviation: abbreviation || '',
        description: description || '',
        fallbackTaxRateId: fallbackTaxRateId || null,
        rules: addPriority(rules),
      }

      if (isEditMode) {
        return update({ id: rulesetId, payload: data })
      }

      return create(data)
    },
    [organization?.id, isEditMode, create, update, rulesetId],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return (
    <CreateOrEditRulesetModalContext.Provider
      value={{
        Form,
        isLoading,
        isDisabled: !!disabled,
        isEditMode,
        modalBodyElement,
        submitForm,
      }}
    >
      <Form>{children}</Form>
    </CreateOrEditRulesetModalContext.Provider>
  )
}

export const useCreateOrEditRulesetModal = () => {
  const context = useContext(CreateOrEditRulesetModalContext)

  if (!context) {
    throw new Error('CreateOrEditRulesetModalContextProvider is missing in the module!')
  }

  return context
}
