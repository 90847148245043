import { useMutation } from 'react-query'

import { APIError } from '../../../../utils'
import { changeUserEmailRequest, ChangeUserEmailResponseData } from '../query-api'

interface UseChangeUserEmailProps {
  onError?: (error?: APIError) => void
  onSettled?: (data: ChangeUserEmailResponseData | undefined, error: APIError | null | undefined) => void
  onSuccess?: (data: ChangeUserEmailResponseData) => void
}

export const useChangeUserEmail = (props?: UseChangeUserEmailProps) => {
  const { onError, onSuccess, onSettled } = props || {}
  const { mutate: changeUserEmail, ...rest } = useMutation(changeUserEmailRequest, {
    onError,
    onSettled,
    onSuccess,
  })

  return { changeUserEmail, ...rest }
}
