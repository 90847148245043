import { notify, Text } from '@design-system'

import { ReactElement, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../enums/notificationKeys'
import { Layout } from '../Layout'
import * as Styled from './styles'

interface InvalidInvitationProps {
  httpStatusCode?: number
}

export const InvalidInvitation = ({ httpStatusCode }: InvalidInvitationProps): ReactElement | null => {
  const { t } = useTranslation()

  const errorMessage = useMemo(() => {
    if (httpStatusCode === 410) {
      return t('organization_invitation.invalid.expired')
    }

    if (httpStatusCode === 404) {
      return t('organization_invitation.invalid.not_found')
    }

    return null
  }, [t, httpStatusCode])

  useEffect(() => {
    if (!errorMessage) {
      notify({ id: NotificationKeys.OrganizationInvitation, message: t('error_saving'), variant: 'error' })
    }
  }, [t, errorMessage])

  if (!errorMessage) {
    return null
  }

  return (
    <Layout>
      <Styled.InvalidInvitationWrapper>
        <Text variant="displayM">{t('organization_invitation.invalid.dagnabbit')}</Text>
        <Text variant="displayM">{errorMessage}</Text>
      </Styled.InvalidInvitationWrapper>
    </Layout>
  )
}
