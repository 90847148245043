import React, { forwardRef, ReactElement, ReactNode, Ref } from 'react'

import * as Styled from './styles'

interface ModalBodyProps {
  children?: ReactNode
  className?: string
}

export const ModalBody = forwardRef(
  ({ children, className }: ModalBodyProps, ref: Ref<HTMLDivElement>): ReactElement => (
    <Styled.ModalBodyWrapper ref={ref} className={className}>
      {children}
    </Styled.ModalBodyWrapper>
  ),
)
