import { BorderRadius, Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../../types/themable'

export const Container = styled.div<Themable>`
  display: flex;
  padding: ${Spacing.XL};
  border-radius: ${BorderRadius.S};
  background-color: ${Color.White};
`
