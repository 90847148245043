import { StatusFormatter } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { billStatusToStatusFormatterVariant } from '../../../../constants/billStatusToStatusFormatterVariant'
import { billStatusToTitleKey } from '../../../../constants/billStatusToTitleKey'
import { BillStatus as BillStatusEnum } from '../../../../enums/billStatus'

interface BillStatusProps {
  status: BillStatusEnum
  title?: string
}

export const BillStatus = ({ status }: BillStatusProps): ReactElement => {
  const { t } = useTranslation()

  const titleKey = billStatusToTitleKey[status]
  const variant = billStatusToStatusFormatterVariant[status]

  return <StatusFormatter variant={variant} title={t(titleKey)} />
}
