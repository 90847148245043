import { ModalConfirmation, ModalConfirmationProps, notify, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../../../enums/notificationKeys'
import { PaymentMethodType } from '../../../../enums/paymentMethodType'
import { useDisablePaymentMethod } from '../../../../hooks/useDisablePaymentMethod'

export const DisableStripeModal = (modalProps: ModalConfirmationProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeDisableStripeModal } = useModal(modalProps.id)
  const { disablePaymentMethod, isProcessing } = useDisablePaymentMethod({
    paymentMethodType: PaymentMethodType.Stripe,
    onSuccess: () => {
      closeDisableStripeModal()
      notify({
        id: NotificationKeys.SubscriptionStripe,
        message: t('integrations.card.stripe.notification.removed'),
        variant: 'success',
      })
    },
    onError: () => {
      notify({
        id: NotificationKeys.SubscriptionStripe,
        message: t('integrations.card.stripe.notification.removed.error'),
        variant: 'error',
      })
    },
  })

  const handleDisableStripeClick = useCallback(() => {
    disablePaymentMethod()
  }, [disablePaymentMethod])

  return (
    <ModalConfirmation
      {...modalProps}
      cancelLabel={t('cancel')}
      danger
      message={t('integrations.card.stripe.disable_modal.body')}
      okLabel={t('integrations.card.stripe.disable_modal.disable_button')}
      okLoading={isProcessing}
      onOk={handleDisableStripeClick}
      title={t('integrations.card.stripe.disable_modal.title')}
    />
  )
}
