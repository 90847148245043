import styled from '@emotion/styled'
import SimpleBar from 'simplebar-react'

import { Asable } from '../../types/asable'

type NavListWrapperProps = Asable

export const NavListWrapper = styled(SimpleBar)<NavListWrapperProps>`
  display: flex;
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  list-style-type: none; // important when using SimpleBar
  overflow: auto;
`
