import { ButtonProtected } from '@components'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as NoBookkeepersIllustration } from '../../../../../../../../assets/images/organization-users-settings/no-bookkeepers.svg'
import { useEmberRouter } from '../../../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../../../enums/emberRoute'
import { Scope } from '../../../../../../../../enums/scope'
import { TrackingContext } from '../../../../../../../../enums/trackingContext'
import { getImageClassName } from '../../../../../../../../utils/getClassName'
import * as Styled from './styles'

export const BookkeepersEmptyView = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()

  const handleFindBookkeeperClick = useCallback(() => {
    navigate(EmberRoute.FindAccountant)
  }, [navigate])

  return (
    <Styled.BookkeepersEmptyView>
      <NoBookkeepersIllustration className={getImageClassName()} />
      <Styled.Content colorVariant="secondary">
        {t('settings.organization.users.bookkeepers_section.empty_content')}
      </Styled.Content>
      <ButtonProtected
        scopes={Scope.UserWriteAccountant}
        trackingContext={TrackingContext.OrganizationSettingsUser}
        onClick={handleFindBookkeeperClick}
      >
        {t('settings.organization.users.bookkeepers_section.find_bookkeeper')}
      </ButtonProtected>
    </Styled.BookkeepersEmptyView>
  )
}
