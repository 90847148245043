import { StatusFormatterVariant } from '@components'

import { QuoteState } from '../../../enums/quoteState'

export const quoteStateToStatusFormatterVariant: Record<
  QuoteState,
  StatusFormatterVariant | [StatusFormatterVariant, StatusFormatterVariant]
> = {
  [QuoteState.All]: 'neutral',
  [QuoteState.Closed]: 'error',
  [QuoteState.Open]: 'primary',
}
