import { Button, Color, DefaultValue, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

// eslint-disable-next-line no-restricted-imports
import { FormItemWrapper } from '../../../../../../design-system/components/FormItem/styles'
import { TitleWrapper } from '../Title/styles'

interface ProfileFormWrapperProps {
  disabled: boolean
}

export const ProfileFormWrapper = styled.div<ProfileFormWrapperProps>`
  padding: ${Spacing.XXXL};
  border-radius: ${DefaultValue.BorderRadius};
  position: relative;
  background-color: ${Color.White};
  box-shadow: 0 2px 2px ${transparentize(0.9, Color.Gray90)};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;

      // Overlay
      &:after {
        content: '';
        opacity: 0.6;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: ${Color.Gray20};
      }
    `}
`

export const FormGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${Spacing.XXXL};
`

export const FormRow = styled.div`
  display: grid;
  margin-bottom: ${Spacing.XL};
  grid-row-gap: ${Spacing.S};

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const LogoSection = styled.section`
  display: flex;
  flex-direction: column;

  ${() => TitleWrapper} {
    margin-bottom: ${Spacing.S};
  }

  ${() => FormItemWrapper} {
    height: 200px;
  }
`

export const FormButton = styled(Button)`
  margin-top: ${Spacing.L};
  margin-left: auto;
`
