import { PlanConfirmationModal as PlanConfirmationModalComponent, ProcessingScreen } from '@components'
import { notify, SkeletonBox, useModal, withModalConditionalRender } from '@design-system'

import isEmpty from 'lodash/isEmpty'
import qs from 'qs'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-use'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { BillingType } from '@modules-deprecated/app/organization/enums/billingType'
import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'
import { useSubscriptionMetrics } from '@views/findAccountant/hooks/useSubscriptionMetrics'

import { ModalId } from '../../../../enums/modalId'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../enums/queryKeys'
import { useOrganizationSubscription, useSegment } from '../../../../hooks'
import { isValidSubscriptionPlan } from '../../../../utils/isValidSubscriptionPlan'
import { removeEmptyKeys } from '../../../../utils/object'
import { SettingsRoute } from '../../enums/settingsRoute'
import { useCouponEligibility } from '../../hooks/useCouponEligibility'
import { useCouponParams } from '../../hooks/useCouponParams'
import { useOrganizationSubscriptionSettings } from './contexts/organizationSubscriptionSettingsContext'
import { ActivateAlertComponent } from './elements/ActivateAlertComponent'
import { SubscriptionPeriodChangeModal } from './elements/SectionPlanDetailsComponent/elements/SubscriptionPeriodChangeModal'
import { SubscriptionOverview } from './elements/SubscriptionOverview'
import { SubscriptionPlansOverlay } from './elements/SubscriptionPlansOverlay'
import { useSubscriptionPlanPrices } from './hooks/useSubscriptionPlanPrices'
import { isPaidPlan } from './plansData'
import { changeSubscriptionPeriod, fetchSubscription, updateUpodiSubscription } from './services/query-api'
import { UpdateSubscriptionMutationParams } from './types'
import { getUpdateSubscriptionPayload, notifySubscriptionUpdateError, notifySubscriptionUpdateSuccess } from './utils'

const PlanConfirmationModal = withModalConditionalRender(PlanConfirmationModalComponent)

function SubscriptionComponent(): ReactElement | null {
  const { t } = useTranslation()
  const history = useHistory()
  const { organization } = useUserOrganization()
  const {
    isLoading: isSubscriptionDataLoading,
    planExpirationDate,
    subscriptionPlan,
    subscriptionPeriod,
    isTrialNoPlanSelected,
    isSubscriptionUpdating,
    billingType,
    defaultActivePaymentMethod,
  } = useOrganizationSubscription()
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>(SubscriptionPlan.Basic)
  const [isYearlyPayment, setIsYearlyPayment] = useState<boolean>(true)
  const [showProcessingScreen, setShowProcessingScreen] = useState(false)
  const [confirmPrice, setConfirmPrice] = useState<number>(0)
  const [recurringPrice, setRecurringPrice] = useState<number>(0)
  const [isPlansSelectionMode, setIsPlansSelectionMode] = useState(false)
  const prevIsPlansSelectionMode = useRef(isPlansSelectionMode)

  const [displaySubscriptionPeriodChangeModal, setDisplaySubscriptionPeriodChangeModal] = useState(false)

  const { monthlyPrice: currentPlanMonthlyPrice, yearlyPricePerMonth: currentPlanYearlyPricePerMonth } =
    useSubscriptionPlanPrices(subscriptionPlan)

  const { hash } = useLocation()

  const hashSearchParamsPart = (hash || '').split('?')[1]
  const params = qs.parse(hashSearchParamsPart, {
    ignoreQueryPrefix: true,
  })

  const {
    promocode,
    payment_cancelled: paymentCancelled,
    payment_failed: paymentFailed,
    payment_successful: paymentSuccessful,
    plan: signupCouponPlan,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    utm_source,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    utm_medium,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    utm_campaign,
  } = params

  const {
    plansSelectionMode: initialPlansSelectionMode = false,
    organizationId,
    onEditCardSubscription,
    onEditSubscription,
    onFetchOrganization,
  } = useOrganizationSubscriptionSettings()

  const {
    handleCouponEligibilityCheck,
    setDiscountPercent,
    totalPrice,
    totalRecurringPrice,
    calculatePriceWithCoupon,
  } = useCouponEligibility({
    organizationId,
    selectedPeriod: isYearlyPayment ? SubscriptionPeriod.Yearly : SubscriptionPeriod.Monthly,
    selectedPlan,
    confirmPrice,
    recurringPrice,
    signupCouponPlan: isValidSubscriptionPlan(signupCouponPlan) ? signupCouponPlan : undefined,
  })

  const { open: openPlanConfirmationModal, close: closeConfirmationModal } = useModal(ModalId.PlanConfirmationModal, {
    onClose: () => setDiscountPercent(0),
  })

  const { track } = useSegment()

  const queryClient = useQueryClient()

  const { data: subscription, isLoading: loadingSubscription } = useQuery(
    [QueryKeys.OrganizationSubscription, organizationId],
    () => fetchSubscription(organizationId),
    {
      enabled: !!organizationId,
    },
  )

  const { data: subscriptionMetrics, isLoading: loadingSubscriptionMetrics } = useSubscriptionMetrics()

  const hasPaymentMethod = () => !isEmpty(defaultActivePaymentMethod)

  const updateSubscriptionMutation = useMutation(
    ({ plan, coupon }: UpdateSubscriptionMutationParams) =>
      updateUpodiSubscription(
        organizationId,
        getUpdateSubscriptionPayload(organizationId, plan, isYearlyPayment, coupon),
      ),
    {
      onSuccess: (data, { plan }) => {
        queryClient.invalidateQueries(QueryKeys.OrganizationSubscription)
        queryClient.invalidateQueries(QueryKeys.OrganizationSubscriptionInfo)

        track('Subscription Order Summary Completed (FE)', {
          context: 'subscription_page',
          plan,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          plan_period: isYearlyPayment ? 'annual' : 'monthly',
        })

        setSelectedPlan(plan)
        handleGoToMainScreen()

        notifySubscriptionUpdateSuccess(t)
      },
      onError: () => {
        notifySubscriptionUpdateError(t)
      },
      onSettled: () => {
        setShowProcessingScreen(false)
      },
    },
  )

  const changeSubscriptionPeriodMutation = useMutation(
    (period: SubscriptionPeriod) => changeSubscriptionPeriod(organizationId, period),
    {
      onError: () => {
        setDisplaySubscriptionPeriodChangeModal(false)
        notify({
          id: NotificationKeys.OrganizationSubscriptionPeriodChange,
          message: t('organization_subscription.period_change.error'),
          variant: 'error',
        })
      },
    },
  )

  const handlePlanSelect = useCallback(
    (plan: SubscriptionPlan, period?: SubscriptionPeriod) => {
      setSelectedPlan(plan)
      setIsYearlyPayment(period === SubscriptionPeriod.Yearly)
      openPlanConfirmationModal()
      track('Subscription Order Summary Viewed (FE)', {
        context: 'subscription_page',
        plan,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        plan_period: period,
      })
    },
    [openPlanConfirmationModal, track],
  )

  useCouponParams({ params, organizationId, handlePlanSelect })

  const isInUpdatePeriodState = useMemo(() => {
    return (
      (subscriptionPeriod === SubscriptionPeriod.Yearly && !displaySubscriptionPeriodChangeModal) ||
      (subscriptionPeriod === SubscriptionPeriod.Monthly && displaySubscriptionPeriodChangeModal)
    )
  }, [subscriptionPeriod, displaySubscriptionPeriodChangeModal])

  useEffect(() => {
    if (subscription && !isEmpty(subscription.organization)) {
      onFetchOrganization({ detail: subscription.organization })
    }
    // only trigger onFetchOrganization on subscription data change, not the props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription])

  // If route redirects straight to plans selection screen
  useEffect(() => {
    setIsPlansSelectionMode(initialPlansSelectionMode)
  }, [initialPlansSelectionMode])

  // Segment event for viewing plans selection mode
  useEffect(() => {
    if (isPlansSelectionMode && !prevIsPlansSelectionMode.current && !!organization?.id && !!track) {
      const utmParams = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        utm_source,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        utm_medium,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        utm_campaign,
      }

      track(
        'Subscription Plan Select Viewed (FE)',
        removeEmptyKeys({ ...utmParams, type: isPlansSelectionMode ? 'full_screen' : 'in_page' }),
      )
    }
  }, [isPlansSelectionMode, organization?.id, track, utm_campaign, utm_medium, utm_source])

  // update previous state of plans selection mode flag to prevent event from firing multiple times
  useEffect(() => {
    prevIsPlansSelectionMode.current = isPlansSelectionMode
  }, [isPlansSelectionMode])

  const isPaymentCancelled = paymentCancelled === '1'
  const isPaymentFailed = paymentFailed === '1'
  const isPaymentSuccessful = paymentSuccessful === '1'

  useEffect(() => {
    if (isPaymentCancelled) {
      notify({
        variant: 'info',
        message: t('organization_subscription.payment_cancelled'),
        id: NotificationKeys.SubscriptionPaymentCancelled,
      })
    } else if (isPaymentFailed) {
      notify({
        variant: 'warning',
        message: t('organization_subscription.payment_failed'),
        id: NotificationKeys.SubscriptionPaymentFailed,
      })
    } else if (isPaymentSuccessful) {
      notify({
        variant: 'success',
        message: t('organization_subscription.card_added'),
        id: NotificationKeys.SubscriptionPaymentSuccessful,
      })
    }
  }, [isPaymentCancelled, isPaymentFailed, isPaymentSuccessful, t])

  const isViewLoading =
    isSubscriptionDataLoading ||
    loadingSubscription ||
    loadingSubscriptionMetrics ||
    !subscription ||
    !subscriptionMetrics

  const handleGoToMainScreen = () => {
    history.push(SettingsRoute.OrganizationSubscription)
  }

  const handleEditSubscriptionRequest = (plan: SubscriptionPlan, price: number, coupon?: string) => {
    onEditSubscription({
      detail: {
        plan,
        period: isYearlyPayment ? SubscriptionPeriod.Yearly : SubscriptionPeriod.Monthly,
        price,
        coupon,
        callback: (error) => {
          queryClient.invalidateQueries(QueryKeys.OrganizationSubscription)
          queryClient.invalidateQueries(QueryKeys.OrganizationSubscriptionInfo)
          closePlanConfirmation()
          setShowProcessingScreen(false)

          track('Subscription Order Summary Completed (FE)', {
            context: 'subscription_page',
            plan,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            plan_period: isYearlyPayment ? 'annual' : 'monthly',
          })

          if (error) {
            notifySubscriptionUpdateError(t)
          } else {
            notifySubscriptionUpdateSuccess(t)
          }
        },
      },
    })
  }

  const handleNonPaidPlan = (plan: SubscriptionPlan, coupon?: string) => {
    updateSubscriptionMutation.mutate({ plan, coupon })
    closePlanConfirmation()
  }

  const handleEditSubscription = {
    [BillingType.External]: (plan: SubscriptionPlan, totalPrice: number, coupon?: string) => {
      if (isPaidPlan(plan) && (!subscription?.organization.activationDate || !hasPaymentMethod())) {
        handleEditSubscriptionRequest(plan, totalPrice, coupon)
      } else {
        handleNonPaidPlan(plan, coupon)
      }
    },
    [BillingType.Own]: (plan: SubscriptionPlan, _totalPrice: number, coupon?: string) => {
      // from this legacy-billing-system component, we always need to add new payment method when plan is paid
      // if user already has a payment method in Upodi, this component is not used anymore (then see react-app/src/modules/organizationSubscription/Subscription.tsx)
      if (isPaidPlan(plan)) {
        handleEditSubscriptionRequest(plan, calculatePriceWithCoupon(confirmPrice), coupon)
      } else {
        handleNonPaidPlan(plan, coupon)
      }
    },
  }

  const handleConfirmPlanChange = (coupon?: string) => {
    setShowProcessingScreen(true)
    handleEditSubscription[billingType](selectedPlan, calculatePriceWithCoupon(confirmPrice), coupon)
  }

  const closePlanConfirmation = () => {
    closeConfirmationModal()
    setDiscountPercent(0)
  }

  const handleSubscriptionPeriodUpdate = () => {
    const switchPeriod =
      subscriptionPeriod === SubscriptionPeriod.Yearly ? SubscriptionPeriod.Monthly : SubscriptionPeriod.Yearly

    changeSubscriptionPeriodMutation.mutate(switchPeriod)

    setTimeout(() => {
      window.location.reload()
    }, 10000)
  }

  const toggleSubscriptionPeriod = useCallback(() => {
    setDisplaySubscriptionPeriodChangeModal(!displaySubscriptionPeriodChangeModal)
  }, [displaySubscriptionPeriodChangeModal])

  const handlePriceChange = useCallback((confirmPrice: number, recurringPrice: number) => {
    setConfirmPrice(confirmPrice)
    setRecurringPrice(recurringPrice)
  }, [])

  const shouldShowActivateAlert = isTrialNoPlanSelected && !hasPaymentMethod()
  const shouldShowSubscriptionOverview = !isViewLoading && !isPlansSelectionMode
  const shouldShowPlansOverlay = !isViewLoading && isPlansSelectionMode

  return (
    <>
      {shouldShowActivateAlert && <ActivateAlertComponent trialEndDate={planExpirationDate || ''} />}
      {(isSubscriptionUpdating || showProcessingScreen) && (
        <ProcessingScreen>{t('organization_subscription.subscription_update.in_progress')}</ProcessingScreen>
      )}
      {isViewLoading && <SkeletonBox height={385} fullWidth />}
      {shouldShowSubscriptionOverview && (
        <SubscriptionOverview
          isInUpdatePeriodState={isInUpdatePeriodState}
          isLoading={isViewLoading}
          onEditCardSubscription={onEditCardSubscription}
          onSubscriptionPeriodToggle={toggleSubscriptionPeriod}
          subscriptionMetrics={subscriptionMetrics}
          subscriptionPlans={subscription?.plans}
        />
      )}
      {shouldShowPlansOverlay && <SubscriptionPlansOverlay onPlanSelect={handlePlanSelect} />}
      <PlanConfirmationModal
        applyUpsellCoupon={promocode as string}
        id={ModalId.PlanConfirmationModal}
        onConfirm={handleConfirmPlanChange}
        onCouponEligibilityCheck={(coupon) => handleCouponEligibilityCheck(coupon)}
        onPriceChange={handlePriceChange}
        planText={t(`organization_subscription.${selectedPlan}`)}
        price={totalPrice}
        recurringPrice={totalRecurringPrice}
        subscriptionPeriod={isYearlyPayment ? SubscriptionPeriod.Yearly : SubscriptionPeriod.Monthly}
        subscriptionPlan={selectedPlan}
      />
      {displaySubscriptionPeriodChangeModal && (
        <SubscriptionPeriodChangeModal
          subscriptionPlan={subscriptionPlan}
          subscriptionPeriod={
            subscriptionPeriod === SubscriptionPeriod.Yearly ? SubscriptionPeriod.Monthly : SubscriptionPeriod.Yearly
          }
          price={
            subscriptionPeriod === SubscriptionPeriod.Yearly ? currentPlanMonthlyPrice : currentPlanYearlyPricePerMonth
          }
          onConfirm={handleSubscriptionPeriodUpdate}
          onClose={() => setDisplaySubscriptionPeriodChangeModal(!displaySubscriptionPeriodChangeModal)}
        />
      )}
    </>
  )
}

export default SubscriptionComponent
