import { TFunction } from 'i18next'
import isNumber from 'lodash/isNumber'

import { TransactionOriginatorType } from '../../../enums/transactionOriginatorType'
import { TransactionSide } from '../../../types/transactionSide'
import { transactionOriginatorTypeToTranslationKey } from '../constants/transactionOriginatorTypeToTypeKey'

export const getTransactionTypeFromOriginatorReference = (
  amount: number | null,
  originatorReference: string | null,
  side: TransactionSide,
  t: TFunction,
): string | undefined => {
  const tKey = originatorReference?.split(':')[0]

  if (!tKey) {
    return undefined
  }

  if (tKey === TransactionOriginatorType.BankPayment && !!side && isNumber(amount)) {
    if (side === 'credit') {
      return t('transactions.table.type.bank_payment.ingoing')
    } else {
      return t('transactions.table.type.bank_payment.outgoing')
    }
  }

  return t(transactionOriginatorTypeToTranslationKey[tKey])
}
