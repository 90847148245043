import { BorderRadius, Color, ComponentsGroup, getDefaultTransition, Spacing } from '@design-system'

import styled from '@emotion/styled'
import transparentize from 'polished/lib/color/transparentize'

import { WidgetSize } from '../../enums/widgetSize'

interface WrapperProps {
  autoHeight?: boolean
  size?: WidgetSize
  highlighted?: boolean
  isHidden: boolean
  isLoading: boolean
  backgroundColor: Color
}

export const WidgetWrapper = styled.div<WrapperProps>`
  display: flex;
  position: relative;
  width: 100%;
  height: ${({ autoHeight }) => (autoHeight ? 'auto' : '270px')};
  padding: ${({ isLoading }) => (isLoading ? '0' : `${Spacing.L} ${Spacing.XL} ${Spacing.XL}`)};
  border-radius: ${BorderRadius.L};
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ isHidden }) => (isHidden ? 0.5 : 1)};
  box-shadow: ${({ highlighted }) =>
    highlighted ? `0 10px 70px ${transparentize(0.65, Color.Gray90)}` : `0 2px 1px ${Color.Gray30}`};
  ${getDefaultTransition('box-shadow')};
`

export const Header = styled.header`
  display: flex;
  min-height: 40px;
  margin-bottom: ${Spacing.L};
  position: relative;
  align-items: center;
  justify-content: space-between;
`
export const SideActions = styled.aside`
  display: flex;
  padding-left: ${Spacing.L};
  flex-grow: 1;
  justify-content: flex-end;
`

export const TitleComponentsGroup = styled(ComponentsGroup)`
  align-items: baseline;
`
