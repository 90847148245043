import { isBefore } from 'date-fns'
import { useMemo } from 'react'

import { ExternalInvoicesSyncStatus } from '@features/externalInvoicesSync/enums/externalInvoicesSyncStatus'
import { useFetchExternalInvoicesSync } from '@features/externalInvoicesSync/hooks/useFetchExternalInvoicesSync'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { OrganizationFeature, useOrganizationFeature } from '@views/settings/routes/OrganizationFeaturesSettings'

import { useOrganizationBrand } from '../../../hooks/useOrganizationBrand'

const latestAllowedCreatedTime = new Date('2024-08-15')

export const useShouldDisplayExternalInvoicesSyncInitiateAction = () => {
  const { organization } = useUserOrganization()
  const { isBrandAgeras } = useOrganizationBrand()
  const { syncData } = useFetchExternalInvoicesSync()
  const { hasFeature } = useOrganizationFeature()

  const hasExternalInvoicesSyncFeature = hasFeature(OrganizationFeature.ExternalInvoicesSync)

  const shouldDisplayExternalInvoicesSyncInitiateAction = useMemo(() => {
    const isSyncNotInitiatedYet = syncData?.syncStatus === ExternalInvoicesSyncStatus.NotInitiated
    const organizationCreatedTime = organization?.createdTime
    const isCountryFrance = organization?.countryId === 'FR'
    const isOrganizationCreatedBeforeLatestAllowedTime = organizationCreatedTime
      ? isBefore(new Date(organizationCreatedTime), latestAllowedCreatedTime)
      : false

    return (
      hasExternalInvoicesSyncFeature &&
      isSyncNotInitiatedYet &&
      isOrganizationCreatedBeforeLatestAllowedTime &&
      isCountryFrance &&
      isBrandAgeras
    )
  }, [
    isBrandAgeras,
    organization?.countryId,
    organization?.createdTime,
    syncData?.syncStatus,
    hasExternalInvoicesSyncFeature,
  ])

  return { shouldDisplayExternalInvoicesSyncInitiateAction }
}
