import React, { ElementType, Fragment, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface TranslateProps {
  as?: ElementType
  count?: number
  value: string
}

export const Translate = ({ as: Component = Fragment, count = 1, value, ...rest }: TranslateProps): ReactElement => {
  const { t } = useTranslation()
  return <Component {...rest}>{t(value, { count })}</Component>
}
