import { Flex, rwd, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const PurchaseCardsGroup = styled(Flex)`
  width: 100%;
  justify-content: space-evenly;
  gap: ${Spacing.XXL};

  ${rwd.equalOrLessThan('medium')} {
    flex-direction: column;
    align-items: center;
  }
`
