import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconRoles = ({
  color = '#ffdd19',
  color2 = '#fff',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  isOutlined,
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-roles')} {...rest}>
    <svg width={width} height={height} x="0px" y="0px" viewBox="0 0 339.65 349.85">
      <path
        d="M88.25,335.6c.94-16,1.77-30.07,2.59-44.19.09-1.6.17-3.21.18-4.82,0-5.65-1.85-8.1-7.47-9.58C68.7,273.1,53.84,269.24,39,265.3c-7.55-2-9.3-7.1-4.38-13.14,9.5-11.66,19.11-23.24,28.72-34.82,4.59-5.53,4.66-8.86.17-14.3Q49,185.51,34.6,167.92C30.1,162.42,32,157,39,155.17q22.29-5.86,44.59-11.67c6.35-1.66,8.5-4.78,8.1-11.28-.75-11.92-1.33-23.85-2-35.78-.18-3.21-.58-6.42-.62-9.63-.1-6.69,4.47-9.94,10.9-7.58,12,4.43,24,9.07,35.92,13.61,2.5,1,5,1.9,7.52,2.83,6.14,2.29,9.22,1.34,12.77-4,8.28-12.53,16.49-25.1,24.77-37.63,4.63-7,10-7,14.64,0Q207.85,72.54,220,91.11c3.89,6,6.86,7,13.51,4.47q20.89-7.92,41.78-15.89c8.29-3.16,13.08.54,12.11,9.17-.52,4.56-.65,9.17-.9,13.75-.54,10.21-1.15,20.42-1.53,30.63-.23,6.26,1.76,8.59,8,10.24,14.86,3.91,29.73,7.76,44.57,11.74,7.48,2,9.07,7.26,4.15,13.24q-14.55,17.72-29.13,35.39c-3.93,4.79-3.86,7.82.06,12.57q14.51,17.52,28.95,35.09c5.24,6.39,3.47,11.65-4.62,13.79q-22.11,5.86-44.24,11.64c-5.81,1.52-8,4-7.67,9.74.77,15.15,1.6,30.3,2.63,45.44.52,7.76-4.53,11.73-12,8.93-14.26-5.38-28.48-10.85-42.74-16.24-6.32-2.39-9-1.51-12.66,4q-12.4,18.81-24.78,37.63c-4.55,6.89-10.5,6.86-15.06-.07q-12.48-19-24.94-37.92c-3.22-4.91-6.31-6-11.88-3.86-14.7,5.54-29.36,11.18-44.08,16.65C93.34,343.57,88.16,340,88.25,335.6Z"
        transform="translate(-18.35 -35.25)"
        fill={isOutlined ? 'transparent' : coloring(color)}
        stroke={coloring(color2)}
        strokeMiterlimit="10"
        strokeWidth="27"
      />
    </svg>
  </Box>
)
