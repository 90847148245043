import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { Spacing } from '../../enums/spacing'
import { DividerTextOrientation } from './types/dividerTextOrientation'

interface DividerWrapperProps {
  dashed: boolean
  orientation: DividerTextOrientation
  dividerColor: Color
}

type DividerCommonProps = Pick<DividerWrapperProps, 'dashed' | 'dividerColor'>

const getPseudoElementCommonStyles = ({ dashed, dividerColor }: DividerCommonProps) => css`
  content: '';
  border-bottom: 1px ${dashed ? 'dashed' : 'solid'} ${dividerColor};
`

export const DividerWrapper = styled.div<DividerWrapperProps>`
  display: flex;
  align-items: center;
  text-align: center;

  // Create the divider lines using pseudo-elements
  &:before {
    ${({ dashed, dividerColor }) => getPseudoElementCommonStyles({ dashed, dividerColor })}
    flex: ${({ orientation }) => (orientation === 'right' ? '10' : '1')};
  }

  &:after {
    ${({ dashed, dividerColor }) => getPseudoElementCommonStyles({ dashed, dividerColor })}
    flex: ${({ orientation }) => (orientation === 'left' ? '10' : '1')};
  }
`

export const TextContainer = styled.div`
  margin: 0 ${Spacing.M};
`
