import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { createDebtCollectionAccount } from '../../../query-api'

interface UseCreateDebtCollectionAccountProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useCreateDebtCollectionAccount = (props?: UseCreateDebtCollectionAccountProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate: create,
    isLoading: isProcessing,
    ...rest
  } = useMutation(createDebtCollectionAccount, { onSuccess, onError })

  return { create, isProcessing, ...rest }
}
