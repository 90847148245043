import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { fetchDebtCollectionTerms } from '../../../query-api'

export const useDebtCollectionTerms = () => {
  const { data, ...rest } = useQuery([QueryKeys.DebtCollectionTerms], () => fetchDebtCollectionTerms())

  return { terms: data, ...rest }
}
