import { getRequest } from '../../../utils'
import { PaymentMethod } from '../organization/types/paymentMethod'
import { SubscriptionInfo } from '../organization/types/subscriptionInfo'
import { Card } from './types'

export const fetchCards = async (organizationId: string): Promise<Card[]> => {
  return getRequest(`/quickpay/organizations/${organizationId}/subscriptions`)
}

export const fetchPaymentMethod = (organizationId: string): Promise<PaymentMethod> => {
  return getRequest(`/integrations/billing/${organizationId}/paymentmethod/default`)
}

export const fetchSubscriptionInfo = (organizationId: string): Promise<SubscriptionInfo> => {
  return getRequest(`/integrations/billing/${organizationId}/subscription`)
}
