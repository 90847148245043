/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use 'useForm' hook instead
 */
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, BoxProps } from 'rebass'
import { AnyObjectSchema } from 'yup'

export type FormSubmittedData = {
  id?: string
  [key: string]: any
}

export type FormsProps = BoxProps & {
  defaultValues?: {}
  onSubmit?: Function
  resetForm?: boolean
  validationSchema?: AnyObjectSchema
}

export const Form = ({ children, defaultValues, onSubmit, resetForm, validationSchema, ...rest }: FormsProps) => {
  const form = useForm({
    defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  })

  const handleSubmit = (data: any) => {
    if (onSubmit) {
      onSubmit(data)
    }
  }

  if (resetForm) {
    return (
      <Box as="form" {...rest}>
        {children}
      </Box>
    )
  }

  return (
    <FormProvider {...form}>
      <Box as="form" onSubmit={form.handleSubmit(handleSubmit)} variant="form" {...rest}>
        {children}
      </Box>
    </FormProvider>
  )
}
