export const formatDate = (date?: Date): string | undefined => {
  if (date) {
    const dateToConvert = new Date(date)
    const isoDate = new Date(dateToConvert.getTime() - dateToConvert.getTimezoneOffset() * 60000)
      .toISOString()
      .split('T', 1)[0]
    return isoDate
  }
  return undefined
}
