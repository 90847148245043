import { DeepPartial, KeepStateOptions, UnpackNestedValue, UseFormReturn } from 'react-hook-form'
import { AnyObjectSchema, Asserts } from 'yup'

import { fixDefaultValues } from './fixDefaultValues'

export const reset =
  <T extends AnyObjectSchema>(form: UseFormReturn<Asserts<T>, any>) =>
  (
    values?: UnpackNestedValue<DeepPartial<Asserts<T>>> | UnpackNestedValue<Asserts<T>> | undefined,
    keepStateOptions?: KeepStateOptions,
  ) => {
    form.reset(values, keepStateOptions)

    if (!keepStateOptions?.keepDefaultValues) {
      fixDefaultValues(form)
    }
  }
