import { ModuleLayout } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { InvoicesRoute } from '../../enums/invoicesRoute'
import { EInvoicesSectionMessage } from './elements/EInvoicesSectionMessage'
import { InvoicesSideActions } from './elements/InvoicesSideActions.tsx/InvoicesSideActions'
import { InvoicesSummary } from './elements/InvoicesSummary'
import { InvoicesTable } from './elements/InvoicesTable'
import { InvoicesTableActions } from './elements/InvoicesTableActions'
import { useInvoicesListFilters } from './hooks/useInvoicesListFilters'
import { useIsInvoicesListEmpty } from './hooks/useIsInvoicesListEmpty'

export const InvoicesList = (): ReactElement => {
  const { t } = useTranslation()
  const isInvoicesListEmpty = useIsInvoicesListEmpty()
  const [{ contactId, contactName }] = useInvoicesListFilters()

  const title = useMemo(
    () => (contactId && contactName ? t('invoicesTo', { contactName }) : t('invoices')),
    [contactId, contactName, t],
  )

  if (isInvoicesListEmpty) {
    return <Redirect to={InvoicesRoute.ListEmpty} />
  }

  return (
    <ModuleLayout sideActions={<InvoicesSideActions />} title={title}>
      <EInvoicesSectionMessage />
      <InvoicesSummary />
      <InvoicesTableActions />
      <InvoicesTable />
    </ModuleLayout>
  )
}
