import { ModuleLayout, Space, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { RolesSideActions } from './elements/RolesSideActions'
import { RolesTable } from './elements/RolesTable'

export const UmbrellaRoles = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <ModuleLayout title={t('umbrella_roles.title')} sideActions={<RolesSideActions />}>
      <Text>{t('umbrella_roles.intro')}</Text>
      <Space size="xl" />
      <RolesTable />
    </ModuleLayout>
  )
}
