import { TranslatedItem } from '@components'

import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

export const getPlanFeatures = (type: SubscriptionPlan): TranslatedItem[] => {
  const featuresByType = {
    [SubscriptionPlan.Free]: [
      { tKey: 'subscription.plan.all_features.accounting_system' },
      { tKey: 'subscription.plan.all_features.invoicing_simple' },
      { tKey: 'subscription.plan.all_features.vat_reporting' },
      { tKey: 'subscription.plan.all_features.technical_email_and_chat' },
      { tKey: 'subscription.plan.all_features.receipt_upload' },
    ],
    [SubscriptionPlan.BasicPaid]: [
      { tKey: 'subscription.plan.all_features.ad_free_invoicing' },
      { tKey: 'subscription.plan.all_features.full_bookkeeping_support' },
      { tKey: 'subscription.plan.all_features.auditor_access' },
    ],
    [SubscriptionPlan.Complete]: [
      { tKey: 'subscription.plan.all_features.accounting_check' },
      { tKey: 'subscription.plan.all_features.annual_accounts' },
      { tKey: 'subscription.plan.all_features.skip_the_queue' },
    ],
    [SubscriptionPlan.Plus]: [
      { tKey: 'subscription.plan.all_features.advanced_ai_features' },
      { tKey: 'subscription.plan.all_features.unlimited_integrations' },
      { tKey: 'subscription.plan.all_features.full_accounting_support' },
      { tKey: 'subscription.plan.all_features.payroll_system' },
    ],
  }

  return featuresByType[type]
}
