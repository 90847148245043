import { amountToDisplayValue, getDisplayDate, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useMultipleBills } from '../../contexts/multipleBillsContext'
import * as Styled from './styles'

export const BillSummary = (): ReactElement => {
  const { t } = useTranslation()
  const { currencyId, contact, paymentDate } = useBillReconciliation()
  const { selectedBillsAmountsSum: paymentAmount } = useMultipleBills()

  const vendorName = contact?.name
  const shouldShowPaymentAmount = !!paymentAmount && !!currencyId

  return (
    <Styled.BillSummaryWrapper>
      <Styled.BillSummaryItem>
        <Text variant="micro" colorVariant="secondary">
          {t('bill.payment_reconcile.vendor')}
        </Text>
        <Text>{vendorName}</Text>
      </Styled.BillSummaryItem>
      <Styled.BillSummaryItem>
        <Text variant="micro" colorVariant="secondary">
          {t('bill.payment_reconcile.payment_date')}
        </Text>
        <Text>{paymentDate ? getDisplayDate(new Date(paymentDate)) : '-'}</Text>
      </Styled.BillSummaryItem>
      <Styled.BillSummaryItem alignRight>
        <Text variant="micro" colorVariant="secondary">
          {t('incl_vat')}
        </Text>
        <Text>{shouldShowPaymentAmount ? amountToDisplayValue(paymentAmount, false, currencyId) : '-'}</Text>
      </Styled.BillSummaryItem>
    </Styled.BillSummaryWrapper>
  )
}
