import styled from '@emotion/styled'

import { AccountSelector } from '@modules-deprecated/app'

export const AllAccountSelector = styled(AccountSelector)`
  display: flex;
  margin-bottom: 0;
  align-items: center;

  label {
    width: auto;
    min-width: 103px;
    margin-right: 15px;
    margin-bottom: 0;
    justify-content: flex-end;
  }
`
