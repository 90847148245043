import React, { useCallback } from 'react'
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import { Box } from 'rebass'

import { reactClass } from '../../../utils'
import { BankLine as BankLineType } from '../types'
import { BankLine } from './BankLine'
import { DroppableContainer } from './DroppableContainer'
import { Placeholder } from './Placeholder'
import { DroppableAreaProps } from './types'

export type DroppableBankLinesProps = DroppableAreaProps<BankLineType> & {
  isApproved: boolean
}

export const DroppableBankLines = ({ collection = [], id, isApproved, type }: DroppableBankLinesProps) => {
  const droppableChild = useCallback(
    (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
      <DroppableContainer
        flex="auto"
        className={reactClass('droppable-banklines')}
        ref={provided.innerRef}
        {...provided.droppableProps}
        {...snapshot}
      >
        {collection.map((bankLine, index) => (
          <BankLine
            key={bankLine.id}
            index={index}
            isApproved={isApproved}
            collection={collection}
            withUngroup={collection.length > 1}
            bankLine={bankLine}
            {...snapshot}
          />
        ))}
        {snapshot.draggingFromThisWith && <Placeholder id="placeholder-from-origin" index={0} mb="0px" />}
        <Box display="none">{provided.placeholder}</Box>
      </DroppableContainer>
    ),
    [collection, isApproved],
  )

  return (
    <Droppable isDropDisabled={isApproved} droppableId={id} type={type}>
      {droppableChild}
    </Droppable>
  )
}
