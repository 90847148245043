import { InvoiceStatus } from './../../../enums/invoiceStatus'

export const invoiceStatusToTitleKey: Record<InvoiceStatus, string> = {
  [InvoiceStatus.All]: 'invoices.category.all',
  [InvoiceStatus.Draft]: 'invoices.category.draft',
  [InvoiceStatus.Overdue]: 'invoices.category.overdue',
  [InvoiceStatus.Paid]: 'invoices.category.paid',
  [InvoiceStatus.PartiallyPaid]: 'invoices.category.partially_paid',
  [InvoiceStatus.Unpaid]: 'invoices.category.unpaid',
}
