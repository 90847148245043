import { Color } from '../enums/color'
import { commonTheme } from './_commonTheme'

export const lightTheme = {
  ds: {
    colors: {
      base: {
        background: Color.Gray20,
        textPrimary: Color.DeepSeaGreen,
        textSecondary: Color.Gray90,
        icon: Color.Gray90,
      },
      pricing: {
        accent: Color.Green,
      },
    },
    ...commonTheme,
  },
}
