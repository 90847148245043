import { Color } from '../../../enums/color'
import { IconName } from '../../Icon'
import { Variant } from '../types/variant'

interface AlertStyles {
  color: Color
  bgColor: Color
  icon: IconName
}

export const variantStyles: Record<Variant, AlertStyles> = {
  error: {
    color: Color.White,
    bgColor: Color.Red,
    icon: 'exclamationMarkCircle',
  },
  warning: {
    color: Color.Gray200,
    bgColor: Color.Yellow,
    icon: 'exclamationMarkCircle',
  },
  info: {
    color: Color.DeepSeaGreen,
    bgColor: Color.Lavender,
    icon: 'iSignCircle',
  },
  success: {
    color: Color.DeepSeaGreen,
    bgColor: Color.Green,
    icon: 'checkCircle',
  },
}
