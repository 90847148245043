import { amountToDisplayValue, Color, Text } from '@design-system'

import React, { memo, ReactElement, useCallback } from 'react'

import { Side } from '../../../enums/side'

interface AmountFormatterProps {
  value: number
  side?: Side
  colorize?: boolean
  currencyId?: string
}

export const AmountFormatter = memo(
  ({ colorize, value, side, currencyId = '' }: AmountFormatterProps): ReactElement => {
    const displayValue = `${amountToDisplayValue(value)} ${currencyId}`
    const displayValueFormatted = side && side === Side.Credit ? `-${displayValue}` : displayValue

    const getColor = useCallback(() => {
      if (!colorize) {
        return
      }

      const displayValueFloat = parseFloat(displayValueFormatted)

      if (Math.abs(displayValueFloat) === 0) {
        return
      }

      return displayValueFloat > 0 ? Color.Green : Color.Red
    }, [colorize, displayValueFormatted])

    return (
      <Text as="span" inherit color={getColor()}>
        {displayValueFormatted}
      </Text>
    )
  },
)
