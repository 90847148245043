import menu from './menu/'
import scrollbar from './scrollbar'

const baseStyles = {
  variants: {
    ...menu,
    ...scrollbar,
  },
}

export { baseStyles }
