import { Button } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { Organization } from '@modules-deprecated/app/organization/types/organization'

import { useEmberRouter } from '../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../enums/emberRoute'
import { Translate } from '../../../../Translate'
import * as Styled from './styles'

interface ViewOrganizationsProps {
  organization: Organization
  onClick?: () => void
}

export const ViewOrganizations = ({ onClick, organization }: ViewOrganizationsProps): ReactElement => {
  const { navigate } = useEmberRouter()

  const handleButtonClick = useCallback(() => {
    navigate(EmberRoute.Organizations, [organization?.url])
    onClick?.()
  }, [navigate, onClick, organization])

  return (
    <Styled.ViewOrganizationsWrapper>
      <Button variant="text" size="xl" icon="suitcase" onClick={handleButtonClick} fullWidth>
        <Translate value="menu.org_dropdown.view_organizations" />
      </Button>
    </Styled.ViewOrganizationsWrapper>
  )
}
