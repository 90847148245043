import { TrialBalanceReportOptions } from '../types/reportOptions'

export const getRegnskabBasisRequestQueryParams = (
  options?: TrialBalanceReportOptions,
): Record<string, any> | undefined => {
  const { period } = options || {}

  return {
    ...(period ? { period } : {}),
  }
}
