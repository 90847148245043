import { SortDirection } from '../../../../../../../enums/sortDirection'
import { VatAccountsSortProperty } from '../../../enums/vatAccountsSortProperty'
import { TaxAccountWithDetails } from '../../../types/taxAccountWithDetails'
import { useFetchVatAccounts } from './useFetchVatAccounts'

interface UseVatAccountsResponse {
  vatAccounts: TaxAccountWithDetails[]
  isLoading?: boolean
}

export const useVatAccounts = (): UseVatAccountsResponse => {
  const { data, isLoading } = useFetchVatAccounts({
    sortDirection: SortDirection.Asc,
    sortProperty: VatAccountsSortProperty.Priority,
  })

  return {
    vatAccounts: data,
    isLoading,
  }
}
