import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconDotDuo = ({ color = 'inherit', color2, width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-dotduo')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g transform="rotate(-30 10 10), translate(6.5, 6.5)">
        <path fill={coloring(color)} d="M0,3 a3.5,3.5 0 1,1 7,0 h-7 z"></path>
        <path fill={coloring(color2 || color)} d="M0,4 a3.5,3.5 0 0,0 7,0 h-7 z"></path>
      </g>
    </svg>
  </Box>
)
