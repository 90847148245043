import { ReactElement } from 'react'

import * as Styled from './styles'

interface Props {
  children: ReactElement
}

export const HighlightedIcon = ({ children }: Props): ReactElement => (
  <Styled.HighlightedIconWrapper justifyContent="center" alignItems="center">
    {children}
  </Styled.HighlightedIconWrapper>
)
