import { SearchInput } from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback } from 'react'

import { useRecurringInvoicesListFilters } from '../../../hooks/useRecurringInvoicesListFilters'

export const RecurringInvoicesSearch = (): ReactElement => {
  const [{ searchQuery }, setFilters] = useRecurringInvoicesListFilters()

  const isSearchInputFocused = !!searchQuery

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilters({ page: 1, searchQuery: event.target.value || undefined })
    },
    [setFilters],
  )

  const handleClear = useCallback(() => {
    setFilters({ page: 1, searchQuery: undefined })
  }, [setFilters])

  return (
    <SearchInput
      focused={isSearchInputFocused}
      onChangeDebounced={handleChange}
      onClear={handleClear}
      value={searchQuery}
    />
  )
}
