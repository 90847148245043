import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../enums/queryKeys'
import { APIError } from '../../../utils'
import { deleteScoutConnection } from '../query-api'
import { ScoutConnection } from '../types/scoutConnection'

interface UseDeleteInvitationArgs {
  onError?: (error: APIError, connection: ScoutConnection) => void
  onSuccess?: (connection: ScoutConnection) => void
}

export const useDeleteScoutConnection = ({ onError, onSuccess }: UseDeleteInvitationArgs = {}) => {
  const { organization } = useUserOrganization()
  const queryClient = useQueryClient()
  const { isLoading: isProcessing, mutate } = useMutation(
    async (connection: ScoutConnection) =>
      organization && (await deleteScoutConnection({ organizationId: organization.id, connectionId: connection.id })),
    {
      onError: (error: APIError, connection: ScoutConnection) => {
        onError?.(error, connection)
      },
      onSuccess: (_, connection: ScoutConnection) => {
        queryClient.invalidateQueries(QueryKeys.ScoutConnections)
        onSuccess?.(connection)
      },
    },
  )

  const deleteConnection = useCallback(
    (connection: ScoutConnection) => {
      return mutate(connection)
    },
    [mutate],
  )

  return {
    delete: deleteConnection,
    isProcessing,
  }
}
