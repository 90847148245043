import filter from 'lodash/filter'

export type ModalCallback = (modalId: string) => void
type Subscribers = Record<string, Array<((modalId: string) => void) | undefined>>

export let _onOpenSubscribers: Subscribers = {}
export let _onCloseSubscribers: Subscribers = {}

export const _subscribeOnOpen = (modalId: string, callback: ModalCallback | undefined) => {
  if (!_onOpenSubscribers[modalId]) {
    _onOpenSubscribers[modalId] = []
  }

  if (_onOpenSubscribers[modalId].includes(callback)) {
    return
  }

  _onOpenSubscribers[modalId].push(callback)
}

export const _subscribeOnClose = (modalId: string, callback: ModalCallback | undefined) => {
  if (!_onCloseSubscribers[modalId]) {
    _onCloseSubscribers[modalId] = []
  }

  if (_onCloseSubscribers[modalId].includes(callback)) {
    return
  }

  _onCloseSubscribers[modalId].push(callback)
}

export const _removeSubscribeOnOpen = (modalId: string, callback: ModalCallback | undefined) => {
  const _onOpenSubscribersUpdated = filter(_onOpenSubscribers[modalId], (subscriber) => subscriber !== callback)
  _onOpenSubscribers = { ..._onOpenSubscribers, [modalId]: _onOpenSubscribersUpdated }
}

export const _removeSubscribeOnClose = (modalId: string, callback: ModalCallback | undefined) => {
  const _onCloseSubscribersUpdated = filter(_onCloseSubscribers[modalId], (subscriber) => subscriber !== callback)
  _onCloseSubscribers = { ..._onCloseSubscribers, [modalId]: _onCloseSubscribersUpdated }
}
