import algoliasearch from 'algoliasearch'
import algoliaInsightsClient from 'search-insights'

const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID || 'JTPY4JZP5P'
const ALGOLIA_API_KEY_SEARCH = process.env.REACT_APP_ALGOLIA_API_KEY_SEARCH || 'e86948b16e243fde6bd32c66b9e99dab'

export const algoliaSearchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY_SEARCH)

const minimumQueryCharsAmountToSendRequest = 3

const isQueryTooShortToSendRequest = (requests: any) =>
  requests.every(({ params }: any) => params.query.length < minimumQueryCharsAmountToSendRequest)

export const searchClient = {
  ...algoliaSearchClient,
  search(requests: any) {
    if (isQueryTooShortToSendRequest(requests)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      })
    }

    return algoliaSearchClient.search(requests)
  },
}

algoliaInsightsClient('init', {
  appId: ALGOLIA_APP_ID,
  apiKey: ALGOLIA_API_KEY_SEARCH,
  useCookie: true,
})

export const insightsClient = algoliaInsightsClient
