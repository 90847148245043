import produce from 'immer'

import { PRISMIC_DOCUMENTS_BY_TYPE_TAGS_RECEIVED, PRISMIC_RECEIVED_ACTION, PRISMIC_TITLES_RECEIVED } from './actions'

export type PrismicState = {
  titles: string[]
  entities: any
}

const initialState: PrismicState = {
  titles: [],
  entities: {
    prismicArticle: {},
    prismicDocument: {},
  },
}

const reducer = (state: PrismicState = initialState, { type, payload }: any): PrismicState => {
  switch (type) {
    case PRISMIC_RECEIVED_ACTION:
      return produce(state, (draftState) => {
        draftState.entities.prismicArticle = payload.prismicArticles
      })
    case PRISMIC_DOCUMENTS_BY_TYPE_TAGS_RECEIVED:
      return produce(state, (draftState) => {
        draftState.entities.prismicDocument = payload.prismicDocuments
      })
    case PRISMIC_TITLES_RECEIVED:
      return { ...state, entities: state.entities, titles: payload }
    default:
      return state
  }
}

export default reducer
