import forEach from 'lodash/forEach'
import noop from 'lodash/noop'
import { useCallback, useEffect } from 'react'

import { initialState } from '../modalContext'
import {
  _onCloseSubscribers,
  _onOpenSubscribers,
  _removeSubscribeOnClose,
  _removeSubscribeOnOpen,
  _subscribeOnClose,
  _subscribeOnOpen,
  ModalCallback,
} from './modalSubscribers'
import { useModals } from './useModals'

interface UseModalProps {
  onOpen?: ModalCallback
  onClose?: ModalCallback
}

interface UseModalResult {
  isOpen: boolean
  isLastOpened: boolean
  open: () => void
  close: () => void
}

export const useModal = (modalId: string, properties?: UseModalProps): UseModalResult => {
  const context = useModals()

  const { onOpen, onClose } = properties || {}
  const [{ openedIds }, dispatch] = context || [initialState, noop]

  const open = useCallback(() => {
    dispatch({
      modalId,
      type: 'OPEN',
    })

    forEach(_onOpenSubscribers[modalId], (callback) => callback?.(modalId))
  }, [dispatch, modalId])

  const close = useCallback(() => {
    dispatch({
      modalId,
      type: 'CLOSE',
    })

    forEach(_onCloseSubscribers[modalId], (callback) => callback?.(modalId))
  }, [dispatch, modalId])

  useEffect(() => {
    if (onOpen) {
      _subscribeOnOpen(modalId, onOpen)
    }

    if (onClose) {
      _subscribeOnClose(modalId, onClose)
    }

    return () => {
      _removeSubscribeOnOpen(modalId, onOpen)
      _removeSubscribeOnClose(modalId, onClose)
    }
  }, [onOpen, onClose, modalId])

  return {
    isOpen: openedIds.includes(modalId),
    isLastOpened: openedIds[openedIds.length - 1] === modalId,
    open,
    close,
  }
}
