import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconSort = ({
  color = 'inherit',
  rotate = false,
  width = '20px',
  height = '20px',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-sort')} {...rest}>
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      style={{
        transition: 'transform 0.3s',
        transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
    >
      <rect fill={coloring(color)} y="8" width="12" height="2"></rect>
      <rect fill={coloring(color)} x="2" y="5" width="8" height="2"></rect>
      <rect fill={coloring(color)} x="4" y="2" width="4" height="2"></rect>
    </svg>
  </Box>
)
