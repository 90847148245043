import { AccountSelect } from '@components'
import { Button, ButtonsGroup, Divider, Modal, ModalProps, Select, Space, Text, useModal } from '@design-system'

import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../enums/modalId'
import { useForm, useFormContext, useWatch } from '../../../../hooks'
import { useBankConnections } from '../../hooks/useBankConnections'
import { useConnectBankToAccount } from '../../hooks/useConnectBankToAccount'
import { getDropdownItems } from '../../utils/getDropdownItems'
import { BankToAccountConnectionFormSchema, getValidationSchema } from './bankToAccountConnectionFormData'

interface BankToAccountConnectionModalProps extends ModalProps {
  accountId: string
  connectionId?: string
  refetchBankConnections: () => void
  removeQueryParams: () => void
}

export const BankToAccountConnectionModal = ({
  accountId,
  connectionId,
  refetchBankConnections,
  removeQueryParams,
  ...modalProps
}: BankToAccountConnectionModalProps): ReactElement => {
  const [selectedId, setSelectedId] = useState<string>()

  const { t } = useTranslation()
  const { close: closeModal } = useModal(ModalId.BankToAccountConnectionModal)
  const { FormItem } = useFormContext<BankToAccountConnectionFormSchema>()

  const { Form, handleSubmit, control, setValue } = useForm({
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const { connectBankToAccount } = useConnectBankToAccount(refetchBankConnections)
  const billyAccount = useWatch({ control, name: 'billyAccount' })
  const bankAccount = useWatch({ control, name: 'bankAccount' })

  const {
    bankConnections: allBankConnections,
    filteredBankConnections: bankConnections,
    isLoading: isLoadingBankConnections,
  } = useBankConnections()

  const bankAccounts = useMemo(
    () => (bankConnections ? getDropdownItems(bankConnections) : getDropdownItems(allBankConnections)),
    [bankConnections, allBankConnections],
  )

  const usedAgerasAccounts = useMemo(
    () =>
      allBankConnections?.map(({ externalAccountId }) => externalAccountId).filter((id): id is string => id !== null),
    [allBankConnections],
  )

  const isButtonDisabled = !billyAccount || !bankAccount

  const handleBankToAccountSubmitForm = useCallback(
    ({ billyAccount, bankAccount }: { billyAccount: string; bankAccount: string }) => {
      connectBankToAccount({ accountId: billyAccount, aiiaBankAccountId: bankAccount })
    },
    [connectBankToAccount],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleBankToAccountSubmitForm)()
  }, [handleSubmit, handleBankToAccountSubmitForm])

  const handleCloseModal = useCallback(() => {
    closeModal()
    removeQueryParams()
  }, [closeModal, removeQueryParams])

  useEffect(() => {
    setValue('billyAccount', accountId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId])

  useEffect(() => {
    const hasSingleBankAccountConnected = bankAccounts && bankAccounts.length === 1

    if (hasSingleBankAccountConnected) {
      const firstBankAccountId = bankAccounts[0].id
      const hasSameAccountSelected = bankAccount === firstBankAccountId

      if (!hasSameAccountSelected) {
        setValue('bankAccount', firstBankAccountId)
        setSelectedId(firstBankAccountId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccount, bankAccounts, isLoadingBankConnections])

  return (
    <Modal size="s" {...modalProps} closable={false}>
      <Modal.Header>
        <Text variant="h2">{t('bank_integration.connect_account_to_bank')}</Text>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Text>{t('bank_integration.choose_and_connect_accounts')}</Text>
          <Space />

          <FormItem
            label={t('bank_integration.bank_account')}
            name="bankAccount"
            render={({ field: { onChange, ...rest } }) => {
              return (
                <Select
                  {...rest}
                  dropdownFetching={isLoadingBankConnections}
                  dropdownSize="fitTrigger"
                  items={bankAccounts || []}
                  onSelect={onChange}
                  placeholder={t('bank_integration.choose_bank_account')}
                  selectedId={selectedId}
                />
              )
            }}
          />
          <Space />

          <FormItem
            label={t('bank_integration.ageras_account')}
            name="billyAccount"
            render={({ field: { onChange, value, ...rest } }) => (
              <AccountSelect
                {...rest}
                selectedId={accountId}
                isBankAccount
                dropdownSize="fitTrigger"
                excludedAccounts={usedAgerasAccounts}
                onSelect={onChange}
              />
            )}
          />
          <Space />

          <Divider />
          <Space />
          <Text>{t('bank_integration.import_info')}</Text>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button onClick={handleCloseModal} variant="text">
            {t('cancel')}
          </Button>
          <Button disabled={isButtonDisabled} onClick={submitForm}>
            {t('bank_integration.approve')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
