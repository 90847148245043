import { Color, FontWeight } from '@design-system'

import { css } from '@emotion/core'

export const sanitize = css`
  *,
  ::before,
  ::after {
    // This causes issues with styles in Ember, so we ignore it for now
    // background-repeat: no-repeat;
    box-sizing: inherit;
  }

  ::before,
  ::after {
    text-decoration: inherit;
    vertical-align: inherit;
  }

  figcaption,
  figure,
  main {
    display: block;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  a {
    background-color: transparent;
    text-decoration-skip: objects;
  }

  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: ${FontWeight.Medium};
  }

  small {
    font-size: 80%;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  audio,
  video {
    display: inline-block;
  }

  img {
    border-style: none;
  }

  table {
    border-collapse: collapse;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }

  button,
  input,
  select,
  textarea {
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  select {
    text-transform: none;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  a,
  area,
  button,
  input,
  label,
  select,
  summary,
  textarea,
  [tabindex='0'] {
    touch-action: manipulation;
  }

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  [hidden] {
    display: none;
  }

  [aria-busy='true'] {
    cursor: progress;
  }

  [aria-controls] {
    cursor: pointer;
  }

  [aria-hidden='false'][hidden]:not(:focus) {
    clip: rect(0, 0, 0, 0);
    display: inherit;
    position: absolute;
  }

  [aria-disabled] {
    cursor: default;
  }

  mark {
    background-color: ${Color.Yellow30};
  }

  [tabindex]:focus-visible {
    box-shadow: inset 0 0 0 2px ${Color.ElectricGreen};
    outline: none;
  }
  [tabindex]:focus {
    outline: none;
  }
`
