import { Color, PaneAmount, PaneItemChild, PaymentPaneProps, Text } from '@design-system'

import { TFunction } from 'i18next'
import React from 'react'

import { InvoiceStatus } from '../../../enums/invoiceStatus'
import { getInvoiceStatus } from '../../../routes/InvoicesList/utils/getInvoiceStatus'
import { Invoice } from '../../../types/invoice'
import { invoiceStatusToPaymentPaneStatus } from '../constants/invoiceStatusToPaymentPaneStatus'
import { InvoicePaymentPaneExtra } from '../elements/InvoicePaymentPaneExtra'
import { InvoicePaymentPanePrefix } from '../elements/InvoicePaymentPanePrefix'
import { getModifiersCount } from './getModifiersCount'

export const getInvoicePaymentPaneData = (
  t: TFunction,
  invoiceData?: Invoice,
  isLoading?: boolean,
): PaymentPaneProps => {
  if (!invoiceData || isLoading) {
    return { isLoading }
  }

  const { amount, balance, dueDate, isPaid, state, tax, balanceModifiers, entryDate } = invoiceData

  const { payments, fees } = getModifiersCount(balanceModifiers)

  const invoiceStatus = getInvoiceStatus({
    amount,
    balance,
    dueDate: dueDate ? new Date(dueDate) : undefined,
    isPaid,
    state,
    tax,
  })

  const modifiersCount = balanceModifiers?.length
  const isPaidOrDraftBillStatus = invoiceStatus === InvoiceStatus.Paid || invoiceStatus === InvoiceStatus.Draft
  const paidDate = modifiersCount ? balanceModifiers[0].entryDate : entryDate
  const amountColor =
    invoiceStatus === InvoiceStatus.Overdue || invoiceStatus === InvoiceStatus.Unpaid ? Color.Red : Color.Gray50

  return {
    amount: (
      <PaneItemChild>
        <PaneAmount amount={balance} color={amountColor} />
      </PaneItemChild>
    ),
    collapsible: !!modifiersCount,
    content: !!modifiersCount && (
      <Text variant="small" inline>
        {!!payments && t('invoices.view.payment_pane.content.payment', { count: payments })}
        {!!payments && !!fees && ` ${t('invoices.view.payment_pane.content.and')} `}
        {!!fees && t('invoices.view.payment_pane.content.fee', { count: fees })}
      </Text>
    ),
    extra: (
      <PaneItemChild>
        <InvoicePaymentPaneExtra date={isPaidOrDraftBillStatus ? paidDate : dueDate} status={invoiceStatus} />
      </PaneItemChild>
    ),
    icon: 'exclamationMarkCircle',
    prefix: <InvoicePaymentPanePrefix status={invoiceStatus} />,
    status: invoiceStatusToPaymentPaneStatus[invoiceStatus],
  }
}
