import { Button } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateOrEditVatRateModal } from '../../context/createOrEditVatRateModalContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { submitForm, isLoading, isDisabled, close } = useCreateOrEditVatRateModal()

  return (
    <>
      {isDisabled ? (
        <Button onClick={close} loading={isLoading}>
          {t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.close')}
        </Button>
      ) : (
        <Button onClick={submitForm} loading={isLoading}>
          {t('save')}
        </Button>
      )}
    </>
  )
}
