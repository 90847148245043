import { FilesPreview } from '@components-deprecated'
import {
  AttachmentType,
  Badge,
  Checkbox,
  Color,
  getStringFromReactNode,
  SkeletonBox,
  Text,
  useModal,
} from '@design-system'

import React, { ChangeEvent, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReceiptType } from '../../../../enums/receiptType'
import { getReceiptModalId } from '../../utils/getReceiptModalId'
import * as Styled from './styles'

interface ReceiptProps {
  footer: ReactNode
  id: string
  isSelected: boolean
  leftSide: ReactNode
  onSelect: (id: string, isSelected: boolean) => void
  fileName?: string
  fileSrc: string
  rightSide?: ReactNode
  title: ReactNode
  type: AttachmentType | null
}

const DEFAULT_TITLE_VALUE = '-'

export const Receipt = ({
  footer,
  id,
  isSelected,
  leftSide,
  onSelect,
  fileName,
  fileSrc,
  rightSide,
  title,
  type,
}: ReceiptProps): ReactElement => {
  const { t } = useTranslation()
  const [isImageLoading, setIsImageLoading] = useState(true)
  const modalId = useMemo(() => getReceiptModalId(id), [id])
  const { open: openReceiptModal } = useModal(modalId)

  const files = useMemo(() => [{ src: fileSrc }], [fileSrc])
  const isSelectable = type !== ReceiptType.EDocument
  const receiptTitle = getStringFromReactNode(title) || DEFAULT_TITLE_VALUE

  const handleImageLoad = useCallback(() => {
    setIsImageLoading(false)
  }, [])

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onSelect(id, event.target.checked)
    },
    [id, onSelect],
  )

  const handleImageClick = useCallback(() => {
    openReceiptModal()
  }, [openReceiptModal])

  return (
    <Styled.ReceiptWrapper checked={isSelected}>
      {isSelectable && (
        <Styled.ReceiptCheckboxWrapper checked={isSelected}>
          <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
        </Styled.ReceiptCheckboxWrapper>
      )}
      <Styled.ReceiptImageWrapper onClick={handleImageClick} title={fileName}>
        <Styled.ReceiptPreviewWrapper loading={isImageLoading}>
          <FilesPreview files={files} fitToWidth onLoad={handleImageLoad} withNavigation={false} fitType="cover" />
        </Styled.ReceiptPreviewWrapper>
        {isImageLoading && <SkeletonBox fullHeight fullWidth />}
      </Styled.ReceiptImageWrapper>
      <Styled.ReceiptContentWrapper>
        <Styled.ReceiptContent>
          <Text as="h3" color={Color.Gray200} variant="large" weight="medium" title={receiptTitle} truncate>
            {receiptTitle}
          </Text>
          {type === ReceiptType.EDocument && (
            <Styled.ReceiptBadgeWrapper>
              <Badge variant={'primary'}>{t('receipts.receipts_list.type.e_invoice')}</Badge>
            </Styled.ReceiptBadgeWrapper>
          )}
        </Styled.ReceiptContent>
        <Styled.ReceiptContent>
          <Styled.ReceiptLeft colorVariant="secondary">{leftSide}</Styled.ReceiptLeft>
          {rightSide && (
            <Styled.ReceiptRight color={Color.Gray200} variant="large" weight="medium">
              {rightSide}
            </Styled.ReceiptRight>
          )}
        </Styled.ReceiptContent>
        <Styled.ReceiptFooter>{footer}</Styled.ReceiptFooter>
      </Styled.ReceiptContentWrapper>
    </Styled.ReceiptWrapper>
  )
}
