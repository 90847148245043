import { getAppBuildTheme } from '../../../../../utils/getAppBuildTheme'
import { CreateUmbrellaVariables } from '../types/createUmbrellaVariables'

const theme = getAppBuildTheme()

export const variablesAddCard: CreateUmbrellaVariables = {
  amount: 0,
  billingStart: '',
  period: '',
  subscriptionDiscount: 0,
  subscriptionPeriod: '',
  subscriptionPlan: '',
  subscriptionPrice: 0,
  amountExVat: 0,
  amountExVatFormatted: '0.00 DKK',
  vat: 0,
  vatFormatted: '0.00 DKK',
  totalAmount: 0,
  totalAmountFormatted: '0.00 DKK',
  amountPerPeriod: '',
  cardOnly: true,
  productName: '',
  startSubscription: 0,
  reusePaymentMethod: 0,
  providerTheme: theme,
}
