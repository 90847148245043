import { ReactNode } from 'react'

import * as Styled from './styles'

interface DisabledWrapperProps {
  children: ReactNode
}

export const DisabledWrapper = ({ children }: DisabledWrapperProps) => {
  return (
    <Styled.DisabledOuterWrapper>
      <Styled.DisabledInnerWrapper>{children}</Styled.DisabledInnerWrapper>
    </Styled.DisabledOuterWrapper>
  )
}
