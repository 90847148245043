export enum LineHeight {
  TextH1 = '40px',
  TextH2 = '32px',
  TextH3 = '24px',
  TextH4 = '16px',
  TextDisplayM = '48px',
  TextDisplayS = '48px',
  TextDisplayXS = '40px',
  TextLarge = '24px',
  TextNormal = '24px',
  TextSmall = '24px',
  TextMicro = '16px',
}
