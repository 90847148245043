import React, { ImgHTMLAttributes } from 'react'

import { getImageClassName } from '../../../../../utils/getClassName'
import { getThumbnailUrl } from '../../../../../utils/getThumbnailUrl'
import * as Styled from './styles'

type AvatarImageProps = ImgHTMLAttributes<HTMLImageElement>

export const AvatarImage = ({ src, alt, ...rest }: AvatarImageProps) => {
  const srcProcessed = src ? getThumbnailUrl(src, { size: '36x36' }) : undefined

  return (
    <Styled.AvatarImageWrapper {...rest} className={`${getImageClassName()} ${rest.className || ''}`}>
      <Styled.AvatarImage alt={alt} src={srcProcessed} />
    </Styled.AvatarImageWrapper>
  )
}
