import React, { MouseEvent, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { NavListLoading } from './elements/NavListLoading'
import { NavListRender } from './elements/NavListRender'
import { NavNotFoundInfo } from './elements/NavNotFoundInfo'
import { useDetectScrollEndPosition } from './hooks/useDetectScrollEndPosition'
import * as Styled from './styles'
import { NavItem } from './types/navItem'
import { NavItemRenderProps } from './types/navItemRenderProps'
import { SubItemsMode } from './types/subItemsMode'

export interface NavListProps<T> {
  expandedIds?: string[]
  focusedId?: string
  isFetching?: boolean // useful for showing loading state when fetching another set of data
  itemRender?: (props: NavItemRenderProps<T>) => ReactNode
  items: NavItem<T>[]
  notFoundContent?: ReactNode
  onItemClick?: (id: string, value: T, event?: MouseEvent) => void
  onScrollEnd?: () => void
  scrollEndOffset?: number
  selectedId?: string
  subItemsMode?: SubItemsMode
}

export const NavList = <T,>({
  expandedIds,
  focusedId,
  isFetching,
  itemRender,
  items,
  notFoundContent,
  onItemClick,
  onScrollEnd,
  scrollEndOffset,
  selectedId,
  subItemsMode,
  ...rest
}: NavListProps<T>): ReactElement => {
  const navListWrapperRef = useRef<HTMLUListElement>(null)
  const [scrollableElement, setScrollableElement] = useState<HTMLUListElement>()

  const expandedItem = subItemsMode === 'horizontal' ? items.find((item) => expandedIds?.includes(item.id)) : undefined
  const shouldPassExpandedIds = expandedItem === undefined
  const itemsToRender = useMemo(
    () => (expandedItem?.subItems && expandedItem?.subItems.length > 0 ? expandedItem.subItems : items),
    [items, expandedItem],
  )

  useEffect(() => {
    if (navListWrapperRef.current) {
      setScrollableElement(navListWrapperRef.current)
    }
  }, [navListWrapperRef])

  useEffect(() => {
    if (isFetching) {
      // scroll down to the bottom when isFetching flag is true
      if (navListWrapperRef.current) {
        navListWrapperRef.current.scrollTop = navListWrapperRef.current?.scrollHeight
      }
    }
  }, [isFetching, navListWrapperRef])

  const handleItemClick = useCallback(
    (id: string, value: T, event?: MouseEvent) => {
      onItemClick?.(id, value, event)
    },
    [onItemClick],
  )

  const handleScrollEnd = useCallback(() => {
    onScrollEnd?.()
  }, [onScrollEnd])

  useDetectScrollEndPosition({
    scrollableElement,
    onScrollEnd: handleScrollEnd,
    enabled: !isFetching && !!onScrollEnd,
    scrollEndOffset,
  })

  return (
    <>
      <Styled.NavListWrapper scrollableNodeProps={{ ref: navListWrapperRef }} {...rest}>
        {itemsToRender.length > 0 || isFetching ? (
          <>
            <NavListRender
              items={itemsToRender}
              expandedIds={shouldPassExpandedIds ? expandedIds : []}
              focusedId={focusedId}
              itemRender={itemRender}
              onItemClick={handleItemClick}
              selectedId={selectedId}
              subItemsMode={subItemsMode}
            />

            {isFetching && <NavListLoading />}
          </>
        ) : (
          <NavNotFoundInfo>{notFoundContent}</NavNotFoundInfo>
        )}
      </Styled.NavListWrapper>
    </>
  )
}
