import { Color, Icon } from '@design-system'

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

export interface IncludedItemsProps {
  includedItems: string[]
}

export const IncludedItems = ({ includedItems }: IncludedItemsProps) => {
  const { t } = useTranslation()

  return (
    <Styled.Container>
      {includedItems.map((item, i) => (
        <Styled.IncludedItem key={item + i}>
          <Styled.IconWrapper>
            <Icon icon="check" color={Color.Green} />
          </Styled.IconWrapper>

          <Styled.TextWrapper>
            <Styled.Text>{` ${t(item)}`}</Styled.Text>
          </Styled.TextWrapper>
        </Styled.IncludedItem>
      ))}
    </Styled.Container>
  )
}
