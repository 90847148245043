import { EmberRoute } from '../../../../../enums/emberRoute'
import { InvoiceBalanceModifierReference } from '../enums/InvoiceBalanceModifierReference'

const invoicePaymentPaneItemRouteMap: Record<InvoiceBalanceModifierReference, string> = {
  [InvoiceBalanceModifierReference.BankPayment]: EmberRoute.BankPayment,
  [InvoiceBalanceModifierReference.Invoice]: EmberRoute.Invoice,
  [InvoiceBalanceModifierReference.ContactBalancePayment]: EmberRoute.ContactBalancePayment,
  [InvoiceBalanceModifierReference.DaybookTransaction]: EmberRoute.DaybookTransaction,
  [InvoiceBalanceModifierReference.InvoiceLateFee]: EmberRoute.InvoiceLateFee,
}

export const getInvoicePaymentPaneItemRoute = (
  balanceModifierReference: InvoiceBalanceModifierReference,
): EmberRoute | string => {
  return invoicePaymentPaneItemRouteMap[balanceModifierReference]
}
