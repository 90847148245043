import { TFunction } from 'i18next'

import { PaymentMethodType } from '../../../../../../../../../query-api/payment-methods-query'
import { paymentMethodTypeToTranslationKey } from '../constants/paymentMethodTypeToTranslationKey'

export const getPaymentMethodDisplayedType = (paymentMethodType: PaymentMethodType, t: TFunction): string => {
  const tKey = paymentMethodTypeToTranslationKey[paymentMethodType]

  return t(tKey)
}
