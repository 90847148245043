import { Color, getDefaultTransition, getShadow, Spacing, zIndex } from '@design-system'

import styled from '@emotion/styled'
import SimpleBar from 'simplebar-react'

import { PANEL_HORIZONTAL_PADDING } from './constants/panelHorizontalPadding'

interface NotesPanelWrapperProps {
  isOpen: boolean
}

export const NotesPanelWrapper = styled.div<NotesPanelWrapperProps>`
  display: flex;
  position: fixed;
  width: 366px;
  height: 100vh;
  padding: ${Spacing.XL} ${PANEL_HORIZONTAL_PADDING} 0;
  flex-direction: column;
  top: 0;
  right: 0;
  background-color: ${Color.White};
  z-index: ${zIndex.NotesPanel};
  will-change: transform;
  transform: ${({ isOpen }) => `translateX(${isOpen ? 0 : '100%'})`};
  ${getShadow('raisedStrong')};
  ${getDefaultTransition('transform')};
`

export const FormWrapper = styled.div``

export const NotesList = styled(SimpleBar)`
  display: flex;
  margin-right: -${PANEL_HORIZONTAL_PADDING};
  margin-left: -${PANEL_HORIZONTAL_PADDING};
  padding-right: ${PANEL_HORIZONTAL_PADDING};
  padding-left: ${PANEL_HORIZONTAL_PADDING};
  flex: 1;
  flex-direction: column;
  list-style-type: none; // important when using SimpleBar
  overflow: auto;
`
