import React, { Children, cloneElement, isValidElement, ReactElement } from 'react'

import { Spacing } from '../../enums/spacing'
import { ComponentsGroup, ComponentsGroupProps } from '../ComponentsGroup'

export type ModuleGroupProps = Omit<ComponentsGroupProps, 'spacing'> & {
  merged?: boolean
}

export const ModuleGroup = ({ merged, children, direction = 'column', ...rest }: ModuleGroupProps): ReactElement => {
  const spacing = !merged ? Spacing.XL : undefined

  return (
    <ComponentsGroup {...rest} direction={direction} spacing={spacing} merged={merged}>
      {Children.map(
        children,
        (child, index) =>
          isValidElement(child) &&
          cloneElement(child, {
            ...child.props,
            skipLeftRounding: merged && index !== 0,
            skipRightRounding: merged && index !== Children.count(children) - 1,
          }),
      )}
    </ComponentsGroup>
  )
}
