import { Button as ButtonComponent, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const NoVouchersWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: ${Spacing.XXXL};
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

export const Button = styled(ButtonComponent)`
  margin-top: ${Spacing.L};
`

export const VouchersActionsWrapper = styled.section`
  display: flex;
  margin-bottom: ${Spacing.L};
  padding: 0 ${Spacing.L};
  align-items: center;
  justify-content: flex-start;
`

export const VouchersActionsText = styled(Text)`
  margin-right: ${Spacing.L};
`
