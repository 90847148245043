const DEV = process.env.NODE_ENV === 'development'

export const error = (...args: unknown[]) => DEV && console?.error && console.error(...args)
// eslint-disable-next-line no-console
export const info = (...args: unknown[]) => DEV && console?.info && console.info(...args)
// eslint-disable-next-line no-console
export const log = (...args: unknown[]) => DEV && console?.log && console.log(...args)

export const table = (...args: unknown[]) => DEV && console?.table && console.table(...args) // eslint-disable-line

export const warn = (...args: unknown[]) => DEV && console?.warn && console.warn(...args)
