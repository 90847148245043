import { TFunction } from 'i18next'
import * as yup from 'yup'

export interface CreateUserForm {
  emali: string // typo in purpose (disabling native autofill)
  password: string
  acceptedTerms: boolean
  acceptedMarketing: boolean
}

export const defaultValues: CreateUserForm = {
  emali: '',
  password: '',
  acceptedTerms: false,
  acceptedMarketing: false,
}

export const getValidationSchema = (t: TFunction): yup.SchemaOf<CreateUserForm> =>
  yup.object().shape({
    emali: yup
      .string()
      .email(t('signup_guide.create_user.form.error.email_format'))
      .required(t('signup_guide.create_user.form.error.email_required')),
    password: yup
      .string()
      .min(6, t('signup_guide.create_user.form.error.password_length'))
      .test('uppercase-letter', t('signup_guide.create_user.form.error.password_uppercase'), (value) =>
        /[A-Z]/.test(value || ''),
      )
      .test('number', t('signup_guide.create_user.form.error.password_number'), (value) => /\d/.test(value || ''))
      .required(t('signup_guide.create_user.form.error.password_required')),
    acceptedTerms: yup
      .boolean()
      .oneOf([true], t('signup_guide.create_user.form.error.accepted_marketing_required'))
      .required(),
    acceptedMarketing: yup.boolean().required(),
  })
