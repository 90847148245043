import { ContactFormInputs } from '../elements/CreateContactModal'
import { Contact } from '../types/contact'

export const getPrefilledContactDataForForm = (contact: Partial<Contact>): Partial<ContactFormInputs> => ({
  ...(contact.registrationNo
    ? {
        companyForRegistrationNo: {
          registrationNo: contact.registrationNo,
          text: contact.registrationNo,
        },
      }
    : {}),
  ...(contact.phone ? { phone: contact.phone } : {}),
  ...(contact ? { cityText: contact.cityText } : {}),
  address: {
    ...(contact.street ? { street: contact.street } : {}),
    ...(contact.zipcodeText ? { zipcodeText: contact.zipcodeText } : {}),
    ...(contact.cityText ? { cityText: contact.cityText } : {}),
  },
})
