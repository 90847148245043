import React from 'react'

import { Spacing } from '../../enums/spacing'
import * as Styled from './styles'

export interface SpaceProps {
  size?: Lowercase<keyof typeof Spacing> | 'auto'
  horizontal?: boolean
}

export const Space = ({ size = 'l', horizontal = false, ...rest }: SpaceProps) => {
  const sizeValue = size === 'auto' ? 'auto' : (Spacing[size.toUpperCase()] as Spacing)

  return <Styled.SpaceWrapper size={sizeValue} horizontal={horizontal} {...rest} />
}
