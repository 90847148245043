import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import uniq from 'lodash/uniq'
import { useCallback, useMemo } from 'react'

import { TableColumnAccessor } from '../types/column'
import { TableData } from '../types/data'

interface UseHiddenColumnsResponse {
  hiddenColumns: string[]
  updateHiddenColumns: (columnId: string, shouldRemove?: boolean) => void
}

/*
 * It gives information from `localStorage` about hidden columns in tables.
 * Due to issues with returning `out-of-sync` data from `useLocalStorage` hook from `react-use` package I replaced it with hook from `@rehooks`.
 * It returns correct data and has nicer syntax. For future features, it makes sense to go with this one.
 * IMPORTANT: This hook doesn't sync between components. It only retrieves newest `hiddenColumns` on initialization of the component.
 * If you want, to keep data in sync then the hook needs to be connected with `Context`.
 * */
export const useHiddenColumns = <T extends TableData>(
  tableId?: string,
  defaultHiddenColumnsIds: TableColumnAccessor<T>[] = [],
): UseHiddenColumnsResponse => {
  const localStorageHiddenColumnsId = `ds-column-visibility-${tableId}`
  const localStorageDefaultHiddenColumnsId = `ds-default-hidden-columns-${tableId}`

  const [hiddenColumns] = useLocalStorage<string[]>(localStorageHiddenColumnsId, [])
  const [defaultHiddenColumns] = useLocalStorage<string[]>(
    localStorageDefaultHiddenColumnsId,
    defaultHiddenColumnsIds as string[],
  )

  const combinedHiddenColumns: string[] = useMemo(
    () => uniq([...hiddenColumns, ...defaultHiddenColumns]),
    [defaultHiddenColumns, hiddenColumns],
  )

  const removeColumn = useCallback(
    (columnId: string) => {
      writeStorage(
        localStorageHiddenColumnsId,
        hiddenColumns.filter((hiddenColumnId: string) => hiddenColumnId !== columnId),
      )
      writeStorage(
        localStorageDefaultHiddenColumnsId,
        defaultHiddenColumns?.filter((defaultHiddenColumnId) => defaultHiddenColumnId !== columnId),
      )
    },
    [defaultHiddenColumns, hiddenColumns, localStorageDefaultHiddenColumnsId, localStorageHiddenColumnsId],
  )

  const addColumn = useCallback(
    (columnId: string) => {
      writeStorage(localStorageHiddenColumnsId, uniq([...hiddenColumns, columnId]))
    },
    [hiddenColumns, localStorageHiddenColumnsId],
  )

  const updateHiddenColumns = useCallback(
    (columnId: string, shouldRemove = false) => {
      if (!tableId) {
        return
      }

      if (shouldRemove) {
        removeColumn(columnId)
      } else {
        addColumn(columnId)
      }
    },
    [addColumn, removeColumn, tableId],
  )

  return useMemo(
    () => ({
      hiddenColumns: combinedHiddenColumns,
      updateHiddenColumns,
    }),
    [combinedHiddenColumns, updateHiddenColumns],
  )
}
