import { ModalBody } from '@components-deprecated'
import { Color, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${Spacing.L};
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${Spacing.M};
  padding-top: ${Spacing.XXL};
`

export const HelperText = styled(Text)``

HelperText.defaultProps = {
  colorVariant: 'secondary',
}

export const EmptyCell = styled.div``

export const FormSection = styled.section`
  & + & {
    border-top: 1px solid ${Color.Gray30};
    margin-top: ${Spacing.XXL};
    padding-top: ${Spacing.XXL};
  }
`

export const ModalBodyScrollable = styled(ModalBody)`
  overflow: auto;
`
