import { VoucherInboxState, VoucherListQueryParams } from '@modules-deprecated/inbox/types'

import { QueryKeys } from '../../../enums/queryKeys'

const missingInformationsQueryParams: VoucherListQueryParams = {
  inboxState: VoucherInboxState.NEED_INFO,
}

type VouchersListQueryArrayKeyResult = [QueryKeys, { organizationId: string; params: VoucherListQueryParams }]

export const getVouchersListQueryArrayKey = (
  organizationId = '',
  params?: VoucherListQueryParams,
): VouchersListQueryArrayKeyResult => [
  QueryKeys.VouchersList,
  { organizationId, params: { ...missingInformationsQueryParams, ...(params || {}) } },
]
