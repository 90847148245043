import {
  DropdownNavList,
  DropdownNavListProps,
  findNavItem,
  Icon,
  Input,
  InputProps,
  NavListItemWithBlockDescription,
  useModal,
  useTheme,
} from '@design-system'

import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchCompanies } from '@modules-deprecated/app/companies/hooks/useFetchCompanies'
import { Company } from '@modules-deprecated/app/companies/types'

import { KeyboardKey } from '../../../enums/keyboardKey'
import { ModalId } from '../../../enums/modalId'
import { Scope } from '../../../enums/scope'
import { TrackingContext } from '../../../enums/trackingContext'
import { isAuthorized } from '../../../utils/isAuthorized'
import { UpgradeSubscriptionModal } from '../../Modal/upgradeSubscriptionModal'
import { NAV_ITEM_ID_CREATE_COMPANY } from './constants/navItemIdCreateCompany'
import { CompanyValue } from './types/companyValue'
import { getDisplayValue } from './utils/getDisplayValue'
import { getItems } from './utils/getItems'

const MIN_INPUT_SIGNS = 3

export interface CompanySelectProps
  extends Pick<DropdownNavListProps<CompanyValue>, 'placement' | 'selectedId'>,
    Pick<
      InputProps,
      | 'alignment'
      | 'autoCompleted'
      | 'autoFocus'
      | 'bordered'
      | 'className'
      | 'disabled'
      | 'error'
      | 'focused'
      | 'hidden'
      | 'id'
      | 'name'
      | 'onBlur'
      | 'onChange'
      | 'onChangeDebounced'
      | 'onFocus'
      | 'onKeyDown'
      | 'onKeyPress'
      | 'onMouseDown'
      | 'onPressEnter'
      | 'placeholder'
      | 'readOnly'
      | 'selectOnFocus'
      | 'size'
      | 'success'
      | 'suffix'
      | 'type'
    > {
  // Select props
  allowCreate?: boolean
  getDisplayValue?: (item: Partial<Company>) => string
  inputValue?: string
  onCreate?: (companyName: string) => void
  onSelect?: (id?: string, value?: Partial<Company>) => void
  selectedValue?: Partial<Company>
  // DropdownNavList props
  dropdownId?: string
  dropdownMaxHeight?: DropdownNavListProps<CompanyValue>['maxHeight']
  dropdownSize?: DropdownNavListProps<CompanyValue>['size']
  scopes?: Scope | Scope[]
}

export const CompanySelect = ({
  allowCreate,
  getDisplayValue: getDisplayValueCustom,
  inputValue: inputValueControlled,
  onCreate,
  onSelect,
  selectedId: selectedIdControlled,
  selectedValue: selectedValueControlled,
  // DropdownNavList props
  dropdownId,
  dropdownMaxHeight = 'default',
  dropdownSize = 'fitTrigger',
  placement = 'bottom-end',
  // Input props
  alignment = 'left',
  autoCompleted,
  autoFocus,
  bordered,
  className,
  disabled,
  error,
  focused = false,
  hidden,
  id,
  name,
  onBlur,
  onChange,
  onChangeDebounced,
  onFocus,
  onKeyDown,
  onKeyPress,
  onMouseDown,
  onPressEnter,
  placeholder,
  readOnly,
  scopes = [],
  selectOnFocus,
  size,
  success,
  type,
}: CompanySelectProps): ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [selectedId, setSelectedId] = useState<string | undefined>(
    selectedValueControlled?.id || selectedIdControlled || '',
  )
  const [selectedValue, setSelectedValue] = useState<Partial<Company> | undefined>(selectedValueControlled)
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string>(
    getDisplayValue(selectedValueControlled, getDisplayValueCustom) || inputValueControlled || '',
  )
  const isDropdownInsideClickedRef = useRef(false) // helper for not clearing data on blur, after selecting some item (check handleInputBlur)
  const { open: openUpgradeSubscriptionModal } = useModal(ModalId.UpgradeSubscriptionModal)
  const { companies, isLoading } = useFetchCompanies(inputValue)

  const isContactLookupAuthorized = isAuthorized(scopes)

  const items = useMemo(
    () =>
      getItems({
        companies,
        t,
        allowCreate,
        isContactLookupAuthorized,
        theme,
      }),
    [companies, t, allowCreate, isContactLookupAuthorized, theme],
  )

  const selectItem = useCallback(
    (id: string | undefined, withSelectEvent?: boolean) => {
      const item = findNavItem(items, id)
      let idToSelect = id
      let valueToSelect = {}

      if (item?.value.isProtected) {
        openUpgradeSubscriptionModal()
        return
      }

      if (item) {
        if (id === NAV_ITEM_ID_CREATE_COMPANY) {
          idToSelect = ''
          valueToSelect = {}
          onCreate?.(inputValue)
        } else {
          const company = companies.find(({ id: itemId }) => itemId === id)

          if (company) {
            valueToSelect = company
            setInputValue(getDisplayValue(company, getDisplayValueCustom) || company.name)
          }
        }
      } else {
        idToSelect = ''
        valueToSelect = {}
        onCreate?.(inputValue)
      }

      setSelectedValue(valueToSelect)
      setSelectedId(idToSelect)
      setIsOpen(false)

      if (withSelectEvent) {
        onSelect?.(idToSelect, valueToSelect)
      }
    },
    [items, onCreate, inputValue, companies, getDisplayValueCustom, onSelect],
  )

  const toggleDropdown = useCallback(() => {
    setIsOpen(!!inputValue && inputValue.length >= MIN_INPUT_SIGNS)
  }, [inputValue, setIsOpen])

  const handleDropdownMouseDown = useCallback(() => {
    isDropdownInsideClickedRef.current = true
  }, [])

  const handleItemClick = useCallback(
    (id: string) => {
      isDropdownInsideClickedRef.current = true
      selectItem(id, true)
    },
    [selectItem],
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event?.target.value

      setInputValue(value)
      setIsOpen(value.length >= MIN_INPUT_SIGNS)
      onChange?.(event)
    },
    [onChange, setIsOpen],
  )

  const handleInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (isDropdownInsideClickedRef.current) {
        isDropdownInsideClickedRef.current = false
      } else {
        if (selectedId && selectedValue) {
          setInputValue(getDisplayValue(selectedValue, getDisplayValueCustom) || selectedValue.name || '')
        } else {
          selectItem(undefined)
        }

        setIsOpen(false)
      }

      onBlur?.(event)
    },
    [onBlur, selectedId, selectedValue, getDisplayValueCustom, selectItem],
  )

  const handleInputFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      toggleDropdown()
      onFocus?.(event)
    },
    [onFocus, toggleDropdown],
  )

  const handleInputKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (isDropdownInsideClickedRef.current) {
        isDropdownInsideClickedRef.current = false
      }

      if (event.key === KeyboardKey.ArrowDown) {
        toggleDropdown()
      }

      onKeyDown?.(event)
    },
    [onKeyDown, toggleDropdown],
  )

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  useEffect(() => {
    if (!!items.length && selectedIdControlled !== undefined && selectedIdControlled !== selectedId) {
      selectItem(selectedIdControlled, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIdControlled, items])

  useEffect(() => {
    if (
      selectedValueControlled !== undefined &&
      !isEmpty(selectedValueControlled) &&
      !isEqual(selectedValueControlled, selectedValue)
    ) {
      setSelectedValue(selectedValueControlled)
      setSelectedId(selectedValueControlled.id)
      setInputValue(
        getDisplayValue(selectedValueControlled, getDisplayValueCustom) || selectedValueControlled.name || '',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValueControlled])

  useEffect(() => {
    if (inputValueControlled !== undefined && inputValueControlled !== inputValue) {
      setInputValue(inputValueControlled)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueControlled])

  const handlePressEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (allowCreate && items.length === 1) {
        selectItem(NAV_ITEM_ID_CREATE_COMPANY)
      }

      onPressEnter?.(event)
    },
    [onPressEnter, items, allowCreate, selectItem],
  )
  return (
    <>
      <DropdownNavList
        autoToggle={false}
        id={dropdownId}
        isFetching={isLoading}
        isOpen={isOpen}
        itemRender={(props) => <NavListItemWithBlockDescription {...props} />}
        items={items}
        maxHeight={dropdownMaxHeight}
        onClose={handleClose}
        onItemClick={handleItemClick}
        onKeyDown={handleInputKeyDown}
        onMouseDown={handleDropdownMouseDown}
        placement={placement}
        selectedId={selectedId}
        size={dropdownSize}
        trigger={
          <Input
            alignment={alignment}
            autoComplete="off"
            autoCompleted={autoCompleted}
            autoFocus={autoFocus}
            bordered={bordered}
            className={className}
            disabled={disabled}
            error={error}
            focused={focused}
            hidden={hidden}
            id={id}
            name={name}
            onBlur={handleInputBlur}
            onChange={handleChange}
            onChangeDebounced={onChangeDebounced}
            onFocus={handleInputFocus}
            onKeyPress={onKeyPress}
            onMouseDown={onMouseDown}
            onPressEnter={handlePressEnter}
            placeholder={placeholder || `${t('company_select.dropdown.placeholder')}...`}
            readOnly={readOnly}
            selectLook
            selectOnFocus={selectOnFocus}
            size={size}
            success={success}
            suffix={<Icon icon="magnifyingGlass" />}
            title={inputValue}
            truncate
            type={type}
            value={inputValue}
          />
        }
        subItemsMode="horizontal"
        withNavigation
      />
      <UpgradeSubscriptionModal id={ModalId.UpgradeSubscriptionModal} trackingContext={TrackingContext.CreateContact} />
    </>
  )
}
