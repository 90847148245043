import { UploadFilesErrorMessage, UploadFilesErrors } from '@components'
import { FilesPreview } from '@components-deprecated'
import { Dropzone, notify, SkeletonBox } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { DropEvent, FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../../../enums/notificationKeys'
import { useLogo } from '../../../../../../hooks/useLogo'
import { formatMegabytesToBytes } from '../../../../../../utils/formatMegabytesToBytes'
import { ACCEPTED_LOGO_FILE_FORMATS } from '../../constants/acceptedLogoFileFormats'
import { useUploadLogo } from '../../hooks/useUploadLogo'

interface LogoDropzoneProps {
  onChange: (logoId?: string) => void
  value?: string
}

export const LogoDropzone = ({ onChange, value: logoId }: LogoDropzoneProps): ReactElement => {
  const { t } = useTranslation()
  const { upload, isUploading } = useUploadLogo({
    onSuccess: (file) => {
      onChange(file.id)
    },
  })

  const { url, isLoading } = useLogo(logoId)

  const handleDropAccepted: <T extends File>(files: T[], event: DropEvent) => void = useCallback(
    ([file]) => {
      upload(file)
    },
    [upload],
  )

  const handleDropRejected = useCallback(
    (filesRejected: FileRejection[]) => {
      const uploadFilesErrors = filesRejected.reduce((result, fileRejected) => {
        const fileName = fileRejected.file.name
        result[fileName] = fileRejected.errors.map((error) => error.message).join(', ')
        return result
      }, {} as UploadFilesErrors)

      notify({
        id: NotificationKeys.UmbrellaProfileLogoUploadRejection,
        message: UploadFilesErrorMessage({ uploadFilesErrors }),
        title: t('file.rejected.count', { count: filesRejected.length }),
        variant: 'warning',
      })
    },
    [t],
  )

  const handleDeleteLogo = useCallback(() => {
    onChange('')
  }, [onChange])

  if (isLoading) {
    return <SkeletonBox fullHeight fullWidth />
  }

  if (url) {
    return (
      <FilesPreview
        circle
        files={[{ src: url }]}
        fitToHeight
        fitType="cover"
        onDelete={handleDeleteLogo}
        withDeleteButton
      />
    )
  }

  return (
    <Dropzone
      accept={ACCEPTED_LOGO_FILE_FORMATS}
      contentText={t('umbrella.admin_profile.dropzone_title')}
      isUploading={isUploading}
      maxFiles={1}
      maxSize={formatMegabytesToBytes(5)}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
    />
  )
}
