import { Account } from '../app/accounts/types'
import {
  ACCOUNTS_RECEIVED,
  ACCOUNTS_REQUEST,
  FILE_RECEIVED,
  FILE_REQUEST,
  INBOX_ACTION_REQUEST,
  TAX_RATES_RECEIVED,
  TAX_RATES_REQUEST,
  VOUCHER_ACTION_CUSTOM_REQUEST,
  VOUCHER_ACTION_RECIEVED,
  VOUCHER_ACTION_REQUEST,
  VOUCHER_ACTION_REQUEST_FAILED,
  VOUCHER_DELETE,
  VOUCHER_DISCARD,
  VOUCHER_REQUEST_MORE_INFO,
  VOUCHERS_NAVIGATION_RECEIVED,
  VOUCHERS_NAVIGATION_REQUEST,
} from './actions'
import {
  FileRequestType,
  InboxActionType,
  TaxRate,
  VoucherActionReceivedType,
  VoucherActionRequestFailedType,
  VoucherActionRequestType,
  VoucherDiscardType,
  VoucherNavigationReceived,
  VoucherNavigationRequestPayload,
  VoucherRequestMoreInfoType,
  VoucherRequestType,
} from './types'

export function taxRatesRequest(organizationId: string) {
  return {
    payload: organizationId,
    type: TAX_RATES_REQUEST,
  }
}

export function taxRatesReceived(taxRates: TaxRate[]) {
  return {
    payload: taxRates,
    type: TAX_RATES_RECEIVED,
  }
}

export function accountsRequest(organizationId: string) {
  return {
    payload: organizationId,
    type: ACCOUNTS_REQUEST,
  }
}

export function accountsReceived(accounts: Account[]) {
  return {
    payload: accounts,
    type: ACCOUNTS_RECEIVED,
  }
}

export function inboxActionRequest(payload: InboxActionType) {
  return {
    payload,
    type: INBOX_ACTION_REQUEST,
  }
}

export function voucherActionRequest(payload: VoucherActionRequestType) {
  return {
    payload,
    type: VOUCHER_ACTION_REQUEST,
  }
}

export function voucherActionCustomRequest(payload: VoucherActionRequestType) {
  return {
    payload,
    type: VOUCHER_ACTION_CUSTOM_REQUEST,
  }
}

export function voucherActionRequestFailed(payload: VoucherActionRequestFailedType) {
  return {
    payload,
    type: VOUCHER_ACTION_REQUEST_FAILED,
  }
}

export function voucherActionRecieved(payload: VoucherActionReceivedType) {
  return {
    payload,
    type: VOUCHER_ACTION_RECIEVED,
  }
}

export function voucherDelete(payload: VoucherRequestType) {
  return {
    payload,
    type: VOUCHER_DELETE,
  }
}

export function voucherRequestMoreInfo(payload: VoucherRequestMoreInfoType) {
  return {
    payload,
    type: VOUCHER_REQUEST_MORE_INFO,
  }
}

export function voucherDiscard(payload: VoucherDiscardType) {
  return {
    payload,
    type: VOUCHER_DISCARD,
  }
}

export function vouchersNavigationRequest(payload: VoucherNavigationRequestPayload) {
  return {
    payload,
    type: VOUCHERS_NAVIGATION_REQUEST,
  }
}

export function vouchersNavigationReceived(payload: VoucherNavigationReceived) {
  return {
    payload,
    type: VOUCHERS_NAVIGATION_RECEIVED,
  }
}

export function fileRequest(payload: FileRequestType) {
  return {
    payload,
    type: FILE_REQUEST,
  }
}

export function fileReceived(file: File) {
  return {
    payload: file,
    type: FILE_RECEIVED,
  }
}
