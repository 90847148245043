import colors from '../colors'

export default {
  hoverMenu: {
    color: colors.white,
    whiteSpace: 'nowrap',
    background: colors.menuActive,
  },
  hoverMenuItem: {
    color: colors.white,
    fontWeight: 400,
    '&:hover': {
      '> div': {
        background: colors.primaryDarkend,
      },
    },
  },
  hoverMenuDivider: {
    background: colors.primaryDarkend,
  },
}
