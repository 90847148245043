import { SkeletonText } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { BulkMode } from '../../enums/bulkMode'
import { useBulkAttachmentsNavigation } from '../../hooks/useBulkAttachmentsNavigation'
import { useBulkBillsNavigation } from '../../hooks/useBulkBillsNavigation'
import { useBillForm } from '../BillEditableForm'

export const BillEditableTitle = (): ReactElement => {
  const { t } = useTranslation()
  const { billId, bulkEditMode, isCreditNote, isNewCreditNote, isCreditNoteFromBill } = useBillForm()
  const { billsToGo, isLoading: isBulkBillLoading } = useBulkBillsNavigation()
  const { attachmentsToGo, isLoading: isBulkAttachmentLoading } = useBulkAttachmentsNavigation()
  const showAsCreditNote = isCreditNote || isNewCreditNote || isCreditNoteFromBill

  // Bulk bill edit
  if (bulkEditMode === BulkMode.Bill) {
    if (isBulkBillLoading) {
      return <SkeletonText variant="h1" width={200} />
    }

    return <>{t('bills.bulk_edit_bill.title', { total: billsToGo })}</>
  }

  // Bulk attachment edit
  if (bulkEditMode === BulkMode.Attachment) {
    if (isBulkAttachmentLoading) {
      return <SkeletonText variant="h1" width={200} />
    }

    return <>{t('bills.bulk_edit_attachment.title', { total: attachmentsToGo })}</>
  }

  // Edit
  if (billId) {
    return <>{t(showAsCreditNote ? 'bills.edit_credit_note.title' : 'bills.edit.title')}</>
  }

  // New
  return <>{t(showAsCreditNote ? 'bills.new_credit_note.title' : 'bills.new.title')}</>
}
