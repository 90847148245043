import { CountryFlag } from '@components'
import { Language, TabButtonsGroup } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { getAppBuildTheme } from '../../../../utils/getAppBuildTheme'
import { useSignupGuide } from '../../context/signupGuideContext'
import * as Styled from './styles'

const theme = getAppBuildTheme()

interface LanguageData {
  value: Language
  flagIcon: () => ReactElement
}

const languages: LanguageData[] = [
  { value: 'da', flagIcon: () => <CountryFlag countryCode="dk" /> },
  { value: 'en', flagIcon: () => <CountryFlag countryCode="us" /> },
]

if (theme === 'ageras') {
  languages.push({ value: 'fr', flagIcon: () => <CountryFlag countryCode="fr" /> })
}

export const LanguageSwitcher = (): ReactElement | null => {
  const { language, setLanguage, isInternalFlow, isInternalUmbrellaFlow } = useSignupGuide()

  const handleClick = useCallback(
    (value: Language) => () => {
      setLanguage(value)
    },
    [setLanguage],
  )

  if (isInternalFlow || isInternalUmbrellaFlow) {
    return null
  }

  return (
    <Styled.LanguageSwitcherWrapper>
      <TabButtonsGroup size="m">
        {languages.map(({ value, flagIcon: FlagIcon }) => (
          <TabButtonsGroup.Button key={value} size="m" onClick={handleClick(value)} selected={language === value}>
            <Styled.IconWrapper>
              <FlagIcon />
            </Styled.IconWrapper>
          </TabButtonsGroup.Button>
        ))}
      </TabButtonsGroup>
    </Styled.LanguageSwitcherWrapper>
  )
}
