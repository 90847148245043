import { useMutation } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { APIError } from '../../../utils'
import { fetchSalesWithoutVATFile } from '../query-api'
import { getStartEndDateFromPeriod } from '../utils/getStartEndDateFromPeriod'
import { useSalesTaxListFilters } from './useSalesTaxListFilters'

interface UseDownloadReportArgs {
  onError?: (error: APIError) => void
  onSuccess?: (payload: Response) => Promise<void>
}

export const useDownloadReport = ({ onError, onSuccess }: UseDownloadReportArgs = {}) => {
  const [{ period: queryParamPeriod }] = useSalesTaxListFilters()
  const { fromDate, toDate } = getStartEndDateFromPeriod(queryParamPeriod) || {}
  const { organization } = useUserOrganization()

  const { isLoading: isDownloading, mutate: download } = useMutation(
    () =>
      fetchSalesWithoutVATFile({
        fromDate: fromDate || '',
        organizationId: organization?.id || '',
        toDate: toDate || '',
      }),
    {
      onError,
      onSuccess,
    },
  )

  return {
    download,
    isDownloading,
  }
}
