import noop from 'lodash/noop'
import React, { ReactElement, RefObject, useCallback, useEffect, useRef } from 'react'

import { Pagination as PaginationComponent } from '../../../Pagination'
import { ItemsPerPage } from '../../hooks/useItemsPerPage'
import { TableItemsAmountSelect } from '../TableItemsAmountSelect'
import { TablePaginationSummary } from '../TablePaginationSummary'
import * as Styled from './styles'

export interface TablePaginationProps {
  currentPage?: number
  isLoading?: boolean
  itemsPerPage: number
  onChange: (page: number) => void
  onSelectChange?: (itemsPerPage: ItemsPerPage) => void
  /*
   * Scroll to top part of table after changing page with pagination.
   * */
  scrollTopOnChange?: boolean
  tableRef: RefObject<HTMLDivElement>
  totalItems: number
  withItemsPerPageSelect?: boolean
  withTableSummary?: boolean
}

export const TablePagination = ({
  currentPage = 1,
  isLoading = false,
  itemsPerPage,
  onChange,
  onSelectChange = noop,
  scrollTopOnChange = true,
  tableRef,
  totalItems,
  withItemsPerPageSelect,
  withTableSummary,
  ...paginationProps
}: TablePaginationProps): ReactElement => {
  const isPageChanged = useRef(false)

  const isSkeletonView = totalItems === 0 && isLoading
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  useEffect(() => {
    const isScrollTopOnChangeActive = !!(scrollTopOnChange && tableRef.current)

    if (!isLoading && isPageChanged.current && isScrollTopOnChangeActive) {
      isPageChanged.current = false
      tableRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, isLoading])

  const handlePageChange = useCallback(
    (page: number) => {
      isPageChanged.current = true
      onChange(page)
    },
    [onChange],
  )

  return (
    <Styled.TablePaginationWrapper>
      {withItemsPerPageSelect && (
        <TableItemsAmountSelect isSkeletonView={isSkeletonView} itemsPerPage={itemsPerPage} onChange={onSelectChange} />
      )}
      <Styled.SideActions>
        {withTableSummary && (
          <TablePaginationSummary
            isSkeletonView={isSkeletonView}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            totalItems={totalItems}
          />
        )}
        <PaginationComponent
          {...paginationProps}
          currentPage={currentPage}
          isSkeletonView={isSkeletonView}
          onChange={handlePageChange}
          totalPages={totalPages}
        />
      </Styled.SideActions>
    </Styled.TablePaginationWrapper>
  )
}
