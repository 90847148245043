import { Link, Text, useTheme } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

export const HelpModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const {
    ds: { data },
  } = useTheme()

  const { email, phone } = data.menu.help

  return (
    <Styled.FooterWrapper>
      <Text colorVariant="secondary" variant="micro">
        {t('help.modal.footer.cannot_find')}
      </Text>
      <Styled.Info>
        <Styled.FooterContactItem>
          <Text variant="micro" colorVariant="secondary">
            {t('help.modal.footer.call_us')}
            :&nbsp;
          </Text>
          <Text variant="micro">
            <Link href={`tel:${phone}`} target="_blank" rel="noopener noreferrer">
              {phone}
            </Link>
          </Text>
        </Styled.FooterContactItem>
        <Styled.FooterContactItem>
          <Text variant="micro" colorVariant="secondary">
            {t('help.modal.footer.mail_us')}
            :&nbsp;
          </Text>
          <Text variant="micro">
            <Link href={`mailto:${email}`} rel="noopener noreferrer" target="_blank">
              {email}
            </Link>
          </Text>
        </Styled.FooterContactItem>
      </Styled.Info>
    </Styled.FooterWrapper>
  )
}
