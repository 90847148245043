import { EmptyView, emptyViewVariants } from '@components'
import { ModuleLayout } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { RecurringInvoicesRoute } from '../../enums/recurringInvoicesRoute'
import { useIsRecurringInvoicesListEmpty } from '../../hooks/useIsRecurringInvoicesListEmpty'

export const RecurringInvoicesListEmpty = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const isRecurringInvoicesListEmpty = useIsRecurringInvoicesListEmpty()

  const handleEmptyViewClick = useCallback(() => {
    navigate(EmberRoute.RecurringInvoicesNew)
  }, [navigate])

  const { animation, description, title, buttonText } = emptyViewVariants.recurringInvoices

  if (!isRecurringInvoicesListEmpty) {
    return <Redirect to={RecurringInvoicesRoute.List} />
  }

  return (
    <ModuleLayout title={t('recurring_invoices')}>
      <EmptyView
        animation={animation}
        onClick={handleEmptyViewClick}
        scopes={Scope.RecurringInvoiceWrite}
        showCTA={!!buttonText?.length}
        trackingContext={TrackingContext.CreateRecurringInvoice}
        tButton={buttonText}
        tDescription={description}
        tTitle={title}
      />
    </ModuleLayout>
  )
}
