import { FormItemsGroup, Space, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../hooks'
import { CreateOrEditVatRateFromTemplateFormSchema } from '../../utils/formData'
import { VatRatesTemplatesSelect } from '../VatRatesTemplatesSelect'

export const CreateVatRateTemplateSelection = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<CreateOrEditVatRateFromTemplateFormSchema>()

  return (
    <>
      <Text>{t('settings.organization.vat_rates.modals.create_or_edit_vat_rate_from_template.description')}</Text>
      <Space size="l" />
      <FormItemsGroup itemsInRow={1}>
        <FormItem
          name="predefinedTag"
          render={({ field: { value, onChange, ...props } }) => (
            <VatRatesTemplatesSelect
              {...props}
              allowClear
              dropdownSize="fitTrigger"
              onSelect={(id) => {
                onChange(id)
              }}
              selectedId={value}
              placeholder={t(
                'settings.organization.vat_rates.modals.create_or_edit_vat_rate_from_template.template_select_placeholder',
              )}
            />
          )}
        />
      </FormItemsGroup>
    </>
  )
}
