import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'

const IconWrapper = styled.figure`
  display: flex;
  padding-top: ${Spacing.XXS};
`

export const IconLeftWrapper = styled(IconWrapper)`
  margin-right: ${Spacing.M};
`

export const IconRightWrapper = styled(IconWrapper)`
  margin-left: auto;
`

export const SubItemsList = styled.ul``
