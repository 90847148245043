import { Dropzone, DropzoneProps } from '@design-system'

import React, { ReactElement } from 'react'

import { WithTrackingContext } from '../../../types/withTrackingContext'
import { DropzonePremium } from '../../PremiumComponents'
import { ProtectableProps, ProtectedComponent } from '../ProtectedComponent'
import { ProtectedInfo } from '../ProtectedInfo'

type DropzoneProtectedProps = ProtectableProps & DropzoneProps & WithTrackingContext

export const DropzoneProtected = ({
  scopes,
  scopesCheckDisabled,
  trackingContext,
  ...props
}: DropzoneProtectedProps): ReactElement => (
  <ProtectedComponent
    scopes={scopes}
    scopesCheckDisabled={scopesCheckDisabled}
    unauthorizedNode={<Dropzone blockedText={<ProtectedInfo />} {...props} isBlocked />}
  >
    <DropzonePremium trackingContext={trackingContext} {...props} />
  </ProtectedComponent>
)
