import { useMonth } from '@datepicker-react/hooks'
import { format, Locale } from 'date-fns'
import React, { ReactElement } from 'react'

import { useLocale } from '../../../../../hooks/useLocale'
import { languageToDateLocale } from '../../../../utils/languageToDateLocale'
import { Text } from '../../../Text'
import { useDatePickerContext } from '../../contexts/datePickerContext'
import { Day } from '../Day'
import { MonthHeaderActions } from './elements/MonthHeaderActions'
import * as Styled from './styles'

interface MonthProps {
  year: number
  month: number
}

export const Month = ({ year, month }: MonthProps): ReactElement => {
  const { firstDayOfWeek } = useDatePickerContext()
  const { code } = useLocale()

  const locale: Locale = languageToDateLocale[code]

  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
    weekdayLabelFormat: (date: Date) => format(date, 'EEEEEE', { locale }),
    monthLabelFormat: (date: Date) => format(date, 'MMMM', { locale }),
  })

  return (
    <Styled.MonthWrapper>
      <MonthHeaderActions month={month} monthLabel={monthLabel} year={year} />

      <Styled.WeekdaysList>
        {weekdayLabels.map((dayLabel) => (
          <Styled.Weekday key={dayLabel}>
            <Text alignment="center" colorVariant="secondary" variant="micro">
              {dayLabel}
            </Text>
          </Styled.Weekday>
        ))}
      </Styled.WeekdaysList>

      <Styled.DaysList>
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return <Day date={day.date} key={day.date.toString()} dayLabel={day.dayLabel} />
          }

          return <div key={index} />
        })}
      </Styled.DaysList>
    </Styled.MonthWrapper>
  )
}
