import styled from '@emotion/styled'
import { darken } from 'polished'
import React, { ElementType } from 'react'

import { Themable } from '../../types/themable'

export interface InfoBadgeProps {
  amount: number
  onClick?: (event: React.MouseEvent) => void
}

export interface InfoBadgeComponentProps extends Themable {
  as?: ElementType
  isClickable: boolean
}

const MAX_AMOUNT = 99

const InfoBadgeComponent = styled.div<InfoBadgeComponentProps>`
  display: inline-flex;
  min-width: 0;
  height: 19px;
  padding: 2px 7px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.buttons.warning.background};
  color: ${({ theme }) => theme.colors.text.inverse};
  font-size: ${({ theme }) => theme.text.small2.fontSize}px;
  font-weight: ${({ theme }) => theme.text.small2.fontWeight};
  line-height: 1;

  ${({ isClickable, theme }) =>
    isClickable &&
    `
      border: 0;
      cursor: pointer;
      text-align: center;
      
      &:hover {
        background-color: ${darken(0.1, theme.buttons.warning.background)}};
      }
      &:active {
        background-color: ${darken(0.15, theme.buttons.warning.background)}};
      }
    `}
`

export const InfoBadge = ({ amount, onClick, ...rest }: InfoBadgeProps) => {
  const isClickable = typeof onClick === 'function'
  const tag = isClickable ? 'button' : 'span'
  const value = amount > MAX_AMOUNT ? `${MAX_AMOUNT}+` : amount

  return (
    <InfoBadgeComponent {...rest} as={tag} isClickable={isClickable} onClick={onClick}>
      {value}
    </InfoBadgeComponent>
  )
}
