import { Direction } from '../../hooks/usePagination'
import { NavigationDirection } from '../../types/navigationDirection'
import { TabButtonsGroup } from '../TabButtonsGroup'
import { Text } from '../Text'
import * as Styled from './styles'

interface FilesPaginationProps {
  activePage: number
  amountOfPages: number
  canClickButton: (direction: Direction) => boolean
  navigationDirection?: NavigationDirection
  onPageChange: (direction: Direction) => void
}

export const FilesPagination = ({
  activePage,
  amountOfPages,
  canClickButton,
  navigationDirection,
  onPageChange,
}: FilesPaginationProps) => {
  const isBackButtonDisabled = !canClickButton(Direction.Back)
  const isNextButtonDisabled = !canClickButton(Direction.Next)

  const handleButtonClick = (direction: Direction, isDisabled: boolean) => {
    if (!isDisabled) {
      onPageChange(direction)
    }
  }

  const isVerticalNavigation = navigationDirection === 'vertical'

  return (
    <Styled.FilesPaginationComponent>
      <TabButtonsGroup size="m">
        <TabButtonsGroup.Button
          icon={isVerticalNavigation ? 'chevronUp' : 'chevronLeft'}
          disabled={isBackButtonDisabled}
          onClick={() => handleButtonClick(Direction.Back, isBackButtonDisabled)}
        />
        <TabButtonsGroup.Button
          icon={isVerticalNavigation ? 'chevronDown' : 'chevronRight'}
          disabled={isNextButtonDisabled}
          onClick={() => handleButtonClick(Direction.Next, isNextButtonDisabled)}
        />
      </TabButtonsGroup>
      <Text variant="large">{`${activePage}/${amountOfPages}`}</Text>
    </Styled.FilesPaginationComponent>
  )
}
