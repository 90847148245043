import { Color, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const PlanCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin-top: ${Spacing.XL};
  text-align: center;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid ${Color.Gray40};
`

export const PlanHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
`

interface CardTopProps {
  disabled?: boolean
}

export const CardTop = styled.div<CardTopProps>`
  padding: ${Spacing.XL} ${Spacing.XXL} 0;
  text-align: center;
  opacity: ${({ disabled }) => (disabled ? '30%' : '100%')}};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')}};
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

interface CardCenterProps {
  disabled?: boolean
}

export const CardCenter = styled.div<CardCenterProps>`
  flex-grow: 1;
  padding: ${Spacing.S} ${Spacing.XXL} ${Spacing.XL};
  margin-top: -2px;
  opacity: ${({ disabled }) => (disabled ? '30%' : '100%')}};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')}};
`

export const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`

export const FeaturesLabelText = styled(Text)`
  margin-top: ${Spacing.M};
  margin-bottom: ${Spacing.M};
`
