import { IconButton, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const MenuActionsWrapper = styled.div`
  display: flex;
  margin-bottom: ${Spacing.L};
`

export const SearchIconButton = styled(IconButton)`
  margin-left: ${Spacing.S};
  flex-shrink: 0;
`
