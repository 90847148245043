import { encodePeriodValue, ProcessingOverlay } from '@components'
import { notify } from '@design-system'

import { subYears } from 'date-fns'
import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useBankLineMatches } from '@modules-deprecated/bankReconciliation/hooks'
import { useFetchBillsSummary } from '@views/bills/routes/BillsList/hooks/useFetchBillsSummary'
import { useFetchInvoicesSummary } from '@views/invoices/routes/InvoicesList/hooks/useFetchInvoicesSummary'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { PeriodMode } from '../../../enums/periodMode'
import { useForm } from '../../../hooks'
import { useRequestAccountant } from '../hooks/useRequestAccountant'
import { useSubscriptionMetrics } from '../hooks/useSubscriptionMetrics'
import { FindAccountantForm, getDefaultValues, getValidationSchema } from '../utils/formData'

interface ContextState {
  isSaving: boolean
  isSubmitted: boolean
  submitForm: () => void
}

const FindAccountantContext = createContext<ContextState | undefined>(undefined)

interface FindAccountantContextProviderProps {
  bankAccountId: string
  children: ReactNode
}

export const FindAccountantContextProvider = ({
  bankAccountId,
  children,
}: FindAccountantContextProviderProps): ReactElement => {
  const { t } = useTranslation()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { organization } = useUserOrganization()

  const summaryPeriod = useMemo(
    () =>
      encodePeriodValue(
        { customDate: { startDate: subYears(new Date(), 1), endDate: new Date() }, mode: PeriodMode.CustomDate },
        organization?.id || '',
      ),
    [organization?.id],
  )

  const { data: subscriptionMetrics, isLoading: areSubscriptionMetricsLoading } = useSubscriptionMetrics()
  const { data: invoicesSummary, isLoading: areInvoicesSummaryLoading } = useFetchInvoicesSummary({
    period: summaryPeriod,
  })
  const { data: billsSummary, isLoading: areBillsSummaryLoading } = useFetchBillsSummary({
    period: summaryPeriod,
  })
  const { bankLineMatches, isLoading: areBankLinesMatchesLoading } = useBankLineMatches({
    accountIds: [bankAccountId],
    isApproved: false,
    entryDatePeriod: summaryPeriod,
  })
  const { Form, handleSubmit, reset } = useForm({
    defaultValues: getDefaultValues({
      bankTransactions: bankLineMatches[0]?.bankLineMatches.length,
      bills: billsSummary?.all.count,
      invoices: invoicesSummary?.all.count,
      revenue: subscriptionMetrics?.revenue,
    }),
    validationSchema: useMemo(() => getValidationSchema(), []),
  })
  const { isSaving, request } = useRequestAccountant({
    onError: () => {
      notify({ id: NotificationKeys.FindAccountant, message: t('error_saving'), variant: 'error' })
    },
    onSuccess: () => {
      setIsSubmitted(true)
      notify({
        id: NotificationKeys.FindAccountant,
        message: t('find_accountant.success_message'),
        variant: 'success',
      })
    },
  })

  // Update values
  useEffect(() => {
    if (
      !areSubscriptionMetricsLoading &&
      !areInvoicesSummaryLoading &&
      !areBillsSummaryLoading &&
      !areBankLinesMatchesLoading
    ) {
      reset(
        getDefaultValues({
          bankTransactions: bankLineMatches[0] ? bankLineMatches[0]?.bankLineMatches.length : undefined,
          bills: billsSummary?.all.count,
          invoices: invoicesSummary?.all.count,
          revenue: subscriptionMetrics?.revenue,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areBankLinesMatchesLoading, areBillsSummaryLoading, areInvoicesSummaryLoading, areSubscriptionMetricsLoading])
  const handleSubmitForm = useCallback(
    (values: FindAccountantForm) => {
      request({
        bills: values.summary.bills || '',
        bankTransactions: values.summary.bankTransactions || '',
        city: organization?.city,
        companyName: organization?.name || '',
        companyType: organization?.companyType,
        cvr: organization?.registrationNo || '',
        description: values.summary.description || '',
        email: organization?.email,
        expectations: values.expectations || '',
        invoices: values.summary.invoices || '',
        issues: values.issue || [],
        phone: organization?.phone,
        postToSheets: true,
        postToSlack: true,
        revenue: values.summary.revenue || '',
        street: organization?.street,
        zipcode: organization?.zipcode,
      })
    },
    [organization, request],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return (
    <FindAccountantContext.Provider value={{ isSaving, isSubmitted, submitForm }}>
      {isSaving && <ProcessingOverlay />}
      <Form>{children}</Form>
    </FindAccountantContext.Provider>
  )
}

export const useFindAccountant = () => {
  const context = useContext(FindAccountantContext)

  if (!context) {
    throw new Error('FindAccountantContextProvider is missing in the module!')
  }

  return context
}
