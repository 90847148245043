import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { SkeletonText } from '../../../SkeletonText'
import { Text } from '../../../Text'
import * as Styled from './styles'

interface TablePaginationSummaryProps {
  currentPage: number
  isSkeletonView?: boolean
  itemsPerPage: number
  totalItems: number
}

export const TablePaginationSummary = ({
  currentPage,
  isSkeletonView,
  itemsPerPage,
  totalItems,
}: TablePaginationSummaryProps): ReactElement => {
  const { t } = useTranslation()
  const firstItemIndex = itemsPerPage * (currentPage - 1) + 1
  const lastItemCountedIndex = itemsPerPage * currentPage
  const lastItemIndex = lastItemCountedIndex > totalItems ? totalItems : lastItemCountedIndex

  return (
    <Styled.TablePaginationSummaryWrapper>
      {isSkeletonView ? (
        <SkeletonText width={170} variant="normal" />
      ) : (
        <Text colorVariant="secondary">
          {t('table.pagination.summary', {
            firstItemIndex,
            lastItemIndex,
            total: totalItems,
          })}
        </Text>
      )}
    </Styled.TablePaginationSummaryWrapper>
  )
}
