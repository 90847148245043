import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const AccountingInfoSelectWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: ${Spacing.S};
`
