import { Color, DefaultValue, pxToNumber, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

interface DraggableItemWrapperProps {
  dragging?: boolean
}

export const DraggableItemWrapper = styled.div<DraggableItemWrapperProps>`
  position: relative;
  // Bug workaround https://github.com/atlassian/react-beautiful-dnd/issues/1881#issuecomment-1652549194
  left: auto !important;

  ${({ dragging }) =>
    dragging &&
    css`
      opacity: 0.75;

      // Overlay
      &:after {
        content: '';
        border: 1px solid ${Color.Gray20};
        border-radius: ${DefaultValue.BorderRadius};
        position: absolute;
        top: ${Spacing.XXS};
        right: -${Spacing.XL};
        bottom: -${Spacing.XXS};
        left: -${Spacing.XL};
        z-index: -1;
        background: ${Color.White};
      }
    `}
`

const DRAG_ZONE_SPACING = pxToNumber(Spacing.XL) - pxToNumber(Spacing.XXS)

export const DragZone = styled.div`
  display: flex;
  position: absolute;
  top: ${DRAG_ZONE_SPACING}px;
  left: -${DRAG_ZONE_SPACING}px;
`
