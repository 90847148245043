import React from 'react'
import { Box } from 'rebass'

import { IconProps } from './Icon'

export const BaseballBilly = ({ width, height, ...rest }: IconProps) => {
  return (
    <Box as="i" {...rest}>
      <svg width={width || 245} height={height || 245} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 917.8 781.7">
        <defs>
          <radialGradient
            id="a"
            cx="439.17"
            cy="658.65"
            r="109.13"
            gradientTransform="matrix(0, 1, -1, 0, 1026.73, 167.57)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.08" stopColor="#FFD4CC" />
            <stop offset="0.53" stopColor="#FED3CA" />
            <stop offset="0.72" stopColor="#FACEC3" />
            <stop offset="0.86" stopColor="#F2C6B6" />
            <stop offset="0.98" stopColor="#E8BAA5" />
            <stop offset="1" stopColor="#E5B7A0" />
          </radialGradient>
          <radialGradient id="b" cx="359.48" cy="608.44" r="197.76" gradientUnits="userSpaceOnUse">
            <stop offset="0.08" stopColor="#FFD4CC" />
            <stop offset="0.59" stopColor="#F2C5B6" />
            <stop offset="1" stopColor="#E5B7A0" />
          </radialGradient>
          <radialGradient id="c" cx="762.91" cy="516.55" r="156.72" gradientUnits="userSpaceOnUse">
            <stop offset="0.08" stopColor="#FFD4CC" />
            <stop offset="0.34" stopColor="#FCD0C6" />
            <stop offset="0.68" stopColor="#F2C6B6" />
            <stop offset="1" stopColor="#E5B7A0" />
          </radialGradient>
          <linearGradient
            id="d"
            x1="823.96"
            y1="267.25"
            x2="802.33"
            y2="515.8"
            gradientTransform="translate(-12.8 60.01) rotate(-7.63)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#A8835E" />
            <stop offset="0.32" stopColor="#A5815D" />
            <stop offset="0.57" stopColor="#9B7A5B" />
            <stop offset="0.79" stopColor="#8B6F57" />
            <stop offset="0.99" stopColor="#745F52" />
            <stop offset="1" stopColor="#735E52" />
          </linearGradient>
          <radialGradient
            id="e"
            cx="358.26"
            cy="451.77"
            r="153.1"
            gradientTransform="translate(90.01 9.82) rotate(2.34)"
            href="#a"
          />
          <linearGradient
            id="f"
            x1="2538.55"
            y1="309.97"
            x2="2594.91"
            y2="390.07"
            gradientTransform="matrix(-1, -0.04, -0.04, 1, 2863.23, 123.37)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.23" stopColor="#5C4A3C" />
            <stop offset="0.48" stopColor="#736357" />
          </linearGradient>
          <linearGradient
            id="g"
            x1="232.21"
            y1="297.69"
            x2="189.06"
            y2="265.2"
            gradientTransform="translate(90.01 9.82) rotate(2.34)"
            href="#f"
          />
          <linearGradient
            id="h"
            x1="477.54"
            y1="302.9"
            x2="535.46"
            y2="385.21"
            gradientTransform="translate(90.01 9.82) rotate(2.34)"
            href="#f"
          />
          <radialGradient
            id="i"
            cx="369.64"
            cy="277.82"
            r="140.44"
            gradientTransform="translate(90.01 9.82) rotate(2.34)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#817469" />
            <stop offset="0.39" stopColor="#7F7267" />
            <stop offset="0.61" stopColor="#796A5F" />
            <stop offset="0.71" stopColor="#736357" />
          </radialGradient>
          <linearGradient
            id="j"
            x1="428.07"
            y1="421.52"
            x2="441.7"
            y2="397.93"
            gradientTransform="translate(90.01 9.82) rotate(2.34)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#323D3D" />
            <stop offset="1" stopColor="gray" />
          </linearGradient>
          <linearGradient id="k" x1="271.37" y1="421.52" x2="284.99" y2="397.93" href="#j" />
          <linearGradient
            id="l"
            x1="192.09"
            y1="417.83"
            x2="522.83"
            y2="417.83"
            gradientTransform="translate(90.01 9.82) rotate(2.34)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#474D59" />
            <stop offset="1" stopColor="#526C7F" />
          </linearGradient>
        </defs>
        <title>baseball_billy</title>
        <g style={{ isolation: 'isolate' }}>
          <circle cx="376.63" cy="401.17" r="348.49" fill="#9BB2C4" />
          <path
            d="M780.19,536.77c0-193-156.44-349.42-349.42-349.42S81.34,343.79,81.34,536.77A347.82,347.82,0,0,0,149,743.48c35.35-44.18,73.62-81.62,100.18-86.93l81.54-24.93,2,1.71,30.71-6.05,2.73-12.62h0l2.54-11.7h0l2.55-11.78,96.88,21-2.55,11.78h0L463,635.79h0L461,645.41l24.22,19.78,3-.87,70.53,57.47L506.41,878C663,843.39,780.19,703.77,780.19,536.77Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M211.15,808.54q6.75,5.46,13.76,10.58l2.8-26.42C221.38,798.44,216.09,803.59,211.15,808.54Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M366.89,625.07S338,628.6,333.08,630.7s1.88,5.64,1.88,5.64l33.81-7.52Z"
            transform="translate(-53.2 -136.54)"
            fill="#006DCC"
          />
          <path
            d="M485.18,665.19,461,645.41l2.08-9.62c-17.49,6.16-35.42,9-51.53,5.69h0l-.28-.07-.29,0v0c-16.13-3.64-31.33-13.75-44.76-26.69l-2.73,12.62-30.71,6.05,65.56,56.52Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <rect
            x="412.41"
            y="558"
            width="12.06"
            height="99.13"
            transform="translate(-317.13 751.36) rotate(-77.78)"
            fill="#2766F1"
          />
          <rect
            x="412.41"
            y="558"
            width="12.06"
            height="99.13"
            transform="translate(-317.13 751.36) rotate(-77.78)"
            fill="url(#a)"
          />
          <polygon points="409.84 499.25 412.41 487.4 412.41 487.4 409.84 499.25 409.84 499.25" fill="#2766F1" />
          <polygon
            points="409.84 499.25 412.41 487.4 412.41 487.4 409.84 499.25 409.84 499.25"
            fill="#354A59"
            opacity="0.1"
          />
          <polygon points="315.52 466.42 312.99 478.13 312.99 478.13 315.52 466.42 315.52 466.42" fill="#2766F1" />
          <polygon
            points="315.52 466.42 312.99 478.13 312.99 478.13 315.52 466.42 315.52 466.42"
            fill="#354A59"
            opacity="0.1"
          />
          <path
            d="M410.94,641.37l.29,0,.28.07h0c16.11,3.33,34,.47,51.53-5.69l2.57-11.85-96.88-21-2.54,11.7c13.43,12.94,28.63,23.05,44.76,26.69Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <polygon
            points="407.76 508.87 409.84 499.25 412.41 487.4 315.52 466.42 312.99 478.13 310.26 490.74 279.55 496.79 285.51 520.46 319.33 546.76 345.11 553.31 377.56 558.04 417.02 552.4 437.68 522.34 407.76 508.87"
            fill="url(#b)"
          />
          <ellipse
            cx="685.05"
            cy="705.27"
            rx="9.39"
            ry="15.33"
            transform="translate(-287.38 219.85) rotate(-25.05)"
            fill="#CFDFE5"
          />
          <path
            d="M807.24,322.71c-7.33,89.92-17.66,182.12-85.29,261a226.53,226.53,0,0,1-66.51,53l30.4,73.65a314,314,0,0,0,77.44-66.55c82.87-98.16,107.09-315,107.09-315Z"
            transform="translate(-53.2 -136.54)"
            fill="url(#c)"
          />
          <path
            d="M783.59,180.45c-.71,33.82,6,68.94,19.42,105.25l-22.78,4.2c-13.77-39.74-21.9-75.88-19.43-105.24Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M813.19,172.41c-26,11.92-49.32,30.74-70.24,55.81l-14.41-18.14c23.36-26.64,46.76-47.07,70.24-55.81Z"
            transform="translate(-53.2 -136.54)"
            fill="#735E52"
          />
          <path
            d="M824.77,223.69c-26,11.93-49.32,30.75-70.24,55.81l-14.41-18.14c23.36-26.63,46.76-47.06,70.24-55.81Z"
            transform="translate(-53.2 -136.54)"
            fill="#735E52"
          />
          <path
            d="M872.85,156.11A362.2,362.2,0,0,1,866,263.17a62.28,62.28,0,0,1,7,2.68c.39-1.29.75-2.64,1.13-4a350.66,350.66,0,0,0,7.31-87q-.35-8.64-1.11-17.37c-1.48-11.63-12.09-21-22.93-21H857C857.09,136.54,871.76,137.34,872.85,156.11Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M955.26,180a28.38,28.38,0,0,0-9.21-1.47c9.59,0,21.46,15.38,16.09,43.64a392.73,392.73,0,0,1-31.4,82.61c-15,36.55-5.73,56.53-43.17,96.19-16.85,17.33-42.79,23.68-68.14,19.9,27.61,6.33,57.05.52,75.55-18.5,37.45-39.66,28.23-59.64,43.18-96.19a393.49,393.49,0,0,0,31.4-82.61C975.56,192,961.27,182,955.26,180Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M824.43,157.12a339.22,339.22,0,0,1,5.46,97.46c1.67.23,5,.82,7.41,1.4a335.86,335.86,0,0,0-2.47-80.45c-.85-5.68-1.86-11.33-3-17a23.3,23.3,0,0,0-21.34-15.29C822.17,144.22,823.23,150.84,824.43,157.12Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M921.79,170.31c-.84,37.49-10.25,73.35-23.06,107.22v0l6.86,2.88c.22-.47.43-1,.64-1.43l-.08-.05v0a366.67,366.67,0,0,0,21.67-90.83c.6-5.42,1.06-10.89,1.4-16.38.79-12.15-8.21-24.13-19.28-26.37a2.79,2.79,0,0,1-.39-.1c-.23-.05-.44,0-.66-.07C919,147,922.09,157.14,921.79,170.31Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M962.15,222.21a391.77,391.77,0,0,1-31.42,82.61c-15,36.57-5.7,56.53-43.16,96.2-16.86,17.32-42.8,23.68-68.15,19.91-31.1-7.09-59.92-29.54-68-65.74-.3-8.67-8.27-42-10-49.76-.3-1.45-.72-2.95-1.14-4.39-5.63-19.09-10.67-32.54-20.13-50.38-14.53-33.55,11-47.38,11.17-47.43a6,6,0,0,1,1.67-.46c5.84-1.07,11.21,3.19,14.41,8.35,11.91,19,22.63,42.24,30.82,63.75.62,1.62,1.24,3.24,1.82,4.86.16.38.29.71.42,1a3.2,3.2,0,0,0,.72,1.14h0a3.16,3.16,0,0,0,1.25.73,2.14,2.14,0,0,0,.48.13,3.46,3.46,0,0,0,2.39-.36c8.57-4.72,14.17-12.93,14.94-22.16a309.54,309.54,0,0,0,.8-39.74c-.12-3-.31-6.12-.5-9.2,0-.28-.06-.55-.08-.83-.14-2.11-.32-4.26-.57-6.4-.07-1.05-.19-2.08-.3-3.14-.16-2-.39-4.09-.66-6.11-.31-2.45-.66-4.91-1-7.41q-1.68-11.09-4.09-22.19c-3.26-9.73,1.89-19.26,12.12-21.51.12,0,.25-.07.36-.08a21.44,21.44,0,0,1,4.22-.44c11.66,1,12.73,7.64,13.9,13.93a337.56,337.56,0,0,1,5.48,97.43c1.69.24,5,.83,7.42,1.4a332.8,332.8,0,0,0,3.16-99.67c-1.4-10.81,5.64-19.67,16.46-19.76.16,0,14.84.79,15.93,19.56A363.25,363.25,0,0,1,866,263.17a63,63,0,0,1,7,2.67c.39-1.28.76-2.64,1.13-4,9.19-31.16,14.94-66.22,15.08-99.78.46-10,7.33-17.19,16.48-17.24h0a18.44,18.44,0,0,1,3.19.29c10.16,1.83,13.23,12,12.95,25.14-.85,37.52-10.26,73.35-23.08,107.23v0c.08,0,6.77,2.81,6.87,2.87.21-.46.42-1,.63-1.46a378.79,378.79,0,0,0,27.54-84c1.78-9,5.65-15.62,12.28-16.41C955.63,178.52,967.51,194,962.15,222.21Z"
            transform="translate(-53.2 -136.54)"
            fill="url(#d)"
          />
          <path
            d="M759.91,355.63c-.31-8.68-8.51-42.05-10.3-49.79-.34-1.47-.73-3-1.17-4.4-5.77-19.14-10.92-32.59-20.6-50.49-14.83-33.6,11.08-47.38,11.2-47.43l.13,0a10.63,10.63,0,0,0-6.21-.79,8.19,8.19,0,0,0-1.7.44c-.12.05-25.68,13.9-11.17,47.44,9.49,17.86,14.53,31.29,20.15,50.41.43,1.41.81,2.92,1.14,4.38,1.75,7.74,9.73,41.07,10,49.75,8.13,36.19,36.93,58.63,68.05,65.72a92.52,92.52,0,0,0,41.21.25,94.53,94.53,0,0,1-31.47.49C797.57,414.41,768.27,391.87,759.91,355.63Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M846.07,173.07a120.82,120.82,0,0,0-31-11,3.06,3.06,0,0,1-1.66-3.89l0-.06a2.87,2.87,0,0,1,3.6-2,121.13,121.13,0,0,1,31,11,3,3,0,0,1,1.66,3.88l0,.06A2.88,2.88,0,0,1,846.07,173.07Z"
            transform="translate(-53.2 -136.54)"
            fill="#735E52"
          />
          <path
            d="M848,156.24a146.3,146.3,0,0,0-27.44,22.06,2.68,2.68,0,0,1-4-.59l0-.05a3.32,3.32,0,0,1,.68-4.35,145.55,145.55,0,0,1,27.44-22.06,2.69,2.69,0,0,1,4,.6l0,.05A3.31,3.31,0,0,1,848,156.24Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M892.45,174.2a114.77,114.77,0,0,0-25.75-17.62,3.07,3.07,0,0,1-.7-4.05l0-.05a2.59,2.59,0,0,1,3.68-1.06A114.86,114.86,0,0,1,895.47,169a3.06,3.06,0,0,1,.69,4.05l0,0A2.58,2.58,0,0,1,892.45,174.2Z"
            transform="translate(-53.2 -136.54)"
            fill="#735E52"
          />
          <path
            d="M897.7,158.79a130.53,130.53,0,0,0-29.5,14.36,2.49,2.49,0,0,1-3.48-1.5l0-.05a3.23,3.23,0,0,1,1.53-3.94,130.26,130.26,0,0,1,29.49-14.35,2.49,2.49,0,0,1,3.48,1.49l0,.06A3.22,3.22,0,0,1,897.7,158.79Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M935.25,201.34a144.76,144.76,0,0,0-19.44-29.23,3.24,3.24,0,0,1,.69-4.29l0,0a2.72,2.72,0,0,1,4,.67A145.56,145.56,0,0,1,940,197.68a3.24,3.24,0,0,1-.69,4.29l0,0A2.72,2.72,0,0,1,935.25,201.34Z"
            transform="translate(-53.2 -136.54)"
            fill="#735E52"
          />
          <path
            d="M941.71,189.12a91,91,0,0,0-27.32-1.52c-1.28-.07-2.11-1.4-1.84-3v-.06a3,3,0,0,1,2.79-2.71,90.78,90.78,0,0,1,27.31,1.51c1.28.07,2.11,1.4,1.85,3v.06A3,3,0,0,1,941.71,189.12Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M767.8,355.13c1.48,2.06,1.44,4.62-.1,5.73l-10,7.19c-1.54,1.11-4,.34-5.46-1.71h0c-1.49-2.06-1.44-4.62.1-5.73l10-7.19c1.53-1.11,4-.34,5.46,1.71Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M777.06,372.53c1.57,2,1.62,4.56.13,5.73l-9.68,7.59c-1.49,1.17-4,.5-5.53-1.49h0c-1.56-2-1.62-4.56-.13-5.73l9.68-7.59c1.49-1.17,4-.5,5.53,1.49Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M788.31,387.28c1.84,1.73,2.29,4.26,1,5.64l-8.42,9c-1.3,1.38-3.85,1.1-5.69-.64h0c-1.85-1.73-2.3-4.26-1-5.64l8.43-9c1.29-1.38,3.84-1.1,5.69.64Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M804.54,399c2,1.53,2.72,4,1.57,5.51l-7.47,9.77c-1.15,1.51-3.72,1.48-5.73,0h0c-2-1.54-2.71-4-1.56-5.51l7.46-9.78c1.15-1.51,3.72-1.48,5.73.06Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M821.35,405.74c2.43.72,4,2.78,3.42,4.59l-3.48,11.8c-.54,1.82-2.94,2.71-5.37,2h0c-2.43-.71-4-2.77-3.43-4.59l3.49-11.8c.53-1.81,2.94-2.7,5.37-2Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M842,408.43c2.53.14,4.49,1.79,4.38,3.68l-.7,12.29c-.11,1.89-2.25,3.3-4.78,3.16h0c-2.53-.15-4.49-1.8-4.38-3.69l.7-12.28c.11-1.89,2.25-3.31,4.78-3.16Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M860.51,404.52c2.41-.78,4.84.05,5.42,1.85l3.79,11.71c.58,1.8-.9,3.9-3.31,4.68h0c-2.41.78-4.83-.05-5.42-1.85L857.2,409.2c-.58-1.8.9-3.9,3.31-4.68Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M878.55,396.51c2.08-1.45,4.64-1.36,5.72.19l7,10.09c1.08,1.56.28,4-1.8,5.44h0c-2.08,1.45-4.64,1.36-5.73-.19l-7-10.09c-1.09-1.56-.28-4,1.8-5.44Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M891.56,381.5c1.47-2.06,3.92-2.84,5.46-1.74l10,7.16c1.54,1.11,1.6,3.67.12,5.73h0c-1.47,2.06-3.92,2.84-5.46,1.73l-10-7.16c-1.54-1.1-1.6-3.66-.12-5.72Z"
            transform="translate(-53.2 -136.54)"
            fill="#4D423B"
          />
          <path
            d="M969.56,223.61c4.73-25-3.16-36.38-9.65-41.09a78.14,78.14,0,0,1-1.38,12.28c-7.37,28.89-29.28,78.63-42.7,104.73-12.05,109.34-107.87,123.13-161.38,58a4.81,4.81,0,0,1-2.78-1.24c.33,1.36.69,2.71,1.07,4l-.38.28c-1.54,1.11-1.59,3.67-.1,5.73a5.12,5.12,0,0,0,3.24,2.1,82.48,82.48,0,0,0,5.55,11.27,4.76,4.76,0,0,0,.93,4.65,5.06,5.06,0,0,0,3.5,2,83.5,83.5,0,0,0,8.42,9.81c-.81,1.43-.35,3.55,1.29,5.09s3.94,1.9,5.3.88a91.94,91.94,0,0,0,10.53,7.4,4.45,4.45,0,0,0,1.89,4.7c2,1.53,4.58,1.56,5.73,0l.38-.49a96.31,96.31,0,0,0,13.61,5.3l-.14.45c-.53,1.82,1,3.88,3.43,4.59s4.83-.17,5.37-2l.24-.8a95.59,95.59,0,0,0,15,1.9l0,.64c-.11,1.89,1.85,3.54,4.38,3.69s4.67-1.27,4.78-3.16l.06-1.16a86.11,86.11,0,0,0,15.38-2.2c.67,1.7,19.77,1.81,5.33,1.72s3.62-2.6,3.33-4.31a70.09,70.09,0,0,0,13.8-6.76l.24.35c1.09,1.55,3.65,1.64,5.73.19s2.88-3.88,1.8-5.44l-.41-.58c1.41-1.21,2.78-2.46,4.08-3.8,2.57-2.73,4.9-5.35,7.06-7.89,1.54.79,3.73.05,5.1-1.87a4.43,4.43,0,0,0,.43-5.06c22.82-30.42,17.62-49.67,30.59-81.37A393.49,393.49,0,0,0,969.56,223.61Z"
            transform="translate(-53.2 -136.54)"
            fill="#1D1D1B"
            opacity="0.2"
          />
          <path
            d="M792.09,291.89c-.6.31-6.88,4.38-10.8-.22a10.32,10.32,0,0,1-1.67-2.86c-.64-1.7-1.59-9.8-1.46-13.94.62,1.62,1.24,3.24,1.82,4.86.16.38.29.71.42,1a3.2,3.2,0,0,0,.72,1.14h0a3.16,3.16,0,0,0,1.25.73,2.14,2.14,0,0,0,.48.13,3.46,3.46,0,0,0,2.39-.36c8.57-4.72,14.17-12.93,14.94-22.16a309.54,309.54,0,0,0,.8-39.74c-.12-3-.31-6.12-.5-9.2,0-.28-.06-.55-.08-.83-.14-2.11-.32-4.26-.57-6.4a29.79,29.79,0,0,0-.3-3.14c-.16-2-.39-4.09-.66-6.11-.31-2.45-.62-4.94-1-7.41-.21-1.54-.45-3.13-.7-4.69,6,14.62,10.47,31.94,10,87C807.18,279,800.82,287.16,792.09,291.89Z"
            transform="translate(-53.2 -136.54)"
            fill="#1D1D1B"
            opacity="0.2"
          />
          <path
            d="M410.94,641.37l.29,0,.28.07h0c16.11,3.33,34,.47,51.53-5.69l2.57-11.85-96.88-21-2.54,11.7c13.43,12.94,28.63,23.05,44.76,26.69Z"
            transform="translate(-53.2 -136.54)"
            fill="#354A59"
            opacity="0.15"
          />
          <path
            d="M490.64,658.77a57.78,57.78,0,0,0,5.15-4l-3-1.49A204.11,204.11,0,0,0,462,640.71l-1,4.7Z"
            transform="translate(-53.2 -136.54)"
            fill="#006DCC"
          />
          <path
            d="M435.29,334.82c-59.26-2.43-150.09-53-154.91,50.44,0,0-.41,123.44,3,147.7s68.18,92.7,139.47,96.27v0h.37l.36,0v0c71.35,2.27,141.53-60.72,146.88-84.54s15-147,15-147C587.75,289.19,494.56,337.24,435.29,334.82Z"
            transform="translate(-53.2 -136.54)"
            fill="url(#e)"
          />
          <path
            d="M424.69,552.82c-40-1.64-66.66-17.77-67.07-18l3.07-5c.6.37,60.9,36.71,135.83,2.93l2.4,5.33A163,163,0,0,1,424.69,552.82Z"
            transform="translate(-53.2 -136.54)"
            fill="#665650"
          />
          <path
            d="M265.81,341.13l10,89.22c6.55-41.08-.28-74.76,31-99.17-.82-1.49,8.4-15.16,7.17-16.39Z"
            transform="translate(-53.2 -136.54)"
            fill="url(#f)"
          />
          <path
            d="M318.68,321.53s8.4-23.24-24.18-45.66c-1.29,1.18,8.94,23.94,2.65,26.16s-27.3.12-31.33,7.4c0,0,13.68-.68,24.63,4.74a68.19,68.19,0,0,1,18.07,13.14Z"
            transform="translate(-53.2 -136.54)"
            fill="url(#g)"
          />
          <path
            d="M605.18,353.78l-18.51,84.28c-3.17-41.48-1.46-82.25-30.66-109.14.94-1.41-.13-11.27,1.2-12.39Z"
            transform="translate(-53.2 -136.54)"
            fill="url(#h)"
          />
          <path
            d="M289.24,335.9S340.1,288,387.79,277.09c13.77-3.16,37.55-6.67,58.47-3.81,25.91,3.55,38.82,12.77,71.44,12.85,32.25.08,46.28-9.27,57.74-16.24s14.08-10.59,13.93-6.88-.23,36-15.66,49c0,0,9.86,1.65,12.49-2s-1.34,2.43-9.28,14.51-25.71,52.3-92.45,45.85S352.39,308.71,289.24,335.9Z"
            transform="translate(-53.2 -136.54)"
            fill="#354A59"
            opacity="0.1"
          />
          <path
            d="M303.2,332.75s41-53.25,88.66-64.18c13.78-3.15,37.55-6.67,58.47-3.81,25.91,3.55,38.83,12.77,71.44,12.86,32.25.08,46.29-9.28,57.74-16.25s14.08-10.59,13.93-6.88-.23,36-15.66,49c0,0,9.86,1.65,12.49-2s1.14,2.53-6.8,14.61-33.05,49.52-99.78,43.07S366.35,305.56,303.2,332.75Z"
            transform="translate(-53.2 -136.54)"
            fill="url(#i)"
          />
          <path
            d="M479.09,351.86c-62.22-8.14-108.47-46.31-165.45-26.76,57.66-17.21,104.58,24.47,167.14,30.52,66.74,6.45,91.85-31,99.79-43.07a108.61,108.61,0,0,0,5.64-9.5C574.21,321,543.26,360.25,479.09,351.86Z"
            transform="translate(-53.2 -136.54)"
            fill="#FFFFFF"
            opacity="0.09"
          />
          <path
            d="M481.06,344c-20.37-2.53-52.7-10.21-70.44-15.84l0,.38c22.55,7.35,45.76,16.18,72,18.61,60.32,5.58,82.9-25.66,90-35.74a87.74,87.74,0,0,0,5.08-7.94C566.92,318.47,539.06,351.22,481.06,344Z"
            transform="translate(-53.2 -136.54)"
            fill="#FFFFFF"
            opacity="0.05"
          />
          <path
            d="M255.25,655.9a31.29,31.29,0,0,0-6,.65c-26.56,5.31-64.83,42.75-100.18,86.93a351.29,351.29,0,0,0,62.11,65.06c4.94-4.95,10.23-10.1,16.56-15.84,6.68-6.06,14.52-12.78,24.38-20.64C272.54,755.75,259.79,658.83,255.25,655.9Z"
            transform="translate(-53.2 -136.54)"
            fill="#F0FAFF"
          />
          <path
            d="M691.9,719l-36.46-84.13c-1.05.56-2.1,1.11-3.15,1.64-56.89,29-116.07,32.65-144.5,29.89,0,0-1.57,4.17-3.7,10.85l-15.87-12.93-3,.87,6.65,5.43L450.66,693,394.8,711.66l-41-39.7-20.7-37.5-.33-1.13.33-2.63-83.86,25.85a31.29,31.29,0,0,1,6-.65c4.54,2.93,17.29,99.85-3.16,116.16-9.86,7.86-17.7,14.58-24.38,20.64l-2.8,26.42c57.73,42.17,128.88,67.59,205.86,67.08A355.56,355.56,0,0,0,511,877l36-107c37.76-5.35,85.32-16.46,137.38-47.25C685.4,722.15,690.87,719.62,691.9,719Z"
            transform="translate(-53.2 -136.54)"
            fill="#F0FAFF"
          />
          <path
            d="M561.33,692.06c-5.16-5.12-21.31-16.7-27.73-21.31-.83.32-13.16-4.43-28.93-11.77l0,.26c-3.94,26.23-47.13,41.49-96.47,34.07-36.37-5.46-65.9-21.72-77.35-40.39-23.13,12.85-38.59,31.53-41.57,53.82-6.61,49.42,50.49,97.83,127.53,108.13s100.32-32,151.45-70.84C586.29,730.33,580.19,710.82,561.33,692.06Z"
            transform="translate(-53.2 -136.54)"
            fill="#F0FAFF"
          />
          <path
            d="M411.19,673.22c-42.85-6.44-76.23-27.86-81.69-50.57a27,27,0,0,0-3.53,9.74c-3.94,26.24,32.86,53.51,82.2,60.92s92.53-7.84,96.47-34.07a50.2,50.2,0,0,0-.57-14.21C492.18,665.13,454,679.66,411.19,673.22Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <polygon points="438.63 534.08 438.24 533.77 438.03 534.4 438.63 534.08" fill="#2766F1" />
          <path d="M576.3,662.13,503,878.66l.61-.12L576.91,662Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path d="M598.69,657l-37.5,110.79.61-.11,37.52-110.86Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path
            d="M621.84,649.69,583.46,763.1l.62-.15,38.41-113.49Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M645.92,639.62,606.22,756.9l.63-.19L646.6,639.29C646.37,639.39,646.14,639.51,645.92,639.62Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path d="M662.6,651.38l-33,97.58.65-.24,32.7-96.6Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path d="M674.2,678.16l-20.55,60.71.67-.31,20.2-59.66Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path d="M685.8,704.94l-7.15,21.12.7-.39,6.77-20Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path
            d="M554.52,665.42,481,882.56l.6-.09,73.48-217.12Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path d="M533.32,667,459.53,885l.59,0L533.9,667Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path d="M514,663.18,438.5,886.09h.58L514.44,663.4Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path d="M490.53,671.32,417.89,886l.56,0,72.78-215Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path d="M465.22,685.05,397.67,884.63l.55,0,67.69-200Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path
            d="M441.21,694.94,377.83,882.19l.54.08L441.79,694.9Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path d="M420.62,694.7l-62.26,184,.53.12,62.29-184Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path d="M400.86,692l-61.61,182,.52.14,61.62-182.06Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path
            d="M381.85,687.15,320.49,868.43l.52.16,61.35-181.27Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M363.66,679.82,302.1,861.72l.5.2,61.55-181.85Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M348.74,662.84,284.06,854l.49.22L349.1,663.48Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M335.93,639.64,266.38,845.15l.48.26,69.43-205.13Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M316.57,635.79,249.06,835.25l.47.29,67.68-199.95Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M293.5,642.9,232.13,824.23c.15.11.3.22.46.32L294.13,642.7Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M270.43,650,219.56,800.3l.84-.79,50.66-149.69Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M247.41,657,199.47,798.61c.15.12.29.26.44.39L248,656.83Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M222.56,669.32,183.79,783.87l.42.43L223.3,668.81Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path d="M193.67,693.63,168.6,767.7l.41.48,25.5-75.36Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path d="M161.09,728.83,154,749.94l.39.52,7.7-22.75Z" transform="translate(-53.2 -136.54)" fill="#2766F1" />
          <path
            d="M625.6,359.53a42.79,42.79,0,0,1,5.07-.06c20.1,1.14,35.58,16.47,34.57,34.23l-3.86,67.92c-1,17.77-14.42,30.67-30.25,29.78-13-.74-12.47-9.69-11.61-24.75Z"
            transform="translate(-53.2 -136.54)"
            fill="#006DCC"
          />
          <path
            d="M247.15,338a41.55,41.55,0,0,0-5-.63c-20.09-1.14-37.2,12.33-38.21,30.1L200,435.42c-1,17.77,10.86,32.11,26.68,33,13,.74,13.49-8.21,14.34-23.27Z"
            transform="translate(-53.2 -136.54)"
            fill="#006DCC"
          />
          <path
            d="M644.29,336.93c6.79-119.62-18.12-188.78-192.48-198.68-149-8.46-211.18,44.47-218.61,175.33Z"
            transform="translate(-53.2 -136.54)"
            fill="#47A9FF"
          />
          <path
            d="M578.61,333.2C585.4,213.58,569.67,145,451,138.21c-101.37-5.76-144.72,48.24-152.15,179.1Z"
            transform="translate(-53.2 -136.54)"
            fill="#64BAF9"
          />
          <path
            d="M290.72,291a69,69,0,0,0-8.47-1.06C248.46,288,219.69,310.63,218,340.5l-6.48,114.2c-1.7,29.87,18.25,54,44.85,55.5,21.83,1.24,22.69-13.81,24.13-39.13Z"
            transform="translate(-53.2 -136.54)"
            fill="#47A9FF"
          />
          <path
            d="M586.66,307.77a69.19,69.19,0,0,1,8.53-.1c33.79,1.92,59.81,27.68,58.12,57.55l-6.49,114.2C645.13,509.29,622.57,531,596,529.49c-21.84-1.24-21-16.29-19.54-41.61Z"
            transform="translate(-53.2 -136.54)"
            fill="#47A9FF"
          />
          <path
            d="M611.22,347.49c-.87,15.35-76.34-16.74-171.37-22.13S266.2,343.3,267.07,327.94,348,264.36,443,269.75,612.1,332.13,611.22,347.49Z"
            transform="translate(-53.2 -136.54)"
            fill="#006DCC"
          />
          <path
            d="M555.46,489.9l20.76-11.81,6-24.87-1-26-9.31-19.51s-29.64-6.93-32.39-6.45-36.26-1.44-36.26-1.44l-45.78,7s-9.42,17.71-9.51,20.07,10.23,29.27,10.23,29.27l7.47,13.66s7.06,9.79,9.74,11.08,12.17,5.25,18.28,6.09,20.72,4,23.46,3.5,20-.12,20-.12Z"
            transform="translate(-53.2 -136.54)"
            fill="#FFFFFF"
          />
          <path
            d="M303.82,478.33l-19.73-13.48-3.93-25.27,3.1-25.84,10.88-18.69s30.11-4.47,32.81-3.77,36.26,1.52,36.26,1.52l45.05,10.73s7.93,18.43,7.83,20.78-12.58,28.34-12.58,28.34l-8.57,13s-7.84,9.19-10.61,10.25-12.56,4.24-18.72,4.58-21,2.27-23.67,1.57-19.87-1.75-19.87-1.75Z"
            transform="translate(-53.2 -136.54)"
            fill="#FFFFFF"
          />
          <path
            d="M520.66,438a13.23,13.23,0,1,0-13.76,12.68A13.22,13.22,0,0,0,520.66,438Z"
            transform="translate(-53.2 -136.54)"
            fill="url(#j)"
          />
          <circle cx="457.51" cy="296.73" r="3.52" fill="#FFFFFF" opacity="0.8" />
          <path
            d="M364.08,431.63a13.23,13.23,0,1,0-13.76,12.68A13.22,13.22,0,0,0,364.08,431.63Z"
            transform="translate(-53.2 -136.54)"
            fill="url(#k)"
          />
          <circle cx="300.94" cy="290.32" r="3.52" fill="#FFFFFF" opacity="0.8" />
          <path
            d="M596.73,404.8a1.44,1.44,0,0,0-1.32-1.38,589.58,589.58,0,0,0-68.24-7.12c-36.35-1.49-83.66,6.24-91.84,5.9l-7.26-.29c-3.81-.16-15.95-2.76-31.48-5.62-17.86-3.3-40.17-7-59.56-7.78-23.43-1-45.75,0-58.62.8-4.71.27-8.17.56-10,.72A1.45,1.45,0,0,0,267,391.3L266,407.81a1.46,1.46,0,0,0,1,1.37l2.17.75c3.22,1.18.91,51,17.95,64.16,10.13,7.78,19.24,12.36,58.34,14,22.65.93,37.11-3.66,51.25-18.55C406.87,458.82,416.59,433,416.59,433a14.7,14.7,0,0,1,14.12-11.18h.19a14.71,14.71,0,0,1,13.35,12.32s7.56,26.57,16.85,38.05c12.87,16,26.91,21.75,49.56,22.68,39.1,1.6,48.55-2.22,59.29-9.14C588,474,589.77,424.11,593.08,423.19l2.23-.57a1.44,1.44,0,0,0,1.11-1.28ZM397.24,455.69c-6.21,11.28-21.66,25.53-42.6,25.57-62.83.12-65.12-14.82-68.68-26.73a69,69,0,0,1-2.16-33.95c2.14-11.68,5.91-18.42,12.35-21.62,2.77-1.41,5.77-2.65,13.34-3.31,6.79-.58,17.24-.66,34.61.05a204.47,204.47,0,0,1,27.82,3c22.2,3.95,37,11,36.75,17.32C408.5,420.31,406.44,439,397.24,455.69Zm175.43,10.58c-4.52,11.58-8,26.28-70.63,21-20.87-1.75-35.1-17.22-40.38-29-7.8-17.38-8.32-36.18-8.14-40.5.36-8.71,29.3-16.45,66-14.95s42.5,4.08,47.53,7.17c6.15,3.72,9.36,10.75,10.54,22.56A69.17,69.17,0,0,1,572.67,466.27Z"
            transform="translate(-53.2 -136.54)"
            fill="url(#l)"
          />
          <path
            d="M452.26,245.08c-1.59,0-3.2,0-4.79-.11-32.64-1.63-58.37-19.33-57.37-39.47.94-18.91,25.63-33.71,56.2-33.71,1.59,0,3.2,0,4.8.12,15.93.79,30.69,5.4,41.57,13,10.66,7.44,16.27,16.84,15.8,26.47C507.53,230.28,482.84,245.08,452.26,245.08Z"
            transform="translate(-53.2 -136.54)"
            fill="#FFFFFF"
          />
          <path
            d="M446.31,170.29v3c1.56,0,3.14,0,4.71.11,15.66.78,30.14,5.3,40.79,12.74C502,193.28,507.42,202.21,507,211.3c-.9,18.1-24.93,32.28-54.71,32.28-1.56,0-3.15,0-4.72-.11-15.65-.78-30.14-5.3-40.78-12.74-10.23-7.13-15.61-16.07-15.16-25.16.9-18.1,24.93-32.28,54.7-32.28v-3m0,0c-31.31,0-56.71,15.16-57.7,35.13-1,21,25.28,39.38,58.79,41,1.64.08,3.26.12,4.87.12,31.32,0,56.71-15.15,57.7-35.13,1.05-21-25.28-39.38-58.79-41-1.64-.08-3.26-.12-4.87-.12Z"
            transform="translate(-53.2 -136.54)"
            fill="#FF7770"
          />
          <path
            d="M414.05,188.54l3.54.18-.41,8.37-.28,3.8a10.36,10.36,0,0,1,6.71-2.61c5.44.27,8.13,4.6,7.8,11.08-.35,7.17-4.83,11.14-9.63,10.91a9.53,9.53,0,0,1-5.87-2.89h-.13l-.41,2.06-2.85-.14Zm7.28,28.72c3.45.17,6.15-2.86,6.41-8,.23-4.62-1.17-7.81-5.14-8-1.77-.09-3.68.81-5.76,2.57l-.54,11A8.2,8.2,0,0,0,421.33,217.26Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M437.21,192.85a2.48,2.48,0,1,1,2.35,2.41A2.31,2.31,0,0,1,437.21,192.85Zm.32,6.64,3.54.18-1,21-3.54-.17Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M448.64,190.26l3.54.17-1.33,26.78c-.05,1.12.4,1.58.92,1.6a2.9,2.9,0,0,0,.78-.05l.34,2.7a4.6,4.6,0,0,1-2.05.25c-2.67-.13-3.67-1.87-3.52-4.94Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M459.65,190.81l3.54.17-1.33,26.77c-.05,1.13.4,1.58.92,1.61a2.9,2.9,0,0,0,.78-.05l.34,2.7a4.86,4.86,0,0,1-2,.25c-2.68-.14-3.68-1.87-3.53-4.94Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M469,228.2c2.37.11,4-1.71,5-4.26l.55-1.53L467.16,201l3.67.18L474.52,213c.56,1.85,1.19,4,1.74,6h.17c.7-1.87,1.41-4,2.07-5.83l4.33-11.43,3.45.17-9,22.32c-1.67,4.08-4,7.12-8.22,6.91a7.13,7.13,0,0,1-2.44-.55l.83-2.78A6.5,6.5,0,0,0,469,228.2Z"
            transform="translate(-53.2 -136.54)"
            fill="#2766F1"
          />
          <path
            d="M249.22,667.55l76.68-23.64c-2.67,20.61,19.66,41.78,53.35,53.38h0q2.88,1,5.88,1.89l.3.09c1,.29,2,.58,3,.85h0c.94.26,1.9.52,2.85.76l.51.13,3,.73.13,0,2.8.62.67.14c1,.21,2,.41,3,.6l.1,0c1,.19,1.93.36,2.9.53l.74.12c1,.17,2,.34,3,.49,1.61.24,3.22.46,4.81.66l.27,0c1.51.18,3,.34,4.51.48l.42,0c1.45.14,2.89.25,4.32.34l.49,0c1.41.09,2.82.15,4.21.2l.5,0c1.4,0,2.79.07,4.17.08h.44c1.41,0,2.8,0,4.19,0h.33c1.44,0,2.87-.1,4.28-.17h.15c1.47-.08,2.92-.19,4.36-.31h0c23.92-2.1,43.36-10,52.91-21.92h0c.37-.46.71-.92,1.05-1.38l.22-.31c.33-.47.66-.95,1-1.44h0c.3-.48.58-1,.84-1.45l.19-.34c.26-.5.51-1,.74-1.5h0c.22-.5.43-1,.62-1.51l.15-.38c.19-.51.37-1,.52-1.55v0c.15-.52.28-1,.41-1.57l.09-.4c.11-.54.22-1.07.3-1.62h0l0-.26c7.17,3.34,13.63,6.14,18.62,8.17,31.42.26,81-6.14,129-30.64,1.05-.53,2.1-1.08,3.15-1.64l4-1.66-4-9.34c-1.05.56-2.1,1.11-3.15,1.64-48,24.5-97.58,30.9-129,30.64-5-2-11.45-4.83-18.62-8.17l0,.26h0c-.08.55-.19,1.08-.3,1.62l-.09.4c-.13.53-.26,1-.41,1.57v0c-.15.52-.33,1-.52,1.55l-.15.38c-.19.51-.4,1-.62,1.51h0c-.23.51-.48,1-.74,1.5l-.19.34c-.26.49-.54,1-.84,1.45h0c-.3.49-.63,1-1,1.44l-.22.31c-.34.46-.68.92-1.05,1.38h0c-9.55,11.88-29,19.82-52.91,21.92h0c-1.44.12-2.89.23-4.36.31h-.15c-1.41.07-2.84.13-4.28.17h-.33c-1.39,0-2.78.05-4.19,0h-.44c-1.38,0-2.77,0-4.17-.08l-.5,0c-1.39-.05-2.8-.11-4.21-.2l-.49,0c-1.43-.09-2.87-.2-4.32-.34l-.42,0c-1.49-.14-3-.3-4.51-.48l-.27,0c-1.59-.2-3.2-.42-4.81-.66-1-.15-2-.32-3-.49l-.74-.12c-1-.17-1.94-.34-2.9-.53l-.1,0c-1-.19-2-.39-3-.6l-.67-.14-2.8-.62-.13,0-3-.73-.51-.13c-.95-.24-1.91-.5-2.85-.76h0c-1-.27-2-.56-3-.85l-.3-.09q-3-.9-5.88-1.89h0c-33.69-11.6-56-32.77-53.35-53.38l-76.68,23.64c1-.21,2.06-.37,3.07-.48-1,.11-2,.27-3.07.48-26.56,5.31-64.83,42.75-100.18,86.93,1.41,1.92,2.84,3.81,4.29,5.7C187.46,707.34,223.73,672.65,249.22,667.55Z"
            transform="translate(-53.2 -136.54)"
            fill="#FF7770"
          />
        </g>
      </svg>
    </Box>
  )
}
