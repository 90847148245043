import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../Button'
import * as Styled from './styles'

interface NavListCreateNewButtonProps {
  children?: ReactNode
  className?: string
  onClick?: () => void
}

export const NavListCreateNewButton = ({ children, onClick, className }: NavListCreateNewButtonProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.NavListCreateNewButtonWrapper className={className} onClick={onClick}>
      <Styled.Separator />
      <Button icon="plusCircle" variant="text" size="xl" fullWidth>
        {children || t('ds.nav_list.create_new')}
      </Button>
    </Styled.NavListCreateNewButtonWrapper>
  )
}
