import { TFunction } from 'i18next'

export const DATA_ACCOUNT_ID = 'data-account-id'
export const DATA_START_DATE = 'data-start-date'
export const DATA_END_DATE = 'data-end-date'

// We have to find amounts and transform them to the clickable links with proper attributes
// Those attributes are then used to navigate to the account with correct query params set up
export const formatReportHtml = (html: string, t: TFunction): string => {
  const htmlElement = document.createElement('div')
  htmlElement.innerHTML = html

  const accountRowsElements = htmlElement.querySelectorAll('tr.account')
  const accountRowsElementsArray = accountRowsElements?.length ? Array.from(accountRowsElements) : []

  for (const account of accountRowsElementsArray) {
    // Read account data
    const accountId = account.getAttribute(DATA_ACCOUNT_ID)
    account.removeAttribute(DATA_ACCOUNT_ID)
    const balancesElements = account.querySelectorAll('td.account-balance')
    const balancesElementsArray = balancesElements?.length ? Array.from(balancesElements) : []

    for (const balance of balancesElementsArray) {
      // Read balance data
      const balanceStartDate = balance.getAttribute(DATA_START_DATE)
      const balanceEndDate = balance.getAttribute(DATA_END_DATE)
      balance.removeAttribute(DATA_START_DATE)
      balance.removeAttribute(DATA_END_DATE)
      const balanceValue = balance.innerHTML
      balance.innerHTML = ''

      // Create a link element
      const linkElement = document.createElement('a')
      linkElement.classList.add('link', 'with-animation', 'account-balance-link')
      linkElement.setAttribute('href', '#')
      linkElement.setAttribute('title', t('reports.html.link.title'))

      // Move all attributes to the link so it's easier to read on click
      linkElement.setAttribute(DATA_ACCOUNT_ID, accountId || '')
      linkElement.setAttribute(DATA_START_DATE, balanceStartDate || '')
      linkElement.setAttribute(DATA_END_DATE, balanceEndDate || '')
      linkElement.append(balanceValue)

      // Append link element to the balance cell with balance value
      balance.append(linkElement)
    }
  }

  return htmlElement.innerHTML
}
