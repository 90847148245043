import { useUserOrganization } from '@modules-deprecated/app/organization'

import { WidgetId } from '../enums/widgetId'

export const HIDE_GET_STARTED_WIDGET_VARIANT_BUCKETS = [5, 6, 7, 8, 9]

export type PermanentlyHiddenWidgetsMap = Partial<Record<WidgetId, boolean>>

export const usePermanentlyHiddenWidgets = (): PermanentlyHiddenWidgetsMap => {
  const { organization } = useUserOrganization()
  const isGetStartedWidgetHidden =
    organization?.randomBucket !== undefined &&
    HIDE_GET_STARTED_WIDGET_VARIANT_BUCKETS.includes(organization.randomBucket)

  return {
    [WidgetId.GetStarted]: isGetStartedWidgetHidden,
  }
}
