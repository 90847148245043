import { useEffect, useRef, useState } from 'react'

/**
 * @deprecated THIS HOOK IS DEPRECATED. Please use "useHover" or "useHoverDirty" from "react-use" package
 */
export function useHover() {
  const [value, setValue] = useState(false)

  const ref = useRef<any>(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(() => {
    const node = ref.current
    if (node) {
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseout', handleMouseOut)

      return () => {
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [ref])

  return { hoverRef: ref, isHovered: value }
}
