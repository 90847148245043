import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'
import { pxToNumber } from '../../utils/pxToNumber'

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  margin-bottom: ${Spacing.M};
  position: absolute;
  z-index: 1;
  flex-direction: column;
  text-align: center;
  width: calc(100% - ${2 * pxToNumber(Spacing.L)}px);
  right: ${Spacing.L};
  left: ${Spacing.L};
`

export const Amount = styled.span`
  display: flex;
  margin-top: -${Spacing.XXS};
  align-items: flex-end;
  text-align: center;
`

export const Illustration = styled.svg``
