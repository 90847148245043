import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ActivateAlertWrapper = styled.div`
  width: 100%;
  margin: ${Spacing.M} auto ${Spacing.XL} auto;
  padding: ${Spacing.XL};
  border: 1px solid ${Color.Gray40};
  border-radius: ${DefaultValue.BorderRadius};
`

export const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 14px;
`
