import { postV2Request } from '../../utils'

export interface RequestAccountantArgs {
  bankTransactions: string
  bills: string
  city?: string
  companyName: string
  companyType?: string
  cvr: string
  description: string
  email?: string
  expectations: string
  invoices: string
  issues: string[]
  organizationId: string
  phone?: string
  postToSheets: boolean
  postToSlack: boolean
  revenue: string
  street?: string
  userName?: string
  zipcode?: string
}

export const requestAccountant = ({
  bankTransactions,
  bills,
  city,
  companyName,
  companyType,
  cvr,
  description,
  email,
  expectations,
  invoices,
  issues,
  organizationId,
  phone,
  postToSheets,
  postToSlack,
  revenue,
  street,
  userName,
  zipcode,
}: RequestAccountantArgs) => {
  return postV2Request({
    endpoint: `/integrations/notification/${organizationId}/request-an-accountant`,
    payload: {
      bankTransactions,
      bills,
      city,
      companyName,
      companyType,
      cvr,
      description,
      email,
      expectations,
      invoices,
      issues,
      phone,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      post_to_sheets: postToSheets ? '1' : '0',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      post_to_slack: postToSlack ? '1' : '0',
      revenue,
      street,
      zipcode,
      userName,
    },
  })
}
