import { Button as ButtonComponent, Spacing, Text as TextComponent } from '@design-system'

import styled from '@emotion/styled'

export const EmptyStateWrapper = styled.div`
  display: flex;
  height: 100%;
  margin-top: -${Spacing.XL};
  flex-direction: column;
  text-align: center;
`

export const Button = styled(ButtonComponent)`
  margin: 0 auto;
`

export const Text = styled(TextComponent)`
  margin: 0 auto;
`
