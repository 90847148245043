import reduce from 'lodash/reduce'

type BreakpointName = 'phone' | 'tablet' | 'medium' | 'large' | 'xlarge' | 'xxlarge'

const breakpoints: [BreakpointName, number][] = [
  ['phone', 576],
  ['tablet', 768],
  ['medium', 992],
  ['large', 1214],
  ['xlarge', 1350],
  ['xxlarge', 1514],
]

const breakpointRanges = reduce(
  breakpoints,
  (result, breakpoint, index) => {
    const [breakpointName, to] = breakpoint
    const from = index === 0 ? 0 : breakpoints[index - 1][1] + 1

    return {
      ...result,
      [breakpointName]: [from, to],
    }
  },
  {},
)

const getBreakpointValue = (breakpointName: BreakpointName): number => {
  const breakpoint = breakpoints.find(([name]) => name === breakpointName)
  return breakpoint?.[1] as number
}

const lessThan = (breakpoint: BreakpointName): string => {
  const from = breakpointRanges[breakpoint][0]
  return `@media screen and (max-width: ${from}px)`
}

const greaterThan = (breakpoint: BreakpointName): string => {
  const to = breakpointRanges[breakpoint][1]
  return `@media screen and (min-width: ${to}px)`
}

const equalOrLessThan = (breakpoint: BreakpointName): string => {
  const to = breakpointRanges[breakpoint][1]
  return `@media screen and (max-width: ${to}px)`
}

const equalOrGreaterThan = (breakpoint: BreakpointName): string => {
  const from = breakpointRanges[breakpoint][0]
  return `@media screen and (min-width: ${from}px)`
}

const between = (breakpointFrom: BreakpointName, breakpointTo: BreakpointName): string => {
  const from = breakpointRanges[breakpointFrom][0]
  const to = breakpointRanges[breakpointTo][1]
  return `@media screen and (min-width: ${from}px) and (max-width: ${to}px)`
}

export const rwd = (...breakpoints: BreakpointName[]): string => {
  let query = '@media'

  for (let i = 0; i < breakpoints.length; i += 1) {
    const [from, to] = breakpointRanges[breakpoints[i]]
    query += ` screen and (min-width: ${from}px) and (max-width: ${to}px)`

    if (i < breakpoints.length - 1) {
      query += ','
    }
  }

  return query
}

rwd.lessThan = lessThan
rwd.greaterThan = greaterThan
rwd.equalOrLessThan = equalOrLessThan
rwd.equalOrGreaterThan = equalOrGreaterThan
rwd.between = between
rwd.getBreakpointValue = getBreakpointValue
