import { useMutation } from 'react-query'

import { APIError } from '../../../utils'
import { postEmailInvoice, PostEmailInvoicePayload } from '../query-api'

interface EmailErrorProps extends APIError {
  body: {
    errorMessage: string
    errorCode: string
  }
}

interface UseEmailInvoiceProps {
  onError?: (error?: EmailErrorProps) => void
  onSuccess?: () => void
}

export interface SendEmailInvoiceProps {
  invoiceId: string
  payload: PostEmailInvoicePayload
}

export const useEmailInvoice = (props?: UseEmailInvoiceProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: sendEmailInvoice, isLoading: isProcessing } = useMutation(
    ({ invoiceId, payload }: SendEmailInvoiceProps) => postEmailInvoice(invoiceId, payload),
    {
      onError,
      onSuccess,
    },
  )

  return { isProcessing, sendEmailInvoice }
}
