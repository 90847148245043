import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { getShadow } from '../../../utils/getShadow'
import { InputWrapperProps } from '../types/inputWrapperProps'

interface AppearanceProps {
  backgroundColor: Color | undefined
  borderColor: Color | undefined
  hasInnerShadow: boolean
}

type GetAppearanceProps = Pick<InputWrapperProps, 'bordered' | 'autoCompleted'>

export const getAppearanceStyle = ({ bordered, autoCompleted }: GetAppearanceProps): SerializedStyles => {
  const appearance: AppearanceProps = {
    backgroundColor: Color.White,
    borderColor: Color.Gray50,
    hasInnerShadow: true,
  }

  if (autoCompleted) {
    appearance.backgroundColor = Color.Purple20
    appearance.borderColor = Color.Lavender
  }

  if (!bordered) {
    appearance.borderColor = undefined
    appearance.hasInnerShadow = false
  }

  return css`
    border: 1px solid ${appearance.borderColor || 'transparent'};
    background-color: ${appearance.backgroundColor};
    ${getShadow(undefined, {
      extraShadowType: appearance.hasInnerShadow ? 'raisedLightInner' : undefined,
    })}
  `
}
