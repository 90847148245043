import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  max-width: 1170px;
  margin: ${Spacing.L} auto ${Spacing.XXL};
  padding: 0 ${Spacing.XL};
  flex-direction: column;
`
