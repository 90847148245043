import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'

export type Direction = 'row' | 'column'

interface ComponentsGroupItemProps {
  fitToWidth?: boolean
}

export const ComponentsGroupItem = styled.li<ComponentsGroupItemProps>`
  ${({ fitToWidth }) =>
    fitToWidth &&
    css`
      flex: 1;
    `}
`

interface ComponentsGroupListProps {
  direction: Direction
  spacing?: Spacing
  centered?: boolean
}

export const ComponentsGroupList = styled.ul<ComponentsGroupListProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};

  ${({ direction, centered }) => {
    if (!centered) {
      return ''
    }
    if (direction === 'column') {
      return 'justify-content: center;'
    }
    return 'align-items: center;'
  }}

  > ${() => ComponentsGroupItem} {
    + ${() => ComponentsGroupItem} {
      ${({ direction, spacing }) =>
        spacing && (direction === 'row' ? `margin-left: ${spacing};` : `margin-top: ${spacing};`)}
    }
  }
`
