import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { defaultStaleTime } from '../../../config/queryClient'
import { fetchRecurringInvoices } from '../query-api'
import { getRecurringInvoicesQueryKey } from '../utils/getRecurringInvoicesQueryKey'

export const useIsRecurringInvoicesListEmpty = (): boolean => {
  const { organization } = useUserOrganization()

  const queryProps = useMemo(
    () => ({
      organizationId: organization?.id as string,
      page: 1,
      pageSize: 1 as ItemsPerPage,
    }),
    [organization?.id],
  )

  const queryKey = useMemo(() => getRecurringInvoicesQueryKey(queryProps), [queryProps])

  const { data: recurringInvoicesCountData, isFetched: recurringInvoicesCountFetched } = useQuery(
    queryKey,
    () => fetchRecurringInvoices(queryProps),
    {
      enabled: !!organization?.id,
      staleTime: defaultStaleTime,
    },
  )

  return recurringInvoicesCountFetched && recurringInvoicesCountData?.data.length === 0
}
