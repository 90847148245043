import {
  DropdownNavList,
  DropdownNavListProps,
  DropdownRef,
  findNavItem,
  Icon,
  Input,
  InputProps,
  Link,
  NavListItemWithBlockDescription,
} from '@design-system'

import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  ReactElement,
  Ref,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Company } from '@modules-deprecated/app/companies/types'
import { Umbrella } from '@modules-deprecated/app/umbrellas/types/umbrella'
import { useBookkeepers } from '@views/settings/routes/OrganizationSettingsUsers/hooks/useBookkeepers'

import { KeyboardKey } from '../../../enums/keyboardKey'
import { getImageClassName } from '../../../utils/getClassName'
import { BookkeeperValue } from './types/bookkeeperValue'
import { getItems } from './utils/getItems'

const MIN_INPUT_SIGNS = 3

interface BookkeeperSelectProps
  extends Pick<DropdownNavListProps<BookkeeperValue>, 'placement' | 'selectedId'>,
    Pick<
      InputProps,
      | 'alignment'
      | 'autoCompleted'
      | 'autoFocus'
      | 'bordered'
      | 'className'
      | 'disabled'
      | 'error'
      | 'focused'
      | 'hidden'
      | 'id'
      | 'name'
      | 'onBlur'
      | 'onChange'
      | 'onChangeDebounced'
      | 'onFocus'
      | 'onKeyDown'
      | 'onKeyPress'
      | 'onMouseDown'
      | 'onPressEnter'
      | 'placeholder'
      | 'readOnly'
      | 'selectOnFocus'
      | 'size'
      | 'success'
      | 'suffix'
      | 'type'
    > {
  // Select props
  allowCreate?: boolean
  inputValue?: string
  onSelect?: (id?: string, value?: Partial<Umbrella>) => void
  selectedValue?: Partial<Umbrella>
  // DropdownNavList props
  dropdownId?: string
  dropdownMaxHeight?: DropdownNavListProps<BookkeeperValue>['maxHeight']
  dropdownSize?: DropdownNavListProps<BookkeeperValue>['size']
}

export const BookkeeperSelect = forwardRef(
  (
    {
      allowCreate,
      inputValue: inputValueControlled,
      onSelect,
      selectedId: selectedIdControlled,
      selectedValue: selectedValueControlled,
      // DropdownNavList props
      dropdownId,
      dropdownMaxHeight = 'default',
      dropdownSize = 'fitTrigger',
      placement = 'bottom-end',
      // Input props
      alignment = 'left',
      autoCompleted,
      autoFocus,
      bordered,
      className,
      disabled,
      error,
      focused = false,
      hidden,
      id,
      name,
      onBlur,
      onChange,
      onChangeDebounced,
      onFocus,
      onKeyDown,
      onKeyPress,
      onMouseDown,
      onPressEnter,
      placeholder,
      readOnly,
      selectOnFocus,
      size,
      success,
      type,
    }: BookkeeperSelectProps,
    ref: Ref<DropdownRef>,
  ): ReactElement => {
    const { t } = useTranslation()
    const [selectedId, setSelectedId] = useState<string | undefined>('')
    const [selectedValue, setSelectedValue] = useState<Partial<Company> | undefined>(selectedValueControlled)
    const [isOpen, setIsOpen] = useState(false)
    const [inputValue, setInputValue] = useState(inputValueControlled || '')
    const isDropdownInsideClickedRef = useRef(false) // helper for not clearing data on blur, after selecting some item (check handleInputBlur)
    const { data, isLoading } = useBookkeepers({ searchQuery: inputValue })

    const items = useMemo(() => getItems(data, t), [data, t])

    const selectItem = useCallback(
      (id: string | undefined, withSelectEvent?: boolean) => {
        const item = findNavItem(items, id)
        let idToSelect = id
        let valueToSelect = {}

        if (item) {
          const bookkeeper = data.find(({ id: itemId }) => itemId === id)

          if (bookkeeper) {
            valueToSelect = bookkeeper
            setInputValue(bookkeeper.name)
          }
        } else {
          idToSelect = ''
          valueToSelect = {}
        }

        setSelectedValue(valueToSelect)
        setSelectedId(idToSelect)
        setIsOpen(false)

        if (withSelectEvent) {
          onSelect?.(idToSelect, valueToSelect)
        }
      },
      [items, data, onSelect],
    )

    const toggleDropdown = useCallback(() => {
      setIsOpen(!!inputValue && inputValue.length >= MIN_INPUT_SIGNS)
    }, [inputValue, setIsOpen])

    const handleDropdownMouseDown = useCallback(() => {
      isDropdownInsideClickedRef.current = true
    }, [])

    const handleClose = useCallback(() => {
      setIsOpen(false)
    }, [])

    const handleItemClick = useCallback(
      (id: string) => {
        isDropdownInsideClickedRef.current = true
        selectItem(id, true)
      },
      [selectItem],
    )

    const handleInputKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (isDropdownInsideClickedRef.current) {
          isDropdownInsideClickedRef.current = false
        }

        if (event.key === KeyboardKey.ArrowDown) {
          toggleDropdown()
        }

        onKeyDown?.(event)
      },
      [onKeyDown, toggleDropdown],
    )

    const handleInputBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (isDropdownInsideClickedRef.current) {
          isDropdownInsideClickedRef.current = false
        } else {
          if (selectedId && selectedValue) {
            setInputValue(selectedValue.name || '')
          } else {
            selectItem(undefined)
          }

          setIsOpen(false)
        }

        onBlur?.(event)
      },
      [onBlur, selectedId, selectedValue, selectItem],
    )

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const value = event?.target.value

        setInputValue(value)
        setIsOpen(value.length >= MIN_INPUT_SIGNS)
        onChange?.(event)
      },
      [onChange, setIsOpen],
    )

    const handleInputFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        toggleDropdown()
        onFocus?.(event)
      },
      [onFocus, toggleDropdown],
    )

    return (
      <DropdownNavList
        autoToggle={false}
        id={dropdownId}
        isFetching={isLoading}
        isOpen={isOpen}
        itemRender={(props) => <NavListItemWithBlockDescription {...props} />}
        items={items}
        maxHeight={dropdownMaxHeight}
        notFoundContent={
          <Trans i18nKey="settings.organization.users.invite_bookkeeper_modal.not_found">
            Can’t find your accountant{' '}
            <span role="img" aria-label="Cry" className={getImageClassName()}>
              😢
            </span>
            <br />
            Send them this
            <Link
              href="https://www.billy.dk/revisor/funktion/for-revisorer/"
              target="_blank"
              rel="noopener noreferrer"
              underlined
            >
              link
            </Link>
            , to get an accountant profile on Billy.
          </Trans>
        }
        onClose={handleClose}
        onItemClick={handleItemClick}
        onKeyDown={handleInputKeyDown}
        onMouseDown={handleDropdownMouseDown}
        placement={placement}
        ref={ref}
        selectedId={selectedId}
        size={dropdownSize}
        trigger={
          <Input
            alignment={alignment}
            autoComplete="off"
            autoCompleted={autoCompleted}
            autoFocus={autoFocus}
            bordered={bordered}
            className={className}
            disabled={disabled}
            error={error}
            focused={focused}
            hidden={hidden}
            id={id}
            name={name}
            onBlur={handleInputBlur}
            onChange={handleChange}
            onChangeDebounced={onChangeDebounced}
            onFocus={handleInputFocus}
            onKeyPress={onKeyPress}
            onMouseDown={onMouseDown}
            onPressEnter={onPressEnter}
            placeholder={
              placeholder || t('settings.organization.users.invite_bookkeeper_modal.contact_name.placeholder')
            }
            readOnly={readOnly}
            selectLook
            selectOnFocus={selectOnFocus}
            size={size}
            success={success}
            suffix={<Icon icon="magnifyingGlass" />}
            title={inputValue}
            truncate
            type={type}
            value={inputValue}
          />
        }
        subItemsMode="horizontal"
        withNavigation
      />
    )
  },
)
