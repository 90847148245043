import { notify } from '@design-system'

import omit from 'lodash/omit'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useUpdateOrganizationAndSettings } from '@modules-deprecated/app/organization/hooks/useUpdateOrganizationAndSettings'

import { useDirtyRoute } from '../../../../../../../contexts/dirtyRouteContext'
import { NotificationKeys } from '../../../../../../../enums/notificationKeys'
import { APIError } from '../../../../../../../utils'
import { getErrorMessage } from '../../../../../../../utils/getErrorMessage'
import { InvoiceAndProductSettingsForm } from '../utils/formData'
import { getOrganizationPayloadFromInvoiceAndProductForm } from '../utils/getOrganizationPayloadFromInvoiceAndProductForm'
import { getSettingsPayloadFromInvoiceAndProductForm } from '../utils/getSettingsPayloadFromInvoiceAndProductForm'

export const useUpdateInvoiceAndProductSettings = () => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { setDirty: setDirtyRoute } = useDirtyRoute()

  const { update: updateOrganizationAndSettings, isProcessing } = useUpdateOrganizationAndSettings({
    onSuccess: () => {
      notify({
        id: NotificationKeys.InvoiceSettingsUpdate,
        message: t('settings.organization.organization_info.notification.update_success'),
        variant: 'success',
      })
      setDirtyRoute(false)
    },
    onError: (error?: APIError) => {
      notify({
        id: NotificationKeys.InvoiceSettingsUpdate,
        message: getErrorMessage(error, 'organization') || t('error_saving'),
        variant: 'error',
      })
    },
  })

  const updateInvoiceAndProductSettings = useCallback(
    (values: InvoiceAndProductSettingsForm, propertiesToOmit?: Array<keyof InvoiceAndProductSettingsForm>) => {
      if (!organization?.id) {
        return
      }

      const valuesUpdated: Partial<InvoiceAndProductSettingsForm> = propertiesToOmit
        ? omit(values, propertiesToOmit)
        : values
      const updateOrganizationPayload = getOrganizationPayloadFromInvoiceAndProductForm(valuesUpdated)
      const updateOrganizationSettingsPayload = getSettingsPayloadFromInvoiceAndProductForm(valuesUpdated)

      updateOrganizationAndSettings({
        organizationId: organization.id,
        payload: updateOrganizationPayload,
        settingsPayload: updateOrganizationSettingsPayload,
      })
    },
    [organization?.id, updateOrganizationAndSettings],
  )

  return { update: updateInvoiceAndProductSettings, isProcessing }
}
