import { ScrollableList } from '../../../../ScrollableList'
import { BillsListItemSkeleton } from './elements/BillsListItemSkeleton'

export const BillsListSkeleton = () => (
  <ScrollableList>
    {[...Array(5).keys()].map((id) => (
      <BillsListItemSkeleton key={id} />
    ))}
  </ScrollableList>
)
