import React from 'react'
import { useTranslation } from 'react-i18next'

import { Color } from '../../../../enums/color'
import { Flex } from '../../../Flex/'
import { Icon } from '../../../Icon'

interface FileErrorThumbnailProps {
  title?: string
}

export const FileErrorThumbnail = ({ title }: FileErrorThumbnailProps) => {
  const { t } = useTranslation()

  return (
    <Flex alignItems="center" justifyContent="center">
      <Icon icon="xSign" color={Color.Red} aria-label={title || t('attachments.thumbnail_error.broken_image')} />
    </Flex>
  )
}
