import { useMutation } from 'react-query'

import { updateExternalInvoicesSync } from '../query-api'

interface UseUpdateExternalInvoicesSyncProps {
  onError?: () => void
  onSuccess?: () => void
}

export const useUpdateExternalInvoicesSync = (props?: UseUpdateExternalInvoicesSyncProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate: update,
    isLoading: isProcessing,
    ...rest
  } = useMutation(updateExternalInvoicesSync, { onSuccess, onError })

  return { update, isProcessing, ...rest }
}
