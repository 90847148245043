import { TFunction } from 'i18next'

import { Role } from '../types/role'

export const getRoleToRoleName = (t: TFunction): Record<Role, string> => ({
  admin: t('role.admin'),
  collaborator: t('role.collaborator'),
  externalInvoicer: t('role.invoicer_external'),
  invoicer: t('role.invoicer'),
  uploader: t('role.uploader'),
})
