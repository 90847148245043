import { css } from '@emotion/core'
import React from 'react'

import { coloring } from '../../styles-deprecated'
import { IconCaretDown, IconProps } from '../Icons'

export type CalendarIconCaretProps = IconProps & {
  direction: 'right' | 'left'
}

export const CalendarIconCaret = ({ direction, ...rest }: CalendarIconCaretProps) => (
  <IconCaretDown
    width={10}
    height={10}
    css={css`
      color: ${coloring('light')};
      &:hover {
        color: ${coloring('primary')};
      }
      ${direction === 'right' ? 'transform: rotate(-90deg)' : 'transform: rotate(90deg)'}
    `}
    {...rest}
  />
)
