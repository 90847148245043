import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { KeyboardKey } from '../../../enums/keyboardKey'
import { useTheme } from '../../hooks/useTheme'
import { ButtonsGroup } from '../ButtonsGroup'
import { DropdownRef } from '../Dropdown'
import { DropdownNavList, DropdownNavListProps } from '../DropdownNavList'
import { Icon } from '../Icon'
import { ClearInputButton, InputProps } from '../Input'
import { filterItemsBasic, findNavItem, NavItem } from '../NavList'
import * as Styled from './styles'
import { getDisplayValue } from './utils/getDisplayValue'
import { getSelectTogglerIcon } from './utils/getSelectTogglerIcon'

export interface SelectProps<T = string>
  extends Pick<DropdownNavListProps<T>, 'items' | 'placement' | 'selectedId'>,
    Pick<
      InputProps,
      | 'alignment'
      | 'autoCompleted'
      | 'autoFocus'
      | 'bordered'
      | 'className'
      | 'disabled'
      | 'error'
      | 'focused'
      | 'hidden'
      | 'id'
      | 'name'
      | 'onBlur'
      | 'onChange'
      | 'onChangeDebounced'
      | 'onFocus'
      | 'onKeyDown'
      | 'onKeyPress'
      | 'onPressEnter'
      | 'placeholder'
      | 'prefix'
      | 'readOnly'
      | 'selectLook'
      | 'size'
      | 'success'
      | 'suffix'
      | 'type'
    > {
  // Select props
  allowClear?: boolean
  defaultSelectedId?: string
  /** custom filtering function for some specific use cases */
  itemsFilterFn?: (items: NavItem<T>[], inputValue: string) => NavItem<T>[]
  /** define custom value which will be visible in Select after selecting */
  getDisplayValue?: (item: NavItem<T>) => string
  /** allows to toggle select from the outside */
  isOpen?: boolean
  onSelect?: (id?: string, value?: T) => void
  /** if enabled, input has cursor inside and user can search for the items */
  withSearch?: boolean
  /** same as 'withSearch' but user can't open dropdown manually, only by searching things (useful for data based on query where we don't have initial list) */
  withSearchOnly?: boolean
  withTogglerIcon?: boolean
  // DropdownNavList props
  dropdownFetching?: DropdownNavListProps<T>['isFetching']
  dropdownFooter?: DropdownNavListProps<T>['footer']
  dropdownHeader?: DropdownNavListProps<T>['header']
  dropdownId?: DropdownNavListProps<T>['id']
  dropdownItemRender?: DropdownNavListProps<T>['itemRender']
  dropdownMaxHeight?: DropdownNavListProps<T>['maxHeight']
  dropdownNotFoundContent?: DropdownNavListProps<T>['notFoundContent']
  dropdownScrollEndOffset?: DropdownNavListProps<T>['scrollEndOffset']
  dropdownSize?: DropdownNavListProps<T>['size']
  dropdownSubItemsMode?: DropdownNavListProps<T>['subItemsMode']
  dropdownWithNavigation?: DropdownNavListProps<T>['withNavigation']
  onDropdownScrollEnd?: () => void
  onDropdownOpen?: DropdownNavListProps<T>['onOpen']
  onDropdownClose?: DropdownNavListProps<T>['onClose']
}

const SelectForwarded = <T,>(
  {
    // Select props
    allowClear = false,
    defaultSelectedId,
    itemsFilterFn,
    getDisplayValue: getDisplayValueCustom,
    isOpen: isOpenControlled = false,
    onSelect,
    selectedId: selectedIdControlled,
    withSearch = true,
    withSearchOnly = false,
    withTogglerIcon = true,
    // DropdownNavList props
    dropdownFetching,
    dropdownFooter,
    dropdownHeader,
    dropdownId,
    dropdownItemRender,
    dropdownMaxHeight = 'default',
    dropdownNotFoundContent,
    dropdownScrollEndOffset,
    dropdownSize,
    dropdownSubItemsMode = 'vertical',
    dropdownWithNavigation = true,
    items,
    onDropdownScrollEnd,
    onDropdownOpen,
    onDropdownClose,
    placement = 'bottom-end',
    // Input props
    alignment = 'left',
    autoCompleted,
    autoFocus,
    bordered,
    className,
    disabled,
    error,
    focused = false,
    hidden,
    id,
    name,
    onBlur,
    onChange,
    onChangeDebounced,
    onFocus,
    onKeyDown,
    onKeyPress,
    onPressEnter,
    placeholder,
    prefix,
    readOnly,
    selectLook = true,
    size,
    success,
    suffix,
    type,
  }: SelectProps<T>,
  forwardedRef: Ref<DropdownRef>,
): ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(isOpenControlled)
  const [isFocused, setIsFocused] = useState(focused)
  const [selectedId, setSelectedId] = useState(defaultSelectedId || selectedIdControlled)
  const [inputValue, setInputValue] = useState(getDefaultInputValue())
  const [itemsList, setItemsList] = useState<NavItem<T>[]>(items)
  const [itemsListProcessed, setItemsListProcessed] = useState<NavItem<T>[]>(items)
  const selectWrapperRef = useRef<DropdownRef>(null)
  const clearIconWrapperRef = useRef<HTMLDivElement>(null)
  const isDropdownInsideClickedRef = useRef(false) // helper for not clearing data on blur, after selecting some item (check handleInputBlur)

  const searchable = withSearch || withSearchOnly
  const isClearable = searchable && allowClear && !!inputValue
  const isReadOnly = !searchable || readOnly
  const shouldSelectOnFocus = searchable

  const togglerIcon = useMemo(
    () => getSelectTogglerIcon({ isOpen, isFocused, withSearch, withSearchOnly }),
    [isOpen, isFocused, withSearch, withSearchOnly],
  )
  const inputPlaceholder = useMemo(() => {
    if (placeholder !== undefined) {
      return placeholder
    }

    const placeholderTKey = searchable ? 'ds.select.placeholder_with_search' : 'ds.select.placeholder'
    return t(placeholderTKey)
  }, [placeholder, searchable, t])

  // Lifecycle

  useEffect(() => {
    setIsOpen(isOpenControlled)
  }, [isOpenControlled])

  useEffect(() => {
    if (selectedIdControlled !== selectedId) {
      selectItem(selectedIdControlled)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIdControlled])

  useEffect(() => {
    setIsFocused(focused)
  }, [focused])

  useEffect(() => {
    const setDefaultInputValue = () => {
      if (!inputValue && selectedId) {
        const item = findNavItem(items, selectedId)

        if (item) {
          const inputValue = getDisplayValue(item, getDisplayValueCustom)
          setInputValue(inputValue)
        }
      }
    }

    setItemsList(items)
    setItemsListProcessed(items)
    setDefaultInputValue()
    // it should only fire on 'items' change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  // Helpers

  function getDefaultInputValue() {
    const item = findNavItem(items, selectedId)
    return item ? getDisplayValue(item, getDisplayValueCustom) : ''
  }

  // Functions

  const openDropdown = useCallback(() => {
    setIsOpen(true)
    onDropdownOpen?.()
  }, [onDropdownOpen])

  const closeDropdown = useCallback(() => {
    setIsOpen(false)
    onDropdownClose?.()
  }, [onDropdownClose])

  const toggleDropdown = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }, [])

  const selectItem = useCallback(
    (id: string | undefined, withSelectEvent?: boolean) => {
      const item = findNavItem(itemsList, id)
      setSelectedId(id)
      setItemsListProcessed(itemsList) // reset items presented on the list after selecting some

      if (item) {
        const inputValue = getDisplayValue(item, getDisplayValueCustom)
        setInputValue(inputValue)
        setItemsListProcessed(items)
      } else {
        setInputValue('')
      }

      if (withSelectEvent) {
        onSelect?.(item?.id, item?.value)
      }
    },
    [itemsList, getDisplayValueCustom, items, onSelect],
  )

  const clear = useCallback(
    (withSelectEvent?: boolean) => {
      setSelectedId('')
      setItemsListProcessed(itemsList)
      setInputValue('')

      if (withSelectEvent) {
        onSelect?.(undefined, {} as T) // we need to pass an empty object so the change is detected by the form
      }
    },
    [itemsList, onSelect],
  )

  const filterItems = useCallback(
    (inputValue: string) => {
      const filterFn = itemsFilterFn || filterItemsBasic
      const itemsFiltered = filterFn(itemsList, inputValue)
      setItemsListProcessed(itemsFiltered)

      if (searchable) {
        openDropdown()
      }

      if (!itemsFiltered.length && !dropdownNotFoundContent) {
        closeDropdown()
      }
    },
    [itemsFilterFn, itemsList, searchable, dropdownNotFoundContent, openDropdown, closeDropdown],
  )

  const keepSelectedItem = useCallback(() => {
    const item = findNavItem(itemsList, selectedId)

    if (item) {
      selectItem(item.id, false)
    }
  }, [itemsList, selectedId, selectItem])

  //  Handlers

  const handleDropdownOpen = useCallback(() => {
    openDropdown()
  }, [openDropdown])

  const handleDropdownClose = useCallback(() => {
    closeDropdown()
  }, [closeDropdown])

  const handleInputMouseDown = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const clickedElement = event.target as HTMLElement | undefined
      const clearIconElement = clearIconWrapperRef.current
      const clearIconClicked =
        clickedElement && (clearIconElement?.contains(clickedElement) || clickedElement === clearIconElement)

      if (clearIconClicked) {
        return
      }

      if (searchable) {
        isDropdownInsideClickedRef.current = true
      }

      if (!withSearchOnly) {
        toggleDropdown()
      }
    },
    [searchable, toggleDropdown, withSearchOnly],
  )

  const handleItemClick = useCallback(
    (id: string) => {
      if (searchable) {
        isDropdownInsideClickedRef.current = true
      }

      selectItem(id, true)
    },
    [selectItem, searchable],
  )

  const handleDropdownMouseDown = useCallback(() => {
    if (searchable) {
      isDropdownInsideClickedRef.current = true
    }
  }, [searchable])

  const handleInputFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true)
      onFocus?.(event)
    },
    [onFocus],
  )

  const handleInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (searchable) {
        if (isDropdownInsideClickedRef.current) {
          isDropdownInsideClickedRef.current = false
        } else {
          const shouldClear = !selectedId || (!inputValue && allowClear)
          closeDropdown()

          if (shouldClear) {
            clear(true)
          } else {
            keepSelectedItem()
          }
        }
      }

      setIsFocused(false)
      onBlur?.(event)
    },
    [searchable, onBlur, selectedId, inputValue, allowClear, closeDropdown, clear, keepSelectedItem],
  )

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.currentTarget.value
      setInputValue(inputValue)
      filterItems(inputValue)
      onChange?.(event)
    },
    [onChange, filterItems],
  )

  const handleInputKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (isDropdownInsideClickedRef.current) {
        isDropdownInsideClickedRef.current = false
      }

      if (event.key === KeyboardKey.ArrowDown) {
        openDropdown()
      }

      onKeyDown?.(event)
    },
    [onKeyDown, openDropdown],
  )

  const handleInputEnterPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      keepSelectedItem()
      onPressEnter?.(event)
    },
    [keepSelectedItem, onPressEnter],
  )

  const handleClearIconClick = useCallback(() => {
    if (allowClear && searchable) {
      clear(true)
    }
  }, [allowClear, clear, searchable])

  useImperativeHandle(forwardedRef, () => ({
    ...(selectWrapperRef.current as DropdownRef),
    // reference to Select points out to the Select's wrapper. And because of that, whenever some outside library (for example react-hook-form)
    // tries to focus on the element, then it throws an error. We have to override the focus method so it's invoked on a trigger element (so for example Input in most cases)
    // (trigger is assigned here: https://github.com/billysbilling/billy-webapp/blob/master/react-app/src/design-system/components/Dropdown/Dropdown.tsx#L98-L105)
    focus: () => {
      selectWrapperRef?.current?.trigger?.focus()
    },
  }))

  return (
    <DropdownNavList
      autoToggle={false}
      footer={dropdownFooter}
      header={dropdownHeader}
      id={dropdownId}
      isFetching={dropdownFetching}
      isOpen={isOpen}
      itemRender={dropdownItemRender}
      items={itemsListProcessed}
      maxHeight={dropdownMaxHeight}
      notFoundContent={dropdownNotFoundContent}
      onClose={handleDropdownClose}
      onItemClick={handleItemClick}
      onKeyDown={handleInputKeyDown}
      onMouseDown={handleDropdownMouseDown}
      onOpen={handleDropdownOpen}
      onScrollEnd={onDropdownScrollEnd}
      placement={placement}
      ref={selectWrapperRef}
      scrollEndOffset={dropdownScrollEndOffset}
      selectedId={selectedId}
      size={dropdownSize}
      trigger={
        <Styled.Input
          className={className}
          alignment={alignment}
          autoComplete="disable-autocomplete"
          autoCompleted={autoCompleted}
          autoFocus={autoFocus}
          bordered={bordered}
          clearable={isClearable}
          disabled={disabled}
          error={error}
          focused={isFocused}
          hidden={hidden}
          id={id}
          name={name}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          onChangeDebounced={onChangeDebounced}
          onMouseDown={handleInputMouseDown}
          onFocus={handleInputFocus}
          onKeyPress={onKeyPress}
          onPressEnter={handleInputEnterPress}
          placeholder={inputPlaceholder}
          prefix={prefix}
          readOnly={isReadOnly}
          searchable={searchable}
          selectLook={selectLook}
          selectOnFocus={shouldSelectOnFocus}
          size={size}
          success={success}
          suffix={
            <ButtonsGroup>
              {isClearable && !disabled && (
                <Styled.ClearInputWrapper ref={clearIconWrapperRef}>
                  <ClearInputButton onClick={handleClearIconClick} />
                </Styled.ClearInputWrapper>
              )}
              {!!suffix && <Styled.ToggleIconWrapper>{suffix}</Styled.ToggleIconWrapper>}
              {withTogglerIcon && (
                <Styled.ToggleIconWrapper>
                  <Icon icon={togglerIcon} color={theme.ds.colors.base.textPrimary} />
                </Styled.ToggleIconWrapper>
              )}
            </ButtonsGroup>
          }
          truncate
          title={inputValue}
          type={type}
          value={inputValue}
        />
      }
      subItemsMode={dropdownSubItemsMode}
      withNavigation={dropdownWithNavigation}
    />
  )
}

export const Select = forwardRef(SelectForwarded) as <T>(
  props: SelectProps<T> & { ref?: Ref<HTMLDivElement | null> },
) => ReactElement
