import { addDays, addMonths, differenceInDays, differenceInMonths, format, getDaysInMonth } from 'date-fns'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider'
import { useTranslation } from 'react-i18next'

import { Handle } from './elements/Handle'
import { Tick } from './elements/Tick'
import { Track } from './elements/Track'
import { DATE_FORMAT } from './helpers'

function formatTick(startDate: Date, value: number, tickValues: number[]): string {
  const date = addDays(startDate, value)
  if (tickValues.length <= 7) {
    return format(date, 'MMMM')
  }
  return format(date, 'MMM')
}

function getTickValues(startDate: Date, endDate: Date): number[] {
  const tickValues = [0]
  let accumulatedDays = 0
  const months = differenceInMonths(endDate, startDate)
  for (let i = 0; i < months; i++) {
    accumulatedDays += getDaysInMonth(addMonths(startDate, i))
    tickValues.push(accumulatedDays)
  }

  return tickValues
}

function getDatesQuery(startDate: Date, minValue: number, maxValue: number): string {
  return `dates:${format(addDays(startDate, minValue), DATE_FORMAT)}...${format(
    addDays(startDate, maxValue),
    DATE_FORMAT,
  )}`
}

type DatePickerSliderProps = {
  startDate: string
  endDate: string
  updateDom: Function
  onDateRangeChange: (range: number[]) => void
}

export const DatePickerSlider: FC<DatePickerSliderProps> = ({
  startDate: startDateAsString,
  endDate: endDateAsString,
  updateDom,
  onDateRangeChange,
}) => {
  const startDate = useMemo(() => new Date(startDateAsString), [startDateAsString])
  const endDate = useMemo(() => new Date(endDateAsString), [endDateAsString])

  const { t } = useTranslation()

  const [tickValues, setTickValues] = useState<number[]>([])
  const [rangeMax, setRangeMax] = useState(differenceInDays(endDate, startDate))
  const [sliderValues, setSliderValues] = useState([0, rangeMax])

  useEffect(() => {
    onDateRangeChange(sliderValues)
  }, [sliderValues, onDateRangeChange])

  useEffect(() => {
    const newMax = differenceInDays(endDate, startDate) + 1
    setRangeMax(newMax)
    setSliderValues([0, newMax])
    setTickValues(getTickValues(startDate, endDate))
    updateDom('period', getDatesQuery(startDate, 0, newMax))
  }, [startDate, endDate, updateDom])

  const updateSliderValues = useCallback((values: number[] | any) => {
    setSliderValues(values)
  }, [])

  const updateQueryString = useCallback(() => {
    updateDom('period', getDatesQuery(startDate, sliderValues[0], sliderValues[1]))
  }, [sliderValues, startDate, updateDom])

  const setValuesToTick = useCallback(
    (index: number) => {
      const min = tickValues[index]
      const max = tickValues[index + 1] || rangeMax
      setSliderValues([min, max])
      updateDom('period', getDatesQuery(startDate, min, max))
    },
    [rangeMax, startDate, tickValues, updateDom],
  )

  const formatShortDate = useCallback(
    (value: number): string => {
      return format(addDays(startDate, value), 'dd. MMM.')
    },
    [startDate],
  )

  return (
    <div className="date-range-slider-holder">
      <Slider
        domain={[0, rangeMax]}
        step={1}
        mode={2}
        values={sliderValues}
        onUpdate={updateSliderValues}
        onChange={updateQueryString}
        className="date-range-slider"
      >
        <Rail>{({ getRailProps }) => <div className="rail" {...getRailProps()} />}</Rail>

        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  getHandleProps={getHandleProps}
                  formatShortDate={formatShortDate}
                />
              ))}
            </div>
          )}
        </Handles>

        <Tracks right={false} left={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
              ))}
            </div>
          )}
        </Tracks>

        <Ticks values={tickValues}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map((tick, i) => (
                <Tick
                  key={i}
                  index={i}
                  tick={tick}
                  name={t(formatTick(startDate, tickValues[i], tickValues))}
                  width={100 / tickValues.length}
                  tickValues={tickValues}
                  selectedValues={sliderValues}
                  handleTickClick={setValuesToTick}
                />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </div>
  )
}

export default DatePickerSlider
