import { Locale } from '@design-system'

import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../../../../../enums/queryKeys'
import { SortDirection } from '../../../../../../../enums/sortDirection'
import { VatRatesSortProperty } from '../../../enums/vatRatesSortProperty'
import { fetchRulesets } from '../../../query-api'
import { RulesetWithRules } from '../../../types/rulesetWithRules'

interface UseFetchRulesetsResponse {
  data: RulesetWithRules[]
  isLoading?: boolean
}

interface UseFetchRulesetsProps {
  acceptLanguage?: Locale
  sortDirection?: SortDirection
  sortProperty?: VatRatesSortProperty
}

export const useFetchRulesets = ({ sortDirection, sortProperty }: UseFetchRulesetsProps): UseFetchRulesetsResponse => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()

  const query = useQuery(
    [QueryKeys.VatRatesRulesets, organization?.id],
    () => fetchRulesets({ organizationId: organization?.id as string }),
    {
      enabled: !!organization?.id,
    },
  )

  const data = useMemo(() => {
    const salesTaxRulesets = query?.data?.salesTaxRulesets || []
    const property = sortProperty || VatRatesSortProperty.Name

    return orderBy(salesTaxRulesets, (item) => item[property], sortDirection === SortDirection.Asc ? 'asc' : 'desc')
  }, [query?.data?.salesTaxRulesets, sortDirection, sortProperty])

  return {
    ...query,
    data,
    isLoading: query.isLoading || isOrganizationLoading,
  }
}
