import { FontWeight } from '@design-system'

export default {
  quickActionMenuContainer: {
    position: 'relative',
    marginTop: '20px',
    height: '40px',
    cursor: 'pointer',
    '&:hover': {
      '.quickActionMenuButton': {
        left: 0,
        right: 0,
        borderRadius: 0,
      },
    },
  },
  quickActionMenuTitle: {
    width: '80px',
    lineHeight: '40px',
    position: 'absolute',
    left: '65px',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'white',
    fontSize: '14px',
    fontWeight: FontWeight.Medium,
  },
  quickActionMenuButton: {
    position: 'absolute',
    left: '30px',
    right: '70px',
    transition: 'all .1s ease-in-out',
    cursor: 'pointer',
    height: '40px',
    borderRadius: '2px',
    boxShadow: '0 1px 3px 0 rgba(0,0,0,0.25)',
  },
}
