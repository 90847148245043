import { WindowPosition } from '../types/windowPosition'

export const getWindowPosition = (): WindowPosition => {
  const { screenLeft, screenTop, screenX, screenY } = window

  return {
    left: (screenLeft || screenX) ?? 0,
    top: (screenTop || screenY) ?? 0,
  }
}
