import { Spacing } from '@design-system'

import styled from '@emotion/styled'

import { ReactComponent as IconInvoiceCreateComponent } from '../../../../assets/images/dashboard-empty-state/invoice-create.svg'
import { emptyViewIconStyle } from '../styles/emptyIconStyles'

export const InvoicesList = styled.ul`
  display: flex;
  margin: auto 0;
`

export const BadgeWrapper = styled.div`
  margin-bottom: ${Spacing.S};
`

export const Footer = styled.footer`
  margin-bottom: -${Spacing.S};
`

export const IconInvoiceCreate = styled(IconInvoiceCreateComponent)`
  ${emptyViewIconStyle}
`
