import { call, put, takeLatest } from 'redux-saga/effects'

import { getRequest } from '../../../utils'
import { productsReceived, ProductsRequested } from './action-creators'
import { PRODUCTS_REQUESTED } from './actions'

function* fetchProducts(action: ProductsRequested) {
  const res: { products: any } = yield call(getRequest, `/v2/products?organizationId=${action.payload}`)
  yield put(productsReceived(res.products))
}

export default function* () {
  yield takeLatest(PRODUCTS_REQUESTED, fetchProducts)
}
