import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { updateActor, UpdateActorResponseData } from '../query-api'

interface UseUpdateActorProps {
  onError?: (error?: APIError) => void
  onSuccess?: (data: UpdateActorResponseData) => void
}

export const useUpdateActor = (props?: UseUpdateActorProps) => {
  const { onError, onSuccess } = props || {}
  const { mutate: update, ...rest } = useMutation(updateActor, { onSuccess, onError })

  return { update, ...rest }
}
