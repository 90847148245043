import { Badge, BadgeVariant } from '@design-system'

import { TFunction } from 'i18next'
import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { VoucherInboxState } from '../types'

type VoucherStatusProps = {
  state: VoucherInboxState
}

type Status = {
  text: string
  variant: BadgeVariant
}

const getVoucherStateToStatus = (
  t: TFunction,
): Record<Exclude<VoucherInboxState, VoucherInboxState.DELETED | VoucherInboxState.DUPLICATED>, Status> => ({
  [VoucherInboxState.RECEIVED]: { text: t('voucher.inbox.state.received'), variant: 'primary' },
  [VoucherInboxState.RECORDED]: { text: t('voucher.inbox.state.recorded'), variant: 'success' },
  [VoucherInboxState.ARCHIVED]: { text: t('voucher.inbox.state.archived'), variant: 'neutral' },
  [VoucherInboxState.DISCARDED]: { text: t('voucher.inbox.state.discarded'), variant: 'neutral' },
  [VoucherInboxState.NEED_INFO]: { text: t('voucher.inbox.state.need_info'), variant: 'warning' },
  [VoucherInboxState.VOIDED]: { text: t('voucher.inbox.state.voided'), variant: 'error' },
})

export const VoucherStatus = ({ state, ...rest }: VoucherStatusProps): ReactElement => {
  const { t } = useTranslation()
  const voucherStateToStatus = useMemo(() => getVoucherStateToStatus(t), [t])
  const { text, variant } = voucherStateToStatus[state]

  return (
    <Badge variant={variant} {...rest}>
      {text}
    </Badge>
  )
}
