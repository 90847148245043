import { SkeletonBox, SkeletonText } from '@design-system'

import * as Styled from './styles'

const SkeletonRows = () => {
  const numberOfRows = 7
  return (
    <>
      {[...Array(numberOfRows)].map(() => (
        <Styled.SkeletonRow>
          <Styled.SkeletonCheckboxCell>
            <SkeletonBox height={24} width={40} />
          </Styled.SkeletonCheckboxCell>
          <SkeletonText />
        </Styled.SkeletonRow>
      ))}
    </>
  )
}

export const SkeletonNotifications = () => {
  return (
    <Styled.SkeletonWrapper>
      <Styled.SkeletonRow>
        <Styled.SkeletonCheckboxCell>
          <SkeletonBox height={32} width={32} />
        </Styled.SkeletonCheckboxCell>
        <SkeletonText variant="h2" />
      </Styled.SkeletonRow>
      <SkeletonRows />
    </Styled.SkeletonWrapper>
  )
}
