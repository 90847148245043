import { encodePeriodValue } from '@components'
import { ItemsPerPage, useItemsPerPage } from '@design-system'

import { useMemo } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useGlobalFiscalYear } from '../../../../../contexts/globalFiscalYearContext'
import { SortDirection } from '../../../../../enums/sortDirection'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { getPeriodValueFromFiscalYear } from '../../../../../utils/getPeriodValueFromFiscalYear'
import { InvoicesSortProperty } from '../../../enums/InvoicesSortProperty'
import { InvoiceStatus } from '../../../enums/invoiceStatus'

export interface InvoicesListFilters {
  page?: number
  pageSize?: ItemsPerPage
  period?: string
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: InvoicesSortProperty
  status?: InvoiceStatus
}

type UseInvoicesFiltersResponse = [InvoicesListFilters, (filters: Partial<InvoicesListFilters>) => void]

export const defaultQuery: InvoicesListFilters = {
  page: 1,
  pageSize: undefined,
  period: undefined,
  searchQuery: '',
  sortDirection: SortDirection.Desc,
  sortProperty: InvoicesSortProperty.Date,
  status: InvoiceStatus.All,
}

interface UseInvoicesListFiltersProps {
  sortDirection?: SortDirection
  sortProperty?: InvoicesSortProperty
  status?: InvoiceStatus
}

export const useInvoicesListFilters = ({
  sortDirection,
  sortProperty,
  status,
}: UseInvoicesListFiltersProps = {}): UseInvoicesFiltersResponse => {
  const [pageSize] = useItemsPerPage()
  const { organization } = useUserOrganization()
  const { fiscalYear: globalFiscalYear } = useGlobalFiscalYear()
  const [queryParams, setQueryParams] = useQueryParams<InvoicesListFilters>({
    defaultQuery: {
      ...defaultQuery,
      sortDirection: sortDirection || defaultQuery.sortDirection,
      sortProperty: sortProperty || defaultQuery.sortProperty,
      status: status || defaultQuery.status,
      ...(globalFiscalYear && organization?.id
        ? { period: encodePeriodValue(getPeriodValueFromFiscalYear(globalFiscalYear), organization.id) }
        : {}),
      pageSize,
    },
  })

  return useMemo(
    () => [
      {
        ...queryParams,
        // Sometimes `page` comes as a string. It needs to be always `number` type.
        page: Number(queryParams.page),
        // Sometimes `pageSize` comes as string. It needs to be always `number` type.
        pageSize: Number(queryParams.pageSize) as ItemsPerPage,
      },
      setQueryParams,
    ],
    [queryParams, setQueryParams],
  )
}
