import { BorderRadius, Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const DifferenceSelectorWrapper = styled.div`
  background-color: ${Color.DayGrey};
  padding: ${Spacing.M} ${Spacing.L};
  margin: ${Spacing.L} 0;
  border-radius: ${BorderRadius.L};
`
