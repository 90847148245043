import mapValues from 'lodash/mapValues'

import { Rule } from '../../../../../../types/rule'
import { RuleForm } from '../../../../../../types/ruleForm'

export const getRulesFormFromRules = (rules: Rule[] | undefined): RuleForm[] | undefined => {
  if (!rules) {
    return undefined
  }

  return rules.map((rule, index) => ({
    ...mapValues(rule, (ruleValue) => ruleValue || null),
    priority: index + 1,
  }))
}
