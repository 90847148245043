import { ButtonProtected } from '@components'
import { Button, ButtonsGroup, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../enums/modalId'
import { Scope } from '../../../../../../../../enums/scope'
import { TrackingContext } from '../../../../../../../../enums/trackingContext'

export const UsersSectionSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { open: openInviteUsersModal } = useModal(ModalId.OrganizationSettingsInviteUsersModal)
  const { open: openTransferOwnershipModal } = useModal(ModalId.OrganizationSettingsTransferOwnershipModal)

  const handleTransferOwnershipButtonClick = useCallback(() => {
    openTransferOwnershipModal()
  }, [openTransferOwnershipModal])

  const handleInviteUserButtonClick = useCallback(() => {
    openInviteUsersModal()
  }, [openInviteUsersModal])

  return (
    <>
      <ButtonsGroup>
        <Button icon="roundedArrows" onClick={handleTransferOwnershipButtonClick} variant="secondary">
          {t('settings.organization.users.transfer_ownership_section.transfer_ownership')}
        </Button>
        <ButtonProtected
          scopes={Scope.UserWrite}
          trackingContext={TrackingContext.OrganizationSettingsUser}
          icon="plusCircle"
          onClick={handleInviteUserButtonClick}
        >
          {t('settings.organization.users.invite_section.invite_user')}
        </ButtonProtected>
      </ButtonsGroup>
    </>
  )
}
