import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Lunar } from '../../../assets/images/integrations/lunar.svg'
import { IntegrationId } from '../enums/integrationId'
import { IntegrationItem } from '../types/integrationItem'

const lunarExternalUrl = 'https://www.lunar.app/dk/erhverv/ref-billy'

export const useLunarIntegration = (): IntegrationItem => {
  const { t } = useTranslation()

  const buttonHandler = useCallback(() => {
    window.open(lunarExternalUrl, '_blank')
  }, [])

  return {
    id: IntegrationId.Lunar,
    name: t('integrations.card.lunar.title'),
    description: t('integrations.card.lunar.body'),
    readMoreLink: 'https://www.billy.dk/apps/lunar/',
    imageNode: <Lunar />,
    isHiddenLoading: false,
    isHidden: false,
    buttonHandler,
    buttonText: t('integrations.card.lunar.button.activate'),
    isStatusLoading: false,
  }
}
