import { TrialEndedModal } from '@components'
import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'

class TrialEndedModalWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function | undefined
}

type TrialEndedModalProps = {
  organizationId: string
  onNavigate: EmberNavigate
}

function TrialEndedModalModule({ onNavigate, organizationId }: TrialEndedModalProps): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <UserOrganizationContextProvider organizationId={organizationId}>
          <UserOrganizationSettingsContextProvider organizationId={organizationId}>
            <EmberRouterContextProvider onNavigate={onNavigate}>
              <ModalContextProvider>
                <TrialEndedModal organizationId={organizationId} />
              </ModalContextProvider>
            </EmberRouterContextProvider>
          </UserOrganizationSettingsContextProvider>
        </UserOrganizationContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

const TrialEndedModalCustomElement = createCustomElement(TrialEndedModalModule, TrialEndedModalWebComponent, 'element')

customElements.get('trial-ended-modal-module') ||
  customElements.define('trial-ended-modal-module', TrialEndedModalCustomElement)

export default TrialEndedModalCustomElement
