import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { fetchAccessTokens, FetchAccessTokensOptions } from '../query-api'

export const useFetchAccessToken = (actorId?: string) => {
  const queryProps: FetchAccessTokensOptions = useMemo(
    () => ({
      actorId: actorId || '',
      offset: 0,
      pageSize: 100,
    }),
    [actorId],
  )

  const {
    data: accessTokensData,
    isIdle,
    isLoading,
  } = useQuery([QueryKeys.AccessTokens, queryProps], () => fetchAccessTokens(queryProps), {
    enabled: !!actorId,
  })

  return {
    accessToken: accessTokensData?.oAuthAccessTokens[0]?.material || '',
    isLoading: isLoading || isIdle,
  }
}
