import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'
import { pxToNumber } from '../../../../utils/pxToNumber'
import * as NavListItemStyled from '../NavListItemView/styles'

export const SubItemWrapper = styled(NavListItemStyled.NavListItemViewWrapper)``

export const SubItem = styled(NavListItemStyled.NavListItemView)`
  height: 32px;
  padding: ${Spacing.XXS} ${Spacing.XXS} ${Spacing.XXS} ${pxToNumber(Spacing.XXXL) + pxToNumber(Spacing.XXS)}px;
`
