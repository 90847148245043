import { TFunction } from 'i18next'
import { ReactNode } from 'react'

import { TrackingContext } from '../../../../enums/trackingContext'

export interface ModalContent {
  heading: ReactNode
  body: ReactNode
}

export const getTrackingContextToModalContentVariant = (t: TFunction) =>
  new Map<TrackingContext[], ModalContent>([
    [
      [TrackingContext.InvoiceAttachment],
      {
        heading: t('premium_feature.feature.add_files.heading'),
        body: t('premium_feature.feature.add_files.body'),
      },
    ],
    [
      [
        TrackingContext.BillWidgetUpload,
        TrackingContext.CreateBillUpload,
        TrackingContext.DaybookAttachment,
        TrackingContext.Uploads,
      ],
      {
        heading: t('premium_feature.feature.upload_receipts.heading'),
        body: t('premium_feature.feature.upload_receipts.body'),
      },
    ],
    [
      [TrackingContext.PaymentMethodEditor],
      {
        heading: t('premium_feature.feature.payment_method.heading'),
        body: t('premium_feature.feature.payment_method.body'),
      },
    ],
    [
      [TrackingContext.CreateRecurringInvoice],
      {
        heading: t('premium_feature.feature.recurring_invoices.heading'),
        body: t('premium_feature.feature.recurring_invoices.body'),
      },
    ],
    [
      [TrackingContext.SalarySignup],
      {
        heading: t('premium_feature.feature.salary.heading'),
        body: t('premium_feature.feature.salary.body'),
      },
    ],
    [
      [TrackingContext.DaybookIndex, TrackingContext.InvoiceEdit],
      {
        heading: t('premium_feature.feature.more_bookkeeping.heading'),
        body: t('premium_feature.feature.more_bookkeeping.body'),
      },
    ],
    [
      [TrackingContext.OrganizationSettingsUser],
      {
        heading: t('premium_feature.feature.add_users.heading'),
        body: t('premium_feature.feature.add_users.body'),
      },
    ],
    [
      [TrackingContext.BankAccountOverviewConnectBank, TrackingContext.BankConnectionsConnectBank],
      {
        heading: t('premium_feature.feature.reconcile_bank.heading'),
        body: t('premium_feature.feature.reconcile_bank.body'),
      },
    ],
    [
      [TrackingContext.CreateQuote],
      {
        heading: t('premium_feature.feature.quotes.heading'),
        body: t('premium_feature.feature.quotes.body'),
      },
    ],
    [
      [TrackingContext.EmailRateExceeded],
      {
        heading: t('premium_feature.feature.email_rate_exceeded.heading'),
        body: t('premium_feature.feature.email_rate_exceeded.body'),
      },
    ],
    [
      [TrackingContext.EmailHigherRateExceeded],
      {
        heading: t('premium_feature.feature.email_higher_rate_exceeded.heading'),
        body: t('premium_feature.feature.email_higher_rate_exceeded.body'),
      },
    ],
    [
      [TrackingContext.InvoiceTemplateDesigner],
      {
        heading: t('premium_feature.feature.design_invoices.heading'),
        body: t('premium_feature.feature.design_invoices.body'),
      },
    ],
    [
      [TrackingContext.Integrations],
      {
        heading: t('premium_feature.feature.integrations.heading'),
        body: t('premium_feature.feature.integrations.body'),
      },
    ],
    [
      [TrackingContext.EInvoice],
      {
        heading: t('premium_feature.feature.e_invoice.heading'),
        body: t('premium_feature.feature.e_invoice.body'),
      },
    ],
    [
      [TrackingContext.FreeInvoicesLimitExceeded],
      {
        heading: t('premium_feature.feature.free_invoices_limit.heading'),
        body: t('premium_feature.feature.free_invoices_limit.body'),
      },
    ],
    [
      [TrackingContext.FreeExpensesLimitExceeded],
      {
        heading: t('premium_feature.feature.free_expenses_limit.heading'),
        body: t('premium_feature.feature.free_expenses_limit.body', { limit: 10 }),
      },
    ],
  ])
