import { TFunction } from 'i18next'
import * as yup from 'yup'

import { Company } from '@modules-deprecated/app/companies/types'
import { Country } from '@modules-deprecated/app/countries/types'
import { Currency } from '@modules-deprecated/app/currencies/types'

import { PaidBy } from '../../../types/paidBy'
import { ProductPlan } from '../../UmbrellaOrganizationsProductPlansSelect'

export interface CreateOrganizationForm {
  isManualMode?: boolean
  orgCity?: string
  orgCompany?: Partial<Company>
  orgCountry?: Partial<Country>
  orgCurrency?: Partial<Currency>
  orgFirstFiscalYearEnd?: string
  orgFirstFiscalYearStart?: string
  orgFiscalYearEndMonth?: string
  orgName?: string
  orgStreet?: string
  orgZipcode?: string
  paidBy?: PaidBy
  productPlan?: ProductPlan
  userName: string
}

export const defaultValues: CreateOrganizationForm = {
  isManualMode: false,
  orgCity: '',
  orgCompany: undefined,
  orgCountry: undefined,
  orgCurrency: undefined,
  orgFirstFiscalYearEnd: '',
  orgFirstFiscalYearStart: '',
  orgFiscalYearEndMonth: '',
  orgName: '',
  orgStreet: '',
  orgZipcode: '',
  paidBy: undefined,
  productPlan: undefined,
  userName: '',
}

export const getValidationSchema = (t: TFunction): yup.SchemaOf<CreateOrganizationForm> =>
  yup.object().shape({
    userName: yup.string().required(t('signup_guide.create_org.form.error.user_name_required')),
    isManualMode: yup.boolean(),
    orgCompany: yup
      .object()
      .shape({
        id: yup.string(),
      })
      .when('isManualMode', {
        is: false,
        then: yup
          .object()
          .shape({
            id: yup.string().required(t('signup_guide.create_org.form.error.company_required')),
          })
          .required(t('signup_guide.create_org.form.error.company_required')),
      }),
    orgCountry: yup
      .object()
      .shape({
        id: yup.string(),
      })
      .when('isManualMode', {
        is: true,
        then: yup
          .object()
          .shape({
            id: yup.string().required(t('signup_guide.create_org.form.error.country_required')),
          })
          .required(t('signup_guide.create_org.form.error.country_required')),
      }),
    orgCurrency: yup
      .object()
      .shape({
        id: yup.string(),
      })
      .when('isManualMode', {
        is: true,
        then: yup
          .object()
          .shape({
            id: yup.string().required(t('signup_guide.create_org.form.error.currency_required')),
          })
          .required(t('signup_guide.create_org.form.error.currency_required')),
      }),
    orgName: yup.string().when('isManualMode', {
      is: true,
      then: yup.string().required(t('signup_guide.create_org.form.error.org_name_required')),
    }),
    orgStreet: yup.string().when('isManualMode', {
      is: true,
      then: yup.string().required(t('signup_guide.create_org.form.error.street_required')),
    }),
    orgCity: yup.string().when('isManualMode', {
      is: true,
      then: yup.string().required(t('signup_guide.create_org.form.error.city_required')),
    }),
    orgZipcode: yup.string().when('isManualMode', {
      is: true,
      then: yup.string().required(t('signup_guide.create_org.form.error.zipcode_required')),
    }),
    orgFiscalYearEndMonth: yup.string(),
    orgFirstFiscalYearStart: yup.string(),
    orgFirstFiscalYearEnd: yup.string(),
    paidBy: yup.mixed().oneOf(['admin', 'user']),
    productPlan: yup
      .object()
      .shape({
        period: yup.string(),
        plan: yup.string(),
      })
      .notRequired()
      .when('paidBy', {
        is: 'admin',
        then: yup.object().shape({
          period: yup.string().required(),
          plan: yup.string().required(),
        }),
      }),
  })
