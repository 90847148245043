import React from 'react'
import { Flex, FlexProps } from 'rebass'

import { reactClass } from '../../utils'

export type PopOverFooterProps = FlexProps & {
  centered?: boolean
}

export const PopOverFooter = ({ centered, children, ...rest }: PopOverFooterProps) => (
  <Flex className={reactClass('pop-over-footer')} justifyContent={centered ? 'center' : 'space-between'} {...rest}>
    {children}
  </Flex>
)
