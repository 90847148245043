import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ImageOverviewMonthlyDKComponent } from '../../../../assets/images/dashboard-empty-state/dk/overview-monthly.svg'
import { ReactComponent as ImageOverviewMonthlyENComponent } from '../../../../assets/images/dashboard-empty-state/en/overview-monthly.svg'
import { ReactComponent as ImageOverviewMonthlyFRComponent } from '../../../../assets/images/dashboard-empty-state/fr/overview-monthly.svg'
import { LocaleCode } from '../../../../enums/localeCode'
import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { EmptyStateImage } from '../../elements/emptyStateImage'
import { Widget } from '../../elements/Widget'
import { WidgetId } from '../../enums/widgetId'
import { OverviewChart } from './elements/OverviewChart'
import { OverviewLabels } from './elements/OverviewLabels'
import { useOverview } from './hooks/useOverview'
import { ChartData } from './types/chartData'
import { OverviewStats } from './types/overviewStats'

const emptyImageByLanguage: Record<LocaleCode, ReactElement> = {
  [LocaleCode.DA]: <ImageOverviewMonthlyDKComponent />,
  [LocaleCode.EN]: <ImageOverviewMonthlyENComponent />,
  [LocaleCode.FR]: <ImageOverviewMonthlyFRComponent />,
}

export const OverviewMonthly = (): ReactElement => {
  const { t } = useTranslation()
  const { chartData, code, currencyId, isLoading, result, totalRevenue, totalExpenses } = useOverview()
  const { isWidgetHidden } = useDashboardSettings()

  const isHidden = useMemo(() => isWidgetHidden(WidgetId.OverviewMonthly), [isWidgetHidden])
  const isEmptyStateVisible = (!isLoading && !chartData.find(({ result }: ChartData) => result)) || isHidden

  const stats: OverviewStats = useMemo(
    () => ({
      totalRevenue: {
        name: t('dashboard.widget.overview.monthly.revenue'),
        amount: totalRevenue,
        colorVariant: totalRevenue < 0 ? 'danger' : 'success',
      },
      totalExpenses: {
        name: t('dashboard.widget.overview.monthly.expenses'),
        amount: totalExpenses,
        colorVariant: totalExpenses <= 0 ? 'danger' : 'success',
      },
      result: {
        name: t('dashboard.widget.overview.monthly.result'),
        amount: result,
        colorVariant: result < 0 ? 'danger' : 'success',
      },
    }),
    [t, totalRevenue, totalExpenses, result],
  )

  return (
    <Widget
      isHidden={isHidden}
      title={t('dashboard.widget.overview.monthly.title', { currencyCode: currencyId })}
      subtitle={t('dashboard.widget.overview.monthly.title.subscript')}
      sideActions={<OverviewLabels isLoading={isLoading} stats={stats} />}
    >
      {isEmptyStateVisible ? (
        <EmptyStateImage image={emptyImageByLanguage[code]} />
      ) : (
        <OverviewChart chartData={chartData} isLoading={isLoading} />
      )}
    </Widget>
  )
}
