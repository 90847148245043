import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconWallet = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-wallet')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={coloring(color)}
        d="M31,24c0,1.657-1.343,3-3,3H4c-1.657,0-3-1.343-3-3V8c0-1.657,1.343-3,3-3h24c1.657,0,3,1.343,3,3V24z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M28,28H4c-2.206,0-4-1.794-4-4V8c0-2.206,1.794-4,4-4h24c2.206,0,4,1.794,4,4v16C32,26.206,30.206,28,28,28z M4,6C2.897,6,2,6.897,2,8v16c0,1.103,0.897,2,2,2h24c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4z"
      ></path>
      <path
        fill={coloring(color2 || color)}
        d="M28,6H17v5.5l-4,4l4,4V26h11c1.105,0,2-0.895,2-2V8C30,6.895,29.105,6,28,6z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M28,6c1.105,0,2,0.895,2,2v16c0,1.105-0.895,2-2,2H17v-6.5l-4-4l4-4V6H28 M28,4H17c-1.105,0-2,0.895-2,2 v4.672l-3.414,3.414c-0.781,0.781-0.781,2.047,0,2.828L15,20.328V26c0,1.105,0.895,2,2,2h11c2.206,0,4-1.794,4-4V8 C32,5.794,30.206,4,28,4L28,4z"
      ></path>
    </svg>
  </Box>
)
