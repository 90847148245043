import { Flex, getShadow, Spacing, zIndex } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../types/themable'
import { SIDE_NAVBAR_WIDTH } from './constants/sideNavbarWidth'

type SideNavbarWrapperProps = Themable

export const SideNavbarWrapper = styled(Flex)<SideNavbarWrapperProps>`
  width: ${SIDE_NAVBAR_WIDTH};
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: ${zIndex.Navbar};
  background-color: ${({ theme }) => theme.ds.colors.base.background};
  ${getShadow('raisedLight')};
`

export const ImageContainer = styled.div`
  width: ${SIDE_NAVBAR_WIDTH};
  height: ${SIDE_NAVBAR_WIDTH};
  padding: ${Spacing.L};
`
