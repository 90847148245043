/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use 'TaxRateSelect' from /components instead
 */
import { SmartSelect, SmartSelectProps } from '@components-deprecated'

import React, { useEffect } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { State } from '../../../../types/reduxSaga-deprecated'
import { selectProvidedOrganizationIdOrDefault } from '../../organization/selectors'
import { taxRatesRequested } from '../action-creators'
import { taxRateSelector } from '../selectors/taxRateSelector'
import { SelectTaxRatesProps, TaxRate } from '../types'

export type TaxRateSelectorProps = SmartSelectProps<TaxRate> &
  SelectTaxRatesProps & {
    formControl?: Control<any>
    organizationId?: string
    stale?: boolean
  }

export const TaxRateSelector = ({
  formControl,
  name = 'tax-rate-selector',
  onItemSelect = () => null,
  disconnect,
  organizationId: providedOrganizationId,
  stale,
  ...rest
}: TaxRateSelectorProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const organizationId = useSelector((state: State) =>
    selectProvidedOrganizationIdOrDefault(state, providedOrganizationId),
  )
  const taxRates = useSelector((state: State) => taxRateSelector(state, rest))

  const { purchase, sale } = rest

  const handleRequest = () => {
    if (stale) {
      return
    }
    dispatch(
      taxRatesRequested({
        organizationId,
        page: { offset: 0 },
        filters: { text: null, purchase, sale },
      }),
    )
  }

  const handleSelect = (item: TaxRate) => onItemSelect(item, name)

  useEffect(() => {
    if (stale) {
      return
    }
    if (organizationId) {
      dispatch(
        taxRatesRequested({
          organizationId,
          page: { offset: 0 },
          filters: { text: null, purchase, sale },
        }),
      )
    }
  }, [dispatch, organizationId, purchase, sale, stale])

  return (
    <SmartSelect<TaxRate>
      formControl={formControl}
      items={taxRates}
      name={name}
      notFoundText={t('app.taxrates.taxrate_selector.notfound')}
      onItemSelect={handleSelect}
      placeholder={t('app.taxrates.taxrate_selector.selecttaxrate')}
      request={handleRequest}
      disconnect={!!formControl || disconnect}
      listWidth={370}
      {...rest}
    />
  )
}
