import uniq from 'lodash/uniq'
import { useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { NAVIGATION_VOUCHERS_PREFETCH_SIDE_AMOUNT } from '../sagas'
import { getVouchersList } from '../services/api'
import { Voucher, VoucherInboxState, VoucherListQueryParams } from '../types'

export interface UseVouchersToNavigateProps {
  organizationId: string
  sort?: string
  voucher?: Voucher
}

export const useVouchersToNavigate = ({ organizationId, sort, voucher }: UseVouchersToNavigateProps) => {
  const queryClient = useQueryClient()
  const [vouchersIds, setVouchersIds] = useState<string[]>([])
  const inverseSort = useMemo(() => (sort && sort.charAt(0) === '-' ? sort.substring(1) : `-${sort}`), [sort])
  const parametersPrev: VoucherListQueryParams = useMemo(
    () => ({
      limit: NAVIGATION_VOUCHERS_PREFETCH_SIDE_AMOUNT + 1,
      sort: inverseSort || '-createdTimestamp',
      inboxState: VoucherInboxState.RECEIVED,
      toDate: voucher?.createdTimestamp,
      dueDateTo: new Date(),
      includeNullDueDate: true,
    }),
    [inverseSort, voucher],
  )
  const parametersNext: VoucherListQueryParams = useMemo(
    () => ({
      limit: NAVIGATION_VOUCHERS_PREFETCH_SIDE_AMOUNT + 1,
      sort: sort || 'createdTimestamp',
      inboxState: VoucherInboxState.RECEIVED,
      fromDate: voucher?.createdTimestamp,
      dueDateTo: new Date(),
      includeNullDueDate: true,
    }),
    [sort, voucher],
  )

  const { data: previousVouchers, isLoading: arePreviousVouchersLoading } = useQuery(
    [QueryKeys.VouchersList, { organizationId, parametersPrev }],
    () => getVouchersList(organizationId, parametersPrev, false),
    {
      enabled: !!voucher,
    },
  )
  const { data: nextVouchers, isLoading: areNextVouchersLoading } = useQuery(
    [QueryKeys.VouchersList, { organizationId, parametersNext }],
    () => getVouchersList(organizationId, parametersNext, false),
    {
      enabled: !!voucher,
    },
  )

  useEffect(() => {
    if (!!previousVouchers && !!nextVouchers) {
      const data = [...previousVouchers.slice().reverse(), ...nextVouchers]
      setVouchersIds(uniq(data.map(({ id }) => id)))
    }
  }, [previousVouchers, nextVouchers, queryClient, organizationId])

  return {
    areVouchersLoading: arePreviousVouchersLoading || areNextVouchersLoading,
    vouchersIds,
  }
}
