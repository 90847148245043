import { BorderRadius, Color, Spacing, Textarea } from '@design-system'

import styled from '@emotion/styled'

export const ContentWrapper = styled.div`
  display: flex;
`

export const LeftSide = styled.div`
  width: 544px;
  height: 478px;
  border-radius: ${BorderRadius.L};
  border: 1px solid ${Color.DayGrey};
  margin-right: ${Spacing.XL};
  overflow: hidden;
`

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const NotesTextarea = styled(Textarea)`
  height: 100%;
  margin-top: ${Spacing.XXXS};
`

export const Footer = styled.footer`
  margin-top: ${Spacing.L};
`
