import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { DefaultValue } from '../../enums/defaultValue'
import { Spacing } from '../../enums/spacing'
import { getShadow } from '../../utils/getShadow'

interface ProgressNotificationWrapperProps {
  clickable?: boolean
}

export const ProgressNotificationWrapper = styled.div<ProgressNotificationWrapperProps>`
  width: 450px;
  border-radius: ${DefaultValue.BorderRadius};
  padding: ${Spacing.L} ${Spacing.XL} ${Spacing.XL};
  background-color: ${Color.White};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'initial')};
  ${getShadow('elevatedStrong')};
`

export const Header = styled.header`
  display: flex;
`

export const CloseButtonWrapper = styled.figure`
  margin: -${Spacing.XS} -${Spacing.M} -${Spacing.XS} auto;
`

export const Content = styled.div``
