import { useBankLines } from '../../../../contexts/bankLinesContext'
import { BankLinesState } from '../../../../enums/bankLinesState'
import { BankLinesEmptyState } from './elements/BankLinesEmptyState'
import { BankLinesList } from './elements/BankLinesList'
import { BankLinesListSkeleton } from './elements/BankLinesList/elements/BankLinesListSkeleton'
import { ConnectBank } from './elements/ConnectBank'
import { RenewBankConnection } from './elements/RenewBankConnection'
import * as Styled from './styles'

export const BankLines = () => {
  const { bankLinesState, isLoading } = useBankLines()

  const bankLinesStateToComponent = {
    [BankLinesState.BankLinesList]: <BankLinesList />,
    [BankLinesState.Empty]: <BankLinesEmptyState />,
    [BankLinesState.ConnectBank]: <ConnectBank />,
    [BankLinesState.RenewBankConnection]: <RenewBankConnection />,
  }

  return (
    <Styled.BankLinesWrapper>
      {isLoading ? <BankLinesListSkeleton /> : bankLinesStateToComponent[bankLinesState]}
    </Styled.BankLinesWrapper>
  )
}
