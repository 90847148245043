/**
 * Returns css gradient animation to be included in css utility from @emotion core
 * @param string color1
 * @param string color2
 * @param number deg, animation orientation
 * @param number tempo, animation velocity
 * @return css gradient animation
 */
export const animateGradient = ({
  color1 = 'rgba(0, 0, 0, .05)',
  color2 = 'rgba(0, 0, 0, .10)',
  deg = 90,
  tempo = 1.4,
}) => `
  @keyframes loading-animation {
    0% { background-position: 100% 50% }
    to { background-position: 0 50% }
  }
  background: linear-gradient(${deg}deg, ${color1} 25%, ${color2} 37%, ${color1} 63%);
  background-size: 400% 100%;
  animation: loading-animation ${tempo}s ease infinite;
`

/**
 * Returns as string percent between min(%) and 100%
 * @param number min
 * @return string
 */
export const randomPercent = (min = 0) => `${min + Math.round(Math.random() * (100 - min))}%`
