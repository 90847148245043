import styled from '@emotion/styled'

export const TabsWrapper = styled.header`
  display: flex;
  margin-bottom: 25px;
  justify-content: center;
`

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 225px;
`
