import styled from '@emotion/styled'
import React, { ReactElement } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

import { DashboardWidget, DashboardWidgetBody } from '../../../components-deprecated/DashboardWidget'
import { useSegment } from '../../../hooks/'
import { EmberEventFn } from '../../../types/emberEventFn'
import WidgetBodySimple from './WidgetBodySimple'

const WidgetBankConnectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

type WidgetBankConnectionProps = {
  onConnectBankAccount: EmberEventFn
  onShowSubscriptionComparison: EmberEventFn
  hasBankConnections: boolean
  isLoading: boolean
}

function WidgetBankConnection({
  onConnectBankAccount,
  onShowSubscriptionComparison,
  hasBankConnections,
  isLoading,
}: WidgetBankConnectionProps): ReactElement {
  const { track } = useSegment()
  const { organization } = useUserOrganization()

  const shouldShowConnectOption = organization?.subscriptionPlan === SubscriptionPlan.Premium && !hasBankConnections
  const shouldShowBasicUpsell = organization?.subscriptionPlan === SubscriptionPlan.Basic
  const shouldHideWidget = hasBankConnections || isLoading || (!shouldShowBasicUpsell && !shouldShowConnectOption)

  const renderBody = () => {
    if (shouldShowBasicUpsell) {
      return (
        <WidgetBodySimple
          copyVariant="basic-upsell"
          action={() => {
            track('Subscription Plan Select Viewed (FE)')
            onShowSubscriptionComparison({ detail: null })
          }}
        />
      )
    }

    if (shouldShowConnectOption) {
      return (
        <WidgetBodySimple
          copyVariant="not-connected"
          action={() => {
            onConnectBankAccount({ detail: null })
          }}
        />
      )
    }
  }

  if (shouldHideWidget) {
    return <></>
  }

  return (
    <DashboardWidget id="widget-bank-connection" size={12} canClose>
      <DashboardWidgetBody centered>
        <WidgetBankConnectionContent>{renderBody()}</WidgetBankConnectionContent>
      </DashboardWidgetBody>
    </DashboardWidget>
  )
}

export default WidgetBankConnection
