import { IconName } from '@design-system'

import { SearchResultCategory } from '../types/searchResultCategory'

export const categoryToIcon: Partial<Record<SearchResultCategory, IconName>> = {
  account: 'barChart',
  bill: 'bill',
  contact: 'user',
  invoice: 'invoice',
  organization: 'suitcase',
  product: 'cube',
}
