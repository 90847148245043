import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchSkatConnection } from '../query-api'

const VALID_REGISTRATION_NUMBER_LENGTH = 8

interface UseSkatConnectionProps {
  skatTransactionId?: string
  cacheTime?: number
}

export const useSkatConnection = ({ skatTransactionId, cacheTime }: UseSkatConnectionProps = {}) => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()

  const isValidCVR = useMemo(() => {
    return (
      organization?.registrationNo?.length === VALID_REGISTRATION_NUMBER_LENGTH &&
      !isNaN(Number(organization?.registrationNo))
    )
  }, [organization?.registrationNo])

  const organizationId = organization?.id as string

  const {
    data: skatConnection,
    isLoading: isSkatConnectionLoading,
    ...rest
  } = useQuery(
    [QueryKeys.SkatConnection, organizationId],
    () => fetchSkatConnection(organizationId, organization?.registrationNo, skatTransactionId),
    {
      enabled: !!organization && isValidCVR,
      cacheTime,
    },
  )

  return {
    skatConnection,
    isLoading: isOrganizationLoading || isSkatConnectionLoading,
    ...rest,
  }
}
