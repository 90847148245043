import cc from 'classcat'
import React, { memo, ReactElement, ReactNode } from 'react'
import { Cell } from 'react-table'

import { TABLE_CELL_NOT_CLICKABLE_CLASS } from '../../enums/classes'
import { TableColumn } from '../../types/column'
import { TableData } from '../../types/data'
import { TableCellContent } from './elements/TableCellContent'
import * as Styled from './styles'

interface TableCellProps<T extends TableData> {
  cell: Cell<T>
  children: ReactNode
  className?: string
  isSkeletonView?: boolean
}

const TableCellMemoized = memo(
  <T extends TableData>({
    cell,
    children,
    className,
    isSkeletonView = false,
    ...rest
  }: TableCellProps<T>): ReactElement => {
    const column = cell.column as TableColumn<T>
    const alignment = column.alignment || 'left'
    const clickable = !column.skipOnRowClick
    const fixedSize = column.fixedSize
    const hidden = column.showOnRowHover
    const truncate = column.truncate

    return (
      <Styled.TableCellWrapper
        {...rest}
        alignment={alignment}
        className={cc([{ [TABLE_CELL_NOT_CLICKABLE_CLASS]: !clickable }, className])}
        clickable={clickable}
        fixedSize={fixedSize}
        hidden={hidden}
      >
        <TableCellContent isSkeletonView={isSkeletonView} title={cell.value} truncate={truncate}>
          {children}
        </TableCellContent>
      </Styled.TableCellWrapper>
    )
  },
)

export const TableCell = TableCellMemoized as <T extends TableData>(props: TableCellProps<T>) => ReactElement
