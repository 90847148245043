import { keyframes } from '@emotion/core'

const openSearchResults = keyframes`
  from {
    bottom: calc(100% - 121px);
  }
  to {
    bottom: 100px;
  }
`

export default {
  menuSearchResultsWrapper: {
    position: 'fixed',
    bottom: 'calc(100% - 121px)',
    overflow: 'hidden',
    left: 0,
    width: '250px',
    animation: `${openSearchResults} .38s ease-in-out`,
    animationFillMode: 'forwards',
    overflowY: 'auto',
    zIndex: 1,
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
    },
  },
  menuSearchResultsWrapperEmpty: {
    position: 'fixed',
    bottom: 'calc(100% - 121px)',
    overflow: 'hidden',
    left: 0,
    width: '250px',
    animation: `${openSearchResults} .38s ease-in-out`,
    animationFillMode: 'forwards',
    overflowY: 'auto',
    cursor: 'default',
    zIndex: 1,
    padding: '10px 0 0 32px',
  },
  menuSearchResult: {
    display: 'flex',
    height: '50px',
    padding: '0 30px',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  menuSearchResultTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: '1.2',
    maxWidth: '160px',
  },
  menuSearchResultTitle: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuSearchResultSubTitle: {
    fontSize: '11px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}
