import { AttachmentsNavigation, FileButtonProtected } from '@components'
import { Attachment, ButtonsGroup } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { BillAttachmentChooser } from '../BillAttachmentChooser'
import * as Styled from './styles'

interface BillEditableAttachmentsActionsProps {
  currentAttachmentIndex?: number
  isLoading?: boolean
  onAttachmentSelect?: (attachment: Attachment) => void
  onFilesSelect?: (files: File[]) => void
  onNavigationClick?: (index: number) => void
  totalAttachments?: number
}

export const BillEditableAttachmentsActions = ({
  currentAttachmentIndex,
  isLoading,
  onAttachmentSelect,
  onFilesSelect,
  onNavigationClick,
  totalAttachments,
}: BillEditableAttachmentsActionsProps): ReactElement => {
  const { t } = useTranslation()

  const handleAttachmentSelect = useCallback(
    (attachment: Attachment) => {
      onAttachmentSelect?.(attachment)
    },
    [onAttachmentSelect],
  )

  const handleFileSelect = useCallback(
    (files: File[]) => {
      onFilesSelect?.(files)
    },
    [onFilesSelect],
  )

  return (
    <Styled.BillAttachmentsActionsWrapper>
      <ButtonsGroup>
        <FileButtonProtected
          icon="arrowUpInsideCloud"
          multiple
          onFilesSelect={handleFileSelect}
          scopes={Scope.BillAttachmentWrite}
          size="m"
          trackingContext={TrackingContext.CreateBillUpload}
          variant="secondary"
        >
          {t('bills.upload_file.button')}
        </FileButtonProtected>
        <BillAttachmentChooser icon="bill" onAttachmentSelect={handleAttachmentSelect} size="m" variant="secondary">
          {t('bills.choose_file.button')}
        </BillAttachmentChooser>
      </ButtonsGroup>
      {!!(totalAttachments && totalAttachments > 1 && !isLoading) && (
        <AttachmentsNavigation
          currentIndex={currentAttachmentIndex}
          total={totalAttachments}
          onClick={onNavigationClick}
        />
      )}
    </Styled.BillAttachmentsActionsWrapper>
  )
}
