import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import * as yup from 'yup'

import { AllowedField } from '../types/types'

export const getValidationSchema = (allowedFields: AllowedField[] = []) => {
  const allFields = {
    accountName: yup.string().required(),
    description: yup.string(),
    accountGroup: yup
      .object()
      .shape({
        id: yup.string().required(),
      })
      .required(),
    vatRate: yup.object().shape({
      id: yup.string(),
    }),
    accountNo: yup.number().required(),
    isArchived: yup.boolean(),
    isBankAccount: yup.boolean(),
    isPaymentEnabled: yup.boolean(),
    currency: yup.object().when('isBankAccount', {
      is: (isBankAccount: boolean) => !!isBankAccount,
      then: yup.object().shape({
        id: yup.string().required(),
      }),
      otherwise: yup.object().shape({
        id: yup.string(),
      }),
    }),
    bank: yup.object().shape({
      id: yup.string(),
    }),
    routingNumber: yup.string().nullable(),
    bankAccountNo: yup.string().nullable(),
    bankSwift: yup.string(),
    bankIban: yup.string(),
    publicAccountId: yup.string().nullable(),
  }
  if (!isEmpty(allowedFields)) {
    return yup.object().shape(pick(allFields, allowedFields))
  }

  return yup.object().shape(allFields)
}
