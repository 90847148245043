import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { InfoSection } from './elements/InfoSection'
import { ProfileForm } from './elements/ProfileForm'

export const UmbrellaProfile = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <ModuleLayout title={t('umbrella_profile')}>
      <InfoSection />
      <ProfileForm />
    </ModuleLayout>
  )
}
