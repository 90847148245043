import colors from './colors'

export default {
  pageHeader: {
    background: colors.white,
    fontSize: '24px',
    lineHeight: 1.5,
    padding: '10px 30px',
    color: '#40494f',
    fontWeight: 300,
    borderBottom: '1px solid #dde1e3',
  },
}
