import { useParams } from 'react-router-dom'

import { ChartOfAccountsUrlParams } from '../types/chartOfAccountsUrlParams'
import { useAccountStatementsFilters } from './useAccountStatementsFilters'
import { useFetchAccountStatements } from './useFetchAccountStatements'

export const useAccountStatements = () => {
  const { accountId } = useParams<ChartOfAccountsUrlParams>()
  const [{ sortDirection, searchQuery, withVoided, period }] = useAccountStatementsFilters()

  const { accountStatement, ...rest } = useFetchAccountStatements({
    accountId: accountId as string,
    period,
    searchQuery,
    sortDirection,
    withVoided,
  })

  return { accountStatement, ...rest }
}
