import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchUmbrellaInvitation } from '../query-api'

interface UseUmbrellaInvitationProps {
  token: string
}

export const useFetchUmbrellaInvitation = ({ token }: UseUmbrellaInvitationProps) => {
  const { data: response, ...rest } = useQuery(
    [QueryKeys.UmbrellaInvitation, token],
    () => fetchUmbrellaInvitation(token),
    {
      enabled: !!token,
      retry: false, // Do not retry since most errors are: expired/not found invitation and should be handled immediately
    },
  )

  return {
    data: response?.data,
    ...rest,
  }
}
