import { SmartSelect, SmartSelectProps } from '@components-deprecated'

import React, { useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../enums/queryKeys'
import { getItemsForSelect } from '../../../../utils/forms'
import { useUserOrganization } from '../../organization'
import { fetchBanks } from '../query-api'
import { Bank } from '../types'

interface BankSelectorProps extends SmartSelectProps<Bank> {
  countryId: string
}

export const BankSelector = ({ countryId, ...rest }: BankSelectorProps) => {
  const { organization } = useUserOrganization()
  const { data } = useQuery(
    [QueryKeys.Banks, countryId],
    () =>
      fetchBanks({
        countryId: organization?.countryId,
        sortDirection: 'ASC',
        pageSize: 200,
        offset: 0,
      }),
    {
      enabled: !!organization,
    },
  )

  const { banks = [] } = data || {}

  const items = useMemo(() => getItemsForSelect(banks), [banks])

  return <SmartSelect items={items} {...rest} />
}
