import { Color } from '@design-system'

import React, { ReactElement } from 'react'

import * as Styled from './styles'

interface ProcessingOverlayProps {
  color?: Color
}

export const ProcessingOverlay = ({ color }: ProcessingOverlayProps): ReactElement => (
  <Styled.ProcessingOverlayWrapper bgColor={color} />
)
