import React, { ImgHTMLAttributes, ReactElement, useEffect, useRef } from 'react'

import { useMeasureDirty } from '../../../hooks/useMeasureDirty'
import { DefaultValue } from '../../enums/defaultValue'
import { useImageZoom } from '../../hooks/useImageZoom'
import { ImageMagnifier } from './elements/ImageMagnifier'
import * as Styled from './styles'
import { ObjectFit } from './types/ObjectFit'

const MINIMUM_SCALE = 2

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  zoomable?: boolean
  // Use to set zoomed in image scale (for example "2" is x2 bigger than the original)
  zoomScale?: number
  // You can pass different image for the zoom, otherwise it's gonna use the same as the original
  zoomImageSrc?: string
  objectFit?: ObjectFit
}

export const Image = ({
  className,
  objectFit = 'cover',
  zoomable = false,
  zoomScale = DefaultValue.ImageZoomScale,
  zoomImageSrc,
  ...imgProps
}: ImageProps): ReactElement => {
  const imageWrapperRef = useRef<HTMLElement>(null)
  const imageMagnifierRef = useRef<HTMLDivElement>(null)
  const imageZoomedRef = useRef<HTMLImageElement>(null)

  const { width: wrapperWidth = 0, height: wrapperHeight = 0 } = useMeasureDirty(imageWrapperRef)
  const { imageZoomedTranslateX, imageZoomedTranslateY, magnifierTranslateX, magnifierTranslateY } = useImageZoom({
    imageWrapperRef,
    imageZoomedRef,
    imageMagnifierRef,
  })

  const scale = Math.max(MINIMUM_SCALE, zoomScale)

  useEffect(() => {
    if (imageZoomedRef.current) {
      imageZoomedRef.current.style.width = `${wrapperWidth * scale}px`
      imageZoomedRef.current.style.height = `${wrapperHeight * scale}px`
    }
  }, [wrapperWidth, wrapperHeight, imageZoomedRef, scale])

  useEffect(() => {
    if (!zoomable) {
      return
    }

    if (imageZoomedRef.current) {
      imageZoomedRef.current.style.transform = `translate(${imageZoomedTranslateX}, ${imageZoomedTranslateY})`
    }

    if (imageMagnifierRef.current) {
      imageMagnifierRef.current.style.transform = `translate(${magnifierTranslateX}, ${magnifierTranslateY})`
    }
  }, [imageZoomedTranslateX, imageZoomedTranslateY, magnifierTranslateX, magnifierTranslateY, zoomable])

  return (
    <Styled.ImageWrapper ref={zoomable ? imageWrapperRef : undefined} className={className}>
      <Styled.Image loading="lazy" objectFit={objectFit} {...imgProps} />

      {zoomable && (
        <Styled.ImageMagnifierWrapper draggable={false}>
          <ImageMagnifier ref={imageMagnifierRef}>
            <Styled.ImageZoomedWrapper ref={imageZoomedRef}>
              <Styled.ImageZoomed
                draggable={false}
                loading="lazy"
                objectFit={objectFit}
                src={zoomImageSrc || imgProps.src}
              />
            </Styled.ImageZoomedWrapper>
          </ImageMagnifier>
        </Styled.ImageMagnifierWrapper>
      )}
    </Styled.ImageWrapper>
  )
}
