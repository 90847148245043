import { PaymentPaneStatus } from '@design-system'

import { BillStatus } from '../../../enums/billStatus'

export const billStatusToPaymentPaneStatus: Record<BillStatus, PaymentPaneStatus | undefined> = {
  [BillStatus.All]: undefined,
  [BillStatus.Draft]: PaymentPaneStatus.Success,
  [BillStatus.Overdue]: PaymentPaneStatus.Error,
  [BillStatus.Paid]: PaymentPaneStatus.Success,
  [BillStatus.PartiallyPaid]: undefined,
  [BillStatus.Unpaid]: undefined,
  [BillStatus.Voided]: undefined,
}
