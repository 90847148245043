import { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { EmberRoute } from '../enums/emberRoute'
import { EmberEventPayload } from '../types/ember'

export type EmberNavigate = (payload: EmberEventPayload) => void

export type RouterNavigate = (route: EmberRoute, options?: any, extra?: any) => void

interface ContextState {
  navigate: RouterNavigate
}

const EmberRouterContext = createContext<ContextState | undefined>(undefined)

interface EmberRouterContextProviderProps {
  children?: ReactNode
  onNavigate: EmberNavigate
}

export const EmberRouterContextProvider = ({ children, onNavigate }: EmberRouterContextProviderProps): ReactElement => {
  const handleNavigate = useCallback<RouterNavigate>(
    (route, props, extra) => {
      // It's required in some edge cases when you need to invoke function on `useEffect`
      requestAnimationFrame(() => {
        onNavigate({ detail: { route, props, extra } })
      })
    },
    [onNavigate],
  )

  return <EmberRouterContext.Provider value={{ navigate: handleNavigate }}>{children}</EmberRouterContext.Provider>
}

export const useEmberRouter = () => {
  const context = useContext(EmberRouterContext)

  if (!context) {
    throw new Error('EmberRouterContextProvider is missing in the module!')
  }

  return context
}
