import { useQuery } from 'react-query'

import { getUmbrellaOrganizationsProductPlans } from '@modules-deprecated/app/umbrellas/query-api'

import { QueryKeys } from '../../../enums/queryKeys'

export const useUmbrellaOrganizationsProductPlans = (umbrellaId?: string) => {
  const { data, isIdle, isLoading } = useQuery(
    QueryKeys.UmbrellaOrganizationsProductPlans,
    () => getUmbrellaOrganizationsProductPlans(umbrellaId as string),
    {
      enabled: !!umbrellaId,
    },
  )

  return {
    productPlans: data?.data || [],
    isLoading: isIdle || isLoading,
  }
}
