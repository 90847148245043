import { NavItem, NavListItemWithBlockDescriptionValue } from '@design-system'

import { VatRateTemplateListValue } from '@views/settings/routes/VatRatesSettings/types/vatRateTemplateListValue'

export const getVatRateTemplatesItems = (
  vatRatesTemplates: VatRateTemplateListValue[],
): NavItem<NavListItemWithBlockDescriptionValue>[] => {
  const items: NavItem<NavListItemWithBlockDescriptionValue>[] = []

  for (const template of vatRatesTemplates) {
    items.push({
      id: template.taxRateId,
      children: template.name,
      value: {
        displayData: {
          title: template.name,
          description: template.description,
        },
      },
      searchKeywords: [template.name, template.description],
    })
  }

  return items
}
