import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const BillNumberWrapper = styled.div``

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing.XS};
`

export const TextContainer = styled(Text)`
  display: flex;
  height: 40px;
  align-items: center;
`
