import { Button, ButtonsGroup } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment } from '../../../../../../hooks'
import { useExternalInvoicesSyncModal } from '../../contexts/externalInvoicesSyncModalContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { closeModal, isProcessing, submitForm } = useExternalInvoicesSyncModal()

  const handleSyncButtonClick = useCallback(() => {
    track('xxx-sales-HistoricalSync Sync Modal clicked', {
      buttonName: 'Sync now',
    })
    submitForm?.()
  }, [submitForm, track])

  const handleCancelButtonClick = useCallback(() => {
    track('xxx-sales-HistoricalSync Sync Modal clicked', {
      buttonName: 'Cancel',
    })
    closeModal()
  }, [closeModal, track])

  return (
    <ButtonsGroup>
      <Button disabled={isProcessing} onClick={handleCancelButtonClick} variant="text">
        {t('cancel')}
      </Button>
      <Button disabled={isProcessing} loading={isProcessing} onClick={handleSyncButtonClick}>
        {t('external_invoices_sync_modal.button.sync_now')}
      </Button>
    </ButtonsGroup>
  )
}
