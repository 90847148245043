/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Input /> from design-system instead
 */
import { Input as InputRebass, Textarea as TextareaRebass } from '@rebass/forms'
import cc from 'classcat'
import get from 'lodash/get'
import React, { ChangeEvent, FocusEvent, forwardRef, ReactElement, ReactNode } from 'react'
import { Control, Controller } from 'react-hook-form'
import { BoxProps } from 'rebass'

import { ConnectForm, ConnectFormType } from './ConnectForm'
import { ErrorMessage } from './ErrorMessage'
import { FormControl } from './FormControl'
import { FormLabel } from './FormLabel'

export type InputSize = 'l' | 'xl'

type InputOptions = {
  required?: boolean | string
  [key: string]: undefined | boolean | string | {}
}

export type InputComponentProps = {
  // @todo had to set <any> becuse this type is used in wrong places,
  // for example as input props of src/components/Form/Checkbox.tsx.
  // Should be refactored at those places.
  autoComplete?: string
  checked?: boolean
  children?: ReactNode
  className?: string
  defaultValue?: number | string | readonly string[]
  disabled?: boolean
  disconnect?: boolean
  errorPath?: string
  errors?: {} // Inherited from context
  hidden?: boolean
  id?: string
  inputClassName?: string
  inputSize?: InputSize
  label?: ReactNode
  max?: string | number
  min?: string | number
  name?: string // Name is required by React Hook Form in order to gather values on submit
  onBlur?: (event: FocusEvent<any>) => void
  onChange?: (event: ChangeEvent<any>) => void
  onFocus?: () => void
  options?: InputOptions
  placeholder?: string
  register?: Function // Inherited from context
  required?: boolean
  silent?: boolean // Used internally by FormGroup to silence error messages on the single input
  textAlign?: string
  type?: 'text' | 'textarea' | 'password' | 'email' | 'number'
  value?: number | string | readonly string[] // TODO: once we finally remove Select, remove also readonly string[]
}

const Selector = forwardRef(({ type = 'text', inputSize = 'l', ...rest }: InputComponentProps, ref) => {
  switch (type) {
    case 'textarea':
      return <TextareaRebass ref={ref} sx={{ resize: 'none' }} {...rest} />
    default:
      return <InputRebass ref={ref} type={type} {...rest} />
  }
})

const InputComponent = ({
  autoComplete = 'off',
  defaultChecked,
  defaultValue,
  disabled,
  disconnect,
  errorPath,
  hidden,
  id,
  inputClassName,
  inputSize,
  label,
  max,
  min,
  name = '',
  onBlur,
  onChange,
  onFocus,
  options = {},
  placeholder,
  required,
  silent,
  textAlign,
  type = 'text',
  value,
  ...rest
}: Omit<BoxProps, 'onChange' | 'label'> & InputComponentProps) => (
  <ConnectForm>
    {({ formState, register }: ConnectFormType) => {
      const errors = formState?.errors || {}
      const error = errorPath ? get(errors, errorPath) : errors[name]

      return (
        <FormControl {...rest}>
          {!hidden && (
            <FormLabel name={name} required={required || options.required}>
              {label}
            </FormLabel>
          )}
          <Selector
            autoComplete={autoComplete}
            className={cc([inputClassName, { invalid: error, hidden, big: inputSize === 'xl' }])}
            disabled={disabled || hidden}
            id={id}
            name={name}
            placeholder={placeholder}
            type={type}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            textAlign={textAlign}
            onChange={onChange}
            defaultValue={defaultValue}
            min={min}
            max={max}
            {...(!disconnect && register ? register(name, { required, ...options }) : undefined)}
          />
          {!silent && error?.message && <ErrorMessage error={error} />}
        </FormControl>
      )
    }}
  </ConnectForm>
)

// Controlled
export type InputControlledResult = any

export type InputProps = InputComponentProps &
  Omit<BoxProps, 'label'> & {
    formControl?: Control<any>
  }

export const Input = ({ formControl, name = '', ...rest }: InputProps): ReactElement => {
  if (!formControl) {
    return <InputComponent {...rest} name={name} />
  }

  return (
    <Controller
      render={({ field }) => (
        <InputComponent
          {...rest}
          name={field.name}
          value={field.value}
          onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            field.onChange(event.currentTarget.value)
          }
          disconnect
        />
      )}
      control={formControl}
      name={name}
    />
  )
}
