import { NavItem, Select, SelectProps } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type ContactType = 'company' | 'person' | undefined
interface ContactTypeSelectProps
  extends Omit<SelectProps<ContactType>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: ContactType) => void
}

export const ContactTypeSelect = ({ onSelect, ...selectProps }: ContactTypeSelectProps): ReactElement => {
  const { t } = useTranslation()

  const items: NavItem<ContactType>[] = useMemo(
    () => [
      {
        id: 'any',
        children: t('settings.organization.vat_rates.modals.create_or_edit_ruleset.contact_type.any'),
        value: undefined,
      },
      {
        id: 'company',
        children: t('settings.organization.vat_rates.modals.create_or_edit_ruleset.contact_type.company'),
        value: 'company',
      },
      {
        id: 'person',
        children: t('settings.organization.vat_rates.modals.create_or_edit_ruleset.contact_type.person'),
        value: 'person',
      },
    ],
    [t],
  )

  const handleSelect = useCallback(
    (id?: string, value?: ContactType) => {
      onSelect?.(id, value)
    },
    [onSelect],
  )

  return <Select {...selectProps} items={items} withSearch={false} onSelect={handleSelect} dropdownSize="fitTrigger" />
}
