import { notify } from '@design-system'

import { TFunction } from 'i18next'

import { NotificationKeys } from '../../../../../../../enums/notificationKeys'

export const notifySyncUpdateError = (t: TFunction) =>
  notify({
    id: NotificationKeys.ExternalInvoicesSyncUpdated,
    message: t('external_invoices_sync_top_bar.error.update_sync_error'),
    variant: 'error',
  })
