import styled from '@emotion/styled'

export const EmptyStateImageWrapper = styled.div`
  display: flex;
  min-height: 0;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`

export const InnerContainer = styled.div`
  display: flex;
  min-height: inherit;
  justify-content: center;
`
