import { notify } from '@design-system'

import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { replaceVoucherFiles } from '@modules-deprecated/inbox/services/query-api'

import { NotificationKeys } from '../../../enums/notificationKeys'

interface UpdateVoucherMutationProps {
  organizationId: string
  voucherId: string
  onSuccess?: (data: void, variables: File[], context: unknown) => void
  onError?: (error: unknown, variables: File[], context: unknown) => void
}

export const useVoucherFileReplaceMutation = ({
  organizationId,
  voucherId,
  onSuccess,
  onError,
}: UpdateVoucherMutationProps) => {
  const { t } = useTranslation()

  return useMutation((files: File[]) => replaceVoucherFiles({ organizationId, voucherId, files }), {
    onSuccess: (...props) => {
      onSuccess && onSuccess(...props)
    },
    onError: (...props) => {
      onError && onError(...props)
      notify({
        id: NotificationKeys.VoucherInboxUpdate,
        message: t('voucher.inbox.toast.voucher_update_failed'),
        variant: 'error',
      })
    },
  })
}
