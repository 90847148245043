import { Button, ButtonsGroup, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../../../enums/modalId'
import { useAddCurrencyDefaultPaymentMethodModal } from '../../contexts/addCurrencyDefaultPaymentMethodModalContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { isProcessing, submitForm } = useAddCurrencyDefaultPaymentMethodModal()
  const { close: closeDeleteTrialDataModal } = useModal(ModalId.AddCurrencyDefaultPaymentMethodModal)

  const handleCancelButtonClick = useCallback(() => {
    closeDeleteTrialDataModal()
  }, [closeDeleteTrialDataModal])

  const handleConfirmButtonClick = useCallback(() => {
    submitForm()
  }, [submitForm])

  return (
    <ButtonsGroup>
      <Button variant="text" onClick={handleCancelButtonClick}>
        {t('settings.organization.invoice.currency_default_payment_methods_settings.add_modal.cancel_button')}
      </Button>
      <Button onClick={handleConfirmButtonClick} loading={isProcessing}>
        {t('settings.organization.invoice.currency_default_payment_methods_settings.add_modal.save_button')}
      </Button>
    </ButtonsGroup>
  )
}
