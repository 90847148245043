import { useCallback } from 'react'
import { useMutation } from 'react-query'

import {
  CreateOrganizationResponseData,
  createOrganiztion,
  OrganizationPayload,
} from '@modules-deprecated/app/organization'

import { APIError } from '../../../../../utils'
import { getSignupAnalyticsData } from '../../../../../utils/getSignupAnalyticsData'

interface UseCreateOrganizationProps {
  onError?: (error?: APIError) => void
  onSuccess?: (response: CreateOrganizationResponseData) => void
}

interface UseCreateOrganization {
  create: (payload: OrganizationPayload) => void
}

export const useCreateOrganization = (props?: UseCreateOrganizationProps): UseCreateOrganization => {
  const { onError, onSuccess } = props || {}

  const createOrganizaitionMutation = useMutation(createOrganiztion, {
    onSuccess: (response) => {
      onSuccess && onSuccess(response)
    },
    onError: (error?: APIError) => {
      onError && onError(error)
    },
  })

  const create = useCallback(
    (payload: OrganizationPayload) => {
      const analytics = getSignupAnalyticsData()
      createOrganizaitionMutation.mutate({ organization: { ...payload, ...analytics } })
    },
    [createOrganizaitionMutation],
  )

  return { create }
}
