import { NavItem } from '@design-system'

import { PeriodMode } from '../../../../enums/periodMode'
import { PeriodValue } from '../../../../types/periodValue'
import { NavigationValue } from '../types/navigationValue'
import { getFiscalYearItems } from './getFiscalYearItems'
import { getMonthItems } from './getMonthItems'
import { getQuarterItems } from './getQuarterItems'

interface GetPeriodItemsProps {
  locale?: string
  maxDate?: Date
  minDate?: Date
  navigationItem?: NavItem<NavigationValue>
  periodMode: PeriodMode
}

export const getPeriodItems = ({
  locale,
  maxDate,
  minDate,
  navigationItem,
  periodMode,
}: GetPeriodItemsProps): NavItem<PeriodValue>[] => {
  const { value: yearValue } = navigationItem || {}

  switch (periodMode) {
    case PeriodMode.Month:
      return typeof yearValue === 'number' ? getMonthItems(yearValue, locale, minDate, maxDate) : []

    case PeriodMode.Quarter:
      return typeof yearValue === 'number' ? getQuarterItems(yearValue, locale, minDate, maxDate) : []

    case PeriodMode.FiscalYear:
      return typeof yearValue === 'string' ? getFiscalYearItems(yearValue, minDate, maxDate) : []

    default:
      return []
  }
}
