import { AccountFormatter, AmountFormatter, DateFormatter, Translate } from '@components'
import { Icon } from '@components-deprecated'
import { InternalAccessor, notify, Table, TableColumn } from '@design-system'

import toNumber from 'lodash/toNumber'
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryParamKeys } from '../../../../enums/queryParamKeys'
import { Side } from '../../../../enums/side'
import { TableIds } from '../../../../enums/tableIds'
import { useQueryParams } from '../../../../hooks/useQueryParams'
import { useMissingReceiptsBankLines } from '../../hooks/useMissingReceiptsBankLines'
import { MissingScreen } from '../MissingScreen'
import { ReceiptView } from './ReceiptView'
import * as Styled from './styles'

interface QueryParams {
  page?: number | string
}

const defaultQuery: QueryParams = {
  page: 1,
}

interface MissingReceiptsView {
  accountId: string
  amount: number
  date: string
  description: string
  id: string
  side: Side
}

export const MissingReceiptsBase = (): ReactElement | null => {
  const { t } = useTranslation()
  const { bankLines: missingReceiptsBankLines, isLoading, isError, errors } = useMissingReceiptsBankLines()
  const [query, setQuery] = useQueryParams<QueryParams>({
    defaultQuery,
    key: QueryParamKeys.MissingReceiptsTable,
    withDefaultQueryInUrl: false,
  })

  const columns: TableColumn<MissingReceiptsView>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        size: 'xs',
        Cell: () => (
          <Styled.ThumbnailFormatterWrapper>
            <Icon type="IconBankBuilding" />
          </Styled.ThumbnailFormatterWrapper>
        ),
      },
      {
        Header: <Translate value="things_to_do.missing_receipts.table.header.date" />,
        accessor: 'date',
        size: 's',
        Cell: ({ value }) => <DateFormatter value={value} />,
      },
      {
        Header: <Translate value="things_to_do.missing_receipts.table.header.description" />,
        accessor: 'description',
        size: 'l',
      },
      {
        Header: <Translate value="things_to_do.missing_receipts.table.header.amount" />,
        accessor: 'amount',
        Cell: ({ row, data }) => {
          const values = data[row.id]
          return <AmountFormatter value={values.amount} side={values.side} colorize />
        },
      },
      {
        Header: <Translate value="things_to_do.missing_receipts.table.header.bank_name" />,
        accessor: 'accountId',
        Cell: ({ value }) => <AccountFormatter accountId={value} />,
      },
      {
        Header: '',
        accessor: InternalAccessor.SubRow,
        Cell: ({ value }) => <ReceiptView accountId={value.accountId} bankLineId={value.bankLineId} />,
      },
    ],
    [],
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setQuery({ page })
    },
    [setQuery],
  )

  const tableData: MissingReceiptsView[] = useMemo(
    () =>
      missingReceiptsBankLines.map((bankLine) => ({
        [InternalAccessor.SubRow]: { accountId: bankLine.accountId, bankLineId: bankLine.id },
        accountId: bankLine.accountId,
        amount: bankLine.amount,
        date: bankLine.entryDate,
        description: bankLine.description,
        id: bankLine.id,
        side: bankLine.side as Side,
      })),
    [missingReceiptsBankLines],
  )

  useEffect(() => {
    if (isError) {
      notify({
        id: NotificationKeys.ThingsToDoMissingReceipts,
        message: t('things_to_do.missing_receipts.error.list'),
        variant: 'error',
      })
      console.error(errors.map((error) => error))
    }
  }, [isError, errors, t])

  if (!isLoading && tableData.length === 0) {
    return null
  }

  return (
    <MissingScreen title={<Translate value="things_to_do.missing_receipts" />}>
      <Table
        columns={columns}
        data={tableData}
        id={TableIds.MissingReceipts}
        isClientSidePagination
        isLoading={isLoading}
        onPageChange={handlePageChange}
        currentPage={toNumber(query.page)}
        withItemsPerPageSelect
      />
    </MissingScreen>
  )
}

export const MissingReceipts = React.memo(MissingReceiptsBase)
