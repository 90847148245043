import { PrismicArticle } from '@components-deprecated'

import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPrismicDocumentsByTypeTags } from './action-creators'
import { ProgressBar } from './ProgressBar'

type PrismicProps = {
  type: string
  tags: string[]
  uid: string
  getMaxStep: Function
}

export function PrismicDocument({ type, tags, uid, getMaxStep }: PrismicProps): ReactElement {
  const dispatch = useDispatch()
  const content = useSelector((state: any) => {
    const document = state.prismic.entities.prismicDocument
    if (document) {
      const t = Object.keys(document)
        .map((k) => document[k])
        .find((doc) => doc.uid === uid)
      return t
    }
  })

  const titles = useSelector((state: any) => state.prismic.titles)

  useEffect(() => {
    getMaxStep && getMaxStep(titles.length)
  }, [getMaxStep, titles])

  useEffect(() => {
    dispatch(getPrismicDocumentsByTypeTags({ type, tags }))
  }, [dispatch, type, tags])

  if (content === undefined) {
    return <></>
  }

  return (
    <>
      <ProgressBar titles={titles} activeTitle={content?.data?.title[0].text} />
      <PrismicArticle type={type} content={content?.data?.content} />
    </>
  )
}
