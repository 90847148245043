import { Color, DefaultValue, getShadow, Icon as IconComponent, Spacing } from '@design-system'

import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { ShortcutKey as ShortcutKeyComponent } from '../../../ShortcutKey'

export const GlobalSearchTipsWrapper = styled.div`
  width: 100%;
  padding: ${Spacing.L};
  position: absolute;
  top: calc(100% + ${Spacing.S});
  left: 0;
  z-index: -1; // we have to move it back to not cover search input with box shadow
  border-radius: ${DefaultValue.BorderRadius};
  background-color: ${Color.DeepSeaGreen};
  ${getShadow('elevatedStrong')};
`

export const Header = styled.header`
  display: flex;
  margin-bottom: ${Spacing.L};
`

export const TitleWrapper = styled.div`
  display: flex;
`

export const TitleIcon = styled(IconComponent)`
  margin-right: ${Spacing.S};
`

export const Content = styled.div`
  margin-bottom: ${Spacing.L};
`

export const Footer = styled.footer`
  border-top: 1px solid ${transparentize(0.9, Color.White)};
  padding-top: ${Spacing.L};
`

export const ShortcutKey = styled(ShortcutKeyComponent)`
  background-color: ${transparentize(0.9, Color.White)};
`
