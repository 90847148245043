import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

export const InfoSection = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.InfoSectionWrapper title={t('umbrella.admin_profile.info.title')} variant="info">
      {t('umbrella.admin_profile.info.content')}
    </Styled.InfoSectionWrapper>
  )
}
