import React, { memo, ReactElement, useState } from 'react'

import * as Styled from './styles'

export interface Option {
  id: number
  label: string
  value: number
}

interface TabsGroupProps {
  label: string
  options: Option[]
  onSelect: (option: Option) => void
}

export const TabsGroup = memo(({ label, options, onSelect }: TabsGroupProps): ReactElement => {
  const [optionSelected, setOption] = useState<Option>(options[0])

  const handleSelect = (option: Option) => {
    setOption(option)
    onSelect(option)
  }

  return (
    <>
      <Styled.GridLabel>{label}</Styled.GridLabel>
      <Styled.Grid>
        {options.map((option) => (
          <Styled.GridItem
            onClick={() => handleSelect(option)}
            selected={optionSelected.id === option.id}
            key={option.id}
          >
            {option.label}
          </Styled.GridItem>
        ))}
      </Styled.Grid>
    </>
  )
})
