import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { all, fork } from 'redux-saga/effects'

import { mapModules } from '../utils/modules'
import { mapValues } from '../utils/object'

export const modules = mapModules(require.context('./', true, /^\.\/[^/]+\/index.ts$/))

const reducersMap = omitBy(mapValues(modules, 'reducer'), isUndefined)

const reducers = combineReducers(reducersMap)
const sagaMiddleware = createSagaMiddleware()

export const store = createStore(reducers, applyMiddleware(sagaMiddleware))

const moduleSagas = Object.values(modules)
  .filter((mod: any) => !!mod.sagas)
  .map((mod: any): any => fork(mod.sagas))

sagaMiddleware.run(function* () {
  yield all(moduleSagas)
})
