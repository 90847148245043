import { getCurrentLocale } from '@design-system'

import qs from 'qs'

import { SortDirection } from '../../../../enums/sortDirection'
import { Metable } from '../../../../types/metable'
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../../utils'
import { CountryGroupSortProperty } from './enums/countryGroupSortProperty'
import { CountryGroup } from './types/countryGroup'
import { RulesetFormData } from './types/rulesetFormData'
import { RulesetWithRules } from './types/rulesetWithRules'
import { VatRateFormData } from './types/vatRateFormData'
import { VatRateFromTemplateFormData } from './types/vatRateFromTemplateFormData'
import { VatRateTemplateListValue } from './types/vatRateTemplateListValue'
import { VatRateTemplateResponseData } from './types/vatRateTemplateResponseData'

export interface FetchRulesetsResponseData extends Metable {
  salesTaxRulesets: RulesetWithRules[]
}

interface VatRateTemplatesQueryParams {
  organizationId: string
}

export interface FetchVatRateTemplatesResponseData extends Metable {
  taxRateTemplates: VatRateTemplateListValue[]
}

interface VatRateTemplatePayload {
  organizationId: string
  vatRateId: string
}

interface RulesetsQueryParams {
  organizationId: string
  include?: string
}

export const fetchRulesets = ({
  organizationId,
  include = 'salesTaxRuleset.rules:embed',
}: RulesetsQueryParams): Promise<FetchRulesetsResponseData> => {
  const locale = getCurrentLocale()

  const queryParams = qs.stringify({
    organizationId,
    include,
  })

  return getRequest(`/v2/salesTaxRulesets?${queryParams}`, {
    'accept-language': locale,
  })
}

export const deleteRuleset = (rulesetId: string): Promise<void> => {
  return deleteRequest(`/v2/salesTaxRulesets/${rulesetId}`)
}

export const deleteTaxRate = (taxRateId: string): Promise<void> => {
  return deleteRequest(`/v2/taxRates/${taxRateId}`)
}

export const updateTaxRate = (
  taxRateId: string,
  payload: Partial<VatRateFormData | VatRateFromTemplateFormData>,
): Promise<void> => {
  return putRequest(`/v2/taxRates/${taxRateId}`, {
    taxRate: payload,
  })
}

export const addTaxRate = (payload: VatRateFormData | VatRateFromTemplateFormData): Promise<void> => {
  return postRequest(`/v2/taxRates`, { taxRate: payload })
}

export const fetchVatRateTemplates = ({
  organizationId,
}: VatRateTemplatesQueryParams): Promise<FetchVatRateTemplatesResponseData> => {
  const locale = getCurrentLocale()
  const queryParams = qs.stringify({
    organizationId,
  })

  return getRequest(`/v2/bookkeeping/taxRateTemplates?${queryParams}`, { 'accept-language': locale })
}

export const fetchOneVatRateTemplate = ({
  organizationId,
  vatRateId,
}: VatRateTemplatePayload): Promise<VatRateTemplateResponseData> => {
  const locale = getCurrentLocale()
  const queryParams = qs.stringify({
    organizationId,
  })

  return getRequest(`/v2/bookkeeping/taxRateTemplates/${vatRateId}?${queryParams}`, { 'accept-language': locale })
}

export const postRuleset = (payload: RulesetFormData): Promise<void> => {
  return postRequest(`/v2/salesTaxRulesets`, { salesTaxRuleset: payload })
}

export const updateRuleset = (id: string, payload: Partial<RulesetFormData>): Promise<void> => {
  return putRequest(`/v2/salesTaxRulesets/${id}`, {
    salesTaxRuleset: payload,
  })
}

export interface FetchCountryGroupsResponseData extends Metable {
  countryGroups: CountryGroup[]
}

interface CountryGroupsQueryParams {
  organizationId: string
  sortDirection?: SortDirection
  sortProperty?: CountryGroupSortProperty
  include?: string
}

export const fetchCountryGroups = ({
  organizationId,
  sortDirection,
  sortProperty,
}: CountryGroupsQueryParams): Promise<FetchCountryGroupsResponseData> => {
  const locale = getCurrentLocale()
  const queryParams = qs.stringify({
    organizationId,
    sortDirection,
    sortProperty,
  })

  return getRequest(`/v2/countryGroups?${queryParams}`, { 'accept-language': locale })
}
