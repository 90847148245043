import React, { cloneElement, isValidElement, ReactElement } from 'react'

import { ColorOrCurrent } from '../../../../types/colorOrCurrent'
import { Icon, IconName } from '../../../Icon'

export interface NavIconChildren {
  color?: ColorOrCurrent
}

interface NavIconProps {
  icon: IconName | ReactElement
  color?: ColorOrCurrent
}

export const NavIcon = ({ color, icon }: NavIconProps): ReactElement => {
  if (typeof icon === 'string') {
    return <Icon icon={icon} color={color || 'currentColor'} />
  }

  return isValidElement<NavIconChildren>(icon)
    ? cloneElement(icon, {
        color: color || 'currentColor',
      })
    : icon
}
