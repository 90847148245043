import { FormItemsGroup, Input, Space } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Scope } from '../../../../../../../enums/scope'
import { useFormContext, useWatch } from '../../../../../../../hooks'
import { getImageClassName } from '../../../../../../../utils/getClassName'
import { getImgPath } from '../../../../../../../utils/getImgPath'
import { CompanySelect } from '../../../../../../selects/CompanySelect'
import { CountrySelect } from '../../../../../../selects/CountrySelect'
import { ContactFormSchema } from '../../../../utils/formData'
import { ContactStandardSettings } from '../../../ContactStandardSettings'
import { SelectedCompanyBox } from '../../../SelectedCompanyBox'
import * as Styled from './styles'

export const SupplierCompanyItems = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem, setValue, control } = useFormContext<ContactFormSchema>()
  const companyFromSearch = useWatch({ control, name: 'companyFromSearch' })
  const companyName = useWatch({ control, name: 'companyName' })
  const shouldShowDefaultState = !companyFromSearch?.id && !companyName?.length

  const handleCompanySelect = useCallback(
    (id?: string) => {
      if (id) {
        setValue('companyName', undefined)
      }
    },
    [setValue],
  )

  const handleCompanyCreate = useCallback(
    (name: string) => {
      setValue('companyName', name)
      setValue('companyFromSearch', undefined)
    },
    [setValue],
  )

  return (
    <>
      <FormItem
        label={t('create_contact_modal.form.company')}
        name="companyFromSearch"
        render={({ field: { value, onChange, ...props } }) => (
          <CompanySelect
            allowCreate
            inputValue={companyName || value?.name || ''}
            onCreate={handleCompanyCreate}
            onSelect={(id, value) => {
              onChange(value)
              handleCompanySelect(id)
            }}
            scopes={Scope.ContactRegisterLookup}
            selectedId={value?.id}
            selectedValue={value}
            {...props}
          />
        )}
      />

      {!!companyFromSearch?.id && <SelectedCompanyBox company={companyFromSearch} />}

      <Space size="l" />

      {shouldShowDefaultState && (
        <Styled.ImageWrapper className={getImageClassName()}>
          <Space size="s" />
          <img
            alt="Illustration-contact"
            height={193}
            loading="lazy"
            src={getImgPath('/modals/illustration-contact.svg')}
            width={272}
          />
        </Styled.ImageWrapper>
      )}

      {!!companyName && (
        <FormItemsGroup itemsInRow={2}>
          <FormItem
            label={t('create_contact_modal.form.tax_id')}
            name="taxId"
            render={({ field: { value, onChange, ...props } }) => (
              <Input
                {...props}
                onChange={onChange}
                placeholder={t('create_contact_modal.form.tax_id')}
                value={value}
                type="number"
              />
            )}
          />

          <FormItem
            label={t('create_contact_modal.form.country')}
            name="country"
            render={({ field: { value, onChange, ...props } }) => (
              <CountrySelect
                {...props}
                dropdownSize="fitTrigger"
                onSelect={(id, value) => onChange(value)}
                selectedId={value?.id}
              />
            )}
          />
        </FormItemsGroup>
      )}

      {!shouldShowDefaultState && (
        <>
          <Space size="xl" />
          <ContactStandardSettings />
        </>
      )}
    </>
  )
}
