import { PreviewFile, rwd } from '@design-system'

import { useTheme } from 'emotion-theming'
import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexProps } from 'rebass'

import { Theme } from '../../../types/theme'
import { Text } from '../../Typography'
import { Container as AbsoluteContainer } from '../Container'
import { Files } from './Files'
import * as Styled from './styles'

const LARGE_SCREEN_BREAKPOINT = rwd.getBreakpointValue('large')

export type FormFilesLayoutProps = FlexProps & {
  children: ReactNode
  files?: PreviewFile[]
  loading?: boolean
}

export const FormFilesLayoutBox = Styled.FormFilesLayoutBox

export const FormFilesLayout = ({ children, files = [], loading, ...rest }: FormFilesLayoutProps): ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme<Theme>()

  const renderLoadingOverlay = () => (
    <Styled.LoadingContainer theme={theme}>
      <Text>{t('loading')}</Text>
    </Styled.LoadingContainer>
  )

  return (
    <Styled.MainContainer {...rest}>
      <AbsoluteContainer>
        {loading && renderLoadingOverlay()}

        <Styled.FormFileStackLayout breakWidth={LARGE_SCREEN_BREAKPOINT}>
          {/* Form side */}
          <Styled.FormContainer direction="vertical">
            <Styled.FormAbsoluteContainer bg="inherit">{children}</Styled.FormAbsoluteContainer>
          </Styled.FormContainer>

          {/* Files side */}
          <Styled.FilesContainer>
            <Styled.FilesAbsoluteContainer bg="inherit">
              <Files files={files} isLoading={!!loading} />
            </Styled.FilesAbsoluteContainer>
          </Styled.FilesContainer>
        </Styled.FormFileStackLayout>
      </AbsoluteContainer>
    </Styled.MainContainer>
  )
}
