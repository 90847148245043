import { SmartSelect, SmartSelectProps } from '@components-deprecated'

import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'

import CreateContactModal from '@views/contacts/elements/CreateContactModal'
import { Contact } from '@views/contacts/types/contact'

import { State } from '../../../../types/reduxSaga-deprecated'
import { getItemsForSelect } from '../../../../utils/forms'
import { contactsRequested } from '../action-creators'

const FOCUS_TIMEOUT = 250

export type ContactSelectorComponentProps = SmartSelectProps<Contact> & {
  prefilledContactData?: Partial<Contact>
  isCustomer?: boolean
  isVendor?: boolean
  onItemSelect?: (selectedContact: Contact) => void
  organizationId?: string
}

export type ContactSelectorComponentRefProps = {
  isModalOpen: () => boolean
}

export const ContactSelectorComponent = forwardRef<ContactSelectorComponentRefProps, ContactSelectorComponentProps>(
  (
    {
      defaultValueId,
      disconnect,
      formControl,
      prefilledContactData,
      isCustomer,
      isVendor,
      name = 'contact-selector',
      onItemSelect = () => null,
      organizationId: providedOrganizationId,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const selectRef = useRef<HTMLDivElement>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalCreatedId, setModalCreatedId] = useState('')
    const [selectedContact, setSelectedContact] = useState<Contact>()
    const [prefilledName, setPrefilledName] = useState('')
    const isNameChanged = useRef(false)

    const defaultOrganizationId = useSelector((state: State) => state.app.organization?.id)
    const organizationId = providedOrganizationId || defaultOrganizationId
    const contacts: Contact[] = useSelector((state: any) => state.app.contacts)
    const items = getItemsForSelect<Contact>(contacts)
    const defaultValueName = prefilledContactData?.name
    const isPreselectedItemExists = !!defaultValueId
    const isSuggestionAvailable = !isNameChanged.current && !isPreselectedItemExists

    let placeholder
    if (isCustomer === isVendor) {
      placeholder = t('app.contacts.contact_selector.selectcontact')
    } else if (isCustomer) {
      placeholder = t('app.contacts.contact_selector.selectcustomer')
    } else if (isVendor) {
      placeholder = t('app.contacts.contact_selector.selectvendor')
    }

    useImperativeHandle(ref, () => ({
      isModalOpen() {
        return isModalOpen
      },
    }))

    const openModalOnShortcut = (_: KeyboardEvent, selectedId: string, isFocused: boolean, listItems: Contact[]) => {
      if (!selectedId && isFocused && prefilledName && !isModalOpen && listItems.length !== 1) {
        handleCreateNew()
      }
    }

    const handleNameChange = useCallback((name: string) => {
      if (!isNameChanged.current) {
        isNameChanged.current = true
      }

      setPrefilledName(name)
    }, [])

    const handleSelect = useCallback(
      (item: Contact) => {
        onItemSelect(item, name)
        setSelectedContact(item)
        setModalCreatedId('')
      },
      [name, onItemSelect],
    )

    const handleCreateNew = () => {
      setIsModalOpen(true)

      if (prefilledName !== defaultValueName) {
        setPrefilledName('')
      }
    }

    const getContactById = (itemId: string) => items?.find((contact) => contact.id === itemId)

    const handleModalClose = (idCreatedContact?: string) => {
      setIsModalOpen(false)
      setModalCreatedId(idCreatedContact || '')

      // Remove prefilled name when contact is created to select created contact
      if (idCreatedContact) {
        setPrefilledName('')
      }

      focus()
    }

    const focus = () => {
      const input = selectRef.current?.querySelector('input')

      if (input) {
        setTimeout(() => {
          input.focus()
        }, FOCUS_TIMEOUT)
      }
    }

    const reset = useCallback((prefilledName = '') => {
      setPrefilledName(prefilledName)
      setModalCreatedId('')
      setSelectedContact(undefined)
      isNameChanged.current = false
    }, [])

    // Reset view on new data
    useUpdateEffect(() => {
      if (prefilledContactData?.id) {
        reset(prefilledContactData?.name)
      }
    }, [prefilledContactData])

    useEffect(() => {
      const hasPrefilledNameChanged = prefilledName && prefilledName !== defaultValueName

      if (defaultValueName && !hasPrefilledNameChanged) {
        setPrefilledName(defaultValueName)
      }
      // `defaultValueName` is the only value that we should check
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValueName])

    useEffect(() => {
      if (modalCreatedId === undefined || modalCreatedId === selectedContact?.id || prefilledName) {
        return
      }

      const newSelectedContact = getContactById(modalCreatedId)
      newSelectedContact && handleSelect(newSelectedContact)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, modalCreatedId])

    useEffect(() => {
      if (organizationId) {
        dispatch(
          contactsRequested({
            organizationId,
            page: { offset: 0 },
            filters: { text: null, isCustomer, isVendor, pageSize: 2000 },
          }),
        )
      }
    }, [dispatch, isCustomer, isVendor, organizationId])

    return (
      <>
        <SmartSelect<Contact>
          disconnect={disconnect}
          items={items}
          name={name}
          notFoundText={t('app.contacts.contact_selector.notfound')}
          onCreateNew={handleCreateNew}
          onEnterPress={openModalOnShortcut}
          onItemSelect={handleSelect}
          onTabPress={openModalOnShortcut}
          onTextChange={handleNameChange}
          placeholder={placeholder}
          preselectedId={modalCreatedId || defaultValueId}
          ref={selectRef}
          suggestedName={isSuggestionAvailable ? defaultValueName : undefined}
          withSelectEvent
          {...rest}
        />
        {isModalOpen && (
          <CreateContactModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            isCustomer={isCustomer}
            isVendor={isVendor}
            prefilledName={prefilledName}
            prefilledContactData={prefilledContactData}
          />
        )}
      </>
    )
  },
)

export type ContactSelectorRefProps = ContactSelectorComponentRefProps

export type ContactSelectorProps = ContactSelectorComponentProps & {
  formControl?: Control<any>
}

export const ContactSelector = forwardRef<ContactSelectorRefProps, ContactSelectorProps>(
  ({ formControl, defaultValueId, name, ...rest }, ref) => {
    if (!formControl) {
      return <ContactSelectorComponent {...rest} ref={ref} name={name} defaultValueId={defaultValueId} />
    }

    return (
      <Controller
        render={({ field }) => (
          <ContactSelectorComponent
            {...rest}
            defaultValueId={field.value?.id}
            disconnect
            prefilledContactData={field.value}
            onItemSelect={(item: Contact) => {
              field.onChange(item)
              rest.withSelectEvent && rest.onItemSelect && rest.onItemSelect(item, name)
            }}
            name={field.name}
            ref={ref}
          />
        )}
        control={formControl}
        name={name}
      />
    )
  },
)
