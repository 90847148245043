import React, { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { EmberEventPayload } from '../../../types/ember'

interface ContextState {
  openUpgradePlanOverlay: () => void
}

const SalaryEmberActionsContext = createContext<ContextState | undefined>(undefined)

interface SalaryEmberActionsContextProviderProps {
  children?: ReactNode
  onOpenUpgradePlanOverlay: (payload: EmberEventPayload) => void
}

export const SalaryEmberActionsContextProvider = ({
  children,
  onOpenUpgradePlanOverlay,
}: SalaryEmberActionsContextProviderProps): ReactElement => {
  const openUpgradePlanOverlay = useCallback(
    () => onOpenUpgradePlanOverlay({ detail: null }),
    [onOpenUpgradePlanOverlay],
  )

  return (
    <SalaryEmberActionsContext.Provider value={{ openUpgradePlanOverlay }}>
      {children}
    </SalaryEmberActionsContext.Provider>
  )
}

export const useSalaryEmberActions = () => {
  const context = useContext(SalaryEmberActionsContext)

  if (!context) {
    throw new Error('SalaryEmberActionsContextProvider is missing in the module!')
  }

  return context
}
