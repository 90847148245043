import { BorderRadius, Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { DASHBOARD_WIDGET_SPACING } from '../../../../constants/dashboardWidgetSpacing'
import { getImgPath } from '../../../../utils/getImgPath'

const externalInvoicesSyncIllustration = getImgPath('/external-invoices-sync/banner-external-invoices-sync.svg')

export const ExternalInvoicesSyncBannerWrapper = styled.div`
  width: 100%;
  height: 104px;
  margin: ${DASHBOARD_WIDGET_SPACING / 2}px 0;
  padding: ${Spacing.L} ${Spacing.XL};
  border-radius: ${BorderRadius.L};
  position: relative;
  background-color: ${Color.Lavender};
  background-image: url('${externalInvoicesSyncIllustration}');
  background-position: bottom right 110px;
  background-size: contain;
  background-repeat: no-repeat;
`

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: ${Spacing.S};
  right: ${Spacing.S};
`
