import React from 'react'

export const PaperPlane = () => {
  return (
    <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.4854 56.9708C44.2174 56.9708 56.9708 44.2174 56.9708 28.4854C56.9708 12.7533 44.2174 0 28.4854 0C12.7533 0 0 12.7533 0 28.4854C0 44.2174 12.7533 56.9708 28.4854 56.9708Z"
        fill="#BBE3FF"
      />
      <path d="M19.3993 41.4577L45.08 20.5977L22.1456 33.1897L19.3993 41.4577Z" fill="#26ABEF" />
      <path
        d="M8.12196 29.0121L17.734 30.9988L22.1455 33.2192L28.1056 40.1434C29.2158 41.4289 31.2609 41.3412 32.2542 39.9388L46.8621 19.3125L8.12196 26.8502C6.95333 27.0839 6.95333 28.7492 8.12196 29.0121Z"
        fill="#2C84FB"
      />
      <path d="M19.3992 41.459L17.7339 30.9706L43.3853 21.0664L22.1455 33.191L19.3992 41.459Z" fill="#52BEFF" />
    </svg>
  )
}
