// order is important
export const services = [
  'bookkeeping',
  'vat_statements',
  'annual_report',
  'tax_statements',
  'payroll_administration',
  'counselling',
  'private_tax_declaration',
  'parental_purchase',
  'auditor_statement',
  'invoices_payment',
] as const
