import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDirtyRoute } from '../../../../../contexts/dirtyRouteContext'
import { useForm } from '../../../../../hooks'
import { getDefaultValues, getValidationSchema, ProfileForm } from '../utils/formData'
import { useUmbrellaProfile } from './useUmbrellaProfile'
import { useUpdateProfile } from './useUpdateProfile'

export const useProfileForm = () => {
  const { data, isLoading: isProfileDataLoading } = useUmbrellaProfile()
  const { update, isSubmitting } = useUpdateProfile()
  const { setDirty } = useDirtyRoute()
  const { t } = useTranslation()

  const handleFormSubmit = useCallback(
    (values: ProfileForm) => {
      update({
        address: {
          city: values.address?.city || null,
          country: values.address?.countryId || null,
          street: values.address?.street || null,
          zipcode: values.address?.zipCode || null,
        },
        description: values.description || null,
        email: values.email,
        logoFileId: values.logo || null,
        name: values.companyName,
        phone: values.phone || null,
        registrationNo: values.cvr || null,
        services: JSON.stringify(values.services) || null,
      })
    },
    [update],
  )

  const {
    formState: { isDirty },
    reset,
    ...form
  } = useForm({
    defaultValues: useMemo(() => getDefaultValues(data), [data]),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
    onSubmit: handleFormSubmit,
  })

  // Set default values when data comes from `useUmbrellaProfile`
  useEffect(() => {
    if (!isProfileDataLoading) {
      reset(getDefaultValues(data))
    }
    // Update only on `isProfileDataLoading` change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileDataLoading])

  // Set dirty flag when the form is changed
  useEffect(() => {
    setDirty(isDirty)
  }, [isDirty, setDirty])

  return { ...form, isLoading: isProfileDataLoading, isSubmitting, update }
}
