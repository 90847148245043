import { LoadingModal, Text } from '@components-deprecated'
import { Button, ButtonProps } from '@design-system'

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'rebass'

import { State } from '../../../types/reduxSaga-deprecated'
import { reconcileAll } from '../action-creators'
import { ReconcileAllModal } from './ReconcileAllModal'

export type ReconcileAllButtonProps = ButtonProps

export const ReconcileAllButton = ({ ...rest }: ReconcileAllButtonProps) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const reconcileAllApprovedState = useSelector((state: State) => state.bankReconciliation.reconcileAllApprovedState)

  const handleOpenModal = useCallback(() => setModalOpen(true), [])
  const handleCloseModal = useCallback(() => setModalOpen(false), [])

  const handleCancel = useCallback(() => {
    setModalOpen(false)
  }, [])

  const handleReconcile = useCallback(() => {
    dispatch(reconcileAll())
    setModalOpen(false)
  }, [dispatch])

  return (
    <>
      <div data-tip={t('bankreconciliation.reconcile_all')}>
        <Button {...rest} icon="checkCircle" onClick={handleOpenModal} size="m" variant="text" />
      </div>
      <ReconcileAllModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onCancel={handleCancel}
        onReconcile={handleReconcile}
      />

      <LoadingModal
        isOpen={reconcileAllApprovedState.reconciling}
        percent={
          100 *
          (reconcileAllApprovedState.total ? reconcileAllApprovedState.complete / reconcileAllApprovedState.total : 0)
        }
      >
        <Flex flexDirection={'row'} justifyContent={'center'}>
          <Text className={'body2'} textAlign={'center'}>
            {!!reconcileAllApprovedState.total &&
              t('bankreconciliation.reconcile_all.reconciling', {
                reconciled: reconcileAllApprovedState.processed,
                total: reconcileAllApprovedState.total,
              })}
          </Text>
        </Flex>
      </LoadingModal>
    </>
  )
}
