import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../../enums/queryKeys'
import { fetchOrganizationRoles } from '../query-api'

export const useOrganizationRoles = () => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const {
    data: roles,
    isLoading: areOrganizationRolesLoading,
    isIdle: areOrganizationRolesIdle,
    ...rest
  } = useQuery(
    [QueryKeys.OrganizationRoles, organization?.id],
    () => fetchOrganizationRoles(organization?.id as string),
    {
      enabled: !!organization,
    },
  )

  return {
    ...rest,
    roles: roles || [],
    isLoading: isOrganizationLoading || areOrganizationRolesLoading || areOrganizationRolesIdle,
  }
}
