import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Themable } from '../../../types/themable'
import { Theme } from '../../../types/theme'
import { Text } from '../../Typography'

const LoadingWrapper = styled.div<Themable>`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
`

type LoadingContainerProps = {
  children?: ReactNode
}

export const LoadingContainer = ({ children }: LoadingContainerProps): ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme<Theme>()

  return (
    <LoadingWrapper theme={theme}>
      <Text>{children || t('loading')}</Text>
    </LoadingWrapper>
  )
}
