import { IconName, LottieAnimationName } from '@design-system'

import { Scope } from '../../../enums/scope'
import { EmptyViewType } from '../types/emptyViewType'

export interface EmptyProps {
  animation: LottieAnimationName
  title: string
  description: string
  buttonText?: string
  buttonIcon?: IconName
  buttonVisibilityScope?: Scope | Scope[]
}

export const emptyViewVariants: Record<EmptyViewType, EmptyProps> = {
  creditors: {
    animation: 'billsEmptyState',
    title: 'empty_state.creditors.title',
    description: 'empty_state.creditors.description',
    buttonText: 'create_bill',
  },
  debtors: {
    animation: 'invoicesEmptyState',
    title: 'empty_state.debtors.title',
    description: 'empty_state.debtors.description',
    buttonText: 'create_invoice',
  },
  invoices: {
    animation: 'invoicesEmptyState',
    title: 'empty_state.invoices.title',
    description: 'empty_state.invoices.description',
    buttonText: 'create_invoice',
  },
  quotes: {
    animation: 'quotesEmptyState',
    title: 'empty_state.quotes.title',
    description: 'empty_state.quotes.description',
    buttonText: 'create_quote',
  },
  recurringInvoices: {
    animation: 'recurringInvoicesEmptyState',
    title: 'empty_state.recurring_invoices.title',
    description: 'empty_state.recurring_invoices.description',
    buttonText: 'create_recurring_invoice',
  },
  products: {
    animation: 'productsEmptyState',
    title: 'empty_state.products.title',
    description: 'empty_state.products.description',
    buttonText: 'create_products',
  },
  bills: {
    animation: 'billsEmptyState',
    title: 'empty_state.bills.title',
    description: 'empty_state.bills.description',
    buttonText: 'create_bill',
  },
  bank: {
    animation: 'bankEmptyState',
    title: 'empty_state.bank.title',
    description: 'empty_state.bank.description',
  },
  bankNotConnected: {
    animation: 'bankEmptyState',
    title: 'empty_state.bank_not_connected.title',
    description: 'empty_state.bank_not_connected.description',
    buttonText: 'empty_state.bank_not_connected.buttonText',
    buttonIcon: 'plug',
  },
  bankNotConnectedUnauthorized: {
    animation: 'bankEmptyState',
    title: 'empty_state.import_transactions.title',
    description: 'empty_state.import_transactions.description',
    buttonText: 'empty_state.import_transactions.buttonText',
    buttonIcon: 'arrowUpInsideCloud',
  },
  contacts: {
    animation: 'contactsEmptyState',
    title: 'empty_state.contacts.title',
    description: 'empty_state.contacts.description',
    buttonText: 'create_contact',
  },
  transactions: {
    animation: 'transactionsEmptyState',
    title: 'empty_state.transactions.title',
    description: 'empty_state.transactions.description',
    buttonText: 'empty_state.transactions.create_transaction',
  },
  einvoicesRegistered: {
    animation: 'invoicesEmptyState',
    title: 'empty_state.einvoice.registered.title',
    description: 'empty_state.einvoice.registered.description',
    buttonText: 'empty_state.einvoice.buttonText',
  },
  einvoicesNotRegistered: {
    animation: 'invoicesEmptyState',
    title: 'empty_state.einvoice.not_registered.title',
    description: 'empty_state.einvoice.not_registered.description',
    buttonText: 'empty_state.einvoice.buttonText',
  },
}
