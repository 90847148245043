import { pxToNumber, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const LayoutWrapper = styled.section`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  flex-direction: column;
  flex-grow: 1;
`

export const Header = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${pxToNumber(Spacing.XXXL) + pxToNumber(Spacing.L)}px ${pxToNumber(Spacing.XXXL) * 3}px
    ${pxToNumber(Spacing.XXXL) + pxToNumber(Spacing.L)}px;
`

export const HeaderLeft = styled.div``

export const HeaderRight = styled.div``

export const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`

export const Footer = styled.footer`
  margin-top: ${pxToNumber(Spacing.XXXL) * 2}px;
  display: flex;
`

export const FooterImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  margin: 0 auto;
`
