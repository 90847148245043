import React, { ReactElement } from 'react'

import { InputPrefixSuffixProps } from '../InputPrefixSuffix'
import * as Styled from './styles'

type InputPrefixProps = InputPrefixSuffixProps

export const InputPrefix = ({ children, ...rest }: InputPrefixProps): ReactElement => (
  <Styled.InputPrefixWrapper {...rest}>{children}</Styled.InputPrefixWrapper>
)
