import { object, SchemaOf, string } from 'yup'

export interface OrganizationInfoForm {
  city: string | undefined
  countryId: string | undefined
  email: string | undefined
  fax: string | undefined
  name: string
  phone: string | undefined
  registrationNo: string | undefined
  street: string | undefined
  zipcode: string | undefined
}

export const defaultValues: OrganizationInfoForm = {
  city: '',
  countryId: '',
  email: '',
  fax: '',
  name: '',
  phone: '',
  registrationNo: '',
  street: '',
  zipcode: '',
}

export type OrganizationInfoFormSchema = SchemaOf<OrganizationInfoForm>

export const getValidationSchema = (): OrganizationInfoFormSchema =>
  object({
    city: string(),
    countryId: string(),
    email: string().email(),
    fax: string(),
    name: string().required(),
    phone: string(),
    registrationNo: string(),
    street: string(),
    zipcode: string(),
  })
