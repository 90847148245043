import { FileAvatar } from '@components'
import { Space } from '@design-system'

import React, { ReactElement } from 'react'

import * as Styled from './styles'

interface OrganizationProps {
  logoId?: string
  name: string
}

export const Organization = ({ logoId, name }: OrganizationProps): ReactElement => {
  return (
    <Styled.OrganizationWrapper>
      {logoId && (
        <>
          <FileAvatar logoId={logoId} />
          <Space size="m" horizontal />
        </>
      )}
      {name}
    </Styled.OrganizationWrapper>
  )
}
