import qs from 'qs'

import { getRequest } from '../../../utils'

const DEFAULT_STATS_AMOUNT = 3

export interface StatsContact {
  amount: number
  contactId: string
  name: string
  rest?: boolean
}

export interface StatsDataQueryParams {
  period: string
}

interface StatsContactResponse {
  data: StatsContact[]
}

export const fetchTopCustomers = async (
  organizationId: string,
  amount = DEFAULT_STATS_AMOUNT,
  params?: StatsDataQueryParams,
): Promise<StatsContactResponse> =>
  getRequest(`/v2/organizations/${organizationId}/stats/topCustomers?n=${amount}&${qs.stringify(params)}`)

export const fetchTopSuppliers = async (
  organizationId: string,
  amount = DEFAULT_STATS_AMOUNT,
  params?: StatsDataQueryParams,
): Promise<StatsContactResponse> =>
  getRequest(`/v2/organizations/${organizationId}/stats/topSuppliers?n=${amount}&${qs.stringify(params)}`)

type SummaryType = 'all' | 'draft' | 'overdue' | 'paid' | 'unpaid'

interface InvoiceSummaryItem {
  count: number
  amount: number
}

type InvoiceSummaryResponse = {
  summary: Record<SummaryType, InvoiceSummaryItem>
}

export const fetchSummary = (organizationId: string, params: StatsDataQueryParams): Promise<InvoiceSummaryResponse> =>
  getRequest(`/v2/invoices/summary?${qs.stringify({ organizationId, ...params })}`)

export type InvoiceTimelineMonthlyResponse = {
  revenue: number
  expenses: number
  income: number
}

export type InvoiceTimelineResponse = {
  data: Record<string, InvoiceTimelineMonthlyResponse>[]
}

export const fetchInvoiceTimeline = (
  organizationId: string,
  params: StatsDataQueryParams,
): Promise<InvoiceTimelineResponse> =>
  getRequest(`/v2/dashboard/incomeTimeline?${qs.stringify({ organizationId, ...params })}`)

interface EasyNumbersPeriodData {
  expense: number
  periodText: string
  revenue: number
  unpaidExpense: number
  unpaidRevenue: number
}

interface EasyNumbers {
  hasData: boolean
  period: EasyNumbersPeriodData
  previous: EasyNumbersPeriodData
}

type EasyNumbersResponse = {
  easyNumbers: EasyNumbers
}

export const fetchEasyNumbers = (organizationId: string, params: StatsDataQueryParams): Promise<EasyNumbersResponse> =>
  getRequest(`/v2/dashboard/easyNumbers?${qs.stringify({ organizationId, ...params })}`)
