import { BorderRadius, Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const BankConnectionWrapper = styled.div`
  background-color: ${Color.DayGrey};
  border-radius: ${BorderRadius.XL};
  padding: ${Spacing.M};
`

export const BankLogo = styled.img`
  border-radius: ${BorderRadius.M};
  object-fit: contain;
`
