import * as React from 'react'

import { IconDiners, IconJcb, IconMaestro, IconMasterCard, IconVisa } from './paymentIcons'

const dankort = ['visa-dankort', 'dankort']
const jcb = ['jcb']
const maestro = ['maestro']
const mastercard = [
  'mastercard-dk',
  'mastercard',
  'mastercard-debet-dk',
  '3d-mastercard-debet-dk',
  'mastercard-debet',
  '3d-mastercard-dk',
]
const visa = ['visa-electron-dk', 'visa-dk', 'visa', '3d-visa-dk', '3d-visa-electron-dk']
const diners = ['diners']

interface CardIssuerLogoProps {
  cardType: string
  height: string
  width: string
}

export const CardIssuerLogo = (props: CardIssuerLogoProps): React.ReactElement => {
  const cardType = props.cardType.toLowerCase()

  if (dankort.includes(cardType)) {
    return <IconVisa height={props.height} width={props.width} />
  } else if (jcb.includes(cardType)) {
    return <IconJcb height={props.height} width={props.width} />
  } else if (maestro.includes(cardType)) {
    return <IconMaestro height={props.height} width={props.width} />
  } else if (mastercard.includes(cardType)) {
    return <IconMasterCard height={props.height} width={props.width} />
  } else if (visa.includes(cardType)) {
    return <IconVisa height={props.height} width={props.width} />
  } else if (diners.includes(cardType)) {
    return <IconDiners height={props.height} width={props.width} />
  }

  return <IconVisa height={props.height} />
}
