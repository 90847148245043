import { Button, ButtonProps, useModal } from '@design-system'

import React, { MouseEvent, ReactElement, useCallback } from 'react'

import { ModalId } from '../../../enums/modalId'
import { useComponentId } from '../../../hooks/useComponentId'
import { useIsBasicPlan } from '../../../hooks/useIsBasicPlan'
import { WithTrackingContext } from '../../../types/withTrackingContext'
import { UpgradeSubscriptionModal } from '../../Modal/upgradeSubscriptionModal'

export interface ButtonPremiumProps extends ButtonProps, WithTrackingContext {
  isBlocked?: boolean
}

export const ButtonPremium = ({
  disabled,
  isBlocked: blocked,
  onClick,
  trackingContext,
  ...rest
}: ButtonPremiumProps): ReactElement => {
  // we need unique id just in case couple of UpgradeSubscriptionModal will be rendered in the same view
  const upgradeModalId = useComponentId(ModalId.UpgradeSubscriptionModal)
  const { open: openUpgradeModal } = useModal(upgradeModalId)

  const { isBasicPlan, isLoading } = useIsBasicPlan()
  const isBlocked = blocked || isBasicPlan

  const handleButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (isBlocked) {
        openUpgradeModal()
        return
      }

      onClick?.(event)
    },
    [isBlocked, onClick, openUpgradeModal],
  )

  return (
    <>
      <Button {...rest} disabled={isLoading || disabled} onClick={handleButtonClick} />
      <UpgradeSubscriptionModal id={upgradeModalId} trackingContext={trackingContext} />
    </>
  )
}
