import { notify } from '@design-system'

import { TFunction } from 'i18next'

import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

import { NotificationKeys } from '../../../../enums/notificationKeys'
import { isPaidPlan } from './plansData'
import { UpodiSubscriptionUpdatePayloadData } from './types'

export const getUpdateSubscriptionPayload = (
  organizationId: string,
  plan: SubscriptionPlan,
  yearlyPayment: boolean,
  couponCode?: string,
): UpodiSubscriptionUpdatePayloadData => {
  let period

  if (isPaidPlan(plan)) {
    period = yearlyPayment ? SubscriptionPeriod.Yearly : SubscriptionPeriod.Monthly
  }

  return { organizationId, period, plan, couponCode }
}

export const getPriceAccountedForCoupons = (
  price: number,
  shouldApplyCoupon: boolean,
  discountPercent?: number | null,
) => {
  if (shouldApplyCoupon) {
    return Math.round(price - (price / 100) * (discountPercent || 0))
  }

  return price
}

export const notifySubscriptionUpdateError = (t: TFunction) =>
  notify({
    id: NotificationKeys.OrganizationSubscriptionUpdate,
    message: t('organization_subscription.subscription_update.error'),
    variant: 'error',
  })

export const notifySubscriptionUpdateSuccess = (t: TFunction) =>
  notify({
    id: NotificationKeys.OrganizationSubscriptionUpdate,
    message: t('organization_subscription.subscription_update.success'),
    variant: 'success',
  })
