import { SkeletonText, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { BillType } from '../../../../enums/billType'
import { useBillView } from '../../contexts/billViewContext'
import * as Styled from './styles'

export const BillHeader = (): ReactElement => {
  const { t } = useTranslation()
  const { billData, isFetching } = useBillView()

  const { bill } = billData || {}
  const isCreditNote = billData?.bill.type === BillType.CreditNote
  const billTypeLabel = isCreditNote ? t('credit_note') : t('bill')
  const billTitle = `${billTypeLabel} ${bill?.voucherNo || ''}`

  return (
    <Styled.Header>
      {isFetching ? (
        <SkeletonText variant="h3" width={150} />
      ) : (
        <Text variant="h3" as="span" title={billTitle} truncate>
          {billTitle}
        </Text>
      )}
    </Styled.Header>
  )
}
