import { Button, Color, DefaultValue, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, BoxProps } from 'rebass'

import { reactClass } from '../../utils'
import { LazyScrolling } from '../LazyScrolling'
import { Text } from '../Typography/Text'
import { SelectListItem } from './SelectListItem'
import { SmartSelectItem } from './types'

const FooterAction = styled.footer`
  border-top: 1px solid ${Color.Gray30};
  padding-top: ${Spacing.S};
`

type SelectListProps<T> = BoxProps & {
  allowCreateNew?: boolean
  createNewText?: string
  isOpen?: boolean
  items: T[]
  notFoundText?: string
  onCreateNew?: () => void
  onItemSelect: (id: string) => void
  render?: (props: T) => React.ReactNode
  request?: () => void
  selectedId?: string
}

const renderListItem = <T extends SmartSelectItem>({ id, ...rest }: T) => (
  <SelectListItem id={id} key={id} data-list-item-id={id} {...rest} />
)

export const SelectList = <T extends SmartSelectItem>({
  allowCreateNew,
  createNewText,
  isOpen,
  items,
  maxHeight,
  notFoundText,
  onCreateNew = () => null,
  onItemSelect = () => null,
  render,
  request,
  selectedId,
  ...rest
}: SelectListProps<T>) => {
  const { t } = useTranslation()
  const handleCreateNew = () => onCreateNew()
  const handleSelect = (id: string) => onItemSelect(id)

  const createNewTextLine = createNewText
    ? t('components.smartselect.createnewitem', { name: createNewText })
    : t('components.smartselect.createnew')

  return (
    <Box
      className={reactClass('smart-select-list')}
      css={css`
        padding: ${Spacing.S};
        background-color: ${Color.White};
        box-shadow: 0 10px 70px ${transparentize(0.65, Color.Gray200)};
        border-radius: ${DefaultValue.BorderRadius};
        overflow: hidden;
        text-align: left;
      `}
      {...rest}
    >
      {items?.length === 0 && notFoundText && (
        <Text p={1} color={Color.Gray90}>
          {notFoundText}
        </Text>
      )}
      <LazyScrolling<T>
        items={items}
        maxHeight={maxHeight}
        onItemSelect={handleSelect}
        render={render || renderListItem}
        request={request}
        selectedId={selectedId}
        withFooter={allowCreateNew}
        withOverflowScroll
      />
      {allowCreateNew && (
        <FooterAction>
          <Button icon="plusCircle" variant="text" onClick={handleCreateNew} size="xl" fullWidth>
            {createNewTextLine}
          </Button>
        </FooterAction>
      )}
    </Box>
  )
}
