import styled from '@emotion/styled'

export const ModalImage = styled.img`
  pointer-events: none;
`

export const CheckPointsListAndImageWrapper = styled.div`
  display: flex;
  width: 480px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
`
