import React, { ReactElement } from 'react'

import { ReportHtml } from '../../elements/ReportHtml'
import { ReportsSkeleton } from '../../elements/ReportsSkeleton'
import { ProfitAndLossTableActions } from './elements/ProfitAndLossTableActions'
import { useProfitAndLoss } from './hooks/useProfitAndLoss'

export const ProfitAndLoss = (): ReactElement => {
  const { report, isLoading, isIdle } = useProfitAndLoss()

  return (
    <>
      <ProfitAndLossTableActions />
      {isLoading || isIdle ? <ReportsSkeleton /> : <ReportHtml html={report?.html} />}
    </>
  )
}
