import React, { ReactElement } from 'react'

import { LicensePacksTable } from '../LicensePacksTable'
import { LicensePacksTableActions } from '../LicensePacksTableActions/LicensePacksTableActions'

export const LicensePacksList = (): ReactElement => {
  return (
    <>
      <LicensePacksTableActions />
      <LicensePacksTable />
    </>
  )
}
