import { Color } from '../../../enums/color'
import { IconName } from '../../Icon'
import { SectionMessageVariant } from '../types'

interface SectionMessageStyles {
  color: Color
  bgColor: Color
  icon: IconName
}

export const variantStyles: Record<SectionMessageVariant, SectionMessageStyles> = {
  error: {
    color: Color.Red120,
    bgColor: Color.Red10,
    icon: 'exclamationMarkCircle',
  },
  warning: {
    color: Color.Yellow220,
    bgColor: Color.Yellow30,
    icon: 'exclamationMarkCircle',
  },
  info: {
    color: Color.Purple110,
    bgColor: Color.Lavender,
    icon: 'iSignCircle',
  },
  success: {
    color: Color.Green140,
    bgColor: Color.Green20,
    icon: 'checkCircle',
  },
  neutral: {
    color: Color.Gray200,
    bgColor: Color.Gray40,
    icon: 'iSignCircle',
  },
}
