import { normalize } from 'normalizr'
import Prismic from 'prismic-javascript'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import { initPrismicApi } from '../../utils'
import { prismicDocumentsByTypeTagsReceived, prismicReceived, prismicTitlesReceived } from './action-creators'
import { GET_PRISMIC_DOCUMENTS_BY_TYPE_TAGS, PRISMIC_GET_DOCUMENT_ACTION } from './actions'
import { prismicArticle, prismicDocuments } from './entities'
import { DocumentByTypeTags, DocumentByTypeTagsPayload, PrismicGetAction } from './types'

type Result = {
  data: {
    title: {
      text: string
    }
  }
}

function* prismicDocumentSaga(action: PrismicGetAction) {
  try {
    const result: { prismicDocument: any } = yield call(getPrismicDocument, action.payload.type, action.payload.uid)
    const normalizedResult = normalize(result, prismicArticle)
    yield put(prismicReceived(normalizedResult.entities))
  } catch (error: any) {
    throw new Error(error)
  }
}

function* prismicDocumentsByTypeTagsSaga(type: DocumentByTypeTags) {
  try {
    const payload = { type: type.payload.type, tags: type.payload.tags }
    const result: { results: any } = yield call(getPrismicDocumentsByTypeTags, payload)
    const titles: string[] = result.results.map((res: Result) => res.data.title[0].text)
    const normalizedResult = normalize(result.results, prismicDocuments)
    yield put(prismicTitlesReceived(titles))
    yield put(prismicDocumentsByTypeTagsReceived(normalizedResult.entities))
  } catch (error: any) {
    throw new Error(error)
  }
}

export default function* (): any {
  yield all([
    yield takeLatest(PRISMIC_GET_DOCUMENT_ACTION, prismicDocumentSaga),
    yield takeLatest(GET_PRISMIC_DOCUMENTS_BY_TYPE_TAGS, prismicDocumentsByTypeTagsSaga),
  ])
}

async function getPrismicDocument(type: string, uid: string) {
  const prismicApi = await initPrismicApi()
  const document = prismicApi.getByUID(type, uid)
  return Promise.resolve(document)
}

async function getPrismicDocumentsByTypeTags(payload: DocumentByTypeTagsPayload) {
  const prismicApi = await initPrismicApi()
  const documents = prismicApi.query(
    [Prismic.Predicates.at('document.type', payload.type), Prismic.Predicates.at('document.tags', payload.tags)],
    { orderings: `[my.${payload.type}.order]` },
  )
  return Promise.resolve(documents)
}
