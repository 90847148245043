import { Space, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Company } from '@modules-deprecated/app/companies/types'

import { DataSummarySection } from '../../../../DataSummarySection'

interface SelectedCompanyBoxProps {
  company: Partial<Company>
}

export const SelectedCompanyBox = ({ company }: SelectedCompanyBoxProps): ReactElement => {
  const { t } = useTranslation()

  const { city = '', id, number = '', numberTo = '', phone = '', street = '', state = '', zipcode = '' } = company

  const addressLineOne = numberTo ? `${street} ${number} - ${numberTo}` : `${street} ${number}`
  const addressLineTwo = `${zipcode} ${city}`

  return (
    <DataSummarySection>
      <DataSummarySection.Column>
        <Text>{addressLineOne}</Text>
        <Text>{addressLineTwo}</Text>
        <Text>{state}</Text>
      </DataSummarySection.Column>
      <DataSummarySection.Column>
        <DataSummarySection.ColumnItem alignment="right">
          <Text colorVariant="secondary">{t('create_contact_modal.form.tax_id')}</Text>
          <Space horizontal size="l" />
          <Text weight="medium">{id}</Text>
        </DataSummarySection.ColumnItem>
        {phone ? (
          <DataSummarySection.ColumnItem alignment="right">
            <Text colorVariant="secondary">{t('create_contact_modal.form.phone')}</Text>
            <Space horizontal size="l" />
            <Text weight="medium">{phone}</Text>
          </DataSummarySection.ColumnItem>
        ) : null}
      </DataSummarySection.Column>
    </DataSummarySection>
  )
}
