import { TFunction } from 'i18next'
import { object, SchemaOf, string } from 'yup'

export interface AddCurrencyDefaultPaymentMethodForm {
  currencyCode: string
  paymentMethodId: string
}

export const defaultValues: AddCurrencyDefaultPaymentMethodForm = {
  currencyCode: '',
  paymentMethodId: '',
}

export type AddCurrencyDefaultPaymentMethodFormSchema = SchemaOf<AddCurrencyDefaultPaymentMethodForm>

export const getValidationSchema = (t: TFunction): AddCurrencyDefaultPaymentMethodFormSchema =>
  object({
    currencyCode: string().required(t('required_field')),
    paymentMethodId: string().required(t('required_field')),
  })
