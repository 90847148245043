import { SettingsSection } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { RemoveUserContextProvider } from '../../contexts/removeUserContext'
import { UserType } from '../../enums/userType'
import { useOrganizationBookkeepers } from '../../hooks/useOrganizationBookkeepers'
import { BookkeepersEmptyView } from './elements/BookkeepersEmptyView'
import { BookkeepersSectionSideActions } from './elements/BookkeepersSectionSideActions'
import { BookkeepersTable } from './elements/BookkeepersTable'
import { InviteBookkeeperModal } from './elements/InviteBookkeeperModal'

export const BookkeepersSection = (): ReactElement => {
  const { t } = useTranslation()
  const { data, isLoading } = useOrganizationBookkeepers()

  const isNoData = data?.length === 0 && !isLoading

  return (
    <SettingsSection>
      <SettingsSection.Content
        title={t('settings.organization.users.bookkeepers_section.title')}
        sideActions={<BookkeepersSectionSideActions />}
      >
        {isNoData ? (
          <BookkeepersEmptyView />
        ) : (
          <RemoveUserContextProvider userType={UserType.Bookkeeper}>
            <BookkeepersTable />
          </RemoveUserContextProvider>
        )}
      </SettingsSection.Content>
      <InviteBookkeeperModal />
    </SettingsSection>
  )
}
