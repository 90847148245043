import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Color } from '../../enums/color'
import { Spacing } from '../../enums/spacing'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { Label as LabelComponent } from '../Label'
import { Size } from './types/size'
import { getCheckedStyle } from './utils/getCheckedStyle'
import { getDisabledStyle } from './utils/getDisabledStyle'
import { getErrorStyle } from './utils/getErrorStyle'
import { getHoverStyle } from './utils/getHoverStyle'
import { getPressedStyle } from './utils/getPressedStyle'
import { sizeToProperties } from './utils/sizeToProperties'

interface ControlProps {
  size: Size
  withTopOffset?: boolean
}

export const Control = styled.span<ControlProps>`
  width: ${({ size }) => sizeToProperties[size].dimension}px;
  height: ${({ size }) => sizeToProperties[size].dimension}px;
  margin-top: ${({ size, withTopOffset }) => withTopOffset && sizeToProperties[size].offsetTop};
  border: 1px solid ${Color.Gray50};
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${Color.White};
  box-shadow: 0 1px 2px ${transparentize(0.85, Color.Gray90)};
  ${getDefaultTransition('background', 'border', 'box-shadow')};
`

export const Label = styled(LabelComponent)`
  margin-left: ${Spacing.S};
  cursor: inherit;
  ${getDefaultTransition('color')};
`

export const NativeRadio = styled.input`
  opacity: 0;
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  &:focus-visible {
    ~ ${() => Control} {
      box-shadow: 0 0 0 2px ${Color.DeepSeaGreen};
    }
  }
`

interface RadioWrapperProps {
  checked: boolean
  disabled: boolean
  error: boolean
  size: Size
}

export const RadioWrapper = styled.label<RadioWrapperProps>`
  display: flex;
  position: relative;
  cursor: pointer;

  ${(props) => hasHoverStyle(props) && getHoverStyle(Control, props.checked)};
  ${(props) => hasPressedStyle(props) && getPressedStyle(Control, props.checked)};
  ${(props) => hasCheckedStyle(props) && getCheckedStyle(Control, props.size)};
  ${(props) => hasErrorStyle(props) && getErrorStyle(Control, props.checked)};
  ${(props) => hasDisabledStyle(props) && getDisabledStyle(Control, Label, props.checked)};
`

const hasHoverStyle = ({ disabled, error }: RadioWrapperProps) => !disabled && !error
const hasPressedStyle = ({ disabled, error }: RadioWrapperProps) => !disabled && !error
const hasCheckedStyle = ({ checked }: RadioWrapperProps) => checked
const hasDisabledStyle = ({ disabled }: RadioWrapperProps) => disabled
const hasErrorStyle = ({ error }: RadioWrapperProps) => error
