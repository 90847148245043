import { ButtonProtected } from '@components'
import { Alert, Flex, Spacing, Text } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useSyncBankConnection } from '@views/bankIntegration/hooks/useSyncBankConnection'

import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { useBankIntegration } from '../../contexts/bankIntegrationContext'

export const ConnectionErrorAlert = (): ReactElement | null => {
  const { t } = useTranslation()
  const { isLoading: isLoadingSyncBankConnection, syncAccountBankConnection } = useSyncBankConnection()
  const { connectedBankAccountId, hasBankConnectionError, isLoading, isOrphaned, requiresBankReconnection } =
    useBankIntegration()

  const shouldShowConnectButton = !isOrphaned && !hasBankConnectionError

  const handleConnectBankClick = useCallback(() => {
    syncAccountBankConnection(connectedBankAccountId as string)
  }, [connectedBankAccountId, syncAccountBankConnection])

  const errorMessage = useMemo(() => {
    if (isOrphaned || hasBankConnectionError) {
      return t('bank_connection_alert.connection_error')
    }

    return t('bank_connection_alert.default_text')
  }, [hasBankConnectionError, isOrphaned, t])

  if (isLoading || (!isOrphaned && !hasBankConnectionError && !requiresBankReconnection)) {
    return null
  }

  return (
    <Alert>
      <Flex alignItems="center" gap={Spacing.S}>
        <Text weight="bold">{errorMessage}</Text>
        {shouldShowConnectButton && (
          <ButtonProtected
            size="m"
            onClick={handleConnectBankClick}
            variant="secondary"
            loading={isLoadingSyncBankConnection}
            scopes={Scope.BankSyncWrite}
            trackingContext={TrackingContext.BankConnectionsConnectBank}
          >
            {t('bank_connection_alert.button')}
          </ButtonProtected>
        )}
      </Flex>
    </Alert>
  )
}
