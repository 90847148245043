import isBefore from 'date-fns/isBefore'
import orderBy from 'lodash/orderBy'

const START_OF_THE_YEAR = new Date(new Date().getFullYear(), 0, 1)

interface GetLastViewDateProps {
  currentDate: Date
  periodicLockReminderLastViewedDate?: string
  reminderDates: Date[]
}

export const getLastViewDate = ({
  currentDate,
  periodicLockReminderLastViewedDate,
  reminderDates,
}: GetLastViewDateProps): Date => {
  // if there's no last view date saved in DB yet, take the previous reminder date
  // and if that one can't be found too, take the beginning of the year
  if (!periodicLockReminderLastViewedDate) {
    const reminderDatesSorted = orderBy(reminderDates)
    const previousReminderDate = reminderDatesSorted.findLast((reminderDate) => isBefore(reminderDate, currentDate))
    return previousReminderDate || START_OF_THE_YEAR
  }

  return new Date(periodicLockReminderLastViewedDate)
}
