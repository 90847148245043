import { Spacing, Text as TextComponent } from '@design-system'

import styled from '@emotion/styled'

export const ConnectionErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Text = styled(TextComponent)`
  margin-top: ${Spacing.L};
`

export const MenetoErrorText = styled(TextComponent)`
  text-align: center;
`
