import { Table, TableColumn } from '@design-system'

import React, { ReactElement } from 'react'

export const ModalSkeleton = (): ReactElement => {
  const data: any = []
  const columns: TableColumn<any>[] = [
    {
      accessor: 'column1',
      Header: '',
    },
    {
      accessor: 'column2',
      Header: '',
    },
    {
      accessor: 'column3',
      Header: '',
    },
  ]

  // Just a dummy data so we can simulate the format of data that will be there
  return <Table columns={columns} data={data} isLoading skeletonItemsPerPage={10} />
}
