import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconInvoices = ({
  color = '#41c447',
  color2 = '#ffffff',
  color3 = '#309c35',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  isOutlined,
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-invoices')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 355.93 241.14">
      <path
        d="M351.59,114.45S331,99.15,269.35,99.15s-102.79,30.59-164.46,30.59-82.23-15.29-82.23-15.29V298s20.56,15.3,82.23,15.3S207.68,282.7,269.35,282.7,351.59,298,351.59,298Z"
        transform="translate(-9.16 -85.65)"
        fill={isOutlined ? 'transparent' : coloring(color)}
        stroke={coloring(color2)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="27"
      />
      <circle
        cx="182.34"
        cy="119.88"
        r="38.5"
        fill={isOutlined ? 'transparent' : coloring(color3)}
        stroke={isOutlined ? coloring(color2) : coloring(color3)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="20"
      />
    </svg>
  </Box>
)
