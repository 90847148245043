import React, { ReactElement } from 'react'

import { InputPrefixSuffixProps } from '../InputPrefixSuffix'
import * as Styled from './styles'

export type InputSuffixProps = InputPrefixSuffixProps

export const InputSuffix = ({ children, ...rest }: InputSuffixProps): ReactElement => (
  <Styled.InputSuffixWrapper {...rest}>{children}</Styled.InputSuffixWrapper>
)
