import { ConnectForm, ConnectFormType, ErrorMessage, FormControl, FormLabel } from '@components-deprecated'

import get from 'lodash/get'
import { ReactElement, ReactNode } from 'react'
import { Control, Controller } from 'react-hook-form'

export interface PublicAccountSelectWrapperProps {
  formControl?: Control<any>
  name: string
  render: (value: any, onChange: () => void) => ReactNode
  label?: ReactNode
}

interface ConnectFormWrapperProps {
  hidden?: boolean
  silent?: boolean
  name?: string
  required?: boolean
  children: ReactNode
  label?: ReactNode
  errorPath?: string
}

const ConnectFormWrapper = ({
  errorPath,
  hidden,
  name = '',
  required,
  label,
  children,
  silent,
  ...rest
}: ConnectFormWrapperProps): ReactElement => {
  return (
    <ConnectForm>
      {({ formState, register }: ConnectFormType) => {
        const errors = formState?.errors || {}
        const error = errorPath ? get(errors, errorPath) : errors[name]

        return (
          <FormControl {...rest}>
            {!hidden && (
              <FormLabel name={name} required={required}>
                {label}
              </FormLabel>
            )}
            {children}
            {!silent && error?.message && <ErrorMessage error={error} />}
          </FormControl>
        )
      }}
    </ConnectForm>
  )
}

export const PublicAccountSelectWrapper = ({
  formControl,
  name = '',
  render,
  ...rest
}: PublicAccountSelectWrapperProps): ReactElement => {
  return (
    <Controller
      render={({ field: { value, onChange, ...props } }) => {
        return <ConnectFormWrapper {...rest}>{render(value, onChange)}</ConnectFormWrapper>
      }}
      control={formControl}
      name={name}
    />
  )
}
