import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../utils'
import { deleteOrganizationFromUmbrella } from '../../../query-api'

interface UseDeletePaymentMethodProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useDeleteOrganizationFromUmbrella = (props?: UseDeletePaymentMethodProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate,
    isLoading: isProcessing,
    ...rest
  } = useMutation(deleteOrganizationFromUmbrella, { onSuccess, onError })

  return { delete: mutate, isProcessing, ...rest }
}
