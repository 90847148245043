import { NavItem, NavItemAccessor } from '../../NavList'

export const getItemsWithSubItemBreadcrumb = <T>(items: NavItem<T>[]) =>
  items.reduce((result: NavItem<T>[], item) => {
    if (item.subItems?.length) {
      const subItemsWithBreadcrumbs: NavItem<T>[] = [
        {
          id: item.id,
          accessor: NavItemAccessor.HeaderBreadcrumb,
          children: item.children,
          value: null as unknown as T,
        },
        ...item.subItems,
      ]

      const itemDecorated: NavItem<T> = { ...item, subItems: getItemsWithSubItemBreadcrumb(subItemsWithBreadcrumbs) }
      return [...result, itemDecorated]
    }

    return [...result, item]
  }, [])
