import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const ZervantInvoicingButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`

export const ButtonSubText = styled(Text)`
  margin-top: ${Spacing.S};
`
