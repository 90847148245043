import { getCurrentLocale } from '@design-system'

import qs from 'qs'

import { PeriodMode } from '../../../../enums/periodMode'
import { SortDirection } from '../../../../enums/sortDirection'
import { Metable } from '../../../../types/metable'
import { getRequest } from '../../../../utils'
import { AccountStatement } from './types/accountStatement'

export interface FetchAccountStatementsPayload {
  accountId: string
  includeVoided?: boolean
  pageSize?: number
  period?: string
  q?: string
  sortDirection?: SortDirection
}
export interface FetchAccountStatementsResponse extends Metable {
  accountStatement: AccountStatement
}

export const fetchAccountStatements = ({
  accountId,
  includeVoided,
  pageSize,
  period,
  q,
  sortDirection,
}: FetchAccountStatementsPayload): Promise<FetchAccountStatementsResponse> => {
  const queryParams = qs.stringify({
    limit: pageSize || 1000,
    ...(includeVoided !== undefined ? { includeVoided } : {}),
    ...(period && period !== PeriodMode.All ? { period } : {}),
    ...(q ? { q } : {}),
    ...(sortDirection ? { sortDirection } : {}),
  })

  const locale = getCurrentLocale()

  return getRequest(`/v2/accounts/${accountId}/statement?${queryParams}`, { 'accept-language': locale })
}
