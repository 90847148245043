import { Badge } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import * as Styled from './styles'

export const MenuBadge = (): ReactElement | null => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()

  if (!umbrella) {
    return null
  }

  return (
    <Styled.MenuBadgeWrapper>
      <Badge variant="warning">{t('menu.admin_badge').toUpperCase()}</Badge>
    </Styled.MenuBadgeWrapper>
  )
}
