import styled from '@emotion/styled'
import React, { ReactElement, ReactNode } from 'react'

import { Themable } from '../../types/themable'
import { FormLabel } from '../Form'
import { SuggestionItem, SuggestionItemProps } from './SuggestionItem'

const SuggestionsContainer = styled.div<Themable>`
  background-color: ${({ theme }) => theme.colors.bg.primaryLight};
  border: 1px dashed ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
`
const SuggestionsList = styled.ul<Themable>`
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  margin-bottom: 8px;
`

type SuggestionsGroupProps = {
  label?: string
  children: ReactNode
}

export const SuggestionsGroup = ({ label, children, ...rest }: SuggestionsGroupProps): ReactElement | null => {
  if (!children) {
    return null
  }

  return (
    <div {...rest}>
      {label && <FormLabel>{label}</FormLabel>}
      <SuggestionsContainer>
        <SuggestionsList>
          {React.Children.map(
            children as ReactElement<SuggestionItemProps>[],
            (child: ReactElement<SuggestionItemProps>) => {
              if (!React.isValidElement(child) || child.type !== SuggestionItem) {
                console.error('Item is not a valid SuggestionItem element')
                return null
              }

              return child
            },
          )}
        </SuggestionsList>
      </SuggestionsContainer>
    </div>
  )
}
