import { TableActionsWrapper } from '@components'

import { ReactElement } from 'react'

import { ContactBalanceDateSelector } from '../ContactBalanceDateSelector'
import { ContactBalanceSearch } from '../ContactBalanceSearch'
import { ContactBalanceTitle } from '../ContactBalanceTitle'

interface ContactBalanceTableActionsProps {
  isLoading?: boolean
  title: string
  total?: number
}

export const ContactBalanceTableActions = ({
  isLoading,
  title,
  total,
}: ContactBalanceTableActionsProps): ReactElement => {
  return (
    <TableActionsWrapper>
      <ContactBalanceTitle isLoading={isLoading} title={title} total={total} />
      <ContactBalanceSearch />
      <ContactBalanceDateSelector />
    </TableActionsWrapper>
  )
}
