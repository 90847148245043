import { DropzoneFullScreenProtected, DropzoneProtected, ProtectedComponent } from '@components'
import { Attachment, AttachmentFile, SkeletonBox } from '@design-system'

import React, { memo, ReactElement, useCallback, useLayoutEffect, useRef } from 'react'
import { FileRejection } from 'react-dropzone'
import { useLocation } from 'react-router-dom'

import { useUploadFiles } from '@views/receipts/hooks/useUploadFiles'

import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { useSegment } from '../../../../hooks'
import { useFormContext, useWatch } from '../../../../hooks/useForm'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { useBillAttachmentPageContext } from '../../contexts/billAttachmentPageContext'
import { BillsRoute } from '../../enums/billsRoute'
import { BillState } from '../../enums/billState'
import { getBillFormBaseWrapperHeight } from '../../utils/getBillFormBaseWrapperHeight'
import { BillAttachmentPreviewBlocked } from '../BillAttachmentPreviewBlocked'
import { BillEditableAttachmentsActions } from '../BillEditableAttachmentsActions'
import { BillEditableAttachmentsPreview } from '../BillEditableAttachmentsPreview'
import { useBillForm } from '../BillEditableForm'
import { useBohrAutoComplete } from '../BillEditableForm/hooks/useBohrAutoComplete'
import { BillFormSchema } from '../BillEditableForm/utils/formData'
import { useDeleteAttachment } from './hooks/useDeleteAttachment'
import { useImageWrapperHeight } from './hooks/useImageWrapperHeight'
import * as Styled from './styles'

export const BillEditableAttachments = memo((): ReactElement => {
  const [currentPageIndex, setCurrentPageIndex] = useBillAttachmentPageContext()
  const { control, setValue, getValues } = useFormContext<BillFormSchema>()
  const billState = useWatch({ control, name: 'state' })
  const { billFormView, isFetching } = useBillForm()
  const { track } = useSegment()
  const billImageWrapperRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const { deleteAttachment } = useDeleteAttachment()
  const { autoCompleteWithBohrData, resetAutoCompletedWithBohrData } = useBohrAutoComplete()

  const isNewBillRoute = location.pathname === BillsRoute.New
  const attachmentsFiles = useWatch({ control, name: 'attachmentsFiles', defaultValue: [] })

  const setFormAttachmentsFiles = useCallback(
    (file: AttachmentFile) => {
      const currentAttachmentsFiles = getValues('attachmentsFiles') || []
      const shouldAutoCompleteForm = billState !== BillState.Approved
      setValue('attachmentsFiles', currentAttachmentsFiles.concat(file), { shouldDirty: true })
      setCurrentPageIndex(currentAttachmentsFiles.length)

      if (file.attachmentId) {
        const currentattachmentsFilesChosen = getValues('attachmentsFilesChosen') || []
        setValue('attachmentsFilesChosen', [...currentattachmentsFilesChosen, file])
      }

      if (shouldAutoCompleteForm && !currentAttachmentsFiles.length && isAuthorized(Scope.VoucherScanRead)) {
        autoCompleteWithBohrData(file)
      }
    },
    [getValues, billState, setValue, setCurrentPageIndex, autoCompleteWithBohrData],
  )

  const { isUploading, uploadFiles } = useUploadFiles({
    onUpload(file: AttachmentFile) {
      setFormAttachmentsFiles(file)
    },
    onSuccess() {
      track('File Uploaded (FE)', {
        context: TrackingContext.CreateBillUpload,
      })

      track('xxx - expense - create expense - receipt uploaded', { billPage: billFormView })
    },
    options: {
      'x-should-scan': true,
    },
  })

  const { reset: resetImageWrapperHeight } = useImageWrapperHeight(
    billImageWrapperRef,
    attachmentsFiles,
    !isFetching && !isUploading,
  )

  const handleAttachmentSelected = useCallback(
    (attachment: Attachment) => {
      setFormAttachmentsFiles({ attachmentId: attachment.id, ...attachment.file })
    },
    [setFormAttachmentsFiles],
  )

  const handleFilesSelected = useCallback(
    (files: File[]) => {
      uploadFiles(files)
    },
    [uploadFiles],
  )

  const handleFilesDropped = useCallback(
    (filesAccepted: File[], filesRejected?: FileRejection[]) => {
      uploadFiles(filesAccepted, filesRejected)
    },
    [uploadFiles],
  )

  const handleDeleteFile = useCallback(
    (fileId: string) => {
      const { attachmentsFilesUpdated } = deleteAttachment(fileId)

      if (!attachmentsFilesUpdated.length) {
        resetImageWrapperHeight()
        resetAutoCompletedWithBohrData()
      }

      if (currentPageIndex > 0) {
        setCurrentPageIndex(currentPageIndex - 1)
      }
    },
    [deleteAttachment, currentPageIndex, resetImageWrapperHeight, resetAutoCompletedWithBohrData, setCurrentPageIndex],
  )

  const handlePageChange = useCallback(
    (index: number) => {
      setCurrentPageIndex(index)
    },
    [setCurrentPageIndex],
  )

  useLayoutEffect(() => {
    if (isNewBillRoute) {
      setCurrentPageIndex(0)
      resetImageWrapperHeight()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewBillRoute])

  useLayoutEffect(() => {
    if (billImageWrapperRef.current) {
      const billImageWrapperElement = billImageWrapperRef.current
      const height = getBillFormBaseWrapperHeight()
      billImageWrapperElement.style.height = `${height}px`
    }
  }, [])

  return (
    <Styled.BillEditableAttachmentsWrapper>
      <Styled.BillImageWrapper ref={billImageWrapperRef}>
        {isFetching ? (
          <SkeletonBox fullHeight fullWidth />
        ) : (
          <>
            {!isUploading && !!attachmentsFiles?.length ? (
              <ProtectedComponent scopes={Scope.BillAttachmentRead} unauthorizedNode={<BillAttachmentPreviewBlocked />}>
                <BillEditableAttachmentsPreview file={attachmentsFiles[currentPageIndex]} onDelete={handleDeleteFile} />
              </ProtectedComponent>
            ) : (
              <DropzoneProtected
                scopes={Scope.BillAttachmentWrite}
                onDrop={handleFilesDropped}
                isUploading={isUploading}
                trackingContext={TrackingContext.CreateBillUpload}
              />
            )}
          </>
        )}
      </Styled.BillImageWrapper>
      <BillEditableAttachmentsActions
        currentAttachmentIndex={currentPageIndex}
        isLoading={isFetching || isUploading}
        onAttachmentSelect={handleAttachmentSelected}
        onFilesSelect={handleFilesSelected}
        onNavigationClick={handlePageChange}
        totalAttachments={attachmentsFiles?.length}
      />
      <DropzoneFullScreenProtected scopes={Scope.BillAttachmentWrite} onDrop={handleFilesDropped} />
    </Styled.BillEditableAttachmentsWrapper>
  )
})
