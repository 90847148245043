import { useEffect, useRef } from 'react'

import { Timeout } from '../types/timeout'

export const useScrollToElement = (element?: HTMLElement | null, shouldScroll?: boolean): void => {
  const isRenderRef = useRef(false) // mechanism for not animating the scroll when it's initially rendered
  const mountTimeoutRef = useRef<Timeout>()

  useEffect(() => {
    mountTimeoutRef.current = setTimeout(() => {
      isRenderRef.current = true
    }, 0)

    return () => {
      if (mountTimeoutRef.current) {
        clearTimeout(mountTimeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (element && shouldScroll) {
      element.scrollIntoView({ behavior: isRenderRef.current ? 'smooth' : 'auto', block: 'center' })
    }
  }, [element, shouldScroll])
}
