import { FormItemsGroup, Input, SkeletonText, Space, SwitchGroup, Text } from '@design-system'

import { useTranslation } from 'react-i18next'

import { UmbrellaRole } from '@modules-deprecated/app/umbrellas/types/umbrellaRole'

import { useFormContext } from '../../../../../../../../../../hooks'
import { useInviteOrUpdateUmbrellaUserForm } from '../../../../context/inviteOrUpdateUmbrellaUserFormContext'
import { InviteUmbrellaUserFormSchema } from '../../../../utils/formData'
import { RoleItem } from '../../../RoleItem/RoleItem'

export const ModalForm = () => {
  const { t } = useTranslation()
  const { roles, isLoadingRoles, isUpdateMode } = useInviteOrUpdateUmbrellaUserForm()
  const { FormItem } = useFormContext<InviteUmbrellaUserFormSchema>()

  return (
    <FormItemsGroup>
      <FormItem
        label={t('umbrella_users.create_edit_user_modal.email')}
        name="email"
        render={({ field }) => <Input {...field} disabled={isUpdateMode} />}
      />
      <Space size="xs" />
      <Text weight="bold">{t('umbrella_users.create_edit_user_modal.roles')}</Text>
      <FormItem
        name="roles"
        render={({ field: { value, onChange, ...props } }) =>
          isLoadingRoles ? (
            <SkeletonText />
          ) : (
            <SwitchGroup {...props} value={value} onChangeMultiple={onChange} direction="column">
              <FormItemsGroup itemsInRow={2}>
                {roles?.map((role: UmbrellaRole) => <RoleItem role={role} key={role.id} />)}
              </FormItemsGroup>
            </SwitchGroup>
          )
        }
      />
    </FormItemsGroup>
  )
}
