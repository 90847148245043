import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { Input as InputComponent, InputProps as InputComponentProps } from '../Input'

interface InputProps extends InputComponentProps {
  clearable: boolean
  searchable: boolean
}

const IconWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

export const ClearIconWrapper = styled(IconWrapper)`
  opacity: 0;
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  ${getDefaultTransition('opacity')};
`

export const ToggleIconWrapper = styled(IconWrapper)``

export const Input = styled(InputComponent)<InputProps>`
  ${({ clearable }) =>
    clearable &&
    `
    &:hover {
      ${ClearInputWrapper} {
        opacity: 1;
      }
    }
  `}

  ${({ searchable, disabled, selectLook }) =>
    !searchable &&
    !disabled &&
    selectLook &&
    css`
      cursor: pointer;
    `}
`

export const ClearInputWrapper = styled.div`
  opacity: 0;
  margin-right: ${Spacing.S};
  ${getDefaultTransition('opacity')};

  &:last-child {
    margin-right: 0;
  }
`
