import { ORGANIZATION_URL_PLACEHOLDER } from '../constants/organizationUrlPlaceholder'
import { ReactRoute } from '../enums/reactRoute'

type QueryParams = Record<string, string>

export const getOrganizationViewPath = (organizationUrl: string, reactRoute: ReactRoute, queryParams?: QueryParams) => {
  if (queryParams) {
    const searchParams = new URLSearchParams(queryParams)
    return `/${organizationUrl}/${reactRoute}?${searchParams.toString()}`
  }

  return `/${organizationUrl}/${reactRoute}`
}

export const getOrganizationViewPathPlaceholder = (reactRoute: ReactRoute) => {
  return `/${ORGANIZATION_URL_PLACEHOLDER}/${reactRoute}`
}
