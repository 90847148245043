import { FontWeight } from '@design-system'

export default {
  organizationMenu: {
    position: 'absolute',
    flexDirection: 'column',
    bottom: '50px',
    left: 0,
    right: 0,
    transition: 'top .4s ease-in-out',
    overflow: 'hidden',
  },
  organizationMenuCurrentOrganization: {
    height: '50px',
    padding: '0 30px',
    alignItems: 'center',
    cursor: 'pointer',
    flexShrink: 0,
  },
  organizationMenuCurrentOrganizationTitle: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: FontWeight.Medium,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  organizationMenuCurrentOrganizationSubtitle: {
    fontSize: '12px',
    lineHeight: '16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  organizationList: {
    flexDirection: 'column',
    marginTop: '10px',
    height: '100%',
    overflowY: 'auto',
  },
  organizationListItem: {
    height: '50px',
    display: 'block',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 30px',
  },
  organizationListItemTitle: {
    color: '#fff',
    fontWeight: FontWeight.Medium,
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  organizationMenuMyCompanies: {
    width: '100%',
    height: '50px',
    alignSelf: 'flex-end',
    alignItems: 'center',
    padding: '0 30px',
    fontSize: '14px',
    cursor: 'pointer',
    flexShrink: 0,
    fontWeight: FontWeight.Medium,
  },
}
