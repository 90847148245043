import React from 'react'

export const IconBankBuilding = () => (
  <i>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="47" height="47" fill="white" stroke="#E5E5E5" />
      <path
        d="M33.2267 30.3552H31.2291V23.4885C31.6037 23.4885 32.1031 23.4885 32.6025 23.4885C32.8522 23.4885 33.2267 23.3637 33.3516 23.114C33.6013 22.8643 33.6013 22.6146 33.6013 22.24C33.6013 21.7406 33.6013 21.2412 33.6013 20.7418C33.6013 20.3673 33.4764 19.9927 33.2267 19.743C32.8522 19.4933 32.6025 19.2436 32.2279 19.1188L24.737 14.1248C24.4873 14 24.2376 14 24.1128 14C23.9879 14 23.7382 14 23.6134 14.1248L15.1236 19.743C14.9988 19.8679 14.7491 19.9927 14.6242 20.4921C14.6242 20.617 14.6242 20.7418 14.6242 20.8667V22.4897C14.6242 22.9891 14.9988 23.3637 15.4982 23.3637H16.8715V30.3552C16.8715 30.3552 15.1236 30.3552 14.8739 30.3552C14.3745 30.3552 14 30.7297 14 31.2291C14 31.7285 14.3745 32.1031 14.8739 32.1031H30.8546H32.3528C32.6025 32.1031 32.8522 32.1031 33.1019 32.1031C33.6013 32.1031 33.9758 31.7285 33.9758 31.2291C34.1007 30.7297 33.7261 30.3552 33.2267 30.3552ZM21.1164 30.3552H18.6194V23.3637H21.1164V30.3552ZM25.3612 30.3552H22.8643V23.3637H25.3612V30.3552ZM29.4812 30.3552H26.9843V23.3637H29.4812V30.3552ZM16.3721 21.7406V20.9915L24.1128 15.8727L31.8534 20.9915V21.6158H16.3721V21.7406Z"
        fill="#909CA7"
      />
      <path
        d="M24.1136 17.8699C23.3645 17.8699 22.7402 18.4941 22.7402 19.2432C22.7402 19.9923 23.3645 20.6165 24.1136 20.6165C24.8627 20.6165 25.4869 19.9923 25.4869 19.2432C25.4869 18.3693 24.8627 17.8699 24.1136 17.8699Z"
        fill="#909CA7"
      />
    </svg>
  </i>
)
