import React, { useCallback } from 'react'
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import { Box, BoxProps } from 'rebass'

import { reactClass } from '../../../utils'
import { BillyTransaction } from '../types'
import { DroppableContainer } from './DroppableContainer'
import { DroppableHighlight } from './DroppableHighlight'
import { Placeholder } from './Placeholder'
import { Transaction } from './Transaction'
import { DroppableAreaProps } from './types'

export type DroppableTransactionsProps = DroppableAreaProps<BillyTransaction>

export type TransactionOrPlaceholderProps = BoxProps & {
  index: number
  billyTransaction: BillyTransaction
  snapshot: DroppableStateSnapshot
}

const TransactionOrPlaceholder = ({ billyTransaction, index, snapshot }: TransactionOrPlaceholderProps) => {
  return billyTransaction.id !== 'placeholder' ? (
    <Transaction billyTransaction={billyTransaction} index={index} {...snapshot} />
  ) : (
    <Placeholder index={index} {...billyTransaction} {...snapshot} />
  )
}

export const DroppableTransactions = ({
  collection = [],
  highlightOnDraggingOver,
  id,
  type,
}: DroppableTransactionsProps) => {
  const droppableChild = useCallback(
    (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
      <DroppableContainer
        flex="auto"
        p="10px"
        m="-10px"
        className={reactClass('droppable-transactions')}
        ref={provided.innerRef}
        {...provided.droppableProps}
        {...snapshot}
      >
        <DroppableHighlight
          highlightOnDraggingOver={highlightOnDraggingOver}
          {...provided.droppableProps}
          {...snapshot}
        />
        {collection.map((billyTransaction, index) => (
          <TransactionOrPlaceholder
            key={billyTransaction.id}
            index={index}
            billyTransaction={billyTransaction}
            snapshot={snapshot}
          />
        ))}
        <Box display="none">{provided.placeholder}</Box>
      </DroppableContainer>
    ),
    [collection, highlightOnDraggingOver],
  )

  return (
    <Droppable droppableId={id} type={type}>
      {droppableChild}
    </Droppable>
  )
}
