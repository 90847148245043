import { OrganizationInvitation } from '@modules-deprecated/app/organization/types/organizationInvitation'

import { User } from '../types/user'

export const getUsersFromInvitations = (invitations: OrganizationInvitation[]): User[] =>
  invitations.map(({ email, id, umbrella, userRole }) => {
    return {
      email,
      id,
      isOwner: false,
      isPending: true,
      userRole,
      ...(umbrella?.name ? { name: umbrella.name } : {}),
      ...(umbrella?.logoFileId ? { logoId: umbrella.logoFileId } : {}),
    }
  })
