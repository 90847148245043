import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconSmartphoneAlt = ({ color = '#FFFFFF', width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-smartphone-alt')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g>
        <path
          fill={coloring(color)}
          d="M12,1v14H5V1H12 M12,0H5C4.448,0,4,0.448,4,1v14c0,0.552,0.448,1,1,1h7c0.552,0,1-0.448,1-1V1C13,0.448,12.552,0,12,0L12,0z"
        />
      </g>
      <rect fill={coloring(color)} x="4" y="3" width="9" height="1" />
      <rect fill={coloring(color)} x="4" y="12" width="9" height="1" />
      <rect fill={coloring(color)} x="8" y="13.5" width="1" height="1" />
    </svg>
  </Box>
)
