import { Modal, ModalProps, withModalConditionalRender } from '@design-system'

import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateOrEditRulesetModalContextProvider } from './context/createOrEditRulesetModalContext'
import { CreateOrEditRulesetForm } from './elements/CreateOrEditRulesetForm'
import { ModalContent } from './elements/ModalContent'
import { ModalFooter } from './elements/ModalFooter'

interface CreateOrEditRulesetModalProps extends ModalProps {
  rulesetId?: string
  disabled?: boolean
}

export const CreateOrEditRulesetModal = withModalConditionalRender(
  ({ rulesetId, disabled, ...modalProps }: CreateOrEditRulesetModalProps): ReactElement => {
    const { t } = useTranslation()
    const modalBodyRef = useRef<HTMLDivElement>(null)
    const [scrollContainerElement, setScrollContainerElement] = useState<HTMLDivElement>()
    const isEditMode = !!rulesetId

    // Bug workaround
    // https://github.com/atlassian/react-beautiful-dnd/issues/1881
    // Ref to scrollable container (modal body) is needed to calculate proper position of dragged element
    // requestAnimationFrame is needed to wait until element inside of ref is rendered
    useEffect(() => {
      const frameId = requestAnimationFrame(() => {
        if (modalBodyRef.current) {
          setScrollContainerElement(modalBodyRef.current)
        }
      })

      return () => {
        cancelAnimationFrame(frameId)
        setScrollContainerElement(undefined)
      }
    }, [])

    return (
      <CreateOrEditRulesetModalContextProvider
        scrollContainerElement={scrollContainerElement}
        modalId={modalProps.id}
        rulesetId={rulesetId}
        disabled={disabled}
      >
        <Modal size="m" {...modalProps} closable autofocusOnInput>
          <Modal.Header
            title={
              isEditMode
                ? t('settings.organization.vat_rates.modals.create_or_edit_ruleset.edit.title')
                : t('settings.organization.vat_rates.modals.create_or_edit_ruleset.create.title')
            }
          />
          <Modal.Body ref={modalBodyRef}>
            <ModalContent>
              <CreateOrEditRulesetForm />
            </ModalContent>
          </Modal.Body>
          <Modal.Footer>
            <ModalFooter />
          </Modal.Footer>
        </Modal>
      </CreateOrEditRulesetModalContextProvider>
    )
  },
)
