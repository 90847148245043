import { useMutation } from 'react-query'

import { APIError } from '../../../utils'
import { deleteBillReconciliationMatch } from '../query-api'

interface UseDeleteBillReconciliationSuggestionProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useDeleteBillReconciliationSuggestion = (props?: UseDeleteBillReconciliationSuggestionProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate: deleteSuggestion,
    isLoading: isProcessing,
    ...rest
  } = useMutation(deleteBillReconciliationMatch, {
    onSuccess,
    onError,
  })

  return { deleteSuggestion, isProcessing, ...rest }
}
