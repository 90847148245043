import styled from '@emotion/styled'

import { Themable } from '../../types/themable'

export const Container = styled.div<Themable>`
  display: flex;
  border-top: ${({ theme }) => `1px solid ${theme.colors.lightGray1}`};
  padding: 17px 0;
`

export const TextContainer = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
`

export const SupportText = styled.p<Themable>`
  color: ${({ theme }) => theme.colors.primaryGray};
`

export const SupportLink = styled.a<Themable>`
  padding-left: 5px;
`
