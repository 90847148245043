import { BadgeVariant } from '@design-system'

import { InvoiceStatus } from '../../../enums/invoiceStatus'

export const invoiceStatusToBadgeVariant: Record<InvoiceStatus, BadgeVariant> = {
  [InvoiceStatus.All]: 'neutral',
  [InvoiceStatus.Overdue]: 'error',
  [InvoiceStatus.Paid]: 'success',
  [InvoiceStatus.Unpaid]: 'warning',
}
