import styled from '@emotion/styled'

import { BorderRadius } from '../../enums/borderRadius'
import { Spacing } from '../../enums/spacing'
import { SectionMessageVariant } from './types'
import { variantStyles } from './utils'

interface SectionMessageProps {
  variant: SectionMessageVariant
}

export const SectionMessage = styled.section<SectionMessageProps>`
  display: flex;
  background-color: ${({ variant }) => variantStyles[variant].bgColor};
  border-radius: ${BorderRadius.L};
  padding: ${Spacing.M} ${Spacing.L};
  color: ${({ variant }) => variantStyles[variant].color};
`

export const IconWrapper = styled.div`
  display: flex;
  margin-top: ${Spacing.S};
  margin-right: ${Spacing.S};
`

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: ${Spacing.XXS};
  margin-bottom: ${Spacing.XXS};
  flex-direction: column;
  flex-grow: 1;
`

export const Header = styled.header``

export const IconButtonWrapper = styled.div`
  margin-left: ${Spacing.S};
`

export const TextWrapper = styled.div``

export const SideActionsWrapper = styled.div`
  margin-top: ${Spacing.L};
`
