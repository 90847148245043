import { Button, DateInput, FormItem as FormItemDS, SkeletonButton } from '@design-system'

import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../hooks/useForm'
import { useBillForm } from '../../contexts/billFormContext'
import { BillFormSchema } from '../../utils/formData'

export const BillDueDate = (): ReactElement => {
  const { FormItem, control } = useFormContext<BillFormSchema>()
  const { isFetching } = useBillForm()
  const { t } = useTranslation()
  const billDate = useWatch({ control, name: 'billDate' })
  const dueDate = useWatch({ control, name: 'dueDate' })
  const isInitiallyOpenRef = useRef(false)

  const [showInputField, setShowInputField] = useState(!!dueDate)

  const handleDueButtonClick = useCallback(() => {
    isInitiallyOpenRef.current = true
    setShowInputField(true)
  }, [])

  useEffect(() => {
    if (!showInputField && dueDate) {
      setShowInputField(true)
    } else if (!dueDate && showInputField) {
      setShowInputField(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDate])

  return (
    <>
      {showInputField ? (
        <FormItem
          name="dueDate"
          label={t('bill.edit.form.due_date')}
          render={({ field }) => (
            <DateInput
              autoFocus={isInitiallyOpenRef.current}
              isOpen={isInitiallyOpenRef.current}
              minDate={billDate}
              {...field}
            />
          )}
        />
      ) : (
        <FormItemDS label="&nbsp;">
          {isFetching ? (
            <SkeletonButton fullWidth />
          ) : (
            <Button icon="plusCircle" variant="text" onClick={handleDueButtonClick}>
              {t('bill.edit.form.due')}
            </Button>
          )}
        </FormItemDS>
      )}
    </>
  )
}
