import { Color, SkeletonText, Space, Text } from '@design-system'

import React, { ReactElement, ReactNode, useMemo } from 'react'

import { DateFormatter } from '../../../TableCellFormatters'
import { Status } from '../../types/status'
import * as Styled from './styles'
import { statusToDotColor } from './utils/statusToDotColor'

export type TimelineStatusItemStatus = Status

export interface TimelineStatusItemProps {
  children?: ReactNode
  date?: Date | string
  description?: ReactNode
  isCollapsed?: boolean
  isCurrent?: boolean
  isLoading?: boolean
  previousItem?: boolean
  status?: Status
  title?: ReactNode
  withSeparator?: boolean
}

export const TimelineStatusItem = ({
  children,
  date,
  description,
  isCollapsed = false,
  isCurrent = false,
  isLoading = false,
  status = 'neutral',
  title,
  withSeparator = true,
}: TimelineStatusItemProps): ReactElement => {
  const statusColor = useMemo(() => {
    if (isLoading) {
      return Color.Gray40
    }

    if (!isCurrent) {
      return statusToDotColor.neutral
    }

    return statusToDotColor[status]
  }, [isCurrent, isLoading, status])

  return (
    <Styled.TimelineStatusItemWrapper
      isCollapsed={isCollapsed}
      statusColor={statusColor}
      withSeparator={isLoading ? false : withSeparator}
    >
      <Styled.Header>
        {isLoading ? (
          <SkeletonText variant="small" fullWidth />
        ) : (
          <>
            <Text variant="small">{title}</Text>
            {date && (
              <Styled.DateWrapper>
                <Text colorVariant="secondary" variant="micro" truncate>
                  <DateFormatter value={date} withHour />
                </Text>
              </Styled.DateWrapper>
            )}
          </>
        )}
      </Styled.Header>

      {(description || children || isLoading) && (
        <Styled.ContentWrapper>
          {isLoading ? (
            <>
              <Space size="xs" />
              <SkeletonText variant="micro" fullWidth />
              <Space size="xxs" />
              <SkeletonText variant="micro" width={150} />
              <Space size="xxs" />
              <SkeletonText variant="micro" fullWidth />
            </>
          ) : (
            description && (
              <Styled.DescriptionText
                colorVariant={isCurrent ? 'primary' : 'secondary'}
                truncate={isCollapsed}
                variant="small"
              >
                {description}
              </Styled.DescriptionText>
            )
          )}

          {children && !isCollapsed && !isLoading && <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>}
        </Styled.ContentWrapper>
      )}
    </Styled.TimelineStatusItemWrapper>
  )
}
