import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const FooterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const FooterContactItem = styled.div`
  display: flex;
  margin-left: ${Spacing.M};
`

export const Info = styled.div`
  display: flex;
`
