import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../enums/queryKeys'
import { useLocale } from '../../../../hooks/useLocale'
import { getApiCountryKey } from '../../../../utils/getApiCountryKey'
import { useUserOrganization } from '../../organization'
import { fetchCompanies } from '../query-api'

const MIN_SIGNS_TO_ENABLE = 3

export const useFetchCompanies = (query?: string) => {
  const { organization } = useUserOrganization()
  const { code: language } = useLocale()
  const apiCountryKey = getApiCountryKey(organization?.countryId, language)

  const { data, isLoading } = useQuery(
    [QueryKeys.Companies, query, apiCountryKey],
    () => fetchCompanies(query, apiCountryKey),
    {
      enabled: !!query && query.length >= MIN_SIGNS_TO_ENABLE && !!apiCountryKey,
      cacheTime: 0,
      keepPreviousData: true,
    },
  )

  return {
    companies: data?.companies || [],
    isLoading,
  }
}
