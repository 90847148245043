import { encodePeriodValue } from '@components'

import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { defaultStaleTime } from '../../../config/queryClient'
import { useGlobalFiscalYear } from '../../../contexts/globalFiscalYearContext'
import { QueryKeys } from '../../../enums/queryKeys'
import { getPeriodValueFromFiscalYear } from '../../../utils/getPeriodValueFromFiscalYear'
import { fetchDaybookConsequencesReport } from '../query-api'

export const useDaybookConsequences = (daybookId: string) => {
  const { organization } = useUserOrganization()
  const { fiscalYear: globalFiscalYear } = useGlobalFiscalYear()
  const [showAllAccounts, setShowAllAccounts] = useState(false)

  const queryprops = useMemo(() => {
    const organizationId = organization?.id as string
    const period = globalFiscalYear
      ? encodePeriodValue(getPeriodValueFromFiscalYear(globalFiscalYear), organizationId)
      : ''
    return {
      organizationId,
      daybookId,
      showAllAccounts: !showAllAccounts,
      period,
    }
  }, [showAllAccounts, organization?.id, daybookId, globalFiscalYear])

  const { data, ...rest } = useQuery(
    [QueryKeys.DaybookConsequences, queryprops],
    () => fetchDaybookConsequencesReport(queryprops),
    {
      enabled: !!organization?.id && !!daybookId,
      staleTime: defaultStaleTime,
    },
  )

  return { reportHtml: data?.draftConsequences, showAllAccounts, setShowAllAccounts, ...rest }
}
