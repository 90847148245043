import { FontWeight } from '@design-system'

import colors from './colors'

export default {
  actionGroup: {
    'a, button': {
      marginRight: 15,
      display: 'inline-block',
      fontSize: 14,
      lineHeight: '20px',
      color: colors.blueA,
      '&:hover': {
        color: colors.blue4,
      },
      '&.active, &.selected': {
        fontWeight: FontWeight.Medium,
      },
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
}
