import { TFunction } from 'i18next'
import { number, object, SchemaOf, string } from 'yup'

export const createOrEditAdditionalFieldFormDefaultValues: CreateOrEditAdditionalFieldForm = {
  name: '',
  description: '',
  priority: 1,
}
export interface CreateOrEditAdditionalFieldForm {
  name: string
  description: string | undefined
  priority: number
}

export type CreateOrEditAdditionalFieldFormSchema = SchemaOf<CreateOrEditAdditionalFieldForm>

export const getValidationSchema = (t: TFunction): CreateOrEditAdditionalFieldFormSchema => {
  return object({
    name: string().required(t('required_field')),
    description: string(),
    priority: number().required(t('required_field')),
  })
}
