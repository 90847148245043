import { AmountFormatter, DateFormatter, Translate } from '@components'
import { Badge, InternalAccessor, TableCellContent, TableColumn, TableHoverActions } from '@design-system'

import { TFunction } from 'i18next'

import { CopyBalanceHoverAction } from '../elements/CopyBalanceHoverAction'
import { AccountStatementsTableRow } from '../enums/accountStatementsTableRow'

export const getAccountStatementsTableColumns = (
  withVoided: boolean | undefined,
  accountCurrencyId: string | undefined,
  t: TFunction,
): TableColumn<AccountStatementsTableRow>[] => {
  const tableColumns: TableColumn<AccountStatementsTableRow>[] = [
    {
      accessor: 'entryDate',
      Cell: ({ value }) => (
        <TableCellContent>
          <DateFormatter value={value} />
        </TableCellContent>
      ),
      fixedSize: true,
      Header: <Translate value="account_statements.entry_date" />,
      size: 's',
      sortable: true,
      truncate: true,
    },
    {
      accessor: 'transactionNo',
      Header: '#',
      size: 'xxs',
      truncate: true,
    },
    {
      accessor: 'voucherNo',
      Header: <Translate value="account_statements.voucher_no" />,
      size: 'xxs',
      truncate: true,
    },
    {
      accessor: 'originatorName',
      Header: <Translate value="account_statements.origin" />,
      size: 'xxl',
      truncate: true,
    },
    {
      accessor: 'currencyId',
      Header: <Translate value="account_statements.currency" />,
      size: 'xxs',
    },
    {
      accessor: 'taxAbbreviation',
      alignment: 'right',
      Header: <Translate value="account_statements.tax_abbreviation" />,
      size: 's',
      fixedSize: true,
    },
    {
      accessor: 'tax',
      alignment: 'right',
      Cell: ({ value }) =>
        value ? (
          <TableCellContent>
            <AmountFormatter value={value} />
          </TableCellContent>
        ) : (
          '—'
        ),
      fixedSize: true,
      Header: <Translate value="account_statements.tax" />,
      size: 's',
    },
    {
      accessor: 'debit',
      alignment: 'right',
      Cell: ({ value }) => (
        <TableCellContent>
          <AmountFormatter value={value || 0} />
        </TableCellContent>
      ),
      fixedSize: true,
      Header: <Translate value="account_statements.debit" />,
      size: 's',
    },
    {
      accessor: 'credit',
      alignment: 'right',
      Cell: ({ value }) => (
        <TableCellContent>
          <AmountFormatter value={value || 0} />
        </TableCellContent>
      ),
      fixedSize: true,
      Header: <Translate value="account_statements.credit" />,
      size: 's',
    },
    {
      accessor: 'balance',
      alignment: 'right',
      Cell: ({ value }) => (
        <TableCellContent>
          <AmountFormatter value={value || 0} />
        </TableCellContent>
      ),
      fixedSize: true,
      Header: accountCurrencyId
        ? t('account_statements.balance_with_currency', {
            currencyId: accountCurrencyId,
          })
        : t('account_statements.balance'),
      size: 's',
    },
    {
      Header: '',
      accessor: InternalAccessor.HoverActions,
      Cell: ({ data, row }) => {
        const values: AccountStatementsTableRow = data[row.id]
        const balance = values.balance

        if (!balance) {
          return null
        }

        return (
          <TableHoverActions>
            <CopyBalanceHoverAction balance={balance} />
          </TableHoverActions>
        )
      },
    },
    {
      accessor: InternalAccessor.Readonly,
    },
  ]

  if (withVoided) {
    tableColumns.push({
      accessor: 'voided',
      alignment: 'right',
      Cell: ({ value: voided }) => voided && <Badge variant="neutral">{t('account_statements.voided')}</Badge>,
      Header: <Translate value="account_statements.status" />,
      fixedSize: true,
      size: 's',
    })
  }

  return tableColumns
}
