import { ModalConfirmation, ModalConfirmationProps, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteOrganizationIcon } from '../../../../hooks/useDeleteOrganizationIcon'

type DeleteIconModalProps = ModalConfirmationProps

export const DeleteIconModal = (modalProps: DeleteIconModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeDeleteIconModal } = useModal(modalProps.id)
  const { deleteOrganizationIcon, isProcessing } = useDeleteOrganizationIcon({
    onSuccess: () => {
      closeDeleteIconModal()
    },
  })

  const handleDeleteIconButtonClick = useCallback(() => {
    deleteOrganizationIcon()
  }, [deleteOrganizationIcon])

  return (
    <ModalConfirmation
      {...modalProps}
      cancelLabel={t('settings.organization.organization_info.icon.delete_modal.cancel_button')}
      danger
      message={t('settings.organization.organization_info.icon.delete_modal.body')}
      okLabel={t('settings.organization.organization_info.icon.delete_modal.delete_button')}
      okLoading={isProcessing}
      onOk={handleDeleteIconButtonClick}
      title={t('settings.organization.organization_info.icon.delete_modal.title')}
    />
  )
}
