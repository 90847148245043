import { RuleForm } from '../../../../../../types/ruleForm'

export const addPriority = (rules: RuleForm[] | undefined) => {
  if (!rules) {
    return undefined
  }

  return rules.map((rule, index) => ({
    ...rule,
    priority: index + 1,
  }))
}
