import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ReceiptsActionsWrapper = styled.section`
  width: 100%;
  margin-bottom: ${Spacing.L};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ReceiptsLeftActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`
