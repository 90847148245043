import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface GuideFormWrapperrProps {
  children: ReactNode
  onSubmit?: () => void
}

export const GuideFormWrapper = ({ children, onSubmit }: GuideFormWrapperrProps): ReactElement => (
  <Styled.GuideFormWrapper onSubmit={onSubmit}>{children}</Styled.GuideFormWrapper>
)
