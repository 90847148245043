import React, { ChangeEvent, ReactElement, ReactNode, useCallback } from 'react'
import { Row } from 'react-table'

import { useSelectRows } from '../../contexts/selectRowsContext'
import { TableData } from '../../types/data'
import { SelectType } from '../../types/selectType'
import { TableCheckbox } from '../TableCheckbox'
import * as Styled from './styles'

interface TableHeaderProps<T extends TableData> {
  bordered?: boolean
  children: ReactNode
  expandable?: boolean
  isSelectable?: boolean
  isSticky?: boolean
  noContent?: boolean
  rows: Row<T>[]
  rowSelectType: SelectType
}

export const TableHeader = <T extends TableData>({
  bordered,
  children,
  isSelectable,
  isSticky,
  rows,
  rowSelectType,
  ...rest
}: TableHeaderProps<T>): ReactElement => {
  const { areAllRowsSelected, selectedRows, selectAllRows } = useSelectRows<T>()

  const isSelectVisible = isSelectable && rowSelectType === 'checkbox'
  const isSelectIndeterminate = !areAllRowsSelected && selectedRows.length !== 0

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      selectAllRows(rows, event.target.checked)
    },
    [selectAllRows, rows],
  )

  return (
    <Styled.TableHeaderWrapper sticky={isSticky}>
      <Styled.TableHeader {...rest} selectable={isSelectable} bordered={bordered}>
        {isSelectVisible && (
          <TableCheckbox
            checked={areAllRowsSelected}
            indeterminate={isSelectIndeterminate}
            name="header-checkbox"
            onChange={handleCheckboxChange}
          />
        )}
        {children}
      </Styled.TableHeader>
    </Styled.TableHeaderWrapper>
  )
}
