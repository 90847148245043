import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ReceiptsSearchWrapper = styled.div`
  max-width: 400px;
  margin-left: ${Spacing.M};

  &:first-of-type {
    margin-left: 0;
  }
`
