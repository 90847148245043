import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { EmberRoute } from '../../../enums/emberRoute'
import { SearchResultItem } from '../types/searchResultItem'
import { getIconName } from './getIconName'

export const getSearchItemsFromFastMenu = (fastMenuItems: NavItem<EmberRoute>[], t: TFunction): SearchResultItem[] => {
  const items: SearchResultItem[] = []

  for (const fastMenuItem of fastMenuItems) {
    const { id, children, value } = fastMenuItem

    items.push({
      id,
      text: `${t('global_search.new')}: ${children}`,
      icon: getIconName(fastMenuItem),
      routeTo: value,
      category: 'menu',
    })
  }

  return items
}
