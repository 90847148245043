import { SubscriptionInvoice } from '../types/subscriptionInvoice'
import { getRequest } from '../utils'

interface FetchUmbrellaInvoicesResponse {
  data: SubscriptionInvoice[]
}

export const fetchUmbrellaInvoices = (umbrellaId: string): Promise<FetchUmbrellaInvoicesResponse> => {
  return getRequest(`/umbrellas/${umbrellaId}/invoices`)
}

interface FetchSubscriptionInvoicePdfResponse {
  pdf: string
}

interface FetchUmbrellaInvoicePdfResponse {
  data: FetchSubscriptionInvoicePdfResponse
}

export const fetchUmbrellaInvoicePdf = async (umbrellaId: string, invoiceId: string): Promise<string> => {
  const response: FetchUmbrellaInvoicePdfResponse = await getRequest(`/umbrellas/${umbrellaId}/invoices/${invoiceId}`)

  return response.data.pdf
}

export const fetchSubscriptionInvoices = (organizationId: string): Promise<SubscriptionInvoice[]> => {
  return getRequest(`/integrations/billing/${organizationId}/invoices`)
}

export const fetchSubscriptionInvoicePdf = async (organizationId: string, invoiceId: string): Promise<string> => {
  const response: FetchSubscriptionInvoicePdfResponse = await getRequest(
    `/integrations/billing/${organizationId}/invoices/${invoiceId} `,
  )
  return response.pdf
}
