import { amountToDisplayValue, Color, Icon, Tooltip } from '@design-system'

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { InfoItem } from './infoItem'
import * as Styled from './styles'

export interface ReconciliationInfoProps {
  accountBalanceAmount?: number
  accountCurrencyId?: string
  bankBalanceAmount?: number
  isBankReconciled?: boolean
}

export const ReconciliationInfo = ({
  accountBalanceAmount,
  accountCurrencyId,
  bankBalanceAmount,
  isBankReconciled,
}: ReconciliationInfoProps) => {
  const { t } = useTranslation()

  const bankBalance =
    typeof bankBalanceAmount === 'number'
      ? `${amountToDisplayValue(bankBalanceAmount)} ${accountCurrencyId}`
      : t('unknown')
  const accountBalance =
    typeof accountBalanceAmount === 'number'
      ? `${amountToDisplayValue(accountBalanceAmount)} ${accountCurrencyId}`
      : t('unknown')
  const reconciliationDifference =
    typeof bankBalanceAmount === 'number' && typeof accountBalanceAmount === 'number'
      ? `${amountToDisplayValue(Math.abs(bankBalanceAmount - accountBalanceAmount))} ${accountCurrencyId}`
      : t('unknown')

  return (
    <Styled.ReconciliationInfoWrapper>
      <Styled.AccountInfoContainer>
        <InfoItem
          amountColor={Color.DeepSeaGreen}
          amount={bankBalance}
          label={t('dashboard.widget.bank-integration.bank_balance')}
        />
        <InfoItem
          amountColor={isBankReconciled ? Color.Green : Color.Red}
          amountTextVariant="h2"
          amount={reconciliationDifference}
          label={t('dashboard.widget.bank-integration.reconciliation_difference')}
          labelIcon={
            <Tooltip label={t('dashboard.widget.bank-integration.tooltip')}>
              <Icon icon="questionCircle" />
            </Tooltip>
          }
        />
        <InfoItem
          amountColor={Color.Gray90}
          amount={accountBalance}
          label={t('dashboard.widget.bank-integration.account_balance')}
        />
      </Styled.AccountInfoContainer>
    </Styled.ReconciliationInfoWrapper>
  )
}
