import { ContactNameAsync, DateFormatter, DueDateFormatter, Translate } from '@components'
import { ItemsPerPage, Table, TableColumn } from '@design-system'

import Cookies from 'js-cookie'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Row } from 'react-table'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { CookieKeys } from '../../../../../../enums/cookieKeys'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { SortDirection } from '../../../../../../enums/sortDirection'
import { TableIds } from '../../../../../../enums/tableIds'
import { QuotesSortProperty } from '../../../../query-api'
import { QuotesTableAccessor } from '../../enums/quotesTableAccessor'
import { useQuotes } from '../../hooks/useQuotes'
import { useQuotesListFilters } from '../../hooks/useQuotesListFilters'
import { useQuotesTableData } from '../../hooks/useQuotesTableData'
import { QuotesTableRow } from '../../types/quotesTableRow'
import { getQuotesType } from '../../utils/getQuotesType'
import { EmailStatusIcon } from '../EmailStatusIcon'
import { QuoteState } from '../QuoteState'

export const QuotesTable = (): ReactElement => {
  const { t } = useTranslation()
  const { quotes, isLoading, pagination } = useQuotes()
  const quotesData = useQuotesTableData(quotes)
  const [{ pageSize, page }, setQueryParams] = useQuotesListFilters()
  const { organization } = useUserOrganization()
  const { navigate } = useEmberRouter()
  const location = useLocation()

  const columns: TableColumn<QuotesTableRow>[] = useMemo(
    () => [
      {
        accessor: QuotesTableAccessor.State,
        alignment: 'center',
        Cell: ({ value: state }) => <QuoteState state={state} />,
        size: 'xxxxs',
        sortable: false,
        truncate: true,
      },
      {
        accessor: QuotesTableAccessor.IsSent,
        alignment: 'center',
        Cell: ({ value: isSent }) => <EmailStatusIcon isSent={isSent} />,
        size: 'xxxxs',
        sortable: false,
        truncate: true,
      },
      {
        accessor: QuotesTableAccessor.Type,
        Header: <Translate value="quotes.table.type" />,
        Cell: ({ value: type }) => getQuotesType(type, t),
        size: 'xxs',
        sortable: true,
        truncate: true,
      },
      {
        accessor: QuotesTableAccessor.OrderNo,
        Header: <Translate value="quotes.table.order_no" />,
        hidden: true,
        size: 'xs',
        sortable: true,
        truncate: true,
      },
      {
        accessor: QuotesTableAccessor.EntryDate,
        Cell: ({ value: date }) => date && <DateFormatter value={date} />,
        fixedSize: true,
        Header: <Translate value="quotes.table.quote_date" />,
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        accessor: QuotesTableAccessor.DueDate,
        Cell: ({ value: date }) => date && <DueDateFormatter value={date} />,
        fixedSize: true,
        Header: <Translate value="quotes.table.expiration_date" />,
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        accessor: QuotesTableAccessor.ContactId,
        Cell: ({ value: contactId }) => contactId && <ContactNameAsync contactId={contactId} />,
        Header: <Translate value="quotes.table.customer" />,
        size: 'm',
        truncate: true,
      },
      {
        accessor: QuotesTableAccessor.Description,
        Header: <Translate value="quotes.table.description" />,
        size: 'm',
        sortable: true,
        truncate: true,
      },
      {
        accessor: QuotesTableAccessor.NetAmount,
        alignment: 'right',
        Header: (
          <Translate value={organization?.hasVat ? 'quotes.table.excl_vat_short' : 'quotes.table.net_amount_header'} />
        ),
        hidden: organization?.hasVat,
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        accessor: QuotesTableAccessor.GrossAmount,
        alignment: 'right',
        Header: (
          <Translate
            value={organization?.hasVat ? 'quotes.table.incl_vat_short' : 'quotes.table.gross_amount_header'}
          />
        ),
        hidden: !organization?.hasVat,
        size: 's',
        sortable: true,
        truncate: true,
      },
    ],
    [organization, t],
  )

  const handleRowClick = useCallback(
    (row: Row<QuotesTableRow>) => {
      const { _urlInternal } = row.values

      if (!_urlInternal) {
        const { id: quoteId } = row.original
        Cookies.set(CookieKeys.QuotesListQuery, location.search)
        navigate(EmberRoute.Quote, quoteId)
      }
    },
    [location.search, navigate],
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setQueryParams({ page })
    },
    [setQueryParams],
  )

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: ItemsPerPage) => {
      setQueryParams({ page: 1, pageSize: itemsPerPage })
    },
    [setQueryParams],
  )

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      setQueryParams({
        page: 1,
        sortProperty: columnId as QuotesSortProperty,
        sortDirection: isDesc ? SortDirection.Desc : SortDirection.Asc,
      })
    },
    [setQueryParams],
  )

  return (
    <Table
      columns={columns}
      data={quotesData}
      id={TableIds.QuotesTable}
      isLoading={isLoading}
      itemsPerPage={pageSize}
      onItemsPerPageChange={handleItemsPerPageChange}
      onPageChange={handlePageChange}
      onRowClick={handleRowClick}
      onSort={handleSort}
      currentPage={Number(page)}
      totalPageItems={pagination?.total}
      withColumnsFiltering
      withItemsPerPageSelect
      withStickyHeader
    />
  )
}
