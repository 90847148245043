import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import qs from 'qs'

const mergeQueryParams = (url: string, searchParams?: string) => {
  const searchParamsCurrent = url.split('?')[1] || ''
  const separator = searchParamsCurrent && searchParams ? '&' : ''
  return searchParamsCurrent || searchParams ? `?${searchParamsCurrent}${separator}${searchParams}` : ''
}

const getValidSearchParams = (searchParams: qs.ParsedQs, props?: UseGetQueryFromEmberRouteProps): qs.ParsedQs => {
  const { queryParamsValidKeys, queryParamsParser } = props || {}
  const searchParamsValid = queryParamsValidKeys ? pick(searchParams, queryParamsValidKeys) : searchParams
  const searchParamsToKeep =
    typeof queryParamsParser === 'function' ? queryParamsParser(searchParamsValid) : searchParamsValid
  return searchParamsToKeep
}

interface UseGetQueryFromEmberRouteProps {
  queryParamsValidKeys?: string[]
  queryParamsParser?: (searchParsed: qs.ParsedQs) => qs.ParsedQs
}

export const useGetQueryFromEmberRoute = (props?: UseGetQueryFromEmberRouteProps) => {
  const { queryParamsValidKeys, queryParamsParser } = props || {}
  const href = window.location.href
  const search = window.location.search

  if (search) {
    const searchParsed = qs.parse(search, { ignoreQueryPrefix: true, allowDots: true })
    let searchParams: string | undefined

    if (queryParamsValidKeys || queryParamsParser) {
      // Extract valid queryParams based on list with valid keys (queryParamsValidKeys) or/and some parser function (queryParamsParser)
      searchParams = qs.stringify(
        getValidSearchParams(searchParsed, {
          queryParamsParser,
          queryParamsValidKeys,
        }),
      )
    }

    if (href && (!isEmpty(searchParsed) || searchParsed.initialRoute)) {
      let url = href.split(search).join('')
      const searchParamsMerged = mergeQueryParams(url, searchParams)

      // add queryParams to the url as well
      if (searchParamsMerged) {
        url = url.concat(searchParamsMerged)

        if (typeof searchParsed.initialRoute === 'string') {
          // decorate initialRoute with searchParams so it's kept after redirection
          searchParsed.initialRoute = searchParsed.initialRoute.concat(searchParamsMerged)
        }
      }

      // we need to remove queries, like the 'initialRoute' search query so it won't reinitialize this module again
      // so for example /bills?initialRoute=/new#/ should become /bills#/
      window.history.replaceState(null, '', url)
      return searchParsed
    }

    return undefined
  }
}
