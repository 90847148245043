import { PaymentPane, sortByDateKey } from '@design-system'

import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BillStatus } from '../../enums/billStatus'
import { useBillView } from '../BillReadableForm'
import { BillPaymentPaneItem } from './elements/BillPaymentPaneItem'
import * as Styled from './styles'
import { getBillPaymentPaneData } from './utils/getBillPaymentPaneData'

export const BillPaymentPane = (): ReactElement | null => {
  const { t } = useTranslation()
  const { billData, isFetching, isFetchingInvalidation, billStatus } = useBillView()

  const isLoading = isFetching || isFetchingInvalidation
  const billDataSorted = useMemo(() => {
    return billData?.bill
      ? {
          ...billData.bill,
          balanceModifiers: sortByDateKey(billData.bill.balanceModifiers || [], 'entryDate'),
        }
      : undefined
  }, [billData?.bill])

  const billPaymentPaneData = useMemo(() => {
    return getBillPaymentPaneData(t, billDataSorted, isLoading)
  }, [t, billDataSorted, isLoading])

  if (!isFetching && billStatus === BillStatus.Voided) {
    return null
  }

  return (
    <Styled.BillPaymentPaneWrapper>
      <PaymentPane {...billPaymentPaneData}>
        {billDataSorted?.balanceModifiers.map(({ id, ...rest }) => (
          <BillPaymentPaneItem key={id} id={id} billType={billDataSorted?.type} {...rest} />
        ))}
      </PaymentPane>
    </Styled.BillPaymentPaneWrapper>
  )
}
