import { ModalProps } from '@design-system'

import { ReactElement } from 'react'

import { TransferOwnershipModal } from '../../../TransferOwnershipModal'
import { useTransferOrganizationOwnershipModal } from '../../contexts/transferOrganizationOwnershipContext'

type OrganizationOwnershipModalContentProps = ModalProps

export const OrganizationOwnershipModalContent = (props: OrganizationOwnershipModalContentProps): ReactElement => {
  const { isLoading, owners, selectedOwner, selectOwnership, transferOwnership, umbrellaOrganizationName } =
    useTransferOrganizationOwnershipModal()

  return (
    <TransferOwnershipModal
      {...props}
      isLoading={isLoading}
      isUmbrellaTransfer={false}
      onSubmit={transferOwnership}
      tableData={owners}
      selectedValue={selectedOwner}
      onRowSelect={selectOwnership}
      organizationName={umbrellaOrganizationName}
    />
  )
}
