import { InternalAccessor } from '@design-system'

import { useMemo } from 'react'

import { OrganizationOwnersTableRow } from '../elements/OrganizationInfo/types/organizationOwnersTableRow'
import { getOrganizationOwnerDisplayedAddress } from '../elements/OrganizationInfo/utils/getOrganizationOwnerDisplayedAddress'
import { City } from '../types/city'
import { OrganizationOwner } from '../types/organizationOwner'
import { Zipcode } from '../types/zipcode'

interface UseOrganizationOwnersTableDataOptions {
  cities: City[]
  organizationOwners: OrganizationOwner[]
  zipcodes: Zipcode[]
}

export const useOrganizationOwnersTableData = (
  organizationOwnersData?: UseOrganizationOwnersTableDataOptions,
): OrganizationOwnersTableRow[] => {
  return useMemo(() => {
    const cities = organizationOwnersData?.cities || []
    const organizationOwners = organizationOwnersData?.organizationOwners || []
    const zipcodes = organizationOwnersData?.zipcodes || []

    return organizationOwners.map(
      ({ cityId, cityText, id, name, nominalOwnershipPercent, street, zipcodeId, zipcodeText }) => {
        return {
          address: getOrganizationOwnerDisplayedAddress({
            cities,
            cityId,
            cityText,
            street,
            zipcodeId,
            zipcodes,
            zipcodeText,
          }),
          id,
          name,
          ...(nominalOwnershipPercent ? { nominalOwnershipPercent: `${nominalOwnershipPercent}%` } : {}),
          [InternalAccessor.HoverActions]: true,
          [InternalAccessor.StaticActions]: true,
        }
      },
    )
  }, [organizationOwnersData?.cities, organizationOwnersData?.organizationOwners, organizationOwnersData?.zipcodes])
}
