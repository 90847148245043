import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Box, BoxProps } from 'rebass'

import { reactClass } from '../../utils'
import { LazyScrollingItem } from './types'

type Render = {
  onItemSelect?: (id: string) => void
  index?: number
  selectedId?: string
}

export type LazyScrollingProps<T> = BoxProps & {
  items?: T[]
  onItemSelect?: (id: string) => void
  render?: (props: T & Render) => ReactNode
  request?: () => void
  selectedId?: string
  withOverflowScroll?: boolean
  withFooter?: boolean
}

const Trigger = React.forwardRef(({ ...rest }, ref) => <Box ref={ref} {...rest} />)

export const LazyScrolling = <T extends LazyScrollingItem>({
  onItemSelect = () => null,
  items = [] as T[],
  request = () => null,
  render = () => null,
  selectedId,
  withOverflowScroll,
  withFooter,
  ...rest
}: LazyScrollingProps<T>) => {
  const [ref, inView] = useInView()
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [total, setTotal] = useState<number>()

  const renderItems = useCallback(
    (props: T, index: number) => {
      return render({ index, onItemSelect, selectedId, withFooter, ...props })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onItemSelect, render, selectedId],
  )

  // Fetch
  useEffect(() => {
    if (!inView || !request) {
      return
    }
    if (loading || done) {
      return
    }
    request()
    setLoading(true)
  }, [done, inView, loading, request])

  // Receive
  useEffect(() => {
    setLoading(false)
    setDone((items.length || 0) === total)
    setTotal(items.length)
  }, [items.length, total])

  return (
    <Box
      as="ul"
      className={reactClass('lazy-scrolling')}
      overflowX={'hidden'}
      overflowY={withOverflowScroll ? 'scroll' : 'initial'}
      {...rest}
    >
      {items.map(renderItems)}
      {!loading && <Trigger ref={ref} />}
    </Box>
  )
}
