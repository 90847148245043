import { Button, Space, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { useSegment } from '../../../../../../hooks'
import { USERFLOW_SETTINGS_GUIDE_ID } from '../../../../constants/userflowIds'
import * as Styled from '../../styles'

export const PreChecklistContent = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { track } = useSegment()

  const startUserflow = useCallback(() => {
    track('xxx - hamster - tile experiment button clicked', {
      button: 'complete profile now',
    })
    navigate(EmberRoute.SettingsApp, { queryParams: { userflow: USERFLOW_SETTINGS_GUIDE_ID } })
  }, [navigate, track])

  return (
    <Styled.WidgetContentWrapper>
      <Styled.Header>
        <Space size="s" />
        <Text variant="displayM">{t('dashboard.widget.get_started.title')}</Text>
        <Space size="l" />
        <Styled.DescriptionWrapper>
          <Text variant="large">{t('dashboard.widget.get_started.description_1')}</Text>
        </Styled.DescriptionWrapper>
      </Styled.Header>
      <Styled.Footer>
        <Button onClick={startUserflow} variant="secondary">
          {t('dashboard.widget.get_started.cta')}
        </Button>
      </Styled.Footer>
      <Styled.Illustration />
    </Styled.WidgetContentWrapper>
  )
}
