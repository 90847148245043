import { TFunction } from 'i18next'
import { array, boolean, object, SchemaOf, string } from 'yup'

import { UmbrellaRole } from '@modules-deprecated/app/umbrellas/types/umbrellaRole'

interface Organization {
  organizationId: string
}

export interface CreateOrEditRoleForm {
  name: string
  description: string | undefined
  grantAdmin: boolean
  grantAll: boolean
  organizations: Organization[] | undefined
}

export type CreateOrEditRoleFormSchema = SchemaOf<CreateOrEditRoleForm>

export const getValidationSchema = (t: TFunction): CreateOrEditRoleFormSchema =>
  object({
    name: string().required(t('umbrella_users.inviteUserModal.error.empty_email')),
    description: string(),
    grantAdmin: boolean().required(),
    grantAll: boolean().required(),
    organizations: array()
      .of(object({ organizationId: string() }))
      .notRequired(),
  })

export const getDefaultValues = (umbrellaRole?: UmbrellaRole): CreateOrEditRoleForm => ({
  name: umbrellaRole?.name || '',
  description: umbrellaRole?.description || '',
  grantAdmin: umbrellaRole?.grantAdmin || false,
  grantAll: umbrellaRole?.grantAll === false ? umbrellaRole.grantAll : true,
  organizations: umbrellaRole?.organizations.length ? umbrellaRole?.organizations : [{ organizationId: '' }],
})
