import produce from 'immer'

import { CURRENCIES_RECEIVED } from './actions'
import { Currency } from './types'

export type CurrenciesState = {
  currencies: Currency[]
}

const initialState: CurrenciesState = {
  currencies: [],
}

const currenciesReducer = (state: CurrenciesState = initialState, { type, payload }: any): CurrenciesState => {
  switch (type) {
    case CURRENCIES_RECEIVED:
      return produce(state, (draftState) => {
        draftState.currencies = payload
      })
    default:
      return state
  }
}

export default currenciesReducer
