import { Link, Space, Text } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSyncErrorTopBar } from '../../../../contexts/syncErrorTopBarContext'
import * as Styled from './styles'

export const SyncErrorTopBarSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { openSkipAndCompleteSyncModal } = useSyncErrorTopBar()

  const handleSkipAndCompleteButtonClick = useCallback(() => {
    openSkipAndCompleteSyncModal()
  }, [openSkipAndCompleteSyncModal])

  return (
    <Styled.SideActionsWrapper alignItems="center">
      <Text weight="bold">
        <Link onClick={handleSkipAndCompleteButtonClick} underlined={false}>
          {t('external_invoices_sync_top_bar.error.skip_and_complete')}
        </Link>
      </Text>
      <Space />
    </Styled.SideActionsWrapper>
  )
}
