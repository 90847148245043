import { Locale } from '@design-system'

import { TFunction } from 'i18next'
import { boolean, object, SchemaOf, string } from 'yup'

import { User } from '@modules-deprecated/app/user'

export interface UserProfileForm {
  canCreateDaybooks: boolean
  name: string
  email: string
  phone: string | undefined
}

export type UserProfileFormSchema = SchemaOf<UserProfileForm>

const isDenmarkBasedOrganization = (locale: Locale) => locale === 'da_DK'

export const getValidationSchema = (t: TFunction): UserProfileFormSchema =>
  object({
    canCreateDaybooks: boolean().required(),
    name: string().required(t('settings.user.field.name.required')),
    email: string()
      .email(t('settings.user.field.email.wrong_format'))
      .required(t('settings.user.field.email.required')),
    phone: string().when('locale', {
      is: isDenmarkBasedOrganization,
      then: (schema) => schema.min(8, t('settings.user.field.phone.wrong_format')),
      otherwise: (schema) => schema.optional(),
    }),
  })

export const getDefaultValues = (user?: User): UserProfileForm => ({
  canCreateDaybooks: user?.canCreateDaybooks || false,
  name: user?.name || '',
  email: user?.email || '',
  phone: user?.phone || '',
})
