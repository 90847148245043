import { useEffect, useState } from 'react'

/**
 * @deprecated THIS HOOK IS DEPRECATED.  Please use "useWindowSize" from "react-use" package
 */
export function useWindowSize() {
  const isWindowClient = typeof window === 'object'

  const [windowSize, setWindowSize] = useState(isWindowClient ? window.innerWidth : undefined)

  useEffect(() => {
    function setSize() {
      setWindowSize(window.innerWidth)
    }

    if (isWindowClient) {
      window.addEventListener('resize', setSize)
      return () => window.removeEventListener('resize', setSize)
    }
  }, [isWindowClient, setWindowSize])

  return windowSize
}
