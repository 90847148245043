import { Button, ModuleLayout, notify, SkeletonBox } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { SalaryConnect } from './elements/SalaryConnect'
import { SalaryPayrollList } from './elements/SalaryPayrollList'
import { SalarySignup } from './elements/SalarySignup'
import { useRequestSalaryLoginUrl } from './hooks/useRequestSalaryLoginUrl'
import { useSalaryConnectionStatus } from './hooks/useSalaryConnectionStatus'

export const SalaryIntegration = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()

  const { isConnected, canConnect, isLoading: isSalaryConnectionLoading } = useSalaryConnectionStatus()

  const handleGetLoginError = useCallback(() => {
    notify({
      id: 'get-salary-login',
      message: 'Could not redirect to the salary page. Please try again later.',
      variant: 'error',
    })
  }, [])

  const { requestLoginUrl, isLoading: isRequestingLoginUrl } = useRequestSalaryLoginUrl({
    onSuccess: (data) => {
      if (data.login) {
        // @todo check for browser support
        window.open(data.login, '_blank')?.focus()
      } else {
        handleGetLoginError()
      }
    },
    onError: handleGetLoginError,
  })

  const isLoading = !organization || !user || isSalaryConnectionLoading
  const title =
    isConnected || canConnect ? t('salary.integration.title_connected') : t('salary.integration.title_disconnected')

  const handleButtonClick = useCallback(() => {
    requestLoginUrl()
  }, [requestLoginUrl])

  const renderContent = useCallback(() => {
    if (isConnected) {
      return <SalaryPayrollList />
    }

    if (canConnect) {
      return <SalaryConnect />
    }

    return <SalarySignup />
  }, [canConnect, isConnected])

  return (
    <ModuleLayout
      title={title}
      sideActions={
        isConnected && (
          <Button icon="arrowOutOfSquare" loading={isRequestingLoginUrl} onClick={handleButtonClick}>
            {t('salary.integration.open_salary')}
          </Button>
        )
      }
    >
      {isLoading && <SkeletonBox height={492} fullWidth />}
      {!isLoading && renderContent()}
    </ModuleLayout>
  )
}
