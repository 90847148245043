import { amountToDisplayValue, Flex, Text } from '@design-system'

import { ReactElement } from 'react'

import { BankLine } from '@modules-deprecated/bankReconciliation/types'
import { prefixAmount } from '@modules-deprecated/bankReconciliation/utils'

import { useBillReconciliation } from '../../../../../../../../contexts/billReconciliationContext'
import * as Styled from './styles'

interface BankLinesListItemContentProps {
  bankLine: BankLine
}

export const BankLinesListItemContent = ({ bankLine }: BankLinesListItemContentProps): ReactElement => {
  const { description, entryDate, amount, side } = bankLine
  const { currencyId } = useBillReconciliation()

  const bankLineAmount = prefixAmount({ side, amount })
  const isNegativeAmount = bankLineAmount < 0
  const shouldShowBankLineAmount = !!bankLineAmount && !!currencyId

  return (
    <Styled.BankLinesListItemContentWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column">
          <Text weight="bold">{description}</Text>
          <Text variant="micro" colorVariant="secondary">
            {entryDate}
          </Text>
        </Flex>
        {shouldShowBankLineAmount && (
          <Text colorVariant={isNegativeAmount ? 'danger' : 'success'} weight="bold">
            {amountToDisplayValue(bankLineAmount, false, currencyId)}
          </Text>
        )}
      </Flex>
    </Styled.BankLinesListItemContentWrapper>
  )
}
