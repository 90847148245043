import { useModal } from '@design-system'

import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as MobilePay } from '../../../assets/images/integrations/mobile-pay.svg'
import { ModalId } from '../../../enums/modalId'
import { IntegrationStatusLabel } from '../elements/IntegrationCard/elements/IntegrationStatusLabel'
import { IntegrationId } from '../enums/integrationId'
import { IntegrationStatus } from '../enums/integrationStatus'
import { IntegrationItem } from '../types/integrationItem'
import { usePaymentMethodsStatuses } from './usePaymentMethodsStatuses'

export const useMobilePayIntegration = (): IntegrationItem => {
  const { t } = useTranslation()
  const { open: openEnableMobilePayModal } = useModal(ModalId.EnableMobilePayModal)
  const { open: openDisableMobilePayModal } = useModal(ModalId.DisableMobilePayModal)
  const { mobilepayStatus, isLoading: isStatusLoading } = usePaymentMethodsStatuses()

  const buttonText = useMemo(() => {
    switch (mobilepayStatus) {
      case IntegrationStatus.Inactive:
        return t('integrations.card.mobile_pay.button.activate')

      case IntegrationStatus.Active:
        return t('integrations.card.mobile_pay.button.deactivate')
    }
  }, [mobilepayStatus, t])

  const buttonHandler = useCallback(() => {
    if (isStatusLoading) {
      return null
    }

    switch (mobilepayStatus) {
      case IntegrationStatus.Inactive:
        openEnableMobilePayModal()
        break

      case IntegrationStatus.Active:
        openDisableMobilePayModal()
        break
    }
  }, [isStatusLoading, mobilepayStatus, openDisableMobilePayModal, openEnableMobilePayModal])

  return {
    id: IntegrationId.Mobilepay,
    name: t('integrations.card.mobile_pay.title'),
    description: t('integrations.card.mobile_pay.body'),
    readMoreLink: 'https://www.billy.dk/apps/mobilepay/',
    imageNode: <MobilePay />,
    isHiddenLoading: false,
    isHidden: false,
    isPremium: true,
    buttonHandler,
    buttonText,
    statusLabelNode: <IntegrationStatusLabel status={mobilepayStatus} isStatusLoading={isStatusLoading} />,
    isStatusLoading,
  }
}
