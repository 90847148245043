import React, {
  Children,
  cloneElement,
  isValidElement,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHoverDirty } from 'react-use'

import { Color } from '../../../../enums/color'
import { useScrollToElement } from '../../../../hooks/useScrollToElement'
import { useTheme } from '../../../../hooks/useTheme'
import { LinkProps } from '../../../../types/linkProps'
import { getLinkProps } from '../../../../utils/getLinkProps'
import { useNavItemSubmit } from '../../hooks/useNavItemSubmit'
import { getItemState } from '../../utils/getItemState'
import { getStateToStyles } from '../../utils/getStateToStyles'
import { NavListItemProps } from '../NavListItem'
import { NavListItemText } from '../NavListItemText'
import * as Styled from './styles'

type NavListSubItemProps<T> = NavListItemProps<T>

type SubItemChildren<T> = Pick<NavListSubItemProps<T>, 'selectedId' | 'onClick' | 'focusedId' | 'item'> & {
  textColor?: Color
}

export const NavListSubItem = <T,>({
  focusedId,
  href,
  item,
  onClick,
  rel,
  selectedId,
  target,
  to,
}: NavListSubItemProps<T>): ReactElement => {
  const theme = useTheme()
  const listItemRef = useRef<HTMLDivElement>(null)
  const [listItemElement, setListItemElement] = useState<HTMLDivElement | null>(null)
  const isHovered = useHoverDirty(listItemRef)

  const { id, children, value, title, readonly } = item
  const isSelected = selectedId === id
  const isFocused = focusedId === id

  const itemState = useMemo(
    () => getItemState({ selected: isSelected, focused: isFocused, hovered: isHovered }),
    [isSelected, isFocused, isHovered],
  )
  const { textSubitemColor, backgroundColor } = getStateToStyles(theme)[itemState]

  useEffect(() => {
    setListItemElement(listItemRef.current)
  }, [listItemRef])

  const handleItemClick = useCallback(
    (event?: MouseEvent) => {
      if (!readonly) {
        onClick?.(id, value, event)
      }
    },
    [readonly, onClick, id, value],
  )

  const handleKeyDown = useCallback(() => {
    if (isFocused && !readonly) {
      listItemRef.current?.click()
    }
  }, [isFocused, readonly])

  useScrollToElement(listItemElement, isFocused || isSelected)
  useNavItemSubmit(handleKeyDown, isFocused, [id, value, isFocused, readonly, handleKeyDown])

  const linkProps: LinkProps | undefined = useMemo(
    () =>
      getLinkProps({
        href,
        rel,
        target,
        to,
      }),
    [href, rel, target, to],
  )

  return (
    <Styled.SubItemWrapper>
      <Styled.SubItem
        {...linkProps}
        ref={listItemRef}
        onClick={handleItemClick}
        backgroundColor={backgroundColor}
        tabIndex={0}
      >
        {typeof children === 'string' ? (
          <NavListItemText color={textSubitemColor} title={title || children}>
            {children}
          </NavListItemText>
        ) : (
          Children.map(children, (child) => {
            if (isValidElement<SubItemChildren<T>>(child)) {
              return cloneElement(child, {
                selectedId,
                onClick,
                focusedId,
                item,
                textColor: textSubitemColor,
              })
            }

            return child
          })
        )}
      </Styled.SubItem>
    </Styled.SubItemWrapper>
  )
}
