/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Switch /> from design-system instead
 */
import { Color, getDefaultTransition } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React, { useCallback } from 'react'
import { Control } from 'react-hook-form'

import { Input } from '../Form/Input'

type FlipSwitchType = {
  checked?: boolean
  disabled?: boolean
  formControl?: Control<any>
  name?: string
  onClick?: () => void
}

const FlipSwitchSlider = styled.div<FlipSwitchType>`
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  width: 40px;
  height: 24px;
  border-radius: 12px;
  position: relative;
  background-color: ${({ checked }) => (checked ? Color.Green : Color.Gray50)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${getDefaultTransition('background-color')};

  ${({ disabled, checked }) =>
    !disabled &&
    css`
      &:hover {
        background-color: ${checked ? Color.DeepSeaGreen : Color.Gray60};
      }
      &:active {
        background-color: ${checked ? Color.Green110 : Color.Gray60};
      }
    `};
`

const FlipSwitchHandle = styled.div<FlipSwitchType>`
  position: absolute;
  height: 20px;
  width: 20px;
  top: 2px;
  left: ${({ checked }) => (checked ? '18px' : '2px')};
  border-radius: 20px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background-color: ${Color.White};
  ${getDefaultTransition('left')};
`

export const FlipSwitch = ({
  checked = false,
  disabled,
  formControl,
  name = 'flip-switch',
  onClick,
  ...rest
}: FlipSwitchType): React.ReactElement => {
  const handleClick = useCallback(() => {
    if (disabled) {
      return
    }

    onClick && onClick()
  }, [disabled, onClick])

  return (
    <FlipSwitchSlider checked={checked} onClick={handleClick} disabled={disabled} {...rest}>
      <FlipSwitchHandle checked={checked} />
      {/* Helper input for handling in forms: */}
      {formControl && <Input formControl={formControl} name={name} checked={checked} hidden />}
    </FlipSwitchSlider>
  )
}
