import { ExportButtonDropdown, ExportType } from '@components'

import React, { ReactElement, useCallback } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useDownloadExport } from '../../../../../../hooks/useDownloadExport'
import { getExportDownloadUrl } from '../../../../../../utils/getExportDownloadUrl'

const chartOfAccountsExportUrl = 'chartOfAccounts'

export const ExportChartOfAccountsButton = (): ReactElement => {
  const { organization } = useUserOrganization()
  const { download } = useDownloadExport()

  const organizationId = organization?.id

  const exportChartOfAccounts = useCallback(
    (type: ExportType) => {
      if (!organizationId) {
        return
      }

      const downloadUrl = getExportDownloadUrl({ exportUrl: chartOfAccountsExportUrl, organizationId, type })
      download(downloadUrl)
    },
    [organizationId, download],
  )

  const handleSelect = useCallback(
    (_: string, value: ExportType) => {
      exportChartOfAccounts(value)
    },
    [exportChartOfAccounts],
  )

  return (
    <ExportButtonDropdown exportTypesToSkip={[ExportType.RegnskabBasis]} onSelect={handleSelect} variant="secondary" />
  )
}
