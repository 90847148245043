import { Alert } from '@design-system'

import React, { ReactNode, useCallback } from 'react'

import { BannerType } from './types'

interface MaintenanceMessageProps {
  children: ReactNode
  dismissable?: boolean
  uniqueKey: string
  onClose?: (id: string) => void
  bannerType: BannerType
}

export const MaintenanceMessage = ({
  children,
  dismissable,
  uniqueKey,
  onClose,
  bannerType,
}: MaintenanceMessageProps) => {
  const handleClose = useCallback(() => {
    if (onClose && dismissable) {
      onClose(uniqueKey)
    }
  }, [dismissable, uniqueKey, onClose])

  return (
    <Alert closable={dismissable} variant={bannerType} onClose={dismissable ? handleClose : undefined}>
      {children}
    </Alert>
  )
}
