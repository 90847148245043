import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'

export const SettingsLayoutHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${Spacing.L};
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing.S};
`
