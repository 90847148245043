import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconControlling = ({
  color = '#9aa5ac',
  color2 = '#fff',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  isOutlined,
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-bills')} {...rest}>
    <svg width={width} height={height} x="0px" y="0px" viewBox="0 0 360.82 332.04">
      <line
        x1="335.54"
        y1="44.91"
        x2="25.28"
        y2="44.91"
        fill="none"
        stroke={isOutlined ? coloring(color2) : coloring(color)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <line
        x1="256.62"
        y1="322.04"
        x2="104.2"
        y2="322.04"
        fill="none"
        stroke={isOutlined ? coloring(color2) : coloring(color)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <line
        x1="180.41"
        y1="320.41"
        x2="180.41"
        y2="10"
        fill="none"
        stroke={isOutlined ? coloring(color2) : coloring(color)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <line
        x1="333.41"
        y1="162.74"
        x2="284.89"
        y2="39.81"
        fill="none"
        stroke={isOutlined ? coloring(color2) : coloring(color)}
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <line
        x1="244.67"
        y1="162.74"
        x2="293.2"
        y2="39.81"
        fill="none"
        stroke={isOutlined ? coloring(color2) : coloring(color)}
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <line
        x1="115.81"
        y1="162.74"
        x2="67.28"
        y2="39.81"
        fill="none"
        stroke={isOutlined ? coloring(color2) : coloring(color)}
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <line
        x1="27.07"
        y1="162.74"
        x2="75.6"
        y2="39.81"
        fill="none"
        stroke={isOutlined ? coloring(color2) : coloring(color)}
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <circle cx="180.41" cy="44.91" r="26.88" fill={isOutlined ? coloring(color2) : coloring(color)} />
      <path
        d="M38.61,206.24H20.94a58.91,58.91,0,0,0,117.82,0H38.61Z"
        transform="translate(-7.44 -44.4)"
        fill="none"
        stroke={isOutlined ? coloring(color2) : coloring(color)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="27"
      />
      <path
        d="M254.61,206.24H236.94a58.91,58.91,0,1,0,117.82,0H254.61Z"
        transform="translate(-7.44 -44.4)"
        fill="none"
        stroke={isOutlined ? coloring(color2) : coloring(color)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="27"
      />
    </svg>
  </Box>
)
