import { amountToDisplayValue, ComponentsGroup, SkeletonText, Spacing, Text } from '@design-system'

import map from 'lodash/map'
import { ReactElement } from 'react'

import { OverviewStats } from '../../types/overviewStats'

interface OverviewLabelsProps {
  stats: OverviewStats
  isLoading: boolean
}

export const OverviewLabels = ({ stats, isLoading }: OverviewLabelsProps): ReactElement => (
  <ComponentsGroup spacing={Spacing.XXL}>
    {map(stats, (stat, key: string) => {
      const { name, amount, colorVariant } = stat
      return (
        <ComponentsGroup key={key} spacing={Spacing.XS}>
          <Text variant="small" colorVariant="secondary">
            {name}:
          </Text>
          {isLoading ? (
            <SkeletonText variant="h3" width={80} />
          ) : (
            <Text variant="h3" colorVariant={colorVariant}>
              {amountToDisplayValue(amount, amount !== 0)}
            </Text>
          )}
        </ComponentsGroup>
      )
    })}
  </ComponentsGroup>
)
