import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { ReactElement, useCallback } from 'react'

import { ApproveInvoiceModal } from '@views/invoices/elements/ApproveInvoiceModal'

import { EmberModal } from '../../contexts/emberCloseModalContext'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'approve-invoice-modal-module'

class ApproveInvoiceModalWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('closeEmber') closeEmber: Function
  @registerEvent('approveInvoice') approveInvoice: Function | undefined
}

interface ApproveInvoiceModalProps {
  onCloseEmber: EmberModal
  onApproveInvoice: Function
}

function ApproveInvoiceModalModule({ onCloseEmber, onApproveInvoice }: ApproveInvoiceModalProps): ReactElement {
  const closeModal = useCallback(() => {
    onCloseEmber({ detail: null })
  }, [onCloseEmber])

  const approveInvoice = useCallback(
    (shouldNeverShowAgain: boolean) => {
      onApproveInvoice({ detail: { shouldNeverShowAgain } })
    },
    [onApproveInvoice],
  )

  return (
    <ThemeProvider>
      <ModalContextProvider>
        <ApproveInvoiceModal onModalClose={closeModal} onApproveInvoice={approveInvoice} />
      </ModalContextProvider>
    </ThemeProvider>
  )
}

const ApproveInvoiceModalCustomElement = createCustomElement(
  ApproveInvoiceModalModule,
  ApproveInvoiceModalWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, ApproveInvoiceModalCustomElement)

export default ApproveInvoiceModalCustomElement
