import { useState } from 'react'

export enum Direction {
  Back = -1,
  Next = 1,
}

export const usePagination = () => {
  const [pagesCount, setPagesCount] = useState(0)
  const [activePage, setActivePage] = useState(0)
  const [prevActivePage, setPrevActivePage] = useState(-1)

  const canChangePage = (direction: Direction) => {
    if (direction === Direction.Back) {
      return activePage > 0
    }

    return activePage < pagesCount - 1
  }

  const setPage = (pageIndex: number) => {
    setActivePage(pageIndex)
  }

  const changePage = (direction: Direction) => {
    if (!canChangePage(direction)) {
      return
    }

    setPrevActivePage(activePage)
    setActivePage((prevActivePage) => (prevActivePage += direction))
  }

  const isLastPage = () => activePage === pagesCount - 1

  const isFirstPage = () => activePage === 0

  return {
    activePage,
    canChangePage,
    changePage,
    isFirstPage,
    isLastPage,
    pagesCount,
    prevActivePage,
    setPage,
    setPagesCount,
  }
}
