import { NavItem } from '../../NavList'
import { SubItemsMode } from '../../NavList/types/subItemsMode'

interface GetItemsNavigable<T> {
  items: Array<NavItem<T>>
  expandedIds: string[]
  subItemsMode: SubItemsMode
  parentItem?: NavItem<T>
}

export const getItemsNavigable = <T>({ items, expandedIds = [], subItemsMode, parentItem }: GetItemsNavigable<T>) =>
  items.reduce((result: Array<NavItem<T>>, item) => {
    const itemsNavigable = [...result]
    const items = item.subItems

    if (subItemsMode === 'vertical') {
      itemsNavigable.push(item)

      if (expandedIds.includes(item.id) && items?.length) {
        itemsNavigable.push(...getItemsNavigable({ items, expandedIds, subItemsMode }))
      }
    } else if (subItemsMode === 'horizontal') {
      if (!parentItem && !expandedIds.length) {
        itemsNavigable.push(item)
      }

      if (!parentItem && expandedIds.includes(item.id) && items?.length) {
        itemsNavigable.push(...getItemsNavigable({ items, expandedIds, subItemsMode, parentItem: item }))
      }

      if (parentItem && expandedIds.includes(parentItem.id)) {
        itemsNavigable.push(item)
      }
    }

    return itemsNavigable
  }, [])
