import { Button, ButtonsGroup, Input, Modal, SkeletonBox, Text, useModal } from '@design-system'

import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { useCopyAndNotify } from '../../../../../../hooks/useCopyAndNotify'
import { useFetchAccessToken } from '../../hooks/useFetchAccessToken'

interface CreateAccessTokenModalProps {
  actorId?: string
}

export const ViewAccessTokenModal = ({ actorId }: CreateAccessTokenModalProps): ReactElement => {
  const [currentAccessToken, setCurrentAccessToken] = useState('')
  const { t } = useTranslation()
  const { close: closeViewAccessTokenModal } = useModal(ModalId.ViewAccessTokenModal, {
    onClose: () => {
      setCurrentAccessToken('')
    },
  })
  const { copy } = useCopyAndNotify()

  const { accessToken, isLoading } = useFetchAccessToken(actorId)

  const handleCopyButtonClick = useCallback(() => {
    if (currentAccessToken) {
      copy(currentAccessToken)
    }
  }, [currentAccessToken, copy])

  const handleCancelClick = useCallback(() => {
    closeViewAccessTokenModal()
  }, [closeViewAccessTokenModal])

  useEffect(() => setCurrentAccessToken(accessToken), [accessToken])

  return (
    <Modal id={ModalId.ViewAccessTokenModal} size="s" closable>
      <Modal.Header title={t('settings.organization.access_tokens.view_access_token_modal.view_access_token')} />
      <Modal.Body>
        <Text colorVariant="secondary">{t('settings.organization.access_tokens.view_access_token_modal.token')}</Text>
        {isLoading || !currentAccessToken ? (
          <SkeletonBox width={512} height={40} />
        ) : (
          <Input readOnly type="text" value={currentAccessToken} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={handleCancelClick}>
            {t('settings.organization.access_tokens.view_access_token_modal.cancel')}
          </Button>
          <Button icon="twoSquares" onClick={handleCopyButtonClick}>
            {t('settings.organization.access_tokens.view_access_token_modal.copy_to_clipboard')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
