import qs from 'qs'

import { Metable } from '../../../types/metable'
import { getRequest } from '../../../utils'
import { Invoice } from './types'

export interface InvoicesQueryParams {
  state?: 'draft' | 'approved'
  pageSize?: number
  offset?: number
  sortProperty?: string
  entryDate?: string
  sortDirection?: 'DESC' | 'ASC'
}

interface InvoicesResponse extends Metable {
  invoices: Invoice[]
}

export const fetchInvoices = (organizationId: string, params: InvoicesQueryParams): Promise<InvoicesResponse> =>
  getRequest(`/v2/invoices?${qs.stringify({ organizationId, ...params })}`)
