export enum EInvoiceRegistrationResponse {
  success = 200,
  registrationError = 400,
  cvrInvalid = 422,
  unexpectedError = 500,

  // registered at Nemhandel by another operator (not Sproom)
  registeredAtNemhandel = 403,

  // registered using Billy/Sproom
  identifierExists = 409,
}
