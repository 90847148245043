import { useMutation } from 'react-query'

import { APIError } from '../../../../utils'
import { changeUserPassword, ChangeUserPasswordResponseData } from '../query-api'

interface UseChangePasswordProps {
  onError?: (error?: APIError) => void
  onSettled?: (response: ChangeUserPasswordResponseData | undefined, error: APIError | null | undefined) => void
  onSuccess?: (response: ChangeUserPasswordResponseData) => void
}

export const useChangePassword = (props?: UseChangePasswordProps) => {
  const { onError, onSuccess, onSettled } = props || {}
  const { mutate: changePassword, ...rest } = useMutation(changeUserPassword, {
    onSuccess,
    onSettled,
    onError,
  })

  return { changePassword, ...rest }
}
