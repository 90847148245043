export const getDefaultValues = () => {
  const now = new Date()

  return {
    invoiceNumber: '',
    issueDate: now,
    dueDate: now,
    message: '',
    invoiceLines: [],
    footerNote: '',
  }
}
