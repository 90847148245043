import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconPage = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-page')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={coloring(color)}
        d="M27,28c0,1.657-1.343,3-3,3H8c-1.657,0-3-1.343-3-3V4c0-1.657,1.343-3,3-3h11c1.657,0,8,6.343,8,8V28z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M24,32H8c-2.206,0-4-1.794-4-4V4c0-2.206,1.794-4,4-4h11c2.187,0,9,6.813,9,9v19C28,30.206,26.206,32,24,32z M18.97,1.999L8,2C6.897,2,6,2.897,6,4v24c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2V9C25.792,7.662,20.338,2.208,18.97,1.999z"
      ></path>
      <path fill={coloring(color2 || color)} d="M21,1h-2v5c0,1.657,1.343,3,3,3h5V7C27,5.343,22.657,1,21,1z"></path>
      <path
        fill={coloring(bg)}
        d="M28,10h-6c-2.206,0-4-1.794-4-4V0h3c2.224,0,7,4.776,7,7V10z M20,2v4c0,1.103,0.897,2,2,2h4V7 c-0.027-1.14-3.86-4.973-5-5H20z"
      ></path>
    </svg>
  </Box>
)
