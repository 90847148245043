import uniqBy from 'lodash/uniqBy'

import { UmbrellaOrganization, UserOrganization } from '../types'

export const getOrganizationItems = (organizations?: UserOrganization[] | UmbrellaOrganization[]) =>
  uniqBy(organizations, 'organizationId').map((organization) => ({
    ...organization,
    id: organization.organizationId,
    text: organization.name?.toString() || '',
  })) || []
