import { addMonths, endOfMonth, isAfter, isBefore, setMonth, setYear, startOfMonth, subMonths } from 'date-fns'
import React, { ReactElement, useCallback, useMemo } from 'react'

import { Button } from '../../../../../Button'
import { ButtonDropdown } from '../../../../../ButtonDropdown'
import { Text } from '../../../../../Text'
import { DATE_PICKER_YEAR_DROPDOWN_ID } from '../../../../constants/yearDropdownId'
import { useDatePickerContext } from '../../../../contexts/datePickerContext'
import { getYearsItems } from '../../../../utils/getYearsItems'
import * as Styled from './styles'

interface MonthHeaderActionsProps {
  monthLabel: string
  year: number
  month: number
}

export const MonthHeaderActions = ({ monthLabel, month, year }: MonthHeaderActionsProps): ReactElement => {
  const { goToNextMonths, goToPreviousMonths, selectedDate, minBookingDate, maxBookingDate, goToDate } =
    useDatePickerContext()

  const items = useMemo(() => getYearsItems(minBookingDate, maxBookingDate).reverse(), [maxBookingDate, minBookingDate])
  const disableGoToPrevious = Boolean(
    minBookingDate && isBefore(subMonths(new Date(year, month, 1), 1), startOfMonth(minBookingDate)),
  )
  const disableGoToNextDisabled = Boolean(
    maxBookingDate && isAfter(addMonths(endOfMonth(new Date(year, month, 1)), 1), endOfMonth(maxBookingDate)),
  )

  const handleYearSelect = useCallback(
    (id: string, value: number) => {
      const currentDate = selectedDate || new Date()
      const dateUpdated = setYear(setMonth(currentDate, month), value)
      goToDate(dateUpdated)
    },
    [goToDate, month, selectedDate],
  )

  return (
    <Styled.MonthHeaderActionsWrapper>
      <Styled.MonthsNavigation>
        <Button
          disabled={disableGoToPrevious}
          icon="chevronLeft"
          onClick={goToPreviousMonths}
          size="m"
          variant="secondary"
        />
        <Styled.MonthNameWrapper>
          <Text alignment="center" weight="medium">
            {monthLabel}
          </Text>
        </Styled.MonthNameWrapper>
        <Button
          disabled={disableGoToNextDisabled}
          icon="chevronRight"
          size="m"
          variant="secondary"
          onClick={goToNextMonths}
        />
      </Styled.MonthsNavigation>

      <Styled.YearSelectWrapper>
        <ButtonDropdown
          dropdownId={DATE_PICKER_YEAR_DROPDOWN_ID}
          dropdownMaxHeight={250}
          fullWidth
          items={items}
          onSelect={handleYearSelect}
          selectedId={String(year)}
          size="m"
          variant="secondary"
        />
      </Styled.YearSelectWrapper>
    </Styled.MonthHeaderActionsWrapper>
  )
}
