import { Contact } from '@views/contacts/types/contact'

import { ContactForm } from './formData'

export const getContactFormDefaultValues = (defaultValues?: Partial<Contact>): Partial<ContactForm> => {
  return {
    companyFromSearch: { id: defaultValues?.registrationNo, name: defaultValues?.name, vatNo: defaultValues?.vatNo },
    country: { id: defaultValues?.countryId },
  }
}
