import { css, SerializedStyles } from '@emotion/core'

import { Spacing } from '../../../../enums/spacing'
import { Size } from '../../types'
import { Position } from '../types'

const buttonSizeToIconSpacing: Record<Size, Spacing> = {
  m: Spacing.S,
  l: Spacing.S,
  xl: Spacing.S,
  xxl: Spacing.M,
}

export const getIconPositionStyle = (
  position: Position,
  size: Size,
  withIconMargin?: boolean,
): SerializedStyles | string => {
  if (!withIconMargin) {
    return ''
  }

  return position === 'left'
    ? css`
        margin-right: ${buttonSizeToIconSpacing[size]};
      `
    : css`
        margin-left: auto;
        padding-left: ${buttonSizeToIconSpacing[size]};
      `
}
