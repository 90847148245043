import { Flex, pxToNumber, SkeletonBox, Spacing, Text } from '@design-system'

import { ReactElement } from 'react'

import * as Styled from './styles'

interface BankConnectionProps {
  bankName?: string
  accountName?: string
  isLoading: boolean
  bankLogoUrl?: string
  logoSize?: number
}
export const BankConnection = ({
  isLoading,
  bankName,
  accountName,
  bankLogoUrl,
  logoSize = 50,
}: BankConnectionProps): ReactElement => {
  const containerHeight = logoSize + 2 * pxToNumber(Spacing.L)

  if (isLoading) {
    return <SkeletonBox height={containerHeight} fullWidth />
  }
  return (
    <Styled.BankConnectionWrapper>
      <Flex flexDirection="row" justifyContent="space-between">
        <Flex flexDirection="column">
          <Text variant="normal" weight="medium">
            {bankName}
          </Text>
          <Text>{accountName}</Text>
        </Flex>
        <Styled.BankLogo src={bankLogoUrl} width={logoSize} height={logoSize} alt={bankName}></Styled.BankLogo>
      </Flex>
    </Styled.BankConnectionWrapper>
  )
}
