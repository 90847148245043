import { Text } from '@design-system'

import cc from 'classcat'
import React from 'react'
import { Flex, FlexProps } from 'rebass'

import { reactClass } from '../../utils'

export type ModalHeaderProps = FlexProps & {
  heading?: string
}

export const ModalHeader = ({ children = '', heading = '', className, ...rest }: ModalHeaderProps) => (
  <Flex className={cc([className, reactClass('modal-header')])} variant="modal.header" {...rest}>
    {typeof children === 'string' ? <Text variant="h2">{children || heading || '.'}</Text> : children}
  </Flex>
)
