import { Module, SkeletonBox, Text } from '@design-system'

import isEmpty from 'lodash/isEmpty'
import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Scope } from '../../../../../../enums/scope'
import { isAuthorized } from '../../../../../../utils/isAuthorized'
import { OrganizationFeature } from '../../enums/organizationFeature'
import { useOrganizationFeature } from '../../hooks/useOrganizationFeature'
import { OrganizationFeatureItem } from '../organizationFeatureItem'
import * as Styled from './styles'

export const OrganizationFeaturesSelection = (): ReactElement | null => {
  const { t } = useTranslation()
  const { isLoading, hasFeature, setFeature, unsetFeature, currentFeatures, previousFeatures } =
    useOrganizationFeature()
  const isBetaWriteAuthorized = isAuthorized(Scope.BetaWrite)

  const handleFeatureToggle = useCallback(
    (name: OrganizationFeature, checked: boolean) => {
      if (checked) {
        setFeature(name)
      } else {
        unsetFeature(name)
      }
    },
    [setFeature, unsetFeature],
  )

  return (
    <Styled.OrganizationFeaturesSelectionWrapper>
      <Module.SectionsGroup>
        <Text variant="h2">{t('settings.organization.features.options.title')}</Text>
        <Module.Section>
          {isLoading && <SkeletonBox height={92} fullWidth />}
          {!isLoading &&
            (!isEmpty(currentFeatures) ? (
              currentFeatures.map((featureName) => (
                <OrganizationFeatureItem
                  key={featureName}
                  name={featureName}
                  value={hasFeature(featureName)}
                  onFeatureToggle={handleFeatureToggle}
                  disabled={!isBetaWriteAuthorized}
                />
              ))
            ) : (
              <Text alignment="center">{t('settings.organization.features.empty_state')}</Text>
            ))}
        </Module.Section>
        <Module.Section>
          {!isEmpty(previousFeatures) && (
            <>
              <Text colorVariant="secondary">{t('settings.organization.features.previous_tests.title')}</Text>
              {previousFeatures.map((featureName) => (
                <OrganizationFeatureItem key={featureName} disabled name={featureName} />
              ))}
            </>
          )}
        </Module.Section>
      </Module.SectionsGroup>
    </Styled.OrganizationFeaturesSelectionWrapper>
  )
}
