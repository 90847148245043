import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const FeaturesList = styled.ul`
  margin-left: ${Spacing.S};
  list-style: inside;
`
