import { addDays, isAfter } from 'date-fns'
import { useMemo } from 'react'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { hasCompletePaymentMethod } from '@views/dashboard/widgets/GetStarted/utils'

import { usePaymentMethods } from './usePaymentMethods'

interface UseOnboardingResponse {
  isAfterDayFromRegistering: boolean
  isLoading: boolean
  isOnboardingCompleted: boolean
}

export const useOnboarding = (): UseOnboardingResponse => {
  const { organization } = useUserOrganization()
  const { paymentMethods, isLoading: isLoadingPaymentMethods } = usePaymentMethods()
  const { accounts = [], isLoading: isLoadingAccounts } = useAccounts()

  const hasCreatedPaymentMethod = useMemo(
    () => hasCompletePaymentMethod(paymentMethods?.data || [], accounts),
    [accounts, paymentMethods],
  )

  const isOnboardingCompleted = hasCreatedPaymentMethod

  const isAfterDayFromRegistering = useMemo(() => {
    return !!organization?.createdTime && isAfter(new Date(), addDays(new Date(organization.createdTime), 1))
  }, [organization?.createdTime])

  return {
    isAfterDayFromRegistering,
    isLoading: isLoadingAccounts || isLoadingPaymentMethods,
    isOnboardingCompleted,
  }
}
