import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'

export const ModalHeaderWrapper = styled.header`
  display: flex;
  padding: ${Spacing.XL} ${Spacing.XL} ${Spacing.M} ${Spacing.XXL};
`

export const CloseButtonWrapper = styled.div`
  margin-top: -${Spacing.S};
  margin-left: auto;
`

export const BackButtonWrapper = styled.div`
  margin-top: -${Spacing.XXS};
  margin-left: -${Spacing.S};
  margin-right: ${Spacing.S};
`
