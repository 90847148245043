import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchTaxRates } from './query-api'
import { TaxRate } from './types'

interface ContextState {
  taxRates: TaxRate[] | undefined
  taxRatesIncludingDeactivated: TaxRate[] | undefined
  isLoading: boolean
}

const TaxRatesContext = createContext<ContextState | undefined>(undefined)

interface TaxRatesContextProps {
  children?: ReactNode
  organizationId?: string
}

export const TaxRatesContextProvider = ({ children, organizationId = '' }: TaxRatesContextProps): ReactElement => {
  const { data, isLoading } = useQuery([QueryKeys.TaxRates, organizationId], () => fetchTaxRates({ organizationId }), {
    enabled: !!organizationId,
  })

  return (
    <TaxRatesContext.Provider
      value={{
        taxRates: data?.taxRates.filter((taxRate) => taxRate.isActive),
        taxRatesIncludingDeactivated: data?.taxRates,
        isLoading,
      }}
    >
      {children}
    </TaxRatesContext.Provider>
  )
}

export const useTaxRates = () => {
  const context = useContext(TaxRatesContext)

  if (!context) {
    throw new Error('TaxRatesContextProvider is missing in the module!')
  }

  return context
}
