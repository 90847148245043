interface Userflow {
  start: (id: string) => void
}

export const useUserflow = (): Userflow => {
  const start = (id: string) => {
    if (!window.userflow) {
      console.error('Trying to start Userflow content, but Userflow library is not present.')
      return
    }

    window.userflow.start(id)
  }

  return {
    start,
  }
}
