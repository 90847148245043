import { Color, Icon, IconName, Text } from '@design-system'

import React, { ReactElement } from 'react'

import * as Styled from './styles'

interface ShortcutKeyProps {
  color?: Color
  label?: string
  icon?: IconName
}

export const ShortcutKey = ({ label, color, icon, ...rest }: ShortcutKeyProps): ReactElement => (
  <Styled.ShortcutKeyWrapper wide={!!label} {...rest}>
    {icon ? (
      <Icon icon={icon} color={color} />
    ) : (
      <Text as="span" color={color} colorVariant="secondary" variant="small">
        {label}
      </Text>
    )}
  </Styled.ShortcutKeyWrapper>
)
