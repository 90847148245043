import { Button, Modal, ModalProps, Text, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../enums/modalId'

export const ConnectionRenewedModal = (props: ModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeModal } = useModal(ModalId.BankConnectionSuccessModal)

  const handleModalClose = useCallback(() => {
    closeModal()
  }, [closeModal])

  return (
    <Modal size="s" {...props}>
      <Modal.Header>
        <Text variant="h2">{t('bank_integration.connection_renewal_success_header')}</Text>
      </Modal.Header>
      <Modal.Body>
        <Text>{t('bank_integration.connection_renewal_success_message')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose} variant="primary">
          {t('bank_integration.close_modal')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
