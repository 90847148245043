import { ORGANIZATION_URL_PLACEHOLDER } from '../../../../../constants/organizationUrlPlaceholder'
import { EmberRoute } from '../../../../../enums/emberRoute'
import { MenuItemExtended } from './getMenuItem'

// Generates url for menu item
// if item has 'to' property, it will be used as url
// ORGANIZATION_URL_PLACEHOLDER will be replaced with organizationUrl
// if item has 'routeTo' property, it will be converted to url using Ember router
// We need this to support both old menu items based on EmberRoute
// and new menu items based on urls passed as to property
// Once we get rid of Ember and we simplify building of menu items, this function will probably be removed
export const generateTo = (
  item: MenuItemExtended,
  organizationUrl: string,
  convertEmberRouteToUrl: (route: EmberRoute) => string,
) => {
  if (item.to) {
    return item.to.replace(ORGANIZATION_URL_PLACEHOLDER, organizationUrl)
  }

  return !item.routeTo || item.submenu?.length ? undefined : convertEmberRouteToUrl(item.routeTo)
}
