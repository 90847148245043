import { SmartSelect } from '@components-deprecated'

import React, { useEffect } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { State } from '../../../../types/reduxSaga-deprecated'
import { userOrganizationsRequested } from '../action-creators'
import { Organization } from '../types'
import { getOrganizationItems } from './getOrganizationItems'
import { OrganizationSelectItem } from './OrganizationSelectItem'

export type OrganizationSelectorProps = {
  formControl?: Control<any>
  name?: string
  onItemSelect?: (organization: Organization, name?: string) => void
  preselectedId?: string
}

export const OrganizationSelector = ({
  formControl,
  name = 'organization-selector',
  onItemSelect,
  preselectedId,
  ...rest
}: OrganizationSelectorProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const organizations: Organization[] = useSelector((state: State) => state.app.organizations)

  const items = getOrganizationItems(organizations)

  useEffect(() => {
    dispatch(userOrganizationsRequested())
  }, [dispatch, preselectedId])

  return (
    <SmartSelect<Organization>
      formControl={formControl}
      items={items}
      mode="default"
      name={name}
      notFoundText={t('organization_not_found')}
      placeholder={t('organization_select')}
      onItemSelect={onItemSelect}
      preselectedId={preselectedId}
      render={OrganizationSelectItem}
      {...rest}
    />
  )
}
