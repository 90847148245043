import { Button } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface PlanDetailsSideActionsProps {
  onClickChangePlan: () => void
}
export const PlanDetailsSideActionsComponent = ({
  onClickChangePlan,
}: PlanDetailsSideActionsProps): ReactElement | null => {
  const { t } = useTranslation()

  return <Button onClick={onClickChangePlan}>{t('organization_subscription.choose_plan')}</Button>
}
