import { isValidElement, ReactChild } from 'react'

import { NavItem } from '../../NavList'

export interface TabDropdownItemValue {
  onClick: () => void
}

export const getDropdownItemsArray = (items: ReactChild[]): NavItem<TabDropdownItemValue>[] =>
  items.reduce((dropdownItems: NavItem<TabDropdownItemValue>[], item) => {
    if (isValidElement(item)) {
      return [
        ...dropdownItems,
        {
          id: String(item.key),
          // @todo: think about better typing
          children: (item.props as any).children,
          value: {
            onClick: (item.props as any).onClick,
          },
          iconLeft: (item.props as any).icon,
        },
      ]
    }

    return dropdownItems
  }, [])
