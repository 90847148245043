export default {
  subMenu: {
    overflow: 'hidden',
    transition: 'all .43s ease-in-out',
  },
  subMenuItem: {
    display: 'block',
    marginBottom: '4px',
    paddingLeft: '55px',
    paddingRight: '10px',
    fontSize: '14px',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    fontWeight: 400,
    '&:last-of-type': {
      marginBottom: '8px',
    },
    '> div': {
      borderRadius: '6px',
      display: 'inline-block',
      padding: '0 10px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    '&:hover': {
      '> div': {
        boxShadow: '0 1px 3px 1px rgba(0,0,0,0.25)',
      },
    },
  },
}
