import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const TableActionsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${Spacing.S};
  margin-top: ${Spacing.XXS}; // helper for search border to not be cut by module's overflow
  align-items: center;
`
