import { CreditCardProps, ExpiredCardInfo, PaymentMethodSelection } from '@components'

import React, { ReactElement } from 'react'

interface OrganizationPaymentMethodSelectionProps {
  cardDetails?: CreditCardProps
  isCardExpired?: boolean
  isLoading?: boolean
  isSubscribed?: boolean
  onAddPaymentClick: () => void
  onUpdatePaymentClick?: () => void
}

export const OrganizationPaymentMethodSelection = ({
  cardDetails,
  isLoading,
  isSubscribed,
  onAddPaymentClick,
  onUpdatePaymentClick,
  isCardExpired,
}: OrganizationPaymentMethodSelectionProps): ReactElement => {
  return (
    <>
      <PaymentMethodSelection
        cardDetails={cardDetails}
        isLoading={isLoading}
        isSubscribed={isSubscribed}
        onAddPaymentClick={onAddPaymentClick}
      />
      {isCardExpired && <ExpiredCardInfo onUpdateCard={onUpdatePaymentClick} />}
    </>
  )
}
