import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'
import { MODAL_HORIZONTAL_PADDING } from '../../constants/modalHorizontalPadding'
import * as ModalStyled from '../ModalBody/styles'

export const ModalNavigationWrapper = styled.nav`
  display: flex;
  width: 100%;
  padding: ${Spacing.M} ${MODAL_HORIZONTAL_PADDING} 0;
  flex-direction: column;

  + ${() => ModalStyled.ModalBodyWrapper} {
    padding-top: ${Spacing.XL};
  }
`
