import { Button, ButtonProps } from '@design-system'

import { ReactElement, useCallback } from 'react'

import * as Styled from './styles'

interface AddDeductionComponentButtonProps extends ButtonProps {
  label: string
  onAdd: () => void
  count: number
}

export const AddDeductionComponentButton = ({
  count,
  onAdd,
  label,
  ...restProps
}: AddDeductionComponentButtonProps): ReactElement => {
  const handleAddLineClick = useCallback(() => {
    onAdd?.()
  }, [onAdd])

  return (
    <Styled.AddDeductionComponentButtonWrapper>
      <Button {...restProps} variant="text" icon="plusCircle" onClick={handleAddLineClick}>
        {label}
      </Button>
    </Styled.AddDeductionComponentButtonWrapper>
  )
}
