import { Route, Switch } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ReactRoute } from '../../enums/reactRoute'
import { getOrganizationViewPath } from '../../utils/getOrganizationViewPath'
import { CreditorsList } from '../creditors'
import { DebtorsList } from '../debtors/routes/DebtorsList'
import { useRedirectHashUrls } from './hooks/useRedirectHashUrls'

export const ViewContainer = () => {
  const { organization } = useUserOrganization()

  useRedirectHashUrls()

  if (!organization) {
    return null
  }

  return (
    <Switch>
      <Route component={DebtorsList} exact path={getOrganizationViewPath(organization.url, ReactRoute.Debtors)}></Route>
      <Route
        component={CreditorsList}
        exact
        path={getOrganizationViewPath(organization.url, ReactRoute.Creditors)}
      ></Route>
    </Switch>
  )
}
