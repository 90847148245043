import { call, put, takeLatest } from 'redux-saga/effects'

import { getRequest } from '../../../utils'
import { umbrellaReceived, UmbrellaRequested, userUmbrellasReceived } from './action-creators'
import { UMBRELLA_REQUESTED, USER_UMBRELLAS_REQUESTED } from './actions'

function* fetchUserUmbrellas() {
  const response: { data: any } = yield call(getRequest, '/user/umbrellas')
  yield put(userUmbrellasReceived(response.data))
}

function* fetchUmbrella(action: UmbrellaRequested) {
  const response: { data: any } = yield call(getRequest, `/umbrellas/${action.payload}`)
  yield put(umbrellaReceived(response.data))
}

export default function* () {
  yield takeLatest(USER_UMBRELLAS_REQUESTED, fetchUserUmbrellas)
  yield takeLatest(UMBRELLA_REQUESTED, fetchUmbrella)
}
