import { ButtonProtected } from '@components'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useInitiateBankConnection } from '@views/bankIntegration/hooks/useInitiateBankConnection'

import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { useBankIntegration } from '../../contexts/bankIntegrationContext'
import { useSyncBankConnection } from '../../hooks/useSyncBankConnection'
import * as Styled from './styles'

export const RenewBankConnectionButton = (): ReactElement | null => {
  const { t } = useTranslation()
  const { connectedBankAccountId, isLoading, requiresBankReconnection } = useBankIntegration()
  const { isLoading: isLoadingBankSync, syncAccountBankConnection } = useSyncBankConnection()
  const { connectToBank } = useInitiateBankConnection()

  const handleSyncBankClick = useCallback(() => {
    requiresBankReconnection ? syncAccountBankConnection(connectedBankAccountId as string) : connectToBank()
  }, [connectedBankAccountId, connectToBank, requiresBankReconnection, syncAccountBankConnection])

  if ((connectedBankAccountId && !requiresBankReconnection) || isLoading) {
    return null
  }

  return (
    <Styled.ButtonWrapper>
      <ButtonProtected
        onClick={handleSyncBankClick}
        size="m"
        fullWidth
        loading={isLoadingBankSync}
        scopes={Scope.BankSyncWrite}
        trackingContext={TrackingContext.BankConnectionsConnectBank}
      >
        {requiresBankReconnection ? t('bank_settings_modal.renew_connection') : t('bank_integration.connect_bank')}
      </ButtonProtected>
    </Styled.ButtonWrapper>
  )
}
