import { Button, ButtonsGroup, Modal, Text, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../enums/modalId'
import { Bill } from '../../types/bill'
import { BillDuplicationTable } from './elements/BillDuplicationTable'
import * as Styled from './styles'

interface BillDuplicationsModalProps {
  duplicatedBills?: Bill[]
  onCancel?: () => void
  onOk?: () => void
}

export const BillDuplicationsModal = ({
  duplicatedBills = [],
  onCancel,
  onOk,
}: BillDuplicationsModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close } = useModal(ModalId.DuplicatedBillActionModal)

  const handleKeepDuplicateBill = useCallback(() => {
    onOk?.()
    close()
  }, [close, onOk])

  const handleDiscardDuplicateBill = useCallback(() => {
    onCancel?.()
    close()
  }, [close, onCancel])

  return (
    <Modal closable id={ModalId.DuplicatedBillActionModal}>
      <Modal.Header title={t('bill.duplicate.modal.title')}>
        <Styled.DuplicateBillIllustration />
      </Modal.Header>
      <Modal.Body>
        <Text>{t('bill.duplicate.modal.message')}</Text>
        {duplicatedBills.length > 0 && <BillDuplicationTable duplicatedBills={duplicatedBills} />}
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button danger onClick={handleDiscardDuplicateBill}>
            {t('bill.duplicate.modal.cancelLabel')}
          </Button>
          <Button onClick={handleKeepDuplicateBill}>{t('bill.duplicate.modal.okLabel')}</Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
