import { TFunction } from 'i18next'
import * as yup from 'yup'

export const JournalCreateSchema = (t: TFunction) =>
  yup.object().shape({
    entryDate: yup
      .date()
      .typeError(t('voucher.inbox.form.entry_date_error'))
      .required(t('voucher.inbox.form.entry_date_error')),
    description: yup.string().required(t('voucher.inbox.form.description_error')),
    lines: yup.array().of(
      yup.object().shape({
        account: yup
          .object()
          .shape({
            id: yup.string().required(t('voucher.inbox.form.account_error')),
          })
          .required(t('voucher.inbox.form.account_error')),
        amount: yup
          .object()
          .shape({
            value: yup
              .number()
              .typeError(t('voucher.inbox.form.amount_error'))
              .required(t('voucher.inbox.form.amount_error')),
            currency: yup.string().required(t('app.currencies.currency_selector.select_currency')),
          })
          .required(),
        taxRate: yup
          .object()
          .shape({
            id: yup.string().required(t('voucher.inbox.form.vat_rate_error')),
          })
          .required(t('voucher.inbox.form.vat_rate_error')),
        contraAccount: yup.object().nullable(),
      }),
    ),
  })
