import { SettingsSection } from '@components'
import { Button } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useOrganizationInvoiceSettings } from '../../contexts/organizationInvoiceSettingsContext'
import { PaymentMethodsTable } from './elements/PaymentMethodsTable'

export const PaymentMethodsSection = (): ReactElement => {
  const { t } = useTranslation()
  const { openCreatePaymentMethodModal } = useOrganizationInvoiceSettings()

  const handleAddPaymentMethodButtonClick = useCallback(() => {
    openCreatePaymentMethodModal?.()
  }, [openCreatePaymentMethodModal])

  return (
    <SettingsSection>
      <SettingsSection.Content
        sideActions={
          <Button icon="plusCircle" onClick={handleAddPaymentMethodButtonClick} variant="secondary">
            {t('settings.organization.invoice.payment_methods_settings.add_payment_method_button')}
          </Button>
        }
        subtitle={t('settings.organization.invoice.payment_methods_settings.subtitle')}
        title={t('settings.organization.invoice.payment_methods_settings.title')}
      >
        <PaymentMethodsTable />
      </SettingsSection.Content>
    </SettingsSection>
  )
}
