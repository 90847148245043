import { Contact } from '@views/contacts/types/contact'

import { ModalType } from '../enums/modalType'
import { ContactForm } from './formData'
import { getCreateSupplierPayload } from './getCreateSupplierPayload'

export const getCreateContactPayload = (
  contactForm: ContactForm,
  modalType: ModalType,
  organizationId: string,
): Partial<Contact> => {
  switch (modalType) {
    case ModalType.Vendor:
      return getCreateSupplierPayload(contactForm, organizationId)
    default:
      return {}
  }
}
