import deburr from 'lodash/deburr'
import groupBy from 'lodash/groupBy'
import toLower from 'lodash/toLower'

import { NavItem } from '..'

const f = (valueToFormat: string) => toLower(deburr(valueToFormat))

const isSearchKeywordMatched = (searchKeywords: NavItem['searchKeywords'], fValue: string): boolean => {
  let isMatch = false

  if (typeof searchKeywords === 'string' && f(searchKeywords).includes(fValue)) {
    isMatch = true
  } else if (Array.isArray(searchKeywords)) {
    isMatch = searchKeywords.some((keyword) => keyword && f(keyword).includes(fValue))
  }

  return isMatch
}

const isMatched = <T>(item: NavItem<T>, fValue: string): boolean => {
  const { searchKeywords, children, value } = item

  if (searchKeywords && isSearchKeywordMatched(searchKeywords, fValue)) {
    return true
  }

  if (typeof children === 'string' && f(children).includes(fValue)) {
    return true
  }

  if (typeof value === 'string' && f(value).includes(fValue)) {
    return true
  }

  return false
}

const groupItemsByAccessorGroup = <T>(allItems: NavItem<T>[], filteredItems: NavItem<T>[]): NavItem<T>[] => {
  // Group items to show correct headers if possible
  const itemsGrouppedByAccessorGroupId = groupBy(filteredItems, 'accessorGroupId')
  const items: NavItem<T>[] = []

  for (const accessorGroupId in itemsGrouppedByAccessorGroupId) {
    const headerItem = allItems.find((item) => item.id === accessorGroupId)

    if (headerItem) {
      items.push(headerItem)
    }

    items.push(...itemsGrouppedByAccessorGroupId[accessorGroupId])
  }

  return items
}

export const filterItemsBasic = <T>(items: NavItem<T>[], inputValue: string): NavItem<T>[] => {
  if (!inputValue) {
    return items
  }

  const notAccessorItems = items.filter((item) => !item.accessor)
  const filteredItems = notAccessorItems.filter((item) => {
    const inputValues = inputValue.split(' ') // if user uses spaces, treat it as separated keywords, not a single phrase
    return inputValues.every((inputValue) => isMatched(item, f(inputValue)))
  })

  return groupItemsByAccessorGroup(items, filteredItems)
}
