import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconAddCircle = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-add-circle')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <path
        fill={coloring(color)}
        d="M7.5,14.5a7,7,0,1,1,7-7A7,7,0,0,1,7.5,14.5Zm0-13a6,6,0,1,0,6,6A6,6,0,0,0,7.5,1.5Z"
        transform="translate(-0.5 -0.5)"
      />
      <polygon
        fill={coloring(color2 || color)}
        points="10.5 6.5 10.5 7.5 7.5 7.5 7.5 10.5 6.5 10.5 6.5 7.5 3.5 7.5 3.5 6.5 6.5 6.5 6.5 3.5 7.5 3.5 7.5 6.5 10.5 6.5"
      />
    </svg>
  </Box>
)
