import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconHelp = ({
  color = 'inherit',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-help')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="1968.251 13.164 32 32">
      <path
        fill={coloring(color)}
        d="M1984.261,20.602c1.193,0,2.375,0.25,3.46,0.729l4.013-4.012c-2.229-1.413-4.79-2.154-7.47-2.154
        c-2.657,0-5.198,0.729-7.416,2.12l4.019,4.019C1981.934,20.843,1983.093,20.602,1984.261,20.602z"
      ></path>
      <path
        fill={coloring(color)}
        d="M1980.858,37.021l-4.018,4.017c2.219,1.394,4.763,2.125,7.423,2.125c2.684,0,5.246-0.743,7.478-2.159
        l-2.506-2.506l-1.505-1.505C1985.578,37.945,1983.018,37.956,1980.858,37.021z"
      ></path>
      <path
        fill={coloring(color)}
        d="M1972.425,21.687c-2.87,4.541-2.872,10.402-0.005,14.945l4.011-4.012c-0.962-2.192-0.961-4.731,0.004-6.922
        L1972.425,21.687z"
      ></path>
      <path
        fill={coloring(color)}
        d="M1996.138,21.741l-4.016,4.016c0.946,2.175,0.948,4.63,0.004,6.806l4.016,4.016
        C1998.958,32.067,1998.958,26.251,1996.138,21.741z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M1997.06,19.584c0,0-0.001-0.001-0.001-0.001c-0.463-0.617-0.967-1.201-1.501-1.735
        c-0.517-0.517-1.085-1.01-1.69-1.467c-2.789-2.104-6.118-3.217-9.626-3.217c-3.484,0-6.794,1.098-9.572,3.176c0,0,0,0,0,0
        c0,0,0,0,0,0c-0.62,0.465-1.207,0.972-1.743,1.507c-0.512,0.512-1.003,1.077-1.459,1.681c0,0.001-0.001,0.001-0.002,0.001
        c-4.283,5.668-4.286,13.587-0.006,19.259c0.456,0.603,0.949,1.171,1.467,1.69c0.532,0.532,1.116,1.037,1.736,1.502
        c2.779,2.082,6.092,3.182,9.58,3.182c3.51,0,6.84-1.114,9.63-3.221c0.001-0.001,0.003-0.001,0.004-0.002
        c0.603-0.457,1.169-0.948,1.684-1.461c0.536-0.538,1.043-1.124,1.506-1.743C2001.284,33.097,2001.282,25.221,1997.06,19.584z
        M1976.818,41.039l4.018-4.017c2.16,0.934,4.719,0.923,6.873-0.028l1.505,1.505l2.506,2.506c-2.231,1.416-4.794,2.159-7.478,2.159
        C1981.581,43.164,1979.037,42.432,1976.818,41.039z M1984.241,15.164c2.68,0,5.241,0.741,7.47,2.154l-4.013,4.012
        c-1.085-0.478-2.267-0.729-3.46-0.729c-1.168,0-2.328,0.241-3.395,0.701l-4.019-4.019
        C1979.043,15.894,1981.584,15.164,1984.241,15.164z M1987.423,34.904C1987.422,34.904,1987.422,34.904,1987.423,34.904
        c-1.92,1.065-4.386,1.076-6.314,0.029c-0.561-0.305-1.069-0.684-1.51-1.125c-0.427-0.428-0.798-0.923-1.101-1.471
        c-1.083-1.96-1.082-4.395,0.004-6.354c0.303-0.546,0.672-1.039,1.096-1.463c0.444-0.444,0.954-0.823,1.516-1.128
        c0,0,0.001,0,0.002-0.001c1.924-1.043,4.384-1.031,6.298,0.028c0.549,0.304,1.044,0.674,1.471,1.101
        c0.44,0.439,0.818,0.947,1.124,1.508c0,0.001,0,0.002,0.001,0.002c1.064,1.959,1.066,4.299,0.005,6.259
        c-0.305,0.562-0.685,1.073-1.129,1.517C1988.459,34.233,1987.967,34.602,1987.423,34.904
        C1987.423,34.903,1987.423,34.903,1987.423,34.904z M1976.413,25.698c-0.965,2.191-0.967,4.73-0.004,6.922l-4.011,4.012
        c-2.867-4.543-2.865-10.404,0.005-14.945L1976.413,25.698z M1992.104,32.563c0.944-2.176,0.942-4.631-0.004-6.806l4.016-4.016
        c2.819,4.51,2.82,10.326,0.004,14.838L1992.104,32.563z"
      ></path>
    </svg>
  </Box>
)
