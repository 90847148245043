import { Flex, Text } from '@design-system'

import { ReactElement } from 'react'

import * as Styled from './styles'

// this is a temporary placeholder component
// which is going to be replaced with a valid preview
export const InvoicePreview = (): ReactElement => (
  <Styled.InvoicePreviewWrapper>
    <Flex justifyContent="center">
      <Styled.InvoicePageWrapper>
        <Flex justifyContent="center">
          <Text variant="h2">Invoice Preview Placeholder</Text>
        </Flex>
      </Styled.InvoicePageWrapper>
    </Flex>
  </Styled.InvoicePreviewWrapper>
)
