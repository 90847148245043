import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'

import { SortDirection } from '../../../../../enums/sortDirection'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { AccountStatementSortProperty } from '../types/accountStatementSortProperty'

interface AccountStatementsFilters {
  page?: number
  pageSize?: ItemsPerPage
  searchQuery?: string
  sortDirection: SortDirection
  sortProperty: AccountStatementSortProperty
  period?: string
  withVoided: boolean
}

type UseAccountStatementsFiltersResponse = [
  AccountStatementsFilters,
  (
    filters:
      | Partial<AccountStatementsFilters>
      | ((prevParams: Partial<AccountStatementsFilters>) => Partial<AccountStatementsFilters>),
  ) => void,
]

export const defaultQuery: AccountStatementsFilters = {
  page: 1,
  pageSize: 100, // for this particular table we want to hardcode it to 100, so it doesn't use the global itemsPerPage mechanism
  searchQuery: '',
  sortDirection: SortDirection.Desc,
  sortProperty: 'entryDate', // BE has it's own sorting but we can at least simulate something to keep it in the url
  period: undefined,
  withVoided: false,
}

export const useAccountStatementsFilters = (): UseAccountStatementsFiltersResponse => {
  const [queryParams, setQueryParams] = useQueryParams<AccountStatementsFilters>({ defaultQuery })

  return useMemo(
    () => [
      {
        ...queryParams,
        page: Number(queryParams.page),
        pageSize: Number(queryParams.pageSize) as ItemsPerPage,
        withVoided:
          typeof queryParams.withVoided === 'string'
            ? queryParams.withVoided === 'true'
            : Boolean(queryParams.withVoided),
      },
      setQueryParams,
    ],
    [queryParams, setQueryParams],
  )
}
