import {
  UMBRELLA_ORGANIZATIONS_RECEIVED,
  UMBRELLA_ORGANIZATIONS_REQUESTED,
  USER_ORGANIZATIONS_RECEIVED,
  USER_ORGANIZATIONS_REQUESTED,
} from './actions'
import { UserOrganization } from './types'

export type UserOrganizationsRequested = {
  payload: null
  type: typeof USER_ORGANIZATIONS_REQUESTED
}

export type UserOrganizationsReceived = {
  payload: Array<UserOrganization>
  type: typeof USER_ORGANIZATIONS_RECEIVED
}

export type UmbrellaQueryFilters = {
  isInboxEnabled?: boolean
}

export type UmbrellaOrganizationsRequested = {
  payload: {
    umbrellaId: string
    filters?: UmbrellaQueryFilters
  }
  type: typeof UMBRELLA_ORGANIZATIONS_REQUESTED
}

export type UmbrellaOrganizationsReceived = {
  payload: Array<UserOrganization>
  type: typeof UMBRELLA_ORGANIZATIONS_RECEIVED
}

export function userOrganizationsRequested(): UserOrganizationsRequested {
  return {
    payload: null,
    type: USER_ORGANIZATIONS_REQUESTED,
  }
}

export function umbrellaOrganizationsRequested(
  umbrellaId: string,
  filters?: UmbrellaQueryFilters,
): UmbrellaOrganizationsRequested {
  return {
    payload: { umbrellaId, filters },
    type: UMBRELLA_ORGANIZATIONS_REQUESTED,
  }
}

export function userOrganizationsReceived(organizations: Array<UserOrganization>): UserOrganizationsReceived {
  return {
    payload: organizations,
    type: USER_ORGANIZATIONS_RECEIVED,
  }
}

export function umbrellaOrganizationsReceived(organizations: Array<UserOrganization>): UmbrellaOrganizationsReceived {
  return {
    payload: organizations,
    type: UMBRELLA_ORGANIZATIONS_RECEIVED,
  }
}
