import styled from '@emotion/styled'
import React from 'react'

const ConnectionStatusImg = styled.img`
  margin-bottom: 8px;
`

export const ConnectionStatusImage = () => {
  const statusImgSrc = '/releases/default/assets/images/dashboard/bank-connection-widget/connect-bank-icon.svg'

  return <ConnectionStatusImg src={statusImgSrc} />
}

export default ConnectionStatusImage
