import isEqual from 'lodash/isEqual'
import { useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { usePrevious } from 'react-use'

import { defaultStaleTime } from '../config/queryClient'
import { QueryKeys } from '../enums/queryKeys'

interface DefaultQuery {
  page?: string | number
}

interface UsePaginationPrefetchingProps<T extends DefaultQuery, K extends object> {
  getQueryFunction: (queryProps: T) => Promise<K>
  getQueryKey: (queryProps: T) => [QueryKeys, T]
  isEnabled: boolean
  queryProps: T
  totalPages: number
}

export const usePaginationPrefetching = <T extends DefaultQuery, K extends object>({
  getQueryFunction,
  getQueryKey,
  isEnabled,
  queryProps,
  totalPages,
}: UsePaginationPrefetchingProps<T, K>) => {
  const queryClient = useQueryClient()

  const queryKey = useMemo(() => getQueryKey(queryProps), [getQueryKey, queryProps])

  const previousQueryKey = usePrevious(queryKey)

  useEffect(() => {
    if (!isEnabled) {
      return
    }

    const currentPage = Number(queryProps.page)
    const nextPage = currentPage + 1
    const previousPage = currentPage - 1

    const prevQueryProps = {
      ...queryProps,
      page: previousPage,
    }
    const nextQueryProps = {
      ...queryProps,
      page: nextPage,
    }

    if (previousQueryKey && !isEqual(previousQueryKey, queryKey)) {
      queryClient.cancelQueries(previousQueryKey)
    }

    if (currentPage > 1) {
      queryClient.prefetchQuery(getQueryKey(prevQueryProps), () => getQueryFunction(prevQueryProps), {
        staleTime: defaultStaleTime,
      })
    }

    if (nextPage <= totalPages) {
      queryClient.prefetchQuery(getQueryKey(nextQueryProps), () => getQueryFunction(nextQueryProps), {
        staleTime: defaultStaleTime,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQueryFunction, getQueryKey, isEnabled, previousQueryKey, queryKey, queryProps, totalPages])
}
