import { FrodaRedirectionQueryParams } from './types/frodaRedirectionQueryParams'

const IS_PRODUCTION = process.env.NODE_ENV === 'production'

export const COMPANY_NAME = 'billy'
export const ELIGIBLE_VERIFICATION_SCORE = 0.5
export const FRODA_HOME_URL = IS_PRODUCTION ? 'https://home.froda.se' : 'https://home.froda-dev.se'
export const BILLY_LENDING_URL = `${FRODA_HOME_URL}/p/billy/landing`
/* eslint-disable @typescript-eslint/naming-convention */
export const DEFAULT_QUERY_PARAMS: FrodaRedirectionQueryParams = {
  jobId: undefined,
  error_code: undefined,
  token: undefined,
  auth_code: undefined,
}
/* eslint-enable @typescript-eslint/naming-convention */
