import { Button as ButtonComponent, SkeletonText as SkeletonTextComponent, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../../../types/themable'

const BUTTON_MARGIN = `${Spacing.S} 0`

export const SkatDisconnectButton = styled.button<Themable>`
  margin: ${BUTTON_MARGIN};
  width: 100%;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primaryBlue};
  box-shadow: none;
  border: none;
  cursor: pointer;
`

export const SkatConnectButton = styled(ButtonComponent)`
  margin: ${BUTTON_MARGIN};
`

export const LinkWrapper = styled.div`
  text-align: center;
`

export const SkeletonText = styled(SkeletonTextComponent)`
  margin-bottom: 7px;
`
