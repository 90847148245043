import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import React, { ReactNode } from 'react'
import { useSessionStorage } from 'react-use'

import { Themable } from '../../types/themable'
import { Theme } from '../../types/theme'
import { IconClose } from '../Icons/icons'

type DashboardWidgetBodyProps = {
  id: string
  size?: number
  children: ReactNode
  canClose?: boolean
}

const DashboardWidgetContainer = styled.div<Themable>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.variants.dashboard.widget.borderRadius};
  border: ${({ theme }) => theme.variants.dashboard.widget.border};
  box-shadow: ${({ theme }) => theme.variants.dashboard.widget.boxShadow};
  min-height: 250px;
  padding: 24px;
  display: flex;
  flex-direction: column;
`

const CloseButton = styled(IconClose)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 10px;
`

export const DashboardWidget = ({ id, size = 6, canClose, children }: DashboardWidgetBodyProps) => {
  const theme = useTheme<Theme>()
  const [isClosed, setIsClosed] = useSessionStorage(`${id}-closed`)

  const handleClose = () => {
    setIsClosed(true)
  }

  if (isClosed) {
    return null
  }

  return (
    <DashboardWidgetContainer className={`dashboard-widget dashboard-widget-size-${size}`}>
      {canClose && <CloseButton width={10} height={10} color={theme.colors.light} onClick={handleClose} />}
      {children}
    </DashboardWidgetContainer>
  )
}
