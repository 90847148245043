import { TranslatedItem } from '../../../SimpleCheckList'

export const modalFeaturesListLeft: TranslatedItem[] = [
  {
    tKey: 'feature_preview.unlimited_invoices',
  },
  {
    tKey: 'feature_preview.full_accounting_support',
  },
  {
    tKey: 'feature_preview.advanced_ai_accounting',
  },
]

export const modalFeaturesListRight: TranslatedItem[] = [
  {
    tKey: 'feature_preview.unlimited_integrations',
  },
  {
    tKey: 'feature_preview.auditor_access',
  },
  {
    tKey: 'feature_preview.plus_more',
  },
]
