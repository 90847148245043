import qs from 'qs'

import { Contact } from '@views/contacts/types/contact'

import { Side } from '../../../enums/side'
import { Metable } from '../../../types/metable'
import { getRequest } from '../../../utils'

interface ContactsResponseData extends Metable {
  contacts: Contact[]
}

export interface FetchContactsQueryParams {
  isCustomer?: boolean
  isSupplier?: boolean
  organizationId: string
  q?: string
  page?: number
  pageSize?: number
}

export const fetchContacts = ({
  organizationId,
  ...queryParams
}: FetchContactsQueryParams): Promise<ContactsResponseData> =>
  getRequest(`/v2/contacts?organizationId=${organizationId}&${qs.stringify(queryParams)}`)

interface ContactByIdResponseData {
  contact: Contact
}

export const fetchContactById = (contactId: string): Promise<ContactByIdResponseData> =>
  getRequest(`/v2/contacts/${contactId}`)

export const findContactsWithBohr = (
  organizationId: string,
  name: string,
  registrationNo: string,
): Promise<Contact[]> => {
  const nameEncoded = encodeURI(name)

  return getRequest(`/organizations/${organizationId}/contacts/search/?vat_no=${registrationNo}&name=${nameEncoded}`)
}

export interface ContactBalance {
  amount: number
  currencyId: string
  side: Side
}
interface FetchContactBalanceResponseData extends Metable {
  balance: ContactBalance
}

export const fetchContactBalance = (contactId: string, currencyId: string): Promise<FetchContactBalanceResponseData> =>
  getRequest(`/v2/contacts/${contactId}/balances/${currencyId}`)
