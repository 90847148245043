import React, { ReactElement, ReactNode, useCallback } from 'react'

import { AmountValue } from '../AmountValue'
import { BadgeVariant } from '../Badge'
import { BadgeWithLabel } from '../BadgeWithLabel'
import { SkeletonText } from '../SkeletonText'
import * as Styled from './styles'

type Id = string | number

interface AmountInfoBoxPropsBase<T> {
  active?: boolean
  amount?: number
  badgeAmount?: number
  badgeLabel?: ReactNode
  badgeVariant?: BadgeVariant
  clickable?: boolean
  currencyId?: string
  disabled?: boolean
  id?: T
  loading?: boolean
  onClick?: (id?: T) => void
  shortFormat?: boolean
}

interface AmountInfoBoxPropsBaseId<T> extends Omit<AmountInfoBoxPropsBase<T>, 'onClick' | 'id'> {
  id: T
  onClick?: (id: T) => void
}

export type AmountInfoBoxProps<T = unknown> = T extends Id ? AmountInfoBoxPropsBaseId<T> : AmountInfoBoxPropsBase<T>

export const AmountInfoBox = <T,>({
  active,
  amount,
  badgeAmount,
  badgeLabel,
  badgeVariant = 'primary',
  clickable,
  currencyId,
  disabled,
  id,
  loading,
  onClick,
  shortFormat = true,
  ...rest
}: AmountInfoBoxProps<T>): ReactElement => {
  const handleClick = useCallback(() => {
    if (clickable && !disabled) {
      onClick?.(id as T)
    }
  }, [clickable, id, disabled, onClick])

  return (
    <Styled.AmountInfoBoxWrapper
      active={active}
      clickable={clickable}
      disabled={disabled}
      onClick={handleClick}
      {...rest}
    >
      <Styled.BadgeWithLabelWrapper>
        {loading ? (
          <SkeletonText variant="small" width={80} />
        ) : (
          <BadgeWithLabel variant={badgeVariant} badge={badgeAmount}>
            {badgeLabel}
          </BadgeWithLabel>
        )}
      </Styled.BadgeWithLabelWrapper>

      {loading ? (
        <SkeletonText variant="h1" fullWidth />
      ) : (
        <>
          {amount !== undefined && (
            <AmountValue textVariant="h1" amount={amount} currencyId={currencyId} shortFormat={shortFormat} />
          )}
        </>
      )}
    </Styled.AmountInfoBoxWrapper>
  )
}
