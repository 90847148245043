/*
 * THIS COMPONENT IS DEPRECATED
 * For components that are defined in `body` HTML tag please use `react-app/src/design-system/utils/zIndex.ts` otherwise use regular numbers instead.
 * */
export const zIndex = {
  aboveContent: 1,
  aboveTableContent: 2,
  aboveElement: 2,
  navigation: 2,
  aboveNavigation: 3,
  overlay: 4,
  modal: 5,
}
