import { BadgeVariant } from '@design-system'

import { PayrollStatus } from '../../../types/payrollStatus'

export const statusToBadgeVariant: Record<PayrollStatus, BadgeVariant> = {
  // Success
  [PayrollStatus.Approved]: 'success',
  [PayrollStatus.AutomaticApproval]: 'success',
  [PayrollStatus.Success]: 'success',
  [PayrollStatus.Tentative]: 'success',
  // Warning
  [PayrollStatus.Awaiting]: 'warning',
  [PayrollStatus.AwaitingApproval]: 'warning',
  [PayrollStatus.MissingApproval]: 'warning',
  [PayrollStatus.Reviewed]: 'warning',
  // Error
  [PayrollStatus.Cancelled]: 'error',
  [PayrollStatus.Failed]: 'error',
  [PayrollStatus.Rejected]: 'error',
  [PayrollStatus.Unknown]: 'error',
}
