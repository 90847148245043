import { BillFormLine } from '../elements/BillEditableForm/utils/formData'

export type BillLineAmounts = Pick<BillFormLine, 'vatAmount' | 'inclVatAmount'>

export const getAmountsFromBillLines = (billLines?: BillLineAmounts[]) => {
  let exclVatAmountTotal = 0
  let vatAmountTotal = 0
  let inclVatAmountTotal = 0

  if (billLines) {
    for (const billLine of billLines) {
      const { vatAmount = 0, inclVatAmount = 0 } = billLine
      const exclVatAmount = inclVatAmount - vatAmount

      exclVatAmountTotal += exclVatAmount
      vatAmountTotal += vatAmount
      inclVatAmountTotal += inclVatAmount
    }
  }

  return {
    exclVatAmountTotal,
    inclVatAmountTotal,
    vatAmountTotal,
  }
}
