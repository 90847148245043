import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PasswordCheckItem } from './PasswordCheckItem'
import * as Styled from './styles'
import { getChecksList } from './utils/getChecksList'

interface PasswordChecksProps {
  value: string
}

export const PasswordChecks = ({ value }: PasswordChecksProps): ReactElement => {
  const { t } = useTranslation()

  const checkList = useMemo(() => getChecksList(t), [t])

  return (
    <Styled.PasswordChecksList>
      {checkList.map((item) => {
        const { isValid, description } = item
        const isChecked = isValid(value)

        return <PasswordCheckItem key={description} isChecked={isChecked} item={item} />
      })}
    </Styled.PasswordChecksList>
  )
}
