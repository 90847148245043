import { RefObject } from 'react'
import { useMouseHovered } from 'react-use'

const toPxFloat = (value: number): string => `${value.toFixed(2)}px`

interface UseImageZoomProps {
  imageWrapperRef: RefObject<HTMLElement>
  imageZoomedRef: RefObject<HTMLElement>
  imageMagnifierRef: RefObject<HTMLElement>
}

export const useImageZoom = ({ imageWrapperRef, imageZoomedRef, imageMagnifierRef }: UseImageZoomProps) => {
  const {
    elX: wrapperMouseX,
    elY: wrapperMouseY,
    elW: wrapperWidth,
    elH: wrapperHeight,
  } = useMouseHovered(imageWrapperRef, {
    whenHovered: true,
    bound: true,
  })

  const { width: imageWidth = 0, height: imageHeight = 0 } = imageZoomedRef.current?.getBoundingClientRect() || {}
  const { width: magnifierWidth = 0, height: magnifierHeight = 0 } =
    imageMagnifierRef.current?.getBoundingClientRect() || {}

  // Math.min and Math.max are used to limit moving magnifier outside the wrapper
  const offsetMagifierX = Math.min(wrapperWidth - magnifierWidth, Math.max(0, wrapperMouseX - magnifierWidth / 2))
  const wrapperPercentX = (100 * offsetMagifierX) / (wrapperWidth - magnifierWidth)

  // Math.min and Math.max are used to limit moving magnifier outside the wrapper
  const offsetMagifierY = Math.min(wrapperHeight - magnifierHeight, Math.max(0, wrapperMouseY - magnifierHeight / 2))
  const wrapperPercentY = (100 * offsetMagifierY) / (wrapperHeight - magnifierHeight)

  // Zoomed image is placed inside the magnifier (which moves with the cursor) so we need to compense magnifier offest
  const offsetX = -offsetMagifierX - ((imageWidth - wrapperWidth) * wrapperPercentX) / 100 || 0
  const offsetY = -offsetMagifierY - ((imageHeight - wrapperHeight) * wrapperPercentY) / 100 || 0

  const imageZoomedTranslateX = toPxFloat(offsetX)
  const imageZoomedTranslateY = toPxFloat(offsetY)

  const magnifierTranslateX = toPxFloat(offsetMagifierX)
  const magnifierTranslateY = toPxFloat(offsetMagifierY)

  return {
    imageZoomedTranslateX,
    imageZoomedTranslateY,
    magnifierTranslateX,
    magnifierTranslateY,
  }
}
