import { PaymentPane, sortByDateKey } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useInvoice } from '../../hooks/useInvoice'
import { InvoicePaymentPaneItem } from './elements/InvoicePaymentPaneItem'
import { getInvoicePaymentPaneData } from './utils/getInvoicePaymentPaneData'

interface InvoicePaymentPaneProps {
  invoiceId: string
}

export const InvoicePaymentPane = ({ invoiceId }: InvoicePaymentPaneProps): ReactElement => {
  const { data: invoiceData, isLoading } = useInvoice(invoiceId)
  const { t } = useTranslation()

  const invoiceDataSorted = useMemo(() => {
    return invoiceData?.invoice
      ? {
          ...invoiceData.invoice,
          balanceModifiers: sortByDateKey(invoiceData.invoice.balanceModifiers || [], 'entryDate'),
        }
      : undefined
  }, [invoiceData?.invoice])

  const invoicePaymentPaneData = useMemo(() => {
    return getInvoicePaymentPaneData(t, invoiceDataSorted, isLoading)
  }, [t, invoiceDataSorted, isLoading])

  return (
    <>
      <PaymentPane {...invoicePaymentPaneData}>
        {invoiceDataSorted?.balanceModifiers.map(({ id, ...rest }) => (
          <InvoicePaymentPaneItem key={id} id={id} invoiceType={invoiceDataSorted.type} {...rest} />
        ))}
      </PaymentPane>
    </>
  )
}
