import { useMemo } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { dateToString } from './dateToString'
import { getDatesFromPeriodText } from './getDatesFromPeriodText'

export const usePeriodDates = (period: string | undefined) => {
  const { organization, isLoading } = useUserOrganization()
  const { firstFiscalYearStart, firstFiscalYearEnd } = organization || {}

  const dates = useMemo(() => {
    if (period && period !== 'all' && firstFiscalYearEnd && firstFiscalYearStart) {
      try {
        const { start, end } = getDatesFromPeriodText(period, {
          firstFiscalYearStart: new Date(firstFiscalYearStart),
          firstFiscalYearEnd: new Date(firstFiscalYearEnd),
        })
        return {
          fromDate: dateToString(start),
          toDate: dateToString(end),
        }
      } catch (error) {
        return {}
      }
    }
  }, [firstFiscalYearEnd, firstFiscalYearStart, period])

  return {
    ...(dates || {}),
    isLoading,
  }
}
