import React, { ReactElement } from 'react'

import { BookkeepersSection } from './elements/BookkeepersSection'
import { UsersSection } from './elements/UsersSection'

export const OrganizationSettingsUsers = (): ReactElement => (
  <>
    <UsersSection />
    <BookkeepersSection />
  </>
)
