import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getVoucherActionsErrorSelector } from '../selectors/getVoucherActionErrorSelector'
import { getVoucherActionsStatusSelector } from '../selectors/getVoucherActionStatusSelector'
import { RequestStatus, VoucherAction } from '../types'

const DEFAULT_ACTIONS = Object.values(VoucherAction)

type CurrentActionStatus = Partial<Record<VoucherAction, RequestStatus>>

type ProcessingActions = Partial<Record<VoucherAction, boolean>>

type UseVoucherActionStateProps = {
  voucherId: string
  actions?: VoucherAction[]
  onSuccess?: (action: VoucherAction) => void
  onError?: (action: VoucherAction, error: Error) => void
  onStart?: (action: VoucherAction) => void
}

export const useVoucherActionState = (props: UseVoucherActionStateProps) => {
  const { actions = DEFAULT_ACTIONS, voucherId, onSuccess, onError, onStart } = props
  const [currentActionStatus, setCurrentActionStatus] = useState<CurrentActionStatus>({})
  const voucherActionsStatus = useSelector(getVoucherActionsStatusSelector(voucherId))
  const voucherActionsError = useSelector(getVoucherActionsErrorSelector(voucherId))

  const processingActions = actions.reduce(
    (result: ProcessingActions, action) => ({
      ...result,
      ...{ [action]: currentActionStatus[action] === RequestStatus.Processing },
    }),
    {},
  )

  const isSomeActionProcessing = Object.values(processingActions).some((value) => !!value)

  useEffect(() => {
    const nextCurrentActionsStatus: CurrentActionStatus = {}

    for (const action of actions) {
      const prevStatus = currentActionStatus[action]
      const nextStatus = voucherActionsStatus?.[action]
      const voucherActionError = voucherActionsError?.[action]

      if (!nextStatus || prevStatus === nextStatus) {
        continue
      }

      if (nextStatus === RequestStatus.Processing) {
        onStart && onStart(action)
      } else if (prevStatus === RequestStatus.Processing && nextStatus === RequestStatus.Failed) {
        const error = voucherActionError || new Error(`Error while doing "${action}" action`)
        console.error(error)
        onError && onError(action, error)
      } else if (prevStatus === RequestStatus.Processing && nextStatus === RequestStatus.Success) {
        onSuccess && onSuccess(action)
      }

      nextCurrentActionsStatus[action] = nextStatus
    }

    setCurrentActionStatus((prevCurrentActionsStatus) => ({ ...prevCurrentActionsStatus, ...nextCurrentActionsStatus }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherActionsStatus, voucherActionsError])

  return { processingActions, isSomeActionProcessing }
}
