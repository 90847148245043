import { da, enUS, fr } from 'date-fns/locale'
import i18next from 'i18next'

const locales = {
  da,
  en: enUS,
  fr,
}

export const useLocale = () => {
  return locales[i18next.language] || locales.da
}
