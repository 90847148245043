import { Link, PopOver, Text } from '@components-deprecated'

import { css } from '@emotion/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Flex, FlexProps } from 'rebass'

import { SpecificState } from '../../../types/reduxSaga-deprecated'
import { reactClass } from '../../../utils'
import { selectBankLineMatch } from '../selectors'
import { Difference, ExtendedBankLineMatch } from '../types'
import { CreateBillForm } from './CreateBillForm'
import { CreateInvoiceForm } from './CreateInvoiceForm'

export type MatchedPlaceholderProps = FlexProps & {
  difference: Difference
  matchId: string
  isDraggingOver?: boolean
}

export const MatchedPlaceholder = ({ isDraggingOver, matchId, difference, ...rest }: MatchedPlaceholderProps) => {
  const [isOpen, setOpen] = useState(false)
  const { amount, differenceAmount } = difference
  const { t } = useTranslation()
  const bankLineMatch = useSelector((state: SpecificState) =>
    selectBankLineMatch(state, matchId),
  ) as ExtendedBankLineMatch

  const isPositiveAmount = differenceAmount ? differenceAmount > 0 : amount > 0

  const toggleOpen = () => setOpen(!isOpen)
  const handleClose = () => setOpen(false)

  return (
    <Flex
      className={reactClass('bankline-placeholder')}
      css={css`
        position: relative;
        padding: 7px 10px;
        height: 30px;
        align-items: center;
        border: 1px solid #cfcfcf;
        border-radius: 4px;
        background-color: #f4f4f4;

        & > *:not(:first-of-type) {
          margin-left: 20px;
        }
      `}
      {...rest}
    >
      <Text
        variant="small1"
        css={css`
          opacity: ${isDraggingOver ? '1.0' : '0.5'};
          transition: opacity 0.2s;
          z-index: 1;
        `}
      >
        <Link onClick={toggleOpen}>{isPositiveAmount ? t('create_invoice') : t('create_bill')}</Link>
        {` ${t('bankreconciliation.matchplaceholder.or_click_here')}`}
      </Text>
      <Box
        css={css`
          position: absolute;
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          height: 30px;
          border-radius: 4px;
          border: ${isDraggingOver ? '1px dashed #CFCFCF' : 'initial'};
          background: ${isDraggingOver
            ? 'linear-gradient(90deg, #F5FBFF, #F5FBFF, #F5FBFF)'
            : 'linear-gradient(90deg, rgba(255,255,255,0.5), rgba(255,255,255,0.9), rgba(255,255,255,1.0))'};
          transition: background 0.2s;
        `}
      />
      {isOpen && (
        <PopOver
          isOpen={isOpen}
          onClose={handleClose}
          css={css`
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            max-width: none;
            margin-left: 0 !important;
            z-index: 2;
          `}
        >
          {isPositiveAmount ? (
            <CreateInvoiceForm bankLineMatch={bankLineMatch} onClose={handleClose} />
          ) : (
            <CreateBillForm bankLineMatch={bankLineMatch} onClose={handleClose} />
          )}
        </PopOver>
      )}
    </Flex>
  )
}
