import React, { ReactElement } from 'react'

import { useFormContext, useWatch } from '../../../../../../hooks/useForm'
import { getAmountsFromBillLines } from '../../../../utils/getAmountsFromBillLines'
import { BillSummaryModule } from '../../../BillSummaryModule'
import { BillFormSchema } from '../../utils/formData'
import { BillPaymentInfo } from '../BillPaymentInfo'

export const BillSummary = (): ReactElement => {
  const { control } = useFormContext<BillFormSchema>()
  const billLines = useWatch({ control, name: 'billLines' })
  const currencyId = useWatch({ control, name: 'currencyId' })
  const paymentDate = useWatch({ control, name: 'paymentDate' })
  const paymentAccount = useWatch({ control, name: 'paymentAccount' })

  const { exclVatAmountTotal, inclVatAmountTotal, vatAmountTotal } = getAmountsFromBillLines(billLines)

  return (
    <BillSummaryModule
      currencyId={currencyId}
      exclVatAmount={exclVatAmountTotal}
      inclVatAmount={inclVatAmountTotal}
      vatAmount={vatAmountTotal}
    >
      {paymentDate && paymentAccount?.name && (
        <BillPaymentInfo paymentDate={paymentDate} paymentAccountName={paymentAccount.name} />
      )}
    </BillSummaryModule>
  )
}
