import { ButtonProtected, SettingsSection } from '@components'
import { Text } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { CompanyType } from '@modules-deprecated/app/organization/enums/companyType'

import { Scope } from '../../../../../../../../enums/scope'
import { TrackingContext } from '../../../../../../../../enums/trackingContext'
import { useOrganizationOwners } from '../../../../contexts/organizationOwnersContext'
import { OrganizationOwnersTable } from './elements/OrganizationOwnersTable'
import { getOrganizationOwnersSectionTKeys } from './utils/getOrganizationOwnersSectionTKeys'

export const OrganizationOwnersSection = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { openCreateOwnerModal } = useOrganizationOwners()

  const isProprietorship = organization?.companyType === CompanyType.Proprietorship
  const isRegistrationNumber = !!organization?.registrationNo

  const { titleTKey, subtitleTKey, buttonLabelTKey, addTaxtoAddOwnersTKey } = useMemo(() => {
    return getOrganizationOwnersSectionTKeys(isProprietorship)
  }, [isProprietorship])

  const handleAddOwnerButtonClick = useCallback(() => {
    openCreateOwnerModal?.()
  }, [openCreateOwnerModal])

  return (
    <SettingsSection>
      <SettingsSection.Content
        title={t(titleTKey)}
        subtitle={t(subtitleTKey)}
        sideActions={
          isRegistrationNumber && (
            <ButtonProtected
              icon="plusCircle"
              onClick={handleAddOwnerButtonClick}
              scopes={Scope.AccountWrite}
              trackingContext={TrackingContext.OrganizationOwners}
              variant="secondary"
            >
              {t(buttonLabelTKey)}
            </ButtonProtected>
          )
        }
      ></SettingsSection.Content>
      {isRegistrationNumber ? (
        <OrganizationOwnersTable isProprietorship={isProprietorship} />
      ) : (
        <Text>{t(addTaxtoAddOwnersTKey)}</Text>
      )}
    </SettingsSection>
  )
}
