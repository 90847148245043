import React, { ReactElement, useEffect } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'

import { useUserOrganizationSettings } from '@modules-deprecated/app/organization'

import { useEmberRouter } from '../../contexts/emberRouterContext'
import { EmberRoute } from '../../enums/emberRoute'
import { Route as ReceiptsRoute } from './enums/route'
import { ReceiptsList } from './routes'

export const Receipts = (): ReactElement => {
  const { organizationSettings, isLoading: areOrganizationSettingsLoading } = useUserOrganizationSettings()
  const { navigate } = useEmberRouter()

  const isInboxEnabled = organizationSettings?.isInboxEnabled === '1'

  useEffect(() => {
    if (!areOrganizationSettingsLoading && isInboxEnabled) {
      navigate(EmberRoute.InboxVouchers)
    }
  }, [areOrganizationSettingsLoading, isInboxEnabled, navigate])

  return (
    <HashRouter>
      <Switch>
        <Route component={ReceiptsList} exact path={ReceiptsRoute.ReceiptsList} />
      </Switch>
    </HashRouter>
  )
}
