import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'

import { da, en, fr } from './../locales'
import { setNumeralLocale } from './numeral'

const resources = {
  en: { translation: en },
  da: { translation: da },
  fr: { translation: fr },
}

export const defaultI18nOptions: InitOptions = {
  resources,
  lng: 'da',
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
}

i18n.use(initReactI18next).init(defaultI18nOptions)

// Initialise numeral to the same locale as i18n to avoid inconsistencies
setNumeralLocale('da')

export const locales = {
  da: 'da_DK',
  en: 'en_US',
  fr: 'fr_FR',
}

export default i18n
