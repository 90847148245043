import { MonthNameFormatted } from '../types/monthNameFormatted'

export const getMonthData = (year: number, monthIndex: number, code = 'en-US'): MonthNameFormatted => {
  const date = new Date(year, monthIndex - 1, 1)

  return {
    short: date.toLocaleString(code, { month: 'short' }),
    long: date.toLocaleString(code, { month: 'long', year: 'numeric' }),
  }
}
