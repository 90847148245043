import React from 'react'
import Lottie, { Options as LottieOptions, LottieProps } from 'react-lottie'

import Animations from './animations'
import { AnimationName } from './animationVariant'
import * as Styled from './styles'

export type LottieAnimationName = AnimationName

export type LottieAnimationProps = Omit<LottieProps, 'options'> & {
  name: AnimationName
  options?: Partial<LottieOptions>
}

// No clear guidelines for using Lottie with TypeScript, no viable type for ref found
export const LottieAnimation = React.forwardRef(({ name, options, ...rest }: LottieAnimationProps, ref: any) => {
  const lottieOptions: LottieOptions = {
    ...options,
    animationData: (name && Animations[name]) || options?.animationData,
  }

  return (
    <Styled.LottieAnimationWrapper className="ds-lottie-animation">
      <Lottie options={lottieOptions} {...(ref ? { ref } : {})} {...rest} />
    </Styled.LottieAnimationWrapper>
  )
})
