import { useQuery } from 'react-query'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { fetchUmbrellaOrganizationsInvitations } from '@modules-deprecated/app/umbrellas/query-api'
import { OrganizationInvitation } from '@modules-deprecated/app/umbrellas/types/organizationInvitation'

import { QueryKeys } from '../../../../../enums/queryKeys'

interface UseInvitedOrganizationsResponse {
  data: OrganizationInvitation[]
  isLoading: boolean
}

export const useInvitedOrganizations = (): UseInvitedOrganizationsResponse => {
  const { umbrella, isLoading: isUmbrellaLoading } = useUmbrella()
  const umbrellaId = umbrella?.id || ''

  const { data, isIdle, isLoading } = useQuery(
    [QueryKeys.UmbrellaOrganizationsInvitations],
    () => fetchUmbrellaOrganizationsInvitations(umbrellaId),
    {
      enabled: !isUmbrellaLoading,
      refetchOnWindowFocus: true,
    },
  )

  return {
    data: data?.data || [],
    isLoading: isIdle || isLoading,
  }
}
