import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@components-deprecated'
import { Button, notify } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { NotificationKeys } from '../../../../../../enums/notificationKeys'
import { APIError } from '../../../../../../utils'
import { deleteAccount } from '../../query-api'

type DeleteAccountModalProps = ModalProps & {
  onCancel?: () => void
  onSaved?: () => void
  accountId?: string
}

export const DeleteAccountModal = ({
  disabled,
  accountId,
  onClose = () => null,
  onSaved = () => null,
  ...rest
}: DeleteAccountModalProps) => {
  const { t } = useTranslation()

  const deleteAccountMutation = useMutation((accountId: string) => deleteAccount(accountId), {
    onSuccess: () => {
      onSaved()
      onClose()
    },
    onError: (error: APIError) => {
      notify({ id: NotificationKeys.AccountDelete, message: error?.body?.errorMessage, variant: 'error' })
    },
  })

  return (
    <Modal isOpen={!!accountId} {...rest} onClose={onClose}>
      <ModalHeader heading={t('coa.confirm')} />
      <ModalBody>{t('coa.confirm_delete_account')}</ModalBody>
      <ModalFooter>
        <Button onClick={onClose} disabled={disabled} variant="text">
          {t('coa.delete_cancel')}
        </Button>
        <Button
          danger
          disabled={disabled}
          onClick={() => accountId && deleteAccountMutation.mutate(accountId)}
          variant="primary"
        >
          {t('coa.delete_account')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
