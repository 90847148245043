import { TableActionsWrapper, TableBadgeLabel } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrellaLicensePacks } from '../../hooks/useUmbrellaLicensePacks'

export const LicensePacksTableActions = (): ReactElement => {
  const { t } = useTranslation()
  const { umbrellaLicensePacks, isLoading } = useUmbrellaLicensePacks()

  return (
    <TableActionsWrapper>
      <TableBadgeLabel amount={umbrellaLicensePacks?.length || 0} isLoading={isLoading} variant="neutral">
        {t('umbrella_subscriptions.license_packs.title')}
      </TableBadgeLabel>
    </TableActionsWrapper>
  )
}
