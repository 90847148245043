import { Input as InputComponent } from '@components-deprecated'
import { FontWeight } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../types/themable'

export const FinancingContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
`

export const AsideWrapper = styled.div``

export const Title = styled.p<Themable>`
  margin-bottom: 8px;
  font-weight: ${FontWeight.Medium};
  font-size: 22px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkGray1};
`

export const SubTitle = styled.p<Themable>`
  margin-bottom: 22px;
  font-weight: ${FontWeight.Medium};
  font-size: 22px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkGray1};
`

export const Description = styled.p<Themable>`
  margin: 8px 0;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray7};
`

export const RangeSliderContainer = styled.div`
  margin: 24px 0;
`

export const TabsContainer = styled.div`
  margin-bottom: 40px;
`

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
`

export const InfoKey = styled.p<Themable>`
  font-weight: normal;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grayA};
`

interface InfoValueProps extends Themable {
  small?: boolean
}

export const InfoValue = styled.p<InfoValueProps>`
  font-weight: ${({ small }) => (small ? FontWeight.Regular : FontWeight.Medium)};
  font-size: ${({ small }) => (small ? '14px' : '18px')};
  text-align: right;
  color: ${({ theme }) => theme.ds.colors.base.textSecondary};
`

export const InfoText = styled.p<Themable>`
  margin: 15px 0;
  font-weight: normal;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primaryGray};
  text-align: left;
`

export const LineDivider = styled.div<Themable>`
  margin: 8px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.newGray2};
`

export const FormOuterContainer = styled.div`
  display: flex;
`

export const FormColoumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const FormColoumnRight = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 30px;
`

export const Input = styled(InputComponent)`
  margin-bottom: 25px;
`
