import { TFunction } from 'i18next'

import { billStatusToTitleKey } from '../../../constants/billStatusToTitleKey'
import { BillStatus } from '../../../enums/billStatus'

export const getBillsTitle = (status = BillStatus.All, amount: number, t: TFunction): string => {
  const tKey = billStatusToTitleKey[status]

  return t(tKey, { count: amount })
}
