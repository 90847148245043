import { IStringifyOptions, parse, stringify } from 'qs'
import { useCallback, useEffect, useMemo } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useHistory, useLocation } from 'react-router-dom'

import { useLocalStorage } from './useLocalStorage'

const options: IStringifyOptions = { arrayFormat: 'indices' }

/**
 * @deprecated THIS HOOK IS DEPRECATED FOR REGULAR USAGE (WITHOUT STORAGE NEEDS). Please use "src/hooks/useQueryParams.ts" instead.  Also - remember to wrap the module with Router from 'react-router-dom' to be able to use this hook
 */
export const useQueryParamsStorage = <T extends Record<string, any>>(
  key: string,
  defaultQuery?: Partial<T>,
  shouldUseStorage = false,
) => {
  const history = useHistory()
  const location = useLocation()
  const [storedQuery, setStoredQuery] = useLocalStorage(`query-params-${key}`, undefined)

  const query = useMemo(() => {
    if (location.search) {
      return parse(location.search, { ignoreQueryPrefix: true })
    }

    if (shouldUseStorage && storedQuery) {
      return storedQuery
    }

    return defaultQuery || {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useBeforeunload(() => {
    updateStoredQuery()
  })

  useEffect(() => {
    return () => updateStoredQuery()
  })

  const updateStoredQuery = useCallback(
    (queryUpdated?: T) => {
      setStoredQuery(queryUpdated)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setStoredQuery],
  )

  const setParams = useCallback(
    (params: T, prefix = '', overrideParams = true) => {
      const newQuery = overrideParams ? params : { ...query, ...params }
      updateStoredQuery(newQuery)
      history.replace(`${prefix}?${stringify(newQuery, options)}`)
    },

    // `T` should be passed as a argument to update because it's Typescript prop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, query, updateStoredQuery],
  )

  const setQueryStringValue = useCallback(
    (key: keyof T, value: any) => {
      let queryNew = query

      if (!value && value !== 0) {
        delete queryNew[key]
      } else {
        queryNew = { ...queryNew, [key]: value }
      }

      setParams(queryNew)
      updateStoredQuery(queryNew)
    },
    // `T` should be passed as a argument to update because it's Typescript prop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query, setParams, updateStoredQuery],
  )

  const setParam = useCallback(
    (key: keyof T, value: any) => {
      setQueryStringValue(key, value)
    },
    // `T` should be passed as a argument to update because it's Typescript prop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setQueryStringValue],
  )

  const compileUrlWithQuery = useCallback((url: string, params: T) => {
    return `${url}?${stringify(params, options)}`
    // `T` should be passed as a argument to update because it's Typescript prop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    queryParams: query,
    setQueryParam: setParam,
    setQueryParams: setParams,
    compileUrlWithQuery,
  }
}
