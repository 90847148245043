import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconTrash = ({ color = 'inherit', color2, width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-trash')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <rect fill={coloring(color2 || color)} x="11" y="13" width="2" height="12"></rect>
      <rect fill={coloring(color2 || color)} x="15" y="13" width="2" height="12"></rect>
      <rect fill={coloring(color2 || color)} x="19" y="13" width="2" height="12"></rect>
      <rect fill={coloring(color)} x="7" y="9" width="18" height="2"></rect>
      <path fill={coloring(color)} d="M23,13v14H9V13H7v14c0,1.104,0.895,2,2,2h14c1.105,0,2-0.896,2-2V13H23z"></path>
      <path fill={coloring(color)} d="M7,11V9h16l2,2h2V9c0-1.105-0.895-2-2-2H7C5.895,7,5,7.895,5,9v2H7z"></path>
      <path fill={coloring(color)} d="M13,9V5h6v4h2V5c0-1.104-0.895-2-2-2h-6c-1.105,0-2,0.896-2,2v4H13z"></path>
    </svg>
  </Box>
)
