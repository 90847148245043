import { date, mixed, object, SchemaOf } from 'yup'

import { EARLIEST_SYNC_START_DATE } from '../constants/earliestSyncStartDate'
import { SyncTimePeriodMode } from '../enums/syncTimePeriodMode'

export interface ExternalInvoicesSyncForm {
  syncTimePeriodMode: SyncTimePeriodMode
  syncStartDate: Date
}

export const defaultValues: ExternalInvoicesSyncForm = {
  syncTimePeriodMode: SyncTimePeriodMode.CurrentFiscalYear,
  syncStartDate: new Date(EARLIEST_SYNC_START_DATE),
}

export type ExternalInvoicesSyncFormSchema = SchemaOf<ExternalInvoicesSyncForm>

export const getValidationSchema = (): ExternalInvoicesSyncFormSchema =>
  object({
    syncTimePeriodMode: mixed<SyncTimePeriodMode>().oneOf(Object.values(SyncTimePeriodMode)).required(),
    syncStartDate: date().required(),
  })
