import { DraftEditorCommand, getDefaultKeyBinding, KeyBindingUtil } from 'draft-js'
import { KeyboardEvent } from 'react'

import { StyleCommand } from '../enums/styleCommands'

const keyToStyleCommand: Record<string | number | symbol, DraftEditorCommand> = {
  b: StyleCommand.Bold,
  i: StyleCommand.Italic,
  u: StyleCommand.Underline,
}

export const keyBindings = (event: KeyboardEvent<{}>): DraftEditorCommand | null => {
  const isCtrlCmdKeyPressed = KeyBindingUtil.isCtrlKeyCommand(event) || KeyBindingUtil.hasCommandModifier(event)
  const cmdCommand = keyToStyleCommand[event.key]

  if (isCtrlCmdKeyPressed && cmdCommand) {
    return cmdCommand
  }

  return getDefaultKeyBinding(event)
}
