import { FontWeight } from '@design-system'

export default {
  menu: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '250px',
    paddingTop: '30px',
    zIndex: 4,
  },
  menuItemsContainer: {
    marginTop: '20px',
    marginBottom: '100px',
    flex: 1,
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
    },
  },
  menuItemContainer: {
    position: 'relative',
    '.hoverMenu': {
      display: 'none',
    },
    '&:hover .hoverMenu': {
      display: 'block',
    },
  },
  menuItemTitle: {
    paddingLeft: '30px',
    paddingRight: '10px',
    alignItems: 'center',
    cursor: 'pointer',
    height: '40px',
    fontSize: '16px',
    fontWeight: FontWeight.Medium,
  },
  menuDivider: {
    height: '1px',
    margin: '8px 65px',
  },
}
