import { ButtonDropdown, ButtonDropdownProps, NavItem } from '@design-system'

import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import { getItems } from './utils/getItems'

const MAX_PERIODS_DEFAULT = 12

export interface ReportsComparisonPeriodSelectProps extends Omit<ButtonDropdownProps<number>, 'items'> {
  maxPeriods?: number
}

export const ReportsComparisonPeriodSelect = ({
  maxPeriods = MAX_PERIODS_DEFAULT,
  selectedId: selectedIdControlled,
  onSelect,
  ...buttonDropdownProps
}: ReportsComparisonPeriodSelectProps): ReactElement => {
  const [selectedId, setSelectedId] = useState(selectedIdControlled)
  const items: NavItem<number>[] = useMemo(() => getItems(maxPeriods), [maxPeriods])

  useEffect(() => {
    const isSelectedItemAvailable = !!items?.find((item) => item.id === selectedId)

    if (!isSelectedItemAvailable && items?.length) {
      const lastItem = items[items.length - 1]
      setSelectedId(lastItem.id)
      onSelect?.(lastItem.id, lastItem.value)
    }
  }, [items, onSelect])

  useEffect(() => {
    setSelectedId(selectedIdControlled)
  }, [selectedIdControlled])

  return (
    <ButtonDropdown
      {...buttonDropdownProps}
      items={items}
      onSelect={onSelect}
      placement="bottom-start"
      selectedId={selectedId}
      variant="secondary"
    />
  )
}
