import { useMemo } from 'react'

import { usePaymentMethods } from '../../../hooks/usePaymentMethods'
import { PaymentMethodType } from '../enums/paymentMethodType'

interface UsePaymentMethodIdResponse {
  paymentMethodId: string | null
}

export const usePaymentMethodId = (paymentMethodType: PaymentMethodType): UsePaymentMethodIdResponse => {
  const { paymentMethods } = usePaymentMethods()

  const paymentMethodId = useMemo(() => {
    return paymentMethods?.data.find((method) => method?.type.toLowerCase() === paymentMethodType)?.id || null
  }, [paymentMethods?.data, paymentMethodType])

  return { paymentMethodId }
}
