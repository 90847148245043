import React, { ReactElement, ReactNode } from 'react'

import { Alignment } from '../../../../types/alignment'
import * as Styled from './styles'

interface ModalFooterProps {
  children?: ReactNode
  className?: string
  alignment?: Alignment
}

export const ModalFooter = ({ alignment = 'right', children, className }: ModalFooterProps): ReactElement => (
  <Styled.ModalFooterWrapper alignment={alignment} className={className}>
    {children}
  </Styled.ModalFooterWrapper>
)
