import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { Spacing } from '../../enums/spacing'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { getShadow } from '../../utils/getShadow'
import { Label as LabelComponent } from '../Label'
import { Size } from './types/size'
import { getCheckedStyle } from './utils/getCheckedStyle'
import { getDisabledStyle } from './utils/getDisabledStyle'
import { getErrorStyle } from './utils/getErrorStyle'
import { getHoverStyle } from './utils/getHoverStyle'
import { getPressedStyle } from './utils/getPressedStyle'
import { sizeToProperties } from './utils/sizeToProperties'

interface ControlProps {
  size: Size
  withTopOffset?: boolean
}

export const Control = styled.span<ControlProps>`
  display: flex;
  width: ${({ size }) => sizeToProperties[size].dimension}px;
  height: ${({ size }) => sizeToProperties[size].dimension}px;
  margin-top: ${({ size, withTopOffset }) => withTopOffset && sizeToProperties[size].offsetTop};
  border: 1px solid ${Color.Gray50};
  border-radius: ${({ size }) => sizeToProperties[size].radius};
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${Color.White};
  ${getShadow('raisedLightInner')};
  ${getDefaultTransition('background', 'border', 'box-shadow')};

  ${() => IconWrapper} {
    svg {
      transform: scale(${({ size }) => sizeToProperties[size].iconScale});
      transform-origin: center;
    }
  }
`

export const IconWrapper = styled.figure`
  display: flex;
`

export const Label = styled(LabelComponent)`
  margin-left: ${Spacing.S};
  cursor: inherit;
  ${getDefaultTransition('color')};
`

export const NativeCheckbox = styled.input`
  opacity: 0;
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  &:focus-visible {
    ~ ${() => Control} {
      box-shadow: 0 0 0 2px ${Color.DeepSeaGreen};
    }
  }
`

interface CheckboxWrapperProps {
  checked: boolean
  disabled: boolean
  error: boolean
  indeterminate: boolean
}

export const CheckboxWrapper = styled.label<CheckboxWrapperProps>`
  display: flex;
  position: relative;
  cursor: pointer;

  path,
  rect {
    fill: currentColor;
  }

  ${(props) => hasHoverStyle(props) && getHoverStyle(Control, props.checked)};
  ${(props) => hasPressedStyle(props) && getPressedStyle(Control, props.checked)};
  ${(props) => hasCheckedStyle(props) && getCheckedStyle(Control)};
  ${(props) => hasErrorStyle(props) && getErrorStyle(Control, props.checked)};
  ${(props) => hasDisabledStyle(props) && getDisabledStyle(Control, Label, props.checked)};
`

const hasHoverStyle = ({ disabled, error }: CheckboxWrapperProps) => !disabled && !error
const hasPressedStyle = ({ disabled, error }: CheckboxWrapperProps) => !disabled && !error
const hasCheckedStyle = ({ checked, indeterminate }: CheckboxWrapperProps) => checked || indeterminate
const hasDisabledStyle = ({ disabled }: CheckboxWrapperProps) => disabled
const hasErrorStyle = ({ error }: CheckboxWrapperProps) => error
