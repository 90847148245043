import { CountrySelect, ProcessingOverlay, SettingsSection } from '@components'
import { Color, FormItemsGroup, Input } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../hooks'
import { useOrganizationInfoSettings } from '../../contexts/organizationInfoSettingsContext'
import { OrganizationInfoFormSchema } from '../../utils/formData'
import * as Styled from './styles'

export const BasicInfo = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<OrganizationInfoFormSchema>()
  const { isProcessing, isLoading } = useOrganizationInfoSettings()

  return (
    <SettingsSection.Content title={t('settings.organization.organization_info.basic_info.heading')}>
      <Styled.BasicInfoFormWrapper>
        {(isProcessing || isLoading) && <ProcessingOverlay color={Color.White} />}
        <FormItemsGroup>
          <FormItem
            name="name"
            label={t('settings.organization.organization_info.basic_info.name.label')}
            render={({ field }) => <Input {...field} />}
          />
          <FormItem
            name="registrationNo"
            label={t('settings.organization.organization_info.basic_info.registration_number.label')}
            render={({ field }) => <Input {...field} />}
          />
          <FormItem
            name="street"
            label={t('settings.organization.organization_info.basic_info.street.label')}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t('settings.organization.organization_info.basic_info.street.placeholder')}
              />
            )}
          />
          <FormItemsGroup itemsInRow={2}>
            <FormItem
              name="zipcode"
              itemColumnStart={1}
              itemColumnEnd={2}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t('settings.organization.organization_info.basic_info.zipcode.placeholder')}
                />
              )}
            />
            <FormItem
              name="city"
              itemColumnStart={2}
              itemColumnEnd={7}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t('settings.organization.organization_info.basic_info.city.placeholder')}
                />
              )}
            />
          </FormItemsGroup>
          <FormItem
            name="countryId"
            label={t('settings.organization.organization_info.basic_info.country.label')}
            render={({ field: { value, onChange, ...props } }) => (
              <CountrySelect {...props} dropdownSize="fitTrigger" onSelect={onChange} selectedId={value} disabled />
            )}
          />
        </FormItemsGroup>
      </Styled.BasicInfoFormWrapper>
    </SettingsSection.Content>
  )
}
