import { useState } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { useOrganizationSource } from '../../../hooks'
import { ExternalInvoicesSyncStatus } from '../enums/externalInvoicesSyncStatus'
import { fetchExternalInvoicesSync } from '../query-api'

const syncRefetchInterval = 600000 // 10 minutes

export const useFetchExternalInvoicesSync = () => {
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const { isSourceZervant } = useOrganizationSource()

  const { data, ...rest } = useQuery(QueryKeys.ExternalInvoicesSync, () => fetchExternalInvoicesSync(), {
    enabled: isSourceZervant,
    onSuccess: (data) => {
      const syncStatus = data?.data?.syncStatus
      const isSyncInProgress = syncStatus === ExternalInvoicesSyncStatus.InProgress

      setShouldRefetch(isSyncInProgress)
    },
    refetchInterval: shouldRefetch ? syncRefetchInterval : false,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  })

  return { syncData: data?.data, ...rest }
}
