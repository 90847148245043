import { SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'
import cc from 'classcat'
import React, { ChangeEvent, MouseEvent, useRef } from 'react'
import { Box, BoxProps, Flex } from 'rebass'

import { Checkbox as CheckboxComponent } from '../Form'
import { TableCell } from './TableCell'
import { TableCellWrapper } from './TableCellWrapper'
import { TableColumn, TableRow } from './types'

const CheckboxWrapper = styled(Flex)`
  height: 100%;
  position: absolute;
  left: 6px;
  align-items: center;
  cursor: default;
`

const Checkbox = styled(CheckboxComponent)`
  margin: 0;

  label {
    margin: 0;
  }
`

type TableRowComponentProps<T extends TableRow> = Omit<BoxProps, 'onSelect'> & {
  columns: TableColumn<T>[]
  index: number
  isRowSelectionDisabled?: boolean
  onRowSelect?: (row: T, selected: boolean) => void
  onSelect?: (row: T, index: number) => void
  row: T
  rowStyles?: SerializedStyles
  selectable?: boolean
  totalLabelColumnName?: keyof T
  hoverable?: boolean
}

export const TableRowComponent = <T extends TableRow>({
  className,
  columns,
  hoverable,
  index,
  isRowSelectionDisabled,
  onRowSelect = () => null,
  onSelect,
  row,
  rowStyles,
  selectable,
  totalLabelColumnName,
  ...rest
}: TableRowComponentProps<T>) => {
  const checkboxWrapperRef = useRef<HTMLDivElement>(null)

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (selectable && checkboxWrapperRef.current?.contains(event.target as Node)) {
      return
    }

    onSelect && onSelect(row, index)
  }

  return (
    <Box
      as="tr"
      key={row.id}
      onClick={handleClick}
      css={rowStyles}
      className={cc([className, { hoverable }])}
      data-row-id={row.id}
      {...rest}
    >
      {selectable && (
        <CheckboxWrapper as="td" ref={checkboxWrapperRef}>
          <Checkbox
            onChange={(event: ChangeEvent<HTMLInputElement>) => onRowSelect(row, event.target.checked)}
            disabled={isRowSelectionDisabled}
          />
        </CheckboxWrapper>
      )}
      {columns.map((column: TableColumn<T>, index: number) => (
        <TableCellWrapper row={row} key={`${row.id}-${index}`}>
          <TableCell<T>
            row={row}
            field={column.field}
            render={column.render}
            type={column.type}
            customStyle={column.customStyle}
            truncate={column.truncate}
            isTotalLabel={totalLabelColumnName === column.field}
          />
        </TableCellWrapper>
      ))}
    </Box>
  )
}
