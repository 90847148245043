import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from '../../../types/theme'
import * as Styled from './styles'

interface FileErrorThumbnailProps {
  title?: string
}

export const FileErrorThumbnail = ({ title }: FileErrorThumbnailProps) => {
  const theme = useTheme<Theme>()
  const { t } = useTranslation()

  return (
    <Styled.FileErrorWrapper>
      <Styled.FileErrorIcon
        color={theme.colors.warning}
        title={title || t('attachments.thumbnail_error.broken_image')}
      />
    </Styled.FileErrorWrapper>
  )
}
