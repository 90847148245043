import { AnyAction } from 'redux'

import { BillLine } from '@views/bills'

import { Side } from '../../enums/side'
import { APIError } from '../../utils'
import {
  BANK_CONNECTION_RECEIVED,
  BANK_CONNECTION_REQUEST_FAILED,
  BANK_CONNECTION_REQUESTED,
  BANK_LINE_MATCH_REMOVED,
  BANK_LINE_MATCH_UPDATED,
  BANK_LINE_MATCHES_DELETED,
  BANK_LINE_SUBJECT_ASSOCIATION_CREATED,
  BANK_LINE_SUBJECT_ASSOCIATION_DELETE_REQUESTED,
  BANK_LINE_SUBJECT_ASSOCIATION_DELETED,
  BANK_LINE_SUBJECT_ASSOCIATION_UPDATED,
  BANK_LINE_UNGROUPED,
  BANK_LINES_AND_TRANSACTIONS_RECEIVED,
  BANK_LINES_AND_TRANSACTIONS_REQUESTED,
  BILLY_TRANSACTION_ADDED,
  CREATE_BILL_AND_RECONCILE,
  CREATE_INVOICE_AND_RECONCILE,
  DELETE_BANK_LINES,
  DIFFERENCE_TYPE_SELECTED,
  FILTERS_CHANGED,
  RECONCILE_MATCH,
  SELECT_DIFFERENCE_TYPE,
  UNGROUP_BANK_LINE,
  UNRECONCILE_MATCH,
} from './actions'

// --------------------------------------
// API
// --------------------------------------

export type GetApiResponse<ResponseProperties> = ResponseProperties & {
  meta: {
    statusCode: number
    success: boolean
    paging: {
      page: number
      pageSize: number
      pageCount: number
      total: number
      nextUrl?: string
      lastUrl?: string
    }
    time: number
  }
}

// --------------------------------------
// Module state
// --------------------------------------

export type BankReconciliationState = {
  // undefined if we have no information about the bank connection (it has not loaded), null if we do and there is no connection
  bankConnection?: BankConnection | null
  bankLineMatches: ExtendedBankLineMatch[]
  bankLines: BankLine[]
  bankLineSubjectAssociations: BankLineSubjectAssociation[]
  billyTransactions: BillyTransaction[]
  reconcileAllApprovedState: ReconcileAllApprovedStatePayload
  bulkReconcileState: BulkReconcileState
  filters: Filters
  isBalanceBarOpen?: boolean
  latestBankLineEntryDate?: string
  primoUltimoBalance: PrimoUltimoBalance | null
  reconcileAllResult: ReconcileAllResult | null
  // Loaders
  bankConnectionLoading: boolean
  bankConnectionResolved: boolean
  bankLinesLoading: boolean
  isTransactionDroppableAreaActive: boolean
  transactionsLoading: boolean
}

// --------------------------------------
// Basics
// --------------------------------------

export enum ReceiptState {
  MissingReceipt = 'missing_receipt',
  ReceiptUploaded = 'receipt_uploaded',
}

export type BankLine = {
  comments?: BankLineComment[]
  id: string
  groupId: string
  matchId: string
  entryDate: string
  description: string
  amount: number
  side: Side
  balance: number
  type: string
  accountId: string
  contraAccountId: string
  receiptState: ReceiptState | null
  provider: string
  externalId: null
}

export type ExtendedBankLine = BankLine & {
  signedAmount: number
  amountDifference: number
}

export type BankLineMatch = {
  id: string
  accountId: string
  entryDate: string
  amount: number
  side: 'debit' | 'credit'
  differenceType?: DifferenceType
  feeAccountId: null
  isApproved: boolean
  approvedTime: string
  lines: BankLine[]
  lineIds: string[]
  subjectAssociationIds: string[]
  // Custom
  description: string
  currencyId: string
}

export type BankLineComment = {
  message: string
  bankLineId: string
  userId: string
}

export type ExtendedBankLineMatch = BankLineMatch & {
  bankLines: BankLine[]
  matchedBillyTransactions: BillyTransaction[]
}

export type BillyTransaction = {
  id: string
  type: ReconcilablePostingType
  subtype: ReconcilablePostingSubType
  entryDate: string
  dueDate: string | null
  text: string | null
  accountId: string | null
  currencyId: string
  amount: number
  conversionCurrencyId: string
  convertedAmount: number
  side: 'debit' | 'credit'
  isBankMatched: boolean
  originatorType: string | null
  originatorId: string | null
  description: string
  contact: string | null
  sequenceNo: string | null
  orderNo: string | null
  state: 'draft' | 'approved'
}

export type BankLineSubjectAssociation = {
  id: string
  matchId: string
  subjectReference: string
}

export type ApprovedMatchResult = {
  id: string
  isApproved: boolean
}

export type ReconcileAllResult = {
  reconciled: number
  total: number
}

export enum NordicApiConnectionStatus {
  Connected = 'connected',
  ManualImport = 'manual_import',
  NeedsRenewal = 'needs_renewal',
}

interface Attachment {
  fileId: string
  organizationId: string
}

export type Bill = {
  id: string
  organizationId: string
  type: 'bill' | 'creditNote'
  createdTime: string
  approvedTime: string
  contactId: string
  contactName: string | null
  entryDate: string
  dueDate: string | null
  state: 'approved' | 'draft'
  voucherNo: string
  currencyId: string
  balance: number
  lineDescription: string
  suppliersInvoiceNo: string | null
  attachments: Attachment[]
  isBare?: boolean
  lines?: BillLine[]
  taxMode?: 'incl' | 'excl'
}

export type Invoice = {
  id: string
  organizationId: string
  type: 'bill' | 'creditNote'
  createdTime: string
  approvedTime: string
  contactId: string
  contactName: string | null
  entryDate: string
  dueDate: string | null
  state: 'approved' | 'draft'
  currencyId: string
  balance: number
  lineDescription: string
  orderNo: string
  invoiceNo: string
}

export type Posting = {
  id: string
  organizationId: string
  transactionId: string
  entryDate: string
  accountId: string
  text: string
  side: 'credit' | 'debit'
  amount: number
  currencyId: string
  isBankMatched: boolean
}

export type Transaction = {
  id: string
  originatorName: string
  originatorReference: string
  description: string
}

export type PostingWithTransaction = Posting & Omit<Transaction, 'id'>

// --------------------------------------
// Bank connection
// --------------------------------------

type ConnectionErrorCode = 'E_SESSION_EXPIRED' | 'E_LOGIN_FAILED' | 'E_INVALID_ACCOUNT' | string

export interface BankConnection {
  accountId: string
  accountName: string
  accountNo: string
  balance: number
  bankName: string
  createdTime: string
  errorCode?: ConnectionErrorCode
  lastPullTime: string
  organizationId: string
  referenceId: string
  type: string
  session?: BankConnectionSession
}

export type BankConnectionSession = {
  createdTime: string
  errorCode?: ConnectionErrorCode
  id: string
  label: string
  loginTokenExpiryTime: string
  organizationId: string
  renewalReminderTime: string | null
  subjectId: string
  updatedTime: string
}

export type BankConnectionRequested = {
  payload: {
    accountId: string
    organizationId: string
  }
  type: typeof BANK_CONNECTION_REQUESTED
}

export type BankConnectionRequestFailed = {
  payload: APIError
  type: typeof BANK_CONNECTION_REQUEST_FAILED
}

export type BankConnectionReceived = {
  payload: BankConnection | null
  type: typeof BANK_CONNECTION_RECEIVED
}

export type GetBankLineMatchesResponse = GetApiResponse<{
  bills?: Bill[]
  invoices?: Invoice[]
  bankLines: BankLine[]
  bankLineMatches: BankLineMatch[]
  bankLineSubjectAssociations?: BankLineSubjectAssociation[]
}>

export type GetPostingsResponse = GetApiResponse<{
  postings: Posting[]
  transactions: Transaction[]
}>

// --------------------------------------
// CRUD bank and Billy transactions
// --------------------------------------

export type BankLinesAndTransactionsRequested = {
  payload: boolean | undefined
  type: typeof BANK_LINES_AND_TRANSACTIONS_REQUESTED
}

export type BankLinesAndTransactionsReceived = {
  payload: any
  type: typeof BANK_LINES_AND_TRANSACTIONS_RECEIVED
}

export type BankLineMatchRemoved = {
  payload: string
  type: typeof BANK_LINE_MATCH_REMOVED
}

export type BankLineMatchUpdated = {
  payload: ExtendedBankLineMatch
  type: typeof BANK_LINE_MATCH_UPDATED
}

export type BankLineSubjectAssociationCreated = {
  payload: BankLineSubjectAssociation
  type: typeof BANK_LINE_SUBJECT_ASSOCIATION_CREATED
}

export type BankLineSubjectAssociationUpdated = {
  payload: BankLineSubjectAssociation
  type: typeof BANK_LINE_SUBJECT_ASSOCIATION_UPDATED
}

export type BankLineSubjectAssociationDeleted = {
  payload: string
  type: typeof BANK_LINE_SUBJECT_ASSOCIATION_DELETED
}

export type DeleteBankLineSubjectAssociationAction = {
  payload: { bankLineSubjectAssociation: BankLineSubjectAssociation; bankLineMatch: ExtendedBankLineMatch }
  type: typeof BANK_LINE_SUBJECT_ASSOCIATION_DELETE_REQUESTED
}

export type BillyTransactionAdded = {
  payload: BillyTransaction
  type: typeof BILLY_TRANSACTION_ADDED
}

// --------------------------------------
// Group / ungroup transactions
// --------------------------------------

export type UngroupBankLine =
  | AnyAction
  | {
      payload: {
        accountId: string
        lineId: string
        matchId: string
        amount: number
        entryDate: string
        isApproved: boolean
        side: string
      }
      type: typeof UNGROUP_BANK_LINE
    }

export type BankLineMatchesDeleted =
  | AnyAction
  | {
      payload: string[]
      type: typeof BANK_LINE_MATCHES_DELETED
    }

export type BankLineUngrouped = {
  payload: {
    newBankLineMatch: ExtendedBankLineMatch
    ungroupedBankLine: BankLine
    previousBankLineMatchId: string
  }
  type: typeof BANK_LINE_UNGROUPED
}

// --------------------------------------
// Filter bank and Billy transactions
// --------------------------------------

export type FiltersChanged = {
  payload: Partial<Filters>
  type: typeof FILTERS_CHANGED
}

export type Filters = {
  bankLineGroupFilters: BankLineGroupFilters
  bankLineGroupSearchValue: string
  dateRange: number[]
  fiscalYear: number
  reconcilablePostingFilters: ReconcilablePostingFilters
  reconcilablePostingSearchValue: string
  bankLinesSortDirection: number
  bankLinesSortProperty: 'entryDate' | 'sideAmount'
  transactionsPostingLimit: number
  transactionsSortDirection: number
  transactionsSortProperty: 'entryDate' | 'sideAmount'
}

export type BankLineGroupFilters = {
  showReconciled: boolean
  showMatched: boolean
  showManual: boolean
}

export enum ReconcilablePostingType {
  Bill = 'bill',
  BillPayment = 'billPayment',
  DaybookTransaction = 'daybookTransaction',
  Invoice = 'invoice',
  InvoicePayment = 'invoicePayment',
  SalesTaxPayment = 'salesTaxPayment',
  SalesTaxReturn = 'salesTaxReturn',
}

export enum ReconcilablePostingSubType {
  Invoice = 'invoice',
  Bill = 'bill',
  SalesTaxReturn = 'salesTaxReturn',
  Posting = 'posting',
}

export type ReconcilablePostingFilters = {
  showDrafts: boolean
  types: ReconcilablePostingType[]
}

// --------------------------------------
// Balance bar
// --------------------------------------

export type PrimoUltimoBalance = {
  bankBalance?: number
  bankEndBalance: number
  bankStartBalance: number
  billyBalance?: number
  billyEndBalance: number
  billyStartBalance: number
}

// --------------------------------------
// Difference
// --------------------------------------

export type DifferenceType = 'overpayment' | 'underpayment' | 'bankFee' | 'exchange_rate'

export type Difference = {
  amount: number
  differenceAmount?: number
  types: DifferenceType[]
}

export type DifferenceTypePayload = {
  type: DifferenceType
  matchId: string
}

export type SelectDifferenceTypeAction = {
  payload: DifferenceTypePayload
  type: typeof SELECT_DIFFERENCE_TYPE
}

export type DifferenceTypeSelectedAction = {
  payload: DifferenceTypePayload
  type: typeof DIFFERENCE_TYPE_SELECTED
}

// --------------------------------------
// Reconciliation
// --------------------------------------

export type ReconcileMatchAction = {
  payload: string
  type: typeof RECONCILE_MATCH
}

export type ReconcileMatchPayload = string

export type UnreconcileMatchAction = {
  payload: string
  type: typeof UNRECONCILE_MATCH
}

export type UnreconcileMatchPayload = string

export type BulkReconcilePayload = {
  bulkReconcile: {
    [key: string]: {
      accountId: string
      taxRateId: string
    }
  }
}

export type BulkReconcileState = {
  [key: string]: boolean
}

export type ReconcileAllApprovedStatePayload = {
  reconciling: boolean
  total?: number
  processed?: number
  successful?: number
}

export type CreateInvoiceAndReconcilePayload = {
  bankLineMatch: ExtendedBankLineMatch
  contactId: string
  currencyId: string
  description: string
  organizationId: string
  productId: string
}

export type CreateInvoiceAndReconcileAction = {
  payload: CreateInvoiceAndReconcilePayload
  type: typeof CREATE_INVOICE_AND_RECONCILE
}

export type CreateBillAndReconcilePayload = {
  accountId: string
  bankLineMatch: ExtendedBankLineMatch
  contactId: string
  currencyId: string
  description: string
  organizationId: string
  taxRateId: string
  voucherNo: string
}

export type CreateBillAndReconcileAction = {
  payload: CreateBillAndReconcilePayload
  type: typeof CREATE_BILL_AND_RECONCILE
}

// --------------------------------------
// Bulk line operations
// --------------------------------------

export type DeleteBankLinesPayload = {
  matchIds: string[]
}

export type DeleteBankLinesAction = {
  payload: DeleteBankLinesPayload
  type: typeof DELETE_BANK_LINES
}

// --------------------------------------
// Query params
// --------------------------------------

export type BankReconcileSortProps = 'entryDate' | 'sideAmount'

export type BankReconcileQueryParams = {
  fiscalYear?: string
  bankLinesSortProperty?: BankReconcileSortProps
  bankLinesSortDirection?: string
  transactionsSortProperty?: BankReconcileSortProps
  transactionsSortDirection?: string
}

// --------------------------------------
// Bill Reconciliation Response
// --------------------------------------

export interface ReconciliationTransaction {
  amount: string
  side: Side
  // eslint-disable-next-line @typescript-eslint/naming-convention
  bank_date: string
  description: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  account_id: string
  currency: string
  id: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  match_id: string
}

export interface ReconciliationSuggestion {
  reconcilables: Bill[] | Invoice[]
  transactions: ReconciliationTransaction[]
  id: string
}

export interface ReconciliationSuggestionsResponse {
  suggestions: ReconciliationSuggestion[]
}
