import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { Spacing } from '../../../../enums/spacing'
import { Asable } from '../../../../types/asable'

interface HeaderProps extends Asable {
  stickyTopOffset?: number
}

export const Header = styled.li<HeaderProps>`
  display: block;
  padding: ${Spacing.L} ${Spacing.L} ${Spacing.S};
  position: sticky;
  top: ${({ stickyTopOffset = 0 }) => stickyTopOffset}px;
  background-color: ${Color.White};
`
