import React, { ReactElement } from 'react'

export const CheckIcon = (): ReactElement => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58928 0.41106C9.9147 0.736484 9.9147 1.2641 9.58928 1.58952L4.08928 7.08952C3.76386 7.41495 3.23624 7.41495 2.91082 7.08952L0.410816 4.58952C0.0853921 4.2641 0.0853921 3.73648 0.410816 3.41106C0.73624 3.08564 1.26386 3.08564 1.58928 3.41106L3.50005 5.32183L8.41082 0.41106C8.73624 0.0856362 9.26386 0.0856362 9.58928 0.41106Z"
        fill="#002E33"
      />
    </svg>
  )
}
