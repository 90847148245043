import uniqueId from 'lodash/uniqueId'
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useForm } from '../Form'
import { useRadioGroup } from '../RadioGroup'
import * as Styled from './styles'
import { Size } from './types/size'

export interface RadioProps {
  autoFocus?: boolean
  checked?: boolean
  children?: ReactNode
  className?: string
  defaultChecked?: boolean
  disabled?: boolean
  error?: boolean
  name?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  size?: Size
  value?: string
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      autoFocus = false,
      checked,
      children,
      className,
      defaultChecked,
      disabled,
      error = false,
      name,
      onChange,
      size = 'm',
      value,
      ...rest
    },
    forwardedRef,
  ) => {
    const {
      disabled: groupDisabled,
      name: groupName,
      value: groupValue,
      onChange: onGroupChange,
    } = useRadioGroup() || {}

    const isCheckedByGroup = groupValue !== undefined && groupValue === value
    const [checkedValue, setCheckedValue] = useState<boolean | undefined>(
      defaultChecked ?? (checked || isCheckedByGroup),
    )
    const { name: formName } = useForm()
    const nativeRadioRef = useRef<HTMLInputElement>(null)

    const id = useMemo(() => `${formName}-${uniqueId('radio-')}-${value}`, [formName, value])
    const radioGroupName = useMemo(() => `${formName}-${groupName || name}`, [formName, groupName, name])
    const isDisabled = !!(disabled ?? groupDisabled)

    // Functions

    const handleValueChange = useCallback((newCheckedValue: boolean) => {
      setCheckedValue(newCheckedValue)
    }, [])

    const handleRadioChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
          return
        }

        setCheckedValue(event.currentTarget.checked)
        onGroupChange && onGroupChange(event)
        onChange && onChange(event)
      },
      [disabled, onChange, onGroupChange],
    )

    // Mutations

    useEffect(() => {
      if (autoFocus) {
        nativeRadioRef.current?.focus()
      }
    }, [autoFocus])

    useEffect(() => {
      if (checked !== undefined) {
        handleValueChange(checked)
      }
    }, [checked, handleValueChange])

    useEffect(() => {
      if (groupValue) {
        handleValueChange(groupValue === value)
      }
    }, [handleValueChange, groupValue, value])

    // Hooks utils

    useImperativeHandle(forwardedRef, () => nativeRadioRef?.current as HTMLInputElement)

    return (
      <Styled.RadioWrapper
        checked={!!checkedValue}
        className={className}
        disabled={isDisabled}
        error={error}
        size={size}
      >
        <Styled.NativeRadio
          {...rest}
          checked={checkedValue}
          disabled={isDisabled}
          id={id}
          name={radioGroupName}
          onChange={handleRadioChange}
          ref={nativeRadioRef}
          type="radio"
          value={value}
        />
        <Styled.Control size={size} withTopOffset={!!children} data-testid="radio-control" />

        {children && (
          <Styled.Label id={id} selectable={false}>
            {children}
          </Styled.Label>
        )}
      </Styled.RadioWrapper>
    )
  },
)
