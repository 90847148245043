import { differenceInYears, endOfYear, getYear, startOfYear } from 'date-fns'

import { NavItem } from '../../NavList'

const TODAY = new Date()

const generateYears = (minDate = TODAY, maxDate = TODAY) => {
  const yearsBack = Math.abs(differenceInYears(startOfYear(minDate), startOfYear(TODAY)))
  const yearsNext = Math.abs(differenceInYears(endOfYear(maxDate), endOfYear(TODAY)))
  const currentYear = getYear(TODAY)
  const years = []

  for (let year = currentYear - yearsBack; year <= currentYear + yearsNext; year++) {
    years.push(year)
  }

  return years
}

export const getYearsItems = (minDate?: Date, maxDate?: Date): NavItem<number>[] => {
  const yearsList = generateYears(minDate, maxDate)

  return yearsList.map((year) => ({
    id: String(year),
    children: String(year),
    value: year,
  }))
}
