import { EmptyView, emptyViewVariants } from '@components'
import { ModuleLayout } from '@design-system'

import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { Scope } from '../../../../enums/scope'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { useTransactionsEmberConnection } from '../../contexts/transactionsEmberConnectionContext'
import { TransactionRoute } from '../../enums/transactionRoute'
import { useIsTransactionsListEmpty } from '../TransactionsList/hooks/useIsTransactionsListEmpty'
import {
  defaultTransactionsQuery,
  useTransactionsListFilters,
} from '../TransactionsList/hooks/useTransactionsListFilters'

export const TransactionsListEmpty = (): ReactElement => {
  const { t } = useTranslation()
  const [, setFilters] = useTransactionsListFilters()
  const isTransactionsListEmpty = useIsTransactionsListEmpty()
  const { handleAddTransaction } = useTransactionsEmberConnection()

  const shouldSeeCreateButton = isAuthorized(Scope.InvoiceWrite)

  // Clear filters in case they left after redirecting from `TransactionsList`
  useEffect(() => {
    setFilters(defaultTransactionsQuery)
  }, [setFilters])

  const { animation, description, title, buttonText } = emptyViewVariants.transactions

  const handleEmptyViewClick = useCallback(() => {
    handleAddTransaction()
  }, [handleAddTransaction])

  if (!isTransactionsListEmpty) {
    return <Redirect to={TransactionRoute.List} />
  }

  return (
    <ModuleLayout title={t('transactions')}>
      <EmptyView
        animation={animation}
        onClick={handleEmptyViewClick}
        showCTA={shouldSeeCreateButton}
        tButton={buttonText}
        tDescription={description}
        tTitle={title}
      />
    </ModuleLayout>
  )
}
