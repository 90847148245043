import { css } from '@emotion/core'

export const getBorderStyle = (skipLeftRounding?: boolean, skipRightRounding?: boolean, fullRound?: boolean) => css`
  /* The specific borderRadius is set in 'getSizeStyle.ts' util */
  ${fullRound &&
  css`
    border-radius: 50%;
  `}

  ${skipLeftRounding &&
  css`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `}

  ${skipRightRounding &&
  css`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `}
`
