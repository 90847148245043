import React, { useCallback, useEffect, useRef, useState } from 'react'

export const Handle = ({ handle: { id, percent, value }, getHandleProps, formatShortDate }: any) => {
  const [isHovering, setIsHovering] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const handleRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = useCallback(() => {
    setIsDragging(true)
  }, [])

  const handleMouseUp = useCallback(() => {
    setIsDragging(false)
  }, [])

  useEffect(() => {
    if (handleRef.current) {
      const handle: HTMLDivElement = handleRef.current
      handle.addEventListener('mousedown', handleMouseDown)

      return () => {
        handle.removeEventListener('mousedown', handleMouseDown)
      }
    }
  }, [isDragging, handleMouseDown])

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, handleMouseUp])

  return (
    <div
      ref={handleRef}
      role="none"
      onMouseDown={(_event) => handleMouseDown()}
      className={`date-range-slider-handle ${isDragging && 'dragging'}`}
      style={{ left: `${percent}%` }}
      {...getHandleProps(id, {
        onMouseEnter: (_e: any) => setIsHovering(true),
        onMouseLeave: (_e: any) => setIsHovering(false),
      })}
    >
      {isHovering && (
        <div className="date-range-slider-tooltip">
          {id === '$$-0' ? formatShortDate(value) : formatShortDate(value - 1)}
        </div>
      )}
    </div>
  )
}
