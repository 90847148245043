import { useQuery } from 'react-query'

import { fetchAccounts } from '@modules-deprecated/app/accounts/query-api'

import { QueryKeys } from '../../../enums/queryKeys'

interface UseFindBankAccountIdProps {
  account?: string
  organizationId: string
}

// use accountId from Ember or pick up the first bank account in the list
export const useFindBankAccountId = ({ account, organizationId }: UseFindBankAccountIdProps) => {
  const { data } = useQuery([QueryKeys.Accounts, organizationId], () => fetchAccounts(organizationId), {
    enabled: !!organizationId && !account,
  })

  const bankAccount = data?.accounts.find(({ isBankAccount }) => !!isBankAccount)
  const currentAccount = account || bankAccount?.id || ''
  const accountCurrency = bankAccount?.currencyId || 'DKK'

  return { accountCurrency, accountId: currentAccount }
}
