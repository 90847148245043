import { Link } from '@design-system'

import { useCallback } from 'react'

import { useEmberRouter } from '../../../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../../../enums/emberRoute'

interface InvoiceTransactionLinkProps {
  transactionId: string
  transactionNo: number | null
}

export const InvoiceTransactionLink = ({ transactionId, transactionNo }: InvoiceTransactionLinkProps) => {
  const { navigate } = useEmberRouter()

  const handleTransactionLinkClick = useCallback(() => {
    navigate(EmberRoute.Transaction, transactionId)
  }, [navigate, transactionId])

  return (
    <>
      <Link onClick={handleTransactionLinkClick} withAnimation>
        {transactionNo}
      </Link>{' '}
    </>
  )
}
