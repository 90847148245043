import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Color } from '../../enums/color'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { zIndex } from '../../utils/zIndex'

interface DropzoneFullScreenWrapperProps {
  active?: boolean
}

export const DropzoneFullScreenWrapper = styled.div<DropzoneFullScreenWrapperProps>`
  opacity: ${({ active }) => (active ? 1 : 0)};
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.DropzoneFullScreen};
  background-color: ${transparentize(0.6, Color.Gray200)};
  pointer-events: ${({ active }) => (active ? 'all' : 'none')};
  ${getDefaultTransition('opacity')};
`

export const ContentWrapper = styled.div`
  margin: auto;
  pointer-events: none; // needed for drag and drop to work correcly, otherwise event can't track it when files are over this wrapper
`
