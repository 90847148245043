import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const AddPaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: ${Spacing.M};
`
