import { Flex, Text } from '@design-system'

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface PaymentIntervalSwitchProps {
  disabled?: boolean
  isInitiallyChecked?: boolean
  togglePaymentFrequency: () => void
}

export const PaymentIntervalSwitchComponent = ({
  disabled,
  isInitiallyChecked,
  togglePaymentFrequency,
}: PaymentIntervalSwitchProps) => {
  const { t } = useTranslation()

  return (
    <Flex justifyContent="center">
      <Text variant="small" colorVariant="secondary">
        {t('organization_subscription.monthly')}
      </Text>
      <Styled.PriceFlipSwitch checked={isInitiallyChecked} onChange={togglePaymentFrequency} disabled={disabled} />
      <Text variant="small" colorVariant="secondary">
        {t('organization_subscription.yearly')}
      </Text>
    </Flex>
  )
}
