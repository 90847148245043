import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconCaretDown = ({ color = 'inherit', width = '20px', height = '20px', rotate, ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-caret-down')} {...rest}>
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 13"
      style={{
        transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.08896 4.08903C1.20761 3.97035 1.39999 3.97032 1.51867 4.08896L6.62667 9.19545L11.7313 4.08903C11.8499 3.97035 12.0423 3.97032 12.161 4.08896C12.2796 4.2076 12.2797 4.39998 12.161 4.51866L7.05631 9.62522C6.94235 9.73914 6.78781 9.80314 6.62667 9.80314C6.46553 9.80314 6.31099 9.73914 6.19703 9.62522L1.08903 4.51873C0.970349 4.40009 0.970321 4.20771 1.08896 4.08903Z"
        stroke={coloring(color)}
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  </Box>
)
