import { isValid, parseISO } from 'date-fns'

import { Iso8601RepeatingInterval, Iso8601RepeatingIntervalProps } from './Iso8601RepeatingInterval'

export const parseIso8601RepeatingInterval = function (value: string) {
  const initialProps: Iso8601RepeatingIntervalProps = {
    isValid: false,
    durationCount: undefined,
    durationUnit: undefined,
    end: undefined,
    repetitions: undefined,
    start: undefined,
  }

  if (typeof value !== 'string') {
    return new Iso8601RepeatingInterval(initialProps)
  }

  const match = value.match(/^R(\d*)\/([^/]+)\/P(\d+)([a-z])$/i)

  if (!match) {
    return new Iso8601RepeatingInterval(initialProps)
  }

  const repetitions = match[1]
  const start = match[2]
  const durationCount = match[3]
  const durationUnit = match[4]

  let formattedRepetitions: number | undefined

  if (repetitions) {
    formattedRepetitions = parseInt(repetitions, 10)
  } else {
    formattedRepetitions = undefined
  }

  if (!isValid(parseISO(start))) {
    return new Iso8601RepeatingInterval(initialProps)
  }

  const formattedDurationCount = parseInt(durationCount, 10)
  const formattedDurationUnit = durationUnit.toUpperCase()
  if (['Y', 'M', 'W', 'D'].indexOf(formattedDurationUnit) === -1) {
    return new Iso8601RepeatingInterval(initialProps)
  }

  const props: Iso8601RepeatingIntervalProps = {
    isValid: true,
    durationCount: formattedDurationCount,
    durationUnit: formattedDurationUnit,
    repetitions: formattedRepetitions,
    start,
  }

  return new Iso8601RepeatingInterval(props)
}
