import { useMutation } from 'react-query'

import { deleteOrganizationUserMember, useUserOrganization } from '@modules-deprecated/app/organization'

import { APIError } from '../../../../../utils'

interface UseDeleteUserArgs {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useDeleteUser = ({ onError, onSuccess }: UseDeleteUserArgs = {}) => {
  const { organization } = useUserOrganization()
  const { isLoading: isSaving, mutate: deleteUser } = useMutation(
    (userId: string) => deleteOrganizationUserMember(organization?.id || '', userId),
    {
      onError,
      onSuccess,
    },
  )

  return {
    deleteUser,
    isSaving,
  }
}
