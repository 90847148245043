import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { Role } from '../types/role'
import { RoleValue } from '../types/roleValue'
import { getPriceForRole } from './getPriceForRole'
import { getRoleToRoleName } from './getRoleToRoleName'

export const getItems = (roles: Role[], t: TFunction, subscriptionProductPlan: string): NavItem<RoleValue>[] => {
  const roleToRoleName = getRoleToRoleName(t)

  return roles.map((role) => {
    const price = getPriceForRole(role, subscriptionProductPlan)
    const displayValue = `${roleToRoleName[role]}${price ? ` (${price})` : ''}`

    return {
      id: role,
      value: {
        role,
        displayData: {
          title: roleToRoleName[role],
          description: price,
        },
      },
      displayValue,
      children: roleToRoleName[role],
    } as NavItem<RoleValue>
  })
}
