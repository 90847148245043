import { BohrSupplier } from '../../../types/bohrSupplier'
import { Contact } from '../types/contact'

export const getContactPrefilledDataFromSupplier = (supplier: BohrSupplier): Partial<Contact> => {
  const {
    contact_id: contactId,
    name,
    phone,
    address_city: city,
    address_street: street,
    address_zip: zipCode,
    vat_no: vatNo,
  } = supplier

  return {
    ...(contactId ? { id: contactId } : {}),
    ...(name ? { name } : {}),
    ...(phone ? { phone } : {}),
    ...(zipCode ? { zipcodeText: zipCode } : {}),
    ...(city ? { cityText: city } : {}),
    ...(street ? { street } : {}),
    ...(vatNo ? { registrationNo: vatNo } : {}),
  }
}
