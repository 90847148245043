import qs from 'qs'

import { InvoiceLog } from '@views/invoices'

import { SortDirection } from '../../enums/sortDirection'
import { Metable } from '../../types/metable'
import { getRequest } from '../../utils'

export interface FetchInvoiceLogsOptions {
  invoiceId: string
  organizationId: string
  sortProperty?: 'createdTime' | 'eventTime'
  sortDirection?: SortDirection
}

export interface FetchInvoiceLogsResponseData extends Metable {
  invoiceLogs: InvoiceLog[]
}

export const fetchInvoiceLogs = ({
  invoiceId,
  organizationId,
  sortProperty,
  sortDirection,
}: FetchInvoiceLogsOptions): Promise<FetchInvoiceLogsResponseData> => {
  const queryParams = qs.stringify({
    invoiceId,
    organizationId,
    ...(sortProperty ? { sortProperty } : {}),
    ...(sortDirection ? { sortDirection } : {}),
  })

  return getRequest(`/v2/invoiceLogs?${queryParams}`)
}
