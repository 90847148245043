import { ExportReportFilterOptions } from '../elements/ExportReport'

export const getReportPeriodForEvents = (options: ExportReportFilterOptions): string | undefined => {
  let period = options.period
  // Remove organization id from the name of the event (should be fiscalyear:YYYY, instead of fiscalyear:ID,YYYY)
  if (period?.startsWith('fiscalyear')) {
    period = `fiscalyear:${period?.slice(-4)}`
  }
  return period
}
