import { PurchaseCard } from '@components'

import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

import { useSubscriptionPlanPrices } from '../../../../hooks/useSubscriptionPlanPrices'
import { useSelectPlan } from '../../contexts/planSelectionContext'
import { useIsCurrentPlan } from '../../hooks/useIsCurrentPlan'
import { getPlanFeatures } from '../../utils/getPlanFeatures'
import { PaymentIntervalSwitchComponent } from '../PaymentIntervalSwitchComponent'

interface BasicPlanCardProps {
  plan: SubscriptionPlan
}

export const BasicPlanCard = ({ plan }: BasicPlanCardProps): ReactElement => {
  const { t } = useTranslation()
  const [isYearlyPaymentChecked, setYearlyPaymentChecked] = useState(true)
  const { selectPlan } = useSelectPlan()
  const { isCurrentPlan, isCurrentPlanOnYearlyPayment } = useIsCurrentPlan(plan)
  const { monthlyPrice, yearlyPricePerMonth, isLoading: isLoadingPrices } = useSubscriptionPlanPrices(plan)

  const handleUpgradePlanButtonClick = useCallback(() => {
    const periodSelected = isYearlyPaymentChecked ? SubscriptionPeriod.Yearly : SubscriptionPeriod.Monthly

    selectPlan(plan, periodSelected)
  }, [isYearlyPaymentChecked, selectPlan, plan])

  useEffect(() => {
    if (isCurrentPlan) {
      setYearlyPaymentChecked(isCurrentPlanOnYearlyPayment)
    }
  }, [isCurrentPlan, isCurrentPlanOnYearlyPayment])

  return (
    <PurchaseCard>
      <PurchaseCard.Header
        description={t('subscription.plan.basic_paid.description')}
        title={t('organization_subscription.basic_paid')}
      />
      {isCurrentPlan ? (
        <PurchaseCard.Button disabled>{t('subscription.plan.current')}</PurchaseCard.Button>
      ) : (
        <PurchaseCard.Button onClick={handleUpgradePlanButtonClick}>
          {t('subscription.plan.upgrade_to_basic')}
        </PurchaseCard.Button>
      )}
      <PurchaseCard.PriceSection
        isLoading={isLoadingPrices}
        price={isYearlyPaymentChecked ? yearlyPricePerMonth : monthlyPrice}
        priceSuffix={t('organization_subscription.plans.price_suffix')}
        pricePeriodNode={
          <PaymentIntervalSwitchComponent
            disabled={isCurrentPlan}
            isInitiallyChecked={isYearlyPaymentChecked}
            togglePaymentFrequency={() => setYearlyPaymentChecked(!isYearlyPaymentChecked)}
          />
        }
      />
      <PurchaseCard.Features
        header={t('subscription.plan.no_limitations')}
        items={getPlanFeatures(plan)}
        itemsLabel={t('subscription.plan.basic_paid.comparison_to_other_plans')}
      />
    </PurchaseCard>
  )
}
