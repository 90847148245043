import { TableActionsWrapper } from '@components'

import React, { ReactElement } from 'react'

import { FailedEInvoicesTag } from '../FailedEInvoicesTag'
import { InvoicesContactFilter } from '../InvoicesContactFilter'
import { InvoicesPeriodSelector } from '../InvoicesPeriodSelector'
import { InvoicesSearch } from '../InvoicesSearch'
import { InvoicesTitle } from '../InvoicesTitle'

export const InvoicesTableActions = (): ReactElement => {
  return (
    <TableActionsWrapper>
      <InvoicesTitle />
      <InvoicesSearch />
      <FailedEInvoicesTag />
      <InvoicesContactFilter />
      <InvoicesPeriodSelector />
    </TableActionsWrapper>
  )
}
