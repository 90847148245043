import { Link, Space, Text } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface LinkToEmberManualPaymentProps {
  onLinkClick: (isWrongMatch?: boolean) => void
}
// when enabling Bank Lines, this component should be removed
export const LinkToEmberManualPayment = ({ onLinkClick }: LinkToEmberManualPaymentProps): ReactElement => {
  const { t } = useTranslation()

  const handleLinkClick = useCallback(() => {
    onLinkClick(false)
  }, [onLinkClick])

  return (
    <>
      <Space />
      <Text colorVariant="secondary">
        {t('bill.payment_reconcile.not_the_right_match')}{' '}
        <Text as="span">
          <Link onClick={handleLinkClick}>{t('bill.payment_reconcile.enter_without_reconciling')}</Link>
        </Text>
      </Text>
    </>
  )
}
