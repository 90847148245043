export const indexValueToDisplayValue = (indexValue?: number): string => {
  if (indexValue === undefined) {
    return ''
  }

  //  Make 0 -> 1
  const realValue = indexValue + 1
  // Make 1 -> 01
  return realValue < 10 ? ('0' + realValue).slice(-2) : String(realValue)
}
