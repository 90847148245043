import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SalarySignupFormWrapper = styled.div``

export const TitleWrapper = styled.header`
  margin-bottom: ${Spacing.L};
`

export const Form = styled.form``

export const AddressGroup = styled.div`
  display: flex;
`

export const ZipCodeField = styled.div`
  width: 76px;
  margin-right: ${Spacing.S};
  flex-shrink: 0;
`

export const CityField = styled.div`
  flex-grow: 1;
`

export const Footer = styled.footer`
  margin-top: ${Spacing.XXL};
`

export const FooterInfo = styled.div`
  margin-top: ${Spacing.S};
`
