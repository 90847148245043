import React, { forwardRef, ReactElement, ReactNode, Ref } from 'react'

import { IconButton, IconButtonProps } from '../IconButton'
import { Text } from '../Text'
import * as Styled from './styles'
import { variantToTextColorVariant } from './utils/variantToTextColorVariant'

export interface IconButtonWithLabelProps extends IconButtonProps {
  children: ReactNode
}

export const IconButtonWithLabel = forwardRef(
  (
    { children, variant = 'text', ...iconButtonProps }: IconButtonWithLabelProps,
    forwardedRef: Ref<HTMLDivElement>,
  ): ReactElement => (
    <Styled.IconButtonWithLabelWrapper ref={forwardedRef}>
      <IconButton {...iconButtonProps} variant={variant} />
      <Text colorVariant={variantToTextColorVariant[variant]} variant="micro">
        {children}
      </Text>
    </Styled.IconButtonWithLabelWrapper>
  ),
)
