import { Umbrella } from '../umbrellas/types/umbrella'
import {
  IS_TRUSTED_PARTNER_RECEIVED,
  IS_TRUSTED_PARTNER_REQUESTED,
  ORGANIZATION_RECEIVED,
  ORGANIZATION_REQUESTED,
  ORGANIZATION_SETTINGS_RECEIVED,
  ORGANIZATION_SETTINGS_REQUESTED,
  ORGANIZATION_UMBRELLAS_RECEIVED,
  ORGANIZATION_UMBRELLAS_REQUESTED,
} from './actions'
import { Organization } from './types/organization'
import { OrganizationSettings } from './types/organizationSettings'

export type OrganizationRequestedAction = {
  payload: string
  type: typeof ORGANIZATION_REQUESTED
}

export type OrganizationReceivedAction = {
  payload: Organization
  type: typeof ORGANIZATION_RECEIVED
}

export type OrganizationSettingsRequestedAction = {
  payload: string
  type: typeof ORGANIZATION_SETTINGS_REQUESTED
}

export type OrganizationSettingsReceivedAction = {
  payload: OrganizationSettings
  type: typeof ORGANIZATION_SETTINGS_RECEIVED
}

export type OrganizationUmbrellasRequestedAction = {
  payload: string
  type: typeof ORGANIZATION_UMBRELLAS_REQUESTED
}

export type OrganizationUmbrellasReceivedAction = {
  payload: Array<Umbrella>
  type: typeof ORGANIZATION_UMBRELLAS_RECEIVED
}

export type IsTrustedPartnerRequestedAction = {
  payload: string
  type: typeof IS_TRUSTED_PARTNER_REQUESTED
}

export type IsTrustedPartnerReceivedAction = {
  payload: boolean
  type: typeof IS_TRUSTED_PARTNER_RECEIVED
}

export const organizationRequested = (organizationId: string): OrganizationRequestedAction => ({
  payload: organizationId,
  type: ORGANIZATION_REQUESTED,
})

export const organizationReceived = (organization: Organization): OrganizationReceivedAction => ({
  payload: organization,
  type: ORGANIZATION_RECEIVED,
})

export const organizationSettingsRequested = (organizationId: string): OrganizationSettingsRequestedAction => ({
  payload: organizationId,
  type: ORGANIZATION_SETTINGS_REQUESTED,
})

export const organizationSettingsReceived = (
  organizationSettings: OrganizationSettings,
): OrganizationSettingsReceivedAction => ({
  payload: organizationSettings,
  type: ORGANIZATION_SETTINGS_RECEIVED,
})

export const organizationUmbrellasRequested = (organizationId: string): OrganizationUmbrellasRequestedAction => ({
  payload: organizationId,
  type: ORGANIZATION_UMBRELLAS_REQUESTED,
})

export const organizationUmbrellasReceived = (umbrellas: Array<Umbrella>): OrganizationUmbrellasReceivedAction => ({
  payload: umbrellas,
  type: ORGANIZATION_UMBRELLAS_RECEIVED,
})

export const isTrustedPartnerRequested = (organizationId: string): IsTrustedPartnerRequestedAction => ({
  payload: organizationId,
  type: IS_TRUSTED_PARTNER_REQUESTED,
})

export const isTrustedPartnerReceived = (isTrustedPartner: boolean): IsTrustedPartnerReceivedAction => ({
  payload: isTrustedPartner,
  type: IS_TRUSTED_PARTNER_RECEIVED,
})
