import pick from 'lodash/pick'

export const getLocalStorageFiltersValidated = <T extends object>(
  objectToCompare: T,
  value: string | null,
): T | undefined => {
  if (!value) {
    return undefined
  }

  try {
    return pick(JSON.parse(value), Object.keys(objectToCompare)) as T
  } catch (error) {
    console.error('Cound not parse filters from localStorage!', error)
    return undefined
  }
}
