import { Icon, IconName, NavIconChildren } from '@design-system'

import React, { ReactElement } from 'react'

import { AvatarImage } from '../AvatarImage'
import * as Styled from './styles'

export interface AvatarIcon {
  icon?: IconName
}

export type AvatarProps = Partial<Pick<HTMLImageElement, 'alt' | 'src'>> & AvatarIcon

interface OrganizationAvatarProps extends NavIconChildren {
  alt?: string
  icon?: IconName
  src?: string
}

export const OrganizationAvatar = ({ icon, src, alt, color, ...rest }: OrganizationAvatarProps): ReactElement => (
  <Styled.OrganizationAvatarWrapper {...rest}>
    {src && <AvatarImage src={src} alt={alt} />}
    {!src && icon && <Icon icon={icon} color={color} />}
  </Styled.OrganizationAvatarWrapper>
)
