import { css } from '@emotion/core'
import React from 'react'
import { Flex, FlexProps } from 'rebass'

export type LineWrapperProps = FlexProps & {
  css?: string | any
  groupable?: boolean
  isDragging?: boolean
  isDraggingOver?: boolean
}

const groupableStyle = `
&:not(:first-of-type) {
  margin-top: -1px;
  border-top-style: dashed;
}`

export const LineWrapper = React.forwardRef(
  (
    { children, className, css: providedCSS, groupable, isDragging, isDraggingOver, ...rest }: LineWrapperProps,
    ref,
  ) => (
    <Flex
      className={className}
      ref={ref}
      css={css`
        position: relative;
        padding: 7px 10px;
        height: 30px;
        justify-content: flex-start;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #cfcfcf;
        ${isDragging ? 'border: 1px solid #CFCFCF !important;' : null}
        ${isDraggingOver && !isDragging ? 'border: 1px dashed #CFCFCF !important;' : null}
      background-color: ${isDraggingOver && !isDragging ? '#F5FBFF' : 'white'};
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
        transition: width 0.2s;
        &:hover {
          i {
            opacity: 1;
          }
        }
        ${groupable && groupableStyle}
        ${providedCSS}
      `}
      {...rest}
    >
      {children}
    </Flex>
  ),
)
