/**
 * Pauses asynchronously the execution of the wrapping function for the given milliseconds.
 *
 * Examples:
 * await wait(500) // in async functions
 * yield wait(500) // in sagas
 *
 * @param   delay number millisecond
 * @return  null
 */
export const wait = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay))
