import { Modal } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { SimpleActor } from '../../types/simpleActor'
import { CreateOrEditAccessTokenModalContextProvider } from './contexts/createOrEditAccessTokenModalContext'
import { ModalBody } from './elements/ModalBody'
import { ModalFooter } from './elements/ModalFooter'

interface CreateAccessTokenModalProps {
  actor?: SimpleActor
  onSuccessCreate: (actorId?: string) => void
}

export const CreateOrEditAccessTokenModal = ({ actor, onSuccessCreate }: CreateAccessTokenModalProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <CreateOrEditAccessTokenModalContextProvider actor={actor} onSuccessCreate={onSuccessCreate}>
      <Modal id={ModalId.CreateOrEditAccessTokenModal} closable size="s">
        <Modal.Header
          title={t(
            actor?.id
              ? 'settings.organization.access_tokens.create_or_edit_access_token_modal.edit'
              : 'settings.organization.access_tokens.create_or_edit_access_token_modal.create',
          )}
        />
        <Modal.Body>
          <ModalBody />
        </Modal.Body>
        <Modal.Footer>
          <ModalFooter />
        </Modal.Footer>
      </Modal>
    </CreateOrEditAccessTokenModalContextProvider>
  )
}
