import { amountToDisplayValue } from '@design-system'

import { useMemo } from 'react'

import { Quote } from '../../../types/quote'
import { QuotesTableAccessor } from '../enums/quotesTableAccessor'
import { QuotesTableRow } from '../types/quotesTableRow'

export const useQuotesTableData = (quotes: Quote[]): QuotesTableRow[] => {
  return useMemo(
    () =>
      quotes.map(({ grossAmount, lineDescription, netAmount, currencyCode, ...rest }) => {
        const grossAmountValue = `${amountToDisplayValue(grossAmount)} ${currencyCode}`
        const netAmountValue = `${amountToDisplayValue(netAmount)} ${currencyCode}`

        return {
          [QuotesTableAccessor.NetAmount]: netAmountValue,
          [QuotesTableAccessor.GrossAmount]: grossAmountValue,
          [QuotesTableAccessor.Description]: lineDescription,
          ...rest,
        }
      }),
    [quotes],
  )
}
