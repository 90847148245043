import { getDayOfYear } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { SpecificState } from '../../../types/reduxSaga-deprecated'
import { formatDateRangePeriod } from '../utils'

type FormattedFiscalDateRangeProps = {
  format?: string
}

const selectDateRangeContext = createSelector(
  (state: SpecificState) => state.bankReconciliation.filters.fiscalYear,
  (state: SpecificState) => state.bankReconciliation.filters.dateRange,
  (state: SpecificState) => state.app.organization?.fiscalYearEndMonth,
  (fiscalYear, dateRange, fiscalYearEndMonth) => ({
    fiscalYear: fiscalYear ?? new Date().getFullYear(),
    dateRange: dateRange?.length === 2 ? dateRange : [0, getDayOfYear(new Date())],
    fiscalYearEndMonth: fiscalYearEndMonth ?? 12,
  }),
)

export const FormattedFiscalDateRange = ({ format = 'MMM dd, yyyy' }: FormattedFiscalDateRangeProps) => {
  const { fiscalYear, dateRange, fiscalYearEndMonth } = useSelector((state: SpecificState) =>
    selectDateRangeContext(state),
  )
  const { from, to } = formatDateRangePeriod(fiscalYear, fiscalYearEndMonth, dateRange, format)

  return (
    <>
      {from} - {to}
    </>
  )
}
