import { IconCheck } from '@components-deprecated'
import { Color, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const StepWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${Spacing.M};
  align-items: center;

  &:last-of-type {
    ${() => Number} {
      &:after {
        display: none;
      }
    }
  }
`

interface NumberProps {
  isChecked: boolean
}

export const Number = styled.div<NumberProps>`
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: ${Spacing.M};
  border: 2px solid ${Color.Gray40};
  border-radius: 50%;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  ${({ isChecked }) =>
    isChecked &&
    `
    border-color: ${Color.Green};
    background-color: ${Color.Green10};
  `}

  &:after {
    content: '';
    width: 2px;
    height: 12px;
    margin-top: ${Spacing.XXXS};
    position: absolute;
    top: 100%;
    background: ${Color.Gray40};
  }
`

export const Content = styled(Text)``

export const Checkmark = styled(IconCheck)`
  fill: ${Color.Green};
`
