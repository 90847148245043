import { TFunction } from 'i18next'
import { object, SchemaOf, string } from 'yup'

export const inviteUserFormDefaultValues: InviteUserForm = {
  email: '',
  userRole: undefined,
}
export interface InviteUserForm {
  email: string
  userRole: string | undefined
}

export type InviteUserFormSchema = SchemaOf<InviteUserForm>

export const getValidationSchema = (t: TFunction): InviteUserFormSchema => {
  return object({
    email: string().required().email(t('settings.organization.users.invite_user_modal.validation.email')),
    userRole: string().optional(),
  })
}
