import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { useUmbrella } from '../../app/umbrellas'
import {
  fetchUmbrellaOrganizations,
  OrganizationsQueryParams,
  UmbrellaOrganizationsResponseData,
} from '../../app/umbrellas/query-api'

interface UseUmbrellaOrganizationsProps extends OrganizationsQueryParams {
  refetchOnWindowFocus?: boolean
}

export const useUmbrellaOrganizations = ({
  includeWorksOnOrganizationUser,
  isInboxEnabled,
  organizationStates,
  organizationStatesOperator,
  refetchOnWindowFocus = false,
  page,
  pageSize,
  q,
  sortDirection,
  sortProperty,
}: UseUmbrellaOrganizationsProps = {}) => {
  const { umbrella } = useUmbrella()

  const queryProps = useMemo(
    (): OrganizationsQueryParams => ({
      includeWorksOnOrganizationUser,
      isInboxEnabled,
      organizationStates,
      page,
      pageSize,
      q,
      sortDirection,
      sortProperty,
    }),
    [
      includeWorksOnOrganizationUser,
      isInboxEnabled,
      organizationStates,
      page,
      pageSize,
      q,
      sortDirection,
      sortProperty,
    ],
  )

  const { data, isLoading } = useQuery(
    [QueryKeys.UmbrellaOrganizations, umbrella?.id, queryProps],
    () =>
      fetchUmbrellaOrganizations(umbrella?.id || '', {
        ...(includeWorksOnOrganizationUser !== undefined ? { includeWorksOnOrganizationUser } : {}),
        ...(isInboxEnabled !== undefined ? { isInboxEnabled } : {}),
        ...(organizationStates !== undefined ? { organizationStates } : {}),
        ...(organizationStatesOperator !== undefined ? { organizationStatesOperator } : {}),
        ...(page && { page }),
        ...(pageSize && { pageSize }),
        ...(q && { q }),
        ...(sortDirection ? { sortDirection } : {}),
        ...(sortProperty ? { sortProperty } : {}),
      }),
    {
      enabled: !!umbrella,
      refetchOnWindowFocus,
    },
  )
  const { data: organizations, meta } = data || ({} as UmbrellaOrganizationsResponseData)

  return {
    isLoading,
    organizations,
    pagination: meta?.paging,
  }
}
