import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { BankIntegrationContextProvider } from '@views/bankIntegration/contexts/bankIntegrationContext'
import { ConnectBankButton } from '@views/bankIntegration/elements/ConnectBankButton/ConnectBankButton'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

class BankIntegrationButtonWebComponent extends DOMModel {
  @byAttrVal accountid: string
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('checkBankIntegration') checkBankIntegration: EmberEventFn | undefined
}

type BankIntegrationButtonModuleProps = {
  accountid: string
  onCheckBankIntegration: EmberEventFn
  onNavigate: EmberNavigate
  organizationId: string
}

function BankIntegrationButtonModule({
  accountid,
  onCheckBankIntegration,
  onNavigate,
  organizationId,
}: BankIntegrationButtonModuleProps): ReactElement {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <CurrentUserContextProvider>
              <EmberRouterContextProvider onNavigate={onNavigate}>
                <ModalContextProvider>
                  <AccountsContextProvider organizationId={organizationId}>
                    <BankIntegrationContextProvider
                      account={accountid}
                      onCheckBankIntegration={onCheckBankIntegration}
                      organizationId={organizationId}
                    >
                      <ConnectBankButton />
                    </BankIntegrationContextProvider>
                  </AccountsContextProvider>
                </ModalContextProvider>
              </EmberRouterContextProvider>
            </CurrentUserContextProvider>
          </UserOrganizationContextProvider>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const BankIntegrationButtonCustomElement = createCustomElement(
  BankIntegrationButtonModule,
  BankIntegrationButtonWebComponent,
  'element',
)

customElements.get('bank-integration-button') ||
  customElements.define('bank-integration-button', BankIntegrationButtonCustomElement)

export default BankIntegrationButtonCustomElement
