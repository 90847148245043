import debounce from 'lodash/debounce'
import { RefObject, useEffect } from 'react'

import { MODULE_LAYOUT_SCROLL_RESTORATION_KEY } from '../constants/moduleLayoutScrollRestorationKey'
import { clearAllScrollRestorationData } from '../utils/clearAllScrollRestorationData'

const SCROLL_DEBOUNCE_TIMEOUT_MS = 150

const getStorageKey = (currentPath: string) => `${MODULE_LAYOUT_SCROLL_RESTORATION_KEY}-${currentPath}`

interface UseScrollRestorationProps {
  isEnabled?: boolean
  scrollableElementRef: RefObject<HTMLDivElement>
}

export const useScrollRestoration = ({ isEnabled, scrollableElementRef }: UseScrollRestorationProps) => {
  // Save scroll position
  useEffect(() => {
    if (!isEnabled || !scrollableElementRef.current) {
      return
    }

    const scrollableElement = scrollableElementRef.current

    const handleContentScroll = debounce(() => {
      sessionStorage.setItem(getStorageKey(window.location.href), scrollableElement.scrollTop.toString())
    }, SCROLL_DEBOUNCE_TIMEOUT_MS)

    scrollableElement.addEventListener('scroll', handleContentScroll)

    return () => {
      scrollableElement.removeEventListener('scroll', handleContentScroll)
    }
  }, [isEnabled, scrollableElementRef])

  // Restore scroll position
  useEffect(() => {
    if (!isEnabled || !scrollableElementRef.current) {
      return
    }

    const scrollableElement = scrollableElementRef.current
    const savedScrollPosition = sessionStorage.getItem(getStorageKey(window.location.href))

    if (savedScrollPosition) {
      window.requestAnimationFrame(() => {
        scrollableElement.scrollTop = parseInt(savedScrollPosition, 10)
      })
    }
  }, [isEnabled, scrollableElementRef])

  // Clear scroll position before unload
  useEffect(() => {
    if (!isEnabled) {
      return
    }

    const handleBeforeunload = () => {
      clearAllScrollRestorationData()
    }

    window.addEventListener('beforeunload', handleBeforeunload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload)
    }
  }, [isEnabled])
}
