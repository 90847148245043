import { Input, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, SmartSelect } from '@components-deprecated'
import { Button } from '@design-system'

import { TFunction } from 'i18next'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InboxModalProps } from '../../types'

const getDiscardReasons = (t: TFunction) => [
  {
    id: 'DUPLICATE',
    text: t('voucher.inbox.modals.discard.reason_duplicate'),
  },
  {
    id: 'NOT_RELEVANT',
    text: t('voucher.inbox.modals.discard.reason_not_relevant'),
  },
]

type DiscardModalProps = InboxModalProps &
  ModalProps & {
    onConfirm?: (reason: DiscardReasonItem, message?: string) => void
  }

export type DiscardReasonItem = {
  id: string
  text: string
}

const initialDiscardReason = { id: '', text: '' }

export const DiscardModal = ({
  disabled,
  isOpen,
  onCancel = () => null,
  onConfirm = () => null,
  ...rest
}: DiscardModalProps) => {
  const { t } = useTranslation()
  const [reason, setReason] = useState<DiscardReasonItem>(initialDiscardReason)
  const [message, setMessage] = useState('')
  const discardReasons = useMemo(() => getDiscardReasons(t), [t])

  const reset = () => {
    setMessage('')
    setReason(initialDiscardReason)
  }

  useEffect(() => {
    reset()
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} {...rest} onClose={onCancel}>
      <ModalHeader heading={t('voucher.inbox.modals.discard.discard_voucher')} />
      <ModalBody>
        <SmartSelect
          label={t('voucher.inbox.modals.discard.reason')}
          name="reason"
          placeholder={t('voucher.inbox.modals.discard.select_reason')}
          items={discardReasons}
          onItemSelect={(reason) => setReason(reason as DiscardReasonItem)}
          value={reason?.text}
          disabled={disabled}
          required
        />
        <Input
          name="message"
          label={t('voucher.inbox.modals.moreinfo.message_label')}
          placeholder={t('voucher.inbox.modals.moreinfo.message_placeholder')}
          value={message}
          disabled={disabled}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setMessage(event.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button disabled={disabled} onClick={onCancel} variant="text">
          {t('voucher.inbox.modals.discard.cancel')}
        </Button>
        <Button danger disabled={!reason.id || disabled} onClick={() => onConfirm(reason, message)}>
          {t('voucher.inbox.modals.discard.discard')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
