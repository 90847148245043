import { css } from '@emotion/core'
import React, { forwardRef, Ref } from 'react'
import { useSelector } from 'react-redux'
import { Box, BoxProps } from 'rebass'

import { SpecificState } from '../../../types/reduxSaga-deprecated'
import { reactClass } from '../../../utils'

type DroppableHighlightProps = BoxProps & {
  highlightOnDraggingOver?: boolean
  isDraggingOver?: boolean
  draggingOverWith?: string | null
  draggingFromThisWith?: string | null
}

export const DroppableHighlight = forwardRef(
  (
    {
      draggingFromThisWith,
      draggingOverWith,
      highlightOnDraggingOver,
      isDraggingOver,
      ...rest
    }: DroppableHighlightProps,
    ref: Ref<HTMLDivElement | null>,
  ) => {
    const isRemotelyActivated = useSelector(
      (state: SpecificState) => state.bankReconciliation.isTransactionDroppableAreaActive,
    )

    const isActive =
      isRemotelyActivated || (highlightOnDraggingOver && isDraggingOver && draggingOverWith !== draggingFromThisWith)
    return (
      <Box
        className={reactClass('droppable-highlight')}
        css={css`
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 10px;
          border-radius: 4px;
          border: 1px dashed #cfcfcf;
          background-color: #f5fbff;
          opacity: ${isActive ? '1.0' : '0.0'};
          transition: all 0.2s;
        `}
        ref={ref}
        {...rest}
      />
    )
  },
)
