import { pxToNumber, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const DuplicateBillsTableWrapper = styled.div`
  margin-top: ${Spacing.XS};
  display: flex;
`

export const DuplicateBillsLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.XXXL};
  margin: ${pxToNumber(Spacing.XXL) + pxToNumber(Spacing.XXXL)}px ${Spacing.XL} 0;
`
