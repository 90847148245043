import React, { ReactElement } from 'react'

import { Color } from '../../../../enums/color'
import { Icon } from '../../../Icon'
import * as Styled from './styles'

interface ClearInputButtonProps {
  onClick?: () => void
  onMouseDown?: () => void
}

export const ClearInputButton = ({ onClick, onMouseDown, ...rest }: ClearInputButtonProps): ReactElement => (
  <Styled.ClearIconWrapper {...rest} onClick={onClick} onMouseDown={onMouseDown}>
    <Icon icon="xSignCircleSolid" color={Color.Gray60} />
  </Styled.ClearIconWrapper>
)
