import styled from '@emotion/styled'

import { getAnimateRotationStyle } from '../../../../utils/getAnimateRotationStyle'

export const LoadingIconWrapper = styled.figure`
  display: flex;
  width: 100%;
  justify-content: center;
  ${getAnimateRotationStyle()};
`
