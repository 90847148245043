import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { StyledComponent } from '../../../types/styledComponent'

export const getHoverStyle = (Control: StyledComponent, checked: boolean): SerializedStyles => css`
  &:hover {
    ${Control} {
      ${checked
        ? css`
            color: ${Color.Lavender};
            border-color: ${Color.DeepSeaGreen};
            background-color: ${Color.DeepSeaGreen};
          `
        : css`
            color: ${Color.DeepSeaGreen};
            border-color: ${Color.DeepSeaGreen};
            background-color: ${Color.Gray10};
          `};
    }
  }
`
