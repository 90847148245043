import { ModalConfirmation, ModalProps, useModal } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../enums/modalId'

interface ConfirmDeleteConnectionModalProps extends ModalProps {
  removeConnection: () => void
}

export const ConfirmDeleteConnectionModal = ({ removeConnection }: ConfirmDeleteConnectionModalProps) => {
  const { t } = useTranslation()
  const { close } = useModal(ModalId.BankConnectionRemoveConfirmationModal)

  const handleConfirmDeleteConnectionClick = useCallback(() => {
    removeConnection()
    close()
  }, [close, removeConnection])

  return (
    <ModalConfirmation
      danger
      id={ModalId.BankConnectionRemoveConfirmationModal}
      cancelLabel={t('cancel')}
      message={t('bank_connection.confirm_disconnect_info')}
      okLabel={t('bank_connection.confirm_disconnect_yes')}
      onOk={handleConfirmDeleteConnectionClick}
      title={t('bank_connection.confirm_disconnect_title')}
    />
  )
}
