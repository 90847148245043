import { notify } from '@design-system'

import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { getStartEndDateFromPeriod } from '../utils/getStartEndDateFromPeriod'
import { useFetchSalesWithoutVat } from './useFetchSalesWithoutVat'
import { useSalesTaxListFilters } from './useSalesTaxListFilters'

export const useSalesWithoutVat = () => {
  const { t } = useTranslation()
  const [{ period: queryParamPeriod }] = useSalesTaxListFilters()
  const startEndDates = getStartEndDateFromPeriod(queryParamPeriod)

  const { data, isLoading } = useFetchSalesWithoutVat({
    toDate: startEndDates?.toDate || '',
    fromDate: startEndDates?.fromDate || '',
    onError: (error) => {
      notify({
        id: NotificationKeys.SalesTaxSalesWithoutVat,
        message: t('sales_tax_returns.eu_sales_without_vat.error.list'),
        variant: 'error',
      })
      console.error(error)
    },
  })

  return { data, isLoading }
}
