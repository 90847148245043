import { useEffect, useState } from 'react'

type Callback = () => void

const preloadedSrcList: string[] = []

export const useLazyImage = (
  placeholderSrc: string,
  src: string,
  loadCallback?: Callback,
  errorCallback?: Callback,
) => {
  const isPreloaded = src && preloadedSrcList.indexOf(src) !== -1
  const [loading, setLoading] = useState(!isPreloaded)
  const [currentSrc, updateCurrentSrc] = useState(isPreloaded ? src : placeholderSrc)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (isPreloaded) {
      updateCurrentSrc(src)
      setLoading(false)
      loadCallback?.()
    } else {
      if (!src) {
        return
      }

      const imageToLoad = new Image()
      imageToLoad.src = src

      imageToLoad.onload = () => {
        preloadedSrcList.push(src)
        updateCurrentSrc(src)
        setLoading(false)
        loadCallback && loadCallback()
      }

      imageToLoad.onerror = () => {
        setIsError(true)
        setLoading(false)
        errorCallback && errorCallback()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  return { loading, src: currentSrc, isError }
}
