import React, { ReactElement } from 'react'

import { LayoutWithSideForm } from './elements/LayoutWithSideForm'
import { SalaryFeaturesList } from './elements/SalaryFeaturesList'
import { SalarySignupForm } from './elements/SalarySignupForm'

export const SalarySignup = (): ReactElement => (
  <LayoutWithSideForm sideForm={<SalarySignupForm />}>
    <SalaryFeaturesList />
  </LayoutWithSideForm>
)
