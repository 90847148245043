import React, { ReactElement } from 'react'

import { ReportHtml } from '../../elements/ReportHtml'
import { ReportsSkeleton } from '../../elements/ReportsSkeleton'
import { BalanceTableActions } from './elements/BalanceTableActions'
import { useBalance } from './hooks/useBalance'

export const Balance = (): ReactElement => {
  const { report, isLoading, isIdle } = useBalance()

  return (
    <>
      <BalanceTableActions />
      {isLoading || isIdle ? <ReportsSkeleton /> : <ReportHtml html={report?.html} />}
    </>
  )
}
