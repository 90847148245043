import isEqual from 'lodash/isEqual'
import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { NavItem } from '../../../NavList'
import { Text } from '../../../Text'

interface ButtonDropdownMultipleTextProps<T> {
  items: NavItem<T>[]
  label?: string
  selectedValues: T[]
}

export const ButtonDropdownMultipleText = <T,>({
  items,
  label,
  selectedValues,
}: ButtonDropdownMultipleTextProps<T>): ReactElement => {
  const { t } = useTranslation()

  const labels = useMemo(
    () =>
      selectedValues
        .map((value) => {
          const selectedItem = items.find((item) => isEqual(item.value, value))
          return selectedItem?.children
        })
        .filter(Boolean),
    [items, selectedValues],
  )

  const labelsCount = labels.length

  return (
    <Text as="span" inherit>
      {labelsCount === items.length && label ? (
        <Text as="span" weight="medium" inheritColor>
          {label}
        </Text>
      ) : (
        <>
          <Text as="span" weight="medium" inheritColor>
            {labels[0]}
          </Text>
          {labelsCount > 1 && (
            <Text as="span" weight="light" inheritColor>
              {t('ds.button_dropdown_multiple.text', { count: labelsCount - 1 })}
            </Text>
          )}
        </>
      )}
    </Text>
  )
}
