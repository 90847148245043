import { DateFormatter, DueDateFormatter, Translate } from '@components'
import { Icon, InternalAccessor, ItemsPerPage, Table, TableColumn } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { Row } from 'react-table'

import { QueryKeys } from '../../../../../../enums/queryKeys'
import { SortDirection } from '../../../../../../enums/sortDirection'
import { TableIds } from '../../../../../../enums/tableIds'
import { getTableColumnIdDirection } from '../../../../../../utils/getTableColumnIdDirection'
import { useBillsTableData } from '../../../../hooks/useBillsTableData'
import { BillsSortProperty } from '../../../../query-api'
import { BillsTableAccessor } from '../../enums/billsTableAccessor'
import { useBills } from '../../hooks/useBills'
import { useBillsListFilters } from '../../hooks/useBillsListFilters'
import { BillsTableRow } from '../../types/billsTableRow'
import { getBillQueryData } from '../../utils/getBillQueryData'
import { BillDescription } from '../BillDescription/BillDescription'
import { BillStatus } from '../BillStatus'

export const BillsTable = (): ReactElement => {
  const { bills, contacts, isLoading, pagination } = useBills()
  const data = useBillsTableData(bills, contacts)
  const [{ pageSize, sortDirection, sortProperty }, setQueryParams] = useBillsListFilters()
  const queryClient = useQueryClient()

  const columns: TableColumn<BillsTableRow>[] = useMemo(
    () => [
      {
        accessor: BillsTableAccessor.Status,
        alignment: 'center',
        Cell: ({ value: status }) => <BillStatus status={status} />,
        size: 'xxxxs',
        sortable: false,
      },
      {
        accessor: BillsTableAccessor.Attachment,
        alignment: 'center',
        Cell: ({ value: hasAttachment }) => hasAttachment && <Icon icon="paperclip" />,
        Header: <Translate value="bills.table.column.attachment" />,
        size: 'xxs',
        sortable: true,
        truncate: true,
      },
      {
        accessor: BillsTableAccessor.VoucherNo,
        Header: <Translate value="bills.table.column.voucher_no" />,
        size: 'xs',
        sortable: true,
        truncate: true,
      },
      {
        accessor: BillsTableAccessor.Date,
        Cell: ({ value: date }) => date && <DateFormatter value={date} />,
        fixedSize: true,
        Header: <Translate value="bills.table.column.date" />,
        size: 's',
        sortable: true,
      },
      {
        accessor: BillsTableAccessor.DueDate,
        Cell: ({ value: dueDate }) => dueDate && <DueDateFormatter value={dueDate} />,
        fixedSize: true,
        Header: <Translate value="bills.table.column.due_date" />,
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        accessor: BillsTableAccessor.RefNo,
        Header: <Translate value="bills.table.column.ref_no" />,
        hidden: true,
        size: 'xs',
        sortable: false,
        truncate: true,
      },
      {
        accessor: BillsTableAccessor.Description,
        Cell: ({ value: descriptionData }) =>
          descriptionData ? (
            <BillDescription isCreditNote={descriptionData.isCreditNote} title={descriptionData.value}>
              {descriptionData.value}
            </BillDescription>
          ) : (
            ''
          ),
        Header: <Translate value="bills.table.column.description" />,
        size: 'm',
        sortable: true,
        truncate: true,
      },
      {
        // It's well known issue in react-table... Link: https://github.com/TanStack/table/issues/3004
        accessor: (accessors) => accessors[BillsTableAccessor.Vendor],
        Header: <Translate value="bills.table.column.vendor" />,
        id: BillsTableAccessor.Vendor,
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        accessor: BillsTableAccessor.ExcludedVat,
        alignment: 'right',
        Header: <Translate value="bills.table.column.excluded_vat" />,
        hidden: true,
        size: 's',
        sortable: true,
      },
      {
        accessor: BillsTableAccessor.IncludedVat,
        alignment: 'right',
        Header: <Translate value="bills.table.column.included_vat" />,
        size: 's',
        sortable: true,
      },
      {
        accessor: BillsTableAccessor.Balance,
        alignment: 'right',
        Header: <Translate value="bills.table.column.balance" />,
        hidden: true,
        size: 's',
        sortable: true,
      },
      {
        accessor: InternalAccessor.UrlInternal,
      },
    ],
    [],
  )

  const sortedColumnId = useMemo(
    () => getTableColumnIdDirection(sortProperty, sortDirection === SortDirection.Desc),
    [sortDirection, sortProperty],
  )

  const handleRowClick = useCallback(
    (row: Row<BillsTableRow>) => {
      const { id: billId } = row.original
      const billQueryData = getBillQueryData(billId, bills, contacts)
      queryClient.setQueryData([QueryKeys.Bill, billId], () => billQueryData)
    },
    [bills, contacts, queryClient],
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setQueryParams({ page })
    },
    [setQueryParams],
  )

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: ItemsPerPage) => {
      setQueryParams({ page: 1, pageSize: itemsPerPage })
    },
    [setQueryParams],
  )

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      setQueryParams({
        page: 1,
        sortProperty: columnId as BillsSortProperty,
        sortDirection: isDesc ? SortDirection.Desc : SortDirection.Asc,
      })
    },
    [setQueryParams],
  )

  return (
    <Table
      columns={columns}
      currentPage={pagination?.page}
      data={data}
      id={TableIds.BillsList}
      isLoading={isLoading}
      itemsPerPage={pageSize}
      onItemsPerPageChange={handleItemsPerPageChange}
      onPageChange={handlePageChange}
      onRowClick={handleRowClick}
      onSort={handleSort}
      sortedColumnId={sortedColumnId}
      totalPageItems={pagination?.total}
      withColumnsFiltering
      withItemsPerPageSelect
      withStickyHeader
    />
  )
}
