import isUndefined from 'lodash/isUndefined'

import { Contact } from '@views/contacts/types/contact'

import { ContactsSelectFilter } from '../types/contactsSelectFilter'

export const filterContacts = (
  contacts: Contact[] = [],
  filters?: ContactsSelectFilter,
  selectedId?: string,
): Contact[] => {
  const { isArchived } = filters || {}

  return contacts.filter((contact) => {
    if (contact.id === selectedId) {
      return true
    }

    if (!isUndefined(isArchived) && isArchived !== contact.isArchived) {
      return false
    }

    return true
  })
}
