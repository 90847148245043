import { ItemsPerPage } from '@design-system'

import { useQuery } from 'react-query'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { getUmbrellaOrganizationsSubscriptions } from '../query-api'

interface UseUmbrellaOrganizationsSubscriptionsArgs {
  search?: string
  page?: number
  pageSize?: ItemsPerPage
}

export const useUmbrellaOrganizationsSubscriptions = ({
  page,
  pageSize,
  search,
}: UseUmbrellaOrganizationsSubscriptionsArgs = {}) => {
  const { umbrella } = useUmbrella()
  const { data, isIdle, isLoading } = useQuery(
    [QueryKeys.UmbrellaOrganizationsSubscriptions, umbrella?.id, search, page, pageSize],
    () => getUmbrellaOrganizationsSubscriptions(umbrella?.id as string, { page, pageSize, search }),
    {
      enabled: !!umbrella?.id,
    },
  )

  return {
    umbrellaOrganizationsSubscriptions: data?.data || [],
    pagination: data?.pagination,
    isLoading: isIdle || isLoading,
  }
}
