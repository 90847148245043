import { WidgetId } from '../enums/widgetId'
import { Widget } from '../types/widget'

export const widgetSettingsByWidgetId: Record<WidgetId, Pick<Widget, 'isStatic' | 'isHiddenPermanently'>> = {
  [WidgetId.Overview]: {},
  [WidgetId.OverviewMonthly]: {},
  [WidgetId.FindAccountant]: {},
  [WidgetId.GetStarted]: { isHiddenPermanently: true },
  [WidgetId.Upsell]: { isStatic: true },
  [WidgetId.Vat]: {},
  [WidgetId.BankIntegration]: {},
  [WidgetId.Invoices]: {},
  [WidgetId.Bills]: {},
  [WidgetId.TopCustomers]: {},
  [WidgetId.Tasks]: {},
  [WidgetId.TopVendors]: {},
}
