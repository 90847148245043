import { Switch } from '@design-system'

import styled from '@emotion/styled'

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const Toggle = styled(Switch)`
  margin: 0 10px;
  width: fit-content;
`
