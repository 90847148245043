import { parseISO } from 'date-fns'

import { PeriodMode } from '../../../../enums/periodMode'
import { PeriodValue } from '../../../../types/periodValue'

export const decodePeriodValue = (stringValue: string): PeriodValue | undefined => {
  const [mode, value] = stringValue.split(':') as [PeriodMode, string]

  if (mode === PeriodMode.CustomDate) {
    const [startDateString, endDateString] = value.split('...')
    const startDate = startDateString ? parseISO(startDateString) : undefined
    const endDate = endDateString ? parseISO(endDateString) : undefined

    return {
      mode: PeriodMode.CustomDate,
      customDate: {
        startDate,
        endDate,
      },
    }
  }

  if (mode === PeriodMode.CustomDateFrom) {
    const startDate = value ? parseISO(value) : undefined
    const endDate = undefined

    return {
      mode: PeriodMode.CustomDate,
      customDate: {
        startDate,
        endDate,
      },
    }
  }

  if (mode === PeriodMode.CustomDateThrough) {
    const startDate = undefined
    const endDate = value ? parseISO(value) : undefined

    return {
      mode: PeriodMode.CustomDate,
      customDate: {
        startDate,
        endDate,
      },
    }
  }

  if (mode === PeriodMode.Month) {
    const [year, month] = value.split('-')

    return {
      mode: PeriodMode.Month,
      year: Number(year),
      value: Number(month) - 1,
    }
  }

  if (mode === PeriodMode.Quarter) {
    const [year, quarter] = value.split('-')

    return {
      mode: PeriodMode.Quarter,
      year: Number(year),
      value: Number(quarter) - 1,
    }
  }

  if (mode === PeriodMode.FiscalYear) {
    const year = value.split(',')[1]

    return {
      mode: PeriodMode.FiscalYear,
      year: Number(year),
      value: Number(year),
    }
  }

  return {
    mode: PeriodMode.All,
  }
}
