import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconFaq = ({ color = '#FFFFFF', width = '20px', height = '20px', bg = '#ffffff', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-faq')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill={coloring(color)}
        d="M15,2v10H7H6.465l-0.297,0.445L4.845,14.43l-2.137-2.137L2.414,12H2H1V2H15 M15,1H1C0.448,1,0,1.448,0,2v10c0,0.552,0.448,1,1,1h1l3,3l2-3h8c0.552,0,1-0.448,1-1V2C16,1.448,15.552,1,15,1L15,1z"
      />
      <g>
        <path
          fill={coloring(color)}
          d="M7.413,8.065c-0.03-0.216-0.022-0.414,0.022-0.594c0.045-0.18,0.111-0.345,0.198-0.495s0.186-0.29,0.297-0.418c0.11-0.129,0.216-0.255,0.314-0.378c0.1-0.123,0.182-0.245,0.248-0.365c0.065-0.12,0.099-0.249,0.099-0.387c0-0.216-0.064-0.39-0.193-0.522C8.27,4.774,8.091,4.708,7.863,4.708c-0.187,0-0.354,0.042-0.504,0.126c-0.15,0.084-0.291,0.198-0.424,0.342L6.36,4.645c0.191-0.228,0.42-0.416,0.684-0.562s0.561-0.221,0.891-0.221c0.24,0,0.462,0.032,0.666,0.095S8.982,4.114,9.132,4.24c0.15,0.126,0.266,0.282,0.347,0.468S9.6,5.11,9.6,5.356c0,0.192-0.034,0.365-0.104,0.518C9.428,6.026,9.342,6.172,9.24,6.31C9.138,6.448,9.028,6.58,8.911,6.706C8.795,6.832,8.689,6.964,8.597,7.102C8.503,7.24,8.429,7.387,8.371,7.543c-0.057,0.156-0.073,0.33-0.049,0.522H7.413zM7.206,9.406c0-0.204,0.062-0.372,0.188-0.504C7.521,8.77,7.68,8.704,7.872,8.704c0.191,0,0.353,0.066,0.481,0.198c0.129,0.132,0.193,0.3,0.193,0.504c0,0.203-0.064,0.372-0.193,0.504s-0.29,0.197-0.481,0.197c-0.192,0-0.351-0.065-0.478-0.197C7.269,9.778,7.206,9.609,7.206,9.406z"
        />
      </g>
    </svg>
  </Box>
)
