import { ProtectedComponent } from '@components'
import { Button } from '@design-system'

import Cookies from 'js-cookie'
import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { CookieKeys } from '../../../../enums/cookieKeys'
import { EmberRoute } from '../../../../enums/emberRoute'
import { Scope } from '../../../../enums/scope'
import { useSegment } from '../../../../hooks'

export const QuotesSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { navigate } = useEmberRouter()
  const location = useLocation()

  const handleCreateNewQuoteClick = useCallback(() => {
    track('XXX - Jesper - Action Taken', {
      context: 'quotes_list',
      action: 'create quote',
    })
    Cookies.set(CookieKeys.QuotesListQuery, location.search)

    navigate(EmberRoute.QuotesNew)
  }, [location.search, navigate, track])

  return (
    <ProtectedComponent scopes={Scope.OfferWrite}>
      <Button variant="primary" icon="plusCircle" onClick={handleCreateNewQuoteClick}>
        {t('quotes.create_quote')}
      </Button>
    </ProtectedComponent>
  )
}
