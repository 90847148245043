import { getRequest, postRequest } from '../../../utils'
import { AgerasFinanceCustomer } from './types/agerasFinanceCustomer'

/* eslint-disable @typescript-eslint/naming-convention */
export interface Lending {
  id: string
  currency: string
  loan_interest_rate: number
  loan_durations: number[]
  max_amount: number
  min_amount: number
  amount_increments: number
}

export const fetchBankLoanData = (organizationId: string): Promise<Lending> => {
  return postRequest(`/integrations/agerasfinance/organizations/${organizationId}/loan-estimates`, {
    currency: 'DKK',
  })
}

export interface AgerasFinanceCustomerResponseData {
  customer: AgerasFinanceCustomer
  token: {
    accessToken: string
  }
}

export const fetchAgerasFinanceCustomer = (organizationId: string): Promise<AgerasFinanceCustomerResponseData> => {
  return getRequest(`/integrations/agerasfinance/organizations/${organizationId}/customer`)
}

export interface LoanResponseData {
  application: {
    id: string
  }
  token: {
    accessToken: string
  }
}

export interface BankLoanRequestPayloadData {
  id: string
  provider: string
  providerId: string
  email: string
  phoneNumber: string
  loanAmount: number
  loanDuration: number
  companyRegistrationNumber: string
  name: string
}

export const createLoanRequest = (
  organizationId: string,
  data: BankLoanRequestPayloadData,
): Promise<LoanResponseData> => {
  return postRequest(
    `/integrations/agerasfinance/organizations/${organizationId}/loan-estimates/${data.id}/actions/approve`,
    {
      /* eslint-disable @typescript-eslint/naming-convention */
      provider: data.provider,
      provider_id: data.providerId,
      email: data.email,
      phone_number: data.phoneNumber,
      loan_amount: data.loanAmount,
      loan_duration: data.loanDuration,
      loan_currency: 'DKK',
      company_registration_number: data.companyRegistrationNumber,
      name: data.name,
      /* eslint-enable @typescript-eslint/naming-convention */
    },
  )
}
