import { Button, ButtonDropdown, SkeletonButton, Space, Tooltip } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { isBillsModulePath } from '@views/bills/utils/isBillsModulePath'

import { useGlobalSearch } from '../../../../contexts/globalSearchContext'
import { CustomEvent } from '../../../../enums/customEvent'
import { EmberRoute } from '../../../../enums/emberRoute'
import { useSegment } from '../../../../hooks'
import { dispatchCustomEvent } from '../../../../utils/customEvent'
import { globalSearchShortcutLabel, globalSearchTriggerDataId } from '../../../GlobalSearch'
import { Translate } from '../../../Translate'
import { useFastMenuItems } from './hooks/useFastMenuItems'
import * as Styled from './styles'
import { getFastMenuEventActionFromRoute } from './utils/getFastMenuEventActionFromRoute'

export const MenuActions = (): ReactElement | null => {
  const [{ isOpen: isSearchOpen }, dispatch] = useGlobalSearch()
  const { t } = useTranslation()
  const { track } = useSegment()
  const { umbrella } = useUmbrella()
  const { organization } = useUserOrganization()
  const { items: fastMenuItems, isLoading: isFastMenuItemsLoading } = useFastMenuItems()

  const isLoading = (!umbrella && !organization) || isFastMenuItemsLoading
  const isOrganizationLocked = organization?.isLocked
  const shouldRenderActions = !isOrganizationLocked

  const trackFastMenuAction = useCallback(
    (routeTo: EmberRoute) => {
      track('XXX - Jesper - Action Taken', {
        context: 'menu',
        action: getFastMenuEventActionFromRoute(routeTo),
      })
    },
    [track],
  )

  const handleItemSelect = useCallback(
    (_: string, routeTo: EmberRoute) => {
      trackFastMenuAction(routeTo)
      // there's an issue when we try to navigate using ember routing to the same module which is currently opened
      // that's why it's better to just invoke a "history" method in this module using custom event
      if (routeTo === EmberRoute.BillsNew && isBillsModulePath()) {
        dispatchCustomEvent(CustomEvent.CreateNewBillClicked)
      }
    },
    [trackFastMenuAction],
  )

  const handleSearchButtonClick = useCallback(() => {
    if (isSearchOpen) {
      dispatch({ type: 'CLOSE' })
      return
    }

    dispatch({ type: 'OPEN' })
  }, [dispatch, isSearchOpen])

  return !shouldRenderActions && !isLoading ? null : (
    <Styled.MenuActionsWrapper>
      {isLoading ? (
        <SkeletonButton size="xl" fullWidth />
      ) : (
        <>
          {fastMenuItems.length > 0 && (
            <>
              <ButtonDropdown
                size="xl"
                items={fastMenuItems}
                placement="bottom-start"
                onSelect={handleItemSelect}
                fullWidth
              >
                <Translate value="menu.create_new" />
              </ButtonDropdown>
              <Space size="s" />
            </>
          )}

          {fastMenuItems.length > 0 ? (
            <Tooltip label={`${t('search')} (${globalSearchShortcutLabel})`} placement="top">
              <Styled.SearchIconButton
                size="xl"
                icon="magnifyingGlass"
                active={isSearchOpen}
                onClick={handleSearchButtonClick}
                title={`${t('search')} (${globalSearchShortcutLabel})`}
                data-id={globalSearchTriggerDataId}
              />
            </Tooltip>
          ) : (
            <Button
              active={isSearchOpen}
              data-id={globalSearchTriggerDataId}
              fullWidth
              icon="magnifyingGlass"
              onClick={handleSearchButtonClick}
              size="xl"
              variant="secondary"
            >
              {t('search')}
            </Button>
          )}
        </>
      )}
    </Styled.MenuActionsWrapper>
  )
}
