import { Button, ButtonsGroup, Checkbox, Flex, Modal, ModalProps, Space, Text, useModal } from '@design-system'

import { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateOrganizationSettings, useUserOrganization } from '@modules-deprecated/app/organization'

import { getImgPath } from '../../../../../../../../utils/getImgPath'
import * as Styled from './styles'

const confirmationIllustration = getImgPath('/invoice-editor/modal-invoice-editor-finalization.svg')

export const DownloadConfirmationModal = (modalProps: ModalProps): ReactElement => {
  const { t } = useTranslation()
  const [shouldNeverShowAgain, setShouldNeverShowAgain] = useState(false)
  const { organization } = useUserOrganization()
  const { update } = useUpdateOrganizationSettings()

  const { close } = useModal(modalProps.id)

  const updateOrganizationSettings = useCallback(() => {
    if (organization?.id) {
      update({
        key: 'isInvoiceEditorDownloadModalDismissed',
        organizationId: organization?.id,
        value: shouldNeverShowAgain ? '1' : '0',
      })
    }
  }, [organization?.id, update, shouldNeverShowAgain])

  const handleCheckboxChange = useCallback(() => {
    setShouldNeverShowAgain((prevState) => !prevState)
  }, [])

  const handleCancelButtonClick = useCallback(() => {
    close()
  }, [close])

  const handleDownloadButtonClick = useCallback(() => {
    updateOrganizationSettings()
    close()
  }, [close, updateOrganizationSettings])

  return (
    <Modal size="s" {...modalProps}>
      <Modal.Header title={t('external_invoices.editor.confirm_download_modal.title')} />
      <Modal.Body>
        <Flex flexDirection="column" alignItems="center">
          <img src={confirmationIllustration} alt="Invoice editor finalization illustration" width={160} height={160} />
          <Space size="xl" />
          <Text>{t('external_invoices.editor.confirm_download_modal.description')}</Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Styled.FooterContentWrapper>
          <Flex justifyContent="space-between" alignItems="center" flexGrow={1}>
            <Checkbox checked={shouldNeverShowAgain} onChange={handleCheckboxChange}>
              {t('external_invoices.editor.confirm_download_modal.dont_show_again')}
            </Checkbox>
            <ButtonsGroup>
              <Button variant="text" onClick={handleCancelButtonClick}>
                {t('cancel')}
              </Button>
              <Button onClick={handleDownloadButtonClick}>{t('download')}</Button>
            </ButtonsGroup>
          </Flex>
        </Styled.FooterContentWrapper>
      </Modal.Footer>
    </Modal>
  )
}
