import { Alert, Button, Flex, Spacing } from '@design-system'

import isNumber from 'lodash/isNumber'
import { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateOrganizationSettings, useUserOrganization } from '@modules-deprecated/app/organization'

import { useSegment } from '../../../../../../hooks'
import { useExternalInvoicesSyncProgress } from '../../contexts/externalInvoicesSyncProgressContext'

export const SyncCompletedTopBar = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { update: updateOrganizationSettings } = useUpdateOrganizationSettings()
  const { processedDocumentsCount } = useExternalInvoicesSyncProgress()

  const completedSyncAlertText = isNumber(processedDocumentsCount)
    ? t('external_invoices_sync_top_bar.completed.syncing_completed', {
        processedDocumentsCount,
      })
    : t('external_invoices_sync_top_bar.completed.syncing_completed.no_documents')

  const handleCloseButtonClick = useCallback(() => {
    if (organization?.id) {
      track('xxx-sales-HistoricalSync-Top Bar closed')

      updateOrganizationSettings({
        key: 'externalInvoicesSyncShowTopBar',
        organizationId: organization?.id,
        value: '0',
      })
    }
  }, [organization?.id, track, updateOrganizationSettings])

  useEffect(() => {
    track('xxx-sales-HistoricalSync-Top Bar updated', {
      topBarType: 'completed',
    })
  }, [])

  return (
    <Alert variant="success">
      <Flex alignItems="center" gap={Spacing.S}>
        {completedSyncAlertText}
        <Button onClick={handleCloseButtonClick} size="m" variant="secondary">
          {t('close')}
        </Button>
      </Flex>
    </Alert>
  )
}
