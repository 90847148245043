import { Account } from '@modules-deprecated/app/accounts/types'
import { ChartOfAccountId } from '@modules-deprecated/app/organization/enums/chartOfAccountId'
import { TaxRate } from '@modules-deprecated/app/taxrates/types'
import { Contact } from '@views/contacts/types/contact'

import { autoCompleteBillForm } from '../utils/autoCompleteBillForm'
import { BillForm } from '../utils/formData'
import { getBillFormFromVendorData } from '../utils/getBillFormFromVendorData'
import { getShouldOverridenParamChange } from '../utils/getShouldOverridenParamChange'

interface GetVendorAutoCompleteProps {
  accounts?: Account[]
  baseCurrencyId?: string
  billForm: BillForm
  chartOfAccountId?: ChartOfAccountId
  countryId?: string
  isVatExempted?: boolean
  locale: string
  taxRates?: TaxRate[]
  vendor: Partial<Contact>
}

export const getAutoCompletedFormWithVendor = async ({
  accounts,
  baseCurrencyId,
  billForm,
  chartOfAccountId,
  countryId,
  isVatExempted,
  locale,
  taxRates,
  vendor,
}: GetVendorAutoCompleteProps): Promise<BillForm> => {
  const billFormVendor = await getBillFormFromVendorData({
    accounts,
    billForm,
    chartOfAccountId,
    countryId,
    isVatExempted,
    locale,
    taxRates,
    vendor,
  })

  return autoCompleteBillForm({
    billFormDefault: billForm,
    billFormOverrides: billFormVendor,
    baseCurrencyId,
    shouldOverridenParamChangeCallback: getShouldOverridenParamChange({ shouldChangeExpenseParams: true }),
  })
}
