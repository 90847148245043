import { RangeSlider, TabsGroup } from '@components-deprecated'
import { amountToDisplayValueSplitted, ModuleLayout, notify } from '@design-system'

import { yupResolver } from '@hookform/resolvers/yup'
import { TFunction } from 'i18next'
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import * as yup from 'yup'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { NotificationKeys } from '../../enums/notificationKeys'
import { useSegment } from '../../hooks'
import { useBankLoanData } from './bankLoanDataContext'
import { CheckoutSummary } from './elements/checkoutSummary/CheckoutSummary'
import { IncludedItems } from './elements/includedItems/IncludedItems'
import { BankLoanRequestPayloadData, createLoanRequest, LoanResponseData } from './query-api'
import * as Styled from './styles'

type FormInputs = {
  companyName: string
  cvr: string
  email?: string
  phone?: string
}

const defaultValues: FormInputs = {
  companyName: '',
  cvr: '',
  email: '',
  phone: '',
}

const validationSchema = (t: TFunction): yup.SchemaOf<FormInputs> =>
  yup.object().shape({
    companyName: yup.string().required(t('financing.checkout.form.company_name.error')),
    cvr: yup.string().required(t('financing.checkout.form.cvr.error')),
    email: yup.string().email().required(t('financing.checkout.form.email.error')),
    phone: yup.string().required(t('financing.checkout.form.phone.error')),
  })

const includedItems = [
  'financing.included.no_setup_fee',
  'financing.included.no_hidden_fees',
  'financing.included.non_binding',
  'financing.included.quick_approval',
  'financing.included.no_mortgage',
]

const agerasFinanceBaseUrl = window.ENV
  ? window.ENV.AGERAS_FINANCE_FRONTEND_URL
  : process.env.AGERAS_FINANCE_FRONTEND_URL

export const Financing = (): ReactElement | null => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()
  const { bankLoadData: data, isLoading: isDataLoading } = useBankLoanData()

  const {
    /* eslint-disable @typescript-eslint/naming-convention */
    currency,
    loan_interest_rate: targetRate,
    max_amount: maxAmount,
    min_amount: minAmount,
    amount_increments: amountIncrements,
    loan_durations: loanDurations,
    /* eslint-enable @typescript-eslint/naming-convention */
  } = data || {}

  const isLoading = isDataLoading || !organization

  const form = useForm<FormInputs>({
    defaultValues,
    resolver: useMemo(() => yupResolver(validationSchema(t)), [t]),
  })

  const requestLoanMutation = useMutation(
    (payload: BankLoanRequestPayloadData) => createLoanRequest(organization?.id as string, payload),
    {
      onSuccess: (response: LoanResponseData) => {
        track('Applied For Business Loan (FE)', {
          amount: selectedLoanAmount,
          currency: 'DKK',
          duration: selectedLoanDuration,
        })

        const successRedirectURL = `${agerasFinanceBaseUrl}/loan-applications/${response.application.id}#token=${response.token.accessToken}`
        window.location.replace(successRedirectURL)
      },
      onError: () => {
        notify({
          id: NotificationKeys.FinancingLoanRequest,
          message: t('financing.loan_request.error'),
          variant: 'error',
        })
      },
    },
  )

  const [selectedLoanAmount, setSelectedLoanAmount] = useState(0)
  const [selectedLoanDuration, setSelectedLoanDuration] = useState(0)
  const isDataFetchedRef = useRef(false)

  useEffect(() => {
    if (!data || isDataFetchedRef.current || !organization) {
      return
    }

    isDataFetchedRef.current = true
    setSelectedLoanAmount(Math.ceil((maxAmount || 0) / 2))
    setSelectedLoanDuration(loanDurations?.length ? loanDurations[0] : 0)
    form.setValue('companyName', organization?.name || '')
    form.setValue('cvr', organization?.registrationNo || '')
  }, [data, organization])

  const tabsOptions = useMemo(() => {
    if (loanDurations?.length) {
      return loanDurations.map((duration) => ({
        id: duration,
        value: duration,
        label: t('organization_subscription.months_number_other', { month: duration }),
      }))
    }
  }, [loanDurations, t])

  const termsAndConditions = useMemo(
    () => [
      {
        text: t('financing.checkout.terms_1'),
        accepted: false,
      },
    ],
    [t],
  )

  if (isLoading || !data) {
    return null
  }

  const handleOnSubmit = (formData: FormInputs) => {
    requestLoanMutation.mutate({
      id: data.id,
      provider: 'billy',
      providerId: organization?.id || '',
      email: formData.email || '',
      phoneNumber: formData.phone || '',
      loanAmount: selectedLoanAmount,
      loanDuration: selectedLoanDuration,
      companyRegistrationNumber: formData.cvr,
      name: user?.name || '',
    })
  }

  const applyForLoan = () => {
    form.handleSubmit((data: FormInputs) => handleOnSubmit(data))()
  }

  const serviceFee = Math.ceil((selectedLoanAmount * ((targetRate || 0) / 100)) / 12 / 2)

  const installments = Math.ceil(selectedLoanAmount / selectedLoanDuration)

  const monthlyPayment = Math.ceil(serviceFee + installments)

  return (
    <ModuleLayout title={t('financing.page_title')}>
      <Styled.FinancingContainer>
        <Styled.ContentWrapper>
          <Styled.Title>{t('financing.title')}</Styled.Title>
          <Styled.Description>{t('financing.description')}</Styled.Description>

          <IncludedItems includedItems={includedItems} />

          <Styled.RangeSliderContainer>
            <RangeSlider
              label={t('financing.loan_amount_label')}
              min={minAmount || 0}
              max={maxAmount || 0}
              unit={currency || ''}
              step={amountIncrements || 0}
              onChange={(amount: number) => setSelectedLoanAmount(amount)}
            />
          </Styled.RangeSliderContainer>

          {tabsOptions && (
            <Styled.TabsContainer>
              <TabsGroup
                label={t('financing.loan_duration')}
                options={tabsOptions}
                onSelect={(option) => setSelectedLoanDuration(option.value)}
              />
            </Styled.TabsContainer>
          )}

          <Styled.SubTitle>{t('financing.second_title')}</Styled.SubTitle>

          <FormProvider {...form}>
            <Styled.FormOuterContainer>
              <Styled.FormColoumnLeft>
                <Styled.Input
                  name="companyName"
                  label={t('financing.checkout.form.company_name')}
                  disabled
                  formControl={form.control}
                />

                <Styled.Input name="email" label={t('financing.checkout.form.email')} formControl={form.control} />
                <Styled.Input name="phone" label={t('financing.checkout.form.phone')} formControl={form.control} />
              </Styled.FormColoumnLeft>

              <Styled.FormColoumnRight>
                <Styled.Input
                  name="cvr"
                  label={t('financing.checkout.form.cvr')}
                  disabled={!!organization?.registrationNo}
                  formControl={form.control}
                />
              </Styled.FormColoumnRight>
            </Styled.FormOuterContainer>
          </FormProvider>
        </Styled.ContentWrapper>

        <Styled.AsideWrapper>
          <CheckoutSummary
            title={t('financing.checkout.title')}
            termsAndConditions={termsAndConditions}
            onCheckout={applyForLoan}
            checkoutButtonText={t('financing.checkout.apply_button')}
            checkoutUnderButtonText={t('financing.checkout.application_non_binding')}
            isLoading={requestLoanMutation.isLoading}
          >
            <Styled.Info>
              <Styled.InfoKey>{t('financing.checkout.loan_amount')}</Styled.InfoKey>
              <Styled.InfoValue>
                {amountToDisplayValueSplitted(selectedLoanAmount).value} {currency}
              </Styled.InfoValue>
            </Styled.Info>

            <Styled.Info>
              <Styled.InfoKey>{t('financing.loan_duration')}</Styled.InfoKey>
              <Styled.InfoValue>{`${t('months_number', { count: selectedLoanDuration })}`}</Styled.InfoValue>
            </Styled.Info>

            <Styled.LineDivider />

            <Styled.Info>
              <Styled.InfoKey>{t('financing.setup_fee')}</Styled.InfoKey>
              <Styled.InfoValue>{`0 ${currency}`}</Styled.InfoValue>
            </Styled.Info>

            <Styled.Info>
              <Styled.InfoKey>{t('financing.checkout.service_fee')}</Styled.InfoKey>
              <Styled.InfoValue>
                {amountToDisplayValueSplitted(serviceFee).value} {currency}
              </Styled.InfoValue>
            </Styled.Info>

            <Styled.Info>
              <Styled.InfoKey>{t('financing.checkout.installments')}</Styled.InfoKey>
              <Styled.InfoValue small>
                {amountToDisplayValueSplitted(installments).value} {currency}
              </Styled.InfoValue>
            </Styled.Info>

            <Styled.Info>
              <Styled.InfoKey>{t('financing.checkout.monthly_payment')}</Styled.InfoKey>
              <Styled.InfoValue small>
                {amountToDisplayValueSplitted(monthlyPayment).value} {currency}
              </Styled.InfoValue>
            </Styled.Info>

            <Styled.InfoText>{t('financing.checkout.info')}</Styled.InfoText>
          </CheckoutSummary>
        </Styled.AsideWrapper>
      </Styled.FinancingContainer>
    </ModuleLayout>
  )
}
