import { User } from '@views/settings/routes/OrganizationSettingsUsers/types'

import { useOrganizationMembers } from '../../organization/hooks/useOrganizationMembers'
import { UmbrellaOrganization } from '../../organizations/types'
import { Organization } from '../types/organization'
import { getOrganizationIds } from '../utils/getOrganizationIds'

interface UseOrganizationOwnersProps {
  organization?: Organization
  umbrellaOrganization?: UmbrellaOrganization
}

export const useOrganizationOwners = ({ umbrellaOrganization, organization }: UseOrganizationOwnersProps = {}) => {
  const { organizationId, ownerUserId, ownerUmbrellaId } = getOrganizationIds({ umbrellaOrganization, organization })

  const { organizationMembers, isLoading } = useOrganizationMembers({
    organizationId,
    ownerUserId,
    ownerUmbrellaId,
  })

  const organizationMembersWithoutCurrentOwner: User[] = organizationMembers
    ? organizationMembers?.filter((organizationMember) => !organizationMember.isOwner)
    : []

  return {
    organizationOwners: organizationMembersWithoutCurrentOwner,
    isLoading,
  }
}
