import { TableActionsWrapper } from '@components'

import React, { ReactElement } from 'react'

import { RecurringInvoicesSearch } from './elements/RecurringInvoicesSearch'

export const RecurringInvoicesTableActions = (): ReactElement => (
  <TableActionsWrapper>
    <RecurringInvoicesSearch />
  </TableActionsWrapper>
)
