import qs from 'qs'

import { getRequest } from '../../../../utils'
import { Report } from './types/report'
import { TaxReturn } from './types/taxReturn'

interface SalesTaxReturnPeriodsResponse {
  salesTaxReturns: TaxReturn[]
}

export const fetchTaxReturnPeriods = (organizationId: string): Promise<SalesTaxReturnPeriodsResponse> => {
  return getRequest(
    `/v2/salesTaxReturns?organizationId=${organizationId}&sortProperty=startDate&sortDirection=DESC&pageSize=100&offset=0`,
  )
}

interface SalesTaxReturnAccountReportProps {
  salesTaxReturnId: string
  noRaw?: boolean
  noHtml?: boolean
}

export interface SalesTaxReturnAccountReportResponse {
  report: Partial<Report>
}

export const fetchSalesTaxReturnAccountReport = ({
  salesTaxReturnId,
  noRaw,
  noHtml,
}: SalesTaxReturnAccountReportProps): Promise<SalesTaxReturnAccountReportResponse> => {
  const queryParams = qs.stringify({
    ...(noRaw ? { noRaw } : {}),
    ...(noHtml ? { noHtml } : {}),
  })

  return getRequest(`/v2/salesTaxReturns/${salesTaxReturnId}/accountReport?${queryParams}`)
}
