import { useMutation } from 'react-query'

import { deleteUmbrellaUserInvitation as deleteUmbrellaUserInvitationQuery } from '@modules-deprecated/app/umbrellas/query-api'

import { APIError } from '../../../../../../../utils'

interface UseDeleteUmbrellaUserInvitationProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface DeleteUmbrellaUserInvitationPayload {
  umbrellaId: string
  invitationId: string
}

export const useDeleteUmbrellaUserInvitation = (props?: UseDeleteUmbrellaUserInvitationProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: deleteUmbrellaUserInvitation, isLoading: isProcessing } = useMutation(
    ({ umbrellaId, invitationId }: DeleteUmbrellaUserInvitationPayload) =>
      deleteUmbrellaUserInvitationQuery(umbrellaId, invitationId),
    {
      onError,
      onSuccess,
    },
  )

  return {
    isProcessing,
    deleteUmbrellaUserInvitation,
  }
}
