import styled from '@emotion/styled'

import { BorderRadius } from '../../enums/borderRadius'
import { Color } from '../../enums/color'
import { Spacing } from '../../enums/spacing'
import { getShadow } from '../../utils/getShadow'

export const ModuleWrapper = styled.div`
  display: flex;
  padding: ${Spacing.L} ${Spacing.XL};
  width: 100%;
  flex-direction: column;
  border-radius: ${BorderRadius.L};
  background-color: ${Color.White};
  ${getShadow('raisedLight')};
`
