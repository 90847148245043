import set from 'lodash/set'
import { Path, PathValue, SetValueConfig, UseFormReturn } from 'react-hook-form'
import { AnyObjectSchema, Asserts } from 'yup'

type Value<T extends AnyObjectSchema> = PathValue<Asserts<T>, Path<Asserts<T>>>

export const setValue =
  <T extends AnyObjectSchema>(form: UseFormReturn<Asserts<T>, any>) =>
  (name: Path<Asserts<T>>, value: Value<T>, options?: SetValueConfig) => {
    if (!options?.shouldDirty) {
      set(form.control._defaultValues, name, value)
    }

    form.setValue(name, value, options)
  }
