import { Flex, Icon, Radio, RadioProps, Spacing, Text, Tooltip } from '@design-system'

import { ReactElement, ReactNode } from 'react'

interface SyncTimePeriodModeRadioProps extends RadioProps {
  header: string
  subheader: string
  tooltipLabel: ReactNode
}

export const SyncTimePeriodModeRadio = ({
  header,
  subheader,
  tooltipLabel,
  ...radioProps
}: SyncTimePeriodModeRadioProps): ReactElement => (
  <Radio {...radioProps}>
    <Text weight="bold">{header}</Text>
    <Tooltip label={tooltipLabel} placement="top-start">
      <Flex alignItems="center" gap={Spacing.XXS}>
        <Text variant="micro" colorVariant="secondary">
          {subheader}
        </Text>
        <Icon icon="iSignCircle" size="small" />
      </Flex>
    </Tooltip>
  </Radio>
)
