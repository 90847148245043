import { amountToDisplayValue, TextColorVariant } from '@design-system'

import { useBillReconciliation } from '../../../contexts/billReconciliationContext'
import { useMultipleBills } from '../../../contexts/multipleBillsContext'

interface UsePrepaymentModalDisplayValuesResponse {
  contactName?: string
  creditBalanceColorVariant: TextColorVariant
  displayedBillsAmount?: string
  displayedContactBalanceAmount?: string
  displayedCreditBalance?: string
}

export const usePrepaymentModalDisplayValues = (): UsePrepaymentModalDisplayValuesResponse => {
  const { contact, contactBalance } = useBillReconciliation()
  const { selectedBillsAmountsSum } = useMultipleBills()

  const contactName = contact?.name
  const contactBalanceCurrencyId = contactBalance?.currencyId
  const contactBalanceAmount = contactBalance?.amount

  const creditBalance =
    !!contactBalanceAmount && !!selectedBillsAmountsSum ? contactBalanceAmount - selectedBillsAmountsSum : undefined
  const isCreditBalancePositive = creditBalance && creditBalance > 0
  const creditBalanceColorVariant = isCreditBalancePositive ? 'success' : 'danger'

  const displayedContactBalanceAmount = contactBalanceAmount
    ? amountToDisplayValue(contactBalanceAmount, false, contactBalanceCurrencyId)
    : undefined
  const displayedBillsAmount = selectedBillsAmountsSum
    ? amountToDisplayValue(-selectedBillsAmountsSum, false, contactBalanceCurrencyId)
    : undefined
  const displayedCreditBalance = creditBalance
    ? amountToDisplayValue(creditBalance, false, contactBalanceCurrencyId)
    : undefined

  return {
    contactName,
    creditBalanceColorVariant,
    displayedBillsAmount,
    displayedContactBalanceAmount,
    displayedCreditBalance,
  }
}
