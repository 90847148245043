import { ModalProps, useModal } from '@design-system'

import { ReactElement } from 'react'

import { TransferUmbrellaOwnershipContextProvider } from './contexts/transferUmbrellaOwnershipContext'
import { UmbrellaOwnershipModalContent } from './elements/UmbrellaOwnershipModalContent'

type UmbrellaOwnershipModalContentProps = ModalProps

export const TransferUmbrellaOwnershipModal = (props: UmbrellaOwnershipModalContentProps): ReactElement => {
  const { close } = useModal(props.id)

  return (
    <TransferUmbrellaOwnershipContextProvider onTransferSuccess={close}>
      <UmbrellaOwnershipModalContent {...props} />
    </TransferUmbrellaOwnershipContextProvider>
  )
}
