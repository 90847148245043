/*
  DOMPurify sanitizes HTML and prevents XSS attacks. You can feed DOMPurify
  with string full of dirty HTML and it will return a string with clean HTML.
  DOMPurify will strip out everything that contains dangerous HTML
  and thereby prevent XSS attacks and other nastiness. 
*/
import { sanitize } from 'dompurify'
import React, { ReactElement } from 'react'

import { Asable } from '../../types/asable'
import * as Styled from './styles'

export interface SafeHtmlProps extends Asable {
  htmlContent?: string
  className?: string
}

export const SafeHtml = ({ as, className, htmlContent = '' }: SafeHtmlProps): ReactElement => {
  return (
    <Styled.SafeHtmlWrapper
      as={as}
      className={className}
      dangerouslySetInnerHTML={{
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        __html: sanitize(htmlContent),
      }}
    />
  )
}
