import { Modal } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../enums/modalId'
import { InviteUserModalContextProvider } from './context/inviteUserModalContext'
import { InviteUserForm } from './elements/InviteUserForm'
import { ModalFooter } from './elements/ModalFooter'
import { ReadMore } from './elements/ReadMore'

export const InviteUsersModal = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <InviteUserModalContextProvider>
      <Modal id={ModalId.OrganizationSettingsInviteUsersModal} size="s" closable>
        <Modal.Header title={t('settings.organization.users.invite_user_modal.title')} />
        <Modal.Body>
          <InviteUserForm />
          <ReadMore />
        </Modal.Body>
        <Modal.Footer>
          <ModalFooter />
        </Modal.Footer>
      </Modal>
    </InviteUserModalContextProvider>
  )
}
