import { FontSize, LineHeight, Textarea as TextareaComponent } from '@design-system'

import styled from '@emotion/styled'

export const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
`

export const Textarea = styled(TextareaComponent)`
  font-size: ${FontSize.TextMicro};
  line-height: ${LineHeight.TextMicro};
`
