import colors from './colors'

export default {
  border: `1px solid ${colors.gray3}`,
  borderActive: `1px solid ${colors.blue3}`,
  fontSize: 14,
  color: `${colors.gray5}`,
  placeholderColor: `${colors.primaryGray}`,
  iconColor: `${colors.primaryGray}`,
  iconColorHovered: `${colors.gray3}`,
}
