import { Modal } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EInvoiceContextProvider } from '../../context/eInvoiceContext'
import { EmailContextProvider } from '../../context/emailContext'
import { useSendInvoiceModal } from '../../context/sendInvoiceModalContext'
import { ModalNavigation } from '../ModalNavigation'
import { SendEInvoiceModalContent } from '../SendEInvoiceModalContent'
import { SendEmailInvoiceModalContent } from '../SendEmailInvoiceModalContent'

interface SendInvoiceModalContentProps {
  contactId: string
}

export const SendInvoiceModalContent = ({ contactId }: SendInvoiceModalContentProps): ReactElement => {
  const { t } = useTranslation()
  const { sendMode, canResend } = useSendInvoiceModal()

  const formComponent = useMemo(() => {
    if (sendMode === 'eInvoice' && canResend) {
      return (
        <EInvoiceContextProvider>
          <SendEInvoiceModalContent />
        </EInvoiceContextProvider>
      )
    }

    return (
      <EmailContextProvider contactId={contactId}>
        <SendEmailInvoiceModalContent contactId={contactId} />
      </EmailContextProvider>
    )
  }, [contactId, canResend, sendMode])

  return (
    <>
      <Modal.Header title={t('modal.send_invoice.title')} />

      {canResend && (
        <Modal.Navigation>
          <ModalNavigation />
        </Modal.Navigation>
      )}

      {formComponent}
    </>
  )
}
