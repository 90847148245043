import { LottieAnimation } from '@design-system'

import cc from 'classcat'
import React, { useEffect, useRef } from 'react'
import { Box, BoxProps } from 'rebass'

import { reactClass } from '../../utils'

export type SuccessErrorAnimatedIconProps = BoxProps & {
  success?: boolean
}

export const SuccessErrorAnimatedIcon = ({ className, success, ...rest }: SuccessErrorAnimatedIconProps) => {
  const animationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // No clear guidelines for using Lottie with TypeScript
    const { anim } = animationRef.current as any

    const handleStart = () => {
      anim.loop = false
    }

    handleStart()
  }, [success])

  return (
    <Box className={cc([className, reactClass('success-error-animated-icon')])} {...rest}>
      <LottieAnimation name={success ? 'successIcon' : 'errorIcon'} ref={animationRef} />
    </Box>
  )
}
