import { NavItem, NavItemAccessor } from '@design-system'

import { Currency } from '../types/currency'
import { CurrencyValue } from '../types/currencyValue'

const MOST_USED_CURRENCIES_IDS = ['DKK', 'USD', 'EUR', 'GBP']

const getCurrencyItem = (currency: Currency): NavItem<CurrencyValue> => ({
  id: currency.id,
  children: currency.id,
  searchKeywords: [currency.id, currency.name],
  value: {
    ...currency,
    displayData: {
      title: currency.id,
      description: currency.name,
    },
  },
})

export const getItems = (currencies: Currency[]): NavItem<CurrencyValue>[] => {
  const currnciesMostUsed: Currency[] = []
  const currnciesOther: Currency[] = [...currencies]
  const items: NavItem<CurrencyValue>[] = []

  // Extract most used currencies from 'currnciesOther' array
  for (const currencyId of MOST_USED_CURRENCIES_IDS) {
    const currencyIndex = currnciesOther.findIndex((currency) => currency.id === currencyId)

    if (currencyIndex >= 0) {
      const mostUsedCurrency = currnciesOther.splice(currencyIndex, 1)[0]
      currnciesMostUsed.push(mostUsedCurrency)
    }
  }

  // Prepare items for most used currencies
  for (const mostUsedCurrency of currnciesMostUsed) {
    items.push(getCurrencyItem(mostUsedCurrency))
  }

  // Add a separator
  items.push({
    id: 'separator',
    accessor: NavItemAccessor.Separator,
    children: '',
    value: {} as CurrencyValue,
  })

  // Prepare items for other currencies
  for (const otherCurrency of currnciesOther) {
    items.push(getCurrencyItem(otherCurrency))
  }

  return items
}
