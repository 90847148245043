import { Color, FormItem, Link, Text } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { fetchTransactions } from '@views/transactions'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { useBillView } from '../../contexts/billViewContext'
import * as Styled from './styles'
import { getBillTransactionsQueries } from './utils/getBillTransactionsQueries'

export const BillTransactions = (): ReactElement => {
  const { t } = useTranslation()
  const { billId } = useBillView()
  const { navigate } = useEmberRouter()

  const { queryKey, queryProps } = useMemo(() => getBillTransactionsQueries(billId), [billId])

  const { data, isLoading } = useQuery(queryKey, () => fetchTransactions(queryProps))
  const transactions = data?.transactions || []

  // TODO: a new function is returned each time - it shouldn't be done like that
  const handleTransactionClick = useCallback(
    (transactionId: string) => () => {
      navigate(EmberRoute.Transaction, transactionId)
    },
    [navigate],
  )

  return (
    <FormItem label={t('transactions')} labelAlignment="right">
      {isLoading ? (
        <Styled.SkeletonText width={75} />
      ) : (
        <Text alignment="right">
          {transactions.length > 0 ? (
            transactions.map((transaction, index) => {
              const { id, transactionNo, isVoid, isVoided } = transaction
              const isLastTransaction = index === transactions.length - 1

              return (
                <Text key={id} inline as="span">
                  <Text
                    color={isVoid || isVoided ? Color.Gray90 : Color.DeepSeaGreen}
                    onClick={handleTransactionClick(id)}
                    as="span"
                    inline
                  >
                    <Link withAnimation>{transactionNo?.toString()}</Link>
                  </Text>
                  {!isLastTransaction && <Text inline>, </Text>}
                </Text>
              )
            })
          ) : (
            <Text>—</Text>
          )}
        </Text>
      )}
    </FormItem>
  )
}
