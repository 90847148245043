import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const BookkeepersEmptyView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Content = styled(Text)`
  margin-top: ${Spacing.M};
  margin-bottom: ${Spacing.L};
`
