import { PreviewFile } from '@components-deprecated'

import { AnyAction } from 'redux'

import { BillLine } from '@views/bills'

import { Side } from '../../enums/side'
import { TaxMode } from '../../enums/taxMode'
import { CurrencyInputControlledResult } from '../app'
import { Account } from '../app/accounts/types'
import { Posting } from '../bankReconciliation/types'
import {
  ACCOUNTS_REQUEST,
  FILE_REQUEST,
  TAX_RATES_REQUEST,
  VOUCHER_ACTION_REQUEST,
  VOUCHER_DELETE,
  VOUCHER_DISCARD,
  VOUCHER_REQUEST_MORE_INFO,
  VOUCHERS_NAVIGATION_REQUEST,
  VOUCHERS_WITH_NEED_INFO_REQUEST,
} from './actions'

export enum RequestStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
}

export enum NavDirection {
  Next = 'next',
  Prev = 'prev',
}

export type VoucherListQueryParams = {
  inboxState?: VoucherInboxState
  search?: string
  fromDate?: string
  toDate?: string
  sort?: string
  limit?: number
  offset?: number
  dueDateTo?: Date
  dueDateFrom?: Date
  includeNullDueDate?: boolean
  order?: string
}

export enum VoucherAction {
  archive = 'archive',
  createBill = 'createBill',
  createDaybookTransaction = 'createDaybookTransaction',
  delete = 'delete',
  discard = 'discard',
  duplicate = 'duplicate',
  fetch = 'fetch',
  receive = 'receive',
  record = 'record',
  requireInfo = 'requireInfo',
  update = 'update',
  postpone = 'postpone',
}

export type Pagination = {
  total: number
  limit: number
  offset: number
}

export type InboxGetAction = AnyAction & {
  payload: string
  type: string
}

export type TaxRatesRequestAction =
  | AnyAction
  | {
      payload: string
      type: typeof TAX_RATES_REQUEST
    }

export type AccountsRequestAction =
  | AnyAction
  | {
      payload: string
      type: typeof ACCOUNTS_REQUEST
    }

export type MarkBillRequestAction = AnyAction

export type TaxRate = {
  id: string
  name: string
  rate: number
}

export type InboxActionType = {
  inboxId: string
  action: string
  organizationId: string
}

export type VoucherUpdatePayloadData = {
  bankAccountId?: string
  description?: string
  inboxState?: VoucherInboxState
  dueDate?: Date | null
}

export type VoucherActionRequestType = {
  action: VoucherAction
  organizationId: string
  voucherId: string
  bankLinesIds?: string[]
  data?: any
}

export type VoucherActionRequestFailedType = {
  action: VoucherAction
  voucherId: string
  error?: Error
}

export type VoucherActionReceivedType = {
  action: VoucherAction
  voucherId: string
}

export type VoucherRequestType = {
  id: string
  organizationId: string
}

export type VoucherRequestMoreInfoType = VoucherRequestType & {
  reason: string
  comment?: string
}

export type VoucherDiscardType = VoucherRequestType & {
  reason: string
  comment?: string
}

export type FileRequestType = {
  id: string
}

export type FileRequestAction = {
  payload: FileRequestType
  type: typeof FILE_REQUEST
}

export type VoucherActionRequestAction = {
  payload: VoucherActionRequestType
  type: typeof VOUCHER_ACTION_REQUEST
}

export type VoucherDeleteAction = {
  payload: VoucherRequestType
  type: typeof VOUCHER_DELETE
}

export type VoucherRequestMoreInfoAction = {
  payload: VoucherRequestMoreInfoType
  type: typeof VOUCHER_REQUEST_MORE_INFO
}

export type VoucherDiscardAction = {
  payload: VoucherDiscardType
  type: typeof VOUCHER_DISCARD
}

export type LinePayload = {
  amount: number
  accountId?: string
  taxRateId?: string
  description?: string
}

export type CreateBillPayloadData = {
  contactId?: string
  currencyCode: string
  entryDate?: string
  taxMode: TaxMode
  lines: LinePayload[]
  paymentAccountId?: string
  paymentDate?: string
  userId?: string
}

export type CreateVoucherPayloadData = {
  bankAccountId?: string
  description?: string
  inboxState?: VoucherInboxState
  origin?: VoucherOrigin
  files?: File[]
  bankLineId?: string
}

export type VoucherNavigationRequest = {
  payload: VoucherNavigationRequestPayload
  type: typeof VOUCHERS_NAVIGATION_REQUEST
}

export type VoucherNavigationRequestPayload = {
  organizationId: string
  voucher: Voucher
  sort?: string
}

export type VoucherNavigationReceived = {
  organizationId: string
  vouchersIds: string[]
}

export type GetAmountOfVouchersWithNeedInfoFilterPayload = {
  organizationId: string
}

export type GetAmountOfVouchersWithNeedInfoFilterAction = {
  payload: GetAmountOfVouchersWithNeedInfoFilterPayload
  type: typeof VOUCHERS_WITH_NEED_INFO_REQUEST
}

export type GetAmountOfVouchersWithNeedInfoFilterReceived = {
  amount: number
}

export type BillValue = {
  amount: number
  currencyCode: string
}

export type EntityReference = {
  entityId: string
  entityType: string // probably an enum when we find out
}

export type Supplier = {
  companyId: string
  country: string
  contactId: string
}

export type AccountSuggestion = {
  accountId: string
  probability: number
}

export enum VoucherInboxState {
  RECEIVED = 'received',
  RECORDED = 'recorded',
  ARCHIVED = 'archived',
  DISCARDED = 'discarded',
  NEED_INFO = 'need_info',
  VOIDED = 'voided',
  DELETED = 'deleted',
  DUPLICATED = 'duplicated',
}

export enum VoucherOrigin {
  EMAIL = 'email',
  MOBILE_APP = 'mobile_app',
  WEB_APP = 'web_app',
}

export type Voucher = {
  bankAccountId: string
  bankLineId: string | null
  createdTimestamp: string
  deletedTimestamp: string | null
  description: string
  dueDate: Date | null
  fileReferences: FileReference[]
  id: string
  inboxState: VoucherInboxState
  organizationId: string
  origin: VoucherOrigin
  stateComment: string | null
  stateReason: NeedInfoReasons | null
  updatedTimestamp: string
  voucherReference: VoucherReference | null
}

export type VoucherReference = {
  accountName: string | null
  amount: number | null
  billId: string | null
  contactName: string | null
  createdTimestamp: string
  currencyId: string | null
  daybookTransactionId: string | null
  deletedTimestamp: string | null
  grossAmount: number | null
  id: string
  transactionId: string | null
  updatedTimestamp: string
  userId: string | null
}

export type FileReference = {
  id: string
  fileId: string
  order: number
  url?: string
}

export interface VoucherFileMeta {
  statusCode: number
  success: boolean
  time: number
}

export interface VoucherFile {
  createdTime: string
  dataJson: string
  downloadUrl: string
  fileName: string
  fileSize: number
  fileType: string
  imageHeight: number
  imageWidth: number
  isImage: boolean
  isPdf: boolean
  originalUrl: string
}

export type InboxModalProps = {
  onCancel?: () => void
  isOpen?: boolean
}

export enum DaybookTransactionState {
  Approved = 'approved',
  Draft = 'draft',
  Voided = 'voided',
}

export type JournalLinePayload = {
  accountId?: string
  amount: number
  text: string
  contraAccountId?: string
  side: Side
  priority: number
  taxRateId?: string
}

export type JournalCreatePayloadData = {
  daybookId?: string
  entryDate?: string
  lines: JournalLinePayload[]
  organizationId: string
  state: DaybookTransactionState
  userId?: string
}

export enum InboxRoutes {
  CreateBill = 'create-bill',
  CreateJournal = 'create-journal',
  ViewVoucher = 'view',
}

export enum NeedInfoReasons {
  ImageQuality = 'IMAGE_QUALITY',
  IncorrectReceipt = 'INCORRECT_RECEIPT',
  MissingAmount = 'MISSING_AMOUNT',
  MissingDate = 'MISSING_DATE',
  MissingPaymentMethod = 'MISSING_PAYMENT_METHOD',
  MissingPurpose = 'MISSING_PURPOSE',
  MissingSupplier = 'MISSING_SUPPLIER',
  MissingVAT = 'MISSING_VAT',
  Other = 'OTHER',
}

export enum VoucherStateReasons {
  Duplicate = 'DUPLICATE',
  NotRelevant = 'NOT_RELEVANT',
}

export type VoucherAllReasons = NeedInfoReasons | VoucherStateReasons

export type Bill = {
  amount: number | null
  approvedTime: string | null
  balance: number | null
  contactId: string | null
  contactName: null
  createdTime: string | null
  creditedBillId: null
  currencyId: string | null
  dueDate: string | null
  entryDate: string | null
  exchangeRate: number | null
  grossAmount: number | null
  id: string
  isBare: boolean
  isPaid: boolean
  lineDescription: string
  lineIds: string[] | null
  organizationId: string | null
  paymentAccountId: string | null
  paymentDate: string | null
  scanType: string | null
  scannedCode: string | null
  scannedState: string | null
  scannedValidation: boolean
  source: string | null
  state: string | null
  subject: string | null
  suppliersInvoiceNo: string | null
  tax: number | null
  taxMode: TaxMode | null
  type: string | null
  voucherNo: string | null
}

export type BalanceModifier = {
  amount: number | null
  entryDate: string | null
  id: string
  isVoided: boolean
  modifierReference: string | null
  realizedCurrencyDifference: number | null
  subjectReference: string | null
}

export type BillData = {
  bill: Bill
  billLines: BillLine[]
  balanceModifiers: BalanceModifier[]
}

export type BillsQueryParams = {
  amount?: number
  contactId?: string
  currencyId?: string
  entryDate?: string
  grossAmount?: number
  maxDueDate?: string
  maxEntryDate?: string
  minDueDate?: string
  minEntryDate?: string
}

export type BillsData = {
  bills: Bill[]
}

export type PostingData = {
  postings: Posting[]
}

export type BankPayment = {
  cashAccountId: string
  cashAmount: number
  cashExchangeRate: number
  cashSide: Side
  contactId: string
  createdTime: string
  entryDate: string
  feeAccountId: string | null
  feeAmount: number | null
  id: string
  isVoided: boolean
  organizationId: string
  subjectCurrencyId: string
}

export type BankPaymentData = {
  bankPayment: BankPayment
}

export type CommonLine<T = Account> = {
  account?: T
  amount: CurrencyInputControlledResult
  description?: string
  taxRate?: TaxRate
}

export type VoucherPreviewFile = PreviewFile & {
  id: string
}

export type VoucherCommonData<T = Account> = {
  billDate?: Date
  paidFrom?: T
  lines?: CommonLine<T>[]
  files?: VoucherPreviewFile[]
}

export interface User {
  createdTime: string
  id: string
  lastUsedTime: string
  name: string
  profilePic48Url: string | null
  profilePicFileId: string | null
  profilePicUrl: string | null
  signupCouponId: string | null
  trustpilotReviewData: string | null
}

export interface UserData {
  user: User
}

export interface Daybook {
  defaultContraAccountId: string | null
  id: string
  isArchived: boolean
  isTransactionSummaryEnabled: boolean
  name: string
  organizationId: string
}

export interface DaybookData {
  daybooks: Daybook[]
}
