import { Color, Text } from '@design-system'

import React, { ReactElement } from 'react'

import * as Styled from './styles'

interface StepProps {
  content: string
  isChecked: boolean
  stepNumber: number
}

export const Step = ({ content, isChecked, stepNumber }: StepProps): ReactElement => {
  return (
    <Styled.StepWrapper>
      <Styled.Number isChecked={isChecked}>
        {isChecked ? (
          <Styled.Checkmark width={12} />
        ) : (
          <Text colorVariant="secondary" variant="large">
            {stepNumber}
          </Text>
        )}
      </Styled.Number>
      <Styled.Content color={isChecked ? Color.Green : Color.Gray90} weight="medium" variant="micro">
        {content}
      </Styled.Content>
    </Styled.StepWrapper>
  )
}
