import { useQuery } from 'react-query'

import { fetchFile } from '@views/umbrella/routes/umbrellaProfile/query-api'

import { QueryKeys } from '../../../../../enums/queryKeys'

interface UseProfilePicResponse {
  isLoading: boolean
  url?: string
}

export const useProfilePic = (profilePicId?: string): UseProfilePicResponse => {
  const {
    data,
    isIdle,
    isLoading: isFileLoading,
  } = useQuery([QueryKeys.UserProfilePic, profilePicId], () => fetchFile(profilePicId as string), {
    enabled: !!profilePicId,
    keepPreviousData: true,
  })

  const isLoading = !!profilePicId && (isIdle || isFileLoading)

  return {
    isLoading,
    url: profilePicId ? data?.file.downloadUrl : undefined,
  }
}
