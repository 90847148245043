/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Input /> from design-system instead
 */
import { Input as InputRebass, InputProps as RebassInputProps } from '@rebass/forms'
import cc from 'classcat'
import get from 'lodash/get'
import React from 'react'

import { ConnectForm, ConnectFormType } from './ConnectForm'
import { ErrorMessage } from './ErrorMessage'

export type FormInputProps = {
  options?: { required?: boolean | string; [key: string]: undefined | boolean | string | {} }
  required?: boolean
  silent?: boolean // Used internally by FormGroup to silence error messages on the single input
  disconnect?: boolean
  errors?: {} // Inherited from context
  register?: Function // Inherited from context
}

export type BaseInputProps = FormInputProps &
  RebassInputProps & {
    errorPath?: string
    defaultValue?: string | number | string[]
    label?: string
    name: string
  }

const ActualInput = React.forwardRef(({ ...rest }: BaseInputProps, ref) => <InputRebass ref={ref} {...rest} />)

export const BaseInput = ({
  autoComplete = 'off',
  className,
  disabled,
  disconnect,
  id,
  label,
  name,
  options = {},
  placeholder,
  required,
  type = 'text',
  errorPath,
  silent,
  value,
  ...rest
}: BaseInputProps) => (
  <ConnectForm>
    {({ formState, register }: ConnectFormType) => {
      const errors = formState?.errors || {}
      const error = errorPath ? get(errors, errorPath) : errors[name]

      return (
        <>
          <ActualInput
            autoComplete={autoComplete}
            className={cc([className, { invalid: error }])}
            disabled={disabled}
            id={id || name}
            name={name}
            placeholder={placeholder}
            type={type}
            value={value}
            {...(!disconnect && register ? register(name, { required, ...options }) : undefined)}
            {...rest}
          />
          {!silent && <ErrorMessage error={error} />}
        </>
      )
    }}
  </ConnectForm>
)
