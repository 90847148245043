import { BrandProtectedComponent, ProtectedComponent } from '@components'
import { ModuleLayout } from '@design-system'

import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ExternalInvoicesSyncDashboardBanner } from '@features/externalInvoicesSync'
import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { AgerasFinanceCustomerContextProvider } from '@views/financing/AgerasFinanceLending'

import { GlobalFiscalYearContextProvider } from '../../contexts/globalFiscalYearContext'
import { Scope } from '../../enums/scope'
import { useSegment } from '../../hooks'
import { AnnualReports } from './banner/AnnualReports'
import { FinancingApplication } from './banner/FinancingApplication'
import { BankWidgetContextProvider } from './contexts/bankWidgetContext'
import { DashboardActions } from './elements/DashboardActions'
import { DashboardWidgetsEditAction } from './elements/DashboardWidgetsEditAction'
import { WidgetsList } from './elements/WidgetsList'

interface DashboardProps {
  organizationId: string
}

export const Dashboard = ({ organizationId }: DashboardProps): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()

  useEffect(() => {
    track('Dashboard Viewed (FE)')
  }, [track])

  return (
    <UserOrganizationContextProvider organizationId={organizationId}>
      <GlobalFiscalYearContextProvider organizationId={organizationId}>
        <BankWidgetContextProvider organizationId={organizationId}>
          <AgerasFinanceCustomerContextProvider organizationId={organizationId}>
            <ModuleLayout
              title={t('dashboard')}
              sideActions={<DashboardActions />}
              headerContent={
                <ProtectedComponent scopes={Scope.DashboardWrite}>
                  <DashboardWidgetsEditAction />
                </ProtectedComponent>
              }
            >
              <FinancingApplication />
              <AnnualReports />
              <BrandProtectedComponent brand="ageras">
                <ExternalInvoicesSyncDashboardBanner />
              </BrandProtectedComponent>
              <WidgetsList />
            </ModuleLayout>
          </AgerasFinanceCustomerContextProvider>
        </BankWidgetContextProvider>
      </GlobalFiscalYearContextProvider>
    </UserOrganizationContextProvider>
  )
}
