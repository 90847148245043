import { NavItem } from '@design-system'

import { getMonthNames } from '../utils/getMonthNames'

export const getMonthItems = (locale: string): NavItem<number>[] => {
  const monthNames = getMonthNames(locale)
  return monthNames.map((monthName, monthIndex) => {
    return {
      id: String(monthIndex),
      children: monthName,
      value: monthIndex,
    }
  })
}
