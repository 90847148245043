import { StatusFormatterVariant } from '@components'

import { InvoiceStatus } from '../../../../../../../enums/invoiceStatus'

export const invoiceStatusToStatusFormatterVariant: Record<
  InvoiceStatus,
  StatusFormatterVariant | [StatusFormatterVariant, StatusFormatterVariant]
> = {
  [InvoiceStatus.Overdue]: 'error',
  [InvoiceStatus.Paid]: 'success',
  [InvoiceStatus.Unpaid]: 'warning',
  [InvoiceStatus.All]: 'neutral',
}
