import { UpgradeSubscriptionModal } from '@components'
import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'

import { queryClient } from '../../config/queryClient'
import { EmberCloseModalContextProvider, EmberModal } from '../../contexts/emberCloseModalContext'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { ModalId } from '../../enums/modalId'
import { TrackingContext } from '../../enums/trackingContext'
import { useComponentId } from '../../hooks/useComponentId'

class UpgradeSubscriptionModalWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @byAttrVal trackingContext: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('closeEmber') closeEmber: Function
}

type UpgradeSubscriptionModalModuleProps = {
  organizationId: string
  trackingContext: TrackingContext
  onNavigate: EmberNavigate
  onCloseEmber: EmberModal
}

function UpgradeSubscriptionModalModule({
  organizationId,
  trackingContext,
  onNavigate,
  onCloseEmber,
}: UpgradeSubscriptionModalModuleProps): ReactElement {
  const modalId = useComponentId(ModalId.UpgradeSubscriptionModal)

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <EmberRouterContextProvider onNavigate={onNavigate}>
          <EmberCloseModalContextProvider onCloseModal={onCloseEmber}>
            <UserOrganizationContextProvider organizationId={organizationId}>
              <UserOrganizationSettingsContextProvider organizationId={organizationId}>
                <CurrentUserContextProvider>
                  <ModalContextProvider>
                    <UpgradeSubscriptionModal id={modalId} trackingContext={trackingContext} forceRender />
                  </ModalContextProvider>
                </CurrentUserContextProvider>
              </UserOrganizationSettingsContextProvider>
            </UserOrganizationContextProvider>
          </EmberCloseModalContextProvider>
        </EmberRouterContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

const UpgradeSubscriptionModalCustomElement = createCustomElement(
  UpgradeSubscriptionModalModule,
  UpgradeSubscriptionModalWebComponent,
  'element',
)

customElements.get('upgrade-subscription-modal-module') ||
  customElements.define('upgrade-subscription-modal-module', UpgradeSubscriptionModalCustomElement)

export default UpgradeSubscriptionModalCustomElement
