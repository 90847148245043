import { css } from '@emotion/core'
import cc from 'classcat'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box } from 'rebass'

import { getClassName } from '../../../utils/getClassName'
import { BaseInput, InputProps } from '../../Form'

export type SelectInputProps = InputProps & {
  disconnect?: boolean
  onTextChange?: (text: string) => void
  iconUrl?: string
  errorPath?: string
  isMenuVisible?: boolean
  name: string
}

export const SelectInput = React.forwardRef(
  (
    {
      disabled,
      disconnect,
      errorPath,
      iconUrl,
      inputSize,
      isMenuVisible,
      name,
      onBlur,
      onFocus,
      onTextChange = () => null,
      placeholder,
      required,
      value,
    }: SelectInputProps,
    ref,
  ) => {
    const [inputText, setInputText] = useState(value)

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInputText(e.target.value)
      onTextChange(e.target.value)
    }

    useEffect(() => {
      setInputText(value)
    }, [value])

    return (
      <>
        {iconUrl && (
          <Box
            as="img"
            width="21px"
            alt={''}
            src={iconUrl}
            css={css`
              position: absolute;
              top: 8px;
              left: 6px;
            `}
          />
        )}
        <BaseInput
          ref={ref}
          name={name}
          placeholder={placeholder}
          type="text"
          pl={iconUrl ? 31 : undefined}
          pr={7}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={handleOnChange}
          value={inputText}
          autoComplete="none"
          required={required}
          disconnect={disconnect}
          errorPath={errorPath}
          className={cc([
            'for-select',
            getClassName('input-deprecated'),
            { focus: isMenuVisible, big: inputSize === 'xl' },
          ])}
          disabled={disabled}
        />
      </>
    )
  },
)
