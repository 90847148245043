import { useCallback } from 'react'

import { useWindowOpen } from '../../../../../hooks/useWindowOpen'
import { WindowFeatures } from '../../../../../types/windowFeatures'
import { useGetStripeConnectUrl } from './useGetStripeConnectUrl'
import { useStripePopupListener } from './useStripePopupListener'

const POPUP_WIDTH = 800
const POPUP_HEIGHT = 600
const POPUP_NAME = 'StripeConnect'

const stripePopupWindowFeatures: WindowFeatures = {
  scrollbars: 1,
  centerscreen: 1,
  width: POPUP_WIDTH,
  height: POPUP_HEIGHT,
}

interface UseStripePopupWindowProps {
  onSuccess: (authorizatinoCode: string) => void
}

interface UseStripePopupWindowReturn {
  isOpen: boolean
  open: () => void
}

export const useStripePopupWindow = ({ onSuccess }: UseStripePopupWindowProps): UseStripePopupWindowReturn => {
  const { stripeConnectUrl, isLoading } = useGetStripeConnectUrl()

  const {
    isOpen,
    open: openStripePopupWindow,
    windowHandler: popupWindow,
  } = useWindowOpen({ url: stripeConnectUrl, target: POPUP_NAME, windowFeatures: stripePopupWindowFeatures })

  const { startListening: startListeningForStripeResponse } = useStripePopupListener({ popupWindow, onSuccess })

  const open = useCallback(() => {
    if (isLoading) {
      return
    }

    openStripePopupWindow()
    startListeningForStripeResponse()
  }, [openStripePopupWindow, startListeningForStripeResponse, isLoading])

  return {
    isOpen,
    open,
  }
}
