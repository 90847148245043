import { css } from '@emotion/core'

export const getAnimateRotationStyle = () => css`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  will-change: transform;
  animation: spinner 1500ms infinite linear;
`
