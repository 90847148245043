import { Button, ButtonsGroup, Link, Modal, Space, Text, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { WarningModalRequiredProps } from '../../../../../../hooks/useWarningModals'

type AccountingBasisConfirmationModalProps = WarningModalRequiredProps

export const AccountingBasisConfirmationModal = ({ onOk, id }: AccountingBasisConfirmationModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close } = useModal(id)

  const handleSave = useCallback(() => {
    onOk?.(id)
    close()
  }, [close, id, onOk])

  const handleCancel = useCallback(() => {
    close()
  }, [close])

  return (
    <Modal closable id={id} size="s">
      <Modal.Header
        title={t('settings.organization.accounting.accounting_basis_confirmation_modal.title')}
      ></Modal.Header>
      <Modal.Body>
        <Trans i18nKey="settings.organization.accounting.accounting_basis_confirmation_modal.text">
          <Text>
            You're attempting to switch accounting basis. Doing so has consequences for your future accounting.
          </Text>
          <Space />
          <Text>
            <Link
              href="https://www.ageras.com/fr/support/article-2-comptabilite-tresorerie-exercice"
              target="_blank"
              underlined
              withAnimation
            >
              Want to know more?
            </Link>
          </Text>
        </Trans>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={handleCancel}>
            {t('cancel')}
          </Button>
          <Button onClick={handleSave}>
            {t('settings.organization.accounting.accounting_basis_confirmation_modal.ok')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
