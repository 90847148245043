import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const User = styled.div`
  width: 100%;
  display: flex;
  margin: ${Spacing.M} 0;
  padding: ${Spacing.M} ${Spacing.L};
  align-items: center;
  background-color: ${Color.Gray20};
  border-radius: ${DefaultValue.BorderRadius};
  text-align: left;
`
