import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface TitleProps {
  children: ReactNode
}

export const Title = ({ children }: TitleProps): ReactElement => (
  <Styled.TitleWrapper colorVariant="primary" variant="h3">
    {children}
  </Styled.TitleWrapper>
)
