import { Button, Modal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateOrEditUmbrellaRole } from '../../../../context/createOrEditUmbrellaRoleContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { submitForm } = useCreateOrEditUmbrellaRole()

  const handleSubmitButtonClick = useCallback(() => {
    submitForm()
  }, [submitForm])

  return (
    <Modal.Footer>
      <Button onClick={handleSubmitButtonClick}>{t('umbrella_roles.edit_roles_modal.save')}</Button>
    </Modal.Footer>
  )
}
