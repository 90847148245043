import { isSameDay } from 'date-fns'
import React, { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Timeout } from '../../types/timeout'
import { SHAKE_ANIMATION_TIME_MS } from '../../utils/shakeAnimation'
import { DATE_PICKER_WIDTH, DATE_PICKER_YEAR_DROPDOWN_ID, DatePicker, DatePickerProps } from '../DatePicker'
import { Dropdown, DropdownProps, DropdownRef } from '../Dropdown'
import { Icon } from '../Icon'
import { InputProps, InputRef } from '../Input'
import { DATE_INPUT_DROPDOWN_ID } from './constants/dateInputDropdownId'
import { SHAKE_CLASS_NAME } from './constants/shakeClassName'
import * as Styled from './styles'
import { getDisplayDate } from './utils/getDisplayDate'
import { getParsedDateFromString } from './utils/getParsedDateFromString'
import { isValidDate } from './utils/isValidDate'

const DATE_PICKER_PREVIEW_DATE_SIGNS_LIMIT = 6

export interface DateInputProps
  extends Pick<
      DatePickerProps,
      'defaultValue' | 'firstDayOfWeek' | 'isDateBlocked' | 'maxDate' | 'minDate' | 'unavailableDates' | 'value'
    >,
    Pick<
      InputProps,
      | 'alignment'
      | 'allowClear'
      | 'autoCompleted'
      | 'autoFocus'
      | 'bordered'
      | 'className'
      | 'disabled'
      | 'error'
      | 'focused'
      | 'hidden'
      | 'id'
      | 'name'
      | 'onBlur'
      | 'onClear'
      | 'onPressEnter'
      | 'placeholder'
      | 'prefix'
      | 'selectOnFocus'
      | 'size'
      | 'success'
    > {
  datePickerId?: DropdownProps['id']
  datePickerPlacement?: DropdownProps['placement']
  isOpen?: boolean
  onChange?: (date?: Date) => void
  onClose?: () => void
  onOpen?: () => void
  shakeOnNotValid?: boolean
}

export const DateInput = ({
  // DateInput props
  defaultValue,
  onChange,
  shakeOnNotValid = true,
  value,
  // DatePicker props
  firstDayOfWeek,
  isDateBlocked,
  maxDate,
  minDate,
  unavailableDates,
  // Input props
  alignment,
  allowClear,
  autoCompleted,
  autoFocus,
  bordered,
  className,
  disabled,
  error,
  focused,
  hidden,
  id,
  name,
  onBlur,
  onClear,
  onPressEnter,
  placeholder,
  prefix,
  selectOnFocus = true,
  size,
  success,
  // Dropdown props
  datePickerId,
  datePickerPlacement,
  isOpen: isOpenControlled = false,
  onClose,
  onOpen,
}: DateInputProps) => {
  const { t } = useTranslation()
  const [dateValue, setDateValue] = useState(defaultValue || value)
  const [inputValue, setInputValue] = useState(getDisplayDate(dateValue) || '')
  const [isOpen, setIsOpen] = useState(isOpenControlled)
  const [previewDateValue, setPreviewDateValue] = useState<Date | undefined>() // used to link currently typed 'inputValue' with Date Picker state
  // helper for not clearing data and on blur, after selecting some item (check handleInputBlur).
  // Also fix for clicking on the edge in the input to not close it just after clicking
  const isDateClickedRef = useRef(false)
  const isDropdownClosedManually = useRef(false)
  const dropdownRef = useRef<DropdownRef<InputRef>>(null)
  const shakeTimeoutRef = useRef<Timeout>()

  // Helper functions

  const setInputValueFormatted = useCallback((date?: Date) => {
    setInputValue(getDisplayDate(date))
  }, [])

  // Mutations

  useEffect(() => {
    return () => {
      if (shakeTimeoutRef.current) {
        clearTimeout(shakeTimeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    setIsOpen(isOpenControlled)
  }, [isOpenControlled])

  useEffect(() => {
    if (inputValue.length < DATE_PICKER_PREVIEW_DATE_SIGNS_LIMIT) {
      setPreviewDateValue(undefined)
      return
    }

    const parsedDate = getParsedDateFromString(inputValue)
    const isValid = isValidDate(parsedDate, { isDateBlocked, unavailableDates, minDate, maxDate })
    setPreviewDateValue(isValid ? parsedDate : undefined)
    // // should be only triggered on 'inputValue' change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  useEffect(() => {
    // we check isSameDay as our DatePicker doesn't handle hours
    if (dateValue && value && isSameDay(dateValue, value)) {
      return
    }

    setDateValue(value)
    setInputValueFormatted(value)
    setPreviewDateValue(undefined)
    // should be only triggered on 'value' change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  // Functions

  const shakeInput = useCallback(() => {
    dropdownRef.current?.trigger?.wrapper?.classList.add(SHAKE_CLASS_NAME)

    shakeTimeoutRef.current = setTimeout(() => {
      dropdownRef.current?.trigger?.wrapper?.classList.remove(SHAKE_CLASS_NAME)
    }, SHAKE_ANIMATION_TIME_MS)
  }, [])

  const openDropdown = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closeDropdown = useCallback(() => {
    isDropdownClosedManually.current = true // optimization to not call 'handleDropdownClose' redundantly
    setIsOpen(false)
  }, [])

  const toggleDropdown = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }, [])

  const changeDate = useCallback(
    (date?: Date) => {
      setDateValue(date)
      onChange?.(date)
      setInputValueFormatted(date)
      setPreviewDateValue(undefined)
      closeDropdown()
    },
    [onChange, setInputValueFormatted, closeDropdown],
  )

  const setDateFromInputValue = useCallback(
    (inputValue: string) => {
      const parsedDate = getParsedDateFromString(inputValue)
      const isValid = isValidDate(parsedDate, { isDateBlocked, unavailableDates, minDate, maxDate })
      const isSameDate = dateValue && isSameDay(parsedDate, dateValue)

      if (!inputValue) {
        changeDate(undefined)
        return
      }

      if (isSameDate) {
        setInputValueFormatted(dateValue)
        return
      }

      if (isValid) {
        changeDate(parsedDate)
      } else {
        if (dateValue) {
          setInputValueFormatted(dateValue)
        } else {
          changeDate(undefined)
        }

        if (shakeOnNotValid) {
          shakeInput()
        }
      }
    },
    [
      changeDate,
      dateValue,
      isDateBlocked,
      maxDate,
      minDate,
      setInputValueFormatted,
      shakeInput,
      shakeOnNotValid,
      unavailableDates,
    ],
  )

  // Handlers

  const handleInputClear = useCallback(() => {
    changeDate(undefined)
    onClear?.()
  }, [changeDate, onClear])

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setInputValue(value)
  }, [])

  const handleDatePickerChange = useCallback(
    (date: Date | null) => {
      if (date) {
        changeDate(date)
      }
    },
    [changeDate],
  )

  const handleInputEnterPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      setDateFromInputValue?.(inputValue)
      onPressEnter?.(event)
      closeDropdown()
    },
    [closeDropdown, inputValue, onPressEnter, setDateFromInputValue],
  )

  const handleInputMouseDown = useCallback(() => {
    if (!disabled) {
      isDateClickedRef.current = true
      toggleDropdown()
    }
  }, [toggleDropdown, disabled])

  const handleDropdownMouseDown = useCallback(() => {
    isDateClickedRef.current = true
  }, [])

  const handleInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (isDateClickedRef.current) {
        isDateClickedRef.current = false
      } else {
        closeDropdown()
      }

      if (event.target.value) {
        setDateFromInputValue(event.target.value)
      }

      onBlur?.(event)
    },
    [closeDropdown, onBlur, setDateFromInputValue],
  )

  const handleDropdownOpen = useCallback(() => {
    openDropdown()
    onOpen?.()
  }, [openDropdown, onOpen])

  const handleDropdownClose = useCallback(() => {
    if (isDropdownClosedManually.current) {
      isDropdownClosedManually.current = false
    } else {
      setDateFromInputValue?.(inputValue)
    }

    closeDropdown()
    onClose?.()
  }, [setDateFromInputValue, inputValue, closeDropdown, onClose])

  return (
    <Dropdown
      id={datePickerId || DATE_INPUT_DROPDOWN_ID}
      childrenIds={[DATE_PICKER_YEAR_DROPDOWN_ID]}
      autoToggle={false}
      isOpen={isOpen}
      onMouseDown={handleDropdownMouseDown}
      onClose={handleDropdownClose}
      onOpen={handleDropdownOpen}
      trigger={
        <Styled.Input
          alignment={alignment}
          allowClear={allowClear}
          autoComplete="off"
          autoCompleted={autoCompleted}
          autoFocus={autoFocus}
          bordered={bordered}
          className={className}
          disabled={disabled}
          error={error}
          focused={focused}
          hidden={hidden}
          id={id}
          name={name}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          onClear={handleInputClear}
          onMouseDown={handleInputMouseDown}
          onPressEnter={handleInputEnterPress}
          placeholder={placeholder !== undefined ? placeholder : t('ds.date_input.placeholder')}
          prefix={prefix}
          selectOnFocus={selectOnFocus}
          size={size}
          success={success}
          suffix={<Icon icon="calendar" />}
          value={inputValue}
        />
      }
      placement={datePickerPlacement || 'bottom-start'}
      ref={dropdownRef}
      width={DATE_PICKER_WIDTH}
    >
      <DatePicker
        firstDayOfWeek={firstDayOfWeek}
        isDateBlocked={isDateBlocked}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleDatePickerChange}
        unavailableDates={unavailableDates}
        value={previewDateValue || dateValue}
      />
    </Dropdown>
  )
}
