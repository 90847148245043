import { PORTAL_CLASS_NAME } from '@design-system'

import { useTheme } from 'emotion-theming'
import React, { memo, MouseEvent, ReactElement, useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useKey } from 'react-use'

import { Theme } from '../../../types/theme'
import { IconCloseV2 as IconClose } from '../../Icons'
import { FilesPreviewContent } from '../FilesPreviewContent'
import { PreviewFile } from '../types'
import * as Styled from './styles'

interface FullScreenPreviewProps {
  files: PreviewFile[]
  onClose?: () => void
  visible?: boolean
}

export const FullScreenPreview = memo(({ onClose, files, visible }: FullScreenPreviewProps): ReactElement => {
  const theme = useTheme<Theme>()
  const contentRef = useRef<HTMLDivElement>(null)

  const handleEscPress = useCallback(() => {
    if (visible) {
      onClose && onClose()
    }
  }, [onClose, visible])

  const handleBackgroundClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const clickedElement = event.target as HTMLElement

      if (contentRef.current && contentRef.current !== clickedElement && !contentRef.current.contains(clickedElement)) {
        onClose && onClose()
      }
    },
    [contentRef, onClose],
  )

  useKey('Escape', handleEscPress, undefined, [visible])

  return ReactDOM.createPortal(
    <Styled.FullScreenWrapper className={PORTAL_CLASS_NAME} onClick={handleBackgroundClick} visible={visible}>
      <Styled.ModalWrapper ref={contentRef}>
        <Styled.CloseWrapper onClick={onClose}>
          <IconClose width={30} height={30} color={theme.colors.text.default} />
        </Styled.CloseWrapper>
        <FilesPreviewContent
          fitToHeight
          height="100%"
          width="100%"
          loading=""
          files={files}
          withDownloadButton
          withRotate
        />
      </Styled.ModalWrapper>
    </Styled.FullScreenWrapper>,
    document.body,
  )
})
