import { TransactionOriginatorType } from '../enums/transactionOriginatorType'
import { TransactionOriginatorTypeGroup } from '../enums/transactionOriginatorTypeGroup'

export const transactionOriginatorTypeGroupToTypes: Record<
  TransactionOriginatorTypeGroup,
  TransactionOriginatorType[]
> = {
  [TransactionOriginatorTypeGroup.Bills]: [TransactionOriginatorType.Bill],
  [TransactionOriginatorTypeGroup.Invoices]: [
    TransactionOriginatorType.Invoice,
    TransactionOriginatorType.InvoiceLateFee,
    TransactionOriginatorType.ExternalInvoice,
  ],
  [TransactionOriginatorTypeGroup.Journals]: [
    TransactionOriginatorType.ConversionBalance,
    TransactionOriginatorType.DaybookTransaction,
  ],
  [TransactionOriginatorTypeGroup.Payments]: [
    TransactionOriginatorType.BankPayment,
    TransactionOriginatorType.ContactBalancePayment,
  ],
  [TransactionOriginatorTypeGroup.Vat]: [
    TransactionOriginatorType.SalesTaxPayment,
    TransactionOriginatorType.SalesTaxReturn,
  ],
}
