import { Color, Spacing, Text as TextComponent } from '@design-system'

import styled from '@emotion/styled'

export const BodyWrapper = styled.div`
  display: flex;
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding: ${Spacing.L} ${Spacing.L} 0;
  background-color: ${Color.Purple20};
`

export const ContentContainer = styled.div`
  width: 596px;
  padding: ${Spacing.XXXL} ${Spacing.XXL} ${Spacing.XXL};
`

export const BulletList = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text = styled(TextComponent)`
  cursor: pointer;
`

export const BenefitItem = styled.div`
  display: flex;
`

export const IconContainer = styled.div`
  margin-right: ${Spacing.XS};
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
