import { ButtonHTMLAttributes, HTMLAttributeAnchorTarget, ReactElement, ReactNode, useCallback, useMemo } from 'react'

import { Asable } from '../../../../types/asable'
import { LinkProps } from '../../../../types/linkProps'
import { getLinkProps } from '../../../../utils/getLinkProps'
import { ButtonIcon, ButtonIconProps } from '../../../Button/ButtonIcon'
import { IconName } from '../../../Icon'
import * as Styled from './styles'
import { Size } from './types/size'

export type TabButtonSize = Size

export interface TabButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'prefix'>, Asable {
  children?: ReactNode
  disabled?: boolean
  fitToWidth?: boolean
  href?: string
  icon?: IconName
  loading?: boolean
  onClick?: () => void
  prefix?: ReactNode
  selected?: boolean
  size?: Size
  suffix?: ReactNode
  target?: HTMLAttributeAnchorTarget
  to?: string
}

export const TabButton = ({
  as,
  children,
  fitToWidth,
  disabled,
  href,
  icon,
  loading,
  onClick,
  prefix,
  selected,
  size = 'l',
  suffix,
  target,
  type = 'button',
  to,
  ...rest
}: TabButtonProps): ReactElement => {
  const buttonIconProps = useMemo(
    (): Pick<ButtonIconProps, 'size' | 'withIconMargin'> => ({
      size,
      withIconMargin: !!children,
    }),
    [children, size],
  )
  const linkProps: LinkProps | undefined = useMemo(
    () =>
      getLinkProps({
        href,
        to,
        as,
        target,
      }),
    [href, to, as, target],
  )

  const handleOnClick = useCallback(() => {
    if (!disabled) {
      onClick?.()
    }
  }, [disabled, onClick])

  return (
    <Styled.TabButtonWrapper
      disabled={disabled}
      fitToWidth={fitToWidth}
      onClick={handleOnClick}
      selected={selected}
      size={size}
      type={type}
      {...linkProps}
      {...rest}
    >
      {prefix && <Styled.PrefixWrapper>{prefix}</Styled.PrefixWrapper>}
      <ButtonIcon {...buttonIconProps} icon={icon} loading={loading} position="left" isVisible={loading || !!icon} />
      {children}
      {suffix && <Styled.SuffixWrapper>{suffix}</Styled.SuffixWrapper>}
    </Styled.TabButtonWrapper>
  )
}
