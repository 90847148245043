import { useMutation } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { APIError } from '../../../../../utils'
import { inviteBookkeeper } from '../query-api'

interface UseInviteBookkeeperArgs {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useInviteBookkeeper = ({ onError, onSuccess }: UseInviteBookkeeperArgs = {}) => {
  const { organization } = useUserOrganization()
  const { mutate: invite, isLoading: isSaving } = useMutation(
    (umbrellaId: string) => inviteBookkeeper(organization?.id || '', umbrellaId),
    { onError, onSuccess },
  )

  return {
    invite,
    isSaving,
  }
}
