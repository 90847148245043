import { useTheme } from 'emotion-theming'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from '../../../../types/theme'
import { Text as TextComponent } from '../../../Typography'

interface FileErrorProps {
  fileName?: string
  errorMessage?: string
  withMargin?: boolean
}

export const FileError = ({ fileName, errorMessage, withMargin = false }: FileErrorProps): ReactElement | null => {
  const theme = useTheme<Theme>()
  const { t } = useTranslation()

  if (!fileName || !errorMessage) {
    return null
  }

  return (
    <TextComponent ml={withMargin ? '20px' : 0} color={theme.colors.warning}>
      <strong>{fileName || t('error')}</strong>
      {' - '}
      {errorMessage}
    </TextComponent>
  )
}
