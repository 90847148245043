import isEmpty from 'lodash/isEmpty'
import { parse } from 'qs'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-use'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { BillingType } from '@modules-deprecated/app/organization/enums/billingType'
import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'
import { AddonSubscription } from '@modules-deprecated/app/organization/types/addonSubscription'
import { Discount } from '@modules-deprecated/app/organization/types/discount'
import { PaymentMethod } from '@modules-deprecated/app/organization/types/paymentMethod'
import { fetchCards, fetchPaymentMethod, fetchSubscriptionInfo } from '@modules-deprecated/app/payment/query-api'
import { Card } from '@modules-deprecated/app/payment/types'
import { fetchSubscription } from '@views/settings/routes/OrganizationSubscriptionSettings/services/query-api'

import { QueryKeys } from '../enums/queryKeys'
import { isExpired } from '../utils/payment'
import { isSwitchingPlan, startsInFuture } from '../utils/subscription'
import { useOrganizationBrand } from './useOrganizationBrand'

interface UseOrganizationSubscriptionResult {
  isLoading: boolean
  organizationId?: string
  billingType: BillingType
  switchPlan: SubscriptionPlan
  switchPlanPeriod: SubscriptionPeriod
  isSwitchingPlan: boolean
  planExpirationDate: string | null
  isLocked?: boolean
  isTrial: boolean
  isOwnBilling: boolean
  isExternalBilling: boolean
  subscriptionPlan: SubscriptionPlan
  planFullName: string
  isStandardPlan: boolean
  subscriptionPeriod: SubscriptionPeriod
  isTrialNoPlanSelected: boolean
  subscriptionPricePerMonth: number
  isYearly: boolean
  isMonthly: boolean
  couponApplied: boolean
  couponCode?: string | null
  couponPeriod?: string | null
  defaultActivePaymentMethod?: PaymentMethod
  defaultActivePaymentCard?: Card
  couponDiscountPercent?: number | null
  subscriptionDiscount: number
  cardAdded: boolean
  cardInvalid: boolean
  cardType?: string
  cardLabel?: string
  cardExpirationDate?: string
  isSubscriptionUpdating: boolean
  AddonSubscriptions?: AddonSubscription[]
  discount: Discount | null
  hasAnnualReportsSubscription: boolean
}

const CALLS_TO_REFETCH = 5
const REFETCH_INTERVAL = 1000
const STANDARD_PLANS = [SubscriptionPlan.Basic, SubscriptionPlan.Pro, SubscriptionPlan.Premium, SubscriptionPlan.Free]

export const useOrganizationSubscription = (): UseOrganizationSubscriptionResult => {
  const { search } = useLocation()
  const { organization } = useUserOrganization()
  const queryParams = parse(search || '', { ignoreQueryPrefix: true })
  const refetchCount = useRef(0)
  const [shouldRefetchSubscriptionData, setShouldRefetchSubscriptionData] = useState(false)
  const organizationId = organization?.id
  const { isBrandBilly } = useOrganizationBrand()
  const { data: subscription, isLoading: isLoadingSubscription } = useQuery(
    [QueryKeys.OrganizationSubscription, organizationId],
    () => fetchSubscription(organizationId as string),
    {
      enabled: !!organizationId,
    },
  )

  const billingType = subscription?.organization.billingType || BillingType.External
  const isOwnBilling = billingType === BillingType.Own
  const isExternalBilling = billingType === BillingType.External

  const {
    data: subscriptionInfo,
    isFetching: isFetchingSubscriptionInfo,
    isLoading: isLoadingSubscriptionInfo,
  } = useQuery(
    [QueryKeys.OrganizationSubscriptionInfo, organizationId],
    () => fetchSubscriptionInfo(organizationId as string),
    {
      enabled: !!organizationId && isExternalBilling && !isLoadingSubscription,
      refetchInterval: shouldRefetchSubscriptionData ? REFETCH_INTERVAL : false,
    },
  )

  const { data: defaultActivePaymentMethod, isLoading: isLoadingDefaultActivePaymentMethod } = useQuery(
    [QueryKeys.PaymentMethod, organizationId],
    () => fetchPaymentMethod(organizationId as string),
    {
      enabled: !!organizationId && isExternalBilling && !isLoadingSubscription,
    },
  )

  const { data: cardsData, isLoading: isLoadingCardsData } = useQuery(
    [QueryKeys.Cards, organizationId],
    () => fetchCards(organizationId as string),
    {
      enabled: !!organizationId && isOwnBilling && !isLoadingSubscription,
    },
  )

  useEffect(() => {
    const shouldRefetchSubscriptionStatus = queryParams?.payment_successful === '1'

    setShouldRefetchSubscriptionData(shouldRefetchSubscriptionStatus)
  }, [queryParams?.payment_successful])

  useEffect(() => {
    // if no flag in query params - do not refetch anything
    if (!shouldRefetchSubscriptionData) {
      return
    }

    // if pending upgrade - refresh infinitely until status changes
    if (subscriptionInfo?.isPendingUpgrade) {
      refetchCount.current = 0
      return
    }

    // if not pending upgrade - just refetch couple of times to make sure everything is updated
    if (!subscriptionInfo?.isPendingUpgrade) {
      refetchCount.current += 1
    }

    // if refetched more than CALLS_TO_REFETCH - stop the process, it means that subscription is updated
    if (refetchCount.current > CALLS_TO_REFETCH) {
      setShouldRefetchSubscriptionData(false)
    }
  }, [isFetchingSubscriptionInfo, shouldRefetchSubscriptionData, subscriptionInfo?.isPendingUpgrade])

  const isLoading =
    isLoadingSubscription || isLoadingSubscriptionInfo || isLoadingDefaultActivePaymentMethod || isLoadingCardsData

  const defaultActivePaymentCard = (cardsData || []).find((card: Card) => card.isDefault)
  const isSubscriptionUpdating = !!subscriptionInfo?.isPendingUpgrade
  const annualReportSubscription = subscriptionInfo?.AddonSubscriptions?.find(
    (addon) => addon.ProductPlan.RefKey && addon.ProductPlan.RefKey?.indexOf('annual_report_subscription') >= 0,
  )

  const subscriptionDiscount = subscription?.organization.subscriptionDiscount || 0
  const isYearlyExternalSubscription = subscriptionInfo?.ProductPlan?.Period === SubscriptionPeriod.Yearly
  const isMonthlyExternalSubscription = subscriptionInfo?.ProductPlan?.Period === SubscriptionPeriod.Monthly

  const organizationSubscriptionPricePerMonth = useMemo(() => {
    const subscriptionPrice = subscription?.organization.subscriptionPrice

    if (subscriptionPrice && subscriptionDiscount) {
      return Math.round(((100 - subscriptionDiscount) / 100) * subscriptionPrice)
    }

    return subscriptionPrice || 0
  }, [subscription?.organization.subscriptionPrice, subscriptionDiscount])

  const externalSubscriptionPricePerMonth = useMemo(() => {
    const subscriptionPrice = subscriptionInfo?.Price

    if (isYearlyExternalSubscription && subscriptionPrice) {
      return Math.round(subscriptionPrice / 12)
    }

    return subscriptionPrice || 0
  }, [isYearlyExternalSubscription, subscriptionInfo?.Price])

  const externalSubscriptionPlan: SubscriptionPlan = useMemo((): SubscriptionPlan => {
    if (isBrandBilly) {
      return startsInFuture(subscriptionInfo)
        ? (subscription?.organization.subscriptionPlan as SubscriptionPlan)
        : (subscriptionInfo?.ProductPlan?.Key as SubscriptionPlan)
    }

    return subscription?.organization.subscriptionProductPlan as SubscriptionPlan
  }, [
    isBrandBilly,
    subscriptionInfo,
    subscription?.organization.subscriptionPlan,
    subscription?.organization.subscriptionProductPlan,
  ])

  const subscriptionData: Record<BillingType, UseOrganizationSubscriptionResult> = {
    // For own/legacy Billy subscriptions
    [BillingType.Own]: {
      isLoading,
      organizationId,
      billingType: BillingType.Own,
      isOwnBilling,
      isExternalBilling,
      switchPlan: subscription?.organization.nextState?.split('_')[0] as SubscriptionPlan,
      switchPlanPeriod: subscription?.organization.nextState?.split('_')[1] as SubscriptionPeriod,
      isSwitchingPlan:
        !!subscription?.organization.nextState &&
        subscription?.organization.nextState !== subscription?.organization.subscriptionPlan,
      planExpirationDate: subscription?.organization?.subscriptionExpires || '',
      isLocked: subscription?.organization.isLocked,
      isTrial: !!subscription?.organization.isTrial,
      subscriptionPlan: subscription?.organization.subscriptionPlan as SubscriptionPlan,
      planFullName: '',
      isStandardPlan: STANDARD_PLANS.includes(subscription?.organization.subscriptionPlan as SubscriptionPlan),
      subscriptionPeriod: subscription?.organization.subscriptionPeriod as SubscriptionPeriod,
      isTrialNoPlanSelected:
        !!subscription?.organization.isTrial &&
        !subscription.organization.activationDate &&
        !!subscription.organization.subscriptionExpires,
      subscriptionPricePerMonth: organizationSubscriptionPricePerMonth,
      isYearly: subscription?.organization.subscriptionPeriod === SubscriptionPeriod.Yearly,
      isMonthly: subscription?.organization.subscriptionPeriod === SubscriptionPeriod.Monthly,
      couponApplied:
        !!subscription?.organization.couponDiscountPercent &&
        !!subscription?.organization.couponExpires &&
        !isExpired(subscription.organization.couponExpires),
      couponCode: subscription?.organization.couponCode,
      couponPeriod: subscription?.organization.couponPeriod,
      couponDiscountPercent: subscription?.organization.couponDiscountPercent,
      defaultActivePaymentMethod: undefined,
      defaultActivePaymentCard,
      subscriptionDiscount,
      cardAdded: !!defaultActivePaymentCard,
      cardInvalid: !defaultActivePaymentCard || isExpired(defaultActivePaymentCard.cardExpire),
      cardType: defaultActivePaymentCard?.cardType,
      cardLabel: defaultActivePaymentCard?.cardLast4,
      cardExpirationDate: defaultActivePaymentCard?.cardExpire,
      isSubscriptionUpdating,
      discount: null,
      hasAnnualReportsSubscription: !!annualReportSubscription?.ID,
    },
    // For Upodi subscriptions
    [BillingType.External]: {
      isLoading,
      organizationId,
      billingType: BillingType.External,
      isOwnBilling,
      isExternalBilling,
      switchPlan: subscriptionInfo?.nextState?.toLowerCase().split('_')[0] as SubscriptionPlan,
      switchPlanPeriod: subscriptionInfo?.nextState?.toLowerCase().split('_')[1] as SubscriptionPeriod,
      isSwitchingPlan: isSwitchingPlan(subscriptionInfo),
      planExpirationDate: subscription?.organization?.subscriptionExpires || '',
      isLocked: subscription?.organization.isLocked,
      isTrial: !!subscription?.organization.isTrial,
      subscriptionPlan: externalSubscriptionPlan,
      planFullName: startsInFuture(subscriptionInfo)
        ? (subscription?.organization.subscriptionPlan as SubscriptionPlan)
        : subscriptionInfo?.ProductPlan?.FullName || '',
      isStandardPlan: STANDARD_PLANS.includes(subscriptionInfo?.ProductPlan?.Key as SubscriptionPlan),
      subscriptionPeriod: subscriptionInfo?.ProductPlan?.Period as SubscriptionPeriod,
      isTrialNoPlanSelected:
        !!subscription?.organization.isTrial &&
        !subscription.organization.activationDate &&
        !!subscription.organization.subscriptionExpires &&
        isEmpty(defaultActivePaymentMethod),
      subscriptionPricePerMonth: externalSubscriptionPricePerMonth,
      isYearly: isYearlyExternalSubscription,
      isMonthly: isMonthlyExternalSubscription,
      couponApplied: false,
      couponCode: subscription?.organization.couponCode,
      couponPeriod: subscription?.organization.couponPeriod,
      couponDiscountPercent: subscription?.organization.couponDiscountPercent,
      defaultActivePaymentMethod,
      defaultActivePaymentCard: undefined,
      subscriptionDiscount,
      cardAdded: !!defaultActivePaymentMethod?.FullName,
      cardInvalid: !defaultActivePaymentMethod || isExpired(defaultActivePaymentMethod.ExpiryDate),
      cardType: defaultActivePaymentMethod?.Issuer?.toLowerCase(),
      cardLabel: defaultActivePaymentMethod?.FullName,
      cardExpirationDate: defaultActivePaymentMethod?.ExpiryDate,
      isSubscriptionUpdating,
      AddonSubscriptions: subscriptionInfo?.AddonSubscriptions,
      discount: subscriptionInfo?.Discount || null,
      hasAnnualReportsSubscription: !!annualReportSubscription?.ID,
    },
  }

  return subscriptionData[billingType]
}
