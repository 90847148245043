import { Color, Icon, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { SimpleCheckListAlignment } from './types/SimpleCheckListAlignment'

interface SimpleChecklistWrapperProps {
  grid?: boolean
  alignment?: SimpleCheckListAlignment
}

export const SimpleChecklistWrapper = styled.div<SimpleChecklistWrapperProps>`
  margin-left: ${({ alignment }) => (alignment === 'left' ? 0 : 'auto')};
  margin-right: auto;
  ${({ grid }) => {
    if (grid) {
      return `
      display: grid;
      grid-template-columns: 250px 1fr;
    `
    }
  }};
`

interface ListItemProps {
  condensed?: boolean
}

export const ListItem = styled.div<ListItemProps>`
  display: flex;
  margin-bottom: ${({ condensed }) => (condensed ? Spacing.S : Spacing.M)};
  align-items: flex-start;
`

export const ListIcon = styled(Icon)`
  margin-top: ${Spacing.XXS};
  margin-right: ${Spacing.S};
  flex-shrink: 0;
  background-color: ${Color.ElectricGreen};
  border-radius: 50%;
  padding: ${Spacing.XXXS};
`
