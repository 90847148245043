import { Scope } from '../../../../../enums/scope'
import { SettingsTabItem } from '../types'

interface TabsFilterOptions {
  isAuthorized: (scope: Scope | Scope[]) => boolean
}

export const filterSettingsTabItems = (
  items: SettingsTabItem[],
  { isAuthorized }: TabsFilterOptions,
): SettingsTabItem[] =>
  items.filter((item) => {
    if (item.scopes && !isAuthorized(item.scopes)) {
      return false
    }

    return true
  })
