import { Icon } from '@design-system'

import { ReactElement } from 'react'

interface EmailStatusIconProps {
  isSent: boolean | null
}

export const EmailStatusIcon = ({ isSent }: EmailStatusIconProps): ReactElement | null =>
  isSent ? <Icon icon="envelopeWithArrow" /> : null
