import { getCurrentLocale } from '@design-system'

import { Metable } from '../../../types/metable'
import { getRequest, postRequest } from '../../../utils'
import { Account, AccountGroup, PublicAccount } from './types'

export interface AccountsResponseData extends Metable {
  accounts: Account[]
}

export interface PublicAccountsResponseData extends Metable {
  publicAccounts: PublicAccount[]
}

interface AccountGroupsResponseData {
  accountGroups: AccountGroup[]
}

export const fetchAccounts = (organizationId: string): Promise<AccountsResponseData> => {
  const locale = getCurrentLocale()

  return getRequest(
    `/v2/accounts?organizationId=${organizationId}&include=account.group:embed,account.predefinedAccount:embed`,
    { 'accept-language': locale },
  )
}

export const fetchAccountGroups = (organizationId: string): Promise<AccountGroupsResponseData> => {
  const locale = getCurrentLocale()

  return getRequest(`/v2/accountGroups?organizationId=${organizationId}`, { 'accept-language': locale })
}

interface FetchAccountResponseData {
  account: Account
}

export const fetchAccount = (accountId: string): Promise<FetchAccountResponseData> => {
  const locale = getCurrentLocale()

  return getRequest(`/v2/accounts/${accountId}`, { 'accept-language': locale })
}

export type CreateAccountPayload = Omit<Partial<Account>, 'id'>

export interface CreateAccountOptions {
  payload: CreateAccountPayload
  organizationId?: string
}

export const createAccount = ({ payload, organizationId }: CreateAccountOptions): Promise<AccountsResponseData> => {
  return postRequest('/v2/accounts', { account: { ...payload, organizationId } })
}

export const fetchPublicAccounts = (organizationId: string): Promise<PublicAccountsResponseData> => {
  const locale = getCurrentLocale()

  return getRequest(`/v2/bookkeeping/publicAccounts?organizationId=${organizationId}`, { 'accept-language': locale })
}
