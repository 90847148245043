import { Button, ButtonsGroup, Flex, Modal, ModalProps, Space, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { SupportIllustration } from '../../../../../assets/images'
import { useFrodaFinanceLendingContext } from '../../contexts/frodaFinanceLendingContext'

export const FrodaFailedModal = (props: ModalProps): ReactElement => {
  const { t } = useTranslation()
  const { onGetOfferClick, isProcessing, closeFrodaFailedModal } = useFrodaFinanceLendingContext()

  return (
    <Modal {...props}>
      <Modal.Header>
        <Flex flexDirection="column">
          <Text variant="h2">{t('financing.froda_lending.verification_process_error_modal.title')}</Text>
          <Space size="m" />
          <Text>{t('financing.froda_lending.verification_process_error_modal.description')}</Text>
          <Space size="m" />
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Flex justifyContent="center">
          <SupportIllustration />
        </Flex>
        <Space size="xl" />
        <Flex justifyContent="flex-end">
          <ButtonsGroup>
            <Button variant="secondary" onClick={closeFrodaFailedModal}>
              {t('financing.froda_lending.verification_process_error_modal.later')}
            </Button>
            <Button loading={isProcessing} onClick={onGetOfferClick}>
              {t('financing.froda_lending.verification_process_error_modal.offer')}
            </Button>
          </ButtonsGroup>
        </Flex>
      </Modal.Body>
    </Modal>
  )
}
