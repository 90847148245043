import { Button, Modal, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'

import { getImgPath } from '../../../../src/utils/getImgPath'
import { LocalStorageKeys } from '../../../enums/localStorageKeys'
import { ModalId } from '../../../enums/modalId'
import * as Styled from './styles'

export const MobileSignupWelcomeModal = (): ReactElement => {
  const { t } = useTranslation()
  const [, setIsClosedMobileExperienceMessage] = useLocalStorage(LocalStorageKeys.MobileExperienceMessageClosed)
  const { close } = useModal(ModalId.MobileSignupWelcomeModal, {
    onClose: () => setIsClosedMobileExperienceMessage(true),
  })

  const handleCloseClick = useCallback(() => {
    close()
  }, [close])

  const goToDownloadApp = useCallback((link: string) => {
    window.location.replace(link)
  }, [])

  return (
    <Modal id={ModalId.MobileSignupWelcomeModal} size="s" closable>
      <Modal.Header />
      <Modal.Body>
        <Styled.Title alignment="center" weight="regular" variant="displayS">
          {t('signup.mobile.welcome_modal.title')}
        </Styled.Title>
        <Styled.SubTitle colorVariant="secondary" alignment="center" variant="large">
          {t('signup.billy.mobile.welcome_modal.sub_title')}
        </Styled.SubTitle>
        <Styled.BoldSection alignment="center" weight="medium" variant="h3">
          {t('signup.mobile.welcome_modal.bold_section')}
        </Styled.BoldSection>
        <Styled.AppOptionsContainer>
          <Styled.AppIllustration
            onClick={() => goToDownloadApp(t('google_play.billy.url'))}
            src={getImgPath('/app-store-illustrations/billy-google-play.svg')}
            alt="Billy app"
          />
          <Styled.AppIllustration
            onClick={() => goToDownloadApp(t('app_store.billy.url'))}
            src={getImgPath('/app-store-illustrations/billy-app-store.svg')}
            alt="Billy app"
          />
        </Styled.AppOptionsContainer>
        <Button onClick={handleCloseClick} variant="secondary" fullWidth>
          {t('signup.mobile.welcome_modal.cta')}
        </Button>
      </Modal.Body>
    </Modal>
  )
}
