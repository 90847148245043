import { Button, Flex, Link, Modal, Space, Text, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../enums/modalId'

interface ExportAsSaftModalProps {
  onModalClose?: () => void
}

export const ExportAsSaftModal = ({ onModalClose }: ExportAsSaftModalProps): ReactElement => {
  const { t } = useTranslation()

  const handleModalClose = useCallback(() => {
    onModalClose?.()
  }, [onModalClose])

  const { close: closeExportAsSaftModal } = useModal(ModalId.ExportAsSaftModal, { onClose: handleModalClose })

  const handleOkButtonClick = useCallback(() => {
    closeExportAsSaftModal()
  }, [closeExportAsSaftModal])

  return (
    <Modal id={ModalId.ExportAsSaftModal} size="s" forceRender closable>
      <Modal.Header title={t('modal.export_as_saft.title')} />
      <Modal.Body>
        <Text>{t('modal.export_as_saft.description')}</Text>
        <Space size="xl" />
        <Flex justifyContent="space-between">
          <Text colorVariant="secondary">
            {t('modal.export_as_saft.contact.call_us')}{' '}
            <Text as="span">
              <Link href="tel:+4560246024/">{t('modal.export_as_saft.contact.call_us.link')}</Link>
            </Text>
          </Text>
          <Text colorVariant="secondary">
            {t('modal.export_as_saft.contact.mail_us')}{' '}
            <Text as="span">
              <Link href="mailto:billy@billy.dk">{t('modal.export_as_saft.contact.mail_us.link')}</Link>
            </Text>
          </Text>
          <Text colorVariant="secondary">
            {t('modal.export_as_saft.contact.chat_with_us')}{' '}
            <Text as="span">{t('modal.export_as_saft.contact.chat_with_us.link')}</Text>
          </Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOkButtonClick}>{t('modal.export_as_saft.ok_button')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
