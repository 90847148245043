import { useMutation } from 'react-query'

import { APIError } from '../../../../utils'
import { updateUmbrellaOwnership, UpdateUmbrellaOwnershipPayload } from '../query-api'

interface UseTransferUmbrellaOwnershipProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface TransferUmbrellaOwnershipProps {
  umbrellaId: string
  payload: UpdateUmbrellaOwnershipPayload
}

export const useTransferUmbrellaOwnership = (props?: UseTransferUmbrellaOwnershipProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: transferUmbrellaOwnership, isLoading: isProcessing } = useMutation(
    ({ umbrellaId, payload }: TransferUmbrellaOwnershipProps) => updateUmbrellaOwnership(umbrellaId, payload),
    {
      onError,
      onSuccess,
    },
  )

  return {
    isProcessing,
    transferUmbrellaOwnership,
  }
}
