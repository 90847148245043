import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'

import { DefaultValue } from '../../enums/defaultValue'
import { Asable } from '../../types/asable'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { Size, Variant } from './types'
import {
  getBorderStyle,
  getDangerStyle,
  getFocusedStyle,
  getLoadingStyle,
  getSizeStyle,
  getVariantStyle,
} from './utils'
import { getActiveStyle } from './utils/getActiveStyle'
import { getDisabledStyle } from './utils/getDisabledStyle'
import { getSuccessStyle } from './utils/getSuccessStyle'

export interface ButtonProps extends Asable {
  active?: boolean
  centered?: boolean
  danger?: boolean
  disabled?: boolean
  focused?: boolean
  forceSquareRatio?: boolean
  fullWidth?: boolean
  fullRound?: boolean
  href?: string
  loading?: boolean
  size: Size
  skipLeftRounding?: boolean
  skipRightRounding?: boolean
  success?: boolean
  variant: Variant
  withIconMargin?: boolean
}

export const Button = styled('button', {
  shouldForwardProp: (propName) => isPropValid(propName) && propName !== 'loading',
})<ButtonProps>`
  display: inline-flex;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  max-width: 100%;
  border: none;
  align-items: center;
  justify-content: ${({ centered }) => (centered ? 'center' : 'unset')};
  font-family: ${DefaultValue.FontFamily};
  white-space: nowrap;
  cursor: pointer;
  ${getDefaultTransition('background-color', 'color', 'border', 'box-shadow', 'transform', 'filter')};

  &:not(:disabled) {
    &:active {
      transform: scale(${DefaultValue.ActiveElementScale});
    }
  }

  ${({ forceSquareRatio, size }) => getSizeStyle(size, forceSquareRatio)};
  ${({ variant }) => getVariantStyle(variant)};
  ${({ variant }) => getDisabledStyle(variant)};
  ${({ active, danger, variant }) => getActiveStyle(variant, danger, active)};
  ${({ success, variant }) => success && getSuccessStyle(variant)};
  ${({ danger, variant }) => danger && getDangerStyle(variant)};
  ${({ danger, loading, variant, success }) => loading && getLoadingStyle(variant, danger, success)};
  ${({ focused }) => focused && getFocusedStyle()};
  ${({ skipLeftRounding, skipRightRounding, fullRound }) =>
    getBorderStyle(skipLeftRounding, skipRightRounding, fullRound)};
`
