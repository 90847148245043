import { AnyAction } from 'redux'

import { COMPANIES_RECEIVED, COMPANIES_REQUESTED } from './actions'
import { Company } from './types'

export type CompaniesRequestedPayload = {
  filters: {
    text: string | null
  }
}

export type CompaniesRequestedAction =
  | AnyAction
  | {
      payload: CompaniesRequestedPayload
      type: typeof COMPANIES_REQUESTED
    }

export function companiesRequested(payload: CompaniesRequestedPayload): CompaniesRequestedAction {
  return {
    payload,
    type: COMPANIES_REQUESTED,
  }
}

export type CompaniesReceivedPayload = {
  companies: Company[]
}

export type CompaniesReceivedAction =
  | AnyAction
  | {
      payload: CompaniesReceivedPayload
      type: typeof COMPANIES_RECEIVED
    }

export function companiesReceived(payload: CompaniesReceivedPayload): CompaniesReceivedAction {
  return {
    payload,
    type: COMPANIES_RECEIVED,
  }
}
