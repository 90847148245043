import React, { ReactElement } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { BrandProtectedComponent } from '../../../ProtectedComponents'
import { HelpButton } from './elements/HelpButton'
import { SettingsButton } from './elements/SettingsButton'
import { UserDropdown } from './elements/UserDropdown'
import { UserSettingsButton } from './elements/UserSettingsButton'
import * as Styled from './styles'

export const MenuFooter = (): ReactElement => {
  const { organization } = useUserOrganization()
  const { umbrella } = useUmbrella()

  const isLoading = !umbrella && !organization
  const shouldShowSettings = !isLoading && !umbrella && !organization?.isLocked

  return (
    <Styled.MenuFooterWrapper>
      <Styled.FooterActions>
        <Styled.FooterActionsItem>
          <BrandProtectedComponent
            brand="billy"
            loadingNode={<UserSettingsButton />}
            unauthorizedNode={<UserSettingsButton />}
          >
            <UserDropdown />
          </BrandProtectedComponent>
        </Styled.FooterActionsItem>
        {shouldShowSettings && (
          <Styled.FooterActionsItem>
            <SettingsButton />
          </Styled.FooterActionsItem>
        )}
        <Styled.FooterActionsItem moveToRight={!shouldShowSettings}>
          <BrandProtectedComponent brand="billy">
            <HelpButton />
          </BrandProtectedComponent>
        </Styled.FooterActionsItem>
      </Styled.FooterActions>
    </Styled.MenuFooterWrapper>
  )
}
