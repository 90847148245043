import { isValid, parse } from 'date-fns'

// Supported "date" string format "yyyy-MM-dd"
export const getLocalDateFromDateString = (date?: string): Date | undefined => {
  if (!date) {
    return undefined
  }

  const dateOnly = date.split('T')[0]
  const parsedDate = parse(dateOnly, 'yyyy-MM-dd', new Date())

  if (isValid(parsedDate)) {
    return parsedDate
  }

  return undefined
}
