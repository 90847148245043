import { SettingsSection } from '@components'
import { Button, ModalConfirmation, notify, useModal } from '@design-system'

import { ReactElement, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { APIError } from '../../../../../../utils'
import { useVatRatesEmberConnection } from '../../contexts/vatRatesEmberConnectionContext'
import { CreateOrEditRulesetModal } from './elements/CreateOrEditRulesetModal'
import { RulesetsTable } from './elements/RulsetsTable/RulesetsTable'
import { useDeleteRuleset } from './hooks/useDeleteRuleset'
import { useInvalidateRulesets } from './hooks/useInvalidateRulesets'
import * as Styled from './styles'

const DELETE_RULESET_CONFIRMATION_MODAL_ID = 'delete-ruleset-confirmation'

export const Rulesets = (): ReactElement => {
  const { t } = useTranslation()

  const { showEditRuleSetModal } = useVatRatesEmberConnection()

  const { open: openCreateRulesetModal } = useModal(ModalId.CreateRulesetModal)

  const rulesetIdToDelete = useRef<string | undefined>()
  const { invalidateRulesets } = useInvalidateRulesets()

  const { open: openDeleteConfirmation, close: closeDeleteConfirmation } = useModal(
    DELETE_RULESET_CONFIRMATION_MODAL_ID,
    {
      onClose: () => {
        rulesetIdToDelete.current = undefined
      },
    },
  )

  const onDeleteRulesetError = useCallback(
    (error: APIError | undefined) => {
      notify({
        id: 'tax-ruleset-deleted',
        message: error?.message || t('settings.organization.vat_rates.rulesets.notification.delete_error'),
        variant: 'error',
      })
    },
    [t],
  )

  const onDeleteRulesetSuccess = useCallback(() => {
    notify({
      id: 'tax-ruleset-deleted',
      message: t('settings.organization.vat_rates.rulesets.notification.delete_success'),
      variant: 'success',
    })
    closeDeleteConfirmation()
    invalidateRulesets()
  }, [closeDeleteConfirmation, invalidateRulesets, t])

  const { delete: deleteRuleset, isLoading: isDeleting } = useDeleteRuleset({
    onSuccess: onDeleteRulesetSuccess,
    onError: onDeleteRulesetError,
  })

  const onRulesetDelete = useCallback(
    (rulesetId: string) => {
      rulesetIdToDelete.current = rulesetId
      openDeleteConfirmation()
    },
    [openDeleteConfirmation],
  )

  const handleDeleteModalOkClick = useCallback(() => {
    if (rulesetIdToDelete.current) {
      deleteRuleset(rulesetIdToDelete.current)
    }
  }, [deleteRuleset])

  const handleCreateRuleSetButtonClick = useCallback(() => {
    openCreateRulesetModal()
  }, [openCreateRulesetModal])

  return (
    <>
      <SettingsSection>
        <SettingsSection.Content
          title={t('settings.organization.vat_rates.rulesets')}
          sideActions={
            <Button icon="plusCircle" variant="secondary" onClick={handleCreateRuleSetButtonClick}>
              {t('settings.organization.vat_rates.rulesets.create')}
            </Button>
          }
          subtitle={t('settings.organization.vat_rates.rulesets.description')}
        >
          <Styled.TableWrapper>
            <RulesetsTable onRulesetDelete={onRulesetDelete} onShowEditRuleSetModal={showEditRuleSetModal} />
          </Styled.TableWrapper>
          <ModalConfirmation
            cancelLabel={t('cancel')}
            danger
            id={DELETE_RULESET_CONFIRMATION_MODAL_ID}
            message={t('settings.organization.vat_rates.modals.delete.message')}
            okLabel={t('settings.organization.vat_rates.modals.delete.delete')}
            okLoading={isDeleting}
            onOk={handleDeleteModalOkClick}
            title={t('settings.organization.vat_rates.modals.delete.title')}
          />
        </SettingsSection.Content>
      </SettingsSection>
      <CreateOrEditRulesetModal id={ModalId.CreateRulesetModal} />
    </>
  )
}
