import { Account } from '@modules-deprecated/app/accounts/types'
import { AllBankConnections } from '@modules-deprecated/bankReconciliation/types'

export const getActiveBankAccounts = (allAccounts: Account[], bankConnections?: AllBankConnections): Account[] =>
  allAccounts.filter((account: Account) => {
    const { newConnections, oldConnections } = bankConnections || {}
    // temporarily we need to support both integrations
    // there're not many connected bank accounts usually (1-2), so it's fine to iterate
    // allAccounts is a list of Billy bank accounts, and there're not many of them either
    const bankAccount =
      newConnections?.find((bank) => bank.externalAccountId === account.id) ||
      oldConnections?.find((bank) => bank.accountId === account.id)

    return bankAccount && !account.isArchived
  })
