import React, { ReactElement } from 'react'

import * as Styled from './styles'

export interface SkeletonBoxProps {
  width?: number
  height?: number
  fullWidth?: boolean
  fullHeight?: boolean
}

export const SkeletonBox = ({ ...props }: SkeletonBoxProps): ReactElement => <Styled.SkeletonBox {...props} />
