import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { DefaultValue } from '../../../../enums/defaultValue'
import { Spacing } from '../../../../enums/spacing'
import { getShadow } from '../../../../utils/getShadow'
import { DATE_PICKER_WIDTH } from '../../constants/datePickerWidth'

const DAYS_IN_WEEK = 7

export const MonthWrapper = styled.div`
  width: ${DATE_PICKER_WIDTH}px;
  padding: ${Spacing.L} ${Spacing.XL};
  border-radius: ${DefaultValue.BorderRadius};
  background-color: ${Color.White};
  ${getShadow('elevatedStrong')}
`

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(${DAYS_IN_WEEK}, 1fr);
  gap: ${Spacing.S};
  justify-content: center;
`

export const WeekdaysList = styled(List)`
  margin-bottom: ${Spacing.S};
`

export const Weekday = styled.li``

export const DaysList = styled(List)``
