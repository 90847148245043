import qs from 'qs'

import { APIResponse, getRequest, putRequest } from '../../utils'
import { RecurringInvoice } from './types/recurringInvoice'
import { RecurringInvoicesSpawnSettings } from './types/recurringInvoicesSpawnSettings'

export interface FetchRecurringInvoicesOptions {
  organizationId: string
  page?: number
  pageSize?: number
  q?: string
  sort?: string
}

export interface FetchRecurringInvoicesResponseData {
  data: RecurringInvoice[]
  meta: { total: number }
}

export const fetchRecurringInvoices = ({
  organizationId,
  page,
  pageSize,
  q,
  sort,
}: FetchRecurringInvoicesOptions): Promise<FetchRecurringInvoicesResponseData> => {
  const queryParams = qs.stringify({
    ...(page ? { page } : {}),
    ...(pageSize ? { pageSize } : {}),
    ...(q ? { q } : {}),
    ...(sort ? { sort } : {}),
  })
  return getRequest(`/organizations/${organizationId}/recurringInvoices?${queryParams}`)
}

export const fetchRecurringInvoicesSpawnSettings = (organizationId: string): Promise<RecurringInvoicesSpawnSettings> =>
  getRequest(`/recurringInvoices/organizations/${organizationId}`)

export interface UpdateRecurringInvoicesSpawnSettingsOptions {
  payload: { enabled: boolean }
  organizationId: string
  preserveSpawns?: boolean
}

export interface UpdateRecurringInvoicesSpawnSettingsResponse {
  data: RecurringInvoicesSpawnSettings
  meta: { statusCode: number }
}

export const updateRecurringInvoicesSpawnSettings = ({
  payload,
  organizationId,
  preserveSpawns,
}: UpdateRecurringInvoicesSpawnSettingsOptions): Promise<APIResponse<UpdateRecurringInvoicesSpawnSettingsResponse>> =>
  putRequest(
    `/recurringInvoices/organizations/${organizationId}${preserveSpawns ? '?preserveSpawns=true' : ''}`,
    payload,
  )
