import { Flex, Spacing, Table, TableColumn, TableData, Text } from '@design-system'

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'

export interface TableDataWithName extends TableData {
  name?: string
}

interface MembersTableProps<T> {
  isLoading: boolean
  onRowSelect: (value: T) => void
  tableData: T[]
}

export const MembersTable = <T extends TableDataWithName>({
  isLoading,
  onRowSelect,
  tableData,
}: MembersTableProps<T>) => {
  const { t } = useTranslation()

  const columns: TableColumn<T>[] = useMemo(
    () =>
      [
        {
          Header: t('transfer_ownership_modal.table.header'),
          accessor: 'name',
          Cell: ({ row, data }) => {
            const { name, email } = data[row.id]
            return (
              <Flex gap={Spacing.L}>
                <Text>{name}</Text>
                {email && <Text colorVariant="secondary">{email}</Text>}
              </Flex>
            )
          },
        },
      ] as TableColumn<T>[],
    [t],
  )

  const handleRowSelect = useCallback(
    (row: Row<T>) => {
      onRowSelect(row.original)
    },
    [onRowSelect],
  )

  return (
    <Table
      columns={columns}
      data={tableData}
      isLoading={isLoading}
      isSelectable
      onRowSelect={handleRowSelect}
      rowSelectType="radio"
      skeletonItemsPerPage={2}
    />
  )
}
