import { SettingsSection } from '@components'
import { Space, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { OrganizationNotificationSettings as OrganizationNotificationSettingsType } from '@modules-deprecated/app/user'
import { useUserNotificationSettings } from '@modules-deprecated/app/user/userNotificationSettingsContext'

import { OrganizationNotificationSettingsContextProvider } from './context/organizationNotificationSettings'
import { DefaultNotificationSettings } from './elements/DefaultNotificationSettings'
import { OrganizationNotificationSettings } from './elements/OrganizationNotificationSettings'
import { SkeletonNotifications } from './elements/SkeletonNotifications'
import * as Styled from './styles'

export const UserNotificationsSettings = (): ReactElement => {
  const { t } = useTranslation()
  const { userNotificationSettings, isLoading } = useUserNotificationSettings()

  return (
    <>
      <SettingsSection>
        <Styled.NotificationsWrapper>
          <Text variant="h2">{t('notification_settings.title')}</Text>
          <Space size="s" />
          <Text colorVariant="secondary">{t('notification_settings.intro')}</Text>
          <Space size="l" />
          {userNotificationSettings?.map(
            (organizationNotificationSettings: OrganizationNotificationSettingsType, i: number) => {
              return (
                <OrganizationNotificationSettingsContextProvider
                  key={i}
                  organizationNotificationSettings={organizationNotificationSettings}
                >
                  <OrganizationNotificationSettings />
                </OrganizationNotificationSettingsContextProvider>
              )
            },
          )}
          {isLoading && <SkeletonNotifications />}
          <DefaultNotificationSettings />
        </Styled.NotificationsWrapper>
      </SettingsSection>
    </>
  )
}
