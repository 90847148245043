import { notify } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { CreateActorResponseData, UpdateActorOptions } from '../query-api'
import { useCreateActor } from './useCreateActor'
import { useDeleteActor } from './useDeleteActor'
import { useUpdateActor } from './useUpdateActor'

interface UseActorMutationsProps {
  onSuccessCreate?: (data: CreateActorResponseData) => void
  onSuccessDelete?: () => void
  onSuccessUpdate?: () => void
}

export const useActorMutations = ({ onSuccessCreate, onSuccessDelete, onSuccessUpdate }: UseActorMutationsProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { create: createActor, isLoading: isCreatingActor } = useCreateActor({
    onSuccess: (data: CreateActorResponseData) => {
      notify({
        id: NotificationKeys.ActorCreate,
        message: t('settings.organization.access_tokens.create_or_edit_access_token_modal.notification.success'),
        variant: 'success',
      })
      onSuccessCreate?.(data)
      queryClient.invalidateQueries(QueryKeys.Actors)
    },
    onError: () => {
      notify({ id: NotificationKeys.ActorCreate, message: t('error_saving'), variant: 'error' })
    },
  })

  const { update, isLoading: isUpdatingActor } = useUpdateActor({
    onSuccess: () => {
      notify({
        id: NotificationKeys.ActorCreate,
        message: t('settings.organization.access_tokens.create_or_edit_access_token_modal.notification.success'),
        variant: 'success',
      })
      onSuccessUpdate?.()
      queryClient.invalidateQueries(QueryKeys.Actors)
    },
    onError: () => {
      notify({ id: NotificationKeys.ActorCreate, message: t('error_saving'), variant: 'error' })
    },
  })

  const updateActor = useCallback((options: UpdateActorOptions) => update(options), [update])

  const { mutate: deleteActor, isLoading: isDeletingActor } = useDeleteActor({
    onSuccess: () => {
      notify({
        id: NotificationKeys.ActorDelete,
        message: t('settings.organization.access_tokens.delete.notification.success'),
        variant: 'success',
      })
      onSuccessDelete?.()
      queryClient.invalidateQueries(QueryKeys.Actors)
    },
    onError: () => {
      notify({
        id: NotificationKeys.ActorDelete,
        message: t('settings.organization.access_tokens.delete.notification.error'),
        variant: 'error',
      })
    },
  })

  return { createActor, isCreatingActor, updateActor, isUpdatingActor, deleteActor, isDeletingActor }
}
