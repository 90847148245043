import { NavItem } from '../../NavList'

export const getParentNavItem = <T>(items: NavItem<T>[], id: string): NavItem<T> | undefined => {
  let item: NavItem<T> | undefined

  for (const parentItem of items) {
    if (parentItem.subItems) {
      for (const subItem of parentItem.subItems) {
        if (subItem.id === id) {
          item = parentItem
          break
        }
      }
    }
  }

  return item
}
