import { AllBankConnections } from '@modules-deprecated/bankReconciliation/types'

interface FindWidgetBankConnectionProps {
  bankConnections?: AllBankConnections
  selectedBankAccountId?: string
}

// we currently support both old and new bank connections, so we need to check both
export const findWidgetBankConnection = ({ bankConnections, selectedBankAccountId }: FindWidgetBankConnectionProps) => {
  if (!bankConnections) {
    return undefined
  }
  const { newConnections, oldConnections } = bankConnections || {}

  if (selectedBankAccountId) {
    const connectionForSelectedAccount =
      newConnections?.find((bankConnection) => bankConnection?.externalAccountId === selectedBankAccountId) ||
      oldConnections?.find((bankConnection) => bankConnection?.accountId === selectedBankAccountId)

    if (connectionForSelectedAccount) {
      return connectionForSelectedAccount
    }
  }

  const connection =
    newConnections?.find((bankConnection) => !!bankConnection?.externalAccountId) || oldConnections?.[0]

  return connection
}
