import { AmountInfoBox, AmountInfoBoxesGroup, AmountInfoBoxProps, Space, Text } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { QuoteState } from '../../../../enums/quoteState'
import { quotesStateToBadgeVariant } from '../../constants/quotesStateToBadgeVariant'
import { quotesStateToTitleKey } from '../../constants/quotesStateToTitleKey'
import { QUOTES_SUMMARY_BOXES_STATE } from '../../constants/quotesSummaryBoxesState'
import { useQuotesListFilters } from '../../hooks/useQuotesListFilters'
import { useQuotesSummary } from '../../hooks/useQuotesSummary'

export const QuotesSummary = (): ReactElement => {
  const { t } = useTranslation()
  const { isLoading, quotesCountByState } = useQuotesSummary()
  const [{ state: currentState }, setFilters] = useQuotesListFilters()

  const handleQuotesInfoBoxClick = useCallback(
    (id: QuoteState) => {
      setFilters({ page: 1, state: id })
    },
    [setFilters],
  )

  const quotesInfoBoxes: AmountInfoBoxProps<QuoteState>[] = useMemo(
    () =>
      QUOTES_SUMMARY_BOXES_STATE.map((state) => ({
        active: state === currentState,
        amount: quotesCountByState[state],
        badgeLabel: t(quotesStateToTitleKey[state]),
        badgeVariant: quotesStateToBadgeVariant[state],
        id: state,
      })),
    [quotesCountByState, t, currentState],
  )

  return (
    <section>
      <Text variant="h3">{t('quotes.summary.title')}</Text>
      <Space size="l" />
      <AmountInfoBoxesGroup itemsInRow={quotesInfoBoxes.length} loading={isLoading}>
        {quotesInfoBoxes.map(({ id, ...restProps }) => (
          <AmountInfoBox {...restProps} clickable id={id} key={id} onClick={handleQuotesInfoBoxClick} />
        ))}
      </AmountInfoBoxesGroup>
      <Space size="xxl" />
    </section>
  )
}
