import { Flex, SkeletonBox, Spacing, Switch, Text } from '@design-system'

import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../hooks'
import { useUmbrellaOrganizationVoucherInbox } from '../../contexts/umbrellaOrganizationUpdateVoucherInboxContext'
import { UmbrellaVoucherInboxFormSchema } from '../../util/formData'

export const ModalBody = () => {
  const { t } = useTranslation()
  const { Form, isLoading } = useUmbrellaOrganizationVoucherInbox()
  const { FormItem } = useFormContext<UmbrellaVoucherInboxFormSchema>()

  if (isLoading) {
    return <SkeletonBox />
  }

  return (
    <Form>
      <Flex flexDirection="row" gap={Spacing.L}>
        <Text weight="bold" variant="small">
          {t('umbrella.organizations_table.update_voucher_inbox_modal.body')}
        </Text>
        <FormItem
          name="isInboxEnabled"
          render={({ field: { onChange, value, ...rest } }) => <Switch checked={value} onChange={onChange} {...rest} />}
        />
      </Flex>
    </Form>
  )
}
