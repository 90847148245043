import { useMissingInformation } from './useMissingInformation'
import { useMissingReceiptsBankLines } from './useMissingReceiptsBankLines'

export const useToDoCount = () => {
  const { data: missingInformationData, isLoading: isLoadingMissingInfo } = useMissingInformation()
  const { bankLines: missingReceiptsBankLines, isLoading: isLoadingReceipts } = useMissingReceiptsBankLines()

  const missingInformationCount = missingInformationData?.pagination?.total || 0
  const missingReceiptsCount = missingReceiptsBankLines?.length || 0

  const count = missingInformationCount + missingReceiptsCount
  const isLoading = isLoadingMissingInfo || isLoadingReceipts

  return {
    count,
    isLoading,
  }
}
