import { LineHeight, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const HitItemLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: ${Spacing.M} ${Spacing.L};
`

export const HitItemLeft = styled.div`
  flex: 1 1 auto;
`

export const HitItemRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-shrink: 0;
  margin: ${Spacing.M} 0 ${Spacing.M} ${Spacing.M};
`

const HIT_DESCRIPTION_LINES_NUMBER = 2

export const HitDescription = styled.div`
  line-height: ${LineHeight.TextMicro};
  height: calc(${LineHeight.TextMicro} * ${HIT_DESCRIPTION_LINES_NUMBER});
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;

  display: -webkit-box;
  line-clamp: ${HIT_DESCRIPTION_LINES_NUMBER};
  -webkit-line-clamp: ${HIT_DESCRIPTION_LINES_NUMBER};
  -webkit-box-orient: vertical;
`
