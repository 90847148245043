import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'
import { Radio } from '../../../Radio'

export const TableRadioWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  left: ${Spacing.XXXS};
  align-items: center;
`

export const RadioWrapper = styled(Radio)`
  margin-left: ${Spacing.S};
`
