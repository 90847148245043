import { Color, getDefaultTransition, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Themable } from '../../../../../types/themable'

interface PeriodsListWrapperProps {
  disabled?: boolean
}

export const PeriodsListWrapper = styled.div<PeriodsListWrapperProps>`
  display: flex;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  min-height: 192px; // needed to keep dropdown height while switching between tabs (preparing list can take some miliseconds which causes blinking)
  flex-direction: column;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

interface PeriodItemsListProps {
  columns?: number
}

export const PeriodItemsList = styled.ul<PeriodItemsListProps>`
  display: grid;
  grid-template-columns: ${({ columns = 3 }) => `repeat(${columns}, 1fr)`};
  gap: ${Spacing.S};
`

export const PeriodItem = styled.li``

const ITEM_HEIGHT = 32

interface ItemButtonProps extends Themable {
  active?: boolean
  disabled?: boolean
}

export const ItemButton = styled.button<ItemButtonProps>`
  appearance: none;
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  display: flex;
  height: ${ITEM_HEIGHT}px;
  border: none;
  border-radius: ${ITEM_HEIGHT / 2}px;
  padding: ${Spacing.S} ${Spacing.S};
  align-items: center;
  color: ${({ active, theme }) => (active ? theme.ds.colors.base.textPrimary : undefined)};
  background-color: ${({ active }) => (active ? Color.ElectricGreen : Color.White)};
  justify-content: center;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  ${getDefaultTransition('background-color')};

  ${({ active, disabled, theme }) =>
    !active &&
    !disabled &&
    css`
      cursor: pointer;

      &:hover {
        color: ${Color.White};
        background-color: ${theme.ds.colors.base.textPrimary};
      }
    `}
`

export const ImagePlaceholderWrapper = styled.figure`
  display: flex;
  margin: auto;
`

export const ImagePlaceholder = styled.img`
  width: 157px;
  height: 132px;
`
