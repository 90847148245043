import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useInvitedOrganizations } from '../../hooks/useInvitedOrganizations'
import { UmbrelaOrganizationsInvitationsTableActions } from '../UmbrelaOrganizationsInvitationsTableActions'
import { UmbrellaOrganizationsInvitationsTable } from '../UmbrellaOrganizationsInvitationsTable'
import * as Styled from './styles'

export const UmbrellaOrganizationsInvitationsSection = (): ReactElement | null => {
  const { t } = useTranslation()
  const { data, isLoading } = useInvitedOrganizations()

  if (isLoading || data.length === 0) {
    return null
  }

  return (
    <Styled.UmbrellaOrganizationsInvitationsSectionWrapper>
      <Styled.InfoSection variant="info">{t('umbrella_organizations.organizations.info')}</Styled.InfoSection>
      <UmbrelaOrganizationsInvitationsTableActions />
      <UmbrellaOrganizationsInvitationsTable />
    </Styled.UmbrellaOrganizationsInvitationsSectionWrapper>
  )
}
