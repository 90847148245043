import { StatusFormatterVariant } from '@components'

import { RecurringInvoiceStatus } from '../../../enums/recurringInvoiceStatus'

export const recurringInvoiceStatusToStatusFormatterVariant: Record<RecurringInvoiceStatus, StatusFormatterVariant> = {
  [RecurringInvoiceStatus.All]: 'neutral',
  [RecurringInvoiceStatus.Active]: 'success',
  [RecurringInvoiceStatus.Failing]: 'error',
  [RecurringInvoiceStatus.Inactive]: 'warning',
}
