import { useEffect, useState } from 'react'

import { ThemeLayout } from '../types/themeLayout'

export const useSystemColorScheme = () => {
  const [systemColorScheme, setSystemColorScheme] = useState<ThemeLayout>()

  useEffect(() => {
    const isDarkScheme = Boolean(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
    const systemColorScheme: ThemeLayout = isDarkScheme ? 'dark' : 'light'
    setSystemColorScheme(systemColorScheme)

    const handleSchemeChanged = (event: MediaQueryListEvent) => {
      const newSystemColorScheme: ThemeLayout = event.matches ? 'dark' : 'light'
      setSystemColorScheme(newSystemColorScheme)
    }

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handleSchemeChanged)
    } else {
      // Safari 12 support
      // https://stackoverflow.com/questions/62693995/addeventlistener-is-not-a-function-for-matchmedia-in-safari-browser
      mediaQuery.addListener(handleSchemeChanged)
    }

    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handleSchemeChanged)
      } else {
        mediaQuery.removeListener(handleSchemeChanged)
      }
    }
  }, [])

  return { systemColorScheme }
}
