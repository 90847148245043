import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { deleteBill } from '../../../query-api'

interface UseDeleteBillProps {
  onError?: (error?: APIError) => void
  onSettled?: (data: void, error: APIError | null | undefined) => void
  onSuccess?: (data: void) => void
}

export const useDeleteBill = (props?: UseDeleteBillProps) => {
  const { onError, onSuccess, onSettled } = props || {}
  const { mutate, ...rest } = useMutation(deleteBill, {
    onError,
    onSuccess,
    onSettled,
  })

  return { delete: mutate, ...rest }
}
