import { notify, Text } from '@design-system'

import React, { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getImageClassName } from '../../../../utils/getClassName'
import { getImgPath } from '../../../../utils/getImgPath'
import { useConnectExistingSalaryAccount } from '../../hooks/useConnectExistingSalaryAccount'
import * as Styled from './styles'

export const SalaryConnect = (): ReactElement => {
  const { t } = useTranslation()
  const [salaryLink, setSalaryLink] = useState<string>()

  const imagePath = getImgPath(`/salary/billy-salary-integration-billy.svg`)

  const { connectAccount, isLoading } = useConnectExistingSalaryAccount({
    onSuccess: ({ redirect_url: redirectUrl }) => {
      window.location.href = redirectUrl
      notify({ id: 'connect-salary', message: t('salary.connect.success'), variant: 'success' })
      setSalaryLink(redirectUrl)
    },
    onError: (error) => {
      console.error(error?.message)
      notify({ id: 'connect-salary', message: t('salary.connect.error'), variant: 'error' })
    },
  })

  const handleConnectButtonClick = useCallback(() => {
    connectAccount()
  }, [connectAccount])

  return (
    <Styled.SalaryConnectWrapper>
      <Styled.IllustrationWrapper className={getImageClassName()}>
        <Styled.Illustration src={imagePath} alt="Salary" />
      </Styled.IllustrationWrapper>

      <Styled.ContentWrapper>
        <Styled.TitleWrapper>
          <Text variant="h2" alignment="center">
            {t('salary.connect.title')}
          </Text>
        </Styled.TitleWrapper>

        <Text colorVariant="secondary" alignment="center">
          {t('salary.connect.description')}
        </Text>
      </Styled.ContentWrapper>

      <Styled.Footer>
        {salaryLink ? (
          <Styled.Button size="xxl" href={salaryLink} target="_blank">
            {t('salary.connect.open_salary_button')}
          </Styled.Button>
        ) : (
          <Styled.Button size="xxl" loading={isLoading} onClick={handleConnectButtonClick}>
            {t('salary.connect.connect_button')}
          </Styled.Button>
        )}
      </Styled.Footer>
    </Styled.SalaryConnectWrapper>
  )
}
