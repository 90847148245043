import React from 'react'
import { Box } from 'rebass'

import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconLock = ({ color = 'inherit', color2, width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-lock')} color={color} {...rest}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color2}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect fill="none" stroke="currentColor" x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
      <path fill="none" stroke="currentColor" d="M7 11V7a5 5 0 0 1 10 0v4"></path>
    </svg>
  </Box>
)
