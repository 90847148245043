import { FontWeight } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../../../../../types/themable'

export const AddonsListWrapper = styled.div`
  margin: 12px;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const LineSeparator = styled.div<Themable>`
  margin: 16px 0;
  height: 1px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.newGray2}`};
`

export const Title = styled.p<Themable>`
  margin: auto 0;
  font-size: 20px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.newGrey1};
`

export const AddonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CenterColumn = styled(Column)`
  flex: 1;
  margin: 0 20px;
`

export const Name = styled.p<Themable>`
  font-weight: ${FontWeight.Medium};
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.ds.colors.base.textSecondary};
`

export const AvailableText = styled.p<Themable>`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.ds.colors.base.textSecondary};
`

export const PriceInfoText = styled.p<Themable>`
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: ${({ theme }) => theme.colors.grayA};
`
