import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const EmptyScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  justify-self: center;
  align-items: center;
`

export const Header = styled(Text)`
  margin-top: ${Spacing.XXXL};
  margin-bottom: ${Spacing.S};
`

export const SubText = styled(Text)``
