import { Switch } from '@design-system'

import React, { ReactElement } from 'react'

import * as Styled from './styles'

interface TermAndConditionProps {
  text: string
  accepted: boolean
  onTermsAccept: (key: string) => void
}

export const TermAndCondition = ({ text, accepted, onTermsAccept }: TermAndConditionProps): ReactElement => (
  <Styled.Container>
    <Styled.FlipSwitchWrapper>
      <Switch checked={accepted} size="l" onChange={() => onTermsAccept(text)} />
    </Styled.FlipSwitchWrapper>
    <Styled.Description>{text}</Styled.Description>
  </Styled.Container>
)
