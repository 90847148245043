import { MouseEvent } from 'react'

import { CustomEvent } from '../enums/customEvent'
import { dispatchCustomEvent } from './customEvent'

// there's an issue when we try to navigate using hash routing to the same module which is currently opened
// this is a temporary fix
// we won't be needing it once we remove hash routing
// it takes the element clicked and gets the href from it or child element
// if the href is the same as the current href, it prevents the default behavior and dispatches a custom event
// that is handled in the useCustomEventsRoutes hook in the current view that is using HashRouter
export const hashRouterLinkSecondClickFix = (event?: MouseEvent) => {
  let linkElement = event?.target as HTMLElement | null

  while (linkElement && linkElement.tagName !== 'A' && linkElement.parentElement) {
    linkElement = linkElement.parentElement
  }

  if (linkElement && linkElement.tagName === 'A') {
    const href = (linkElement as HTMLLinkElement).href
    const splitHref = href.split('#')
    const splitCurrentHref = window.location.href.split('#')
    const hash = splitHref[1] ? splitHref[1] : '/'

    if (splitHref[0] === splitCurrentHref[0]) {
      event?.preventDefault()

      dispatchCustomEvent<{ route: string }>(CustomEvent.RoutingLinkClicked, {
        route: hash,
      })
    }
  }
}
