import { SettingsSection } from '@components'

import React, { ReactElement } from 'react'

import { useInvoiceAndProductSettings } from '../../contexts/invoiceAndProductSettingsContext'
import { InvoiceSettingsColumn } from './elements/InvoiceSettingsColumn'
import { OrganizationLogoSection } from './elements/OrganizationLogoSection'
import { ProductSettingsColumn } from './elements/ProductSettingsColumn'

export const InvoiceAndProductSettingsForm = (): ReactElement => {
  const { Form } = useInvoiceAndProductSettings()

  return (
    <Form>
      <SettingsSection.ColumnsContainer>
        <InvoiceSettingsColumn />
        <SettingsSection.RowsContainer>
          <ProductSettingsColumn />
          <OrganizationLogoSection />
        </SettingsSection.RowsContainer>
      </SettingsSection.ColumnsContainer>
    </Form>
  )
}
