import { AlertsGroup } from '@design-system'

import { isBefore } from 'date-fns'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'

import { MaintenanceMessage } from '../../components-deprecated/MaintenanceMessage'
import { getRequest } from '../../utils'
import * as Styled from './styles'

type BannerType = 'info' | 'warning' | 'error' | 'success'

type Origin = 'all' | 'billy'

interface ApplicationBanner {
  copyDk: string
  copyEn: string
  dismissable: boolean
  uniqueKey: string
  origin: Origin
  bannerType: BannerType
  startDate: string
  endDate: string
}

export const MaintenanceBar = (): ReactElement => {
  const {
    i18n: { language },
  } = useTranslation()
  const [banners, setBanners] = useState<ApplicationBanner[]>([])
  const [dismissedMaintenanceBars, confirmMaintenanceBar] = useLocalStorage<string[]>('dismissedMaintenanceBars', [])

  const hasNotBeenDismissed = useCallback(
    ({ uniqueKey }: ApplicationBanner) => {
      return !dismissedMaintenanceBars?.includes(uniqueKey)
    },
    [dismissedMaintenanceBars],
  )

  const isFromExpectedOrigin = useCallback(({ origin }: ApplicationBanner) => {
    return origin === 'all' || origin === 'billy'
  }, [])

  const isInsideDateRange = useCallback((banner: ApplicationBanner) => {
    if (isBefore(new Date(), new Date(banner.startDate))) {
      return false
    }

    return !banner.endDate || isBefore(new Date(), new Date(banner.endDate))
  }, [])

  const getContentBasedOnLanguage = useCallback(
    (dkContent: string, enContent: string) => {
      switch (language) {
        case 'en':
          return enContent
        case 'dk':
        default:
          return dkContent
      }
    },
    [language],
  )

  const updateValue = useCallback(
    (id: string) => {
      confirmMaintenanceBar([...(dismissedMaintenanceBars || []), id])
    },
    [confirmMaintenanceBar, dismissedMaintenanceBars],
  )

  const notificationList = useMemo(() => {
    return banners.filter(hasNotBeenDismissed).filter(isFromExpectedOrigin).filter(isInsideDateRange)
  }, [banners, hasNotBeenDismissed, isFromExpectedOrigin, isInsideDateRange])

  const loadApplicationBanners = useCallback(async () => {
    try {
      const { applicationBanners } = await getRequest('/v2/applicationBanners')
      setBanners(applicationBanners)
    } catch (exception) {
      // We want to fail as gracefully as possible here, as this is a very secondary component
    }
  }, [])

  useEffect(() => {
    ;(async () => loadApplicationBanners())() // get tslint not to complain
  }, [loadApplicationBanners])

  return (
    <Styled.MaintenanceBarWrapper>
      <AlertsGroup>
        {notificationList.map(({ copyDk, copyEn, dismissable, uniqueKey, bannerType }) => (
          <MaintenanceMessage
            dismissable={dismissable}
            uniqueKey={uniqueKey}
            key={uniqueKey}
            onClose={updateValue}
            bannerType={bannerType}
          >
            {getContentBasedOnLanguage(copyDk, copyEn)}
          </MaintenanceMessage>
        ))}
      </AlertsGroup>
    </Styled.MaintenanceBarWrapper>
  )
}
