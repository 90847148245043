import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconSettings2 = ({
  color = '#697588',
  color2 = '#fff',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-settings2')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 397.3 397.3">
      <path
        fill={coloring(color)}
        d="M252.79,438.58a26.14,26.14,0,0,1-26.11-26.12V410.6a39.57,39.57,0,0,0-25.75-36.31,39.8,39.8,0,0,0-43.61,8.09l-1.16,1.15a26.11,26.11,0,0,1-36.94,0l-.07-.07a26.15,26.15,0,0,1-.08-36.8l.09-.08.12-.12,1.07-1.08a40,40,0,0,0-28.41-67.92H88.82a26.12,26.12,0,1,1,0-52.24h1.86A39.57,39.57,0,0,0,127,199.49a39.59,39.59,0,0,0-8.09-43.6l-1.16-1.17a26.15,26.15,0,0,1-.09-36.87l.16-.16a26.12,26.12,0,0,1,36.87,0l1.19,1.18a39.78,39.78,0,0,0,41.81,8.81h1.54l1.89-.81A39.76,39.76,0,0,0,225.23,90.5V87.39a26.12,26.12,0,0,1,52.24,0V89a40,40,0,0,0,67.92,28.46l1.16-1.17a26.12,26.12,0,0,1,36.94,0l0,0a26.12,26.12,0,0,1,0,36.93l-1.19,1.18a39.61,39.61,0,0,0-8.81,41.82v1.54l.81,1.89a39.78,39.78,0,0,0,36.42,24.12h3.11a26.12,26.12,0,1,1,0,52.23h-1.62A40,40,0,0,0,383.8,344l1.16,1.17a26.15,26.15,0,0,1,.2,36.76l-.18.16-.19.2a26.12,26.12,0,0,1-36.77-.13l-1.19-1.19a40,40,0,0,0-67.92,28.42v3.11A26.15,26.15,0,0,1,252.79,438.58Zm-1.44-252.83a64.18,64.18,0,1,0,64.18,64.18A64.25,64.25,0,0,0,251.35,185.75Z"
        transform="translate(-52.7 -51.27)"
      />
      <path
        fill={coloring(color2)}
        d="M251.35,71.27a16.14,16.14,0,0,1,16.12,16.12V89.1a50,50,0,0,0,84.91,35.53l.08-.08.08-.08,1.09-1.09a16.1,16.1,0,0,1,22.79,0h0a16.11,16.11,0,0,1,0,22.8l0,0h0l-1.06,1.06-.08.08-.08.08A49.56,49.56,0,0,0,363.54,198v1.87l1.62,3.78a49.77,49.77,0,0,0,45.57,30.18h3.15a16.12,16.12,0,1,1,0,32.23h-1.7A50,50,0,0,0,376.65,351l.08.08.07.07,1.1,1.09a16.14,16.14,0,0,1,0,22.78h0l0,0a16.13,16.13,0,0,1-22.79,0l0,0L354,373.94l-.08-.08-.08-.08a50,50,0,0,0-84.91,35.53v3.15a16.12,16.12,0,1,1-32.23,0v-2.09a49.79,49.79,0,0,0-32-45.35,49.79,49.79,0,0,0-54.35,10.21l-.08.07-.08.08-1.09,1.09a16.11,16.11,0,0,1-22.79,0l0,0a16.13,16.13,0,0,1,0-22.8l0,0h0l1.06-1.06.08-.08.07-.08A50,50,0,0,0,92,267.49H88.82a16.12,16.12,0,1,1,0-32.24h2.09a49.81,49.81,0,0,0,45.35-32,49.56,49.56,0,0,0-10.21-54.35l-.08-.08-.08-.08-1.09-1.09a16.14,16.14,0,0,1,0-22.77l0,0,0,0a16.13,16.13,0,0,1,22.79,0l0,0,1.07,1.08.07.07.08.08a49.81,49.81,0,0,0,50.53,11.66h1.88l3.77-1.61a49.8,49.8,0,0,0,30.18-45.58V87.39a16.14,16.14,0,0,1,16.12-16.12m0,252.83a74.18,74.18,0,1,0-74.18-74.17,74.26,74.26,0,0,0,74.18,74.17m0-272.83a36.12,36.12,0,0,0-36.12,36.12v3.07a29.8,29.8,0,0,1-18.06,27.27h-1.44a29.78,29.78,0,0,1-32.87-6l-1.08-1.08,0,0a36.13,36.13,0,0,0-51.08,0l0,0a36.12,36.12,0,0,0,0,51.08l1.08,1.08a31.27,31.27,0,0,1-21.31,52.37H88.82a36.12,36.12,0,1,0,0,72.24h3.07a30,30,0,0,1,21.31,50.93l-1.09,1.08,0,0a36.13,36.13,0,0,0,0,51.08l0,0a36.13,36.13,0,0,0,51.08,0l1.08-1.09a31.28,31.28,0,0,1,52.38,21.31v1.63a36.12,36.12,0,1,0,72.23,0v-3.07a30,30,0,0,1,50.93-21.31l1.08,1.08,0,0a36.13,36.13,0,0,0,51.08,0l0,0a36.12,36.12,0,0,0,0-51.08L391,337A30,30,0,0,1,412.26,286h1.62a36.12,36.12,0,1,0,0-72.23h-3.07a29.82,29.82,0,0,1-27.27-18.06V194.3a29.78,29.78,0,0,1,6-32.86l1.09-1.09,0,0a36.12,36.12,0,0,0,0-51.08l0,0a36.12,36.12,0,0,0-51.08,0l-1.09,1.09A30,30,0,0,1,287.47,89V87.39a36.12,36.12,0,0,0-36.12-36.12Zm0,252.83a54.18,54.18,0,1,1,54.18-54.17,54.18,54.18,0,0,1-54.18,54.17Z"
        transform="translate(-52.7 -51.27)"
      />
    </svg>
  </Box>
)
