import { Translate } from '@components'
import { Button, ButtonsGroup } from '@design-system'

import React, { ReactElement } from 'react'

import * as Styled from './styles'

interface VouchersActionsProps {
  isEditMode?: boolean
  onDelete: () => void
  onEditModeChange: () => void
  selectedRows: number
  totalRows: number
}

export const VouchersActions = ({
  isEditMode,
  onDelete,
  onEditModeChange,
  selectedRows,
  totalRows,
}: VouchersActionsProps): ReactElement => (
  <Styled.VouchersActionsWrapper>
    {isEditMode ? (
      <>
        <Styled.VouchersActionsText>
          <Translate count={selectedRows} value="voucher.inbox.vouchers_selected" />
        </Styled.VouchersActionsText>
        <ButtonsGroup>
          <Button danger disabled={!selectedRows} onClick={onDelete} size="m">
            <Translate value="delete" />
          </Button>
          <Button onClick={onEditModeChange} size="m">
            <Translate value="done" />
          </Button>
        </ButtonsGroup>
      </>
    ) : (
      <>
        <Styled.VouchersActionsText>
          <Translate count={totalRows} value="voucher.inbox.vouchers_total" />
        </Styled.VouchersActionsText>
        <Button variant="secondary" onClick={onEditModeChange} size="m">
          <Translate value="edit" />
        </Button>
      </>
    )}
  </Styled.VouchersActionsWrapper>
)
