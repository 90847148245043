import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconDot = ({ color = 'inherit', width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-dot')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <circle fill={coloring(color)} cx="10" cy="10" r="3.5"></circle>
    </svg>
  </Box>
)
