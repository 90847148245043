import cc from 'classcat'
import React from 'react'
import { Flex, FlexProps } from 'rebass'

import { reactClass } from '../../../utils'

export const FlexLeft = ({ children, className, ...rest }: FlexProps) => (
  <Flex className={cc([className, reactClass('flex-left')])} flex="auto" justifyContent="flex-start" {...rest}>
    {children}
  </Flex>
)
