import { useMutation } from 'react-query'

import { APIError } from '../../../utils'
import { updateAttachment, UpdateAttachmentResponseData } from '../query-api'

interface UseCreateOrganizationProps {
  onError?: (error?: APIError) => void
  onSuccess?: (response: UpdateAttachmentResponseData) => void
}

export const useUpdateReceipt = (props?: UseCreateOrganizationProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: update, ...rest } = useMutation(updateAttachment, {
    onSuccess: (response) => {
      onSuccess?.(response)
    },
    onError: (error?: APIError) => {
      onError?.(error)
    },
  })

  return { update, ...rest }
}
