import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'
import { Themable } from '../../types/themable'
import { rwdMenuSpacingForTitle } from '../../utils/rwdMenuSpacingForTitle'

type ModuleLayoutWrapperProps = Themable

export const ModuleLayoutWrapper = styled.main<ModuleLayoutWrapperProps>`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  background-color: ${({ theme }) => theme.ds.colors.base.background};
`

type ModuleStickyHeaderProps = Themable

export const ModuleStickyHeader = styled.header<ModuleStickyHeaderProps>`
  width: 100%;
  padding-top: ${Spacing.XL};
  padding-bottom: ${Spacing.XL};
  background-color: ${({ theme }) => theme.ds.colors.base.background};
`

export const Header = styled.div`
  ${rwdMenuSpacingForTitle}
`

export const BackButtonWrapper = styled.div`
  margin-right: ${Spacing.S};
  cursor: pointer;
`

export const HeaderBottomActionsWrapper = styled.div`
  margin-top: ${Spacing.S};
`

export const SideActions = styled.aside`
  padding-left: ${Spacing.L};
`
