import { AttachmentType } from '@design-system'

import { ReceiptType } from '../enums/receiptType'

interface Receipt {
  type: AttachmentType | null
}

export const getReceiptType = (receipt: Receipt): ReceiptType => {
  if (receipt.type === ReceiptType.EDocument) {
    return ReceiptType.EDocument
  }

  return ReceiptType.Upload
}
