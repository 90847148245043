import { createContext, ReactElement, ReactNode, useContext, useState } from 'react'

import { EmberEventFn } from '../../../../../types/emberEventFn'

interface ContextState {
  canResend: boolean
  contactEAN?: string
  contactId: string
  invoiceId: string
  message?: string
  selectedContactPersonId?: string
  sendMode: SendMode
  setSendMode: (mode: SendMode) => void
  subject?: string
  onInvoiceSent: EmberEventFn
}

const SendInvoiceModalContext = createContext<ContextState | undefined>(undefined)

interface SendInvoiceModalContextProps {
  canResend: boolean
  children: ReactNode
  contactCVR?: string
  contactEAN?: string
  contactId: string
  invoiceId: string
  message?: string
  selectedContactPersonId?: string
  sendAsEInvoice: boolean
  subject?: string
  onInvoiceSent: EmberEventFn
}

export type SendMode = 'eInvoice' | 'email'

export const SendInvoiceModalContextProvider = ({
  canResend,
  children,
  contactEAN,
  contactId,
  invoiceId,
  message,
  selectedContactPersonId,
  sendAsEInvoice,
  subject,
  onInvoiceSent,
}: SendInvoiceModalContextProps): ReactElement => {
  const [sendMode, setSendMode] = useState<SendMode>(sendAsEInvoice ? 'eInvoice' : 'email')

  return (
    <SendInvoiceModalContext.Provider
      value={{
        sendMode,
        canResend,
        setSendMode,
        contactId,
        invoiceId,
        subject,
        message,
        selectedContactPersonId,
        contactEAN,
        onInvoiceSent,
      }}
    >
      {children}
    </SendInvoiceModalContext.Provider>
  )
}

export const useSendInvoiceModal = () => {
  const context = useContext(SendInvoiceModalContext)

  if (!context) {
    throw new Error('SendInvoiceModalContextProvider is missing in the module!')
  }

  return context
}
