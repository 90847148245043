import { SettingsSection } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../../../enums/modalId'
import { DeleteLogoModal } from './elements/DeleteLogoModal'
import { OrganizationLogo } from './elements/OrganizationLogo'
import * as Styled from './styles'

export const OrganizationLogoSection = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <>
      <SettingsSection.Content
        title={t('settings.organization.organization_info.logo.title')}
        subtitle={t('settings.organization.organization_info.logo.subtitle')}
      >
        <Styled.LogoWrapper>
          <OrganizationLogo />
        </Styled.LogoWrapper>
      </SettingsSection.Content>
      <DeleteLogoModal id={ModalId.DeleteOrganizationLogoModal} />
    </>
  )
}
