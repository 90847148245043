/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use 'AccountSelect' from /components instead
 */
import {
  Checkbox,
  FormControl,
  FormLabel,
  SelectListItem,
  SmartSelect,
  SmartSelectItem,
  SmartSelectProps,
} from '@components-deprecated'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React, { ChangeEvent, ReactElement, ReactNode, useEffect, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Flex } from 'rebass'

import { useAccounts } from '../accountsContext'
import { Account, AccountNature, SelectorAccountType } from '../types'
import { getFilteredAccounts } from '../utils/getFilteredAccounts'

type AccountSelectorContainerProps = {
  withCheckbox?: boolean
}

const AccountSelectorContainer = styled.div<AccountSelectorContainerProps>`
  display: flex;

  ${({ withCheckbox }) =>
    withCheckbox &&
    `
    input[type=text] {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  `}
`

type Checked = { checked: boolean }

export type AccountChecked = Account & Checked

const simplifyAccountsList = (accounts: SelectorAccountType[]) =>
  accounts.map((account) => ({ ...account, text: account.name })).filter((account) => !account.isHeading)

const simplifiedAccountRender = <T extends SmartSelectItem>({ id, isHeading, name, ...rest }: T) => (
  <SelectListItem id={id} key={id} {...rest} text={name} description={false} />
)

type SelectAccountProps = {
  defaultValueId?: string
  isArchived?: boolean
  isBankAccount?: boolean
  isPaymentEnabled?: boolean
  natures?: AccountNature[]
  accountErrorPath?: string
}

export type AccountSelectorComponentProps = Omit<SmartSelectProps<Account>, 'onChange' | 'onItemSelect'> &
  SelectAccountProps & {
    checkboxLabel?: ReactNode
    defaultValueId?: string
    formControl?: Control<any>
    onChange?: (value: AccountChecked) => void
    onItemSelect?: (value: AccountChecked, name: string) => void
    organizationId?: string
    simplifiedList?: boolean
    stale?: boolean
    withCheckbox?: boolean
  }

export const AccountSelectorComponent = ({
  accountErrorPath,
  checkboxLabel,
  defaultChecked = false,
  defaultValueId,
  disabled,
  disconnect,
  formControl,
  name = 'account-selector',
  onChange = () => null,
  onItemSelect = () => null,
  organizationId: providedOrganizationId,
  simplifiedList,
  stale,
  withCheckbox,
  preselectedId,
  ...rest
}: AccountSelectorComponentProps) => {
  const [account, setAccount] = useState<Account>()
  const [checked, setChecked] = useState(defaultChecked)

  const { t } = useTranslation()

  const { accounts: allAccounts = [] } = useAccounts()
  const accounts = getFilteredAccounts(allAccounts, rest)

  const handleAccountSelect = (item: Account) => {
    setAccount(item)

    const value = { ...item, checked }
    onChange && onChange(value)
    onItemSelect && onItemSelect(value, name)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checkedUpdated = event.target.checked
    const value = account
      ? { ...account, checked: checkedUpdated }
      : ({ id: '', checked: checkedUpdated } as AccountChecked)

    setChecked(checkedUpdated)
    onChange && onChange(value)
    onItemSelect && onItemSelect(value, name)
  }

  useEffect(() => {
    const accountNew = accounts.find((account) => account.id === defaultValueId)

    if (!account || defaultValueId !== account.id) {
      setAccount(accountNew)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValueId, accounts])

  useEffect(() => {
    if (defaultChecked !== undefined && defaultChecked !== checked) {
      setChecked(defaultChecked)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultChecked])

  return (
    <AccountSelectorContainer withCheckbox={withCheckbox}>
      <SmartSelect<Account>
        allowCreateNew={false} // we don't allow to create new accounts, so this is explicitly set here
        width="100%"
        formControl={formControl}
        items={simplifiedList ? simplifyAccountsList(accounts) : accounts}
        name={name}
        defaultValueId={account?.id}
        preselectedId={preselectedId || account?.id}
        notFoundText={t('app.accounts.account_select.not_found')}
        onItemSelect={handleAccountSelect}
        placeholder={t('app.accounts.account_select.select_account')}
        disconnect={!!formControl || disconnect}
        render={simplifiedList ? simplifiedAccountRender : undefined}
        disabled={disabled}
        errorPath={accountErrorPath}
        listWidth={370}
        {...rest}
      />

      {withCheckbox && (
        <FormControl
          css={css`
            flex-shrink: 0;
          `}
        >
          <FormLabel name="checkbox">{checkboxLabel}</FormLabel>
          <Flex
            variant="forms.datecheckbox.checkboxwrapper"
            alignItems="center"
            justifyContent="center"
            className={disabled ? 'disabled' : ''}
            css={css`
              border-left: 0;
              height: 40px;
              justify-content: center;
              align-items: center;
              width: auto;
              label {
                margin-bottom: 0;
              }
              label span {
                display: none;
              }
            `}
          >
            <Checkbox
              name={`${name}_checked`}
              padding={1}
              margin={0}
              width="22px"
              checked={checked}
              disabled={disabled}
              onChange={handleCheckboxChange}
              disconnect={disconnect}
            />
          </Flex>
        </FormControl>
      )}
    </AccountSelectorContainer>
  )
}

// Controlled
export type AccountSelectorControlledResult = AccountChecked

export type AccountSelectorProps = AccountSelectorComponentProps

export const AccountSelector = ({
  formControl,
  name = '',
  withSelectEvent,
  ...rest
}: AccountSelectorProps): ReactElement => {
  if (!formControl) {
    return <AccountSelectorComponent {...rest} name={name} />
  }

  return (
    <Controller
      render={({ field }) => {
        const value = field.value as AccountSelectorControlledResult

        return (
          <AccountSelectorComponent
            {...rest}
            name={field.name}
            defaultValueId={value?.id}
            checked={value?.checked}
            onChange={(value: AccountChecked) => {
              field.onChange(value)
              withSelectEvent && rest.onItemSelect && rest.onItemSelect(value, field.name)
            }}
            accountErrorPath={`${name}.id`}
            disconnect
          />
        )
      }}
      control={formControl}
      name={name}
    />
  )
}
