/*
  NOTE: this util positions elements which are in the same index level,
  so you should consider wrapping each of such elements into the "createPortal" method or positioned "fixed"
*/
import reduce from 'lodash/reduce'
import reverse from 'lodash/reverse'

const INITIAL_INDEX = 100

enum Element {
  Alert = 'Alert',
  DraggableTrigger = 'DraggableTrigger',
  DraggableWidget = 'DraggableWidget',
  Dropdown = 'Dropdown',
  DropzoneFullScreen = 'DropzoneFullScreen',
  FullScreenOverlay = 'FullScreenOverlay',
  GlobalSearch = 'GlobalSearch',
  Menu = 'Menu',
  MenuOverlay = 'MenuOverlay',
  Modal = 'Modal',
  ModalOverlay = 'ModalOverlay',
  Navbar = 'Navbar',
  NotesPanel = 'NotesPanel',
  Notification = 'Notification',
  SidePanel = 'SidePanel',
  TableHeader = 'TableHeader',
  TableStickyHeader = 'TableStickyHeader',
  Tooltip = 'Tooltip',
}

// Higher z-index first:
const order: Element[] = [
  Element.Notification,
  Element.DropzoneFullScreen,
  Element.Navbar,
  Element.Tooltip,
  Element.Dropdown,
  Element.Modal,
  Element.ModalOverlay,
  Element.FullScreenOverlay,
  Element.GlobalSearch,
  Element.NotesPanel,
  Element.SidePanel,
  Element.Menu,
  Element.MenuOverlay,
  Element.Alert,
  Element.TableStickyHeader,
  Element.TableHeader,
  Element.DraggableWidget,
]

export const zIndex: Record<Element, number> = reduce(
  reverse(order),
  (result, element, index) => ({ ...result, ...{ [element]: INITIAL_INDEX + index } }),
  {} as Record<Element, number>,
)
