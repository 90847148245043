import { NavItem } from '@design-system'

import reduce from 'lodash/reduce'

import { TaxMetaField } from '../../../../../../../VatReturnSettings'
import { getDisplayTitle } from './getDisplayTitle'

const getItem = (field: TaxMetaField): NavItem<TaxMetaField> => {
  const displayTitle = getDisplayTitle(field)

  const searchKeywords = [field.name]

  if (field.description) {
    searchKeywords.push(...field.description.split(' '))
  }

  return {
    id: field.id,
    children: displayTitle,
    searchKeywords,
    value: field,
  }
}

export const getItems = (accounts: TaxMetaField[]): NavItem<TaxMetaField>[] => {
  return reduce(
    accounts,
    (result, account) => {
      const item = getItem(account)

      result.push(item)
      return result
    },
    [] as NavItem<TaxMetaField>[],
  )
}
