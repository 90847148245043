import { Text, useRadioGroup } from '@design-system'

import uniqueId from 'lodash/uniqueId'
import { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import * as Styled from './styles'

export interface RadioButtonSectionProps<T = string> {
  checked?: boolean
  description: string
  label: string
  onChange: (value?: T) => void
  value: T
}

export const RadioButtonSection = <T extends string>({
  checked,
  description,
  label,
  onChange,
  value,
}: RadioButtonSectionProps<T>): ReactElement => {
  const { name: groupName, value: groupValue, onChange: onGroupChange } = useRadioGroup()
  const groupValueTyped = groupValue as T
  const [checkedValue, setCheckedValue] = useState<boolean | undefined>(checked || groupValueTyped === value)

  const id = useMemo(() => `${uniqueId('radio-')}-${value}`, [value])
  const radioGroupName = useMemo(() => `group-${groupName}`, [groupName])

  const handleValueChange = useCallback((newCheckedValue: boolean) => {
    setCheckedValue(newCheckedValue)
  }, [])

  const handleRadioChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCheckedValue(event.currentTarget.checked)
      onGroupChange?.(event)
    },
    [onGroupChange],
  )

  useEffect(() => {
    if (checked !== undefined) {
      handleValueChange(checked)
    }
  }, [checked, handleValueChange])

  useEffect(() => {
    if (groupValueTyped) {
      handleValueChange(groupValueTyped === value)
      onChange(groupValueTyped)
    }
  }, [groupValue, groupValueTyped, handleValueChange, onChange, value])

  return (
    <Styled.RadioButtonSectionWrapper htmlFor={id} checked={checkedValue}>
      <Styled.LeftSection>
        <Styled.RadioControl checked={checkedValue} />
      </Styled.LeftSection>
      <Styled.RightSection>
        <Styled.NativeRadio
          checked={checkedValue}
          id={id}
          name={radioGroupName}
          onChange={handleRadioChange}
          type="radio"
          value={value}
        />
        <Styled.RadioLabelWrapper>
          <Text variant="h3">{label}</Text>
        </Styled.RadioLabelWrapper>
        <Text variant="micro">{description}</Text>
      </Styled.RightSection>
    </Styled.RadioButtonSectionWrapper>
  )
}
