import { zIndex } from '@design-system'

import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Themable } from '../../../types/themable'

interface FullScreenWrapperProps extends Themable {
  visible?: boolean
}

export const FullScreenWrapper = styled.div<FullScreenWrapperProps>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.Modal};
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  transition: opacity 0.3s ease-out;

  ${() => ModalWrapper} {
    transform: ${({ visible }) => (visible ? 'translateY(0)' : 'translateY(30px)')};
  }
`

export const ModalWrapper = styled.div<Themable>`
  padding: 10px;
  width: 75vw;
  height: 75vh;
  margin: auto;
  border-radius: 8px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 34px 100px ${({ theme }) => transparentize(0.85, theme.colors.black)};
  transition: transform 0.3s ease-out;
`

export const CloseWrapper = styled.div`
  position: absolute;
  top: -40px;
  right: 0;
  cursor: pointer;
`
