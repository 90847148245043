import { Organization } from '@modules-deprecated/app/organization/types/organization'

import { Metable } from '../../../../types/metable'
import { deleteRequest, getRequest, putRequest } from '../../../../utils'
import { ProductPlan } from '../umbrellaSubscriptions/types/productPlan'
import { UpdateVoucherInboxProps } from './elements/UmbrellaOrganizationsUpdateVoucherInboxModal/hooks/useUpdateVoucherInbox'

interface GetProductPlansResponseData extends Metable {
  data: ProductPlan[]
}

export const getProductPlansForOrganization = (
  umbrellaId: string | undefined,
  organizationId: string,
): Promise<GetProductPlansResponseData> => {
  return getRequest(`/umbrellas/${umbrellaId}/organizations/${organizationId}/getProductPlans`)
}

export interface UpdateConsolidatedBillingPayload {
  organizationId: string
  consolidatedBilling: boolean
}

export interface UpdateConsolidatedBillingResponse extends Metable {
  organizations: Organization[]
}

export const updateConsolidatedBilling = ({
  organizationId,
  consolidatedBilling,
}: UpdateConsolidatedBillingPayload): Promise<UpdateConsolidatedBillingResponse> => {
  return putRequest(`/v2/organizations/${organizationId}/consolidatedBilling`, {
    data: {
      consolidatedBilling,
    },
  })
}

export interface DeleteOrganizationFromUmbrellaPayload {
  umbrellaId: string
  umbrellaOrganizationId: string
}

export const deleteOrganizationFromUmbrella = ({
  umbrellaId,
  umbrellaOrganizationId,
}: DeleteOrganizationFromUmbrellaPayload) => {
  return deleteRequest(`/umbrellas/${umbrellaId}/organizations/${umbrellaOrganizationId}`)
}

export function updateVoucherInboxEnabled({ organizationId, isInboxEnabled }: UpdateVoucherInboxProps): Promise<void> {
  return putRequest(`/v2/organizations/${organizationId}/settings/billy/isInboxEnabled`, { value: isInboxEnabled })
}
