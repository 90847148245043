import { NavItem } from '@design-system'

import { CountryGroup } from '../../../../../../../../../types/countryGroup'
import { getDisplayTitle } from './getDisplayTitle'

const getItem = (field: CountryGroup): NavItem<CountryGroup> => {
  const displayTitle = getDisplayTitle(field)

  return {
    id: field.id,
    children: displayTitle,
    value: field,
  }
}

export const getItems = (accounts: CountryGroup[]): NavItem<CountryGroup>[] => accounts.map(getItem)
