import styled from '@emotion/styled'

import { DefaultValue } from '../../../../enums/defaultValue'
import { Spacing } from '../../../../enums/spacing'
import { Alignment } from '../../../../types/alignment'
import { MODAL_HORIZONTAL_PADDING } from '../../constants/modalHorizontalPadding'

const alignmentToJustifyContent: Record<Alignment, string> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

interface ModalFooterWrapperProps {
  alignment: Alignment
}

export const ModalFooterWrapper = styled.footer<ModalFooterWrapperProps>`
  display: flex;
  min-height: 72px;
  border-radius: 0 0 ${DefaultValue.BorderRadius} ${DefaultValue.BorderRadius};
  padding: ${Spacing.L} ${MODAL_HORIZONTAL_PADDING} ${Spacing.XL};
  justify-content: ${({ alignment }) => alignmentToJustifyContent[alignment]};
`
