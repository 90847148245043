import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../enums/queryKeys'
import { fetchContactPersons, FetchContactPersonsOptions } from '../query-api/contactPerson-query'

interface UseContactPersonsProps {
  contactId?: string
  searchQuery?: string
}

export const useContactPersons = ({ contactId, searchQuery }: UseContactPersonsProps) => {
  const queryProps: FetchContactPersonsOptions = useMemo(
    () => ({
      contactId: contactId as string,
      q: searchQuery,
      pageSize: 100,
    }),
    [contactId, searchQuery],
  )

  const { data: contactPersons, ...rest } = useQuery(
    [QueryKeys.ContactPersons, queryProps],
    () => fetchContactPersons(queryProps),
    {
      enabled: !!contactId,
      cacheTime: 0,
    },
  )

  return { contactPersons, ...rest }
}
