import React, { ReactElement } from 'react'

import * as Styled from './styles'

export const SkeletonMenu = (): ReactElement => (
  <>
    <Styled.SkeletonMenuItem />
    <Styled.SkeletonMenuItem width={120} />
    <Styled.SkeletonMenuItem width={150} />
    <Styled.SkeletonMenuItem width={120} />
    <Styled.SkeletonMenuItem />
    <Styled.SkeletonMenuItem />
    <Styled.SkeletonMenuItem width={120} />
    <Styled.SkeletonMenuItem />
    <Styled.SkeletonMenuItem width={140} />
  </>
)
