import { SectionMessage } from '@design-system'

import { TFunction } from 'i18next'
import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SignupErrorCode } from '../../../../query-api/signup-query'
import * as Styled from './styles'

interface ErrorMessageProps {
  errorCode?: string
  defaultMessage: string
}

const getErrorToMessageMap = (t: TFunction): Record<string, string> => ({
  [SignupErrorCode.EmailAlreadyExists]: t('signup_guide.create_user.error_email_exists'),
})

export const ErrorMessage = ({ defaultMessage, errorCode }: ErrorMessageProps): ReactElement => {
  const { t } = useTranslation()
  const message = useMemo(() => errorCode && getErrorToMessageMap(t)[errorCode], [t, errorCode])
  const fallbackMessage = `${defaultMessage} ${errorCode ? ` Code: ${errorCode}.` : ''}`

  return (
    <Styled.ErrorMessageWrapper>
      <SectionMessage variant="error">{message || fallbackMessage}</SectionMessage>
    </Styled.ErrorMessageWrapper>
  )
}
