import { RefObject, useEffect } from 'react'
import { useLocation } from 'react-use'

interface UseScrollResetProps {
  isEnabled?: boolean
  scrollableElementRef: RefObject<HTMLDivElement>
}

export const useScrollReset = ({ isEnabled, scrollableElementRef }: UseScrollResetProps) => {
  const location = useLocation()

  useEffect(() => {
    if (isEnabled && !!scrollableElementRef.current) {
      const scrollableElement = scrollableElementRef.current

      scrollableElement?.scrollTo(0, 0)
    }
  }, [isEnabled, location, scrollableElementRef])
}
