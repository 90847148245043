import uniqBy from 'lodash/uniqBy'

import { SearchResultItem } from '../types/searchResultItem'

export const combineSearchResultItems = (...resultItemsArray: SearchResultItem[][]): SearchResultItem[] => {
  const allItems: SearchResultItem[] = []

  for (const items of resultItemsArray) {
    allItems.push(...items)
  }

  return uniqBy(allItems, 'id')
}
