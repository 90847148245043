import { NavItem, notify, TableStaticActions, useModal, withModalConditionalRender } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../../../../../../enums/notificationKeys'
import { Scope } from '../../../../../../../../../../enums/scope'
import { useIsBasicPlan } from '../../../../../../../../../../hooks/useIsBasicPlan'
import { isAuthorized } from '../../../../../../../../../../utils/isAuthorized'
import { InvoiceTemplateTableRow } from '../../../../types/invoiceTemplateTableRow'
import { InvoiceTemplatesTableStaticActionType } from '../../enums/invoiceTemplatesTableStaticActionType'
import { getDeleteInvoiceTemplateModalId } from '../../utils/getDeleteInvoiceTemplateModalId'
import { DeleteInvoiceTemplateModal } from '../DeleteInvoiceTemplateModal'

const DeleteInvoiceTemplateModalConditional = withModalConditionalRender(DeleteInvoiceTemplateModal)

export const InvoiceTemplatesTableStaticActions = ({
  invoiceTemplateId,
  isDefaultInvoiceTemplate,
}: Omit<InvoiceTemplateTableRow, 'invoiceTemplateName' | 'invoiceTemplate' | 'id'>): ReactElement => {
  const { t } = useTranslation()
  const { isBasicPlan } = useIsBasicPlan()
  const { open: openUpgradeSubscriptionModal } = useModal(ModalId.UpgradeSubscriptionModal)

  const deleteTemplateModalId = getDeleteInvoiceTemplateModalId(invoiceTemplateId)
  const { open: openDeleteTemplateModal } = useModal(deleteTemplateModalId)

  const shouldShowUpgradeSubscriptionModal = isBasicPlan || !isAuthorized(Scope.InvoiceDesignerWrite)

  const handleDeleteButtonClick = useCallback(() => {
    if (shouldShowUpgradeSubscriptionModal) {
      openUpgradeSubscriptionModal()
      return
    }

    if (isDefaultInvoiceTemplate) {
      notify({
        id: NotificationKeys.InvoiceTemplateDelete,
        message: t(
          'settings.organization.invoice.templates_settings.table.actions.delete_default_template.notification',
        ),
        variant: 'warning',
      })
      return
    }

    openDeleteTemplateModal()
  }, [
    shouldShowUpgradeSubscriptionModal,
    isDefaultInvoiceTemplate,
    openDeleteTemplateModal,
    openUpgradeSubscriptionModal,
    t,
  ])

  const staticActionsItems: NavItem[] = useMemo(
    () => [
      {
        children: t('settings.organization.invoice.templates_settings.table.actions.delete'),
        iconLeft: 'trash',
        id: 'delete',
        value: InvoiceTemplatesTableStaticActionType.Delete,
      },
    ],
    [t],
  )

  return (
    <>
      <TableStaticActions items={staticActionsItems} onSelect={handleDeleteButtonClick} />
      <DeleteInvoiceTemplateModalConditional id={deleteTemplateModalId} invoiceTemplateId={invoiceTemplateId} />
    </>
  )
}
