import { Icon, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Input as RebassInput, InputProps as RebassInputProps } from '@rebass/forms'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'rebass'

const LeftIconWrapper = styled.figure`
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  left: ${Spacing.M};
  align-items: center;
`

interface RightIconWrapperProps {
  disabled?: boolean
}

const RightIconWrapper = styled.figure<RightIconWrapperProps>`
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  right: ${Spacing.M};
  z-index: 1;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`

export type SearchBoxProps = RebassInputProps & {
  clearable?: boolean
  disabled?: boolean
  large?: boolean
  onValueChange: (e: any) => any
  placeholderT?: string
  withSearchIcon?: boolean
  defaultValue?: string
}

export const SearchBox = ({
  clearable = true,
  disabled,
  large,
  onValueChange,
  placeholderT = 'search_box.default_placeholder',
  withSearchIcon,
  defaultValue,
  ...rest
}: SearchBoxProps) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputVal, setInputVal] = useState(defaultValue || '')
  const debouncedOnChange = useCallback(debounce(onValueChange, 250), [])

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== inputVal) {
      setInputVal(defaultValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  const handleOnChange = useCallback(
    (e: any) => {
      debouncedOnChange(e.target.value)
      setInputVal(e.target.value)
    },
    [debouncedOnChange],
  )

  const handleClear = () => {
    if (inputRef.current) {
      onValueChange('')
      setInputVal('')
      inputRef.current.value = ''
      inputRef.current.focus()
    }
  }

  return (
    <Box
      css={css`
        display: flex;
        height: 40px;
        position: relative;
        align-items: center;
      `}
      {...rest}
    >
      {withSearchIcon && (
        <LeftIconWrapper>
          <Icon icon="magnifyingGlass" data-testid="search-box-magnifier-icon" />
        </LeftIconWrapper>
      )}

      <RebassInput
        ref={inputRef}
        disabled={disabled}
        placeholder={t(placeholderT)}
        onChange={handleOnChange}
        defaultValue={defaultValue || ''}
        value={inputVal}
        data-testid="search-box"
        css={css`
          padding-left: ${withSearchIcon ? Spacing.XXXL : Spacing.M};
          padding-right: ${clearable ? Spacing.XXXL : Spacing.M};
        `}
      />

      {clearable && inputVal && (
        <RightIconWrapper>
          <Icon icon="xSign" data-testid="search-box-clear-icon" onClick={handleClear} />
        </RightIconWrapper>
      )}
    </Box>
  )
}
