import styled from '@emotion/styled'

import { Asable } from '../../../types/asable'

type AlignSelf = 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'

export interface FlexItemWrapperProps extends Asable {
  // flex?: string // not exposing in purpose - it's to flexible property - shouldn't be set up as an attribute
  // flexBasis?: number | string | 'auto' // not exposing in purpose - it's to flexible property - shouldn't be set up as an attribute
  alignSelf?: AlignSelf
  flexGrow?: 1 | 0
  flexShrink?: 1 | 0
  order?: number
}

export const FlexItemWrapper = styled.div<FlexItemWrapperProps>`
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf}`};
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow}`};
  ${({ flexShrink }) => flexShrink && `flex-shrink: ${flexShrink}`};
  ${({ order }) => order && `order: ${order}`};
`
