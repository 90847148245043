import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconPerson = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-person')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={coloring(color)}
        d="M25,31H7c-1.657,0-3-1.343-3-3v-6.277c0-1.067,0.567-2.054,1.488-2.591l9-5.25 c0.934-0.545,2.089-0.545,3.023,0l9,5.25C27.433,19.669,28,20.656,28,21.723V28C28,29.657,26.657,31,25,31z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M25,32H7c-2.206,0-4-1.794-4-4v-6.277c0-1.417,0.76-2.741,1.984-3.455l9-5.25 c1.223-0.713,2.809-0.713,4.031,0l9,5.25C28.24,18.982,29,20.306,29,21.723V28C29,30.206,27.206,32,25,32z M16,14.473 c-0.354,0-0.702,0.094-1.008,0.272l-9,5.25C5.38,20.353,5,21.015,5,21.723V28c0,1.103,0.897,2,2,2h18c1.103,0,2-0.897,2-2v-6.277 c0-0.708-0.38-1.371-0.992-1.728l-9-5.25C16.702,14.567,16.354,14.473,16,14.473z"
      ></path>
      <ellipse fill={coloring(color2 || color)} cx="16" cy="8.5" rx="6.5" ry="7.5"></ellipse>
      <path
        fill={coloring(bg)}
        d="M16,17c-4.136,0-7.5-3.813-7.5-8.5S11.864,0,16,0s7.5,3.813,7.5,8.5S20.136,17,16,17z M16,2 c-3.033,0-5.5,2.916-5.5,6.5S12.967,15,16,15s5.5-2.916,5.5-6.5S19.033,2,16,2z"
      ></path>
    </svg>
  </Box>
)
