import { useMemo } from 'react'

import { useShouldDisplayExternalInvoicesSyncInitiateAction } from '@features/externalInvoicesSync/hooks/useShouldDisplayExternalInvoicesSyncInitiateAction'

import { Scope } from '../../../../../enums/scope'
import { useOrganizationSource } from '../../../../../hooks'
import { isAuthorized } from '../../../../../utils/isAuthorized'

export const useShouldDisplayInvoicingSettingsTab = () => {
  const { isSourceBilly, isSourceZervant } = useOrganizationSource()
  const { shouldDisplayExternalInvoicesSyncInitiateAction } = useShouldDisplayExternalInvoicesSyncInitiateAction()

  const shouldDisplayInvoicingSettingsTab = useMemo(() => {
    if (isSourceBilly) {
      return isAuthorized(Scope.InvoiceWrite)
    } else if (isSourceZervant) {
      return shouldDisplayExternalInvoicesSyncInitiateAction
    } else {
      return false
    }
  }, [isSourceBilly, isSourceZervant, shouldDisplayExternalInvoicesSyncInitiateAction])

  return { shouldDisplayInvoicingSettingsTab }
}
