import { BrandProtectedComponent, SettingsSection } from '@components'
import { SkeletonBox } from '@design-system'

import { ReactElement } from 'react'

import { OrganizationIconSection } from '../OrganizationIconSection/OrganizationIconSection'
import { OrganizationInfoSettingsContextProvider } from './contexts/organizationInfoSettingsContext'
import { BasicInfo } from './elements/BasicInfo'
import { ContactInfo } from './elements/ContactInfo'
import { OrganizationInfoFooter } from './elements/OrganizationInfoFooter'

export const OrganizationInfo = (): ReactElement => (
  <SettingsSection>
    <OrganizationInfoSettingsContextProvider>
      <SettingsSection.ColumnsContainer>
        <BasicInfo />
        <SettingsSection.RowsContainer>
          <BrandProtectedComponent brand="billy" loadingNode={<SkeletonBox fullWidth height={261} />}>
            <ContactInfo />
          </BrandProtectedComponent>
          <OrganizationIconSection />
        </SettingsSection.RowsContainer>
      </SettingsSection.ColumnsContainer>
      <OrganizationInfoFooter />
    </OrganizationInfoSettingsContextProvider>
  </SettingsSection>
)
