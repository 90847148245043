import { Translate } from '@components'
import { CellValue, InternalAccessor, Table, TableColumn, TableHoverActions } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TableIds } from '../../../../../../../../enums/tableIds'
import { usePaymentMethods } from '../../../../../../../../hooks/usePaymentMethods'
import { useOrganizationInvoiceSettings } from '../../../../contexts/organizationInvoiceSettingsContext'
import { usePaymentMethodsTableData } from '../../hooks/usePaymentMethodsTableData'
import { PaymentMethodName } from '../../types/paymentMethodName'
import { PaymentMethodsTableRow } from '../../types/paymentMethodsTableRow'
import { PaymentMethodsTableStaticActions } from './elements/PaymentMethodsTableStaticActions'
import { TablePaymentMethodName } from './elements/TablePaymentMethodName'

export const PaymentMethodsTable = (): ReactElement => {
  const { t } = useTranslation()
  const { isLoading, paymentMethods } = usePaymentMethods()
  const tableData = usePaymentMethodsTableData(paymentMethods)
  const { openEditPaymentMethodModal } = useOrganizationInvoiceSettings()

  const handleEditPaymentMethodButtonClick = useCallback(
    (paymentMethodId: string) => {
      openEditPaymentMethodModal?.(paymentMethodId)
    },
    [openEditPaymentMethodModal],
  )

  const columns: TableColumn<PaymentMethodsTableRow>[] = useMemo(
    () => [
      {
        accessor: 'name',
        Cell: ({ value: props }: CellValue<PaymentMethodName>) => <TablePaymentMethodName {...props} />,
        Header: <Translate value="settings.organization.invoice.payment_methods_settings.table.header.name" />,
      },

      {
        accessor: 'type',
        Header: <Translate value="settings.organization.invoice.payment_methods_settings.table.header.type" />,
      },
      {
        accessor: InternalAccessor.HoverActions,
        Cell: ({ row, data }) => (
          <TableHoverActions>
            <TableHoverActions.Item
              icon="paperWithPencil"
              label={t('settings.organization.invoice.payment_methods_settings.table.actions.edit')}
              onClick={() => {
                const { id } = data[row.id] as PaymentMethodsTableRow

                handleEditPaymentMethodButtonClick(id)
              }}
            />
          </TableHoverActions>
        ),
        Header: '',
      },
      {
        accessor: InternalAccessor.StaticActions,
        Cell: ({ row, data }) => {
          const { id } = data[row.id] as PaymentMethodsTableRow

          return <PaymentMethodsTableStaticActions paymentMethodId={id} />
        },
        Header: '',
      },
    ],
    [handleEditPaymentMethodButtonClick, t],
  )

  return (
    <Table
      columns={columns}
      data={tableData}
      id={TableIds.PaymentMethods}
      isLoading={isLoading}
      skeletonItemsPerPage={2}
      withColumnsFiltering={false}
    />
  )
}
