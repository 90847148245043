import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../utils'
import { deleteTaxAccount } from '../../../query-api'

interface UseDeleteTaxRateProps {
  onError?: (error?: APIError) => void
  onSettled?: (data: void, error: APIError | null | undefined) => void
  onSuccess?: (data: void) => void
}

export const useDeleteVatAccount = (props?: UseDeleteTaxRateProps) => {
  const { onError, onSuccess, onSettled } = props || {}
  const { mutate, ...rest } = useMutation(deleteTaxAccount, {
    onError,
    onSuccess,
    onSettled,
  })

  return { delete: mutate, ...rest }
}
