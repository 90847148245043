import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchUserUmbrellas } from './query-api'
import { UserUmbrella } from './types/userUmbrella'

interface ContextState {
  userUmbrellas: UserUmbrella[]
}

const UserUmbrellasContext = createContext<ContextState | undefined>(undefined)

interface UserUmbrellasContextProps {
  children?: ReactNode
}

export const UserUmbrellasContextProvider = ({ children }: UserUmbrellasContextProps): ReactElement => {
  const { data: userUmbrellas } = useQuery([QueryKeys.Umbrella], fetchUserUmbrellas)

  return (
    <UserUmbrellasContext.Provider value={{ userUmbrellas: userUmbrellas?.data || [] }}>
      {children}
    </UserUmbrellasContext.Provider>
  )
}

export const useUserUmbrellas = () => {
  const context = useContext(UserUmbrellasContext)

  if (!context) {
    throw new Error('UserUmbrellasContextProvider is missing in the module!')
  }

  return context
}
