import { FileButtonProps } from '@design-system'

import React, { ReactElement } from 'react'

import { WithTrackingContext } from '../../../types/withTrackingContext'
import { FileButtonPremium } from '../../PremiumComponents'
import { ProtectableProps, ProtectedComponent } from '../ProtectedComponent'

type FileButtonProtectedProps = ProtectableProps & FileButtonProps & WithTrackingContext

export const FileButtonProtected = ({
  scopes,
  scopesCheckDisabled,
  trackingContext,
  ...props
}: FileButtonProtectedProps): ReactElement => (
  <ProtectedComponent
    scopes={scopes}
    scopesCheckDisabled={scopesCheckDisabled}
    unauthorizedNode={<FileButtonPremium {...props} trackingContext={trackingContext} isBlocked />}
  >
    <FileButtonPremium trackingContext={trackingContext} {...props} />
  </ProtectedComponent>
)
