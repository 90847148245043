import { ChartOfAccountId } from '@modules-deprecated/app/organization/enums/chartOfAccountId'

export type CoaCode = 'billymeneto' | 'manual' | 'none'

export const getCoaCodeFromId = (type?: ChartOfAccountId): CoaCode => {
  if (!type) {
    return 'none'
  }

  if ([ChartOfAccountId.Billy, ChartOfAccountId.MenetoEmv, ChartOfAccountId.MenetoSelskab].indexOf(type) >= 0) {
    return 'billymeneto'
  }

  if (type === ChartOfAccountId.Manual) {
    return 'manual'
  }

  return 'none'
}
