import { amountToDisplayValue } from '@design-system'

import { useMemo } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ContactBalanceCategoryRange } from '../enums/contactBalanceCategoryRange'
import { ContactBalanceTableAccessor } from '../enums/contactBalanceTableAccessor'
import { ContactBalance } from '../types/contactBalance'
import { ContactBalanceTableRow } from '../types/contactBalanceTableRow'
import { getCategoryTotalAmountByRange } from '../utils/getCategoryTotalAmountByRange'

/* eslint-disable @typescript-eslint/naming-convention */
export const useContactBalanceTableData = (data: ContactBalance[]): ContactBalanceTableRow[] => {
  const { organization } = useUserOrganization()

  return useMemo(
    () =>
      data.map(({ category_totals, contact_id, contact_name, total }) => {
        return {
          [ContactBalanceTableAccessor.AverageRange]: getCategoryTotalAmountByRange(
            category_totals,
            ContactBalanceCategoryRange.AverageRange,
            organization?.baseCurrencyId,
          ),
          [ContactBalanceTableAccessor.ContactName]: contact_name,
          [ContactBalanceTableAccessor.LongRange]: getCategoryTotalAmountByRange(
            category_totals,
            ContactBalanceCategoryRange.LongRange,
            organization?.baseCurrencyId,
          ),
          [ContactBalanceTableAccessor.NotOverdue]: getCategoryTotalAmountByRange(
            category_totals,
            ContactBalanceCategoryRange.NotOverdue,
            organization?.baseCurrencyId,
          ),
          [ContactBalanceTableAccessor.ShortRange]: getCategoryTotalAmountByRange(
            category_totals,
            ContactBalanceCategoryRange.ShortRange,
            organization?.baseCurrencyId,
          ),
          [ContactBalanceTableAccessor.Total]:
            total !== undefined ? `${amountToDisplayValue(total)} ${organization?.baseCurrencyId || ''}` : undefined,
          id: contact_id,
        }
      }),
    [data, organization?.baseCurrencyId],
  )
}
/* eslint-enable @typescript-eslint/naming-convention */
