import { ReactElement } from 'react'

import { OrganizationFeature, useOrganizationFeature } from '../settings/routes/OrganizationFeaturesSettings'
import { AgerasFinanceLending } from './AgerasFinanceLending'
import { FrodaFinanceLending, FrodaFinanceLendingProviderContext } from './FrodaFinanceLending'

export const Financing = (): ReactElement => {
  const { hasFeature } = useOrganizationFeature()
  const hasFrodaLendingFeature = hasFeature(OrganizationFeature.FrodaLending)

  if (hasFrodaLendingFeature) {
    return (
      <FrodaFinanceLendingProviderContext>
        <FrodaFinanceLending />
      </FrodaFinanceLendingProviderContext>
    )
  }

  return <AgerasFinanceLending />
}
