import { ControlAlignmentPlacement } from '../types/controlAlignmentPlacement'

export const getControlAlignmentPlacement = (controlAlignment?: ControlAlignmentPlacement): string => {
  let alignment = 'unset'

  switch (controlAlignment) {
    case 'top':
      alignment = 'flex-start'
      break
    case 'center':
      alignment = 'center'
      break
    case 'bottom':
      alignment = 'flex-end'
      break
  }

  return `align-self: ${alignment};`
}
