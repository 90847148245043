import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'

export const SidePanelHeaderWrapper = styled.header`
  display: flex;
  padding: ${Spacing.L} ${Spacing.L} ${Spacing.M} ${Spacing.XXL};
  align-items: center;
`

export const CloseButtonWrapper = styled.div`
  margin-left: auto;
`
