import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { createBill, CreateBillPayload, CreateBillResponseData } from '../../../query-api'

interface UseCreateBillProps {
  onError?: (error?: APIError) => void
  onSettled?: (
    data: CreateBillResponseData | undefined,
    error: APIError | null | undefined,
    variables: CreateBillPayload,
    context: void | undefined,
  ) => void
  onSuccess?: (data: CreateBillResponseData, variables: CreateBillPayload) => void
}

export const useCreateBill = (props?: UseCreateBillProps) => {
  const { onError, onSuccess, onSettled } = props || {}
  const { mutate: create, ...rest } = useMutation(createBill, {
    onSuccess,
    onError,
    onSettled,
  })

  return { create, ...rest }
}
