import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { fetchTransactions, getTransactionsQueryKey, TransactionSortProperty } from '@views/transactions'

export const useInvoiceTransactions = (originatorReference?: string) => {
  const queryProps = useMemo(
    () => ({
      originatorReference,
      sortProperty: TransactionSortProperty.TransactionNo,
      withCreator: true,
      withVoided: true,
    }),
    [originatorReference],
  )

  const queryKey = useMemo(() => getTransactionsQueryKey(queryProps), [queryProps])

  const { data, isLoading } = useQuery(queryKey, () => fetchTransactions(queryProps), {
    enabled: !!originatorReference,
  })

  const { transactions } = data || {}

  return {
    transactions,
    isLoading,
  }
}
