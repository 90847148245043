import { StyledComponent } from '../../../types/styledComponent'
import * as Styled from '../styles'
import { Variant } from '../types/variant'

export const variantToComponent: Record<Variant, StyledComponent> = {
  h1: Styled.TextH1,
  h2: Styled.TextH2,
  h3: Styled.TextH3,
  h4: Styled.TextH4,
  displayM: Styled.DisplayM,
  displayS: Styled.DisplayS,
  displayXS: Styled.DisplayXS,
  large: Styled.TextLarge,
  normal: Styled.TextNormal,
  small: Styled.TextSmall,
  micro: Styled.TextMicro,
}
