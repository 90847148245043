/*
 *   This modal is the based on the CreateOrEditVatRateModal and uses some of its code
 */
import { Modal, ModalProps, withModalConditionalRender } from '@design-system'

import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { VatRateType } from '../shared'
import { CreateOrEditVatRateFromTemplateModalContextProvider } from './context/createOrEditVatRateFromTemplateModalContext'
import { CreateOrEditVatRateFromTemplateForm } from './elements/CreateOrEditVatRateFromTemplateForm'
import { CreateVatRateTemplateSelection } from './elements/CreateVatRateTemplateSelection'
import { MigrateVatRateTemplateSelection } from './elements/MigrateVatRateTemplateSelection'
import { ModalContent } from './elements/ModalContent'
import { ModalFooter } from './elements/ModalFooter'

interface CreateOrEditVatRateFromTemplateModalProps extends ModalProps {
  vatRateId?: string
  disabled?: boolean
  appliesTo?: VatRateType
}

export const CreateOrEditVatRateFromTemplateModal = withModalConditionalRender(
  ({
    vatRateId,
    disabled,
    appliesTo = VatRateType.Sales,
    ...modalProps
  }: CreateOrEditVatRateFromTemplateModalProps): ReactElement => {
    const { t } = useTranslation()
    const modalBodyRef = useRef<HTMLDivElement>(null)
    const [scrollContainerElement, setScrollContainerElement] = useState<HTMLDivElement>()
    const isEditMode = !!vatRateId

    // Bug workaround
    // https://github.com/atlassian/react-beautiful-dnd/issues/1881
    // Ref to scrollable container (modal body) is needed to calculate proper position of dragged element
    // requestAnimationFrame is needed to wait until element inside of ref is rendered
    useEffect(() => {
      const frameId = requestAnimationFrame(() => {
        if (modalBodyRef.current) {
          setScrollContainerElement(modalBodyRef.current)
        }
      })

      return () => {
        cancelAnimationFrame(frameId)
        setScrollContainerElement(undefined)
      }
    }, [])

    return (
      <CreateOrEditVatRateFromTemplateModalContextProvider
        scrollContainerElement={scrollContainerElement}
        modalId={modalProps.id}
        vatRateId={vatRateId}
        disabled={disabled}
        appliesTo={appliesTo}
      >
        <Modal {...modalProps} closable autofocusOnInput>
          <Modal.Header
            title={t(
              isEditMode
                ? 'settings.organization.vat_rates.modals.edit_vat_rate.title'
                : 'settings.organization.vat_rates.modals.create_vat_rate.title',
            )}
          />
          <Modal.Body ref={modalBodyRef}>
            <ModalContent>
              {isEditMode ? <MigrateVatRateTemplateSelection /> : <CreateVatRateTemplateSelection />}
              <CreateOrEditVatRateFromTemplateForm />
            </ModalContent>
          </Modal.Body>
          <Modal.Footer>
            <ModalFooter />
          </Modal.Footer>
        </Modal>
      </CreateOrEditVatRateFromTemplateModalContextProvider>
    )
  },
)
