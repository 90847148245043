import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'

export const availableValues = [25, 50, 100] as const

export type ItemsPerPage = (typeof availableValues)[number]

export const DEFAULT_ITEMS_PER_PAGE: ItemsPerPage = 50

type UseItemsPerPageResponse = [ItemsPerPage, (itemsPerPage: ItemsPerPage) => void]

// defaultItemsPerPage: ItemsPerPage = DEFAULT_ITEMS_PER_PAGE,

export const useItemsPerPage = (
  defaultItemsPerPage: ItemsPerPage = DEFAULT_ITEMS_PER_PAGE,
): UseItemsPerPageResponse => {
  const [itemsPerPage, setItemsPerPage] = useLocalStorage<ItemsPerPage>('ds-items-per-page', defaultItemsPerPage)

  // Check is saved value is correct. If not then change it to default one.
  useEffect(() => {
    if (itemsPerPage && !availableValues.includes(itemsPerPage)) {
      setItemsPerPage(defaultItemsPerPage)
    }
    // Should be only checked on the `itemsPerPage` value update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, setItemsPerPage])

  return [itemsPerPage as ItemsPerPage, setItemsPerPage]
}
