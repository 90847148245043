import { DateInput, Divider, FormItem, FormItemsGroup, InputNumber, Space, Text } from '@design-system'

import { format, isAfter } from 'date-fns'
import { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment } from '../../../../../../hooks'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useManualPayment } from '../../contexts/manualPaymentContext'
import { useMultipleBills } from '../../contexts/multipleBillsContext'
import { BillAccountSelector } from '../BillAccountSelector'
import { BillSummary } from '../BillSummary'
import { DifferenceSelector } from '../DifferenceSelector'

export const ManualPaymentModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { currencyId, paymentDate, setPaymentDate } = useBillReconciliation()
  const { selectedBills } = useMultipleBills()
  const { hasManualPaymentDifference, manualPaymentAmount, manualPaymentDifference, setManualPaymentAmount } =
    useManualPayment()

  const newestBillDate = useMemo(() => {
    const sortedBills = selectedBills.toSorted((billA, billB) => {
      if (billA?.entryDate && billB?.entryDate) {
        const billAEntryDate = new Date(billA?.entryDate)
        const billBEntryDate = new Date(billB?.entryDate)

        return isAfter(billAEntryDate, billBEntryDate) ? -1 : 1
      }
      return 1
    })

    return sortedBills[0]?.entryDate
  }, [selectedBills])

  const minDate = newestBillDate ? new Date(newestBillDate) : new Date()

  const handlePaymentDateChange = useCallback(
    (date?: Date) => {
      if (date) {
        setPaymentDate(format(date, 'yyyy-MM-dd'))
      }
    },
    [setPaymentDate],
  )

  const handlePaymentAmountChange = useCallback(
    (amount?: number) => {
      if (amount) {
        setManualPaymentAmount(amount)
      }
    },
    [setManualPaymentAmount],
  )

  useEffect(() => {
    track('xxx - expenses - Manual payment modal opened')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPaymentDate(format(new Date(), 'yyyy-MM-dd'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <BillSummary />
      <Space />
      <Divider />
      <Space />
      <BillAccountSelector />
      <Space />
      <FormItemsGroup itemsInRow={4}>
        <FormItem label={t('bill.payment_reconcile.payment_date')}>
          <DateInput
            minDate={minDate}
            onChange={handlePaymentDateChange}
            value={paymentDate ? new Date(paymentDate) : undefined}
          />
        </FormItem>
        <FormItem itemColumnEnd={5} itemColumnStart={2} label={t('amount')}>
          <InputNumber
            value={manualPaymentAmount}
            onChange={handlePaymentAmountChange}
            suffix={currencyId && <Text colorVariant="secondary">{currencyId}</Text>}
          />
        </FormItem>
      </FormItemsGroup>
      {hasManualPaymentDifference && <DifferenceSelector difference={manualPaymentDifference} />}
    </>
  )
}
