import React, { memo, MouseEvent, ReactElement, ReactNode, useCallback, useRef } from 'react'

import { Icon } from '../Icon'
import { Text } from '../Text'
import * as Styled from './styles'
import { Variant } from './types/variant'
import { variantStyles } from './utils/variantStyles'

export type TagVariant = Variant

export interface TagProps {
  children: ReactNode
  clickable?: boolean
  closable?: boolean
  onClick?: (event: MouseEvent) => void
  onClose?: (event: MouseEvent) => void
  variant?: TagVariant
}

export const Tag = memo(
  ({
    children,
    clickable = false,
    closable = true,
    onClick,
    onClose,
    variant = 'primary',
    ...rest
  }: TagProps): ReactElement => {
    const closeWrapperRef = useRef<HTMLDivElement>(null)

    const handleClick = useCallback(
      (event: MouseEvent) => {
        const elementClicked = event.target as HTMLElement | undefined
        const isCloseClicked =
          (elementClicked && closeWrapperRef.current?.contains(elementClicked)) ||
          elementClicked === closeWrapperRef.current

        if (clickable && !isCloseClicked) {
          onClick?.(event)
        }
      },
      [onClick, clickable],
    )

    const handleCloseClick = useCallback(
      (event: MouseEvent) => {
        if (closable) {
          onClose?.(event)
        }
      },
      [closable, onClose],
    )

    return (
      <Styled.TagWrapper
        variant={variant}
        clickable={clickable}
        onClick={handleClick}
        data-testid="tag-wrapper"
        {...rest}
      >
        <Text variant="small" weight="medium" color={variantStyles[variant].color}>
          {children}
        </Text>

        {closable && (
          <Styled.CloseIconWrapper ref={closeWrapperRef} onClick={handleCloseClick}>
            <Icon icon="xSignCircle" size="small" color={variantStyles[variant].color} />
          </Styled.CloseIconWrapper>
        )}
      </Styled.TagWrapper>
    )
  },
)
