import { css, SerializedStyles } from '@emotion/core'
import { transparentize } from 'polished'

import { Color } from '../../../enums/color'
import { FontWeight } from '../../../enums/fontWeight'
import { getShadow } from '../../../utils/getShadow'
import { Variant } from '../types'

export const getVariantStyle = (variant: Variant): SerializedStyles =>
  ({
    primary: css`
      background-color: ${Color.DeepSeaGreen};
      color: ${Color.ElectricGreen};
      ${getShadow('raisedLight')};

      &:not(:disabled) {
        &:hover {
          background-color: ${Color.Green140};
          color: ${Color.White};
          ${getShadow('raisedLight')};
        }
      }
    `,
    secondary: css`
      background-color: ${Color.White};
      color: ${Color.DeepSeaGreen};
      ${getShadow('raisedLight', { borderColor: Color.Gray50 })};

      &:not(:disabled) {
        &:hover {
          background-color: ${Color.Gray30};
          color: ${Color.DeepSeaGreen};
          ${getShadow('raisedLight')};
        }
      }
    `,
    text: css`
      font-weight: ${FontWeight.Regular};
      background-color: transparent;
      color: ${Color.Gray90};

      &:not(:disabled) {
        &:hover {
          background-color: ${transparentize(0.88, Color.Gray60)};
        }
      }
    `,
  })[variant]
