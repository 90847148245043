import { amountToDisplayValue } from '@design-system'

import { TFunction } from 'i18next'

export const getPlanPrice = (price: string, period: string, t: TFunction) => {
  let periodTranslation = ''

  if (period === 'monthly') {
    periodTranslation = t('month.abbreviation')
  } else if (period === 'yearly') {
    periodTranslation = t('year.abbreviation')
  }

  return `${amountToDisplayValue(price, true)} DKK/${periodTranslation}`
}
