import React, { createContext, ReactElement, ReactNode, useContext } from 'react'

import { InvoiceDocumentType } from '../../../enums/invoiceDocumentType'
import { Invoice } from '../../../types/invoice'
import { useFetchInvoice } from '../hooks/useFetchInvoice'

interface ContextState {
  documentType?: InvoiceDocumentType
  invoice?: Invoice
  invoiceId?: string
  isLoading: boolean
}

const InvoiceContext = createContext<ContextState | undefined>(undefined)

interface InvoiceContextProps {
  children?: ReactNode
  documentType?: InvoiceDocumentType
  invoiceId?: string
}

export const InvoiceContextProvider = ({ invoiceId, documentType, children }: InvoiceContextProps): ReactElement => {
  const { data: invoiceData, isLoading } = useFetchInvoice(invoiceId, documentType)

  return (
    <InvoiceContext.Provider value={{ invoice: invoiceData?.data, documentType, invoiceId, isLoading }}>
      {children}
    </InvoiceContext.Provider>
  )
}

export const useInvoice = () => {
  const context = useContext(InvoiceContext)

  if (!context) {
    throw new Error('InvoiceContextProvider is missing in the module!')
  }

  return context
}
