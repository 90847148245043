import styled from '@emotion/styled'

export const DashboardActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-right: auto;
  justify-content: space-between;
`

export const GlobalPeriodSelectWrapper = styled.div`
  margin-left: auto;
`
