export const commonTheme = {
  data: {
    menu: {
      help: {
        faqLink: 'https://www.billy.dk/support/',
        email: 'billy@billy.dk',
        phone: '60 24 60 24',
      },
    },
  },
}
