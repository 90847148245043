import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { deleteActor } from '../query-api'

interface UseDeleteActorProps {
  onError?: (error?: APIError) => void
  onSuccess?: (data: void) => void
}

export const useDeleteActor = (props?: UseDeleteActorProps) => {
  const { onError, onSuccess } = props || {}
  const { mutate, ...rest } = useMutation(deleteActor, { onSuccess, onError })

  return { mutate, ...rest }
}
