import { TableActionsWrapper } from '@components'

import React, { ReactElement } from 'react'

import { AccountStatementsPeriodSelector } from '../AccountStatementsPeriodSelector'
import { AccountStatementsTableSearch } from '../AccountStatementsTableSearch'
import { AccountStatementsTableTitle } from '../AccountStatementsTableTitle'
import { AccountStatementsVoidedFilter } from '../AccountStatementsVoidedFilter'

export const AccountStatementsTableActions = (): ReactElement => {
  return (
    <TableActionsWrapper>
      <AccountStatementsTableTitle />
      <AccountStatementsTableSearch />
      <AccountStatementsVoidedFilter />
      <AccountStatementsPeriodSelector />
    </TableActionsWrapper>
  )
}
