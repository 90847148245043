import { useMutation } from 'react-query'

import { APIError } from '../../../utils'
import { putCancelSendingEInvoice } from '../query-api'

interface UseCancelSendingEInvoiceProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

interface PostCancelSendingEInvoiceProps {
  deliveryId?: string
}

export const useCancelSendingEInvoice = (props?: UseCancelSendingEInvoiceProps) => {
  const { onError, onSuccess } = props || {}

  const {
    mutate: cancelSendingEInvoice,
    isLoading: isProcessing,
    ...rest
  } = useMutation(
    ({ deliveryId }: PostCancelSendingEInvoiceProps) => {
      if (!deliveryId) {
        throw new Error('No deliveryId specified for eInvoice')
      }
      return putCancelSendingEInvoice(deliveryId)
    },
    {
      onError,
      onSuccess,
    },
  )

  return { isProcessing, cancelSendingEInvoice, ...rest }
}
