import { darken } from 'polished'

import { Color } from '../enums/color'
import { Theme } from '../types/theme'
import { commonTheme } from './_commonTheme'

export const darkTheme: Theme = {
  ds: {
    colors: {
      base: {
        background: darken(0.03, Color.Gray20) as Color,
        textPrimary: Color.Black,
        textSecondary: Color.Black,
        icon: Color.Black,
      },
      pricing: {
        accent: Color.Black,
      },
    },
    ...commonTheme,
  },
}
