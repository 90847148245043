import { ItemsPerPage, useItemsPerPage } from '@design-system'

import { useMemo } from 'react'

import { PeriodMode } from '../../../../../enums/periodMode'
import { SortDirection } from '../../../../../enums/sortDirection'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { QuoteState } from '../../../enums/quoteState'
import { QuotesSortProperty } from '../../../query-api'

interface QuotesListFilters {
  page?: number
  pageSize?: ItemsPerPage
  period?: string
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: QuotesSortProperty
  state: QuoteState
}

type UseQuotesFiltersResponse = [QuotesListFilters, (filters: Partial<QuotesListFilters>) => void]

export const defaultQuery: QuotesListFilters = {
  page: 1,
  pageSize: undefined,
  period: PeriodMode.All,
  searchQuery: undefined,
  sortDirection: SortDirection.Desc,
  sortProperty: QuotesSortProperty.EntryDate,
  state: QuoteState.Open,
}

export const useQuotesListFilters = (): UseQuotesFiltersResponse => {
  const [pageSize] = useItemsPerPage()
  const [queryParams, setQueryParams] = useQueryParams<QuotesListFilters>({
    defaultQuery: {
      ...defaultQuery,
      pageSize,
    },
  })

  return useMemo(
    () => [
      {
        ...queryParams,
        // Sometimes `pageSize` comes as string. It needs to be always `number` type.
        pageSize: Number(queryParams.pageSize) as ItemsPerPage,
      },
      setQueryParams,
    ],
    [queryParams, setQueryParams],
  )
}
