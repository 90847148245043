export const ACTIVATE_TRANSACTION_DROPPABLE_AREA = 'ACTIVATE_TRANSACTION_DROPPABLE_AREA'
export const BANK_CONNECTION_RECEIVED = 'BANK_CONNECTION_RECEIVED'
export const BANK_CONNECTION_REQUEST_FAILED = 'BANK_CONNECTION_REQUEST_FAILED'
export const BANK_CONNECTION_REQUESTED = 'BANK_CONNECTION_REQUESTED'
export const BANK_LINE_MATCHES_DELETED = 'BANK_LINE_MATCHES_DELETED'
export const BANK_LINE_MATCH_REMOVED = 'BANK_LINE_MATCH_REMOVED'
export const BANK_LINE_MATCH_UPDATED = 'BANK_LINE_MATCH_UPDATED'
export const BANK_LINES_LOADING = 'BANK_LINES_LOADING'
export const BANK_LINE_SUBJECT_ASSOCIATION_CREATED = 'BANK_LINE_SUBJECT_ASSOCIATION_CREATED'
export const BANK_LINE_SUBJECT_ASSOCIATION_DELETED = 'BANK_LINE_SUBJECT_ASSOCIATION_DELETED'
export const BANK_LINE_SUBJECT_ASSOCIATION_DELETE_REQUESTED = 'BANK_LINE_SUBJECT_ASSOCIATION_DELETE_REQUESTED'
export const BANK_LINE_SUBJECT_ASSOCIATION_UPDATED = 'BANK_LINE_SUBJECT_ASSOCIATION_UPDATED'
export const BANK_LINE_UNGROUPED = 'BANK_LINE_UNGROUPED'
export const BANK_LINES_AND_TRANSACTIONS_RECEIVED = 'BANK_LINES_AND_TRANSACTIONS_RECEIVED'
export const BANK_LINES_AND_TRANSACTIONS_REQUESTED = 'BANK_LINES_AND_TRANSACTIONS_REQUESTED'
export const BANK_LINES_LATEST_LINE_REQUESTED = 'BANK_LINES_LATEST_LINE_REQUESTED'
export const BANK_LINES_LATEST_LINE_RECEIVED = 'BANK_LINES_LATEST_LINE_RECEIVED'
export const BILLY_TRANSACTION_ADDED = 'BILLY_TRANSACTION_ADDED'
export const CREATE_BILL_AND_RECONCILE = 'CREATE_BILL_AND_RECONCILE'
export const CREATE_INVOICE_AND_RECONCILE = 'CREATE_INVOICE_AND_RECONCILE'
export const CREATE_PLACEHOLDER = 'CREATE_PLACEHOLDER'
export const DESTROY_PLACEHOLDERS = 'DESTROY_PLACEHOLDERS'
export const DIFFERENCE_TYPE_SELECTED = 'DIFFERENCE_TYPE_SELECTED'
export const DND_BANK_LINES = 'DND_BANK_LINES'
export const DND_BILLY_TRANSACTIONS = 'DND_BILLY_TRANSACTIONS'
export const FILTERS_CHANGED = 'FILTERS_CHANGED'
export const SELECT_DIFFERENCE_TYPE = 'SELECT_DIFFERENCE_TYPE'
export const TRANSACTIONS_LOADING = 'TRANSACTIONS_LOADING'
export const UNGROUP_BANK_LINE = 'UNGROUP_BANK_LINE'
// Reconcile, all, and bulk
export const BULK_RECONCILE = 'BULK_RECONCILE'
export const BULK_RECONCILE_STATE_UPDATED = 'BULK_RECONCILE_STATE_UPDATED'
export const RECONCILE_ALL = 'RECONCILE_ALL'
export const RECONCILE_ALL_APPROVED_STATE_UPDATED = 'RECONCILE_ALL_APPROVED_STATE_UPDATED'
export const RECONCILE_MATCH = 'RECONCILE_MATCH'
export const RESET_BULK_RECONCILE_STATE = 'RESET_BULK_RECONCILE_STATE'
export const UNRECONCILE_MATCH = 'UNRECONCILE_MATCH'
// Independent scrolling for bank lines & transactions
export const BANK_BALANCE_RECEIVED = 'BANK_BALANCE_RECEIVED'
export const BANK_BALANCE_REQUESTED = 'BANK_BALANCE_REQUESTED'
export const SET_BALANCE_BAR_OPEN = 'SET_BALANCE_BAR_OPEN'
export const DELETE_BANK_LINES = 'DELETE_BANK_LINES'
