import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { Themable } from '../../types/themable'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { InputWrapperProps } from './types/inputWrapperProps'
import { getInputStyle } from './utils/getInputStyle'
import { getInputWrapperStyle } from './utils/getInputWrapperStyle'

export const InputWrapper = styled.div<InputWrapperProps>`
  ${(props) => getInputWrapperStyle(props, Input)};

  ${({ clearable }) =>
    clearable &&
    css`
      &:hover {
        ${ClearInputWrapper} {
          opacity: 1;
        }
      }
    `}
`

export const ClearInputWrapper = styled.div`
  opacity: 0;
  ${getDefaultTransition('opacity')};
`

export interface InputProps extends Themable {
  color?: Color
}

export const Input = styled.input<InputProps>`
  ${({ theme, color }) => getInputStyle(theme, color)};
`
