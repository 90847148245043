import { Color } from '@design-system'

import { isPast, isToday } from 'date-fns'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { DateFormatter } from '../DateFormatter'

interface DueDateFormatterProps {
  dateFormat?: string
  value: string | Date
}

export const DueDateFormatter = ({ dateFormat, value }: DueDateFormatterProps): ReactElement => {
  const { t } = useTranslation()

  const dueDate = new Date(value)
  const isPastDue = isPast(dueDate)
  const isDueDateToday = isToday(dueDate)

  return (
    <DateFormatter dateFormat={dateFormat} value={value} color={isPastDue ? Color.Red : undefined}>
      {isDueDateToday ? t('today') : undefined}
    </DateFormatter>
  )
}
