import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ReportsTableActionsWrapper = styled.div`
  display: flex;
`

export const LeftActions = styled.div`
  margin-right: ${Spacing.L};
`

export const RightActions = styled.div`
  margin-left: auto;
  align-self: flex-end;
`
