import { Text } from '@components-deprecated'
import { amountToDisplayValue } from '@design-system'

import styled from '@emotion/styled'
import uniqueId from 'lodash/uniqueId'
import React, { ElementType, ReactNode, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Contact } from '@views/contacts/types/contact'

import { Themable } from '../../../../types/themable'
import { formatDate } from '../../../../utils/date-fns'
import { Bill } from '../../types'

type WarningInfoProps = Themable

const WarningInfo = styled.span<WarningInfoProps>`
  display: block;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.colors.badges.warning.bg};
`

type BadgeProps = Themable

const Badge = styled.span<BadgeProps>`
  display: inline-flex;
  height: 27px;
  padding: 7px;
  border-radius: 4px;
  font-size: 9px;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.heading};
  letter-spacing: 0.05em;
  text-transform: uppercase;
`

const BadgeHighlighted = styled(Badge)<BadgeProps>`
  background-color: ${({ theme }) => theme.colors.badges.default.bg};
  color: ${({ theme }) => theme.colors.badges.default.text};
`

interface ListItemProps extends Themable {
  highlighted?: boolean
  hoverable?: boolean
}

const ListItem = styled.li<ListItemProps>`
  opacity: ${({ hoverable, highlighted }) => (hoverable && !highlighted ? 0.7 : 1)};
  display: grid;
  margin-bottom: 2px;
  min-height: 45px;
  border-radius: 4px;
  padding: 7px 10px;
  position: relative;
  align-items: center;
  grid-template-columns: 1.25fr repeat(4, 1fr);
  column-gap: 10px;
  box-shadow: ${({ highlighted }) => (highlighted ? '0 4px 30px rgba(0, 0, 0, 0.05)' : 'none')};
  cursor: ${({ hoverable }) => (hoverable ? 'pointer' : 'auto')};

  &:first-of-type {
    margin-bottom: 10px;
  }
  &:last-of-type {
    margin-bottom: 30px;
  }
  ${({ hoverable, theme }) =>
    hoverable &&
    `
      &:hover {
        background-color: ${theme.colors.gray1};
      }
  `}
`

const Link = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const ListWrapper = styled.ul`
  overflow: auto;
  margin-bottom: -30px;
`

interface TableValueProps extends Themable {
  as?: ElementType
  isTitle?: boolean
}

const TableValue = styled.p<TableValueProps>`
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1em;

  ${({ isTitle, theme }) =>
    isTitle &&
    `
      color: ${theme.colors.table.header};
      font-weight: ${theme.fontWeights.bold};
    `};
`

export interface CurrentVoucherData {
  contactId: string
  date: Date
  description: string
  grossAmount: number
  currencyId: string
}

interface ListItemData {
  id: string
  badge?: ReactNode
  date: string
  vendor: string
  description: string
  amount: string
  href?: string
  isHighlighted?: boolean
}

interface DuplicationModalBodyProps {
  current: CurrentVoucherData
  duplicates: Bill[]
  organizationUrl: string
}

const renderValues =
  (isTitle?: boolean) =>
  (...children: ReactNode[]) =>
    children.map((child) => (
      <TableValue
        as={isTitle ? 'h3' : 'p'}
        isTitle={isTitle}
        key={`${typeof child === 'string' ? child : uniqueId()}`}
        title={typeof child === 'string' ? child : ''}
      >
        {child}
      </TableValue>
    ))

const getDate = (date?: Date | string | null) => formatDate(date, 'MMM. d yyyy')

export const DuplicationModalBody = ({ current, duplicates, organizationUrl }: DuplicationModalBodyProps) => {
  const { t } = useTranslation()
  const contacts: Contact[] = useSelector((state: any) => state.app.contacts)

  const getContactName = useCallback(
    (id: string) => contacts.find((contact) => contact.id === id)?.name || '',
    [contacts],
  )

  const billsData: ListItemData[] = useMemo(
    () => [
      {
        id: 'title',
        date: t('date'),
        vendor: t('vendor'),
        description: t('description'),
        amount: t('incl_vat'),
      },
      {
        id: 'current',
        badge: <BadgeHighlighted>{t('doublet_checker_window.bill.new')}</BadgeHighlighted>,
        date: getDate(current.date) || '',
        vendor: getContactName(current.contactId),
        description: current.description,
        amount: `${amountToDisplayValue(current.grossAmount)} ${current.currencyId}`,
        isHighlighted: true,
      },
      ...duplicates.map((duplicate) => ({
        id: duplicate.id,
        badge: <Badge>{t('doublet_checker_window.bill.existing')}</Badge>,
        date: getDate(duplicate.entryDate) || '',
        vendor: getContactName(duplicate.contactId || ''),
        description: duplicate.lineDescription,
        amount: `${amountToDisplayValue(duplicate.grossAmount as number)} ${duplicate.currencyId}`,
        href: `${window.location.origin}/${organizationUrl}/bills/${duplicate.id}`,
      })),
    ],
    [
      current.contactId,
      current.currencyId,
      current.date,
      current.description,
      current.grossAmount,
      duplicates,
      getContactName,
      organizationUrl,
      t,
    ],
  )

  return (
    <>
      <WarningInfo>
        <Text>{t('doublet_checker_window.bill.created', { count: duplicates.length })}</Text>
      </WarningInfo>
      <ListWrapper>
        {billsData.map(({ id, isHighlighted, description, date, amount, href, badge, vendor }, index) => (
          <ListItem key={id} highlighted={isHighlighted} hoverable={!!href}>
            {href && <Link href={href} target="_blank" />}
            {renderValues(index === 0)(badge, date, vendor, description, amount)}
          </ListItem>
        ))}
      </ListWrapper>
    </>
  )
}
