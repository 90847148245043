import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

export interface PurchaseCardsGroupProps {
  children: ReactNode
}

export const PurchaseCardsGroup = ({ children }: PurchaseCardsGroupProps): ReactElement => (
  <Styled.PurchaseCardsGroup>{children}</Styled.PurchaseCardsGroup>
)
