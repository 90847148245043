/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use 'CurrencySelect' from /components instead
 */
import { SmartSelect, SmartSelectProps } from '@components-deprecated'

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../enums/queryKeys'
import { getItemsForSelect } from '../../../../utils/forms'
import { fetchCurrencies } from '../query-api'
import { Currency } from '../types'

export type CurrencySelectorProps = SmartSelectProps<Currency> & {
  currency?: string
}

export const CurrencySelector = ({
  currency,
  name = 'currency_selector',
  listWidth,
  ...rest
}: CurrencySelectorProps) => {
  const { t } = useTranslation()
  const { data } = useQuery([QueryKeys.Currencies], fetchCurrencies)

  const currencies = data?.currencies || []
  const items = useMemo(() => getItemsForSelect<Currency>(currencies, 'id', 'name'), [currencies])

  return (
    <SmartSelect<Currency>
      items={items}
      name={name}
      listWidth={listWidth || 238}
      notFoundText={t('app.currencies.currency_selector.not_found')}
      placeholder={t('app.currencies.currency_selector.select_currency')}
      selectFirst
      {...rest}
    />
  )
}
