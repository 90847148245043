import { withModalConditionalRender } from '@design-system'

import { ReactElement } from 'react'

import { ModalId } from '../../../../../enums/modalId'
import { FrodaFailedModal as FrodaFailed } from '../FrodaFailedModal'
import { FrodaLendingEligibilityModal as FrodaLendingEligibility } from '../FrodaLendingEligibilityModal'
import { FrodaProcessingModal as FrodaProcessing } from '../FrodaProcessingModal'

const FrodaLendingEligibilityModal = withModalConditionalRender(FrodaLendingEligibility)
const FrodaFailedModal = withModalConditionalRender(FrodaFailed)
const FrodaProcessingModal = withModalConditionalRender(FrodaProcessing)

export const FrodaFinanceLendingModalsGroup = (): ReactElement => {
  return (
    <>
      <FrodaLendingEligibilityModal id={ModalId.FrodaLendingEligibilityModal} />
      <FrodaFailedModal id={ModalId.FrodaFailedModal} />
      <FrodaProcessingModal id={ModalId.FrodaProcessingModal} />
    </>
  )
}
