import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { Spacing } from '../../../../enums/spacing'
import { Asable } from '../../../../types/asable'

type SeparatorProps = Asable

export const Separator = styled.li<SeparatorProps>`
  display: block;
  height: 1px;
  margin: ${Spacing.S} 0;
  border: 0;
  background-color: ${Color.Gray30};
`
