import { Widget } from '../types/widget'

export const parseWidgetsList = (widgetsValue: string): Widget[] => {
  try {
    return JSON.parse(widgetsValue)
  } catch (error) {
    console.error(error)
    return []
  }
}
