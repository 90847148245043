import { FontWeight } from '@design-system'

import colors from './colors'

const commonStyles = {
  color: colors.blueA,
  fontSize: 14,
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  '&:disabled:hover, &.disabled:hover': {
    textDecoration: 'none',
    cursor: 'default',
  },
}

export default {
  action: {
    ...commonStyles,
    fontWeight: FontWeight.Medium,
  },
  link: {
    ...commonStyles,
    fontSize: 'inherit',
  },
  navigation: {
    ...commonStyles,
  },
}
