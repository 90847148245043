import { NavItem, NavListItemWithInlineDescription, NavListItemWithInlineDescriptionValue, Text } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SortDirection } from '../../../../enums/sortDirection'
import { ReceiptsSortProperty } from '../../enums/sort'
import { useReceiptsFilters } from '../../hooks/useReceiptsFilters'
import * as Styled from './styles'
import { getDisplayTitle } from './utils/getDisplayTitle'
import { getSortFilterId } from './utils/getSortFilterId'
import { getSortingItems } from './utils/getSortingItems'

export interface ReceiptsSortFilterDropdownValue extends NavListItemWithInlineDescriptionValue {
  direction: SortDirection
  property: ReceiptsSortProperty
}

export const ReceiptsSorting = (): ReactElement => {
  const { t } = useTranslation()
  const [{ sortBy, sortDirection }, setFilters] = useReceiptsFilters()

  const items: NavItem<ReceiptsSortFilterDropdownValue>[] = useMemo(() => getSortingItems(t), [t])

  const handleSelect = useCallback(
    (_id: string, value: ReceiptsSortFilterDropdownValue) => {
      const { direction, property } = value

      setFilters({ page: 1, sortBy: property, sortDirection: direction })
    },
    [setFilters],
  )

  return (
    <Styled.ReceiptsSortingWrapper>
      <Text colorVariant="secondary" nowrap>
        {t('receipts.sorting.title')}
      </Text>
      <Styled.ButtonDropdownWrapper
        dropdownSize="fitTrigger"
        dropdownItemRender={(props) => <NavListItemWithInlineDescription {...props} />}
        getDisplayValue={getDisplayTitle}
        items={items}
        onSelect={handleSelect}
        selectedId={getSortFilterId(sortBy, sortDirection)}
        variant="secondary"
      />
    </Styled.ReceiptsSortingWrapper>
  )
}
