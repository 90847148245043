import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: ${Spacing.S};
  margin-bottom: -${Spacing.S};
`
