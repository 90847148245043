import { DropzoneFullScreen } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { DropzoneFullScreenPremium, DropzoneFullScreenPremiumProps } from '../../PremiumComponents'
import { ProtectableProps, ProtectedComponent } from '../ProtectedComponent'

type DropzoneFullScreenProtectedProps = ProtectableProps & DropzoneFullScreenPremiumProps

export const DropzoneFullScreenProtected = ({
  scopes,
  scopesCheckDisabled,
  ...props
}: DropzoneFullScreenProtectedProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <ProtectedComponent
      scopes={scopes}
      scopesCheckDisabled={scopesCheckDisabled}
      unauthorizedNode={<DropzoneFullScreenPremium blockedText={t('premium_feature.warning')} {...props} isBlocked />}
    >
      <DropzoneFullScreen {...props} />
    </ProtectedComponent>
  )
}
