import { notify } from '@design-system'

import { useTranslation } from 'react-i18next'
import { UseMutateFunction, useMutation } from 'react-query'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { updateUmbrella, UpdateUmbrellaData } from '@modules-deprecated/app/umbrellas/query-api'

import { queryClient } from '../../../../../config/queryClient'
import { useDirtyRoute } from '../../../../../contexts/dirtyRouteContext'
import { CustomEvent } from '../../../../../enums/customEvent'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { dispatchCustomEvent } from '../../../../../utils/customEvent'

interface UseUpdateProfileResponse {
  update: UseMutateFunction<void, unknown, UpdateUmbrellaData>
  isSubmitting: boolean
}

export const useUpdateProfile = (): UseUpdateProfileResponse => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { setDirty } = useDirtyRoute()
  const { mutate: update, isLoading } = useMutation(
    (data: UpdateUmbrellaData) => updateUmbrella(umbrella?.id || '', data),
    {
      onError: () => {
        notify({
          id: NotificationKeys.UmbrellaProfileForm,
          message: t('umbrella.admin_profile.submit.error'),
          variant: 'error',
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries(QueryKeys.Umbrella)
      },
      onSuccess: () => {
        notify({
          id: NotificationKeys.UmbrellaProfileForm,
          message: t('umbrella.admin_profile.submit.success'),
          variant: 'success',
        })
        setDirty(false)
        dispatchCustomEvent(CustomEvent.AdminProfileUpdated)
      },
    },
  )

  return { isSubmitting: isLoading, update }
}
