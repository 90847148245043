import React, { forwardRef, LegacyRef, ReactElement, ReactNode, useCallback } from 'react'

import { useDraggingDetection } from '../../../../hooks'
import { GridItemProps } from '../../../../types/gridItemProps'
import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { WidgetId } from '../../enums/widgetId'
import * as Styled from './styles'

interface DraggableWidgetProps extends GridItemProps {
  children: ReactNode
  id: WidgetId
  isDraggable?: boolean
  isHidden?: boolean
  isLoading?: boolean
  isStatic?: boolean
  onToggleVisibility: (id: WidgetId, isHidden: boolean) => void
}

export const DraggableWidget = forwardRef(
  (
    {
      className = '',
      children,
      id,
      isDraggable,
      isHidden,
      isLoading,
      isStatic,
      onToggleVisibility,
      ...rest
    }: DraggableWidgetProps,
    forwardedRef: LegacyRef<HTMLDivElement>,
  ): ReactElement => {
    const { isEditMode } = useDashboardSettings()
    const isDragging = useDraggingDetection(className)

    const isAnimating = !isDragging && isEditMode && !isHidden && !isStatic

    const handleClick = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation()
        onToggleVisibility(id, !isHidden)
      },
      [id, isHidden, onToggleVisibility],
    )

    return (
      <Styled.DraggableWidgetWrapper
        {...rest}
        draggable={isEditMode && isDraggable && !isStatic}
        dragging={isDragging}
        editMode={isEditMode}
        loading={isLoading}
        ref={forwardedRef}
      >
        <Styled.ShakeWrapper animate={isAnimating}>
          {isEditMode && !isStatic && (
            <Styled.HideButton
              className="hide-widget-button"
              icon="eyeCrossed"
              onClick={handleClick}
              size="m"
              variant="secondary"
            />
          )}
          {children}
        </Styled.ShakeWrapper>
      </Styled.DraggableWidgetWrapper>
    )
  },
)
