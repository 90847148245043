import { Button, Color, getDefaultTransition, Spacing, zIndex } from '@design-system'

import { css } from '@emotion/core'
import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

interface DraggableWidgetWrapperProps {
  dragging?: boolean
  draggable?: boolean
  editMode?: boolean
  loading?: boolean
}

export const DraggableWidgetWrapper = styled('div', {
  shouldForwardProp: (propName: string) => isPropValid(propName) && propName !== 'loading',
})<DraggableWidgetWrapperProps>`
  pointer-events: ${({ draggable, editMode }) => (!draggable && editMode ? 'none' : 'initial')};

  &:hover {
    ${() => HideButton} {
      opacity: 1;
    }
  }

  ${({ loading }) =>
    loading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${({ draggable, dragging }) =>
    draggable &&
    !dragging &&
    css`
      &,
      * {
        cursor: grab;

        &:active {
          cursor: grabbing;
        }
      }
    `}

  ${({ draggable }) =>
    draggable &&
    css`
      overflow: hidden;

      &:hover {
        &:before {
          opacity: 1;
          transform: translateX(0);
        }
      }

      &:before {
        opacity: 0;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        z-index: 99;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom left,
          ${transparentize(0.1, Color.White)},
          ${transparentize(0.1, Color.White)},
          transparent 60%
        );
        transform-origin: top right;
        transform: translateX(100%);
        pointer-events: none;
        ${getDefaultTransition('opacity', 'transform')};
      }
    `}

  ${({ dragging }) =>
    dragging &&
    css`
      z-index: ${zIndex.DraggableWidget};
      box-shadow: 0 10px 70px ${transparentize(0.65, Color.Gray200)};
      will-change: transform;
      ${getDefaultTransition('box-shadow')};

      &:before,
      ${HideButton} {
        display: none;
      }
    `}

  ${({ dragging, draggable, editMode }) =>
    !dragging &&
    (draggable || editMode) &&
    css`
      ${getDefaultTransition('transform')}
    `}
`

interface ShakeWrapperProps {
  animate?: boolean
}

export const ShakeWrapper = styled.div<ShakeWrapperProps>`
  @keyframes shake {
    0% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    10% {
      transform: translate(0px, -1px) rotate(1deg);
    }
    20% {
      transform: translate(-1px, 0px) rotate(0deg);
    }
    30% {
      transform: translate(1px, 1px) rotate(-1deg);
    }
    40% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    50% {
      transform: translate(0px, 1px) rotate(1deg);
    }
    60% {
      transform: translate(-1px, 0px) rotate(0deg);
    }
    70% {
      transform: translate(1px, 0px) rotate(-1deg);
    }
    80% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    90% {
      transform: translate(0px, 1px) rotate(1deg);
    }
    100% {
      transform: translate(0px, 0px) rotate(0deg);
    }
  }

  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  ${({ animate }) =>
    animate &&
    css`
      animation: shake 1.5s ease-in infinite;

      &:nth-of-type(2n) {
        animation: shake 2s ease-in infinite;
        animation-delay: 1s;
      }
      &:nth-of-type(3n) {
        animation: shake 2.8s ease-in infinite;
        animation-delay: 0.5s;
      }
      &:nth-of-type(4n) {
        animation: shake 2.3s ease-in infinite;
        animation-delay: 1.5s;
      }

      &:hover {
        animation: none;
      }

      & > * {
        pointer-events: none;
      }
    `}
`

export const HideButton = styled(Button)`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  top: ${Spacing.S};
  right: ${Spacing.S};
  z-index: 100;
  pointer-events: auto;
  ${getDefaultTransition('opacity')}
`
