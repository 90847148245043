import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { Spacing } from '../../enums/spacing'
import { Themable } from '../../types/themable'
import { InputWrapperProps } from '../Input/types/inputWrapperProps'
import { InputSize } from '../Input/types/size'
import { InputWeight } from '../Input/types/weight'
import { getFocusStyle } from '../Input/utils/getFocusStyle'
import { getInputStyle } from '../Input/utils/getInputStyle'
import { getInputWrapperStyle } from '../Input/utils/getInputWrapperStyle'
import { getSizeStyle } from '../Input/utils/getSizeStyle'

export const TextareaWrapper = styled.div`
  width: 100%;
  position: relative;
`

interface TextareaProps extends InputWrapperProps, Themable {
  color: Color
  withSuffix: boolean
  resizable: boolean
  truncate: boolean
  weight: InputWeight
}

export const Textarea = styled.textarea<TextareaProps>`
  height: 100%;
  ${({ theme, color }) => getInputStyle(theme, color)};
  ${(props) => getInputWrapperStyle(props, undefined, true)};
  ${({ withSuffix }) => withSuffix && `padding-right: ${Spacing.XXXL}`};
  ${({ resizable }) => !resizable && 'resize: none'};

  &:disabled {
    cursor: not-allowed;
  }
  &:focus {
    ${({ error, bordered }) => bordered && getFocusStyle(error)};
  }
`

interface SuffixIconWrapperProps {
  inputSize: InputSize
}

export const SuffixIconWrapper = styled.div<SuffixIconWrapperProps>`
  position: absolute;
  top: 0;
  right: 0;
  ${({ inputSize }) => getSizeStyle(inputSize, true)};
`
