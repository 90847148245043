import { DateFormatter } from '@components'
import { FormItem, FormItemsGroup, ModuleGroup, SkeletonText, Space, Text } from '@design-system'

import compact from 'lodash/compact'
import sortBy from 'lodash/sortBy'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate } from '../../../../utils'
import { useBillView } from './contexts/billViewContext'
import { BillHeader } from './elements/BillHeader'
import { BillLine } from './elements/BillLine'
import { BillSummary } from './elements/BillSummary'
import { BillTransactions } from './elements/BillTransactions'
import { BillVendor } from './elements/BillVendor'
import * as Styled from './styles'
import { billStatusToOutlineColor } from './utils/billStatusToOutlineColor'

export const BillReadableForm = (): ReactElement => {
  const { t } = useTranslation()
  const { billStatus, billData, isFetching } = useBillView()
  const { bill, billLines } = billData || {}

  const billDate = bill?.entryDate ? formatDate(new Date(bill?.entryDate)) : undefined
  const dueDate = bill?.dueDate ? formatDate(new Date(bill?.dueDate)) : undefined
  const moduleOutlineColor = billStatus ? billStatusToOutlineColor[billStatus] : undefined
  const referenceNo = bill?.suppliersInvoiceNo
  const lines = billLines ? sortBy(billLines, 'priority') : undefined

  return (
    <Styled.BillViewWrapper>
      <ModuleGroup>
        <Styled.Module outlineColor={moduleOutlineColor}>
          <BillHeader />

          <Space size="s" />

          <BillVendor />
          <FormItemsGroup itemsInRow={compact([true, dueDate, referenceNo, true]).length}>
            <FormItem label={t('bill.view.form.bill_date')}>
              {isFetching ? <SkeletonText /> : <Text>{billDate && <DateFormatter value={new Date(billDate)} />}</Text>}
            </FormItem>
            {dueDate && (
              <FormItem label={t('bill.view.form.due_date')}>
                <Text>
                  <DateFormatter value={new Date(dueDate)} />
                </Text>
              </FormItem>
            )}
            {referenceNo && (
              <FormItem label={t('bill.view.form.reference_number')}>
                <Text>{referenceNo}</Text>
              </FormItem>
            )}

            <BillTransactions />
          </FormItemsGroup>

          {lines?.length ? (
            lines.map((billLine) => {
              return <BillLine key={billLine.priority} billLine={billLine} />
            })
          ) : (
            <BillLine />
          )}
        </Styled.Module>

        <BillSummary />
      </ModuleGroup>
    </Styled.BillViewWrapper>
  )
}
