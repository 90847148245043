import React, { ReactElement, ReactNode } from 'react'

import { ButtonsGroup } from '../../../ButtonsGroup'
import { TableHoverAction } from './elements/TableHoverAction'

interface TableHoverActionsProps {
  children: ReactNode
}

export const TableHoverActions = ({ children }: TableHoverActionsProps): ReactElement => {
  return <ButtonsGroup>{children}</ButtonsGroup>
}

TableHoverActions.Item = TableHoverAction
