export const checkPropsEquality = <T extends Record<string, any>>(
  prevProps: Readonly<T>,
  nextProps: Readonly<T>,
  propsToCheck: Array<keyof T>,
): boolean => {
  let isEqual = true

  for (const key in propsToCheck) {
    if (prevProps[key] !== nextProps[key]) {
      isEqual = false
      break
    }
  }

  return isEqual
}
