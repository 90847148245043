import { amountToDisplayValue, Color, Module, Text } from '@design-system'

import { differenceInDays, format } from 'date-fns'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

import { useOrganizationSubscription } from '../../../../../../hooks'
import { plansWithNoPeriodSelection, planWithOldUsageInfo } from '../../plansData'
import { SummaryBoxesComponent } from '../SummaryBoxesComponent'
import { SubscriptionPeriodToggle } from './elements/SubscriptionPeriodToggle'
import { SwitchPlanSectionMessageComponent } from './elements/SwitchPlanSectionMessageComponent'
import * as Styled from './styles'
import { getPeriodText } from './utils/getPeriodText'

export interface SectionPlanDetailsProps {
  isTrial: boolean
  switchPlan?: SubscriptionPlan
  isSwitchingPlan: boolean
  switchPlanPeriod?: SubscriptionPeriod
  planExpirationDate?: string | null
  postingsLimit: number | null
  revenueLimit: number | null
  subscriptionPeriod: SubscriptionPeriod | null
  subscriptionPlan: SubscriptionPlan
  onSubscriptionPeriodToggle: () => void
  isInUpdatePeriodState?: boolean
  hasDiscount: boolean
}

export const SectionPlanDetailsComponent = ({
  isTrial,
  switchPlan,
  isSwitchingPlan,
  switchPlanPeriod,
  planExpirationDate,
  postingsLimit,
  revenueLimit,
  subscriptionPeriod,
  subscriptionPlan,
  onSubscriptionPeriodToggle,
  isInUpdatePeriodState,
  hasDiscount,
}: SectionPlanDetailsProps) => {
  const { t } = useTranslation()
  const period: SubscriptionPeriod = subscriptionPeriod || SubscriptionPeriod.Yearly
  const { organization } = useUserOrganization()
  const { subscriptionPricePerMonth } = useOrganizationSubscription()

  const subscriptionExpiringDate = format(new Date(planExpirationDate || new Date()), 'yyyy-MM-dd')
  const trialDays = subscriptionExpiringDate
    ? differenceInDays(new Date(subscriptionExpiringDate), new Date()) + 1
    : null
  const isPlanWithOldUsageInfo = planWithOldUsageInfo.includes(subscriptionPlan)

  const summaryItems = React.useMemo(() => {
    const periodText = getPeriodText(subscriptionPlan, period, t)

    const planName =
      isTrial && trialDays ? (
        <>
          <Text as="span" weight="medium" inline>
            {organization?.subscriptionProductPlan === SubscriptionPlan.PlusTrial &&
              `${t(`organization_subscription.plus`)} - `}
            {organization?.subscriptionProductPlan === SubscriptionPlan.PremiumTrial &&
              `${t(`organization_subscription.premium`)} - `}
          </Text>
          <Text as="span" weight="medium" color={Color.Yellow200} inline>
            {trialDays} {t('organization_subscription.days_of_free_trial_left')}
          </Text>
        </>
      ) : (
        `${t(`organization_subscription.${subscriptionPlan}`)}, ${periodText}`
      )
    const items = [
      {
        name: t('organization_subscription.summary_current_plan'),
        value: planName,
      },
    ]

    if (revenueLimit !== undefined && isPlanWithOldUsageInfo && !isTrial) {
      items.push({
        name: t('organization_subscription.max_revenue'),
        value: revenueLimit ? `${amountToDisplayValue(revenueLimit)} DKK` : t('organization_subscription.unlimited'),
      })
    }

    if (postingsLimit !== undefined && isPlanWithOldUsageInfo && !isTrial) {
      items.push({
        name: t('organization_subscription.postings_limit'),
        value: postingsLimit ? `${postingsLimit}` : t('organization_subscription.unlimited'),
      })
    }

    items.push({
      name: t('organization_subscription.cost'),
      value: `${isTrial ? 0 : subscriptionPricePerMonth} DKK/${t('month.abbreviation')}`,
    })

    if (
      subscriptionPeriod &&
      !plansWithNoPeriodSelection.includes(subscriptionPlan) &&
      !hasDiscount &&
      !isSwitchingPlan &&
      !isTrial
    ) {
      items.push({
        name: t('organization_subscription.billing_type'),
        value: (
          <SubscriptionPeriodToggle
            subscriptionPeriod={subscriptionPeriod}
            isInUpdatePeriodState={isInUpdatePeriodState}
            onToggle={onSubscriptionPeriodToggle}
            isDisabled={subscriptionPlan === SubscriptionPlan.Archive}
          />
        ),
      })
    }

    return items
  }, [
    subscriptionPricePerMonth,
    hasDiscount,
    isInUpdatePeriodState,
    isPlanWithOldUsageInfo,
    isSwitchingPlan,
    isTrial,
    onSubscriptionPeriodToggle,
    organization?.subscriptionProductPlan,
    period,
    postingsLimit,
    revenueLimit,
    subscriptionPeriod,
    subscriptionPlan,
    t,
    trialDays,
  ])

  const shouldDisplayRenewalDate = useMemo(() => {
    return !isSwitchingPlan && subscriptionExpiringDate && !plansWithNoPeriodSelection.includes(subscriptionPlan)
  }, [isSwitchingPlan, subscriptionExpiringDate, subscriptionPlan])

  return (
    <Module.Section title={t('organization_subscription.plan_details')}>
      <SummaryBoxesComponent items={summaryItems} />
      {isSwitchingPlan && switchPlan && (
        <Styled.SectionMessageContainer>
          <SwitchPlanSectionMessageComponent
            currentPlan={subscriptionPlan}
            switchPlan={switchPlan}
            switchPlanPeriod={switchPlanPeriod}
            subscriptionExpiringDate={subscriptionExpiringDate}
          />
        </Styled.SectionMessageContainer>
      )}
      {shouldDisplayRenewalDate && (
        <Styled.RenewalMessage variant="small" colorVariant="secondary">
          {t('organization_subscription.renewal_on')}
          <strong>{subscriptionExpiringDate}</strong>.
        </Styled.RenewalMessage>
      )}
    </Module.Section>
  )
}
