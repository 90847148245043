import { RoleSelect } from '@components'
import { FormItemsGroup, Input, Space } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../hooks'
import { getImageClassName } from '../../../../../../../../../../utils/getClassName'
import { InviteUserFormSchema } from '../../utils/formData'
import * as Styles from './styles'

export const InviteUserForm = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<InviteUserFormSchema>()

  return (
    <>
      <FormItemsGroup itemsInRow={2} itemsTemplate={[3, 2]}>
        <FormItem
          label={t('settings.organization.users.invite_user_modal.label.email')}
          name="email"
          render={({ field }) => (
            <Input
              {...field}
              autoFocus
              placeholder={t('settings.organization.users.invite_user_modal.placeholder.email')}
            />
          )}
        />

        <FormItem
          label={t('settings.organization.users.invite_user_modal.label.role')}
          name="userRole"
          render={({ field: { value, onChange, ...props } }) => (
            <RoleSelect
              {...props}
              placeholder={t('settings.organization.users.invite_user_modal.placeholder.role')}
              onSelect={(id?: string, value?: string) => onChange(value)}
              selectedId={value}
              withSearch={false}
            />
          )}
        />
      </FormItemsGroup>
      <Space size="xxxl" />
      <Styles.SendInvitation className={getImageClassName()} />
      <Space size="xxxl" />
    </>
  )
}
