import { Button, ButtonsGroup } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexProps } from 'rebass'

export type NavigationButtonsProps = FlexProps & {
  color?: string
  disableLeftAction?: boolean
  disableRightAction?: boolean
  labelLeft?: string
  labelRight?: string
  onLeftClick?: () => void
  onRightClick?: () => void
  idLeft?: string
  idRight?: string
}

export const NavigationButtons = (props: NavigationButtonsProps) => {
  const { t } = useTranslation()
  const {
    disableLeftAction,
    disableRightAction,
    labelLeft = t('previous'),
    labelRight = t('skip'),
    onLeftClick = () => null,
    onRightClick = () => null,
    idLeft,
    idRight,
  } = props

  return (
    <ButtonsGroup>
      <Button
        data-id={idLeft}
        disabled={disableLeftAction}
        icon="chevronLeft"
        onClick={onLeftClick}
        size="m"
        variant="text"
      >
        {labelLeft}
      </Button>
      <Button
        data-id={idRight}
        disabled={disableRightAction}
        iconRight="chevronRight"
        onClick={onRightClick}
        size="m"
        variant="text"
      >
        {labelRight}
      </Button>
    </ButtonsGroup>
  )
}
