import { useMutation } from 'react-query'

import { APIError } from '../../../../utils'
import { AccountsResponseData, createAccount } from '../query-api'

interface UseCreateAccountProps {
  onError?: (error?: APIError) => void
  onSuccess?: (data: AccountsResponseData) => void
}

export const useCreateAccount = (props?: UseCreateAccountProps) => {
  const { onError, onSuccess } = props || {}
  const { mutate: create, isLoading: isProcessing, ...rest } = useMutation(createAccount, { onSuccess, onError })

  return { create, isProcessing, ...rest }
}
