// This is needed in order to sync routers from react-router-dom in different web-components
// Without it for example routers in other web-components would not know about
// url changes made by clicking Link components in the main app menu
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()
let isURLChangeStartedByEmberRouter = false

export interface EmberHistoryEventDetail {
  url: string
}

// We are using this object to trick Ember's router to do any routing via react-router-dom
// It is used in application.js
window._sharedHistory = {
  pushState: function (data: any, unused: string, url?: string | URL | null) {
    if (url) {
      isURLChangeStartedByEmberRouter = true
      history.push(url, data)
      isURLChangeStartedByEmberRouter = false
    }
  },
  replaceState: function (data: any, unused: string, url?: string | URL | null) {
    if (url) {
      isURLChangeStartedByEmberRouter = true
      history.replace(url, data)
      isURLChangeStartedByEmberRouter = false
    }
  },
}

export { history, isURLChangeStartedByEmberRouter }
