import { ContactPersonSelect } from '@components'

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ContactPerson } from '@modules-deprecated/app/contactPerson/types/contactPerson'

import * as Styled from './styles'

interface ContactPersonSelectWithTagsProps {
  allowMultiple: boolean
  onChange: (value: ContactPerson[]) => void
  value?: ContactPerson[]
  contactId?: string
}

export const ContactPersonSelectWithTags = ({
  allowMultiple = true,
  onChange,
  value,
  contactId,
}: ContactPersonSelectWithTagsProps) => {
  const { t } = useTranslation()

  const onPersonSelect = useCallback(
    (newPerson: ContactPerson) => {
      const hasPersonAlreadyBeenSelected = value?.some((person) => person.id === newPerson.id)
      if (!hasPersonAlreadyBeenSelected) {
        const selectedPersons = value || []

        const updatedSelectedPersons = [...selectedPersons, newPerson]
        onChange(updatedSelectedPersons)
      }
    },
    [value, onChange],
  )

  const handleRemoveTag = useCallback(
    (personToBeRemoved: ContactPerson) => {
      const updatedSelectedPersons = value?.filter((person) => person.id !== personToBeRemoved.id)
      onChange(updatedSelectedPersons || [])
    },
    [onChange, value],
  )

  const handleTagClose = useCallback(
    (contactPerson: ContactPerson) => () => {
      handleRemoveTag(contactPerson)
    },
    [handleRemoveTag],
  )

  const handleTagClick = useCallback(
    (contactPerson: ContactPerson) => () => {
      handleRemoveTag(contactPerson)
    },
    [handleRemoveTag],
  )

  const onCreateContactPerson = useCallback(
    (contactPerson: ContactPerson) => {
      onPersonSelect(contactPerson)
    },
    [onPersonSelect],
  )

  const isLimitReached = value && value?.length > 0
  const shouldShowSelect = allowMultiple || (!allowMultiple && !isLimitReached)

  return (
    <>
      <Styled.ComponentsGroup>
        {value?.map((person: ContactPerson) => (
          <Styled.Tag
            key={person.id}
            closable
            clickable
            onClose={handleTagClose(person)}
            onClick={handleTagClick(person)}
          >
            {person.name || person.email}
          </Styled.Tag>
        ))}
      </Styled.ComponentsGroup>

      {shouldShowSelect && (
        <ContactPersonSelect
          onSelect={onPersonSelect}
          withSearch
          placeholder={t('modal.send_invoice.form.recipient.placeholder')}
          contactId={contactId}
          onCreateContactPerson={onCreateContactPerson}
          selectedContacts={value || []}
        />
      )}
    </>
  )
}
