import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const OrganizationsList = styled.div``
export const OrganizationsListRow = styled.div`
  display: grid;
  gap: ${Spacing.L};
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: ${Spacing.L};
  margin-right: -${Spacing.XL};
`
