import { notify } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useState } from 'react'

import { useOrganizationOwners } from '@modules-deprecated/app/organization/hooks/useOrganizationOwners'
import { useTransferOrganizationOwnership } from '@modules-deprecated/app/organization/hooks/useTransferOrganizationOwnership'
import { UpdateOrganizationOwnershipPayload } from '@modules-deprecated/app/organization/query-api'
import { Organization } from '@modules-deprecated/app/organization/types/organization'
import { UmbrellaOrganization } from '@modules-deprecated/app/organizations/types'
import { User } from '@views/settings/routes/OrganizationSettingsUsers/types'

import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { APIError } from '../../../../../utils/api'
import { invalidateQueriesAfterTransfer } from '../utils/invalidateQueriesAfterTransfer'

interface ContextState {
  isLoading: boolean
  owners: User[]
  selectedOwner: User | undefined
  selectOwnership: (owner: User) => void
  transferOwnership: () => void
  umbrellaOrganizationName: string
}

const TransferOrganizationOwnershipContext = createContext<ContextState | undefined>(undefined)

interface TransferOrganizationOwnershipContextProps {
  onTransferSuccess?: () => void
  organization?: Organization
  umbrellaOrganization?: UmbrellaOrganization
  children: ReactNode
}

export const TransferOrganizationOwnershipContextProvider = ({
  onTransferSuccess,
  organization,
  umbrellaOrganization,
  children,
}: TransferOrganizationOwnershipContextProps): ReactElement => {
  const [selectedOwner, setSelectedOwner] = useState<User>()
  const { organizationOwners, isLoading } = useOrganizationOwners({ umbrellaOrganization, organization })

  const { transferOrganizationOwnership } = useTransferOrganizationOwnership({
    onSuccess: () => {
      invalidateQueriesAfterTransfer(!!umbrellaOrganization)
      onTransferSuccess?.()
    },
    onError: (error: APIError | undefined) => {
      notify({
        id: NotificationKeys.TransferOwnership,
        message: error?.message,
        variant: 'error',
      })
    },
  })

  const organizationId = umbrellaOrganization?.organizationId || organization?.id

  const transferOwnership = useCallback(() => {
    if (!selectedOwner || !organizationId) {
      return
    }

    const ownerIdKey: keyof UpdateOrganizationOwnershipPayload = selectedOwner.isUmbrella
      ? 'ownerUmbrellaId'
      : 'ownerUserId'
    const payload: UpdateOrganizationOwnershipPayload = { [ownerIdKey]: selectedOwner.id }
    transferOrganizationOwnership({ organizationId, payload })
  }, [selectedOwner, organizationId, transferOrganizationOwnership])

  const selectOwnership = useCallback(
    (owner: User) => {
      setSelectedOwner(owner)
    },
    [setSelectedOwner],
  )

  return (
    <TransferOrganizationOwnershipContext.Provider
      value={{
        isLoading,
        owners: organizationOwners,
        selectedOwner,
        selectOwnership,
        transferOwnership,
        umbrellaOrganizationName: umbrellaOrganization?.name || '',
      }}
    >
      {children}
    </TransferOrganizationOwnershipContext.Provider>
  )
}

export const useTransferOrganizationOwnershipModal = () => {
  const context = useContext(TransferOrganizationOwnershipContext)

  if (!context) {
    throw new Error('TransferOrganizationOwnershipContextProvider is missing in the module!')
  }

  return context
}
