import { Icon, Space, Text } from '@design-system'

import { ReactElement, ReactNode } from 'react'
import { Trans } from 'react-i18next'

import * as Styled from './styles'

interface BillReconciliationSuggestionProps {
  children?: ReactNode
  withIcon?: boolean
}

export const BillReconciliationSuggestion = ({
  children,
  withIcon = false,
}: BillReconciliationSuggestionProps): ReactElement => {
  return (
    <Styled.ReconciliationSuggestionWrapper>
      <Styled.ReconciliationSuggestionHeader>
        {withIcon && (
          <>
            <Icon icon="aiStars" size="large" color="currentColor" />
            <Space size="xxs" horizontal />
          </>
        )}
        <Text>
          <Trans i18nKey="bill.payment_reconcile.found_match">
            We found a <strong>bank line</strong> that looks like a match for this bill!
          </Trans>
        </Text>
      </Styled.ReconciliationSuggestionHeader>
      <Space size="m" />
      {children}
    </Styled.ReconciliationSuggestionWrapper>
  )
}
