import { AnyAction } from 'redux'

import { AppState } from '../types/appState'
import { TAX_RATES_RECEIVED, TAX_RATES_REQUESTED } from './actions'

const taxRatesReducer = (state: AppState, { type, payload }: AnyAction): AppState => {
  switch (type) {
    case TAX_RATES_REQUESTED:
      return state

    case TAX_RATES_RECEIVED:
      return {
        ...state,
        taxRates: payload,
      }

    default:
      return state
  }
}

export default taxRatesReducer
