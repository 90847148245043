import { Modal } from '@design-system'

import { useInviteOrUpdateUmbrellaUserForm } from '../../context/inviteOrUpdateUmbrellaUserFormContext'
import { ModalForm } from './elements/ModalForm'

export const ModalBody = () => {
  const { Form } = useInviteOrUpdateUmbrellaUserForm()

  return (
    <Modal.Body>
      <Form>
        <ModalForm />
      </Form>
    </Modal.Body>
  )
}
