import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconSupport = ({
  color = '#ED5244',
  color2 = '#FFFFFF',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-support')} {...rest}>
    <svg width={width} height={height} x="0px" y="0px" viewBox="0 0 397 397">
      <g>
        <path
          fill={coloring(color)}
          d="M198.5,387C94.6,387,10,302.4,10,198.5S94.6,10,198.5,10S387,94.6,387,198.5S302.4,387,198.5,387z
          M198.5,109.1c-49.4,0-89.4,40-89.4,89.4s40,89.4,89.4,89.4s89.4-40,89.4-89.4c0,0,0,0,0,0C287.8,149.1,247.9,109.2,198.5,109.1z"
        />
      </g>
      <g>
        <path
          fill={coloring(color2)}
          d="M198.5,20C297.1,20,377,99.9,377,198.5c0,98.6-79.9,178.5-178.5,178.5S20,297.1,20,198.5
          c0-47.3,18.8-92.7,52.3-126.2C105.7,38.7,151.1,19.9,198.5,20 M198.5,297.9c54.9,0,99.4-44.5,99.4-99.3s-44.5-99.4-99.3-99.4
          c-54.9,0-99.4,44.5-99.4,99.3c0,0,0,0,0,0C99.2,253.4,143.7,297.8,198.5,297.9 M198.5,0C88.9,0,0,88.9,0,198.5S88.9,397,198.5,397
          S397,308.1,397,198.5S308.1,0,198.5,0L198.5,0z M198.5,277.9c-43.9,0-79.4-35.5-79.4-79.4s35.5-79.4,79.4-79.4s79.4,35.5,79.4,79.4
          v0C277.9,242.4,242.4,277.9,198.5,277.9z"
        />
      </g>
      <g>
        <rect
          x="90.2"
          y="40.7"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -41.5208 100.24)"
          fill={coloring(color2)}
          width="20"
          height="119"
        />
      </g>
      <g>
        <rect
          x="286.8"
          y="237.2"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -122.9219 296.7598)"
          fill={coloring(color2)}
          width="20"
          height="119"
        />
      </g>
      <g>
        <rect
          x="237.4"
          y="90.7"
          transform="matrix(0.7078 -0.7064 0.7064 0.7078 15.5117 238.8179)"
          fill={coloring(color2)}
          width="117.9"
          height="20"
        />
      </g>
      <g>
        <rect
          x="40.7"
          y="286.8"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -180.4812 157.7993)"
          fill={coloring(color2)}
          width="119"
          height="20"
        />
      </g>
    </svg>
  </Box>
)
