import { Button, ButtonProps } from '@design-system'

import React, { forwardRef, ReactElement, Ref, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { usePeriodSelectContext } from '../../contexts/periodSelectContext'
import { getPeriodButtonLabel } from './utils/getPeriodButtonLabel'

type PeriodButtonProps = ButtonProps

export const PeriodButton = forwardRef(
  (props: PeriodButtonProps, forwardedRef: Ref<HTMLButtonElement>): ReactElement => {
    const { t } = useTranslation()
    const { defaultButtonLabel, periodValueSaved } = usePeriodSelectContext()

    const buttonLabel = useMemo(() => getPeriodButtonLabel(t, periodValueSaved), [t, periodValueSaved])

    return (
      <Button ref={forwardedRef} icon="calendar" variant="secondary" {...props}>
        {buttonLabel || defaultButtonLabel || t('period_select.trigger_button_placeholder')}
      </Button>
    )
  },
)
