import React, { ReactElement, ReactNode, useRef } from 'react'

import { Container } from '../../../Container'
import { useScrollReset } from './hooks/useScrollReset'
import { useScrollRestoration } from './hooks/useScrollRestoration'
import * as Styled from './styles'

interface ModuleContentProps {
  children: ReactNode
  fullHeight?: boolean
  resetScroll?: boolean
  restoreScroll?: boolean
}

export const ModuleContent = ({
  children,
  fullHeight,
  resetScroll,
  restoreScroll,
}: ModuleContentProps): ReactElement => {
  const moduleContentRef = useRef<HTMLDivElement>(null)

  useScrollRestoration({ scrollableElementRef: moduleContentRef, isEnabled: restoreScroll })
  useScrollReset({ scrollableElementRef: moduleContentRef, isEnabled: resetScroll })

  return (
    <Styled.ModuleContent scrollableNodeProps={{ ref: moduleContentRef }} $fullHeight={fullHeight}>
      <Container>
        <Styled.InnerContent>{children}</Styled.InnerContent>
      </Container>
    </Styled.ModuleContent>
  )
}
