import { Divider as DividerComponent, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const BillLine = styled.div`
  padding-top: ${Spacing.S};
  padding-bottom: ${Spacing.XXS};
`

export const BillLineTitleWrapper = styled.header`
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;
`

export const Divider = styled(DividerComponent)`
  margin-top: ${Spacing.M};
`
