import { withModalConditionalRender } from '@design-system'

import { ModalId } from '../../../../enums/modalId'
import { ConfirmDeleteConnectionModal } from '../ConfirmDeleteConnectionModal/ConfirmDeleteConnectionModal'
import { RemovedBankConnectionModal } from '../RemovedBankConnectionModal/RemovedBankConnectionModal'
import { BankToAccountConnectedModal } from '../SuccessfulConnectionModal/BankToAccountConnectedModal'
import { ConnectionRenewedModal } from '../SuccessfulConnectionModal/ConnectionRenewedModal'

const BankConnectionRenewedModal = withModalConditionalRender(ConnectionRenewedModal)
const BankConnectionRemovedModal = withModalConditionalRender(RemovedBankConnectionModal)
const BankConnectionEstablishedModal = withModalConditionalRender(BankToAccountConnectedModal)
const BankConnectionDeleteConfirmationModal = withModalConditionalRender(ConfirmDeleteConnectionModal)

interface BankIntegrationModalsProps {
  removeConnection: () => void
}

export const BankIntegrationModals = ({ removeConnection }: BankIntegrationModalsProps) => {
  return (
    <>
      <BankConnectionDeleteConfirmationModal
        id={ModalId.BankConnectionRemoveConfirmationModal}
        removeConnection={removeConnection}
      />
      <BankConnectionEstablishedModal id={ModalId.BankConnectionEstablishedModal} />
      <BankConnectionRenewedModal id={ModalId.BankConnectionSuccessModal} />
      <BankConnectionRemovedModal id={ModalId.BankConnectionRemovedModal} />
    </>
  )
}
