import { Translate } from '@components'
import {
  InternalAccessor,
  ModalConfirmation,
  NavItem,
  Table,
  TableColumn,
  TableHoverActions,
  TableStaticActions,
  useModal,
} from '@design-system'

import React, { ReactElement, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { TableIds } from '../../../../../../enums/tableIds'
import { AccessTokensStaticActionType } from '../../enums/accessTokensStaticActionType'
import { useAccessTokensTableData } from '../../hooks/useAccessTokensTableData'
import { useActorMutations } from '../../hooks/useActorMutations'
import { useFetchActors } from '../../hooks/useFetchActors'
import { AccessTokensTableRow } from '../../types/accessTokensTableRow'
import { SimpleActor } from '../../types/simpleActor'
import { AccessTokenModalsGroup } from './elements/AccessTokenModalsGroup'
import * as Styled from './styles'

export const AccessTokensTable = (): ReactElement => {
  const actorRef = useRef<SimpleActor>()
  const { t } = useTranslation()
  const { actors, isLoading } = useFetchActors()
  const data = useAccessTokensTableData(actors)

  const resetActorRef = useCallback(() => {
    actorRef.current = undefined
  }, [])

  const { open: openCreateOrEditAccessTokenModal } = useModal(ModalId.CreateOrEditAccessTokenModal, {
    onClose: () => {
      resetActorRef()
    },
  })
  const { open: openViewAccessTokenModal } = useModal(ModalId.ViewAccessTokenModal, {
    onClose: () => {
      resetActorRef()
    },
  })
  const { open: openDeleteConfirmationModal, close: closeDeleteConfirmationModal } = useModal(
    ModalId.DeleteActorConfirmationModal,
    {
      onClose: () => {
        resetActorRef()
      },
    },
  )

  const { deleteActor, isDeletingActor } = useActorMutations({
    onSuccessDelete: () => {
      resetActorRef()
      closeDeleteConfirmationModal()
    },
  })

  const handleDeleteButtonClick = useCallback(
    (actorId?: string) => {
      if (actorId) {
        openDeleteConfirmationModal()
      }
    },
    [openDeleteConfirmationModal],
  )

  const handleDeleteModalOkButtonClick = useCallback(() => {
    if (actorRef.current?.id) {
      deleteActor(actorRef.current.id)
    }
  }, [deleteActor])

  const staticActionsItems: NavItem[] = useMemo(
    () => [
      {
        children: t('settings.organization.access_tokens_table.actions.delete'),
        iconLeft: 'trash',
        id: 'delete',
        value: AccessTokensStaticActionType.Delete,
      },
    ],
    [t],
  )

  const columns: TableColumn<AccessTokensTableRow>[] = useMemo(
    () => [
      {
        accessor: 'name',
        alignment: 'left',
        Header: <Translate value="settings.organization.access_tokens_table.header.name" />,
        sortable: false,
      },
      {
        accessor: 'description',
        alignment: 'left',
        Header: <Translate value="settings.organization.access_tokens_table.header.description" />,
        sortable: false,
      },
      {
        Header: '',
        accessor: InternalAccessor.HoverActions,
        Cell: ({ row, data }) => (
          <TableHoverActions>
            <TableHoverActions.Item
              icon="magnifyingGlass"
              label={t('settings.organization.access_tokens_table.actions.view')}
              onClick={() => {
                const { id, name } = data[row.id] as AccessTokensTableRow
                actorRef.current = { id, name }
                openViewAccessTokenModal()
              }}
            />
            <TableHoverActions.Item
              icon="paperWithPencil"
              label={t('settings.organization.access_tokens_table.actions.edit')}
              onClick={() => {
                const { id, description, name } = data[row.id] as AccessTokensTableRow
                actorRef.current = { id, description, name }
                openCreateOrEditAccessTokenModal()
              }}
            />
          </TableHoverActions>
        ),
      },
      {
        Header: '',
        accessor: InternalAccessor.StaticActions,
        Cell: ({ row, data }) => (
          <TableStaticActions
            items={staticActionsItems}
            onSelect={() => {
              const { id, name } = data[row.id] as AccessTokensTableRow
              actorRef.current = { id, name }
              handleDeleteButtonClick(actorRef.current.id)
            }}
          />
        ),
      },
    ],
    [handleDeleteButtonClick, openCreateOrEditAccessTokenModal, openViewAccessTokenModal, staticActionsItems, t],
  )

  return (
    <Styled.AccessTokensTableWrapper>
      <Table
        columns={columns}
        data={data}
        id={TableIds.AccessTokens}
        isLoading={isLoading}
        skeletonItemsPerPage={4}
        withColumnsFiltering={false}
      />
      <AccessTokenModalsGroup actor={actorRef.current} />
      <ModalConfirmation
        cancelLabel={t('cancel')}
        danger
        id={ModalId.DeleteActorConfirmationModal}
        message={t('settings.organization.access_tokens.delete.confirmation_modal.message')}
        okLabel={t('settings.organization.access_tokens.delete.confirmation_modal.button_delete')}
        okLoading={isDeletingActor}
        onOk={handleDeleteModalOkButtonClick}
        title={t('settings.organization.access_tokens.delete.confirmation_modal.title')}
      />
    </Styled.AccessTokensTableWrapper>
  )
}
