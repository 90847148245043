import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { BankLineMatchesQueryParams, fetchBankLineMatchesFromMultipleAccounts } from '../query-api'

interface UseBankLineMatchesProps extends Omit<BankLineMatchesQueryParams, 'accountId'> {
  accountIds: string[]
  isDisabled?: boolean
}

export const useBankLineMatches = ({ accountIds, isDisabled, ...restProps }: UseBankLineMatchesProps) => {
  const queryParams: BankLineMatchesQueryParams[] = useMemo(
    () =>
      accountIds.map((accountId) => ({
        accountId,
        ...restProps,
      })),
    [accountIds, restProps],
  )
  const { data: bankLineMatches, isLoading } = useQuery(
    [QueryKeys.BankLineMatches, queryParams],
    () =>
      fetchBankLineMatchesFromMultipleAccounts({
        queryKey: [QueryKeys.BankLineMatches, { paramsList: queryParams }],
      }),
    {
      enabled: accountIds.length > 0 && !isDisabled,
      refetchOnWindowFocus: true,
    },
  )

  return {
    bankLineMatches: bankLineMatches || [],
    isLoading,
  }
}
