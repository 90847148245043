import { ModalConfirmation, useModal } from '@design-system'

import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ModalId } from '../enums/modalId'
import { RegisterEvent } from '../types/registerEvent'

interface ContextState {
  setDirty: (isDirty: boolean) => void
}

const DirtyRouteContext = createContext<ContextState | undefined>(undefined)

export type SetDirtyEvent = RegisterEvent<{
  isDirty: boolean
  onRouteChange: () => void
  onRouteBlocked: () => void
}>

export interface DirtyRouteProps {
  onLeaveRoute: (event: RegisterEvent<undefined>) => void
  onSetDirtyRouteFlag: (event: SetDirtyEvent) => void
}

interface DirtyRouteContextProviderProps extends DirtyRouteProps {
  children: ReactNode
}

export const DirtyRouteContextProvider = ({
  children,
  onLeaveRoute,
  onSetDirtyRouteFlag,
}: DirtyRouteContextProviderProps) => {
  const { close: closeModal, open: openModal } = useModal(ModalId.DirtyRouteModal)
  const { t } = useTranslation()
  const history = useHistory()
  const isDirty = useRef(false)
  const unblockFnRef = useRef<() => void>()
  const locationPath = useRef<Location>()

  // Set history detection
  useEffect(() => {
    const listener = history.block(handleBlockHistory as any) // can't solve typescript issue

    return () => {
      // Destroy listener
      listener()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBlockHistory = useCallback(
    (location: Location) => {
      if (isDirty.current) {
        locationPath.current = location
        openModal()

        // The `false` value blocks switching the route
        return false
      }

      closeModal()
    },
    [closeModal, openModal],
  )

  const handleRouteChange = useCallback(() => {
    closeModal()
  }, [closeModal])

  const handleRouteBlocked = useCallback(() => {
    openModal()
  }, [openModal])

  const handleSetDirty = useCallback(
    (newIsDirty: boolean) => {
      isDirty.current = newIsDirty

      onSetDirtyRouteFlag({
        detail: { isDirty: isDirty.current, onRouteBlocked: handleRouteBlocked, onRouteChange: handleRouteChange },
      })
    },
    [handleRouteBlocked, handleRouteChange, onSetDirtyRouteFlag],
  )

  const handleLeaveRoute = useCallback(() => {
    handleSetDirty(false)
    closeModal()

    if (locationPath.current) {
      unblockFnRef.current?.()
      history.replace(locationPath.current)
    } else {
      onLeaveRoute({ detail: undefined })
    }
  }, [closeModal, handleSetDirty, history, onLeaveRoute])

  return (
    <DirtyRouteContext.Provider
      value={{
        setDirty: handleSetDirty,
      }}
    >
      {children}
      <ModalConfirmation
        cancelLabel={t('dirty_route_modal.cancel_button')}
        danger
        id={ModalId.DirtyRouteModal}
        message={t('dirty_route_modal.content')}
        okLabel={t('dirty_route_modal.ok_button')}
        onOk={handleLeaveRoute}
        title={t('dirty_route_modal.title')}
      />
    </DirtyRouteContext.Provider>
  )
}

export const useDirtyRoute = () => {
  const context = useContext(DirtyRouteContext)

  if (!context) {
    throw new Error('DirtyRouteContextProvider is missing in the module!')
  }

  return context
}
