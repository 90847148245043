import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconVat = ({
  color,
  color2 = '#FFFFFF',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  isOutlined,
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-vat')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 342.75 366.27">
      <path
        d="M221.37,43.65c52.44,4.61,99.89,36.71,124.27,84.13l-124.25,50Z"
        transform="translate(-16.84 -30.05)"
        fill={isOutlined ? 'transparent' : coloring('#ED5244')}
      />
      <path
        d="M234.88,59.2a141.09,141.09,0,0,1,52.28,22,144.44,144.44,0,0,1,39,39.83L234.89,157.8V59.2M220.15,30.05a12.32,12.32,0,0,0-12.28,12.32l0,137.18a12.33,12.33,0,0,0,12.3,12.32,12.18,12.18,0,0,0,4.6-.9l127.09-51.15a12.33,12.33,0,0,0,6.39-17C332.4,71.48,281,34.84,221.14,30.09c-.33,0-.66,0-1,0Z"
        transform="translate(-16.84 -30.05)"
        fill={coloring('#fff')}
      />
      <path
        d="M185.72,382.82c-2.9,0-5.63-.09-8.34-.27h-.17A155.08,155.08,0,0,1,30.34,227.7c0-79.93,61.87-147.55,141.13-154.51l0,137.12a25.78,25.78,0,0,0,34.94,24.13l129-48.85a155.65,155.65,0,0,1,5.7,41.83c0,85.68-69.68,155.39-155.35,155.4Z"
        transform="translate(-16.84 -30.05)"
        fill={isOutlined ? 'transparent' : coloring('#41c447')}
      />
      <path
        d="M158,88.71l0,121.6a39.27,39.27,0,0,0,53.22,36.75l114.44-43.33a142.93,142.93,0,0,1,2,23.7A141.88,141.88,0,0,1,185.75,369.32c-2.61,0-5.06-.08-7.49-.24h-.33A141.65,141.65,0,0,1,158,88.71M172.7,59.58c-.33,0-.65,0-1,0C85.55,66.45,16.83,139.17,16.84,227.7c0,90,70.84,163.6,159.65,168.32,2.87.19,5.93.3,9.26.3h0A169,169,0,0,0,348,180.62a12.3,12.3,0,0,0-16.19-8.11l-130.2,49.3a12.14,12.14,0,0,1-4.37.81A12.31,12.31,0,0,1,185,210.31l0-138.42A12.31,12.31,0,0,0,172.7,59.58Z"
        transform="translate(-16.84 -30.05)"
        fill={coloring('#fff')}
      />
    </svg>
  </Box>
)
