import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { Spacing } from '../../../../enums/spacing'
import { getDefaultTransition } from '../../../../utils/getDefaultTransition'

interface PaymentPaneItemWrapperProps {
  collapsible?: boolean
  withHoverEffect?: boolean
}

export const PaymentPaneItemWrapper = styled.div<PaymentPaneItemWrapperProps>`
  position: relative;
  display: grid;
  grid-template-columns: 96px 96px 1fr auto auto;
  align-items: center;
  width: 100%;
  padding: 20px ${Spacing.XL};
  ${getDefaultTransition('background-color')}

  ${({ collapsible, withHoverEffect }) => {
    if (!collapsible && withHoverEffect) {
      return css`
        &:hover {
          background-color: ${Color.Gray10};
        }
      `
    }
  }}

  ${({ withHoverEffect }) => {
    if (withHoverEffect) {
      return css`
        cursor: pointer;
      `
    }
  }}

  &:not(&:first-of-type) {
    &:after {
      content: '';
      position: absolute;
      right: ${Spacing.XL};
      left: ${Spacing.XL};
      top: 0;
      height: 1px;
      background-color: ${Color.Gray30};
    }
  }
`

export const PaymentPaneItem = styled.div`
  display: flex;
  padding-right: ${Spacing.L};

  &:last-of-type {
    padding-right: 0;
  }
`
