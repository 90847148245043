import { Button, ButtonProps, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { ModalId } from '../../../enums/modalId'
import { useComponentId } from '../../../hooks/useComponentId'
import { WithTrackingContext } from '../../../types/withTrackingContext'
import { UpgradeSubscriptionModal } from '../../Modal/upgradeSubscriptionModal'
import { ProtectableProps, ProtectedComponent } from '../ProtectedComponent'

export type ButtonProtectedProps = ProtectableProps & ButtonProps & WithTrackingContext

export const ButtonProtected = ({
  scopes,
  scopesCheckDisabled,
  trackingContext,
  to,
  ...props
}: ButtonProtectedProps): ReactElement => {
  const upgradeModalId = useComponentId(ModalId.UpgradeSubscriptionModal)
  const { open: openUpgradeModal } = useModal(upgradeModalId)

  const handleUnauthorizedButtonClick = useCallback(() => {
    openUpgradeModal()
  }, [openUpgradeModal])

  return (
    <ProtectedComponent
      scopes={scopes}
      scopesCheckDisabled={scopesCheckDisabled}
      unauthorizedNode={
        <>
          <Button {...props} onClick={handleUnauthorizedButtonClick} />
          <UpgradeSubscriptionModal id={upgradeModalId} trackingContext={trackingContext} />
        </>
      }
    >
      <Button {...props} to={to} />
    </ProtectedComponent>
  )
}
