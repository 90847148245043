import { TaxMode } from '../../../../../enums/taxMode'
import { Bill } from '../../../types/bill'
import { BillLine } from '../../../types/billLine'

export const getInclVatAmount = (bill: Bill, billLine: Partial<BillLine>): number => {
  const amount = billLine.amount || 0
  const vatAmount = billLine.tax || 0

  return bill.taxMode === TaxMode.Excluding ? amount + vatAmount || 0 : amount
}
