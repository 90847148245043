import qs from 'qs'

import { ApiContentType } from '../../enums/apiContentType'
import { SortDirection } from '../../enums/sortDirection'
import { getRequest } from '../../utils'
import { QuoteState } from './enums/quoteState'
import { QuoteType } from './enums/quoteType'
import { QuotesPagination } from './routes/QuotesList/types/quotesPagination'
import { Quote } from './types/quote'

export enum QuotesSortProperty {
  Type = 'type',
  OrderNo = 'orderNo',
  EntryDate = 'entryDate',
  DueDate = 'dueDate',
  ContactName = 'contact.name',
  Description = 'description',
  NetAmount = 'netAmount',
  GrossAmount = 'grossAmount',
}

export interface FetchQuotesOptions {
  /* Examples:
   * - entryDatePeriod=all
   * - entryDatePeriod=dates:2019-04-23...2019-06-14
   * - entryDatePeriod=from:2019-05-08
   * - entryDatePeriod=half:2019-2
   * - entryDatePeriod=month:2019-06
   * - entryDatePeriod=quarter:2019-2
   * - entryDatePeriod=through:2019-05-03
   * - entryDatePeriod=year:2019
   * - entryDatePeriod=fiscalyear:2019
   */
  entryDatePeriod?: string
  organization: string
  q?: string
  page?: number
  pageSize?: number
  sortDirection?: SortDirection
  sortProperty?: QuotesSortProperty
  state?: QuoteState
  type?: QuoteType
}

export interface FetchQuotesResponseData {
  data: Quote[]
  meta: QuotesPagination
}

export const fetchQuotes = ({
  organization,
  page,
  pageSize,
  q,
  state,
  sortProperty,
  sortDirection,
  ...rest
}: FetchQuotesOptions): Promise<FetchQuotesResponseData> => {
  const sortDirectionSign = sortDirection === 'ASC' ? '' : '-'

  const queryParams = qs.stringify({
    organization,
    sort: sortProperty ? `${sortDirectionSign}${sortProperty}` : '-entryDate',
    ...(page ? { page } : {}),
    ...(pageSize ? { pageSize } : {}),
    ...(q ? { q } : {}),
    ...(state ? { state } : {}),
    ...rest,
  })

  return getRequest(`/organizations/${organization}/quotes?${queryParams}`, {
    Accept: ApiContentType.ApplicationBillyJSON,
  })
}
