import { Country } from '../types/country'
import { Locale } from '../types/locale'

/* eslint-disable @typescript-eslint/naming-convention */
export const localeToCountryCode: Record<Locale, Country> = {
  da_DK: 'dk',
  en_US: 'us',
  fr_FR: 'fr',
}
/* eslint-enable @typescript-eslint/naming-convention */
