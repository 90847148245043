import { Badge, BadgeProps, useModal, withModalConditionalRender } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../enums/modalId'
import { TrackingContext } from '../../enums/trackingContext'
import { UpgradeSubscriptionModal } from '../Modal/upgradeSubscriptionModal'

const UpgradeSubscriptionModalConditional = withModalConditionalRender(UpgradeSubscriptionModal)

interface UpgradeBadgeProps extends BadgeProps {
  trackingContext: TrackingContext
}

export const UpgradeBadge = ({ children, trackingContext, ...rest }: UpgradeBadgeProps): ReactElement => {
  const { t } = useTranslation()
  const { open: openUpgradeSubscriptionModal } = useModal(ModalId.UpgradeSubscriptionModal)

  return (
    <>
      <Badge {...rest} onClick={openUpgradeSubscriptionModal}>
        {children || t('upgrade')}
      </Badge>
      <UpgradeSubscriptionModalConditional id={ModalId.UpgradeSubscriptionModal} trackingContext={trackingContext} />
    </>
  )
}
