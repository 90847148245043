import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { StyledComponent } from '../../../types/styledComponent'

export const getErrorStyle = (Control: StyledComponent, checked: boolean): SerializedStyles => css`
  ${Control} {
    box-shadow: none;

    ${checked
      ? css`
          border-color: ${Color.Red};
          background-color: ${Color.Red};

          /* Check circle inside */
          &:before {
            background-color: ${Color.White};
          }
        `
      : css`
          border-color: ${Color.Red};
          background-color: ${Color.White};

          /* Check circle inside */
          &:before {
            background-color: ${Color.DeepSeaGreen};
          }
        `};
  }
`
