import { DateInput } from '@design-system'

import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../hooks'

export const InvoiceDueDate = () => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext()

  return (
    <FormItem
      name="dueDate"
      label={t('external_invoices.editor.form.field.due_date.label')}
      render={({ field }) => {
        return <DateInput {...field} />
      }}
    />
  )
}
