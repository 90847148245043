import React, { ReactElement } from 'react'

import { CreditCard, CreditCardProps } from '../../../CreditCard'

interface CardDetailsProps extends Partial<CreditCardProps> {
  isCardExpired?: boolean
}

export const CardDetails = ({
  expirationDate,
  isCardExpired,
  horizontal,
  isInvalid,
  label,
  type,
}: CardDetailsProps): ReactElement | null => {
  if (isInvalid === undefined) {
    return null
  }

  return (
    <CreditCard
      expirationDate={expirationDate}
      isInvalid={isInvalid}
      label={label}
      type={type}
      horizontal={horizontal}
    />
  )
}
