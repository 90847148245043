import { BillState } from '../enums/billState'
import { BillStatus } from '../enums/billStatus'

interface StatusToQueryPropsArgs {
  isOverdue: boolean
  isPaid: boolean
  state: BillState | BillState[]
}

export const billStatusToQueryProps: Record<Exclude<BillStatus, BillStatus.Voided>, Partial<StatusToQueryPropsArgs>> = {
  [BillStatus.All]: {
    state: [BillState.Approved, BillState.Draft],
  },
  [BillStatus.Draft]: {
    state: BillState.Draft,
  },
  [BillStatus.Overdue]: {
    isOverdue: true,
    isPaid: false,
    state: BillState.Approved,
  },
  [BillStatus.Paid]: {
    isPaid: true,
    state: BillState.Approved,
  },
  [BillStatus.PartiallyPaid]: {},
  [BillStatus.Unpaid]: {
    isPaid: false,
    state: BillState.Approved,
  },
}
