import { ItemsPerPage, Table } from '@design-system'

import Cookies from 'js-cookie'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Row } from 'react-table'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { CookieKeys } from '../../../../../../enums/cookieKeys'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { SortDirection } from '../../../../../../enums/sortDirection'
import { TableIds } from '../../../../../../enums/tableIds'
import { getTableColumnIdDirection } from '../../../../../../utils/getTableColumnIdDirection'
import { TransactionSortProperty } from '../../../../query-api'
import { useTransactions } from '../../hooks/useTransactions'
import { useTransactionsListFilters } from '../../hooks/useTransactionsListFilters'
import { useTransactionsTableData } from '../../hooks/useTransactionsTableData'
import { TransactionsTableRow } from '../../types/transactionsTableRow'
import { getTransactionTableColumns } from './utils/getTransactionTableColumns'

export const TransactionsTable = (): ReactElement => {
  const { t } = useTranslation()
  const { transactions, isLoading, pagination } = useTransactions()
  const data = useTransactionsTableData(transactions, t)
  const [{ pageSize, sortDirection, sortProperty, withVoided }, setQueryParams] = useTransactionsListFilters()
  const { navigate } = useEmberRouter()
  const location = useLocation()

  const columns = useMemo(() => getTransactionTableColumns(withVoided, t), [withVoided, t])

  const sortedColumnId = useMemo(() => {
    return getTableColumnIdDirection(sortProperty, sortDirection === SortDirection.Desc)
  }, [sortDirection, sortProperty])

  const handleRowClick = useCallback(
    (row: Row<TransactionsTableRow>) => {
      const { _urlInternal } = row.values

      if (!_urlInternal) {
        const { id: transactionId } = row.original
        Cookies.set(CookieKeys.TransactionsListQuery, location.search)
        navigate(EmberRoute.Transaction, transactionId)
      }
    },
    [location.search, navigate],
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setQueryParams({ page })
    },
    [setQueryParams],
  )

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: ItemsPerPage) => {
      setQueryParams({ page: 1, pageSize: itemsPerPage })
    },
    [setQueryParams],
  )

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      setQueryParams({
        page: 1,
        sortProperty: columnId as TransactionSortProperty,
        sortDirection: isDesc ? SortDirection.Desc : SortDirection.Asc,
      })
    },
    [setQueryParams],
  )

  return (
    <Table
      columns={columns}
      currentPage={pagination?.page}
      data={data}
      id={TableIds.TransactionsList}
      isLoading={isLoading}
      itemsPerPage={pageSize}
      onItemsPerPageChange={handleItemsPerPageChange}
      onPageChange={handlePageChange}
      onRowClick={handleRowClick}
      onSort={handleSort}
      sortedColumnId={sortedColumnId}
      totalPageItems={pagination?.total}
      withColumnsFiltering
      withItemsPerPageSelect
      withStickyHeader
    />
  )
}
