import { Color, getDefaultTransition, Spacing } from '@design-system'

import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'

import { HIGHLIGHT_NOTE_CLASS_NAME } from '../../constants/highlightNoteClassName'
import { PANEL_HORIZONTAL_PADDING } from '../../constants/panelHorizontalPadding'

const getHighlightKeyframes = () => keyframes`
  0% {
    background-color: transparent;
  }
  1%, 80% {
    background-color: ${Color.Purple20};
  }
  100% {
    background-color: transparent;
  }
`

export const NoteItemWrapper = styled.li`
  padding: ${Spacing.XL} ${PANEL_HORIZONTAL_PADDING};
  margin-right: -${PANEL_HORIZONTAL_PADDING};
  margin-left: -${PANEL_HORIZONTAL_PADDING};
  border-bottom: 1px solid ${Color.Gray30};
  ${getDefaultTransition('background')};

  &.${HIGHLIGHT_NOTE_CLASS_NAME} {
    animation: ${getHighlightKeyframes()} 5000ms;
  }

  &:first-of-type {
    border-top: 1px solid ${Color.Gray30};
  }
`

export const NoteHeader = styled.header`
  display: flex;
  justify-content: space-between;
`

export const NoteDateWrapper = styled.div`
  flex-shrink: 0;
`

export const NoteAuthorWrapper = styled.div`
  margin-left: ${Spacing.L};
  overflow: hidden;
`

export const NoteContent = styled.div``
