import { NavItem } from '@design-system'

import { endOfQuarter, isAfter, isBefore, setQuarter, setYear, startOfQuarter } from 'date-fns'
import chunk from 'lodash/chunk'

import { PeriodMode } from '../../../../enums/periodMode'
import { PeriodValue } from '../../../../types/periodValue'
import { getMonthNames } from '../../MonthSelect'

const MONTHS_IN_QUARTER_COUNT = 3

export const getQuarterItems = (
  year: number,
  locale?: string,
  minDate?: Date,
  maxDate?: Date,
): NavItem<PeriodValue>[] => {
  const monthNames = getMonthNames(locale)
  const monthNamesInQuarters = chunk(monthNames, MONTHS_IN_QUARTER_COUNT)

  return monthNamesInQuarters.map((quarterMonths, quarterIndex) => {
    const date = setQuarter(setYear(new Date(), year), quarterIndex)
    let isDisabled = false

    if (maxDate) {
      isDisabled = isAfter(endOfQuarter(date), endOfQuarter(maxDate))
    }

    if (minDate && !isDisabled) {
      isDisabled = isBefore(startOfQuarter(date), startOfQuarter(minDate))
    }

    return {
      id: String(quarterIndex),
      children: quarterMonths.join(' — '),
      value: {
        mode: PeriodMode.Quarter,
        year,
        value: quarterIndex,
        disabled: isDisabled,
      },
    }
  }, [])
}
