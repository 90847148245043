import { Modal, ModalBody, ModalFooter, ModalHeader } from '@components-deprecated'
import { Text } from '@design-system'

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import * as Styled from './styles'

export interface ConnectionModalProps {
  isOpen: boolean
  isTryingToConnect: boolean
  onClose: () => void
  handleConnectionAttempt: () => void
}

const connectionSteps = [
  'skat.connection_modal.instructions.step_1',
  'skat.connection_modal.instructions.step_2',
  'skat.connection_modal.instructions.step_3',
  'skat.connection_modal.instructions.step_6',
  'skat.connection_modal.instructions.step_7',
  'skat.connection_modal.instructions.step_8',
]

export const ConnectionModal = (props: ConnectionModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} width={720}>
      <ModalHeader heading={t('skat.connection_modal.title')} />

      <ModalBody>
        {props.isTryingToConnect ? (
          <>
            <Styled.List>
              {connectionSteps.slice(1).map((step) => (
                <li key={step}>
                  <Trans i18nKey={step}>
                    Some copy <strong>some bolded copy</strong>
                  </Trans>
                </li>
              ))}
            </Styled.List>
            <br />
            <Text>{t('skat.connection_modal.ready_to_report')}</Text>
            <br />
            <Text>{t('skat.connection_modal.modal_will_dissappear')}</Text>
          </>
        ) : (
          <>
            <Text>{t('skat.connection_modal.info_1')}</Text>
            <Text>{t('skat.connection_modal.info_2')}</Text>
            <br />
            <Styled.List>
              {connectionSteps.map((step) => (
                <Styled.ListItem key={step}>
                  <Trans i18nKey={step}>
                    Some copy <strong>some bolded copy</strong>
                  </Trans>
                </Styled.ListItem>
              ))}
            </Styled.List>
          </>
        )}
      </ModalBody>

      <ModalFooter>
        {!props.isTryingToConnect && (
          <Styled.Button onClick={props.handleConnectionAttempt}>{t('skat_box.connect')}</Styled.Button>
        )}
      </ModalFooter>
    </Modal>
  )
}
