import { Link } from '@design-system'

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

export interface SkatBoxContentProps {
  isConnected?: boolean
  onConnect: () => void
  onDisconnect: () => void
  isLoadingConnecting: boolean
  isValidCVR: boolean
}

export const SkatBoxContent = ({
  isConnected,
  onConnect,
  onDisconnect,
  isLoadingConnecting,
  isValidCVR,
}: SkatBoxContentProps) => {
  const { t } = useTranslation()

  if (isLoadingConnecting) {
    return (
      <>
        <Styled.SkeletonText width={100} />
        <Styled.SkeletonText width={50} />
        <Styled.SkeletonText width={150} />
      </>
    )
  } else {
    return isConnected ? (
      <>
        <p>{t('skat_box.connected_automatic')}</p>
        <Styled.SkatDisconnectButton onClick={onDisconnect}>{t('skat_box.disconnect')}</Styled.SkatDisconnectButton>
      </>
    ) : (
      <>
        <p>{t('skat_box.connected_manual')}</p>

        <Styled.SkatConnectButton disabled={!isValidCVR} fullWidth onClick={onConnect} variant="primary">
          {t('skat_box.connect')}
        </Styled.SkatConnectButton>

        <Styled.LinkWrapper>
          <Link
            withAnimation
            target="_blank"
            href="https://www.billy.dk/support/article/forbind-dit-regnskab-med-skat/"
          >
            {t('skat_box.see_how_easy')}
          </Link>
        </Styled.LinkWrapper>
      </>
    )
  }
}
