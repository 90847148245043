import { SkeletonBox as SkeletonBoxComponent } from '@design-system'

import styled from '@emotion/styled'

export const BankConnectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`

export const InnerContainer = styled.div``

export const SkeletonBox = styled(SkeletonBoxComponent)`
  margin: auto;
`
