import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'

import { ReceiptFilterType } from '@views/receipts/elements/Receipt'

import { SortDirection } from '../enums/sortDirection'
import { useQueryParams } from './useQueryParams'

export interface BulkNavigationQueryParams {
  bulkPage: number
  bulkPageSize: ItemsPerPage
  bulkSortDirection: SortDirection
  bulkSortProperty: string
  bulkUploadType: ReceiptFilterType
}

export const defaultQuery: BulkNavigationQueryParams = {
  bulkPage: 1,
  bulkPageSize: 100,
  bulkSortDirection: SortDirection.Asc,
  bulkSortProperty: '',
  bulkUploadType: ReceiptFilterType.Upload,
}

interface UseBulkNavigationQueryParams {
  enabled?: boolean
  initialQuery?: Partial<BulkNavigationQueryParams>
}

type UseBulkNavigationQueryResponse = [
  BulkNavigationQueryParams,
  (queryParams: Partial<BulkNavigationQueryParams>) => void,
]

export const useBulkNavigationQueryParams = (props?: UseBulkNavigationQueryParams): UseBulkNavigationQueryResponse => {
  const { enabled = true, initialQuery } = props || {}
  const [queryParams, setQueryParams] = useQueryParams<BulkNavigationQueryParams>({
    enabled,
    defaultQuery: {
      bulkPage: initialQuery?.bulkPage || defaultQuery.bulkPage,
      bulkPageSize: initialQuery?.bulkPageSize || defaultQuery.bulkPageSize,
      bulkSortDirection: initialQuery?.bulkSortDirection || defaultQuery.bulkSortDirection,
      bulkSortProperty: initialQuery?.bulkSortProperty || defaultQuery.bulkSortProperty,
      bulkUploadType: initialQuery?.bulkUploadType || defaultQuery.bulkUploadType,
    },
  })

  return useMemo(
    () => [
      {
        ...queryParams,
        // Sometimes `bulkPage` comes as a string. It needs to be always `number` type.
        bulkPage: Number(queryParams.bulkPage),
        // Sometimes `bulkPageSize` comes as a string. It needs to be always `number` type.
        bulkPageSize: Number(queryParams.bulkPageSize) as ItemsPerPage,
      },
      setQueryParams,
    ],
    [queryParams, setQueryParams],
  )
}
