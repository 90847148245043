import { Color } from '@design-system'

import React, { ReactElement } from 'react'

import { IconProps } from '../Icon'

export const IconCircleQuestion = ({
  className,
  color = Color.Gray90,
  title,
  width = '20px',
  height = '20px',
}: IconProps): ReactElement => (
  <i className={className} title={title}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width={width} height={height}>
      <path
        fill={color}
        d="M8 10.7l-.5.2-.2.5.2.5.5.2.5-.2.2-.5-.2-.5a.8.8 0 00-.5-.2zM8 3.9a2.6 2.6 0 00-2.6 2.6c0 .4.3.7.7.7.4 0 .7-.3.7-.7 0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2S8.7 7.8 8 7.8c-.4 0-.7.3-.7.7v1c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-.4a2.6 2.6 0 001.9-2.5c0-1.5-1.1-2.7-2.6-2.7z"
      />
      <path
        fill={color}
        d="M8 0a8 8 0 00-8 8 8 8 0 008 8 8 8 0 008-8 8 8 0 00-8-8zm0 14.6c-3.6 0-6.6-3-6.6-6.6 0-3.6 3-6.6 6.6-6.6 3.6 0 6.6 3 6.6 6.6 0 3.6-3 6.6-6.6 6.6z"
      />
    </svg>
  </i>
)
