import React, { ReactElement } from 'react'

import { useInvalidateQueriesCustomListener } from '../../hooks/useInvalidateQueriesCustomListener'
import { useInvalidateQueriesListener } from '../../hooks/useInvalidateQueriesListener'
import { PeriodicLockReminderModal } from './elements/PeriodicLockReminderModal'
import { ToastContainer } from './elements/ToastContainer'

export const GlobalComponent = (): ReactElement => {
  // Events listeners - mostly used for handling changes in Ember to invalidate some data in React
  // It has to be done in the Menu as it's the only module which is constantly rendered
  useInvalidateQueriesListener()
  useInvalidateQueriesCustomListener()

  // Here add anything that needs to be always present in DOM
  return (
    <>
      <ToastContainer />
      <PeriodicLockReminderModal />
    </>
  )
}
