import { TFunction } from 'i18next'

import { settingsRouteToEmberSettingsRoute } from '@views/settings/constants/settingsRouteToEmberSettingsRoute'
import { SettingsTabItem } from '@views/settings/elements/SettingsNavigation/types'
import { umbrellaRouteToEmberRoute } from '@views/umbrella/constants/umbrellaRouteToEmberRoute'
import { UmbrellaSettingsTabItem } from '@views/umbrella/routes/umbrellaSettings/elements/UmbrellaSettingsNavigation/types/umbrellaSettingsTabItem'

import { SearchResultItem } from '../types/searchResultItem'

export const getSearchItemsForSettings = (
  menuItems: (SettingsTabItem | UmbrellaSettingsTabItem)[],
  t: TFunction,
): SearchResultItem[] => {
  const items: SearchResultItem[] = []

  for (const menuItem of menuItems) {
    const { route, text, icon, hidden } = menuItem
    if (!hidden) {
      items.push({
        id: route,
        icon,
        text: `${t('global_search.go_to')}: ${t('settings')}/${text}`,
        category: 'menu',
        routeTo: settingsRouteToEmberSettingsRoute[route] || umbrellaRouteToEmberRoute[route],
      })
    }
  }

  return items
}
