import { Text } from '@design-system'

import React, { ReactElement, ReactNode, useCallback } from 'react'

import { Logo } from '../Logo'
import * as Styled from './styles'
import { TagSuggestion } from './types/tagSuggestion'

interface BookkeepingTagSuggestionProps {
  children: ReactNode
  tags: TagSuggestion[]
  onClick?: (tag: TagSuggestion) => void
}

export const BookkeepingTagSuggestion = ({
  children,
  tags,
  onClick,
  ...rest
}: BookkeepingTagSuggestionProps): ReactElement => {
  // TODO: causes rerenders, should be changed
  const handleClick = useCallback(
    (tag: TagSuggestion) => () => {
      onClick?.(tag)
    },
    [onClick],
  )

  return (
    <Styled.BookkeepingTagSuggestion {...rest}>
      <Styled.ImageWrapper>
        <Logo logo="agerasSimple" />
      </Styled.ImageWrapper>

      <Styled.ContentWrapper>
        <Text>{children}</Text>

        <Styled.TagsList>
          {tags.map((tag) => (
            <Styled.Tag key={tag.tagId} onClick={handleClick(tag)}>
              {tag.tagName}
            </Styled.Tag>
          ))}
        </Styled.TagsList>
      </Styled.ContentWrapper>
    </Styled.BookkeepingTagSuggestion>
  )
}
