import { AnyAction } from 'redux'

import {
  ACTIVATE_TRANSACTION_DROPPABLE_AREA,
  BANK_BALANCE_RECEIVED,
  BANK_CONNECTION_RECEIVED,
  BANK_CONNECTION_REQUESTED,
  BANK_LINE_MATCH_REMOVED,
  BANK_LINE_MATCH_UPDATED,
  BANK_LINE_MATCHES_DELETED,
  BANK_LINE_SUBJECT_ASSOCIATION_CREATED,
  BANK_LINE_SUBJECT_ASSOCIATION_DELETED,
  BANK_LINE_SUBJECT_ASSOCIATION_UPDATED,
  BANK_LINE_UNGROUPED,
  BANK_LINES_AND_TRANSACTIONS_RECEIVED,
  BANK_LINES_LATEST_LINE_RECEIVED,
  BANK_LINES_LOADING,
  BILLY_TRANSACTION_ADDED,
  BULK_RECONCILE_STATE_UPDATED,
  CREATE_PLACEHOLDER,
  DESTROY_PLACEHOLDERS,
  DIFFERENCE_TYPE_SELECTED,
  DND_BANK_LINES,
  DND_BILLY_TRANSACTIONS,
  FILTERS_CHANGED,
  RECONCILE_ALL_APPROVED_STATE_UPDATED,
  RESET_BULK_RECONCILE_STATE,
  SET_BALANCE_BAR_OPEN,
  TRANSACTIONS_LOADING,
} from './actions'
import { BankReconciliationState, ReconcilablePostingType } from './types'
import {
  createPlaceholder,
  destroyPlaceholders,
  dndBankLines,
  dndBillyTransactions,
  selectDifferenceType,
  ungroupBankLine,
} from './utils'

const initialState: BankReconciliationState = {
  bankConnection: null,
  bankLineMatches: [],
  bankLines: [],
  bankLineSubjectAssociations: [],
  billyTransactions: [],
  reconcileAllApprovedState: { reconciling: false },
  bulkReconcileState: {},
  filters: {
    bankLineGroupFilters: {
      showReconciled: false,
      showMatched: true,
      showManual: true,
    },
    bankLineGroupSearchValue: '',
    dateRange: [],
    fiscalYear: new Date().getFullYear(),
    reconcilablePostingFilters: {
      types: Object.values(ReconcilablePostingType),
      showDrafts: true,
    },
    reconcilablePostingSearchValue: '',
    bankLinesSortDirection: 1,
    bankLinesSortProperty: 'entryDate',
    transactionsPostingLimit: 500,
    transactionsSortDirection: 1,
    transactionsSortProperty: 'entryDate',
  },
  // BalanceBar
  latestBankLineEntryDate: undefined,
  primoUltimoBalance: null,
  isBalanceBarOpen: false,
  // results
  reconcileAllResult: null,
  // DND
  isTransactionDroppableAreaActive: false,
  // Loaders
  bankConnectionLoading: false,
  bankConnectionResolved: false,
  bankLinesLoading: false,
  transactionsLoading: false,
}

const reducer = (
  state: BankReconciliationState = initialState,
  { type, payload }: AnyAction,
): BankReconciliationState => {
  switch (type) {
    case BANK_CONNECTION_REQUESTED:
      return {
        ...state,
        bankConnection: undefined,
        bankConnectionLoading: true,
      }

    case BANK_CONNECTION_RECEIVED:
      return {
        ...state,
        bankConnection: payload,
        bankConnectionLoading: false,
        bankConnectionResolved: true,
      }

    case BANK_LINES_AND_TRANSACTIONS_RECEIVED:
      return {
        ...state,
        ...payload,
      }

    case BANK_LINES_LATEST_LINE_RECEIVED:
      return {
        ...state,
        ...payload,
      }

    case BANK_LINE_UNGROUPED:
      return {
        ...state,
        ...ungroupBankLine({
          bankLineMatches: state.bankLineMatches,
          bankLine: payload.ungroupedBankLine,
          newBankLineMatch: payload.newBankLineMatch,
          previousBankLineMatchId: payload.previousBankLineMatchId,
          sortProperty: state.filters.bankLinesSortProperty,
          sortDirection: state.filters.bankLinesSortDirection,
        }),
      }

    case BANK_LINE_MATCHES_DELETED:
      return {
        ...state,
        bankLineMatches: state.bankLineMatches.filter((match) => {
          return !match.lineIds.some((lineId: string) => payload.includes(lineId))
        }),
      }

    case BANK_LINE_MATCH_REMOVED:
      return {
        ...state,
        bankLineMatches: state.bankLineMatches.filter((match) => match.id !== payload),
      }

    case BANK_LINE_MATCH_UPDATED:
      return {
        ...state,
        bankLineMatches: state.bankLineMatches.map((bankLineMatch) => {
          return bankLineMatch.id === payload.id ? payload : bankLineMatch
        }),
      }

    case BANK_LINE_SUBJECT_ASSOCIATION_CREATED:
      return {
        ...state,
        bankLineSubjectAssociations: [...state.bankLineSubjectAssociations, payload],
      }

    case BANK_LINE_SUBJECT_ASSOCIATION_UPDATED:
      return {
        ...state,
        bankLineSubjectAssociations: state.bankLineSubjectAssociations.map((blsa) => {
          return blsa.subjectReference === payload.subjectReference ? payload : blsa
        }),
      }

    case BANK_LINE_SUBJECT_ASSOCIATION_DELETED:
      return {
        ...state,
        bankLineSubjectAssociations: state.bankLineSubjectAssociations.filter((blsa) => blsa.id !== payload),
      }

    case BILLY_TRANSACTION_ADDED:
      return {
        ...state,
        billyTransactions: [...state.billyTransactions, payload],
      }

    case RECONCILE_ALL_APPROVED_STATE_UPDATED:
      return {
        ...state,
        reconcileAllApprovedState: payload,
      }

    case BULK_RECONCILE_STATE_UPDATED:
      return {
        ...state,
        bulkReconcileState: {
          ...state.bulkReconcileState,
          [payload.matchId]: payload.success,
        },
      }

    case RESET_BULK_RECONCILE_STATE:
      return {
        ...state,
        bulkReconcileState: {},
      }

    case DND_BANK_LINES:
      return {
        ...state,
        ...dndBankLines({
          id: payload.id,
          fullMatchId: payload.matchId,
          fullSourceMatchId: payload.sourceMatchId,
          bankLineMatches: state.bankLineMatches,
          billyTransactions: state.billyTransactions,
          transactionsSortProperty: state.filters.transactionsSortProperty,
          transactionsSortDirection: state.filters.transactionsSortDirection,
        }),
      }

    case DND_BILLY_TRANSACTIONS:
      return {
        ...state,
        ...dndBillyTransactions({
          id: payload.id,
          fullMatchId: payload.matchId,
          fullSourceMatchId: payload.sourceMatchId,
          bankLineMatches: state.bankLineMatches,
          billyTransactions: state.billyTransactions,
          sortProperty: state.filters.transactionsSortProperty,
          sortDirection: state.filters.transactionsSortDirection,
        }),
      }

    case FILTERS_CHANGED:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      }

    case CREATE_PLACEHOLDER:
      return {
        ...state,
        ...createPlaceholder({
          source: payload.source,
          type: payload.type,
          bankLineMatches: state.bankLineMatches,
          billyTransactions: state.billyTransactions,
        }),
      }

    case DESTROY_PLACEHOLDERS:
      return {
        ...state,
        ...destroyPlaceholders({
          bankLineMatches: state.bankLineMatches,
          billyTransactions: state.billyTransactions,
        }),
      }

    case BANK_BALANCE_RECEIVED:
      return {
        ...state,
        primoUltimoBalance: payload.primoUltimoBalance,
      }

    case SET_BALANCE_BAR_OPEN:
      return {
        ...state,
        isBalanceBarOpen: payload.isOpen,
      }

    case DIFFERENCE_TYPE_SELECTED:
      return {
        ...state,
        bankLineMatches: selectDifferenceType(state, payload),
      }

    case ACTIVATE_TRANSACTION_DROPPABLE_AREA:
      return {
        ...state,
        isTransactionDroppableAreaActive: payload.isActive,
      }

    case BANK_LINES_LOADING:
      return {
        ...state,
        bankLinesLoading: payload.isLoading,
      }

    case TRANSACTIONS_LOADING:
      return {
        ...state,
        transactionsLoading: payload.isLoading,
      }

    default:
      return state
  }
}

export default reducer
