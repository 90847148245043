import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchUserOrganizations } from './query-api'
import { UserOrganization } from './types'

interface ContextState {
  userOrganizations: UserOrganization[]
}

const UserOrganizationsContext = createContext<ContextState | undefined>(undefined)

interface UserOrganizationsContextProps {
  children?: ReactNode
  organizationId?: string
}

export const UserOrganizationsContextProvider = ({ children }: UserOrganizationsContextProps): ReactElement => {
  const { data: organizations } = useQuery([QueryKeys.UserOrganizations], fetchUserOrganizations)

  return (
    <UserOrganizationsContext.Provider value={{ userOrganizations: organizations?.data || [] }}>
      {children}
    </UserOrganizationsContext.Provider>
  )
}

export const useUserOrganizations = () => {
  const context = useContext(UserOrganizationsContext)

  if (!context) {
    throw new Error('UserOrganizationsContextProvider is missing in the module!')
  }

  return context
}
