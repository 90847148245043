export const bannedFileNameCharacters = [
  { value: '#', replacedValue: '' },
  { value: '%', replacedValue: '' },
  { value: '&', replacedValue: '' },
  { value: '{', replacedValue: '' },
  { value: '}', replacedValue: '' },
  { value: '\\', replacedValue: '' },
  { value: '/', replacedValue: '-' },
  { value: '<', replacedValue: '' },
  { value: '>', replacedValue: '' },
  { value: '*', replacedValue: '' },
  { value: '?', replacedValue: '' },
  { value: '!', replacedValue: '' },
  { value: '"', replacedValue: '' },
  { value: ':', replacedValue: '' },
  { value: '@', replacedValue: '' },
  { value: '+', replacedValue: '' },
  { value: '`', replacedValue: '' },
  { value: '|', replacedValue: '' },
  { value: '=', replacedValue: '' },
]
