import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization, useUserOrganizationSettings } from '@modules-deprecated/app/organization'

import { useDirtyRoute } from '../../../../../../../contexts/dirtyRouteContext'
import { FormComponent, useForm } from '../../../../../../../hooks'
import { useUpdateInvoiceAndProductSettings } from '../hooks/useUpdateInvoiceAndProductSettings'
import { convertInvoiceSettingsDataToForm } from '../utils/convertInvoiceSettingsDataToForm'
import { defaultValues, getValidationSchema, InvoiceAndProductSettingsForm } from '../utils/formData'

interface ContextState {
  Form: FormComponent
  isLoading: boolean
  isProcessing: boolean
  submitForm: () => void
}

const InvoiceAndProductSettingsContext = createContext<ContextState | undefined>(undefined)

interface InvoiceAndProductSettingsContextProps {
  children?: ReactNode
}

export const InvoiceAndProductSettingsContextProvider = ({
  children,
}: InvoiceAndProductSettingsContextProps): ReactElement => {
  const { t } = useTranslation()
  const { organization, isLoading: isLoadingOrganization } = useUserOrganization()
  const { organizationSettings, isLoading: isLoadingOrganizationSettings } = useUserOrganizationSettings()
  const { setDirty: setDirtyRoute } = useDirtyRoute()
  const {
    Form,
    formState: { isDirty: isFormDirty },
    getValues,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    defaultValues,
    validationSchema: getValidationSchema(t, organization?.minNextInvoiceNo || 1),
  })
  const { update, isProcessing } = useUpdateInvoiceAndProductSettings()

  const shouldShowNextInvoiceNoField = getValues('shouldShowNextInvoiceNoField')
  const isLoading = isLoadingOrganization || isLoadingOrganizationSettings

  const handleFormSubmit = useCallback(
    (values: InvoiceAndProductSettingsForm) => {
      const propertiesToOmit: Array<keyof InvoiceAndProductSettingsForm> | undefined = shouldShowNextInvoiceNoField
        ? undefined
        : ['nextInvoiceNo']

      update(values, propertiesToOmit)
    },
    [shouldShowNextInvoiceNoField, update],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleFormSubmit)()
  }, [handleFormSubmit, handleSubmit])

  useEffect(() => {
    if (organization && organizationSettings && !isLoading && !isProcessing) {
      const resetFormValues = convertInvoiceSettingsDataToForm(organization, organizationSettings)

      resetForm(resetFormValues)
    }
  }, [isLoading, isProcessing, organization, organizationSettings])

  useEffect(() => {
    setDirtyRoute(isFormDirty)
  }, [isFormDirty, setDirtyRoute])

  return (
    <InvoiceAndProductSettingsContext.Provider value={{ Form, isLoading, isProcessing, submitForm }}>
      {children}
    </InvoiceAndProductSettingsContext.Provider>
  )
}

export const useInvoiceAndProductSettings = () => {
  const context = useContext(InvoiceAndProductSettingsContext)

  if (!context) {
    throw new Error('InvoiceAndProductSettingsContextProvider is missing in the module!')
  }

  return context
}
