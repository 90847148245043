import React, { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { EmberEventFn } from '../types/emberEventFn'

export type EmberConnectBank = EmberEventFn

export type ConnectBank = (bankAccountId?: string) => void

interface ContextState {
  connectBank: ConnectBank
}

const EmberConnectBankContext = createContext<ContextState | undefined>(undefined)

interface EmberConnectBankContextProps {
  children?: ReactNode
  onConnectBank: EmberConnectBank
}

export const EmberConnectBankContextProvider = ({
  children,
  onConnectBank,
}: EmberConnectBankContextProps): ReactElement => {
  const handleConnectBank = useCallback(
    (bankAccountId?: string) => {
      onConnectBank({ detail: { bankAccountId } })
    },
    [onConnectBank],
  )

  return (
    <EmberConnectBankContext.Provider value={{ connectBank: handleConnectBank }}>
      {children}
    </EmberConnectBankContext.Provider>
  )
}

export const useEmberConnectBank = () => {
  const context = useContext(EmberConnectBankContext)

  if (!context) {
    throw new Error('EmberConnectBankContextProvider is missing in the module!')
  }

  return context
}
