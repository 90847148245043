import { ItemsPerPage } from '@design-system'

import { useEffect, useMemo, useRef } from 'react'
import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../../../config/queryClient'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { SortDirection } from '../../../../../enums/sortDirection'
import { usePeriodDates } from '../../../../../hooks/usePeriodDates'
import { Paging } from '../../../../../types/metable'
import { InvoicesSortProperty } from '../../../enums/InvoicesSortProperty'
import { InvoiceStatus } from '../../../enums/invoiceStatus'
import { fetchInvoices } from '../../../query-api'
import { FetchInvoicesParams } from '../../../types/fetchInvoicesParams'

interface UseFetchInvoicesProps {
  page?: number
  pageSize?: ItemsPerPage
  period?: string
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: InvoicesSortProperty
  status?: InvoiceStatus
}

export const useFetchInvoices = ({
  page,
  pageSize,
  period,
  sortProperty,
  sortDirection,
  searchQuery,
  status,
}: UseFetchInvoicesProps) => {
  const previousPagination = useRef<Paging | undefined>(undefined)
  const { isLoading: isLoadingPeriodDates, fromDate, toDate } = usePeriodDates(period)

  const queryParams: FetchInvoicesParams = useMemo(() => {
    return {
      ...(page ? { page } : {}),
      ...(pageSize ? { pageSize: pageSize as ItemsPerPage } : {}),
      ...(sortDirection ? { sortDirection } : {}),
      ...(sortProperty ? { sortProperty } : {}),
      ...(searchQuery ? { searchQuery } : {}),
      ...(fromDate && toDate ? { fromDate, toDate } : {}),
      ...(status && status !== InvoiceStatus.All ? { status } : {}),
    }
  }, [fromDate, page, pageSize, searchQuery, sortDirection, sortProperty, status, toDate])

  const queryKey = useMemo(() => [QueryKeys.InvoicesZervant, queryParams], [queryParams])

  const {
    data: invoicesData,
    isIdle,
    isLoading,
  } = useQuery(queryKey, () => fetchInvoices(queryParams), {
    enabled: !isLoadingPeriodDates,
    staleTime: defaultStaleTime,
  })

  // If pagination exists then copy the value to reference and use it when original pagination during loading returns `undefined` value.
  useEffect(() => {
    if (invoicesData?.data?.meta.paging) {
      previousPagination.current = { ...invoicesData?.data?.meta.paging }
    }
  }, [invoicesData?.data?.meta.paging])

  return {
    invoices: invoicesData?.data?.documents || [],
    pagination: invoicesData?.data.meta.paging || previousPagination.current,
    isLoading: isIdle || isLoading,
  }
}
