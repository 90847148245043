import { IStringifyOptions, stringify } from 'qs'
import { useHistory } from 'react-router-dom'

import { useLocalStorage } from './useLocalStorage'

const options: IStringifyOptions = { arrayFormat: 'indices' }

export const useSetQueryParams = <T extends Record<string, any>>(key: string) => {
  const history = useHistory()
  const [, setStoredQuery] = useLocalStorage(`query-params-${key}`)

  return (params: T, prefix = '') => {
    setStoredQuery(params)
    history.push(`${prefix}?${stringify(params, options)}`)
  }
}
