import React, { ReactElement } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AccountSelector } from '@modules-deprecated/app'
import { Account } from '@modules-deprecated/app/accounts/types'

import { Input } from '../../../Form'
import { UploadItem } from '../../types'
import { FileError } from '../FileError'
import * as Styled from './styles'

interface AccountFormFragmentProps {
  index: number
  bulkAccount?: Account
  getCurrentItems: () => UploadItem[]
  resetBulkAccount: () => void
  filterBrokenFilesItems: (items: UploadItem[]) => UploadItem[]
  field: Partial<UploadItem>
}

export const AccountFormFragment = ({
  index,
  bulkAccount,
  getCurrentItems,
  resetBulkAccount,
  filterBrokenFilesItems,
  field: formField,
}: AccountFormFragmentProps): ReactElement => {
  const form = useFormContext()
  const { t } = useTranslation()

  const handleItemAccountSelect = () => {
    if (!bulkAccount?.id) {
      return
    }

    const items = getCurrentItems()
    const itemsFiltered = filterBrokenFilesItems(items)
    const isSomeDiffer = itemsFiltered.some((item) => item.account?.id !== bulkAccount?.id)

    if (isSomeDiffer) {
      resetBulkAccount()
    }
  }

  const isSaving = form.formState.isSubmitting

  return (
    <>
      <AccountSelector
        formControl={form.control}
        name={`items[${index}].account`}
        mr="20px"
        mb="0"
        onItemSelect={handleItemAccountSelect}
        withSelectEvent
        disabled={isSaving}
        isPaymentEnabled
        simplifiedList
      />
      <Styled.CommentFieldWrapper>
        <Input
          formControl={form.control}
          name={`items[${index}].comment`}
          placeholder={t('voucher.inbox.form.attachment_placeholder')}
          disabled={isSaving}
          errorPath={`items[${index}].comment`}
          mb="0"
          defaultValue={formField.comment}
        />
      </Styled.CommentFieldWrapper>
      <Controller
        control={form.control}
        name={`items[${index}].errorMessage`}
        render={({ field }) => <FileError fileName={formField.file?.name} errorMessage={field.value} withMargin />}
      />
    </>
  )
}
