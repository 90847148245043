import { Color, SkeletonText } from '@design-system'

import { differenceInDays, format } from 'date-fns'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { LocaleCode } from '../../../../../enums/localeCode'
import { useLocale } from '../../../../../hooks/useLocale'
import * as Styled from './styles'

export interface TaxDeadlineDisplayProps {
  deadlineTimestamp?: string
  isLoading?: boolean
}

export function TaxDeadlineDisplay({ deadlineTimestamp, isLoading }: TaxDeadlineDisplayProps) {
  const { t } = useTranslation()
  const locale = useLocale()
  const dateFormatting = locale.code === LocaleCode.EN ? 'MMMM d, yyyy' : 'd. MMMM yyyy'

  let daysLeft
  let dueDate

  if (deadlineTimestamp) {
    const deadlineDate = Date.parse(deadlineTimestamp)
    daysLeft = differenceInDays(new Date(), deadlineDate)
    daysLeft = daysLeft > 0 ? -Math.abs(daysLeft) : Math.abs(daysLeft)
    dueDate = format(new Date(deadlineTimestamp), dateFormatting, { locale })
  }

  return (
    <Styled.Container>
      <>
        {!isLoading && (
          <Styled.Text colorVariant="secondary" variant="small">
            {t('dashboard.widget.vat.due_date')}
          </Styled.Text>
        )}

        <Styled.TextWrapper>
          {isLoading ? (
            <SkeletonText variant="h1" width={100} />
          ) : (
            <>
              <Styled.Text color={Color.DeepSeaGreen} variant="h1">
                {daysLeft}
              </Styled.Text>
              <Styled.Text colorVariant="secondary">{t('day', { count: daysLeft })}</Styled.Text>
            </>
          )}
        </Styled.TextWrapper>

        {isLoading ? (
          <SkeletonText variant="micro" />
        ) : (
          <Styled.Text weight="regular" colorVariant="secondary" variant="micro">
            {dueDate}
          </Styled.Text>
        )}
      </>
    </Styled.Container>
  )
}
