import { Text } from '@design-system'

import React, { ReactElement } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInternalUmbrellaId } from '../../../../hooks/useInternalUmbrellaId'
import { ProductPlan } from '../../../UmbrellaOrganizationsProductPlansSelect'
import { CreateOrganizationForm } from '../../utils/formData'
import * as Styled from './styles'

interface SubscriptionSectionProps {
  formControl: Control<CreateOrganizationForm>
  disabled?: boolean
}

export const SubscriptionSection = ({ disabled, formControl }: SubscriptionSectionProps): ReactElement => {
  const { t } = useTranslation()
  const umbrellaId = useInternalUmbrellaId()

  return (
    <Styled.SubscriptionSection>
      <Styled.ProductPlansSection>
        <Text colorVariant="secondary">{t('signup_guide.create_internal_umbrella_org.product_plans.title')}</Text>
        <Controller
          control={formControl}
          render={({ field }) => {
            const { onChange, value, ...restField } = field

            return (
              <Styled.UmbrellaOrganizationsProductPlansSelect
                {...restField}
                disabled={disabled}
                onSelect={(_, selectedValue?: ProductPlan) => {
                  if (!selectedValue) {
                    return
                  }

                  onChange(selectedValue)
                }}
                placement="top-start"
                selectedId={`${value?.plan}_${value?.period}`}
                size="xl"
                umbrellaId={umbrellaId as string}
              />
            )
          }}
          name="productPlan"
        />
      </Styled.ProductPlansSection>
    </Styled.SubscriptionSection>
  )
}
