import { Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import { SimpleCheckList, TranslatedItem } from '../../../SimpleCheckList'
import * as Styled from './styles'

interface PurchaseCardFeaturesProps {
  footer?: ReactNode
  header?: ReactNode
  items: TranslatedItem[]
  itemsLabel?: string
}

export const PurchaseCardFeatures = ({
  footer,
  header,
  items,
  itemsLabel,
}: PurchaseCardFeaturesProps): ReactElement => (
  <Styled.PurchaseCardFeaturesWrapper>
    {header && (
      <Styled.HeaderWrapper>
        <Text variant="large" alignment="center" weight="bold">
          {header}
        </Text>
      </Styled.HeaderWrapper>
    )}
    {itemsLabel && <Styled.ItemsLabelWrapper alignment="left">{itemsLabel}</Styled.ItemsLabelWrapper>}
    <SimpleCheckList items={items} alignment="left" />
    {footer && (
      <Styled.FooterWrapper>
        <Text>{footer}</Text>
      </Styled.FooterWrapper>
    )}
  </Styled.PurchaseCardFeaturesWrapper>
)
