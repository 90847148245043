import React, { createContext, ReactElement, ReactNode, useContext, useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchAccountGroups } from './query-api'
import { AccountGroup } from './types'

interface ContextState {
  accountGroups: AccountGroup[] | undefined
  isLoading: boolean
}

const AccountGroupsContext = createContext<ContextState | undefined>(undefined)

interface AccountGroupsContextProps {
  children?: ReactNode
  organizationId?: string
}

export const AccountGroupsContextProvider = ({
  children,
  organizationId = '',
}: AccountGroupsContextProps): ReactElement | null => {
  const { data, isLoading } = useQuery(
    [QueryKeys.AccountGroups, organizationId],
    () => fetchAccountGroups(organizationId),
    {
      enabled: !!organizationId,
    },
  )

  return (
    <AccountGroupsContext.Provider value={{ accountGroups: data?.accountGroups, isLoading }}>
      {children}
    </AccountGroupsContext.Provider>
  )
}

export const useAccountGroups = () => {
  const context = useContext(AccountGroupsContext)

  if (!context) {
    throw new Error('AccountGroupsContextProvider is missing in the module!')
  }

  const { accountGroups = [] } = context
  const accountGroupsFiltered = useMemo(() => accountGroups.filter(({ type }) => type === 'group'), [accountGroups])

  return { accountGroups: accountGroupsFiltered }
}

export const useAccountGroupsWithSums = () => {
  const context = useContext(AccountGroupsContext)

  if (!context) {
    throw new Error('AccountGroupsContextProvider is missing in the module!')
  }

  return context
}
