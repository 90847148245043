import { Color, FontWeight, rwd, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { Input as InputComponent } from '../../../components-deprecated/Form'
import { Themable } from '../../../types/themable'

export const LineInfo = styled.div<Themable>`
  display: flex;
  flex-grow: 1;
  margin-bottom: ${Spacing.L};
  justify-content: space-between;
  font-size: 14px;
`

export const ClickOption = styled.div<Themable>`
  margin: 8px 0;
  font-weight: ${FontWeight.Medium};
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.newBlue1};
  cursor: pointer;
`

export const Input = styled(InputComponent)`
  margin: 0;
`

export const LineInfoKey = styled.p<Themable>`
  color: ${({ theme }) => theme.colors.primaryGray};
`

export const LineInfoValue = styled.p<Themable>`
  font-weight: ${FontWeight.Medium};
  color: ${Color.DeepSeaGreen};
`

export const LineInfoTotalKey = styled(LineInfoKey)<Themable>`
  font-size: 22px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.DeepSeaGreen};
`

export const LineInfoTotalValue = styled(LineInfoValue)<Themable>`
  font-size: 22px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.DeepSeaGreen};
`

export const LineInfoPlanValue = styled(LineInfoValue)<Themable>`
  font-weight: ${FontWeight.Medium};
  color: ${Color.DeepSeaGreen};
`

export const LineSeparator = styled.div<Themable>`
  height: 1px;
  margin-bottom: ${Spacing.XL};
  background-color: ${Color.Gray30};
`

export const CouponContainer = styled.div`
  display: flex;

  ${rwd.equalOrLessThan('phone')} {
    flex-direction: column;
  }
`
