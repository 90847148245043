import { PlanConfirmationModal as PlanConfirmationModalComponent, ProcessingScreen, PurchaseCard } from '@components'
import { useModal, withModalConditionalRender } from '@design-system'

import React, { ReactElement, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'
import { useSubscriptionPlanPrices } from '@views/settings/routes/OrganizationSubscriptionSettings/hooks/useSubscriptionPlanPrices'
import {
  notifySubscriptionUpdateError,
  notifySubscriptionUpdateSuccess,
} from '@views/settings/routes/OrganizationSubscriptionSettings/utils'

import { queryClient } from '../../../../config/queryClient'
import { ModalId } from '../../../../enums/modalId'
import { QueryKeys } from '../../../../enums/queryKeys'
import { useSegment } from '../../../../hooks'
import { useAnnualReportPurchasing } from '../../contexts/annualReportPurchasingContext'
import { AnnualReportType } from '../../enums/annualReportType'
import { getARTypeFeatures } from '../../utils/getARTypeFeatures'

const PlanConfirmationModal = withModalConditionalRender(PlanConfirmationModalComponent)

const ANNUAL_REPORT_TRACKING_CONTEXT = 'annual_report'

export const CompletePlanCard = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const [confirmPrice, setConfirmPrice] = useState<number>(0)
  const [recurringPrice, setRecurringPrice] = useState<number>(0)
  const [showProcessingScreen, setShowProcessingScreen] = useState(false)
  const { onEditSubscription } = useAnnualReportPurchasing()
  const { open: openPlanConfirmationModal, close: closeConfirmationModal } = useModal(ModalId.PlanConfirmationModal)
  const { monthlyPrice, isLoading: isLoadingPrice } = useSubscriptionPlanPrices(SubscriptionPlan.Complete)

  const handleBuyButtonClick = useCallback(() => {
    openPlanConfirmationModal()
  }, [openPlanConfirmationModal])

  const handlePlanConfirm = () => {
    setShowProcessingScreen(true)
    onEditSubscription({
      detail: {
        plan: SubscriptionPlan.Complete,
        period: SubscriptionPeriod.Monthly,
        price: confirmPrice,
        callback: (error) => {
          queryClient.invalidateQueries(QueryKeys.OrganizationSubscription)
          queryClient.invalidateQueries(QueryKeys.OrganizationSubscriptionInfo)
          closeConfirmationModal()
          setShowProcessingScreen(false)

          track('Subscription Order Summary Completed (FE)', {
            context: ANNUAL_REPORT_TRACKING_CONTEXT,
            plan: SubscriptionPlan.Complete,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            plan_period: SubscriptionPeriod.Monthly,
          })

          if (error) {
            notifySubscriptionUpdateError(t)
          } else {
            notifySubscriptionUpdateSuccess(t)
          }
        },
      },
    })
  }

  const handlePriceChange = useCallback((confirmPrice: number, recurringPrice: number) => {
    setConfirmPrice(confirmPrice)
    setRecurringPrice(recurringPrice)
  }, [])

  return (
    <PurchaseCard highlight={{ text: t('annual_report.package.all_inclusive'), variant: 'success' }}>
      <PurchaseCard.Header
        description={
          <Trans i18nKey="annual_report.package.complete.description">
            Complete care with an
            <br />
            accountant
          </Trans>
        }
        title={t('annual_report.package.complete.title')}
      />
      <PurchaseCard.Button onClick={handleBuyButtonClick}>{t('annual_report.package.buy')}</PurchaseCard.Button>
      <PurchaseCard.PriceSection
        isLoading={isLoadingPrice}
        price={monthlyPrice}
        priceSuffix={t('annual_report.package.price_suffix.monthly')}
      />
      <PurchaseCard.Features
        footer={t('annual_report.package.complete.footer')}
        header={t('annual_report.package.included')}
        items={getARTypeFeatures(AnnualReportType.CompletePlan)}
      />
      {showProcessingScreen && (
        <ProcessingScreen>{t('organization_subscription.subscription_update.in_progress')}</ProcessingScreen>
      )}
      <PlanConfirmationModal
        id={ModalId.PlanConfirmationModal}
        onConfirm={handlePlanConfirm}
        onPriceChange={handlePriceChange}
        planText={t(`organization_subscription.${SubscriptionPlan.Complete}`)}
        price={confirmPrice}
        recurringPrice={recurringPrice}
        subscriptionPeriod={SubscriptionPeriod.Monthly}
        subscriptionPlan={SubscriptionPlan.Complete}
      />
    </PurchaseCard>
  )
}
