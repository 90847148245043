import { Button, FormItemsGroup, Input, Modal, Space } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { useEInvoiceContext } from '../../context/eInvoiceContext'
import { useRegisterInternationallyForm } from './hooks/useRegisterInternationallyForm'

export const RegisterInternationallyFormModal = (): ReactElement => {
  const { t } = useTranslation()
  const { Form, FormItem, submitForm } = useRegisterInternationallyForm()
  const { isPeppolRegistrationLoading } = useEInvoiceContext()

  const handleSubmitButtonClick = useCallback(() => {
    submitForm()
  }, [submitForm])

  return (
    <Modal id={ModalId.RegisterInternationallyFormModal} size="s">
      <Modal.Header title={t('modal.register_internationally_form.title')}></Modal.Header>
      <Modal.Body>
        <Trans i18nKey="modal.register_internationally_form.body">
          Before you can send e-invoices to a non-danish customers, you need to register your company in the
          international e-invoice network called PEPPOL. Fortunately, you only need to do this once 😀
          <Space />
          Simply submit your information below and then you will receive an e-mail from our e-invoice partner called
          Sproom with a link to sign the application with MitID.
        </Trans>
        <Space />
        <Form name="register-internationally-form">
          <FormItemsGroup>
            <FormItem
              label={t('modal.register_internationally_form.name')}
              name="name"
              render={({ field }) => <Input {...field} />}
            ></FormItem>
            <FormItem
              label={t('modal.register_internationally_form.cvr')}
              name="cvr"
              render={({ field }) => <Input {...field} />}
            />
            <FormItem
              label={t('modal.register_internationally_form.email')}
              name="email"
              render={({ field }) => <Input {...field} />}
            />
          </FormItemsGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmitButtonClick} loading={isPeppolRegistrationLoading}>
          {t('modal.register_internationally_form.register_now')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
