import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'

import { AccountStatementsSideActions } from './elements/AccountStatementsSideActions'
import { AccountStatementsTable } from './elements/AccountStatementsTable'
import { AccountStatementsTableActions } from './elements/AccountStatementsTableActions'
import { AccountStatementsTitle } from './elements/AccountStatementsTitle'

export const AccountStatements = (): ReactElement => (
  <ModuleLayout title={<AccountStatementsTitle />} sideActions={<AccountStatementsSideActions />}>
    <AccountStatementsTableActions />
    <AccountStatementsTable />
  </ModuleLayout>
)
