import { SkeletonText as SkeletonTextComponent, Text as TextComponent } from '@design-system'

import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Text = styled(TextComponent)`
  margin: 0 5px 7px 0;
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

export const SkeletonText = styled(SkeletonTextComponent)`
  margin-bottom: 7px;
`
