import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const AvatarImageWrapper = styled.figure`
  width: 100%;
  height: 100%;
  border-radius: ${DefaultValue.BorderRadius};
  background-color: ${Color.White};
  padding: ${Spacing.XXS};
`

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${DefaultValue.BorderRadius};
  object-fit: cover;
`
