import { rwd, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const MenuHeader = styled.header`
  display: flex;
  margin-bottom: ${Spacing.XL};
  align-items: center;
  justify-content: center;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const BadgeWrapper = styled.div`
  margin-left: ${Spacing.S};
`

export const CloseButtonWrapper = styled.div`
  cursor: pointer;
  margin-left: auto;

  ${rwd.greaterThan('xlarge')} {
    display: none;
  }
`
