import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'

import { store } from '@modules-deprecated'
import { AccountGroupsContextProvider } from '@modules-deprecated/app/accounts'
import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { EditAccountAction } from '@views/settings/routes/OrganizationChartOfAccounts/elements/EditAccountAction'

import { queryClient } from '../../config/queryClient'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

class AccountEditorWebComponent extends DOMModel {
  @byAttrVal accountId: string
  @byAttrVal groupId: string
  @byAttrVal organizationId: string
  @byAttrVal text: string
  @byAttrVal isLinkButton: boolean
  @registerEvent('onAccountSave') onAccountSave: EmberEventFn
}

type AccountEditorProps = {
  accountId: string
  organizationId: string
  groupId: string
  text: string
  isLinkButton: boolean
  onAccountSave: EmberEventFn
}

function AccountEditorModule({ organizationId, ...rest }: AccountEditorProps): ReactElement {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <AccountGroupsContextProvider organizationId={organizationId}>
              <EditAccountAction {...rest} />
            </AccountGroupsContextProvider>
          </UserOrganizationContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </ReduxProvider>
  )
}

const AccountEditorCustomElement = createCustomElement(AccountEditorModule, AccountEditorWebComponent, 'element')

customElements.get('account-editor-module') ||
  customElements.define('account-editor-module', AccountEditorCustomElement)

export default AccountEditorCustomElement
