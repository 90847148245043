import React from 'react'
import { Box } from 'rebass'

import { IconProps } from './Icon'

export const BillyFace = ({ width, height, ...rest }: IconProps) => {
  return (
    <Box as="i" {...rest}>
      <svg width={width || 42} height={height || 42} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1691.4 1746">
        <path
          d="M1691.4,475.7l-42.9,455.6c-24.2-205.2-138.8-447.6-289.2-575.1,4.4-7.2-2.9-55.9,3.4-61.7Z"
          transform="translate(0 0)"
          fill="#725e50"
        ></path>
        <path
          d="M1648.5,946.1c3-441.7-357.5-813.7-805.6-811.8-453.5,2-808.7,370.9-805.7,812.6,3,437.4,360.3,799.1,805.7,799.1S1645.5,1385,1648.5,946.1Z"
          transform="translate(0 0)"
          fill="#ffd4cc"
        ></path>
        <path
          d="M828.4,1425.1c-199.2,0-335-74.6-337.1-75.8l14.2-25.3c3.1,1.7,310.2,169.8,675.8-13l13,26C1061.3,1403.4,935.6,1425.1,828.4,1425.1Z"
          transform="translate(0 0)"
          fill="#595959"
        ></path>
        <path
          d="M313.8,1110.8l-82.6-59.7L210.9,932.8l8.3-122.4,45.9-79s117.3-38,128.4-35.2,227.8,17.6,227.8,17.6l129.1,60.5s11.9,49.2,11.9,60.3-46.6,135.9-46.6,135.9L683,1033.4s-30.4,44.8-41.5,50.3-50.5,22.4-75.5,25.2-85.8,5.3-96.9,2.5-80.6,5-80.6,5Z"
          transform="translate(0 0)"
          fill="#fff"
        ></path>
        <path
          d="M1411.8,1090.1l46.6-47.8,42.8-119.6,13.7-119.8-72.3-58.4s-77.9-33.4-89-30.6-242,2-242,2L961.3,751.6s-5.4,70.8-5.4,81.9,12.9,147.2,12.9,147.2l67.7,58.6s33.4,41.7,44.5,47.3,36.2,30.6,61.2,33.4,105.7,5.6,116.8,2.8,74.8-6.1,74.8-6.1Z"
          transform="translate(0 0)"
          fill="#fff"
        ></path>
        <circle cx="489.9" cy="880.2" r="70.1" fill="#595959"></circle>
        <circle cx="1194.8" cy="880.2" r="70.1" fill="#595959"></circle>
        <path
          d="M951.3,715.2h0s-34.3,16.1-80.7,16.1c0,0-54.1.2-54.1.5-39.3-.7-61-7.1-71.2-11.3-225.8-115.7-633.4-18-633.4-18l.8,83.3c40.6,19.4,52.2,130.4,53.9,133.9,31.7,223.3,144.8,213.7,287.9,213.7,244.5,0,305.7-162.2,317.9-279.7,8.7-38.2,39-42.5,51-42.3h42.2c15.9,0,41.4,10.4,48.1,45.4h.1c13,117.2,75.3,276.6,317.6,276.6,143.1,0,256.2,9.6,287.9-213.7,1.8-3.5,13.3-114.5,53.9-133.9l.8-83.3C1574,702.4,1179.1,607.8,951.3,715.2ZM417.4,1096.3s-157.9,10.6-184.5-106.8c-24.7-106-40.4-233.5,103.3-261.4,59.3-10.7,388.6-35.3,391.2,93.6C721.9,958.2,677.4,1096.3,417.4,1096.3ZM1453,989.4c-26.7,117.5-184.5,106.8-184.5,106.8-260,0-304.5-138.1-310-274.6,2.6-128.9,331.9-104.3,391.2-93.6C1493.4,756,1477.7,883.5,1453,989.4Z"
          transform="translate(0 0)"
          fill="#595959"
        ></path>
        <polygon
          points="109 310 218.4 310 218.4 209.6 293.4 284.7 322 313.2 209.5 410.5 109 310"
          fill="#735f50"
        ></polygon>
        <path
          d="M1458.1,242.8H1326.5L1592,139.7S1162.2,0,984.3,0c-83,0-264.6,82-434.7,169.6C491.8,199.3,0,475.5,0,475.5S36.3,905.8,38.5,903c10-185.7,83.1-355.8,199.4-486.6C310.9,341,494.7,285,595.3,282c336.3,335.3,627.3,178.2,769.6,56.4C1365.5,338,1458.1,242.8,1458.1,242.8Z"
          transform="translate(0 0)"
          fill="#725e50"
        ></path>
      </svg>
    </Box>
  )
}
