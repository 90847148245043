import { TableHoverActions } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useCopyAndNotify } from '../../../../../../hooks/useCopyAndNotify'

interface CopyBalanceHoverActionProps {
  balance: number
}

export const CopyBalanceHoverAction = ({ balance }: CopyBalanceHoverActionProps): ReactElement => {
  const { t } = useTranslation()
  const { copy } = useCopyAndNotify()

  const handleCopyBalanceClick = useCallback(() => {
    copy(balance.toString())
  }, [balance, copy])

  return (
    <TableHoverActions.Item
      icon="twoSquares"
      label={t('account_statements.copy_balance')}
      onClick={handleCopyBalanceClick}
    />
  )
}
