import { useModal } from '@design-system'

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { fetchBankConnections } from '@modules-deprecated/bankReconciliation/query-api'
import { BankConnection } from '@modules-deprecated/bankReconciliation/types'

import { ModalId } from '../../../enums/modalId'
import { QueryKeys } from '../../../enums/queryKeys'
import { Scope } from '../../../enums/scope'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { isAuthorized } from '../../../utils/isAuthorized'

export const useBankConnections = () => {
  const [queryParams] = useQueryParams({
    defaultQuery: { success: undefined, connectionId: undefined },
    withDefaultQueryInUrl: false,
  })
  const { organization } = useUserOrganization()
  const { open: openErrorModal } = useModal(ModalId.BankConnectionErrorModal)

  const canUseBankIntegration = isAuthorized(Scope.BankSyncRead)

  const {
    data: bankConnections,
    isLoading,
    refetch,
  } = useQuery([QueryKeys.BankConnections], () => fetchBankConnections(organization?.id as string), {
    enabled: !!organization?.id && canUseBankIntegration,
    onError: openErrorModal,
  })

  const filteredBankConnections: BankConnection[] | undefined = useMemo(
    () =>
      queryParams.connectionId &&
      bankConnections?.length &&
      bankConnections?.filter((bankConnection) => {
        if (bankConnection.externalAccountId) {
          return false
        }
        return bankConnection.connection.id === queryParams.connectionId
      }),
    [bankConnections, queryParams.connectionId],
  )

  return {
    bankConnections,
    filteredBankConnections,
    isLoading,
    refetch,
  }
}
