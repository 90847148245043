import { useEffect } from 'react'

export const useMousePress = (
  callback: (event: MouseEvent) => void,
  dependencies: any[] = [],
  el: HTMLElement = document.body,
  enabled = true,
) => {
  useEffect(() => {
    if (enabled) {
      el.addEventListener('mousedown', callback)
    } else {
      el.removeEventListener('mousedown', callback)
    }

    return () => {
      el.removeEventListener('mousedown', callback)
    }
  }, [...dependencies, callback, el, enabled])
}
