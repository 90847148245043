import { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface TableActionsWrapperProps {
  children: ReactNode
}

export const TableActionsWrapper = ({ children }: TableActionsWrapperProps): ReactElement => {
  return <Styled.TableActionsWrapper>{children}</Styled.TableActionsWrapper>
}
