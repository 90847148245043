import { BorderRadius } from '../../../enums/borderRadius'
import { Spacing } from '../../../enums/spacing'
import { Size } from '../types/size'

interface ControlProperties {
  dimension: number
  offsetTop: Spacing
  iconScale: number
  radius: BorderRadius
}

export const sizeToProperties: Record<Size, ControlProperties> = {
  m: { dimension: 16, offsetTop: Spacing.XXS, iconScale: 1, radius: BorderRadius.XS },
  l: { dimension: 20, offsetTop: Spacing.XXXS, iconScale: 1.2, radius: BorderRadius.S },
}
