import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const SubHeader = styled(Text)`
  margin-bottom: ${Spacing.XXXL};
`

export const PlansWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
