import styled from '@emotion/styled'

export const BillsListItemContentWrapper = styled.div`
  width: 100%;
`

export const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 120px 70px 300px;
`
