export const handleFrodaMessage = (event: MessageEvent, frodaHomeUrl: string) => {
  if (event.origin !== frodaHomeUrl) {
    return // Ignore messages from other origins for security
  }

  const { name, value } = event.data
  if (name === 'loginUrl' && value) {
    window.location.replace(value)
  }
}
