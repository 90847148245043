import { useMutation } from 'react-query'

import { APIError } from '../../../utils'
import { createBillTimeline, CreateBillTimelinePayload, GetBillTimelineResponseData } from '../query-api'

interface UseAddBillTimelineProps {
  onError?: (error?: APIError, variables?: CreateBillTimelinePayload, context?: GetBillTimelineResponseData) => void
  onSuccess?: () => void
  onSettled?: (data: void | undefined, error: APIError | null | undefined) => void
  onMutate?: (
    variables: CreateBillTimelinePayload,
  ) => GetBillTimelineResponseData | Promise<GetBillTimelineResponseData>
}

export const useAddBillTimeline = (props?: UseAddBillTimelineProps) => {
  const { onError, onSuccess, onSettled, onMutate } = props || {}
  const { mutate: create, ...rest } = useMutation(createBillTimeline, {
    onSuccess,
    onError,
    onSettled,
    onMutate,
  })

  return { create, ...rest }
}
