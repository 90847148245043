import { DefaultValue } from '../enums/defaultValue'
import { AnimatableCssProperty } from '../types/animatableCssProperty'

export function getDefaultTransition(...properties: AnimatableCssProperty[]): string {
  return `
    transition-property: ${properties};
    transition-duration: ${DefaultValue.TransitionDuration};
    transition-timing-function: ${DefaultValue.TransitionTimingFunction};
  `
}
