import { InvoiceSendingState } from '@views/invoices'

export const sendingStateToTitleTKey: Record<InvoiceSendingState, string> = {
  created: 'einvoce.status.title.created',
  deleted: 'einvoce.status.title.deleted',
  failed: 'einvoce.status.title.failed',
  internalcancel: 'einvoce.status.title.cancelled',
  pending: 'einvoce.status.title.created',
  received: 'einvoce.status.title.received',
  sent: 'einvoce.status.title.sent',
  signedoff: 'einvoce.status.title.signedoff',
  voided: 'einvoce.status.title.voided',
}
