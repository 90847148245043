import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { updateUserRole, UpdateUserRoleResponseData } from '../query-api'

interface UseCreateOrganizationProps {
  onError?: (error?: APIError) => void
  onSettled?: (data: UpdateUserRoleResponseData | undefined, error: APIError | null | undefined) => void
  onSuccess?: (response: UpdateUserRoleResponseData) => void
}

export const useUpdateUserRole = (props?: UseCreateOrganizationProps) => {
  const { onError, onSuccess, onSettled } = props || {}

  const { mutate: update, ...rest } = useMutation(updateUserRole, {
    onSuccess,
    onSettled,
    onError,
  })

  return { update, ...rest }
}
