import find from 'lodash/find'
import React, { memo, useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Account } from '@modules-deprecated/app/accounts/types'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { EmberEventFn } from '../../../../../types/emberEventFn'
import { AccountsGroupsSet } from '../types'
import { ChartOfAccountsListTable } from './ChartOfAccountsListTable'
import { DeleteAccountModal } from './DeleteAccountModal'
import { EditAccountModal } from './EditAccountModal'
import { getEditAccountAllowedFields } from './EditAccountModal/utils/getEditAccountAllowedFields'

interface ChartOfAccountsListProps {
  accountGroupsSet: AccountsGroupsSet
  isLoading: boolean
  onReloadAccounts: EmberEventFn
}

export const ChartOfAccountsList = memo(
  ({ accountGroupsSet, isLoading, onReloadAccounts }: ChartOfAccountsListProps) => {
    const { groups, accountsInGroups } = accountGroupsSet
    const queryClient = useQueryClient()
    const [deletingAccountId, setDeletingAccountId] = useState<string>()
    const [editingAccount, setEditingAccount] = useState<Account>()
    const [isEditingAccountPredefined, setIsEditingAccountPredefined] = useState(false)

    const handleEditAccount = useCallback((account: Account) => {
      setEditingAccount(account)
    }, [])

    const handleEditPredefinedAccount = useCallback((account: Account) => {
      setIsEditingAccountPredefined(true)
      setEditingAccount(account)
    }, [])

    const handleModalSaved = useCallback(() => {
      queryClient.invalidateQueries(QueryKeys.Accounts)
      onReloadAccounts({ detail: null })
    }, [onReloadAccounts, queryClient])

    const handleDelete = useCallback((accountId: string) => {
      setDeletingAccountId(accountId)
    }, [])

    const getAccountGroup = useCallback(
      (account?: Account) => {
        if (!account) {
          return undefined
        }

        const { id } = account

        let foundGroupId: string | undefined

        for (const groupId in accountsInGroups) {
          if (find(accountsInGroups[groupId], ({ id: accId }) => accId === id)) {
            foundGroupId = groupId
            break
          }
        }

        if (!foundGroupId) {
          return undefined
        }

        return find(groups, ({ id }) => foundGroupId === id)
      },
      [accountsInGroups, groups],
    )

    const handleDeleteAccountModalClose = useCallback(() => {
      setDeletingAccountId(undefined)
    }, [])

    const handleAddAccountModalClose = useCallback(() => {
      setEditingAccount(undefined)
      setIsEditingAccountPredefined(false)
    }, [])

    return (
      <>
        <ChartOfAccountsListTable
          groups={groups}
          accountsInGroups={accountsInGroups}
          isLoading={isLoading}
          onEditAccount={handleEditAccount}
          onEditPredefinedAccount={handleEditPredefinedAccount}
          onDeleteAccount={handleDelete}
        />
        <DeleteAccountModal
          accountId={deletingAccountId}
          onClose={handleDeleteAccountModalClose}
          onSaved={handleModalSaved}
        />
        <EditAccountModal
          isOpen={!!editingAccount}
          account={editingAccount}
          group={editingAccount ? getAccountGroup(editingAccount) : undefined}
          onClose={handleAddAccountModalClose}
          onSave={handleModalSaved}
          allowedFields={
            isEditingAccountPredefined ? getEditAccountAllowedFields(!!editingAccount?.predefinedAccount) : []
          }
        />
      </>
    )
  },
)
