import { Text, useTheme } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'
import { SimpleCheckListAlignment } from './types/SimpleCheckListAlignment'

export interface TranslatedItem {
  tKey: string
  isIncluded?: boolean
}

interface SimpleCheckListProps {
  items: TranslatedItem[]
  grid?: boolean
  showNotIncluded?: boolean
  condensed?: boolean
  alignment?: SimpleCheckListAlignment
}

export const SimpleCheckList = ({ grid, items, condensed, alignment = 'center' }: SimpleCheckListProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Styled.SimpleChecklistWrapper grid={grid} alignment={alignment}>
      {items
        .filter((item) => item.isIncluded !== false)
        .map((item) => (
          <Styled.ListItem key={item.tKey} condensed={condensed}>
            <Styled.ListIcon icon="check" color={theme.ds.colors.base.textPrimary} />
            <Text variant="normal" alignment="left">
              {t(item.tKey)}
            </Text>
          </Styled.ListItem>
        ))}
    </Styled.SimpleChecklistWrapper>
  )
}
