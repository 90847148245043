import { Badge } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceStatus } from '../../../../enums/invoiceStatus'
import { invoiceStatusToBadgeVariant } from '../../../../routes/InvoicesList/constants/invoiceStatusToBadgeVariant'
import { invoiceStatusToTitleKey } from '../../../../routes/InvoicesList/constants/invoiceStatusToTitleKey'

interface InvoicePaymentPanePrefixProps {
  status: InvoiceStatus
}

export const InvoicePaymentPanePrefix = ({ status }: InvoicePaymentPanePrefixProps): ReactElement => {
  const { t } = useTranslation()

  const badgeVariant = invoiceStatusToBadgeVariant[status]
  const badgeLabel = t(invoiceStatusToTitleKey[status])

  return <Badge variant={badgeVariant}>{badgeLabel}</Badge>
}
