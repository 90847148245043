import { getRegion } from '../../../../utils/getRegion'
import { BookkeepingTagQuestionResponseResult } from '../types/bookkeepingTag'
import { BookkeepingTagNavItemValue } from '../types/bookkeepingTagNavItemValue'

const DEFAULT_COUNTRY_ID = 'DK'

export const getAnswerFromBookkeepingTagValue = (
  bookkeepingTagValue?: BookkeepingTagNavItemValue,
  countryId?: string,
): BookkeepingTagQuestionResponseResult | undefined => {
  const contactCountryId = countryId || DEFAULT_COUNTRY_ID
  const region = getRegion(contactCountryId)

  return bookkeepingTagValue?.response?.result.find((result) => result.region === region && !result.isAsset)
}
