import { NavItem, NavItemAccessor } from '@design-system'

import { TFunction } from 'i18next'

import { ComparisonValue } from '../../../types/comparisonValue'

export const getCompareToMonthItems = (t: TFunction): NavItem<ComparisonValue>[] | undefined => [
  {
    id: 'internal_changes',
    children: t('reports.comparison_select.changes'),
    value: 'internal_changes',
  },
  {
    id: 'separator',
    accessor: NavItemAccessor.Separator,
    children: '',
    value: '' as ComparisonValue,
  },
  {
    id: 'month',
    children: t('reports.comparison_select.previous_month'),
    value: 'month',
  },
  {
    id: 'quarter',
    children: t('reports.comparison_select.previous_quarter'),
    value: 'quarter',
  },
  {
    id: 'year',
    children: t('reports.comparison_select.previous_year'),
    value: 'year',
  },
]
