import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { DownloadExport } from './utils/downloadExport'

export const useDownloadExport = () => {
  const { t } = useTranslation()

  const download = useCallback(
    (url: string, filename?: string) => {
      const downloadExport = new DownloadExport(url, t, filename)
      downloadExport.init()
    },
    [t],
  )

  return { download }
}
