import { useLocalStorage } from '@rehooks/local-storage'

import { LocalStorageKeys } from '../../../../enums/localStorageKeys'
import { toggleBookkeepingHero } from '../utils/toggleBookkeepingHero'
import { toggleIntercomChat } from '../utils/toggleIntercomChat'

type BooleanStringified = 'true' | 'false'

export const useResetChatStates = () => {
  const [hideIntercomMessenger] = useLocalStorage<BooleanStringified>(LocalStorageKeys.HideIntercomMessenger)
  const [hideBookkeepingHero] = useLocalStorage<BooleanStringified>(LocalStorageKeys.HideBookkeepingHero)

  return () => {
    toggleIntercomChat(hideIntercomMessenger === 'true')
    toggleBookkeepingHero(hideBookkeepingHero === 'true')
  }
}
