import { AmountInfoBox, AmountInfoBoxesGroup } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useSalaryStatistics } from '../../hooks/useSalaryStatistics'
import * as Styled from './styles'

export const SalarySummary = (): ReactElement => {
  const { t } = useTranslation()
  const { statistics, isLoading } = useSalaryStatistics()

  const { averageTransfersLastYear = 0, totalTransfersThisYear = 0, employeeCount = 0 } = statistics || {}

  return (
    <Styled.SalarySummaryWrapper>
      <AmountInfoBoxesGroup loading={isLoading}>
        <AmountInfoBox
          badgeVariant="primary"
          badgeLabel={t('salary.summary.total_transfers')}
          amount={totalTransfersThisYear}
          currencyId="DKK"
        />
        <AmountInfoBox
          badgeVariant="success"
          badgeLabel={t('salary.summary.avarage_transfers')}
          amount={averageTransfersLastYear}
          currencyId="DKK"
        />
        <AmountInfoBox badgeVariant="warning" badgeLabel={t('salary.summary.employee_count')} amount={employeeCount} />
      </AmountInfoBoxesGroup>
    </Styled.SalarySummaryWrapper>
  )
}
