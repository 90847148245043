import { useMemo } from 'react'
import { useLocalStorage } from 'react-use'

import { LabsFlag } from '../enums/labsFlag'

export type LabsMenuFlags = Record<LabsFlag, boolean>

export const useLabsMenuFlags = (): LabsMenuFlags => {
  const [bankReconciliationFlag] = useLocalStorage(LabsFlag.ReactBankReconciliation, 0)
  const [reactBillsFlag] = useLocalStorage(LabsFlag.ReactBills, 0)
  const [salesTaxReturnSkatConnectionFlag] = useLocalStorage(LabsFlag.SalesTaxReturnWithSkatConnection, 0)

  return useMemo(
    () => ({
      [LabsFlag.ReactBankReconciliation]: !!bankReconciliationFlag,
      [LabsFlag.ReactBills]: !!reactBillsFlag,
      [LabsFlag.SalesTaxReturnWithSkatConnection]: !!salesTaxReturnSkatConnectionFlag,
    }),
    [bankReconciliationFlag, reactBillsFlag, salesTaxReturnSkatConnectionFlag],
  )
}
