import { Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

export const IntegrationsHeader = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.IntegrationsHeaderWrapper>
      <Text>{t('integrations.header.connect_billy')}</Text>
    </Styled.IntegrationsHeaderWrapper>
  )
}
