import React, { ReactElement, ReactNode } from 'react'

import { InputSize } from '../../types/size'
import * as Styled from './styles'

export interface InputPrefixSuffixProps {
  children: ReactNode
  disabled?: boolean
  inputSize: InputSize
  onClick?: () => void
}

export const InputPrefixSuffix = ({
  children,
  disabled = false,
  inputSize,
  onClick,
  ...rest
}: InputPrefixSuffixProps): ReactElement => (
  <Styled.InputPrefixSuffixWrapper disabled={disabled} inputSize={inputSize} {...rest}>
    {children}
  </Styled.InputPrefixSuffixWrapper>
)
