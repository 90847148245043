import { Space, Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface PurchaseCardHeaderProps {
  description: ReactNode
  title: ReactNode
}

export const PurchaseCardHeader = ({ description, title }: PurchaseCardHeaderProps): ReactElement => (
  <Styled.HeaderContainer flexDirection="column" alignItems="center">
    <Text variant="displayXS" alignment="center">
      {title}
    </Text>
    <Space size="s" />
    <Text alignment="center">{description}</Text>
  </Styled.HeaderContainer>
)
