import { ItemsPerPage } from '@design-system'

import { useCallback, useState } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useFormContext } from '../../../../../hooks/useForm'
import { formatDate } from '../../../../../utils'
import { BillState } from '../../../enums/billState'
import { BillType } from '../../../enums/billType'
import { fetchBills } from '../../../query-api'
import { Bill } from '../../../types/bill'
import { BillsQueryProps } from '../../../types/billsQueryProps'
import { getAmountsFromBillLines } from '../../../utils/getAmountsFromBillLines'
import { BillFormSchema } from '../utils/formData'

interface UseBillDuplicatesCheckResponse {
  checkForDuplicateBills: () => Promise<Bill[]>
  isChecking: boolean
}

export const useBillDuplicatesCheck = (): UseBillDuplicatesCheckResponse => {
  const [isChecking, setIsChecking] = useState(false)
  const { organization } = useUserOrganization()
  const { getValues } = useFormContext<BillFormSchema>()

  const checkForDuplicateBills = useCallback(async () => {
    const { billDate, billLines, currencyId, vendor, creditedBillId } = getValues()
    const { inclVatAmountTotal } = getAmountsFromBillLines(billLines)

    if (billDate && currencyId && inclVatAmountTotal && vendor) {
      const formattedDate = formatDate(billDate)
      const isCreditNote = !!creditedBillId

      const queryProps: BillsQueryProps = {
        contactId: vendor.id,
        currencyId,
        entryDatePeriod: `dates:${formattedDate}...${formattedDate}`,
        organizationId: organization?.id || '',
        page: 1,
        pageSize: 4 as ItemsPerPage,
        state: BillState.Approved,
        grossAmount: inclVatAmountTotal,
      }

      setIsChecking(true)
      const { bills } = await fetchBills(queryProps)
      const filteredBills = isCreditNote
        ? bills.filter((bill) => bill.type === BillType.CreditNote)
        : bills.filter((bill) => bill.type === BillType.Bill)
      setIsChecking(false)
      return filteredBills
    }
    return []
  }, [getValues, organization])

  return {
    checkForDuplicateBills,
    isChecking,
  }
}
