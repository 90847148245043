import styled from '@emotion/styled'

import { Themable } from '../../types/themable'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { getShadow } from '../../utils/getShadow'
import { zIndex } from '../../utils/zIndex'

interface SidePanelWrapperProps extends Themable {
  isOpen?: boolean
}

export const SidePanelWrapper = styled.div<SidePanelWrapperProps>`
  display: flex;
  position: fixed;
  width: 387px;
  height: 100vh;
  flex-direction: column;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.ds.colors.base.background};
  z-index: ${zIndex.SidePanel};
  will-change: transform;
  transform: ${({ isOpen }) => `translateX(${isOpen ? 0 : '100%'})`};
  ${getShadow('raisedStrong')};
  ${getDefaultTransition('transform')};
`
