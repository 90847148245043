interface Feature {
  tKey: string
}

export const featuresList: Feature[] = [
  {
    tKey: 'salary.signup.feature.a',
  },
  {
    tKey: 'salary.signup.feature.b',
  },
  {
    tKey: 'salary.signup.feature.c',
  },
  {
    tKey: 'salary.signup.feature.d',
  },
  {
    tKey: 'salary.signup.feature.e',
  },
  {
    tKey: 'salary.signup.feature.f',
  },
  {
    tKey: 'salary.signup.feature.g',
  },
  {
    tKey: 'salary.signup.feature.h',
  },
]
