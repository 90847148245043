import { useCallback, useEffect, useRef, useState } from 'react'

export const useActivateDropzone = (wrapperElement?: HTMLElement) => {
  const isMouseDownOnBodyRef = useRef(false)
  const [isActive, setIsActive] = useState(false)

  const handleMouseUp = useCallback(() => {
    isMouseDownOnBodyRef.current = false
    document.body.removeEventListener('mouseup', handleMouseUp)
  }, [])

  const handleMouseDown = useCallback(() => {
    isMouseDownOnBodyRef.current = true
    document.body.addEventListener('mouseup', handleMouseUp)
  }, [handleMouseUp])

  const enable = useCallback(() => {
    if (isMouseDownOnBodyRef.current) {
      return
    }

    setIsActive(true)
  }, [])

  const disable = useCallback(() => {
    setIsActive(false)
  }, [])

  useEffect(() => {
    document.body.addEventListener('mousedown', handleMouseDown)

    if (wrapperElement) {
      if (!isActive) {
        document.body.addEventListener('dragenter', enable)
        wrapperElement.removeEventListener('dragleave', disable)
        wrapperElement.removeEventListener('drop', disable)
      } else {
        document.body.removeEventListener('dragenter', enable)
        wrapperElement.addEventListener('dragleave', disable)
        wrapperElement.addEventListener('drop', disable)
      }
    }

    return () => {
      document.body.removeEventListener('mousedown', handleMouseDown)
      document.body.removeEventListener('mouseup', handleMouseUp)
      document.body.removeEventListener('dragenter', enable)

      if (wrapperElement) {
        wrapperElement.removeEventListener('dragleave', disable)
        wrapperElement.removeEventListener('drop', disable)
      }
    }
  }, [disable, enable, handleMouseDown, handleMouseUp, isActive, wrapperElement])

  return isActive
}
