import { FormItemsGroup, Input, InputPassword, SectionMessage, Space, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../hooks'
import { DeleteTrialDataFormSchema } from '../../utils/formData'

export const ModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<DeleteTrialDataFormSchema>()

  return (
    <>
      <SectionMessage variant="error">{t('delete_trial_data_modal.warning')}</SectionMessage>
      <Space />
      <Text>{t('delete_trial_data_modal.confirmation')}</Text>
      <Space />
      <FormItemsGroup>
        <FormItem
          name="password"
          label={t('delete_trial_data_modal.password.label')}
          render={({ field }) => <InputPassword {...field} />}
        />
        <FormItem
          name="matchingPhrase"
          label={t('delete_trial_data_modal.type_word_reset.label')}
          render={({ field }) => <Input {...field} />}
        />
      </FormItemsGroup>
    </>
  )
}
