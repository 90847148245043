import { TabButtonsGroup } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { SettingsRoute } from '../../enums/settingsRoute'
import { useIsUserSettingsRoute } from '../../hooks/useIsUserSettingsRoute'

export const SettingsContextSwitcher = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const currentRouteName = location.pathname
  const isUserSettingsRoute = useIsUserSettingsRoute(currentRouteName as SettingsRoute)

  return (
    <TabButtonsGroup>
      <TabButtonsGroup.Button to={SettingsRoute.Profile} selected={isUserSettingsRoute}>
        {t('settings.user.profile')}
      </TabButtonsGroup.Button>
      <TabButtonsGroup.Button to={SettingsRoute.Organization} selected={!isUserSettingsRoute}>
        {t('organization')}
      </TabButtonsGroup.Button>
    </TabButtonsGroup>
  )
}
