import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const AttachmentsNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const NavigationSummary = styled.div`
  margin-right: ${Spacing.XXS};
  margin-left: ${Spacing.XXS};
`
