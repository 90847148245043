import { useCallback, useState } from 'react'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../config/queryClient'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { useCreatePaymentMethod } from '../../../../../hooks/useCreatePaymentMethod'
import { CreatePaymentMethodResponse } from '../../../../../query-api/payment-methods-query'
import { APIError } from '../../../../../utils'
import { RECEIVABLE_ACCOUNT_SYSTEM_ROLE } from '../../../constants/receivableAccountProps'
import { getMobilePayPaymentMethodPayload } from '../utils/getMobilePayPaymentMethodPayload'
import { useCreateMobilePayAccount } from './useCreateMobilePayAccount'

interface UseCreateMobilePayPaymentMethodProps {
  onSuccess?: (mobilePayPaymentMethodId?: string) => void
  onError?: (error?: APIError) => void
}

export const useCreateMobilePayPaymentMethod = ({ onSuccess, onError }: UseCreateMobilePayPaymentMethodProps) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const { organization } = useUserOrganization()
  const { accounts = [] } = useAccounts()
  const { create: createPaymentMethod } = useCreatePaymentMethod({
    onSuccess: (responseData: CreatePaymentMethodResponse) => {
      setIsProcessing(false)
      const paymentMethodId = responseData?.data?.id

      onSuccess?.(paymentMethodId)
    },
    onError: (error?: APIError) => {
      setIsProcessing(false)
      onError?.(error)
    },
  })
  const { createMobilePayAccount } = useCreateMobilePayAccount({
    onSuccess: (mobilePayAccountId?: string) => {
      if (!organization?.id || !mobilePayAccountId) {
        return
      }

      queryClient.invalidateQueries(QueryKeys.Accounts)

      createPaymentMethod({
        payload: getMobilePayPaymentMethodPayload(mobilePayAccountId),
        organizationId: organization.id,
      })
    },
    onError: (error?: APIError) => {
      setIsProcessing(false)
      onError?.(error)
    },
  })

  const createMobilePayPaymentMethod = useCallback(
    (mobilePayAccountId: string | undefined) => {
      if (!organization?.id) {
        return
      }

      setIsProcessing(true)

      if (mobilePayAccountId) {
        createPaymentMethod({
          payload: getMobilePayPaymentMethodPayload(mobilePayAccountId),
          organizationId: organization.id,
        })
      } else {
        const receivableAccountGroupId =
          accounts.find((account) => account?.systemRole === RECEIVABLE_ACCOUNT_SYSTEM_ROLE)?.group?.id || null

        if (!receivableAccountGroupId) {
          console.error('An account with systemRole "accountsReceivable" is required to create a MobilePay account')
          return
        }

        createMobilePayAccount(receivableAccountGroupId)
      }
    },
    [accounts, createMobilePayAccount, createPaymentMethod, organization?.id],
  )

  return { createMobilePayPaymentMethod, isProcessing }
}
