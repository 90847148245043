import qs from 'qs'

import { getRequest } from '../../../utils'
import { SalesWithoutVatParams } from '../hooks/useFetchSalesWithoutVat'

export interface SalesWithoutVat {
  id: string
  name: string
  countryId: string
  registrationNo: string
  minDate: string
  maxDate: string
  V?: number
  Y?: number
}

export interface SalesApiResponse {
  result: SalesWithoutVat[]
}

export const getSalesWithoutVatList = async (
  organizationId: string,
  params: SalesWithoutVatParams,
): Promise<SalesApiResponse> => {
  return getRequest(`/v2/organizations/${organizationId}/salesWithoutVat?${qs.stringify(params)}`)
}
