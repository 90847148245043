/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Text /> from design-system instead
 */
import { format, isValid } from 'date-fns'
import numeral from 'numeral'
import React, { ReactElement } from 'react'
import { Text as RebassText, TextProps as RebassTextProps } from 'rebass'

import { useLocale } from '../../hooks/useLocale'
import { coloring } from '../../styles-deprecated'

export type TextType = 'text' | 'date' | 'currency'

export type TextProps = RebassTextProps & {
  type?: TextType
  currencyColored?: boolean
}

export const Text = ({ children = '', type = 'text', variant, currencyColored, ...rest }: TextProps): ReactElement => {
  const locale = useLocale()

  let content
  switch (type) {
    case 'date':
      content =
        typeof children === 'string' && isValid(new Date(children))
          ? format(new Date(children), 'PP', { locale })
          : 'invalid date'
      break

    case 'currency':
      content =
        (typeof children === 'string' || typeof children === 'number') && typeof +children === 'number'
          ? numeral(children).format('0,0.00')
          : 'invalid amount'
      break

    case 'text':
    default:
      content = children
  }

  const getColor = () => {
    const amount = numeral(children).value()

    return amount === null || amount > 0 ? coloring('primaryGreen') : coloring('primaryRed')
  }

  if (type === 'text' && typeof children === 'string') {
    const paragraphs = children.split('\n').filter(Boolean)

    return (
      <>
        {paragraphs.map((text, index) => (
          <RebassText key={index} as="p" variant={variant || 'body1'} mt={index > 0 ? '24px' : undefined} {...rest}>
            {text}
          </RebassText>
        ))}
      </>
    )
  }

  if (type === 'currency' && currencyColored) {
    return (
      <RebassText as="p" variant={variant || 'body1'} {...rest} color={getColor()}>
        {content}
      </RebassText>
    )
  }

  return (
    <RebassText as="p" variant={variant || 'body1'} {...rest}>
      {content}
    </RebassText>
  )
}
