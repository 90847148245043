import styled from '@emotion/styled'

import { LineHeight } from '../../../../../../enums/lineHeight'
import { Text } from '../../../../../Text'

export const TableCellContent = styled(Text)`
  width: 100%;
`

export const TableSkeletonCellContent = styled.div`
  display: flex;
  width: 100%;
  min-height: ${LineHeight.TextNormal};
  align-items: center;
`
