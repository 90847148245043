import { Icon, IconName, Tab } from '@design-system'

import React, { ReactNode, useCallback, useMemo, useState } from 'react'

import { ActionData } from './ActionGroup'

export interface ActionGroupItemProps {
  children: ReactNode
  onClick?: (data?: ActionData) => void
  selected?: boolean
  sortable?: boolean
  text?: string
  value: string
}

export const ActionGroupItem = ({ children, onClick, selected, sortable, text = '', value }: ActionGroupItemProps) => {
  const [sortDirection, setSortDirection] = useState(1)

  const handleClick = useCallback(() => {
    if (!onClick) {
      return
    }

    if (!sortable) {
      return onClick()
    }

    if (selected) {
      setSortDirection(sortDirection * -1)
      onClick({ sortDirection: sortDirection * -1 })
    } else {
      setSortDirection(1)
      onClick({ sortDirection: 1 })
    }
  }, [onClick, selected, sortDirection, sortable])

  const buttonIconName = useMemo((): IconName | undefined => {
    if (!(selected && sortable)) {
      return
    }

    return sortDirection === -1 ? 'caretUp' : 'caretDown'
  }, [selected, sortDirection, sortable])

  return (
    <Tab active={selected} onClick={handleClick}>
      {children || text}
      {buttonIconName && <Icon icon={buttonIconName} />}
    </Tab>
  )
}
