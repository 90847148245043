import { Link, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { Trans } from 'react-i18next'

import * as Styled from './styles'

export const ReadMore = (): ReactElement => {
  return (
    <Styled.ReadMoreWrapper>
      <Text colorVariant="secondary" variant="micro" alignment="center">
        <Trans i18nKey="settings.organization.users.invite_user_modal.read_more">
          Read more about our roles and which permissions they have, right{' '}
          <Link withAnimation href="https://billy.dk/support/article/roller-og-rettigheder" target="_blank">
            here
          </Link>
          .
        </Trans>
      </Text>
    </Styled.ReadMoreWrapper>
  )
}
