import { notify, useModal } from '@design-system'

import noop from 'lodash/noop'
import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../../../../../config/queryClient'
import { ModalId } from '../../../../../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../../enums/queryKeys'
import { useForm } from '../../../../../../../../../hooks'
import { APIError } from '../../../../../../../../../utils'
import { getErrorMessage } from '../../../../../../../../../utils/getErrorMessage'
import { TaxMetaField } from '../../../../../types/taxMetaField'
import { useCreateAdditionalField } from '../hooks/useCreateAdditionalField'
import { useUpdateAdditionalField } from '../hooks/useUpdateAdditionalField'
import {
  CreateOrEditAdditionalFieldForm,
  createOrEditAdditionalFieldFormDefaultValues,
  getValidationSchema,
} from '../utils/formData'

interface ContextState {
  isLoading: boolean
  submitForm: () => void
  isEditMode: boolean
}

const defaultValue: ContextState = {
  isLoading: false,
  submitForm: noop,
  isEditMode: false,
}

const CreateOrEditAdditionalFieldModalContext = createContext(defaultValue)

interface CreateOrEditAdditionalFieldModalContextProps {
  children: ReactNode
  additionalField?: TaxMetaField
}

export const CreateOrEditAdditionalFieldModalContextProvider = ({
  children,
  additionalField,
}: CreateOrEditAdditionalFieldModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { close } = useModal(ModalId.CreateOrEditAdditionalFieldModal, {
    onOpen: () => {
      reset()
    },
  })
  const isEditMode = !!additionalField

  const handleCreateOrEditError = useCallback(
    (error?: APIError) => {
      const message = getErrorMessage(error, 'salesTaxAccount') || t('error_saving')
      notify({ id: NotificationKeys.CreateOrEditAdditionalFields, message, variant: 'error' })
    },
    [t],
  )

  const handleCreateOrEditSuccess = useCallback(() => {
    queryClient.invalidateQueries(QueryKeys.AdditionalFields)
    close()
  }, [close])

  const { create, isLoading: isCreateProcessing } = useCreateAdditionalField({
    onError: handleCreateOrEditError,
    onSuccess: handleCreateOrEditSuccess,
  })

  const { update, isLoading: isEditProcessing } = useUpdateAdditionalField({
    onError: handleCreateOrEditError,
    onSuccess: handleCreateOrEditSuccess,
  })

  const isLoading = isEditMode ? isEditProcessing : isCreateProcessing

  const { Form, handleSubmit, setValue, reset } = useForm({
    defaultValues: createOrEditAdditionalFieldFormDefaultValues,
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  useEffect(() => {
    if (additionalField) {
      setValue('name', additionalField.name)
      setValue('description', additionalField.description)
      setValue('priority', additionalField.priority)
    } else {
      reset()
    }
  }, [reset, setValue, additionalField])

  const handleSubmitForm = useCallback(
    ({ name, description, priority }: CreateOrEditAdditionalFieldForm) => {
      const organizationId = organization?.id

      if (isEditMode) {
        return update({ id: additionalField.id, payload: { name, description, priority, organizationId } })
      }

      return create({ name, description, priority, organizationId })
    },
    [organization?.id, isEditMode, create, update, additionalField?.id],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return (
    <CreateOrEditAdditionalFieldModalContext.Provider
      value={{
        submitForm,
        isLoading,
        isEditMode,
      }}
    >
      <Form>{children}</Form>
    </CreateOrEditAdditionalFieldModalContext.Provider>
  )
}

export const useCreateOrEditAdditionalFieldModal = () => useContext(CreateOrEditAdditionalFieldModalContext)
