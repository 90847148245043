import { Badge, TableCellContent } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { PaymentMethodName } from '../../../../types/paymentMethodName'
import * as Styled from './styles'

export const TablePaymentMethodName = ({ isDefault, isPending, name }: PaymentMethodName): ReactElement => {
  const { t } = useTranslation()

  return (
    <TableCellContent weight="medium" truncate>
      <Styled.NameAndBadgeWrapper>
        {name}
        {isDefault && (
          <Badge>{t('settings.organization.invoice.payment_methods_settings.table.method_name.default')}</Badge>
        )}
        {isPending && (
          <Badge variant="error">
            {t('settings.organization.invoice.payment_methods_settings.table.method_name.pending')}
          </Badge>
        )}
      </Styled.NameAndBadgeWrapper>
    </TableCellContent>
  )
}
