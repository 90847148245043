import { DateInput, FormRow, Input } from '@components-deprecated'

import { yupResolver } from '@hookform/resolvers/yup'
import { addMonths, endOfDay } from 'date-fns'
import { TFunction } from 'i18next'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallowCompareEffect } from 'react-use'
import * as yup from 'yup'

import { AccountSelector } from '@modules-deprecated/app'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { Voucher, VoucherInboxState } from '@modules-deprecated/inbox/types'

import { useDeleteVoucherMutation } from '../../../../hooks/useDeleteVoucherMutation'
import { useUpdateVoucherMutation } from '../../../../hooks/useUpdateVoucherMutation'
import { extendVoucherDescription } from '../../../../utils/extendVoucherDescription'
import { MissingInformationActions } from '../../MissingInformationActions'
import { MissingInformationContent } from '../../MissingInformationContent'

interface BankAccount {
  id?: string
}

type FormInputs = {
  bankAccount?: BankAccount
  dueDate?: Date
  description?: string
}

interface MissingSupplierProps {
  voucher: Voucher
}

const dateMonthsLimit = 3

const defaultValues: FormInputs = {
  bankAccount: undefined,
  dueDate: undefined,
  description: '',
}

const dateInputDateMaxRange = addMonths(endOfDay(new Date()), dateMonthsLimit)

const validationSchema = (t: TFunction): yup.SchemaOf<FormInputs> =>
  yup.object().shape(
    {
      bankAccount: yup.object().when('dueDate', {
        is: (dueDate: boolean) => !!dueDate,
        then: yup.object().shape({
          id: yup.string(),
        }),
        otherwise: yup.object().shape({
          id: yup.string().required(t('things_to_do.validation.bank_account_or_due_date')),
        }),
      }),
      dueDate: yup
        .date()
        .typeError(t('things_to_do.validation.bank_account_or_due_date'))
        .max(dateInputDateMaxRange)
        .when('bankAccount.id', (bankAccountId: string, schema: yup.DateSchema) =>
          bankAccountId ? schema : schema.required(t('things_to_do.validation.bank_account_or_due_date')),
        ),
      description: yup.string(),
    },
    [['bankAccount', 'dueDate']],
  )

export const MissingPaymentMethod = ({ voucher }: MissingSupplierProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const form = useForm<FormInputs>({
    defaultValues,
    resolver: useMemo(() => yupResolver(validationSchema(t)), [t]),
  })
  const [bankAccount, dueDate] = form.watch(['bankAccount', 'dueDate'])

  useShallowCompareEffect(() => {
    if (bankAccount?.id || dueDate) {
      form.trigger()
    }
  }, [bankAccount, dueDate])

  const mutationBaseData = {
    organizationId: organization?.id as string,
    voucherId: voucher.id,
  }

  const deleteMutation = useDeleteVoucherMutation(mutationBaseData)
  const updateMutation = useUpdateVoucherMutation(mutationBaseData)

  const isLoading = deleteMutation.isLoading || updateMutation.isLoading

  const handleFormSubmit = useCallback(
    (values: FormInputs) => {
      const payload = {
        bankAccountId: values.bankAccount?.id || voucher.bankAccountId,
        dueDate: values.dueDate || voucher.dueDate,
        inboxState: VoucherInboxState.RECEIVED,
        description: values.description
          ? extendVoucherDescription(voucher.description, {
              comment: values.description,
            })
          : values.description,
      }
      updateMutation.mutate(payload)
    },
    [updateMutation, voucher.bankAccountId, voucher.description, voucher.dueDate],
  )

  const handleSubmit = useCallback(() => {
    form.handleSubmit(handleFormSubmit)()
  }, [form, handleFormSubmit])

  const handleDelete = useCallback(() => {
    deleteMutation.mutate()
  }, [deleteMutation])

  return (
    <MissingInformationContent disabled={isLoading}>
      <FormProvider {...form}>
        <FormRow error={form.formState.errors.bankAccount?.id || form.formState.errors.dueDate}>
          <AccountSelector
            formControl={form.control}
            label={t('things_to_do.missing_payment_method.account.label')}
            preselectedId={voucher.bankAccountId}
            name="bankAccount"
            isPaymentEnabled
            simplifiedList
            silent
          />
          <DateInput
            formControl={form.control}
            label={t('things_to_do.missing_payment_method.due_date.label')}
            maxDate={dateInputDateMaxRange}
            name="dueDate"
            silent
          />
        </FormRow>
        <Input formControl={form.control} label={t('voucher.inbox.view.comment')} name="description" type="textarea" />
      </FormProvider>
      <MissingInformationActions onSubmit={handleSubmit} onDelete={handleDelete} disabled={isLoading} />
    </MissingInformationContent>
  )
}
