import { pxToNumber, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

interface TwoColumnsLayoutWrapperProps {
  fullHeight?: boolean
}

export const TwoColumnsLayoutWrapper = styled.div<TwoColumnsLayoutWrapperProps>`
  display: flex;

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};
`

interface SideProps {
  asymmetrical?: boolean
  sticky?: boolean
}

const Side = styled.div<SideProps>`
  display: flex;
  flex-direction: column;

  ${({ asymmetrical }) =>
    !asymmetrical &&
    css`
      width: calc(50% - ${pxToNumber(Spacing.XXL) / 2}px);
    `}

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
    `}
`

export const LeftSide = styled(Side)`
  flex-shrink: 1;

  ${({ asymmetrical }) =>
    asymmetrical &&
    css`
      width: 30%;
    `}
`

export const RightSide = styled(Side)`
  min-width: 582px;
  margin-left: ${Spacing.XXL};

  ${({ asymmetrical }) =>
    asymmetrical &&
    css`
      width: 70%;
    `}
`
