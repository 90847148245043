import { getVouchersList } from '../services/api'
import { Voucher, VoucherListQueryParams } from '../types'

export const getFirstVoucherFound = async (
  organizationId: string,
  parameters: VoucherListQueryParams,
): Promise<Voucher | undefined> => {
  const params = { ...parameters, limit: 1 }
  try {
    const vouchers = await getVouchersList(organizationId, params, false)
    return vouchers?.length ? vouchers[0] : undefined
  } catch (error) {
    console.error('Error while fetching vouchers', error)
  }
}
