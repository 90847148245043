import { Button, ButtonDropdown, ButtonsGroup, NavItem, useModal, withModalConditionalRender } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganizationSettings } from '@modules-deprecated/app/organization'

import { ModalId } from '../../../../../../enums/modalId'
import { ApprovalConfirmationModal } from './elements/ApprovalConfirmationModal'
import { DownloadConfirmationModal } from './elements/DownloadConfirmationModal'
import { FinalizeDropdownItem } from './types/finalizeDropdownItem'

const ApprovalConfirmationModalConditional = withModalConditionalRender(ApprovalConfirmationModal)
const DownloadConfirmationModalConditional = withModalConditionalRender(DownloadConfirmationModal)

const approvalConfirmationModalId = ModalId.InvoiceEditorApprovalConfirmationModal
const downloadConfirmationModalId = ModalId.InvoiceEditorDownloadConfirmationModal

export const InvoiceEditorSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { organizationSettings } = useUserOrganizationSettings()
  const { open: openApprovalConfirmationModal } = useModal(approvalConfirmationModalId)
  const { open: openDownloadConfirmationModal } = useModal(downloadConfirmationModalId)

  const dropdownItems = useMemo(
    (): NavItem<FinalizeDropdownItem>[] => [
      {
        id: 'downloadAsPdf',
        children: t('external_invoices.editor.side_actions.download_as_pdf'),
        iconLeft: 'arrowPointingDown',
        value: 'downloadAsPdf',
      },
      {
        id: 'approveAndClose',
        children: t('external_invoices.editor.side_actions.approve_and_close'),
        iconLeft: 'checkStar',
        value: 'approveAndClose',
      },
    ],
    [t],
  )

  const handleSelect = useCallback(
    (_: string, value: FinalizeDropdownItem) => {
      if (value === 'downloadAsPdf') {
        const shouldOpenDownloadModal = organizationSettings?.isInvoiceEditorDownloadModalDismissed !== '1'

        if (shouldOpenDownloadModal) {
          openDownloadConfirmationModal()
        }

        // send a request to download
      }

      if (value === 'approveAndClose') {
        const shouldOpenApprovalModal = organizationSettings?.isInvoiceEditorApprovalModalDismissed !== '1'

        if (shouldOpenApprovalModal) {
          openApprovalConfirmationModal()
        }

        // send a request to approve
      }
    },
    [
      openApprovalConfirmationModal,
      openDownloadConfirmationModal,
      organizationSettings?.isInvoiceEditorApprovalModalDismissed,
      organizationSettings?.isInvoiceEditorDownloadModalDismissed,
    ],
  )

  return (
    <>
      <ButtonsGroup>
        <Button variant="secondary">{t('external_invoices.editor.side_actions.save_draft')}</Button>
        <ButtonDropdown items={dropdownItems} onSelect={handleSelect}>
          {t('external_invoices.editor.side_actions.finalize')}
        </ButtonDropdown>
      </ButtonsGroup>
      <ApprovalConfirmationModalConditional id={approvalConfirmationModalId} />
      <DownloadConfirmationModalConditional id={downloadConfirmationModalId} />
    </>
  )
}
