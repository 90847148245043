import { Button } from '@design-system'

import { css, SerializedStyles } from '@emotion/core'
import cc from 'classcat'
import React from 'react'
import { Box, Flex, FlexProps } from 'rebass'

import { reactClass } from '../../utils'
import { log } from '../../utils/logger'
import { Heading, Text } from '../Typography'

export type FriendlyErrorProps = FlexProps & {
  comp?: React.ReactNode
  compProps?: {}
  css?: SerializedStyles
  error?: Error
  errorInfo?: React.ErrorInfo
  handleRetry?: () => void
}

const DEV = process.env.NODE_ENV === 'development'

export const FriendlyError = ({
  children,
  className,
  comp,
  compProps,
  css: styles,
  error,
  errorInfo,
  handleRetry,
  ...rest
}: FriendlyErrorProps) => {
  if (DEV) {
    log(error)
    log(errorInfo)
    log(comp)
    log(compProps)
  }

  return (
    <Flex
      as="section"
      bg="primary"
      className={cc([className, reactClass('friendly-error')])}
      css={[
        css`
          display: flex;
          width: 100%;
          height: 100vh;
          flex: 1;
          padding: 10vh;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: white;
          & > div {
            text-align: center;
          }
        `,
        styles,
      ]}
      {...rest}
    >
      {children || (
        <>
          <Box>
            <Heading variant="h1" as="h2">
              Error!
            </Heading>
          </Box>
          <Box my={2}>
            <Heading variant="h3">Snap! We broke the internet.</Heading>
          </Box>
          <Box my={2}>
            <Text variant="body1">We are very sorry for the inconvenience, reloading the page may fix it.</Text>
            <Text variant="body1">If the issue persists contact the administrator of the site.</Text>
          </Box>
        </>
      )}
      <Box mt={4} my={2}>
        <Button onClick={handleRetry}>Retry</Button>
      </Box>
      {DEV && errorInfo ? (
        <Box my={4} maxWidth={600}>
          {errorInfo.componentStack}
        </Box>
      ) : null}
    </Flex>
  )
}
