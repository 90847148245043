import styled from '@emotion/styled'

export const ModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SimpleChecklistWrapper = styled.div`
  width: 205px;
`
