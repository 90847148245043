import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const UsageUpsellWrapper = styled.section`
  display: flex;
  flex-direction: row;
`

export const UsageWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
`

export const UpsellWrapper = styled.aside`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`

interface UpsellTextProps {
  flexible?: boolean
}

export const UpsellText = styled.span<UpsellTextProps>`
  width: ${({ flexible }) => (flexible ? 'auto' : '216px')};
  text-align: center;
`

export const NoLimitationsText = styled(Text)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Spacing.XL};
`
