import { notify } from '@design-system'

import get from 'lodash/get'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { getBill } from '../query-api'

export const useGetBill = (billId?: string) => {
  const { t } = useTranslation()
  const query = useQuery([QueryKeys.Bill, billId], () => getBill({ billId: billId as string }), {
    enabled: !!billId,
  })

  useEffect(() => {
    if (query.isError) {
      const message = get(query.error, 'message') || t('bill.notification.fetch_bill_error')
      notify({ id: 'bill-error', message, variant: 'error' })
    }
  }, [query.error, query.isError, t])

  return query
}
