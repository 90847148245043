import { getCurrentLocale } from '@design-system'

import { call, put, takeLatest } from 'redux-saga/effects'

import { getRequest, log } from '../../../utils'
import { taxRatesReceived, TaxRatesRequested } from './action-creators'
import { TAX_RATES_REQUESTED } from './actions'
import { TaxRate } from './types'

function* fetchTaxRates({ payload }: TaxRatesRequested) {
  const { organizationId, filters } = payload
  const locale = getCurrentLocale()

  try {
    const params = new URLSearchParams()
    params.append('organizationId', organizationId)
    params.append('include', 'taxRate.deductionComponents:embed')
    if (filters.purchase) {
      params.append('appliesToPurchases', 'true')
    } // not currently supported by API
    if (filters.sale) {
      params.append('appliesToSales', 'true')
    } // not currently supported by API
    const res: { taxRates: any } = yield call(getRequest, `/v2/taxRates?${params.toString()}`, {
      'accept-language': locale,
    })
    const taxRates = res.taxRates
      .map((rate: TaxRate) => ({
        ...rate,
        deductionRate: rate.deductionComponents?.reduce((rate, deduction) => rate + deduction.share, 0) * rate.rate,
      }))
      .sort((a: TaxRate, b: TaxRate) => (a.taxRateGroup > b.taxRateGroup ? 1 : -1))
      .sort((a: TaxRate, b: TaxRate) => (a.taxRateGroup === b.taxRateGroup ? b.rate - a.rate : 0))
    yield put(taxRatesReceived(taxRates))
  } catch (e) {
    log('Error: tax rates not available.')
    yield put(taxRatesReceived([]))
  }
}

export default function* () {
  yield takeLatest(TAX_RATES_REQUESTED, fetchTaxRates)
}
