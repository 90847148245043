import { TwoColumnsLayout } from '@components'

import { ReactElement } from 'react'

import { BillOverviewReconciliationSuggestion } from '../BillOverviewReconciliationSuggestion'
import { BillPaymentPane } from '../BillPaymentPane'
import { BillReadableAttachments } from '../BillReadableAttachments'
import { BillReadableForm } from '../BillReadableForm'
import { useBillReconciliationABTest } from '../BillReconciliationModal/hooks/useBillReconciliationABTest'

export const BillReadableLayout = (): ReactElement => {
  const { isInVariantA, isInVariantB } = useBillReconciliationABTest()

  return (
    <>
      {isInVariantA && <BillOverviewReconciliationSuggestion />}
      <BillPaymentPane />
      {isInVariantB && <BillOverviewReconciliationSuggestion />}
      <TwoColumnsLayout asymmetrical fullHeight>
        <BillReadableAttachments />
        <BillReadableForm />
      </TwoColumnsLayout>
    </>
  )
}
