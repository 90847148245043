import { Translate } from '@components'
import { InternalAccessor, Table, TableColumn } from '@design-system'

import capitalize from 'lodash/capitalize'
import pick from 'lodash/pick'
import { ReactElement, useCallback, useMemo } from 'react'

import { TaxAccountWithDetails } from '../../../../types/taxAccountWithDetails'
import { useVatAccounts } from '../../hooks/useVatAccounts'
import { VatAccountsTableHoverActions } from './elements/VatAccountsTableHoverActions'
import { VatAccountsTableStaticActions } from './elements/VatAccountsTableStaticActions'
import { VatAccountsTableRow } from './types/vatAccountsTableRow'

interface VatAccountsTableProps {
  onEditVatAccountModal: (account: TaxAccountWithDetails) => void
  onVatAccountDelete: (accountId: string) => void
}

export const VatAccountsTable = ({
  onEditVatAccountModal,
  onVatAccountDelete,
}: VatAccountsTableProps): ReactElement => {
  const { vatAccounts, isLoading } = useVatAccounts()

  const tableData: VatAccountsTableRow[] = useMemo(() => {
    return vatAccounts.map((account) => ({
      ...pick(account, ['id', 'type', 'priority']),
      name: account.account.name,
      [InternalAccessor.HoverActions]: true,
      [InternalAccessor.StaticActions]: true,
    }))
  }, [vatAccounts])

  const handleOnEditVatAccountClick = useCallback(
    (accountId: string) => {
      const account = vatAccounts.find((vatAccount) => vatAccount.id === accountId)

      if (account) {
        onEditVatAccountModal(account)
      }
    },
    [onEditVatAccountModal, vatAccounts],
  )

  const columns: TableColumn<VatAccountsTableRow>[] = useMemo(
    () => [
      {
        Header: <Translate value={'settings.organization.tax_return.columns.name'} />,
        accessor: 'name',
        size: 'xl',
      },
      {
        Header: <Translate value={'settings.organization.tax_return.columns.type'} />,
        accessor: 'type',
        size: 'xxs',
        Cell: ({ value }) => <>{capitalize(value)}</>,
      },
      {
        Header: <Translate value={'settings.organization.tax_return.columns.priority'} />,
        accessor: 'priority',
        alignment: 'right',
        size: 'xxs',
      },
      {
        Header: '',
        accessor: InternalAccessor.HoverActions,
        Cell: ({ row, data }) => {
          const { id } = data[row.id] as VatAccountsTableRow
          return <VatAccountsTableHoverActions onEdit={handleOnEditVatAccountClick} id={id} />
        },
      },
      {
        Header: '',
        accessor: InternalAccessor.StaticActions,
        Cell: ({ row, data }) => {
          const { id } = data[row.id] as VatAccountsTableRow
          return <VatAccountsTableStaticActions onDelete={onVatAccountDelete} id={id} />
        },
      },
    ],
    [handleOnEditVatAccountClick, onVatAccountDelete],
  )

  return <Table columns={columns} data={tableData} isLoading={isLoading} skeletonItemsPerPage={5} />
}
