import { Color, Flex, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const HighlightedIconWrapper = styled(Flex)`
  background-color: ${Color.DayGrey};
  border-radius: 50%;
  height: 40px;
  margin-right: ${Spacing.S};
  min-width: 40px;
  width: 40px;
`
