import { useMutation } from 'react-query'

import { deleteUmbrellaRole } from '@modules-deprecated/app/umbrellas/query-api'

import { APIError } from '../../../../../../../../../utils'

interface UseDeleteRoleProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface DeleteRolePayload {
  umbrellaId: string
  roleId: string
}

export const useDeleteRole = (props?: UseDeleteRoleProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: deleteRole, isLoading: isProcessing } = useMutation(
    ({ umbrellaId, roleId }: DeleteRolePayload) => deleteUmbrellaRole(umbrellaId, roleId),
    {
      onError,
      onSuccess,
    },
  )

  return {
    isProcessing,
    deleteRole,
  }
}
