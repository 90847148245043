import { Paging } from '../../../types/metable'
import { Contact } from '../types/contact'
import { useContactsListFilters } from './useContactsListFilters'
import { useFetchContacts } from './useFetchContacts'

interface UseContactsResponse {
  contacts: Contact[]
  isLoading: boolean
  pagination?: Paging
}

export const useContacts = (): UseContactsResponse => {
  const [queryProps] = useContactsListFilters()
  const { contacts, isLoading, pagination } = useFetchContacts(queryProps)

  return {
    contacts,
    isLoading,
    pagination,
  }
}
