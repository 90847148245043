import { deleteRequest, putRequest } from '../../../../../../utils'

interface CurrencyDefaultPaymentMethodOptions {
  currencyCode: string
  organizationId: string
  paymentMethodId: string
}

export const updateCurrencyDefaultPaymentMethod = ({
  currencyCode,
  organizationId,
  paymentMethodId,
}: CurrencyDefaultPaymentMethodOptions): Promise<void> => {
  return putRequest(
    `/organizations/${organizationId}/paymentMethodCurrencyDefaults/${currencyCode}/${paymentMethodId}`,
    {},
  )
}

export const deleteCurrencyDefaultPaymentMethod = ({
  currencyCode,
  organizationId,
  paymentMethodId,
}: CurrencyDefaultPaymentMethodOptions): Promise<void> => {
  return deleteRequest(
    `/organizations/${organizationId}/paymentMethodCurrencyDefaults/${currencyCode}/${paymentMethodId}`,
  )
}
