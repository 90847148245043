import { NavItem, TableStaticActions, useModal, withModalConditionalRender } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PaymentMethodsStaticActionType } from '../../enums/paymentMethodsStaticActionType'
import { getDeletePaymentMethodModalId } from '../../utils/getDeletePaymentMethodModalId'
import { DeletePaymentMethodModal } from '../DeletePaymentMethodModal'

const DeletePaymentMethodModalConditional = withModalConditionalRender(DeletePaymentMethodModal)

interface PaymentMethodsTableStaticActionsProps {
  paymentMethodId: string
}

export const PaymentMethodsTableStaticActions = ({
  paymentMethodId,
}: PaymentMethodsTableStaticActionsProps): ReactElement => {
  const { t } = useTranslation()
  const deletePaymentMethodModalId = getDeletePaymentMethodModalId(paymentMethodId)
  const { open: openDeletePaymentMethodModal } = useModal(deletePaymentMethodModalId)

  const handleDeleteButtonClick = useCallback(() => {
    openDeletePaymentMethodModal()
  }, [openDeletePaymentMethodModal])

  const staticActionsItems: NavItem[] = useMemo(
    () => [
      {
        children: t('settings.organization.invoice.payment_methods_settings.table.actions.delete'),
        iconLeft: 'trash',
        id: 'delete',
        value: PaymentMethodsStaticActionType.Delete,
      },
    ],
    [t],
  )

  return (
    <>
      <TableStaticActions items={staticActionsItems} onSelect={handleDeleteButtonClick} />
      <DeletePaymentMethodModalConditional id={deletePaymentMethodModalId} paymentMethodId={paymentMethodId} />
    </>
  )
}
