import React, { ReactElement, ReactNode } from 'react'

import { IconButton } from '../../../IconButton'
import { Text } from '../../../Text'
import * as Styled from './styles'

export interface SettingsLayoutHeaderProps {
  onBackButtonClick?: () => void
  sideActions?: ReactNode
  title?: string
  withBackButton?: boolean
}

export const SettingsLayoutHeader = ({
  onBackButtonClick,
  sideActions,
  title,
  withBackButton,
}: SettingsLayoutHeaderProps): ReactElement => (
  <Styled.SettingsLayoutHeaderWrapper>
    <Styled.TitleWrapper>
      {withBackButton && <IconButton icon="chevronLeft" onClick={onBackButtonClick} />}
      <Text variant="h2">{title}</Text>
    </Styled.TitleWrapper>
    {sideActions}
  </Styled.SettingsLayoutHeaderWrapper>
)
