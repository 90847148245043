import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ReconciliationSuggestionContent = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  grid-gap: ${Spacing.S};
  align-items: center;
`
