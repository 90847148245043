import bottomMenu from './bottomMenu'
import hoverMenu from './hoverMenu'
import menu from './menu'
import menuSearchResults from './menuSearchResults'
import organizationMenu from './organizationMenu'
import quickMenuButton from './quickMenuButton'
import subMenu from './subMenu'

export default {
  ...bottomMenu,
  ...hoverMenu,
  ...menu,
  ...menuSearchResults,
  ...organizationMenu,
  ...quickMenuButton,
  ...subMenu,
}
