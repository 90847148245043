import { DropdownFooterProps } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { AccountInfo } from '../AccountInfo'
import { ViewOrganizations } from '../ViewOrganizations'
import * as Styled from './styles'

type AccountSelectorFooterProps = DropdownFooterProps

export const AccountSelectorFooter = ({ onFooterItemClick }: AccountSelectorFooterProps): ReactElement | null => {
  const { organization } = useUserOrganization()

  const handleViewOrganizationClick = useCallback(() => {
    onFooterItemClick?.()
  }, [onFooterItemClick])

  if (!organization) {
    return null
  }

  return (
    <Styled.AccountSelectorFooterWrapper>
      <Styled.Separator as="hr" />
      <ViewOrganizations organization={organization} onClick={handleViewOrganizationClick} />
      <Styled.Separator as="hr" />
      <AccountInfo organization={organization} />
    </Styled.AccountSelectorFooterWrapper>
  )
}
