import { Tooltip as TooltipComponent } from '@design-system'

import styled from '@emotion/styled'

export const ReconciliationInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

export const AccountInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
`

export const Tooltip = styled(TooltipComponent)`
  max-width: 200px;
`
