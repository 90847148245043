import { getCurrentLocale } from '@design-system'

import sortBy from 'lodash/sortBy'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { QueryKeys } from '../../../../enums/queryKeys'
import { getApiCountryKey } from '../../../../utils/getApiCountryKey'
import { fetchCategories, fetchCategorySuggestions, FetchCategorySuggestionsOptions } from '../query-api'
import { TrainingData } from '../types/trainingData'

interface UseBookkeepingCategoriesMLSearchProps {
  searchQuery?: string
  trainingData?: TrainingData
  isEnabled?: boolean
  isCategoriesPrefetchEnabled?: boolean
}

export const useBookkeepingCategoriesMLSearch = ({
  searchQuery = '',
  trainingData,
  isEnabled = true,
  isCategoriesPrefetchEnabled = true,
}: UseBookkeepingCategoriesMLSearchProps) => {
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()
  const locale = getCurrentLocale()
  const chartOfAccountId = organization?.chartOfAccountId
  const isUserAndOrganizationLoaded = !!user && !!organization

  /* eslint-disable @typescript-eslint/naming-convention */
  const options: FetchCategorySuggestionsOptions | undefined = useMemo(
    () =>
      isUserAndOrganizationLoaded
        ? {
            text_input: searchQuery,
            session_id: trainingData?.id || '',
            contact_vat_number: trainingData?.contact?.vatNo || '',
            contact_reg_number: trainingData?.contact?.registrationNo || '',
            contact_country: trainingData?.contact?.countryId || '',
            organisation_country_code: getApiCountryKey(organization?.countryId).toUpperCase(),
            user_locale: user.locale,
            organization_id: organization.id,
            scanned_category_result: trainingData?.scannedCategoryResult || null,
            meta: {
              user_id: user.id,
              file_id: null,
              category_id: null,
            },
          }
        : undefined,

    [
      isUserAndOrganizationLoaded,
      organization?.countryId,
      organization?.id,
      searchQuery,
      trainingData?.contact?.countryId,
      trainingData?.contact?.registrationNo,
      trainingData?.contact?.vatNo,
      trainingData?.id,
      trainingData?.scannedCategoryResult,
      user?.id,
      user?.locale,
    ],
  )
  /* eslint-enable @typescript-eslint/naming-convention */

  const { data: categorySuggestionsData, isLoading: isLoadingSuggestions } = useQuery(
    [QueryKeys.BookkeepingCategorySuggestions, options],
    () => fetchCategorySuggestions(options as FetchCategorySuggestionsOptions),
    {
      enabled: isEnabled && isUserAndOrganizationLoaded,
      cacheTime: 0,
      keepPreviousData: true,
    },
  )

  const categoriesIds = useMemo(
    () => categorySuggestionsData?.suggested.map(({ id }) => id) || [],
    [categorySuggestionsData?.suggested],
  )

  const queryId = useMemo(() => categorySuggestionsData?.query_id, [categorySuggestionsData])

  const { data: categoriesData, isLoading: isLoadingCategoriesData } = useQuery(
    [QueryKeys.BookkeepingCategories, locale, chartOfAccountId, organization?.countryId],
    () =>
      fetchCategories({
        locale,
        accountType: chartOfAccountId,
        countryId: organization?.countryId,
      }),
    {
      enabled: isCategoriesPrefetchEnabled && !!chartOfAccountId && !!organization?.countryId,
      keepPreviousData: true,
      refetchOnMount: false,
    },
  )

  const suggestedCategories = useMemo(() => {
    const filteredCategories = categoriesData?.filter(({ id }) => categoriesIds.includes(id)) || []
    // keeps suggested categories in order from search results score
    return sortBy(filteredCategories, (category) => categoriesIds.indexOf(category.id))
  }, [categoriesData, categoriesIds])

  return {
    isLoading: isLoadingSuggestions || isLoadingCategoriesData,
    queryId,
    suggestedCategories,
  }
}
