import { createSelector } from 'reselect'

import { SpecificState } from '../../types/reduxSaga-deprecated'
import { ReceiptState } from './types'

export const selectBankLineSubjectAssociationByReference = createSelector(
  (state: SpecificState) => state.bankReconciliation.bankLineSubjectAssociations,
  (_: unknown, referenceId: string) => referenceId,
  (bankLineSubjectAssociations, referenceId) => {
    return bankLineSubjectAssociations.find((blsa) => {
      return blsa.subjectReference.endsWith(`:${referenceId}`)
    })
  },
)

export const selectBankLineMatch = createSelector(
  (state: SpecificState) => state.bankReconciliation.bankLineMatches,
  (_: unknown, matchId: string) => matchId,
  (bankLineMatches, matchId) => {
    return bankLineMatches.find((bankLineMatch) => bankLineMatch.id === matchId)
  },
)

export const selectSelectedBankLines = createSelector(
  (state: SpecificState) => state.bankReconciliation.bankLineMatches,
  (_: unknown, selectedIds: string[]) => selectedIds,
  (_: unknown, __: unknown, isDeleteMode: boolean) => isDeleteMode,
  (bankLineMatches, selectedIds, isDeleteMode) => {
    const bankLineMatchIds =
      bankLineMatches
        ?.filter(({ bankLines: [bankLine] }) => isDeleteMode || bankLine?.receiptState !== ReceiptState.MissingReceipt)
        .map((match) => match.id) || []
    const validSelectedIds = selectedIds.filter((id) => bankLineMatchIds?.includes(id))
    const allSelected = selectedIds.length === bankLineMatchIds.length
    return { bankLineMatchIds, validSelectedIds, allSelected }
  },
)
