import { FormFilesLayout, Header, ListSection } from '@components-deprecated'
import { Button, notify } from '@design-system'

import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import { transparentize } from 'polished'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../enums/queryKeys'
import { Themable } from '../../../../types/themable'
import { Theme } from '../../../../types/theme'
import { useUserOrganization } from '../../../app/organization'
import { VoucherStatus } from '../../elements'
import { VoucherActions } from '../../elements/VoucherActions'
import { useVoucherActionState } from '../../hooks/useVoucherActionState'
import { getBankPayment, getBill, getTransactionPosting, getUser } from '../../services/api'
import { fetchVoucher } from '../../services/query-api'
import { BankPaymentData, BillData, PostingData, VoucherInboxState } from '../../types'
import { getPaymentIdFromBalance, getVoucherFiles } from '../../utils'
import { VoucherViewEditable, VoucherViewEditableRefProps } from './VoucherViewEditable'
import { VoucherViewReadOnly } from './VoucherViewReadOnly'

const READ_ONLY_STATES = [
  VoucherInboxState.ARCHIVED,
  VoucherInboxState.DISCARDED,
  VoucherInboxState.RECORDED,
  VoucherInboxState.VOIDED,
]

const getVoucherStateToColor = (
  theme: Theme,
): Record<Exclude<VoucherInboxState, VoucherInboxState.DELETED | VoucherInboxState.DUPLICATED>, string> => ({
  [VoucherInboxState.RECEIVED]: theme.colors.label.primary,
  [VoucherInboxState.RECORDED]: theme.colors.label.success,
  [VoucherInboxState.ARCHIVED]: theme.colors.label.success,
  [VoucherInboxState.DISCARDED]: theme.colors.label.default,
  [VoucherInboxState.NEED_INFO]: theme.colors.label.warning,
  [VoucherInboxState.VOIDED]: theme.colors.label.danger,
})

const getPaymentId = (billData: BillData) => {
  const { balanceModifiers, bill } = billData
  return getPaymentIdFromBalance(balanceModifiers, bill.id)
}

type VoucherViewProps = {
  customerView?: boolean
}

const BookkeeperInfo = styled.footer<Themable>`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: flex-end;
`

export const VoucherView = ({ customerView }: VoucherViewProps): ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme<Theme>()
  const { voucherId } = useParams() as { voucherId: string }
  const { organization } = useUserOrganization()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [billData, setBillData] = useState<BillData>()
  const [transactionData, setTransactionData] = useState<PostingData>()
  const [bankPaymentData, setBankPaymentData] = useState<BankPaymentData>()
  const voucherEditableRef = useRef<VoucherViewEditableRefProps>(null)
  const bookkeeperName = useRef<string>()
  const organizationId = organization?.id || ''

  // Queries

  const {
    data: voucher,
    isError: isVoucherFetchError,
    error: voucherFetchError,
  } = useQuery([QueryKeys.Voucher, organizationId, voucherId], () =>
    fetchVoucher({
      queryKey: [QueryKeys.Voucher, { organizationId, voucherId }],
    }),
  )

  // Listening voucher actions

  const { isSomeActionProcessing } = useVoucherActionState({ voucherId: voucher?.id || '' })

  // Computed variables

  const voucherStateToColor = getVoucherStateToColor(theme)
  const files = voucher ? getVoucherFiles(voucher) : []
  const isPartner = !customerView
  const customState: VoucherInboxState | undefined = bankPaymentData?.bankPayment.isVoided
    ? VoucherInboxState.VOIDED
    : voucher?.inboxState
  const isReadonly = customState ? READ_ONLY_STATES.indexOf(customState) !== -1 || isPartner : true
  const borderColor = customState ? voucherStateToColor[customState] : theme.colors.shade30
  const shouldRenderBookkeeperInfo =
    !!bookkeeperName.current && voucher?.inboxState === VoucherInboxState.RECORDED && isPartner

  // Request additional voucher data

  useEffect(() => {
    async function fetchAdditionalData() {
      if (!voucher) {
        return
      }

      const { billId, transactionId, userId } = voucher.voucherReference || {}

      if (billId) {
        const billData = await getBill(billId)
        const paymentId = getPaymentId(billData)

        if (paymentId) {
          const paymentData = await getBankPayment(paymentId)
          setBankPaymentData(paymentData)
        }

        setBillData(billData)
      } else if (transactionId) {
        const transactionData = await getTransactionPosting(transactionId)
        setTransactionData(transactionData)
      }

      if (userId) {
        const { user } = await getUser(userId)
        bookkeeperName.current = user.name
      }

      setIsLoading(false)
    }

    fetchAdditionalData()
  }, [voucher])

  const close = () => {
    history.push('/')
  }

  const onSaveClick = () => {
    if (voucherEditableRef?.current?.handleSave) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      voucherEditableRef?.current?.handleSave()
    }
  }

  if (isVoucherFetchError) {
    console.error(voucherFetchError)
    notify({
      id: NotificationKeys.VoucherInboxFetch,
      message: t('voucher.inbox.toast.voucher_fetched_failed', { voucherId }),
      variant: 'error',
    })
    close()
  }

  return (
    <>
      <Header title={t('view_voucher')} withMargin>
        {!isReadonly && (
          <Button disabled={isSomeActionProcessing} onClick={onSaveClick}>
            {t('save')}
          </Button>
        )}

        {voucher && (
          <VoucherActions voucher={voucher} customerView={customerView} expanded={!isPartner} backToInboxAfterAction />
        )}
      </Header>

      <FormFilesLayout files={files} loading={isLoading}>
        <ListSection
          title={t('voucher.inbox.header.inbox_state')}
          titleColor={theme.colors.text.default}
          borderColor={transparentize(0.5, borderColor)}
          headerActions={customState ? <VoucherStatus state={customState} /> : null}
        >
          {isReadonly ? (
            <VoucherViewReadOnly
              voucher={voucher}
              billData={billData}
              transactionData={transactionData}
              bankPaymentData={bankPaymentData}
            />
          ) : (
            <VoucherViewEditable ref={voucherEditableRef} voucher={voucher} organizationId={organizationId} />
          )}
          {shouldRenderBookkeeperInfo && (
            <BookkeeperInfo>
              <p>
                {t('voucher.inbox.bookkeeper.handled_by')} <strong>{bookkeeperName.current}</strong>
              </p>
            </BookkeeperInfo>
          )}
        </ListSection>
      </FormFilesLayout>
    </>
  )
}
