import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { submitSignup, SubmitSignupPayload, SubmitSignupResponseData } from '../query-api/signup-query'
import { APIError } from '../utils'

interface UseSignupProps {
  onError?: (error?: APIError) => void
  onSuccess?: (response: SubmitSignupResponseData) => void
}

export const useSignup = (props?: UseSignupProps) => {
  const { onSuccess, onError } = props || {}

  const { mutate, ...rest } = useMutation(submitSignup, {
    onSuccess,
    onError,
  })

  const signup = useCallback(
    (payload: SubmitSignupPayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    signup,
    ...rest,
  }
}
