import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'

export const NavNotFoundInfoWrapper = styled.div``

export const NavDefaultInfo = styled.div`
  display: flex;
  width: 100%;
  padding-top: ${Spacing.S};
  padding-bottom: ${Spacing.S};
  justify-content: center;
`
