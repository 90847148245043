import { Button, ButtonProps } from '@design-system'

import React, { ReactElement, ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useCopyAndNotify } from '../../hooks/useCopyAndNotify'
import * as Styled from './styles'

interface CopyableProps extends ButtonProps {
  valueToCopy: string
  children: ReactNode
}

export const Copyable = ({ children, valueToCopy, ...rest }: CopyableProps): ReactElement => {
  const { t } = useTranslation()
  const { copy } = useCopyAndNotify()

  const onIconClick = useCallback(() => {
    copy(valueToCopy)
  }, [copy, valueToCopy])

  return (
    <Styled.Tooltip as="span" label={t('copyable.tooltip')}>
      <Button icon="twoSquares" onClick={onIconClick} variant="text" {...rest}>
        {children}
      </Button>
    </Styled.Tooltip>
  )
}
