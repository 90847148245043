import { ExportType } from '@components'

import { getExportDownloadUrl } from '../../../utils/getExportDownloadUrl'
import { FilterOptions } from '../elements/ExportReport'
import { ReportsApiUrl } from '../enums/reportsApiUrl'
import { getRegnskabBasisRequestQueryParams } from './getRegnskabBasisRequestQueryParams'
import { getReportRequestQueryParams } from './getReportRequestQueryParams'

const regnskabBasisExportUrl = 'danishBasicAccountingReport'

export const getExportStatementDownloadUrl = (
  reportUrl: ReportsApiUrl,
  organizationId: string,
  type: ExportType,
  options: FilterOptions | undefined,
): string => {
  let downloadUrl: string
  const queryParams = getReportRequestQueryParams(reportUrl, options)

  if (type === ExportType.RegnskabBasis) {
    const regnskabBasisParams = getRegnskabBasisRequestQueryParams(options)

    downloadUrl = getExportDownloadUrl({
      exportUrl: regnskabBasisExportUrl,
      organizationId,
      type: ExportType.Csv,
      queryParams: regnskabBasisParams,
    })
  } else {
    downloadUrl = getExportDownloadUrl({ exportUrl: reportUrl, organizationId, type, queryParams })
  }

  return downloadUrl
}
