import { IconButtonWithLabel } from '@design-system'

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { isSettingsModulePath } from '@views/settings/utils/isSettingsModulePath'
import { routeToInternalSettings } from '@views/settings/utils/routeToInternalSettings'

import { useEmberCurrentRoute } from '../../../../../../contexts/emberCurrentRouteContext'
import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'

export const SettingsButton = () => {
  const { t } = useTranslation()
  const { currentRoutePath } = useEmberCurrentRoute()
  const { navigate } = useEmberRouter()
  const isSettingsIconActive = currentRoutePath.includes('settings')
  const { organization } = useUserOrganization()

  const organizationUrl = organization?.url

  const handleSettingsClick = useCallback(() => {
    if (isSettingsModulePath()) {
      routeToInternalSettings(EmberRoute.SettingsOrganization)
      return
    }

    navigate(EmberRoute.SettingsOrganization, [organizationUrl])
  }, [navigate, organizationUrl])

  return (
    <IconButtonWithLabel icon="cog" onClick={handleSettingsClick} active={isSettingsIconActive}>
      {t('menu.footer.settings_action')}
    </IconButtonWithLabel>
  )
}
