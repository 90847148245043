import { Features } from './types'

export const FEATURES = [
  'fixedAssets',
  'pbs',
  'bankSyncV2',
  'reactContacts',
  'reactCreateContacts',
  'reactBankReconciliation',
]

export const getFeatures = (): Features => {
  return FEATURES.reduce((featuresList, feature) => {
    const featureFlag = localStorage.getItem(`labs-flag:${feature}`)
    return {
      ...featuresList,
      [feature]: featureFlag === '1',
    }
  }, {})
}
