import { css, SerializedStyles } from '@emotion/core'
import cc from 'classcat'
import React, { useState } from 'react'
import { Box, BoxProps, Flex } from 'rebass'

import { reactClass } from '../../utils'
import { IconCaretDown } from '../Icons'

export type FlipperProps = BoxProps & {
  bg?: 'default' | 'inverse' | 'soft'
  css?: SerializedStyles
  onFlipSide?: () => void
}

export const Flipper = ({
  bg = 'default',
  children,
  className,
  css: styles,
  onFlipSide = () => null,
  ...rest
}: FlipperProps) => {
  const [side, setSide] = useState<number>(0)

  const handleFlipSide = () => {
    setSide((prevSide) => (prevSide + 1) % 2)
    onFlipSide()
  }

  return (
    <Box
      bg={`bg.${bg}`}
      className={cc([className, reactClass('flipper')])}
      css={[
        css`
          padding: 7.5px 0px;
        `,
        styles,
      ]}
      {...rest}
    >
      <Flex color="primary" m="0 auto">
        <Flex flex="auto">
          {React.Children.map(
            children as React.ReactNode[],
            (child: React.ReactNode, index: number): JSX.Element | null =>
              React.isValidElement(child) && index === side ? React.cloneElement(child, {}) : null,
          )}
        </Flex>
        <IconCaretDown
          onClick={handleFlipSide}
          rotate={!!side}
          width={13}
          ml="15px"
          css={css`
            cursor: pointer;
          `}
        />
      </Flex>
    </Box>
  )
}
