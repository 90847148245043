import { yupResolver } from '@hookform/resolvers/yup'
import { TFunction } from 'i18next'
import React, { ReactElement, useCallback, useMemo, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Provider as ReduxProvider } from 'react-redux'
import * as yup from 'yup'

import { store } from '@modules-deprecated'
import { ContactSelector } from '@modules-deprecated/app'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { Voucher, VoucherInboxState, VoucherUpdatePayloadData } from '@modules-deprecated/inbox/types'
import { Contact } from '@views/contacts/types/contact'

import { FileUploadRefProps } from '../../../../../../components-deprecated/FileUpload'
import { ImageFile, UploadItem } from '../../../../../../components-deprecated/FileUpload/types'
import { useDeleteVoucherMutation } from '../../../../hooks/useDeleteVoucherMutation'
import { useUpdateVoucherMutation } from '../../../../hooks/useUpdateVoucherMutation'
import { useVoucherFileReplaceMutation } from '../../../../hooks/useVoucherFileReplaceMutation'
import { extendVoucherDescription } from '../../../../utils/extendVoucherDescription'
import { MissingInformationActions } from '../../MissingInformationActions'
import { MissingInformationContent } from '../../MissingInformationContent'

type FormInputs = {
  vendor: Contact | null
}

const defaultValues: FormInputs = {
  vendor: null,
}

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    vendor: yup
      .object()
      .shape({
        name: yup.string(),
        id: yup.string(),
      })
      .nullable()
      .required(t('things_to_do.missing_information.missing_supplier.error')),
  })

interface MissingSupplierProps {
  voucher: Voucher
}

export const MissingSupplier = ({ voucher }: MissingSupplierProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const fileUploadRef = useRef<FileUploadRefProps>(null)
  const form = useForm<FormInputs>({
    defaultValues,
    resolver: useMemo(() => yupResolver(validationSchema(t)), [t]),
  })

  const vendor = form.watch('vendor')

  const mutationBaseData = {
    organizationId: organization?.id as string,
    voucherId: voucher.id,
  }

  const deleteMutation = useDeleteVoucherMutation(mutationBaseData)
  const updateMutation = useUpdateVoucherMutation(mutationBaseData)
  const filesReplaceMutation = useVoucherFileReplaceMutation({
    ...mutationBaseData,
    onSuccess: () => {
      const payload = getUpdatePayload(voucher, vendor)
      updateMutation.mutate(payload)
    },
  })

  const isLoading = filesReplaceMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading

  const handleFileUpload = (_: UploadItem, itemFile?: ImageFile) => {
    if (!itemFile) {
      return
    }

    filesReplaceMutation.mutate([itemFile])
  }

  const handleFormSubmit = (data: FormInputs) => {
    const payload = getUpdatePayload(voucher, data.vendor)
    updateMutation.mutate(payload)
  }

  const handleSubmit = () => {
    const isSomeFileDropped = fileUploadRef.current?.isSomeFileDropped()

    if (isSomeFileDropped) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      fileUploadRef.current?.submitFileUpload()
      return
    }

    form.handleSubmit(handleFormSubmit)()
  }

  const handleDelete = useCallback(() => {
    deleteMutation.mutate()
  }, [deleteMutation])

  const handleFileDrop = (filesAccepted: File[]) => {
    if (filesAccepted.length > 0) {
      form.clearErrors()
    }
  }

  return (
    <MissingInformationContent
      ref={fileUploadRef}
      disabled={isLoading}
      onFileUpload={handleFileUpload}
      onFileDrop={handleFileDrop}
    >
      <FormProvider {...form}>
        <ReduxProvider store={store}>
          <ContactSelector
            label={t('vendor')}
            isVendor
            name="vendor"
            formControl={form.control}
            organizationId={organization?.id}
          />
        </ReduxProvider>
      </FormProvider>

      <MissingInformationActions onSubmit={handleSubmit} onDelete={handleDelete} disabled={isLoading} />
    </MissingInformationContent>
  )
}

function getUpdatePayload(voucher: Voucher, vendor: Contact | null): VoucherUpdatePayloadData {
  const description = vendor?.id
    ? extendVoucherDescription(voucher.description, { supplier: vendor?.name })
    : voucher.description

  return { description, inboxState: VoucherInboxState.RECEIVED }
}
