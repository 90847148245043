import { ExportAsSaftModal } from '@components'
import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { ReactElement, useCallback } from 'react'

import { EmberModal } from '../../contexts/emberCloseModalContext'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'export-as-saft-modal-module'

class ExportAsSaftModalWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('closeEmber') closeEmber: Function
}

interface ExportAsSaftModalProps {
  onCloseEmber: EmberModal
}

function ExportAsSaftModalModule({ onCloseEmber }: ExportAsSaftModalProps): ReactElement {
  const closeModal = useCallback(() => {
    onCloseEmber({ detail: null })
  }, [onCloseEmber])

  return (
    <ThemeProvider>
      <ModalContextProvider>
        <ExportAsSaftModal onModalClose={closeModal} />
      </ModalContextProvider>
    </ThemeProvider>
  )
}

const ExportAsSaftModalCustomElement = createCustomElement(
  ExportAsSaftModalModule,
  ExportAsSaftModalWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, ExportAsSaftModalCustomElement)

export default ExportAsSaftModalCustomElement
