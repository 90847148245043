import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const CustomDateWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const IconWrapper = styled.figure`
  display: flex;
  padding-right: ${Spacing.XXS};
  padding-left: ${Spacing.XXS};
`
