import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { EditCardSubscriptionParams, EditSubscriptionParams, FetchOrganizationParams } from '../types'

interface ContextState {
  plansSelectionMode?: boolean
  organizationId: string
  onEditCardSubscription: (event: EditCardSubscriptionParams) => void
  onEditSubscription: (event: EditSubscriptionParams) => void
  onFetchOrganization: (event: FetchOrganizationParams) => void
}

const OrganizationSubscriptionSettingsContext = createContext<ContextState | undefined>(undefined)

interface OrganizationSubscriptionSettingsContextProps {
  organizationId: string
  onEditCardSubscription: (event: EditCardSubscriptionParams) => void
  onEditSubscription: (event: EditSubscriptionParams) => void
  onFetchOrganization: (event: FetchOrganizationParams) => void
  children?: ReactNode
}

export const OrganizationSubscriptionSettingsContextProvider = ({
  organizationId,
  onEditCardSubscription,
  onEditSubscription,
  onFetchOrganization,
  children,
}: OrganizationSubscriptionSettingsContextProps): ReactElement => {
  const location = useLocation()
  const currentRouteName = location.pathname
  const plansSelectionMode = currentRouteName.includes('plans_selection')

  return (
    <OrganizationSubscriptionSettingsContext.Provider
      value={{
        plansSelectionMode,
        organizationId,
        onEditCardSubscription,
        onEditSubscription,
        onFetchOrganization,
      }}
    >
      {children}
    </OrganizationSubscriptionSettingsContext.Provider>
  )
}

export const useOrganizationSubscriptionSettings = () => {
  const context = useContext(OrganizationSubscriptionSettingsContext)

  if (!context) {
    throw new Error('OrganizationSubscriptionSettingsContextProvider is missing in the module!')
  }

  return context
}
