import { Translate } from '@components'
import { Link } from '@design-system'

import React, { memo, ReactElement } from 'react'

import { Tab } from '../enums/tab'
import * as Styled from './styles'

interface TabItem {
  id: Tab
  tLabel: string
}

const tabs: TabItem[] = [
  {
    id: Tab.WithVoucher,
    tLabel: 'things_to_do.missing_receipts.tabs.with_voucher',
  },
  {
    id: Tab.WithoutVoucher,
    tLabel: 'things_to_do.missing_receipts.tabs.without_voucher',
  },
]

interface TabsProps {
  activeTab: Tab
  onTabClick: (tab: Tab) => void
}

export const Tabs = memo(
  ({ activeTab, onTabClick }: TabsProps): ReactElement => (
    <Styled.TabItemsList>
      {tabs.map((tab) => (
        <Styled.TabItem key={tab.id}>
          <Link onClick={() => onTabClick(tab.id)} underlined={tab.id === activeTab} withAnimation>
            <Translate value={tab.tLabel} />
          </Link>
        </Styled.TabItem>
      ))}
    </Styled.TabItemsList>
  ),
)
