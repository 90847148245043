import { TFunction } from 'i18next'

import { SearchResultCategory } from '../types/searchResultCategory'

export const getCategoryTranslation = (t: TFunction): Record<SearchResultCategory, string> => ({
  account: t('global_search.category.account'),
  bill: t('global_search.category.bill'),
  contact: t('global_search.category.contact'),
  invoice: t('global_search.category.invoice'),
  organization: t('global_search.category.organization'),
  product: t('global_search.category.product'),
  menu: t('global_search.category.menu'),
})
