import { Button } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateOrEditAccessTokenModal } from '../../contexts/createOrEditAccessTokenModalContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { submitForm, isProcessing, actorId } = useCreateOrEditAccessTokenModal()

  return (
    <Button onClick={submitForm} loading={isProcessing}>
      {t(
        actorId
          ? 'settings.organization.access_tokens.create_or_edit_access_token_modal.button_update'
          : 'settings.organization.access_tokens.create_or_edit_access_token_modal.button_create',
      )}
    </Button>
  )
}
