import { encodePeriodValue } from '@components'

import React, { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useGlobalFiscalYear } from '../../../../../../contexts/globalFiscalYearContext'
import { PeriodValue } from '../../../../../../types/periodValue'
import { getPeriodValueFromFiscalYear } from '../../../../../../utils/getPeriodValueFromFiscalYear'
import { ReportFilter, ReportFilterValues } from '../../../../elements/ReportsFilters'
import { ReportsTableActions } from '../../../../elements/ReportsTableActions'
import { ComparisonValue } from '../../../../types/comparisonValue'
import { filterToPayloadOption } from '../../../../utils/reportFilterToPayloadOption'
import { useTrialBalanceFilters } from '../../hooks/useTrialBalanceFilters'

export const TrialBalanceTableActions = (): ReactElement => {
  const { organization } = useUserOrganization()
  const [queryParams, setQueryParams] = useTrialBalanceFilters()
  const { fiscalYear: globalFiscalYear } = useGlobalFiscalYear()
  const hasPeriodBeenSetRef = useRef(false)

  const filterValues: ReportFilterValues = useMemo(
    () => ({
      hideZero: Boolean(queryParams.hideZeroes),
      invertAmounts: Boolean(queryParams.inverted),
      showYtd: Boolean(queryParams.ytd),
    }),
    [queryParams.hideZeroes, queryParams.inverted, queryParams.ytd],
  )

  // Check the initial period
  useEffect(() => {
    if (!!organization?.id && globalFiscalYear !== undefined && !hasPeriodBeenSetRef.current) {
      setQueryParams((prevQuery) => {
        hasPeriodBeenSetRef.current = true

        if (!prevQuery.period) {
          return { period: encodePeriodValue(getPeriodValueFromFiscalYear(globalFiscalYear), organization.id) }
        }

        return prevQuery
      })
    }
  }, [globalFiscalYear, organization?.id, setQueryParams])

  const handlePeriodChange = useCallback(
    (periodValue?: PeriodValue) => {
      if (!organization?.id) {
        return
      }

      setQueryParams({ period: periodValue ? encodePeriodValue(periodValue, organization.id) : undefined })
    },
    [organization?.id, setQueryParams],
  )

  const handleComparisonPeriodSelect = useCallback(
    (_id: string, value: number) => {
      setQueryParams({ compareCount: value })
    },
    [setQueryParams],
  )

  const handleComparisonSelect = useCallback(
    (_id: string, value: ComparisonValue) => {
      setQueryParams({ compareWith: value })
    },
    [setQueryParams],
  )

  const handleFilterChange = useCallback(
    (filter: ReportFilter, value: boolean) => {
      const payloadOption = filterToPayloadOption[filter]
      setQueryParams({ [payloadOption]: value })
    },
    [setQueryParams],
  )

  return (
    <ReportsTableActions
      comparisonId={queryParams.compareWith}
      comparisonPeriodId={queryParams.compareCount?.toString()}
      encodedPeriod={queryParams.period}
      filterValues={filterValues}
      onComparisonPeriodSelect={handleComparisonPeriodSelect}
      onComparisonSelect={handleComparisonSelect}
      onFilterChange={handleFilterChange}
      onPeriodChange={handlePeriodChange}
    />
  )
}
