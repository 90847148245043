import { CurrencySelect } from '@components'
import { SkeletonButton } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../hooks/useForm'
import { BillState } from '../../../../enums/billState'
import { useBillForm } from '../../contexts/billFormContext'
import { BillFormSchema } from '../../utils/formData'

export const BillCurrencySelect = (): ReactElement => {
  const { t } = useTranslation()
  const { control, FormItem } = useFormContext<BillFormSchema>()
  const { isCreditNote, isCreditNoteFromBill } = useBillForm()
  const billState = useWatch({ control, name: 'state' })

  return (
    <FormItem
      label={t('bill.edit.form.currency')}
      name="currencyId"
      render={({ field: { value, onChange, ...rest } }) => (
        <>
          {value ? (
            <CurrencySelect
              defaultSelectedId={value}
              selectedId={value}
              onSelect={(id) => onChange(id)}
              {...rest}
              disabled={billState === BillState.Approved || isCreditNote || isCreditNoteFromBill}
            />
          ) : (
            <SkeletonButton fullWidth />
          )}
        </>
      )}
    />
  )
}
