import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { ReccuringInvoices } from '@views/recurringInvoices'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'recurring-invoices-module'

class RecurringInvoicesWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
}

interface RecurringInvoicesModuleProps {
  organizationId: string
  onNavigate: EmberNavigate
}

const RecurringInvoicesModule = ({ organizationId, onNavigate }: RecurringInvoicesModuleProps): ReactElement => (
  <ThemeProvider>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <CurrentUserContextProvider>
          <ModalContextProvider>
            <UserOrganizationContextProvider organizationId={organizationId}>
              <EmberRouterContextProvider onNavigate={onNavigate}>
                <ReccuringInvoices />
              </EmberRouterContextProvider>
            </UserOrganizationContextProvider>
          </ModalContextProvider>
        </CurrentUserContextProvider>
      </QueryClientProvider>
    </HashRouter>
  </ThemeProvider>
)

const RecurringInvoicesCustomElement = createCustomElement(
  RecurringInvoicesModule,
  RecurringInvoicesWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, RecurringInvoicesCustomElement)

export default RecurringInvoicesCustomElement
