import { Color } from '../../../enums/color'
import { Theme } from '../../../types/theme'
import { ColorVariant } from '../types/colorVariant'

export const getTextToColor = (theme: Theme): Record<ColorVariant, Color> => ({
  primary: theme.ds.colors.base.textPrimary,
  secondary: theme.ds.colors.base.textSecondary,
  danger: Color.Red,
  success: Color.Green90,
})
