import React from 'react'
import { Box, BoxProps } from 'rebass'

import { reactClass } from '../../utils'

export type FormControlProps = BoxProps

export const FormControl = React.forwardRef(({ children, ...rest }: FormControlProps, ref) => (
  <Box className={reactClass('form-control')} ref={ref} variant="forms.formcontrol" {...rest}>
    {children}
  </Box>
))
