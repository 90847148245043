import uniqueId from 'lodash/uniqueId'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { getArrayValue } from '../utils/getArrayValue'
import { toggleValue } from '../utils/toggleValue'

interface UseMultipleSelectableValuesProps {
  defaultValue?: string | string[]
  name?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onChangeMultiple?: (value: string[]) => void
  value?: string | string[]
}

export const useMultipleSelectableValues = ({
  defaultValue,
  name,
  onChange,
  onChangeMultiple,
  value,
}: UseMultipleSelectableValuesProps) => {
  const [checkedValue, setCheckedValue] = useState<string[] | undefined>(getArrayValue(defaultValue ?? value))
  const groupName = useMemo(() => name || uniqueId('componentgroup-'), [name])

  const handleComponentChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checkedValueUpdated = toggleValue(checkedValue || [], event.currentTarget.value)
      setCheckedValue(checkedValueUpdated)
      onChangeMultiple?.(checkedValueUpdated)
      onChange?.(event)
    },
    [checkedValue, onChange, onChangeMultiple],
  )

  useEffect(() => {
    if (value !== undefined) {
      setCheckedValue(getArrayValue(value))
    }
  }, [value])

  return {
    checkedValue,
    groupName,
    handleComponentChange,
  }
}
