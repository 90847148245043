import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SettingsSectionRowsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${Spacing.XXL};
`
