import { format } from 'date-fns'

import { getCurrentLocale } from '../../../utils/getCurrentLocale'

export const getDisplayDateFormat = (withHour?: boolean) => {
  const locale = getCurrentLocale()
  const isUSLocale = locale === 'en_US'

  if (withHour) {
    return isUSLocale ? 'MM/dd/yyyy HH:mm' : 'dd/MM/yyyy HH:mm'
  }

  return isUSLocale ? 'MM/dd/yyyy' : 'dd/MM/yyyy'
}

export const getDisplayDate = (date?: Date) => {
  return date ? format(date, getDisplayDateFormat()) : ''
}
