import { Button, ButtonProps } from '@design-system'

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SpecificState } from '../../../types/reduxSaga-deprecated'
import { getAccessToken } from '../../../utils'
import { formatDateRangePeriod } from '../utils'

export type DownloadLinesButtonProps = ButtonProps

export const DownloadLinesButton = ({ ...rest }: DownloadLinesButtonProps) => {
  const { t } = useTranslation()

  const account = useSelector((state: SpecificState) => state.app.account)
  const organizationId = useSelector((state: SpecificState) => state.app.organization)?.id || ''
  const fiscalYearEndMonth = useSelector((state: SpecificState) => state.app.organization)?.fiscalYearEndMonth ?? 12
  const filters = useSelector((state: SpecificState) => state.bankReconciliation.filters)

  const handleDownloadLines = useCallback(() => {
    if (!account || !organizationId) {
      console.warn('No account or organization provided')
      return
    }
    const { fiscalYear, dateRange } = filters
    const { from, to } = formatDateRangePeriod(fiscalYear, fiscalYearEndMonth, dateRange)
    const accessToken = getAccessToken() || ''
    const apiUrl = window.ENV ? window.ENV.newApiUrl : process.env.REACT_APP_API_URL
    const url = `${apiUrl}/organizations/${organizationId}/bankLines/download`

    const params = new URLSearchParams()
    params.append('access_token', accessToken)
    params.append('accountId', account?.id)
    params.append('isReconciled', 'false')

    if (from) {
      params.append('minEntryDate', from)
    }
    if (to) {
      params.append('maxEntryDate', to)
    }

    window.open(`${url}?${params.toString()}`, '_blank')
  }, [account, filters, fiscalYearEndMonth, organizationId])

  return (
    <div data-tip={t('bankreconciliation.download_lines.trigger_tooltip')}>
      <Button {...rest} icon="arrowPointingDown" onClick={handleDownloadLines} size="m" variant="text" />
    </div>
  )
}
