import debounce from 'lodash/debounce'
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  InputHTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { KeyboardKey } from '../../../enums/keyboardKey'
import { useTheme } from '../../hooks/useTheme'
import { ImperativeRef } from '../../types/imperativeRef'
import { Timeout } from '../../types/timeout'
import { ClearInputButton } from './elements/ClearInputButton'
import { ErrorIcon } from './elements/ErrorIcon'
import { InputPrefix } from './elements/InputPrefix'
import { InputSuffix } from './elements/InputSuffix'
import { SuccessIcon } from './elements/SuccessIcon'
import * as Styled from './styles'
import { Alignment } from './types/alignment'
import { ColorVariant } from './types/colorVariant'
import { InputSize } from './types/size'
import { InputWeight } from './types/weight'
import { getTextColor } from './utils/getTextColor'

const DEFAULT_DEBOUNCE_RATE = 500
const RENDER_SAFETY_TIMEOUT_MS = 50

export type InputType = InputHTMLAttributes<HTMLInputElement>
export type Value = InputType['value']

export interface InputPropsRaw {
  alignment?: Alignment
  allowClear?: boolean
  autoCompleted?: boolean
  autoFocus?: boolean
  /** allows hiding border in input */
  bordered?: boolean
  className?: string
  colorVariant?: ColorVariant
  debounceRate?: number
  defaultValue?: Value
  disabled?: boolean
  error?: boolean
  focused?: boolean
  hidden?: boolean
  /** Debounce is set for 500ms */
  onChangeDebounced?: (event: ChangeEvent<HTMLInputElement>) => void
  onClear?: () => void
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
  onMouseUp?: (event: MouseEvent<HTMLDivElement>) => void
  onMouseDown?: (event: MouseEvent<HTMLDivElement>) => void
  onPressEnter?: (event: KeyboardEvent<HTMLInputElement>) => void
  /** element rendered on the left side of the input */
  prefix?: ReactNode
  /** enabled 'select' look for the input: bolded font + box-shadow */
  selectLook?: boolean
  /** do selection of the whole text on input focus */
  selectOnFocus?: boolean
  size?: InputSize
  success?: boolean
  /** element rendered on the right side of the input */
  suffix?: ReactNode
  truncate?: boolean
  value?: Value
  weight?: InputWeight
}

export interface InputRef extends ImperativeRef {
  blur: () => void
  focus: () => void
  input: HTMLInputElement
}

export type InputProps = Omit<InputType, 'size' | 'prefix'> & InputPropsRaw

export const Input = forwardRef<InputRef, InputProps>(
  (
    {
      alignment = 'left',
      allowClear,
      autoCompleted = false,
      autoFocus = false,
      bordered = true,
      className,
      colorVariant = 'primary',
      debounceRate = DEFAULT_DEBOUNCE_RATE,
      defaultValue,
      disabled = false,
      error = false,
      focused = false,
      hidden = false,
      id,
      name,
      onBlur,
      onChange,
      onChangeDebounced,
      onClear,
      onClick,
      onMouseUp,
      onMouseDown,
      onFocus,
      onKeyPress,
      onPressEnter,
      selectOnFocus = false,
      prefix,
      readOnly = false,
      selectLook = false,
      size = 'l',
      success = false,
      suffix,
      truncate = false,
      type = 'text',
      value = '',
      weight = 'regular',
      ...inputRest
    },
    forwardedRef,
  ) => {
    const [currentValue, setCurrentValue] = useState<Value>(defaultValue ?? value)
    const [isFocused, setIsFocused] = useState(false)
    const inputWrapperRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const selectTimeoutRef = useRef<Timeout>()
    const focusTimeoutRef = useRef<Timeout>()
    const hasClickedInsideInputWrapper = useRef(false)
    const theme = useTheme()
    const textColor = getTextColor(theme)[colorVariant]

    const isClearable = !!currentValue && allowClear

    const clearTimeouts = useCallback(() => {
      if (focusTimeoutRef.current) {
        clearTimeout(focusTimeoutRef.current)
      }

      if (selectTimeoutRef.current) {
        clearTimeout(selectTimeoutRef.current)
      }
    }, [])

    // Helpers

    const focus = useCallback(() => {
      focusTimeoutRef.current = setTimeout(() => {
        inputRef.current?.focus()
      }, RENDER_SAFETY_TIMEOUT_MS)
    }, [])

    const select = useCallback(() => {
      selectTimeoutRef.current = setTimeout(() => {
        inputRef.current?.select()
      }, RENDER_SAFETY_TIMEOUT_MS)
    }, [])

    // Functions

    const handleValueChange = useCallback((newValue: Value) => {
      setCurrentValue(newValue)
    }, [])

    const handleDebouncedInputChange = useCallback(
      debounce((event: ChangeEvent<HTMLInputElement>) => onChangeDebounced?.(event), debounceRate, {
        leading: false,
        trailing: true,
      }),
      [onChangeDebounced],
    )

    const handleInputChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
          return
        }

        handleValueChange(event?.currentTarget.value)
        handleDebouncedInputChange(event)
        onChange?.(event)
      },
      [disabled, handleDebouncedInputChange, handleValueChange, onChange],
    )

    const handleWrapperMouseUp = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        if (disabled) {
          return
        }

        hasClickedInsideInputWrapper.current = false
        onMouseUp?.(event)
      },
      [disabled, onMouseUp],
    )

    const handleWrapperMouseDown = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        if (disabled) {
          return
        }

        hasClickedInsideInputWrapper.current = true
        setIsFocused(true)
        onMouseDown?.(event)
        focus()
      },
      [disabled, focus, onMouseDown],
    )

    const handleInputBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (hasClickedInsideInputWrapper.current) {
          hasClickedInsideInputWrapper.current = false
          inputRef.current?.focus()
          return
        }

        clearTimeouts()

        setIsFocused(false)
        onBlur?.(event)
      },
      [clearTimeouts, onBlur],
    )

    const handleInputFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true)
        onFocus?.(event)

        if (selectOnFocus) {
          select()
        }
      },
      [onFocus, selectOnFocus, select],
    )

    const handleKeyPress = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === KeyboardKey.Enter) {
          onPressEnter?.(event)
        }

        onKeyPress?.(event)
      },
      [onPressEnter, onKeyPress],
    )

    const handleClearInputButtonClick = useCallback(() => {
      handleValueChange('')
      onClear?.()
    }, [handleValueChange, onClear])

    // Lifecycle

    useEffect(() => {
      if (autoFocus) {
        focus()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoFocus])

    useEffect(() => {
      if (value !== undefined) {
        handleValueChange(value)
      }
    }, [value, handleValueChange])

    useEffect(() => {
      if (focused) {
        focus()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused])

    useEffect(() => {
      return () => {
        clearTimeouts()
      }
    }, [clearTimeouts])

    // Hooks utils

    useImperativeHandle(forwardedRef, () => ({
      focus: () => {
        setIsFocused(true)
        inputRef.current?.focus()
      },
      blur: () => {
        setIsFocused(false)
        inputRef.current?.blur()
      },
      get input() {
        return inputRef.current as HTMLInputElement
      },
      get wrapper() {
        return inputWrapperRef.current as HTMLDivElement
      },
    }))

    return (
      <Styled.InputWrapper
        alignment={alignment}
        autoCompleted={autoCompleted}
        bordered={bordered}
        className={className}
        clearable={isClearable}
        data-testid="input-wrapper"
        disabled={disabled}
        error={error}
        focused={isFocused}
        hidden={hidden}
        onClick={onClick}
        onMouseDown={handleWrapperMouseDown}
        onMouseUp={handleWrapperMouseUp}
        ref={inputWrapperRef}
        selectLook={selectLook}
        size={size}
        truncate={truncate}
        weight={weight}
      >
        {prefix && (
          <InputPrefix inputSize={size} disabled={disabled} data-testid="input-prefix">
            {prefix}
          </InputPrefix>
        )}

        <Styled.Input
          {...inputRest}
          color={textColor}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onKeyPress={handleKeyPress}
          readOnly={readOnly}
          ref={inputRef}
          tabIndex={readOnly ? -1 : 0}
          type={type}
          value={currentValue}
        />
        {allowClear && !disabled && (
          <Styled.ClearInputWrapper>
            <ClearInputButton onClick={handleClearInputButtonClick} />
          </Styled.ClearInputWrapper>
        )}
        {suffix && (
          <InputSuffix inputSize={size} disabled={disabled} data-testid="input-suffix">
            {suffix}
          </InputSuffix>
        )}
        {error && <ErrorIcon inputSize={size} disabled={disabled} />}
        {success && <SuccessIcon inputSize={size} disabled={disabled} />}
      </Styled.InputWrapper>
    )
  },
)
