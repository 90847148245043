import { TFunction } from 'i18next'
import { object, SchemaOf, string } from 'yup'

import { Organization } from '@modules-deprecated/app/organization/types/organization'
import { User } from '@modules-deprecated/app/user'

export interface RegisterInternationallyForm {
  name: string
  cvr: string
  email: string
}

export type RegisterInternationallyFormSchema = SchemaOf<RegisterInternationallyForm>

export const getValidationSchema = (t: TFunction): RegisterInternationallyFormSchema =>
  object({
    cvr: string().required(t('modal.register_internationally_form.required')),
    email: string().email().required(t('modal.register_internationally_form.required')),
    name: string().required(t('modal.register_internationally_form.required')),
  })

export const getDefaultValues = (organization?: Organization, user?: User): RegisterInternationallyForm => ({
  cvr: organization?.registrationNo || '',
  email: user?.email || '',
  name: user?.name || '',
})
