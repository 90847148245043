import { Actor } from '../../../types/actor'
import { AccessTokenForm } from './formData'

export const getPayloadFromAccessTokenForm = (
  accessTokenForm: AccessTokenForm,
  organizationId: string,
): Partial<Actor> => {
  const payload: Partial<Actor> = {
    name: accessTokenForm.name,
    organizationId,
    description: accessTokenForm.description,
  }

  return payload
}
