import { Button, Color, Input, Text } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../../../hooks'
import { useEInvoiceContext } from '../../../../context/eInvoiceContext'
import { Identifier } from '../../../../enums/identifier'
import { SendEInvoiceFormSchema } from '../../../../utils/eInvoiceFormData'
import { isForeignGln } from '../../../RegisterInternationallyFormModal/utils/isForeignGln'
import { GLN_LENGTH } from '../../constants/glnLength'

interface GlnFormItemProps {
  canUpdateContactGln: boolean
  handleUpdateContactWithGln: () => void
}

export const GlnFormItem = ({
  canUpdateContactGln,
  handleUpdateContactWithGln,
}: GlnFormItemProps): ReactElement | null => {
  const { t } = useTranslation()

  const { control, FormItem } = useFormContext<SendEInvoiceFormSchema>()
  const { isIdentifierVerified, isIdentifierChecked, isPeppolVerified } = useEInvoiceContext()

  const identifierType = useWatch({ control, name: 'identifierType' })
  const glnInput = useWatch({ control, name: 'gln' })

  const glnInputMessage = useMemo(() => {
    if (!isIdentifierChecked) {
      return
    }

    if (glnInput?.length !== GLN_LENGTH) {
      return (
        <Text variant="small" colorVariant="danger">
          {t('modal.send_invoice.form.gln.invalid')}
        </Text>
      )
    }

    if (canUpdateContactGln) {
      return (
        <Text variant="small" colorVariant="secondary">
          {t('modal.send_invoice.form.gln.updatable')}
        </Text>
      )
    }

    if (!isIdentifierVerified && isIdentifierChecked && isPeppolVerified && isForeignGln(glnInput)) {
      return (
        <Text variant="small" color={Color.Red}>
          {t('modal.send_invoice.form.gln.no_peppol')}
        </Text>
      )
    }

    if (!isIdentifierVerified && isIdentifierChecked) {
      return (
        <Text variant="small" colorVariant="danger">
          {t('modal.send_invoice.form.gln.cannot_receive')}
        </Text>
      )
    }
  }, [isIdentifierChecked, glnInput, canUpdateContactGln, isIdentifierVerified, isPeppolVerified, t])

  if (identifierType === Identifier.CVR) {
    return null
  }

  return (
    <FormItem
      name="gln"
      extra={glnInputMessage}
      render={({ field }) => (
        <Input
          {...field}
          autoFocus
          success={isIdentifierVerified && isIdentifierChecked}
          error={!isIdentifierVerified && isIdentifierChecked}
          suffix={
            canUpdateContactGln && (
              <Button size="m" variant="secondary" onClick={handleUpdateContactWithGln}>
                {t('modal.send_invoice.form.identifier.update_contact')}
              </Button>
            )
          }
        />
      )}
    />
  )
}
