import { Color, DefaultValue, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const FileInfo = styled.div`
  background-color: ${Color.Gray20};
  height: 62px;
  width: 100%;
  display: flex;
  gap: ${Spacing.S};
  justify-content: space-between;
  align-items: flex-end;
  border-top-left-radius: ${DefaultValue.BorderRadius};
  border-top-right-radius: ${DefaultValue.BorderRadius};
  padding: ${Spacing.M} ${Spacing.L};
`

export const FileLabel = styled.div`
  overflow: hidden;
`

export const AmountLabel = styled(Text)`
  flex-shrink: 0;
`

export const PreviewWrapper = styled.div`
  padding-left: ${Spacing.L};
  border-left: 1px solid ${Color.Gray30};
  width: 347px;
  height: 100%;
`

export const FileImageWrapper = styled.div`
  border: 2px solid ${Color.Gray20};
  border-bottom-left-radius: ${DefaultValue.BorderRadius};
  border-bottom-right-radius: ${DefaultValue.BorderRadius};
  height: 460px;
`

export const HoverHelperImage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Color.Purple20};
  border-radius: ${DefaultValue.BorderRadius};
`

export const Footer = styled.footer`
  width: 266px;
  padding-top: ${Spacing.XXL};
`
