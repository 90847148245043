import { useUserOrganization } from '@modules-deprecated/app/organization'

import { OrganizationFeature, useOrganizationFeature } from '../../settings/routes/OrganizationFeaturesSettings'

// This is a temporary solution for convenience only.
// Normally, this should just be the beta feature toggler,
// but here we're adding additional conditions to make it easier to test
export const useInvoiceEditorBeta = () => {
  const { hasFeature } = useOrganizationFeature()
  const { organization } = useUserOrganization()

  // this email condition is async, so e.g. entering a restricted url directly might not work
  const organizationEmailDomain = organization?.email?.split('@')?.[1] || ''
  const isValidEmailDomain = organizationEmailDomain === 'ageras.com'

  const hasInvoiceEditorBetaFeature = hasFeature(OrganizationFeature.InvoiceEditorBeta)
  const isEligibleForInvoiceEditorBeta = hasInvoiceEditorBetaFeature || isValidEmailDomain

  return {
    isEligibleForInvoiceEditorBeta,
  }
}
