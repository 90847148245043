import React from 'react'

import { IconProps } from '../Icon'

export const IconCheckRound = ({ color = 'inherit', width = '20px', height = '20px', ...rest }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M15.6999 5.90004C15.0999 3.80004 13.7999 2.10004 11.9999 1.00004C10.0999 3.98159e-05 7.9999 -0.29996 5.8999 0.30004C3.8999 0.80004 2.0999 2.20004 1.0999 4.00004C-9.57251e-05 5.90004 -0.300096 8.00004 0.299904 10.1C0.899904 12.2 2.1999 13.9 3.9999 15C5.1999 15.6 6.5999 16 7.9999 16C8.6999 16 9.39991 15.9 10.0999 15.7C12.1999 15.1 13.8999 13.8 14.9999 12C15.9999 10.1 16.2999 8.00004 15.6999 5.90004ZM13.6999 11.3C12.7999 12.8 11.3999 13.9 9.6999 14.4C7.9999 14.9 6.1999 14.6 4.6999 13.7C3.1999 12.8 2.0999 11.4 1.5999 9.70004C1.0999 8.00004 1.3999 6.20004 2.2999 4.70004C3.1999 3.20004 4.5999 2.10004 6.2999 1.60004C6.8999 1.40004 7.3999 1.40004 7.9999 1.40004C9.09991 1.40004 10.2999 1.70004 11.2999 2.30004C12.7999 3.20004 13.8999 4.60004 14.3999 6.30004C14.8999 8.00004 14.5999 9.80004 13.6999 11.3Z"
        fill="#5AA547"
      />
      <path
        d="M7.3998 11C7.1998 11 6.9998 11 6.8998 10.8L4.4998 8.6C4.3998 8.5 4.2998 8.3 4.2998 8.1C4.2998 7.9 4.3998 7.7 4.4998 7.6C4.6998 7.5 4.7998 7.4 4.9998 7.4C5.1998 7.4 5.2998 7.5 5.4998 7.6L7.2998 9.3L10.4998 5.3C10.5998 5.1 10.7998 5 10.9998 5C11.1998 5 11.2998 5 11.3998 5.1C11.4998 5.2 11.5998 5.4 11.6998 5.6C11.6998 5.8 11.6998 6 11.5998 6.1L7.9998 10.8C7.7998 10.9 7.5998 11 7.3998 11Z"
        fill="#5AA547"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
