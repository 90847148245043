import { ButtonDropdownMultiple, NavItem, Text } from '@design-system'

import { ChangeEvent, ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TransactionOriginatorTypeGroup } from '../../../../enums/transactionOriginatorTypeGroup'
import { useTransactionsListFilters } from '../../hooks/useTransactionsListFilters'
import * as Styled from './styles'
import { getTransactionsOriginatorTypeGroupsData } from './utils/getTransactionsOriginatorTypeGroupsData'

export const TransactionsTypesSelector = (): ReactElement => {
  const { t } = useTranslation()
  const [{ originatorTypeGroup }, setFilters] = useTransactionsListFilters()

  const items: NavItem<TransactionOriginatorTypeGroup>[] = useMemo(
    () => getTransactionsOriginatorTypeGroupsData(t),
    [t],
  )

  const handleSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>, values: TransactionOriginatorTypeGroup[]) => {
      setFilters({ originatorTypeGroup: values })
    },
    [setFilters],
  )

  return (
    <Styled.TransactionsTypesSelectorWrapper>
      <Text colorVariant="secondary">{t('transactions.table_actions.types_selector.label')}:</Text>
      <ButtonDropdownMultiple
        items={items}
        onSelect={handleSelect}
        selectedValues={originatorTypeGroup}
        placement="bottom-start"
        selectAllText={t('transactions.table_actions.types_selector.all')}
        withSelectAll
      />
    </Styled.TransactionsTypesSelectorWrapper>
  )
}
