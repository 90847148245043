import noop from 'lodash/noop'
import { Context, createContext, ReactElement, ReactNode, useCallback, useContext, useState } from 'react'
import { Row } from 'react-table'

import { TableData } from '../types/data'
import { SelectType } from '../types/selectType'

type SelectedRow = string

interface ContextState<T extends TableData> {
  areAllRowsSelected: boolean
  selectAllRows: (allRows: Row<T>[], isSelected: boolean) => void
  selectedRows: SelectedRow[]
  selectRow: (row: Row<T>, isSelected: boolean) => void
}

const defaultValue = {
  areAllRowsSelected: false,
  selectAllRows: noop,
  selectedRows: [],
  selectRow: noop,
}

const SelectRowsContext = createContext<ContextState<any>>(defaultValue)

interface SelectRowsContextProviderProps<T extends TableData> {
  children: ReactNode
  onSelect?: (row: Row<T>, isSelected: boolean) => void
  onSelectAll?: (rows: Row<T>[], isSelected: boolean) => void
  rowsCount: number
  selectType: SelectType
}

export const SelectRowsContextProvider = <T extends TableData>({
  children,
  onSelect,
  onSelectAll,
  rowsCount,
  selectType,
}: SelectRowsContextProviderProps<T>): ReactElement => {
  const [areAllRowsSelected, setAreAllRowsSelected] = useState(false)
  const [selectedRows, setSelectedRows] = useState<SelectedRow[]>([])

  const handleSelectRow = useCallback(
    (row: Row<T>, isSelected: boolean) => {
      const { id } = row.original

      onSelect?.(row, isSelected)

      if (selectType === 'radio') {
        setSelectedRows(isSelected ? [id] : [])
        return
      }

      if (isSelected) {
        setSelectedRows((previousSelectedRows) => {
          const newSelectedRows = [...previousSelectedRows, id]

          setAreAllRowsSelected(newSelectedRows.length === rowsCount)

          return newSelectedRows
        })
      } else {
        setSelectedRows((previousSelectedRows) => previousSelectedRows.filter((selectedRowId) => selectedRowId !== id))
        setAreAllRowsSelected(false)
      }
    },
    [onSelect, rowsCount, selectType],
  )

  const handleSelectAllRows = useCallback(
    (allRows: Row<T>[], isSelected: boolean) => {
      onSelectAll?.(allRows, isSelected)

      setAreAllRowsSelected(isSelected)

      if (isSelected) {
        setSelectedRows(allRows.map((row) => row.original.id))
      } else {
        setSelectedRows([])
      }
    },
    [onSelectAll],
  )

  return (
    <SelectRowsContext.Provider
      value={{
        areAllRowsSelected,
        selectAllRows: handleSelectAllRows,
        selectedRows,
        selectRow: handleSelectRow,
      }}
    >
      {children}
    </SelectRowsContext.Provider>
  )
}

export const useSelectRows = <T extends TableData>() =>
  useContext<ContextState<T>>(SelectRowsContext as unknown as Context<ContextState<T>>)
