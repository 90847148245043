import { Flex, FormItemsGroup, Icon, SectionMessage, Space, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../hooks'
import { useCreateOrEditVatRateFromTemplateModal } from '../../context/createOrEditVatRateFromTemplateModalContext'
import { CreateOrEditVatRateFromTemplateFormSchema } from '../../utils/formData'
import { VatRatesTemplatesSelect } from '../VatRatesTemplatesSelect'

export const MigrateVatRateTemplateSelection = (): ReactElement | null => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<CreateOrEditVatRateFromTemplateFormSchema>()
  const { vatRate } = useCreateOrEditVatRateFromTemplateModal()

  if (vatRate?.predefinedTag) {
    return null
  }

  return (
    <>
      <SectionMessage closable={false} showIcon={false}>
        <Text variant="large">
          {t('settings.organization.vat_rates.modals.create_or_edit_vat_rate_from_template.migrate_message.title')}
        </Text>
        <Space size="m" />
        <Flex alignItems="center">
          <Flex.Item>
            <Icon icon="exclamationMarkCircle" size="default" />
          </Flex.Item>
          <Flex.Item>
            <Space size="s" horizontal />
          </Flex.Item>
          <Flex.Item>
            <Text variant="large">
              {t(
                'settings.organization.vat_rates.modals.create_or_edit_vat_rate_from_template.migrate_message.description',
              )}
            </Text>
          </Flex.Item>
        </Flex>
        <Space size="m" />
        <FormItemsGroup itemsInRow={1}>
          <FormItem
            name="predefinedTag"
            render={({ field: { value, onChange, ...props } }) => (
              <VatRatesTemplatesSelect
                {...props}
                allowClear
                dropdownSize="fitTrigger"
                onSelect={(id) => {
                  onChange(id)
                }}
                selectedId={value}
                placeholder={t(
                  'settings.organization.vat_rates.modals.create_or_edit_vat_rate_from_template.template_select_placeholder',
                )}
              />
            )}
          />
        </FormItemsGroup>
      </SectionMessage>
      <Space size="xl" />
    </>
  )
}
