import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

export interface SettingsSectionsColumnsWrapperProps {
  children: ReactNode
}

export const SettingsSectionsColumnsWrapper = ({ children }: SettingsSectionsColumnsWrapperProps): ReactElement => {
  return <Styled.SectionsColumnsWrapper>{children}</Styled.SectionsColumnsWrapper>
}
