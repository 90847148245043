import React, { FormHTMLAttributes, forwardRef, ReactElement, ReactNode, Ref } from 'react'

import { FormContextProvider } from './contexts/formContext'
import * as Styled from './styles'
import { FormLayout } from './types/formLayout'

export interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  children: ReactNode
  name?: string
  layout?: FormLayout
}

export const Form = forwardRef(
  ({ children, name, layout, ...rest }: FormProps, forwardedRef: Ref<HTMLFormElement>): ReactElement => (
    <Styled.FormWrapper ref={forwardedRef} {...rest}>
      <FormContextProvider formName={name} formLayout={layout}>
        {children}
      </FormContextProvider>
    </Styled.FormWrapper>
  ),
)
