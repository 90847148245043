import React, { ReactElement } from 'react'

import { Button } from '../../../Button'
import { IconName } from '../../../Icon'

interface PaginationButtonProps {
  disabled: boolean
  icon: IconName
  onClick: () => void
  title: string
}

export const PaginationButton = ({ disabled, icon, onClick, title }: PaginationButtonProps): ReactElement => (
  <Button disabled={disabled} icon={icon} onClick={onClick} size="m" title={title} variant="text" />
)
