import { useFetchAccessToken } from '@views/settings/routes/OrganizationAccessTokensSettings/hooks/useFetchAccessToken'
import { useFetchActors } from '@views/settings/routes/OrganizationAccessTokensSettings/hooks/useFetchActors'

import { DEBT_COLLECTION_ACTOR_NAME } from '../constants/debtCollectionActor'

interface UseFetchDebtCollectionTokenResponse {
  token: string | null
  isLoading: boolean
}

export const useFetchDebtCollectionToken = (): UseFetchDebtCollectionTokenResponse => {
  const { actors, isLoading: isLoadingActors } = useFetchActors()

  const debtCollectionActorId: string | undefined = actors?.find((actor) => {
    return actor?.name === DEBT_COLLECTION_ACTOR_NAME
  })?.id

  const { accessToken: debtCollectionToken, isLoading: isLoadingToken } = useFetchAccessToken(debtCollectionActorId)

  if (!debtCollectionActorId && !isLoadingActors) {
    return { token: null, isLoading: false }
  }

  const isLoading = isLoadingActors || isLoadingToken

  return { token: debtCollectionToken, isLoading }
}
