import { InvoiceBalanceModifier } from '../../../types/invoiceBalanceModifier'
import { InvoiceBalanceModifierReference } from '../enums/InvoiceBalanceModifierReference'
import { getInvoiceBalanceModifierReference } from './getInvoiceBalanceModifierReference'

interface ModifiersCount {
  payments: number
  fees: number
}

export const getModifiersCount = (balanceModifiers: InvoiceBalanceModifier[]): ModifiersCount => {
  const modifiersCount: ModifiersCount = {
    payments: 0,
    fees: 0,
  }

  for (const { modifierReference } of balanceModifiers) {
    const modifierData = getInvoiceBalanceModifierReference(modifierReference || '')
    const { modifierType } = modifierData || {}

    switch (modifierType) {
      case InvoiceBalanceModifierReference.InvoiceLateFee:
        modifiersCount.fees++
        break
      default:
        modifiersCount.payments++
        break
    }
  }

  return modifiersCount
}
