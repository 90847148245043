import { Locale, localeToCountryCode, NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { locales } from '../../../../utils/i18n'
import { CountryFlag } from '../../../CountryFlag'

/* eslint-disable @typescript-eslint/naming-convention */
const localeToLanguageTKey: Record<Locale, string> = {
  da_DK: 'settings.user.language.da_DK',
  en_US: 'settings.user.language.en_US',
  fr_FR: 'settings.user.language.fr_FR',
}
/* eslint-enable @typescript-eslint/naming-convention */

export const getLanguageItems = (t: TFunction): NavItem<Locale>[] => {
  return Object.keys(locales).map((language) => {
    const locale = locales[language]
    const countryFromLocale = localeToCountryCode[locale]
    return {
      id: locale,
      children: t(localeToLanguageTKey[locale]),
      title: countryFromLocale,
      iconLeft: <CountryFlag countryCode={countryFromLocale} />,
      value: locale,
    }
  })
}
