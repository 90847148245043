import get from 'lodash/get'

import { themeLight } from '../themes/billy'

/**
 * DEPRECATED
 * Given a name property available in theme.colors, returns the value of it
 * Use it to extract colors without the need of useTheme()
 * @param  string color The color property
 * @return string       The actual color as string to be use in template strings and Styled System props
 */
export const coloring = (color: string) => {
  return (color && get(themeLight?.colors, color)) || color
}
