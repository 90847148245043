import { addDays, endOfDay, isBefore } from 'date-fns'
import { useEffect, useState } from 'react'

import { useUpdateOrganizationSettings, useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

import { SignupCouponParamsSettingsKeys } from '../../../enums/signupCouponParamsSettingsKeys'
import { isValidSubscriptionPlan } from '../../../utils/isValidSubscriptionPlan'

interface QueryParams {
  coupon?: string
  duration?: string
  plan?: string
  period?: string
}

interface UseCouponParamsProps {
  organizationId: string
  params: QueryParams
  handlePlanSelect: (plan: SubscriptionPlan, period?: SubscriptionPeriod) => void
}

export const useCouponParams = ({ params, organizationId, handlePlanSelect }: UseCouponParamsProps) => {
  const {
    coupon: signupCouponId,
    duration: signupAvailableDays,
    plan: signupCouponPlan,
    period: signupCouponPeriod,
  } = params

  const [isAutoCouponModalOpened, setIsAutoCouponModalOpened] = useState(false)
  const { organizationSettings, isLoading: isOrganizationSettingsLoading } = useUserOrganizationSettings()

  const { update: updateSettings } = useUpdateOrganizationSettings()

  // Stores params of coupon passed via URL to the organization settings
  useEffect(() => {
    if (
      !signupCouponId ||
      !signupCouponPeriod ||
      !isValidSubscriptionPlan(signupCouponPlan) ||
      !signupCouponPlan ||
      !signupAvailableDays ||
      organizationSettings?.signupPromoEndDate
    ) {
      return
    }

    updateSettings({
      key: SignupCouponParamsSettingsKeys.SignupCouponId,
      organizationId,
      value: signupCouponId,
    })

    updateSettings({
      key: SignupCouponParamsSettingsKeys.SignupPeriod,
      organizationId,
      value: signupCouponPeriod,
    })

    updateSettings({
      key: SignupCouponParamsSettingsKeys.SignupPlan,
      organizationId,
      value: signupCouponPlan,
    })

    const endDate = endOfDay(addDays(new Date(), parseInt(signupAvailableDays as string, 10)))
    updateSettings({
      key: SignupCouponParamsSettingsKeys.SignupPromoEndDate,
      organizationId,
      value: endDate.toISOString(),
    })
  }, [
    organizationId,
    organizationSettings?.signupPromoEndDate,
    signupAvailableDays,
    signupCouponId,
    signupCouponPeriod,
    signupCouponPlan,
    updateSettings,
  ])

  // Open modal for coupons passed from partner pages or emails that are stored in organization settings
  useEffect(() => {
    // Precondition - do not open if settings are not loaded, modal was already opened or there is no coupon in URL param
    if (isOrganizationSettingsLoading || !organizationSettings || isAutoCouponModalOpened || !signupCouponId) {
      return
    }

    const { signupPeriod, signupPlan: currentSignupPlan, signupPromoEndDate } = organizationSettings

    const isApplicable = signupPromoEndDate ? isBefore(new Date(), new Date(signupPromoEndDate)) : false

    // If there are no settings that could be applied or the coupon is outtdated, also do not open
    if (!isApplicable || !currentSignupPlan || !signupPromoEndDate) {
      return
    }

    setIsAutoCouponModalOpened(true)
    handlePlanSelect(currentSignupPlan, signupPeriod)
  }, [handlePlanSelect, isAutoCouponModalOpened, isOrganizationSettingsLoading, organizationSettings, signupCouponId])
}
