import { amountToDisplayValue } from '@design-system'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Contact } from '@views/contacts/types/contact'
import { getContactName } from '@views/contacts/utils/getContactName'

import { RecurringInvoice } from '../../../types/recurringInvoice'
import { RecurringInvoicesTableRow } from '../../../types/recurringInvoicesTableRow'
import { getRecurringInvoiceStatus } from '../utils/getRecurringInvoiceStatus'
import { getRecurringPeriod } from '../utils/getRecurringPeriod'
import { getRecurringSpawnMode } from '../utils/getRecurringSpawnMode'

export const useRecurringInvoicesTableData = (
  recurringInvoices: RecurringInvoice[],
  contacts: Contact[],
): RecurringInvoicesTableRow[] => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      recurringInvoices.map(
        ({
          contactId,
          currencyCode,
          id,
          netAmount,
          nextSpawnTimestamp,
          recurringInterval,
          recurringSpawnMode,
          sendErrorCode,
          spawnErrorCode,
        }) => {
          return {
            status: getRecurringInvoiceStatus({
              nextSpawnTimestamp,
              sendErrorCode,
              spawnErrorCode,
            }),
            contactName: getContactName(contacts, contactId),
            netAmount: netAmount && currencyCode ? `${amountToDisplayValue(netAmount)} ${currencyCode}` : undefined,
            recurringPeriod: getRecurringPeriod(recurringInterval, t),
            recurringDate: nextSpawnTimestamp,
            recurringSpawnMode: getRecurringSpawnMode(recurringSpawnMode, t),
            id,
          }
        },
      ),
    [contacts, recurringInvoices, t],
  )
}
