interface LimitValueProps {
  value: number | null
  max?: number
  min?: number
}

interface LimitValueResult {
  valueLimited: number | null
  hasValueBeenLimited: boolean
}

export const limitValue = ({ value, max, min }: LimitValueProps): LimitValueResult => {
  let valueLimited = value
  let hasValueBeenLimited = false

  if (typeof valueLimited === 'number') {
    if (typeof min === 'number' && valueLimited < min) {
      valueLimited = min
      hasValueBeenLimited = true
    }

    if (typeof max === 'number' && valueLimited > max) {
      valueLimited = max
      hasValueBeenLimited = true
    }
  }

  return { valueLimited, hasValueBeenLimited }
}
