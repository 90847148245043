import { amountToDisplayValue } from './amountToDisplayValue'
import { amountToNumber } from './amountToNumber'

export const MATH_OPERATORS = /([\+\-\*\/\(\)])/g // eslint-disable-line no-useless-escape
export const NON_MATH_CHARACTERS = /([^\d\.\,\+\-\*\/\(\)]+)/g // eslint-disable-line no-useless-escape

const BRACKETS = ['(', ')']
const OPERATORS = ['+', '-', '*', '/']
const ALLOWED_MATH_CHARACTERS = [...BRACKETS, ...OPERATORS]

/*
  Strips right hand operators from expression, so:
    ['1', '+', '2', '/', '+']
  becomes:
    ['1', '+', '2']
  so user is able to leave last operator in the input.
*/
const clearRightHandExpressions = (expressions: string[]): string[] => {
  const reversed = expressions.reverse()
  const indexOfNonOperator = reversed.findIndex((part) => !OPERATORS.includes(part))

  return reversed.slice(indexOfNonOperator).reverse()
}

export const isExpression = (amount: string) => {
  const regex = new RegExp(MATH_OPERATORS)
  return regex.test(amount)
}

export const getEvaluatedExpression = (amount: string): number => {
  try {
    /*
      Wraps special characters with # for easier splitting.
      Runs filter to discard empty values.
    */
    const expressionParts = amount
      .replace(MATH_OPERATORS, '#$1#')
      .split('#')
      .filter((part) => part.trim().length > 0)

    // Removes right-hand operators
    const clearedExpressions = clearRightHandExpressions(expressionParts)

    const sanitizedParts = clearedExpressions.map((part) => {
      if (ALLOWED_MATH_CHARACTERS.includes(part)) {
        return part
      }

      return amountToNumber(amountToDisplayValue(part), true)
    })

    const sanitizedExpression = sanitizedParts.join('')

    const evaluatedResult = eval(sanitizedExpression) // eslint-disable-line no-eval

    if (!isFinite(evaluatedResult)) {
      return 0
    }

    return Math.round(evaluatedResult * 100) / 100 // rounding float to 2 decimal places
  } catch (error) {
    return 0
  }
}

// If user tries to input an expression while no calculations are used, then return 0
export const getUnevaluatedExpression = (amount: string): number => {
  return isExpression(amount) ? 0 : Number(amount)
}
