import { Logo } from '@components'
import { DisableMobileZoom } from '@components-deprecated'

import React, { ReactElement, ReactNode, useEffect, useMemo, useRef } from 'react'

import { useLocaleCode } from '../../../../hooks/useLocaleCode'
import { getImageClassName } from '../../../../utils/getClassName'
import { getImgPath } from '../../../../utils/getImgPath'
import { LanguageSwitcher } from '../LanguageSwitcher'
import { TrustpilotRating } from '../TrustpilotRating'
import * as Styled from './styles'

interface GuideLayoutProps {
  children: ReactNode
  autoFocus?: boolean
}

export const GuideLayout = ({ autoFocus, children }: GuideLayoutProps): ReactElement => {
  const locale = useLocaleCode()
  const layoutRef = useRef<HTMLDivElement>(null)
  const backgroundSrc = useMemo(() => getImgPath(`/signup-guide/ageras-guide-layout-bg-${locale}.svg`), [locale])

  useEffect(() => {
    if (!autoFocus) {
      return
    }

    const firstInput = layoutRef.current?.querySelector('input:not(:disabled)') as HTMLInputElement | undefined
    firstInput && firstInput.focus()
  }, [autoFocus])

  return (
    <Styled.GuideLayoutWrapper ref={layoutRef}>
      <DisableMobileZoom />

      <Styled.LayoutContent>
        {/* Left side */}
        <Styled.LeftSideWrapper>
          <Styled.LeftSideContentWrapper>
            <Styled.LeftSizeHeader>
              <Logo />
              <LanguageSwitcher />
            </Styled.LeftSizeHeader>
            <Styled.ContentWrapper>{children}</Styled.ContentWrapper>
          </Styled.LeftSideContentWrapper>
        </Styled.LeftSideWrapper>

        {/* Right side */}
        <Styled.RightSideWrapper className={getImageClassName()} imageSrc={backgroundSrc}>
          <Styled.RightSideFooter>
            <TrustpilotRating />
          </Styled.RightSideFooter>
        </Styled.RightSideWrapper>
      </Styled.LayoutContent>
    </Styled.GuideLayoutWrapper>
  )
}
