import { Color, DefaultValue, getShadow } from '@design-system'

import styled from '@emotion/styled'

export const BillAttachmentPreviewWrapper = styled.div`
  height: 100%;
  border-radius: ${DefaultValue.BorderRadius};
  background-color: ${Color.White};
  overflow: hidden;
  ${getShadow('raisedLight')};
`
