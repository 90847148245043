import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'
import { Icon } from '../../../Icon'
import { alignmentToJustify } from '../../enums/alignmentToJustify'
import { TableAlignmentProps } from '../../types/alignment'
import { TableSortableProps } from '../../types/sortable'
import { getDynamicSizeColumnStyles } from '../../utils/getDynamicSizeColumnStyles'
import { getFixedSizeColumnStyles } from '../../utils/getFixedSizeColumnStyles'

interface TableHeaderItemWrapperProps extends TableAlignmentProps, Partial<TableSortableProps> {
  fixedSize?: boolean
  sorted?: boolean
}

export const TableHeaderItemWrapper = styled.div<TableHeaderItemWrapperProps>`
  display: flex;
  margin-right: ${Spacing.XL};
  align-items: center;
  justify-content: ${({ alignment }) => alignmentToJustify[alignment]};
  ${({ fixedSize }) => (fixedSize ? getFixedSizeColumnStyles() : getDynamicSizeColumnStyles())};

  &:last-of-type {
    margin-right: 0;
  }
`

export const SortIcon = styled(Icon)`
  margin-left: ${Spacing.XXS};
  flex-shrink: 0;
`
