import { Space } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { SettingsModuleLayout } from '../../elements/SettingsModuleLayout'
import { SettingsRoute } from '../../enums/settingsRoute'
import { AdditionalFields } from './elements/AdditionalFields'
import { VatAccounts } from './elements/VatAccounts'

export const VatReturnSettings = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <SettingsModuleLayout
      title={t('settings.organization.tax_return.title')}
      withBackButton
      backButtonTo={SettingsRoute.OrganizationAccounting}
    >
      <VatAccounts />
      <Space size="xl" />
      <AdditionalFields />
    </SettingsModuleLayout>
  )
}
