import { Button, ButtonsGroup, Input, Modal, ModalProps, Space, Text, useModal } from '@design-system'

import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useRejectReceiptForm } from '../../hooks/useRejectReceiptForm'

interface ReceiptRejectionModalProps extends ModalProps {
  attachmentId?: string
  onSuccess?: () => void
}

export const ReceiptRejectionModal = ({ attachmentId, onSuccess, ...modalProps }: ReceiptRejectionModalProps) => {
  const { t } = useTranslation()
  const { close } = useModal(modalProps.id)

  const {
    Form,
    FormItem,
    submitForm,
    isProcessing: isRejecting,
    isOrganizationLoading,
  } = useRejectReceiptForm({
    attachmentId,
    onSuccess: () => {
      close()
      onSuccess?.()
    },
  })

  const handleCancelButtonClick = useCallback(() => {
    close()
  }, [close])

  const handleRejectButtonClick = useCallback(() => {
    submitForm()
  }, [])

  return (
    <Modal size="s" closable {...modalProps}>
      <Modal.Header title={t('receipts.modal.reject_e_invoice.title')} />
      <Modal.Body>
        <Form>
          <Trans i18nKey="receipts.modal.reject_e_invoice.message">
            <Text colorVariant="secondary">
              Please be aware that if you reject this E-invoice, it will be removed from your files and (the sender)
              will be notified that it is rejected.
            </Text>
            <Space />
            <Text colorVariant="secondary">It is required to inform the reason of the rejection.</Text>
          </Trans>
          <Space />
          <FormItem
            name="reason"
            render={({ field }) => (
              <Input {...field} placeholder={t('receipts.modal.reject_e_invoice.reason_placeholder')} />
            )}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={handleCancelButtonClick}>
            {t('cancel')}
          </Button>
          <Button
            onClick={handleRejectButtonClick}
            disabled={isRejecting || isOrganizationLoading}
            loading={isRejecting}
            danger
          >
            {t('receipts.modal.reject_e_invoice.button')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
