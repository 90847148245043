import React from 'react'
import { Box } from 'rebass'

import { IconProps } from '../Icon'

export const IconHiper = ({ width = '780px', height = '500px', ...rest }: IconProps) => (
  <Box as="i" width={width} height={height} {...rest}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 780 500"
      enableBackground="new 0 0 780 500"
      xmlSpace="preserve"
    >
      <polygon
        fill="#F37421"
        points="99,138.9p 99,313.9 138.7,313.9 138.7,240.7 203.9,240.7 203.9,313.9 243.4,313.9 243.4,138.9 203.9,138.9 203.9,206.2 138.7,206.2 138.7,138.9 99,138.9"
      />
      <path
        fill="#F37421"
        d="M585.2,263c0.5-2.9,1.3-8.8,1.3-15.6c0-31.4-15.6-63.4-56.6-63.4c-44.1,0-64.1,35.6-64.1,67.8c0,39.7,24.7,64.7,67.8,64.7c17.1,0,33-2.6,46-7.8l-5.2-26.7c-10.6,3.4-21.6,5.2-35.1,5.2c-18.4,0-34.5-7.8-35.8-24.1H585.2L585.2,263z M503.1,236c1-10.6,7.8-25.7,24.4-25.7c18.2,0,22.3,16.1,22.3,25.7H503.1L503.1,236z"
      />
      <path
        fill="#F37421"
        d="M599.1,313.9h39.5v-64.4c0-3.1,0.3-6.2,0.8-8.8c2.6-12.2,12.5-20,27-20c4.4,0,7.8,0.5,10.6,1v-37.1c-2.9-0.5-4.7-0.5-8.3-0.5c-12.2,0-27.8,7.8-34.3,26.2h-1l-1.3-23.4h-34c0.5,10.9,1,23.1,1,41.8V313.9L599.1,313.9z"
      />
      <path
        fill="#FFE700"
        d="M283.5,131.9c11.2,0,20.3,9.1,20.3,20.3c0,11.2-9.1,20.3-20.3,20.3c-11.2,0-20.3-9.1-20.3-20.3C263.2,141,272.3,131.9,283.5,131.9L283.5,131.9z"
      />
      <path
        fill="#F37421"
        d="M366.9,284.3h19.8c19.9,0,28.9-12.7,28.9-26c0-13.3-1-43.3-25.5-43.3c-28.2,0-23.7,37.3-23.5,56.8C366.5,276,366.8,280.2,366.9,284.3L366.9,284.3z M263.2,186.8h40.6v71.6c0,13.3,7.4,26,23.7,26c0.1-32.2,0-65.4-1.1-97.6h33.9 c0.7,6.2,1.4,12.4,2,18.6c16-32,66.5-25,83.5,2.1c17.5,27.9,23.5,107.9-59.1,107.9h-19.2c0.2,16.2,0.2,32.5,0.2,48.8h-40.6 c0-15.6,0.1-32,0.2-48.8c-43.9-0.2-64.1-28-64.1-57V186.8L263.2,186.8z"
      />
    </svg>
  </Box>
)
