import { Badge, IconButton, Space, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface NotesHeaderProps {
  count: number
  onCloseClick?: () => void
}

export const NotesHeader = ({ count, onCloseClick }: NotesHeaderProps): ReactElement => {
  const { t } = useTranslation()

  const handleCloseButtonClick = useCallback(() => {
    onCloseClick?.()
  }, [onCloseClick])

  return (
    <Styled.NotesHeader>
      <Text variant="h3">{t('note_panel.title')}</Text>
      <Space horizontal size="xs" />
      {count > 0 && (
        <Styled.BadgeWrapper>
          <Badge>{count}</Badge>
        </Styled.BadgeWrapper>
      )}
      <Styled.ClosePanelIconWrapper onClick={handleCloseButtonClick}>
        <IconButton icon="xSign" />
      </Styled.ClosePanelIconWrapper>
    </Styled.NotesHeader>
  )
}
