import { UploadFilesErrorMessage } from '@components'
import { Dropzone, FilesPreview, getRejectedFilesList, notify, SkeletonBox, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ACCEPTED_PICTURE_FILE_FORMATS } from '../../../../../../../../../../../../constants/acceptedPictureFileFormats'
import { ModalId } from '../../../../../../../../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../../../../../../../../enums/notificationKeys'
import { formatMegabytesToBytes } from '../../../../../../../../../../../../utils/formatMegabytesToBytes'
import { getImageClassName } from '../../../../../../../../../../../../utils/getClassName'
import { useOrganizationImage } from '../../../../../../../../../OrganizationSettings/hooks/useOrganizationImage'
import { useUploadOrganizationLogo } from '../../../../../../../../../OrganizationSettings/hooks/useUploadOrganizationLogo'

export const OrganizationLogo = (): ReactElement => {
  const { t } = useTranslation()
  const { organization, isLoading: isLoadingOrganization } = useUserOrganization()
  const { upload, isLoading: isUploadingOrganizationLogo } = useUploadOrganizationLogo()
  const { open: openDeleteOrganizationLogoModal } = useModal(ModalId.DeleteOrganizationLogoModal)
  const { url: logoUrl, isLoading: isLoadingLogo } = useOrganizationImage(organization?.logoFileId || '')

  const handleDropAccepted = useCallback(
    ([file]: File[]) => {
      upload(file)
    },
    [upload],
  )

  const handleDropRejected = useCallback(
    (filesRejected: FileRejection[]) => {
      const uploadFilesErrors = getRejectedFilesList(filesRejected)
      notify({
        id: NotificationKeys.OrganizationLogoUploadRejection,
        message: UploadFilesErrorMessage({ uploadFilesErrors }),
        title: t('file.rejected.count', { count: filesRejected.length }),
        variant: 'warning',
      })
    },
    [t],
  )

  const handleEditButtonClick = useCallback(
    (file: File) => {
      upload(file)
    },
    [upload],
  )

  const handleDeleteButtonClick = useCallback(() => {
    openDeleteOrganizationLogoModal()
  }, [openDeleteOrganizationLogoModal])

  if (isLoadingLogo || isLoadingOrganization) {
    return <SkeletonBox fullHeight fullWidth />
  }

  if (logoUrl && organization?.logoFileId) {
    return (
      <FilesPreview
        bordered
        className={getImageClassName()}
        files={[{ src: logoUrl }]}
        fitToHeight
        fitType="contain"
        onDelete={handleDeleteButtonClick}
        onEdit={handleEditButtonClick}
        withDeleteButton
        withEditButton
      />
    )
  }

  return (
    <Dropzone
      accept={ACCEPTED_PICTURE_FILE_FORMATS}
      contentText={t('settings.organization.organization_info.logo.dropzone_placeholder')}
      isUploading={isUploadingOrganizationLogo}
      maxFiles={1}
      maxSize={formatMegabytesToBytes(5)}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
    />
  )
}
