import { Color, DefaultValue, getShadow, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { PERIOD_DROPDOWN_WIDTH } from '../../constants/periodDropdownWidth'

export const PeriodDropdownWrapper = styled.div`
  display: flex;
  width: ${PERIOD_DROPDOWN_WIDTH}px;
  border-radius: ${DefaultValue.BorderRadius};
  flex-direction: column;
  background-color: ${Color.White};
  ${getShadow('elevatedStrong')}
`

export const ScrollableWrapper = styled.div`
  padding: ${Spacing.L} ${Spacing.L} ${Spacing.S};
  overflow: auto;
`

export const FooterWrapper = styled.footer`
  padding: ${Spacing.S} ${Spacing.L} ${Spacing.L};
`
