import styled from '@emotion/styled'

import { DefaultValue } from '../../enums/defaultValue'
import { Themable } from '../../types/themable'
import { skeletonAnimation } from '../../utils/skeletonAnimation'

const DEFAULT_WIDTH = 150
const DEFAULT_HEIGHT = 50

interface SkeletonBoxProps extends Themable {
  fullWidth?: boolean
  fullHeight?: boolean
  width?: number
  height?: number
}

export const SkeletonBox = styled.div<SkeletonBoxProps>`
  ${({ width = DEFAULT_WIDTH }) => width && `width: ${width}px`};
  ${({ height = DEFAULT_HEIGHT }) => height && `height: ${height}px`};
  ${({ fullWidth }) => fullWidth && 'width: 100%'};
  ${({ fullHeight }) => fullHeight && 'height: 100%'};
  ${skeletonAnimation()};
  border-radius: ${DefaultValue.BorderRadius};
`
