import { CountrySelect, CustomDraggableItemProps, TaxRateSelect } from '@components'
import { FormItemsGroup, IconButton, Spacing, Text, Tooltip } from '@design-system'

import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../../../hooks/useForm'
import { CreateOrEditRulesetFormSchema } from '../../../../utils/formData'
import { ContactTypeSelect } from '../ContactTypeSelect'
import { CountryGroupSelect } from '../CountryGroupSelect'
import * as Styled from './styles'

export interface RuleProps extends CustomDraggableItemProps {
  disabled?: boolean
}

export const Rule = ({ onDelete, index, disabled }: RuleProps): ReactElement => {
  const { t } = useTranslation()
  const { FormItem, watch, resetField } = useFormContext<CreateOrEditRulesetFormSchema>()

  const countryGroupIdFieldName = `rules.${index}.countryGroupId` as `rules.${number}.countryGroupId`
  const countryIdFieldName = `rules.${index}.countryId` as `rules.${number}.countryId`

  const countryGroupIdValue = watch(countryGroupIdFieldName)
  const countryIdValue = watch(countryIdFieldName)

  useEffect(() => {
    if (countryGroupIdValue) {
      resetField(countryIdFieldName, { defaultValue: null })
    }
  }, [countryGroupIdValue, countryIdFieldName, index, resetField])

  useEffect(() => {
    if (countryIdValue) {
      resetField(countryGroupIdFieldName, { defaultValue: null })
    }
  }, [countryGroupIdFieldName, countryIdValue, index, resetField])

  return (
    <Styled.Rule>
      <FormItemsGroup itemsTemplate={[1, 4, 1, 9, 1]} columnSpacing={Spacing.S}>
        <Styled.TextCenteringWrapper>
          <Text variant="large">{t('settings.organization.vat_rates.modals.create_or_edit_ruleset.if')}</Text>
        </Styled.TextCenteringWrapper>
        <FormItem
          name={countryGroupIdFieldName}
          render={({ field: { value, onChange, ...props } }) => (
            <CountryGroupSelect
              {...props}
              dropdownSize="fitTrigger"
              onSelect={onChange}
              selectedId={value || undefined}
              placeholder={t('settings.organization.vat_rates.modals.create_or_edit_ruleset.any_country_group')}
              disabled={disabled}
            />
          )}
        />
        <Styled.TextCenteringWrapper>
          <Text alignment="center" variant="large">
            {t('settings.organization.vat_rates.modals.create_or_edit_ruleset.or')}
          </Text>
        </Styled.TextCenteringWrapper>
        <FormItem
          name={countryIdFieldName}
          render={({ field: { value, onChange, ...props } }) => (
            <CountrySelect
              {...props}
              dropdownSize="fitTrigger"
              onSelect={onChange}
              selectedId={value || undefined}
              placeholder={t('settings.organization.vat_rates.modals.create_or_edit_ruleset.any_country')}
              disabled={disabled}
            />
          )}
        />
        <Tooltip label={t('bill.edit.form.delete_line')} placement="top">
          <IconButton icon="trash" onClick={onDelete} disabled={disabled} />
        </Tooltip>
      </FormItemsGroup>
      <FormItemsGroup itemsTemplate={[1, 10, 4, 1]} columnSpacing={Spacing.S}>
        <Styled.TextCenteringWrapper>
          <Text variant="large">{t('settings.organization.vat_rates.modals.create_or_edit_ruleset.and')}</Text>
        </Styled.TextCenteringWrapper>
        <FormItem
          name={`rules.${index}.contactType`}
          render={({ field: { value, onChange, ...props } }) => (
            <ContactTypeSelect
              {...props}
              onSelect={(_id, value) => {
                onChange(value)
              }}
              selectedId={value || 'any'}
              disabled={disabled}
            />
          )}
        />
      </FormItemsGroup>
      <FormItemsGroup itemsTemplate={[1, 10, 4, 1]} columnSpacing={Spacing.S}>
        <Styled.TextCenteringWrapper>
          <Text variant="large">Use</Text>
        </Styled.TextCenteringWrapper>
        <FormItem
          name={`rules.${index}.taxRateId`}
          render={({ field: { value, onChange, ...props } }) => {
            return (
              <TaxRateSelect
                {...props}
                allowClear
                dropdownSize="fitTrigger"
                onSelect={onChange}
                sale
                selectedId={value || undefined}
                placeholder={t('settings.organization.vat_rates.modals.create_or_edit_ruleset.no_vat')}
                disabled={disabled}
              />
            )
          }}
        />
      </FormItemsGroup>
    </Styled.Rule>
  )
}
