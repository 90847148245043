import { Link } from '@design-system'

import { useCallback } from 'react'

import { useEmberRouter } from '../../../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../../../enums/emberRoute'
import { useInvoice } from '../../../../contexts/invoiceContext'

export const InvoiceCustomerLink = () => {
  const { navigate } = useEmberRouter()
  const { invoice } = useInvoice()

  const { customer } = invoice || {}
  const { displayName: customerName, externalContactId } = customer || {}

  const handleCustomerLinkClick = useCallback(() => {
    navigate(EmberRoute.ContactsCustomer, externalContactId)
  }, [externalContactId, navigate])

  return (
    <Link onClick={handleCustomerLinkClick} withAnimation>
      {customerName}
    </Link>
  )
}
