import { useDatepicker } from '@datepicker-react/hooks'
import { createContext, ReactElement, ReactNode, useContext } from 'react'

import { SelectedDate } from '../types/selectedDate'

type DatePickerContextType = ReturnType<typeof useDatepicker> &
  SelectedDate & {
    minBookingDate?: Date
    maxBookingDate?: Date
  }

export const datePickerContextDefaultValue: DatePickerContextType = {
  activeMonths: [],
  firstDayOfWeek: 1,
  focusedDate: null,
  goToDate: () => false,
  goToNextMonths: () => false,
  goToNextMonthsByOneMonth: () => false,
  goToNextYear: () => false,
  goToPreviousMonths: () => false,
  goToPreviousMonthsByOneMonth: () => false,
  goToPreviousYear: () => false,
  hoveredDate: null,
  isDateBlocked: () => false,
  isDateFocused: () => false,
  isDateHovered: () => false,
  isDateSelected: () => false,
  isEndDate: () => false,
  isFirstOrLastSelectedDate: () => false,
  isStartDate: () => false,
  maxBookingDate: undefined,
  minBookingDate: undefined,
  numberOfMonths: 1,
  onDateFocus: () => false,
  onDateHover: () => false,
  onDateSelect: () => false,
  onResetDates: () => false,
  selectedDate: null,
}

const DatePickerContext = createContext(datePickerContextDefaultValue)

interface DatePickerContextProps {
  children?: ReactNode
  value?: Partial<typeof datePickerContextDefaultValue> & SelectedDate
}

export const DatePickerContextProvider = ({ children, value }: DatePickerContextProps): ReactElement => (
  <DatePickerContext.Provider value={{ ...datePickerContextDefaultValue, ...value }}>
    {children}
  </DatePickerContext.Provider>
)

export const useDatePickerContext = () => useContext(DatePickerContext)
