import { RouteProtected } from '@components'
import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch } from 'react-router-dom'

import { Scope } from '../../enums/scope'
import { withRouteLinkClickedListener } from '../../hocs/withRouteLinkClickedListener'
import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { defaultQuery } from './constants/defaultQuery'
import { SettingsContextSwitcher } from './elements/SettingsContextSwitcher'
import { SettingsNavigation } from './elements/SettingsNavigation'
import { SettingsRoute } from './enums/settingsRoute'
import { OrganizationAccessTokensSettings } from './routes/OrganizationAccessTokensSettings'
import { OrganizationAccountingSettings } from './routes/OrganizationAccountingSettings'
import { ChartOfAccounts } from './routes/OrganizationChartOfAccounts'
import { OrganizationFeaturesSettings } from './routes/OrganizationFeaturesSettings'
import { InvoiceSettings } from './routes/OrganizationInvoiceSettings'
import { OrganizationSettings } from './routes/OrganizationSettings'
import { OrganizationSettingsUsers } from './routes/OrganizationSettingsUsers'
import { SubscriptionComponent } from './routes/OrganizationSubscriptionSettings'
import { UserNotificationsSettings } from './routes/UserNotificationsSettings'
import { UserPrivacySettings } from './routes/UserPrivacySettings'
import { UserProfileSettings } from './routes/UserProfileSettings'
import { VatRatesSettings } from './routes/VatRatesSettings'
import { VatReturnSettings } from './routes/VatReturnSettings'
import * as Styled from './styles'

export const Settings = withRouteLinkClickedListener((): ReactElement => {
  const { t } = useTranslation()
  const emberQuery = useGetQueryFromEmberRoute({ queryParamsValidKeys: Object.keys(defaultQuery) })

  return (
    <ModuleLayout
      headerBottomActions={<SettingsNavigation />}
      restoreScroll={false}
      resetScroll={true}
      sideActions={
        <Styled.SettingsSideActionsWrapper>
          <SettingsContextSwitcher />
        </Styled.SettingsSideActionsWrapper>
      }
      title={t('settings')}
    >
      <Switch>
        <Route exact path="/">
          {typeof emberQuery?.initialRoute === 'string' ? (
            <Redirect to={emberQuery.initialRoute} />
          ) : (
            <Redirect to={SettingsRoute.Organization} />
          )}
        </Route>
        <Route component={OrganizationSettings} exact path={SettingsRoute.Organization} />
        <Route component={OrganizationAccountingSettings} exact path={SettingsRoute.OrganizationAccounting} />
        <RouteProtected
          component={VatReturnSettings}
          scopes={Scope.VatReturnRead}
          exact
          path={SettingsRoute.OrganizationAccountingVatReturn}
        />
        <Route component={ChartOfAccounts} exact path={SettingsRoute.OrganizationAccountingChartOfAccounts} />
        <RouteProtected
          component={VatRatesSettings}
          scopes={Scope.SettingVatWrite}
          exact
          path={SettingsRoute.OrganizationVatRates}
        />
        <RouteProtected
          component={InvoiceSettings}
          scopes={Scope.InvoiceRead}
          exact
          path={SettingsRoute.OrganizationInvoicing}
        />
        <RouteProtected
          component={OrganizationSettingsUsers}
          scopes={Scope.UserRead}
          exact
          path={SettingsRoute.OrganizationUsers}
        />
        <RouteProtected
          component={SubscriptionComponent}
          scopes={Scope.OrganizationSubscriptionWrite}
          exact
          path={[SettingsRoute.OrganizationSubscription, SettingsRoute.OrganizationSubscriptionPlansSelection]}
        />
        <RouteProtected
          component={OrganizationAccessTokensSettings}
          scopes={Scope.TokensRead}
          exact
          path={SettingsRoute.OrganizationAccessTokens}
        />
        <Route component={OrganizationFeaturesSettings} exact path={SettingsRoute.OrganizationBetas} />
        <Route component={UserProfileSettings} exact path={SettingsRoute.Profile} />
        <Route component={UserNotificationsSettings} exact path={SettingsRoute.ProfileNotifications} />
        <Route component={UserPrivacySettings} exact path={SettingsRoute.ProfilePrivacy} />
      </Switch>
    </ModuleLayout>
  )
})
