import { Flex, pxToNumber, SkeletonBox, Spacing, Text } from '@design-system'

import { ReactElement } from 'react'

import { BankConnectionOld, BankConnection as BankConnectionType } from '@modules-deprecated/bankReconciliation/types'
import { formatIban } from '@views/bankIntegration/utils/formatIban'

import { isOldBankConnection } from '../../../../utils/isOldBankConnection'
import * as Styled from './styles'

interface BankConnectionProps {
  connectedBank?: BankConnectionOld | BankConnectionType
  isLoading: boolean
  logoSize?: number
}

const getBankLogo = (connectedBank?: BankConnectionType) => {
  const bankPresentation = connectedBank?.bank?.presentation
  const rawPresentation = connectedBank?.bank?.raw?.presentation
  const largeLogos = rawPresentation?.logos?.large

  return bankPresentation || largeLogos?.svg || largeLogos?.png
}

export const BankConnection = ({ connectedBank, isLoading, logoSize = 48 }: BankConnectionProps): ReactElement => {
  const containerHeight = logoSize + 2 * pxToNumber(Spacing.L)
  if (isLoading) {
    return <SkeletonBox height={containerHeight} fullWidth />
  }

  const isOldConnection = isOldBankConnection(connectedBank)

  const bankAccountName = isOldConnection ? connectedBank.accountName : connectedBank?.name
  const bankName = isOldConnection ? connectedBank.bankName : connectedBank?.bank?.name
  const bankAccountTitle = isOldConnection
    ? connectedBank.accountName
    : `${bankAccountName} ${formatIban(connectedBank?.iban)} ${connectedBank?.currency}`

  const bankLogoUrl = isOldConnection ? null : getBankLogo(connectedBank)

  return (
    <Styled.BankConnectionWrapper>
      <Flex flexDirection="row" gap={Spacing.L}>
        {bankLogoUrl && (
          <Styled.BankLogo src={bankLogoUrl} width={logoSize} height={logoSize} alt={bankName}></Styled.BankLogo>
        )}
        <Flex flexDirection="column">
          <Text variant="normal" weight="medium">
            {bankName}
          </Text>
          <Text>{bankAccountTitle}</Text>
        </Flex>
      </Flex>
    </Styled.BankConnectionWrapper>
  )
}
