import colors from './colors'

export default {
  card: {
    position: 'relative',
    backgroundColor: colors.white,
    border: `1px solid ${colors.gray2}`,
    borderBottomWidth: '2px',
    borderRadius: '2px',
    margin: '20px',
    header: {
      padding: '20px',
      borderBottom: `1px solid ${colors.gray2}`,
    },
    body: {
      padding: '20px',
    },
  },
  paymentCard: {
    backgroundColor: colors.white,
    borderRadius: '2px',
    border: `1px solid ${colors.gray2}`,
    boxShadow: '0 2px 4px 0 rgba(30, 57, 101, 0.03)',
    padding: '20px',
    width: '240px',
    height: '150px',
  },
  subscriptionCard: {
    position: 'relative',
    backgroundColor: colors.white,
    border: `1px solid ${colors.gray2}`,
    borderBottomWidth: '2px',
    borderRadius: '2px',
    margin: '20px 0',
    body: {
      padding: '20px',
    },
  },
}
