import React, { ReactElement, useCallback } from 'react'

import { Icon } from '../../../Icon'
import { StepDirection } from '../../types/stepDirection'
import * as Styled from './styles'

interface StepNavigationProps {
  onChange: (direction: StepDirection) => void
}

export const StepNavigation = ({ onChange }: StepNavigationProps): ReactElement => {
  const handleIconUpClick = useCallback(() => {
    onChange('up')
  }, [onChange])

  const handleIconDownClick = useCallback(() => {
    onChange('down')
  }, [onChange])

  return (
    <Styled.StepNavigationWrapper>
      <Styled.IconWrapper onClick={handleIconUpClick}>
        <Icon icon="chevronUp" />
      </Styled.IconWrapper>
      <Styled.IconWrapper onClick={handleIconDownClick}>
        <Icon icon="chevronDown" />
      </Styled.IconWrapper>
    </Styled.StepNavigationWrapper>
  )
}
