import { useMemo } from 'react'

import { QueryKeys } from '../../../enums/queryKeys'
import { useQueriesTyped } from '../../../hooks/useQueriesTyped'
import { BankLinesQueryParams, fetchBankLines } from '../query-api'
import { BankLine } from '../types'

export const useMultipleBankLines = (queryParams: BankLinesQueryParams[], isEnabled = true) => {
  const queries = useQueriesTyped(
    queryParams.map((queryParam) => {
      return {
        queryKey: [QueryKeys.BankLines, queryParam.accountId],
        queryFn: () => fetchBankLines(queryParam),
        enabled: isEnabled && !!queryParam?.accountId,
        refetchOnMount: false,
      }
    }),
  )

  const isLoading = useMemo(
    () => queries.some((query) => query.isLoading || (isEnabled && query.isIdle)),
    [isEnabled, queries],
  )
  const isError = useMemo(() => queries.some((query) => query.isError), [queries])
  const errors = useMemo(() => queries.map((query) => query.error), [queries])

  const bankLines = useMemo(
    () =>
      !isLoading
        ? queries.reduce((result, query) => {
            const data = query?.data

            if (data?.bankLines.length) {
              result.push(...data.bankLines)
            }

            return result
          }, [] as BankLine[])
        : [],
    [isLoading, queries],
  )

  return {
    bankLines,
    isLoading,
    isError,
    errors,
  }
}
