import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const NotificationsTable = styled.div`
  width: 100%;
  border-top: 1px solid ${Color.Gray40};
`

export const MuteRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${Color.Gray40};
  padding: ${Spacing.L} ${Spacing.XXS};
`
