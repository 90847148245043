export enum InvoiceDocumentType {
  CreditNote = 'creditnote',
  DeliveryNote = 'deliverynote',
  DeliveryNoteDraft = 'deliverynoteDraft',
  Estimate = 'estimate',
  Invoice = 'invoice',
  InvoiceDraft = 'invoiceDraft',
  Receipt = 'receipt',
  ReceiptDraft = 'receiptDraft',
}
