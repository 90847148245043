import { SkeletonBox, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SkeletonMenuItem = styled(SkeletonBox)`
  width: ${({ width = 160 }) => `${width}px`};
  max-width: 100%;
  height: ${Spacing.XL};
  margin: ${Spacing.M} 0 ${Spacing.XL};
`
