import { Badge, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { CellProps } from 'react-table'

import { ChartOfAccountsAccount } from '../../types'

type AccountNameCellProps = CellProps<ChartOfAccountsAccount>

export const AccountNameCell = ({ data, row }: AccountNameCellProps): ReactElement => {
  const { t } = useTranslation()
  const { description, isArchived, isPaymentEnabled, name, systemRole } = data[row.id]

  return (
    <>
      <Text colorVariant={isArchived ? 'secondary' : 'primary'}>
        {`${name} `} {isPaymentEnabled && <Badge variant="primary">{t('coa.payment_enabled')}</Badge>}{' '}
        {!!systemRole && <Badge variant="neutral">{t('coa.system_account')}</Badge>}{' '}
        {isArchived && <Badge variant="warning">{t('coa.account_archived')}</Badge>}
      </Text>
      <Text variant="small" colorVariant="secondary">
        {description}
      </Text>
    </>
  )
}
