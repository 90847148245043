import { Quote } from '../../../types/quote'
import { QuotesPagination } from '../types/quotesPagination'
import { useFetchQuotes } from './useFetchQuotes'
import { useQuotesListFilters } from './useQuotesListFilters'

interface UseQuotesResponse {
  quotes: Quote[]
  isLoading: boolean
  pagination?: QuotesPagination
}

export const useQuotes = (): UseQuotesResponse => {
  const [{ page, pageSize, period, searchQuery, sortDirection, sortProperty, state }] = useQuotesListFilters()
  const { data, isLoading, pagination } = useFetchQuotes({
    page,
    pageSize,
    period,
    searchQuery,
    sortDirection,
    sortProperty,
    state,
  })

  return {
    quotes: data,
    isLoading,
    pagination,
  }
}
