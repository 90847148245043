import React, { Children, cloneElement, isValidElement, ReactElement, ReactNode, useCallback } from 'react'

import { TimelineStatusItem, TimelineStatusItemProps } from '../TimelineStatusItem'
import * as Styled from './styles'

export interface TimelineStatusItemsListProps {
  children: ReactNode
  isCollapsed?: boolean
  isLoading?: boolean
}

export const TimelineStatusItemsList = ({
  children,
  isCollapsed = false,
  isLoading = false,
}: TimelineStatusItemsListProps): ReactElement => {
  const shouldRenderItem = useCallback(
    (index: number) => {
      if (isCollapsed && index > 0) {
        return false
      }

      return true
    },
    [isCollapsed],
  )

  return (
    <Styled.TimelineStatusItemsListWrapper>
      {isLoading ? (
        <Styled.TimelineStatusItemsListItem>
          <TimelineStatusItem isLoading />
        </Styled.TimelineStatusItemsListItem>
      ) : (
        Children.map(
          children,
          (child, index) =>
            isValidElement<TimelineStatusItemProps>(child) &&
            shouldRenderItem(index) && (
              <Styled.TimelineStatusItemsListItem>
                {cloneElement<TimelineStatusItemProps>(child, {
                  isCollapsed,
                  isCurrent: index === 0,
                  isLoading,
                  withSeparator: index < Children.count(children) - 1,
                })}
              </Styled.TimelineStatusItemsListItem>
            ),
        )
      )}
    </Styled.TimelineStatusItemsListWrapper>
  )
}
