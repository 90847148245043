import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconPagesAlt = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-pages-alt')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={coloring(color)}
        d="M28,1H16c-1.657,0-3,1.343-3,3v18c0,1.657,1.343,3,3,3h12c1.657,0,3-1.343,3-3V4C31,2.343,29.657,1,28,1z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M28,26H16c-2.206,0-4-1.794-4-4V4c0-2.206,1.794-4,4-4h12c2.206,0,4,1.794,4,4v18C32,24.206,30.206,26,28,26 z M16,2c-1.103,0-2,0.897-2,2v18c0,1.103,0.897,2,2,2h12c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H16z"
      ></path>
      <path
        fill={coloring(color2 || color)}
        d="M16,7H4c-1.657,0-3,1.343-3,3v18c0,1.657,1.343,3,3,3h12c1.657,0,3-1.343,3-3V10C19,8.343,17.657,7,16,7z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M16,32H4c-2.206,0-4-1.794-4-4V10c0-2.206,1.794-4,4-4h12c2.206,0,4,1.794,4,4v18C20,30.206,18.206,32,16,32 z M4,8c-1.103,0-2,0.897-2,2v18c0,1.103,0.897,2,2,2h12c1.103,0,2-0.897,2-2V10c0-1.103-0.897-2-2-2H4z"
      ></path>
    </svg>
  </Box>
)
