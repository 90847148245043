import noop from 'lodash/noop'
import React, { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { EmberEventFn } from '../../../../../types/emberEventFn'

interface ContextState {
  showCreateRateModal: (props: { appliesToSales?: boolean; appliesToPurchases?: boolean }) => void
  showEditRateModal: (rateId: string) => void
  showCreateRuleSetModal: () => void
  showEditRuleSetModal: (ruleId: string) => void
}

const defaultValue: ContextState = {
  showCreateRateModal: noop,
  showEditRateModal: noop,
  showCreateRuleSetModal: noop,
  showEditRuleSetModal: noop,
}

const VatRatesEmberConnectionContext = createContext(defaultValue)

interface VatRatesEmberConnectionContextProps {
  children?: ReactNode
  onShowCreateRateModal: EmberEventFn
  onShowEditRateModal: EmberEventFn
  onShowCreateRuleSetModal: EmberEventFn
  onShowEditRuleSetModal: EmberEventFn
}

export const VatRatesEmberConnectionContextProvider = ({
  onShowCreateRateModal,
  onShowEditRateModal,
  onShowCreateRuleSetModal,
  onShowEditRuleSetModal,
  children,
}: VatRatesEmberConnectionContextProps): ReactElement => {
  const showCreateRateModal = useCallback(
    (props: { appliesToSales?: boolean; appliesToPurchases?: boolean }) => {
      onShowCreateRateModal({ detail: { props } })
    },
    [onShowCreateRateModal],
  )

  const showEditRateModal = useCallback(
    (rateId: string) => {
      onShowEditRateModal({ detail: { id: rateId } })
    },
    [onShowEditRateModal],
  )

  const showCreateRuleSetModal = useCallback(() => {
    onShowCreateRuleSetModal({ detail: null })
  }, [onShowCreateRuleSetModal])

  const showEditRuleSetModal = useCallback(
    (ruleId: string) => {
      onShowEditRuleSetModal({ detail: { id: ruleId } })
    },
    [onShowEditRuleSetModal],
  )

  return (
    <VatRatesEmberConnectionContext.Provider
      value={{ showCreateRateModal, showCreateRuleSetModal, showEditRateModal, showEditRuleSetModal }}
    >
      {children}
    </VatRatesEmberConnectionContext.Provider>
  )
}

export const useVatRatesEmberConnection = () => useContext(VatRatesEmberConnectionContext)
