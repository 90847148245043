import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const PasswordChecksItem = styled.li`
  display: flex;
  align-items: center;
`

export const IconWrapper = styled.figure`
  display: flex;
  margin-right: ${Spacing.S};
  align-items: center;
`
