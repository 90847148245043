import { ElementType } from 'react'

import { inlineElements } from '../constants/inlineElements'

export const isInlineElement = (as?: ElementType<any>): boolean => {
  if (!as || typeof as !== 'string') {
    return false
  }

  return inlineElements.includes(as)
}
