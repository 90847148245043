import { TableData } from '@design-system'

import { TFunction } from 'i18next'
import { useMemo } from 'react'

import { Country } from '@modules-deprecated/app/countries/types'

import { Contact } from '../../../../../types/contact'
import { ContactsTableAccessor } from '../../../enums/ContactsTableAccessor'

export interface ContactsTableRow extends TableData {
  [ContactsTableAccessor.ContactNo]?: string
  [ContactsTableAccessor.ContactPersonEmail]?: string
  [ContactsTableAccessor.Country]?: string
  [ContactsTableAccessor.CreatedTime]?: Date
  [ContactsTableAccessor.Name]?: string
  [ContactsTableAccessor.Phone]?: string
  [ContactsTableAccessor.Type]?: string
}

export const useContactsTableData = (contacts: Contact[], t: TFunction, countries?: Country[]): ContactsTableRow[] => {
  return useMemo(
    () =>
      contacts.map(({ contactNo, contactPersons, countryId, createdTime, id, name, phone, type }) => {
        const country = countries?.find((country) => country.id === countryId)
        return {
          id,
          [ContactsTableAccessor.ContactNo]: contactNo,
          [ContactsTableAccessor.ContactPersonEmail]: contactPersons.length ? contactPersons[0].email : '',
          [ContactsTableAccessor.Country]: country?.name,
          [ContactsTableAccessor.CreatedTime]: createdTime,
          [ContactsTableAccessor.Name]: name,
          [ContactsTableAccessor.Phone]: phone,
          [ContactsTableAccessor.Type]:
            type === 'person' ? t('contacts.table.data.individual') : t('contacts.table.data.company'),
        }
      }),
    [contacts, countries, t],
  )
}
