import { TabButtonsGroup } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../hooks'
import { contactTypeToTKey } from '../../constants/contactTypeToTKey'
import { headerNavigationTypes } from '../../constants/headerNavigationTypes'
import { ContactType } from '../../enums/contactType'
import { ContactFormSchema } from '../../utils/formData'
import * as Styled from './styles'

export const ContactTypeSwitcher = (): ReactElement => {
  const { t } = useTranslation()
  const { setValue, control } = useFormContext<ContactFormSchema>()
  const contactType = useWatch({ control, name: 'contactType' })

  const handleContactTypeButtonClick = useCallback(
    (type: ContactType) => () => {
      setValue('contactType', type)
    },
    [setValue],
  )

  return (
    <Styled.ContactTypeSwitcherWrapper>
      <TabButtonsGroup>
        {headerNavigationTypes.map((type) => (
          <TabButtonsGroup.Button
            key={type}
            selected={type === contactType}
            onClick={handleContactTypeButtonClick(type)}
          >
            {t(contactTypeToTKey[type])}
          </TabButtonsGroup.Button>
        ))}
      </TabButtonsGroup>
    </Styled.ContactTypeSwitcherWrapper>
  )
}
