import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const TimelineStatusWrapper = styled.div`
  width: 100%;
  border-radius: ${DefaultValue.BorderRadius};
  padding: ${Spacing.L};
  background-color: ${Color.White};
`

export const Header = styled.header`
  display: flex;
  margin-bottom: ${Spacing.L};
  align-items: center;
`

export const ContentWrapper = styled.div`
  & + ${() => ListWrapper} {
    margin-top: ${Spacing.L};
  }
`

export const ListWrapper = styled.div``

export const ExpansionTriggerWrapper = styled.span`
  user-select: none;
  cursor: pointer;
`

export const ExpansionTriggerIconWrapper = styled.figure``
