import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { RecurringInvoicesDisabledSpawnSectionMessage } from '../../elements/RecurringInvoicesEnablingSectionMessage'
import { RecurringInvoicesRoute } from '../../enums/recurringInvoicesRoute'
import { useFetchIsSpawnEnabled } from '../../hooks/useFetchIsSpawnEnabled'
import { useIsRecurringInvoicesListEmpty } from '../../hooks/useIsRecurringInvoicesListEmpty'
import { RecurringInvoicesSideActions } from './elements/RecurringInvoicesSideActions'
import { RecurringInvoicesTable } from './elements/RecurringInvoicesTable'
import { RecurringInvoicesTableActions } from './elements/RecurringInvoicesTableActions'

export const RecurringInvoicesList = (): ReactElement => {
  const { t } = useTranslation()
  const isRecurringInvoicesListEmpty = useIsRecurringInvoicesListEmpty()
  const { isEnabled: isSpawnEnabled, isFetched: isSpawnFetched } = useFetchIsSpawnEnabled()

  if (isRecurringInvoicesListEmpty) {
    return <Redirect to={RecurringInvoicesRoute.ListEmpty} />
  }

  return (
    <ModuleLayout sideActions={<RecurringInvoicesSideActions />} title={t('recurring_invoices')}>
      {!isSpawnEnabled && isSpawnFetched && <RecurringInvoicesDisabledSpawnSectionMessage />}
      <RecurringInvoicesTableActions />
      <RecurringInvoicesTable />
    </ModuleLayout>
  )
}
