import { useCallback } from 'react'

import { useMatch } from '../../../../contexts/matchContext'
import { useBillReconciliationABTest } from '../../../../hooks/useBillReconciliationABTest'
import { ReconciliationSuggestionVariantA } from '../ReconciliationSuggestionVariantA'
import { ReconciliationSuggestionVariantB } from '../ReconciliationSuggestionVariantB'

// when enabling Bank Lines, onPayManually prop will not be needed any more
interface ReconciliationSuggestionABTestProps {
  onPayManually: (isWrongMatch?: boolean) => void
}

export const ReconciliationSuggestionABTest = ({ onPayManually }: ReconciliationSuggestionABTestProps) => {
  const { isInVariantA } = useBillReconciliationABTest()
  const { matchSuggestion } = useMatch()

  const handleWrongMatchButtonClick = useCallback(() => {
    onPayManually(true)
  }, [onPayManually])

  return (
    <>
      {isInVariantA ? (
        <ReconciliationSuggestionVariantA
          matchSuggestion={matchSuggestion}
          onButtonClick={handleWrongMatchButtonClick}
        />
      ) : (
        <ReconciliationSuggestionVariantB matchSuggestion={matchSuggestion} />
      )}
    </>
  )
}
