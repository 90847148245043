import { TFunction } from 'i18next'
import { boolean, object, SchemaOf, string } from 'yup'

export const getSignupFormDefaultValues = (email: string) => {
  return {
    email,
    password: '',
    name: '',
    acceptedTerms: false,
    acceptedMarketing: false,
  }
}

export interface SignupForm {
  email: string
  password: string
  name: string
  acceptedTerms: boolean
  acceptedMarketing?: boolean
}

export type SignupFormSchema = SchemaOf<SignupForm>

export const getValidationSchema = (t: TFunction): SignupFormSchema => {
  return object({
    email: string().required().email(),
    password: string()
      .min(6, t('organization_invitation.form.errors.password_length'))
      .required(t('organization_invitation.form.errors.field_required')),
    name: string().required(t('organization_invitation.form.errors.field_required')),
    acceptedTerms: boolean().oneOf([true], t('organization_invitation.form.errors.accepted_terms')).defined(),
    acceptedMarketing: boolean().optional(),
  })
}
