import { notify, useModal } from '@design-system'

import { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../../../../../config/queryClient'
import { ModalId } from '../../../../../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../../enums/queryKeys'
import { useForm } from '../../../../../../../../../hooks'
import { useInviteUser } from '../hooks/useInviteUser'
import { getValidationSchema, InviteUserForm, inviteUserFormDefaultValues } from '../utils/formData'

interface ContextState {
  isLoading: boolean
  submitForm: () => void
}

const InviteUserModalContext = createContext<ContextState | undefined>(undefined)

interface InviteUserModalContextProps {
  children: ReactNode
}

export const InviteUserModalContextProvider = ({ children }: InviteUserModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { close } = useModal(ModalId.OrganizationSettingsInviteUsersModal)
  const { inviteUser, isLoading } = useInviteUser({
    onError: () => {
      notify({ id: NotificationKeys.CreateContact, message: t('error_saving'), variant: 'error' })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.OrganizationUsers, { refetchInactive: true })
      notify({
        id: NotificationKeys.CreateContact,
        message: t('settings.organization.users.invite_user_modal.on_invitation_sent'),
        variant: 'success',
      })
      close()
    },
  })
  const { Form, handleSubmit } = useForm({
    defaultValues: inviteUserFormDefaultValues,
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const organizationId = organization?.id || ''

  const handleSubmitForm = useCallback(
    ({ email, userRole }: InviteUserForm) => {
      return inviteUser({ organizationId, payload: { email, userRole } })
    },
    [inviteUser, organizationId],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return (
    <InviteUserModalContext.Provider
      value={{
        submitForm,
        isLoading,
      }}
    >
      <Form>{children}</Form>
    </InviteUserModalContext.Provider>
  )
}

export const useInviteUserModal = () => {
  const context = useContext(InviteUserModalContext)

  if (!context) {
    throw new Error('InviteUserModalContextProvider is missing in the module!')
  }

  return context
}
