import { Button, ButtonsGroup, Modal, ModalProps } from '@design-system'

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrellaOrganizationChangeSubscription } from './contexts/umbrellaOrganizationChangeSubscriptionContext'
import { ConsolidatedBillingFormItem } from './elements/ConsolidatedBillingFormItem'
import { ProductPlansFormItem } from './elements/ProductPlansFormItem'

type UmbrellaOrganizationsChangeSubscriptionModalProps = ModalProps

export const UmbrellaOrganizationsChangeSubscriptionModal = (
  modalProps: UmbrellaOrganizationsChangeSubscriptionModalProps,
) => {
  const { t } = useTranslation()
  const { Form, submitForm } = useUmbrellaOrganizationChangeSubscription()

  const handleApproveButtonClick = useCallback(() => {
    submitForm()
  }, [submitForm])

  return (
    <Modal {...modalProps} closable size="s">
      <Modal.Header title={t('umbrella.organizations_table.change_subscription_modal.title')}></Modal.Header>
      <Modal.Body>
        <Form>
          <ConsolidatedBillingFormItem />
          <ProductPlansFormItem />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button onClick={handleApproveButtonClick}>
            {t('umbrella.organizations_table.change_subscription_modal.approve')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
