import uniqueId from 'lodash/uniqueId'
import React, { ChangeEvent, forwardRef, ReactElement, Ref, useCallback, useEffect, useMemo, useState } from 'react'

import { Spacing } from '../../enums/spacing'
import { ComponentsGroupProps } from '../ComponentsGroup'
import { RadioGroupProvider } from './contexts/radioGroupContext'
import * as Styled from './styles'

export interface RadioGroupProps extends Pick<ComponentsGroupProps, 'children' | 'direction' | 'spacing'> {
  name?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  value?: string
  defaultValue?: string
  disabled?: boolean
}

export const RadioGroup = forwardRef(
  (
    { children, defaultValue, disabled, name, onChange, spacing = Spacing.S, value, ...props }: RadioGroupProps,
    forwardedRef: Ref<HTMLUListElement>,
  ): ReactElement => {
    const [checkedValue, setCheckedValue] = useState<string | undefined>(defaultValue ?? value)
    const radioGroupName = useMemo(() => name || uniqueId('radiogroup-'), [name])

    const handleRadioChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setCheckedValue(event.currentTarget.value)
        onChange?.(event)
      },
      [onChange],
    )

    useEffect(() => {
      if (value !== undefined) {
        setCheckedValue(value)
      }
    }, [value])

    return (
      <RadioGroupProvider disabled={disabled} name={radioGroupName} value={checkedValue} onChange={handleRadioChange}>
        <Styled.RadioGroupWrapper {...props} ref={forwardedRef} spacing={spacing}>
          {children}
        </Styled.RadioGroupWrapper>
      </RadioGroupProvider>
    )
  },
)
