import { Modal, ModalProps, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const FrodaProcessingModal = (modalProps: ModalProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Modal closable={false} {...modalProps}>
      <Modal.Header title={t('financing.froda_lending.verification_process_modal.title')} />
      <Modal.Body>
        <Text>{t('financing.froda_lending.verification_process_modal.description')}</Text>
      </Modal.Body>
    </Modal>
  )
}
