import { Text, TextColorVariant } from '@design-system'

import { ChangeEvent, ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { OrganizationFeature, organizationFeaturesTranslations } from '../../enums/organizationFeature'
import * as Styled from './styles'

interface OrganizationFeatureItemProps {
  disabled?: boolean
  name: OrganizationFeature
  onFeatureToggle?: (name: OrganizationFeature, checked: boolean) => void
  value?: boolean
}

export const OrganizationFeatureItem = ({
  disabled,
  name,
  onFeatureToggle,
  value = false,
}: OrganizationFeatureItemProps): ReactElement => {
  const { t } = useTranslation()

  const colorVariant: TextColorVariant = useMemo(() => (disabled ? 'secondary' : 'primary'), [disabled])

  const handleFeatureChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onFeatureToggle?.(name, event.target.checked)
    },
    [name, onFeatureToggle],
  )

  return (
    <Styled.OrganizationFeatureItemWrapper>
      <Styled.FeatureHeader>
        <Text colorVariant={colorVariant} variant="h3">
          {t(organizationFeaturesTranslations[name].tTitle)}
        </Text>
        <Styled.SwitchToggle defaultChecked={value} disabled={disabled} onChange={handleFeatureChange} />
      </Styled.FeatureHeader>
      <Text colorVariant={colorVariant}>{t(organizationFeaturesTranslations[name].tDescription)}</Text>
    </Styled.OrganizationFeatureItemWrapper>
  )
}
