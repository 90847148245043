import { ReactElement, ReactNode } from 'react'

import { getClassName } from '../../../utils/getClassName'
import { Flex } from '../Flex'
import { Icon, IconName } from '../Icon'
import { IconButton } from '../IconButton'
import { Space } from '../Space'
import { Text } from '../Text'
import * as Styled from './styles'
import { Variant } from './types/variant'
import { variantStyles } from './utils/variantStyles'

export interface AlertProps {
  children: ReactNode
  closable?: boolean
  icon?: IconName
  onClose?: () => void
  showIcon?: boolean
  sideActions?: ReactNode
  variant?: Variant
}

export const Alert = ({
  children,
  closable,
  icon: customIcon,
  onClose,
  showIcon = true,
  sideActions,
  variant = 'warning',
}: AlertProps): ReactElement => {
  const icon = customIcon || variantStyles[variant].icon
  const variantTextColor = variantStyles[variant].color

  return (
    <Styled.AlertWrapper className={getClassName('dark-theme-suspend')} role="alert" variant={variant}>
      <Styled.Alert justifyContent="center">
        <Flex alignItems="center" flexGrow={1} justifyContent="center">
          {closable && <Space horizontal size="xxxl" />}
          {showIcon && (
            <Styled.IconWrapper>
              <Icon color={variantTextColor} icon={icon} />
            </Styled.IconWrapper>
          )}
          <Text color={variantTextColor}>{children}</Text>
        </Flex>
        {sideActions && <Styled.SideActions>{sideActions}</Styled.SideActions>}
        {closable && (
          <Styled.IconCloseWrapper>
            <IconButton color={variantTextColor} onClick={onClose} size="m" icon="xSign" />
          </Styled.IconCloseWrapper>
        )}
      </Styled.Alert>
    </Styled.AlertWrapper>
  )
}
