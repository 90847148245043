import { InternalAccessor, Table, TableColumn } from '@design-system'

import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { useUmbrellaRoles } from '../../hooks/useUmbrellaRoles'
import { AmountColumn } from './elements/AmountColumn'
import { RoleColumn } from './elements/RoleColumn'
import { RolesTableStaticActions } from './elements/RolesTableStaticActions/RolesTableStaticActions'
import { useTableData } from './hooks/useTableData'
import { TableRow } from './types/tableRow'

export const RolesTable = (): ReactElement => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { data: umbrellaRolesData, isLoading } = useUmbrellaRoles()

  const tableData = useTableData(umbrellaRolesData)

  const columns: TableColumn<TableRow>[] = useMemo(
    () => [
      {
        Header: t('umbrella_roles.roles_table.column.role'),
        accessor: 'roleName',
        size: 'xxxl',
        Cell: ({ data, row }) => {
          const { roleName, roleDescription, isAdmin, hasAccessToAllOrganizations } = data[row.id]
          return (
            <RoleColumn
              roleName={roleName}
              description={roleDescription}
              isAdmin={isAdmin}
              hasAccessToAllOrganizations={hasAccessToAllOrganizations}
            />
          )
        },
      },
      {
        Header: t('umbrella_roles.roles_table.column.users'),
        accessor: 'usersCount',
        size: 'xs',
        Cell: ({ data, row }) => {
          const { usersCount } = data[row.id]
          return <AmountColumn amount={usersCount} translationType="user" />
        },
      },
      {
        Header: t('umbrella_roles.roles_table.column.organizations'),
        accessor: 'organizationsCount',
        size: 'xs',
        Cell: ({ data, row }) => {
          const { organizationsCount } = data[row.id]
          return <AmountColumn amount={organizationsCount} translationType="organization" />
        },
      },
      {
        Header: '',
        accessor: InternalAccessor.StaticActions,
        Cell: ({ data, row }) => {
          const { id } = data[row.id]
          return umbrella ? <RolesTableStaticActions umbrellaId={umbrella.id} roleId={id} /> : <></>
        },
      },
    ],
    [t, umbrella],
  )

  return <Table data={tableData} columns={columns} isLoading={isLoading} />
}
