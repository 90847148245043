import { Dropzone, DropzoneProps, SkeletonBox, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../enums/modalId'
import { useComponentId } from '../../../hooks/useComponentId'
import { useIsBasicPlan } from '../../../hooks/useIsBasicPlan'
import { WithTrackingContext } from '../../../types/withTrackingContext'
import { UpgradeSubscriptionModal } from '../../Modal/upgradeSubscriptionModal'

export interface DropzonePremiumProps extends Omit<DropzoneProps, 'isBlocked'>, WithTrackingContext {
  isBlocked?: boolean
}

export const DropzonePremium = ({
  isBlocked: blocked,
  blockedText,
  trackingContext,
  onClick,
  ...rest
}: DropzonePremiumProps): ReactElement => {
  // we need unique id just in case couple of UpgradeSubscriptionModal will be rendered in the same view
  const upgradeModalId = useComponentId(ModalId.UpgradeSubscriptionModal)
  const { open: openUpgradeModal } = useModal(upgradeModalId)
  const { t } = useTranslation()

  const { isBasicPlan, isLoading } = useIsBasicPlan()
  const isBlocked = blocked || isBasicPlan

  const handleDropzoneClick = useCallback(() => {
    if (isBlocked) {
      openUpgradeModal()
    }

    onClick?.()
  }, [isBlocked, onClick, openUpgradeModal])

  return (
    <>
      {isLoading ? (
        <SkeletonBox fullHeight fullWidth />
      ) : (
        <Dropzone
          {...rest}
          blockedText={blockedText || t('premium_feature.wrong_subscription.info_warning')}
          isBlocked={isBlocked}
          onClick={handleDropzoneClick}
        />
      )}
      <UpgradeSubscriptionModal id={upgradeModalId} trackingContext={trackingContext} />
    </>
  )
}
