import {
  Checkbox,
  Fieldset,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabContent,
  Tabs,
} from '@components-deprecated'
import { Button, notify } from '@design-system'

import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { AccountSelector } from '@modules-deprecated/app/accounts/elements/AccountSelector'
import { Account, AccountNature } from '@modules-deprecated/app/accounts/types'
import { AddressSelector } from '@modules-deprecated/app/address/elements/AddressSelector'
import { Address } from '@modules-deprecated/app/address/types'
import { CompanySelector } from '@modules-deprecated/app/companies/elements/CompanySelector'
import { Company } from '@modules-deprecated/app/companies/types'
import { contactsRequested } from '@modules-deprecated/app/contacts/action-creators'
import { CountrySelector } from '@modules-deprecated/app/countries/elements/CountrySelector'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { TaxRateSelector } from '@modules-deprecated/app/taxrates/elements/TaxRateSelector'
import { TaxRate } from '@modules-deprecated/app/taxrates/types'

import { ControlledFormGroup } from '../../../components-deprecated/Form/ControlledFormGroup'
import { NotificationKeys } from '../../../enums/notificationKeys'
import { useKeyDown } from '../../../hooks'
import { removeEmptyKeys } from '../../../utils/object'
import { createContact } from '../query-api'
import { CreateContactModalSchema } from '../schemas/createContactModal'
import { Contact } from '../types/contact'
import { ContactType } from '../types/contactType'
import { Country } from '../types/country'
import { getPrefilledContactDataForForm } from '../utils/getPrefilledContactDataForForm'

const ContactModalBody = styled(ModalBody)`
  overflow: auto;
`
type CreateContactPopupProps = {
  isCustomer?: boolean
  isOpen?: boolean
  isVendor?: boolean
  onClose?: (contactId?: string) => void
  organizationId?: string
  prefilledContactData?: Partial<Contact>
  prefilledName?: string
}

type CompanyContact = Company & Contact

type ContactPersonLine = {
  name: string
  email: string
}

export type ContactFormInputs = {
  companyForName: Partial<CompanyContact> & { text?: string }
  companyForRegistrationNo: Partial<CompanyContact>
  phone: string
  email: string
  namePrivate: string
  address: Partial<Address>
  country: Partial<Country>
  isCustomer: boolean
  isSupplier: boolean
  ean: string
  defaultExpenseProductDescription: string
  defaultExpenseAccount: Partial<Account>
  paymentTermsMode: Partial<TaxRate>
  contactPersons: ContactPersonLine[]
}

const contactFormDefaultValues = {
  companyForName: {},
  companyForRegistrationNo: {},
  email: '',
  phone: '',
  namePrivate: '',
  address: {},
  country: {
    id: 'DK',
  },
  isCustomer: false,
  isSupplier: false,
  ean: '',
  defaultExpenseProductDescription: '',
  defaultExpenseAccount: {},
  paymentTermsMode: {},
  contactPersons: [
    {
      name: '',
      email: '',
    },
  ],
}

const CreateContactModal = ({
  onClose = () => null,
  isOpen,
  isCustomer,
  isVendor,
  prefilledName,
  prefilledContactData,
}: CreateContactPopupProps): JSX.Element => {
  const [type, setType] = useState<ContactType>(ContactType.Company)
  const { organization } = useUserOrganization()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const form = useForm<ContactFormInputs>({
    defaultValues: {
      ...contactFormDefaultValues,
      ...(prefilledContactData ? getPrefilledContactDataForForm(prefilledContactData) : {}),
      namePrivate: prefilledName,
      companyForName: {
        name: prefilledName,
        text: prefilledName,
      },
      isSupplier: !!isVendor,
      isCustomer: !!isCustomer,
    },
    resolver: yupResolver(CreateContactModalSchema(t, type)),
  })
  const organizationId = organization?.id || ''

  const prefillAddressFromCompany = (company: Partial<CompanyContact>) => {
    const { city, state, street, zipcode } = company

    const address: Partial<Address> = {
      cityText: city,
      street,
      zipcodeText: zipcode,
      stateText: state,
    }

    const newAddress = {
      ...removeEmptyKeys(form.getValues().address),
      ...removeEmptyKeys(address),
    }

    form.setValue('address', newAddress)
  }

  const prefillCVRFromCompany = (company: Partial<CompanyContact>) => {
    const { id } = company

    if (!id) {
      return
    }

    form.setValue('companyForRegistrationNo', { registrationNo: id, text: id })
  }

  const prefillNameFromCompany = (company: Partial<CompanyContact>) => {
    const { name } = company

    if (!name) {
      return
    }

    form.setValue('companyForName', { name, text: name })
  }

  const { id: countryId } = form.watch('country')
  const companyForName = form.watch('companyForName')
  const companyForRegistrationNo = form.watch('companyForRegistrationNo')

  useEffect(() => {
    form.setValue('address', { ...form.getValues().address, cityId: null, zipcodeId: null })
  }, [countryId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    prefillAddressFromCompany(companyForName)
    prefillCVRFromCompany(companyForName)
  }, [companyForName])

  useEffect(() => {
    prefillAddressFromCompany(companyForRegistrationNo)
    prefillNameFromCompany(companyForRegistrationNo)
  }, [companyForRegistrationNo])

  const handleSubmit = async (formData: ContactFormInputs) => {
    const contact = {
      type,
      name: type === ContactType.Person ? formData.namePrivate : formData.companyForName.name,
      ...formData.address,
      phone: formData.phone,
      registrationNo: formData.companyForRegistrationNo.registrationNo,
      ean: formData.ean,
      isCustomer: formData.isCustomer,
      isSupplier: formData.isSupplier,
      defaultExpenseProductDescription: formData.defaultExpenseProductDescription,
      organizationId,
      countryId: formData.country.id,
      defaultTaxRateId: formData.paymentTermsMode.id,
      defaultExpenseAccountId: formData.defaultExpenseAccount.id,
      contactPersons:
        type === ContactType.Person
          ? [
              {
                name: formData.namePrivate,
                email: formData.email,
              },
            ]
          : formData.contactPersons,
    }

    try {
      const saveContactResult = await createContact(contact)

      if (saveContactResult.meta.success) {
        onClose(saveContactResult.contacts[0].id)
        dispatch(
          contactsRequested({
            organizationId,
            page: { offset: 0 },
            filters: { text: null, isCustomer, isVendor },
          }),
        )
      }
    } catch (error) {
      notify({ id: NotificationKeys.CreateContact, message: t('error_saving'), variant: 'error' })
    }
  }

  const submitForm = () => {
    form.handleSubmit(handleSubmit)()
  }

  // Shortcuts

  useKeyDown(
    'Enter',
    (event) => {
      if (isOpen) {
        event.preventDefault()
        event.stopPropagation()
        submitForm()
      }
    },
    { withShift: true },
  )

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} autofocus>
        <ModalHeader heading={t('contacts.header.create_contact')} />
        <ContactModalBody>
          <FormProvider {...form}>
            <form>
              <Tabs onSelectAction={(tabId) => setType(tabId as ContactType)}>
                <TabContent text={t('contacts.tab.company')} id={ContactType.Company}>
                  <CompanySelector
                    required
                    name="companyForName"
                    label={t('contact.property.company_name')}
                    onTextChange={(text) => form.setValue('companyForName', { name: text })}
                    formControl={form.control}
                  />
                  <CompanySelector
                    name="companyForRegistrationNo"
                    label={t('contacts.property.cvr')}
                    onTextChange={(text) => form.setValue('companyForRegistrationNo', { registrationNo: text })}
                    displayProp="id"
                    formControl={form.control}
                  />
                  <ControlledFormGroup
                    label={t('contacts.form.group.contact_person')}
                    addButtonLabel={t('contacts.form.action.add_new_contact_person')}
                    formControl={form.control}
                    name="contactPersons"
                    editable
                  >
                    <Input
                      name="name"
                      placeholder={t('contact.property.name.placeholder')}
                      formControl={form.control}
                    />
                    <Input
                      name="email"
                      placeholder={t('contacts.form.email.placeholder')}
                      type="email"
                      formControl={form.control}
                    />
                  </ControlledFormGroup>
                  <Input label={t('contacts.form.phone')} name="phone" formControl={form.control} />
                  <AddressSelector countryId={countryId} name="address" formControl={form.control} />
                </TabContent>
                <TabContent text={t('contacts.tab.individual')} id={ContactType.Person}>
                  <Input name="namePrivate" label={t('contact.property.name')} formControl={form.control} required />
                  <Input
                    name="email"
                    label={t('contacts.form.email')}
                    placeholder={t('contacts.form.email.placeholder')}
                    formControl={form.control}
                  />
                  <Input label={t('contacts.form.phone')} name="phone" formControl={form.control} />
                  <AddressSelector countryId={countryId} name="address" formControl={form.control} />
                </TabContent>
                <TabContent fixed>
                  <CountrySelector
                    name="country"
                    label={t('contacts.form.country')}
                    formControl={form.control}
                    required
                  />
                  <Fieldset name="contact-type" label={t('contacts.form.type')} inline>
                    <Checkbox name="isCustomer" label={t('contacts.form.type.customer')} formControl={form.control} />
                    <Checkbox name="isSupplier" label={t('contacts.form.type.vendor')} formControl={form.control} />
                  </Fieldset>
                  <Input
                    name="ean"
                    label={t('contact.property.ean')}
                    placeholder={t('contact.property.ean')}
                    options={{ pattern: { value: /\d{13}/ } }}
                    formControl={form.control}
                  />
                  <Input
                    name="defaultExpenseProductDescription"
                    label={t('contacts.form.standard_product_description')}
                    placeholder={t('contacts.form.standard_product_description.placeholder')}
                    formControl={form.control}
                  />
                  <AccountSelector
                    formControl={form.control}
                    label={t('contacts.form.standard_expense_category')}
                    name="defaultExpenseAccount"
                    natures={[AccountNature.Expense, AccountNature.Asset]}
                    placeholder={t('voucher.inbox.form.select_account')}
                  />
                  <TaxRateSelector
                    formControl={form.control}
                    label={t('voucher.inbox.form.vat_rate')}
                    name="paymentTermsMode"
                    placeholder={t('voucher.inbox.form.vat_rate_placeholder')}
                    purchase
                  />
                </TabContent>
              </Tabs>
            </form>
          </FormProvider>
        </ContactModalBody>
        <ModalFooter>
          <Button onClick={submitForm} type="submit">
            {t('save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CreateContactModal
