import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const Content = styled.div``

export const SalesTaxPeriodSelector = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${Spacing.M};
`
