import { ProcessingOverlay, SettingsSection } from '@components'
import { Color, FormItemsGroup, Input } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../hooks'
import { useOrganizationInfoSettings } from '../../contexts/organizationInfoSettingsContext'
import { OrganizationInfoFormSchema } from '../../utils/formData'
import * as Styled from './styles'

export const ContactInfo = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<OrganizationInfoFormSchema>()
  const { isProcessing, isLoading } = useOrganizationInfoSettings()

  return (
    <SettingsSection.Content title={t('settings.organization.organization_info.contact_info.heading')}>
      <Styled.ContactInfoFormWrapper>
        {(isProcessing || isLoading) && <ProcessingOverlay color={Color.White} />}
        <FormItemsGroup>
          <FormItem
            name="phone"
            label={t('settings.organization.organization_info.contact_info.phone.label')}
            render={({ field }) => <Input {...field} />}
          />
          <FormItem
            name="fax"
            label={t('settings.organization.organization_info.contact_info.fax.label')}
            render={({ field }) => <Input {...field} />}
          />
          <FormItem
            name="email"
            label={t('settings.organization.organization_info.contact_info.email.label')}
            render={({ field }) => <Input {...field} type="email" />}
          />
        </FormItemsGroup>
      </Styled.ContactInfoFormWrapper>
    </SettingsSection.Content>
  )
}
