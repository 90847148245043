import React, { ChangeEvent, ReactElement } from 'react'

import { Checkbox } from '../../../Checkbox'
import { Text } from '../../../Text'
import * as Styled from './styles'

interface ButtonDropdownMultipleHeaderProps {
  checked?: boolean
  isIndeterminate?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  label?: string
}

export const ButtonDropdownMultipleHeader = ({
  onChange,
  checked,
  isIndeterminate,
  label,
}: ButtonDropdownMultipleHeaderProps): ReactElement => {
  return (
    <Styled.ButtonDropdownMultipleHeaderWrapper>
      <Checkbox onChange={onChange} checked={checked} indeterminate={isIndeterminate} size="l">
        <Text colorVariant="primary" weight="medium">
          {label}
        </Text>
      </Checkbox>
    </Styled.ButtonDropdownMultipleHeaderWrapper>
  )
}
