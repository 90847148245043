import { addDays, addYears, getYear, subYears } from 'date-fns'

interface FiscalYear {
  year: number
  startDate: Date
  endDate: Date
}

const getNextFiscalYear = (endDate: Date): FiscalYear => {
  const nextEndDate = addYears(endDate, 1)
  const nextStartDate = addDays(subYears(nextEndDate, 1), 1)

  return {
    year: getYear(nextEndDate),
    startDate: nextStartDate,
    endDate: nextEndDate,
  }
}

export const getFiscalYearForYear = (year: number, firstStartDate: Date, firstEndDate: Date): FiscalYear => {
  let fiscalYear = {
    year: getYear(firstStartDate),
    startDate: firstStartDate,
    endDate: firstEndDate,
  }

  while (getYear(fiscalYear.endDate) < year) {
    fiscalYear = getNextFiscalYear(fiscalYear.endDate)
  }

  return fiscalYear
}
