import {
  Badge,
  ButtonsGroup,
  ClearInputButton,
  DropdownNavList,
  DropdownNavListProps,
  findNavItem,
  getSelectTogglerIcon,
  Icon,
  InputProps,
  NavItem,
  NavListItemWithBlockDescription,
  useModal,
  useTheme,
} from '@design-system'

import isEqual from 'lodash/isEqual'
import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { fetchContacts, FetchContactsQueryParams } from '@modules-deprecated/app/contacts/query-api'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { Contact } from '@views/contacts/types/contact'

import { KeyboardKey } from '../../../enums/keyboardKey'
import { ModalId } from '../../../enums/modalId'
import { QueryKeys } from '../../../enums/queryKeys'
import { CreateContactModal } from '../../Modal/CreateContactModal'
import { ModalType } from '../../Modal/CreateContactModal/enums/modalType'
import { ContactSelectDropdownFooter } from './elements/ContactSelectDropdownFooter'
import * as Styled from './styles'
import { ContactValue } from './types/contactValue'
import { filterContacts } from './utils/filterContacts'
import { getContactValue } from './utils/getContactValue'
import { getDefaultContactsFilters } from './utils/getDefaultContactsFilters'
import { getItems } from './utils/getItems'

export interface ContactSelectProps
  extends Pick<DropdownNavListProps<Partial<Contact>>, 'placement' | 'selectedId'>,
    Pick<
      InputProps,
      | 'alignment'
      | 'autoCompleted'
      | 'autoFocus'
      | 'bordered'
      | 'className'
      | 'disabled'
      | 'error'
      | 'focused'
      | 'hidden'
      | 'id'
      | 'name'
      | 'onBlur'
      | 'onChange'
      | 'onChangeDebounced'
      | 'onFocus'
      | 'onKeyDown'
      | 'onKeyPress'
      | 'onMouseDown'
      | 'onPressEnter'
      | 'placeholder'
      | 'readOnly'
      | 'selectOnFocus'
      | 'size'
      | 'success'
      | 'suffix'
      | 'type'
    > {
  // Select props
  allowClear?: boolean
  allowCreateNew?: boolean
  inputValue?: string
  modalType?: ModalType
  onCreateNew?: (contact: Partial<Contact>) => void
  onSelect?: (id?: string, value?: Partial<Contact>) => void
  selectedValue?: Partial<Contact>
  /** if enabled, input has cursor inside and user can search for the items */
  withSearch?: boolean
  // DropdownNavList props
  dropdownId?: string
  dropdownMaxHeight?: DropdownNavListProps<Partial<Contact>>['maxHeight']
  dropdownSize?: DropdownNavListProps<Partial<Contact>>['size']
}

const PAGES_LIMIT = 2

export const ContactSelect = ({
  allowClear = false,
  allowCreateNew = false,
  inputValue: inputValueControlled,
  modalType = ModalType.Vendor,
  onCreateNew,
  onSelect,
  selectedId: selectedIdControlled,
  selectedValue: selectedValueControlled,
  withSearch = false,
  // DropdownNavList props
  dropdownId,
  dropdownMaxHeight = 'default',
  dropdownSize = 'fitTrigger',
  placement = 'bottom-end',
  // Input props
  alignment = 'left',
  autoCompleted,
  autoFocus,
  bordered,
  className,
  disabled,
  error,
  focused = false,
  hidden,
  id,
  name,
  onBlur,
  onChange,
  onChangeDebounced,
  onFocus,
  onKeyDown,
  onKeyPress,
  onMouseDown,
  onPressEnter,
  placeholder,
  readOnly,
  selectOnFocus,
  size,
  success,
  type,
}: ContactSelectProps): ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { organization } = useUserOrganization()
  const { open: openModal } = useModal(ModalId.CreateContactModal)
  const [itemsListProcessed, setItemsListProcessed] = useState<NavItem<ContactValue>[]>([])
  const [isFocused, setIsFocused] = useState(focused)
  const [selectedId, setSelectedId] = useState<string | undefined>(selectedIdControlled || '')
  const [selectedValue, setSelectedValue] = useState<Partial<Contact> | undefined>(selectedValueControlled)
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState(inputValueControlled || '')
  const [currentPage, setCurrentPage] = useState(1)
  const isInputActionClickedRef = useRef(false) // helper for detecting click on some action(clear, create) inside input to not open the dropdown
  const isDropdownInsideClickedRef = useRef(false) // helper for not clearing data on blur, after selecting some item (check handleInputBlur)

  const isClearable = withSearch && allowClear && !!inputValue

  const togglerIcon = useMemo(
    () => getSelectTogglerIcon({ isOpen, isFocused, withSearch, withSearchOnly: false }),
    [isOpen, isFocused, withSearch],
  )

  const queryProps: FetchContactsQueryParams = useMemo(
    () => ({
      organizationId: organization?.id as string,
      ...(modalType === ModalType.Client ? { isCustomer: true } : {}),
      ...(modalType === ModalType.Vendor ? { isSupplier: true } : {}),
      q: inputValue,
      page: currentPage,
      pageSize: 100,
    }),
    [organization?.id, modalType, inputValue, currentPage],
  )

  const { data, isLoading, refetch } = useQuery([QueryKeys.Contacts, queryProps], () => fetchContacts(queryProps), {
    enabled: !!organization?.id,
    cacheTime: 0,
  })

  const responseFilters = useMemo(() => data?.meta?.paging, [data?.meta?.paging])

  useEffect(() => {
    const defaultFilters = getDefaultContactsFilters()
    const contactsFiltered = filterContacts(data?.contacts, defaultFilters, selectedId)
    const isPagesLimitItem = !inputValue?.length && currentPage === PAGES_LIMIT
    const items = getItems(contactsFiltered, t, isPagesLimitItem)

    setItemsListProcessed((prevItems) => [...prevItems, ...items])
    // should only trigger on data?.contacts change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.contacts])

  const openDropdown = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closeDropdown = useCallback(() => {
    setIsOpen(false)
  }, [])

  const toggleDropdown = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }, [])

  const handleDropdownOpen = useCallback(() => {
    openDropdown()
  }, [openDropdown])

  const handleDropdownClose = useCallback(() => {
    closeDropdown()
  }, [closeDropdown])

  const resetItemsState = useCallback(() => {
    setItemsListProcessed([])
    setCurrentPage(1)
  }, [])

  const setSelectedContact = useCallback((contact: Partial<Contact> | undefined) => {
    // Contact could be undefined in case it was changed outside the component
    setInputValue(contact?.name || '')
    setSelectedId(contact?.id || undefined)
    setSelectedValue(contact || undefined)
  }, [])

  const handleInputActionClicked = useCallback(() => {
    isInputActionClickedRef.current = true
  }, [])

  const handleCreateNew = useCallback(() => {
    openModal()
    closeDropdown()
  }, [openModal, closeDropdown])

  const handleCreateNewContactMouseDown = useCallback(() => {
    handleInputActionClicked()
    closeDropdown()
    handleCreateNew()
  }, [closeDropdown, handleCreateNew, handleInputActionClicked])

  const selectItem = useCallback(
    (id: string | undefined, withSelectEvent = true) => {
      const item = findNavItem(itemsListProcessed, id)

      // When select same item and input value wasn't changed
      if (id !== selectedId && item?.value.name !== inputValue) {
        resetItemsState()
      }

      if (item) {
        setSelectedId(id)
        setSelectedValue(getContactValue(item))
        setInputValue(item.value.name)
      } else {
        setSelectedId(undefined)
        setSelectedValue(undefined)
      }

      if (withSelectEvent) {
        const valueFiltered = item?.value ? getContactValue(item) : undefined
        onSelect?.(id, valueFiltered)
      }
    },
    [itemsListProcessed, onSelect, resetItemsState, selectedId, inputValue],
  )

  const handleClearIconMouseDown = useCallback(() => {
    if (allowClear) {
      handleInputActionClicked()
      selectItem(undefined, true)
      setInputValue('')
    }
  }, [allowClear, handleInputActionClicked, selectItem])

  const handleItemClick = useCallback(
    (id: string) => {
      if (withSearch) {
        isDropdownInsideClickedRef.current = true
      }

      selectItem(id, true)
    },
    [selectItem, withSearch],
  )

  const handleInputMouseDown = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (isInputActionClickedRef.current) {
        isInputActionClickedRef.current = false
        return
      }

      if (withSearch) {
        isDropdownInsideClickedRef.current = true
      }

      toggleDropdown()
      onMouseDown?.(event)
    },
    [onMouseDown, withSearch, toggleDropdown],
  )

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      resetItemsState()
      setInputValue(value)
      openDropdown()
      onChange?.(event)
    },
    [onChange, openDropdown, setInputValue, resetItemsState],
  )

  const handleInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (withSearch) {
        if (isDropdownInsideClickedRef.current) {
          isDropdownInsideClickedRef.current = false
        } else {
          // We need to reset items and input only when isDropdownInsideClickedRef.current = false
          if (!selectedId && !selectedValue && !!inputValue) {
            resetItemsState()
            setInputValue('')
          }

          closeDropdown()
        }
      } else {
        closeDropdown()
      }

      setIsFocused(false)
      onBlur?.(event)
    },
    [onBlur, closeDropdown, selectedId, selectedValue, withSearch, inputValue, resetItemsState],
  )

  const handleInputFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true)
      onFocus?.(event)
    },
    [onFocus],
  )

  const handleInputKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (isDropdownInsideClickedRef.current) {
        isDropdownInsideClickedRef.current = false
      }

      if (event.key === KeyboardKey.ArrowDown) {
        openDropdown()
      }

      onKeyDown?.(event)
    },
    [onKeyDown, openDropdown],
  )

  const handleDropdownMouseDown = useCallback(() => {
    if (withSearch) {
      isDropdownInsideClickedRef.current = true
    }
  }, [withSearch])

  const handlePressEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (allowCreateNew && !itemsListProcessed.length && !selectedId) {
        handleCreateNew()
      }

      onPressEnter?.(event)
    },
    [allowCreateNew, onPressEnter, itemsListProcessed, handleCreateNew, selectedId],
  )

  const handleScrollEnd = useCallback(() => {
    if (responseFilters && responseFilters.pageCount > currentPage) {
      if (currentPage === PAGES_LIMIT && !inputValue.length) {
        return
      }
      setCurrentPage(responseFilters.page + 1)
    }
  }, [currentPage, responseFilters, inputValue])

  const handleContactCreated = useCallback(
    async (contact: Partial<Contact>) => {
      resetItemsState()

      // We need to set all new contact data to avoid list reset in selectedValueControlled useEffect
      setSelectedContact(contact)
      // Refetch contacts list to select new contact
      await refetch()
      onSelect?.(contact.id, contact)
      onCreateNew?.(contact)
    },
    [onSelect, resetItemsState, onCreateNew, refetch, setSelectedContact],
  )

  useEffect(() => {
    if (selectedIdControlled !== undefined && selectedIdControlled !== selectedId) {
      selectItem(selectedIdControlled, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIdControlled])

  useEffect(() => {
    if (!isEqual(selectedValueControlled, selectedValue)) {
      resetItemsState()
      setSelectedContact(selectedValueControlled)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValueControlled])

  useEffect(() => {
    if (inputValueControlled !== undefined && inputValueControlled !== inputValue) {
      resetItemsState()
      setInputValue(inputValueControlled)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueControlled])

  useEffect(() => {
    setIsFocused(focused)
  }, [focused])

  const modalDefaultValues = useMemo((): Partial<Contact> => {
    const countryId = organization?.countryId
    let defaultValues: Partial<Contact> = { ...(countryId ? { countryId } : {}) }

    if (selectedValue && !selectedValue?.id) {
      defaultValues = { ...selectedValue, ...defaultValues }
    } else if (selectedValue?.id) {
      defaultValues.name = ''
    } else {
      defaultValues.name = inputValue
    }

    return defaultValues
  }, [selectedValue, inputValue, organization?.countryId])

  return (
    <>
      <DropdownNavList
        autoToggle={false}
        id={dropdownId}
        isFetching={isLoading}
        isOpen={isOpen}
        itemRender={(props) => <NavListItemWithBlockDescription {...props} />}
        items={itemsListProcessed}
        maxHeight={dropdownMaxHeight}
        onClose={handleDropdownClose}
        onItemClick={handleItemClick}
        onKeyDown={handleInputKeyDown}
        onMouseDown={handleDropdownMouseDown}
        onOpen={handleDropdownOpen}
        onScrollEnd={handleScrollEnd}
        placement={placement}
        scrollEndOffset={50}
        selectedId={selectedId}
        size={dropdownSize}
        footer={
          allowCreateNew ? (
            <ContactSelectDropdownFooter onClick={handleCreateNew} inputValue={inputValue} selectedId={selectedId} />
          ) : undefined
        }
        trigger={
          <Styled.Input
            alignment={alignment}
            autoComplete="off"
            autoCompleted={autoCompleted}
            autoFocus={autoFocus}
            bordered={bordered}
            className={className}
            clearable={isClearable}
            disabled={disabled}
            error={error}
            focused={isFocused}
            hidden={hidden}
            id={id}
            name={name}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            onChangeDebounced={onChangeDebounced}
            onMouseDown={handleInputMouseDown}
            onFocus={handleInputFocus}
            onKeyPress={onKeyPress}
            onPressEnter={handlePressEnter}
            placeholder={placeholder || `${t('company_select.dropdown.placeholder')}...`}
            readOnly={readOnly}
            searchable={withSearch}
            selectLook
            selectOnFocus={selectOnFocus}
            size={size}
            success={success}
            suffix={
              <ButtonsGroup>
                {isClearable && !disabled && (
                  <Styled.ClearInputWrapper>
                    <ClearInputButton onMouseDown={handleClearIconMouseDown} />
                  </Styled.ClearInputWrapper>
                )}

                {selectedValue && !selectedValue?.id && (
                  <Styled.CreateNewContactButton type="button" onMouseDown={handleCreateNewContactMouseDown}>
                    <Badge>{t('contact_select.create_new_contact.text')}</Badge>
                  </Styled.CreateNewContactButton>
                )}

                <Styled.ToggleIconWrapper>
                  <Icon icon={togglerIcon} color={theme.ds.colors.base.textPrimary} />
                </Styled.ToggleIconWrapper>
              </ButtonsGroup>
            }
            title={inputValue}
            truncate
            type={type}
            value={inputValue}
          />
        }
        withNavigation
      />
      <CreateContactModal type={modalType} defaultValues={modalDefaultValues} onContactCreate={handleContactCreated} />
    </>
  )
}
