import { SkeletonText } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { BillType } from '../../enums/billType'
import { getContactFromBill } from '../../utils/getContactFromBill'
import { useBillView } from '../BillReadableForm'

export const BillReadableTitle = (): ReactElement => {
  const { isFetching, billData } = useBillView()
  const { t } = useTranslation()

  if (!billData || isFetching) {
    return <SkeletonText width={250} variant="h1" />
  }

  const { bill, contacts } = billData
  const isCreditNote = billData?.bill.type === BillType.CreditNote
  const vendor = getContactFromBill(bill, contacts)

  return (
    <>
      {isCreditNote ? t('bill.view.title.credit_note_from') : t('bill.view.title.bill_from')} {vendor?.name}
    </>
  )
}
