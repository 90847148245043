import { ButtonProtected, SettingsSection, SubscriptionInvoices } from '@components'

import isEmpty from 'lodash/isEmpty'
import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'
import { Plan } from '@modules-deprecated/app/organization/types/plan'
import { useIsAnnualReportAvailable } from '@views/annualReport/hooks/useIsAnnualReportAvailable'

import { Scope } from '../../../../../../enums/scope'
import { TrackingContext } from '../../../../../../enums/trackingContext'
import { useOrganizationSubscription } from '../../../../../../hooks'
import { isExpired } from '../../../../../../utils/payment'
import { SettingsRoute } from '../../../../enums/settingsRoute'
import { plansPossibleToChange } from '../../plansData'
import { SubscriptionMetricsData } from '../../services/query-api'
import { EditCardSubscriptionParams } from '../../types'
import { AddonsListComponent } from '../AddonsListComponent'
import { BillingSummaryComponent } from '../BillingSummaryComponent'
import { PlanDetailsSideActionsComponent } from '../PlanDetailsSideActionsComponent'
import { SectionPlanDetailsComponent } from '../SectionPlanDetailsComponent'
import { SectionPlanUsageComponent } from '../SectionPlanUsageComponent'

interface SubscriptionOverviewProps {
  isInUpdatePeriodState: boolean
  isLoading: boolean
  onEditCardSubscription: (event: EditCardSubscriptionParams) => void
  onSubscriptionPeriodToggle: () => void
  subscriptionMetrics: SubscriptionMetricsData
  subscriptionPlans: Plan[]
}

export const SubscriptionOverview = ({
  isInUpdatePeriodState,
  isLoading,
  onEditCardSubscription,
  onSubscriptionPeriodToggle,
  subscriptionMetrics,
  subscriptionPlans,
}: SubscriptionOverviewProps): ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    switchPlan,
    switchPlanPeriod,
    isSwitchingPlan,
    planExpirationDate,
    subscriptionPlan,
    subscriptionPeriod,
    isTrial,
    isTrialNoPlanSelected,
    isStandardPlan,
    discount,
    cardAdded,
    cardExpirationDate,
  } = useOrganizationSubscription()
  const isAnnualReportAvailable = useIsAnnualReportAvailable()

  const premiumPlanWithAnnualReportsDisabled = subscriptionPlan === SubscriptionPlan.Premium && !isAnnualReportAvailable
  const shouldShowChangePlanButton =
    (isTrial || plansPossibleToChange.includes(subscriptionPlan)) && !premiumPlanWithAnnualReportsDisabled

  const title = isTrialNoPlanSelected
    ? t('organization_subscription.choose_trial_end')
    : t('organization_subscription.subscription_overview')

  const handleClickChangePlan = () => {
    history.push(SettingsRoute.OrganizationSubscriptionPlansSelection)
  }

  const getAvailableAddons = () => {
    const currentPlan = subscriptionPlans?.filter((planItem) => planItem.id === subscriptionPlan)[0]
    // we currently only offer validated_scans
    return currentPlan ? currentPlan.addons.filter((addon) => addon.id === 'validated_scans') : []
  }

  const handleEditPaymentInformationClick = useCallback(() => {
    if (cardAdded && cardExpirationDate && !isExpired(cardExpirationDate)) {
      onEditCardSubscription({ detail: { cardOnly: true } })
    }
  }, [cardAdded, cardExpirationDate, onEditCardSubscription])

  return (
    <>
      <SettingsSection>
        <SettingsSection.RowsContainer>
          <SettingsSection.Content
            title={title}
            sideActions={
              shouldShowChangePlanButton && (
                <PlanDetailsSideActionsComponent onClickChangePlan={handleClickChangePlan} />
              )
            }
          >
            <SectionPlanDetailsComponent
              isTrial={isTrial}
              switchPlan={switchPlan}
              switchPlanPeriod={switchPlanPeriod}
              isSwitchingPlan={isSwitchingPlan}
              planExpirationDate={planExpirationDate}
              postingsLimit={subscriptionMetrics.plans[subscriptionPlan]?.postingsNumberLimit}
              revenueLimit={subscriptionMetrics.plans[subscriptionPlan]?.revenueLimit}
              subscriptionPeriod={subscriptionPeriod}
              subscriptionPlan={subscriptionPlan}
              onSubscriptionPeriodToggle={onSubscriptionPeriodToggle}
              isInUpdatePeriodState={isInUpdatePeriodState}
              hasDiscount={!!discount && !isEmpty(discount)}
            />
            <SectionPlanUsageComponent
              subscriptionPlan={subscriptionPlan}
              onClickChangePlan={handleClickChangePlan}
              revenue={subscriptionMetrics?.revenue}
              revenueLimit={subscriptionMetrics.plans[subscriptionPlan]?.revenueLimit}
              isRevenueEstimated={subscriptionMetrics.isRevenueEstimated}
              postings={subscriptionMetrics?.postings}
              postingsLimit={subscriptionMetrics.plans[subscriptionPlan]?.postingsNumberLimit}
              hasSufficientData={subscriptionMetrics.isSufficientData}
              isStandardPlan={isStandardPlan}
            />
            {getAvailableAddons().length > 0 && getAvailableAddons()[0].value > 0 && (
              <AddonsListComponent addons={getAvailableAddons()} />
            )}
          </SettingsSection.Content>
        </SettingsSection.RowsContainer>
      </SettingsSection>
      <SettingsSection>
        <SettingsSection.RowsContainer>
          <SettingsSection.Content
            title={t('organization_subscription.payment_methods')}
            sideActions={
              cardAdded && (
                <ButtonProtected
                  scopes={Scope.OrganizationPaymentWrite}
                  trackingContext={TrackingContext.PaymentInformationEdit}
                  onClick={handleEditPaymentInformationClick}
                  icon="paperWithPencil"
                  variant="secondary"
                >
                  {t('billing_summary.footer.edit_payment')}
                </ButtonProtected>
              )
            }
          >
            <BillingSummaryComponent isLoading={isLoading} onEditCardSubscription={onEditCardSubscription} />
          </SettingsSection.Content>
        </SettingsSection.RowsContainer>
      </SettingsSection>
      <SettingsSection>
        <SettingsSection.RowsContainer>
          <SettingsSection.Content title={t('organization_subscription.paid_invoices')} collapsible>
            <SubscriptionInvoices />
          </SettingsSection.Content>
        </SettingsSection.RowsContainer>
      </SettingsSection>
    </>
  )
}
