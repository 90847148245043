import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface StepsListProps {
  children?: ReactNode
}
export const StepsList = ({ children }: StepsListProps): ReactElement => {
  return <Styled.StepsList>{children}</Styled.StepsList>
}
