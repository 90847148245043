import React from 'react'

import { IconProps } from '../Icon'

export const IconAlert = ({ color = '#FF543E', width = '20px', height = '20px', ...rest }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.7239 5.93041C15.1743 3.88242 13.8341 2.1364 11.9978 1.07603C10.1615 0.0156641 7.97939 -0.272304 5.9309 0.275406C3.88269 0.824995 2.13649 2.16534 1.0761 4.00185C0.0157065 5.83837 -0.272113 8.02077 0.275899 10.0694C0.731668 11.7676 1.73388 13.2685 3.12775 14.3403C4.52163 15.4121 6.22961 15.9951 7.9879 15.9994C8.69092 15.9989 9.39085 15.9064 10.0699 15.7244C12.1177 15.1746 13.8635 13.8343 14.9236 11.9981C15.9838 10.1618 16.2716 7.97977 15.7239 5.93141V5.93041ZM13.7379 11.3114C13.1933 12.2553 12.4242 13.0503 11.4989 13.626C10.5736 14.2017 9.52056 14.5402 8.43313 14.6117C7.3457 14.6831 6.25743 14.4852 5.26475 14.0356C4.27207 13.5859 3.40562 12.8984 2.74217 12.0338C2.07872 11.1693 1.63874 10.1544 1.46123 9.07922C1.28372 8.004 1.37415 6.90159 1.72451 5.86968C2.07487 4.83776 2.67435 3.90818 3.46982 3.16332C4.2653 2.41845 5.23221 1.88128 6.2849 1.59941C6.84775 1.44796 7.42803 1.37097 8.0109 1.37041C9.17311 1.37279 10.3143 1.68073 11.3199 2.26333C12.3255 2.84594 13.1603 3.68274 13.7404 4.68978C14.3206 5.69683 14.6258 6.83873 14.6253 8.00093C14.6249 9.16314 14.3188 10.3048 13.7379 11.3114Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00003 11.6783C8.39767 11.6783 8.72003 11.3559 8.72003 10.9583C8.72003 10.5606 8.39767 10.2383 8.00003 10.2383C7.60238 10.2383 7.28003 10.5606 7.28003 10.9583C7.28003 11.3559 7.60238 11.6783 8.00003 11.6783Z"
      fill={color}
      stroke={color}
    />
    <path d="M8 5.12109V8.00109" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
