import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface EmptyScreenProps {
  headerText?: string
  subText?: string
  img: ReactNode
}

export const EmptyScreen = ({ headerText, subText, img }: EmptyScreenProps): ReactElement => (
  <Styled.EmptyScreenWrapper>
    {img}
    {headerText && <Styled.Header variant="h2"> {headerText} </Styled.Header>}
    {subText && <Styled.SubText> {subText} </Styled.SubText>}
  </Styled.EmptyScreenWrapper>
)
