import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconVideo = ({
  color = '#FFFFFF',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-video')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill={coloring(color)}
        d="M15,1H1C0.448,1,0,1.448,0,2v11c0,0.552,0.448,1,1,1h14c0.552,0,1-0.448,1-1V2C16,1.448,15.552,1,15,1z M15,3v9H1V3H15z"
      />
      <polygon fill={coloring(color)} points="6,5 11,7.5 6,10 " />
    </svg>
  </Box>
)
