import React, { createContext, ReactElement, ReactNode, useContext } from 'react'

export type EmberUserType = 'Accountant' | 'Business Owner' | 'Business Colleague' | 'Unknown'

interface ContextState {
  userType: EmberUserType
}

const EmberUserContextContext = createContext<ContextState | undefined>(undefined)

interface EmberCurrentRouteContextProviderProps {
  children?: ReactNode
  userType: string
}

export function isOfTypeEmberUserType(keyInput: string, typeArray: string[]): keyInput is EmberUserType {
  return typeArray.includes(keyInput)
}

export const EmberUserTypeContextProvider = ({
  children,
  userType,
}: EmberCurrentRouteContextProviderProps): ReactElement => {
  const typeArray = ['Accountant', 'Business Owner', 'Business Colleague', 'Unknown']
  const parsedUserType = isOfTypeEmberUserType(userType, typeArray) ? (userType as EmberUserType) : 'Unknown'

  return (
    <EmberUserContextContext.Provider value={{ userType: parsedUserType }}>{children}</EmberUserContextContext.Provider>
  )
}

export const useEmberUserType = () => {
  const context = useContext(EmberUserContextContext)

  if (!context) {
    throw new Error('EmberUserTypeContextProvider is missing in the module!')
  }

  return context
}
