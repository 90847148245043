import { SettingsSection, UpgradeSubscriptionModal } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { TrackingContext } from '../../../../../../enums/trackingContext'
import { InvoiceTemplatesSectionSideActions } from './elements/InvoiceTemplatesSectionSideActions'
import { InvoiceTemplatesTable } from './elements/InvoiceTemplatesTable'

export const InvoiceTemplatesSection = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <SettingsSection>
      <SettingsSection.Content
        sideActions={<InvoiceTemplatesSectionSideActions />}
        subtitle={t('settings.organization.invoice.templates_settings.subtitle')}
        title={t('settings.organization.invoice.templates_settings.title')}
      >
        <InvoiceTemplatesTable />
      </SettingsSection.Content>
      <UpgradeSubscriptionModal
        id={ModalId.UpgradeSubscriptionModal}
        trackingContext={TrackingContext.InvoiceTemplates}
      />
    </SettingsSection>
  )
}
