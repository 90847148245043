import { Logo as LogoComponent } from '@components'
import { Color, Spacing, Text as TextComponent } from '@design-system'

import styled from '@emotion/styled'

export const LogoWrapper = styled.div`
  display: flex;
  margin-top: ${Spacing.XXL};
  justify-content: center;
  align-items: center;
`

export const Logo = styled(LogoComponent)`
  margin-bottom: ${Spacing.XXL};

  & + & {
    margin-left: ${Spacing.XL};
    padding-top: ${Spacing.S};
  }
`

export const SectionTitle = styled(TextComponent)`
  margin-bottom: ${Spacing.L};
`

export const Paragraph = styled(TextComponent)`
  margin-bottom: ${Spacing.XL};
`
Paragraph.defaultProps = {
  color: Color.Gray90,
}

export const TermsWrapper = styled.div`
  display: flex;
  margin-right: ${Spacing.L};
  align-items: center;
`
