import { Translate } from '@components'
import { FilesPreview, InfoPanel, PreviewFile } from '@components-deprecated'
import { SkeletonBox } from '@design-system'

import React, { ReactElement, ReactNode, useCallback } from 'react'

import * as Styled from './styles'

interface MissingInformationLayoutProps {
  bookkeeperMessage?: string
  children: ReactNode
  files: PreviewFile[]
  isLoading?: boolean
  notificationMessage?: ReactNode
}

export const MissingInformationLayout = ({
  bookkeeperMessage,
  children,
  isLoading,
  files,
  notificationMessage,
}: MissingInformationLayoutProps): ReactElement => {
  const renderInfoPanelContent = useCallback(() => {
    if (!notificationMessage) {
      return bookkeeperMessage
    }

    if (!bookkeeperMessage) {
      return notificationMessage
    }

    return (
      <>
        {notificationMessage}
        <br />
        <br />
        {bookkeeperMessage}
      </>
    )
  }, [bookkeeperMessage, notificationMessage])

  return (
    <Styled.MissingInformationWrapper>
      {/* Thumbnail */}
      <Styled.FileWrapper>
        <Styled.FileTitle>
          <Translate value="things_to_do.missing_information.thumbnail_title" />
        </Styled.FileTitle>
        <Styled.File>
          {isLoading ? (
            <SkeletonBox fullHeight fullWidth />
          ) : (
            <FilesPreview
              fitToHeight
              height="100%"
              width="100%"
              loading=""
              withNavigation={false}
              files={files}
              withModalZoom
            />
          )}
        </Styled.File>
      </Styled.FileWrapper>

      {/* Form */}
      <Styled.FormWrapper>
        {(isLoading || notificationMessage || bookkeeperMessage) && (
          <Styled.NotificationWrapper>
            {isLoading ? <SkeletonBox height={47} fullWidth /> : <InfoPanel>{renderInfoPanelContent()}</InfoPanel>}
          </Styled.NotificationWrapper>
        )}

        {isLoading ? <SkeletonBox height={160} fullWidth /> : children}
      </Styled.FormWrapper>
    </Styled.MissingInformationWrapper>
  )
}
