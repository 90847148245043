import { ButtonsGroup } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { ReportsRoute } from '../../enums/reportsRoute'
import { ExportReport } from '../ExportReport'

const routeToExportComponent: Record<ReportsRoute, () => JSX.Element> = {
  [ReportsRoute.ProfitAndLoss]: ExportReport.ProfitAndLoss,
  [ReportsRoute.TrialBalance]: ExportReport.TrialBalance,
  [ReportsRoute.Balance]: ExportReport.Balance,
}

export const ReportsSideActions = (): ReactElement => {
  const location = useLocation()
  const ExportReportComponent: (() => JSX.Element) | undefined = useMemo(
    () => routeToExportComponent[location.pathname],
    [location.pathname],
  )

  return <ButtonsGroup>{ExportReportComponent && <ExportReportComponent />}</ButtonsGroup>
}
