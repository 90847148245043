import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconPieChart = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-pie-chart')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <circle fill={coloring(color)} cx="14" cy="18" r="13"></circle>
      <path
        fill={coloring(bg)}
        d="M14,32C6.28,32,0,25.72,0,18S6.28,4,14,4s14,6.28,14,14S21.72,32,14,32z M14,6C7.383,6,2,11.383,2,18 s5.383,12,12,12s12-5.383,12-12S20.617,6,14,6z"
      ></path>
      <path fill={coloring(color2 || color)} d="M17,1.033V15h13.967C30.473,7.524,24.476,1.528,17,1.033z"></path>
      <path
        fill={coloring(bg)}
        d="M30.967,16H17c-0.553,0-1-0.447-1-1V1.033c0-0.276,0.114-0.541,0.316-0.73 c0.202-0.188,0.467-0.277,0.75-0.268c7.967,0.526,14.372,6.932,14.898,14.898c0.018,0.276-0.079,0.548-0.268,0.75 C31.508,15.886,31.243,16,30.967,16z M18,14h11.857C28.978,7.883,24.117,3.022,18,2.143V14z"
      ></path>
    </svg>
  </Box>
)
