import { amountToDisplayValue, Text, TextColorVariant } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { TooltipProps } from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

import { ChartDataKeys } from '../../enums/chartDataKeys'
import * as Styled from './styles'

type CustomTooltipData = {
  name: string
  value: number
  colorVariant: TextColorVariant
}

export const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>): ReactElement | null => {
  const { t } = useTranslation()

  if (!active || !payload?.length) {
    return null
  }

  const title = payload?.[3].payload.monthData.long || ''
  const revenue = (payload?.[2].value as number) || 0
  const expenses = (payload?.[1].value as number) || 0
  const result = (payload?.[3].value as number) || 0

  const tooltipData: CustomTooltipData[] = [
    {
      name: String(payload?.[2].name) || ChartDataKeys.Revenue,
      value: revenue,
      colorVariant: revenue < 0 ? 'danger' : 'success',
    },
    {
      name: String(payload?.[1].name) || ChartDataKeys.Expenses,
      value: expenses,
      colorVariant: expenses <= 0 ? 'danger' : 'success',
    },
    {
      name: String(payload?.[3].name) || ChartDataKeys.Result,
      value: result,
      colorVariant: result < 0 ? 'danger' : 'success',
    },
  ]

  return (
    <Styled.CustomTooltipWrapper>
      <Text alignment="center" variant="small">
        {title}
      </Text>
      {tooltipData.map((item, index) => {
        const value = item.value
        return (
          <Styled.TooltipData key={index}>
            {
              // Add divider for every value except the revenue
              index !== 1 && <Styled.TooltipDivider />
            }
            <Styled.TooltipComponentsGroup>
              <Text colorVariant="secondary" variant="small">
                {t('dashboard.widget.overview.monthly.' + item.name)}
              </Text>
              <Text alignment="right" colorVariant={item.colorVariant}>
                {amountToDisplayValue(value, value !== 0)}
              </Text>
            </Styled.TooltipComponentsGroup>
          </Styled.TooltipData>
        )
      })}
    </Styled.CustomTooltipWrapper>
  )
}
