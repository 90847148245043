import React, { ReactNode } from 'react'

import { Container } from '../Container'
import { Flex } from '../Flex'
import { IconButton } from '../IconButton'
import { Space } from '../Space'
import { Text } from '../Text'
import { ModuleContent } from './elements/ModuleContent'
import * as Styled from './styles'

export interface ModuleLayoutProps {
  backButtonTo?: string
  children?: ReactNode
  fullHeight?: boolean
  headerBottomActions?: ReactNode
  headerContent?: ReactNode
  onBackButtonClick?: () => void
  resetScroll?: boolean
  restoreScroll?: boolean
  sideActions?: ReactNode
  title?: ReactNode
  withBackButton?: boolean
}

export const ModuleLayout = ({
  backButtonTo,
  children,
  fullHeight,
  headerBottomActions,
  headerContent,
  onBackButtonClick,
  resetScroll = false, // this prop is used to enable resetting scroll position on route change
  restoreScroll = true, // restoring scroll position after re-entering ModuleLayout is a default behaviour
  sideActions,
  title,
  withBackButton,
}: ModuleLayoutProps) => {
  return (
    <Styled.ModuleLayoutWrapper>
      <Styled.ModuleStickyHeader>
        <Container fullWidth>
          <Styled.Header>
            <Flex alignItems="center" justifyContent="space-between">
              {title && (
                <Flex alignItems="center">
                  {withBackButton && (
                    <Styled.BackButtonWrapper onClick={onBackButtonClick}>
                      <IconButton to={backButtonTo} icon="chevronLeft" />
                    </Styled.BackButtonWrapper>
                  )}
                  <Text variant="h1">{title}</Text>
                </Flex>
              )}
              {headerContent && (
                <>
                  <Space horizontal />
                  <Flex flexGrow={1}>{headerContent}</Flex>
                </>
              )}
              {sideActions && <Styled.SideActions>{sideActions}</Styled.SideActions>}
            </Flex>
          </Styled.Header>
          {headerBottomActions && (
            <Styled.HeaderBottomActionsWrapper>{headerBottomActions}</Styled.HeaderBottomActionsWrapper>
          )}
        </Container>
      </Styled.ModuleStickyHeader>

      {children && (
        <ModuleContent fullHeight={fullHeight} resetScroll={resetScroll} restoreScroll={restoreScroll}>
          {children}
        </ModuleContent>
      )}
    </Styled.ModuleLayoutWrapper>
  )
}
