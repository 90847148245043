import { Color, DefaultValue } from '@design-system'

import styled from '@emotion/styled'

export const AnswersWrapper = styled.div`
  display: flex;
  border-radius: ${DefaultValue.BorderRadius};
  flex-direction: column;
  box-shadow: 0 2px 1px ${Color.Gray30};
  background: ${Color.White};
`
