import React from 'react'
import { Box } from 'rebass'

import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconFinancing = ({
  color = '#00C82B',
  color2 = '#fff',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  isOutlined,
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-financing')} {...rest}>
    <svg width={width} height={height} viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3999 16.2001V16.8C15.3999 19.6 13.0999 21.9 10.2999 21.9H5.29995C2.49995 21.9 0.199951 19.6 0.199951 16.8V16.2001C0.199951 13.6 1.79995 10.7 4.89995 7.40005C4.49995 7.10005 4.29995 6.60005 4.29995 6.00005C4.29995 5.40005 4.59995 4.80005 5.09995 4.50005L3.49995 1.20005C3.29995 0.80005 3.59995 0.300049 4.09995 0.300049H11.7C12.2 0.300049 12.4999 0.80005 12.2999 1.20005L10.7 4.50005C11.2 4.80005 11.5 5.40005 11.5 6.00005C11.5 6.50005 11.2999 7.00005 10.8999 7.40005C13.7999 10.7 15.3999 13.6 15.3999 16.2001ZM14.2 16.8V16.2001C14.2 13.9001 12.7 11.1 9.49995 8.00005H6.19995C3.09995 11.2 1.49995 14.0001 1.49995 16.2001V16.8C1.49995 18.9 3.19995 20.6001 5.29995 20.6001H10.3999C12.4999 20.6001 14.2 18.9 14.2 16.8ZM9.29995 4.20005L10.5999 1.70005H4.99995L6.29995 4.20005H9.29995ZM9.99995 6.10005C9.99995 5.80005 9.69995 5.50005 9.39995 5.50005H6.19995C5.89995 5.50005 5.59995 5.80005 5.59995 6.10005C5.59995 6.50005 5.89995 6.70005 6.19995 6.70005H9.39995C9.79995 6.70005 9.99995 6.40005 9.99995 6.10005Z"
        fill="white"
      />
      <path
        d="M14.2 16.2V16.8C14.2 18.9 12.5 20.6 10.4 20.6H5.3C3.2 20.6 1.5 18.9 1.5 16.8V16.2C1.5 14 3.1 11.2 6.2 8H9.5C12.6 11.2 14.2 13.9 14.2 16.2Z"
        fill={isOutlined ? 'transparent' : color}
      />
      <path
        d="M7.8 9.1001C7.6 9.1001 7.39999 9.30009 7.39999 9.50009V10.6001C7.19999 10.7001 7 10.8001 6.8 10.9001C6.2 11.3001 6 11.9001 6 12.6001C6.1 13.4001 6.7 14.1001 7.6 14.5001C7.8 14.6001 8 14.7001 8.2 14.8001C8.6 15.0001 8.8 15.1001 9 15.4001C9.2 15.9001 9.19999 16.5001 8.89999 16.8001C8.29999 17.3001 7.7 17.2001 7.3 17.0001C6.8 16.8001 6.6 16.3001 6.6 15.9001C6.6 15.7001 6.4 15.5001 6.2 15.4001C6 15.4001 5.8 15.6001 5.7 15.8001C5.6 16.6001 6.1 17.4001 6.8 17.8001C7 17.9001 7.19999 18.0001 7.39999 18.0001V19.0001C7.39999 19.2001 7.6 19.4001 7.8 19.4001C8 19.4001 8.2 19.2001 8.2 19.0001V18.0001C8.6 17.9001 9 17.7001 9.3 17.5001C10 17.0001 10 15.8001 9.7 15.1001C9.4 14.4001 8.9 14.2001 8.5 14.0001C8.3 13.9001 8.2 13.9001 8 13.8001C7.4 13.5001 6.99999 13.0001 6.89999 12.5001C6.89999 12.2001 7 11.9001 7.3 11.6001C7.6 11.4001 7.9 11.4001 8.1 11.4001C8.5 11.5001 9 11.8001 9.2 12.2001C9.3 12.4001 9.6 12.5001 9.8 12.3001C10 12.2001 10.1 11.9001 9.89999 11.7001C9.49999 11.1001 8.9 10.6001 8.2 10.5001V9.50009C8.2 9.30009 8 9.1001 7.8 9.1001Z"
        fill="white"
      />
    </svg>
  </Box>
)
