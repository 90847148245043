import styled from '@emotion/styled'

import { Color } from '../../enums/color'

export const AlertsGroupList = styled.ul`
  display: flex;
  flex-direction: column;
`

export const AlertsGroupItem = styled.li`
  margin-bottom: 1px;
  box-shadow: 0px 1px 0px 0px ${Color.Gray20};
`
