import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { ObjectFit } from './types/ObjectFit'

export const ImageWrapper = styled.figure`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`

interface ImageProps {
  objectFit: ObjectFit
}

export const Image = styled.img<ImageProps>`
  width: 100%;
  height: 100%;
  object-fit: ${({ objectFit }) => objectFit};
`

export const ImageMagnifierWrapper = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  will-change: opacity;
  ${getDefaultTransition('opacity')};

  &:hover {
    opacity: 1;
    cursor: none;
  }
`

export const ImageZoomedWrapper = styled.figure`
  position: absolute;
  top: 0;
  left: 0;
  background: ${Color.White};
`

interface ImageZoomedProps {
  objectFit: ObjectFit
}

export const ImageZoomed = styled.img<ImageZoomedProps>`
  width: 100%;
  height: 100%;
  object-fit: ${({ objectFit }) => objectFit};
  will-change: transform;
`
