import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconBackAndForward = ({
  color = 'inherit',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-back-and-forward')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <polygon fill={coloring(color)} points="22,5 12,5 12,2 2,8 12,14 12,11 22,11 "></polygon>
      <path
        fill={coloring(bg)}
        d="M12,2v3h10v6H12v3L2,8L12,2 M12,0c-0.356,0-0.713,0.095-1.029,0.285l-10,6C0.369,6.646,0,7.297,0,8 s0.369,1.354,0.971,1.715l10,6C11.287,15.905,11.644,16,12,16c0.34,0,0.679-0.086,0.985-0.26C13.612,15.385,14,14.721,14,14v-1h8 c1.105,0,2-0.895,2-2V5c0-1.105-0.895-2-2-2h-8V2c0-0.721-0.388-1.385-1.015-1.74C12.68,0.086,12.34,0,12,0L12,0z"
      ></path>
      <polygon fill={coloring(color)} points="30,24 20,18 20,21 10,21 10,27 20,27 20,30 "></polygon>
      <path
        fill={coloring(bg)}
        d="M20,18l10,6l-10,6v-3H10v-6h10V18 M20,16c-0.34,0-0.679,0.086-0.985,0.26C18.388,16.615,18,17.279,18,18v1 h-8c-1.105,0-2,0.895-2,2v6c0,1.105,0.895,2,2,2h8v1c0,0.721,0.388,1.385,1.015,1.74C19.32,31.914,19.66,32,20,32 c0.356,0,0.713-0.095,1.029-0.285l10-6C31.631,25.354,32,24.703,32,24s-0.369-1.354-0.971-1.715l-10-6 C20.713,16.095,20.356,16,20,16L20,16z"
      ></path>
    </svg>
  </Box>
)
