import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconCaretRight = ({
  color = 'inherit',
  color2 = 'inherit',
  width = '20px',
  height = '20px',
  rotate,
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-caret-right')} {...rest}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{
        transition: 'transform 0.3s',
        transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
    >
      <path
        d="M1.158,4.158a.54.54,0,0,1,.764,0L11,13.236l9.075-9.078a.54.54,0,1,1,.764.764L11.767,14a1.08,1.08,0,0,1-1.528,0L1.158,4.922A.54.54,0,0,1,1.158,4.158Z"
        transform="translate(2 21) rotate(-90)"
        fill={coloring(color)}
        stroke={coloring(color2)}
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  </Box>
)
