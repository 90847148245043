import { TableSortedColumnState } from '../types/column'

export const getCorrectColumnId = (columnId: string): TableSortedColumnState => {
  const isSortedDesc = columnId.startsWith('-') || !columnId

  return {
    id: isSortedDesc ? columnId.slice(1) : columnId,
    isSortedDesc,
  }
}
