import { emptyViewVariants } from '@components'
import { ModuleLayout } from '@design-system'

import mapValues from 'lodash/mapValues'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'

import {
  ContactBalanceEmpty,
  ContactBalanceTable,
  ContactBalanceTableActions,
  ContactBalanceTableRow,
  ContactBalanceType,
  useContactBalanceListFilters,
} from '@features/contactBalance'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { BillStatus } from '@views/bills'
import { BillsSortProperty } from '@views/bills/query-api'
import { BillsListFilters } from '@views/bills/routes/BillsList'

import { EmberRoute } from '../../../../enums/emberRoute'
import { PeriodMode } from '../../../../enums/periodMode'
import { ReactRoute } from '../../../../enums/reactRoute'
import { SortDirection } from '../../../../enums/sortDirection'
import { useEmberRouteUrl } from '../../../../hooks/useEmberRouteUrl'
import { getOrganizationViewPath } from '../../../../utils/getOrganizationViewPath'
import { useCreditors } from './hooks/useCreditors'

export const CreditorsList = (): ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()
  const { organization } = useUserOrganization()
  const { creditors, isLoading, pagination } = useCreditors()
  const [{ searchQuery }] = useContactBalanceListFilters()
  const { url: billsNewUrl } = useEmberRouteUrl(EmberRoute.BillsNew)

  const showEmptyView = useMemo(
    () => !isLoading && !searchQuery && creditors.length === 0,
    [isLoading, searchQuery, creditors.length],
  )

  const onTableRowClick = useCallback(
    (row: Row<ContactBalanceTableRow>) => {
      const { id: contactId, contactName } = row.original

      const invoicesQueryParams: BillsListFilters = {
        contactId,
        contactName,
        period: PeriodMode.All,
        sortProperty: BillsSortProperty.DueDate,
        sortDirection: SortDirection.Asc,
        status: BillStatus.Unpaid,
      }

      if (organization) {
        history.push(
          `${getOrganizationViewPath(organization.url, ReactRoute.Bills, mapValues(invoicesQueryParams, String))}`,
        )
      }
    },
    [history, organization],
  )

  return (
    <ModuleLayout title={t('creditors')}>
      <ContactBalanceTableActions isLoading={isLoading} title={t('creditors')} total={pagination?.total} />
      {showEmptyView && billsNewUrl ? (
        <ContactBalanceEmpty {...emptyViewVariants.creditors} path={billsNewUrl} />
      ) : (
        <ContactBalanceTable
          data={creditors}
          isLoading={isLoading || !billsNewUrl}
          contactBalanceType={ContactBalanceType.Creditor}
          onRowClick={onTableRowClick}
        />
      )}
    </ModuleLayout>
  )
}
