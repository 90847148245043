import React, { ReactElement } from 'react'

import { ReceiptsSorting } from '../../../ReceiptsSorting'
import * as Styled from './styles'

export const ReceiptsRightActions = (): ReactElement => (
  <Styled.ReceiptsRightActionsWrapper>
    <ReceiptsSorting />
  </Styled.ReceiptsRightActionsWrapper>
)
