import { SkeletonText as SkeletonTextComponent } from '@design-system'

import styled from '@emotion/styled'

export const TitleWrapper = styled.div`
  align-self: flex-start;
`

export const SkeletonText = styled(SkeletonTextComponent)`
  margin-left: auto;
`
SkeletonText.defaultProps = {
  width: 100,
}
