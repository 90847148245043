import { Badge, useModal } from '@design-system'

import qs from 'qs'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAgerasFinancing } from '@features/financingBanner/hooks/useAgerasFinancing'
import { useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import { useAgerasFinanceCustomer } from '@views/financing/AgerasFinanceLending'
import { ApprovalState } from '@views/financing/AgerasFinanceLending/enums/approvalState'

import { ReactComponent as InvoiceFinancing } from '../../../assets/images/integrations/invoice-financing.svg'
import { ModalId } from '../../../enums/modalId'
import { IntegrationId } from '../enums/integrationId'
import { IntegrationItem } from '../types/integrationItem'
import { IntegrationStatusBadeProps } from '../types/integrationStatusBadeProps'

export const useInvoiceFinancingIntegration = (): IntegrationItem => {
  const { t } = useTranslation()
  const { open: openApprovalModal } = useModal(ModalId.FinancingApplicationModal)
  const { cancelDismissal, dismiss, isLoading: isAgerasFinancingLoading } = useAgerasFinancing()
  const { organizationSettings, isLoading: isOrganizationSettingsLoading } = useUserOrganizationSettings()

  const {
    customer: invoiceFinancingCustomer,
    token,
    isCustomerLoading: isInvoiceFinancingLoading,
  } = useAgerasFinanceCustomer()

  const isSwitchLoading = isOrganizationSettingsLoading || isAgerasFinancingLoading

  const isSwitchChecked = !organizationSettings?.financingDismissedDate

  const setInvoiceFinancingDismissedDate = useCallback(() => {
    if (isSwitchChecked) {
      dismiss(true)
      return
    }

    cancelDismissal()
  }, [isSwitchChecked, cancelDismissal, dismiss])

  const approvalState = useMemo(() => {
    if (isInvoiceFinancingLoading) {
      return null
    }

    return invoiceFinancingCustomer?.approval_state
  }, [invoiceFinancingCustomer?.approval_state, isInvoiceFinancingLoading])

  const handleDraftCTAClick = useCallback(() => {
    const params = qs.stringify({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      redirect_uri: `${window.location.origin}${window.location.pathname}?application_sent=1`,
    })

    const draftCTALink = `${window.ENV.AGERAS_FINANCE_FRONTEND_URL}/invoice-financing?${params}#token=${token}`

    window.open(draftCTALink, '_blank')
  }, [token])

  const buttonText = useMemo(() => {
    if (!approvalState) {
      return t('integrations.card.invoice_financing.button.activate')
    } else if (approvalState === ApprovalState.Draft) {
      return t('integrations.card.invoice_financing.button.draft')
    } else if (approvalState === ApprovalState.Pending) {
      return t('integrations.card.invoice_financing.button.pending')
    } else if (approvalState === ApprovalState.Approved) {
      return t('integrations.card.invoice_financing.button.approved')
    } else if (approvalState === ApprovalState.Rejected) {
      return t('integrations.card.invoice_financing.button.rejected')
    } else if (approvalState === ApprovalState.CustomerRejected) {
      return t('integrations.card.invoice_financing.button.customer_rejected')
    }
  }, [approvalState, t])

  const buttonHandler = useCallback(() => {
    if (isInvoiceFinancingLoading) {
      return
    }

    if (!approvalState || approvalState === ApprovalState.CustomerRejected) {
      return openApprovalModal()
    } else if (approvalState === ApprovalState.Draft) {
      return handleDraftCTAClick()
    }
  }, [approvalState, isInvoiceFinancingLoading, openApprovalModal, handleDraftCTAClick])

  const statusLabelNode = useMemo(() => {
    const statusToBadgePropsMap: Record<ApprovalState, IntegrationStatusBadeProps> = {
      [ApprovalState.Draft]: {
        variant: 'warning',
        tKey: 'integrations.card.invoice_financing.status.draft',
      },
      [ApprovalState.Pending]: {
        variant: 'warning',
        tKey: 'integrations.card.invoice_financing.status.pending',
      },
      [ApprovalState.Approved]: {
        variant: 'success',
        tKey: 'integrations.card.invoice_financing.status.approved',
      },
      [ApprovalState.Rejected]: {
        variant: 'error',
        tKey: 'integrations.card.invoice_financing.status.rejected',
      },
      [ApprovalState.CustomerRejected]: {
        variant: 'error',
        tKey: 'integrations.card.invoice_financing.status.customer_rejected',
      },
    }

    const badgeProps = approvalState ? statusToBadgePropsMap[approvalState] : null
    if (!badgeProps) {
      return null
    }

    return <Badge variant={badgeProps.variant}>{t(badgeProps.tKey)}</Badge>
  }, [approvalState, t])

  return {
    id: IntegrationId.InvoiceFinancing,
    name: t('integrations.card.invoice_financing.title'),
    description: t('integrations.card.invoice_financing.body'),
    readMoreLink: 'https://www.billy.dk/support/article/krav-til-straksbetaling',
    imageNode: <InvoiceFinancing />,
    isHiddenLoading: false,
    isHidden: false,
    buttonHandler,
    buttonText,
    statusLabelNode,
    isStatusLoading: isInvoiceFinancingLoading,
    isSwitchLoading,
    isSwitchChecked,
    switchHandler: setInvoiceFinancingDismissedDate,
    switchText: t('integrations.card.invoice_financing.switch.label'),
  }
}
