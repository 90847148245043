import { RichTextStyleSettings } from '@design-system'

export const descriptionFieldStyleSettings: RichTextStyleSettings = {
  h1: {
    isEnabled: false,
  },
  h3: {
    isEnabled: false,
  },
  italic: {
    isEnabled: false,
  },
  underline: {
    isEnabled: false,
  },
  ol: {
    isEnabled: false,
  },
}
