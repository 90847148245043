import { getMenuItem, MenuItemExtended } from './getMenuItem'

export const organizationMenuItems: MenuItemExtended[] = [
  getMenuItem('dashboard'),
  getMenuItem('thingsToDo'),
  getMenuItem('sales', ['invoices', 'quotes', 'recurringInvoices', 'products', 'debtors']),
  getMenuItem('expenses', ['bills', 'creditors', 'salary']),
  getMenuItem('vouchers', ['vouchersList', 'salary']),
  getMenuItem('uploads'),
  getMenuItem('bank', ['bankReconciliation', 'loans']),
  getMenuItem('bankReact'),
  getMenuItem('bookkeeping', ['daybook', 'transactions']),
  getMenuItem('accounting', ['reports', 'vatSettlements', 'vatSettlementsSkat', 'annualReports', 'exports']),
  getMenuItem('contacts'),
  getMenuItem('services', ['integrations', 'findAccountant']),
]
