import { RichTextStyle } from '../types/richTextStyle'
import { RichTextStyleSettings } from '../types/richTextStyleSettings'

export const applyStyleSettings = <T extends { name: RichTextStyle }>(
  defaultStyles: T[],
  styleSettings?: RichTextStyleSettings,
) =>
  styleSettings
    ? defaultStyles.filter(({ name }) => {
        if (styleSettings[name]?.isEnabled === false) {
          return false
        }

        return true
      })
    : defaultStyles
