import { ReactElement, useMemo } from 'react'

import { useOrganizationNotificationSettings } from '../../context/organizationNotificationSettings'
import { NotificationSettingsTableRows } from '../../types/notificationSettingsTypes'
import { NotificationSettingsTableRow } from './elements/NotificationSettingsTableRow'
import * as Styled from './styles'
import { convertSettings } from './utils/convertSettings'

export const NotificationSettingsTable = (): ReactElement => {
  const { notificationSettings, isMuted } = useOrganizationNotificationSettings()

  const settingsWithBooleans = useMemo(() => convertSettings(notificationSettings), [notificationSettings])

  return (
    <Styled.NotificationsTable>
      {settingsWithBooleans.map((setting: NotificationSettingsTableRows) => {
        return (
          <NotificationSettingsTableRow
            key={setting.notificationSettingsKey}
            notificationSettingsKey={setting.notificationSettingsKey}
            value={setting.value}
          />
        )
      })}
      <NotificationSettingsTableRow notificationSettingsKey={'muted'} value={!!isMuted} />
    </Styled.NotificationsTable>
  )
}
