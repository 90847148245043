import { notify } from '@design-system'

import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInterval } from 'react-use'

import { CustomEvent } from '../../../../../enums/customEvent'
import { NotificationKeys } from '../../../../../enums/notificationKeys'

const CALLBACK_LISTENER_INTERVAL = 200

interface UseStripePopupIntervalProps {
  popupWindow: Window | null
}

interface UseStripePopupIntervalReturn {
  startListening: () => void
  stopListening: () => void
}

export const useStripePopupInterval = ({ popupWindow }: UseStripePopupIntervalProps): UseStripePopupIntervalReturn => {
  const [isListening, setIsListening] = useState(false)
  const { t } = useTranslation()

  const startListening = useCallback(() => {
    setIsListening(true)
  }, [])

  const stopListening = useCallback(() => {
    setIsListening(false)
  }, [])

  let leftStripeDomain = false

  useInterval(
    () => {
      try {
        if (popupWindow?.document.domain === document.domain) {
          if (leftStripeDomain && popupWindow.document.readyState === 'complete') {
            // popupWindow returned to our domain
            popupWindow.postMessage(
              {
                message: CustomEvent.StripeIntegrationRequestResult,
              },
              '*',
            )
          }
        } else {
          // this code should never be reached, as the x-site
          // security check throws but just in case
          leftStripeDomain = true
        }
      } catch (event) {
        // navigated away or closed
        if (popupWindow?.closed) {
          stopListening()

          notify({
            id: NotificationKeys.SubscriptionStripe,
            message: t('integrations.card.stripe.notification.failed'),
            variant: 'error',
          })

          return
        }
        // navigated to another domain
        leftStripeDomain = true
      }
    },
    isListening ? CALLBACK_LISTENER_INTERVAL : null,
  )

  return {
    startListening,
    stopListening,
  }
}
