import { amountToDisplayValue, FormItem, FormItemsGroup, Module, Spacing, Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface BillSummaryModuleProps {
  children?: ReactNode
  currencyId?: string
  exclVatAmount: number
  inclVatAmount: number
  isLoading?: boolean
  vatAmount: number
}

export const BillSummaryModule = ({
  children,
  currencyId,
  exclVatAmount,
  inclVatAmount,
  isLoading,
  vatAmount,
}: BillSummaryModuleProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Module>
      <FormItemsGroup itemsInRow={4} spacing={Spacing.L}>
        <Styled.TitleWrapper>
          <Text weight="medium">
            {t('bill.edit.form.total')} {currencyId ? `(${currencyId})` : ''}
          </Text>
        </Styled.TitleWrapper>
        <FormItem label={t('bill.edit.form.excl_vat_amount')} labelAlignment="right">
          {isLoading ? (
            <Styled.SkeletonText variant="h3" />
          ) : (
            <Text alignment="right" variant="large">
              {amountToDisplayValue(exclVatAmount, false, currencyId)}
            </Text>
          )}
        </FormItem>
        <FormItem label={t('bill.edit.form.vat_amount')} labelAlignment="right">
          {isLoading ? (
            <Styled.SkeletonText variant="h3" />
          ) : (
            <Text alignment="right" variant="large">
              {amountToDisplayValue(vatAmount, false, currencyId)}
            </Text>
          )}
        </FormItem>
        <FormItem label={t('bill.edit.form.incl_vat_amount')} labelAlignment="right">
          {isLoading ? (
            <Styled.SkeletonText variant="h3" />
          ) : (
            <Text alignment="right" variant="h3">
              {amountToDisplayValue(inclVatAmount, false, currencyId)}
            </Text>
          )}
        </FormItem>
      </FormItemsGroup>

      {children}
    </Module>
  )
}
