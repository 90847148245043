import { Checkbox } from '@components-deprecated'
import { Spacing } from '@design-system'

import { css } from '@emotion/core'
import without from 'lodash/without'
import React from 'react'
import { Box } from 'rebass'

import { reactClass } from '../../../utils'
import { ExtendedBankLineMatch, ReceiptState } from '../types'
import { calcDifference } from '../utils'
import { DroppableBankLines } from './DroppableBankLines'
import { DroppableMatchedTransactions } from './DroppableMatchedTransactions'
import { MatchListRowContext } from './MatchList'
import { MatchListRowActionOverlay as ActionOverlay } from './MatchListRowActionOverlay'

type MatchListRowProps = {
  context: MatchListRowContext
  match: ExtendedBankLineMatch
}

export const MatchListRow = ({
  match: { bankLines, id, isApproved, matchedBillyTransactions },
  context: { bulkSelectionMode, isDeleteMode, onChangeSelectedIds, selectedIds },
}: MatchListRowProps) => {
  const receiptState = bankLines?.[0]?.receiptState // I took only first `bankLine` because all bank lines get same `receiptState`
  const difference = calcDifference({ bankLines, matchedBillyTransactions })
  const createUpdateSelectionFunction = (id: string) => {
    return () => {
      onChangeSelectedIds(selectedIds.includes(id) ? without(selectedIds, id) : selectedIds.concat([id]))
    }
  }
  const isRowDisabled = receiptState && receiptState === ReceiptState.MissingReceipt && !isDeleteMode

  return (
    <Box
      className={reactClass('match-list-row')}
      id={id}
      css={css`
        position: relative;
        display: grid;
        padding-top: 10px;
        grid-template-columns: 280fr 230fr;
        column-gap: 5px;
        &:first-of-type {
          padding-top: 0;
        }
        &:not(:first-of-type) > .${reactClass('action-overlay')} {
          top: 11px;
        }
        p {
          opacity: ${isApproved ? '0.5' : '1.0'};
        }
      `}
    >
      <ActionOverlay className={reactClass('remove-from-match')} forceEnabled={bulkSelectionMode}>
        <Checkbox
          mt={Spacing.XXXS}
          ml={'10px'}
          mb={'2.5px'}
          mr={'37px'}
          disabled={!!isRowDisabled}
          checked={selectedIds.includes(id)}
          onChange={createUpdateSelectionFunction(id)}
        />
      </ActionOverlay>
      <DroppableBankLines collection={bankLines} id={`bankline:${id}`} isApproved={isApproved} type="bankline" />
      <DroppableMatchedTransactions
        collection={matchedBillyTransactions}
        isApproved={isApproved}
        bankLines={bankLines}
        id={`billytransaction:${id}`}
        type="billytransaction"
        difference={difference}
      />
    </Box>
  )
}
