import React, { ChangeEvent, createContext, ReactElement, ReactNode, useContext, useMemo } from 'react'

interface ContextState {
  name?: string
  disabled?: boolean
  value?: string[]
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const defaultValue: ContextState = {}

const CheckboxGroup = createContext<ContextState>(defaultValue)

interface CheckboxGroupProps extends ContextState {
  children?: ReactNode
}

export const CheckboxGroupProvider = ({
  children,
  name,
  disabled,
  value: currentValue,
  onChange,
}: CheckboxGroupProps): ReactElement => {
  const value = useMemo(
    () => ({
      name,
      disabled,
      value: currentValue,
      onChange,
    }),
    [name, disabled, currentValue, onChange],
  )

  return <CheckboxGroup.Provider value={value}>{children}</CheckboxGroup.Provider>
}

export const useCheckboxGroup = () => useContext(CheckboxGroup)
