import { Translate } from '@components'
import { InternalAccessor, NavItem, Table, TableColumn, TableHoverActions, TableStaticActions } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TableIds } from '../../../../../../../../../../enums/tableIds'
import { useOrganizationOwners } from '../../../../../../contexts/organizationOwnersContext'
import { useFetchOrganizationOwnersData } from '../../../../../../hooks/useFetchOrganizationOwnersData'
import { useOrganizationOwnersTableData } from '../../../../../../hooks/useOrganizationOwnersTableData'
import { OrganizationOwnerStaticActionType } from '../../../../enums/organizationOwnersStaticActionType'
import { OrganizationOwnersTableRow } from '../../../../types/organizationOwnersTableRow'

interface OwnersTableProps {
  isProprietorship?: boolean
}

export const OrganizationOwnersTable = ({ isProprietorship }: OwnersTableProps): ReactElement => {
  const { t } = useTranslation()
  const { openEditOwnerModal, openDeleteOwnerModal } = useOrganizationOwners()
  const { isLoading, organizationOwnersData } = useFetchOrganizationOwnersData()
  const tableData = useOrganizationOwnersTableData(organizationOwnersData)

  const handleEditOwnerButtonClick = useCallback(
    (organizationOwnerId: string) => {
      openEditOwnerModal?.(organizationOwnerId)
    },
    [openEditOwnerModal],
  )

  const handleDeleteOwnerButtonClick = useCallback(
    (organizationOwnerId: string) => {
      openDeleteOwnerModal?.(organizationOwnerId)
    },
    [openDeleteOwnerModal],
  )

  const staticActionsItems: NavItem<OrganizationOwnerStaticActionType>[] = useMemo(
    () => [
      {
        children: t('settings.organization.organization_info.owners_table.actions.delete'),
        iconLeft: 'trash',
        id: 'delete',
        value: OrganizationOwnerStaticActionType.Delete,
      },
    ],
    [t],
  )

  const proprietorsColumns: TableColumn<OrganizationOwnersTableRow>[] = useMemo(
    () => [
      {
        accessor: 'name',
        Header: <Translate value="settings.organization.organization_info.owners_table.header.name" />,
        size: 's',
      },
      {
        accessor: 'address',
        Header: <Translate value="settings.organization.organization_info.owners_table.header.address" />,
      },
      {
        accessor: InternalAccessor.HoverActions,
        Cell: ({ row, data }) => (
          <TableHoverActions>
            <TableHoverActions.Item
              icon="paperWithPencil"
              label={t('settings.organization.organization_info.owners_table.actions.edit')}
              onClick={() => {
                const { id } = data[row.id] as OrganizationOwnersTableRow
                handleEditOwnerButtonClick(id)
              }}
            />
          </TableHoverActions>
        ),
        Header: '',
      },
      {
        accessor: InternalAccessor.StaticActions,
        Cell: ({ row, data }) => (
          <TableStaticActions
            items={staticActionsItems}
            onSelect={() => {
              const { id } = data[row.id] as OrganizationOwnersTableRow
              handleDeleteOwnerButtonClick(id)
            }}
          />
        ),
        Header: '',
      },
    ],
    [handleDeleteOwnerButtonClick, handleEditOwnerButtonClick, staticActionsItems, t],
  )

  const ownersColumns: TableColumn<OrganizationOwnersTableRow>[] = useMemo(
    () => [
      ...proprietorsColumns,
      {
        accessor: 'nominalOwnershipPercent',
        Header: <Translate value="settings.organization.organization_info.owners_table.header.ownership_share" />,
        size: 's',
      },
    ],
    [proprietorsColumns],
  )

  return (
    <Table
      columns={isProprietorship ? proprietorsColumns : ownersColumns}
      data={tableData}
      id={TableIds.OrganizationOwners}
      isLoading={isLoading}
      skeletonItemsPerPage={2}
      withColumnsFiltering={false}
    />
  )
}
