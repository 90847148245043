import { Flex, SkeletonBox, Space } from '@design-system'

import * as Styled from './styles'

export const BillsListItemSkeleton = () => (
  <Styled.BillsListItemSkeletonWrapper>
    <SkeletonBox width={16} height={16} />
    <Space size="m" horizontal />
    <Styled.BillsListItemContentSkeletonWrapper>
      <Flex justifyContent="space-between">
        <Styled.DetailsSkeletonWrapper>
          <SkeletonBox width={80} height={20} />
          <SkeletonBox width={20} height={20} />
          <SkeletonBox width={150} height={20} />
        </Styled.DetailsSkeletonWrapper>
        <SkeletonBox width={90} height={20} />
      </Flex>
    </Styled.BillsListItemContentSkeletonWrapper>
  </Styled.BillsListItemSkeletonWrapper>
)
