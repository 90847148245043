import { Placement } from '@popperjs/core'
import React, {
  cloneElement,
  forwardRef,
  KeyboardEvent,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import { useKey, usePrevious } from 'react-use'

import { KeyboardKey } from '../../../enums/keyboardKey'
import { useMousePress } from '../../../hooks/useMousePress'
import { PORTAL_CLASS_NAME } from '../../constants/portalClassName'
import { useComponentToggle } from '../../hooks/useComponentToggle'
import { ImperativeRef } from '../../types/imperativeRef'
import { isElementPartOfOtherElements } from '../../utils/isElementPartOfOtherElements'
import { DROPDOWN_CLASS_NAME } from './constants/dropdownClassName'
import * as Styled from './styles'
import { DropdownMaxHeight } from './types/dropdownMaxHeight'
import { DropdownSize } from './types/dropdownSize'
import { getDropdownMaxHeight } from './utils/getDropdownMaxHeight'
import { getPopperModifiers } from './utils/getPopperModifiers'

/** for some components that have more than one ref we use the approach to return the root element in 'wrapper' object (for example react-app/src/design-system/components/Input/Input.tsx) */
type TriggerRef<T = HTMLElement> = T & ImperativeRef

export interface DropdownRef<T = HTMLElement> {
  trigger: TriggerRef<T> | null
  dropdown: HTMLDivElement
}

export interface DropdownProps {
  /** toggle dropdown when click on trigger element is detected */
  autoToggle?: boolean
  children: ReactElement
  /** don't close dropdown when click on childrenIds elements  */
  childrenIds?: string[]
  className?: string
  id?: string
  trigger: ReactElement
  /** allows to toggle select from the parent component */
  isOpen?: boolean
  maxHeight?: DropdownMaxHeight | number
  onClick?: () => void
  onClose?: () => void
  onKeyDown?: (event: KeyboardEvent<any>) => void
  onMouseDown?: () => void
  onOpen?: () => void
  placement?: Placement
  size?: DropdownSize
  width?: number
}

const DropdownForwarded = <T extends HTMLElement>(
  {
    autoToggle = true,
    children,
    childrenIds,
    className,
    id,
    isOpen: isOpenControlled = false,
    maxHeight = 'fullScreen',
    onClick,
    onClose,
    onKeyDown,
    onMouseDown,
    onOpen,
    placement = 'auto',
    size = 'l',
    trigger,
    width,
  }: DropdownProps,
  forwardedRef: Ref<DropdownRef<T>>,
) => {
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null)
  const [dropdownListElement, setDropdownListElement] = useState<HTMLElement | null>(null)
  const dropdownWrapperRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<TriggerRef<T>>(null)

  const fullWidth = size === 'fitTrigger'
  const modifiers = useMemo(() => getPopperModifiers(fullWidth), [fullWidth])

  const { styles, forceUpdate } = usePopper(triggerElement, dropdownListElement, { placement, modifiers })

  const { close, open, toggle, isVisible, isRendered } = useComponentToggle({
    onRenderCallback: forceUpdate, // needed to recalculate position on open (not on component mount)
    isOpen: isOpenControlled,
  })
  const isVisiblePrevious = usePrevious(isVisible)

  useImperativeHandle(forwardedRef, () => ({
    get trigger() {
      return triggerRef.current
    },
    get dropdown() {
      return dropdownWrapperRef.current as HTMLDivElement
    },
  }))

  // Mutations

  useEffect(() => {
    if (!triggerElement) {
      return
    }

    const dropdownMaxHeight = getDropdownMaxHeight(triggerElement, maxHeight)

    if (dropdownWrapperRef.current && dropdownMaxHeight) {
      dropdownWrapperRef.current.style.maxHeight = `${dropdownMaxHeight}px`
    }
  }, [triggerElement, isRendered, maxHeight])

  useEffect(() => {
    const triggerElement = triggerRef.current?.wrapper || triggerRef.current

    if (triggerElement) {
      // prevent wrong calcuations on popper position
      triggerElement.style.transform = 'none'
      setTriggerElement(triggerElement)
    }
  }, [triggerRef])

  useEffect(() => {
    const dropdownListElement = dropdownWrapperRef.current

    if (dropdownListElement) {
      setDropdownListElement(dropdownListElement)
    }
  }, [dropdownWrapperRef])

  useEffect(() => {
    if (!isVisiblePrevious && isVisible) {
      onOpen?.()

      if (typeof triggerRef.current?.focus === 'function') {
        triggerRef.current?.focus()
      }
    } else if (isVisiblePrevious && !isVisible) {
      onClose?.()
    }
    // should only trigger on isVisible flag
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  // Functions

  const closeDropdown = useCallback(() => {
    close()
  }, [close])

  const isElementOutOfTheDropdown = useCallback(
    (element: HTMLElement) => {
      return (
        element &&
        !triggerElement?.contains(element) &&
        !dropdownListElement?.contains(element) &&
        !isElementPartOfOtherElements(element, childrenIds)
      )
    },
    [childrenIds, dropdownListElement, triggerElement],
  )

  const handleMousePress = useCallback(
    (event: MouseEvent) => {
      const elementPressed = event.target as HTMLElement | undefined

      if (elementPressed && isElementOutOfTheDropdown(elementPressed)) {
        closeDropdown()
      }
    },
    [isElementOutOfTheDropdown, closeDropdown],
  )

  const handleEscapePress = useCallback(() => {
    closeDropdown()
  }, [closeDropdown])

  const handleTriggerMouseDown = useCallback(
    (event: MouseEvent) => {
      trigger.props.onMouseDown?.(event)

      if (!autoToggle) {
        return
      }

      toggle()
    },
    [autoToggle, toggle, trigger.props],
  )

  const handleTriggerKeyDown = useCallback(
    (event: KeyboardEvent<any>) => {
      onKeyDown?.(event)
      trigger.props.onKeyDown?.(event)

      if (!autoToggle || isVisible) {
        return
      }

      if (event.key === KeyboardKey.Spacebar || event.key === KeyboardKey.Enter) {
        open()
      }
    },
    [autoToggle, isVisible, onKeyDown, open, trigger.props],
  )

  // Events hooks

  useKey('Escape', handleEscapePress, { target: isVisible ? window : null }, [isVisible, isRendered])
  useMousePress(handleMousePress, [isVisible, isRendered], undefined, isVisible)

  return (
    <>
      {cloneElement(trigger, {
        active: isVisible || trigger.props.active,
        tabIndex: 0,
        ref: triggerRef,
        // it's better to call onMouseDown + onKeyDown (insted of onClick); that's for cases when trigger is scaled down on press event (e.g. Button); in that cases position could be wrongly calculated
        onMouseDown: handleTriggerMouseDown,
        onKeyDown: handleTriggerKeyDown,
      })}

      {ReactDOM.createPortal(
        <Styled.DropdownWrapper
          className={`${DROPDOWN_CLASS_NAME} ${PORTAL_CLASS_NAME} ${className || ''}`}
          id={id}
          onClick={onClick}
          onMouseDown={onMouseDown}
          ref={dropdownWrapperRef}
          size={size}
          style={isRendered ? styles.popper : {}}
          width={width}
        >
          {isRendered ? cloneElement(children, { visible: isVisible }) : null}
        </Styled.DropdownWrapper>,
        document.body,
      )}
    </>
  )
}

export const Dropdown = forwardRef(DropdownForwarded) as <T>(
  props: DropdownProps & { ref?: Ref<DropdownRef<T>> },
) => ReactElement
