import React, { ReactElement, ReactNode } from 'react'

import { ColumnItemAlignment } from '../../types/columnItemAlignment'
import * as Styled from './styles'

interface ColumnItemProps {
  alignment?: ColumnItemAlignment
  children: ReactNode
}

export const ColumnItem = ({ alignment = 'left', children }: ColumnItemProps): ReactElement => (
  <Styled.ColumnItemWrapper alignment={alignment}>{children}</Styled.ColumnItemWrapper>
)
