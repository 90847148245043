import { DateFormatter, ReceiptRejectionButton } from '@components'
import {
  amountToDisplayValue,
  Attachment,
  Button,
  ButtonsGroup,
  FilesPreview,
  getFileThumbnail,
  Modal,
  ModalProps,
  notify,
  PreviewFile,
  Space,
  Text,
  useModal,
} from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { QueryKeys } from '../../../../enums/queryKeys'
import { ReceiptType } from '../../../../enums/receiptType'
import { getImageClassName } from '../../../../utils/getClassName'
import { useUpdateReceipt } from '../../hooks/useUpdateReceipt'
import { ManageUploadButton } from './elements/ManageUploadButton'
import { ReceiptInfo } from './elements/ReceiptInfo'
import * as Styled from './styles'

interface ReceiptModalProps extends ModalProps {
  receipt: Attachment
  title: string
}

export const ReceiptModal = ({ id: modalId, receipt, title }: ReceiptModalProps): ReactElement => {
  const { id, file, supplier, documentDate, amount, type, comment = '' } = receipt
  const [noteValue, setNoteValue] = useState(comment || '')
  const shouldRedirectRef = useRef(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { navigate } = useEmberRouter()

  const onModalOpen = useCallback(() => {
    setNoteValue(comment)
  }, [setNoteValue, comment])
  const { close } = useModal(modalId, { onOpen: onModalOpen })

  const [documentDateParsed] = documentDate ? documentDate.split('T') : []
  const isEDocument = type === ReceiptType.EDocument

  const { update, isLoading: isUpdating } = useUpdateReceipt({
    onSuccess: async ({ attachments }) => {
      await queryClient.invalidateQueries(QueryKeys.ReceiptsList)
      notify({
        id: 'receipt-update-success',
        message: t('receipts.modal.update.success'),
        variant: 'success',
      })

      if (shouldRedirectRef.current) {
        shouldRedirectRef.current = false
        queryClient.setQueryData([QueryKeys.Receipt, id], { attachment: attachments[0] })
        navigate(EmberRoute.BillsNew, { queryParams: { attachmentId: id } })
      } else {
        close()
      }
    },
    onError: (error) => {
      shouldRedirectRef.current = false
      console.error(error)

      notify({
        id: 'receipt-update-error',
        message: error?.message || t('receipts.modal.update.error'),
        variant: 'error',
      })
    },
  })

  const files: PreviewFile[] = useMemo(
    () => [
      {
        src: file.isPdf ? file.downloadUrl : getFileThumbnail(file, 'medium'),
        srcZoom: file.isPdf ? file.downloadUrl : getFileThumbnail(file, 'large'),
        srcDownload: file.downloadUrl,
      },
    ],
    [file],
  )
  const isDirty = comment !== noteValue

  const handleNoteChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setNoteValue(event.target.value)
    },
    [setNoteValue],
  )

  const handleSaveButtonClick = useCallback(() => {
    update({ id, comment: noteValue })
  }, [id, noteValue, update])

  const handleManageUploadButtonClick = useCallback(() => {
    shouldRedirectRef.current = true
    update({ id, comment: noteValue })
  }, [update, id, noteValue])

  const handleEInvoiceRejection = useCallback(() => {
    close()
  }, [close])

  return (
    <Modal id={modalId} autofocusOnInput>
      <Modal.Header title={title} />
      <Modal.Body>
        <Styled.ContentWrapper>
          <Styled.LeftSide>
            <FilesPreview
              className={getImageClassName()}
              files={files}
              fitToHeight
              fitToWidth
              withDownloadButton
              zoomable
            />
          </Styled.LeftSide>
          <Styled.RightSide>
            <ReceiptInfo title={t('receipts.modal.supplier_label')}>
              <Text>{supplier || '-'}</Text>
            </ReceiptInfo>

            <ReceiptInfo title={t('receipts.modal.upload_date_label')}>
              <Text>{documentDateParsed ? <DateFormatter value={documentDateParsed} /> : '-'}</Text>
            </ReceiptInfo>

            <ReceiptInfo title={t('receipts.modal.price_label')}>
              <Text>{amount ? amountToDisplayValue(amount) : '-'}</Text>
            </ReceiptInfo>

            <ReceiptInfo title={t('receipts.modal.notes_label')} fitToHeight>
              <Styled.NotesTextarea
                placeholder={t('receipts.modal.notes_placeholder')}
                resizable={false}
                onChange={handleNoteChange}
                value={noteValue}
              />
            </ReceiptInfo>

            <Styled.Footer>
              {/* @todo show bill id when it's handled in ReceiptsList (https://agerasgroup.atlassian.net/browse/SHARK-1311) */}
              <ManageUploadButton onClick={handleManageUploadButtonClick} />
              {isEDocument && (
                <>
                  <Space size="s" />
                  <ReceiptRejectionButton attachmentId={receipt.id} size="m" onSuccess={handleEInvoiceRejection} />
                </>
              )}
            </Styled.Footer>
          </Styled.RightSide>
        </Styled.ContentWrapper>
      </Modal.Body>

      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={close}>
            {t('cancel')}
          </Button>
          <Button onClick={handleSaveButtonClick} loading={isUpdating} disabled={!isDirty}>
            {t('save')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
