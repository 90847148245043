import { Text } from '@design-system'

import { ReactElement } from 'react'

import * as Styled from './styles'

interface AsideInfoItemProps {
  title: string
  children: string | ReactElement
}

export const AsideInfoItem = ({ title, children }: AsideInfoItemProps): ReactElement => {
  return (
    <Styled.AsideInfoItemWrapper>
      <Text variant="small" weight="bold">
        {title}
      </Text>
      {children}
    </Styled.AsideInfoItemWrapper>
  )
}
