import styled from '@emotion/styled'

import {
  CardActionsWrapper,
  CardContentWrapper,
  CardDescriptionWrapper,
  CardImageWrapper,
  IntegrationCardWrapper,
} from '../../styles'

export const SkeletonIntegrationCardWrapper = styled(IntegrationCardWrapper)``

export const SkeletonCardContentWrapper = styled(CardContentWrapper)``

export const SkeletonCardImageWrapper = styled(CardImageWrapper)``

export const SkeletonCardDescriptionWrapper = styled(CardDescriptionWrapper)``

export const SkeletonCardActionsWrapper = styled(CardActionsWrapper)``
