import { Color, Text } from '@design-system'

import { differenceInDays } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate } from '../../../../../../utils'
import * as Styled from './styles'

export interface ActivateAlertProps {
  trialEndDate: string
}

export const ActivateAlertComponent = ({ trialEndDate }: ActivateAlertProps) => {
  const { t } = useTranslation()
  const daysOfTrial = differenceInDays(new Date(trialEndDate), new Date()) + 1
  return (
    <Styled.ActivateAlertWrapper>
      <Styled.TitleWrapper>
        <Text variant="h3">{`${t('organization_subscription.plus')} - `}</Text>&nbsp;
        <Text variant="h3" color={Color.Yellow120}>
          {daysOfTrial} {t('organization_subscription.days_of_free_trial_left')}
        </Text>
      </Styled.TitleWrapper>

      <Text variant="small" colorVariant="secondary">
        {t('organization_subscription.trial_info', {
          date: formatDate(trialEndDate, 'MMM. dd yyyy'),
        })}
      </Text>
    </Styled.ActivateAlertWrapper>
  )
}
