import React, { memo, ReactElement } from 'react'

import { FileThumbnail } from '@modules-deprecated/inbox/elements/common/FileThumbnail'

import { FileEmptyThumbnail, FilesPreview } from '../../../components-deprecated/FilesPreview'
import { getPathFromUrl } from '../../../utils/getPathFromUrl'
import * as Styled from './styles'

interface ThumbnailFormatterProps {
  src: string
  alt: string
}

export const ThumbnailFormatter = memo(({ src, alt }: ThumbnailFormatterProps): ReactElement => {
  return (
    <Styled.ThumbnailFormatterWrapper>
      <FileThumbnail>
        {src ? (
          <FilesPreview
            fitToHeight
            height="100%"
            width="100%"
            loading=""
            withNavigation={false}
            files={[{ src, alt }]}
          />
        ) : (
          <FileEmptyThumbnail />
        )}
      </FileThumbnail>
    </Styled.ThumbnailFormatterWrapper>
  )
}, arePropsEqual)

function arePropsEqual(
  prevProps: Readonly<ThumbnailFormatterProps>,
  nextProps: Readonly<ThumbnailFormatterProps>,
): boolean {
  const prevSrcPath = getPathFromUrl(prevProps.src)
  const nextSrcPath = getPathFromUrl(nextProps.src)

  return prevSrcPath === nextSrcPath
}
