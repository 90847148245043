import { useFetchIncomeStatementsReport } from '../../../hooks/useFetchIncomeStatementsReport'
import { useProfitAndLossFilters } from './useProfitAndLossFilters'

export const useProfitAndLoss = () => {
  const [options] = useProfitAndLossFilters()
  const { report, isLoading, isIdle } = useFetchIncomeStatementsReport(options)

  return {
    report,
    isLoading,
    isIdle,
  }
}
