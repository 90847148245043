import { ButtonDropdownMultiple, NavItem, Text } from '@design-system'

import isEqual from 'lodash/isEqual'
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { accountantTypes } from '../../constants/accountantTypes'
import { companies } from '../../constants/companies'
import { services } from '../../constants/services'
import { AccountantType } from '../../types/accountantType'
import { Company } from '../../types/company'
import { Service } from '../../types/service'
import { getAccountingInfoItems } from '../../utils/getAccountingInfoItems'
import * as Styled from './styles'

export interface AccountingInfo {
  accountantTypes: AccountantType[]
  services: Service[]
  companies: Company[]
}

interface AccountingInfoSelectProps {
  value?: AccountingInfo
  onChange: (value: AccountingInfo) => void
}

const defaultSelection: AccountingInfo = {
  accountantTypes: [],
  services: [],
  companies: [],
}

export const AccountingInfoSelect = ({ value, onChange }: AccountingInfoSelectProps): ReactElement => {
  const [selection, setSelection] = useState<AccountingInfo>(defaultSelection)
  const { t } = useTranslation()

  const accountantTypesItems: NavItem<AccountantType>[] = useMemo(
    () => getAccountingInfoItems<AccountantType>(accountantTypes, 'accountant_type', t),
    [t],
  )
  const servicesItems: NavItem<Service>[] = useMemo(() => getAccountingInfoItems<Service>(services, 'service', t), [t])
  const companyItems: NavItem<Company>[] = useMemo(() => getAccountingInfoItems<Company>(companies, 'company', t), [t])

  const handleButtonDropdownMultipleSelect = useCallback(
    (stateKey: keyof AccountingInfo) =>
      (_: ChangeEvent<HTMLInputElement>, values: AccountantType[] | Service[] | Company[]) => {
        const updatedSelection: AccountingInfo = {
          ...selection,
          [stateKey]: values,
        }
        setSelection(updatedSelection)
        onChange(updatedSelection)
      },
    [onChange, selection],
  )

  useEffect(() => {
    if (value && !isEqual(value, selection)) {
      try {
        setSelection(value)
      } catch (error) {
        setSelection(defaultSelection)
      }
    }
  }, [selection, value])

  return (
    <Styled.AccountingInfoSelectWrapper>
      <Text>{t('umbrella.services.accountant_type.label')}</Text>
      <ButtonDropdownMultiple<AccountantType>
        items={accountantTypesItems}
        onSelect={handleButtonDropdownMultipleSelect('accountantTypes')}
        placement="bottom-start"
        selectAllText={t('umbrella.services.all')}
        selectedValues={selection.accountantTypes}
        withSelectAll
      />
      <Text>{t('umbrella.services.service.label')}</Text>
      <ButtonDropdownMultiple<Service>
        items={servicesItems}
        onSelect={handleButtonDropdownMultipleSelect('services')}
        placement="bottom-start"
        selectAllText={t('umbrella.services.all')}
        selectedValues={selection.services}
        withSelectAll
      />

      <Text>{t('umbrella.services.company.label')}</Text>
      <ButtonDropdownMultiple<Company>
        items={companyItems}
        onSelect={handleButtonDropdownMultipleSelect('companies')}
        placement="bottom-start"
        selectAllText={t('umbrella.services.all')}
        selectedValues={selection.companies}
        withSelectAll
      />
    </Styled.AccountingInfoSelectWrapper>
  )
}
