import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface MissingScreenProps {
  title: ReactNode
  children: ReactNode
}

export const MissingScreen = ({ children, title }: MissingScreenProps): ReactElement => {
  return (
    <Styled.MissingScreenWrapper>
      <Styled.Title variant="h3">{title}</Styled.Title>
      {children}
    </Styled.MissingScreenWrapper>
  )
}
