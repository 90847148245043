import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { WidgetsSection } from '../WidgetsSection'

export const WidgetsList = (): ReactElement => {
  const { t } = useTranslation()
  const { isEditMode, isLoading, visibleWidgets, hiddenWidgets } = useDashboardSettings()

  const isHiddenSectionVisible = isEditMode && !isLoading

  return (
    <>
      <WidgetsSection isEditMode={isEditMode} widgets={visibleWidgets} />
      {isHiddenSectionVisible && (
        <WidgetsSection isDraggable={false} title={t('dashboard.widget.hidden.title')} widgets={hiddenWidgets} />
      )}
    </>
  )
}
