import { ReactElement } from 'react'

import { useInvoiceEditor } from '../../contexts/InvoiceEditorContext'
import { InvoiceEditorView } from '../../enums/invoiceEditorView'
import { InvoiceEditorForm } from './elements/InvoiceEditorForm'
import { InvoicePreview } from './elements/InvoicePreview'

export const InvoiceEditorContent = (): ReactElement => {
  const { view } = useInvoiceEditor()

  const isPreviewSelected = view === InvoiceEditorView.Preview

  if (isPreviewSelected) {
    return <InvoicePreview />
  }

  return <InvoiceEditorForm />
}
