import { Button, Space, Text } from '@design-system'

import React, { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface AddPaymentMethodProps {
  fullWidth?: boolean
  onAddPaymentClick: () => void
}

export const AddPaymentMethod = ({ fullWidth, onAddPaymentClick }: AddPaymentMethodProps): ReactElement => {
  const [isClicked, setIsClicked] = useState(false)
  const { t } = useTranslation()

  const handleClick = useCallback(() => {
    setIsClicked(true)
    onAddPaymentClick()
  }, [onAddPaymentClick])

  return (
    <Styled.AddPaymentMethodWrapper>
      <Text alignment="center" colorVariant="secondary" variant="small">
        {t('billing_summary.no_payment.content')}
      </Text>
      <Space size="l" />
      <Button loading={isClicked} onClick={handleClick} variant="secondary" icon="plusCircle" fullWidth={fullWidth}>
        {t('billing_summary.no_payment.button')}
      </Button>
    </Styled.AddPaymentMethodWrapper>
  )
}
