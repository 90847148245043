import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'
import { MODAL_HORIZONTAL_PADDING } from '../../constants/modalHorizontalPadding'

//  Warning! Don't use SimlpeBar here - it causes flickering in Modal when elements are re-reinitialized
export const ModalBodyWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: ${Spacing.M} ${MODAL_HORIZONTAL_PADDING} ${Spacing.XL};
  flex-direction: column;
  overflow: auto;
`
