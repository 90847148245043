import { InternalAccessor } from '@design-system'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FetchPaymentMethodsResponseData, PaymentMethod } from '../../../../../../../query-api/payment-methods-query'
import { getPaymentMethodDisplayedType } from '../elements/PaymentMethodsTable/utils/getPaymentMethodDisplayedType'
import { PaymentMethodsTableRow } from '../types/paymentMethodsTableRow'

export const usePaymentMethodsTableData = (
  paymentMethods?: FetchPaymentMethodsResponseData,
): PaymentMethodsTableRow[] => {
  const { t } = useTranslation()

  return useMemo(() => {
    const paymentMethodsTableData: PaymentMethod[] = paymentMethods?.data || []

    return paymentMethodsTableData.map(({ id, isDefault, isPending, name, type }) => ({
      id,
      name: { isDefault, isPending, name },
      type: getPaymentMethodDisplayedType(type, t),
      [InternalAccessor.HoverActions]: true,
      [InternalAccessor.StaticActions]: true,
    }))
  }, [paymentMethods?.data, t])
}
