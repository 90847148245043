import Cookies from 'js-cookie'
import Prismic from 'prismic-javascript'

import { ApiContentType } from '../enums/apiContentType'
import { CookieKeys } from '../enums/cookieKeys'

declare global {
  interface Window {
    ENV: any
  }
}

interface ResponseMeta {
  meta: {
    statusCode: number
    success: boolean
    time: number
  }
}

interface ResponseBody<T> {
  [key: string]: T[]
}

export type APIResponse<T> = ResponseBody<T> & ResponseMeta

export const apiUrl = window.ENV ? window.ENV.newApiUrl : process.env.REACT_APP_API_URL

const prismicUrl = process.env.REACT_APP_PRISMIC_URL || 'https://meneto-support.prismic.io/api/v2'

export function getAccessToken(): string | undefined {
  return Cookies.get(CookieKeys.BillyApiAccessToken)
}

export function setAccessToken(token: string) {
  Cookies.set(CookieKeys.BillyApiAccessToken, token)
}

export class APIError extends Error {
  public statusCode: number
  public statusMessage: string
  public body: any
  public validationErrors: any

  public constructor(statusCode: number, statusMessage: string, body: any) {
    super(`${statusCode} - ${statusMessage}`)

    this.name = this.constructor.name
    this.statusCode = statusCode
    this.statusMessage = statusMessage
    this.body = body
    this.message = body?.message || body?.errorMessage
    this.validationErrors = body?.validationErrors
  }
}

export async function getUnAuthRequest(endpoint: string): Promise<any | false> {
  const url = `${apiUrl}${endpoint}`
  const res = await fetch(url)

  try {
    return await res.json()
  } catch (err) {
    console.error(err)
    return false
  }
}

export type RequestDataOptions = {
  returnRawResponse?: boolean
  withAuth?: boolean
  skipContentType?: boolean
  contentType?: string
}

type Options = {
  headers?: ApiHeaders
}

export type ApiHeaders = {
  [key: string]: string | number | boolean | undefined
  'X-Filename'?: string
  'X-File-Size'?: string
  'X-Thumbnail-Names'?: string
  'X-Should-Scan'?: boolean
  'X-OrganizationId'?: string
}

export type ApiOptions = Options

export async function request(
  endpoint: string,
  requestOptions: RequestInit = {},
  options: RequestDataOptions = {},
): Promise<any> {
  const { returnRawResponse, withAuth, skipContentType, contentType } = options

  const contentAndAcceptType = contentType || ApiContentType.ApplicationJSON
  const headers = new Headers({
    Accept: contentAndAcceptType,
    ...requestOptions.headers,
  })

  if (!skipContentType) {
    // This is a solution for the issue with passing FormData data format
    // https://stackoverflow.com/questions/49692745/express-using-multer-error-multipart-boundary-not-found-request-sent-by-pos
    headers.append('Content-Type', contentAndAcceptType)
  }

  if (withAuth) {
    const accessToken = getAccessToken()
    if (!accessToken) {
      throw new Error('No access token')
    }

    headers.append('x-access-token', accessToken)
    headers.append('Authorization', `Bearer ${accessToken}`)
  }

  // @TODO we need to figure out how to add microservices - not just absolute URLs
  const res = await fetch(`${endpoint.startsWith('http') || endpoint.startsWith('https') ? '' : apiUrl}${endpoint}`, {
    mode: 'cors',
    ...requestOptions,
    headers,
  })

  if (!res.ok) {
    let body
    try {
      // We do not read the response directly with json() because the error thrown is not catchable.
      // Moreover, we are only interested in the body if it is a valid JSON object
      body = JSON.parse(await res.text())
    } catch (e) {
      // If we can't read the body, we simply don't pass it
    }

    throw new APIError(res.status, res.statusText, body)
  }

  if (returnRawResponse) {
    return res
  }

  if (res.status === 204) {
    return
  }

  if (res.status === 201) {
    try {
      return await res.json()
    } catch (error) {
      return undefined
    }
  }

  return res.json()
}

export function getRawRequest(endpoint: string, headers = {}, withAuth = true): Promise<Response> {
  return request(endpoint, { headers }, { returnRawResponse: true, withAuth })
}

export function getRequest(endpoint: string, headers = {}, withAuth = true): Promise<any> {
  return request(endpoint, { headers }, { returnRawResponse: false, withAuth })
}

export function postRequest(endpoint: string, data: any, headers = {}, withAuth = true): Promise<any> {
  return request(
    endpoint,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    },
    {
      returnRawResponse: false,
      withAuth,
    },
  )
}

interface PostV2Args<T> {
  endpoint: string
  headers?: Headers
  options?: RequestDataOptions
  payload?: T
}

// TODO: Replace old usages of old `postRequest` and rename new one with the old one name
export function postV2Request<T, K = any>({ endpoint, headers, options = {}, payload }: PostV2Args<T>): Promise<K> {
  return request(
    endpoint,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    },
    {
      returnRawResponse: false,
      withAuth: true,
      ...options,
    },
  )
}

export function postFileRequest(endpoint: string, file: File, options: Options): Promise<any> {
  const defaultHeaders = {
    'X-Filename': encodeURI(file.name),
    'X-File-Size': file.size,
    'X-Thumbnail-Names': 'webapp',
  }

  return request(
    endpoint,
    {
      method: 'POST',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers: { ...defaultHeaders, ...options?.headers },
      body: file,
    },
    {
      returnRawResponse: false,
      withAuth: true,
      skipContentType: true,
    },
  )
}

export function putRequest(endpoint: string, data: any, headers = {}, withAuth = true): Promise<any> {
  return request(
    endpoint,
    {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
    },
    {
      returnRawResponse: false,
      withAuth,
    },
  )
}

interface PutV2Args<T> {
  endpoint: string
  headers?: Headers
  options?: RequestDataOptions
  payload?: T
}

// TODO: Replace old usages of  old `putRequest` and rename new one with the old one name
export function putV2Request<T, K = any>({ endpoint, headers, options = {}, payload }: PutV2Args<T>): Promise<K> {
  return request(
    endpoint,
    {
      method: 'PUT',
      headers,
      body: JSON.stringify(payload),
    },
    {
      returnRawResponse: false,
      withAuth: true,
      ...options,
    },
  )
}

export function patchRequest(
  endpoint: string,
  data: any,
  headers = {},
  withAuth = true,
  contentType?: ApiContentType,
): Promise<any> {
  return request(
    endpoint,
    {
      method: 'PATCH',
      headers,
      body: JSON.stringify(data),
    },
    {
      returnRawResponse: false,
      withAuth,
      contentType,
    },
  )
}

export function deleteRequest(endpoint: string, headers = {}, withAuth = true): Promise<any> {
  return request(
    endpoint,
    {
      method: 'DELETE',
      headers,
    },
    {
      returnRawResponse: true,
      withAuth,
    },
  )
}

interface DeleteV2Args {
  endpoint: string
  headers?: Headers
  options?: RequestDataOptions
}

// TODO: Replace old usages of  old `putRequest` and rename new one with the old one name
export function deleteV2Request<T = any>({ endpoint, headers, options = {} }: DeleteV2Args): Promise<T> {
  return request(
    endpoint,
    {
      method: 'DELETE',
      headers,
    },
    {
      returnRawResponse: false,
      withAuth: true,
      ...options,
    },
  )
}

export const initPrismicApi = async () => {
  const prismicApi = await Prismic.api(prismicUrl)
  return prismicApi
}

export const patchRequestWithSetContentType = (endpoint: string, data: any) => {
  return request(
    endpoint,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    },
    {
      withAuth: true,
      returnRawResponse: false,
      skipContentType: true,
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  )
}
