import { NavItem } from '@design-system'

import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { QueryKeys } from '../../../../enums/queryKeys'
import { logSearchCategory, LogSearchCategoryParams } from '../query-api'
import { BookkeepingTagNavItemValue } from '../types/bookkeepingTagNavItemValue'

export type CategorySearchEventType = 'selected' | 'removed'

interface LogCategoryEventProps {
  categoryId: number | null
  eventType: CategorySearchEventType
  items?: NavItem<BookkeepingTagNavItemValue>[]
  queryId: string
  sessionId: string
}

export const useLogSearchCategory = () => {
  const { mutate: logCategory, isLoading } = useMutation(
    [QueryKeys.LogSearchCategory],
    (params: LogSearchCategoryParams) => logSearchCategory(params),
  )

  const logCategoryEvent = useCallback(
    ({ categoryId, eventType, items, queryId, sessionId }: LogCategoryEventProps) => {
      let selectedIndex = null

      if (eventType === 'selected') {
        // `items` is a formatted list of categories for the _nested_ dropdown, so its first item is a header
        // We need to remove the header to know the exact index of the selected item
        const trimmedCategoriesList = items?.slice(1)
        selectedIndex = trimmedCategoriesList?.findIndex((item) => parseInt(item.id, 10) === categoryId) || null
      }

      /* eslint-disable @typescript-eslint/naming-convention */
      const searchParams = {
        session_id: sessionId,
        category_id: categoryId,
        query_id: queryId,
        event_type: eventType,
        selected_index: selectedIndex,
      }
      /* eslint-enable @typescript-eslint/naming-convention */

      logCategory(searchParams)
    },
    [logCategory],
  )

  return {
    isLoading,
    logCategoryEvent,
  }
}
