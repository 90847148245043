import { Color } from '../../../enums/color'
import { Variant } from '../types/variant'

interface BadgeStyles {
  textColor: Color
  bgColor: Color
}

export const variantStyles: Record<Variant, BadgeStyles> = {
  error: {
    textColor: Color.Red120,
    bgColor: Color.Red10,
  },
  warning: {
    textColor: Color.Yellow220,
    bgColor: Color.Yellow10,
  },
  primary: {
    textColor: Color.Purple110,
    bgColor: Color.Lavender,
  },
  success: {
    textColor: Color.Green140,
    bgColor: Color.Green10,
  },
  neutral: {
    textColor: Color.Gray200,
    bgColor: Color.Gray30,
  },
}
