import ReactDOM from 'react-dom'

export const getElementFontSize = (htmlElement: HTMLElement | null): number => {
  let fontSize = 0

  if (htmlElement) {
    const element = ReactDOM.findDOMNode(htmlElement)

    if (element && element instanceof Element) {
      fontSize = Number(window.getComputedStyle(element).getPropertyValue('font-size').replace(/\D/g, ''))
    }
  }

  return fontSize
}
