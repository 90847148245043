import { ModalContextProvider, withModalConditionalRender } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { store } from '@modules-deprecated'
import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { IsTrustedPartnerContextProvider } from '@modules-deprecated/app/umbrellas'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { BankIntegrationContextProvider } from '@views/bankIntegration/contexts/bankIntegrationContext'
import { ConnectionRenewedModal } from '@views/bankIntegration/elements/SuccessfulConnectionModal/ConnectionRenewedModal'
import { Dashboard } from '@views/dashboard'
import { DashboardSettingsContextProvider } from '@views/dashboard/contexts/dashboardSettingsContext'

import { queryClient } from '../../config/queryClient'
import { EmberConnectBank, EmberConnectBankContextProvider } from '../../contexts/emberConnectBankContext'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { EmberUserTypeContextProvider } from '../../contexts/emberUserTypeContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { ModalId } from '../../enums/modalId'

const MODULE_NAME = 'dashboard-module'

class DashboardWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @byAttrVal userType: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('connectBank') connectBank: Function
}

interface DashboardModuleProps {
  organizationId: string
  onNavigate: EmberNavigate
  onConnectBank: EmberConnectBank
  userType: string
}

const BankConnectionRenewedModal = withModalConditionalRender(ConnectionRenewedModal)

const DashboardModule = ({ organizationId, onNavigate, onConnectBank, userType }: DashboardModuleProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ReduxProvider store={store}>
          <CurrentUserContextProvider>
            <EmberRouterContextProvider onNavigate={onNavigate}>
              <Router>
                <AccountsContextProvider organizationId={organizationId}>
                  <ModalContextProvider>
                    <EmberConnectBankContextProvider onConnectBank={onConnectBank}>
                      <UserOrganizationContextProvider organizationId={organizationId}>
                        <UserOrganizationSettingsContextProvider organizationId={organizationId}>
                          <BankIntegrationContextProvider organizationId={organizationId}>
                            <IsTrustedPartnerContextProvider organizationId={organizationId}>
                              <EmberUserTypeContextProvider userType={userType}>
                                <DashboardSettingsContextProvider>
                                  <ModalContextProvider>
                                    <Dashboard organizationId={organizationId} />
                                    <BankConnectionRenewedModal id={ModalId.BankConnectionSuccessModal} />
                                  </ModalContextProvider>
                                </DashboardSettingsContextProvider>
                              </EmberUserTypeContextProvider>
                            </IsTrustedPartnerContextProvider>
                          </BankIntegrationContextProvider>
                        </UserOrganizationSettingsContextProvider>
                      </UserOrganizationContextProvider>
                    </EmberConnectBankContextProvider>
                  </ModalContextProvider>
                </AccountsContextProvider>
              </Router>
            </EmberRouterContextProvider>
          </CurrentUserContextProvider>
        </ReduxProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

const DashboardCustomElement = createCustomElement(DashboardModule, DashboardWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, DashboardCustomElement)

export default DashboardCustomElement
