import { DefaultValue, TOAST_SAFETY_OFFSET, zIndex } from '@design-system'

import { css } from '@emotion/core'
import 'react-toastify/dist/ReactToastify.css'

export const toaster = css`
  /** Classes for the displayed toast **/
  .Toastify__toast {
    min-height: 34px;
    margin: -${TOAST_SAFETY_OFFSET}px; // box shadow safety offset compensation
    padding: ${TOAST_SAFETY_OFFSET}px; // box shadow safety offset
    box-shadow: none;
  }
  .Toastify__toast-body {
    padding: 0;

    & > p {
      font-weight: 400;
      line-height: 18px;
      font-size: 14px;
      font-family: ${DefaultValue.FontFamily};
    }

    & + div {
      margin-left: 39.5px;
      margin-right: -1.5px;
      align-self: center;
      svg {
        height: 15.5px;
      }
    }
  }

  .Toastify__toast-container {
    width: auto;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    bottom: 100px;
    z-index: ${zIndex.Notification};
  }

  /** Classes for the progress bar **/
  .Toastify__progress-bar {
    background-color: white;
    height: 1px;
  }
`
