import { ReactElement, ReactNode } from 'react'
import ReactDOMServer from 'react-dom/server'

import { isStringOrNumber } from '../utils/isStringOrNumber'

export const getStringFromReactNode = (value?: ReactNode): string | undefined => {
  if (!value) {
    return undefined
  }

  if (isStringOrNumber(value)) {
    return String(value)
  }

  return ReactDOMServer.renderToString(value as ReactElement).trim()
}
