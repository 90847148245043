import React from 'react'
import { Box } from 'rebass'

import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconOrganizationOutline = ({ color = '#fff', width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-organizations-outline')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360.12 326.81">
      <rect
        x="13.5"
        y="80.12"
        width="333.12"
        height="233.18"
        rx="33.31"
        strokeWidth="27"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M254.58,360.23V93.74a33.31,33.31,0,0,0-33.31-33.31H154.65a33.31,33.31,0,0,0-33.31,33.31V360.23"
        transform="translate(-7.9 -46.93)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="27"
      />
    </svg>
  </Box>
)
