import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'

const FILE_NAME_WRAPPER_POSITION = '30px'

export const FileNameWrapper = styled.div`
  max-width: 80%;
  height: 30px;
  border-radius: 2px;
  padding: 4px 10px 2px;
  align-items: center;
  position: absolute;
  bottom: ${FILE_NAME_WRAPPER_POSITION};
  right: ${FILE_NAME_WRAPPER_POSITION};
  background-color: ${Color.White};
  box-shadow: 0 1px 5px ${Color.Gray50};
`

export const FileName = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
