import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

interface ShortcutKeyWrapperProps {
  wide?: boolean
}

export const ShortcutKeyWrapper = styled.span<ShortcutKeyWrapperProps>`
  display: inline-flex;
  min-width: 24px;
  height: 24px;
  border-radius: ${DefaultValue.BorderRadius};
  padding: ${Spacing.XXS} ${({ wide }) => (wide ? Spacing.S : Spacing.XXS)};
  align-items: center;
  justify-content: center;
  background-color: ${Color.Gray30};

  & + & {
    margin-left: ${Spacing.XXXS};
  }
`
