import React from 'react'

import { Color } from '../../../../enums/color'
import { Flex } from '../../../Flex'
import { Icon } from '../../../Icon'

export const FileEmptyThumbnail = () => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Icon icon="xSign" color={Color.Gray30} />
    </Flex>
  )
}
