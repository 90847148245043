import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { SortDirection } from '../../../../../enums/sortDirection'
import { ReceiptsSortProperty } from '../../../enums/sort'
import { ReceiptsSortFilterDropdownValue } from '../ReceiptsSorting'
import { getSortFilterId } from './getSortFilterId'

export const getSortingItems = (t: TFunction): NavItem<ReceiptsSortFilterDropdownValue>[] => [
  {
    children: [SortDirection.Desc, ReceiptsSortProperty.CreatedTime],
    id: getSortFilterId(ReceiptsSortProperty.CreatedTime, SortDirection.Desc),
    value: {
      displayData: {
        description: t('receipts.sorting.created_time_desc.description'),
        title: t('receipts.sorting.created_time_desc.title'),
      },
      direction: SortDirection.Desc,
      property: ReceiptsSortProperty.CreatedTime,
    },
  },
  {
    children: [SortDirection.Asc, ReceiptsSortProperty.CreatedTime],
    id: getSortFilterId(ReceiptsSortProperty.CreatedTime, SortDirection.Asc),
    value: {
      displayData: {
        description: t('receipts.sorting.created_time_asc.description'),
        title: t('receipts.sorting.created_time_asc.title'),
      },
      direction: SortDirection.Asc,
      property: ReceiptsSortProperty.CreatedTime,
    },
  },
  {
    children: [SortDirection.Desc, ReceiptsSortProperty.DocumentDate],
    id: getSortFilterId(ReceiptsSortProperty.DocumentDate, SortDirection.Desc),
    value: {
      displayData: {
        description: t('receipts.sorting.document_date_desc.description'),
        title: t('receipts.sorting.document_date_desc.title'),
      },
      direction: SortDirection.Desc,
      property: ReceiptsSortProperty.DocumentDate,
    },
  },
  {
    children: [SortDirection.Asc, ReceiptsSortProperty.DocumentDate],
    id: getSortFilterId(ReceiptsSortProperty.DocumentDate, SortDirection.Asc),
    value: {
      displayData: {
        description: t('receipts.sorting.document_date_asc.description'),
        title: t('receipts.sorting.document_date_asc.title'),
      },
      direction: SortDirection.Asc,
      property: ReceiptsSortProperty.DocumentDate,
    },
  },
  {
    children: [SortDirection.Desc, ReceiptsSortProperty.Amount],
    id: getSortFilterId(ReceiptsSortProperty.Amount, SortDirection.Desc),
    value: {
      displayData: {
        description: t('receipts.sorting.amount_desc.description'),
        title: t('receipts.sorting.amount_desc.title'),
      },
      direction: SortDirection.Desc,
      property: ReceiptsSortProperty.Amount,
    },
  },
  {
    children: [SortDirection.Asc, ReceiptsSortProperty.Amount],
    id: getSortFilterId(ReceiptsSortProperty.Amount, SortDirection.Asc),
    value: {
      displayData: {
        description: t('receipts.sorting.amount_asc.description'),
        title: t('receipts.sorting.amount_asc.title'),
      },
      direction: SortDirection.Asc,
      property: ReceiptsSortProperty.Amount,
    },
  },
  {
    children: [SortDirection.Asc, ReceiptsSortProperty.Supplier],
    id: getSortFilterId(ReceiptsSortProperty.Supplier, SortDirection.Asc),
    value: {
      displayData: {
        description: t('receipts.sorting.supplier_asc.description'),
        title: t('receipts.sorting.supplier_asc.title'),
      },
      direction: SortDirection.Asc,
      property: ReceiptsSortProperty.Supplier,
    },
  },
  {
    children: [SortDirection.Desc, ReceiptsSortProperty.Supplier],
    id: getSortFilterId(ReceiptsSortProperty.Supplier, SortDirection.Desc),
    value: {
      displayData: {
        description: t('receipts.sorting.supplier_desc.description'),
        title: t('receipts.sorting.supplier_desc.title'),
      },
      direction: SortDirection.Desc,
      property: ReceiptsSortProperty.Supplier,
    },
  },
]
