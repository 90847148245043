import { SettingsSection } from '@components'
import { Button, Checkbox, ComponentsGroup, ModalConfirmation, notify, Spacing, useModal } from '@design-system'

import { ReactElement, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { APIError } from '../../../../../../utils'
import { useDeleteVatRate } from '../../hooks/useDeleteVatRate'
import { useInvalidateVatRates } from '../../hooks/useInvalidateVatRates'
import { useTemplatesToCreateVatRates } from '../../hooks/useTemplatesToCreateVatRates'
import { CreateOrEditVatRateFromTemplateModal } from '../VatRateModal/CreateOrEditVatRateFromTemplateModal'
import { CreateOrEditVatRateModal } from '../VatRateModal/CreateOrEditVatRateModal'
import { VatRateType } from '../VatRateModal/shared/enums/vatRateType'
import { getCreateModalId } from '../VatRateModal/shared/utils/getCreateModalId'
import { PurchaseVatRatesTable } from './elements/PurchaseVatRatesTable'
import * as Styled from './styles'

const DELETE_PURCHASE_TAX_RATE_CONFIRMATION_MODAL_ID = 'delete-purchase-tax-rate-confirmation'

export const PurchaseVatRates = (): ReactElement => {
  const { t } = useTranslation()
  const [isArchivedVisible, setIsArchivedVisible] = useState(false)
  const vatRateIdToDelete = useRef<string | undefined>()
  const { invalidateTaxRates } = useInvalidateVatRates()

  const createVatRateModalId = getCreateModalId('purchase')

  const { open: openCreateVatRateModal } = useModal(createVatRateModalId)

  const { open: openDeleteConfirmation, close: closeDeleteConfirmation } = useModal(
    DELETE_PURCHASE_TAX_RATE_CONFIRMATION_MODAL_ID,
    {
      onClose: () => {
        vatRateIdToDelete.current = undefined
      },
    },
  )

  const onDeleteTaxRateError = useCallback(
    (error: APIError | undefined) => {
      notify({
        id: 'tax-ruleset-deleted',
        message: error?.message || t('settings.organization.vat_rates.rates.notification.delete_error'),
        variant: 'error',
      })
    },
    [t],
  )

  const onDeleteTaxRateSuccess = useCallback(() => {
    notify({
      id: 'tax-ruleset-deleted',
      message: t('settings.organization.vat_rates.rates.notification.delete_success'),
      variant: 'success',
    })
    closeDeleteConfirmation()
    invalidateTaxRates()
  }, [closeDeleteConfirmation, invalidateTaxRates, t])

  const { delete: deleteRuleset, isLoading: isDeleting } = useDeleteVatRate({
    onSuccess: onDeleteTaxRateSuccess,
    onError: onDeleteTaxRateError,
  })

  const onRateDelete = useCallback(
    (taxRateId: string) => {
      vatRateIdToDelete.current = taxRateId
      openDeleteConfirmation()
    },
    [openDeleteConfirmation],
  )

  const handleDeleteModalOkClick = useCallback(() => {
    if (vatRateIdToDelete.current) {
      deleteRuleset(vatRateIdToDelete.current)
    }
  }, [deleteRuleset])

  const handleCreateRateButtonClick = useCallback(() => {
    openCreateVatRateModal()
  }, [openCreateVatRateModal])

  const handleShowArchivedCheckboxChange = useCallback(() => {
    setIsArchivedVisible((currentValue) => !currentValue)
  }, [])

  const { isCreateNewVatRateFromTemplate } = useTemplatesToCreateVatRates()

  return (
    <>
      <SettingsSection>
        <SettingsSection.Content
          title={t('settings.organization.vat_rates.purchase_vat_rates')}
          sideActions={
            <ComponentsGroup spacing={Spacing.L} direction="column" centered={false}>
              <Button icon="plusCircle" variant="secondary" onClick={handleCreateRateButtonClick}>
                {t('settings.organization.vat_rates.sales_vat_rates.create')}
              </Button>
              <Styled.CheckboxWrapper>
                <Checkbox onChange={handleShowArchivedCheckboxChange}>
                  {t('settings.organization.vat_rates.show_archived')}
                </Checkbox>
              </Styled.CheckboxWrapper>
            </ComponentsGroup>
          }
          subtitle={t('settings.organization.vat_rates.purchase_vat_rates.description')}
        >
          <Styled.TableWrapper>
            <PurchaseVatRatesTable onRateDelete={onRateDelete} showArchived={isArchivedVisible} />
          </Styled.TableWrapper>
          <ModalConfirmation
            cancelLabel={t('cancel')}
            danger
            id={DELETE_PURCHASE_TAX_RATE_CONFIRMATION_MODAL_ID}
            message={t('settings.organization.vat_rates.modals.delete.message')}
            okLabel={t('settings.organization.vat_rates.modals.delete.delete')}
            okLoading={isDeleting}
            onOk={handleDeleteModalOkClick}
            title={t('settings.organization.vat_rates.modals.delete.title')}
          />
        </SettingsSection.Content>
      </SettingsSection>

      {isCreateNewVatRateFromTemplate ? (
        <CreateOrEditVatRateFromTemplateModal id={createVatRateModalId} appliesTo={VatRateType.Purchase} />
      ) : (
        <CreateOrEditVatRateModal id={createVatRateModalId} appliesTo={VatRateType.Purchase} />
      )}
    </>
  )
}
