/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Tooltip /> from design-system instead
 */
import styled from '@emotion/styled'
import React from 'react'
import { Tooltip as ReactTooltip, ITooltip as TooltipProps } from 'react-tooltip'

import { Themable } from '../../types/themable'

type StyledTooltipProps = TooltipProps & Themable

const StyledTooltip = styled(ReactTooltip)`
  padding: 5px;
  font-weight: bold;
  white-space: normal;
  background-color: ${({ theme }: StyledTooltipProps) => theme.colors.light} !important;
  ::after {
    border-top-color: ${({ theme }: StyledTooltipProps) => theme.colors.light} !important;
  }
`

export const Tooltip = (props: TooltipProps) => {
  return <StyledTooltip {...props} />
}
