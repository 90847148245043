import { Translate } from '@components'
import { CellValue, ItemsPerPage, Table, TableColumn } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrellaOrganizationSubscriptionsFilters } from '../../context/umbrellaOrganizationSubscriptionsFiltersContext'
import { useUmbrellaOrganizationsSubscriptions } from '../../hooks/useUmbrellaOrganizationsSubscriptions'
import { OrganizationSubscriptionsTableAccessors } from './constants/organizationSubscriptionsTableAccessors'
import { OrganizationSubscriptionsTableRow } from './types/organizationSubscriptionsTableRow'
import { getPlanPrice } from './utils/getPlanPrice'

export const OrganizationSubscriptionsTable = (): ReactElement => {
  const { t } = useTranslation()

  const { queryParams, setQueryParams } = useUmbrellaOrganizationSubscriptionsFilters()
  const { umbrellaOrganizationsSubscriptions, isLoading, pagination } = useUmbrellaOrganizationsSubscriptions({
    page: queryParams.page,
    pageSize: queryParams.pageSize,
    search: queryParams.search,
  })

  const columns: TableColumn<OrganizationSubscriptionsTableRow>[] = useMemo(
    () => [
      {
        accessor: OrganizationSubscriptionsTableAccessors.Name,
        Header: <Translate value="umbrella_subscriptions.organization_subscriptions.table.name_column" />,
        Cell: ({
          value: name,
        }: CellValue<Pick<OrganizationSubscriptionsTableRow, OrganizationSubscriptionsTableAccessors.Name>>) => (
          <>{name}</>
        ),
      },
      {
        accessor: OrganizationSubscriptionsTableAccessors.SubscriptionPlan,
        Header: <Translate value="umbrella_subscriptions.organization_subscriptions.table.subscription_plan_column" />,
        Cell: ({
          value: subscriptionPlan,
        }: CellValue<
          Pick<OrganizationSubscriptionsTableRow, OrganizationSubscriptionsTableAccessors.SubscriptionPlan>
        >) => <>{subscriptionPlan}</>,
      },
      {
        accessor: OrganizationSubscriptionsTableAccessors.Price,
        alignment: 'right',
        Header: <Translate value="umbrella_subscriptions.organization_subscriptions.table.price_column" />,
        Cell: ({
          value: price,
        }: CellValue<Pick<OrganizationSubscriptionsTableRow, OrganizationSubscriptionsTableAccessors.Price>>) => (
          <>{price}</>
        ),
      },
    ],
    [],
  )

  const tableData: OrganizationSubscriptionsTableRow[] = useMemo(
    () =>
      umbrellaOrganizationsSubscriptions.map(({ organizationId, planId, name, period, price, subscriptionPlan }) => ({
        [OrganizationSubscriptionsTableAccessors.Name]: name,
        [OrganizationSubscriptionsTableAccessors.Price]:
          price && period
            ? getPlanPrice(price, period, t)
            : t('umbrella_subscriptions.organization_subscriptions.table.empty_value'),
        [OrganizationSubscriptionsTableAccessors.SubscriptionPlan]:
          subscriptionPlan || t('umbrella_subscriptions.organization_subscriptions.table.empty_value'),
        id: `${organizationId}-${planId}`,
        organizationId,
      })),
    [umbrellaOrganizationsSubscriptions, t],
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setQueryParams({ page })
    },
    [setQueryParams],
  )

  const handleItemsPerPageChange = useCallback(
    (pageSize: ItemsPerPage) => {
      setQueryParams({ pageSize })
    },
    [setQueryParams],
  )

  const paginationProps = useMemo(
    () => ({
      currentPage: queryParams.page ? Number(queryParams.page) : undefined,
      itemsPerPage: queryParams.pageSize ? (Number(queryParams.pageSize) as ItemsPerPage) : undefined,
      totalPageItems: pagination?.total,
    }),
    [pagination?.total, queryParams],
  )

  return (
    <Table
      {...paginationProps}
      columns={columns}
      data={tableData}
      isLoading={isLoading}
      onItemsPerPageChange={handleItemsPerPageChange}
      onPageChange={handlePageChange}
      withItemsPerPageSelect
      withPagination
      withStickyHeader
    />
  )
}
