import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface TableStaticActionsHeaderItemProps {
  children?: ReactNode
}

export const TableStaticActionsHeaderItem = ({ children }: TableStaticActionsHeaderItemProps): ReactElement => {
  return <Styled.TableStaticActionsHeaderItemWrapper>{children}</Styled.TableStaticActionsHeaderItemWrapper>
}
