import { useBankConnections } from '@views/bankIntegration/hooks/useBankConnections'

export const useShouldConnectBank = (accountId: string) => {
  const { bankConnections, isLoading: isLoadingBankConnections } = useBankConnections()

  const connectedBank = bankConnections?.find((bankConnection) => bankConnection.id === accountId)

  const hasConnectionError = !!connectedBank?.connection.requiresUpdate

  const shouldRenewBankConnection = hasConnectionError
  const shouldConnectBank = !connectedBank
  const isLoading = isLoadingBankConnections

  return {
    isLoading,
    shouldConnectBank,
    shouldRenewBankConnection,
  }
}
