import { Button as ButtonComponent, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const Button = styled(ButtonComponent)`
  margin: 0 auto;
`

export const List = styled.ul`
  list-style: disc;
  list-style-position: outside;
`

export const ListItem = styled.li`
  margin-bottom: ${Spacing.XS};
  margin-left: ${Spacing.L};
`
