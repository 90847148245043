import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { FinancingBanner } from '@features/financingBanner/elements/FinancingBanner'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { AgerasFinanceCustomerContextProvider } from '@views/financing'

import { queryClient } from '../../config/queryClient'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

class FinancingBannerWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('onBannerInteraction') onBannerInteraction: EmberEventFn
}

type IProps = {
  organizationId: string
  onBannerInteraction: EmberEventFn
}

function Module({ organizationId, onBannerInteraction }: IProps): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <CurrentUserContextProvider>
          <ModalContextProvider>
            <AgerasFinanceCustomerContextProvider organizationId={organizationId}>
              <UserOrganizationContextProvider organizationId={organizationId}>
                <UserOrganizationSettingsContextProvider organizationId={organizationId}>
                  <FinancingBanner onBannerInteraction={onBannerInteraction} />
                </UserOrganizationSettingsContextProvider>
              </UserOrganizationContextProvider>
            </AgerasFinanceCustomerContextProvider>
          </ModalContextProvider>
        </CurrentUserContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

let FinancingBannerCustomElement

if (customElements) {
  FinancingBannerCustomElement = createCustomElement(Module, FinancingBannerWebComponent, 'element')

  customElements.get('financing-banner-module') ||
    customElements.define('financing-banner-module', FinancingBannerCustomElement)
}

export default FinancingBannerCustomElement
