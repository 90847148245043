import React, { ReactElement, ReactNode, useCallback, useEffect } from 'react'
import { Accept, DropzoneOptions, useDropzone } from 'react-dropzone'

import { DropzoneBlockedDefaultProps, dropzoneBlockedDefaultProps } from '../../constants/dropzoneBlockedDefaultProps'
import { DropzoneContent } from './elements/DropzoneContent'
import { useTranslateRejectedFilesErrors } from './hooks/useTranslateRejectedFilesErrors'
import * as Styled from './styles'

export const ACCEPT_DEFAULT: Accept = {
  'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
  'application/pdf': ['.pdf'],
}

export interface DropzoneProps extends DropzoneOptions {
  blockedText?: ReactNode
  children?: ReactNode
  contentText?: ReactNode
  className?: string
  isBlocked?: boolean
  isUploading?: boolean
  onClick?: () => void
  onDragActive?: (isActive: boolean) => void
  raw?: boolean
  uploadingText?: ReactNode
}

export const Dropzone = ({
  accept = ACCEPT_DEFAULT,
  blockedText,
  children,
  className,
  contentText,
  disabled,
  isBlocked,
  isUploading,
  onClick,
  onDragActive,
  raw,
  uploadingText,
  onDrop,
  onDropRejected,
  maxFiles,
  maxSize,
  minSize,
  ...rest
}: DropzoneProps): ReactElement => {
  const { handleDrop, handleDropRejected } = useTranslateRejectedFilesErrors({
    onDrop,
    onDropRejected,
    maxFiles,
    maxSize,
    minSize,
    accept,
  })

  const dropzoneBlockedProps: DropzoneBlockedDefaultProps | undefined = isBlocked
    ? dropzoneBlockedDefaultProps
    : undefined

  const dropzoneOptions: DropzoneOptions = {
    ...rest,
    accept,
    disabled,
    maxFiles,
    maxSize,
    minSize,
    onDrop: handleDrop,
    onDropRejected: handleDropRejected,
    ...dropzoneBlockedProps,
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone(dropzoneOptions)

  useEffect(() => {
    onDragActive?.(isDragActive)
  }, [onDragActive, isDragActive])

  const handleDropzoneClick = useCallback(() => {
    if (!isBlocked) {
      open()
    }

    onClick?.()
  }, [isBlocked, onClick, open])

  return (
    <Styled.DropzoneWrapper
      blocked={isBlocked}
      className={className}
      disabled={disabled}
      raw={raw}
      active={isDragActive}
      {...getRootProps()}
      onClick={handleDropzoneClick}
    >
      <input {...getInputProps()} />

      {children || (
        <DropzoneContent
          blockedText={blockedText}
          contentText={contentText}
          isBlocked={isBlocked}
          isDragActive={isDragActive}
          isUploading={isUploading}
          uploadingText={uploadingText}
        />
      )}
    </Styled.DropzoneWrapper>
  )
}
