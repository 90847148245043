/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Text /> from design-system instead
 */
import cc from 'classcat'
import React from 'react'
import { Heading as RebassHeading, HeadingProps as RebassHeadingProps } from 'rebass'

export type HeadingProps = RebassHeadingProps

export const Heading = ({ as = 'h2', children, variant, className, ...rest }: HeadingProps) => (
  <RebassHeading as={as} variant={variant || 'h2'} className={cc([className, as])} {...rest}>
    {children}
  </RebassHeading>
)
