import { AccountSelect } from '@components'
import {
  amountToDisplayValue,
  Button,
  ButtonsGroup,
  DateInput,
  Divider,
  FormItem as FormItemDS,
  FormItemsGroup as FormItemsGroupDS,
  Modal,
  Space,
  Text,
  useModal,
} from '@design-system'

import React, { useCallback, useEffect, useMemo } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { SUBMIT_SHORTCUT } from '../../../../constants/shortcuts'
import { ModalId } from '../../../../enums/modalId'
import { useSegment } from '../../../../hooks'
import { useForm } from '../../../../hooks/useForm'
import { formatDate } from '../../../../utils'
import { getAmountsFromBillLines } from '../../utils/getAmountsFromBillLines'
import { BillForm } from '../BillEditableForm/utils/formData'
import { defaultValues, getValidationSchema, RegisterPaymentForm } from './utils/formData'

interface BillRegisterPaymentModalProps {
  billFormErrors?: FieldErrors<BillForm>
  currentBillFormValues?: BillForm
  hasPaymentRegistered?: boolean
  onRemove?: () => void
  onSubmit?: (form: RegisterPaymentForm) => void
}

export const BillRegisterPaymentModal = ({
  billFormErrors,
  currentBillFormValues,
  hasPaymentRegistered,
  onRemove,
  onSubmit,
}: BillRegisterPaymentModalProps) => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { accounts = [] } = useAccounts()
  const { handleSubmit, Form, FormItem, setValue, clearErrors, getValues, setError } = useForm({
    defaultValues,
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const { close, isOpen } = useModal(ModalId.BillRegisterPaymentModal, {
    onClose: () => {
      resetForm()
      clearErrors()
    },
  })
  const { isOpen: isDirtyRouteModalOpened } = useModal(ModalId.DirtyRouteModal)

  // Computed values

  const { billDate, vendor, billLines, currencyId, paymentDate, paymentAccount } = currentBillFormValues || {}
  const { inclVatAmountTotal } = getAmountsFromBillLines(billLines)

  // Lifecycle

  // Set 'paymentDate' if already saved
  useEffect(() => {
    if (isOpen && paymentDate) {
      setValue('paymentDate', paymentDate)

      if (billFormErrors?.paymentDate) {
        setError('paymentDate', billFormErrors?.paymentDate)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, paymentDate, billFormErrors])

  // Set 'paymentAccount' if already saved
  useEffect(() => {
    if (isOpen && accounts.length && paymentAccount?.id) {
      const account = accounts.find((account) => account.id === paymentAccount.id)
      setValue('paymentAccount', account)

      if (billFormErrors?.paymentAccount) {
        setError('paymentAccount.id', billFormErrors?.paymentAccount)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, accounts, paymentAccount, billFormErrors])

  // Set 'paymentDate' if doesn't exist, using the 'billDate' initially
  useEffect(() => {
    if (isOpen && !getValues('paymentDate') && billDate && !paymentDate) {
      setValue('paymentDate', billDate)
    }

    if (isOpen && !getValues('billDate') && billDate) {
      setValue('billDate', billDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, billDate])

  // Set 'paymentAccount' if doesn't exist, using the default organization bill account
  useEffect(() => {
    if (
      isOpen &&
      !getValues('paymentAccount') &&
      organization?.defaultBillBankAccountId &&
      accounts.length &&
      !paymentAccount?.id
    ) {
      const account = accounts.find((account) => account.id === organization.defaultBillBankAccountId)
      setValue('paymentAccount', account)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, accounts, organization?.defaultBillBankAccountId])

  // Helpers

  const submitForm = useCallback(
    (form: RegisterPaymentForm) => {
      onSubmit?.(form)
      track('xxx - expenses - Expense draft - register payment')
      close()
    },
    [close, onSubmit],
  )

  const resetForm = useCallback(() => {
    setValue('paymentAccount', undefined)
    setValue('billDate', undefined)
    setValue('paymentDate', undefined)
  }, [setValue])

  // Handlers

  const handleCancelClick = useCallback(() => {
    close()
  }, [close])

  const handleRemovePaymentClick = useCallback(() => {
    onRemove?.()
    close()
  }, [onRemove, close])

  const handleRegisterPaymentClick = useCallback(() => {
    handleSubmit(submitForm)()
  }, [handleSubmit, submitForm])

  const handleSubmitShortcut = useCallback(() => {
    handleSubmit(submitForm)()
  }, [handleSubmit, submitForm])

  // Shortcuts

  useHotkeys(
    SUBMIT_SHORTCUT,
    handleSubmitShortcut,
    { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'], enabled: !isDirtyRouteModalOpened },
    [isDirtyRouteModalOpened],
  )

  return (
    <Modal id={ModalId.BillRegisterPaymentModal} autofocusOnInput={!billDate}>
      <Modal.Header
        title={
          hasPaymentRegistered
            ? t('bill.register_payment.modal.title.edit')
            : t('bill.register_payment.modal.title.register')
        }
      />
      <Modal.Body>
        <FormItemsGroupDS itemsInRow={4}>
          <FormItemDS label={t('bill.register_payment.form.bill_date')}>
            {billDate ? formatDate(billDate, 'dd-MM-yyyy') : '—'}
          </FormItemDS>
          <FormItemDS itemColumnStart={2} itemColumnEnd={4} label={t('bill.register_payment.form.vendor')}>
            <Text variant="h3">{vendor?.name ? vendor.name : '—'}</Text>
          </FormItemDS>
          <FormItemDS labelAlignment="right" label={t('bill.register_payment.form.incl_vat_amount')}>
            <Text variant="h3" alignment="right">
              {amountToDisplayValue(inclVatAmountTotal)}{' '}
              <Text as="span" weight="medium">
                {currencyId}
              </Text>
            </Text>
          </FormItemDS>
        </FormItemsGroupDS>
        <Space size="l" />
        <Divider />
        <Space size="l" />
        <Form>
          <FormItemsGroupDS itemsInRow={4}>
            <FormItem
              label={t('bill.register_payment.form.payment_date')}
              name="paymentDate"
              render={({ field }) => <DateInput {...field} minDate={billDate} />}
            ></FormItem>
            <FormItem
              itemColumnEnd={5}
              itemColumnStart={2}
              label={t('bill.register_payment.form.payment_account')}
              name="paymentAccount"
              render={({ field: { value, onChange, ...props } }) => (
                <AccountSelect
                  {...props}
                  allowClear
                  dropdownSize="fitTrigger"
                  isPaymentEnabled
                  onSelect={(id, value) => onChange(value)}
                  selectedId={value?.id}
                />
              )}
            ></FormItem>
          </FormItemsGroupDS>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={handleCancelClick}>
            {t('cancel')}
          </Button>
          {hasPaymentRegistered && (
            <Button variant="text" onClick={handleRemovePaymentClick}>
              {t('bill.register_payment.button.remove')}
            </Button>
          )}
          <Button onClick={handleRegisterPaymentClick}>
            {hasPaymentRegistered ? t('bill.register_payment.button.edit') : t('bill.register_payment.button.register')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
