import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { ReactComponent as FinancingIllustration } from '../../../../assets/images/billy-financing.svg'

export const FinancingBannerWrapper = styled.div`
  border-radius: ${DefaultValue.BorderRadius};
  background-color: ${Color.Purple20};
  display: flex;
  overflow: hidden;
`

export const BannerImageWrapper = styled.div`
  min-width: 215px;
  max-width: 380px;
  background-position: center right;
  background-size: cover;
  flex-grow: 1;
  margin-top: ${Spacing.M};
  margin-bottom: -${Spacing.M};
  position: relative;
`

export const BannerImage = styled(FinancingIllustration)`
  position: absolute;
  right: 0;
`

export const BannerContent = styled.div`
  min-width: 630px;
  padding: ${Spacing.L} ${Spacing.XXL};
`
