import { Color } from '@design-system'

import styled from '@emotion/styled'

import { Themable } from '../../types/themable'

export const Container = styled.div`
  display: flex;
  margin: 12px 0;
`

export const FlipSwitchWrapper = styled.div`
  padding: 4px 0;
`

export const Description = styled.p<Themable>`
  margin-left: 10px;
  font-weight: normal;
  font-size: 14px;
  color: ${Color.Gray90};
`
