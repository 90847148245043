import { AmountFormatter, Translate } from '@components'
import { Table, TableColumn } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { TableIds } from '../../../../enums/tableIds'
import { useSalesWithoutVat } from '../../hooks/useSalesWithoutVat'

export const SalesTaxTable = (): ReactElement => {
  const { organization } = useUserOrganization()
  const { data, isLoading } = useSalesWithoutVat()

  const columns: TableColumn<any>[] = useMemo(
    () => [
      {
        Header: <Translate value={'sales_tax_returns.eu_sales_without_vat.country_code'} />,
        accessor: 'countryCode',
        size: 'xs',
      },
      {
        Header: <Translate value={'sales_tax_returns.eu_sales_without_vat.vat_no'} />,
        accessor: 'vatNo',
        size: 's',
      },
      {
        Header: <Translate value={'sales_tax_returns.eu_sales_without_vat.company_name'} />,
        accessor: 'companyName',
        size: 'l',
      },
      {
        Header: <Translate value={'sales_tax_returns.eu_sales_without_vat.period'} />,
        accessor: 'period',
        size: 'l',
      },
      {
        Header: <Translate value={'sales_tax_returns.eu_sales_without_vat.total_goods'} />,
        accessor: 'totalGoods',
        alignment: 'right',
        size: 'm',
        Cell: ({ row, data }) => {
          const values = data[row.id]

          return <AmountFormatter value={values.totalGoods} currencyId={organization?.baseCurrencyId} />
        },
      },
      {
        Header: <Translate value={'sales_tax_returns.eu_sales_without_vat.total_services'} />,
        accessor: 'totalServices',
        alignment: 'right',
        size: 'm',
        Cell: ({ row, data }) => {
          const values = data[row.id]

          return <AmountFormatter value={values.totalServices} currencyId={organization?.baseCurrencyId} />
        },
      },
    ],
    [organization],
  )

  const tableData: any[] = useMemo(
    () =>
      data.map((sale: any) => ({
        id: uuidv4(),
        companyName: sale.name,
        countryCode: sale.countryId,
        vatNo: sale.registrationNo,
        period: `${sale.minDate} - ${sale.maxDate}`,
        totalGoods: sale.V || 0,
        totalServices: sale.Y || 0,
        exchangeRate: sale.exchangeRate,
      })),
    [data],
  )

  return <Table columns={columns} data={tableData} id={TableIds.SalesTax} isLoading={isLoading} />
}
