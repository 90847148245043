import React, { ReactNode } from 'react'

import { Text } from '../../../Text'
import { NavListItemChildren } from '../../elements/NavListItem'
import { NavItemDisplayableValue } from '../../types/navItem'
import { NavItemRenderProps } from '../../types/navItemRenderProps'
import * as Styled from './styles'

interface DisplayDataInline {
  description?: string
  prefix?: ReactNode
  title: string
}
export type NavListItemWithInlineDescriptionValue = NavItemDisplayableValue<DisplayDataInline>
type NavListItemWithInlineDescriptionProps<T> = NavItemRenderProps<T>

export const NavListItemWithInlineDescription = <T extends NavListItemWithInlineDescriptionValue>(
  props: NavListItemWithInlineDescriptionProps<T>,
) => {
  return (
    <Styled.NavListItemWithInlineDescription {...props}>
      {/* This is decorated with textColor and textSecondaryColor in "NavListItem.tsx": */}
      <CustomText {...props} />
    </Styled.NavListItemWithInlineDescription>
  )
}

type CustomTextProps<T> = NavListItemChildren<T>

const CustomText = <T extends NavListItemWithInlineDescriptionValue>({
  item,
  textColor,
  textSecondaryColor,
}: CustomTextProps<T>) => {
  const {
    value: {
      displayData: { description, prefix, title },
    },
  } = item

  const titleWithDescription = description ? `${title} (${description})` : title

  return (
    <Styled.Content>
      {!!prefix && <Styled.PrefixWrapper>{prefix}</Styled.PrefixWrapper>}
      <Text title={titleWithDescription} truncate nowrap>
        <Text as="span" color={textColor} weight="medium">
          {title}
        </Text>
        &nbsp;
        {description && (
          <Text as="span" color={textSecondaryColor}>
            ({description})
          </Text>
        )}
      </Text>
    </Styled.Content>
  )
}
