import { getRawRequest, postRequest } from '../../utils'

const cloudCollectApiKey = window.ENV.CLOUD_COLLECT_API_KEY
const cloudCollectTermsAndConditionsUrl = window.ENV.CLOUD_COLLECT_URL + '/integrations/billy/terms-and-conditions/'
const cloudCollectCreateAccountUrl = window.ENV.CLOUD_COLLECT_URL + '/integrations/billy/create-account/'

export type FetchDebtCollectionTermsResponse = string

export const fetchDebtCollectionTerms = async (): Promise<FetchDebtCollectionTermsResponse> => {
  const termsResponse = await getRawRequest(cloudCollectTermsAndConditionsUrl)
  const termsHtml = await termsResponse.text()

  return termsHtml
}

export const createDebtCollectionAccount = (token: string) => {
  const payload = { accessToken: token }

  const headers = {
    Authorization: `Bearer ${cloudCollectApiKey}`,
  }

  return postRequest(cloudCollectCreateAccountUrl, payload, headers, false)
}

export interface ConnectToMobilePayOptions {
  paymentMethodId: string
  organizationId: string
  registrationNumber: string
}

export interface ConnectToMobilePayResponse {
  status: string
  url: string
}

export const connectToMobilePay = ({
  organizationId,
  paymentMethodId,
  registrationNumber,
}: ConnectToMobilePayOptions): Promise<ConnectToMobilePayResponse> => {
  return postRequest(`/integrations/mobilepay/organizations/${organizationId}/connect`, {
    businessIdentifierId: registrationNumber,
    futurePaymentMethodId: paymentMethodId,
  })
}
