import React, { ChangeEvent, createContext, ReactElement, ReactNode, useContext, useMemo } from 'react'

interface ContextState {
  name?: string
  disabled?: boolean
  value?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const defaultValue: ContextState = {}

const RadioGroup = createContext(defaultValue)

interface RadioGroupProps extends ContextState {
  children?: ReactNode
}

export const RadioGroupProvider = ({
  children,
  name,
  disabled,
  value: currentValue,
  onChange,
}: RadioGroupProps): ReactElement => {
  const value = useMemo(
    () => ({
      name,
      disabled,
      value: currentValue,
      onChange,
    }),
    [name, disabled, currentValue, onChange],
  )

  return <RadioGroup.Provider value={value}>{children}</RadioGroup.Provider>
}

export const useRadioGroup = () => useContext(RadioGroup)
