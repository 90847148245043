import React from 'react'
import { Box } from 'rebass'

import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconLogOut = ({ color = '#095a8c', width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-logout')} {...rest}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 396.32 435.1">
      <path
        d="M302.59,42.79h92.24A46.12,46.12,0,0,1,441,88.91V411.77a46.12,46.12,0,0,1-46.12,46.12H302.59"
        transform="translate(-54.63 -32.79)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <path
        d="M66.2,250.34l113-113v85.34H347.84a3.25,3.25,0,0,1,3.25,3.24v47.51a3.26,3.26,0,0,1-3.25,3.25H179.19v86.64Z"
        transform="translate(-54.63 -32.79)"
        fill={color}
      />
      <path
        d="M169.19,161.5v71.19h171.9v34H169.19v72.5L80.34,250.34l88.85-88.84m12.53-37.81a7.26,7.26,0,0,0-5.19,2.18L56.45,246a6.22,6.22,0,0,0,0,8.78L176.53,374.81a7.27,7.27,0,0,0,5.19,2.19,7.41,7.41,0,0,0,7.47-7.43V286.69H347.84a13.25,13.25,0,0,0,13.25-13.25V225.93a13.25,13.25,0,0,0-13.25-13.24H189.19V131.12a7.41,7.41,0,0,0-7.47-7.43Z"
        transform="translate(-54.63 -32.79)"
        fill="#fff"
      />
    </svg>
  </Box>
)
