import get from 'lodash/get'
import { SchemaOf } from 'yup'

export const getItemsForSelect = <T>(items?: T[], textPath = 'name', descriptionPath = '') =>
  items?.map((item) => ({
    ...item,
    text: get(item, textPath)?.toString() || '',
    ...(descriptionPath ? { description: get(item, descriptionPath)?.toString() || '' } : {}),
  }))

type CustomError = {
  type: string
  message: string
}

type YupError = {
  path: string
  type?: string
  message: string
}

export const useYupValidationResolver =
  <T>(validationSchema: SchemaOf<T>, validator: (data: T) => Promise<Record<string, string> | undefined>) =>
  async (data: T) => {
    const customErrors: Record<string, CustomError> = {}
    const validatorResult = (await validator(data)) || {}

    for (const path in validatorResult) {
      const message = validatorResult[path]
      customErrors[path] = Object.assign({}, { type: 'validation', message })
    }

    try {
      const values = await validationSchema.validate(data, {
        abortEarly: false,
      })

      return {
        values,
        errors: Object.assign({}, customErrors),
      }
    } catch (errors: any) {
      const errorsMapped = errors.inner.reduce(
        (allErrors: YupError, currentError: YupError) => ({
          ...allErrors,
          [currentError.path]: {
            type: currentError.type ?? 'validation',
            message: currentError.message,
          },
        }),
        {},
      )

      return {
        values: {},
        errors: Object.assign({}, errorsMapped, customErrors),
      }
    }
  }
