import { DateFormatter } from '@components'
import { amountToDisplayValue, Color, PaymentPane, PaymentPaneProps, Text } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { InvoiceBalanceModifier } from '../../../../types/invoiceBalanceModifier'
import { InvoiceType } from '../../../../types/invoiceType'
import { creditnoteBalanceModifierReferenceToTKey } from '../../constants/creditnoteBalanceModifierReferenceToTKey'
import { invoiceBalanceModifierReferenceToIconName } from '../../constants/invoiceBalanceModifierReferenceToIconName'
import { invoiceBalanceModifierReferenceToTKey } from '../../constants/invoiceBalanceModifierReferenceToTKey'
import { InvoiceBalanceModifierReference } from '../../enums/InvoiceBalanceModifierReference'
import { getInvoiceBalanceModifierReference } from '../../utils/getInvoiceBalanceModifierReference'
import { getInvoicePaymentPaneItemRoute } from '../../utils/getInvoicePaymentPaneItemRoute'

type InvoicePaymentPaneItemProps = InvoiceBalanceModifier &
  Pick<PaymentPaneProps, 'withHoverEffect'> & {
    invoiceType: InvoiceType | null
  }

export const InvoicePaymentPaneItem = ({
  amount,
  entryDate,
  invoiceType,
  modifierReference,
  withHoverEffect,
}: InvoicePaymentPaneItemProps): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()

  const modifierData = useMemo(() => getInvoiceBalanceModifierReference(modifierReference), [modifierReference])

  const { modifierType, modifierId } = modifierData || {}

  const contentText = useMemo(() => {
    if (modifierType) {
      if (invoiceType === InvoiceType.CreditNote) {
        return t(creditnoteBalanceModifierReferenceToTKey[modifierType])
      }
      return t(invoiceBalanceModifierReferenceToTKey[modifierType])
    }
  }, [invoiceType, modifierType, t])

  const icon = useMemo(() => {
    if (modifierType) {
      return invoiceBalanceModifierReferenceToIconName[modifierType]
    }
  }, [modifierType])

  const handleClick = useCallback(() => {
    if (modifierType && modifierId) {
      const route = getInvoicePaymentPaneItemRoute(modifierType)

      if (Object.values(EmberRoute).includes(route as any)) {
        navigate(route as EmberRoute, modifierId)
      }
    }
  }, [modifierType, modifierId, navigate])

  const isFee = modifierType === InvoiceBalanceModifierReference.InvoiceLateFee

  return (
    <PaymentPane.Item
      amount={amount !== null && <Text variant="normal">{amountToDisplayValue(amount)}</Text>}
      content={
        <Text variant="small" colorVariant="secondary">
          {contentText}
        </Text>
      }
      extra={
        <Text colorVariant="secondary" variant="small">
          {entryDate && <DateFormatter value={entryDate} />}
        </Text>
      }
      icon={icon}
      onClick={handleClick}
      prefix={
        <Text color={isFee ? Color.Red : Color.Green} variant="micro">
          {isFee
            ? t('invoices.view.payment_pane.item.prefix_fee')
            : t('invoices.view.payment_pane.item.prefix_payment')}
        </Text>
      }
      withHoverEffect={withHoverEffect}
    />
  )
}
