import { css } from '@emotion/core'

import { sizeToPx } from '../constants/sizeToPx'
import { Size } from '../types/size'

export const getSizeStyles = (size: Size) => {
  const sizeValue = sizeToPx[size]

  return css`
    width: ${sizeValue};
    height: ${sizeValue};
  `
}
