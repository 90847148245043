import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { BorderRadius } from '../../enums/borderRadius'
import { Color } from '../../enums/color'
import { DefaultValue } from '../../enums/defaultValue'
import { Spacing } from '../../enums/spacing'
import { Asable } from '../../types/asable'
import { Themable } from '../../types/themable'
import { getShadow } from '../../utils/getShadow'
import { skeletonAnimation } from '../../utils/skeletonAnimation'
import { TABLE_COLOR } from './constants/tableColors'
import { SubRowSpacing } from './enums/styles'
import { TableAlignment } from './types/alignment'

interface TableWrapperProps extends Themable {
  bordered?: boolean
}

export const TableWrapper = styled.div<TableWrapperProps>`
  border-bottom-right-radius: ${BorderRadius.L};
  border-bottom-left-radius: ${BorderRadius.L};

  ${({ bordered }) =>
    bordered &&
    css`
      border: 1px solid ${Color.DayGrey};
      border-radius: ${BorderRadius.L};
      ${getShadow('raisedLight')};
    `}
`

type TableContentProps = Asable

export const TableContent = styled.div<TableContentProps>``

export const TableFooterActions = styled.section`
  display: flex;
  margin-top: ${Spacing.L};
  align-items: center;
  justify-content: space-between;
`

interface SkeletonCellMockProps {
  alignment?: TableAlignment
}

export const SkeletonCellMock = styled.span<SkeletonCellMockProps>`
  display: block;
  width: 80%;
  height: 14px;
  border-radius: ${DefaultValue.BorderRadius};
  ${skeletonAnimation()};

  ${({ alignment }) => {
    switch (alignment) {
      case 'center':
        return css`
          margin: auto;
        `
      case 'right':
        return css`
          margin-left: auto;
        `
    }
  }};
`

export const TableSubRow = styled.li`
  padding: ${Spacing.XL} ${SubRowSpacing * 2}px;
`

export const TableGroup = styled.ol`
  border-bottom-right-radius: ${BorderRadius.L};
  border-bottom-left-radius: ${BorderRadius.L};
  background-color: ${TABLE_COLOR};

  &:not(:last-of-type) {
    margin-bottom: ${Spacing.L};
    box-shadow: 0 2px 2px ${transparentize(0.9, Color.Gray90)};
  }
`
