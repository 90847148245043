import { css, SerializedStyles } from '@emotion/core'

import { FontWeight } from '../../../enums/fontWeight'
import { StyledComponent } from '../../../types/styledComponent'
import { InputWeight } from '../types/weight'

export const getFontStyle = (weight: InputWeight, selectLook: boolean, Input?: StyledComponent): SerializedStyles => {
  if (selectLook) {
    return css`
      ${Input || '&'} {
        font-weight: ${FontWeight.Medium};
      }
    `
  }

  return css`
    ${Input || '&'} {
      font-weight: ${weight === 'medium' ? FontWeight.Medium : FontWeight.Regular};
    }
  `
}
