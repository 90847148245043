import { Button, Space, Text } from '@design-system'

import random from 'lodash/random'
import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { useSegment } from '../../../../hooks'
import { getImgPath } from '../../../../utils/getImgPath'
import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { Widget } from '../../elements/Widget'
import { WidgetId } from '../../enums/widgetId'
import { UpsellVariantIds } from './enums/upsellVariantIds'
import * as Styled from './styles'
import { getUpsellVariants } from './utils/getUpsellVariants'

export const Upsell = (): ReactElement => {
  const isUpsellWidgetViewedRef = useRef(false)
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { track } = useSegment()
  const { isWidgetHidden } = useDashboardSettings()
  const { organization } = useUserOrganization()

  const isCompanyOrganizationCompanyType = organization?.companyType === 'company'

  const variants = useMemo(
    () =>
      getUpsellVariants(t).filter(
        ({ id }) => !(id === UpsellVariantIds.BookkeepingLaw && isCompanyOrganizationCompanyType),
      ),
    [isCompanyOrganizationCompanyType, t],
  )

  const randomVariantId = useMemo((): number => {
    return random(0, variants.length - 1)
  }, [variants])
  const isHidden = useMemo(() => isWidgetHidden(WidgetId.Upsell), [isWidgetHidden])

  const {
    backgroundColor,
    body,
    buttonLabel,
    heading,
    id,
    img: { src, alt },
  } = variants[randomVariantId]

  useEffect(() => {
    if (!isUpsellWidgetViewedRef.current) {
      track('xxx - hamster - dashboard basic upsell viewed', {
        context: id,
      })
      isUpsellWidgetViewedRef.current = true
    }
  }, [id, track])

  const handleCtaButtonClick = useCallback(() => {
    track('xxx - hamster - dashboard basic upsell clicked', {
      context: id,
    })
    navigate(EmberRoute.SettingsSubscriptionsPlansSelection)
  }, [navigate, track, id])

  return (
    <Widget isHidden={isHidden} backgroundColor={backgroundColor}>
      <Styled.UpsellWidgetWrapper>
        <Styled.Header>
          <Text variant="displayXS">{heading}</Text>
          <Space />
          <Styled.DescriptionWrapper>
            <Text variant="large">{body}</Text>
          </Styled.DescriptionWrapper>
        </Styled.Header>
        <Styled.Footer>
          <Button variant="secondary" onClick={handleCtaButtonClick}>
            {buttonLabel}
          </Button>
        </Styled.Footer>
        <Styled.Illustration src={getImgPath(src)} alt={alt} />
      </Styled.UpsellWidgetWrapper>
    </Widget>
  )
}
