import React, { MouseEvent, ReactNode, useCallback } from 'react'

import { Icon, IconName } from '../Icon'
import { IconButton } from '../IconButton'
import { Text } from '../Text'
import * as Styled from './styles'
import { NotificationVariant } from './types'
import { variantStyles } from './utils'

export interface NotificationProps {
  id: string
  title?: string
  message: ReactNode
  variant?: NotificationVariant
  icon?: IconName
  showIcon?: boolean
  onClick?: () => void
  onClose: (id: string) => void
  closable?: boolean
  sideActions?: ReactNode
}

export const Notification = ({
  id,
  title,
  message,
  variant = 'warning',
  icon: customIcon,
  showIcon = true,
  onClick,
  closable = true,
  onClose,
  sideActions,
}: NotificationProps) => {
  const icon = customIcon || variantStyles[variant].icon
  const color = variantStyles[variant].color

  const handleCloseClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      onClose(id)
    },
    [id, onClose],
  )

  return (
    <Styled.NotificationWrapper clickable={!!onClick} onClick={onClick} variant={variant} withCloseButton={closable}>
      <Styled.Content>
        {showIcon && (
          <Styled.IconWrapper>
            <Icon icon={icon} color={color} />
          </Styled.IconWrapper>
        )}

        <Styled.BodyContent>
          {title && (
            <Text variant="large" weight="medium" color={color}>
              {title}
            </Text>
          )}

          <Text variant="normal" color={color}>
            {message}
          </Text>

          {sideActions && <Styled.SideActions>{sideActions}</Styled.SideActions>}
        </Styled.BodyContent>
      </Styled.Content>

      {closable && (
        <Styled.CloseIconWrapper>
          <IconButton onClick={handleCloseClick} size="m" color={color} icon="xSign" />
        </Styled.CloseIconWrapper>
      )}
    </Styled.NotificationWrapper>
  )
}
