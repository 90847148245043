import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconCross = ({
  color = 'inherit',
  color2 = 'transparent',
  width = '20px',
  height = '20px',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-cross')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 205.3 205.3">
      <circle
        cx="102.65"
        cy="102.65"
        r="98"
        fill="none"
        stroke={coloring(color2)}
        strokeMiterlimit="10"
        strokeWidth="9.3"
      />
      <line
        x1="141.95"
        y1="63.34"
        x2="63.34"
        y2="141.95"
        fill="none"
        stroke={coloring(color)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="13"
      />
      <line
        x1="63.34"
        y1="63.34"
        x2="141.95"
        y2="141.95"
        fill="none"
        stroke={coloring(color)}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="13"
      />
    </svg>
  </Box>
)
