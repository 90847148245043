import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as GetLoanLogo } from '../../../assets/images/integrations/get-loan.svg'
import { useEmberRouter } from '../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../enums/emberRoute'
import { Scope } from '../../../enums/scope'
import { isAuthorized } from '../../../utils/isAuthorized'
import { IntegrationId } from '../enums/integrationId'
import { IntegrationItem } from '../types/integrationItem'

export const useGetLoanIntegration = (): IntegrationItem => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()

  const buttonHandler = useCallback(() => {
    navigate(EmberRoute.Financing)
  }, [navigate])

  const isHidden = !isAuthorized(Scope.LoansWrite)

  return {
    id: IntegrationId.GetLoan,
    name: t('integrations.card.get_loan.title'),
    description: t('integrations.card.get_loan.body'),
    readMoreLink: 'https://www.billy.dk/erhvervslaan',
    imageNode: <GetLoanLogo />,
    isHiddenLoading: false,
    isHidden,
    buttonHandler,
    buttonText: t('integrations.card.get_loan.button.activate'),
    statusLabelNode: null,
    isStatusLoading: false,
  }
}
