import React, { forwardRef, ReactElement, ReactNode } from 'react'

import { FileUpload, FileUploadRefProps } from '../../../../../components-deprecated/FileUpload'
import { ImageFile, UploadItem } from '../../../../../components-deprecated/FileUpload/types'
import * as Styled from './styles'

interface MissingInformationContentProps {
  children: ReactNode
  direction?: Styled.Direction
  disabled?: boolean
  onFileUpload?: (item: UploadItem, itemFile: ImageFile | undefined) => void
  onFileDrop?: (filesAccepted: File[], filesRejected: File[]) => void
}

export const MissingInformationContent = forwardRef<FileUploadRefProps, MissingInformationContentProps>(
  ({ disabled, children, direction = 'row', onFileUpload, onFileDrop, ...rest }, ref): ReactElement => (
    <Styled.FormContent direction={direction} {...rest}>
      {/* Dropzone */}
      {!!ref && onFileUpload && (
        <Styled.DropzoneWrapper>
          <FileUpload
            ref={ref}
            disabled={disabled}
            onItemUpload={onFileUpload}
            onFileDrop={onFileDrop}
            multipleFiles={false}
            customSubmit
            sameHeightFileList
          />
        </Styled.DropzoneWrapper>
      )}

      {/* Content */}
      <Styled.ContentWrapper>{children}</Styled.ContentWrapper>
    </Styled.FormContent>
  ),
)
