import { TableActionsWrapper } from '@components'

import React, { ReactElement } from 'react'

import { ReportsFilters, ReportsFiltersProps } from '../ReportsFilters'
import { ReportsPeriodFilters, ReportsPeriodFiltersProps } from '../ReportsPeriodFilters'
import * as Styled from './styles'

type ReportsTableActionsProps = ReportsPeriodFiltersProps & ReportsFiltersProps

export const ReportsTableActions = ({
  comparisonId,
  comparisonPeriodId,
  disabledPeriodModes,
  encodedPeriod,
  filterValues,
  hiddenFilters,
  isComparisonSelectDisabled,
  maxDate,
  minDate,
  onComparisonPeriodSelect,
  onComparisonSelect,
  onFilterChange,
  onPeriodChange,
  reportsComparisonSelectItems,
  withPeriodComparison,
}: ReportsTableActionsProps): ReactElement => {
  return (
    <TableActionsWrapper>
      <Styled.LeftActions>
        <ReportsPeriodFilters
          comparisonId={comparisonId}
          comparisonPeriodId={comparisonPeriodId}
          disabledPeriodModes={disabledPeriodModes}
          encodedPeriod={encodedPeriod}
          isComparisonSelectDisabled={isComparisonSelectDisabled}
          maxDate={maxDate}
          minDate={minDate}
          onComparisonPeriodSelect={onComparisonPeriodSelect}
          onComparisonSelect={onComparisonSelect}
          onPeriodChange={onPeriodChange}
          reportsComparisonSelectItems={reportsComparisonSelectItems}
          withPeriodComparison={withPeriodComparison}
        />
      </Styled.LeftActions>
      <Styled.RightActions>
        <ReportsFilters hiddenFilters={hiddenFilters} filterValues={filterValues} onFilterChange={onFilterChange} />
      </Styled.RightActions>
    </TableActionsWrapper>
  )
}
