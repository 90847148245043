import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchUmbrella } from './query-api'
import { Umbrella } from './types/umbrella'

interface ContextState {
  umbrella: Umbrella | undefined
  isLoading: boolean
}

const defaultValue: ContextState = {
  umbrella: {} as Umbrella,
  isLoading: false,
}

const UmbrellaContext = createContext<ContextState>(defaultValue)

interface UmbrellaContextProps {
  children?: ReactNode
  umbrellaId?: string
}

export const UmbrellaContextProvider = ({ children, umbrellaId = '' }: UmbrellaContextProps): ReactElement => {
  const { data: umbrella, isLoading } = useQuery([QueryKeys.Umbrella, umbrellaId], () => fetchUmbrella(umbrellaId), {
    enabled: !!umbrellaId,
  })

  return <UmbrellaContext.Provider value={{ umbrella: umbrella?.data, isLoading }}>{children}</UmbrellaContext.Provider>
}

// no context protection here in purpose - we don't know if we are in organization or umbrella view (TBD)
export const useUmbrella = () => useContext(UmbrellaContext)
