const TIMEOUT_TO_CHECK = 250
const MAX_ATTEMPT_COUNT = 20

export const waitForSegment = () =>
  new Promise((resolve, reject) => {
    let count = 0
    const interval = setInterval(() => {
      if (window.analytics && typeof window.analytics?.setAnonymousId === 'function') {
        clearInterval(interval)
        resolve(true)
        return
      }

      if (count >= MAX_ATTEMPT_COUNT) {
        clearInterval(interval)
        reject(new Error('Couldnt load segment'))
        return
      }

      count++
    }, TIMEOUT_TO_CHECK)
  })
