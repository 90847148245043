import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'

interface IconWrapperProps {
  uploading?: boolean
}

export const ImageWrapper = styled.div<IconWrapperProps>`
  display: flex;
  margin-bottom: ${Spacing.S};
  align-items: center;
  justify-content: center;
`

export const Footer = styled.footer<IconWrapperProps>`
  position: relative;
  z-index: 1;
  text-align: center;
`
