import { BrandProtectedComponent, TableActionsWrapper } from '@components'

import React, { ReactElement } from 'react'

import { ContactsArchivedFilter } from './elements/ContactsArchivedFilter'
import { ContactsSearch } from './elements/ContactsSearch'
import { ContactsTabs } from './elements/ContactsTabs'
import { ContactsTitle } from './elements/ContactsTitle'

export const ContactsTableActions = (): ReactElement => {
  return (
    <TableActionsWrapper>
      <ContactsTitle />
      <ContactsSearch />
      <ContactsTabs />
      <BrandProtectedComponent brand="billy">
        <ContactsArchivedFilter />
      </BrandProtectedComponent>
    </TableActionsWrapper>
  )
}
