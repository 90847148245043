import React from 'react'
import { BoxProps } from 'rebass'

import * as Icons from './paymentIcons'

export type PaymentIconProps = BoxProps & {
  width?: number | string
  height?: number | string
}

export const PaymentIcon = ({ type = 'IconVisa', ...rest }: PaymentIconProps) => {
  const Icon = Icons[type]
  return <Icon display="inline-block" {...rest} />
}
