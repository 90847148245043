import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const List = styled.ul`
  list-style: disc;
`

export const ListItem = styled.li`
  margin-bottom: ${Spacing.XS};
  margin-left: ${Spacing.L};
`
