import { Placement } from '@popperjs/core'
import React, { ReactElement, ReactNode, useCallback, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

import { Timeout } from '../../../types/timeout'
import { Color } from '../../enums/color'
import { DefaultValue } from '../../enums/defaultValue'
import { Spacing } from '../../enums/spacing'
import { useComponentToggle } from '../../hooks/useComponentToggle'
import { Asable } from '../../types/asable'
import { pxToNumber } from '../../utils/pxToNumber'
import { transitionToMiliseconds } from '../../utils/transitionToMiliseconds'
import { Text } from '../Text'
import { DisabledWrapper } from './elements/DisabledWrapper'
import * as Styled from './styles'
import { TooltipArrow } from './TooltipArrow'
import { getArrowPlacementFromPopper } from './utils/getArrowPlacementFromPopper'

const TOOLTIP_OPEN_DELAY_MS = 400
const TOOLTIP_ANIMATION_TIME_MS = transitionToMiliseconds(DefaultValue.TransitionDuration)

export interface TooltipProps extends Asable {
  children: ReactNode
  forceHidden?: boolean
  label: ReactNode
  placement?: Placement
}

export const Tooltip = ({
  as,
  children,
  forceHidden = false,
  label,
  placement = 'auto',
  ...rest
}: TooltipProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const arrowRef = useRef<HTMLDivElement>(null)
  const openTimeout = useRef<Timeout>()
  const closeTimeout = useRef<Timeout>()
  const { attributes, styles, state, forceUpdate } = usePopper(containerRef?.current, tooltipRef?.current, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowRef?.current,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, pxToNumber(Spacing.XS)],
        },
      },
    ],
  })

  const { close, open, isVisible, isRendered } = useComponentToggle({
    animationTime: TOOLTIP_ANIMATION_TIME_MS,
  })

  const resetTimeouts = useCallback(() => {
    if (openTimeout.current) {
      clearTimeout(openTimeout.current)
      openTimeout.current = undefined
    }

    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current)
      closeTimeout.current = undefined
    }
  }, [])

  const closeTooltip = useCallback(() => {
    resetTimeouts()
    // Needs to be timeouted with 0 delay to put close action in the event queue
    // This solves the case in which close action gets lost among multiple
    // mouseEnter, mouseLeave and setTimeout events, i.e. in case of rapid movements
    // of the mouse between trigerring elements (especially when trigerring element disappears and appears)
    closeTimeout.current = setTimeout(() => close(), 0)
  }, [close, resetTimeouts])

  const openWithDelay = useCallback(() => {
    forceUpdate && forceUpdate()
    open()
    resetTimeouts()
  }, [forceUpdate, open, resetTimeouts])

  useEffect(() => {
    if (forceHidden) {
      closeTooltip()
    }
  }, [forceHidden, closeTooltip])

  const handleMouseEnter = useCallback(() => {
    if (openTimeout.current) {
      return
    }

    openTimeout.current = setTimeout(openWithDelay, TOOLTIP_OPEN_DELAY_MS)
  }, [openWithDelay])

  useEffect(() => {
    return () => closeTooltip()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMouseLeave = useCallback(() => {
    closeTooltip()
  }, [closeTooltip])

  return (
    <>
      <Styled.TooltipWrapper
        as={as}
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...rest}
      >
        {children}
      </Styled.TooltipWrapper>
      {isRendered &&
        createPortal(
          <Styled.Tooltip
            ref={tooltipRef}
            style={styles.popper}
            visible={isVisible && !forceHidden}
            {...attributes.popper}
          >
            <>
              <Text color={Color.White} alignment="center">
                {label}
              </Text>
              <TooltipArrow
                ref={arrowRef}
                placement={getArrowPlacementFromPopper(state?.placement)}
                style={styles.arrow}
                {...attributes.arrow}
                {...attributes.popper}
              />
            </>
          </Styled.Tooltip>,
          document.body,
        )}
    </>
  )
}

Tooltip.DisabledWrapper = DisabledWrapper
