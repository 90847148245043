import { Flex, ModuleLayout, Spacing } from '@design-system'

import { useCallback } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { InvoicesRoute } from '../../enums/invoicesRoute'
import { InvoiceUrlParams } from '../../types/invoiceUrlParams'
import { InvoiceContextProvider } from './contexts/invoiceContext'
import { InvoiceHtmlPreview } from './elements/InvoiceHtmlPreview'
import { InvoiceTitle } from './elements/InvoiceTitle'
import { InvoiceViewActions } from './elements/InvoiceViewActions'
import { InvoiceViewSidebar } from './elements/InvoiceViewSidebar'
import * as Styled from './styles'

export const InvoiceView = () => {
  const { documentType, invoiceId } = useParams<InvoiceUrlParams>()
  const history = useHistory()
  const location = useLocation()

  const handleBackButtonClick = useCallback(() => {
    history.push(`${InvoicesRoute.List}${location.search}`)
  }, [history, location.search])

  return (
    <InvoiceContextProvider documentType={documentType} invoiceId={invoiceId}>
      <ModuleLayout
        onBackButtonClick={handleBackButtonClick}
        sideActions={<InvoiceViewActions />}
        title={<InvoiceTitle />}
        withBackButton
      >
        <Styled.InvoiceViewWrapper>
          <Flex gap={Spacing.XL}>
            <Flex.Item>
              <InvoiceHtmlPreview />
            </Flex.Item>
            <Flex.Item>
              <InvoiceViewSidebar />
            </Flex.Item>
          </Flex>
        </Styled.InvoiceViewWrapper>
      </ModuleLayout>
    </InvoiceContextProvider>
  )
}
