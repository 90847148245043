import { NavItemState } from '../types/navItemState'

interface ItemStateProps {
  expanded?: boolean
  focused?: boolean
  hovered?: boolean
  readonly?: boolean
  selected?: boolean
}

export const getItemState = ({ selected, focused, expanded, hovered, readonly }: ItemStateProps): NavItemState => {
  if (selected) {
    return 'selected'
  }

  if (hovered && !readonly) {
    return 'hovered'
  }

  if (expanded) {
    return 'expanded'
  }

  if (focused) {
    return 'focused'
  }

  return 'default'
}
