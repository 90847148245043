import { TFunction } from 'i18next'
import { object, SchemaOf, string } from 'yup'

export const getLoginFormDefaultValues = (email: string) => {
  return {
    email,
    password: '',
  }
}

export interface LoginForm {
  email: string
  password: string
}

export type LoginFormSchema = SchemaOf<LoginForm>

export const getValidationSchema = (t: TFunction): LoginFormSchema => {
  return object({
    email: string().email().required(t('organization_invitation.form.errors.field_required')),
    password: string().required(t('organization_invitation.form.errors.field_required')),
  })
}
