import { Space, Text } from '@design-system'

import { t } from 'i18next'
import React, { ReactElement } from 'react'

import { CardIssuerLogo } from '../../components-deprecated/Icons/CardIssuerLogo'
import { formatDate } from '../../utils'
import { getImageClassName } from '../../utils/getClassName'
import * as Styled from './styles'

export interface CreditCardProps {
  expirationDate?: string | Date
  horizontal?: boolean
  isInvalid: boolean
  label?: string
  type?: string
}

const CARD_HIDDEN_NUMBERS = '•••• •••• ••••'

export const CreditCard = ({
  expirationDate = '',
  horizontal = false,
  isInvalid,
  label = '',
  type = 'visa',
}: CreditCardProps): ReactElement => (
  <Styled.CreditCardWrapper isInvalid={isInvalid} horizontal={horizontal}>
    <Styled.IssuerLogoWrapper className={getImageClassName()} horizontal={horizontal}>
      <CardIssuerLogo cardType={type} height="28px" width="auto" />
    </Styled.IssuerLogoWrapper>
    <Text variant={horizontal ? 'normal' : undefined}>
      {label.length <= 4 ? (
        <>
          ${CARD_HIDDEN_NUMBERS} {label}
        </>
      ) : (
        label
      )}
    </Text>
    {horizontal && <Space size="xxxxl" horizontal />}
    <Text>
      {horizontal && `${t('organization_subscription.expiration')} `}
      {formatDate(expirationDate, 'MM/yy')}
    </Text>
  </Styled.CreditCardWrapper>
)
