import { CurrencySelect, PaymentMethodSelect } from '@components'
import { FormItemsGroup, Space, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../hooks'
import { useAddCurrencyDefaultPaymentMethodModal } from '../../contexts/addCurrencyDefaultPaymentMethodModalContext'
import { AddCurrencyDefaultPaymentMethodFormSchema } from '../../utils/formData'

export const ModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<AddCurrencyDefaultPaymentMethodFormSchema>()
  const { Form } = useAddCurrencyDefaultPaymentMethodModal()

  return (
    <Form>
      <Text>{t('settings.organization.invoice.currency_default_payment_methods_settings.add_modal.paragraph')}</Text>
      <Space />
      <FormItemsGroup itemsInRow={2}>
        <FormItem
          name="currencyCode"
          render={({ field: { value, onChange, ...rest } }) => (
            <CurrencySelect
              defaultSelectedId={value}
              dropdownSize="fitTrigger"
              onSelect={(id) => onChange(id)}
              placeholder={t(
                'settings.organization.invoice.currency_default_payment_methods_settings.add_modal.currency_select_placeholder',
              )}
              selectedId={value}
              {...rest}
            />
          )}
        />
        <FormItem
          name="paymentMethodId"
          render={({ field: { value, onChange, ...rest } }) => (
            <PaymentMethodSelect
              defaultSelectedId={value}
              dropdownSize="fitTrigger"
              onSelect={(id) => onChange(id)}
              placeholder={t(
                'settings.organization.invoice.currency_default_payment_methods_settings.add_modal.payment_method_select_placeholder',
              )}
              selectedId={value}
              {...rest}
            />
          )}
        />
      </FormItemsGroup>
    </Form>
  )
}
