import styled from '@emotion/styled'

export const MenuFooterWrapper = styled.footer``

export const FooterActions = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`

interface FooterActionsItemProps {
  moveToRight?: boolean
}

export const FooterActionsItem = styled.li<FooterActionsItemProps>`
  display: flex;
  justify-content: center;
  ${({ moveToRight }) => moveToRight && 'grid-column-start: 3'};

  &:first-of-type {
    justify-content: flex-start;
  }
  &:last-of-type {
    justify-content: flex-end;
  }
`
