import React, { forwardRef, ReactElement, useState } from 'react'
import { FileError, FileRejection } from 'react-dropzone'

import { Dropzone } from '../Dropzone'
import { FilesUploadList, FileUploadRefProps } from './FilesUploadList'
import { ImageFile, UploadItem } from './types'

const FILE_SIZE_LIMIT = 2097152 // 2 Mb

const decorateFilesWithPreview = (files: File[], errors?: FileError[]) =>
  files.map((file) => Object.assign(file, { preview: URL.createObjectURL(file), errors }))

const flatFilesRejections = (rejections: FileRejection[]) =>
  rejections.map((rejection) => Object.assign(rejection.file, { errors: rejection.errors }))

type FileUploadProps = {
  className?: string
  customSubmit?: boolean
  disabled?: boolean
  multipleFiles?: boolean
  onItemUpload: (item: UploadItem, itemFile: ImageFile | undefined) => void
  onUploadFinish?: (isAnyFileUploaded: boolean) => void
  sameHeightFileList?: boolean
  scrollQuerySelector?: string
  withAccountSelection?: boolean
  onFileDrop?: (filesAccepted: File[], filesRejected: File[]) => void
}

export const FileUpload = forwardRef<FileUploadRefProps, FileUploadProps>(
  (
    {
      className,
      disabled,
      onUploadFinish,
      scrollQuerySelector,
      withAccountSelection,
      onItemUpload,
      customSubmit,
      sameHeightFileList = false,
      multipleFiles = true,
      onFileDrop,
    },
    ref,
  ): ReactElement => {
    const [files, setFiles] = useState<ImageFile[]>()

    const handleDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const filesAccepted = decorateFilesWithPreview(acceptedFiles)
      const filesRejected = flatFilesRejections(fileRejections)

      setFiles([...filesAccepted, ...filesRejected])

      onFileDrop && onFileDrop(filesAccepted, filesRejected)
    }

    const handleItemRemove = (itemsLeft: UploadItem[]) => {
      if (!itemsLeft.length) {
        setFiles([])
      }
    }

    return (
      <>
        {files?.length ? (
          <FilesUploadList
            ref={ref}
            className={className}
            files={files}
            onItemRemove={handleItemRemove}
            onUploadFinish={onUploadFinish}
            withAccountSelection={withAccountSelection}
            onItemUpload={onItemUpload}
            sameHeightFileList={sameHeightFileList}
            customSubmit={customSubmit}
            disabled={disabled}
          />
        ) : (
          <Dropzone
            disabled={disabled}
            onDrop={handleDrop}
            maxSize={FILE_SIZE_LIMIT}
            scrollQuerySelector={scrollQuerySelector}
            allowMultipleFileUpload={multipleFiles}
          />
        )}
      </>
    )
  },
)
