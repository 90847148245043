import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconExit = ({
  color = 'inherit',
  color2,
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-exit')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={coloring(color)}
        d="M31,26c0,1.657-1.343,3-3,3H17c-1.657,0-3-1.343-3-3V6c0-1.657,1.343-3,3-3h11c1.657,0,3,1.343,3,3V26z"
      ></path>
      <path
        fill={coloring(bg)}
        d="M28,30H17c-2.206,0-4-1.794-4-4V6c0-2.206,1.794-4,4-4h11c2.206,0,4,1.794,4,4v20C32,28.206,30.206,30,28,30 z M17,4c-1.103,0-2,0.897-2,2v20c0,1.103,0.897,2,2,2h11c1.103,0,2-0.897,2-2V6c0-1.103-0.897-2-2-2H17z"
      ></path>
      <polygon fill={coloring(color2 || color)} points="1,16 9.999,8 10,12 17,12 17,20 10,20 9.999,24 "></polygon>
      <path
        fill={coloring(bg)}
        d="M9.999,25c-0.24,0-0.478-0.087-0.665-0.253l-8.999-8C0.122,16.558,0,16.286,0,16s0.122-0.558,0.335-0.748 l8.999-8c0.294-0.262,0.716-0.326,1.074-0.165c0.359,0.161,0.59,0.518,0.59,0.912l0,3h6c0.553,0,1,0.448,1,1v8c0,0.553-0.447,1-1,1 h-6l0,3c0,0.394-0.231,0.751-0.59,0.912C10.277,24.972,10.138,25,9.999,25z M2.505,16L9,21.773L9,20c0-0.553,0.448-1,1-1h6v-6h-6 c-0.552,0-1-0.448-1-1l0-1.773L2.505,16z"
      ></path>
    </svg>
  </Box>
)
