import { useEffect, useMemo, useRef, useState } from 'react'

import { getElementFontSize } from '../utils/getElementFontSize'
import { scaleFontSizeToContainer } from '../utils/scaleFontSizeToContainer'

export const useScaleFontSizes = (withScaling: boolean) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const valueRef = useRef<HTMLDivElement>(null)
  const currencyRef = useRef<HTMLDivElement>(null)

  const [containerWidth, setContainerWidth] = useState<number>()
  const [textWidth, setTextWidth] = useState<number>()

  useEffect(() => {
    if (containerRef.current && !containerWidth) {
      setContainerWidth(containerRef.current.offsetWidth)
    }

    if (valueRef.current && currencyRef.current && !textWidth) {
      setTextWidth(valueRef.current.offsetWidth + currencyRef.current.offsetWidth)
    }
  }, [containerRef, valueRef, currencyRef, containerWidth, textWidth])

  const originalValueFontSize = useMemo(() => getElementFontSize(valueRef.current), [])
  const originalCurrencyFontSize = useMemo(() => getElementFontSize(currencyRef.current), [])

  const valueFontSize = useMemo(
    () =>
      withScaling ? scaleFontSizeToContainer(originalValueFontSize, containerWidth, textWidth) : originalValueFontSize,
    [withScaling, originalValueFontSize, containerWidth, textWidth],
  )
  const currencyFontSize = useMemo(
    () =>
      withScaling
        ? scaleFontSizeToContainer(originalCurrencyFontSize, containerWidth, textWidth)
        : originalCurrencyFontSize,
    [withScaling, originalCurrencyFontSize, containerWidth, textWidth],
  )

  return { valueFontSize, currencyFontSize, containerRef, valueRef, currencyRef }
}
