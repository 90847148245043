import uniqueId from 'lodash/uniqueId'
import React, { Children, cloneElement, forwardRef, isValidElement, ReactElement, ReactNode, Ref, useMemo } from 'react'

import { Alignment } from '../../types/alignment'
import { useForm } from '../Form'
import { Label } from '../Label'
import { ErrorInfo } from './elements/ErrorInfo'
import { ExtraInfo } from './elements/ExtraInfo'
import * as Styled from './styles'

export interface FormItemDecorated {
  id: string
  error: boolean
  name?: string
}

export interface FormItemProps {
  children?: (({ id }: FormItemDecorated) => JSX.Element) | ReactNode
  error?: ReactNode
  extra?: ReactNode
  itemColumnEnd?: number
  itemColumnStart?: number
  label?: ReactNode
  labelAlignment?: Alignment
  name?: string
}

export const FormItem = forwardRef(
  (
    { children, error, extra, itemColumnEnd, itemColumnStart, label, labelAlignment = 'left', name }: FormItemProps,
    forwardedRef: Ref<HTMLDivElement>,
  ): ReactElement => {
    const { name: formName } = useForm()

    const id = useMemo(() => (name ? `${formName}-${name}` : `${formName}-${uniqueId('item-')}`), [formName, name])

    const childrenDecorated = useMemo(() => {
      if (typeof children === 'function') {
        return children({
          id,
          error: !!error,
          name,
        })
      }

      return Children.map(children, (child) => {
        if (isValidElement<FormItemDecorated>(child)) {
          return cloneElement(child, {
            id,
            error: !!error,
            name,
          })
        }

        return child
      })
    }, [children, error, id, name])

    return (
      <Styled.FormItemWrapper ref={forwardedRef} itemColumnEnd={itemColumnEnd} itemColumnStart={itemColumnStart}>
        {label && (
          <Styled.LabelWrapper alignment={labelAlignment}>
            <Label id={id}>{label}</Label>
          </Styled.LabelWrapper>
        )}
        {childrenDecorated}
        {extra && <ExtraInfo>{extra}</ExtraInfo>}
        {error && <ErrorInfo>{error}</ErrorInfo>}
      </Styled.FormItemWrapper>
    )
  },
)
