import { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface SidePanelBodyProps {
  children?: ReactNode
}

export const SidePanelBody = ({ children }: SidePanelBodyProps): ReactElement => (
  <Styled.SidePanelBodyWrapper>{children}</Styled.SidePanelBodyWrapper>
)
