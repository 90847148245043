import { checkPropsEquality } from '../../../hooks/checkPropsEquality'
import { FilesPreviewContentProps } from '../elements/FilesPreviewContent'
import { checkFilesEquality } from './checkFilesEquality'

const PROPS_TO_CHECK = [
  'asType',
  'loading',
  'withDownloadButton',
  'fitToHeight',
  'fitToWidth',
  'withFilename',
  'withModalZoom',
  'withLazyLoad',
  'withNavigation',
  'withRotate',
] as (keyof FilesPreviewContentProps)[]

export const arePropsEqual = (
  prevProps: Readonly<FilesPreviewContentProps>,
  nextProps: Readonly<FilesPreviewContentProps>,
) => {
  const areFilesEqual = checkFilesEquality(prevProps.files, nextProps.files)
  const isRestEqual = checkPropsEquality(prevProps, nextProps, PROPS_TO_CHECK)

  return areFilesEqual && isRestEqual
}
