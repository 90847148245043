import { Badge, TableCellContent, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../../../../../enums/emberRoute'
import { ModalId } from '../../../../../../../../../../enums/modalId'
import { Scope } from '../../../../../../../../../../enums/scope'
import { useIsBasicPlan } from '../../../../../../../../../../hooks/useIsBasicPlan'
import { isAuthorized } from '../../../../../../../../../../utils/isAuthorized'
import { InvoiceTemplateTableRow } from '../../../../types/invoiceTemplateTableRow'
import * as Styled from './styles'

export const TableInvoiceTemplateName = ({
  invoiceTemplateId,
  isDefaultInvoiceTemplate,
  invoiceTemplateName,
}: Omit<InvoiceTemplateTableRow, 'invoiceTemplate' | 'id'>): ReactElement => {
  const { t } = useTranslation()
  const { isBasicPlan } = useIsBasicPlan()
  const { open: openUpgradeSubscriptionModal } = useModal(ModalId.UpgradeSubscriptionModal)
  const { navigate } = useEmberRouter()

  const shouldShowUpgradeSubscriptionModal = isBasicPlan || !isAuthorized(Scope.InvoiceDesignerWrite)

  const handleCellClick = useCallback(() => {
    if (shouldShowUpgradeSubscriptionModal) {
      openUpgradeSubscriptionModal()
      return
    }

    navigate(EmberRoute.InvoiceTemplate, invoiceTemplateId)
  }, [shouldShowUpgradeSubscriptionModal, navigate, invoiceTemplateId, openUpgradeSubscriptionModal])

  return (
    <TableCellContent weight="medium" truncate>
      <Styled.NameAndBadgeWrapper onClick={handleCellClick}>
        {invoiceTemplateName}
        {isDefaultInvoiceTemplate && (
          <Badge>{t('settings.organization.invoice.templates_settings.table.template_name.default')}</Badge>
        )}
      </Styled.NameAndBadgeWrapper>
    </TableCellContent>
  )
}
