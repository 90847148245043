import { SettingsSection } from '@components'
import { Button, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { AddCurrencyDefaultPaymentMethodModal } from './elements/AddCurrencyDefaultPaymentMethodModal'
import { CurrencyDefaultPaymentMethodsTable } from './elements/CurrencyDefaultPaymentMethodsTable'

export const CurrencyDefaultPaymentMethodsSection = (): ReactElement => {
  const { t } = useTranslation()
  const { open: openAddCurrencyDefaultPaymentMethodModal } = useModal(ModalId.AddCurrencyDefaultPaymentMethodModal)

  const handleAddCurrencyDefaultPaymentMethodButtonClick = useCallback(() => {
    openAddCurrencyDefaultPaymentMethodModal()
  }, [openAddCurrencyDefaultPaymentMethodModal])

  return (
    <SettingsSection>
      <SettingsSection.Content
        sideActions={
          <Button icon="plusCircle" onClick={handleAddCurrencyDefaultPaymentMethodButtonClick} variant="secondary">
            {t('settings.organization.invoice.currency_default_payment_methods_settings.add_currency_default_button')}
          </Button>
        }
        subtitle={t('settings.organization.invoice.currency_default_payment_methods_settings.subtitle')}
        title={t('settings.organization.invoice.currency_default_payment_methods_settings.title')}
      >
        <CurrencyDefaultPaymentMethodsTable />
      </SettingsSection.Content>
      <AddCurrencyDefaultPaymentMethodModal />
    </SettingsSection>
  )
}
