import { Translate } from '@components'
import {
  CellValue,
  InternalAccessor,
  NavItem,
  Space,
  Table,
  TableCellContent,
  TableColumn,
  TableStaticActions,
} from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useRemoveUser } from '../../../../contexts/removeUserContext'
import { useUsers } from '../../../../hooks/useUsers'
import { RoleCell } from './elements/RoleCell'
import { TableUserName } from './elements/TableUserName'
import { UsersTableAccessors } from './enums/usersTableAccessors'
import { UsersTableStaticActionType } from './enums/usersTableStaticActionType'
import { UserName as UserNameType } from './types/userName'
import { UsersTableRow } from './types/usersTableRow'

export const UsersTable = (): ReactElement => {
  const { t } = useTranslation()
  const { data: usersData, isLoading } = useUsers()
  const { removeUser } = useRemoveUser()

  const staticActionsItems: NavItem[] = useMemo(
    () => [
      {
        children: t('settings.organization.access_tokens_table.actions.delete'),
        iconLeft: 'trash',
        id: 'delete',
        value: UsersTableStaticActionType.Delete,
      },
    ],
    [t],
  )

  const columns: TableColumn<UsersTableRow>[] = useMemo(
    () => [
      {
        accessor: UsersTableAccessors.Name,
        alignment: 'left',
        Cell: ({ value: props }: CellValue<UserNameType>) => <TableUserName {...props} />,
        Header: <Translate value="name" />,
      },
      {
        accessor: UsersTableAccessors.Email,
        alignment: 'left',
        Cell: ({ value: email }: CellValue<string>) => <TableCellContent truncate>{email}</TableCellContent>,
        Header: <Translate value="email" />,
      },
      {
        accessor: UsersTableAccessors.Role,
        alignment: 'left',
        Cell: ({ data, row }) => {
          const values = data[row.id] as UsersTableRow
          const { name, role, id, email } = values

          return (
            <RoleCell
              isInvitationPending={name.isPending}
              isOwner={name.isOwner}
              role={role}
              userEmail={email}
              userId={id}
            />
          )
        },
        Header: <Translate value="role" />,
      },
      {
        Header: '',
        accessor: InternalAccessor.StaticActions,
        Cell: ({ row, data }) => {
          const { user } = data[row.id] as UsersTableRow

          if (user.isOwner) {
            return <Space size="xxl" horizontal />
          }

          return (
            <TableStaticActions
              items={staticActionsItems}
              onSelect={() => {
                removeUser(user)
              }}
            />
          )
        },
      },
    ],
    [removeUser, staticActionsItems],
  )

  const tableData = useMemo(() => {
    if (isLoading) {
      return []
    }

    return usersData.map((user) => {
      const { email, id, isOwner, isPending, name } = user
      return {
        [UsersTableAccessors.Name]: { name, isOwner, isPending },
        [UsersTableAccessors.Email]: email || '',
        [UsersTableAccessors.Role]: user.userRole,
        [InternalAccessor.StaticActions]: true,
        user,
        id,
      }
    })
  }, [usersData, isLoading])

  return <Table columns={columns} data={tableData} isLoading={isLoading} skeletonItemsPerPage={5} />
}
