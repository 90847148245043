import { FormItemsGroup as FormItemsGroupComponent, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const DeductionComponentWrapper = styled.div`
  padding-top: ${Spacing.S};
  padding-bottom: ${Spacing.XXS};
`
export const FormItemsGroup = styled(FormItemsGroupComponent)`
  align-items: center;
  text-align: center;
`
