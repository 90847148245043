import styled from '@emotion/styled'
import React, { ReactElement, ReactNode } from 'react'
import { Link as LinkRouter } from 'react-router-dom'

import { TableRow } from './types'

const Link = styled(LinkRouter)`
  display: contents;
`

type TableCellWrapperProps<T = TableRow> = {
  row: T
  children: ReactNode
}

export const TableCellWrapper = ({ children, row }: TableCellWrapperProps): ReactElement => {
  if (row.linkTo) {
    return <Link to={row.linkTo}>{children}</Link>
  }

  return <>{children}</>
}
