import { Account, SelectAccountProps, SelectorAccountType } from '../types'

let id = 0

export const getFilteredAccounts = (accounts?: Account[], props?: SelectAccountProps) => {
  const { accountId, isBankAccount, isArchived = false, isPaymentEnabled, natures = [] } = props || {}

  let heading = ''
  return (accounts || [])
    .filter((account: Account) => {
      if (accountId !== undefined && accountId !== account.id) {
        return false
      }
      if (isBankAccount !== undefined && isBankAccount !== account.isBankAccount) {
        return false
      }
      if (isPaymentEnabled !== undefined && isPaymentEnabled !== account.isPaymentEnabled) {
        return false
      }
      if (isArchived !== undefined && isArchived !== account.isArchived) {
        return false
      }
      if (natures.length && !natures.includes(account.natureId)) {
        return false
      }

      return true
    })
    .map((account: Account) => ({
      ...account,
      text: `${account.accountNo ? account.accountNo + ' - ' : ''} ${account.name}: ${account.description}`,
    }))
    .reduce((processedAccounts: SelectorAccountType[], account) => {
      if (account.group && heading !== account.group.name) {
        heading = account.group.name || ''
        return [
          ...processedAccounts,
          {
            id: (id++).toString(),
            text: heading,
            isHeading: true,
          },
          account,
        ] as SelectorAccountType[]
      }
      return [...processedAccounts, account]
    }, [])
}
