import { StatusFormatter } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceStatus as InvoiceStatusEnum } from '../../../../../../enums/invoiceStatus'
import { invoiceStatusToTitleKey } from '../../../../constants/invoiceStatusToTitleKey'
import { invoiceStatusToStatusFormatterVariant } from './utils/invoiceStatusToStatusFormatterVariant'

interface InvoiceStatusProps {
  status: InvoiceStatusEnum
}

export const InvoiceStatus = ({ status }: InvoiceStatusProps): ReactElement => {
  const { t } = useTranslation()

  const variant = invoiceStatusToStatusFormatterVariant[status]
  const title = invoiceStatusToTitleKey[status]

  return <StatusFormatter variant={variant} title={t(title)} />
}
