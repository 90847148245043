import cc from 'classcat'
import uniqueId from 'lodash/uniqueId'
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useCheckboxGroup } from '../CheckboxGroup'
import { useForm } from '../Form'
import { CheckIcon } from './elements/CheckIcon'
import { IndeterminateIcon } from './elements/IndeterminateIcon'
import * as Styled from './styles'
import { Size } from './types/size'

export interface CheckboxProps {
  autoFocus?: boolean
  checked?: boolean
  children?: ReactNode
  className?: string
  defaultChecked?: boolean
  disabled?: boolean
  error?: boolean
  indeterminate?: boolean
  name?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  size?: Size
  value?: string
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      autoFocus = false,
      checked,
      children,
      className,
      defaultChecked,
      disabled = false,
      error = false,
      indeterminate = false,
      name,
      onChange,
      size = 'm',
      value = '',
      ...rest
    },
    forwardedRef,
  ) => {
    const {
      disabled: groupDisabled,
      name: groupName,
      onChange: onGroupChange,
      value: groupValue,
    } = useCheckboxGroup() || {}
    const [checkedValue, setCheckedValue] = useState<boolean>(
      defaultChecked ?? (checked || groupValue?.includes(value) || false),
    )
    const { name: formName } = useForm()
    const nativeCheckboxRef = useRef<HTMLInputElement>(null)

    const id = useMemo(() => (name ? `${formName}-${name}` : `${formName}-${uniqueId('checkbox-')}`), [formName, name])
    const checkboxGroupName = useMemo(() => `${formName}-${groupName || name}`, [formName, groupName, name])
    const isChecked = !!checkedValue || indeterminate
    const isDisabled = !!(disabled ?? groupDisabled)

    // Functions

    const handleValueChange = useCallback((newCheckedValue: boolean) => {
      setCheckedValue(newCheckedValue)
    }, [])

    const handleCheckboxChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
          return
        }

        setCheckedValue(event.currentTarget.checked)
        onGroupChange && onGroupChange(event)
        onChange && onChange(event)
      },
      [disabled, onChange, onGroupChange],
    )

    const renderControlIcon = useCallback(() => {
      if (!isChecked) {
        return null
      }

      return indeterminate ? <IndeterminateIcon /> : <CheckIcon />
    }, [indeterminate, isChecked])

    // Mutations

    useEffect(() => {
      if (autoFocus) {
        nativeCheckboxRef.current?.focus()
      }
    }, [autoFocus])

    useEffect(() => {
      if (checked !== undefined) {
        handleValueChange(checked)
      }
    }, [checked, handleValueChange])

    // Hooks utils

    useImperativeHandle(forwardedRef, () => nativeCheckboxRef?.current as HTMLInputElement)

    return (
      <Styled.CheckboxWrapper
        checked={isChecked}
        className={cc(['ds-checkbox', className, { disabled: isDisabled, checked: isChecked }])}
        disabled={isDisabled}
        error={error}
        indeterminate={indeterminate}
        data-testid="checkbox-wrapper"
      >
        <Styled.NativeCheckbox
          {...rest}
          checked={checkedValue}
          disabled={isDisabled}
          id={id}
          name={checkboxGroupName}
          onChange={handleCheckboxChange}
          ref={nativeCheckboxRef}
          value={value}
          type="checkbox"
        />
        <Styled.Control
          className="ds-checkbox-control"
          size={size}
          withTopOffset={!!children}
          data-testid="checkbox-control"
        >
          <Styled.IconWrapper>{renderControlIcon()}</Styled.IconWrapper>
        </Styled.Control>

        {children && (
          <Styled.Label id={id} selectable={false}>
            {children}
          </Styled.Label>
        )}
      </Styled.CheckboxWrapper>
    )
  },
)
