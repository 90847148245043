import { BasePlacement } from '@popperjs/core'
import React, { forwardRef, HTMLAttributes, ReactElement, Ref } from 'react'

import { ReactComponent as ArrowDown } from '../../../../assets/images/tooltip/arrow-down.svg'
import { ReactComponent as ArrowLeft } from '../../../../assets/images/tooltip/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../../../assets/images/tooltip/arrow-right.svg'
import { ReactComponent as ArrowUp } from '../../../../assets/images/tooltip/arrow-up.svg'
import * as Styled from './styles'

const placementToArrowIcon: Record<BasePlacement, any> = {
  left: ArrowRight,
  right: ArrowLeft,
  top: ArrowDown,
  bottom: ArrowUp,
}

interface TooltipArrowProps extends HTMLAttributes<HTMLDivElement> {
  placement: BasePlacement
}

export const TooltipArrow = forwardRef(
  ({ placement, ...rest }: TooltipArrowProps, ref: Ref<HTMLDivElement>): ReactElement => {
    const ArrowIcon = placementToArrowIcon[placement]
    return (
      <Styled.Arrow ref={ref} placement={placement} {...rest}>
        <ArrowIcon />
      </Styled.Arrow>
    )
  },
)
