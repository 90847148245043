import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { BorderRadius } from '../../../../enums/borderRadius'
import { Color } from '../../../../enums/color'
import { zIndex } from '../../../../utils/zIndex'
import { getHeaderPadding } from '../../utils/getHeaderPadding'

interface TableHeaderWrapperProps {
  sticky?: boolean
}

export const TableHeaderWrapper = styled.div<TableHeaderWrapperProps>`
  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: ${zIndex.TableStickyHeader};
    `}
`

interface TableHeaderProps {
  bordered?: boolean
  expandable?: boolean
  noContent?: boolean
  selectable?: boolean
}

export const TableHeader = styled.header<TableHeaderProps>`
  height: 56px;
  position: relative;
  z-index: ${zIndex.TableHeader};
  border-bottom: 1px solid ${Color.Gray30};
  border-top-right-radius: ${BorderRadius.L};
  border-top-left-radius: ${BorderRadius.L};
  background-color: ${Color.White};
  align-items: center;
  color: ${Color.DeepSeaGreen};
  white-space: nowrap;
  ${({ expandable, selectable }) => getHeaderPadding(selectable, expandable)};

  ${({ noContent, bordered }) =>
    noContent &&
    !bordered &&
    css`
      border-top-right-radius: ${BorderRadius.L};
      border-top-left-radius: ${BorderRadius.L};
    `}
`
