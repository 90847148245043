import React, { ReactElement, SVGAttributes } from 'react'

import { useTheme } from '../../hooks/useTheme'
import { ColorOrCurrent } from '../../types/colorOrCurrent'
import { iconToComponent } from './iconToComponent'
import { IconSize } from './types'
import { getIconStyles } from './utils/getIconStyles'

export const iconSizeToPx: Record<IconSize, string> = {
  large: '24px',
  default: '16px',
  small: '12px',
}

type IconSVGProps = Omit<SVGAttributes<unknown>, 'width' | 'height'>

export type IconName = keyof typeof iconToComponent | ReactElement<IconSVGProps>

export interface IconProps extends IconSVGProps {
  color?: ColorOrCurrent
  icon: IconName
  size?: IconSize
}

export const Icon = ({ size = 'default', color, icon, ...rest }: IconProps): ReactElement => {
  const { ds } = useTheme()
  const IconComponent = (typeof icon === 'string' ? iconToComponent[icon] : icon) as React.FunctionComponent
  const sizePx = iconSizeToPx[size]
  const iconCss = getIconStyles(sizePx, color || ds.colors.base.icon)

  return <IconComponent role="icon" aria-label={icon} css={iconCss} {...rest} />
}
