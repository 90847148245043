import { useMutation } from 'react-query'

import { CustomEvent } from '../../../../enums/customEvent'
import { dispatchCustomEvent } from '../../../../utils/customEvent'
import { setMultipleOrganizationSettings } from '../query-api'
import { OrganizationSettings } from '../types/organizationSettings'

interface UseUpdateMultipleOrganizationSettingsProps {
  onError?: () => void
  onSuccess?: () => void
}

export const useUpdateMultipleOrganizationSettings = (props?: UseUpdateMultipleOrganizationSettingsProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate: update,
    mutateAsync: updateAsync,
    ...rest
  } = useMutation(setMultipleOrganizationSettings, {
    onSuccess: (data) => {
      const updateOrganizationSettingsResponseData = data?.settings

      onSuccess?.()

      dispatchCustomEvent<Partial<OrganizationSettings>>(
        CustomEvent.OrganizationSettingsUpdatedInReactApp,
        updateOrganizationSettingsResponseData,
      )
    },
    onError,
  })

  return { update, updateAsync, ...rest }
}
