import sortBy from 'lodash/sortBy'

import { Account } from '@modules-deprecated/app/accounts/types'
import { BankConnection as BankConnectionType } from '@modules-deprecated/bankReconciliation/types'

export const getOldestBankConnection = (
  bankAccounts: Account[],
  bankConnections?: BankConnectionType[],
): BankConnectionType | undefined => {
  const bankConnectionDataSorted = sortBy(bankConnections, 'createdTime')

  for (const bankConnection of bankConnectionDataSorted) {
    const account = bankAccounts.find((account) => bankConnection.accountId === account.id)

    if (account) {
      return bankConnection
    }
  }

  return undefined
}
