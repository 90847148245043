import { Color, PaneAmount, PaneItemChild, PaymentPaneProps, Text } from '@design-system'

import { TFunction } from 'i18next'
import React from 'react'

import { BillStatus } from '../../../enums/billStatus'
import { Bill } from '../../../types/bill'
import { getBillStatus } from '../../../utils/getBillStatus'
import { billStatusToPaymentPaneStatus } from '../constants/billStatusToPaymentPaneStatus'
import { BillPaymentPaneExtra } from '../elements/BillPaymentPaneExtra'
import { BillPaymentPanePrefix } from '../elements/BillPaymentPanePrefix'

export const getBillPaymentPaneData = (t: TFunction, billData?: Bill, isLoading?: boolean): PaymentPaneProps => {
  if (!billData || isLoading) {
    return { isLoading }
  }

  const { amount, balance, balanceModifiers, dueDate, entryDate, isPaid, state, tax } = billData

  const billStatus = getBillStatus({
    amount,
    balance,
    dueDate: dueDate ? new Date(dueDate) : undefined,
    isPaid,
    normalizePartiallyPaid: true,
    state,
    tax,
  })

  const modifiersCount = balanceModifiers?.length
  const isPaidOrDraftBillStatus = billStatus === BillStatus.Paid || billStatus === BillStatus.Draft
  const paidDate = modifiersCount ? balanceModifiers[0].entryDate : entryDate
  const amountColor = billStatus === BillStatus.Overdue || billStatus === BillStatus.Unpaid ? Color.Red : Color.Gray50

  return {
    amount: (
      <PaneItemChild>
        <PaneAmount amount={balance} color={amountColor} />
      </PaneItemChild>
    ),
    collapsible: !!modifiersCount,
    content: !!modifiersCount && (
      <Text variant="small">{t('bills.view.payment_pane.content.payment', { count: modifiersCount })}</Text>
    ),
    extra: (
      <PaneItemChild>
        <BillPaymentPaneExtra date={isPaidOrDraftBillStatus ? paidDate : dueDate} status={billStatus} />
      </PaneItemChild>
    ),
    icon: 'exclamationMarkCircle',
    prefix: <BillPaymentPanePrefix status={billStatus} />,
    status: billStatusToPaymentPaneStatus[billStatus],
  }
}
