import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { BankIntegrationContextProvider } from '@views/bankIntegration/contexts/bankIntegrationContext'
import { RenewBankConnectionButton } from '@views/bankIntegration/elements/RenewBankConnectionButton/RenewBankConnectionButton'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'

class BankRenewConnectionButtonComponent extends DOMModel {
  @byAttrVal organizationId: string
  @byAttrVal accountid: string
  @registerEvent('checkBankIntegration') checkBankIntegration: EmberEventFn | undefined
  @registerEvent('navigate') navigate: Function
}

type BankRenewConnectionButtonModuleProps = {
  accountid: string
  onCheckBankIntegration?: EmberEventFn
  onNavigate: EmberNavigate
  organizationId: string
}

function BankRenewConnectionButtonModule({
  accountid,
  onCheckBankIntegration,
  onNavigate,
  organizationId,
}: BankRenewConnectionButtonModuleProps): ReactElement {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <CurrentUserContextProvider>
            <UserOrganizationContextProvider organizationId={organizationId}>
              <EmberRouterContextProvider onNavigate={onNavigate}>
                <ModalContextProvider>
                  <AccountsContextProvider organizationId={organizationId}>
                    <BankIntegrationContextProvider
                      account={accountid}
                      onCheckBankIntegration={onCheckBankIntegration}
                      organizationId={organizationId}
                    >
                      <RenewBankConnectionButton />
                    </BankIntegrationContextProvider>
                  </AccountsContextProvider>
                </ModalContextProvider>
              </EmberRouterContextProvider>
            </UserOrganizationContextProvider>
          </CurrentUserContextProvider>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const BankRenewConnectionButtonCustomElement = createCustomElement(
  BankRenewConnectionButtonModule,
  BankRenewConnectionButtonComponent,
  'element',
)

customElements.get('bank-connection-renew-button') ||
  customElements.define('bank-connection-renew-button', BankRenewConnectionButtonCustomElement)

export default BankRenewConnectionButtonCustomElement
