import { AttachmentFile } from '@design-system'

import { BillAttachment } from '../../../types/billAttachment'

export const decorateAttachmentsFilesWithAttachmentId = (
  attachmentsFiles?: AttachmentFile[],
  attachments?: BillAttachment[],
): AttachmentFile[] | undefined => {
  return attachmentsFiles?.map((attachmentFile) => {
    const attachment = attachments?.find((attachment) => attachment.fileId === attachmentFile.id)

    if (attachment) {
      return {
        ...attachmentFile,
        attachmentId: attachment.id,
      }
    }

    return attachmentFile
  })
}
