import { amountToDisplayValue, Flex, SkeletonBox, Space, Text, useTheme } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface PurchaseCardPriceSectionProps {
  isLoading?: boolean
  price?: number
  pricePeriodNode?: ReactNode
  priceSuffix: string
}

export const PurchaseCardPriceSection = ({
  isLoading,
  price,
  pricePeriodNode,
  priceSuffix,
}: PurchaseCardPriceSectionProps): ReactElement => {
  const theme = useTheme()

  return (
    <Styled.PurchaseCardPriceSectionWrapper flexDirection="column" alignItems="center">
      {pricePeriodNode && <Styled.PricePeriodWrapper>{pricePeriodNode}</Styled.PricePeriodWrapper>}
      <Styled.PriceWrapper>
        {/* !price would be incorrect here, because it can be 0 */}
        {isLoading || price === undefined ? (
          <SkeletonBox fullWidth fullHeight />
        ) : (
          <Flex justifyContent="center" alignItems="baseline">
            <Text variant="displayM" color={theme.ds.colors.pricing.accent} weight="bold">
              {amountToDisplayValue(price, true)}
            </Text>
            <Space horizontal size="xxs" />
            <Text variant="h2" color={theme.ds.colors.pricing.accent}>
              {priceSuffix}
            </Text>
          </Flex>
        )}
      </Styled.PriceWrapper>
    </Styled.PurchaseCardPriceSectionWrapper>
  )
}
