import { Role } from '@components'

import merge from 'lodash/merge'
import qs from 'qs'

import {
  getOrganizationInvitations,
  getOrganizationUmbrellas,
  getOrganizationUsers,
} from '@modules-deprecated/app/organization'
import { InvitationType } from '@modules-deprecated/app/organization/enums/invitationType'
import { fetchUserUmbrellas } from '@modules-deprecated/app/umbrellas/query-api'
import { UmbrellaListItem } from '@modules-deprecated/app/umbrellas/types/umbrellaListItem'
import { User as CurrentUser } from '@modules-deprecated/app/user'

import { ApiContentType } from '../../../../enums/apiContentType'
import { Metable } from '../../../../types/metable'
import { getRequest, postV2Request, putV2Request } from '../../../../utils'
import { InvitedUmbrella, User } from './types'
import { getSortedUsers } from './utils/getSortedUsers'
import { getUsersFromInvitations } from './utils/getUsersFromInvitations'

export async function getInvitedBookkeepers(organizationId: string, ownerUmbrellaId: string): Promise<User[]> {
  const [{ data: umbrellas }, { data: invitations }] = await Promise.all([
    getOrganizationUmbrellas(organizationId),
    getOrganizationInvitations({ organizationId, type: InvitationType.Umbrella }),
  ])

  const umbrellasData = umbrellas.map(
    ({ logoFileId, name, umbrellaId }): User => ({
      id: umbrellaId,
      isOwner: umbrellaId === ownerUmbrellaId,
      isPending: false,
      logoId: logoFileId || undefined,
      isUmbrella: true,
      name,
    }),
  )

  return getSortedUsers([...umbrellasData, ...getUsersFromInvitations(invitations)])
}

export async function getInvitedUsers(organizationId: string, ownerUserId: string): Promise<User[]> {
  const [users, { data: invitations }] = await Promise.all([
    getOrganizationUsers(organizationId),
    getOrganizationInvitations({ organizationId, type: InvitationType.User }),
  ])

  const usersData = users.map(
    ({ email, name, userId, userRole }): User => ({
      email,
      id: userId,
      isOwner: userId === ownerUserId,
      isPending: false,
      name,
      userRole,
    }),
  )

  return getSortedUsers([...usersData, ...getUsersFromInvitations(invitations)])
}

interface GetBookkeepersResponseData extends Metable {
  data: UmbrellaListItem[]
}

export function getBookkeepers(q?: string): Promise<GetBookkeepersResponseData> {
  const queryParams = qs.stringify({
    ...(q ? { q } : {}),
  })

  return getRequest(`/umbrellas?${queryParams}`)
}

interface InviteBookkeeperPayload {
  data: {
    umbrellaId: string
  }
}

interface InviteBookkeeperResponseData {
  data: InvitedUmbrella
}

export function inviteBookkeeper(organizationId: string, umbrellaId: string) {
  return postV2Request<InviteBookkeeperPayload, InviteBookkeeperResponseData>({
    endpoint: `/organizations/${organizationId}/invitations/umbrella`,
    payload: { data: { umbrellaId } },
    options: {
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  })
}

export async function getOrganizationMembers({
  organizationId,
  ownerUserId,
  ownerUmbrellaId,
  user,
}: {
  organizationId: string
  ownerUserId: string
  ownerUmbrellaId: string
  user?: CurrentUser
}): Promise<User[]> {
  const [users, { data: umbrellas }, { data: userUmbrellas }] = await Promise.all([
    getOrganizationUsers(organizationId),
    getOrganizationUmbrellas(organizationId),
    fetchUserUmbrellas(),
  ])

  const currentUserMember: User | undefined = user?.id
    ? {
        id: user.id,
        name: user.name,
        email: user.email || undefined,
        avatarUrl: user.profilePicUrl || undefined,
        isOwner: user.id === ownerUserId,
      }
    : undefined

  const usersMembers = users.map(
    (user): User => ({
      id: user.userId,
      name: user.name || user.email,
      email: user.email || undefined,
      avatarUrl: user.profilePicUrl || undefined,
      isOwner: user.userId === ownerUserId,
    }),
  )

  const umbrellasMembers = umbrellas.map(
    (umbrella): User => ({
      id: umbrella.umbrellaId,
      name: umbrella.name,
      logoId: umbrella.logoFileId || undefined,
      isOwner: umbrella.umbrellaId === ownerUmbrellaId,
      isUmbrella: true,
    }),
  )

  const userUmbrellasMembers = userUmbrellas.map(
    (umbrella): User => ({
      id: umbrella.umbrellaId,
      name: umbrella.name,
      logoId: undefined,
      isOwner: umbrella.umbrellaId === ownerUmbrellaId,
      isUmbrella: true,
    }),
  )

  const organizationsMembersObj: Record<string, User> = [
    ...(currentUserMember ? [currentUserMember] : []),
    ...usersMembers,
    ...umbrellasMembers,
    ...userUmbrellasMembers,
  ].reduce((acc, organizationMember) => {
    const existingUser = acc[organizationMember.id]

    // If duplicates, make sure that all fields are present
    if (existingUser) {
      acc[organizationMember.id] = merge(existingUser, organizationMember)
    } else {
      acc[organizationMember.id] = organizationMember
    }

    return acc
  }, {})

  return getSortedUsers(Object.values(organizationsMembersObj))
}

export interface UpdateUserRolePayload {
  userId: string
  organizationId: string
  userRole: Role
}

export interface UpdateUserRoleResponseData {
  data: UpdateUserRolePayload
}

export const updateUserRole = ({
  userId,
  organizationId,
  userRole,
}: UpdateUserRolePayload): Promise<UpdateUserRoleResponseData> => {
  return putV2Request({
    endpoint: `/organizations/${organizationId}/users/${userId}/userRole/${userRole}`,
    options: {
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  })
}
