import qs from 'qs'

import { TransactionOriginatorTypeGroup } from '../enums/transactionOriginatorTypeGroup'
import { TransactionsListFilters } from '../routes/TransactionsList/hooks/useTransactionsListFilters'
import { getOriginatorTypeGroup } from './getOriginatorTypeGroup'

type InitialQuery = Partial<
  Omit<TransactionsListFilters, 'originatorTypeGroup'> & {
    originatorTypeGroup?: TransactionOriginatorTypeGroup[] | string
  }
>

export const emberQueryParamsParser = (searchParams: qs.ParsedQs) => {
  const initialQuery: InitialQuery = { ...searchParams }

  initialQuery.originatorTypeGroup =
    typeof initialQuery.originatorTypeGroup === 'string'
      ? getOriginatorTypeGroup(initialQuery.originatorTypeGroup)
      : initialQuery.originatorTypeGroup

  return initialQuery as qs.ParsedQs
}
