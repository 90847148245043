import { Button, IconButton, IconButtonsGroup, TABLE_CELL_NOT_CLICKABLE_CLASS, Tooltip } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { CellProps } from 'react-table'

import { Account } from '@modules-deprecated/app/accounts/types'

import { SetAccountArchivedStatusPayload } from '../../query-api'
import { ChartOfAccountsAccount } from '../../types'
import * as Styled from './styles'

export interface AccountActionsCellProps extends CellProps<ChartOfAccountsAccount> {
  handleEditAccount: (account: Account) => void
  handleEditPredefinedAccount: (account: Account) => void
  handleToggleAccountArchived: (payload: SetAccountArchivedStatusPayload) => void
  handleDeleteAccount: (accountId: string) => void
}

export const AccountActionsCell = ({
  data,
  row,
  handleEditAccount,
  handleEditPredefinedAccount,
  handleToggleAccountArchived,
  handleDeleteAccount,
}: AccountActionsCellProps): ReactElement => {
  const { t } = useTranslation()
  const account = data[row.id]
  const isNotPredefined = !account.predefinedAccount
  const isArchived = account.isArchived
  const isPredefinedButEditable = account.predefinedAccount?.isEditable
  const isAccountEditable = isNotPredefined || isPredefinedButEditable

  const handleEditIconClick = () => {
    if (isNotPredefined) {
      handleEditAccount(account)
      return
    }

    if (account.predefinedAccount?.isEditable) {
      handleEditPredefinedAccount(account)
    }
  }

  const handleAccountArchived = useCallback(() => {
    handleToggleAccountArchived({ accountId: account.id, isArchived: !account.isArchived })
  }, [account.id, account.isArchived, handleToggleAccountArchived])

  const handleDelete = useCallback(() => {
    handleDeleteAccount(account.id)
  }, [account.id, handleDeleteAccount])

  return (
    <>
      <Styled.ActionsCell className={TABLE_CELL_NOT_CLICKABLE_CLASS}>
        <IconButtonsGroup>
          {isAccountEditable && (
            <Tooltip label={t('edit')} placement="top">
              <IconButton icon="paperWithPencil" onClick={handleEditIconClick} />
            </Tooltip>
          )}
          {isAccountEditable && !isArchived && (
            <Tooltip label={t('hide')} placement="top">
              <IconButton icon="eyeCrossed" onClick={handleAccountArchived} />
            </Tooltip>
          )}
          {isArchived && (
            <Button icon="eye" variant="text" onClick={handleAccountArchived}>
              {t('unhide')}
            </Button>
          )}
          {!account?.predefinedAccount && !isArchived && (
            <Tooltip label={t('delete')} placement="top">
              <IconButton icon="trash" onClick={handleDelete} />
            </Tooltip>
          )}
        </IconButtonsGroup>
      </Styled.ActionsCell>
    </>
  )
}
