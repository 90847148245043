import { Locale } from '@design-system'

import { useMemo } from 'react'

import { DEFAULT_LOCALE } from '../../../../../constants/defaultLocale'
import { SortDirection } from '../../../../../enums/sortDirection'
import { useLocaleCode } from '../../../../../hooks/useLocaleCode'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { VatRatesSortProperty } from '../enums/vatRatesSortProperty'

interface VatRatesListFilters {
  acceptLanguage: Locale
  sortDirection: SortDirection
  sortProperty: VatRatesSortProperty
}

type UseVatRatesListFiltersResult = [VatRatesListFilters, (filters: Partial<VatRatesListFilters>) => void]

export const defaultQuery: VatRatesListFilters = {
  acceptLanguage: DEFAULT_LOCALE,
  sortDirection: SortDirection.Asc,
  sortProperty: VatRatesSortProperty.Priority,
}

export const useVatRatesListFilters = (key: string): UseVatRatesListFiltersResult => {
  const acceptLanguage = useLocaleCode()
  const [queryParams, setQueryParams] = useQueryParams<VatRatesListFilters>({
    key,
    defaultQuery: {
      ...defaultQuery,
      acceptLanguage,
    },
  })

  return useMemo(() => [queryParams, setQueryParams], [queryParams, setQueryParams])
}
