import styled from '@emotion/styled'
import { Flex, FlexProps } from 'rebass'

import { Themable } from '../../../../types/themable'
import { Text as TextComponent } from '../../../Typography'

type SavingContainerProps = FlexProps & Themable

export const SavingContainer = styled(Flex)<SavingContainerProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Text = styled(TextComponent)`
  font-size: 16px;
`
