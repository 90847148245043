import { Translate } from '@components'
import {
  amountToDisplayValue,
  CellValue,
  Color,
  InternalAccessor,
  Link,
  Space,
  Table,
  TableColumn,
  Text,
} from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useUmbrellaLicensePacks } from '../../hooks/useUmbrellaLicensePacks'
import { LicensePacksTableAccessors } from './constants/licensePacksTableAccessors'
import { LicenseOrganizationsSubRow } from './elements/LicenseOrganizationsSubRow'
import { LicensePacksTableRow } from './types/licensePacksTableRow'

export const LicensePacksTable = (): ReactElement | null => {
  const { t } = useTranslation()
  const { umbrellaLicensePacks, isLoading } = useUmbrellaLicensePacks()

  const columns: TableColumn<LicensePacksTableRow>[] = useMemo(
    () => [
      {
        accessor: LicensePacksTableAccessors.Name,
        Header: <Translate value="umbrella_subscriptions.license_packs.table.name_column" />,
        Cell: ({ value: name }: CellValue<Pick<LicensePacksTableRow, LicensePacksTableAccessors.Name>>) => <>{name}</>,
        size: 'xxl',
      },
      {
        accessor: LicensePacksTableAccessors.Licenses,
        Header: <Translate value="umbrella_subscriptions.license_packs.table.licenses_column" />,
        Cell: ({ value: licenses }: CellValue<Pick<LicensePacksTableRow, LicensePacksTableAccessors.Licenses>>) => (
          <>{licenses}</>
        ),
      },
      {
        accessor: LicensePacksTableAccessors.Price,
        alignment: 'right',
        Header: <Translate value="umbrella_subscriptions.license_packs.table.price_column" />,
        Cell: ({ value: price }: CellValue<Pick<LicensePacksTableRow, LicensePacksTableAccessors.Price>>) => (
          <>{price}</>
        ),
      },
      {
        Header: '',
        accessor: InternalAccessor.SubRow,
        Cell: ({ value }) => (
          <LicenseOrganizationsSubRow organizations={value.organizations || []} license={value.license} />
        ),
      },
    ],
    [],
  )

  const tableData: LicensePacksTableRow[] = useMemo(
    () =>
      umbrellaLicensePacks.map(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ({ product_plan_key, product_plan_name, usedSeats, maxSeats, price, organizations }) => ({
          [LicensePacksTableAccessors.Name]: product_plan_name,
          [LicensePacksTableAccessors.Licenses]: t('umbrella_subscriptions.license_packs.used_out_of', {
            usedSeats,
            maxSeats,
          }),
          [LicensePacksTableAccessors.Price]: amountToDisplayValue(price, true, 'DKK'),
          [InternalAccessor.SubRow]: { organizations, license: product_plan_name },
          id: product_plan_key,
        }),
      ),
    [t, umbrellaLicensePacks],
  )

  return (
    <>
      <Table
        columns={columns}
        data={tableData}
        isLoading={isLoading}
        emptyRowInfo={
          <Trans
            i18nKey="umbrella_subscriptions.license_packs.table.empty"
            components={{
              text: <Text color={Color.Green120}>{''}</Text>,
              maillink: <Link href="mailto:partner@billy.dk" />,
            }}
          />
        }
        withEmptyRowInfo
      />
      <Space size="xl" />
    </>
  )
}
