import { Module } from '@design-system'

import { ReactElement, ReactNode } from 'react'

import { AsideInfoItem } from './elements/AsideInfoItem'
import * as Styled from './styles'

interface AsideInfoProps {
  children: ReactNode
  className?: string
  title: string
}

export const AsideInfo = ({ children, className, title }: AsideInfoProps): ReactElement => (
  <Styled.AsideInfoWrapper className={className}>
    <Module.Section title={title}>{children}</Module.Section>
  </Styled.AsideInfoWrapper>
)

AsideInfo.Item = AsideInfoItem
