import { css } from '@emotion/core'

import { DefaultValue } from '../../../enums/defaultValue'
import { StyledComponent } from '../../../types/styledComponent'
import { getDefaultTransition } from '../../../utils/getDefaultTransition'
import { InputWrapperProps } from '../types/inputWrapperProps'
import { getAlignmentStyle } from '../utils/getAlignmentStyle'
import { getAppearanceStyle } from '../utils/getAppearanceStyle'
import { getDisabledStyle } from '../utils/getDisabledStyle'
import { getErrorStyle } from '../utils/getErrorStyle'
import { getFocusStyle } from '../utils/getFocusStyle'
import { getHoverStyle } from '../utils/getHoverStyle'
import { getPlaceholderStyle } from '../utils/getPlaceholderStyle'
import { getSizeStyle } from '../utils/getSizeStyle'
import { getTruncateStyle } from '../utils/getTruncateStyle'
import { getFontStyle } from './getFontStyle'

const hasDisabledStyle = ({ disabled }: InputWrapperProps) => disabled
const hasErrorStyle = ({ disabled, error }: InputWrapperProps) => !disabled && error
const hasHoverStyle = ({ bordered, disabled, error, focused }: InputWrapperProps) =>
  bordered && !disabled && !error && !focused
const hasFocusStyle = ({ bordered, disabled, focused }: InputWrapperProps) => bordered && !disabled && focused
const hasTruncateStyle = ({ truncate }: InputWrapperProps) => !!truncate

export const getInputWrapperStyle = (props: InputWrapperProps, Input?: StyledComponent, isTextArea = false) => {
  const InputComponent: StyledComponent | undefined = isTextArea ? undefined : Input

  return css`
    display: ${props.hidden ? 'none' : 'flex'};
    width: 100%;
    border-radius: ${DefaultValue.BorderRadius};
    align-items: stretch;
    cursor: ${props.selectLook ? 'default' : 'text'};
    ${getDefaultTransition('border', 'background', 'box-shadow')};

    ${getAppearanceStyle(props)};
    ${getSizeStyle(props.size, isTextArea)};
    ${getAlignmentStyle(props.alignment, InputComponent)}
    ${getFontStyle(props.weight, props.selectLook, InputComponent)};
    ${getPlaceholderStyle(props.theme, props.selectLook, InputComponent)};
    ${hasDisabledStyle(props) && getDisabledStyle(props.bordered, InputComponent)};
    ${hasErrorStyle(props) && getErrorStyle(props.bordered)};
    ${hasHoverStyle(props) && getHoverStyle()};
    ${hasFocusStyle(props) && getFocusStyle(props.error)};
    ${hasTruncateStyle(props) && getTruncateStyle(InputComponent)};
  `
}
