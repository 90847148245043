import { Locale } from '@design-system'

import { useLocale } from './useLocale'

export const useLocaleCode = (): Locale => {
  const { code } = useLocale()

  if (code.includes('da')) {
    return 'da_DK'
  }
  if (code.includes('fr')) {
    return 'fr_FR'
  }

  return 'en_US'
}
