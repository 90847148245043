import { Color, DefaultValue, getDefaultTransition, Spacing, Text } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import React from 'react'
import { Box } from 'rebass'

import { SmartSelectItem } from './types'

const HeadingWrapper = styled.header`
  margin: -${Spacing.S} 0 -${Spacing.S} -${Spacing.S};
`

export type SelectListItemProps = SmartSelectItem

export const SelectListItem = ({
  children,
  css: providedCSS,
  description,
  id,
  isHeading,
  onItemSelect = (id: string) => id,
  selectedId,
  text,
  withFooter,
  ...rest
}: SelectListItemProps) => {
  const selected = isHeading ? false : selectedId === id
  const headingStyles = css`
    border-radius: 0;
    position: sticky;
    top: 0;
    background-color: ${Color.White};
    cursor: auto;
  `
  const itemStyles = css`
    background-color: ${selected ? Color.Purple20 : Color.White};
    cursor: pointer;
    ${getDefaultTransition('background-color')};

    &:last-of-type {
      margin-bottom: ${withFooter ? Spacing.S : 0};
    }

    ${!selected &&
    css`
      &:hover {
        background-color: ${transparentize(0.75, Color.Gray60)};
      }
    `};
  `

  let content

  if (isHeading) {
    content = (
      <HeadingWrapper>
        <Text variant="h4" colorVariant="secondary">
          {text}
        </Text>
      </HeadingWrapper>
    )
  } else if (description) {
    content = (
      <>
        <Text weight="medium" color={selected ? Color.Blue : undefined}>
          {text}
        </Text>
        <Text variant="micro" color={selected ? Color.Blue : Color.Gray90}>
          {description}
        </Text>
      </>
    )
  } else {
    content = (
      <Text weight="medium" color={selected ? Color.Blue : undefined}>
        {text}
      </Text>
    )
  }

  const handleRowSelect = () => (isHeading ? null : onItemSelect(id))

  return (
    <Box
      as="li"
      onClick={handleRowSelect}
      css={css`
        padding: ${Spacing.M} ${Spacing.L};
        border-radius: ${DefaultValue.BorderRadius};

        ${isHeading ? headingStyles : itemStyles}
        ${providedCSS}
      `}
      {...rest}
    >
      {children || content}
    </Box>
  )
}
