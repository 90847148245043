import qs from 'qs'

import { Metable } from '../../../types/metable'
import { getRequest } from '../../../utils'
import { Bank } from './types'

interface FetchBanksResponseData extends Metable {
  banks: Bank[]
}

interface FetchBanksParams {
  countryId?: string
  sortDirection?: 'ASC' | 'DESC'
  pageSize?: number
  offset?: number
}

export const fetchBanks = (queryParams: FetchBanksParams = { countryId: 'DK' }): Promise<FetchBanksResponseData> =>
  getRequest(`/v2/banks?${qs.stringify(queryParams)}`)
