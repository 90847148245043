import { Translate } from '@components'
import { Button, ButtonsGroup } from '@design-system'

import React, { memo, ReactElement, useCallback, useState } from 'react'

import { DeleteModal } from '@modules-deprecated/inbox/elements'

import * as Styled from './styles'

interface MissingInformationActionsProps {
  onSubmit?: () => void
  onDelete?: () => void
  disabled?: boolean
}

export const MissingInformationActions = memo(
  ({ disabled, onSubmit, onDelete, ...props }: MissingInformationActionsProps): ReactElement => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    const handleConfirmDelete = useCallback(() => {
      onDelete && onDelete()
      setIsDeleteModalOpen(false)
    }, [onDelete])

    const handleDelete = useCallback(() => {
      setIsDeleteModalOpen(true)
    }, [])

    return (
      <Styled.FooterActions {...props}>
        {(onDelete || onSubmit) && (
          <ButtonsGroup>
            {onDelete && (
              <Button danger disabled={disabled} icon="trash" onClick={handleDelete} variant="text">
                <Translate value="voucher.inbox.modals.delete.delete_voucher" />
              </Button>
            )}
            {onSubmit && (
              <Button disabled={disabled} onClick={onSubmit}>
                <Translate value="submit" />
              </Button>
            )}
          </ButtonsGroup>
        )}
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onCancel={() => setIsDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
          disabled={disabled}
        />
      </Styled.FooterActions>
    )
  },
)
