import { DataSummarySection, FileAvatar } from '@components'
import { Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Umbrella } from '@modules-deprecated/app/umbrellas/types/umbrella'

interface SelectedBookkeeperSectionProps {
  bookkeeper: Partial<Umbrella>
}

export const SelectedBookkeeperSection = ({ bookkeeper }: SelectedBookkeeperSectionProps): ReactElement => {
  const { t } = useTranslation()
  const { address, email, logoFileId, name, phone, registrationNo } = bookkeeper

  const noContentSign = t('settings.organization.users.invite_bookkeeper_modal.empty')

  return (
    <DataSummarySection>
      <DataSummarySection.ImageColumn>
        <FileAvatar logoId={logoFileId || ''} size="l" />
      </DataSummarySection.ImageColumn>
      <DataSummarySection.Column>
        <Text weight="medium">{name}</Text>
        {address && (
          <>
            <Text>{address.street ? address.street : noContentSign}</Text>
            <Text>{address.zipcode && address.city ? `${address.zipcode}, ${address.city}` : noContentSign}</Text>
          </>
        )}
      </DataSummarySection.Column>
      <DataSummarySection.Column>
        {registrationNo && (
          <DataSummarySection.ColumnItem alignment="right">
            <Text colorVariant="secondary">
              {t('settings.organization.users.invite_bookkeeper_modal.label.tax_id')}
            </Text>
            <Text weight="medium">&nbsp;{registrationNo}</Text>
          </DataSummarySection.ColumnItem>
        )}
        {phone && (
          <DataSummarySection.ColumnItem alignment="right">
            <Text colorVariant="secondary">{t('settings.organization.users.invite_bookkeeper_modal.label.tel')}</Text>
            <Text>&nbsp;{phone}</Text>
          </DataSummarySection.ColumnItem>
        )}
        <DataSummarySection.ColumnItem alignment="right">
          <Text>{email}</Text>
        </DataSummarySection.ColumnItem>
      </DataSummarySection.Column>
    </DataSummarySection>
  )
}
