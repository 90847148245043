import { TableSize } from '../types/size'

type TableSizeValues = {
  maxWidth: number
}

export const sizeToWidth: Record<TableSize, TableSizeValues> = {
  xxxxs: { maxWidth: 8 },
  xxxs: { maxWidth: 20 },
  xxs: { maxWidth: 30 },
  xs: { maxWidth: 50 },
  s: { maxWidth: 100 },
  m: { maxWidth: 150 },
  l: { maxWidth: 250 },
  xl: { maxWidth: 350 },
  xxl: { maxWidth: 450 },
  xxxl: { maxWidth: 550 },
}
