import { AttachmentFile } from '@design-system'

export const attachmentsFilesToAttachmentsIds = (attachmentsFiles: AttachmentFile[]): string[] =>
  attachmentsFiles.reduce((result: string[], attachmentFile) => {
    if (attachmentFile.attachmentId) {
      result.push(attachmentFile.attachmentId)
    }

    return result
  }, [])
