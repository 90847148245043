import { StepsList, StepsListItem } from '@components'
import { Button, ButtonsGroup, Modal, notify, useModal } from '@design-system'

import qs from 'qs'
import { ReactElement, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { setOrganizationSetting, useUserOrganization } from '@modules-deprecated/app/organization'
import { AgerasFinanceCustomerResponse } from '@views/financing'

import { ReactComponent as FinancingIllustration } from '../../../../assets/images/billy-financing.svg'
import { queryClient } from '../../../../config/queryClient'
import { ModalId } from '../../../../enums/modalId'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../enums/queryKeys'
import { useSegment } from '../../../../hooks'
import { APIError } from '../../../../utils'
import { getImageClassName } from '../../../../utils/getClassName'
import { FINANCING_ENABLED } from '../../constants/financingSettingsKeys'
import { createFinancingUser } from '../../query-api'
import * as Styled from './styles'

export const ApprovalModal = (): ReactElement => {
  const { close: closeFinancingApplicationModal } = useModal(ModalId.FinancingApplicationModal)
  const { organization } = useUserOrganization()
  const { t } = useTranslation()
  const { track } = useSegment()
  const successRedirectUrl = useRef<string>()

  const { mutate: setFinancingEnabled } = useMutation(setOrganizationSetting, {
    onSuccess: () => {
      if (successRedirectUrl.current) {
        window.location.replace(successRedirectUrl.current)
      }
    },
    onSettled: () => {
      successRedirectUrl.current = undefined
      queryClient.invalidateQueries(QueryKeys.UserOrganizationSettings)
    },
  })

  const { mutate: applyForFinancing, isLoading: isApplyingForFinancing } = useMutation(createFinancingUser, {
    onSuccess: (data: AgerasFinanceCustomerResponse) => {
      if (!organization) {
        return
      }
      track('Invoice Financing Integration Updated (FE)', {
        action: 'applied',
      })
      const token = data.token.accessToken

      const params = qs.stringify({
        /* eslint-disable @typescript-eslint/naming-convention */
        redirect_uri: `${window.location.origin}${window.location.pathname}?application_sent=1`,
        /* eslint-enable @typescript-eslint/naming-convention */
      })

      const url = `${window.ENV.AGERAS_FINANCE_FRONTEND_URL}/invoice-financing?${params}#token=${token}`
      successRedirectUrl.current = url

      setFinancingEnabled({
        key: FINANCING_ENABLED,
        organizationId: organization.id,
        value: '1',
      })
    },
    onError: (error: APIError) => {
      const { message } = error
      const parsedMessage = JSON.parse(message)

      closeFinancingApplicationModal()
      notify({
        id: NotificationKeys.FinancingApprovalError,
        message: parsedMessage.errors.company_registration_number
          ? t('financing.approval_modal.no_tax_id')
          : parsedMessage.message,
        variant: 'error',
      })
    },
  })

  const handleApprove = useCallback(() => {
    if (!organization) {
      return
    }

    applyForFinancing(organization?.id)
  }, [applyForFinancing, organization])

  return (
    <Modal id={ModalId.FinancingApplicationModal} size="s">
      <Modal.Header title={t('financing.approval_modal.title')} />
      <Modal.Body>
        <Styled.IllustrationWrapper className={getImageClassName()}>
          <FinancingIllustration />
        </Styled.IllustrationWrapper>
        <StepsList>
          <StepsListItem
            title={t('financing.approval_modal.step_1')}
            description={t('financing.approval_modal.step_1.description')}
            active
          />
          <StepsListItem title={t('financing.approval_modal.step_2')} />
          <StepsListItem title={t('financing.approval_modal.step_3')} />
          <StepsListItem title={t('financing.approval_modal.step_4')} />
        </StepsList>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="primary" onClick={handleApprove} loading={isApplyingForFinancing}>
            {t('financing.approval_modal.cta')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
