import { Locale } from 'date-fns'
import { da, enUS, fr } from 'date-fns/locale'

import { Language } from '../types/language'

export const languageToDateLocale: Record<Language, Locale> = {
  da,
  en: enUS,
  fr,
}
