import React, { ReactElement } from 'react'

import { IconProps } from '../Icon'

export const IconComment = ({
  className,
  color = 'inherit',
  title,
  width = '20px',
  height = '20px',
}: IconProps): ReactElement => (
  <i className={className} title={title}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 14" width={width} height={height}>
      <path
        fill={color}
        d="M10.72 4.248H5.28a.65.65 0 00-.656.656c0 .368.288.656.656.656h5.44a.65.65 0 00.656-.656.649.649 0 00-.656-.656zM10.72 7.064H5.28a.65.65 0 00-.656.656.65.65 0 00.656.656h5.44a.65.65 0 00.656-.656.65.65 0 00-.656-.656z"
      />
      <path
        fill={color}
        d="M8 .776C3.808.776.4 3.256.4 6.313c0 1.583.912 3.008 2.368 4.016-.24.576-.624 1.344-1.088 2-.32.432.08 1.008.592.88 1.408-.384 2.72-1.04 3.6-1.568.672.144 1.392.224 2.128.224 4.192 0 7.6-2.48 7.6-5.537C15.6 3.272 12.192.776 8 .776zm0 9.776c-3.472 0-6.288-1.888-6.288-4.224 0-2.336 2.816-4.24 6.288-4.24 3.472 0 6.288 1.889 6.288 4.224 0 2.336-2.816 4.24-6.288 4.24z"
      />
    </svg>
  </i>
)
