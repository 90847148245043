import { ContactSelect } from '@components'

import { useFormContext } from '../../../../../../../../../../hooks'
import { Contact } from '../../../../../../../../../contacts/types/contact'

export const InvoiceSendTo = () => {
  const { FormItem } = useFormContext()

  return (
    <FormItem
      name="sendTo"
      render={({ field: { value, onChange, ...props } }) => {
        return (
          <ContactSelect
            {...props}
            allowClear
            allowCreateNew
            onSelect={(id?: string, value?: Partial<Contact>) => {
              onChange(value)
            }}
            selectedId={value?.id}
            selectedValue={value}
            withSearch
          />
        )
      }}
    />
  )
}
