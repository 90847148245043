import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'
import { pxToNumber } from '../../utils/pxToNumber'
import { sizeToProps } from '../Input/utils/getSizeStyle'

const BORDER_WIDTH = 1

export const SearchInputWrapper = styled.div`
  display: flex;
  height: ${sizeToProps.l.height + BORDER_WIDTH * 2}px;
  align-items: center;
`

interface InputWrapperProps {
  visible: boolean
}

export const InputWrapper = styled.div<InputWrapperProps>`
  ${({ visible }) =>
    visible &&
    `
      margin-left: ${pxToNumber(Spacing.XXXS) + BORDER_WIDTH}px;
  `}
`
