import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const NotesHeader = styled.header`
  display: flex;
`

export const BadgeWrapper = styled.div``

export const ClosePanelIconWrapper = styled.figure`
  margin-left: auto;
  margin-top: -${Spacing.S};
  margin-right: -${Spacing.L};
`
