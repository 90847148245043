import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'
import { pxToNumber } from '../../../../utils/pxToNumber'

interface TableRowExpanderWrapperProps {
  selectable: boolean
}

export const TableRowExpanderWrapper = styled.button<TableRowExpanderWrapperProps>`
  display: flex;
  padding: 0;
  position: absolute;
  left: ${({ selectable }) => (selectable ? `${pxToNumber(Spacing.L) * 2}px` : Spacing.L)};
  border: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
