import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const PaymentsList = styled.ul``

export const PaymentItem = styled.li`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  column-gap: ${Spacing.S};
`
