import { ProcessingOverlay } from '@components'

import { ReactElement, ReactNode } from 'react'

import { modalContentLayout } from '../../../shared'
import { useCreateOrEditVatRateFromTemplateModal } from '../../context/createOrEditVatRateFromTemplateModalContext'

interface ModalContentProps {
  children?: ReactNode
}

export const ModalContent = ({ children }: ModalContentProps): ReactElement => {
  const { Form, isLoadingTemplate } = useCreateOrEditVatRateFromTemplateModal()

  return (
    <>
      {isLoadingTemplate && <ProcessingOverlay />}
      <Form layout={modalContentLayout}>{children}</Form>
    </>
  )
}
