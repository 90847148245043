import { UmbrellaOrganizationSelect } from '@components'
import { FormItem as FormItemDS, FormItemsGroup, IconButton } from '@design-system'

import { ReactElement, useCallback } from 'react'

import { useFormContext } from '../../../../../../../../../../hooks'
import { CreateOrEditRoleFormSchema } from '../../../../utils/formData'

interface OrganizationSelectLineProps {
  index: number
  organizationsIdsToSkip?: string[]
  onOrganizationDelete?: () => void
}

export const OrganizationSelectLine = ({
  index,
  organizationsIdsToSkip,
  onOrganizationDelete,
}: OrganizationSelectLineProps): ReactElement => {
  const { FormItem } = useFormContext<CreateOrEditRoleFormSchema>()

  const handleDeleteLineClick = useCallback(() => {
    onOrganizationDelete?.()
  }, [onOrganizationDelete])

  return (
    <FormItemsGroup itemsInRow={4}>
      <FormItem
        itemColumnStart={1}
        itemColumnEnd={4}
        name={`organizations.${index}.organizationId`}
        render={({ field: { value, onChange, ...props } }) => (
          <UmbrellaOrganizationSelect
            selectedId={value}
            onSelect={(value) => onChange(value)}
            organizationsIdsToSkip={organizationsIdsToSkip}
          />
        )}
      />
      <FormItemDS itemColumnStart={4}>
        <IconButton icon="trash" onClick={handleDeleteLineClick} />
      </FormItemDS>
    </FormItemsGroup>
  )
}
