import { RULESETS_FILTER_KEY } from '../../../constants/rulesetsFilterKey'
import { useVatRatesListFilters } from '../../../hooks/useVatRatesListFilters'
import { RulesetWithRules } from '../../../types/rulesetWithRules'
import { useFetchRulesets } from './useFetchRulesets'

interface UseRulesetsResponse {
  rulesets: RulesetWithRules[]
  isLoading?: boolean
}

export const useRulesets = (): UseRulesetsResponse => {
  const [{ sortDirection, sortProperty }] = useVatRatesListFilters(RULESETS_FILTER_KEY)
  // we don't pass "page" and "pageSize" to avoid refetching data because of all list is already fetched
  // BE doesn't support this properties
  const { data, isLoading } = useFetchRulesets({
    sortDirection,
    sortProperty,
  })

  return {
    rulesets: data,
    isLoading,
  }
}
