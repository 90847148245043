import { pxToNumber, Spacing } from '@design-system'

import isEqual from 'lodash/isEqual'
import React, { ReactElement, ReactNode, useCallback, useMemo, useRef } from 'react'
import Grid from 'react-grid-layout'

import { widgetToComponent } from '../../constants/widgetIdToComponent'
import { widgetSettingsByWidgetId } from '../../constants/widgetSettingsByWidgetId'
import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { WidgetId } from '../../enums/widgetId'
import { Widget } from '../../types/widget'
import { getLayoutFromWidgets } from '../../utils'
import { getWidgetsFromLayout } from '../../utils/getWidgetsFromLayout'
import { DraggableWidget } from '../DraggableWidget'
import { WidgetsGrid } from '../WidgetsGrid'
import * as Styled from '../WidgetsList/styles'

interface WidgetsSectionProps {
  isDraggable?: boolean
  isEditMode?: boolean
  title?: ReactNode
  widgets: Widget[]
}

const WIDGET_MARGIN = pxToNumber(Spacing.L)
const WIDGET_PADDING = 0

export const WidgetsSection = ({
  title,
  widgets,
  isDraggable = true,
  isEditMode,
}: WidgetsSectionProps): ReactElement => {
  const { setWidgets, isLoading, toggleWidgetVisibility } = useDashboardSettings()
  const isGridInitializedRef = useRef(false)

  const isVisible = !isLoading || widgets.length > 0

  const layout = useMemo(() => getLayoutFromWidgets(widgets, isDraggable), [isDraggable, widgets])

  const handleToggleVisibilityClick = useCallback(
    (id: WidgetId, shouldHide: boolean) => {
      toggleWidgetVisibility({ id, shouldHide })
    },
    [toggleWidgetVisibility],
  )

  const handleWidgetsGridChange = useCallback(
    (newLayout: Grid.Layout[]) => {
      if (!isGridInitializedRef.current) {
        isGridInitializedRef.current = true
        return
      }

      const isLayoutChanged = newLayout.some((item, index) => {
        const prevItem = layout[index]

        // Check if the layout changed after drag ended, using the index and coordinates
        return prevItem && !isEqual([prevItem.i, prevItem.x, prevItem.y], [item.i, item.x, item.y])
      })

      if (isLayoutChanged) {
        setWidgets(getWidgetsFromLayout(newLayout, !isDraggable))
      }
    },
    [isDraggable, layout, setWidgets],
  )

  return (
    <Styled.WidgetsSectionWrapper>
      {title && <Styled.TitleSection>{title}</Styled.TitleSection>}
      {isVisible && (
        <WidgetsGrid
          cols={2}
          compactType="vertical"
          containerPadding={[WIDGET_PADDING, WIDGET_PADDING]}
          draggableCancel=".hide-widget-button"
          isDraggable={isEditMode && isDraggable}
          isResizable={false}
          layout={layout}
          margin={[WIDGET_MARGIN, WIDGET_MARGIN]}
          onLayoutChange={handleWidgetsGridChange}
          rowHeight={270}
        >
          {widgets.map(({ id, isHidden = false }) => {
            const WidgetComponent = widgetToComponent[id]
            const { isStatic } = widgetSettingsByWidgetId[id]

            if (!WidgetComponent) {
              return null
            }

            return (
              <DraggableWidget
                id={id}
                isDraggable={isDraggable}
                isHidden={isHidden}
                isLoading={isLoading}
                isStatic={isStatic}
                key={id}
                onToggleVisibility={handleToggleVisibilityClick}
              >
                <WidgetComponent />
              </DraggableWidget>
            )
          })}
        </WidgetsGrid>
      )}
    </Styled.WidgetsSectionWrapper>
  )
}
