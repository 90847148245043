import { Button, ModalConfirmation, notify, SettingsLayout, Space, Text, useModal } from '@design-system'

import { ReactElement, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { APIError } from '../../../../../../utils'
import { TaxMetaField } from '../../types/taxMetaField'
import { AdditionalFieldsTable } from './elements/AdditionalFieldsTable/AdditionalFieldsTable'
import { CreateOrEditAdditionalFieldModal } from './elements/CreateOrEditAdditionalFieldModal'
import { useDeleteAdditionalField } from './hooks/useDeleteAdditionalField'
import { useInvalidateAdditionalFields } from './hooks/useInvalidateAdditionalFields'
import * as Styled from './styles'

const DELETE_ADDITIONAL_FIELD_CONFIRMATION_MODAL_ID = 'delete-additional-field-confirmation-modal-id'

export const AdditionalFields = (): ReactElement => {
  const { t } = useTranslation()
  const additionalFieldToEditRef = useRef<TaxMetaField>()
  const { open: openCreateOrEditAdditionalFieldModal } = useModal(ModalId.CreateOrEditAdditionalFieldModal, {
    onClose: () => {
      additionalFieldToEditRef.current = undefined
    },
  })

  const additionalFieldIdToDeleteRef = useRef<string>()
  const { invalidateAdditionalFields } = useInvalidateAdditionalFields()

  const { open: openDeleteConfirmation, close: closeDeleteConfirmation } = useModal(
    DELETE_ADDITIONAL_FIELD_CONFIRMATION_MODAL_ID,
    {
      onClose: () => {
        additionalFieldIdToDeleteRef.current = undefined
      },
    },
  )

  const handleDeleteAdditionalFieldError = useCallback(
    (error: APIError | undefined) => {
      notify({
        id: 'additional-field-deleted',
        message: error?.message || t('settings.organization.vat_rates.rates.notification.delete_error'),
        variant: 'error',
      })
    },
    [t],
  )

  const handleDeleteAdditionalFieldSuccess = useCallback(() => {
    notify({
      id: 'additional-field-deleted',
      message: t('settings.organization.vat_rates.rates.notification.delete_success'),
      variant: 'success',
    })
    closeDeleteConfirmation()
    invalidateAdditionalFields()
  }, [closeDeleteConfirmation, invalidateAdditionalFields, t])

  const { delete: deleteAdditionalField, isLoading: isDeleting } = useDeleteAdditionalField({
    onSuccess: handleDeleteAdditionalFieldSuccess,
    onError: handleDeleteAdditionalFieldError,
  })

  const handleAdditionalFieldDelete = useCallback(
    (vatAccountId: string) => {
      additionalFieldIdToDeleteRef.current = vatAccountId
      openDeleteConfirmation()
    },
    [openDeleteConfirmation],
  )

  const handleDeleteModalOkClick = useCallback(() => {
    if (additionalFieldIdToDeleteRef.current) {
      deleteAdditionalField(additionalFieldIdToDeleteRef.current)
    }
  }, [deleteAdditionalField])

  const handleCreateAdditionalField = useCallback(() => {
    openCreateOrEditAdditionalFieldModal()
  }, [openCreateOrEditAdditionalFieldModal])

  const handleEditVatAdditionalField = useCallback(
    (additionalField: TaxMetaField) => {
      if (additionalField.isPredefined) {
        notify({
          id: 'additional-field-edit-predefined',
          message: t('settings.organization.tax_return.cant_edit_predefined'),
          variant: 'warning',
        })
        return
      }

      additionalFieldToEditRef.current = additionalField
      openCreateOrEditAdditionalFieldModal()
    },
    [openCreateOrEditAdditionalFieldModal, t],
  )

  return (
    <SettingsLayout
      title={t('settings.organization.tax_return.meta_fields')}
      sideActions={
        <Button onClick={handleCreateAdditionalField} variant="secondary">
          {t('settings.organization.tax_return.create_meta_field')}
        </Button>
      }
    >
      <Styled.TableWrapper>
        <Text variant="small" colorVariant="secondary">
          {t('settings.organization.tax_return.meta_fields_tip')}
        </Text>
        <Space size="xl" />
        <AdditionalFieldsTable
          onEditAdditionalFieldModal={handleEditVatAdditionalField}
          onAdditionalFieldDelete={handleAdditionalFieldDelete}
        />
      </Styled.TableWrapper>

      <CreateOrEditAdditionalFieldModal additionalField={additionalFieldToEditRef.current} />
      <ModalConfirmation
        cancelLabel={t('cancel')}
        danger
        id={DELETE_ADDITIONAL_FIELD_CONFIRMATION_MODAL_ID}
        message={t('settings.organization.tax_return.modals.delete.message')}
        okLabel={t('delete')}
        okLoading={isDeleting}
        onOk={handleDeleteModalOkClick}
        title={t('settings.organization.tax_return.modals.delete.title')}
      />
    </SettingsLayout>
  )
}
