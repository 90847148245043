import { ProcessingOverlay, SettingsSection } from '@components'
import { Button, Color, FormItemsGroup, InputPassword } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { usePasswordForm } from '../../hooks/usePasswordForm'
import * as Styled from './styles'

export const PasswordForm = (): ReactElement => {
  const { Form, FormItem, isSubmitting: isProcessing } = usePasswordForm()
  const { t } = useTranslation()

  return (
    <SettingsSection>
      <Form name="user-password">
        <SettingsSection.Content title={t('settings.user.change_password')}>
          <Styled.FormFieldsWrapper>
            {isProcessing && <ProcessingOverlay color={Color.White} />}
            <FormItemsGroup>
              <FormItem
                label={t('change_password.old_password')}
                name="oldPassword"
                render={({ field }) => <InputPassword {...field} />}
              />
              <FormItem
                label={t('change_password.new_password')}
                name="newPassword"
                render={({ field }) => <InputPassword {...field} />}
              />
            </FormItemsGroup>
          </Styled.FormFieldsWrapper>
        </SettingsSection.Content>
        <SettingsSection.Footer>
          <Button loading={isProcessing} size="l" type="submit">
            {t('change_password.change_password')}
          </Button>
        </SettingsSection.Footer>
      </Form>
    </SettingsSection>
  )
}
