import React, { forwardRef, ReactElement, ReactNode, Ref } from 'react'

import * as Styled from './styles'

interface ImageMagnifierProps {
  children: ReactNode
}

export const ImageMagnifier = forwardRef(
  (props: ImageMagnifierProps, forwardedRef: Ref<HTMLDivElement>): ReactElement => (
    <Styled.ImageMagnifierWrapper ref={forwardedRef} {...props} />
  ),
)
