const IMAGE_PERCENT_SENSITIVITY_CHANGE = 10

export const shouldRefetchImage = (
  oldWith: number,
  oldHeight: number,
  newWidth: number,
  newHeight: number,
): boolean => {
  if (oldWith > newWidth && oldHeight > newHeight) {
    return false
  }

  if ((!oldWith && newWidth) || (!oldHeight && newHeight)) {
    return true
  }

  const widthPercent = (newWidth * 100) / oldWith
  const heightPercent = (newHeight * 100) / oldHeight
  const widthDiff = Math.abs(100 - widthPercent)
  const heightDiff = Math.abs(100 - heightPercent)

  return widthDiff > IMAGE_PERCENT_SENSITIVITY_CHANGE || heightDiff > IMAGE_PERCENT_SENSITIVITY_CHANGE
}
