import { Checkbox, CheckboxProps, Space } from '@design-system'

import { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface ScrollableListItemProps extends Omit<CheckboxProps, 'disabled'> {
  children: ReactNode
  disabled?: boolean
  highlighted?: boolean
  id: string
}

export const ScrollableListItem = ({
  children,
  disabled,
  highlighted,
  id,
  ...checkboxProps
}: ScrollableListItemProps): ReactElement => (
  <Styled.ScrollableListItemWrapper key={id} disabled={disabled} highlighted={highlighted}>
    <Checkbox {...checkboxProps} disabled={disabled} value={id} />
    <Space horizontal size="m" />
    {children}
  </Styled.ScrollableListItemWrapper>
)
