import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconTwoColumns = ({
  color = 'inherit',
  width = '20px',
  height = '20px',
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-two-columns')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={coloring(color)} d="M28,1h-8v30h8c1.657,0,3-1.343,3-3V4C31,2.343,29.657,1,28,1z"></path>
      <path
        fill={coloring(bg)}
        d="M28,32h-9V0h9c2.206,0,4,1.794,4,4v24C32,30.206,30.206,32,28,32z M21,30h7c1.103,0,2-0.897,2-2V4 c0-1.103-0.897-2-2-2h-7V30z"
      ></path>
      <path fill={coloring(color)} d="M12,1H4C2.343,1,1,2.343,1,4v24c0,1.657,1.343,3,3,3h8V1z"></path>
      <path
        fill={coloring(bg)}
        d="M13,32H4c-2.206,0-4-1.794-4-4V4c0-2.206,1.794-4,4-4h9V32z M4,2C2.897,2,2,2.897,2,4v24 c0,1.103,0.897,2,2,2h7V2H4z"
      ></path>
    </svg>
  </Box>
)
