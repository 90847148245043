import { ChangeEvent, useCallback } from 'react'

import { useBankLines } from '../../../../../../contexts/bankLinesContext'
import { ScrollableList } from '../../../../../ScrollableList'
import { BankLinesListItemContent } from './elements/BankLinesListItemContent'

export const BankLinesList = () => {
  const { bankLines, selectedBankLinesIds, setSelectedBankLinesIds } = useBankLines()

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        setSelectedBankLinesIds([...selectedBankLinesIds, event.currentTarget.value])
        return
      }

      setSelectedBankLinesIds(selectedBankLinesIds.filter((id) => id !== event.currentTarget.value))
    },
    [selectedBankLinesIds, setSelectedBankLinesIds],
  )

  return (
    <ScrollableList onChange={handleCheckboxChange} value={selectedBankLinesIds}>
      {bankLines?.map((bankLine) => {
        const bankLineId = bankLine?.id
        const isSelected = selectedBankLinesIds.includes(bankLineId)

        return (
          <ScrollableList.Item id={bankLineId} key={bankLineId} highlighted={isSelected}>
            <BankLinesListItemContent bankLine={bankLine} />
          </ScrollableList.Item>
        )
      })}
    </ScrollableList>
  )
}
