import { Button, Color, Link, Space, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../hooks'
import { useFindAccountant } from '../../contexts/findAccountantContext'
import { FindAccountantFormSchema } from '../../utils/formData'
import { isSummaryFilled } from '../../utils/isSummaryFilled'
import { Steps } from '../Steps'
import * as Styled from './styles'

export const SummarySection = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem, watch } = useFormContext<FindAccountantFormSchema>()
  const { isSaving, isSubmitted, submitForm } = useFindAccountant()

  const isAccepted = watch('isAccepted')
  const issue = watch('issue')
  const expectations = watch('expectations')
  const isBusinessInfoCorrect = watch('isBusinessInfoCorrect')
  const summary = watch('summary')
  const isButtonDisabled =
    isSubmitted || !isAccepted || !issue || !expectations || !isBusinessInfoCorrect || !isSummaryFilled(summary)

  return (
    <Styled.SummarySectionWrapper>
      <Styled.Title color={Color.Gray60} variant="h3">
        {t('find_accountant.summary.title')}
      </Styled.Title>
      <Styled.ContentWrapper>
        <Steps />
        <Styled.Content colorVariant="secondary" variant="small">
          <Text as="span" inline inherit variant="small">
            {t('find_accountant.summary.content.when_you')}
          </Text>
          <Space size="xs" />
          <Text as="span" inline inherit variant="small">
            {t('find_accountant.summary.content.you_can')}&nbsp;
            <Link target="_blank" rel="noreferrer" href="https://www.billy.dk/revisor/find-selv-revisor/" withAnimation>
              {t('find_accountant.summary.content.link')}
            </Link>
          </Text>
        </Styled.Content>
      </Styled.ContentWrapper>
      <FormItem
        name="isAccepted"
        render={({ field: { value, ...props } }) => (
          <Styled.Switch checked={value} {...props}>
            <Text colorVariant="secondary" variant="micro">
              {t('find_accountant.summary.agreement')}
            </Text>
          </Styled.Switch>
        )}
      />
      <Button
        disabled={isButtonDisabled}
        fullWidth
        icon="calculator"
        loading={isSaving}
        onClick={submitForm}
        size="xxl"
      >
        {t('find_accountant.summary.get_quote')}
      </Button>
    </Styled.SummarySectionWrapper>
  )
}
