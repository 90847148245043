import React, { createContext, ReactElement, ReactNode, useContext } from 'react'

import { Account } from '@modules-deprecated/app/accounts/types'
import { BankConnection } from '@modules-deprecated/bankReconciliation/types'

import { EmberEventFn } from '../../../types/emberEventFn'

interface RenewConnectionEventProps {
  bankConnection: BankConnection
  bankAccount: Account
}

interface RemoveConnectionEventProps {
  bankAccount: Account
}

interface ContextState {
  accountId: string
  renewConnection: (bankConnection: BankConnection, bankAccount: Account) => void
  removeConnection: (bankAccount: Account) => void
}

const BankSettingsContext = createContext<ContextState | undefined>(undefined)

interface BankSettingsContextProps {
  children?: ReactNode
  accountId: string
  renewConnection: EmberEventFn<RenewConnectionEventProps>
  removeConnection: EmberEventFn<RemoveConnectionEventProps>
}

export const BankSettingsContextProvider = ({
  accountId,
  renewConnection,
  removeConnection,
  children,
}: BankSettingsContextProps): ReactElement => {
  const handleRenewConnection = (bankConnection: BankConnection, bankAccount: Account) => {
    renewConnection({ detail: { bankConnection, bankAccount } })
  }

  const handleRemoveConnection = (bankAccount: Account) => {
    removeConnection({ detail: { bankAccount } })
  }
  return (
    <BankSettingsContext.Provider
      value={{ renewConnection: handleRenewConnection, removeConnection: handleRemoveConnection, accountId }}
    >
      {children}
    </BankSettingsContext.Provider>
  )
}

export const useBankSettings = () => {
  const context = useContext(BankSettingsContext)

  if (!context) {
    throw new Error('BankSettingsContextProvider is missing in the module!')
  }

  return context
}
