import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SubscriptionDetailsWrapper = styled.div``

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Spacing.L};
`
