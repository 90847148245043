import { Account } from '@modules-deprecated/app/accounts/types'
import { Organization } from '@modules-deprecated/app/organization/types/organization'
import { Product } from '@modules-deprecated/app/products/types'
import { Invoice } from '@modules-deprecated/bankReconciliation/types'
import { Bill } from '@modules-deprecated/inbox/types'
import { Contact } from '@views/contacts/types/contact'

import { Metable } from '../../types/metable'
import { getRequest } from '../../utils'

export interface SearchGloballyResponseData extends Metable {
  accounts?: Account[]
  bills?: Bill[]
  contacts?: Contact[]
  invoices?: Invoice[]
  organizations?: Organization[]
  products?: Product[]
}

export const searchGlobally = (organizationId: string, query: string): Promise<SearchGloballyResponseData> => {
  return getRequest(`/v2/search?organizationId=${organizationId}&q=${query}`)
}
