import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const Rule = styled.div`
  padding-top: ${Spacing.S};
  padding-bottom: ${Spacing.XXS};
`

export const TextCenteringWrapper = styled.div`
  align-self: center;
`
