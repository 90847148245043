import { FormGroup } from '@components-deprecated'
import { Input } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useForm } from '../../../../../../../../hooks'
import * as Styled from './styles'
import { CouponCodeForm as CouponCodeFormType, defaultValues, getValidationSchema } from './utils/formData'

interface CouponCodeFormProps {
  onSubmit: (couponCode: string) => void
}

export const CouponCodeForm = ({ onSubmit }: CouponCodeFormProps): ReactElement => {
  const { t } = useTranslation()
  const { Form, FormItem, handleSubmit, watch } = useForm({
    defaultValues,
    validationSchema: useMemo(() => getValidationSchema(), []),
  })

  const couponCode = watch('couponCode')

  const handleFormSubmit = useCallback(
    (form: CouponCodeFormType) => {
      onSubmit(form.couponCode)
    },
    [onSubmit],
  )

  const handleSubmitClick = useCallback(() => {
    handleSubmit(handleFormSubmit)()
  }, [handleFormSubmit, handleSubmit])

  return (
    <Styled.CouponCodeFormWrapper>
      <Form>
        <FormGroup>
          <FormItem
            name="couponCode"
            render={({ field }) => <Input {...field} placeholder={t('billing_summary.footer.coupon_code.open')} />}
          />
          <Styled.SubmitButton
            disabled={!couponCode}
            fullWidth
            onClick={handleSubmitClick}
            size="m"
            variant="secondary"
          >
            {t('billing_summary.footer.coupon_code.button')}
          </Styled.SubmitButton>
        </FormGroup>
      </Form>
    </Styled.CouponCodeFormWrapper>
  )
}
