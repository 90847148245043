import { rwdMenuSpacingForTitle, Spacing, Text as TextComponent } from '@design-system'

import { css, SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'
import cc from 'classcat'
import React, { ReactNode } from 'react'
import { Flex, FlexProps } from 'rebass'

import { reactClass } from '../../../utils'

export type HeaderProps = Omit<FlexProps, 'title'> & {
  css?: SerializedStyles
  title?: ReactNode
  withMargin?: boolean
}

const Text = styled(TextComponent)`
  ${rwdMenuSpacingForTitle}
`

export const Header = ({ children, className, css: styles, title, withMargin, ...rest }: HeaderProps) => (
  <Flex
    className={cc([className, reactClass('header')])}
    variant="moduleHeader"
    css={[
      css`
        width: 100%;
        padding: ${Spacing.XL} ${Spacing.XXXL};
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: ${withMargin ? '35px' : 'initial'};
      `,
      styles,
    ]}
    {...rest}
  >
    {typeof title === 'string' ? (
      <Text variant="h1" truncate>
        {title}
      </Text>
    ) : (
      title || null
    )}
    <Flex
      css={css`
        height: 40px;
        margin-left: auto;
        align-items: center;
        flex-shrink: 0;

        & > *:not(:first-of-type) {
          margin-left: 10px;
        }
      `}
    >
      {children}
    </Flex>
  </Flex>
)
