import React, { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { EmberEventFn } from '../../../types/emberEventFn'

interface ContextState {
  createNewContact: () => void
}

const ContactsEmberConnectionContext = createContext<ContextState | undefined>(undefined)

interface ContactsEmberConnectionContextProps {
  children?: ReactNode
  onCreateNewContact: EmberEventFn
}

export const ContactsEmberConnectionContextProvider = ({
  onCreateNewContact,
  children,
}: ContactsEmberConnectionContextProps): ReactElement => {
  const createNewContact = useCallback(() => {
    onCreateNewContact({ detail: {} })
  }, [onCreateNewContact])

  return (
    <ContactsEmberConnectionContext.Provider value={{ createNewContact }}>
      {children}
    </ContactsEmberConnectionContext.Provider>
  )
}

export const useContactsEmberConnection = () => {
  const context = useContext(ContactsEmberConnectionContext)

  if (!context) {
    throw new Error('ContactsEmberConnectionContext is missing in the module!')
  }

  return context
}
