import React from 'react'

import * as Styled from './styles'

export const RelaxImage = () => (
  <Styled.Icon>
    <svg width="298" height="209" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.602 208.252c59.772 0 98.435-2.196 98.435-52.832 0-50.637-22.127-111.1475-108.227-111.1475-86.1006 0-108.227 60.5105-108.227 111.1475 0 50.636 58.2467 52.832 118.019 52.832z"
        fill="#5ABCAD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M277.77 191.49c4.999 1.919 8.547 3.174 10.644 3.764 3.144.886 10.159 1.993 9.192 4.207-.968 2.214-16.933 1.329-23.222 1.329-4.193 0-5.806-2.879-4.838-8.636l8.224-.664z"
        fill="#F1BFBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.988 125.822c0 19.14 5.435 29.798 16.304 31.973 16.305 3.262 54.907 1.522 58.983 2.392 4.076.87 56.444 32.517 61.14 34.365 4.696 1.849 10.55-1.087 9.112-3.48-.748-1.243-50.899-51.547-58.332-54.593-10.843-4.442-42.439-7.177-45.556-8.047-2.078-.58-3.756-1.668-5.035-3.263l-36.616.653z"
        fill="#4274A4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M267.031 191.49c5.915 1.919 9.911 3.174 11.989 3.764 3.117.886 10.071 1.993 9.112 4.207-.959 2.214-16.305 1.329-22.539 1.329-4.156 0-5.755-2.879-4.796-8.636l6.234-.664z"
        fill="#FFD7D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.683 128.084c0 19.122 5.44 29.77 16.322 31.943 16.322 3.259 52.807 2.246 56.888 3.115 4.08.869 65.388 32.63 70.09 34.477 4.701 1.846 10.226-2.699 8.786-5.089-1.44-2.39-45.752-48.8-63.754-50.973-18.003-2.173-38.885-6.305-44.646-7.823-3.841-1.012-6.001-2.895-6.481-5.65h-37.205z"
        fill="#5184B4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.234 63.1418c3.386-6.6203 4.837-10.7939 4.353-12.521-.484-1.727-13.062-5.607-37.734-11.6399 2.74-2.3027 6.449-3.454 11.124-3.454 7.014 0 44.264 5.1635 44.264 12.9351 0 2.6727-1.529 6.2759-2.902 9.7146-.859 2.1515-2.471 5.5336-4.837 10.1463l-14.268-5.1811z"
        fill="#FFD7D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.601 65.95c-2.552-2.4487-4.3863-4.4652-5.5028-6.0497-1.1165-1.5844 5.5826-7.202 20.0968-16.8527l8.135-9.0745c.798-3.0248-.877-3.9611-5.024-2.8088-2.647.7355-8.304 6.9481-9.57 7.5621C78.7916 49.848 69.915 56.7571 69.915 59.9003c0 3.457 5.6623 9.8668 16.987 19.2294L93.601 65.95z"
        fill="#FFD7D5"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="69" y="30" width="48" height="50">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.601 65.95c-2.552-2.4487-4.3863-4.4652-5.5028-6.0497-1.1165-1.5844 5.5826-7.202 20.0968-16.8527l8.135-9.0745c.798-3.0248-.877-3.9611-5.024-2.8088-2.647.7355-8.304 6.9481-9.57 7.5621C78.7916 49.848 69.915 56.7571 69.915 59.9003c0 3.457 5.6623 9.8668 16.987 19.2294L93.601 65.95z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.1572 68.4524c-5.2636-4.4653-7.3371-7.6342-6.2206-9.5067 1.9959-3.3473 10.1423-8.8448 18.9014-12.5315 16.747-7.0493 12.52.2967-12.6808 22.0382z"
          fill="#F0BDBA"
          opacity=".340011"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.522 71.8659c4.792 0 24.198 0 29.468-4.773 5.271-4.773 5.271-6.9426 6.948-7.8104 1.677-.8678 13.417 6.9426 13.417 7.8104 0 .8678-5.858 13.8506-5.271 17.5733.958 6.0748 11.979 36.4488 16.531 41.8728-6.229 2.748-9.983 3.615-11.26 2.603-5.192-4.113-12.179 5.402-15.813 4.556-8.385-1.953-17.728 0-27.072 0-2.715-23.034-6.628-36.2683-11.739-39.7027-4.952-3.3275-11.6285-8.387-20.0291-15.1783-.4815-.3893-.6175-1.0216-.3323-1.546l6.4701-11.9014c.3506-.645 1.2123-.9105 1.9245-.593.0486.0217.0959.0459.1415.0724 8.0503 4.6779 13.5893 7.0169 16.6163 7.0169z"
        fill="#F9A24F"
      />
      <mask id="b" maskUnits="userSpaceOnUse" x="85" y="59" width="87" height="75">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.522 71.8659c4.792 0 24.198 0 29.468-4.773 5.271-4.773 5.271-6.9426 6.948-7.8104 1.677-.8678 13.417 6.9426 13.417 7.8104 0 .8678-5.858 13.8506-5.271 17.5733.958 6.0748 11.979 36.4488 16.531 41.8728-6.229 2.748-9.983 3.615-11.26 2.603-5.192-4.113-12.179 5.402-15.813 4.556-8.385-1.953-17.728 0-27.072 0-2.715-23.034-6.628-36.2683-11.739-39.7027-4.952-3.3275-11.6285-8.387-20.0291-15.1783-.4815-.3893-.6175-1.0216-.3323-1.546l6.4701-11.9014c.3506-.645 1.2123-.9105 1.9245-.593.0486.0217.0959.0459.1415.0724 8.0503 4.6779 13.5893 7.0169 16.6163 7.0169z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.4199 73.1675c16.6906 6.364 28.9491 13.9574 36.7751 22.7802 7.826 8.8233 22.72 20.5383 44.681 35.1463l-37.134 19.743-22.281-44.475-22.0411-33.1945z"
          fill="#A37E5B"
          opacity=".237946"
        />
      </g>
      <mask id="c" maskUnits="userSpaceOnUse" x="85" y="59" width="87" height="75">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.522 71.8659c4.792 0 24.198 0 29.468-4.773 5.271-4.773 5.271-6.9426 6.948-7.8104 1.677-.8678 13.417 6.9426 13.417 7.8104 0 .8678-5.858 13.8506-5.271 17.5733.958 6.0748 11.979 36.4488 16.531 41.8728-6.229 2.748-9.983 3.615-11.26 2.603-5.192-4.113-12.179 5.402-15.813 4.556-8.385-1.953-17.728 0-27.072 0-2.715-23.034-6.628-36.2683-11.739-39.7027-4.952-3.3275-11.6285-8.387-20.0291-15.1783-.4815-.3893-.6175-1.0216-.3323-1.546l6.4701-11.9014c.3506-.645 1.2123-.9105 1.9245-.593.0486.0217.0959.0459.1415.0724 8.0503 4.6779 13.5893 7.0169 16.6163 7.0169z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#c)" stroke="#fff" strokeWidth="7">
        <path
          opacity=".395592"
          clipRule="evenodd"
          d="M117.352 112.88c2.538 0 4.595-1.864 4.595-4.162 0-2.299-2.057-4.162-4.595-4.162-2.539 0-4.596 1.863-4.596 4.162 0 2.298 2.057 4.162 4.596 4.162zM147.298 66.5646c3.587 0 6.495-2.6334 6.495-5.8819s-2.908-5.8819-6.495-5.8819-6.495 2.6334-6.495 5.8819 2.908 5.8819 6.495 5.8819zM145.027 132.785l9.617-1.539-11.307-7.17 1.69 8.709zM155.257 108.452l10.35 8.532 1.374-14.21-11.724 5.678zM95.2432 62.064l4.2631 12.0729 9.0267-11.6714-13.2898-.4015z"
        />
        <path
          opacity=".3"
          d="M97.9624 87.1902l6.0616.1533-1.314-4.3627 6.225-.2548M133.487 101.736l5.743-4.1327-4.948-3.1157 5.545-4.6258"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.079 59.696c1.194 4.8937 2.41 8.0842 3.646 9.5714.735.8828 2.159 5.0485-2.962 6.6883-3.851 1.2329-7.333-1.4606-7.63-3.1436-.513-2.9079-4.034-9.1136-5.27-11.9786-.824-1.91 3.248-2.2892 12.216-1.1375z"
        fill="#FFD7D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.96 58.6147c.957 3.5457 2.014 5.837 3.171 6.8738.687.6156.624 4.121-4.508 4.2056-4.015.0662-6.898-2.1379-7.114-3.3626-.373-2.1161-2.625-4.2601-3.717-6.3109-.729-1.3672 3.328-1.8358 12.168-1.4059z"
        fill="#F0BDBA"
        opacity=".340011"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.021 42.3009c3.253 4.6888 5.896 7.2926 7.928 7.8113 3.048.7781 1.621 3.0031-.143 3.3143-1.765.3111 6.996 6.6599 1.912 10.8454-5.084 4.1855-16.941 2.987-20.745-6.209-2.537-6.1308-2.731-11.3589-.583-15.6845l11.631-.0775z"
        fill="#FFD7D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.942 49.7264c-.599.1055-1.161-.2474-1.257-.7883-.095-.5409.313-1.0649.911-1.1704.599-.1055 1.161.2474 1.256.7883.096.5408-.312 1.0648-.91 1.1704z"
        fill="#88583F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.218 45.2022c3.378-10.2432 2.216-16.3971-3.485-18.4615-8.552-3.0967-21.606 13.6766-20.481 20.0563-3.7854 3.2986-4.8325 6.9915-3.141 11.0786 2.536 6.1307 12.756 6.3022 15.129 3.2525 1.582-2.0332 1.576-6.2386-.018-12.6165 4.608.0898 7.898-1.8488 9.868-5.8159l2.128 2.5065z"
        fill="#A78555"
      />
      <path
        d="M108.421 40.18c2.436-6.4487 7.041-10.2703 13.815-11.4647"
        stroke="#836740"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.493 55.0426c-1.91-1.1984-5.363-.4237-8.486 3.9489-2.082 2.915-6.382 1.6276-12.902-3.8623l.63 3.5736c3.145 4.6584 5.756 7.2684 7.836 7.83 5.157 1.3928 10.954.2651 13.337-1.4305 5.735-4.0818 1.495-8.8612-.415-10.0597z"
        fill="#A78555"
      />
      <path
        d="M120.021 72.0213c1.12 3.7332 3.754 5.3152 7.901 4.746 4.147-.5691 5.82-3.0153 5.018-7.3387"
        stroke="#F08A29"
        strokeWidth="6"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.9096 189.281c26.4394 0 66.1864-5.121 54.6274-36.038-11.558-30.917-28.188-50.248-54.6274-50.248-26.4398 0-47.8735 19.316-47.8735 43.143 0 23.828 21.4337 43.143 47.8735 43.143z"
        fill="#5ABCAD"
      />
      <path
        d="M90.5078 102.497c12.1462-1.232 22.8762 1.149 32.1882 7.144 18.496 11.906 29.919 33.475 34.83 61.046"
        stroke="#3F9F90"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.0216 23.7068c7.34 0 13.2902-5.3069 13.2902-11.8534C69.3118 5.30695 63.3616 0 56.0216 0c-7.3399 0-13.2902 5.30695-13.2902 11.8534 0 6.5465 5.9503 11.8534 13.2902 11.8534z"
        fill="#FFBF62"
      />
      <path
        d="M51.207 10.9593l3.4468 3.4397 6.1802-5.09089"
        stroke="#FBFCFD"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M243.323 66.7058c7.34 0 13.291-5.3069 13.291-11.8534 0-6.5464-5.951-11.8534-13.291-11.8534-7.34 0-13.29 5.307-13.29 11.8534 0 6.5465 5.95 11.8534 13.29 11.8534z"
        fill="#FFBF62"
      />
      <path
        d="M238.51 53.9583l3.446 3.4397 6.181-5.0909"
        stroke="#FBFCFD"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6034 187.742c-5.8202-4.184-9.6707-10.277-11.55158-18.278-1.67961-7.145.2391-16.344 1.88074-23.168 8.14794 7.445 13.21264 13.096 15.19424 16.953 2.343 4.561 3.765 11.538 4.2662 20.93l-9.7896 3.563z"
        fill="#80DA99"
      />
      <mask id="d" maskUnits="userSpaceOnUse" x="7" y="145" width="20" height="43">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8441 187.949c-5.0051-5.132-7.73912-11.801-8.20204-20.007-.41338-7.328 3.07354-16.054 5.87514-22.489 6.7315 8.746 10.738 15.191 12.0196 19.334 1.5154 4.898 1.7044 12.016.5669 21.353l-10.2596 1.809z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#d)" stroke="#62C87E" strokeWidth="2" strokeLinecap="round">
        <path d="M21.3403 178.335l4.4509-6.698M15.7679 166.177l3.2508-5.172M11.6427 154.585l1.5038-3.276M18.0826 172.459l-7.4029-.976M13.1783 160.44l-4.49568-1.117M9.09766 145.015c3.30484 15.039 8.71814 28.612 16.23984 40.718" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity=".396429">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.2257 194.638c5.8202-4.185 9.6707-10.277 11.5516-18.279 1.6796-7.145-.2391-16.344-1.8808-23.167-8.1479 7.444-13.2126 13.095-15.1942 16.953-2.343 4.561-3.765 11.537-4.2662 20.93l9.7896 3.563z"
          fill="#80DA99"
        />
        <mask id="e" maskUnits="userSpaceOnUse" x="19" y="152" width="21" height="43">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.985 194.844c5.0051-5.131 7.7391-11.8 8.202-20.007.4134-7.328-3.0735-16.054-5.8751-22.489-6.7315 8.747-10.738 15.191-12.0196 19.334-1.5154 4.899-1.7044 12.016-.5669 21.353l10.2596 1.809z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#e)" stroke="#62C87E" strokeWidth="2" strokeLinecap="round">
          <path d="M25.4888 185.23l-4.4509-6.698M31.0612 173.073l-3.2508-5.173M35.1864 161.48l-1.5038-3.275M28.7465 179.355l7.4029-.977M33.6508 167.335l4.4957-1.116M37.7314 151.91c-3.3048 15.04-8.7181 28.613-16.2398 40.719" />
        </g>
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.0118 194.638c5.8202-4.185 9.6707-10.277 11.5516-18.279 1.6796-7.145-.2391-16.344-1.8807-23.167-8.1479 7.444-13.2127 13.095-15.1942 16.953-2.343 4.561-3.7651 11.537-4.2663 20.93l9.7896 3.563z"
        fill="#80DA99"
      />
      <mask id="f" maskUnits="userSpaceOnUse" x="25" y="152" width="21" height="43">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.7712 194.844c5.005-5.131 7.7391-11.8 8.202-20.007.4134-7.328-3.0736-16.054-5.8752-22.489-6.7314 8.747-10.7379 15.191-12.0196 19.334-1.5153 4.899-1.7043 12.016-.5669 21.353l10.2597 1.809z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#f)" stroke="#62C87E" strokeWidth="2" strokeLinecap="round">
        <path d="M31.274 185.23l-4.451-6.698M36.8463 173.073l-3.2507-5.173M40.9715 161.48l-1.5038-3.275M34.5316 179.355l7.403-.977M39.436 167.335l4.4956-1.116M43.5166 151.91c-3.3049 15.04-8.7182 28.613-16.2398 40.719" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5947 181.263h32.8528l-3.4446 22.489c-.3272 2.136-2.3676 3.728-4.7783 3.728h-16.407c-2.4107 0-4.4511-1.592-4.7783-3.728l-3.4446-22.489z"
        fill="#4980FE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1359 200.565c2.4395 0 4.4171-1.768 4.4171-3.95 0-2.182-1.9776-3.951-4.4171-3.951s-4.4171 1.769-4.4171 3.951c0 2.182 1.9776 3.95 4.4171 3.95z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.873 183.52l32.5263-.373-1.4055 8.057H14.0777l-1.2047-7.684z"
        fill="#4980FE"
        opacity=".383315"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4392 179.929h31.163c2.3724 0 4.2956 1.72 4.2956 3.842s-1.9232 3.842-4.2956 3.842h-31.163c-2.3724 0-4.29565-1.72-4.29565-3.842s1.92325-3.842 4.29565-3.842z"
        fill="#4980FE"
      />
    </svg>
  </Styled.Icon>
)
