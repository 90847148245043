import { EmberRoute } from '../enums/emberRoute'
import { useEmberRouteToUrlGenerator } from './useEmberRouteToUrlGenerator'

interface UseEmberRouteUrlResult {
  url: string | undefined
}

export const useEmberRouteUrl = (emberRoute: EmberRoute): UseEmberRouteUrlResult => {
  const { generateUrl } = useEmberRouteToUrlGenerator()

  if (!generateUrl) {
    return { url: undefined }
  }

  return { url: generateUrl(emberRoute) }
}
