import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { StyledComponent } from '../../../types/styledComponent'

export const getPressedStyle = (Control: StyledComponent, checked: boolean): SerializedStyles => css`
  &:active {
    ${Control} {
      box-shadow: none;

      ${checked
        ? css`
            border-color: ${Color.Green110};
            background-color: ${Color.Green110};

            /* Check circle inside */
            &:before {
              background-color: ${Color.White};
            }
          `
        : css`
            border-color: ${Color.Gray50};
            background-color: ${Color.Gray20};

            /* Check circle inside */
            &:before {
              background-color: ${Color.DeepSeaGreen};
            }
          `};
    }
  }
`
