import { getCurrentLocale } from '@design-system'

import { Metable } from '../../../../types/metable'
import { getRequest } from '../../../../utils'
import { SalesTaxPeriods } from './types/salesTaxPeriods'

interface GetOrganizationSalesTaxPeriodsResponseData extends Metable {
  periods: SalesTaxPeriods
}

export function getOrganizationSalesTaxPeriods(
  organizationId: string,
): Promise<GetOrganizationSalesTaxPeriodsResponseData> {
  const locale = getCurrentLocale()
  return getRequest(`/v2/vat/periods/${organizationId}`, { 'accept-language': locale })
}
