import { useUserOrganization } from '@modules-deprecated/app/organization'

import { TrainingData } from '../types/trainingData'
import { useBookkeepingCategoriesBookySearch } from './useBookkeepingCategoriesBookySearch'
import { useBookkeepingCategoriesMLSearch } from './useBookkeepingCategoriesMLSearch'

interface UseBookkeepingCategorySuggestionsOptions {
  searchQuery?: string
  trainingData?: TrainingData
  isEnabled?: boolean
}

export const useBookkeepingCategorySuggestions = ({
  searchQuery = '',
  trainingData,
  isEnabled = true,
}: UseBookkeepingCategorySuggestionsOptions) => {
  const { organization } = useUserOrganization()
  const countryId = organization?.countryId || 'DK'

  // for FR customers, use the new ML search
  const { isLoading, queryId, suggestedCategories } = useBookkeepingCategoriesMLSearch({
    isEnabled: isEnabled && countryId === 'FR',
    searchQuery,
    trainingData,
    isCategoriesPrefetchEnabled: isEnabled && countryId === 'FR',
  })

  // keep using Booky basic search for others
  const { suggestedCategories: suggestedBookyCategories, isLoading: isLoadingBooky } =
    useBookkeepingCategoriesBookySearch({
      searchQuery,
      isEnabled: isEnabled && countryId === 'DK',
    })

  return {
    isLoading: isLoading || isLoadingBooky,
    queryId: queryId || null,
    suggestedCategories: suggestedCategories.length ? suggestedCategories : suggestedBookyCategories,
  }
}
