import { Locale, Select, SelectProps } from '@design-system'

import { forwardRef, ReactElement, Ref, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CountryIsoCode } from '../../../types/countryIsoCode'
import { getAppBuildTheme } from '../../../utils/getAppBuildTheme'
import { locales } from '../../../utils/i18n'
import { CountryFlag } from '../../CountryFlag'
import { getLanguageItems } from './utils/getLanguageItems'

interface LanguageDropdownProps extends Omit<SelectProps<Locale>, 'items' | 'onSelect' | 'suffix'> {
  onSelect?: (value?: Locale) => void
}

export const LanguageSelect = forwardRef(
  ({ selectedId, onSelect, ...selectProps }: LanguageDropdownProps, ref: Ref<HTMLDivElement | null>): ReactElement => {
    const { t } = useTranslation()

    const theme = getAppBuildTheme()

    const isFrenchEnabled = theme === 'ageras'
    const languageItems = useMemo(
      () =>
        getLanguageItems(t).filter(({ id }) => {
          const frenchDisabledButSelected = !isFrenchEnabled && selectedId === locales.fr
          return id !== locales.fr || isFrenchEnabled || frenchDisabledButSelected
        }),
      [isFrenchEnabled, selectedId, t],
    )

    const selectedItem = useMemo(() => languageItems.find(({ id }) => selectedId === id), [selectedId, languageItems])

    const renderSuffix = useMemo(
      () => (selectedItem?.title ? <CountryFlag countryCode={selectedItem.title as CountryIsoCode} /> : null),
      [selectedItem],
    )

    const handleSelect = useCallback(
      (_id?: string, value?: Locale) => {
        onSelect?.(value)
      },
      [onSelect],
    )

    return (
      <Select
        {...selectProps}
        items={languageItems}
        dropdownSize={selectProps.dropdownSize || 'fitTrigger'}
        selectedId={selectedId}
        onSelect={handleSelect}
        suffix={renderSuffix}
        ref={ref}
      />
    )
  },
)
