import cc from 'classcat'
import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { LinkProps as LinkPropsRebass, Link as RebassLink } from 'rebass'

import { reactClass } from '../../utils'

export type LinkProps = LinkPropsRebass & {
  active?: boolean
  disabled?: boolean
  href?: string
  onClick?: (event?: MouseEvent) => void
  selected?: boolean
  target?: '_blank' | '_self' | '_parent' | '_top'
  text?: string
  title?: string
  to?: string
  variant?: 'action' | 'link' | 'navigation'
}

export const Link = ({
  active,
  children,
  className,
  disabled,
  href,
  onClick,
  selected,
  target = '_self',
  text = '',
  title = '',
  to = '',
  variant = 'link',
  ...rest
}: LinkProps) => {
  const classNames = cc([className, reactClass('link'), { active, disabled, selected }])

  // Do not exectute callback when disabled
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick()
    }
  }

  if (variant === 'navigation') {
    return (
      <ReactRouterLink
        onClick={handleClick}
        style={{ cursor: disabled ? 'default' : undefined }}
        target={target}
        title={title}
        to={disabled ? '' : href || to}
      >
        <RebassLink as="span" className={classNames} variant={variant} {...rest}>
          {children || text}
        </RebassLink>
      </ReactRouterLink>
    )
  }

  return (
    <RebassLink
      as="a"
      className={classNames}
      disabled={disabled}
      onClick={handleClick}
      target={target}
      title={title}
      variant={variant}
      {...rest}
    >
      {children || text}
    </RebassLink>
  )
}
