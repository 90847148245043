import { TFunction } from 'i18next'

import { CheckItem } from '../types/checkItem'

const MIN_PASSWORD_LENGTH = 6

export const getChecksList = (t: TFunction): CheckItem[] => [
  {
    description: t('password_check.min_length', { count: MIN_PASSWORD_LENGTH }),
    isValid: (value: string) => value.length >= MIN_PASSWORD_LENGTH,
  },
  {
    description: t('password_check.uppercase_letter'),
    isValid: (value: string) => /[A-Z]/.test(value),
  },
  {
    description: t('password_check.number'),
    isValid: (value: string) => /\d/.test(value),
  },
]
