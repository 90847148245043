import { FormItemsGroup, Module, Space } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceDueDate } from './elements/InvoiceDueDate'
import { InvoiceFooterNote } from './elements/InvoiceFooterNote'
import { InvoiceIssueDate } from './elements/InvoiceIssueDate'
import { InvoiceMessage } from './elements/InvoiceMessage'
import { InvoiceNumber } from './elements/InvoiceNumber'
import { InvoiceSendTo } from './elements/InvoiceSendTo'

export const InvoiceEditorForm = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Module>
      <Space />
      <Module.SectionsGroup>
        <Module.Section title={t('external_invoices.editor.form.field.send_to.label')}>
          <FormItemsGroup itemsInRow={2}>
            <InvoiceSendTo />
          </FormItemsGroup>
          <Space size="m" />
        </Module.Section>
        <Module.Section>
          <Space size="s" />
          <FormItemsGroup itemsInRow={6}>
            <InvoiceNumber />
            <InvoiceIssueDate />
            <InvoiceDueDate />
            <InvoiceMessage />
          </FormItemsGroup>
          <Space size="m" />
        </Module.Section>
        <Module.Section>
          <Space size="s" />
          <InvoiceFooterNote />
          <Space size="m" />
        </Module.Section>
      </Module.SectionsGroup>
    </Module>
  )
}
