import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ImageWrapper = styled.div`
  height: 265px;
  width: 265px;
  margin-top: ${Spacing.XXXL};
`

export const FeaturesTesterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Color.Lavender};
  border-radius: ${DefaultValue.BorderRadius};
  padding: ${Spacing.XXXL};
`

export const FeaturesTesterQuestion = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${Spacing.XL};
`

export const FeaturesTesterDescription = styled.div``

export const CandidateToggleContainer = styled.div`
  width: 40px;
`
