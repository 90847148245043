import { NavListItemWithInlineDescription, Select, SelectProps } from '@design-system'

import React, { forwardRef, ReactElement, Ref, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useOrganizationRoles } from './hooks/useOrganizationRoles'
import { Role } from './types/role'
import { RoleValue } from './types/roleValue'
import { getItems } from './utils/getItems'

interface RoleSelectProps extends Omit<SelectProps<RoleValue>, 'items' | 'onSelect'> {
  onSelect?: (id?: string, value?: Role) => void
}

export const RoleSelect = forwardRef(
  ({ onSelect, ...selectProps }: RoleSelectProps, ref: Ref<HTMLDivElement | null>): ReactElement => {
    const { roles, isLoading: isOrganizationRolesLoading } = useOrganizationRoles()
    const { organization } = useUserOrganization()
    const { t } = useTranslation()

    const items = useMemo(
      () =>
        isOrganizationRolesLoading || !organization ? [] : getItems(roles, t, organization?.subscriptionProductPlan),
      [isOrganizationRolesLoading, organization, roles, t],
    )

    const handleSelect = useCallback(
      (id?: string, value?: RoleValue) => {
        onSelect?.(id, value?.role)
      },
      [onSelect],
    )

    return (
      <Select
        {...selectProps}
        dropdownFetching={isOrganizationRolesLoading}
        dropdownItemRender={(props) => <NavListItemWithInlineDescription {...props} />}
        dropdownSize={selectProps.dropdownSize || 'l'}
        onSelect={handleSelect}
        items={items}
        ref={ref}
      />
    )
  },
)
