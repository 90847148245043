import { useEffect, useState } from 'react'

import { ScreenSize } from '../types/screenSize'
import { getCurrentScreenSize } from '../utils/getCurrentScreenSize'

export const useScreenSize = (): ScreenSize => {
  const [screenSize, setScreenSize] = useState<ScreenSize>(getCurrentScreenSize())

  useEffect(() => {
    const handleScreenSize = () => {
      setScreenSize(getCurrentScreenSize())
    }

    // not bulletproof (doesn't detect screen change), but should cover cases
    // when a window is being resized due to being moved to another screen
    window.addEventListener('resize', handleScreenSize)

    return () => {
      window.removeEventListener('resize', handleScreenSize)
    }
  }, [])

  return {
    ...screenSize,
  }
}
