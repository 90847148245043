import { InvoiceStatus } from '../../../../../enums/invoiceStatus'
import { Invoice } from '../../../../../types/invoice'

export const isInvoicePaid = (invoice: Invoice | undefined): boolean => {
  if (!invoice) {
    return false
  }

  const { status } = invoice
  const invoiceStatus = status?.status
  return invoiceStatus === InvoiceStatus.Paid || !!status?.paidDate
}
