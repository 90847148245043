import { Button, LottieAnimation, Modal, Space, Text, useModal } from '@design-system'

import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'

import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

import { LocalStorageKeys } from '../../../enums/localStorageKeys'
import { ModalId } from '../../../enums/modalId'
import { getImgPath } from '../../../utils/getImgPath'
import * as Styled from './styles'

interface BillyWelcomeModalProps {
  plan: SubscriptionPlan.Basic | SubscriptionPlan.Pro | SubscriptionPlan.Premium
}

export const BillyWelcomeModal = ({ plan }: BillyWelcomeModalProps) => {
  const { t } = useTranslation()
  const [, setIsClosedWelcomeModal] = useLocalStorage(LocalStorageKeys.BillyWelcomeModal)
  const { close } = useModal(ModalId.BillyWelcomeModal, {
    onClose: () => setIsClosedWelcomeModal(true),
  })

  const handleCloseClick = useCallback(() => {
    close()
  }, [close])

  return (
    <Styled.ModalBillyWelcome id={ModalId.BillyWelcomeModal} closable forceRender size="s">
      <Modal.Header>
        <Text variant="h2">{t('billy_welcome_modal.title')}</Text>
      </Modal.Header>

      <Modal.Body>
        <Styled.InfoTextWrapper>
          <figure>
            <img src={getImgPath('/modals/welcome.svg')} alt="billy welcome" />
            <Styled.LottieAnimationConfetti>
              <LottieAnimation name="confetti" options={{ loop: false, autoplay: true }} />
            </Styled.LottieAnimationConfetti>
          </figure>

          <Space />

          <Trans i18nKey={`billy_welcome_modal.as_thanks_info.${plan}`} />
        </Styled.InfoTextWrapper>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseClick}>{t('billy_welcome_modal.btn')}</Button>
      </Modal.Footer>
    </Styled.ModalBillyWelcome>
  )
}
