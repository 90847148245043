import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'
import { getAnimateRotationStyle } from '../../../../utils/getAnimateRotationStyle'
import { Icon as IconComponent } from '../../../Icon'

export const NavListLoadingWrapper = styled.div`
  display: flex;
  padding: ${Spacing.S};
  justify-content: center;
`

export const RotatingIcon = styled(IconComponent)`
  ${getAnimateRotationStyle()};
`
