import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { getDefaultTransition } from '../../../../utils/getDefaultTransition'

export const DayWrapper = styled.li``

interface DayButtonProps {
  bgColor?: Color
  disabled?: boolean
  selected?: boolean
}

export const DayButton = styled.button<DayButtonProps>`
  appearance: none;
  display: flex;
  width: 32px;
  height: 32px;
  border: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ bgColor = Color.White }) => bgColor};
  ${getDefaultTransition('background-color')}

  ${({ disabled, selected }) =>
    !selected &&
    !disabled &&
    css`
      cursor: pointer;
    `}
`
