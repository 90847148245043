import { Button } from '@design-system'

import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { EmberEventFn } from '../../../types/emberEventFn'
import ConnectionStatusImage from './ConnectionStatusImage'

const Description = styled.p`
  text-align: center;
  margin-bottom: 16px;
`

type CopyVariant = 'not-connected' | 'basic-upsell'

type WidgetBodySimpleProps = {
  action: EmberEventFn
  copyVariant: CopyVariant
}

export const WidgetBodySimple = ({ copyVariant = 'not-connected', action }: WidgetBodySimpleProps) => {
  const { t } = useTranslation()

  const handleAction = () => {
    action()
  }

  return (
    <>
      <ConnectionStatusImage />
      <h1 className="h1">{t(`dashboard.widget.bank-connection.${copyVariant}.title.billy`)}</h1>
      <Description>{t(`dashboard.widget.bank-connection.${copyVariant}.description.billy`)}</Description>
      <Button onClick={handleAction} variant="secondary">
        {t(`dashboard.widget.bank-connection.${copyVariant}.cta`)}
      </Button>
    </>
  )
}

export default WidgetBodySimple
