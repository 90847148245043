import { ModuleLayout } from '@design-system'

import React, { memo, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { BillsRoute } from '../../enums/billsRoute'
import { BillsSideActions } from './elements/BillsSideActions/BillsSideActions'
import { BillsSummary } from './elements/BillsSummary'
import { BillsTable } from './elements/BillsTable'
import { BillsTableActions } from './elements/BillsTableActions'
import { ReceiptsToFill } from './elements/ReceiptsToFill'
import { useBillsListFilters } from './hooks/useBillsListFilters'
import { useIsBillsListEmpty } from './hooks/useIsBillsListEmpty'

export const BillsList = memo((): ReactElement => {
  const { t } = useTranslation()
  const isBillsListEmpty = useIsBillsListEmpty()
  const [{ contactId, contactName }] = useBillsListFilters()

  const title = useMemo(
    () => (contactId && contactName ? t('billsFrom', { contactName }) : t('bills')),
    [contactId, contactName, t],
  )

  if (isBillsListEmpty) {
    return <Redirect to={BillsRoute.ListEmpty} />
  }

  return (
    <>
      <ModuleLayout sideActions={<BillsSideActions />} title={title}>
        <ReceiptsToFill />
        <BillsSummary />
        <BillsTableActions />
        <BillsTable />
      </ModuleLayout>
    </>
  )
})
