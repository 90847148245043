import { notify } from '@design-system'

import { TFunction } from 'i18next'

import { NotificationKeys } from '../../../../../enums/notificationKeys'

export const notifyBillReconciliationError = (t: TFunction) =>
  notify({
    id: NotificationKeys.BillReconciliationError,
    message: t('application_error.unknown_error'),
    variant: 'error',
  })
