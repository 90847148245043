import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../utils'
import { updateCurrencyDefaultPaymentMethod } from '../query-api'

interface UseUpdateCurrencyDefaultPaymentMethodProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useUpdateCurrencyDefaultPaymentMethod = (props?: UseUpdateCurrencyDefaultPaymentMethodProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate: update,
    isLoading: isProcessing,
    ...rest
  } = useMutation(updateCurrencyDefaultPaymentMethod, { onSuccess, onError })

  return { update, isProcessing, ...rest }
}
