import { Modal, ModalProps } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { InviteOrUpdateUmbrellaUserFormContextProvider } from './context/inviteOrUpdateUmbrellaUserFormContext'
import { ModalBody } from './elements/ModalBody/ModalBody'
import { ModalFooter } from './elements/ModalFooter'

interface InviteOrUpdateUmbrellaUserModalProps extends ModalProps {
  umbrellaId?: string
  userId?: string
}

export const InviteOrUpdateUmbrellaUserModal = ({
  userId,
  umbrellaId,
  id,
  ...props
}: InviteOrUpdateUmbrellaUserModalProps): ReactElement => {
  const { t } = useTranslation()
  const isUpdateMode = !!userId
  const titleKey = isUpdateMode
    ? 'umbrella_users.create_edit_user_modal.title_edit'
    : 'umbrella_users.create_edit_user_modal.title_create'

  return (
    <InviteOrUpdateUmbrellaUserFormContextProvider modalId={id} userId={userId} umbrellaId={umbrellaId}>
      <Modal id={id} size="s" {...props}>
        <Modal.Header title={t(titleKey)} />
        <ModalBody />
        <ModalFooter />
      </Modal>
    </InviteOrUpdateUmbrellaUserFormContextProvider>
  )
}
