import i18next from 'i18next'

import { setNumeralLocale } from '../../utils'
import '../../utils/i18n'

type SetLanguageProps = {
  lang: string
}

export const setLanguage = ({ lang }: SetLanguageProps) => {
  i18next.changeLanguage(lang)
  setNumeralLocale(lang)
}
