import {
  AmountValue as AmountValueComponent,
  SkeletonText as SkeletonTextComponent,
  Text as TextComponent,
} from '@design-system'

import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
`

export const Text = styled(TextComponent)`
  margin-bottom: 7px;
`

export const SkeletonText = styled(SkeletonTextComponent)`
  margin-bottom: 7px;
`

export const AmountValue = styled(AmountValueComponent)`
  margin-bottom: 7px;
`

export const TextWrapper = styled.div`
  display: flex;
`
