import { amountToDisplayValue } from '@design-system'

interface GetReceiptDetailsArgs {
  amount?: number
}

export const getReceiptRightInfo = ({ amount }: GetReceiptDetailsArgs): string | undefined => {
  if (amount) {
    return amountToDisplayValue(amount)
  }

  return '-'
}
