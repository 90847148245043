interface PayloadWithFiles {
  files?: File[]
}

export const getFormDataWithFiles = (payload: PayloadWithFiles): FormData => {
  const { files = [], ...restProps } = payload

  const body = new FormData()
  for (const file of files) {
    body.append('files', file)
  }

  for (const property in restProps) {
    if (restProps[property]) {
      body.append(property, restProps[property])
    }
  }

  return body
}
