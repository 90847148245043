import { UmbrellaAddress } from '@modules-deprecated/app/umbrellas/types/umbrellaAddress'

export const getAddressContent = ({ city, street, zipcode }: UmbrellaAddress): string => {
  let content = ''

  if (street) {
    content += `${street}, `
  }

  if (zipcode) {
    content += ` ${zipcode}`
  }

  if (city) {
    content += ` ${city}`
  }

  if (!content) {
    return '-'
  }

  return content
}
