import { amountToDisplayValue } from '@design-system'

import { ContactBalanceCategoryRange } from '../enums/contactBalanceCategoryRange'
import { ContactBalanceCategory } from '../types/contactBalanceCategory'

export const getCategoryTotalAmountByRange = (
  categories: ContactBalanceCategory[],
  categoryRange: ContactBalanceCategoryRange,
  currency?: string,
): string | undefined => {
  const amount = categories.find(({ range }) => range === categoryRange)?.amount

  if (amount !== undefined) {
    return `${amountToDisplayValue(amount)} ${currency || ''}`
  }

  return undefined
}
