import { Image as ImageComponent, ImageProps as ImageComponentProps } from '@design-system'

import styled from '@emotion/styled'

interface ImageProps extends ImageComponentProps {
  circle?: boolean
  isLoading?: boolean
  fitToHeight?: boolean
}

export const Image = styled(ImageComponent)<ImageProps>`
  width: 100%;
  height: ${({ fitToHeight }) => (fitToHeight ? '100%' : 'auto')};

  ${({ circle }) => {
    if (circle) {
      return `
        border-radius: 50%;
        width: auto;
        aspect-ratio: 1 / 1;
      `
    }
  }};

  ${({ isLoading }) => {
    if (isLoading) {
      return `
        filter: blur(5px);
      `
    }
  }};
`
