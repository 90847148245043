import { css, SerializedStyles } from '@emotion/core'

import { StyledComponent } from '../../../types/styledComponent'
import { getTruncateStyle as getTruncateStyleGeneric } from '../../../utils/getTruncateStyle'

export const getTruncateStyle = (Input?: StyledComponent): SerializedStyles => css`
  ${Input || '&'} {
    ${getTruncateStyleGeneric()};
  }
`
