import { FormItemsGroup, Input, Space } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../hooks'
import { CountrySelect } from '../../../../../../selects/CountrySelect'
import { ContactFormSchema } from '../../../../utils/formData'
import { ContactStandardSettings } from '../../../ContactStandardSettings'

export const SupplierPersonItems = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<ContactFormSchema>()

  return (
    <>
      <FormItem
        label={t('create_contact_modal.form.individual.first_name')}
        name="personFirstName"
        render={({ field: { value, onChange, ...props } }) => (
          <Input
            {...props}
            autoComplete="new-password"
            onChange={onChange}
            placeholder={t('create_contact_modal.form.individual.first_name')}
            value={value}
          />
        )}
      />

      <Space size="l" />

      <FormItem
        label={t('create_contact_modal.form.individual.last_name')}
        name="personLastName"
        render={({ field: { value, onChange, ...props } }) => (
          <Input
            {...props}
            autoComplete="new-password"
            onChange={onChange}
            placeholder={t('create_contact_modal.form.individual.last_name')}
            value={value}
          />
        )}
      />

      <Space size="l" />

      <FormItemsGroup itemsInRow={2}>
        <FormItem
          label={t('create_contact_modal.form.email')}
          name="email"
          render={({ field: { value, onChange, ...props } }) => (
            <Input
              {...props}
              autoComplete="new-password"
              onChange={onChange}
              placeholder={t('create_contact_modal.form.email')}
              value={value}
              type="email"
            />
          )}
        />

        <FormItem
          label={t('create_contact_modal.form.country')}
          name="country"
          render={({ field: { value, onChange, ...props } }) => (
            <CountrySelect
              {...props}
              dropdownSize="fitTrigger"
              onSelect={(id, value) => onChange(value)}
              selectedId={value?.id}
            />
          )}
        />
      </FormItemsGroup>

      <Space size="xl" />

      <ContactStandardSettings />
    </>
  )
}
