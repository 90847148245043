import { SettingsSection } from '@components'

import React, { ReactElement } from 'react'

import { InvoiceAndProductSettingsContextProvider } from './contexts/invoiceAndProductSettingsContext'
import { InvoiceAndProductSettingsForm } from './elements/InvoiceAndProductSettingsForm'
import { SectionFooter } from './elements/SectionFooter'

export const InvoiceAndProductSettingsSection = (): ReactElement => (
  <SettingsSection>
    <InvoiceAndProductSettingsContextProvider>
      <InvoiceAndProductSettingsForm />
      <SectionFooter />
    </InvoiceAndProductSettingsContextProvider>
  </SettingsSection>
)
