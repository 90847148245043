import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconExitCircle = ({ color = 'inherit', color2, width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-exit-circle')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <circle stroke={coloring(color)} fill="none" cx="50" cy="50" r="50" strokeMiterlimit="10" strokeWidth="14" />
      <line
        stroke={coloring(color2 || color)}
        fill="none"
        x1="30"
        y1="30"
        x2="70"
        y2="70"
        strokeMiterlimit="10"
        strokeWidth="14"
      />
      <line
        stroke={coloring(color2 || color)}
        fill="none"
        x1="30"
        y1="70"
        x2="70"
        y2="30"
        strokeMiterlimit="10"
        strokeWidth="14"
      />
    </svg>
  </Box>
)
