import { BorderRadius, Color, FontWeight, getShadow, Spacing } from '@design-system'

import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import { Box, BoxProps, Flex, Text, TextProps } from 'rebass'

import { coloring } from '../../styles-deprecated'

const Header = styled(Flex)`
  min-height: 30px;
  margin-bottom: 30px;
  align-items: center;
`
Header.defaultProps = { as: 'header' }

type TitleProps = TextProps & {
  color?: string
}

const ListSectionWrapper = styled(Box)`
  border-radius: ${BorderRadius.L};
  padding: ${Spacing.L};
  background-color: ${Color.White};
  ${getShadow('raisedLight')};
`

const Title = styled(Text)<TitleProps>`
  color: ${({ color }) => color && coloring(color)};
  font-weight: ${FontWeight.Medium};
`

const HeaderActions = styled.aside`
  margin-left: auto;
`

export type ListSectionProps = BoxProps & {
  borderColor?: string
  headerActions?: ReactNode
  title: string
  titleColor?: string
}

export const ListSection = ({
  borderColor = 'primary',
  children,
  className,
  headerActions,
  title,
  titleColor = 'primary',
  ...rest
}: ListSectionProps) => (
  <ListSectionWrapper {...rest}>
    <Header>
      <Title color={titleColor}>{title}</Title>
      {headerActions && <HeaderActions>{headerActions}</HeaderActions>}
    </Header>
    {children}
  </ListSectionWrapper>
)
