import { Button, DropdownFooterProps, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface ContactSelectDropdownFooterProps extends DropdownFooterProps {
  inputValue?: string
  onClick: () => void
  selectedId?: string
}

export const ContactSelectDropdownFooter = ({
  inputValue,
  onClick,
  onFooterItemClick,
  selectedId,
}: ContactSelectDropdownFooterProps): ReactElement => {
  const { t } = useTranslation()

  const handleClick = useCallback(() => {
    onClick()
    onFooterItemClick?.()
  }, [onClick, onFooterItemClick])

  const text = inputValue && !selectedId ? `"${inputValue}"` : t('contact_select.footer.create_new_contact_button.new')

  return (
    <Styled.DropdownFooterWrapper>
      <Styled.Separator as="hr" />
      <Button fullWidth icon="plusCircle" size="xl" variant="text" onClick={handleClick}>
        <Text truncate inherit as="span">
          {t('contact_select.footer.create_new_contact_button.text', { name: text })}
        </Text>
      </Button>
    </Styled.DropdownFooterWrapper>
  )
}
