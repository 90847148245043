import { Flex, Link, ModuleLayout, Space, Text, useTheme } from '@design-system'

import React, { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { getImageClassName } from '../../utils/getClassName'
import { getImgPath } from '../../utils/getImgPath'
import * as Styled from './styles'

export const OverdueInvoiceLockedAccountView = (): ReactElement => {
  const theme = useTheme()
  const { t } = useTranslation()

  const email = theme.ds.data.menu.help.email

  return (
    <ModuleLayout fullHeight>
      <Styled.Container>
        <Styled.ContentWrapper>
          <Flex flexDirection="column" alignItems="center">
            <Text variant="h1">{t('organization.not_owner_and_overdue_invoice.header')}</Text>
            <Space size="l" />
            <Text>{t('organization.not_owner_and_overdue_invoice.currently_locked_account')}</Text>
            <Space size="xl" />
            <Text alignment="center">
              <Trans i18nKey="organization.not_owner_and_overdue_invoice.contact_account_owner">
                We noticed that you are logged in with a user that is not the owner of the account.
                <strong>Please contact the account owner</strong> to settle the payment and reactivate the account.
              </Trans>
            </Text>
            <Space size="xl" />
            <Text alignment="center" variant="micro">
              <Trans i18nKey="organization.not_owner_and_overdue_invoice.contact_support_team" tOptions={{ email }}>
                If you have any questions or need assistance along the way, feel free to reach out to our support team
                at
                <Link href={`mailto:${email}`}>{email}</Link>
              </Trans>
            </Text>
            <Space size="xxxxl" />
            <img
              src={getImgPath('/modals/key-and-lock.svg')}
              width={284}
              height={253}
              className={getImageClassName()}
              alt={t('organization.not_owner_and_overdue_invoice.key_and_lock_illustration_description')}
            />
          </Flex>
        </Styled.ContentWrapper>
      </Styled.Container>
    </ModuleLayout>
  )
}
