import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route } from 'react-router-dom'

import { UserNotificationsSettings } from '@views/settings/routes/UserNotificationsSettings'
import { UserPrivacySettings } from '@views/settings/routes/UserPrivacySettings'
import { UserProfileSettings } from '@views/settings/routes/UserProfileSettings'

import { UmbrellaRoute } from '../../enums/UmbrellaRoute'
import { UmbrellaSettingsNavigation } from './elements/UmbrellaSettingsNavigation'

export const UmbrellaSettings = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <ModuleLayout
      headerBottomActions={<UmbrellaSettingsNavigation />}
      title={t('settings')}
      restoreScroll={false}
      resetScroll
    >
      <Route exact path={UmbrellaRoute.Settings}>
        <Redirect to={UmbrellaRoute.SettingsProfile} />
      </Route>
      <Route component={UserProfileSettings} exact path={UmbrellaRoute.SettingsProfile} />
      <Route component={UserNotificationsSettings} exact path={UmbrellaRoute.SettingsNotifications} />
      <Route component={UserPrivacySettings} exact path={UmbrellaRoute.SettingsPrivacy} />
    </ModuleLayout>
  )
}
