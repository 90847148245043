import { Spacing } from '../../../enums/spacing'
import { Size } from '../types/size'

interface ControlProperties {
  width: number
  height: number
  knobSize: number
  offsetTop: Spacing | null
}

export const sizeToProperties: Record<Size, ControlProperties> = {
  m: { width: 36, height: 20, knobSize: 16, offsetTop: Spacing.XXXS },
  l: { width: 44, height: 24, knobSize: 20, offsetTop: null },
}
