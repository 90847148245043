import { Service } from '../types/service'

export const servicesToTranslationKey: Record<Service, string> = {
  bookkeeping: 'umbrella.services.service.bookkeeping',
  /* eslint-disable @typescript-eslint/naming-convention */
  vat_statements: 'umbrella.services.service.vat_statements',
  annual_report: 'umbrella.services.service.annual_report',
  tax_statements: 'umbrella.services.service.tax_statements',
  payroll_administration: 'umbrella.services.service.payroll_administration',
  /* eslint-enable @typescript-eslint/naming-convention */
  counselling: 'umbrella.services.service.counselling',
  /* eslint-disable @typescript-eslint/naming-convention */
  private_tax_declaration: 'umbrella.services.service.private_tax_declaration',
  parental_purchase: 'umbrella.services.service.parental_purchase',
  auditor_statement: 'umbrella.services.service.auditor_statement',
  invoices_payment: 'umbrella.services.service.invoices_payment',
  /* eslint-enable @typescript-eslint/naming-convention */
}
