import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { QueryParamKeys } from '../../../enums/queryParamKeys'
import { useQueryParamsStorage } from '../../../hooks/useQueryParamsStorage'
import { fetchVoucher } from '../services/query-api'
import { useVouchersToNavigate, UseVouchersToNavigateProps } from './useVouchersToNavigate'

type QueryParams = {
  sort: string
}

const REFETCH_LIMIT = 4
const PREFETCH_PREVIOUS_AMOUNT = 1
const PREFETCH_NEXT_AMOUNT = 2

const getVouchersIdsToPrefetch = (voucherIds: string[], currentIndex: number) => {
  const previousIds: string[] = []
  const nextIds: string[] = []

  for (let i = 1; i <= PREFETCH_PREVIOUS_AMOUNT; i += 1) {
    const voucherId = voucherIds[currentIndex - i]
    voucherId && previousIds.push(voucherId)
  }

  for (let i = 1; i <= PREFETCH_NEXT_AMOUNT; i += 1) {
    const voucherId = voucherIds[currentIndex + i]
    voucherId && nextIds.push(voucherId)
  }

  return [...previousIds, ...nextIds]
}

export const useVoucherNavigation = (organizationId: string, voucherId: string) => {
  const queryClient = useQueryClient()
  const { data: voucher } = useQuery([QueryKeys.Voucher, organizationId, voucherId], () =>
    fetchVoucher({
      queryKey: [QueryKeys.Voucher, { organizationId, voucherId }],
    }),
  )
  const {
    queryParams: { sort },
  } = useQueryParamsStorage<QueryParams>(QueryParamKeys.VoucherList, {}, true)
  const defaultVouchersToNavigateParams = useMemo(
    () => ({
      organizationId,
      sort,
      voucher: undefined,
    }),
    [organizationId, sort],
  )
  const [vouchersToNavigateParams, setVouchersToNavigateParams] = useState<UseVouchersToNavigateProps>(
    defaultVouchersToNavigateParams,
  )
  const { areVouchersLoading, vouchersIds } = useVouchersToNavigate(vouchersToNavigateParams)

  const currentIndex = vouchersIds.findIndex((id) => id === voucherId)
  const prevVoucherId = vouchersIds[currentIndex - 1]
  const nextVoucherId = vouchersIds[currentIndex + 1]

  const shouldRefetchVouchersNavigation = useCallback(
    (voucherId: string) => {
      if (voucherId && areVouchersLoading) {
        return true
      }

      const index = vouchersIds.findIndex((id) => id === voucherId)
      return index <= REFETCH_LIMIT || index >= vouchersIds.length - REFETCH_LIMIT
    },
    [areVouchersLoading, vouchersIds],
  )

  useEffect(() => {
    if (shouldRefetchVouchersNavigation(voucherId)) {
      if (!voucher) {
        return
      }

      setVouchersToNavigateParams({
        organizationId,
        voucher,
        sort,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherId, voucher])

  useEffect(() => {
    if (!vouchersIds.length) {
      return
    }

    const voucherIdsToPrefetch = getVouchersIdsToPrefetch(vouchersIds, currentIndex)

    for (const prefetchVoucherId of voucherIdsToPrefetch) {
      queryClient.prefetchQuery([QueryKeys.Voucher, organizationId, prefetchVoucherId], () =>
        fetchVoucher({
          queryKey: [QueryKeys.Voucher, { organizationId, voucherId: prefetchVoucherId }],
        }),
      )
    }
  }, [vouchersIds, currentIndex, queryClient, organizationId])

  return { prevVoucherId, nextVoucherId, isLoading: areVouchersLoading }
}
