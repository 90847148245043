import { Button, Modal, Space, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface NotOwnerModalProps {
  modalId: string
  forceRender?: boolean
  onModalClose: () => void
}

export const NotOwnerModal = ({ modalId, forceRender, onModalClose }: NotOwnerModalProps): ReactElement => {
  const { t } = useTranslation()

  const handleCloseButtonClick = useCallback(() => {
    onModalClose()
  }, [onModalClose])

  return (
    <Modal id={modalId} forceRender={forceRender} size="s">
      <Modal.Header title={t('premium_feature.wrong_subscription')} />
      <Modal.Body>
        <Text variant="large">{t('premium_feature.warning')}</Text>
        <Space size="m" />
        <Text variant="large">{t('premium_feature.warning_wrong_user')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseButtonClick}>{t('back')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
