import { Modal } from '@design-system'

import styled from '@emotion/styled'

export const InfoTextWrapper = styled.div`
  text-align: center;
`

export const LottieAnimationConfetti = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`

export const ModalBillyWelcome = styled(Modal)`
  overflow: hidden;
`
