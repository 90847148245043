import forEach from 'lodash/forEach'
import noop from 'lodash/noop'
import { useCallback, useEffect } from 'react'

import { initialState } from '../modalContext'
import {
  _onCloseSubscribers,
  _onOpenSubscribers,
  _removeSubscribeOnClose,
  _removeSubscribeOnOpen,
  _subscribeOnClose,
  _subscribeOnOpen,
  ModalCallback,
} from './modalSubscribers'
import { useModals } from './useModals'

type ModalAction = (modalId: string) => void

interface UseModalMultipleProps {
  onOpen?: ModalCallback
  onClose?: ModalCallback
}

interface UseModalMultipleResult {
  openedIds: string[]
  open: ModalAction
  close: ModalAction
}

export const useModalMultiple = (modalIds: string[], properties?: UseModalMultipleProps): UseModalMultipleResult => {
  const context = useModals()

  const { onOpen, onClose } = properties || {}
  const [{ openedIds }, dispatch] = context || [initialState, noop]

  const open = useCallback(
    (modalId: string) => {
      dispatch({
        modalId,
        type: 'OPEN',
      })

      forEach(_onOpenSubscribers[modalId], (callback) => callback?.(modalId))
    },
    [dispatch],
  )

  const close = useCallback(
    (modalId: string) => {
      dispatch({
        modalId,
        type: 'CLOSE',
      })

      forEach(_onCloseSubscribers[modalId], (callback) => callback?.(modalId))
    },
    [dispatch],
  )

  useEffect(() => {
    for (const modalId of modalIds) {
      if (onOpen) {
        _subscribeOnOpen(modalId, onOpen)
      }

      if (onClose) {
        _subscribeOnClose(modalId, onClose)
      }
    }

    return () => {
      for (const modalId of modalIds) {
        _removeSubscribeOnOpen(modalId, onOpen)
        _removeSubscribeOnClose(modalId, onClose)
      }
    }
  }, [onOpen, onClose, modalIds])

  return {
    openedIds,
    open,
    close,
  }
}
