import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { OrganizationOwner } from '@modules-deprecated/app/organizations/types'
import { Umbrella } from '@modules-deprecated/app/umbrellas/types/umbrella'
import { User } from '@modules-deprecated/app/user'

import { TableStaticActionType } from '../../UmbrellaOganizationsListTableStaticActions'

interface StaticActionsForUmbrellaOrganizationProps {
  umbrellaOrganizationOwner?: OrganizationOwner
  user?: User
  umbrella?: Umbrella
  t: TFunction
}

export const getStaticActionsForUmbrellaOrganization = ({
  umbrellaOrganizationOwner,
  user,
  umbrella,
  t,
}: StaticActionsForUmbrellaOrganizationProps): NavItem<TableStaticActionType>[] => {
  const organizationOwnerUmbrellaId =
    umbrellaOrganizationOwner?.type === 'umbrella' ? umbrellaOrganizationOwner?.id : undefined
  const organizationOwnerUserId = umbrellaOrganizationOwner?.type === 'user' ? umbrellaOrganizationOwner?.id : undefined
  const isOrganizationOwnerUmbrella = organizationOwnerUmbrellaId === umbrella?.id

  const hasTransferOwnershipRight = organizationOwnerUserId === user?.id || isOrganizationOwnerUmbrella
  const hasSubscriptionChangeRight = isOrganizationOwnerUmbrella
  const hasInboxEnabled = umbrella?.isInboxEnabled && isOrganizationOwnerUmbrella

  const items: NavItem<TableStaticActionType>[] = [
    {
      id: 'removeOrganization',
      children: t('umbrella_organizations.organizations_table.removeOrganization'),
      value: TableStaticActionType.RemoveOrganization,
    },
  ]

  if (hasInboxEnabled) {
    items.unshift({
      id: 'updateVoucherInbox',
      children: t('umbrella_organizations.organizations_table.updateVoucherInbox'),
      value: TableStaticActionType.UpdateVoucherInbox,
    })
  }

  if (hasTransferOwnershipRight) {
    items.unshift({
      id: 'transferOwnership',
      children: t('umbrella_organizations.organizations_table.transferOwnership'),
      value: TableStaticActionType.TransferOwnership,
    })
  }

  if (hasSubscriptionChangeRight) {
    items.unshift({
      id: 'changeSubscription',
      children: t('umbrella_organizations.organizations_table.changeSubscription'),
      value: TableStaticActionType.ChangeSubscription,
    })
  }

  return items
}
