import {
  IconProps,
  InfiniteTable,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  SmartSelectItem,
  SuccessErrorAnimatedIcon,
  TableColumn,
  TableRow,
  Text,
} from '@components-deprecated'
import { Button, notify } from '@design-system'

import { css } from '@emotion/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { SpecificState } from '../../../types/reduxSaga-deprecated'
import { AccountSelector, TaxRateSelector } from '../../app'
import { TaxRate } from '../../app/taxrates/types'
import { bankLinesAndTransactionsRequested, bulkReconcile, resetBulkReconcileState } from '../action-creators'
import { BankLine, ExtendedBankLineMatch } from '../types'
import { prefixAmount } from '../utils'

export type BulkReconciliationModalProps = ModalProps

type BankLineRow = BankLine & {
  date: Date
  index: number
  selectedAccountId?: string
  selectedTaxRateId?: string
}

type FormData = {
  [key: string]: {
    accountId: string
    taxRateId: string
  }
}

type AnimateSuccessProps = IconProps & { matchId: string }

const AnimateSuccess = ({ matchId }: AnimateSuccessProps) => {
  const success = useSelector((state: SpecificState) => state.bankReconciliation.bulkReconcileState[matchId])
  if (success === undefined) {
    return <span />
  } else {
    return <SuccessErrorAnimatedIcon success={success} />
  }
}

const renderResultIcon = (rowData: BankLineRow) => <AnimateSuccess matchId={rowData.matchId} />

const selectFilteredMatches = createSelector(
  (state: SpecificState) => state.bankReconciliation.bankLineMatches,
  (matches) =>
    matches.filter((match) => {
      return match.bankLines.length === 1 && match.matchedBillyTransactions.length === 0
    }),
)

// Avoids unnecessary calls to the API for each given Selector
const staleCounter = {
  accounts: 0,
  taxRates: 0,
}

export const BulkReconciliationModal = ({ onClose = () => null, ...rest }: BulkReconciliationModalProps) => {
  const [data, setData] = useState<BankLineRow[]>([] as BankLineRow[])
  const [formData, setFormData] = useState<FormData>({})
  const [isDone, setDone] = useState(false)
  const [isProcessing, setProcessing] = useState(false)
  const [selectedRows, setSelectedRows] = useState<BankLineRow[]>([] as BankLineRow[])
  const [isBulkActionsActive, setBulkActionsActive] = useState<boolean>(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const bankLineMatches = useSelector(selectFilteredMatches)
  const bulkReconcileState = useSelector((state: SpecificState) => state.bankReconciliation.bulkReconcileState)

  const validateFormData = () =>
    Object.keys(formData).length === data.length &&
    Object.keys(formData).reduce((acc, key) => acc && !!formData[key].accountId && !!formData[key].taxRateId, true)

  const handleBulkCreate = () => {
    if (!validateFormData()) {
      notify({
        id: NotificationKeys.BankReconciliationBulkReconcile,
        message: t('bankreconciliation.bulk_reconcile.incomplete_form_message'),
        variant: 'error',
      })
    } else {
      dispatch(bulkReconcile({ bulkReconcile: formData }))
      setProcessing(true)
    }
  }

  const handleDeleteRow = () => {
    const selectedIds = selectedRows.map((row) => row.id)
    const cleanData = data.filter((row) => !selectedIds.includes(row.id))
    setData(() => cleanData)
  }

  const handleDone = () => setSelectedRows([])

  const handleRowSelect = (row: TableRow, selected: boolean) => {
    if (selected) {
      setSelectedRows([...selectedRows, row as BankLineRow])
    } else {
      setSelectedRows(selectedRows.filter((r) => r.id !== row.id))
    }
  }

  const handleClose = () => {
    onClose()
    dispatch(resetBulkReconcileState())
    if (isDone) {
      dispatch(bankLinesAndTransactionsRequested())
    }
  }

  const handleBulkActionsToggled = (active: boolean) => {
    setBulkActionsActive(active)
  }

  const renderAccountSelector = useCallback(
    (rowData: BankLineRow) => (
      <AccountSelector
        disabled={isDone || isProcessing || isBulkActionsActive}
        isBankAccount={false}
        mb={0}
        name={`account-selector-${rowData.matchId}`}
        stale={staleCounter.accounts++ > 0}
        width={170}
        onItemSelect={(item: SmartSelectItem) => {
          const update = { selectedAccountId: item.id, selectedTaxRateId: item.taxRateId }
          const newData = data.map((match) => (match.matchId === rowData.matchId ? { ...match, ...update } : match))
          setData(() => newData)
          setFormData((formData) => ({
            ...formData,
            [rowData.matchId]: {
              accountId: item.id,
              amount: rowData.amount || 0,
              contraAccountId: rowData.accountId,
              entryDate: rowData.entryDate,
              side: rowData.side === 'debit' ? 'credit' : 'debit',
              taxRateId: item.taxRateId,
              text: rowData.description,
            },
          }))
        }}
        preselectedId={rowData.selectedAccountId}
      />
    ),
    [data, isBulkActionsActive, isDone, isProcessing],
  )

  const renderTaxRateSelector = useCallback(
    (rowData: BankLineRow) => (
      <TaxRateSelector
        disabled={isDone || isProcessing || isBulkActionsActive}
        mb={0}
        name={`tax-rate-selector-${rowData.matchId}`}
        stale={staleCounter.taxRates++ > 0}
        width={170}
        listPlacement="bottom-end"
        onItemSelect={(item: SmartSelectItem) => {
          const taxRate = item as TaxRate
          const update = { selectedTaxRateId: taxRate.id }
          const newData = data.map((match) => (match.matchId === rowData.matchId ? { ...match, ...update } : match))
          setData(() => newData as BankLineRow[])
          const prevFormData = formData[rowData.matchId] || {}
          setFormData((formData) => ({
            ...formData,
            [rowData.matchId]: {
              ...prevFormData,
              taxRateId: taxRate.id,
            },
          }))
        }}
        preselectedId={rowData.selectedTaxRateId || ''}
      />
    ),
    [data, formData, isBulkActionsActive, isDone, isProcessing],
  )

  const renderAmount = useCallback(
    ({ amount, side }: BankLineRow) => (
      <Text
        type="currency"
        currencyColored
        css={css`
          padding-right: 4px;
        `}
      >
        {prefixAmount({ amount, side })}
      </Text>
    ),
    [],
  )

  useEffect(() => {
    const data = bankLineMatches.map((match: ExtendedBankLineMatch, index: number) => {
      return {
        ...match.bankLines[0],
        date: new Date(match.bankLines[0].entryDate),
        index: index + 1,
      }
    })
    setData(data)
  }, [bankLineMatches])

  useEffect(() => {
    const completed = Object.keys(bulkReconcileState).map((key) => bulkReconcileState[key] !== undefined)
    if (completed.length !== 0 && completed.length === data.length) {
      setTimeout(() => {
        setDone(true)
        setProcessing(false)
      }, 1000)
    }
  }, [bulkReconcileState, data.length])

  const columns: TableColumn[] = [
    {
      field: 'index',
      text: t('bankreconciliation.bulk_reconcile.transactions_header'),
      type: 'center',
    },
    {
      field: 'date',
      text: t('date'),
      type: 'date',
    },
    {
      field: 'description',
      text: t('description'),
      type: 'text',
      truncate: true,
    },
    {
      field: 'amount',
      text: t('amount'),
      render: renderAmount,
      type: 'number',
    },
    {
      field: 'selector_account',
      text: t('account'),
      render: renderAccountSelector,
      type: 'custom',
    },
    {
      field: 'taxRateId',
      text: t('vat'),
      render: renderTaxRateSelector,
      type: 'custom',
    },
    {
      field: 'result',
      text: '',
      render: renderResultIcon,
      type: 'icon',
    },
  ]

  return (
    <Modal onClose={onClose} {...rest}>
      <ModalHeader heading={t('bankreconciliation.bulk_reconcile.title')} />
      <ModalBody
        css={css`
          overflow-y: scroll;
          min-height: 50vh;
        `}
      >
        <InfiniteTable
          columns={columns}
          data={data}
          onRowSelect={handleRowSelect}
          total={data?.length}
          rowSpacing={10}
          bulkActions={{
            onToggle: handleBulkActionsToggled,
            onDeleteRow: handleDeleteRow,
            onDone: handleDone,
            totalRowsText: (count) => t('bankreconciliation.bulk_reconcile.transactions_total', { count }),
            selectedRows: selectedRows.length,
            pt: 0,
          }}
        />
      </ModalBody>
      <ModalFooter>
        {!isDone && (
          <Button disabled={isProcessing} onClick={handleClose} variant="text">
            {t('bankreconciliation.bulk_reconcile.btn_cancel')}
          </Button>
        )}
        {isProcessing && <Text mt="5px">{t('bankreconciliation.bulk_reconcile.please_wait')}</Text>}
        {isDone && <Text mt="5px">{t('bankreconciliation.bulk_reconcile.completed')}</Text>}
        <Button onClick={isDone ? handleClose : handleBulkCreate} disabled={isProcessing}>
          {isDone
            ? t('bankreconciliation.bulk_reconcile.btn_complete')
            : t('bankreconciliation.bulk_reconcile.btn_save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
