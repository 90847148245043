import { DropzoneFullScreen, DropzoneFullScreenProps } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useIsBasicPlan } from '../../../hooks/useIsBasicPlan'

export interface DropzoneFullScreenPremiumProps extends Omit<DropzoneFullScreenProps, 'isBlocked'> {
  isBlocked?: boolean
}

export const DropzoneFullScreenPremium = ({
  blockedText,
  isBlocked: blocked,
  ...rest
}: DropzoneFullScreenPremiumProps): ReactElement => {
  const { t } = useTranslation()

  const { isBasicPlan } = useIsBasicPlan()
  const isBlocked = blocked || isBasicPlan

  return (
    <DropzoneFullScreen
      {...rest}
      blockedText={blockedText || t('premium_feature.wrong_subscription.info_warning')}
      isBlocked={isBlocked}
    />
  )
}
