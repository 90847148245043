import { useEffect } from 'react'

import { BILLY_LENDING_URL } from '../constants'

export const useGenerateFrodaRedirectUrl = (
  setHomeAccessUrl: (url: string) => void,
  parentUrl: string,
  redirectToken: string | undefined,
  errorCode: string | undefined,
  authCode: string | undefined,
) => {
  useEffect(() => {
    if (redirectToken || errorCode || authCode) {
      const queryStrings = [`parentUrl=${parentUrl}`]

      if (redirectToken) {
        queryStrings.push(`token=${redirectToken}`)
      }
      if (errorCode) {
        queryStrings.push(`error_code=${errorCode}`)
      }
      if (authCode) {
        queryStrings.push(`auth_code=${authCode}`)
      }

      setHomeAccessUrl(`${BILLY_LENDING_URL}?${queryStrings.join('&')}`)
    }
  }, [redirectToken, errorCode, parentUrl, authCode, setHomeAccessUrl])
}
