import { InternalAccessor } from '@design-system'

import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Contact } from '@views/contacts/types/contact'
import { getContactName } from '@views/contacts/utils/getContactName'

import { BillState } from '../enums/billState'
import { BillType } from '../enums/billType'
import { BillsTableAccessor } from '../routes/BillsList/enums/billsTableAccessor'
import { BillsTableRow } from '../routes/BillsList/types/billsTableRow'
import { Bill } from '../types/bill'
import { getBillAmountToDisplayValue } from '../utils/getBillAmountToDisplayValue'
import { getBillEditPath } from '../utils/getBillEditPath'
import { getBillStatus } from '../utils/getBillStatus'
import { getBillViewPath } from '../utils/getBillViewPath'

export const useBillsTableData = (bills: Bill[], contacts: Contact[]): BillsTableRow[] => {
  const location = useLocation()

  return useMemo(
    () =>
      bills.map(
        ({
          amount,
          attachments,
          balance,
          currencyId,
          contactId,
          dueDate,
          entryDate,
          grossAmount,
          id,
          isPaid,
          lineDescription,
          state,
          suppliersInvoiceNo,
          voucherNo,
          tax,
          type,
        }) => {
          const isCreditNote = type === BillType.CreditNote
          const billPath =
            state === BillState.Draft
              ? `${getBillEditPath(id)}${location.search}`
              : `${getBillViewPath(id)}${location.search}`

          return {
            [InternalAccessor.UrlInternal]: billPath,
            [BillsTableAccessor.Attachment]: !!attachments.length,
            [BillsTableAccessor.Balance]:
              balance && currencyId
                ? getBillAmountToDisplayValue({ amount: balance, currency: currencyId, isCreditNote: false })
                : undefined,
            [BillsTableAccessor.Date]: entryDate,
            [BillsTableAccessor.Description]: lineDescription
              ? {
                  isCreditNote,
                  value: lineDescription,
                }
              : undefined,
            [BillsTableAccessor.DueDate]: !isPaid && dueDate ? dueDate : null,
            [BillsTableAccessor.ExcludedVat]:
              amount !== null && currencyId
                ? getBillAmountToDisplayValue({ amount, currency: currencyId, isCreditNote })
                : undefined,
            [BillsTableAccessor.IncludedVat]:
              grossAmount !== null && currencyId
                ? getBillAmountToDisplayValue({ amount: grossAmount, currency: currencyId, isCreditNote })
                : undefined,
            [BillsTableAccessor.RefNo]: suppliersInvoiceNo,
            [BillsTableAccessor.Status]: getBillStatus({
              amount,
              balance,
              dueDate: dueDate ? new Date(dueDate) : undefined,
              isPaid,
              state,
              tax,
            }),
            [BillsTableAccessor.Vendor]: contactId ? getContactName(contacts, contactId) : undefined,
            [BillsTableAccessor.VoucherNo]: voucherNo,
            id,
          }
        },
      ),
    [bills, contacts, location.search],
  )
}
