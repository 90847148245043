import noop from 'lodash/noop'
import { DropzoneOptions } from 'react-dropzone'

export type DropzoneBlockedDefaultProps = Pick<DropzoneOptions, 'noClick' | 'noKeyboard' | 'onDrop'>

export const dropzoneBlockedDefaultProps: DropzoneBlockedDefaultProps = {
  noClick: true,
  noKeyboard: true,
  onDrop: noop,
}
