import { Color, IconButton } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { useOnboarding } from '../../../../hooks/useOnboarding'
import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { Widget } from '../../elements/Widget'
import { WidgetId } from '../../enums/widgetId'
import { PostChecklistContent } from './elements/PostChecklistContent'
import { PreChecklistContent } from './elements/PreChecklistContent'
import * as Styled from './styles'

export const GetStarted = (): ReactElement => {
  const { isWidgetHidden, toggleWidgetVisibility } = useDashboardSettings()
  const { isLoading: isLoadingOnboarding, isOnboardingCompleted, isAfterDayFromRegistering } = useOnboarding()

  const isHidden = isWidgetHidden(WidgetId.GetStarted)

  const handleHideWiget = useCallback(() => {
    toggleWidgetVisibility({ id: WidgetId.GetStarted, shouldHide: true })
  }, [toggleWidgetVisibility])

  return (
    <Widget
      isHidden={isHidden}
      backgroundColor={isOnboardingCompleted ? Color.Purple20 : Color.Lavender}
      isLoading={isLoadingOnboarding}
    >
      {!isOnboardingCompleted && isAfterDayFromRegistering && (
        <Styled.CloseIconWrapper>
          <IconButton icon="xSign" iconColor={Color.DeepSeaGreen} size="m" onClick={handleHideWiget} />
        </Styled.CloseIconWrapper>
      )}
      {isOnboardingCompleted ? <PostChecklistContent /> : <PreChecklistContent />}
    </Widget>
  )
}
