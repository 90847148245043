import React from 'react'
import { Flex, FlexProps } from 'rebass'

export enum MenuItemTextVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

type MenuItemTextProps = FlexProps & {
  variant?: MenuItemTextVariant
  children?: React.ReactNode
}

export const MenuItemText = ({ children, variant = MenuItemTextVariant.Primary, ...rest }: MenuItemTextProps) => {
  const color = variant === MenuItemTextVariant.Primary ? '#40494f' : '#697588'

  const fontSize = variant === MenuItemTextVariant.Primary ? '14px' : '12px'

  return (
    <Flex color={color} fontSize={fontSize} {...rest}>
      {children}
    </Flex>
  )
}
