import { Color, DefaultValue, getDefaultTransition, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

export const HitsWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  max-width: calc(100% - ${Spacing.XXL} * 2);
  margin-top: ${Spacing.XS};
  max-height: 350px;
  overflow: scroll;
  overflow-x: hidden;
  box-shadow: 0 10px 70px ${transparentize(0.65, Color.Gray200)};
  padding: ${Spacing.S};
  border-radius: ${Spacing.XXS};
  background-color: ${Color.White};
`

export const NoResultsWrapper = styled.li`
  padding: ${Spacing.S} 0;
  display: flex;
  justify-content: center;
`

export const HitsList = styled.ul``

interface HitListItemProps {
  isFocused?: boolean
}

export const HitListItem = styled.li<HitListItemProps>`
  border-radius: ${DefaultValue.BorderRadius};
  overflow: hidden;
  ${getDefaultTransition('background-color')};

  &:hover {
    background-color: ${transparentize(0.75, Color.Gray60)};
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${transparentize(0.75, Color.Gray60)};
    `}
`
