import { Modal, ModalProps, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateOrEditUmbrellaRoleContextProvider } from '../../context/createOrEditUmbrellaRoleContext'
import { ModalBody } from './elements/ModalBody'
import { ModalFooter } from './elements/ModalFooter'

interface CreateOrEditRoleModalProps extends ModalProps {
  roleId?: string
}

export const CreateOrEditUmbrellaRoleModal = ({ roleId, ...props }: CreateOrEditRoleModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeRoleModal } = useModal(props.id)

  const titleKey = roleId
    ? 'umbrella_roles.edit_roles_modal.title_edit'
    : 'umbrella_roles.edit_roles_modal.title_create'

  const handleRoleUpdated = useCallback(() => {
    closeRoleModal()
  }, [closeRoleModal])

  return (
    <CreateOrEditUmbrellaRoleContextProvider onRoleUpdated={handleRoleUpdated} roleId={roleId}>
      <Modal {...props} size="s">
        <Modal.Header title={t(titleKey)} />
        <ModalBody />
        <ModalFooter />
      </Modal>
    </CreateOrEditUmbrellaRoleContextProvider>
  )
}
