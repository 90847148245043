import { Button, ButtonsGroup, useModal } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../../config/queryClient'
import { ModalId } from '../../../../../../enums/modalId'
import { QueryKeys } from '../../../../../../enums/queryKeys'
import { useSegment } from '../../../../../../hooks'
import { useCreateBillsAndBankLinesReconciliation } from '../../../../hooks/useReconcileBillsAndBankLines'
import { useInvalidateBills } from '../../../../routes/BillsList'
import { useBankLines } from '../../contexts/bankLinesContext'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useMultipleBills } from '../../contexts/multipleBillsContext'
import { BillReconciliationModalStateId } from '../../enums/billReconciliationModalStateId'
import { PaymentRegistrationSuccessState } from '../../enums/paymentRegistrationSuccessState'
import { convertBillsIdsToReconciliationSubjects } from '../../utils/convertBillsIdsToReconciliationSubjects'
import { notifyBillReconciliationError } from '../../utils/notifyBillReconciliationError'
import { notifyBillReconciliationSuccess } from '../../utils/notifyBillReconciliationSuccess'
import { useModalContentToggler } from '../ModalContentToggler'

export const BankLinesModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { canUsePrepayment, initialBill, selectedDifferenceType } = useBillReconciliation()
  const { bankLines, hasBankLinesDifference, selectedBankLinesIds } = useBankLines()
  const { selectedBillsIds } = useMultipleBills()
  const { setActiveState } = useModalContentToggler()
  const { invalidateBills } = useInvalidateBills()
  const { close: closeBillReconciliationModal } = useModal(ModalId.BillReconciliationModal, {
    onClose: () => {
      track('xxx - expenses - Bank line modal closed')
    },
  })

  const { create: createBillsAndBankLinesReconcilation, isProcessing } = useCreateBillsAndBankLinesReconciliation({
    onSuccess: () => {
      notifyBillReconciliationSuccess(PaymentRegistrationSuccessState.RegisteredAndReconciled, t)
      queryClient.invalidateQueries([QueryKeys.Bill, initialBill?.id])
      invalidateBills()
      closeBillReconciliationModal()
    },
    onError: () => notifyBillReconciliationError(t),
  })

  const organizationId = organization?.id
  const isEnterPaymentButtonDisabled = useMemo(
    () => (hasBankLinesDifference && !selectedDifferenceType) || !selectedBankLinesIds?.length,
    [hasBankLinesDifference, selectedBankLinesIds?.length, selectedDifferenceType],
  )

  const handleEnterPaymentButtonClick = useCallback(() => {
    if (!selectedBankLinesIds?.length || !selectedBillsIds?.length || !organizationId) {
      return
    }

    if (canUsePrepayment) {
      setActiveState(BillReconciliationModalStateId.Prepayment)
      return
    }

    if (selectedBankLinesIds?.length === 1) {
      track('xxx - expenses - Bank line modal - register payment clicked', {
        bankLinesCount: bankLines?.length || 0,
      })
    }

    if (selectedBankLinesIds?.length > 1) {
      track('xxx - expenses - Bank line modal - register multiple payments clicked', {
        selectedBankLinesCount: selectedBankLinesIds.length,
      })
    }

    const billReconciliationSubjects = convertBillsIdsToReconciliationSubjects(selectedBillsIds)

    createBillsAndBankLinesReconcilation({
      bankLinesIds: selectedBankLinesIds,
      organizationId,
      subjects: billReconciliationSubjects,
    })
  }, [
    bankLines?.length,
    canUsePrepayment,
    createBillsAndBankLinesReconcilation,
    organizationId,
    selectedBankLinesIds,
    selectedBillsIds,
    setActiveState,
    track,
  ])

  const handleRegisterMultipleBillsButton = useCallback(() => {
    track('xxx - expenses - Bank line modal - register mutliple bills clicked')

    setActiveState(BillReconciliationModalStateId.MultipleBills)
  }, [setActiveState, track])

  return (
    <ButtonsGroup>
      <Button onClick={handleRegisterMultipleBillsButton} variant="text" icon="papersPile">
        {t('bill.payment_reconcile.button.register_multiple_bills')}
      </Button>
      <Button onClick={handleEnterPaymentButtonClick} disabled={isEnterPaymentButtonDisabled} loading={isProcessing}>
        {t('bill.payment_reconcile.button.enter_payment_and_reconcile')}
      </Button>
    </ButtonsGroup>
  )
}
