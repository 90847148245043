import { SettingsSection } from '@components'
import { Button, Checkbox, CheckboxGroup, Space, Text, useModal } from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { DeleteTrialDataModal } from './elements/DeleteTrialDataModal'
import { DeleteTrialDataOptions } from './types/deleteTrialDataOptions'

export const defaultDeleteTrialDataValues: DeleteTrialDataOptions = {
  resetContacts: false,
  resetProducts: false,
}

export const DeleteTrialDataSection = (): ReactElement => {
  const { t } = useTranslation()
  const [values, setValues] = useReducer(
    (prevState: DeleteTrialDataOptions, nextStatePartial: Partial<DeleteTrialDataOptions>) => ({
      ...prevState,
      ...nextStatePartial,
    }),
    defaultDeleteTrialDataValues,
  )
  const { open: openDeleteTrialDataModal } = useModal(ModalId.DeleteTrialDataModal)

  const handleDeleteButtonClick = useCallback(() => {
    openDeleteTrialDataModal()
  }, [openDeleteTrialDataModal])

  const handleCheckboxChange = useCallback(
    (value: keyof DeleteTrialDataOptions) => (event: ChangeEvent<HTMLInputElement>) => {
      setValues({ [value]: event.currentTarget.checked })
    },
    [],
  )

  return (
    <SettingsSection>
      <SettingsSection.Content
        title={t('settings.organization.organization_info.delete_trial_data.title')}
        subtitle={t('settings.organization.organization_info.delete_trial_data.subtitle')}
      >
        <Text weight="bold"> {t('settings.organization.organization_info.delete_trial_data.warning')}</Text>
        <Space />
        <CheckboxGroup direction="column">
          <Checkbox checked disabled>
            {t('settings.organization.organization_info.delete_trial_data.checkbox.invoices')}
          </Checkbox>
          <Checkbox checked disabled>
            {t('settings.organization.organization_info.delete_trial_data.checkbox.bills')}
          </Checkbox>
          <Checkbox checked disabled>
            {t('settings.organization.organization_info.delete_trial_data.checkbox.payments')}
          </Checkbox>
          <Checkbox checked disabled>
            {t('settings.organization.organization_info.delete_trial_data.checkbox.transactions')}
          </Checkbox>
          <Checkbox checked disabled>
            {t('settings.organization.organization_info.delete_trial_data.checkbox.bank_statement_reconciliations')}
          </Checkbox>
          <Checkbox onChange={handleCheckboxChange('resetProducts')} checked={values.resetProducts}>
            {t('settings.organization.organization_info.delete_trial_data.checkbox.products')}
          </Checkbox>
          <Checkbox onChange={handleCheckboxChange('resetContacts')} checked={values.resetContacts}>
            {t('settings.organization.organization_info.delete_trial_data.checkbox.contacts')}
          </Checkbox>
        </CheckboxGroup>
        <Space />
        <Text weight="bold">{t('settings.organization.organization_info.delete_trial_data.instruction')}</Text>
      </SettingsSection.Content>
      <SettingsSection.Footer>
        <Button danger onClick={handleDeleteButtonClick}>
          {t('settings.organization.organization_info.delete_trial_data.button_label')}
        </Button>
      </SettingsSection.Footer>
      <DeleteTrialDataModal deleteTrialDataOptions={values} />
    </SettingsSection>
  )
}
