import { Color, DefaultValue, getDefaultTransition, Spacing, Text } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Themable } from '../../../../types/themable'

interface ReceiptWrapperProps extends Themable {
  checked?: boolean
}

export const ReceiptWrapper = styled.li<ReceiptWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: ${DefaultValue.BorderRadius};
  position: relative;
  background-color: ${Color.White};
  overflow: hidden;
  box-shadow: 0 48px 110px 0 ${transparentize(0.5, Color.Gray50)};
  aspect-ratio: 1 / 1.41;

  // Overlay for handling border around tile on hover and select.
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: ${DefaultValue.BorderRadius};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    box-shadow: inset 0 0 0 2px ${({ checked }) => (checked ? Color.DeepSeaGreen : 'transparent')};
    pointer-events: none;
    ${getDefaultTransition('box-shadow')};
  }

  &:hover {
    ${({ checked }) =>
      !checked &&
      css`
        &:after {
          box-shadow: inset 0 0 0 1px ${Color.DeepSeaGreen};
        }
      `}
  }
`

interface ReceiptCheckboxWrapperProps extends Themable {
  checked: boolean
}

export const ReceiptCheckboxWrapper = styled.div<ReceiptCheckboxWrapperProps>`
  position: absolute;
  top: ${Spacing.M};
  right: ${Spacing.M};
  z-index: 2;
  ${getDefaultTransition('opacity')};
`

export const ReceiptBadgeWrapper = styled.div`
  margin-left: ${Spacing.S};
`

export const ReceiptImageWrapper = styled.figure`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 100%;
`

interface ReceiptImageProps {
  loading: boolean
}

export const ReceiptPreviewWrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'loading',
})<ReceiptImageProps>`
  height: 100%;

  ${({ loading }) =>
    loading &&
    `
    opacity: 0;
    width: 100%;
    position: absolute;
  `}
`

export const ReceiptContentWrapper = styled.div`
  padding: ${Spacing.L};
`

export const ReceiptContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ReceiptLeft = styled(Text)``

export const ReceiptRight = styled(Text)``

export const ReceiptFooter = styled.footer`
  margin-top: ${Spacing.S};
`
