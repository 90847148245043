import { LineHeight } from '@design-system'

import styled from '@emotion/styled'

interface LogoWrapperProps {
  clickable?: boolean
}

export const LogoWrapper = styled.figure<LogoWrapperProps>`
  display: flex;
  height: ${LineHeight.TextH1};
  align-items: center;
  ${({ clickable }) => clickable && 'cursor: pointer'};
`
