import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

import { Account } from '@modules-deprecated/app/accounts/types'

import { AccountsInGroups } from '../types'

export const groupAccountsByGroupId = (accounts: Account[]): AccountsInGroups => {
  const sortedAccounts = sortBy(accounts, 'accountNo')
  const accountsInGroups = groupBy(sortedAccounts, 'group.id')
  return accountsInGroups as unknown as AccountsInGroups
}
