import { Color, DefaultValue, FontSize, FontWeight, LineHeight, Spacing } from '@design-system'

import { transparentize } from 'polished'

import { FORMS_INNER_SHADOW } from '../constants/formsInnerShadow'
import colors from './colors'

const BORDER_RADIUS = DefaultValue.BorderRadius
const MARGIN_BOTTOM = 10
const HEIGHT = 40
const HEIGHT_BIG = 48

const DISABLED = {
  color: Color.Gray50,
  borderColor: Color.Gray40,
  backgroundColor: Color.Gray20,
  boxShadow: 'none',

  '&::placeholder': {
    color: Color.Gray50,
  },
}

const FOCUS = {
  '&:focus, &.focus': {
    borderColor: Color.Green,
    boxShadow: `${FORMS_INNER_SHADOW}, 0 0 0 3px ${Color.Green10}`,
  },
}

const SELECT = {
  boxShadow: `${FORMS_INNER_SHADOW}, 0 1px 2px ${transparentize(0.3, Color.Gray50)}`,
  fontWeight: FontWeight.Medium,

  '&::placeholder': {
    opacity: 1,
    color: Color.DeepSeaGreen,
    fontSize: FontSize.TextNormal,
    fontWeight: FontWeight.Medium,
  },
  '&:disabled': {
    '&::placeholder': {
      color: Color.Gray60,
    },
  },
  '&:not(:disabled):not(.invalid)': {
    ...FOCUS,
  },
}

const errorStyle = {
  backgroundColor: Color.Red5,
  borderColor: Color.Red,

  '&, &.for-select': {
    '&:focus, &.focus': {
      borderColor: Color.Red,
      boxShadow: `${FORMS_INNER_SHADOW}, 0 0 0 3px ${transparentize(0.8, Color.Red90)}`,
    },
    '&:hover': {
      borderColor: Color.Red,
    },
  },
}

const inputStyle = {
  outline: 'none',
  padding: `${Spacing.S} ${Spacing.M}`,
  width: '100%',
  fontFamily: DefaultValue.FontFamily,
  fontSize: FontSize.TextNormal,
  color: Color.DeepSeaGreen,
  border: `1px solid ${Color.Gray50}`,
  borderRadius: BORDER_RADIUS,
  backgroundColor: Color.White,
  boxShadow: FORMS_INNER_SHADOW,
  transition: `
    border ${DefaultValue.TransitionDuration} ${DefaultValue.TransitionTimingFunction},
    background ${DefaultValue.TransitionDuration} ${DefaultValue.TransitionTimingFunction},
    box-shadow ${DefaultValue.TransitionDuration} ${DefaultValue.TransitionTimingFunction}
  `,

  '&.big': {
    padding: Spacing.M,
  },
  '&:not(:disabled):not(.invalid):not(:focus):not(.focus):hover': {
    borderColor: Color.DeepSeaGreen,
  },
  ...FOCUS,
  '&.invalid': errorStyle,
  '&:disabled': {
    ...DISABLED,
  },
  '&.hidden': {
    display: 'none',
  },
  '&::placeholder': {
    color: Color.Gray60,
    fontSize: FontSize.TextNormal,
    fontWeight: FontWeight.Regular,
  },
  '&.for-select': {
    paddingRight: '30px',
    textOverflow: 'ellipsis',
    ...SELECT,
  },
  '&.with-right-icon': {
    paddingRight: '50px',
  },
  '&.suggestion': {
    paddingRight: '90px',
  },
  '&.prefilled': {
    backgroundColor: Color.Purple20,
    borderColor: Color.Lavender,
    borderStyle: 'solid',
  },
}

export default {
  defaultRadius: BORDER_RADIUS,
  form: {
    margin: 0,
    '> *': {
      transition: 'background-color .1s linear, border-color .1s linear',
    },
  },
  formcontrol: {
    marginBottom: MARGIN_BOTTOM,
  },
  fieldset: {
    marginBottom: MARGIN_BOTTOM,
    padding: 0,
  },
  formgroup: {
    label: {
      display: 'none',
    },
    input: {
      borderRadius: 0,
      marginTop: '-1px',
    },
    'input:focus, input:hover': {
      zIndex: 1,
      position: 'relative',
    },
    '> div': {
      marginBottom: 0,
    },
    '>  div::first-of-type input': {
      borderTopLeftRadius: BORDER_RADIUS,
      borderTopRightRadius: BORDER_RADIUS,
    },
    '> :last-child input': {
      borderBottomLeftRadius: BORDER_RADIUS,
      borderBottomRightRadius: BORDER_RADIUS,
    },
  },
  formhorizontalgroup: {
    display: 'flex',
    label: {
      display: 'none',
    },
    input: {
      borderRadius: 0,
    },
    'input:focus, input:hover': {
      zIndex: 1,
      position: 'relative',
    },
    '> div:not(:first-of-type) input': {
      marginLeft: '-1px',
    },
    '> div:first-of-type input': {
      borderTopLeftRadius: BORDER_RADIUS,
      borderBottomLeftRadius: BORDER_RADIUS,
    },
    '> :last-child input': {
      borderTopRightRadius: BORDER_RADIUS,
      borderBottomRightRadius: BORDER_RADIUS,
    },
  },
  datecheckbox: {
    checkboxwrapper: {
      border: inputStyle.border,
      borderTopRightRadius: BORDER_RADIUS,
      borderBottomRightRadius: BORDER_RADIUS,
      padding: inputStyle.padding,

      '&.disabled': {
        ...DISABLED,
      },
    },
  },
  addressgroup: {
    input: { borderRadius: 0 },
    '> div:first-of-type': {
      marginBottom: 0,
      input: {
        borderBottom: 'none',
        borderTopLeftRadius: BORDER_RADIUS,
        borderTopRightRadius: BORDER_RADIUS,
      },
    },
    '> div > *': {
      marginBottom: 0,
      '&:first-of-type': {
        flex: 1,
        input: {
          borderBottomLeftRadius: BORDER_RADIUS,
        },
      },
      '&:last-of-type': {
        flex: 2,
        input: {
          borderLeft: 'none',
          borderBottomRightRadius: BORDER_RADIUS,
        },
      },
    },
  },
  error: {
    '&:not:empty': {
      marginBottom: MARGIN_BOTTOM,
    },
  },
  label: {
    marginBottom: Spacing.XXXS,
    color: Color.Gray90,
    fontSize: FontSize.TextNormal,
    fontWeight: FontWeight.Regular,
    lineHeight: LineHeight.TextNormal,
  },
  input: {
    ...inputStyle,
    height: HEIGHT,

    '&.big': {
      height: HEIGHT_BIG,
    },
  },
  textarea: {
    ...inputStyle,
    height: HEIGHT * 2,

    '&.big': {
      height: HEIGHT_BIG * 2,
    },
  },
  select: {
    ...inputStyle,
    ...SELECT,
    height: HEIGHT,
  },
  slider: {
    position: 'relative',
    width: '100%',
    margin: '50px auto',
    height: '2px',
    zIndex: 5,
    cursor: 'pointer',
    '&::-webkit-slider-thumb': {
      width: '28px',
      height: '28px',
      borderRadius: '14px',
      border: '1px solid #2fc557',
      background: 'radial-gradient(circle, rgba(47,197,87,1) 25%, rgba(255,255,255,1) 25%)',
      zIndex: 10,
      transform: 'translateY(-14px)',
      boxShadow: '0 4px 6px -1px rgba(100,100,100, .4)',
    },
    '&::-webkit-slider-runnable-track': {
      height: '2px',
      borderRadius: '2px',
      backgroundColor: colors.gray2,
    },
  },
}
