import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconMenetoInbox = ({ color = '#FFFFFF', width = '18px', height = '18px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-meneto-inbox')} {...rest}>
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 314.75 256.95">
      <polyline
        points="301.88 128.48 215.18 128.48 186.28 171.83 128.48 171.83 99.58 128.48 12.88 128.48"
        fill="none"
        stroke={coloring(color)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="25.75"
      />
      <path
        d="M66.85,77.44,17,177v86.7a28.9,28.9,0,0,0,28.9,28.9H277.1A28.9,28.9,0,0,0,306,263.7V177L256.15,77.44a28.91,28.91,0,0,0-25.87-16H92.72A28.91,28.91,0,0,0,66.85,77.44Z"
        transform="translate(-4.12 -48.52)"
        fill="none"
        stroke={coloring(color)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="25.75"
      />
    </svg>
  </Box>
)
