import React, { ReactNode } from 'react'

import { Icon, IconName } from '../Icon'
import { IconButton } from '../IconButton'
import { Space } from '../Space'
import { Text } from '../Text'
import * as Styled from './styles'
import { SectionMessageVariant } from './types'
import { variantStyles } from './utils'

export interface SectionMessageProps {
  children?: ReactNode
  closable?: boolean
  icon?: IconName
  onClose?: () => void
  showIcon?: boolean
  sideActions?: ReactNode
  title?: string
  variant?: SectionMessageVariant
}

export const SectionMessage = ({
  children,
  closable,
  icon: customIcon,
  onClose,
  showIcon = true,
  sideActions,
  title,
  variant = 'warning',
  ...rest
}: SectionMessageProps) => {
  const icon = customIcon || variantStyles[variant].icon
  const color = variantStyles[variant].color

  return (
    <Styled.SectionMessage {...rest} variant={variant}>
      {showIcon && (
        <Styled.IconWrapper>
          <Icon color={color} icon={icon} />
        </Styled.IconWrapper>
      )}

      <Styled.ContentWrapper>
        {title && (
          <Styled.Header>
            <Text variant="h3" color={color}>
              {title}
            </Text>
          </Styled.Header>
        )}

        {title && children && <Space size="s" />}

        <Text variant="normal" color={color}>
          {children}
        </Text>

        {sideActions && <Styled.SideActionsWrapper>{sideActions}</Styled.SideActionsWrapper>}
      </Styled.ContentWrapper>

      {closable && (
        <Styled.IconButtonWrapper>
          <IconButton size="m" onClick={onClose} color={color} icon="xSign" />
        </Styled.IconButtonWrapper>
      )}
    </Styled.SectionMessage>
  )
}
