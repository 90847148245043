import { Flex, ModalConfirmation, Spacing, Text, useModal } from '@design-system'

import { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { USERFLOW_DOWNLOAD_MOBILE_APP_ID } from '@views/dashboard/constants/userflowIds'

import { ModalId } from '../../../../enums/modalId'
import { useUserflow } from '../../../../hooks'
import { useQueryParams } from '../../../../hooks/useQueryParams'
import { getImgPath } from '../../../../utils/getImgPath'
import * as Styled from './styles'

enum ScoutConnectedModalQueryParamValue {
  open = 'true',
  closed = 'false',
}

export const ScoutConnectedModal = (): ReactElement => {
  const { t } = useTranslation()
  const { close, open } = useModal(ModalId.ScoutConnectionConnectedModal)
  const { start } = useUserflow()
  const [queryParams, setQueryParams] = useQueryParams<{ scoutConnectedModalOpen: string }>({
    enabled: true,
    defaultQuery: { scoutConnectedModalOpen: ScoutConnectedModalQueryParamValue.closed },
  })

  useEffect(() => {
    if (queryParams.scoutConnectedModalOpen === ScoutConnectedModalQueryParamValue.open) {
      open?.()
    }
  }, [queryParams, open])

  const onCloseScoutConnectedModal = useCallback(() => {
    setQueryParams({ scoutConnectedModalOpen: ScoutConnectedModalQueryParamValue.closed })
  }, [setQueryParams])

  const closeScoutConnectedModal = useCallback(() => {
    close?.()
  }, [close])

  const handleStartUserflow = useCallback(() => {
    start(USERFLOW_DOWNLOAD_MOBILE_APP_ID)
    closeScoutConnectedModal()
  }, [start, closeScoutConnectedModal])

  return (
    <ModalConfirmation
      id={ModalId.ScoutConnectionConnectedModal}
      message={
        <Flex flexDirection="column" gap={Spacing.XL}>
          <Text>{t('receipts.side_panel.connected.modal.body1')}</Text>
          <Text variant="small" weight="bold">
            {t('receipts.side_panel.connected.modal.body2')}
          </Text>
          <Flex gap={Spacing.L}>
            <Styled.AppIllustration
              alt="Billy app"
              onClick={handleStartUserflow}
              src={getImgPath('/app-store-illustrations/billy-google-play.svg')}
            />
            <Styled.AppIllustration
              alt="Billy app"
              onClick={handleStartUserflow}
              src={getImgPath('/app-store-illustrations/billy-app-store.svg')}
            />
          </Flex>
        </Flex>
      }
      okLabel={t('ok')}
      onClose={onCloseScoutConnectedModal}
      onOk={closeScoutConnectedModal}
      title={t('receipts.side_panel.connected.modal.title')}
    />
  )
}
