import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import orderBy from 'lodash/orderBy'

import { getLastViewDate } from './getLastViewDate'

interface ShouldShowReminderProps {
  currentDate: Date
  periodicLockReminderLastViewedDate?: string
  reminderDates: Date[]
}

export const shouldShowReminder = ({
  currentDate,
  periodicLockReminderLastViewedDate,
  reminderDates,
}: ShouldShowReminderProps): boolean => {
  const lastViewedDate = getLastViewDate({
    currentDate,
    periodicLockReminderLastViewedDate,
    reminderDates,
  })
  const reminderDatesSorted = orderBy(reminderDates)
  let value = false

  for (const reminderDate of reminderDatesSorted) {
    // if currentDate is after reminderDate AND lastViewedDate is before reminderDate:
    if (isAfter(currentDate, reminderDate) && isBefore(lastViewedDate, reminderDate)) {
      value = true
      break
    }
  }

  return value
}
