import React, { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Link } from '../../../Link'
import * as Styled from './styles'

interface SavingOverlayProps {
  disabled?: boolean
  isSavingFinished: boolean
  itemsToSaveCount: number
  onClose(): void
  shouldDisplay: boolean
  savedCount: number
}

export const SavingOverlay = ({
  disabled,
  isSavingFinished,
  itemsToSaveCount,
  onClose,
  shouldDisplay,
  savedCount,
}: SavingOverlayProps): ReactElement | null => {
  const { t } = useTranslation()

  if (!shouldDisplay) {
    return null
  }

  return (
    <Styled.SavingContainer>
      <Styled.Text>{isSavingFinished ? t('attachments.uploading_complete') : t('attachments.uploading')}</Styled.Text>
      <Styled.Text>
        <Trans
          i18nKey="attachments.upload_stats"
          count={itemsToSaveCount}
          values={{
            count: itemsToSaveCount,
            savedCount,
          }}
        >
          Uploaded <strong>{savedCount}</strong> of <strong>{itemsToSaveCount}</strong> file.
        </Trans>
      </Styled.Text>
      {isSavingFinished && !disabled && (
        <Styled.Text>
          <Link onClick={onClose}>{t('attachments.close')}</Link>
        </Styled.Text>
      )}
    </Styled.SavingContainer>
  )
}
