import { InvoiceState } from '../enums/invoiceState'
import { InvoiceStatus } from '../enums/invoiceStatus'

interface StatusToQueryPropsArgs {
  isOverdue: boolean
  isPaid: boolean
  state: InvoiceState | InvoiceState[]
}

export const invoiceStatusToQueryProps: Record<InvoiceStatus, Partial<StatusToQueryPropsArgs>> = {
  [InvoiceStatus.All]: {
    state: [InvoiceState.Approved, InvoiceState.Draft],
  },
  [InvoiceStatus.Draft]: {
    state: [InvoiceState.Draft],
  },
  [InvoiceStatus.Overdue]: {
    isOverdue: true,
    isPaid: false,
    state: [InvoiceState.Approved],
  },
  [InvoiceStatus.Paid]: {
    isPaid: true,
    state: [InvoiceState.Approved],
  },
  [InvoiceStatus.PartiallyPaid]: {},
  [InvoiceStatus.Unpaid]: {
    isPaid: false,
    state: [InvoiceState.Approved],
  },
}
