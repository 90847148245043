import { Color, Icon, IconName, Tooltip } from '@design-system'

import cc from 'classcat'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { AppButtonType } from '../../../../types/appButtonType'
import { getClassName } from '../../../../utils/getClassName'
import * as Styled from './styles'

interface AppButtonTypeProperties {
  iconName: IconName
  iconColor: Color
  labelTKey: string
  backgroundColor: Color
  buttonColor?: Color
  labelColor?: Color
  hoverButtonColor?: Color
  hoverBackgroundColor?: Color
  hoverIconColor?: Color
}

const appButtonProperties: Record<AppButtonType, AppButtonTypeProperties> = {
  ageras: {
    iconName: 'calculator',
    iconColor: Color.Black,
    labelTKey: 'dashboard.side_navbar.ageras_accounting',
    backgroundColor: Color.White,
    buttonColor: Color.ElectricGreen,
  },
  zervant: {
    iconName: 'documentWithDollarSign',
    iconColor: Color.ElectricGreen,
    labelTKey: 'dashboard.side_navbar.nav_to_zervant',
    backgroundColor: Color.Gray20,
    buttonColor: Color.DeepSeaGreen,
    hoverButtonColor: Color.Green,
    hoverBackgroundColor: Color.White,
    hoverIconColor: Color.DeepSeaGreen,
  },
}

interface AppButtonProps {
  type: AppButtonType
  isClickable?: boolean
  onClick?: () => void
}

export const AppButton = ({ type, isClickable, onClick }: AppButtonProps): ReactElement => {
  const { t } = useTranslation()
  const {
    iconName,
    iconColor,
    labelTKey,
    backgroundColor,
    buttonColor,
    hoverButtonColor,
    hoverBackgroundColor,
    hoverIconColor,
  } = appButtonProperties[type]

  return (
    <Tooltip label={t(labelTKey)} placement="left">
      <Styled.AppButtonWrapper
        isClickable={isClickable}
        className={cc([getClassName('app-button-wrapper'), type])}
        backgroundColor={backgroundColor}
        buttonColor={buttonColor}
        hoverButtonColor={hoverButtonColor}
        hoverBackgroundColor={hoverBackgroundColor}
        hoverIconColor={hoverIconColor}
        onClick={onClick}
      >
        <Styled.AppButton
          className={getClassName('app-button')}
          isClickable={isClickable}
          buttonColor={buttonColor}
          hoverButtonColor={hoverButtonColor}
        >
          <Icon icon={iconName} color={iconColor} />
        </Styled.AppButton>
      </Styled.AppButtonWrapper>
    </Tooltip>
  )
}
