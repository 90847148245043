import { NavItem, TableStaticActions, useModal, withModalConditionalRender } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateOrEditUmbrellaRoleModal } from '../../../CreateOrEditUmbrellaRoleModal'
import { DeleteRoleModal } from '../DeleteRoleModal/DeleteRoleModal'

const DeleteRoleModalConditional = withModalConditionalRender(DeleteRoleModal)
const EditRoleModalConditional = withModalConditionalRender(CreateOrEditUmbrellaRoleModal)

interface RolesTableStaticActionProps {
  roleId: string
  umbrellaId: string
}

enum RolesTableStaticActionType {
  Delete = 'delete',
  Edit = 'edit',
}

export const getDeleteRoleModalId = (id: string) => `${id}-delete-role`
export const getEditRoleModalId = (id: string) => `${id}-edit-role`

export const RolesTableStaticActions = ({ roleId, umbrellaId }: RolesTableStaticActionProps): ReactElement => {
  const { t } = useTranslation()
  const deleteRoleModalId = getDeleteRoleModalId(roleId)
  const editRoleModalId = getEditRoleModalId(roleId)
  const { open: openDeleteRoleModal } = useModal(deleteRoleModalId)
  const { open: openEditRoleModal } = useModal(editRoleModalId)

  const staticActionsItems: NavItem<RolesTableStaticActionType>[] = useMemo(
    () => [
      {
        id: 'edit',
        children: t('edit'),
        value: RolesTableStaticActionType.Edit,
        iconLeft: 'paperWithPencil',
      },
      {
        id: 'delete',
        children: t('delete'),
        value: RolesTableStaticActionType.Delete,
        iconLeft: 'trash',
      },
    ],
    [t],
  )

  const handleStaticActionSelect = useCallback(
    (id: string, value: RolesTableStaticActionType) => {
      switch (value) {
        case RolesTableStaticActionType.Delete: {
          openDeleteRoleModal()
          break
        }
        case RolesTableStaticActionType.Edit: {
          openEditRoleModal()
          break
        }
      }
    },
    [openDeleteRoleModal, openEditRoleModal],
  )

  return (
    <>
      <TableStaticActions items={staticActionsItems} onSelect={handleStaticActionSelect} />
      <DeleteRoleModalConditional id={deleteRoleModalId} roleId={roleId} umbrellaId={umbrellaId} />
      <EditRoleModalConditional id={editRoleModalId} roleId={roleId} />
    </>
  )
}
