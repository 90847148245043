import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchRecurringInvoicesSpawnSettings } from '../query-api'

export const useFetchIsSpawnEnabled = () => {
  const { organization } = useUserOrganization()

  const { data: spawnSettingsData, ...rest } = useQuery(
    [QueryKeys.RecurringInvoicesSpawnSettings, organization?.id],
    () => fetchRecurringInvoicesSpawnSettings(organization?.id as string),
    {
      enabled: !!organization?.id,
    },
  )

  return { isEnabled: spawnSettingsData?.enabled, ...rest }
}
