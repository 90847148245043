import invert from 'lodash/invert'

import { CustomEvent } from '../../../enums/customEvent'
import { EmberRoute } from '../../../enums/emberRoute'
import { CustomEventDetail } from '../../../hooks/useCustomEventsRoutes'
import { dispatchCustomEvent } from '../../../utils/customEvent'
import { umbrellaRouteToEmberRoute } from '../constants/umbrellaRouteToEmberRoute'
import { UmbrellaRoute } from '../enums/UmbrellaRoute'

// there's an issue when we try to navigate using ember routing to the same module which is currently opened
// that's why it's better to just invoke a "history" method in this module using custom event
export const routeToUmbrella = (route: EmberRoute) => {
  const umbrellaRoute: Partial<Record<EmberRoute, UmbrellaRoute>> = invert(umbrellaRouteToEmberRoute)
  const routeToUmbrella = umbrellaRoute[route]

  if (routeToUmbrella) {
    dispatchCustomEvent<CustomEventDetail>(CustomEvent.RoutingLinkClicked, {
      route: routeToUmbrella,
    })
  }
}
