import React, { ReactNode } from 'react'
import { FieldValues, useFormContext, UseFormReturn } from 'react-hook-form'

export type ConnectFormProps = {
  children: ReactNode | ((args: any) => ReactNode)
}

export type ConnectFormType = UseFormReturn<FieldValues, object>

export const ConnectForm = ({ children }: ConnectFormProps) => {
  const form = useFormContext()

  const result = typeof children === 'function' ? (children({ ...form }) as ReactNode) : children
  return <>{result}</>
}
