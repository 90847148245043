import React, { ReactElement } from 'react'

import * as Styled from './styles'
import { Variant } from './types/variant'

export type StatusFormatterVariant = Variant

interface StatusFormatterProps {
  title?: string
  variant?: StatusFormatterVariant | [StatusFormatterVariant, StatusFormatterVariant]
}

export const StatusFormatter = ({ variant = 'primary', ...rest }: StatusFormatterProps): ReactElement => (
  <Styled.StatusFormatterWrapper {...rest} variant={variant} />
)
