import { ModalContextProvider, withModalConditionalRender } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { ReactElement, useCallback } from 'react'
import { QueryClientProvider } from 'react-query'

import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import {
  EmberActivateIntegration,
  EmberDeactivateIntegration,
  EmberIntegrationContextProvider,
  EmberReloadPaymentMethods,
} from '@views/integrations/context/IntegrationContext'
import { EnableStripeModal as EnableStripe } from '@views/integrations/elements/StripeModalsGroup/elements/EnableStripeModal'

import { queryClient } from '../../config/queryClient'
import { EmberModal } from '../../contexts/emberCloseModalContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { ModalId } from '../../enums/modalId'

const MODULE_NAME = 'enable-stripe-modal-module'

class EnableStripeModalWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('closeEmber') closeEmber: Function
  @registerEvent('reloadPaymentMethods') reloadPaymentMethods: Function
  @registerEvent('openPaymentMethodEditor') openPaymentMethodEditor: Function
}

interface EnableStripeModalProps {
  organizationId: string
  onCloseEmber: EmberModal
  onActivateIntegration: EmberActivateIntegration
  onDeactivateIntegration: EmberDeactivateIntegration
  onReloadPaymentMethods: EmberReloadPaymentMethods
  onOpenPaymentMethodEditor: EmberModal
}
const EnableStripeModal = withModalConditionalRender(EnableStripe)

function EnableStripeModalModule({
  organizationId,
  onCloseEmber,
  onReloadPaymentMethods,
  onOpenPaymentMethodEditor,
}: EnableStripeModalProps): ReactElement {
  const closeModal = useCallback(() => {
    onCloseEmber({ detail: null })
  }, [onCloseEmber])

  const openPaymentMethodEditor = useCallback(() => {
    onOpenPaymentMethodEditor({ detail: null })
  }, [onOpenPaymentMethodEditor])

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ModalContextProvider>
          <EmberIntegrationContextProvider onReloadPaymentMethods={onReloadPaymentMethods}>
            <CurrentUserContextProvider>
              <AccountsContextProvider organizationId={organizationId}>
                <UserOrganizationContextProvider organizationId={organizationId}>
                  <EnableStripeModal
                    id={ModalId.EnableStripeModal}
                    forceRender
                    onModalClose={closeModal}
                    onActivationSuccess={openPaymentMethodEditor}
                  />
                </UserOrganizationContextProvider>
              </AccountsContextProvider>
            </CurrentUserContextProvider>
          </EmberIntegrationContextProvider>
        </ModalContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const EnableStripeModalCustomElement = createCustomElement(
  EnableStripeModalModule,
  EnableStripeModalWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, EnableStripeModalCustomElement)

export default EnableStripeModalCustomElement
