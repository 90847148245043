import { Spacing } from '../../../enums/spacing'
import { pxToNumber } from '../../../utils/pxToNumber'
import { RowWithCheckboxSpacingLeft, SubRowSpacing } from '../enums/styles'

export const getHeaderPadding = (selectable?: boolean, expandable?: boolean) => {
  if (selectable) {
    if (expandable) {
      return `padding: 0 ${Spacing.XL} 0 ${RowWithCheckboxSpacingLeft + SubRowSpacing}px;`
    }

    return `padding: 0 ${Spacing.XL} 0 ${RowWithCheckboxSpacingLeft}px;`
  }

  return expandable
    ? `padding: 0 ${Spacing.XL} 0 ${pxToNumber(Spacing.XL) + SubRowSpacing}px;`
    : `padding: 0 ${Spacing.XL};`
}
