import { createContext, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { getUserNotificationSettings } from './query-api'
import { OrganizationNotificationSettings } from './types'

interface ContextState {
  userNotificationSettings: OrganizationNotificationSettings[] | undefined
  isLoading: boolean
}

const defaultValue: ContextState = {
  userNotificationSettings: [],
  isLoading: true,
}

const UserNotificationSettingsContext = createContext(defaultValue)

interface UserNotificationSettingsContextProps {
  children?: ReactNode
}

export const UserNotificationSettingsContextProvider = ({ children }: UserNotificationSettingsContextProps) => {
  const { data: userNotificationData, isLoading } = useQuery(
    [QueryKeys.UserNotificationSettings],
    getUserNotificationSettings,
  )

  return (
    <UserNotificationSettingsContext.Provider
      value={{ isLoading, userNotificationSettings: userNotificationData?.data }}
    >
      {children}
    </UserNotificationSettingsContext.Provider>
  )
}

export const useUserNotificationSettings = () => useContext(UserNotificationSettingsContext)
