import { Color } from '@design-system'

import styled from '@emotion/styled'
import { Flex, FlexProps } from 'rebass'

type FileThumbnailProps = FlexProps

export const FileThumbnail = styled(Flex)<FileThumbnailProps>`
  width: 47px;
  height: 47px;
  padding: 3.5px;
  border: 1px solid ${Color.Gray40};
  background-color: ${Color.White};
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
`

FileThumbnail.defaultProps = {
  as: 'figure',
}
