import { useMemo } from 'react'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { Account } from '@modules-deprecated/app/accounts/types'

import { RECEIVABLE_ACCOUNT_SYSTEM_ROLE } from '../constants/receivableAccountProps'

interface UseReceivableAccountResponse {
  account: Account | null
  isLoading: boolean
}

export const useReceivableAccount = (): UseReceivableAccountResponse => {
  const { accounts = [], isLoading } = useAccounts()

  const account = useMemo(() => {
    return accounts.find((account) => account?.systemRole === RECEIVABLE_ACCOUNT_SYSTEM_ROLE) || null
  }, [accounts])

  return { account, isLoading }
}
