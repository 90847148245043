import { Translate } from '@components'
import {
  amountToDisplayValue,
  AmountValue,
  Color,
  DonutChart,
  DonutChartData,
  Link,
  SkeletonText,
  Text,
} from '@design-system'

import React, { useCallback, useMemo } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { useGlobalFiscalYear } from '../../../../contexts/globalFiscalYearContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { StatsContact } from '../../services/query-api'
import * as Styled from './styles'

interface UserData extends StatsContact, DonutChartData {}

export enum UsersListContext {
  Vendor = 'vendor',
  Customer = 'customer',
}

interface UsersListProps {
  isLoading?: boolean
  users: UserData[]
  usersListContext?: UsersListContext
}

const LOADING_DATA = [...new Array(3)]

export const UsersList = ({ isLoading, users, usersListContext }: UsersListProps) => {
  const { organization } = useUserOrganization()
  const { fiscalYear } = useGlobalFiscalYear()
  const { navigate } = useEmberRouter()
  const totalAmount = useMemo(() => users.reduce((accumulator, { value }) => accumulator + value, 0), [users])
  const data = useMemo(() => users.map((user) => ({ ...user, value: Math.abs(user.value) })), [users])

  const handleUserClick = useCallback(
    (contactId: string) => {
      if (usersListContext === UsersListContext.Vendor) {
        navigate(EmberRoute.ContactsSupplier, contactId, { fiscalYear })
      }
      if (usersListContext === UsersListContext.Customer) {
        navigate(EmberRoute.ContactsCustomer, contactId, { fiscalYear })
      }
    },
    [fiscalYear, navigate, usersListContext],
  )

  return (
    <Styled.Wrapper>
      <DonutChart data={data} isLoading={isLoading}>
        <Text alignment="center" colorVariant="secondary" variant="micro">
          <Translate value="total" />
        </Text>
        <AmountValue
          amount={totalAmount}
          currencyId={organization?.baseCurrencyId || ''}
          textVariant="h2"
          shortFormat
          alignCenter
        />
      </DonutChart>
      {isLoading ? (
        <Styled.List>
          {LOADING_DATA.map((_, index) => (
            <Styled.ListItem key={index} color={Color.Gray50}>
              <Styled.Label>
                <SkeletonText width={100} />
              </Styled.Label>
              <Styled.Amount>
                <SkeletonText width={40} />
              </Styled.Amount>
            </Styled.ListItem>
          ))}
        </Styled.List>
      ) : (
        <Styled.List>
          {users.map(({ color, contactId, amount, name }) => (
            <Styled.ListItem key={contactId} color={color}>
              <Styled.Label title={name} truncate>
                <Link onClick={() => handleUserClick(contactId)} withAnimation>
                  {name}
                </Link>
              </Styled.Label>
              <Styled.Amount>
                {amountToDisplayValue(amount, true)} {organization?.baseCurrencyId}
              </Styled.Amount>
            </Styled.ListItem>
          ))}
        </Styled.List>
      )}
    </Styled.Wrapper>
  )
}
