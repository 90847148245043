import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../enums/queryKeys'
import { APIError } from '../../../utils'
import { getSalesWithoutVatList } from '../services/api'

export interface SalesWithoutVatParams {
  fromDate: string
  onError?: (error?: APIError) => void
  onSuccess?: () => void
  toDate: string
}

export const useFetchSalesWithoutVat = (params: SalesWithoutVatParams) => {
  const { organization } = useUserOrganization()

  const query = useQuery(
    [
      QueryKeys.SalesWithoutVat,
      {
        organizationId: organization?.id,
        ...params,
      },
    ],
    () => getSalesWithoutVatList(organization?.id as string, params),
    {
      enabled: !!organization?.id,
      onError: params.onError,
      onSuccess: params.onSuccess,
    },
  )

  return {
    ...query,
    data: query?.data?.result || [],
  }
}
