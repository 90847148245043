import {
  addYears,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  getYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
} from 'date-fns'

interface FiscalYearOptions {
  firstFiscalYearStart?: Date
  firstFiscalYearEnd?: Date
}

interface DatePeriodResult {
  start: Date
  end: Date
}

interface FiscalYear {
  year: number
  startDate: Date
  endDate: Date
}

const INVALID_FISCAL_YEAR_FORMAT = 'Invalid fiscal year format'

export function getDatesFromPeriodText(periodText: string, options?: FiscalYearOptions): DatePeriodResult {
  const [period, value] = periodText.split(':', 2)
  let date: Date

  switch (period) {
    case 'month': {
      const [year, month] = value.split('-').map(Number)
      date = new Date(year, month - 1)
      return {
        start: startOfMonth(date),
        end: endOfMonth(date),
      }
    }

    case 'quarter': {
      const [year, quarter] = value.split('-').map(Number)
      date = new Date(year, (quarter - 1) * 3)
      return {
        start: startOfQuarter(date),
        end: endOfQuarter(date),
      }
    }

    case 'year': {
      const year = Number(value)
      date = new Date(year, 0)
      return {
        start: startOfYear(date),
        end: endOfYear(date),
      }
    }

    case 'fiscalyear': {
      const yearString = value.split(',').pop()

      if (!yearString) {
        throw new Error(INVALID_FISCAL_YEAR_FORMAT)
      }

      const year = Number(yearString)

      if (isNaN(year)) {
        throw new Error(INVALID_FISCAL_YEAR_FORMAT)
      }

      const firstFiscalYearStart = options?.firstFiscalYearStart ?? startOfYear(new Date(year, 0))
      const firstFiscalYearEnd = options?.firstFiscalYearEnd ?? endOfYear(new Date(year, 0))
      const fiscalYear = getFiscalYearForYear(year, firstFiscalYearStart, firstFiscalYearEnd)

      return {
        start: fiscalYear.start,
        end: fiscalYear.end,
      }
    }

    case 'dates': {
      const [startDateStr, endDateStr] = value.split('...')
      const [startYear, startMonth, startDay] = startDateStr.split('-').map(Number)
      const [endYear, endMonth, endDay] = endDateStr.split('-').map(Number)
      return {
        start: new Date(startYear, startMonth - 1, startDay),
        end: new Date(endYear, endMonth - 1, endDay),
      }
    }

    default:
      throw new Error('Unrecognized date period format: ' + periodText)
  }
}

function getNextFiscalYear(startDate: Date, endDate: Date): FiscalYear {
  const nextEndDate = addYears(endDate, 1)
  const nextStartDate = subDays(addYears(endDate, 1), 365)

  return {
    year: getYear(nextEndDate),
    startDate: nextStartDate,
    endDate: nextEndDate,
  }
}

function getFiscalYearForYear(year: number, firstStartDate: Date, firstEndDate: Date): DatePeriodResult {
  let fiscalYear: FiscalYear = {
    year: getYear(firstEndDate),
    startDate: startOfDay(firstStartDate),
    endDate: endOfDay(firstEndDate),
  }

  while (fiscalYear.year < year) {
    fiscalYear = getNextFiscalYear(fiscalYear.startDate, fiscalYear.endDate)
  }

  return {
    start: fiscalYear.startDate,
    end: fiscalYear.endDate,
  }
}
