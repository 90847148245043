import { Contact } from '@views/contacts/types/contact'

import { Paging } from '../../../../../types/metable'
import { Invoice } from '../../../types/invoice'
import { useFetchInvoices } from './useFetchInvoices'
import { useInvoicesListFilters } from './useInvoicesListFilters'

interface UseInvoicesResponse {
  invoices: Invoice[]
  contacts: Contact[]
  isLoading: boolean
  pagination?: Paging
}

export const useInvoices = (): UseInvoicesResponse => {
  const [{ contactId, page, pageSize, period, searchQuery, sendingState, sortDirection, sortProperty, status }] =
    useInvoicesListFilters()
  const { invoices, contacts, isLoading, pagination } = useFetchInvoices({
    contactId,
    page,
    pageSize,
    period,
    searchQuery,
    sendingState,
    sortDirection,
    sortProperty,
    status,
  })

  return {
    invoices,
    contacts,
    isLoading,
    pagination,
  }
}
