import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Color } from '../../../../enums/color'
import { Icon } from '../../../Icon'
import { Space } from '../../../Space'
import { Text } from '../../../Text'
import * as Styled from './styles'

interface DropzoneContentProps {
  blockedText?: ReactNode
  isBlocked?: boolean
  isUploading?: boolean
  uploadingText?: ReactNode
}

export const DropzoneContent = ({
  blockedText,
  isBlocked,
  isUploading,
  uploadingText,
}: DropzoneContentProps): ReactElement => {
  const { t } = useTranslation()

  if (isUploading) {
    return (
      <>
        <Styled.LoadingIconWrapper>
          <Icon icon="circleDashed" color={Color.White} size="large" />
        </Styled.LoadingIconWrapper>
        <Space size="m" />
        <Text variant="h1" color={Color.White} alignment="center">
          {uploadingText || t('ds.dropzone_full_screen.uploding_files')}
        </Text>
      </>
    )
  }

  if (isBlocked) {
    return (
      <Text variant="h1" color={Color.White} alignment="center">
        {blockedText || t('ds.dropzone.blocked')}
      </Text>
    )
  }

  return (
    <>
      <Text variant="h1" color={Color.White} alignment="center">
        {t('ds.dropzone_full_screen.drop_files')}
      </Text>
      <Space size="s" />
      <Text variant="large" color={Color.White} alignment="center">
        {t('ds.dropzone_full_screen.accepted_files')}
      </Text>
    </>
  )
}
