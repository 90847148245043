import { InternalAccessor, Space, Table, TableColumn, Text } from '@design-system'

import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { InvitedUmbrellaUser } from '@modules-deprecated/app/umbrellas/types/invitedUmbrellaUser'

import { formatDate } from '../../../../../../utils'
import { useInvitedUsers } from '../../hooks/useInvitedUsers'
import { InvitationsTableStaticActions } from './elements/InvitationsTableStaticActions/InvitationsTableStaticActions'

export const InvitationsTable = (): ReactElement => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { data: invitedUsers, isLoading } = useInvitedUsers()

  const invitationsTableData = useMemo(
    () => invitedUsers.map((user) => ({ ...user, [InternalAccessor.StaticActions]: true })),
    [invitedUsers],
  )

  const columns: TableColumn<InvitedUmbrellaUser>[] = useMemo(
    () => [
      {
        Header: t('umbrella_users.invitationTable.column.createdTime'),
        accessor: 'createdTime',
        Cell: ({ data, row }) => {
          const { createdTime } = data[row.id]
          return <Text>{formatDate(createdTime, 'MMM. dd yyyy')}</Text>
        },
      },
      {
        Header: t('umbrella_users.invitationTable.column.email'),
        accessor: 'email',
      },
      {
        Header: '',
        accessor: InternalAccessor.StaticActions,
        Cell: ({ row, data }) => {
          const { id } = data[row.id]
          return umbrella ? <InvitationsTableStaticActions invitationId={id} umbrellaId={umbrella.id} /> : <></>
        },
        sortable: false,
      },
    ],
    [t, umbrella],
  )

  return (
    <>
      <Text>{t('umbrella_users.invitationTable.intro')}</Text>
      <Space size="m" />
      <Table columns={columns} data={invitationsTableData} isLoading={isLoading} />
    </>
  )
}
