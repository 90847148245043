import { NavItem } from '@design-system'

export const revenueItems: NavItem<number[]>[] = [
  {
    children: '0-499.999',
    id: '0-499.999',
    value: [0, 499999],
  },
  {
    children: '500.000-999.999',
    id: '500.000-999.999',
    value: [500000, 999999],
  },
  {
    children: '1.000.000-1.499.999',
    id: '1.000.000-1.499.999',
    value: [1000000, 1499999],
  },
  {
    children: '1.500.000-1.999.999',
    id: '1.500.000-1.999.999',
    value: [1500000, 1999999],
  },
  {
    children: '2.000.000+',
    id: '2.000.000+',
    value: [2000000],
  },
]
