import {
  DateCheckbox,
  DateCheckboxControlledResult,
  DateInput,
  FinancialSummary,
  FormFilesLayout,
  FormFilesLayoutBox,
  Header,
  IconInfo,
  Input,
  NavigationButtons,
  PopOverHoverable,
} from '@components-deprecated'
import { Button, ButtonsGroup, notify } from '@design-system'

import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import { isFuture, isSameDay } from 'date-fns'
import { useTheme } from 'emotion-theming'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'
import { Box, Flex } from 'rebass'

import { updateContact } from '@views/contacts/query-api'
import { Contact } from '@views/contacts/types/contact'
import { getContactPrefilledDataFromSupplier } from '@views/contacts/utils/getContactPrefilledDataFromSupplier'
import { UmbrellaRoute } from '@views/umbrella/enums/UmbrellaRoute'

import { useCommonFormData } from '../../../contexts/commonFormDataContext'
import { NotificationKeys } from '../../../enums/notificationKeys'
import { QueryKeys } from '../../../enums/queryKeys'
import { QueryParamKeys } from '../../../enums/queryParamKeys'
import { Side } from '../../../enums/side'
import { TaxMode } from '../../../enums/taxMode'
import { useKeyDown } from '../../../hooks'
import { useQueryParamsStorage } from '../../../hooks/useQueryParamsStorage'
import { State } from '../../../types/reduxSaga-deprecated'
import { Theme } from '../../../types/theme'
import { formatDate } from '../../../utils'
import {
  AccountSelector,
  AccountSelectorControlledResult,
  accountsSelector,
  ContactSelector,
  ContactSelectorRefProps,
  CurrencyInput,
  CurrencyInputControlledResult,
  TaxRateSelector,
} from '../../app'
import { Account, AccountNature } from '../../app/accounts/types'
import { contactUpdated } from '../../app/contacts/action-creators'
import { useUserOrganization } from '../../app/organization'
import { taxRateSelector } from '../../app/taxrates/selectors/taxRateSelector'
import { getTaxRateById } from '../../app/taxrates/utils/getTaxRateById'
import { useCurrentUser } from '../../app/user'
import { ReconciliationSuggestionsBox } from '../../bankReconciliation/elements/ReconciliationSuggestionsBox'
import { fetchBankLines } from '../../bankReconciliation/query-api'
import { BankLine } from '../../bankReconciliation/types'
import { voucherActionRequest } from '../action-creators'
import {
  AccountSuggestions as AccountSuggestionsComponent,
  CurrentVoucherData,
  DuplicationModalBody,
  ValidationModal,
  ValidationModalType,
} from '../elements'
import { LineActions, LineWrapper, RemoveLineButton } from '../elements/common/LineElements'
import { VoucherActions, VoucherActionsRefProps } from '../elements/VoucherActions'
import { useBohrScanResult } from '../hooks/useBohrScanResult'
import { useVoucherActionState } from '../hooks/useVoucherActionState'
import { useVoucherNavigation } from '../hooks/useVoucherNavigation'
import { BillCreateSchema } from '../schemas/billCreate'
import { getBankAccountSelector } from '../selectors/getBankAccount'
import { getBills } from '../services/api'
import { fetchVoucher } from '../services/query-api'
import {
  Bill,
  CreateBillPayloadData,
  InboxRoutes,
  NavDirection,
  TaxRate,
  Voucher,
  VoucherAction,
  VoucherCommonData,
  VoucherPreviewFile,
} from '../types'
import { getVoucherInboxPath } from '../utils'
import { getVATSummaryFromLines, VATSummaryPart } from '../utils/getVATSummaryFromLines'
import { getVoucherBankLine } from '../utils/getVoucherBankLine'
import { getVoucherFiles } from '../utils/getVoucherFiles'
import { getVoucherPrefilledValues } from '../utils/getVoucherPrefilledValues'
import * as Styled from './styles'
import { GetInitialDataProps } from './types'

enum Action {
  Close = 'Close',
  NextVoucher = 'NextVoucher',
}

const Form = styled.form`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const SuggestionLabel = styled.span`
  display: flex;
  align-items: center;
  position: relative;
`

const SuggestionPopOver = styled(PopOverHoverable)`
  position: absolute;
  bottom: 100%;
  right: 0;
  white-space: nowrap;
`

const SuggestionInfoIcon = styled(IconInfo)`
  display: flex;
  margin-top: -2px;
  margin-left: 3px;
`

const AccountSuggestions = styled(AccountSuggestionsComponent)`
  margin-top: 20px;
`

type BillLine = {
  account?: AccountSelectorControlledResult
  amount: CurrencyInputControlledResult
  description?: string
  taxRate?: TaxRate
}

type FormInputs = {
  billDate: DateCheckboxControlledResult
  lines: BillLine[]
  paidFrom?: Account
  paymentDate?: Date
  vendor?: Contact
}

const defaultLine: BillLine = {
  amount: { value: 0, currency: 'DKK' },
}

const formDefaultValues: FormInputs = {
  billDate: { value: undefined, checked: false },
  paymentDate: undefined,
  lines: [defaultLine],
}

type DuplicationData = {
  current: CurrentVoucherData
  bills: Bill[]
}

type BillCreateProps = {
  customerView?: boolean
}

type QueryParams = {
  sort: string
}

const taxRateSelectorFn = (state: State) => taxRateSelector(state, {})
const accountsSelectorFn = (state: State) => accountsSelector(state, {})

export const BillCreate = ({ customerView }: BillCreateProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()
  const { commonFormData, dispatch: dispatchCommonFormData } =
    useCommonFormData<VoucherCommonData<AccountSelectorControlledResult>>()
  const theme = useTheme<Theme>()
  const form = useForm<FormInputs>({
    defaultValues: getInitialData({ commonData: commonFormData.value }),
    resolver: yupResolver(BillCreateSchema(t)),
  })
  const {
    fields: lines,
    append: addLine,
    remove: removeLine,
  } = useFieldArray({
    control: form.control,
    name: 'lines',
  })
  const queryClient = useQueryClient()
  const { voucherId } = useParams() as { voucherId: string }
  const [files, setFiles] = useState<VoucherPreviewFile[]>(commonFormData.value?.files || [])
  const [selectedAccountIdSuggestion, setSelectedAccountIdSuggestion] = useState<string>()
  const [validationModal, setValidationModal] = useState<ValidationModalType>()
  const [bankLineMatch, setBankLineMatch] = useState<BankLine>()
  const isVoucherFetchedRef = useRef(false)
  const isBohrDataFetchedRef = useRef(false)
  const isBankLineDataFetchedRef = useRef(false)
  const isPaymentDateChangedByUserRef = useRef(false)
  const contactSelectorRef = useRef<ContactSelectorRefProps>(null)
  const voucherActionsRef = useRef<VoucherActionsRefProps>(null)
  const duplicationDataRef = useRef<DuplicationData>()
  const { queryParams, setQueryParams } = useQueryParamsStorage<QueryParams>(QueryParamKeys.VoucherList)

  const bankAccount = useSelector(getBankAccountSelector)

  const organizationId = organization?.id || ''
  const firstFileId = files[0]?.id

  // Shortcuts

  const isSomeModalOpened = () => {
    const isSomeVoucherActionModalOpened = voucherActionsRef.current?.isSomeModalOpen()
    const isCreateContactModalOpen = contactSelectorRef.current?.isModalOpen()

    return isSomeVoucherActionModalOpened || isCreateContactModalOpen
  }

  useKeyDown(
    '+',
    () => {
      if (!isSomeModalOpened()) {
        addLine(defaultLine)
      }
    },
    { withShift: true },
  )

  useKeyDown(
    'Enter',
    () => {
      if (!isSomeModalOpened()) {
        onCreateAndGoNextClick()
      }
    },
    { withShift: true },
  )

  // Form state

  const billDateCurrent: DateCheckboxControlledResult = form.watch('billDate', formDefaultValues.billDate)
  const paymentDateCurrent: Date | undefined = form.watch('paymentDate')
  const accountId = form.watch('paidFrom', formDefaultValues.paidFrom)
  const billLines: BillLine[] = form.watch('lines') // @todo let's get rid of this as it decreases performance A LOT

  // Queries

  const {
    data: voucher,
    isLoading,
    isError: isVoucherFetchError,
    error: voucherFetchError,
  } = useQuery(
    [QueryKeys.Voucher, organizationId, voucherId],
    () =>
      fetchVoucher({
        queryKey: [QueryKeys.Voucher, { organizationId, voucherId }],
      }),
    { enabled: !!organization },
  )

  const bankLineAccountId = voucher?.bankAccountId || bankAccount?.id

  const { data: bankLinesData } = useQuery(
    [QueryKeys.BankLines, bankLineAccountId],
    () => fetchBankLines({ accountId: bankLineAccountId as string }),
    {
      enabled: !!bankLineAccountId,
      refetchOnMount: false,
    },
  )
  const { data: bohrData, isLoading: isBohrDataLoading } = useBohrScanResult(firstFileId)

  // Computed variables

  const vatSummary = getVATSummaryFromLines<BillLine>(billLines, t)
  const reconciliationAmount = vatSummary.find(({ id }) => id === VATSummaryPart.InclVAT)?.value || 0
  const reconciliationDate = billDateCurrent.checked ? paymentDateCurrent : billDateCurrent?.value
  const taxRates = useSelector(taxRateSelectorFn)
  const accounts = useSelector(accountsSelectorFn)
  const {
    prevVoucherId,
    nextVoucherId,
    isLoading: isNavigationLoading,
  } = useVoucherNavigation(organizationId, voucherId)
  const voucherBankLine = useMemo(
    () => getVoucherBankLine(voucher, bankLinesData?.bankLines),
    [voucher, bankLinesData?.bankLines],
  )

  // Action state

  const actionAfterSaveRef = useRef<Action>()
  const { processingActions } = useVoucherActionState({
    voucherId,
    actions: [VoucherAction.fetch, VoucherAction.createBill],
    onSuccess: (action) => {
      if (action === VoucherAction.createBill) {
        queryClient.invalidateQueries(QueryKeys.Voucher)
        notify({
          id: NotificationKeys.VoucherInboxBillCreate,
          message: t('voucher.inbox.toast.create_bill_success'),
          variant: 'success',
        })
        if (actionAfterSaveRef.current === Action.NextVoucher) {
          goToVoucher(NavDirection.Next)
        } else if (actionAfterSaveRef.current === Action.Close) {
          close()
        }
      }
    },
    onError: (action, error) => {
      console.error(error)

      if (action === VoucherAction.createBill) {
        notify({
          id: NotificationKeys.VoucherInboxBillCreate,
          message: t('voucher.inbox.toast.create_bill_failed'),
          variant: 'error',
        })
      }
    },
  })

  const isProcessing = processingActions[VoucherAction.createBill]
  const isDisabled = isProcessing || isLoading
  const areFieldsDisabled = isDisabled || !isBohrDataFetchedRef.current

  // Update form state when voucher is received

  useEffect(() => {
    const supplier = bohrData?.entities?.vendor?.content

    if (supplier) {
      const prefilledData = getContactPrefilledDataFromSupplier(supplier)

      if (!isEmpty(prefilledData)) {
        form.setValue('vendor', prefilledData as Contact)
      }
    }
    // `setValue` is the only value that we should check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bohrData, form.setValue])

  useEffect(() => {
    if (!voucher || isVoucherFetchedRef.current) {
      return
    }

    isVoucherFetchedRef.current = true
    isBankLineDataFetchedRef.current = !!voucherBankLine
    isBohrDataFetchedRef.current = !isBohrDataLoading

    const formData = getInitialData({
      bankLine: voucherBankLine,
      bohrData,
      commonData: commonFormData.value,
      voucher,
    })

    const voucherFiles = getVoucherFiles(voucher)

    form.reset(formData)
    setFiles(voucherFiles)

    if (commonFormData) {
      dispatchCommonFormData({ type: 'CLEAR' })
    }

    // Disable eslint rule because it should be only triggered on voucher data update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher])

  useEffect(() => {
    setBankLineMatch((prevBankLineMatch) => (!prevBankLineMatch ? voucherBankLine : prevBankLineMatch))
  }, [voucherBankLine])

  useUpdateEffect(() => {
    resetView()
    // Disable eslint rule because it should be only triggered on voucher id update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherId])

  const updateVouchersData = useCallback(() => {
    if (!voucher) {
      return
    }

    const { amount, date, description } = getVoucherPrefilledValues(bohrData, voucherBankLine) || {}

    if (date) {
      const billPaid = isVoucherInitiallyPaid(voucher)
      const billDate = { value: date, checked: billPaid }
      form.setValue('billDate', billDate)
      form.setValue('paymentDate', date)
    }

    for (let i = 0; i < lines.length; i++) {
      if (amount) {
        form.setValue(`lines.${i}.amount`, amount)
      }

      if (description) {
        form.setValue(`lines.${i}.description`, description)
      }
    }
  }, [bohrData, form, lines.length, voucher, voucherBankLine])

  useEffect(() => {
    if ((!bohrData && isBohrDataLoading) || isBohrDataFetchedRef.current) {
      return
    }

    isBohrDataFetchedRef.current = true
    updateVouchersData()
    // Trigger effect only when `bohrData` changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bohrData, isBohrDataLoading])

  useEffect(() => {
    if (isBankLineDataFetchedRef.current || !voucher || !voucherBankLine) {
      return
    }

    isBankLineDataFetchedRef.current = true
    updateVouchersData()
  }, [updateVouchersData, voucher, voucherBankLine])

  const getCommonFormData = (): VoucherCommonData => {
    const formValues = form.getValues()

    return {
      paidFrom: formValues.paidFrom,
      billDate: formValues.billDate.value,
      lines: formValues.lines,
      files,
    }
  }

  const close = () => {
    setQueryParams(queryParams, UmbrellaRoute.VoucherInbox)
  }

  const resetView = () => {
    isVoucherFetchedRef.current = false
    isBohrDataFetchedRef.current = false
    isBankLineDataFetchedRef.current = false
    form.clearErrors()
    form.reset()
  }

  // Handlers

  const navigateToVoucher = (voucherId: string) => {
    const urlPath = getVoucherInboxPath(voucherId, InboxRoutes.CreateBill)
    setQueryParams(queryParams, urlPath)
  }

  const goToVoucher = (direction: NavDirection) => {
    if (!voucher || isNavigationLoading) {
      return
    }

    resetView()

    const goToVoucherId = direction === NavDirection.Prev ? prevVoucherId : nextVoucherId

    if (goToVoucherId && goToVoucherId !== voucher.id) {
      navigateToVoucher(goToVoucherId)
      return
    }

    close()
  }

  const selectDefaultTaxRate = (lineIndex: number, account?: Account) => {
    if (!account?.taxRateId) {
      return
    }

    const accountTaxRate = getTaxRateById(taxRates, account.taxRateId)
    form.setValue(`lines.${lineIndex}.taxRate`, accountTaxRate)
  }

  const selectDefaultAccounts = (item: Contact) => {
    const { defaultExpenseAccountId, defaultTaxRateId } = item

    for (let i = 0; i < lines.length; i++) {
      let taxRateId: string | undefined

      // Select default tax rate
      if (defaultTaxRateId) {
        taxRateId = defaultTaxRateId
      } else if (defaultExpenseAccountId) {
        const account = accounts.find((account) => account.id === defaultExpenseAccountId)
        taxRateId = account?.taxRateId
      }

      if (taxRateId) {
        const taxRate = getTaxRateById(taxRates, taxRateId)
        form.setValue(`lines.${i}.taxRate`, taxRate)
      }

      // Select default account
      if (defaultExpenseAccountId) {
        form.setValue(`lines.${i}.account`, { id: defaultExpenseAccountId } as AccountSelectorControlledResult)
        setSelectedAccountIdSuggestion('')
      }
    }
  }

  const saveDefaultAccountForVendor = async (vendor: Contact, accountId: string) => {
    try {
      const vendorUpdated: Contact = { ...vendor, defaultExpenseAccountId: accountId }
      dispatch(contactUpdated(vendorUpdated))
      await updateContact(vendorUpdated)
    } catch (error) {
      console.error(`Couldn't update contact with id="${vendor.id}"`)
    }
  }

  const findDuplicates = async (data: FormInputs): Promise<Bill[] | undefined> => {
    try {
      const { bills = [] } = await getBills(organizationId, {
        minEntryDate: formatDate(data.billDate.value),
        maxEntryDate: formatDate(data.billDate.value),
        grossAmount: vatSummary[2].value,
        contactId: data.vendor?.id,
        currencyId: data.lines[0].amount.currency,
      })

      return bills.length ? bills : undefined
    } catch (e) {
      return undefined
    }
  }

  const isValidBankLineMatch = (data: FormInputs) => {
    if (!bankLineMatch) {
      return true
    }

    if (data.paymentDate && !isSameDay(new Date(bankLineMatch.entryDate), data.paymentDate)) {
      form.setError('paymentDate', { type: 'date', message: t('voucher.inbox.form.bank_line_date_error') })
      return false
    }

    if (reconciliationAmount - bankLineMatch.amount !== 0) {
      for (let i = 0; i < lines.length; i++) {
        form.setError(`lines.${i}.amount.value`, {
          type: 'amount',
          message: t('voucher.inbox.form.bank_line_amount_error'),
        })
      }
      return false
    }

    return true
  }

  const customValidate = async (data: FormInputs, validatorToSkip?: ValidationModalType): Promise<boolean> => {
    const billDate = data.billDate.value

    if (validatorToSkip !== ValidationModalType.FutureDate) {
      const isFutureBillDate = billDate && isFuture(billDate)

      if (isFutureBillDate) {
        setValidationModal(ValidationModalType.FutureDate)
        return false
      }
    }

    if (validatorToSkip !== ValidationModalType.DuplicatedBill) {
      const bills = await findDuplicates(data)

      if (bills) {
        duplicationDataRef.current = {
          bills,
          current: {
            contactId: data.vendor?.id || '',
            currencyId: data.lines[0].amount.currency,
            date: data.billDate?.value || new Date(),
            description: data.lines[0].description || '',
            grossAmount: vatSummary[2].value,
          },
        }
        setValidationModal(ValidationModalType.DuplicatedBill)
        return false
      }
    }

    if (!isValidBankLineMatch(data)) {
      return false
    }

    return true
  }

  const handleOnSubmit = async (data: FormInputs, validatorToSkip?: ValidationModalType) => {
    const hasPassedValidation = await customValidate(data, validatorToSkip)

    if (!hasPassedValidation) {
      return
    }

    const payload: CreateBillPayloadData = {
      contactId: data.vendor?.id,
      currencyCode: data.lines[0].amount.currency,
      entryDate: formatDate(data.billDate.value),
      taxMode: TaxMode.Including,
      lines: data.lines.map((line) => ({
        ...line,
        amount: parseFloat(line.amount.value.toString()),
        accountId: line.account?.id,
        taxRateId: line.taxRate?.id,
      })),
      userId: user?.id,
      ...(bankLineMatch?.matchId ? {} : { paymentAccountId: data.paidFrom?.id }),
      ...(bankLineMatch?.matchId ? {} : { paymentDate: formatDate(data.paymentDate) }),
    }

    if (data.vendor && data.lines.length === 1 && data.lines[0].account?.checked) {
      await saveDefaultAccountForVendor(data.vendor, data.lines[0].account.id)
    }

    dispatch(
      voucherActionRequest({
        organizationId,
        voucherId,
        action: VoucherAction.createBill,
        data: payload,
        ...(bankLineMatch?.matchId ? { bankLinesIds: [bankLineMatch.matchId] } : {}),
      }),
    )
  }

  const submit = (validatorToSkip?: ValidationModalType) => {
    form.handleSubmit((data: FormInputs) => handleOnSubmit(data, validatorToSkip))()
  }

  const onCreateClick = () => {
    actionAfterSaveRef.current = Action.Close
    submit()
  }

  const onCreateAndGoNextClick = () => {
    actionAfterSaveRef.current = Action.NextVoucher
    submit()
  }

  const handleVouchersAsJournal = () => {
    dispatchCommonFormData({ type: 'SET', payload: getCommonFormData() })
    const urlPath = getVoucherInboxPath(voucherId, InboxRoutes.CreateJournal)

    setQueryParams(queryParams, urlPath)
  }

  const getHeaderTitle = () => {
    let title = t('create_bill')

    if (organization?.name) {
      title += ` (${organization?.name})`
    }

    return title
  }

  const handleAccountSuggectionClick = (accountId: string) => {
    const account = accounts.find((account) => account.id === accountId)

    if (!account) {
      return
    }

    const taxRateId = account.taxRateId
    const taxRate = taxRateId ? getTaxRateById(taxRates, taxRateId) : undefined

    for (let i = 0; i < lines.length; i++) {
      form.setValue(`lines.${i}.account`, account as AccountSelectorControlledResult)

      if (taxRate) {
        form.setValue(`lines.${i}.taxRate`, taxRate)
      }

      setSelectedAccountIdSuggestion(accountId)
    }
  }

  const handleAccountSelect = (index: number, account: Account) => {
    selectDefaultTaxRate(index, account)
    setSelectedAccountIdSuggestion('')
  }

  const handleValidationModalConfirm = () => {
    submit(validationModal)
    setValidationModal(undefined)
    duplicationDataRef.current = undefined
  }

  const handleValidationModalCancel = () => {
    if (validationModal === ValidationModalType.FutureDate) {
      form.setError('billDate.value', { type: 'date', message: t('voucher.inbox.form.date_future_error') })
    }

    setValidationModal(undefined)
    duplicationDataRef.current = undefined
  }

  const handleBillDateChange = (data: DateCheckboxControlledResult) => {
    if (!isPaymentDateChangedByUserRef.current) {
      // wait until we are sure that the 'paymentDate' is actually visible (should be just set to visible)
      // so that's why we want to set it in the next frame
      window.requestAnimationFrame(() => {
        form.setValue('paymentDate', data.value)
      })
    }
  }

  const handlePaymentDateChange = () => {
    isPaymentDateChangedByUserRef.current = true
  }

  if (isVoucherFetchError) {
    console.error(voucherFetchError)
    notify({
      id: NotificationKeys.VoucherInboxFetch,
      message: t('voucher.inbox.toast.voucher_fetched_failed', { voucherId }),
      variant: 'error',
    })
    close()
  }

  const handleSuggestionSelected = (bankLine: BankLine | undefined) => {
    setBankLineMatch(bankLine)
    const currentPaidFrom = form.getValues('paidFrom') as Account | undefined

    if (bankLine?.entryDate) {
      form.setValue('paymentDate', new Date(bankLine.entryDate))
    }

    if (!currentPaidFrom?.id && bankLine?.accountId) {
      const bankLineAccount = accounts.find((account) => account.id === bankLine.accountId)

      if (bankLineAccount) {
        form.setValue('paidFrom', bankLineAccount)
      }
    }
  }

  return (
    <>
      <Header title={getHeaderTitle()}>
        <ButtonsGroup>
          <Button data-id={nextVoucherId} disabled={isDisabled} onClick={onCreateAndGoNextClick} variant="primary">
            {t('voucher.inbox.form.action.publish_and_go_next')}
          </Button>
          <Button disabled={isDisabled} onClick={onCreateClick} variant="secondary">
            {t('voucher.inbox.form.action.publish')}
          </Button>
          <Button disabled={isDisabled} onClick={handleVouchersAsJournal} variant="secondary">
            {t('voucher.inbox.form.handleVouchersAsJournal')}
          </Button>
          {voucher && (
            <VoucherActions
              ref={voucherActionsRef}
              customerView={customerView}
              disabled={isDisabled}
              nextVoucherId={nextVoucherId}
              voucher={voucher}
              onSuccess={resetView}
              queryParams={queryParams}
            />
          )}
        </ButtonsGroup>
      </Header>

      <Flex mb="15px" px="30px" pt="10px" justifyContent="space-between">
        <NavigationButtons
          idLeft={prevVoucherId}
          idRight={nextVoucherId}
          disableLeftAction={isNavigationLoading}
          disableRightAction={isNavigationLoading}
          onLeftClick={() => goToVoucher(NavDirection.Prev)}
          onRightClick={() => goToVoucher(NavDirection.Next)}
        />
      </Flex>

      <FormFilesLayout files={files}>
        <FormProvider {...form}>
          <Styled.FormWrapper>
            <Form>
              <FormFilesLayoutBox>
                <ContactSelector
                  allowCreateNew
                  disabled={areFieldsDisabled}
                  formControl={form.control}
                  isVendor
                  label={t('vendor')}
                  name="vendor"
                  onItemSelect={selectDefaultAccounts}
                  organizationId={organizationId}
                  placeholder={t('voucher.inbox.form.select_vendor')}
                  ref={contactSelectorRef}
                  withSelectEvent
                />
                <DateCheckbox
                  formControl={form.control}
                  name="billDate"
                  checkboxLabel={t('voucher.inbox.form.paid')}
                  dateInputLabel={t('voucher.inbox.form.bill_date')}
                  disabled={areFieldsDisabled}
                  onChange={handleBillDateChange}
                  withChangeEvent
                />

                {billDateCurrent?.checked && (
                  <>
                    <DateInput
                      formControl={form.control}
                      name="paymentDate"
                      label={t('voucher.inbox.form.payment_date')}
                      disabled={areFieldsDisabled}
                      onChange={handlePaymentDateChange}
                      withChangeEvent
                    />
                    <AccountSelector
                      formControl={form.control}
                      isPaymentEnabled
                      label={t('voucher.inbox.form.paid_from')}
                      name="paidFrom"
                      disabled={areFieldsDisabled}
                    />
                  </>
                )}
              </FormFilesLayoutBox>
              {!!bohrData && (
                <AccountSuggestions
                  bohrData={bohrData}
                  onClick={handleAccountSuggectionClick}
                  selectedId={selectedAccountIdSuggestion}
                />
              )}
              <ul>
                {lines.map((item, index) => (
                  <LineWrapper key={item.id}>
                    <FormFilesLayoutBox>
                      <Input
                        formControl={form.control}
                        label={t('voucher.inbox.form.description')}
                        name={`lines.${index}.description`}
                        errorPath={`lines.${index}.description`}
                        defaultValue={item.description}
                        disabled={areFieldsDisabled}
                        type="text"
                      />
                      <AccountSelector
                        formControl={form.control}
                        label={t('account')}
                        name={`lines.${index}.account`}
                        natures={[AccountNature.Expense, AccountNature.Asset]}
                        placeholder={t('voucher.inbox.form.select_account')}
                        onItemSelect={(account) => handleAccountSelect(index, account)}
                        disabled={areFieldsDisabled}
                        withCheckbox={lines.length === 1}
                        checkboxLabel={
                          <SuggestionLabel>
                            {t('save')}
                            <SuggestionPopOver text={t('voucher.inbox.account.set_as_default_contact')}>
                              <SuggestionInfoIcon width="14px" height="14px" />
                            </SuggestionPopOver>
                          </SuggestionLabel>
                        }
                        withSelectEvent
                      />
                      <CurrencyInput
                        formControl={form.control}
                        label={t('amount')}
                        name={`lines.${index}.amount`}
                        disabled={areFieldsDisabled}
                        defaultValue={item.amount}
                        mb={0}
                      />
                      <TaxRateSelector
                        formControl={form.control}
                        label={t('voucher.inbox.form.vat_rate')}
                        name={`lines.${index}.taxRate`}
                        organizationId={organizationId}
                        placeholder={t('voucher.inbox.form.vat_rate_placeholder')}
                        disabled={areFieldsDisabled}
                        purchase={true}
                        mb={0}
                      />
                    </FormFilesLayoutBox>
                    <LineActions>
                      {lines.length > 1 && !areFieldsDisabled && (
                        <RemoveLineButton onClick={() => removeLine(index)} color={theme.colors.black} />
                      )}
                    </LineActions>
                  </LineWrapper>
                ))}
              </ul>

              {!areFieldsDisabled && (
                <div>
                  <Button icon="plusCircle" variant="text" onClick={() => addLine(defaultLine)}>
                    {t('voucher.inbox.form.add_line')}
                  </Button>
                </div>
              )}
              <Box mt="20px">
                <FinancialSummary items={vatSummary} placement="right" />
              </Box>
              <ReconciliationSuggestionsBox
                accountId={accountId?.id || bankAccount?.id}
                amount={reconciliationAmount}
                date={reconciliationDate}
                matchId={bankLineMatch?.matchId}
                onSuggestionsSelected={handleSuggestionSelected}
                suggestionsSide={Side.Credit}
              />
            </Form>
          </Styled.FormWrapper>
        </FormProvider>
      </FormFilesLayout>

      <ValidationModal
        isOpen={!!validationModal}
        type={validationModal}
        onCancel={handleValidationModalCancel}
        onConfirm={handleValidationModalConfirm}
      >
        {validationModal === ValidationModalType.DuplicatedBill && duplicationDataRef.current && organization?.url ? (
          <DuplicationModalBody
            organizationUrl={organization?.url}
            current={duplicationDataRef.current.current}
            duplicates={duplicationDataRef.current.bills}
          />
        ) : null}
      </ValidationModal>
    </>
  )
}

function isVoucherInitiallyPaid(voucher: Voucher, accounts?: Account[]): boolean {
  const paidFromAccount = accounts?.find((account) => account.id === voucher.bankAccountId)
  return !paidFromAccount?.isBankAccount
}

function getInitialData({
  bankLine,
  bohrData,
  commonData = {},
  voucher,
}: GetInitialDataProps<FormInputs, AccountSelectorControlledResult>): FormInputs {
  const initialData: FormInputs = { ...formDefaultValues }

  if (voucher) {
    const {
      amount: amountPrefilled,
      date: datePrefilled,
      description: descriptionPrefilled,
    } = getVoucherPrefilledValues(bohrData, bankLine) || {}
    const billDateValue =
      datePrefilled || (voucher.createdTimestamp ? new Date(voucher.createdTimestamp) : initialData.billDate.value)
    const paidFrom = { id: voucher.bankAccountId || '' } as Account
    const isPaid = isVoucherInitiallyPaid(voucher)
    const description = descriptionPrefilled || voucher.description || ''

    initialData.paidFrom = paidFrom
    initialData.billDate = { value: billDateValue, checked: isPaid }
    initialData.paymentDate = isPaid ? billDateValue : undefined
    initialData.lines = [
      {
        ...defaultLine,
        description,
        ...(amountPrefilled ? { amount: amountPrefilled } : {}),
      },
    ]
  }

  // Common data is data from Handle As Journal screen
  if (commonData.billDate) {
    initialData.billDate = { value: commonData.billDate, checked: true }
    initialData.paymentDate = commonData.billDate
  }

  if (commonData.paidFrom) {
    initialData.paidFrom = commonData.paidFrom
  }

  if (commonData.lines) {
    initialData.lines = commonData.lines
  }

  return initialData
}
