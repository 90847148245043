import React from 'react'
import { Box } from 'rebass'

import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconCloseV2 = ({ color = '#FF7770', width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-cross')} {...rest}>
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.00009 17C3.74381 17 3.48878 16.9025 3.29254 16.7075C2.90249 16.3174 2.90249 15.6836 3.29254 15.2936L15.2936 3.29254C15.6836 2.90249 16.3174 2.90249 16.7075 3.29254C17.0975 3.68259 17.0975 4.31637 16.7075 4.70642L4.70642 16.7075C4.5114 16.9025 4.2564 17 4.00012 17H4.00009Z"
        fill={color}
      />
      <path
        d="M16.0011 17C15.7449 17 15.4898 16.9025 15.2936 16.7075L3.29254 4.70642C2.90249 4.31637 2.90249 3.68259 3.29254 3.29254C3.68259 2.90249 4.31637 2.90249 4.70642 3.29254L16.7075 15.2936C17.0975 15.6836 17.0975 16.3174 16.7075 16.7075C16.5124 16.9025 16.2574 17 16.0012 17H16.0011Z"
        fill={color}
      />
    </svg>
  </Box>
)
