import { Button } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Scope } from '../../../../../../enums/scope'
import { isAuthorized } from '../../../../../../utils/isAuthorized'
import { useTransactionsEmberConnection } from '../../../../contexts/transactionsEmberConnectionContext'

export const TransactionsSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { handleAddTransaction } = useTransactionsEmberConnection()

  const shouldHideAddTransaction = !isAuthorized(Scope.TransactionWrite, false, true)

  if (shouldHideAddTransaction) {
    return <></>
  }

  return (
    <Button icon="plusCircle" onClick={handleAddTransaction}>
      {t('transactions.side_actions.new')}
    </Button>
  )
}
