import { ButtonsGroup } from '@design-system'

import React, { ReactElement } from 'react'

import { ExportAccountStatement } from './ExportAccountStatement'

export const AccountStatementsSideActions = (): ReactElement => {
  return (
    <ButtonsGroup>
      <ExportAccountStatement />
    </ButtonsGroup>
  )
}
