import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { getRegisteredForEInvoicing } from '@views/invoices/query-api'

import { QueryKeys } from '../../../../../../../enums/queryKeys'

export const useIsRegisteredForEInvoicing = () => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const queryParams = useMemo(() => ({ organizationId: organization?.id }), [organization?.id])
  const isQueryEnabled = !isOrganizationLoading && !!organization?.id

  const { data, isLoading } = useQuery(
    [QueryKeys.RegisteredForEInvoicing, queryParams],
    () => getRegisteredForEInvoicing(queryParams),
    {
      enabled: isQueryEnabled,
    },
  )

  const { data: registeredForEInvoicingData } = data || {}
  const isRegistered = (registeredForEInvoicingData || []).length > 0

  return useMemo(
    () => ({
      isRegistered,
      isLoading,
    }),
    [isLoading, isRegistered],
  )
}
