import sortBy from 'lodash/sortBy'

import { AttachmentFile } from '../types/attachmentFile'

type Size = 'small' | 'medium' | 'large'

export const getFileThumbnail = (file: AttachmentFile, size: Size = 'large'): string => {
  const variants = file?.variants ? sortBy(file.variants, 'imageWidth') : []
  let downloadUrl = ''

  if (!variants.length) {
    downloadUrl = file.downloadUrl
  } else if (size === 'small') {
    downloadUrl = variants[0]?.downloadUrl
  } else if (size === 'medium') {
    downloadUrl = variants[Math.round(variants.length / 2)]?.downloadUrl
  } else if (size === 'large') {
    downloadUrl = variants[variants.length - 1]?.downloadUrl
  }

  return downloadUrl || file.downloadUrl
}
