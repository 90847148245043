import React, { ChangeEvent, ReactElement } from 'react'

import { ReceiptsLeftActions } from './elements/ReceiptsLeftActions'
import { ReceiptsRightActions } from './elements/ReceiptsRightActions'
import * as Styled from './styles'

interface ReceiptsActionsProps {
  areAllReceiptsSelected: boolean
  onSelectAllActionChange: (event: ChangeEvent<HTMLInputElement>) => void
  selectedReceiptsCount: number
}

export const ReceiptsActions = ({
  areAllReceiptsSelected,
  onSelectAllActionChange,
  selectedReceiptsCount,
}: ReceiptsActionsProps): ReactElement => (
  <Styled.ReceiptsActionsWrapper>
    <ReceiptsLeftActions
      areAllReceiptsSelected={areAllReceiptsSelected}
      onSelectAllActionChange={onSelectAllActionChange}
      selectedReceiptsCount={selectedReceiptsCount}
    />
    <ReceiptsRightActions />
  </Styled.ReceiptsActionsWrapper>
)
