import { css } from '@emotion/core'

import { Color } from '../../../enums/color'
import { DefaultValue } from '../../../enums/defaultValue'
import { FontSize } from '../../../enums/fontSize'
import { LineHeight } from '../../../enums/lineHeight'
import { Theme } from '../../../types/theme'
import { getDefaultTransition } from '../../../utils/getDefaultTransition'
import { getTextColor } from './getTextColor'

export const getInputStyle = (theme: Theme, color?: Color) => css`
  appearance: none;
  outline: none;
  width: 100%;
  border: none;
  padding: 0;
  color: ${color || getTextColor(theme).primary};
  font-size: ${FontSize.TextNormal};
  font-family: ${DefaultValue.FontFamily};
  line-height: ${LineHeight.TextNormal};
  cursor: inherit;
  ${getDefaultTransition('color')};

  &:focus-visible {
    box-shadow: none;
  }

  &[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
    }
  }
`
