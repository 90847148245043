import React from 'react'

import { IconProps } from '../Icon'

export const IconAttention = ({
  color = 'currentColor',
  color2,
  width = '16px',
  height = '16px',
  ...rest
}: IconProps) => (
  <i {...rest}>
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7238 5.93089C15.1742 3.88291 13.834 2.13689 11.9978 1.07652C10.1615 0.0161524 7.97933 -0.271816 5.93084 0.275894C3.88263 0.825483 2.13643 2.16583 1.07604 4.00234C0.0156455 5.83886 -0.272174 8.02126 0.275838 10.0699C0.731607 11.7681 1.73382 13.269 3.12769 14.3408C4.52156 15.4126 6.22954 15.9956 7.98784 15.9999C8.69086 15.9994 9.39079 15.9069 10.0698 15.7249C12.1176 15.1751 13.8634 13.8348 14.9236 11.9985C15.9838 10.1623 16.2716 7.98025 15.7238 5.93189V5.93089ZM13.7378 11.3119C13.1932 12.2558 12.4242 13.0508 11.4988 13.6265C10.5735 14.2022 9.5205 14.5407 8.43307 14.6122C7.34564 14.6836 6.25737 14.4857 5.26469 14.0361C4.27201 13.5864 3.40556 12.8989 2.74211 12.0343C2.07865 11.1698 1.63868 10.1549 1.46117 9.07971C1.28366 8.00449 1.37409 6.90208 1.72445 5.87016C2.07481 4.83825 2.67429 3.90867 3.46976 3.1638C4.26523 2.41894 5.23215 1.88177 6.28484 1.59989C6.84769 1.44845 7.42797 1.37146 8.01084 1.37089C9.17304 1.37328 10.3142 1.68121 11.3198 2.26382C12.3255 2.84643 13.1602 3.68322 13.7404 4.69027C14.3205 5.69732 14.6257 6.83921 14.6253 8.00142C14.6248 9.16363 14.3188 10.3053 13.7378 11.3119Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99997 11.6797C8.39761 11.6797 8.71997 11.3574 8.71997 10.9597C8.71997 10.5621 8.39761 10.2397 7.99997 10.2397C7.60232 10.2397 7.27997 10.5621 7.27997 10.9597C7.27997 11.3574 7.60232 11.6797 7.99997 11.6797Z"
        fill={color}
        stroke={color}
      />
      <path d="M8 5.11963V7.99963" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </i>
)
