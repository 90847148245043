import { Attachment, Modal, ModalProps, SkeletonBox, useDetectScrollEndPosition, useModal } from '@design-system'

// @todo this component should be moved back to @design-system once dependencies to @components-deprecated are cut
import { ReactElement, ReactNode, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalEmpty } from './elements/ModalEmpty'
import { ModalFileItem } from './elements/ModalFileItem'
import { ModalFilePreview } from './elements/ModalFilePreview'
import * as Styled from './styles'

export type ModalFileChooserProps = Omit<ModalProps, 'closable' | 'children' | 'width'> & {
  emptyDescription?: ReactNode
  files: Attachment[]
  isLoading?: boolean
  onClose?: () => void
  onOpen?: () => void
  onScrollEnd?: () => void
  onSelect?: (file: Attachment) => void
  title?: ReactNode
}

const LOADING_SKELETON_COUNT = 10

export const ModalFilesChooser = ({
  emptyDescription,
  files,
  id,
  isLoading,
  onClose,
  onOpen,
  onScrollEnd,
  onSelect,
  title,
  ...rest
}: ModalFileChooserProps): ReactElement => {
  const { t } = useTranslation()
  const [previewReceipt, setPreviewReceipt] = useState<Attachment>()
  const filesContainerRef = useRef<HTMLUListElement>(null)

  const closeModal = useCallback(() => {
    setPreviewReceipt(undefined)
    onClose?.()
  }, [onClose])

  const { close } = useModal(id, { onClose: closeModal, onOpen })

  const handleFileSelect = useCallback(
    (file: Attachment) => {
      onSelect?.(file)
      close()
    },
    [close, onSelect],
  )

  const handleFileBlur = useCallback(() => {
    setPreviewReceipt(undefined)
  }, [])

  const handleFileHover = useCallback((item: Attachment) => {
    setPreviewReceipt(item)
  }, [])

  const handleScrollEnd = useCallback(() => {
    onScrollEnd?.()
  }, [onScrollEnd])

  useDetectScrollEndPosition({
    scrollableElement: filesContainerRef.current !== null ? filesContainerRef.current : undefined,
    onScrollEnd: handleScrollEnd,
    enabled: !isLoading,
    scrollEndOffset: 500,
  })

  return (
    <Modal closable id={id} {...rest}>
      <Modal.Header title={title || t('ds.modal_files_chooser.default.title')} />
      <Modal.Body>
        <Styled.BodyLayout>
          {!isLoading && files.length === 0 && <ModalEmpty description={emptyDescription} />}

          <Styled.FilesContainerWrapper ref={filesContainerRef}>
            <Styled.Container>
              <Styled.FilesContainer>
                {files?.map((attachment: Attachment) => (
                  <ModalFileItem
                    item={attachment}
                    key={attachment.id}
                    onFileBlur={handleFileBlur}
                    onFileHover={handleFileHover}
                    onFileSelect={handleFileSelect}
                  />
                ))}
                {isLoading &&
                  [...Array(LOADING_SKELETON_COUNT).keys()].map((index) => {
                    return <SkeletonBox key={index} height={128} fullWidth />
                  })}
              </Styled.FilesContainer>
            </Styled.Container>
          </Styled.FilesContainerWrapper>
          <Styled.Container>
            {!isLoading && !!files.length && <ModalFilePreview previewAttachment={previewReceipt} />}
          </Styled.Container>
        </Styled.BodyLayout>
      </Modal.Body>
    </Modal>
  )
}
