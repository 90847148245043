import { Flex, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const PurchaseCardPriceSectionWrapper = styled(Flex)`
  margin-top: ${Spacing.XL};
`

export const PricePeriodWrapper = styled.div`
  margin-bottom: ${Spacing.XL};
`

export const PriceWrapper = styled.div`
  width: 100%;
  height: 50px;
`
