import { Checkbox, FormItemsGroup, Input, Spacing, Text, Textarea } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../hooks'
import { SendEmailInvoiceFormSchema } from '../../../../utils/emailInvoiceFormData'
import { ContactPersonSelectFormItem } from '../ContactPersonSelectFormItem'

interface SendEmailInvoiceFormProps {
  contactId: string
}

export const SendEmailInvoiceForm = ({ contactId }: SendEmailInvoiceFormProps): ReactElement => {
  const { t } = useTranslation()

  const { FormItem } = useFormContext<SendEmailInvoiceFormSchema>()

  return (
    <FormItemsGroup rowSpacing={Spacing.XL}>
      <ContactPersonSelectFormItem contactId={contactId} />

      <FormItem
        label={t('modal.send_invoice.form.subject.label')}
        name="subject"
        render={({ field }) => <Input {...field} />}
      />

      <FormItem
        label={t('modal.send_invoice.form.message.label')}
        name="message"
        render={({ field }) => <Textarea rows={6} {...field} />}
      />

      <FormItem
        name="requestCopy"
        extra={<Text>{t('modal.send_invoice.form.note')}</Text>}
        render={({ field: { value, onChange } }) => (
          <Checkbox checked={value} onChange={onChange}>
            {t('modal.send_invoice.form.request_copy')}
          </Checkbox>
        )}
      />
    </FormItemsGroup>
  )
}
