import { IconCaretDown, Link, LoadingPlaceholder } from '@components-deprecated'

import { css } from '@emotion/core'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'rebass'

import { QueryParamKeys } from '../../../enums/queryParamKeys'
import { useQueryParamsStorage } from '../../../hooks/useQueryParamsStorage'
import { SpecificState } from '../../../types/reduxSaga-deprecated'
import { reactClass } from '../../../utils'
import { filtersChanged } from '../action-creators'
import { BankReconcileQueryParams, BankReconcileSortProps } from '../types'
import { DroppableTransactions } from './DroppableTransactions'

export const TransactionList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { setQueryParam } = useQueryParamsStorage<BankReconcileQueryParams>(QueryParamKeys.TransactionList)

  const isBalanceBarOpen = useSelector((state: SpecificState) => state.bankReconciliation.isBalanceBarOpen)
  const billyTransactions = useSelector((state: SpecificState) => state.bankReconciliation.billyTransactions)
  const transactionsLoading = useSelector((state: SpecificState) => state.bankReconciliation.transactionsLoading)
  const transactionsSortProperty = useSelector(
    (state: SpecificState) => state.bankReconciliation.filters.transactionsSortProperty,
  )
  const transactionsSortDirection = useSelector(
    (state: SpecificState) => state.bankReconciliation.filters.transactionsSortDirection,
  )

  const handleSort = useCallback(
    (title: BankReconcileSortProps) => {
      const direction = transactionsSortProperty === title ? transactionsSortDirection * -1 : 1

      setQueryParam('transactionsSortProperty', title)
      setQueryParam('transactionsSortDirection', direction)

      dispatch(
        filtersChanged({
          transactionsSortProperty: title,
          transactionsSortDirection: direction,
        }),
      )
    },
    [dispatch, setQueryParam, transactionsSortProperty, transactionsSortDirection],
  )

  const placeholderStyle = {
    borderRadius: '4px',
    color1: '#F5FAFF',
    color2: '#DEEFFF',
    fullWidth: true,
    lineHeight: '24px',
    lines: 5,
    spacing: '18px',
  }

  return (
    <Box className={reactClass('transaction-list-container')} m="0 -10px 10px">
      <Flex
        className={reactClass('list-heading')}
        fontSize="14px"
        p="10px 20px"
        backgroundColor={'white'}
        css={css`
          > a {
            border-bottom: none !important;
            text-decoration: none !important;
          }
        `}
      >
        <Box width="120px">
          <Link onClick={() => handleSort('entryDate')}>
            {t('bankreconciliation.matchgroups.billdate')}
            {transactionsSortProperty === 'entryDate' && (
              <IconCaretDown
                ml="4px"
                width={12}
                height={12}
                rotate={transactionsSortDirection === -1}
                color="primary"
              />
            )}
          </Link>
        </Box>
        <Link
          disabled
          css={css`
            flex: 1;
          `}
        >
          {t('bankreconciliation.matchgroups.description')}
        </Link>

        <Box
          width="85px"
          css={css`
            text-align: right;
          `}
        >
          <Link onClick={() => handleSort('sideAmount')}>
            {t('bankreconciliation.matchgroups.amount')}
            {transactionsSortProperty === 'sideAmount' && (
              <IconCaretDown
                ml="4px"
                width={12}
                height={12}
                rotate={transactionsSortDirection === -1}
                color="primary"
              />
            )}
          </Link>
        </Box>
      </Flex>
      <Box
        className={reactClass('list-scrollable')}
        css={css`
          overflow-x: hidden;
          overflow-y: auto;
          max-height: calc(100vh - ${isBalanceBarOpen ? 394 : 320}px);
          padding: 0px 10px 30px;
          *[data-rbd-draggable-context-id] {
            margin-bottom: 10px;
          }
        `}
      >
        {transactionsLoading ? (
          <LoadingPlaceholder {...placeholderStyle} />
        ) : (
          <DroppableTransactions
            pb="0"
            collection={billyTransactions}
            id="billytransaction:source"
            type="billytransaction"
            highlightOnDraggingOver
          />
        )}
      </Box>
    </Box>
  )
}
