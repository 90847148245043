import styled from '@emotion/styled'

import { ReactComponent as CreateFirstInvoiceIllustration } from '../../../../../../assets/dashboard-create-first-invoice.svg'

export const WidgetContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
`

export const Header = styled.div``

export const Footer = styled.div``

export const DescriptionWrapper = styled.div`
  width: 65%;
`

export const Illustration = styled(CreateFirstInvoiceIllustration)`
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: -1;
`
