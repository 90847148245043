import { Spacing, Text as TextComponent } from '@design-system'

import styled from '@emotion/styled'

export const LanguageSwitcherWrapper = styled.div``

export const Title = styled(TextComponent)`
  margin-right: ${Spacing.S};
`

export const IconWrapper = styled.figure`
  display: flex;
`
