import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconIntegrations = ({
  color = '#41C447',
  color2 = '#fff',
  width = '20px',
  height = '20px',
  isOutlined,
  bg = '#ffffff',
  ...rest
}: IconProps) => (
  <Box as="i" className={reactClass('icon-bills')} {...rest}>
    <svg width={width} height={height} x="0px" y="0px" viewBox="0 0 287.82 344.08">
      <path
        d="M231.4,94a43.73,43.73,0,0,1-3.74,19.36,6.4,6.4,0,0,0,5.79,8.94l22.8.07a61.65,61.65,0,0,1,61.38,61.82l-.08,22.66a3.23,3.23,0,0,1-5.13,2.64,43.64,43.64,0,0,0-27.13-7.92,43.94,43.94,0,1,0,26.77,80.07,3.23,3.23,0,0,1,5.13,2.64l-.08,23a61.63,61.63,0,0,1-61.81,61.38l-137.12-.44a61.63,61.63,0,0,1-61.37-61.82l.44-123.18a61.63,61.63,0,0,1,61.81-61.38l22.07.07a6.35,6.35,0,0,0,5.87-8.87A44,44,0,1,1,231.4,94Z"
        transform="translate(-43.31 -38.09)"
        fill={isOutlined ? 'transparent' : coloring(color)}
        stroke={coloring(color2)}
        strokeMiterlimit="10"
        strokeWidth="27"
      />
    </svg>
  </Box>
)
