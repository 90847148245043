import styled from '@emotion/styled'

import { Spacing } from '../../../../enums/spacing'

export const SidePanelBodyWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: ${Spacing.M} ${Spacing.XXL} ${Spacing.XL};
  flex-direction: column;
  overflow: auto;
  gap: ${Spacing.L};
`
