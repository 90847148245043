import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { VoucherListResponse } from '@modules-deprecated/inbox/services/query-api'

import { QueryKeys } from '../../../enums/queryKeys'
import { getVouchersListQueryArrayKey } from '../utils/getVouchersListQueryArrayKey'

const DEFAULT_VOUCHERS_LIST_DATA: VoucherListResponse = {
  vouchers: [],
  pagination: { total: 0, limit: 0, offset: 0 },
}

export const useOptimisticVoucherListUpdate = (organizationId: string, voucherId: string) => {
  const client = useQueryClient()
  const vouchersListKey = getVouchersListQueryArrayKey(organizationId)

  const previousVouchersListData = useMemo(
    () => client.getQueryData<VoucherListResponse>(vouchersListKey) || DEFAULT_VOUCHERS_LIST_DATA,
    [client, vouchersListKey],
  )

  const updateVouchersList = useCallback(async () => {
    await client.cancelQueries(QueryKeys.VouchersList)
    client.setQueryData(vouchersListKey, {
      vouchers: previousVouchersListData.vouchers.filter(({ id }) => id !== voucherId),
      pagination: {
        ...previousVouchersListData.pagination,
        total: previousVouchersListData.pagination.total - 1,
      },
    })
  }, [client, previousVouchersListData, voucherId, vouchersListKey])

  const invalidateVouchersList = useCallback(() => {
    client.invalidateQueries(vouchersListKey)
  }, [client, vouchersListKey])

  return {
    invalidateVouchersList,
    updateVouchersList,
  }
}
