import { Translate } from '@components'
import { Button } from '@design-system'

import React, { ReactElement } from 'react'
import ReactDatePicker from 'react-datepicker'

import { TaskState } from '../../enums/taskState'

export interface TaskItemSideButtonProps {
  taskState?: TaskState
  onApprove: () => void
  onDeadlineChange: (deadline: Date) => void
}

export const TaskItemSideButton = ({
  taskState,
  onApprove,
  onDeadlineChange,
}: TaskItemSideButtonProps): ReactElement | null => {
  if (taskState !== TaskState.Completed && !!taskState) {
    return null
  }

  if (!taskState) {
    return (
      <ReactDatePicker
        onChange={(date: Date) => onDeadlineChange(date)}
        portalId="root-test"
        popperPlacement="bottom-end"
        customInput={
          <Button icon="plusCircle" size="m" variant="text">
            <Translate value="dashboard.widget.tasks.add.date" />
          </Button>
        }
      />
    )
  }

  return (
    <Button icon="checkCircle" onClick={onApprove} size="m" variant="text">
      <Translate value="dashboard.widget.tasks.approve_task" />
    </Button>
  )
}
