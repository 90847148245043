import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

import { useOrganizationSubscription } from '../../../../../../../hooks'
import { freePlans } from '../../../plansData'
import { usePaidPlansToShow } from './usePaidPlansToShow'

export const useShouldDisplayPlan = () => {
  const { subscriptionPlan, isTrial, isLocked } = useOrganizationSubscription()
  const isLockedOrTrial = isLocked || isTrial

  const paidPlansToShow = usePaidPlansToShow(isLockedOrTrial, subscriptionPlan)
  const shouldDisplayBasicPlan = paidPlansToShow.includes(SubscriptionPlan.BasicPaid)
  const shouldDisplayPlusPlan = paidPlansToShow.includes(SubscriptionPlan.Plus)
  const shouldDisplayCompletePlan = paidPlansToShow.includes(SubscriptionPlan.Complete)
  const shouldDisplayFreePlan = isLockedOrTrial || (subscriptionPlan && freePlans.includes(subscriptionPlan))

  return { shouldDisplayFreePlan, shouldDisplayBasicPlan, shouldDisplayPlusPlan, shouldDisplayCompletePlan }
}
