import { notify } from '@design-system'

import { TFunction } from 'i18next'

import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { PaymentRegistrationSuccessState } from '../enums/paymentRegistrationSuccessState'

export const notifyBillReconciliationSuccess = (paymentSuccessState: PaymentRegistrationSuccessState, t: TFunction) => {
  const paymentSuccessStateToTranslationKey = {
    [PaymentRegistrationSuccessState.Registered]: 'bill.payment_reconcile.payment_is_registered',
    [PaymentRegistrationSuccessState.RegisteredAndReconciled]:
      'bill.payment_reconcile.payment_is_registered_and_reconciled',
    [PaymentRegistrationSuccessState.RegisteredAndReconciledWithPrepayment]:
      'bill.payment_reconcile.payment_is_registered_and_reconciled_with_prepayment',
  }

  return notify({
    id: NotificationKeys.BillReconciliationSuccess,
    message: t(paymentSuccessStateToTranslationKey[paymentSuccessState]),
    variant: 'success',
    icon: 'checkCircle',
  })
}
