import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { Variant } from '../types'

const getLoadingStylesForVariant = (variant: Variant, danger?: boolean, success?: boolean): SerializedStyles =>
  ({
    primary: css`
      background-color: ${Color.DayGrey};
      box-shadow: none;
      color: ${Color.Gray90};

      ${danger &&
      css`
        background-color: ${Color.Red10};
        color: ${Color.Red};
      `};
      ${success &&
      css`
        background-color: ${Color.Green10};
        color: ${Color.Gray90};
      `};
    `,
    secondary: css`
      background-color: ${Color.DayGrey};
      box-shadow: none;
      color: ${Color.Gray90};

      ${danger &&
      css`
        background-color: ${Color.Red10};
        color: ${Color.Red};
      `};
    `,
    text: css`
      color: ${Color.Gray60};

      ${danger &&
      css`
        color: ${Color.Red};
      `};
    `,
  })[variant]

export const getLoadingStyle = (variant: Variant, danger?: boolean, success?: boolean) => css`
  ${getLoadingStylesForVariant(variant, danger, success)};
  pointer-events: none;
  user-select: none;
`
