import { Badge } from '@design-system'

import React, { ReactElement } from 'react'

import { useToDoCount } from '../../hooks/useToDoCount'

export const ToDoCountBadge = (): ReactElement | null => {
  const { count, isLoading } = useToDoCount()

  if (!count || isLoading) {
    return null
  }

  return <Badge variant="error">{count}</Badge>
}
