import { Button } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateOrEditRulesetModal } from '../../context/createOrEditRulesetModalContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { submitForm, isLoading } = useCreateOrEditRulesetModal()

  return (
    <Button type="button" onClick={submitForm} loading={isLoading}>
      {t('save')}
    </Button>
  )
}
