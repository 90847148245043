import { ItemsPerPage } from '@design-system'

import { useCallback } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { ReceiptFillType } from '@views/receipts/elements/Receipt'
import { ReceiptsSortProperty } from '@views/receipts/enums/sort'
import { useFetchReceipts } from '@views/receipts/hooks/useFetchReceipts'

import { useBulkNavigationQueryParams } from '../../../hooks/useBulkNavigationQueryParams'
import { BillUrlParams } from '../types/billUrlParams'
import { getBulkEditAttachmentPath } from '../utils/getBulkEditAttachmentPath'
import { useBulkNavigation } from './useBulkNavigation'

export const useBulkAttachmentsNavigation = () => {
  const { attachmentIdBulk } = useParams<BillUrlParams>()
  const isEnabled = !!attachmentIdBulk
  const [bulkQueryParams, setBulkQueryParams] = useBulkNavigationQueryParams({
    enabled: isEnabled,
  })
  const history = useHistory()
  const location = useLocation()

  const { bulkPage, bulkPageSize, bulkSortDirection, bulkSortProperty, bulkUploadType } = bulkQueryParams
  const hasAllPropertiesDefined = bulkPage && bulkPageSize && bulkSortDirection && bulkSortProperty && bulkUploadType
  const page = Number(bulkPage)
  const pageSize = Number(bulkPageSize) as ItemsPerPage

  const handleChangePage = useCallback(
    (attachmentIdBulk: string | undefined) => {
      history.push(`${getBulkEditAttachmentPath(attachmentIdBulk || '')}${location.search}`)
    },
    [history, location.search],
  )

  const { attachments, isLoading, pagination } = useFetchReceipts({
    isDisabled: !isEnabled || !hasAllPropertiesDefined,
    page,
    pageSize,
    sortDirection: bulkSortDirection,
    sortBy: bulkSortProperty as ReceiptsSortProperty,
    searchQuery: '',
    fillType: ReceiptFillType.Nonfilled,
    type: bulkUploadType,
  })

  const { itemsToGo, next, previous, hasNext, hasPrevious } = useBulkNavigation({
    currentId: attachmentIdBulk,
    isLoading,
    items: attachments || [],
    page,
    onPageChange: handleChangePage,
    onQueryParamsChange: setBulkQueryParams,
    pageSize,
    pagination,
  })

  return {
    attachmentsToGo: itemsToGo,
    goToNextAttachment: next,
    goToPreviousAttachment: previous,
    hasNextAttachment: hasNext,
    hasPreviousAttachment: hasPrevious,
    isLoading,
  }
}
