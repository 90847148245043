import { css } from '@emotion/core'
import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { Link } from 'react-router-dom'

import { BorderRadius } from '../../../../enums/borderRadius'
import { Color } from '../../../../enums/color'
import { Spacing } from '../../../../enums/spacing'
import { pxToNumber } from '../../../../utils/pxToNumber'
import { sizeToDimension } from '../../../Button/utils/getSizeStyle'
import { TABLE_ROW_HOVER_COLOR } from '../../constants/tableColors'
import { TABLE_ROW_HORIZONTAL_SPACING, TABLE_ROW_VERTICAL_SPACING } from '../../enums/styles'
import { getRowPadding } from '../../utils/getRowPadding'
import { TableCellWrapper } from '../TableCell/styles'

export const STATIC_ACTIONS_SPACING_LEFT = Spacing.S
export const STATIC_ACTIONS_SPACING_RIGHT = `-${pxToNumber(TABLE_ROW_HORIZONTAL_SPACING) / 2}px`

interface TableRowWrapperProps {
  clickable?: boolean
  disabled?: boolean
  expandable?: boolean
  expanded?: boolean
  hoverable?: boolean
  ignoreSpacing?: boolean
  isSkeletonView?: boolean
  selectable: boolean
  withStaticActionOffset?: boolean
}

export const TableRowWrapper = styled.li<TableRowWrapperProps>`
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  min-height: 56px;
  position: relative;
  border-top: 1px solid ${transparentize(0.5, Color.Gray40)};
  align-items: center;
  color: ${Color.DeepSeaGreen};
  cursor: ${({ clickable, expandable }) => (clickable || expandable ? 'pointer' : 'initial')};
  ${({ selectable, expandable = false, ignoreSpacing = false, withStaticActionOffset = false }) =>
    getRowPadding({ ignoreSpacing, selectable, expandable, withStaticActionOffset })};

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.6;
      pointer-events: none;
  `}

  ${({ clickable, expandable, expanded, hoverable }) => {
    if (expanded) {
      return `background-color: ${transparentize(0.3, Color.Gray20)};`
    }

    if (clickable || hoverable || expandable) {
      return `
        &:hover {
          background-color: ${TABLE_ROW_HOVER_COLOR};
        }
      `
    }
  }}

  &:hover {
    // To show cells that are hidden by default (see TableCellWrapper styles)
    // and should reveal on mouse hover
    ${() => TableCellWrapper} {
      opacity: 1;
    }
    ${() => TableHoverActions} {
      opacity: 1;
      pointer-events: all;
    }
  }

  &:first-of-type {
    border-top: 0;
  }
  &:last-of-type {
    border-bottom-right-radius: ${BorderRadius.L};
    border-bottom-left-radius: ${BorderRadius.L};
  }
`

const getTableLinkStyles = (
  ignoreSpacing: boolean,
  selectable: boolean,
  expandable: boolean,
  withStaticActionOffset: boolean,
) => `
  display: inherit;
  height: 100%;
  flex: inherit;
  align-items: inherit;
  font: inherit;
  color: currentColor;
  text-decoration: none;
  ${getRowPadding({ ignoreSpacing, selectable, expandable, withStaticActionOffset })};
`

interface TableRowLinkProps {
  selectable: boolean
}

export const TableRowLink = styled.a<TableRowLinkProps>`
  ${({ selectable }) => getTableLinkStyles(false, selectable, false, false)};
`

export const TableRowRouterLink = styled(Link, {
  shouldForwardProp: (propName) => isPropValid(propName),
})<TableRowLinkProps>`
  ${({ selectable }) => getTableLinkStyles(false, selectable, false, false)};
`

interface TableHoverActionsProps {
  bgColor: Color
  withStaticActions?: boolean
}

export const TableHoverActions = styled.aside<TableHoverActionsProps>`
  ${({ bgColor, withStaticActions }) => css`
    opacity: 0;
    display: flex;
    position: absolute;
    top: 0;
    right: ${withStaticActions
      ? sizeToDimension.m +
        Math.abs(pxToNumber(STATIC_ACTIONS_SPACING_RIGHT)) +
        Math.abs(pxToNumber(STATIC_ACTIONS_SPACING_LEFT))
      : 0}px;
    height: 100%;
    align-items: center;
    background-color: ${bgColor};
    pointer-events: none;
    ${getRowPadding()};
    ${withStaticActions ? 'padding-right: 0' : ''};

    &:before {
      content: '';
      width: 50px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 100%;
      background: linear-gradient(to right, ${transparentize(1, bgColor)} 10%, ${bgColor} 100%);
    }
  `}
`

export const TableStaticActions = styled.aside`
  display: flex;
  margin-top: -${TABLE_ROW_VERTICAL_SPACING};
  margin-bottom: -${TABLE_ROW_VERTICAL_SPACING};
  margin-right: ${STATIC_ACTIONS_SPACING_RIGHT};
  margin-left: ${STATIC_ACTIONS_SPACING_LEFT};
  align-items: center;
`
