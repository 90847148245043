import { encodePeriodValue, GlobalPeriodSelect } from '@components'

import React, { ReactElement, useCallback, useEffect, useRef } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useGlobalFiscalYear } from '../../../../../../contexts/globalFiscalYearContext'
import { PeriodValue } from '../../../../../../types/periodValue'
import { getPeriodValueFromFiscalYear } from '../../../../../../utils/getPeriodValueFromFiscalYear'
import { useBillsListFilters } from '../../hooks/useBillsListFilters'
import * as Styled from './styles'

export const BillsPeriodSelector = (): ReactElement => {
  const { organization } = useUserOrganization()
  const [{ period: queryParamPeriod }, setQueryParams] = useBillsListFilters()
  const { fiscalYear: globalFiscalYear } = useGlobalFiscalYear()
  const hasPeriodBeenSetRef = useRef(false)

  // Check the initial period
  useEffect(() => {
    if (!!organization?.id && !queryParamPeriod && globalFiscalYear !== undefined && !hasPeriodBeenSetRef.current) {
      hasPeriodBeenSetRef.current = true
      setQueryParams({ period: encodePeriodValue(getPeriodValueFromFiscalYear(globalFiscalYear), organization.id) })
    }
  }, [globalFiscalYear, organization, queryParamPeriod, setQueryParams])

  const handlePeriodChange = useCallback(
    (periodValue: PeriodValue) => {
      if (!organization?.id) {
        return
      }

      setQueryParams({ period: encodePeriodValue(periodValue, organization.id) })
    },
    [organization, setQueryParams],
  )

  return (
    <Styled.BillsPeriodSelector>
      <GlobalPeriodSelect encodedPeriod={queryParamPeriod} onChange={handlePeriodChange} placement="bottom-end" />
    </Styled.BillsPeriodSelector>
  )
}
