import { Button, Modal, ModalProps, Text, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../enums/modalId'

interface AccountInUseModalProps extends ModalProps {
  removeQueryParams: () => void
}

export const AccountInUseModal = ({ removeQueryParams, ...modalProps }: AccountInUseModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeModal } = useModal(ModalId.BankConnectionAlreadyInUseModal)

  const handleCloseModal = useCallback(() => {
    removeQueryParams()
    closeModal()
  }, [closeModal, removeQueryParams])

  return (
    <Modal size="s" {...modalProps}>
      <Modal.Header>
        <Text variant="h2">{t('bank_integration.account_in_use_header')}</Text>
      </Modal.Header>
      <Modal.Body>
        <Text>{t('bank_integration.account_in_use_message')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseModal} variant="secondary">
          {t('bank_integration.close_modal')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
