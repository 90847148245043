import React, { ReactElement, ReactNode } from 'react'

import { SettingsLayoutHeader } from './elements/SettingsLayoutHeader'
import * as Styled from './styles'

export interface SettingsLayoutProps {
  children?: ReactNode
  onBackButtonClick?: () => void
  sideActions?: ReactNode
  title?: string
  withBackButton?: boolean
}

export const SettingsLayout = ({
  children,
  onBackButtonClick,
  sideActions,
  title,
  withBackButton,
}: SettingsLayoutProps): ReactElement => {
  return (
    <Styled.SettingsLayoutWrapper>
      <SettingsLayoutHeader
        onBackButtonClick={onBackButtonClick}
        sideActions={sideActions}
        title={title}
        withBackButton={withBackButton}
      />

      <Styled.Row>{children}</Styled.Row>
    </Styled.SettingsLayoutWrapper>
  )
}

SettingsLayout.Column = Styled.Column
SettingsLayout.Header = SettingsLayoutHeader
