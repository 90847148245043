import { Locale } from '@design-system'

import { useCallback, useMemo } from 'react'

import { useCurrentUser, useUpdateUser } from '@modules-deprecated/app/user'

export const useLanguageChange = () => {
  const { user } = useCurrentUser()
  const { update } = useUpdateUser({
    onSuccess: () => {
      window.location.reload()
    },
  })

  const changeLanguage = useCallback(
    (locale?: Locale) => {
      if (!user) {
        return
      }

      update({
        id: user.id,
        locale,
      })
    },
    [update, user],
  )

  return useMemo(() => ({ changeLanguage }), [changeLanguage])
}
