import React from 'react'
import { Box } from 'rebass'

import { coloring } from '../../../styles-deprecated'
import { reactClass } from '../../../utils'
import { IconProps } from '../Icon'

export const IconMenetoLogOut = ({ color = '#ffffff', width = '20px', height = '20px', ...rest }: IconProps) => (
  <Box as="i" className={reactClass('icon-meneto-logout')} {...rest}>
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      color={coloring(color)}
    >
      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" fill="none"></path>
      <polyline points="16 17 21 12 16 7" fill="none"></polyline>
      <line x1="21" y1="12" x2="9" y2="12" fill="none"></line>
    </svg>
  </Box>
)
