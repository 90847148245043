import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

export interface SettingsSectionFooterProps {
  children: ReactNode
}

export const SettingsSectionFooter = ({ children }: SettingsSectionFooterProps): ReactElement => {
  return <Styled.SettingsSectionFooterWrapper>{children}</Styled.SettingsSectionFooterWrapper>
}
