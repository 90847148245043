import { BasePlacement, Placement } from '@popperjs/core'

const ALLOWED_ARROW_PLACEMENTS: BasePlacement[] = ['top', 'bottom', 'left', 'right']

export const getArrowPlacementFromPopper = (popperPlacement: Placement = 'top'): BasePlacement => {
  let arrowPlacement: BasePlacement = 'top'

  for (const placement of ALLOWED_ARROW_PLACEMENTS) {
    if (popperPlacement.includes(placement)) {
      arrowPlacement = placement
    }
  }

  return arrowPlacement
}
