import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { CustomEvent } from '../../../enums/customEvent'
import { useCustomEventListener } from '../../../hooks/useCustomEventListener'
import { BillsRoute } from '../enums/billsRoute'

export const useCustomEventsRoutes = () => {
  const history = useHistory()
  const location = useLocation()

  const handleCreateBillClick = useCallback(() => {
    history.push(`${BillsRoute.New}${location.search}`)
  }, [history, location.search])

  useCustomEventListener(CustomEvent.CreateNewBillClicked, handleCreateBillClick)
}
