import uniqueId from 'lodash/uniqueId'
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useForm } from '../Form'
import { useSwitchGroup } from '../SwitchGroup'
import * as Styled from './styles'
import { Size } from './types/size'

export interface SwitchProps {
  autoFocus?: boolean
  checked?: boolean
  children?: ReactNode
  className?: string
  danger?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  name?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  size?: Size
  value?: string
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      autoFocus = false,
      checked,
      children,
      className,
      danger = false,
      defaultChecked,
      disabled = false,
      name,
      onChange,
      size = 'm',
      value = '',
      ...rest
    },
    forwardedRef,
  ) => {
    const {
      disabled: groupDisabled,
      name: groupName,
      onChange: onGroupChange,
      value: groupValue,
    } = useSwitchGroup() || {}
    const [checkedValue, setCheckedValue] = useState<boolean>(
      defaultChecked ?? (checked || groupValue?.includes(value) || false),
    )
    const { name: formName } = useForm() || {}
    const nativeCheckboxRef = useRef<HTMLInputElement>(null)

    const id = useMemo(() => (name ? `${formName}-${name}` : `${formName}-${uniqueId('switch-')}`), [formName, name])
    const checkboxGroupName = useMemo(() => `${formName}-${groupName || name}`, [formName, groupName, name])

    const isDisabled = !!(disabled ?? groupDisabled)

    // Functions

    const handleValueChange = useCallback((newCheckedValue: boolean) => {
      setCheckedValue(newCheckedValue)
    }, [])

    const handleCheckboxChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (isDisabled) {
          return
        }

        setCheckedValue(event.currentTarget.checked)
        onChange && onChange(event)
        onGroupChange && onGroupChange(event)
      },
      [isDisabled, onChange, onGroupChange],
    )

    // Mutations

    useEffect(() => {
      if (autoFocus) {
        nativeCheckboxRef.current?.focus()
      }
    }, [autoFocus])

    useEffect(() => {
      if (checked !== undefined) {
        handleValueChange(checked)
      }
    }, [checked, handleValueChange])

    // Hooks utils

    useImperativeHandle(forwardedRef, () => nativeCheckboxRef?.current as HTMLInputElement)

    return (
      <Styled.SwitchWrapper
        checked={!!checkedValue}
        className={className}
        disabled={isDisabled}
        danger={danger}
        size={size}
      >
        <Styled.NativeCheckbox
          {...rest}
          ref={nativeCheckboxRef}
          checked={checkedValue}
          disabled={isDisabled}
          id={id}
          name={checkboxGroupName}
          onChange={handleCheckboxChange}
          value={value}
          type="checkbox"
        />
        <Styled.Control size={size} />

        {children && (
          <Styled.Label id={id} selectable={false}>
            {children}
          </Styled.Label>
        )}
      </Styled.SwitchWrapper>
    )
  },
)
