import { NavItem } from '@design-system'

import { TFunction } from 'i18next'
import reduce from 'lodash/reduce'

import { PublicAccount } from '@modules-deprecated/app/accounts/types'

import { PublicAccountValue } from '../types/publicAccountValue'
import { getDisplayTitle } from './getDisplayTitle'

const getItem = (account: PublicAccount): NavItem<PublicAccountValue> => {
  const displayTitle = getDisplayTitle(account)

  const searchKeywords = [account.name, account.id, account.nature.id, account.nature.name, account.number.toString()]

  return {
    id: account.id,
    children: displayTitle,
    searchKeywords,
    value: {
      ...account,
      displayData: {
        title: displayTitle,
      },
    },
  }
}

export const getItems = (accounts: PublicAccount[], t: TFunction): NavItem<PublicAccountValue>[] => {
  return reduce(
    accounts,
    (result, account) => {
      const item = getItem(account)

      result.push(item)
      return result
    },
    [] as NavItem<PublicAccountValue>[],
  )
}
