import { TFunction } from 'i18next'
import * as yup from 'yup'

import { ContactType } from '../types/contactType'

export const CreateContactModalSchema = (t: TFunction, contactType: ContactType) => {
  const companyName =
    contactType === ContactType.Company
      ? yup.string().required(t('contacts.form.error.name_is_required')).min(1)
      : yup.string()
  const namePrivate =
    contactType === ContactType.Person
      ? yup.string().required(t('contacts.form.error.name_is_required')).min(1)
      : yup.string()

  return yup.object().shape({
    companyForName: yup.object().shape({
      name: companyName,
    }),
    companyForRegistrationNo: yup.object().shape({
      registrationNo: yup.string(),
    }),
    email: yup.string().email(),
    phone: yup.string(),
    namePrivate,
    address: yup.object(),
    country: yup.object().shape({
      id: yup.string().required(t('contacts.form.country_is_required')),
    }),
    isCustomer: yup.boolean(),
    isSupplier: yup.boolean(),
    ean: yup.string(),
    defaultExpenseProductDescription: yup.string(),
    defaultExpenseAccount: yup.object(),
    paymentTermsMode: yup.object(),
    contactPersons: yup.array(
      yup.object().shape({
        name: yup.string(),
        email: yup.string().email(t('email_invalid')),
      }),
    ),
  })
}
