import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const HelpModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const ModalIllustrationWrapper = styled.div`
  max-width: 128px;
  margin: ${Spacing.XL} auto 0;
`
