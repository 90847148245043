import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const UmbrellaSubscriptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 291px;
  grid-column-gap: ${Spacing.XXL};
`

export const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
