import { ChangeEvent, ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonDropdownMultiple } from '../../../ButtonDropdownMultiple'
import { IconButton } from '../../../IconButton'
import { NavItem } from '../../../NavList'
import { SkeletonIconButton } from '../../../SkeletonIconButton'
import { Tooltip } from '../../../Tooltip'
import { TableToggleableColumn } from '../../types/column'
import * as Styled from './styles'

interface TableColumnsFilteringProps {
  columns: TableToggleableColumn[]
  isLoading?: boolean
  hiddenColumnsIds: string[]
  onSelect: (id: string, checked?: boolean) => void
}

export const TableColumnsFiltering = ({
  columns,
  isLoading,
  hiddenColumnsIds,
  onSelect,
}: TableColumnsFilteringProps): ReactElement => {
  const { t } = useTranslation()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const dropdownItems = useMemo(
    (): NavItem[] =>
      columns.map(({ id, name }) => ({
        children: name,
        id,
        value: id,
      })),
    [columns],
  )

  const handleDropdownOpen = useCallback(() => {
    setIsDropdownOpen(true)
  }, [])

  const handleDropdownClose = useCallback(() => {
    setIsDropdownOpen(false)
  }, [])

  const selectedValues = useMemo(
    () => dropdownItems.filter(({ value }) => !hiddenColumnsIds.includes(value)).map(({ value }) => value),
    [dropdownItems, hiddenColumnsIds],
  )

  const handleSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>, selectedIds: string[], itemId?: string, itemChecked?: boolean) => {
      if (itemId) {
        onSelect(itemId, itemChecked)
      }
    },
    [onSelect],
  )

  return (
    <Styled.TableFilteringWrapper>
      {isLoading ? (
        <SkeletonIconButton size="m" />
      ) : (
        <Tooltip forceHidden={isDropdownOpen} label={t('table_column_toggler.toggle_columns')} placement="top">
          <ButtonDropdownMultiple
            items={dropdownItems}
            selectedValues={selectedValues}
            onClose={handleDropdownClose}
            onOpen={handleDropdownOpen}
            onSelect={handleSelect}
            placement="top-start"
            size="m"
            triggerElement={<IconButton icon="barChartEqual" size="m" variant="secondary" />}
          />
        </Tooltip>
      )}
    </Styled.TableFilteringWrapper>
  )
}
