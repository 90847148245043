import React, { ReactElement } from 'react'

import { ReportHtml } from '../../elements/ReportHtml'
import { ReportsSkeleton } from '../../elements/ReportsSkeleton'
import { TrialBalanceTableActions } from './elements/TrialBalanceTableActions'
import { useTrialBalance } from './hooks/useTrialBalance'

export const TrialBalance = (): ReactElement => {
  const { report, isLoading, isIdle } = useTrialBalance()

  return (
    <>
      <TrialBalanceTableActions />
      {isLoading || isIdle ? <ReportsSkeleton /> : <ReportHtml html={report?.html} />}
    </>
  )
}
