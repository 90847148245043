import { Color } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

interface DateFormatterWrapperProps {
  color?: Color
}

export const DateFormatterWrapper = styled.time<DateFormatterWrapperProps>`
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
`
