import styled from '@emotion/styled'
import React, { ReactElement, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

export type LazyPreviewOptions = {
  rootElement?: HTMLElement
  rootMargin?: string
}

type LazyPreviewProps = {
  children: ReactNode
  options?: LazyPreviewOptions
}

const LazyWrapper = styled.span`
  width: 100%;
`

export const LazyPreview = ({
  children,
  options: { rootElement, rootMargin = '0px' } = {},
}: LazyPreviewProps): ReactElement => {
  const [ref, inView] = useInView({
    root: rootElement,
    rootMargin,
    triggerOnce: true,
  })

  return <LazyWrapper ref={ref}>{inView && children}</LazyWrapper>
}
