/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use DS Form instead
 */
import { css } from '@emotion/core'
import React, { ReactNode, useState } from 'react'
import { Box, BoxProps, Flex } from 'rebass'

import { deprecatedComponentInfo } from '../../utils/deprecatedComponentInfo'
import { IconAdd, IconTrash } from '../Icons'
import { ConnectForm, ConnectFormType } from './ConnectForm'
import { ErrorMessage } from './ErrorMessage'
import { FormLabel } from './FormLabel'
import { InputProps } from './Input'

deprecatedComponentInfo('FormGroup', 'ControlledFormGroup')

export type FormGroupProps = Omit<BoxProps, 'children' | 'label'> & {
  addLabel?: string
  children: React.ReactElement<InputProps>[] // Limit children to valid inputs
  label?: ReactNode
  horizontal?: boolean
  silent?: boolean
}

let blockCount = 0

export const FormGroup = React.forwardRef(
  ({ addLabel, children, label, horizontal, silent, ...rest }: FormGroupProps, ref) => {
    const names = [] as string[]
    let required = false
    React.Children.forEach(children, (child: React.ReactElement<InputProps>) => {
      child.props.name && names.push(child.props.name)
      required = required || !!(child.props.required || child.props.options?.required)
    })

    const [blocks, setBlocks] = useState<number[]>([0])

    const handleAddBlock = () => {
      setBlocks([...blocks, ++blockCount])
    }
    const handleRemoveBlock = (id: number) => () => {
      setBlocks(blocks.filter((_id) => _id !== id))
    }

    return (
      <ConnectForm>
        {({ formState }: ConnectFormType) => {
          const errors = formState?.errors || {}

          return (
            <Box ref={ref} {...rest}>
              {label && <FormLabel required={required}>{label}</FormLabel>}
              {blocks.map((id: number) => (
                <Flex key={id}>
                  <Box
                    variant={horizontal ? 'forms.formhorizontalgroup' : 'forms.formgroup'}
                    css={css`
                      flex: auto;
                    `}
                  >
                    {React.Children.map(
                      children as React.ReactElement<InputProps>[],
                      (child: React.ReactElement<InputProps>): JSX.Element | null =>
                        React.isValidElement(child)
                          ? React.cloneElement(child, {
                              silent,
                              name: id !== 0 && blocks.length > 1 ? `${child.props.name}-${id}` : child.props.name,
                            })
                          : null,
                    )}
                  </Box>
                  {blocks.length > 1 && (
                    <Box
                      onClick={handleRemoveBlock(id)}
                      css={css`
                        margin-left: 16px;
                        padding-top: 5px;
                        cursor: pointer;
                        color: #697588;
                        :hover {
                          color: #2766f1;
                        }
                      `}
                    >
                      <IconTrash width={14} />
                    </Box>
                  )}
                </Flex>
              ))}
              {addLabel && (
                <FormLabel
                  onClick={handleAddBlock}
                  css={css`
                    display: flex;
                    margin-top: 10px;
                    align-items: flex-end;
                    cursor: pointer;
                    color: #697588;
                    :hover {
                      color: #2766f1;
                    }
                    i {
                      margin-right: 5px;
                    }
                  `}
                >
                  <IconAdd color="inherit" width={13} />
                  {addLabel}
                </FormLabel>
              )}
              {names.map((name) => errors[name] && <ErrorMessage key={name} error={errors[name]} />)}
            </Box>
          )
        }}
      </ConnectForm>
    )
  },
)
