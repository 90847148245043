import { useQuery } from 'react-query'

import { QueryKeys } from '../enums/queryKeys'
import { getUmbrellaMembers, GetUmbrellaMembersQueryParams } from '../query-api/umbrella-members-query'

export const useUmbrellaMembers = (umbrellaId?: string, queryParams?: GetUmbrellaMembersQueryParams) => {
  const { data, isLoading } = useQuery(
    [QueryKeys.UmbrellaMembers, queryParams],
    () => getUmbrellaMembers(umbrellaId || '', queryParams),
    { enabled: !!umbrellaId },
  )

  return {
    umbrellaMembers: data || [],
    isLoading,
  }
}
