import {
  BookkeepingTagNavItemValue,
  getAccountFromBookkeepingTagAnswer,
  getAnswerFromBookkeepingTagValue,
  getTaxRateFromBookkeepingTagAnswer,
} from '@components'

import { Account } from '@modules-deprecated/app/accounts/types'
import { TaxRate } from '@modules-deprecated/app/taxrates/types'
import { Contact } from '@views/contacts/types/contact'

interface GetDataFromBookkeepingTagProps {
  accounts?: Account[]
  bookkeepingTagValue: BookkeepingTagNavItemValue
  isVatExempted?: boolean
  taxRates?: TaxRate[]
  vendor?: Partial<Contact>
}

interface GetDataFromBookkeepingTagResult {
  account: Account | undefined
  taxRate: TaxRate | undefined
}

export const getDataFromBookkeepingTag = ({
  accounts,
  bookkeepingTagValue,
  isVatExempted,
  taxRates,
  vendor,
}: GetDataFromBookkeepingTagProps): GetDataFromBookkeepingTagResult => {
  const answer = getAnswerFromBookkeepingTagValue(bookkeepingTagValue, vendor?.country?.id || vendor?.countryId)
  const account = answer ? getAccountFromBookkeepingTagAnswer(answer, accounts) : undefined
  const taxRate = answer ? getTaxRateFromBookkeepingTagAnswer(answer, isVatExempted, taxRates) : undefined

  return {
    account,
    taxRate,
  }
}
