import { Button, Color, Icon, LottieAnimation, Modal, Space, Text, useModal } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberCloseModal } from '../../../../contexts/emberCloseModalContext'
import { ModalId } from '../../../../enums/modalId'
import { getImgPath } from '../../../../utils/getImgPath'
import * as Styled from './styles'

const featuresTranslationKeys = [
  'first_invoice_modal.features.send_invoice',
  'first_invoice_modal.features.follow_timeline',
  'first_invoice_modal.features.keep_track_of_invoices',
]

export const FirstInvoiceModal = () => {
  const { t } = useTranslation()
  const { closeEmberModal } = useEmberCloseModal()

  const { close } = useModal(ModalId.FirstInvoiceModal, {
    onClose: () => {
      closeEmberModal()
    },
  })

  const handleCloseClick = useCallback(() => {
    close()
  }, [close])

  return (
    <Styled.FirstInvoiceModal id={ModalId.FirstInvoiceModal} size="s" closable>
      <Modal.Header />
      <Modal.Body>
        <Styled.InfoTextWrapper>
          <img src={getImgPath('/modals/green-tickbox-illustration.svg')} width="99" height="99" alt="green tickbox" />
          <Styled.LottieAnimationConfetti>
            <LottieAnimation name="confetti" options={{ loop: false, autoplay: true }} />
          </Styled.LottieAnimationConfetti>
          <Space />
          <Text variant="h2">{t('first_invoice_modal.title')}</Text>
          <Space />
          <Text variant="large">{t('first_invoice_modal.subtitle')}</Text>
          <Space />
          <ul>
            {featuresTranslationKeys.map((feature) => (
              <Styled.FeaturesListItem>
                <Styled.IconWrapper>
                  <Icon icon="checkCircleSolid" color={Color.Green120} />
                </Styled.IconWrapper>
                <Text variant="large">{t(feature)}</Text>
              </Styled.FeaturesListItem>
            ))}
          </ul>
        </Styled.InfoTextWrapper>
      </Modal.Body>
      <Styled.FirstInvoiceModalFooter>
        <Button onClick={handleCloseClick}>{t('first_invoice_modal.btn')}</Button>
      </Styled.FirstInvoiceModalFooter>
    </Styled.FirstInvoiceModal>
  )
}
