import { createContext, Dispatch, ReactElement, ReactNode, SetStateAction, useContext, useState } from 'react'

import { BankLinesSortingProperty } from '../elements/BankLinesModalBody/elements/BankLinesActions/types/bankLinesSortingProperty'

interface ContextState {
  bankLinesSearchQuery?: string
  bankLinesSortingProperty?: BankLinesSortingProperty
  setBankLinesSearchQuery: Dispatch<SetStateAction<string | undefined>>
  setBankLinesSortingProperty: Dispatch<SetStateAction<BankLinesSortingProperty | undefined>>
}

const BankLinesFilteringContext = createContext<ContextState | undefined>(undefined)

interface BankLinesFilteringContextContextProps {
  children?: ReactNode
}

export const BankLinesFilteringContextProvider = ({
  children,
}: BankLinesFilteringContextContextProps): ReactElement => {
  const [bankLinesSearchQuery, setBankLinesSearchQuery] = useState<string | undefined>()
  const [bankLinesSortingProperty, setBankLinesSortingProperty] = useState<BankLinesSortingProperty | undefined>(
    BankLinesSortingProperty.AmountDifference,
  )

  return (
    <BankLinesFilteringContext.Provider
      value={{
        bankLinesSearchQuery,
        bankLinesSortingProperty,
        setBankLinesSearchQuery,
        setBankLinesSortingProperty,
      }}
    >
      {children}
    </BankLinesFilteringContext.Provider>
  )
}

export const useBankLinesFilters = () => {
  const context = useContext(BankLinesFilteringContext)

  if (!context) {
    throw new Error('BankLinesFilteringContextProvider is missing in the module!')
  }

  return context
}
