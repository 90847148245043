export enum TransactionOriginatorType {
  BankPayment = 'bankPayment',
  Bill = 'bill',
  ContactBalancePayment = 'contactBalancePayment',
  ConversionBalance = 'conversionBalance',
  ExternalInvoice = 'externalInvoice',
  DaybookTransaction = 'daybookTransaction',
  Invoice = 'invoice',
  InvoiceLateFee = 'invoiceLateFee',
  SalesTaxReturn = 'salesTaxReturn',
  SalesTaxPayment = 'salesTaxPayment',
}
