import { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { useForm } from '../../../../../hooks'
import { getDefaultValues } from '../elements/InvoiceEditorContent/elements/InvoiceEditorForm/utils/getDefaultValues'
import { getInvoiceEditorFormSchema } from '../elements/InvoiceEditorContent/elements/InvoiceEditorForm/utils/getInvoiceEditorFormSchema'

interface ContextState {
  submitForm: () => void
}

const InvoiceEditorFormContext = createContext<ContextState | undefined>(undefined)

interface InvoiceEditorFormContextProviderProps {
  children?: ReactNode
}

export const InvoiceEditorFormContextProvider = ({ children }: InvoiceEditorFormContextProviderProps): ReactElement => {
  const { handleSubmit, Form } = useForm({
    defaultValues: getDefaultValues(),
    validationSchema: getInvoiceEditorFormSchema(),
  })

  const handleFormSubmit = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('submit')
  }, [])

  const submitForm = useCallback(() => {
    handleSubmit(handleFormSubmit)()
  }, [handleSubmit, handleFormSubmit])

  return (
    <InvoiceEditorFormContext.Provider value={{ submitForm }}>
      <Form>{children}</Form>
    </InvoiceEditorFormContext.Provider>
  )
}

export const useInvoiceEditorForm = () => {
  const context = useContext(InvoiceEditorFormContext)

  if (!context) {
    throw new Error('InvoiceEditorFormContext is missing in the module!')
  }

  return context
}
