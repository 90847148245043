import { Translate } from '@components'
import { InternalAccessor, Table, TableColumn } from '@design-system'

import React, { ReactElement, useMemo } from 'react'

import { TableIds } from '../../../../../../../../enums/tableIds'
import { useInvoiceTemplates } from '../../hooks/useInvoiceTemplates'
import { useInvoiceTemplatesTableData } from '../../hooks/useInvoiceTemplatesTableData'
import { InvoiceTemplateTableRow } from '../../types/invoiceTemplateTableRow'
import { InvoiceTemplatesTableHoverActions } from './elements/InvoiceTemplatesTableHoverActions'
import { InvoiceTemplatesTableStaticActions } from './elements/InvoiceTemplatesTableStaticActions'
import { TableInvoiceTemplateName } from './elements/TableInvoiceTemplateName'

export const InvoiceTemplatesTable = (): ReactElement => {
  const { invoiceTemplates, isLoading } = useInvoiceTemplates()
  const tableData = useInvoiceTemplatesTableData(invoiceTemplates)

  const columns: TableColumn<InvoiceTemplateTableRow>[] = useMemo(
    () => [
      {
        accessor: 'invoiceTemplateName',
        Cell: ({ row, data }) => {
          const { invoiceTemplateId, isDefaultInvoiceTemplate, invoiceTemplateName } = data[
            row.id
          ] as InvoiceTemplateTableRow

          return (
            <TableInvoiceTemplateName
              invoiceTemplateId={invoiceTemplateId}
              invoiceTemplateName={invoiceTemplateName}
              isDefaultInvoiceTemplate={isDefaultInvoiceTemplate}
            />
          )
        },
        Header: <Translate value="settings.organization.invoice.templates_settings.table.header.name" />,
      },
      {
        accessor: InternalAccessor.HoverActions,
        Cell: ({ row, data }) => {
          const { invoiceTemplateId, isDefaultInvoiceTemplate, invoiceTemplate } = data[
            row.id
          ] as InvoiceTemplateTableRow

          return (
            <InvoiceTemplatesTableHoverActions
              invoiceTemplate={invoiceTemplate}
              invoiceTemplateId={invoiceTemplateId}
              isDefaultInvoiceTemplate={isDefaultInvoiceTemplate}
            />
          )
        },
        Header: '',
      },
      {
        accessor: InternalAccessor.StaticActions,
        Cell: ({ row, data }) => {
          const { invoiceTemplateId, isDefaultInvoiceTemplate } = data[row.id] as InvoiceTemplateTableRow

          return (
            <InvoiceTemplatesTableStaticActions
              invoiceTemplateId={invoiceTemplateId}
              isDefaultInvoiceTemplate={isDefaultInvoiceTemplate}
            />
          )
        },
        Header: '',
      },
    ],
    [],
  )

  return (
    <Table
      columns={columns}
      data={tableData}
      id={TableIds.InvoiceTemplates}
      isLoading={isLoading}
      skeletonItemsPerPage={4}
      withColumnsFiltering={false}
    />
  )
}
