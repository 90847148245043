/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Table /> from design-system instead
 */
import { DefaultValue } from '@design-system'

import { css, SerializedStyles } from '@emotion/core'
import cc from 'classcat'
import React, { useCallback, useState } from 'react'
import { Box, BoxProps } from 'rebass'

import { reactClass } from '../../utils'
import { BulkActions, BulkActionsProps } from './BulkActions'
import defaultStyles from './styles'
import { TableBody } from './TableBody'
import { TableHead } from './TableHead'
import { TableColumn, TableRow, TableTotalRow } from './types'

export type InfiniteTableProps<T extends TableRow> = Omit<BoxProps, 'data' | 'onSelect'> & {
  bulkActions?: Omit<BulkActionsProps, 'onToggle' | 'totalRows'> &
    Partial<Pick<BulkActionsProps, 'onToggle' | 'totalRows'>>
  columns: TableColumn<T>[]
  css?: SerializedStyles
  data?: T[]
  onFetch?: (offset: number) => void
  onHeaderHover?: (isHovered: boolean) => void
  onRowSelect?: (row: T, selected: boolean) => void
  onSelect?: (row: T, index: number) => void
  onSort?: (field: string) => void
  rowSpacing?: number
  selectable?: boolean
  stickyHeader?: boolean
  total?: number | null
  isRowSelectionDisabled?: (row: T) => boolean
  rowTotal?: TableTotalRow<T>
}

export const InfiniteTable = <T extends TableRow>({
  bulkActions,
  className,
  columns,
  css: styles,
  data,
  isRowSelectionDisabled = () => false,
  onFetch = () => null,
  onHeaderHover = () => null,
  onRowSelect = () => null,
  onSelect,
  onSort = () => null,
  rowSpacing,
  rowTotal,
  stickyHeader,
  total,
  ...rest
}: InfiniteTableProps<T>) => {
  const onFetchCallback = useCallback(
    (offset: number) => {
      onFetch(offset)
    },
    [onFetch],
  )
  const [isBulkActionsActive, setBulkActionsActive] = useState(false)

  const toggleBulkActions = useCallback(() => {
    setBulkActionsActive(!isBulkActionsActive)
    if (bulkActions?.onToggle) {
      bulkActions.onToggle(!isBulkActionsActive)
    }
  }, [bulkActions, isBulkActionsActive])

  const rowStyles = css`
    display: grid;
    width: 100%;
    border-collapse: collapse;
    grid-template-rows: auto 1fr auto;
    grid-column-gap: ${rowSpacing || 30}px;
    grid-template-columns: ${columns.map(
      (col: TableColumn<T>) => ' ' + (col.customStyle?.colSize || defaultStyles[col.type].size),
    )};
  `

  return (
    <Box
      css={css`
        position: relative;
        border-radius: ${DefaultValue.BorderRadius};
      `}
    >
      {bulkActions && (
        <BulkActions
          active={isBulkActionsActive}
          totalRows={data?.length || 0}
          selectedRows={data?.map((item) => item.selected).length || 0}
          {...bulkActions}
          onToggle={toggleBulkActions}
        />
      )}
      <Box
        as="table"
        className={cc([className, reactClass('infinite-table'), { stickyHeader, selectable: isBulkActionsActive }])}
        variant="gridTable"
        css={[
          css`
            width: 100%;
          `,
          styles,
        ]}
        {...rest}
      >
        <TableHead<T> columns={columns} onHover={onHeaderHover} onSort={onSort} rowStyles={rowStyles} />
        <TableBody<T>
          columns={columns}
          data={data}
          onFetch={onFetchCallback}
          onSelect={onSelect}
          onRowSelect={onRowSelect}
          total={total}
          rowStyles={rowStyles}
          selectable={isBulkActionsActive}
          isRowSelectionDisabled={isRowSelectionDisabled}
          rowTotal={rowTotal}
        />
      </Box>
    </Box>
  )
}
