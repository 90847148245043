import { useTheme } from 'emotion-theming'
import React, { ReactElement, ReactNode } from 'react'

import { Theme } from '../../types/theme'
import { IconAttention } from '../Icons'
import * as Styled from './styles'

type InfoPanelVariants = 'danger'

interface InfoPanelProps {
  children?: ReactNode
  title?: ReactNode
  variant?: InfoPanelVariants
}

interface Variant {
  icon: ReactNode
  color: string
  backgroundColor: string
}

const getVariantSettings = (variant: InfoPanelVariants, theme: Theme): Variant =>
  ({
    danger: {
      icon: <IconAttention />,
      color: theme.colors.brandOrange,
      backgroundColor: theme.colors.brandOrangeLight,
    },
  })[variant]

export const InfoPanel = ({ children, title, variant = 'danger' }: InfoPanelProps): ReactElement => {
  const theme = useTheme<Theme>()

  const currentVariant = getVariantSettings(variant, theme)

  return (
    <Styled.InfoPanelContainer color={currentVariant.color} backgroundColor={currentVariant.backgroundColor}>
      <Styled.IconWrapper>{currentVariant.icon}</Styled.IconWrapper>
      <Styled.InfoPanelTextContainer>
        {title && <Styled.InfoPanelTitle>{title}</Styled.InfoPanelTitle>}
        {children && <Styled.InfoPanelText>{children}</Styled.InfoPanelText>}
      </Styled.InfoPanelTextContainer>
    </Styled.InfoPanelContainer>
  )
}
