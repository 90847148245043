import { notify, useModal } from '@design-system'

import noop from 'lodash/noop'
import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../../../../../config/queryClient'
import { ModalId } from '../../../../../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../../enums/queryKeys'
import { useForm } from '../../../../../../../../../hooks'
import { APIError } from '../../../../../../../../../utils'
import { getErrorMessage } from '../../../../../../../../../utils/getErrorMessage'
import { TaxAccountWithDetails } from '../../../../../types/taxAccountWithDetails'
import { useCreateVatAccount } from '../hooks/useCreateVatAccount'
import { useUpdateVatAccount } from '../hooks/useUpdateVatAccount'
import {
  CreateOrEditVatAccountForm,
  createOrEditVatAccountFormDefaultValues,
  getValidationSchema,
} from '../utils/formData'

interface ContextState {
  isLoading: boolean
  submitForm: () => void
  isEditMode: boolean
}

const defaultValue: ContextState = {
  isLoading: false,
  submitForm: noop,
  isEditMode: false,
}

const CreateOrEditVatAccountModalContext = createContext(defaultValue)

interface CreateOrEditVatAccountModalContextProps {
  children: ReactNode
  vatAccount?: TaxAccountWithDetails
}

export const CreateOrEditVatAccountModalContextProvider = ({
  children,
  vatAccount,
}: CreateOrEditVatAccountModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { close } = useModal(ModalId.CreateOrEditVatAccountModal, {
    onOpen: () => {
      reset()
    },
  })
  const isEditMode = !!vatAccount

  const handleCreateOrEditError = useCallback(
    (error?: APIError) => {
      const message = getErrorMessage(error, 'salesTaxAccount') || t('error_saving')
      notify({ id: NotificationKeys.CreateOrEditVatAccounts, message, variant: 'error' })
    },
    [t],
  )

  const handleCreateOrEditSuccess = useCallback(() => {
    queryClient.invalidateQueries(QueryKeys.VatAccounts)
    close()
  }, [close])

  const { create, isLoading: isCreateLoading } = useCreateVatAccount({
    onError: handleCreateOrEditError,
    onSuccess: handleCreateOrEditSuccess,
  })

  const { update, isLoading: isEditLoading } = useUpdateVatAccount({
    onError: handleCreateOrEditError,
    onSuccess: handleCreateOrEditSuccess,
  })

  const isLoading = isEditMode ? isEditLoading : isCreateLoading

  const { Form, handleSubmit, setValue, reset } = useForm({
    defaultValues: createOrEditVatAccountFormDefaultValues,
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  useEffect(() => {
    if (vatAccount) {
      setValue('account', vatAccount.account)
      setValue('priority', vatAccount.priority)
      setValue('type', vatAccount.type)
    } else {
      reset()
    }
  }, [reset, setValue, vatAccount])

  const handleSubmitForm = useCallback(
    ({ account, type, priority }: CreateOrEditVatAccountForm) => {
      const accountId = account?.id || ''

      if (isEditMode) {
        return update({ id: vatAccount.id, payload: { accountId, type, priority } })
      }

      if (organization?.id) {
        return create({ accountId, type, priority, organizationId: organization?.id })
      }
    },
    [create, isEditMode, organization?.id, update, vatAccount?.id],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return (
    <CreateOrEditVatAccountModalContext.Provider
      value={{
        submitForm,
        isLoading,
        isEditMode,
      }}
    >
      <Form>{children}</Form>
    </CreateOrEditVatAccountModalContext.Provider>
  )
}

export const useCreateOrEditVatAccountModal = () => useContext(CreateOrEditVatAccountModalContext)
