import { KeyboardEvent } from 'react'

export type MenuEventHandlerProps = {
  input: HTMLInputElement | null
  onMenuToggle: (isVisible: boolean) => void
}

export class MenuEventHandler {
  input: HTMLInputElement | null
  onMenuToggle: (isVisible: boolean) => void

  constructor({ input, onMenuToggle }: MenuEventHandlerProps) {
    this.input = input
    this.onMenuToggle = onMenuToggle
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  openMenu() {
    this.onMenuToggle(true)
    this.input && this.input.focus()
  }

  closeMenu() {
    this.onMenuToggle(false)
  }

  handleKeyDown({ key }: KeyboardEvent<HTMLDivElement>) {
    switch (key) {
      case 'Escape':
        this.closeMenu()
        break
      case 'ArrowDown':
        this.openMenu()
        break
    }
  }
}
