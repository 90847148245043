import { useEffect, useRef } from 'react'

export const useDropdownHeight = (element: HTMLElement | null, isEnabled?: boolean) => {
  const dropdownHeightRef = useRef<number>()

  useEffect(() => {
    if (!isEnabled) {
      dropdownHeightRef.current = undefined
      return
    }

    const elementRect = element?.getBoundingClientRect()

    if (elementRect) {
      const dropdownHeight = elementRect.height
      dropdownHeightRef.current = dropdownHeight
    }
  }, [isEnabled, element])

  return dropdownHeightRef.current
}
