import { Button, ButtonsGroup, Space, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { useSegment } from '../../../../../../hooks'
import { USERFLOW_INVOICE_GUIDE_ID } from '../../../../constants/userflowIds'
import * as Styled from './styles'

export const OnboardingEmptyState = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { track } = useSegment()

  const handleCreateInvoiceClick = useCallback(() => {
    track('xxx - hamster - tile experiment button clicked', {
      button: 'create invoice now',
    })
    navigate(EmberRoute.InvoicesNew, { queryParams: { userflow: USERFLOW_INVOICE_GUIDE_ID } })
  }, [navigate, track])

  const handleShowHow = useCallback(() => {
    track('xxx - hamster - tile experiment button clicked', {
      button: 'show me how',
    })
    window
      .open(
        'https://www.billy.dk/support/article/opret-forste-faktura?utm_source=userflow&utm_medium=internal&utm_campaign=fakturatile_show_me_how',
        '_blank',
      )
      ?.focus()
  }, [track])

  return (
    <Styled.WidgetContentWrapper>
      <Styled.Header>
        <Space size="s" />
        <Text variant="displayS">{t('dashboard.widget.invoices.onboarding.title')}</Text>
        <Space />
        <Styled.DescriptionWrapper>
          <Text>{t('dashboard.widget.invoices.onboarding.description')}</Text>
        </Styled.DescriptionWrapper>
      </Styled.Header>
      <Styled.Footer>
        <ButtonsGroup>
          <Button onClick={handleCreateInvoiceClick}>
            {t('dashboard.widget.invoices.onboarding.start_invoicing')}
          </Button>
          <Button variant="secondary" onClick={handleShowHow}>
            {t('dashboard.widget.invoices.onboarding.show_me_how')}
          </Button>
        </ButtonsGroup>
      </Styled.Footer>
      <Styled.Illustration />
    </Styled.WidgetContentWrapper>
  )
}
