import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const Container = styled.div`
  height: 520px;
`

export const BodyLayout = styled.div`
  display: flex;
  position: relative;
`

export const FilesContainerWrapper = styled.ul`
  padding-right: 8px;
  overflow-y: auto;
`

export const FilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 86px);
  grid-gap: ${Spacing.S};
  padding: ${Spacing.XXXS};
`
