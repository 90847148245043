import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../hooks'
import { FindAccountantFormSchema } from '../../utils/formData'
import { isSummaryFilled } from '../../utils/isSummaryFilled'
import { Step } from '../Step'
import * as Styled from './styles'

export const Steps = (): ReactElement => {
  const { t } = useTranslation()
  const { watch } = useFormContext<FindAccountantFormSchema>()

  const issue = watch('issue')
  const expectations = watch('expectations')
  const isBusinessInfoCorrect = watch('isBusinessInfoCorrect')
  const summary = watch('summary')

  return (
    <Styled.StepsWrapper>
      <Step isChecked={!!issue} content={t('find_accountant.questions.one')} stepNumber={1} />
      <Step isChecked={!!expectations} content={t('find_accountant.questions.two')} stepNumber={2} />
      <Step isChecked={!!isBusinessInfoCorrect} content={t('find_accountant.questions.three')} stepNumber={3} />
      <Step isChecked={isSummaryFilled(summary)} content={t('find_accountant.questions.four')} stepNumber={4} />
    </Styled.StepsWrapper>
  )
}
