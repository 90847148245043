import qs from 'qs'

import { SortDirection } from '../../enums/sortDirection'
import { Metable } from '../../types/metable'
import { getRequest } from '../../utils'
import { TransactionOriginatorType } from './enums/transactionOriginatorType'
import { TransactionOriginatorTypeGroup } from './enums/transactionOriginatorTypeGroup'
import { getOriginatorTypesFromGroup } from './routes/TransactionsList/utils/getOriginatorTypesFromGroup'
import { Transaction } from './types/transaction'

export enum TransactionSortProperty {
  EntryDate = 'entryDate',
  TransactionNo = 'transactionNo',
  VoucherNo = 'voucherNo',
  HasVoucher = 'hasVoucher',
}

export interface FetchTransactionsOptions {
  /* Examples:
   * - entryDatePeriod=all
   * - entryDatePeriod=dates:2019-04-23...2019-06-14
   * - entryDatePeriod=from:2019-05-08
   * - entryDatePeriod=half:2019-2
   * - entryDatePeriod=month:2019-06
   * - entryDatePeriod=quarter:2019-2
   * - entryDatePeriod=through:2019-05-03
   * - entryDatePeriod=year:2019
   * - entryDatePeriod=fiscalyear:2019
   */
  entryDatePeriod?: string
  withVoided?: boolean
  organizationId?: string
  originatorReference?: string
  originatorType?: TransactionOriginatorType[]
  // Possibility to filter by originator type groups, overrides originatorType filter
  originatorTypeGroup?: TransactionOriginatorTypeGroup[]
  q?: string
  page?: number
  pageSize?: number
  sortDirection?: SortDirection
  sortProperty?: TransactionSortProperty
  withHasVoucher?: boolean
  withCreator?: boolean
}

export interface FetchTransactionsResponseData extends Metable {
  transactions: Transaction[]
}

export const fetchTransactions = ({
  entryDatePeriod,
  withVoided,
  organizationId,
  originatorReference,
  originatorType,
  originatorTypeGroup,
  page,
  pageSize,
  q,
  sortDirection,
  sortProperty,
  withHasVoucher,
  withCreator,
}: FetchTransactionsOptions): Promise<FetchTransactionsResponseData> => {
  const queryParams = qs.stringify({
    organizationId,
    // Property 'withVoided' use cases:
    // if true will return only transactions with isVoid or isVoided true
    // if false will return transactions with isVoid or isVoided false
    // if undefined - no filtering on this prop
    ...(withVoided ? {} : { isVoided: false }),
    ...(originatorReference ? { originatorReference } : {}),
    ...(originatorType?.length ? { originatorType } : {}),
    ...(originatorTypeGroup?.length ? { originatorType: getOriginatorTypesFromGroup(originatorTypeGroup) } : {}),
    ...(page ? { page } : {}),
    ...(pageSize ? { pageSize } : {}),
    ...(q ? { q } : {}),
    ...(sortDirection ? { sortDirection } : {}),
    ...(sortProperty ? { sortProperty } : {}),
    ...(entryDatePeriod ? { entryDatePeriod } : {}),
    ...(withHasVoucher ? { withHasVoucher: 1 } : {}),
    ...(withCreator ? { withCreator } : {}),
  })

  return getRequest(`/v2/transactions?${queryParams}`)
}
