import { SettingsSectionsColumnsWrapper } from '@components'

import React, { ReactElement } from 'react'

import { ProfileInfoForm } from './elements/ProfileInfoForm'
import { ProfilePictureForm } from './elements/ProfilePictureForm'

export const UserProfileForm = (): ReactElement => {
  return (
    <SettingsSectionsColumnsWrapper>
      <ProfileInfoForm />
      <ProfilePictureForm />
    </SettingsSectionsColumnsWrapper>
  )
}
