import React, { ChangeEvent, ReactElement } from 'react'

import { useReceiptsFilters } from '../../../../hooks/useReceiptsFilters'
import { ReceiptFilterType } from '../../../Receipt'
import { ReceiptsSearch } from '../../../ReceiptsSearch'
import { ReceiptsSelectAllAction } from '../../../ReceiptsSelectAllAction'
import * as Styled from './styles'

interface ReceiptsLeftActionsProps {
  areAllReceiptsSelected: boolean
  onSelectAllActionChange: (event: ChangeEvent<HTMLInputElement>) => void
  selectedReceiptsCount: number
}

export const ReceiptsLeftActions = ({
  areAllReceiptsSelected,
  onSelectAllActionChange,
  selectedReceiptsCount,
}: ReceiptsLeftActionsProps): ReactElement => {
  const [{ type }] = useReceiptsFilters()

  const isSelectable = type === ReceiptFilterType.Upload

  return (
    <Styled.ReceiptsLeftActionsWrapper>
      {isSelectable && (
        <ReceiptsSelectAllAction
          isSelected={areAllReceiptsSelected}
          onSelectAllCheckboxChange={onSelectAllActionChange}
          selectedReceiptsCount={selectedReceiptsCount}
        />
      )}
      <ReceiptsSearch />
    </Styled.ReceiptsLeftActionsWrapper>
  )
}
