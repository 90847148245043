import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@components-deprecated'
import { Button } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { InboxModalProps } from '../../types'

type DeleteModalProps = InboxModalProps &
  ModalProps & {
    onConfirm?: () => void
    heading?: string
  }

export const DeleteModal = ({
  heading,
  disabled,
  isOpen,
  onCancel = () => null,
  onConfirm = () => null,
  ...rest
}: DeleteModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} {...rest} onClose={onCancel}>
      <ModalHeader heading={heading || t('voucher.inbox.modals.delete.delete_voucher')} />
      <ModalBody>{t('voucher.inbox.modals.delete.delete_info')}</ModalBody>
      <ModalFooter>
        <Button disabled={disabled} onClick={onCancel} variant="text">
          {t('voucher.inbox.modals.delete.cancel')}
        </Button>
        <Button danger disabled={disabled} onClick={onConfirm}>
          {t('voucher.inbox.modals.delete.delete')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
