import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import React from 'react'

import { Themable } from '../../types/themable'
import { Theme } from '../../types/theme'
import { IconCheck } from '../Icons'

type CheckmarkContainerProps = Themable & CheckmarkProps

const CheckmarkContainer = styled.div<CheckmarkContainerProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.forms.lightBackground};
  border: 1px solid ${({ theme }) => theme.colors.forms.border};
  margin-right: 8px;
  backface-visibility: hidden; // soften border edges

  ${({ checked, theme }) => {
    if (checked) {
      return `
      background-color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
      `
    }
  }}
`

type CheckmarkProps = {
  checked?: boolean
}
export const Checkmark = ({ checked }: CheckmarkProps) => {
  const theme = useTheme<Theme>()

  return (
    <CheckmarkContainer checked={checked}>
      {checked && <IconCheck color={theme.colors.white} width={10} height={110} />}
    </CheckmarkContainer>
  )
}
