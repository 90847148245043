import { EmptyView, emptyViewRoutes, EmptyViewType, emptyViewVariants, ProtectableProps } from '@components'
import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { QueryClientProvider } from 'react-query'

import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { TrackingContext } from '../../enums/trackingContext'
import { EmberEventFn } from '../../types/emberEventFn'

class EmptyViewWebComponent extends DOMModel {
  @byAttrVal type: EmptyViewType
  @byAttrVal scopes: string
  @byAttrVal trackingContext: TrackingContext
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function | undefined
  @registerEvent('emptyViewButtonClick') emptyViewButtonClick: EmberEventFn | undefined
}

interface EmptyViewProps extends Partial<ProtectableProps> {
  type: EmptyViewType
  onNavigate?: EmberNavigate
  onEmptyViewButtonClick?: EmberEventFn
  trackingContext?: TrackingContext
  organizationId: string
}

function EmptyViewModule({
  type,
  onNavigate,
  onEmptyViewButtonClick,
  scopes,
  trackingContext,
  organizationId,
}: EmptyViewProps): ReactElement {
  const route = useMemo(() => emptyViewRoutes[type], [type])

  const handleClick = useCallback(() => {
    onNavigate?.({ detail: { route } })
    onEmptyViewButtonClick?.({ detail: {} })
  }, [onNavigate, route, onEmptyViewButtonClick])

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ModalContextProvider>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <CurrentUserContextProvider>
              <EmptyView
                animation={emptyViewVariants[type].animation}
                buttonIcon={emptyViewVariants[type]?.buttonIcon}
                onClick={handleClick}
                scopes={scopes}
                showCTA={!!emptyViewVariants[type].buttonText?.length}
                tButton={emptyViewVariants[type].buttonText}
                tDescription={emptyViewVariants[type].description}
                trackingContext={trackingContext}
                tTitle={emptyViewVariants[type].title}
              />
            </CurrentUserContextProvider>
          </UserOrganizationContextProvider>
        </ModalContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const EmptyViewCustomElement = createCustomElement(EmptyViewModule, EmptyViewWebComponent, 'element')

customElements.get('empty-view-module') || customElements.define('empty-view-module', EmptyViewCustomElement)

export default EmptyViewCustomElement
