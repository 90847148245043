import { AnyAction } from 'redux'

import { ACCOUNTS_RECEIVED, ACCOUNTS_REQUESTED, BANK_ACCOUNT_SELECTED } from './actions'
import { Account } from './types'

export type AccountsRequestedProps = {
  organizationId: string
}

export type AccountsRequested =
  | AnyAction
  | {
      payload: {}
      type: typeof ACCOUNTS_REQUESTED
    }

export type AccountsReceived =
  | AnyAction
  | {
      payload: Account[]
      type: typeof ACCOUNTS_RECEIVED
    }

export type BankAccountSelected =
  | AnyAction
  | {
      payload: Account
      type: typeof BANK_ACCOUNT_SELECTED
    }

export function accountsRequested({ organizationId }: AccountsRequestedProps) {
  return {
    payload: { organizationId },
    type: ACCOUNTS_REQUESTED,
  }
}

export function accountsReceived(accounts: Account[]): AccountsReceived {
  return {
    payload: accounts,
    type: ACCOUNTS_RECEIVED,
  }
}

export function bankAccountSelected(account: Account): BankAccountSelected {
  return {
    payload: account,
    type: BANK_ACCOUNT_SELECTED,
  }
}
