import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const BillsSummaryWrapper = styled.section`
  margin-bottom: ${Spacing.XXL};
`

export const Title = styled(Text)`
  margin-bottom: ${Spacing.L};
`
