import { NavItem } from '@design-system'

import omit from 'lodash/omit'

import { Contact } from '@views/contacts/types/contact'

import { ContactValue } from '../types/contactValue'

export const getContactValue = (contact: NavItem<ContactValue>): Partial<Contact> => {
  return omit(contact.value, 'displayData')
}
