import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const NewReceiptWrapper = styled.section`
  height: 486px;
  padding: ${Spacing.XL};
  border-radius: ${DefaultValue.BorderRadius};
  background-color: ${Color.White};
`
