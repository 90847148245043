import { useMutation } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { APIError } from '../../../utils'
import { connectExistingSalaryAccount, ConnectExistingSalaryAccountResponseData } from '../query-api'

interface UseConnectExistingSalaryAccount {
  onError?: (error?: APIError) => void
  onSettled?: (data: ConnectExistingSalaryAccountResponseData | undefined, error: APIError | null | undefined) => void
  onSuccess?: (data: ConnectExistingSalaryAccountResponseData) => void
}

export const useConnectExistingSalaryAccount = (props?: UseConnectExistingSalaryAccount) => {
  const { onError, onSuccess, onSettled } = props || {}
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()

  const { mutate: connectAccount, ...rest } = useMutation(
    () =>
      connectExistingSalaryAccount({
        organizationId: organization?.id as string,
        registrationNo: organization?.registrationNo as string,
        userEmail: user?.email as string,
        redirectUrl: window.location.href,
      }),
    {
      onSuccess,
      onError,
      onSettled,
    },
  )

  return { connectAccount, ...rest }
}
