import { Button, ButtonsGroup, Checkbox, Modal, useModal } from '@design-system'

import { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../enums/modalId'
import { ModalBody } from './elements/ModalBody'
import * as Styled from './styles'

interface ApproveInvoiceModalProps {
  onModalClose?: () => void
  onApproveInvoice?: (shouldNeverShowAgain: boolean) => void
}

export const ApproveInvoiceModal = ({ onModalClose, onApproveInvoice }: ApproveInvoiceModalProps): ReactElement => {
  const [shouldNeverShowAgain, setShouldNeverShowAgain] = useState(false)
  const { t } = useTranslation()

  const handleModalClose = useCallback(() => {
    onModalClose?.()
  }, [onModalClose])

  const { close } = useModal(ModalId.ApproveInvoiceModal, { onClose: handleModalClose })

  const handleBackToDraftButtonClick = useCallback(() => close(), [close])

  const handleCheckboxChange = useCallback(() => {
    setShouldNeverShowAgain((prevState) => !prevState)
  }, [])

  const handleApproveInvoiceButtonClick = useCallback(() => {
    if (onApproveInvoice) {
      onApproveInvoice(shouldNeverShowAgain)
    }
    close()
  }, [close, shouldNeverShowAgain, onApproveInvoice])

  return (
    <Modal id={ModalId.ApproveInvoiceModal} size="s" forceRender closable>
      <Modal.Header title={t('approve_invoice_modal.title')} />
      <Modal.Body>
        <ModalBody />
      </Modal.Body>
      <Modal.Footer>
        <Styled.FooterContentWrapper>
          <Checkbox checked={shouldNeverShowAgain} onChange={handleCheckboxChange}>
            {t('approve_invoice_modal.dismiss_checkbox')}
          </Checkbox>
          <ButtonsGroup>
            <Button variant="text" onClick={handleBackToDraftButtonClick}>
              {t('approve_invoice_modal.back_to_draft')}
            </Button>
            <Button onClick={handleApproveInvoiceButtonClick}>{t('approve_invoice_modal.approve')}</Button>
          </ButtonsGroup>
        </Styled.FooterContentWrapper>
      </Modal.Footer>
    </Modal>
  )
}
