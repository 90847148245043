import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../../../utils'
import { updateRuleset } from '../../../../../query-api'
import { Ruleset } from '../../../../../types/ruleset'

interface UseUpdateRuleset {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface UpdateRulesetProps {
  id: string
  payload: Partial<Ruleset>
}

export const useUpdateRuleset = (props?: UseUpdateRuleset) => {
  const { onError, onSuccess } = props || {}

  const {
    mutate: update,
    isLoading: isProcessing,
    ...rest
  } = useMutation(({ id, payload }: UpdateRulesetProps) => updateRuleset(id, payload), {
    onError,
    onSuccess,
  })

  return { update, isProcessing, ...rest }
}
