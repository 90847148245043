import React, { ChangeEvent, createContext, ReactElement, ReactNode, useContext, useMemo } from 'react'

interface ContextState {
  name?: string
  disabled?: boolean
  value?: string[]
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const defaultValue: ContextState = {}

const SwitchGroup = createContext<ContextState>(defaultValue)

interface SwitchGroupProps extends ContextState {
  children?: ReactNode
}

export const SwitchGroupProvider = ({
  children,
  name,
  disabled,
  value: currentValue,
  onChange,
}: SwitchGroupProps): ReactElement => {
  const value = useMemo(
    () => ({
      name,
      disabled,
      value: currentValue,
      onChange,
    }),
    [name, disabled, currentValue, onChange],
  )

  return <SwitchGroup.Provider value={value}>{children}</SwitchGroup.Provider>
}

export const useSwitchGroup = () => useContext(SwitchGroup)
