import { useMemo } from 'react'

import { PeriodMode } from '../../../enums/periodMode'
import { useQueryParams } from '../../../hooks/useQueryParams'

interface SalesTaxListFilters {
  period: string
}

type SalesTaxListFiltersResponse = [SalesTaxListFilters, (filters: Partial<SalesTaxListFilters>) => void]

const getDefaultQuery = (): SalesTaxListFilters => {
  const dateNow = new Date()

  return {
    period: `${PeriodMode.Month}:${dateNow.getFullYear()}-${dateNow.getMonth() + 1}`,
  }
}

export const useSalesTaxListFilters = (): SalesTaxListFiltersResponse => {
  const [queryParams, setQueryParams] = useQueryParams<SalesTaxListFilters>({
    defaultQuery: getDefaultQuery(),
  })

  return useMemo(() => [queryParams, setQueryParams], [queryParams, setQueryParams])
}
