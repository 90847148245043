import React, { ReactElement } from 'react'

export const IndeterminateIcon = (): ReactElement => {
  return (
    <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666748 1.00032C0.666748 0.540087 1.03984 0.166992 1.50008 0.166992H8.50008C8.96031 0.166992 9.33341 0.540087 9.33341 1.00032C9.33341 1.46056 8.96031 1.83365 8.50008 1.83365H1.50008C1.03984 1.83365 0.666748 1.46056 0.666748 1.00032Z"
        fill="#002E33"
      />
    </svg>
  )
}
