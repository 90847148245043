import { css, SerializedStyles } from '@emotion/core'

import { Color } from '../../../enums/color'
import { StyledComponent } from '../../../types/styledComponent'

export const getDisabledStyle = (
  Control: StyledComponent,
  Label: StyledComponent,
  checked: boolean,
): SerializedStyles => css`
  ${Control} {
    ${checked
      ? css`
          background-color: ${Color.Gray20};
        `
      : css`
          background-color: ${Color.Gray20};
        `};
  }

  ${Label} {
    opacity: 0.5;
  }

  cursor: not-allowed;
`
