import { Color, getDefaultTransition } from '@design-system'

import { css } from '@emotion/core'
import { transparentize } from 'polished'

export const SIZE = '8px'
export const COLOR = transparentize(0.7, Color.Gray90)
export const COLOR_HOVER = transparentize(0.5, Color.Gray90)

export const scrollbars = css`
  ::-webkit-scrollbar {
    width: ${SIZE};
    height: ${SIZE};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: ${SIZE};
    background-color: ${COLOR};
    cursor: pointer;
    ${getDefaultTransition('background-color')};

    &:hover {
      background-color: ${COLOR_HOVER};
    }
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
`
