import { Color, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const QuestionWrapper = styled.div`
  margin-bottom: ${Spacing.XXL};
  padding-bottom: ${Spacing.XXL};
  border-bottom: 1px solid ${Color.Gray30};
`

export const TitleWrapper = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
`

export const Title = styled(Text)`
  width: 100%;
  margin-bottom: ${Spacing.XL};
`

export const SideActions = styled.div`
  margin-left: auto;
`
