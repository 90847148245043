import { Spacing, Text } from '@design-system'

import styled from '@emotion/styled'

export const SectionMessageContainer = styled.div`
  margin-top: ${Spacing.L};
`

export const RenewalMessage = styled(Text)`
  margin-top: ${Spacing.L};
`
