import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../enums/queryKeys'
import { fetchBankLoanData, Lending } from '../query-api'

interface ContextState {
  bankLoadData: Lending | undefined
  isLoading: boolean
}

const BankLoadDataContext = createContext<ContextState | undefined>(undefined)

interface BankLoadDataContextProps {
  children?: ReactNode
  organizationId?: string
}

export const BankLoadDataContextProvider = ({
  children,
  organizationId = '',
}: BankLoadDataContextProps): ReactElement | null => {
  const { data, isLoading } = useQuery([QueryKeys.BankLoan, organizationId], () => fetchBankLoanData(organizationId), {
    enabled: !!organizationId,
  })

  return (
    <BankLoadDataContext.Provider value={{ bankLoadData: data, isLoading }}>{children}</BankLoadDataContext.Provider>
  )
}

export const useBankLoanData = () => {
  const context = useContext(BankLoadDataContext)

  if (!context) {
    throw new Error('BankLoadDataContextProvider is missing in the module!')
  }

  return context
}
