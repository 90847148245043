import { Color, SkeletonText, Space, Text } from '@design-system'

import React, { ReactElement, useLayoutEffect, useRef } from 'react'

import { DateFormatter } from '../../../TableCellFormatters'
import { HIGHLIGHT_NOTE_CLASS_NAME } from '../../constants/highlightNoteClassName'
import { Note } from '../../types/note'
import * as Styled from './styles'

interface NoteItemProps {
  note?: Note
  isNew?: boolean
  isLoading?: boolean
}

export const NoteItem = ({ note, isLoading, isNew }: NoteItemProps): ReactElement => {
  const wrapperRef = useRef<HTMLLIElement>(null)

  useLayoutEffect(() => {
    if (isNew && wrapperRef.current) {
      wrapperRef.current.classList.add(HIGHLIGHT_NOTE_CLASS_NAME)
    }
  }, [isNew])

  const noteDate = note?.date ? (
    <Styled.NoteDateWrapper>
      <Text variant="micro" color={Color.Gray60}>
        <DateFormatter value={note.date} dateFormat="MMM. d yyyy" />
      </Text>
    </Styled.NoteDateWrapper>
  ) : (
    '—'
  )

  const noteAuthor = note?.author ? (
    <Text variant="micro" weight="medium" color={Color.Gray60} title={note.author} truncate>
      {note.author}
    </Text>
  ) : (
    '—'
  )

  return (
    <Styled.NoteItemWrapper ref={wrapperRef}>
      <Styled.NoteHeader>
        {isLoading ? <SkeletonText variant="micro" width={70} /> : noteDate}
        <Styled.NoteAuthorWrapper>
          {isLoading ? <SkeletonText variant="micro" width={70} /> : noteAuthor}
        </Styled.NoteAuthorWrapper>
      </Styled.NoteHeader>
      <Space size="xxs" />
      <Styled.NoteContent>
        {isLoading ? (
          <>
            <SkeletonText fullWidth />
            <Space size="xxs" />
            <SkeletonText width={200} />
            <Space size="xxs" />
            <SkeletonText fullWidth />
          </>
        ) : (
          <Text>{note?.description}</Text>
        )}
      </Styled.NoteContent>
    </Styled.NoteItemWrapper>
  )
}
