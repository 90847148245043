import { DATE_PICKER_YEAR_DROPDOWN_ID, Dropdown, DropdownProps, Timeout } from '@design-system'

import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { PeriodValue } from '../../../../../types/periodValue'
import { CUSTOM_DATE_END_DATE_ID, CUSTOM_DATE_START_DATE_ID } from '../../constants/customDateIds'
import { PERIOD_DROPDOWN_WIDTH } from '../../constants/periodDropdownWidth'
import { usePeriodSelectContext } from '../../contexts/periodSelectContext'
import { PeriodButton } from '../PeriodButton'
import { PeriodDropdown } from '../PeriodDropdown'

const UPDATE_STATE_AFTER_CLOSING_DROPDOWN_TIMEOUT_MS = 50

export interface PeriodSelectProps extends Pick<DropdownProps, 'placement' | 'onOpen' | 'onClose'> {
  onSave?: (periodValue?: PeriodValue) => void
  fullWidth?: boolean
}

export const PeriodSelectContent = ({
  fullWidth,
  onClose,
  onOpen,
  onSave,
  placement,
}: PeriodSelectProps): ReactElement => {
  const { periodValueSaved, updatePeriodState } = usePeriodSelectContext()
  const [isOpen, setIsOpen] = useState(false)
  const closingDropdownTimeoutRef = useRef<Timeout>()

  useEffect(() => {
    return () => {
      if (closingDropdownTimeoutRef.current) {
        clearTimeout(closingDropdownTimeoutRef.current)
      }
    }
  }, [])

  const open = useCallback(() => {
    setIsOpen(true)
    onOpen?.()
  }, [onOpen])

  const close = useCallback(() => {
    setIsOpen(false)
    onClose?.()
  }, [onClose])

  const handleOpen = useCallback(() => {
    open()
  }, [open])

  const handleClose = useCallback(() => {
    close()

    // we don't want to show changing state for the user, so we do this action a little after closing dropdown
    closingDropdownTimeoutRef.current = setTimeout(() => {
      updatePeriodState(periodValueSaved)
    }, UPDATE_STATE_AFTER_CLOSING_DROPDOWN_TIMEOUT_MS)
  }, [close, periodValueSaved, updatePeriodState])

  const handleSave = useCallback(
    (periodValue?: PeriodValue) => {
      onSave?.(periodValue)
      close()
    },
    [close, onSave],
  )

  return (
    <Dropdown
      childrenIds={[CUSTOM_DATE_START_DATE_ID, CUSTOM_DATE_END_DATE_ID, DATE_PICKER_YEAR_DROPDOWN_ID]}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      placement={placement}
      trigger={<PeriodButton fullWidth={fullWidth} />}
      width={PERIOD_DROPDOWN_WIDTH}
    >
      <PeriodDropdown onSave={handleSave} />
    </Dropdown>
  )
}
