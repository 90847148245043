import { Modal } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../enums/modalId'
import { InviteBookkeeperModalContextProvider } from './contexts/inviteBookkeeperModalContext'
import { InvitationForm } from './elements/InvitationForm'
import { ModalFooter } from './elements/ModalFooter'

export const InviteBookkeeperModal = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <InviteBookkeeperModalContextProvider>
      <Modal closable id={ModalId.OrganizationSettingsInviteBookkeeper} size="s">
        <Modal.Header title={t('settings.organization.users.invite_bookkeeper_modal.title')} />
        <Modal.Body>
          <InvitationForm />
        </Modal.Body>
        <Modal.Footer>
          <ModalFooter />
        </Modal.Footer>
      </Modal>
    </InviteBookkeeperModalContextProvider>
  )
}
