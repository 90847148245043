import { withModalConditionalRender } from '@design-system'

import React, { ReactElement } from 'react'

import { ModalId } from '../../../../enums/modalId'
import { DisableMobilePayModal as DisableMobilePay } from './elements/DisableMobilePayModal'
import { EnableMobilePayModal as EnableMobilePay } from './elements/EnableMobilePayModal'

const EnableMobilePayModal = withModalConditionalRender(EnableMobilePay)
const DisableMobilePayModal = withModalConditionalRender(DisableMobilePay)

export const MobilePayModalsGroup = (): ReactElement => (
  <>
    <EnableMobilePayModal id={ModalId.EnableMobilePayModal} />
    <DisableMobilePayModal id={ModalId.DisableMobilePayModal} />
  </>
)
