import React, { memo, ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useLazyImage } from '../../../hooks/useLazyImage'
import { getSizeString, getThumbnailUrl } from '../../../utils/getThumbnailUrl'
import { FileErrorThumbnail } from '../FileThumbnails'
import { FitType } from '../types'
import * as Styled from './styles'

const DEFAULT_ALT = 'Image Preview'
const IMAGE_PERCENT_SENSITIVITY_CHANGE = 10

const shouldRefetchImage = (oldWith: number, oldHeight: number, newWidth: number, newHeight: number) => {
  if (oldWith > newWidth && oldHeight > newHeight) {
    return false
  }

  if ((!oldWith && newWidth) || (!oldHeight && newHeight)) {
    return true
  }

  const widthPercent = (newWidth * 100) / oldWith
  const heightPercent = (newHeight * 100) / oldHeight
  const widthDiff = Math.abs(100 - widthPercent)
  const heightDiff = Math.abs(100 - heightPercent)

  return widthDiff > IMAGE_PERCENT_SENSITIVITY_CHANGE || heightDiff > IMAGE_PERCENT_SENSITIVITY_CHANGE
}

type ImagePreviewProps = {
  alt?: string
  circle?: boolean
  fitToHeight?: boolean
  fitToWidth?: boolean
  fitType: FitType
  height?: number
  onError?: () => void
  onLoad?: () => void
  rotation?: number
  src: string
  srcZoom?: string
  width?: number
  zoomable?: boolean
  zoomScale?: number
}

export const ImagePreview = memo(
  ({
    alt = DEFAULT_ALT,
    circle = false,
    fitToHeight,
    fitType,
    height = 0,
    onError,
    onLoad,
    rotation,
    src,
    srcZoom,
    width = 0,
    zoomable,
    zoomScale,
  }: ImagePreviewProps): ReactElement => {
    const { t } = useTranslation()
    const [imageWidth, setImageWidth] = useState(0)
    const [imageHeight, setImageHeight] = useState(0)

    useEffect(() => {
      const shouldRefetch = shouldRefetchImage(imageWidth, imageHeight, width, height)

      if ((!height && !width) || !shouldRefetch) {
        return
      }

      setImageHeight(height)
      setImageWidth(width)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height, width])

    const shouldFetchImage = Boolean(imageWidth || imageHeight)

    const placeholderSrc = useMemo(
      () =>
        getThumbnailUrl(src, {
          size: '50',
          fit: fitType,
          rotate: rotation,
        }),
      [src, fitType, rotation],
    )

    const finalSrc = useMemo(
      () =>
        shouldFetchImage
          ? getThumbnailUrl(src, { size: getSizeString(imageWidth, imageHeight), fit: fitType, rotate: rotation })
          : '',
      [shouldFetchImage, src, imageWidth, imageHeight, fitType, rotation],
    )

    const { loading, src: currentSrc, isError } = useLazyImage(placeholderSrc, finalSrc, onLoad, onError)

    return (
      <>
        {isError ? (
          <FileErrorThumbnail title={t('attachments.thumbnail_error.cant_load')} />
        ) : (
          <Styled.Image
            src={currentSrc}
            alt={alt}
            circle={circle}
            isLoading={loading}
            zoomable={zoomable}
            zoomScale={zoomScale}
            zoomImageSrc={srcZoom}
            objectFit={fitType}
            fitToHeight={fitToHeight}
          />
        )}
      </>
    )
  },
)
