import { useCallback } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../config/queryClient'
import { QueryKeys } from '../../../enums/queryKeys'
import { useDeletePaymentMethod } from '../../../hooks/useDeletePaymentMethod'
import { useEmberIntegration } from '../context/IntegrationContext'
import { PaymentMethodType } from '../enums/paymentMethodType'
import { usePaymentMethodId } from './usePaymentMethodId'

interface UseDeletePaymentMethodProps {
  paymentMethodType: PaymentMethodType
  onSuccess?: () => void
  onError?: () => void
}

export const useDisablePaymentMethod = ({ paymentMethodType, onSuccess, onError }: UseDeletePaymentMethodProps) => {
  const { organization } = useUserOrganization()
  const { reloadPaymentMethods } = useEmberIntegration()
  const { delete: deletePaymentMethod, isProcessing } = useDeletePaymentMethod({
    onSuccess: () => {
      onSuccess?.()
      queryClient.invalidateQueries(QueryKeys.PaymentMethods)
      reloadPaymentMethods()
    },
    onError: () => {
      onError?.()
    },
  })

  const { paymentMethodId } = usePaymentMethodId(paymentMethodType)

  const disablePaymentMethod = useCallback(() => {
    if (!organization?.id || !paymentMethodId) {
      return
    }

    deletePaymentMethod({ organizationId: organization.id, paymentMethodId })
  }, [deletePaymentMethod, paymentMethodId, organization?.id])

  return { disablePaymentMethod, isProcessing }
}
