export enum FileType {
  Image = 'image',
  Pdf = 'pdf',
}

export type PreviewFile = {
  alt?: string
  src: string
  srcDownload?: string
  srcZoom?: string
}

export type FitType = 'contain' | 'cover'
