import { SkeletonBox, SkeletonButton, SkeletonText, Space } from '@design-system'

import { ReactElement } from 'react'

import * as Styled from './styles'

export const SkeletonIntegrationCard = (): ReactElement => (
  <Styled.SkeletonIntegrationCardWrapper>
    <Styled.SkeletonCardContentWrapper>
      <Styled.SkeletonCardImageWrapper>
        <SkeletonBox fullWidth fullHeight />
      </Styled.SkeletonCardImageWrapper>
      <SkeletonText fullWidth variant="h3" />
      <Space size="l" />
      <Styled.SkeletonCardDescriptionWrapper>
        <SkeletonText width={170} variant="micro" />
        <Space size="s" />
        <SkeletonText width={200} variant="micro" />
        <Space size="s" />
        <SkeletonText width={180} variant="micro" />
        <Space size="s" />
        <SkeletonText width={120} variant="micro" />
      </Styled.SkeletonCardDescriptionWrapper>
    </Styled.SkeletonCardContentWrapper>
    <Styled.SkeletonCardActionsWrapper>
      <SkeletonButton fullWidth />
    </Styled.SkeletonCardActionsWrapper>
  </Styled.SkeletonIntegrationCardWrapper>
)
