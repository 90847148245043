import { notify } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'
import { APIError } from 'src/utils'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { QueryKeys } from '../../../enums/queryKeys'
import { deleteReceiptsBulk } from '../query-api'

interface UseReceiptsDeleteProps {
  onSuccess?: () => void
}

export const useReceiptsDelete = ({ onSuccess }: UseReceiptsDeleteProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const mutationOptions: UseMutationOptions<unknown, APIError, string[], unknown> = {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.ReceiptsList)
      queryClient.invalidateQueries(QueryKeys.AttachmentsCount)

      notify({
        id: NotificationKeys.ReceiptsList,
        message: t('receipt.deleted'),
        variant: 'success',
      })
      onSuccess?.()
    },
    onError: (error) => {
      notify({
        id: NotificationKeys.ReceiptsList,
        message: error?.body?.errorMessage || t('receipt.deleted.error'),
        variant: 'error',
      })
    },
  }

  const deleteFilesMutation = useMutation(async (receiptsIds: string[]) => {
    await deleteReceiptsBulk(receiptsIds)
  }, mutationOptions)

  const deleteReceipts = useCallback(
    (receiptsIds: string[]) => {
      deleteFilesMutation.mutate(receiptsIds)
    },
    [deleteFilesMutation],
  )

  return { isDeleting: deleteFilesMutation.isLoading, deleteReceipts }
}
