import { ReactElement } from 'react'

import { ExternalInvoicesSyncStatus } from '../../enums/externalInvoicesSyncStatus'
import { useExternalInvoicesSyncProgress } from './contexts/externalInvoicesSyncProgressContext'
import { SyncCompletedTopBar } from './elements/SyncCompletedTopBar'
import { SyncErrorTopBar } from './elements/SyncErrorTopBar'
import { SyncProgressTopBar } from './elements/SyncProgressTopBar'

const syncStatusToComponent: Record<ExternalInvoicesSyncStatus, ReactElement | null> = {
  [ExternalInvoicesSyncStatus.NotInitiated]: null,
  [ExternalInvoicesSyncStatus.InProgress]: <SyncProgressTopBar />,
  [ExternalInvoicesSyncStatus.VatRateError]: <SyncErrorTopBar />,
  [ExternalInvoicesSyncStatus.Completed]: <SyncCompletedTopBar />,
}

export const ExternalInvoicesSyncTopBar = (): ReactElement | null => {
  const { syncStatus } = useExternalInvoicesSyncProgress()

  if (!syncStatus) {
    return null
  }

  return syncStatusToComponent[syncStatus]
}
