import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { IconTrustPilotLogo, IconTrustPilotStars } from '../../../Icons/icons'
import * as Styled from './styles'

export const TrustpilotRating = () => {
  const { t } = useTranslation()

  return (
    <Styled.TrustpilotRatingWrapper>
      <IconTrustPilotStars />

      <Styled.Text>{t('trustpilot.rating')}</Styled.Text>

      <IconTrustPilotLogo />
    </Styled.TrustpilotRatingWrapper>
  )
}
