import colors from './colors'

const defaultStyles = {
  cursor: 'pointer',
  alignItems: 'center',
}

export default {
  primary: {
    ...defaultStyles,
    color: colors.primary,
    '& div input ~ svg, & div input:focus ~ svg, & div input:checked ~ svg': {
      color: colors.primary,
    },
  },
  normal: {
    ...defaultStyles,
    color: colors.gray5,
    '& div input ~ svg, & div input:focus ~ svg, & div input:checked ~ svg': {
      color: colors.gray5,
    },
  },
  danger: {
    ...defaultStyles,
    color: colors.redA,
    '& div input ~ svg, & div input:focus ~ svg, & div input:checked ~ svg': {
      color: colors.redA,
    },
  },
  disabled: {
    ...defaultStyles,
    color: colors.grayA,
    cursor: 'inital',
    '& div input ~ svg, & div input:focus ~ svg, & div input:checked ~ svg': {
      color: colors.grayA,
    },
  },
}
