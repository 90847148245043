import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import { AutoCompletedFields } from '../../../../../types/formWithAutoCompletedFields'
import { isUndefinedOrEmptyObject } from '../../../../../utils/isUndefinedOrEmptyObject'
import {
  BillForm,
  BillFormBasic,
  BillFormLineKeys,
  BillFormPartial,
  getDefaultBillLine,
  getDefaultValues,
} from './formData'
import { GetShouldOverridenParamChange } from './getShouldOverridenParamChange'

interface ShouldOverrvideValueProps {
  object: Record<string, any>
  property: any
  valueCurrent: any
  valueOverriden: any
  defaultValue: any
}

const shouldOverrideValue = ({
  object,
  property,
  valueCurrent,
  valueOverriden,
  defaultValue,
}: ShouldOverrvideValueProps) =>
  Object.prototype.hasOwnProperty.call(object, property) &&
  !isUndefinedOrEmptyObject(valueOverriden) &&
  !isEqual(valueOverriden, defaultValue) &&
  (isUndefinedOrEmptyObject(valueCurrent) || isEqual(valueCurrent, defaultValue))

export interface AutoCompleteBillFormProps {
  billFormDefault: BillForm
  billFormOverrides: BillFormPartial
  baseCurrencyId?: string
  onlyFirstBillLine?: boolean
  tagChanged?: boolean
  shouldOverridenParamChangeCallback?: GetShouldOverridenParamChange
}

export const autoCompleteBillForm = ({
  billFormDefault,
  billFormOverrides,
  baseCurrencyId,
  onlyFirstBillLine,
  shouldOverridenParamChangeCallback,
}: AutoCompleteBillFormProps): BillForm => {
  const billForm: BillForm = cloneDeep(billFormDefault)
  const autoCompletedFields: AutoCompletedFields<BillFormBasic> = {}
  const billDefaultValues = getDefaultValues(baseCurrencyId)
  const billDefaultBillLine = getDefaultBillLine()

  for (const property in billForm) {
    if (property === 'billLines') {
      continue
    }

    const valueCurrent = billForm[property]
    const valueOverriden = billFormOverrides[property]
    const defaultValue = billDefaultValues[property]
    const hasBeenAlreadyAutoCompleted = billForm?.autoCompletedFields?.[property]

    const shouldOverride = shouldOverrideValue({
      object: billDefaultValues,
      property,
      valueCurrent,
      valueOverriden,
      defaultValue,
    })

    if (shouldOverride || (hasBeenAlreadyAutoCompleted && valueOverriden !== undefined)) {
      billForm[property] = valueOverriden
      autoCompletedFields[property] = true
    }
  }

  if (billForm.billLines?.length && billFormOverrides.billLines?.length) {
    for (let i = 0; i < billForm.billLines.length; i++) {
      const isFirstLine = i === 0

      if (onlyFirstBillLine && !isFirstLine) {
        break
      }

      for (const property in billForm.billLines[i]) {
        const valueCurrent = billForm.billLines[i][property]
        const valueOverriden = (billFormOverrides.billLines[i] || billFormOverrides.billLines[0])?.[property]
        const defaultValue = billDefaultBillLine[property]
        const autoCompletedProperty = `billLines.${i}.${property}`
        const hasBeenAlreadyAutoCompleted = billForm?.autoCompletedFields?.[autoCompletedProperty]
        const propertiesLocked = billForm.billLines[i].lockedByBookkeepingTag

        const shouldOverwriteValue =
          hasBeenAlreadyAutoCompleted ||
          shouldOverrideValue({
            object: billDefaultBillLine,
            property,
            valueCurrent,
            valueOverriden,
            defaultValue,
          })

        const shouldParamChange = shouldOverridenParamChangeCallback
          ? shouldOverridenParamChangeCallback({
              property: property as BillFormLineKeys,
              shouldOverwriteValue,
              propertiesLocked,
              hasValueOverriden: !!valueOverriden,
            })
          : shouldOverwriteValue

        if (shouldParamChange) {
          billForm.billLines[i][property] = valueOverriden
          autoCompletedFields[autoCompletedProperty] = !!valueOverriden
        }
      }
    }
  }

  if (!isEmpty(autoCompletedFields)) {
    billForm.autoCompletedFields = { ...(billForm.autoCompletedFields || {}), ...autoCompletedFields }
  }

  return billForm
}
