import React, { ReactElement } from 'react'

import { SearchResultItem } from '../../types/searchResultItem'
import { GlobalSearchItem } from '../GlobalSearchItem'
import { ResultsFooter } from './ResultsFooter'
import * as Styled from './styles'

interface GlobalSearchResultsProps {
  items: SearchResultItem[]
  searchValue?: string
  focusedId?: string
  onItemClick: (item: SearchResultItem) => void
}

export const GlobalSearchResults = ({
  focusedId,
  items,
  onItemClick,
  searchValue,
}: GlobalSearchResultsProps): ReactElement => (
  <Styled.GlobalSearchResultsWrapper>
    <Styled.ResultsList>
      {items.map((item) => (
        <GlobalSearchItem
          key={item.id}
          item={item}
          onClick={onItemClick}
          searchValue={searchValue}
          focusedId={focusedId}
        />
      ))}
    </Styled.ResultsList>

    <ResultsFooter />
  </Styled.GlobalSearchResultsWrapper>
)
