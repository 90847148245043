import styled from '@emotion/styled'

import { Themable } from '../../../../types/themable'

export const TrustpilotRatingWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const Text = styled.p<Themable>`
  margin: 0 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primaryGray};
`
