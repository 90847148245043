import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { defaultStaleTime } from '../../../../../config/queryClient'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { BillStatus } from '../../../enums/billStatus'
import { fetchBillsSummary } from '../../../query-api'
import { BillsSummaryData } from '../types/billsSummaryData'
import { BillsSummaryQueryProps } from '../types/billsSummaryQueryProps'

interface UseFetchBillsSummaryProps {
  contactId?: string
  period?: string
  searchQuery?: string
  status?: BillStatus
}

interface UseFetchBillsSummaryResponse {
  data?: BillsSummaryData
  isLoading: boolean
}

export const useFetchBillsSummary = ({
  contactId,
  period,
  searchQuery,
  status,
}: UseFetchBillsSummaryProps): UseFetchBillsSummaryResponse => {
  const { organization } = useUserOrganization()

  const queryProps: BillsSummaryQueryProps = useMemo(
    () => ({
      ...(contactId ? { contactId } : {}),
      ...(searchQuery ? { q: searchQuery } : {}),
      ...(period ? { entryDatePeriod: period } : {}),
      organizationId: organization?.id as string,
    }),
    [organization?.id, contactId, period, searchQuery],
  )
  const queryKey: [QueryKeys, BillsSummaryQueryProps] = useMemo(
    () => [QueryKeys.BillsSummary, queryProps],
    [queryProps],
  )

  const { data, isIdle, isLoading } = useQuery(queryKey, () => fetchBillsSummary(queryProps), {
    enabled: !!organization,
    keepPreviousData: true,
    staleTime: defaultStaleTime,
  })

  return {
    data: data?.summary,
    isLoading: isIdle || isLoading,
  }
}
