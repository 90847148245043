import React, { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { EmberEventFn } from '../../../types/emberEventFn'

interface ContextState {
  navigateToAccount: (accountId: string, startDate?: string | null, endDate?: string | null) => void
}

const ReportsEmberConnectionContext = createContext<ContextState | undefined>(undefined)

interface ReportsEmberConnectionContextProps {
  children?: ReactNode
  onNavigateToAccount: EmberEventFn
}

export const ReportsEmberConnectionContextProvider = ({
  onNavigateToAccount,
  children,
}: ReportsEmberConnectionContextProps): ReactElement => {
  const navigateToAccount = useCallback(
    (accountId: string, startDate?: string | null, endDate?: string | null) => {
      onNavigateToAccount({ detail: { props: { accountId, startDate, endDate } } })
    },
    [onNavigateToAccount],
  )

  return (
    <ReportsEmberConnectionContext.Provider value={{ navigateToAccount }}>
      {children}
    </ReportsEmberConnectionContext.Provider>
  )
}

export const useReportsEmberConnection = () => {
  const context = useContext(ReportsEmberConnectionContext)

  if (!context) {
    throw new Error('ReportsEmberConnectionContextProvider is missing in the module!')
  }

  return context
}
