import { Button, ButtonsGroup, useModals } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { NAVIGATE_LEFT_SHORTCUT, NAVIGATE_RIGHT_SHORTCUT } from '../../../../constants/shortcuts'
import { BulkMode } from '../../enums/bulkMode'
import { useBulkAttachmentsNavigation } from '../../hooks/useBulkAttachmentsNavigation'
import { useBulkBillsNavigation } from '../../hooks/useBulkBillsNavigation'
import { useBillForm } from '../BillEditableForm'

export const BillBulkEditNavigation = (): ReactElement => {
  const { bulkEditMode } = useBillForm()
  const [{ openedIds: openedModalsIds }] = useModals()

  const {
    hasPreviousBill,
    hasNextBill,
    isLoading: areBillsLoading,
    goToPreviousBill,
    goToNextBill,
  } = useBulkBillsNavigation()
  const {
    hasPreviousAttachment,
    hasNextAttachment,
    isLoading: areAttachmentsLoading,
    goToPreviousAttachment,
    goToNextAttachment,
  } = useBulkAttachmentsNavigation()

  const isPreviousDisabled = useMemo(() => {
    if (bulkEditMode === BulkMode.Attachment) {
      return !hasPreviousAttachment || areAttachmentsLoading
    }

    return !hasPreviousBill || areBillsLoading
  }, [areAttachmentsLoading, areBillsLoading, bulkEditMode, hasPreviousAttachment, hasPreviousBill])

  const isNextDisabled = useMemo(() => {
    if (bulkEditMode === BulkMode.Attachment) {
      return !hasNextAttachment || areAttachmentsLoading
    }

    return !hasNextBill || areBillsLoading
  }, [areAttachmentsLoading, areBillsLoading, bulkEditMode, hasNextAttachment, hasNextBill])

  const handleGoPrevious = useCallback(() => {
    if (!isPreviousDisabled && bulkEditMode === BulkMode.Attachment) {
      goToPreviousAttachment()
      return
    }

    goToPreviousBill()
  }, [bulkEditMode, goToPreviousAttachment, goToPreviousBill, isPreviousDisabled])

  const handleGoNext = useCallback(() => {
    if (!isNextDisabled && bulkEditMode === BulkMode.Attachment) {
      goToNextAttachment()
      return
    }

    goToNextBill()
  }, [bulkEditMode, goToNextAttachment, goToNextBill, isNextDisabled])

  useHotkeys(
    NAVIGATE_LEFT_SHORTCUT,
    handleGoPrevious,
    { enabled: !isPreviousDisabled && !openedModalsIds.length, enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] },
    [handleGoPrevious],
  )
  useHotkeys(
    NAVIGATE_RIGHT_SHORTCUT,
    handleGoNext,
    { enabled: !isNextDisabled && !openedModalsIds.length, enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] },
    [handleGoNext],
  )

  return (
    <ButtonsGroup merged>
      <Button disabled={isPreviousDisabled} icon="chevronLeft" onClick={handleGoPrevious} variant="secondary" />
      <Button disabled={isNextDisabled} icon="chevronRight" onClick={handleGoNext} variant="secondary" />
    </ButtonsGroup>
  )
}
