import { notify } from '@design-system'

import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { Origin } from '../../../enums/origin'
import { QueryKeys } from '../../../enums/queryKeys'
import { useUploadFiles, UseUploadFilesProps } from './useUploadFiles'

export const useUploadReceipts = ({ onError, onSuccess }: UseUploadFilesProps = {}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useUploadFiles({
    onError: (error) => {
      notify({
        id: NotificationKeys.ReceiptsList,
        message: error?.body?.errorMessage || t('file.rejected'),
        variant: 'error',
      })

      onError?.(error)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.AttachmentsCount)
      queryClient.invalidateQueries(QueryKeys.ReceiptsList)

      notify({
        id: NotificationKeys.ReceiptsList,
        message: t('file.uploaded'),
        variant: 'success',
      })

      onSuccess?.()
    },
    options: {
      fileOrigin: Origin.Web,
      'x-create-attachment': true,
      'x-create-variants': true,
      'x-should-scan': true,
    },
  })
}
