import { useMemo } from 'react'

import { useAccounts } from '@modules-deprecated/app/accounts/accountsContext'
import { Account } from '@modules-deprecated/app/accounts/types'

interface UseBankAccountsResponse {
  bankAccounts: Account[]
  isLoading: boolean
}

export const useBankAccounts = (): UseBankAccountsResponse => {
  const { accounts = [], isLoading } = useAccounts()
  const bankAccounts = useMemo(() => accounts.filter(({ isBankAccount }) => !!isBankAccount), [accounts])

  return {
    bankAccounts,
    isLoading,
  }
}
