import { GlobalPeriodSelect } from '@components'

import { ReactElement } from 'react'

import { PeriodMode } from '../../../../enums/periodMode'

export const DashboardActions = (): ReactElement => (
  <GlobalPeriodSelect
    disabledPeriodModes={[PeriodMode.All, PeriodMode.Month, PeriodMode.Quarter, PeriodMode.CustomDate]}
    placement="bottom-end"
  />
)
