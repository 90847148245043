import { Color, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const BillAttachmentPreviewBlockedWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  border: 1px dashed ${Color.Gray50};
  border-radius: ${DefaultValue.BorderRadius};
  padding: ${Spacing.XXL};
  background-color: ${Color.Gray20};
`

export const CenteredContent = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
