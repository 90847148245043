import cc from 'classcat'
import React from 'react'

import { reactClass } from '../../utils'
import { IconDot, IconProps } from '../Icons'

export type StatusIconProps = IconProps & {
  status: string
}

export const StatusIcon = ({ className, status, ...rest }: StatusIconProps) => (
  <IconDot className={cc([className, reactClass('status-icon')])} width={20} height={20} color={status} {...rest} />
)
