import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchOrganizationInvitation } from '../query-api'

interface UseOrganizationInvitationProps {
  token: string
}

export const useFetchOrganizationInvitation = ({ token }: UseOrganizationInvitationProps) => {
  const { data: response, ...rest } = useQuery(
    [QueryKeys.OrganizationInvitation, token],
    () => fetchOrganizationInvitation(token),
    {
      enabled: !!token,
      retry: false, // Do not retry since most errors are: expired/not found invitation and should be handled immediately
    },
  )

  return {
    data: response?.data,
    ...rest,
  }
}
