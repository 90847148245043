import { Attachment } from '@design-system'

import { Paging } from '../../../types/metable'
import { ReceiptFillType } from '../elements/Receipt'
import { useFetchReceipts } from './useFetchReceipts'
import { useReceiptsFilters } from './useReceiptsFilters'

interface UseReceiptsResponse {
  attachments: Attachment[]
  pagination?: Paging
  isLoading: boolean
}

export const useReceipts = (): UseReceiptsResponse => {
  const [{ page, sortBy, sortDirection, type, searchQuery }] = useReceiptsFilters()
  const { attachments, isLoading, pagination } = useFetchReceipts({
    page,
    searchQuery,
    sortBy,
    sortDirection,
    fillType: ReceiptFillType.Nonfilled,
    type,
  })

  return {
    attachments,
    isLoading,
    pagination,
  }
}
