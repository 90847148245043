import { QueryKeys } from '../../../enums/queryKeys'
import { QueryProps } from '../types/queryProps'

export const getAttachmentsQueryKey = ({
  organizationId,
  page,
  pageSize,
  searchQuery,
  sortDirection,
  sortProperty,
  type,
}: QueryProps): [QueryKeys, QueryProps] => [
  QueryKeys.AttachmentsModal,
  { organizationId, page, pageSize, searchQuery, sortDirection, sortProperty, type },
]
