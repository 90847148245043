import QueryString from 'qs'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { WindowFeatures } from '../types/windowFeatures'
import { useScreenSize } from './useScreenSize'
import { useWindowPosition } from './useWindowPosition'

export type WindowTargetKeyword = '_self' | '_blank' | '_parent' | '_top'

export interface UseWindowOpenProps {
  shouldFocus?: boolean
  target?: WindowTargetKeyword | string
  url: string
  windowFeatures?: WindowFeatures
}

export interface UseWindowOpenReturn {
  isOpen: boolean
  open: () => void
  windowHandler: Window | null
}

export const useWindowOpen = ({
  shouldFocus = true,
  target = '_blank',
  url,
  windowFeatures,
}: UseWindowOpenProps): UseWindowOpenReturn => {
  const [windowHandler, setWindowHandler] = useState<Window | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const { windowCenter } = useWindowPosition()
  const { width: screenWidth, height: screenHeight } = useScreenSize()

  const featuresString = useMemo(() => {
    const areDimensionsProvided = windowFeatures?.width && windowFeatures?.height
    const isPositionProvided = windowFeatures?.top && windowFeatures?.left

    const features = {
      // default to fullscreen
      left: 0,
      top: 0,
      width: screenWidth,
      height: screenHeight,
      ...windowFeatures,
    }

    if (areDimensionsProvided && !isPositionProvided) {
      // center the popup relatively to the center of the original window
      features.left = windowCenter.left - features.width / 2
      features.top = windowCenter.top - features.height / 2

      // if the popup can fit on screen, but is placed
      // partially outside of it, correct the position
      if (features.width <= screenWidth) {
        if (features.left + features.width > screenWidth) {
          features.left = features.left - (features.left - screenWidth)
        }
      }
      if (features.height <= screenHeight) {
        if (features.top + features.height > screenHeight) {
          features.top = features.top - (features.top - screenHeight)
        }
      }
    }

    return QueryString.stringify(features, { delimiter: ',' })
  }, [windowFeatures, screenWidth, screenHeight, windowCenter.left, windowCenter.top])

  const open = useCallback(() => {
    const newWindow = window.open(url, target, featuresString)

    setWindowHandler(newWindow)

    if (shouldFocus && newWindow !== null) {
      newWindow.focus()
    }
  }, [url, target, featuresString, shouldFocus])

  useEffect(() => {
    setIsOpen(windowHandler !== null && !windowHandler?.closed)
  }, [windowHandler, windowHandler?.closed])

  return {
    isOpen,
    open,
    windowHandler,
  }
}
