import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

import { Account } from '@modules-deprecated/app/accounts/types'
import { ContactBalance } from '@modules-deprecated/app/contacts'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { DifferenceType } from '@modules-deprecated/bankReconciliation/types'
import { useFetchContact } from '@views/contacts/hooks/useFetchContact'
import { useFetchContactBalance } from '@views/contacts/hooks/useFetchContactBalance'
import { Contact } from '@views/contacts/types/contact'

import { Side } from '../../../../../enums/side'
import { useAccount } from '../../../../../hooks/useAccount'
import { Bill } from '../../../types/bill'

interface ContextState {
  canUsePrepayment?: boolean
  contact?: Contact
  contactBalance?: ContactBalance
  currencyId?: string | null
  initialBill?: Bill
  paymentDate: string | null
  selectedAccount?: Account
  selectedAccountId: string
  selectedDifferenceType?: DifferenceType
  setPaymentDate: Dispatch<SetStateAction<string | null>>
  setSelectedAccountId: Dispatch<SetStateAction<string>>
  setSelectedDifferenceType: Dispatch<SetStateAction<DifferenceType | undefined>>
}

const BillReconciliationContext = createContext<ContextState | undefined>(undefined)

interface BillReconciliationContextContextProps {
  bill?: Bill
  children?: ReactNode
}

export const BillReconciliationContextProvider = ({
  bill: initialBill,
  children,
}: BillReconciliationContextContextProps): ReactElement => {
  const { organization } = useUserOrganization()
  const [selectedDifferenceType, setSelectedDifferenceType] = useState<DifferenceType>()
  const [selectedAccountId, setSelectedAccountId] = useState<string>('')
  const [paymentDate, setPaymentDate] = useState<string | null>(null)
  const { account: selectedAccount } = useAccount(selectedAccountId)
  const { contact } = useFetchContact(initialBill?.contactId)

  const currencyId = initialBill?.currencyId
  const { contactBalance } = useFetchContactBalance(contact?.id, currencyId)

  const canUsePrepayment = !!contactBalance?.amount && contactBalance?.side === Side.Debit

  useEffect(() => {
    const initialAccountId = organization?.defaultBillBankAccountId

    if (initialAccountId) {
      setSelectedAccountId(initialAccountId)
    }
  }, [organization?.defaultBillBankAccountId])

  return (
    <BillReconciliationContext.Provider
      value={{
        canUsePrepayment,
        contact,
        currencyId,
        contactBalance,
        initialBill,
        paymentDate,
        selectedAccount,
        selectedAccountId,
        selectedDifferenceType,
        setPaymentDate,
        setSelectedAccountId,
        setSelectedDifferenceType,
      }}
    >
      {children}
    </BillReconciliationContext.Provider>
  )
}

export const useBillReconciliation = () => {
  const context = useContext(BillReconciliationContext)

  if (!context) {
    throw new Error('BillReconciliationContextProvider is missing in the module!')
  }

  return context
}
