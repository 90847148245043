import { Input as InputComponent } from '@components-deprecated'
import { Spacing } from '@design-system'

import styled from '@emotion/styled'

interface OrganizationCVRDataProps {
  visible?: boolean
}

export const OrganizationCVRData = styled.div<OrganizationCVRDataProps>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  margin-top: ${Spacing.L};
`

export const Input = styled(InputComponent)`
  margin-bottom: ${Spacing.S};
`

export const DualInputsWrapper = styled.div`
  display: flex;

  ${() => Input} {
    width: 50%;
    margin-bottom: 0;
    margin-right: ${Spacing.S};

    &:last-of-type {
      margin-right: 0;
    }
  }
`
