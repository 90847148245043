import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface ColumnProps {
  children: ReactNode
}

export const Column = ({ children }: ColumnProps): ReactElement => {
  return <Styled.ColumnWrapper>{children}</Styled.ColumnWrapper>
}
