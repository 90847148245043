export enum BillFormFieldsChangeSource {
  Bohr = 'bohr',
  Search = 'search',
  Vendor = 'vendor',
  Manually = 'manually',
}

// Tracking separate Bill form fields changes source
// main: fallback value for the whole form changes / fields resets on user value removals
// expenseCategory: expense category changes
// tag: bookkeeping tag changes (Booky)
// taxRate: tax rate changes

export interface BillFormFieldsChangeSourceMap {
  main: BillFormFieldsChangeSource
  expenseCategory?: BillFormFieldsChangeSource
  tag?: BillFormFieldsChangeSource
  taxRate?: BillFormFieldsChangeSource
}
