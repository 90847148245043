import { TFunction } from 'i18next'

import { InvoiceStatus } from '../../../../../../../enums/invoiceStatus'
import { invoiceStatusToTitleKey } from '../../../../../constants/invoiceStatusToTitleKey'

export const getInvoicesTitle = (status = InvoiceStatus.All, amount: number, t: TFunction): string => {
  const tKey = invoiceStatusToTitleKey[status]

  return t(tKey, { count: amount })
}
