import React, { ReactElement, ReactNode } from 'react'

import { Text } from '../Text'

export interface LabelProps {
  children: ReactNode
  selectable?: boolean
  id: string
}

export const Label = ({ children, id, selectable, ...rest }: LabelProps): ReactElement => (
  <Text as="label" colorVariant="secondary" htmlFor={id} selectable={selectable} inline {...rest}>
    {children}
  </Text>
)
