import { FormItemsGroup, Input, InputNumber, Textarea } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../hooks/useForm'
import { CreateOrEditAdditionalFieldFormSchema } from '../../utils/formData'

export const CreateOrEditAdditionalFieldForm = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<CreateOrEditAdditionalFieldFormSchema>()

  return (
    <>
      <FormItemsGroup itemsInRow={1}>
        <FormItem
          label={t('settings.organization.tax_return.modals.edit_field.name')}
          name="name"
          render={({ field }) => <Input {...field} />}
        />
        <FormItem
          label={t('settings.organization.tax_return.modals.edit_field.description')}
          name="description"
          render={({ field }) => <Textarea {...field} resizable={false} size="xl" />}
        />
        <FormItem
          label={t('settings.organization.tax_return.modals.edit_field.priority')}
          name="priority"
          render={({ field }) => <InputNumber {...field} withFormatting={false} keyboard />}
        />
      </FormItemsGroup>
    </>
  )
}
