import { Color, SectionMessage, Text } from '@design-system'

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

import { organizationSubscriptionSwitchPlanTKeys } from './constants/organizationSubscriptionSwitchPlanTKeys'

interface SwitchPlanSectionMessageProps {
  currentPlan: SubscriptionPlan
  switchPlan: SubscriptionPlan
  switchPlanPeriod?: SubscriptionPeriod
  subscriptionExpiringDate: string
}

export const SwitchPlanSectionMessageComponent = ({
  currentPlan,
  switchPlan,
  switchPlanPeriod,
  subscriptionExpiringDate,
}: SwitchPlanSectionMessageProps) => {
  const { t } = useTranslation()

  const downgradeVariant = switchPlan === SubscriptionPlan.Basic ? 'warning' : 'info'
  const textColor = switchPlan === SubscriptionPlan.Basic ? Color.Yellow200 : Color.Blue
  const switchPlanText = useMemo(() => {
    if (currentPlan === switchPlan) {
      return 'organization_subscription.plan_switch.continue.text'
    }
    if (
      (currentPlan === SubscriptionPlan.Basic && switchPlan !== SubscriptionPlan.Basic) ||
      (currentPlan === SubscriptionPlan.Pro && switchPlan === SubscriptionPlan.Premium)
    ) {
      return 'organization_subscription.plan_switch.upgrade.text'
    }

    return 'organization_subscription.plan_switch.downgrade.text'
  }, [currentPlan, switchPlan])

  const periodSwitchText = useMemo(() => {
    if (switchPlanPeriod && switchPlanPeriod === SubscriptionPeriod.Monthly) {
      return `(${t('organization_subscription.monthly')})`
    }
    if (switchPlanPeriod && switchPlanPeriod === SubscriptionPeriod.Yearly) {
      return `(${t('organization_subscription.yearly')})`
    }
    return ''
  }, [switchPlanPeriod, t])

  return (
    <SectionMessage icon="exclamationMarkCircle" closable={false} variant={downgradeVariant}>
      <Text as="span" color={textColor}>
        {t(switchPlanText, {
          plan: t(organizationSubscriptionSwitchPlanTKeys[switchPlan]),
          period: periodSwitchText,
          formattedSubscriptionDowngradeDate: subscriptionExpiringDate || '',
        })}
      </Text>
    </SectionMessage>
  )
}
