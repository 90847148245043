import React, { ReactElement } from 'react'

import { OrganizationSubscriptionsTable } from '../OrganizationSubscriptionsTable'
import { OrganizationSubscriptionsTableActions } from '../OrganizationSubscriptionsTableActions'

export const OrganizationSubscriptionsList = (): ReactElement => {
  return (
    <>
      <OrganizationSubscriptionsTableActions />
      <OrganizationSubscriptionsTable />
    </>
  )
}
