import { useMutation } from 'react-query'

import { createPaymentMethod, CreatePaymentMethodResponse } from '../query-api/payment-methods-query'
import { APIError } from '../utils'

interface UseCreatePaymentProps {
  onError?: (error?: APIError) => void
  onSuccess?: (data: CreatePaymentMethodResponse) => void
}

export const useCreatePaymentMethod = (props?: UseCreatePaymentProps) => {
  const { onError, onSuccess } = props || {}
  const { mutate: create, isLoading: isProcessing, ...rest } = useMutation(createPaymentMethod, { onSuccess, onError })

  return { create, isProcessing, ...rest }
}
