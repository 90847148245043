import noop from 'lodash/noop'
import React, { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'

import { EmberEventFn } from '../types/emberEventFn'

export type EmberModal = EmberEventFn

export type CloseModal = () => void

interface ContextState {
  closeEmberModal: CloseModal
}

const defaultValue: ContextState = {
  closeEmberModal: noop,
}

const EmberCloseModalContext = createContext<ContextState>(defaultValue)

interface EmberCloseModalContextProps {
  children?: ReactNode
  onCloseModal: EmberModal
}

export const EmberCloseModalContextProvider = ({
  children,
  onCloseModal,
}: EmberCloseModalContextProps): ReactElement => {
  const handleCloseModal = useCallback(() => {
    onCloseModal({ detail: null })
  }, [onCloseModal])

  return (
    <EmberCloseModalContext.Provider value={{ closeEmberModal: handleCloseModal }}>
      {children}
    </EmberCloseModalContext.Provider>
  )
}

export const useEmberCloseModal = () => useContext(EmberCloseModalContext)
